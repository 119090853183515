import { ReactNode } from 'react'
import { Icon } from 'sierra-ui/components'
import { AutocompleteProps, MUIAutocomplete, MUIPaper, MUITextField, withMUIStyles } from 'sierra-ui/mui'
import { spacing, token } from 'sierra-ui/theming'
import { theme } from 'sierra-ui/theming/legacy-theme'
import styled, { css } from 'styled-components'

const StyledPaper = styled(MUIPaper)<{ $compactOptions?: boolean }>`
  margin-top: 0.5rem;
  box-shadow: 0px 16px 24px rgba(0, 0, 0, 0.04);
  border-radius: ${p => p.theme.borderRadius['size-8']};
  border: 1px solid ${token('border/default')};
  background: ${token('surface/default')};

  & li:hover {
    background: ${token('surface/soft')} !important;
  }

  ${p =>
    p.$compactOptions === true &&
    css`
      .MuiAutocomplete-option {
        background-color: ${token('surface/default')} !important;
        padding-top: ${spacing['2']};
        padding-bottom: ${spacing['2']};

        &:first-child {
          padding-top: 0.5rem;
        }

        &:last-child {
          padding-bottom: 0.5rem;
        }
      }
    `}
`

const ChipInput = withMUIStyles({
  root: {
    '& .MuiAutocomplete-endAdornment': {
      top: 'calc(50% - 12px)',
    },
    '&:hover .MuiOutlinedInput-notchedOutline': {
      borderWidth: '1px',
    },
    '& .MuiOutlinedInput-notchedOutline': {
      transition: 'border-color 0.2s ease',
      borderWidth: '1px',
    },
    '&.Mui-focused .MuiOutlinedInput-notchedOutline': {
      borderColor: `${theme.color.blueBright}`,
      borderWidth: '1px',
    },
    '& .MuiAutocomplete-inputRoot[class*="MuiOutlinedInput-root"][class*="MuiOutlinedInput-marginDense"]': {
      padding: '2px 4px',
    },
    '& .MuiAutocomplete-inputRoot': {
      padding: '4px 6px',
      borderRadius: '10px',
    },
    '& .MuiAutocomplete-inputRoot > button': {
      margin: '0.125rem 0.25rem 0.125rem 0',
    },
    '& .MuiAutocomplete-tag': {
      fontSize: `0.875rem`,
    },
  },
  inputFocused: {
    boxShadow: 'none',
    borderWidth: '1px',
  },
})(MUIAutocomplete) as unknown as typeof MUIAutocomplete

const StyledChipInput = styled(ChipInput)`
  &&& {
    input {
      color: ${token('foreground/primary')};

      &::placeholder {
        color: ${token('foreground/secondary')};
      }
    }

    .MuiOutlinedInput-notchedOutline {
      border-color: ${token('form/border/1')};
    }

    &:hover {
      .MuiOutlinedInput-notchedOutline {
        border-color: ${token('form/border/2')};
      }
    }

    &.Mui-focused .MuiOutlinedInput-notchedOutline {
      border-color: ${token('form/border/3')};
    }
  }
` as typeof ChipInput

type ChipInputProps = {
  header?: ReactNode
  inputPlaceholder?: string
  autoFocus?: boolean
  compactOptions?: boolean
  onBlur?: () => void
  error?: boolean
  helperText?: string
  disabled?: boolean
}

/**
 * @deprecated Use [ui/autocomplete](https://ui.sana.dev/?path=/docs/components-autocomplete--docs) instead
 */
export const ChipInputWithAutocomplete = <
  T,
  Multiple extends boolean | undefined,
  DisableClearable extends boolean | undefined,
  FreeSolo extends boolean | undefined,
>({
  disableCloseOnSelect = true,
  limitTags = 7,
  header = null,
  inputPlaceholder,
  compactOptions = false,
  autoFocus,
  error = false,
  helperText = '',
  disabled = false,
  onBlur,
  ...rest
}: Omit<AutocompleteProps<T, Multiple, DisableClearable, FreeSolo>, 'renderInput'> &
  ChipInputProps): JSX.Element => {
  return (
    <StyledChipInput
      limitTags={limitTags}
      popupIcon={<></>}
      disableCloseOnSelect={disableCloseOnSelect}
      disabled={disabled}
      closeIcon={<Icon iconId='close' size='size-16' color='foreground/primary' />}
      PaperComponent={({ children }) => {
        return (
          <StyledPaper $compactOptions={compactOptions} style={{ pointerEvents: 'all' }}>
            <>
              {header}
              {children}
            </>
          </StyledPaper>
        )
      }}
      renderInput={params => (
        <MUITextField
          {...params}
          fullWidth
          error={error}
          autoFocus={autoFocus}
          helperText={helperText}
          onBlur={onBlur}
          variant='outlined'
          placeholder={inputPlaceholder}
          size='small'
        />
      )}
      {...rest}
    />
  )
}
