import { useCallback, useEffect, useState } from 'react'
import chatSfx from 'sierra-client/assets/sounds/chat.mp3'
import handRaiseSfx from 'sierra-client/assets/sounds/hand-raise.mp3'
import joinSfx from 'sierra-client/assets/sounds/join.mp3'
import leaveSfx from 'sierra-client/assets/sounds/leave.mp3'
import reactionSfx from 'sierra-client/assets/sounds/reaction.mp3'
import { useSanaSound } from 'sierra-client/components/liveV2/hooks/use-sana-sound'
import { useCurrentClientContext } from 'sierra-client/components/liveV2/live-provider'
import { useLiveSessionIdContext } from 'sierra-client/components/liveV2/live-session-id-provider'
import { useRightSidebarContext } from 'sierra-client/components/liveV2/live-sidebar-provider'
import { usePrevious } from 'sierra-client/hooks/use-previous'
import { selectLastMessageInChat, selectUnreadMessagesCount } from 'sierra-client/state/chat/selectors'
import { useSelector } from 'sierra-client/state/hooks'
import {
  selectCurrentRoomParticipantCount,
  selectLiveSessionRaisedHands,
} from 'sierra-client/state/live-session/selectors'
import { selectUserId } from 'sierra-client/state/user/user-selector'
import { ScopedChatId } from 'sierra-domain/collaboration/types'

const RaiseHandSfx = (): null => {
  const [playHandRaise] = useSanaSound(handRaiseSfx, { volume: 0.06 })
  const raisedHands = useSelector(selectLiveSessionRaisedHands)
  const previousRaisedHandsCount = usePrevious(raisedHands.length)

  useEffect(() => {
    if (previousRaisedHandsCount === undefined || previousRaisedHandsCount < raisedHands.length) {
      playHandRaise()
    }
  }, [raisedHands.length, previousRaisedHandsCount, playHandRaise])

  return null
}

const ChatMessageSfx = ({ chatId, userId }: { chatId: ScopedChatId; userId: string }): null => {
  const { state, isOpen } = useRightSidebarContext()
  const isChatOpen = isOpen && state === 'chat_open'

  const [playChatSound] = useSanaSound(chatSfx, { volume: 0.04 })
  const [playReactionSound] = useSanaSound(reactionSfx, { volume: 0.04 })

  const unreadCount = useSelector(state => selectUnreadMessagesCount(state, chatId, 'root'))

  const message = useSelector(state => selectLastMessageInChat(state, chatId))
  const previousMessage = usePrevious(message)

  useEffect(() => {
    if (
      message !== undefined &&
      message.userId !== userId &&
      message.id !== previousMessage?.id &&
      message.responseToMessageId === undefined &&
      (!isChatOpen || document.visibilityState === 'hidden') &&
      unreadCount <= 1
    ) {
      // Play sounds when window is inactive or chat is closed and
      message.type === 'emoji' ? playReactionSound() : playChatSound()
    }
  }, [message, previousMessage, playChatSound, playReactionSound, isChatOpen, unreadCount, userId])

  return null
}

const JoinAndLeaveSfx = (): null => {
  const { client } = useCurrentClientContext()
  const [playJoin] = useSanaSound(joinSfx, { volume: 0.1, interrupt: true })
  const [playLeave] = useSanaSound(leaveSfx, { volume: 0.1, interrupt: true })
  const participantCount = useSelector(selectCurrentRoomParticipantCount)
  const [shouldPlaySound, setShouldPlaySound] = useState(false)

  const shouldPlay = participantCount < 5 && shouldPlaySound

  useEffect(() => {
    const timeout = setTimeout(() => {
      setShouldPlaySound(true)
    }, 3000)

    return () => {
      clearTimeout(timeout)
    }
  }, [])

  const handleUserJoin = useCallback((): void => {
    if (shouldPlay) {
      playJoin()
    }
  }, [shouldPlay, playJoin])

  const handleUserLeave = useCallback((): void => {
    if (shouldPlay) {
      playLeave()
    }
  }, [playLeave, shouldPlay])

  useEffect(() => {
    if (client === undefined) return

    client.on('user-joined', handleUserJoin)
    client.on('user-left', handleUserLeave)

    return () => {
      client.off('user-joined', handleUserJoin)
      client.off('user-left', handleUserLeave)
    }
  }, [client, handleUserJoin, handleUserLeave])

  return null
}

export const AudioSfx = (): JSX.Element | null => {
  const { liveSessionId } = useLiveSessionIdContext()
  const userId = useSelector(selectUserId)
  const chatId = ScopedChatId.fromId(liveSessionId)

  if (userId === undefined) return null

  return (
    <>
      <JoinAndLeaveSfx />
      <RaiseHandSfx />
      <ChatMessageSfx chatId={chatId} userId={userId} />
    </>
  )
}
