import { FC } from 'react'
import { useDragLayer } from 'react-dnd'
import { createPortal } from 'react-dom'
import { DragOverlayCard } from 'sierra-client/features/program/admin/edit/dnd/overlays/components'
import { Icon, TruncatedText } from 'sierra-ui/components'
import { View } from 'sierra-ui/primitives'
import styled from 'styled-components'

const WrapperView = styled(View).attrs({
  padding: '16 10',
  gap: '12',
  overflow: 'hidden',
  background: 'surface/soft',
})`
  width: 100%;
`

export const ProgramSectionDragOverlay: FC = () => {
  const { item, isDragging, position, initial, initialSource } = useDragLayer(monitor => ({
    isDragging: monitor.isDragging(),
    item: monitor.getItem(),
    position: monitor.getClientOffset(),
    initial: monitor.getInitialClientOffset() ?? { x: 0, y: 0 },
    initialSource: monitor.getInitialSourceClientOffset() ?? { x: 0, y: 0 },
    tmp: monitor.getDifferenceFromInitialOffset(),
  }))

  if (!isDragging || position === null) return null

  const x = initial.x - initialSource.x
  const y = initial.y - initialSource.y

  if (item.type !== 'program-section') return null

  return createPortal(
    <DragOverlayCard x={position.x - x} y={position.y - y} width={288} height={48} gradient={false}>
      <WrapperView>
        <Icon iconId='draggable' color='foreground/muted' />
        <TruncatedText lines={1} bold>
          {item.title}
        </TruncatedText>
      </WrapperView>
    </DragOverlayCard>,
    document.body
  )
}
