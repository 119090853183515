import { useAtom, useAtomValue } from 'jotai'
import _ from 'lodash'
import React, { forwardRef, useEffect, useRef } from 'react'
import { IconMenu } from 'sierra-client/components/common/icon-menu'
import { editorGrid } from 'sierra-client/editor/layout'
import { useTranslation } from 'sierra-client/hooks/use-translation'
import { newPageCardColumnColorAtom } from 'sierra-client/state/settings'
import { BlockDefinition } from 'sierra-client/views/block-types'
import { useCommentingContext } from 'sierra-client/views/commenting/context'
import { isInElement, unwrapToTextNodes, updateNodeWithId } from 'sierra-client/views/v3-author/command'
import { DisplayNone } from 'sierra-client/views/v3-author/components'
import { useIsUniquelySelected, usePath } from 'sierra-client/views/v3-author/hooks'
import {
  assertElementType,
  findNode,
  getCurrentlySelectedNode,
  isElementType,
  parentType,
  safeToDomNode,
} from 'sierra-client/views/v3-author/queries'
import { RenderingContext } from 'sierra-client/views/v3-author/rendering-context'
import { SlateWrapperProps } from 'sierra-client/views/v3-author/slate'
import { TableCellMultiSelectionSetup } from 'sierra-client/views/v3-author/table/multi-select/table-cell-multi-selection-setup'
import { useHandleCellClick } from 'sierra-client/views/v3-author/table/multi-select/use-handle-cell-click'
import { useTableCellMultiSelection } from 'sierra-client/views/v3-author/table/multi-select/use-table-cell-multi-selection'
import { tablePreviewThemeAtom } from 'sierra-client/views/v3-author/table/preview-theme'
import { TableColorPicker } from 'sierra-client/views/v3-author/table/table-color-picker'
import { TableContextProvider, useTableContext } from 'sierra-client/views/v3-author/table/table-context'
import { TableOperations, getDimensions } from 'sierra-client/views/v3-author/table/table-operations'
import { debug } from 'sierra-client/views/v3-author/table/utils'
import { unwrapNonParagraphChildren } from 'sierra-client/views/v3-author/unwrap-non-paragraph-children'
import { CourseTheme } from 'sierra-domain/content/v2/content'
import { Entity } from 'sierra-domain/entity'
import { nanoid12 } from 'sierra-domain/nanoid-extensions'
import { assertNever, getUrlFromText, iife } from 'sierra-domain/utils'
import { EditorKeyboardEvent, Link, SanaEditor, TableDimensions } from 'sierra-domain/v3-author'
import { createParagraph, createTableCell } from 'sierra-domain/v3-author/create-blocks'
import { ColorBuilder, color } from 'sierra-ui/color'
import { Icon, MenuItem, Tooltip } from 'sierra-ui/components'
import { View } from 'sierra-ui/primitives'
import { LightTokenProvider, spacing } from 'sierra-ui/theming'
import { v2_breakpoint } from 'sierra-ui/theming/breakpoints'
import { fonts } from 'sierra-ui/theming/fonts'
import { ThemeName, getTheme } from 'sierra-ui/theming/legacy-theme'
import { Editor, Element, Node, Path, Range, Text, Transforms } from 'slate'
import { useFocused, useSelected, useSlateStatic } from 'slate-react'
import styled, { ThemeProvider, css, useTheme } from 'styled-components'

const FullWidthContainer = styled.div`
  overflow: auto unset;
  overscroll-behavior-x: contain;
  margin-top: 0.5rem;
`

const cellPlugin = (cellType: 'table-cell' | 'table-header-cell') => (editor: SanaEditor) => {
  const { deleteBackward, normalizeNode, insertBreak, insertData } = editor

  editor.insertData = transfer => {
    if (isInElement(editor, cellType)) {
      const { selection } = editor
      // When marking text and pasting a link, keep the marking as display text and create a link
      const potentialUrl = getUrlFromText(transfer.getData('text/plain'))

      if (potentialUrl !== undefined && selection !== null && Editor.string(editor, selection) !== '') {
        const link: Entity<Link> = { id: nanoid12(), type: 'link', url: potentialUrl, children: [] }
        Transforms.wrapNodes(editor, link, { split: true })
        return
      }

      const text = transfer.getData('text/plain')
      Transforms.insertText(editor, text)
    } else {
      return insertData(transfer)
    }
  }

  editor.insertBreak = () => {
    const { selection } = editor

    if (selection && Range.isCollapsed(selection)) {
      const [cell] = Editor.nodes(editor, {
        match: isElementType(cellType),
      })

      if (cell !== undefined) {
        return
      }
    }

    insertBreak()
  }

  editor.deleteBackward = unit => {
    const { selection } = editor

    if (selection !== null && Range.isCollapsed(selection)) {
      const [node] = Editor.node(editor, Path.parent(selection.anchor.path))

      if (isElementType(cellType, node)) {
        if (node.children.map(c => (Text.isText(c) ? c.text : '')).join('') === '') {
          return
        }
      }
    }

    deleteBackward(unit)
  }

  editor.normalizeNode = entry => {
    const [node, path] = entry

    if (!isElementType(cellType, node)) {
      return normalizeNode(entry)
    }

    // Unwrap any "non-text" children
    const { didUnwrap } = unwrapNonParagraphChildren(editor, entry)
    if (didUnwrap) {
      return
    }

    if (!isElementType('table-row', Node.parent(editor, path))) {
      debug(`Converting ${node.type} to paragraph at`, path)
      unwrapToTextNodes(editor, { at: path })
      return Transforms.wrapNodes(editor, createParagraph(), { at: path })
    }

    return normalizeNode(entry)
  }

  return editor
}

const Grid = styled.div`
  overflow: auto;
  overscroll-behavior-x: contain;

  ${editorGrid}

  padding-bottom: 2.25rem;
`

const CellActions = styled(View).attrs({ contentEditable: false, alignItems: 'flex-start' })`
  position: absolute;
  top: 0;
  bottom: 0;
  padding: 12px 0;
  right: 0.5rem;
  color: var(--icon-color);
  transition: all 100ms;
`

const RowActions = styled(View).attrs({
  contentEditable: false,
})`
  position: absolute;
  top: 0;
  right: 100%;
  height: 100%;
  padding-right: 0.5rem;
  color: var(--icon-color);
  transition: all 100ms;
`

const AddButton = styled.button`
  background-color: transparent;
  flex-grow: 1;
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
  transition: all 100ms;
  color: var(--icon-color);
  border: none;
  opacity: 0.5;
`

const StyledIconMenu = styled(IconMenu)`
  color: ${p => p.theme.home.textColor};
`

type TableActionsMenuProps = {
  tablePath: Path
  isTableHead: boolean
  elementId: string
  previewThemeName: ThemeName | undefined
  elementThemeName: ThemeName | undefined
  setPreviewThemeName: (value: ThemeName | undefined) => void
  element: Element & { id: string; theme?: CourseTheme }
}

const TableActionsMenu: React.FC<TableActionsMenuProps> = ({
  tablePath,
  isTableHead,
  elementId,
  previewThemeName,
  setPreviewThemeName,
  elementThemeName,
  element,
}) => {
  const editor = useSlateStatic()
  const { t } = useTranslation()
  const { dimensions } = useTableContext()
  const path = usePath({ nodeId: elementId })
  const columnIndex = path[path.length - 1]
  const rowIndex = path[path.length - 2]
  const { clearSelection, addSelection, containsSelection, getSelection } = useTableCellMultiSelection()

  const nestedThemeItem: MenuItem[] = [
    {
      id: 'theme',
      type: 'canvas',
      render: () => {
        return (
          <>
            <div contentEditable={false}>
              <TableColorPicker
                onMouseOver={(themeName: string) => {
                  const theme = CourseTheme.parse({ type: 'preset', name: themeName })
                  if (!containsSelection(elementId)) {
                    clearSelection()
                    addSelection(elementId)
                  }
                  setPreviewThemeName(theme.name)
                }}
                onMouseOut={() => {
                  if (previewThemeName !== undefined) setPreviewThemeName(undefined)
                }}
                onClick={(themeName: string): void => {
                  const theme = CourseTheme.parse({ type: 'preset', name: themeName })

                  if (_.isEqual(element.theme, theme)) {
                    // If theme matches current theme, remove it
                    if (containsSelection(elementId)) {
                      // Apply to all selected cells
                      getSelection().forEach(id => updateNodeWithId(editor, id, { theme: undefined }))
                    } else {
                      // Apply only to current cell
                      updateNodeWithId(editor, elementId, { theme: undefined })
                    }
                    setPreviewThemeName(undefined)
                  } else {
                    // Set new theme
                    if (containsSelection(elementId)) {
                      // Apply to all selected cells
                      getSelection().forEach(id => updateNodeWithId(editor, id, { theme }))
                    } else {
                      // Apply only to current cell
                      updateNodeWithId(editor, elementId, { theme })
                    }
                  }
                  setPreviewThemeName(undefined)
                }}
                selectedTheme={elementThemeName}
              />
            </div>
          </>
        )
      },
    },
  ]

  const addRow = (): void => {
    if (isTableHead) {
      return TableOperations.addTableHead(editor, tablePath)
    }
    if (rowIndex !== undefined) {
      TableOperations.addRow(editor, tablePath, { index: rowIndex })
    }
  }

  const addColumn = (): void => {
    TableOperations.addColumn(editor, tablePath, { index: columnIndex })
  }

  const deleteRow = (): void => {
    if (rowIndex !== undefined) {
      TableOperations.deleteRow(editor, tablePath, { index: rowIndex })
    }
  }

  const deleteColumn = (): void => {
    if (columnIndex !== undefined) {
      TableOperations.deleteColumn(editor, tablePath, { index: columnIndex })
    }
  }

  const tableActionItems: MenuItem[] = [
    {
      type: 'group',
      id: 'cell-settings',
      label: t('author.table.cell-settings'),
      menuItems: [
        {
          id: 'add-row',
          type: 'label',
          label: t('author.table.insert-row-above'),
          icon: 'arrow--up',
          onClick: addRow,
        },
        {
          id: 'add-column',
          type: 'label',
          label: t('author.table.insert-column-left'),
          icon: 'arrow--left',
          onClick: addColumn,
        },
        {
          id: 'color-picker',
          type: 'nested',
          label: t('author.table.cell-theme'),
          icon: 'color-palette',
          menuItems: nestedThemeItem,
        },
        {
          id: 'separator',
          type: 'separator',
        },
        {
          id: 'delete-row',
          type: 'label',
          label: t('author.table.delete-row'),
          icon: 'trash-can',
          hidden: (dimensions && dimensions.rows < 2) || isTableHead,
          onClick: deleteRow,
        },
        {
          id: 'delete-column',
          type: 'label',
          label: t('author.table.delete-column'),
          icon: 'trash-can',
          hidden: dimensions && dimensions.columns < 2,
          onClick: deleteColumn,
        },
      ],
    },
  ]

  return (
    <LightTokenProvider>
      <StyledIconMenu items={tableActionItems} closeOnPick aria-label='menu' size='small' />
    </LightTokenProvider>
  )
}

type ControlsProps = { $position: 'above' | 'below' | 'right' }
const Controls = styled.div.attrs({
  contentEditable: false,
})<ControlsProps>`
  position: absolute;
  transition: all 100ms;
  display: flex;
  align-items: stretch;
  justify-content: center;

  border: 2px solid var(--background-color);
  border-radius: ${p => p.theme.borderRadius['size-10']};

  &:hover {
    background: var(--cell-color);

    ${AddButton} {
      opacity: 1;
    }
  }

  ${p => {
    switch (p.$position) {
      case 'above':
        return css`
          top: -${spacing['4']};
          width: 100%;
          flex-direction: row;
          height: 3rem;
        `
      case 'below':
        return css`
          top: calc(100% + ${spacing['2']});
          width: 100%;
          flex-direction: row;
          height: 2rem;
        `
      case 'right':
        return css`
          top: 0;
          left: 100%;
          height: 100%;
          flex-direction: column;
          width: 2rem;
        `
      default:
        assertNever(p.$position)
    }
  }}
`

const PositionRelative = styled.div`
  position: relative;
  display: inline-flex;
`

const Td = styled.td<{
  $hasCursor: boolean
  $hasCellActions: boolean
  $isMultiSelected: boolean
  $backgroundColor: ColorBuilder
}>`
  position: relative;
  min-width: 10rem;
  width: auto;
  padding: 1rem;
  transition-duration: 100ms;
  transition-property: background, border;
  background-color: ${p => p.$backgroundColor};
  color: ${p => p.theme.home.textColor};
  border: 2px solid transparent;
  border-radius: 8px;
  ${p =>
    p.$isMultiSelected &&
    css`
      outline: 2px solid var(--icon-color) !important;
      outline-offset: -4px;
    `}

  ${p =>
    p.$hasCursor === true
      ? css`
          outline: 2px solid var(--icon-color) !important;
          outline-offset: -4px;
        `
      : css`
          &:hover {
            background-color: ${p => color(p.theme.home.backgroundColor).shift(0.07).toString()};
          }
        `}
  ${p =>
    p.$hasCellActions &&
    css`
      padding-right: ${spacing.large};
    `}
    & ${CellActions} {
    opacity: 0;
  }

  &:hover ${CellActions} {
    opacity: 1;
  }
`
const Th = styled(Td).attrs({ as: 'th' })`
  text-align: left;
  color: ${p => p.theme.home.textColor};
  font-weight: ${fonts.weight.regular};
`
const Tr = styled.tr`
  &:last-child td {
    border-bottom: none;
  }

  ${RowActions} {
    opacity: 0;
  }

  &:hover ${RowActions} {
    opacity: 1;
  }
`

const StyledTable = styled.table<{
  $hasCursor: boolean
  $dimensions: TableDimensions
  $fluid: boolean
}>`
  width: ${p => (p.$fluid ? '100%' : `calc(${p.$dimensions.columns} * 10rem)`)};

  @media screen and (max-width: ${v2_breakpoint.phone}) {
    width: auto;
  }

  table-layout: fixed;
  border-collapse: separate;
  color: ${p => p.theme.home.textColor};
  position: relative;
  transition: all 100ms;

  /* fix for background overlapping borders */

  &,
  tr,
  th,
  td {
    background-clip: padding-box;
  }
`
const BlockAlignment = styled.div<{
  $dimensions: TableDimensions
}>`
  position: relative;

  --icon-color: ${p => color(p.theme.home.textColor).opacity(0.8).toString()};
  --cell-color: ${p => color(p.theme.home.textColor).opacity(0.07).toString()};
  --cell-color-strong: ${p => color(p.theme.home.textColor).opacity(0.32).toString()};
  --background-color: ${p => p.theme.home.backgroundColor};

  ${Controls} {
    opacity: 0;
  }

  &:hover {
    ${Controls} {
      opacity: 1;
    }
  }
`
const THead = styled.thead``

function tableRootDescendantPlugin(type: 'table-head' | 'table-body'): BlockDefinition['plugin'] {
  return editor => {
    const { normalizeNode } = editor

    editor.normalizeNode = entry => {
      const [node, path] = entry

      if (!isElementType(type, node)) return normalizeNode(entry)

      const children = Array.from(Node.children(editor, path))

      if (children.length === 0) {
        debug(`Removing  ${type} at`, path)
        return Transforms.removeNodes(editor, { at: path })
      }

      if (children.every(([child]) => Text.isText(child))) {
        return Transforms.unwrapNodes(editor, { at: path })
      }

      for (const [child, childPath] of children) {
        if (Text.isText(child)) {
          debug(`remove text node child in ${type}`, child)
          return Transforms.removeNodes(editor, { at: childPath })
        }

        if (!isElementType('table-row', child)) {
          debug(`unwrap child in ${type}`, child)
          return Transforms.unwrapNodes(editor, { at: childPath, voids: true })
        }
      }

      const [from, to] =
        type === 'table-head'
          ? (['table-cell', 'table-header-cell'] as const)
          : (['table-header-cell', 'table-cell'] as const)

      for (const [descendant, descendantPath] of Node.descendants(node)) {
        if (isElementType(from, descendant)) {
          debug(`change cell from ${from} to ${to} in ${type}`)
          return Transforms.setNodes(editor, { type: to }, { at: [...path, ...descendantPath] })
        }
      }

      if (parentType(editor, path) !== 'table') {
        debug(`${type} must be wrapped in table. Unwrapping at`, path)
        return Transforms.unwrapNodes(editor, { at: path })
      }

      return normalizeNode(entry)
    }
    return editor
  }
}

export const TableHead: BlockDefinition = {
  Wrapper: forwardRef<HTMLTableSectionElement, SlateWrapperProps>(
    ({ attributes, children, element, ...props }, ref) => {
      assertElementType('table-head', element)

      return (
        <THead {...attributes} {...props} ref={ref}>
          <TableContextProvider value={{ insideHeader: true }}>{children}</TableContextProvider>
        </THead>
      )
    }
  ),
  plugin: tableRootDescendantPlugin('table-head'),
}

export const TableBody: BlockDefinition = {
  Wrapper: forwardRef<HTMLTableSectionElement, SlateWrapperProps>(
    ({ attributes, children, element, ...props }, ref) => {
      assertElementType('table-body', element)
      return (
        <tbody {...attributes} {...props} ref={ref}>
          {children}
        </tbody>
      )
    }
  ),
  plugin: tableRootDescendantPlugin('table-body'),
}

export const TableRow: BlockDefinition = {
  Wrapper: forwardRef<HTMLTableRowElement, SlateWrapperProps>(
    ({ attributes, children, element, readOnly, ...props }, ref) => {
      assertElementType('table-row', element)

      return (
        <Tr {...attributes} {...props} ref={ref}>
          {children}
        </Tr>
      )
    }
  ),
  plugin(editor) {
    const { normalizeNode } = editor

    editor.normalizeNode = ([node, path]) => {
      if (!isElementType('table-row', node)) {
        return normalizeNode([node, path])
      }

      for (const child of Node.children(editor, path)) {
        if (!isElementType(['table-cell', 'table-header-cell'], child[0])) {
          debug(`unwrap foreign child in row`, child[0])
          unwrapToTextNodes(editor, { at: child[1] })
          return Transforms.wrapNodes(editor, createTableCell(), { at: child[1] })
        }
      }

      const parent = parentType(editor, path)
      if (parent !== 'table-head' && parent !== 'table-body') {
        debug(`table-row must be wrapped in table-head or table-body. Unwrapping at`, path)
        return Transforms.unwrapNodes(editor, { at: path })
      }

      normalizeNode([node, path])
    }

    return editor
  },
}

export const TableCell: BlockDefinition = {
  Wrapper: forwardRef<HTMLTableCellElement | null, SlateWrapperProps>(
    ({ attributes, children, element, readOnly, ...props }, ref) => {
      assertElementType('table-cell', element)

      const editor = useSlateStatic()
      const isOnlySelectedElement = useIsUniquelySelected({ nodeId: element.id })
      const isEditorFocused = useFocused()
      const hasCursor = isOnlySelectedElement && isEditorFocused
      const { tablePath } = useTableContext()
      const defaultTheme = useTheme()
      const { containsSelection } = useTableCellMultiSelection()

      const [previewThemeName, setPreviewThemeName] = useAtom(tablePreviewThemeAtom)

      const potentialPreviewThemeName = containsSelection(element.id) ? previewThemeName : undefined

      const elementThemeName = potentialPreviewThemeName ?? element.theme?.name

      const theme = elementThemeName
        ? getTheme(defaultTheme, potentialPreviewThemeName ?? elementThemeName)
        : defaultTheme

      const nodeId = element.id
      useEffect(() => {
        const entry = findNode(editor, node => node.id === nodeId)
        if (entry.length === 0) return

        const [node] = entry

        if (hasCursor) {
          safeToDomNode(editor, node)?.scrollIntoView({
            behavior: 'auto',
            block: 'nearest',
            inline: 'center',
          })
        }
      }, [hasCursor, nodeId, editor])

      const handleCellClick = useHandleCellClick(element.id, () => setPreviewThemeName(undefined))

      const isNewPageCard = useAtomValue(newPageCardColumnColorAtom)

      return (
        <ThemeProvider theme={theme}>
          <Td
            {...attributes}
            {...props}
            ref={ref}
            $hasCursor={hasCursor}
            $hasCellActions={tablePath !== undefined}
            $isMultiSelected={containsSelection(element.id)}
            onClick={handleCellClick}
            $backgroundColor={iife(() => {
              if (isNewPageCard) {
                return elementThemeName === undefined
                  ? color(theme.home.textColor).opacity(0.04)
                  : color(theme.home.backgroundColor)
              } else {
                // If theme is explicitly set to match background, make it transparent
                const hasSameBackgroundAsCard =
                  elementThemeName !== undefined &&
                  theme.home.backgroundColor === defaultTheme.home.backgroundColor

                return hasSameBackgroundAsCard
                  ? color('transparent')
                  : elementThemeName === undefined
                    ? color(theme.home.textColor).opacity(0.04)
                    : color(theme.home.backgroundColor)
              }
            })}
          >
            {children}
            {tablePath !== undefined && !readOnly && (
              <CellActions>
                <TableActionsMenu
                  elementThemeName={elementThemeName}
                  previewThemeName={potentialPreviewThemeName}
                  setPreviewThemeName={setPreviewThemeName}
                  isTableHead={false}
                  elementId={element.id}
                  element={element}
                  tablePath={tablePath}
                />
              </CellActions>
            )}
          </Td>
        </ThemeProvider>
      )
    }
  ),
  plugin: cellPlugin('table-cell'),
}

export const TableHeaderCell: BlockDefinition = {
  Wrapper: forwardRef<HTMLTableHeaderCellElement, SlateWrapperProps>(
    ({ attributes, children, element, readOnly, ...props }, ref) => {
      assertElementType('table-header-cell', element)
      const editor = useSlateStatic()
      const isOnlySelectedElement = useIsUniquelySelected({ nodeId: element.id })
      const isEditorFocused = useFocused()
      const hasCursor = isOnlySelectedElement && isEditorFocused
      const { tablePath } = useTableContext()
      const { containsSelection } = useTableCellMultiSelection()

      const defaultTheme = useTheme()

      const [previewThemeName, setPreviewThemeName] = useAtom(tablePreviewThemeAtom)

      const potentialPreviewThemeName = containsSelection(element.id) ? previewThemeName : undefined

      const elementThemeName = potentialPreviewThemeName ?? element.theme?.name

      const theme = elementThemeName
        ? getTheme(defaultTheme, potentialPreviewThemeName ?? elementThemeName)
        : defaultTheme

      const nodeId = element.id
      useEffect(() => {
        const entry = findNode(editor, node => node.id === nodeId)
        if (entry.length === 0) return

        const [node] = entry

        if (hasCursor) {
          safeToDomNode(editor, node)?.scrollIntoView({
            behavior: 'auto',
            block: 'nearest',
            inline: 'center',
          })
        }
      }, [hasCursor, nodeId, editor])

      const handleCellClick = useHandleCellClick(element.id, () => setPreviewThemeName(undefined))

      const isNewPageCard = useAtomValue(newPageCardColumnColorAtom)

      return (
        <ThemeProvider theme={theme}>
          <Th
            {...attributes}
            {...props}
            ref={ref}
            $hasCursor={hasCursor}
            $hasCellActions={tablePath !== undefined}
            $isMultiSelected={containsSelection(element.id)}
            onClick={handleCellClick}
            $backgroundColor={iife(() => {
              if (isNewPageCard) {
                return elementThemeName === undefined
                  ? color(theme.home.textColor).opacity(0.04)
                  : color(theme.home.backgroundColor)
              } else {
                // If theme is explicitly set to match background, make it transparent
                const hasSameBackgroundAsCard =
                  elementThemeName !== undefined &&
                  theme.home.backgroundColor === defaultTheme.home.backgroundColor

                return hasSameBackgroundAsCard
                  ? color('transparent')
                  : elementThemeName === undefined
                    ? color(theme.home.textColor).opacity(0.04)
                    : color(theme.home.backgroundColor)
              }
            })}
          >
            {children}

            {tablePath !== undefined && !readOnly && (
              <CellActions>
                <TableActionsMenu
                  elementThemeName={elementThemeName}
                  previewThemeName={previewThemeName}
                  setPreviewThemeName={setPreviewThemeName}
                  elementId={element.id}
                  isTableHead={true}
                  element={element}
                  tablePath={tablePath}
                />
              </CellActions>
            )}
          </Th>
        </ThemeProvider>
      )
    }
  ),
  plugin: cellPlugin('table-header-cell'),
}

export const TableRoot: BlockDefinition = {
  Wrapper: forwardRef<HTMLDivElement, SlateWrapperProps>(
    ({ attributes, children, element, ...props }, publicRef) => {
      assertElementType('table', element)

      const dimensions = getDimensions(element)
      const path = usePath({ nodeId: element.id })
      const { withHeaders } = element.options
      const hasHeader = element.children.some(isElementType('table-head'))

      return (
        <FullWidthContainer {...attributes} {...props} ref={publicRef}>
          <TableContextProvider value={{ tablePath: path, dimensions, withHeaders, hasHeader }}>
            {children}
          </TableContextProvider>
        </FullWidthContainer>
      )
    }
  ),
  Component({ children, element, readOnly, ...props }) {
    assertElementType('table', element)

    const { t } = useTranslation()
    const editor = useSlateStatic()
    const isSelected = useSelected()
    const path = usePath({ nodeId: element.id })
    const { dimensions } = useTableContext()

    const commenting = useCommentingContext()
    const containerRef = useRef<HTMLDivElement | null>(null)

    useEffect(() => {
      const scrollingContainer = containerRef.current
      if (scrollingContainer === null) return

      const scrolllistener = (): void => {
        commenting?.triggerRerender()
      }

      scrollingContainer.addEventListener('scroll', scrolllistener)

      return () => {
        scrollingContainer.addEventListener('scroll', scrolllistener)
      }
    }, [commenting])

    const {
      editor: { isWideLayout },
    } = useTheme()

    if (dimensions === undefined) {
      return <DisplayNone>{children}</DisplayNone>
    }

    const fluidThreshold = isWideLayout ? 7 : 5
    const isFluid = dimensions.columns < fluidThreshold

    return (
      <>
        <TableCellMultiSelectionSetup />
        <Grid ref={containerRef}>
          <RenderingContext preventDrag={true}>
            <BlockAlignment data-block-inner={element.id} $dimensions={dimensions}>
              <PositionRelative>
                <StyledTable
                  {..._.omit(props, 'data-block-inner')}
                  $hasCursor={isSelected === true}
                  $dimensions={dimensions}
                  $fluid={isFluid}
                >
                  {children}
                </StyledTable>

                {!readOnly && (
                  <>
                    <Controls $position='below'>
                      <Tooltip title={t('author.table.add-row')}>
                        <AddButton onClick={() => TableOperations.addRow(editor, path, { index: undefined })}>
                          <Icon iconId='add' size='size-14' color='currentColor' />
                        </AddButton>
                      </Tooltip>
                    </Controls>
                    <Controls $position='right'>
                      <Tooltip title={t('author.table.add-column')}>
                        <AddButton
                          onClick={() => TableOperations.addColumn(editor, path, { index: undefined })}
                        >
                          <Icon iconId='add' size='size-14' color='currentColor' />
                        </AddButton>
                      </Tooltip>
                    </Controls>
                  </>
                )}
              </PositionRelative>
            </BlockAlignment>
          </RenderingContext>
        </Grid>
      </>
    )
  },
  plugin(editor) {
    const { insertBreak, normalizeNode } = editor

    editor.insertBreak = () => {
      if (!isInElement(editor, 'table')) return insertBreak()

      const [cellEntry] = Editor.nodes(editor, {
        match: n => !Editor.isEditor(n) && Element.isElement(n) && n.type === 'table-cell',
      })

      if (cellEntry !== undefined) {
        const [cell] = cellEntry

        if (Element.isElement(cell) && (cell.type === 'table-cell' || cell.type === 'table-header-cell')) {
          return
        }
      }

      return insertBreak()
    }

    editor.normalizeNode = ([node, path]) => {
      if (!isElementType('table', node)) {
        return normalizeNode([node, path])
      }

      /**
       * Ensure there's exactly one table-head and one table-body
       */
      let hasHead = false as boolean // https://sanalabs.slack.com/archives/C01JQ4F7YUB/p1671544208707689
      let hasBody = false as boolean

      // Looping through children backwards, in order to not shift the paths as nodes are removed.
      for (const [child, childPath] of Node.children(editor, path, { reverse: true })) {
        if (!Element.isElement(child)) continue

        switch (child.type) {
          case 'table-head': {
            if (hasHead) {
              debug(`Removing duplicate table-head at`, childPath)
              Transforms.removeNodes(editor, { at: childPath })
              return
            } else {
              hasHead = true
            }
            break
          }
          case 'table-body': {
            if (hasBody) {
              debug(`Removing duplicate table-body at`, childPath)
              Transforms.removeNodes(editor, { at: childPath })
              return
            } else {
              hasBody = true
            }
            break
          }
          default:
            debug(`Unwrapping unexpected node at`, childPath)
            Transforms.unwrapNodes(editor, { at: childPath, voids: true })
            return
        }
      }

      if (!hasBody) {
        debug(`Unwrapping table without body at`, path)
        return Transforms.unwrapNodes(editor, { at: path, voids: true })
      }

      const didHarmonize = TableOperations.harmonize(editor, path)
      if (didHarmonize) return
      else return normalizeNode([node, path])
    }

    return editor
  },
  actionsVerticalAlignment: 'top',
}

export const onKeyDown = (event: EditorKeyboardEvent, editor: Editor): void => {
  const selectedNode = getCurrentlySelectedNode(editor)

  if (selectedNode === undefined || !isElementType(['table-cell', 'table-header-cell'], selectedNode[0])) {
    return
  }

  const [, path] = selectedNode
  const cellAncestors = Array.from(Node.ancestors(editor, path))

  const table = cellAncestors[1]
  const tableDescendant = cellAncestors[2]

  if (table === undefined || !isElementType('table', table[0]) || tableDescendant === undefined) {
    return
  }

  const [, tablePath] = table
  const { key, shiftKey } = event
  let direction: 'left' | 'right' | 'up' | 'down' | undefined

  if (event.metaKey && event.shiftKey) {
    const rowIndex = path.slice(-2)[0]
    const columnIndex = path.slice(-1)[0]

    switch (true) {
      case key === 'ArrowUp' && !isElementType('table-head', tableDescendant[0]): {
        if (rowIndex !== undefined && rowIndex) {
          event.preventDefault()
          TableOperations.moveRow(editor, tablePath, { from: rowIndex, to: rowIndex - 1 })
          return
        }
        break
      }
      case key === 'ArrowDown' && !isElementType('table-head', tableDescendant[0]): {
        if (rowIndex !== undefined) {
          event.preventDefault()
          TableOperations.moveRow(editor, tablePath, { from: rowIndex, to: rowIndex + 1 })
          return
        }
        break
      }
      case key === 'ArrowLeft': {
        if (columnIndex !== undefined) {
          event.preventDefault()
          TableOperations.moveColumn(editor, tablePath, { from: columnIndex, to: columnIndex - 1 })
          return
        }
        break
      }
      case key === 'ArrowRight': {
        if (columnIndex !== undefined) {
          event.preventDefault()
          TableOperations.moveColumn(editor, tablePath, { from: columnIndex, to: columnIndex + 1 })
          return
        }
        break
      }
    }
  }

  switch (key) {
    case 'ArrowUp': {
      direction = 'up'
      break
    }
    case 'ArrowDown': {
      direction = 'down'
      break
    }
    case 'Tab': {
      direction = shiftKey ? 'left' : 'right'
      break
    }
    default:
      break
  }

  if (direction !== undefined) {
    event.preventDefault()
    TableOperations.move(editor, tablePath, { direction })
  }
}
