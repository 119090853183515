import _ from 'lodash'
import { config } from 'sierra-client/config/global-config'
import { customThemeWithDefaults, migrateCustomThemes } from 'sierra-client/hooks/use-themes'
import { SingleThemeIcon } from 'sierra-client/views/flexible-content/editor/content-sidebar/multi-selection/components/single-theme-icon'
import { useMultiSelection } from 'sierra-client/views/flexible-content/editor/content-sidebar/multi-selection/hooks/use-multi-selection'
import { getDefaultCardTheme } from 'sierra-client/views/flexible-content/polaris-card-theme'
import { View } from 'sierra-ui/primitives'
import { palette, token } from 'sierra-ui/theming'
import { getTheme, Theme, useCardTheme, useLightTheme } from 'sierra-ui/theming/legacy-theme'
import { focusRing } from 'sierra-ui/utils'
import { FocusRing } from 'sierra-ui/utils/focus-ring'
import styled, { css } from 'styled-components'

/* Return a list of max. 4 fileIds and their theme */
const useThemesByFile = (): [string, Theme][] => {
  const { selection } = useMultiSelection()

  const organizationThemes = config.organization.settings.brand.customThemes
  const lightTheme = useLightTheme()
  const cardTheme = useCardTheme()

  return _.chain(selection)
    .mapValues(selection => {
      if (selection.file.theme === undefined) {
        return getDefaultCardTheme({ dataType: selection.file.data.type, lightTheme, cardTheme })
      } else {
        // There is an unfortunate inconsistency in the naming of the CustomTheme keys which requires the following transformation:
        const customThemes = customThemeWithDefaults(migrateCustomThemes(organizationThemes))
        const result = getTheme({ ...lightTheme, customThemes }, selection.file.theme)
        return result
      }
    })
    .toPairs()
    .take(4)
    .value()
}

const MultiThemeWrapper = styled.span.attrs({ focusRingSize: 'small' })<{ $isActive: boolean } & FocusRing>`
  width: 24px;
  height: 24px;
  padding: 2px;

  display: inline-grid;
  gap: 2px;
  align-content: center; /* Centre vertically if there are only 1 - 2 colour*/
  grid-template-columns: 8px 8px;
  grid-template-rows: 8px;
  grid-auto-rows: 8px;

  border: 1px solid ${token('border/strong')};
  border-radius: ${p => p.theme.borderRadius['size-6']};
  background-color: ${token('surface/default')};

  ${p => p.$isActive && focusRing}
`

const Color = styled.span<{ color: string }>`
  width: 8px;
  height: 8px;
  border-radius: 3px;
  background-color: ${p => (p.color === palette.primitives.white ? token('surface/default') : p.color)};
  ${p =>
    p.color === palette.primitives.white &&
    css`
      border: 1px solid ${token('border/strong')};
    `}
`

const Margin = styled(View).attrs({ paddingRight: '2' })``

const _MultiThemeIcon: React.FC<{ open: boolean; className?: string }> = ({ className, open }) => {
  const { areMultipleSelected } = useMultiSelection()
  const themesByFile = useThemesByFile()

  if (!areMultipleSelected) {
    return (
      <Margin>
        <SingleThemeIcon />
      </Margin>
    )
  }

  return (
    <Margin>
      <MultiThemeWrapper className={className} $isActive={open}>
        {themesByFile.map(([id, value]) => (
          <Color key={id} color={value.home.backgroundColor} />
        ))}
      </MultiThemeWrapper>
    </Margin>
  )
}

export const MultiThemeIcon = styled(_MultiThemeIcon)``
