import { WritableDraft, produce } from 'immer'
import { atom, useAtom } from 'jotai'
import React, { useCallback, useEffect, useRef, useState } from 'react'
import { getFlag } from 'sierra-client/config/global-config'
import { useEditorJotaiDocument } from 'sierra-client/editor/editor-jotai-context'
import { supportsTextToSpeech } from 'sierra-client/editor/text-to-speech/supports-text-to-speech'
import { useIsDebugMode } from 'sierra-client/hooks/use-is-debug-mode'
import { useRequiredRouterSelfPacedIds } from 'sierra-client/hooks/use-router-ids'
import { FCC } from 'sierra-client/types'
import { useEditorMode } from 'sierra-client/views/v3-author/editor-context/editor-context'
import { useAncestors } from 'sierra-client/views/v3-author/hooks'
import { isElementType } from 'sierra-client/views/v3-author/queries'
import { ScopedFileId } from 'sierra-domain/collaboration/types'
import { AbstractSlateNode, hasOnlyEmptyTextInNodes } from 'sierra-domain/slate-util'
import { CustomElement } from 'sierra-domain/v3-author'
import { Button } from 'sierra-ui/primitives'
import { useOnChanged } from 'sierra-ui/utils'
import { Descendant, Element } from 'slate'
import styled from 'styled-components'

function removeQuestionExplanations(element: AbstractSlateNode): AbstractSlateNode[] {
  if (
    Element.isElement(element) &&
    isElementType('question-card-multiple-choice-alternative-explanation', element)
  ) {
    return []
  } else if (Element.isElement(element)) {
    const newChildren = element.children.flatMap(removeQuestionExplanations)
    return [{ ...element, children: newChildren }]
  } else {
    return [element]
  }
}

function isTextToSpeechPossible(element: Element): boolean {
  const isEmpty = hasOnlyEmptyTextInNodes(removeQuestionExplanations(element))
  return !isEmpty
}

export const TextToSpeechHoverTarget = styled.span<{ shouldAlwaysBeVisible: boolean }>`
  user-select: none;
  position: absolute;
  top: 0;
  bottom: 0;
  margin-block: auto;

  display: flex;
  align-items: center;

  /* We should only show this element when hovering the paragraph.
     But, since it is placed outside the paragraph, we want to keep showing it
     even when the mouse leaves the paragraph as long as it still is close to the element.
     We achieve this by placing it right next to the paragraph with no gap. */
  left: -64px;
  width: 64px;

  opacity: ${p => (p.shouldAlwaysBeVisible ? 1 : 0)};
  &:hover {
    opacity: 1;
  }

  transition: opacity 0.2s ease-in;
`

type GlobalAudioState = { [audioId: string]: { state: 'playing' | 'paused' | 'pre-load'; focused: boolean } }
const globalPlayingAudio = atom<GlobalAudioState>({})

function getOrIntializeAudioState(
  globalAudioState: WritableDraft<GlobalAudioState>,
  audioId: string
): WritableDraft<GlobalAudioState[string]> {
  const state: GlobalAudioState[string] = globalAudioState[audioId] ?? { state: 'paused', focused: false }
  globalAudioState[audioId] = state
  return state
}

const StyledButton = styled(Button)`
  backdrop-filter: blur(40px);
`

function allNodes(document: Descendant[]): Descendant[] {
  const nodes: Descendant[] = []
  for (const node of document) {
    nodes.push(node)
    if (Element.isElement(node)) {
      nodes.push(...allNodes(node.children))
    }
  }
  return nodes
}

function findNextAudioId(document: Descendant[], elementId: string): string | undefined {
  let hasFoundCurrentNode = false
  for (const node of allNodes(document)) {
    if (!Element.isElement(node)) continue

    if (node.id === elementId) {
      hasFoundCurrentNode = true
      continue
    }

    if (hasFoundCurrentNode) {
      const support = supportsTextToSpeech(node)
      const isPossible = support && isTextToSpeechPossible(node)
      if (isPossible) return node.id
    }
  }
}

const _TextToSpeech: React.FC<{ elementId: string }> = ({ elementId }) => {
  const ids = useRequiredRouterSelfPacedIds()
  const contentId = ids.flexibleContentId
  const fileId =
    ids.fileId === 'next' ? 'next' : ids.fileId !== undefined ? ScopedFileId.extractId(ids.fileId) : undefined

  const document = useEditorJotaiDocument()
  const nextAudioId = findNextAudioId(document, elementId)

  const [isLoadingAudio, setIsDownloadingAudio] = useState(false)
  const [globalAudioState, setGlobalAudioState] = useAtom(globalPlayingAudio)

  const playState = globalAudioState[elementId]?.state ?? 'idle'

  const audioRef = useRef<HTMLAudioElement>(null)

  const play = useCallback(
    (audioId: string) => {
      setGlobalAudioState(previous =>
        produce(previous, draft => {
          // Stop all other playback
          for (const audioId in draft) {
            const audio = getOrIntializeAudioState(draft, audioId)
            audio.state = audio.state === 'playing' ? 'paused' : audio.state
            audio.focused = false
          }

          // Start the current playback
          const audio = getOrIntializeAudioState(draft, audioId)
          audio.state = 'playing'
          audio.focused = true
        })
      )
    },
    [setGlobalAudioState]
  )

  const pause = useCallback(
    (audioId: string) => {
      setGlobalAudioState(previous =>
        produce(previous, draft => {
          // Pause current playback
          const audio = getOrIntializeAudioState(draft, audioId)
          audio.state = 'paused'
        })
      )
    },
    [setGlobalAudioState]
  )

  const preLoad = useCallback(
    (audioId: string) => {
      setGlobalAudioState(previous =>
        produce(previous, draft => {
          // Pause current playback
          const audio = getOrIntializeAudioState(draft, audioId)
          audio.state = 'pre-load'
        })
      )
    },
    [setGlobalAudioState]
  )

  // Sync `playState` with the <audio> element
  useEffect(() => {
    if (playState === 'playing') {
      void audioRef.current?.play()

      // Preload the track for the next element so that playback is seamless
      if (nextAudioId !== undefined) preLoad(nextAudioId)
    } else if (playState === 'paused') {
      void audioRef.current?.pause()
    }
  }, [nextAudioId, playState, preLoad])

  const focus = globalAudioState[elementId]?.focused ?? false
  useOnChanged((wasFocused, isFocused) => {
    if (wasFocused === true && isFocused === false) {
      // Restart the audio track when an element loses focus
      // Otherwise the audio may start from the middle of the track
      // when a whole lesson is being listened to in order.
      const audio = audioRef.current
      if (audio) audio.currentTime = 0
    }
  }, focus)

  const isDebugMode = useIsDebugMode()

  return (
    <TextToSpeechHoverTarget
      shouldAlwaysBeVisible={playState === 'playing' || isDebugMode}
      contentEditable={false}
    >
      {/* Empty span to satisfy slate */}
      <span />
      <StyledButton
        onClick={() => {
          if (playState === 'playing') pause(elementId)
          else {
            play(elementId)
          }
        }}
        loading={isLoadingAudio}
        decoratorAvoidOffset={!isDebugMode}
        icon={
          isLoadingAudio
            ? undefined
            : playState === 'playing'
              ? 'pause--filled'
              : playState === 'paused'
                ? 'play--filled'
                : 'headphones'
        }
        variant='ghost'
      >
        {isDebugMode ? playState : ''}
      </StyledButton>

      {playState !== 'idle' && (
        <audio
          // eslint-disable-next-line react/forbid-dom-props
          style={{ display: 'none' }}
          ref={audioRef}
          controls={false}
          autoPlay={playState === 'playing'}
          onLoadStart={() => setIsDownloadingAudio(true)}
          onLoadedData={() => setIsDownloadingAudio(false)}
          onEnded={() => {
            // Pause the current audio playback and reset it to the start of the track
            pause(elementId)
            // Restart when finished
            const audio = audioRef.current
            if (audio) audio.currentTime = 0

            // Continue to the next audio element if it exists
            if (nextAudioId !== undefined) play(nextAudioId)
          }}
        >
          <source src={`/x-realtime/content/listen/${contentId}/${fileId}/${elementId}.mp3`} />
        </audio>
      )}
    </TextToSpeechHoverTarget>
  )
}

/**
 * Ensure that no parent of the current element supports text to speech, in these cases we do not want to nest them.
 */
const TextToSpeechParentGuard: FCC<{ element: CustomElement }> = ({ element, children }) => {
  const anyParentHasTextToSpeech = useAncestors({ nodeId: element.id }).some(([element]) =>
    supportsTextToSpeech(element)
  )

  if (anyParentHasTextToSpeech) return null
  else return <>{children}</>
}

export const TextToSpeech: React.FC<{ element: CustomElement }> = ({ element }) => {
  const isPossible = isTextToSpeechPossible(element)
  const isSelfPaced = useEditorMode() === 'self-paced'

  const isDebugMode = useIsDebugMode()
  const isFlagEnabled = getFlag('text-to-speech')

  if ((isDebugMode || isFlagEnabled) && isPossible && isSelfPaced)
    return (
      <TextToSpeechParentGuard element={element}>
        <_TextToSpeech elementId={element.id} />
      </TextToSpeechParentGuard>
    )
  else return null
}
