import { createFileRoute } from '@tanstack/react-router'

import { PageIdentifier } from 'sierra-client/layout/sana-page'
import { useSettingsPageEnabled } from 'sierra-client/views/manage/permissions/use-settings-page-enabled'
import { SettingsBrandingView } from 'sierra-client/views/manage/settings/settings-branding'
import { ManagePageWrapper } from 'sierra-client/views/manage/utils/manage-page-wrapper'

function SettingsBrandingPage(): JSX.Element | null {
  const hasSettingsAccess = useSettingsPageEnabled()

  if (hasSettingsAccess !== true) {
    return null
  }

  return (
    <ManagePageWrapper pageIdentifier={PageIdentifier.ManageSettings()}>
      <SettingsBrandingView />
    </ManagePageWrapper>
  )
}

const RouteComponent = SettingsBrandingPage

export const Route = createFileRoute('/settings/branding')({
  component: RouteComponent as React.FC,
})
