import { EditableContent } from 'sierra-domain/api/editable-content'
import { LearnEntity } from 'sierra-domain/api/entities'
import { ScopedCreateContentId, ScopedSelfPacedContentId } from 'sierra-domain/collaboration/types'
import { FileId } from 'sierra-domain/flexible-content/identifiers'
import { DateFromISOString } from 'sierra-domain/zod-extensions'
import { z } from 'zod'

const zScopedCreateContentId = z
  .string()
  .transform(str => str as ScopedCreateContentId)
  .refine(id => ScopedCreateContentId.tryExtractId(id as ScopedCreateContentId) !== null, {
    message: 'Invalid ScopedCreateContentId',
  })

const zScopedSelfPacedContentId = z
  .string()
  .transform(str => str as ScopedSelfPacedContentId)
  .refine(id => ScopedSelfPacedContentId.tryExtractId(id) !== null, {
    message: 'Invalid ScopedSelfPacedContentId',
  })

export const RecentlyVisitedEntry = z.discriminatedUnion('type', [
  z.object({
    type: z.literal('editor'),
    scopedId: zScopedCreateContentId,
    date: DateFromISOString,
    fileId: FileId.optional(),
  }),
  z.object({
    type: z.literal('self-paced'),
    scopedId: zScopedSelfPacedContentId,
    date: DateFromISOString,
    fileId: FileId.optional(),
  }),
])

export type RecentlyVisitedEntry = z.infer<typeof RecentlyVisitedEntry>

export type RecentlyVisitedContent = {
  type: 'recently-visited-content'
  entry: RecentlyVisitedEntry
  href: string
  id: string
} & ({ content: EditableContent; kind: 'editable' } | { content: LearnEntity; kind: 'learner' })
