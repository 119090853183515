import { DateTime } from 'luxon'
import React, { useState } from 'react'
import { queryClient } from 'sierra-client/api/query-client'
import {
  ContentScrollView,
  ContentWrapperView,
  HeadingWrapperView,
  WrapperView,
} from 'sierra-client/features/external-trainings/atoms'
import { getUserTranscriptQuery } from 'sierra-client/features/external-trainings/queries'
import { useTranslation } from 'sierra-client/hooks/use-translation'
import { useTypedMutation } from 'sierra-client/state/api'
import { ZodRouteInput } from 'sierra-domain/api/types'
import { UserId } from 'sierra-domain/api/uuid'
import { XRealtimeUserAddExternalTrainings } from 'sierra-domain/routes'
import { assertNever, isDefined, isEmptyArray, isNonEmptyString, isNotDefined } from 'sierra-domain/utils'
import { FormElement, InputDatePicker, LabelMenuItem, NumberInput, TruncatedText } from 'sierra-ui/components'
import { Button, Heading, IconButton, InputPrimitive, Text, View } from 'sierra-ui/primitives'
import { SingleSelectDropdown } from 'sierra-ui/primitives/menu-dropdown'

type MenuItemDurationType = 'minutes' | 'hours' | 'days'

type FormStateTraining = {
  title?: string
  provider?: string
  date?: DateTime
  duration?: { type?: MenuItemDurationType; value?: number }
}

const INITIAL_FORM_STATE = {
  title: '',
  provider: '',
  date: DateTime.now(),
  duration: { type: 'hours', value: 1 },
} satisfies FormStateTraining

const DURATION_TYPE_MENU_ITEMS = [
  { type: 'label', id: 'minutes', label: 'Minutes' },
  { type: 'label', id: 'hours', label: 'Hours' },
  { type: 'label', id: 'days', label: 'Days' },
] as const satisfies LabelMenuItem<MenuItemDurationType>[]

const TITLE_MAX_LENGTH = 255 as const

const getDurationInMinutesFromDuration = (duration: FormStateTraining['duration']): number | undefined => {
  if (isNotDefined(duration)) return undefined
  if (isNotDefined(duration.type)) return undefined
  if (isNotDefined(duration.value)) return undefined

  switch (duration.type) {
    case 'days':
      return duration.value * 60 * 24
    case 'hours':
      return duration.value * 60
    case 'minutes':
      return duration.value
    default:
      assertNever(duration.type)
  }
}

type TrainingData = ZodRouteInput<typeof XRealtimeUserAddExternalTrainings>['trainingsData']

const getTrainingDataFromFormState = (formState: FormStateTraining[]): TrainingData => {
  const maybeTrainingData = formState.map(state => {
    const title = state.title
    if (!isNonEmptyString(title)) return
    if (title.length > TITLE_MAX_LENGTH) return

    const provider = state.provider
    if (!isNonEmptyString(provider)) return

    const date = state.date?.toSQLDate()
    if (!isNonEmptyString(date)) return

    const durationInMinutes = getDurationInMinutesFromDuration(state.duration)
    if (isNotDefined(durationInMinutes)) return

    return { title, provider, date, durationInMinutes }
  })

  const trainingData = maybeTrainingData.filter(isDefined)
  return trainingData
}

export const AddTrainingPanelContent: React.FC<{ userId: UserId; closePanel: () => void }> = ({
  userId,
  closePanel,
}) => {
  const { t } = useTranslation()
  const [formState, setFormState] = useState<FormStateTraining[]>([{ ...INITIAL_FORM_STATE }])

  const trainingsData = getTrainingDataFromFormState(formState)
  const isFormSubmitDisabled = trainingsData.length !== formState.length

  const userTranscriptQuery = getUserTranscriptQuery({ userId })
  const addTrainingMutation = useTypedMutation(XRealtimeUserAddExternalTrainings, {
    onSuccess: async () => {
      await queryClient.invalidateQueries({ ...userTranscriptQuery, refetchType: 'all' })
      closePanel()
    },
    onError: async () => {
      await queryClient.invalidateQueries(userTranscriptQuery)
    },
  })

  return (
    <WrapperView>
      <ContentScrollView>
        <HeadingWrapperView>
          <Heading size='h5' bold>
            {t('add-external-training.title')}
          </Heading>
          <Text size='regular' color={'foreground/secondary'}>
            {t('add-external-training.description')}
          </Text>
        </HeadingWrapperView>

        <ContentWrapperView>
          <View direction='column' gap='32'>
            {formState.map((state, index) => {
              const selectedItem = DURATION_TYPE_MENU_ITEMS.find(
                menuItem => menuItem.id === state.duration?.type
              )

              return (
                <View key={index} direction='column' gap='16'>
                  <View>
                    <TruncatedText lines={1} bold color='foreground/muted'>
                      {t('add-external-training.training-title-number', { number: index + 1 })}
                    </TruncatedText>
                    {formState.length > 1 && (
                      <IconButton
                        iconId='trash-can'
                        variant='transparent'
                        tooltip={t('dictionary.remove')}
                        size='x-small'
                        onClick={() => {
                          const newFormState = [...formState.slice(0, index), ...formState.slice(index + 1)]
                          setFormState(newFormState)
                        }}
                      />
                    )}
                  </View>
                  <View>
                    <FormElement
                      label={t('add-external-training.form-title')}
                      isError={(state.title?.length ?? 0) > TITLE_MAX_LENGTH}
                    >
                      <InputPrimitive
                        placeholder={t('add-external-training.form-title-placeholder')}
                        value={state.title ?? ''}
                        onChange={event => {
                          setFormState(previousFormState =>
                            previousFormState.map((state, stateIndex) =>
                              stateIndex === index ? { ...state, title: event.target.value } : state
                            )
                          )
                        }}
                      />
                    </FormElement>
                    <FormElement label={t('add-external-training.form-provider')}>
                      <InputPrimitive
                        placeholder={t('add-external-training.form-provider-placeholder')}
                        value={state.provider ?? ''}
                        onChange={event => {
                          setFormState(previousFormState =>
                            previousFormState.map((state, stateIndex) =>
                              stateIndex === index ? { ...state, provider: event.target.value } : state
                            )
                          )
                        }}
                      />
                    </FormElement>
                  </View>
                  <View>
                    <FormElement label={t('dictionary.date')} isError={isNotDefined(state.date)}>
                      <InputDatePicker
                        value={state.date}
                        onChange={date => {
                          setFormState(previousFormState =>
                            previousFormState.map((state, stateIndex) =>
                              stateIndex === index ? { ...state, date } : state
                            )
                          )
                        }}
                      />
                    </FormElement>
                    <FormElement
                      label={t('dictionary.duration')}
                      isError={isNotDefined(state.duration?.value)}
                    >
                      <View>
                        <NumberInput
                          min={1}
                          onChange={number => {
                            setFormState(previousFormState =>
                              previousFormState.map((state, stateIndex) =>
                                stateIndex === index
                                  ? { ...state, duration: { ...state.duration, value: number ?? undefined } }
                                  : state
                              )
                            )
                          }}
                          value={state.duration?.value ?? null}
                        />
                        <SingleSelectDropdown<MenuItemDurationType>
                          selectedItem={selectedItem}
                          onSelect={menuItem => {
                            setFormState(previousFormState =>
                              previousFormState.map((state, stateIndex) =>
                                stateIndex === index
                                  ? { ...state, duration: { ...state.duration, type: menuItem.id } }
                                  : state
                              )
                            )
                          }}
                          menuItems={DURATION_TYPE_MENU_ITEMS}
                        />
                      </View>
                    </FormElement>
                  </View>
                </View>
              )
            })}
          </View>
          <View>
            <IconButton
              iconId='add'
              variant='transparent'
              onClick={() => {
                setFormState(formState => [...formState, { ...INITIAL_FORM_STATE }])
              }}
            />
            <Text color='foreground/muted'>{t('add-external-training.add-training-button')}</Text>
          </View>
        </ContentWrapperView>
      </ContentScrollView>
      <View alignSelf='flex-end' direction='column'>
        {addTrainingMutation.isError && (
          <Text color='destructive/background'>{t('dictionary.something-went-wrong')}</Text>
        )}
        <View alignSelf='flex-end'>
          <Button onClick={closePanel} variant='secondary'>
            {t('modal.cancel')}
          </Button>
          <Button
            disabled={isFormSubmitDisabled}
            loading={addTrainingMutation.isPending}
            onClick={() => {
              if (isEmptyArray(trainingsData)) return
              addTrainingMutation.mutate({ userId, trainingsData })
            }}
          >
            {t('modal.save')}
          </Button>
        </View>
      </View>
    </WrapperView>
  )
}
