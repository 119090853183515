import React, { useCallback, useState } from 'react'
import { Absolute } from 'sierra-client/views/manage/programs/enrollment-rules/modals/start-date-selector/absolute'
import {
  StartDateSelectorProps,
  getCurrentDateString,
} from 'sierra-client/views/manage/programs/enrollment-rules/modals/start-date-selector/common'
import { Now } from 'sierra-client/views/manage/programs/enrollment-rules/modals/start-date-selector/now'
import { Relative } from 'sierra-client/views/manage/programs/enrollment-rules/modals/start-date-selector/relative'
import { EnrollmentStartTime } from 'sierra-domain/api/manage'
import { View } from 'sierra-ui/primitives'
import { Checkbox } from 'sierra-ui/primitives/form/checkbox'
import styled from 'styled-components'

export const useStartDateSelector = (
  initialTimeState?: EnrollmentStartTime | undefined
): StartDateSelectorProps => {
  const [startDate, setStartDate] = useState<StartDateSelectorProps['startDate']>(
    initialTimeState ?? {
      type: 'now',
    }
  )

  const resetStartDate = useCallback(() => {
    setStartDate(initialTimeState ?? { type: 'now' })
  }, [initialTimeState])

  return {
    startDate,
    setStartDate,
    resetStartDate,
  }
}

const Radiobutton = styled(Checkbox).attrs({ radio: true })``

const OptionList = styled(View).attrs({ direction: 'column', gap: '16' })``

const Option = styled(View).attrs({ direction: 'row', gap: '8' })`
  min-height: 42px;
`

export const StartDateSelector: React.FC<StartDateSelectorProps> = props => {
  const {
    startDate: { type },
    setStartDate,
    disabled = false,
  } = props

  const setNow = (): void => {
    if (!disabled) setStartDate({ type: 'now' })
  }

  const setRelative = (): void => {
    if (disabled || type === 'relative') {
      return // Don't reset
    }

    setStartDate({
      type: 'relative',
      attribute: { type: 'user.createdAt' },
      offset: { type: 'days', days: 0 },
    })
  }

  const setAbsolute = (): void => {
    if (disabled || type === 'absolute') {
      return // Don't reset
    }

    setStartDate(getCurrentDateString())
  }

  return (
    <OptionList>
      <Option>
        <Radiobutton disabled={disabled} checked={type === 'now'} onCheckedChange={setNow} />
        <Now onClick={setNow} />
      </Option>
      <Option>
        <Radiobutton disabled={disabled} checked={type === 'absolute'} onCheckedChange={setAbsolute} />
        <Absolute onClick={setAbsolute} {...props} />
      </Option>
      <Option>
        <Radiobutton disabled={disabled} checked={type === 'relative'} onCheckedChange={setRelative} />
        <Relative onClick={setRelative} {...props} />
      </Option>
    </OptionList>
  )
}
