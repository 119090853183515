import { useState } from 'react'
import { useHomeworkDetailsQuery, useHomeworkQuery } from 'sierra-client/api/hooks/use-homework'
import { DetailsHeader } from 'sierra-client/views/manage/components/details-header'
import { ManageHeadline } from 'sierra-client/views/manage/components/layout/manage-headline'
import { HomeworkSubmissionsTable } from 'sierra-client/views/manage/homeworks/manage-homework/submissions-table'
import { ManageHomeworksModal } from 'sierra-client/views/manage/homeworks/manage-homeworks-modal'
import { HomeworkId } from 'sierra-domain/api/uuid'
import { LoadingSpinner, Text, View } from 'sierra-ui/primitives'

export const ManageHomework: React.FC<{ homeworkId: HomeworkId }> = ({ homeworkId }) => {
  const [reviewSubmissionId, setReviewSubmissionId] = useState<string>()
  const homeworkQuery = useHomeworkQuery({ homeworkId })
  const detailsQuery = useHomeworkDetailsQuery({ homeworkId })

  const courseTitle = homeworkQuery.data?.data.courseData.courseTitle

  return (
    <View direction='column' grow>
      <DetailsHeader backlink={{ href: '/manage/exercises', label: 'manage.backlinks--exercises' }} />

      <View direction='column' grow>
        <ManageHeadline>{courseTitle}</ManageHeadline>
        <View direction='column'>
          {detailsQuery.isSuccess ? (
            <HomeworkSubmissionsTable
              data={detailsQuery.data}
              setReviewSubmissionId={setReviewSubmissionId}
            />
          ) : detailsQuery.isError ? (
            <View justifyContent='center' padding='large'>
              <Text size='small' color='grey40'>
                Something went wrong
              </Text>
            </View>
          ) : (
            <View justifyContent='center' padding='large'>
              <LoadingSpinner />
            </View>
          )}
        </View>
      </View>

      <ManageHomeworksModal
        data={
          reviewSubmissionId !== undefined
            ? {
                reviewSubmissionId,
              }
            : undefined
        }
        onClose={() => setReviewSubmissionId(undefined)}
      />
    </View>
  )
}
