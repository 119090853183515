import { motion } from 'framer-motion'
import { useAtomValue } from 'jotai'
import React from 'react'
import {
  collapseIconVariants,
  iconVariants,
} from 'sierra-client/features/collapsable-sidebar/animation-config'
import { collapsableSidebarStateAtom } from 'sierra-client/features/collapsable-sidebar/atoms'
import {
  ButtonStyles,
  ButtonStylesProps,
  usePrimitiveButtonPseudoStyles,
} from 'sierra-ui/primitives/button/button'
import { token } from 'sierra-ui/theming'
import styled from 'styled-components'

const Wrapper = styled(motion.div)`
  display: flex;
  flex-grow: 1;
  position: relative;
  background-color: ${token('org/primary')};
  border-radius: 10px;
`

const StyledButton = styled(motion.button)<ButtonStylesProps>`
  ${ButtonStyles};
  display: flex;
  flex-grow: 1;
  position: relative;
  max-width: 177px;
`

const WideModeContents = styled(motion.div)`
  width: 100%;
  height: fit-content;
  position: absolute;
  left: 0;
  bottom: 0;
`

export const AddCardComponent = React.forwardRef<HTMLDivElement, { addCardButton: React.ReactNode }>(
  ({ addCardButton }, ref) => {
    const sidebarState = useAtomValue(collapsableSidebarStateAtom)
    const pseudoStyles = usePrimitiveButtonPseudoStyles('primary')
    return (
      <div ref={ref}>
        <Wrapper initial={sidebarState} animate={sidebarState}>
          <StyledButton $pseudoStyles={pseudoStyles} variants={iconVariants} $grow>
            +
          </StyledButton>
          <WideModeContents variants={collapseIconVariants}>{addCardButton}</WideModeContents>
        </Wrapper>
      </div>
    )
  }
)
