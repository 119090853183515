import _ from 'lodash'
import * as Commands from 'sierra-client/views/v3-author/command'
import { convertListItemToText, headingLevelToListItemLevel } from 'sierra-client/views/v3-author/list/utils'
import { isElementType, nodeAtPath, parentType } from 'sierra-client/views/v3-author/queries'
import {
  isValidParagraphChild,
  unwrapNonParagraphChildren,
  unwrapNonParagraphChildrenAtPath,
} from 'sierra-client/views/v3-author/unwrap-non-paragraph-children'
import { textInNodes } from 'sierra-domain/slate-util'
import { createListItem } from 'sierra-domain/v3-author/create-blocks'
import { Editor, Element, Node, Path, Range, Transforms } from 'slate'

const debug = (...messages: unknown[]): void => console.debug('[withList]', ...messages)

const calculateOrdinals = (indents: number[]): number[] => {
  let prevIndent = indents[0] ?? 0
  const counters = Array.from<number>({ length: prevIndent + 1 }).fill(0)

  const ordinalNumbers = []

  for (const indent of indents) {
    if (indent > prevIndent) {
      for (let i = 0; i < indent - prevIndent; i++) {
        counters.push(0) // Add new counter level
      }
    } else if (indent < prevIndent) {
      for (let i = 0; i < prevIndent - indent; i++) {
        counters.pop() // Remove counter for that level
      }
    }

    counters[counters.length - 1]!++
    ordinalNumbers.push(counters[counters.length - 1]!)
    prevIndent = indent
  }

  return ordinalNumbers
}

export const withLists = (editor: Editor): Editor => {
  const { normalizeNode, deleteBackward } = editor

  editor.normalizeNode = entry => {
    const [node, path] = entry

    if (Element.isElement(node) && (node.type === 'numbered-list' || node.type === 'bulleted-list')) {
      // Make sure all child nodes are list-items
      for (const [child, childPath] of Node.children(editor, path)) {
        const isListItem = Element.isElement(child) && child.type === 'list-item'

        if (!isListItem) {
          //If a child node is a bulleted or numbered list, unwrap the list
          if (
            Element.isElement(child) &&
            (child.type === 'numbered-list' || child.type === 'bulleted-list')
          ) {
            return Transforms.unwrapNodes(editor, {
              at: childPath,
            })
          }

          const listItemLevel =
            Element.isElement(child) && child.type === 'heading'
              ? headingLevelToListItemLevel(child.level)
              : Element.isElement(child) && child.type === 'paragraph' && textInNodes([child])[0] !== ''
                ? child.level
                : undefined

          debug('Converting non-list-item node from list')
          return Transforms.wrapNodes(editor, createListItem({ level: listItemLevel }), { at: childPath })
        }
      }
    }

    if (Element.isElement(node) && node.type === 'numbered-list') {
      // Make sure all have the correct ordinal
      const listItems = Array.from(node.children)
      const indents = Array.from(listItems).map(listItem => listItem.indent ?? 0)
      const ordinals = calculateOrdinals(indents)

      // Set the ordinals for each list item once they update
      const ordinalOperations: (() => void)[] = listItems.flatMap((listItem, index) =>
        listItem.ordinal !== ordinals[index]
          ? [() => Transforms.setNodes(editor, { ordinal: ordinals[index] }, { at: path.concat(index) })]
          : []
      )
      if (ordinalOperations.length > 0) {
        debug('Updating list ordinals')
        return ordinalOperations.forEach(op => op())
      }
    }

    // list-item nodes must be wrapped in a bulleted- or numbered-list
    if (isElementType('list-item', node)) {
      const parent = parentType(editor, path)
      const isInList = parent === 'bulleted-list' || parent === 'numbered-list'
      if (!isInList) {
        debug(`Detected list item outside of list. Converting to a paragrpah`)
        Transforms.unwrapNodes(editor, { at: path, mode: 'all', voids: true })
        return Transforms.wrapNodes(editor, convertListItemToText(node), { at: path })
      }

      for (const [child] of Array.from(Node.children(editor, path))) {
        const isValidType = isValidParagraphChild(child)
        if (!isValidType) {
          debug(`Unwrapping unexpected element ${JSON.stringify(child)} in list-item at`, path)
          return unwrapNonParagraphChildren(editor, entry)
        }
      }
    }

    // Merge adjacent lists
    if (Element.isElement(node) && (node.type === 'numbered-list' || node.type === 'bulleted-list')) {
      // Merge previous list
      if (_.last(path) !== 0) {
        const previousPath = Path.previous(path)
        const previousNode = nodeAtPath(editor.children, previousPath)
        if (
          Element.isElement(previousNode) &&
          previousNode.type === node.type &&
          previousNode.id !== node.id
        ) {
          return Transforms.mergeNodes(editor, { at: path })
        }
      }
      // Merge next list
      const nextPath = Path.next(path)
      const nextNode = nodeAtPath(editor.children, nextPath)
      if (Element.isElement(nextNode) && nextNode.type === node.type && nextNode.id !== node.id) {
        return Transforms.mergeNodes(editor, { at: nextPath })
      }
    }

    normalizeNode(entry)
  }

  editor.deleteBackward = unit => {
    const { selection } = editor

    // If the cursor is at the start of the first list-item in a list,
    // deleting backward should transform the current list item into a paragraph and split out the rest of the list.
    if (selection && Range.isCollapsed(selection)) {
      const [listItem, listItemPath] = Editor.node(editor, Path.parent(selection.anchor.path))
      const [list, listPath] = Editor.node(editor, Path.parent(listItemPath))

      if (!(Element.isElement(list) && (list.type === 'bulleted-list' || list.type === 'numbered-list')))
        return deleteBackward(unit)
      if (!(Element.isElement(listItem) && listItem.type === 'list-item')) return deleteBackward(unit)

      const listLength = Array.from(list.children).length
      const isFirstListItem = _.last(listItemPath) === 0
      const isLastListItem = _.last(listItemPath) === listLength - 1
      const isCursorAtTheStartOfTheNode =
        selection.anchor.offset === 0 && selection.focus.offset === 0 && _.last(selection.focus.path) === 0
      if (!isCursorAtTheStartOfTheNode) return deleteBackward(unit)

      debug('Deleted backwards at the start of a list item. Converting it into a paragraph.')

      const path = listPath

      // We dont want to normalize here since merginf adjacent lists interferes with it.
      Editor.withoutNormalizing(editor, () => {
        // Split after if not last list item
        if (!isLastListItem) {
          Transforms.splitNodes(editor, { at: Path.next(listItemPath) })
        }

        // Split before
        Transforms.splitNodes(editor, { at: listItemPath })

        Transforms.wrapNodes(editor, convertListItemToText(listItem), { at: Path.next(path) })

        unwrapNonParagraphChildrenAtPath(editor, Path.next(path))

        if (isFirstListItem) {
          Transforms.removeNodes(editor, { at: path })
        }
      })

      return
    }

    deleteBackward(unit)
  }

  return editor
}

export const untoggleList = (editor: Editor): void => {
  if (Commands.isBlockActive(editor, 'numbered-list')) {
    Commands.toggleBlock(editor, 'numbered-list')
  } else if (Commands.isBlockActive(editor, 'bulleted-list')) {
    Commands.toggleBlock(editor, 'bulleted-list')
  }
}
