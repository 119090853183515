import { useParams } from '@tanstack/react-router'
import { useEffect } from 'react'
import { useActiveRouteId } from 'sierra-client/hooks/router/use-active-route'
import { getGlobalRouter } from 'sierra-client/router'
import { selectCurrentCourse } from 'sierra-client/state/content/selectors'
import { useSelector } from 'sierra-client/state/hooks'
import { SelfPacedContentId } from 'sierra-domain/api/nano-id'

export const V4Redirect = (): null => {
  const routeId = useActiveRouteId()
  const courseId = useParams({ strict: false, select: s => s.courseId })
  const course = useSelector(selectCurrentCourse)

  useEffect(() => {
    if (
      routeId.startsWith('/course/') === true &&
      courseId !== undefined &&
      course !== undefined &&
      course.id === courseId &&
      course.type === 'native:self-paced'
    ) {
      void getGlobalRouter().navigate({
        to: '/s/$flexibleContentId',
        params: { flexibleContentId: courseId as SelfPacedContentId },
      })
    }
  }, [courseId, routeId, course])

  return null
}
