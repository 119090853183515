import { first } from 'lodash'
import { DateTime, Duration } from 'luxon'
import {
  ContentKind,
  ExerciseState,
  LiveSessionLocation as LiveSessionLocationGQL,
} from 'sierra-client/api/graphql/gql/graphql'
import { useGetFormattedTime } from 'sierra-client/core/format'
import { gqlGradeStatusToGradeStatus } from 'sierra-client/features/homework'
import { useTranslation } from 'sierra-client/hooks/use-translation'
import { prefixWithProgramAndOrPath } from 'sierra-client/views/learner/path/get-href-to-course'
import {
  ScheduledLiveSession,
  StepLiveSession,
  StepUpcomingLiveSession,
} from 'sierra-client/views/learner/program/types'
import {
  EnrollmentData,
  EnrollmentStepType,
  ProgramInfoStepType,
  isEmailEnrollmentStep,
} from 'sierra-client/views/learner/program/utils/step-graphql'
import { EmailStepShape } from 'sierra-client/views/learner/program/utils/types'
import {
  InternalLinkURL,
  LinkURL,
  Linkable,
  continueUrl,
  isExternalLinkURL,
} from 'sierra-client/views/workspace/utils/urls'
import { CourseKind } from 'sierra-domain/api/common'
import { GradeStatus, HomeworkState } from 'sierra-domain/api/homework'
import { FileId } from 'sierra-domain/api/nano-id'
import { AssetContext } from 'sierra-domain/asset-context'
import { LiveSessionLocation } from 'sierra-domain/content/session'
import { getPrioritizedHomework } from 'sierra-domain/homework/utils'
import { assert, assertNever, isDefined, isNotDefined } from 'sierra-domain/utils'
import { TokenOrColor } from 'sierra-ui/color/token-or-color'
import { Text } from 'sierra-ui/primitives'

type DateString = string
const calculateDateDifference = (dateString: string): number => {
  const today: Date = new Date()
  today.setHours(0, 0, 0, 0)

  const givenDate: Date = new Date(dateString + 'T00:00:00')
  givenDate.setHours(0, 0, 0, 0)

  const difference: number = Math.floor((givenDate.getTime() - today.getTime()) / (1000 * 60 * 60 * 24))
  return difference
}

const agoMoreThan = 5
const agoLessThan = -5

export const Utils: React.FC<{
  date: DateString
  color?: TokenOrColor
}> = ({ date, color }) => {
  const { t } = useTranslation()
  const difference = calculateDateDifference(date)

  if (difference === 0) {
    return (
      <Text bold size='small' color='destructive/background'>
        {t('program-overview.details.today')}
      </Text>
    )
  } else if (difference > agoMoreThan) {
    return (
      <Text size='small' color={color ?? 'LEGACY_DEFAULT_TEXT_COLOR_REPLACE_ASAP'}>
        {date}
      </Text>
    )
  } else if (difference > 0) {
    return (
      <Text bold size='small' color={color ?? 'LEGACY_DEFAULT_TEXT_COLOR_REPLACE_ASAP'}>
        {t('program-overview.details.in', { count: difference })}
      </Text>
    )
  } else if (difference < agoLessThan) {
    return (
      <Text bold size='small' color='destructive/background'>
        {date}
      </Text>
    )
  } else if (difference < 0) {
    return (
      <Text bold size='small' color='destructive/background'>
        {t('program-overview.details.ago', { count: Math.abs(difference) })}
      </Text>
    )
  }

  return <></>
}

export const useFormatDuration = (isoDuration: string, remaining: boolean = false): string => {
  const timeLeftInSeconds = Math.round(Duration.fromISO(isoDuration).toMillis() / 1000)
  return useGetFormattedTime(timeLeftInSeconds, remaining)
}

export const getLiveSessionForStep = (
  upcomingSelfEnrollmentLiveSessions: StepUpcomingLiveSession[],
  scheduledLiveSessions: ScheduledLiveSession[],
  filterEnrolled: boolean
): StepLiveSession | undefined => {
  const selfEnrolled = filterEnrolled
    ? upcomingSelfEnrollmentLiveSessions.filter(s => s.isEnrolled)
    : upcomingSelfEnrollmentLiveSessions
  const selfEnrolledSessions = selfEnrolled.map(s => s.liveSession)
  const sortedClosestInTime = [...selfEnrolledSessions, ...scheduledLiveSessions].sort((a, b) => {
    return a.startTime < b.startTime ? -1 : 1
  })

  return first(sortedClosestInTime)
}

export function getStepContentType<TStep extends EnrollmentStepType>(
  step: TStep
): TStep extends EmailStepShape ? 'email' : CourseKind | 'path' | 'program'
export function getStepContentType(step: EnrollmentStepType): 'email' | CourseKind | 'path' | 'program' {
  if (isEmailEnrollmentStep(step)) {
    return 'email'
  }

  const typeMap = {
    LinkCourse: 'link',
    LinkedInCourse: 'linkedin',
    ScormCourse: 'scorm',
    NativeSelfPacedCourse: 'native:self-paced',
    NativeLiveCourse: 'native:live',
    NativeCourseGroup: 'native:course-group',
    ScormCourseGroup: 'scorm:course-group',
    Path: 'path',
    Program: 'program',
    NativeEventGroup: 'native:event-group',
  } as const

  return typeMap[step.content.__typename]
}

export const courseTypeNameToCourseKind: Record<ContentKind, CourseKind> = {
  LINK: 'link',
  LINKEDIN: 'linkedin',
  SCORM: 'scorm',
  NATIVE_SELF_PACED: 'native:self-paced',
  NATIVE_LIVE: 'native:live',
  NATIVE_COURSE_GROUP: 'native:course-group',
  SCORM_COURSE_GROUP: 'scorm:course-group',
  NATIVE_EVENT_GROUP: 'native:event-group',
}

export const programInfoStepAsCourseKind = (
  programInfoStep: ProgramInfoStepType
): CourseKind | 'path' | 'program' | undefined => {
  switch (programInfoStep.__typename) {
    case 'CourseProgramStep':
      return programInfoStep.course === null || programInfoStep.course === undefined
        ? undefined
        : courseTypeNameToCourseKind[programInfoStep.course.courseKind]
    case 'EmailProgramStep':
      return undefined
    case 'PathProgramStep':
      return 'path'
    default:
      assertNever(programInfoStep)
  }
}

export const programInfoStepAssetContext = (programInfoStep: ProgramInfoStepType): AssetContext => {
  switch (programInfoStep.__typename) {
    case 'CourseProgramStep':
      return { type: 'course', courseId: programInfoStep.courseId }
    case 'EmailProgramStep':
      return { type: 'unknown' }
    case 'PathProgramStep':
      return { type: 'path', pathId: programInfoStep.pathId }
    default:
      assertNever(programInfoStep)
  }
}

export const getNextStepForPath = (step: EnrollmentStepType): EnrollmentStepType => {
  if (step.__typename !== 'UserProgramPathStep' || !('currentStep' in step)) {
    return step
  }
  const nextCourse = step.currentStep
  const nextStep = step.subSteps.find(step => step.contentId === nextCourse?.contentId)

  return isDefined(nextStep) ? nextStep : step
}

export const getNextStepForProgram = (
  enrollment: EnrollmentData
): { nextStep: EnrollmentStepType | undefined; index: number | undefined } => {
  const nextStepMaybe = enrollment?.nextStep ?? undefined

  const nextStepIndex =
    nextStepMaybe !== undefined
      ? enrollment?.steps.findIndex(step => {
          if (
            step.__typename === 'UserProgramCourseStep' &&
            nextStepMaybe.__typename === 'UserProgramCourseStep'
          ) {
            return step.courseId === nextStepMaybe.courseId
          } else if (
            step.__typename === 'UserProgramPathStep' &&
            nextStepMaybe.__typename === 'UserProgramPathStep'
          ) {
            return step.pathId === nextStepMaybe.pathId
          } else {
            return false
          }
        })
      : undefined

  const nextStep =
    isDefined(nextStepIndex) && nextStepIndex !== -1 ? enrollment?.steps[nextStepIndex] : undefined

  if (isDefined(nextStep) && nextStep.__typename === 'UserProgramPathStep') {
    return { nextStep: getNextStepForPath(nextStep), index: undefined }
  }

  return { nextStep, index: nextStepIndex !== -1 ? nextStepIndex : undefined }
}

export function convertStepToLinkable<TStep extends EnrollmentStepType>(
  step: TStep
): TStep extends EmailStepShape ? null : Linkable
export function convertStepToLinkable(step: EnrollmentStepType): Linkable | null {
  if (step.__typename === 'UserProgramEmailStep') {
    return null
  }

  if (step.__typename === 'UserProgramPathStep') {
    return {
      type: 'path',
      id: step.pathId,
    }
  }

  const type = getStepContentType(step)
  assert(type !== 'path')

  const content = step.content
  assert(content.__typename !== 'Path')

  const id = step.courseId

  switch (content.__typename) {
    case 'LinkCourse':
      return {
        id,
        type,
        url: content.url,
      }
    case 'LinkedInCourse':
      return {
        id,
        type,
        url: content.url,
      }
    case 'ScormCourseGroup':
    case 'NativeCourseGroup': {
      return {
        id,
        type,
        activeEditionId: undefined, // todo?
      }
    }
    case 'ScormCourse':
    case 'NativeSelfPacedCourse':
      return {
        id,
        type,
        courseGroupId: undefined, // todo?
      }
    case 'NativeLiveCourse':
      return { id, type }
    case 'Program':
      return { id, type }

    case 'NativeEventGroup':
      return { id, type }
    default:
      assertNever(content)
  }
}

export const getContinueURLForStep = ({
  step,
  upcomingSelfEnrollmentLiveSessions,
  programId,
  parentPathId,
}: {
  step: EnrollmentStepType
  upcomingSelfEnrollmentLiveSessions: StepUpcomingLiveSession[] | undefined
  programId: string
  parentPathId: string | undefined
}): LinkURL | null => {
  if (step.__typename === 'UserProgramEmailStep') {
    return null
  }

  const scheduledLiveSessions =
    step.__typename === 'UserProgramCourseStep'
      ? step.assignedLiveSessions
          ?.map(s => s.liveSession)
          .filter(s => s.__typename === 'ScheduledLiveSession')
      : []

  const liveSession =
    step.__typename === 'UserProgramCourseStep'
      ? getLiveSessionForStep(upcomingSelfEnrollmentLiveSessions ?? [], scheduledLiveSessions ?? [], true)
      : undefined

  if (liveSession !== undefined) {
    return `/l/${liveSession.liveSessionId}` as InternalLinkURL
  }

  const href: LinkURL = continueUrl(convertStepToLinkable(step))

  // TODO: this is just patching a bug where we dont deal with external links.
  // We should fix this properly where we branch the code somewhere else and not here.
  // Suggestion is to create a JSX component that handles `Linkable` or `URL` instead of this here
  if (isExternalLinkURL(href)) {
    return href
  }

  return prefixWithProgramAndOrPath({
    href,
    programId,
    pathId: parentPathId,
  }) as InternalLinkURL
}

export const daysFromNow = (raw: string): number => {
  const days = DateTime.fromISO(raw).diffNow('days').days
  return Math.ceil(days)
}

export const mapLiveSessionLocation = (
  location?: LiveSessionLocationGQL | null
): LiveSessionLocation | undefined =>
  location ? { type: 'physical' as const, value: location.value } : undefined

export const getStepProgress = (step: EnrollmentStepType): number => {
  switch (step.__typename) {
    case 'UserProgramCourseStep':
    case 'UserProgramPathStep': {
      return step.progress.progress
    }
    case 'UserProgramEmailStep': {
      return isDefined(step.availableAt) && DateTime.fromISO(step.availableAt) < DateTime.now() ? 1 : 0
    }
    default:
      assertNever(step)
  }
}

export function isStepCompleted(step: EnrollmentStepType): boolean {
  switch (step.__typename) {
    case 'UserProgramEmailStep': {
      const progress = getStepProgress(step)
      return progress === 1
    }
    case 'UserProgramPathStep':
    case 'UserProgramCourseStep': {
      const passedAt = step.progress.passedAt
      return passedAt !== null
    }
    default:
      assertNever(step)
  }
}

export function getStepTimeLeft(step: EnrollmentStepType): string {
  switch (step.__typename) {
    case 'UserProgramEmailStep': {
      return '0'
    }
    case 'UserProgramPathStep':
    case 'UserProgramCourseStep': {
      return step.progress.timeLeft
    }
    default:
      assertNever(step)
  }
}

export const isSameEnrollmentStep = (stepA: EnrollmentStepType, stepB: EnrollmentStepType): boolean => {
  if (stepA.__typename === 'UserProgramCourseStep' && stepB.__typename === 'UserProgramCourseStep') {
    return stepA.courseId === stepB.courseId
  } else if (stepA.__typename === 'UserProgramPathStep' && stepB.__typename === 'UserProgramPathStep') {
    return stepA.pathId === stepB.pathId
  } else {
    return false
  }
}

export const getExercisesFromStep = (step: EnrollmentStepType): ExerciseState[] | undefined => {
  if (isDefined(step.content) && 'exercises' in step.content) return step.content.exercises
  else return undefined
}

export const getHomeworkStates = (exercises?: ExerciseState[]): HomeworkState[] => {
  if (isNotDefined(exercises)) return []
  const homeworkStates = exercises.map(exercise => {
    const fileId = FileId.parse(exercise.fileId)
    const grade = gqlGradeStatusToGradeStatus[exercise.grade]
    return { fileId, grade }
  })
  return homeworkStates
}

export const getHomeworkGradeForStep = (step: EnrollmentStepType): GradeStatus | undefined => {
  const exercises = getExercisesFromStep(step)
  const homeworkStates = getHomeworkStates(exercises)
  const selectedHomework = getPrioritizedHomework(homeworkStates)
  return selectedHomework?.grade ?? undefined
}
