import { ColumnToColumnData } from 'sierra-client/lib/tabular/datatype/data'
import { TabularLabel } from 'sierra-client/lib/tabular/datatype/label'
import { ColumnDefinitionOf } from 'sierra-client/lib/tabular/datatype/tabledefinition'
import { RenderHint } from 'sierra-client/lib/tabular/hints'
import { ColumnRef } from 'sierra-client/lib/tabular/types'

// todo: simplify. this is a temporary solution to map the new external API to the old internal API
// todo: column-specific hints/props
// todo: remote sort key

export const getColumnBuilder =
  <K extends keyof ColumnToColumnData>(type: K) =>
  <D, R extends ColumnRef>({
    ref,
    header,
    tooltip,
    enabled = true,
    sortable = false,
    hints = [],
    getData,
  }: {
    ref: R
    header: TabularLabel
    tooltip?: TabularLabel
    enabled?: boolean
    sortable?: boolean
    hints?: RenderHint[]
    getData: (_: D) => ColumnToColumnData[K]
  }): ColumnDefinitionOf<D, { ref: R; type: K }> => ({
    type,
    ref,
    header,
    tooltip,
    enabled,
    sortable,
    hints,
    getData: d => ({ type, data: getData(d) }),
  })
