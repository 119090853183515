import { useAtomValue, useSetAtom } from 'jotai'
import React, { useCallback, useEffect, useMemo, useRef, useState } from 'react'
import { isChrome, isEdgeChromium } from 'react-device-detect'
import { useDrag, useDrop } from 'react-dnd'
import { DragItemTypes, EditorSidebarDragItem } from 'sierra-client/components/common/dnd/dnd-types'
import { useNonExpiringNotif, useNotif } from 'sierra-client/components/common/notifications'
import {
  collapsableSidebarStateAtom,
  FolderIcon,
  NestedItemList,
  sidebarHasNestedMenuOpenAtom,
} from 'sierra-client/features/collapsable-sidebar'
import { useResetBooleanAfterDelay } from 'sierra-client/hooks/use-reset-boolean-after-delay'
import { useTranslation } from 'sierra-client/hooks/use-translation'
import { useMultiSelectMoveFiles } from 'sierra-client/state/flexible-content/factory'
import {
  selectFlexibleContentFolder,
  selectFlexibleContentNodes,
  selectParentFolders,
} from 'sierra-client/state/flexible-content/selectors'
import { flexibleContentSlice } from 'sierra-client/state/flexible-content/slice'
import { isFile, isFileId, isFolderId } from 'sierra-client/state/flexible-content/types'
import { useDispatch, useSelector } from 'sierra-client/state/hooks'
import { selectUserId } from 'sierra-client/state/user/user-selector'
import { FCC } from 'sierra-client/types'
import {
  useCreatePageContext,
  useCreatePageYDocContext,
} from 'sierra-client/views/flexible-content/create-page-context'
import { CanvasMenuItem } from 'sierra-client/views/flexible-content/editor/content-sidebar/canvas-menu-item'
import { useCardPopperContext } from 'sierra-client/views/flexible-content/editor/content-sidebar/card-location-context'
import { sidebarContainerAtom } from 'sierra-client/views/flexible-content/editor/content-sidebar/content-sidebar-atoms'
import {
  onPaste,
  pasteFolder,
  useCopyModule,
  useCopyModuleToClipboard,
} from 'sierra-client/views/flexible-content/editor/content-sidebar/copy-paste-utils'
import { HoveredReveal } from 'sierra-client/views/flexible-content/editor/content-sidebar/hovered-reveal'
import { editorNodeMovedLogger } from 'sierra-client/views/flexible-content/editor/content-sidebar/logger'
import { useMultiSelection } from 'sierra-client/views/flexible-content/editor/content-sidebar/multi-selection/hooks/use-multi-selection'
import { useReversibleEditorAction } from 'sierra-client/views/flexible-content/undo-redo/use-reversible-editor-action'
import { ScopedCreateContentId } from 'sierra-domain/collaboration/types'
import { apply, Destination } from 'sierra-domain/editor/operations'
import { FolderId, NodeId } from 'sierra-domain/flexible-content/identifiers'
import { assertIsNonNullable, isDefined } from 'sierra-domain/utils'
import { resolveTokenOrColor } from 'sierra-ui/color/token-or-color'
import { EditableText, MenuItem, Tooltip } from 'sierra-ui/components'
import { focusInput } from 'sierra-ui/components/editable-text/editable-text'
import { IconButton, Text, View } from 'sierra-ui/primitives'
import { IconMenu } from 'sierra-ui/primitives/menu-dropdown'
import { token } from 'sierra-ui/theming'
import styled, { css } from 'styled-components'

export const FolderTitleRow = styled(View).attrs({
  radius: 'regular',
})`
  height: 36px;
  padding: 8px 12px 8px 10px;
  gap: 10px;
  position: relative;
  font-weight: 500;
  overflow: hidden;
  transition: border-color 50ms cubic-bezier(0.25, 0.1, 0.25, 1);

  &:hover {
    background-color: ${p => resolveTokenOrColor('org/primary', p.theme).opacity(0.08)};
    transition: background-color 50ms cubic-bezier(0.25, 0.1, 0.25, 1);
  }

  white-space: nowrap;
  text-overflow: ellipsis;
`

export const FolderContainer = styled(View).attrs({
  radius: 'regular',
  direction: 'column',
  gap: 'none',
})<{
  $dropIndicator?: 'on' | 'above' | 'below'
}>`
  position: relative;
  transition: border-color 50ms cubic-bezier(0.25, 0.1, 0.25, 1);

  &:after {
    content: '';
    position: absolute;
    width: calc(100% - 4px);
    height: calc(100% - 4px);
    border-width: 2px;
    border-style: solid;
    border-color: transparent;
    z-index: 1;
    border-radius: 0.25rem;
    pointer-events: none;
  }

  ${p =>
    p.$dropIndicator === 'on'
      ? css`
          outline: 2px solid ${p.theme.color.blueVivid};
          outline-offset: -2px;
        `
      : p.$dropIndicator === 'above'
        ? css`
            &:after {
              top: 0;
              height: 0px;
              border-color: ${p.theme.color.blueVivid};
              border-radius: 0;
              border-width: 1px;
            }
          `
        : p.$dropIndicator === 'below'
          ? css`
              &:after {
                bottom: 0;
                height: 0px;
                border-color: ${p.theme.color.blueVivid};
                border-radius: 0;
                border-width: 1px;
              }
            `
          : undefined}
`

const FolderMenu: React.FC<{
  nodeRef: React.RefObject<HTMLElement>
  startRename: () => void
  parentFolderId?: FolderId
  folderId: FolderId
}> = ({ nodeRef, startRename, parentFolderId, folderId }) => {
  const { t } = useTranslation()
  const { createContentId, scopedCreateContentId, operationState } = useCreatePageContext()
  const dispatch = useDispatch()
  const folder = useSelector(state => selectFlexibleContentFolder(state, createContentId, folderId))
  const userId = useSelector(state => selectUserId(state))
  const isSelfPacedCourse = ScopedCreateContentId.isSelfPacedId(scopedCreateContentId)
  const isSelfpaced = ScopedCreateContentId.isSelfPacedId(scopedCreateContentId)
  const notif = useNotif()
  const nonExpiringNotif = useNonExpiringNotif()
  const isSafari = window.navigator.userAgent.includes('Safari')
  const isCopyPasteEnabled = isChrome || isSafari || isEdgeChromium
  const [isSettingsOpen, setIsSettingsOpen] = useState(false)
  const setSidebarHasNestedMenuOpen = useSetAtom(sidebarHasNestedMenuOpenAtom)
  const applyReversibleAction = useReversibleEditorAction()

  const onOpenChange = useCallback(
    (newIsOpen: boolean) => {
      setSidebarHasNestedMenuOpen(newIsOpen)
      setIsSettingsOpen(newIsOpen)
    },
    [setSidebarHasNestedMenuOpen]
  )

  const { isEnabled: duplicateModuleRecentlyFinished, setTrue: setDuplicateModuleRecentlyFinished } =
    useResetBooleanAfterDelay()
  const duplicateModuleMutation = useCopyModule({
    operationState,
    contentId: createContentId,
    onSuccess: copiedFolder => {
      assertIsNonNullable(userId)
      void pasteFolder({
        operationState,
        userId,
        nextTo: folderId,
        isSelfpacedContent: isSelfpaced,
        copyFolder: copiedFolder,
        notif,
        nonExpiringNotif,
        t,
        createContentId,
      })
      setDuplicateModuleRecentlyFinished()
    },
  })

  const { isEnabled: copyModuleRecentlyFinished, setTrue: setCopyModuleRecentlyFinished } =
    useResetBooleanAfterDelay()
  const copyModuleMutation = useCopyModuleToClipboard({
    operationState,
    contentId: createContentId,
  })

  const folderMenuItems = useMemo<MenuItem[]>(
    () => [
      {
        id: 'rename',
        type: 'label',
        label: t('admin.rename'),
        icon: 'edit',
        onClick: startRename,
      },
      {
        id: 'duplicate',
        type: 'canvas',
        render({ onItemClick }) {
          return (
            <CanvasMenuItem
              loading={duplicateModuleMutation.isPending}
              onClick={e => {
                e.preventDefault()
                e.stopPropagation()
                onItemClick?.()
                if (duplicateModuleMutation.isPending) {
                  return
                }
                duplicateModuleMutation.mutate(folderId)
              }}
              text={t('dictionary.duplicate')}
              icon={duplicateModuleRecentlyFinished ? 'checkmark' : 'duplicate'}
              keyboardShortcut='D'
            />
          )
        },
      },
      {
        id: 'copy',
        hidden: !isCopyPasteEnabled,
        type: 'canvas',
        render({ onItemClick }) {
          return (
            <CanvasMenuItem
              loading={copyModuleMutation.isPending}
              onClick={e => {
                e.preventDefault()
                e.stopPropagation()
                onItemClick?.()
                if (copyModuleMutation.isPending) {
                  return
                }
                copyModuleMutation.mutate(folderId, {
                  onSuccess: () => setCopyModuleRecentlyFinished(),
                })
              }}
              text={t('admin.copy')}
              icon={copyModuleRecentlyFinished ? 'checkmark' : 'copy--file'}
              keyboardShortcut='C'
            />
          )
        },
      },
      {
        id: 'paste',
        type: 'label',
        hidden: !isCopyPasteEnabled,
        label: t('admin.paste'),
        icon: 'paste--file',
        onClick: () => {
          assertIsNonNullable(userId)
          void onPaste({
            operationState,
            userId,
            isSelfpacedContent: isSelfpaced,
            notif,
            nonExpiringNotif,
            t,
            scopedCreateContentId,
            createContentId,
            dispatch,
            folderId: folderId,
            nextTo: folderId,
          })
        },
      },
      {
        id: 'pt',
        type: 'switch',
        hidden: !isSelfPacedCourse,
        label: t('content-sidebar.folder.pt-toggle'),
        icon: 'fast-forward',
        checked: folder?.settings?.placementTestEnabled ?? false,
        preventClose: true,
        onToggleChange: () => {
          apply(operationState, {
            type: 'update-folder',
            folderId: folderId,
            update: folder => {
              folder.settings = {
                ...folder.settings,
                placementTestEnabled: !(folder.settings?.placementTestEnabled ?? false),
              }
            },
          })
        },
      },
      {
        id: 'review',
        type: 'switch',
        hidden: !isSelfPacedCourse,
        label: t('content-sidebar.folder.review-toggle'),
        icon: 'inspection',
        checked: folder?.settings?.reviewEnabled ?? false,
        preventClose: true,
        onToggleChange: () => {
          apply(operationState, {
            type: 'update-folder',
            folderId: folderId,
            update: folder => {
              folder.settings = {
                ...folder.settings,
                reviewEnabled: !(folder.settings?.reviewEnabled ?? false),
              }
            },
          })
        },
      },
      {
        id: 'delete',
        type: 'label',
        disabled: parentFolderId === undefined,
        label: t('admin.delete'),
        icon: 'trash-can',
        color: 'destructive/background',
        onClick: () => {
          assertIsNonNullable(folder)

          applyReversibleAction({ type: 'remove-folder', folder })
        },
      },
    ],
    [
      t,
      startRename,
      isCopyPasteEnabled,
      isSelfPacedCourse,
      folder,
      parentFolderId,
      duplicateModuleMutation,
      duplicateModuleRecentlyFinished,
      folderId,
      copyModuleMutation,
      copyModuleRecentlyFinished,
      setCopyModuleRecentlyFinished,
      userId,
      operationState,
      isSelfpaced,
      notif,
      nonExpiringNotif,
      scopedCreateContentId,
      createContentId,
      dispatch,
      applyReversibleAction,
    ]
  )

  return (
    <>
      <HoveredReveal forcedVisible={isSettingsOpen} anchor={nodeRef}>
        <IconMenu
          iconId='overflow-menu--vertical'
          variant='ghost'
          menuItems={folderMenuItems}
          size='small'
          onSelect={() => {}}
          isOpen={isSettingsOpen}
          onOpenChange={onOpenChange}
        />
      </HoveredReveal>
    </>
  )
}

type FolderDndProps = {
  parentFolderId?: FolderId
  folderId: FolderId
  collapsed: boolean
  children: React.ReactNode
}

const DndContainer = React.forwardRef<HTMLDivElement, FolderDndProps>(
  ({ children, collapsed, parentFolderId, folderId }, ref) => {
    const { createContentId } = useCreatePageContext()
    const dispatch = useDispatch()
    const flexibleContentNodes = useSelector(state => selectFlexibleContentNodes(state, createContentId))
    const folder = useSelector(state => selectFlexibleContentFolder(state, createContentId, folderId))
    const parentFolders = useSelector(state => selectParentFolders(state, createContentId, folderId))
    const [dropIndicator, setDropIndicator] = useState<'on' | 'below' | 'above'>()
    const { selection, areMultipleSelected, clearSelection } = useMultiSelection()
    const multiSelectMoveFiles = useMultiSelectMoveFiles({ onSuccess: clearSelection })

    const nodeRef = useRef<HTMLDivElement | null>(null)

    const [{ isDragging }, handleRef, dragPreviewRef] = useDrag<
      EditorSidebarDragItem,
      void,
      { isDragging: boolean }
    >(
      () => ({
        type: DragItemTypes.EditorSidebar,
        item: {
          type: DragItemTypes.EditorSidebar,
          id: folderId,
          parentFolderId: parentFolderId !== undefined ? parentFolderId : 'folder:root',
          title: folder?.title ?? '',
          iconId: 'module',
          itemType: 'folder',
        },
        collect: monitor => ({ isDragging: monitor.isDragging() }),
      }),
      [folder?.title, folderId, parentFolderId]
    )

    const [{ isOver, canDrop }, dropRef] = useDrop<
      EditorSidebarDragItem,
      { nodeId: NodeId },
      { isOver: boolean; canDrop: boolean }
    >(() => {
      return {
        accept: [DragItemTypes.EditorSidebar],
        collect: monitor => ({
          isOver: monitor.isOver({ shallow: true }),
          canDrop: monitor.canDrop(),
        }),
        canDrop: item => {
          // Don't allow drop's on itself
          if (item.id === folderId) return false

          // Is the item a parent folder?
          if (isFolderId(item.id) && parentFolders.includes(item.id)) {
            return false
          }

          return true
        },
        hover: (item, monitor) => {
          if (!monitor.isOver({ shallow: true }) || !monitor.canDrop()) return

          const hoveringPosition = monitor.getClientOffset()
          const targetBoundingBox = nodeRef.current?.getBoundingClientRect()

          const itemIsFolder = isFolderId(item.id)
          const itemIsAssessmentCard: boolean = (() => {
            if (isFileId(item.id)) {
              const file = flexibleContentNodes?.[item.id]

              if (isFile(file) && file.data.type === 'assessment-card') {
                return true
              }
            }

            return false
          })()

          if (hoveringPosition !== null && targetBoundingBox !== undefined) {
            const hoveredPortion = (hoveringPosition.y - targetBoundingBox.y) / targetBoundingBox.height

            // We can't drop folders on folders, only below and above
            if (itemIsFolder || itemIsAssessmentCard) {
              if (hoveredPortion > 0.5) {
                // Note: Drop below a folder can be confusing when it's not collapsed
                if (collapsed || folder?.nodeIds.length === 0) {
                  setDropIndicator('below')
                }
              } else {
                setDropIndicator('above')
              }
            } else {
              // Dropping an item on a folder
              if (hoveredPortion > 0.25 && hoveredPortion < 0.75) {
                setDropIndicator('on')
              } else if (hoveredPortion < 0.25) {
                setDropIndicator('above')
              } else if (hoveredPortion > 0.75) {
                // Note: Drop below a folder can be confusing when it's not collapsed
                if (collapsed || folder?.nodeIds.length === 0) {
                  setDropIndicator('below')
                }
              }
            }
          }
        },
        drop: (item, monitor) => {
          if (monitor.didDrop()) {
            return
          }

          const nodeId = item.id
          const destinationNodeId =
            dropIndicator !== 'on' && parentFolderId !== undefined ? parentFolderId : folderId

          if (areMultipleSelected) {
            const destination: Destination = (() => {
              if (dropIndicator === 'on') {
                return {
                  type: 'add-at-end' as const,
                }
              } else {
                return {
                  type: 'next-to' as const,
                  nodeId: folderId,
                  position: dropIndicator,
                }
              }
            })()
            // move all nodes
            multiSelectMoveFiles.mutate({
              fileIds: Object.values(selection)
                // assessment cards can't be added to folders;
                .filter(s => s.file.data.type !== 'assessment-card')
                // if the card is already in the destination folder, ignore it, but only when dropping into the module
                .filter(s => (dropIndicator === 'on' ? s.parentFolderId !== folderId : true))
                .map(s => s.file.id),
              folderId: destinationNodeId,
              destination,
            })
          } else {
            dispatch(
              flexibleContentSlice.actions.moveNode({
                createContentId,
                nodeId,
                parentFolderId: item.parentFolderId,
                destinationId: destinationNodeId,
                nextTo:
                  dropIndicator === 'on'
                    ? undefined
                    : {
                        nodeId: folderId,
                        after: dropIndicator === 'below',
                      },
              })
            )
          }

          void dispatch(
            editorNodeMovedLogger({
              nodeType: item.itemType,
              nodeId: item.id,
            })
          )
          // Returning an object marks the drop as handled
          return { nodeId }
        },
      }
    }, [
      folderId,
      parentFolders,
      flexibleContentNodes,
      collapsed,
      folder?.nodeIds.length,
      dropIndicator,
      parentFolderId,
      areMultipleSelected,
      dispatch,
      multiSelectMoveFiles,
      selection,
      createContentId,
    ])

    dropRef(dragPreviewRef(handleRef(nodeRef)))

    return (
      <FolderContainer
        cursor={isDragging ? 'grabbing' : 'pointer'}
        $dropIndicator={isOver && canDrop ? dropIndicator : undefined}
        ref={current => {
          nodeRef.current = current
          if (ref === null) return
          else if (typeof ref === 'function') ref(current)
          else ref.current = current
        }}
      >
        {children}
      </FolderContainer>
    )
  }
)
const FadeOutContainer = styled.div`
  position: absolute;
  transition: opacity 150ms cubic-bezier(0.77, 0, 0.175, 1) !important;
  opacity: 1;
`

const FadeInContainer = styled.div`
  transition: opacity 150ms cubic-bezier(0.77, 0, 0.175, 1) !important;
  opacity: 0;
`

const EmptyModuleContainer = styled(View)`
  cursor: pointer;

  &:hover {
    ${FadeOutContainer} {
      opacity: 0;
    }

    ${FadeInContainer} {
      opacity: 1;
    }
  }
`

export const AnimatedOutlineCSS = css<{ $hasBorder: boolean }>`
  border-radius: 8px;
  outline: 1px solid;
  outline-offset: 4px;
  outline-color: transparent;
  transition: outline-color 400ms;
  ${p =>
    p.$hasBorder &&
    css`
      outline-color: ${token('border/strong')};
    `}
`

const BorderContainer = styled.div<{ $hasBorder: boolean }>`
  ${AnimatedOutlineCSS}
`

const EditableContainer = styled.div`
  flex: 1;
  min-width: 0;
`

const NoWrap = styled.div`
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
`
export const BrowseFolder: FCC<{
  parentFolderId?: FolderId
  folderId: FolderId
  currentCardId: NodeId | undefined
}> = ({ children, parentFolderId, folderId, currentCardId }) => {
  const { createContentId, operationState } = useCreatePageContext()
  const { t } = useTranslation()
  const { permission } = useCreatePageYDocContext()
  const [collapsed, setCollapsed] = useState(false)
  const folder = useSelector(state => selectFlexibleContentFolder(state, createContentId, folderId))
  const [contentEditable, setContentEditable] = useState(false)
  const inputRef = useRef<HTMLParagraphElement>(null)
  const noChildren = folder?.nodeIds.length === 0
  const isCurrentCardInFolder =
    isDefined(currentCardId) && isDefined(folder) && folder.nodeIds.includes(currentCardId)

  const sidebarState = useAtomValue(collapsableSidebarStateAtom)

  useEffect(() => {
    if (!isCurrentCardInFolder && sidebarState === 'collapsed') {
      setCollapsed(true)
    }
  }, [sidebarState, isCurrentCardInFolder, setCollapsed])

  const { setCreateCardLocation, setCardPopperOpen } = useCardPopperContext()

  const startRename = (): void => {
    setContentEditable(true)
    focusInput(inputRef)
  }

  const dispatchRenameFolder = useCallback(
    (updatedValue: string): void => {
      apply(operationState, {
        type: 'update-folder',
        folderId: folderId,
        update: folder => {
          folder.title = updatedValue
        },
      })
    },
    [operationState, folderId]
  )

  const nodeRef = useRef<HTMLDivElement>(null)

  const sidebarContainer = useAtomValue(sidebarContainerAtom)

  if (!folder) return <>No such folder {folderId}</>

  const folderIconId = collapsed ? 'chevron--down--small' : 'chevron--up--small'

  const isEditingTitle = permission === 'edit' && contentEditable

  return (
    <BorderContainer $hasBorder={isCurrentCardInFolder && sidebarState === 'collapsed'}>
      <DndContainer ref={nodeRef} parentFolderId={parentFolderId} folderId={folderId} collapsed={collapsed}>
        <FolderTitleRow>
          <FolderIcon
            numberOfContent={folder.nodeIds.length}
            isCompleted={false}
            isCurrentItem={isCurrentCardInFolder}
          />

          <Tooltip
            title={isEditingTitle ? undefined : folder.title}
            container={sidebarContainer ?? undefined}
          >
            <EditableContainer>
              <EditableText
                ref={inputRef}
                contentEditable={isEditingTitle}
                setContentEditable={setContentEditable}
                value={folder.title}
                onRename={dispatchRenameFolder}
                color={isCurrentCardInFolder ? 'foreground/primary' : 'foreground/secondary'}
              />
            </EditableContainer>
          </Tooltip>
          <View gap='none'>
            {permission === 'edit' && !isEditingTitle && (
              <FolderMenu
                parentFolderId={parentFolderId}
                folderId={folderId}
                startRename={startRename}
                nodeRef={nodeRef}
              />
            )}
            <IconButton
              size='small'
              variant='transparent'
              onClick={() => setCollapsed(!collapsed)}
              iconId={folderIconId}
            />
          </View>
        </FolderTitleRow>

        {!collapsed && noChildren && (
          <EmptyModuleContainer
            onClick={() => {
              setCreateCardLocation({ folderId })
              setCardPopperOpen(true)
            }}
            grow
            marginLeft='40'
            marginTop='8'
            marginBottom='8'
          >
            <FadeOutContainer>
              <NoWrap>
                <Text bold color='foreground/muted'>
                  {t('content-sidebar.folder.empty-module')}
                </Text>
              </NoWrap>
            </FadeOutContainer>
            <FadeInContainer>
              <NoWrap>
                <Text bold color='foreground/muted'>
                  {t('content-sidebar.folder.add-card')} +
                </Text>
              </NoWrap>
            </FadeInContainer>
          </EmptyModuleContainer>
        )}
      </DndContainer>
      <NestedItemList open={!collapsed}>{children}</NestedItemList>
    </BorderContainer>
  )
}
