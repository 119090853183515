import { useEffect } from 'react'
import { selectCourseIsCompleted } from 'sierra-client/state/card-progress/selectors'
import { selectCurrentPathId } from 'sierra-client/state/content/selectors'
import { useDispatch, useSelector } from 'sierra-client/state/hooks'
import { fetchPathById } from 'sierra-client/state/v2/paths-actions'
import { CreateContentId } from 'sierra-domain/api/nano-id'

export const PathProgressSync: React.FC<{ createContentId: CreateContentId }> = ({
  createContentId,
}): null => {
  const dispatch = useDispatch()
  const currentPathId = useSelector(selectCurrentPathId)

  const courseCompleted = useSelector(state => selectCourseIsCompleted(state, createContentId))

  useEffect(() => {
    if (currentPathId === undefined) return

    void dispatch(fetchPathById({ pathId: currentPathId }))
  }, [currentPathId, dispatch, courseCompleted])

  return null
}
