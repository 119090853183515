import { motion } from 'framer-motion'
import React, { FC } from 'react'
import { SquareAvatar, SquareAvatarSize } from 'sierra-client/components/common/square-avatar'
import {
  ParticipantVideo,
  useLocalParticipantVideo,
  useParticipantVideo,
} from 'sierra-client/components/liveV2/hooks/use-participant-video'
import { MediaPlayer } from 'sierra-client/components/liveV2/live-layer/mediaplayer'
import { ParticipantOverlayWrapper } from 'sierra-client/components/liveV2/participant'
import { PrioriotyLevel } from 'sierra-client/components/liveV2/participant-provider'
import { RaisingHand } from 'sierra-client/components/liveV2/raising-hand'
import { useSelector } from 'sierra-client/state/hooks'
import { LiveParticipant } from 'sierra-client/state/live-session/types'
import { selectClientId, selectVideoState } from 'sierra-client/state/live/selectors'
import { selectUser } from 'sierra-client/state/user/user-selector'
import { useUserLegacy } from 'sierra-client/state/users/hooks'
import { UserId } from 'sierra-domain/api/uuid'
import { ChatOverflowDirection } from 'sierra-ui/missions/live'
import { v2_breakpoint } from 'sierra-ui/theming/breakpoints'
import { styleByProp } from 'sierra-ui/utils'
import styled, { css } from 'styled-components'

type VideoAvatarSize = 'tall-minimized' | 'small' | 'medium' | 'large' | 'tall'

const desktopVideoAvatarWidth = styleByProp('$size', {
  'small': '2.5rem',
  'medium': '5rem',
  'large': '7.5rem',
  'tall-minimized': '7.5rem',
  'tall': '7.5rem',
})

const desktopVideoAvatarHeight = styleByProp('$size', {
  'small': '2.5rem',
  'medium': '5rem',
  'large': '7.5rem',
  'tall-minimized': '2.25rem',
  'tall': '9rem',
})

const mobileVideoAvatarWidth = styleByProp('$size', {
  'small': '2.5rem',
  'medium': '3rem',
  'large': '3.5rem',
  'tall-minimized': '2.5rem',
  'tall': '5rem',
})

const mobileVideoAvatarHeight = styleByProp('$size', {
  'small': '3.5rem',
  'medium': '4rem',
  'large': '4.5rem',
  'tall-minimized': '3.5rem',
  'tall': '7rem',
})

const getSquareAvatarSize = (videoSize: VideoAvatarSize): SquareAvatarSize => {
  switch (videoSize) {
    case 'tall-minimized':
    case 'small':
      return 'small'
    case 'medium':
    case 'tall':
      return 'medium'
    case 'large':
      return 'large'
  }
}

const VideoAvatarContainer = styled(motion.div)<{
  $size: VideoAvatarSize
  $dynamicSize?: boolean
  $height?: number
  $width?: number
}>`
  position: relative;
  will-change: opacity, transform;
  pointer-events: auto;

  ${p => css`
    ${p.$dynamicSize === true && p.$height !== undefined
      ? css`
          width: 100%;
          height: ${p.$height}%;

          @media screen and (max-width: ${v2_breakpoint.tablet}) {
            width: ${p.$width}%;
            height: 100%;
          }
        `
      : css`
          width: ${desktopVideoAvatarWidth};
          height: ${desktopVideoAvatarHeight};

          @media screen and (max-width: ${v2_breakpoint.phone}) {
            width: ${mobileVideoAvatarWidth};
            height: ${mobileVideoAvatarHeight};
          }
        `}
  `}
`

const ParticipantContainer = styled.div`
  position: relative;
  width: 100%;
  height: 100%;
  color: white;
  display: inline-flex;
  vertical-align: middle;
  justify-content: center;
  align-items: center;
`

const MediaPlayerContainer = styled(motion.div)`
  width: 100%;
  height: 100%;
  border-radius: 8px;
  overflow: hidden;
  /**
  Safari hack for border radius with overflow hidden
  This will tell the browser this element needs to create a new stacking context
  https://stackoverflow.com/questions/49066011/overflow-hidden-with-border-radius-not-working-on-safari 
  */
  isolation: isolate;
`

const StyledRaisingHand = styled(RaisingHand)`
  position: absolute;
  left: 8px;
  top: 8px;
`

const StyledSquareAvatar = styled(SquareAvatar)`
  width: 100%;
  height: 100%;
`

type VideoAvatarInnerProps = {
  video?: ParticipantVideo | undefined
  showVideo: boolean
  uuid?: UserId
  agoraUID: string | undefined
  size?: VideoAvatarSize
  dynamicSize?: boolean
  height?: number
  width?: number
  chatOverflowDirection: ChatOverflowDirection
}

type VideoAvatarProps = {
  participant: LiveParticipant
  showVideo: boolean
  size?: VideoAvatarSize
  dynamicSize?: boolean
  height?: number
  width?: number
  priority?: number
}

const VideoAvatarInner: React.FC<VideoAvatarInnerProps> = ({
  showVideo,
  uuid,
  agoraUID,
  size = 'medium',
  dynamicSize = false,
  height,
  width,
  chatOverflowDirection,
  video,
}) => {
  const user = useUserLegacy(uuid)
  const isThisVideoAvatarPlaying = video !== undefined && video !== 'in-use'

  return (
    <VideoAvatarContainer
      $size={size}
      $dynamicSize={dynamicSize}
      $height={height}
      $width={width}
      initial={{ opacity: 0, visibility: 'hidden' }}
      animate={{ opacity: 1, visibility: 'visible' }}
      key={user?.firstName}
      layoutId={user?.firstName}
      transition={{ type: 'spring', bounce: 0.05, duration: 0.2 }}
    >
      <ParticipantContainer>
        {isThisVideoAvatarPlaying && showVideo ? (
          <MediaPlayerContainer key='media-player-container'>
            <MediaPlayer video={video} />
          </MediaPlayerContainer>
        ) : (
          <StyledSquareAvatar
            size={getSquareAvatarSize(size)}
            firstName={user?.firstName}
            lastName={user?.lastName}
            image={user?.avatar}
            color={user?.avatarColor}
            showName={false}
          />
        )}
        <ParticipantOverlayWrapper
          uuid={uuid}
          agoraUID={agoraUID}
          chatOverflowDirection={chatOverflowDirection}
        />
      </ParticipantContainer>
      {user !== undefined && uuid !== undefined && <StyledRaisingHand userId={uuid} />}
    </VideoAvatarContainer>
  )
}

export const LocalUserInVideoAvatarBar: FC<{ height: number; width: number }> = ({ height, width }) => {
  const localTrack = useLocalParticipantVideo()
  const agoraUID = useSelector(selectClientId)
  const videoState = useSelector(selectVideoState)
  const user = useSelector(selectUser)

  return (
    <VideoAvatarInner
      chatOverflowDirection='left'
      uuid={user?.uuid}
      agoraUID={agoraUID}
      video={localTrack}
      showVideo={videoState === 'on'}
      size='medium'
      dynamicSize={true}
      height={height}
      width={width}
    />
  )
}

export const VideoAvatar: React.FC<VideoAvatarProps> = ({
  participant,
  showVideo,
  size = 'medium',
  height,
  width,
  dynamicSize = false,
  priority = PrioriotyLevel.MEDIUM,
}) => {
  const participantVideo = useParticipantVideo(participant.agoraUID, priority, 'low')

  return (
    <VideoAvatarInner
      chatOverflowDirection='left'
      uuid={participant.userId}
      agoraUID={participant.agoraUID}
      video={participantVideo}
      showVideo={showVideo}
      size={size}
      dynamicSize={dynamicSize}
      height={height}
      width={width}
    />
  )
}
