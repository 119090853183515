import Tippy from '@tippyjs/react'
import { EmojiData, Picker } from 'emoji-mart'
import 'emoji-mart/css/emoji-mart.css'
import { useCallback, useMemo, useRef, useState } from 'react'
import { EmojiPickerPopupContextObj } from 'sierra-client/editor/emoji-context'
import { OnEmojiSelectedCallbackFn, OnOutsideClickCallbackFn } from 'sierra-client/editor/emoji-types'

interface EmojiPickerPopupProviderProps {
  children: React.ReactElement | React.ReactElement[]
}

const EmojiPickerPopupProvider = ({ children }: EmojiPickerPopupProviderProps): JSX.Element => {
  const [visible, setVisible] = useState(false)
  const [anchor, setAnchor] = useState<Element | null>(null)
  const onEmojiSelectedCallbackRef = useRef<OnEmojiSelectedCallbackFn | null>(null)
  const onOutsideClickCallbackRef = useRef<OnOutsideClickCallbackFn | null>(null)

  const open = useCallback(
    (
      newAnchor: Element,
      emojiSelectedCallback: OnEmojiSelectedCallbackFn,
      onOutsideClickCallback?: OnOutsideClickCallbackFn
    ): void => {
      setAnchor(newAnchor)
      onEmojiSelectedCallbackRef.current = emojiSelectedCallback
      setVisible(true)
      if (onOutsideClickCallback !== undefined) {
        onOutsideClickCallbackRef.current = onOutsideClickCallback
      }
    },
    []
  )

  const close = useCallback((): void => {
    setAnchor(null)
    onEmojiSelectedCallbackRef.current = null
    setVisible(false)
  }, [])

  const onSelect = useCallback((emoji: EmojiData): void => {
    if ('native' in emoji && onEmojiSelectedCallbackRef.current) {
      onEmojiSelectedCallbackRef.current({ emoji: emoji.native, emojiCode: emoji.colons })
    }
  }, [])

  const content = useMemo(() => <Picker title='Pick an emoji' native onSelect={onSelect} />, [onSelect])

  const contextValue = useMemo(
    () => ({
      open,
      close,
      isOpen: visible,
      currentAnchor: anchor,
    }),
    [open, close, visible, anchor]
  )

  return (
    <EmojiPickerPopupContextObj.Provider value={contextValue}>
      <Tippy
        reference={anchor}
        visible={visible}
        interactive
        appendTo={document.body}
        onClickOutside={() => {
          close()
          onOutsideClickCallbackRef.current?.()
        }}
        content={content}
      />
      {children}
    </EmojiPickerPopupContextObj.Provider>
  )
}

// eslint-disable-next-line import/no-default-export
export default EmojiPickerPopupProvider
