import { useDropAWordData } from 'sierra-client/views/v3-author/drop-a-word/drop-a-word-data-layer'
import { DropAWord } from 'sierra-client/views/v3-author/drop-a-word/renderer/drop-a-word'
import { CreateContentId } from 'sierra-domain/api/nano-id'
import { FileId } from 'sierra-domain/flexible-content/identifiers'
import { DropAWordData } from 'sierra-domain/flexible-content/types'

export const LiveDropAWordCard: React.FC<{
  contentId: CreateContentId
  fileId: FileId
  fileData: DropAWordData
  addWordDisabled?: boolean
}> = ({ fileData }) => {
  const data = useDropAWordData()

  return <DropAWord data={data} title={fileData.title} onTitleChanged={undefined} />
}
