import React, { useCallback, useContext, useEffect, useMemo, useState } from 'react'
import { errorLogger } from 'sierra-client/error/error-logger'
import { usePost } from 'sierra-client/hooks/use-post'
import { useSelector } from 'sierra-client/state/hooks'
import { selectUserId } from 'sierra-client/state/user/user-selector'
import { FCC } from 'sierra-client/types'
import { useFileContext } from 'sierra-client/views/flexible-content/file-context'
import { updateNodeWithId } from 'sierra-client/views/v3-author/command'
import { isElementType } from 'sierra-client/views/v3-author/queries'
import { EditorMode } from 'sierra-client/views/v3-author/slate'
import { NanoId12 } from 'sierra-domain/api/nano-id'
import { Entity } from 'sierra-domain/entity'
import {
  XRealtimeStrategySelfPacedContentChecklistCheckBox,
  XRealtimeStrategySelfPacedContentChecklistGetChecks,
  XRealtimeStrategySelfPacedContentChecklistUncheckBox,
} from 'sierra-domain/routes'
import { assertIsNonNullable, assertNever, iife } from 'sierra-domain/utils'
import { CheckList as CheckListType } from 'sierra-domain/v3-author'
import { useSlateStatic } from 'slate-react'

type ChecklistDataLayer = {
  checkedIds: NanoId12[] | undefined
  setCheck: (checked: boolean, checklistItemId: NanoId12) => Promise<void>
}

const DefaultContext = React.createContext<ChecklistDataLayer | undefined>(undefined)
const SelfPacedContext = React.createContext<ChecklistDataLayer | undefined>(undefined)

const SelfPacedDataProvider: FCC<{ element: Entity<CheckListType> }> = ({ element, children }) => {
  const userId = useSelector(selectUserId)
  const { postWithUserErrorException } = usePost()
  const [checkedIds, setCheckedIds] = useState<NanoId12[]>([])
  const { file, flexibleContentId } = useFileContext()

  const checklistId = isElementType('check-list', element) ? element.id : undefined

  const checkCheck = useCallback(async () => {
    if (userId === undefined) throw new Error('no userId')
    if (checklistId === undefined) throw new Error('no checklistId')
    const res = await postWithUserErrorException(XRealtimeStrategySelfPacedContentChecklistGetChecks, {
      checklistId: checklistId,
      fileId: file.id,
      courseId: flexibleContentId,
    })
    return res
  }, [checklistId, file.id, flexibleContentId, postWithUserErrorException, userId])

  useEffect(() => {
    checkCheck()
      .then(res => setCheckedIds(res.checkedIds))
      .catch(errorLogger.captureError)
  }, [checkCheck])

  const setCheck = useCallback(
    async (checked: boolean, checklistItemId: NanoId12) => {
      if (checklistId === undefined) throw new Error('no checklistId')
      if (checked === true) {
        await postWithUserErrorException(XRealtimeStrategySelfPacedContentChecklistCheckBox, {
          checklistId: checklistId,
          checklistItemId: checklistItemId,
          fileId: file.id,
          courseId: flexibleContentId,
        })
      }
      if (checked === false) {
        await postWithUserErrorException(XRealtimeStrategySelfPacedContentChecklistUncheckBox, {
          checklistId: checklistId,
          checklistItemId: checklistItemId,
          fileId: file.id,
          courseId: flexibleContentId,
        })
      }

      checkCheck()
        .then(res => setCheckedIds(res.checkedIds))
        .catch(errorLogger.captureError)
    },
    [checkCheck, checklistId, file.id, flexibleContentId, postWithUserErrorException]
  )

  const value = useMemo(() => ({ checkedIds, setCheck }), [checkedIds, setCheck])
  return <SelfPacedContext.Provider value={value}>{children}</SelfPacedContext.Provider>
}

const DefaultDataProvider: FCC<{ element: Entity<CheckListType> }> = ({ children }) => {
  const editor = useSlateStatic()
  const setCheck = useCallback(
    (checked: boolean, checklistItemId: NanoId12) => {
      updateNodeWithId(editor, checklistItemId, { checked: checked })
      return Promise.resolve()
    },
    [editor]
  )
  const value = useMemo(() => ({ setCheck, checkedIds: undefined }), [setCheck])

  return <DefaultContext.Provider value={value}>{children}</DefaultContext.Provider>
}

export const CheckListProvider: FCC<{ element: Entity<CheckListType>; mode: EditorMode }> = ({
  mode,
  element,
  children,
}) => {
  const Provider = iife(() => {
    switch (mode) {
      case 'self-paced':
        return SelfPacedDataProvider
      case 'create':
      case 'live':
      case 'review':
      case 'placement-test':
      case 'recap':
      case 'template':
      case 'version-history':
        return DefaultDataProvider
      default:
        assertNever(mode)
    }
  })

  return <Provider element={element}>{children}</Provider>
}

export const useCheckListSelfpacedData = (): ChecklistDataLayer => {
  const selfPacedContext = useContext(SelfPacedContext)
  const defaultContext = useContext(DefaultContext)

  const context = selfPacedContext ?? defaultContext
  assertIsNonNullable(context, 'must be wrapped in <CheckListProvider>')
  return context
}
