import { useEffect, useState } from 'react'
import { File } from 'sierra-domain/flexible-content/types'
import { SlateDocument } from 'sierra-domain/v3-author'
import { getSlateDocument, getSlateDocumentSharedType } from 'sierra-domain/v3-author/slate-yjs-extension'
import * as Y from 'yjs'

export function useSlateDocument(yDoc: Y.Doc, fileId: File['id']): SlateDocument {
  const [slateDocument, setSlateDocument] = useState(getSlateDocument(yDoc, fileId))
  useEffect(() => {
    function onDocumentChanged(): void {
      const newDocument = getSlateDocument(yDoc, fileId)
      setSlateDocument(newDocument)
    }

    onDocumentChanged()

    const slateSharedType = getSlateDocumentSharedType(yDoc, fileId)
    slateSharedType.observeDeep(onDocumentChanged)
    return () => slateSharedType.unobserveDeep(onDocumentChanged)
  }, [fileId, yDoc])

  return slateDocument
}
