import React, { useState } from 'react'
import { usePasswordStrength } from 'sierra-client/hooks/use-password-strength'
import { usePost } from 'sierra-client/hooks/use-post'
import { useTranslation } from 'sierra-client/hooks/use-translation'
import { getUserErrorTranslationKey } from 'sierra-client/utils/translation-utils'
import { AuthenticationContent } from 'sierra-client/views/authentication/native/components/authentication-content'
import { isLeft } from 'sierra-domain/either'
import { XRealtimeAuthResetPassword } from 'sierra-domain/routes'
import { FormElement, PasswordInputPrimitive } from 'sierra-ui/components'
import { Button, Spacer, Text } from 'sierra-ui/primitives'

type Props = {
  code: string
}

export const ResetPassword: React.FC<Props> = ({ code }) => {
  const { t } = useTranslation()
  const [password, setPassword] = useState('')
  const [passwordError, setPasswordError] = useState<string | undefined>(undefined)
  const [passwordConfirmation, setPasswordConfirmation] = useState('')
  const [passwordConfirmationError, setPasswordConfirmationError] = useState<string | undefined>(undefined)
  const [success, setSuccess] = useState(false)
  const [error, setError] = useState<string | undefined>(undefined)
  const { postWithUserErrorCode } = usePost()
  const { isPasswordStrengthGood } = usePasswordStrength()

  const disableSubmit = password === '' || passwordConfirmation === ''

  const handleBlur = async (): Promise<void> => {
    if (!password) return

    if (!(await isPasswordStrengthGood(password))) {
      setPasswordError(t('create-account.errors.password-strength'))
    }
  }

  const resetErrors = (): void => {
    setPasswordError(undefined)
    setPasswordConfirmationError(undefined)
  }

  const onPasswordChange = (e: React.ChangeEvent<HTMLInputElement>): void => {
    setPassword(e.target.value)
    resetErrors()
  }

  const onPasswordConfirmationChange = (e: React.ChangeEvent<HTMLInputElement>): void => {
    setPasswordConfirmation(e.target.value)
    resetErrors()
  }

  const submit = async (): Promise<void> => {
    let errors = false

    if (!password) {
      errors = true
      setPasswordError(t('create-account.errors.password'))
    } else if (!(await isPasswordStrengthGood(password))) {
      errors = true
      setPasswordError(t('create-account.errors.password-strength'))
    } else {
      setPasswordError(undefined)
    }

    if (password !== passwordConfirmation) {
      errors = true
      setPasswordConfirmationError(t('create-account.errors.password-confirmation'))
    } else {
      setPasswordConfirmationError(undefined)
    }

    if (errors) return

    const result = await postWithUserErrorCode(XRealtimeAuthResetPassword, {
      code,
      password,
    })

    if (isLeft(result)) {
      setError(t(getUserErrorTranslationKey(result.left)))
      return
    }

    setError(undefined)
    setSuccess(true)
  }

  const handleKeyDown = (e: React.KeyboardEvent): void => {
    if (e.key === 'Enter') {
      void submit()
    }
  }

  return (
    <>
      <AuthenticationContent message={t('reset-password.heading')}>
        <FormElement
          label={t('reset-password.new-password')}
          isError={passwordError !== undefined}
          helper={passwordError}
          grow
        >
          <PasswordInputPrimitive
            value={password}
            onChange={onPasswordChange}
            onKeyDown={handleKeyDown}
            onBlur={handleBlur}
            disabled={success}
            autoFocus
          />
        </FormElement>
        <FormElement
          grow
          label={t('create-account.fields.confirm-password')}
          isError={passwordConfirmationError !== undefined}
          helper={passwordConfirmationError}
        >
          <PasswordInputPrimitive
            value={passwordConfirmation}
            onChange={onPasswordConfirmationChange}
            onKeyDown={handleKeyDown}
            disabled={success}
          />
        </FormElement>
        {error !== undefined && (
          <>
            <Spacer size='4' />
            <Text size='micro' color='redBright'>
              {error}
            </Text>
          </>
        )}
        <Spacer />
        {success ? (
          <Button variant='primary' grow href='/'>
            {t('reset-password.password-reset')}
          </Button>
        ) : (
          <Button
            variant='primary'
            type='submit'
            id={'reset-button'}
            onClick={submit}
            disabled={disableSubmit}
          >
            {t('reset-password.reset-password')}
          </Button>
        )}
      </AuthenticationContent>
    </>
  )
}
