import React, { useState } from 'react'
import { useIntercom } from 'react-use-intercom'
import { useNotif } from 'sierra-client/components/common/notifications'
import { getFlag } from 'sierra-client/config/global-config'
import { useToggle } from 'sierra-client/hooks/use-toggle'
import { useTranslation } from 'sierra-client/hooks/use-translation'
import { useSelector } from 'sierra-client/state/hooks'
import { selectUser } from 'sierra-client/state/user/user-selector'
import {
  FooterWrapper,
  PanelLayout,
} from 'sierra-client/views/manage/components/user-attributes/flows/components/layout'
import { EmptyUsers } from 'sierra-client/views/manage/components/user-attributes/flows/invite-users/alerts/empty-users'
import { MissingMandatoryAttributes } from 'sierra-client/views/manage/components/user-attributes/flows/invite-users/alerts/missing-mandatory-attributes'
import { useComposeUserInvitationConfig } from 'sierra-client/views/manage/components/user-attributes/flows/invite-users/hooks/use-compose-user-invitation-config'
import { EmailAutoComplete } from 'sierra-client/views/manage/components/user-attributes/flows/invite-users/tabs/invite-via-email/email-auto-complete/email-auto-complete'
import { UserList } from 'sierra-client/views/manage/components/user-attributes/flows/invite-users/tabs/invite-via-email/user-list'
import { isOwner } from 'sierra-domain/access-level'
import { isDefined, isNonEmptyArray } from 'sierra-domain/utils'
import { PanelInPanelOverlay } from 'sierra-ui/components/layout-kit/layout-panel'
import { Button, Checkbox, Spacer, Text, View } from 'sierra-ui/primitives'
import styled from 'styled-components'

type InviteViaEmailTabProps = {
  closeWithValidation: () => void
  closeDirectly: () => void
  onSave: () => void
}

const EmailSection = styled(View).attrs({
  direction: 'column',
})``

const BulkEditButton: React.FC = () => {
  const { t } = useTranslation()
  const { userAttributesConfig, openAttributeEdit } = useComposeUserInvitationConfig()
  const emails = Object.keys(userAttributesConfig)

  if (emails.length < 2) {
    return <></>
  }

  return (
    <Button variant='secondary' onClick={() => openAttributeEdit(emails)}>
      {t('manage.users.invite.bulk-editing.edit-all-users')}
    </Button>
  )
}

export const InviteViaEmailTab: React.FC<InviteViaEmailTabProps> = ({
  closeWithValidation,
  closeDirectly,
  onSave,
}) => {
  const { t } = useTranslation()
  const isSandbox = getFlag('sandbox')
  const me = useSelector(selectUser)
  const [forceEmail, setForceEmail] = useState<boolean>(false)
  const [
    missingMandatoryAttributesOpen,
    { on: openMissingMandatoryAttributesAlert, off: closeMissingMandatoryAttributesAlert },
  ] = useToggle(false)
  const [emptyUsersOpen, { on: openEmptyUsers, off: closeEmptyUsers }] = useToggle(false)
  const [isSaving, setIsSaving] = useState(false)
  const {
    hasChanged,
    sendInvites,
    validateInvites,
    closeAttributeEdit,
    editingAttributes,
    resetConfig,
    emptyUsers,
    userAttributesConfig,
  } = useComposeUserInvitationConfig()

  const notification = useNotif()
  const intercom = useIntercom()

  const save = React.useCallback(async () => {
    setIsSaving(true)
    const success = await validateInvites()

    if (success) {
      if (emptyUsers.length > 0) {
        // If there are mandatory attributes, we never show the empty users alert,
        // since we’re checking if there are empty mandatory attributes first (through success).
        // If all users have them filled in, there won’t be any empty users.
        openEmptyUsers()
        setIsSaving(false)
      } else {
        const usedEmails = await sendInvites({ forceEmail })

        if (usedEmails !== undefined && usedEmails.length > 0) {
          notification.push({ type: 'error' })
          throw new Error(
            `Attemped to send invites to ${usedEmails.join(', ')} but emails are already in use.`
          )
        }

        intercom.trackEvent('user-invited')
        notification.push({
          type: 'custom',
          level: 'success',
          body: t('manage.users.invite.invite-sent', { count: Object.keys(userAttributesConfig).length }),
        })
        closeDirectly()
        resetConfig()
        setIsSaving(false)
        onSave()
      }
    } else {
      openMissingMandatoryAttributesAlert()
      setIsSaving(false)
    }
  }, [
    validateInvites,
    emptyUsers.length,
    openEmptyUsers,
    sendInvites,
    forceEmail,
    intercom,
    notification,
    t,
    userAttributesConfig,
    closeDirectly,
    resetConfig,
    onSave,
    openMissingMandatoryAttributesAlert,
  ])

  const onConfirmSend = React.useCallback(async () => {
    setIsSaving(true)
    const usedEmails = await sendInvites({ forceEmail })

    if (usedEmails !== undefined && usedEmails.length > 0) {
      notification.push({ type: 'error' })
      throw new Error(`Attemped to send invites to ${usedEmails.join(', ')} but emails are already in use.`)
    }

    intercom.trackEvent('user-invited')
    notification.push({
      type: 'custom',
      level: 'success',
      body: t('manage.users.invite.invite-sent', { count: Object.keys(userAttributesConfig).length }),
    })
    setIsSaving(false)
    closeDirectly()
    resetConfig()
  }, [closeDirectly, forceEmail, intercom, notification, resetConfig, sendInvites, t, userAttributesConfig])

  const showForceEmail =
    isDefined(me) && isOwner(me.accessRole, me.accessLevel) && isSandbox && isNonEmptyArray(emptyUsers)

  return (
    <>
      <PanelLayout>
        <EmailSection>
          <Spacer size='none' />
          <EmailAutoComplete />
          <Spacer size='24' />
        </EmailSection>
        <UserList />

        {showForceEmail && (
          <View paddingBottom='24' justifyContent='flex-end'>
            <Checkbox
              checked={forceEmail}
              onCheckedChange={checked => {
                setForceEmail(checked === 'indeterminate' ? false : checked)
              }}
            />
            <Text>{t('manage.users.invite.force-email')}</Text>
          </View>
        )}
        <FooterWrapper>
          <View grow>
            <BulkEditButton />
          </View>
          <View>
            <Button variant='secondary' onClick={closeWithValidation}>
              {t('modal.cancel')}
            </Button>
            <Button variant='primary' loading={isSaving} onClick={save} disabled={!hasChanged || isSaving}>
              {t('manage.users.invite.send-invites')}
            </Button>
          </View>
        </FooterWrapper>
      </PanelLayout>
      {editingAttributes && <PanelInPanelOverlay onClick={closeAttributeEdit} />}
      <MissingMandatoryAttributes
        open={missingMandatoryAttributesOpen}
        onAbort={closeMissingMandatoryAttributesAlert}
      />
      <EmptyUsers
        open={emptyUsersOpen}
        isLoading={isSaving}
        onAbort={closeEmptyUsers}
        onConfirm={onConfirmSend}
      />
    </>
  )
}
