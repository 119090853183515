import _ from 'lodash'
import { Dispatch, SetStateAction, useCallback, useEffect, useState } from 'react'

export const useThrottledState = <S = undefined>(
  initialValue: S,
  throttleMs = 300,
  options?: _.ThrottleSettings
): [S, Dispatch<SetStateAction<S>>] => {
  const [value, setValue] = useState(initialValue)

  const throttledSetValue =
    // eslint-disable-next-line react-hooks/exhaustive-deps
    useCallback(_.throttle(setValue, throttleMs, options), [setValue, throttleMs])

  return [value, throttledSetValue]
}

export const useThrottledAndLiveState = <S = undefined>(
  initialValue: S,
  throttleMs = 300,
  options?: _.ThrottleSettings
): [throttledValue: S, liveValue: S, setValue: Dispatch<SetStateAction<S>>] => {
  const [liveValue, setLiveValue] = useState(initialValue)
  const [throttledValue, setThrottledValue] = useThrottledState(initialValue, throttleMs, options)

  useEffect(() => {
    setThrottledValue(liveValue)
  }, [setThrottledValue, liveValue])

  return [throttledValue, liveValue, setLiveValue]
}
