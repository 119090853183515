import { createFileRoute } from '@tanstack/react-router'
import { useEffect, useState } from 'react'
import { usePost } from 'sierra-client/hooks/use-post'
import { XRealtimeDebugThrowException } from 'sierra-domain/routes'
import { Button } from 'sierra-ui/primitives'

function Page(): JSX.Element {
  const { postWithUserErrorException } = usePost()
  const [activePostError, setActivePostError] = useState(false)
  const [activeReactError, setActiveReactError] = useState(false)

  useEffect(() => {
    if (activePostError) {
      void postWithUserErrorException(XRealtimeDebugThrowException, {})
    }
  }, [activePostError, postWithUserErrorException])

  if (activeReactError) throw Error('Error in React')

  return (
    <>
      <Button
        variant='secondary'
        onClick={(): void => {
          throw new Error('Error triggered for testing')
        }}
      >
        Error for testing
      </Button>
      <Button
        variant='secondary'
        onClick={(): void => {
          void postWithUserErrorException(XRealtimeDebugThrowException, {})
        }}
      >
        API error for testing
      </Button>
      <Button
        variant='secondary'
        onClick={(): void => {
          setActivePostError(!activePostError)
        }}
      >
        Activate effect for api error testing
      </Button>
      <Button
        variant='secondary'
        onClick={(): void => {
          setActiveReactError(!activeReactError)
        }}
      >
        Activate error in React
      </Button>
    </>
  )
}

export const Route = createFileRoute('/admin/error')({
  component: Page as React.FC,
})
