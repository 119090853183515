import { useSetAtom } from 'jotai'
import { useDashboardCollaborators } from 'sierra-client/features/insights/api-hooks/use-dashboard-collaborators'
import { invalidateGetDashboard } from 'sierra-client/features/insights/api-hooks/use-get-dashboard'
import { invalidateListDashboards } from 'sierra-client/features/insights/api-hooks/use-list-dashboards'
import { useSetDashboardCollaboratorRoles } from 'sierra-client/features/insights/api-hooks/use-set-dashboard-collaborator-roles'
import { latestVisitedDashboardAtom } from 'sierra-client/features/insights/atoms'
import { useTranslation } from 'sierra-client/hooks/use-translation'
import { getGlobalRouter } from 'sierra-client/router'
import { useSelector } from 'sierra-client/state/hooks'
import { selectUser } from 'sierra-client/state/user/user-selector'
import { getAvatarImage } from 'sierra-client/utils/avatar-img'
import { InsightsDashboardCollaborator, InsightsDashboardRole } from 'sierra-domain/api/insights'
import { Avatar } from 'sierra-domain/api/manage'
import { NanoId12 } from 'sierra-domain/api/nano-id'
import { MenuItem, RoundAvatar, Tooltip } from 'sierra-ui/components'
import { ScrollView, Text, View } from 'sierra-ui/primitives'
import { SingleSelectDropdown } from 'sierra-ui/primitives/menu-dropdown'
import { token } from 'sierra-ui/theming'
import styled from 'styled-components'

type ListCollaboratorsProps = {
  dashboardId: NanoId12
  editRightMenuItems: MenuItem[]
}

const Container = styled(ScrollView)`
  max-height: 250px;
`

const MemberContainer = styled(View)`
  border-bottom: 1px solid ${token('border/default')};
`

type NewRoleItemId = InsightsDashboardRole | 'remove'

export const ListCollaborators: React.FC<ListCollaboratorsProps> = ({ dashboardId, editRightMenuItems }) => {
  const { t } = useTranslation()
  const collaborators = useDashboardCollaborators(dashboardId)
  const currentUser = useSelector(selectUser)
  const setDashboardCollaboratorsMutation = useSetDashboardCollaboratorRoles()
  const setLatestVisitedDashboard = useSetAtom(latestVisitedDashboardAtom)

  const handleSetCollaboratorRole = (
    collaborator: InsightsDashboardCollaborator,
    newRole: NewRoleItemId
  ): void => {
    const newCollaborator = {
      [collaborator.identity.identity.id]:
        newRole === 'remove'
          ? { type: 'unassign' as const, role: collaborator.role }
          : {
              type: 'assign' as const,
              role: newRole,
            },
    }

    setDashboardCollaboratorsMutation.mutate(
      {
        users: collaborator.identity.identity.type === 'user' ? newCollaborator : {},
        groups: collaborator.identity.identity.type === 'userGroup' ? newCollaborator : {},
        dashboardId,
      },
      {
        onSuccess: async (data, variables) => {
          if (collaborator.identity.identity.id === currentUser?.uuid) {
            // since 'role' is a part of these endpoints, we need to invalidate those caches
            await invalidateGetDashboard({ dashboardId: variables.dashboardId })
            await invalidateListDashboards()

            if (newRole === 'remove') {
              setLatestVisitedDashboard(undefined)
              await getGlobalRouter().navigate({ to: '/manage/insights', replace: true })
            }
          }
        },
      }
    )
  }

  const menuItemsWithDelete = (disableDelete: boolean): MenuItem[] => {
    return [
      ...editRightMenuItems,
      {
        id: 'remove',
        type: 'label',
        label: t('dictionary.remove'),
        color: 'destructive/background',
        disabled: disableDelete,
      },
    ]
  }

  if (collaborators.data === undefined) return <></>

  const disableOwnerDelete = collaborators.data.collaborators.filter(user => user.role === 'owner').length < 2

  const getUserAvatarImage = (userId: string, avatar: Avatar): string | undefined => {
    if (avatar.type !== 'image') {
      return undefined
    }
    switch (avatar.image.type) {
      case 'url':
        return avatar.image.url
      case 'file':
        return getAvatarImage(userId, avatar.image.file)
      default:
        return undefined
    }
  }

  return (
    <Container>
      {collaborators.data.collaborators.map(collaborator => {
        const disabledBecauseOnlyOwner = collaborator.role === 'owner' && disableOwnerDelete

        return (
          <MemberContainer key={collaborator.identity.identity.id} justifyContent='space-between'>
            <View padding='12'>
              <div className='avatar'>
                {
                  <RoundAvatar
                    size='small'
                    color={
                      collaborator.identity.avatar.type === 'color'
                        ? collaborator.identity.avatar.color
                        : undefined
                    }
                    firstName={collaborator.identity.name}
                    src={getUserAvatarImage(collaborator.identity.identity.id, collaborator.identity.avatar)}
                  />
                }
              </div>
              <Text bold>{collaborator.identity.name}</Text>
              {currentUser?.uuid === collaborator.identity.identity.id && (
                <Text bold color='foreground/muted'>
                  {t('teamspace.edit-modal.you')}
                </Text>
              )}
            </View>
            <Tooltip
              title={
                disabledBecauseOnlyOwner
                  ? t('manage.insights.share-dashboard-form.last-owner-tooltip')
                  : undefined
              }
            >
              <View
                data-testid={`access-level-${
                  collaborator.identity.identity.type === 'user'
                    ? collaborator.identity.email
                    : collaborator.identity.name
                }`}
              >
                <SingleSelectDropdown
                  variant='ghost'
                  disabled={disabledBecauseOnlyOwner}
                  selectedItem={editRightMenuItems.find(menuItem => menuItem.id === collaborator.role)}
                  menuItems={menuItemsWithDelete(disabledBecauseOnlyOwner)}
                  onSelect={item => {
                    handleSetCollaboratorRole(collaborator, item.id as NewRoleItemId)
                  }}
                />
              </View>
            </Tooltip>
          </MemberContainer>
        )
      })}
    </Container>
  )
}
