import { FC } from 'react'
import { useSpeakerNoteContext } from 'sierra-client/views/flexible-content/editor/content-sidebar/speaker-notes/context'
import {
  CreateSpeakerNotes,
  LiveSpeakerNotes,
} from 'sierra-client/views/flexible-content/editor/content-sidebar/speaker-notes/index'
import { File } from 'sierra-domain/flexible-content/types'

type SpeakerNoteProps = { file: File }

export const CreateSpeakerNoteInput: FC<SpeakerNoteProps & { editable: boolean }> = ({ file, editable }) => {
  const { focusedSpeakerNote } = useSpeakerNoteContext()

  return <CreateSpeakerNotes file={file} editable={editable} active={focusedSpeakerNote === file.id} />
}

export const LiveSpeakerNoteInput: FC<SpeakerNoteProps & { active: boolean }> = ({ file, active }) => {
  return <LiveSpeakerNotes file={file} editable={false} active={active} />
}
