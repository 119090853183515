import { produce } from 'immer'
import _ from 'lodash'
import { normalizeOutline } from 'sierra-client/features/program/admin/edit/normalize'
import { ActionWithType } from 'sierra-client/features/program/admin/edit/types'
import { ProgramTracking } from 'sierra-client/views/manage/programs/hooks/use-tracking'
import { getSectionStepRange } from 'sierra-client/views/manage/programs/staggered-assignments/renderer/utils'
import {
  createScheduleOffset,
  getStepId,
  isContentProgramStep,
} from 'sierra-client/views/manage/programs/staggered-assignments/utils'
import { ProgramOutline, ProgramStep } from 'sierra-domain/api/manage'
import { assertNever, isDefined, isNotDefined } from 'sierra-domain/utils'

export const moveStep = (
  action: ActionWithType<'MOVE_STEP'>,
  outline: ProgramOutline,
  tracking: ProgramTracking
): ProgramOutline => {
  const { fromPosition, toPosition, collapsed, location = 'above', destinationLevel = 'section' } = action

  const maybeStep = outline.steps.at(fromPosition)

  if (maybeStep) {
    tracking.program.step.dragDrop(getStepId(maybeStep), maybeStep.type)
  }

  const result = produce(outline, draft => {
    const dropTarget = draft.steps[toPosition]
    const moving = draft.steps.splice(fromPosition, 1)[0]

    if (moving === undefined) {
      return outline
    }

    const fromSectionIndex = moving.sectionIndex

    if (isDefined(fromSectionIndex)) {
      tracking.program.section.removeStep({ sectionIndex: fromSectionIndex })
    }

    /**
     * The index we insert the item back into depends on two things:
     * 1. Is the item dropped above or below the "drop target"?
     * 2. Is the item dragged downwards in the list, causing a shift when spliced?
     */
    const insertIndex =
      (location === 'above' ? toPosition : toPosition + 1) + (toPosition > fromPosition ? -1 : 0)

    if (destinationLevel === 'section') {
      moving.sectionIndex = dropTarget?.sectionIndex ?? undefined
      if (isDefined(moving.sectionIndex)) {
        tracking.program.section.addStep({
          sectionIndex: moving.sectionIndex,
          collapsed: collapsed ?? false,
        })
      }
    } else {
      moving.sectionIndex = undefined
    }

    draft.steps.splice(insertIndex, 0, moving)

    /**
     * Figure out which self-positioned sections needs to be adjusted
     * based on the move operation. Basically, the section will be affected
     * if the number of steps above it either increases or decreases.
     */
    draft.sections.forEach(section => {
      if (isDefined(section.selfIndex)) {
        const selfIndex = section.selfIndex
        const shouldAdjustPosition =
          (selfIndex <= fromPosition && selfIndex >= toPosition) ||
          (selfIndex >= fromPosition && selfIndex <= toPosition)
        const adjustment = toPosition <= fromPosition ? 1 : -1

        section.selfIndex += shouldAdjustPosition ? adjustment : 0
      }
    })

    /**
     * After we moved an item within the program, we need to
     * make sure the that the new first step isn't running with
     * a completion-based schedule. If it is, we change it to a
     * relative schedule instead.
     */
    const first = draft.steps[0]
    if (first?.schedule.type === 'on-previous-steps-completion') {
      first.schedule = {
        type: 'relative',
        offset: first.schedule.offset ?? createScheduleOffset(0),
      }
    }

    // Check if the "sending" section now needs a selfIndex
    const senderNeedsSelfIndex = isDefined(fromSectionIndex)
      ? draft.steps.reduce((sum, s) => (s.sectionIndex === fromSectionIndex ? sum + 1 : sum), 0) === 0
      : false

    if (senderNeedsSelfIndex && isDefined(fromSectionIndex)) {
      const fromSection = draft.sections[fromSectionIndex]

      if (isDefined(fromSection)) {
        const spliceOffset = fromPosition > toPosition ? 1 : 0
        // if we're dragging a step out of a section and placing it
        // directly above the empty section, we want to push the section down by one
        const sameIndexOffset = fromPosition === toPosition && location === 'above' ? 1 : 0

        fromSection.selfIndex = fromPosition + spliceOffset + sameIndexOffset
      }
    }
  })

  return normalizeOutline(result)
}

export const moveSection = (
  action: ActionWithType<'MOVE_SECTION'>,
  outline: ProgramOutline,
  tracking: ProgramTracking
): ProgramOutline => {
  const result = produce(outline, draft => {
    const section = draft.sections[action.sectionIndex]
    const stepCount = draft.steps.reduce(
      (count, s) => (s.sectionIndex === action.sectionIndex ? count + 1 : count),
      0
    )

    if (!isDefined(section)) {
      return
    }

    tracking.program.section.moveSection({ sectionIndex: action.sectionIndex })

    if (stepCount === 0) {
      section.selfIndex = action.position === 'above' ? action.to : action.to + 1
    } else {
      const range = getSectionStepRange(action.sectionIndex, outline)

      if (range === null) {
        return
      }

      const { start, end } = range

      // Pop all steps that are in the section
      const count = end - start + 1
      const steps = draft.steps.splice(start, count)

      /**
       * The index we insert the item back into depends on two things:
       * 1. Is the item dropped above or below the "drop target"?
       * 2. Is the item dragged downwards in the list, causing a shift when spliced?
       */
      const toLocation = action.position === 'above' ? action.to : action.to + 1
      const spliceOffset = action.to > end ? -count : 0
      const insertIndex = toLocation + spliceOffset

      draft.steps.splice(insertIndex, 0, ...steps)
    }
  })

  return normalizeOutline(result)
}

export const resetOutline = (action: ActionWithType<'RESET_OUTLINE'>): ProgramOutline => {
  return action.initialOutline
}

export const ungroupSection = (
  action: ActionWithType<'UNGROUP_SECTION'>,
  outline: ProgramOutline,
  tracking: ProgramTracking
): ProgramOutline => {
  const result = produce(outline, draft => {
    // Remove the section
    draft.sections.splice(action.sectionIndex, 1)

    // Update all steps, accounting for the deleted section
    draft.steps.forEach(step => {
      if (isDefined(step.sectionIndex)) {
        if (step.sectionIndex === action.sectionIndex) {
          step.sectionIndex = null
        } else if (action.sectionIndex < step.sectionIndex) {
          step.sectionIndex -= 1
        }
      }
    })
  })

  tracking.program.section.ungroup({ sectionIndex: action.sectionIndex })
  return normalizeOutline(result)
}

export const deleteSection = (
  action: ActionWithType<'DELETE_SECTION'>,
  outline: ProgramOutline,
  tracking: ProgramTracking
): ProgramOutline => {
  const result = produce(outline, draft => {
    // Remove the section instance
    draft.sections.splice(action.sectionIndex, 1)

    // Remove all the steps that referenced the section
    draft.steps = draft.steps.filter(step => !(step.sectionIndex === action.sectionIndex))

    // Update remaining steps, accounting for the deleted section
    draft.steps.forEach(step => {
      if (isDefined(step.sectionIndex)) {
        if (step.sectionIndex > action.sectionIndex) {
          step.sectionIndex -= 1
        }
      }
    })
  })

  tracking.program.section.remove({ sectionIndex: action.sectionIndex })
  return normalizeOutline(result)
}

export const patchSection = (
  action: ActionWithType<'PATCH_SECTION'>,
  outline: ProgramOutline
): ProgramOutline => {
  const result = produce(outline, draft => {
    const changingSection = draft.sections[action.sectionIndex]

    if (changingSection === undefined) {
      return
    }

    changingSection.title =
      action.section.title === undefined ? changingSection.title : action.section.title ?? ''
    changingSection.description =
      action.section.description === undefined
        ? changingSection.description
        : action.section.description ?? ''
  })

  return result
}

export const removeStep = (
  action: ActionWithType<'REMOVE_STEP'>,
  outline: ProgramOutline,
  tracking: ProgramTracking
): ProgramOutline => {
  const maybeStep = outline.steps.at(action.stepIndex)
  if (maybeStep) {
    tracking.program.step.removed(getStepId(maybeStep), maybeStep.type)
    if (isDefined(maybeStep.sectionIndex)) {
      tracking.program.section.removeStep({ sectionIndex: maybeStep.sectionIndex })
    }
  }

  const result = produce(outline, draft => {
    draft.steps.splice(action.stepIndex, 1)
  })
  return normalizeOutline(result)
}

const getStepMetadata = (s: ProgramStep): object => {
  switch (s.type) {
    case 'email':
      return {
        image: s.image,
        title: s.title,
        description: s.description,
      }
    case 'path':
      return {
        title: s.title,
      }
    case 'course':
      return {
        title: s.title,
        courseKind: s.courseKind,
      }
    default:
      assertNever(s)
  }
}

export const addSteps = (
  action: ActionWithType<'ADD_STEPS'>,
  outline: ProgramOutline,
  tracking: ProgramTracking
): ProgramOutline => {
  const result = produce(outline, draft => {
    action.steps.forEach(s => {
      draft.steps.push(s)
      tracking.program.step.save(getStepId(s), s.type, getStepMetadata(s))
    })
  })

  return normalizeOutline(result)
}

export const updateStep = (
  action: ActionWithType<'UPDATE_STEP'>,
  outline: ProgramOutline,
  tracking: ProgramTracking
): ProgramOutline => {
  const updatedOutline = produce(outline, draft => {
    const updateStepIndex = draft.steps.findIndex(s => getStepId(s) === getStepId(action.updatedStep))
    if (updateStepIndex < 0) {
      throw new Error(`Could not find step ${getStepId(action.updatedStep)} while updating.`)
    }

    draft.steps.splice(updateStepIndex, 1, action.updatedStep)
  })
  tracking.program.step.edit(
    getStepId(action.updatedStep),
    action.updatedStep.type,
    getStepMetadata(action.updatedStep)
  )
  return normalizeOutline(updatedOutline)
}

export const addSection = (outline: ProgramOutline, tracking: ProgramTracking): ProgramOutline => {
  const result = produce(outline, draft => {
    const section = {
      draftId: draft.sections
        .reduce((count, section) => ('draftId' in section ? count + 1 : count), 0)
        .toString(),
      title: 'New section',
    }

    draft.sections.push(section)
  })
  tracking.program.section.add({ sectionIndex: result.sections.length - 1 })
  return result
}

export const addStepToEmptySection = (
  action: ActionWithType<'ADD_STEP_TO_EMPTY_SECTION'>,
  outline: ProgramOutline,
  tracking: ProgramTracking
): ProgramOutline => {
  const result = produce(outline, draft => {
    const section = draft.sections[action.sectionIndex]
    const stepIndex = draft.steps.findIndex(s => getStepId(s) === action.stepId)
    const step = draft.steps.splice(stepIndex, 1)[0]
    const stepCount = draft.steps.reduce(
      (count, step) => (step.sectionIndex === action.sectionIndex ? count + 1 : count),
      0
    )

    if (isNotDefined(step) || isNotDefined(section)) {
      return
    }

    tracking.program.section.addStep({ sectionIndex: action.sectionIndex, collapsed: false })

    const fromSectionIndex = step.sectionIndex

    if (stepCount > 0) {
      throw Error('Section is not empty')
    }

    // Insert the step back into the outline structure at the right index
    const spliceOffset = stepIndex < (section.selfIndex ?? 0) ? 1 : 0
    const at = isDefined(section.selfIndex) ? section.selfIndex - spliceOffset : draft.steps.length
    draft.steps.splice(at, 0, step)
    step.sectionIndex = action.sectionIndex

    // This section no longer needs a selfIndex
    section.selfIndex = undefined

    // Check if the "sending" section now needs a selfIndex
    const senderNeedsSelfIndex = isDefined(fromSectionIndex)
      ? draft.steps.reduce((sum, s) => (s.sectionIndex === fromSectionIndex ? sum + 1 : sum), 0) === 0
      : false

    if (senderNeedsSelfIndex && isDefined(fromSectionIndex)) {
      const fromSection = draft.sections[fromSectionIndex]

      if (isDefined(fromSection)) {
        fromSection.selfIndex = stepIndex
      }
    }
  })

  return normalizeOutline(result)
}

export const updateDueDate = (
  action: ActionWithType<'UPDATE_DUE_DATE'>,
  outline: ProgramOutline
): ProgramOutline => {
  const result = produce(outline, draft => {
    const step = draft.steps[action.stepIndex]

    if (step === undefined || !isContentProgramStep(step)) {
      return
    }

    step.dueDate = action.dueDate
  })

  return result
}

export const updateSchedule = (
  action: ActionWithType<'UPDATE_SCHEDULE'>,
  outline: ProgramOutline,
  tracking: ProgramTracking
): ProgramOutline => {
  const maybeStep = outline.steps.at(action.stepIndex)

  if (maybeStep && !_.isEqual(maybeStep.schedule, action.schedule)) {
    tracking.program.step.setStaggeredAssignmentTiming(getStepId(maybeStep), maybeStep.type, action.schedule)
  }

  const result = produce(outline, draft => {
    const step = draft.steps[action.stepIndex]

    if (step === undefined) {
      return
    }

    step.schedule = action.schedule
  })

  return result
}

export const updateLiveSessionAssignment = (
  action: ActionWithType<'UPDATE_LIVE_SESSION_ASSIGNMENT'>,
  outline: ProgramOutline
): ProgramOutline => {
  const result = produce(outline, draft => {
    const step = draft.steps[action.stepIndex]

    if (step === undefined || !isContentProgramStep(step)) {
      return
    }

    if (action.assignment === 'auto-assign') {
      step.autoAssignLiveSession = true
      step.enableSelfEnrollment = false
    }

    if (action.assignment === 'self-enroll') {
      step.autoAssignLiveSession = false
      step.enableSelfEnrollment = true
    }

    if (action.assignment === 'manual') {
      step.autoAssignLiveSession = false
      step.enableSelfEnrollment = false
    }
  })

  return result
}
