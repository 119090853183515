import { motion } from 'framer-motion'
import { Duration } from 'luxon'

import { HeaderItemCSS } from 'sierra-client/components/liveV2/header-buttons'
import { Text } from 'sierra-ui/primitives'
import { token } from 'sierra-ui/theming'
import styled, { css } from 'styled-components'

export type TimerState = 'done' | 'warning' | 'normal'

export const TimerButton = styled(motion.div)<{
  $clickable: boolean
  $timerState: TimerState
  $light?: boolean
}>`
  will-change: transform;
  cursor: ${p => (p.$clickable ? 'pointer' : 'default')};
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 8px;
  padding: 0 0.625rem;
  ${HeaderItemCSS}

  ${p => {
    switch (p.$timerState) {
      case 'done':
        return css`
          background-color: ${p.theme.color.redVivid};

          &:hover {
            background-color: ${p.theme.color.redVivid};
          }
        `
      default: {
        if (p.$light === true) {
          return css`
            background-color: ${token('surface/default')};
            border: 1px solid ${token('border/default')};

            &:hover {
              border: 1px solid ${token('form/border/3')};
            }
          `
        }
        return css`
          background-color: ${token('border/default')};

          &:hover {
            background-color: ${token('border/strong')};
          }
        `
      }
    }
  }}

  transition: background-color 0.2s cubic-bezier(0.25, 0.1, 0.25, 1);

  & > ${Text} {
    font-variant-numeric: tabular-nums;
  }
`
export const TimerText = styled(Text)`
  line-height: 1;
  padding-right: 0.25rem;
`
export const secondsToDuration = (sec?: number): string =>
  sec !== undefined ? Duration.fromMillis(sec * 1000).toFormat('mm:ss') : ''
