import { useAtom } from 'jotai'
import _ from 'lodash'
import { DateTime } from 'luxon'
import React, { useCallback, useEffect, useMemo, useRef, useState } from 'react'
import {
  HistoryWithTargetIndexAtom,
  TargetIndexAtom,
} from 'sierra-client/editor/version-history/use-history-with-target-index-atom'
import { useIsDebugMode } from 'sierra-client/hooks/use-is-debug-mode'
import { useScrollToView } from 'sierra-client/hooks/use-scroll-to-view'
import { useToggle } from 'sierra-client/hooks/use-toggle'
import { useTranslation } from 'sierra-client/hooks/use-translation'
import { getGlobalRouter } from 'sierra-client/router'
import { useUsers } from 'sierra-client/state/users/hooks'
import { useCreateDateTime, useDateAndTimeAgo } from 'sierra-client/utils/date-utils'
import { UserIdAvatars } from 'sierra-client/views/flexible-content/editor/content-sidebar/user-id-avatars'
import { ListVirtualizer } from 'sierra-client/views/workspace/components/list-virtualizer'
import { UserId } from 'sierra-domain/api/uuid'
import { FileId } from 'sierra-domain/flexible-content/identifiers'
import { isDefined } from 'sierra-domain/utils'
import { ExpandRange, History, YjsUpdateGrouping } from 'sierra-domain/version-history/types'
import { FoldingColumn, MenuItem, Tooltip } from 'sierra-ui/components'
import { ScrollView, Skeleton, Switch, Text, View } from 'sierra-ui/primitives'
import { IconMenu } from 'sierra-ui/primitives/menu-dropdown'
import { token } from 'sierra-ui/theming'
import { dotSeparator } from 'sierra-ui/utils'
import styled, { css } from 'styled-components'

const DuplicateContainer = styled.div`
  margin-left: auto;
`

const UpdateLi = styled.li<{ $selected: boolean }>`
  background-color: ${p => (p.$selected ? token('foreground/primary').opacity(0.05) : 'transparent')};
  transition: background-color 50ms cubic-bezier(0.25, 0.1, 0.25, 1);
  border-radius: 10px;

  &:hover {
    background-color: ${p =>
      p.$selected ? token('foreground/primary').opacity(0.05) : token('foreground/primary').opacity(0.025)};
  }

  ${DuplicateContainer} {
    opacity: 0;
  }

  &:hover ${DuplicateContainer} {
    opacity: 1;
  }
`

const UpdateButton = styled.button<{ $showBorder: boolean }>`
  width: 100%;
  height: 100%;
  & > div {
    display: flex;
    flex-direction: row;
    align-items: flex-start;
    gap: 4px;
    justify-content: space-between;
    align-items: center;
    border-bottom: 1px solid ${token('border/default')};
    &:hover {
      border-color: transparent;
    }

    ${p =>
      p.$showBorder === false &&
      css`
        border-color: transparent;
      `}

    padding-block: 12px;
  }

  background: transparent;

  border-radius: 8px;
  padding-block: 0px;
  padding-inline: 16px;
  cursor: pointer;

  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
`

const UserDataSkeleton: React.FC = () => (
  <View paddingLeft='2' paddingRight='4'>
    <Skeleton $radius='100%' $height={20} $width={20} />
  </View>
)

const UserData: React.FC<{ userIds: UserId[] }> = ({ userIds }) => {
  const users = useUsers(userIds)
  const hasLoadingUsers = users.some(user => user.status === 'loading')

  if (hasLoadingUsers) return <UserDataSkeleton />
  else return <UserIdAvatars animateAvatars={false} userIds={userIds} />
}

export const VersionHistoryRightFoldingColumn = styled(FoldingColumn)`
  background-color: ${token('surface/default')};
  color: ${token('foreground/primary')};
  border: 1px solid ${token('border/default')};
  border-radius: 0.5rem;
  overflow: hidden;
`

const Scrollable = styled(ScrollView).attrs({ as: 'ul' })`
  height: 100%;
  overflow: auto;
  padding: 0 8px;
`

function useWaitForElement(ref: React.RefObject<HTMLDivElement | null>): HTMLDivElement | null {
  const [element, setElement] = useState<HTMLDivElement | null>(null)

  useEffect(() => {
    let isCancelled = false

    function loop(): void {
      if (isCancelled) return
      else if (ref.current === null) requestAnimationFrame(loop)
      else setElement(ref.current)
    }

    loop()

    return () => {
      isCancelled = true
    }
  }, [ref])

  return element
}

const RightSidebarContainer = styled.div`
  height: 100%;
  overflow: hidden;

  display: flex;
  flex-direction: column;
`
const CapitalizedText = styled(Text)`
  display: flex;
  flex-direction: row;
  gap: 4px;

  &::first-letter {
    text-transform: uppercase;
  }
`

const Month = styled(CapitalizedText)`
  color: ${token('foreground/muted')};
`

const SidebarMonth: React.FC<{ dateTime: DateTime }> = ({ dateTime }) => {
  const text = useMemo(() => dateTime.toLocaleString({ month: 'long', year: 'numeric' }), [dateTime])

  return (
    <View padding='8 16'>
      <Month color='LEGACY_DEFAULT_TEXT_COLOR_REPLACE_ASAP' size='micro' bold>
        {text}
      </Month>
    </View>
  )
}

const SidebarDate: React.FC<{ dateTime: DateTime; selected: boolean; isCurrentVersion: boolean }> = ({
  dateTime,
  selected,
  isCurrentVersion,
}) => {
  const { t } = useTranslation()
  const { formattedDate } = useDateAndTimeAgo(dateTime)

  return (
    <CapitalizedText>
      <Tooltip title={dateTime.toLocaleString(DateTime.DATETIME_FULL_WITH_SECONDS)}>
        <Text bold={selected}>{isCurrentVersion ? t('version-history.current') : formattedDate}</Text>
      </Tooltip>
    </CapitalizedText>
  )
}

type SidebarUpdate = {
  id: string
  dateTime: DateTime
  isCurrentVersion: boolean
  targetIndex: number
  type: 'yjs' | 'published-version'
  updateGrouping: YjsUpdateGrouping | undefined
  userIds: UserId[]
}

type SidebarDay = { dateTime: DateTime; type: 'month' }
type SidebarItem = SidebarUpdate | SidebarDay

const SidebarUpdateSkeleton: React.FC<{ showBorder: boolean }> = ({ showBorder }) => (
  <UpdateLi $selected={false}>
    <UpdateButton $showBorder={showBorder}>
      <div>
        <Text>
          <Skeleton $width={100} $height={16} $radius={4} />
        </Text>
        <UserDataSkeleton />
      </div>
    </UpdateButton>
  </UpdateLi>
)

const SidebarSkeleton: React.FC = () => (
  <Scrollable>
    <View padding='8 16'>
      <Skeleton $radius={4} $height={16} $width={80} />
    </View>

    <SidebarUpdateSkeleton showBorder={true} />
    <SidebarUpdateSkeleton showBorder={true} />
    <SidebarUpdateSkeleton showBorder={false} />
  </Scrollable>
)

const PublishedPill = styled.div`
  display: flex;

  padding: 2px 5px;
  border-radius: 8px;
  border: 1px solid ${token('form/border/1')};
  gap: 4px;
`

const SidebarUpdate = (props: {
  setTargetIndex: React.Dispatch<React.SetStateAction<number>>
  update: SidebarUpdate
  selected: boolean
  showBorder: boolean
  isCurrentVersion: boolean
  latestPublishedVersion: string | undefined
  setExpandedRanges: React.Dispatch<React.SetStateAction<ExpandRange[]>>
  expandedRanges: ExpandRange[]
}): JSX.Element | null => {
  const { t } = useTranslation()
  const {
    latestPublishedVersion,
    setTargetIndex,
    update,
    selected,
    showBorder,
    isCurrentVersion,
    setExpandedRanges,
  } = props

  const ref = useRef<HTMLLIElement | null>(null)
  useScrollToView(ref, { shouldScroll: selected })

  const isDebugMode = useIsDebugMode()
  const menuItems = useMemo((): MenuItem<string>[] => {
    const { updateGrouping } = update
    if (
      updateGrouping === undefined ||
      updateGrouping.hasMultipleUpdates === false ||
      // This feature is not yet ready for external users, but we will keep it for debugging
      !isDebugMode
    ) {
      return []
    }

    return [
      {
        id: 'expand',
        type: 'label',
        icon: 'expand',
        label: `Expand ${updateGrouping.numberOfUpdates} updates`,
        onClick: () => {
          setExpandedRanges(previous =>
            _.uniqWith(
              [...previous, { startingAt: updateGrouping.startOfGroup, endingAt: updateGrouping.endOfGroup }],
              _.isEqual
            )
          )
        },
      },
    ]
  }, [isDebugMode, setExpandedRanges, update])

  return (
    <UpdateLi
      ref={ref}
      $selected={selected}
      onClick={() => {
        setTargetIndex(update.targetIndex)
        if (update.type === 'published-version') {
          void getGlobalRouter().navigate({
            to: '/history/s/$',
            search: { publishVersion: update.id },
          })
        }
      }}
    >
      <UpdateButton $showBorder={showBorder}>
        <div>
          <View gap='10' wrap='wrap'>
            <SidebarDate dateTime={update.dateTime} selected={selected} isCurrentVersion={isCurrentVersion} />

            {update.type === 'published-version' && (
              <PublishedPill>
                <Text size='micro' bold color='foreground/secondary'>
                  {t('version-history.published')}
                </Text>
                {latestPublishedVersion === update.id && (
                  <>
                    <Text size='micro' color='foreground/muted'>
                      {dotSeparator}
                    </Text>
                    <Text size='micro' bold color='success/background'>
                      {t('version-history.latest')}
                    </Text>
                  </>
                )}
              </PublishedPill>
            )}
          </View>

          <UserData userIds={update.userIds} />

          {menuItems.length > 0 && (
            <IconMenu
              size='small'
              iconId='overflow-menu--vertical'
              menuItems={menuItems}
              variant='ghost'
              onSelect={() => {}}
            />
          )}
        </div>
      </UpdateButton>
    </UpdateLi>
  )
}

function groupSidebarUpdatesByMonth(updates: SidebarUpdate[]): SidebarItem[] {
  const groupedUpdates: SidebarItem[] = []

  for (const update of updates) {
    const lastItem = groupedUpdates[groupedUpdates.length - 1]

    if (lastItem === undefined || !lastItem.dateTime.hasSame(update.dateTime, 'month')) {
      groupedUpdates.push({ dateTime: update.dateTime, type: 'month' }, update)
    } else if (
      !(
        // Empty yjs updates are produced by our backend, and these are not relevant to the user
        (update.type === 'yjs' && update.userIds.length === 0)
      )
    ) {
      groupedUpdates.push(update)
    }
  }

  return groupedUpdates
}

const ScrollContainer = styled.div`
  flex: 1;
  max-height: 100%;
  overflow: hidden;
`

const SidebarContent = ({
  history,
  nodeId,
  targetIndexAtom,
  showOnlyCurrentCard,
  showOnlyPublishedVersions,
  expandedRanges,
  setExpandedRanges,
}: {
  history: History
  nodeId: FileId | undefined
  targetIndexAtom: TargetIndexAtom
  showOnlyCurrentCard: boolean
  showOnlyPublishedVersions: boolean
  expandedRanges: ExpandRange[]
  setExpandedRanges: React.Dispatch<React.SetStateAction<ExpandRange[]>>
}): JSX.Element | null => {
  const scrollRef = useRef<HTMLDivElement | null>(null)
  const scrollElement = useWaitForElement(scrollRef)

  const createDateTime = useCreateDateTime()

  const relevantUpdates = useMemo((): SidebarUpdate[] => {
    const updates: SidebarUpdate[] = history.updates
      .map((update, index, updates): SidebarUpdate => {
        const updateGrouping: YjsUpdateGrouping | undefined =
          update.type === 'yjs' ? update.updateGrouping : undefined

        return {
          id: update.id,
          type: update.type,
          targetIndex: index,
          isCurrentVersion: index === updates.length - 1,
          dateTime: createDateTime(update.createdAt),
          userIds: update.userIds,
          updateGrouping,
        }
      })
      .reverse()

    if (showOnlyPublishedVersions) {
      return updates.filter(update => update.type === 'published-version')
    } else if (showOnlyCurrentCard) {
      return updates.filter(update => {
        if (nodeId === undefined) return true
        return history.yjsUpdatesMeta[nodeId]?.some(it => it.targetIndex === update.targetIndex)
      })
    } else {
      return updates
    }
  }, [
    history.updates,
    history.yjsUpdatesMeta,
    showOnlyPublishedVersions,
    showOnlyCurrentCard,
    createDateTime,
    nodeId,
  ])

  const sidebarItems = useMemo(() => groupSidebarUpdatesByMonth(relevantUpdates), [relevantUpdates])

  const [targetIndex, setTargetIndex] = useAtom(targetIndexAtom)

  const isSelected = useCallback(
    (index: number) => {
      const update = sidebarItems[index]
      if (update === undefined) return false
      if (update.type === 'month') return false
      return targetIndex === update.targetIndex
    },
    [sidebarItems, targetIndex]
  )

  const latestPublishedVersion = useMemo(() => {
    return _.maxBy(history.updates, update => update.type === 'published-version' && update.createdAt)?.id
  }, [history.updates])

  return (
    <ScrollContainer
      onKeyDown={e => {
        const currentRelevantUpdateIndex = relevantUpdates.findIndex(
          update => update.targetIndex === targetIndex
        )
        if (currentRelevantUpdateIndex === -1) return

        const nextRelevantTargetIndex = relevantUpdates[currentRelevantUpdateIndex + 1]?.targetIndex
        const previousRelevantTargetIndex = relevantUpdates[currentRelevantUpdateIndex - 1]?.targetIndex
        if (e.key === 'ArrowDown') {
          if (isDefined(nextRelevantTargetIndex)) setTargetIndex(nextRelevantTargetIndex)
          e.preventDefault()
        }
        if (e.key === 'ArrowUp') {
          if (isDefined(previousRelevantTargetIndex)) setTargetIndex(previousRelevantTargetIndex)
          e.preventDefault()
        }
      }}
    >
      <Scrollable ref={scrollRef} grow gap='none'>
        <ListVirtualizer<SidebarItem>
          scrollElement={scrollElement}
          items={sidebarItems}
          estimateSize={64} // Estimated size of UpdateContainer element in the DOM.
          renderItem={(item, index) =>
            item.type === 'month' ? (
              <SidebarMonth key={item.dateTime.toMillis()} dateTime={item.dateTime} />
            ) : (
              <SidebarUpdate
                expandedRanges={expandedRanges}
                setExpandedRanges={setExpandedRanges}
                latestPublishedVersion={latestPublishedVersion}
                key={item.id}
                update={item}
                setTargetIndex={setTargetIndex}
                selected={isSelected(index)}
                isCurrentVersion={item.isCurrentVersion}
                showBorder={
                  // Do not show a border on the last element
                  index !== sidebarItems.length - 1 &&
                  // Do not show a border if the next item is selected
                  !isSelected(index + 1)
                }
              />
            )
          }
        />
      </Scrollable>
    </ScrollContainer>
  )
}

const HorizontalLine = styled.div`
  margin: 0 36px 0 24px;
  border-top: 1px solid ${token('border/default')};
`

const SwitchContainer = styled(View)`
  padding: 24px 16px 24px 20px;
  * {
    color: ${token('foreground/muted')};
    gap: 8px;
  }
`

export const VersionHistoryRightSidebar = ({
  nodeId,
  historyWithTargetIndexAtom,
  expandedRanges,
  setExpandedRanges,
}: {
  nodeId: FileId | undefined
  historyWithTargetIndexAtom: HistoryWithTargetIndexAtom | undefined
  expandedRanges: ExpandRange[]
  setExpandedRanges: React.Dispatch<React.SetStateAction<ExpandRange[]>>
}): JSX.Element | null => {
  const { t } = useTranslation()
  const [showOnlyCurrentCard, { toggle: toggleShowOnlyCurrentCard }] = useToggle(false)
  const [showOnlyPublishedVersions, { toggle: toggleShowOnlyPublishedVersions }] = useToggle(false)
  const hasPublishedVersions =
    historyWithTargetIndexAtom?.history.updates.some(update => update.type === 'published-version') ?? false

  return (
    <RightSidebarContainer>
      <View padding='16 24' direction='column'>
        <Text bold>{t('version-history.right-sidebar-title')}</Text>
        <Text color='foreground/muted'>{t('version-history.right-sidebar-description')}</Text>
      </View>
      <HorizontalLine />
      {historyWithTargetIndexAtom !== undefined ? (
        <SidebarContent
          expandedRanges={expandedRanges}
          setExpandedRanges={setExpandedRanges}
          nodeId={nodeId}
          targetIndexAtom={historyWithTargetIndexAtom.targetIndexAtom}
          history={historyWithTargetIndexAtom.history}
          showOnlyPublishedVersions={showOnlyPublishedVersions}
          showOnlyCurrentCard={showOnlyCurrentCard}
        />
      ) : (
        <SidebarSkeleton />
      )}
      <HorizontalLine />

      <SwitchContainer direction='column' gap='12'>
        {hasPublishedVersions && (
          <Switch
            size='small'
            checked={showOnlyPublishedVersions}
            onChange={toggleShowOnlyPublishedVersions}
            text={t('version-history.show-only-published')}
            // We do not yet have support for filtering published courses by the current card
            disabled={showOnlyCurrentCard}
          />
        )}
        <Switch
          size='small'
          checked={showOnlyCurrentCard}
          onChange={toggleShowOnlyCurrentCard}
          text={t('version-history.right-sidebar-full-history-toggle')}
        />
      </SwitchContainer>
    </RightSidebarContainer>
  )
}
