import { UseQueryResult } from '@tanstack/react-query'
import { graphql } from 'sierra-client/api/graphql/gql'
import { GetSubmissionForReviewQuery } from 'sierra-client/api/graphql/gql/graphql'
import { useGraphQuery } from 'sierra-client/api/hooks/use-graphql-query'

const reviewSubmissionsQuery = graphql(`
  query GetSubmissionForReview($submissionId: HomeworkSubmissionId!) {
    homeworkSubmission(submissionId: $submissionId) {
      assignedAt
      dueDate
      gradeStatus
      feedback
      criteria {
        id
        data {
          __typename
          ... on SubmissionReviewCriteria1to5 {
            rating
          }
        }
      }
      previousSubmissions {
        gradeStatus
        reviewerId
        feedback
        submission {
          __typename
          id
          createdAt
          ... on FileHomeworkSubmission {
            fileId
            fileName
            fileType
            fileSize
            fileUrl
          }
          ... on TextHomeworkSubmission {
            text
          }
        }
      }
      homework {
        id
        cardId
        title
        reviewInstructions {
          tiptapJSONData
        }
        course {
          courseId
          title
        }
        reviewCriteria {
          id
          title
          data {
            __typename
          }
        }
        # numberOfSubmissions?
        # expectedNumberOfSubmissions?
      }
      submitter {
        id
        firstName
        lastName
        email
        status
        avatar {
          ...AvatarFragment
        }
      }
      submission {
        __typename
        id
        createdAt
        ... on FileHomeworkSubmission {
          fileId
          fileName
          fileType
          fileSize
          fileUrl
        }
        ... on TextHomeworkSubmission {
          text
        }
      }
    }
  }
`)

export const useSubmissionForReview = (
  submissionId: string
): UseQueryResult<GetSubmissionForReviewQuery, unknown> => {
  return useGraphQuery(
    {
      document: reviewSubmissionsQuery,
      queryOptions: { staleTime: 60 * 1000, refetchOnWindowFocus: false, refetchOnMount: true, gcTime: 0 },
    },
    {
      submissionId,
    }
  )
}
