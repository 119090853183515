import { createEntityAdapter } from '@reduxjs/toolkit'
import { UserId } from 'sierra-domain/api/uuid'
import { LightUser } from 'sierra-domain/user'

export type UserResult =
  | { status: 'loading'; uuid: UserId }
  | { status: 'not-found'; uuid: UserId }
  | ({ status: 'loaded'; uuid: UserId } & LightUser)

export const userAdapter = createEntityAdapter({ selectId: (item: UserResult) => item.uuid })

export type UsersState = {
  queuedUserIds: UserId[]
  adapter: ReturnType<typeof userAdapter.getInitialState>
}
