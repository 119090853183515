/**
 * Helper for preventing orphans in strings
 */
export const getStringWithoutOrphan = (str: string): string => {
  const words = str.trim().split(' ')
  if (words.length > 2) {
    const lastWords = words.slice(-2).join('\xa0')
    words.splice(-2, 2, lastWords)
    return words.join(' ')
  }
  return str
}
