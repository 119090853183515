import { createFileRoute } from '@tanstack/react-router'
import { requireLoggedIn } from 'sierra-client/core/require-logged-in'
import { CoursePage } from 'sierra-client/views/learner/course/course-page'
import { CourseId, PathId } from 'sierra-domain/api/nano-id'
import { z } from 'zod'

const RouteComponent = (): JSX.Element => {
  const courseId = Route.useParams({ select: params => params.courseId })
  return <CoursePage courseId={courseId} />
}

export const Route = createFileRoute('/path/$pathId/course/$courseId/')({
  component: requireLoggedIn(RouteComponent, { allowScorm: true }) as React.FC,
  params: {
    parse: z.object({ pathId: PathId, courseId: CourseId }).parse,
    stringify: p => p,
  },
})
