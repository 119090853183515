import { DateTime, Interval } from 'luxon'
import { toLocalTimeFormat, useGetDaysLeft, useGetFormattedTime } from 'sierra-client/core/format'
import { useTranslation } from 'sierra-client/hooks/use-translation'
import { Trans } from 'sierra-client/hooks/use-translation/trans'
import { TranslationKey } from 'sierra-client/hooks/use-translation/types'
import { GradeStatus } from 'sierra-domain/api/homework'
import { Button, Text } from 'sierra-ui/primitives'
import { TypographicBase } from 'sierra-ui/primitives/typography'
import { token } from 'sierra-ui/theming'
import styled from 'styled-components'

export const NoWrapText = styled(Text)`
  font-variant-numeric: tabular-nums;
  white-space: nowrap;
  text-transform: capitalize;
`

const NoWrapLowercaseText = styled(NoWrapText)`
  text-transform: lowercase;
`

export const TimeEstimate: React.FC<{
  timeEstimate: number
  timeLeft?: boolean
  color?: TypographicBase['color'] | 'currentColor'
}> = ({ timeEstimate, timeLeft, color }) => {
  const formattedTime = useGetFormattedTime(timeEstimate, false)
  const { t } = useTranslation()

  return (
    <NoWrapText size='small' color={color ?? 'foreground/primary'}>
      {formattedTime}
      {timeLeft === true && ' ' + t('time.remaining')}
      {/* todo(damjan): localize */}
    </NoWrapText>
  )
}

export const DueDate: React.FC<{
  dueDate: string

  color?: TypographicBase['color'] | 'currentColor'
}> = ({ dueDate, color }) => {
  const { getFormattedDaysLeft } = useGetDaysLeft()

  return (
    <NoWrapLowercaseText size='small' color={color ?? 'foreground/primary'}>
      ,&nbsp;{getFormattedDaysLeft(dueDate)}
    </NoWrapLowercaseText>
  )
}

function toDateTime(date: Date | string): DateTime {
  return typeof date === 'string' ? DateTime.fromISO(date) : DateTime.fromJSDate(date)
}

export const DaysToSession: React.FC<{
  startDate: Date | string
  endDate: Date | string
  showRange?: boolean
  color?: TypographicBase['color'] | 'currentColor'
}> = ({ startDate, endDate, showRange, color }) => {
  const { getFormattedDaysTo } = useGetDaysLeft()

  const sessionInterval = Interval.fromDateTimes(toDateTime(startDate), toDateTime(endDate))
  const sessionIsNow = sessionInterval.contains(DateTime.now())
  const startTime = toLocalTimeFormat(toDateTime(startDate))
  const endTime = toLocalTimeFormat(toDateTime(endDate))

  return (
    <NoWrapText size='small' color={color ?? 'foreground/primary'}>
      {getFormattedDaysTo(startDate, endDate, showRange)?.formattedDate}
      {sessionIsNow && `, ${startTime} - ${endTime}`}
    </NoWrapText>
  )
}

export const HomeworkGradeStatus: React.FC<{
  gradeStatus: GradeStatus
  color?: TypographicBase['color'] | 'currentColor'
}> = ({ gradeStatus, color }) => {
  switch (gradeStatus) {
    case 'not-submitted':
      return (
        <NoWrapText size='small' color={color ?? 'foreground/primary'}>
          Waiting for submission
        </NoWrapText>
      )
    case 'not-graded':
      return (
        <NoWrapText size='small' color={color ?? 'foreground/primary'}>
          Waiting for review
        </NoWrapText>
      )
    case 'failed':
      return (
        <NoWrapText size='small' color={color ?? 'foreground/primary'}>
          Failed
        </NoWrapText>
      )
    case 'passed':
      return (
        <NoWrapText size='small' color={color ?? 'foreground/primary'}>
          Passed
        </NoWrapText>
      )
  }
}

const BoldedNumber = styled(Text).attrs({ as: 'span', bold: true })`
  margin: 0 2px;
`

const OneLineText = styled(Text)`
  white-space: nowrap;
`

export const StepsCompleted: React.FC<{
  totalCount: number
  completedCount: number
  color?: TypographicBase['color'] | 'currentColor'
}> = ({ totalCount, completedCount, color }) => {
  return (
    <OneLineText color={color ?? 'foreground/primary'}>
      <Trans
        i18nKey={'recommendation.program-step-completed-of-total' satisfies TranslationKey}
        values={{ completedCount, totalCount }}
        components={{
          bold: <BoldedNumber color={color ?? 'foreground/primary'} />,
        }}
      />
    </OneLineText>
  )
}

// The new 'ghost' variant button doesn't have a transparent background, which we need occasionally.
export const ActualGhostButton = styled(Button).attrs({
  variant: 'ghost',
})`
  color: ${token('foreground/primary')};
  background-color: transparent;

  &:hover {
    background-color: ${token('surface/soft')};
  }
`
