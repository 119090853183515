import fuzzysort from 'fuzzysort'
import { reject, unionBy } from 'lodash'
import { useCallback, useMemo, useRef, useState } from 'react'
import { useListMovableContent } from 'sierra-client/api/hooks/use-teamspace'
import { SearchInput } from 'sierra-client/components/templates/components/search-input'
import { ContentRow } from 'sierra-client/features/teamspace/components/modals/import-content-modal/content-row'
import { CourseGroupRow } from 'sierra-client/features/teamspace/components/modals/import-content-modal/course-group-row'
import { ErroImportModal } from 'sierra-client/features/teamspace/components/modals/import-content-modal/error-import-modal'
import {
  TeamspaceContentToMove,
  useMoveTeamspaceContent,
} from 'sierra-client/features/teamspace/components/move-teamspace-content'
import { useDebouncedAndLiveState } from 'sierra-client/hooks/use-debounced-state'
import { useTranslation } from 'sierra-client/hooks/use-translation'
import { useOverrideIntercomLauncherVisibility } from 'sierra-client/intercom/intercom-visibility'
import { useHasManageAccess } from 'sierra-client/views/manage/permissions/use-has-manage-access'
import { ListVirtualizer } from 'sierra-client/views/workspace/components/list-virtualizer'
import { NanoId12 } from 'sierra-domain/api/nano-id'
import { MovableContent } from 'sierra-domain/api/teamspace'
import { assertNever } from 'sierra-domain/utils'
import { ClosePanelButton, LabelMenuItem, Panel } from 'sierra-ui/components'
import { Button, Heading, LoadingSpinner, ScrollView, View } from 'sierra-ui/primitives'
import { SingleSelectDropdown } from 'sierra-ui/primitives/menu-dropdown'
import styled from 'styled-components'

const ImportModalForm = styled.form`
  flex: 1;
  display: flex;
  flex-direction: column;
  gap: 24px;
  overflow: hidden;
`

type ImportFilter = {
  contentType:
    | 'any-type'
    | 'course'
    | 'live'
    | 'scorm'
    | 'link'
    | 'linkedin'
    | 'native:event-group'
    | 'native:course-group'
    | 'scorm:course-group'
  authorship: 'any-author' | 'only-owner-content' | 'only-shared-content'
}

const includeAllFilter: ImportFilter = {
  contentType: 'any-type',
  authorship: 'any-author',
}

const rowMatchesContentTypeFilter = ({
  row,
  contentType,
}: {
  row: MovableContent
  contentType: ImportFilter['contentType']
}): boolean => {
  switch (contentType) {
    case 'any-type':
      return true
    case 'course':
      return row.kind === 'native:self-paced'
    case 'live':
      return row.kind === 'native:live'
    case 'scorm':
      return row.kind === 'scorm'
    case 'link':
      return row.kind === 'link'
    case 'linkedin':
      return row.kind === 'linkedin'
    case 'native:event-group':
      return row.kind === 'native:event-group'
    case 'native:course-group':
      return row.kind === 'native:course-group'
    case 'scorm:course-group':
      return row.kind === 'scorm:course-group'
    default:
      assertNever(contentType)
  }
}

const rowMatchesAuthorshipFilter = ({
  row,
  authorship,
}: {
  row: MovableContent
  authorship: ImportFilter['authorship']
}): boolean => {
  switch (authorship) {
    case 'any-author':
      return true
    case 'only-owner-content':
      return row.role === 'owner'
    case 'only-shared-content':
      return row.role === 'editor'
    default:
      assertNever(authorship)
  }
}

const filterRow = (filter: ImportFilter, row: MovableContent): boolean => {
  const contentTypeMatch = rowMatchesContentTypeFilter({ row, contentType: filter.contentType })
  const authorshipMatch = rowMatchesAuthorshipFilter({ row, authorship: filter.authorship })

  return contentTypeMatch && authorshipMatch
}

const ImportModalContainer = styled(View)`
  height: 100%;
  overflow: hidden;
`

const FiltersContainer = styled(View)`
  max-width: 100%;
`

const SearchWrapper = styled.div`
  flex: 1;
`

const ContentTypeDropdown: React.FC<{
  selectedContentType: ImportFilter['contentType']
  onSelect: (value: ImportFilter['contentType']) => void
}> = ({ selectedContentType, onSelect }) => {
  const { t } = useTranslation()
  const hasManageAccess = useHasManageAccess()

  const contentTypeToMenuItems: {
    [K in ImportFilter['contentType']]: LabelMenuItem<ImportFilter['contentType']>
  } = {
    'any-type': {
      id: 'any-type',
      type: 'label',
      label: t('filter.content.any-type'),
    },
    'live': {
      id: 'live',
      type: 'label',
      label: t('dictionary.live'),
    },
    'course': {
      id: 'course',
      type: 'label',
      label: t('dictionary.course-singular'),
    },
    'scorm': {
      id: 'scorm',
      type: 'label',
      label: t('manage.courses.import.scorm'),
      hidden: !hasManageAccess,
    },
    'link': {
      id: 'link',
      type: 'label',
      label: t('author.slate.link.link'),
      hidden: !hasManageAccess,
    },
    'linkedin': {
      id: 'linkedin',
      type: 'label',
      label: 'LinkedIn',
      hidden: !hasManageAccess,
    },
    'native:event-group': {
      id: 'native:event-group',
      type: 'label',
      label: t('dictionary.in-person'),
      hidden: !hasManageAccess,
    },
    'native:course-group': {
      id: 'native:course-group',
      type: 'label',
      label: t('dictionary.edition-plural'),
      hidden: !hasManageAccess,
    },
    'scorm:course-group': {
      id: 'scorm:course-group',
      type: 'label',
      label: t('dictionary.external-edition-plural'),
      hidden: !hasManageAccess,
    },
  }
  const menuItems = Object.values(contentTypeToMenuItems)
  const selectedItem = contentTypeToMenuItems[selectedContentType]

  return (
    <SingleSelectDropdown
      bold
      grow={false}
      onSelect={item => {
        onSelect(item.id)
      }}
      selectedItem={selectedItem}
      menuItems={menuItems}
    />
  )
}

const AuthorshipDropdown: React.FC<{
  selectedAuthorship: ImportFilter['authorship']
  onSelect: (value: ImportFilter['authorship']) => void
}> = ({ selectedAuthorship, onSelect }) => {
  const { t } = useTranslation()

  const authorshipToMenuItems: {
    [K in ImportFilter['authorship']]: LabelMenuItem<ImportFilter['authorship']>
  } = {
    'any-author': {
      id: 'any-author',
      type: 'label',
      label: t('filter.authorship.any-author'),
    },
    'only-owner-content': {
      id: 'only-owner-content',
      type: 'label',
      label: t('filter.authorship.only-owner-content'),
    },
    'only-shared-content': {
      id: 'only-shared-content',
      type: 'label',
      label: t('filter.authorship.only-shared-content'),
    },
  }
  const menuItems = Object.values(authorshipToMenuItems)
  const selectedItem = authorshipToMenuItems[selectedAuthorship]

  return (
    <SingleSelectDropdown
      bold
      grow={false}
      onSelect={item => {
        onSelect(item.id)
      }}
      selectedItem={selectedItem}
      menuItems={menuItems}
    />
  )
}

export const ImportContentModal: React.FC<{
  modalOpen: boolean
  onClose: () => void
  teamspaceName: string
  teamspaceId: NanoId12
  folder: {
    folderId: NanoId12
    displayName: string | undefined
  }
}> = ({ modalOpen, onClose, teamspaceName, teamspaceId, folder }) => {
  const { t } = useTranslation()
  const [failureModal, setFailureModal] = useState<'closed' | { failedItems: Array<TeamspaceContentToMove> }>(
    'closed'
  )

  useOverrideIntercomLauncherVisibility(modalOpen ? 'hidden' : 'default')

  const [contentFilter, setContentFilter] = useState<ImportFilter>(includeAllFilter)
  const [debouncedSearchTerm, liveSearchTerm, setSearchTerm] = useDebouncedAndLiveState('', { wait: 200 })
  const [contentToMove, setContentToMove] = useState<TeamspaceContentToMove[]>([])

  const movableContentQuery = useListMovableContent(teamspaceId, { enabled: modalOpen })
  const content: MovableContent[] = useMemo(() => movableContentQuery.data ?? [], [movableContentQuery.data])

  const displayedContent = useMemo(() => {
    const searchContent = debouncedSearchTerm === '' ? content : unionBy([...content], 'id')
    const sortedContent = searchContent.filter(it => filterRow(contentFilter, it))

    if (debouncedSearchTerm === '') {
      return sortedContent
    }

    const filteredContent = fuzzysort
      .go(debouncedSearchTerm, sortedContent, {
        all: true,
        key: 'settings.title',
      })
      .map(({ obj }) => obj)

    return filteredContent
  }, [content, contentFilter, debouncedSearchTerm])

  const handleClose = useCallback(() => {
    setContentToMove([])
    setFailureModal('closed')
    onClose()
  }, [onClose])

  const { moveTeamspaceContent } = useMoveTeamspaceContent()

  const onRowCheckedChange = (content: TeamspaceContentToMove, checked: boolean): void => {
    if (checked === false) {
      setContentToMove(prev => reject(prev, { id: content.id }))
    } else {
      setContentToMove(prev => {
        const exists = prev.some(c => c.id === content.id)
        return exists ? prev : prev.concat(content)
      })
    }
  }

  const handleSubmit = (options?: { skipContentToApprove: boolean }): void => {
    moveTeamspaceContent(
      {
        contentToMove,
        targetTeamspace: {
          id: teamspaceId,
          displayName: teamspaceName,
        },
        targetFolder:
          folder.displayName !== undefined
            ? { id: folder.folderId, displayName: folder.displayName }
            : undefined,
        skipContentToApprove: options?.skipContentToApprove,
      },
      {
        onSuccess: () => {
          handleClose()
        },
        onError: e => {
          setFailureModal({ failedItems: e })
        },
      }
    )
  }

  const nodeRef = useRef<HTMLDivElement | null>(null)

  return (
    <Panel
      size={{ width: 656 }}
      animation={'slide-right'}
      open={modalOpen}
      onClose={handleClose}
      disableScrollbarGutter
    >
      <ClosePanelButton onClick={handleClose} ariaLabel={t('dictionary.close')} />
      <ImportModalContainer direction='column' gap='24'>
        <View paddingTop='32' paddingLeft='40' paddingRight='40'>
          <Heading size='h5' bold>
            {t('teamspaces.import-modal.title', { title: teamspaceName })}
          </Heading>
        </View>
        <View paddingLeft='40' paddingRight='40' direction='column' gap='16'>
          <FiltersContainer grow justifyContent='space-between'>
            <SearchWrapper>
              <SearchInput term={liveSearchTerm} onChange={setSearchTerm} />
            </SearchWrapper>

            <ContentTypeDropdown
              selectedContentType={contentFilter.contentType}
              onSelect={contentType => {
                setContentFilter(prevState => ({ ...prevState, contentType }))
              }}
            />
            <AuthorshipDropdown
              selectedAuthorship={contentFilter.authorship}
              onSelect={authorship => {
                setContentFilter(prevState => ({ ...prevState, authorship }))
              }}
            />
          </FiltersContainer>
        </View>
        <ImportModalForm onSubmit={() => handleSubmit()}>
          <ScrollView ref={nodeRef} grow>
            {movableContentQuery.isPending ? (
              <>
                {/* TODO display skeleton */}
                <LoadingSpinner />
              </>
            ) : (
              <ListVirtualizer
                scrollElement={nodeRef.current}
                items={displayedContent}
                estimateSize={78}
                renderItem={item => {
                  if (item.kind === 'native:course-group' || item.kind === 'scorm:course-group') {
                    return (
                      <CourseGroupRow
                        key={item.id}
                        content={item}
                        isSelected={contentToMove.some(c => c.id === item.id)}
                        onCheckedChange={onRowCheckedChange}
                        teamspaceToImportTo={teamspaceId}
                      />
                    )
                  } else {
                    return (
                      <ContentRow
                        key={item.id}
                        content={item}
                        isSelected={contentToMove.some(c => c.id === item.id)}
                        onCheckedChange={onRowCheckedChange}
                      />
                    )
                  }
                }}
              />
            )}
          </ScrollView>
          <View paddingLeft='40' paddingRight='40' paddingBottom='32' gap='8' justifyContent='flex-end'>
            <Button
              variant='secondary'
              type='button'
              onClick={() => {
                setContentToMove([])
                onClose()
              }}
            >
              {t('dictionary.cancel')}
            </Button>
            <Button disabled={contentToMove.length === 0} type='submit'>
              {t('dictionary.add')}
            </Button>
          </View>
        </ImportModalForm>
        {failureModal === 'closed' ? null : (
          <ErroImportModal
            onOpenChange={open => {
              if (!open) {
                setFailureModal('closed')
              }
            }}
            open
            onBack={() => setFailureModal('closed')}
            onExclude={() => {
              handleSubmit({ skipContentToApprove: true })
            }}
            failedItems={failureModal.failedItems}
          />
        )}
      </ImportModalContainer>
    </Panel>
  )
}
