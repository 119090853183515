import fuzzysort from 'fuzzysort'
import _ from 'lodash'
import { useMemo } from 'react'
import {
  teamspaceContentTypeFilterRow,
  teamspacePublishStateFilterRow,
  teamspaceSortFunctions,
} from 'sierra-client/views/workspace/create-new/filters/create-page-desktop-filters'
import {
  CreatePageSelectedFilters,
  CreatePageSortFunctionId,
} from 'sierra-client/views/workspace/create-new/filters/types'
import { FolderRow, Teamspace, TeamspaceContent } from 'sierra-domain/api/teamspace'

type FilteredTeamspaceContent = { [id: string]: TeamspaceContent[] }

export const useFilteredTeamspaceContent = (
  teamspaces: Teamspace[],
  debouncedSearchTerm: string,
  selectedFilters: CreatePageSelectedFilters,
  selectedSortFunction: CreatePageSortFunctionId
): FilteredTeamspaceContent => {
  const content: FilteredTeamspaceContent = useMemo(
    () =>
      _.chain(teamspaces)
        .keyBy('id')
        .mapValues(teamspace => {
          const rootFolder = teamspace.content.find(
            (it): it is FolderRow => it.type === 'folder' && it.parentFolderId === undefined
          )

          const firstLevelContent = teamspace.content.filter(it => it.parentFolderId === rootFolder?.id)
          const allLevelsContent = teamspace.content

          const content = debouncedSearchTerm === '' ? firstLevelContent : allLevelsContent

          const sortFunction = teamspaceSortFunctions[selectedSortFunction]

          const sortedContent = sortFunction(content)
          const allContentTypes = selectedFilters.selectedContentType.length === 0

          const filteredContentType = allContentTypes
            ? sortedContent
            : selectedFilters.selectedContentType.flatMap(contentType =>
                sortedContent.filter(row => teamspaceContentTypeFilterRow(contentType, row))
              )

          const allPublishStates = selectedFilters.selectedPublishState.length === 0

          const filteredContent = allPublishStates
            ? filteredContentType
            : selectedFilters.selectedPublishState.flatMap(contentType =>
                filteredContentType.filter(row => teamspacePublishStateFilterRow(contentType, row))
              )

          if (debouncedSearchTerm === '') {
            return filteredContent
          }

          const searchContent = fuzzysort
            .go(
              debouncedSearchTerm,
              filteredContent.map(it => ({
                id: it.type === 'folder' ? it.id : it.content.id,
                displayName: it.type === 'folder' ? it.displayName : it.content.title,
                content: it,
              })),
              {
                all: true,
                limit: debouncedSearchTerm.length > 0 ? 20 : undefined,
                key: 'displayName',
              }
            )
            .map(({ obj }) => obj)

          return searchContent.map(({ content }) => content)
        })
        .value(),
    [teamspaces, debouncedSearchTerm, selectedFilters, selectedSortFunction]
  )

  return content
}
