import { isKeyHotkey } from 'is-hotkey'
import _ from 'lodash'
import {
  getCurrentlySelectedLeaf,
  getCurrentlySelectedNode,
  isElementType,
} from 'sierra-client/views/v3-author/queries'
import { nanoid12 } from 'sierra-domain/nanoid-extensions'
import { EditorKeyboardEvent } from 'sierra-domain/v3-author'
import { Editor, Element, Range, Transforms } from 'slate'

function isEditorSelectionInLink(editor: Editor): boolean {
  const [match] = Editor.nodes(editor, { match: isElementType('link') })
  return Boolean(match)
}

// Default left/right behavior is unit:'character'.
// This fails to distinguish between two cursor positions, such as
// <inline>foo<cursor/></inline> vs <inline>foo</inline><cursor/>.
// Here we modify the behavior to unit:'offset'.
// Source: https://github.com/ianstormtaylor/slate/blob/main/site/examples/inlines.tsx#L69
function fixChromiumArrowBug(editor: Editor, event: EditorKeyboardEvent): void {
  const { selection } = editor
  if (selection && Range.isCollapsed(selection) && isEditorSelectionInLink(editor)) {
    if (isKeyHotkey('left', event)) {
      event.preventDefault()
      Transforms.move(editor, { unit: 'offset', reverse: true })
      return
    }
    if (isKeyHotkey('right', event)) {
      event.preventDefault()
      Transforms.move(editor, { unit: 'offset' })
      return
    }
  }
}

export const shortcuts = (event: EditorKeyboardEvent, editor: Editor): void => {
  const currentlySelectedNode = getCurrentlySelectedNode(editor)
  const currentlySelectedLeaf = getCurrentlySelectedLeaf(editor)
  const { selection } = editor

  fixChromiumArrowBug(editor, event)

  // Link shortcut is cmd+shift+u
  if (
    event.key === 'u' &&
    event.shiftKey &&
    event.metaKey &&
    selection !== null &&
    Range.isExpanded(selection)
  ) {
    if (currentlySelectedLeaf !== undefined && currentlySelectedNode !== undefined) {
      const text = Editor.string(editor, selection)
      const [node] = currentlySelectedNode

      const startPoint = nanoid12()

      // Is not a link
      if (
        _.isEqual(selection.anchor.path, selection.focus.path) &&
        Element.isElement(node) &&
        node.type !== 'link'
      ) {
        Transforms.wrapNodes(
          editor,
          {
            type: 'link',
            id: startPoint,
            url: '',
            children: [{ text }],
          },
          { split: true }
        )
        if (editor.selection !== null) {
          Transforms.select(editor, { anchor: editor.selection.focus, focus: editor.selection.focus })
        }
      } else {
        const [match] = Editor.nodes(editor, {
          match: node => Element.isElement(node) && node.type === 'link',
        })

        if (match) {
          const [, linkPath] = match

          Transforms.unwrapNodes(editor, { at: linkPath, voids: true })
        }
      }
    }
  }
}
