import { useMemo } from 'react'
import { Helmet } from 'react-helmet-async'
import { config } from 'sierra-client/config/global-config'
import { isDefined } from 'sierra-domain/utils'

export const PageTitle: React.FC<{ title?: string | undefined }> = ({ title }) => {
  const { platformName, pageTitle } = config.organization.settings.brand
  const platformTitle = platformName ?? pageTitle ?? 'Sana'

  const titleString = useMemo(
    () => [title, platformTitle].filter(isDefined).join(' | '),
    [title, platformTitle]
  )

  return (
    <Helmet>
      <title>{titleString}</title>
    </Helmet>
  )
}
