import { useCachedQuery } from 'sierra-client/state/api'
import { LiveSessionId } from 'sierra-domain/api/nano-id'
import { XRealtimeStrategyLiveSessionGetBotData } from 'sierra-domain/routes'

export type UseSessionBotDataQueryResult = ReturnType<
  typeof useCachedQuery<typeof XRealtimeStrategyLiveSessionGetBotData>
>

export function useSessionBotDataQuery(liveSessionId: LiveSessionId): UseSessionBotDataQueryResult {
  return useCachedQuery(
    XRealtimeStrategyLiveSessionGetBotData,
    { liveSessionId },
    {
      refetchInterval(query) {
        if (query.state.data?.type === 'loading') {
          return 1000 * 60 * 1
        }
        return false
      },
    }
  )
}
