import { atom } from 'jotai'
import { atomWithStorage } from 'sierra-client/state/storage'
import { RecordingSection, StructuredQueryInput, VideoSection } from 'sierra-domain/api/search'
import { SanaResult } from 'sierra-domain/search/types/search-results'

export type AssistantJob = {
  id: string
  name: string
  description: string
  state: AssistantJobState
  loadingMessage: string
}

type AssistantJobState =
  | { type: 'queued' }
  | { type: 'pending' }
  | { type: 'success' }
  | { type: 'error'; error: string }

export const assistantJobsAtom = atom<Map<string, AssistantJob>>(new Map())

export const assistantLoadingMessageAtom = atom(
  get => [...get(assistantJobsAtom).values()].find(job => job.state.type === 'pending')?.loadingMessage
)

export const assistantQueryAtom = atom<StructuredQueryInput | undefined>(undefined)

export const assistantActiveAtom = atom<boolean>(false)

export const assistantActiveMediaAtom = atom<
  (SanaResult & { extraArgs: VideoSection | RecordingSection }) | null
>(null)

export const ShowMockDataAtom = atomWithStorage('showMockData', false)

export const assistantChatStateAtom = atom<'active' | 'passive' | 'idle'>('idle')

export const assistantChatActiveAtom = atom(
  get => get(assistantChatStateAtom) === 'active' && (get(assistantQueryAtom)?.text.length ?? 0) > 0,
  (_, set, v: boolean) => {
    set(assistantChatStateAtom, v ? 'active' : 'idle')
  }
)

export const assistantUiStateAtom = atom<'input' | 'search' | 'chat' | 'connect' | 'loading'>('input')

export const enterToViewAtom = atom(false)

export const hideGlobalAssistantAtom = atom<boolean | undefined>(undefined)
