import { palette, spacing } from 'sierra-ui/theming'
import styled from 'styled-components'

export const HorizontalLineDivider = styled.div<{
  marginRight?: boolean
  marginLeft?: boolean
  bright?: boolean
}>`
  width: 1px;
  height: 1rem;
  margin: 0 ${p => (p.marginRight === true ? spacing.xxsmall : 0)} 0
    ${p => (p.marginLeft === true ? spacing.xxsmall : 0)};
  background: ${p => (p.bright === true ? palette.primitives.white : palette.primitives.black)};
  opacity: ${p => (p.bright === true ? 0.15 : 0.1)};
`
