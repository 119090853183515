import { AvatarFragmentFragment, BasicUser } from 'sierra-client/api/graphql/gql/graphql'
import { convertGQLAvatar } from 'sierra-client/api/graphql/util/convert-gql-avatar'
import { Identity } from 'sierra-client/components/common/identities-selector/types'
import { getAvatarImage } from 'sierra-client/utils/avatar-img'
import { User } from 'sierra-domain/user'
import { AvatarColor } from 'sierra-domain/user/avatar-color'
import { getUserName } from 'sierra-domain/utils'

export const getIdentityBrand = (
  identity: Identity
): { color: AvatarColor | undefined; image: string | undefined } => {
  const image =
    identity.avatar.type === 'image' && identity.avatar.image.type === 'file'
      ? identity.avatar.image.file
      : undefined
  const color = identity.avatar.type === 'color' ? identity.avatar.color : undefined

  return {
    color,
    image: getAvatarImage(identity.id, image),
  }
}

export const mapUserToIdentity = (user: User, displaySuffix = ''): Identity => {
  const username = getUserName(user)
  return {
    id: user.uuid,
    identity: {
      type: 'user',
      id: user.uuid,
    },
    name: `${username ?? ''} ${displaySuffix}`,
    email: user.email,
    avatar: {
      type: 'color',
      initials: `${user.firstName[0] ?? ''}${user.lastName[0] ?? ''}`,
      color: user.avatarColor,
    },
  }
}

export const mapBasicUserToIdentity = (
  user: Pick<BasicUser, 'id' | 'displayName' | 'email'> & {
    avatar: AvatarFragmentFragment
  }
): Identity => {
  return {
    id: user.id,
    identity: {
      type: 'user',
      id: user.id,
    },
    name: user.displayName,
    email: user.email,
    avatar: convertGQLAvatar(user.avatar),
  }
}
