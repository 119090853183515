import { MutableRefObject, createContext, createRef, useContext } from 'react'

type MultiSelectionTimeoutRef = {
  ref: MutableRefObject<NodeJS.Timeout | null>
}

/* Used to prevent certain actions from resetting the multi selection, such as clicking an option in the multi select menu */
const MultiSelectionTimeoutContext = createContext<MultiSelectionTimeoutRef>({
  ref: createRef<NodeJS.Timeout | null>(),
})

export const MultiSelectionTimeoutProvider = MultiSelectionTimeoutContext.Provider

export const useMultiSelectionTimeoutRef = (): MultiSelectionTimeoutRef =>
  useContext(MultiSelectionTimeoutContext)
