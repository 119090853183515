import { RemoteVideoTrackStats } from 'agora-rtc-sdk-ng'
import { useSetAtom } from 'jotai'
import _ from 'lodash'
import { useEffect, useRef } from 'react'
import {
  CallStatsAtom,
  ComputePressureAtom,
  ComputePressureValue,
  LocalTracksStatsAtom,
  PerformanceStatsAtom,
  RemoteTracksStatsAtom,
  RenderRateVolatilityEmaAtom,
  StatsSchedulingTimeEmaAtom,
} from 'sierra-client/components/liveV2/live-layer/call-stats/atoms'
import { useCurrentClientContext } from 'sierra-client/components/liveV2/live-provider'
import { ExponentialMovingAverage } from 'sierra-client/lib/statistics/exponential-moving-average'
import { logger } from 'sierra-client/logger/logger'

// https://developer.chrome.com/docs/web-platform/compute-pressure/#creating-a-pressure-observer
// TODO(Jonathan) this is an experimental API with an origin trial, we should ensure we only use it if it is supported
declare global {
  interface PressureRecord {
    readonly source: 'cpu'
    readonly state: ComputePressureValue
    readonly time: number
  }

  class PressureObserver {
    constructor(callback: (records: PressureRecord[]) => void, options?: { sampleRate: number })
    observe: (source: 'cpu') => Promise<void>
    unobserve: (source: 'cpu') => void
    disconnect: () => void
  }
}

const REFRESH_RATE_MS = 500
const EMA_SECONDS_TO_MEASURE = 20

export const StatisticsCollector = (): null => {
  const rrvEmaRef = useRef(new ExponentialMovingAverage(EMA_SECONDS_TO_MEASURE * (1000 / REFRESH_RATE_MS)))
  const schedulingEmaRef = useRef(
    new ExponentialMovingAverage(EMA_SECONDS_TO_MEASURE * (1000 / REFRESH_RATE_MS))
  )

  const { client } = useCurrentClientContext()
  const setCallStats = useSetAtom(CallStatsAtom)
  const setPerformanceStats = useSetAtom(PerformanceStatsAtom)
  const setLocalTracksStats = useSetAtom(LocalTracksStatsAtom)
  const setRemoteTracksStats = useSetAtom(RemoteTracksStatsAtom)
  const setComputePressureAtom = useSetAtom(ComputePressureAtom)
  const setRenderRateVolatilityEmaAtom = useSetAtom(RenderRateVolatilityEmaAtom)
  const setStatsSchedulingTimeEmaAtom = useSetAtom(StatsSchedulingTimeEmaAtom)

  useEffect(() => {
    if ('PressureObserver' in globalThis) {
      // TODO: (Jonathan) The Compute Pressure API is an experimental API with an origin trial
      // so we can't trust that it will be available in all browsers
      // or that the data it returns will be accurate
      // we wrap it in a try catch to ensure we don't break the rest of the app if it fails
      try {
        const observer = new PressureObserver(
          records => {
            const lastRecord = records[records.length - 1]
            if (lastRecord === undefined) return
            setComputePressureAtom(lastRecord.state)
          },
          { sampleRate: 0.5 }
        )

        observer.observe('cpu').catch(error =>
          logger.error('[PressureObserver] PressureObserver.observe error', {
            error: error instanceof Error ? error : undefined,
          })
        )

        return () => {
          try {
            observer.disconnect()
          } catch (error) {
            logger.error('[PressureObserver] Error disconnecting PressureObserver', { error })
          }
          setComputePressureAtom(undefined)
        }
      } catch (error) {
        logger.error('[PressureObserver] Error creating PressureObserver', { error })
      }
    }
  }, [setComputePressureAtom])

  useEffect(() => {
    let statsTimeout: NodeJS.Timeout | undefined
    let avgFPSSamplesCollectedPerStream: number[] = []
    let lastScheduleTime: number = 0

    const calculaterenderRateVolatility = (remoteVideoStats?: {
      [uid: string]: RemoteVideoTrackStats
    }): number | undefined => {
      if (remoteVideoStats === undefined) return
      let activeVideoStreams = 0
      const totalFramesReceived = Object.values(remoteVideoStats).reduce((sum, stats) => {
        if (stats.receiveFrameRate === undefined || stats.receiveFrameRate <= 0) return sum
        activeVideoStreams++
        return sum + stats.receiveFrameRate
      }, 0)

      if (activeVideoStreams === 0) return 0
      avgFPSSamplesCollectedPerStream.push(totalFramesReceived / activeVideoStreams)

      if (avgFPSSamplesCollectedPerStream.length >= 8) {
        avgFPSSamplesCollectedPerStream = _.takeRight(avgFPSSamplesCollectedPerStream, 8)
        const meanFPS = _.mean(avgFPSSamplesCollectedPerStream)
        if (meanFPS === 0) {
          return 0
        } else {
          const sumDeviation = avgFPSSamplesCollectedPerStream.reduce(
            (sum, FPS) => sum + Math.abs(FPS - meanFPS),
            0
          )
          return (sumDeviation / 8) * (100 / meanFPS)
        }
      }
    }

    // We do the stats collection in a recursive timeout instead of an interval
    // this guaranties the next iteration isn't scheduled until the previous one is completed
    // and also helps ensure the time tracking for scheudle drift becomes is more accurate
    const fetchStats = (): void => {
      const statsSchedulingTime =
        lastScheduleTime !== 0 ? performance.now() - lastScheduleTime - REFRESH_RATE_MS : 0

      const t0 = performance.now()
      const rtcStats = client?.getRTCStats()
      const remoteVideoStats = client?.getRemoteVideoStats()
      const localVideoStats = client?.getLocalVideoStats()
      const localAudioStats = client?.getLocalAudioStats()
      const remoteAudioStats = client?.getRemoteAudioStats()
      const renderRateVolatility = calculaterenderRateVolatility(remoteVideoStats)
      const statsDurationTime = performance.now() - t0

      // don't track EMA when the tab is hidden since that makes the metrics unreliable
      if (renderRateVolatility !== undefined && !document.hidden) rrvEmaRef.current.push(renderRateVolatility)
      if (!document.hidden) schedulingEmaRef.current.push(statsSchedulingTime)

      if (rrvEmaRef.current.filled()) setRenderRateVolatilityEmaAtom(rrvEmaRef.current.avg())
      if (schedulingEmaRef.current.filled()) setStatsSchedulingTimeEmaAtom(schedulingEmaRef.current.avg())

      setCallStats(rtcStats)
      setPerformanceStats({
        renderRateVolatility,
        statsSchedulingTime,
        statsDurationTime,
      })
      setLocalTracksStats({
        videoStats: localVideoStats,
        audioStats: localAudioStats,
      })
      setRemoteTracksStats({
        remoteAudioStats,
        remoteVideoStats,
      })

      lastScheduleTime = performance.now()
      statsTimeout = setTimeout(fetchStats, REFRESH_RATE_MS)
    }
    fetchStats()

    return () => {
      if (statsTimeout !== undefined) {
        clearTimeout(statsTimeout)
      }
    }
  }, [
    client,
    setCallStats,
    setPerformanceStats,
    setLocalTracksStats,
    setRemoteTracksStats,
    setRenderRateVolatilityEmaAtom,
    setStatsSchedulingTimeEmaAtom,
  ])

  return null
}
