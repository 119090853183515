import { useAtomValue } from 'jotai'
import { reactDebugShortcutKeyAtom } from 'sierra-client/features/react-debug-mode'
import { useDeveloperToolsEnabled } from 'sierra-client/hooks/use-developer-tools-enabled'
import { accessibilityTestingAtom } from 'sierra-client/lib/accessibility-testing/atoms'
import { dynamic } from 'sierra-client/utils/dynamic'

const LazyAccessibilityTesting = dynamic(
  () => import('sierra-client/lib/accessibility-testing/implementation')
)

export const AccessibilityTesting: React.FC = () => {
  const isOn = useAtomValue(accessibilityTestingAtom)
  const developerToolsEnabled = useDeveloperToolsEnabled()
  const isReactDebugMode = useAtomValue(reactDebugShortcutKeyAtom)

  return !isReactDebugMode && isOn && developerToolsEnabled ? <LazyAccessibilityTesting /> : null
}
