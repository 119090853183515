import React, { useEffect } from 'react'
import { useTranslation } from 'sierra-client/hooks/use-translation'
import { labelToString } from 'sierra-client/lib/filter/components/common'
import { valueId } from 'sierra-client/lib/filter/components/predicate-utils'
import { ScrollGradientTop } from 'sierra-client/views/manage/components/user-attributes/flows/components/layout'
import { useComposeUserInvitationConfig } from 'sierra-client/views/manage/components/user-attributes/flows/invite-users/hooks/use-compose-user-invitation-config'
import { UserInvitationPills } from 'sierra-client/views/manage/components/user-attributes/flows/invite-users/tabs/invite-via-email/user-invitation-pills'
import {
  createValueRep,
  getValueFromConfig,
  getValueRepFromDomainChoices,
  userShapeFromEmail,
} from 'sierra-client/views/manage/components/user-attributes/flows/invite-users/utils'
import { useInvitationDomains } from 'sierra-client/views/manage/components/user-attributes/hooks/use-invitation-domains'
import { UserInvitationAttribute } from 'sierra-domain/user-attributes/user-invitation-attribute'
import { Icon, UserDisplay } from 'sierra-ui/components'
import { Button, Spacer, Text, View } from 'sierra-ui/primitives'
import { token } from 'sierra-ui/theming'
import styled from 'styled-components'

const List = styled(View).attrs({ direction: 'column', gap: 'small' })`
  flex: 1 0 0;
  min-height: 0;
  overflow: auto;
  justify-content: flex-start;
  padding-bottom: 16px;
`

const OnHover = styled.div`
  display: none;
  width: fit-content;
`

const OnDefault = styled.div`
  display: flex;
  width: fit-content;
  gap: 8px;
`

const Row = styled(View).attrs({ grow: true, justifyContent: 'space-between', gap: 'none' })`
  position: relative; /* For overflow of long attributes */
  flex: 0 0 min-content;
  cursor: pointer;
  flex-grow: 0;

  &:hover ${OnHover} {
    display: flex;
  }

  &:hover ${OnDefault} {
    display: none;
  }
`

const RemoveButton = styled(Button).attrs({ variant: 'transparent' })`
  color: ${token('foreground/muted')};

  &:hover {
    background: none;
    color: ${token('foreground/primary')};
  }
`

const UserToInvite: React.FC<{
  email: string
  roleText: string
  onAddDetails: () => void
  onRemove: () => void
  index: number
}> = ({ email, roleText, onAddDetails, onRemove, index }) => {
  const { t } = useTranslation()
  const [attributesInPills, setAttributesInPills] = React.useState<UserInvitationAttribute[]>([])

  const { avatarColor, ...userShape } = userShapeFromEmail(email, index)
  const { hasAttributeErrors, userAttributesConfig } = useComposeUserInvitationConfig()
  const { customAttributeDomains, managerDomain } = useInvitationDomains()

  const showWarning = hasAttributeErrors(email)

  // Get list of attributes to show in pills (custom + manager)
  useEffect(() => {
    const attributesList: UserInvitationAttribute[] = []
    const userAttributes = userAttributesConfig[email]?.data

    if (userAttributes === undefined) throw new Error('User not found in config.')

    const attributesAsArray: UserInvitationAttribute[] = Array.from(userAttributes)

    const customAttributes = attributesAsArray.filter(attribute => {
      return customAttributeDomains.some(domain => {
        return domain.ref.key === attribute.ref.key
      })
    })

    attributesList.push(...customAttributes)

    /* See useInvitationDomains for explication */
    if (managerDomain !== undefined) {
      const managerAttribute = attributesAsArray.find(attribute => managerDomain.ref === attribute.ref)

      if (managerAttribute !== undefined && managerAttribute.values.length !== 0) {
        attributesList.push(managerAttribute)
      }

      setAttributesInPills(attributesList)
    }
  }, [customAttributeDomains, email, managerDomain, userAttributesConfig])

  const handleRemove = (e: React.MouseEvent): void => {
    e.stopPropagation()
    onRemove()
  }

  return (
    <Row onClick={onAddDetails}>
      <UserDisplay
        primaryText={email}
        secondaryText={roleText}
        avatar={{ ...userShape, color: avatarColor }}
      />
      <View>
        <OnDefault>
          {attributesInPills.length > 0 && (
            <View gap='4'>
              <UserInvitationPills attributes={attributesInPills} email={email} />
            </View>
          )}
          {showWarning && <Icon iconId='warning--filled' color='yellowBright' />}
          {!showWarning && attributesInPills.length === 0 && (
            <Icon iconId='chevron--right--small' color='foreground/muted' />
          )}
        </OnDefault>
        <OnHover>
          <RemoveButton onClick={handleRemove}>{t('dictionary.remove')}</RemoveButton>
          <Button variant='secondary' onClick={onAddDetails}>
            {t('manage.users.invite.add-details')}
          </Button>
        </OnHover>
      </View>
    </Row>
  )
}

const EmptyUsers: React.FC = () => {
  const { t } = useTranslation()

  return (
    <View
      grow
      direction='column'
      padding='large'
      paddingTop='none'
      alignItems='center'
      justifyContent='center'
      gap='2'
    >
      <Icon iconId='user--add' color='foreground/muted' />
      <Spacer size='8' />
      <Text bold color='foreground/muted'>
        {t('admin.organization.users.invite-users')}
      </Text>
      <Text color='foreground/muted'>{t('manage.users.invite.empty-enter-emails')}</Text>
    </View>
  )
}

export const UserList: React.FC = () => {
  const { dynamicT, t } = useTranslation()

  const { accessLevelDomain } = useInvitationDomains()
  const { userAttributesConfig, openAttributeEdit, removeUser } = useComposeUserInvitationConfig()

  const emails = Object.keys(userAttributesConfig)

  return (
    <>
      {emails.length > 0 && (
        <>
          <Spacer size='none' />
          <ScrollGradientTop top='18' height='15'>
            <Text bold color='foreground/muted'>
              {t('dictionary.user-plural')}
            </Text>
          </ScrollGradientTop>
          <List>
            <Spacer size='none' />
            {emails.map((email, index) => {
              const user = userAttributesConfig[email]
              const accessLevelRep = (() => {
                const currentValue = getValueFromConfig(accessLevelDomain, userAttributesConfig, email)

                if (currentValue !== undefined) {
                  return createValueRep(
                    currentValue,
                    getValueRepFromDomainChoices(accessLevelDomain.domain, valueId(currentValue)).label
                  )
                }

                return undefined
              })()

              if (user === undefined || accessLevelRep?.label === undefined) {
                throw new Error('Could not find user when looping through userAttributesConfig.')
              }

              return (
                <UserToInvite
                  email={email}
                  roleText={labelToString(accessLevelRep.label, dynamicT)}
                  key={email}
                  onAddDetails={() => openAttributeEdit([email])}
                  onRemove={() => removeUser(email)}
                  index={index}
                />
              )
            })}
          </List>
        </>
      )}
      {emails.length === 0 && <EmptyUsers />}
    </>
  )
}
