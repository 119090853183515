import _ from 'lodash'
import React, { useCallback, useMemo } from 'react'
import { useTranslation } from 'sierra-client/hooks/use-translation'
import { labelToString } from 'sierra-client/lib/filter/components/common'
import { valueId } from 'sierra-client/lib/filter/components/predicate-utils'
import { useComposeUserInvitationConfig } from 'sierra-client/views/manage/components/user-attributes/flows/invite-users/hooks/use-compose-user-invitation-config'
import {
  getCommonValueRepForChoices,
  getValueRepFromDomainChoices,
  valueRepToMenuLabelItem,
} from 'sierra-client/views/manage/components/user-attributes/flows/invite-users/utils'
import { createInvitationAttribute } from 'sierra-client/views/manage/components/user-attributes/utils'
import { UserCustomAttributeDomainRepWithParentChoices } from 'sierra-domain/user-attributes/user-invitation-domain-rep'
import { isNonEmptyArray } from 'sierra-domain/utils'
import { LabelMenuItem, MenuItem } from 'sierra-ui/components'
import { SingleSelectDropdown } from 'sierra-ui/primitives/menu-dropdown'
import { useOnChanged } from 'sierra-ui/utils'

export const InvitationDomainWithParentChoicesInput: React.FC<{
  domainRep: UserCustomAttributeDomainRepWithParentChoices
}> = ({ domainRep }) => {
  const { dynamicT, t } = useTranslation()
  const { addOrReplaceAttributes, resetAttribute, getAttribute, userAttributesConfig, editingEmails } =
    useComposeUserInvitationConfig()

  const parentRef = domainRep.domain.parentRef

  const parentValues = useMemo(() => {
    return editingEmails
      .flatMap(email => getAttribute(parentRef, email)?.values ?? [])
      .map(({ value }) => value)
  }, [editingEmails, getAttribute, parentRef])
  const hasParentValue = isNonEmptyArray(parentValues)
  const isMixedMode = _.uniq(parentValues).length === parentValues.length && editingEmails.length > 1

  const currentAttributeItem: LabelMenuItem | undefined = useMemo(
    () =>
      valueRepToMenuLabelItem(
        getCommonValueRepForChoices(domainRep, userAttributesConfig, editingEmails),
        dynamicT
      ),
    [domainRep, dynamicT, editingEmails, userAttributesConfig]
  )

  // If parent value changes, make sure we reset the current attribute
  useOnChanged(prev => {
    if (isNonEmptyArray(parentValues) && isNonEmptyArray(prev) && !_.isEqual(parentValues, prev)) {
      resetAttribute(editingEmails, domainRep.ref)
    }
  }, parentValues)

  const setAttribute = useCallback(
    (item: MenuItem): void => {
      const attributeValue = getValueRepFromDomainChoices(domainRep.domain, item.id)
      const attribute = createInvitationAttribute(domainRep.ref, [attributeValue.value])

      addOrReplaceAttributes([attribute])
    },
    [domainRep.domain, domainRep.ref, addOrReplaceAttributes]
  )

  const menuItems: LabelMenuItem[] = useMemo(
    () =>
      domainRep.domain.choices
        .filter(choice => parentValues.includes(choice.parentValue.value))
        .map(choice => ({
          type: 'label',
          id: valueId(choice.value),
          value: choice.value,
          label: labelToString(
            getValueRepFromDomainChoices(domainRep.domain, valueId(choice.value)).label,
            dynamicT
          ),
        })),
    [domainRep.domain, dynamicT, parentValues]
  )

  return (
    <SingleSelectDropdown
      withSearch={menuItems.length > 8}
      disabled={domainRep.editableInternally === false || !hasParentValue || isMixedMode}
      onSelect={setAttribute}
      selectedItem={currentAttributeItem}
      menuItems={menuItems}
      placeholder={t('dictionary.select')}
    />
  )
}
