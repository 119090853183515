import { forwardRef } from 'react'
import ReactSimpleOauth2Login from 'react-simple-oauth2-login'
import {
  OauthComponentProps,
  ReactSimpleOauth2LoginRef,
  getHost,
} from 'sierra-client/components/sana-now-integration-oauth/shared'
import { useOrgId } from 'sierra-client/hooks/use-org-tenant-id'
import { useTranslation } from 'sierra-client/hooks/use-translation'
import { logger } from 'sierra-client/logger/logger'

export const ZoomOauth = forwardRef<ReactSimpleOauth2LoginRef, OauthComponentProps>(
  ({ clientId, onSuccess }, ref): JSX.Element => {
    const { t } = useTranslation()
    const redirectUrl = `https://${getHost()}/x-realtime/auth/user-zoom-oauth`
    const orgId = useOrgId()

    return (
      <ReactSimpleOauth2Login
        ref={ref}
        css={'display: none'}
        authorizationUrl='https://zoom.us/oauth/authorize'
        responseType='code'
        clientId={clientId}
        redirectUri={redirectUrl}
        state={orgId}
        onFailure={(error: unknown) => {
          logger.warn('Zoom oauth error', { error })
        }}
        onSuccess={onSuccess}
        scope='meeting:write:meeting'
      >
        {t('dictionary.zoom')}
      </ReactSimpleOauth2Login>
    )
  }
)
