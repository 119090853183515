import { useCallback, useEffect, useState } from 'react'
import { usePost } from 'sierra-client/hooks/use-post'
import { TagDetail } from 'sierra-domain/api/admin'
import { XRealtimeAdminTagsListTags } from 'sierra-domain/routes'

type UseTagsData = {
  isLoading: boolean
  tags: TagDetail[]
  libraryTags: TagDetail[]
  fetchTags: () => Promise<void>
  fetchCsvData: () => Record<string, unknown>[]
}

export const mapTagToCsv = (tag: TagDetail): Record<string, unknown> => {
  return {
    name: tag.tag.data.name,
    description: tag.tag.data.description,
    coursesCount: tag.courses.length,
    learnersCount: tag.learnersCount,
  }
}

export const useTags = (): UseTagsData => {
  const { postWithUserErrorException } = usePost()

  const [isLoading, setIsLoading] = useState(false)
  const [tags, setTags] = useState<TagDetail[]>([])
  const [libraryTags, setLibraryTags] = useState<TagDetail[]>([])

  const fetchTags = useCallback(async () => {
    setIsLoading(true)
    const response = await postWithUserErrorException(XRealtimeAdminTagsListTags, {
      includeDisabled: true,
    })
    setTags(response.data.filter(s => s.tag.enabled))
    setLibraryTags(response.data.filter(s => !s.tag.enabled))
    setIsLoading(false)
  }, [postWithUserErrorException])

  const fetchCsvData = useCallback(() => {
    return tags.map(mapTagToCsv)
  }, [tags])

  useEffect(() => {
    void fetchTags()
  }, [fetchTags])

  return { isLoading, tags, libraryTags, fetchTags, fetchCsvData }
}
