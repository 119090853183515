import { forwardRef, useMemo, useState } from 'react'
import { graphql } from 'sierra-client/api/graphql/gql'
import { useGraphQuery } from 'sierra-client/api/hooks/use-graphql-query'
import { Thumbnail } from 'sierra-client/components/common/thumbnail'
import { useTranslation } from 'sierra-client/hooks/use-translation'
import { Autocomplete, DefaultStyledAutocompleteOption } from 'sierra-ui/components'
import { Text } from 'sierra-ui/primitives'
import { token } from 'sierra-ui/theming'
import styled from 'styled-components'

const graphQLQuery = graphql(`
  query getCertificatesPreview {
    certificates {
      title
      id
      previewImageUrl
    }
  }
`)

const ListContainer = styled.div`
  padding: 1rem;
  background: ${token('surface/default')};
  max-height: 400px;
  overflow: auto;
`

type SelectCertificateProps = {
  onSelect: (certificateId: string) => Promise<void>
  selectedCertificates: string[]
  onClose: () => void
}

export const SelectCertificate = forwardRef<HTMLInputElement, SelectCertificateProps>(
  ({ onSelect, selectedCertificates }, ref) => {
    const { t } = useTranslation()
    const [query, setQuery] = useState('')
    const certificatesResponse = useGraphQuery({ document: graphQLQuery }, {})
    const matchingCertificates = useMemo(() => {
      const certficates = certificatesResponse.data?.certificates
      if (certficates === undefined) return []
      return certficates
        .filter(c => !selectedCertificates.includes(c.id))
        .filter(c => c.title.toLowerCase().includes(query.toLowerCase()))
        .map(c => ({ ...c, value: c.id }))
    }, [certificatesResponse, selectedCertificates, query])

    return (
      <Autocomplete
        ref={ref}
        query={query}
        onQueryChange={setQuery}
        matchingItems={matchingCertificates}
        placeholder={t('manage.certificates.select-certificate.placeholder')}
        renderMatchingItemList={({ getItemProps }) => (
          <ListContainer>
            <Text bold>{t('manage.certificates.select-certificate.matching-certificates')}</Text>
            {matchingCertificates.map((item, index) => (
              <DefaultStyledAutocompleteOption {...item} key={item.id} {...getItemProps(item, index)}>
                <Thumbnail width={2.5} height={2} radius={0.375} image={item.previewImageUrl} />
                <Text bold>{item.title}</Text>
              </DefaultStyledAutocompleteOption>
            ))}
          </ListContainer>
        )}
        onSelect={item => onSelect(item.id)}
      />
    )
  }
)
