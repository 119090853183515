import { AnimatePresence, motion } from 'framer-motion'
import { useState } from 'react'
import { useTranslation } from 'sierra-client/hooks/use-translation'
import { Button, Text, View } from 'sierra-ui/primitives'
import { FCC } from 'sierra-ui/types'
import styled from 'styled-components'

const Box = styled(View).attrs({
  direction: 'column',
  justifyContent: 'space-between',
  borderColor: 'form/border/1',
  background: 'surface/soft',
  radius: 'size-12',
  padding: '16',
  gap: '16',
})``

const DeleteInfo = styled(View).attrs({ direction: 'column', gap: '2' })``

const FadeInOut: FCC = ({ children }) => {
  return (
    <motion.div
      initial={{ opacity: 0, translateX: 8 }}
      animate={{ opacity: 1, translateX: 0 }}
      exit={{ opacity: 0, translateX: -8 }}
      transition={{
        duration: 0.1,
      }}
    >
      {children}
    </motion.div>
  )
}

export const DeleteBox: React.FC<{
  title: string
  description: string
  deleteLabel: string
  onDelete: () => void
  confirm?: boolean
}> = ({ title, description, deleteLabel, onDelete, confirm = false }) => {
  const [isConfirming, setIsConfirming] = useState(false)
  const { t } = useTranslation()

  function onClick(): void {
    if (!confirm) {
      onDelete()
    } else {
      setIsConfirming(true)
    }
  }

  return (
    <>
      <Box>
        <DeleteInfo>
          <Text size='small' bold>
            {title}
          </Text>
          <Text size='small' color='foreground/muted'>
            {description}
          </Text>
        </DeleteInfo>

        <AnimatePresence initial={false} mode='wait'>
          {isConfirming ? (
            <FadeInOut key='confirm'>
              <View justifyContent='space-between'>
                <Text bold>{t('components.delete-box.message')}</Text>

                <View>
                  <Button variant='secondary' onClick={() => setIsConfirming(false)}>
                    {t('components.delete-box.cancel')}
                  </Button>
                  <Button
                    variant='destructive'
                    onClick={() => {
                      onDelete()
                      setIsConfirming(false)
                    }}
                  >
                    {t('components.delete-box.yes')}
                  </Button>
                </View>
              </View>
            </FadeInOut>
          ) : (
            <FadeInOut key='act'>
              <Button variant='destructive' onClick={onClick}>
                {deleteLabel}
              </Button>
            </FadeInOut>
          )}
        </AnimatePresence>
      </Box>
    </>
  )
}
