import { createAction } from '@reduxjs/toolkit'
import { postWithUserErrorException } from 'sierra-client/state/api'
import { createRootStateAsyncThunk } from 'sierra-client/state/create-root-state-async-thunk'
import {
  QueryCourseLearnerLinkResponse,
  QueryCourseLearnerLinkedInResponse,
  QueryCourseLearnerScormResponse,
  QueryCourseLearnerUnionResponse,
  QueryCourseLiveResponse,
  QueryCourseNativeCourseGroupResponse,
  QueryCourseScormCourseGroupResponse,
  QueryCourseSelfPacedResponse,
} from 'sierra-domain/api/content-v2'
import { StrategyCourseStatus } from 'sierra-domain/api/course-with-status'
import { CourseId } from 'sierra-domain/api/nano-id'
import { TimeLeft } from 'sierra-domain/api/time-left'
import { Entity } from 'sierra-domain/entity'
import { XRealtimeContentQueryCourse, XRealtimeStrategyCourseStatus } from 'sierra-domain/routes'

type WithCourseId<T> = T & { id: CourseId }

export type ContentStatus = {
  courseId: CourseId
  passedTimestamp: string | undefined
  progressTimestamp: string | undefined
  timeLeft: TimeLeft
}

export const fetchCourseStatusById = createRootStateAsyncThunk(
  'content/fetch-course-results-by-id',
  async ({ courseId }: { courseId: CourseId }, { dispatch }): Promise<StrategyCourseStatus> => {
    const courseStatus = await postWithUserErrorException(
      XRealtimeStrategyCourseStatus,
      { courseId },
      dispatch
    )

    return courseStatus
  }
)

const updateNativeLiveCourse = createAction<WithCourseId<QueryCourseLiveResponse>>(
  'content/update-native-live-course'
)

const updateNativeCourseGroupCourse = createAction<WithCourseId<QueryCourseNativeCourseGroupResponse>>(
  'content/update-native-course-group-course'
)

const updateScormCourseGroupCourse = createAction<WithCourseId<QueryCourseScormCourseGroupResponse>>(
  'content/update-scorm-course-group-course'
)

const updateNativeSelfPacedCourse = createAction<WithCourseId<QueryCourseSelfPacedResponse>>(
  'content/update-native-self-paced-course'
)
export const updateLinkedInCourse = createAction<WithCourseId<QueryCourseLearnerLinkedInResponse>>(
  'content/update-linkedin-course'
)
export const updateScormCourse = createAction<WithCourseId<QueryCourseLearnerScormResponse>>(
  'content/update-scorm-course'
)

export const updateLinkCourse = createAction<WithCourseId<QueryCourseLearnerLinkResponse>>(
  'content/update-link-course'
)

export const fetchCourseDataById = createRootStateAsyncThunk(
  'content/fetch-course-data-by-id',
  async (
    { courseId }: { courseId: CourseId },
    { dispatch }
  ): Promise<Entity<QueryCourseLearnerUnionResponse>> => {
    const value = await postWithUserErrorException(XRealtimeContentQueryCourse, { courseId }, dispatch)

    const entity = { id: courseId, ...value }

    if (entity.type === 'linkedin') void dispatch(updateLinkedInCourse(entity))
    else if (entity.type === 'link') void dispatch(updateLinkCourse(entity))
    else if (entity.type === 'scorm') void dispatch(updateScormCourse(entity))
    else if (entity.type === 'native:live') void dispatch(updateNativeLiveCourse(entity))
    else if (entity.type === 'native:course-group') void dispatch(updateNativeCourseGroupCourse(entity))
    else if (entity.type === 'scorm:course-group') void dispatch(updateScormCourseGroupCourse(entity))
    else void dispatch(updateNativeSelfPacedCourse(entity))

    return entity
  }
)
