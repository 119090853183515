import { ThunkDispatch, UnknownAction, createAction, createAsyncThunk } from '@reduxjs/toolkit'
import { debounce } from 'lodash'
import { getVideoCallService } from 'sierra-client/components/liveV2/services/video-call-service'
import {
  CameraResolution,
  TrackState,
  VideoQuality,
  VideoSubscribeConfig,
} from 'sierra-client/components/liveV2/services/video-call-service/types'
import { logger } from 'sierra-client/logger/logger'
import { selectCallIsActive, selectIssuesWithName } from 'sierra-client/state/live/selectors'
import { LiveIssue } from 'sierra-client/state/live/types'
import { RootState } from 'sierra-client/state/types'

export const updateCallState = createAsyncThunk('live/update-state', async () => {
  const callService = await getVideoCallService()
  const extendedCallState = await callService.getCurrentCallState()
  const { transcription, ...callState } = extendedCallState

  return { callState, transcription }
})

// Defer the dispatch of the updateCallState action to the next tick
// this will ensure we only update the state once per tick
// during nosiy periods of the call, for example when a lot of people leave at the same time
// this helps ensure we don't update the same state multiple times
const deferredUpdateCallState = debounce(
  (dispatch: ThunkDispatch<unknown, unknown, UnknownAction>) => {
    return dispatch(updateCallState())
  },
  0, // 0 wait time and `leading: false` means the call will be executed on the next tick
  {
    leading: false,
    maxWait: 0,
  }
)

export const callStateChanged = createAsyncThunk('live/state-changed', (_props, { dispatch }) => {
  void deferredUpdateCallState(dispatch)
})

export const addIssue = createAction<LiveIssue>('live/add-issue')
export const setKeyboardNavigationEnabled = createAction<boolean>('live/set-keyboard-navigation-enabled')
export const clearIssue = createAction<LiveIssue>('live/clear-issue')
export const clearAllIssues = createAction('live/clear-all-issues')
export const dismissIssue = createAction<LiveIssue>('live/dismiss-issue')
export const setUsersDisplayed = createAction<string[]>('live/set-users-displayed')
export const setPreferredVideoQuality = createAction<VideoQuality>('live/set-preferred-video-quality')

export const updateAvailableCameras = createAsyncThunk('live/update-available-cameras', async () => {
  const callService = await getVideoCallService()
  const permission = callService.getDevicePermissions()

  const cameras = permission.hasCameraPermission === 'allowed' ? await callService.getCameras(true) : []

  return { cameras }
})

export const updateAvailableMicrophones = createAsyncThunk('live/update-available-microphones', async () => {
  const callService = await getVideoCallService()
  const permission = callService.getDevicePermissions()

  const microphones =
    permission.hasMicrophonePermission === 'allowed' ? await callService.getMicrophones(true) : []

  return { microphones }
})

export const useCamera = createAsyncThunk(
  'live/use-camera',
  async ({ deviceId }: { deviceId: string }, { dispatch }) => {
    const callService = await getVideoCallService()
    await callService.useCamera(deviceId)

    // When a user has specified a device to use we disable auto switching
    // since they've shown intent to use a specific device, and we don't want
    // to move away from that device
    callService.setAutoSwitchDevices(false)
    void deferredUpdateCallState(dispatch)
  }
)

export const useMicrophone = createAsyncThunk(
  'live/use-microphone',
  async ({ deviceId }: { deviceId: string }, { dispatch }) => {
    const callService = await getVideoCallService()
    await callService.useMicrophone(deviceId)

    // When a user has specified a device to use we disable auto switching
    // since they've shown intent to use a specific device, and we don't want
    // to move away from that device
    callService.setAutoSwitchDevices(false)

    void deferredUpdateCallState(dispatch)
  }
)

export const goOnStage = createAsyncThunk('live/go-on-stage', async (arg, { dispatch }) => {
  const callService = await getVideoCallService()

  try {
    await callService.goOnStage()
  } finally {
    void deferredUpdateCallState(dispatch)
  }
})

export const goOffStage = createAsyncThunk('live/go-off-stage', async (arg, { dispatch }) => {
  const callService = await getVideoCallService()

  try {
    await callService.goOffStage()
  } finally {
    void deferredUpdateCallState(dispatch)
  }
})

export const muteAudio = createAsyncThunk('live/mute-audio', async () => {
  const callService = await getVideoCallService()
  await callService.muteAudio()

  return { audioState: callService.audioState }
})

export const unmuteAudio = createAsyncThunk<{ audioState: TrackState }, void, { state: RootState }>(
  'live/unmute-audio',
  async (_, { dispatch, getState }) => {
    const now = Date.now()
    const callService = await getVideoCallService()
    await callService.unmuteAudio()

    const { audioState } = callService
    const callIsActive = selectCallIsActive(getState())
    if (audioState === 'on' && callIsActive) {
      await dispatch(goOnStage())
    }

    const elapsed = Date.now() - now
    logger.info(`Async action unmuteAudio took ${elapsed}ms`, { elapsed })

    return { audioState }
  }
)

export const enableVideo = createAsyncThunk('live/enable-video', async () => {
  const callService = await getVideoCallService()
  await callService.enableVideo()
  return { videoState: callService.videoState }
})

export const disableVideo = createAsyncThunk('live/disable-video', async () => {
  const callService = await getVideoCallService()
  await callService.disableVideo()
  return { videoState: callService.videoState }
})

export const startScreenSharing = createAsyncThunk('live/start-screen-sharing', async (arg, { dispatch }) => {
  const callService = await getVideoCallService()

  try {
    await callService.startScreenSharing()
  } finally {
    void deferredUpdateCallState(dispatch)
  }
})

export const stopScreenSharing = createAsyncThunk('live/stop-screen-sharing', async (arg, { dispatch }) => {
  const callService = await getVideoCallService()

  try {
    await callService.stopScreenSharing()
  } finally {
    void deferredUpdateCallState(dispatch)
  }
})

export const startScreenRecording = createAsyncThunk(
  'live/start-screen-recording',
  async (
    {
      cropTarget,
      recordingId,
      recordingChannel,
      useUserId,
    }: {
      cropTarget?: object
      recordingId: string
      recordingChannel: string
      useUserId: number
    },
    { dispatch }
  ) => {
    const callService = await getVideoCallService()
    try {
      await callService.startScreenRecording({ cropTarget, recordingId, recordingChannel, useUserId })
    } finally {
      void deferredUpdateCallState(dispatch)
    }
  }
)

export const stopScreenRecording = createAsyncThunk(
  'live/stop-screen-recording',
  async (_props, { dispatch }) => {
    const callService = await getVideoCallService()
    try {
      callService.stopScreenRecording()
    } finally {
      void deferredUpdateCallState(dispatch)
    }
  }
)

export const joinCall = createAsyncThunk(
  'live/join-call',
  async ({ channelId }: { channelId: string }, { dispatch }) => {
    const callService = await getVideoCallService()

    try {
      await callService.joinCall(channelId)
    } finally {
      void deferredUpdateCallState(dispatch)
    }
  }
)

export const leaveCall = createAsyncThunk('live/leave-call', async (arg, { dispatch }) => {
  const callService = await getVideoCallService()

  try {
    await callService.leaveCall()
  } finally {
    void deferredUpdateCallState(dispatch)
  }
})

export const joinBreakoutRoom = createAsyncThunk<void, { breakoutRoom: string }, { state: RootState }>(
  'live/join-breakout-room',
  async ({ breakoutRoom }: { breakoutRoom: string }, { dispatch, getState }) => {
    const callService = await getVideoCallService()

    try {
      await callService.joinBreakoutRoom(breakoutRoom)

      const hasCallSwitchError = selectIssuesWithName(getState())('call-switch-room-error').length > 0
      if (hasCallSwitchError) void dispatch(clearIssue('call-switch-room-error'))
    } catch (error) {
      void dispatch(addIssue('call-switch-room-error'))
      throw error
    } finally {
      void deferredUpdateCallState(dispatch)
    }
  }
)

export const setCameraResolution = createAsyncThunk<
  { resolution: CameraResolution },
  { resolution: CameraResolution }
>('live/set-camera-resolution', async ({ resolution }, { dispatch }) => {
  const callService = await getVideoCallService()

  try {
    await callService.setCameraResolution(resolution)
  } finally {
    void deferredUpdateCallState(dispatch)
  }

  return { resolution }
})

export const setVideoSubscribeConfig = createAsyncThunk<
  void,
  {
    config: VideoSubscribeConfig
  }
>('live/set-video-subscribe-config', async ({ config }) => {
  const callService = await getVideoCallService()
  await callService.setVideoSubscribeConfig(config)
})

export const leaveBreakoutRoom = createAsyncThunk<void, void, { state: RootState }>(
  'live/leave-breakout-room',
  async (arg, { dispatch }) => {
    const callService = await getVideoCallService()

    try {
      await callService.leaveBreakoutRoom()
    } finally {
      void deferredUpdateCallState(dispatch)
    }
  }
)

export const setNoiseCancellation = createAsyncThunk<
  { enabled: boolean },
  { enabled: boolean },
  { state: RootState }
>('live/set-noise-cancellation', async ({ enabled }) => {
  const callService = await getVideoCallService()
  await callService.setNoiseCancellation(enabled)

  return { enabled: callService.noiseCancellationEnabled }
})

export const setBackgroundBlur = createAsyncThunk(
  'live/set-background-blur',
  async ({ enabled }: { enabled: boolean }) => {
    const callService = await getVideoCallService()

    await callService.setBackgroundBlur(enabled)

    return { enabled: callService.backgroundBlurEnabled }
  }
)

export const restoreInitialUserSettings = createAsyncThunk<
  void,
  { noiseCancellationEnabled: boolean; backgroundBlurEnabled: boolean },
  { state: RootState }
>('live/restore-user-settings', async ({ noiseCancellationEnabled, backgroundBlurEnabled }, { dispatch }) => {
  const callService = await getVideoCallService()
  const currentCallState = await callService.getCurrentCallState()

  try {
    if (noiseCancellationEnabled !== currentCallState.noiseCancellationEnabled) {
      await callService.setNoiseCancellation(noiseCancellationEnabled)
    }

    if (backgroundBlurEnabled !== currentCallState.backgroundBlurEnabled) {
      await callService.setBackgroundBlur(backgroundBlurEnabled)
    }
  } finally {
    void deferredUpdateCallState(dispatch)
  }
})
