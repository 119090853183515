import _ from 'lodash'
import React, { useCallback, useEffect, useMemo, useRef, useState } from 'react'
import { clickedQuestionQACard, wroteQuestionQACard } from 'sierra-client/core/logging/authoring/logger'
import { useSseWithErrorNotification } from 'sierra-client/hooks/use-sse'
import { useTranslation } from 'sierra-client/hooks/use-translation'
import { useCachedQuery, useTypedMutation } from 'sierra-client/state/api'
import { useDispatch, useSelector } from 'sierra-client/state/hooks'
import { selectUser } from 'sierra-client/state/user/user-selector'
import { useUserLegacy } from 'sierra-client/state/users/hooks'
import { getAvatarImage } from 'sierra-client/utils/avatar-img'
import { useSelfPacedBigContext } from 'sierra-client/views/flexible-content/self-paced-big-context'
import { useNavigateToFile } from 'sierra-client/views/v3-author/tags/tags'
import { CreateContentId } from 'sierra-domain/api/nano-id'
import { UserId } from 'sierra-domain/api/uuid'
import { FileId } from 'sierra-domain/flexible-content/identifiers'
import { File } from 'sierra-domain/flexible-content/types'
import { createNanoId12FromString } from 'sierra-domain/nanoid-extensions'
import {
  XRealtimeStrategyAuthorFilesMatchingQuestion,
  XRealtimeStrategyAuthordGenerateQuestions,
  XRealtimeStrategySelfPacedFilesMatchingQuestion,
  XRealtimeStrategySelfPacedGenerateQuestions,
} from 'sierra-domain/routes'
import {
  SSEXRealtimeAuthorAnswerQuestion,
  SSEXRealtimeSelfPacedAnswerQuestion,
} from 'sierra-domain/routes-sse'
import { asNonNullable, assertWith } from 'sierra-domain/utils'
import { Icon, RoundAvatar, TruncatedText } from 'sierra-ui/components'
import { Heading, Spacer, Text, View } from 'sierra-ui/primitives'
import { v2_breakpoint } from 'sierra-ui/theming/breakpoints'
import { fadeIn } from 'sierra-ui/theming/keyframes'
import smoothscroll from 'smoothscroll-polyfill'
import styled, { css } from 'styled-components'

import { UseQueryResult } from '@tanstack/react-query'
import { AnimatePresence, motion } from 'framer-motion'
import { useIsMobile } from 'sierra-client/state/browser/selectors'
import { useFileTitle, useGetFileTitle } from 'sierra-client/state/flexible-content/file-title'
import { selectFlexibleContentNodes } from 'sierra-client/state/flexible-content/selectors'
import { useCreatePageYDocContext } from 'sierra-client/views/flexible-content/create-page-context'
import { getFileIcon } from 'sierra-client/views/flexible-content/editor/content-sidebar/icons'
import { color } from 'sierra-ui/color'
import { token } from 'sierra-ui/theming'

const Paddings = css`
  padding-inline: 172px;
  padding-bottom: 8px;
  padding-top: 8px;
  @media (max-width: ${v2_breakpoint.desktop_small}) {
    padding-inline: 48px;
  }

  @media (max-width: ${v2_breakpoint.phone}) {
    padding-inline: 24px;
  }
`

export const SearchBarContainer = styled(View).attrs({
  direction: 'column',
  alignItems: 'center',
  justifyContent: 'center',
  marginTop: '32',
  gap: 'none',
})`
  position: absolute;
  padding-bottom: 68px;
  bottom: 0;
  width: 100%;
  overflow: hidden;

  & > * {
    opacity: 0;
    animation: 1s cubic-bezier(0.25, 0.1, 0.25, 1) ${fadeIn} forwards;
  }
`
export const FadeInView = styled(View)<{ fadeIn: boolean }>`
  ${p =>
    p.fadeIn &&
    css`
      & > * {
        opacity: 0;
        animation: 1s cubic-bezier(0.25, 0.1, 0.25, 1) ${fadeIn} forwards;
      }
    `}
`
export const QuestionsContainer = styled.div<{ wordCloud: boolean }>`
  -ms-overflow-style: none;
  scrollbar-width: none;

  &::-webkit-scrollbar {
    display: none;
  }

  display: flex;
  flex-direction: row;
  ${p => p.wordCloud && 'max-width: 120ch;'}
  margin: auto;
  align-items: center;
  ${p =>
    p.wordCloud
      ? css`
          flex-wrap: wrap;
          justify-content: center;
        `
      : css`
          flex-wrap: nowrap;

          & > *:first-child {
            margin-left: auto;
          }

          & > *:last-child {
            margin-right: auto;
          }
        `};
  overflow-x: auto;
  white-space: nowrap;
  width: 100%;
  gap: 8px;
  scrollbar-gutter: stable;

  /* paddings */
  padding-inline: 128px;
  ${p =>
    p.wordCloud
      ? css`
          padding-bottom: 48px;
          padding-top: 48px;
        `
      : css`
          padding-bottom: 8px;
          padding-top: 8px;
        `} @media (
    max-width: ${v2_breakpoint.desktop_small}) {
    padding-inline: 48px;
  }

  @media (max-width: ${v2_breakpoint.phone}) {
    padding-inline: 24px;
  }
`
export const AnswerContainer = styled(View).attrs({ direction: 'column' })`
  padding-bottom: calc(100vh - 250px);
  padding-inline: 160px;
  width: 100%;
  height: fit-content;

  @media (max-width: ${v2_breakpoint.desktop_small}) {
    padding-inline: 64px;
  }

  @media (max-width: ${v2_breakpoint.phone}) {
    padding-inline: 32px;
  }
`
export const AnswerTextContainer = styled(View)<{ width: string; fadeIn: boolean }>`
  flex-wrap: wrap;
  ${p => `max-width: ${p.width};`}
  ${p =>
    p.fadeIn &&
    css`
      & > * {
        opacity: 0;
        animation: 1s cubic-bezier(0.25, 0.1, 0.25, 1) ${fadeIn} forwards;
      }
    `}
`
export const SpinningIcon = styled(Icon)<{ $isSpinning: boolean }>`
  ${p =>
    p.$isSpinning &&
    css`
      animation: spin 1.5s ease-in-out 150ms;
      animation-iteration-count: infinite;
      @keyframes spin {
        0% {
          transform: rotate(0deg);
        }
        100% {
          transform: rotate(180deg);
        }
      }
    `}
  & svg {
    display: block;
    width: 24px;
    height: 24px;
    color: ${p => p.theme.text.default};
  }
`
export const BackgroundContainer = styled(View)`
  width: 100%;
  height: 100%;
`
export const ClickMe = styled(View).attrs({
  alignItems: 'center',
  justifyContent: 'center',
})`
  border-radius: 100px;
  flex-shrink: 0;
  background-color: white;
  transition-duration: 120ms;
  transition-timing-function: cubic-bezier(0.25, 0.5, 0.25, 1);

  &:hover {
    background-color: ${color('white').darken(0.02)};
  }

  cursor: pointer;
  padding: 14px 24px 14px 24px;
  box-shadow:
    0px 2px 4px 0px #00000014,
    0px 0px 0px 1px #0000000a;
  white-space: nowrap;
  width: fit-content;

  /* opacity: 0;
  animation: 1s cubic-bezier(0.25, 0.1, 0.25, 1) ${fadeIn} forwards; */
`
export const FadeInWord = styled.span<{ fadeIn: boolean }>`
  font-size: 17px;
  line-height: 25.5px;
  height: 26px;
  ${p =>
    p.fadeIn &&
    css`
      opacity: 0;
      animation: 0.5s cubic-bezier(0.25, 0.1, 0.25, 1) ${fadeIn} forwards;
    `}
`
export const Avatar: React.FC<{ userId?: UserId }> = ({ userId }) => {
  const myUser = useSelector(selectUser)
  const otherUser = useUserLegacy(userId)
  const user = userId === undefined ? myUser : otherUser

  return (
    <div style={{ marginTop: '6px' }}>
      <RoundAvatar
        size='small'
        firstName={user?.firstName}
        lastName={user?.lastName}
        src={getAvatarImage(user?.uuid, user?.avatar)}
        color={user?.avatarColor}
      />
    </div>
  )
}
export const SourceContainer = styled(View)`
  white-space: nowrap;
  cursor: pointer;
  padding: 8px;
  padding-right: 12px;
  background-color: ${token('form/background/2')};
  border-radius: 12px;

  transition-duration: 120ms;
  transition-timing-function: cubic-bezier(0.25, 0.5, 0.25, 1);

  &:hover {
    background-color: ${token('form/background/3')};
  }
`

export const Input = styled.input.attrs({ type: 'text' })`
  height: auto;
  border: 0 !important;
  padding: 0;
  border-radius: 0;

  & input {
    font-size: 0.875rem;
    background: white;
    color: black;

    &::placeholder {
      color: ${color('grey35')};
    }
  }

  flex: 1;
`
export const AddWordInputContainer = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: stretch;
  gap: 8px;

  /* width: 348px; */
  width: 100%;
  height: 64px;
  border-radius: 40px;
  padding: 14px;
  padding-left: 18px;

  background: white;
  box-shadow:
    0px 2px 4px 0px #00000014,
    0px 0px 0px 1px #0000000a;
`
export const Root = styled.div`
  position: relative;
  max-height: 100%;
  height: 100%;
`

export const BottomContainer = styled(View)`
  padding-bottom: 8px;
`

export const InputWrapper = styled(View)`
  width: 100%;
  ${Paddings}
`
export const LinebreakingPRE = styled.pre`
  max-width: 80ch;
  white-space: pre-line;
`
const StyledIcon = styled(Icon)`
  cursor: pointer;
  padding-right: 10px;
`

const IconWithPadding = styled(Icon)`
  padding-left: 4px;
`

export type QAState =
  | { type: 'none' }
  | { type: 'finding-matching-files'; query: string }
  | { type: 'answering' | 'answered'; query: string; answer: string; fileIds: FileId[] }

function scrollIntoView(element: HTMLElement, options?: ScrollIntoViewOptions): void {
  smoothscroll.polyfill()
  element.scrollIntoView(options ?? { block: 'start', behavior: 'smooth' })
}

const LearnerSource: React.FC<{ file: File }> = ({ file }) => {
  const { slateDocumentMap } = useSelfPacedBigContext()

  const navigateToFile = useNavigateToFile()
  const getFileTitle = useGetFileTitle(slateDocumentMap)

  return (
    <SourceContainer gap='6' key={file.id} onClick={() => navigateToFile(file.id)}>
      <IconWithPadding size='size-12' iconId={getFileIcon(file.data)} />

      <Text color={'foreground/primary'} bold>
        {getFileTitle(file)}
      </Text>
    </SourceContainer>
  )
}

const EditorSource: React.FC<{ file: File }> = ({ file }) => {
  const { yDoc } = useCreatePageYDocContext()
  const fileTitle = useFileTitle(yDoc, file)

  const navigateToFile = useNavigateToFile()

  return (
    <SourceContainer gap='6' key={file.id} onClick={() => navigateToFile(file.id)}>
      <IconWithPadding size='size-12' iconId={getFileIcon(file.data)} />

      <TruncatedText color={'foreground/primary'} bold>
        {fileTitle}
      </TruncatedText>
    </SourceContainer>
  )
}

const QuestionAnswerBlock: React.FC<{
  isLatest: boolean
  chatState: QAState
  sourceFiles: File[] | undefined
  fadeIn: boolean
  mode: 'learner' | 'editor'
}> = ({ isLatest, chatState, sourceFiles, fadeIn, mode }) => {
  const [scrollRef, setScrollRef] = useState<HTMLDivElement | null>(null)
  const isMobile = useIsMobile()

  const { t } = useTranslation()

  useEffect(() => {
    if (scrollRef !== null && isLatest) {
      scrollIntoView(scrollRef)
    }
  }, [isLatest, scrollRef])

  const spacer = isMobile ? ('8' as const) : ('32' as const)

  if (chatState.type === 'none') return null
  return (
    <React.Fragment key={chatState.query}>
      <View
        key={chatState.query}
        ref={setScrollRef}
        gap={spacer}
        direction='row'
        alignItems='flex-start'
        paddingTop={isMobile ? '40' : '112'}
      >
        {!isMobile && <Avatar />}
        <AnswerTextContainer key={chatState.query} fadeIn={fadeIn} width='60ch'>
          <p style={{ fontSize: '32px', fontWeight: 500, lineHeight: 1.15 }}>{chatState.query}</p>
        </AnswerTextContainer>
      </View>
      <Spacer size={spacer} />
      <BottomContainer gap={spacer} direction='row' alignItems='flex-start'>
        {!isMobile && (
          <SpinningIcon
            $isSpinning={chatState.type === 'finding-matching-files' || chatState.type === 'answering'}
            iconId='sana-symbol'
            size='size-24'
          />
        )}
        <View direction='column'>
          {(chatState.type === 'answering' || chatState.type === 'answered') && (
            <>
              <AnswerTextContainer fadeIn={fadeIn} style={{ gap: 'none' }} width='80ch'>
                <LinebreakingPRE>
                  {chatState.answer.split(' ').map((word, index, array) => (
                    <FadeInWord fadeIn={fadeIn} key={`${word}-${index}`}>
                      {word}
                      {index !== array.length - 1 && ' '}
                    </FadeInWord>
                  ))}
                </LinebreakingPRE>
              </AnswerTextContainer>
              {sourceFiles !== undefined && sourceFiles.length > 0 && (
                <FadeInView fadeIn={fadeIn} direction='column' marginTop={'32'}>
                  <Text color={'foreground/muted'} bold>
                    {t('question-answers.sources-title')}
                  </Text>
                  <View marginTop={'4'} direction='row' wrap='wrap'>
                    {_.take(sourceFiles, 3).map(file => (
                      <React.Fragment key={file.id}>
                        {mode === 'editor' && <EditorSource file={file} />}
                        {mode === 'learner' && <LearnerSource file={file} />}
                      </React.Fragment>
                    ))}
                  </View>
                </FadeInView>
              )}
            </>
          )}
        </View>
      </BottomContainer>
    </React.Fragment>
  )
}

const DisclaimerContainer = styled(View)`
  left: 0;
  right: 0;
  margin-inline: auto;
  position: absolute;
  bottom: 0;
  height: 76px;
`

const RefreshButton = styled(motion.div)`
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
  padding: 14px 16px;
  background-color: ${token('foreground/primary').opacity(0.04)};

  border-radius: 46px;
  transition-duration: 120ms;
  transition-timing-function: cubic-bezier(0.25, 0.5, 0.25, 1);

  &:hover {
    background-color: ${token('foreground/primary').opacity(0.07)};
  }
`
const QuestionCloud: React.FC<{
  generatedQuestions: { fileId: `file:${string}`; question: string }[]
  courseId: CreateContentId
  userId: UserId | undefined
  refetchQuestions: () => void
  startAnswering: (question: string, fileIds: FileId[]) => Promise<void>
}> = ({ generatedQuestions, courseId, userId, refetchQuestions, startAnswering }) => {
  const dispatch = useDispatch()
  return (
    <QuestionsContainer wordCloud>
      {generatedQuestions.map(({ question, fileId }, index) => (
        <div style={{ display: 'flex', gap: '8px' }} key={index}>
          <ClickMe
            key={question + index}
            animated
            initial={{ opacity: 0, y: 20, rotateZ: (Math.random() - 0.5) * (400 / question.length) }}
            animate={{
              opacity: 1,
              y: 0,
              rotateZ: 0,
              transition: {
                duration: 0.3,
                delay: index * 0.2,
                y: { type: 'spring' },
                rotateZ: { type: 'spring' },
              },
            }}
            exit={{ opacity: 0, transition: { duration: 1 } }}
            onClick={() => {
              void dispatch(
                clickedQuestionQACard({
                  contentId: courseId,
                  cardId: fileId,
                  activityId: createNanoId12FromString(courseId + fileId + userId),
                  where: 'word-cloud',
                  in: 'self-paced',
                })
              )
              void refetchQuestions()
              void startAnswering(question, [fileId])
            }}
          >
            <Text bold color={'black'}>
              {question}
            </Text>
          </ClickMe>
          {index === generatedQuestions.length - 1 && (
            <RefreshButton
              initial={{ opacity: 0 }}
              animate={{ opacity: 1, transition: { duration: 0.3, delay: generatedQuestions.length * 0.2 } }}
              exit={{ opacity: 0, transition: { duration: 1 } }}
              onClick={() => {
                void refetchQuestions()
              }}
            >
              <Icon iconId='shuffle'></Icon>
            </RefreshButton>
          )}
        </div>
      ))}
    </QuestionsContainer>
  )
}

const Disclaimer: React.FC = () => {
  const { t } = useTranslation()
  return (
    <>
      <Icon color={'foreground/muted'} iconId='view--off'></Icon>
      <Text color={'foreground/muted'} size='micro'>
        {t('question-answers.private-conversation-disclaimer')}
      </Text>
    </>
  )
}
type QACardProps = {
  state: QAState
  previousChats: { query: string; answer: string; sourceFiles: File[] }[]
  setPreviousChats: React.Dispatch<
    React.SetStateAction<{ query: string; answer: string; sourceFiles: File[] }[]>
  >
  currentQuestionSources: File[] | undefined
  generatedQuestionsQuery: UseQueryResult<
    {
      questions: {
        fileId: `file:${string}`
        question: string
      }[]
    },
    Error
  >
  generatedQuestions: { fileId: `file:${string}`; question: string }[] | undefined
  courseId: CreateContentId
  startAnswering: (question: string, fileIds: FileId[]) => Promise<void>
  findRelevantNodesAndStartAnswering: ({
    courseId,
    fileId,
    question,
  }: {
    courseId: string
    fileId: FileId
    question: string
  }) => void
  fileId: FileId
  mode: 'learner' | 'editor'
}

const QACard: React.FC<QACardProps> = ({
  state,
  previousChats,
  setPreviousChats,
  currentQuestionSources,
  generatedQuestionsQuery,
  generatedQuestions,
  courseId,
  startAnswering,
  findRelevantNodesAndStartAnswering,
  fileId,
  mode,
}) => {
  const dispatch = useDispatch()
  const { t } = useTranslation()
  const [word, setWord] = useState('')
  const inputRef = useRef<HTMLInputElement>(null)

  const me = useSelector(selectUser)
  const userId = me?.uuid

  const handleWrittenQuestion = useCallback(() => {
    if (state.type === 'answered' || state.type === 'answering') {
      setPreviousChats(previous => [...previous, { ...state, sourceFiles: currentQuestionSources ?? [] }])
    }
    setWord('')
    void findRelevantNodesAndStartAnswering({ courseId, fileId, question: word })
    void dispatch(
      wroteQuestionQACard({
        contentId: courseId,
        cardId: fileId,
        activityId: createNanoId12FromString(courseId + fileId + userId),
      })
    )
  }, [
    courseId,
    currentQuestionSources,
    dispatch,
    fileId,
    findRelevantNodesAndStartAnswering,
    setPreviousChats,
    state,
    userId,
    word,
  ])

  return (
    <>
      <Root>
        {state.type !== 'none' ? (
          <AnswerContainer>
            {previousChats.map(chat => (
              <QuestionAnswerBlock
                isLatest={false}
                key={chat.query + chat.answer}
                fadeIn={false}
                chatState={{
                  type: 'answered',
                  answer: chat.answer,
                  query: chat.query,
                  fileIds: chat.sourceFiles.map(it => it.id),
                }}
                sourceFiles={chat.sourceFiles}
                mode={mode}
              />
            ))}
            <QuestionAnswerBlock
              isLatest={true}
              key={state.query + 'latest'}
              fadeIn={true}
              chatState={state}
              sourceFiles={currentQuestionSources}
              mode={mode}
            />
          </AnswerContainer>
        ) : (
          <BackgroundContainer alignItems='center' justifyContent='center' direction='column'>
            <View direction='column' gap='none'>
              <View alignItems='center' justifyContent='center' direction='column'>
                <motion.div
                  key='title-text'
                  layout='position'
                  style={{
                    display: 'flex',
                    flexDirection: 'column',
                    alignItems: 'center',
                    justifyContent: 'center',
                  }}
                >
                  <Heading align='center' bold size='h4'>
                    {t('question-answers.prompt')}
                  </Heading>
                  <Heading bold size='h4'>
                    {t('question-answers.sub-prompt')}
                  </Heading>
                </motion.div>
              </View>

              {!generatedQuestionsQuery.isFetching &&
              generatedQuestions !== undefined &&
              generatedQuestions.length > 0 ? (
                <QuestionCloud
                  generatedQuestions={generatedQuestions}
                  courseId={courseId}
                  userId={userId}
                  refetchQuestions={() => generatedQuestionsQuery.refetch()}
                  startAnswering={startAnswering}
                />
              ) : (
                <Spacer size='40' />
              )}

              <View justifyContent='center'>
                <motion.div key={'disclaimer'} layout='position' style={{ display: 'flex', gap: '8px' }}>
                  <Disclaimer />
                </motion.div>
              </View>
            </View>
          </BackgroundContainer>
        )}
      </Root>

      <SearchBarContainer>
        <AnimatePresence>
          {!generatedQuestionsQuery.isFetching &&
            state.type !== 'none' &&
            generatedQuestions !== undefined &&
            generatedQuestions.length > 0 && (
              <QuestionsContainer wordCloud={false}>
                {generatedQuestions.slice(0, 3).map(({ question, fileId }, index) => (
                  <ClickMe
                    animated
                    initial={{ opacity: 0, x: 20, rotateZ: 0 }}
                    animate={{
                      opacity: 1,
                      x: 0,
                      transition: { duration: 0.3, delay: index * 0.1 },
                    }}
                    exit={{ opacity: 0, transition: { duration: 0.1 } }}
                    onClick={() => {
                      void generatedQuestionsQuery.refetch()
                      void dispatch(
                        clickedQuestionQACard({
                          contentId: courseId,
                          cardId: fileId,
                          activityId: createNanoId12FromString(courseId + fileId + userId),
                          where: 'search-bar',
                          in: 'self-paced',
                        })
                      )
                      void startAnswering(question, [fileId])
                    }}
                    key={index}
                  >
                    <Text bold color={'black'}>
                      {question}
                    </Text>
                  </ClickMe>
                ))}
                <RefreshButton
                  initial={{ opacity: 0 }}
                  animate={{
                    opacity: 1,
                    transition: { duration: 0.3, delay: generatedQuestions.length * 0.2 },
                  }}
                  exit={{ opacity: 0, transition: { duration: 0.1 } }}
                  onClick={() => {
                    void generatedQuestionsQuery.refetch()
                  }}
                >
                  <Icon iconId='shuffle'></Icon>
                </RefreshButton>
              </QuestionsContainer>
            )}
        </AnimatePresence>
        <InputWrapper>
          <AddWordInputContainer>
            <Avatar />
            <Input
              value={word}
              placeholder={t('dictionary.write-something')}
              onKeyDown={e => {
                if (e.key === 'Enter') handleWrittenQuestion()
              }}
              onChange={e => setWord(e.target.value)}
              ref={inputRef}
            />
            <StyledIcon
              size='size-20'
              onClick={handleWrittenQuestion}
              iconId='arrow-up--filled'
              color={word === '' ? 'grey25' : 'black'}
            />
          </AddWordInputContainer>
        </InputWrapper>
      </SearchBarContainer>
      {state.type !== 'none' && (
        <DisclaimerContainer justifyContent='center'>
          <Disclaimer />
        </DisclaimerContainer>
      )}
    </>
  )
}

export const StupidQuestionsLearnerView: React.FC<{ courseId: CreateContentId; fileId: FileId }> = ({
  courseId,
  fileId,
}) => {
  const { subscribeSse } = useSseWithErrorNotification()

  const [state, setState] = useState<QAState>({ type: 'none' })
  const [previousChats, setPreviousChats] = useState<
    { query: string; answer: string; sourceFiles: File[] }[]
  >([])

  const abortAnswerRef = useRef<AbortController>()

  const generatedQuestionsQuery = useCachedQuery(
    XRealtimeStrategySelfPacedGenerateQuestions,
    { courseId, fileId },
    { refetchOnMount: false }
  )
  const generatedQuestions = generatedQuestionsQuery.data?.questions

  const {
    jsonData: { nodeMap },
  } = useSelfPacedBigContext()

  const currentQuestionSources: File[] | undefined = useMemo(() => {
    if (state.type !== 'answered') return undefined

    return state.fileIds.flatMap(fileId => {
      const file = nodeMap[asNonNullable(fileId)]
      if (file === undefined) return []
      assertWith(File, file)
      return [file]
    })
  }, [state, nodeMap])

  const startAnswering = useCallback(
    async (question: string, fileIds: FileId[]) => {
      if (state.type === 'answered' || state.type === 'answering') {
        setPreviousChats(previous => [...previous, { ...state, sourceFiles: currentQuestionSources ?? [] }])
      }
      setState({ type: 'answering', query: question, answer: '', fileIds })

      abortAnswerRef.current?.abort()
      abortAnswerRef.current = new AbortController()

      await subscribeSse(
        SSEXRealtimeSelfPacedAnswerQuestion,
        { courseId, question, fileIds },
        event => {
          setState(previous => {
            if (previous.type === 'answering') {
              return { ...previous, answer: previous.answer + event.data.text }
            } else return previous
          })
        },
        () => {
          setState(previous => {
            if (previous.type === 'answering') return { ...previous, type: 'answered' }
            else return previous
          })
        },
        abortAnswerRef.current.signal
      )
    },
    [courseId, currentQuestionSources, state, subscribeSse]
  )
  const findRelevantFileIdsMutation = useTypedMutation(XRealtimeStrategySelfPacedFilesMatchingQuestion, {
    onMutate: ({ question }) => {
      setState({ type: 'finding-matching-files', query: question })
      void generatedQuestionsQuery.refetch()
    },
    onSuccess: ({ fileIds }, { question }) => {
      if (state.type === 'finding-matching-files' && state.query === question) {
        void startAnswering(question, fileIds)
      }
    },
  })

  return (
    <QACard
      state={state}
      previousChats={previousChats}
      currentQuestionSources={currentQuestionSources}
      generatedQuestionsQuery={generatedQuestionsQuery}
      generatedQuestions={generatedQuestions}
      courseId={courseId}
      startAnswering={startAnswering}
      setPreviousChats={setPreviousChats}
      findRelevantNodesAndStartAnswering={findRelevantFileIdsMutation.mutateAsync}
      fileId={fileId}
      mode={'learner'}
    />
  )
}

export const StupidQuestionsEditorView: React.FC<{ createContentId: CreateContentId; fileId: FileId }> = ({
  createContentId,
  fileId,
}) => {
  const { subscribeSse } = useSseWithErrorNotification()

  const [state, setState] = useState<QAState>({ type: 'none' })
  const [previousChats, setPreviousChats] = useState<
    { query: string; answer: string; sourceFiles: File[] }[]
  >([])

  const abortAnswerRef = useRef<AbortController>()

  const generatedQuestionsQuery = useCachedQuery(
    XRealtimeStrategyAuthordGenerateQuestions,
    { createContentId, fileId },
    { refetchOnMount: false }
  )
  const generatedQuestions = generatedQuestionsQuery.data?.questions

  const flexibleContentNodes = useSelector(state => selectFlexibleContentNodes(state, createContentId))

  const currentQuestionSources: File[] | undefined = useMemo(() => {
    if (state.type !== 'answered' || flexibleContentNodes === undefined) return undefined

    return state.fileIds.flatMap(fileId => {
      const file = flexibleContentNodes[asNonNullable(fileId)]
      if (file === undefined) return []
      assertWith(File, file)
      return [file]
    })
  }, [state, flexibleContentNodes])

  const startAnswering = useCallback(
    async (question: string, fileIds: FileId[]) => {
      if (state.type === 'answered' || state.type === 'answering') {
        setPreviousChats(previous => [...previous, { ...state, sourceFiles: currentQuestionSources ?? [] }])
      }
      setState({ type: 'answering', query: question, answer: '', fileIds })

      abortAnswerRef.current?.abort()
      abortAnswerRef.current = new AbortController()

      await subscribeSse(
        SSEXRealtimeAuthorAnswerQuestion,
        { createContentId, question, fileIds },
        event => {
          setState(previous => {
            if (previous.type === 'answering') {
              return { ...previous, answer: previous.answer + event.data.text }
            } else return previous
          })
        },
        () => {
          setState(previous => {
            if (previous.type === 'answering') return { ...previous, type: 'answered' }
            else return previous
          })
        },
        abortAnswerRef.current.signal
      )
    },
    [createContentId, currentQuestionSources, state, subscribeSse]
  )
  const findRelevantFileIdsMutation = useTypedMutation(XRealtimeStrategyAuthorFilesMatchingQuestion, {
    onMutate: ({ question }) => {
      setState({ type: 'finding-matching-files', query: question })
      void generatedQuestionsQuery.refetch()
    },
    onSuccess: ({ fileIds }, { question }) => {
      if (state.type === 'finding-matching-files' && state.query === question) {
        void startAnswering(question, fileIds)
      }
    },
  })

  return (
    <QACard
      state={state}
      previousChats={previousChats}
      currentQuestionSources={currentQuestionSources}
      generatedQuestionsQuery={generatedQuestionsQuery}
      generatedQuestions={generatedQuestions}
      courseId={createContentId}
      startAnswering={startAnswering}
      setPreviousChats={setPreviousChats}
      findRelevantNodesAndStartAnswering={findRelevantFileIdsMutation.mutateAsync}
      fileId={fileId}
      mode={'editor'}
    />
  )
}
