import { useQueryClient } from '@tanstack/react-query'
import { createFileRoute } from '@tanstack/react-router'
import { useMemo, useState } from 'react'
import { SkillId } from 'sierra-client/api/graphql/branded-types'

import { useGraphQuery } from 'sierra-client/api/hooks/use-graphql-query'
import { PageTitle } from 'sierra-client/components/common/page-title'
import {
  AdminOnboarding,
  CreateSkillAction,
  DeleteSkillModal,
  EditSkillPanel,
  ImportSkillAction,
  QUERY_KEY,
  SkillDebugControl,
  SkillSettingAction,
  SkillsTabular,
  getSkillsCountAndLevelsQuery,
  useSkillsTableAPI,
  useTracking,
} from 'sierra-client/features/skills'
import { useOrganizationPermissions } from 'sierra-client/hooks/use-permissions'
import { useTranslation } from 'sierra-client/hooks/use-translation'
import { PageIdentifier } from 'sierra-client/layout/sana-page'
import { SearchableTabularToolbar } from 'sierra-client/lib/tabular/components/tabular-toolbar'
import { ManagePageWrapper } from 'sierra-client/views/manage/utils/manage-page-wrapper'
import { PageHeader } from 'sierra-client/views/settings/components/page-header'
import { View } from 'sierra-ui/primitives'
import { token } from 'sierra-ui/theming'
import styled from 'styled-components'

const FullPage = styled(View).attrs({ direction: 'column', grow: true, gap: 'none' })`
  height: 100%;
`

const VerticalDivider = styled.hr`
  transform: rotate(90deg);
  background-color: ${token('border/strong')};
  height: 1px;
  width: 20px;
`

// Note: ManagePageWrapper handles the role check.
function ManageSkillsPage(): JSX.Element | null {
  const { t } = useTranslation()
  const orgPermissions = useOrganizationPermissions()
  const canManageSkills = orgPermissions.has('MANAGE_SKILLS')
  const canEditSkillsSettings =
    orgPermissions.has('EDIT_SKILLS_SETTINGS') && orgPermissions.has('EDIT_SETTINGS')

  const skills = useGraphQuery({ document: getSkillsCountAndLevelsQuery }, {})
  const showOnboarding = skills.data?.skills.totalCount === 0

  const tracking = useTracking()
  const queryClient = useQueryClient()

  const [dialogOpen, setDialogOpen] = useState<
    { type: 'closed' } | { type: 'open-edit'; skillId: SkillId } | { type: 'open-delete'; skillId: SkillId }
  >({
    type: 'closed',
  })

  const actions = useMemo(
    () => ({
      onEdit: (skillId: SkillId) => {
        tracking.skill.clickActionMenu({ skillId, action: 'open-edit' })
        setDialogOpen({ type: 'open-edit', skillId })
      },
      onDelete: (skillId: SkillId) => {
        tracking.skill.clickActionMenu({ skillId, action: 'open-delete' })
        setDialogOpen({ type: 'open-delete', skillId })
      },
    }),
    [setDialogOpen, tracking.skill]
  )
  const tableAPI = useSkillsTableAPI(actions)

  if (!canManageSkills) {
    // todo(workflows): Redirect after initializing
    return null
  }

  return (
    <>
      <PageTitle title={t('dictionary.skills')} />

      <ManagePageWrapper pageIdentifier={PageIdentifier.ManageSkills()}>
        <FullPage>
          {showOnboarding ? (
            <AdminOnboarding />
          ) : (
            <>
              <PageHeader
                title='dictionary.skills'
                withoutMargin
                helpLink='https://help.sana.ai/en/articles/158979-skills'
              />

              <View justifyContent='flex-end' paddingBottom='16'>
                <SearchableTabularToolbar api={tableAPI.api} />

                <SkillDebugControl />

                <View>
                  {canEditSkillsSettings ? (
                    <>
                      <SkillSettingAction />
                      <VerticalDivider />
                    </>
                  ) : undefined}
                  <ImportSkillAction />
                  <CreateSkillAction />
                </View>
              </View>

              <SkillsTabular tableAPI={tableAPI} />
            </>
          )}
        </FullPage>
      </ManagePageWrapper>

      <EditSkillPanel
        onClose={() => {
          setDialogOpen({ type: 'closed' })
        }}
        onSuccess={() => {
          setDialogOpen({ type: 'closed' })
          void queryClient.invalidateQueries({ queryKey: QUERY_KEY })
        }}
        open={dialogOpen.type === 'open-edit'}
        skillId={dialogOpen.type === 'open-edit' ? dialogOpen.skillId : undefined}
      />
      <DeleteSkillModal
        onClose={() => {
          setDialogOpen({ type: 'closed' })
        }}
        onSuccess={() => {
          setDialogOpen({ type: 'closed' })
          void queryClient.invalidateQueries({ queryKey: QUERY_KEY })
        }}
        skillId={dialogOpen.type === 'open-delete' ? dialogOpen.skillId : undefined}
      />
    </>
  )
}

const RouteComponent = ManageSkillsPage

export const Route = createFileRoute('/manage/skills/')({
  component: RouteComponent as React.FC,
})
