import { createContext, useContext } from 'react'
import { useSelector } from 'sierra-client/state/hooks'
import { selectSessionState } from 'sierra-client/state/live-session/selectors'
import { LiveSession } from 'sierra-domain/content/session'

export type LiveSessionContext = LiveSession

const LiveSessionContextObj = createContext<LiveSessionContext | undefined>(undefined)

export const LiveSessionContextProvider = LiveSessionContextObj.Provider

export const useLiveSessionContext = (): LiveSessionContext => {
  const context = useContext(LiveSessionContextObj)

  if (context === undefined) {
    throw new Error('This component must be wrapped in a LiveSessionContextProvider')
  }

  return context
}

export const useSafeLiveSessionContext = (): LiveSessionContext | undefined => {
  const context = useContext(LiveSessionContextObj)
  return context
}

export const useLiveSessionState = (): 'active' | 'ended' => {
  const sessionData = useLiveSessionContext().data
  // TODO: Remove this selector and make the context update realtime instead
  const liveSessionState = useSelector(selectSessionState)
  return liveSessionState === 'ended' || sessionData.endedAt !== undefined ? 'ended' : 'active'
}
