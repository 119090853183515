import { useTranslation } from 'sierra-client/hooks/use-translation'
import { CommentContentReference } from 'sierra-domain/chat'
import { assertNever } from 'sierra-domain/utils'
import { Text } from 'sierra-ui/primitives'
import styled, { css } from 'styled-components'

const StyledText = styled(Text)<{ $isText: boolean }>`
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;

  ${p =>
    p.$isText &&
    css`
      background-color: ${p => p.theme.color.blueSelected};
    `}
`

export const ContentReferencePresentation: React.FC<{
  contentReference: CommentContentReference
}> = ({ contentReference }) => {
  const { t } = useTranslation()

  switch (contentReference.type) {
    case 'block':
      return (
        <StyledText size='micro' $isText={false} color='foreground/secondary'>
          {t('chat.commented-on')}&nbsp;
          {contentReference.blockPreview !== undefined ? (
            <>{contentReference.blockPreview}</>
          ) : (
            t('chat.a-block')
          )}
        </StyledText>
      )
    case 'range':
      return (
        <StyledText size='micro' $isText={true} color='foreground/secondary'>
          {contentReference.rangePreview !== undefined ? <>{contentReference.rangePreview}</> : ''}
        </StyledText>
      )
    default:
      assertNever(contentReference)
  }
}
