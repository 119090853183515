import { ComputePressureValue } from 'sierra-client/components/liveV2/live-layer/call-stats/atoms'

// These matcht the ComputePresure
type SystemPresure = 'nominal' | 'fair' | 'serious' | 'critical'
type PresureSignals = { rrv: number; scheduling: number; computePresure: ComputePressureValue[] }

// These values are quite arbitrary and should be adjusted based on how this performs.
// Now I just looked at the data collected in NR and picked some values that seemed reasonable.
const THREASHOLDS: Record<SystemPresure, PresureSignals> = {
  nominal: {
    rrv: 0,
    scheduling: 0,
    computePresure: ['nominal', 'fair', 'serious', 'critical'],
  },
  fair: {
    rrv: 6,
    scheduling: 50,
    computePresure: ['nominal', 'fair', 'serious', 'critical'],
  },
  serious: {
    rrv: 10,
    scheduling: 100,
    computePresure: ['fair', 'serious', 'critical'],
  },
  critical: {
    rrv: 15,
    scheduling: 250,
    computePresure: ['serious', 'critical'],
  },
}

export const getCurrentPressure = ({
  rrvEma,
  schedulingEma,
  computePressure,
}: {
  rrvEma: number | undefined
  schedulingEma: number | undefined
  computePressure?: ComputePressureValue | undefined
}): SystemPresure => {
  for (const presure of ['critical', 'serious', 'fair', 'nominal'] as const) {
    const threasholds = THREASHOLDS[presure]

    const isRrvAboveThreashold = rrvEma !== undefined && rrvEma > threasholds.rrv
    const isSchedulingAboveThreashold = schedulingEma !== undefined && schedulingEma > threasholds.scheduling

    // If we don't have compute pressure, we don't care about it
    const isComputePresureAboveThreashold =
      computePressure !== undefined ? threasholds.computePresure.includes(computePressure) : true

    if (isRrvAboveThreashold && isSchedulingAboveThreashold && isComputePresureAboveThreashold) return presure
  }

  return 'nominal'
}
