import React from 'react'
import { ConfirmationModalProvider } from 'sierra-client/components/common/modals/confirmation-modal'
import { getFlag } from 'sierra-client/config/global-config'
import { AddTrainingPanelContent } from 'sierra-client/features/external-trainings/add-training-panel'
import { ViewTrainingPanelContent } from 'sierra-client/features/external-trainings/view-transcript-panel'
import { useToggle } from 'sierra-client/hooks/use-toggle'
import { useTranslation } from 'sierra-client/hooks/use-translation'
import { UserId } from 'sierra-domain/api/uuid'
import { isDefined } from 'sierra-domain/utils'
import { Panel } from 'sierra-ui/components'
import { Button, View } from 'sierra-ui/primitives'

const TrainingsPanel: React.FC<{ userId?: UserId }> = ({ userId }) => {
  const [addTrainingPanelIsOpen, { on: openAddTrainingPanel, off: closeAddTrainingPanel }] = useToggle(false)
  const [viewTrainingsPanelIsOpen, { on: openViewTrainingPanel, off: closeViewTrainingPanel }] =
    useToggle(false)
  const { t } = useTranslation()

  return (
    <>
      <View alignSelf='flex-end'>
        <Button variant='secondary' onClick={openViewTrainingPanel}>
          {t('my-library.view-transcript')}
        </Button>
        <Button variant='primary' onClick={openAddTrainingPanel} icon='add'>
          {t('my-library.add-external-training')}
        </Button>
      </View>
      {isDefined(userId) && (
        <>
          <Panel
            open={addTrainingPanelIsOpen}
            padding='32 40'
            onClose={closeAddTrainingPanel}
            size={{ width: 600 }}
          >
            <AddTrainingPanelContent userId={userId} closePanel={closeAddTrainingPanel} />
          </Panel>
          <Panel
            open={viewTrainingsPanelIsOpen}
            padding='32 40'
            onClose={closeViewTrainingPanel}
            size={{ width: 600 }}
          >
            <ConfirmationModalProvider>
              <ViewTrainingPanelContent userId={userId} closePanel={closeViewTrainingPanel} />
            </ConfirmationModalProvider>
          </Panel>
        </>
      )}
    </>
  )
}

export const ExternalTrainigs: React.FC<{ userId?: UserId }> = ({ userId }) => {
  const isLearnerTranscriptEnabled = getFlag('learner-transcript')
  if (!isLearnerTranscriptEnabled) return null

  const isExternalTrainingsEnabled = getFlag('external-user-trainings')
  if (!isExternalTrainingsEnabled) return null

  return <TrainingsPanel userId={userId} />
}
