import { getGlobalRouter } from 'sierra-client/router'
import { ScopedCreateContentId } from 'sierra-domain/collaboration/types'
import { NodeId } from 'sierra-domain/flexible-content/identifiers'

export type NavigateToCreateContentIdPayload = {
  scopedCreateContentId: ScopedCreateContentId
  nodeId?: NodeId
}

export type NavigateToCreateContentIdOptions = {
  replace?: boolean
}

export function navigateToCreateContentId(
  { scopedCreateContentId, nodeId }: NavigateToCreateContentIdPayload,
  userOptions?: NavigateToCreateContentIdOptions
): Promise<void> {
  const { replace = false } = userOptions ?? {}
  const scope = ScopedCreateContentId.urlSubPathForId(scopedCreateContentId)
  const createContentId = ScopedCreateContentId.extractId(scopedCreateContentId)

  return getGlobalRouter().navigate({
    to: `/create/${scope}/$`,
    params: { _splat: nodeId === undefined ? createContentId : `${createContentId}/${nodeId}` },
    replace,
  })
}
