import React from 'react'
import { useDragLayer } from 'react-dnd'
import { DragItem, DragItemTypes } from 'sierra-client/components/common/dnd/dnd-types'
import { DragOverlayPosition } from 'sierra-client/components/common/dnd/drag-overlay-position'
import { isDefined } from 'sierra-domain/utils'
import { color } from 'sierra-ui/color'
import styled, { ThemeProvider } from 'styled-components'

const FadedBox = styled.div<{ width: number }>`
  position: relative;
  height: 150px;
  padding: 16px;
  overflow: hidden;

  width: ${p => p.width}px;
  border-radius: 8px;
  background: linear-gradient(
    165deg,
    ${p => color(p.theme.home.backgroundColor).shift(0.04).toString()} 30%,
    transparent 80%
  );
`

export const FadedBoxDragOverlay: React.FC = () => {
  const { isDragging, item } = useDragLayer<{
    isDragging: boolean
    item: DragItem
  }>(monitor => ({
    isDragging: monitor.isDragging(),
    item: monitor.getItem(),
  }))

  if (!isDragging) return null
  if (isDefined(item) && item.type !== DragItemTypes.VerticalStack) return null

  return (
    <ThemeProvider theme={item.theme}>
      <DragOverlayPosition>
        <FadedBox width={item.width ?? 100}></FadedBox>
      </DragOverlayPosition>
    </ThemeProvider>
  )
}
