import { UseQueryResult } from '@tanstack/react-query'
import { CachedQueryOptions, useCachedQuery } from 'sierra-client/state/api'
import { ListEntitiesResponse } from 'sierra-domain/api/recommendations'
import { XRealtimeStrategyRecommend2ListEntities } from 'sierra-domain/routes'

export const useListLearnEntities = <TSelectData = ListEntitiesResponse>(
  options: CachedQueryOptions<TSelectData> = {}
): UseQueryResult<TSelectData> => {
  return useCachedQuery(
    XRealtimeStrategyRecommend2ListEntities,
    {},
    {
      retry: false,
      ...options,
    }
  )
}
