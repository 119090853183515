import storage from 'local-storage-fallback'
import { useEffect, useRef } from 'react'

const browserSessionKey = 'browser_session'

enum Durations {
  Update = 5 * 1000,
  Inactivity = 15 * 60 * 1000,
}

type BrowserSessionInfo = {
  sessionStartTimestamp?: number
  lastActivity?: number
}

const readBrowserSession = (): BrowserSessionInfo => {
  try {
    const browserSession: BrowserSessionInfo = JSON.parse(storage.getItem(browserSessionKey) ?? '{}')
    return browserSession
  } catch (e) {
    return {}
  }
}

const updateBrowserSession = (): void => {
  if (typeof window === 'undefined') return

  const { lastActivity, sessionStartTimestamp } = readBrowserSession()

  const shouldKeepPreviousSession =
    lastActivity !== undefined && lastActivity > Date.now() - Durations.Inactivity

  const newBrowserSession: BrowserSessionInfo = {
    sessionStartTimestamp: shouldKeepPreviousSession ? sessionStartTimestamp : Date.now(),
    lastActivity: Date.now(),
  }

  storage.setItem(browserSessionKey, JSON.stringify(newBrowserSession))
}

export const getBrowserSessionTimestamp = (): number | null => {
  if (typeof window === 'undefined') return null
  updateBrowserSession()

  const item = storage.getItem(browserSessionKey)
  if (item === null) return null

  const sessionStartTimestamp = readBrowserSession().sessionStartTimestamp
  return sessionStartTimestamp ?? null
}

export const UpdateBrowserSession = (): null => {
  const intervalRef = useRef<NodeJS.Timeout | null>(null)

  const clearIntervalRef = (): void => {
    if (intervalRef.current !== null) {
      clearInterval(intervalRef.current)
      intervalRef.current = null
    }
  }

  const setIntervalRef = (): void => {
    clearIntervalRef()
    updateBrowserSession()
    intervalRef.current = setInterval(updateBrowserSession, Durations.Update)
  }

  useEffect(
    () => {
      setIntervalRef()
      window.addEventListener('focus', setIntervalRef)
      window.addEventListener('blur', clearIntervalRef)
      return (): void => {
        clearIntervalRef()
        window.removeEventListener('focus', setIntervalRef)
        window.removeEventListener('blur', clearIntervalRef)
      }
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    []
  )

  return null
}
