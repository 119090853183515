/* eslint-disable react/jsx-no-literals */

import { useMemo } from 'react'
import { SkillId } from 'sierra-client/api/graphql/branded-types'
import { RouterLink } from 'sierra-client/components/common/link'
import { isNonEmptyArray } from 'sierra-domain/utils'
import { SkillIconId, Tooltip } from 'sierra-ui/components'
import { Text, View } from 'sierra-ui/primitives'
import { dotSeparator } from 'sierra-ui/utils'
import styled, { css } from 'styled-components'
import { SkillColor } from '../colors'
import { useTracking } from '../tracking'
import { SimpleBadge } from './badges/simple-badge'

const BadgeLink = styled(RouterLink)``

const StackContainer = styled.div<{ $size: StackSize }>`
  display: flex;
  align-items: center;
  position: relative;
  isolation: isolate;

  ${BadgeLink} {
    transition: all 150ms ease-in-out;

    ${p =>
      p.$size === 'small'
        ? css`
            width: 32px;
            height: 32px;
            margin: 0 -4px;

            &:hover {
              transform: translateY(-5px);
            }
          `
        : css`
            width: 52px;
            height: auto;
            margin: 0 -10px;

            &:hover {
              transform: translateY(-7px);
            }
          `}
  }
`

type StackSize = 'large' | 'small'

export type BadgeStackItem = {
  color: SkillColor
  icon: SkillIconId
  skill: {
    id: SkillId
    name: string
  }
  skillLevel: {
    index: number
    name: string
  }
}

export const BadgeStack: React.FC<{
  badges: Array<BadgeStackItem>
  stackSize?: number
  size?: StackSize
}> = ({ badges, stackSize = 5, size = 'small' }) => {
  const tracking = useTracking()

  const { badgesToRender, badgesToOmit } = useMemo(() => {
    return {
      badgesToRender: badges.slice(0, stackSize),
      badgesToOmit: badges.slice(stackSize),
    }
  }, [badges, stackSize])

  return (
    <StackContainer $size={size}>
      {badgesToRender.map(badge => {
        return (
          <Tooltip
            key={badge.skillLevel.index}
            title={`${badge.skill.name} ${dotSeparator} ${badge.skillLevel.name}`}
            side='top'
          >
            <BadgeLink
              href={`/skill/${badge.skill.id}`}
              onClick={() => {
                tracking.badge.click({
                  theme: badge.color,
                  iconId: badge.icon,
                })
              }}
            >
              <SimpleBadge theme={badge.color} iconId={badge.icon} />
            </BadgeLink>
          </Tooltip>
        )
      })}
      {isNonEmptyArray(badgesToOmit) && (
        <View paddingLeft='6'>
          <Text size='micro' color='foreground/muted'>{`+${badgesToOmit.length}`}</Text>
        </View>
      )}
    </StackContainer>
  )
}
