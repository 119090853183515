import { useVirtualizer } from '@tanstack/react-virtual'
import _ from 'lodash'
import React, { useCallback, useEffect, useMemo, useRef } from 'react'
import { useTranslation } from 'sierra-client/hooks/use-translation'
import {
  AutocompleteOption,
  AutocompleteOptions,
} from 'sierra-client/views/manage/components/user-attributes/flows/components/layout'
import { useComposeUserInvitationConfig } from 'sierra-client/views/manage/components/user-attributes/flows/invite-users/hooks/use-compose-user-invitation-config'
import {
  GreyPill,
  RemoveButtonInPills,
} from 'sierra-client/views/manage/components/user-attributes/flows/invite-users/panels/set-attributes-panel/auto-complete-pills'
import { getCommonCourseIdsForEmails } from 'sierra-client/views/manage/components/user-attributes/flows/invite-users/utils'
import { useManageCourses } from 'sierra-client/views/manage/courses/use-manage-courses'
import { CourseSummary } from 'sierra-domain/api/admin'
import { isDefined } from 'sierra-domain/utils'
import { Autocomplete, DefaultStyledAutocompleteOption } from 'sierra-ui/components'
import { Text, View } from 'sierra-ui/primitives'

type Course = { id: string; title: string }

const VirtualizedListOfCourses: React.FC<{ matchingCourses: Course[] }> = ({ matchingCourses }) => {
  const scrollingRef = useRef<HTMLDivElement>(null)
  const virtualizer = useVirtualizer({
    count: matchingCourses.length,
    getScrollElement: () => scrollingRef.current,
    estimateSize: () => 55,
    overscan: 20,
  })

  return (
    <AutocompleteOptions ref={scrollingRef}>
      <AutocompleteOption>
        {virtualizer.getVirtualItems().map(virtualItem => {
          const index = virtualItem.index
          const item = matchingCourses[index]

          if (!isDefined(item)) return null

          return (
            <DefaultStyledAutocompleteOption key={item.id} value={item.id}>
              <Text bold>{item.title}</Text>
            </DefaultStyledAutocompleteOption>
          )
        })}
      </AutocompleteOption>
    </AutocompleteOptions>
  )
}

const courseIdToTitle = (courseId: string, allCourses: CourseSummary[]): string => {
  return _.find(allCourses, c => c.courseId === courseId)?.title ?? ''
}

export const CoursesAutoComplete: React.FC = () => {
  const { courses: allCourses } = useManageCourses()

  const [query, setQuery] = React.useState('')
  const [showPlaceholder, setShowPlaceholder] = React.useState(false)
  const [matchingItems, setMatchingItems] = React.useState<Course[]>([])

  const { editingEmails, coursesPerUser, updateCoursesPerUser } = useComposeUserInvitationConfig()

  const { t } = useTranslation()

  const selectedCourses: Course[] = useMemo(() => {
    const courseIds = getCommonCourseIdsForEmails(coursesPerUser, editingEmails)

    if (courseIds === undefined) {
      //case if values are different
      setShowPlaceholder(true)
      return []
    }

    if (courseIds.length === 0) {
      //case if common values are empty
      setShowPlaceholder(false)
      return []
    }

    setShowPlaceholder(false)
    return courseIds.map(id => ({ id: id, title: courseIdToTitle(id, allCourses) })) //case if common values exist
  }, [allCourses, coursesPerUser, editingEmails])

  const onSelectItem = React.useCallback(
    (item: Course) => {
      const courseIdsWithNewCourse = selectedCourses.map(course => course.id).concat(item.id)
      updateCoursesPerUser(courseIdsWithNewCourse)
    },
    [selectedCourses, updateCoursesPerUser]
  )

  const onUnselectItem = React.useCallback(
    (item: Course) => {
      const selectedCoursesWithoutItem = selectedCourses.filter(course => course.id !== item.id)
      updateCoursesPerUser(selectedCoursesWithoutItem.map(course => course.id))
    },
    [selectedCourses, updateCoursesPerUser]
  )

  const updateMatchingItems = useCallback(() => {
    const matching =
      query.length === 0
        ? allCourses
        : allCourses.filter(i => {
            const title = i.title.toLowerCase()
            const q = query.toLowerCase()

            return title.includes(q)
          })

    const excludeSelected = matching.filter(i => !selectedCourses.some(c => c.id === i.courseId))

    const matchingIds = excludeSelected.map(i => ({
      id: i.courseId,
      title: i.title,
    }))

    setMatchingItems(matchingIds)
  }, [allCourses, query, selectedCourses])

  const onQueryChange = React.useCallback(
    (value: string) => {
      setQuery(value)
      updateMatchingItems()
    },
    [updateMatchingItems]
  )

  useEffect(() => {
    updateMatchingItems()
  }, [updateMatchingItems])

  return (
    <View>
      <Autocomplete
        query={query}
        onQueryChange={onQueryChange}
        matchingItems={matchingItems}
        selectedItems={selectedCourses}
        onSelect={onSelectItem}
        onUnselect={onUnselectItem}
        placeholder={showPlaceholder ? t('manage.users.invite.bulk-editing.mixed') : ''}
        renderSelectedItem={(course, { onUnselect, ...props }) => {
          return (
            <GreyPill key={course.id} {...props}>
              <View gap='6'>
                <Text bold>{course.title}</Text>
              </View>
              <RemoveButtonInPills onClick={onUnselect} aria-label={t('dictionary.remove')} />
            </GreyPill>
          )
        }}
        renderMatchingItemList={() => <VirtualizedListOfCourses matchingCourses={matchingItems} />}
      />
    </View>
  )
}
