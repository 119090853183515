import DOMPurify from 'dompurify'
import { encode } from 'html-entities'
import ReactDOMServer from 'react-dom/server'
import { config } from 'sierra-client/config/global-config'
import { User } from 'sierra-domain/user'
import { View } from 'sierra-ui/primitives'
import styled from 'styled-components'

export type EmbedInfo = {
  html: string
  duration?: number
}

const StaticWrap = styled.div`
  & > div {
    margin-left: auto;
    margin-right: auto;
  }

  max-height: 100%;
`

// There are courses for Medlearn that use this type of embed. For example, see
// https://sanalabs.sana.ai/course/-kq8IQl6OOad/module/CNBLSCa_tW4y/lesson/UvCt3DQS9SQF
//
// For testing these embeds, we need to use https://medlearn.sana.ai that has special
// CSP rules in next.config.js that enables unsafe directives, since the normal CSP
// is incompatible with the linked script file.
const getHowdouEmbedElement = async (href: string, user: User): Promise<JSX.Element> => {
  const params = new URL(href).searchParams

  const src = ReactDOMServer.renderToStaticMarkup(
    <html>
      <body>
        <div
          data-howdou={params.get('data-howdou')}
          data-howdou-partner={params.get('data-howdou-partner')}
          data-howdou-hide-title={params.get('data-howdou-hide-title')}
          data-howdou-lang={params.get('data-howdou-lang')}
          data-howdou-mode={params.get('data-howdou-mode')}
          data-howdou-email={user.email}
          data-howdou-first-name={user.firstName}
          data-howdou-last-name={user.lastName}
        />
        <script src='https://embed.howdou.net/sana.js' />
      </body>
    </html>
  )

  return (
    <StaticWrap>
      <div className='howdou-embed'>
        <div className='howdou-embed-dummy' />
        <iframe scrolling='no' className='howdou-embed-iframe' srcDoc={src} />
      </div>
    </StaticWrap>
  )
}

// There are courses for KI that use this type of embed. For example, see
// https://sanalabs.sana.ai/course/tuAzwP-URqmX/module/TnhaonNBLMQJ/lesson/0AOA7xPZFHRF
const getKiEmbedElement = async (href: string): Promise<JSX.Element> => {
  const id = encode(href.split('/').slice(-1)[0])

  return (
    <StaticWrap>
      <div className='karolinska-video'>
        <iframe
          id='kaltura_player'
          src={`https://api.kaltura.nordu.net/p/318/sp/31800/embedIframeJs/uiconf_id/23450616/partner_id/318?iframeembed=true&playerId=kaltura_player&entry_id=${id}&flashvars[streamerType]=auto&amp;flashvars[localizationCode]=en&amp;flashvars[leadWithHTML5]=true&amp;flashvars[sideBarContainer.plugin]=true&amp;flashvars[sideBarContainer.position]=left&amp;flashvars[sideBarContainer.clickToClose]=true&amp;flashvars[chapters.plugin]=true&amp;flashvars[chapters.layout]=vertical&amp;flashvars[chapters.thumbnailRotator]=false&amp;flashvars[streamSelector.plugin]=true&amp;flashvars[EmbedPlayer.SpinnerTarget]=videoHolder&amp;flashvars[dualScreen.plugin]=true&amp;flashvars[Kaltura.addCrossoriginToIframe]=true&amp;&wid=0_kvwhxzxh`}
          width='100%'
          height='100%'
          allowFullScreen={true}
          /* eslint-disable-next-line @typescript-eslint/ban-ts-comment */
          // @ts-expect-error
          webkitallowfullscreen={true}
          mozallowfullscreen={true}
          allow='autoplay *; fullscreen *; encrypted-media *'
          sandbox='allow-forms allow-same-origin allow-scripts allow-top-navigation allow-pointer-lock allow-popups allow-modals allow-orientation-lock allow-popups-to-escape-sandbox allow-presentation allow-top-navigation-by-user-activation'
          frameBorder='0'
          title='Kaltura Player'
        />
      </div>
    </StaticWrap>
  )
}

export const getIframelyUrl = (href: string): string => {
  const url = new URL('https://iframe.ly/api/iframely')

  url.searchParams.set('url', href)
  url.searchParams.set('api_key', config.iframelyApiKey)
  url.searchParams.set('iframe', '1')
  url.searchParams.set('omit_script', '1')
  url.searchParams.set('playerjs', 'true')

  return url.href
}

const getIframelyEmbedInfo = async (href: string): Promise<EmbedInfo> => {
  const response = await fetch(getIframelyUrl(href), {})

  const payload = (await response.json()) as { html: string; meta: { duration: number | undefined } }

  // The html property is not used anymore since we query iframely on render through
  // getIframelyEmbedElement, so we can probably replace html with empty string here.
  return { html: payload.html, duration: payload.meta.duration }
}

const getIframelyEmbedElement = async (href: string): Promise<JSX.Element> => {
  const response = await fetch(getIframelyUrl(href), {})

  const payload = (await response.json()) as { html: string; meta: { duration: number | undefined } }

  return (
    <StaticWrap
      dangerouslySetInnerHTML={{
        __html: DOMPurify.sanitize(payload.html, {
          ADD_TAGS: ['iframe'],
          ADD_ATTR: ['allowfullscreen'],
          KEEP_CONTENT: false,
        }),
      }}
    />
  )
}

const Audio = styled.audio`
  width: 100%;
  max-width: 32rem;
`

// Render .mp3 audio files with correct html5 tag for native style
const getAudioEmbedElement = async (href: string): Promise<JSX.Element> => {
  return (
    <StaticWrap>
      <View justifyContent='center' gap='none'>
        <Audio controls>
          <source src={href} type='audio/mpeg' />
        </Audio>
      </View>
    </StaticWrap>
  )
}

type SaveHandler = (href: string) => Promise<EmbedInfo>
type RenderHandler = (href: string, user: User) => Promise<JSX.Element>

const handlers: { pattern: RegExp; save?: SaveHandler; render: RenderHandler }[] = [
  { pattern: /embed\.howdou\.net/, render: getHowdouEmbedElement },
  { pattern: /play\.ki\.se/, render: getKiEmbedElement },
  { pattern: /\.mp3$/, render: getAudioEmbedElement },
  { pattern: /.*/, save: getIframelyEmbedInfo, render: getIframelyEmbedElement },
]

export const getEmbedInfo = async (url: string): Promise<EmbedInfo | undefined> => {
  const handler = handlers.find(({ pattern }) => pattern.test(url))

  if (!handler) {
    throw new Error(`No handler exists for ${url}`)
  }

  return handler.save?.(url)
}

export const getEmbedElement = async (url: string, user: User): Promise<JSX.Element> => {
  const handler = handlers.find(({ pattern }) => pattern.test(url))

  if (!handler) {
    throw new Error(`No handler exists for ${url}`)
  }

  return handler.render(url, user)
}
