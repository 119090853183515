import { Measures } from 'sierra-domain/api/insights'
import { IconId } from 'sierra-ui/components'

export const measureIconMap: Record<Measures['id'], IconId> = {
  'users.count': 'user',
  'active-users': 'user--done',
  'sessions.duration': 'timer',
  'sessions.average-duration-per-user': 'timer',
  'user-course-progress.access': 'view',
  'user-course-progress.progress': 'gauge',
  'user-content-progress.assigned': 'user--add',
  'user-course-progress.assigned': 'course',
  'user-path-progress.assigned': 'path',
  'user-program-progress.assigned': 'portfolio',
  'user-content-progress.started': 'play--circle--outline',
  'user-course-progress.started': 'course',
  'user-path-progress.started': 'path',
  'user-program-progress.started': 'portfolio',
  'user-content-progress.start-rate': 'play--circle--outline',
  'user-course-progress.start-rate': 'course',
  'user-path-progress.start-rate': 'path',
  'user-program-progress.start-rate': 'portfolio',
  'user-content-progress.passed': 'checkmark--outline',
  'user-course-progress.passed': 'course',
  'user-path-progress.passed': 'path',
  'user-program-progress.passed': 'portfolio',
  'user-content-progress.passed-rate': 'checkmark--outline',
  'user-course-progress.passed-rate': 'course',
  'user-path-progress.passed-rate': 'path',
  'user-program-progress.passed-rate': 'portfolio',
  'user-question.question': 'help',
  'user-question.attempts': 'hashtag',
  'questions.success-rate': 'checkbox--checked',
  'questions.first-time-correct-rate': 'checkbox--checked',
  'questions.users-attempted': 'user',
  'user-assessment.assessment': 'assessment',
  'user-assessment.attempts': 'hashtag',
  'user-assessment.users-attempted': 'user',
  'user-assessment.score': 'star',
  'user-assessment.first-attempt-score': 'star',
  'user-assessment.last-attempt-score': 'star',
  'user-assessment.average-attempts-per-responder': 'hashtag',
  'poll.poll': 'poll',
  'user-poll.votes': 'hashtag',
  'user-poll.users-attempted': 'user',
  'skills': 'skill',
  'user-skills.subscriptions': 'hashtag',
  'user-skills.completions': 'hashtag',
  'user-course-feedback.feedback': 'star',
  'user-course-feedback.count': 'hashtag',
  'user-course-feedback.average-rating': 'star',
  'user-homework-submission': 'task',
  'user-homework-submission.submissions': 'hashtag',
  'user-homework-submission.pass-rate': 'checkbox--checked',
  'user-homework-submission.first-time-pass-rate': 'checkbox--checked',
  'certificates': 'medal',
  'issued-certificates.active': 'hashtag',
  'issued-certificates.expired': 'hashtag',
  'issued-certificates.revoked': 'hashtag',
  'issued-certificates.count': 'hashtag',
}
