import { useState } from 'react'
import { Context } from 'sierra-client/lib/filter/components/common'
import { useTypedMutation } from 'sierra-client/state/api'
import { FilterGenerateDomain } from 'sierra-domain/api/filter'
import { XRealtimeFilterGenerate } from 'sierra-domain/routes'
import { GenerationPopover } from './generation-popover'

type FilterGenerationPopoverProps = {
  domain: FilterGenerateDomain
  ctx: Context
}

export const FilterGenerationPopover: React.FC<FilterGenerationPopoverProps> = ({ domain, ctx }) => {
  const [isLoading, setIsLoading] = useState(false)

  const generateFilterMutation = useTypedMutation(XRealtimeFilterGenerate, {})

  const onGenerate = (prompt: string, onSuccess: () => void, onError: (error: string) => void): void => {
    setIsLoading(true)
    generateFilterMutation.mutate(
      { prompt, domain },
      {
        onSuccess: response => {
          const { filter } = response
          onSuccess()
          ctx.update(() => filter)
        },
        onError: error => {
          onError(JSON.parse(error.text).error.message)
        },
      }
    )
    setIsLoading(false)
  }

  return (
    <GenerationPopover
      inputPlaceholder='manage.insights.widget-builder.generate-ai-filter-placeholder'
      iconButtonTooltip='manage.insights.widget-builder.generate-ai-filter-tooltip'
      isLoading={isLoading}
      onGenerate={onGenerate}
    />
  )
}
