import { useMutation } from '@tanstack/react-query'
import { groupBy } from 'lodash'
import React, { useMemo } from 'react'
import { graphql } from 'sierra-client/api/graphql/gql'
import {
  MarkUserAttendaceModalUserItemFragment,
  SetCalendarAttendanceInput,
} from 'sierra-client/api/graphql/gql/graphql'
import {
  convertGQLAvatar,
  getAvatarColor,
  getAvatarUrl,
} from 'sierra-client/api/graphql/util/convert-gql-avatar'
import { graphQuery } from 'sierra-client/api/hooks/use-graphql-query'
import { useTranslation } from 'sierra-client/hooks/use-translation'
import { withModal } from 'sierra-client/views/manage/utils/with-modal'
import { CalendarEventId } from 'sierra-domain/api/nano-id'
import { assertNever, iife } from 'sierra-domain/utils'
import { Icon, MenuButton, RoundAvatar } from 'sierra-ui/components'
import { Button, Heading, IconButton, Spacer, Text, View } from 'sierra-ui/primitives'
import { spacing, token } from 'sierra-ui/theming'
import styled from 'styled-components'

const Container = styled(View).attrs({ direction: 'column' })`
  position: relative;
  padding: ${spacing['40']};
  min-width: 664px;
  max-height: 80vh;
`

const CloseIconContainer = styled.div`
  position: absolute;
  top: ${spacing['16']};
  right: ${spacing['16']};
`

const ListContainer = styled(View).attrs({ direction: 'column' })`
  gap: 0;
`
const ListElement = styled(View)`
  justify-content: space-between;
  align-items: center;
  min-height: 64px;
  border-bottom: 1px solid ${token('border/default')};
`

export const userAttendanceFragment = graphql(`
  fragment MarkUserAttendaceModalUserItem on CalendarEventAssignment {
    attended
    hasSelfReportedAttendance
    user {
      id
      displayName
      firstName
      lastName
      email
      avatar {
        ...AvatarFragment
      }
    }
  }
`)

const upsertUserAttendanceMutation = graphql(`
  mutation AttendUser($input: SetCalendarAttendanceInput!) {
    setCalendarEventAttendance(input: $input) {
      ...MarkUserAttendaceModalUserItem
    }
  }
`)

const UserListElement: React.FC<{
  item: MarkUserAttendaceModalUserItemFragment
  onChange: () => void
  calendarEventId: CalendarEventId
}> = props => {
  const { t } = useTranslation()

  const item = useMemo(() => {
    const attendanceData = props.item
    return {
      ...attendanceData,
      user: { ...attendanceData.user, avatar: convertGQLAvatar(attendanceData.user.avatar) },
    }
  }, [props.item])

  const upsertUserAttendance = useMutation({
    mutationFn: (input: SetCalendarAttendanceInput) => graphQuery(upsertUserAttendanceMutation, { input }),
    onSuccess: props.onChange,
  })

  return (
    <ListElement key={item.user.id}>
      <View>
        <RoundAvatar
          color={getAvatarColor(item.user.avatar)}
          src={getAvatarUrl(item.user.id, item.user.avatar)}
          firstName={item.user.firstName ?? ''}
          lastName={item.user.lastName ?? ''}
          size={'small'}
        />
        <div>
          <Text color='foreground/primary' size='small' bold>
            {item.user.displayName}
          </Text>
          <Text color='foreground/muted' size='small'>
            {item.user.email}
          </Text>
        </div>
      </View>

      <View>
        {item.hasSelfReportedAttendance && (
          <Icon
            aria-label={t('manage.events.has-checked-in-tooltip')}
            tooltip={t('manage.events.has-checked-in-tooltip')}
            iconId='checkmark--filled'
          />
        )}
        {iife(() => {
          switch (item.attended) {
            case 'ATTENDED':
              return (
                <MenuButton
                  variant='success'
                  menuItems={[
                    {
                      id: 'mark-attended-as-not-attended',
                      type: 'label',
                      label: t('manage.events.did-not-attend'),
                    },
                  ]}
                  onSelect={selectedItem => {
                    switch (selectedItem.id) {
                      case 'mark-attended-as-not-attended':
                        upsertUserAttendance.mutate({
                          attendeeId: item.user.id,
                          calendarEventId: props.calendarEventId,
                          attended: false,
                        })
                        return
                      default:
                        assertNever(selectedItem.id)
                    }
                  }}
                >
                  {t('manage.events.attended')}
                </MenuButton>
              )
            case 'NOT_ATTENDED':
              return (
                <MenuButton
                  variant='destructive'
                  menuItems={[
                    {
                      id: 'mark-not-attended-as-attended',
                      type: 'label',
                      label: t('manage.events.attended'),
                    },
                  ]}
                  onSelect={selectedItem => {
                    switch (selectedItem.id) {
                      case 'mark-not-attended-as-attended':
                        upsertUserAttendance.mutate({
                          attendeeId: item.user.id,
                          calendarEventId: props.calendarEventId,
                          attended: true,
                        })
                        return
                      default:
                        assertNever(selectedItem.id)
                    }
                  }}
                >
                  {t('manage.events.did-not-attend')}
                </MenuButton>
              )
            case 'NOT_REPORTED':
              return (
                <View>
                  <Button
                    icon='checkbox--checkmark--filled'
                    iconColor='greenBright'
                    variant='secondary'
                    onClick={() => {
                      upsertUserAttendance.mutate({
                        attendeeId: item.user.id,
                        calendarEventId: props.calendarEventId,
                        attended: true,
                      })
                    }}
                  >
                    {t('manage.events.attended')}
                  </Button>
                  <Button
                    icon='checkbox--cross--filled'
                    iconColor='redVivid'
                    variant='secondary'
                    onClick={() => {
                      upsertUserAttendance.mutate({
                        attendeeId: item.user.id,
                        calendarEventId: props.calendarEventId,
                        attended: false,
                      })
                    }}
                  >
                    {t('manage.events.did-not-attend')}
                  </Button>
                </View>
              )
            default:
              assertNever(item.attended)
          }
        })}
      </View>
    </ListElement>
  )
}

export const MarkUserAttendanceModal = withModal<{
  users: Array<MarkUserAttendaceModalUserItemFragment>
  calendarEventId: CalendarEventId
  onChange: () => void
}>({ size: 'fit-content', disableScrollbarGutter: true }, props => {
  const { t } = useTranslation()
  const users = props.users

  const [checkedInUsers, notCheckedInUsers] = useMemo(() => {
    const groupedUsers = groupBy(users, user => user.hasSelfReportedAttendance)
    return [groupedUsers['true'] ?? [], groupedUsers['false'] ?? []]
  }, [users])

  return (
    <Container>
      <CloseIconContainer>
        <IconButton
          tooltip={t('dictionary.close')}
          variant='transparent'
          size='small'
          iconId='close'
          onClick={props.onClose}
        />
      </CloseIconContainer>
      <Heading size={'h5'} bold>
        {t('manage.events.mark-attendance')}
      </Heading>

      <Spacer size='8' />

      <Text bold>{t('manage.events.has-checked-in-list-tite')}</Text>
      <ListContainer>
        {checkedInUsers.map(item => (
          <UserListElement
            key={item.user.id}
            calendarEventId={props.calendarEventId}
            onChange={props.onChange}
            item={item}
          />
        ))}
      </ListContainer>

      <Spacer size='8' />
      <Text bold>{t('manage.events.has-not-checked-in-list-title')}</Text>

      <ListContainer>
        {notCheckedInUsers.map(item => (
          <UserListElement
            key={item.user.id}
            calendarEventId={props.calendarEventId}
            onChange={props.onChange}
            item={item}
          />
        ))}
      </ListContainer>

      <Spacer size='32' />
      <View marginLeft='auto'>
        <Button onClick={props.onClose}>{t('dictionary.done')}</Button>
      </View>
      <Spacer size='32' />
    </Container>
  )
})
