/* eslint-disable react/jsx-no-literals */
import React from 'react'
import { getFileContentImage } from 'sierra-client/api/content'
import { PrintButton, WrapButtons } from 'sierra-client/views/learner/certificate/components'
import { CertificateProps } from 'sierra-client/views/learner/certificate/types'
import { getFormattedCompletionDate, handlePrint } from 'sierra-client/views/learner/certificate/utils'
import { Heading, Spacer, Text } from 'sierra-ui/primitives'
import styled from 'styled-components'

const Wrap = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;

  height: 100%;
  padding: 2cm;
  line-height: 1.5;

  @page {
    size: landscape;
  }
`

const Container = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
`

const Logo = styled.img`
  width: 130px;
`

const StyledWrapButtons = styled(WrapButtons)`
  left: 2rem;
  right: auto;
`

const HeadingText = styled(Heading).attrs({
  size: 'h1',
})`
  color: #007a73;
`

const CompletionText = styled(Heading).attrs({
  size: 'h4',
})`
  font-weight: 400;
`

const NameAndCompletionContainer = styled.div`
  display: flex;
  justify-content: space-around;
  align-self: stretch;
`

const NameText = styled(Text).attrs({
  size: 'large',
  bold: true,
})``

// Ideally this component wouldn't have to know about the course id, but the requested copy included the course title
// in a way that wasn't straight-forward to include in a template.
export const MerckCertificate: React.FC<CertificateProps> = ({ id, user, completionTimestamp, course }) => {
  return (
    <>
      <Wrap id='printwrapper'>
        <Container>
          <HeadingText color='LEGACY_DEFAULT_HEADING_COLOR_REPLACE_ASAP'>
            {id === 'F8C-x9jyVEhM' ? 'Félicitations!' : 'Congratulations!'}
          </HeadingText>

          <Spacer size='medium' />

          {id === 'F8C-x9jyVEhM' ? (
            <>
              <CompletionText color='LEGACY_DEFAULT_HEADING_COLOR_REPLACE_ASAP'>
                Vous avez terminé le programme de soutien aux
              </CompletionText>
              <CompletionText color='LEGACY_DEFAULT_HEADING_COLOR_REPLACE_ASAP'>
                patients MERCK SANTÉ™ – Oncologie
              </CompletionText>
            </>
          ) : (
            <>
              <CompletionText color='LEGACY_DEFAULT_HEADING_COLOR_REPLACE_ASAP'>
                You have completed
              </CompletionText>
              <CompletionText color='LEGACY_DEFAULT_HEADING_COLOR_REPLACE_ASAP'>
                {course.settings.title}
              </CompletionText>
              <CompletionText color='LEGACY_DEFAULT_HEADING_COLOR_REPLACE_ASAP'>
                with 100% accuracy
              </CompletionText>
            </>
          )}

          <Spacer size='80' />

          <NameAndCompletionContainer>
            <NameText color='LEGACY_DEFAULT_TEXT_COLOR_REPLACE_ASAP'>
              {user.firstName} {user.lastName}
            </NameText>
            <NameText color='LEGACY_DEFAULT_TEXT_COLOR_REPLACE_ASAP'>
              {getFormattedCompletionDate(completionTimestamp)}
            </NameText>
          </NameAndCompletionContainer>

          <Spacer size='80' />

          <Logo
            src={getFileContentImage('images/merck-logo.svg', { bucket: 'sierra-static', optimize: false })}
          />
        </Container>
      </Wrap>
      <StyledWrapButtons>
        <PrintButton variant='secondary' onClick={handlePrint}>
          Print or download
        </PrintButton>
      </StyledWrapButtons>
    </>
  )
}
