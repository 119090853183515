import { View } from 'sierra-ui/primitives'
import { breakpoints } from 'sierra-ui/theming/breakpoints'
import styled from 'styled-components'

export const FilteringContainer = styled.div`
  position: relative;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  gap: 0.75rem;
  width: 100%;

  overflow-x: scroll;
  height: 100%;

  @media (min-width: ${breakpoints.tablet}) {
    overflow-x: visible;
  }
`

export const Container = styled(View)`
  overflow: auto;

  @media (min-width: ${breakpoints.tablet}) {
    overflow: visible;
  }
`
