import { Action, Dispatch } from 'redux'
import { DynamicT, TranslationKey } from 'sierra-client/hooks/use-translation/types'
import { NotificationPushType } from 'sierra-client/state/notifications/types'
import { ShowUploadFileModal } from 'sierra-client/views/v3-author/file-attachment/use-editor-upload-file'
import { CreateContentId } from 'sierra-domain/api/nano-id'
import { FileId } from 'sierra-domain/flexible-content/identifiers'
import { IconId } from 'sierra-ui/components'
import { BaseRange, Editor } from 'slate'

type Label = TranslationKey | { type: 'untranslated'; value: string }

export function resolveLabel(label: Label, t: DynamicT): string {
  if (typeof label === 'string') return t(label)
  else return label.value
}

export type CreatePageSlashMenuContext = {
  createContentId: CreateContentId
  nodeId: FileId | undefined
}

export type SlashMenuEntry = {
  label: Label
  icon?: IconId
  isEnabled?: (_: { editor: Editor }) => boolean
  edit: (_: {
    createPageContext: CreatePageSlashMenuContext | undefined
    editor: Editor
    dispatch: Dispatch<Action>
    lastSelection: BaseRange | null
    currentId?: string
    forceInsert?: boolean
    isInQuestionBlock?: boolean
    showUploadFileModal: ShowUploadFileModal
    dynamicT: DynamicT
    notification?: {
      push: (v: NotificationPushType) => void
    }
  }) => void
}
