import { createFileRoute } from '@tanstack/react-router'
import { requireLoggedIn } from 'sierra-client/core/require-logged-in'
import { SelfPacedPage } from 'sierra-client/views/self-paced/self-paced-page'
import { PathId, SelfPacedContentId } from 'sierra-domain/api/nano-id'
import { ProgramId } from 'sierra-domain/api/uuid'
import { FileId } from 'sierra-domain/flexible-content/identifiers'
import { z } from 'zod'

export const Route = createFileRoute('/program/$programId/path/$pathId/s/$flexibleContentId/$fileId')({
  component: requireLoggedIn(SelfPacedPage, { allowScorm: true }) as React.FC,
  params: {
    parse: z.object({
      programId: ProgramId,
      pathId: PathId,
      flexibleContentId: SelfPacedContentId,
      fileId: FileId.or(z.literal('next')),
    }).parse,
    stringify: p => p,
  },
})
