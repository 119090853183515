import React, { useEffect, useState } from 'react'
import { useTranslation } from 'sierra-client/hooks/use-translation'
import {
  CheckList,
  RadioButtonList,
} from 'sierra-client/views/workspace/create-new/filters/mobile/check-list'
import { CreatePageTabs } from 'sierra-client/views/workspace/create-new/filters/shared/create-page-tabs'
import {
  useContenTypeMenuItems,
  usePublishStateMenuItems,
  useSortByMenuItems,
} from 'sierra-client/views/workspace/create-new/filters/shared/filter-functions'
import { CreatePageFilterRenderer } from 'sierra-client/views/workspace/create-new/filters/types'
import { Drawer, Icon, TruncatedText } from 'sierra-ui/components'
import { Accordion } from 'sierra-ui/missions/foundation/filters/accordion'
import { Button, Heading, IconButton, ScrollView, Spacer, Text, View } from 'sierra-ui/primitives'
import styled from 'styled-components'

const Header = styled(View).attrs({
  direction: 'row',
  alignItems: 'flex-start',
  justifyContent: 'space-between',
})``

const CloseButton = styled(IconButton).attrs({
  iconId: 'close',
  color: 'foreground/muted',
  size: 'default',
  variant: 'transparent',
})``

const Title = styled(Heading).attrs({ size: 'h5', bold: true })``

const ShowHeadline: React.FC = () => {
  const { t } = useTranslation()
  return (
    <View direction='row' justifyContent='flex-start' alignItems='center' gap='6'>
      <Icon iconId='view' />
      <Text bold size='regular'>
        {t('dictionary.show')}
      </Text>
    </View>
  )
}

const StyledScrollView = styled(ScrollView)`
  overflow: auto;
  scrollbar-gutter: stable;
`

const FilterPadding = styled(View).attrs({ grow: true })`
  padding: 0 0 12px 12px;
`

const ButtonContainer = styled(View).attrs({ gap: '8' })`
  width: 100%;
`

export const CreatePageMobileFilters: React.FC<CreatePageFilterRenderer> = ({
  selectedTabAtom,
  selectedFilters,
  setSelectedFilters,
  selectedSortFunction,
  setSelectedSortFunction,
}) => {
  const { t } = useTranslation()
  const [filterPanelOpen, setFilterPanelOpen] = useState(false)
  const onClose = (): void => setFilterPanelOpen(false)
  const [filterSection, setFilterSection] = useState<string | undefined>(undefined)

  const { selectedContentType, selectedPublishState } = selectedFilters
  const { setSelectedContentType, setSelectedPublishState } = setSelectedFilters

  const contentTypeItems = useContenTypeMenuItems(selectedContentType, setSelectedContentType)
  const publishStateItems = usePublishStateMenuItems(selectedPublishState, setSelectedPublishState)
  const sortOptionItems = useSortByMenuItems(selectedSortFunction, setSelectedSortFunction)
  const [numberOfFilters, setNumberOfFilters] = useState(0)

  useEffect(() => {
    const numberOfSortFunction = selectedSortFunction === 'edited-desc' ? 0 : 1
    const updatedNumberOfFilters =
      selectedContentType.length + selectedPublishState.length + numberOfSortFunction

    setNumberOfFilters(updatedNumberOfFilters)
  }, [selectedContentType, selectedPublishState, selectedSortFunction])

  const clearText = t('create-page.filters.mobile.clear', { count: numberOfFilters })
  return (
    <>
      <IconButton variant='secondary' iconId='filter' onClick={() => setFilterPanelOpen(!filterPanelOpen)} />
      <Drawer open={filterPanelOpen} onClose={onClose} padding='24'>
        <Header>
          <Title>{t('create-page.filters.mobile.title')}</Title>
          <CloseButton onClick={onClose} />
        </Header>
        <Spacer size='24' />
        <View direction='column' gap='8'>
          <ShowHeadline />
          <CreatePageTabs selectedTabAtom={selectedTabAtom} />
          <Spacer size='16' />
        </View>
        <StyledScrollView disableScrollbarGutter={false}>
          <Accordion
            value={filterSection}
            onValueChange={setFilterSection}
            items={[
              {
                id: 'content-type',
                label: t('create-page.filters.content-type'),
                iconId: 'edit',
                content: (
                  <FilterPadding>
                    <CheckList items={contentTypeItems} />
                  </FilterPadding>
                ),
                trailingVisual: (
                  <TruncatedText bold color='foreground/muted'>
                    {contentTypeItems
                      .filter(i => i.checked)
                      .map(i => i.label)
                      .join(', ')}
                  </TruncatedText>
                ),
              },
              {
                id: 'publish-state',
                label: t('create-page.filters.publish-state'),
                iconId: 'edit',
                content: (
                  <FilterPadding>
                    <CheckList items={publishStateItems} />
                  </FilterPadding>
                ),
                trailingVisual: (
                  <TruncatedText bold color='foreground/muted'>
                    {publishStateItems
                      .filter(i => i.checked)
                      .map(i => i.label)
                      .join(', ')}
                  </TruncatedText>
                ),
              },
              {
                id: 'sort-by',
                label: t('create-page.filters.mobile.sort-by'),
                iconId: 'wiki',
                content: (
                  <FilterPadding>
                    <RadioButtonList items={sortOptionItems} selectedSortFunction={selectedSortFunction} />
                  </FilterPadding>
                ),
                trailingVisual: (
                  <TruncatedText bold color='foreground/muted'>
                    {sortOptionItems
                      .filter(i => i.id === selectedSortFunction)
                      .map(i => i.label)
                      .join(', ')}
                  </TruncatedText>
                ),
              },
            ]}
          />
        </StyledScrollView>
        <View grow direction='column' justifyContent='flex-end'>
          {numberOfFilters > 0 && (
            <ButtonContainer>
              <ButtonContainer>
                <Button
                  variant='secondary'
                  grow
                  onClick={() => {
                    setSelectedContentType([])
                    setSelectedPublishState([])
                    setSelectedSortFunction('edited-desc')
                  }}
                >
                  {clearText}
                </Button>
              </ButtonContainer>
              <ButtonContainer>
                <Button variant='primary' grow onClick={onClose}>
                  {t('create-page.filters.mobile.apply')}
                </Button>
              </ButtonContainer>
            </ButtonContainer>
          )}
        </View>
      </Drawer>
    </>
  )
}
