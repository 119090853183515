import { useEffect } from 'react'
import { useStableFunction } from 'sierra-client/hooks/use-stable-function'

// todo(?): allow functions to return cleanup function?
export function useOnMount(unstableOnMount: () => void): void {
  const stableOnMount = useStableFunction(unstableOnMount)

  useEffect(() => {
    stableOnMount()
  }, [stableOnMount])
}
