import { unwrapToTextNodes } from 'sierra-client/views/v3-author/command'
import { isElementType, parentType } from 'sierra-client/views/v3-author/queries'
import { createMatrixOption } from 'sierra-domain/v3-author/create-blocks'
import { Editor, Node, Range, Text, Transforms } from 'slate'

const debug = (...messages: unknown[]): void => console.debug('[withMatrix]', ...messages)

const isMatrixOption = isElementType('matrix-option')

export const withMatrix = (editor: Editor): Editor => {
  const { normalizeNode, insertBreak, deleteBackward } = editor

  editor.normalizeNode = entry => {
    const [node, path] = entry

    if (isElementType('matrix', node)) {
      const children = Array.from(Node.children(editor, path))
      const matrixOptions = children.filter(([child]) => isMatrixOption(child))

      if (parentType(editor, path) !== 'editor') {
        debug('Matrix must be at the root, unwrapping at', JSON.stringify(path))
        return Transforms.unwrapNodes(editor, { at: path })
      }

      for (const [child, childPath] of children) {
        if (!isElementType('matrix-option', child)) {
          debug(`Removing unexpected element at`, JSON.stringify(childPath))
          return Transforms.removeNodes(editor, { at: childPath, voids: true })
        }
      }

      if (matrixOptions.length < 4) {
        const numberOfMissingOptions = 4 - matrixOptions.length

        const at = path.concat(0)
        debug(`Adding ${numberOfMissingOptions} missing matrix-option at`, JSON.stringify(at))
        Transforms.insertNodes(
          editor,
          Array.from({ length: numberOfMissingOptions }, () => createMatrixOption()),
          {
            at,
            voids: true,
          }
        )
        return
      }

      if (matrixOptions.length > 4) {
        debug(`Removing additional matrix-options at`, JSON.stringify(path))
        Transforms.removeNodes(editor, { at: path.concat(4) })
        return
      }
    }
    normalizeNode(entry)
  }

  editor.insertBreak = () => {
    const { selection } = editor
    if (!(selection && Range.isCollapsed(selection))) return insertBreak()
    const [entry] = Editor.nodes(editor, { match: isElementType('matrix') })
    if (entry) return Transforms.move(editor, { distance: 1, unit: 'line' })
    insertBreak()
  }

  editor.deleteBackward = unit => {
    const { selection } = editor
    if (selection && Range.isCollapsed(selection)) {
      const [entry] = Editor.nodes(editor, {
        match: isElementType('matrix'),
      })
      if (entry !== undefined) {
        const isCursorAtTheStartOfTheNode = selection.anchor.offset === 0 && selection.focus.offset === 0
        if (isCursorAtTheStartOfTheNode) return
      }

      return deleteBackward(unit)
    }
  }

  return editor
}

export const withMatrixOption = (editor: Editor): Editor => {
  const { normalizeNode } = editor

  editor.normalizeNode = entry => {
    const [node, path] = entry

    if (isElementType('matrix-option', node)) {
      if (parentType(editor, path) !== 'matrix') {
        debug(`matrix-option must be the direct child of a matrix, unwrapping at`, JSON.stringify(path))
        return Transforms.unwrapNodes(editor, { at: path, voids: true, mode: 'all' })
      }

      for (const [child, childPath] of Array.from(Node.children(editor, path))) {
        if (!Text.isText(child)) {
          debug('Unwrapping unexpected element in matrix-option at', childPath)
          unwrapToTextNodes(editor, { at: childPath })
        }
      }
    }

    normalizeNode(entry)
  }

  return editor
}
