/* eslint-disable @typescript-eslint/no-unused-vars */

import { AsyncThunk, AsyncThunkAction, createAsyncThunk } from '@reduxjs/toolkit'
import { getBrowserSessionTimestamp } from 'sierra-client/components/browser/update-browser-session'
import { getContext } from 'sierra-client/core/logging/context'
import { PageInstance } from 'sierra-client/core/logging/page/types'
import { UserSingleton } from 'sierra-client/core/user'
import { errorLogger } from 'sierra-client/error/error-logger'
import { selectLoggingEnabled } from 'sierra-client/state/logging/selectors'
import { addLog } from 'sierra-client/state/logging/slice'
import {
  Category,
  Identity,
  Integrations,
  PageInstanceProps,
  PageMessage,
  TrackMessage,
} from 'sierra-client/state/logging/types'
import { RootState } from 'sierra-client/state/types'

export abstract class Logger {
  private static instance: Logger | undefined

  public static initialize(instance: Logger): void {
    if (Logger.instance !== undefined) {
      throw Error('Instance already initialized')
    } else {
      this.instance = instance
    }

    this.instance.initialize()
  }

  initialize(): void {}

  track(
    identity: Identity,
    message: TrackMessage<Record<string, unknown>>,
    loggingEnabled?: boolean
  ): Promise<void> {
    throw new Error('Not implemented')
  }

  page(identity: Identity, message: PageMessage<PageInstanceProps>, loggingEnabled?: boolean): Promise<void> {
    throw new Error('Not implemented')
  }

  private static getInstance(): Logger {
    if (Logger.instance !== undefined) {
      return Logger.instance
    } else {
      throw Error('Instance not initialized')
    }
  }

  private static getIntegrations(): Integrations {
    const sessionId = getBrowserSessionTimestamp() ?? undefined

    if (sessionId === undefined) {
      errorLogger.error('Unable to retrieve session ID')
    }

    return {
      Amplitude: { session_id: sessionId },
    }
  }

  private static buildTrackMessage<T extends Record<string, unknown>>(
    event: string,
    properties: T,
    state: RootState
  ): TrackMessage<T> {
    return {
      event,
      context: getContext(state),
      integrations: this.getIntegrations(),
      properties,
    }
  }

  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  public static trackLogger = <InputArgs extends Record<string, any>>(
    eventId: string,
    userIdRequired: boolean = true
  ): AsyncThunk<{ identity: Identity; message: TrackMessage<InputArgs> }, InputArgs, { state: RootState }> =>
    createAsyncThunk<
      { identity: Identity; message: TrackMessage<InputArgs> },
      InputArgs,
      { state: RootState }
    >(`log/track/${eventId}`, async (props, { getState, dispatch }) => {
      const identity = Logger.getIdentity(userIdRequired)
      const message = Logger.buildTrackMessage(eventId, props, getState())
      await Logger.getInstance().track(identity, message, selectLoggingEnabled(getState()))
      void dispatch(addLog({ type: 'event', message }))
      return { identity, message }
    })

  public static trackLoggerWithExtra = <
    InputArgs extends Record<string, unknown> | undefined,
    OutputArgs extends Record<string, unknown>,
  >(
    eventId: string,
    enrich: (input: InputArgs, state: RootState) => OutputArgs,
    userIdRequired: boolean = true
  ): AsyncThunk<{ identity: Identity; message: TrackMessage<OutputArgs> }, InputArgs, { state: RootState }> =>
    createAsyncThunk<
      { identity: Identity; message: TrackMessage<OutputArgs> },
      InputArgs,
      { state: RootState }
    >(`log/track/${eventId}`, async (props, { getState, dispatch }) => {
      const state = getState()
      const identity = Logger.getIdentity(userIdRequired)
      const enrichedProps = enrich(props, state)
      const message = Logger.buildTrackMessage(eventId, enrichedProps, state)
      await Logger.getInstance().track(identity, message, selectLoggingEnabled(getState()))
      void dispatch(addLog({ type: 'event', message }))
      return { identity, message }
    })

  private static getIdentity(userIdRequired: boolean): Identity {
    const userId = UserSingleton.getInstance().getUser()?.uuid
    const anonymousId = UserSingleton.getInstance().getAnonymousId()

    if (userIdRequired && userId === undefined) {
      const err = new Error('userId is required but not set')
      errorLogger.captureError(err)
      throw err
    } else {
      return { userId, anonymousId }
    }
  }

  private static buildPageMessage<P extends PageInstanceProps>(
    category: Category,
    id: string,
    properties: P,
    state: RootState
  ): PageMessage<P> {
    return {
      category: category,
      name: id,
      context: getContext(state),
      integrations: this.getIntegrations(),
      properties: properties,
    }
  }

  public static pageLogger<Input extends PageInstanceProps, Output extends PageInstanceProps>(
    pageInstance: PageInstance<Input, Output>
  ): AsyncThunkAction<{ identity: Identity; message: PageMessage<Output> }, void, { state: RootState }> {
    return createAsyncThunk<{ identity: Identity; message: PageMessage<Output> }, void, { state: RootState }>(
      `log/page/${pageInstance.category}/${pageInstance.id}`,
      async (_, { getState, dispatch }) => {
        const state = getState()
        const identity = Logger.getIdentity(pageInstance.userIdRequired)
        const message = Logger.buildPageMessage(
          pageInstance.category,
          pageInstance.id,
          pageInstance.enrich(state),
          state
        )
        await Logger.getInstance().page(identity, message, selectLoggingEnabled(getState()))
        void dispatch(addLog({ type: 'page', message }))
        return { identity, message }
      }
    )()
  }
}

export class FanoutLogger extends Logger {
  private instances: Logger[]

  constructor(instances: Logger[]) {
    super()
    this.instances = instances
  }

  override initialize(): void {
    this.instances.forEach(instance => instance.initialize())
  }

  override track(
    identity: Identity,
    message: TrackMessage<Record<string, unknown>>,
    loggingEnabled?: boolean
  ): Promise<void> {
    return Promise.all(
      this.instances.map(instance => instance.track(identity, message, loggingEnabled))
    ).then()
  }

  override page(
    identity: Identity,
    message: PageMessage<PageInstanceProps>,
    loggingEnabled?: boolean
  ): Promise<void> {
    return Promise.all(
      this.instances.map(instance => instance.page(identity, message, loggingEnabled))
    ).then()
  }
}
