import { default as React } from 'react'
import { useNotif } from 'sierra-client/components/common/notifications'
import { openYDocDebug, openYDocMetaHistory, useYDocMetadata } from 'sierra-client/editor/debug-ydoc-shortcut'
import { printTree } from 'sierra-client/editor/utils/print-tree'
import { useLatestSelection } from 'sierra-client/views/v3-author/hooks'
import { EditorMode } from 'sierra-client/views/v3-author/slate'
import { color } from 'sierra-ui/color'
import { Tooltip } from 'sierra-ui/components'
import { Text } from 'sierra-ui/primitives'
import { zIndex } from 'sierra-ui/theming'
import { BasePoint, BaseRange, BaseSelection, Range } from 'slate'
import { useSlateStatic } from 'slate-react'
import styled from 'styled-components'

const DebugContainer = styled.div<{ $top: string }>`
  position: absolute;
  display: flex;
  top: ${p => p.$top};
  gap: 0.5rem;
  display: flex;
  width: 100%;
  justify-content: center;
  z-index: ${Math.max(...Object.values(zIndex)) + 2};
`
const DebugDataInner = styled.div`
  cursor: pointer;
  padding: 0.5rem;
  border-radius: ${p => p.theme.borderRadius['size-6']};
  background-color: ${p => color(p.theme.home.backgroundColor).shift(0.05).toString()};
  &:hover {
    background-color: ${p => color(p.theme.home.backgroundColor).shift(0.1).toString()};
  }
`

function stringifyPoint(point: BasePoint): string {
  return `${JSON.stringify(point.path)}, ${point.offset}`
}

export function stringifySelection(selection: BaseSelection): string {
  if (selection === null) {
    return 'null'
  }

  if (Range.isCollapsed(selection)) {
    return stringifyPoint(selection.focus)
  }

  const [start, end] = Range.edges(selection)
  const arrow = Range.isBackward(selection) ? '<-' : '->'

  return [stringifyPoint(start), arrow, stringifyPoint(end)].join(' ')
}

const Selection: React.FC<{ selection: BaseRange }> = ({ selection }) => {
  return <Text size='technical'>{stringifySelection(selection)}</Text>
}

export const DebugData: React.FC<{ mode: EditorMode }> = ({ mode }): JSX.Element | null => {
  const editor = useSlateStatic()
  const latestSelection = useLatestSelection()
  const notif = useNotif()

  const selectionText = JSON.stringify(latestSelection)

  const yDocMeta = useYDocMetadata()

  const top = mode === 'create' ? '0.5rem' : '2rem'

  return (
    <DebugContainer $top={top}>
      {latestSelection !== null && (
        <DebugDataInner
          onClick={() => {
            void window.navigator.clipboard.writeText(selectionText)
            notif.push({ type: 'custom', level: 'info', body: 'Copied and logged selection' })
          }}
        >
          <Tooltip title='Copy and log selection'>
            <div>
              <Selection selection={latestSelection} />
            </div>
          </Tooltip>
        </DebugDataInner>
      )}
      <DebugDataInner
        onClick={() => {
          void window.navigator.clipboard.writeText(JSON.stringify(editor.children))
          printTree(editor)
          // eslint-disable-next-line no-console
          console.log(editor.children)
          notif.push({
            type: 'custom',
            level: 'info',
            body: 'Copied and logged editor state',
          })
        }}
      >
        <Tooltip title='Copy and log editor state'>
          <Text color='LEGACY_DEFAULT_TEXT_COLOR_REPLACE_ASAP' size='technical'>
            Editor
          </Text>
        </Tooltip>
      </DebugDataInner>

      <DebugDataInner
        onClick={() => {
          notif.push({ type: 'custom', level: 'info', body: 'Logged latest actions to console' })
          // eslint-disable-next-line no-console
          console.log(editor.readRecentActionsLog())
        }}
      >
        <Tooltip title='Log recent actions'>
          <Text color='LEGACY_DEFAULT_TEXT_COLOR_REPLACE_ASAP' size='technical'>
            Actions log
          </Text>
        </Tooltip>
      </DebugDataInner>

      {yDocMeta && (
        <>
          <DebugDataInner onClick={() => openYDocDebug(yDocMeta)}>
            <Tooltip title='View the yDoc state'>
              <Text color='LEGACY_DEFAULT_TEXT_COLOR_REPLACE_ASAP' size='technical'>
                yDoc
              </Text>
            </Tooltip>
          </DebugDataInner>

          <DebugDataInner onClick={() => openYDocMetaHistory(yDocMeta)}>
            <Tooltip title='View the yDoc history'>
              <Text color='LEGACY_DEFAULT_TEXT_COLOR_REPLACE_ASAP' size='technical'>
                History
              </Text>
            </Tooltip>
          </DebugDataInner>
        </>
      )}
    </DebugContainer>
  )
}
