import { useSetAtom } from 'jotai'
import { useResetBooleanAfterDelay } from 'sierra-client/hooks/use-reset-boolean-after-delay'
import { useTranslation } from 'sierra-client/hooks/use-translation'
import { manageSessionsPanelStateAtom } from 'sierra-client/views/flexible-content/editor/topbar/manage-sessions-panel-state'
import { Button } from 'sierra-ui/primitives'

export const SessionListButton: React.FC = () => {
  const { t } = useTranslation()
  const setPanelState = useSetAtom(manageSessionsPanelStateAtom)

  const { isEnabled: recentlyClicked, setTrue: setRecentlyClicked } = useResetBooleanAfterDelay()

  const handleClick = (): void => {
    setPanelState({ type: 'list-sessions' })
    setRecentlyClicked()
  }

  return (
    <>
      <Button
        variant='transparent'
        icon={recentlyClicked ? 'checkmark' : 'calendar'}
        decoratorPosition='left'
        onClick={handleClick}
      >
        {t('share.view-sessions')}
      </Button>
    </>
  )
}
