import React from 'react'
import { ActionModal } from 'sierra-client/components/common/modals/action-modal'
import { useTranslation } from 'sierra-client/hooks/use-translation'

export const DiscardAlert: React.FC<{
  open: boolean
  onClose: () => void
  onConfirm: () => void
}> = ({ open, onClose, onConfirm }) => {
  const { t } = useTranslation()
  return (
    <ActionModal
      title={t('alert.are-you-sure')}
      open={open}
      onClose={onClose}
      primaryAction={onConfirm}
      primaryActionLabel={t('alert.discard-changes')}
      deleteAction={true}
    >
      {t('alert.changes-lost')}
    </ActionModal>
  )
}
