import { RouterLink } from 'sierra-client/components/common/link'
import { NotificationContainer, Title } from 'sierra-client/features/activity-center/notifications/components'
import { LiveSessionImage } from 'sierra-client/features/activity-center/notifications/components/entity-image'
import { NotificationIcon } from 'sierra-client/features/activity-center/notifications/components/notification-icon'
import { NotificationTitle } from 'sierra-client/features/activity-center/notifications/components/notification-title'
import { HomeNotification } from 'sierra-domain/api/learn'
import { TruncatedText } from 'sierra-ui/components'
import { View } from 'sierra-ui/primitives'

export const RecapReadyNotification: React.FC<{
  notification: Extract<HomeNotification, { type: 'recap-ready' }>
}> = ({ notification }) => {
  const { liveSession } = notification

  return (
    <NotificationContainer
      notification={notification}
      icon={<NotificationIcon iconId='video' />}
      title={<NotificationTitle titleKey='notification.new-session-summary-available' />}
      content={
        <>
          <View alignItems='center' grow>
            <View grow>
              <RouterLink href={`/l/${liveSession.id}`}>
                <LiveSessionImage liveSession={liveSession} />
              </RouterLink>
              <RouterLink href={`/l/${liveSession.id}`}>
                <Title size='regular' bold>
                  {liveSession.title}
                </Title>
                {notification.summary !== undefined && (
                  <TruncatedText lines={2} size='small' color='foreground/secondary'>
                    {notification.summary}
                  </TruncatedText>
                )}
              </RouterLink>
            </View>
          </View>
        </>
      }
    />
  )
}
