import { useCallback } from 'react'
import { useTranslation } from 'sierra-client/hooks/use-translation'
import { useDispatch, useSelector } from 'sierra-client/state/hooks'
import { dismissIssue as dismissIssueAction } from 'sierra-client/state/live/actions'
import { LiveIssueCategory, LiveIssueInformation } from 'sierra-client/state/live/live-issue-information'
import { selectIssuesInCategory } from 'sierra-client/state/live/selectors'
import { LiveTabID } from 'sierra-client/views/liveV2/settings/live-tab-id'
import { Icon } from 'sierra-ui/components'
import { Button, Text, View } from 'sierra-ui/primitives'
import { legacyLight } from 'sierra-ui/theming/legacy-theme'
import { ThemeProvider } from 'styled-components'

const IssueMessage: React.FC<{ issue: LiveIssueInformation }> = ({ issue }) => {
  const dispatch = useDispatch()
  const { t } = useTranslation()

  const dismissIssue = useCallback((): void => {
    if (issue.dismissable) {
      void dispatch(dismissIssueAction(issue.name))
    }
  }, [dispatch, issue])

  return (
    <ThemeProvider theme={legacyLight}>
      <View direction='column' background='orangeLight' radius='regular' padding='xsmall'>
        <View>
          <Icon iconId='warning' color='black' />
          <Text size='regular' bold>
            {t(issue.title)}
          </Text>
        </View>
        <Text size='small'>{t(issue.longDescription)}</Text>
        {issue.helpLink !== undefined && (
          <View paddingTop='4'>
            <Text size='small'>
              <a href={issue.helpLink} target='_blank' rel='noreferrer'>
                {t('live-page.live-issue-information__help-link')}
              </a>
            </Text>
          </View>
        )}
        {issue.dismissable && (
          <View paddingTop='xsmall'>
            <Button variant='secondary' onClick={dismissIssue}>
              {t('dictionary.dismiss')}
            </Button>
          </View>
        )}
      </View>
    </ThemeProvider>
  )
}

export const IssueMessages: React.FC<{ category: LiveIssueCategory }> = ({ category }): JSX.Element => {
  const issues = useSelector(selectIssuesInCategory)(category)

  return (
    <>
      {issues.map(issue => (
        <IssueMessage key={issue.name} issue={issue} />
      ))}
    </>
  )
}

export const IssueToTabMap: Record<LiveIssueCategory, LiveTabID> = {
  audio: 'audio',
  network: 'network',
  video: 'video',
}
