import _ from 'lodash'
import { useEffect, useMemo } from 'react'
import { useShortcutMenuState } from 'sierra-client/components/shortcut-menu/context'
import { Result, createSearch } from 'sierra-client/components/shortcut-menu/root-search'
import { Group } from 'sierra-client/components/shortcut-menu/types'
import { useDebouncedState } from 'sierra-client/hooks/use-debounced-state'
import { useTranslation } from 'sierra-client/hooks/use-translation'
import { IconId } from 'sierra-ui/components'

const guessGroup: Record<Result['type'], Group> = {
  action: 'commands',
  component: 'commands',
  toggle: 'commands',
  link: 'navigation',
}

function assignGroup(result: Result): Result & { group: Group } {
  const predefinedGroup = 'group' in result ? result['group'] : undefined
  const group = predefinedGroup ?? guessGroup[result.type]

  return { ...result, group }
}

function isDisabled(result: Result): boolean {
  return result.disabled === true
}

function idFor(result: Result): string {
  return result.label
}

type GroupedResult = {
  type: 'group-member' | 'group-header'
  id: string
  group: Group
  result: Result
  iconId: IconId
  disabled: boolean
}
function grouped(results: Result[]): GroupedResult[] {
  return (
    _.chain(results)
      // Group and ungrouped shortcuts with a heuristic
      .map(assignGroup)
      .groupBy('group')
      .entries()
      .flatMap(([group, results]) =>
        results.map(
          (result, index): GroupedResult => ({
            result,
            id: idFor(result),
            group: group as Group,
            iconId: result.iconId ?? 'arrow--right',
            disabled: isDisabled(result),
            type: index === 0 ? 'group-header' : 'group-member',
          })
        )
      )
      .value()
  )
}

export function useRootSearch(liveQuery: string): GroupedResult[] {
  const { shortcuts } = useShortcutMenuState()
  const { t } = useTranslation()

  const [query, setQuery] = useDebouncedState(liveQuery, { maxWait: 32, wait: 16 })
  useEffect(() => setQuery(liveQuery), [liveQuery, setQuery])

  return useMemo(() => {
    const { search } = createSearch({ t, shortcuts })
    const allResults = search(query)
    return grouped(allResults)
  }, [query, shortcuts, t])
}
