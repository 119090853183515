import { Icon } from 'sierra-ui/components'
import { palette } from 'sierra-ui/theming'
import styled, { css } from 'styled-components'

export const SingleThemeIcon = styled(Icon).attrs({
  size: 'size-16',
  color: 'currentColor',
  hoverColor: 'currentColor',
  iconId: 'text--color',
})`
  width: 24px;
  height: 24px;

  color: ${p => p.theme.home.textColor};
  background-color: ${p => p.theme.home.backgroundColor};
  border: 1px solid transparent;
  border-radius: ${p => p.theme.borderRadius['size-6']};

  ${p =>
    p.theme.home.backgroundColor === palette.primitives.white &&
    css`
      border: 1px solid ${palette.grey[5]};
    `}
`
