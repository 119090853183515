import { useQuery, UseQueryOptions, UseQueryResult } from '@tanstack/react-query'
import { getInsightsQueryOptions } from 'sierra-client/features/insights/api-hooks/query-options'
import { apiQueryOptions, typedInvalidateAllQueries } from 'sierra-client/state/api'
import { InsightsDashboardListResponse } from 'sierra-domain/api/insights'
import { XAnalyticsDashboardsList } from 'sierra-domain/routes'

export const listDashboardQuery = {
  ...apiQueryOptions(XAnalyticsDashboardsList, {}),
  ...getInsightsQueryOptions<InsightsDashboardListResponse>(),
  staleTime: 10 * 1000, // 10 seconds
} as const satisfies UseQueryOptions<InsightsDashboardListResponse>

export const useListDashboards = (): UseQueryResult<InsightsDashboardListResponse> => {
  const dashboardData = useQuery(listDashboardQuery)
  return dashboardData
}

export const invalidateListDashboards = async (): Promise<void> => {
  await typedInvalidateAllQueries(XAnalyticsDashboardsList)
}
