import { useEffect, useMemo } from 'react'
import { selectCardIsCompleted } from 'sierra-client/state/card-progress/selectors'
import { useSelector } from 'sierra-client/state/hooks'
import { useSelfPacedFiles } from 'sierra-client/views/self-paced/files-provider'

export const KeyboardNavigation: React.FC<{ onFailedNavigation: () => void }> = ({ onFailedNavigation }) => {
  const {
    goTo,
    currentReview,
    goToNextUp,
    linearNextUp,
    currentPlacementTest,
    currentFile,
    fileIds,
    flexibleContentId,
  } = useSelfPacedFiles()

  const { prevFileId } = useMemo(() => {
    const currentIndex = fileIds.findIndex(fileId => fileId === currentFile?.id)

    return {
      prevFileId: fileIds[currentIndex - 1],
    }
  }, [fileIds, currentFile])

  const cardIsCompleted = useSelector(state =>
    currentFile !== undefined ? selectCardIsCompleted(state, flexibleContentId, currentFile.id) : false
  )

  const reviewOrPtIsOngoing = currentPlacementTest !== undefined || currentReview !== undefined

  useEffect(() => {
    if (fileIds.length === 0) return

    const handleKey = (evt: KeyboardEvent): void => {
      // Don't act if an input field is the element
      const target = evt.target as HTMLElement | undefined
      if (target && 'tagName' in target) {
        const tag = target.tagName.toLowerCase()
        if (tag === 'input' || tag === 'textarea' || tag === 'select') {
          return
        }
      }

      if (reviewOrPtIsOngoing) {
        return
      }

      switch (evt.key) {
        case 'ArrowRight':
          if (cardIsCompleted && linearNextUp.type !== 'course-completed' && linearNextUp.type !== 'none') {
            goToNextUp(linearNextUp)
            evt.stopPropagation()
          } else if (!cardIsCompleted || linearNextUp.type === 'course-completed') {
            onFailedNavigation()
          }
          break
        case 'ArrowLeft':
          if (prevFileId !== undefined) goTo(prevFileId)
          evt.stopPropagation()
          break
      }
    }

    window.addEventListener('keydown', handleKey)

    return () => {
      window.removeEventListener('keydown', handleKey)
    }
  }, [
    cardIsCompleted,
    fileIds,
    goTo,
    goToNextUp,
    linearNextUp,
    onFailedNavigation,
    prevFileId,
    reviewOrPtIsOngoing,
  ])

  return null
}
