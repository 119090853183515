import { groupBy } from 'lodash'
import React from 'react'
import { useTranslation } from 'sierra-client/hooks/use-translation'
import { isCollaboratorRoleAboveOrEqual } from 'sierra-client/views/workspace/utils/collaborator-role-utils'
import { CourseKind } from 'sierra-domain/api/common'
import { UserRole } from 'sierra-domain/api/editable-content'
import {
  TeamspaceContent,
  TeamspaceEditableContent,
  TeamspaceNonEditorCourse,
} from 'sierra-domain/api/teamspace'
import { assertNever, isDefined } from 'sierra-domain/utils'

export const TeamspaceContentTypeName: React.FC<{
  type: CourseKind | 'path'
  isTemplate: boolean
}> = ({ type, isTemplate }) => {
  const { t } = useTranslation()

  switch (type) {
    case 'native:live': {
      if (isTemplate) return t('create.home.live-template')
      return t('dictionary.live')
    }
    case 'native:self-paced': {
      if (isTemplate) return t('create.home.course-template')
      return t('dictionary.course-singular')
    }
    case 'native:event-group':
      return t('dictionary.in-person')
    case 'path':
      return t('dictionary.path-singular')
    case 'scorm':
    case 'scorm:course-group':
      return t('manage.courses.import.scorm')
    case 'link':
      return t('author.slate.link.link')
    case 'linkedin':
      return 'LinkedIn'
    case 'native:course-group':
      return t('dictionary.course-singular')
    default:
      assertNever(type)
  }
}

export function getRoleIsAtLeastEditorAndCommenter({
  highestCollaboratorRole,
  hasManageAccess,
  isEditableContent,
}: {
  highestCollaboratorRole: UserRole | undefined
  hasManageAccess: boolean
  isEditableContent: boolean
}): { roleIsAtLeastEditor: boolean; roleIsAtLeastCommenter: boolean } {
  const canComment =
    isDefined(highestCollaboratorRole) && isCollaboratorRoleAboveOrEqual(highestCollaboratorRole, 'commenter')
  const canEdit =
    isDefined(highestCollaboratorRole) && isCollaboratorRoleAboveOrEqual(highestCollaboratorRole, 'editor')

  const canEditNonEditorCourse = canEdit && hasManageAccess
  const roleIsAtLeastCommenterNonEditorCourse = canComment && hasManageAccess

  const roleIsAtLeastEditor = isEditableContent ? canEdit : canEditNonEditorCourse
  const roleIsAtLeastCommenter = isEditableContent ? canComment : roleIsAtLeastCommenterNonEditorCourse

  return { roleIsAtLeastEditor, roleIsAtLeastCommenter }
}

// Allows you to grab the content that is can be grouped under a course group
export function extractCourseGroupContent(cs: Array<TeamspaceContent>): {
  topLevelContent: Array<TeamspaceContent>
  courseGroupContent: Record<string, Array<TeamspaceEditableContent | TeamspaceNonEditorCourse>>
} {
  // Check if the id exists as any of the content
  const idExists = (id?: string): boolean =>
    cs.some(c => (c.type === 'folder' ? c.id === id : c.content.id === id))

  const { nonGrouped = [], ...groupableContent } = groupBy(cs, it => {
    if (it.type !== 'folder' && idExists(it.content.courseGroupId)) {
      return it.content.courseGroupId
    } else {
      return 'nonGrouped'
    }
  })

  return {
    topLevelContent: nonGrouped,
    // We do not group the folder types above. Casting here could be considered "safe"
    courseGroupContent: groupableContent as Record<
      string,
      Array<TeamspaceEditableContent | TeamspaceNonEditorCourse>
    >,
  }
}
