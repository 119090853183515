import { atom, createStore, useAtomValue, useSetAtom } from 'jotai'
import { PermissionOverrides } from 'sierra-client/components/shortcut-menu/permissions-inspector/types'
import { atomWithStorage } from 'sierra-client/state/storage'

const permissionsInspectorStore = createStore()

const isPermissionsInspectorToggledAtom = atomWithStorage('isPermissionsInspectorToggled', false)
// "local permission overrides"
const localPermissionsConfigAtom = atom<PermissionOverrides | undefined>(undefined)

// eslint-disable-next-line @typescript-eslint/explicit-function-return-type
export const useIsPermissionsInspectorToggled = () =>
  useAtomValue(isPermissionsInspectorToggledAtom, { store: permissionsInspectorStore })

// eslint-disable-next-line @typescript-eslint/explicit-function-return-type
export const useSetIsPermissionsInspectorToggled = () =>
  useSetAtom(isPermissionsInspectorToggledAtom, { store: permissionsInspectorStore })

// eslint-disable-next-line @typescript-eslint/explicit-function-return-type
export const useLocalPermissionsConfig = () =>
  useAtomValue(localPermissionsConfigAtom, { store: permissionsInspectorStore })

// eslint-disable-next-line @typescript-eslint/explicit-function-return-type
export const useSetLocalPermissionsConfig = () =>
  useSetAtom(localPermissionsConfigAtom, { store: permissionsInspectorStore })
