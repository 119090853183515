import { useAtom } from 'jotai/index'
import { useCallback } from 'react'
import { graphql } from 'sierra-client/api/graphql/gql'
import { FetchEmailHtmlQuery } from 'sierra-client/api/graphql/gql/graphql'
import { convertGQLAvatar } from 'sierra-client/api/graphql/util/convert-gql-avatar'
import { graphQuery } from 'sierra-client/api/hooks/use-graphql-query'
import {
  matchingMembersAtom,
  queryAtom,
  selectedMembersAtom,
} from 'sierra-client/views/manage/programs/send-reminder/atoms'
import { Member } from 'sierra-client/views/manage/programs/send-reminder/types'
import { parseDuration } from 'sierra-client/views/manage/programs/send-reminder/utils'
import { fetchEmailHtml } from 'sierra-client/views/manage/programs/staggered-assignments/queries/email-template-queries'
import { UserId } from 'sierra-domain/api/uuid'

type ProgramMembersData = {
  query: string
  setQuery: (query: string) => void
  matching: Member[]
  fetchMatching: () => Promise<void>
  fetchSelected: (
    requestedUserIds: UserId[]
  ) => Promise<{ alreadyCompleted: number; recentlyContacted: number }>
  selectedMembers: Member[]
  setSelectedMembers: (selectedMembers: Member[]) => void
  fetchRenderedEmail: (programId: string) => Promise<FetchEmailHtmlQuery>
}

export const useProgramReminderRecipients = (programId: string): ProgramMembersData => {
  const [matching, setMatching] = useAtom(matchingMembersAtom)
  const [selectedMembers, setSelectedMembers] = useAtom(selectedMembersAtom)
  const [query, setQuery] = useAtom(queryAtom)

  const fetchMatching = useCallback(async (): Promise<void> => {
    const result = await graphQuery(
      graphql(`
        query fetchMatching($id: ProgramId!, $query: String) {
          programEnrollments(id: $id, query: $query) {
            data {
              user {
                id
                firstName
                lastName
                email
                status
                avatar {
                  ...AvatarFragment
                }
              }
              progress {
                progress
              }
              notificationTimeout
            }
          }
        }
      `),
      { id: programId, query: query }
    )

    const members = result.programEnrollments.data.map(user => {
      return {
        email: user.user.email,
        status: user.user.status,
        id: user.user.id,
        firstName: user.user.firstName,
        lastName: user.user.lastName,
        avatar: convertGQLAvatar(user.user.avatar),
        progress: user.progress.progress,
        hoursSinceContact:
          user.notificationTimeout !== null && user.notificationTimeout !== undefined
            ? parseDuration(user.notificationTimeout).hours
            : undefined,
      }
    })

    //filter out already selected members
    const filtered = members.filter(
      member => !selectedMembers.some(m => m.id === member.id) && member.status === 'ACTIVE'
    )

    const matching = filtered.filter(m =>
      `${m.firstName} ${m.lastName}`.toLowerCase().includes(query.toLowerCase())
    )

    setMatching(matching)
  }, [programId, query, selectedMembers, setMatching])

  const fetchSelected = useCallback(
    async (requestedUserIds: UserId[]): Promise<{ alreadyCompleted: number; recentlyContacted: number }> => {
      const result = await graphQuery(
        graphql(`
          query fetchEnrollments($userIds: [UserId!]!, $programId: ProgramId!) {
            usersFromIds(ids: $userIds) {
              id
              firstName
              lastName
              email
              status
              avatar {
                ...AvatarFragment
              }
              enrollment(programId: $programId) {
                notificationTimeout
                progress {
                  progress
                }
              }
            }
          }
        `),
        { userIds: requestedUserIds, programId: programId }
      )

      const selected = result.usersFromIds.map(user => {
        return {
          email: user.email,
          status: user.status,
          id: user.id,
          firstName: user.firstName,
          lastName: user.lastName,
          avatar: convertGQLAvatar(user.avatar),
          progress: user.enrollment?.progress.progress,
          hoursSinceContact:
            user.enrollment?.notificationTimeout !== null &&
            user.enrollment?.notificationTimeout !== undefined
              ? parseDuration(user.enrollment.notificationTimeout).hours
              : undefined,
        }
      })

      //only include active users
      const onlyActiveUsers = selected.filter(m => m.status === 'ACTIVE')

      //filter out learners who already completed the program
      const withoutCompleted = onlyActiveUsers.filter(m => !(m.progress === 1))

      //filter out learners who were contacted recently
      const withoutRecentlyContacted = withoutCompleted.filter(
        m => !(m.hoursSinceContact !== undefined && m.hoursSinceContact < 24)
      )

      setSelectedMembers(withoutRecentlyContacted)

      return {
        alreadyCompleted: onlyActiveUsers.length - withoutCompleted.length,
        recentlyContacted: withoutCompleted.length - withoutRecentlyContacted.length,
      }
    },
    [programId, setSelectedMembers]
  )

  const fetchRenderedEmail = useCallback<(programId: string) => Promise<FetchEmailHtmlQuery>>(
    async (programId): Promise<FetchEmailHtmlQuery> => {
      const html = await fetchEmailHtml({
        type: 'AD_HOC_NOTIFICATION',
        forAdHocNotification: { programId: programId },
      })
      return html
    },
    []
  )

  return {
    query,
    setQuery,
    matching,
    fetchMatching,
    fetchSelected,
    selectedMembers,
    setSelectedMembers,
    fetchRenderedEmail,
  }
}
