import { createFileRoute } from '@tanstack/react-router'
import { listTeamspacesQuery } from 'sierra-client/api/hooks/use-teamspace'
import { queryClient } from 'sierra-client/api/query-client'

import { CustomInterfaceThemeProvider } from 'sierra-client/components/common/custom-interface-theme-provider'
import { requireLoggedIn } from 'sierra-client/core/require-logged-in'
import { PageIdentifier, SanaPage } from 'sierra-client/layout/sana-page'
import { CreateNewPage } from 'sierra-client/views/workspace/create-new/create-new-page'
import { createPageQuery } from 'sierra-client/views/workspace/utils/create-page-utils'

const Page = (): JSX.Element => (
  <SanaPage mode='light' page={PageIdentifier.NewCreatePage()}>
    <CustomInterfaceThemeProvider>
      <CreateNewPage />
    </CustomInterfaceThemeProvider>
  </SanaPage>
)

const RouteComponent = requireLoggedIn(Page)

export const Route = createFileRoute('/create/')({
  component: RouteComponent as React.FC,
  loader: ({ context }) => {
    if (context.userId === undefined) return

    void queryClient.prefetchQuery(createPageQuery)
    void queryClient.prefetchQuery(listTeamspacesQuery)
  },
})
