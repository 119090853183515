import { default as React, useMemo } from 'react'
import { ContentTableRootView } from 'sierra-client/components/common/content-table-row'
import { RouterLink } from 'sierra-client/components/common/link'
import { useResolveAsset } from 'sierra-client/hooks/use-resolve-asset'
import { useTranslation } from 'sierra-client/hooks/use-translation'
import { useIsMobile } from 'sierra-client/state/browser/selectors'
import { ActionsBlock, DetailsBlock } from 'sierra-client/views/learner/self-enroll-live-session/components'
import { LinkLabel } from 'sierra-client/views/workspace/learn/link-label'
import { CourseId } from 'sierra-domain/api/nano-id'
import { ImageUnion } from 'sierra-domain/content/v2/content'
import { Icon, TruncatedTextWithTooltip } from 'sierra-ui/components'
import { Text, View } from 'sierra-ui/primitives'
import { palette, token } from 'sierra-ui/theming'
import { v2_breakpoint } from 'sierra-ui/theming/breakpoints'
import { FCC } from 'sierra-ui/types'
import styled from 'styled-components'

/**
 * This component is used to show a few of the upcoming sessions inline in a list.
 * For instance, it is used in the course outline on the path page and in the course list
 * on the /learn page.
 */

const SelfEnrollSessionListContainer = styled.div`
  display: flex;
  flex-direction: column;
  border: 1px solid ${token('border/default')};
  border-radius: 0.5rem;
  overflow: hidden;
  margin: 8px 0;

  /* We want this specific color if rendered in learn page content table and hovered. */

  ${ContentTableRootView}:hover & {
    border-color: ${token('border/default')} !important;
  }
`

const SelfEnrollSessionListHeader = styled.div`
  display: flex;
  justify-content: space-between;
  padding: 1rem 1.5rem;
  background-color: ${token('surface/soft')};

  /* We want this specific color if rendered in learn page content table and hovered. */

  ${ContentTableRootView}:hover & {
    background-color: ${palette.grey[5]} !important;
  }
`
const SelfEnrollSessionListContent = styled.div`
  display: flex;
  flex-direction: column;
  padding: 1.5rem;
  background-color: ${token('surface/default')};
  padding: 24px;

  @media screen and (max-width: ${v2_breakpoint.phone}) {
    padding: 16px;
  }
`

const SelfEnrollSessionContainer = styled.div`
  display: flex;
  justify-content: space-between;
  gap: 1rem;
`

const SelfEnrollImageThumbnail = styled.div<{ $bg: string }>`
  /* border: 1px solid ${palette.grey[5]}; */
  border-radius: 12px;
  width: 3.5rem;
  height: 3.5rem;
  flex-shrink: 0;

  background-image: url('${p => p.$bg}');
  background-size: cover;
`

// (damjan): dedupe
const Separator = styled.span`
  display: block;
  width: 100%;
  height: 0;
  border-bottom: 1px solid ${token('border/default')};
`

export type SelfEnrollSessionListData<Id extends string> = {
  sessionId: Id
  title: string
  location: string
  time: string
  freeSpots: number | undefined
}

const DetailsSomething = styled(View)`
  gap: 2px;
  row-gap: 0px;
  flex-flow: wrap;
`

const HideOnMobile: FCC = ({ children }) => {
  const isMobile = useIsMobile()
  return isMobile ? null : children
}

const SelfEnrollSessionListItem = <Id extends string>({
  session,
  courseId,
  courseImage,
  renderButton,
}: {
  courseId: string
  courseImage?: ImageUnion
  session: SelfEnrollSessionListData<Id>
  renderButton: (session: SelfEnrollSessionListData<Id>) => JSX.Element
}): JSX.Element => {
  const { t } = useTranslation()
  const { title, freeSpots, time, location } = session
  const sessionFull = freeSpots !== undefined && freeSpots === 0

  const availabilityString = sessionFull
    ? t('live.session-full')
    : freeSpots !== undefined
      ? t('course-detail.free-spots', { count: freeSpots })
      : undefined

  const assetContext = useMemo(
    () => ({ type: 'course' as const, courseId: CourseId.parse(courseId) }),
    [courseId]
  )
  const imageSrc = useResolveAsset({
    image: courseImage,
    size: 'course-sm',
    assetContext,
  })

  return (
    <SelfEnrollSessionContainer>
      <HideOnMobile>
        <SelfEnrollImageThumbnail $bg={imageSrc} />
      </HideOnMobile>

      <DetailsBlock>
        <TruncatedTextWithTooltip size='small' bold color='foreground/secondary'>
          {time}
        </TruncatedTextWithTooltip>

        <Text size='micro'>{title}</Text>

        <DetailsSomething>
          <TruncatedTextWithTooltip size='micro' color='foreground/secondary'>
            {location}
          </TruncatedTextWithTooltip>

          {availabilityString !== undefined ? (
            <>
              <Icon iconId='radio-button--dot' size='size-12' color='foreground/muted' />
              <Text size='micro' color='foreground/secondary' title={availabilityString}>
                {availabilityString}
              </Text>
            </>
          ) : null}
        </DetailsSomething>
      </DetailsBlock>

      <ActionsBlock>{renderButton(session)}</ActionsBlock>
    </SelfEnrollSessionContainer>
  )
}

export const SelfEnrollSessionList = <Id extends string>({
  courseId,
  courseImage,
  sessions,
  browseAllLink,
  renderButton,
}: {
  courseId: string
  courseImage: ImageUnion | undefined
  browseAllLink: string
  sessions: SelfEnrollSessionListData<Id>[]
  renderButton: (session: SelfEnrollSessionListData<Id>) => JSX.Element
}): JSX.Element => {
  const { t } = useTranslation()

  const maxThreeSessions = sessions.slice(0, 3)

  return (
    <SelfEnrollSessionListContainer>
      <SelfEnrollSessionListHeader>
        <Text size='small' color='foreground/secondary' bold>
          {t('dictionary.recommended-sessions')}
        </Text>
        <RouterLink href={browseAllLink}>
          <LinkLabel>{t('dictionary.browse-all')}</LinkLabel>
        </RouterLink>
      </SelfEnrollSessionListHeader>
      <SelfEnrollSessionListContent>
        <View gap='8' direction='column'>
          {maxThreeSessions.map((session, i) => {
            return (
              <React.Fragment key={session.sessionId}>
                <SelfEnrollSessionListItem
                  courseId={courseId}
                  courseImage={courseImage}
                  session={session}
                  renderButton={renderButton}
                />
                {i !== maxThreeSessions.length - 1 && <Separator />}
              </React.Fragment>
            )
          })}
        </View>
      </SelfEnrollSessionListContent>
    </SelfEnrollSessionListContainer>
  )
}
