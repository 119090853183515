import { UseMutateFunction, useMutation } from '@tanstack/react-query'
import { AnimatePresence, LayoutGroup, motion } from 'framer-motion'
import { FC, useCallback, useRef, useState } from 'react'
import { queryClient } from 'sierra-client/api/query-client'
import { ActionModal } from 'sierra-client/components/common/modals/action-modal'
import { useTranslation } from 'sierra-client/hooks/use-translation'
import { getCachedQueryKey, typedPost, useCachedQuery } from 'sierra-client/state/api'
import { useTracking } from 'sierra-client/views/manage/settings/tracking'
import {
  GetMicrosoftTeamsConfigResponse,
  SetMicrosoftTeamsConfigRequest,
} from 'sierra-domain/api/notification-channels'
import {
  MsTeamsSettings,
  NotificationState,
  PatchOrgNotificationSettingsRequest,
} from 'sierra-domain/api/org-notification-settings'
import { XRealtimeGetMicrosoftTeamsConfig, XRealtimeSetMicrosoftTeamsConfig } from 'sierra-domain/routes'
import { FormElement, Icon } from 'sierra-ui/components'
import { Button, InputPrimitive, Switch, Text, View } from 'sierra-ui/primitives'
import { token } from 'sierra-ui/theming'
import styled from 'styled-components'

const MsTeamsLogo = (): JSX.Element => {
  return (
    <svg height='31' width='31' viewBox='1 1 14 14' xmlns='http://www.w3.org/2000/svg' fill='none'>
      <path
        fill='#5059C9'
        d='M10.765 6.875h3.616c.342 0 .619.276.619.617v3.288a2.272 2.272 0 01-2.274 2.27h-.01a2.272 2.272 0 01-2.274-2.27V7.199c0-.179.145-.323.323-.323zM13.21 6.225c.808 0 1.464-.655 1.464-1.462 0-.808-.656-1.463-1.465-1.463s-1.465.655-1.465 1.463c0 .807.656 1.462 1.465 1.462z'
      />
      <path
        fill='#7B83EB'
        d='M8.651 6.225a2.114 2.114 0 002.117-2.112A2.114 2.114 0 008.65 2a2.114 2.114 0 00-2.116 2.112c0 1.167.947 2.113 2.116 2.113zM11.473 6.875h-5.97a.611.611 0 00-.596.625v3.75A3.669 3.669 0 008.488 15a3.669 3.669 0 003.582-3.75V7.5a.611.611 0 00-.597-.625z'
      />
      <path
        fill='#000000'
        d='M8.814 6.875v5.255a.598.598 0 01-.596.595H5.193a3.951 3.951 0 01-.287-1.476V7.5a.61.61 0 01.597-.624h3.31z'
        opacity='.1'
      />
      <path
        fill='#000000'
        d='M8.488 6.875v5.58a.6.6 0 01-.596.595H5.347a3.22 3.22 0 01-.267-.65 3.951 3.951 0 01-.172-1.15V7.498a.61.61 0 01.596-.624h2.985z'
        opacity='.2'
      />
      <path
        fill='#000000'
        d='M8.488 6.875v4.93a.6.6 0 01-.596.595H5.08a3.951 3.951 0 01-.172-1.15V7.498a.61.61 0 01.596-.624h2.985z'
        opacity='.2'
      />
      <path
        fill='#000000'
        d='M8.163 6.875v4.93a.6.6 0 01-.596.595H5.079a3.951 3.951 0 01-.172-1.15V7.498a.61.61 0 01.596-.624h2.66z'
        opacity='.2'
      />
      <path
        fill='#000000'
        d='M8.814 5.195v1.024c-.055.003-.107.006-.163.006-.055 0-.107-.003-.163-.006A2.115 2.115 0 016.593 4.6h1.625a.598.598 0 01.596.594z'
        opacity='.1'
      />
      <path
        fill='#000000'
        d='M8.488 5.52v.699a2.115 2.115 0 01-1.79-1.293h1.195a.598.598 0 01.595.594z'
        opacity='.2'
      />
      <path
        fill='#000000'
        d='M8.488 5.52v.699a2.115 2.115 0 01-1.79-1.293h1.195a.598.598 0 01.595.594z'
        opacity='.2'
      />
      <path
        fill='#000000'
        d='M8.163 5.52v.647a2.115 2.115 0 01-1.465-1.242h.87a.598.598 0 01.595.595z'
        opacity='.2'
      />
      <path
        fill='url(#microsoft-teams-gradient)'
        d='M1.597 4.925h5.969c.33 0 .597.267.597.596v5.958a.596.596 0 01-.597.596h-5.97A.596.596 0 011 11.479V5.521c0-.33.267-.596.597-.596z'
      />
      <path fill='#ffffff' d='M6.152 7.193H4.959v3.243h-.76V7.193H3.01v-.63h3.141v.63z' />
      <defs>
        <linearGradient
          id='microsoft-teams-gradient'
          x1='2.244'
          x2='6.906'
          y1='4.46'
          y2='12.548'
          gradientUnits='userSpaceOnUse'
        >
          <stop stopColor='#5A62C3' />
          <stop offset='.5' stopColor='#4D55BD' />
          <stop offset='1' stopColor='#3940AB' />
        </linearGradient>
      </defs>
    </svg>
  )
}

const ApplicationIcon = styled.div`
  width: 48px;
  height: 48px;
  padding: 12px;
  display: flex;
  justify-content: center;
  flex-direction: row;
  align-items: center;
  border-radius: 12px;
  border: 0.8px solid ${token('border/strong')};
  flex-shrink: 0;
`

const PromptBox = styled(View)`
  flex-direction: column;
  align-items: flex-start;
  border-radius: 12px;
  width: 100%;
  background-color: ${token('surface/soft')};
  padding: 20px 24px;
  margin-top: 24px;
`

const ActionButtonsWrapper = styled(View)`
  width: 100%;
  justify-content: space-between;
  margin-top: 16px;
  flex-wrap: wrap;
`

const LinkWrapper = styled.a.attrs({ target: '_blank' })`
  cursor: pointer;

  &:hover {
    color: ${token('foreground/link').shift(0.2)};
  }
`

type UseMSTeamsInfoHook = () => {
  isPending: boolean
  config: GetMicrosoftTeamsConfigResponse | null
  setConfig: UseMutateFunction<
    null,
    Error,
    {
      enable: boolean | null
      tenantId?: string | null | undefined
    },
    unknown
  >
}

export const useMSTeamsInfo: UseMSTeamsInfoHook = () => {
  const { data } = useCachedQuery(XRealtimeGetMicrosoftTeamsConfig, {})

  const mutation = useMutation({
    mutationFn: (data: SetMicrosoftTeamsConfigRequest) => typedPost(XRealtimeSetMicrosoftTeamsConfig, data),
    onMutate: async newConfig => {
      const queryKey = getCachedQueryKey(XRealtimeGetMicrosoftTeamsConfig, {})

      await queryClient.cancelQueries({ queryKey })

      const previousConfig = queryClient.getQueryData([queryKey])

      queryClient.setQueryData(queryKey, newConfig)

      return { previousConfig }
    },
    onSettled: async () => {
      const queryKey = getCachedQueryKey(XRealtimeGetMicrosoftTeamsConfig, {})
      await queryClient.invalidateQueries({ queryKey })
    },
  })

  const config = data ?? null

  return {
    config,
    setConfig: mutation.mutate,
    isPending: mutation.isPending,
  }
}

const InstallationPromptBox = (): JSX.Element => {
  const { t } = useTranslation()

  return (
    <PromptBox>
      <Text bold>{t('manage.settings.msTeams.installation.title')}</Text>
      <Text color='foreground/muted'>{t('manage.settings.msTeams.installation.description')}</Text>
      <ActionButtonsWrapper>
        <Button
          href='https://admin.teams.microsoft.com/'
          target='_blank'
          decoratorPosition='right'
          icon='launch'
          variant='secondary'
        >
          {t('manage.settings.msTeams.installation.open-button')}
        </Button>
        <LinkWrapper href='https://help.sana.ai/en/articles/176959-microsoft-teams-notifications'>
          <Text bold color='foreground/link'>
            {t('manage.settings.msTeams.installation.guide-link')}
          </Text>
        </LinkWrapper>
      </ActionButtonsWrapper>
    </PromptBox>
  )
}

const IconAndTitle = (): JSX.Element => {
  const { t } = useTranslation()

  return (
    <View alignItems='center' gap='16'>
      <ApplicationIcon>
        <MsTeamsLogo />
      </ApplicationIcon>
      <Text size='small' bold align='center'>
        {t('manage.settings.msTeams.notifications-title')}
      </Text>
    </View>
  )
}

const HorizontalDivider = styled.hr`
  border: 0;
  border-top: 1px solid ${token('border/default')};
  margin: 24px 0 18px;
`

type Props = {
  teamsSettings: MsTeamsSettings
  onChange: (_: PatchOrgNotificationSettingsRequest) => void
}

export const MSTeamsNotificationsSettings: FC<Props> = ({ teamsSettings, onChange }) => {
  const { t } = useTranslation()
  const tracking = useTracking()

  const inputRef = useRef<HTMLInputElement>(null)

  const [editing, setEditing] = useState(teamsSettings.tenantId === undefined)
  const [invalid, setInvalid] = useState(false)
  const { config, isPending } = useMSTeamsInfo()
  const [localTenantId, setLocalTenantId] = useState(teamsSettings.tenantId ?? '')
  const [confirmingDisable, setConfirmingDisable] = useState(false)

  // This is weird, I was hoping to not have this. Our current setup is a bit messy.
  const notConfigured = teamsSettings.state === NotificationState.Disabled && config?.installationCount === 0

  const enabled = teamsSettings.state === NotificationState.Enabled
  const showInstallationPrompt = config?.installationCount === 0

  const onToggleTeamsNotifications = (newValue: NotificationState): void => {
    onChange({
      msTeamsSettings: {
        state: newValue,
      },
    })
  }

  const validateTenantId = useCallback(() => {
    //const uuidRegex = /^[0-9a-f]{8}-[0-9a-f]{4}-[1-5][0-9a-f]{3}-[89ab][0-9a-f]{3}-[0-9a-f]{12}$/i
    const pattern = /^[0-9a-f]{8}-[0-9a-f]{4}-[0-9a-f]{4}-[0-9a-f]{4}-[0-9a-f]{12}$/i
    const valid = pattern.test(localTenantId)

    return valid
  }, [localTenantId])

  const onConfigSave = (): void => {
    if (!validateTenantId()) {
      setInvalid(true)
      setEditing(true)

      requestAnimationFrame(() => inputRef.current?.focus())

      return
    }

    setEditing(false)

    onChange({
      msTeamsSettings: {
        state: localTenantId ? NotificationState.Enabled : NotificationState.Disabled,
        tenantId: localTenantId || undefined,
      },
    })

    tracking.notifications.enableMSTeams()
  }

  return (
    <>
      <View justifyContent='space-between' grow>
        <IconAndTitle />

        {notConfigured ? (
          <Button variant='secondary' loading={isPending} onClick={() => setEditing(true)}>
            {t('manage.settings.msTeams.configure')}
          </Button>
        ) : (
          <Switch
            checked={enabled}
            onChange={() =>
              enabled ? setConfirmingDisable(true) : onToggleTeamsNotifications(NotificationState.Enabled)
            }
            ariaLabel={t('manage.settings.msTeams.notifications-title')}
          />
        )}
      </View>

      {showInstallationPrompt && <InstallationPromptBox />}

      {!notConfigured && (
        <>
          <HorizontalDivider />
          <View>
            <FormElement
              label={t('manage.settings.msTeams.tenantId')}
              isError={invalid}
              helper={invalid ? t('manage.settings.msTeams.tenantId.input-error-invalid-tenant') : undefined}
            >
              <View>
                <LayoutGroup>
                  <motion.div style={{ flex: 1 }} layout='size' transition={{ duration: 0.15 }}>
                    <InputPrimitive
                      autoFocus
                      ref={inputRef}
                      disabled={!editing}
                      placeholder={t('manage.settings.msTeams.tenantId.placeholder')}
                      value={localTenantId}
                      onChange={e => {
                        setLocalTenantId(e.target.value)
                        setInvalid(false)
                      }}
                      leadingVisual={
                        !editing ? (
                          <View paddingLeft='8'>
                            <Icon iconId='checkmark--filled' color='success/background' />
                          </View>
                        ) : undefined
                      }
                      autoComplete='off'
                    />
                  </motion.div>

                  <AnimatePresence mode='wait'>
                    {editing ? (
                      <>
                        <Button variant='secondary' onClick={onConfigSave}>
                          {t('dictionary.save')}
                        </Button>
                        <Button variant='transparent' onClick={() => setEditing(false)}>
                          {t('dictionary.discard')}
                        </Button>
                      </>
                    ) : (
                      <Button
                        variant='secondary'
                        onClick={() => {
                          setEditing(true)

                          requestAnimationFrame(() => {
                            inputRef.current?.focus()
                          })
                        }}
                      >
                        {t('dictionary.edit')}
                      </Button>
                    )}
                  </AnimatePresence>
                </LayoutGroup>
              </View>
            </FormElement>
          </View>

          {invalid && (
            <View paddingTop='4'>
              <LinkWrapper href='https://learn.microsoft.com/en-us/sharepoint/find-your-office-365-tenant-id'>
                <Text size='micro' color='foreground/link' bold>
                  {t('manage.settings.msTeams.tenantId.how-to-find')}
                </Text>
              </LinkWrapper>
            </View>
          )}
        </>
      )}

      <ActionModal
        open={confirmingDisable}
        isLoading={false}
        onClose={() => {
          setConfirmingDisable(false)
        }}
        primaryAction={() => {
          onToggleTeamsNotifications(NotificationState.Disabled)
        }}
        title={t('manage.settings.msTeams.disable-notifications.title')}
        primaryActionLabel={t('manage.settings.msTeams.disable-notifications.confirm')}
        deleteAction
      >
        <Text>{t('manage.settings.msTeams.disable-notifications.description')}</Text>
      </ActionModal>
    </>
  )
}
