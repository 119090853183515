import { View } from 'sierra-ui/primitives'
import styled from 'styled-components'

export const DropdownWidth = styled(View).attrs({
  direction: 'column',
  justifyContent: 'flex-start',
  alignItems: 'stretch',
})`
  min-width: 150px;
`
