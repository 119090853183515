import { useQueryClient } from '@tanstack/react-query'
import React from 'react'
import { resolveUserAvatar } from 'sierra-client/api/content'
import { graphql } from 'sierra-client/api/graphql/gql'
import { DebugAutoAssignMutation } from 'sierra-client/api/graphql/gql/graphql'
import { graphQuery } from 'sierra-client/api/hooks/use-graphql-query'
import { SearchContent } from 'sierra-client/components/shortcut-menu/search-content'
import { useTranslation } from 'sierra-client/hooks/use-translation'
import { isUserLoaded, useUsers } from 'sierra-client/state/users/hooks'
import { EditableContent } from 'sierra-domain/api/editable-content'
import { AvatarStack, Label, TruncatedText } from 'sierra-ui/components'
import { View } from 'sierra-ui/primitives'

const autoAssignSkills = async (courseId: string): Promise<DebugAutoAssignMutation> => {
  return graphQuery(
    graphql(`
      mutation debugAutoAssign($courseId: CourseId!) {
        debugAutoAssign(courseId: $courseId) {
          skillNames
          reason
        }
      }
    `),
    { courseId }
  )
}

type ConditionalWrapperProps = {
  condition: boolean
  wrapper: (children: React.ReactElement) => React.JSX.Element
  children: React.ReactElement
}
const ConditionalWrapper: React.FC<ConditionalWrapperProps> = ({ condition, wrapper, children }) =>
  condition ? wrapper(children) : children

const ContentRowTitle: React.FC<{ title: string; type: string; courseId: string }> = ({
  courseId,
  title,
  type,
}) => (
  <View>
    <ConditionalWrapper condition={type !== 'native:self-paced'} wrapper={children => <s>{children}</s>}>
      <TruncatedText lines={2} size='small' bold>
        {type === 'native:self-paced' ? `${title} [${courseId}]` : `${title} [${courseId}]`}
      </TruncatedText>
    </ConditionalWrapper>
    {type === 'native:self-paced' && (
      <Label iconId='bookmark--filled' $size='small' bold $bgColor='grey5' $color='grey90'>
        Course
      </Label>
    )}
    {type === 'path' && (
      <Label iconId='path' $size='small' bold $bgColor='grey5' $color='grey90'>
        Not supported
      </Label>
    )}
  </View>
)

const Content: React.FC<EditableContent> = ({ ...content }) => {
  const { t } = useTranslation()
  const displayTitle = content.title === '' ? t('admin.author.no-title') : content.title
  const users = useUsers(content.collaborators.map(u => u.userId))
  const loadedUsers = users.filter(isUserLoaded).map(resolveUserAvatar)

  return (
    <>
      <ContentRowTitle {...content} title={displayTitle} courseId={content.id} />
      <AvatarStack users={loadedUsers} size='tiny' />
    </>
  )
}

export const AutoAssignSkills: React.FC = () => {
  const queryClient = useQueryClient()
  return (
    <SearchContent
      numberOfCoursesToDisplay={25}
      ContentComponent={Content}
      onContentSelected={content =>
        autoAssignSkills(content.id).then(x => {
          void queryClient.invalidateQueries({ queryKey: ['graphql', 'skills-table'] })
          return x
        })
      }
      successMessage={result => {
        const skillsAssigned = result.debugAutoAssign.skillNames
        return skillsAssigned.length === 0
          ? 'No skills were assigned'
          : `Skills assigned: ${skillsAssigned.join(', ')}`
      }}
    />
  )
}
