import { FC } from 'react'
import { assertNever, iife } from 'sierra-domain/utils'
import { Icon, IconId } from 'sierra-ui/components'
import { palette, token } from 'sierra-ui/theming'
import styled, { css } from 'styled-components'

type IconVariant = 'default' | 'success' | 'warning' | 'failed'

const IconContainer = styled.div<{ $variant: IconVariant }>`
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 6px;
  border-radius: 999px;
  background: tomato;

  ${p =>
    iife(() => {
      switch (p.$variant) {
        case 'default': {
          return css`
            background: ${palette.blue.vivid};
            color: white;
          `
        }
        case 'success': {
          return css`
            background: ${token('success/background')};
            color: white;
          `
        }
        case 'warning': {
          return css`
            background: ${token('warning/background')};
            color: white;
          `
        }
        case 'failed': {
          return css`
            background: ${token('surface/soft')};
            color: ${token('foreground/secondary')};
          `
        }
        default:
          assertNever(p.$variant)
      }
    })}
`

export const NotificationIcon: FC<{ variant?: IconVariant; iconId: IconId }> = ({
  variant = 'default',
  iconId,
}) => {
  return (
    <IconContainer $variant={variant}>
      <Icon iconId={iconId} color='currentColor' size='size-12' />
    </IconContainer>
  )
}
