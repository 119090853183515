import { AuthenticationLayout } from 'sierra-client/views/authentication/authentication-layout'
import { ResetPassword } from 'sierra-client/views/authentication/native/account-action/reset-password'
import { UnsubscribeEmail } from 'sierra-client/views/authentication/native/account-action/unsubscribe-email'
import { VerifyEmail } from 'sierra-client/views/authentication/native/account-action/verify-email'
import { AuthenticationContainer } from 'sierra-client/views/authentication/native/components/common'

export type NativeAccountActionProps = {
  code: string
  mode: string
}

export const NativeAccountAction = ({ code, mode }: NativeAccountActionProps): JSX.Element => {
  return (
    <AuthenticationLayout>
      <AuthenticationContainer>
        {mode === 'reset-password' ? (
          <ResetPassword code={code} />
        ) : mode === 'verify-email' ? (
          <VerifyEmail code={code} />
        ) : mode === 'unsubscribe-notification-emails' ? (
          <UnsubscribeEmail code={code} />
        ) : null}
      </AuthenticationContainer>
    </AuthenticationLayout>
  )
}
