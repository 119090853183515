import type {
  AgoraRTCStats,
  LocalAudioTrackStats,
  LocalVideoTrackStats,
  RemoteAudioTrackStats,
  RemoteVideoTrackStats,
} from 'agora-rtc-sdk-ng'
import { atom } from 'jotai'
import { Text } from 'sierra-ui/primitives'
import styled from 'styled-components'

export const SectionTitle = styled(Text).attrs({
  bold: true,
  size: 'small',
})``

export const StatsText = styled(Text).attrs({ size: 'micro' })``

export const formatBytes = (bytes: number): string => (bytes / 1000000).toPrecision(4) + ' MB'
export const formatBitrate = (bitrate: number): string => (bitrate / 1000).toPrecision(4) + ' Kbps'
export const formatTime = (ms: number | undefined): string =>
  ms !== undefined ? ms.toPrecision(4) + ' ms' : 'N/A'
export const formatFps = (fps: number | undefined): string =>
  fps !== undefined ? Math.floor(fps).toString() : 'N/A'

type LocalTracksStats = {
  videoStats?: LocalVideoTrackStats
  audioStats?: LocalAudioTrackStats
}

type RemoteTracksStats = {
  remoteVideoStats?: { [uid: string]: RemoteVideoTrackStats }
  remoteAudioStats?: { [uid: string]: RemoteAudioTrackStats }
}

type PerformanceStats = {
  renderRateVolatility?: number
  statsSchedulingTime?: number
  statsDurationTime?: number
}

export const CallStatsAtom = atom<AgoraRTCStats | undefined>(undefined)
export const PerformanceStatsAtom = atom<PerformanceStats>({})
export const LocalTracksStatsAtom = atom<LocalTracksStats>({})
export const RemoteTracksStatsAtom = atom<RemoteTracksStats>({})

export type ComputePressureValue = 'nominal' | 'fair' | 'serious' | 'critical' | undefined
export const ComputePressureAtom = atom<ComputePressureValue>(undefined)
export const RenderRateVolatilityEmaAtom = atom<number | undefined>(undefined)
export const StatsSchedulingTimeEmaAtom = atom<number | undefined>(undefined)
