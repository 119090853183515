import React from 'react'
import { useTranslation } from 'sierra-client/hooks/use-translation'
import { TableAPI } from 'sierra-client/lib/tabular/api'
import { EnrollmentRep } from 'sierra-client/lib/tabular/datatype/internal/reps/enrollment-rep'
import { getUserName } from 'sierra-domain/utils'
import { Icon, IconId, TruncatedText } from 'sierra-ui/components'
import styled from 'styled-components'

const FlexRowDiv = styled.div`
  display: flex;
  flex-direction: row;
`

const IconCell: React.FC<{ iconId: IconId; text?: string }> = ({ iconId, text }) => (
  <FlexRowDiv>
    <Icon iconId={iconId} />
    {text !== undefined && (
      <TruncatedText lines={1} bold size='small'>
        {text}
      </TruncatedText>
    )}
  </FlexRowDiv>
)

export const RenderEnrolledBy: React.FC<{ enrolledBy: EnrollmentRep; api: TableAPI }> = ({ enrolledBy }) => {
  const { t } = useTranslation()

  return enrolledBy.type === 'admin' ? (
    <TruncatedText lines={1} size='small' bold={true}>
      {getUserName(enrolledBy) ?? t('dictionary.unknown')}
    </TruncatedText>
  ) : enrolledBy.type === 'self' ? (
    <TruncatedText lines={1} size='small' bold={true}>
      {t('dictionary.self-enrolment')}
    </TruncatedText>
  ) : enrolledBy.type === 'enrollment-rule' ? (
    <IconCell iconId='bolt' text={enrolledBy.name} />
  ) : (
    <TruncatedText lines={1} size='small'>
      {t('dictionary.unknown')}
    </TruncatedText>
  )
}
