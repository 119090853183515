import { useAtomValue } from 'jotai'
import { atomWithStorage } from 'sierra-client/state/storage'
import { useMediaQuery } from 'sierra-ui/utils'

export type ColorThemePreference = 'light' | 'dark' | 'system-preference'
export const colorThemePreferenceAtom = atomWithStorage<ColorThemePreference>('colorTheme', 'light')

export const useColorThemePreference = (): ColorThemePreference => {
  return useAtomValue(colorThemePreferenceAtom)
}

export const useIsDarkTheme = (): boolean => {
  const preference = useColorThemePreference()
  const prefersDarkMode = useMediaQuery('(prefers-color-scheme: dark)')

  switch (preference) {
    case 'light':
      return false
    case 'dark':
      return true
    case 'system-preference':
      return prefersDarkMode
  }
  preference satisfies never
}
