import _ from 'lodash'
import { ColorBuilder, color } from 'sierra-ui/color'
import { ColorValue } from 'sierra-ui/color/types'

function isSolidWhite(colorString: ColorValue | ColorBuilder): boolean {
  return _.isEqual(color(colorString).toRgba(), { r: 255, g: 255, b: 255, a: 1 })
}

export function getDropAWordBackgroundColor(colorName: ColorValue): ColorBuilder {
  if (isSolidWhite(colorName)) {
    return color('#f3f2f5')
  } else {
    return color(colorName)
  }
}
