import React from 'react'
import { ActionModal } from 'sierra-client/components/common/modals/action-modal'
import { useTranslation } from 'sierra-client/hooks/use-translation'
import { Spacer } from 'sierra-ui/primitives'

export const MissingMandatoryAttributesUserDetailAlert: React.FC<{
  open: boolean
  onAbort: () => void
}> = ({ open, onAbort }) => {
  const { t } = useTranslation()

  return (
    <ActionModal
      title={t('manage.users.user-attributes.alerts.missing-user-details.title')}
      open={open}
      onClose={onAbort}
      secondaryAction={onAbort}
      secondaryActionLabel={t(
        'manage.users.user-attributes.alerts.missing-user-details.add-missing-attributes'
      )}
    >
      {t('manage.users.user-attributes.alerts.missing-user-details.description-user-detail')}
      <Spacer />
    </ActionModal>
  )
}
