import { MutableRefObject, useCallback, useEffect, useRef } from 'react'
import { ConnectDragSource, ConnectableElement, useDrag } from 'react-dnd'
import { ContentTableDragItem, DragItemTypes } from 'sierra-client/components/common/dnd/dnd-types'
import { useIsMobile } from 'sierra-client/state/browser/selectors'
import { EditableContent, NonEditorTeamspaceCourse } from 'sierra-domain/api/editable-content'
import { FolderRow } from 'sierra-domain/api/teamspace'
import { AssetContext } from 'sierra-domain/asset-context'
import { isNonNullable } from 'sierra-domain/utils'

const isElementDefined = (element: unknown): element is HTMLElement => {
  return isNonNullable(element) && element instanceof HTMLElement
}

const usePreventBrowserDefaultDndBehaviour = (dragRef: MutableRefObject<ConnectableElement | null>): void => {
  useEffect(() => {
    const element = dragRef.current

    const mousedownHandler: EventListener = event => {
      event.preventDefault()
    }

    if (isElementDefined(element)) {
      // Workaround to prevent firefox from having a drag overlay when we
      // start dragging an element.
      element.draggable = false

      // Draggable attribute is not propagated to the children. So, we also need to
      // find a potential link and disable the HTML draggable.
      const link = element.querySelector('a')
      if (isNonNullable(link)) {
        link.draggable = false
      }

      // Prevent Firefox from triggering a click on the element after dropping.
      element.addEventListener('mousedown', mousedownHandler)
    }

    return () => {
      if (isElementDefined(element)) {
        element.removeEventListener('mousedown', mousedownHandler)
      }
    }
  }, [dragRef])
}

export const useContentDrag = (
  content: EditableContent | FolderRow | NonEditorTeamspaceCourse,
  assetContext: AssetContext
): { drag: ConnectDragSource; isDragging: boolean; canDrag: boolean } => {
  const isMobile = useIsMobile()
  const [{ isDragging, canDrag }, drag] = useDrag<
    ContentTableDragItem,
    void,
    { isDragging: boolean; canDrag: boolean }
  >(() => {
    if (content.type === 'folder') {
      return {
        type: DragItemTypes.ContentTableContent,
        collect: monitor => ({
          isDragging: monitor.isDragging(),
          canDrag: monitor.canDrag(),
        }),
        canDrag:
          !isMobile &&
          (content.teamspaceEffectiveRole === 'owner' || content.teamspaceEffectiveRole === 'editor'),
        item: {
          type: 'content-table',
          id: content.id,
          title: content.displayName,
          itemType: 'folder',
          iconId: 'folder',
          currentTeamspaceId: content.teamspaceId,
          highestCollaboratorRole: content.teamspaceEffectiveRole,
        },
      }
    }

    if (content.type === 'path') {
      return {
        type: DragItemTypes.ContentTableContent,
        collect: monitor => ({
          isDragging: monitor.isDragging(),
          canDrag: monitor.canDrag(),
        }),
        canDrag: false,
        item: {
          type: 'content-table',
          id: content.id,
          title: content.title,
          itemType: content.type,
          currentTeamspaceId: content.teamspaceId,
          highestCollaboratorRole: content.highestCollaboratorRole,
          ...(content.image ? { image: content.image, assetContext } : { iconId: 'document--blank' }),
        },
      }
    }

    return {
      type: DragItemTypes.ContentTableContent,
      collect: monitor => ({
        isDragging: monitor.isDragging(),
        canDrag: monitor.canDrag(),
      }),
      canDrag: !isMobile,
      item: {
        type: 'content-table',
        id: content.id,
        title: content.title,
        itemType: content.type,
        currentTeamspaceId: content.teamspaceId,
        highestCollaboratorRole: content.highestCollaboratorRole,
        ...(content.image ? { image: content.image, assetContext } : { iconId: 'document--blank' }),
      },
    }
  }, [isMobile, content, assetContext])

  const dragRef = useRef<ConnectableElement | null>(null)
  const dragWrapper = useCallback<ConnectDragSource>(
    (ref: ConnectableElement | null) => {
      dragRef.current = ref
      return drag(ref)
    },
    [drag]
  )

  usePreventBrowserDefaultDndBehaviour(dragRef)

  return { drag: dragWrapper, isDragging, canDrag }
}
