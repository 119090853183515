import { DateTime } from 'luxon'
import { getTableValue } from 'sierra-client/features/insights/display-widgets/data-utils'
import {
  createEmptyDimensionTableValue,
  createEmptyMeasureTableValue,
} from 'sierra-client/features/insights/display-widgets/normalize-data/empty-rows'
import {
  DimensionColumn,
  MeasureColumn,
  SortByColumnRef,
  TableRow,
  TableValue,
  TimeFilter,
  WidgetWithAtLeastOneDimension,
} from 'sierra-domain/api/insights'
import { Filter } from 'sierra-domain/filter/datatype/filter'
import { Operator } from 'sierra-domain/filter/datatype/op'
import { assertNever } from 'sierra-domain/utils'

const dateStringIn = (operator: Operator, currentDateString: string, filterDateString: string): boolean => {
  switch (operator.type) {
    case 'operator.lt':
      return new Date(currentDateString) < new Date(filterDateString)
    case 'operator.lte':
      return new Date(currentDateString) <= new Date(filterDateString)
    case 'operator.gt':
      return new Date(currentDateString) > new Date(filterDateString)
    case 'operator.gte':
      return new Date(currentDateString) >= new Date(filterDateString)
    case 'operator.eq':
      return currentDateString === filterDateString
    case 'operator.neq':
      return currentDateString !== filterDateString
    default:
      throw new Error(`Unsupported operator: ${operator.type}`)
  }
}

const isDateInFilter = (dateString: string, filter: Filter | undefined): boolean => {
  if (filter === undefined) return true

  switch (filter.type) {
    case 'filter.and':
      return filter.filters.every(filter => {
        return isDateInFilter(dateString, filter)
      })
    case 'filter.or':
      return filter.filters.some(filter => {
        return isDateInFilter(dateString, filter)
      })
    case 'filter.filter': {
      if (filter.domain.type !== 'dimension.native.time') return true

      if (filter.predicate.type === 'predicate.or') {
        return filter.predicate.values.some(predicate => {
          if (predicate.type !== 'value.date') return true
          return dateStringIn(filter.operator, dateString, predicate.value)
        })
      }

      if (filter.predicate.type === 'predicate.and') {
        return filter.predicate.values.every(predicate => {
          if (predicate.type !== 'value.date') return true
          return dateStringIn(filter.operator, dateString, predicate.value)
        })
      }

      if (filter.predicate.type === 'predicate.none') return false

      if (filter.predicate.value.type !== 'value.date') return true
      return dateStringIn(filter.operator, dateString, filter.predicate.value.value)
    }
    default:
      assertNever(filter)
  }
}

const getDateStringFromTableValue = (tableValue: TableValue): string | undefined => {
  switch (tableValue.type) {
    case 'value.date':
      return tableValue.value
    case 'value.week':
    case 'value.month':
    case 'value.quarter':
    case 'value.year':
      return tableValue.date
    default:
      throw new Error('Data point is not time')
  }
}

const getFirstRowWithDate = (
  tableRows: TableRow[],
  indexDimension: DimensionColumn
): { tableRow: TableRow | undefined } => {
  const tableRow = tableRows.find(row => {
    const tableValue = row.values[indexDimension.name]
    if (tableValue === undefined) return false

    const dateString = getDateStringFromTableValue(tableValue)
    return dateString !== undefined
  })

  return { tableRow }
}

const getLastRowWithDate = (
  tableRows: TableRow[],
  indexDimension: DimensionColumn
): { tableRow: TableRow | undefined } => {
  const tableRow = tableRows.findLast(row => {
    const tableValue = row.values[indexDimension.name]
    if (tableValue === undefined) return false

    const dateString = getDateStringFromTableValue(tableValue)
    return dateString !== undefined
  })

  return { tableRow }
}

const getDateStringFromTableRow = (tableRow: TableRow, indexDimension: DimensionColumn): string => {
  const dimensionTableValue = getTableValue(tableRow, indexDimension.name)

  const dateString = getDateStringFromTableValue(dimensionTableValue)
  if (dateString === undefined) throw new Error('Date is not available')

  return dateString
}

const getStartDateFromTimeFilter = (serverTimeNow: string, timeFilter?: TimeFilter): string | undefined => {
  if (timeFilter === undefined) return undefined

  switch (timeFilter.type) {
    case 'time.last': {
      const now = DateTime.fromISO(serverTimeNow)
      switch (timeFilter.unit) {
        case 'unit.temporal.date': {
          const start = now.minus({ days: timeFilter.amount })
          return start.toSQLDate()
        }
        case 'unit.temporal.year_month': {
          const start = now.minus({ months: timeFilter.amount })
          return start.toSQLDate()
        }
        case 'unit.temporal.year_week': {
          const start = now.minus({ weeks: timeFilter.amount })
          return start.toSQLDate()
        }
        case 'unit.temporal.year_quarter': {
          const start = now.minus({ months: timeFilter.amount * 3 })
          return start.toSQLDate()
        }
        case 'unit.temporal.year': {
          const start = now.minus({ years: timeFilter.amount })
          return start.toSQLDate()
        }
        default:
          assertNever(timeFilter.unit)
      }
      break
    }
    case 'time.between':
      return timeFilter.startDate
    case 'time.since':
      return timeFilter.date
  }
}

const getEndDateFromTimeFilter = (timeFilter?: TimeFilter): string | undefined => {
  if (timeFilter === undefined) return undefined

  switch (timeFilter.type) {
    case 'time.last':
      return undefined
    case 'time.between':
      return timeFilter.endDate
    case 'time.since':
      return undefined
  }
}

const advanceDate = (date: DateTime, dimensionType: string): DateTime => {
  switch (dimensionType) {
    case 'type.date':
      return date.plus({ days: 1 })
    case 'type.week':
      return date.plus({ weeks: 1 }).startOf('week')
    case 'type.month':
      return date.plus({ months: 1 }).startOf('month')
    case 'type.quarter':
      return date.plus({ months: 3 }).startOf('quarter')
    case 'type.year':
      return date.plus({ years: 1 }).startOf('year')
    default:
      throw new Error(`Unsupported dimension type: ${dimensionType}`)
  }
}

/**
 * Fills gaps in table rows by adding empty rows for missing time rows, i.e. date, week, month, quarter, year.
 */
export const fillDataGapsInTableRows = ({
  tableRows,
  measure,
  indexDimension,
  filter,
  timeFilter,
  serverTimeNow,
}: {
  tableRows: TableRow[]
  measure: MeasureColumn
  indexDimension: DimensionColumn
  filter: Filter | undefined
  timeFilter: TimeFilter | undefined
  serverTimeNow: string
}): TableRow[] => {
  // We only support filling gaps for time dimensions
  if (
    indexDimension.type.type !== 'type.date' &&
    indexDimension.type.type !== 'type.week' &&
    indexDimension.type.type !== 'type.month' &&
    indexDimension.type.type !== 'type.quarter' &&
    indexDimension.type.type !== 'type.year'
  )
    throw new Error('Dimension is not time')

  const { tableRow: firstTableRowWithDate } = getFirstRowWithDate(tableRows, indexDimension)
  if (firstTableRowWithDate === undefined) return tableRows
  const { tableRow: lastTableRowWithDate } = getLastRowWithDate(tableRows, indexDimension)
  if (lastTableRowWithDate === undefined) return tableRows

  const firstRowDateString = getDateStringFromTableRow(firstTableRowWithDate, indexDimension)
  const lastRowDateString = getDateStringFromTableRow(lastTableRowWithDate, indexDimension)

  const firstDate = DateTime.fromISO(firstRowDateString, { zone: 'UTC' })
  const lastDate = DateTime.fromISO(lastRowDateString, { zone: 'UTC' })

  const direction: SortByColumnRef['order'] = firstDate < lastDate ? 'asc' : 'desc'

  const timeFilterStartDate = getStartDateFromTimeFilter(serverTimeNow, timeFilter)
  const timeFilterEndDate = getEndDateFromTimeFilter(timeFilter)

  const ascStartDate =
    timeFilterStartDate !== undefined ? DateTime.fromISO(timeFilterStartDate, { zone: 'UTC' }) : firstDate

  const startDate = direction === 'asc' ? ascStartDate : lastDate
  const endDate =
    timeFilterEndDate !== undefined
      ? DateTime.fromISO(timeFilterEndDate, { zone: 'UTC' })
      : DateTime.fromISO(serverTimeNow, { zone: 'UTC' })

  const dates: string[] = []

  let currentDate = startDate.toUTC()

  while (currentDate <= endDate) {
    const dateString = currentDate.toSQLDate()

    if (isDateInFilter(dateString, filter)) {
      dates.push(dateString)
    }

    currentDate = advanceDate(currentDate, indexDimension.type.type)
  }

  if (direction === 'desc') {
    dates.reverse()
  }

  const firstMeasureTableValue = getTableValue(firstTableRowWithDate, measure.name)
  const firstDimensionTableValue = getTableValue(firstTableRowWithDate, indexDimension.name)

  const filledTableRows: TableRow[] = []

  for (const date of dates) {
    const existingData = tableRows.filter(row => {
      const value = row.values[indexDimension.name]
      if (value === undefined) throw new Error('No value for this date')

      const dateString = getDateStringFromTableValue(value)
      if (dateString === undefined) return false

      const isSameDate = dateString === date
      return isSameDate
    })

    if (existingData.length > 0) {
      filledTableRows.push(...existingData)
    } else {
      const emptyDimensionTableValue = createEmptyDimensionTableValue(
        firstDimensionTableValue,
        new Date(date)
      )
      const emptyMeasureTableValue = createEmptyMeasureTableValue(firstMeasureTableValue)
      const emptyTableRow: TableRow = {
        values: {
          [indexDimension.name]: emptyDimensionTableValue,
          [measure.name]: emptyMeasureTableValue,
        },
      }
      filledTableRows.push(emptyTableRow)
    }
  }

  return filledTableRows
}

/**
 * Fills gaps in table result by adding empty rows for missing time rows, i.e. date, week, month, quarter, year.
 */
export const fillDataGapsInInTableResult = ({
  tableRows,
  widget,
  indexDimension,
  measure,
  serverTimeNow,
}: {
  tableRows: TableRow[]
  widget: WidgetWithAtLeastOneDimension
  indexDimension: DimensionColumn
  measure: MeasureColumn
  serverTimeNow: string
}): TableRow[] => {
  const { filter, timeFilter } = widget

  switch (indexDimension.type.type) {
    case 'type.date':
    case 'type.week':
    case 'type.month':
    case 'type.quarter':
    case 'type.year':
      return fillDataGapsInTableRows({
        tableRows,
        measure,
        indexDimension,
        filter,
        timeFilter,
        serverTimeNow,
      })
    default:
      return tableRows
  }
}
