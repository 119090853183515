import FormData from 'form-data'
import { useCallback } from 'react'
import { postWithUserErrorCode, postWithUserErrorException } from 'sierra-client/state/api'
import { useDispatch } from 'sierra-client/state/hooks'
import { AnyZodRoute, ZodRouteInput, ZodRouteOutput } from 'sierra-domain/api/types'
import { Either } from 'sierra-domain/either'
import { UserErrorCode } from 'sierra-domain/error'

export type PostWithoutErrorsType = <Route extends AnyZodRoute>(
  route: Route,
  input: ZodRouteInput<Route> | FormData,
  options?: { notifications?: boolean }
) => Promise<ZodRouteOutput<Route>>

export type PostWithErrorsType = <Route extends AnyZodRoute>(
  route: Route,
  input: ZodRouteInput<Route> | FormData
) => Promise<Either<UserErrorCode, ZodRouteOutput<Route>>>

export const usePost = (): {
  postWithUserErrorException: PostWithoutErrorsType
  postWithUserErrorCode: PostWithErrorsType
} => {
  const dispatch = useDispatch()

  return {
    postWithUserErrorException: useCallback(
      (route, input, { notifications = true } = { notifications: true }) =>
        postWithUserErrorException(route, input, dispatch, { notifications }),
      [dispatch]
    ),
    postWithUserErrorCode: useCallback(
      (route, input) => postWithUserErrorCode(route, input, dispatch, { notifications: true }),
      [dispatch]
    ),
  }
}
