import { useMutation } from '@tanstack/react-query'
import React, { useCallback, useState } from 'react'
import { graphql } from 'sierra-client/api/graphql/gql'
import {
  CreateEnrollmentRequestMutationVariables,
  SelfEnrollToEventMutationVariables,
  SelfUnnrollFromEventMutationVariables,
} from 'sierra-client/api/graphql/gql/graphql'
import { graphQuery } from 'sierra-client/api/hooks/use-graphql-query'
import { useTranslation } from 'sierra-client/hooks/use-translation'
import { TranslationLookup } from 'sierra-client/hooks/use-translation/types'
import { EnrollmentStatus } from 'sierra-client/views/learner/event-group/get-list-item-enrollment-status'
import { withModal } from 'sierra-client/views/manage/utils/with-modal'
import { CalendarEventId } from 'sierra-domain/api/nano-id'
import { FormElement } from 'sierra-ui/components'
import { CalendarEventDisplay } from 'sierra-ui/missions/foundation/calendar-events/calendar-event-display'
import { Button, Heading, IconButton, Spacer, Text, TextAreaPrimitive, View } from 'sierra-ui/primitives'
import styled from 'styled-components'

export const createEnrollmentRequestMutation = graphql(`
  mutation createEnrollmentRequest($input: CreateCalendarEventEnrollmentRequestInput!) {
    createCalendarEventEnrollmentRequest(input: $input) {
      __typename
    }
  }
`)

export const selfEnrollMutation = graphql(`
  mutation SelfEnrollToEvent($input: EnrollInEventInput!) {
    selfEnrollToEvent(input: $input) {
      __typename
    }
  }
`)

export const selfUnenrollMutation = graphql(`
  mutation SelfUnnrollFromEvent($input: UnenrollInEventInput!) {
    selfUnenrollFromEvent(input: $input) {
      __typename
    }
  }
`)

const getListItemButtonText = ({
  enrollmentStatus,
  t,
}: {
  enrollmentStatus: EnrollmentStatus
  t: TranslationLookup
}): string => {
  switch (enrollmentStatus) {
    case 'can-request-seat':
    case 'disabled-request-seat':
      return t('event-groups.request-a-seat')
    case 'pending-enrollment-request':
      return t('event-groups.waiting-for-approval')
    case 'disabled-enrolled':
    case 'enrolled':
      return t('course-detail.enrolled')
    case 'can-self-enroll':
    case 'disabled-self-enroll':
      return t('course-detail.enroll')
    case 'enrollment-request-rejected':
      return t('event-groups.request-rejected')
  }
}

const RequestSeatInformation = styled(Text).attrs({ color: 'foreground/secondary' })``

export type CalendarEventInfo = {
  left: string
  imageUrl: string
  right: string
}

type RequestSeatModalProps = {
  calendarEventInfo: CalendarEventInfo
  onConfirm: (requesterComment: string | undefined) => void
}

const RequestSeatModal = withModal<RequestSeatModalProps>(
  { padding: '24 24', size: { width: 618 }, disableScrollbarGutter: true },
  ({ calendarEventInfo, onClose, onConfirm }) => {
    const { t } = useTranslation()
    const [requesterComment, setRequesterComment] = useState<string>('')

    return (
      <View grow direction='column' justifyContent='space-between' alignItems='stretch' gap='8'>
        <View direction='column' gap='16'>
          <View direction='row' justifyContent='space-between' alignItems='flex-start'>
            <Heading size='h5' bold>
              {t('in-person-events.request-header')}
            </Heading>
            <IconButton size='small' variant='transparent' iconId='close' onClick={onClose} />
          </View>
          <RequestSeatInformation>{t('in-person-events.request-info-2')}</RequestSeatInformation>
          <FormElement label={t('dictionary.session')}>
            <CalendarEventDisplay {...calendarEventInfo} />
          </FormElement>
          <FormElement label={`${t('dictionary.comment-singular')} (${t('dictionary.optional')})`}>
            <TextAreaPrimitive
              placeholder={t('in-person-events.request-comment-placeholder')}
              value={requesterComment}
              onChange={e => setRequesterComment(e.target.value)}
            />
          </FormElement>
          <Spacer size='none' />
        </View>
        <View grow direction='row' justifyContent='flex-end' alignItems='flex-end' gap='8' marginTop='auto'>
          <Button variant='secondary' onClick={onClose}>
            {t('dictionary.close')}
          </Button>
          <Button
            variant='primary'
            onClick={() => {
              const trimmedComment = requesterComment.trim()
              return onConfirm(trimmedComment === '' ? undefined : trimmedComment)
            }}
          >
            {t('dictionary.confirm')}
          </Button>
        </View>
      </View>
    )
  }
)

export const CalendarEventEnrollmentRequestButton: React.FC<{
  calendarEventId: CalendarEventId
  calendarEventInfo: CalendarEventInfo
  enrollmentStatus: EnrollmentStatus
  itemChanged: () => void
}> = ({ calendarEventId, enrollmentStatus, itemChanged, calendarEventInfo }) => {
  const { t } = useTranslation()
  const [requestModalOpen, setRequestModalOpen] = React.useState(false)

  const enrollmentRequestMutation = useMutation({
    mutationFn: (variables: CreateEnrollmentRequestMutationVariables) =>
      graphQuery(createEnrollmentRequestMutation, variables),
    onSettled: () => itemChanged(),
  })

  const enrollInEventMutation = useMutation({
    mutationFn: (variables: SelfEnrollToEventMutationVariables) => graphQuery(selfEnrollMutation, variables),
    onSettled: () => itemChanged(),
  })

  const unenrollInEventMutation = useMutation({
    mutationFn: (variables: SelfUnnrollFromEventMutationVariables) =>
      graphQuery(selfUnenrollMutation, variables),
    onSettled: () => itemChanged(),
  })

  const loading =
    enrollInEventMutation.isPending ||
    unenrollInEventMutation.isPending ||
    enrollmentRequestMutation.isPending

  const handleClickEnroll = useCallback((): void => {
    switch (enrollmentStatus) {
      case 'can-request-seat':
        setRequestModalOpen(true)
        break
      case 'pending-enrollment-request':
        break
      case 'enrolled':
        unenrollInEventMutation.mutate({ input: { calendarEventId } })
        break
      case 'can-self-enroll':
        enrollInEventMutation.mutate({ input: { calendarEventId } })
        break
    }
  }, [enrollInEventMutation, enrollmentStatus, calendarEventId, unenrollInEventMutation])

  const noUserActionAvailable =
    enrollmentStatus === 'disabled-enrolled' ||
    enrollmentStatus === 'disabled-request-seat' ||
    enrollmentStatus === 'disabled-self-enroll' ||
    enrollmentStatus === 'enrollment-request-rejected' ||
    enrollmentStatus === 'pending-enrollment-request'

  if (noUserActionAvailable) {
    return (
      <Text
        color={enrollmentStatus === 'disabled-enrolled' ? 'success/background' : 'foreground/primary'}
        bold
      >
        {getListItemButtonText({
          enrollmentStatus,
          t,
        })}
      </Text>
    )
  }

  return (
    <>
      <Button
        onClick={handleClickEnroll}
        loading={loading}
        variant={enrollmentStatus === 'enrolled' ? 'success' : 'secondary'}
      >
        {getListItemButtonText({
          enrollmentStatus,
          t,
        })}
      </Button>
      <RequestSeatModal
        open={requestModalOpen}
        onClose={() => setRequestModalOpen(false)}
        calendarEventInfo={calendarEventInfo}
        onConfirm={requesterComment => {
          enrollmentRequestMutation.mutate({ input: { calendarEventId, requesterComment } })
          setRequestModalOpen(false)
        }}
      />
    </>
  )
}
