import { getDescriptionFromTimeFilterItem } from 'sierra-client/features/insights/widget-builder/data-selectors/time-filter-utils'
import { TimeFilter } from 'sierra-domain/api/insights'
import { MenuItem } from 'sierra-ui/components'
import { SingleSelectDropdown } from 'sierra-ui/primitives/menu-dropdown'

type TimeFilterItem = {
  label: string
  timeFilter: TimeFilter
}

const timeFilterItems = {
  'last-month': {
    label: 'Last month',
    timeFilter: { type: 'time.last', amount: 1, unit: 'unit.temporal.year_month' },
  },
  // 'previous-month': {
  //   label: 'Previous month',
  //   timeFilter: { type: 'time.between', startDate: '2024-01-01', endDate: '2024-02-01' },
  // },
  'three-months': {
    label: '3 months',
    timeFilter: { type: 'time.last', amount: 3, unit: 'unit.temporal.year_month' },
  },
  'six-months': {
    label: '6 months',
    timeFilter: { type: 'time.last', amount: 6, unit: 'unit.temporal.year_month' },
  },
  'last-year': {
    label: 'Last year',
    timeFilter: { type: 'time.last', amount: 1, unit: 'unit.temporal.year' },
  },
  'since-2024': {
    label: 'Since beginning of year',
    timeFilter: { type: 'time.since', date: '2024-01-01' },
  },
} as const satisfies Record<string, TimeFilterItem>

type TimeFilterItemId = keyof typeof timeFilterItems

type TimeFilterMenuItem = MenuItem<TimeFilterItemId>

const getMenuItemFromTimeFilterItem = ([id, timeFilterItem]: [
  TimeFilterItemId,
  TimeFilterItem,
]): TimeFilterMenuItem => {
  return {
    id,
    type: 'label',
    label: timeFilterItem.label,
    description: getDescriptionFromTimeFilterItem(timeFilterItem.timeFilter),
  }
}

type TimeFilterSelectorProps = {
  selectedTimeFilter?: TimeFilter
  onChange: (timeFilter: TimeFilter) => void
}

export const TimeFilterSelector: React.FC<TimeFilterSelectorProps> = ({ selectedTimeFilter, onChange }) => {
  const menuItems = Object.entries(timeFilterItems).map(([id, item]) =>
    getMenuItemFromTimeFilterItem([id as TimeFilterItemId, item])
  )

  const selectedItem = menuItems.find(item => {
    const { timeFilter } = timeFilterItems[item.id]
    return JSON.stringify(timeFilter) === JSON.stringify(selectedTimeFilter)
  })

  const onSelect = (item: TimeFilterMenuItem): void => {
    const { timeFilter } = timeFilterItems[item.id]
    onChange(timeFilter)
  }

  return (
    <SingleSelectDropdown<TimeFilterItemId>
      placeholder='Today'
      menuItems={menuItems}
      selectedItem={selectedItem}
      onSelect={onSelect}
    />
  )
}
