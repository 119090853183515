import React, { forwardRef } from 'react'
import styled from 'styled-components'

const Wrap = styled.div<{ $width?: number; $height?: number }>`
  & svg {
    display: block;
    width: ${p => p.$width ?? 32}px;
    height: ${p => p.$height ?? 32}px;
  }
`

type Props = { width?: number; height?: number }
export const ZoomLogo = forwardRef<HTMLDivElement, React.HTMLAttributes<HTMLDivElement> & Props>(
  ({ width, height, ...divProps }, ref): JSX.Element => (
    <Wrap $width={width} $height={height} {...divProps} ref={ref}>
      <svg width='16' height='16' viewBox='0 0 16 16' fill='none' xmlns='http://www.w3.org/2000/svg'>
        <path
          d='M11 11.5C11 12.3284 10.3284 13 9.5 13H3C1.34314 13 0 11.6569 0 10V4.5C0 3.67163 0.67157 3 1.5 3H8C9.65686 3 11 4.34314 11 6V11.5ZM14.8 3.89991L12.6 5.54991C12.2223 5.8332 12 6.27779 12 6.74994L12 9.24994C12 9.72206 12.2223 10.1666 12.6 10.4499L14.8 12.0999C15.2944 12.4707 16 12.118 16 11.4999V4.49992C16 3.88189 15.2944 3.52909 14.8 3.89991Z'
          fill='#0B5CFF'
        />
      </svg>
    </Wrap>
  )
)
