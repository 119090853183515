import { AnimationProps, motion } from 'framer-motion'
import { MouseEventHandler, useRef } from 'react'
import { Emoji } from 'sierra-client/components/common/emoji'
import { commaSeparatedList } from 'sierra-domain/utils'
import { Tooltip } from 'sierra-ui/components'
import { palette, token } from 'sierra-ui/theming'
import styled from 'styled-components'

const Reaction = styled.button<{ highlighted: boolean }>`
  display: flex;
  align-items: center;
  height: 28px;
  padding: 0 8px 0 8px;
  background-color: ${p => (p.highlighted ? token('surface/soft') : 'transparent')};
  border-radius: 54px;

  &:hover {
    cursor: pointer;
    background-color: ${p => (p.highlighted ? token('surface/strong') : token('surface/soft'))};
  }
`

const ReactionCount = styled.span`
  font-size: 10px;
  margin-left: 6px;
  color: ${palette.primitives.black};
  font-variant-numeric: tabular-nums;
`

export type ReflectionCardReactionProps = {
  reaction: string
  count: number
  names: string[]
  highlighted: boolean
  disableAnimations?: boolean | undefined
  onClick: MouseEventHandler<HTMLButtonElement> | undefined
}

const scaleUpAnimationProps: AnimationProps = {
  initial: { scale: 1.13 },
  animate: { scale: 1 },
  transition: { duration: 0.4 },
}

const fadeUpAnimationProps: AnimationProps = {
  initial: { y: 0, scale: 1, opacity: 1, position: 'absolute' },
  animate: { y: -12, scale: 1.5, opacity: 0 },
  transition: { duration: 0.6 },
}

export const ReflectionCardReaction = ({
  reaction,
  count,
  names,
  highlighted,
  onClick,
}: ReflectionCardReactionProps): JSX.Element => {
  const originalCount = useRef(count)
  const emoji = <Emoji emojiCode={reaction} size={14} />

  return (
    <Tooltip key={reaction} title={commaSeparatedList(names)} side='top'>
      <motion.div {...scaleUpAnimationProps} key={count}>
        <Reaction highlighted={highlighted} onClick={onClick}>
          {emoji}

          {originalCount.current !== count && (
            // Avoid emoji fade-up animation on first render, otherwise it looks
            // messy when opening a page with lots of reactions.
            <motion.div {...fadeUpAnimationProps} key={count}>
              {emoji}
            </motion.div>
          )}

          <ReactionCount>{count}</ReactionCount>
        </Reaction>
      </motion.div>
    </Tooltip>
  )
}
