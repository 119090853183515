import {
  InputComponent,
  WithModalOptions,
  WrappedComponent,
  WrappedComponentWhenData,
} from 'sierra-client/views/manage/utils/with-modal/types'
import { useExtensibleClose } from 'sierra-client/views/manage/utils/with-modal/use-extensible-close'
import { Modal } from 'sierra-ui/components'

export const withModal = <P extends Record<string, unknown>>(
  { ModalComponent = Modal, ...modalProps }: WithModalOptions = {},
  Component: InputComponent<P>
): WrappedComponent<P> => {
  // Return wrapped component
  return props => {
    const { onClose, registerOnCloseHandler } = useExtensibleClose(props.onClose)

    return (
      <ModalComponent open={props.open} onClose={onClose} {...modalProps}>
        {props.open && (
          <Component
            {...props}
            onClose={onClose}
            onCloseWithoutValidation={props.onClose}
            registerOnCloseHandler={registerOnCloseHandler}
          />
        )}
      </ModalComponent>
    )
  }
}

export const withModalOpenWhenData = <P extends Record<string, unknown>>(
  { ModalComponent = Modal, ...modalProps }: WithModalOptions = {},
  Component: InputComponent<P>
): WrappedComponentWhenData<P> => {
  return ({ data, onClose: onCloseRaw }) => {
    const { onClose, registerOnCloseHandler } = useExtensibleClose(onCloseRaw)

    return (
      <ModalComponent open={data !== undefined} onClose={onClose} {...modalProps}>
        {data !== undefined && (
          <Component
            {...data}
            onClose={onClose}
            onCloseWithoutValidation={onCloseRaw}
            registerOnCloseHandler={registerOnCloseHandler}
          />
        )}
      </ModalComponent>
    )
  }
}
