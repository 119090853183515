import { useIsEnabledMultiSelectionTableCells } from 'sierra-client/views/v3-author/table/multi-select/use-is-enabled-multi-selection-table-cells'
import { useTableCellMultiSelection } from 'sierra-client/views/v3-author/table/multi-select/use-table-cell-multi-selection'

export const useHandleCellClick = (
  cellId: string,
  clearPreviewTheme: () => void
): ((e: React.MouseEvent) => void) => {
  const isMultiSelectionEnabled = useIsEnabledMultiSelectionTableCells()
  const { clearSelection, addSelection, removeSelection, containsSelection } = useTableCellMultiSelection()

  return (e: React.MouseEvent): void => {
    if (isMultiSelectionEnabled && (e.ctrlKey || e.metaKey)) {
      if (containsSelection(cellId)) {
        removeSelection(cellId)
      } else {
        addSelection(cellId)
      }
    } else {
      clearSelection()
      clearPreviewTheme()
      addSelection(cellId)
    }
  }
}
