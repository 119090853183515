import _ from 'lodash'
import { useCallback, useMemo, useRef, useState } from 'react'
import {
  HotspotContainer,
  HotspotIcon,
  MobileCloseIcon,
  SimpleHotspot,
  ViewportAwareHotspotText,
} from 'sierra-client/views/v3-author/images/hotspots/common'
import { Hotspot, Hotspots } from 'sierra-client/views/v3-author/images/hotspots/types'
import { Entity } from 'sierra-domain/entity'
import { Image as ImageBlock } from 'sierra-domain/v3-author'
import { Text } from 'sierra-ui/primitives'

const LearnerHotspot: React.FC<{ hotspot: Hotspot; setHotspotComplete?: () => void }> = ({
  hotspot,
  setHotspotComplete,
}) => {
  const ref = useRef<HTMLDivElement>(null)
  const [isOpen, setIsOpen] = useState(false)

  const onMouseEnter = useCallback(() => {
    // Ignore touch devices
    if (window.matchMedia('(pointer: coarse)').matches) return

    setIsOpen(true)
    setHotspotComplete?.()
  }, [setHotspotComplete])

  const onMouseLeave = useCallback(() => {
    // We don't have to ignore touch devices here
    setIsOpen(false)
  }, [])

  const onClick = useCallback(() => {
    setIsOpen(open => !open)
    setHotspotComplete?.()
  }, [setHotspotComplete])

  const { x, y, text } = hotspot
  return (
    <HotspotContainer ref={ref} $x={x} $y={y} isOpen={isOpen}>
      <HotspotIcon isOpen={isOpen} onMouseEnter={onMouseEnter} onMouseLeave={onMouseLeave} onClick={onClick}>
        {isOpen && <MobileCloseIcon />}
      </HotspotIcon>
      {isOpen && <ViewportAwareHotspotText parentElement={ref.current}>{text}</ViewportAwareHotspotText>}
    </HotspotContainer>
  )
}

const PDFHotspot: React.FC<{ hotspot: Hotspot; index: number }> = ({ hotspot, index }) => {
  const { x, y } = hotspot
  return (
    <HotspotContainer $x={x} $y={y} isOpen={false}>
      <SimpleHotspot>
        <Text color='LEGACY_DEFAULT_TEXT_COLOR_REPLACE_ASAP' size='small'>
          {index + 1}
        </Text>
      </SimpleHotspot>
    </HotspotContainer>
  )
}

export const LearnerHotspotsStatic: React.FC<{
  imageBlock: Entity<ImageBlock>
  hotspots: Hotspots
  isPdf?: boolean
  setHotspotComplete?: (id: string) => void
  onMouseDown?: (e: React.MouseEvent<HTMLDivElement, MouseEvent>) => void
}> = ({ imageBlock, hotspots, isPdf = false, setHotspotComplete = () => {}, onMouseDown = () => {} }) => {
  const sortedHotspots = useMemo(
    () =>
      _.chain(hotspots)
        .map((hotspot, hotspotId) => [hotspotId, hotspot] as const)
        .orderBy(([, hotspot]) => hotspot.y)
        .value(),
    [hotspots]
  )

  return (
    // This div serves no styling purpose but keeps the DOM less cluttered
    <div key={imageBlock.id} onMouseDown={onMouseDown}>
      {sortedHotspots.map(([hotspotId, hotspot], index) =>
        isPdf === true ? (
          <PDFHotspot key={hotspotId} hotspot={hotspot} index={index} />
        ) : (
          <LearnerHotspot
            key={hotspotId}
            hotspot={hotspot}
            setHotspotComplete={() => setHotspotComplete(hotspotId)}
          />
        )
      )}
    </div>
  )
}
