import { atom, useAtom, useSetAtom } from 'jotai'
import _, { intersectionWith } from 'lodash'
import { FC, useCallback, useState } from 'react'
import { useMoveCoursesMutation } from 'sierra-client/api/hooks/use-teamspace'
import { useNotif } from 'sierra-client/components/common/notifications'
import { teamspaceContentMovedLogger } from 'sierra-client/features/teamspace/logger'
import { useTranslation } from 'sierra-client/hooks/use-translation'
import { useDispatch } from 'sierra-client/state/hooks'
import { NanoId12 } from 'sierra-domain/api/nano-id'
import { MovableContent, MoveCoursesResponse } from 'sierra-domain/api/teamspace'
import { ImageUnion } from 'sierra-domain/content/v2/image-union'
import { RequestError } from 'sierra-domain/error'
import { isDefined, isNonEmptyArray } from 'sierra-domain/utils'
import { CloseModalButton, Modal, ModalHeader } from 'sierra-ui/components'
import { Button, Checkbox, ScrollView, Text, View } from 'sierra-ui/primitives'
import styled from 'styled-components'

export type TeamspaceContentToMove = {
  id: NanoId12
  title: string
  currentTeamspaceId?: NanoId12
  type: 'editable-content' | 'non-editor-content'
} & (
  | {
      courseGroup: MovableContent
    }
  | {
      image?: ImageUnion
    }
)

type Target = {
  id: NanoId12
  displayName: string
}

type Move = (
  data: {
    contentToMove: Array<TeamspaceContentToMove>
    targetTeamspace?: Target
    targetFolder?: Target
    skipContentToApprove?: boolean
  },
  callback?: { onSuccess?: () => void; onError?: (failedItems: Array<TeamspaceContentToMove>) => void }
) => void

type MoveTeamspaceContent = {
  moveTeamspaceContent: Move
}

type ConfirmModalStatus =
  | {
      open: true
      data: {
        contentToApprove: TeamspaceContentToMove[]
        approvedContent: TeamspaceContentToMove[]
        targetTeamspaceId: NanoId12 | undefined
        targetFolderId: NanoId12 | undefined
      }
      callback: {
        onSuccess?: (content: TeamspaceContentToMove[]) => void
        onError?: (e: RequestError) => void
      }
    }
  | { open: false }

const ConfirmMoveContentAtom = atom<ConfirmModalStatus>({ open: false })

export const useMoveTeamspaceContent = (): MoveTeamspaceContent => {
  const setConfirmMoveContentAtom = useSetAtom(ConfirmMoveContentAtom)
  const moveCoursesMutation = useMoveCoursesMutation()
  const notification = useNotif()
  const dispatch = useDispatch()
  const { t } = useTranslation()

  const moveTeamspaceContent = useCallback<Move>(
    (
      { contentToMove, targetTeamspace, targetFolder, skipContentToApprove = false },
      { onSuccess, onError } = {}
    ) => {
      const handleSuccess = (content: Array<TeamspaceContentToMove>): void => {
        if (isDefined(onSuccess)) {
          onSuccess()

          content.forEach(
            it =>
              void dispatch(
                teamspaceContentMovedLogger({
                  teamspaceId: targetTeamspace?.id,
                  contentId: it.id,
                  movedBy: 'importModal',
                  contentType: it.type,
                  parentFolderId: targetFolder?.id,
                })
              )
          )
        }

        const folderName = isDefined(targetFolder)
          ? ' ' + targetFolder.displayName + ' ' + t('dictionary.in') + ' '
          : ' '

        const teamspaceName = isDefined(targetTeamspace)
          ? targetTeamspace.displayName
          : t('author.content.my-content')

        notification.push({
          type: 'custom',
          level: 'info',
          body: t('teamspace.move.successful') + folderName + teamspaceName,
        })
      }

      const [readyToMove, contentToApprove] = contentToMove.reduce(
        (acc, it) => {
          // Content need approval if it is in another teamspace, so users might lose access to the content when moved
          if (it.currentTeamspaceId !== undefined && it.currentTeamspaceId !== targetTeamspace?.id) {
            acc[1].push(it)
          } else {
            acc[0].push(it)
          }
          return acc
        },
        [[], []] as [TeamspaceContentToMove[], TeamspaceContentToMove[]]
      )

      if (isNonEmptyArray(contentToApprove) && !skipContentToApprove) {
        setConfirmMoveContentAtom({
          open: true,
          data: {
            contentToApprove,
            approvedContent: readyToMove,
            targetFolderId: targetFolder?.id,
            targetTeamspaceId: targetTeamspace?.id,
          },
          callback: {
            onSuccess: (content: TeamspaceContentToMove[]) => {
              handleSuccess(content)
            },
            onError: e => {
              const response = MoveCoursesResponse.safeParse(e)
              if (response.success) {
                const failedContent = intersectionWith(
                  contentToApprove,
                  response.data.forbiddenCourseIds,
                  (a, b) => a.id === b
                )
                onError?.(failedContent)
              } else {
                throw response.error
              }
            },
          },
        })
      } else {
        moveCoursesMutation.mutate(
          {
            courseIds: readyToMove.map(it => it.id),
            targetFolderId: targetFolder?.id,
            targetTeamspaceId: targetTeamspace?.id,
          },
          {
            onSuccess: () => {
              handleSuccess(readyToMove)
            },
            onError: () => {
              // If something goes wrong we do not want to proceed to the confirmation modal
              // Since the current behaviour is that if the request fails for one course
              // nothing is moved
              setConfirmMoveContentAtom({ open: false })
              return
            },
          }
        )
      }
    },
    [dispatch, moveCoursesMutation, notification, setConfirmMoveContentAtom, t]
  )

  return {
    moveTeamspaceContent,
  }
}

const ModalWrapper = styled(View).attrs({
  direction: 'column',
  justifyContent: 'flex-start',
  padding: '24',
  paddingLeft: '32',
  grow: true,
  gap: 'none',
})`
  min-width: 450px;
  min-height: 200px;
`

const Label = styled.label`
  display: flex;
  gap: 8px;
`

const Form = styled.form`
  padding: 8px;
  display: flex;
  flex-grow: 1;
  flex-direction: column;
  justify-content: space-between;
  overflow: hidden;
`

const ModalBody: React.FC<{
  contentToApprove: TeamspaceContentToMove[]
  onClose: () => void
  onSubmit: (approvedContent: TeamspaceContentToMove[]) => void
}> = ({ contentToApprove, onClose, onSubmit }) => {
  const { t } = useTranslation()
  const [approvedContent, setApprovedContent] = useState<TeamspaceContentToMove[]>(contentToApprove)

  return (
    <Form
      onSubmit={event => {
        event.preventDefault()
        onSubmit(approvedContent)
      }}
    >
      <ScrollView grow direction='column' justifyContent='flex-start' marginTop='8' marginBottom='8'>
        {contentToApprove.map(it => {
          return (
            <Label key={it.id}>
              <Checkbox
                checked={approvedContent.some(content => content.id === it.id)}
                onCheckedChange={checked => {
                  if (checked === false) {
                    setApprovedContent(prev => _.reject(prev, { id: it.id }))
                  } else if (checked === true) {
                    setApprovedContent(prev => [...prev, it])
                  }
                }}
              />
              <Text>{it.title}</Text>
            </Label>
          )
        })}
      </ScrollView>
      <View justifyContent='flex-end'>
        <Button variant='secondary' type='button' onClick={onClose}>
          {t('admin.organization.confirm-cancel')}
        </Button>
        <Button type='submit' variant='primary' disabled={approvedContent.length === 0}>
          {t('dictionary.confirm')}
        </Button>
      </View>
    </Form>
  )
}

const ConfirmModal = styled(Modal)`
  max-height: 50%;
`

const BodyWrapper = styled(View)`
  overflow: hidden;
`

export const MoveTeamspaceContentConfirmModal: FC = () => {
  const [confirmMoveContent, setConfirmMoveContent] = useAtom(ConfirmMoveContentAtom)
  const { t } = useTranslation()
  const moveCoursesMutation = useMoveCoursesMutation()

  const handleCloseModal = useCallback(() => {
    setConfirmMoveContent({ open: false })
  }, [setConfirmMoveContent])

  const handleSubmit = useCallback(
    (approvedContent: TeamspaceContentToMove[]) => {
      if (confirmMoveContent.open) {
        moveCoursesMutation.mutate(
          {
            courseIds: approvedContent.concat(confirmMoveContent.data.approvedContent).map(it => it.id),
            targetFolderId: confirmMoveContent.data.targetFolderId,
            targetTeamspaceId: confirmMoveContent.data.targetTeamspaceId,
          },
          {
            onSuccess: () => {
              if (isDefined(confirmMoveContent.callback.onSuccess) && approvedContent.length !== 0)
                confirmMoveContent.callback.onSuccess(approvedContent)
              handleCloseModal()
            },
            onError: e => {
              confirmMoveContent.callback.onError?.(e)
              handleCloseModal()
            },
          }
        )
      }
    },
    [confirmMoveContent, handleCloseModal, moveCoursesMutation]
  )

  return (
    <ConfirmModal size={{ width: 500 }} open={confirmMoveContent.open} onClose={handleCloseModal}>
      <ModalWrapper>
        <CloseModalButton ariaLabel={t('dictionary.close')} onClick={handleCloseModal} />
        <ModalHeader alignItems='flex-start'>
          <Text size='large' bold>
            {t('teamspace.move-content.title')}
          </Text>
        </ModalHeader>

        {confirmMoveContent.open && (
          <BodyWrapper marginTop='8' direction='column'>
            <Text bold>{t('teamspace.move-content.body')}</Text>
            <ModalBody
              contentToApprove={confirmMoveContent.data.contentToApprove}
              onClose={handleCloseModal}
              onSubmit={handleSubmit}
            />
          </BodyWrapper>
        )}
      </ModalWrapper>
    </ConfirmModal>
  )
}
