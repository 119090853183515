import React from 'react'
import { useTranslation } from 'sierra-client/hooks/use-translation'
import * as settingsActions from 'sierra-client/state/author-course-settings/actions'
import { useDispatch } from 'sierra-client/state/hooks'
import { DialogIcon } from 'sierra-client/views/manage/components/dialog-utils'
import { CreateContentId } from 'sierra-domain/api/nano-id'
import { Icon, Modal } from 'sierra-ui/components'
import { Button, Text, View } from 'sierra-ui/primitives'
import styled from 'styled-components'

const IconsContainer = styled(View)`
  border-width: 0;
`

const IconContainer = styled(View)`
  height: 3.5rem;
  width: 3.5rem;
  border-radius: ${p => p.theme.borderRadius['size-12']};
  border-width: 0;
`

const ArrowContainer = styled(View)`
  width: 3rem;
  border-width: 0;
`

const InvisibleSeparator = styled.div<{ height: string }>`
  height: ${p => p.height};
`

const StyledDialogIcon = styled(DialogIcon)`
  position: absolute;
  right: 20px;
  top: 20px;
`

const Contents = styled(View).attrs({ alignItems: 'center' })`
  border-width: 0;
  text-align: center;
`
export const ConvertCourseModal: React.FC<{
  createContentId: CreateContentId
  type: string | undefined
  onClose: () => void
}> = ({ type, createContentId, onClose }) => {
  const { t } = useTranslation()
  const dispatch = useDispatch()
  return (
    <Modal onClose={onClose} open={type !== undefined} size={{ width: 575 }}>
      <StyledDialogIcon onClick={onClose} />
      <Contents direction='column' gap='none' margin='xxlarge 80'>
        <IconsContainer marginBottom='medium' gap='none'>
          {type === 'course' ? (
            <>
              <IconContainer background='grey5' justifyContent='center'>
                <Icon iconId='play--circle--filled' color='grey25' size='size-24' />
              </IconContainer>
              <ArrowContainer justifyContent='center'>
                <Icon iconId='direction--straight--right' color='foreground/secondary' />
              </ArrowContainer>
              <IconContainer background='blueBright' justifyContent='center'>
                <Icon iconId='bookmark--filled' color='white' size='size-24' />
              </IconContainer>
            </>
          ) : (
            <>
              <IconContainer background='grey5' justifyContent='center'>
                <Icon iconId='bookmark--filled' color='grey25' size='size-24' />
              </IconContainer>
              <ArrowContainer justifyContent='center'>
                <Icon iconId='direction--straight--right' color='foreground/secondary' />
              </ArrowContainer>
              <IconContainer background='redVivid' justifyContent='center'>
                <Icon iconId='play--circle--filled' color='white' size='size-24' />
              </IconContainer>
            </>
          )}
        </IconsContainer>

        <Text size='regular' bold>
          {type === 'course'
            ? t('author.convert-to-course-title')
            : type === 'live'
              ? t('author.convert-to-live-title')
              : ''}
        </Text>
        <InvisibleSeparator height='0.5rem' />
        <Text color='foreground/secondary' size='small'>
          {type === 'course'
            ? t('author.convert-to-course-info')
            : type === 'live'
              ? t('author.convert-to-live-info')
              : ''}
        </Text>
        <InvisibleSeparator height='1.5rem' />
        <View gap='xsmall'>
          <Button
            onClick={async () => {
              if (type === 'course') {
                await dispatch(
                  settingsActions.convertFlexibleContent({
                    originalFlexibleContentId: createContentId,
                    convertToType: 'native:self-paced',
                  })
                )
              }
              if (type === 'live') {
                await dispatch(
                  settingsActions.convertFlexibleContent({
                    originalFlexibleContentId: createContentId,
                    convertToType: 'native:live',
                  })
                )
              }

              onClose()
            }}
          >
            {t('author.convert')}
          </Button>
          <Button variant='secondary' onClick={onClose}>
            {t('admin.organization.confirm-cancel')}
          </Button>
        </View>
      </Contents>
    </Modal>
  )
}
