import { useTranslation } from 'sierra-client/hooks/use-translation'
import { palette } from 'sierra-ui/theming'
import styled from 'styled-components'

const UnsplashAttributionContainer = styled.div`
  font-size: 0.625rem;
  color: ${palette.primitives.white};
  text-shadow: 0 0.0625rem 0.125rem rgba(35, 37, 43, 0.3);

  a:hover {
    text-decoration: underline;
  }
`

export const UnsplashAttribution: React.FC<{
  name: string
  userName: string
}> = ({ name, userName }) => {
  const { t } = useTranslation()

  return (
    <UnsplashAttributionContainer>
      <a
        href={`https://unsplash.com/@${userName}?utm_source=sana.ai&utm_medium=referral`}
        target='_blank'
        rel='noreferrer'
      >
        {t('content.image-credit.unsplash', { name })}
      </a>
    </UnsplashAttributionContainer>
  )
}
