import { UseQueryResult, keepPreviousData } from '@tanstack/react-query'
import { useCallback, useMemo, useState } from 'react'
import { SkillLevelSettingId } from 'sierra-client/api/graphql/branded-types'
import { graphql } from 'sierra-client/api/graphql/gql'
import { SkillLevel, UserGroupType } from 'sierra-client/api/graphql/gql/graphql'
import { convertGQLAvatar } from 'sierra-client/api/graphql/util/convert-gql-avatar'
import { graphQuery, useGraphQuery } from 'sierra-client/api/hooks/use-graphql-query'
import { SubscribersTableData } from 'sierra-client/features/skills/components/tabular/skill-subscribers-tabular'
import { Avatar } from 'sierra-domain/api/manage'
import { GroupId, UserId } from 'sierra-domain/api/uuid'
import { createFilter } from 'sierra-domain/filter/datatype/filter'
import * as OP from 'sierra-domain/filter/datatype/op'
import * as PRED from 'sierra-domain/filter/datatype/pred'
import * as V from 'sierra-domain/filter/datatype/value'
import z from 'zod'

export type UserItem = {
  type: 'user'
  id: UserId
  displayName: string
  email: string
  avatar: Avatar
}

export type GroupItem = {
  type: 'group'
  id: GroupId
  displayName: string
  email: undefined
  avatar: Avatar
  groupType: UserGroupType
  membersCount: number
}

export const SkillUserToSubscribe = z.object({
  email: z.string(),
  id: UserId,
  displayName: z.string(),
  avatar: Avatar,
  targetLevel: SkillLevelSettingId.optional(),
  achievedLevelsUpTo: SkillLevelSettingId.optional(),
})

export type SkillUserToSubscribe = z.infer<typeof SkillUserToSubscribe>

type UsersAndGroupsAutoCompleteData = {
  query: string
  setQuery: (query: string) => void
  getMembersInGroup: (groupId: GroupId) => Promise<SkillUserToSubscribe[]>
  selected: SkillUserToSubscribe[]
  onSelect: (item: UserItem | GroupItem) => void
  onUnSelect: (email: string) => void
  reset: () => void
  matching: (UserItem | GroupItem)[]
}

const usersAndGroupsQuery = graphql(`
  query usersAndGroups($query: String, $filter: UserFilter) {
    users(query: $query, filter: $filter) {
      data {
        id
        email
        displayName
        avatar {
          ...AvatarFragment
        }
      }
    }
    userGroups(query: $query) {
      data {
        name
        id
        type
        avatar {
          ...AvatarFragment
        }
        membersCount
      }
    }
  }
`)

export const useUsersAndGroupsAutoComplete = (
  alreadyAssigned: SubscribersTableData[],
  open: boolean,
  skillLevels: Array<SkillLevel>
): UsersAndGroupsAutoCompleteData => {
  const [query, setQuery] = useState<string>('')
  const [selected, setSelected] = useState<SkillUserToSubscribe[]>([])

  const highestSkillLevel = skillLevels.toSorted((a, b) => b.levelSetting.index - a.levelSetting.index)[0]

  const alreadyAssignedIds = useMemo(
    () => alreadyAssigned.flatMap(td => td.rows.map(r => r.id)),
    [alreadyAssigned]
  )

  const reset = useCallback(() => {
    setQuery('')
    setSelected([])
  }, [])

  const matching: UseQueryResult<(UserItem | GroupItem)[], unknown> = useGraphQuery(
    {
      document: usersAndGroupsQuery,
      queryOptions: {
        enabled: open,
        placeholderData: keepPreviousData,
        select: data => {
          const matchingUsers: UserItem[] = data.users.data.map(u => ({
            type: 'user',
            id: u.id,
            displayName: u.displayName,
            email: u.email,
            avatar: convertGQLAvatar(u.avatar),
          }))

          // filter out already assigned and selected users
          const filteredUsers: UserItem[] =
            alreadyAssignedIds.length === 0
              ? matchingUsers
              : matchingUsers.filter(
                  m => !alreadyAssignedIds.some(id => id === m.id) && !selected.some(s => s.id === m.id)
                )

          const matchingGroups: GroupItem[] = data.userGroups.data.map(g => ({
            type: 'group',
            id: g.id,
            displayName: g.name,
            email: undefined,
            avatar: convertGQLAvatar(g.avatar),
            groupType: g.type,
            membersCount: g.membersCount,
          }))

          return [...filteredUsers, ...matchingGroups]
        },
      },
    },
    {
      query: query,
      filter: JSON.stringify(
        createFilter(
          { type: 'user.type' },
          OP.NEquals,
          PRED.And([V.stringValue('guest'), V.stringValue('scorm')])
        )
      ),
    }
  )

  const getMembersInGroup = useCallback<UsersAndGroupsAutoCompleteData['getMembersInGroup']>(
    async groupId => {
      const result = await graphQuery(
        graphql(`
          query groupMembers($groupId: UserGroupId!) {
            members(id: $groupId) {
              cursor
              data {
                user {
                  id
                  email
                  displayName
                  avatar {
                    ...AvatarFragment
                  }
                }
              }
            }
          }
        `),
        { groupId }
      )

      const selectedGroupUsers: SkillUserToSubscribe[] = result.members.data.map(u => ({
        type: 'user',
        id: u.user.id,
        displayName: u.user.displayName,
        email: u.user.email,
        avatar: convertGQLAvatar(u.user.avatar),
        targetLevel: highestSkillLevel?.levelSetting.id,
        achievedLevels: [],
      }))

      return selectedGroupUsers
    },
    [highestSkillLevel]
  )

  const onSelect = useCallback(
    async (item: UserItem | GroupItem) => {
      if (item.type === 'group') {
        const groupMembersToSubscribe: SkillUserToSubscribe[] = await getMembersInGroup(item.id)
        setSelected(currSelected => [...currSelected, ...groupMembersToSubscribe])
      } else {
        setSelected(currSelected => [
          ...currSelected,
          {
            ...item,
            targetLevel: highestSkillLevel?.levelSetting.id,
            achievedLevels: [],
          },
        ])
      }
    },
    [getMembersInGroup, highestSkillLevel]
  )

  const onUnSelect = useCallback(
    //we use email to filter to not have to handle overriden ids in UsersForm
    async (email: string) => {
      const newSelected = selected.filter(s => s.email !== email)
      setSelected(newSelected)
    },
    [selected]
  )

  return {
    query,
    setQuery,
    matching: matching.data ?? [],
    getMembersInGroup,
    selected,
    onSelect,
    onUnSelect,
    reset,
  }
}
