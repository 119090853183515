import React from 'react'
import { mapOneDimensionalData } from 'sierra-client/features/insights/display-widgets/data-utils'
import { getPercentage } from 'sierra-client/features/insights/display-widgets/value-utils'
import { useTranslation } from 'sierra-client/hooks/use-translation'
import { ProgressBarWidget as ProgressBarWidgetType, TableResult } from 'sierra-domain/api/insights'
import { ProgressBar } from 'sierra-ui/missions/delphi/charts/progress-bar'
import { View } from 'sierra-ui/primitives'
import styled from 'styled-components'

const ProgressBarOuterContainer = styled(View).attrs({ direction: 'row', justifyContent: 'center' })`
  height: 100%;
`

const ProgressBarInnerContainer = styled(View)<{ previewMode: boolean }>`
  height: 100%;
  width: ${props => (props.previewMode ? '60%' : '100%')};
`

type ProgressBarWidgetProps = {
  tableResult: TableResult
  widget: ProgressBarWidgetType
  previewMode: boolean
}
export const ProgressBarWidget: React.FC<ProgressBarWidgetProps> = ({ tableResult, widget, previewMode }) => {
  const { t } = useTranslation()
  const data = mapOneDimensionalData(tableResult, t, widget)

  if (data.type !== 'type.progress') {
    throw Error('ProgressBarWidget only supports type.progress')
  }

  return (
    <ProgressBarOuterContainer>
      <ProgressBarInnerContainer previewMode={previewMode}>
        <ProgressBar progress={data.value} progressLabel={getPercentage(data.value)} label={data.label} />
      </ProgressBarInnerContainer>
    </ProgressBarOuterContainer>
  )
}
