import { useAtomValue } from 'jotai'
import { useCallback, useEffect, useState } from 'react'
import { SelfPacedPublishState } from 'sierra-client/api/hooks/use-self-paced-publish-state'
import { Link } from 'sierra-client/components/common/link'
import { useNotif } from 'sierra-client/components/common/notifications'
import { AssignCourseParticipants } from 'sierra-client/components/sharing/tabs/components/assign-course-participants'
import { AssignLiveParticipants } from 'sierra-client/components/sharing/tabs/components/assign-live-participants'
import { AssignTabFooter } from 'sierra-client/components/sharing/tabs/components/assign-tab-footer'
import {
  BottomContainer,
  TabRootContainer,
} from 'sierra-client/components/sharing/tabs/components/containers'
import { TabTop } from 'sierra-client/components/sharing/tabs/components/tab-top'
import { SharingModalContent } from 'sierra-client/components/sharing/types'
import { SHARING_CONTENT_HELP_PAGE_URL } from 'sierra-client/config/links'
import { coursePermissionUpdated } from 'sierra-client/core/logging/authoring/logger'
import { useContentKindPermissions } from 'sierra-client/hooks/use-permissions'
import { usePost } from 'sierra-client/hooks/use-post'
import { useTranslation } from 'sierra-client/hooks/use-translation'
import { useDispatch } from 'sierra-client/state/hooks'
import { useCreatePageYDocContext } from 'sierra-client/views/flexible-content/create-page-context'
import {
  CoursePermissionSettings,
  useCoursePermissionSettings,
} from 'sierra-client/views/flexible-content/editor/course-permission-settings-context'
import { derivePublishButtonState } from 'sierra-client/views/flexible-content/editor/topbar/self-paced-content-topbar'
import { XRealtimeAuthorPublishSelfPacedCourse } from 'sierra-domain/routes'
import { assertNever, iife } from 'sierra-domain/utils'
import { Icon } from 'sierra-ui/components'
import { Button, Text, View } from 'sierra-ui/primitives'
import { token } from 'sierra-ui/theming'
import styled from 'styled-components'

const MessageText = styled(Text).attrs({ color: 'foreground/muted' })`
  text-align: center;
`

const InformationBoxWrapper = styled(View)`
  position: absolute;
  bottom: 16px;
  right: 8px;
  left: 16px;
  background-color: ${token('surface/soft')};
  border-radius: 8px;
  align-items: center;
  justify-content: center;
  padding: 8px 0;
`
const VisibilityHelpLink = styled(Link)`
  color: ${token('foreground/primary')};
`

const InformationBox: React.FC = () => {
  const { t } = useTranslation()
  return (
    <InformationBoxWrapper grow>
      <MessageText size='micro'>
        {t('assign.content.visible-sub-description')}{' '}
        <VisibilityHelpLink target='_blank' href={SHARING_CONTENT_HELP_PAGE_URL} size='micro' bold>
          {t('content.learn-more')}
        </VisibilityHelpLink>
      </MessageText>
    </InformationBoxWrapper>
  )
}

const MessageTextWrapper = styled(View)`
  max-width: 45ch;
  margin-top: 16px;
  margin-bottom: 22px;
`

type NotPublishedMessageProps = {
  content: SharingModalContent
  publishState: SelfPacedPublishState
}

function visibleCourse(settings: CoursePermissionSettings): boolean {
  return (
    settings.status === 'done' &&
    (settings.visibilityInOrg === 'visible-everyone' || settings.visibilityInOrg === 'visible-admins')
  )
}

const NotPublishedMessage: React.FC<NotPublishedMessageProps> = ({ content, publishState }) => {
  const { postWithUserErrorException } = usePost()
  const { t } = useTranslation()
  const notifications = useNotif()
  const { updateVisibilityInOrg, coursePermissionSettings } = useCoursePermissionSettings()
  const dispatch = useDispatch()
  const coursePermissions = useContentKindPermissions(content.id)

  const { latestYUpdateIdAtom } = useCreatePageYDocContext()
  const latestYUpdateId = useAtomValue(latestYUpdateIdAtom)
  const publishButtonState = derivePublishButtonState(publishState.state, latestYUpdateId)
  const isPublishStateDraft = publishButtonState === 'draft'

  const visible = visibleCourse(coursePermissionSettings)
  const isAllowedToUpdateSettings =
    (content.type === 'course' && isPublishStateDraft ? coursePermissions.has('EDIT_PUBLISHED') : true) &&
    (visible || coursePermissions.has('EDIT_SHARING_SETTINGS'))

  const onContinue = useCallback(async () => {
    if (content.type === 'course' && isPublishStateDraft) {
      await postWithUserErrorException(XRealtimeAuthorPublishSelfPacedCourse, {
        contentId: content.id,
      })
      await publishState.refresh()
      notifications.push({ type: 'course-published' })
    }

    void updateVisibilityInOrg(content.id, 'visible-admins')
    void dispatch(
      coursePermissionUpdated({
        contentId: content.id,
        contentType: content.type === 'course' ? 'self-paced' : 'live',
        coursePermissionType: 'visibilityInOrg',
        coursePermissionUpdate: 'visible-admins',
      })
    )
    notifications.push({ type: 'visibility-updated' })
  }, [
    content.type,
    content.id,
    isPublishStateDraft,
    updateVisibilityInOrg,
    dispatch,
    notifications,
    postWithUserErrorException,
    publishState,
  ])

  const titleText = !isAllowedToUpdateSettings
    ? t('assign.content.not-available')
    : content.type === 'course' && isPublishStateDraft
      ? t('assign.content.publish-and-visible')
      : t('assign.content.visible')
  return (
    <>
      <View direction='column' justifyContent='center' alignItems='center' gap='none' marginBottom='80'>
        {isAllowedToUpdateSettings ? (
          <Icon iconId='building' size='size-16' />
        ) : (
          <Icon iconId='locked' size='size-16' color='foreground/muted' />
        )}
        <MessageTextWrapper direction='column' justifyContent='center' alignItems='center' gap='2'>
          <Text bold>{titleText}</Text>
          <MessageText>
            {isAllowedToUpdateSettings
              ? t('assign.content.visible-description')
              : t('assign.content.not-available-description')}
          </MessageText>
        </MessageTextWrapper>
        {isAllowedToUpdateSettings && <Button onClick={onContinue}>{t('dictionary.continue')}</Button>}
      </View>
      <InformationBox />
    </>
  )
}

type AssignTabProps = {
  content: SharingModalContent
  publishState: SelfPacedPublishState
  onClose: () => void
  canEditAssignments: boolean
}

type TabState = 'unpublished' | 'visible' | 'nonVisible' | 'loading'

export const AssignTab: React.FC<AssignTabProps> = ({
  content,
  publishState,
  onClose,
  canEditAssignments,
}) => {
  const { t } = useTranslation()
  const [tabState, setTabState] = useState<TabState>('loading')
  const { coursePermissionSettings } = useCoursePermissionSettings()

  const visible = visibleCourse(coursePermissionSettings)

  useEffect(() => {
    if (content.type === 'course') {
      if (publishState.state === undefined) {
        setTabState('loading')
      } else if (!publishState.state.published) {
        setTabState('unpublished')
      } else if (visible) {
        setTabState('visible')
      } else {
        setTabState('nonVisible')
      }
    } else {
      setTabState(visible ? 'visible' : 'nonVisible')
    }
  }, [content.type, publishState.state, visible])

  return (
    <TabRootContainer>
      <TabTop
        title={
          tabState === 'visible'
            ? t('sharing-modal.assign-content', {
                content: content.title,
              })
            : ''
        }
        onClose={onClose}
      />
      <BottomContainer>
        {iife(() => {
          switch (tabState) {
            case 'visible':
              return (
                <View direction='column' justifyContent='space-between'>
                  {content.type === 'course' ? (
                    <AssignCourseParticipants content={content} canEditAssignments={canEditAssignments} />
                  ) : (
                    <AssignLiveParticipants content={content} canEditAssignments={canEditAssignments} />
                  )}
                  <AssignTabFooter />
                </View>
              )
            case 'unpublished':
            case 'nonVisible':
              return <NotPublishedMessage content={content} publishState={publishState} />
            case 'loading':
              return null
            default:
              assertNever(tabState)
          }
        })}
      </BottomContainer>
    </TabRootContainer>
  )
}
