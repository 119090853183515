import { useIsDebugMode } from 'sierra-client/hooks/use-is-debug-mode'
import { useToggle } from 'sierra-client/hooks/use-toggle'
import { useDispatch, useSelector } from 'sierra-client/state/hooks'
import { addIssue, clearAllIssues, clearIssue } from 'sierra-client/state/live/actions'
import { ISSUE_INFORMATION } from 'sierra-client/state/live/live-issue-information'
import { selectAllCurrentIssues } from 'sierra-client/state/live/selectors'
import { Button, Checkbox, IconButton, Text, View } from 'sierra-ui/primitives'
import { DarkTokenProvider, zIndex } from 'sierra-ui/theming'
import styled from 'styled-components'

const SimulationContainer = styled.div`
  max-height: 300px;
  overflow: auto;
  background: black;
  border-radius: 6px;
  display: flex;
  flex-direction: column;
  position: fixed;
  right: 8px;
  gap: 4px;
  bottom: 72px;
  z-index: ${zIndex.ON_MODAL};

  button {
    padding: 4px;
    border-radius: 6px;
    background: black;
    color: white;
    cursor: pointer;
    &:hover {
      opacity: 0.75;
    }
  }
`

export const IssueControl: React.FC = () => {
  const dispatch = useDispatch()
  const issues = new Set(useSelector(selectAllCurrentIssues).map(issue => issue.name))

  function clearIssues(): void {
    dispatch(clearAllIssues())
  }

  return (
    <>
      {Object.values(ISSUE_INFORMATION).map(issue => (
        <View key={issue.name} padding='4'>
          <Text>{issue.name}</Text>
          <Checkbox
            onCheckedChange={() => {
              if (issues.has(issue.name)) {
                dispatch(clearIssue(issue.name))
              } else {
                dispatch(addIssue(issue.name))
              }
            }}
            checked={issues.has(issue.name)}
          />
        </View>
      ))}

      <Button onClick={clearIssues}>Clear issues</Button>
    </>
  )
}

const SimulateHardwareError: React.FC = () => {
  const [isExpanded, { toggle }] = useToggle()

  return (
    <DarkTokenProvider>
      <SimulationContainer>
        {isExpanded ? (
          <>
            <IssueControl />
            <Button onClick={toggle}>Close</Button>
          </>
        ) : (
          <IconButton iconId='settings' onClick={toggle} />
        )}
      </SimulationContainer>
    </DarkTokenProvider>
  )
}

export const SimulateHardwareErrorDebug: React.FC = () => {
  const isDebug = useIsDebugMode()

  if (isDebug) return <SimulateHardwareError />
  return null
}
