import React from 'react'
import { CheckSession } from 'sierra-client/state/user/plugins/check-session'
import { CheckShouldForceHardNavigation } from 'sierra-client/state/user/plugins/check-should-force-hard-navigation'
import { CourseContentFetcher } from 'sierra-client/state/user/plugins/course-content-fetcher'
import { PathContentFetcher } from 'sierra-client/state/user/plugins/path-content-fetcher'
import { ProgramStateFetcher } from 'sierra-client/state/user/plugins/program-state-fetcher'
import { RouteWatcher } from 'sierra-client/state/user/plugins/route-watcher'
import { V4Redirect } from 'sierra-client/state/user/plugins/v4-redirect'

/**
 * These plugins observe state changes and perform side effects accordingly.
 * For example, one of them watches the current route and sets the current location
 * in Redux accordingly.
 */

export const StatePlugins: React.FC = React.memo(() => {
  return (
    <>
      <CheckSession />
      <CheckShouldForceHardNavigation />
      <CourseContentFetcher />
      <PathContentFetcher />
      <ProgramStateFetcher />
      <RouteWatcher />
      <V4Redirect />
    </>
  )
})
