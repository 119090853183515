import { FC, useState } from 'react'
import { ActionModal } from 'sierra-client/components/common/modals/action-modal'
import { useOrganizationCanSendEmails } from 'sierra-client/hooks/use-organization-can-send-emails'
import { useTranslation } from 'sierra-client/hooks/use-translation'
import { useCachedQuery } from 'sierra-client/state/api'
import { useSelector } from 'sierra-client/state/hooks'
import { selectUserOrganizationId } from 'sierra-client/state/user/user-selector'
import { GetEmailConfigResponse } from 'sierra-domain/api/notification-channels'
import {
  EmailSettings,
  NotificationState,
  PatchOrgNotificationSettingsRequest,
} from 'sierra-domain/api/org-notification-settings'
import { XRealtimeAdminNotificationChannelsEmailsGetConfig } from 'sierra-domain/routes'
import { Button, Switch, Text, View } from 'sierra-ui/primitives'
import { token } from 'sierra-ui/theming'
import styled from 'styled-components'

const ApplicationIcon = styled.div`
  width: 48px;
  height: 48px;
  padding: 0.5rem;
  display: flex;
  justify-content: center;
  flex-direction: row;
  align-items: center;
  border-radius: 12px;
  border: 0.8px solid ${token('border/strong')};
  flex-shrink: 0;
`

type EmailIconProps = {
  disabled: boolean
}

const EmailIcon: FC<EmailIconProps> = ({ disabled = false }) => {
  const fill = disabled ? '#E6E6E6' : '#14AAFF'
  return (
    <svg width='32' height='32' viewBox='0 0 32 32' fill='none' xmlns='http://www.w3.org/2000/svg'>
      <rect width='32' height='32' rx='16' fill={fill} />
      <path
        d='M16.0949 17.0101C16.3711 17.0101 16.6389 16.9012 16.8984 16.6669L25.2606 9.30081C24.8086 8.99947 24.2896 8.86554 23.5698 8.86554H8.62835C7.90849 8.86554 7.38951 8.99947 6.92913 9.30081L15.2913 16.6669C15.5591 16.9012 15.827 17.0101 16.0949 17.0101ZM6.21763 21.9403L12.4118 15.7545L6.20089 10.2802C6.10882 10.4559 6 10.8828 6 11.4604V20.7015C6 21.2372 6.08371 21.6222 6.21763 21.9403ZM8.37723 23.2963H23.8209C24.4319 23.2963 24.9091 23.1457 25.2271 22.928L18.8571 16.558L17.6351 17.6462C17.1496 18.0731 16.6222 18.2907 16.0949 18.2907C15.5759 18.2907 15.0486 18.0731 14.5631 17.6462L13.3409 16.558L6.97099 22.928C7.28906 23.1457 7.75781 23.2963 8.37723 23.2963ZM25.9805 21.9403C26.106 21.6222 26.1981 21.2372 26.1981 20.7015V11.4604C26.1981 10.8828 26.0893 10.4559 25.9972 10.2802L19.7863 15.7545L25.9805 21.9403Z'
        fill='white'
      />
    </svg>
  )
}

const HorizontalDivider = styled.hr`
  border: 0;
  border-top: 1px solid ${token('border/default')};
  margin: 24px 0 18px;
`

export const useEmailConfig = (): {
  config: GetEmailConfigResponse | undefined
} => {
  const { data } = useCachedQuery(XRealtimeAdminNotificationChannelsEmailsGetConfig, {})

  const config = data

  return {
    config,
  }
}

export const EmailNotificationsToggle = ({
  emailSettings,
  onChange,
}: {
  emailSettings: EmailSettings
  onChange: (_: PatchOrgNotificationSettingsRequest) => void
}): JSX.Element => {
  const organizationId = useSelector(selectUserOrganizationId)
  const [showDeleteConfirm, setShowDeleteConfirm] = useState<boolean>(false)
  const { t } = useTranslation()

  const { config } = useEmailConfig()

  const organizationCanSendEmails = useOrganizationCanSendEmails()

  if (organizationId === undefined) {
    return <></>
  }

  const enabled = emailSettings.state === NotificationState.Enabled && organizationCanSendEmails

  const onToggleEmailNotifications = (newValue: NotificationState): void => {
    onChange({
      emailSettings: {
        ...emailSettings,
        state: newValue,
      },
    })
  }

  const onAuthorize = (consentUrl: string): void => {
    window.location.href = consentUrl
  }

  const emailDisabled = organizationCanSendEmails === false

  return (
    <>
      <View justifyContent='space-between' grow gap='16'>
        <View alignItems='center' gap='16'>
          <ApplicationIcon>
            <EmailIcon disabled={emailDisabled} />
          </ApplicationIcon>
          <Text size='small' bold align='center' color={emailDisabled ? 'foreground/muted' : undefined}>
            {t('manage.settings.email-notifications')}
          </Text>
        </View>
        <Switch
          disabled={emailDisabled}
          checked={enabled}
          onChange={() =>
            enabled ? setShowDeleteConfirm(true) : onToggleEmailNotifications(NotificationState.Enabled)
          }
          ariaLabel={t('manage.settings.email-notifications')}
        />
      </View>

      {emailDisabled && (
        <>
          <HorizontalDivider />
          <Text size='small' color='foreground/muted'>
            {t('manage.settings.email-notifications.disabled-by-flags.description')}
          </Text>
        </>
      )}

      {config && config.type === 'smtp/oauth/microsoft' && (
        <>
          <HorizontalDivider />
          <Button variant='secondary' onClick={() => onAuthorize(config.consentUrl)}>
            {t('dictionary.authorize')}
          </Button>
        </>
      )}

      <ActionModal
        open={showDeleteConfirm}
        onClose={() => {
          setShowDeleteConfirm(false)
        }}
        primaryAction={() => onToggleEmailNotifications(NotificationState.Disabled)}
        title={t('manage.settings.email-notifications.disable-notifications.title')}
        primaryActionLabel={t('manage.settings.email-notifications.disable-notifications.confirm')}
        deleteAction
      >
        <Text>{t('manage.settings.email-notifications.disable-notifications.description')}</Text>
      </ActionModal>
    </>
  )
}
