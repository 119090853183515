import { Editor, Path } from 'slate'

/**
 * We currently do not have a good way of finding out if the question
 * is an inline question, beyond checking if there are multiple elements
 * at the root level. In a question-card, there will be just one element at the root.
 */
export function isInline({ editor }: { editor: Editor }): boolean {
  return editor.children.length > 1
}

export function isNotInline(options: { editor: Editor; path: Path }): boolean {
  return !isInline(options)
}
