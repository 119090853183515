import { HomeImpressionProps } from 'sierra-client/core/logging/course/logger'
import { Logger } from 'sierra-client/core/logging/logger'
import { selectCurrentLiveSessionId } from 'sierra-client/state/content/selectors'
import { selectIsFacilitator } from 'sierra-client/state/live-session/selectors'
import { RootState } from 'sierra-client/state/types'
import { NanoId12 } from 'sierra-domain/api/nano-id'

export type HomeLiveSessionImpressionProps = HomeImpressionProps & {
  liveSessionId: string
}

type LiveSessionJoinedProps = {
  liveSessionId: string
  sessionTitle: string
}

type CommonProps = { liveSessionId?: string; isFacilitator: boolean }

const getCommonTrackingProps = (state: RootState): CommonProps => ({
  liveSessionId: selectCurrentLiveSessionId(state),
  isFacilitator: selectIsFacilitator(state),
})

export const liveSessionJoined = Logger.trackLoggerWithExtra<LiveSessionJoinedProps, LiveSessionJoinedProps>(
  'live_session_joined',
  (input, state) => {
    return {
      ...input,
      ...getCommonTrackingProps(state),
    }
  }
)

type BugReportedProps = {
  bugCategory: string
  sessionTitle: string
}

export const bugReported = Logger.trackLoggerWithExtra<BugReportedProps, BugReportedProps>(
  'live_session_bug_reported',
  (input, state) => {
    return {
      ...input,
      ...getCommonTrackingProps(state),
    }
  }
)

type CardViewed = {
  cardType: string
}

export const cardViewed = Logger.trackLoggerWithExtra<CardViewed, CardViewed>(
  'live_session_card_viewed',
  (input, state) => {
    return {
      ...input,
      ...getCommonTrackingProps(state),
    }
  }
)

export const leaveButtonPressed = Logger.trackLoggerWithExtra(
  'live_session_leave_button_pressed',
  (_input, state) => {
    return {
      ...getCommonTrackingProps(state),
    }
  }
)

export const leaveButtonLeaveConfirmed = Logger.trackLoggerWithExtra(
  'live_session_leave_button_leave_confirmed',
  (_input, state) => {
    return {
      ...getCommonTrackingProps(state),
    }
  }
)

export const leaveButtonEndSessionConfirmed = Logger.trackLoggerWithExtra(
  'live_session_leave_button_end_session_confirmed',
  (_input, state) => {
    return {
      ...getCommonTrackingProps(state),
    }
  }
)

export const leaveButtonCanceled = Logger.trackLoggerWithExtra(
  'live_session_leave_canceled',
  (_input, state) => {
    return {
      ...getCommonTrackingProps(state),
    }
  }
)

type PostSessionPageViewedArgs = {
  pageVersion: 'thank-you-page' | 'session-summary-page'
}

export const postSessionPageViewed = Logger.trackLoggerWithExtra<
  PostSessionPageViewedArgs,
  PostSessionPageViewedArgs
>('live_session_post_session_page_viewed', (input, state) => {
  return {
    ...input,
    ...getCommonTrackingProps(state),
  }
})

export const postSessionCreatePushModalViewed = Logger.trackLoggerWithExtra(
  'live_session_post_session_create_push_modal_viewed',
  (input, state) => {
    return {
      ...input,
      ...getCommonTrackingProps(state),
    }
  }
)

export const postSessionCreatePushModalDismissed = Logger.trackLoggerWithExtra(
  'live_session_post_session_create_push_modal_dismissed',
  (input, state) => {
    return {
      ...input,
      ...getCommonTrackingProps(state),
    }
  }
)

export const postSessionCreatePushModalTemplateClicked = Logger.trackLoggerWithExtra(
  'live_session_post_session_create_push_modal_template_clicked',
  (input, state) => {
    return {
      ...input,
      ...getCommonTrackingProps(state),
    }
  }
)

export const postSessionCreatePushModalCreateButtonClicked = Logger.trackLoggerWithExtra(
  'live_session_post_session_create_push_modal_create_button_clicked',
  (input, state) => {
    return {
      ...input,
      ...getCommonTrackingProps(state),
    }
  }
)

export const pictureInPictureEnabled = Logger.trackLoggerWithExtra(
  'live_session_picture_in_picture_enabled',
  (input, state) => {
    return {
      ...input,
      ...getCommonTrackingProps(state),
    }
  }
)

export const pictureInPictureDisabled = Logger.trackLoggerWithExtra(
  'live_session_picture_in_picture_disabled',
  (input, state) => {
    return {
      ...input,
      ...getCommonTrackingProps(state),
    }
  }
)

export const pictureInPictureSettingToggled = Logger.trackLoggerWithExtra<
  { enabled: boolean },
  { enabled: boolean }
>('live_session_picture_in_picture_setting_toggled', (input, state) => {
  return {
    ...input,
    ...getCommonTrackingProps(state),
  }
})

export const pushToTalkSettingToggled = Logger.trackLoggerWithExtra<
  { enabled: boolean },
  { enabled: boolean }
>('live_session_push_to_talk_setting_toggled', (input, state) => {
  return {
    ...input,
    ...getCommonTrackingProps(state),
  }
})

export const followMeEnabled = Logger.trackLoggerWithExtra(
  'live_session_follow_me_enabled',
  (input, state) => {
    return {
      ...input,
      ...getCommonTrackingProps(state),
    }
  }
)

export const followMeUserCancelled = Logger.trackLoggerWithExtra(
  'live_session_follow_me_user_cancelled',
  (input, state) => {
    return {
      ...input,
      ...getCommonTrackingProps(state),
    }
  }
)

export const participantPinned = Logger.trackLoggerWithExtra(
  'live_session_participant_pinned',
  (input, state) => {
    return {
      ...input,
      ...getCommonTrackingProps(state),
    }
  }
)

export const participantUnpinned = Logger.trackLoggerWithExtra(
  'live_session_participant_unpinned',
  (input, state) => {
    return {
      ...input,
      ...getCommonTrackingProps(state),
    }
  }
)

export const recordingStarted = Logger.trackLoggerWithExtra(
  'live_session_recording_started',
  (input, state) => {
    return {
      ...input,
      ...getCommonTrackingProps(state),
    }
  }
)

export const facilitatorAdded = Logger.trackLoggerWithExtra(
  'live_session_facilitator_added',
  (input, state) => {
    return {
      ...input,
      ...getCommonTrackingProps(state),
    }
  }
)

export const facilitatorRemoved = Logger.trackLoggerWithExtra(
  'live_session_facilitator_removed',
  (input, state) => {
    return {
      ...input,
      ...getCommonTrackingProps(state),
    }
  }
)

export const timerStarted = Logger.trackLoggerWithExtra('live_session_timer_started', (input, state) => {
  return {
    ...input,
    ...getCommonTrackingProps(state),
  }
})

export const noiseCancellationEnabled = Logger.trackLoggerWithExtra(
  'live_session_noise_cancellation_enabled',
  (input, state) => {
    return {
      ...input,
      ...getCommonTrackingProps(state),
    }
  }
)

export const backgroundBlurEnabled = Logger.trackLoggerWithExtra(
  'live_session_background_blur_enabled',
  (input, state) => {
    return {
      ...input,
      ...getCommonTrackingProps(state),
    }
  }
)

export const backgroundBlurToggled = Logger.trackLoggerWithExtra<{ enabled: boolean }, { enabled: boolean }>(
  'live_session_background_blur_toggled',
  (input, state) => {
    return {
      ...input,
      ...getCommonTrackingProps(state),
    }
  }
)

export const noiseCancellationToggled = Logger.trackLoggerWithExtra<
  { enabled: boolean },
  { enabled: boolean }
>('live_session_noise_cancellation_toggled', (input, state) => {
  return {
    ...input,
    ...getCommonTrackingProps(state),
  }
})

export const instantSessionStarted = Logger.trackLoggerWithExtra(
  'live_session_instant_session_started',
  input => {
    return {
      ...input,
    }
  }
)

export const helpClicked = Logger.trackLoggerWithExtra('live_session_help_clicked', (input, state) => {
  return {
    ...input,
    ...getCommonTrackingProps(state),
  }
})

type WatchedRecordingArgs = {
  context: 'summary' | 'recap' | 'manage'
  recordingId: string
}

export const watchedRecording = Logger.trackLoggerWithExtra<WatchedRecordingArgs, WatchedRecordingArgs>(
  'live_session_watched_recording',
  input => {
    return {
      ...input,
    }
  }
)

export const autoCloseSessionModalTimedOut = Logger.trackLoggerWithExtra(
  'live_session_auto_close_session_modal_timed_out',
  (_input, state) => {
    return {
      ...getCommonTrackingProps(state),
    }
  }
)

export const autoCloseSessionModalConfirmed = Logger.trackLoggerWithExtra(
  'live_session_auto_close_session_modal_confirmed',
  (_input, state) => {
    return {
      ...getCommonTrackingProps(state),
    }
  }
)

export const autoCloseSessionModalRejected = Logger.trackLoggerWithExtra(
  'live_session_auto_close_session_modal_rejected',
  (_input, state) => {
    return {
      ...getCommonTrackingProps(state),
    }
  }
)

type SanaNowTrackignProps = {
  liveSessionId: NanoId12
}

const NOW_SESSION_PREFIX = `sana_now_live_session`
export const sanaNowSessionJoined = Logger.trackLoggerWithExtra<SanaNowTrackignProps, SanaNowTrackignProps>(
  `${NOW_SESSION_PREFIX}_session_joined`,
  (input, _state) => {
    return {
      ...input,
    }
  }
)

export const sanaNowPresentClicked = Logger.trackLoggerWithExtra<
  SanaNowTrackignProps & {
    contentId: NanoId12
  },
  SanaNowTrackignProps & {
    contentId: NanoId12
  }
>(`${NOW_SESSION_PREFIX}_present_clicked`, (input, _state) => {
  return {
    ...input,
  }
})

export const sanaNowMeetingLinkGenerated = Logger.trackLoggerWithExtra<
  {
    meetingService: string
  },
  {
    meetingService: string
  }
>(`${NOW_SESSION_PREFIX}_meeting_link_generated`, (input, _state) => {
  return {
    ...input,
  }
})

export const sanaNowShareModalOpened = Logger.trackLoggerWithExtra<
  SanaNowTrackignProps,
  SanaNowTrackignProps
>(`${NOW_SESSION_PREFIX}_share_modal_opened`, (input, _state) => {
  return {
    ...input,
  }
})
