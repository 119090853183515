import { motion } from 'framer-motion'
import { useCallback, useRef } from 'react'
import { tiptapContentToString } from 'sierra-client/components/chat/tiptap'
import { useRightSidebarContext } from 'sierra-client/components/liveV2/live-sidebar-provider'
import { useUserLegacy } from 'sierra-client/state/users/hooks'
import { UserId } from 'sierra-domain/api/uuid'
import { Message } from 'sierra-domain/chat'
import { ColorName } from 'sierra-ui/color/types'
import { Text } from 'sierra-ui/primitives'
import { token } from 'sierra-ui/theming'
import styled from 'styled-components'

const ChatMessageContainer = styled(motion.div)<{
  $size: string
  $chatMessagePosition: string
  $color: ColorName
}>`
  display: flex;
  align-items: center;
  gap: 8px;
  background-color: ${p => p.theme.color[p.$color]};
  color: ${token('foreground/primary')};
  border-radius: 2rem;
  padding: 0.3125rem 0.75rem;
  position: absolute;
  max-width: ${props => (props.$size === 'large' ? 'calc(100% - 1rem)' : '250px')};
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
  cursor: pointer;
  z-index: 2;
  transform-origin: middle;

  bottom: ${p =>
    ({
      left: '50%',
      right: '50%',
      bottom: '3.2rem',
    })[p.$chatMessagePosition]};

  transform: ${p =>
    ({
      left: 'translateY(50%) !important',
      right: 'translateY(50%) !important',
      top: 'translateX(50%) !important',
      bottom: 'translate(50%, 50%) !important',
    })[p.$chatMessagePosition]};

  right: ${p =>
    ({
      left: 'calc(100% - 0.5rem)',
      top: '50%',
      bottom: '50%',
    })[p.$chatMessagePosition]};

  left: ${p =>
    ({
      right: 'calc(100% - 0.5rem)',
    })[p.$chatMessagePosition]};
`

const ChatUserContainer = styled.div`
  background-color: rgba(0, 0, 0, 0.1);
  color: white;
  border-radius: 0.5rem;
  display: flex;
  gap: 0.5rem;
  align-items: center;
  padding: 4px 6px;
`

export type ChatMessagePosition = 'top' | 'bottom' | 'left' | 'right'

type ChatMessageTextProps = {
  message: Message
  size?: 'small' | 'large'
  chatMessagePosition: ChatMessagePosition
  showSender?: boolean
}

const ChatMessageSender: React.FC<{ userId: UserId }> = ({ userId }) => {
  const user = useUserLegacy(userId)
  return (
    <ChatUserContainer>
      <Text size='micro' color='white' bold>
        {user?.firstName}
      </Text>
    </ChatUserContainer>
  )
}

export const ChatMessageText: React.FC<ChatMessageTextProps> = ({
  message,
  size = 'large',
  chatMessagePosition,
  showSender = false,
}) => {
  const user = useUserLegacy(message.userId)
  const { setState } = useRightSidebarContext()
  const chatContainerRef = useRef<HTMLDivElement>(null)
  const openChat = useCallback(
    (e: React.MouseEvent<HTMLElement>): void => {
      e.stopPropagation()
      setState('chat_open')
    },
    [setState]
  )

  if (message.type === 'emoji') return null

  return (
    <ChatMessageContainer
      $chatMessagePosition={chatMessagePosition}
      ref={chatContainerRef}
      onClick={openChat}
      $size={size}
      $color={user?.avatarColor ?? 'blueVivid'}
      initial={{ scale: 0.5, opacity: 0 }}
      animate={{ scale: 1, opacity: 1 }}
      exit={{ scale: 0.5, opacity: 0 }}
      transition={{ ease: [0.25, 0.1, 0.25, 1], duration: 0.2 }}
    >
      {showSender && <ChatMessageSender userId={message.userId} />}
      {tiptapContentToString(message.tiptapJsonData)}
    </ChatMessageContainer>
  )
}
