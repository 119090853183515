import { useCallback } from 'react'
import { ConnectDropTarget, useDrop } from 'react-dnd'
import { ContentTableDragItem, DragItemTypes } from 'sierra-client/components/common/dnd/dnd-types'
import { usePost } from 'sierra-client/hooks/use-post'
import { EditableContent, NonEditorTeamspaceCourse } from 'sierra-domain/api/editable-content'
import { PinnableContentType } from 'sierra-domain/api/user'
import { XRealtimeUserAddPins } from 'sierra-domain/routes'
import { assertNever } from 'sierra-domain/utils'

export const pinnableContentType = (
  createContentKind: EditableContent['type'] | NonEditorTeamspaceCourse['type']
): PinnableContentType => {
  switch (createContentKind) {
    case 'native:self-paced':
    case 'scorm':
    case 'link':
    case 'linkedin':
    case 'native:event-group':
    case 'native:live':
    case 'native:course-group':
    case 'scorm:course-group':
      return 'course'
    case 'path':
      return 'path'
    default:
      assertNever(createContentKind)
  }
}

export const usePinnedContentDrop = (
  onPin: (id: string, isPinned: boolean) => void
): {
  isOver: boolean
  canDrop: boolean
  drop: ConnectDropTarget
} => {
  const { postWithUserErrorException } = usePost()
  const addPin = useCallback(
    async (content: ContentTableDragItem) => {
      if (content.itemType === 'folder') return
      await postWithUserErrorException(XRealtimeUserAddPins, {
        items: [{ id: content.id, type: pinnableContentType(content.itemType) }],
      }).then(() => onPin(content.id, true))
    },
    [onPin, postWithUserErrorException]
  )

  const [{ isOver, canDrop }, drop] = useDrop<
    ContentTableDragItem,
    void,
    { isOver: boolean; canDrop: boolean }
  >(() => {
    return {
      accept: DragItemTypes.ContentTableContent,
      collect: monitor => {
        return {
          isOver: monitor.isOver({ shallow: true }),
          canDrop: monitor.canDrop(),
        }
      },
      canDrop: item => {
        return item.itemType !== 'path' && item.itemType !== 'folder'
      },
      drop: item => {
        void addPin(item)
      },
    }
  }, [addPin])

  return {
    isOver,
    canDrop,
    drop,
  }
}
