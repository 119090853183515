import { UseQueryResult, useMutation, useQuery, useQueryClient } from '@tanstack/react-query'
import { queryClient } from 'sierra-client/api/query-client'
import { useLivePageContext } from 'sierra-client/components/liveV2/contexts/live-page'
import { liveSessionDataChannel } from 'sierra-client/realtime-data/channels'
import { typedPost, useCachedQuery, useTypedMutation } from 'sierra-client/state/api'
import {
  GetLiveSessionTranscriptionRequest,
  GetLiveSessionTranscriptionResponse,
} from 'sierra-domain/api/live-session'
import { LiveRoomId, LiveSessionId } from 'sierra-domain/api/nano-id'
import { LiveSession } from 'sierra-domain/content/session'
import { RequestError } from 'sierra-domain/error'
import {
  XRealtimeContentGetLiveSession,
  XRealtimeStrategyLiveSessionEndLiveSession,
  XRealtimeStrategyLiveSessionGetRoomDetails,
  XRealtimeStrategyLiveSessionGetTranscription,
  XRealtimeStrategyLiveSessionReopenLiveSession,
  XRealtimeStrategyLiveSessionStartLiveSession,
} from 'sierra-domain/routes'

type LiveSessionQueryResult = UseQueryResult<LiveSession>

export const useCurrentLiveSessionQuery = (): LiveSessionQueryResult => {
  const livePage = useLivePageContext()
  const queryClient = useQueryClient()

  liveSessionDataChannel.useChannelEvent({
    channelId: livePage.liveSessionId,
    event: 'live-session-settings-changed',
    callback: updatedSettings => {
      queryClient.setQueryData(
        [XRealtimeContentGetLiveSession.path, { liveSessionId: livePage.liveSessionId }],
        (existing: LiveSession | undefined): LiveSession | undefined => {
          if (existing !== undefined) {
            return {
              ...existing,
              data: {
                ...existing.data,
                transcribeSession: updatedSettings.transcribeSession,
                allowGuestAccess: updatedSettings.guestAccessEnabled,
                endedAt: updatedSettings.endedAt,
                videoCallSetting: updatedSettings.videoCallSetting,
                startedAt: updatedSettings.startedAt,
              },
            }
          }
        }
      )
    },
  })

  return useCachedQuery(
    XRealtimeContentGetLiveSession,
    { liveSessionId: livePage.liveSessionId },
    {
      retry: (count, error) =>
        count < 5 && !RequestError.isAccessError(error) && !RequestError.isNotFound(error),
    }
  )
}

type SetLiveSessionState = {
  liveSessionId: LiveSessionId
  state: 'ended' | 'active'
}

// eslint-disable-next-line @typescript-eslint/explicit-function-return-type
export const useLiveSessionStateMutation = () => {
  const mutation = useMutation({
    mutationFn: async ({ state, liveSessionId }: SetLiveSessionState) => {
      const url =
        state === 'ended'
          ? XRealtimeStrategyLiveSessionEndLiveSession
          : XRealtimeStrategyLiveSessionReopenLiveSession

      await typedPost(url, {
        liveSessionId,
      })
    },

    onSuccess: async (_, { liveSessionId }) => {
      await queryClient.invalidateQueries({
        queryKey: [XRealtimeContentGetLiveSession.path, { liveSessionId }],
      })
    },
  })

  return mutation
}

type UseSetLiveSessionStartedMutationReturn = ReturnType<
  typeof useTypedMutation<typeof XRealtimeStrategyLiveSessionStartLiveSession>
>
export const useSetLiveSessionStartedMutation = (): UseSetLiveSessionStartedMutationReturn => {
  const mutation = useTypedMutation(XRealtimeStrategyLiveSessionStartLiveSession, {
    onSuccess: async (_, { liveSessionId }) => {
      await queryClient.invalidateQueries({
        queryKey: [XRealtimeContentGetLiveSession.path, { liveSessionId }],
      })
    },
  })

  return mutation
}

export const useLiveSessionTranscriptionQuery = (
  parameters: GetLiveSessionTranscriptionRequest
): UseQueryResult<GetLiveSessionTranscriptionResponse, unknown> => {
  const queryResult = useQuery({
    queryKey: ['transcription', 'liveSessionId', parameters],
    queryFn: async () => {
      const fetchResult = await typedPost(XRealtimeStrategyLiveSessionGetTranscription, parameters)
      return fetchResult
    },
    refetchInterval: query => (query.state.data?.transcription === undefined ? 5000 : false),
  })

  return queryResult
}

const getKeyForRoomTopLevelQuery = (roomId: LiveRoomId): string[] => [
  // If this query is used by other components and the data has changed, the page will rerender
  // so this needs a unique key so we can refresh the page when we deliberately want to.
  'root-room-query',
  XRealtimeStrategyLiveSessionGetRoomDetails.path,
  'liveRoomId',
  roomId,
]

export const useCurrentSessionForRoomQuery = (roomId: LiveRoomId): UseQueryResult<LiveSessionId, unknown> => {
  return useQuery({
    queryFn: async () => {
      return typedPost(XRealtimeStrategyLiveSessionGetRoomDetails, { roomId })
    },
    queryKey: getKeyForRoomTopLevelQuery(roomId),
    retry: (count, error) => count < 3 && !RequestError.isNotFound(error),
    select: data => data.currentSession.sessionId,
  })
}

export const useResetCurrentSessionForRoomQuery = (roomId: LiveRoomId): (() => Promise<void>) => {
  const queryClient = useQueryClient()

  return () =>
    queryClient.invalidateQueries({
      queryKey: getKeyForRoomTopLevelQuery(roomId),
    })
}
