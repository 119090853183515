import { useEffect, useState } from 'react'
import { usePost } from 'sierra-client/hooks/use-post'
import { XRealtimeStrategyGetCourseRating } from 'sierra-domain/routes'

export type HasSubmittedRating = 'has-submitted-rating' | 'has-not-submitted-rating' | 'unknown'

export const useUserHasSubmittedRating = (courseId: string): HasSubmittedRating => {
  const { postWithUserErrorException } = usePost()
  const [hasAlreadySubmittedRating, setHasAlreadySubmittedRating] = useState<HasSubmittedRating>('unknown')

  useEffect(
    () => {
      let cancelled = false
      setHasAlreadySubmittedRating('unknown')

      const checkRating = async (): Promise<void> => {
        const { rating } = await postWithUserErrorException(XRealtimeStrategyGetCourseRating, {
          courseId,
        })
        if (cancelled) return

        setHasAlreadySubmittedRating(!!rating ? 'has-submitted-rating' : 'has-not-submitted-rating')
      }

      void checkRating()

      return () => {
        cancelled = true
      }
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [courseId]
  )

  return hasAlreadySubmittedRating
}
