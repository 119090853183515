import { getCurrentlySelectedNode, isElementType } from 'sierra-client/views/v3-author/queries'
import { EditorKeyboardEvent } from 'sierra-domain/v3-author'
import { Editor } from 'slate'

export const onKeyDown = (event: EditorKeyboardEvent, editor: Editor): void => {
  if (event.key !== 'Tab') return

  const currentNode = getCurrentlySelectedNode(editor)
  if (!currentNode || !isElementType('code', currentNode[0])) return

  event.preventDefault()
  editor.insertText('  ')
}
