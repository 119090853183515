import { Logger } from 'sierra-client/core/logging/logger'

type Identifier = `exercise_${string}`

export const submitSubmission = Logger.trackLoggerWithExtra<
  { courseId: string; fileId: string },
  { courseId: string; fileId: string }
>('exercise_submission_submit' satisfies Identifier, input => {
  return {
    ...input,
  }
})

export const submitSubmissionReview = Logger.trackLoggerWithExtra<
  { homeworkId: string; submissionId: string },
  { homeworkId: string; submissionId: string }
>('exercise_submission_review_submit' satisfies Identifier, input => {
  return {
    ...input,
  }
})
