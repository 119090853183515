import { VideoCallSetting } from 'sierra-domain/content/session'

export const inferVideoCallProviderFromUrl = (
  url: string
): 'google-meet' | 'microsoft-teams' | 'zoom' | undefined => {
  const googleMeetRegex = /meet\.google\.com/
  const microsoftTeamsRegex = /teams\.microsoft\.com/
  const zoomRegex = /zoom\.us/

  if (googleMeetRegex.test(url)) {
    return 'google-meet'
  } else if (microsoftTeamsRegex.test(url)) {
    return 'microsoft-teams'
  } else if (zoomRegex.test(url)) {
    return 'zoom'
  } else {
    return undefined
  }
}

export const getOrInferVideoCallProviderFromVideoCallSetting = (
  videoCallSetting: VideoCallSetting
): VideoCallSetting['type'] => {
  if (videoCallSetting.type === 'url') return inferVideoCallProviderFromUrl(videoCallSetting.url) ?? 'url'
  return videoCallSetting.type
}
