import { t } from 'i18next'
import { useCallback, useState } from 'react'
import { ActionModal } from 'sierra-client/components/common/modals/action-modal'
import { AssignModal } from 'sierra-client/components/common/modals/multi-assign-modal'
import { parseModalToContentAssignment } from 'sierra-client/components/common/modals/multi-assign-modal/utils'
import { useNotif } from 'sierra-client/components/common/notifications'
import { getGlobalRouter } from 'sierra-client/router'
import { NewCourseSettingsModal } from 'sierra-client/views/course-settings/course-settings-modal'
import { DueDateDialog, useDueDate } from 'sierra-client/views/manage/components/due-date'
import { UpdateCourseGroupModal } from 'sierra-client/views/manage/courses/course-groups/modals/update-course-group-modal'
import { UseCourseGroupDetailsData } from 'sierra-client/views/manage/courses/use-manage-course-group-details'
import {
  UserModalActions,
  UserModalActionsProps,
} from 'sierra-client/views/manage/users/components/user-modal-actions'
import { DueDateAbsolute, DueDateSource } from 'sierra-domain/api/manage'
import { CourseId } from 'sierra-domain/api/nano-id'
import { UserId } from 'sierra-domain/api/uuid'

export type CourseGroupModalActions =
  | (UserModalActionsProps['action'] & { targetUserId: UserId })
  | {
      modal: 'delete'
    }
  | {
      modal: 'unassign-users'
      userIds: UserId[]
    }
  | {
      modal: 'enroll-to-group' | 'enroll-to-edition'
      courseId: string
    }
  | {
      modal: 'detach-edition'
      courseEditionId: string
    }
  | {
      modal: 'update-course-group'
    }
  | {
      modal: 'set-due-dates'
      currentDueDate?: DueDateAbsolute
      origin?: DueDateSource
      userId: UserId
    }

export type CourseGroupActionModalControl = {
  open: (modalState: CourseGroupModalActions | undefined) => void
  close: () => void
  state: CourseGroupModalActions | undefined
}

export const useCourseGroupActionModals = (): CourseGroupActionModalControl => {
  const [action, setAction] = useState<CourseGroupModalActions | undefined>()

  return {
    open: setAction,
    close: () => setAction(undefined),
    state: action,
  }
}

type Props = {
  control: CourseGroupActionModalControl
  courseGroupId: CourseId
  courseGroupDetails: UseCourseGroupDetailsData
}

export const CourseGroupActionModals: React.FC<Props> = ({
  control: { state, close },
  courseGroupId,
  courseGroupDetails,
}) => {
  const {
    fetchCourseGroup,
    fetchUsersData,
    courseEditionsData,
    deleteCourseGroup,
    unassignUsers,
    detachEdition,
  } = courseGroupDetails

  const notifications = useNotif()
  const { setUsersDueDate, assignWithDueDate } = useDueDate()

  const detachCourseEdition = useCallback(
    async (editionId: string) => {
      if (courseEditionsData.length <= 2) {
        await deleteCourseGroup() // Remove all editions and delete group
        void getGlobalRouter().navigate({ to: '/manage/content' }) // Go back to content
      } else {
        await detachEdition(editionId)
        await fetchCourseGroup() // refresh
      }
    },
    [courseEditionsData, detachEdition, deleteCourseGroup, fetchCourseGroup]
  )

  return (
    <>
      {(state?.modal === 'enroll-to-group' || state?.modal === 'enroll-to-edition') && (
        <AssignModal
          isOpen
          config={{
            subjectType: 'course',
            panes: 'user-and-user-group',
            activePane: 'user',
            showDueDates: true,
            subjectsSupportAssignmentSettings: false,
            autoAssignmentAvailable: false,
            onSave: async selections => {
              const result = await assignWithDueDate(
                parseModalToContentAssignment([state.courseId], 'course', selections)
              )
              if (result?.error !== undefined) return

              notifications.push({ type: 'assigned' })
              close()
              void Promise.all([fetchCourseGroup(), fetchUsersData()])
            },
          }}
          subjects={state.courseId}
          title={
            state.modal === 'enroll-to-edition'
              ? t('manage.course-groups.assignments.assign-to-edition')
              : t('manage.course-groups.assignments.assign-to-group')
          }
          onClose={() => {
            close()
          }}
        />
      )}
      {state?.modal === 'unassign-users' && (
        <ActionModal
          open
          onClose={close}
          primaryAction={async (): Promise<void> => {
            await unassignUsers(state.userIds)
            close()
            notifications.push({
              type: 'custom',
              level: 'success',
              body: t('notifications.done'),
            })
          }}
          primaryActionLabel={t('dictionary.unassign')}
          title={t('manage.unassign-users.title', { count: state.userIds.length })}
          deleteAction
        />
      )}
      <ActionModal
        title={t('course-editions.create.delete.title')}
        open={state?.modal === 'delete'}
        onClose={close}
        deleteAction
        primaryActionLabel={t('course-editions.create.delete.title')}
        primaryAction={async () => {
          await deleteCourseGroup()
          close()
        }}
      >
        {t('manage.course-groups.delete-group.confirm-body')}
      </ActionModal>
      <ActionModal
        title={t('manage.course-groups.detach-edition.title')}
        open={state?.modal === 'detach-edition'}
        onClose={close}
        primaryActionLabel={t('manage.course-groups.detach-edition.confirm-action')}
        primaryAction={async () => {
          const editionId = state?.modal === 'detach-edition' ? state.courseEditionId : undefined

          if (editionId === undefined) return

          await detachCourseEdition(editionId)
        }}
      >
        {t('manage.course-groups.detach-edition.confirm-body')}
      </ActionModal>
      <NewCourseSettingsModal onSave={fetchCourseGroup} />
      <DueDateDialog
        open={state?.modal === 'set-due-dates'}
        onClose={close}
        value={state?.modal === 'set-due-dates' ? state.currentDueDate : undefined}
        type='user'
        forceDisableRemove={state?.modal === 'set-due-dates' ? state.origin !== 'direct' : undefined}
        onChange={async value => {
          if (state?.modal !== 'set-due-dates' || value?.type === 'relative') return

          // Set due date on both the course group and all editions
          const courseGroupAssignment = {
            dueDate: value?.date,
            content: { type: 'course' as const, id: courseGroupId },
          }

          const assignments = courseEditionsData.map(edition => ({
            content: { id: edition.courseId, type: 'course' as const },
            dueDate: value?.date,
          }))

          await setUsersDueDate([state.userId], [...assignments, courseGroupAssignment])

          await Promise.all([fetchCourseGroup(), fetchUsersData()])

          close()
        }}
      />
      <UpdateCourseGroupModal
        courseGroupId={courseGroupId}
        open={state?.modal === 'update-course-group'}
        onClose={close}
        onSave={fetchCourseGroup}
      />

      {state && 'targetUserId' in state && (
        <UserModalActions
          targetUserId={state.targetUserId}
          action={state}
          onClose={close}
          onDone={() => {
            void Promise.all([fetchCourseGroup(), fetchUsersData()])
          }}
        />
      )}
    </>
  )
}
