import { AnimatePresence } from 'framer-motion'
import { useTranslation } from 'react-i18next'
import { Fading, Folding } from 'sierra-client/features/program/components/motion'
import { useToggle } from 'sierra-client/hooks/use-toggle'
import { ProgramOutline } from 'sierra-domain/api/manage'
import { isDefined } from 'sierra-domain/utils'
import { IconButton, Text, View } from 'sierra-ui/primitives'
import { token } from 'sierra-ui/theming'
import { FCC } from 'sierra-ui/types'
import styled from 'styled-components'

const SectionContainer = styled(View)`
  border-bottom: 1px solid ${token('border/strong')};
  padding-block: 12px;
  margin-block: 12px;

  header {
    display: flex;
    justify-content: space-between;
    margin-bottom: 12px;

    .meta {
      max-width: 600px;
    }
  }
`

type SectionRendererProps = {
  outline: ProgramOutline
  sectionIndex: number | undefined
}
export const SectionRenderer: FCC<SectionRendererProps> = ({ outline, sectionIndex, children }) => {
  const [open, { toggle }] = useToggle(true)
  const { t } = useTranslation()

  const section = sectionIndex !== undefined ? outline.sections[sectionIndex] : null

  if (!isDefined(section)) {
    return null
  }

  const steps = outline.steps.filter(step => step.sectionIndex === sectionIndex)

  return (
    <SectionContainer direction='column' grow role='list' aria-label={section.title}>
      <header>
        <View direction='row' gap='10'>
          <div className='meta'>
            <Text bold>{section.title}</Text>

            {open && (
              <Fading>
                <Text color='foreground/secondary'>{section.description}</Text>
              </Fading>
            )}
          </div>

          {!open && (
            <Fading>
              <Text color='foreground/muted'>
                {t('home.hover-card.steps_other', { count: steps.length })}
              </Text>
            </Fading>
          )}
        </View>

        <IconButton
          variant='transparent'
          size='small'
          tooltip={open ? 'Collapse section' : 'Expand section'}
          iconId={open ? 'chevron--up--small' : 'chevron--down--small'}
          onClick={toggle}
        />
      </header>

      <AnimatePresence initial={false}>{open && <Folding>{children}</Folding>}</AnimatePresence>
    </SectionContainer>
  )
}
