//tables

import { View } from 'sierra-ui/primitives'
import styled from 'styled-components'

export const TableHeight = styled(View).attrs({ direction: 'column' })`
  width: 100%;
  height: 100%;
`

export const EmptyTableWrapper = styled(View).attrs({
  direction: 'column',
  radius: 'size-16',
  background: 'surface/soft',
  grow: true,
  justifyContent: 'center',
  alignItems: 'center',
  padding: '32',
})`
  height: 290px;
`
