import { Node } from '@tiptap/core'
import { Mention, MentionOptions } from '@tiptap/extension-mention'
import { ReactRenderer } from '@tiptap/react'
import _ from 'lodash'
import React from 'react'
import { AppThemeTokenProvider } from 'sierra-client/config/token-provider'
import { useAssetResolver } from 'sierra-client/hooks/use-resolve-asset'
import { useTranslation } from 'sierra-client/hooks/use-translation'
import { SuggestionItem } from 'sierra-client/views/insights/insights-content-search/suggestions/suggestion-item'
import {
  InsightsContentSuggestion,
  InsightsQueryRef,
  InsightsRefType,
} from 'sierra-client/views/insights/insights-content-search/types'
import { useLoadInsightContentSuggestions } from 'sierra-client/views/insights/insights-content-search/use-load-insights-content-suggestions'
import {
  getQueryRefId,
  refTypeToTranslation,
  renderTaggedContentPill,
} from 'sierra-client/views/insights/insights-content-search/utils'
import { CourseId } from 'sierra-domain/api/nano-id'
import { AssetContext } from 'sierra-domain/asset-context'
import { ImageUnion } from 'sierra-domain/content/v2/image-union'
import { iife, isDefined } from 'sierra-domain/utils'
import { Menu, MenuItem } from 'sierra-ui/components'
import { ItemContainer } from 'sierra-ui/components/menu/atoms'
import { MentionNode } from 'sierra-ui/missions/search/assistant-pill/editor/course-suggestions'
import { token } from 'sierra-ui/theming'
import styled, { useTheme } from 'styled-components'
import tippy, { GetReferenceClientRect, Instance } from 'tippy.js'

const MenuWithoutBorder = styled.div`
  ${ItemContainer} {
    &:hover {
      background: ${token('surface/default')};
    }
  }
`

type MentionListProps = {
  query: string
  command: (ref: InsightsQueryRef) => void
}

type MentionListRef = {
  onKeyDown: (props: { event: Event }) => boolean
}

const SuggestionsList = React.forwardRef<MentionListRef, MentionListProps>(({ query, command }, ref) => {
  const [selectedIndex, setSelectedIndex] = React.useState(0)
  const { t } = useTranslation()

  const items = useLoadInsightContentSuggestions(query)

  const getItemIndex = (item: InsightsContentSuggestion): number =>
    items.findIndex(i => getQueryRefId(i.ref) === getQueryRefId(item.ref))

  /* Group items */
  const groups = _.chain(items)
    .groupBy(item => item.ref.refType)
    .value()

  /* Flatten to get the same order for index */
  const itemsFlat = _.flatten(Object.values(groups))

  const selectItem = (index: number): void => {
    const item = itemsFlat[index]
    if (isDefined(item)) command(item.ref)
  }

  /* Reset to 0 if we change the items */
  React.useEffect(() => setSelectedIndex(0), [items])

  React.useImperativeHandle(ref, () => ({
    onKeyDown: ({ event }): boolean => {
      const key = 'key' in event && typeof event.key === 'string' ? event.key : ''

      if (key === 'ArrowUp') {
        setSelectedIndex((selectedIndex + itemsFlat.length - 1) % itemsFlat.length)
        event.stopPropagation()
        return true
      } else if (key === 'ArrowDown') {
        event.stopPropagation()
        setSelectedIndex((selectedIndex + 1) % itemsFlat.length)
        return true
      } else if (key === 'Enter') {
        event.stopPropagation()
        selectItem(selectedIndex)
        return true
      } else return false
    },
  }))

  /* Group items by section */
  const menuItems: MenuItem[] = Object.keys(groups).map(
    key =>
      ({
        type: 'group',
        id: key,
        label: t(refTypeToTranslation(key as InsightsRefType)),
        menuItems: groups[key]?.map(item => {
          const currentItem = items.at(selectedIndex)

          const highlighted =
            (currentItem !== undefined && getQueryRefId(item.ref) === getQueryRefId(currentItem.ref)) ||
            selectedIndex === getItemIndex(item)

          return {
            type: 'canvas',
            render: () => (
              <SuggestionItem
                item={item}
                onClick={() => {
                  const hoveredItemIndex = getItemIndex(item)
                  selectItem(hoveredItemIndex)
                }}
                onMouseEnter={() => {
                  setSelectedIndex(getItemIndex(item))
                }}
              />
            ),
            id: getQueryRefId(item.ref),
            label: item.ref.title,
            forceHover: highlighted,
          }
        }),
      }) as MenuItem
  )

  return (
    <AppThemeTokenProvider>
      <MenuWithoutBorder>
        <Menu items={menuItems} />
      </MenuWithoutBorder>
    </AppThemeTokenProvider>
  )
})

export function getMentionNode(
  props: InsightsQueryRef,
  assetResolver: (image: ImageUnion | undefined, assetContext: AssetContext) => string
): MentionNode {
  const assetContext: AssetContext = iife(() => {
    if (props.refType === 'content') {
      const [contentType, contentId] = props.contentId.split(':')
      if (contentType === 'course') {
        return { type: 'course', courseId: CourseId.parse(contentId) }
      }
    }
    return { type: 'unknown' }
  })

  switch (props.refType) {
    case 'content':
      return MentionNode.parse({
        type: 'mention',
        attrs: {
          label: props.title,
          id: getQueryRefId(props),
          ...props,
          tagType: props.refType,
          imageUrl: isDefined(props.image) ? assetResolver(props.image, assetContext) : undefined,
        },
      })
    case 'user':
      return MentionNode.parse({
        type: 'mention',
        attrs: {
          label: props.title,
          id: getQueryRefId(props),
          ...props,
          tagType: props.refType,
          avatar: props.avatar,
        },
      })

    case 'group':
      return MentionNode.parse({
        type: 'mention',
        attrs: {
          label: props.title,
          id: getQueryRefId(props),
          ...props,
          tagType: props.refType,
          avatar: props.avatar,
        },
      })
  }
}

type CustomMentionOptions = MentionOptions<InsightsQueryRef, InsightsQueryRef>

function createSuggestions(
  assetResolver: (image: ImageUnion | undefined, assetContext: AssetContext) => string
): CustomMentionOptions['suggestion'] {
  return {
    command: ({ editor, range, props }) => {
      // increase range.to by one when the next node is of type "text"
      // and starts with a space character
      const nodeAfter = editor.view.state.selection.$to.nodeAfter
      const overrideSpace = nodeAfter?.text?.startsWith(' ')
      if (overrideSpace === true) range.to += 1

      const node = getMentionNode(props, assetResolver)

      editor
        .chain()
        .focus()
        .insertContentAt(range, [node, { type: 'text', text: ' ' }])
        .run()
    },

    render: () => {
      let component: ReactRenderer<MentionListRef, MentionListProps> | undefined
      let popup: Instance[]

      return {
        onStart: props => {
          component = new ReactRenderer(SuggestionsList, { props, editor: props.editor })

          if (!props.clientRect) {
            return
          }

          popup = tippy('body', {
            getReferenceClientRect: props.clientRect as GetReferenceClientRect,
            appendTo: () => document.body,
            content: component.element,
            showOnCreate: true,
            interactive: true,
            trigger: 'manual',
            placement: 'bottom-start',
          })
        },

        onUpdate(props) {
          component?.updateProps(props)

          if (!props.clientRect) {
            return
          }

          popup[0]?.setProps({ getReferenceClientRect: props.clientRect as GetReferenceClientRect })
        },

        onKeyDown: (props): boolean => {
          if (props.event.key === 'Escape') {
            popup[0]?.hide()

            return true
          }

          if (component?.ref === null) return false
          return component?.ref.onKeyDown(props) ?? false
        },

        onExit() {
          popup[0]?.destroy()
          component?.destroy()
        },
      }
    },
  }
}

export function InsightsContentSuggestions(): Node<CustomMentionOptions, unknown> {
  const theme = useTheme()
  const assetResolver = useAssetResolver({ size: 'default' })
  return Mention.extend<CustomMentionOptions>({
    addAttributes() {
      return {
        tagType: {
          default: undefined,
          parseHTML: element => element.getAttribute('data-mention-tag-type'),
          renderHTML: attributes => {
            if (attributes.tagType === undefined) return {}

            return {
              'data-mention-tag-type': attributes.uuid,
            }
          },
        },
        imageUrl: {
          default: undefined,
          parseHTML: element => element.getAttribute('data-mention-image-url'),
          renderHTML: attributes => {
            if (attributes.imageUrl === undefined) return {}

            return {
              'data-mention-image-url': attributes.imageUrl,
            }
          },
        },
        avatar: {
          default: undefined,
          parseHTML: element => element.getAttribute('data-mention-avatar'),
          renderHTML: attributes => {
            if (attributes.avatar === undefined) return {}

            return {
              'data-mention-avatar': attributes.avatar,
            }
          },
        },
        id: {
          default: null,
          parseHTML: element => element.getAttribute('data-id'),
          renderHTML: attributes => {
            if (attributes.id === undefined) {
              return {}
            }

            return {
              'data-id': attributes.id,
            }
          },
        },
        label: {
          default: null,
          parseHTML: element => element.getAttribute('data-label'),
          renderHTML: attributes => {
            if (attributes.label === undefined) {
              return {}
            }

            return {
              'data-label': attributes.label,
            }
          },
        },
      }
    },
  }).configure({
    renderHTML({ node }) {
      return renderTaggedContentPill(node, theme)
    },
    suggestion: createSuggestions(assetResolver),
  })
}
