import { isElementType } from 'sierra-client/views/v3-author/queries'
import { Editor, Range, Transforms } from 'slate'

const debug = (...messages: unknown[]): void => console.debug('[withTags]', ...messages)

export const withTags = (editor: Editor): Editor => {
  const { isInline, isVoid, deleteBackward } = editor

  editor.isInline = element => element.type === 'tag' || isInline(element)
  editor.isVoid = element => element.type === 'tag' || isVoid(element)

  editor.deleteBackward = unit => {
    const { selection } = editor
    if (selection && Range.isCollapsed(selection)) {
      const [tag] = Editor.nodes(editor, {
        match: isElementType('tag'),
      })
      if (tag === undefined) return deleteBackward(unit)
      debug('deleting backward in tag, removing and adding @.')
      Transforms.insertText(editor, '@', { at: Editor.before(editor, selection) })
    }

    deleteBackward(unit)
  }
  return editor
}
