import { unwrapToTextNodes } from 'sierra-client/views/v3-author/command'
import { isElementType, parentType } from 'sierra-client/views/v3-author/queries'
import { isValidInteractiveCardChild } from 'sierra-domain/v3-author'
import { createParagraph } from 'sierra-domain/v3-author/create-blocks'
import { Editor, Node, Transforms } from 'slate'

const debug = (...messages: unknown[]): void => console.debug('[withReflectionCard]', ...messages)

export const withReflectionCard = (editor: Editor): Editor => {
  const { normalizeNode } = editor

  editor.normalizeNode = entry => {
    const [node, path] = entry

    if (isElementType('reflection-card', node)) {
      const isAtRootPosition = parentType(editor, path) === 'editor'
      if (!isAtRootPosition) {
        debug('reflection-cards can only be at the root of documents, unwrapping at', JSON.stringify(path))
        unwrapToTextNodes(editor, { at: path })
        return Transforms.wrapNodes(editor, createParagraph(), { at: path })
      }

      for (const [child, childPath] of Node.children(editor, path)) {
        if (!isValidInteractiveCardChild(child)) {
          debug(
            'reflection-cards can only contain interactive card children, converting at',
            JSON.stringify(childPath)
          )
          unwrapToTextNodes(editor, { at: childPath })
          return Transforms.wrapNodes(editor, createParagraph(), { at: childPath })
        }
      }
    }

    return normalizeNode(entry)
  }

  return editor
}
