import CharacterCount from '@tiptap/extension-character-count'
import Collaboration from '@tiptap/extension-collaboration'
import CollaborationCursor from '@tiptap/extension-collaboration-cursor'
import Link from '@tiptap/extension-link'
import Placeholder from '@tiptap/extension-placeholder'
import { Editor, EditorContent, Extension } from '@tiptap/react'
import StarterKit from '@tiptap/starter-kit'
import { FC, useEffect, useMemo } from 'react'
import {
  tiptapCursorStyles,
  tiptapPlaceholder,
} from 'sierra-client/views/sticky-notes-card/common/tiptap-styles'
import { NoteId, StickyNotesCardYjsApi } from 'sierra-domain/card/sticky-notes-card'
import { User } from 'sierra-domain/user'
import { assert } from 'sierra-domain/utils'
import { theme } from 'sierra-ui/theming/legacy-theme'
import { maxWidth } from 'sierra-ui/utils/media-query-styles'
import styled from 'styled-components'
import { Awareness } from 'y-protocols/awareness'
import * as Y from 'yjs'

const StickyNoteEditorStyled = styled(EditorContent)`
  cursor: auto;

  * {
    word-break: normal;
    hyphens: none;
    overflow-wrap: normal;
  }

  .ProseMirror {
    min-height: 1rem;
    font-size: 14px; /* Initial font size - is adjusted by AutoFitFont dynamically */
    font-weight: 500;

    ${maxWidth.phone} {
      /* Fix auto-zoom issues on iOS */
      font-size: 1rem;
    }
  }

  .ProseMirror [data-placeholder] {
    font-size: 14px;
    font-weight: 400;

    ${maxWidth.phone} {
      /* Fix auto-zoom issues on iOS */
      font-size: 1rem;
    }
  }

  .ProseMirror p:not(.is-editor-empty) {
    font-size: inherit;
  }

  .ProseMirror {
    > * + * {
      margin-top: 0;
    }

    a {
      color: #1a0dab;
    }

    a:hover {
      cursor: pointer;
      text-decoration: underline;
    }

    p {
      line-height: 1.2;
    }

    ul,
    ol {
      padding: 0 0.9em;
    }

    h1,
    h2,
    h3,
    h4,
    h5,
    h6 {
      line-height: 1.1;
    }

    code {
      font-family:
        ui-monospace,
        SFMono-Regular,
        SF Mono,
        Menlo,
        Consolas,
        Liberation Mono,
        monospace;
      padding: 0.2em 0.4em;
      margin: 0;
      font-size: 85%;
      background-color: rgb(175 184 193 / 20%);
      border-radius: 6px;
    }

    strong {
      font-weight: 500;
    }

    pre {
      background: #0d0d0d;
      color: #fff;
      font-family: 'JetBrainsMono', monospace;
      padding: 0.75rem 1rem;
      border-radius: 0.5rem;

      code {
        color: inherit;
        padding: 0;
        background: none;
        font-size: 0.8rem;
      }
    }

    img {
      max-width: 100%;
      height: auto;
    }

    blockquote {
      padding-left: 1rem;
      border-left: 2px solid rgba(#0d0d0d, 0.1);
    }

    hr {
      border: none;
      border-top: 2px solid rgba(#0d0d0d, 0.1);
      margin: 2rem 0;
    }

    .link {
      color: ${p => p.theme.color.blueMedium};

      &:hover {
        text-decoration: underline;
      }
    }
  }

  ${tiptapPlaceholder};
  ${tiptapCursorStyles};
`

class Provider {
  awareness: Awareness

  constructor(awareness: Awareness) {
    this.awareness = awareness
  }
}

type CollaborationCursorUser = {
  name?: string
  color: string
}

const useStickyNoteEditor = ({
  noteId,
  awareness,
  api,
  user,
  editable = true,
}: {
  noteId: string
  awareness?: Awareness
  api: StickyNotesCardYjsApi
  user?: User
  editable?: boolean
}): Editor | null => {
  const provider = useMemo(() => awareness && new Provider(awareness), [awareness])
  const editor = useMemo(() => {
    console.debug(`[useStickyNoteEditor] init editor ${noteId}`)

    const fragment = api.yTiptapDocumentMap.get(noteId)
    assert(fragment instanceof Y.XmlFragment)

    const extensions = [
      StarterKit.configure({
        blockquote: false,
        // bold: false,
        // bulletList: false,
        // code: false,
        // codeBlock: false,
        // document: false,
        dropcursor: false,
        gapcursor: false,
        hardBreak: false,
        heading: false,
        history: false,
        // horizontalRule: false,
        // italic: false,
        // listItem: false,
        // orderedList: false,
        // paragraph: false,
        // strike: false,
        // text: false,
      }),
      Link.configure(),
      Placeholder.configure({
        placeholder: 'Write something...',
      }),
      Collaboration.configure({
        fragment,
      }),
      CharacterCount.configure({
        limit: 750,
      }),
      Extension.create({
        name: 'delete-on-empty',
        priority: 1000,
        addKeyboardShortcuts() {
          return {
            Backspace: () => {
              if (this.editor.isEmpty) {
                api.deleteNote(noteId)
                return true
              }

              return false
            },
          }
        },
      }),
    ]

    // Only include collaboratieve cursors if awareness was provided
    if (provider) {
      extensions.push(
        CollaborationCursor.configure({
          provider,
          user: {
            name: user?.firstName,
            color: theme.color[user?.avatarColor ?? 'pinkBright'],
          } as CollaborationCursorUser,
          render: (user: CollaborationCursorUser) => {
            // Based on https://github.com/ueberdosis/tiptap/tree/main/packages/extension-collaboration-cursor
            const cursor = document.createElement('span')
            cursor.classList.add('collaboration-cursor__caret')
            cursor.setAttribute('style', `--cursor-color: ${user.color}`)

            const label = document.createElement('div')
            label.classList.add('collaboration-cursor__label')
            label.appendChild(document.createTextNode(user.name ?? ''))
            cursor.appendChild(label)

            return cursor
          },
        })
      )
    }

    return new Editor({
      editable,
      extensions,
    })
  }, [noteId, provider, api, user, editable])

  useEffect(() => () => editor.destroy(), [editor])

  return editor
}

export const StickyNoteEditor: FC<{
  noteId: NoteId
  awareness: Awareness
  api: StickyNotesCardYjsApi
  user: User
  onFocus?: React.FocusEventHandler<HTMLDivElement>
  onBlur?: React.FocusEventHandler<HTMLDivElement>
}> = ({ noteId, awareness, api, user, onFocus, onBlur }) => {
  const editor = useStickyNoteEditor({ noteId, awareness, api, user })

  return (
    <StickyNoteEditorStyled
      editor={editor}
      onFocus={onFocus}
      onBlur={onBlur}
      onMouseDown={event => event.stopPropagation()}
      onKeyDown={event => {
        if (event.key === 'Escape') {
          editor?.commands.blur()
        }

        if (['ArrowUp', 'ArrowDown', 'ArrowRight', 'ArrowLeft'].includes(event.key)) {
          event.stopPropagation()
        }
      }}
    />
  )
}
