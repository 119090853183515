import { useSpeakerNoteContext } from 'sierra-client/views/flexible-content/editor/content-sidebar/speaker-notes/context'
import { SpeakerNoteMode } from 'sierra-client/views/flexible-content/editor/content-sidebar/speaker-notes/types'
import { Text, View } from 'sierra-ui/primitives'
import { token } from 'sierra-ui/theming'
import styled, { css } from 'styled-components'

export const TabWrapper = styled(View).attrs({
  padding: '2',
  marginBottom: 'xsmall',
  radius: 'size-8',
  background: 'surface/soft',
})<{
  mode: SpeakerNoteMode
}>`
  align-self: flex-start;
`

export const Tab = styled(View).attrs({
  direction: 'column',
  justifyContent: 'center',
  alignItems: 'center',
  paddingLeft: 'xxsmall',
  paddingRight: 'xxsmall',
  paddingTop: '6',
  paddingBottom: '6',
  cursor: 'pointer',
  radius: 'size-8',
  background: null,
})<{ isCurrent: boolean; mode: SpeakerNoteMode }>`
  ${p =>
    p.isCurrent &&
    css`
      background-color: ${token('surface/default')(p)};
    `};
`

export const SpeakerTabs: React.FC<{
  mode: SpeakerNoteMode
}> = ({ mode }) => {
  const { currentTab, tabs, setTab } = useSpeakerNoteContext()

  return (
    <TabWrapper mode={mode}>
      {tabs.map(tab => {
        const isCurrent = tab.id === currentTab
        return (
          <Tab mode={mode} isCurrent={isCurrent} key={tab.id} onClick={() => setTab(tab.id)}>
            <Text size='small' bold>
              {tab.id}
            </Text>
          </Tab>
        )
      })}
    </TabWrapper>
  )
}
