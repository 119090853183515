import React, { useMemo } from 'react'
import { LiveContentId, LiveSessionId } from 'sierra-domain/api/nano-id'
import { ScopedLiveSessionId } from 'sierra-domain/collaboration/types'

type RecapContext = { liveSessionId: ScopedLiveSessionId; flexibleContentId: LiveContentId }
const RecapContext = React.createContext<RecapContext | undefined>(undefined)

export const RecapContextProvider: React.FC<
  React.PropsWithChildren<{
    liveSessionId: LiveSessionId
    flexibleContentId: LiveContentId
  }>
> = ({ children, liveSessionId, flexibleContentId }) => {
  const value = useMemo(() => {
    const scopedLiveSessionId = ScopedLiveSessionId.fromId(liveSessionId)
    return { liveSessionId: scopedLiveSessionId, flexibleContentId }
  }, [liveSessionId, flexibleContentId])

  return <RecapContext.Provider value={value}>{children}</RecapContext.Provider>
}

export const useRecapContext = (): RecapContext | undefined => React.useContext(RecapContext)
