import { PostableDashboard } from 'sierra-client/features/insights/create-dashboard'
import {
  getDefaultDashboard,
  getEmptyDashboard,
} from 'sierra-client/features/insights/dashboard-selector/default-dashboard'
import { TranslationLookup } from 'sierra-client/hooks/use-translation/types'
import { getGlobalRouter } from 'sierra-client/router'
import { Dashboard, ListedUserFilterDashboard } from 'sierra-domain/api/insights'
import { NanoId12 } from 'sierra-domain/api/nano-id'

export const getDashboardToRedirectTo = (dashboards: ListedUserFilterDashboard[]): NanoId12 | undefined => {
  const dashboardId = dashboards.at(0)?.dashboard.id
  return dashboardId
}

export const redirectToDashboard = async (dashboardId: NanoId12): Promise<void> => {
  await getGlobalRouter().navigate({ to: `/manage/insights/dashboards/${dashboardId}` })
}

export const createNewDashboardAndRedirect = async (
  dashboardTemplate: 'default' | 'empty',
  t: TranslationLookup,
  upsertDashboard: (dashboard: PostableDashboard) => Promise<Dashboard>
): Promise<void> => {
  const newDashboard = dashboardTemplate === 'default' ? getDefaultDashboard(t) : getEmptyDashboard(t)

  try {
    const response = await upsertDashboard(newDashboard)
    await getGlobalRouter().navigate({ to: `/manage/insights/dashboards/${response.id}` })
  } catch (error) {
    console.error(error)
  }
}
