/* eslint-disable @typescript-eslint/no-use-before-define */
import {
  Heading,
  HorizontalStack,
  ListItem,
  OrderedList,
  UnorderedList,
  VerticalStack,
} from 'sierra-domain/v3-author'
import { Descendant, Editor, Element, Text } from 'slate'

function printHeadingToMd(editor: Editor, node: Heading): string {
  if (node.level === 5) {
    return `# ${printNodeToMd(editor, node.children)}`
  } else if (node.level === 0) {
    return `## ${printNodeToMd(editor, node.children)}`
  } else if (node.level === 2) {
    return `### ${printNodeToMd(editor, node.children)}`
  } else if (node.level === 3) {
    return `#### ${printNodeToMd(editor, node.children)}`
  } else return ''
}

function printHorizontalStack(editor: Editor, node: HorizontalStack): string {
  return printNodeToMd(editor, node.children)
}

function printVerticalStack(editor: Editor, node: VerticalStack): string {
  return printNodeToMd(editor, node.children)
}

function printNumberedList(editor: Editor, node: OrderedList): string {
  return printNodeToMd(editor, node.children)
}

function printBulletList(editor: Editor, node: UnorderedList): string {
  return printNodeToMd(editor, node.children)
}

function printListItem(editor: Editor, node: ListItem): string {
  const indent = node.indent ?? 0
  const indentString = '  '.repeat(indent)
  const ordinal = node.ordinal !== undefined ? `${node.ordinal}. ` : '- '
  return `${indentString}${ordinal}${printNodeToMd(editor, node.children)}`
}

/*
 * Gives an approximate markdown representation of the slate nodes.
 * This is a best-effort function and may not be 100% accurate to the slate doc, but should always be valid markdown.
 *
 * NOTE: not all slate nodes are supported yet, and this function is not well tested.
 */
export function printNodeToMd(editor: Editor, node: Descendant | Descendant[]): string {
  if (Text.isText(node)) return node.text

  if (Array.isArray(node))
    return node
      .map(
        node =>
          `${printNodeToMd(editor, node)}${
            Text.isText(node) || (Element.isElement(node) && editor.isInline(node)) ? '' : '\n'
          }`
      )
      .join('')

  if (node.type === 'heading') return printHeadingToMd(editor, node)
  if (node.type === 'paragraph') return printNodeToMd(editor, node.children)
  if (node.type === 'link') return printNodeToMd(editor, node.children)
  if (node.type === 'horizontal-stack') return printHorizontalStack(editor, node)
  if (node.type === 'vertical-stack') return printVerticalStack(editor, node)
  if (node.type === 'numbered-list') return printNumberedList(editor, node)
  if (node.type === 'bulleted-list') return printBulletList(editor, node)
  if (node.type === 'list-item') return printListItem(editor, node)

  return printNodeToMd(editor, node.children)
}
