import { createAction, createSlice, PayloadAction } from '@reduxjs/toolkit'
import { clearState } from 'sierra-client/state/actions'
import { LoggingState, PageState } from 'sierra-client/state/logging/types'

export const enterPage = createAction<PageState>('logging/enter-page')

export const addLog = createAction<LoggingState['logs'][number]>('logging/add-log')

const initialState: LoggingState = {
  logs: [],
  enabled: true,
}

export const loggingSlice = createSlice({
  name: 'logging',
  initialState,
  reducers: {
    setLoggingEnabled(state, { payload }: PayloadAction<boolean>) {
      state.enabled = payload
    },
  },
  extraReducers: builder => {
    builder.addCase(clearState, () => initialState)

    builder.addCase(enterPage, (state, { payload }) => {
      state.page = payload
    })

    builder.addCase(addLog, (state, { payload }) => {
      state.logs.push(payload)
    })
  },
})
