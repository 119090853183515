import React, { useEffect } from 'react'
import { useOrganizationPermissions } from 'sierra-client/hooks/use-permissions'
import { PageIdentifier, SanaPage } from 'sierra-client/layout/sana-page'
import { getGlobalRouter } from 'sierra-client/router'
import { UserOrganizationsPermission } from 'sierra-domain/api/user'

export const requireOrgPermission =
  <T extends Record<string, unknown>>(
    Component: React.ComponentType<T>,
    orgPermission: UserOrganizationsPermission
  ): React.ComponentType<T> =>
  (props: T) => {
    const orgPermissions = useOrganizationPermissions()
    const isInitializing = orgPermissions.status === 'loading'

    const hasAccess = orgPermissions.has(orgPermission)

    useEffect(() => {
      if (isInitializing) return

      if (!hasAccess) {
        alert('You do not have permission to view this page')
        void getGlobalRouter().navigate({ to: '/' })
      }
    }, [isInitializing, hasAccess])

    return !isInitializing && hasAccess ? (
      <Component {...props} />
    ) : (
      <SanaPage page={PageIdentifier.Empty({ message: 'Checking if user has correct access level' })} />
    )
  }
