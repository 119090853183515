import { FCC } from 'sierra-client/types'
import { token } from 'sierra-ui/theming'
import { fonts } from 'sierra-ui/theming/fonts'
import { focusRing } from 'sierra-ui/utils'
import styled, { css } from 'styled-components'

export const sidebarButtonCss = css`
  ${fonts.body.small};
  color: ${token('foreground/primary')};
  background-color: ${token('surface/default')};
  font-weight: ${fonts.weight.medium};
  display: flex;
  align-items: center;
  width: 100%;
  height: 2.125rem;
  padding: 0.5rem;
  cursor: pointer;
  border-radius: ${p => p.theme.borderRadius.regular};

  &:focus-visible {
    ${focusRing};
  }

  &:not([aria-selected='true']) {
    &:hover {
      background-color: ${token('surface/soft')};
    }
  }

  &[aria-selected='true'] {
    background-color: ${token('surface/soft')};
  }
`

const StyledButton = styled.div`
  ${sidebarButtonCss};
`

export const SidebarButton: FCC<React.HTMLAttributes<HTMLDivElement> & { selected: boolean }> = ({
  selected,
  children,
  ...props
}) => {
  return (
    <StyledButton role='button' aria-selected={selected === true || undefined} {...props}>
      {children}
    </StyledButton>
  )
}
