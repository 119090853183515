import { useMemo, useState } from 'react'
import { useAddTeamspaceFolderMutation } from 'sierra-client/api/hooks/use-teamspace'
import { useNotif } from 'sierra-client/components/common/notifications'
import { useTemplatePicker } from 'sierra-client/components/templates'
import { ImportContentModal } from 'sierra-client/features/teamspace/components/modals/import-content-modal'
import { TeamspaceFolderModal } from 'sierra-client/features/teamspace/components/modals/teamspace-folder-modal'
import { folderCreatedLogger } from 'sierra-client/features/teamspace/logger'
import { useTranslation } from 'sierra-client/hooks/use-translation'
import { getGlobalRouter } from 'sierra-client/router'
import { useDispatch } from 'sierra-client/state/hooks'
import { NanoId12 } from 'sierra-domain/api/nano-id'
import { assertNever } from 'sierra-domain/utils'
import { MenuButton, MenuItem } from 'sierra-ui/components'
import { Button } from 'sierra-ui/primitives'

export const PlainCreateNewButton: React.FC<{ variant?: 'primary' | 'secondary' }> = ({
  variant = 'primary',
}) => {
  const { templatePickerOn } = useTemplatePicker()
  const { t } = useTranslation()

  return (
    <Button
      variant={variant}
      onClick={() => {
        templatePickerOn()
      }}
    >
      {t('topbar.create-new')}
    </Button>
  )
}

type AddContentItem = MenuItem<'folder' | 'import'>

export const CreateNewButtonWithOptions: React.FC<{
  parentFolder: {
    folderId: NanoId12
    displayName: string | undefined
  }
  teamspaceId: NanoId12
  teamspaceName: string
}> = ({ parentFolder, teamspaceId, teamspaceName }) => {
  const { templatePickerOn } = useTemplatePicker()
  const { t } = useTranslation()
  const notification = useNotif()
  const dispatch = useDispatch()

  const [addFolderModalOpen, setAddFolderModalOpen] = useState(false)
  const [importContentModalOpen, setImportContentModalOpen] = useState(false)

  const addFolderMutation = useAddTeamspaceFolderMutation()

  const onClose = (): void => {
    setAddFolderModalOpen(false)
  }

  const addFolder = (folderName: string): void => {
    addFolderMutation.mutate(
      { teamspaceId, parentFolderId: parentFolder.folderId, folderName },
      {
        onSuccess: data => {
          onClose()

          void getGlobalRouter().navigate({ to: `/t/${teamspaceId}/${data.newFolderId}` })

          notification.push({
            type: 'custom',
            level: 'info',
            body: t('teamspace.folder.new-folder'),
          })

          void dispatch(
            folderCreatedLogger({
              folderId: data.newFolderId,
              teamspaceId,
              parentFolderId: parentFolder.folderId,
            })
          )
        },
      }
    )
  }

  const menuItems = useMemo<AddContentItem[]>(
    () => [
      {
        id: 'folder',
        icon: 'folder--add',
        type: 'label',
        label: t('teamspace.create-new.folder'),
      },
      {
        id: 'import',
        icon: 'document--import',
        type: 'label',
        label: t('teamspace.create-new.import-content'),
      },
    ],
    [t]
  )

  return (
    <>
      <MenuButton
        onSelect={item => {
          switch (item.id) {
            case 'folder':
              setAddFolderModalOpen(true)
              return
            case 'import':
              setImportContentModalOpen(true)
              return
            default:
              assertNever(item.id)
          }
        }}
        onPrimaryClick={() => templatePickerOn()}
        menuItems={menuItems}
        menuLabel={t('admin.author.more-content-actions')}
      >
        {t('admin.author.new')}
      </MenuButton>
      <TeamspaceFolderModal
        modalOpen={addFolderModalOpen}
        onConfirm={addFolder}
        onClose={onClose}
        defaultFolderName={''}
        confirmLabel={t('teamspace.folders.add-folder-confirm')}
      />
      <ImportContentModal
        teamspaceName={teamspaceName}
        teamspaceId={NanoId12.parse(teamspaceId)}
        modalOpen={importContentModalOpen}
        onClose={() => setImportContentModalOpen(false)}
        folder={parentFolder}
      />
    </>
  )
}
