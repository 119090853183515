import { Collaboration } from '@tiptap/extension-collaboration'
import CollaborationCursor from '@tiptap/extension-collaboration-cursor'
import { Extensions, JSONContent } from '@tiptap/react'
import { useCallback, useMemo } from 'react'
import { getServerTimeNow, selectClock } from 'sierra-client/state/collaboration/selectors'
import { useSelector } from 'sierra-client/state/hooks'
import { selectUser } from 'sierra-client/state/user/user-selector'
import {
  getInstructionXmlFragment,
  getReviewInstruction,
  setInstructionTimeEdited,
  setInstructionsJsonData,
} from 'sierra-domain/collaboration/homework-review-instructions-map'
import { FileId } from 'sierra-domain/flexible-content/identifiers'
import { getUserName } from 'sierra-domain/utils'
import { theme } from 'sierra-ui/theming/legacy-theme'
import { Awareness } from 'y-protocols/awareness'
import * as Y from 'yjs'

class Provider {
  awareness: Awareness

  constructor(awareness: Awareness) {
    this.awareness = awareness
  }
}

type CollaborationCursorUser = {
  name: string
  color: string
}

type CreateEditorProps = { editable: boolean; fileId: FileId; yDoc: Y.Doc; awareness: Awareness }

export const useHomeworkReviewInstructionsCreateEditor = ({
  editable,
  fileId,
  yDoc,
  awareness,
}: CreateEditorProps): { extensions: Extensions; onChange: (json: JSONContent) => void } => {
  const instruction = getReviewInstruction(yDoc, fileId)
  const fragment = useMemo(() => getInstructionXmlFragment(instruction), [instruction])

  const serverClock = useSelector(selectClock)
  const onChange = useCallback(
    (json: JSONContent): void => {
      if (editable && json.content !== undefined) {
        const hasContent = json.content.some(content => content.content !== undefined)
        if (hasContent) {
          const data = JSON.stringify(json)
          setInstructionsJsonData(instruction, data)
        } else {
          setInstructionsJsonData(instruction)
        }
        if (serverClock !== undefined) {
          const serverTimeNow = getServerTimeNow(serverClock)
          setInstructionTimeEdited(instruction, serverTimeNow)
        }
      }
    },
    [editable, instruction, serverClock]
  )

  const user = useSelector(selectUser)
  const provider = useMemo(() => new Provider(awareness), [awareness])

  const extensions: Extensions = useMemo(() => {
    const collaboratorCursorUser: CollaborationCursorUser = {
      name: getUserName(user) ?? '',
      color: theme.color[user?.avatarColor ?? 'pinkBright'],
    }
    return [
      Collaboration.configure({ fragment }),
      CollaborationCursor.configure({
        provider,
        user: collaboratorCursorUser,
        render: (user: CollaborationCursorUser) => {
          // Based on https://github.com/ueberdosis/tiptap/tree/main/packages/extension-collaboration-cursor
          const cursor = document.createElement('span')
          cursor.classList.add('collaboration-cursor__caret')
          cursor.setAttribute('style', `--cursor-color: ${user.color}`)

          const label = document.createElement('div')
          label.classList.add('collaboration-cursor__label')
          label.appendChild(document.createTextNode(user.name))
          cursor.appendChild(label)

          return cursor
        },
      }),
    ]
  }, [provider, fragment, user])

  return { extensions, onChange }
}
