import { atom } from 'jotai'
import { graphql } from 'sierra-client/api/graphql/gql'
import {
  GradeStatus,
  HomeworkSortAttributeInput,
  HomeworkSubmissionsQuery,
  SortableHomeworkSubmissionAttribute,
} from 'sierra-client/api/graphql/gql/graphql'
import {
  convertGQLAvatar,
  getAvatarColor,
  getAvatarUrl,
} from 'sierra-client/api/graphql/util/convert-gql-avatar'
import { convertGQLImage } from 'sierra-client/api/graphql/util/convert-gql-image'
import { toGQLSortOrder } from 'sierra-client/api/graphql/util/convert-sort-order'
import { null2Undefined } from 'sierra-client/api/graphql/util/null'
import { graphQuery } from 'sierra-client/api/hooks/use-graphql-query'
import { TranslationKey, TranslationLookup } from 'sierra-client/hooks/use-translation/types'
import { filterEmptyFilters } from 'sierra-client/lib/filter'
import {
  dateTimesColumn,
  homeworksColumn,
  stringsColumn,
  userStackColumn,
  usersColumn,
} from 'sierra-client/lib/tabular/column-definitions'
import * as DL from 'sierra-client/lib/tabular/control/dataloader'
import { DataLoaderStateMachine } from 'sierra-client/lib/tabular/control/dataloader'
import { Header } from 'sierra-client/lib/tabular/datatype/internal/header'
import { UserRep } from 'sierra-client/lib/tabular/datatype/internal/reps/user-rep'
import { translatedLabel } from 'sierra-client/lib/tabular/datatype/label'
import {
  TableDataFromDefinition,
  TableDefinitionOf,
  definition2Data,
} from 'sierra-client/lib/tabular/datatype/tabledefinition'
import { getRowDataFromTableAPI } from 'sierra-client/lib/tabular/utils'
import { selectedAtom } from 'sierra-client/lib/tabular/utils/atom-helpers'
import { VirtualColumns } from 'sierra-client/lib/tabular/virtual-columns'
import {
  assertNever,
  isDefined,
  isNonEmptyString,
  isNonNullable,
  isNullable,
  lowercase,
} from 'sierra-domain/utils'

export const HOMEWORK_COLUMN_REFS = {
  USER: 'SUBMITTER_NAME',
  HOMEWORK: 'HOMEWORK_TITLE',
  DUE: 'DUE_DATE',
  STATUS: 'GRADE_STATUS',
  REVIEWERS: 'reviewers',
  SUBMITTED_AT: 'SUBMITTED_AT',
} as const

type Data = HomeworkSubmissionsQuery['homeworkSubmissions']['data'][number]

type HomeworkTableDefinition = TableDefinitionOf<
  Data,
  [
    { type: 'users'; ref: typeof HOMEWORK_COLUMN_REFS.USER },
    { type: 'homeworks'; ref: typeof HOMEWORK_COLUMN_REFS.HOMEWORK },
    { type: 'dateTimes'; ref: typeof HOMEWORK_COLUMN_REFS.SUBMITTED_AT },
    { type: 'dateTimes'; ref: typeof HOMEWORK_COLUMN_REFS.DUE },
    { type: 'strings'; ref: typeof HOMEWORK_COLUMN_REFS.STATUS },
    { type: 'userStacks'; ref: typeof HOMEWORK_COLUMN_REFS.REVIEWERS },
  ]
>
export type HomeworkTableData = TableDataFromDefinition<Data, HomeworkTableDefinition>

const homeworkSubmissionsQuery = graphql(`
  query HomeworkSubmissions(
    $limit: Int
    $cursor: String
    $sortBy: [HomeworkSortAttributeInput!]
    $filter: HomeworkSubmissionFilter
  ) {
    homeworkSubmissions(limit: $limit, cursor: $cursor, sortBy: $sortBy, filter: $filter) {
      cursor
      totalCount
      data {
        homeworkId
        userId
        submitter {
          id
          firstName
          lastName
          email
          status
          avatar {
            ...AvatarFragment
          }
        }
        homework {
          id
          title
          submissionType
          courseId
          course {
            title
            image {
              ...ImageFragment
            }
          }
        }
        reviewerIds
        reviewers {
          id
          firstName
          lastName
          avatar {
            ...AvatarFragment
          }
        }
        submission {
          id
          createdAt
        }
        assignedAt
        dueDate
        gradeStatus
      }
    }
  }
`)

const gradeState2TranslationKey = (status: GradeStatus): TranslationKey => {
  switch (status) {
    case 'FAILED':
      return 'homework.failed'
    case 'FAILED_WITH_NO_RETRIES':
      return 'homework.failed-with-no-retries'
    case 'PASSED':
      return 'homework.passed'
    case 'NOT_GRADED':
      return 'homework.ready-for-review'
    case 'NOT_SUBMITTED':
      return 'homework.no-submission'
    case 'DISMISSED':
      return 'homework.dismissed'
    default:
      assertNever(status)
  }
}

const uniqueSubmissionId = (row: HomeworkSubmissionsQuery['homeworkSubmissions']['data'][number]): string =>
  row.submission?.id ?? `${row.homeworkId}-${row.userId}`

const toTableDefinition = (t: TranslationLookup): HomeworkTableDefinition => {
  return {
    nested: {},
    rows: { getId: s => uniqueSubmissionId(s) },
    columns: [
      usersColumn({
        ref: HOMEWORK_COLUMN_REFS.USER,
        header: translatedLabel('dictionary.user-singular'),
        sortable: true,
        getData: s => {
          const submitter = s.submitter
          const avatar = convertGQLAvatar(submitter.avatar)
          return {
            firstName: submitter.firstName ?? '-',
            lastName: submitter.lastName ?? '-',
            status: lowercase(submitter.status) satisfies UserRep['status'],
            email: submitter.email,
            active: submitter.status === 'ACTIVE',
            avatar: getAvatarUrl(submitter.id, avatar),
            avatarColor: getAvatarColor(avatar),
            id: submitter.id,
          }
        },
      }),
      homeworksColumn({
        ref: HOMEWORK_COLUMN_REFS.HOMEWORK,
        header: translatedLabel('dictionary.homework-singular'),
        sortable: true,
        getData: s => {
          return {
            id: s.homeworkId,
            submissionId: s.submission?.id,
            title: isNonEmptyString(s.homework.title) ? s.homework.title : undefined,
            course: {
              title: s.homework.course.title,
              id: s.homework.courseId,
              image: convertGQLImage(s.homework.course.image),
            },
          }
        },
      }),
      dateTimesColumn({
        ref: HOMEWORK_COLUMN_REFS.SUBMITTED_AT,
        header: translatedLabel('dictionary.submitted'),
        sortable: true,
        getData: s => (isNonNullable(s.submission) ? new Date(s.submission.createdAt) : undefined),
      }),
      dateTimesColumn({
        ref: HOMEWORK_COLUMN_REFS.DUE,
        header: translatedLabel('dictionary.due'),
        sortable: true,
        getData: s => (isNonNullable(s.dueDate) ? new Date(s.dueDate) : undefined),
      }),
      stringsColumn({
        ref: HOMEWORK_COLUMN_REFS.STATUS,
        header: translatedLabel('dictionary.status'),
        sortable: true,
        hints: ['bold'],
        getData: s => t(gradeState2TranslationKey(s.gradeStatus)),
      }),
      userStackColumn({
        ref: HOMEWORK_COLUMN_REFS.REVIEWERS,
        header: translatedLabel('manage.homework.reviewer-plural'),
        getData: data => ({
          users: data.reviewers.map(r => {
            const avatar = convertGQLAvatar(r.avatar)
            return {
              avatar: getAvatarUrl(r.id, avatar),
              avatarColor: getAvatarColor(avatar),
              firstName: null2Undefined(r.firstName),
              lastName: null2Undefined(r.lastName),
              id: r.id,
            }
          }),
        }),
      }),
    ],
  }
}

export const virtualColumns = (action: {
  onReview: (submissionId: string) => void
}): VirtualColumns<HomeworkTableData> => ({
  left: [],
  // TODO: break this out to a helper
  right: [
    {
      ref: 'review-action',
      header: () =>
        ({
          ref: 'review-action',
          type: 'action',
          enabled: atom(() => true),
          sortable: atom(() => false),
          hints: ['sticky', 'sticky-right', 'column-toggle'],
        }) satisfies Header,
      cell: ({ pos, api }) => ({
        type: 'rowAction',
        action: {
          type: 'label',
          label: translatedLabel('dictionary.review'),
          callback: (ref, api) => {
            const rowData = getRowDataFromTableAPI(api, ref)

            if (rowData && isDefined(rowData.HOMEWORK_TITLE.data.submissionId)) {
              action.onReview(rowData.HOMEWORK_TITLE.data.submissionId)
            }
          },
          hints: ['button.secondary'],
          hide: (ref, api) => {
            const rowData = getRowDataFromTableAPI(api, ref)
            if (isDefined(rowData?.HOMEWORK_TITLE.data.submissionId)) {
              return false
            }
            return true
          },
        },
        pos,
        hints: ['sticky', 'sticky-right'],
        enabled: atom(() => true),
        selected: selectedAtom(pos, api.atoms.selection),
      }),
    },
  ],
})

export const homeworksDataLoader = (
  t: TranslationLookup
): DataLoaderStateMachine<HomeworkTableData, { cursor: string | null | undefined }> =>
  DL.createDataLoader({
    async fetchInit({ modifier, control, predicate }) {
      const sortBy = modifier.sorting?.map(
        sort =>
          ({
            // todo: fix cast
            key: sort.column as SortableHomeworkSubmissionAttribute,
            order: toGQLSortOrder(sort.direction),
          }) satisfies HomeworkSortAttributeInput
      )

      const r = await graphQuery(homeworkSubmissionsQuery, {
        limit: control.limit,
        sortBy,
        // As we allow empty filters in the view but we dont want the query to have empty filters
        filter:
          predicate.filter !== undefined ? JSON.stringify(filterEmptyFilters(predicate.filter)) : undefined,
      })

      return {
        meta: { cursor: r.homeworkSubmissions.cursor },
        data: r.homeworkSubmissions.data,
        totalCount: r.homeworkSubmissions.totalCount,
        done: isNullable(r.homeworkSubmissions.cursor),
      }
    },

    async fetchMore({ control, meta }) {
      const r = await graphQuery(homeworkSubmissionsQuery, {
        limit: control.limit,
        cursor: meta.cursor,
      })

      return {
        meta: { cursor: r.homeworkSubmissions.cursor },
        data: r.homeworkSubmissions.data,
        totalCount: r.homeworkSubmissions.totalCount,
        done: isNullable(r.homeworkSubmissions.cursor),
      }
    },

    transformResults(data) {
      return [definition2Data(toTableDefinition(t), data)]
    },
  })
