import { ImageFragmentFragment } from 'sierra-client/api/graphql/gql/graphql'
import { ImageUnion } from 'sierra-domain/content/v2/content'
import { assertNever } from 'sierra-domain/utils'

export function convertGQLImage(fragmentValue: ImageFragmentFragment): ImageUnion
export function convertGQLImage(
  fragmentValue: ImageFragmentFragment | null | undefined
): ImageUnion | undefined
export function convertGQLImage(
  fragmentValue: ImageFragmentFragment | null | undefined
): ImageUnion | undefined {
  if (fragmentValue === undefined || fragmentValue === null) return undefined

  const img = fragmentValue

  switch (img.__typename) {
    case 'FileImage':
      return {
        type: 'file',
        file: img.file,
        width: img.width ?? undefined,
        height: img.height ?? undefined,
      }
    case 'MediaLibraryImage':
      return {
        type: 'media-library-image',
        file: img.file,
        width: img.mediaWidth,
        height: img.mediaHeight,
      }
    case 'UnsplashImage':
      return {
        type: 'unsplash',
        url: img.url,
        width: img.width ?? undefined,
        height: img.height ?? undefined,
        user: {
          name: img.user.name,
          username: img.user.username,
        },
      }
    case 'UrlImage':
      return {
        type: 'url',
        url: img.url,
      }
    default:
      assertNever(img)
  }
}
