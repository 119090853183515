import { createSelector } from '@reduxjs/toolkit'
import { selectFiles } from 'sierra-client/state/flexible-content/selectors'
import { RootState } from 'sierra-client/state/types'
import { CreateContentId } from 'sierra-domain/api/nano-id'
import { FileId } from 'sierra-domain/flexible-content/identifiers'
import { File } from 'sierra-domain/flexible-content/types'
import { KeysMappedToTrue } from 'sierra-domain/utils'

const RecapsFileTypeVisibilityMap = {
  // Enabled in recaps
  'bullet': true,
  'embed': true,
  'general': true,
  'image': true,
  'notepad': true,
  'poll': true,
  'question-card': true,
  'reflections': true,
  'slate-card': true,
  'video': true,
  'sticky-notes': true,
  'flip-cards': true,
  'sliding-scale': true,
  'drop-a-word': true,
  'external-notepad': true,

  // Disabled in recaps
  'breakout-room': false,
  'live-lobby': false,
  'project-card': false,
  'homework': false,
  'stupid-questions': false,
  'scenario': false,
  'roleplay': false,
  'assessment-card': false,
} satisfies Record<File['data']['type'], boolean>
type RecapsFileTypeVisibilityMap = typeof RecapsFileTypeVisibilityMap

type RecapFileType = KeysMappedToTrue<RecapsFileTypeVisibilityMap>
export type RecapFile = File & { data: { type: RecapFileType } }

function isRecapFile(file: File): file is RecapFile {
  return RecapsFileTypeVisibilityMap[file.data.type] === true
}

export const selectRecapFiles = createSelector(
  [
    (state: RootState) => state,
    (state: unknown, flexibleContentId: CreateContentId) => flexibleContentId,
    selectFiles,
  ],
  (state, flexibleContentId, files): RecapFile[] => files.filter(isRecapFile)
)

export const selectRecapFileIds = createSelector(
  [
    (state: RootState) => state,
    (state: unknown, flexibleContentId: CreateContentId) => flexibleContentId,
    selectRecapFiles,
  ],
  (state, flexibleContentId, recapFiles): FileId[] => recapFiles.map(file => file.id)
)
