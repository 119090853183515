import { keepPreviousData, UseQueryResult } from '@tanstack/react-query'
import { graphql } from 'sierra-client/api/graphql/gql'
import { useGraphQuery } from 'sierra-client/api/hooks/use-graphql-query'
import { useAreCertificatesEnabled } from 'sierra-client/views/manage/certificates/use-are-certificates-enabled'
import { HeatmapContent } from 'sierra-domain/api/heatmap'
import { CertificateId } from 'sierra-domain/api/nano-id'

export const useAvailableCertificates = (input?: {
  title?: string
  limit?: number
  skip?: boolean
  includeIds?: Array<CertificateId>
}): UseQueryResult<Array<HeatmapContent>, unknown> => {
  const certificatesFeatureEnabled = useAreCertificatesEnabled()
  const { title, limit, includeIds } = input ?? { limit: 100 }

  const queryResult = useGraphQuery(
    {
      document: graphql(`
        query heatmapCertificates($limit: Int, $title: String, $includeIds: [CertificateId!]) {
          certificates(limit: $limit, withTitle: $title, includeIds: $includeIds) {
            id
            title
            previewImageUrl
          }
        }
      `),
      queryOptions: {
        placeholderData: keepPreviousData,
        enabled: certificatesFeatureEnabled && Boolean(input?.skip) === false,
        staleTime: 60_000,
        gcTime: 60_000,
        select: (res): Array<HeatmapContent> => {
          const certificates = res.certificates

          return certificates.map(
            (c): HeatmapContent => ({
              type: 'certificate',
              id: c.id,
              title: c.title,
              image: c.previewImageUrl,
            })
          )
        },
      },
    },
    {
      limit: limit,
      title: title,
      includeIds: includeIds,
    }
  )
  return queryResult
}
