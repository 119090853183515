import fuzzysort from 'fuzzysort'
import { DateTime } from 'luxon'
import React, { useEffect, useMemo, useState } from 'react'
import { CellProps, Column } from 'react-table'
import { SelectableHeader, SelectableRow } from 'sierra-client/components/table/select'
import { SortableHeader } from 'sierra-client/components/table/sortable-header'
import { useCalendarEventPermissions, useHasContentKindPermission } from 'sierra-client/hooks/use-permissions'
import { useTranslation } from 'sierra-client/hooks/use-translation'
import { getGlobalRouter } from 'sierra-client/router'
import {
  ManageTableSmall,
  useManageTableSmall,
} from 'sierra-client/views/manage/components/manage-table-small'
import { RoundedSearchBar } from 'sierra-client/views/manage/components/rounded-search-bar'
import {
  EventRow,
  eventGroupToCsv,
  formatEventSchedule,
  getEventLocationString,
  getEventScheduleStart,
} from 'sierra-client/views/manage/event-groups/event-utils'
import { CalendarEventId, EventGroupId } from 'sierra-domain/api/nano-id'
import { TruncatedTextWithTooltip } from 'sierra-ui/components'
import { MenuItem } from 'sierra-ui/components/menu'
import { Button, Text, View } from 'sierra-ui/primitives'
import { IconMenu, SingleSelectDropdown } from 'sierra-ui/primitives/menu-dropdown'

type EventsDateFilter = 'upcoming' | 'all' | 'past'
type EventsFilter = {
  query: string
  dateFilter: EventsDateFilter
}
const DEFAULT_FILTER: EventsFilter = {
  query: '',
  dateFilter: 'upcoming',
}

export const ManageEventGroupCalendarEventsTable: React.FC<{
  events: EventRow[]
  eventGroupId: EventGroupId
  openCreateCalendarEventPanel: (open: boolean) => void
  deleteCalendarEvent: (calendarEventId: CalendarEventId) => Promise<void>
}> = ({ events, openCreateCalendarEventPanel, eventGroupId, deleteCalendarEvent }) => {
  const { t } = useTranslation()
  const [focusedEventId, setFocusedEventId] = useState<CalendarEventId | undefined>(undefined)
  const [filter, setFilter] = useState<EventsFilter>(DEFAULT_FILTER)
  const canScheduleCalendarEvent = useHasContentKindPermission(eventGroupId, 'SCHEDULE')

  const filterMenuItems: MenuItem<EventsDateFilter>[] = useMemo(
    () => [
      {
        type: 'label' as const,
        id: 'upcoming' as const,
        label: t('manage.live-session.filter.upcoming-sessions'),
        selected: filter.dateFilter === 'upcoming',
      },
      {
        type: 'label' as const,
        id: 'all' as const,
        label: t('admin.author.sessions.all'),
        selected: filter.dateFilter === 'all',
      },
      {
        type: 'label' as const,
        id: 'past' as const,
        label: t('manage.live-session.filter.past-sessions'),
        selected: filter.dateFilter === 'past',
      },
    ],
    [filter.dateFilter, t]
  )
  const selectedDateFilter = filterMenuItems.find(item => item.id === filter.dateFilter)

  const filteredSessions = useMemo(() => {
    let sessions = events

    if (filter.dateFilter !== 'all') {
      sessions = sessions.filter(session => {
        const dt = getEventScheduleStart(session.data.schedule)
        const todayAtMidnight = DateTime.now().set({ hour: 0, minute: 0, second: 0, millisecond: 0 })

        if (filter.dateFilter === 'upcoming') {
          return dt >= todayAtMidnight
        }

        if (filter.dateFilter === 'past') {
          return dt < todayAtMidnight
        }

        return true
      })
    }

    if (filter.query.trim() !== '') {
      sessions = fuzzysort.go(filter.query, sessions, { key: 'data.title' }).map(({ obj }) => obj)
    }

    return sessions
  }, [events, filter])

  const columns: Column<EventRow>[] = React.useMemo(
    () => [
      {
        id: 'select',
        accessor: event => event.data.id,
        Header: p => <SelectableHeader {...p} />,
        Cell: (p: CellProps<EventRow>) => <SelectableRow {...p} />,
        width: '5%',
      },
      {
        id: 'title',
        Header: p => <SortableHeader label={t('table.name')} smallLabel {...p} />,
        accessor: event => event.data.title,
        Cell: (p: CellProps<EventRow>) => (
          <TruncatedTextWithTooltip color='foreground/primary' size='small' bold>
            {p.row.original.data.title}
          </TruncatedTextWithTooltip>
        ),
        width: '20%',
      },
      {
        id: 'time',
        Header: p => {
          return (
            <>
              <SortableHeader label={t('dictionary.date-and-time')} smallLabel {...p} />
            </>
          )
        },
        accessor: event => getEventScheduleStart(event.data.schedule),
        Cell: (p: CellProps<EventRow>) => {
          return (
            <View alignItems='center'>
              <TruncatedTextWithTooltip color='foreground/primary' size='small'>
                {formatEventSchedule(p.row.original.data.schedule)}
              </TruncatedTextWithTooltip>
            </View>
          )
        },
        width: '14%',
      },
      {
        id: 'location',
        Header: p => <SortableHeader label={t('dictionary.location')} smallLabel {...p} />,
        accessor: event => event.data.location,
        Cell: (p: CellProps<EventRow>) => {
          const locationString = getEventLocationString(p.row.original.data.location)
          const location = locationString !== undefined ? locationString : t('events.no-location')
          return (
            <TruncatedTextWithTooltip color='foreground/primary' size='small'>
              {location}
            </TruncatedTextWithTooltip>
          )
        },
        width: '24%',
      },
      {
        id: 'participants',
        Header: p => <SortableHeader label={t('table.learners')} smallLabel {...p} />,
        accessor: event => event.data.location,
        Cell: (p: CellProps<EventRow>) => (
          <TruncatedTextWithTooltip color='foreground/primary' size='small'>
            {`${p.row.original.data.assignedUsers.length} ${
              p.row.original.data.participantLimit !== undefined
                ? ` / ${p.row.original.data.participantLimit}`
                : ''
            }`}
          </TruncatedTextWithTooltip>
        ),
        width: '10%',
      },
      {
        id: 'facilitators',
        Header: p => <SortableHeader label={t('dictionary.facilitators')} smallLabel {...p} />,
        accessor: event => event.data.facilitators,
        Cell: (p: CellProps<EventRow>) => (
          <TruncatedTextWithTooltip color='foreground/primary' size='small'>
            {p.row.original.data.facilitators.map(({ displayName }) => displayName).join(', ')}
          </TruncatedTextWithTooltip>
        ),
        width: '18%',
      },
      {
        id: 'actions',
        width: '5%',
        disableSortBy: true,
        accessor: event => event.data,
        Cell: (p: CellProps<EventRow>) => {
          const [open, setOpen] = useState(false)
          const calendarEventPermissions = useCalendarEventPermissions(p.row.original.data.id)

          useEffect(() => {
            if (open) {
              setFocusedEventId(p.row.original.data.id)
            } else {
              setFocusedEventId(undefined)
            }
          }, [open, p.row.original.data.id])

          const menuItems: MenuItem[] = useMemo(() => {
            return [
              {
                type: 'label',
                label: t('manage.view-details'),
                icon: 'user',
                id: 'view-details',
                hidden: !calendarEventPermissions.has('VIEW'),
                onClick: () =>
                  getGlobalRouter().navigate({
                    to: `/manage/in-person-events/${eventGroupId}/${p.row.original.data.id}`,
                  }),
              },
              {
                type: 'label',
                label: t('dictionary.delete'),
                id: 'delete',
                icon: 'trash-can',
                hidden: !calendarEventPermissions.has('DELETE'),
                color: 'destructive/background',
                onClick: () => deleteCalendarEvent(p.row.original.data.id),
              },
            ]
          }, [calendarEventPermissions, p.row.original.data.id])

          return (
            <>
              <View justifyContent='flex-end' grow>
                <IconMenu
                  iconId='overflow-menu--horizontal'
                  aria-label='Details'
                  variant='transparent'
                  onSelect={item => {
                    if ('onClick' in item) {
                      item.onClick?.()
                    }
                  }}
                  menuItems={menuItems}
                  isOpen={open}
                  onOpenChange={setOpen}
                />
              </View>
            </>
          )
        },
      },
    ],
    [deleteCalendarEvent, eventGroupId, t]
  )

  const { tableInstance } = useManageTableSmall({
    tableOptions: { data: filteredSessions, columns },
    getEntityId: event => event.data.id,
  })

  return (
    <View direction='column' gap='16'>
      <Text size='large' bold>
        {t('content.sessions')}
      </Text>
      <View>
        <RoundedSearchBar
          value={filter.query}
          onChange={query => setFilter(curr => ({ ...curr, query }))}
          placeholder={t('search.sessions')}
        />
        <View>
          <SingleSelectDropdown
            truncateTrigger={false}
            onSelect={item =>
              setFilter(previous => {
                return { ...previous, dateFilter: item.id }
              })
            }
            selectedItem={selectedDateFilter}
            menuItems={filterMenuItems}
          />
        </View>
        {canScheduleCalendarEvent && (
          <Button onClick={() => openCreateCalendarEventPanel(true)}>{t('admin.author.sessions.new')}</Button>
        )}
      </View>
      <ManageTableSmall
        focusedId={focusedEventId}
        tableInstance={tableInstance}
        getEntityId={event => event.data.id}
        hideSelect
        onViewDetails={id =>
          getGlobalRouter().navigate({ to: `/manage/in-person-events/${eventGroupId}/${id}` })
        }
        mapEntityToCsv={entity =>
          eventGroupToCsv({
            eventGroupId: entity.data.id,
            data: entity.data,
            assignedUsers: entity.data.assignedUsers.length,
          })
        }
        translations={{
          searchPlaceholder: t('search.sessions'),
          tableLoading: t('manage.sessions.table-loading'),
          tableNoResults: t('manage.sessions.no-results'),
          csvPrefix: t('content.sessions'),
        }}
      />
    </View>
  )
}
