import { TargetAndTransition, motion } from 'framer-motion'
import { useAtomValue } from 'jotai'
import { helperStateAtom } from 'sierra-client/views/course-helper/atoms'
import {
  desktopHelperConfig,
  helperInlineStates,
  useDesktopHelperBorderRadius,
  useHelperVisibility,
} from 'sierra-client/views/course-helper/config'
import { RenderHelperContent } from 'sierra-client/views/course-helper/content'
import { DesktopBubble, DesktopContentWrapper } from 'sierra-client/views/course-helper/shared/containers'
import { useHelperSize } from 'sierra-client/views/course-helper/shared/helper-animation-context'
import { isSecondaryHelperStyling } from 'sierra-client/views/course-helper/shared/should-show-box-shadow'
import { HelperVisibility } from 'sierra-client/views/course-helper/types'
import { color } from 'sierra-ui/color'
import styled, { useTheme } from 'styled-components'

const { bubbleDuration, fadeDuration } = desktopHelperConfig

const LayerIsolation = styled.div`
  position: relative;
  isolation: isolate;
`

const contentVariants: Record<HelperVisibility, (delay: number) => TargetAndTransition> = {
  hidden: (delay: number) => ({
    opacity: 0,
    transition: {
      delay: delay,
      duration: fadeDuration,
    },
  }),
  visible: (delay: number) => ({
    opacity: 1,
    transition: {
      delay,
      duration: fadeDuration,
    },
  }),
}

const Inner = styled(motion.div).attrs({
  variants: contentVariants,
})`
  overflow: hidden;
  pointer-events: auto;
`

export const DesktopHelperInternal: React.FC = () => {
  const helperState = useAtomValue(helperStateAtom)
  const visibility: HelperVisibility = useHelperVisibility()
  const borderRadius = useDesktopHelperBorderRadius()
  const { size, ref, animationEnabled } = useHelperSize()

  const theme = useTheme()
  const backgroundColor = color(theme.home.backgroundColor)
  const isSecondaryStyling = isSecondaryHelperStyling(helperState)
  const isInlineState = helperInlineStates.includes(helperState.type)
  const seeThrough = isSecondaryStyling ? { backgroundColor: backgroundColor } : undefined

  return (
    <LayerIsolation>
      <DesktopContentWrapper ref={ref} $seeThrough={seeThrough} $disableHover={!isInlineState}>
        <Inner
          key={helperState.type}
          custom={animationEnabled ? bubbleDuration : 0}
          initial='hidden'
          animate={visibility}
        >
          <RenderHelperContent />
        </Inner>
      </DesktopContentWrapper>
      <DesktopBubble
        $seeThrough={seeThrough}
        custom={{
          opacity: isInlineState ? 0.85 : undefined,
          size,
          duration: animationEnabled ? bubbleDuration : 0,
          borderRadius,
        }}
        initial='hidden'
        animate={visibility}
      />
    </LayerIsolation>
  )
}
