import {
  SetIntervalWorkerMessage,
  SetIntervalWorkerResponse,
} from 'sierra-client/features/sana-now/presence/set-interval-worker/set-interval-worker-types'
import { logger } from 'sierra-client/logger/logger'
import IntervalWorker from './set-interval.worker?worker&inline'

export interface SetIntervalWorker extends Worker {
  postMessage(message: SetIntervalWorkerMessage): void
  onmessage: ((this: Worker, ev: MessageEvent<SetIntervalWorkerResponse>) => any) | null
}

export const createSetIntervalWorker = (): SetIntervalWorker | null => {
  try {
    return new IntervalWorker()
  } catch (error) {
    logger.error('Failed to create SetIntervalWorker', { error })
    return null
  }
}
