import { CourseSettingsValidation } from 'sierra-client/state/author-course-settings/types'
import { CourseKind, CourseSettings, LinkSettings } from 'sierra-domain/api/common'

function isValidLinkSettings(linkSettings: LinkSettings | undefined): boolean {
  try {
    new URL(linkSettings?.url ?? '')
  } catch (_) {
    return false
  }
  return true
}

export const validateSettings = (settings: CourseSettings, kind: CourseKind): CourseSettingsValidation[] => {
  if (kind === 'native:live') return []

  const errors: CourseSettingsValidation[] = []

  if (kind === 'linkedin' || kind === 'native:self-paced') {
    if (settings.durationMinutes < 1 && !settings.automaticDuration) {
      errors.push({
        scope: 'settings',
        field: 'durationMinutes',
        i18nArgs: ['content.validation.course-title'],
      })
    }
    return errors
  }

  if (settings.title.length === 0) {
    errors.push({
      scope: 'settings',
      field: 'title',
      i18nArgs: ['content.validation.course-title'],
    })
  }

  if (kind === 'link') {
    if (!isValidLinkSettings(settings.linkSettings)) {
      errors.push({
        scope: 'settings',
        field: 'linkSettings',
        i18nArgs: ['content.validation.link-invalid'],
      })
    }
  }

  return errors
}
