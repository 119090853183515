import { isElementType, parentType } from 'sierra-client/views/v3-author/queries'
import { isValidInteractiveCardChild } from 'sierra-domain/v3-author'
import { createParagraph } from 'sierra-domain/v3-author/create-blocks'
import { Editor, Element, Node, Transforms } from 'slate'

const debug = (...messages: unknown[]): void => console.debug('[withHomeworkCard]', ...messages)

export const withHomeworkContainer = (editor: Editor): Editor => {
  const { normalizeNode } = editor

  editor.normalizeNode = entry => {
    const [node, path] = entry

    if (isElementType('homework-card', node)) {
      const children = Array.from(Node.children(editor, path))

      if (parentType(editor, path) !== 'editor') {
        debug('homework-card must be at the root, unwrapping at', JSON.stringify(path))
        return Transforms.unwrapNodes(editor, { at: path })
      }

      //wrap invalid children in paragraph
      for (const [child, childPath] of children) {
        if (Element.isElement(child) && isValidInteractiveCardChild(child) === false) {
          debug(
            `converting unexpected ${child.type} in ${node.type} to paragraph at`,
            JSON.stringify(childPath)
          )
          return Transforms.wrapNodes(editor, createParagraph(), { at: childPath })
        }
      }

      // check if children contains a ValidInteractiveCardChild otherwise add a paragraph
      const hasValidInteractiveCardChild = children.some(([child]) => isValidInteractiveCardChild(child))

      if (!hasValidInteractiveCardChild) {
        const at = path.concat(0)
        debug(`Adding missing ValidInteractiveCardChild at`, JSON.stringify(at))
        return Transforms.insertNodes(editor, createParagraph(), { at })
      }
    }
    normalizeNode(entry)
  }

  return editor
}
