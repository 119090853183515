import * as React from 'react'

type ComponentModule<P = object> = { default: React.ComponentType<P> }
export type DynamicLoader<P = object> = () => Promise<React.ComponentType<P> | ComponentModule<P>>

export type DynamicOptions = {
  loading?: React.ReactNode
}

/** Normalize loader to return the module as form { default: Component } for `React.lazy`. */
function convertModule<P>(mod: React.ComponentType<P> | ComponentModule<P>): ComponentModule<P> {
  // eslint-disable-next-line @typescript-eslint/strict-boolean-expressions, @typescript-eslint/no-unnecessary-condition
  return { default: (mod as ComponentModule<P>)?.default || mod }
}

/**
 * A composite of {@link React.lazy} and {@link React.Suspense}, similar to Next's `dynamic` function.
 * - https://nextjs.org/docs/pages/building-your-application/optimizing/lazy-loading
 * - https://github.com/vercel/next.js/blob/canary/packages/next/src/shared/lib/dynamic.tsx
 */
export function dynamic<TProps = object>(
  loader: DynamicLoader<TProps>,
  options?: DynamicOptions
): (props: TProps) => JSX.Element {
  const Component = React.lazy(() => loader().then(convertModule))

  return (props: TProps) => (
    <React.Suspense fallback={options?.loading ?? null}>
      <Component
        // eslint-disable-next-line @typescript-eslint/no-explicit-any
        {...(props as any)}
      />
    </React.Suspense>
  )
}
