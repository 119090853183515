import { View } from 'sierra-ui/primitives'
import styled, { css } from 'styled-components'

const Layer = styled(View)<{
  $passive?: boolean
  $blur?: boolean
  withFade?: boolean
  $backgroundImageUrl?: string
}>`
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  border-radius: 24px;
  border: none;
  overflow: hidden;

  ${p =>
    p.$backgroundImageUrl !== undefined &&
    css`
      background-image: url(${p.$backgroundImageUrl});
      background-size: cover;
      background-position: center center;
    `}

  ${p =>
    p.withFade === true &&
    css`
      background: linear-gradient(to bottom, transparent, rgba(0, 0, 0, 0.4));
    `};

  ${p =>
    p.$passive === true &&
    css`
      pointer-events: none;

      & * {
        pointer-events: auto;
      }
    `};

  ${p =>
    p.$blur === true &&
    css`
      backdrop-filter: blur(2px);
      -webkit-backdrop-filter: blur(10px);
    `}
  video {
    max-width: 100%;
  }
`

const Container = styled.div<{ $position?: 'absolute' | 'fixed'; larger?: boolean; shadow?: boolean }>`
  position: ${p => p.$position || 'absolute'};
  transition: 300ms ease-in-out;
  transition-delay: 500ms;
  right: 24px;
  top: 24px;
  width: 120px !important;
  height: 120px !important;
  overflow: hidden;
  border-radius: 24px;
  border: none;
  -webkit-border-radius: 24px;
  -moz-border-radius: 24px;
  overflow: hidden;

  [data-hover='true'] {
    opacity: 0;
  }

  &:hover {
    [data-hover='true'] {
      opacity: 1;
    }
  }

  ${p =>
    p.shadow === true &&
    css`
      box-shadow: 0px 16px 24px 0px rgba(0, 0, 0, 0.08);
    `}

  ${p =>
    p.larger === true &&
    css`
      transition: 300ms ease-in-out;
      width: 160px !important;
      height: 160px !important;
      border-radius: 32px;
    `}
`

export const Layers = { Container, Layer }
