import { produce } from 'immer'
import _ from 'lodash'
import { DynamicT } from 'sierra-client/hooks/use-translation/types'
import { labelToString } from 'sierra-client/lib/filter/components/common'
import { valueId } from 'sierra-client/lib/filter/components/predicate-utils'
import { getAvatarColorAt } from 'sierra-client/utils/random-avatar-color'
import { findAttributeIndex, getAttribute } from 'sierra-client/views/manage/components/user-attributes/utils'
import { UserId } from 'sierra-domain/api/uuid'
import { DomainChoices, DomainWithParentChoices, ValueRep } from 'sierra-domain/filter/datatype/domain'
import { Value, ValueWithoutExerciseAndFile } from 'sierra-domain/filter/datatype/value'
import { CoursesPerUser } from 'sierra-domain/user-attributes/courses-per-user'
import { UserAttributesConfig } from 'sierra-domain/user-attributes/user-attributes-config'
import { UserInvitationAttribute } from 'sierra-domain/user-attributes/user-invitation-attribute'
import {
  UserCustomAttributeDomainRepChoices,
  UserCustomAttributeDomainRepType,
  UserCustomAttributeDomainRepWithParentChoices,
  UserInvitationDomainRep,
} from 'sierra-domain/user-attributes/user-invitation-domain-rep'
import { UserValidationErrorsByEmail } from 'sierra-domain/user-attributes/user-invitation-validation-error'
import { AvatarStackUserShape, LabelMenuItem } from 'sierra-ui/components'
import z from 'zod'

const zodEmail = z.string().email()

/* Should be synced with the backend */
const EMAIL_PATTERN = new RegExp(
  '^(([^<>()\\[\\].,;:\\s@"]+(\\.[^<>()\\[\\].,;:\\s@"]+)*)|(".+"))@((\\[[0-9]{1,3}\\.[0-9]{1,3}\\.[0-9]{1,3}\\.[0-9]{1,3}])|(([a-zA-Z\\-0-9]+\\.)+[a-zA-Z]{2,}))$'
)

export const isEmail = (email: string): boolean => EMAIL_PATTERN.test(email)

export const splitEmail = (email: string): { identifier: string; domain: string } => {
  const split = zodEmail.parse(email)
  const parsedEmail = split.split('@')
  const identifier = parsedEmail[0]
  const domain = parsedEmail[1]

  return {
    identifier: identifier ?? '',
    domain: domain ?? '',
  }
}

export const userShapeFromEmail = (email: string, index: number): AvatarStackUserShape => {
  const { identifier, domain } = splitEmail(email)

  return {
    // This UUID does not represent a user in our database, but it's needed since we'll pass this to a lot of
    // components that expect it. It would be nice to be able to avoid doing this.
    // We'll make sure to not use a valid UUID just in case it's sent to the backend by mistake.
    uuid: ('invalid_uuid_' + email) as UserId,
    firstName: identifier,
    lastName: domain,
    avatarColor: getAvatarColorAt(index),
  }
}

export const addOrReplaceAttributes = (
  config: UserAttributesConfig,
  emails: string[],
  newAttributes: UserInvitationAttribute[]
): UserAttributesConfig => {
  const updatedConfig = produce(config, draft => {
    emails.forEach(email => {
      const draftUser = draft[email]

      if (draftUser?.data === undefined) {
        throw new Error(`Could not find user (${email}) when adding attributes.`)
      }

      newAttributes.forEach(newAttribute => {
        const oldAttributeIndex = findAttributeIndex(draftUser.data, newAttribute.ref)

        // Attribute couldn't be found, add
        if (oldAttributeIndex === -1) {
          draftUser.data.push(newAttribute)
        } else {
          // Attribute found, replace
          draftUser.data.splice(oldAttributeIndex, 1, newAttribute)
        }
      })
    })
  })

  return updatedConfig
}

export const getValuesFromConfig = (
  userInvitationDomainRep: UserInvitationDomainRep,
  userAttributeConfig: UserAttributesConfig,
  email: string
): ValueWithoutExerciseAndFile[] | undefined => {
  const attributeInConfig = getAttribute(userInvitationDomainRep.ref, email, userAttributeConfig)

  if (attributeInConfig !== undefined) {
    const currentValue = attributeInConfig.values

    return currentValue
  }

  return undefined
}

export const getValueFromConfig = (
  userInvitationDomainRep: UserInvitationDomainRep,
  userAttributeConfig: UserAttributesConfig,
  email: string
): ValueWithoutExerciseAndFile | undefined => {
  const valuesForEmail = getValuesFromConfig(userInvitationDomainRep, userAttributeConfig, email)

  if (valuesForEmail !== undefined) {
    const currentValue = valuesForEmail[0]

    if (currentValue === undefined || currentValue.type === 'value.none') {
      throw new Error('Attribute set but has no value.')
    }

    return currentValue
  }

  return undefined
}

export const createValueRep = (value: ValueRep['value'], label: ValueRep['label']): ValueRep => ({
  value,
  label,
})

export const valueRepToMenuLabelItem = (
  valueRep: ValueRep | undefined,
  dynamicT: DynamicT
): LabelMenuItem | undefined => {
  if (valueRep === undefined) {
    return undefined
  }

  return {
    type: 'label',
    id: valueId(valueRep.value),
    label: labelToString(valueRep.label, dynamicT),
  }
}

export const getValueRepFromDomainChoices = (
  choicesDomain: DomainChoices | DomainWithParentChoices,
  choiceValueId: string,
  throwErrorOnUnknownValues: boolean = true
): DomainChoices['choices'][0] => {
  const valueFromChoice = choicesDomain.choices.find(c => valueId(c.value) === choiceValueId)

  if (valueFromChoice === undefined) {
    if (throwErrorOnUnknownValues) throw new Error(`Could not find value rep for ${choiceValueId}.`)
    return {
      label: { type: 'label.default', label: choiceValueId.split(':')[1] ?? '' },
      value: { type: 'value.string', value: choiceValueId.split(':')[1] ?? '' },
    }
  }

  return valueFromChoice
}

export const getCommonValueRepForChoices = (
  domainRep: UserCustomAttributeDomainRepChoices | UserCustomAttributeDomainRepWithParentChoices,
  userAttributesConfig: UserAttributesConfig,
  emails: string[]
): ValueRep | undefined => {
  const firstEmail = emails[0]

  if (firstEmail === undefined) {
    return undefined
  }

  const currentValueForFirst = getValueFromConfig(domainRep, userAttributesConfig, firstEmail)

  if (currentValueForFirst === undefined) {
    return undefined
  }

  const valueRep = getValueRepFromDomainChoices(domainRep.domain, valueId(currentValueForFirst))

  // One email
  if (emails.length === 1) {
    return valueRep
  } else {
    // Several emails
    const currentValues = emails.map(e => getValueFromConfig(domainRep, userAttributesConfig, e))
    const allUsersHaveTheSameValue = currentValues.every(v => _.isEqual(v, currentValueForFirst))

    if (!allUsersHaveTheSameValue) {
      return createValueRep(
        { type: 'value.string', value: 'mixed' },
        {
          type: 'label.translation',
          translationKey: 'manage.users.invite.bulk-editing.mixed',
          defaultLabel: 'Mixed',
        }
      )
    }

    return valueRep
  }
}

// The following three functions cover the three use cases for domains (TypeOf, Choices, AutoComplete)

export const getCommonValueForTypeOf = (
  domainRep: UserCustomAttributeDomainRepType,
  userAttributesConfig: UserAttributesConfig,
  emails: string[],
  dynamicT: DynamicT
): ValueWithoutExerciseAndFile['value'] | undefined => {
  const firstEmail = emails[0]

  if (firstEmail === undefined) {
    return undefined
  }

  const currentValueForFirst = getValueFromConfig(domainRep, userAttributesConfig, firstEmail)
  const fallback = currentValueForFirst === undefined ? '' : currentValueForFirst.value

  if (emails.length === 1) {
    return fallback
  } else {
    // Several emails
    const currentValues = emails.map(e => getValueFromConfig(domainRep, userAttributesConfig, e))
    const allUsersHaveTheSameValues = currentValues.every(v => _.isEqual(v, currentValueForFirst))

    if (!allUsersHaveTheSameValues) {
      return dynamicT('manage.users.invite.bulk-editing.mixed')
    }

    return fallback
  }
}

export const getCommonValueListDomainChoices = (
  choicesDomainRep: UserCustomAttributeDomainRepChoices,
  userAttributesConfig: UserAttributesConfig,
  emails: string[]
): ValueRep[] | undefined => {
  const values: Record<string, Value[] | undefined> = {}

  emails.forEach(email => {
    const valuesForEmail = getValuesFromConfig(choicesDomainRep, userAttributesConfig, email)
    values[email] = valuesForEmail
  })

  const firstEmail = emails[0]

  if (firstEmail === undefined) {
    return undefined
  }

  const first = values[firstEmail]

  const allUsersHaveTheSameValues = emails.every(email => {
    const valuesForEmail = values[email]

    return _.isEqual(valuesForEmail, first)
  })

  if (!allUsersHaveTheSameValues) {
    return undefined
  }

  const valueReps: ValueRep[] =
    first?.map(value => getValueRepFromDomainChoices(choicesDomainRep.domain, valueId(value))) ?? []

  return valueReps
}

export const getCoursesForUser = (coursesPerUser: CoursesPerUser, email: string): string[] => {
  const coursesForUser = coursesPerUser[email]

  if (coursesForUser === undefined) {
    throw new Error(`Could not find ${email} in course config.`)
  }

  return coursesForUser
}

export const getCommonCourseIdsForEmails = (
  coursesPerUser: CoursesPerUser,
  emails: string[]
): string[] | undefined => {
  const coursesForUsers = emails.map(email => getCoursesForUser(coursesPerUser, email))

  const first = coursesForUsers[0]

  const allUsersHaveTheSameValues = coursesForUsers.every(v => _.isEqual(v, first))

  if (!allUsersHaveTheSameValues) {
    return undefined
  }

  return first
}

export const updateCoursesPerUser = (
  coursesPerUser: CoursesPerUser,
  emails: string[],
  courseIds: string[]
): CoursesPerUser => {
  const updatedCoursesPerUser = produce(coursesPerUser, draft => {
    emails.forEach(email => {
      const draftUser = draft[email]

      if (draftUser === undefined) {
        throw new Error(`Could not find user (${email}) when adding courses.`)
      }

      const uniqueCourseIds = _.uniq([...courseIds])
      draft[email] = uniqueCourseIds
    })
  })
  return updatedCoursesPerUser
}

export const addUsersToAttributesConfig = (
  config: UserAttributesConfig,
  emails: string[],
  accessLevelAttribute: UserInvitationAttribute,
  accessRoleAttribute: UserInvitationAttribute | undefined
): UserAttributesConfig => {
  const result = produce(config, draft => {
    for (const email of emails) {
      draft[email] = {
        data: [accessLevelAttribute, accessRoleAttribute].filter((a): a is UserInvitationAttribute => !!a),
      }
    }
  })
  return result
}

export const addUsersToCoursesPerUser = (config: CoursesPerUser, emails: string[]): CoursesPerUser => {
  const result = produce(config, draft => {
    for (const email of emails) {
      draft[email] = []
    }
  })
  return result
}

export const isEmptyInvitationErrors = (invitationErrorsByMail: UserValidationErrorsByEmail): boolean => {
  const emails = Object.keys(invitationErrorsByMail)

  for (const email of emails) {
    const errorsForUser = invitationErrorsByMail[email]

    if (errorsForUser !== undefined && errorsForUser.length > 0) {
      return false
    }
  }

  return true
}
