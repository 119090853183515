import { getAssetContextFromLearnEntity } from 'sierra-client/components/util/asset-contex'
import { useResolveAsset } from 'sierra-client/hooks/use-resolve-asset'
import { getGlobalRouter } from 'sierra-client/router'
import { useDispatch } from 'sierra-client/state/hooks'
import { courseHelperRecommendationClicked } from 'sierra-client/views/course-helper/logger'
import { Thumbnail } from 'sierra-client/views/workspace/components'
import { convertLearnEntityToLinkable } from 'sierra-client/views/workspace/utils/entity-to-linkable'
import { detailsUrl } from 'sierra-client/views/workspace/utils/urls'
import { LearnEntity } from 'sierra-domain/api/entities'
import { Text, View } from 'sierra-ui/primitives'
import styled from 'styled-components'

const StyledThumbnail = styled(Thumbnail)`
  width: 80px;
  min-height: 50px;
  max-height: 50px;
  margin-right: 0px;
  border-radius: 8px;
`

export const RecommendationDisplay: React.FC<{
  entity: LearnEntity
  metadata: JSX.Element
  recommendationType: 'assigned' | 'recommendations'
}> = ({ entity, metadata, recommendationType }) => {
  const dispatch = useDispatch()

  const assetContext = getAssetContextFromLearnEntity(entity)
  const thumbnailSrc = useResolveAsset({
    image: entity.image,
    size: 'default',
    assetContext,
  })
  const link = detailsUrl(convertLearnEntityToLinkable(entity))

  return (
    <View
      direction='row'
      justifyContent='flex-start'
      alignItems='center'
      onClick={() => {
        void getGlobalRouter().navigate({ to: link as string })
        void dispatch(
          courseHelperRecommendationClicked({
            typeOfRecommendation: recommendationType,
          })
        )
      }}
      cursor='pointer'
    >
      <View>
        <StyledThumbnail lazy={true} image={thumbnailSrc} />
      </View>
      <View direction='column' grow justifyContent='center' alignItems='flex-start'>
        <Text bold color='foreground/primary'>
          {entity.title}
        </Text>
        {metadata}
      </View>
    </View>
  )
}
