import React from 'react'
import { ComponentShortcut, Group, Shortcut } from 'sierra-client/components/shortcut-menu/types'
import { TranslationKey } from 'sierra-client/hooks/use-translation/types'
import { assertNever } from 'sierra-domain/utils'

export const groupLabels: Record<Group, TranslationKey> = {
  'developer-tools': 'shortcut-menu.groups.developer-tools',
  'navigation': 'shortcut-menu.groups.navigation',
  'commands': 'shortcut-menu.groups.commands',
  'other': 'shortcut-menu.groups.other',
  'create': 'shortcut-menu.groups.create',
  'live': 'shortcut-menu.groups.live',
}

export type Action =
  | { type: 'shortcuts-updated'; shortcuts: Shortcut[] }
  | { type: 'register-shortcut'; shortcut: Shortcut }
  | { type: 'unregister-shortcut'; label: string }
  | { type: 'close' }
  | { type: 'back' }
  | { type: 'open' }
  | { type: 'nest'; label: string }
  | { type: 'update-component'; label: string; Component: React.ReactNode | React.FC }

export type State = {
  shortcuts: Shortcut[]
  isOpen: boolean
  nestedShortcut?: ComponentShortcut
  components?: { [label: string]: React.ReactNode | React.FC }
}

export function step(state: State, action: Action): State {
  switch (action.type) {
    case 'shortcuts-updated':
      return { ...state, shortcuts: action.shortcuts }
    case 'open':
      return { ...state, isOpen: true }
    case 'close': {
      const { nestedShortcut, ...filteredState } = state
      return { ...filteredState, isOpen: false }
    }
    case 'back': {
      const { nestedShortcut, ...filteredState } = state
      if (nestedShortcut === undefined) return { ...filteredState, isOpen: false }
      else return { ...filteredState }
    }
    case 'nest': {
      const shortcut = state.shortcuts.find(it => it.label === action.label)
      if (shortcut?.type !== 'component') return state
      return { ...state, nestedShortcut: shortcut }
    }
    case 'register-shortcut':
      return {
        ...state,
        shortcuts: [...state.shortcuts, action.shortcut],
      }
    case 'unregister-shortcut':
      return {
        ...state,
        shortcuts: state.shortcuts.filter(it => it.label !== action.label),
      }
    case 'update-component':
      return {
        ...state,
        components: {
          ...state.components,
          [action.label]: action.Component,
        },
      }
    default:
      assertNever(action)
  }
}

export const initialState: State = {
  shortcuts: [],
  isOpen: false,
}
