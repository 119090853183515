import _ from 'lodash'
import {
  assertElementType,
  getParent,
  isElementType,
  parentType,
} from 'sierra-client/views/v3-author/queries'
import { isQuestionBody } from 'sierra-client/views/v3-author/question-card/utils'
import { nanoid12 } from 'sierra-domain/nanoid-extensions'
import {
  QuestionCardMultipleChoiceAlternative,
  QuestionCardMultipleChoiceAlternativeChildren,
  isValidInteractiveCardChild,
} from 'sierra-domain/v3-author'
import {
  createParagraph,
  createQuestionCardMatchThePairsAlternative,
  createQuestionCardMatchThePairsAlternativeOption,
  createQuestionCardMultipleChoiceAlternative,
  createQuestionCardMultipleChoiceBody,
  createQuestionMultipleChoiceAlternativeExplanation,
  createQuestionMultipleChoiceAlternativeOption,
} from 'sierra-domain/v3-author/create-blocks'
import { Editor, Element, Node, Path, Range, Text, Transforms } from 'slate'

const { insertNodes, removeNodes, wrapNodes, unwrapNodes, mergeNodes } = Transforms
const { isElement } = Element

const debug = (...messages: unknown[]): void => console.debug('[withQuestionCard]', ...messages)

const withQuestionCard = (editor: Editor): Editor => {
  const { normalizeNode } = editor

  editor.normalizeNode = entry => {
    const [node, path] = entry

    if (!isElementType('question-card', node)) return normalizeNode(entry)

    const children = Array.from(Node.children(editor, path))
    const header = children.find(([child]) => isValidInteractiveCardChild(child))
    const questionBody = children.find(([child]) => isQuestionBody(child))

    // Add missing review flag
    if (node.includeInPlacementTestAndReview === undefined) {
      return Transforms.setNodes(editor, { includeInPlacementTestAndReview: true }, { at: path })
    }

    // Add missing paragraph
    if (header === undefined) {
      debug('adding missing paragraph in question-card', JSON.stringify(path))
      return insertNodes(editor, createParagraph(), { at: path.concat(0) })
    }

    // Add missing question body
    if (questionBody === undefined) {
      debug('adding missing question body in question-card', JSON.stringify(path))
      return insertNodes(editor, createQuestionCardMultipleChoiceBody(), { at: path.concat(1) })
    }

    const [, questionBodyPath] = questionBody
    const questionBodyIndex = _.last(questionBodyPath) ?? 1

    for (const [child, childPath] of children) {
      const index = _.last(childPath) ?? 0

      // Convert invalid nodes before the question body into a paragraph
      if (index < questionBodyIndex && !isValidInteractiveCardChild(child)) {
        debug('Converting to paragraph at', JSON.stringify(childPath))
        return wrapNodes(editor, createParagraph(), { at: childPath })
      }

      // Remove nodes after the question body
      if (index > questionBodyIndex) {
        debug('Removing nodes after question body at', JSON.stringify(childPath))
        return removeNodes(editor, { at: childPath })
      }
    }

    return normalizeNode(entry)
  }

  return editor
}

const withQuestionCardAlternativeChildren = (editor: Editor): Editor => {
  const { normalizeNode } = editor

  editor.normalizeNode = entry => {
    const [node, path] = entry

    if (
      !isElementType(
        [
          'question-card-multiple-choice-alternative-option',
          'question-card-multiple-choice-alternative-explanation',
        ],
        node
      )
    )
      return normalizeNode(entry)

    for (const [childNode, childPath] of Node.children(editor, path)) {
      if (!Text.isText(childNode)) {
        debug(`Unwrapping unexpected node at ${JSON.stringify(childPath)}`)
        return Transforms.unwrapNodes(editor, { at: childPath, voids: true, mode: 'all' })
      }
    }

    return normalizeNode(entry)
  }

  return editor
}

const withQuestionCardFreeTextBody = (editor: Editor): Editor => {
  const { normalizeNode } = editor

  editor.normalizeNode = entry => {
    const [node, path] = entry

    if (!isElementType('question-card-free-text-body', node)) {
      return normalizeNode(entry)
    }

    const parent = parentType(editor, path)
    if (parent !== 'question-card') {
      debug(`Unwrapping unexpected question-card-free-text-body at`, path)
      return unwrapNodes(editor, { at: path, voids: true, mode: 'all' })
    }

    // Should only have paragraph children
    const children = Array.from(Node.children(editor, path))

    for (const [childNode, childPath] of children) {
      if (!isElementType('paragraph', childNode)) {
        debug(`Wrapping unexpected node in paragraph at ${JSON.stringify(path)}`)
        return Transforms.wrapNodes(editor, createParagraph(), { at: childPath })
      }
    }

    // Should have at least one
    if (children.length < 1) {
      debug(`Adding missing paragraph to question header at ${JSON.stringify(path)}`)
      return insertNodes(
        editor,
        {
          type: 'paragraph',
          id: nanoid12(),
          children: [{ text: '' }],
        },
        { at: path.concat(0) }
      )
    }

    return normalizeNode(entry)
  }

  return editor
}

const withQuestionCardMultipleChoiceBody = (editor: Editor): Editor => {
  const { normalizeNode } = editor

  editor.normalizeNode = entry => {
    const [node, path] = entry

    if (
      !(
        isElementType('question-card-pick-the-best-option-body', node) ||
        isElementType('question-card-select-all-that-apply-body', node)
      )
    ) {
      return normalizeNode(entry)
    }

    const parent = parentType(editor, path)
    if (parent !== 'question-card') {
      debug(`Unwrapping unexpected question-card-body at`, path)
      return unwrapNodes(editor, { at: path, voids: true, mode: 'all' })
    }

    const children = Array.from(Node.children(editor, path))

    for (const [childNode, childPath] of children) {
      if (!isElementType('question-card-multiple-choice-alternative', childNode)) {
        debug(
          `Only question alternatives allowed here. Removing at ${JSON.stringify(childNode)} ${JSON.stringify(
            path
          )}`
        )
        return removeNodes(editor, { at: childPath })
      }
    }

    // Should have at least two
    if (children.length < 2) {
      debug(`Adding new question alternative at ${JSON.stringify(path)}`)
      return insertNodes(editor, createQuestionCardMultipleChoiceAlternative({ status: 'incorrect' }), {
        at: path.concat(Math.max(0, children.length - 1)),
      })
    }

    // Should only have one correct alternative in pick-the-best-option
    if (isElementType('question-card-pick-the-best-option-body', node)) {
      let encounteredCorrect = false

      for (const [childNode, childPath] of children) {
        assertElementType('question-card-multiple-choice-alternative', childNode)
        const { status } = childNode

        if (encounteredCorrect && status === 'correct') {
          return Transforms.setNodes(editor, { status: 'incorrect' }, { at: childPath })
        }

        if (status === 'correct') {
          encounteredCorrect = true
        }
      }
    }

    return normalizeNode(entry)
  }

  return editor
}

const withQuestionCardMultipleChoiceAlternativeOption = (editor: Editor): Editor => {
  const { normalizeNode, deleteBackward, insertBreak } = editor

  editor.deleteBackward = unit => {
    const { selection } = editor

    if (selection && Range.isCollapsed(selection)) {
      const [multipleChoiceAlternativeOption] = Editor.nodes(editor, {
        match: isElementType('question-card-multiple-choice-alternative-option'),
      })

      if (multipleChoiceAlternativeOption !== undefined) {
        const [node, path] = multipleChoiceAlternativeOption
        // If the option is empty, delete the alternative
        if (_.isEqual(isElement(node) && node.children[0], { text: '' })) {
          debug(`Deleting empty question alternative at: ${JSON.stringify(path)}`)
          return removeNodes(editor, { at: Path.parent(path) })
        }
      }
    }

    return deleteBackward(unit)
  }

  editor.insertBreak = () => {
    const { selection } = editor
    if (selection && Range.isCollapsed(selection)) {
      // When pressing enter in a question-card-multiple-choice-alternative-option, add a new alterantive
      const [multipleChoiceAlternativeOption] = Editor.nodes(editor, {
        match: isElementType('question-card-multiple-choice-alternative-option'),
      })

      if (multipleChoiceAlternativeOption !== undefined) {
        const [, parentPath] = getParent(editor, multipleChoiceAlternativeOption)
        const newAlternative = createQuestionCardMultipleChoiceAlternative({ status: 'incorrect' })

        debug(`Pressed enter, adding new question alternative as sibling at ${JSON.stringify(parentPath)}`)
        Transforms.insertNodes(editor, newAlternative, {
          at: Path.next(parentPath),
        })

        return Transforms.move(editor, { distance: 2, unit: 'line' })
      }
    }

    insertBreak()
  }

  editor.normalizeNode = entry => {
    const [node, path] = entry

    if (!isElementType('question-card-multiple-choice-alternative-option', node)) {
      return normalizeNode(entry)
    }

    // Check for correct parent (question-card-multiple-choice-alternative)
    if (parentType(editor, path) !== 'question-card-multiple-choice-alternative') {
      debug(`Unwrapping unexpected question card multiple choice alternative explanation at`, path)
      return Transforms.unwrapNodes(editor, { at: path })
    }

    // Check for correct content (only text)
    const children = Array.from(Node.children(editor, path))

    for (const [child, childPath] of children) {
      if (isElement(child)) {
        debug(`Question option must contain text. Unwrapping ${child.type} at ${JSON.stringify(childPath)}`)
        return unwrapNodes(editor, { at: childPath })
      }
    }

    return normalizeNode(entry)
  }

  return editor
}

const withQuestionCardMultipleChoiceAlternativeExplanation = (editor: Editor): Editor => {
  const { normalizeNode, deleteBackward, insertBreak } = editor

  editor.deleteBackward = unit => {
    return deleteBackward(unit)
  }

  editor.insertBreak = () => {
    const { selection } = editor
    if (selection && Range.isCollapsed(selection)) {
      // When pressing enter in a question-card-multiple-choice-alternative-explanation, add a new alterantive
      const [multipleChoiceAlternativeExplanation] = Editor.nodes(editor, {
        match: isElementType('question-card-multiple-choice-alternative-explanation'),
      })

      if (multipleChoiceAlternativeExplanation !== undefined) {
        const [, parentPath] = getParent(editor, multipleChoiceAlternativeExplanation)
        const newAlternative = createQuestionCardMultipleChoiceAlternative({ status: 'incorrect' })

        debug(`Pressed enter, adding new question alternative as sibling at ${JSON.stringify(parentPath)}`)
        Transforms.insertNodes(editor, newAlternative, {
          at: Path.next(parentPath),
        })

        return Transforms.move(editor, { distance: 1, unit: 'line' })
      }
    }

    insertBreak()
  }

  editor.normalizeNode = entry => {
    const [node, path] = entry

    if (!isElementType('question-card-multiple-choice-alternative-explanation', node)) {
      return normalizeNode(entry)
    }

    // Check for correct parent (question-card-multiple-choice-alternative-2)
    if (parentType(editor, path) !== 'question-card-multiple-choice-alternative') {
      debug(`Unwrapping unexpected question card multiple choice alternative explanation at`, path)
      return unwrapNodes(editor, { at: path, voids: true })
    }

    // Check for correct content (only text)
    const children = Array.from(Node.children(editor, path))

    for (const [child, childPath] of children) {
      if (isElement(child)) {
        debug(
          `Question explanation must contain text. Unwrapping ${child.type} at ${JSON.stringify(childPath)}`
        )
        return unwrapNodes(editor, { at: childPath })
      }
    }

    return normalizeNode(entry)
  }

  return editor
}

const validAlternativeChildrenTypes: Record<QuestionCardMultipleChoiceAlternativeChildren['type'], null> = {
  'question-card-multiple-choice-alternative-option': null,
  'question-card-multiple-choice-alternative-explanation': null,
}

function isValidQuestionCardAlternativeChildrenNode(
  node: unknown
): node is QuestionCardMultipleChoiceAlternative {
  return Element.isElement(node) && Object.keys(validAlternativeChildrenTypes).includes(node.type)
}

const withQuestionCardMultipleChoiceAlternative = (editor: Editor): Editor => {
  const { normalizeNode } = editor

  editor.normalizeNode = entry => {
    const [node, path] = entry

    if (!isElementType('question-card-multiple-choice-alternative', node)) {
      return normalizeNode(entry)
    }

    // Check for correct parent (question-multiple-choice-body)
    if (
      parentType(editor, path) !== 'question-card-pick-the-best-option-body' &&
      parentType(editor, path) !== 'question-card-select-all-that-apply-body'
    ) {
      debug(`Unwrapping unexpected question card multiple choice alternative at`, path)
      return unwrapNodes(editor, { at: path, voids: true })
    }

    // Check for correct content (only text)
    const children = Array.from(Node.children(editor, path))

    // Check if it's an old alternative and convert if so
    for (const [childNode, childPath] of children) {
      if (Text.isText(childNode)) {
        debug(
          `Old question alternative not allowed here. Wrapping in option at ${JSON.stringify(
            childNode
          )} ${JSON.stringify(path)}`
        )

        return Transforms.wrapNodes(
          editor,
          { type: 'question-card-multiple-choice-alternative-option', id: nanoid12(), children: [] },
          { at: childPath }
        )
      }
    }

    const option = children.find(([child]) =>
      isElementType('question-card-multiple-choice-alternative-option', child)
    )
    const explanation = children.find(([child]) =>
      isElementType('question-card-multiple-choice-alternative-explanation', child)
    )

    // Add missing option
    if (option === undefined) {
      debug('adding missing option in alternative', JSON.stringify(path))
      return insertNodes(editor, createQuestionMultipleChoiceAlternativeOption(), { at: path.concat(0) })
    }

    // Add missing explanation
    if (explanation === undefined) {
      debug('adding missing explanation in alternative', JSON.stringify(path))
      return insertNodes(editor, createQuestionMultipleChoiceAlternativeExplanation(), { at: path.concat(1) })
    }

    const [, explanationPath] = explanation
    const explanationIndex = _.last(explanationPath) ?? 1

    for (const [child, childPath] of children) {
      const index = _.last(childPath) ?? 0

      // Convert invalid nodes before the question body into an option
      if (index < explanationIndex && !isValidQuestionCardAlternativeChildrenNode(child)) {
        debug('Converting to option at', JSON.stringify(childPath))
        return wrapNodes(editor, createQuestionMultipleChoiceAlternativeOption(), { at: childPath })
      }

      // Remove nodes after the question body
      if (index > explanationIndex) {
        debug('Removing nodes after explanation at', JSON.stringify(childPath))
        return removeNodes(editor, { at: childPath })
      }
    }

    // Merge all options and explanations
    if (children.length > 2) {
      debug('Merge all options', JSON.stringify(path))
      return mergeNodes(editor, {
        match: (n, p) =>
          isElementType('question-card-multiple-choice-alternative-option', n) &&
          _.isEqual(_.dropRight(p, 1), path),
        at: path.concat(1),
      })
    }

    if (children.length > 2) {
      debug('Merge all explanations', JSON.stringify(path))
      return mergeNodes(editor, {
        match: (n, p) =>
          isElementType('question-card-multiple-choice-alternative-explanation', n) &&
          _.isEqual(_.dropRight(p, 1), path),
        at: path.concat(1),
      })
    }

    return normalizeNode(entry)
  }

  return editor
}

// ---

const withQuestionCardMatchThePairsBody = (editor: Editor): Editor => {
  const { normalizeNode } = editor

  editor.normalizeNode = entry => {
    const [node, path] = entry

    if (!isElementType('question-card-match-the-pairs-body', node)) {
      return normalizeNode(entry)
    }

    const parent = parentType(editor, path)
    if (parent !== 'question-card') {
      debug(`Unwrapping unexpected question-card-body at`, path)
      return unwrapNodes(editor, { at: path, voids: true, mode: 'all' })
    }

    const children = Array.from(Node.children(editor, path))

    for (const [childNode, childPath] of children) {
      if (!isElementType('question-card-match-the-pairs-alternative', childNode)) {
        debug(
          `Only match the pairs alternatives allowed here. Removing at ${JSON.stringify(
            childNode
          )} ${JSON.stringify(path)}`
        )
        return removeNodes(editor, { at: childPath })
      }
    }

    // Should have at least two
    if (children.length < 2) {
      debug(`Adding new question alternative at ${JSON.stringify(path)}`)
      return insertNodes(editor, createQuestionCardMatchThePairsAlternative(), {
        at: path.concat(Math.max(0, children.length - 1)),
      })
    }

    return normalizeNode(entry)
  }

  return editor
}

const withQuestionCardMatchThePairsAlternative = (editor: Editor): Editor => {
  const { normalizeNode } = editor

  editor.normalizeNode = entry => {
    const [node, path] = entry

    if (!isElementType('question-card-match-the-pairs-alternative', node)) {
      return normalizeNode(entry)
    }

    // Check for correct parent (question-multiple-choice-body)
    if (parentType(editor, path) !== 'question-card-match-the-pairs-body') {
      debug(`Unwrapping unexpected question card match the pairs alternative at`, path)
      return unwrapNodes(editor, { at: path, voids: true })
    }

    let optionCount = 0
    for (const [childNode, childPath] of Node.children(editor, path)) {
      if (isElementType('question-card-match-the-pairs-alternative-option', childNode)) {
        if (optionCount >= 2) {
          debug(`Removing extra option at ${JSON.stringify(childPath)}`)
          return removeNodes(editor, { at: childPath })
        }
        optionCount++
      } else {
        // todo: or wrap?
        debug(`Removing unexpected node in option at ${JSON.stringify(childPath)}`)
        return removeNodes(editor, { at: childPath })
      }
    }

    // Add missing option
    if (optionCount < 2) {
      debug('adding missing options in alternative', JSON.stringify(path))
      return insertNodes(editor, createQuestionCardMatchThePairsAlternativeOption(), { at: path.concat(0) })
    }

    return normalizeNode(entry)
  }

  return editor
}

const withQuestionCardMatchThePairsAlternativeOption = (editor: Editor): Editor => {
  const { normalizeNode, deleteBackward, insertBreak } = editor

  editor.deleteBackward = unit => {
    const { selection } = editor

    if (selection && Range.isCollapsed(selection)) {
      const [MatchThePairsAlternativeOption] = Editor.nodes(editor, {
        match: isElementType('question-card-match-the-pairs-alternative-option'),
      })

      if (MatchThePairsAlternativeOption !== undefined) {
        const [node, path] = MatchThePairsAlternativeOption
        // If the option is empty, delete the alternative
        if (_.isEqual(isElement(node) && node.children[0], { text: '' })) {
          debug(`Deleting empty question alternative at: ${JSON.stringify(path)}`)
          return removeNodes(editor, { at: Path.parent(path) })
        }
      }
    }

    return deleteBackward(unit)
  }

  editor.insertBreak = () => {
    const { selection } = editor
    if (selection && Range.isCollapsed(selection)) {
      // When pressing enter in a question-card-match-the-pairs-alternative-option, add a new alterantive
      const [MatchThePairsAlternativeOption] = Editor.nodes(editor, {
        match: isElementType('question-card-match-the-pairs-alternative-option'),
      })

      if (MatchThePairsAlternativeOption !== undefined) {
        const [, parentPath] = getParent(editor, MatchThePairsAlternativeOption)
        const newAlternative = createQuestionCardMatchThePairsAlternative()

        debug(`Pressed enter, adding new question alternative as sibling at ${JSON.stringify(parentPath)}`)
        Transforms.insertNodes(editor, newAlternative, {
          at: Path.next(parentPath),
        })

        return Transforms.move(editor, { distance: 2, unit: 'line' })
      }
    }

    insertBreak()
  }

  editor.normalizeNode = entry => {
    const [node, path] = entry

    if (!isElementType('question-card-match-the-pairs-alternative-option', node)) {
      return normalizeNode(entry)
    }

    // Check for correct parent (question-card-match-the-pairs-alternative)
    if (parentType(editor, path) !== 'question-card-match-the-pairs-alternative') {
      debug(`Unwrapping unexpected question card multiple choice alternative explanation at`, path)
      return Transforms.unwrapNodes(editor, { at: path })
    }

    // Check for correct content (only text)
    const children = Array.from(Node.children(editor, path))

    for (const [child, childPath] of children) {
      if (isElement(child)) {
        debug(`Question option must contain text. Unwrapping ${child.type} at ${JSON.stringify(childPath)}`)
        return unwrapNodes(editor, { at: childPath })
      }
    }

    return normalizeNode(entry)
  }

  return editor
}

export const withQuestionCards: (editor: Editor) => Editor = _.flow([
  withQuestionCard,
  withQuestionCardFreeTextBody,
  withQuestionCardMultipleChoiceBody,
  withQuestionCardMultipleChoiceAlternative,
  withQuestionCardMultipleChoiceAlternativeOption,
  withQuestionCardMultipleChoiceAlternativeExplanation,
  withQuestionCardAlternativeChildren,
  withQuestionCardMatchThePairsBody,
  withQuestionCardMatchThePairsAlternative,
  withQuestionCardMatchThePairsAlternativeOption,
])
