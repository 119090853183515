import { graphql } from 'sierra-client/api/graphql/gql'
import { ContentKind } from 'sierra-client/api/graphql/gql/graphql'
import { graphQuery } from 'sierra-client/api/hooks/use-graphql-query'
import {
  ProgramEntity,
  ProgramEntityCourseStep,
  ProgramEntityPathStep,
} from 'sierra-client/state/content/types'
import { createRootStateAsyncThunk } from 'sierra-client/state/create-root-state-async-thunk'
import { CourseKind } from 'sierra-domain/api/common'
import { ProgramId } from 'sierra-domain/api/uuid'
import { assertNever } from 'sierra-domain/utils'

const fetchProgramQuery = graphql(`
  query FetchProgramQuery($programId: ProgramId!) {
    program(id: $programId, version: null) {
      name
      visibility
      steps {
        __typename
        ... on CourseProgramStep {
          course {
            courseId
            title
            courseKind
          }
        }
        ... on PathProgramStep {
          path {
            pathId
            courses {
              courseId
              title
              courseKind
            }
          }
        }
      }
    }
  }
`)

const fetchProgramEnrollmentQuery = graphql(`
  query FetchProgramEnrollmentQuery($programId: ProgramId!) {
    me {
      enrollment(programId: $programId) {
        progress {
          passedAt
          progress
        }
        steps {
          __typename
          ... on UserProgramCourseStep {
            courseId
            availableAt
            progress {
              passedAt
            }
            course {
              __typename
              courseKind
              title
            }
          }
          ... on UserProgramPathStep {
            pathId
            availableAt
            progress {
              passedAt
              courses {
                courseId
                passedAt
                course {
                  title
                  courseKind
                }
              }
            }
          }
        }
      }
    }
  }
`)

const contentTypeNameToCourseKind: Record<ContentKind, CourseKind> = {
  LINK: 'link',
  LINKEDIN: 'linkedin',
  SCORM: 'scorm',
  NATIVE_SELF_PACED: 'native:self-paced',
  NATIVE_LIVE: 'native:live',
  NATIVE_COURSE_GROUP: 'native:course-group',
  SCORM_COURSE_GROUP: 'scorm:course-group',
  NATIVE_EVENT_GROUP: 'native:event-group',
}

export class ProgramError extends Error {
  constructor(message: string) {
    super(message)
    this.name = 'ProgramError'
  }
}

export const fetchProgramById = createRootStateAsyncThunk(
  'program/fetch-program-by-id',
  async ({ programId }: { programId: ProgramId }): Promise<ProgramEntity> => {
    const programResponse = await graphQuery(fetchProgramQuery, { programId })
    const enrollmentResponse = await graphQuery(fetchProgramEnrollmentQuery, { programId })

    const program = programResponse.program ?? undefined
    const enrollment = enrollmentResponse.me.enrollment ?? undefined

    if (program === undefined) {
      // This means no access
      throw new ProgramError('Program could not be loaded')
    }

    const programSteps = program.steps.flatMap<ProgramEntity['steps'][number]>(gqlStep => {
      switch (gqlStep.__typename) {
        case 'EmailProgramStep':
          return []
        case 'CourseProgramStep':
          // null means that this user does not have permission to see this course
          // if we think this should not happen, the backend must make sure that all content is available
          return gqlStep.course === null || gqlStep.course === undefined
            ? []
            : [
                {
                  type: 'course',
                  title: gqlStep.course.title,
                  courseKind: contentTypeNameToCourseKind[gqlStep.course.courseKind],
                  id: gqlStep.course.courseId,
                  passedAt: undefined,
                  availableAt: undefined,
                } satisfies ProgramEntityCourseStep,
              ]
        case 'PathProgramStep':
          return gqlStep.path === null || gqlStep.path === undefined
            ? []
            : [
                {
                  type: 'path',
                  id: gqlStep.path.pathId,
                  passedAt: undefined,
                  availableAt: undefined,
                  courses: gqlStep.path.courses.map(c => ({
                    type: 'course',
                    title: c.title,
                    courseKind: contentTypeNameToCourseKind[c.courseKind],
                    id: c.courseId,
                    passedAt: undefined,
                  })),
                } satisfies ProgramEntityPathStep,
              ]
        default:
          assertNever(gqlStep)
      }
    })
    const enrollmentSteps = enrollment?.steps.flatMap<ProgramEntity['steps'][number]>(step => {
      switch (step.__typename) {
        case 'UserProgramCourseStep': {
          return [
            {
              type: 'course',
              title: step.course.title,
              courseKind: contentTypeNameToCourseKind[step.course.courseKind],
              id: step.courseId,
              passedAt: step.progress.passedAt ?? undefined,
              availableAt: step.availableAt ?? undefined,
            } satisfies ProgramEntityCourseStep,
          ]
        }
        case 'UserProgramPathStep':
          return [
            {
              type: 'path',
              id: step.pathId,
              passedAt: step.progress.passedAt ?? undefined,
              availableAt: step.availableAt ?? undefined,
              courses: step.progress.courses.map(c => ({
                type: 'course',
                title: c.course.title,
                courseKind: contentTypeNameToCourseKind[c.course.courseKind],
                id: c.courseId,
                passedAt: c.passedAt ?? undefined,
              })),
            } satisfies ProgramEntityPathStep,
          ]
        case 'UserProgramEmailStep': {
          return []
        }
        default:
          assertNever(step)
      }
    })

    return {
      id: programId,
      name: program.name,
      passedAt: enrollment?.progress.passedAt ?? undefined,
      steps: enrollmentSteps ?? programSteps,
    }
  }
)
