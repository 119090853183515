import { UseQueryResult } from '@tanstack/react-query'
import { useAtom } from 'jotai'
import _ from 'lodash'
import React, { useState } from 'react'
import { SkillId } from 'sierra-client/api/graphql/branded-types'
import { graphql } from 'sierra-client/api/graphql/gql'
import { LeaderboardQuery } from 'sierra-client/api/graphql/gql/graphql'
import { useGraphQuery, useInvalidateGraphQuery } from 'sierra-client/api/hooks/use-graphql-query'
import { OpenSelectSkillModalAtom } from 'sierra-client/features/skills/components/learner-board/atoms'
import { Board } from 'sierra-client/features/skills/components/learner-board/board'
import { LevelUpBoard } from 'sierra-client/features/skills/components/learner-board/level-up-board'
import {
  YourSkillsView,
  YourSkillsViewProps,
} from 'sierra-client/features/skills/components/learner-board/your-skills'
import { SelectSkillsModal } from 'sierra-client/features/skills/components/select-skills/select-skills'
import {
  getMySkillProgressQuery,
  skillLeaderboardSettingsQuery,
  useInvalidateGetMySkillsContentQuery,
} from 'sierra-client/features/skills/shared-gql-queries'
import { useTranslation } from 'sierra-client/hooks/use-translation'
import { useIsTablet } from 'sierra-client/state/browser/selectors'
import { HomePadding } from 'sierra-client/views/learner/home/types'
import { isDefined, isEmptyArray, isNonEmptyArray } from 'sierra-domain/utils'
import { Icon, Tabs } from 'sierra-ui/components'
import { ScrollView, Spacer, Text, View } from 'sierra-ui/primitives'
import { token } from 'sierra-ui/theming'
import { v2_breakpoint } from 'sierra-ui/theming/breakpoints'
import styled from 'styled-components'
import { LeaderboardView, LeaderboardViewProps } from './leaderboard'

const TOP_LEARNERS_DEFAULT = 3

const leaderboardQuery = graphql(`
  query Leaderboard($top: Int!, $skillId: SkillId, $excludeMe: Boolean = false) {
    topSkillLearners(limit: $top, skillId: $skillId) {
      skillLearners {
        badges {
          skillId
          skillName
          skillLevelIndex
          skillLevelName
          badgeIcon
          badgeTheme
        }
        userInfo {
          id
          firstName
          lastName
          avatar {
            ...AvatarFragment
          }
        }
        rank
      }
      totalUsersWithBadges
    }

    me @skip(if: $excludeMe) {
      id
      firstName
      lastName
      avatar {
        ...AvatarFragment
      }
      skillRanking {
        rank
        badges {
          skillId
          skillName
          skillLevelIndex
          skillLevelName
          badgeIcon
          badgeTheme
        }
      }
    }
  }
`)

const subscribersQuery = graphql(`
  query SkillSubscribers($skillId: SkillId!) {
    skillSubscribers(id: $skillId) {
      totalCount
    }
  }
`)

const useLeaderboard = ({
  top = TOP_LEARNERS_DEFAULT,
  skillId,
  excludeMe = false,
  skip = false,
}: {
  top?: number
  skillId?: SkillId
  excludeMe?: boolean
  skip?: boolean
}): UseQueryResult<LeaderboardQuery, unknown> => {
  return useGraphQuery(
    { document: leaderboardQuery, queryOptions: { enabled: !skip } },
    { top, skillId, excludeMe }
  )
}

type LeaderboardProps = {
  top?: number
  skillId?: SkillId
  excludeMe?: boolean
}

const FullScrollView = styled(ScrollView)`
  height: 100%;
  width: 100%;
`

const HalfView = styled(View)`
  width: 50%;
  height: 100%;
`

export const Leaderboard: React.FC<
  Pick<LeaderboardProps, 'top'> & Pick<LeaderboardViewProps, 'separateMe'>
> = ({ separateMe, top = TOP_LEARNERS_DEFAULT }) => {
  const res = useLeaderboard({ top })
  return (
    <FullScrollView paddingTop='12'>
      <LeaderboardView res={res} top={TOP_LEARNERS_DEFAULT} separateMe={separateMe} />
    </FullScrollView>
  )
}

export const YourSkills: React.FC<YourSkillsViewProps> = ({ showLeaderboard }) => {
  return (
    <FullScrollView direction='column'>
      <YourSkillsView showLeaderboard={showLeaderboard} />
    </FullScrollView>
  )
}

const EmptyLearnersWrapper = styled(View)`
  padding: 80px 76px;
  outline: 2px solid ${token('border/default')};
  border-radius: 16px;
`
const EmptyLearners: React.FC = () => {
  const { t } = useTranslation()
  return (
    <EmptyLearnersWrapper gap='none' direction='column' alignItems='center' justifyContent='center'>
      <Icon iconId='skill--graduation--cap' />
      <Spacer size='16' />
      <Text size='small' bold>
        {t('skills.learners.empty.title')}
      </Text>
      <Text size='small' color='foreground/muted'>
        {t('skills.learners.empty.subtitle')}
      </Text>
    </EmptyLearnersWrapper>
  )
}

export const TopLearners: React.FC<Pick<LeaderboardProps, 'top' | 'skillId'>> = ({
  top = TOP_LEARNERS_DEFAULT,
  skillId,
}) => {
  const { t } = useTranslation()
  const subscribersCountRes = useGraphQuery(
    {
      document: subscribersQuery,
      queryOptions: {
        enabled: isDefined(skillId),
        select: data => {
          return data.skillSubscribers.totalCount
        },
      },
    },
    { skillId: skillId as SkillId }
  )
  const res = useGraphQuery(
    {
      document: leaderboardQuery,
      queryOptions: {
        select: ({ me, ...rest }) => {
          // Make sure for top learners for a specific skill, we only show the badges for that skill
          if (!me || !skillId) {
            return { me, ...rest }
          }
          const filteredSkillRank = {
            ...me.skillRanking,
            rank: undefined,
            badges: me.skillRanking?.badges.filter(badge => badge.skillId === skillId) ?? [],
          }
          const filteredMe = { ...me, skillRanking: filteredSkillRank }
          return { me: filteredMe, ...rest }
        },
      },
    },
    { top, skillId }
  )

  const totalUsersWithBadges = res.data?.topSkillLearners.totalUsersWithBadges

  const hasSubscribers = (subscribersCountRes.data ?? 0) > 0
  const hasSubscribersWithBadges = (totalUsersWithBadges ?? 0) > 0

  // Remove the specific case of having subscribers but nobody has achieved a badge yet
  if (hasSubscribers && !hasSubscribersWithBadges) {
    return null
  }

  return (
    <View direction='column' gap='16' style={{ width: '100%', height: '100%' }}>
      <Text bold color='foreground/secondary'>
        {_.capitalize(t('skills.top-learners'))}
      </Text>
      {
        // No subscribeds, show empty learner state
        !hasSubscribers ? (
          <EmptyLearners />
        ) : (
          // only show leaderboard if we have subscribers with badges
          hasSubscribersWithBadges && <LeaderboardView top={top} show='level' res={res} />
        )
      }
    </View>
  )
}

export const HomepageLeaderboard: React.FC = () => {
  const { t } = useTranslation()
  const isTablet = useIsTablet()

  return (
    <Board
      title={isTablet ? undefined : _.capitalize(t('dictionary.leaderboard'))}
      disableBorder
      disableManageButton
    >
      <Leaderboard top={TOP_LEARNERS_DEFAULT} />
    </Board>
  )
}

const StyledDiv = styled.div<{ $homeInlinePadding: HomePadding }>`
  display: flex;
  flex-flow: row nowrap;
  padding-inline: ${p => p.$homeInlinePadding.start}px ${p => p.$homeInlinePadding.end}px;

  @media screen and (max-width: ${v2_breakpoint.tablet}) {
    display: block;
  }
`

type Tabs = 'your-skills' | 'leaderboard'

export const SkillsHomepageGallery: React.FC<
  Pick<LeaderboardProps, 'top'> & { homeInlinePadding: HomePadding }
> = ({ top, homeInlinePadding }) => {
  const [selectSkillOpen, setSelectSkillOpen] = useAtom(OpenSelectSkillModalAtom)
  const invalidateMySkillProgressQuery = useInvalidateGraphQuery(getMySkillProgressQuery)
  const invalidateMySkillContentQuery = useInvalidateGetMySkillsContentQuery()
  const isTablet = useIsTablet()
  const [activeTab, setActiveTab] = useState<Tabs>('your-skills')
  const { t } = useTranslation()

  const res = useLeaderboard({ top })
  const userSkillsRes = useGraphQuery({ document: getMySkillProgressQuery }, {})
  const skillSettingsRes = useGraphQuery({ document: skillLeaderboardSettingsQuery }, {})

  const invalidateQueries = (): void => {
    void invalidateMySkillProgressQuery()
    void invalidateMySkillContentQuery()
  }

  const topLearners = res.data?.topSkillLearners.skillLearners
  const userSkills = userSkillsRes.data?.me.skills ?? []
  const skillSettings = skillSettingsRes.data?.skillSettings ?? { showLeaderboard: true }
  const hideLeaderLevelUpBoards = isEmptyArray(userSkills)
  const showLeaderboard =
    skillSettings.showLeaderboard && isDefined(topLearners) && isNonEmptyArray(topLearners)

  if (hideLeaderLevelUpBoards) {
    return (
      <StyledDiv $homeInlinePadding={homeInlinePadding}>
        <YourSkills showLeaderboard={showLeaderboard} />
        <SelectSkillsModal
          variant='learner-manage'
          open={selectSkillOpen}
          onCancel={() => {
            setSelectSkillOpen(false)
          }}
          onComplete={() => {
            setSelectSkillOpen(false)
            invalidateQueries()
          }}
        />
      </StyledDiv>
    )
  }

  return (
    <StyledDiv $homeInlinePadding={homeInlinePadding}>
      {isTablet ? (
        <Tabs
          hideLine
          items={[
            {
              content: <YourSkills showLeaderboard={showLeaderboard} />,
              id: 'your-skills',
              label: _.capitalize(t('skills.board.your-skills')),
            },
            {
              content: showLeaderboard ? <HomepageLeaderboard /> : <LevelUpBoard />,
              id: 'leaderboard',
              label: _.capitalize(
                t(showLeaderboard ? 'dictionary.leaderboard' : 'skills.board.level-up.title')
              ),
            },
          ]}
          onChange={setActiveTab}
          value={activeTab}
        />
      ) : (
        <>
          <HalfView>
            <YourSkills showLeaderboard={showLeaderboard} />
          </HalfView>
          <HalfView>{showLeaderboard ? <HomepageLeaderboard /> : <LevelUpBoard />}</HalfView>
        </>
      )}
      <SelectSkillsModal
        variant='learner-manage'
        open={selectSkillOpen}
        onCancel={() => {
          setSelectSkillOpen(false)
        }}
        onComplete={() => {
          setSelectSkillOpen(false)
          invalidateQueries()
        }}
      />
    </StyledDiv>
  )
}
