import { atom, useAtomValue, useSetAtom } from 'jotai'
import _ from 'lodash'
import { useEffect, useId, useMemo } from 'react'
import { useDeepEqualityMemo } from 'sierra-client/hooks/use-deep-equality-memo'
import {
  ControlProperty,
  ControlValue,
  DebugReturnValues,
  DebugValues,
  Properties,
  controlsAtom,
} from 'sierra-client/lib/use-debug/atom'

function toProperty(property: ControlValue): ControlProperty {
  if (typeof property === 'number') return { type: 'number', value: property }
  if (typeof property === 'boolean') return { type: 'boolean', value: property }
  if (typeof property === 'string') return { type: 'string', value: property }
  if (Array.isArray(property)) {
    if (typeof property[0] === 'string')
      return {
        type: 'list',
        value: property[0],
        values:
          // eslint-disable-next-line @typescript-eslint/no-explicit-any
          property as any,
      }
  }
  if (typeof property === 'object' && 'min' in property) {
    return { type: 'range', value: property.value, min: property.min, max: property.max, step: property.step }
  }
  if (typeof property === 'object' && 'color' in property) {
    return { type: 'color', value: property.color }
  }
  throw new Error(`Unknown property type ${JSON.stringify(property)}`)
}

function fromProperty(property: ControlProperty): string | number | boolean {
  return property.value
}

export function useDebug<Values extends DebugValues>(
  name: string,
  _values: Values
): DebugReturnValues<Values> {
  const values = useDeepEqualityMemo(_values)
  const properties = useMemo(() => {
    const result: Properties = {}
    for (const [name, value] of Object.entries(values)) {
      result[name] = toProperty(value)
    }
    return result
  }, [values])
  const propertiesAtom = useMemo(() => atom(properties), [properties])
  const setControlGroups = useSetAtom(controlsAtom)
  const id = useId()

  useEffect(() => {
    setControlGroups(prev => {
      if (prev.some(it => it.id === id)) throw new Error('Duplicate control group id ${id}')
      return [...prev, { id, name, properties: propertiesAtom }]
    })
    return () => {
      setControlGroups(prev => {
        return prev.filter(it => it.id !== id)
      })
    }
  }, [id, name, propertiesAtom, setControlGroups])

  const debugValues = useAtomValue(propertiesAtom)
  return useMemo(() => {
    const result = {} as DebugReturnValues<Values>
    for (const [name, value] of Object.entries(debugValues)) {
      _.set(result, name, fromProperty(value))
    }
    return result
  }, [debugValues])
}
