import { Separator } from '@radix-ui/react-separator'
import { mapValues } from 'lodash'
import { PageTitle } from 'sierra-client/components/common/page-title'
import { useTranslation } from 'sierra-client/hooks/use-translation'
import { PageHeader } from 'sierra-client/views/settings/components/page-header'
import { SettingsSection } from 'sierra-client/views/settings/components/settings-section'
import { notificationCategories } from 'sierra-client/views/settings/emails-and-notifications/common'
import { EmailNotificationsToggle } from 'sierra-client/views/settings/emails-and-notifications/email'
import { MSTeamsNotificationsSettings } from 'sierra-client/views/settings/emails-and-notifications/microsoft-teams'
import { NotificationsSection } from 'sierra-client/views/settings/emails-and-notifications/notifications-section'
import { SectionSkeleton } from 'sierra-client/views/settings/emails-and-notifications/section-skeleton'
import { SlackNotificationsSettings } from 'sierra-client/views/settings/emails-and-notifications/slack'
import { TranslationSettingsSection } from 'sierra-client/views/settings/emails-and-notifications/translation-settings-section'
import { useOrgNotificationSettings } from 'sierra-client/views/settings/emails-and-notifications/use-org-notification-settings'
import {
  OrgNotificationSettingsResponse,
  PatchOrgNotificationSettingsRequest,
} from 'sierra-domain/api/org-notification-settings'
import { View } from 'sierra-ui/primitives'
import { token } from 'sierra-ui/theming'
import styled from 'styled-components'

const ChannelBox = styled(View).attrs({ direction: 'column', gap: 'none' })`
  padding: 24px;
  min-height: 96px;
  border-radius: 16px;
  box-shadow:
    0px 2px 4px 0px #00000014,
    0px 0px 0px 1px #0000000a;
  background: ${token('elevated/background')};
`

const ChannelSection: React.FC<{
  orgSettings: OrgNotificationSettingsResponse
  onChange: (_: PatchOrgNotificationSettingsRequest) => void
}> = ({ orgSettings, onChange }) => {
  return (
    <SettingsSection
      iconId='send--outline'
      title={'settings.notifications-page.notifications-channel.title'}
      subtitle={'settings.notifications-page.notifications-channel.subtitle'}
    >
      <View direction='column' gap='12'>
        <ChannelBox>
          <EmailNotificationsToggle emailSettings={orgSettings.emailSettings} onChange={onChange} />
        </ChannelBox>
        <ChannelBox>
          <SlackNotificationsSettings slackSettings={orgSettings.slackSettings} onChange={onChange} />
        </ChannelBox>
        <ChannelBox>
          <MSTeamsNotificationsSettings teamsSettings={orgSettings.msTeamsSettings} onChange={onChange} />
        </ChannelBox>
      </View>
    </SettingsSection>
  )
}

const NotificationsListSection: React.FC<{
  settings: OrgNotificationSettingsResponse
  onChange: (_: PatchOrgNotificationSettingsRequest) => void
}> = ({ settings, onChange }) => {
  const categories = notificationCategories

  return (
    <SettingsSection
      iconId='notification'
      title='settings.notifications-page.notifications-list.title'
      subtitle='settings.notifications-page.notifications-list.subtitle'
    >
      <View direction='column' gap='40'>
        {Object.entries(categories).map(([key, category]) => (
          <NotificationsSection
            key={key}
            category={category}
            settings={settings.notificationSettings}
            onChange={settings => onChange({ notificationSettings: mapValues(settings, s => s.state) })}
          />
        ))}
      </View>
    </SettingsSection>
  )
}

const Page = styled(View).attrs({
  direction: 'column',
  gap: 'none',
  grow: true,
})``

const Divider = styled(Separator)`
  height: 1px;
  background-color: ${token('form/border/1')};
`

export const EmailsAndNotifications: React.FC = () => {
  const { t } = useTranslation()
  const { result: settingsRequest, mutate } = useOrgNotificationSettings()

  const error = settingsRequest.isError
  const loading = settingsRequest.isLoading || settingsRequest.data === undefined

  if (error) {
    return <h1>Something went wrong</h1>
  }

  function onSettingsChange(settings: PatchOrgNotificationSettingsRequest): void {
    mutate(settings)
  }

  return (
    <>
      <PageTitle title={t('settings.notifications-page.title')} />

      <Page>
        <PageHeader
          title='settings.notifications-page.title'
          subtitle='settings.notifications-page.subtitle'
          helpLink='https://help.sana.ai/en/articles/96582-customizing-your-sana-domain'
        />

        {loading ? (
          <>
            <SectionSkeleton />
            <Divider orientation='horizontal' />
            <SectionSkeleton />
          </>
        ) : (
          <>
            <ChannelSection orgSettings={settingsRequest.data} onChange={onSettingsChange} />
            <TranslationSettingsSection settings={settingsRequest.data} onChange={onSettingsChange} />
            <NotificationsListSection settings={settingsRequest.data} onChange={onSettingsChange} />
          </>
        )}
      </Page>
    </>
  )
}
