import { useAtomValue } from 'jotai'
import { useEffect, useState } from 'react'
import {
  FolderIcon,
  NestedItemList,
  collapsableSidebarStateAtom,
} from 'sierra-client/features/collapsable-sidebar'
import { AnimatedOutlineCSS } from 'sierra-client/views/flexible-content/editor/content-sidebar/browse-folder'
import { File, Folder } from 'sierra-domain/flexible-content/types'
import { isDefined } from 'sierra-domain/utils'
import { TruncatedText } from 'sierra-ui/components'
import { IconButton, View } from 'sierra-ui/primitives'
import styled from 'styled-components'

const FolderTitleRow = styled(View).attrs({
  cursor: 'pointer',
  alignItems: 'center',
})`
  user-select: none;
  gap: 10px;
  padding: 8px 4px 8px 10px;
  height: 36px;
`

const FolderContainer = styled(View)<{ $hasBorder: boolean }>`
  ${AnimatedOutlineCSS};
`

export const RenderFolder = ({
  node,
  currentCard,
  children,
}: {
  node: Folder
  currentCard: File | undefined
  children?: JSX.Element[]
}): JSX.Element => {
  const [open, setOpen] = useState(true)
  const isCurrentCardInFolder = isDefined(currentCard) && node.nodeIds.includes(currentCard.id)
  const sidebarState = useAtomValue(collapsableSidebarStateAtom)

  useEffect(() => {
    if (sidebarState === 'collapsed') {
      setOpen(isCurrentCardInFolder)
    }
  }, [sidebarState, isCurrentCardInFolder, setOpen])

  return (
    <FolderContainer
      $hasBorder={isCurrentCardInFolder && sidebarState === 'collapsed'}
      direction='column'
      gap='none'
    >
      <FolderTitleRow onClick={() => setOpen(isOpen => !isOpen)}>
        <FolderIcon
          isCompleted={false}
          numberOfContent={node.nodeIds.length}
          isCurrentItem={isCurrentCardInFolder}
        />
        <TruncatedText
          lines={1}
          size='small'
          bold
          color={isCurrentCardInFolder ? 'foreground/primary' : 'foreground/secondary'}
        >
          {node.title}
        </TruncatedText>

        <IconButton
          size='small'
          variant='transparent'
          iconId={open ? 'chevron--up--small' : 'chevron--down--small'}
        />
      </FolderTitleRow>
      <NestedItemList open={open}>{children}</NestedItemList>
    </FolderContainer>
  )
}
