import { useMemo } from 'react'
import { useTranslation } from 'sierra-client/hooks/use-translation'
import {
  checkState,
  NotificationCategoryInfo,
  NotificationListTitles,
  SettingsList,
} from 'sierra-client/views/settings/emails-and-notifications/common'
import { NotificationSettings, NotificationState } from 'sierra-domain/api/org-notification-settings'
import { View } from 'sierra-ui/primitives'

export type NotificationsSectionProps = {
  category: NotificationCategoryInfo
  settings: NotificationSettings
  onChange: (value: NotificationSettings) => void
}

export const NotificationsSection: React.FC<NotificationsSectionProps> = ({
  category,
  settings,
  onChange,
}) => {
  const { t } = useTranslation()

  const { title, iconId } = category

  const settingItems = useMemo(
    () =>
      category.notifications.map(notification => {
        const { state, disabled } = settings[notification.id]
        return {
          id: notification.id,
          checked: state === NotificationState.Enabled,
          disabled,
          title: notification.title,
          subtitle: notification.subtitle,
        }
      }),
    [category, settings]
  )

  return (
    <View direction='column' gap='16'>
      <NotificationListTitles title={t(title)} iconId={iconId} />
      <SettingsList
        settings={settingItems}
        onClick={(id, checked) => {
          onChange({ ...settings, [id]: { ...settings[id], state: checkState(checked) } })
        }}
      />
    </View>
  )
}
