import { FCC } from 'sierra-client/types'
import { Icon } from 'sierra-ui/components'
import { Text } from 'sierra-ui/primitives'
import styled from 'styled-components'

const FolderIconWrapper = styled.div`
  position: relative;
  display: flex;
  align-items: center;
  justify-content: center;
  height: 16px;
  width: 16px;
`

const FolderIconTextWrapper = styled.div`
  position: absolute;
  display: flex;
  align-items: center;
  justify-content: center;
  left: 0;
  bottom: 0;
  height: 13px;
  width: 13px;
`

const FolderIconText = styled(Text)`
  font-size: 8px;
  line-height: 12px;
`

export const FolderIcon: FCC<{
  numberOfContent: number
  isCompleted: boolean
  isCurrentItem: boolean
}> = ({ numberOfContent, isCompleted, isCurrentItem }) => {
  if (isCompleted) {
    return (
      <Icon
        iconId='course--module--completed'
        color={isCurrentItem ? 'foreground/primary' : 'foreground/muted'}
      />
    )
  }

  return (
    <FolderIconWrapper>
      <Icon iconId='course--module' color={isCurrentItem ? 'foreground/primary' : 'foreground/muted'} />
      <FolderIconTextWrapper>
        <FolderIconText size='technical' color={isCurrentItem ? 'foreground/primary' : 'foreground/muted'}>
          {numberOfContent}
        </FolderIconText>
      </FolderIconTextWrapper>
    </FolderIconWrapper>
  )
}
