import _ from 'lodash'
import React, { useEffect, useMemo, useState } from 'react'
import testSpeakerSound from 'sierra-client/assets/sounds/smart-progression.mp3'
import { RouterLink } from 'sierra-client/components/common/link'
import { useLiveContext } from 'sierra-client/components/liveV2/live-context'
import { LocalAudioIndicator } from 'sierra-client/components/liveV2/live-layer/audio-indicator-wrapper'
import { SettingsButton } from 'sierra-client/components/liveV2/live-layer/toolbar'
import { ToolbarItem } from 'sierra-client/components/liveV2/live-layer/toolbar-item'
import { LIVE_SPEAKER_HELP } from 'sierra-client/config/links'
import { useToggle } from 'sierra-client/hooks/use-toggle'
import { useTranslation } from 'sierra-client/hooks/use-translation'
import { useDispatch, useSelector } from 'sierra-client/state/hooks'
import { disableVideo, enableVideo, muteAudio, unmuteAudio } from 'sierra-client/state/live/actions'
import {
  selectAudioState,
  selectAvailableCameras,
  selectAvailableMicrophones,
  selectCameraPermission,
  selectMicrophonePermission,
  selectVideoState,
} from 'sierra-client/state/live/selectors'
import { useCameraSettings } from 'sierra-client/views/liveV2/settings/use-camera-settings'
import { useMicrophonesSettings } from 'sierra-client/views/liveV2/use-microphone-settings'
import { isDefined } from 'sierra-domain/utils'
import { color } from 'sierra-ui/color'
import { Icon, MenuItem } from 'sierra-ui/components'
import { AudioIndicator } from 'sierra-ui/missions/live'
import { Skeleton, Text, View } from 'sierra-ui/primitives'
import { ButtonStyles, usePrimitiveButtonPseudoStyles } from 'sierra-ui/primitives/button/button'
import { SingleSelectDropdown } from 'sierra-ui/primitives/menu-dropdown'
import { palette } from 'sierra-ui/theming'
import { fonts } from 'sierra-ui/theming/fonts'
import { minWidth } from 'sierra-ui/utils/media-query-styles'
import styled, { css } from 'styled-components'
import useSound from 'use-sound'

const ControlContainerCss = css<{ active: boolean }>`
  width: 36px;
  height: 36px;
  display: flex;
  justify-content: center;
  align-items: center;

  border-radius: 10px;
  background: ${p => !p.active && color('white').opacity(0.15)};
  border: none;
  &:hover {
    ${p => !p.active && `background: ${color('white').opacity(0.3)};`}
  }

  backdrop-filter: blur(17px);
`

const SettingsControlContainer = styled(SettingsButton).attrs({
  renderedIn: 'pre-lobby',
  iconColor: 'white',
})`
  ${ControlContainerCss}
`

const ControlContainer = styled(ToolbarItem)`
  ${ControlContainerCss}
`

const MicrophoneControl: React.FC<{
  setShowAutoMutedMessage: (show: boolean) => void
  disableAutoMute: React.MutableRefObject<boolean>
}> = ({ setShowAutoMutedMessage, disableAutoMute }) => {
  const { isReady: ready } = useLiveContext()
  const audioState = useSelector(selectAudioState)
  const microphonePermission = useSelector(selectMicrophonePermission)
  const { t } = useTranslation()

  const dispatch = useDispatch()

  const toggleAudio = async (): Promise<void> => {
    setShowAutoMutedMessage(false)
    disableAutoMute.current = true

    if (audioState === 'on') await dispatch(muteAudio())
    else await dispatch(unmuteAudio())
  }

  const microphoneActiveTooltip = useMemo(() => {
    if (audioState !== 'unavailable') return t('live.turn-on-microphone')
    if (microphonePermission === 'denied') return t('live.microphone-permission-denied')
    return t('live.microphone-unavailable')
  }, [audioState, microphonePermission, t])

  return (
    <ControlContainer
      pfcid={ready ? 'audio-button' : undefined}
      active={audioState !== 'on'}
      activeColor={palette.red.vivid}
      activeIcon={<Icon iconId='microphone--off' size='size-14' color='white' />}
      icon={<Icon iconId='microphone' size='size-14' color='white' />}
      onClick={toggleAudio}
      disabled={!ready}
      tooltip={t('live.turn-off-microphone')}
      activeTooltip={microphoneActiveTooltip}
    />
  )
}

const VideoControl: React.FC = () => {
  const { isReady: ready } = useLiveContext()
  const videoState = useSelector(selectVideoState)
  const cameraPermission = useSelector(selectCameraPermission)
  const { t } = useTranslation()

  const dispatch = useDispatch()

  const toggleVideo = (): void => {
    if (videoState === 'on') {
      void dispatch(disableVideo())
    } else {
      void dispatch(enableVideo())
    }
  }

  const cameraActiveTooltip = useMemo(() => {
    if (videoState !== 'unavailable') return t('live.turn-on-camera')
    if (cameraPermission === 'denied') return t('live.camera-permission-denied')
    return t('live.camera-unavailable')
  }, [videoState, cameraPermission, t])

  return (
    <ControlContainer
      pfcid={ready ? 'video-button' : undefined}
      active={videoState !== 'on'}
      activeColor={palette.red.vivid}
      activeIcon={<Icon iconId='video--off' size='size-14' color='white' />}
      icon={<Icon iconId='video' size='size-14' color='white' />}
      onClick={toggleVideo}
      disabled={!ready}
      tooltip={t('live.turn-off-camera')}
      activeTooltip={cameraActiveTooltip}
    />
  )
}

const ControlsContainer = styled.div`
  position: absolute;
  inset: 0;
  display: flex;
  justify-content: center;
  align-items: flex-end;
  gap: 4px;
  padding: 8px;

  &::before {
    content: '';
    background: linear-gradient(to top, rgba(0, 0, 0, 0.4) 0%, transparent 70%);
    position: absolute;
    inset: 0;
  }

  ${minWidth.phone} {
    padding: 24px;
  }
`

const AbsoluteLeftAlign = styled.div`
  position: absolute;
  display: flex;
  align-items: center;
  justify-content: center;
  bottom: 10px;
  left: 10px;
  width: 60px;
  height: 60px;
`

export const PreLobbyHardwareControls: React.FC<{
  setShowAutoMutedMessage: (show: boolean) => void
  disableAutoMute: React.MutableRefObject<boolean>
}> = ({ setShowAutoMutedMessage, disableAutoMute }) => {
  const audioState = useSelector(selectAudioState)

  return (
    <ControlsContainer>
      <AbsoluteLeftAlign>
        {audioState === 'on' && <LocalAudioIndicator hideMutedIcon={true} />}
      </AbsoluteLeftAlign>

      <VideoControl />
      <MicrophoneControl
        setShowAutoMutedMessage={setShowAutoMutedMessage}
        disableAutoMute={disableAutoMute}
      />
      <SettingsControlContainer active={false} />
    </ControlsContainer>
  )
}

const PulsingAudioIndicator: React.FC = () => {
  const [volume, setVolume] = useState(0.6)
  useEffect(() => {
    const minVolume = 6
    const maxVolume = 10
    let volume = minVolume
    let increase = true
    const interval = setInterval(() => {
      setVolume(volume / 10)

      volume = _.clamp(volume + (increase ? 1 : -1), minVolume, maxVolume)
      if (volume >= maxVolume) increase = false
      if (volume <= minVolume) increase = true
    }, 200)

    setVolume(6)

    return () => clearInterval(interval)
  }, [])
  return <AudioIndicator barColor='foreground/primary' volume={volume} hideMutedIcon={true} />
}

const AudioContainer = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  height: 100%;
  width: 18px;
`

const _TestSpeakerButton = styled.button`
  ${ButtonStyles}
`

const TestSpeakerButton: React.FC<{ onSoundPlayed: () => void }> = ({ onSoundPlayed }) => {
  const { t } = useTranslation()
  const [isPlaying, setIsPlaying] = useState(false)
  const [playSound, { stop }] = useSound(testSpeakerSound, {
    volume: 0.5,
    onplay: () => setIsPlaying(true),
    onend: () => {
      setIsPlaying(false)
      onSoundPlayed()
    },
  })

  const pseudoStyles = usePrimitiveButtonPseudoStyles('secondary')

  return (
    <_TestSpeakerButton
      $pseudoStyles={pseudoStyles}
      $grow={true}
      onClick={() => {
        stop()
        playSound()
      }}
    >
      <View>
        <AudioContainer>
          {isPlaying ? <PulsingAudioIndicator /> : <Icon iconId='volume--up' />}
        </AudioContainer>
        {t('live-page.test-speaker')}
      </View>
    </_TestSpeakerButton>
  )
}

const SelectionContainer = styled.div`
  display: grid;
  grid-template-columns: 1fr;
  gap: 8px;
  width: 100%;

  ${minWidth.phone} {
    grid-template-columns: minmax(0, 1fr) minmax(0, 1fr) auto;
  }
`

const SpeakerHelpContainer = styled.div`
  width: 100%;
  text-align: center;

  a {
    font-weight: ${fonts.weight.bold};
  }
`

export const PreLobbyHardwareSelection: React.FC = () => {
  const { t } = useTranslation()
  const cameras = useSelector(selectAvailableCameras)
  const microphones = useSelector(selectAvailableMicrophones)
  const cameraPermission = useSelector(selectCameraPermission)
  const microphonePermission = useSelector(selectMicrophonePermission)

  const cameraSettings = useCameraSettings()
  const microphoneSettings = useMicrophonesSettings()
  const [speakerTested, { on: onSpeakerTested }] = useToggle()

  const cameraMenuItems: MenuItem[] = useMemo(
    () =>
      cameras?.map(camera => ({
        type: 'label',
        label: camera.label,
        id: camera.deviceId,
      })) ?? [],
    [cameras]
  )

  const cameraSelectedItem: MenuItem | undefined = useMemo(() => {
    if (cameraPermission === 'denied') {
      return {
        type: 'label',
        icon: 'video',
        label: t('dictionary.no-permissions'),
        id: 'no-premissions',
      }
    }

    const item = cameraMenuItems.find(item => item.id === cameraSettings?.currentId)
    return isDefined(item) ? { ...item, icon: 'video' } : undefined
  }, [cameraMenuItems, cameraPermission, cameraSettings?.currentId, t])

  const microphoneMenuItems: MenuItem[] = useMemo(
    () =>
      microphones?.map(microphone => ({
        type: 'label',
        label: microphone.label,
        id: microphone.deviceId,
      })) ?? [],
    [microphones]
  )

  const microphoneSelectedItem: MenuItem | undefined = useMemo(() => {
    if (microphonePermission === 'denied') {
      return {
        type: 'label',
        icon: 'microphone',
        label: t('dictionary.no-permissions'),
        id: 'no-premissions',
      }
    }

    const item = microphoneMenuItems.find(item => item.id === microphoneSettings?.currentId)
    return isDefined(item) ? { ...item, icon: 'microphone' } : undefined
  }, [microphoneMenuItems, microphonePermission, microphoneSettings?.currentId, t])

  return (
    <>
      <SelectionContainer>
        {cameraPermission === 'not-requested' || cameraSelectedItem === undefined ? (
          <Skeleton $width='100%' $height='36px' $radius='8px' />
        ) : (
          <SingleSelectDropdown
            truncateTrigger={1}
            disabled={cameraPermission === 'denied'}
            menuItems={cameraMenuItems}
            selectedItem={cameraSelectedItem}
            onSelect={camera => {
              cameraSettings?.onItemSelected({ id: camera.id })
            }}
          />
        )}

        {microphonePermission === 'not-requested' || microphoneSelectedItem === undefined ? (
          <Skeleton $width='100%' $height='36px' $radius='8px' />
        ) : (
          <SingleSelectDropdown
            truncateTrigger={1}
            disabled={microphonePermission === 'denied'}
            menuItems={microphoneMenuItems}
            selectedItem={microphoneSelectedItem}
            onSelect={microphone => {
              microphoneSettings?.onItemSelected({ id: microphone.id })
            }}
          />
        )}

        <TestSpeakerButton onSoundPlayed={onSpeakerTested} />
      </SelectionContainer>

      {speakerTested && (
        <SpeakerHelpContainer>
          <Text color='foreground/muted'>
            {t('live-page.no-sound__title')}{' '}
            <RouterLink target='_blank' rel='noopener noreferrer' href={LIVE_SPEAKER_HELP}>
              {t('live-page.no-sound__link')}
            </RouterLink>
          </Text>
        </SpeakerHelpContainer>
      )}
    </>
  )
}
