/**
 * Play video and ignore AbortError's like "The play() request was interrupted by a new load request." or "The play() request was interrupted by a call to pause()."
 * @param video The video element to play.
 * @returns A promise that resolves when the video has started playing.
 */
export function playVideoAndIgnoreAbort(video: HTMLVideoElement): Promise<void> {
  return video.play().catch(error => {
    if (error?.name !== 'AbortError') {
      throw error
    }
  })
}
