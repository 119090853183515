import { useAtomValue } from 'jotai'
import { selectAtom } from 'jotai/utils'
import React from 'react'
import { TableAPI } from 'sierra-client/lib/tabular/api'
import { AlignSelfCenterView } from 'sierra-client/lib/tabular/components/cell-components'
import { CellRenderContext } from 'sierra-client/lib/tabular/components/util'
import { CellString } from 'sierra-client/lib/tabular/datatype/internal/cell-with-data'
import { Icon, TruncatedText } from 'sierra-ui/components'
import { View } from 'sierra-ui/primitives'

export const StringCell: React.FC<{
  cell: CellString
  api: TableAPI
}> = ({ cell, api }) => {
  const toggleExpand = React.useCallback(
    (ev: React.MouseEvent<HTMLElement>) => (
      ev.preventDefault(), ev.stopPropagation(), api.action.toggleExpanded({ row: cell.pos.row })
    ),
    [api, cell.pos.row]
  )

  const expandedAtom = React.useMemo(
    () => selectAtom(api.atoms.expanded, set => set.has(cell.pos.row)),
    [cell.pos.row, api.atoms.expanded]
  )
  const expanded = useAtomValue(expandedAtom)

  const hasChildrenRows = React.useContext(CellRenderContext).hasChildrenRows

  return (
    <View alignItems='flex-start' gap='4'>
      <View>
        <TruncatedText lines={1} size='small' bold={cell.hints.includes('bold')}>
          {cell.data}
        </TruncatedText>
      </View>
      {cell.hints.includes('with-nested-row-toggle') && hasChildrenRows && (
        <AlignSelfCenterView onClick={toggleExpand}>
          <Icon iconId={expanded ? 'chevron--up--small' : 'chevron--down--small'} />
        </AlignSelfCenterView>
      )}
    </View>
  )
}
