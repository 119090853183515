import { useCallback, useState } from 'react'
import {
  useBrowseTeamspaces,
  useJoinTeamspaceMutation,
  useLeaveTeamspaceMutation,
  useTeamspaceById,
} from 'sierra-client/api/hooks/use-teamspace'
import { Link } from 'sierra-client/components/common/link'
import {
  PanelBody,
  PanelFooter,
  PanelGroupButton,
} from 'sierra-client/features/teamspace/components/modals/panels/panel-elements'
import { TeamspaceIcon } from 'sierra-client/features/teamspace/components/teamspace-icon'
import { IncreasedZIndexTooltip } from 'sierra-client/features/teamspace/components/teamspace-permission-selector'
import { teamspaceJoinedLogger, teamspaceLeftLogger } from 'sierra-client/features/teamspace/logger'
import { useOnLocationChange } from 'sierra-client/hooks/router/use-on-location-change'
import { useHasOrganizationPermission, useHasTeamspacePermission } from 'sierra-client/hooks/use-permissions'
import { useTranslation } from 'sierra-client/hooks/use-translation'
import { useDispatch } from 'sierra-client/state/hooks'
import { NanoId12 } from 'sierra-domain/api/nano-id'
import { Button, Heading, LoadingSpinner, Text, View } from 'sierra-ui/primitives'
import { token } from 'sierra-ui/theming'
import styled, { css } from 'styled-components'

type BrowseTeamspaceItem = {
  id: NanoId12
  displayName: string
  numMembers: number
  iconTheme?: string
}

const BrowseUL = styled.ul`
  display: flex;
  flex-direction: column;
  gap: 12px;
`

const BrowseLI = styled.li`
  display: flex;
  flex-direction: row;
  gap: 12px;
  align-items: center;
`

const TeamspaceDisplayName = styled.div`
  flex: 1;
  overflow: hidden;

  p {
    display: block;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
  }
`

const TeamspaceItemActions = styled.div`
  margin-right: auto;
  text-align: right;
`

const JoinLeaveText = styled(Text)<{ $disabled?: boolean }>`
  ${p =>
    p.$disabled === true
      ? css`
          cursor: not-allowed;
          color: ${token('foreground/muted')};
        `
      : css`
          cursor: pointer;
          color: ${token('foreground/secondary')};
        `}
`

const JoinAction: React.FC<{ teamspace: BrowseTeamspaceItem }> = ({ teamspace }) => {
  const { t } = useTranslation()
  const { mutate: joinMutation } = useJoinTeamspaceMutation()
  const dispatch = useDispatch()

  const join = useCallback(
    () =>
      joinMutation(
        { teamspaceId: teamspace.id },
        {
          onSuccess: () => {
            void dispatch(
              teamspaceJoinedLogger({
                teamspaceId: teamspace.id,
              })
            )
          },
        }
      ),
    [teamspace.id, joinMutation, dispatch]
  )

  return (
    <JoinLeaveText size='small' bold onClick={join}>
      {t('dictionary.join')}
    </JoinLeaveText>
  )
}

const LeaveAction: React.FC<{ teamspace: BrowseTeamspaceItem }> = ({ teamspace }) => {
  const { t } = useTranslation()
  const { mutate: leaveMutation } = useLeaveTeamspaceMutation()
  const { data } = useTeamspaceById(teamspace.id)
  const dispatch = useDispatch()

  const hasLeavePermission = useHasTeamspacePermission(teamspace.id, 'LEAVE')
  const isOwner = data?.effectiveRole === 'owner'

  const leave = useCallback(
    () =>
      !hasLeavePermission
        ? undefined
        : leaveMutation(
            { teamspaceId: teamspace.id },
            {
              onSuccess: () => {
                void dispatch(
                  teamspaceLeftLogger({
                    teamspaceId: teamspace.id,
                  })
                )
              },
            }
          ),
    [hasLeavePermission, leaveMutation, teamspace.id, dispatch]
  )

  let tooltipMessage = undefined // tooltip disabled on undefined
  if (!hasLeavePermission) {
    if (isOwner) {
      tooltipMessage = t('teamspace.browse.owner-leave-not-allowed')
    } else {
      tooltipMessage = t('teamspace.browse.group-member-leave-not-allowed')
    }
  }

  return (
    <IncreasedZIndexTooltip title={tooltipMessage}>
      <JoinLeaveText $disabled={!hasLeavePermission} size='small' bold onClick={leave}>
        {t('dictionary.leave')}
      </JoinLeaveText>
    </IncreasedZIndexTooltip>
  )
}

const TeamspaceListItem: React.FC<{
  teamspace: BrowseTeamspaceItem
  isMember: boolean
  hover: boolean
  onHover: (teamspaceId: NanoId12) => void
  onHoverOut: () => void
}> = ({ teamspace, isMember, hover, onHover, onHoverOut }) => {
  const { t } = useTranslation()
  const { id, displayName, numMembers } = teamspace

  return (
    <BrowseLI onMouseEnter={() => onHover(teamspace.id)} onMouseLeave={onHoverOut}>
      <TeamspaceIcon
        displayName={displayName}
        themeName={teamspace.iconTheme}
        inactive={!hover && !isMember}
      />
      <TeamspaceDisplayName>
        <Link href={`/t/${id}`} next={true}>
          <Text bold size='small' color='foreground/primary'>
            {displayName}
          </Text>
        </Link>
      </TeamspaceDisplayName>
      <TeamspaceItemActions>
        {hover ? (
          isMember ? (
            <LeaveAction teamspace={teamspace} />
          ) : (
            <JoinAction teamspace={teamspace} />
          )
        ) : (
          <Text size='small' color='foreground/muted'>
            {t('teamspace.members', { count: numMembers })}
          </Text>
        )}
      </TeamspaceItemActions>
    </BrowseLI>
  )
}

export const BrowseTeamspacesPanel: React.FC<{ onClickNew: () => void; onClose: () => void }> = ({
  onClickNew,
  onClose,
}) => {
  const { t } = useTranslation()
  const query = useBrowseTeamspaces()
  const [hoverId, setHoverId] = useState<NanoId12 | undefined>(undefined)

  useOnLocationChange(onClose)

  const onHover = useCallback(
    (teamspaceId: NanoId12) => {
      setHoverId(teamspaceId)
    },
    [setHoverId]
  )
  const onHoverOut = (): void => setHoverId(undefined)

  const userHasCreateTeamspacePermission = useHasOrganizationPermission('CREATE_TEAMSPACE')

  if (!query.isSuccess) return <LoadingSpinner />

  const { memberOf, available } = query.data

  return (
    <>
      <PanelBody>
        <Heading bold size='h5' spacing='small'>
          {t('teamspace.browse.heading')}
        </Heading>
        <View direction='column' gap='32'>
          {memberOf.length > 0 && (
            <View direction='column' gap='16'>
              <Text bold size='small' color='foreground/secondary'>
                {t('teamspace.browse.your-teamspaces')}
              </Text>
              <BrowseUL>
                {memberOf.map(teamspace => (
                  <TeamspaceListItem
                    key={teamspace.id}
                    teamspace={teamspace}
                    isMember={true}
                    hover={teamspace.id === hoverId}
                    onHover={onHover}
                    onHoverOut={onHoverOut}
                  />
                ))}
              </BrowseUL>
            </View>
          )}
          {available.length > 0 && (
            <View direction='column' gap='16'>
              <Text bold size='small' color='foreground/secondary'>
                {t('teamspace.browse.more-teamspaces')}
              </Text>
              <BrowseUL>
                {available.map(teamspace => (
                  <TeamspaceListItem
                    key={teamspace.id}
                    teamspace={teamspace}
                    isMember={false}
                    hover={teamspace.id === hoverId}
                    onHover={onHover}
                    onHoverOut={onHoverOut}
                  />
                ))}
              </BrowseUL>
            </View>
          )}
        </View>
      </PanelBody>
      {userHasCreateTeamspacePermission && (
        <PanelFooter>
          <PanelGroupButton>
            <Button variant='secondary' onClick={onClickNew}>
              {t('teamspaces.new')}
            </Button>
          </PanelGroupButton>
        </PanelFooter>
      )}
    </>
  )
}
