import {
  XRealtimeAdminAssignmentsUnassignFromLiveSession,
  XRealtimeAdminLiveSessionsAssignUsers,
} from 'sierra-domain/routes'

import _ from 'lodash'
import React from 'react'
import { CustomMenuProps, Menu } from 'sierra-client/components/common/menu'
import { usePost } from 'sierra-client/hooks/use-post'
import { useTranslation } from 'sierra-client/hooks/use-translation'
import { getLiveSessionTimes } from 'sierra-client/views/manage/courses/utils/live-session-utils'
import { AssignmentWithDueDate, LiveSessionRow, UnassignLiveSessionRequest } from 'sierra-domain/api/manage'
import { UserId } from 'sierra-domain/api/uuid'
import { scheduledOrNull } from 'sierra-domain/content/session'
import { TruncatedText } from 'sierra-ui/components'
import { MUIMenuItem } from 'sierra-ui/mui'
import { Text, View } from 'sierra-ui/primitives'
import styled from 'styled-components'

const FreeHeightMenuItem = styled(MUIMenuItem)`
  height: auto;
  cursor: pointer;

  & > * {
    cursor: pointer;
  }
`

type SelectLiveSessionProps = {
  targetUsers: UserId[]
  currentLiveSessionIds?: string[]
  onDone?: () => void
  menuProps?: Omit<CustomMenuProps, 'trigger'>
  action: 'assign' | 'unassign'
  renderTrigger: (props: { disabled: boolean }) => React.ReactNode
  liveSessions: LiveSessionRow[]
}

export const SelectLiveSession: React.FC<SelectLiveSessionProps> = ({
  liveSessions,
  targetUsers,
  currentLiveSessionIds = [],
  onDone = () => {},
  renderTrigger,
  action,
  menuProps,
}) => {
  const { postWithUserErrorException } = usePost()
  const { t } = useTranslation()

  const handleAssign = async (targetLsId: string): Promise<void> => {
    const assignments = targetUsers.map<AssignmentWithDueDate>(userId => ({
      assignee: {
        id: userId,
        type: 'user',
        dueDate: undefined,
      },
      content: {
        id: targetLsId,
        type: 'live-session',
      },
    }))

    await postWithUserErrorException(XRealtimeAdminLiveSessionsAssignUsers, assignments)
    onDone()
  }

  const handleUnassign = async (targetLsId: string): Promise<void> => {
    const unAssignments: UnassignLiveSessionRequest = {
      liveSessionId: targetLsId,
      userIds: targetUsers,
    }

    await postWithUserErrorException(XRealtimeAdminAssignmentsUnassignFromLiveSession, unAssignments)
    onDone()
  }

  const filteredLiveSession = liveSessions.filter(ls => {
    const containsLs = currentLiveSessionIds.includes(ls.liveSessionId)
    if (action === 'assign' && containsLs) return false
    if (action === 'unassign' && !containsLs) return false
    return true
  })

  return (
    <Menu {...menuProps} trigger={renderTrigger({ disabled: filteredLiveSession.length === 0 })}>
      {filteredLiveSession.map(ls => {
        const {
          assignmentsCount,
          data: { maxNumberOfUsers, title, location },
        } = ls
        const { startTime, endTime } = scheduledOrNull(ls.data) ?? {}
        const isFull =
          action === 'assign'
            ? maxNumberOfUsers !== undefined && assignmentsCount + targetUsers.length > maxNumberOfUsers
            : false

        const formattedTimes = getLiveSessionTimes(startTime, endTime)

        const seatsAvailable = (maxNumberOfUsers ?? 0) - assignmentsCount
        const seatsAvailableText =
          maxNumberOfUsers === undefined
            ? undefined
            : t('manage.live-session.n-seats-available', { count: seatsAvailable })

        return (
          <FreeHeightMenuItem
            key={ls.liveSessionId}
            onClick={() =>
              action === 'assign' ? handleAssign(ls.liveSessionId) : handleUnassign(ls.liveSessionId)
            }
            disabled={isFull}
          >
            <View grow gap='large' padding='xxsmall none'>
              <View direction='column' gap='none' grow>
                {formattedTimes !== undefined && (
                  <>
                    <Text size='micro' color='grey40' bold>
                      {formattedTimes.date}
                    </Text>
                    <Text color='LEGACY_DEFAULT_TEXT_COLOR_REPLACE_ASAP' size='large' bold>
                      {formattedTimes.weekDay},
                    </Text>
                    <Text color='LEGACY_DEFAULT_TEXT_COLOR_REPLACE_ASAP' size='large' bold>
                      {formattedTimes.time}
                    </Text>
                  </>
                )}
              </View>
              <View direction='column' gap='none' grow>
                {_.compact([seatsAvailableText, location?.value, title]).map((item, index) => (
                  <TruncatedText key={index} size='small' color='grey40' lines={1}>
                    {item}
                  </TruncatedText>
                ))}
              </View>
            </View>
          </FreeHeightMenuItem>
        )
      })}
    </Menu>
  )
}

type LiveSessionMenuItemProps = {
  liveSession: LiveSessionRow
  targetUser: UserId
  onDone?: () => void
  action: 'assign' | 'unassign'
}

export const LiveSessionMenuItem: React.FC<LiveSessionMenuItemProps> = ({
  liveSession,
  targetUser,
  onDone = () => {},
  action,
}) => {
  const { postWithUserErrorException } = usePost()
  const { t } = useTranslation()

  const handleAssign = async (targetLsId: string): Promise<void> => {
    const assignment: AssignmentWithDueDate = {
      assignee: {
        id: targetUser,
        type: 'user',
        dueDate: undefined,
      },
      content: {
        id: targetLsId,
        type: 'live-session',
      },
    }

    await postWithUserErrorException(XRealtimeAdminLiveSessionsAssignUsers, [assignment])
    onDone()
  }

  const handleUnassign = async (targetLsId: string): Promise<void> => {
    const unAssignments: UnassignLiveSessionRequest = {
      liveSessionId: targetLsId,
      userIds: [targetUser],
    }

    await postWithUserErrorException(XRealtimeAdminAssignmentsUnassignFromLiveSession, unAssignments)
    onDone()
  }

  const isFull =
    action === 'assign'
      ? liveSession.data.maxNumberOfUsers !== undefined &&
        liveSession.assignmentsCount + 1 > liveSession.data.maxNumberOfUsers
      : false

  const { startTime, endTime } = scheduledOrNull(liveSession.data) ?? {}
  const formattedTimes = getLiveSessionTimes(startTime, endTime)

  const seatsAvailable = (liveSession.data.maxNumberOfUsers ?? 0) - liveSession.assignmentsCount
  const seatsAvailableText =
    liveSession.data.maxNumberOfUsers === undefined
      ? undefined
      : t('manage.live-session.n-seats-available', { count: seatsAvailable })

  return (
    <FreeHeightMenuItem
      key={liveSession.liveSessionId}
      onClick={() =>
        action === 'assign'
          ? handleAssign(liveSession.liveSessionId)
          : handleUnassign(liveSession.liveSessionId)
      }
      disabled={isFull}
    >
      <View grow gap='large' padding='xxsmall none'>
        <View direction='column' gap='none' grow>
          {formattedTimes !== undefined && (
            <>
              <Text size='micro' color='grey40' bold>
                {formattedTimes.date}
              </Text>
              <Text color='LEGACY_DEFAULT_TEXT_COLOR_REPLACE_ASAP' size='large' bold>
                {formattedTimes.weekDay},
              </Text>
              <Text color='LEGACY_DEFAULT_TEXT_COLOR_REPLACE_ASAP' size='large' bold>
                {formattedTimes.time}
              </Text>
            </>
          )}
        </View>
        <View direction='column' gap='none' grow>
          {_.compact([seatsAvailableText, liveSession.data.location?.value, liveSession.data.title]).map(
            (item, index) => (
              <TruncatedText key={index} size='small' color='grey40' lines={1}>
                {item}
              </TruncatedText>
            )
          )}
        </View>
      </View>
    </FreeHeightMenuItem>
  )
}
