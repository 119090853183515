import { Linkable } from 'sierra-client/views/workspace/utils/urls'
import { LearnerContent } from 'sierra-domain/api/learn'
import { assertNever } from 'sierra-domain/utils'

export const convertLearnerContentToLinkable = (content: LearnerContent): Linkable => {
  const id = content.id

  switch (content.type) {
    case 'live-session':
    case 'homework':
      return {
        id,
        type: content.type,
        courseId: content.courseId,
      }
    case 'link':
    case 'linkedin':
      return {
        id,
        type: content.type,
        url: content.url,
      }
    case 'native:course-group':
    case 'scorm:course-group':
      return {
        id,
        type: content.type,
        activeEditionId: content.activeEditionId,
      }
    case 'native:self-paced':
    case 'scorm':
      return {
        id,
        type: content.type,
        courseGroupId: content.courseGroupId,
      }
    case 'native:live':
    case 'path':
    case 'native:event-group':
      return {
        id,
        type: content.type,
      }
    case 'program':
      return {
        id,
        type: content.type,
        nextStep: undefined, // todo?
      }
    default:
      assertNever(content)
  }
}
