import { useQueries } from '@tanstack/react-query'
import { useCallback, useEffect, useMemo, useState } from 'react'
import { CSVDownload } from 'react-csv'
import { tiptapContentToString } from 'sierra-client/components/chat/tiptap'
import { useLiveSessionContext } from 'sierra-client/components/liveV2/contexts/live-session-data'
import { usePost } from 'sierra-client/hooks/use-post'
import { useTranslation } from 'sierra-client/hooks/use-translation'
import { typedPost } from 'sierra-client/state/api'
import { selectChat } from 'sierra-client/state/chat/selectors'
import { ChatId } from 'sierra-client/state/chat/types'
import { useGetFileTitle } from 'sierra-client/state/flexible-content/file-title'
import { selectFlexibleContent } from 'sierra-client/state/flexible-content/selectors'
import { isFile } from 'sierra-client/state/flexible-content/types'
import { useSelector } from 'sierra-client/state/hooks'
import { useUsersLegacy } from 'sierra-client/state/users/hooks'
import { useFlexibleContentYDoc } from 'sierra-client/views/flexible-content/polaris-editor-provider/use-flexible-content-ydoc'
import { getCsvFileName } from 'sierra-client/views/manage/components/export-csv'
import { assertElementType, isElementType } from 'sierra-client/views/v3-author/queries'
import { UserId } from 'sierra-domain/api/uuid'
import { Message } from 'sierra-domain/chat'
import { ScopedChatId, ScopedLiveSessionId } from 'sierra-domain/collaboration/types'
import { scheduledOrNull } from 'sierra-domain/content/session'
import { FileId } from 'sierra-domain/flexible-content/identifiers'
import { File, FlexibleContentJsonData, SlateFileType } from 'sierra-domain/flexible-content/types'
import { createNanoId12FromString } from 'sierra-domain/nanoid-extensions'
import {
  XRealtimeContentListUsers,
  XRealtimeStrategyContentDataPollGetPollResults,
  XRealtimeStrategyContentDataReflectionCardGetResponses,
  XRealtimeStrategyLiveSessionGetAttendance,
} from 'sierra-domain/routes'
import { LightUser } from 'sierra-domain/user'
import { assertNever, getUserName, isDefined } from 'sierra-domain/utils'
import { allPollCardAlternatives } from 'sierra-domain/v3-author'
import { getSlateDocument } from 'sierra-domain/v3-author/slate-yjs-extension'
import { MenuButton, MenuItem } from 'sierra-ui/components'
import { Descendant, Element, Text } from 'slate'
import { Doc } from 'yjs'

type PollResponseWithType = {
  type: 'poll'
  userId: UserId
  timestamp: string
  documentId: string
  pollOptionId: string
}

type ReflectionResponseWithType = {
  type: 'reflection'
  userId?: UserId
  documentId: string
  reflection: string
  anonymous: boolean
}

type UserResponse = PollResponseWithType | ReflectionResponseWithType

type UserInput = UserResponse | Message

type CsvSessionData = {
  fileId: FileId
  cardType: 'poll' | 'reflection'
  userName: string
  cardTitle: string
  timestamp?: string
  heading?: string
  content?: string
}

type CsvChatData = {
  chatId: ChatId
  userName: string
  message: string
  sentAt: string
  threadId?: string
}

type CsvHeader<CsvData> = {
  key: keyof CsvData
  label: string
}

const getAllFiles = (flexibleContent?: FlexibleContentJsonData): File[] => {
  const nodeMap = flexibleContent?.nodeMap ?? {}
  const allNodes = Object.entries(nodeMap).map(([, node]) => node)
  const allFiles = allNodes.filter(isFile)
  return allFiles
}

const getAllSlateElements = (
  yDoc: Doc,
  flexibleContent?: FlexibleContentJsonData
): (Element & { fromFile: File })[] => {
  const allFiles = getAllFiles(flexibleContent)
  const allSlateElements = allFiles.flatMap(file => {
    const { success: isSlateFileType } = SlateFileType.safeParse(file.data.type)
    if (isSlateFileType) {
      const elements: Element[] = getSlateDocument(yDoc, file.id).flatMap((node): Element[] => {
        if (Element.isElement(node)) return [node]
        else return []
      })
      return elements.map(child => ({ fromFile: file, ...child }))
    } else {
      return []
    }
  })
  return allSlateElements
}

const getUniqueUserIds = (userInputs: UserInput[]): UserId[] => {
  const userIds = userInputs.flatMap(userInput => userInput.userId).filter(isDefined)
  const uniqueUserIds = [...new Set(userIds)]
  return uniqueUserIds
}

const getUserNameFromResponse = (users: (LightUser | undefined)[], response: UserResponse): string => {
  if (response.type === 'reflection' && response.anonymous) return 'Anonymous'

  const user = users.find(user => user?.uuid === response.userId)
  return getUserName(user) ?? ''
}

const getSlateElement = (
  allElements: (Element & { fromFile: File })[],
  elementId: string
): (Element & { fromFile: File }) | undefined => allElements.find(element => element.id === elementId)

const getHeading = (element: Element): string => {
  const firstChild = element.children[0]
  if (Element.isElement(firstChild)) {
    if (firstChild.type === 'paragraph') {
      const paragraph = firstChild.children[0]
      if (Text.isText(paragraph)) {
        return paragraph.text
      }
    }
  }
  return ''
}

export const getPollResponse = (descendant: Descendant, pollOptionId?: string): string => {
  if (!isElementType('poll-card', descendant)) return ''

  const alternativeContainer = descendant.children.find(
    child => child.type === 'poll-card-alternative-container'
  )

  if (!isElementType('poll-card-alternative-container', alternativeContainer)) return ''

  const pollOption = alternativeContainer.children.find(child => child.id === pollOptionId)
  if (pollOption?.type === 'poll-card-alternative') {
    const paragraph = pollOption.children[0]
    if (Text.isText(paragraph)) {
      return paragraph.text
    }
  }
  return ''
}

const useAttendanceData = (): {
  loadAttendanceData: () => Promise<void>
  loading: boolean
  users: LightUser[]
} => {
  const liveSession = useLiveSessionContext()
  const [users, setUsers] = useState<LightUser[]>([])
  const [loading, setLoading] = useState(false)
  const { postWithUserErrorException } = usePost()

  const loadAttendanceData = useCallback(async (): Promise<void> => {
    setLoading(true)

    const res = await postWithUserErrorException(XRealtimeStrategyLiveSessionGetAttendance, {
      liveSessionId: liveSession.liveSessionId,
    })

    const users = await postWithUserErrorException(XRealtimeContentListUsers, {
      requestedUserIds: res.attendees,
    })

    setUsers(
      users.data.map(({ userInfo: { firstName, lastName, avatar, avatarColor, userId, email } }) => ({
        firstName: firstName ?? '',
        lastName: lastName ?? '',
        avatar,
        avatarColor,
        uuid: userId,
        email: email,
      }))
    )
    setLoading(false)
  }, [liveSession, postWithUserErrorException])

  const data = useMemo(
    () => ({
      loadAttendanceData,
      loading,
      users,
    }),
    [loadAttendanceData, loading, users]
  )

  return data
}

// TODO
// we don't have data export working since we moved the data to the backend
const useCsvSessionData = (): {
  data: CsvSessionData[]
  headers: CsvHeader<CsvSessionData>[]
  loading: boolean
} => {
  const { yDoc } = useFlexibleContentYDoc()
  const liveSession = useLiveSessionContext()

  const flexibleContent = useSelector(state =>
    selectFlexibleContent(state, liveSession.data.flexibleContentId)
  )
  const allSlateElements = getAllSlateElements(yDoc, flexibleContent)
  const reflectionCardElements = allSlateElements.filter(
    slateElement => slateElement.type === 'reflection-card'
  )

  const getFileTitle = useGetFileTitle(yDoc)

  const reflectionResponseDataQuery = useQueries({
    queries: reflectionCardElements.map((slateElement, i) => {
      const reflectionId = slateElement.id
      const fileId = slateElement.fromFile.id

      return {
        queryKey: [XRealtimeStrategyContentDataReflectionCardGetResponses.path, i],
        queryFn: async () =>
          typedPost(XRealtimeStrategyContentDataReflectionCardGetResponses, {
            reflectionId,
            contentId: liveSession.data.flexibleContentId,
            liveSessionId: liveSession.liveSessionId,
            fileId,
            order: 'created-time-asc',
          }),
        staleTime: Infinity,
      }
    }),
  })

  const reflectionResponsesWithType: ReflectionResponseWithType[] = useMemo(
    () =>
      reflectionResponseDataQuery
        .map(({ data }) => data)
        .flatMap(
          responseData =>
            responseData?.responses.map(response => {
              if (response.type === 'skipped')
                return {
                  type: 'reflection',
                  userId: response.userId,
                  documentId: response.reflectionId,
                  reflection: '',
                  anonymous: false,
                }
              else {
                return {
                  type: 'reflection',
                  userId: response.userId,
                  documentId: response.reflectionId,
                  reflection: response.response ?? '',
                  anonymous: response.anonymous,
                }
              }
            }) ?? []
        ),
    [reflectionResponseDataQuery]
  )

  const pollCardElements = allSlateElements.filter(slateElement => slateElement.type === 'poll-card')

  const pollDataQuery = useQueries({
    queries: pollCardElements.map((slateElement, i) => {
      const pollId = slateElement.id
      const fileId = slateElement.fromFile.id

      assertElementType('poll-card', slateElement)
      const pollAlternatives = allPollCardAlternatives(slateElement)
      // Synthetically create a version of the poll
      const versionId = createNanoId12FromString(pollAlternatives.map(alt => alt.id).join())

      return {
        queryKey: [XRealtimeStrategyContentDataPollGetPollResults.path, i],
        queryFn: async () =>
          typedPost(XRealtimeStrategyContentDataPollGetPollResults, {
            contentId: liveSession.data.flexibleContentId,
            pollId,
            versionId,
            fileId,
            liveSessionId: liveSession.liveSessionId,
          }),
        staleTime: Infinity,
      }
    }),
  })

  const pollResponsesWithType: PollResponseWithType[] = pollDataQuery
    .map(({ data }) => data)
    .flatMap(
      (data, i) =>
        data?.optionResults.flatMap(
          result =>
            result.chosenByUserIds?.map(userId => ({
              type: 'poll',
              userId: userId,
              timestamp: data.updatedAt ?? '',
              documentId: pollCardElements[i]?.id ?? '', // Z: hacky way of getting the id by relying on same ordering
              pollOptionId: result.optionId,
            })) ?? []
        ) ?? []
    )

  const userResponses = [...reflectionResponsesWithType, ...pollResponsesWithType]

  const uniqueUserIds = getUniqueUserIds(userResponses)
  const users = useUsersLegacy(uniqueUserIds)

  const data = userResponses.reduce<CsvSessionData[]>((result, response) => {
    const element = getSlateElement(allSlateElements, response.documentId)
    if (element === undefined) return result

    const fileId = element.fromFile.id
    const cardType = response.type
    const userName = getUserNameFromResponse(users, response)
    const cardTitle = getFileTitle(element.fromFile)
    const timestamp = response.type === 'poll' ? response.timestamp : undefined
    const heading = getHeading(element)
    const content =
      response.type === 'poll' ? getPollResponse(element, response.pollOptionId) : response.reflection

    const row = { fileId, cardType, userName, cardTitle, timestamp, heading, content }
    return [row, ...result]
  }, [])

  const headers: CsvHeader<CsvSessionData>[] = [
    { key: 'fileId', label: 'Card ID' },
    { key: 'cardType', label: 'Card Type' },
    { key: 'userName', label: 'User Name' },
    { key: 'cardTitle', label: 'Card Title' },
    { key: 'timestamp', label: 'Timestamp' },
    { key: 'heading', label: 'Heading' },
    { key: 'content', label: 'Content' },
  ]

  return {
    data,
    headers,
    loading: reflectionResponseDataQuery.some(it => it.isPending) || pollDataQuery.some(it => it.isPending),
  }
}

const useCsvChatData = (): {
  data: CsvChatData[] | null
  headers: CsvHeader<CsvChatData>[]
  loadData: () => Promise<void>
  loading: boolean
} => {
  const [loading, setLoading] = useState(false)
  const headers: CsvHeader<CsvChatData>[] = [
    { key: 'chatId', label: 'Message ID' },
    { key: 'userName', label: 'Sender Name' },
    { key: 'message', label: 'Message' },
    { key: 'sentAt', label: 'Sent At' },
    { key: 'threadId', label: 'Thread ID' },
  ]
  const [data, setData] = useState<CsvChatData[] | null>(null)
  const liveSession = useLiveSessionContext()

  const chat = useSelector(state =>
    selectChat(state, ScopedChatId.fromId(ScopedLiveSessionId.fromId(liveSession.liveSessionId)))
  )
  const { postWithUserErrorException } = usePost()

  const loadData = useCallback(async (): Promise<void> => {
    setLoading(true)
    const messages = Object.entries(chat?.messages ?? []).map(([, message]) => message)
    const uniqueUserIds = getUniqueUserIds(messages)
    const usersResponse = await postWithUserErrorException(XRealtimeContentListUsers, {
      requestedUserIds: uniqueUserIds,
    })

    const users = usersResponse.data.map(
      ({ userInfo: { firstName, lastName, avatar, avatarColor, userId } }) => ({
        firstName: firstName ?? '',
        lastName: lastName ?? '',
        avatar,
        avatarColor,
        id: userId,
      })
    )

    setData(
      messages.reduce<CsvChatData[]>((result, message) => {
        if (message.type !== 'tiptap-plain') return result

        const user = users.find(user => user.id === message.userId)
        const userName = getUserName(user) ?? ''
        const content = tiptapContentToString(message.tiptapJsonData)

        const csvChatData: CsvChatData = {
          chatId: message.id,
          userName,
          message: content,
          sentAt: message.timeSent,
          threadId: message.responseToMessageId,
        }
        return [csvChatData, ...result]
      }, [])
    )
    setLoading(false)
  }, [chat?.messages, postWithUserErrorException])

  return { data, headers, loadData, loading }
}

const useAugmentedSessionCsvData = ({
  headers,
  data,
}: {
  data: CsvSessionData[]
  headers: CsvHeader<CsvSessionData>[]
}): { loadData: () => Promise<void>; data: string[][]; loading: boolean } => {
  const { loadAttendanceData, users, loading } = useAttendanceData()
  const liveSession = useLiveSessionContext()

  const title = liveSession.data.title
  const liveSessionId = liveSession.liveSessionId
  const startTime = scheduledOrNull(liveSession.data)?.startTime ?? ''
  const endTime = scheduledOrNull(liveSession.data)?.endTime ?? ''

  let rowData = useMemo(() => {
    const headerRow = headers.map(header => header.label)

    const dataRows = data.map(row => {
      const rowData = headers.map(header => row[header.key])
      return rowData
    })

    return [headerRow, ...dataRows] as string[][]
  }, [data, headers])

  rowData = useMemo(() => {
    const augmentedRowData = [
      ['Title', title],
      ['Session ID', liveSessionId],
      ['Start Time', startTime],
      ['End Time', endTime],
      ['Attendance', `${users.map(getUserName).filter(isDefined).join(', ')}`],
      [],
      ...rowData,
    ]
    return augmentedRowData
  }, [title, liveSessionId, startTime, endTime, users, rowData])

  const ret = useMemo(
    () => ({
      loadData: loadAttendanceData,
      data: rowData,
      loading,
    }),
    [loadAttendanceData, rowData, loading]
  )

  return ret
}

const DownloadChatData = ({ onDone }: { onDone: () => void }): JSX.Element => {
  const [ready, setReady] = useState(false)
  const { data, headers, loadData, loading } = useCsvChatData()

  const downloadReady = ready && !loading && data !== null

  useEffect(() => {
    void (async () => {
      await loadData()
      setReady(true)
    })()
  }, [loadData])

  useEffect(() => {
    if (downloadReady) onDone()
  }, [downloadReady, onDone])

  if (!downloadReady) return <></>

  return (
    <CSVDownload data={data} headers={headers} filename={getCsvFileName('sana-live-chat')} target='_blank' />
  )
}
const DownloadSessionData = ({ onDone }: { onDone: () => void }): JSX.Element => {
  const [ready, setReady] = useState(false)
  const csvSession = useCsvSessionData()
  const { data: csvSessionRows, loadData, loading } = useAugmentedSessionCsvData(csvSession)

  const downloadReady = ready && !loading && !csvSession.loading

  useEffect(() => {
    void (async () => {
      await loadData()
      setReady(true)
    })()
  }, [loadData])

  useEffect(() => {
    if (downloadReady) onDone()
  }, [downloadReady, onDone])

  if (!downloadReady) return <></>
  return <CSVDownload data={csvSessionRows} filename={getCsvFileName('sana-live')} target='_blank' />
}

type DownloadItem = MenuItem<'downloaddata' | 'downloadchat'>

export const DownloadDataButton = (): JSX.Element => {
  const { t } = useTranslation()
  const [downloadChat, setDownloadChat] = useState(false)
  const [downloadSession, setDownloadSession] = useState(false)

  const menuItems = useMemo<DownloadItem[]>(() => {
    return [
      {
        id: 'downloaddata',
        type: 'label',
        label: t('download.download-session-data'),
        icon: 'export',
        loading: downloadSession,
      },
      {
        id: 'downloadchat',
        type: 'label',
        label: t('download.download-chat'),
        icon: 'chat',
        loading: downloadChat,
      },
    ]
  }, [downloadChat, downloadSession, t])

  const handleItemSelect = (item: DownloadItem): void => {
    switch (item.id) {
      case 'downloaddata':
        setDownloadSession(true)
        return
      case 'downloadchat':
        setDownloadChat(true)
        return
      default:
        assertNever(item.id)
    }
  }

  return (
    <>
      <MenuButton menuItems={menuItems} onSelect={handleItemSelect}>
        {t('download.download-data')}
      </MenuButton>
      {downloadChat && <DownloadChatData onDone={() => setDownloadChat(false)} />}
      {downloadSession && <DownloadSessionData onDone={() => setDownloadSession(false)} />}
    </>
  )
}
