import { motion } from 'framer-motion'
import { titleVariants } from 'sierra-client/features/collapsable-sidebar/animation-config'
import styled from 'styled-components'

/* With the current setting, 125px is the maximu size a text node on the top level sidebar can take.
 * As we add an ellipsis ... which css shouldn't adapt during the animation, we set a min-width on the child
 * and wrap it in a container with overflow hidden which handles the opacity animation
 * */
export const AnimateHideWithoutEllipsis = styled(motion.div).attrs({ variants: titleVariants })<{
  $minWidth: number
}>`
  overflow: hidden;
  min-width: ${p => p.$minWidth}px; /* TODO: Can we paramatrise / calculate this? */
`
