import React, { useEffect, useState } from 'react'
import { HiddenInput } from 'sierra-client/components/common/inputs/hidden-input'
import { RouterLink } from 'sierra-client/components/common/link'
import { Auth } from 'sierra-client/core/auth'
import { usePasswordStrength } from 'sierra-client/hooks/use-password-strength'
import { usePost } from 'sierra-client/hooks/use-post'
import { useTranslation } from 'sierra-client/hooks/use-translation'
import { getGlobalRouter } from 'sierra-client/router'
import { useSelector } from 'sierra-client/state/hooks'
import {
  selectIsInitializing,
  selectIsPendingCodeVerificationOrLoggedIn,
} from 'sierra-client/state/user/user-selector'
import { getUserErrorTranslationKey } from 'sierra-client/utils/translation-utils'
import {
  AuthenticationForm,
  AuthenticationLayout,
} from 'sierra-client/views/authentication/authentication-layout'
import { AuthenticationContent } from 'sierra-client/views/authentication/native/components/authentication-content'
import { AuthenticationContainer } from 'sierra-client/views/authentication/native/components/common'
import { isLeft } from 'sierra-domain/either'
import { XRealtimeAuthCheckInviteCode, XRealtimeAuthCreateUserFromInviteCode } from 'sierra-domain/routes'
import { FormElement, PasswordInputPrimitive } from 'sierra-ui/components'
import { Button, InputPrimitive, Spacer, Text, View } from 'sierra-ui/primitives'

type InviteCodeErrorProps = {
  error: string
  accepted: boolean
}

const InviteCodeError: React.FC<InviteCodeErrorProps> = ({ error, accepted }) => {
  const { t } = useTranslation()

  return (
    <AuthenticationContent message={t('create-account.heading')}>
      <Text color='foreground/primary' size='small' spacing='xsmall'>
        {error}
      </Text>
      {accepted && (
        <RouterLink href={'/'}>
          <Button variant='primary' grow>
            {t('create-account.go-to-login')}
          </Button>
        </RouterLink>
      )}
    </AuthenticationContent>
  )
}

const AlreadySignedIn: React.FC = () => {
  const { t } = useTranslation()

  return (
    <AuthenticationContent message={t('create-account.heading')}>
      <Text color='foreground/primary' size='small'>
        {t('create-account.logged-in')}
      </Text>
      <Spacer size='xsmall' />
      <RouterLink href='/'>
        <Button variant='primary' id={'start-page-button'}>
          {t('accept-invite.go-to-start-page')}
        </Button>
      </RouterLink>
    </AuthenticationContent>
  )
}

type CreateUserProps = {
  code: string
  email: string
  onCreate: () => void
}

const CreateUser: React.FC<CreateUserProps> = ({ code, email, onCreate }: CreateUserProps) => {
  const { t } = useTranslation()
  const [password, setPassword] = useState('')
  const [passwordError, setPasswordError] = useState<string | undefined>(undefined)
  const [passwordConfirmation, setPasswordConfirmation] = useState('')
  const [passwordConfirmationError, setPasswordConfirmationError] = useState<string | undefined>(undefined)
  const [error, setError] = useState<string | undefined>()
  const { postWithUserErrorCode } = usePost()
  const { isPasswordStrengthGood } = usePasswordStrength()

  const disableSubmit = password === '' || passwordConfirmation === ''

  const resetPasswordErrors = (): void => {
    setPasswordError(undefined)
    setPasswordConfirmationError(undefined)
  }

  const handleBlurPassword = async (): Promise<void> => {
    if (!password) return

    if (!(await isPasswordStrengthGood(password))) {
      setPasswordError(t('create-account.errors.password-strength'))
    }
  }

  const onPasswordChange = (e: React.ChangeEvent<HTMLInputElement>): void => {
    setPassword(e.target.value)
    resetPasswordErrors()
  }

  const onPasswordConfirmationChange = (e: React.ChangeEvent<HTMLInputElement>): void => {
    setPasswordConfirmation(e.target.value)
    resetPasswordErrors()
  }

  const submit = async (event: React.FormEvent<HTMLFormElement>): Promise<void> => {
    event.preventDefault()

    setError(undefined)

    let errors = false

    if (!password) {
      errors = true
      setPasswordError(t('create-account.errors.password'))
    } else if (!(await isPasswordStrengthGood(password))) {
      errors = true
      setPasswordError(t('create-account.errors.password-strength'))
    } else {
      setPasswordError(undefined)
    }

    if (password !== passwordConfirmation) {
      errors = true
      setPasswordConfirmationError(t('create-account.errors.password-confirmation'))
    } else {
      setPasswordConfirmationError(undefined)
    }

    if (errors) return

    const result = await postWithUserErrorCode(XRealtimeAuthCreateUserFromInviteCode, {
      code,
      password,
    })

    if (isLeft(result)) {
      setError(t(getUserErrorTranslationKey(result.left)))
      return
    }

    setError(undefined)

    onCreate()

    await Auth.getInstance().synchronize()
  }

  return (
    <AuthenticationContent
      productTourId='product-tour-create-account-invite-code'
      message={t('create-account.heading')}
    >
      <AuthenticationForm onSubmit={submit}>
        <View direction='column' gap='xsmall'>
          <FormElement label={t('create-account.fields.email')}>
            <InputPrimitive type='text' value={email} disabled onChange={() => {}} />
          </FormElement>
          {/* The HiddenInput is necessary for Chrome since it fills the field before the password field with username. */}
          <HiddenInput tabIndex={-1} type='text' value='' readOnly />
          <FormElement
            label={t('create-account.fields.password')}
            isError={passwordError !== undefined}
            helper={passwordError}
          >
            <PasswordInputPrimitive
              id='password'
              value={password}
              onChange={onPasswordChange}
              onBlur={handleBlurPassword}
              autoFocus
            />
          </FormElement>
          <FormElement
            label={t('accept-invite.fields.confirm-password')}
            isError={passwordConfirmationError !== undefined}
            helper={passwordConfirmationError}
          >
            <PasswordInputPrimitive
              id='confirm-password'
              value={passwordConfirmation}
              onChange={onPasswordConfirmationChange}
            />
          </FormElement>
          {error !== undefined && (
            <Text size='small' color='redBright' spacing='xsmall'>
              {error}
            </Text>
          )}
          <Button variant='primary' type='submit' disabled={disableSubmit} grow>
            {t('create-account.continue')}
          </Button>
        </View>
      </AuthenticationForm>
    </AuthenticationContent>
  )
}

type Props = {
  code: string
}

export const NativeCreateAccountFromInviteCode: React.FC<Props> = ({ code }) => {
  const { t } = useTranslation()
  const isInitializing = useSelector(selectIsInitializing)
  const isPendingCodeVerificationOrLoggedIn = useSelector(selectIsPendingCodeVerificationOrLoggedIn)

  const [email, setEmail] = useState<string>('')
  const [codeError, setCodeError] = useState<string | undefined>(undefined)
  const [codeChecked, setCodeChecked] = useState<boolean>(false)
  const [created, setCreated] = useState<boolean>(false)
  const [inviteAccepted, setInviteAccepted] = useState<boolean>(false)
  const { postWithUserErrorCode } = usePost()

  useEffect(() => {
    void (async () => {
      const result = await postWithUserErrorCode(XRealtimeAuthCheckInviteCode, {
        code,
      })

      if (isLeft(result)) {
        setCodeError(t(getUserErrorTranslationKey(result.left)))
        if (result.left === 'auth/invite-already-accepted') {
          setInviteAccepted(true)
        }
      } else {
        setCodeChecked(true)
        setEmail(result.right.email)
      }
    })()
  }, [code, postWithUserErrorCode, t])

  useEffect(() => {
    if (codeChecked && isPendingCodeVerificationOrLoggedIn && created) {
      void getGlobalRouter().navigate({ to: '/' })
    }
  }, [isPendingCodeVerificationOrLoggedIn, codeChecked, created])

  let content: JSX.Element | null

  if (isInitializing) content = null
  else if (codeError !== undefined) content = <InviteCodeError error={codeError} accepted={inviteAccepted} />
  else if (isPendingCodeVerificationOrLoggedIn && !created) content = <AlreadySignedIn />
  else content = <CreateUser code={code} email={email} onCreate={() => setCreated(true)} />

  return (
    <AuthenticationLayout>
      <AuthenticationContainer>{content}</AuthenticationContainer>
    </AuthenticationLayout>
  )
}
