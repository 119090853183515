import React from 'react'
import { useTranslation } from 'sierra-client/hooks/use-translation'
import { Button } from 'sierra-ui/primitives'
import { v2_breakpoint } from 'sierra-ui/theming/breakpoints'
import styled from 'styled-components'

type EnrollButtonProps = {
  enrolled: boolean
  sessionFull: boolean
  loading: boolean
  onClick: () => void
}

export const EnrollButton: React.FC<EnrollButtonProps> = ({ enrolled, sessionFull, loading, onClick }) => {
  const { t } = useTranslation()
  return (
    <Button
      variant={enrolled ? 'primary' : 'secondary'}
      loading={loading}
      disabled={sessionFull && !enrolled}
      onClick={onClick}
    >
      {enrolled ? t('course-detail.enrolled') : t('course-detail.enroll')}
    </Button>
  )
}

export const ActionsBlock = styled.div`
  display: flex;
  gap: 0.5rem;
  justify-content: flex-end;
`

export const DetailsBlock = styled.div`
  flex: 1 1 auto;
  display: flex;
  flex-direction: column;
  overflow: hidden;

  @media screen and (max-width: ${v2_breakpoint.phone}) {
    flex-direction: column;
  }
`
