import { useMutation } from '@tanstack/react-query'
import React, { useCallback, useRef } from 'react'
import { graphql } from 'sierra-client/api/graphql/gql'
import { graphQuery } from 'sierra-client/api/hooks/use-graphql-query'
import { TeamsLogo } from 'sierra-client/components/common/logos/teams-logo'
import { MicrosoftTeamsOauth } from 'sierra-client/components/sana-now-integration-oauth/microsoft-teams-oauth'
import { ReactSimpleOauth2LoginRef } from 'sierra-client/components/sana-now-integration-oauth/shared'
import { useVideoCallProviderAuthenticatedQuery } from 'sierra-client/features/sana-now/create-session-panel/video-call-provider-buttons/use-video-call-provider-auth'
import { useTranslation } from 'sierra-client/hooks/use-translation'
import { isNonNullable } from 'sierra-domain/utils'
import { Button } from 'sierra-ui/primitives'

export const MicrosoftTeamsButton: React.FC<{
  onLink: (url: string) => void
  disabled?: boolean
}> = ({ onLink, disabled }) => {
  const { t } = useTranslation()
  const oauthRef = useRef<ReactSimpleOauth2LoginRef | null>(null)

  const authenticatedQuery = useVideoCallProviderAuthenticatedQuery()

  const clientId = authenticatedQuery.data?.viewer.integrations.microsoftTeamsClientId ?? ''

  const createMeetingLinkMutation = useMutation({
    mutationFn: () =>
      graphQuery(
        graphql(`
          mutation createMicrosoftTeamsUrl {
            createMicrosoftTeamsUrl {
              url
            }
          }
        `)
      ),
    onSuccess: res => {
      if (isNonNullable(res.createMicrosoftTeamsUrl?.url)) {
        onLink(res.createMicrosoftTeamsUrl.url)
      } else {
        oauthRef.current?.onBtnClick()
      }
    },
  })

  const handleOnButtonClick = useCallback(() => {
    if (isNonNullable(createMeetingLinkMutation.data?.createMicrosoftTeamsUrl?.url)) {
      onLink(createMeetingLinkMutation.data.createMicrosoftTeamsUrl.url)
      return
    }

    if (authenticatedQuery.data?.viewer.integrations.microsoftTeamsAuthenticated === true) {
      createMeetingLinkMutation.mutate()
    } else {
      oauthRef.current?.onBtnClick()
    }
  }, [
    createMeetingLinkMutation,
    authenticatedQuery.data?.viewer.integrations.microsoftTeamsAuthenticated,
    onLink,
  ])

  return (
    <>
      <MicrosoftTeamsOauth
        ref={oauthRef}
        clientId={clientId}
        onSuccess={() => {
          createMeetingLinkMutation.mutate()
        }}
      />
      <Button
        variant='secondary'
        onClick={handleOnButtonClick}
        loading={authenticatedQuery.isPending || createMeetingLinkMutation.isPending}
        disabled={authenticatedQuery.isPending || disabled}
        customDecorator={<TeamsLogo width={14} height={14} />}
      >
        {t('dictionary.microsoft-teams')}
      </Button>
    </>
  )
}
