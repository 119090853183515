import { RouterLink } from 'sierra-client/components/common/link'
import { IconId } from 'sierra-ui/components'
import { Button } from 'sierra-ui/primitives'

export const HeaderButton = ({
  href,
  children,
  external = false,
  grow,
  icon,
}: {
  href?: string
  children: string
  external?: boolean
  grow?: boolean
  icon?: IconId
}): JSX.Element | null => {
  if (href === undefined) return null

  return (
    <div data-intercom-selector='start-button'>
      {external ? (
        <Button grow={grow} href={href} target='_blank' rel='noopener noreferrer' icon={icon}>
          {children}
        </Button>
      ) : (
        <RouterLink href={href}>
          <Button grow={grow} icon={icon}>
            {children}
          </Button>
        </RouterLink>
      )}
    </div>
  )
}
