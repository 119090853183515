import { TeamspaceRoleWithOwner } from 'sierra-domain/api/teamspace'

const levels: Record<TeamspaceRoleWithOwner, number> = {
  viewer: 0,
  commenter: 1,
  editor: 2,
  owner: 3,
}

export const isTeamspaceRoleAbove = (
  subject: TeamspaceRoleWithOwner,
  compareTo: TeamspaceRoleWithOwner
): boolean => levels[subject] > levels[compareTo]
