import { createSlice } from '@reduxjs/toolkit'
import { deepMergeJson, deepPatchJson } from '@sanalabs/json'
import { WritableDraft } from 'immer'
import { DateTime } from 'luxon'
import { clearState } from 'sierra-client/state/actions'
import {
  chatAwarenessStatesChanged,
  chatChanged,
  chatCleared,
  chatLocalAwarenessStateMerged,
  chatMessageUpdated,
  chatVisibilityChanged,
  editMessage,
  reactToMessage,
  reactionAdded,
  reactionRemoved,
  resolveComment,
  sendCommentMessage,
  sendEmojiMessage,
  sendPlainMessage,
  setCommentAttachmentStatus,
  unresolveComment,
} from 'sierra-client/state/chat/actions'
import { ChatAwareness, ChatAwarenessData, ChatState } from 'sierra-client/state/chat/types'
import { Chat, CommentTiptapMessage, EmojiMessage, Message, PlainTiptapMessage } from 'sierra-domain/chat'
import { ScopedChatId } from 'sierra-domain/collaboration/types'
import { iife, isEmoji } from 'sierra-domain/utils'

const initialState: ChatState = {
  chats: {},
  awarenesses: {},
  visibleChats: {},
  lastSeenMessageTimestamp: {},
}

const assertChatSynced = (state: ChatState, chatId: ScopedChatId): Chat => {
  const chat = state.chats[chatId]
  if (chat === undefined) {
    throw new Error(
      `AssertionError: Expected chat '${chatId}' to be defined in ${JSON.stringify(state.chats)}`
    )
  }
  return chat
}

const ensureChatExists = (state: ChatState, chatId: ScopedChatId): Chat => {
  const chat = (state.chats[chatId] = state.chats[chatId] ?? { messages: {} })
  return chat
}

const createChatIfUndefined = (state: ChatState, chatId: ScopedChatId): Chat => {
  const chat = state.chats[chatId]
  if (chat !== undefined) return chat

  const newChat = { messages: {}, rootMessageIds: [], replyMessageIds: {} }
  state.chats[chatId] = newChat
  return newChat
}

const createAwarenessIfUndefined = (state: ChatState, chatId: ScopedChatId): ChatAwareness => {
  const awareness = state.awarenesses[chatId]
  if (awareness !== undefined) return awareness

  const newAwareness = { states: [], localState: {} }
  state.awarenesses[chatId] = newAwareness
  return newAwareness
}

const maxDate = (lhs: string, rhs: string): string => {
  if (new Date(lhs).getTime() > new Date(rhs).getTime()) {
    return lhs
  } else {
    return rhs
  }
}

/** Sets the timestamps of the last read message in the given threads to the latest messages in those threads.
 * The root thread has the threadID 'root'.
 */
const markThreadsAsRead = (
  state: WritableDraft<ChatState>,
  chatId: ScopedChatId,
  threadIds: Set<string>
): void => {
  const chat = assertChatSynced(state, chatId)
  const timestamps: Record<string, string> = state.lastSeenMessageTimestamp[chatId] || {}
  for (const msg of Object.values(chat.messages)) {
    // Check if this message is part of a thread that is currently visible
    const threadId = msg.responseToMessageId !== undefined ? msg.responseToMessageId : 'root'
    if (threadIds.has(threadId)) {
      const t = timestamps[threadId]
      timestamps[threadId] = t !== undefined ? maxDate(t, msg.timeSent) : msg.timeSent
    }

    // This message could be the root of a new thread as well.
    // In that case the message id is the same as the thread id.
    if (threadIds.has(msg.id)) {
      const t = timestamps[msg.id]
      timestamps[msg.id] = t !== undefined ? maxDate(t, msg.timeSent) : msg.timeSent
    }
  }

  state.lastSeenMessageTimestamp[chatId] = timestamps
}

/** Marks all messages in all threads as read (including the root thread) */
const markAllMessagesAsRead = (state: WritableDraft<ChatState>, chatId: ScopedChatId): void => {
  const chat = assertChatSynced(state, chatId)
  const allThreads = new Set(
    Object.values(chat.messages).map(m =>
      m.responseToMessageId !== undefined ? m.responseToMessageId : 'root'
    )
  )
  markThreadsAsRead(state, chatId, allThreads)
}

/** Marks messages in the given chat as read based on which threads are currently visible */
const refreshLatestSeenChatMessage = (state: WritableDraft<ChatState>, chatId: ScopedChatId): void => {
  const visible = state.visibleChats[chatId]
  if (visible !== undefined) {
    const visibleThreadIds = Object.entries(visible)
      .filter(([, visibleComponentKeys]) => visibleComponentKeys.length > 0)
      .map(([k]) => k)
    markThreadsAsRead(state, chatId, new Set(visibleThreadIds))
  }
}

const sendMessage = (
  state: WritableDraft<ChatState>,
  chatId: ScopedChatId,
  threadId: string,
  msg: Message
): void => {
  const chat = assertChatSynced(state, chatId)

  if (threadId !== 'root') {
    msg.responseToMessageId = threadId
  }

  chat.messages[msg.id] = msg

  refreshLatestSeenChatMessage(state, chatId)
}

export const chatSlice = createSlice({
  name: 'chat',
  initialState,
  reducers: {},
  extraReducers: builder => {
    builder.addCase(clearState, () => initialState)
    builder.addCase(
      sendPlainMessage.fulfilled,
      (state, { payload: { id, chatId, threadId, userId, tiptapJsonData, serverTime } }) => {
        const msg: PlainTiptapMessage = {
          id,
          type: 'tiptap-plain',
          userId,
          tiptapJsonData,
          timeSent: serverTime,
        }
        sendMessage(state, chatId, threadId, msg)
      }
    )

    builder.addCase(
      sendCommentMessage.fulfilled,
      (
        state,
        { payload: { id, chatId, threadId, userId, tiptapJsonData, contentReference, serverTime } }
      ) => {
        const msg: CommentTiptapMessage = {
          id,
          type: 'tiptap-comment',
          userId,
          tiptapJsonData,
          timeSent: serverTime,
          contentReference,
        }
        sendMessage(state, chatId, threadId, msg)
      }
    )

    builder.addCase(
      sendEmojiMessage.fulfilled,
      (state, { payload: { id, chatId, threadId, userId, emoji, serverTime } }) => {
        const msg: EmojiMessage = {
          id,
          type: 'emoji',
          userId,
          emoji,
          timeSent: serverTime,
        }
        sendMessage(state, chatId, threadId, msg)
      }
    )

    builder.addCase(
      chatVisibilityChanged,
      (state, { payload: { chatId, threadId, componentKey, visible } }) => {
        let threads = state.visibleChats[chatId]
        if (threads === undefined) {
          threads = state.visibleChats[chatId] = {}
        }

        let keys = threads[chatId]
        if (keys === undefined) {
          keys = threads[threadId] = []
        }

        if (keys.includes(componentKey) !== visible) {
          if (visible) {
            keys.push(componentKey)
          } else {
            keys.splice(keys.indexOf(componentKey), 1)
          }
        }

        if (state.chats[chatId] !== undefined) {
          refreshLatestSeenChatMessage(state, chatId)
        }
      }
    )

    builder.addCase(
      reactToMessage.fulfilled,
      (state, { payload: { id, chatId, userId, messageId, reaction, serverTime } }) => {
        const chat = assertChatSynced(state, chatId)
        const message = chat.messages[messageId]

        if (!isEmoji(reaction)) throw new Error(`reactions must be an emoji, got "${reaction}"`)
        if (!message) throw new Error(`No message with id ${messageId} in channel ${chatId}`)
        if (message.type === 'emoji')
          throw new Error(`Cannot react to emoji message with id ${messageId} in channel ${chatId}`)

        if (message.reactions === undefined) message.reactions = {}

        const userReaction = Object.values(message.reactions).find(
          existingReaction => existingReaction.userId === userId && existingReaction.reaction === reaction
        )

        if (userReaction) {
          delete message.reactions[userReaction.id]
        } else {
          message.reactions[id] = {
            id,
            userId,
            reaction,
            timeSent: serverTime,
          }
        }
      }
    )

    builder.addCase(
      editMessage.fulfilled,
      (state, { payload: { tiptapJsonData, messageId, chatId, serverTime } }) => {
        const chat = assertChatSynced(state, chatId)
        const message = chat.messages[messageId]
        if (!message) throw new Error(`No message with id ${messageId} in channel ${chatId}`)
        if (message.type === 'emoji')
          throw new Error(`Cannot edit emoji message with id ${messageId} in channel ${chatId}`)

        message.tiptapJsonData = tiptapJsonData
        message.timeEdited = serverTime
      }
    )

    builder.addCase(
      resolveComment.fulfilled,
      (state, { payload: { chatId, threadId, userId, serverTime } }) => {
        const chat = assertChatSynced(state, chatId)
        const message = Object.values(chat.messages).find(message => message.id === threadId)

        if (message === undefined || message.type !== 'tiptap-comment') {
          return
        }

        message.resolvedAt = serverTime
        message.resolvedBy = userId
      }
    )

    builder.addCase(unresolveComment, (state, { payload: { chatId, threadId } }) => {
      const chat = assertChatSynced(state, chatId)
      const message = Object.values(chat.messages).find(message => message.id === threadId)

      if (message === undefined || message.type !== 'tiptap-comment') {
        return
      }

      delete message.resolvedAt
      delete message.resolvedBy
    })

    builder.addCase(setCommentAttachmentStatus, (state, { payload: { chatId, threadId, isDetached } }) => {
      const chat = assertChatSynced(state, chatId)
      const message = Object.values(chat.messages).find(message => message.id === threadId)

      if (message === undefined || message.type !== 'tiptap-comment') {
        return
      }

      message.detachedFromContent = isDetached
    })

    builder.addCase(reactionAdded, (state, { payload: { chatId, messageId, reaction } }) => {
      const chat = ensureChatExists(state, chatId)
      const message = chat.messages[messageId]
      if (message === undefined || message.type === 'emoji') {
        return
      }

      message.reactions = message.reactions ?? {}
      message.reactions[reaction.id] = reaction
    })

    builder.addCase(reactionRemoved, (state, { payload: { chatId, messageId, reactionId } }) => {
      const chat = ensureChatExists(state, chatId)
      const message = chat.messages[messageId]
      if (message === undefined || message.type === 'emoji') {
        return
      }

      message.reactions = message.reactions ?? {}
      delete message.reactions[reactionId]
    })

    builder.addCase(chatMessageUpdated, (state, { payload: { chatId, data } }) => {
      const chat = ensureChatExists(state, chatId)
      const existingMessage = chat.messages[data.messageId]

      if (
        existingMessage !== undefined &&
        DateTime.fromISO(data.createdAt) < DateTime.fromISO(existingMessage.timeSent)
      ) {
        console.warn('Received outdated message update, ignoring it.', { newMessage: data, existingMessage })
        return
      }

      const message: Message = iife(() => {
        if (data.messageData.type === 'doc') {
          if (data.commentData !== undefined) {
            return {
              id: data.messageId,
              type: 'tiptap-comment',
              userId: data.userId,
              timeSent: data.createdAt,
              timeEdited: data.editedAt,
              responseToMessageId: data.responseToMessageId,
              tiptapJsonData: data.messageData,
              contentReference: data.commentData.contentRef,
              resolvedAt: data.commentData.resolved?.resolvedAt.toISOString(),
              resolvedBy: data.commentData.resolved?.resolvedBy,
              reactions: existingMessage?.type !== 'emoji' ? existingMessage?.reactions : undefined,
            } satisfies CommentTiptapMessage
          }

          return {
            id: data.messageId,
            type: 'tiptap-plain',
            userId: data.userId,
            timeSent: data.createdAt,
            timeEdited: data.editedAt,
            responseToMessageId: data.responseToMessageId,
            tiptapJsonData: data.messageData,
            reactions: existingMessage?.type !== 'emoji' ? existingMessage?.reactions : undefined,
          } satisfies PlainTiptapMessage
        }

        if (data.messageData.type === 'emoji') {
          return {
            id: data.messageId,
            type: 'emoji',
            userId: data.userId,
            timeSent: data.createdAt,
            emoji: data.messageData.emoji,
          } satisfies EmojiMessage
        }

        throw new Error('Unknown chat message type: ' + data.messageData.type)
      })

      chat.messages[message.id] = message
      refreshLatestSeenChatMessage(state, chatId)
    })

    // The following reducers are used for the @sanalabs/y-redux integration

    builder.addCase(chatChanged, (state, { payload: { chatId, chat } }) => {
      chat = Chat.parse(chat)
      const firstSync = state.chats[chatId] === undefined
      deepPatchJson(createChatIfUndefined(state, chatId), chat)
      if (firstSync) {
        // The first time we sync a particular chat we mark all messages in all threads as read.
        // This is because we only want to notify the user about new messages received *after* the page was loaded.
        markAllMessagesAsRead(state, chatId)
      } else {
        refreshLatestSeenChatMessage(state, chatId)
      }
    })

    builder.addCase(chatCleared, (state, { payload: { chatId } }) => {
      delete state.chats[chatId]
    })

    builder.addCase(chatAwarenessStatesChanged, (state, { payload: { chatId, awarenessStates } }) => {
      const awareness = createAwarenessIfUndefined(state, chatId)
      awarenessStates.forEach(state => ChatAwarenessData.parse(state))

      deepPatchJson(awareness.states, awarenessStates)
    })

    builder.addCase(
      chatLocalAwarenessStateMerged,
      (state, { payload: { chatId, partialLocalAwarenessState } }) => {
        const awareness = createAwarenessIfUndefined(state, chatId)
        deepMergeJson(awareness.localState, partialLocalAwarenessState)
      }
    )
  },
})
