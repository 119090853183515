import { createFileRoute } from '@tanstack/react-router'

import { requireLoggedIn } from 'sierra-client/core/require-logged-in'
import { prefetchRecommendations } from 'sierra-client/hooks/use-recommendations'
import { PageIdentifier, SanaPage } from 'sierra-client/layout/sana-page'
import { LearnPage, prefetchLearnerContent } from 'sierra-client/views/workspace/learn/learn-page'

const Page: React.FC = () => {
  return (
    <SanaPage mode='light' showIntercomLauncher={true} page={PageIdentifier.NewLearnPage()}>
      <LearnPage />
    </SanaPage>
  )
}

const RouteComponent = requireLoggedIn(Page)

export const Route = createFileRoute('/learn/')({
  loader: ({ context }) => {
    if (context.userId !== undefined) {
      void prefetchLearnerContent()
      void prefetchRecommendations()
    }
  },
  component: RouteComponent as React.FC,
})
