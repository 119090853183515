import { CoursesPerUser } from 'sierra-domain/user-attributes/courses-per-user'
import { UserAttributesConfig } from 'sierra-domain/user-attributes/user-attributes-config'

/*
 * Verify if emails in both parts of the config object match.
 * */
export const verifyEmailMatches = (
  userAttributesConfig: UserAttributesConfig,
  coursesPerUser: CoursesPerUser
): void => {
  const attributeEmails = Object.keys(userAttributesConfig)
  const courseEmails = Object.keys(coursesPerUser)
  const combinedArray = attributeEmails.concat(courseEmails)

  const uniqueValues = combinedArray.filter((value, index, self) => {
    return self.indexOf(value) === self.lastIndexOf(value)
  })

  if (uniqueValues.length !== 0) {
    throw new Error(`Emails in config object don't match. Missing matches ${JSON.stringify(uniqueValues)}.`)
  }
}
