import React from 'react'
import { AnimationContainer } from 'sierra-client/features/insights/widget-builder/data-selectors/animation-container'
import { WidgetFilterSelector } from 'sierra-client/features/insights/widget-builder/data-selectors/widget-filter-selector'
import { WidgetBuilderState } from 'sierra-client/features/insights/widget-builder/widget-builder-state'
import { useTranslation } from 'sierra-client/hooks/use-translation'
import { MeasureRef, ViewRef } from 'sierra-domain/api/insights'
import { Filter } from 'sierra-domain/filter/datatype/filter'
import { Text, View } from 'sierra-ui/primitives'

export const WidgetFilterContainer: React.FC<{
  selectedView?: ViewRef
  selectedMeasures: MeasureRef[]
  selectedFilter?: Filter
  visualisation: WidgetBuilderState['visualisation']
  onChange: (Filter: Filter) => void
  widgetBuilderState: WidgetBuilderState
}> = ({ selectedView, selectedMeasures, selectedFilter, visualisation, onChange, widgetBuilderState }) => {
  const { t } = useTranslation()

  return (
    <AnimationContainer direction='column' justifyContent='space-between' position='relative'>
      <View justifyContent='space-between'>
        <Text color='foreground/primary' bold>
          {t('manage.insights.widgetbuilder.filter')}
        </Text>
      </View>

      <WidgetFilterSelector
        selectedMeasures={selectedMeasures}
        selectedView={selectedView}
        selectedFilter={selectedFilter}
        visualisation={visualisation}
        onChange={onChange}
        widgetBuilderState={widgetBuilderState}
      />
    </AnimationContainer>
  )
}
