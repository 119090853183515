import { useSetAtom } from 'jotai'
import React from 'react'
import { getFlag } from 'sierra-client/config/global-config'
import { HeaderItem, useToggleMobileGlobalSidebar } from 'sierra-client/features/collapsable-sidebar'
import { ItemsList } from 'sierra-client/features/collapsable-sidebar/items/shared/items-list'
import { ManageSidebar } from 'sierra-client/features/global-sidebar/components/manage-sidebar'
import { SettingsSidebar } from 'sierra-client/features/global-sidebar/components/settings-sidebar'
import { sidebarMainNavigationClickedLogger } from 'sierra-client/features/global-sidebar/logger'
import { useIsCreateAccessible } from 'sierra-client/hooks/use-create-enabled'
import { useTranslation } from 'sierra-client/hooks/use-translation'
import { useDispatch } from 'sierra-client/state/hooks'
import { assistantActiveAtom } from 'sierra-client/views/global-assistant/atoms'

export const SidebarMainNavigation: React.FC<{
  shortcutComponent: React.ReactNode | undefined
}> = ({ shortcutComponent }) => {
  const { t } = useTranslation()
  const dispatch = useDispatch()
  const isCreateAccessible = useIsCreateAccessible()
  const setShowAssistantSearch = useSetAtom(assistantActiveAtom)
  const { close: closeMobileSidebar } = useToggleMobileGlobalSidebar()
  const updatedHomeReelsFlag = getFlag('updated-home-reels')

  return (
    <nav>
      {shortcutComponent}
      <ItemsList $marginTop={16}>
        <HeaderItem
          type='label'
          iconId='home'
          title={t('topbar.home')}
          href='/'
          preload
          onClick={() => {
            void dispatch(
              sidebarMainNavigationClickedLogger({
                navigatedTo: 'home',
              })
            )
          }}
        />
        <HeaderItem
          type='simple-button-item'
          iconId='search'
          title={t('dictionary.search')}
          onClick={() => {
            void dispatch(sidebarMainNavigationClickedLogger({ navigatedTo: 'search' }))
            setShowAssistantSearch(true)
            closeMobileSidebar()
          }}
        />
        {updatedHomeReelsFlag && (
          <HeaderItem
            type='label'
            iconId='browse'
            title={t('dictionary.browse')}
            href='/browse'
            preload
            onClick={() => {
              void dispatch(sidebarMainNavigationClickedLogger({ navigatedTo: 'browse' }))
            }}
          />
        )}
        <HeaderItem
          type='label'
          id='learn'
          href='/learn'
          iconId='grid'
          title={t('topbar.library')}
          preload
          onClick={() => {
            void dispatch(
              sidebarMainNavigationClickedLogger({
                navigatedTo: 'myLibrary',
              })
            )
          }}
        />

        {isCreateAccessible && (
          <HeaderItem
            type='label'
            iconId='edit'
            title={t('topbar.author')}
            href='/create'
            preload
            onClick={() => {
              void dispatch(
                sidebarMainNavigationClickedLogger({
                  navigatedTo: 'create',
                })
              )
            }}
          />
        )}

        <ManageSidebar />
        <SettingsSidebar />
      </ItemsList>
    </nav>
  )
}
