import { AiGradientBase } from 'sierra-ui/components'
import { useAiColors } from 'sierra-ui/components/ai-kit/ai-color-utils'

export const SanaGradientIcon = (base?: AiGradientBase): JSX.Element => {
  const { aiColor, aiColorTwo } = useAiColors(base)

  return (
    <svg width='16' height='16' viewBox='0 0 16 16' fill='none' xmlns='http://www.w3.org/2000/svg'>
      <path
        d='M8.00118 5.99108C9.37915 5.99108 10.4962 4.87402 10.4962 3.49606C10.4962 2.1181 9.37915 1.00104 8.00118 1.00104C6.62322 1.00104 5.50617 2.1181 5.50617 3.49606C5.50617 4.87402 6.62322 5.99108 8.00118 5.99108Z'
        fill='black'
        fillOpacity='0.6'
      />
      <path
        d='M8.00118 5.99108C9.37915 5.99108 10.4962 4.87402 10.4962 3.49606C10.4962 2.1181 9.37915 1.00104 8.00118 1.00104C6.62322 1.00104 5.50617 2.1181 5.50617 3.49606C5.50617 4.87402 6.62322 5.99108 8.00118 5.99108Z'
        fill='url(#paint0_linear_6378_10151)'
      />
      <path
        d='M5.99004 8.00308C5.99004 6.62512 4.87298 5.50806 3.49502 5.50806C2.11706 5.50806 1 6.62512 1 8.00308C1 9.38104 2.11706 10.4981 3.49502 10.4981C4.87298 10.4981 5.99004 9.38104 5.99004 8.00308Z'
        fill='black'
        fillOpacity='0.6'
      />
      <path
        d='M5.99004 8.00308C5.99004 6.62512 4.87298 5.50806 3.49502 5.50806C2.11706 5.50806 1 6.62512 1 8.00308C1 9.38104 2.11706 10.4981 3.49502 10.4981C4.87298 10.4981 5.99004 9.38104 5.99004 8.00308Z'
        fill='url(#paint1_linear_6378_10151)'
      />
      <path
        d='M10.4962 12.5057C10.4962 13.8837 9.37915 15.0007 8.00118 15.0007C6.62322 15.0007 5.50617 13.8837 5.50617 12.5057C5.50617 11.1278 6.62322 10.0107 8.00118 10.0107C9.37915 10.0107 10.4962 11.1278 10.4962 12.5057Z'
        fill='black'
        fillOpacity='0.6'
      />
      <path
        d='M10.4962 12.5057C10.4962 13.8837 9.37915 15.0007 8.00118 15.0007C6.62322 15.0007 5.50617 13.8837 5.50617 12.5057C5.50617 11.1278 6.62322 10.0107 8.00118 10.0107C9.37915 10.0107 10.4962 11.1278 10.4962 12.5057Z'
        fill='url(#paint2_linear_6378_10151)'
      />
      <path
        d='M15 8.00308C15 6.62512 13.8829 5.50806 12.505 5.50806C11.127 5.50806 10.01 6.62512 10.01 8.00308C10.01 9.38104 11.127 10.4981 12.505 10.4981C13.8829 10.4981 15 9.38104 15 8.00308Z'
        fill='black'
        fillOpacity='0.6'
      />
      <path
        d='M15 8.00308C15 6.62512 13.8829 5.50806 12.505 5.50806C11.127 5.50806 10.01 6.62512 10.01 8.00308C10.01 9.38104 11.127 10.4981 12.505 10.4981C13.8829 10.4981 15 9.38104 15 8.00308Z'
        fill='url(#paint3_linear_6378_10151)'
      />
      <defs>
        <linearGradient
          id='paint0_linear_6378_10151'
          x1='1'
          y1='1.00104'
          x2='17.0178'
          y2='3.91705'
          gradientUnits='userSpaceOnUse'
        >
          <stop stopColor={aiColor.toHexString()} />
          <stop offset='1' stopColor={aiColorTwo.toHexString()} />
        </linearGradient>
        <linearGradient
          id='paint1_linear_6378_10151'
          x1='1'
          y1='1.00104'
          x2='17.0178'
          y2='3.91705'
          gradientUnits='userSpaceOnUse'
        >
          <stop stopColor={aiColor.toHexString()} />
          <stop offset='1' stopColor={aiColorTwo.toHexString()} />
        </linearGradient>
        <linearGradient
          id='paint2_linear_6378_10151'
          x1='1'
          y1='1.00104'
          x2='17.0178'
          y2='3.91705'
          gradientUnits='userSpaceOnUse'
        >
          <stop stopColor={aiColor.toHexString()} />
          <stop offset='1' stopColor={aiColorTwo.toHexString()} />
        </linearGradient>
        <linearGradient
          id='paint3_linear_6378_10151'
          x1='1'
          y1='1.00104'
          x2='17.0178'
          y2='3.91705'
          gradientUnits='userSpaceOnUse'
        >
          <stop stopColor={aiColor.toHexString()} />
          <stop offset='1' stopColor={aiColorTwo.toHexString()} />
        </linearGradient>
      </defs>
    </svg>
  )
}
