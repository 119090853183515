import * as Sentry from '@sentry/react'
import { UserSingleton } from 'sierra-client/core/user'
import { Meta, UnknownError } from 'sierra-client/error/log-meta'

/**
 * A logger that will report warnings and errors to Sentry
 */
export class ErrorLogger {
  private lastEvent: Sentry.Event | undefined

  warn = (msg: string, meta?: Meta): string => {
    const error = new Error(msg)
    return this.captureWarning(error, meta)
  }

  error = (msg: string, meta?: Meta): string => {
    const error = new Error(msg)
    return this.captureError(error, meta)
  }

  captureError = (e: unknown, meta: Meta = {}): string => {
    const error = e instanceof Error ? e : new UnknownError(e)

    const { tags, fingerprint, ...metaContext } = meta

    return Sentry.captureException(error, {
      contexts: {
        meta: metaContext,
      },
      tags,
      fingerprint:
        fingerprint !== undefined ? (Array.isArray(fingerprint) ? fingerprint : [fingerprint]) : undefined,
    })
  }

  captureWarning = (e: unknown, meta: Meta = {}): string => {
    const error = e instanceof Error ? e : new UnknownError(e)
    const { tags, fingerprint, ...metaContext } = meta

    return Sentry.captureException(error, {
      contexts: {
        meta: metaContext,
      },
      level: 'warning',
      tags,
      fingerprint:
        fingerprint !== undefined ? (Array.isArray(fingerprint) ? fingerprint : [fingerprint]) : undefined,
    })
  }

  askForUserReport = (reportTitle: string, meta: Meta = {}): void => {
    const previousEventId = this.lastEvent?.event_id
    const { tags, ...metaContext } = meta

    const eventId = Sentry.captureMessage(reportTitle, {
      level: 'info',
      contexts: {
        meta: metaContext,
        previousEvent: { previousEventId },
      },
      tags,
    })

    const user = UserSingleton.getInstance().getUser()

    Sentry.showReportDialog({
      eventId: eventId,
      title: 'Report Issue',
      subtitle: 'Thank you for taking the time to report the issue.',
      subtitle2: '',
      labelSubmit: 'Submit',
      user: {
        name: user?.firstName,
        email: user?.email,
      },
    })
  }

  /**
   * Sentry internally dedeup events, so we need to track the last sent events ourselves
   * We need this event in order to connect user feedback to a sentry event
   * @param event
   */
  setLastSentryEvent = (event: Sentry.Event): void => {
    this.lastEvent = event
  }
}

export const errorLogger = new ErrorLogger()
