import { useTranslation } from 'sierra-client/hooks/use-translation'

export const useFormattedList = ({
  list,
  type,
}: {
  list: Array<string>
  type: 'conjunction' | 'disjunction'
}): string => {
  const { lang } = useTranslation()

  const formatter = new Intl.ListFormat(lang, {
    style: 'long',
    type,
  })

  return formatter.format(list)
}
