import { Logger } from 'sierra-client/core/logging/logger'

export const mediaLibraryManageModalOpened = Logger.trackLoggerWithExtra(
  'media-library-manage-modal-opened',
  input => {
    return {
      ...input,
    }
  }
)

type MediaLibraryUploaded = {
  uploadedBy: 'browse' | 'drag-and-drop'
}

export const mediaLibraryImageUploaded = Logger.trackLoggerWithExtra<
  MediaLibraryUploaded,
  MediaLibraryUploaded
>('media-library-image-uploaded', input => {
  return {
    ...input,
  }
})

export const mediaLibraryTagAdded = Logger.trackLoggerWithExtra('media-library-tag-added', input => {
  return {
    ...input,
  }
})

export const mediaLibraryImageTagged = Logger.trackLoggerWithExtra('media-library-image-tagged', input => {
  return {
    ...input,
  }
})

export const mediaLibraryImageUsed = Logger.trackLoggerWithExtra('media-library-images-used', input => {
  return {
    ...input,
  }
})
