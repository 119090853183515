import { useEffect } from 'react'

export function useScrollToView(
  ref: React.RefObject<HTMLElement | null>,
  {
    shouldScroll = true,
    behavior = 'instant',
    block = 'nearest',
  }: { shouldScroll?: boolean; behavior?: ScrollBehavior; block?: ScrollLogicalPosition } = {}
): void {
  useEffect(() => {
    if (!shouldScroll) return

    const element = ref.current
    if (!element) return

    // Only scroll if the element is not visible
    const observer = new IntersectionObserver(([entry]) => {
      if (!Boolean(entry?.isIntersecting)) {
        element.scrollIntoView({ behavior, block })
      }

      observer.disconnect()
    })

    observer.observe(element)
    return () => {
      observer.disconnect()
    }
  }, [ref, shouldScroll, behavior, block])
}
