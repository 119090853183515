import { useCallback } from 'react'
import { gcsBlobUpload } from 'sierra-client/api/blob-upload-with-progress'
import { getFileContentVideo, getNamespacedImage } from 'sierra-client/api/content'
import { useNotif } from 'sierra-client/components/common/notifications'
import { usePost } from 'sierra-client/hooks/use-post'
import { useTranslation } from 'sierra-client/hooks/use-translation'
import { useSelector } from 'sierra-client/state/hooks'
import { selectUserOrganizationId } from 'sierra-client/state/user/user-selector'
import { UploadMediaWithProgress } from 'sierra-client/views/v3-author/common/media-uploader/types'
import { maxFileSizeBytes, maxFileSizeGb } from 'sierra-client/views/v3-author/plugins/with-file-paste'
import { XRealtimeAuthorSetVideoUploaded, XRealtimeAuthorUploadVideo } from 'sierra-domain/routes'

type VideoUploadFunction = UploadMediaWithProgress<{ url: string } | undefined>
export const useUploadVideoWithProgress = (): { uploadVideo: VideoUploadFunction } => {
  const organizationId = useSelector(selectUserOrganizationId)
  const { postWithUserErrorException } = usePost()
  const { t } = useTranslation()
  const notif = useNotif()

  const uploadVideo = useCallback<VideoUploadFunction>(
    async (blob, assetContext, onProgress) => {
      if (organizationId === undefined) return

      if (blob.size > maxFileSizeBytes) {
        notif.push({
          type: 'error',
          body: t('manage.media-library.file-too-large', { size: maxFileSizeGb }),
        })

        throw new Error(`File too large (max size ${maxFileSizeGb} GB)`)
      }

      const data = assetContext.type === 'course' ? { courseId: assetContext.courseId } : {}

      // Get a signed URL for upload
      const { videoId, url: uploadUrl } = await postWithUserErrorException(XRealtimeAuthorUploadVideo, data)

      await gcsBlobUpload({
        url: uploadUrl,
        blob,
        contentType: 'video/mp4',
        onProgress: value => {
          onProgress('uploading', value)
        },
      })

      await postWithUserErrorException(XRealtimeAuthorSetVideoUploaded, {
        fileId: videoId,
        courseId: assetContext.type === 'course' ? assetContext.courseId : undefined,
      })

      if (assetContext.type === 'course') {
        return {
          url: getNamespacedImage(assetContext, videoId, 'video'),
        }
      }

      return {
        url: getFileContentVideo(videoId, organizationId),
      }
    },
    [postWithUserErrorException, organizationId, notif, t]
  )

  return { uploadVideo }
}
