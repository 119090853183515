import { DateTime } from 'luxon'
import { useEffect, useState } from 'react'
import { useStableFunction } from 'sierra-client/hooks/use-stable-function'

const getSecondsRemaining = (endTime: DateTime): number => {
  const diff = Math.round(endTime.diff(DateTime.local(), 'seconds').seconds)
  return Math.max(-1, diff)
}

/**
 * A HOC that takes a render prop and renders the number of seconds remaining until the end time.
 * Note: It actually counts down to -1, so you'll need to handle that case in your render prop. This is to allow the timer to show 0 seconds remaining, which feels more natural.
 */
export const CountDown = ({
  endTime,
  onTimeoutDone,
  render,
}: {
  endTime: DateTime
  onTimeoutDone: () => void
  render: (seconds: number) => JSX.Element
}): JSX.Element => {
  const [secondsToJoin, setSecondsToJoin] = useState(getSecondsRemaining(endTime))

  const stableOnTimeoutDone = useStableFunction(onTimeoutDone)

  useEffect(() => {
    const id = setInterval(() => {
      const secondsRemaining = getSecondsRemaining(endTime)
      setSecondsToJoin(secondsRemaining)

      if (secondsRemaining === -1) {
        clearInterval(id)
        stableOnTimeoutDone()
      }
    }, 1000)

    return () => {
      clearInterval(id)
    }
  }, [endTime, stableOnTimeoutDone])

  return render(secondsToJoin)
}
