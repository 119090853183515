import React from 'react'
import { useTranslation } from 'sierra-client/hooks/use-translation'
import { Empty } from 'sierra-client/views/manage/components/common'
import { SmallTableWrapper } from 'sierra-client/views/manage/components/small-table-wrapper'
import { CoursesTable } from 'sierra-client/views/manage/tags/components/tables'
import { CourseId } from 'sierra-domain/api/nano-id'

export const CoursesSection: React.FC<{
  courseIds?: CourseId[]
}> = ({ courseIds }) => {
  const { t } = useTranslation()

  return (
    <SmallTableWrapper title={t('admin.organization.tab.courses')}>
      {courseIds === undefined || courseIds.length === 0 ? (
        <Empty
          hideIcon
          title={t('manage.tags.course.empty-title')}
          body={t('manage.tags.course.empty-body')}
        />
      ) : (
        <CoursesTable courseIds={courseIds} />
      )}
    </SmallTableWrapper>
  )
}
