import { debounce } from 'lodash'
import { useCallback, useMemo } from 'react'
import { Logging } from 'sierra-client/core/logging'
import { useDispatch } from 'sierra-client/state/hooks'
import { CourseId, FileId } from 'sierra-domain/api/nano-id'
import { ScenarioDataSource } from 'sierra-domain/flexible-content/types'

type BaseTrackingData = {
  courseId: CourseId
  fileId: `file:${string}`
}

const extractFileId = (fileId: `file:${string}`): FileId => {
  return FileId.parse(fileId.split(':')[1])
}

type ScenarioCardTracking = {
  inputPrompt: {
    setScenarioSuggestion: (_: { title: string }) => void
    setCompletionCriteriaSuggestion: (_: { criteria: string }) => void
    setContext: (_: { context: Array<ScenarioDataSource> }) => void
    setDescription: (_: { description: string }) => void
    setLearnerRole: (_: { role: string }) => void
    setAssistantRole: (_: { role: string }) => void
    setCompletionCriteria: (_: { criteria: string }) => void
    setAssistantBehaviour: (_: { behaviour: string }) => void
    setAssistantAvatar: (_: { avatar: string }) => void
  }
  editGeneratedPrompt: {
    editLearnerRole: (_: { role: string }) => void
    editLearnerScenario: (_: { scenario: string }) => void
    editLearnerObjective: (_: { objective: string }) => void
    editAssistantRole: (_: { role: string }) => void
    editAssistantScenario: (_: { scenario: string }) => void
    editAssistantObjective: (_: { objective: string }) => void
    editScenarioTitle: (_: { title: string }) => void
    editScenarioDescription: (_: { description: string }) => void
    switchLearnerAndAssistant: () => void
  }
  misc: {
    clickPreview: () => void
  }
  learner: {
    completedFailure: () => void
    completedSuccess: () => void
    complianceFailure: () => void
    restartScenario: () => void
    undoLastMessage: () => void
    tryAgain: () => void
    sentMessages: (_: { messages: number }) => void
    startScenario: () => void
    endConversation: () => void
  }
}

export const useTracking = ({ courseId, fileId: _fileId }: BaseTrackingData): ScenarioCardTracking => {
  const dispatch = useDispatch()
  const debouncedAction = useCallback(<I>(cb: (_: I) => void) => debounce(cb, 3000), [])
  const fileId = extractFileId(_fileId)

  return useMemo(
    () => ({
      inputPrompt: {
        setScenarioSuggestion: debouncedAction(({ title }) =>
          dispatch(Logging.scenarioCard.setScenarioSuggestion({ title, courseId, fileId }))
        ),
        setCompletionCriteriaSuggestion: debouncedAction(({ criteria }) =>
          dispatch(Logging.scenarioCard.setCompletionCriteriaSuggestion({ criteria, courseId, fileId }))
        ),
        setContext: debouncedAction(({ context }) =>
          dispatch(Logging.scenarioCard.setContext({ context, courseId, fileId }))
        ),
        setAssistantBehaviour: debouncedAction(({ behaviour }) =>
          dispatch(Logging.scenarioCard.setAssistantBehaviour({ behaviour, courseId, fileId }))
        ),
        setDescription: debouncedAction(({ description }) =>
          dispatch(Logging.scenarioCard.setDescription({ description, courseId, fileId }))
        ),
        setLearnerRole: debouncedAction(({ role }) =>
          dispatch(Logging.scenarioCard.setLearnerRole({ role, courseId, fileId }))
        ),
        setAssistantRole: debouncedAction(({ role }) =>
          dispatch(Logging.scenarioCard.setAssistantRole({ role, courseId, fileId }))
        ),
        setCompletionCriteria: debouncedAction(({ criteria }) =>
          dispatch(Logging.scenarioCard.setCompletionCriteria({ criteria, courseId, fileId }))
        ),
        setAssistantAvatar: debouncedAction(({ avatar }) =>
          dispatch(Logging.scenarioCard.setAssistantAvatar({ courseId, fileId, avatar }))
        ),
      },
      editGeneratedPrompt: {
        editLearnerRole: debouncedAction(({ role }) =>
          dispatch(Logging.scenarioCard.editLearnerRole({ role, courseId, fileId }))
        ),
        editLearnerScenario: debouncedAction(({ scenario }) =>
          dispatch(Logging.scenarioCard.editLearnerScenario({ scenario, courseId, fileId }))
        ),
        editLearnerObjective: debouncedAction(({ objective }) =>
          dispatch(Logging.scenarioCard.editLearnerObjective({ objective, courseId, fileId }))
        ),
        editAssistantRole: debouncedAction(({ role }) =>
          dispatch(Logging.scenarioCard.editAssistantRole({ role, courseId, fileId }))
        ),
        editAssistantScenario: debouncedAction(({ scenario }) =>
          dispatch(Logging.scenarioCard.editAssistantScenario({ scenario, courseId, fileId }))
        ),
        editAssistantObjective: debouncedAction(({ objective }) =>
          dispatch(Logging.scenarioCard.editAssistantObjective({ objective, courseId, fileId }))
        ),
        editScenarioTitle: debouncedAction(({ title }) =>
          dispatch(Logging.scenarioCard.editScenarioTitle({ title, courseId, fileId }))
        ),
        editScenarioDescription: debouncedAction(({ description }) =>
          dispatch(Logging.scenarioCard.editScenarioDescription({ description, courseId, fileId }))
        ),
        switchLearnerAndAssistant: () =>
          dispatch(Logging.scenarioCard.switchLearnerAndAssistant({ courseId, fileId })),
      },
      misc: {
        clickPreview: () => dispatch(Logging.scenarioCard.clickPreview({ courseId, fileId })),
      },
      learner: {
        completedFailure: () => dispatch(Logging.scenarioCard.completedFailure({ courseId, fileId })),
        completedSuccess: () => dispatch(Logging.scenarioCard.completedSuccess({ courseId, fileId })),
        complianceFailure: () => dispatch(Logging.scenarioCard.complianceFailure({ courseId, fileId })),
        sentMessages: ({ messages }) =>
          dispatch(Logging.scenarioCard.sentMessages({ messages, courseId, fileId })),
        restartScenario: () => dispatch(Logging.scenarioCard.restartScenario({ courseId, fileId })),
        undoLastMessage: () => dispatch(Logging.scenarioCard.undoLastMessage({ courseId, fileId })),
        tryAgain: () => dispatch(Logging.scenarioCard.tryAgain({ courseId, fileId })),
        startScenario: () => dispatch(Logging.scenarioCard.startScenario({ courseId, fileId })),
        endConversation: () => dispatch(Logging.scenarioCard.endConversation({ courseId, fileId })),
      },
    }),
    [courseId, debouncedAction, dispatch, fileId]
  )
}
