import React from 'react'
import { TemplatePickerProvider, useTemplatePicker } from 'sierra-client/components/templates'
import { useTranslation } from 'sierra-client/hooks/use-translation'

import { Button } from 'sierra-ui/primitives'

type Props = { onClick?: () => void; label?: string }

const ButtonComponent: React.FC<Props> = props => {
  const { templatePickerOn } = useTemplatePicker()
  const { t } = useTranslation()

  const label = props.label ?? t('admin.create-new')

  return (
    <Button
      grow
      id='tour-start-create'
      data-intercom-target='Create new button'
      variant='primary'
      {...props}
      onClick={() => {
        templatePickerOn()
        props.onClick?.()
      }}
    >
      {label}
    </Button>
  )
}

export const CreateNewCourseButton: React.FC<Props> = props => (
  <TemplatePickerProvider>
    <ButtonComponent {...props} />
  </TemplatePickerProvider>
)
