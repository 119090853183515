import React from 'react'
import { isMacOs } from 'react-device-detect'
import { Icon, IconId } from 'sierra-ui/components'
import { LoadingSpinner, Text, View } from 'sierra-ui/primitives'
import { palette, spacing } from 'sierra-ui/theming'
import styled from 'styled-components'

const KeyBoardButton = styled.button`
  border-radius: 4px;
  display: flex;
  text-align: center;
  justify-content: center;
  align-items: center;
  margin: 0 ${spacing['2']};
  cursor: pointer;
  height: 19px;
  width: 18px;
  background-color: ${palette.grey[2]};
`

const MenuItemView = styled(View)`
  margin: -12px -16px;
  padding: 12px 0;
  width: 100%;
  margin-left: 0;
`

export const CanvasMenuItem: React.FC<{
  onClick: (e: React.MouseEvent<HTMLElement, MouseEvent>) => void
  loading?: boolean
  icon: IconId
  text: string
  keyboardShortcut: 'D' | 'C' | 'V'
}> = ({ onClick, loading = false, icon, text, keyboardShortcut }) => {
  return (
    <MenuItemView onClick={onClick} justifyContent='space-between'>
      <View>
        {loading ? (
          <LoadingSpinner size='14' padding='none' color='currentColor' />
        ) : (
          <Icon size='size-14' iconId={icon} color='foreground/primary' />
        )}
        <Text size='small'>{text}</Text>
      </View>
      <View gap='none'>
        <KeyBoardButton>
          <Text size='technical' color='grey40'>
            {isMacOs ? '⌘' : 'ctrl'}
          </Text>
        </KeyBoardButton>
        <KeyBoardButton>
          <Text size='technical' color='grey40'>
            {keyboardShortcut}
          </Text>
        </KeyBoardButton>
      </View>
    </MenuItemView>
  )
}
