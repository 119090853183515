import { isEqual } from 'lodash'
import { DomainRep, Filter, Or, andAll, createFilter, defaultOp, orAll } from 'sierra-client/lib/filter'
import { assertNever } from 'sierra-domain/utils'

export type FilterInitializer = (domainRep: DomainRep) => Filter
export const singleFilterInit: FilterInitializer = dr => createFilter(dr.ref, defaultOp(dr), Or([]))
export const groupFilterInit: FilterInitializer = dr => andAll([singleFilterInit(dr)])

export const setFilterAt = (f: Filter, nf: Filter, index: number): Filter => {
  switch (f.type) {
    case 'filter.filter':
      return f
    case 'filter.and':
      return andAll(f.filters.map((f, i) => (i === index ? nf : f)))
    case 'filter.or':
      return orAll(f.filters.map((f, i) => (i === index ? nf : f)))
    default:
      assertNever(f)
  }
}

export const removeFilterAt = (f: Filter, index: number): Filter => {
  switch (f.type) {
    case 'filter.filter':
      return f
    case 'filter.and':
      return andAll(f.filters.filter((_, i) => i !== index))
    case 'filter.or':
      return orAll(f.filters.filter((_, i) => i !== index))
    default:
      assertNever(f)
  }
}

export const appendFilter = (f: Filter, nf: Filter): Filter => {
  switch (f.type) {
    case 'filter.filter':
      return f
    case 'filter.and':
      return andAll([...f.filters, nf])
    case 'filter.or':
      return orAll([...f.filters, nf])
    default:
      return assertNever(f)
  }
}

export const isFilterEqual = (filter1?: Filter, filter2?: Filter): boolean => {
  const isFilterEqual = isEqual(filter1, filter2)
  return isFilterEqual
}
