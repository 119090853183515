import { AnimatePresence } from 'framer-motion'
import React, { useCallback, useEffect, useMemo, useState } from 'react'
import { getFlag } from 'sierra-client/config/global-config'
import { StepNumber, useOutlineReducer } from 'sierra-client/features/program'
import { useTranslation } from 'sierra-client/hooks/use-translation'
import { useOutlineEditPanels } from 'sierra-client/views/manage/programs/staggered-assignments/hooks/use-outline-edit-panels'
import { highlightAnimationDuration } from 'sierra-client/views/manage/programs/staggered-assignments/hooks/use-scroll-highlight'
import { clusterSections } from 'sierra-client/views/manage/programs/staggered-assignments/renderer/clusterSection'
import { SectionRenderer } from 'sierra-client/views/manage/programs/staggered-assignments/renderer/sections/section-renderer'
import { ProgramStepRenderer } from 'sierra-client/views/manage/programs/staggered-assignments/types'
import { getStepId } from 'sierra-client/views/manage/programs/staggered-assignments/utils'
import { isDefined } from 'sierra-domain/utils'
import { color } from 'sierra-ui/color'
import { Icon, MenuButton, MenuItem, Popover, StepBadge, type StepBadgeProps } from 'sierra-ui/components'
import { IconButton, Text, View } from 'sierra-ui/primitives'
import { palette, spacing, token } from 'sierra-ui/theming'
import { defaultEasing } from 'sierra-ui/theming/animations'
import { ConditionalWrapper, focusRing } from 'sierra-ui/utils'
import styled, { css, keyframes } from 'styled-components'

const OutlineWrapper = styled(View).attrs({
  direction: 'column',
  justifyContent: 'flex-start',
  alignItems: 'stretch',
  gap: '12',
})`
  user-select: none;
`

export const VerticalStepWrapper = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: stretch;

  & > * {
    margin-bottom: 2px;
  }
`

const StepNumberContainer = styled(View).attrs({
  justifyContent: 'center',
  alignItems: 'center',
})`
  grid-area: Number;
  align-self: center;
`

export const StepNumberPill: React.FC<StepBadgeProps> = props => (
  <StepNumberContainer>
    <StepBadge size='small' {...props} />
  </StepNumberContainer>
)

export const TwoLineCell = styled(View).attrs({
  direction: 'column',
  grow: true,
  justifyContent: 'center',
  gap: '2',
})`
  align-self: center;
`

const OverflowText = styled(Text)<{ $minWidth?: number }>`
  max-width: ${p => p.$minWidth ?? '125'}px;
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
`

const PopoverTriggerIcon = styled(Icon)``

const PopoverTriggerButton = styled.button`
  display: flex;
  align-items: center;
  gap: 2px;
  background: transparent;
  width: fit-content;
  border-radius: 6px;
  border: 1px solid transparent;

  &:hover ${PopoverTriggerIcon} {
    color: ${token('foreground/primary')};
  }

  &:focus-visible {
    ${focusRing};
    border: 1px solid ${token(`form/focus/border`)};
  }
`

const PopoverContent = styled.div`
  width: 280px;
  height: auto;
  padding: 16px;
  overflow: hidden;
`

export const DropdownPopover: React.FC<
  React.PropsWithChildren<{ label: string; minWidth?: number; empty?: boolean; initialOpen?: boolean }>
> = ({ label, empty, minWidth, children, initialOpen }) => {
  const [open, setOpen] = useState(false)

  useEffect(() => {
    if (initialOpen === true) {
      setOpen(true)
    }
  }, [initialOpen])

  return (
    <Popover
      renderTrigger={() => (
        <PopoverTriggerButton>
          <OverflowText
            $minWidth={minWidth}
            size='small'
            color={empty === true ? 'foreground/muted' : 'foreground/primary'}
          >
            {label}
          </OverflowText>
          <PopoverTriggerIcon
            iconId={open ? 'chevron--up--small' : 'chevron--down--small'}
            color='foreground/muted'
          />
        </PopoverTriggerButton>
      )}
    >
      <PopoverContent>{children}</PopoverContent>
    </Popover>
  )
}

export const stepSpacing = {
  paddingTop: '12' as const,
  paddingLeft: '10' as const,
  paddingRight: '10' as const,
  paddingBottom: '12' as const,
  gap: '12' as const,
}

export const StepBase = styled(View).attrs({
  direction: 'column',
  justifyContent: 'center',
  alignItems: 'stretch',
  borderColor: 'border/default',
  grow: true,
})`
  border-radius: 16px;
  border-color: transparent;
  outline: 1px solid ${token('border/default')};
  box-shadow: 0px 2px 4px 0px #00000014;
`

export const DragHandle = styled(Icon).attrs({ iconId: 'draggable', color: 'foreground/muted' })`
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  cursor: grab;
  margin-right: ${spacing['6']};

  &:hover {
    color: ${token('foreground/primary')};
  }
`

export const DragHandleAndNumber = styled(View).attrs({
  direction: 'column',
  justifyContent: 'center',
  alignItems: 'center',
})`
  position: relative;
  align-self: center;
`

export const DeleteIconButton = styled(IconButton).attrs({
  iconId: 'trash-can',
  color: 'foreground/muted',
  variant: 'transparent',
  size: 'small',
})``

const pulse = keyframes`
    0% {
        box-shadow: 0px 0px 0px 0px ${palette.blue.pastel};
        border-color: ${color('grey10')};
    }

    10% {
        border-color: ${palette.blue.bright};
    }

    55% {
        box-shadow: 0px 0px 0px 12px ${color('white')};
    }

    56% {
        box-shadow: 0px 0px 0px 0px ${palette.blue.pastel};
    }

    100% {
        box-shadow: 0px 0px 0px 12px ${color('white')};
        border-color: ${palette.blue.bright};
    }
`

type StepOutlineProps = {
  isDragging?: boolean
  lineOnHover?: boolean
  highlighted?: boolean
  isDeleteFocused?: boolean
}

export const StepOutline = styled(StepBase).attrs({
  background: 'surface/default',
  gap: 'none',
})<StepOutlineProps>`
  & ${DeleteIconButton} {
    opacity: 0;
    pointer-events: none;
  }

  &:focus-visible,
  &:focus-within,
  &:hover {
    ${DeleteIconButton} {
      opacity: 1;
      pointer-events: auto;
    }
  }

  &:focus-visible,
  &:focus-within {
    ${focusRing};
    z-index: 1; /* ensure the focus ring appears above sibling elements */
    border: 1px solid ${token(`form/focus/border`)};
  }

  ${p =>
    p.isDeleteFocused === true &&
    css`
      & ${DeleteIconButton} {
        opacity: 1;
        box-shadow: 0px 0px 0px 4px ${palette.blue.bright}50;

        svg {
          color: ${token('foreground/primary')} !important;
        }
      }
    `}
  & ${DragHandle} {
    opacity: 0;
  }

  &:hover ${DragHandleAndNumber} ${StepNumberContainer} {
    opacity: 0;
  }

  &:hover ${DragHandle} {
    opacity: 1;
    color: ${token('foreground/primary')};
  }

  ${p =>
    p.isDragging === true &&
    css`
      position: relative;
      box-shadow: 0px 2px 4px rgba(0, 0, 0, 0.08);
      border-color: ${token('border/strong')};
      overflow: hidden;
      opacity: 0;

      &::before {
        position: absolute;
        inset: 0;
        content: '';
        opacity: 1;
      }
    `}

  ${p =>
    p.lineOnHover === true &&
    css`
      &:hover {
        border-color: ${token('border/default')};
      }
    `}

    ${p =>
    p.highlighted === true &&
    css`
      animation: ${pulse} ${highlightAnimationDuration}s ease-out 1 forwards;

      &:hover {
        border-color: ${palette.blue.bright};
      }
    `}
`

type TitleProps = {
  $gridArea?: string
}
export const Title = styled(Text).attrs({
  size: 'small',
  bold: true,
})<TitleProps>`
  ${p =>
    p.$gridArea !== undefined &&
    css`
      grid-area: ${p.$gridArea};
    `}
`

export const Subline = styled(Text).attrs({ size: 'small', bold: false, color: 'foreground/muted' })``

const StepCellSpan = styled(TwoLineCell)<{ spanWholeRow?: boolean }>`
  ${p =>
    p.spanWholeRow === true &&
    css`
      grid-column: 2 / 4;
    `}
`

export const LiveSessionAssignmentIcon = styled(Icon).attrs({
  iconId: 'elbow--arrow',
  color: 'foreground/muted',
})``

export const StepCell: React.FC<{
  text: string
  children?: React.ReactNode | React.ReactNode[]
  empty?: boolean
  spanWholeRow?: boolean
}> = ({ text, empty, children, spanWholeRow }) => (
  <StepCellSpan direction='column' gap='2' spanWholeRow={spanWholeRow}>
    <Text size='small' color={empty === true ? 'foreground/muted' : 'foreground/primary'}>
      {text}
    </Text>
    {children}
  </StepCellSpan>
)

export const Empty = styled(View).attrs({ 'aria-hidden': true })<{ $gridArea?: string; size?: string }>`
  opacity: 0;
  pointer-events: none;

  ${p =>
    p.size !== undefined &&
    css`
      width: ${p.size}px;
    `}

  ${p =>
    p.$gridArea !== undefined &&
    css`
      grid-area: ${p.$gridArea};
    `}
`

export const AddStep = styled(StepBase).attrs({
  cursor: 'pointer',
  grow: true,
  background: 'surface/soft',
  ...stepSpacing,
})`
  & {
    /* Increase specificity */
    min-height: auto;
  }

  padding-top: 0.5rem;
  padding-bottom: 0.5rem;
  align-items: center;
  min-height: 4.625rem;
  ${defaultEasing};

  & * {
    ${defaultEasing};
    color: ${token('foreground/muted')};
  }

  &:hover {
    background-color: ${token('border/default')};

    * {
      color: ${token('foreground/muted')};
    }
  }
`

export const AddStepFooter: React.FC = () => {
  const { t } = useTranslation()
  const { dispatch } = useOutlineReducer()
  const {
    setPanels: {
      editOutline: { on: editOutlineOn },
      addContent: { on: addStepOn },
      addEmailNotification: { on: addEmailOn },
    },
  } = useOutlineEditPanels()

  const onContentClick = useCallback(() => {
    editOutlineOn()
    addStepOn()
  }, [editOutlineOn, addStepOn])

  const onEmailClick = useCallback(() => {
    editOutlineOn()
    addEmailOn()
  }, [addEmailOn, editOutlineOn])

  const onSectionClick = useCallback(() => {
    editOutlineOn()
    dispatch({ type: 'ADD_SECTION' })
  }, [editOutlineOn, dispatch])

  const sectionsEnabled = getFlag('programs/sections')

  const items: MenuItem[] = useMemo(
    () => [
      {
        type: 'label',
        id: 'content',
        icon: 'book',
        label: t('manage.programs.add-content'),
      },
      {
        type: 'label',
        id: 'email',
        icon: 'email',
        label: t('manage.programs.add-email'),
      },
      {
        id: 'separator',
        type: 'separator',
        hidden: !sectionsEnabled,
      },
      {
        type: 'label',
        id: 'section',
        icon: 'section',
        label: t('sticky-notes.add-board'),
        hidden: !sectionsEnabled,
      },
    ],
    [t, sectionsEnabled]
  )

  return (
    <View marginTop='24' marginLeft='12'>
      <MenuButton
        variant='secondary'
        menuItems={items}
        onSelect={item => {
          switch (item.id) {
            case 'content':
              return onContentClick()
            case 'email':
              return onEmailClick()
            case 'section':
              return onSectionClick()
          }
        }}
      >
        {t('manage.program.program-details.outline.add-step-button')}
      </MenuButton>
    </View>
  )
}

/**
 * The ProgramStepsRenderer renders the outer layout and maps the StepGrid to each step
 * - Numbers
 * - Lines
 * - Steps
 * - Legend
 *
 * The step grid, the step and the legend are renderers in their specific implementations (edit, view)
 */
export const ProgramStepsRenderer: ProgramStepRenderer = ({ Renderer, canEdit, programId }) => {
  const { outline } = useOutlineReducer()
  const sectionsFeatureEnabled = getFlag('programs/sections')
  const clusters = clusterSections(outline)

  const Wrapper = Renderer.Wrapper ?? React.Fragment

  return (
    <OutlineWrapper>
      <Renderer.StepGrid $isHeader={true}>
        <Renderer.Header />
      </Renderer.StepGrid>

      {Renderer.OptionalTop !== undefined && <Renderer.OptionalTop />}

      <Wrapper>
        <AnimatePresence initial={false}>
          {clusters.map((cluster, clusterIndex) => {
            const sectionIndex = cluster.sectionIndex
            const section = isDefined(sectionIndex) ? outline.sections[sectionIndex] : null
            const clusterKey = isDefined(section)
              ? 'draftId' in section
                ? `draft-${section.draftId}`
                : section.id
              : `cluster-${clusterIndex}`

            return (
              <ConditionalWrapper
                key={clusterKey}
                condition={sectionsFeatureEnabled && isDefined(sectionIndex)}
                renderWrapper={children => (
                  <SectionRenderer sectionIndex={sectionIndex ?? null}>{children}</SectionRenderer>
                )}
                renderWrapperElse={children => (
                  <View direction='column' gap='12' style={{ marginInline: 16 }}>
                    {children}
                  </View>
                )}
              >
                {cluster.members.map(step => {
                  const previousStep = outline.steps[step.index - 1]
                  const nextStep = outline.steps[step.index + 1]
                  const isFirstStep = sectionsFeatureEnabled
                    ? isDefined(previousStep)
                      ? previousStep.sectionIndex !== step.sectionIndex
                      : true
                    : step.index === 0
                  const isLastStep = sectionsFeatureEnabled
                    ? isDefined(nextStep)
                      ? nextStep.sectionIndex !== step.sectionIndex
                      : true
                    : step.index === outline.steps.length - 1

                  return (
                    <Renderer.Step
                      key={getStepId(step)}
                      step={step}
                      canEdit={canEdit}
                      stepNumberPill={
                        <StepNumberContainer>
                          <StepNumber index={step.index} outline={outline} />
                        </StepNumberContainer>
                      }
                      isFirstStep={isFirstStep}
                      isLastStep={isLastStep}
                      previousStep={previousStep}
                      StepGrid={Renderer.StepGrid}
                      programId={programId}
                    />
                  )
                })}
              </ConditionalWrapper>
            )
          })}
        </AnimatePresence>
      </Wrapper>
      {Renderer.OptionalFooter !== undefined && <Renderer.OptionalFooter />}
    </OutlineWrapper>
  )
}
