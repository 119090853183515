import { Logger } from 'sierra-client/core/logging/logger'

type Identifier = `inline-auto-complete_${string}`

export const autoCompleteAccepted = Logger.trackLogger<{ suggestionId: string; source: 'click' | 'tab' }>(
  'inline-auto-complete_accepted' satisfies Identifier
)
export const autoCompleteRejected = Logger.trackLogger<{ suggestionId: string }>(
  'inline-auto-complete_rejected' satisfies Identifier
)
export const autoCompleteSuggested = Logger.trackLogger<{ suggestionId: string }>(
  'inline-auto-complete_suggested' satisfies Identifier
)
