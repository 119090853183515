import _ from 'lodash'
import { useRef, useState } from 'react'
import { useInterval } from 'sierra-client/hooks/use-interval'
import { useStableFunction } from 'sierra-client/hooks/use-stable-function'
import { useOnChanged } from 'sierra-ui/utils'

/**
 * This hook returns a value that is periodically recalculated based on the
 * input value. The value is recalculated every `delay` milliseconds.
 *
 * This is preferable to storing the current time in state since that will cause
 * re-renders every time the time changes.
 */
export const usePeriodicRefresh = <T, U>(inputValue: T, callback: (x: T) => U, delay: number): U => {
  const isInitialRenderRef = useRef(true)
  const [mappedValue, setMappedValue] = useState(() => callback(inputValue))

  const recalculateValue = useStableFunction((): void => {
    const newValue = callback(inputValue)
    if (!_.isEqual(newValue, mappedValue)) {
      setMappedValue(newValue)
    }
  })

  // Recalculate regularly
  useInterval(recalculateValue, delay)

  // Recalculate when input changes
  useOnChanged(() => {
    // Note: useOnChanged calls its callback once on mount. We don't need to recalculate the value at
    // that point since we already do it when calling useState. For that reason we'll skip
    // the first call here.
    if (!isInitialRenderRef.current) {
      recalculateValue()
    }

    isInitialRenderRef.current = false
  }, inputValue)

  return mappedValue
}
