import { useEffect, useRef, useState } from 'react'
import { graphql } from 'sierra-client/api/graphql/gql'
import { useGraphQuery } from 'sierra-client/api/hooks/use-graphql-query'
import { useInvalidateSubtitleQuery } from 'sierra-client/components/blocks/video/use-add-subtitle-tracks'
import { useTranslation } from 'sierra-client/hooks/use-translation'
import { LoaderAnimation, Text, View } from 'sierra-ui/primitives'
import { ButtonShapeStyles } from 'sierra-ui/primitives/button/button'
import styled from 'styled-components'

const Indicator = styled(View)<{ $hide: boolean }>`
  ${ButtonShapeStyles};
  background: linear-gradient(0deg, rgba(90, 90, 90, 0.4) 0%, rgba(90, 90, 90, 0.4) 100%),
    rgba(90, 90, 90, 0.05);
  border-radius: 12px;
  background-blend-mode: multiply;
  backdrop-filter: blur(34px);
  border: none;
  transition:
    background 0.1s cubic-bezier(0.25, 0.1, 0.25, 1),
    opacity 0.2s cubic-bezier(0.25, 0.1, 0.25, 1);
  color: white;
  font-variant-numeric: tabular-nums;

  position: absolute;
  top: 8px;
  left: 8px;
  pointer-events: none;

  opacity: ${p => (p.$hide ? 0 : 1)};
`

const getVideoTranscriptionStatus = graphql(`
  query GetVideoTranscriptionStatus($videoUrl: String!) {
    video(videoUrl: $videoUrl) {
      transcriptionState
    }
  }
`)

export const VideoIsTranscribingIndicator = ({ videoUrl }: { videoUrl: string }): JSX.Element | null => {
  const [hide, setHide] = useState(true)
  const { t } = useTranslation()

  const invalidateSubtitleQuery = useInvalidateSubtitleQuery(videoUrl)

  const transcriptionStateQuery = useGraphQuery(
    {
      document: getVideoTranscriptionStatus,
      queryOptions: {
        refetchInterval(query): number | false {
          if (query.state.data?.video.transcriptionState === 'PROCESSING') return 30_000

          // There is a delay when we start transcribing a new video,
          // so if the transcrition hasn't started yet try again in a while.
          if (query.state.data?.video.transcriptionState === 'NONE' && query.state.dataUpdateCount === 1)
            return 15_000

          return false
        },
      },
    },
    {
      videoUrl,
    }
  )
  const state = transcriptionStateQuery.data?.video.transcriptionState
  const prevState = useRef(state)

  useEffect(() => {
    if (prevState.current === 'PROCESSING' && state === 'DONE') {
      void invalidateSubtitleQuery()
    }

    prevState.current = state
    if (state === 'PROCESSING') {
      setHide(false)
    } else {
      const timeout = setTimeout(() => {
        setHide(true)
      }, 5000)
      return () => clearTimeout(timeout)
    }
  }, [state, invalidateSubtitleQuery])

  return (
    <Indicator gap='6' $hide={hide}>
      {state === 'PROCESSING' && (
        <>
          <LoaderAnimation color='white' size={16} />
          <Text color='white' size='small'>
            {t('author.video.transcription-status--processing')}
          </Text>
        </>
      )}
      {state === 'DONE' && (
        <Text color='white' size='small'>
          {t('author.video.transcription-status--done')}
        </Text>
      )}
    </Indicator>
  )
}
