import { createFileRoute } from '@tanstack/react-router'
import { requireLoggedIn } from 'sierra-client/core/require-logged-in'
import { CoursePage } from 'sierra-client/views/learner/course/course-page'
import { CourseId } from 'sierra-domain/api/nano-id'
import { ProgramId } from 'sierra-domain/api/uuid'
import { z } from 'zod'

export const Route = createFileRoute('/program/$programId/course/$courseId/')({
  component: requireLoggedIn(CoursePage, { allowScorm: true }) as React.FC,
  params: {
    parse: z.object({ programId: ProgramId, courseId: CourseId }).parse,
    stringify: p => p,
  },
})
