import React from 'react'
import { useTranslation } from 'sierra-client/hooks/use-translation'
import { assertNever } from 'sierra-domain/utils'
import { MenuButton } from 'sierra-ui/components'
import { View } from 'sierra-ui/primitives'

type EnrollButtonProps = {
  canCreateEnrollmentRule: boolean
  onCreateEnrollmentRule: () => void
  onEnrollOnce: () => void
  canEnrollUsers: boolean
}

export const EnrollButton: React.FC<EnrollButtonProps> = ({
  canCreateEnrollmentRule,
  onCreateEnrollmentRule,
  onEnrollOnce,
  canEnrollUsers,
}) => {
  const { t } = useTranslation()

  return (
    <View justifyContent='flex-end' alignItems='center'>
      <MenuButton
        disabled={!canEnrollUsers}
        menuItems={[
          {
            id: 'enroll-once',
            type: 'label',
            label: t('manage.programs.enrollment-rules.enroll-once'),
            description: t('manage.programs.enroll-once.description'),
          },
          {
            id: 'enrollment-rule',
            type: 'label',
            label: t('manage.programs.enrollment-rules.create-enrollment-rule'),
            description: t('manage.programs.enrolment-rule.description'),
            hidden: !canCreateEnrollmentRule,
          },
        ]}
        onSelect={item => {
          switch (item.id) {
            case 'enroll-once':
              onEnrollOnce()
              return
            case 'enrollment-rule':
              onCreateEnrollmentRule()
              return
            default:
              assertNever(item.id)
          }
        }}
      >
        {t('manage.programs.enrollment-rules.enroll')}
      </MenuButton>
    </View>
  )
}
