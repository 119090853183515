import { useCallback, useEffect, useState } from 'react'
import { usePost } from 'sierra-client/hooks/use-post'
import { GroupRow, GroupType } from 'sierra-domain/api/manage'
import { UserId } from 'sierra-domain/api/uuid'
import {
  XRealtimeAdminAssignmentsSetUserGroupMemberships,
  XRealtimeAdminGroupsListGroups,
} from 'sierra-domain/routes'

type UseGroupsData = {
  isLoading: boolean
  groupsData: GroupRow[]
  fetchGroups: () => Promise<void>
  fetchCsvData: () => Array<Record<string, unknown>>
  assignUsers: (groupsIds: string[], userIds: UserId[]) => Promise<void>
}

export const mapGroupToCsv = (group: GroupRow): Record<string, unknown> => {
  return {
    name: group.groupInfo.groupName,
    admin: group.groupInfo.adminName,
    learnersCount: group.learnerIds.length,
    coursesCount: group.courseIds.length,
    pathsCount: group.pathIds.length,
  }
}

export const useGroups: (type: GroupType) => UseGroupsData = type => {
  const { postWithUserErrorException } = usePost()
  const [groupsData, setGroupsData] = useState<GroupRow[]>([])
  const [isLoading, setIsLoading] = useState(false)

  const fetchGroups = useCallback(async (): Promise<void> => {
    setIsLoading(true)
    const response = await postWithUserErrorException(XRealtimeAdminGroupsListGroups, {
      type,
      groupIds: undefined,
    })
    setGroupsData(response.data)
    setIsLoading(false)
  }, [type, postWithUserErrorException])

  const assignUsers = useCallback<UseGroupsData['assignUsers']>(
    async (groupIds, userIds) => {
      await postWithUserErrorException(XRealtimeAdminAssignmentsSetUserGroupMemberships, {
        groupIds,
        userIds,
      })
    },
    [postWithUserErrorException]
  )

  useEffect(() => {
    void fetchGroups()
  }, [fetchGroups])

  const fetchCsvData = useCallback(() => {
    return groupsData.map(mapGroupToCsv)
  }, [groupsData])

  return {
    isLoading,
    groupsData,
    fetchGroups,
    fetchCsvData,
    assignUsers,
  }
}
