import { produce } from 'immer'
import React from 'react'
import { useTranslation } from 'sierra-client/hooks/use-translation'
import { useSelector } from 'sierra-client/state/hooks'
import { selectUser } from 'sierra-client/state/user/user-selector'
import { DropdownWidth } from 'sierra-client/views/manage/users/components/user-settings-panel/general-tab/common'
import { useGeneralSettingsDraft } from 'sierra-client/views/manage/users/components/user-settings-panel/general-tab/use-general-settings-draft'
import { getAccessLevelOptions } from 'sierra-client/views/manage/users/user-utils'
import { isAccessLevelBelowOrEqualTo, isOwner, UserAccessLevel } from 'sierra-domain/access-level'
import { MenuItem } from 'sierra-ui/components'
import { SingleSelectDropdown } from 'sierra-ui/primitives/menu-dropdown'

export const AccessLevelDropdown: React.FC = () => {
  const { t } = useTranslation()
  const me = useSelector(selectUser)
  const menuItems: MenuItem[] = getAccessLevelOptions(t, me?.accessLevel)
  const { generalSettingsDraft, initialGeneralSettings, setGeneralSettingsDraft, readOnlyMetadata } =
    useGeneralSettingsDraft()
  const { accessLevel: initialAccessLevel, accessRole: initialAccessRole } = initialGeneralSettings

  const disabled =
    readOnlyMetadata ||
    me === undefined ||
    !isAccessLevelBelowOrEqualTo(initialAccessLevel, me.accessLevel) ||
    isOwner(initialAccessRole, initialAccessLevel) ||
    initialAccessLevel === 'guest' // You shouldn't be able to upgrade guest users since they have fake emails

  const handleSelect = (item: MenuItem): void =>
    setGeneralSettingsDraft(
      produce(generalSettingsDraft, edit => {
        edit.accessLevel = item.id as UserAccessLevel
      })
    )

  return (
    <DropdownWidth>
      <SingleSelectDropdown
        disabled={disabled}
        selectedItem={menuItems.find(i => i.id === generalSettingsDraft.accessLevel)}
        menuItems={menuItems}
        onSelect={handleSelect}
      />
    </DropdownWidth>
  )
}
