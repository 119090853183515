import { motion } from 'framer-motion'
import _ from 'lodash'
import React, { useMemo, useState } from 'react'
import { useBrowseTeamspaces } from 'sierra-client/api/hooks/use-teamspace'
import { titleVariants } from 'sierra-client/features/collapsable-sidebar'
import { ContentButton, Teamspaces } from 'sierra-client/features/global-sidebar/components/tabs-content'
import { useSidebarTeamspaces } from 'sierra-client/features/global-sidebar/teamspace-state'
import { TeamspaceModal } from 'sierra-client/features/teamspace'
import { useHasOrganizationPermission } from 'sierra-client/hooks/use-permissions'
import { useTranslation } from 'sierra-client/hooks/use-translation'
import { Icon } from 'sierra-ui/components'
import { LoadingSpinner, Text, View } from 'sierra-ui/primitives'
import { token } from 'sierra-ui/theming'
import styled from 'styled-components'

const TeamspaceButton = styled(ContentButton)`
  margin-bottom: 24px;

  color: ${token('foreground/muted')};

  &:hover {
    color: ${token('foreground/primary')};
  }
`

const NonSelectableText = styled(Text)`
  user-select: none;
  white-space: nowrap;
`

const BrowseTeamspaceButton = styled(View).attrs({
  justifyContent: 'center',
})`
  flex: 0 0 auto;
  color: currentColor;
  background: ${p => p.theme.home.backgroundColor};
  border-radius: 6px;
  border: 1px solid ${token('form/border/1')};
  width: 20px;
  height: 20px;
  transition: all 50ms cubic-bezier(0.25, 0.1, 0.25, 1);
`

export const GlobalSidebarTabs: React.FC = () => {
  const { t } = useTranslation()
  const [teamspaceModalOpen, setTeamspaceModalOpen] = useState(false)
  const teamspacesQuery = useSidebarTeamspaces()
  const userHasCreateTeamspacePermission = useHasOrganizationPermission('CREATE_TEAMSPACE')
  const browseTeamspacesQuery = useBrowseTeamspaces()
  const hasBrowsableTeamspaces =
    browseTeamspacesQuery.isSuccess &&
    (browseTeamspacesQuery.data.available.length > 0 || browseTeamspacesQuery.data.memberOf.length > 0)

  const showBrowseTeamspaceButton = userHasCreateTeamspacePermission || hasBrowsableTeamspaces

  const teamspaces = useMemo(
    () => _.sortBy(teamspacesQuery.data ?? [], it => it.displayName),
    [teamspacesQuery.data]
  )

  return (
    <View direction='column' gap='none' id='product-tour-sidebar-teamspaces'>
      <Teamspaces teamspaces={teamspaces} />
      {teamspacesQuery.data === undefined && <LoadingSpinner />}

      <View direction='column'>
        {showBrowseTeamspaceButton && (
          <TeamspaceModal
            trigger={
              <TeamspaceButton
                onClick={() => setTeamspaceModalOpen(true)}
                id='tour-global-sidebar-browse'
                data-intercom-target='global-sidebar-browse'
                aria-label={t('teamspace.menu')}
              >
                <View marginRight='8'>
                  <BrowseTeamspaceButton>
                    <Icon iconId='add' color='currentColor' />
                  </BrowseTeamspaceButton>
                </View>
                <motion.div variants={titleVariants}>
                  <NonSelectableText bold color='currentColor'>
                    {teamspaces.length > 0
                      ? t('teamspace.browse-teamspaces')
                      : t('teamspace.browse-teamspaces-full')}
                  </NonSelectableText>
                </motion.div>
              </TeamspaceButton>
            }
            defaultPane='browse'
            open={teamspaceModalOpen}
            onClose={() => {
              setTeamspaceModalOpen(false)
            }}
          />
        )}
      </View>
    </View>
  )
}
