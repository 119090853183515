import { createFileRoute } from '@tanstack/react-router'
import { requireLoggedIn } from 'sierra-client/core/require-logged-in'
import { store } from 'sierra-client/state/store'
import { fetchCourseDataById, fetchCourseStatusById } from 'sierra-client/state/v2/courses-actions'
import { fetchTagsData } from 'sierra-client/state/v2/tags-actions'
import { SelfPacedPage } from 'sierra-client/views/self-paced/self-paced-page'
import { SelfPacedContentId } from 'sierra-domain/api/nano-id'
import { FileId } from 'sierra-domain/flexible-content/identifiers'
import { z } from 'zod'

export const Route = createFileRoute('/s/$flexibleContentId/$fileId')({
  component: requireLoggedIn(SelfPacedPage, { allowScorm: true }) as React.FC,
  params: {
    parse: z.object({
      flexibleContentId: SelfPacedContentId,
      fileId: FileId.or(z.literal('next')),
    }).parse,
    stringify: p => p,
  },
  loader: ({ context, params }) => {
    if (context.userId !== undefined) {
      void store.dispatch(fetchTagsData())
      void store.dispatch(fetchCourseDataById({ courseId: params.flexibleContentId }))
      void store.dispatch(fetchCourseStatusById({ courseId: params.flexibleContentId }))
    }
  },
})
