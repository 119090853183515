import { FC } from 'react'
import { RouterLink } from 'sierra-client/components/common/link'
import { getFlag } from 'sierra-client/config/global-config'
import { ActivityCenter } from 'sierra-client/features/activity-center'
import { useTranslation } from 'sierra-client/hooks/use-translation'
import { TranslationKey } from 'sierra-client/hooks/use-translation/types'
import { isDefined } from 'sierra-domain/utils'
import { Text, View } from 'sierra-ui/primitives'
import { token } from 'sierra-ui/theming'
import styled from 'styled-components'

const Container = styled.div<{ $withoutMargin: boolean }>`
  display: flex;
  justify-content: space-between;
  align-items: flex-start;
  padding-top: 0.5rem;
  margin-bottom: 1rem;
  background: ${token('surface/default')};
`
const HeaderGroup = styled(View).attrs({
  direction: 'column',
  gap: 'none',
})`
  max-width: 400px;
`

export const PageHeaderRightSection: FC<{ helpLink: string }> = ({ helpLink }) => {
  const { t } = useTranslation()
  const activityCenterEnabled = getFlag('activity-center')

  return (
    <View gap='16'>
      <View gap='6' marginLeft='auto'>
        <Text size='small' bold={true} color={'foreground/secondary'}>
          {t('home.help.title')}
        </Text>
        <Text size='small' color={'foreground/secondary'}>
          <RouterLink target='_blank' rel='noopener noreferrer' href={helpLink}>
            {t('home.help.cta')}
          </RouterLink>
        </Text>
      </View>

      {activityCenterEnabled && (
        <View>
          <ActivityCenter />
        </View>
      )}
    </View>
  )
}

type PageHeaderProps = {
  title: TranslationKey
  subtitle?: TranslationKey
  withoutMargin?: boolean
  helpLink?: string
}

export const PageHeader: FC<PageHeaderProps> = ({
  title,
  subtitle,
  withoutMargin = false,
  helpLink = 'https://help.sana.ai/en/',
}) => {
  const { t } = useTranslation()

  return (
    <Container $withoutMargin={withoutMargin}>
      <HeaderGroup>
        <Text bold size='large' as='h2'>
          {t(title)}
        </Text>
        {isDefined(subtitle) && (
          <Text color='foreground/secondary' size='large'>
            {t(subtitle)}
          </Text>
        )}
      </HeaderGroup>

      <PageHeaderRightSection helpLink={helpLink} />
    </Container>
  )
}
