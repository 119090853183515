import React from 'react'
import { useStableFunction } from 'sierra-client/hooks/use-stable-function'
import { useTranslation } from 'sierra-client/hooks/use-translation'
import { Trans } from 'sierra-client/hooks/use-translation/trans'
import { useGetFileTitle } from 'sierra-client/state/flexible-content/file-title'
import { useCreatePageContext } from 'sierra-client/views/flexible-content/create-page-context'
import { useCreateToast } from 'sierra-client/views/flexible-content/create-page-toast'
import { applyReversible } from 'sierra-domain/editor/operations'
import { FileId } from 'sierra-domain/flexible-content/identifiers'
import { File, Folder } from 'sierra-domain/flexible-content/types'
import { assertNever } from 'sierra-domain/utils'
import { Tooltip, useIsTextTruncated } from 'sierra-ui/components'
import { fonts } from 'sierra-ui/theming/fonts'
import styled from 'styled-components'

type ReversibleAction =
  | {
      type: 'remove-files'
      files: [File, ...File[]]
    }
  | {
      type: 'remove-folder'
      folder: Folder
    }
  | {
      type: 'remove-video'
      fileId: FileId
    }

type UseReversibleAction = (action: ReversibleAction) => void

const TruncatedTitle = styled.span`
  font-weight: ${fonts.weight.bold};
  overflow: hidden;
  text-overflow: ellipsis;
`

const TruncatedTitleWithTooltip: React.FC<{ children?: React.ReactNode }> = ({ children }) => {
  const { isTruncatedWidth: isTruncated, setTextRef } = useIsTextTruncated()

  const truncatedText = <TruncatedTitle ref={setTextRef}>{children}</TruncatedTitle>

  return !isTruncated ? truncatedText : <Tooltip title={children}>{truncatedText}</Tooltip>
}

export const useReversibleEditorAction = (): UseReversibleAction => {
  const { t } = useTranslation()

  const { operationState } = useCreatePageContext()
  const { addToast } = useCreateToast()
  const getFileTitle = useGetFileTitle(operationState.yDoc)

  return useStableFunction((action: ReversibleAction) => {
    switch (action.type) {
      case 'remove-files': {
        const [firstFile, ...otherFiles] = action.files
        const fileIds = [firstFile.id, ...otherFiles.map(f => f.id)] satisfies [FileId, ...FileId[]]

        // Make sure to read the file title before deleting the file.
        const firstFileTitle = getFileTitle(firstFile)

        const { revert } = applyReversible(operationState, { type: 'remove-nodes', nodeIds: fileIds })

        addToast({
          title:
            fileIds.length > 1 ? (
              t('create.toast.nodes-deleted', { number: fileIds.length })
            ) : (
              <Trans
                i18nKey='create.toast.node-deleted'
                values={{ title: firstFileTitle }}
                components={{
                  Truncate: <TruncatedTitleWithTooltip />,
                }}
              />
            ),
          action: {
            text: t('dictionary.undo'),
            altText: t('dictionary.undo'),
            onClick: () => {
              revert()
            },
          },
        })
        break
      }
      case 'remove-folder': {
        const folderId = action.folder.id
        const folderTitle = action.folder.title

        const { revert } = applyReversible(operationState, { type: 'remove-nodes', nodeIds: [folderId] })

        addToast({
          title: (
            <Trans
              i18nKey='create.toast.node-deleted'
              values={{ title: folderTitle }}
              components={{
                Truncate: <TruncatedTitleWithTooltip />,
              }}
            />
          ),
          action: {
            text: t('dictionary.undo'),
            altText: t('dictionary.undo'),
            onClick: () => {
              revert()
            },
          },
        })
        break
      }
      case 'remove-video': {
        const { revert } = applyReversible(operationState, {
          type: 'update-files',
          fileIds: [action.fileId],
          update: file => {
            if (file.data.type !== 'video') throw Error(`File ${file.id} is not a video`)
            file.data.video.url = undefined
          },
        })

        addToast({
          title: t('create.toast.video-deleted'),
          action: {
            text: t('dictionary.undo'),
            altText: t('dictionary.undo'),
            onClick: () => {
              revert()
            },
          },
        })
        break
      }

      default:
        assertNever(action)
    }
  })
}
