import { invalidateGetDashboard } from 'sierra-client/features/insights/api-hooks/use-get-dashboard'
import { invalidateListDashboards } from 'sierra-client/features/insights/api-hooks/use-list-dashboards'
import { TypedMutation, useTypedMutation } from 'sierra-client/state/api'
import { XAnalyticsDashboardsUpsert } from 'sierra-domain/routes'

export const useUpsertDashboard = (): TypedMutation<typeof XAnalyticsDashboardsUpsert> => {
  return useTypedMutation(XAnalyticsDashboardsUpsert, {
    onSuccess: async (data, variables) => {
      await invalidateListDashboards()

      if (variables.dashboard.id !== undefined) {
        // we are modifying an existing dashboard, therefore we need to invalidate dashboard data
        await invalidateGetDashboard({ dashboardId: variables.dashboard.id })
      }
    },
  })
}
