import { orderBy } from 'lodash'
import { useMemo } from 'react'
import {
  UseGridParticipants,
  getLobbyParticipants,
  hasEqualAgoraUID,
  useGenericGridParticipants,
} from 'sierra-client/components/liveV2/participant-hooks/participant-grid-hooks'
import { useStableOrder } from 'sierra-client/hooks/use-stable-order'
import { CreateContentId } from 'sierra-domain/api/nano-id'

export const useLobbyGridParticipants = ({
  participantLimit = 17,
  flexibleContentId,
}: {
  participantLimit: number
  flexibleContentId: CreateContentId
}): UseGridParticipants => {
  const { participantCount, allParticipants } = useGenericGridParticipants(flexibleContentId)
  const numberOfActiveUsers = participantCount <= participantLimit ? participantLimit : participantLimit - 1

  const { activeParticipants, passiveParticipants } = useMemo(
    () =>
      getLobbyParticipants({
        numberOfActiveUsers,
        participants: allParticipants,
      }),
    [numberOfActiveUsers, allParticipants]
  )

  const stableParticipants = useStableOrder(activeParticipants, hasEqualAgoraUID)

  // After the stable sorting we want to make sure that the current user is always first.
  const currentUserFirst = orderBy(stableParticipants, it => (it.isCurrentClient ? 1 : 0), 'desc')

  return { participants: currentUserFirst, passiveParticipants }
}
