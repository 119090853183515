import { LearnerContent } from 'sierra-domain/api/learn'
import { assertNever } from 'sierra-domain/utils'
import { Icon, IconProps } from 'sierra-ui/components'
import styled, { css } from 'styled-components'

const StyledIcon = styled(Icon)<{ faded?: boolean }>`
  ${p =>
    p.faded === true &&
    css`
      opacity: 0.4;
    `}
`

const getIconId = (type: LearnerContent['type']): IconProps['iconId'] => {
  switch (type) {
    case 'native:self-paced':
    case 'native:course-group':
      return 'bookmark--filled'
    case 'linkedin':
    case 'scorm':
    case 'link':
    case 'scorm:course-group':
      return 'link'
    case 'native:live':
    case 'live-session':
      return 'play--circle--filled'
    case 'path':
      return 'path'
    case 'homework':
      return 'task'
    case 'program':
      return 'media--library'
    case 'native:event-group':
      return 'calendar'
    default:
      assertNever(type)
  }
}
export const RecommendationContentIcon: React.FC<{
  type: LearnerContent['type']
  color?: IconProps['color']
  faded?: boolean
}> = ({ type, color, faded }) => (
  <StyledIcon iconId={getIconId(type)} size='size-16' color={color} faded={faded} />
)
