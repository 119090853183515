import pino from 'pino'
import { errorLogger } from 'sierra-client/error/error-logger'
import { Meta, UnknownError } from 'sierra-client/error/log-meta'
import { handlePinoSend } from 'sierra-client/error/new-relic-logger'

const baseLogger = pino({
  enabled: process.env.NODE_ENV !== 'test',
  level: 'debug',
  browser: {
    transmit: {
      level: 'trace',
      send: handlePinoSend,
    },
  },
})

class Logger {
  constructor(
    private logger: pino.Logger,
    private attributes: Record<string, string | number | undefined> = {}
  ) {}

  /**
   * Trace logs will be sent to NR but won't be visible in the console window
   */
  trace = (msg: string, meta?: Meta): void => {
    this.logger.trace(msg, meta)
  }

  debug = (msg: string, meta?: Meta): void => {
    this.logger.debug(msg, meta)
  }

  info = (msg: string, meta?: Meta): void => {
    this.logger.info(msg, meta)
  }

  warn = (msg: string, meta?: Meta): void => {
    this.logger.warn(msg, meta)
    errorLogger.warn(msg, { ...this.attributes, ...meta })
  }

  error = (msg: string, meta?: Meta): void => {
    this.logger.error(msg, meta)
    errorLogger.error(msg, { ...this.attributes, ...meta })
  }

  captureInfo = (e: Error | unknown, meta: Meta = {}): void => {
    const error = e instanceof Error ? e : new UnknownError(e)
    this.logger.info(error.message, { ...meta, error })
  }

  captureWarning = (e: Error | unknown, meta: Meta = {}): string => {
    const error = e instanceof Error ? e : new UnknownError(e)
    this.logger.warn(error.message, { ...meta, error })
    return errorLogger.captureWarning(error, { ...this.attributes, ...meta })
  }

  captureError = (e: Error | unknown, meta: Meta = {}): string => {
    const error = e instanceof Error ? e : new UnknownError(e)
    this.logger.error(error.message, { ...meta, error })
    return errorLogger.captureError(error, { ...this.attributes, ...meta })
  }

  getLogger = (attributes: Record<string, string | number>): Logger => {
    return new Logger(this.logger.child(attributes), attributes)
  }
}

export const logger = new Logger(baseLogger)
