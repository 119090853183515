import { createFileRoute } from '@tanstack/react-router'
import React, { useMemo, useState } from 'react'
import QRCode from 'react-qr-code'
import { graphql } from 'sierra-client/api/graphql/gql'
import { SetCalendarAttendanceInput } from 'sierra-client/api/graphql/gql/graphql'
import { convertGQLAvatar } from 'sierra-client/api/graphql/util/convert-gql-avatar'
import { convertGQLImage } from 'sierra-client/api/graphql/util/convert-gql-image'
import { graphQuery, useGraphQuery } from 'sierra-client/api/hooks/use-graphql-query'
import { SanaImage } from 'sierra-client/components/common/image'
import { RouterLink } from 'sierra-client/components/common/link'
import { ActionModal } from 'sierra-client/components/common/modals/action-modal'
import { AssignModal } from 'sierra-client/components/common/modals/multi-assign-modal'
import { AssignModalSelection } from 'sierra-client/components/common/modals/multi-assign-modal/types'
import { useNotif } from 'sierra-client/components/common/notifications'
import {
  useCalendarEventPermissions,
  useHasCalendarEventPermissions,
} from 'sierra-client/hooks/use-permissions'
import { useResolveAsset } from 'sierra-client/hooks/use-resolve-asset'
import { useTranslation } from 'sierra-client/hooks/use-translation'
import { PageIdentifier } from 'sierra-client/layout/sana-page'
import { LoadingPermissionsWrapper } from 'sierra-client/routes/access-denied.route'
import {
  ManageDetailContent,
  ManageDetailGrid,
  ManageDetailSidebar,
} from 'sierra-client/views/manage/components/common'
import { DetailsHeader } from 'sierra-client/views/manage/components/details-header'
import { ColumnLayout } from 'sierra-client/views/manage/components/layout/column-layout'
import { EditCalendarEventPanel } from 'sierra-client/views/manage/event-groups/edit-calendar-event-panel'
import { useEventGroupAssignmentData } from 'sierra-client/views/manage/event-groups/event-group-assignment-table/use-event-group-assignment-table-data'
import {
  formatGqlEventSchedule,
  getGqlEventScheduleStart,
  gqlEventLocationString,
  gqlEventLocationToEventLocation,
  gqlEventScheduleToEventSchedule,
} from 'sierra-client/views/manage/event-groups/event-utils'
import { ManageAssignedLearnersTable } from 'sierra-client/views/manage/event-groups/manage-assigned-learners-table'
import { MarkUserAttendanceModal } from 'sierra-client/views/manage/event-groups/mark-user-attendance-modal'
import { useDeleteCalendarEventAttendance } from 'sierra-client/views/manage/event-groups/use-delete-calendar-event-attendance'
import { useSetAttendanceStatus } from 'sierra-client/views/manage/event-groups/use-set-attendance-status'
import { ManagePageWrapper } from 'sierra-client/views/manage/utils/manage-page-wrapper'
import { CalendarEventId, CourseId, EventGroupId } from 'sierra-domain/api/nano-id'
import { UserId } from 'sierra-domain/api/uuid'
import { AssetContext } from 'sierra-domain/asset-context'
import { isDefined, isNonNullable } from 'sierra-domain/utils'
import { Icon } from 'sierra-ui/components'
import { FreeTextEditor } from 'sierra-ui/missions/workflows/free-text-editor'
import { Button, Heading, Spacer, Text, View } from 'sierra-ui/primitives'
import { token } from 'sierra-ui/theming'
import { maxWidth } from 'sierra-ui/utils/media-query-styles'
import styled from 'styled-components'
import { z } from 'zod'

const getEventGroupQuery = graphql(`
  query getCalendarEvent($id: CalendarEventId!) {
    calendarEvent(id: $id) {
      __typename
      id
      schedule {
        ...CalendarEventScheduleFragment
      }
      location {
        ...CalendarEventLocationFragment
      }
      description
      title
      selfReportAttendance
      approverSetting
      assignedUsers {
        attended
        user {
          firstName
          lastName
          id
          email
          avatar {
            ...AvatarFragment
          }
        }
        rsvp
        ...MarkUserAttendaceModalUserItem
      }

      facilitators {
        id
        displayName
        avatar {
          ...AvatarFragment
        }
      }

      specificReviewers {
        id
        displayName
        avatar {
          ...AvatarFragment
        }
      }

      participantLimit
      seatsRemaining

      eventGroup {
        __typename
        contentId
        title
        description
        image {
          ...ImageFragment
        }
      }
    }
  }
`)

const assignUsersToCalendarEventMutation = graphql(`
  mutation assignUsersToCalendarEvent($input: AssignUsersToEventInput!) {
    assignUsersToEvent(input: $input) {
      assignedUsers {
        user {
          firstName
          lastName
          id
          avatar {
            ...AvatarFragment
          }
        }
        ...MarkUserAttendaceModalUserItem
      }
    }
  }
`)

const assignUserGroupsToCalendarEventMutation = graphql(`
  mutation assignUserGroupsToCalendarEvent($input: AssignUserGroupsToEventInput!) {
    assignUserGroupsToEvent(input: $input) {
      assignedUsers {
        user {
          firstName
          lastName
          id
          avatar {
            ...AvatarFragment
          }
        }
        ...MarkUserAttendaceModalUserItem
      }
    }
  }
`)

const unassignUsersFromCalendarEventMutation = graphql(`
  mutation unassignUsersFromCalendarEvent($input: UnassignUsersFromEventInput!) {
    unassignUsersFromEvent(input: $input) {
      assignedUsers {
        attended
        hasSelfReportedAttendance
        user {
          firstName
          lastName
          id
          avatar {
            ...AvatarFragment
          }
        }
        ...MarkUserAttendaceModalUserItem
      }
    }
  }
`)

const Divider = styled.hr`
  width: 100%;
  height: 1px;
  margin: unset;
  background-color: ${token('border/default')};
`

const DescriptionText = styled(Text)`
  white-space: pre-wrap;
`

const QRCodeContainer = styled.div`
  padding: 24px;
  background: rgba(0, 0, 0, 0.05);
  display: flex;
  align-items: center;
  justify-content: center;
`

const QRCodeContainerInner = styled.div``

const QRCodeCardContainer = styled.div`
  display: flex;
  background: ${token('surface/default')};
  border: 1px solid ${token('border/default')};
  border-radius: 12px;
  overflow: hidden;
  margin-top: 16px;
  cursor: pointer;

  ${maxWidth.desktop} {
    flex-direction: column;
  }
`

const QRCodeBox = (props: { url: string }): JSX.Element => (
  <QRCodeContainerInner>
    <QRCode size={48} value={props.url} viewBox={`0 0 256 256`} bgColor='rgba(0, 0, 0, 0)' />
  </QRCodeContainerInner>
)

const ManageCalendarEventDetail: React.FC<{
  calendarEventId: CalendarEventId
  eventGroupId: EventGroupId
}> = ({ calendarEventId, eventGroupId }) => {
  const { t } = useTranslation()
  const notifications = useNotif()
  const hasPermissionToEdit = useHasCalendarEventPermissions(calendarEventId, 'EDIT')

  const { allAssignedUsers: allAssignedUsersToEventGroup, refresh: refreshAssignedUsersQuery } =
    useEventGroupAssignmentData({
      courseId: eventGroupId,
    })

  const [action, setAction] = useState<
    | { modal: 'edit-calendar-event' }
    | { modal: 'mark-attendance' }
    | { modal: 'enroll-users' }
    | {
        modal: 'unassign-users'
        ids: UserId[]
      }
  >()

  const onAssignToEvent = async (selections: AssignModalSelection[]): Promise<void> => {
    const userIds = selections.flatMap(selection => (selection.type === 'user' ? [selection.id] : []))
    const userGroupIds = selections.flatMap(selection =>
      selection.type === 'user-group' ? [selection.id] : []
    )

    await Promise.all([
      graphQuery(assignUsersToCalendarEventMutation, {
        input: { userIds, calendarEventId },
      }),
      graphQuery(assignUserGroupsToCalendarEventMutation, {
        input: { userGroupIds, calendarEventId },
      }),
    ])
  }

  const onUnassignToEvent = async (userIds: UserId[]): Promise<void> => {
    await graphQuery(unassignUsersFromCalendarEventMutation, {
      input: { userIds, calendarEventId: calendarEventId },
    })
  }

  const queryResult = useGraphQuery(
    {
      document: getEventGroupQuery,
      queryOptions: {
        gcTime: 0,
        refetchOnWindowFocus: false,
      },
    },
    {
      id: calendarEventId,
    }
  )

  const event = queryResult.data?.calendarEvent

  const learners = useMemo(() => {
    return (
      event?.assignedUsers.map(it => {
        const assignedUserInfo = allAssignedUsersToEventGroup.data.find(
          d => d.userInfo.baseUserInfo.userId === it.user.id
        )

        return {
          data: {
            id: it.user.id,
            firstName: it.user.firstName ?? '',
            lastName: it.user.lastName ?? '',
            avatar: convertGQLAvatar(it.user.avatar),
            status: it.attended,
            email: it.user.email,
            assignmentInfo: {
              assignedAt: assignedUserInfo?.assignedAt?.toISOString(),
              programs: assignedUserInfo?.programs,
            },
            rsvp: it.rsvp ?? undefined,
          },
        }
      }) ?? []
    )
  }, [allAssignedUsersToEventGroup.data, event?.assignedUsers])

  const deleteCalendarEventAttendance = useDeleteCalendarEventAttendance()

  const deleteAttendance = async (calendarEventId: CalendarEventId, attendeeId: UserId): Promise<void> => {
    await deleteCalendarEventAttendance(calendarEventId, attendeeId)
    void queryResult.refetch()
  }

  const setUserCalendarEventAttendanceStatus = useSetAttendanceStatus()

  const setUserAttendanceStatus = async (input: SetCalendarAttendanceInput): Promise<void> => {
    await setUserCalendarEventAttendanceStatus(input)
    void queryResult.refetch()
  }

  const selfCheckInUrl = useMemo(() => {
    const url = new URL(window.location.href)
    return `${url.protocol}//${url.hostname}/check-in/${eventGroupId}/${calendarEventId}`
  }, [calendarEventId, eventGroupId])

  const assetContext: AssetContext = useMemo(() => {
    if (isNonNullable(event)) {
      const [scope, id] = event.eventGroup.contentId.split(':')
      if (scope === 'course') {
        return { type: 'course', courseId: CourseId.parse(id) }
      }
    }

    return { type: 'unknown' }
  }, [event])

  const imageSrc = useResolveAsset({
    image: isNonNullable(event) ? convertGQLImage(event.eventGroup.image) : undefined,
    assetContext,
    size: 'default',
  })

  if (!isNonNullable(event)) return null

  const numberOfUsersAttendingString = isNonNullable(event.participantLimit)
    ? `${event.assignedUsers.length}/${event.participantLimit}`
    : event.assignedUsers.length

  const startDateTime = getGqlEventScheduleStart(event.schedule)

  return (
    <>
      <ColumnLayout>
        <DetailsHeader
          backlink={{
            href: '/manage/in-person-events',
            label: 'manage.backlinks--in-person-events',
          }}
        />
        <ManageDetailGrid>
          <ManageDetailSidebar>
            <SanaImage src={imageSrc} ratio='16:9' rounded />
            <Spacer size='16' />
            <Heading size='h4' bold>
              {event.title}
            </Heading>
            <Spacer size='8' />
            <View>
              <Icon color={'foreground/muted'} iconId='calendar' />
              <Text color={'foreground/muted'}>
                {formatGqlEventSchedule(event.schedule, { month: 'long' })}
              </Text>
            </View>
            <Spacer size='4' />
            <View>
              <Icon color={'foreground/muted'} iconId='location' />
              <Text color={'foreground/muted'}>
                {gqlEventLocationString(event.location) ?? t('events.no-location')}
              </Text>
            </View>
            <Spacer size='4' />
            {isDefined(numberOfUsersAttendingString) && (
              <View>
                <Icon color={'foreground/muted'} iconId='mandatory' />
                <Text color={'foreground/muted'}>{numberOfUsersAttendingString}</Text>
              </View>
            )}
            <Spacer size='24' />
            {hasPermissionToEdit && (
              <>
                <Button onClick={() => setAction({ modal: 'edit-calendar-event' })} variant='secondary'>
                  {t('dictionary.edit-session')}
                </Button>
                <Spacer size='24' />
              </>
            )}
            <Text size='regular' bold>
              {t('dictionary.description')}
            </Text>
            <Spacer size='4' />
            <DescriptionText>
              {isDefined(event.description?.length) && event.description.length > 0 ? (
                <FreeTextEditor content={event.description} useHtml editable={false} />
              ) : (
                t('dictionary.no-description')
              )}
            </DescriptionText>

            <Spacer size='24' />
            <Divider />
            <Spacer size='24' />
            {event.selfReportAttendance === true && (
              <div>
                <Text size='regular' bold>
                  {t('event-groups.self-check-in')}
                </Text>
                <RouterLink href={`/check-in/${eventGroupId}/${calendarEventId}/qr`} target='_blank'>
                  <QRCodeCardContainer>
                    <QRCodeContainer>
                      <QRCodeBox url={selfCheckInUrl} />
                    </QRCodeContainer>
                    <View direction='column' padding='16'>
                      <Text bold>{t('event-groups.qr-code-description')}</Text>
                      <Text color={'foreground/muted'}>{t('event-groups.open-in-new-tab')}</Text>
                    </View>
                  </QRCodeCardContainer>
                </RouterLink>
              </div>
            )}
          </ManageDetailSidebar>
          <ManageDetailContent>
            <ManageAssignedLearnersTable
              quickSetAttendanceStatus={setUserAttendanceStatus}
              learners={learners}
              showAssignModal={() => setAction({ modal: 'enroll-users' })}
              showUnassignModal={(ids: UserId[]) => setAction({ modal: 'unassign-users', ids })}
              showMarkAttendanceModal={() => setAction({ modal: 'mark-attendance' })}
              eventStartTime={startDateTime}
              deleteCalendarEventAttendance={deleteAttendance}
              calendarEventId={calendarEventId}
            />
          </ManageDetailContent>
        </ManageDetailGrid>
      </ColumnLayout>
      {['enroll-users', 'enroll-groups'].includes(action?.modal ?? '') && (
        <AssignModal
          isOpen={true}
          config={{
            subjectType: 'calendar-event',
            panes: 'user-and-user-group',
            activePane: 'user',
            onSave: async selections => {
              await onAssignToEvent(selections)
              await queryResult.refetch()
              refreshAssignedUsersQuery()
              setAction(undefined)
            },
          }}
          subjects={calendarEventId}
          title={t('dictionary.enrollments')}
          onClose={() => {
            setAction(undefined)
          }}
        />
      )}
      {action?.modal === 'unassign-users' && (
        <ActionModal
          open
          onClose={() => setAction(undefined)}
          primaryAction={async (): Promise<void> => {
            await onUnassignToEvent(action.ids)
            setAction(undefined)
            notifications.push({
              type: 'custom',
              level: 'success',
              body: t('notifications.done'),
            })
            void queryResult.refetch()
          }}
          primaryActionLabel={t('dictionary.unassign')}
          title={t('manage.unassign-users.title', { count: action.ids.length })}
          deleteAction
        />
      )}

      {/* TODO: get identities */}
      {action?.modal === 'edit-calendar-event' && (
        <EditCalendarEventPanel
          event={{
            id: calendarEventId,
            schedule: gqlEventScheduleToEventSchedule(event.schedule),
            title: event.title,
            description: event.description ?? '',
            location: gqlEventLocationToEventLocation(event.location),
            participantLimit: event.participantLimit ?? undefined,
            selfReportAttendance: event.selfReportAttendance,
            approverSetting: event.approverSetting ?? undefined,
            participantIdentities: event.assignedUsers.map(participant => ({
              identity: { type: 'user', id: participant.user.id },
              name: participant.user.firstName + ' ' + participant.user.lastName,
              avatar: convertGQLAvatar(participant.user.avatar),
            })),
            facilitatorIdentities: event.facilitators.map(facilitator => ({
              identity: { type: 'user', id: facilitator.id },
              name: facilitator.displayName,
              avatar: convertGQLAvatar(facilitator.avatar),
            })),
            specificReviewers: event.specificReviewers.map(reviewer => ({
              identity: { type: 'user', id: reviewer.id },
              name: reviewer.displayName,
              avatar: convertGQLAvatar(reviewer.avatar),
            })),
          }}
          open={true}
          onClose={() => setAction(undefined)}
          afterSubmit={() => queryResult.refetch()}
        />
      )}

      {action?.modal === 'mark-attendance' && (
        <MarkUserAttendanceModal
          open={true}
          onClose={() => setAction(undefined)}
          users={event.assignedUsers}
          calendarEventId={calendarEventId}
          onChange={queryResult.refetch}
        />
      )}
    </>
  )
}

const Page: React.FC = () => {
  const { calendarEventId, eventGroupId } = Route.useParams()

  const permissions = useCalendarEventPermissions(calendarEventId)

  return (
    <ManagePageWrapper
      pageIdentifier={PageIdentifier.ManageCalendarEventDetail({ calendarEventId, eventGroupId })}
    >
      <LoadingPermissionsWrapper
        isLoading={permissions.status === 'loading'}
        hasAccess={permissions.has('MANAGE')}
      >
        <ManageCalendarEventDetail eventGroupId={eventGroupId} calendarEventId={calendarEventId} />
      </LoadingPermissionsWrapper>
    </ManagePageWrapper>
  )
}

export const Route = createFileRoute('/manage/in-person-events/$eventGroupId/$calendarEventId')({
  component: Page,
  validateSearch: z.object({
    groupId: z.string().optional(),
  }),
  params: {
    parse: z.object({ eventGroupId: EventGroupId, calendarEventId: CalendarEventId }).parse,
    stringify: p => p,
  },
})
