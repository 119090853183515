import 'emoji-mart/css/emoji-mart.css'
import { useContext } from 'react'
import { EmojiPickerContext, EmojiPickerPopupContextObj } from 'sierra-client/editor/emoji-context'
import { dynamic } from 'sierra-client/utils/dynamic'

export const Emoji = dynamic(() => import('sierra-client/components/common/emoji-component-implementation'))

export const EmojiPickerPopupProvider = dynamic(
  () => import('sierra-client/editor/emoji-picker-popup-provider')
)

export const useEmojiPicker = (): EmojiPickerContext => {
  const context = useContext(EmojiPickerPopupContextObj)

  if (context === undefined) {
    throw new Error('The component needs to be wrapped in the EmojiPickerProvider')
  }

  return context
}
