import { MonitorWindowSize } from 'sierra-client/state/browser/selectors'
import { useWindowSize } from 'sierra-ui/utils'
import { createGlobalStyle } from 'styled-components'

const GlobalVHStyle = createGlobalStyle<{ height: number }>`
  :root {
    --VH: ${p => p.height / 100}px;
  }
`

export const SyncBrowserState = (): JSX.Element => {
  const height = useWindowSize().height
  return (
    <>
      <GlobalVHStyle height={height} />
      <MonitorWindowSize />
    </>
  )
}
