import React, { useCallback, useState } from 'react'
import { HeaderGroupIconButton } from 'sierra-client/components/liveV2/header-buttons'
import { useCreatePageContext } from 'sierra-client/views/flexible-content/create-page-context'
import { UploadImageModal } from 'sierra-client/views/upload-image-modal/upload-image-modal'
import type { AssetContext } from 'sierra-domain/asset-context'
import { ImageUnion } from 'sierra-domain/content/v2/content'
import { apply } from 'sierra-domain/editor/operations'
import { CardBackgroundImage, File } from 'sierra-domain/flexible-content/types'

export function getFileImage(file: File): ImageUnion | undefined {
  if (file.data.type === 'image') return file.data.image
  else return file.backgroundImage
}

export const FileBackgroundPicker: React.FC<{
  file: File
  assetContext: AssetContext
}> = ({ file, assetContext }) => {
  const { operationState } = useCreatePageContext()

  const [open, setOpen] = useState(false)

  const fileId = file.id
  const fileImage = getFileImage(file)

  const updateBackground = useCallback(
    (image: CardBackgroundImage | undefined): void => {
      if (fileImage === undefined && !open) {
        setOpen(!open)
      } else {
        setOpen(false)
        apply(operationState, {
          type: 'update-files',
          fileIds: [fileId],
          update: file => {
            if (file.data.type === 'image') {
              file.data.image = image
            } else {
              file.backgroundImage = image
            }
          },
        })
      }
    },
    [fileImage, open, operationState, fileId]
  )

  return (
    <>
      <HeaderGroupIconButton
        color='currentColor'
        iconId={fileImage === undefined ? 'image' : 'no-image'}
        onClick={() => updateBackground(undefined)}
      />

      <UploadImageModal
        open={open}
        onUploadDone={updateBackground}
        onClose={() => setOpen(false)}
        assetContext={assetContext}
      />
    </>
  )
}
