import { graphql } from 'sierra-client/api/graphql/gql'
import { UpsertCertificateRequestInput } from 'sierra-client/api/graphql/gql/graphql'
import { graphQuery } from 'sierra-client/api/hooks/use-graphql-query'

export const useUpsertCertificate = (): ((
  upsertCertificateRequest: UpsertCertificateRequestInput
) => Promise<void>) => {
  const upsertCertificate = async (
    upsertCertificateRequest: UpsertCertificateRequestInput
  ): Promise<void> => {
    await graphQuery(
      graphql(`
        mutation upsertCertificate($upsertCertificateRequest: UpsertCertificateRequestInput!) {
          upsertCertificate(upsertCertificateRequest: $upsertCertificateRequest) {
            certificate {
              id
              title
              description
              certificateAuthority
              validityPeriod {
                duration
                timeUnit
                expires
              }
              templateData {
                orientation
                logotype
                backgroundColor
                textColor
                backgroundImage {
                  file
                  width
                  height
                }
              }
            }
          }
        }
      `),
      {
        upsertCertificateRequest,
      }
    )
  }

  return upsertCertificate
}
