import { createFileRoute } from '@tanstack/react-router'
import { PageIdentifier } from 'sierra-client/layout/sana-page'
import { ManagePathDetails } from 'sierra-client/views/manage/paths/manage-path-details'
import { ManagePageWrapper } from 'sierra-client/views/manage/utils/manage-page-wrapper'
import { PathId } from 'sierra-domain/api/nano-id'
import { z } from 'zod'

// Note: ManagePageWrapper handles the role check.
const Page: React.FC = () => {
  const pathId = Route.useParams({ select: p => p.pathId })

  return (
    <ManagePageWrapper pageIdentifier={PageIdentifier.ManagePathDetail({ pathId })}>
      <ManagePathDetails key={pathId} pathId={pathId} />
    </ManagePageWrapper>
  )
}

const RouteComponent = Page

export const Route = createFileRoute('/manage/paths/$pathId')({
  component: RouteComponent as React.FC,
  validateSearch: z.object({
    isNewPath: z.boolean().optional(),
  }),
  params: {
    parse: z.object({ pathId: PathId }).parse,
    stringify: p => p,
  },
})
