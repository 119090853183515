import { createFileRoute } from '@tanstack/react-router'
import React from 'react'
import { ShortcutMenu } from 'sierra-client/components/shortcut-menu/shortcut-menu'
import { config } from 'sierra-client/config/global-config'
import { requireLoggedIn } from 'sierra-client/core/require-logged-in'
import { isFileId } from 'sierra-client/state/flexible-content/types'
import { RecapPage } from 'sierra-client/views/recap/recap-page'
import { LiveSessionId } from 'sierra-domain/api/nano-id'
import { NodeId } from 'sierra-domain/flexible-content/identifiers'
import { z } from 'zod'

const SplatParamSchema = z.union([z.tuple([LiveSessionId]), z.tuple([LiveSessionId, NodeId])])

const Page: React.FC = () => {
  const [liveSessionId, nodeId] = Route.useParams({
    select: params => SplatParamSchema.parse(params._splat?.split('/')),
  })

  if (nodeId !== undefined && !isFileId(nodeId)) {
    throw new Error(`Can only render files`)
  }

  return (
    <>
      <RecapPage liveSessionId={liveSessionId} fileId={nodeId} />

      <ShortcutMenu.Action
        label='shortcut-menu.default.go-to-live'
        group='navigation'
        run={() => {
          const { location } = window

          const url = new URL(`${location.origin}/l/${liveSessionId}`)
          const host = config.auth.host
          if (host !== undefined) url.searchParams.set('x-host', host)

          window.open(url, '_blank')
        }}
        iconId='arrow--right'
        permission='ACCESS_LMS'
      />
    </>
  )
}

const RouteComponent = requireLoggedIn(Page, { allowScorm: true })

export const Route = createFileRoute('/r/$')({
  component: RouteComponent as React.FC,
})
