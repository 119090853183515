import { AnimatePresence, motion } from 'framer-motion'
import React, { useEffect, useState } from 'react'
import { useLiveQuizContext } from 'sierra-client/views/v3-author/live-quiz/live-quiz-context'
import { LiveQuizAvatar } from 'sierra-client/views/v3-author/live-quiz/live-quiz-user/live-quiz-avatar'
import { LiveQuizGetLeaderboardResponse } from 'sierra-domain/api/strategy-v2'
import { LiveQuizUser } from 'sierra-domain/live-session'
import { isDefined } from 'sierra-domain/utils'
import { color } from 'sierra-ui/color'
import { Icon } from 'sierra-ui/components'
import { Heading, Text, View } from 'sierra-ui/primitives'
import styled from 'styled-components'

const MaxWidth = styled(View).attrs({ direction: 'column', gap: '48' })`
  max-width: 700px;
  width: 100%;
`

const DummyGrid = styled(View).attrs({
  direction: 'column',
  gap: '48',
})``

const Top = styled(View).attrs({ direction: 'column', gap: '12' })``

const Title = styled(Text).attrs({
  size: 'small',
  bold: true,
})`
  color: ${p => color(p.theme.home.textColor).opacity(0.4)};
`

const InfoText = styled(Heading).attrs({
  size: 'h3',
  bold: true,
})`
  color: ${p => color(p.theme.home.textColor)};
`

/* Entry */
const Name = styled(Text).attrs({ size: 'small', bold: true })`
  color: ${p => color(p.theme.home.textColor).shift(0)};
`

const Points = styled(Text).attrs({ size: 'small', bold: true })`
  color: ${p => color(p.theme.home.textColor).opacity(0.4)};
`

type BarWidth = { $percentage: number }

const Bar = styled(View).attrs({
  animated: true,
  direction: 'row',
  gap: 'none',
  justifyContent: 'flex-end',
  alignItems: 'center',
  padding: '4',
})<{ $percentage: number }>`
  align-self: center;

  min-width: calc(16px + 8px) !important; /* Icon-size + 2 * padding */
  transition: background-color 0.4ms cubic-bezier(0.25, 0.5, 0.25, 1);

  border-radius: 16px;
  background-color: ${p => color(p.theme.home.textColor).opacity(Math.max(0.2, p.$percentage / 100))};
`

const Circle = styled.span`
  flex: 0 0 auto; /* Should stay at its size */

  display: inline-flex;
  justify-content: center;
  align-items: center;

  width: 16px;
  height: 16px;
  border-radius: 100%;

  background-color: ${p => color(p.theme.home.backgroundColor)};
`

const ArrowIcon = styled(Icon).attrs({
  iconId: 'arrow--right',
  size: 'size-10',
})`
  color: ${p => color(p.theme.home.textColor)};
`

const BarWithArrow: React.FC<BarWidth> = ({ $percentage }) => (
  <Bar
    initial={{ width: `${$percentage * 0.9}%` }}
    animate={{ width: `${$percentage}%` }}
    transition={{ duration: 0.4, delay: 0.5 }}
    $percentage={$percentage}
  >
    <Circle>
      <ArrowIcon />
    </Circle>
  </Bar>
)

const Grid = styled.div`
  display: grid;
  grid-template-columns: max-content 100px 1fr;
  grid-auto-rows: auto;
  gap: 16px;

  & > * {
    grid-column: 1 / -1;
  }
`

type Entry = {
  userId: string
  points?: number
  percentage: number
} & LiveQuizUser

const Subgrid = styled(motion.div)`
  display: grid;
  grid-template-columns: subgrid;
`

const Entry: React.FC<Entry> = ({ points, percentage, userId, ...user }) => (
  <Subgrid
    layout='position'
    layoutId={userId}
    transition={{ type: 'spring', bounce: 0.2, duration: 0.5, delay: 0.5, layout: { delay: 1 } }}
  >
    <LiveQuizAvatar size='leaderboard' {...user} />
    <View direction='column' gap='none'>
      <Name>{user.nickname}</Name>
      <Points>
        <AnimatePresence mode='popLayout'>
          {points
            ?.toString()
            .split('')
            .map((p, i, { length }) => (
              <motion.span
                style={{ display: 'inline-block', fontVariantNumeric: 'tabular-nums' }}
                key={`${points}-${p}-${i}`}
                initial={{ y: 4, opacity: 0, filter: 'blur(1px)' }}
                animate={{ y: 0, opacity: 1, filter: 'blur(0)' }}
                exit={{ y: -4, opacity: 0, filter: 'blur(1px)' }}
                transition={{ duration: 0.4, delay: (length - i) * 0.05 }}
              >
                {p}
              </motion.span>
            ))}
        </AnimatePresence>{' '}
        points
      </Points>
    </View>
    <BarWithArrow $percentage={percentage} />
  </Subgrid>
)

type UsersWithPoints = {
  userId: string
  points: number
}[]

export const Leaderboard: React.FC<{ leaderboard: LiveQuizGetLeaderboardResponse }> = ({ leaderboard }) => {
  const { status, users } = useLiveQuizContext()

  const preQuestionPoints: UsersWithPoints = leaderboard.entries.map(entry => ({
    userId: entry.userId,
    points: entry.pointsWithoutExcludedQuestion ?? 0,
  }))
  const postQuestionPoints: UsersWithPoints = leaderboard.entries.map(entry => ({
    userId: entry.userId,
    points: entry.points,
  }))

  const [showPoints, setShowPoints] = useState(preQuestionPoints)

  const [allPercentagesZero, setAllPercentagesZero] = useState(
    status.step === 'leaderboard' && status.currentIndex === 1
  )

  useEffect(() => {
    setTimeout(() => {
      setShowPoints(postQuestionPoints)
      setAllPercentagesZero(false)
    }, 2000)
  }, [postQuestionPoints])

  const showPointsWithNames = showPoints
    .map(entry => {
      const user = users.find(user => user.userId === entry.userId)

      if (user === undefined) {
        return undefined
      }

      return {
        ...entry,
        ...user,
      }
    })
    .filter(user => isDefined(user))
    .sort((e1, e2) => (e1.points > e2.points ? -1 : 1))
    .slice(0, 5)

  const postQuestionLeader = postQuestionPoints.sort((e1, e2) => (e1.points > e2.points ? -1 : 1))[0]
  const postQuestionLeaderUser = users.find(user => user.userId === postQuestionLeader?.userId)

  if (status.step !== 'leaderboard' && status.step !== 'finished') {
    return null
  }

  return (
    <MaxWidth>
      <DummyGrid>
        <Top>
          <Title>{status.step === 'leaderboard' ? 'Leaderboard' : 'Results'}</Title>
          {postQuestionLeaderUser && (
            <>
              {status.step === 'finished' ? (
                <InfoText>{postQuestionLeaderUser.nickname} wins!</InfoText>
              ) : (
                <InfoText>
                  With incredible speed, {postQuestionLeaderUser.nickname} has taken the lead.
                </InfoText>
              )}
            </>
          )}
        </Top>
        <Grid>
          {showPointsWithNames.map((entry, index) => (
            <Entry
              key={entry.userId}
              percentage={allPercentagesZero || entry.points === 0 ? 0 : 100 - 0.25 * index * 100}
              {...entry}
            />
          ))}
        </Grid>
      </DummyGrid>
    </MaxWidth>
  )
}
