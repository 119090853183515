import _ from 'lodash'
import { DateTime } from 'luxon'
import React, { useCallback, useEffect, useMemo } from 'react'
import { useInView } from 'react-intersection-observer'
import { CellProps, Column, useGlobalFilter, useSortBy, useTable } from 'react-table'
import { RouterLink } from 'sierra-client/components/common/link'
import { BasicTable } from 'sierra-client/components/table/basic-table'
import { getAssetContextFromContentKindAndId } from 'sierra-client/components/util/asset-contex'
import { useDebouncedAction } from 'sierra-client/hooks/use-debounced-action'
import { useTranslation } from 'sierra-client/hooks/use-translation'
import { getAvatarImage } from 'sierra-client/utils/avatar-img'
import { InfiniteScrollMessage } from 'sierra-client/views/manage/components/common'
import { getHrefForContentDetails } from 'sierra-client/views/manage/content/utils/content-utils'
import { ContentRow } from 'sierra-client/views/manage/reports/components/heatmap/components'
import {
  ColumnHeader,
  HeaderContainer,
} from 'sierra-client/views/manage/reports/components/heatmap/components/column-header'
import {
  useAvailableHeatmapContent,
  useHeatmap,
} from 'sierra-client/views/manage/reports/components/heatmap/hooks/use-heatmap'
import {
  AssignmentStatuses,
  CertificateStatuses,
  HeatmapUserFilter,
} from 'sierra-client/views/manage/reports/components/heatmap/types'
import { formatPercentage } from 'sierra-client/views/measure/analytics-v2/util'
import { checkCertificateStatus } from 'sierra-client/views/workspace/utils/certificates'
import { manageUrl } from 'sierra-client/views/workspace/utils/urls'
import {
  HeatmapCertificateStatus,
  HeatmapContent,
  HeatmapContentCourse,
  HeatmapContentStatus,
} from 'sierra-domain/api/heatmap'
import { AssetContext } from 'sierra-domain/asset-context'
import { BaseUserRow } from 'sierra-domain/user/base-user-row'
import { ItemOf, assertNever, isDefined } from 'sierra-domain/utils'
import {
  Autocomplete,
  AutocompleteOption,
  Icon,
  RoundAvatar,
  Tooltip,
  TruncatedText,
  getAvatarPropsFromBaseUserInfo,
} from 'sierra-ui/components'
import { HideScrollbarUnlessHovered } from 'sierra-ui/components/layout-kit'
import { IconButton, Spacer, Text, View } from 'sierra-ui/primitives'
import { palette, token } from 'sierra-ui/theming'
import styled, { css } from 'styled-components'

export const VerticalSeparator = styled.span<{ $height?: number }>`
  border-right: 1px solid rgba(0, 0, 0, 0.05);
  ${p =>
    p.$height !== undefined &&
    css`
      height: ${p.$height}px;
    `}
`

const UserHeaderWrapper = styled.div`
  display: flex;
  align-items: center;
  height: 4rem;
`

const TableContainer = styled.div`
  height: 100%;
  flex: 1;

  table {
    max-height: 100%;
    width: unset;
    max-width: 100%;
    -ms-overflow-style: none;
    scrollbar-width: none;

    &::-webkit-scrollbar {
      display: none;
    }

    /* Hide the overflow that is shown when hovering a row. */

    td,
    th {
      &:first-child::before,
      &:last-child::after {
        content: none;
        display: none;
      }
    }
  }

  tbody > tr {
    height: 4rem;
  }

  /* Remove the border of the last td in extra row (loading) */

  tbody > tr:last-of-type td:last-of-type {
    border-right: 0;
  }

  && tr > td:first-child {
    padding-left: 0;
    border-right: 2px solid ${palette.grey[5]};
  }

  tr > td:nth-child(2) {
    border-left: none;
  }

  tr > th:nth-child(2) {
    border-left: none;
  }

  td:not(:first-child),
  th:not(:first-child) {
    padding: 1rem;
    border-left: 1px solid ${palette.grey[5]};
  }
`

type LearnerRow = BaseUserRow & {
  rowType: 'learner'
}

const CellText = styled(Text).attrs({
  size: 'small',
})``

const GreenCellText = styled(Text).attrs({
  size: 'small',
})`
  ${p => css`
    color: ${p.theme.color.greenBright};
  `}
`

const GreyCellText = styled(Text).attrs({
  size: 'small',
})`
  ${p => css`
    color: ${p.theme.color.grey45};
  `}
`

const CellContentFull = styled.div<{
  $color?: 'green' | 'red' | 'darkred' | 'yellow' | 'orange' | 'white'
  $started: boolean
}>`
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  padding: 1rem;

  display: flex;
  align-items: center;
  justify-content: space-between;

  ${p =>
    p.$color === 'green'
      ? css`
          background-color: ${p.theme.color.greenBright};

          ${CellText} {
            color: ${palette.primitives.white};
          }
        `
      : p.$color === 'red'
        ? css`
            background-color: ${p.theme.color.redPastel};
          `
        : p.$color === 'orange'
          ? css`
              background-color: ${p.theme.color.orangeVivid};

              ${CellText} {
                color: ${palette.primitives.white};
              }
            `
          : p.$color === 'yellow'
            ? css`
                background-color: ${p.theme.color.yellowBright};
              `
            : p.$color === 'white'
              ? css`
                  background-color: ${palette.primitives.white};
                `
              : p.$color === 'darkred'
                ? css`
                    background-color: ${p.theme.color.redVivid};

                    ${CellText} {
                      color: ${palette.primitives.white};
                    }
                  `
                : undefined};

  ${p =>
    p.$started === false &&
    css`
      ${CellText} {
        opacity: 0.5;
      }
    `}
`

const EmptyCellContent = styled(CellContentFull)`
  padding: 0;

  svg {
    stroke-opacity: 0.05;
    stroke-linecap: butt;
  }
`

const UserAssignmentCell: React.FC<{
  row: HeatmapContentStatus
  content: Omit<HeatmapContent, 'type'>
}> = ({ row }) => {
  const { t } = useTranslation()

  const { progress, dueDate, lastProgressAt, completedAt } = row

  const isCompleted = completedAt !== undefined
  const isStarted = lastProgressAt !== undefined && progress > 0.0
  const hasPendingActions = isDefined(row.pendingActions) ? row.pendingActions > 0 : true
  const isOverdue = dueDate !== undefined && DateTime.now().toISODate() > dueDate && hasPendingActions

  const cellText = useMemo(() => {
    if (isCompleted) {
      const dateString = DateTime.fromISO(completedAt).toLocaleString({
        month: 'short',
        day: 'numeric',
        year: 'numeric',
      })
      return (
        <>
          <GreenCellText color='LEGACY_DEFAULT_TEXT_COLOR_REPLACE_ASAP' bold>
            Completed
          </GreenCellText>
          <GreyCellText color='LEGACY_DEFAULT_TEXT_COLOR_REPLACE_ASAP'>{dateString}</GreyCellText>
        </>
      )
    } else if (isStarted) {
      const progressTimeString = DateTime.fromISO(lastProgressAt).toLocaleString({
        month: 'short',
        day: 'numeric',
      })

      if (isOverdue) {
        return (
          <>
            <CellText color='LEGACY_DEFAULT_TEXT_COLOR_REPLACE_ASAP' bold>
              {formatPercentage(progress, 0)}
            </CellText>
            <CellText color='LEGACY_DEFAULT_TEXT_COLOR_REPLACE_ASAP'>{progressTimeString}</CellText>
          </>
        )
      } else {
        return (
          <>
            <CellText color='LEGACY_DEFAULT_TEXT_COLOR_REPLACE_ASAP' bold>
              {formatPercentage(progress, 0)}
            </CellText>
            <GreyCellText color='LEGACY_DEFAULT_TEXT_COLOR_REPLACE_ASAP'>{progressTimeString}</GreyCellText>
          </>
        )
      }
    } else {
      return (
        <CellText color='LEGACY_DEFAULT_TEXT_COLOR_REPLACE_ASAP'>
          {t('manage.heatmap.status.not-started')}
        </CellText>
      )
    }
  }, [isCompleted, isStarted, isOverdue, completedAt, lastProgressAt, progress, t])

  const cellTitle =
    dueDate === undefined || isCompleted
      ? undefined
      : // TODO: Add translation string specifically for this
        `${t('due-date.due-date')}: ${DateTime.fromISO(dueDate).toLocaleString({
          month: 'long',
          day: 'numeric',
          year: 'numeric',
        })}`

  return (
    <CellContentFull
      $color={isCompleted ? 'white' : isOverdue ? 'darkred' : undefined}
      $started={isStarted || isCompleted}
      title={cellTitle}
    >
      {cellText}
    </CellContentFull>
  )
}

const UserCertificateCell: React.FC<{
  row: HeatmapCertificateStatus
}> = ({ row }) => {
  const { t } = useTranslation()

  const { issuedAt, certStatus } = useMemo(() => {
    const issuedAt = DateTime.fromISO(row.issuedAt)
    const certStatus = checkCertificateStatus(row)

    return { issuedAt, certStatus }
  }, [row])

  const tooltipText =
    certStatus.status === 'valid'
      ? certStatus.date
        ? t('manage.heatmap.certificates.expires-at', { date: certStatus.date.toISODate() })
        : t('manage.heatmap.certificates.no-expiry')
      : t('manage.heatmap.certificates.awarded-at', { date: issuedAt.toISODate() })

  const cellText = useMemo(() => {
    if (certStatus.status === 'revoked') {
      return (
        <>
          <CellText color='foreground/primary' bold>
            {t('manage.heatmap.certificates.revoked')}
          </CellText>
          <CellText color='foreground/primary'>{certStatus.revokedAt.toISODate()}</CellText>
        </>
      )
    } else if (certStatus.status === 'expiring-soon') {
      return (
        <>
          <CellText color='foreground/primary' bold>
            {t('manage.heatmap.certificates.expiring-soon')}
          </CellText>
          <CellText color='foreground/primary'>{certStatus.expiresAt.toISODate()}</CellText>
        </>
      )
    } else if (certStatus.status === 'expired') {
      return (
        <>
          <CellText color='foreground/primary' bold>
            {t('manage.heatmap.certificates.expired')}
          </CellText>
          <CellText color='foreground/primary'>{certStatus.expiredAt.toISODate()}</CellText>
        </>
      )
    } else {
      return (
        <>
          <GreenCellText color='foreground/primary' bold>
            {t('manage.heatmap.certificates.valid')}
          </GreenCellText>
          <GreyCellText color='foreground/primary'>
            {t('manage.heatmap.certificates.awarded-at', { date: issuedAt.toISODate() })}
          </GreyCellText>
        </>
      )
    }
  }, [certStatus, t, issuedAt])

  const cellContentColor = (() => {
    switch (certStatus.status) {
      case 'expiring-soon':
        return 'orange'
      case 'valid':
        return 'white'
      case 'revoked':
      case 'expired':
        return 'darkred'
      default:
        assertNever(certStatus)
    }
  })()

  return (
    <CellContentFull $color={cellContentColor} $started={true} title={tooltipText}>
      {cellText}
    </CellContentFull>
  )
}

const BlankCell: React.FC = () => {
  return (
    <EmptyCellContent $started={false}>
      <svg xmlns='http://www.w3.org/2000/svg' width='100%' height='100%'>
        <defs>
          <pattern
            id='lines'
            width='10'
            height='10'
            // patternContentUnits='default'
            patternUnits='userSpaceOnUse'
          >
            {/* full-length diagonal from bottom-left to top-right*/}
            <path d='M10 0 L0 10z' stroke='black' fill='transparent'></path>

            {/* half-width lines on either side, for make the pattern twice as dense */}
            <path d='M6 -1 L-1 6z' stroke='black' fill='transparent'></path>
            <path d='M11 4 L4 11z' stroke='black' fill='transparent'></path>

            {/* pixel left over in corners */}
            <path d='M15 5 L5 15' stroke='black' fill='transparent'></path>
            <path d='M5 -5 L-5 5' stroke='black' fill='transparent'></path>
          </pattern>
        </defs>
        <rect width='100%' height='100%' fill='url(#lines)' />
      </svg>
    </EmptyCellContent>
  )
}

const AssignmentCell: React.FC<{
  cellProps: CellProps<LearnerRow>
  content: Omit<HeatmapContent, 'type'>
  statuses: AssignmentStatuses | undefined
  certificateStatuses: CertificateStatuses | undefined
}> = React.memo(({ cellProps, content, statuses, certificateStatuses }) => {
  const row = statuses?.[cellProps.row.original.userId]?.[content.id]
  const certificateRow = certificateStatuses?.[cellProps.row.original.userId]?.[content.id]

  if (row !== undefined) {
    return <UserAssignmentCell row={row} content={content} />
  }

  if (certificateRow !== undefined) {
    return <UserCertificateCell row={certificateRow} />
  }

  // There's neither a content row nor a homework row
  return <BlankCell />
})

const AddIcon = styled(Icon).attrs({ iconId: 'add' })`
  align-self: center;
  padding-right: 10px;
`
const List = styled.ul`
  max-height: 600px;
  overflow: auto;
  background: ${token('surface/default')};
  ${HideScrollbarUnlessHovered}
`
const ListItem = styled(AutocompleteOption)<{ disabled?: boolean }>`
  padding: 8px;
  gap: 8px;

  ${p =>
    p.disabled === true
      ? css`
          pointer-events: none;
        `
      : css`
          cursor: pointer;

          &[data-highlighted],
          &:hover {
            background-color: ${token('surface/soft')};
          }
        `}
`
const ContentSelector: React.FC<{
  userFilter: HeatmapUserFilter
  canAddContent: boolean
  onSelect: (id: string) => void
  ignoreContentIds?: Array<string>
}> = ({ onSelect, userFilter, canAddContent, ignoreContentIds }) => {
  const { t } = useTranslation()
  const [state, setState] = React.useState('')
  const [query, setQuery] = React.useState<string>('')

  const availableEntities = useAvailableHeatmapContent({
    userFilter,
    contentQuery: query,
    ignoreContentIds: ignoreContentIds,
    limit: 30,
    skip: !canAddContent,
    skipCourseEditions: true,
  })

  const debouncedQueryChange = useDebouncedAction(setQuery, { wait: 300 })
  const onChange = useCallback(
    (s: string) => {
      setState(s)
      debouncedQueryChange(s)
    },
    [debouncedQueryChange]
  )
  const menuItems = useMemo((): Array<{
    id: string
    title: string
    type: 'separator' | 'item' | 'has-more'
  }> => {
    const separator = (title: string): { id: string; title: string; type: 'separator' } => ({
      id: title,
      type: 'separator' as const,
      title,
    })

    const hasMoreItem = { id: 'has-more', title: `${t('dictionary.more')}...`, type: 'has-more' as const }

    const coursesAndPaths = availableEntities.coursesAndPaths.content.map(
      x => ({ id: x.id, title: x.title, type: 'item' }) as const
    )
    const certificates = availableEntities.certificates.content.map(
      x => ({ id: x.id, title: x.title, type: 'item' }) as const
    )

    return [
      separator(t('dictionary.content-singular')),
      ...coursesAndPaths,
      availableEntities.coursesAndPaths.hasMore ? hasMoreItem : undefined,
      separator(t('dictionary.certificate-singular')),
      ...certificates,
      availableEntities.certificates.hasMore ? hasMoreItem : undefined,
    ].filter(isDefined)
  }, [
    availableEntities.certificates.content,
    availableEntities.certificates.hasMore,
    availableEntities.coursesAndPaths.content,
    availableEntities.coursesAndPaths.hasMore,
    t,
  ])

  const onSelectSelectableItems = useCallback(
    (item: ItemOf<typeof menuItems>) => {
      onSelect(item.id)
    },
    [onSelect]
  )

  return (
    <Autocomplete
      placeholder={t('manage.heatmap.add-content')}
      query={state}
      onQueryChange={onChange}
      matchingItems={menuItems}
      onSelect={onSelectSelectableItems}
      renderMatchingItemList={({ getItemProps }) => (
        <List>
          {menuItems.map((item, index) => {
            switch (item.type) {
              case 'separator':
                return (
                  <ListItem key={item.id + index} disabled {...getItemProps(item, index)}>
                    <Text bold color='foreground/muted'>
                      {item.title}
                    </Text>
                  </ListItem>
                )
              case 'has-more':
                return (
                  <ListItem key={item.id + index} disabled {...getItemProps(item, index)}>
                    <Text bold color='foreground/secondary' size='technical'>
                      {item.title}
                    </Text>
                  </ListItem>
                )
              case 'item':
                return (
                  <ListItem key={item.id + index} {...getItemProps(item, index)}>
                    <Text>{item.title}</Text>
                  </ListItem>
                )
            }
          })}
        </List>
      )}
      trailingVisual={<AddIcon />}
    />
  )
}

const getAssetContextFromHeatmapContentCourse = (
  content: Pick<HeatmapContentCourse, 'id' | 'courseType'>
): AssetContext => {
  return getAssetContextFromContentKindAndId(content.id, content.courseType)
}

type HeatmapProps = {
  userFilter: HeatmapUserFilter
  initialSelectedIds?: Array<string>
  canAddContent: boolean
  programId?: string
  onContentsChange?: (contents: Array<HeatmapContent>) => void
}
export const HeatmapV2: React.FC<HeatmapProps> = React.memo(
  ({ programId, userFilter, initialSelectedIds, canAddContent, onContentsChange }) => {
    const { t } = useTranslation()
    const [inViewRef, shouldLoadMore] = useInView({
      threshold: 0,
    })

    const [hoveredRowIndex, setHoveredRowIndex] = React.useState<number | undefined>(undefined)
    const [hoveredColumnId, setHoveredColumnId] = React.useState<string | undefined>(undefined)

    const [expandedIds, setExpandedIds] = React.useState<Set<string>>(() => new Set())

    const { users, chosenContent, heatmapStats, toggleContent, loadMoreUsers, hasMoreUsers } = useHeatmap({
      userFilter,
      initialSelectedIds: initialSelectedIds,
      programId,
    })

    useEffect(() => {
      onContentsChange?.(chosenContent)
    }, [onContentsChange, chosenContent])

    useEffect(() => {
      if (shouldLoadMore) {
        loadMoreUsers()
      }
    }, [loadMoreUsers, shouldLoadMore])

    const rows: LearnerRow[] = React.useMemo(
      () =>
        users.map(user => ({
          ...user,
          rowType: 'learner',
        })),
      [users]
    )

    const columns: Column<LearnerRow>[] = React.useMemo(() => {
      return [
        {
          id: 'learnerId',
          Header: () => (
            <Text size='small' color='grey40'>
              {t('table.name')}
            </Text>
          ),
          Footer: () => (
            <UserHeaderWrapper>
              <Text color='foreground/primary' size='small' bold>
                {t('manage.heatmap.table.group-total')}
              </Text>
            </UserHeaderWrapper>
          ),
          Cell: (p: CellProps<LearnerRow>) => {
            const user = p.row.original

            return (
              <Tooltip
                title={
                  <Text color='surface/default' size='micro'>
                    <b>{_.compact([user.firstName, user.lastName]).join(' ')}</b>
                    <span> {user.email}</span>
                  </Text>
                }
              >
                <View paddingLeft='xxsmall' alignItems='center'>
                  <RoundAvatar
                    size='small'
                    {...getAvatarPropsFromBaseUserInfo(user)}
                    src={getAvatarImage(user.userId, user.avatar)}
                  />
                  <Spacer />
                  <RouterLink href={`/manage/users/${user.userId}`}>
                    <View cursor='pointer'>
                      <TruncatedText
                        color='LEGACY_DEFAULT_TEXT_COLOR_REPLACE_ASAP'
                        size='small'
                        bold
                        lines={1}
                      >
                        {_.compact([user.firstName, user.lastName]).join(' ')}
                      </TruncatedText>
                    </View>
                  </RouterLink>
                </View>
              </Tooltip>
            )
          },
          width: '250px',
        },
        {
          id: 'average-completion',
          Header: () => (
            <Text size='small' color='grey40'>
              {t('manage.heatmap.table.total')}
            </Text>
          ),
          Footer: () => (
            <Text color='LEGACY_DEFAULT_TEXT_COLOR_REPLACE_ASAP' size='small' bold>
              {formatPercentage(heatmapStats.overallProgress, 0)}
            </Text>
          ),
          Cell: (p: CellProps<LearnerRow>) => {
            return (
              <Text size='small' color='grey70'>
                {formatPercentage(heatmapStats.userProgress[p.row.original.userId], 0)}
              </Text>
            )
          },
          width: '100px',
        },
        ...chosenContent.flatMap(row => {
          const items = [
            {
              id: row.id,
              Header: () => (
                <HeaderContainer>
                  <ColumnHeader
                    row={row}
                    href={
                      row.type !== 'certificate'
                        ? getHrefForContentDetails({
                            id: row.id,
                            contentType: row.type,
                            courseKind: row.type === 'course' ? row.courseType : undefined,
                          })
                        : undefined
                    }
                  />
                  <View direction='row' alignItems='center'>
                    {row.type === 'path' && (
                      <IconButton
                        variant='transparent'
                        iconId={expandedIds.has(row.id) ? 'close' : 'add'}
                        tooltip={
                          expandedIds.has(row.id)
                            ? t('manage.heatmap.collapse-path')
                            : t('manage.heatmap.expand-path')
                        }
                        onClick={() => {
                          if (expandedIds.has(row.id)) {
                            setExpandedIds(ids => new Set(Array.from(ids).filter(x => x !== row.id)))
                          } else {
                            setExpandedIds(ids => new Set([...ids, row.id]))
                          }
                        }}
                      />
                    )}
                    {row.type === 'path' && canAddContent === true && <VerticalSeparator $height={16} />}
                    {canAddContent === true && (
                      <IconButton
                        iconId='trash-can'
                        variant='transparent'
                        tooltip={t('manage.heatmap.delete')}
                        onClick={() => toggleContent(row.id)}
                      />
                    )}
                  </View>
                </HeaderContainer>
              ),
              Footer: () => (
                <Text color='LEGACY_DEFAULT_TEXT_COLOR_REPLACE_ASAP' size='small' bold>
                  {formatPercentage(heatmapStats.contentProgress[row.id], 0)}
                </Text>
              ),
              Cell: (p: CellProps<LearnerRow>) => (
                <AssignmentCell
                  cellProps={p}
                  content={row}
                  statuses={heatmapStats.assignmentStatuses}
                  certificateStatuses={heatmapStats.certificateStatuses}
                />
              ),
              width: '250px',
            },
          ]

          if (row.type === 'path' && expandedIds.has(row.id)) {
            items.push(
              ...(row.courses?.map(pathCourse => ({
                id: `${row.id}:${pathCourse.id}`,
                Header: () => (
                  <ContentRow
                    title={pathCourse.title}
                    image={pathCourse.image}
                    assetContext={getAssetContextFromHeatmapContentCourse(pathCourse)}
                    contentType='course'
                    courseKind={pathCourse.courseType}
                    isCourseEdition={false}
                    href={manageUrl({
                      id: pathCourse.id,
                      type: pathCourse.courseType,
                    })}
                  />
                ),
                Footer: () => (
                  <Text color='LEGACY_DEFAULT_TEXT_COLOR_REPLACE_ASAP' size='small' bold>
                    {formatPercentage(heatmapStats.contentProgress[pathCourse.id], 0)}
                  </Text>
                ),
                Cell: (p: CellProps<LearnerRow>) => (
                  <AssignmentCell
                    cellProps={p}
                    content={pathCourse}
                    statuses={heatmapStats.assignmentStatuses}
                    certificateStatuses={heatmapStats.certificateStatuses}
                  />
                ),
                width: '250px',
              })) ?? [])
            )
          }
          return items
        }),
        ...(canAddContent === true
          ? [
              {
                id: 'add-content',
                Header: () => {
                  return (
                    <View marginRight='16'>
                      <ContentSelector
                        onSelect={id => {
                          toggleContent(id)
                        }}
                        canAddContent={canAddContent}
                        userFilter={userFilter}
                        ignoreContentIds={chosenContent.map(x => x.id)}
                      />
                    </View>
                  )
                },
                Cell: () => null,
                width: '165px',
              },
            ]
          : []),
      ]
    }, [
      chosenContent,
      canAddContent,
      t,
      heatmapStats.overallProgress,
      heatmapStats.userProgress,
      heatmapStats.contentProgress,
      heatmapStats.assignmentStatuses,
      heatmapStats.certificateStatuses,
      expandedIds,
      toggleContent,
      userFilter,
    ])

    const tableInstance = useTable(
      { data: rows, columns, autoResetGlobalFilter: false, autoResetSortBy: false },
      useGlobalFilter,
      useSortBy
    )

    return (
      <TableContainer>
        <BasicTable
          tableInstance={tableInstance}
          getHeaderProps={column => ({
            onMouseEnter: () => {
              // Don't highlight header column
              if (column.id !== 'learnerId') {
                setHoveredColumnId(column.id)
              }
            },
            onMouseLeave: () => {
              setHoveredRowIndex(undefined)
              setHoveredColumnId(undefined)
            },
            style: {
              height: 80,
              ...(column.id.includes(':') // lol
                ? {
                    borderBottom: '4px solid #e6e6e6',
                  }
                : {}),
              ...(column.id === 'learnerId'
                ? {
                    position: 'sticky',
                    borderRight: '2px solid #f2f2f2',
                    top: 0,
                    left: 0,
                    backgroundColor: '#fff',
                    zIndex: 3,
                    paddingLeft: 0,
                  }
                : {}),
              ...(column.id === 'add-content'
                ? {
                    zIndex: 4,
                  }
                : {}),
              ...(hoveredColumnId === column.id
                ? {
                    backgroundColor: '#fbfbfb',
                    color: '#000000',
                  }
                : {}),
            },
          })}
          getCellProps={cellInfo => ({
            onMouseEnter: () => {
              setHoveredRowIndex(cellInfo.row.index)

              // Don't highlight header column
              if (cellInfo.column.id !== 'learnerId') {
                setHoveredColumnId(cellInfo.column.id)
              }
            },
            onMouseLeave: () => {
              setHoveredRowIndex(undefined)
              setHoveredColumnId(undefined)
            },
            style: {
              width: cellInfo.column.width,
              minWidth: cellInfo.column.width,
              maxWidth: cellInfo.column.width,
              position: 'relative',
              height: 64,
              ...(cellInfo.column.id === 'learnerId'
                ? {
                    position: 'sticky',
                    borderRight: '2px solid #f2f2f2',
                    left: 0,
                    backgroundColor: '#fff',
                    zIndex: 1,
                  }
                : {}),

              ...(hoveredRowIndex === cellInfo.row.index || hoveredColumnId === cellInfo.column.id
                ? {
                    // backgroundColor: cellInfo.column.id !== 'learnerId' ? 'rgba(0, 0, 0, 0.03)' : '#f7f7f7', // lol. converted rgba on white to just rgb.
                    backgroundColor: '#fbfbfb',
                  }
                : {}),
            },
          })}
          getFooterProps={column => ({
            onMouseEnter: () => {
              // Don't highlight header column
              if (column.id !== 'learnerId') {
                setHoveredColumnId(column.id)
              }
            },
            onMouseLeave: () => {
              setHoveredRowIndex(undefined)
              setHoveredColumnId(undefined)
            },
            style: {
              width: column.width,
              minWidth: column.width,
              maxWidth: column.width,
              // height: '4rem',
              position: 'sticky',
              borderTop: '2px solid #f2f2f2',
              bottom: 0,
              backgroundColor: '#fff',
              zIndex: 2,
              ...(column.id === 'learnerId'
                ? {
                    zIndex: 3,
                    left: 0,
                  }
                : {}),
              ...(hoveredColumnId === column.id
                ? {
                    backgroundColor: '#fbfbfb',
                  }
                : {}),
            },
          })}
          extraBodyRow={
            hasMoreUsers ? (
              <InfiniteScrollMessage
                padding='none'
                ref={inViewRef}
                text={t('manage.users.table-loading')}
                showSanaLogo
              />
            ) : undefined
          }
        />
      </TableContainer>
    )
  }
)
