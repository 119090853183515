import { useCallback, useEffect, useMemo, useRef, useState } from 'react'
import { graphql } from 'sierra-client/api/graphql/gql'
import { useGraphQuery } from 'sierra-client/api/hooks/use-graphql-query'
import { CertificateSelector } from 'sierra-client/components/certificates/certificate-selector'
import { DeleteBox } from 'sierra-client/components/common/delete-box'
import { useConfirmationModalContext } from 'sierra-client/components/common/modals/confirmation-modal'
import { generativeFeatureUsed } from 'sierra-client/core/logging/authoring/logger'
import { useProgramPermissions } from 'sierra-client/hooks/use-permissions'
import { useTranslation } from 'sierra-client/hooks/use-translation'
import { subscribeSse } from 'sierra-client/state/api'
import { useDispatch } from 'sierra-client/state/hooks'
import { useAreCertificatesEnabled } from 'sierra-client/views/manage/certificates/use-are-certificates-enabled'
import { ImageUploadControl } from 'sierra-client/views/manage/paths/components/image-upload-control'
import { CoverImageInModal } from 'sierra-client/views/manage/programs/components/cover-image-in-modal'
import {
  useAddCertificateToProgram,
  useDeleteCertificateFromProgram,
} from 'sierra-client/views/manage/programs/hooks/use-connect-certificate-to-program'
import { useProgramDetails } from 'sierra-client/views/manage/programs/hooks/use-program-details'
import { ModalActions, PanelType } from 'sierra-client/views/manage/programs/types'
import { UploadImageModal } from 'sierra-client/views/upload-image-modal/upload-image-modal'
import { ProgramId } from 'sierra-domain/api/uuid'
import { AssetContext } from 'sierra-domain/asset-context'
import { ImageUnion } from 'sierra-domain/content/v2/content'
import { ImageData } from 'sierra-domain/flexible-content/types'
import { SSEXRealtimeProgramGenerateDescription } from 'sierra-domain/routes-sse'
import { ClosePanelButton, FormElement, Panel, Tooltip } from 'sierra-ui/components'
import {
  Button,
  Heading,
  InputPrimitive,
  Spacer,
  Switch,
  Text,
  TextAreaPrimitive,
  View,
} from 'sierra-ui/primitives'
import { DarkTokenProvider } from 'sierra-ui/theming'
import styled from 'styled-components'

const Subtitle = styled(Text).attrs({ size: 'small', bold: true })``

const Info = styled(Subtitle).attrs({ size: 'small', color: 'grey25' })``

const Form = styled(View).attrs({ direction: 'column', gap: '8' })``

const ModalHeader = styled(View).attrs({
  direction: 'row',
  alignItems: 'flex-start',
  justifyContent: 'space-between',
})``

const Inner = styled(View).attrs({
  direction: 'column',
  paddingTop: '32',
  paddingRight: '40',
  paddingBottom: '32',
  paddingLeft: '32',
  gap: '32',
})``

const Title = styled(Heading).attrs({ size: 'h5', bold: true })``

const DescriptionWrapper = styled.div`
  position: relative;
`

const GenerateButton = styled(Button).attrs({ variant: 'ghost', icon: 'sana-symbol' })`
  position: absolute;
  bottom: 12px;
  left: 8px;
`

const certificatesQuery = graphql(`
  query getCertificatesConnectedToProgram($programId: ProgramId!) {
    program(id: $programId, version: null) {
      id
      certificates {
        id
        title
        description
        previewImageUrl
      }
    }
  }
`)

type EditProgramModalProps = {
  open: boolean
  programId: ProgramId
  canManageCertificates: boolean
  onDelete: () => Promise<void>
  update: () => Promise<void>
  action: ModalActions
  setAction: (action: ModalActions) => void
  setPanel: (panel: PanelType) => void
}

export const EditProgramModal: React.FC<EditProgramModalProps> = ({
  open,
  programId,
  canManageCertificates,
  onDelete: _onDelete,
  update,
  action,
  setAction,
  setPanel,
}) => {
  const { t } = useTranslation()
  const [title, setTitle] = useState('')
  const [description, setDescription] = useState('')
  const [image, setImage] = useState<ImageUnion | undefined>(undefined)
  const [allowsSelfEnroll, setAllowsSelfEnroll] = useState(false)
  const [showImageModal, setShowImageModal] = useState(false)
  const [isGeneratingDescription, setIsGeneratingDescription] = useState(false)
  const [isSaving, setIsSaving] = useState(false)

  const { program, updateProgram, deleteProgramImage } = useProgramDetails(programId)
  const onAddCertificate = useAddCertificateToProgram()
  const onDeleteCertificate = useDeleteCertificateFromProgram()
  const programPermissions = useProgramPermissions(programId)
  const confirmationModalContext = useConfirmationModalContext()

  const isReview = action.modal === 'edit-program' && action.enrollmentMode !== undefined

  const enrollmentMode = useRef<'create-enrollment-rule' | 'users' | ''>('')

  useEffect(() => {
    if (action.modal === 'edit-program' && action.enrollmentMode !== undefined) {
      enrollmentMode.current = action.enrollmentMode
    }
  }, [action])

  // Initialize values
  useEffect(() => setTitle(program?.name ?? ''), [program?.name]) // Set name once available
  useEffect(() => setDescription(program?.description ?? ''), [program?.description]) // Set description once available
  useEffect(() => setImage(program?.image), [program?.image]) // Set image once available
  useEffect(() => setAllowsSelfEnroll(program?.allowsSelfEnroll ?? false), [program?.allowsSelfEnroll]) // Set selfEnrolment once available

  const reset = useCallback(() => {
    setTitle(program?.name ?? '')
    // setDescription(program?.description ?? '')
    setImage(program?.image ?? undefined)
    setAllowsSelfEnroll(program?.allowsSelfEnroll ?? false)
  }, [
    program?.name,
    // program?.description,
    program?.image,
    program?.allowsSelfEnroll,
  ])

  const onClose = useCallback((): void => {
    setAction({ modal: undefined })
    setPanel('none')
  }, [setAction, setPanel])

  const closeAndReset = (): void => {
    reset()
    onClose()
  }

  const handleDelete = (): void => {
    confirmationModalContext.show({
      bodyText: t('admin.organization.learners.delete-program-message'),
      confirmLabel: t('admin.organization.learners.delete-program'),
      deleteAction: true,
      onConfirm: async () => {
        await _onDelete()
        onClose()
      },
    })
  }

  const onSave = useCallback(async (): Promise<void> => {
    setIsSaving(true)
    await updateProgram(programId, { name: title, description, image, selfEnrolment: allowsSelfEnroll })

    if (image === undefined) {
      await deleteProgramImage(programId)
    }

    await update()
    onClose()
    setIsSaving(false)
  }, [
    updateProgram,
    programId,
    title,
    description,
    image,
    allowsSelfEnroll,
    update,
    onClose,
    deleteProgramImage,
  ])

  const onImageUploaded = (image: ImageData['image'] | undefined): void => setImage(image)
  const onDeleteImage = (): void => setImage(undefined)

  const certificatesFeatureEnabled = useAreCertificatesEnabled()

  const certificatesData = useGraphQuery(
    { document: certificatesQuery, queryOptions: { enabled: certificatesFeatureEnabled } },
    { programId }
  )
  const certificates = certificatesData.data?.program?.certificates ?? []

  const handleAddCertificate = async (certificateId: string): Promise<void> => {
    await onAddCertificate(programId, certificateId)
    await certificatesData.refetch()
  }

  const handleRemoveCertificate = async (certificateId: string): Promise<void> => {
    await onDeleteCertificate(programId, certificateId)
    await certificatesData.refetch()
  }

  const disableDescriptionGeneration =
    description.length > 0 || isGeneratingDescription || program?.steps.length === 0

  const dispatch = useDispatch()

  const handleGenerateDescription = async (): Promise<void> => {
    setIsGeneratingDescription(true)
    setDescription('')
    await subscribeSse({
      route: SSEXRealtimeProgramGenerateDescription,
      input: { programId: programId },
      onError: e => console.error(e),
      onMessage: event => {
        setDescription(prev => prev.concat(event.data.text))
      },
    })
    setIsGeneratingDescription(false)
    void dispatch(
      generativeFeatureUsed({
        programId: programId,
        generativeFeature: 'generate-program-description',
      })
    )
  }

  const continueToEnrollment = (): void => {
    if (enrollmentMode.current !== '') {
      setAction({ modal: enrollmentMode.current })
    } else {
      setAction({ modal: undefined })
    }
  }

  const skipReview = (): void => {
    onClose()
    continueToEnrollment()
  }

  const saveReview = async (): Promise<void> => {
    await onSave()
    continueToEnrollment()
  }

  const assetContext: AssetContext = useMemo(() => ({ type: 'program', programId }), [programId])

  if (!programPermissions.has('EDIT_METADATA')) return null

  return (
    <Panel size={{ width: 650 }} open={open} onClose={onClose} animation='slide-right' padding='none'>
      <Inner>
        <View direction='column' gap='4'>
          <ModalHeader>
            <Title>
              {isReview
                ? t('manage.program.program-details.review-settings')
                : t('manage.program.program-details.program-details')}
            </Title>
            <Tooltip title={t('dictionary.close')}>
              <ClosePanelButton ariaLabel={t('dictionary.close')} onClick={onClose} />
            </Tooltip>
          </ModalHeader>
          {isReview && <Text>{t('manage.program.program-details.review-description')}</Text>}
        </View>
        <Form>
          <Subtitle>{t('manage.program.program-details.title')}</Subtitle>
          <InputPrimitive
            id='title'
            value={title}
            onChange={e => setTitle(e.target.value)}
            placeholder={t('manage.programs.new-program')}
          />
        </Form>
        <Form>
          <DescriptionWrapper>
            <FormElement
              label={
                <View justifyContent='space-between'>
                  <Subtitle>{t('manage.program.program-details.description')}</Subtitle>
                  <Info>{t('manage.program.program-details.optional')}</Info>
                </View>
              }
              htmlFor='description'
            >
              <TextAreaPrimitive
                id='description'
                rows={5}
                autoExpand
                value={description}
                onChange={e => setDescription(e.target.value)}
                placeholder={t('manage.program.program-details.description-information')}
              />
            </FormElement>
            {!disableDescriptionGeneration && (
              <GenerateButton
                loading={isGeneratingDescription}
                disabled={isGeneratingDescription}
                onClick={handleGenerateDescription}
              >
                {t('dictionary.generate')}
              </GenerateButton>
            )}
          </DescriptionWrapper>
        </Form>
        <Form>
          <Subtitle>{t('manage.program.program-details.cover-image')}</Subtitle>
          {image === undefined ? (
            <ImageUploadControl image={image} onUpload={() => setShowImageModal(true)}>
              <UploadImageModal
                onUploadDone={onImageUploaded}
                onClose={() => setShowImageModal(false)}
                open={showImageModal}
                assetContext={assetContext}
              />
            </ImageUploadControl>
          ) : (
            <DarkTokenProvider>
              <CoverImageInModal image={image} onDelete={onDeleteImage} assetContext={assetContext} />
            </DarkTokenProvider>
          )}
        </Form>
        <Form>
          <Switch
            text={t('manage.program.program-details.self-enrolment')}
            checked={allowsSelfEnroll}
            onChange={() => {
              setAllowsSelfEnroll(!allowsSelfEnroll)
            }}
          />
        </Form>
        {certificatesFeatureEnabled && (
          <View direction='column' justifyContent='flex-start'>
            <Subtitle>{t('dictionary.certificate-plural')}</Subtitle>
            <Info>{t('manage.certificates.add-certificate-to-course-description')}</Info>
            <CertificateSelector
              selectedCertificates={certificates}
              canEdit={canManageCertificates}
              onAdd={handleAddCertificate}
              onRemove={handleRemoveCertificate}
            />
          </View>
        )}
        <View direction='row' justifyContent='flex-end'>
          {isReview ? (
            <>
              <Button onClick={skipReview} variant='secondary'>
                {t('dictionary.skip')}
              </Button>
              <Button loading={isSaving} onClick={saveReview}>
                {t('dictionary.save')}
              </Button>
            </>
          ) : (
            <>
              <Button variant='secondary' onClick={closeAndReset}>
                {t('manage.program.program-details.cancel')}
              </Button>
              <Button loading={isSaving} onClick={onSave}>
                {t('manage.program.program-details.save')}
              </Button>
            </>
          )}
        </View>
        <Spacer size='2' />
        {programPermissions.has('DELETE') && (
          <DeleteBox
            title={t('manage.program.program-details.delete-program')}
            description={t('manage.program.program-details.delete-information')}
            deleteLabel={t('dictionary.delete')}
            onDelete={handleDelete}
          />
        )}
      </Inner>
    </Panel>
  )
}
