import _ from 'lodash'
import React, { useEffect, useMemo, useState } from 'react'
import { ActionModal } from 'sierra-client/components/common/modals/action-modal'
import { useNotif } from 'sierra-client/components/common/notifications'
import { useTranslation } from 'sierra-client/hooks/use-translation'
import { TranslationKey } from 'sierra-client/hooks/use-translation/types'
import { getGlobalRouter } from 'sierra-client/router'
import {
  XApiServer,
  getDefaultXApiServerValues,
  useManageXApiDetails,
} from 'sierra-client/views/manage/api/use-manage-x-api-details'
import { DetailsHeader } from 'sierra-client/views/manage/components/details-header'
import { NanoId12 } from 'sierra-domain/api/nano-id'
import { FormElement, Input, MenuItem, PasswordInputPrimitive } from 'sierra-ui/components'
import { Button, Text, TextAreaPrimitive, View } from 'sierra-ui/primitives'
import { SingleSelectDropdown } from 'sierra-ui/primitives/menu-dropdown'
import styled from 'styled-components'
import { z } from 'zod'

const Container = styled(View).attrs({ direction: 'column', grow: true })`
  max-width: 40rem;
`

type XApiServerFormErrors = Partial<
  Record<keyof XApiServer, { i18nArgs?: [TranslationKey, Record<string, unknown>?] }>
>

type ManageXApiDetailsProps = {
  serverIdOrNew: NanoId12 | 'new'
}

const getFormErrors = (values: XApiServer): XApiServerFormErrors => {
  const isValidHttpsUrl = (value?: string): boolean => {
    if (value === undefined) return false
    if (!value.startsWith('https://')) return false // Checks it's HTTPS
    return z.string().url().safeParse(value).success // Checks it's an URL
  }

  const errors: XApiServerFormErrors = {}
  if (values.name === '') {
    errors.name = {}
  }

  // No checks for description
  // if (values.description === '') {
  //   errors.description = {}
  // }

  if (values.tokenUrl === '') {
    errors.tokenUrl = { i18nArgs: ['dictionary.required'] }
  } else if (!isValidHttpsUrl(values.tokenUrl)) {
    errors.tokenUrl = { i18nArgs: ['manage.x-api.errors.https'] }
  }

  if (values.statementsUrl === '') {
    errors.statementsUrl = { i18nArgs: ['dictionary.required'] }
  } else if (!isValidHttpsUrl(values.statementsUrl)) {
    errors.statementsUrl = { i18nArgs: ['manage.x-api.errors.https'] }
  }

  if (values.clientId === '') {
    errors.clientId = {}
  }

  if (values.name === '') {
    errors.name = {}
  }

  if (values.isNew && (values.clientSecret === undefined || values.clientSecret === '')) {
    errors.clientSecret = {}
  }

  if (values.actorType === undefined) {
    errors.actorType = { i18nArgs: ['dictionary.required'] }
  }

  return errors
}

export const ManageXApiDetails: React.FC<ManageXApiDetailsProps> = ({ serverIdOrNew }) => {
  const { t } = useTranslation()
  const notifications = useNotif()

  const { isLoading, error, xApiServer, createXApiServer, updateXApiServer, deleteXApiServer } =
    useManageXApiDetails(serverIdOrNew)
  const [action, setAction] = useState<undefined | { modal: 'delete' }>(undefined)
  const [xApiServerForm, setXApiServerForm] = useState<XApiServer>(getDefaultXApiServerValues(serverIdOrNew))
  const [formErros, setFormErrors] = useState<XApiServerFormErrors>({})

  const actorTypeMenuItems: MenuItem<'mbox' | 'account'>[] = useMemo(
    () => [
      { type: 'label', label: 'Mbox', id: 'mbox' } satisfies MenuItem<'mbox'>,
      {
        type: 'label',
        label: t('dictionary.account'),
        id: 'account',
      } satisfies MenuItem<'account'>,
    ],
    [t]
  )

  useEffect(() => {
    setXApiServerForm(xApiServer)
  }, [xApiServer])

  const handleSave = async (): Promise<void> => {
    const { serverId, clientSecret, isNew, ...rest } = xApiServerForm

    // Validate
    const errors = getFormErrors(xApiServerForm)
    setFormErrors(errors)
    if (Object.keys(errors).length > 0) return

    // Create
    if (isNew) {
      if (clientSecret === undefined) return // check for TS
      const success = await createXApiServer({ ...rest, clientSecret })
      if (!success) return
      notifications.push({ type: 'success', title: t('manage.x-api.notification.created'), body: '' })
      void getGlobalRouter().navigate({ to: '/settings/api' })
    } else {
      // Update
      if (serverId === undefined) return
      await updateXApiServer(serverId, { ...rest, clientSecret })
      notifications.push({ type: 'success', title: t('manage.x-api.notification.updated'), body: '' })
    }
  }

  // Form change
  const handleFormChange = <T extends keyof XApiServer, R extends XApiServer[T]>(
    field: T,
    value: R
  ): void => {
    setXApiServerForm(f => ({
      ...f,
      [field]: value,
    }))
  }

  //Flags
  const disableSave = isLoading || _.isEqual(xApiServer, xApiServerForm)

  return (
    <Container>
      <DetailsHeader
        backlink={{
          href: '/settings/api',
          label: 'manage.backlinks--api',
        }}
      />

      <View direction='column' grow gap='24'>
        <Input
          id='name'
          placeholder={t('dictionary.name')}
          label={t('dictionary.name')}
          required
          value={xApiServerForm.name}
          isError={formErros.name !== undefined}
          helperText={formErros.name?.i18nArgs !== undefined ? t(...formErros.name.i18nArgs) : undefined}
          onChange={e => handleFormChange('name', e.target.value)}
          spacing='none'
        />

        <FormElement
          label={t('dictionary.description')}
          isError={formErros.description !== undefined}
          helper={
            formErros.description?.i18nArgs !== undefined ? t(...formErros.description.i18nArgs) : undefined
          }
        >
          <TextAreaPrimitive
            id='description'
            placeholder={t('dictionary.description')}
            // required
            value={xApiServerForm.description}
            rows={3}
            autoExpand
            onChange={e => handleFormChange('description', e.target.value)}
          />
        </FormElement>

        <Input
          id='tokenUrl'
          placeholder={t('manage.x-api.token-url')}
          label={t('manage.x-api.token-url')}
          required
          value={xApiServerForm.tokenUrl}
          isError={formErros.tokenUrl !== undefined}
          helperText={
            formErros.tokenUrl?.i18nArgs !== undefined ? t(...formErros.tokenUrl.i18nArgs) : undefined
          }
          onChange={e => handleFormChange('tokenUrl', e.target.value)}
          spacing='none'
        />

        <Input
          id='statementsUrl'
          placeholder={t('manage.x-api.statements-url')}
          label={t('manage.x-api.statements-url')}
          required
          value={xApiServerForm.statementsUrl}
          isError={formErros.statementsUrl !== undefined}
          helperText={
            formErros.statementsUrl?.i18nArgs !== undefined
              ? t(...formErros.statementsUrl.i18nArgs)
              : undefined
          }
          onChange={e => handleFormChange('statementsUrl', e.target.value)}
          spacing='none'
        />

        <Input
          id='clientId'
          placeholder={t('manage.x-api.client-id')}
          label={t('manage.x-api.client-id')}
          required
          value={xApiServerForm.clientId}
          isError={formErros.clientId !== undefined}
          helperText={
            formErros.clientId?.i18nArgs !== undefined ? t(...formErros.clientId.i18nArgs) : undefined
          }
          onChange={e => handleFormChange('clientId', e.target.value)}
          spacing='none'
        />

        <FormElement
          label={t('manage.x-api.actor-type')}
          isError={formErros.actorType !== undefined}
          helper={
            formErros.actorType?.i18nArgs !== undefined ? t(...formErros.actorType.i18nArgs) : undefined
          }
        >
          <SingleSelectDropdown
            selectedItem={actorTypeMenuItems.find(i => i.id === xApiServerForm.actorType)}
            onSelect={e => handleFormChange('actorType', e.id)}
            menuItems={actorTypeMenuItems}
          />
        </FormElement>

        <FormElement
          label={t('manage.x-api.client-secret')}
          isError={formErros.clientSecret !== undefined}
          helper={
            formErros.clientSecret?.i18nArgs !== undefined ? t(...formErros.clientSecret.i18nArgs) : undefined
          }
        >
          <PasswordInputPrimitive
            id='clientSecret'
            placeholder={'• • • • • • • • • • •'}
            required
            value={xApiServerForm.clientSecret ?? ''}
            onChange={e => handleFormChange('clientSecret', e.target.value)}
          />
        </FormElement>

        <View gap='small'>
          <Button disabled={disableSave} onClick={handleSave}>
            {xApiServerForm.isNew ? t('dictionary.add') : t('dictionary.update')}
          </Button>
          {!xApiServerForm.isNew && (
            <Button
              variant='destructive'
              onClick={() => setAction({ modal: 'delete' })}
              icon='trash-can'
              decoratorPosition='left'
            >
              {t('admin.delete')}
            </Button>
          )}
        </View>
        {error !== undefined && (
          <>
            <Text size='regular' color='redBright'>
              {t(...error.i18nArgs)}
            </Text>
          </>
        )}
      </View>
      <ActionModal
        open={action?.modal === 'delete'}
        onClose={() => setAction(undefined)}
        primaryAction={async () => {
          if (xApiServer.serverId === undefined) return
          await deleteXApiServer(xApiServer.serverId)
          void getGlobalRouter().navigate({ to: '/settings/api' })
        }}
        title={t('manage.x-api.confirmation.delete-title')}
        primaryActionLabel={t('dictionary.continue')}
        deleteAction
      >
        {t('manage.x-api.confirmation.delete-description')}
      </ActionModal>
    </Container>
  )
}
