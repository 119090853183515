import DOMPurify from 'dompurify'
import 'katex/dist/katex.min.css'
import React, { useMemo } from 'react'
import { editorGrid } from 'sierra-client/editor/layout'
import { useTranslation } from 'sierra-client/hooks/use-translation'
import { Toolbar, ToolbarIcon } from 'sierra-client/views/v3-author/block-toolbar'
import { removeNodeWithId } from 'sierra-client/views/v3-author/command'
import { BlockCommentIndicator } from 'sierra-client/views/v3-author/commenting/block-comment-indicator'
import {
  ListItemInnerCss,
  OrderedListItemCss,
  UnorderedListItemCss,
} from 'sierra-client/views/v3-author/list/list'
import { Prose } from 'sierra-client/views/v3-author/markdown/prose-styling'
import { renderMarkdown } from 'sierra-client/views/v3-author/markdown/render'
import { assertElementType } from 'sierra-client/views/v3-author/queries'
import { SlateFC, SlateWrapperProps } from 'sierra-client/views/v3-author/slate'
import { Markdown as MarkdownBlock } from 'sierra-domain/v3-author'
import { color } from 'sierra-ui/color'
import { Text as TextComponent } from 'sierra-ui/primitives'
import { spacing } from 'sierra-ui/theming'
import { fonts, h2Lesson, h3Lesson, h4Lesson } from 'sierra-ui/theming/fonts'
import { Text } from 'slate'
import { useSelected, useSlateStatic } from 'slate-react'
import styled, { css } from 'styled-components'

const PreviewWrap = styled(TextComponent)`
  color: ${p => p.theme.home.textColor};

  &&& * {
    color: inherit;
  }

  && {
    margin-bottom: 0;

    white-space: normal; /* pre-wrap is needed in editing mode, but not in the preview */

    p {
      padding-top: 1rem;
    }

    code {
      font-family: 'JetBrainsMono', 'Monaco', 'Menlo', 'Fira code', 'Fira Mono', monospace;
    }

    /* Lists */
    ul,
    ol {
      margin: 0 !important;
      padding: 0 !important;
    }

    /* Bulleted list */
    ul {
      li {
        ${UnorderedListItemCss}
        ${ListItemInnerCss}
      }
    }

    /* Numbered lists */
    ol {
      li {
        ${OrderedListItemCss}
        counter-increment: ordinals;
        padding-left: 1rem;

        ${ListItemInnerCss}; /* Uses a data attribute instead */
        &:before {
          content: counter(ordinals) '.';
        }
      }
    }

    /* Headings */
    h1 {
      ${h2Lesson}
    }

    h2 {
      ${h3Lesson}
    }

    h3 {
      ${h4Lesson}
    }

    h4 {
      ${h4Lesson}
    }
  }
`

const MarkdownPreview: React.FC<{ children: MarkdownBlock['children'] }> = ({ children: [firstChild] }) => {
  const html = useMemo(() => {
    const textContent = Text.isText(firstChild) ? firstChild.text : ''
    return renderMarkdown(textContent)
  }, [firstChild])

  return (
    <PreviewWrap
      color='LEGACY_DEFAULT_TEXT_COLOR_REPLACE_ASAP'
      $level='regular'
      size='regular'
      contentEditable={false}
      dangerouslySetInnerHTML={{ __html: DOMPurify.sanitize(html) }}
    />
  )
}

const Content = styled(PreviewWrap)`
  position: relative;
  padding-top: 1rem;
  font-size: 1.2rem;
  line-height: 150%;
  color: ${p => p.theme.home.textColor};
  white-space: pre-wrap !important;
  cursor: text;
`

const MarkdownProse = styled(Prose)`
  position: relative;
  min-height: 50px;
  margin-bottom: ${spacing['4']};
  font-size: inherit !important;
  border-radius: 1rem;
`

const MarkdownContainer = styled.div<{
  $isSelected: boolean
  $readOnly: boolean
}>`
  position: relative;
  border-radius: ${p => p.theme.borderRadius['size-6']};
  ${editorGrid}

  ${p =>
    !p.$readOnly &&
    css`
      ${MarkdownProse}::after {
        content: 'Markdown';
        position: absolute;
        display: absolute;
        right: 0;
        top: 0;
        font-weight: ${fonts.weight.bold};
        ${fonts.body.small};
        color: ${color(p.theme.home.textColor).shift(0.2).opacity(0.8).toString()};
        opacity: ${p.$isSelected ? 1 : 0};
      }

      &:hover {
        background-color: ${color(p.theme.home.textColor).opacity(0.05).toString()};
        ${MarkdownProse}::after {
          opacity: 1;
        }
      }
    `}

  ${p =>
    p.$isSelected &&
    !p.$readOnly &&
    css`
      background-color: ${color(p.theme.home.textColor).opacity(0.1).toString()};
    `}
`
export const MarkdownWrapper = React.forwardRef<HTMLDivElement, SlateWrapperProps>(
  ({ children, ...props }, ref) => {
    const isSelected = useSelected()
    return (
      <MarkdownContainer $isSelected={isSelected} $readOnly={props.readOnly} {...props} ref={ref}>
        {children}
      </MarkdownContainer>
    )
  }
)

// This is required to acquire focus when clicking on the element
const AcquireFocus = styled.div`
  & > * {
    opacity: 0;
  }
  color: transparent;
  position: absolute;
  top: 0;
  right: 0;
  left: 0;
  bottom: 0;
`

export const Markdown: SlateFC = ({ element, children, readOnly, ...props }) => {
  assertElementType('markdown', element)
  const { t } = useTranslation()
  const editor = useSlateStatic()
  const showMarkdown = !useSelected() || readOnly

  return (
    <>
      <MarkdownProse {...props}>
        {showMarkdown ? (
          <MarkdownPreview {...element} />
        ) : (
          <Content color='LEGACY_DEFAULT_TEXT_COLOR_REPLACE_ASAP' $level='regular' size='regular'>
            {children}
          </Content>
        )}
        {showMarkdown && <AcquireFocus>{children}</AcquireFocus>}
        <BlockCommentIndicator element={element} />
      </MarkdownProse>

      {!readOnly && (
        <Toolbar elementId={element.id}>
          <ToolbarIcon
            tooltip={t('author.block-editor.remove')}
            iconId='trash-can'
            onClick={() => removeNodeWithId(editor, element.id)}
          />
        </Toolbar>
      )}
    </>
  )
}
