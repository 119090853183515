import { Dispatch, SetStateAction, useCallback, useMemo, useState } from 'react'
import { usePost } from 'sierra-client/hooks/use-post'
import { ContentFilter } from 'sierra-client/views/manage/content/utils/content-utils'
import {
  XRealtimeAdminCoursesDeleteCourseGroup,
  XRealtimeAdminCoursesDetachEdition,
} from 'sierra-domain/routes'

type UseManageContentHook = {
  filter: ContentFilter
  setFilter: Dispatch<SetStateAction<ContentFilter>>
  detachEdition: (editionId: string) => Promise<void>
  deleteCourseGroup: (courseGroupId: string) => Promise<void>
}

export const useManageContent = (): UseManageContentHook => {
  const { postWithUserErrorException } = usePost()

  const [filter, setFilter] = useState<UseManageContentHook['filter']>({
    contentClassification: undefined,
    tagIds: [],
  })

  const detachEdition = useCallback<UseManageContentHook['detachEdition']>(
    async courseEditionId => {
      await postWithUserErrorException(XRealtimeAdminCoursesDetachEdition, {
        courseEditionId,
      })
    },
    [postWithUserErrorException]
  )

  const deleteCourseGroup = useCallback<UseManageContentHook['deleteCourseGroup']>(
    async courseGroupId => {
      await postWithUserErrorException(XRealtimeAdminCoursesDeleteCourseGroup, {
        courseGroupId,
      })
    },
    [postWithUserErrorException]
  )

  return useMemo(
    () => ({
      filter,
      setFilter,
      detachEdition,
      deleteCourseGroup,
    }),
    [filter, setFilter, detachEdition, deleteCourseGroup]
  )
}
