import { Logger } from 'sierra-client/core/logging/logger'

type Identifier = `group_${string}`

type GroupPayload = {
  id?: string
  name?: string
  description?: string
}

export const groupCreate = Logger.trackLoggerWithExtra<GroupPayload, GroupPayload>(
  'group_static_create' satisfies Identifier,
  input => {
    return {
      ...input,
    }
  }
)

export const groupDelete = Logger.trackLoggerWithExtra<GroupPayload, GroupPayload>(
  'group_static_delete' satisfies Identifier,
  input => {
    return {
      ...input,
    }
  }
)

export const smartGroupCreate = Logger.trackLoggerWithExtra<GroupPayload, GroupPayload>(
  'group_smart_create' satisfies Identifier,
  input => {
    return {
      ...input,
    }
  }
)

export const smartGroupDelete = Logger.trackLoggerWithExtra<GroupPayload, GroupPayload>(
  'group_smart_delete' satisfies Identifier,
  input => {
    return {
      ...input,
    }
  }
)
