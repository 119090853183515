import { resolveTokenOrColor } from 'sierra-ui/color/token-or-color'
import { Icon } from 'sierra-ui/components'
import { token } from 'sierra-ui/theming'
import styled, { css } from 'styled-components'

export const ExpandIcon = styled(Icon)`
  color: ${token('foreground/primary').opacity(0.4)};
  margin-left: auto;
  transition: color 100ms cubic-bezier(0.25, 0.1, 0.25, 1);
`

export const HoverCSS = css<{ selected: boolean }>`
  &:hover {
    ${p =>
      !p.selected &&
      css`
        background-color: ${p => resolveTokenOrColor('org/primary', p.theme).opacity(0.08)};
      `}
    ${ExpandIcon} {
      color: ${token('foreground/primary').opacity(1)};
    }
  }
`

export const SidebarIcon = styled(Icon)`
  color: ${token('foreground/secondary')};
`

export const SelectedCSS = css<{ selected: boolean }>`
  ${p =>
    p.selected &&
    css`
      color: ${token('foreground/primary')};
      background-color: ${resolveTokenOrColor('org/primary', p.theme).opacity(0.08)};

      & ${SidebarIcon} {
        color: ${token('foreground/primary')};
      }

      & > p {
        color: ${token('foreground/primary')};
      }
    `};
`
