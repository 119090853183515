import { atom, useAtomValue, useSetAtom } from 'jotai'
import { useEffect } from 'react'

const editorContentHeightAtom = atom(1000)

export const useEditorScrollContainerHeight = (): number => useAtomValue(editorContentHeightAtom)

/**
 * Measure height of outer editor scroll container. (We don't care about the width at the moment.)
 */
export const MeasureEditorScrollContainer = ({
  editorScrollContainer,
}: {
  editorScrollContainer: HTMLElement | null
}): null => {
  const setHeight = useSetAtom(editorContentHeightAtom)

  useEffect(() => {
    // Note: Don't reset the height here. We want to keep the old value until we read a new one.
    if (editorScrollContainer === null) return

    const measure = (): void => {
      const newHeight = editorScrollContainer.clientHeight

      setHeight(newHeight)
    }

    measure()

    const resizeObserver = new ResizeObserver(measure)

    resizeObserver.observe(editorScrollContainer)

    return () => {
      resizeObserver.disconnect()
    }
  }, [editorScrollContainer, setHeight])

  return null
}
