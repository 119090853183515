/* We are positioning a second sidebar behind it which will only grow when the sidebar "sticks" */
import { spacingConfig } from 'sierra-client/features/collapsable-sidebar/items/spacing-config'
import { CollapsableSidebarVariations } from 'sierra-client/features/collapsable-sidebar/types'

/**** Sidebar layout ****/
export const innerBelowVariants: CollapsableSidebarVariations = {
  collapsed: () => ({ width: `${spacingConfig.size.collapsed}px` }),
  hovered: () => ({ width: `${spacingConfig.size.collapsed}px` }),
  sticky: () => ({ width: `${spacingConfig.size.full}px` }),
}

export const innerAboveVariants: CollapsableSidebarVariations = {
  collapsed: () => ({
    width: `${spacingConfig.size.collapsed}px`,
  }),
  hovered: () => ({
    width: `${spacingConfig.size.full}px`,
    boxShadow: '0px 0 24px 0px rgba(0, 0, 0, 0.078)',
  }),
  sticky: () => ({ width: spacingConfig.size.full }),
}

export const sidebarContentsVariants = {
  collapsed: {
    width: spacingConfig.size.collapsed,
  },
  hovered: {
    width: spacingConfig.size.full,
  },
  sticky: {
    width: spacingConfig.size.full,
  },
} satisfies CollapsableSidebarVariations

export const bottomContainerVariants: CollapsableSidebarVariations = {
  collapsed: () => ({
    width: `${spacingConfig.size.collapsed}px`,
  }),
  hovered: () => ({
    width: `${spacingConfig.size.full}px`,
  }),
  sticky: () => ({ width: `${spacingConfig.size.full}px` }),
}

/**** Items ****/
/* Icon + Text items */
// this should be the same x and duration than in the variants below
export const titleVariants: CollapsableSidebarVariations = {
  collapsed: { opacity: 0, x: -4, maxWidth: '0', transition: { duration: 0.2 } },
  hovered: { opacity: 1, x: 0, maxWidth: '100%', transition: { duration: 0.2 } },
  sticky: { opacity: 1, x: 0, maxWidth: '100%' },
}

/* Company logo */
// this should be the same x and duration than in the variants above
export const companyLogoVariants = {
  initial: { opacity: 0, x: -4 },
  animate: { opacity: 1, x: 0, transition: { duration: 0.2 } },
  exit: { opacity: 0, x: -4, transition: { duration: 0.2 } },
}

// Item text should fade out before shrinking but fade in after growing
export const headerItemVariants: CollapsableSidebarVariations = {
  collapsed: { transition: { when: 'afterChildren' } },
  hovered: { transition: { when: 'beforeChildren' } },
  sticky: { transition: { when: 'beforeChildren' } },
}

/* Create new button */
export const textVariants: CollapsableSidebarVariations = {
  collapsed: { opacity: 0, transition: { duration: 0.2 } },
  hovered: { opacity: 1, transition: { duration: 0.2 } },
  sticky: { opacity: 1 },
}

export const iconVariants: CollapsableSidebarVariations = {
  collapsed: { opacity: 1, transition: { duration: 0.2 } },
  hovered: { opacity: 0, transition: { duration: 0.2 } },
  sticky: { opacity: 0 },
}

/**** Misc ****/
/* Collapse icon */
// Toggle button should fade out before collapsing and after growing
export const collapseIconVariants: CollapsableSidebarVariations = {
  collapsed: { opacity: 0, transition: { duration: 0.2 } },
  hovered: { opacity: 1, transition: { duration: 0.2 } },
  sticky: { opacity: 1 },
}
