import { useCallback, useMemo, useRef, useState } from 'react'
import { usePost } from 'sierra-client/hooks/use-post'
import { Filter } from 'sierra-client/lib/filter'
import { useDownloadReport } from 'sierra-client/views/manage/reports/report-utils'
import { OutputFormat, ReportJobStatus } from 'sierra-domain/api/analytics-reporting'
import { XRealtimeAnalyticsReportsJobsList, XRealtimeAnalyticsReportsSubmitJob } from 'sierra-domain/routes'
import { asNonNullable } from 'sierra-domain/utils'

type HeatmapJob = {
  jobId: string
  status: ReportJobStatus
}

type StartDownloadParams = {
  query: string | undefined
  filter: Filter | undefined
  contentIds: string[]
  outputFormat: OutputFormat
}

type UseHeatmapReportJobState = {
  isLoading: boolean
  job: HeatmapJob | undefined
  startDownload: (params: StartDownloadParams) => Promise<void>
}

export const useHeatmapReportJobState = (): UseHeatmapReportJobState => {
  const { postWithUserErrorException } = usePost()
  const downloadReport = useDownloadReport()
  const [job, setJob] = useState<HeatmapJob | undefined>(undefined)
  const intervalRef = useRef<ReturnType<typeof setInterval> | undefined>(undefined)

  const startDownload = useCallback<UseHeatmapReportJobState['startDownload']>(
    async ({ query, filter, contentIds, outputFormat }) => {
      // Clear pending check
      clearInterval(intervalRef.current)
      intervalRef.current = undefined

      const res = await postWithUserErrorException(XRealtimeAnalyticsReportsSubmitJob, {
        reportId: 'heatmap-learner-progress',
        query,
        filter,
        contentIds,
        outputFormat,
      })

      setJob(res.job)

      const jobId = res.job.jobId

      const checkStatus = async (): Promise<void> => {
        const res2 = await postWithUserErrorException(XRealtimeAnalyticsReportsJobsList, {
          jobIds: [jobId],
        })

        const newJob = asNonNullable(res2.jobs[0])

        setJob(newJob)

        if (newJob.status === 'successful' || newJob.status === 'error' || newJob.status === 'no-data') {
          clearInterval(intervalRef.current)
          intervalRef.current = undefined

          if (newJob.status === 'successful') {
            void downloadReport(jobId)
          }
        }
      }

      intervalRef.current = setInterval(checkStatus, 2000)
    },
    [postWithUserErrorException, downloadReport]
  )

  return useMemo(
    () => ({
      isLoading: job?.status === 'pending' || job?.status === 'running',
      job,
      startDownload,
    }),
    [job, startDownload]
  )
}
