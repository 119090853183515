import { useEffect, useId, useMemo, useState } from 'react'
import { Pill } from 'sierra-client/components/common/pill'
import { useTranslation } from 'sierra-client/hooks/use-translation'
import { tagsDataSelectors } from 'sierra-client/state/content/selectors'
import { useDispatch, useSelector } from 'sierra-client/state/hooks'
import { fetchTagsData } from 'sierra-client/state/v2/tags-actions'
import { TagsPicker } from 'sierra-client/views/course-settings/components/tags-picker'
import { CreateTagModal } from 'sierra-client/views/manage/tags/create-tag-modal'
import { Tag } from 'sierra-domain/content/tag'
import { isDefined } from 'sierra-domain/utils'
import { FormElement, InputGroup } from 'sierra-ui/components'
import { Spacer, Text, View } from 'sierra-ui/primitives'
import { narrowDotSeparator } from 'sierra-ui/utils'

export const TagsInput: React.FC<{
  initialSelectionTagIds: string[]
  onChange: (_: string[]) => void
  disabled?: boolean
}> = ({ initialSelectionTagIds, onChange, disabled }) => {
  const { t } = useTranslation()
  const dispatch = useDispatch()

  const inputId = useId()

  const tags = useSelector(tagsDataSelectors.selectAll)
  const tagOptions: Tag[] = useMemo(() => {
    return tags.filter(s => s.data.name !== '')
  }, [tags])

  useEffect(() => {
    if (tags.length === 0) {
      void dispatch(fetchTagsData())
    }
  }, [dispatch, tags.length])

  const [openCreateSkillModal, setOpenCreateSkillModal] = useState(false)
  const [typedInput, setTypedInput] = useState('')

  const [selectedTagIds, _setSelectedTagIds] = useState<string[]>(initialSelectionTagIds)
  const setSelectedTagIds = (tagIds: string[]): void => {
    _setSelectedTagIds(tagIds)
    onChange(tagIds)
  }

  const selectedTags = useMemo(() => {
    return selectedTagIds.map(id => tagOptions.find(option => option.id === id)).filter(isDefined)
  }, [tagOptions, selectedTagIds])

  return (
    <>
      <InputGroup $spacing='none'>
        <FormElement label={t('table.skills')} htmlFor={inputId}>
          <TagsPicker
            id={inputId}
            disabled={disabled}
            options={tagOptions}
            selected={selectedTags}
            onChange={selectedTags => setSelectedTagIds(selectedTags.map(s => s.id))}
            inputValue={typedInput}
            onInputChange={setTypedInput}
            inputPlaceholder={t('author.course-settings.skill-placeholder')}
            noResultElement={() => (
              <View
                grow
                gap='none'
                direction='column'
                onClick={() => {
                  setOpenCreateSkillModal(true)
                }}
              >
                <View gap='none' alignItems='flex-start'>
                  <Text color='foreground/muted' size='small'>
                    {t('course-settings.no-matches')} {narrowDotSeparator}
                  </Text>
                  <Spacer size='4' />
                  <Text color='foreground/primary' size='small'>
                    {t('course-settings.create-skill')}
                  </Text>
                </View>
                <Spacer size='xxsmall' />
                <Pill active={false} variant='outlined'>
                  {typedInput}
                </Pill>
              </View>
            )}
          />
        </FormElement>
      </InputGroup>
      <CreateTagModal
        open={openCreateSkillModal}
        onClose={() => setOpenCreateSkillModal(false)}
        onAfterSave={newTagId => {
          setSelectedTagIds([...selectedTagIds, newTagId])
          setTypedInput('')
          setOpenCreateSkillModal(false)
        }}
        defaultTagName={typedInput}
      />
    </>
  )
}
