import React from 'react'
import { RouterLink } from 'sierra-client/components/common/link'
import { UsersToNotifyText } from 'sierra-client/components/homework/users-to-notify-text'
import { useLocalizedFormatters } from 'sierra-client/core/format'
import { useTranslation } from 'sierra-client/hooks/use-translation'
import {
  GradeText,
  HomeworkFileIconWithImage,
  LightText,
  parseStringToJsonContent,
} from 'sierra-client/views/v3-author/homework/homework-atoms'
import { ReviewComment } from 'sierra-client/views/v3-author/homework/homework-upload/review-comment'
import { ReviewCriteriaList } from 'sierra-client/views/v3-author/homework/homework-upload/review-criteria-list'
import { isGradeFailed } from 'sierra-domain/api/homework'
import { SubmissionForUser } from 'sierra-domain/homework'
import { isDefined, isNonEmptyJsonString } from 'sierra-domain/utils'
import { Icon, Tooltip, TruncatedText } from 'sierra-ui/components'
import { FreeTextEditor } from 'sierra-ui/missions/workflows/free-text-editor'
import { Button, ScrollView, Text, View } from 'sierra-ui/primitives'
import { palette, token } from 'sierra-ui/theming'
import { narrowDotSeparator } from 'sierra-ui/utils'
import styled from 'styled-components'

const GradeTextWrapper = styled(View)`
  justify-content: flex-end;
  min-width: 120px;
`

const ReviewCommentView = styled(View).attrs({
  direction: 'column',
  gap: 'xxsmall',
  paddingTop: 'small',
  paddingBottom: 'small',
})`
  width: fit-content;
`

const ViewButton = styled(View)`
  cursor: pointer;
`

const ButtonText = styled(Text)`
  color: ${palette.primitives.black};
  opacity: 0.4;
  transition: all 0.1s cubic-bezier(0.25, 0.1, 0.25, 1);
  &:hover {
    opacity: 1;
  }
`

const StyledView = styled(View)`
  position: relative;
  bottom: 0;
`

const Separator = styled(View)`
  align-self: center;
  height: 1px;
  width: 100%;
  background-color: ${palette.grey[5]};
  border: 0;
`

export const StyledRouterLink = styled(RouterLink)`
  display: flex;
  width: 24px;
  height: 24px;
  background-color: transparent;
  border-radius: 6px;

  align-items: center;
  justify-content: center;
  transition: all 150ms cubic-bezier(0.25, 0.1, 0.25, 1);
  cursor: pointer;

  &:hover {
    background: ${palette.grey[5]};
    & > * {
      color: ${palette.primitives.black};
    }
  }
`

const FreeTextContainer = styled(ScrollView)<{ $preview: boolean }>`
  max-height: ${p => (p.$preview ? '450px' : '100px')};
`

export const SubmissionWrapper = styled(View).attrs({
  direction: 'column',
})`
  background-color: ${token('surface/default')};
  border-radius: ${p => p.theme.borderRadius['size-12']};
  border: 1px solid ${token('border/default')};
  box-shadow: 0px 4px 12px rgba(0, 0, 0, 0.04);
  overflow: hidden;
`

// HACK: this is just to align the text. Should do it better
const UsersNotifiedTextWrapper = styled(View)`
  margin-left: 22px;
  max-width: 440px;
`

export const OldSubmission: React.FC<{
  isOpen: boolean
  submission: SubmissionForUser
  allowSubmission: boolean
  submissionsLeft: number | undefined
  setOpenSubmissionId: (submissionId: string | undefined) => void
}> = ({ isOpen, submission, allowSubmission, setOpenSubmissionId, submissionsLeft }) => {
  const { t } = useTranslation()
  const { formatTimestamp } = useLocalizedFormatters()

  const { createdAt, reviewInfo } = submission
  const fileInfo = 'fileInfo' in submission ? submission.fileInfo : undefined

  return (
    <SubmissionWrapper
      key={submission.submissionId}
      animated
      initial={{ opacity: 0 }}
      animate={{ opacity: 1 }}
      exit={{ opacity: 0 }}
      layout='position'
      paddingTop='small'
      gap='none'
    >
      <View direction='column' gap='12' paddingLeft='small' paddingRight='small'>
        {isOpen === true && (
          <Text size='small' bold>
            {t('content.homework.your-submission')}
          </Text>
        )}
        <View gap='16' grow alignItems='flex-start'>
          {isDefined(fileInfo) && <HomeworkFileIconWithImage fileInfo={fileInfo} />}

          <View grow direction='column' gap='4'>
            {'text' in submission ? (
              <FreeTextContainer disableScrollbarGutter $preview={isOpen}>
                <FreeTextEditor content={parseStringToJsonContent(submission.text)} editable={false} />
              </FreeTextContainer>
            ) : (
              <TruncatedText lines={1} size='small' bold>
                {fileInfo?.fileName ?? ''}
              </TruncatedText>
            )}

            <View gap='8' paddingBottom='small'>
              <LightText size='small'>Submitted {formatTimestamp(createdAt)}</LightText>

              {isOpen !== true && (
                <>
                  <Text size='small' color='grey40'>
                    {narrowDotSeparator}
                  </Text>
                  <ViewButton
                    onClick={() => {
                      setOpenSubmissionId(submission.submissionId)
                    }}
                  >
                    <ButtonText color='LEGACY_DEFAULT_TEXT_COLOR_REPLACE_ASAP' bold size='small'>
                      {t('dictionary.view')}
                    </ButtonText>
                  </ViewButton>
                </>
              )}
            </View>
          </View>

          <View alignItems='center'>
            {reviewInfo.gradeStatus !== undefined && isOpen !== true && (
              <GradeTextWrapper>
                <GradeText gradeStatus={reviewInfo.gradeStatus} />
              </GradeTextWrapper>
            )}
            {isOpen === true && fileInfo !== undefined && (
              <Tooltip title={t('dictionary.view')}>
                <StyledRouterLink href={fileInfo.url} target='_blank' rel='noopener noreferrer'>
                  <Icon iconId='view' color='grey40' />
                </StyledRouterLink>
              </Tooltip>
            )}
          </View>
        </View>
      </View>
      {isNonEmptyJsonString(reviewInfo.feedback) && isOpen === true && (
        <View direction='column' gap='none' paddingLeft='small' paddingRight='small'>
          <Separator />
          <ReviewCommentView>
            <Text size='small' bold>
              {t('dictionary.comment-singular')}
            </Text>
            <ReviewComment feedback={reviewInfo.feedback} reviewerId={reviewInfo.reviewerId} />
          </ReviewCommentView>
        </View>
      )}
      {reviewInfo.criteriaValues.length > 0 && (
        <ReviewCriteriaList criteriaValues={reviewInfo.criteriaValues} />
      )}
      {isOpen === true && (
        <>
          <Separator />
          <View
            direction='column'
            gap='none'
            paddingLeft='small'
            paddingRight='small'
            paddingBottom='small'
            paddingTop='24'
            background={reviewInfo.gradeStatus === 'passed' ? 'greenPastel' : 'surface/default'}
          >
            <StyledView
              justifyContent='space-between'
              grow
              background={reviewInfo.gradeStatus === 'passed' ? 'greenPastel' : 'surface/default'}
            >
              {reviewInfo.gradeStatus !== undefined && (
                <View>
                  <GradeText gradeStatus={reviewInfo.gradeStatus} />
                </View>
              )}
              <View gap='12'>
                {isDefined(submissionsLeft) && submissionsLeft > 0 && (
                  <Text size='micro' color='foreground/muted'>
                    {t('homework.n-attempts-left', { count: submissionsLeft })}
                  </Text>
                )}
                {allowSubmission === true && reviewInfo.gradeStatus === 'failed' && (
                  <Button icon='restart' onClick={() => setOpenSubmissionId(undefined)}>
                    {t('content.homework.submit-new')}
                  </Button>
                )}
              </View>
            </StyledView>
            {isGradeFailed(reviewInfo.gradeStatus) && submissionsLeft === 0 && (
              <UsersNotifiedTextWrapper>
                <UsersToNotifyText />
              </UsersNotifiedTextWrapper>
            )}
          </View>
        </>
      )}
    </SubmissionWrapper>
  )
}
