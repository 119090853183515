import { Text } from 'sierra-ui/primitives'
import styled from 'styled-components'

const AvatarContainer = styled.div`
  width: calc(100% - 6rem);
  display: flex;
  align-items: center;
  padding-right: 1rem;
`

const FileInput = styled.input`
  position: absolute;
  width: 100%;
  height: 100%;
  left: 0;
  top: 0;
  opacity: 0;
  cursor: pointer;
  z-index: 1;
`

const FileButtonContainer = styled.div`
  position: relative;
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 100%;
  padding-top: 1.5rem;
  z-index: 0;
  transition: 0.2s ease color;
`

const AvatarLabel = styled(Text).attrs({
  color: 'grey35',
  size: 'small',
  bold: true,
})`
  width: 100%;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  margin-left: 1rem;
`

export const FileButton = styled(({ optionalLabel, className, label, children, innerRef, ...props }) => (
  <FileButtonContainer>
    <AvatarContainer>
      {children}
      <AvatarLabel>{label}</AvatarLabel>
    </AvatarContainer>
    <Text color='grey35' size='small'>
      {optionalLabel}
    </Text>
    <FileInput ref={innerRef} {...props} />
  </FileButtonContainer>
))``
