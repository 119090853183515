import { createFileRoute } from '@tanstack/react-router'

import { PageIdentifier } from 'sierra-client/layout/sana-page'
import { useTagsEnabled } from 'sierra-client/views/manage/permissions/use-tags-enabled'
import { ManageTags } from 'sierra-client/views/manage/tags/manage-tags'
import { ManagePageWrapper } from 'sierra-client/views/manage/utils/manage-page-wrapper'

// Note: ManagePageWrapper handles the role check.
function ManageSkillsPage(): JSX.Element | null {
  const tagsAvailable = useTagsEnabled()

  if (!tagsAvailable) {
    // todo(workflows): Redirect after initializing

    return null
  }

  return (
    <ManagePageWrapper pageIdentifier={PageIdentifier.ManageTags()}>
      <ManageTags />
    </ManagePageWrapper>
  )
}

const RouteComponent = ManageSkillsPage

export const Route = createFileRoute('/manage/tags/')({
  component: RouteComponent as React.FC,
})
