import React from 'react'
import { CustomInterfaceThemeProvider } from 'sierra-client/components/common/custom-interface-theme-provider'
import { ConfirmationModalProvider } from 'sierra-client/components/common/modals/confirmation-modal'
import { useRequiredRouterEditorIds } from 'sierra-client/hooks/use-router-ids'
import { IntercomLauncherVisibility } from 'sierra-client/intercom/intercom-visibility'
import { PageIdentifier, SanaPage } from 'sierra-client/layout/sana-page'
import { FCC } from 'sierra-client/types'
import { ContentModelCreatePage } from 'sierra-client/views/flexible-content/create-page'
import { useIsCreatePageContextLoading } from 'sierra-client/views/flexible-content/create-page-context'
import { LiveContentId, SelfPacedContentId } from 'sierra-domain/api/nano-id'
import { View } from 'sierra-ui/primitives'
import styled, { createGlobalStyle } from 'styled-components'

const GlobalStyles = createGlobalStyle`
    #__next {
        height: 100%;
        overflow: hidden;
    }
`

const PageRoot: FCC = ({ children }) => {
  const { mode, contentId, nodeId } = useRequiredRouterEditorIds()
  if (mode === 'live') {
    const flexibleContentId = LiveContentId.parse(contentId)
    return (
      <SanaPage mode='light' page={PageIdentifier.CreateFlexibleContent({ flexibleContentId, nodeId })}>
        {children}
      </SanaPage>
    )
  } else {
    const selfPacedContentId = SelfPacedContentId.parse(contentId)
    return (
      <SanaPage mode='light' page={PageIdentifier.CreateSelfPacedContent({ selfPacedContentId, nodeId })}>
        {children}
      </SanaPage>
    )
  }
}

const CreatePageWrapper = styled(View).attrs({ direction: 'column', gap: 'none', grow: true })`
  max-height: 100%;
`

export const CreatePageFull: React.FC = (): JSX.Element => {
  const isLoaded = !useIsCreatePageContextLoading()

  return (
    <>
      <GlobalStyles />
      <IntercomLauncherVisibility visibility='hidden' />

      <CreatePageWrapper>
        <CustomInterfaceThemeProvider>
          <ConfirmationModalProvider>
            <PageRoot>{isLoaded && <ContentModelCreatePage />}</PageRoot>
          </ConfirmationModalProvider>
        </CustomInterfaceThemeProvider>
      </CreatePageWrapper>
    </>
  )
}
