import { createContext, useContext } from 'react'
import { LiveSessionId } from 'sierra-domain/api/nano-id'

type LivePageContext = {
  liveSessionId: LiveSessionId
}

const LivePageContextObj = createContext<LivePageContext | undefined>(undefined)

export const LivePageContextProvider = LivePageContextObj.Provider

export const useLivePageContext = (): LivePageContext => {
  const context = useContext(LivePageContextObj)

  if (context === undefined) {
    throw new Error('This component must be wrapped in a LivePageContextProvider')
  }

  return context
}
