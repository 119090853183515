import React from 'react'
import { getFlag } from 'sierra-client/config/global-config'
import { useOrganizationPermissions } from 'sierra-client/hooks/use-permissions'
import { useTranslation } from 'sierra-client/hooks/use-translation'
import { useInsightsEnabled } from 'sierra-client/views/manage/hooks/use-insights-enabled'
import {
  NotificationHeader,
  NotificationInput,
} from 'sierra-client/views/user-settings/components/notification-input'
import {
  AccountSettingsFormConfig,
  HandleAccountSettingsFormChange,
  UserSettingsFormErrors,
} from 'sierra-client/views/user-settings/user-settings-utils'
import { AccountSettings } from 'sierra-domain/api/user'
import { NotificationType, UserNotificationSetting } from 'sierra-domain/user'
import { Spacer, View } from 'sierra-ui/primitives'
import { token } from 'sierra-ui/theming'
import styled from 'styled-components'

const Divider = styled.div`
  width: 100%;
  background-color: ${token('border/strong')};
  height: 1px;
`

type NotificationSettingsFormProps = {
  values: AccountSettings
  errors: UserSettingsFormErrors
  formConfig: AccountSettingsFormConfig
  notificationType: NotificationType
  onChange: HandleAccountSettingsFormChange
}

function areAnyDefined(...inputs: Array<UserNotificationSetting | undefined>): boolean {
  for (const input of inputs) {
    if (typeof input === 'undefined') {
      return false
    }
  }

  return true
}

export const NotificationSettingsForm: React.FC<NotificationSettingsFormProps> = ({
  values,
  notificationType,
  onChange,
}) => {
  const { t } = useTranslation()
  const recapEmailEnabled = getFlag('live/post-session-email')
  const insightsEnabled = useInsightsEnabled()

  const { notificationSettings: notificationSettingsValues } = values

  const orgPermissions = useOrganizationPermissions()
  const hasHomeworkPermission = orgPermissions.has('MANAGE_HOMEWORK')
  const hasInsightsPermission = orgPermissions.has('ACCESS_ANALYTICS')
  const hasGlobalCertPermission = orgPermissions.has('MANAGE_CERTIFICATES')

  const showAssignmentSection = areAnyDefined(
    notificationSettingsValues.contentAssigned,
    notificationSettingsValues.upcomingDueDate,
    notificationSettingsValues.alreadyOverdue
  )

  const showCertificatesSection =
    notificationType === 'email' &&
    areAnyDefined(
      notificationSettingsValues.certificateIssuedToUser,
      notificationSettingsValues.issuedCertificateExpiring,
      notificationSettingsValues.issuedCertificatesExpiringForIssuer
    )

  const showEventsSection = areAnyDefined(
    notificationSettingsValues.sessionAssigned,
    notificationSettingsValues.sessionUnassigned,
    notificationSettingsValues.upcomingSession,
    notificationType === 'email' ? notificationSettingsValues.calendarEventSelfEnrolled : undefined,
    notificationSettingsValues.calendarEventUpdated,
    notificationType === 'email' ? notificationSettingsValues.recapReady : undefined,
    notificationType === 'email'
      ? notificationSettingsValues.calendarEventEnrollmentRequestsPendingReview
      : undefined,
    notificationType === 'email'
      ? notificationSettingsValues.calendarEventEnrollmentRequestApproved
      : undefined
  )

  const showCreatorSection = areAnyDefined(
    notificationType === 'email' ? notificationSettingsValues.addedAsCollaborator : undefined,
    notificationType === 'email' ? notificationSettingsValues.addedAsCommenter : undefined,
    notificationSettingsValues.collaborationRequestAccess,
    notificationSettingsValues.mentionedInComment,
    notificationSettingsValues.threadRespondedTo
  )

  const showTeamspaceSection =
    notificationType === 'email' && areAnyDefined(notificationSettingsValues.teamspaceRequestAccess)

  const showExercisesSection =
    notificationType === 'email' &&
    areAnyDefined(
      notificationSettingsValues.homeworksAvailableForReview,
      notificationSettingsValues.receivedReview
    )

  const showInsightsSection = areAnyDefined(notificationSettingsValues.sharedDashboard)

  return (
    <View direction='column' alignItems='stretch' gap='24'>
      {showAssignmentSection && (
        <>
          <NotificationHeader iconId='course' label={t('settings.notification-categories.course.title')} />
          <NotificationInput
            label={t('settings.notifications.content-assigned')}
            notificationType={notificationType}
            value={notificationSettingsValues.contentAssigned}
            onChange={value => onChange('notificationSettings', 'contentAssigned', value)}
          />
          <NotificationInput
            label={t('settings.notifications.upcoming-due-date')}
            notificationType={notificationType}
            value={notificationSettingsValues.upcomingDueDate}
            onChange={value => onChange('notificationSettings', 'upcomingDueDate', value)}
          />
          <NotificationInput
            label={t('settings.notifications.already-overdue')}
            notificationType={notificationType}
            value={notificationSettingsValues.alreadyOverdue}
            onChange={value => onChange('notificationSettings', 'alreadyOverdue', value)}
          />
          <Divider />
        </>
      )}
      {showCertificatesSection && (
        <>
          <NotificationHeader
            iconId='medal'
            label={t('settings.notification-categories.certificate.title')}
          />
          <NotificationInput
            label={t('settings.notifications.certificate-issued-to-user')}
            notificationType={notificationType}
            value={notificationSettingsValues.certificateIssuedToUser}
            onChange={value => onChange('notificationSettings', 'certificateIssuedToUser', value)}
          />
          <NotificationInput
            label={t('settings.notifications.expiring-certificate')}
            notificationType={notificationType}
            value={notificationSettingsValues.issuedCertificateExpiring}
            onChange={value => onChange('notificationSettings', 'issuedCertificateExpiring', value)}
          />
          {hasGlobalCertPermission && (
            <NotificationInput
              label={t('settings.notifications.expiring-certificate-issuer')}
              notificationType={notificationType}
              value={notificationSettingsValues.issuedCertificatesExpiringForIssuer}
              onChange={value =>
                onChange('notificationSettings', 'issuedCertificatesExpiringForIssuer', value)
              }
            />
          )}
          <Divider />
        </>
      )}
      {showEventsSection && (
        <>
          <NotificationHeader iconId='location' label={t('settings.notification-categories.event.title')} />
          <NotificationInput
            label={t('settings.notifications.session-assigned')}
            notificationType={notificationType}
            value={notificationSettingsValues.sessionAssigned}
            onChange={value => onChange('notificationSettings', 'sessionAssigned', value)}
          />
          <NotificationInput
            label={t('settings.notifications.session-unassigned')}
            notificationType={notificationType}
            value={notificationSettingsValues.sessionUnassigned}
            onChange={value => onChange('notificationSettings', 'sessionUnassigned', value)}
          />
          <NotificationInput
            label={t('settings.notifications.upcoming-sessions')}
            notificationType={notificationType}
            value={notificationSettingsValues.upcomingSession}
            onChange={value => onChange('notificationSettings', 'upcomingSession', value)}
          />
          {notificationType === 'email' && (
            <NotificationInput
              label={t('settings.notifications.event-self-enrolled')}
              notificationType={notificationType}
              value={notificationSettingsValues.calendarEventSelfEnrolled}
              onChange={value => onChange('notificationSettings', 'calendarEventSelfEnrolled', value)}
            />
          )}
          <NotificationInput
            label={t('settings.notifications.session-updated')}
            notificationType={notificationType}
            value={notificationSettingsValues.calendarEventUpdated}
            onChange={value => onChange('notificationSettings', 'calendarEventUpdated', value)}
          />
          {recapEmailEnabled === true && notificationType === 'email' && (
            <NotificationInput
              label={t('settings.notifications.recap-ready')}
              notificationType={notificationType}
              value={notificationSettingsValues.recapReady}
              onChange={value => onChange('notificationSettings', 'recapReady', value)}
            />
          )}
          {notificationType === 'email' && (
            <NotificationInput
              label={t('settings.notifications.event-enrollment-requests-pending')}
              notificationType={notificationType}
              value={notificationSettingsValues.calendarEventEnrollmentRequestsPendingReview}
              onChange={value =>
                onChange('notificationSettings', 'calendarEventEnrollmentRequestsPendingReview', value)
              }
            />
          )}
          {notificationType === 'email' && (
            <NotificationInput
              label={t('settings.notifications.event-enrollment-request-update')}
              notificationType={notificationType}
              value={notificationSettingsValues.calendarEventEnrollmentRequestApproved}
              onChange={value => {
                onChange('notificationSettings', 'calendarEventEnrollmentRequestApproved', value)
                onChange('notificationSettings', 'calendarEventEnrollmentRequestRejected', value)
              }}
            />
          )}
          <Divider />
        </>
      )}
      {showCreatorSection && (
        <>
          <NotificationHeader iconId='edit' label={t('settings.notification-categories.creator.title')} />
          {notificationType === 'email' && (
            <NotificationInput
              label={t('settings.notifications.added-as-collaborator')}
              notificationType={notificationType}
              value={notificationSettingsValues.addedAsCollaborator}
              onChange={value => onChange('notificationSettings', 'addedAsCollaborator', value)}
            />
          )}
          {notificationType === 'email' && (
            <NotificationInput
              label={t('settings.notifications.added-as-commenter')}
              notificationType={notificationType}
              value={notificationSettingsValues.addedAsCommenter}
              onChange={value => onChange('notificationSettings', 'addedAsCommenter', value)}
            />
          )}
          <NotificationInput
            label={t('settings.notifications.collaboration-request')}
            notificationType={notificationType}
            value={notificationSettingsValues.collaborationRequestAccess}
            onChange={value => onChange('notificationSettings', 'collaborationRequestAccess', value)}
          />
          <NotificationInput
            label={t('settings.notifications.mentioned-in-comment')}
            notificationType={notificationType}
            value={notificationSettingsValues.mentionedInComment}
            onChange={value => onChange('notificationSettings', 'mentionedInComment', value)}
          />
          <NotificationInput
            label={t('settings.notifications.thread-responded-to')}
            notificationType={notificationType}
            value={notificationSettingsValues.threadRespondedTo}
            onChange={value => onChange('notificationSettings', 'threadRespondedTo', value)}
          />
          <Divider />
        </>
      )}
      {showTeamspaceSection && (
        <>
          <NotificationHeader
            iconId='teamspace'
            label={t('settings.notification-categories.teamspace.title')}
          />
          <NotificationInput
            label={t('settings.notifications.teamspace-request-access')}
            notificationType={notificationType}
            value={notificationSettingsValues.teamspaceRequestAccess}
            onChange={value => onChange('notificationSettings', 'teamspaceRequestAccess', value)}
          />
          <Divider />
        </>
      )}
      {showExercisesSection && (
        <>
          <NotificationHeader
            iconId='send--outline'
            label={t('settings.notification-categories.assignments.title')}
          />
          {hasHomeworkPermission && (
            <NotificationInput
              label={t('settings.notifications.exercise-available-for-review')}
              notificationType={notificationType}
              value={notificationSettingsValues.homeworksAvailableForReview}
              onChange={value => onChange('notificationSettings', 'homeworksAvailableForReview', value)}
            />
          )}
          <NotificationInput
            label={t('settings.notifications.exercise-reveiced-review')}
            notificationType={notificationType}
            value={notificationSettingsValues.receivedReview}
            onChange={value => onChange('notificationSettings', 'receivedReview', value)}
          />
          <Divider />
        </>
      )}
      {insightsEnabled && showInsightsSection && hasInsightsPermission && (
        <>
          <NotificationHeader
            iconId='chart--column'
            label={t('settings.notification-categories.insights.title')}
          />
          <NotificationInput
            label={t('settings.notifications.dashoard-shared')}
            notificationType={notificationType}
            value={notificationSettingsValues.sharedDashboard}
            onChange={value => onChange('notificationSettings', 'sharedDashboard', value)}
          />
        </>
      )}
      <Spacer />
    </View>
  )
}
