import _ from 'lodash'
import React, { useMemo } from 'react'
import { CellProps, Column, useGlobalFilter, useSortBy, useTable } from 'react-table'
import { RouterLink } from 'sierra-client/components/common/link'
import { SortableHeader } from 'sierra-client/components/table/sortable-header'
import { Table } from 'sierra-client/components/table/table'
import { useTranslation } from 'sierra-client/hooks/use-translation'
import { useDispatch } from 'sierra-client/state/hooks'
import { TableContainer } from 'sierra-client/views/manage/components/common'
import { JobStatus } from 'sierra-client/views/manage/reports/components/job-status'
import { reportsViewInInsightsLogger } from 'sierra-client/views/manage/reports/logger'
import { useGetReportDescription, useGetReportTitle } from 'sierra-client/views/manage/reports/report-utils'
import { Report, ReportJob } from 'sierra-domain/api/analytics-reporting'
import { IconButton, View } from 'sierra-ui/primitives'

type ReportsTableProps = {
  onClick: (report: Report) => void
  reports: Report[]
  jobs: ReportJob[]
}

type ReportWithLatestJob = Report & {
  latestJob?: ReportJob
}

export const ReportsTable: React.FC<ReportsTableProps> = ({ onClick, reports, jobs }) => {
  const { t } = useTranslation()
  const getReportTitle = useGetReportTitle()
  const getReportDescription = useGetReportDescription()
  const dispatch = useDispatch()

  const reportLatestJob: Record<string, ReportJob> = useMemo(() => {
    const jobsArr = Object.values(jobs)
    const jobsSorted = _.sortBy(jobsArr, job => job.createdAt)

    return jobsSorted.reduce(
      (acc, job) => {
        acc[job.reportId] = job
        return acc
      },
      {} as Record<string, ReportJob>
    )
  }, [jobs])

  const reportsWithStatus = useMemo(
    () =>
      reports.map(report => ({
        ...report,
        latestJob: reportLatestJob[report.id],
      })),
    [reports, reportLatestJob]
  )

  const columns: Column<ReportWithLatestJob>[] = useMemo(() => {
    const columns = [
      {
        id: 'title',
        accessor: report => getReportTitle(report),
        Header: p => <SortableHeader label={t('table.name')} {...p} />,
        Cell: (p: CellProps<ReportWithLatestJob>) => {
          return <>{getReportTitle(p.row.original)}</>
        },
        width: '30%',
      },
      {
        accessor: 'description',
        Header: p => <SortableHeader label={t('table.description')} {...p} />,
        Cell: p => {
          return <>{getReportDescription(p.row.original)}</>
        },
        width: '55%',
      },
      {
        id: 'status',
        accessor: 'id',
        width: '10%',
        disableSortBy: true,
        disableGlobalFilter: true,
        Header: '',
        Cell: p => {
          const { latestJob: job } = p.row.original

          return job ? (
            <View justifyContent='flex-start'>
              <JobStatus job={job} />
            </View>
          ) : null
        },
      },
      {
        id: 'actions',
        accessor: 'id',
        width: '5%',
        disableSortBy: true,
        disableGlobalFilter: true,
        Header: '',
        Cell: p => (
          <View justifyContent='flex-end'>
            <IconButton
              variant='transparent'
              iconId='download'
              onClick={() => onClick(p.row.original)}
              tooltip='Configure and download'
            />
          </View>
        ),
      },
    ] satisfies Column<ReportWithLatestJob>[]

    const insightsColumn = {
      id: 'insights',
      accessor: 'id',
      width: '5%',
      disableSortBy: true,
      disableGlobalFilter: true,
      Header: '',
      Cell: p => {
        const reportId = p.row.original.id
        if (reportId === 'user-course-instance-progress') return null

        return (
          <View justifyContent='flex-end'>
            <RouterLink
              href={`/manage/insights/dashboards/templates/${reportId}`}
              onClick={() => {
                void dispatch(reportsViewInInsightsLogger({ reportId }))
              }}
            >
              <IconButton
                variant='transparent'
                iconId='launch'
                tooltip={t('manage.reports.view-in-insights')}
              />
            </RouterLink>
          </View>
        )
      },
    } satisfies Column<ReportWithLatestJob>

    columns.push(insightsColumn)

    return columns
  }, [t, onClick, getReportTitle, getReportDescription, dispatch])

  const tableInstance = useTable(
    { data: reportsWithStatus, columns: columns, autoResetGlobalFilter: false, autoResetSortBy: false },
    useGlobalFilter,
    useSortBy
  )

  return (
    <TableContainer>
      <Table
        tableInstance={tableInstance}
        getRowProps={row => ({
          onClick: () => onClick(row.original),
        })}
      />
    </TableContainer>
  )
}
