import { CurrentVideo } from 'sierra-client/components/liveV2/hooks/use-participant-picture-in-picture/types'
import { Logging } from 'sierra-client/core/logging'
import { useDispatch } from 'sierra-client/state/hooks'
import { useOnChanged } from 'sierra-ui/utils'

export const useTrackUsage = (currentVideo: CurrentVideo | undefined): void => {
  const dispatch = useDispatch()

  // Log when PiP is enabled and disabled
  useOnChanged((previous, current) => {
    if (previous === undefined && current !== undefined) {
      void dispatch(Logging.liveSession.pictureInPictureEnabled())
    } else if (previous !== undefined && current === undefined) {
      void dispatch(Logging.liveSession.pictureInPictureDisabled())
    }
  }, currentVideo)
}
