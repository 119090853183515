import { atom, useSetAtom } from 'jotai'
import _ from 'lodash'
import { useEffect } from 'react'

const scrollOffsetAtom = atom(0)

// Should only ever be active once. Registers a scroll listener on the body
// element and updates the scroll offset in Redux.
export function useScrollOffset(): void {
  const setScrollOffset = useSetAtom(scrollOffsetAtom)
  useEffect(() => {
    const updateScroll = _.throttle(() => setScrollOffset(window.scrollY), 150)

    window.addEventListener('scroll', updateScroll)

    return () => {
      // Reset to 0 once unmounted
      setScrollOffset(0)

      updateScroll.cancel()
      window.removeEventListener('scroll', updateScroll)
    }
  }, [setScrollOffset])
}
