import { capitalize } from 'lodash'
import { DateTime } from 'luxon'
import React, { useRef, useState } from 'react'
import { CSVLink } from 'react-csv'
import { useTranslation } from 'sierra-client/hooks/use-translation'
import { Button, IconButton } from 'sierra-ui/primitives'

type ExportCsvButtonProps<T extends Record<string, unknown>> =
  React.ButtonHTMLAttributes<HTMLButtonElement> & {
    fetchCsvData: () => Promise<T[]> | T[]
    filename: string
  }

export const getCsvFileName = (prefix: string): string => {
  const date = DateTime.now().toFormat('y-LL-dd-T')
  return `${prefix}-${date}.csv`
}

export const ExportCSVButton = <T extends Record<string, unknown>>({
  fetchCsvData,
  filename,
}: ExportCsvButtonProps<T>): JSX.Element => {
  const { t } = useTranslation()
  // CSVLink doesn't have types, so we have to do this.
  const csvLinkRef = useRef<any>(null)

  const [csvData, setCsvData] = useState<T[]>([])

  const downloadCSV = async (): Promise<void> => {
    const data = await fetchCsvData()
    setCsvData(data)
    setTimeout(() => {
      csvLinkRef.current?.link?.click()
    })
  }

  return (
    <>
      <CSVLink ref={csvLinkRef} data={csvData} filename={getCsvFileName(filename)} />
      <Button variant='transparent' icon='download' onClick={downloadCSV}>
        {capitalize(t('dictionary.download'))}
      </Button>
    </>
  )
}

export const ExportCSVIconButton = <T extends Record<string, unknown>>({
  fetchCsvData,
  filename,
}: {
  fetchCsvData: () => Promise<T[]> | T[]
  filename: string
}): JSX.Element => {
  const { t } = useTranslation()
  // CSVLink doesn't have types, so we have to do this.
  const csvLinkRef = useRef<any>(null)

  const [csvData, setCsvData] = useState<T[]>([])

  const downloadCSV = async (): Promise<void> => {
    const data = await fetchCsvData()
    setCsvData(data)
    setTimeout(() => {
      csvLinkRef.current?.link?.click()
    })
  }

  return (
    <span>
      <CSVLink ref={csvLinkRef} data={csvData} filename={getCsvFileName(filename)} />
      <IconButton
        iconId='download'
        onClick={downloadCSV}
        variant='transparent'
        color='foreground/muted'
        tooltip={`${t('manage.export')} .csv`}
      />
    </span>
  )
}
