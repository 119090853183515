import React, { useMemo } from 'react'
import { getFileContentImage, getNamespacedImage } from 'sierra-client/api/content'
import { config } from 'sierra-client/config/global-config'
import { useSelector } from 'sierra-client/state/hooks'
import { selectIsAssaAbloy } from 'sierra-client/state/organization/selectors'
import { Text } from 'sierra-ui/primitives'
import { v2_breakpoint } from 'sierra-ui/theming/breakpoints'
import { max_height_100dvh } from 'sierra-ui/utils'
import styled from 'styled-components'

export const Wrapper = styled.div<{ $backgroundImage?: string }>`
  background-color: ${p => p.theme.home.backgroundColor};
  background-image: url(${p => p.$backgroundImage});
  background-size: cover;
  background-position: center;
  width: 100%;
  min-height: 100%;
  padding-left: 0.5rem;
  padding-right: 0.5rem;

  @media screen and (min-width: ${v2_breakpoint.phone}) {
    padding-left: 1rem;
    padding-right: 1rem;
  }
`

export const Content = styled.div`
  margin: 0 auto;
  max-width: 40rem;
  height: 100%;
  ${max_height_100dvh}
  display: flex;
  flex-direction: column;
  align-items: center;
`

export const Centered = styled.div`
  width: 100%;
  max-width: 50ch;
  text-align: center;
  display: flex;
  flex-direction: column;

  /* Workaround for flex bug in Safari */
  flex-shrink: 0;
  flex-basis: auto;
`

export const ListContainer = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
  overflow-y: scroll;

  /* Padding for the scrollbar on touch devices */
  padding-right: 0.5rem;

  /* Hide scrollbar (there were some complaints about it being too intrusive on some browsers) */
  scrollbar-width: none;

  &::-webkit-scrollbar {
    display: none;
  }

  /* Some padding at the bottom in order to not fade the last row */

  &::after {
    content: '';
    display: block;
    width: 100%;
    min-height: 32px;
  }

  /* Based on https://pqina.nl/blog/fade-out-overflow-using-css-mask-image/ */
  --scrollbar-width: 1rem;
  --scrollbar-width: 0px;

  --mask-image-content: linear-gradient(to bottom, black, black calc(100% - 64px), transparent);
  --mask-size-content: calc(100% - var(--scrollbar-width)) 100%;

  --mask-image-scrollbar: linear-gradient(black, black);
  --mask-size-scrollbar: var(--scrollbar-width) 100%;

  mask-image: var(--mask-image-content), var(--mask-image-scrollbar);
  mask-size: var(--mask-size-content), var(--mask-size-scrollbar);
  mask-position:
    0 0,
    100% 0;
  mask-repeat: no-repeat, no-repeat;
`

export const ListItem = styled.div`
  display: flex;
  width: 100%;
  max-width: 100%;
  padding: 1rem 0;
  border-bottom: 1px solid rgba(255, 255, 255, 0.2);
  cursor: pointer;
  user-select: none;

  /* Workaround for flex bug in Safari */
  flex-shrink: 0;
  flex-basis: auto;
`

export const ItemTitle = styled(Text).attrs({ size: 'regular', bold: true })`
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
`

const LogoWrapper = styled.div`
  display: flex;
  width: 100%;
  align-items: center;
  justify-content: center;
`

const LogoImg = styled.img`
  max-height: 2.5rem;
  max-width: 5rem;
  vertical-align: middle;

  @media screen and (min-width: ${v2_breakpoint.tablet}) {
    max-height: 3rem;
  }
`

export const BrandLogo: React.FC = () => {
  const orgConfig = config.organization
  const isAssaAbloy = useSelector(selectIsAssaAbloy)

  const logoSrc = useMemo(() => {
    if (isAssaAbloy) {
      return getFileContentImage('images/assa-abloy-logo-white.png', { bucket: 'sierra-static' })
    }

    return orgConfig.settings.brand.logo === null
      ? undefined
      : getNamespacedImage({ type: 'organization-settings' }, orgConfig.settings.brand.logo, 'image', {
          width: 80,
        })
  }, [orgConfig, isAssaAbloy])

  if (logoSrc === undefined) return null

  return (
    <LogoWrapper>
      <LogoImg src={logoSrc} />
    </LogoWrapper>
  )
}
