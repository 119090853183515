import React from 'react'
import { PartialRecord } from 'sierra-client/components/liveV2/types'
import { useTranslation } from 'sierra-client/hooks/use-translation'
import { TranslationKey } from 'sierra-client/hooks/use-translation/types'
import { useIsMobile } from 'sierra-client/state/browser/selectors'
import { useSelector } from 'sierra-client/state/hooks'
import { selectAllCurrentIssues } from 'sierra-client/state/live/selectors'
import { FCC } from 'sierra-client/types'
import { IssueToTabMap } from 'sierra-client/views/liveV2/settings/issues'
import { LiveTabID } from 'sierra-client/views/liveV2/settings/live-tab-id'
import { color } from 'sierra-ui/color'
import { Icon } from 'sierra-ui/components'
import { Heading, Text, View } from 'sierra-ui/primitives'
import { palette, token } from 'sierra-ui/theming'
import { maxWidth } from 'sierra-ui/utils/media-query-styles'
import styled, { css } from 'styled-components'

export type LiveSettingsTabComponent = React.FC<{
  onClose: () => void
}>

export type LiveSettingsTabConfig = {
  id: LiveTabID
  label: TranslationKey
  component: LiveSettingsTabComponent
}

const TabContent = styled.div`
  width: 100%;
  height: auto;
  max-width: 28rem;
  margin-top: 3rem;

  ${maxWidth.phone} {
    margin-top: 0;
    padding-top: 1rem;
    border-top: 1px solid ${palette.grey[5]};
  }
`

const TabsColumn = styled(View).attrs({ direction: 'column', grow: true, paddingRight: 'xsmall' })`
  max-width: 13rem;
  min-width: 7rem;
  height: 100%;

  border-right: 1px solid ${palette.grey[5]};
`

const TabLink = styled(Text).attrs({ bold: true, size: 'small' })<{ isActive: boolean }>`
  ${p =>
    p.isActive
      ? css`
          color: ${token('foreground/primary')};
        `
      : css`
          color: ${token('foreground/muted')};
        `}
`

const TabItemWrapper = styled(View).attrs({
  padding: 'xxsmall',
  radius: 'small',
  cursor: 'pointer',
  justifyContent: 'space-between',
})<{
  isActive: boolean
}>`
  max-width: 12rem;
  position: relative;
  transition: background-color 150ms cubic-bezier(0.25, 0.1, 0.25, 1);

  ${p =>
    p.isActive
      ? css`
          background-color: ${color(p.theme.home.tabColor).opacity(0.05)};
          transition: none;
        `
      : css`
          &:hover {
            background-color: ${color(p.theme.home.tabColor).opacity(0.05)};
          }
        `}
`

type TabsProps = {
  onClick: (tabId: LiveTabID) => void
  current: LiveTabID
  tabs: PartialRecord<LiveTabID, LiveSettingsTabConfig>
}

const ResponsiveTabs: FCC<TabsProps> = ({ tabs, onClick, current, children }) => {
  const isMobile = useIsMobile()
  const { t, dynamicT } = useTranslation()
  const tabsWithNotifications = useSelector(selectAllCurrentIssues).map(
    issue => IssueToTabMap[issue.category]
  )

  if (isMobile) {
    return (
      <View direction='column' grow>
        <Heading bold size='h4' spacing='medium'>
          {t('dictionary.settings')}
        </Heading>
        <View direction='row'>
          {Object.entries(tabs).map(([key, tab]) => (
            <TabItemWrapper key={key} isActive={tab.id === current} onClick={() => onClick(tab.id)}>
              <TabLink isActive={tab.id === current}>{dynamicT(tab.label)}</TabLink>
              {tabsWithNotifications.includes(tab.id) && <Icon iconId='warning' color='redVivid' />}
            </TabItemWrapper>
          ))}
        </View>
        {children}
      </View>
    )
  }
  return (
    <View grow alignItems='flex-start' gap={'24'}>
      <TabsColumn>
        <Heading bold size='h4' spacing='medium'>
          {t('dictionary.settings')}
        </Heading>
        {Object.entries(tabs).map(([key, tab]) => (
          <TabItemWrapper grow key={key} isActive={tab.id === current} onClick={() => onClick(tab.id)}>
            <TabLink isActive={tab.id === current}>{dynamicT(`${tab.label}`)}</TabLink>
            {tabsWithNotifications.includes(tab.id) && <Icon iconId='warning' color='redVivid' />}
          </TabItemWrapper>
        ))}
      </TabsColumn>
      {children}
    </View>
  )
}

export const Tabs = ({ onClick, current, tabs }: TabsProps): JSX.Element => {
  const currentTab = tabs[current]

  return (
    <View grow>
      <ResponsiveTabs current={current} tabs={tabs} onClick={onClick}>
        {currentTab !== undefined && <TabContent>{React.createElement(currentTab.component)}</TabContent>}
      </ResponsiveTabs>
    </View>
  )
}
