import { DateTime } from 'luxon'
import { FC } from 'react'
import { graphql } from 'sierra-client/api/graphql/gql'
import { convertGQLAvatar, getAvatarUrl } from 'sierra-client/api/graphql/util/convert-gql-avatar'
import { useGraphQuery } from 'sierra-client/api/hooks/use-graphql-query'
import { TimelinePanelContent } from 'sierra-client/features/program/admin/user-timeline/timeline-panel-content'
import { useTranslation } from 'sierra-client/hooks/use-translation'
import { ProgramOutline, UserProgramVersion } from 'sierra-domain/api/manage'
import { ProgramId, UserId } from 'sierra-domain/api/uuid'
import { assertNever, iife, isDefined } from 'sierra-domain/utils'
import { RoundAvatar } from 'sierra-ui/components'
import { Skeleton, Text, View } from 'sierra-ui/primitives'
import styled from 'styled-components'

const PanelHeader = styled.div`
  display: flex;
  flex-direction: column;
  gap: 8px;
  padding-block: 32px;
`

const SkeletonContainer = styled.div`
  display: flex;
  flex-direction: column;
  gap: 50px;
  padding: 32px 0;
`

function formatVersion(version: UserProgramVersion): string {
  return DateTime.fromISO(version.updatedAt).toFormat('yyyy-MM-dd HH:mm')
}

const query = graphql(`
  query userTimelinePanelQuery($userId: UserId!, $programId: ProgramId!) {
    user(id: $userId) {
      firstName
      lastName
      displayName
      avatar {
        ...AvatarFragment
      }

      programSchedule(programId: $programId) {
        __typename

        availableAt
        dueDate {
          date
          source
        }

        ... on UserProgramCourseStep {
          contentId

          progress {
            progress
            contentId
            startedAt
            passedAt
          }

          content {
            contentId
            contentType
            title
            image {
              ...ImageFragment
            }
          }
          course {
            courseKind
          }
        }
        ... on UserProgramPathStep {
          contentId

          progress {
            progress
            contentId
            startedAt
            passedAt
          }

          content {
            contentId
            contentType
            title
            image {
              ...ImageFragment
            }
          }
        }

        ... on UserProgramEmailStep {
          resourceId
          title
          image {
            ...ImageFragment
          }
        }
      }

      enrollment(programId: $programId) {
        enrolledBy {
          __typename
        }
      }
    }
  }
`)

export const TimelinePanel: FC<{
  programOutline: ProgramOutline
  userId: UserId
  programId: ProgramId
  programVersionId: UserProgramVersion
}> = ({ programOutline, userId, programVersionId, programId }) => {
  const { t } = useTranslation()
  const timelineQuery = useGraphQuery({ document: query }, { userId, programId })

  if (!timelineQuery.isSuccess) {
    return (
      <SkeletonContainer>
        <Skeleton $width='100%' $height={50} />
        <Skeleton $width='100%' $height={400} />
      </SkeletonContainer>
    )
  }

  if (!isDefined(timelineQuery.data.user)) {
    // TODO: do we need a branch for this?
    return null
  }

  const { user } = timelineQuery.data
  const avatar = convertGQLAvatar(timelineQuery.data.user.avatar)

  return (
    <View direction='column' gap='8'>
      <PanelHeader>
        <View gap='16'>
          {iife(() => {
            switch (avatar.type) {
              case 'color': {
                return (
                  <RoundAvatar
                    color={avatar.color}
                    firstName={user.firstName ?? ''}
                    lastName={user.lastName ?? ''}
                  />
                )
              }
              case 'image': {
                return (
                  <RoundAvatar
                    src={getAvatarUrl(userId, avatar)}
                    firstName={user.firstName ?? ''}
                    lastName={user.lastName ?? ''}
                  />
                )
              }
              default:
                assertNever(avatar)
            }
          })}

          <Text bold size='large'>
            {user.displayName}
          </Text>
        </View>

        <Text color='foreground/secondary' size='regular'>
          {t('manage.programs.version-from-date', { date: formatVersion(programVersionId) })}
        </Text>
      </PanelHeader>

      <TimelinePanelContent
        outline={programOutline}
        userWithProgramSchedule={timelineQuery.data.user}
        programId={programId}
      />
    </View>
  )
}
