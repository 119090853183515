import clipboardCopy from 'clipboard-copy'
import React, { useCallback } from 'react'
import { useNotif } from 'sierra-client/components/common/notifications'
import { useTranslation } from 'sierra-client/hooks/use-translation'
import { useCachedQuery } from 'sierra-client/state/api'
import { getBaseUrl } from 'sierra-client/views/manage/utils'
import { XRealtimeAdminGroupsGroupInviteToken } from 'sierra-domain/routes'
import { isNullable, noOp } from 'sierra-domain/utils'
import { Tooltip } from 'sierra-ui/components'
import { Button, InputPrimitive, Text, View } from 'sierra-ui/primitives'
import styled from 'styled-components'

const CapText = styled(Text)`
  &:first-letter {
    text-transform: capitalize;
  }
`
const CapBtn = styled(Button)`
  *:first-letter {
    text-transform: capitalize;
  }
`

const _CopyLink: React.FC<{ link: string }> = ({ link }) => {
  const { t } = useTranslation()
  const { push } = useNotif()

  const copy = useCallback(
    (link2Copy: string) => {
      clipboardCopy(link2Copy)
        .then(() => {
          push({ type: 'custom', level: 'success', body: t('clipboard.success.text') })
        })
        .catch(() => {
          push({ type: 'custom', level: 'error', body: t('clipboard.error.failed-to-copy') })
        })
    },
    [push, t]
  )

  return (
    <View direction='column' gap='8'>
      <CapText bold size='regular'>
        {t('dictionary.link')}
      </CapText>
      <View>
        <InputPrimitive onChange={noOp} disabled value={link} />
        <Tooltip title={t('dictionary.copy-to-clipboard')}>
          <CapBtn onClick={() => copy(link)}>{t('dictionary.copy')}</CapBtn>
        </Tooltip>
      </View>
    </View>
  )
}

export const CopyLink: React.FC<{ groupId: string }> = ({ groupId }) => {
  const { data: link } = useCachedQuery(
    XRealtimeAdminGroupsGroupInviteToken,
    { groupId },
    { select: ({ token }) => `${getBaseUrl()}/group-invite/${token}` }
  )

  if (isNullable(link)) {
    return null
  }

  return <_CopyLink link={link} />
}
