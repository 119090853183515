import {
  MetadataWithIcon,
  NotificationContainer,
  Title,
} from 'sierra-client/features/activity-center/notifications/components'
import { EntityImage } from 'sierra-client/features/activity-center/notifications/components/entity-image'
import { NotificationIcon } from 'sierra-client/features/activity-center/notifications/components/notification-icon'
import { NotificationTitle } from 'sierra-client/features/activity-center/notifications/components/notification-title'
import { useSelector } from 'sierra-client/state/hooks'
import { selectUser } from 'sierra-client/state/user/user-selector'
import { HomeNotification } from 'sierra-domain/api/learn'
import { assertIsNonNullable } from 'sierra-domain/utils'
import { View } from 'sierra-ui/primitives'

export const CalendarEventAssignedNotification: React.FC<{
  notification: Extract<HomeNotification, { type: 'calendar-event-assigned' }>
}> = ({ notification }) => {
  const entity = notification.calendarEvent

  const currentUser = useSelector(selectUser)
  assertIsNonNullable(currentUser)

  return (
    <NotificationContainer
      notification={notification}
      icon={<NotificationIcon iconId='location' />}
      title={<NotificationTitle untranslated='New assignment' />}
      content={
        <>
          <EntityImage fallback='event' />

          <View direction='column' gap='4'>
            <Title size='regular' bold>
              {entity.title}
            </Title>
            <MetadataWithIcon type='event' />
          </View>
        </>
      }
    />
  )
}
