import { atom, useSetAtom } from 'jotai'
import { useEffect, useState } from 'react'
import { useIntercom } from 'react-use-intercom'
import { useShortcutMenuDispatch } from 'sierra-client/components/shortcut-menu/context'
import { SearchBarContainer } from 'sierra-client/components/shortcut-menu/search-ui'
import { FormElement } from 'sierra-ui/components'
import { InputPrimitive } from 'sierra-ui/primitives'

export const intercomOverrideAtom = atom(false)

export const IntercomProductTourShortcut: React.FC = () => {
  const [id, setId] = useState<string>('')
  const intercom = useIntercom()
  const dispatch = useShortcutMenuDispatch()

  const setIntercomOverride = useSetAtom(intercomOverrideAtom)

  useEffect(() => {
    setIntercomOverride(true)
  }, [setIntercomOverride])

  return (
    <>
      <SearchBarContainer>
        <FormElement label='Enter Product Tour ID'>
          <InputPrimitive
            autoFocus={true}
            type='number'
            value={id}
            onChange={e => setId(e.target.value)}
            onKeyDown={e => {
              if (e.key === 'Enter') {
                dispatch({ type: 'close' })
                intercom.boot()
                intercom.startTour(parseInt(id))
              }
            }}
          />
        </FormElement>
      </SearchBarContainer>
    </>
  )
}
