import { FastAverageColor } from 'fast-average-color'
import { Duration } from 'luxon'
import { FC, useEffect, useRef, useState } from 'react'
import { VideoPlayer } from 'sierra-client/components/blocks/video'
import { VideoJsPlayer } from 'sierra-client/components/blocks/video/types'
import { UseSessionBotDataQueryResult } from 'sierra-client/features/sana-now/post-session-page/use-session-bot-data'
import { useTranslation } from 'sierra-client/hooks/use-translation'
import { GetLiveSessionBotDataResponse } from 'sierra-domain/api/live-session'
import { assertNever, iife } from 'sierra-domain/utils'
import { dynamicColor } from 'sierra-ui/color'
import { AiBackground, Icon } from 'sierra-ui/components'
import { LoaderAnimation, Spacer, Text, View } from 'sierra-ui/primitives'
import { token, useTokenValue } from 'sierra-ui/theming'
import styled, { css } from 'styled-components'

const ContainerBaseStyles = css`
  position: relative;
  overflow: hidden;
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 24px;
  padding: 64px 24px;
`

const SummaryContainer = styled.div`
  ${ContainerBaseStyles}
  background-color: ${token('surface/soft')};
`

const LoadingContainer = styled.div`
  ${ContainerBaseStyles}
`

const TextContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  max-width: 400px;
  gap: 8px;
`

type SummaryData = Extract<GetLiveSessionBotDataResponse, { type: 'completed' }>['data']

const BotNotEnabled = (): JSX.Element => {
  const { t } = useTranslation()
  return (
    <SummaryContainer>
      <TextContainer>
        <Text size='small' bold color='foreground/secondary'>
          {t('sana-now.post-session-page.summary.not-enabled-title-text')}
        </Text>
        <Text size='small' color='foreground/secondary' align='center'>
          {t('sana-now.post-session-page.summary.not-enabled-body-text')}
        </Text>
      </TextContainer>
    </SummaryContainer>
  )
}

const SummaryLoading = (): JSX.Element => {
  const { t } = useTranslation()

  return (
    <LoadingContainer>
      <AiBackground base='greenLight' />
      <TextContainer>
        <LoaderAnimation size={32} />
        <Spacer size='8' />
        <Text size='small' bold>
          {t('sana-now.post-session-page.summary.loading-title-text')}
        </Text>
        <Text size='small' align='center'>
          {t('sana-now.post-session-page.summary.loading-body-text')}
        </Text>
      </TextContainer>
    </LoadingContainer>
  )
}

const Mark = styled.div<{ $hidden?: boolean }>`
  flex-shrink: 0;
  width: 4px;
  height: 4px;
  background-color: ${p => (p.$hidden === true ? 'transparent' : token('foreground/primary'))};
  border-radius: 100%;
`

const UnorderedList = styled.ul`
  list-style-type: none;
  margin-left: 20px;
`

const UnorderedListItem = styled.li`
  margin-bottom: 24px;
`

const VideoPreviewContainer = styled.button<{ backgroundColor?: string }>`
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  padding: 20px 24px;
  position: relative;
  background-color: ${p => p.backgroundColor ?? token('surface/soft')};
  border-radius: 24px;
  width: 100%;
  aspect-ratio: 2/1;
  overflow: hidden;
  cursor: pointer;
`

const PosterImage = styled.div<{ url?: string }>`
  position: absolute;
  background-image: url(${p => p.url});
  background-size: cover;
  background-position: center;
  border-radius: 12px;
  overflow: hidden;
  width: 60%;
  aspect-ratio: 16/9;
  left: 50%;
  bottom: -12px;
  transform: translateX(-50%);
`

const PlayButton = styled.div`
  background: white;
  border-radius: 100%;
  width: 40px;
  height: 40px;
  display: flex;
  align-items: center;
  justify-content: center;
`

// To visually center the icon we need to shift it a bit to the right
const ShiftedIcon = styled(Icon)`
  margin-left: 2px;
`

const ColoredText = styled(Text)<{ posterColor?: string; faded?: boolean }>`
  color: ${p => {
    if (p.posterColor === undefined) return 'inherit'
    if (p.faded === true) {
      return dynamicColor(p.posterColor).brightness() > 0.7 ? 'rgba(0,0,0,0.3)' : 'rgba(255,255,255, 0.5)'
    }

    return dynamicColor(p.posterColor).brightness() > 0.7 ? 'black' : 'white'
  }};
`

const VideoWrapper = styled.div`
  width: 100%;
  height: 100%;
  background-color: ${p => p.theme.color.black};
  border-radius: 24px;
  overflow: hidden;
`

const VideoPreview: FC<{ data: SummaryData }> = ({ data }) => {
  const surfaceColor = useTokenValue()('surface/soft')
  const [primaryPosterColor, setPrimaryPosterColor] = useState<string>(surfaceColor)
  const [showVideo, setShowVideo] = useState(false)
  const playerRef = useRef<VideoJsPlayer | null>(null)
  const [videoDuration, setVideoDuration] = useState<Duration | undefined>()
  const { t } = useTranslation()

  useEffect(() => {
    const fac = new FastAverageColor()

    fac
      .getColorAsync(data.recordingPosterUrl, {
        algorithm: 'sqrt',
        ignoredColor: [
          [255, 255, 255, 255, 40], // white with threshold 40
          [154, 131, 119, 255, 50], // browns/beiges with threshold 50
        ],
      })
      .then(color => {
        setPrimaryPosterColor(color.hex)
      })
      .catch(() => {
        return
      })

    return () => fac.destroy()
  }, [data.recordingPosterUrl])

  const setVideoVisible = (): void => {
    setShowVideo(true)
    void playerRef.current?.play()
  }

  return (
    <>
      {!showVideo && (
        <VideoPreviewContainer backgroundColor={primaryPosterColor} onClick={setVideoVisible}>
          <PosterImage url={data.recordingPosterUrl} />
          <View direction='column' alignItems='flex-start' gap='2'>
            <ColoredText posterColor={primaryPosterColor} size='small' bold>
              {t('sana-now.post-session-page.summary.recording-placeholder--title')}
            </ColoredText>
            {videoDuration !== undefined && (
              <ColoredText faded posterColor={primaryPosterColor} size='small' bold>
                {videoDuration.toFormat('mm:ss')}
              </ColoredText>
            )}
          </View>
          <PlayButton>
            <ShiftedIcon color={'black'} iconId='play--filled' />
          </PlayButton>
        </VideoPreviewContainer>
      )}
      <VideoWrapper style={{ display: showVideo ? 'block' : 'none', aspectRatio: '2/1' }}>
        <VideoPlayer
          onLoadedMetadata={player => {
            const duration = player.player().duration()
            if (!Number.isNaN(duration)) setVideoDuration(Duration.fromMillis(duration * 1000))
            playerRef.current = player.player()
          }}
          options={{
            playsinline: true,
            autoplay: false,
            bigPlayButton: true,
            controls: true,
            controlBar: { pictureInPictureToggle: false },
            preload: 'auto',
            fill: true,
            playbackRates: [0.5, 0.75, 1, 1.25, 1.5, 1.75, 2, 5],
            sources: [{ src: data.recordingUrl, type: 'video/mp4' }],
            // poster: data.recordingPosterUrl,
          }}
        />
      </VideoWrapper>
    </>
  )
}

const SummaryAvailable: FC<{ data: SummaryData }> = ({ data }): JSX.Element => {
  const { t } = useTranslation()

  return (
    <>
      <Text>{data.summary}</Text>

      <Spacer size='40' />

      <View gap='16' direction='column'>
        <Text size='small' bold>
          {t('sana-now.post-session-page.summary.key-topics')}
        </Text>
        <UnorderedList>
          {data.notes.map((note, index) => (
            <UnorderedListItem key={index}>
              <View alignItems='baseline' direction='column'>
                <View>
                  <Mark />
                  <Text bold>{note.topic}</Text>
                </View>
                <View direction='column'>
                  {/* Add a hidden mark on this text so that the title and sub items align */}
                  <Mark $hidden />
                  {note.notes.map((noteItem, index) => (
                    <Text color='foreground/secondary' size='small' key={index}>
                      {noteItem}
                    </Text>
                  ))}
                </View>
              </View>
            </UnorderedListItem>
          ))}
        </UnorderedList>
      </View>
    </>
  )
}

export const Summary: FC<{ summaryData: UseSessionBotDataQueryResult }> = ({ summaryData }) => {
  const { t } = useTranslation()

  return (
    <View direction='column' gap='16'>
      {summaryData.data !== undefined && summaryData.data.type === 'completed' && (
        // The key is essential to ensure the video component is refreshed if the url is changed
        // otherwise the videojs will not reload the video
        <View key={summaryData.data.data.recordingUrl}>
          <VideoPreview data={summaryData.data.data} />
        </View>
      )}

      <Text size='regular' bold>
        {t('dictionary.summary')}
      </Text>
      {iife(() => {
        switch (summaryData.data?.type) {
          case 'missing':
            return <BotNotEnabled />
          case 'no-data':
            return <BotNotEnabled />
          case 'loading':
            return <SummaryLoading />
          case 'completed':
            return <SummaryAvailable data={summaryData.data.data} />
          case undefined:
            return null
          default:
            assertNever(summaryData.data)
        }
      })}
    </View>
  )
}
