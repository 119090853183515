import React from 'react'
import { useTranslation } from 'sierra-client/hooks/use-translation'
import { getAccessLevelTranslationKey } from 'sierra-client/views/manage/users/user-utils'
import { UserAccessLevel } from 'sierra-domain/access-level'
import { Text } from 'sierra-ui/primitives'
import styled from 'styled-components'

const UnderlinedLink = styled(Text).attrs({ as: 'a' })``

export const AccessLevelIndicator: React.FC<{ accessLevel: UserAccessLevel; onClick: () => void }> = ({
  accessLevel,
  onClick,
}) => {
  const { t } = useTranslation()
  const label = t(getAccessLevelTranslationKey(accessLevel))

  return <UnderlinedLink onClick={() => onClick()}>{label}</UnderlinedLink>
}
