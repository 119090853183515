import { useCallback, useEffect } from 'react'
import { useConfirmationModalContext } from 'sierra-client/components/common/modals/confirmation-modal'
import { useBreakoutInSession } from 'sierra-client/components/liveV2/hooks/use-breakout-in-session'
import { useSelectCurrentCard } from 'sierra-client/components/liveV2/hooks/use-select-current-card'
import { useTranslation } from 'sierra-client/hooks/use-translation'
import { useDispatch } from 'sierra-client/state/hooks'
import { liveSessionSlice } from 'sierra-client/state/live-session/slice'
import { FCC } from 'sierra-client/types'
import { FileId } from 'sierra-domain/flexible-content/identifiers'

export const sidebarFileId = (fileId: FileId): string => `sidebar-file-${fileId}`

export const scrollToActiveFileInSidebar = (fileId: FileId): void => {
  const element = document.getElementById(sidebarFileId(fileId))
  if (element) {
    element.scrollIntoView({ block: 'center', behavior: 'smooth' })
  }
}

export const useScrollToActiveFileInSidebar = (): void => {
  const currentCard = useSelectCurrentCard()

  useEffect(() => {
    if (currentCard === undefined) return
    scrollToActiveFileInSidebar(currentCard.id)
  }, [currentCard])
}

export const FileScrollAnchor: FCC<{ fileId: FileId }> = ({ fileId, children }) => (
  <div id={sidebarFileId(fileId)}>{children}</div>
)

export const useGoToNode = (): ((fileId: FileId) => void) => {
  const { t } = useTranslation()
  const dispatch = useDispatch()
  const breakoutInSession = useBreakoutInSession()
  const confirmationModalContext = useConfirmationModalContext()

  const goToNode = useCallback(
    (fileId: FileId): void => {
      if (breakoutInSession) {
        confirmationModalContext.show({
          bodyText: t('live.breakout-rooms.leave-card-confirmation'),
          onConfirm: () => {
            void dispatch(liveSessionSlice.actions.stopBreakoutSession())
            void dispatch(liveSessionSlice.actions.setCurrentCardId(fileId))
          },
        })
      } else {
        void dispatch(liveSessionSlice.actions.setCurrentCardId(fileId))
      }
    },
    [breakoutInSession, confirmationModalContext, dispatch, t]
  )

  return goToNode
}
