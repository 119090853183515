export { DistributionSettings } from 'sierra-client/features/multi-tenancy/editor/distribution-settings'
export { OrganizationClusterVisibilitySettings } from 'sierra-client/features/multi-tenancy/editor/organization-cluster-visibility-settings'

export { useOrganizationCluster } from 'sierra-client/features/multi-tenancy/hooks/use-organization-cluster'
export { useOrganizationClusterMenuItems } from 'sierra-client/features/multi-tenancy/hooks/use-organization-cluster-menu'
export { useUnpublishDistributedCourseMutation } from 'sierra-client/features/multi-tenancy/hooks/use-unpublish-distributed-course-mutation'

export {
  constructDistributionPatch,
  translateVisibility,
  useCourseDistributionSettingsMutation,
} from 'sierra-client/features/multi-tenancy/hooks/use-course-distribution-settings'

export { DistributionPill } from 'sierra-client/features/multi-tenancy/components/distribution-pill'

export type { OrganizationClusterState } from 'sierra-client/features/multi-tenancy/types'

export { Card, SquareLogo } from 'sierra-client/features/multi-tenancy/components'

export { organizationComparator } from 'sierra-client/features/multi-tenancy/utils/organization-comparator'

export { UnpublishConfirmationModal } from 'sierra-client/features/multi-tenancy/components/unpublish-confirmation-modal'

/**
 * NOTE: We'll extend this further at a later point. It's just nice to have it in one place.
 */
export function distributionSupportsContentType(type: string): boolean {
  if (type === 'native:self-paced') {
    return true
  }

  return false
}
