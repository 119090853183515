import { AnimatePresence, LayoutGroup } from 'framer-motion'
import React from 'react'
import { useInsightsViews } from 'sierra-client/features/insights/api-hooks/use-views'
import { getAvailableViewData } from 'sierra-client/features/insights/hooks/use-insights-views'
import { AnimationContainer } from 'sierra-client/features/insights/widget-builder/data-selectors/animation-container'
import { MultiMeasureSelector } from 'sierra-client/features/insights/widget-builder/data-selectors/multi-measure-selector'
import { TimeFilterContainer } from 'sierra-client/features/insights/widget-builder/data-selectors/time-filter-container'
import { getViewRefForMeasureRef } from 'sierra-client/features/insights/widget-builder/data-selectors/view-helpers'
import { ViewSelector } from 'sierra-client/features/insights/widget-builder/data-selectors/view-selector'
import { WidgetDimensionContainer } from 'sierra-client/features/insights/widget-builder/data-selectors/widget-dimension-container'
import { WidgetFilterContainer } from 'sierra-client/features/insights/widget-builder/data-selectors/widget-filter-container'
import { WidgetTableTypeSelector } from 'sierra-client/features/insights/widget-builder/data-selectors/widget-table-type-selector'
import { PivotTableSelectors } from 'sierra-client/features/insights/widget-builder/pivot-table-selectors'
import { hasTimeFilter } from 'sierra-client/features/insights/widget-builder/types'
import { WidgetBuilderState } from 'sierra-client/features/insights/widget-builder/widget-builder-state'
import { useTranslation } from 'sierra-client/hooks/use-translation'
import {
  Dimension,
  DimensionRef,
  MeasureRef,
  TimeFilter,
  ViewRef,
  areDimensionRefsEqual,
  areViewRefsEqual,
} from 'sierra-domain/api/insights'
import { Filter } from 'sierra-domain/filter/datatype/filter'
import { assertNever, isDefined } from 'sierra-domain/utils'
import { Text, View } from 'sierra-ui/primitives'
import { MeasureSelector } from './measure-selector'

const clearNotEnabledDimension = (
  enabledDimensions: Dimension[],
  dimensionRef?: DimensionRef
): DimensionRef | undefined => {
  if (dimensionRef === undefined) return undefined

  if (enabledDimensions.some(dimension => areDimensionRefsEqual(dimension.ref, dimensionRef))) {
    return dimensionRef
  }

  return undefined
}

/**
 * Filter out filters that has a disabled dimension ref
 */
const filterOutDisabledFilters = (filter?: Filter, dimensions?: Dimension[]): Filter | undefined => {
  if (filter === undefined) return filter
  if (dimensions === undefined) return filter

  if (filter.type === 'filter.and' || filter.type === 'filter.or') {
    const filters = filter.filters
      .flatMap(filter => filterOutDisabledFilters(filter, dimensions))
      .filter(isDefined)

    if (filters.length === 0) return undefined

    return { ...filter, filters }
  }

  return dimensions.find(dimension => JSON.stringify(dimension.ref) === JSON.stringify(filter.domain)) !==
    undefined
    ? filter
    : undefined
}

export const getSelectedMeasuresFromState = (widgetBuilderState: WidgetBuilderState): MeasureRef[] => {
  switch (widgetBuilderState.visualisation.type) {
    case 'metric':
    case 'progress-bar':
    case 'pivot-table':
    case 'bar-chart':
    case 'line-chart':
      return widgetBuilderState.selections.measures.slice(0, 1)
    case 'table': {
      switch (widgetBuilderState.visualisation.table.tableType) {
        case 'standard':
          return widgetBuilderState.selections.measures
        case 'list':
          return []
        default:
          return assertNever(widgetBuilderState.visualisation.table)
      }
    }
    default:
      assertNever(widgetBuilderState.visualisation)
  }
}

const getSelectedViewFromState = (widgetBuilderState: WidgetBuilderState): ViewRef | undefined => {
  switch (widgetBuilderState.visualisation.type) {
    case 'table': {
      switch (widgetBuilderState.visualisation.table.tableType) {
        case 'list':
          return widgetBuilderState.selections.view
        default:
          return undefined
      }
    }
    default:
      return undefined
  }
}

const emptyMeasureArray: MeasureRef[] = [] as const

export const WidgetDataSelector = ({
  widgetBuilderState,
  onChange,
}: {
  widgetBuilderState: WidgetBuilderState
  onChange: (newState: WidgetBuilderState) => void
}): React.ReactNode => {
  const { t } = useTranslation()
  const { data: viewsData } = useInsightsViews()

  const handleSelectMeasures = (newMeasures: MeasureRef[]): void => {
    if (viewsData === undefined) return console.error('View data not loaded')

    const availableViewData = getAvailableViewData({ viewsData, measures: newMeasures })

    // Clear dimensions if they are not enabled for these measures
    const dimensions = widgetBuilderState.selections.dimensions
      .map(dimension => clearNotEnabledDimension(availableViewData.availableDimensions, dimension))
      .filter(isDefined)

    const filter = filterOutDisabledFilters(
      widgetBuilderState.selections.filter,
      availableViewData.availableDimensions
    )

    // Clear timeFilter if time dimension is not enabled for this measure
    const hasTimeDimension = availableViewData.availableDimensions.some(
      dimension => dimension.ref.type === 'dimension.native.time'
    )
    const timeFilter = hasTimeDimension ? widgetBuilderState.selections.timeFilter : undefined

    const measureRef = newMeasures[0]
    const viewRef = getViewRefForMeasureRef(measureRef)
    const visualisation: WidgetBuilderState['visualisation'] =
      viewRef === undefined &&
      widgetBuilderState.visualisation.type === 'table' &&
      widgetBuilderState.visualisation.table.tableType === 'list'
        ? { type: 'table', table: { tableType: 'standard' } }
        : widgetBuilderState.visualisation

    const view = viewRef !== undefined ? viewRef : widgetBuilderState.selections.view

    onChange({
      ...widgetBuilderState,
      selections: {
        ...widgetBuilderState.selections,
        measures: newMeasures,
        dimensions,
        filter,
        timeFilter,
        view,
      },
      visualisation,
    })
  }

  const handleSetDimensions = (dimensions: DimensionRef[]): void => {
    onChange({
      ...widgetBuilderState,
      selections: { ...widgetBuilderState.selections, dimensions },
    })
  }

  const handleSelectTableType = (tableType: 'standard' | 'list'): void => {
    switch (tableType) {
      case 'standard': {
        if (viewsData === undefined) return console.error('View data not loaded')
        const availableViewData = getAvailableViewData({
          viewsData,
          measures: widgetBuilderState.selections.measures,
        })

        // Clear dimensions if they are not enabled for these measures
        const dimensions = widgetBuilderState.selections.dimensions
          .map(dimension => clearNotEnabledDimension(availableViewData.availableDimensions, dimension))
          .filter(isDefined)

        const filter = filterOutDisabledFilters(
          widgetBuilderState.selections.filter,
          availableViewData.availableDimensions
        )

        return onChange({
          ...widgetBuilderState,
          selections: { ...widgetBuilderState.selections, dimensions, filter },
          visualisation: { type: 'table', table: { tableType } },
        })
      }
      case 'list': {
        const viewId = widgetBuilderState.selections.view
        if (viewId === undefined) {
          return onChange({
            ...widgetBuilderState,
            visualisation: { type: 'table', table: { tableType } },
          })
        } else {
          if (viewsData === undefined) return console.error('View data not loaded')

          const view = viewsData.views.find(view => areViewRefsEqual(viewId, view.id))

          if (view === undefined) return console.error('View not found')

          const defaultDimensions =
            view.listSpec?.type === 'list.default-dimensions' ? view.listSpec.dimensions : []

          const nonDefaultDimensions = widgetBuilderState.selections.dimensions.filter(
            dimension =>
              !defaultDimensions.some(defaultDimension => areDimensionRefsEqual(defaultDimension, dimension))
          )
          const dimensions = [...defaultDimensions, ...nonDefaultDimensions]

          const availableViewData = getAvailableViewData({
            viewsData,
            viewRef: widgetBuilderState.selections.view,
          })

          const filter = filterOutDisabledFilters(
            widgetBuilderState.selections.filter,
            availableViewData.availableDimensions
          )

          return onChange({
            ...widgetBuilderState,
            visualisation: { type: 'table', table: { tableType } },
            selections: { ...widgetBuilderState.selections, dimensions, filter },
          })
        }
      }
      default:
        assertNever(tableType)
    }
  }

  const handleSelectView = (viewRef: ViewRef): void => {
    if (viewsData === undefined) return console.error('View data not loaded')

    const availableViewData = getAvailableViewData({ viewsData, viewRef })
    const filter = filterOutDisabledFilters(
      widgetBuilderState.selections.filter,
      availableViewData.availableDimensions
    )

    const view = viewsData.views.find(view => areViewRefsEqual(viewRef, view.id))

    if (view === undefined) return console.error('View data not loaded')

    const defaultDimensions =
      view.listSpec?.type === 'list.default-dimensions' ? view.listSpec.dimensions : undefined

    if (defaultDimensions === undefined) return console.error('View is missing default dimensions')

    onChange({
      ...widgetBuilderState,
      selections: { ...widgetBuilderState.selections, view: viewRef, dimensions: defaultDimensions, filter },
    })
  }

  const dimensionSelectorEnabled =
    widgetBuilderState.visualisation.type !== 'metric' &&
    widgetBuilderState.visualisation.type !== 'progress-bar'

  const timeFilterSelectorEnabled =
    widgetBuilderState.selections.dimensions[0]?.type === 'dimension.native.time' &&
    hasTimeFilter(widgetBuilderState)

  const handleSelectFilter = (newFilter?: Filter): void => {
    onChange({ ...widgetBuilderState, selections: { ...widgetBuilderState.selections, filter: newFilter } })
  }

  const handleSelectTimeFilter = (timeFilter?: TimeFilter): void => {
    onChange({ ...widgetBuilderState, selections: { ...widgetBuilderState.selections, timeFilter } })
  }

  return (
    <LayoutGroup>
      <AnimatePresence mode='popLayout'>
        {widgetBuilderState.visualisation.type === 'table' && (
          <View
            animated
            initial={{ opacity: 0 }}
            animate={{ opacity: 1 }}
            exit={{ opacity: 0 }}
            transition={{ duration: 0.1 }}
          >
            <WidgetTableTypeSelector
              value={widgetBuilderState.visualisation.table.tableType}
              onChange={handleSelectTableType}
            />
          </View>
        )}
      </AnimatePresence>
      {widgetBuilderState.visualisation.type === 'pivot-table' ? (
        <AnimationContainer layoutId='wrapper' key='wrapper' direction='column' grow gap='16'>
          <PivotTableSelectors
            measure={widgetBuilderState.selections.measures[0]}
            dimensions={widgetBuilderState.selections.dimensions.slice(0, 2)}
            filter={widgetBuilderState.selections.filter}
            visualisation={widgetBuilderState.visualisation}
            onChangeMeasure={newMeasure =>
              handleSelectMeasures([
                newMeasure.ref,
                ...widgetBuilderState.selections.measures.slice(
                  1,
                  widgetBuilderState.selections.measures.length
                ),
              ])
            }
            onChangeFilter={handleSelectFilter}
            onChangeDimensions={newDimensions =>
              handleSetDimensions([
                ...newDimensions,
                ...widgetBuilderState.selections.dimensions.slice(
                  2,
                  widgetBuilderState.selections.dimensions.length
                ),
              ])
            }
            widgetBuilderState={widgetBuilderState}
          />
        </AnimationContainer>
      ) : widgetBuilderState.visualisation.type === 'table' &&
        widgetBuilderState.visualisation.table.tableType === 'list' ? (
        <AnimationContainer direction='column' gap='16'>
          <AnimationContainer direction='column' gap='8' layoutId='measure-container'>
            <View justifyContent='space-between'>
              <Text color='foreground/primary' bold capitalize='first'>
                {t('manage.insights.widgetbuilder.view')}
              </Text>
            </View>

            <View padding='4 none' grow>
              <ViewSelector selectedView={widgetBuilderState.selections.view} onChange={handleSelectView} />
            </View>
          </AnimationContainer>

          <WidgetDimensionContainer
            selectedMeasures={getSelectedMeasuresFromState(widgetBuilderState)}
            selectedView={getSelectedViewFromState(widgetBuilderState)}
            selectedDimensions={widgetBuilderState.selections.dimensions}
            visualisation={widgetBuilderState.visualisation}
            onChange={handleSetDimensions}
          />

          <WidgetFilterContainer
            selectedView={getSelectedViewFromState(widgetBuilderState)}
            selectedMeasures={getSelectedMeasuresFromState(widgetBuilderState)}
            selectedFilter={widgetBuilderState.selections.filter}
            visualisation={widgetBuilderState.visualisation}
            onChange={handleSelectFilter}
            widgetBuilderState={widgetBuilderState}
          />
        </AnimationContainer>
      ) : (
        <AnimationContainer direction='column' gap='16'>
          <AnimationContainer direction='column' gap='8' layoutId='measure-container'>
            <View justifyContent='space-between'>
              <Text color='foreground/primary' bold capitalize='first'>
                {t('manage.insights.widgetbuilder.metric')}
              </Text>
            </View>

            {widgetBuilderState.visualisation.type === 'table' ? (
              <MultiMeasureSelector
                selectedMeasures={widgetBuilderState.selections.measures}
                onChange={handleSelectMeasures}
                visualisation={widgetBuilderState.visualisation}
              />
            ) : (
              <View padding='4 none' grow>
                <MeasureSelector
                  selectedMeasure={widgetBuilderState.selections.measures[0]}
                  onChange={newMeasure => handleSelectMeasures([newMeasure.ref])}
                  disabledMeasures={emptyMeasureArray}
                  visualisation={widgetBuilderState.visualisation}
                />
              </View>
            )}
          </AnimationContainer>

          {dimensionSelectorEnabled && (
            <WidgetDimensionContainer
              selectedMeasures={getSelectedMeasuresFromState(widgetBuilderState)}
              selectedView={getSelectedViewFromState(widgetBuilderState)}
              selectedDimensions={widgetBuilderState.selections.dimensions}
              visualisation={widgetBuilderState.visualisation}
              onChange={handleSetDimensions}
            />
          )}

          <WidgetFilterContainer
            selectedView={getSelectedViewFromState(widgetBuilderState)}
            selectedMeasures={getSelectedMeasuresFromState(widgetBuilderState)}
            selectedFilter={widgetBuilderState.selections.filter}
            visualisation={widgetBuilderState.visualisation}
            onChange={handleSelectFilter}
            widgetBuilderState={widgetBuilderState}
          />

          {timeFilterSelectorEnabled && (
            <TimeFilterContainer
              selectedTimeFilter={widgetBuilderState.selections.timeFilter}
              onChange={handleSelectTimeFilter}
            />
          )}
        </AnimationContainer>
      )}
    </LayoutGroup>
  )
}
