import { motion } from 'framer-motion'
import _ from 'lodash'
import { useCallback, useMemo } from 'react'
import { TextToSpeech } from 'sierra-client/editor/text-to-speech'
import { useShowCorrectAnswers } from 'sierra-client/hooks/use-show-correct-answers'
import { useTranslation } from 'sierra-client/hooks/use-translation'
import { TranslationKey } from 'sierra-client/hooks/use-translation/types'
import { useSafeFileContext } from 'sierra-client/views/flexible-content/file-context'
import {
  findPathToNodeWithId,
  removeNodeWithId,
  updateNodeWithId,
} from 'sierra-client/views/v3-author/command'
import { useIsInPdfExport } from 'sierra-client/views/v3-author/export-pdf/use-is-pdf-export'
import { useAncestorWithType, useParent } from 'sierra-client/views/v3-author/hooks'
import {
  useIsInLiveQuizContext,
  useLiveQuizContext,
} from 'sierra-client/views/v3-author/live-quiz/live-quiz-context'
import { useLiveQuizQuestionCardContext } from 'sierra-client/views/v3-author/live-quiz/live-quiz-question-context'
import { LiveQuizAvatarforStack } from 'sierra-client/views/v3-author/live-quiz/live-quiz-user/live-quiz-avatar'
import { assertElementType, isElementType } from 'sierra-client/views/v3-author/queries'
import { ExplanationLine } from 'sierra-client/views/v3-author/question-card/multiple-choice-alternative/shared'
import { useQuestionCardData } from 'sierra-client/views/v3-author/question-card/question-card-data-layer'
import { RadioCheckbox } from 'sierra-client/views/v3-author/question-card/radiocheckbox'
import {
  AlternativeContextProvider,
  useAlternativeContext,
} from 'sierra-client/views/v3-author/question-card/use-alternative-context'
import { AlternativeStatus, getAlternativeStatus } from 'sierra-client/views/v3-author/question-card/utils'
import { useRenderingContext } from 'sierra-client/views/v3-author/rendering-context'
import { SlateFC } from 'sierra-client/views/v3-author/slate'
import { QuestionCardResponseData } from 'sierra-domain/card/question-card'
import { assertNever, iife } from 'sierra-domain/utils'
import {
  CustomElementType,
  QuestionCardMultipleChoiceAlternative as SlateQuestionCardMultipleChoiceAlternative,
} from 'sierra-domain/v3-author'
import { color, deriveTheme } from 'sierra-ui/color'
import { Icon } from 'sierra-ui/components'
import { IconButton, Text, View } from 'sierra-ui/primitives'
import { DefaultDropdownTrigger, SingleSelectDropdown } from 'sierra-ui/primitives/menu-dropdown'
import { SingleSelectDropdownProps } from 'sierra-ui/primitives/menu-dropdown/single-select-dropdown'
import { LightTokenProvider, palette, spacing, token } from 'sierra-ui/theming'
import { v2_breakpoint } from 'sierra-ui/theming/breakpoints'
import { fonts } from 'sierra-ui/theming/fonts'
import { resolveSizes } from 'sierra-ui/utils'
import { Editor, Element, Path, Text as SlateText, Transforms } from 'slate'
import { useSlateStatic } from 'slate-react'
import styled, { css, useTheme } from 'styled-components'

const ActionSeparator = styled.span`
  display: inline-block;
  width: 1px;
  margin: 0 0.5rem;
  background-color: ${palette.grey[5]};
`

export const QuestionCardMultipleChoiceAlternativeWrapper = styled(View).attrs({
  padding: 'none',
  direction: 'row',
  gap: 'none',
  alignItems: 'center',
})`
  border: 0;
`

type QuestionCardMultipleChoiceAlternativeContainerProps = {
  $white?: boolean
  $submitted?: boolean
  readOnly?: boolean
}
export const QuestionCardMultipleChoiceAlternativeContainer = styled(
  motion.div
)<QuestionCardMultipleChoiceAlternativeContainerProps>`
  width: 100%;
  display: grid;
  align-items: stretch; /* Stretch vertically to fill the cell */
  justify-items: stretch; /* Stretch horizontally to fill the cell */

  grid-template-columns: min-content 1fr max-content min(20%, 50px) auto;

  grid-template-rows: min-content min-content;
  column-gap: ${spacing.xsmall};
  padding: ${resolveSizes('xsmall small')};
  grid-template-areas:
    'checkbox option toggle space actions'
    'vertical explanation . space .';

  ${p =>
    p.readOnly === true &&
    css`
      @media screen and (max-width: 600px) {
        column-gap: 0;
        grid-template-columns: ${spacing.xsmall} min-content auto;
        grid-template-rows: min-content min-content min-content;
        padding: ${spacing.xsmall} ${spacing.small};
        grid-template-areas:
          'space checkbox option'
          'space . result'
          'space vertical explanation';
        padding: ${spacing.xsmall} 0;
      }
    `}

  transform: translate3d(0, 0, 0);

  ${p =>
    p.$white === true
      ? `
      color: ${palette.primitives.black};
      background-color: ${palette.primitives.white};
      border-color: ${palette.grey[5]};
      `
      : `
      color: ${deriveTheme(p.theme).textColor};
      background-color: ${color(deriveTheme(p.theme).textColor).opacity(0.05).toString()};
      transition: background-color 0.1s cubic-bezier(0.25, 0.1, 0.25, 1);

  `}

  ${p =>
    p.$white !== true &&
    p.$submitted !== true &&
    `
      background-color: ${color(deriveTheme(p.theme).textColor).opacity(0.1).toString()};

      &:hover {
        background-color: ${color(deriveTheme(p.theme).textColor).opacity(0.2).toString()};
      }
  `}

  border-radius: ${p => p.theme.borderRadius['size-10']};
  cursor: ${p => (p.onClick ? 'pointer' : 'auto')};
`

type LabelWrapperProps = {
  color: string
  readOnly?: boolean
}
const LabelWrapper = styled(View).attrs({ contentEditable: false, grow: false })<LabelWrapperProps>`
  grid-area: actions;

  color: ${p => p.color};
  ${fonts.body.small};
  padding-left: 0.75rem;

  font-weight: ${fonts.weight.bold};
  ${p =>
    p.readOnly === true &&
    css`
      @media screen and (max-width: ${v2_breakpoint.phone}) {
        grid-area: result;
      }
    `}
`

const getTranslationKeyForAlternativeStatus = (status: AlternativeStatus): TranslationKey | undefined => {
  switch (status) {
    case 'correct':
      return 'author.slate.correct'
    case 'incorrect':
      return 'author.slate.incorrect'
    case 'missed':
      return 'author.slate.missed'
    // For this to work we need to look at the whole response and solution and check if another question has been evaluated as correct
    // (multipleChoiceType === 'question-card-select-all-that-apply-body'
    //   ? 'author.slate.missed'
    //   : 'author.slate.also-correct')
    case 'unselected':
      return undefined
    case 'selected':
      return undefined
    case 'create':
      return undefined
    case 'disabled':
      return undefined
    default:
      assertNever(status)
  }
}

const getColorForAlternativeStatus = (status: AlternativeStatus): string | undefined => {
  switch (status) {
    case 'correct':
      return palette.green.bright
    case 'incorrect':
      return palette.red.bright
    case 'missed':
    case 'unselected':
    case 'selected':
    case 'create':
    case 'disabled':
      return undefined
    default:
      assertNever(status)
  }
}

const Label = styled(
  ({
    alternativeStatus,
    readOnly,
  }: {
    multipleChoiceType: CustomElementType | undefined
    alternativeStatus: AlternativeStatus
    readOnly?: boolean
  }) => {
    const theme = useTheme()
    const { t } = useTranslation()
    const translationKey = getTranslationKeyForAlternativeStatus(alternativeStatus)
    const color = getColorForAlternativeStatus(alternativeStatus) ?? deriveTheme(theme).secondaryTextColor

    return translationKey !== undefined ? (
      <LabelWrapper readOnly={readOnly === true} color={color}>
        {t(translationKey)}
      </LabelWrapper>
    ) : (
      <></>
    )
  }
)`
  user-select: none;
`

const Actions = styled.div.attrs({ contentEditable: false })<{ readOnly: boolean }>`
  grid-area: actions;
  user-select: none;
  justify-content: flex-end;
  align-self: center;
  display: ${p => (p.readOnly ? 'none' : 'flex')};
`

const Delete = styled(IconButton).attrs({
  variant: 'transparent',
  iconId: 'trash-can',
  tooltip: 'Delete option',
})`
  cursor: pointer;
`

const ExplanationButton = styled(IconButton).attrs({
  variant: 'transparent',
  color: 'grey40',
})`
  cursor: pointer;
`

const CorrectAnswer = styled(Text)`
  margin-left: auto;
  white-space: nowrap;
`

const ToggleContainer = styled.div.attrs({ contentEditable: false })`
  align-self: center;
`

type AlternativeRow = {
  alternativeStatus: AlternativeStatus
  onClick?: () => void
  radio: boolean
  children: JSX.Element
  status?: SlateQuestionCardMultipleChoiceAlternative['status']
  dropdownProps?: SingleSelectDropdownProps<'correct' | 'incorrect'>
  onRemove?: () => void
  numberOfAlternatives?: number
  multipleChoiceType?: CustomElementType | undefined
  submitted?: boolean
  isEmpty: boolean
}

const QuestionCardMultipleChoiceAlternativeRow: SlateFC<AlternativeRow> = props => {
  const {
    alternativeStatus,
    onClick,
    children,
    radio,
    readOnly,
    dropdownProps,
    onRemove,
    numberOfAlternatives,
    multipleChoiceType,
    submitted,
    isEmpty,
    ...slateProps
  } = props

  const isWhite = ['correct', 'incorrect', 'selected', 'create'].includes(alternativeStatus)

  const correctAnswer = alternativeStatus === 'correct'
  const incorrectAnswer = alternativeStatus === 'incorrect'

  // TODO(patrik): This still allows hover when submitted && the answer is correct, not sure why. Refactor.
  const allowHover = submitted === false
  const explanationContext = useAlternativeContext()
  const showLine = readOnly || explanationContext?.explanationOpen === true
  const { t } = useTranslation()
  const { element } = props
  const editor = useSlateStatic()
  const showCorrectAnswers = useShowCorrectAnswers()
  const { preview } = useRenderingContext()
  const isMultipleChoice =
    useAncestorWithType({
      nodeId: element.id,
      type: 'question-card-select-all-that-apply-body',
    }) !== undefined

  const isCorrectAlternative =
    isElementType('question-card-multiple-choice-alternative', element) && element.status === 'correct'

  const file = useSafeFileContext()?.file
  const fileData = file?.data
  const parent = useAncestorWithType({ nodeId: element.id, type: 'question-card' })
  const assessmentHideAnswerLabel =
    fileData !== undefined &&
    fileData.type === 'assessment-card' &&
    fileData.settings.hideCorrectAnswers === true

  const isInPdfExport = useIsInPdfExport()
  const hideAnswerLabel = (parent?.hideCorrectAnswers ?? false) || assessmentHideAnswerLabel

  return (
    <>
      <TextToSpeech element={element} />
      <LightTokenProvider>
        <QuestionCardMultipleChoiceAlternativeContainer
          readOnly={readOnly}
          initial={{
            scale: correctAnswer ? 1.03 : 1,
          }}
          animate={
            !readOnly
              ? false
              : {
                  scale: correctAnswer || hideAnswerLabel ? 1.03 : 1,
                  x: incorrectAnswer && !hideAnswerLabel ? [0, -30, 30, -20, 10, 0] : 1,
                }
          }
          transition={{
            scale: { type: 'spring', duration: 0.2 },
            x: { type: 'spring', duration: 0.5 },

            ease: [0.25, 0.1, 0.25, 1],
            default: { duration: 0.1 },
          }}
          onClick={onClick}
          $white={isWhite}
          $submitted={submitted}
          whileHover={{ scale: allowHover ? 1.01 : 1 }}
          whileTap={{ scale: allowHover ? 0.99 : 1 }}
          role={radio ? 'radio' : 'checkbox'}
          aria-checked={alternativeStatus === 'correct' || alternativeStatus === 'selected'}
          {...slateProps}
        >
          {showCorrectAnswers === true && preview === true && isCorrectAlternative && (
            <CorrectAnswer
              color='LEGACY_DEFAULT_TEXT_COLOR_REPLACE_ASAP'
              size='regular'
              contentEditable={false}
            >
              {t('author.slate.correct')}
            </CorrectAnswer>
          )}
          <RadioCheckbox
            checked={
              alternativeStatus === 'selected' ||
              alternativeStatus === 'correct' ||
              alternativeStatus === 'incorrect'
            }
            radio={!isMultipleChoice}
          />

          {children}
          <ToggleContainer contentEditable={false}>
            {!readOnly && dropdownProps && numberOfAlternatives !== undefined ? (
              <SingleSelectDropdown {...dropdownProps} />
            ) : (
              <span />
            )}
          </ToggleContainer>
          {showCorrectAnswers !== true && showLine && <ExplanationLine contentEditable={false} />}
          {readOnly && !hideAnswerLabel && !isInPdfExport && (
            <Label
              readOnly={readOnly}
              multipleChoiceType={multipleChoiceType}
              alternativeStatus={alternativeStatus}
            />
          )}

          <Actions readOnly={readOnly}>
            {!readOnly && dropdownProps && (
              <ExplanationButton
                iconId={showLine ? 'no-explanation' : 'explanation'}
                tooltip={
                  showLine
                    ? t('create.question-card.remove-explanation')
                    : t('create.question-card.add-explanation')
                }
                onClick={() => {
                  assertElementType(
                    'question-card-multiple-choice-alternative-explanation',
                    element.children[1]
                  )
                  if (explanationContext?.explanationOpen === false) {
                    explanationContext.toggle()
                    return
                  }
                  removeNodeWithId(editor, element.children[1].id)
                }}
              />
            )}
            {!readOnly && dropdownProps && numberOfAlternatives !== undefined && numberOfAlternatives > 2 ? (
              <>
                <ActionSeparator />
                <Delete onClick={onRemove} />
              </>
            ) : (
              // Slate requires us to have an empty element here, otherwise it'll be possible to select this element
              <span />
            )}
          </Actions>
        </QuestionCardMultipleChoiceAlternativeContainer>
      </LightTokenProvider>
    </>
  )
}

const QuestionCardMultipleChoiceAlternativeLearner: SlateFC = props => {
  const { element, children } = props
  assertElementType('question-card-multiple-choice-alternative', element)

  const questionBody = useParent({ nodeId: element.id })

  const {
    updateResponse,
    questionCardData: { response, evaluation },
  } = useQuestionCardData()

  const submitted = useMemo(() => evaluation !== undefined, [evaluation])

  if (response.type !== 'multiple-choice') {
    throw new Error(`Trying to load question of type ${response.type} in multiple choice alternative.`)
  }

  const checkboxVariant = useMemo(
    () => getAlternativeStatus({ response, solution: element, id: element.id, submitted }),
    [response, element, submitted]
  )

  const onClick = useCallback(() => {
    const oldResponse = response.alternatives[element.id]

    if (oldResponse === undefined) {
      throw new Error('Could not find alternative')
    }

    let newResponse: QuestionCardResponseData

    if (isElementType('question-card-pick-the-best-option-body', questionBody)) {
      newResponse = {
        ...response,
        alternatives: {
          ..._.mapValues(response.alternatives, () => ({ status: 'incorrect' })), // Set all other values to incorrect
          [element.id]: {
            status: 'correct',
          },
        },
        selectedAlternativeIds: [element.id],
      }
    } else {
      newResponse = {
        ...response,
        alternatives: {
          ...response.alternatives,
          [element.id]: {
            status: oldResponse.status === 'correct' ? 'incorrect' : 'correct', // Toggle the status of this response
          },
        },
        selectedAlternativeIds: iife(() => {
          // Toggle this alternative
          if (response.selectedAlternativeIds.includes(element.id)) {
            return response.selectedAlternativeIds.filter(it => it !== element.id)
          } else {
            return [...response.selectedAlternativeIds, element.id]
          }
        }),
      }
    }

    updateResponse(newResponse)
  }, [response, updateResponse, questionBody, element.id])

  return (
    <QuestionCardMultipleChoiceAlternativeRow
      alternativeStatus={checkboxVariant}
      onClick={submitted ? undefined : onClick}
      radio={isElementType('question-card-pick-the-best-option-body', questionBody)}
      multipleChoiceType={Element.isElement(questionBody) ? questionBody.type : undefined}
      submitted={submitted}
      isEmpty={false}
      {...props}
    >
      {children}
    </QuestionCardMultipleChoiceAlternativeRow>
  )
}

type DropdownMenuItem = {
  type: 'label'
  id: 'correct' | 'incorrect'
  icon: 'checkmark--filled' | 'close--circle--filled'
  label: string
  selected: boolean
  color: 'success/background' | 'destructive/background'
}

const DropdownTextContainer = styled.span`
  display: flex;
  align-items: center;
  gap: 4px;
`

const DropdownText = styled.strong<{ color: 'success/background' | 'destructive/background' }>`
  white-space: nowrap;
  width: fit-content;
  color: ${p => token(p.color)(p)};
`

const DropdownTextHolder = styled.span`
  position: relative;

  ${DropdownText} {
    position: absolute;
    inset: 0;
  }
`

const InvisibleSpan = styled.span`
  display: inline-block;
  visibility: hidden;
`

const QuestionCardMultipleChoiceAlternativeCreate: SlateFC = props => {
  const { element, children, readOnly } = props
  assertElementType('question-card-multiple-choice-alternative', element)
  const editor = useSlateStatic()
  const { t } = useTranslation()

  const { status } = element

  const questionBody = useParent({ nodeId: element.id })
  const numberOfAlternatives = questionBody?.children.length ?? 0

  const isEmpty = (() => {
    const explanation = element.children[1]
    const explanationText = SlateText.isText(explanation) ? explanation : explanation?.children[0]
    return !readOnly && SlateText.isText(explanationText) && explanationText.text === ''
  })()

  const items: DropdownMenuItem[] = [
    {
      type: 'label',
      id: 'correct',
      icon: 'checkmark--filled',
      label: t('content.questions.alternative.correct'),
      selected: status === 'correct',
      color: 'success/background',
    },
    {
      type: 'label',
      id: 'incorrect',
      icon: 'close--circle--filled',
      label: t('content.questions.alternative.incorrect'),
      selected: status === 'incorrect',
      color: 'destructive/background',
    },
  ]
  const selectedItem = items.find(item => item.id === status)
  const widestAlternative = _.maxBy(items, item => item.label.length)?.label ?? ''
  const dropdownProps: SingleSelectDropdownProps<'correct' | 'incorrect'> = {
    selectedItem: selectedItem,
    renderTrigger: () => (
      <DefaultDropdownTrigger grow>
        {selectedItem !== undefined && (
          <DropdownTextContainer>
            <Icon color={selectedItem.color} iconId={selectedItem.icon} />
            <DropdownTextHolder>
              <InvisibleSpan>{widestAlternative}</InvisibleSpan>
              <DropdownText color={selectedItem.color}>{selectedItem.label}</DropdownText>
            </DropdownTextHolder>
          </DropdownTextContainer>
        )}
      </DefaultDropdownTrigger>
    ),
    onSelect: item => {
      // Set all other items to incorrect first in pick-the-best-option
      if (isElementType('question-card-pick-the-best-option-body', questionBody) && item.id === 'correct') {
        for (const child of questionBody.children) {
          if (child.status === 'correct') {
            updateNodeWithId(editor, child.id, {
              status: 'incorrect',
            })
          }
        }
      }

      updateNodeWithId(editor, element.id, {
        status: item.id,
      })
    },
    menuItems: items,
  }

  return (
    <QuestionCardMultipleChoiceAlternativeRow
      alternativeStatus='create'
      radio={isElementType('question-card-pick-the-best-option-body', questionBody)}
      status={status}
      dropdownProps={dropdownProps}
      onRemove={() => {
        const pathToElement = findPathToNodeWithId(editor, element.id)
        try {
          if (pathToElement !== undefined) {
            Transforms.select(editor, Editor.start(editor, Path.previous(pathToElement)))
          }
        } catch (e) {
          /* empty */
        }
        removeNodeWithId(editor, element.id)
      }}
      numberOfAlternatives={numberOfAlternatives}
      isEmpty={isEmpty}
      {...props}
    >
      {children}
    </QuestionCardMultipleChoiceAlternativeRow>
  )
}

const QuestionCardLiveQuizMultipleChoiceAlternativeUI = styled(View).attrs({
  direction: 'row',
  gap: '16',
  padding: '24',
  radius: 'size-20',
  cursor: 'pointer',
  grow: true,
  animated: true,
})<{ $canSelect: boolean; $selectedThisAlternative: boolean }>`
  opacity: ${p => (p.$canSelect === false && p.$selectedThisAlternative === false ? 0.5 : 1)};
  font-weight: 500;
`

const StyledText = styled(Text)`
  opacity: 0.8;
`

const AlternativeUserIndicators: React.FC<{ userIds: string[] }> = ({ userIds }) => {
  const { users } = useLiveQuizContext()
  const numberOfUsers = userIds.length

  return (
    <View direction='row' gap='4'>
      {userIds.slice(0, 7).map(userId => {
        const user = users.find(user => user.userId === userId)

        if (user === undefined) {
          return null
        }

        return <LiveQuizAvatarforStack key={user.userId} {...user} size='question' />
      })}
      {numberOfUsers > 7 && (
        <StyledText color={'purpleLight'} size='technical'>
          + {numberOfUsers - 7}
        </StyledText>
      )}
    </View>
  )
}

const QuestionCardLiveQuizMultipleChoiceAlternative: SlateFC = props => {
  // Show avatars if available after answering

  const { element } = props

  assertElementType('question-card-multiple-choice-alternative', element)

  const { respond, selectedAlternativeId, answers } = useLiveQuizQuestionCardContext()

  const canSelect = selectedAlternativeId === undefined
  const selectedThisAlternative = selectedAlternativeId === element.id

  const onClick = useCallback(() => {
    if (!canSelect) {
      return
    }

    void respond({
      alternativeId: element.id,
    })
  }, [respond, element.id, canSelect])

  const background = selectedThisAlternative ? 'white' : 'surface/soft'

  const thisAlternativeAnswers = answers[element.id] ?? []

  const isCorrectAlternative = element.status === 'correct'

  const icon = iife(() => {
    if (canSelect) {
      return <RadioCheckbox checked={false} radio={true} />
    }

    if (isCorrectAlternative && selectedThisAlternative) {
      return <Icon iconId='checkmark--filled' color={'greenBright'} size={'size-24'} />
    }

    if (isCorrectAlternative && !selectedThisAlternative) {
      return <Icon iconId='checkmark--filled' color={'white'} size={'size-24'} />
    }

    if (!isCorrectAlternative && selectedThisAlternative) {
      return <Icon iconId={'close--circle--filled'} color={'redVivid'} size={'size-24'} />
    }

    if (!isCorrectAlternative && !selectedThisAlternative) {
      return <Icon iconId={'close--circle--filled'} color={'white'} size={'size-24'} />
    }

    return <></>
  })

  return (
    <QuestionCardLiveQuizMultipleChoiceAlternativeUI
      $canSelect={canSelect}
      $selectedThisAlternative={selectedThisAlternative}
      background={background}
      whileHover={canSelect ? { scale: 1.03 } : {}}
      animate={{ scale: !canSelect && isCorrectAlternative ? 1.05 : 1 }}
      onClick={onClick}
    >
      {icon}

      <View grow>
        <View grow>{props.children}</View>

        {!canSelect && <AlternativeUserIndicators userIds={thisAlternativeAnswers} />}
      </View>
    </QuestionCardLiveQuizMultipleChoiceAlternativeUI>
  )
}

export const QuestionCardMultipleChoiceAlternative: SlateFC = props => {
  const { element, readOnly } = props
  const { preview } = useRenderingContext()
  assertElementType('question-card-multiple-choice-alternative', element)
  const Renderer =
    !readOnly && preview === false
      ? QuestionCardMultipleChoiceAlternativeCreate
      : QuestionCardMultipleChoiceAlternativeLearner

  const isLiveQuizContext = useIsInLiveQuizContext()

  if (isLiveQuizContext) {
    return <QuestionCardLiveQuizMultipleChoiceAlternative {...props} />
  }

  return (
    <AlternativeContextProvider>
      <Renderer {...props} />
    </AlternativeContextProvider>
  )
}
