import React from 'react'
import { insightsWidgetHistoryLogger } from 'sierra-client/features/insights/logger'
import { useDispatch } from 'sierra-client/state/hooks'

// An enum with all the types of actions to use in our reducer
enum HistoryActionKind {
  UNDO = 'UNDO',
  REDO = 'REDO',
  SET = 'SET',
}

type UndoAction = {
  type: HistoryActionKind.UNDO
}

type RedoAction = {
  type: HistoryActionKind.REDO
}

type SetAction<T> = {
  type: HistoryActionKind.SET
  newPresent: T
}

type HistoryAction<T> = UndoAction | RedoAction | SetAction<T>

type State<T> = {
  past: T[]
  present: T
  future: T[]
}

function useHistoryStateReducer<T>(state: State<T>, action: HistoryAction<T>): State<T> {
  const { past, present, future } = state
  switch (action.type) {
    case HistoryActionKind.UNDO:
      return {
        past: past.slice(0, past.length - 1),
        present: past[past.length - 1] ?? present,
        future: present !== undefined ? [present, ...future] : [...future],
      }
    case HistoryActionKind.REDO:
      return {
        past: [...past, present],
        present: future[0] ?? present,
        future: future.slice(1),
      }
    case HistoryActionKind.SET:
      if (action.newPresent === present) {
        return state
      }

      return {
        past: [...past, present],
        present: action.newPresent,
        future: [],
      }
  }
}

export type UseHistoryState<T> = {
  state: State<T>['present']
  set: (newState: T) => void
  undo: () => void
  redo: () => void
  canUndo: boolean
  canRedo: boolean
}

export function useHistoryState<T>(initialPresent: T): UseHistoryState<T> {
  const initialPresentRef = React.useRef<T>(initialPresent)
  const dispatchLogger = useDispatch()

  const [state, dispatch] = React.useReducer(useHistoryStateReducer<T>, {
    past: [],
    future: [],
    present: initialPresentRef.current,
  })

  const canUndo = state.past.length !== 0
  const canRedo = state.future.length !== 0

  const undo = React.useCallback(() => {
    if (canUndo) {
      dispatch({ type: HistoryActionKind.UNDO })

      void dispatchLogger(insightsWidgetHistoryLogger({ action: 'undo' }))
    }
  }, [canUndo, dispatchLogger])

  const redo = React.useCallback(() => {
    if (canRedo) {
      dispatch({ type: HistoryActionKind.REDO })
      void dispatchLogger(insightsWidgetHistoryLogger({ action: 'redo' }))
    }
  }, [canRedo, dispatchLogger])

  const set = React.useCallback((newPresent: T) => dispatch({ type: HistoryActionKind.SET, newPresent }), [])

  return { state: state.present, set, undo, redo, canUndo, canRedo }
}
