import { Dispatch, createContext, useCallback, useContext, useMemo, useState } from 'react'
import { useConfirmationModalContext } from 'sierra-client/components/common/modals/confirmation-modal'
import { useNotif } from 'sierra-client/components/common/notifications'
import { useTranslation } from 'sierra-client/hooks/use-translation'
import { postWithUserErrorException } from 'sierra-client/state/api'
import { store } from 'sierra-client/state/store'
import { FCC } from 'sierra-client/types'
import { CourseShareLinkAccessLevel, CourseVisibilityInOrg } from 'sierra-domain/api/author-v2'
import { NanoId12 } from 'sierra-domain/api/nano-id'
import { RequestError } from 'sierra-domain/error'
import {
  XRealtimeAuthorGetCoursePermissionSettings,
  XRealtimeAuthorSetCoursePermissionSettings,
} from 'sierra-domain/routes'

type WrapConfirmationModal = (param: { bodyText: string; confirmLabel: string }) => Promise<boolean>

export type CoursePermissionSettings =
  | { status: 'idle' }
  | { status: 'loading' }
  | {
      status: 'done'
      shareLinkAccessLevel: CourseShareLinkAccessLevel
      visibilityInOrg: CourseVisibilityInOrg
    }
  | { status: 'error' }

type FetchInitialCoursePermissionSettings = (courseId: NanoId12) => Promise<void>

type UpdateShareLinkAccessLevel = (
  courseId: NanoId12,
  shareLinkAccessLevel: CourseShareLinkAccessLevel
) => Promise<void>

type UpdateVisibilityInOrg = (courseId: NanoId12, visibilityInOrg: CourseVisibilityInOrg) => Promise<void>

type UseCoursePermissionSettings = () => {
  coursePermissionSettings: CoursePermissionSettings
  fetchInitialCoursePermissionSettings: FetchInitialCoursePermissionSettings
  updateShareLinkAccessLevel: UpdateShareLinkAccessLevel
  updateVisibilityInOrg: UpdateVisibilityInOrg
}

type CoursePermissionSettingsContext = {
  coursePermissionSettings: CoursePermissionSettings
  setCoursePermissionSettings: Dispatch<CoursePermissionSettings>
}

const CoursePermissionSettingsContext = createContext<CoursePermissionSettingsContext | undefined>(undefined)

export const CoursePermissionSettingsContextProvider: FCC = ({ children }) => {
  const [coursePermissionSettings, setCoursePermissionSettings] = useState<CoursePermissionSettings>({
    status: 'idle',
  })

  const value: CoursePermissionSettingsContext = useMemo(
    () => ({ coursePermissionSettings, setCoursePermissionSettings }),
    [coursePermissionSettings, setCoursePermissionSettings]
  )
  return (
    <CoursePermissionSettingsContext.Provider value={value}>
      {children}
    </CoursePermissionSettingsContext.Provider>
  )
}

function useCoursePermissionSettingsContext(): CoursePermissionSettingsContext {
  const context = useContext(CoursePermissionSettingsContext)
  if (context === undefined)
    throw new Error(
      '`CoursePermissionSettingsContext` was not found. The component must be wrapped in a `<CoursePermissionSettingsContextProvider>`'
    )

  return context
}

export const useCoursePermissionSettings: UseCoursePermissionSettings = () => {
  const { t } = useTranslation()
  const notif = useNotif()
  const confirmationModalContext = useConfirmationModalContext()
  const { coursePermissionSettings, setCoursePermissionSettings } = useCoursePermissionSettingsContext()

  const handleError = useCallback(
    (error: unknown): void => {
      setCoursePermissionSettings({ status: 'error' })

      if (error instanceof RequestError) {
        if (error.status === 401) {
          notif.push({ type: 'forbidden' })
        } else {
          notif.push({ type: 'error' })
        }
      }
    },
    [notif, setCoursePermissionSettings]
  )

  const fetchInitialCoursePermissionSettings = useCallback<FetchInitialCoursePermissionSettings>(
    async courseId => {
      if (coursePermissionSettings.status !== 'loading') {
        try {
          setCoursePermissionSettings({ status: 'loading' })

          const result = await postWithUserErrorException(
            XRealtimeAuthorGetCoursePermissionSettings,
            { courseId },
            store.dispatch,
            { notifications: false }
          )

          setCoursePermissionSettings({
            status: 'done',
            shareLinkAccessLevel: result.shareLinkAccessLevel,
            visibilityInOrg: result.visibilityInOrg,
          })
        } catch (error) {
          handleError(error)
        }
      }
    },
    [coursePermissionSettings.status, handleError, setCoursePermissionSettings]
  )

  const wrapConfirmationModal = useCallback<WrapConfirmationModal>(
    ({ bodyText, confirmLabel }) => {
      return new Promise(resolve => {
        confirmationModalContext.show({
          bodyText,
          confirmLabel,
          onConfirm: () => {
            resolve(true)
          },
          onAborted() {
            resolve(false)
          },
        })
      })
    },
    [confirmationModalContext]
  )

  const confirmVisibilityChange = useCallback(
    async (
      nextVisibilityState: CourseVisibilityInOrg,
      currentVisibilityState: CourseVisibilityInOrg
    ): ReturnType<WrapConfirmationModal> => {
      if (
        nextVisibilityState === 'private' &&
        (currentVisibilityState === 'visible-admins' || currentVisibilityState === 'visible-everyone')
      ) {
        return wrapConfirmationModal({
          bodyText: t('share.confirm-modal.visibility.body'),
          confirmLabel: t('dictionary.continue'),
        })
      }

      return true
    },
    [t, wrapConfirmationModal]
  )

  const updateShareLinkAccessLevel = useCallback<UpdateShareLinkAccessLevel>(
    async (courseId, shareLinkAccessLevel) => {
      try {
        if (coursePermissionSettings.status === 'done') {
          const visibilityInOrg =
            shareLinkAccessLevel === 'invite-only' &&
            coursePermissionSettings.visibilityInOrg === 'visible-everyone'
              ? 'visible-admins'
              : coursePermissionSettings.visibilityInOrg

          const confirmed = await confirmVisibilityChange(
            visibilityInOrg,
            coursePermissionSettings.visibilityInOrg
          )

          if (confirmed) {
            setCoursePermissionSettings({
              status: 'done',
              shareLinkAccessLevel,
              visibilityInOrg,
            })
            await postWithUserErrorException(
              XRealtimeAuthorSetCoursePermissionSettings,
              {
                courseId,
                shareLinkAccessLevel,
                visibilityInOrg,
              },
              store.dispatch,
              { notifications: false }
            )
          }
        }
      } catch (error) {
        handleError(error)
      }
    },
    [confirmVisibilityChange, coursePermissionSettings, handleError, setCoursePermissionSettings]
  )

  const updateVisibilityInOrg = useCallback<UpdateVisibilityInOrg>(
    async (courseId, visibilityInOrg) => {
      try {
        if (coursePermissionSettings.status === 'done') {
          const shareLinkAccessLevel =
            visibilityInOrg === 'visible-everyone'
              ? 'can-view'
              : coursePermissionSettings.shareLinkAccessLevel

          const confirmed = await confirmVisibilityChange(
            visibilityInOrg,
            coursePermissionSettings.visibilityInOrg
          )

          if (confirmed) {
            setCoursePermissionSettings({
              status: 'done',
              shareLinkAccessLevel,
              visibilityInOrg,
            })

            await postWithUserErrorException(
              XRealtimeAuthorSetCoursePermissionSettings,
              {
                courseId,
                shareLinkAccessLevel,
                visibilityInOrg,
              },
              store.dispatch,
              { notifications: false }
            )
          }
        }
      } catch (error) {
        handleError(error)
      }
    },
    [confirmVisibilityChange, coursePermissionSettings, handleError, setCoursePermissionSettings]
  )

  return {
    coursePermissionSettings,
    fetchInitialCoursePermissionSettings,
    updateShareLinkAccessLevel,
    updateVisibilityInOrg,
  }
}
