import React from 'react'
import { Heading, View } from 'sierra-ui/primitives'

export const MetricWidgetPreview: React.FC<{ value: number }> = ({ value }) => (
  <View direction='column' grow justifyContent='flex-end'>
    <Heading size='h5' bold>
      {value}
    </Heading>
  </View>
)
