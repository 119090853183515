import _ from 'lodash'
import React, { useEffect, useMemo } from 'react'
import { useInView } from 'react-intersection-observer'
import { CellProps, Column, useTable } from 'react-table'
import { getCourseDefaultImage } from 'sierra-client/api/content'
import { IconListItem, VerticalIconList } from 'sierra-client/components/common/icon-list'
import { SanaImage } from 'sierra-client/components/common/image'
import { RadioButton } from 'sierra-client/components/common/inputs/radio'
import { Pill, PillRow } from 'sierra-client/components/common/pill'
import { CourseMark } from 'sierra-client/components/courses/mark'
import { Table } from 'sierra-client/components/table/table'
import { TableScrollContainer } from 'sierra-client/components/table/table-utils'
import { useLocalizedFormatters, useTimeFormatter } from 'sierra-client/core/format'
import { useTranslation } from 'sierra-client/hooks/use-translation'
import {
  BetweenContainer,
  FilterChoice,
  InfiniteScrollMessage,
  TableFooter,
} from 'sierra-client/views/manage/components/common'
import { FilterPopover } from 'sierra-client/views/manage/components/filter-popover'
import { RoundedSearchBar } from 'sierra-client/views/manage/components/rounded-search-bar'
import { LinkedInCourseDetails } from 'sierra-client/views/manage/linked-in/linked-in-course-details'
import {
  LINKED_IN_DIFFICULTY_LEVELS,
  getDifficultyLevelTranslationKey,
  getImportSelectKey,
} from 'sierra-client/views/manage/linked-in/linked-in-utils'
import {
  PartnerCourseRecordWithStatus,
  useLinkedInCourses,
} from 'sierra-client/views/manage/linked-in/use-linkedin-couses'
import { LinkedInTargetLocalLanguages } from 'sierra-domain/api/partner'
import { ImageUnion } from 'sierra-domain/content/v2/image-union'
import { TruncatedText } from 'sierra-ui/components'
import { Button, Checkbox, Spacer, Text, View } from 'sierra-ui/primitives'
import { palette, token } from 'sierra-ui/theming'
import { narrowDotSeparator } from 'sierra-ui/utils'
import styled from 'styled-components'

const Divider = styled.div`
  width: 100%;
  background-color: ${token('border/strong')};
  height: 1px;
`

const LinkedInImage = styled(SanaImage)`
  border-radius: 4px;
  max-width: 200px;
  min-width: 120px;
`

const SearchBar = styled.div`
  display: flex;
  align-items: center;
  position: sticky;
  top: -1rem;
  background-color: ${palette.primitives.white};
  padding: 2rem 2rem 1rem;
  z-index: 2;
`

type LinkedInContentTableProps = {
  courseDetailsUrn?: string
  setCourseDetailsUrn: (urn: string | undefined) => void
  onAfterImport: () => void
  onClose: () => void
}

const RightMargin = styled.div`
  margin-right: 4px;
`

export const LinkedInContentTable: React.FC<LinkedInContentTableProps> = ({
  courseDetailsUrn,
  setCourseDetailsUrn,
  onAfterImport,
  onClose,
}) => {
  const { t, dynamicT } = useTranslation()
  const { formatTimestamp } = useLocalizedFormatters()
  const {
    data,
    isLoading,
    hasMore,
    liveFilter,
    importedIds,
    isImporting,
    importLinkedInCourses,
    setFilter,
    toggleSelectedCourse,
    next,
  } = useLinkedInCourses()

  const { timeFormatter } = useTimeFormatter()
  const [inViewRef, shouldLoadMore] = useInView({ threshold: 0 })

  const idsToImport = useMemo(
    () => _.entries(importedIds).flatMap(([urn, status]) => (status === 'selected' ? [urn] : [])),
    [importedIds]
  )

  const selectedCourseDetails = useMemo(
    () => data.find(course => course.urn === courseDetailsUrn),
    [courseDetailsUrn, data]
  )

  const resolveImage = (image: ImageUnion | undefined): string => {
    if (image?.type === 'url') {
      return image.url
    }
    return getCourseDefaultImage()
  }

  const columns: Column<PartnerCourseRecordWithStatus>[] = useMemo(
    () => [
      {
        id: 'image',
        Cell: (p: CellProps<PartnerCourseRecordWithStatus>) => {
          const { image } = p.row.original
          return (
            <CourseMark kind='linkedin'>
              <LinkedInImage src={resolveImage(image)} ratio='3:2' />
            </CourseMark>
          )
        },
        width: '10%',
      },
      {
        id: 'title-and-tags',
        Cell: (p: CellProps<PartnerCourseRecordWithStatus>) => {
          const { tags, title } = p.row.original
          const displayTags = tags.slice(0, 2)
          const extraNTags: string | undefined = tags.length >= 3 ? `+ ${tags.length - 2}` : undefined

          return (
            <View direction='column' margin='xxsmall' alignItems='flex-start' gap='none'>
              <TruncatedText color='foreground/primary' size='small' lines={1} bold>
                {title}
              </TruncatedText>
              <Spacer size='xxsmall' />
              <PillRow>
                {displayTags.map(liTag => (
                  <Pill key={liTag}>{liTag}</Pill>
                ))}
                <Text color='foreground/muted' size='small' spacing='xxsmall'>
                  {extraNTags}
                </Text>
              </PillRow>
            </View>
          )
        },
        width: '46%',
      },
      {
        id: 'details',
        Cell: (p: CellProps<PartnerCourseRecordWithStatus>) => {
          const { contributors, difficultyLevel, timeToComplete, lastUpdatedAt } = p.row.original

          return (
            <VerticalIconList spacing='xxsmall'>
              <IconListItem
                iconId='time'
                text={[
                  timeFormatter(timeToComplete),
                  ...(difficultyLevel === undefined
                    ? []
                    : [narrowDotSeparator, t(getDifficultyLevelTranslationKey(difficultyLevel))]),
                ]
                  .join(' ')
                  .trim()}
              />
              <IconListItem
                iconId='notebook'
                text={[
                  contributors.map(c => c.name).join(', '),
                  narrowDotSeparator,
                  formatTimestamp(lastUpdatedAt),
                ].join(' ')}
              />
            </VerticalIconList>
          )
        },
        width: '42%',
      },
      {
        id: 'actions',
        accessor: 'importStatus',
        Cell: p => {
          const { importStatus, urn } = p.row.original
          return (
            <View justifyContent='flex-end' alignItems='flex-start' gap='none'>
              <Button
                variant={importStatus === 'selected' ? 'primary' : 'secondary'}
                disabled={importStatus === 'imported'}
                onClick={e => {
                  e.stopPropagation()
                  toggleSelectedCourse(urn)
                }}
              >
                {t(getImportSelectKey(importStatus))}
              </Button>
            </View>
          )
        },
        width: '12%',
      },
    ],
    [timeFormatter, toggleSelectedCourse, t, formatTimestamp]
  )
  const tableInstance = useTable({ data, columns })

  useEffect(() => {
    if (isLoading || !hasMore || !shouldLoadMore) return
    next()
  }, [hasMore, isLoading, next, shouldLoadMore])

  return (
    <View justifyContent='center' alignItems='flex-start' gap='none'>
      {selectedCourseDetails !== undefined ? (
        <LinkedInCourseDetails
          course={selectedCourseDetails}
          action={
            <View>
              <Button
                disabled={selectedCourseDetails.importStatus === 'imported'}
                onClick={() => {
                  toggleSelectedCourse(selectedCourseDetails.urn)
                }}
              >
                {t(getImportSelectKey(selectedCourseDetails.importStatus))}
              </Button>
              <Spacer size='xxsmall' />
              <a href={selectedCourseDetails.url} target='_blank' rel='noopener noreferrer'>
                <Button variant={'secondary'} icon='launch' decoratorPosition='right'>
                  {t('manage.linked-in.open-in-linkedin')}
                </Button>
              </a>
            </View>
          }
        />
      ) : (
        <>
          <TableScrollContainer>
            <SearchBar>
              <RoundedSearchBar
                value={liveFilter.filterKeyword}
                onChange={value => setFilter(p => ({ ...p, filterKeyword: value }))}
                placeholder={t('manage.linked-in.search-placeholder')}
                maxWidth='100%'
              />

              <Spacer />

              <FilterPopover activeCount={liveFilter.difficultyLevels.length}>
                <View direction='column' padding='small' borderColor='border/default' radius='small'>
                  <Text color='LEGACY_DEFAULT_TEXT_COLOR_REPLACE_ASAP' size='small' bold spacing='xxsmall'>
                    {t('manage.linked-in.level')}
                  </Text>
                  {LINKED_IN_DIFFICULTY_LEVELS.map(level => (
                    <FilterChoice key={level}>
                      <RightMargin>
                        <Checkbox
                          checked={liveFilter.difficultyLevels.includes(level)}
                          onCheckedChange={() => {
                            const shouldRemove = liveFilter.difficultyLevels.includes(level)
                            setFilter(f => ({
                              ...f,
                              difficultyLevels: shouldRemove
                                ? f.difficultyLevels.filter(l => l !== level)
                                : f.difficultyLevels.concat(level),
                            }))
                          }}
                        />
                      </RightMargin>
                      <Text color='LEGACY_DEFAULT_TEXT_COLOR_REPLACE_ASAP' size='small'>
                        {t(getDifficultyLevelTranslationKey(level))}
                      </Text>
                    </FilterChoice>
                  ))}

                  <Spacer size='xxsmall' />

                  <Text color='LEGACY_DEFAULT_TEXT_COLOR_REPLACE_ASAP' size='small' bold spacing='xxsmall'>
                    {t('dictionary.language')}
                  </Text>
                  {_.chain(LinkedInTargetLocalLanguages)
                    .map(value => ({
                      code: value,
                      label: dynamicT(`language.${value}`),
                    }))
                    .orderBy(lang => lang.label)
                    .map(({ code, label }) => (
                      <FilterChoice key={code}>
                        <RadioButton
                          name={code}
                          size='small'
                          value={code}
                          label={label}
                          checked={liveFilter.filterTargetLocaleLanguage === code}
                          onChange={() =>
                            setFilter(f => ({
                              ...f,
                              filterTargetLocaleLanguage: code,
                            }))
                          }
                        />
                      </FilterChoice>
                    ))
                    .value()}
                </View>
              </FilterPopover>
            </SearchBar>
            <Table
              tableInstance={tableInstance}
              getTHeadProps={() => ({ style: { display: 'none' } })}
              getRowProps={row => ({
                onClick: () => setCourseDetailsUrn(row.original.urn),
              })}
            />
            {hasMore || isLoading ? (
              <InfiniteScrollMessage ref={inViewRef} text={t('manage.courses.table-loading')} showSanaLogo />
            ) : (
              <InfiniteScrollMessage
                text={
                  tableInstance.rows.length > 0
                    ? t('manage.courses.table-end')
                    : t('manage.courses.no-results')
                }
              />
            )}
          </TableScrollContainer>

          <TableFooter>
            <Divider />
            <Spacer size='small' />
            <BetweenContainer>
              <div>{t('table.n-selected', { count: idsToImport.length })}</div>
              <View alignItems='flex-start' gap='none'>
                <Button
                  loading={isImporting}
                  onClick={async () => {
                    await importLinkedInCourses(idsToImport)
                    onAfterImport()
                  }}
                  disabled={idsToImport.length === 0}
                >
                  {t('manage.course.import')}
                </Button>
                <Spacer size='xxsmall' />
                <Button variant='secondary' onClick={onClose}>
                  {t('dictionary.cancel')}
                </Button>
              </View>
            </BetweenContainer>
          </TableFooter>
        </>
      )}
    </View>
  )
}
