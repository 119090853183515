import { pipe } from 'lodash/fp'
import React from 'react'
import { graphql } from 'sierra-client/api/graphql/gql'
import { useGraphQuery } from 'sierra-client/api/hooks/use-graphql-query'
import { useTranslation } from 'sierra-client/hooks/use-translation'
import { booleanSerializer, useQueryState } from 'sierra-client/lib/querystate/use-query-state'
import {
  IssuedCertificateItem,
  meToCertificateItem,
  sortCertificatesOnStatus,
  uniqueIssuedCertificates,
} from 'sierra-client/views/workspace/learn/my-certificates/utils'

import { UseQueryResult } from '@tanstack/react-query'
import { CertificateList } from 'sierra-client/views/workspace/learn/my-certificates/list'
import { isNonEmptyArray } from 'sierra-domain/utils'
import { Panel } from 'sierra-ui/components'
import { IconButton, Text, View } from 'sierra-ui/primitives'
import styled from 'styled-components'

const meQuery = graphql(`
  query MeQuery {
    me {
      id
      issuedCertificates {
        id
        expiresAt
        revokedAt
        issuedAt
        pdfUrl
        snapshotImageUrl
        supportingNote
        supportingFileUrl
        certificate {
          id
          title
          description
          certificateAuthority
          validityPeriod {
            duration
            timeUnit
            expires
          }
          templateData {
            templateId
            orientation
            logotype
            textColor
            backgroundColor
            backgroundImage {
              file
              width
              height
            }
          }
        }
      }
    }
  }
`)

/**
 * Hooks to consolidate logic of grabbing 'my certificates'.
 * */
const useMyCertificates = (): UseQueryResult<IssuedCertificateItem[], unknown> => {
  const res = useGraphQuery(
    {
      document: meQuery,
      queryOptions: {
        select: d => {
          const getMyCerts = pipe(
            // All transformations of certificates
            meToCertificateItem,
            uniqueIssuedCertificates,
            sortCertificatesOnStatus
          )
          return getMyCerts(d)
        },
      },
    },
    {}
  )
  return res
}

const PanelCertificateList: React.FC<{ hasCTA?: boolean }> = props => {
  const certs = useMyCertificates()
  if (certs.isPending) {
    // No loading was decided as the view is quite small
    return null
  }

  if (certs.isError) {
    // No error handling was decided as the view is quite small
    return null
  }

  if (!isNonEmptyArray(certs.data)) {
    // No empty state was decided as the view is quite small
    return null
  }

  return <CertificateList items={certs.data} {...props} />
}

// TODO: This should perhaps not be used?
const TextButton = styled(Text).attrs({ 'role': 'button', 'aria-pressed': false })`
  transition: all 0.1s cubic-bezier(0.25, 0.1, 0.25, 1);
  cursor: pointer;

  &:hover {
    opacity: 1;
  }
`

const CloseButtonContainer = styled(View)`
  position: absolute;
  top: 0px;
  right: 0px;
`

const ShowMoreCertificates: React.FC<{ count: number }> = ({ count }) => {
  const [open, setOpen] = useQueryState(booleanSerializer, false, 'my-certificate-list-open')
  const { t } = useTranslation()

  return (
    <>
      <TextButton
        aria-label='Show my certificates'
        onClick={() => setOpen(true)}
        color='foreground/muted'
        size='small'
        bold
      >
        {t('search.view-more', { count })}
      </TextButton>
      <Panel disableScrollbarGutter size={{ width: 656 }} open={open} onClose={() => setOpen(false)}>
        <CloseButtonContainer tabIndex={0} padding='xsmall'>
          <IconButton iconId='close' variant='transparent' onClick={() => setOpen(false)} />
        </CloseButtonContainer>
        <View direction='column' gap='medium' padding='medium'>
          <Text color='foreground/primary' size='large' bold>
            {t('learner.insights.your-certificates')}
          </Text>
          <PanelCertificateList hasCTA />
        </View>
      </Panel>
    </>
  )
}

const TO_BE_SEEN = 2
export const MyCertificates: React.FC = () => {
  const { t } = useTranslation()
  const certs = useMyCertificates()
  if (certs.isPending) {
    // No loading was decided as the view is quite small
    return null
  }

  if (certs.isError) {
    // No error handling was decided as the view is quite small
    return null
  }

  if (!isNonEmptyArray(certs.data)) {
    // No empty state was decided as the view is quite small
    return null
  }

  const aboveTheFold = certs.data.slice(0, TO_BE_SEEN)
  const belowTheFold = certs.data.slice(TO_BE_SEEN)

  return (
    <View direction='column' gap='12' aria-label='My certificates'>
      <Text size='small' color='foreground/secondary' bold>
        {t('learner.insights.your-certificates')}
      </Text>
      <CertificateList items={aboveTheFold} />
      {belowTheFold.length > 0 && <ShowMoreCertificates count={belowTheFold.length} />}
    </View>
  )
}
