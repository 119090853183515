import { logger } from 'sierra-client/logger/logger'

const metaDatanotLoadedMessage =
  "Failed to execute 'requestPictureInPicture' on 'HTMLVideoElement': Metadata for the video element are not loaded yet."
const mustBeHandlingUserGestureMessage =
  "Failed to execute 'requestPictureInPicture' on 'HTMLVideoElement': Must be handling a user gesture if there isn't already an element in Picture-in-Picture."

const retryPiP = (videoEl: HTMLVideoElement, retryFn: () => Promise<void>): void => {
  videoEl.onloadedmetadata = async () => {
    try {
      await retryFn()
    } catch (e) {
      // Ignore browser error, requires user intervention
      if (e instanceof Error && e.message === mustBeHandlingUserGestureMessage) {
        return
      }
      logger.captureInfo(e)
    }
  }
}

export const handlePiPError = (
  e: unknown,
  videoEl: HTMLVideoElement | null,
  retryFn?: () => Promise<void>
): void => {
  if (e instanceof Error) {
    if (e.message === mustBeHandlingUserGestureMessage) {
      // Ignore browser error, requires user intervention
      return
    }
    if (e.message === metaDatanotLoadedMessage) {
      /* 
    This can happen if PiP is turned off because no video is on
    and then someone turns on their video while the Sana tab is in the background.
    It is possible that PiP will be able to trigger when metadata loads
    */
      if (videoEl !== null && retryFn !== undefined) {
        // Retry when metadata has loaded
        return retryPiP(videoEl, retryFn)
      }
    }
  } else {
    logger.captureInfo(e)
  }
}
