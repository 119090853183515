import { createAsyncThunk } from '@reduxjs/toolkit'
import { logger } from 'sierra-client/logger/logger'
import { postWithUserErrorException, typedPostWithRetry } from 'sierra-client/state/api'
import { selectCurrentPathId, selectCurrentProgramId } from 'sierra-client/state/content/selectors'
import { RootState } from 'sierra-client/state/types'
import { NextUp } from 'sierra-domain/api/backend-self-paced/types'
import { CourseId, LiveSessionId, NanoId12 } from 'sierra-domain/api/nano-id'
import { FlexibleContentStatus } from 'sierra-domain/api/strategy-v2'
import { FileId, FolderId } from 'sierra-domain/flexible-content/identifiers'
import {
  XRealtimeStrategyLiveSessionSetCardStatus,
  XRealtimeStrategySelfPacedContentPlacementTestClose,
  XRealtimeStrategySelfPacedContentSetCardStatus,
  XRealtimeStrategySelfPacedContentStatus,
} from 'sierra-domain/routes'

const emptyStatus: FlexibleContentStatus = {
  courseStatus: {
    completed: undefined,
    started: undefined,
  },
  moduleStatuses: {},
  cardStatuses: {},
}

export const setCardProgressStarted = createAsyncThunk<
  { courseId: CourseId; status: FlexibleContentStatus; nextUp: NextUp; publishedAt: string | undefined },
  { fileId: FileId; courseId: CourseId; liveSessionId?: NanoId12 },
  { state: RootState }
>('card-progress/set-card-viewed', async ({ fileId, courseId, liveSessionId }, { getState }) => {
  const programId = selectCurrentProgramId(getState())
  const pathId = selectCurrentPathId(getState())

  try {
    if (liveSessionId !== undefined) {
      const { nextUp, publishedAt } = await typedPostWithRetry(XRealtimeStrategyLiveSessionSetCardStatus, {
        fileId,
        courseId,
        programId,
        pathId,
        sessionId: liveSessionId,
        status: 'started',
      })

      return { courseId, status: emptyStatus, nextUp, publishedAt }
    } else {
      const { status, nextUp, publishedAt } = await typedPostWithRetry(
        XRealtimeStrategySelfPacedContentSetCardStatus,
        {
          fileId,
          courseId,
          programId,
          pathId,
          status: 'started',
        }
      )

      return { courseId, status, nextUp, publishedAt }
    }
  } catch (e) {
    logger.warn('Failed to set card progress', { error: e })
    throw e
  }
})

export const setCardProgressCompleted = createAsyncThunk<
  { courseId: CourseId; status: FlexibleContentStatus; nextUp: NextUp; publishedAt: string | undefined },
  { fileId: FileId; courseId: CourseId; liveSessionId?: LiveSessionId },
  { state: RootState }
>('card-progress/set-card-completed', async ({ fileId, courseId, liveSessionId }, { getState }) => {
  const programId = selectCurrentProgramId(getState())
  const pathId = selectCurrentPathId(getState())

  try {
    if (liveSessionId !== undefined) {
      const { nextUp, publishedAt } = await typedPostWithRetry(XRealtimeStrategyLiveSessionSetCardStatus, {
        fileId,
        courseId,
        programId,
        pathId,
        sessionId: liveSessionId,
        status: 'completed',
      })

      return { courseId, status: emptyStatus, nextUp, publishedAt }
    } else {
      const { status, nextUp, publishedAt } = await typedPostWithRetry(
        XRealtimeStrategySelfPacedContentSetCardStatus,
        {
          fileId,
          courseId,
          programId,
          pathId,
          sessionId: liveSessionId,
          status: 'completed',
        }
      )

      return { courseId, status, nextUp, publishedAt }
    }
  } catch (e) {
    logger.warn('Could not set card progress to completed.', { error: e })
    throw e
  }
})

export const setCardProgressViewedAndCompleted = createAsyncThunk<
  void,
  { fileId: FileId; courseId: CourseId; liveSessionId?: LiveSessionId },
  { state: RootState }
>(
  'card-progress/set-card-viewed-and-completed',
  async ({ fileId, courseId, liveSessionId }, { dispatch }) => {
    try {
      await dispatch(setCardProgressStarted({ fileId, courseId, liveSessionId })).unwrap()
      await dispatch(setCardProgressCompleted({ fileId, courseId, liveSessionId })).unwrap()
    } catch (e) {
      logger.warn('Could not set card progress to viewed and completed.', { error: e })
      throw e
    }
  }
)

export const fetchCourseProgress = createAsyncThunk<
  { courseId: NanoId12; status: FlexibleContentStatus; nextUp: NextUp; publishedAt: string | undefined },
  { courseId: NanoId12; fileId: FileId | undefined },
  { state: RootState }
>('card-progress/fetch-course-progress', async ({ courseId, fileId }, { dispatch, getState }) => {
  const programId = selectCurrentProgramId(getState())
  const pathId = selectCurrentPathId(getState())

  try {
    const { status, nextUp, publishedAt } = await postWithUserErrorException(
      XRealtimeStrategySelfPacedContentStatus,
      { courseId, programId, pathId, fileId },
      dispatch
    )

    return { courseId, status, nextUp, publishedAt }
  } catch (e) {
    logger.warn('Failed to fetch course progress', { error: e })
    throw new Error('Failed to fetch course progress')
  }
})

export const closePlacementTest = createAsyncThunk<
  { courseId: NanoId12; status: FlexibleContentStatus; nextUp: NextUp; publishedAt: string | undefined },
  { courseId: NanoId12; moduleId: FolderId; fileId: FileId | undefined },
  { state: RootState }
>('card-progress/close-placement-test', async ({ courseId, moduleId, fileId }, { dispatch, getState }) => {
  const programId = selectCurrentProgramId(getState())
  const pathId = selectCurrentPathId(getState())

  try {
    const { status, nextUp, publishedAt } = await postWithUserErrorException(
      XRealtimeStrategySelfPacedContentPlacementTestClose,
      { courseId, moduleId, programId, pathId, fileId },
      dispatch
    )
    return { courseId, status, nextUp, publishedAt }
  } catch (e) {
    logger.warn('Failed to fetch course progress in close placement test', { error: e })
    throw new Error('Failed to fetch course progress in close placement test')
  }
})
