import React, { useEffect } from 'react'
import { Empty } from 'sierra-client/core/logging/page/logger'
import { useDeveloperToolsEnabled } from 'sierra-client/hooks/use-developer-tools-enabled'
import { SanaPage } from 'sierra-client/layout/sana-page'
import { getGlobalRouter } from 'sierra-client/router'
import { useSelector } from 'sierra-client/state/hooks'
import { selectIsInitializing } from 'sierra-client/state/user/user-selector'

export const requireDeveloperToolsEnabled =
  <T extends JSX.IntrinsicAttributes>(Component: React.ComponentType<T>): React.ComponentType<T> =>
  (props: T) => {
    const isInitializing = useSelector(selectIsInitializing)
    const developerToolsEnabled = useDeveloperToolsEnabled()

    useEffect(() => {
      if (isInitializing) return

      if (!developerToolsEnabled) {
        alert('You do not have permission to view this page')
        void getGlobalRouter().navigate({ to: '/' })
      }
    }, [developerToolsEnabled, isInitializing])

    return !isInitializing && developerToolsEnabled ? (
      <Component {...props} />
    ) : (
      <SanaPage page={Empty({ message: 'Checking if user has developer tooling access' })} />
    )
  }
