import { graphql } from 'sierra-client/api/graphql/gql'
import { CertificateDataQuery } from 'sierra-client/api/graphql/gql/graphql'
import { graphQuery } from 'sierra-client/api/hooks/use-graphql-query'

export const fetchCertificate = async (
  certificateId: string
): Promise<CertificateDataQuery['certificate']> => {
  const result = await graphQuery(
    graphql(`
      query certificateData($certificateId: CertificateId!) {
        certificate(id: $certificateId) {
          id
          title
          description
          certificateAuthority
          validityPeriod {
            duration
            timeUnit
            expires
          }
          templateData {
            templateId
            orientation
            logotype
            textColor
            backgroundColor
            backgroundImage {
              file
              width
              height
            }
          }
        }
      }
    `),
    { certificateId: certificateId }
  )
  if (result.certificate === undefined) {
    throw new Error('Certificate not found')
  }
  return result.certificate
}
