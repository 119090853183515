import type { BadgeTheme } from 'sierra-client/features/skills/components/badges/types'
import { token } from 'sierra-ui/theming'
import type { DesignToken, TokenFunction } from 'sierra-ui/theming/tokens/types'

export const getThemeItemColor = (value: string): string | TokenFunction => {
  return value.startsWith('token:') ? token(value.split(':')[1] as DesignToken) : value
}

type RichBadgeColors = {
  background: string
  backgroundColor: string
  foreground: string
  iconColor: string
  dropShadow: string
  titleColor: string
  subtitleColor: string
}

export const richBadgeThemeMap: Record<BadgeTheme, RichBadgeColors> = {
  titanium: {
    background:
      'radial-gradient(circle at var(--radial-x) var(--radial-y), #FFFFFF 10%, #9A9A9A 65%, #2C2C2C 100%)',
    backgroundColor: '#222222',
    foreground:
      'radial-gradient(circle at var(--radial-x) var(--radial-y), #707070 10%, #474747 65%, #484848  100%)',
    dropShadow: `
      drop-shadow(calc(-1 * var(--shadow-x)) calc(-1 * var(--shadow-y)) 0 rgba(0, 0, 0, 0.6))
      drop-shadow(var(--shadow-x) var(--shadow-y) 0 rgba(255, 255, 255, 0.2))
    `,
    iconColor: '#494848',
    titleColor: '#ffffff',
    subtitleColor: '#a0a0a0',
  },
  platinum: {
    background:
      'radial-gradient(circle at var(--radial-x) var(--radial-y), #DEDCE2 0%, #9A9A9A 50%, #2C2C2C 100%)',
    backgroundColor: '#AAA6B5',
    foreground:
      'radial-gradient(circle at var(--radial-x) var(--radial-y), #ACA8B9 15%, #878692 65%, #79757F 100%)',
    dropShadow: `
      drop-shadow(calc(-1 * var(--shadow-x)) calc(-1 * var(--shadow-y)) 0 rgba(0, 0, 0, 0.6))
      drop-shadow(var(--shadow-x) var(--shadow-y) 0 rgba(255, 255, 255, 0.4))
    `,
    iconColor: '#878692',
    titleColor: '#ffffff',
    subtitleColor: '#19181B',
  },
  gold: {
    background:
      'radial-gradient(circle at var(--radial-x) var(--radial-y), #FFFFFF 15%, #9A9A9A 65%, #2C2C2C 100%)',
    backgroundColor: '#DBC89C',
    foreground:
      'radial-gradient(circle at var(--radial-x) var(--radial-y), #FDEFE5 15%, #DCBE6A 65%, #B6912A 100%)',
    dropShadow: `
      drop-shadow(calc(-1 * var(--shadow-x)) calc(-1 * var(--shadow-y)) 0 rgba(0,0,0,0.3))
      drop-shadow(var(--shadow-x) var(--shadow-y) 0 rgba(255, 255, 255, 0.8))
    `,
    iconColor: '#DCBE6A',
    titleColor: 'rgba(0,0,0,0.9)',
    subtitleColor: 'rgba(0,0,0,0.6)',
  },
  silver: {
    background:
      'radial-gradient(circle at var(--radial-x) var(--radial-y), #FFFFFF 15%, #9A9A9A 65%, #2C2C2C 100%)',
    backgroundColor: '#EAEAEA',
    foreground:
      'radial-gradient(circle at var(--radial-x) var(--radial-y), #ececec 15%, #D4D4D9 65%, #D4D4D9 100%)',
    dropShadow: `
      drop-shadow(calc(-1 * var(--shadow-x)) calc(-1 * var(--shadow-y)) 0 rgba(0, 0, 0, 0.4))
      drop-shadow(var(--shadow-x) var(--shadow-y) 0 rgba(255, 255, 255, 1))
    `,
    iconColor: '#D4D4D9',
    titleColor: 'rgba(0,0,0,0.9)',
    subtitleColor: 'rgba(0,0,0,0.6)',
  },
  bronze: {
    background:
      'radial-gradient(circle at var(--radial-x) var(--radial-y), #f6d9d9 15%, #9A9A9A 50%, #2C2C2C 90%)',
    backgroundColor: '#E4BD98',
    foreground:
      'radial-gradient(circle at var(--radial-x) var(--radial-y), #e2c5c0 15%, #CB9C81 65%, #BC7F5D 100%)',
    dropShadow: `
      drop-shadow(calc(-1 * var(--shadow-x)) calc(-1 * var(--shadow-y)) 0 rgba(0, 0, 0, 0.6))
      drop-shadow(var(--shadow-x) var(--shadow-y) 0 rgba(255, 255, 255, 0.5))
    `,
    iconColor: '#DAB3A0',
    titleColor: 'rgba(0,0,0,0.9)',
    subtitleColor: 'rgba(0,0,0,0.7)',
  },
  sapphire: {
    background:
      'radial-gradient(circle at var(--radial-x) var(--radial-y), #3442b3 15%, #242f95 50%, #1c2471 90%)',
    backgroundColor: '#4f5480',
    foreground:
      'radial-gradient(circle at var(--radial-x) var(--radial-y), #839cdd 15%, #4d6ec1 65%, #395fbb 100%)',
    dropShadow: `
      drop-shadow(calc(-1 * var(--shadow-x)) calc(-1 * var(--shadow-y)) 0 rgba(0, 0, 0, 0.5))
      drop-shadow(var(--shadow-x) var(--shadow-y) 0 rgba(255,255,255, 0.6))
    `,
    iconColor: '#3A5FBB',
    titleColor: 'rgba(255,255,255,1)',
    subtitleColor: 'rgba(255,255,255,0.7)',
  },
  emerald: {
    background:
      'radial-gradient(circle at var(--radial-x) var(--radial-y), #01754c 15%, #11583e 50%, #123c2d 90%)',
    backgroundColor: '#63766f',
    foreground:
      'radial-gradient(circle at var(--radial-x) var(--radial-y), #6bb297 15%, #368166 65%, #288060 100%)',
    dropShadow: `
      drop-shadow(calc(-1 * var(--shadow-x)) calc(-1 * var(--shadow-y)) 0 rgba(0, 0, 0, 0.5))
      drop-shadow(var(--shadow-x) var(--shadow-y) 0 rgba(255, 255, 255, 0.6))
    `,
    iconColor: '#368166',
    titleColor: 'rgba(255,255,255,1)',
    subtitleColor: 'rgba(255,255,255,0.7)',
  },
  ruby: {
    background:
      'radial-gradient(circle at var(--radial-x) var(--radial-y), #841010 15%, #671111 50%, #480505 90%)',
    backgroundColor: '#852a2a',
    foreground:
      'radial-gradient(circle at var(--radial-x) var(--radial-y),  #de8a8a 15%, #ac4949 65%, #A13434 100%)',
    dropShadow: `
      drop-shadow(calc(-1 * var(--shadow-x)) calc(-1 * var(--shadow-y)) 0 rgba(0, 0, 0, 0.5))
      drop-shadow(var(--shadow-x) var(--shadow-y) 0 rgba(255, 255, 255, 0.6))
    `,
    iconColor: '#ac4949',
    titleColor: 'rgba(255,255,255,1)',
    subtitleColor: 'rgba(255,255,255,0.7)',
  },
  disabled: {
    background: '',
    backgroundColor: 'token:surface/default',
    foreground: 'token:form/background/2',
    dropShadow: '',
    iconColor: 'token:form/background/2',
    titleColor: 'token:form/disabled/foreground',
    subtitleColor: 'token:form/disabled/foreground',
  },
}

type SimpleBadgeColors = {
  backgroundColor: string
  foregroundColor: string
  iconColor: string
  dropShadow: string
}

export const simpleBadgeThemeMap: Record<BadgeTheme, SimpleBadgeColors> = {
  titanium: {
    backgroundColor: '#000000',
    foregroundColor: '#5A5A5A',
    iconColor: '#5A5A5A',
    dropShadow: 'drop-shadow(0px -1px 0px rgba(255, 255, 255, 0.5))',
  },
  platinum: {
    backgroundColor: 'linear-gradient(180deg, #DFDEE3, #AAA6B5 85%)',
    foregroundColor: '#878692',
    iconColor: '#878692',
    dropShadow: 'drop-shadow(0px 1px 0px #494752) drop-shadow(0px -1px 0px rgba(255, 255, 255, 0.35))',
  },
  gold: {
    backgroundColor: 'linear-gradient(180deg, #FCF8ED, #EAD8AE 85%)',
    foregroundColor: '#DCBD68',
    iconColor: '#DCBD68',
    dropShadow: 'drop-shadow(0px 1px 0px #A99A77) drop-shadow(0px -1px 0px rgba(255, 255, 255, 0.25))',
  },
  silver: {
    backgroundColor: 'linear-gradient(180deg, #F4F4F4, #E5E5E5 85%)',
    foregroundColor: '#D8D8DD',
    iconColor: '#D8D8DD',
    dropShadow: 'drop-shadow(0px 1px 0px #ADADAD) drop-shadow(0px -1px 0px rgba(255, 255, 255, 0.25))',
  },
  bronze: {
    backgroundColor: 'linear-gradient(180deg, #FAE5D6, #E3B991 85%)',
    foregroundColor: '#CB9C81',
    iconColor: '#CB9C81',
    dropShadow: 'drop-shadow(0px 1px 0px #A57E5D) drop-shadow(0px -1px 0px rgba(255, 255, 255, 0.15))',
  },
  sapphire: {
    backgroundColor: 'linear-gradient(180deg, #2935A8, #151B56 85%)',
    foregroundColor: '#4d6ec1',
    iconColor: '#4d6ec1',
    dropShadow:
      'drop-shadow(0px 1px 0px rgba(0,0,50,0.4)) drop-shadow(0px -1px 0px rgba(255, 255, 255, 0.4))',
  },
  emerald: {
    backgroundColor: 'linear-gradient(180deg, #08774F, #054830 85%)',
    foregroundColor: '#288060',
    iconColor: '#288060',
    dropShadow:
      'drop-shadow(0px 1px 0px rgba(0,50,0,0.4)) drop-shadow(0px -1px 0px rgba(255, 255, 255, 0.4))',
  },
  ruby: {
    backgroundColor: 'linear-gradient(180deg, #770808, #480505 85%)',
    foregroundColor: '#A13434',
    iconColor: '#A13434',
    dropShadow:
      'drop-shadow(0px 1px 0px rgba(50,0,0,0.4)) drop-shadow(0px -1px 0px rgba(255, 255, 255, 0.4))',
  },
  disabled: {
    backgroundColor: 'token:surface/default',
    foregroundColor: 'token:form/background/4',
    iconColor: 'token:form/background/4',
    dropShadow: '',
  },
}
