import { createFileRoute } from '@tanstack/react-router'

import { CustomInterfaceThemeProvider } from 'sierra-client/components/common/custom-interface-theme-provider'
import { requireLoggedIn } from 'sierra-client/core/require-logged-in'
import { requireOwner } from 'sierra-client/core/require-owner'
import { PageIdentifier, SanaPage } from 'sierra-client/layout/sana-page'
import { CreateNewPage } from 'sierra-client/views/workspace/create-new/create-new-page'

const Page = (): JSX.Element => {
  return (
    <SanaPage mode='light' page={PageIdentifier.NewCreatePage()}>
      <CustomInterfaceThemeProvider>
        <CreateNewPage />
      </CustomInterfaceThemeProvider>
    </SanaPage>
  )
}

const RouteComponent = requireOwner(requireLoggedIn(Page))

export const Route = createFileRoute('/create-new/')({
  component: RouteComponent as React.FC,
})
