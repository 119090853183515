import { createFileRoute } from '@tanstack/react-router'

import React from 'react'
import { queryClient } from 'sierra-client/api/query-client'
import { getFlag } from 'sierra-client/config/global-config'
import {
  InsightsHome,
  listDashboardQuery,
  listDashboardTemplatesQuery,
  LoadRecentOrDefaultDashboard,
} from 'sierra-client/features/insights'
import { PageIdentifier } from 'sierra-client/layout/sana-page'
import { useInsightsEnabled } from 'sierra-client/views/manage/hooks/use-insights-enabled'
import { ManagePageWrapper } from 'sierra-client/views/manage/utils/manage-page-wrapper'
import { PageHeader } from 'sierra-client/views/settings/components/page-header'
import { View } from 'sierra-ui/primitives'
import styled from 'styled-components'

// Sadly needed due to how the manage layout works
const Wrapper = styled(View)`
  height: 100%;
  width: 100%;
`

const InsightsDashboardsPage: React.FC = () => {
  const insightsEnabled = useInsightsEnabled()
  const newInsightsHome = getFlag('insights/new-home')

  if (!insightsEnabled) {
    return null
  }

  return (
    <ManagePageWrapper pageIdentifier={PageIdentifier.ManageInsights()}>
      <Wrapper direction='column'>
        <View direction='column'>
          <PageHeader title='manage.insights.headline' withoutMargin />

          {newInsightsHome ? <InsightsHome /> : <LoadRecentOrDefaultDashboard />}
        </View>
      </Wrapper>
    </ManagePageWrapper>
  )
}

const RouteComponent = InsightsDashboardsPage

export const Route = createFileRoute('/manage/insights/dashboards/')({
  component: RouteComponent as React.FC,
  loader: ({ context }) => {
    if (context.userId === undefined) return

    void queryClient.prefetchQuery(listDashboardTemplatesQuery)
    void queryClient.prefetchQuery(listDashboardQuery)
  },
})
