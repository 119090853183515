import { token } from 'sierra-ui/theming'
import styled, { css } from 'styled-components'

export const FullHeightWithPadding = styled.div<{ $hideBorder?: boolean }>`
  flex-direction: column;
  display: flex;
  height: 100%;

  ${p =>
    p.$hideBorder !== true &&
    css`
      border: 1px solid ${token('border/default')};
    `}

  border-radius: 12px;
  overflow: hidden;
`
