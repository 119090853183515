import { createFileRoute } from '@tanstack/react-router'

import React from 'react'
import { requireOwner } from 'sierra-client/core/require-owner'
import { useCachedQuery, useTypedMutation } from 'sierra-client/state/api'
import {
  XRealtimeAdminLiveSessionsListRecentlyDeletedLiveSessions,
  XRealtimeAdminLiveSessionsUndeleteLiveSession,
} from 'sierra-domain/routes'
import { TruncatedText } from 'sierra-ui/components'
import { Button, Text, View } from 'sierra-ui/primitives'
import styled from 'styled-components'

const MaxWidthTruncatedText = styled(TruncatedText)`
  max-width: 80ch;
`

const Ul = styled.ul`
  li + li {
    margin-top: 16px;
  }
`

export const Page: React.FC = () => {
  const deletedLiveSessionsQuery = useCachedQuery(
    XRealtimeAdminLiveSessionsListRecentlyDeletedLiveSessions,
    {}
  )
  const undeleteLiveSessionMutation = useTypedMutation(XRealtimeAdminLiveSessionsUndeleteLiveSession, {
    onSuccess: () => deletedLiveSessionsQuery.refetch(),
  })
  const deletedLiveSessions = deletedLiveSessionsQuery.data?.liveSessions
  return (
    <div>
      {deletedLiveSessionsQuery.isPending && <>Loading deleted live sessions</>}
      {deletedLiveSessionsQuery.isError && <>Error loading deleted live sessions</>}
      {deletedLiveSessions?.length === 0 && <>No recently deleted live sessions found</>}
      {deletedLiveSessionsQuery.isPending === true && <>Undeleting live session...</>}
      {deletedLiveSessionsQuery.isError && <>Error undeleteing live session</>}

      <View padding='48'>
        <Ul>
          {deletedLiveSessions !== undefined &&
            deletedLiveSessions.map(liveSession => (
              <li key={liveSession.liveSessionId}>
                <View direction='column'>
                  <View gap='8'>
                    <Text size='large'>
                      {liveSession.liveSessionTitle} [{liveSession.liveSessionId}] - deleted at:{' '}
                      {new Date(liveSession.deletedAt).toLocaleString()}
                    </Text>
                    <Button
                      loading={undeleteLiveSessionMutation.isPending}
                      onClick={() => {
                        if (confirm('Are you sure you want to undelete this live session?')) {
                          undeleteLiveSessionMutation.mutate({ liveSessionId: liveSession.liveSessionId })
                        }
                      }}
                    >
                      Undelete
                    </Button>
                  </View>

                  <MaxWidthTruncatedText lines={2} size='small'>
                    Course: {liveSession.flexibleContentTitle} [{liveSession.flexibleContentId}]
                  </MaxWidthTruncatedText>
                  <MaxWidthTruncatedText lines={2} size='small'>
                    Start: {liveSession.startTime ? new Date(liveSession.startTime).toLocaleString() : 'N/A'}
                  </MaxWidthTruncatedText>
                  <MaxWidthTruncatedText lines={2} size='small'>
                    End: {liveSession.endTime ? new Date(liveSession.endTime).toLocaleString() : 'N/A'}
                  </MaxWidthTruncatedText>
                  <MaxWidthTruncatedText lines={2} size='small'>
                    Ended: {liveSession.endedAt ? new Date(liveSession.endedAt).toLocaleString() : 'N/A'}
                  </MaxWidthTruncatedText>
                </View>
              </li>
            ))}
        </Ul>
      </View>
    </div>
  )
}

const RouteComponent = requireOwner(Page)

export const Route = createFileRoute('/recovery/livesessions')({
  component: RouteComponent as React.FC,
})
