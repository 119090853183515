import { useAtomValue } from 'jotai'
import React, { useCallback, useMemo, useState } from 'react'
import { Modal } from 'sierra-client/components/common/modals/modal'
import { BreakoutRoom } from 'sierra-client/components/liveV2/breakout-rooms/breakout-room'
import { useSelectCurrentCard } from 'sierra-client/components/liveV2/hooks/use-select-current-card'
import { AppThemeTokenProvider } from 'sierra-client/config/token-provider'
import { useTranslation } from 'sierra-client/hooks/use-translation'
import { useDispatch, useSelector } from 'sierra-client/state/hooks'
import { currentPollDataAtom } from 'sierra-client/state/interactive-card-data/poll-card'
import { startBreakoutSession, startTimer } from 'sierra-client/state/live-session/actions'
import { selectAllSessionLearners, selectBreakoutSession } from 'sierra-client/state/live-session/selectors'
import * as breakoutRooms from 'sierra-client/state/live-session/utils'
import { useFlexibleContentYDoc } from 'sierra-client/views/flexible-content/polaris-editor-provider/use-flexible-content-ydoc'
import { InteractivePollConfig } from 'sierra-client/views/v3-author/poll-card/types'
import { isElementType } from 'sierra-client/views/v3-author/queries'
import { File } from 'sierra-domain/flexible-content/types'
import { BreakoutRoom as BreakoutRoomType } from 'sierra-domain/live-session'
import { isDefined } from 'sierra-domain/utils'
import { PollCard, allPollCardAlternatives } from 'sierra-domain/v3-author'
import { getSlateDocument } from 'sierra-domain/v3-author/slate-yjs-extension'
import { MenuItem } from 'sierra-ui/components'
import { Button, InputPrimitive, View } from 'sierra-ui/primitives'
import { SingleSelectDropdown } from 'sierra-ui/primitives/menu-dropdown'
import { token } from 'sierra-ui/theming'
import { fonts } from 'sierra-ui/theming/fonts'
import styled from 'styled-components'
import z from 'zod'

const BreakoutText = styled.div`
  font-weight: ${fonts.weight.bold};
  flex: 1;
  padding-right: 1rem;
  padding-bottom: 8px;
  padding-top: 16px;
  color: ${token('foreground/primary')};
`

const RoomWrapper = styled.div`
  margin-top: 16px;
  display: flex;
  gap: 10px;
`

const choicesFromPollCard = (pollCard: PollCard): InteractivePollConfig['choices'] => {
  const alternatives = allPollCardAlternatives(pollCard)

  return alternatives.map(({ id, children }) => ({
    id,
    description: children.flatMap(it => it.text).join('\n'),
  }))
}
const Algorithm = z.enum(['random', 'manual', 'breakout', 'poll'])
type Algorithm = z.infer<typeof Algorithm>
type AlgorithmOption = { label: string; roomSize: boolean; enabled: boolean }

const BreakoutRoomsModalInner: React.FC<{
  onClose: () => void
  currentCard: File
  suggestedRoomSize?: number
  suggestedDuration?: number
}> = ({ onClose, currentCard, suggestedRoomSize = 2, suggestedDuration }) => {
  const dispatch = useDispatch()
  const learners = useSelector(selectAllSessionLearners)
  const pollResults = useAtomValue(currentPollDataAtom)?.optionResults
  const previousBreakoutSession = useSelector(selectBreakoutSession)
  const maxDurationInMinutes = 5 * 60
  const { t } = useTranslation()
  const { yDoc } = useFlexibleContentYDoc()

  const [algorithm, setAlgorithm] = useState<Algorithm>('random')
  const [roomSize, setRoomSize] = useState<number>(suggestedRoomSize)
  const [duration, setDuration] = useState<number | undefined>(suggestedDuration)

  const currentPoll = useMemo(() => {
    if (currentCard.data.type !== 'poll') return undefined

    const [pollCard] = getSlateDocument(yDoc, currentCard.id)
    if (!isElementType('poll-card', pollCard)) return undefined

    return {
      id: pollCard.id,
      choices: choicesFromPollCard(pollCard),
    }
  }, [currentCard.data.type, currentCard.id, yDoc])

  const options = useMemo<Record<Algorithm, AlgorithmOption>>(() => {
    return {
      random: {
        label: t('live.breakout-room.algorithm.randomly-populate'),
        roomSize: true,
        enabled: true,
      },
      manual: {
        label: t('live.breakout-room.algorithm.manual'),
        roomSize: false,
        enabled: true,
      },
      breakout: {
        label: t('live.breakout-room.algorithm.same-as-before'),
        roomSize: false,
        enabled: previousBreakoutSession !== undefined,
      },
      poll: {
        label: t('live.breakout-room.algorithm.base-on-poll'),
        roomSize: true,
        enabled: currentPoll !== undefined,
      },
    }
  }, [currentPoll, previousBreakoutSession, t])

  const optionMenuItems: MenuItem[] = Object.entries(options).map(([id, { label, enabled }]) => ({
    id,
    type: 'label',
    label,
    disabled: !enabled,
  }))

  const selectedItem = optionMenuItems.find(it => it.id === algorithm)

  const rooms = useMemo<BreakoutRoomType[]>(() => {
    if (algorithm === 'poll' && currentPoll !== undefined && pollResults !== undefined) {
      return breakoutRooms.createBasedOnPoll(
        currentPoll,
        pollResults,
        learners.map(({ userId }) => userId),
        roomSize
      )
    } else if (algorithm === 'breakout' && previousBreakoutSession !== undefined) {
      return breakoutRooms.createFromPrevious(
        learners.map(({ userId }) => userId),
        Object.values(previousBreakoutSession.breakoutRooms).filter(isDefined)
      )
    } else if (algorithm === 'manual') {
      return breakoutRooms.addNewBreakoutRoom([])
    } else {
      return breakoutRooms.createRandomly(
        learners.map(({ userId }) => userId),
        roomSize
      )
    }
  }, [algorithm, currentPoll, learners, pollResults, previousBreakoutSession, roomSize])

  const startBreakout = useCallback((): void => {
    void dispatch(startBreakoutSession({ breakoutRooms: rooms, duration }))
    if (duration !== undefined) {
      void dispatch(startTimer({ durationSeconds: duration * 60 }))
    }

    onClose()
  }, [dispatch, duration, onClose, rooms])

  const roomSizeEnabled = options[algorithm].roomSize

  const roomSizeItems: MenuItem[] = [
    { id: '2', type: 'label', label: '2 ' + t('live.breakout-room.people') },
    { id: '3', type: 'label', label: '3 ' + t('live.breakout-room.people') },
    { id: '4', type: 'label', label: '4 ' + t('live.breakout-room.people') },
    { id: '5', type: 'label', label: '5 ' + t('live.breakout-room.people') },
    { id: '6', type: 'label', label: '6 ' + t('live.breakout-room.people') },
  ]

  return (
    <>
      <Modal open onClose={onClose} size='small'>
        <BreakoutText>{t('live.breakout-room.algorithm.title')}</BreakoutText>

        <SingleSelectDropdown
          menuItems={optionMenuItems}
          selectedItem={selectedItem}
          onSelect={item => {
            if (Algorithm.safeParse(item.id).success) {
              setAlgorithm(item.id as Algorithm)
            }
          }}
        />

        <BreakoutText>{t('live.breakout-room.room-size')}</BreakoutText>
        <SingleSelectDropdown
          disabled={!roomSizeEnabled}
          menuItems={roomSizeItems}
          selectedItem={roomSizeItems.find(it => it.id === roomSize.toString())}
          onSelect={item => setRoomSize(Number.parseInt(item.id))}
        />

        <BreakoutText>{t('live.breakout-room.duration-in-minutes')}</BreakoutText>

        <InputPrimitive
          type='number'
          id='duration'
          placeholder={t('live.breakout-room.duration-placeholder')}
          onChange={event => {
            const minutes = Number.parseInt(event.target.value)
            const isNotValidDuration = event.target.value === '' || Number.isNaN(minutes) || minutes === 0
            setDuration(isNotValidDuration ? undefined : Math.min(minutes, maxDurationInMinutes))
          }}
          value={duration !== undefined ? duration.toString() : ''}
        />

        <RoomWrapper>
          {Object.entries(rooms).map(([id, room]) => (
            <BreakoutRoom key={id} room={room} onClick={() => {}} />
          ))}
        </RoomWrapper>
        <View justifyContent='center' marginTop='10'>
          <Button onClick={startBreakout}>{t('live.breakout-room.create')}</Button>
        </View>
      </Modal>
    </>
  )
}

export const BreakoutRoomsModal: React.FC<{
  onClose: () => void
  suggestedRoomSize?: number
  suggestedDuration?: number
}> = ({ onClose, suggestedRoomSize, suggestedDuration }) => {
  const currentCard = useSelectCurrentCard()

  if (currentCard === undefined) {
    return null
  }

  return (
    <AppThemeTokenProvider followSystemColorScheme={false}>
      <BreakoutRoomsModalInner
        onClose={onClose}
        currentCard={currentCard}
        suggestedRoomSize={suggestedRoomSize}
        suggestedDuration={suggestedDuration}
      />
    </AppThemeTokenProvider>
  )
}
