import { createSelector } from '@reduxjs/toolkit'
import { createCachedSelector } from 're-reselect'
import {
  precomputedImageAnnotationAdapter,
  precomputedTokenAnnotationAdapter,
  userAnnotationAdapter,
} from 'sierra-client/state/pdf-annotation/adapters'
import { PdfAnnotationState } from 'sierra-client/state/pdf-annotation/types'
import { RootState } from 'sierra-client/state/types'
import { isDefined } from 'sierra-domain/utils'

const selectPdfAnnotationState = (state: RootState): PdfAnnotationState => state.pdfAnnotation
const selectPdfAnnotationData = createSelector(selectPdfAnnotationState, state => state.data)

const selectPrecomputedAnnotationsByPage = createSelector(selectPdfAnnotationData, data =>
  data.type === 'loading' ? undefined : data.precomputedAnnotationsByPage
)

const selectPrecomputedTokenAnnotationsMap = createSelector(selectPdfAnnotationData, data =>
  data.type === 'loading' ? undefined : data.precomputedTokenAnnotationMap
)

export const precomputedTokenAnnotationSelectors = precomputedTokenAnnotationAdapter.getSelectors()
export const precomputedImageAnnotationSelectors = precomputedImageAnnotationAdapter.getSelectors()
export const selectPrecomputedAnnotationsDataByPage = createSelector(
  selectPrecomputedAnnotationsByPage,
  selectPrecomputedTokenAnnotationsMap,
  (annotationIds, annotationsMap) =>
    annotationsMap === undefined || annotationIds === undefined
      ? undefined
      : annotationIds.map(pageAnnotationIds =>
          pageAnnotationIds
            .map(id => {
              return precomputedTokenAnnotationSelectors.selectById(annotationsMap, id)
            })
            .filter(isDefined)
        )
)
export const selectPrecomputedAnnotationsForPage = createCachedSelector(
  [selectPrecomputedAnnotationsDataByPage, (_: unknown, pageIndex: number) => pageIndex],
  (annotationsBypage, pageIndex) => annotationsBypage?.[pageIndex]
)((_, pageIndex) => pageIndex)

export const annotationEntitiesSelectors = userAnnotationAdapter.getSelectors()

export const selectUserAnnotationsForDoc = createSelector(selectPdfAnnotationData, data => {
  if (data.type === 'loading') return undefined

  return data.annotationsByPage.map(annotations =>
    annotations.flatMap(it => {
      const annotation = annotationEntitiesSelectors.selectById(data.annotationMap, it)

      if (annotation === undefined) return []
      const tokens = annotation.tokenRefs
        .map(id => precomputedTokenAnnotationSelectors.selectById(data.precomputedTokenAnnotationMap, id))
        .filter(isDefined)

      return [{ ...annotation, tokens }]
    })
  )
})

export const selectUserAnnotationsForPage = createCachedSelector(
  [selectPdfAnnotationData, (state: unknown, pageIndex: number) => pageIndex],
  (data, pageIndex) => {
    if (data.type === 'loading') return

    return data.annotationsByPage[pageIndex]?.flatMap(it => {
      const res = annotationEntitiesSelectors.selectById(data.annotationMap, it)
      return res === undefined ? [] : [res]
    })
  }
)((state, pageIndex) => pageIndex)

const selectPdfAnnotationUi = createSelector(selectPdfAnnotationState, state => state.ui)
export const selectPdfAnnotationActiveAnnotationId = createSelector(
  selectPdfAnnotationUi,
  state => state.activeAnnotation
)

export const selectPdfAnnotationActiveAnnotationType = createSelector(
  selectPdfAnnotationUi,
  state => state.activeAnnotationType ?? 'title'
)

const selectPdfAnnotationSettings = createSelector(selectPdfAnnotationState, state => state.settings)
export const selectPdfAnnotationClip = createSelector(selectPdfAnnotationSettings, state => state.clip)

export const selectPdfId = createSelector(selectPdfAnnotationState, state => {
  return state.documentId
})

export const selectFilename = createSelector(selectPdfAnnotationState, state => {
  if (state.filename === undefined) return ''

  return state.filename
})

export const selectAutomated = createSelector(selectPdfAnnotationData, data => {
  if (data.type === 'loading') return false

  const annotations = data.annotationMap.ids.map(id =>
    annotationEntitiesSelectors.selectById(data.annotationMap, id)
  )

  return annotations.find(x => x?.automated === true) ? true : false
})

export const selectHasIsCardTitle = createSelector(selectPdfAnnotationData, data => {
  if (data.type === 'loading') return false

  const annotations = data.annotationMap.ids.map(id =>
    annotationEntitiesSelectors.selectById(data.annotationMap, id)
  )

  return annotations.find(x => x?.type === 'title') ? true : false
})

export const selectCanBeAutomated = createSelector(selectPdfAnnotationData, data => {
  if (data.type === 'loading') return false

  const annotations = data.annotationMap.ids.map(id =>
    annotationEntitiesSelectors.selectById(data.annotationMap, id)
  )

  return annotations.find(x => x?.type === 'title') &&
    annotations.find(x => x?.type === 'paragraph') &&
    (annotations.find(x => x?.type === 'h1') ||
      annotations.find(x => x?.type === 'h2') ||
      annotations.find(x => x?.type === 'h3'))
    ? true
    : false
})
