import React from 'react'
import { resolveUserAvatar } from 'sierra-client/api/content'
import { SearchContent } from 'sierra-client/components/shortcut-menu/search-content'
import { PostWithoutErrorsType, usePost } from 'sierra-client/hooks/use-post'
import { useTranslation } from 'sierra-client/hooks/use-translation'
import { useUsersLegacy } from 'sierra-client/state/users/hooks'
import { EditableContent } from 'sierra-domain/api/editable-content'
import { NanoId12 } from 'sierra-domain/api/nano-id'
import { XRealtimeAuthorExtractFlexibleContentText } from 'sierra-domain/routes'
import { isDefined } from 'sierra-domain/utils'
import { AvatarStack, Label, TruncatedText } from 'sierra-ui/components'
import { View } from 'sierra-ui/primitives'

const numberOfCoursesToDisplay = 25

const ContentRowTitle: React.FC<{ title: string; type: string; isTemplate: boolean; courseId: string }> = ({
  courseId,
  title,
  type,
  isTemplate,
}) => (
  <View>
    <TruncatedText lines={2} size='small' bold>
      {`${title} [${courseId}]`}
    </TruncatedText>
    {type === 'native:live' && (
      <Label iconId='play--circle--filled' $size='small' bold $bgColor='redVivid' $color='white'>
        Live
      </Label>
    )}
    {type === 'native:self-paced' && (
      <Label iconId='bookmark--filled' $size='small' bold $bgColor='grey5' $color='grey90'>
        Course
      </Label>
    )}
    {type === 'path' && (
      <Label iconId='path' $size='small' bold $bgColor='grey5' $color='grey90'>
        Path
      </Label>
    )}
    {isTemplate && (
      <Label iconId='template' $size='small' bold $bgColor='bluePastel' $color='black'>
        Template
      </Label>
    )}
  </View>
)

const Content: React.FC<EditableContent> = ({ ...content }) => {
  const { t } = useTranslation()
  const displayTitle = content.title === '' ? t('admin.author.no-title') : content.title
  const users = useUsersLegacy(content.collaborators.map(u => u.userId))
  const definedUsers = users.filter(isDefined).map(resolveUserAvatar)

  return (
    <>
      <ContentRowTitle
        {...content}
        title={displayTitle}
        courseId={content.id}
        isTemplate={content.type !== 'path' && content.templateSettings !== undefined}
      />
      <AvatarStack users={definedUsers} size='tiny' />
    </>
  )
}

const exportCourseText = async (
  content: EditableContent,
  postWithUserErrorException: PostWithoutErrorsType
): Promise<void> => {
  const exportText = await postWithUserErrorException(XRealtimeAuthorExtractFlexibleContentText, {
    courseId: NanoId12.parse(content.id),
  })
  await window.navigator.clipboard.writeText(exportText.text)
}

export const ExportCourseText: React.FC = () => {
  const { postWithUserErrorException } = usePost()

  return (
    <SearchContent
      numberOfCoursesToDisplay={numberOfCoursesToDisplay}
      ContentComponent={Content}
      onContentSelected={content => exportCourseText(content, postWithUserErrorException)}
      successMessage={_ => 'Copied course to clipboard'}
    />
  )
}
