import { createAsyncThunk } from '@reduxjs/toolkit'
import { queryClient } from 'sierra-client/api/query-client'
import { clearState } from 'sierra-client/state/actions'
import { RootState } from 'sierra-client/state/types'
import { UserAuthStatus } from 'sierra-client/state/user/user-types'
import { UserId } from 'sierra-domain/api/uuid'

// Not possible to add actions here that depend from sierra-client/state/api
// since it creates a circular dependency. Actions can be added to user-slice.

const userId = (state: UserAuthStatus): UserId | undefined =>
  state.type === 'user' ? state.user.uuid : undefined

export const statusChanged = createAsyncThunk<UserAuthStatus, UserAuthStatus, { state: RootState }>(
  'user/statusChanged',
  (payload, { dispatch, getState }) => {
    const state = getState()

    if (userId(state.user.status) !== userId(payload)) {
      dispatch(clearState())
      queryClient.clear()
    }

    return payload
  }
)
