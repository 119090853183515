import _ from 'lodash'
import { useMemo } from 'react'
import { useInsightsViews } from 'sierra-client/features/insights/api-hooks/use-views'
import {
  Dimension,
  Dimensions,
  FilterDomain,
  InsightViews,
  InsightsView,
  Measure,
  MeasureRef,
  Measures,
  SingleDimension,
  ViewRef,
  areDimensionRefsEqual,
  areMeasureRefsEqual,
  areViewRefsEqual,
} from 'sierra-domain/api/insights'
import { DomainRep } from 'sierra-domain/filter/datatype/domain'
import { Label } from 'sierra-domain/filter/datatype/label'
import { isDefined } from 'sierra-domain/utils'

function findDimensionIntersection(arr1: Dimension[], arr2: Dimension[]): Dimension[] {
  return arr1.filter(dim1 => arr2.some(dim2 => areDimensionRefsEqual(dim1.ref, dim2.ref)))
}

function findDimensionIntersectionOfViews(views: InsightViews['views']): Dimension[] {
  if (views.length === 0) {
    return []
  }

  const dimensionsArrays = views.map(view => view.dimensions) satisfies Dimension[][]

  return dimensionsArrays.reduce((acc, curr) => findDimensionIntersection(acc, curr))
}

function findFilterIntersection(arr1: FilterDomain[], arr2: FilterDomain[]): FilterDomain[] {
  return arr1.filter(dim1 => arr2.some(dim2 => areDimensionRefsEqual(dim1.ref, dim2.ref)))
}

function findFilterIntersectionOfViews(views: InsightViews['views']): FilterDomain[] {
  if (views.length === 0) {
    return []
  }

  const filterArrays = views.map(view => view.filterDomains).filter(isDefined) satisfies FilterDomain[][]

  return filterArrays.reduce((acc, curr) => findFilterIntersection(acc, curr))
}

export type ViewsData = {
  availableMeasures: Measure[]
  availableDimensions: Dimension[]
  availableFilters: FilterDomain[]
  availableViews: InsightsView[]
  disabledDimensionsByMetricLabels: DisabledByMetricLabelsMap
  disabledFiltersByMetricLabels: DisabledByMetricLabelsMap
}

const flattenDimensions = (dimensions: Dimensions[]): SingleDimension[] => {
  return dimensions.flatMap(dim => {
    switch (dim.type) {
      case 'dimensions.dimension':
        return [dim]
      case 'dimensions.group':
      case 'dimensions.section':
        return flattenDimensions(dim.dimensions)
    }
  })
}

export type DisabledByMetricLabelsMap = Record<string, Label[]>

const emptyFilterMap: DisabledByMetricLabelsMap = {} as const

export const getAvailableViewData = ({
  viewsData,
  measures,
  viewRef,
}: {
  viewsData: InsightViews
  measures?: MeasureRef[]
  viewRef?: ViewRef
}): ViewsData => {
  const filteredViews = (
    measures !== undefined && measures.length > 0
      ? viewsData.views.filter(view =>
          view.measures.some(viewMeasure =>
            measures.some(measure => areMeasureRefsEqual(measure, viewMeasure.ref))
          )
        )
      : viewsData.views
  ) satisfies InsightViews['views']

  const availableMeasures = filteredViews.flatMap(view => view.measures)

  const availableDimensions = findDimensionIntersectionOfViews(filteredViews)

  const availableFilters =
    measures !== undefined && measures.length > 0
      ? findFilterIntersectionOfViews(filteredViews)
      : _.uniqBy(
          // Union of all filters since no metrics are selected
          filteredViews.flatMap(view => view.filterDomains),
          filter => JSON.stringify(filter?.ref)
        ).filter(isDefined)

  const allDimensions = flattenDimensions(viewsData.dimensions)

  const emptyDimensionsMap = {} as const satisfies DisabledByMetricLabelsMap

  const view =
    viewRef !== undefined ? viewsData.views.find(view => areViewRefsEqual(view.id, viewRef)) : undefined

  const disabledDimensionsByViewLabels =
    view !== undefined
      ? allDimensions.reduce<DisabledByMetricLabelsMap>((acc, dimension) => {
          const enabled = view.dimensions.some(viewDimension =>
            areDimensionRefsEqual(viewDimension.ref, dimension.dimension)
          )
          if (enabled) return acc

          // TODO(niklas): add proper label
          const label = view.measures[0]?.label
          if (label === undefined) return acc

          return { ...acc, [JSON.stringify(dimension.dimension)]: [label] }
        }, {})
      : emptyDimensionsMap

  const disabledDimensionsByMetricLabels =
    measures?.reduce<DisabledByMetricLabelsMap>((total, measure) => {
      const actualMeasure = availableMeasures.find(m => areMeasureRefsEqual(m.ref, measure))

      if (actualMeasure === undefined) {
        // Early return if we won't be able to show a label anyway
        return total
      }

      const viewsWithMeasure = viewsData.views.filter(view =>
        view.measures.some(viewMeasure => areMeasureRefsEqual(measure, viewMeasure.ref))
      )

      const disabledDimensions = allDimensions
        .map(menuDimension => {
          const isDisabledInOneMetricView = !viewsWithMeasure.every(view =>
            view.dimensions.some(viewDimension =>
              areDimensionRefsEqual(viewDimension.ref, menuDimension.dimension)
            )
          )
          return isDisabledInOneMetricView ? JSON.stringify(menuDimension.dimension) : undefined
        })
        .filter(isDefined)

      for (const dimension of disabledDimensions) {
        const currentValue = total[dimension]
        if (currentValue === undefined) {
          total[dimension] = [actualMeasure.label]
        } else {
          total[dimension] = [...currentValue, actualMeasure.label]
        }
      }

      return total
    }, {}) ?? emptyDimensionsMap

  const disabledFiltersByViewLabels =
    view !== undefined
      ? allDimensions.reduce<DisabledByMetricLabelsMap>((acc, dimension) => {
          const enabled =
            view.filterDomains !== undefined &&
            view.filterDomains.some(viewDimension =>
              areDimensionRefsEqual(viewDimension.ref, dimension.dimension)
            )
          if (enabled) return acc

          // TODO(niklas): add proper label
          const label = view.measures[0]?.label
          if (label === undefined) return acc

          return { ...acc, [JSON.stringify(dimension.dimension)]: [label] }
        }, {})
      : emptyFilterMap

  const disabledFiltersByMetricLabels =
    measures?.reduce((total, measure) => {
      const actualMeasure = availableMeasures.find(m => areMeasureRefsEqual(m.ref, measure))
      const viewsWithMeasure = viewsData.views.filter(view =>
        view.measures.some(viewMeasure => areMeasureRefsEqual(measure, viewMeasure.ref))
      )

      if (actualMeasure === undefined) {
        // Early return if we won't be able to show a label anyway
        return total
      }

      const disabledFilters = allDimensions
        .map(menuDimension => {
          const isDisabledInOneMetricView = !viewsWithMeasure.every(
            view =>
              view.filterDomains !== undefined &&
              view.filterDomains.some(viewDimension =>
                areDimensionRefsEqual(viewDimension.ref, menuDimension.dimension)
              )
          )
          return isDisabledInOneMetricView ? JSON.stringify(menuDimension.dimension) : undefined
        })
        .filter(isDefined)

      for (const filter of disabledFilters) {
        const currentValue = total[filter]
        if (currentValue === undefined) {
          total[filter] = [actualMeasure.label]
        } else {
          total[filter] = [...currentValue, actualMeasure.label]
        }
      }

      return total
    }, {} as DisabledByMetricLabelsMap) ?? emptyFilterMap

  return {
    availableMeasures,
    availableDimensions,
    availableFilters,
    availableViews: filteredViews,
    // TODO(niklas): fix this
    disabledDimensionsByMetricLabels: {
      ...disabledDimensionsByViewLabels,
      ...disabledDimensionsByMetricLabels,
    },
    disabledFiltersByMetricLabels: { ...disabledFiltersByViewLabels, ...disabledFiltersByMetricLabels },
  }
}

export const useInsightsViewsFromMeasures = (
  measures: MeasureRef[] | undefined,
  view: ViewRef | undefined
): { isLoading: true; data: undefined } | { isLoading: false; data: ViewsData } => {
  const { data: viewsData, isLoading } = useInsightsViews()

  return useMemo(() => {
    if (viewsData === undefined) {
      return { isLoading: true, data: undefined }
    }

    return {
      isLoading,
      data: getAvailableViewData({ viewsData, measures, viewRef: view }),
    }
  }, [isLoading, viewsData, measures, view])
}

/**
 * Returns a list of all measures
 * @returns measures
 */
export const useInsightsMeasures = (): Measures[] | undefined => {
  const { data: views } = useInsightsViews()

  return views?.measures
}

export const useInsightsDimensions = (): Dimensions[] | undefined => {
  const { data: views } = useInsightsViews()

  return views?.dimensions
}

export const useAllFilterDomainReps = (): DomainRep[] => {
  const { data: viewsData } = useInsightsViews()
  const allFilterDomainReps: DomainRep[] = useMemo(() => {
    return viewsData === undefined ? [] : getAvailableViewData({ viewsData }).availableFilters
  }, [viewsData])

  return allFilterDomainReps
}
