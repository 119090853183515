export const setDifference = <A>(a: Set<A>, b: Set<A>): Set<A> => {
  const s = new Set<A>(a)
  b.forEach(x => s.delete(x))
  return s
}

export const setUnion = <A>(a: Set<A>, b: Set<A>): Set<A> => {
  const s = new Set<A>(a)
  b.forEach(x => s.add(x))
  return s
}

export const setIntersection = <A>(a: Set<A>, b: Set<A>): Set<A> => {
  const s = new Set<A>()
  a.forEach(x => {
    if (b.has(x)) s.add(x)
  })
  return s
}
