import { maxBy } from 'lodash'
import { createContext, ReactNode, useCallback, useContext, useMemo, useState } from 'react'
import { VideoQuality } from 'sierra-client/components/liveV2/services/video-call-service/types'
import { NanoId12 } from 'sierra-domain/api/nano-id'

export enum PrioriotyLevel {
  LOW = 10,
  MEDIUM = 50,
  HIGH = 90,
}

type VideoUsage = {
  priority: PrioriotyLevel
  quality: VideoQuality
  ownerId: NanoId12
}

export type ParticipantId = 'local' | string
type ParticipantsContext = {
  videoRequests: Record<ParticipantId, VideoUsage[]>
  videosPlaying: Record<ParticipantId, { ownerId: string; isPlaying: true } | undefined>
  requestVideo: (
    uid: ParticipantId,
    ownerId: NanoId12,
    priority: PrioriotyLevel,
    quality: VideoQuality
  ) => void
  removeVideoRequest: (uid: ParticipantId, ownerId: NanoId12) => void
  setVideoIsPlaying: (uid: ParticipantId, ownerId: string, isPlaying: boolean) => void
}

const ParticipantsContextObj = createContext<ParticipantsContext | undefined>(undefined)

export const ParticipantsVideoPriorityProvider = ({ children }: { children: ReactNode }): JSX.Element => {
  const [videoRequests, setVideoRequests] = useState<Record<ParticipantId, VideoUsage[]>>({})
  const [videosPlaying, setVideosPlaying] = useState<
    Record<ParticipantId, { ownerId: string; isPlaying: true } | undefined>
  >({})

  const removeVideoRequest = useCallback((uid: ParticipantId, ownerId: NanoId12) => {
    setVideoRequests(requests => {
      const currentRequests = (requests[uid] || []).filter(r => r.ownerId !== ownerId)
      if (currentRequests.length === 0) {
        delete requests[uid]
        return { ...requests }
      }

      return {
        ...requests,
        [uid]: [...currentRequests],
      }
    })
  }, [])

  const requestVideo = useCallback(
    (uid: ParticipantId, ownerId: NanoId12, priority: PrioriotyLevel, quality: VideoQuality) => {
      setVideoRequests(requests => {
        const currentRequests = (requests[uid] || []).filter(r => r.ownerId !== ownerId)

        return {
          ...requests,
          [uid]: [...currentRequests, { priority, quality, ownerId }],
        }
      })
    },
    []
  )

  const setVideoIsPlaying = useCallback((uid: ParticipantId, ownerId: string, isPlaying: boolean) => {
    setVideosPlaying(videos => {
      if (isPlaying) {
        return { ...videos, [uid]: { ownerId, isPlaying } }
      } else {
        return { ...videos, [uid]: undefined }
      }
    })
  }, [])

  const participantsContextValue = useMemo(
    () => ({
      requestVideo,
      setVideoIsPlaying,
      removeVideoRequest,
      videoRequests,
      videosPlaying,
    }),
    [removeVideoRequest, requestVideo, setVideoIsPlaying, videoRequests, videosPlaying]
  )

  return (
    <ParticipantsContextObj.Provider value={participantsContextValue}>
      {children}
    </ParticipantsContextObj.Provider>
  )
}

export const useParticipantsVideoPriority = (): ParticipantsContext => {
  const context = useContext(ParticipantsContextObj)

  if (context === undefined) {
    throw new Error('This component must be wrapped in a ParticipantsProvider')
  }

  return context
}

// We need to ensure videos only play at one place at a time
export const useVideoIsSafeToUse = (video: ParticipantId, ownerId: string): boolean => {
  const { videosPlaying } = useParticipantsVideoPriority()

  const videoIsPlaying = videosPlaying[video]
  return videoIsPlaying === undefined || videoIsPlaying.ownerId === ownerId
}

export const useCurrentVideoOwner = (video: ParticipantId): VideoUsage | undefined => {
  const { videoRequests } = useParticipantsVideoPriority()

  return maxBy(videoRequests[video] ?? [], r => r.priority)
}
