import { useCallback, useMemo, useState } from 'react'
import { useLiveSessionContext } from 'sierra-client/components/liveV2/contexts/live-session-data'
import { HeaderIconButton } from 'sierra-client/components/liveV2/header-buttons'
import { BugReportModal } from 'sierra-client/components/liveV2/live-layer/bug-report-modal'
import { Logging } from 'sierra-client/core/logging'
import { useTranslation } from 'sierra-client/hooks/use-translation'
import { useTypedMutation } from 'sierra-client/state/api'
import { useDispatch, useSelector } from 'sierra-client/state/hooks'
import { selectIsFacilitator } from 'sierra-client/state/live-session/selectors'
import { SettingsModal } from 'sierra-client/views/liveV2/settings/settings'
import { XRealtimeAuthorLiveSessionsUpdateLiveSettings } from 'sierra-domain/routes'
import { MenuItem } from 'sierra-ui/components'
import { MenuDropdownPrimitive } from 'sierra-ui/primitives/menu-dropdown'

export const OptionsButton = (): JSX.Element => {
  const { t } = useTranslation()
  const dispatch = useDispatch()
  const [showReportBug, setShowReportBug] = useState(false)
  const [showSettings, setShowSettings] = useState(false)

  const liveSession = useLiveSessionContext()
  const isFacilitator = useSelector(selectIsFacilitator)

  const updateSettingsMutation = useTypedMutation(XRealtimeAuthorLiveSessionsUpdateLiveSettings)

  const onToggleTranscriptionsClick = useCallback(() => {
    if (isFacilitator) {
      const isTranscribingSession = liveSession.data.transcribeSession
      const liveSessionId = liveSession.liveSessionId
      updateSettingsMutation.mutate({ liveSessionId, transcribeSession: !isTranscribingSession })
    }
  }, [isFacilitator, liveSession.data.transcribeSession, liveSession.liveSessionId, updateSettingsMutation])

  const onToggleGuestAccessClick = useCallback(() => {
    if (isFacilitator) {
      const guestsAreAllowed = liveSession.data.allowGuestAccess
      const liveSessionId = liveSession.liveSessionId
      updateSettingsMutation.mutate({ liveSessionId, enableGuestAccess: !guestsAreAllowed })
    }
  }, [isFacilitator, liveSession.data.allowGuestAccess, liveSession.liveSessionId, updateSettingsMutation])

  const items = useMemo(() => {
    const menuItems: MenuItem[] = []
    const isTranscribing = liveSession.data.transcribeSession
    const guestAccessEnabled = liveSession.data.allowGuestAccess
    if (isFacilitator) {
      menuItems.push({
        type: 'label',
        id: 'transcribe-session',
        label: isTranscribing
          ? t('live.smart-features.title.disable')
          : t('live.smart-features.title.enable'),
        description: isTranscribing
          ? t('live.smart-features.description.disable')
          : t('live.smart-features.description.enable'),
      })
    }
    if (isFacilitator) {
      menuItems.push({
        type: 'label',
        id: 'guest-access',
        label: guestAccessEnabled
          ? t('admin.author.sessions.disable-guest-access')
          : t('admin.author.sessions.allow-guest-access'),
      })
    }
    menuItems.push(
      {
        type: 'label',
        id: 'settings',
        label: t('dictionary.settings'),
      },
      {
        type: 'label',
        id: 'report-bug',
        label: t('live.report-a-bug'),
      }
    )
    return menuItems
  }, [isFacilitator, liveSession.data.allowGuestAccess, liveSession.data.transcribeSession, t])

  return (
    <div id='tour-live-help'>
      <MenuDropdownPrimitive
        renderTrigger={() => (
          <HeaderIconButton iconId='overflow-menu--vertical' tooltip={t('dictionary.more-options')} />
        )}
        closeOnSelect
        menuItems={items}
        onSelect={item => {
          if (item.id === 'report-bug') {
            void dispatch(Logging.liveSession.helpClicked())
            setShowReportBug(true)
          } else if (item.id === 'settings') {
            setShowSettings(true)
          } else if (item.id === 'transcribe-session') {
            onToggleTranscriptionsClick()
          } else if (item.id === 'guest-access') {
            onToggleGuestAccessClick()
          }
        }}
      />
      {showReportBug && <BugReportModal open={showReportBug} onCloseModal={() => setShowReportBug(false)} />}
      {showSettings && (
        <SettingsModal open={showSettings} onClose={() => setShowSettings(false)} renderedIn={'live'} />
      )}
    </div>
  )
}
