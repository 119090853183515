import _ from 'lodash'
import { isElementType, rangeOfNode } from 'sierra-client/views/v3-author/queries'
import { unwrapNonParagraphChildren } from 'sierra-client/views/v3-author/unwrap-non-paragraph-children'
import { Entity } from 'sierra-domain/entity'
import { nanoid12 } from 'sierra-domain/nanoid-extensions'
import { Heading } from 'sierra-domain/v3-author'
import { Editor, NodeEntry, Path, Range, Transforms } from 'slate'

const isCursorAtTheEndOfTheHeading = (editor: Editor, headingEntry: NodeEntry<Entity<Heading>>): boolean => {
  const { selection } = editor
  if (!selection || !Range.isCollapsed(selection)) return false
  const [, end] = rangeOfNode(headingEntry)
  const selectionPointIsAdjacentToEnd = _.isEqual(end, Editor.after(editor, selection.anchor))
  const selectionAndEndInDifferentNodes = !_.isEqual(end.path, selection.anchor.path)
  const isInLastNonEmptyNode = selectionPointIsAdjacentToEnd && selectionAndEndInDifferentNodes

  return _.isEqual(end, selection.anchor) || isInLastNonEmptyNode
}

export const withHeading = (editor: Editor): Editor => {
  const { insertBreak, normalizeNode } = editor

  editor.normalizeNode = entry => {
    const [node] = entry

    if (!isElementType('heading', node)) return normalizeNode(entry)

    const { didUnwrap } = unwrapNonParagraphChildren(editor, entry)
    if (didUnwrap) return

    normalizeNode(entry)
  }

  editor.insertBreak = () => {
    const { selection } = editor
    if (!selection || !Range.isCollapsed(selection)) return insertBreak()

    const [match] = Editor.nodes(editor, { match: isElementType('heading') })
    if (match === undefined) return insertBreak()

    // If the cursor is at the last text position in the current heading, insert a new paragraph instead of creating a new line.
    if (isCursorAtTheEndOfTheHeading(editor, match)) {
      const [, path] = match
      Transforms.insertNodes(
        editor,
        { type: 'paragraph', id: nanoid12(), children: [{ text: '' }] },
        { at: Path.next(path) }
      )
      Transforms.move(editor, { distance: 1 })
      return
    }

    insertBreak()
  }

  return editor
}
