import { RouterLink } from 'sierra-client/components/common/link'
import { useTranslation } from 'sierra-client/hooks/use-translation'
import { Trans } from 'sierra-client/hooks/use-translation/trans'
import { TranslationKey } from 'sierra-client/hooks/use-translation/types'
import { BorderCard } from 'sierra-client/views/manage/components/overview-cards'
import { StatusText } from 'sierra-client/views/manage/groups/components/status'
import { useManageProgramURL } from 'sierra-client/views/manage/utils/use-manage-program-url'
import { LinkLabel } from 'sierra-client/views/workspace/learn/link-label'
import { TopGroup } from 'sierra-domain/api/analytics-v2'
import { Icon } from 'sierra-ui/components'
import { Heading, Spacer, Text, View } from 'sierra-ui/primitives'
import { palette } from 'sierra-ui/theming'
import styled from 'styled-components'

// todo(damjan): dedupe
const Divider = styled.div`
  width: 100%;
  height: 1px;
  margin: 0.5rem 0;
  background-color: ${p => p.theme.color.grey10};
  margin: 1rem 0;
`

const TrophyIcon = styled(Icon).attrs({
  iconId: 'trophy',
  size: 'size-16',
})`
  border-radius: 50%;
  background-color: ${p => p.theme.color.greenBright};
  align-self: flex-start;
  padding: 1rem;
  color: ${palette.primitives.white};
`

const ViewProgramLink: React.FC<{ programId: string }> = ({ programId: programId }) => {
  const { t } = useTranslation()
  const manageProgramURL = useManageProgramURL(programId)
  // TODO(ludvig): We need to support the sort order on the group detail page first.
  // TODO(ludvig): Maybe this link should open the heatmap instead?
  // return <Link href={`/manage/groups/${groupId}?sortBy=status&sortOrder=desc`}>{t('manage-dashboard.link.view')}</Link>

  return (
    <RouterLink href={manageProgramURL}>
      <LinkLabel bold>{t('manage-dashboard.link.view')}</LinkLabel>
    </RouterLink>
  )
}

const ViewProgramsLink: React.FC = () => {
  const { t } = useTranslation()
  return (
    <RouterLink href='/manage/programs?sortBy=status&sortOrder=desc'>
      <LinkLabel bold>{t('manage-dashboard.link.view-programs')}</LinkLabel>
    </RouterLink>
  )
}

const ZeroGroups: React.FC = () => {
  const { t } = useTranslation()

  return (
    <>
      <View direction='column' style={{ flex: 1 }}>
        <Heading size='h5' bold>
          {t('manage-dashboard.need-attention.zero-groups')}
        </Heading>
      </View>
      <TrophyIcon />
      <Spacer size='6' />
      <Text size='small'>{t('manage-dashboard.group-insights.zero-groups-body')}</Text>
      <Spacer size='xsmall' />
      <ViewProgramsLink />
    </>
  )
}

const NotScheduledReason: React.FC<{ groupName: string }> = ({ groupName }) => {
  const { t } = useTranslation()

  return (
    <View gap='6' direction='column'>
      <StatusText color='red'>{t('manage-dashboard.group-insights.unscheduled-sessions')}</StatusText>
      <Text size='small'>
        <Trans
          i18nKey={
            'manage-dashboard.group-insights.unscheduled-sessions-description' satisfies TranslationKey
          }
          values={{ groupName }}
          components={{
            bold: <b />,
          }}
        />
      </Text>
    </View>
  )
}

const OverdueReason: React.FC<{ groupName: string }> = ({ groupName }) => {
  const { t } = useTranslation()

  return (
    <View gap='6' direction='column'>
      <StatusText color='red' filled>
        {t('manage-dashboard.group-insights.overdue')}
      </StatusText>
      <Text size='small'>
        <Trans
          i18nKey={'manage-dashboard.group-insights.overdue-description' satisfies TranslationKey}
          values={{ groupName }}
          components={{
            bold: <b />,
          }}
        />
      </Text>
    </View>
  )
}

const DueSoonReason: React.FC<{ groupName: string }> = ({ groupName }) => {
  const { t } = useTranslation()

  return (
    <View gap='6' direction='column'>
      <StatusText color='red'>{t('manage-dashboard.group-insights.due-soon')}</StatusText>
      <Text size='small'>
        <Trans
          i18nKey={'manage-dashboard.group-insights.due-soon-description' satisfies TranslationKey}
          values={{ groupName }}
          components={{
            bold: <b />,
          }}
        />
      </Text>
    </View>
  )
}

const RiskReason: React.FC<{ group: TopGroup }> = ({ group }) => {
  const { groupName, reasons } = group

  return (
    <>
      {reasons.includes('not-scheduled') ? (
        <NotScheduledReason groupName={groupName} />
      ) : reasons.includes('overdue') ? (
        <OverdueReason groupName={groupName} />
      ) : reasons.includes('due-soon') ? (
        <DueSoonReason groupName={groupName} />
      ) : null}
    </>
  )
}

const GroupRiskContainer = styled(View).attrs({
  direction: 'column',
  alignItems: 'flex-start',
  gap: 'xsmall',
})`
  max-width: 28ch;
`

const OneGroup: React.FC<{ groups: [TopGroup] }> = ({ groups }) => {
  const { t } = useTranslation()

  const { groupId } = groups[0]

  return (
    <>
      <Heading size='h5' bold>
        {t('manage-dashboard.need-attention.one-group')}
      </Heading>
      <View gap='none' direction='column'>
        <GroupRiskContainer>
          <RiskReason group={groups[0]} />
          <ViewProgramLink programId={groupId} />
        </GroupRiskContainer>
      </View>
    </>
  )
}

const TwoGroups: React.FC<{ groups: [TopGroup, TopGroup] }> = ({ groups }) => {
  const { t } = useTranslation()

  const [group1, group2] = groups

  return (
    <>
      <Heading size='h5' bold>
        {t('manage-dashboard.need-attention.two-groups')}
      </Heading>
      <View gap='none' direction='column'>
        <GroupRiskContainer>
          <RiskReason group={group1} />
          <ViewProgramLink programId={group1.groupId} />
        </GroupRiskContainer>
        <Divider />
        <GroupRiskContainer>
          <RiskReason group={group2} />
          <ViewProgramLink programId={group2.groupId} />
        </GroupRiskContainer>
      </View>
    </>
  )
}

const ThreeOrMoreGroups: React.FC<{ groups: [TopGroup, TopGroup, TopGroup, ...TopGroup[]] }> = ({
  groups,
}) => {
  const { t } = useTranslation()

  return (
    <>
      <Heading size='h5' bold>
        {t('manage-dashboard.need-attention.n-programs')}
      </Heading>
      <View gap='none' direction='column'>
        <GroupRiskContainer>
          <RiskReason group={groups[0]} />
          <ViewProgramLink programId={groups[0].groupId} />
        </GroupRiskContainer>
        <Divider />
        <GroupRiskContainer>
          <Text size='small'>
            {t('manage-dashboard.group-insights.n-other-programs', { count: groups.length - 1 })}
          </Text>
          <ViewProgramsLink />
        </GroupRiskContainer>
      </View>
    </>
  )
}

const InsightsContentWrapper = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  flex: 1;
`

// todo(damjan): refactor
type GroupInsightsProps = {
  groups: TopGroup[] | undefined
}
export const GroupInsightsCard: React.FC<GroupInsightsProps> = ({ groups }) => {
  const { t } = useTranslation()

  return (
    <BorderCard>
      <Text size='small' bold>
        {t('manage-dashboard.program-insights.title')}
      </Text>
      <InsightsContentWrapper>
        {groups === undefined ? (
          <></>
        ) : groups.length === 0 ? (
          <ZeroGroups />
        ) : groups.length === 1 ? (
          <OneGroup groups={groups as [TopGroup]} />
        ) : groups.length === 2 ? (
          <TwoGroups groups={groups as [TopGroup, TopGroup]} />
        ) : (
          <ThreeOrMoreGroups groups={groups as [TopGroup, TopGroup, TopGroup, ...TopGroup[]]} />
        )}
      </InsightsContentWrapper>
    </BorderCard>
  )
}
