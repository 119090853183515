import React, { useRef } from 'react'
import { HiddenInput } from 'sierra-client/components/common/inputs/hidden-input'
import { Message } from 'sierra-client/components/common/message'
import { Modal } from 'sierra-client/components/common/modals/modal'
import { useTranslation } from 'sierra-client/hooks/use-translation'
import { useScormUpload } from 'sierra-client/views/manage/scorm/use-scorm-upload'
import { Button, LoadingSpinner, Spacer, Text, View } from 'sierra-ui/primitives'

type ScormImportFormProps = {
  onAfterImport: () => void
}
const ScormImportForm: React.FC<ScormImportFormProps> = ({ onAfterImport }) => {
  const { t } = useTranslation()
  const fileInputRef = useRef<HTMLInputElement>(null)
  const { uploadData, uploadFile, reset, processFile } = useScormUpload()

  return (
    <View direction='column' alignItems='center'>
      <HiddenInput
        type='file'
        id='scorm-upload'
        accept='.zip'
        multiple={false}
        onChange={e => {
          if (e.target.files === null) return
          const file = e.target.files[0]
          if (file === undefined) return
          void uploadFile(file, processFile)
        }}
        ref={fileInputRef}
      />
      {uploadData.status === 'not-started' ? (
        <>
          <Message>
            <Text color='LEGACY_DEFAULT_TEXT_COLOR_REPLACE_ASAP' size='small'>
              {t('scorm.import.version-alert')}
            </Text>
          </Message>
          <Spacer size='4' />
          <Button onClick={() => fileInputRef.current?.click()}>{t('scorm.upload.upload-scorm-file')}</Button>
        </>
      ) : uploadData.status === 'uploading' ? (
        <>
          <Text color='LEGACY_DEFAULT_TEXT_COLOR_REPLACE_ASAP' size='regular'>
            {t('scorm.upload.uploading')}
          </Text>
          <Spacer />
          <LoadingSpinner />
        </>
      ) : uploadData.status === 'upload-done' ? (
        <>
          <Text color='LEGACY_DEFAULT_TEXT_COLOR_REPLACE_ASAP' size='regular'>
            {t('scorm.upload.processing')}
          </Text>
          <Spacer />
          <LoadingSpinner />
        </>
      ) : uploadData.status === 'processing-done' && uploadData.courseId !== undefined ? (
        <>
          <Text color='LEGACY_DEFAULT_TEXT_COLOR_REPLACE_ASAP' size='regular'>
            {t('scorm.upload.successful')}
          </Text>
          <Spacer />
          <View gap='xsmall'>
            <Button onClick={onAfterImport}>{t('scorm.upload.return-courses')}</Button>
            <Button variant='secondary' onClick={reset}>
              {t('scorm.upload.upload-another')}
            </Button>
          </View>
        </>
      ) : uploadData.status === 'error' ? (
        <View direction='column' alignItems='center'>
          <Text size='regular' color='redBright'>
            {uploadData.i18nArgs !== undefined ? t(...uploadData.i18nArgs) : t('user-error.other.unknown')}
          </Text>
          <Spacer />
          <Button onClick={reset}>{t('scorm.import.retry')}</Button>
        </View>
      ) : null}
    </View>
  )
}

type ScormImportDialogProps = ScormImportFormProps & {
  isOpen: boolean
  onClose: () => void
}

export const ScormImportDialog: React.FC<ScormImportDialogProps> = ({ isOpen, onClose, onAfterImport }) => {
  const { t } = useTranslation()

  return (
    <Modal open={isOpen} size='xsmall' onClose={onClose} title={t('manage.course.import-courses')}>
      <ScormImportForm onAfterImport={onAfterImport} />
    </Modal>
  )
}
