import { useMemo, useState } from 'react'
import { usePost } from 'sierra-client/hooks/use-post'
import { CourseId } from 'sierra-domain/api/nano-id'
import { isRight } from 'sierra-domain/either'
import { XRealtimeAuthorDeleteCourse } from 'sierra-domain/routes'

export type UseDeleteCourse = {
  loading: boolean
  showDeleteConfirm: boolean
  remove: () => Promise<void>
  openDeleteConfirm: () => void
  closeDeleteConfirm: () => void
}

export const useDeleteCourse = ({
  courseId,
  onDeleted,
}: {
  courseId: CourseId
  onDeleted: () => void
}): UseDeleteCourse => {
  const [loading, setLoading] = useState(false)
  const [showDeleteConfirm, setShowDeleteConfirm] = useState(false)
  const { postWithUserErrorCode } = usePost()

  return useMemo(() => {
    const openDeleteConfirm = (): void => {
      setShowDeleteConfirm(true)
    }
    const closeDeleteConfirm = (): void => {
      setShowDeleteConfirm(false)
    }

    const remove = async (): Promise<void> => {
      setLoading(true)
      closeDeleteConfirm()
      const result = await postWithUserErrorCode(XRealtimeAuthorDeleteCourse, { courseId })
      if (isRight(result)) onDeleted()
      setLoading(false)
    }

    return { loading, remove, showDeleteConfirm, openDeleteConfirm, closeDeleteConfirm }
  }, [courseId, loading, onDeleted, postWithUserErrorCode, showDeleteConfirm])
}
