import { useTranslation } from 'sierra-client/hooks/use-translation'
import { AuthenticationContent } from 'sierra-client/views/authentication/native/components/authentication-content'
import { Text } from 'sierra-ui/primitives'

export const UserDisabled = (): JSX.Element => {
  const { t } = useTranslation()

  return (
    <AuthenticationContent message={t('user-disabled.heading')}>
      <Text color='foreground/primary' size='small'>
        {t('user-disabled.body')}
      </Text>
    </AuthenticationContent>
  )
}
