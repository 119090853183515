import { FC, useEffect, useState } from 'react'
import { useAblyYDocWithAwareness } from 'sierra-client/collaboration/use-ably-ydoc'
import { useLiveSessionIdContextIfAvailable } from 'sierra-client/components/liveV2/live-session-id-provider'
import { usePolarisContext } from 'sierra-client/views/flexible-content/polaris-editor-provider/polaris-editor-provider'
import { CardGridV2 } from 'sierra-client/views/learner/lesson/grid'
import { useRecapContext } from 'sierra-client/views/recap/recap-context'
import { LiveProjectCardView } from 'sierra-client/views/v3-author/project-card/live'
import { RecapProjectCardView } from 'sierra-client/views/v3-author/project-card/recap'
import { Data } from 'sierra-client/views/v3-author/project-card/types'
import { isElementType } from 'sierra-client/views/v3-author/queries'
import { EditorMode } from 'sierra-client/views/v3-author/slate'
import {
  StickyNotesCardYjsApi,
  createStickyNoteYjsApi,
  isStickyNotesCardYDocInitialized,
} from 'sierra-domain/card/sticky-notes-card'
import { ScopedLiveSessionId, ScopedStickyNotesCardId } from 'sierra-domain/collaboration/types'
import { FileId } from 'sierra-domain/flexible-content/identifiers'
import { textInNodes } from 'sierra-domain/slate-util'
import { assertIsNonNullable } from 'sierra-domain/utils'
import { SlateDocument } from 'sierra-domain/v3-author'
import { fromSlateSharedType, getSlateDocumentSharedType } from 'sierra-domain/v3-author/slate-yjs-extension'
import { spacing } from 'sierra-ui/theming'
import { fonts } from 'sierra-ui/theming/fonts'
import styled from 'styled-components'

const CardTitle = styled.div`
  ${fonts.heading.h5};
  min-width: 25rem;
  color: #000;
  outline: none;
  border: none;
`

const Container = styled(CardGridV2)`
  flex: 0 0 100%;
  padding-top: ${spacing['96']};
  padding-bottom: 4rem;
  grid-auto-rows: minmax(min-content, max-content);
  row-gap: 2rem;

  && {
    min-height: unset;
  }
`

function scopedStickyNotesCardId(
  scopedFiledId: FileId,
  scopedLiveSessionId: ScopedLiveSessionId
): ScopedStickyNotesCardId {
  const fileId = scopedFiledId.replace(/^file:/, '')
  return `sticky-notes-card:${fileId}:${scopedLiveSessionId}` as ScopedStickyNotesCardId
}

const transform = (doc: SlateDocument): Data => {
  const [projectCard] = doc.filter(isElementType('project-card'))
  assertIsNonNullable(projectCard, 'The slate document was missing a project-card')

  const titleEl = projectCard.children.filter(isElementType('paragraph'))
  const sectionEls = projectCard.children.filter(isElementType('project-card-section'))

  return {
    title: textInNodes(titleEl).join(''),
    sections: sectionEls.map(section => ({
      id: section.id,
      title: textInNodes([section]).join(''),
    })),
  }
}

const useData = (fileId: FileId): Data | undefined => {
  const [data, setData] = useState<Data>()

  const { yDoc } = usePolarisContext()

  useEffect(() => {
    const yType = getSlateDocumentSharedType(yDoc, fileId)

    const handler = (): void => setData(transform(fromSlateSharedType(yType)))

    handler()

    yType.observeDeep(handler)

    return () => yType.unobserveDeep(handler)
  }, [fileId, yDoc])

  return data
}

const useLiveSessionId = (): ScopedLiveSessionId => {
  const liveContext = useLiveSessionIdContextIfAvailable()
  const recapContext = useRecapContext()

  const availble = liveContext ?? recapContext
  if (!availble)
    throw new Error('Unable to find a live session id, make sticky notes are used in a supported context')

  return availble.liveSessionId
}

export const ProjectCard: FC<{ fileId: FileId; mode: EditorMode }> = ({ fileId, mode }) => {
  const liveSessionId = useLiveSessionId()
  const [api, setApi] = useState<StickyNotesCardYjsApi>()
  const data = useData(fileId)

  const provider = useAblyYDocWithAwareness(scopedStickyNotesCardId(fileId, liveSessionId))

  useEffect(() => {
    if (!provider) return

    const handler = (): void => {
      console.debug('[ably provider] top level observer fired')
      if (isStickyNotesCardYDocInitialized(provider.yDoc)) {
        setApi(createStickyNoteYjsApi(provider.yDoc))
      }
    }

    handler()

    provider.yDoc.getMap('dataWrapper').observe(handler)

    return () => {
      provider.yDoc.getMap('dataWrapper').unobserve(handler)
      setApi(undefined)
    }
  }, [provider])

  if (!provider || !data || !api) return <>Loading...</>

  return (
    <Container>
      <CardTitle>{data.title}</CardTitle>
      {mode === 'recap' && <RecapProjectCardView sections={data.sections} api={api} />}
      {mode === 'live' && (
        <LiveProjectCardView awareness={provider.awareness} api={api} sections={data.sections} />
      )}
    </Container>
  )
}
