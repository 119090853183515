import { ContentKind } from 'sierra-client/api/graphql/gql/graphql'
import { CourseKind } from 'sierra-domain/api/common'
import { ImageUnion } from 'sierra-domain/content/v2/content'

export type IssuedByCourse = {
  id: string
  title: string
  image?: ImageUnion
  courseId: string
  courseKind: CourseKind
  description?: string
}
export type IssuedByProgram = { id: string; name: string; image?: ImageUnion; description?: string }

export const contentKindToCourseKind: Record<ContentKind, CourseKind> = {
  LINK: 'link',
  LINKEDIN: 'linkedin',
  SCORM: 'scorm',
  NATIVE_SELF_PACED: 'native:self-paced',
  NATIVE_LIVE: 'native:live',
  NATIVE_COURSE_GROUP: 'native:course-group',
  SCORM_COURSE_GROUP: 'scorm:course-group',
  NATIVE_EVENT_GROUP: 'native:event-group',
}
