import { createFileRoute } from '@tanstack/react-router'
import { requireLoggedIn } from 'sierra-client/core/require-logged-in'
import { EventGroupPage } from 'sierra-client/views/learner/event-group/event-group-page'
import { EventGroupId, PathId } from 'sierra-domain/api/nano-id'
import { ProgramId } from 'sierra-domain/api/uuid'
import { z } from 'zod'

const RouteComponent = (): JSX.Element => {
  const eventGroupId = Route.useParams({ select: params => params.eventGroupId })
  return <EventGroupPage eventGroupId={eventGroupId} />
}

export const Route = createFileRoute('/program/$programId/path/$pathId/event/$eventGroupId/')({
  component: requireLoggedIn(RouteComponent) as React.FC,
  params: {
    parse: z.object({ programId: ProgramId, pathId: PathId, eventGroupId: EventGroupId }).parse,
    stringify: p => p,
  },
})
