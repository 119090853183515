import React from 'react'
import { useTranslation } from 'sierra-client/hooks/use-translation'
import { checkCertificateStatus } from 'sierra-client/views/workspace/utils/certificates'
import { assertNever, isDefined } from 'sierra-domain/utils'
import { color } from 'sierra-ui/color'
import { Text } from 'sierra-ui/primitives'
import styled from 'styled-components'

const CapitalizeText = styled(Text)`
  text-transform: capitalize;
`

export const ExpiredText: React.FC<{ expiresAt?: string; revokedAt?: string }> = ({
  expiresAt,
  revokedAt,
}) => {
  const { t } = useTranslation()

  const certStatus = checkCertificateStatus({ expiresAt, revokedAt })

  switch (certStatus.status) {
    case 'expired': {
      return (
        <CapitalizeText bold color={color('redVivid').opacity(0.5)} size='small'>
          {t('certificates.expired-since-days', { count: Math.ceil(certStatus.since.days) })}
        </CapitalizeText>
      )
    }
    case 'expiring-soon': {
      return (
        <CapitalizeText bold color='redVivid' size='small'>
          {t('certificates.expiring-in-days', { count: Math.ceil(certStatus.in.days) })}
        </CapitalizeText>
      )
    }
    case 'revoked': {
      return (
        <CapitalizeText bold color='redVivid' size='small'>
          {t('manage.heatmap.certificates.revoked')}
        </CapitalizeText>
      )
    }
    case 'valid': {
      const text = isDefined(certStatus.date)
        ? t(`manage.ceritficates.valid-through`, {
            expiredDate: certStatus.date.toLocaleString({ year: 'numeric', month: 'short', day: '2-digit' }),
          })
        : t(`manage.certificates.valid-until-revoked`)
      return (
        <CapitalizeText bold size='small' color='foreground/muted'>
          {text}
        </CapitalizeText>
      )
    }
    default:
      assertNever(certStatus)
  }
}
