import _ from 'lodash'
import { useEffect, useRef, useState } from 'react'
import {
  useGetHomeworksSubmissionAssingedCount,
  useListHomeworksQuery,
} from 'sierra-client/api/hooks/use-homework'
import { PageTitle } from 'sierra-client/components/common/page-title'
import { useIsInViewport } from 'sierra-client/hooks/use-is-in-viewport'
import { useTranslation } from 'sierra-client/hooks/use-translation'
import { stringWithZodSerializer, useQueryState } from 'sierra-client/lib/querystate/use-query-state'
import { useSelector } from 'sierra-client/state/hooks'
import { selectUserId } from 'sierra-client/state/user/user-selector'
import { InfiniteScrollMessage } from 'sierra-client/views/manage/components/common'
import { ManageHomeworkTabular } from 'sierra-client/views/manage/homeworks/manage-homeworks-tabular'
import { ExerciseSubmissionReportModal } from 'sierra-client/views/manage/homeworks/manage-homeworks/exercise-submission-report-modal'
import { HomeworksTable } from 'sierra-client/views/manage/homeworks/manage-homeworks/homeworks-table'
import { useExerciseSubmissionReport } from 'sierra-client/views/manage/homeworks/manage-homeworks/use-exercise-submission-report'
import { JobStatus } from 'sierra-client/views/manage/reports/components/job-status'
import { PageHeader } from 'sierra-client/views/settings/components/page-header'
import { MenuItem, Tabs } from 'sierra-ui/components'
import { Button, Text, View } from 'sierra-ui/primitives'
import { SingleSelectDropdown } from 'sierra-ui/primitives/menu-dropdown'
import styled from 'styled-components'
import z from 'zod'

type ReviewerFilter = 'all' | 'you'

const InvisibleElement = styled.span`
  flex: 0 0 1px;
  transform: translateY(-100px);
`

const ViewWrapper = styled(View).attrs({ direction: 'column', grow: true, gap: 'none' })`
  height: 100%;
  width: 100%;
`

const TableWrapper = styled(View).attrs({ direction: 'column' })`
  max-width: 95%;
`

const InvisibleElementInViewport: React.FC<{ isInViewportCallback: () => void }> = ({
  isInViewportCallback,
}) => {
  const ref = useRef<HTMLSpanElement>(null)
  const isInViewport = useIsInViewport(ref)

  useEffect(() => {
    if (isInViewport) {
      isInViewportCallback()
    }
  }, [isInViewportCallback, isInViewport])

  return <InvisibleElement ref={ref} />
}

// TODO: Refactor or replace?
const OldTable: React.FC = () => {
  const { t } = useTranslation()
  const userId = useSelector(selectUserId)
  const [reviewerFilter, setReviewerFilter] = useState<ReviewerFilter>('you')
  const query = useListHomeworksQuery({
    commonFilters: { reviewerId: reviewerFilter === 'all' ? undefined : userId },
  })
  const { exerciseSubmissionReport, addJob, latestJob, reportTitle } = useExerciseSubmissionReport()

  const [reportModalOpen, setReportModalOpen] = useState(false)

  const latestPage = _.last(query.data?.pages)
  const fetchMoreHomeworks = async (): Promise<void> => {
    await query.fetchNextPage()
  }

  const items: MenuItem<ReviewerFilter>[] = [
    {
      type: 'label',
      id: 'all',
      label: t('manage.homework.any-reviewer'),
      selected: reviewerFilter === 'all',
    },
    {
      type: 'label',
      id: 'you',
      label: t('manage.homework.assigned-to-you'),
      selected: reviewerFilter === 'you',
    },
  ]
  const selectedItem = items.find(item => item.id === reviewerFilter)

  return (
    <>
      <View direction='column' grow>
        <View gap='8' justifyContent='space-between'>
          <View>
            <SingleSelectDropdown
              menuItems={items}
              selectedItem={selectedItem}
              onSelect={item => setReviewerFilter(item.id)}
              bold
            />
          </View>
          {exerciseSubmissionReport !== undefined && (
            <View>
              {latestJob && <JobStatus job={latestJob} />}
              <Button variant='secondary' onClick={() => setReportModalOpen(true)}>
                {t('dictionary.export')}
              </Button>
            </View>
          )}
        </View>
        <TableWrapper>
          {query.isPending ? (
            <InfiniteScrollMessage padding='64' text={t('dictionary.loading')} showSanaLogo />
          ) : (
            <>
              {latestPage !== undefined && (
                <>
                  <HomeworksTable data={latestPage.data} />
                  {query.isFetchingNextPage ? (
                    <InfiniteScrollMessage padding='64' text={t('dictionary.loading')} showSanaLogo />
                  ) : (
                    <>
                      {query.hasNextPage === true && (
                        <InvisibleElementInViewport isInViewportCallback={fetchMoreHomeworks} />
                      )}
                    </>
                  )}
                </>
              )}
            </>
          )}
        </TableWrapper>
      </View>
      {exerciseSubmissionReport !== undefined && (
        <ExerciseSubmissionReportModal
          report={exerciseSubmissionReport}
          isOpen={reportModalOpen}
          onClose={() => setReportModalOpen(false)}
          title={reportTitle}
          addJob={addJob}
        />
      )}
    </>
  )
}

const Count = styled(View).attrs({
  radius: 'size-28',
  color: 'foreground/primary',
  paddingLeft: '6',
  paddingRight: '6',
})`
  background: ${p => p.theme.color.greenBright};
`

const TabbedContentContainer = styled(View).attrs({
  paddingTop: 'xsmall',
  grow: true,
  alignItems: 'flex-start',
})`
  height: 100%;
`

const zodTabType = z.enum(['exercises', 'submissions'])
const selectedTabSerializer = stringWithZodSerializer(zodTabType)

export const ManageHomeworks: React.FC = () => {
  const { t } = useTranslation()
  const [selectedTab, selectTab] = useQueryState(selectedTabSerializer, 'submissions', 'tab')

  const count = useGetHomeworksSubmissionAssingedCount()

  return (
    <>
      <PageTitle title={t('dictionary.homework-plural')} />

      <ViewWrapper>
        <PageHeader
          title='dictionary.homework-plural'
          withoutMargin
          helpLink='https://help.sana.ai/en/articles/32545-exercises'
        />

        <Tabs
          grow
          items={[
            {
              content: (
                <TabbedContentContainer>
                  <ManageHomeworkTabular />
                </TabbedContentContainer>
              ),
              id: 'submissions',
              label: (
                <View gap='xxsmall'>
                  <Text>{t('manage.homework.submission-plural')}</Text>
                  {count !== 0 && (
                    <Count role='status' aria-label={t('manage.homework.submission-pill', { count })}>
                      <Text color='white' size='micro' bold>
                        {count}
                      </Text>
                    </Count>
                  )}
                </View>
              ),
            },
            {
              content: (
                <TabbedContentContainer>
                  <OldTable />
                </TabbedContentContainer>
              ),
              id: 'exercises',
              label: t('dictionary.homework-plural'),
            },
          ]}
          onChange={selectTab}
          value={selectedTab}
        />
      </ViewWrapper>
    </>
  )
}
