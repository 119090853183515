import { useAtomValue, useSetAtom } from 'jotai'
import { loadable } from 'jotai/utils'
import { useEffect } from 'react'
import {
  collapsableSidebarStateAtom,
  initialCollapsableSidebarStateAtom,
} from 'sierra-client/features/collapsable-sidebar/atoms'
import { useScrollbarWidth } from 'sierra-client/features/collapsable-sidebar/hooks/use-scrollbar-width'

/* The sidebar is ready when we have loaded the previous user state and the scrollbar width of the browser */
/* Prevents the browser from jiggling when loading */
export const useInitialSidebarStateReady = (): boolean => {
  const initialSidebarState = useAtomValue(loadable(initialCollapsableSidebarStateAtom))
  const setSidebarState = useSetAtom(collapsableSidebarStateAtom)
  const scrollbarWidth = useScrollbarWidth()

  /* Initialize the initial state to collapsed if it's undefined */
  useEffect(() => {
    if (initialSidebarState.state !== 'hasData') return
    setSidebarState(initialSidebarState.data ?? 'collapsed')
  }, [setSidebarState, initialSidebarState])

  return !(scrollbarWidth === undefined || initialSidebarState.state !== 'hasData')
}
