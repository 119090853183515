import { SetStateAction, atom } from 'jotai'
import { CollapsableSidebarState } from 'sierra-client/features/collapsable-sidebar/types'
import { atomWithStorage } from 'sierra-client/state/storage'
import { assertNever } from 'sierra-domain/utils'

export const scrollbarWidthAtom = atom<number | undefined>(undefined)

export const sidebarHasNestedMenuOpenAtom = atom(false)

export const initialCollapsableSidebarStateAtom = atomWithStorage<CollapsableSidebarState | undefined>(
  'initial-collapsable-sidebar-state',
  'sticky',
  undefined,
  {
    getOnInit: true,
  }
)
// This is the state of the sidebar outside of Sana Now
export const internalCollapsableSidebarStateAtom = atom<CollapsableSidebarState | undefined>(undefined)

export const sanaNowSidebarAtom = atom<CollapsableSidebarState | undefined>(undefined)

// When we enter a Sana Now session we set the "sanaNowSidebarAtom", and we set it to undefined when we leave,
// if the "sanaNowSidebarAtom" is undefined, we use internalCollapsableSidebarStateAtom instead.
// We introduced the separate SanaNowState to be able to overrule the users preference
// and force close the sidebar when entering a now session.
export const collapsableSidebarStateAtom = atom(
  get => get(sanaNowSidebarAtom) ?? get(internalCollapsableSidebarStateAtom),
  (get, set, update: SetStateAction<CollapsableSidebarState | undefined>) => {
    const sanaNowSidebarState = get(sanaNowSidebarAtom)
    const newValue =
      update === undefined || typeof update === 'string' ? update : update(get(collapsableSidebarStateAtom))

    if (sanaNowSidebarState !== undefined) {
      set(sanaNowSidebarAtom, newValue)
    } else {
      set(internalCollapsableSidebarStateAtom, newValue)
    }
  }
)

export const collapsableSidebarBooleanStateAtom = atom(
  get => {
    const sidebarState = get(collapsableSidebarStateAtom)

    switch (sidebarState) {
      case 'sticky':
        return true
      case 'collapsed':
      case 'hovered':
      case undefined:
        return false
      default:
        assertNever(sidebarState)
    }
  },
  (get, set, update: SetStateAction<boolean>) => {
    const newValue = typeof update === 'boolean' ? update : update(get(collapsableSidebarBooleanStateAtom))

    set(collapsableSidebarStateAtom, newValue ? 'sticky' : 'collapsed')
  }
)

export const sidebarVersionAtom = atom<'new-sidebar' | 'sana-now-sidebar'>('new-sidebar')
