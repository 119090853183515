import { downloadBlob } from 'sierra-client/api'
import { FileAttachment as FileAttachmentBlock } from 'sierra-domain/v3-author'
import { palette } from 'sierra-ui/theming'
import { theme } from 'sierra-ui/theming/legacy-theme'

export const downloadFileAttachment = async (url: string, name: string): Promise<void> => {
  await fetch(url)
    .then(resp => resp.blob())
    .then(blob => {
      downloadBlob(blob, name)
    })
}

// https://stackoverflow.com/a/18650828
export const formatBytes = (bytes: number, decimals = 2): string => {
  if (bytes === 0) return '0 Bytes'

  const k = 1024
  const dm = decimals < 0 ? 0 : decimals
  const sizes = ['Bytes', 'KB', 'MB', 'GB', 'TB', 'PB', 'EB', 'ZB', 'YB']

  const i = Math.floor(Math.log(bytes) / Math.log(k))

  const value = parseFloat((bytes / Math.pow(k, i)).toFixed(dm)).toString()
  const unit = sizes[i] ?? 'unknown'

  return `${value} ${unit}`
}

export type Color = FileAttachmentBlock['color']

export const getColor = (color: Color): string =>
  ({
    blue: theme.color.blueDark,
    red: theme.color.redVivid,
    black: palette.primitives.black,
  })[color]
