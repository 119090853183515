import { FCC } from 'sierra-client/types'
import { isNotDefined } from 'sierra-domain/utils'
import { Icon, IconId } from 'sierra-ui/components'

export type CardIconStatus = 'completed' | 'pending' | 'failed'

const getIcon = (s?: CardIconStatus): IconId => {
  if (isNotDefined(s)) {
    return 'course--card'
  }

  switch (s) {
    case 'completed':
      return 'course--card--completed'
    case 'pending':
      return 'course--card--pending'
    case 'failed':
      return 'course--card--x'
  }
}

export const CardIcon: FCC<{
  isCurrentItem: boolean
  showPlayIcon?: boolean
  status?: CardIconStatus
}> = ({ isCurrentItem, status, showPlayIcon }) => {
  return (
    <Icon
      iconId={showPlayIcon === true ? 'course--card--playing' : getIcon(status)}
      color={isCurrentItem ? 'foreground/primary' : 'foreground/muted'}
    />
  )
}
