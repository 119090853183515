import { useTranslation } from 'sierra-client/hooks/use-translation'
import { EnrollmentRuleWrapper } from 'sierra-client/views/manage/programs/enrollment-rules/modals/common'
import { Icon } from 'sierra-ui/components'
import { Text, View } from 'sierra-ui/primitives'
import { token } from 'sierra-ui/theming'
import styled from 'styled-components'

const Box = styled(View).attrs({ radius: 'size-6', padding: '10' })`
  background: ${token('surface/soft')};
  border: 1px solid ${token('border/default')};
`

const Plus: React.FC = () => (
  <Box>
    <Icon iconId='add' color='foreground/muted' />
  </Box>
)

const StyledEnrollmentRuleWrapper = styled(EnrollmentRuleWrapper).attrs({ cursor: 'pointer' })``

export const AddEnrollmentRule: React.FC<{ onClick: () => void }> = ({ onClick }) => {
  const { t } = useTranslation()

  return (
    <StyledEnrollmentRuleWrapper onClick={onClick}>
      <Plus />
      <Text size='small' bold>
        {t('manage.programs.enrollment-rules.add-rule')}
      </Text>
    </StyledEnrollmentRuleWrapper>
  )
}
