import { AnimatePresence, motion } from 'framer-motion'
import React from 'react'
import { NestedItemList } from 'sierra-client/features/collapsable-sidebar'
import { FileContainer } from 'sierra-client/views/flexible-content/editor/content-sidebar/browse-file'
import { FolderTitleRow } from 'sierra-client/views/flexible-content/editor/content-sidebar/browse-folder'
import { Icon } from 'sierra-ui/components'
import { View } from 'sierra-ui/primitives'
import { token } from 'sierra-ui/theming'
import styled from 'styled-components'

const SkeletonFileContainer = styled(FileContainer)`
  /* I don't want to have to do this >:( */
  padding: 8px 4px 8px 8px;
  margin-top: 2px;
  margin-bottom: 2px;
  gap: 0.5rem;
`

const Skeleton = styled.span<{ height: number; width: number; radius: number }>`
  display: inline-block;
  background-color: ${token('foreground/primary').opacity(0.1)};
  height: ${p => p.height}px;
  width: ${p => p.width}px;
  border-radius: ${p => p.radius}px;

  /* fade in and out animation css with keyframes */
  animation: fading 1.5s ease-in-out 0.5s infinite;
  @keyframes fading {
    0% {
      opacity: 100%;
    }
    50% {
      opacity: 40%;
    }
    100% {
      opacity: 100%;
    }
  }
`

export const CreatePageSidebarSkeleton: React.FC = () => {
  return (
    <>
      <AnimatePresence>
        <motion.div transition={{ duration: 0.5 }} initial={{ opacity: 0.2 }} animate={{ opacity: 1 }}>
          <FolderTitleRow>
            <Icon color='grey25' iconId={'module'} size='size-16' />
            <View grow>
              <Skeleton radius={4} height={12} width={120} />
            </View>
          </FolderTitleRow>
          <NestedItemList open>
            <SkeletonFileContainer>
              <div style={{ minWidth: '20px' }}>
                <Skeleton radius={6} height={20} width={20} />
              </div>
              <View grow>
                <Skeleton radius={4} height={12} width={115} />
              </View>
            </SkeletonFileContainer>
            <SkeletonFileContainer>
              <div style={{ minWidth: '20px' }}>
                <Skeleton radius={6} height={20} width={20} />
              </div>
              <View grow>
                <Skeleton radius={4} height={12} width={110} />
              </View>
            </SkeletonFileContainer>
          </NestedItemList>
          <FolderTitleRow>
            <Icon color='grey25' iconId={'module'} size='size-16' />
            <View grow>
              <Skeleton radius={4} height={12} width={110} />
            </View>
          </FolderTitleRow>
          <NestedItemList open>
            <SkeletonFileContainer>
              <div style={{ minWidth: '20px' }}>
                <Skeleton radius={6} height={20} width={20} />
              </div>
              <View grow>
                <Skeleton radius={4} height={12} width={110} />
              </View>
            </SkeletonFileContainer>
            <SkeletonFileContainer>
              <div style={{ minWidth: '20px' }}>
                <Skeleton radius={6} height={20} width={20} />
              </div>
              <View grow>
                <Skeleton radius={4} height={12} width={100} />
              </View>
            </SkeletonFileContainer>
            <SkeletonFileContainer>
              <div style={{ minWidth: '20px' }}>
                <Skeleton radius={6} height={20} width={20} />
              </div>
              <View grow>
                <Skeleton radius={4} height={12} width={110} />
              </View>
            </SkeletonFileContainer>
          </NestedItemList>
        </motion.div>
      </AnimatePresence>
    </>
  )
}
