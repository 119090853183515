import React, { FC, PropsWithChildren, useContext, useState } from 'react'
import { PillState, PillVariant, allPillVariants } from 'sierra-client/components/common/pill/pill-styles'
import { Label, LabelProps, Tooltip } from 'sierra-ui/components'
import { View } from 'sierra-ui/primitives/views/view'
import { ConditionalWrapper } from 'sierra-ui/utils/conditional-wrapper'
import { ThemeContext } from 'styled-components'

type PillProps = React.HTMLAttributes<HTMLSpanElement> & {
  children?: LabelProps['children']

  variant?: PillVariant
  active?: boolean
  disabled?: LabelProps['disabled']
  maxWidth?: LabelProps['$maxWidth']
  size?: LabelProps['$size']
  iconId?: LabelProps['iconId']
  iconPos?: LabelProps['iconPos']
}

const getPillState = ({ active, hovered }: { active: boolean; hovered: boolean }): PillState => {
  if (active === true) return 'active'
  if (hovered === true) return 'hovered'
  return 'default'
}

/**
 * @deprecated Use `sierra-ui/primitives/pill` instead
 */
export const Pill = React.forwardRef<HTMLSpanElement, PillProps>(
  (
    {
      active = false,
      size = 'small',
      variant = 'filled',
      iconId,
      children,
      iconPos,
      maxWidth,
      disabled,
      onClick,
      ...rest
    },
    ref
  ) => {
    const currentTheme = useContext(ThemeContext)
    const [hovered, setHovered] = useState(false)

    const pillState = getPillState({ active, hovered })
    const currentPillStyle = allPillVariants[variant][pillState][currentTheme.mode]
    const hasOnlyTextContent = typeof children === 'string' && children.length > 0 && maxWidth !== undefined

    return (
      <ConditionalWrapper
        condition={hasOnlyTextContent}
        renderWrapper={c => <Tooltip title={hasOnlyTextContent ? children : ''}>{c}</Tooltip>}
      >
        <Label
          {...currentPillStyle}
          $size={size}
          $maxWidth={maxWidth}
          iconId={iconId}
          iconPos={iconPos}
          onClick={onClick}
          ref={ref}
          disabled={disabled}
          {...rest}
          onMouseEnter={(...args) => {
            setHovered(true)
            // Allow usage of onMouseEnter from parent component
            if (rest.onMouseEnter !== undefined) rest.onMouseEnter(...args)
          }}
          onMouseLeave={(...args) => {
            setHovered(false)
            // Allow usage of onMouseLeave from parent component
            if (rest.onMouseLeave !== undefined) rest.onMouseLeave(...args)
          }}
        >
          {children}
        </Label>
      </ConditionalWrapper>
    )
  }
)

/**
 * @deprecated
 **/
export const PillRow: FC<PropsWithChildren<{ $multiline?: boolean }>> = ({ $multiline, children }) => {
  return <View wrap={$multiline === true ? 'wrap' : 'nowrap'}>{children}</View>
}
