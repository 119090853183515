import React, { useCallback, useEffect, useState } from 'react'
import { useProgramReminderRecipients } from 'sierra-client/views/manage/programs/hooks/use-program-reminder-recipients'
import { useLightColor } from 'sierra-client/views/manage/programs/staggered-assignments/utils'
import { View } from 'sierra-ui/primitives'
import { token, useTokenValue } from 'sierra-ui/theming'
import styled from 'styled-components'

const PreviewLayout = styled(View)<{ backgroundColor: string }>`
  background-color: ${p => p.backgroundColor};
  width: 100%;
  min-height: 350px;
  height: 400px;
  transition: background-color 100ms cubic-bezier(0.25, 0.1, 0.25, 1) 200ms;
  border-bottom: 1px solid ${token('border/default')};
  overflow-y: scroll;
  overflow-x: hidden;
  position: relative;
  top: 0;
`

const StyledIframe = styled.iframe<{ backgroundColor: string }>`
  width: 100%;
  height: auto;
  position: absolute;
  top: 0;
  background-color: ${p => p.backgroundColor};
  overflow: hidden;
  transform-origin: top center;
  transform: scale(0.8);
  transition: background-color 100ms cubic-bezier(0.25, 0.1, 0.25, 1) 200ms;
  margin-top: -40px;
`

const Preview: React.FC<{ html: string | undefined; background: string }> = ({ html, background }) => {
  const [previewElement, setPreviewElement] = useState<HTMLIFrameElement | null>(null)

  const calculateHeight = useCallback(() => {
    if (previewElement === null || previewElement.contentWindow === null) return
    previewElement.style.height = '0'
    previewElement.style.height = previewElement.contentWindow.document.body.scrollHeight + 'px'
  }, [previewElement])

  useEffect(calculateHeight, [calculateHeight])

  useEffect(() => {
    if (previewElement === null) return

    previewElement.onload = () => {
      calculateHeight()
    }
  }, [previewElement, calculateHeight])

  return (
    html !== undefined && (
      <StyledIframe srcDoc={html} scrolling='no' backgroundColor={background} ref={setPreviewElement} />
    )
  )
}

export const ProgramReminderPreviewSection: React.FC<{ programId: string }> = ({ programId }) => {
  const [renderedEmail, setRenderedEmail] = useState<string>('<html/>')

  const getToken = useTokenValue()
  const orgColor = getToken('org/primary')
  const lightColor = useLightColor(orgColor)

  const { fetchRenderedEmail } = useProgramReminderRecipients(programId)

  useEffect(() => {
    async function fetchHtml(): Promise<string> {
      const result = await fetchRenderedEmail(programId)
      setRenderedEmail(result.emailHtml.html)
      return result.emailHtml.html
    }

    void fetchHtml()
  }, [fetchRenderedEmail, programId])

  return (
    <PreviewLayout backgroundColor={lightColor}>
      <Preview html={renderedEmail} background={lightColor} />
    </PreviewLayout>
  )
}
