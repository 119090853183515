import React from 'react'
import {
  AdaptiveState,
  createSubmitEmbedResponse,
  createSubmitQuestionResponse,
  currentElement,
  goToNextElement,
  resetResponse,
  start,
  updateResponse,
} from 'sierra-client/views/self-paced/adaptive-features/adaptive-context'

const submitQuestionResponse = createSubmitQuestionResponse('review')
const submitEmbedResponse = createSubmitEmbedResponse('review')

export const Reviews = {
  currentElement,
  updateResponse,
  submitQuestionResponse,
  submitEmbedResponse,
  resetResponse,
  goToNextElement,
  start,
}

export type ReviewContext = {
  state: AdaptiveState
  setState: React.Dispatch<React.SetStateAction<AdaptiveState>>
}

const ReactReviewContext = React.createContext<ReviewContext | undefined>(undefined)
export const ReviewContextProvider = ReactReviewContext.Provider

export function useReviewContext(): ReviewContext {
  const context = React.useContext(ReactReviewContext)

  if (context === undefined) throw new Error('useReviewContext must be used within a ReviewContextProvider')
  return context
}
