import _ from 'lodash'
import React from 'react'

type Size = { width: number; height: number }

type Params = {
  throttleMs?: number
}

export const useObserveElementSize = (
  element: HTMLElement | null,
  { throttleMs = 100 }: Params = {}
): Size => {
  const [width, setWidth] = React.useState(0)
  const [height, setHeight] = React.useState(0)

  React.useEffect(() => {
    if (!element) {
      // We could return undefined here, that would make sense.
      // But in a way I guess 0x0 is also true if an element is not visible.
      setWidth(0)
      setHeight(0)
      return
    }

    const _onResize = (): void => {
      setWidth(element.clientWidth)
      setHeight(element.clientHeight)
    }

    const onResize = throttleMs > 0 ? _.throttle(_onResize, throttleMs) : _onResize

    onResize()

    const observer = new ResizeObserver(onResize)
    observer.observe(element)

    return () => {
      observer.disconnect()
    }
  }, [element, throttleMs])

  return React.useMemo(() => ({ width, height }), [width, height])
}
