import React from 'react'
import { editorGrid } from 'sierra-client/editor/layout'
import { TextToSpeech } from 'sierra-client/editor/text-to-speech'
import {
  bullet,
  getLineHeightForListItem,
  getListItemSize,
  ListItemSizes,
} from 'sierra-client/views/v3-author/list/utils'
import { assertElementType } from 'sierra-client/views/v3-author/queries'
import { SlateFC, SlateWrapperProps } from 'sierra-client/views/v3-author/slate'
import { fonts } from 'sierra-ui/theming/fonts'
import styled, { css } from 'styled-components'

export const ListItemInnerCss = css<{ $indent?: number; $level: ListItemSizes }>`
  margin-left: ${p => p.$indent !== undefined && p.$indent > 0 && `calc(${p.$indent} * 1.5rem)`} !important;
  line-height: ${p => getLineHeightForListItem(p.$level)};

  ${p =>
    p.$level === 'large' || p.$level === 'regular' || p.$level === 'small'
      ? fonts.body[p.$level]
      : fonts.heading[p.$level]}

  &::before {
    content: ${p => `'${bullet(p.$indent)}  '`};
    color: ${p => p.theme.home.textColor};
  }
`

const ListItemInner = styled.li<{ $indent?: number; $level: ListItemSizes }>`
  position: relative;
  color: ${p => p.theme.home.textColor};

  && {
    transform: translateX(14px);
  }

  ${ListItemInnerCss}
`

export const ListItemContainer = React.forwardRef(
  (
    { element, children, ...attributes }: SlateWrapperProps,
    ref: React.ForwardedRef<HTMLLIElement | null>
  ) => {
    assertElementType('list-item', element)
    return (
      <ListItemInner
        $level={getListItemSize(element.level)}
        data-ordinal={element.ordinal}
        $indent={element.indent ?? 0}
        {...attributes}
        ref={ref}
      >
        {children}
      </ListItemInner>
    )
  }
)

export const ListItem: SlateFC = ({ children, ...props }) => <span {...props}>{children}</span>

export const UnorderedListItemCss = css`
  position: relative;
  margin-left: 9px;
`

const UnorderedListCss = css`
  list-style: none;
  text-indent: -15px;

  &&& {
    row-gap: 0;
  }

  & li {
    ${UnorderedListItemCss}
  }

  ul li::before,
  &::before {
    color: ${p => p.theme.home.textColor};
    position: absolute;
    left: 0;
  }
`

export const OrderedListItemCss = css`
  ${UnorderedListItemCss}
  margin-left:11px;
  &::before {
    content: attr(data-ordinal) '.';
    margin-right: 6px;
    margin-left: -0.25rem;
    color: ${p => p.theme.home.textColor};
    font-variant-numeric: tabular-nums;
  }
`
const OrderedListCss = css`
  ${UnorderedListCss}
  & li {
    ${OrderedListItemCss}
  }
`

const _UnorderedList = styled.ul`
  ${UnorderedListCss}
  position: relative;

  ${editorGrid}
`

export const UnorderedList = React.forwardRef<HTMLUListElement, SlateWrapperProps>(
  ({ element, children, attributes, mode, ...props }, ref) => {
    return (
      <_UnorderedList {...attributes} {...props} ref={ref}>
        {children}
        <TextToSpeech element={element} />
      </_UnorderedList>
    )
  }
)

const _OrderedList = styled.ol`
  ${OrderedListCss}
  position: relative;
  ${editorGrid}
`

export const OrderedList = React.forwardRef<HTMLOListElement, SlateWrapperProps>(
  ({ element, children, attributes, mode, ...props }, ref) => {
    return (
      <_OrderedList {...attributes} {...props} ref={ref}>
        {children}
        <TextToSpeech element={element} />
      </_OrderedList>
    )
  }
)
