import { AnimatePresence, motion } from 'framer-motion'
import type { SkillId } from 'sierra-client/api/graphql/branded-types'
import { useGraphQuery } from 'sierra-client/api/hooks/use-graphql-query'
import { useCurrentUser } from 'sierra-client/api/hooks/use-user'
import {
  getMySubcriptionsQuery,
  useInvalidateGetMySubcriptionsQuery,
  useSubscribeLearnerMutation,
  useUnsubscribeLearnerMutation,
} from 'sierra-client/features/skills/shared-gql-queries'
import { useTranslation } from 'sierra-client/hooks/use-translation'
import { useIsTablet } from 'sierra-client/state/browser/selectors'
import { isNotDefined } from 'sierra-domain/utils'
import { Button, Skeleton, Text, View } from 'sierra-ui/primitives'
import { useTracking } from '../../tracking'

export const SubscribeButton: React.FC<{
  skillId: SkillId
  onSubscribeUpdate?: () => void
}> = ({ skillId, onSubscribeUpdate }) => {
  const { t } = useTranslation()
  const tracking = useTracking()
  const isTablet = useIsTablet()

  const userQuery = useCurrentUser()
  const query = useGraphQuery({ document: getMySubcriptionsQuery }, { id: skillId })
  const invalidateQuery = useInvalidateGetMySubcriptionsQuery()

  const { mutate: subscribeMutation, isPending: isSubscribing } = useSubscribeLearnerMutation({
    onSuccess: () => {
      tracking.learner.selfSubscribe({ skillId })
      void invalidateQuery()
      onSubscribeUpdate?.()
    },
  })

  const { mutate: unsubscribeMutation, isPending: isUnsubscribing } = useUnsubscribeLearnerMutation({
    onSuccess: () => {
      tracking.learner.selfUnsubscribe({ skillId })
      void invalidateQuery()
      onSubscribeUpdate?.()
    },
  })

  if (isNotDefined(userQuery.data) || isNotDefined(query.data)) {
    return <Skeleton $width={115} $height={36} $radius={10} />
  }

  const userId = userQuery.data.uuid
  const isSubscribed = query.data.me.skills.some(skill => skill.id === skillId)
  const skillLevelSettingIds = query.data.skill?.skillLevels.map(it => it.levelSetting.id) ?? []

  const handleClick = (): void => {
    if (isSubscribing || isUnsubscribing) return

    if (isSubscribed) {
      unsubscribeMutation({ subscriptions: { skillId, userId } })
    } else {
      subscribeMutation({
        subscriptions: skillLevelSettingIds.map(id => ({
          skillId,
          userId,
          skillLevelSettingId: id,
          alreadyAchieved: false,
        })),
      })
    }
  }

  return (
    <View gap='24'>
      <AnimatePresence initial={false}>
        {!isSubscribed && !isTablet && (
          <motion.div
            transition={{ ease: [0.25, 0.1, 0.25, 1], duration: 0.18 }}
            initial={{ opacity: 0, translateX: 20 }}
            animate={{ opacity: 1, translateX: 0 }}
            exit={{ opacity: 0, translateX: 10 }}
          >
            <Text color='foreground/muted'>{t('skills.subscribe.description')}</Text>
          </motion.div>
        )}
      </AnimatePresence>
      <Button
        variant={isSubscribed ? 'secondary' : 'primary'}
        icon={isSubscribed ? 'checkmark' : undefined}
        onClick={handleClick}
      >
        {isSubscribed ? t('skills.users.following.text') : t('skills.users.follow.text')}
      </Button>
    </View>
  )
}
