import { Pill, PillRow } from 'sierra-client/components/common/pill'
import { useTranslation } from 'sierra-client/hooks/use-translation'
import { AssignmentData } from 'sierra-domain/api/manage'
import { Text } from 'sierra-ui/primitives'

export const AssignedViaPill: React.FC<{
  assignmentData?: AssignmentData
  maxPills?: number
}> = ({ assignmentData, maxPills = 2 }) => {
  const { t } = useTranslation()
  if (assignmentData === undefined) return null
  if (assignmentData.type === 'individual') return <>{t('manage.users.assignment-type.individual')}</>

  const extraAssignments = assignmentData.programs.length - maxPills
  const extraAssignmentsText = extraAssignments > 0 ? `+${extraAssignments}` : undefined
  const visibleAssignments = assignmentData.programs.slice(0, maxPills)

  return (
    <PillRow $multiline>
      {visibleAssignments.map(p => (
        <Pill maxWidth={150} key={p.uuid}>
          {p.name}
        </Pill>
      ))}
      {extraAssignmentsText !== undefined && (
        <Text size='small' color='grey25'>
          {extraAssignmentsText}
        </Text>
      )}
    </PillRow>
  )
}
