import { UseMutationResult, UseQueryResult } from '@tanstack/react-query'
import { typedInvalidateQuery, useCachedQuery, useTypedMutation } from 'sierra-client/state/api'
import { DeleteHeatmapRequest, ListHeatmapsResponse } from 'sierra-domain/api/heatmap'
import { RequestError } from 'sierra-domain/error'
import { XRealtimeAdminHeatmapDeleteHeatmap, XRealtimeAdminHeatmapListHeatmaps } from 'sierra-domain/routes'

export const useListHeatmaps = (): UseQueryResult<ListHeatmapsResponse> => {
  const response = useCachedQuery(XRealtimeAdminHeatmapListHeatmaps, {})
  return response
}

export const invalidateListHeatmaps = async (): Promise<void> => {
  await typedInvalidateQuery(XRealtimeAdminHeatmapListHeatmaps, {})
}

export const useDeleteHeatmap = (): UseMutationResult<null, RequestError, DeleteHeatmapRequest> => {
  const mutation = useTypedMutation(XRealtimeAdminHeatmapDeleteHeatmap, {
    onSuccess: async () => {
      await invalidateListHeatmaps()
    },
  })
  return mutation
}
