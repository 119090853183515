import { useCallback } from 'react'
import { useTranslation } from 'sierra-client/hooks/use-translation'
import { typedPost, useCachedQuery } from 'sierra-client/state/api'
import {
  EmailValidationReason,
  EmailWithValidation,
  EmailWithValidationFailed,
  EmailWithValidationSuccess,
} from 'sierra-client/views/manage/components/user-attributes/flows/invite-users/tabs/invite-via-email/email-auto-complete/types'
import { isEmail } from 'sierra-client/views/manage/components/user-attributes/flows/invite-users/utils'
import { EmailSuffixes } from 'sierra-domain/api/user'
import {
  XRealtimeAdminCheckAlreadyInUseEmails,
  XRealtimeGetOrganizationEmailSuffixes,
} from 'sierra-domain/routes'

const verifyEmailPatternUtil = (
  email: string,
  suffixData: EmailSuffixes | undefined
): EmailWithValidation => {
  if (suffixData === undefined) {
    return { id: email, success: true }
  }

  const { suffixes } = suffixData

  // Split the email into local and domain parts
  const parts = email.split('@')

  if (parts.length !== 2) {
    return { id: email, success: false, reason: 'pattern' } // Email must have one "@" symbol
  }

  const domainPart = parts[1]

  // Check if the email matches the regular expression
  if (!isEmail(email) || domainPart === undefined) {
    return { id: email, success: false, reason: 'pattern' }
  }

  if (suffixData.suffixes.length === 0) {
    return { id: email, success: true }
  }

  if (!Boolean(suffixes.some(suffix => domainPart.endsWith(suffix)))) {
    // Check if the domain part matches any of the allowed suffixes
    return { id: email, success: false, reason: 'suffix' }
  }

  return { id: email, success: true }
}

const verifyEmailPatternsUtil = (
  emails: string[],
  suffixData: EmailSuffixes | undefined
): EmailWithValidation[] => emails.map(email => verifyEmailPatternUtil(email, suffixData))

const verifyEmailAlreadyInUseUtil = (email: string, emailsAlreadyInUse: string[]): EmailWithValidation =>
  emailsAlreadyInUse.includes(email.toLowerCase())
    ? { id: email, success: false, reason: 'used' }
    : { id: email, success: true }

const verifyEmailsAlreadyInUseUtil = (
  emails: string[],
  emailsAlreadyInUse: string[]
): EmailWithValidation[] => emails.map(email => verifyEmailAlreadyInUseUtil(email, emailsAlreadyInUse))

const toEmailList = (emails: string[] | undefined): string => (emails ?? []).map(s => '@' + s).join(', ')

type EmailVerificationProps = {
  validateEmails: (emails: string[]) => Promise<EmailWithValidation[]>
  getEmailErrorMessage: (reason: EmailValidationReason) => string
}

export const isInvalidEmail = (e: EmailWithValidation): e is EmailWithValidationFailed => e.success === false

export const isValidEmail = (e: EmailWithValidation): e is EmailWithValidationSuccess => e.success === true

export const useEmailVerification = (): EmailVerificationProps => {
  const { t } = useTranslation()
  const { data: suffixData } = useCachedQuery(XRealtimeGetOrganizationEmailSuffixes, {}, {})

  const verifyEmailPatterns = useCallback(
    (emails: string[]): EmailWithValidation[] => verifyEmailPatternsUtil(emails, suffixData),
    [suffixData]
  )

  const verifyEmailsAlreadyInUse = useCallback(async (emails: string[]): Promise<EmailWithValidation[]> => {
    const { emailsAlreadyInUse } = await typedPost(XRealtimeAdminCheckAlreadyInUseEmails, {
      emailsToCheck: emails.map(e => e.toLowerCase()),
    })

    const withValidation: EmailWithValidation[] = verifyEmailsAlreadyInUseUtil(emails, emailsAlreadyInUse)
    return withValidation
  }, [])

  const validateEmails: EmailVerificationProps['validateEmails'] = useCallback(
    async emails => {
      const patternVerifiedPassed = verifyEmailPatterns(emails).filter(e => e.success)
      const patternVerifiedFailed = verifyEmailPatterns(emails).filter(e => !e.success)
      const alreadyInUseVerified = await verifyEmailsAlreadyInUse(patternVerifiedPassed.map(e => e.id))

      return [...patternVerifiedFailed, ...alreadyInUseVerified]
    },
    [verifyEmailPatterns, verifyEmailsAlreadyInUse]
  )

  const getEmailErrorMessage: EmailVerificationProps['getEmailErrorMessage'] = useCallback(
    reason => {
      switch (reason) {
        case 'pattern':
          return t('dictionary.invalid-email')
        case 'suffix':
          return t('manage.users.invite.email-suffixes', {
            emails: toEmailList(suffixData?.suffixes),
          })
        case 'used':
          return t('manage.users.invite.email-already-in-use')
      }
    },
    [suffixData?.suffixes, t]
  )

  return {
    validateEmails,
    getEmailErrorMessage,
  }
}
