import { RouterLink } from 'sierra-client/components/common/link'
import { NotificationContainer, Title } from 'sierra-client/features/activity-center/notifications/components'
import { ContentContainer } from 'sierra-client/features/activity-center/notifications/components/content-card'
import { EntityImage } from 'sierra-client/features/activity-center/notifications/components/entity-image'
import { NotificationIcon } from 'sierra-client/features/activity-center/notifications/components/notification-icon'
import { NotificationTitle } from 'sierra-client/features/activity-center/notifications/components/notification-title'
import { convertLearnEntityToLinkable } from 'sierra-client/views/workspace/utils/entity-to-linkable'
import { detailsUrl } from 'sierra-client/views/workspace/utils/urls'
import { entityDescription } from 'sierra-domain/api/entities'
import { HomeNotification } from 'sierra-domain/api/learn'
import { assertIsNonNullable, ExtractFrom } from 'sierra-domain/utils'
import { TruncatedText } from 'sierra-ui/components'
import { View } from 'sierra-ui/primitives'

export const UserUnassignedNotification: React.FC<{
  notification: ExtractFrom<HomeNotification, { type: 'user-unassigned' }>
}> = ({ notification }) => {
  if (notification.assignments.length === 0) return null
  else if (notification.assignments.length > 1) {
    return (
      <>
        <NotificationContainer
          notification={notification}
          icon={<NotificationIcon iconId='course' />}
          title={<NotificationTitle untranslated='You have been unassigned' />}
          content={
            <ContentContainer>
              {notification.assignments.map(({ entity }) => (
                <View key={entity.id}>
                  <EntityImage entity={entity} size='small' />
                  <RouterLink href={detailsUrl(convertLearnEntityToLinkable(entity))}>
                    <Title size='small' bold>
                      {entity.title}
                    </Title>
                  </RouterLink>
                </View>
              ))}
            </ContentContainer>
          }
        />
      </>
    )
  } else {
    const entity = notification.assignments[0]?.entity
    assertIsNonNullable(entity)
    const linkable = convertLearnEntityToLinkable(entity)

    return (
      <NotificationContainer
        notification={notification}
        icon={<NotificationIcon variant='warning' iconId='course' />}
        title={<NotificationTitle untranslated='You have been unassigned' />}
        cardUrl={detailsUrl(linkable)}
        content={
          <>
            <EntityImage entity={entity} />
            <View direction='column' gap='4'>
              <Title size='regular' bold>
                {entity.title}
              </Title>

              <TruncatedText lines={2} size='small' color='foreground/secondary'>
                {entityDescription(entity) ?? ''}
              </TruncatedText>
            </View>
          </>
        }
      />
    )
  }
}
