import { YHistoryEditor, YjsEditor } from '@slate-yjs/core'
import _ from 'lodash'
import { EditableYjsEditor } from 'sierra-client/views/v3-author/collaboration/types'
import { isReadOnlyYjsEditor } from 'sierra-client/views/v3-author/collaboration/with-read-only-yjs-editor'
import { BaseEditor } from 'slate'

function debug(...messages: unknown[]): void {
  return console.debug('[editor/compatibility]', ...messages)
}

export function isEditableYjsEditor(editor: BaseEditor): editor is EditableYjsEditor {
  const isYjsEditor =
    // eslint-disable-next-line @typescript-eslint/no-unnecessary-condition
    typeof _.get(editor, 'disconnect') === 'function' && _.get(editor, 'sharedRoot') !== undefined

  return isYjsEditor && '_isEditableYjsEditor' in editor && editor._isEditableYjsEditor === true
}

/**
 * We need to support a few different types of Slate editors and plugins. Unfortunately, these do not always
 * have a uniform inter}face, so to work around that we use the functions in this file.
 */

/**
 * Perform an `operation` without saving it to the undo/redo stack.
 *
 * Note: This is not yet supported for the YHistory editor.
 */
export function withoutSavingToUndoStack(editor: BaseEditor, operation: () => void): void {
  if (isEditableYjsEditor(editor)) {
    YHistoryEditor.withoutSaving(editor as YHistoryEditor, operation)
  } else {
    debug('[warning] called withoutSaving on an editor that does not support history. This is a no-op.')
  }
}

/**
 * Destroy an instance of the editor.
 *
 * Note: This is a no-op if the editor does not support being destroyed.
 */
export function destroyEditor(editor: BaseEditor): void {
  if (isEditableYjsEditor(editor)) {
    if (!YjsEditor.connected(editor)) return
    debug('Destroying the YjsEditor')
    return YjsEditor.disconnect(editor)
  } else if (isReadOnlyYjsEditor(editor)) {
    if (!editor.connected) return
    debug('Destroying the read-only editor')
    return editor.disconnect()
  } else {
    debug('Attempted to destroy a non-collaborative editor. This is a no-op.')
  }
}

/**
 * Connect the collaborative editor. This is required in newer versions of slate-yjs.
 */
export function connectEditor(editor: BaseEditor): void {
  if (isEditableYjsEditor(editor)) {
    if (YjsEditor.connected(editor)) return
    debug('Connecting the editor')
    return YjsEditor.connect(editor)
  } else if (isReadOnlyYjsEditor(editor)) {
    if (editor.connected) return
    debug('Connecting the read-only editor')
    return editor.connect()
  }
  debug('Connecting the legacy editor (no-op')
}
