import { createFileRoute } from '@tanstack/react-router'
import { Template } from 'sierra-client/features/insights'
import { PageIdentifier } from 'sierra-client/layout/sana-page'
import { useInsightsEnabled } from 'sierra-client/views/manage/hooks/use-insights-enabled'
import { ManagePageWrapper } from 'sierra-client/views/manage/utils/manage-page-wrapper'
import { TemplateId } from 'sierra-domain/api/insights'
import { z } from 'zod'

const DashboardTemplate: React.FC = () => {
  const templateId = Route.useParams({ select: params => params.templateId })
  const insightsEnabled = useInsightsEnabled()

  if (!insightsEnabled) return null

  return (
    <ManagePageWrapper pageIdentifier={PageIdentifier.ManageInsightsDashboardTemplates({ templateId })}>
      <Template id={templateId} />
    </ManagePageWrapper>
  )
}

export const Route = createFileRoute('/manage/insights/dashboards/templates/$templateId/')({
  component: DashboardTemplate as React.FC,
  params: {
    parse: z.object({ templateId: TemplateId }).parse,
    stringify: p => p,
  },
})
