import { motion } from 'framer-motion'
import React, { useEffect, useState } from 'react'
import { getFileContentImage } from 'sierra-client/api/content'
import {
  ConfirmationModalProvider,
  useConfirmationModalContext,
} from 'sierra-client/components/common/modals/confirmation-modal'
import { useIsDebugMode } from 'sierra-client/hooks/use-is-debug-mode'
import { useTranslation } from 'sierra-client/hooks/use-translation'
import { TranslationKey } from 'sierra-client/hooks/use-translation/types'
import { useDebug } from 'sierra-client/lib/use-debug/use-debug'
import { useCachedQuery, useTypedMutation } from 'sierra-client/state/api'
import { useDispatch } from 'sierra-client/state/hooks'
import {
  AiNarrationsDebugControls,
  calculateQuotaExceeded,
  ScriptState,
  VideoCardNarration,
} from 'sierra-client/views/flexible-content/ai-narrations/ai-narrations'
import { generateVideoCardAvatarModalOpened } from 'sierra-client/views/flexible-content/ai-narrations/logger'
import { useAiNarrationDraftState } from 'sierra-client/views/flexible-content/ai-narrations/use-narration-draft-state'
import { estimateCompletionPercentage } from 'sierra-client/views/flexible-content/card-narration/generating-narration-layer'
import { Debug } from 'sierra-client/views/learner/components/debug'
import { DestinationTypeApi, NarrationMetadata, NarrationSettings } from 'sierra-domain/api/author-v2'
import { CreateContentId } from 'sierra-domain/api/nano-id'
import { ScopedCreateContentId } from 'sierra-domain/collaboration/types'
import { CreateOperationState } from 'sierra-domain/editor/operations'
import { File, GeneratedNarrationDraft } from 'sierra-domain/flexible-content/types'
import {
  XRealtimeAuthorCancelGenerateNarrationAvatar,
  XRealtimeAuthorGetNarrationDefaultDraftState,
} from 'sierra-domain/routes'
import { iife } from 'sierra-domain/utils'
import { Icon, Modal } from 'sierra-ui/components'
import { aiGradientLeftToRight } from 'sierra-ui/components/ai-kit/ai-gradient'
import { Button, Spacer, Text, View } from 'sierra-ui/primitives'
import { ButtonShapeStyles } from 'sierra-ui/primitives/button/button'
import { LightTokenProvider } from 'sierra-ui/theming'
import styled, { css } from 'styled-components'

const _AINarrationVideoCard: React.FC<{
  scopedCreateContentId: ScopedCreateContentId
  operationState: CreateOperationState
  narrationSettings: NarrationSettings
  narrationMetadata: NarrationMetadata | undefined
  debugControls: AiNarrationsDebugControls
  errorGeneratingNarration: boolean
  generatedNarrationDraft: GeneratedNarrationDraft
  file: File
  onClose: () => void
  destinationTypeApi: DestinationTypeApi
}> = ({
  scopedCreateContentId,
  operationState,
  narrationSettings,
  narrationMetadata,
  debugControls,
  errorGeneratingNarration,
  generatedNarrationDraft,
  file,
  onClose,
  destinationTypeApi,
}) => {
  const { showDebugData, synthesiaUnavailableOverride } = debugControls
  const isDebugMode = useIsDebugMode()
  const { minutesUsed, quotaExceeded } = calculateQuotaExceeded(debugControls, isDebugMode, narrationSettings)

  const contentId = ScopedCreateContentId.extractId(scopedCreateContentId)

  const { characterLimit, quota, avatars } = narrationSettings

  const { script, setScript, avatarId, setAvatarId } = useAiNarrationDraftState({
    file,
    operationState,
    defaultAvatarId: generatedNarrationDraft.avatarId,
    defaultScript: generatedNarrationDraft.script,
  })

  const scriptState: ScriptState = {
    type: 'plain',
    setScript: setScript,
    script: script,
  }

  return (
    <ConfirmationModalProvider>
      <VideoCardNarration
        contentId={contentId}
        fileId={file.id}
        narrationMetadata={narrationMetadata}
        quotaExceeded={quotaExceeded}
        characterLimit={characterLimit}
        minutesUsed={minutesUsed}
        quota={quota}
        avatars={avatars}
        showDebugData={showDebugData}
        errorGeneratingNarration={errorGeneratingNarration}
        forceShowSynthesiaUnavailable={synthesiaUnavailableOverride === 'unavailable'}
        avatarId={avatarId}
        setAvatarId={setAvatarId}
        scriptState={scriptState}
        onClose={onClose}
        destinationTypeApi={destinationTypeApi}
      />
    </ConfirmationModalProvider>
  )
}

const AINarrationsVideoCard: React.FC<{
  file: File
  scopedCreateContentId: ScopedCreateContentId
  operationState: CreateOperationState
  narrationSettings: NarrationSettings
  narrationMetadata: NarrationMetadata | undefined
  onClose: () => void
  destinationTypeApi: DestinationTypeApi
}> = ({
  file,
  scopedCreateContentId,
  operationState,
  narrationSettings,
  narrationMetadata,
  onClose,
  destinationTypeApi,
}) => {
  const debugControls = useDebug('ai-narrations', {
    quotaExceededOverride: ['no-override', 'exceeded'] as const,
    errorGeneratingNarrationOverride: ['no-override', 'error'] as const,
    synthesiaUnavailableOverride: ['no-override', 'unavailable'] as const,
    showDebugData: false,
    minutesUsedOverride: ['no-override', '5%', '50%', '95%', '100%', '150%'] as const,
  })
  const isDebugMode = useIsDebugMode()

  const errorGeneratingNarration = iife(() => {
    const actualValue = narrationMetadata?.type === 'error'
    if (isDebugMode && debugControls.errorGeneratingNarrationOverride === 'error') {
      return true
    } else {
      return actualValue
    }
  })

  const draftQuery = useCachedQuery(XRealtimeAuthorGetNarrationDefaultDraftState, {
    fileId: file.id,
    contentId: ScopedCreateContentId.extractId(scopedCreateContentId),
    destinationTypeApi: destinationTypeApi,
  })

  const generatedNarrationDraft = draftQuery.data

  if (generatedNarrationDraft === undefined) {
    return <Debug>Narration draft was undefined</Debug>
  }

  return (
    <_AINarrationVideoCard
      generatedNarrationDraft={generatedNarrationDraft}
      onClose={onClose}
      debugControls={debugControls}
      file={file}
      scopedCreateContentId={scopedCreateContentId}
      operationState={operationState}
      narrationSettings={narrationSettings}
      narrationMetadata={narrationMetadata}
      errorGeneratingNarration={errorGeneratingNarration}
      destinationTypeApi={destinationTypeApi}
    />
  )
}

export type VideoState =
  | { type: 'generating'; generatingMetadata: NarrationMetadata & { type: 'loading' } }
  | { type: 'no-video' }
  | { type: 'has-video'; videoUrl: string }

export function getVideoState(
  videoUrl: string | undefined,
  narrationMetadata: NarrationMetadata | undefined
): VideoState {
  if (narrationMetadata?.type === 'loading')
    return { type: 'generating', generatingMetadata: narrationMetadata }
  else if (videoUrl === undefined) return { type: 'no-video' }
  else return { type: 'has-video', videoUrl }
}

export const NarrationModal: React.FC<{
  file: File
  operationState: CreateOperationState
  scopedCreateContentId: ScopedCreateContentId
  narrationSettings: NarrationSettings
  narrationMetadata: NarrationMetadata | undefined
  narrationModalOpen: boolean
  closeNarrationModal: () => void
  destinationTypeApi: DestinationTypeApi
}> = ({
  file,
  operationState,
  scopedCreateContentId,
  narrationSettings,
  narrationMetadata,
  narrationModalOpen,
  closeNarrationModal,
  destinationTypeApi,
}) => (
  <LightTokenProvider>
    <Modal
      open={narrationModalOpen}
      onClose={closeNarrationModal}
      disableScrollbarGutter
      size={{ width: 500 }}
    >
      <AINarrationsVideoCard
        destinationTypeApi={destinationTypeApi}
        file={file}
        scopedCreateContentId={scopedCreateContentId}
        operationState={operationState}
        narrationSettings={narrationSettings}
        narrationMetadata={narrationMetadata}
        onClose={closeNarrationModal}
      />
    </Modal>
  </LightTokenProvider>
)

const AIButton = styled(motion.button).attrs({
  layout: 'position',
  base: '#5B59EA',
  variant: 'primary' as const,
})`
  ${ButtonShapeStyles};
  ${aiGradientLeftToRight};
  transform-origin: center center;

  cursor: 'pointer';
`

export const GenerateNarrationButton: React.FC<{
  setNarrationModalOpen: (open: boolean) => void
  destinationType: 'video-card' | 'video-block'
}> = ({ setNarrationModalOpen, destinationType }) => {
  const { t } = useTranslation()
  const dispatch = useDispatch()

  return (
    <AIButton
      onClick={e => {
        e.stopPropagation()
        e.preventDefault()
        setNarrationModalOpen(true)
        void dispatch(
          generateVideoCardAvatarModalOpened({
            avatarDestinationType: destinationType,
          })
        )
      }}
    >
      <View gap='8'>
        <Icon color='white' iconId='sana-symbol' />
        <Text color='white'>{t('author.video.generate-video')}</Text>
      </View>
    </AIButton>
  )
}

const BlurContainer = styled(View)<{ $blur: number }>`
  flex-direction: column;
  background: rgba(0, 0, 0, 0.25);
  backdrop-filter: blur(${p => p.$blur}px);
  width: 100%;
  height: 100%;
  flex-shrink: 0;
`

const CancelButton = styled(Button).attrs({ variant: 'ghost' })`
  color: white;
  height: auto;
  padding: 8px 12px;
  background: rgba(255, 255, 255, 0.2);
  margin-bottom: 4px;

  &:hover {
    background: rgba(255, 255, 255, 0.1);
  }
`

type GeneratingProps = {
  contentId: CreateContentId
  generatingMetadata: NarrationMetadata & { type: 'loading' }
  canEdit: boolean
}

const Layer = styled(View)<{
  $backgroundImageUrl?: string
}>`
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  border: none;
  overflow: hidden;

  ${p =>
    p.$backgroundImageUrl !== undefined &&
    css`
      background-image: url(${p.$backgroundImageUrl});
      background-size: cover;
      background-position: center center;
    `}
  video {
    max-width: 100%;
  }
`

function blurBasedOnPercentage(percentage: number): number {
  // 0 percent is the blurriest, 100 percent is the least blurry
  // When percentage is 0, blur is 100 and should linear decrease to 20 when percentage is 100
  return 100 - percentage * 0.8
}

function textBasedOnPercentage(percentage: number): TranslationKey {
  if (percentage < 25) {
    return 'author.avatar.generating.hiring-actor'
  } else if (percentage >= 25 && percentage < 45) {
    return 'author.avatar.generating.studio'
  } else if (percentage >= 45 && percentage < 65) {
    return 'author.avatar.generating.adjusting-lights'
  } else if (percentage >= 65 && percentage < 90) {
    return 'author.avatar.generating.recording'
  } else {
    return 'author.avatar.generating.editing'
  }
}

const PercentageText = styled(Text)`
  opacity: 0.6;
`

export const GeneratingNarrationView: React.FC<GeneratingProps> = ({
  generatingMetadata,
  canEdit,
  contentId,
}) => {
  const { t } = useTranslation()
  const { thumbnailUrl, createdAt, estimatedCompletedAt, narrationId } = generatingMetadata
  const confirmationModalContext = useConfirmationModalContext()
  const cancelMutation = useTypedMutation(XRealtimeAuthorCancelGenerateNarrationAvatar, {})
  const avatarThumbnailUrl =
    thumbnailUrl === undefined ? undefined : getFileContentImage(thumbnailUrl, { bucket: 'sierra-static' })

  const [percentage, setPercentage] = useState<number>(
    estimateCompletionPercentage(createdAt, estimatedCompletedAt)
  )
  const [blur, setBlur] = useState(blurBasedOnPercentage(percentage))
  const [loadingText, setTextLoading] = useState(textBasedOnPercentage(percentage))

  useEffect(() => {
    const interval = setInterval(() => {
      setPercentage(estimateCompletionPercentage(createdAt, estimatedCompletedAt))
      setBlur(blurBasedOnPercentage(percentage))
      setTextLoading(textBasedOnPercentage(percentage))
    }, 300)

    return () => clearInterval(interval)
  })

  return (
    <Layer
      background='white'
      alignItems='center'
      justifyContent='center'
      direction='column'
      $backgroundImageUrl={avatarThumbnailUrl}
    >
      <BlurContainer gap='none' justifyContent='center' alignItems='center' $blur={blur}>
        <View gap='4' direction='column' justifyContent='center' alignItems='center'>
          <Text bold size='small' color={'white'} align='center'>
            {t(loadingText)}
          </Text>
          <PercentageText bold color={'white'} align='center'>{`${percentage}%`}</PercentageText>
        </View>
        <Spacer size='24' />
        {canEdit && (
          <CancelButton
            loading={cancelMutation.isPending}
            onClick={() =>
              confirmationModalContext.show({
                bodyText: t('ai-narrations.cancel-warning'),
                confirmLabel: t('ai-narrations.cancel'),
                onConfirm: () => {
                  cancelMutation.mutate({
                    narrationId: narrationId,
                    contentId: contentId,
                    destinationTypeApi: { type: 'video-card' },
                  })
                },
              })
            }
          >
            {t('dictionary.cancel')}
          </CancelButton>
        )}
      </BlurContainer>
    </Layer>
  )
}
