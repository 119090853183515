import * as Tabs from '@radix-ui/react-tabs'
import { DateTime } from 'luxon'
import { SelfPacedPublishState } from 'sierra-client/api/hooks/use-self-paced-publish-state'
import { sidebarButtonCss } from 'sierra-client/components/common/sidebar-button'
import { AssignTab } from 'sierra-client/components/sharing/tabs/assign-tab'
import { CollaborateTab } from 'sierra-client/components/sharing/tabs/collaborate-tab'
import { ExportTab } from 'sierra-client/components/sharing/tabs/export-tab'
import { SharingModalContent } from 'sierra-client/components/sharing/types'
import { useContentKindPermissions } from 'sierra-client/hooks/use-permissions'
import { useTranslation } from 'sierra-client/hooks/use-translation'
import {
  selectCourseKind,
  selectTemplateSettings,
} from 'sierra-client/state/author-course-settings/selectors'
import { selectFlexibleContentLiveSessions } from 'sierra-client/state/flexible-content/selectors'
import { useSelector } from 'sierra-client/state/hooks'
import { useHasManageAccess } from 'sierra-client/views/manage/permissions/use-has-manage-access'
import { ContentPermission } from 'sierra-domain/api/common'
import { LiveContentId } from 'sierra-domain/api/nano-id'
import { LightUser } from 'sierra-domain/user'
import { Icon, Modal } from 'sierra-ui/components'
import { Text, View } from 'sierra-ui/primitives'
import { token } from 'sierra-ui/theming'
import { focusRingInset } from 'sierra-ui/utils'
import styled, { css } from 'styled-components'

const StyledModal = styled(Modal)`
  overflow: hidden;
  position: absolute;
  top: 54px;
  right: 10px;
  min-height: 450px;
  box-shadow: 0 8px 16px 0 #00000014;
  background: ${token('surface/default')};
  border: 1px solid ${token('border/default')};

  /* Set the transform origin for the Modal pop animation. */
  transform-origin: 60% 0;
`

const StyledTabsRoot = styled(Tabs.Root)`
  display: flex;
`

const StyledTabsList = styled(Tabs.List)`
  width: 205px;
  padding: 14px;
  border-right: 1px solid ${token('border/default')};
  display: flex;
  flex-direction: column;
  gap: 0.5rem;
`

const StyledTabsTrigger = styled(Tabs.Trigger)`
  ${sidebarButtonCss}
`

const contentCss = css`
  position: relative;
  width: 618px;
  min-height: 450px;
  height: fit-content;

  &:focus-visible {
    &:before {
      content: '';
      ${focusRingInset}
      position: absolute;
      inset: 0;
    }
  }
`

const StyledTabsContent = styled(Tabs.Content)`
  ${contentCss}
`

const NoTabContent = styled.div`
  ${contentCss}
`

const TabText = styled(Text)``

type SharingModalProps = {
  open: boolean
  onClose: () => void
  content: SharingModalContent
  publishState: SelfPacedPublishState
  permission: ContentPermission
  requestAccessUser: LightUser | undefined
}

const TabsName = {
  collaborate: 'collaborate',
  assignSelfPaced: 'assign-self-paced',
  assignLive: 'assign-live',
  export: 'export',
} as const

type TabsNameValues = (typeof TabsName)[keyof typeof TabsName]

export const SharingModal: React.FC<SharingModalProps> = ({
  open,
  onClose,
  content,
  publishState,
  permission,
  requestAccessUser,
}) => {
  const { t } = useTranslation()

  const hasManageAccess = useHasManageAccess()

  const courseKind = useSelector(selectCourseKind)
  const coursePermissions = useContentKindPermissions(content.id)
  const showCollaborateTab = coursePermissions.has('EDIT_COLLABORATORS')
  const canEditAssignments = coursePermissions.has('EDIT_ASSIGNMENTS')

  const templateSettings = useSelector(selectTemplateSettings)
  const isTemplate = templateSettings !== undefined

  const allLiveSessions = useSelector(state =>
    selectFlexibleContentLiveSessions(state, LiveContentId.parse(content.id))
  )
  const futureSessions = allLiveSessions.filter(
    session => session.data.type === 'scheduled' && DateTime.fromISO(session.data.endTime) > DateTime.now()
  )

  const hideAssignLiveForCommentersIfNoFutureLiveSessions =
    permission === 'comment' && futureSessions.length === 0

  const hideAssignSelfPacedForCommenterIfUnpublished =
    permission === 'comment' && publishState.state?.published === false

  const showAssignLiveTab = !hideAssignLiveForCommentersIfNoFutureLiveSessions && courseKind === 'native:live'

  const showAssignSelfPacedTab =
    !hideAssignSelfPacedForCommenterIfUnpublished && courseKind === 'native:self-paced'

  const selectedTab = ((): TabsNameValues => {
    if (showCollaborateTab) return TabsName.collaborate
    if (showAssignSelfPacedTab) return TabsName.assignSelfPaced
    if (showAssignLiveTab) return TabsName.assignLive
    return TabsName.export
  })()

  if (!hasManageAccess || isTemplate) {
    return (
      <StyledModal overlayVariant='dark' animation='pop' size='fit-content' open={open} onClose={onClose}>
        <NoTabContent>
          <CollaborateTab content={content} onClose={onClose} requestAccessUser={requestAccessUser} />
        </NoTabContent>
      </StyledModal>
    )
  }

  return (
    <StyledModal overlayVariant='light' animation='pop' size='fit-content' open={open} onClose={onClose}>
      <StyledTabsRoot defaultValue={selectedTab} orientation='vertical' activationMode='manual'>
        <StyledTabsList>
          {showCollaborateTab && (
            <StyledTabsTrigger value={TabsName.collaborate}>
              <View>
                <Icon color='foreground/primary' iconId='face--add' />
                <TabText size='small'>{t('dictionary.collaborate')}</TabText>
              </View>
            </StyledTabsTrigger>
          )}

          {showAssignSelfPacedTab && (
            <StyledTabsTrigger value={TabsName.assignSelfPaced}>
              <View>
                <Icon color='foreground/primary' iconId='education' />
                <TabText size='small'>{t('dictionary.assign')}</TabText>
              </View>
            </StyledTabsTrigger>
          )}

          {showAssignLiveTab && (
            <StyledTabsTrigger value={TabsName.assignLive}>
              <View>
                <Icon color='foreground/primary' iconId='education' />
                <TabText size='small'>{t('dictionary.assign')}</TabText>
              </View>
            </StyledTabsTrigger>
          )}

          <StyledTabsTrigger value={TabsName.export}>
            <View>
              <Icon color='foreground/primary' iconId='download' />
              <TabText size='small'>{t('dictionary.export')}</TabText>
            </View>
          </StyledTabsTrigger>
        </StyledTabsList>

        {showCollaborateTab && (
          <StyledTabsContent value={TabsName.collaborate}>
            <CollaborateTab content={content} onClose={onClose} requestAccessUser={requestAccessUser} />
          </StyledTabsContent>
        )}

        <StyledTabsContent value={showAssignSelfPacedTab ? TabsName.assignSelfPaced : TabsName.assignLive}>
          <AssignTab
            content={content}
            publishState={publishState}
            onClose={onClose}
            canEditAssignments={canEditAssignments}
          />
        </StyledTabsContent>

        <StyledTabsContent value={TabsName.export}>
          <ExportTab content={content} onClose={onClose} />
        </StyledTabsContent>
      </StyledTabsRoot>
    </StyledModal>
  )
}
