import { postWithUserErrorException } from 'sierra-client/state/api'
import { createRootStateAsyncThunk } from 'sierra-client/state/create-root-state-async-thunk'
import { PathId } from 'sierra-domain/api/nano-id'
import { XRealtimeStrategyPathDetail } from 'sierra-domain/routes'

export const fetchPathById = createRootStateAsyncThunk(
  'paths/fetch-path-by-id',
  async ({ pathId }: { pathId: PathId }, { dispatch }) => {
    const response = await postWithUserErrorException(XRealtimeStrategyPathDetail, { pathId }, dispatch)

    return {
      id: pathId,
      ...response,
    }
  }
)
