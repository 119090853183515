import {
  useCreatePageContext,
  useEditOrResponsesStateSafe,
} from 'sierra-client/views/flexible-content/create-page-context'
import {
  CreateDropAWordDataProvider,
  LiveDropAWordDataProvider,
  SelfPacedDropAWordDataProvider,
  useDropAWordData,
} from 'sierra-client/views/v3-author/drop-a-word/drop-a-word-data-layer'
import { DropAWord } from 'sierra-client/views/v3-author/drop-a-word/renderer/drop-a-word'
import { ContentPermission } from 'sierra-domain/api/common'
import { CreateContentId } from 'sierra-domain/api/nano-id'
import { apply } from 'sierra-domain/editor/operations'
import { FileId } from 'sierra-domain/flexible-content/identifiers'
import { DropAWordData, File } from 'sierra-domain/flexible-content/types'
import { assert } from 'sierra-domain/utils'

const _CreateDropAWordCard: React.FC<{
  readOnly: boolean
  file: File & { data: DropAWordData }
  fileId: FileId
}> = ({ readOnly, file, fileId }) => {
  const { operationState } = useCreatePageContext()
  const data = useDropAWordData()

  const onTitleChanged = readOnly
    ? undefined
    : (title: string): void => {
        apply(operationState, {
          type: 'update-files',
          fileIds: [fileId],
          update: file => {
            assert(file.data.type === 'drop-a-word')
            file.data.title = title
          },
        })
      }

  return <DropAWord data={data} title={file.data.title} onTitleChanged={onTitleChanged} />
}

export const CreateDropAWordCard: React.FC<{
  readOnly: boolean
  file: File & { data: DropAWordData }
  fileId: FileId
  createContentId: CreateContentId
  permission: ContentPermission
}> = ({ createContentId, permission, ...props }) => {
  const editOrResponsesState = useEditOrResponsesStateSafe()

  if (editOrResponsesState === undefined || editOrResponsesState.type === 'edit') {
    return (
      <CreateDropAWordDataProvider>
        <_CreateDropAWordCard {...props} />
      </CreateDropAWordDataProvider>
    )
  } else if (editOrResponsesState.liveSessionId !== undefined) {
    return (
      <LiveDropAWordDataProvider
        contentId={createContentId}
        isInteractive={false}
        file={props.file}
        liveSessionId={editOrResponsesState.liveSessionId}
      >
        <_CreateDropAWordCard {...props} />
      </LiveDropAWordDataProvider>
    )
  } else {
    return (
      <SelfPacedDropAWordDataProvider
        contentId={createContentId}
        fileId={props.fileId}
        isInteractive={false}
        isCollaborator={permission === 'edit'}
      >
        <_CreateDropAWordCard {...props} />
      </SelfPacedDropAWordDataProvider>
    )
  }
}
