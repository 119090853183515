import { editorGrid, editorGridItem } from 'sierra-client/editor/layout'
import { FCC } from 'sierra-client/types'
import { QuestionCardMultipleChoiceAlternativeContainer } from 'sierra-client/views/v3-author/question-card/multiple-choice-alternative/multiple-choice-alternative'
import { QuestionCardMultipleChoiceAlternativeOptionWrapper } from 'sierra-client/views/v3-author/question-card/multiple-choice-alternative/multiple-choice-alternative-option'
import { AlternativeInput } from 'sierra-client/views/v3-author/question-card/multiple-choice-alternative/shared'
import {
  DropdownContainer,
  MarginContainer,
} from 'sierra-client/views/v3-author/question-card/question-card-body-wrapper'
import { RadioCheckbox } from 'sierra-client/views/v3-author/question-card/radiocheckbox'
import { RenderingContext } from 'sierra-client/views/v3-author/rendering-context'
import { GridWrapper } from 'sierra-client/views/v3-author/wrapper'
import { color } from 'sierra-ui/color'
import { Skeleton, Spacer, View } from 'sierra-ui/primitives'
import styled, { css } from 'styled-components'

export const StyledSkeletonBlockWrapper = styled(GridWrapper)<{ $selected: boolean }>`
  position: relative;
  padding: 2rem 0;
  width: 100%;

  &:before {
    content: '';
    position: absolute;
    inset: 0;
    z-index: -1;
    border-radius: ${p => p.theme.borderRadius['size-6']};
    ${editorGridItem};
    transition: background-color 0.1s;

    ${p =>
      p.$selected &&
      css`
        box-shadow: 0 0 0 3px #b4d5ff;
        background-color: ${p => color(p.theme.home.textColor).opacity(0.05).toString()};
      `}
  }

  &:hover {
    &:before {
      background-color: ${p => color(p.theme.home.textColor).opacity(0.05).toString()};
    }
  }
`
export const QuestionSkeletonInner = styled.div`
  position: relative;
  color: ${p => p.theme.home.textColor};

  & span[data-slate-placeholder='true'] {
    white-space: nowrap;
  }

  ${editorGrid}
`
export const SkeletonQuestionCardContainer: FCC<{ selected: boolean }> = ({ children, selected }) => {
  return (
    <StyledSkeletonBlockWrapper $selected={selected}>
      <RenderingContext withGrid={false}>
        <QuestionSkeletonInner contentEditable={false}>{children}</QuestionSkeletonInner>
      </RenderingContext>
    </StyledSkeletonBlockWrapper>
  )
}

export const SkeletonQuestionBody: FCC = ({ children }) => (
  <MarginContainer contentEditable={false}>
    <DropdownContainer contentEditable={false}>
      <Skeleton $height={36} $width={200} $radius={8} />
    </DropdownContainer>
    <View gap='4' direction='column'>
      {children}
    </View>
  </MarginContainer>
)
export const SkeletonQuestionAlternative: () => JSX.Element = () => (
  <QuestionCardMultipleChoiceAlternativeContainer as={Skeleton}>
    <RadioCheckbox checked={false} radio={false} />
    <QuestionCardMultipleChoiceAlternativeOptionWrapper>
      <AlternativeInput readOnly>
        <Spacer size={'16'} />
      </AlternativeInput>
    </QuestionCardMultipleChoiceAlternativeOptionWrapper>
  </QuestionCardMultipleChoiceAlternativeContainer>
)
