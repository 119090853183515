import { motion } from 'framer-motion'
import _ from 'lodash'
import { DateTime, Duration } from 'luxon'
import React, { useCallback, useEffect, useMemo, useState } from 'react'
import { useCurrentUserId } from 'sierra-client/api/hooks/use-user'
import { HorizontalLineDivider } from 'sierra-client/components/common/horizontal-line-divider'
import { useLiveSessionContext } from 'sierra-client/components/liveV2/contexts/live-session-data'
import { useIsFacilitatorOrLearnerLedSession } from 'sierra-client/components/liveV2/hooks/use-is-facilitator-or-learner-led-session'
import { TimerButton, TimerText, secondsToDuration } from 'sierra-client/components/liveV2/timer-button'
import { AppThemeTokenProvider } from 'sierra-client/config/token-provider'
import { useTranslation } from 'sierra-client/hooks/use-translation'
import { getServerTimeNow, selectClock } from 'sierra-client/state/collaboration/selectors'
import { useSelector } from 'sierra-client/state/hooks'
import { AddQuestion } from 'sierra-client/views/v3-author/assessment-card/add-question'
import {
  AssessmentContainer,
  AssessmentHeadline,
  AssessmentIconBar,
  assessmentContainerRadius,
} from 'sierra-client/views/v3-author/assessment-card/assessment-container'
import {
  Assessments,
  useAssessmentContext,
} from 'sierra-client/views/v3-author/assessment-card/assessment-context'
import { removeNodeWithId } from 'sierra-client/views/v3-author/command'
import { useChildren, useParent, usePath } from 'sierra-client/views/v3-author/hooks'
import { Leaderboard } from 'sierra-client/views/v3-author/live-quiz/leaderboard'
import { useLiveQuizContext } from 'sierra-client/views/v3-author/live-quiz/live-quiz-context'
import { ProgressBar } from 'sierra-client/views/v3-author/live-quiz/progress-bar'
import { assertElementType } from 'sierra-client/views/v3-author/queries'
import { QuestionCardIconBar } from 'sierra-client/views/v3-author/question-card/question-card-icon-bar'
import { RenderingContext } from 'sierra-client/views/v3-author/rendering-context'
import { SlateFCWrapper, SlateWrapperProps } from 'sierra-client/views/v3-author/slate'
import { assertNever, iife } from 'sierra-domain/utils'
import { createAssessmentQuestion } from 'sierra-domain/v3-author/create-blocks'
import { Icon } from 'sierra-ui/components'
import { Button, IconButton, View } from 'sierra-ui/primitives'
import { token } from 'sierra-ui/theming'
import { ConditionalWrapper } from 'sierra-ui/utils'
import { Editor, Element, Path, Transforms } from 'slate'
import { useSlateStatic } from 'slate-react'
import styled, { css } from 'styled-components'

const FadeInWrapper = styled.div<{ show: boolean }>`
  transition: opacity 200ms;
  opacity: 0;
  ${p =>
    p.show &&
    css`
      opacity: 1;
    `}
`

export const AssessmentTimer = (): JSX.Element | null => {
  const { state, setState } = useAssessmentContext()
  const endTime = state.status === 'during' ? state.endTime : undefined
  const clock = useSelector(selectClock)
  const [countDownTimeRemaining, setCountDownTimeRemaining] = useState<Duration | undefined>()

  useEffect(() => {
    let interval: ReturnType<typeof setInterval>
    if (endTime === undefined) {
      setCountDownTimeRemaining(undefined)
    } else {
      interval = setInterval(async () => {
        const now = DateTime.fromISO(getServerTimeNow(clock))
        const diff = endTime.diff(now)
        if (diff.as('seconds') < 0) {
          setCountDownTimeRemaining(Duration.fromMillis(0))
          clearInterval(interval)
          const nextState = await Assessments.timesUp(state)
          setState(nextState)
        } else {
          setCountDownTimeRemaining(diff)
        }
      }, 200)
    }

    return () => {
      clearInterval(interval)
    }
  }, [clock, endTime, setState, state])

  const milliseconds = countDownTimeRemaining?.milliseconds
  const timeString = secondsToDuration((milliseconds ?? 0) / 1000)
  const isAlmostDone = milliseconds !== undefined ? milliseconds < 30 * 1000 : false

  return (
    <FadeInWrapper show={milliseconds !== undefined}>
      <TimerButton
        id='timer'
        $clickable={false}
        $timerState={isAlmostDone ? 'done' : 'normal'}
        initial='initial'
        animate={'initial'}
      >
        <ConditionalWrapper
          renderWrapper={children => <AppThemeTokenProvider>{children}</AppThemeTokenProvider>}
          condition={isAlmostDone}
        >
          <>
            <Icon iconId='timer' color='foreground/primary' />
            <TimerText size='small'>
              <div>{timeString}</div>
            </TimerText>
          </>
        </ConditionalWrapper>
      </TimerButton>
    </FadeInWrapper>
  )
}

const AssessmentQuestionCreateWrapper = React.forwardRef<HTMLDivElement, SlateWrapperProps>((props, ref) => {
  const { children, element, attributes, readOnly } = props
  assertElementType('assessment-question', element)

  const assessmentCard = useParent({ nodeId: element.id })
  const questionCount = Element.isElement(assessmentCard) && assessmentCard.children.length - 1 // introduction
  const { t } = useTranslation()

  const currentPath = usePath({ nodeId: element.id })
  const questionIndex = _.last(currentPath)

  const isFirstQuestion = questionIndex === 1 // 0 is introduction
  const isLastQuestion = questionIndex === questionCount

  const editor = useSlateStatic()
  const addQuestion = useCallback(() => {
    if (readOnly) return

    Transforms.insertNodes(editor, createAssessmentQuestion(), { at: Path.next(currentPath) })
    Transforms.select(editor, Editor.start(editor, Path.next(currentPath)))
  }, [editor, currentPath, readOnly])

  return (
    <>
      <AssessmentContainer {...attributes} ref={ref} radius={assessmentContainerRadius}>
        <QuestionCardIconBar justifyContent='space-between'>
          <AssessmentHeadline
            iconId='help'
            label={t('assessment-card.question-number', { number: questionIndex ?? 0 })}
          />
          {!readOnly && (
            <AssessmentIconBar>
              {questionCount !== false && questionCount > 1 && (
                <>
                  <IconButton
                    size='small'
                    variant='transparent'
                    iconId='arrow--up'
                    disabled={isFirstQuestion}
                    onClick={() =>
                      Transforms.moveNodes(editor, { at: currentPath, to: Path.previous(currentPath) })
                    }
                  />
                  <IconButton
                    size='small'
                    variant='transparent'
                    iconId='arrow--down'
                    disabled={isLastQuestion}
                    onClick={() =>
                      Transforms.moveNodes(editor, { at: currentPath, to: Path.next(currentPath) })
                    }
                  />
                  {(!isFirstQuestion || !isLastQuestion) && <HorizontalLineDivider />}
                </>
              )}
              <IconButton
                size='small'
                variant='transparent'
                iconId='trash-can'
                disabled={questionCount !== false && questionCount < 2}
                onClick={() => removeNodeWithId(editor, element.id)}
              />
            </AssessmentIconBar>
          )}
        </QuestionCardIconBar>
        {children}
      </AssessmentContainer>
      <AddQuestion onClick={addQuestion} hide={readOnly} isGrey={isLastQuestion} />
    </>
  )
})

const AssessmentQuestionLearnerWrapper = React.forwardRef<HTMLDivElement, SlateWrapperProps>((props, ref) => {
  const { children, element, attributes } = props
  assertElementType('assessment-question', element)

  const { state } = useAssessmentContext()
  const currentPath = usePath({ nodeId: element.id })
  const questionIndex = _.last(currentPath)

  const shouldShowQuestion = useMemo((): boolean => {
    if (state.status === 'during') {
      const { currentIndex } = state
      return currentIndex + 1 === questionIndex
    }

    return false
  }, [state, questionIndex])

  return (
    <AssessmentContainer {...attributes} ref={ref} radius='none' $hide={!shouldShowQuestion}>
      {shouldShowQuestion && state.status === 'during' ? (
        <>
          {/* <QuestionCardIconBar justifyContent='center'>
            <ProgressBar
              assessmentState={state}
              totalQuestions={questionCount}
              next={async () => {
                const newState = await Assessments.next(state)
                setState(newState)
              }}
              previous={async () => {
                const newState = await Assessments.previous(state)
                setState(newState)
              }}
            />

            {state.endTime !== undefined && (
              <View marginTop={'8'}>
                <AssessmentTimer />
              </View>
            )}
          </QuestionCardIconBar> */}
          {children}
        </>
      ) : null}
    </AssessmentContainer>
  )
})

const Centred = styled(View).attrs({
  direction: 'column',
  justifyContent: 'center',
  alignItems: 'center',
  grow: true,
})``

const calculatePoints = (startedAt: number, respondedAt: number): number => {
  const questionDuration = 30_000
  const delta = Duration.fromMillis(respondedAt - startedAt).as('milliseconds')
  const points = Math.floor(1000 - delta * (1000 / questionDuration))
  return Math.max(points, 0)
}

const useGetQuestionId = (elementId: string): string => {
  const questionBodies =
    useChildren({
      parentId: elementId,
      types: ['question-card-pick-the-best-option-body'],
    }) ?? []

  if (questionBodies.length === 0) {
    throw new Error('Could not find question body in live quiz.')
  }
  const questionId = questionBodies[0]?.id

  if (questionId === undefined) {
    throw new Error('Could not find question body in live quiz.')
  }

  return questionId
}

const LiveQuizQuestionWrapperQuestion: SlateFCWrapper = ({ children, element }) => {
  const { status, setStatus, questionCount } = useLiveQuizContext()

  const questionId = useGetQuestionId(element.id)

  const isFacilitator = useIsFacilitatorOrLearnerLedSession()

  if (status.step !== 'question') {
    return null
  }

  const next = (): void => {
    if (status.currentIndex === questionCount) {
      setStatus({ step: 'finished', lastQuestionId: questionId })
    } else {
      setStatus({ step: 'leaderboard', currentIndex: status.currentIndex })
    }
  }

  const startPoints = calculatePoints(status.startedAt, new Date().getTime())

  return (
    <Centred>
      <View direction='column' alignItems='center'>
        <div style={{ width: '100%', paddingLeft: '32px', marginBottom: '-32px' }}>
          <ProgressBar startPoints={startPoints} />
        </div>

        {children}
        {isFacilitator && (
          <Button variant={'ghost'} onClick={next}>
            Continue
          </Button>
        )}
      </View>
    </Centred>
  )
}

const Container = styled.div`
  position: fixed;
  display: flex;
  inset: 0;
  overflow: hidden;
  justify-content: center;
  text-align: center;
`

const Counter = styled(motion.div)`
  font-weight: 500;
  font-size: 432px;
  color: ${token('foreground/primary')};

  display: flex;
  justify-content: center;
  align-items: center;
  text-align: center;
  width: 100%;
  height: 100%;
`

const LiveQuizQuestionWrapperCountdown: SlateFCWrapper = ({ element }) => {
  const { status, setStatus, startQuestion, fileId } = useLiveQuizContext()
  const { liveSessionId } = useLiveSessionContext()
  const questionId = useGetQuestionId(element.id)
  const [count, setCount] = useState(4)
  const isFacilitator = useIsFacilitatorOrLearnerLedSession()
  const currentUserId = useCurrentUserId()

  const next = useCallback(async (): Promise<void> => {
    if (status.step !== 'countdown') {
      return
    }
    if (currentUserId.data === undefined) {
      return
    }
    const startedAt = await startQuestion({ questionId, liveSessionId, fileId })
    setStatus({
      step: 'question',
      currentIndex: status.currentIndex,
      startedAt,
      hostUserId: currentUserId.data,
    })
  }, [status, questionId, liveSessionId, fileId, setStatus, startQuestion, currentUserId.data])

  useEffect(() => {
    if (status.step !== 'countdown') {
      return
    }
    if (currentUserId.data === undefined) {
      return
    }

    if (count > 0) {
      const nextTimeout = setTimeout(() => {
        setCount(prevCount => prevCount - 1)
      }, 1000)

      return () => {
        clearTimeout(nextTimeout)
      }
    } else {
      if (status.hostUserId === currentUserId.data) {
        void next()
      }
    }
  }, [count, isFacilitator, next, status, currentUserId])

  if (status.step !== 'countdown') {
    return null
  }

  return (
    <Container>
      {count > 0 ? (
        <Counter
          initial={{ scale: 2 }}
          animate={{
            scale: [2, 0.4, 2, 0.4, 2, 0.4, 0.4, 5],
          }}
          transition={{ duration: 4, times: [0, 0.25, 0.25, 0.5, 0.5, 0.75, 0.75, 1] }}
        >
          {count > 1 ? count - 1 : 'Go!'}
        </Counter>
      ) : null}
    </Container>
  )
}

const LiveQuizQuestionWrapperLeaderboard: SlateFCWrapper = ({ element }) => {
  const { status, setStatus, leaderboard, refreshLeaderboardAfterQuestion, fileId } = useLiveQuizContext()
  const questionId = useGetQuestionId(element.id)
  const { liveSessionId } = useLiveSessionContext()
  const [fetchedLeaderboard, setFetchedLeaderboard] = useState<boolean>(false)
  const [isFetching, setIsFetching] = useState<boolean>(false)
  const currentUserId = useCurrentUserId()

  useEffect(() => {
    if (!isFetching) {
      setIsFetching(true)
      void refreshLeaderboardAfterQuestion({ questionId, liveSessionId, fileId }).then(() => {
        setFetchedLeaderboard(true)
      })
    }
  }, [questionId, liveSessionId, fileId, refreshLeaderboardAfterQuestion, isFetching])

  const isFacilitator = useIsFacilitatorOrLearnerLedSession()

  if (status.step !== 'leaderboard') {
    return null
  }
  if (currentUserId.data === undefined) {
    return null
  }

  const next = (): void => {
    setStatus({ step: 'countdown', currentIndex: status.currentIndex + 1, hostUserId: currentUserId.data })
  }

  return (
    <Centred gap='48'>
      {fetchedLeaderboard && leaderboard !== undefined && (
        <Leaderboard key={questionId} leaderboard={leaderboard} />
      )}
      {isFacilitator && (
        <Button variant={'ghost'} onClick={next}>
          Next question
        </Button>
      )}
    </Centred>
  )
}

const LiveQuizOuter = styled.div<{ $show: boolean }>`
  ${p =>
    p.$show
      ? css`
          display: flex;
          flex-direction: column;
          align-items: center;
          justify-content: center;
          flex: 1;
        `
      : css`
          display: none;
        `}
`

const LiveQuizQuestionWrapper = React.forwardRef<HTMLDivElement, SlateWrapperProps>((props, ref) => {
  const { element } = props

  const { status } = useLiveQuizContext()

  const currentPath = usePath({ nodeId: element.id })
  const questionIndex = _.last(currentPath)

  const shouldShow = iife(() => {
    switch (status.step) {
      case 'countdown':
      case 'question':
      case 'leaderboard':
        return status.currentIndex === questionIndex
      default:
        return false
    }
  })

  return (
    <LiveQuizOuter $show={shouldShow} ref={ref}>
      {iife(() => {
        if (status.step === 'countdown' && questionIndex === status.currentIndex) {
          return <LiveQuizQuestionWrapperCountdown {...props} />
        }

        if (status.step === 'question' && questionIndex === status.currentIndex) {
          return <LiveQuizQuestionWrapperQuestion {...props} />
        }

        if (status.step === 'leaderboard' && questionIndex === status.currentIndex) {
          return <LiveQuizQuestionWrapperLeaderboard {...props} />
        }

        return null
      })}
    </LiveQuizOuter>
  )
})

export const AssessmentQuestionWrapper = React.forwardRef<HTMLDivElement, SlateWrapperProps>((props, ref) => {
  const { children, element, mode } = props
  assertElementType('assessment-question', element)

  const Renderer = iife(() => {
    switch (mode) {
      case 'create':
      case 'template':
      case 'version-history':
        return AssessmentQuestionCreateWrapper
      case 'self-paced':
      case 'review':
      case 'placement-test':
      case 'recap':
        return AssessmentQuestionLearnerWrapper
      case 'live':
        return LiveQuizQuestionWrapper
      default:
        assertNever(mode)
    }
  })

  return (
    <RenderingContext preventDrag={true} allowBlockComments={true} disableMenu={true}>
      <Renderer {...props} ref={ref}>
        {children}
      </Renderer>
    </RenderingContext>
  )
})
