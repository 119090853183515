import _ from 'lodash'
import React, { useState } from 'react'
import { useTranslation } from 'sierra-client/hooks/use-translation'
import { CellSingleSelect } from 'sierra-client/lib/tabular/datatype/internal/cell-with-data'
import { labelToString } from 'sierra-client/lib/tabular/datatype/label'
import { isDefined, isNonEmptyString } from 'sierra-domain/utils'
import { LabelMenuItem, MenuItem } from 'sierra-ui/components'
import { View } from 'sierra-ui/primitives'
import { SingleSelectDropdown } from 'sierra-ui/primitives/menu-dropdown'
import styled from 'styled-components'

const Wrapper = styled(View)`
  width: 136px;
`

export const SingleSelectCell: React.FC<{
  cell: CellSingleSelect
}> = ({ cell }) => {
  const { t } = useTranslation()
  const selectedMenuItem: LabelMenuItem | undefined = isNonEmptyString(cell.data.selected)
    ? {
        type: 'label',
        id: '0',
        label: _.capitalize(cell.data.selected),
      }
    : undefined

  const menuItems: MenuItem[] = cell.data.items.map(item => ({
    type: 'label',
    id: item.id,
    label: _.capitalize(item.label),
  }))

  const dropDownItems = isDefined(cell.data.label)
    ? [
        {
          type: 'group',
          label: labelToString(cell.data.label, t),
          id: `group-${labelToString(cell.data.label, t)}`,
          menuItems: menuItems,
        } satisfies MenuItem,
      ]
    : menuItems

  const [selectedItem, setSelectedItem] = useState<MenuItem | undefined>(selectedMenuItem)

  const onSelect = (item: MenuItem): void => {
    setSelectedItem(item)
    cell.data.onSelect(item, cell.pos.row)
  }

  return (
    <Wrapper>
      <SingleSelectDropdown
        placeholder={t('dictionary.select')}
        selectedItem={selectedItem}
        onSelect={onSelect}
        menuItems={dropDownItems}
      />
    </Wrapper>
  )
}
