import { ProgramInfo, ProgramInfoStepType } from 'sierra-client/views/learner/program/utils/step-graphql'
import { isDefined, isNotDefined } from 'sierra-domain/utils'

type StepsCluster = Array<ProgramInfoStepType>

export const clusterSectionsLearner = (outline: ProgramInfo): StepsCluster[] => {
  return outline.steps
    .filter(step => !(step.__typename === 'EmailProgramStep'))
    .reduce((clusters, step) => {
      const previousCluster = clusters[clusters.length - 1]

      // There's no cluster at all at this point. Return a new cluster.
      if (isNotDefined(previousCluster)) {
        return [[step]]
      }

      const lastItem = previousCluster[previousCluster.length - 1]
      const matches = isDefined(lastItem) && lastItem.sectionIndex === step.sectionIndex

      if (matches) {
        previousCluster.push(step)
        clusters.splice(-1, 1, previousCluster)

        return clusters
      } else {
        // Add the current item to a new cluster, and append it to the existing list
        return [...clusters, [step]]
      }
    }, [] as StepsCluster[])
}
