import { produce } from 'immer'
import { useAtom } from 'jotai'
import { multiSelectionAtom } from 'sierra-client/views/flexible-content/editor/content-sidebar/multi-selection/atoms'
import { UseMultiSelection } from 'sierra-client/views/flexible-content/editor/content-sidebar/multi-selection/types'
import { FileId } from 'sierra-domain/flexible-content/identifiers'

export const useMultiSelection = (): UseMultiSelection => {
  const [selection, setSelection] = useAtom(multiSelectionAtom)

  const addSelection: UseMultiSelection['addSelection'] = items => {
    const produced = produce(selection, draft => {
      for (const { fileId, info } of items) {
        draft[fileId] = info
      }
    })

    setSelection(produced)
  }

  const removeSelection: UseMultiSelection['removeSelection'] = fileId => {
    setSelection(
      produce(selection, draft => {
        delete draft[fileId]
      })
    )
  }

  const containsSelection: UseMultiSelection['containsSelection'] = fileId =>
    Object.keys(selection).includes(fileId)

  const clearSelection: UseMultiSelection['clearSelection'] = () => {
    setSelection({})
  }

  const getSelection: UseMultiSelection['getSelection'] = fileId => selection[fileId]

  const selectionCount = Object.keys(selection).length

  const areMultipleSelected = selectionCount > 1

  const selectedFileIds = Object.keys(selection) as [FileId, ...FileId[]] // This always has at least the current card as a selection

  return {
    selection,
    selectedFileIds,
    addSelection,
    removeSelection,
    containsSelection,
    clearSelection,
    getSelection,
    areMultipleSelected,
    selectionCount,
  }
}
