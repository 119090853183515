import { createSlice } from '@reduxjs/toolkit'
import {
  addNonExpiringNotification,
  addNotification,
  removeNotification,
  removeNotifications,
  reportRejectedAction,
  reportUserError,
} from 'sierra-client/state/notifications/actions'
import { NotificationsState } from 'sierra-client/state/notifications/types'

const initialState: NotificationsState = {
  notifications: [],
  errors: [],
  isSanaUpdated: false,
}

export const notificationsSlice = createSlice({
  name: 'notifications',
  initialState,
  reducers: {},
  extraReducers: builder => {
    builder.addCase(reportUserError, (state, { payload }) => {
      state.errors.push({
        type: 'user-error',
        code: payload.code,
      })
    })
    builder.addCase(reportRejectedAction.fulfilled, (state, action) => {
      state.errors.push({
        ...action,
        type: 'redux-error',
      })
    })
    builder.addCase(addNotification, (state, { payload }) => {
      state.notifications = state.notifications.filter(v => v.type !== payload.type)
      state.notifications.push(payload)
    })
    builder.addCase(addNonExpiringNotification, (state, { payload }) => {
      state.notifications = state.notifications.filter(v => v.type !== payload.type)
      state.notifications.push(payload)
    })
    builder.addCase(removeNotification, (state, { payload }) => {
      state.notifications = state.notifications.filter(v => v.id !== payload.id)
    })
    builder.addCase(removeNotifications, (state, { payload }) => {
      state.notifications = state.notifications.filter(v => !payload.ids.includes(v.id))
    })
  },
})
