import { useMutation, type UseMutationResult, type UseQueryResult } from '@tanstack/react-query'
import { graphql } from 'sierra-client/api/graphql/gql'
import {
  type AssignSkillLevelsToContentMutation,
  type AssignSkillLevelsToContentMutationVariables,
} from 'sierra-client/api/graphql/gql/graphql'
import { graphQuery, useGraphQuery, useInvalidateGraphQuery } from 'sierra-client/api/hooks/use-graphql-query'
import { BadgeIconId, BadgeTheme } from 'sierra-client/features/skills/components/badges/types'
import type { ContentSkill } from 'sierra-client/features/skills/components/content-skill/types'
import type { InvalidateQuery } from 'sierra-client/state/api'
import { isDefined } from 'sierra-domain/utils'

const skillsAssignedToContent = graphql(`
  query SkillsAssignedToContent($ids: [InputContentId!]!) {
    content: contentWithIds(ids: $ids) {
      contentId
      assignedSkills {
        skill {
          ...ContentSkillDetails
        }
        assignedSkillLevel {
          levelSetting {
            id
            defaultBadgeTheme
            name
          }
        }
      }
    }
  }
`)

export const useSkillsAssignedToContent = (contentId: string): UseQueryResult<ContentSkill[], unknown> => {
  return useGraphQuery(
    {
      document: skillsAssignedToContent,
      queryOptions: {
        select(data) {
          const content = data.content.find(it => it.contentId === contentId)

          if (isDefined(content)) {
            return content.assignedSkills.map(
              ({ skill: skillFragmentValue, assignedSkillLevel }): ContentSkill => {
                const skill = skillFragmentValue

                return {
                  id: skill.id,
                  name: skill.name,
                  iconId: BadgeIconId.catch('locked').parse(skill.defaultBadgeIcon),
                  theme: BadgeTheme.catch('disabled').parse(
                    assignedSkillLevel.levelSetting.defaultBadgeTheme
                  ),
                  levelSettingId: assignedSkillLevel.levelSetting.id,
                  levels: skill.skillLevels,
                }
              }
            )
          } else {
            return []
          }
        },
      },
    },
    { ids: [contentId] }
  )
}

export const useInvalidateSkillAssignedToContent = (contentId: string): InvalidateQuery => {
  return useInvalidateGraphQuery(skillsAssignedToContent, { ids: [contentId] })
}

const assignSkillLevelsToContent = graphql(`
  mutation AssignSkillLevelsToContent($contentId: InputContentId!, $skills: [SkillToAssignInput!]!) {
    assignSkillLevelsToContent(contentId: $contentId, skills: $skills)
  }
`)

export const useAssignSkillsToContentMutation = (
  contentId: string
): UseMutationResult<
  AssignSkillLevelsToContentMutation,
  unknown,
  AssignSkillLevelsToContentMutationVariables,
  unknown
> => {
  const invalidateSkillsAssignedToContentQuery = useInvalidateSkillAssignedToContent(contentId)

  return useMutation({
    mutationFn: vars => graphQuery(assignSkillLevelsToContent, vars),
    onSettled() {
      void invalidateSkillsAssignedToContentQuery()
    },
  })
}
