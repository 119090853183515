import _ from 'lodash'
import { getCurrentNodeEmpty, getParent } from 'sierra-client/views/v3-author/queries'
import { Editor, Element, Node, Range, Transforms } from 'slate'

function debug(...messages: unknown[]): void {
  console.debug('[withVoids]', ...messages)
}

export function withVoids(editor: Editor): Editor {
  const { deleteBackward, normalizeNode } = editor

  editor.normalizeNode = entry => {
    const [node, path] = entry

    if (!(Element.isElement(node) && Editor.isVoid(editor, node))) return normalizeNode(entry)

    // Voids can only have a single empty text node as a child
    if (!_.isEqual(node.children, [{ text: '' }])) {
      for (const [child, childPath] of Node.children(editor, path, { reverse: true })) {
        const index = _.last(childPath) ?? 0
        if (index > 0 || !_.isEqual(child, { text: '' })) {
          debug('Removing invalid child at', childPath)
          Transforms.removeNodes(editor, { at: childPath, voids: true })
        }
      }

      if (Array.from(Node.children(editor, path)).length === 0) {
        debug('Adding missing child at at', path.concat(0))
        Transforms.insertNodes(editor, { text: '' }, { at: path.concat(0), voids: true })
      }

      return
    } else {
      return normalizeNode(entry)
    }
  }

  editor.deleteBackward = unit => {
    const { selection } = editor

    // We suspect there is an issue with deleting backwards with { unit: 'line' }
    // in slate causing an 'unable to resolve DOM node from slate point' error
    // This log will help us trace where the issue is
    if (unit === 'line') {
      console.debug('[deleteBackward]', unit)
    }

    if (!selection) return deleteBackward(unit)

    const { path: previousPath } = Editor.before(editor, selection.anchor.path) || {}

    if (previousPath === undefined) return deleteBackward(unit)

    const previousNode = Editor.node(editor, previousPath)
    const [previousParentNode] = getParent(editor, previousNode)

    const previousNodeIsVoid =
      Element.isElement(previousParentNode) && Editor.isVoid(editor, previousParentNode)
    const previousNodeIsInline =
      Element.isElement(previousParentNode) && Editor.isInline(editor, previousParentNode)

    // Deleting backwards from the start of a node after a void node selects the void node
    if (
      previousNodeIsVoid &&
      !previousNodeIsInline &&
      selection.focus.offset === 0 &&
      Range.isCollapsed(selection)
    ) {
      const isEmpty = getCurrentNodeEmpty(editor)
      Transforms.move(editor, { distance: 1, reverse: true, unit: 'character' })
      if (isEmpty) {
        Transforms.removeNodes(editor, { at: selection })
      }
    } else {
      return deleteBackward(unit)
    }
  }

  return editor
}
