import { Dispatch, SetStateAction, useMemo } from 'react'
import { useNotif } from 'sierra-client/components/common/notifications'
import { useOrganizationPermissions } from 'sierra-client/hooks/use-permissions'
import { useTranslation } from 'sierra-client/hooks/use-translation'
import { getGlobalRouter } from 'sierra-client/router'
import { useTypedMutation } from 'sierra-client/state/api'
import { useSelector } from 'sierra-client/state/hooks'
import { selectIsOwnerUser, selectUser } from 'sierra-client/state/user/user-selector'
import { UserModalActionsProps } from 'sierra-client/views/manage/users/components/user-modal-actions'
import { UseUserDetailData } from 'sierra-client/views/manage/users/manage-user-details/hooks/use-user-detail-data'
import { useUserDetails } from 'sierra-client/views/manage/users/manage-user-details/hooks/use-user-details'
import { isOwner } from 'sierra-domain/access-level'
import { UserDetailResponse } from 'sierra-domain/api/manage'
import { UserId } from 'sierra-domain/api/uuid'
import { XRealtimeAdminUsersVerifyUserEmail } from 'sierra-domain/routes'
import { LabelMenuItem } from 'sierra-ui/components'

type UserMenuItemsProps = {
  setUserAction: Dispatch<SetStateAction<UserModalActionsProps['action']>>
  targetUserData?: UserDetailResponse
  targetUserId: UserId
  resendInviteOrOpenConfirmation: () => void
  canEdit: boolean
  refetch: UseUserDetailData['refetch']
}

export type UserSettingMenuItem = LabelMenuItem<
  | 'user-settings'
  | 'assign-content'
  | 'assign-program'
  | 'resend-invite'
  | 'resetProgress'
  | 'revoke'
  | 'verify-email'
> & {
  action: () => void
}

export const useUserSettingsMenuItems = ({
  setUserAction,
  resendInviteOrOpenConfirmation,
  targetUserId,
  targetUserData,
  canEdit,
  refetch,
}: UserMenuItemsProps): UserSettingMenuItem[] => {
  //Utils
  const { t } = useTranslation()
  const notifications = useNotif()

  const verifyUserEmailMutation = useTypedMutation(XRealtimeAdminUsersVerifyUserEmail, {
    onSuccess: () => refetch(),
  })

  //Data
  const me = useSelector(selectUser)

  // Used only for action utils. Doesn't send the ID so we don't re-fetch user details
  const { deleteUser } = useUserDetails()
  const permissions = useOrganizationPermissions()
  const canDelete = permissions.has('DELETE_ADMINISTERED_USER')
  const canEditAssignments = permissions.has('EDIT_CONTENT_ASSIGNMENTS')
  const canResetProgress = useSelector(selectIsOwnerUser)

  const menuItems: UserSettingMenuItem[] = useMemo(() => {
    if (me === undefined) return []

    return [
      {
        type: 'label',
        id: 'user-settings',
        label: canEdit ? t('manage.users.user-settings.title') : t('manage.view-details'),
        action: () => setUserAction({ modal: 'user-settings' }),
      },
      {
        type: 'label',
        id: 'assign-content',
        label: t('manage.users.assign-to-content'),
        hidden: !canEditAssignments,
        action: () => setUserAction({ modal: 'assign-content' }),
      },
      {
        type: 'label',
        id: 'assign-program',
        label: t('manage.users.assign-to-programs'),
        hidden: !canEditAssignments,
        action: () => setUserAction({ modal: 'assign-programs' }),
      },
      {
        id: 'resend-invite',
        type: 'label',
        label: t('admin.organization.invite.resend-invite'),
        action: resendInviteOrOpenConfirmation,
        hidden: targetUserData?.status !== 'pending',
      },
      {
        id: 'resetProgress',
        type: 'label',
        label: t('admin.organization.users.reset-progress'),
        color: 'destructive/background',
        action: () => setUserAction({ modal: 'reset-progress' }),
        hidden: targetUserData?.status === 'pending' || !canResetProgress,
      },
      {
        id: 'revoke',
        type: 'label',
        label: t('admin.organization.invite.revoke-invite'),
        color: 'destructive/background',
        action: async () => {
          await deleteUser(targetUserId)
          notifications.push({ type: 'revoke-invite' })
          await getGlobalRouter().navigate({ to: '/manage/users' })
        },
        hidden: targetUserData?.status !== 'pending' || !canDelete,
      },
      {
        type: 'label',
        id: 'verify-email',
        label: 'Verify Email',
        disabled: targetUserData?.emailVerificationOk ?? true,
        action: () => {
          if (confirm('Are you sure you want to mark this email as verified?')) {
            verifyUserEmailMutation.mutate({ userId: targetUserId })
          }
        },
        hidden: !isOwner(me.accessRole, me.accessLevel),
      },
    ]
  }, [
    deleteUser,
    me,
    canEdit,
    canEditAssignments,
    notifications,
    resendInviteOrOpenConfirmation,
    setUserAction,
    t,
    targetUserData?.status,
    targetUserData?.emailVerificationOk,
    targetUserId,
    canDelete,
    canResetProgress,
    verifyUserEmailMutation,
  ])

  return menuItems
}
