import React from 'react'
import { ChatRealtimeDataHandler } from 'sierra-client/collaboration/chat-provider/backend/chat-realtime-data-handler'
import { SyncSanaNowBackendChatToRedux } from 'sierra-client/collaboration/chat-provider/backend/sync-sana-now-backend-chat-to-redux'
import { SyncUserId } from 'sierra-client/collaboration/chat-provider/sync-user-id'
import { TriggerChatMigration } from 'sierra-client/collaboration/chat-provider/trigger-chat-migration'
import { SyncClock } from 'sierra-client/collaboration/sync-clock'
import { ChatIdentifier } from 'sierra-domain/api/chat'
import { ScopedChatId } from 'sierra-domain/collaboration/types'

export const ChatBackendSync: React.FC<{ chatId: ScopedChatId; chatIdentifier: ChatIdentifier }> = ({
  chatId,
  chatIdentifier,
}) => {
  return (
    <>
      <SyncSanaNowBackendChatToRedux chatIdentifier={chatIdentifier} />
      <ChatRealtimeDataHandler chatId={chatId} chatIdentifier={chatIdentifier} />
      <SyncUserId chatId={chatId} />
      <SyncClock />
      <TriggerChatMigration chatId={chatId} chatIdentifier={chatIdentifier} />
    </>
  )
}
