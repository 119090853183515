import { isElementType } from 'sierra-client/views/v3-author/queries'
import { isValidParagraphChild } from 'sierra-client/views/v3-author/unwrap-non-paragraph-children'
import { createParagraph } from 'sierra-domain/v3-author/create-blocks'
import { Editor, Node, Path, Point, Range } from 'slate'

function debug(...messages: unknown[]): void {
  console.debug('[withPreamble]', ...messages)
}

export const withPreamble = (editor: Editor): Editor => {
  const { normalizeNode, insertBreak, deleteBackward } = editor

  editor.normalizeNode = entry => {
    const [node, path] = entry

    if (!isElementType('preamble', node)) {
      return normalizeNode(entry)
    }

    for (const [child, childPath] of Node.children(editor, path)) {
      if (!isValidParagraphChild(child)) {
        debug(`Unwrapping non text child at: ${JSON.stringify(childPath)}`)
        editor.unwrapNodes({ at: childPath, voids: true })
        return
      }
    }

    normalizeNode(entry)
  }

  editor.insertBreak = () => {
    const { selection } = editor
    if (selection && Range.isCollapsed(selection)) {
      const [match] = editor.nodes({
        match: isElementType('preamble'),
      })

      if (match !== undefined) {
        const [, path] = match
        const nextPath = Path.next(path)
        debug(`insertBreak in preamble, wrapping in paragraph at ${JSON.stringify(nextPath)}`)

        editor.withoutNormalizing(() => {
          insertBreak()
          editor.wrapNodes(createParagraph(), { at: nextPath })
        })
        return
      }
    }

    insertBreak()
  }

  editor.deleteBackward = entry => {
    const { selection } = editor
    if (selection && Range.isCollapsed(selection)) {
      const [match] = editor.nodes({
        match: isElementType('preamble'),
      })

      if (match !== undefined) {
        const [, path] = match
        const preambleStartPoint = editor.start(path)

        if (Point.equals(selection.anchor, preambleStartPoint)) {
          debug(`deleteBackward at start of preamble, wrapping in paragraph at ${JSON.stringify(path)}`)
          editor.wrapNodes(createParagraph(), { at: path })
          return
        }
      }
    }

    deleteBackward(entry)
  }

  return editor
}
