import { useCallback, useMemo } from 'react'
import { useColorThemePreference } from 'sierra-client/components/common/color-theme-preference'
import { FCC } from 'sierra-client/types'
import { dark, light } from 'sierra-ui/theming'
import { Theme } from 'sierra-ui/theming/legacy-theme'
import { useTokensWithOverrides } from 'sierra-ui/theming/tokens/context'
import { useMediaQuery } from 'sierra-ui/utils'
import { ThemeProvider } from 'styled-components'

export const AppThemeTokenProvider: FCC<{ followSystemColorScheme?: boolean }> = ({
  followSystemColorScheme = false,
  children,
}) => {
  const prefersDarkMode = useMediaQuery('(prefers-color-scheme: dark)')
  const colorThemePreference = useColorThemePreference()

  const tokens = useMemo(() => {
    if (followSystemColorScheme || colorThemePreference === 'system-preference') {
      return prefersDarkMode ? dark : light
    }

    return colorThemePreference === 'dark' ? dark : light
  }, [colorThemePreference, followSystemColorScheme, prefersDarkMode])

  const tokensWithOverrides = useTokensWithOverrides(tokens)

  const patchTheme = useCallback(
    (theme: Theme): Theme => ({ ...theme, tokens: tokensWithOverrides }),
    [tokensWithOverrides]
  )

  return <ThemeProvider theme={patchTheme}>{children}</ThemeProvider>
}
