import { createFileRoute } from '@tanstack/react-router'
import { useLiveSessionPermissions } from 'sierra-client/hooks/use-permissions'
import { PageIdentifier } from 'sierra-client/layout/sana-page'
import { LoadingPermissionsWrapper } from 'sierra-client/routes/access-denied.route'
import { ManageLiveSessionDetails } from 'sierra-client/views/manage/live-session/manage-live-session-details'
import { ManagePageWrapper } from 'sierra-client/views/manage/utils/manage-page-wrapper'
import { LiveSessionId } from 'sierra-domain/api/nano-id'
import { z } from 'zod'

// Note: ManagePageWrapper handles the role check.
const Page: React.FC = () => {
  const liveSessionId = Route.useParams({ select: p => p.liveSessionId })
  const permissions = useLiveSessionPermissions(liveSessionId)

  return (
    <ManagePageWrapper pageIdentifier={PageIdentifier.ManageLiveSessionDetail({ liveSessionId })}>
      <LoadingPermissionsWrapper
        isLoading={permissions.status === 'loading'}
        hasAccess={permissions.has('MANAGE')}
      >
        <ManageLiveSessionDetails
          key={liveSessionId}
          liveSessionId={liveSessionId}
          canEditAssignments={permissions.has('EDIT_ASSIGNMENTS')}
        />
      </LoadingPermissionsWrapper>
    </ManagePageWrapper>
  )
}

export const Route = createFileRoute('/manage/events/$liveSessionId')({
  component: Page,
  params: {
    parse: z.object({ liveSessionId: LiveSessionId }).parse,
    stringify: p => p,
  },
})
