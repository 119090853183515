import { browserName, isChrome, isDesktop, isEdgeChromium, isFirefox } from 'react-device-detect'

const isChromiumBased =
  isChrome ||
  isEdgeChromium ||
  // Allow for Brave browser to be considered as Chromium (which is the case). react-device-detect does not have a specific check for Brave.
  browserName === 'Brave'

export const isDenoiserSupported = (isFirefox || isChromiumBased) && isDesktop
export const isVirtualBackgroundSupported = isChromiumBased && isDesktop
export const isDualStreamSupported = isDesktop
export const isPictureInPictureSupported = isChromiumBased && isDesktop
export const isPushToTalkSupported = isDesktop
