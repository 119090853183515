import { Logger } from 'sierra-client/core/logging/logger'

function event(name: string): string {
  return `identities-selector-${name}`
}

type PastedEmailsPayload = {
  selectorId: string
  numberOfEmails: number
}

export const pastedEmails = Logger.trackLoggerWithExtra<PastedEmailsPayload, PastedEmailsPayload>(
  event('pasted-emails'),
  input => ({ ...input })
)
