import { atom } from 'jotai'
import { UserId } from 'sierra-domain/api/uuid'

export type PollOptionData = { optionId: string; totalVotes: number; votedByUserIds: UserId[] }
export type PollData = {
  optionResults: PollOptionData[]
  totalVotes: number
  updatedAt?: string
}
export const currentPollDataAtom = atom<PollData | undefined>(undefined)
