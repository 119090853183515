import { useSearch } from '@tanstack/react-router'
import { FC, useRef } from 'react'
import { useNotif } from 'sierra-client/components/common/notifications'
import { useTranslation } from 'sierra-client/hooks/use-translation'
import { logger } from 'sierra-client/logger/logger'
import { getGlobalRouter } from 'sierra-client/router'
import { selectMessageSafe } from 'sierra-client/state/chat/selectors'
import { browseCommentThread } from 'sierra-client/state/commenting/actions'
import { useDispatch, useSelector } from 'sierra-client/state/hooks'
import { useCreateRouteIds } from 'sierra-client/views/commenting/use-create-route-ids'
import { ScopedChatId, ScopedCreateContentId } from 'sierra-domain/collaboration/types'
import { NodeId } from 'sierra-domain/flexible-content/identifiers'
import { useOnChanged } from 'sierra-ui/utils'

const CommentingQueryListenerImplementation: FC<{
  chatId: ScopedChatId
  contentId: ScopedCreateContentId
  unitId?: NodeId
}> = ({ chatId, contentId }) => {
  const dispatch = useDispatch()
  const hasNotfiedRef = useRef(false)

  const linkedMessageId = useSearch({ strict: false, select: s => s.chat_message })
  const linkedMessage = useSelector(state => selectMessageSafe(state, chatId, linkedMessageId))
  const threadRootMessage = useSelector(state =>
    selectMessageSafe(state, chatId, linkedMessage?.responseToMessageId)
  )
  const linkedThreadRoot = threadRootMessage !== undefined ? threadRootMessage : linkedMessage

  const notifications = useNotif()
  const { t } = useTranslation()

  useOnChanged(() => {
    if (linkedThreadRoot === undefined) {
      return
    } else if (linkedThreadRoot.type !== 'tiptap-comment') {
      logger.error(`Deeplinking to a chat thread is not supported yet, got ${linkedThreadRoot.type}`)
      return
    } else if (linkedThreadRoot.contentReference.unitId === undefined) {
      logger.error(`Comment ${linkedThreadRoot.id} has no associated unitId`)
      return
    }

    if (linkedThreadRoot.detachedFromContent === true && !hasNotfiedRef.current) {
      notifications.push({
        level: 'info',
        type: 'custom',
        body: t('commenting.comment-content-removed'),
        icon: 'unlink',
      })

      hasNotfiedRef.current = true
    }

    void dispatch(
      browseCommentThread({
        reason: 'deeplink',
        comment: linkedThreadRoot,
      })
    )

    // If we are on the wrong file we also redirect to the corrent page
    // TODO: can this happen? I don't understand how this can occur

    const scope = ScopedCreateContentId.urlSubPathForId(contentId)
    const currentPathname = window.location.pathname
    const destinationPathname = `/create/${scope}/${ScopedCreateContentId.extractId(contentId)}/${
      linkedThreadRoot.contentReference.unitId
    }`

    if (currentPathname !== destinationPathname) {
      void getGlobalRouter().navigate({ to: destinationPathname, replace: true })
    }
  }, [linkedThreadRoot?.id])

  return null
}

export const CommentingQueryListener: FC<{ chatId: ScopedChatId }> = ({ chatId }) => {
  const data = useCreateRouteIds()
  const isCardEditor = data !== undefined

  if (!isCardEditor) {
    return null
  }

  const { contentId, unitId } = data

  return <CommentingQueryListenerImplementation chatId={chatId} contentId={contentId} unitId={unitId} />
}
