import React, { forwardRef } from 'react'
import styled from 'styled-components'

const Wrap = styled.div<{ $width?: number; $height?: number }>`
  & svg {
    display: block;
    width: ${p => p.$width ?? 32}px;
    height: ${p => p.$height ?? 32}px;
  }
`

type Props = { width?: number; height?: number }
export const TeamsLogo = forwardRef<HTMLDivElement, React.HTMLAttributes<HTMLDivElement> & Props>(
  ({ width, height, ...divProps }, ref): JSX.Element => (
    <Wrap $width={width} $height={height} {...divProps} ref={ref}>
      <svg width='16' height='16' viewBox='0 0 16 16' fill='none' xmlns='http://www.w3.org/2000/svg'>
        <g clipPath='url(#clip0_8978_7495)'>
          <path
            d='M11.1605 6.08142H15.2933C15.6838 6.08142 16.0003 6.39794 16.0003 6.7884V10.5529C16.0003 11.9879 14.837 13.1512 13.402 13.1512H13.3897C11.9547 13.1514 10.7912 11.9882 10.791 10.5532C10.791 10.5531 10.791 10.553 10.791 10.5529V6.45091C10.791 6.24685 10.9564 6.08142 11.1605 6.08142Z'
            fill='#5059C9'
          />
          <path
            d='M13.9537 5.33724C14.8785 5.33724 15.6281 4.58758 15.6281 3.66282C15.6281 2.73807 14.8785 1.9884 13.9537 1.9884C13.029 1.9884 12.2793 2.73807 12.2793 3.66282C12.2793 4.58758 13.029 5.33724 13.9537 5.33724Z'
            fill='#5059C9'
          />
          <path
            d='M8.74429 5.33721C10.0801 5.33721 11.1629 4.25437 11.1629 2.91861C11.1629 1.58285 10.0801 0.5 8.74429 0.5C7.40853 0.5 6.32568 1.58285 6.32568 2.91861C6.32568 4.25437 7.40853 5.33721 8.74429 5.33721Z'
            fill='#7B83EB'
          />
          <path
            d='M11.9695 6.08142H5.14756C4.76176 6.09097 4.45656 6.41114 4.46551 6.79696V11.0905C4.41164 13.4058 6.24336 15.3271 8.55854 15.3838C10.8737 15.3271 12.7054 13.4058 12.6516 11.0905V6.79696C12.6605 6.41114 12.3553 6.09097 11.9695 6.08142Z'
            fill='#7B83EB'
          />
          <path
            opacity='0.1'
            d='M8.93065 6.08142V12.0982C8.9288 12.3741 8.7616 12.6219 8.50646 12.727C8.42523 12.7614 8.33792 12.7791 8.24972 12.7791H4.79297C4.7446 12.6563 4.69995 12.5335 4.66274 12.407C4.53249 11.98 4.46603 11.5362 4.46552 11.0898V6.79584C4.45658 6.41064 4.76127 6.09097 5.14645 6.08142H8.93065Z'
            fill='black'
          />
          <path
            opacity='0.2'
            d='M8.55856 6.08142V12.4703C8.55854 12.5585 8.54083 12.6458 8.50646 12.727C8.40141 12.9821 8.15354 13.1493 7.87763 13.1512H4.96786C4.9046 13.0284 4.84507 12.9056 4.79297 12.7791C4.74087 12.6526 4.69995 12.5335 4.66274 12.407C4.53249 11.98 4.46603 11.5362 4.46552 11.0898V6.79584C4.45658 6.41064 4.76127 6.09097 5.14645 6.08142H8.55856Z'
            fill='black'
          />
          <path
            opacity='0.2'
            d='M8.55855 6.08142V11.7261C8.55571 12.101 8.25251 12.4042 7.87762 12.407H4.66274C4.53249 11.98 4.46603 11.5362 4.46552 11.0898V6.79584C4.45658 6.41064 4.76127 6.09097 5.14645 6.08142H8.55855Z'
            fill='black'
          />
          <path
            opacity='0.2'
            d='M8.18645 6.08142V11.7261C8.18362 12.101 7.88041 12.4042 7.50552 12.407H4.66274C4.53249 11.98 4.46603 11.5362 4.46552 11.0898V6.79584C4.45658 6.41064 4.76127 6.09097 5.14645 6.08142H8.18645Z'
            fill='black'
          />
          <path
            opacity='0.1'
            d='M8.93026 4.15773V5.32982C8.867 5.33354 8.80747 5.33727 8.74421 5.33727C8.68095 5.33727 8.62142 5.33355 8.55816 5.32982C8.43256 5.32149 8.308 5.30156 8.18607 5.27029C7.43258 5.09185 6.81007 4.56342 6.51165 3.8489C6.4603 3.72891 6.42044 3.60431 6.39258 3.47681H8.24932C8.6248 3.47823 8.92883 3.78225 8.93026 4.15773Z'
            fill='black'
          />
          <path
            opacity='0.2'
            d='M8.55823 4.5298V5.3298C8.43263 5.32147 8.30807 5.30154 8.18614 5.27027C7.43265 5.09183 6.81014 4.5634 6.51172 3.84888H7.8773C8.25277 3.8503 8.5568 4.15433 8.55823 4.5298Z'
            fill='black'
          />
          <path
            opacity='0.2'
            d='M8.55823 4.5298V5.3298C8.43263 5.32147 8.30807 5.30154 8.18614 5.27027C7.43265 5.09183 6.81014 4.5634 6.51172 3.84888H7.8773C8.25277 3.8503 8.5568 4.15433 8.55823 4.5298Z'
            fill='black'
          />
          <path
            opacity='0.2'
            d='M8.18614 4.52981V5.27027C7.43265 5.09183 6.81014 4.5634 6.51172 3.84888H7.50521C7.88069 3.85031 8.18471 4.15434 8.18614 4.52981Z'
            fill='black'
          />
          <path
            d='M0.682043 3.84888H7.50399C7.88068 3.84888 8.18604 4.15424 8.18604 4.53092V11.3529C8.18604 11.7296 7.88067 12.0349 7.50399 12.0349H0.682043C0.305358 12.0349 0 11.7295 0 11.3529V4.53092C0 4.15424 0.305365 3.84888 0.682043 3.84888Z'
            fill='url(#paint0_linear_8978_7495)'
          />
          <path
            d='M5.88829 6.44535H4.52457V10.1588H3.65574V6.44535H2.29834V5.72498H5.88829V6.44535Z'
            fill='white'
          />
        </g>
        <defs>
          <linearGradient
            id='paint0_linear_8978_7495'
            x1='1.42208'
            y1='3.31594'
            x2='6.76397'
            y2='12.5679'
            gradientUnits='userSpaceOnUse'
          >
            <stop stopColor='#5A62C3' />
            <stop offset='0.5' stopColor='#4D55BD' />
            <stop offset='1' stopColor='#3940AB' />
          </linearGradient>
          <clipPath id='clip0_8978_7495'>
            <rect width='16' height='16' fill='white' />
          </clipPath>
        </defs>
      </svg>
    </Wrap>
  )
)
