import { useMutation } from '@tanstack/react-query'
import { useEffect, useMemo, useRef, useState } from 'react'
import { useDeepEqualityMemo } from 'sierra-client/hooks/use-deep-equality-memo'
import { useIsDebugMode } from 'sierra-client/hooks/use-is-debug-mode'
import { useAssertUniqueness } from 'sierra-client/hooks/use-uniqueness-check'
import { typedPost } from 'sierra-client/state/api'
import { useSelector } from 'sierra-client/state/hooks'
import { selectUser } from 'sierra-client/state/user/user-selector'
import { UpdateLivePresenceData } from 'sierra-domain/api/live-session-presence'
import { NanoId12 } from 'sierra-domain/api/nano-id'
import { nanoid12 } from 'sierra-domain/nanoid-extensions'
import {
  XRealtimeUniversalPresenceRemovePresence,
  XRealtimeUniversalPresenceUpdatePresence,
} from 'sierra-domain/routes'

import { useSetAtom } from 'jotai'
import { debounce } from 'lodash'
import {
  SetIntervalWorker,
  createSetIntervalWorker,
} from 'sierra-client/features/sana-now/presence/set-interval-worker'
import { CurrentUserPresenceSessionAtom } from 'sierra-client/features/sana-now/presence/state'
import { logger } from 'sierra-client/logger/logger'

type SyncPresenceProps = Omit<UpdateLivePresenceData, 'sessionId'>

const PING_INTERVAL = 10_000

const calculateAndLogDrift = (lastPing: number | null, source: 'worker' | 'setInterval'): number => {
  if (lastPing !== null) {
    const drift = performance.now() - lastPing - PING_INTERVAL

    if (Math.abs(drift) > 1000) {
      logger.debug(`_SyncPresence: ${source} drift ms=${drift}`, { drift })
    }
  }

  return performance.now()
}

const useIntervalWorker = (): SetIntervalWorker | null => {
  const [worker] = useState(() => createSetIntervalWorker())
  const [error, setError] = useState<Error | null>(null)

  useEffect(() => {
    if (!worker) return

    worker.onerror = event => {
      setError(new Error(event.message))
      logger.captureError(event)
    }

    return () => {
      worker.terminate()
    }
  }, [worker])

  if (error) return null
  return worker
}

function _SyncPresence(_presence: SyncPresenceProps): null {
  const presence = useDeepEqualityMemo(_presence)
  const updateRequestAbortController = useRef<AbortController | null>(null)
  const lastIntervalPingAt = useRef<number | null>(null)
  const lastWorkerPingAt = useRef<number | null>(null)
  const setIntervalWorker = useIntervalWorker()
  const setSessionId = useSetAtom(CurrentUserPresenceSessionAtom)

  const [sessionId] = useState(() => nanoid12())
  const { mutate: updatePresenceMutation } = useMutation({
    mutationFn: (presence: UpdateLivePresenceData) => {
      updateRequestAbortController.current = new AbortController()
      return typedPost(XRealtimeUniversalPresenceUpdatePresence, presence, {
        signal: updateRequestAbortController.current.signal,
      })
    },
  })

  useEffect(() => {
    setSessionId(sessionId)

    return () => {
      setSessionId(undefined)
    }
  }, [sessionId, setSessionId])

  const updatePresenceMutationDebounced = useMemo(
    () => debounce(updatePresenceMutation, 250, { maxWait: 1000, leading: true, trailing: true }),
    [updatePresenceMutation]
  )

  const { mutate: clearMutation } = useMutation({
    mutationFn: ({ sessionId }: { sessionId: NanoId12 }) => {
      updateRequestAbortController.current?.abort()
      return typedPost(
        XRealtimeUniversalPresenceRemovePresence,
        { sessionId },
        { priority: 'high', keepalive: true }
      )
    },
  })

  // Send a clear mutation when the component unmounts
  useEffect(() => {
    const close = (): void => clearMutation({ sessionId })
    window.addEventListener('beforeunload', close)
    return () => {
      window.removeEventListener('beforeunload', close)
      clearMutation({ sessionId })
    }
  }, [sessionId, clearMutation])

  useEffect(() => {
    /**
     * We send a ping to the backend every PING_INTERVAL ms to keep track of what users are still in this session.
     * When the tab is in the background the browser will throttle the setTimeout and setInterval functions, which
     * causes the pings to be sent less frequently, sometimes as much as 180 seconds too late.
     * To avoid this we use a webworker to imitate an interval since they aren't throttled in the same way.
     *
     * We still use a setTimeout function as a fallback should there be any issues with the worker.
     */

    let timeout = setTimeout(function fn() {
      if (!setIntervalWorker) {
        updatePresenceMutationDebounced({ ...presence, sessionId })
      }

      lastIntervalPingAt.current = calculateAndLogDrift(lastIntervalPingAt.current, 'setInterval')
      // instead of using setInterval, we use setTimeout to avoid overlapping pings
      timeout = setTimeout(fn, PING_INTERVAL)
    }, PING_INTERVAL)

    if (setIntervalWorker) {
      logger.debug('_SyncPresence: Using webworker for presence ping')
      setIntervalWorker.postMessage({ type: 'start', interval: PING_INTERVAL })
      setIntervalWorker.onmessage = ({ data }) => {
        lastWorkerPingAt.current = calculateAndLogDrift(lastWorkerPingAt.current, 'worker')

        switch (data) {
          case 'tick':
            // Important to not use the debounced version here
            // since debounce is using setTimeout
            updatePresenceMutation({ ...presence, sessionId })
        }
      }
    } else {
      logger.debug('_SyncPresence: Using interval for presence ping')
    }

    updatePresenceMutationDebounced({ ...presence, sessionId })

    return () => {
      clearTimeout(timeout)
      setIntervalWorker?.postMessage({ type: 'stop' })
    }
  }, [presence, updatePresenceMutationDebounced, setIntervalWorker, sessionId, updatePresenceMutation])

  return null
}

export function SyncLocalPresence(_presence: SyncPresenceProps): JSX.Element | null {
  useAssertUniqueness(`SyncLiveSessionPresence:${_presence.liveSessionId}`)
  const user = useSelector(selectUser)
  return <_SyncPresence key={user?.uuid} {..._presence} />
}

export function DebugUserSyncLocalPresence(_presence: SyncPresenceProps): JSX.Element | null {
  const isDebug = useIsDebugMode()
  if (!isDebug) return null

  return <_SyncPresence {..._presence} />
}
