import { FCC } from 'sierra-ui/types'
import styled from 'styled-components'

const Container = styled.div<{ $area: string }>`
  grid-area: ${p => p.$area};
`

export const GridArea: FCC<{ area: string }> = ({ children, area }) => {
  return <Container $area={area}>{children}</Container>
}
