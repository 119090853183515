import { useCallback, useMemo } from 'react'
import { logger } from 'sierra-client/logger/logger'
import { useFileTitle, useGetFileTitle } from 'sierra-client/state/flexible-content/file-title'
import { getSubtreeIds } from 'sierra-client/state/flexible-content/selectors'
import { FCC } from 'sierra-client/types'
import { PolarisCardTheme } from 'sierra-client/views/flexible-content/polaris-card-theme'
import { useFlexibleContentYDoc } from 'sierra-client/views/flexible-content/polaris-editor-provider/use-flexible-content-ydoc'
import { useSelfPacedBigContext } from 'sierra-client/views/flexible-content/self-paced-big-context'
import { FlexibleContentStatus } from 'sierra-domain/api/strategy-v2'
import { FileId, NodeId } from 'sierra-domain/flexible-content/identifiers'
import { File, FlexibleContentJsonData, Folder } from 'sierra-domain/flexible-content/types'
import { assertWith, guardWith } from 'sierra-domain/utils'
import { ProgressBar, ProgressBarFile, ProgressBarFolder } from 'sierra-ui/missions/live'
import { Text } from 'sierra-ui/primitives'

const LiveNodeTitle = ({ node }: { node: File }): JSX.Element => {
  const yDoc = useFlexibleContentYDoc()
  const fileTitle = useFileTitle(yDoc.yDoc, node)
  return (
    <Text size='micro' color={'white'} bold>
      {fileTitle}
    </Text>
  )
}

const SelfPacedNodeTitle = ({ node }: { node: File }): JSX.Element => {
  const { slateDocumentMap } = useSelfPacedBigContext()
  const getFileTitle = useGetFileTitle(slateDocumentMap)
  return (
    <Text size='micro' color={'white'} bold>
      {getFileTitle(node)}
    </Text>
  )
}

const RenderFile = ({
  node,
  numFilesInContent,
  moduleTitle,
  isCurrent,
  isCompleted,
  onClick,
  mode,
}: {
  node: File
  numFilesInContent: number
  isCurrent: boolean
  isCompleted: boolean
  moduleTitle?: string
  onClick?: (fileId: FileId) => void
  mode: 'self-paced' | 'live' | 'recap'
}): JSX.Element => {
  const NodeTitle = mode === 'self-paced' ? SelfPacedNodeTitle : LiveNodeTitle
  const handleClick = useCallback(() => onClick?.(node.id), [node.id, onClick])

  return (
    <ProgressBarFile
      moduleTitle={moduleTitle}
      fileTitle={<NodeTitle node={node} />}
      handleClick={handleClick}
      nrFiles={numFilesInContent}
      highlight={isCurrent}
      completed={isCompleted}
    />
  )
}

const ProgressBarNode = ({
  nodeId,
  content,
  renderFile,
  renderFolder,
  numFilesInContent,
  moduleTitle,
}: {
  nodeId: NodeId
  content: FlexibleContentJsonData
  renderFile: (file: File, numFilesInContent: number, moduleTitle?: string) => JSX.Element
  renderFolder: (folder: Folder, children?: JSX.Element[]) => JSX.Element
  numFilesInContent: number
  moduleTitle?: string
}): JSX.Element | null => {
  const node = content.nodeMap[nodeId]

  if (!node) {
    logger.error('No node found with ID', { nodeId })
    return null
  }

  switch (node.type) {
    case 'file':
      return renderFile(node, numFilesInContent, moduleTitle)
    case 'folder':
      return renderFolder(
        node,
        node.nodeIds.map(nodeId => (
          <ProgressBarNode
            renderFile={renderFile}
            renderFolder={renderFolder}
            key={nodeId}
            nodeId={nodeId}
            content={content}
            numFilesInContent={numFilesInContent}
            moduleTitle={node.title}
          />
        ))
      )
    default:
      return <p>{`Node type (${node.type}) not implemented`}</p>
  }
}

type ProgressBarProps = {
  flexibleContent: FlexibleContentJsonData
  currentFileId: FileId
  // TODO: For self paced we wanna show some progress tied to the users current progress
  progress?: FlexibleContentStatus
  supportedFileIds?: FileId[]
  mode: 'self-paced' | 'live' | 'recap'
  onClickFile?: (fileId: FileId) => void
}

const ThemeProvider: FCC<{ file: File | undefined }> = ({ file, children }) => {
  if (!file) return <>{children}</>
  return <PolarisCardTheme {...file}>{children}</PolarisCardTheme>
}

export const FlexibleContentProgressBar: React.FC<ProgressBarProps> = ({
  flexibleContent,
  currentFileId,
  onClickFile,
  supportedFileIds,
  mode,
}) => {
  const root = flexibleContent.nodeMap['folder:root']
  assertWith(Folder, root)

  const { numFilesInContent, fileIndexMap } = useMemo(() => {
    const fileIds = getSubtreeIds(root, flexibleContent.nodeMap).filter(guardWith(FileId))
    const fileIndexMap: Record<FileId, number> = {}
    fileIds.forEach((fileId, i) => (fileIndexMap[fileId] = i))

    return { numFilesInContent: fileIds.length, fileIndexMap }
  }, [flexibleContent.nodeMap, root])

  // The file indicators will be too small at this point
  if (numFilesInContent > 100) return null

  const file = flexibleContent.nodeMap[currentFileId]

  return (
    <ThemeProvider file={file?.type === 'file' ? file : undefined}>
      <ProgressBar>
        {root.nodeIds.map(nodeId => (
          <ProgressBarNode
            renderFile={(file, numFilesInContent, moduleTitle) => (
              <RenderFile
                mode={mode}
                isCurrent={currentFileId === file.id}
                isCompleted={(fileIndexMap[file.id] ?? -1) < (fileIndexMap[currentFileId] ?? -1)}
                node={file}
                numFilesInContent={numFilesInContent}
                moduleTitle={moduleTitle}
                onClick={
                  supportedFileIds === undefined || supportedFileIds.includes(file.id)
                    ? onClickFile
                    : undefined
                }
              />
            )}
            renderFolder={(folder, children) => <ProgressBarFolder>{children}</ProgressBarFolder>}
            key={nodeId}
            nodeId={nodeId}
            content={flexibleContent}
            numFilesInContent={numFilesInContent}
          />
        ))}
      </ProgressBar>
    </ThemeProvider>
  )
}
