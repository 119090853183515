import React from 'react'
import { ScenarioChat } from 'sierra-client/views/v3-author/scenario/chat'
import { assertScenarioCard } from 'sierra-client/views/v3-author/scenario/utils'
import { CreateContentId } from 'sierra-domain/api/nano-id'
import { File } from 'sierra-domain/flexible-content/types'
import { assert } from 'sierra-domain/utils'
import { View } from 'sierra-ui/primitives'
import styled from 'styled-components'

const StyledView = styled(View)`
  height: 100%;
`

const ChatContainer = styled(View).attrs({ alignItems: 'stretch' })`
  height: 100%;
  width: 100%;
`

export const ScenarioStarter: React.FC<{
  file: File
  courseId: CreateContentId
}> = ({ courseId, file }) => {
  assertScenarioCard(file)
  assert(file.data.input.character !== undefined)

  return (
    <StyledView alignItems='stretch' grow justifyContent='center'>
      <View grow direction='column' alignItems='center' justifyContent='center' position='relative'>
        <ChatContainer
          key='chat'
          grow
          animated
          initial={{ opacity: 0 }}
          animate={{ opacity: 1 }}
          exit={{ opacity: 0 }}
          transition={{ duration: 0.2 }}
        >
          <ScenarioChat character={file.data.input.character} file={file} courseId={courseId} />
        </ChatContainer>
      </View>
    </StyledView>
  )
}
