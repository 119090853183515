import React, { useMemo } from 'react'
import { useTranslation } from 'sierra-client/hooks/use-translation'
import { DomainRef, DomainRep, Filter, getDomainRep, Operator, Predicate } from 'sierra-client/lib/filter'
import { EntityAutoCompleteLabel } from 'sierra-client/lib/filter/components/auto-complete/auto-complete'
import {
  humanShowOp,
  Intersperse,
  labelsForPredicate,
  labelToString,
  predicateLabelsToLabel,
} from 'sierra-client/lib/filter/components/common'
import { valueId } from 'sierra-client/lib/filter/components/predicate-utils'
import * as UI from 'sierra-client/lib/filter/ui'
import { assertNever, isDefined } from 'sierra-domain/utils'
import { Tooltip } from 'sierra-ui/components'
import { z } from 'zod'

const CustomAttributeDomainRef = z.object({
  type: z.literal('user.customAttribute'),
  key: z.string(),
})

const getCustomAttributeDomainRefKey = (domain: DomainRef): string | undefined => {
  const res = CustomAttributeDomainRef.safeParse(domain)

  if (res.success) {
    return res.data.key
  } else {
    return undefined
  }
}

const getLabelsFromPredicate = (predicate: Predicate): string[] => {
  switch (predicate.type) {
    case 'predicate.single':
      return [valueId(predicate.value)].filter(f => f !== '')
    case 'predicate.and':
    case 'predicate.or':
      return predicate.values.map(val => valueId(val)).filter(isDefined)
    case 'predicate.none':
      return []
    default:
      assertNever(predicate)
  }
}

export const ReadOnlyDomain: React.FC<{ domainRep: DomainRep | undefined; filterDomain: DomainRef }> = ({
  domainRep,
  filterDomain,
}) => {
  const { dynamicT } = useTranslation()

  const label = useMemo(() => {
    if (domainRep !== undefined) {
      return labelToString(domainRep.label, dynamicT)
    } else {
      // The most common case for a missing domainRep will probably be a custom attribute that was removed.
      // We'll make an attempt to get the attribute key from the filter domain.
      return getCustomAttributeDomainRefKey(filterDomain) ?? 'N/A'
    }
  }, [dynamicT, domainRep, filterDomain])

  return (
    <UI.Attribute.Wrapper readOnly={true}>
      <UI.Attribute.Text>{label}</UI.Attribute.Text>
    </UI.Attribute.Wrapper>
  )
}

export const ReadOnlyOperator: React.FC<{ operator: Operator }> = ({ operator }) => {
  const { t } = useTranslation()

  return (
    <UI.Operator.Wrapper readOnly={true}>
      <UI.Operator.Text color='foreground/secondary'>{humanShowOp(operator, t)}</UI.Operator.Text>
    </UI.Operator.Wrapper>
  )
}

export const ReadOnlyPredicateChoices: React.FC<{
  domainRep: DomainRep | undefined
  predicate: Predicate
}> = ({ domainRep, predicate }) => {
  const { dynamicT } = useTranslation()
  const labels = useMemo<string[]>(() => {
    if (domainRep !== undefined) {
      return labelsForPredicate(domainRep, predicate, dynamicT)
    } else {
      return getLabelsFromPredicate(predicate)
    }
  }, [domainRep, predicate, dynamicT])

  return (
    <UI.Value.Wrapper readOnly={true}>
      <Tooltip title={labels.join(', ')}>
        <UI.Value.Text>{predicateLabelsToLabel(labels)}</UI.Value.Text>
      </Tooltip>
    </UI.Value.Wrapper>
  )
}

export const ReadOnlyPredicateAutocomplete: React.FC<{ domainRep: DomainRep; predicate: Predicate }> = ({
  domainRep,
  predicate,
}) => {
  if (domainRep.domain.type !== 'domain.auto-complete') {
    return null
  }
  return <EntityAutoCompleteLabel readOnly entity={domainRep.domain.entity} predicate={predicate} />
}

export const ReadOnlyPredicate: React.FC<{ domainRep: DomainRep | undefined; predicate: Predicate }> = ({
  domainRep,
  predicate,
}) => {
  switch (domainRep?.domain.type) {
    case 'domain.auto-complete':
      return <ReadOnlyPredicateAutocomplete domainRep={domainRep} predicate={predicate} />
  }
  return <ReadOnlyPredicateChoices domainRep={domainRep} predicate={predicate} />
}

const ReadOnlyDistinctionAnd: React.FC = () => {
  const { t } = useTranslation()
  return (
    <UI.Distinction.Wrapper readOnly>
      <UI.Distinction.Text color='foreground/secondary'>
        {t('user-filter.conjunction.and')}
      </UI.Distinction.Text>
    </UI.Distinction.Wrapper>
  )
}

const ReadOnlyDistinctionOr: React.FC = () => {
  const { t } = useTranslation()
  return (
    <UI.Distinction.Wrapper readOnly>
      <UI.Distinction.Text>{t('user-filter.conjunction.or')}</UI.Distinction.Text>
    </UI.Distinction.Wrapper>
  )
}

export const ReadOnlyFilterGroup: React.FC<React.PropsWithChildren> = ({ children }) => (
  <UI.FilterGroup.Wrapper readOnly={false}>
    <UI.Filter readOnly={false}>{children}</UI.Filter>
  </UI.FilterGroup.Wrapper>
)

export const ReadOnlyFilter: React.FC<{
  domainReps: DomainRep[]
  filter: Filter
  depth?: number
}> = ({ domainReps, filter, depth = 0 }) => {
  switch (filter.type) {
    case 'filter.filter': {
      const domainRep = getDomainRep(domainReps, filter.domain)

      return (
        <UI.FilterSection.Wrapper readOnly={true}>
          <UI.FilterSection.Wrapper readOnly={false} hasError={domainRep === undefined}>
            <ReadOnlyDomain domainRep={domainRep} filterDomain={filter.domain} />
            <ReadOnlyOperator operator={filter.operator} />
            <ReadOnlyPredicate domainRep={domainRep} predicate={filter.predicate} />
          </UI.FilterSection.Wrapper>
        </UI.FilterSection.Wrapper>
      )
    }
    case 'filter.and':
      return (
        <Intersperse separator={<ReadOnlyDistinctionAnd />}>
          {filter.filters.map((filter, i) => (
            <ReadOnlyFilter depth={depth + 1} domainReps={domainReps} filter={filter} key={i} />
          ))}
        </Intersperse>
      )
    case 'filter.or':
      return (
        <Intersperse separator={<ReadOnlyDistinctionOr />}>
          {filter.filters.map((filter, i) => (
            <ReadOnlyFilter depth={depth + 1} domainReps={domainReps} filter={filter} key={i} />
          ))}
        </Intersperse>
      )
    default:
      assertNever(filter)
  }
}
