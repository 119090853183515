import { useEffect, useMemo, useState } from 'react'
import { usePost } from 'sierra-client/hooks/use-post'
import { useTranslation } from 'sierra-client/hooks/use-translation'
import { selectIsCourseCompleted } from 'sierra-client/state/card-progress/selectors'
import { useSelector } from 'sierra-client/state/hooks'
import { selectUser } from 'sierra-client/state/user/user-selector'
import {
  ShuffleElementsProvider,
  useQuestionShuffle,
} from 'sierra-client/views/flexible-content/question-shuffling/shuffle-elements-provider'
import { AdaptiveModal } from 'sierra-client/views/self-paced/adaptive-features/adaptive-border'
import { AdaptiveState } from 'sierra-client/views/self-paced/adaptive-features/adaptive-context'
import {
  BottomContainer,
  BottomContainerText,
  DuringAdaptiveModal,
  FinishedAdaptiveModal,
  Separator,
  StartAdaptiveModal,
} from 'sierra-client/views/self-paced/adaptive-features/modal'
import { useSelfPacedFiles } from 'sierra-client/views/self-paced/files-provider'
import { ReviewContextProvider, Reviews } from 'sierra-client/views/self-paced/review/review-context'
import { StaticEditor } from 'sierra-client/views/self-paced/review/static-editor'
import { CreateContentId } from 'sierra-domain/api/nano-id'
import { FlexibleContentAdaptiveReview } from 'sierra-domain/api/strategy-v2'
import { AssetContext } from 'sierra-domain/asset-context'
import { FolderId } from 'sierra-domain/flexible-content/identifiers'
import { XRealtimeStrategySelfPacedContentAdaptiveElements } from 'sierra-domain/routes'
import { SlateRootElement } from 'sierra-domain/v3-author'
import { Icon } from 'sierra-ui/components'
import { LoadingSpinner, Text, View } from 'sierra-ui/primitives'

const initialiseReviewState = (
  review: FlexibleContentAdaptiveReview,
  contentId: CreateContentId
): AdaptiveState => ({
  contentId,
  index: 0,
  status: 'start',
  elements: review.elements.map(({ cardId, exerciseId, slateNodes }) => ({
    fileId: cardId,
    exerciseId: exerciseId,
    element: slateNodes,
  })),
})

const startFinishedProps = {
  size: {
    width: 517,
    height: 640,
  },
  background: 'white' as const,
  radius: 'size-28' as const,
}

const duringProps = {
  size: 'full-screen' as const,
  background: 'white' as const,
}

const ReviewModal: React.FC<{
  courseId: CreateContentId
  folderId: FolderId | undefined
  closeModal: () => void
}> = ({ courseId, folderId, closeModal }) => {
  const [state, setState] = useState<AdaptiveState>({ status: 'loading', contentId: courseId, index: 0 })
  const reviewContextValue = useMemo(() => ({ state, setState }), [state, setState])
  const { postWithUserErrorException } = usePost()
  const { flexibleContentId, flexibleContent } = useSelfPacedFiles()
  const { shuffleQuestionsInDocument } = useQuestionShuffle()
  const isCourseCompleted = useSelector(state => selectIsCourseCompleted(state, flexibleContentId))
  const { t } = useTranslation()
  const [moduleTitle, setModuleTitle] = useState<string | undefined>(undefined)
  const me = useSelector(selectUser)

  const open = folderId !== undefined

  useEffect(() => {
    if (folderId === undefined) return
    void postWithUserErrorException(XRealtimeStrategySelfPacedContentAdaptiveElements, {
      courseId,
    }).then(({ adaptiveElements }) => {
      const review = adaptiveElements.reviews[folderId]

      if (review === undefined) {
        throw new Error('ReviewModal was flagged as open but did not return any elements.')
      }

      setState(initialiseReviewState(review, courseId))
    })
  }, [courseId, folderId, postWithUserErrorException])

  const modalProps = state.status === 'during' ? duringProps : startFinishedProps
  const element = Reviews.currentElement(state)
  const document: SlateRootElement[] = element !== undefined ? element.element : []
  const shuffledDocument = shuffleQuestionsInDocument(document)

  useEffect(() => {
    const handleKey = (evt: KeyboardEvent): void => {
      if (evt.key === 'ArrowRight' && element?.evaluation === true) {
        setState(Reviews.goToNextElement)
      }
      if (evt.key === 'Enter' && state.status === 'start') {
        setState(Reviews.start)
      }
    }

    window.addEventListener('keydown', handleKey)

    return () => {
      window.removeEventListener('keydown', handleKey)
    }
  }, [state.status, element?.evaluation])

  // get title of current modal
  useEffect(() => {
    if (folderId === undefined || flexibleContent === undefined) return

    const folder = flexibleContent.nodeMap[folderId]

    if (folder === undefined || folder.type !== 'folder') return

    setModuleTitle(folder.title)
  }, [flexibleContent, folderId])

  const assetContext: AssetContext = { type: 'course', courseId: flexibleContentId }

  return (
    <ReviewContextProvider value={reviewContextValue}>
      <AdaptiveModal open={open} onClose={closeModal} hideBorder={state.status !== 'start'} {...modalProps}>
        {state.status === 'loading' && (
          <View grow justifyContent='center' alignItems='center'>
            <LoadingSpinner />
          </View>
        )}
        {state.status === 'start' && (
          <StartAdaptiveModal
            animationType='prs'
            iconId='inspection'
            topbar={t('content.review.review')}
            heading={t('content.review.modal-title')}
            onStart={() => setState(Reviews.start)}
            closeModal={closeModal}
            moduleTitle={moduleTitle}
            buttonText={t('content.adaptive.start')}
          >
            <View padding='xsmall' paddingBottom='medium' gap='none'>
              <Text color='LEGACY_DEFAULT_TEXT_COLOR_REPLACE_ASAP' size='regular' align='center'>
                {t('content.review.start')}
              </Text>
            </View>
            <View gap='none'>
              <Text color='grey40' size='small'>
                {t('content.review.review-learnings', { count: state.elements.length })}
              </Text>
              <Icon color='grey40' iconId='radio-button--dot' size='size-12' />
              <Text color='grey40' bold size='small'>
                {t('content.review.remember-better')}
              </Text>
            </View>
          </StartAdaptiveModal>
        )}
        {state.status === 'during' && (
          <DuringAdaptiveModal
            onContinue={() => setState(Reviews.goToNextElement)}
            hideContinue={element?.evaluation !== true}
            closeModal={closeModal}
            total={state.elements.length}
            index={state.index}
            moduleTitle={moduleTitle}
            iconId='inspection'
            topbar={t('content.review.review')}
            tooltip={t('content.review.tooltip')}
          >
            <StaticEditor
              mode='review'
              document={shuffledDocument}
              assetContext={assetContext}
              file={undefined}
            />
          </DuringAdaptiveModal>
        )}
        {state.status === 'successful' && (
          <>
            <FinishedAdaptiveModal
              animationType='prs'
              iconId='inspection'
              topbar={t('content.review.review')}
              heading={
                isCourseCompleted
                  ? t('content.prs.end-course.title')
                  : me?.firstName !== undefined
                    ? t('content.prs.completed.title', { name: me.firstName })
                    : t('content.placement-test.great-job')
              }
              text={t('content.review.finished')}
              onContinue={closeModal}
              moduleTitle={moduleTitle}
              buttonText={
                isCourseCompleted ? t('content.adaptive.back-to-home') : t('content.adaptive.continue')
              }
            />
            {isCourseCompleted && (
              <BottomContainer direction='column' alignItems='center' padding='none small' gap='none'>
                <Separator />
                <View padding='small none' gap='none'>
                  <BottomContainerText color='grey25' size='small' bold onClick={closeModal}>
                    {t('content.review.view-content')}
                  </BottomContainerText>
                </View>
              </BottomContainer>
            )}
          </>
        )}
      </AdaptiveModal>
    </ReviewContextProvider>
  )
}

export const Review: React.FC = () => {
  const { flexibleContentId, currentReview, setCurrentReview } = useSelfPacedFiles()

  return (
    <ShuffleElementsProvider>
      <ReviewModal
        key={currentReview}
        courseId={flexibleContentId}
        folderId={currentReview}
        closeModal={() => setCurrentReview(undefined)}
      />
    </ShuffleElementsProvider>
  )
}
