import { UseQueryResult } from '@tanstack/react-query'
import { queryClient } from 'sierra-client/api/query-client'
import { getInsightsQueryOptions } from 'sierra-client/features/insights/api-hooks/query-options'
import { cleanFilter } from 'sierra-client/features/insights/utils'
import { logger } from 'sierra-client/logger/logger'
import { getCachedQueryKey, typedPost, useCachedQuery } from 'sierra-client/state/api'
import {
  DimensionRef,
  InsightsQueryRequest,
  InsightsQueryResponse,
  SortBy,
  ViewRef,
  Widget,
  areViewRefsEqual,
} from 'sierra-domain/api/insights'
import { DomainRep } from 'sierra-domain/filter/datatype/domain'
import { XAnalyticsQuery } from 'sierra-domain/routes'
import { assertNever } from 'sierra-domain/utils'

const defaultSort = (widget: Widget): SortBy => {
  switch (widget.type) {
    case 'widget.list-table': {
      if (areViewRefsEqual(widget.view, ViewRef.parse({ type: 'view.native.users' }))) {
        return [{ column: { type: 'column.dimension', dimension: widget.dimensions[0] }, order: 'asc' }]
      } else {
        return [{ column: { type: 'column.dimension', dimension: widget.dimensions[0] }, order: 'desc' }]
      }
    }
    case 'widget.line-chart':
      return [{ column: { type: 'column.dimension', dimension: widget.dimensions[0] }, order: 'asc' }]
    default:
      return [{ column: { type: 'column.measure', measure: widget.measures[0] }, order: 'desc' }]
  }
}

export const toQueryRequest = (widget: Widget): InsightsQueryRequest => {
  const sortBy = 'sortBy' in widget ? widget.sortBy ?? defaultSort(widget) : defaultSort(widget)

  switch (widget.type) {
    case 'widget.aggregation-table':
      return {
        query: {
          type: 'query.drill-across',
          measures: widget.measures,
          dimensions: widget.dimensions,
          filter: widget.filter,
          sortBy,
          limit: widget.limit,
          timeFilter: widget.timeFilter,
        },
      }
    case 'widget.bar-chart':
      // note: it probably makes sense to use a pivot query when doin a stacked bar chart
      // and the drill across query when doing bar chart with multiple bars
      return {
        query: {
          type: 'query.drill-across',
          measures: widget.measures,
          dimensions: widget.dimensions.slice(0, 1), //TODO: Actually pass correct dimensions
          filter: widget.filter,
          sortBy: widget.sortBy,
          limit: widget.limit,
        },
      }
    case 'widget.line-chart':
      return {
        query: {
          type: 'query.drill-across',
          measures: widget.measures,
          dimensions: widget.dimensions.slice(0, 1), //TODO: Actually pass correct dimensions
          filter: widget.filter,
          sortBy,
          limit: widget.limit,
          timeFilter: widget.timeFilter,
        },
      }
    case 'widget.metric':
    case 'widget.progress-bar':
      return {
        query: {
          type: 'query.drill-across',
          measures: widget.measures,
          dimensions: [],
          filter: widget.filter,
        },
      }
    case 'widget.pivot-table': {
      const rowDimension: DimensionRef = widget.dimensions[0]
      const columnDimension = widget.dimensions[1]
      if (columnDimension === undefined) {
        throw new Error('No column dimension found')
      }
      return {
        query: {
          type: 'query.pivot',
          measure: widget.measures[0],
          rowDimension,
          columnDimension,
          // TODO: Add sorting here sortBy: widget.sortBy,
          filter: widget.filter,
        },
      }
    }
    case 'widget.list-table': {
      return {
        query: {
          type: 'query.table',
          view: widget.view,
          dimensions: widget.dimensions,
          filter: widget.filter,
          sortBy,
          timeFilter: widget.timeFilter,
          limit: widget.limit,
        },
      }
    }
    default:
      assertNever(widget)
  }
}

export const cleanRequestFilter = (
  request: InsightsQueryRequest,
  allFilterDomainReps: DomainRep[]
): InsightsQueryRequest => {
  if (request.query.filter !== undefined) {
    const cleanedFilter = cleanFilter(request.query.filter, allFilterDomainReps)
    return { ...request, query: { ...request.query, filter: cleanedFilter } }
  }

  return request
}

type UseQueryAnalyticsDataProps = {
  widget: Widget
  allFilterDomainReps: DomainRep[]
}

export const useQueryAnalyticsData = ({
  widget,
  allFilterDomainReps,
}: UseQueryAnalyticsDataProps): UseQueryResult<InsightsQueryResponse> => {
  const request = toQueryRequest(widget)
  const requestWithCleanedFilter = cleanRequestFilter(request, allFilterDomainReps)

  return useCachedQuery(XAnalyticsQuery, requestWithCleanedFilter, {
    ...getInsightsQueryOptions<InsightsQueryResponse>(),
    staleTime: 5 * 60 * 1000,
    meta: {
      feature: 'insights',
      tags: { feature: 'insights' },
      reactComponent: 'DataViewer',
      widget,
      queryRequest: request,
    },
  })
}

export const forceRefreshQueryAnalyticsData = async (widget: Widget): Promise<void> => {
  const request = toQueryRequest(widget)
  const queryKey = getCachedQueryKey(XAnalyticsQuery, request)

  try {
    await queryClient.prefetchQuery({
      queryKey,
      queryFn: () => typedPost(XAnalyticsQuery, { ...request, forceRefresh: true }),
      staleTime: 0,
    })
  } catch (error) {
    logger.captureError(error, {
      tags: { feature: 'insights' },
      reactComponent: 'forceRefreshQueryAnalyticsData',
      widget,
      queryRequest: request,
    })
  }
}
