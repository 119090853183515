import React, { FC, ReactNode } from 'react'
import { config } from 'sierra-client/config/global-config'
import { useIsFurtherAuthenticationRequired } from 'sierra-client/config/use-is-further-authentication-required'
import { PageIdentifier, SanaPage } from 'sierra-client/layout/sana-page'
import { useSelector } from 'sierra-client/state/hooks'
import { selectIsGuestUser, selectIsInitializing } from 'sierra-client/state/user/user-selector'
import { Authenticate } from 'sierra-client/views/authentication/flows/authenticate'
import { ExternalAuthenticate } from 'sierra-client/views/authentication/flows/external-authenticate'

type Options = {
  allowScorm?: boolean
  allowGuestAccess?: boolean
}

type Props = {
  children?: ReactNode
} & Options

const NativeRequireLoggedIn: FC<Props> = ({ children, allowScorm = false, allowGuestAccess = false }) => {
  const isInitializing = useSelector(selectIsInitializing)
  const isGuestUser = useSelector(selectIsGuestUser)

  const isAuthenticationRequired = useIsFurtherAuthenticationRequired()

  if (isInitializing)
    return (
      <SanaPage mode='dark' page={PageIdentifier.Empty({ message: 'Initializing NativeRequiredLoggedIn' })} />
    )

  if (config.scorm.isScorm && !allowScorm) throw new Error('SCORM is not supported for this location')
  if (isGuestUser === 'guest' && !allowGuestAccess)
    throw new Error('Guest access not supported for this location')

  return isAuthenticationRequired ? (
    <SanaPage mode='dark' page={PageIdentifier.AuthenticationLogin()}>
      {config.scorm.isScormNative ? <ExternalAuthenticate /> : <Authenticate />}
    </SanaPage>
  ) : (
    <>{children}</>
  )
}

export const requireLoggedIn =
  <T extends Record<string, unknown>>(
    Component: React.ComponentType<T>,
    options: Options = {}
  ): React.ComponentType<T> =>
  (props1: T) => {
    return (
      <NativeRequireLoggedIn {...options}>
        <Component {...props1} />
      </NativeRequireLoggedIn>
    )
  }
