/* eslint-disable react/forbid-dom-props */
import { useMemo } from 'react'
import { XYCoord, useDragLayer } from 'react-dnd'
import { DragItem } from 'sierra-client/components/common/dnd/dnd-types'
import { useResolveAsset } from 'sierra-client/hooks/use-resolve-asset'
import { useSafeFlexibleContentId } from 'sierra-client/views/v3-author/hooks'
import { Text } from 'sierra-ui/primitives'
import { token } from 'sierra-ui/theming'
import styled, { CSSProperties, ThemeProvider, createGlobalStyle, css } from 'styled-components'

const VerticalOverlay = styled.div<{
  $width: number | undefined
  $height: number | undefined
  $backgroundImageSrc: string | undefined
}>`
  background-color: ${token('foreground/primary')};
  border-radius: 12px;
  padding: 24px;
  width: ${p => (p.$width !== undefined ? `${p.$width}px` : '100%')};
  height: ${p => (p.$height !== undefined ? `${p.$height}px` : ' 300px')};
  ${p =>
    p.$backgroundImageSrc !== undefined &&
    css`
      color: white;
      background: url(${p.$backgroundImageSrc});
      background-position: center;
      background-size: cover;
    `}
`

const HorizontalOverlay = styled.div<{
  $height: number | undefined
  $width: number | undefined
}>`
  display: flex;
  align-items: center;
  gap: 8px;
  background-color: ${token('foreground/primary')};
  border-radius: 12px;
  padding: 24px;
  width: ${p => (p.$width !== undefined ? `${p.$width}px` : '100%')};
  height: ${p => (p.$height !== undefined ? `${p.$height}px` : ' 50px')};
`

const Image = styled.img`
  object-fit: cover;
  object-position: center center;
  background-color: ${token('surface/soft')};
  position: relative;
  width: 40px;
  height: 40px;
  border-radius: 8px;
`

const DragOverlayContainer = styled.div`
  position: fixed;
  pointer-events: none;
  z-index: 100;
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
`

const GlobalStyleWhileDragging = createGlobalStyle`
	* {
		user-select: none;
    cursor: grabbing !important;
	}
`

function getItemStyles(initialOffset: XYCoord | null, currentOffset: XYCoord | null): CSSProperties {
  if (!initialOffset || !currentOffset) {
    return {
      display: 'none',
    }
  }

  const { x, y } = currentOffset

  const transform = `translate(${x}px, ${y}px)`
  return {
    transform,
    WebkitTransform: transform,
  }
}

export const CustomDragPollAlternativeOverLay: React.FC = () => {
  const { isDragging, item, initialOffset, currentOffset } = useDragLayer<{
    isDragging: boolean
    item: DragItem
    initialOffset: XYCoord | null
    currentOffset: XYCoord | null
  }>(monitor => ({
    item: monitor.getItem(),
    initialOffset: monitor.getInitialClientOffset(),
    currentOffset: monitor.getClientOffset(),
    isDragging: monitor.isDragging(),
  }))

  const flexibleContentId = useSafeFlexibleContentId()
  const assetContext = useMemo(
    () =>
      flexibleContentId !== undefined
        ? { type: 'course' as const, courseId: flexibleContentId }
        : { type: 'unknown' as const },
    [flexibleContentId]
  )

  const resolvedImageSrc = useResolveAsset({
    image: isDragging && item.type === 'poll-alternative' ? item.image : undefined,
    assetContext,
    size: 'course-sm',
  })

  if (!isDragging) return null
  if (item.type !== 'poll-alternative') return null

  const imageSrc = item.image !== undefined ? resolvedImageSrc : undefined

  return (
    <ThemeProvider theme={item.theme}>
      <DragOverlayContainer contentEditable={false}>
        <div style={getItemStyles(initialOffset, currentOffset)}>
          {item.styling.direction === 'row' ? (
            <VerticalOverlay
              $width={item.styling.width}
              $height={item.styling.height}
              $backgroundImageSrc={imageSrc}
            >
              {item.firstTextNode !== undefined && (
                <Text size='regular' color='surface/default'>
                  {item.firstTextNode}
                </Text>
              )}
            </VerticalOverlay>
          ) : (
            <HorizontalOverlay $width={item.styling.width} $height={item.styling.height}>
              {item.image !== undefined && <Image src={imageSrc} />}
              {item.firstTextNode !== undefined && (
                <Text size='regular' color='surface/default'>
                  {item.firstTextNode}
                </Text>
              )}
            </HorizontalOverlay>
          )}
        </div>
        <GlobalStyleWhileDragging />
      </DragOverlayContainer>
    </ThemeProvider>
  )
}
