import { useEffect } from 'react'
import { CSVLink } from 'react-csv'
import { useInView } from 'react-intersection-observer'
import {
  TableInstance,
  TableOptions,
  useFilters,
  useGlobalFilter,
  usePagination,
  useRowSelect,
  useSortBy,
  useTable,
} from 'react-table'
import { TableBulkActions } from 'sierra-client/components/table/select'
import { Table, TableProps, TableTr } from 'sierra-client/components/table/table'
import { useTranslation } from 'sierra-client/hooks/use-translation'
import {
  ActionButton,
  InfiniteScrollMessage,
  TableContainer,
} from 'sierra-client/views/manage/components/common'
import { ExportCSVButton, getCsvFileName } from 'sierra-client/views/manage/components/export-csv'
import { useFEInfinitePagination } from 'sierra-client/views/manage/hooks/use-fe-infinite-pagination'
import { AvatarContainer } from 'sierra-ui/components'
import styled, { css } from 'styled-components'

type UseManageTableLargeProps<E extends Record<string, unknown>> = {
  tableOptions: TableOptions<E>
  getEntityId: (entity: E) => string
}

type UseManageTableLargeData<E extends Record<string, unknown>> = {
  tableInstance: TableInstance<E>
  selectedIds: string[]
}

export const useManageTableLarge = <E extends Record<string, unknown>>({
  tableOptions,
  getEntityId,
}: UseManageTableLargeProps<E>): UseManageTableLargeData<E> => {
  const tableInstance = useTable<E>(
    {
      autoResetGlobalFilter: false,
      autoResetSortBy: false,
      ...tableOptions,
    },
    useFilters,
    useGlobalFilter,
    useSortBy,
    usePagination,
    useRowSelect
  )

  const selectedIds = tableInstance.selectedFlatRows.map(r => r.original).map(getEntityId)

  return {
    tableInstance,
    selectedIds,
  }
}

type ManageTableLargeProps<E extends Record<string, unknown>> = {
  tableInstance: TableInstance<E>
  isLoading?: boolean
  hasAvatarColumn?: boolean
  lastHighlightedIndex?: number
  focusedId?: string
  bulkActions?: JSX.Element
  translations: {
    tableLoading: string
    tableEnd: string
    tableNoResults: string
    csvPrefix: string
  }
  renderSubComponent?: TableProps<E>['renderSubComponent']
  getEntityId: (entity: E) => string
  onViewDetails?: (id: string) => void
  mapEntityToCsv?: (entity: E) => Record<string, unknown>
}

const TableContainerWrapper = styled(TableContainer)<{ hasAvatarColumn: boolean }>`
  ${p =>
    p.hasAvatarColumn &&
    css`
      ${TableTr} {
        &:hover {
          ${AvatarContainer} {
            opacity: 0;
            pointer-events: none;
          }
        }
      }
    `};
`

const DownloadContainer = styled.div`
  display: flex;
  justify-content: flex-end;
`

export const ManageTableLarge = <E extends Record<string, unknown>>({
  tableInstance,
  isLoading = false,
  hasAvatarColumn = false,
  focusedId,
  bulkActions,
  translations,
  renderSubComponent,
  getEntityId,
  onViewDetails,
  mapEntityToCsv,
  lastHighlightedIndex,
}: ManageTableLargeProps<E>): JSX.Element => {
  const { t } = useTranslation()
  const [inViewRef, shouldLoadMore] = useInView({ threshold: 0 })

  const { hasMore, nextPage, resetPage } = useFEInfinitePagination(tableInstance)

  useEffect(() => {
    if (shouldLoadMore && !isLoading) {
      nextPage()
    }
  }, [tableInstance.state.pageSize, nextPage, isLoading, shouldLoadMore])

  return (
    <TableContainerWrapper hasAvatarColumn={hasAvatarColumn}>
      {mapEntityToCsv !== undefined && (
        <DownloadContainer>
          <ExportCSVButton
            color='secondary'
            fetchCsvData={() => tableInstance.rows.map(r => r.original).map(mapEntityToCsv)}
            filename={translations.csvPrefix}
          />
        </DownloadContainer>
      )}

      <Table
        tableInstance={tableInstance}
        renderSubComponent={renderSubComponent}
        getCellProps={() =>
          hasAvatarColumn
            ? {
                style: {
                  paddingLeft: 0,
                },
              }
            : {}
        }
        getRowProps={row => ({
          onClick: onViewDetails === undefined ? undefined : () => onViewDetails(getEntityId(row.original)),
          isFocused: getEntityId(row.original) === focusedId,
          $isLastHighlightedItem: row.index === lastHighlightedIndex,
        })}
        getHeaderProps={column => ({
          onClick: () => {
            if (!column.canSort) return
            resetPage()
          },
        })}
        headerOverride={
          tableInstance.selectedFlatRows.length > 0 ? (
            <TableBulkActions tableInstance={tableInstance}>
              {mapEntityToCsv !== undefined && (
                <CSVLink
                  data={tableInstance.selectedFlatRows.map(r => r.original).map(mapEntityToCsv)}
                  filename={getCsvFileName(translations.csvPrefix)}
                >
                  <ActionButton color='blueBright'>
                    {t('manage.export')} {'.csv'}
                  </ActionButton>
                </CSVLink>
              )}
              {bulkActions}
            </TableBulkActions>
          ) : undefined
        }
      />
      {hasMore || isLoading ? (
        <InfiniteScrollMessage ref={inViewRef} text={translations.tableLoading} showSanaLogo />
      ) : (
        <InfiniteScrollMessage
          text={tableInstance.rows.length > 0 ? translations.tableEnd : translations.tableNoResults}
        />
      )}
    </TableContainerWrapper>
  )
}
