import { CellProps, HeaderProps, TableInstance } from 'react-table'
import { useTranslation } from 'sierra-client/hooks/use-translation'
import { FCC } from 'sierra-client/types'
import { ActionButton } from 'sierra-client/views/manage/components/common'
import { Checkbox, Spacer, Text } from 'sierra-ui/primitives'
import styled from 'styled-components'

const TableBulkActionsWrapper = styled.div`
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  padding-left: 3rem;

  z-index: 5;

  display: flex;
  align-items: center;
  justify-content: space-between;

  @media screen and (min-width: 94rem) {
    td {
      &:first-child::before {
        border-top-left-radius: 0;
        border-bottom-left-radius: 0;
      }

      &:last-child::after {
        border-top-right-radius: 0;
        border-bottom-right-radius: 0;
      }
    }
  }
`

const BulkContainer = styled.div`
  display: flex;
  align-items: center;
`

export const CheckBoxContainer = styled.div`
  position: absolute;
  top: -1px;
  left: -0.25rem;
  width: 2.5rem;
  height: calc(100% + 2px);
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: transparent;
  transition: background-color 0.2s ease;

  @media screen and (min-width: 94rem) {
    border-top-left-radius: 0.5rem;
    border-bottom-left-radius: 0.5rem;
  }
`

const Divider = styled.div`
  width: 1px;
  height: 0.75rem;
  background: ${p => p.theme.color.grey10};
`

export const SelectableRow = <T extends Record<string, unknown> = Record<string, unknown>>({
  selectedFlatRows,
  row,
  toggleRowSelected,
  className,
}: CellProps<T>): JSX.Element => {
  const isSelected = selectedFlatRows.find(r => r.id === row.id) !== undefined
  return (
    <CheckBoxContainer
      className={className}
      onClick={event => {
        event.stopPropagation()
      }}
    >
      <Checkbox
        checked={isSelected}
        onCheckedChange={() => {
          toggleRowSelected(row.id)
        }}
      />
    </CheckBoxContainer>
  )
}

export const SelectableHeader = <T extends Record<string, unknown> = Record<string, unknown>>({
  toggleAllRowsSelected,
  rows,
  selectedFlatRows,
  className,
}: Pick<HeaderProps<T>, 'toggleAllRowsSelected' | 'rows' | 'selectedFlatRows'> & {
  className?: string
}): JSX.Element => {
  const totalRows = rows.length
  const selectedCount = selectedFlatRows.length
  return (
    <CheckBoxContainer className={className}>
      <Checkbox
        checked={selectedCount > 0 && selectedCount < totalRows ? 'indeterminate' : selectedCount > 0}
        onCheckedChange={() => toggleAllRowsSelected()}
      />
    </CheckBoxContainer>
  )
}

export const TableBulkActions: FCC<{
  tableInstance: TableInstance<any>
  search?: JSX.Element
  hideSelect?: boolean
  className?: string
}> = ({ children, tableInstance, search = null, hideSelect = false, className }) => {
  const { t } = useTranslation()

  const selectedRows = tableInstance.selectedFlatRows
  const hasSelection = selectedRows.length > 0

  return (
    <TableBulkActionsWrapper className={className}>
      {!hideSelect && <SelectableHeader {...tableInstance} />}
      {hasSelection ? (
        <BulkContainer>
          <Text color='LEGACY_DEFAULT_TEXT_COLOR_REPLACE_ASAP' size='small'>
            {t('table.n-selected', { count: selectedRows.length })}
          </Text>
          <Spacer size='medium' />
          {children}
          <Spacer size='xxsmall' />
          <Divider />
          <Spacer size='xxsmall' />
          <ActionButton color='redBright' onClick={() => tableInstance.toggleAllRowsSelected(false)}>
            {t('cancel')}
          </ActionButton>
        </BulkContainer>
      ) : (
        <Spacer size='small' />
      )}
      {search !== null && <BulkContainer>{search}</BulkContainer>}
    </TableBulkActionsWrapper>
  )
}
