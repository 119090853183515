import React from 'react'
import { AnimationContainer } from 'sierra-client/features/insights/widget-builder/data-selectors/animation-container'
import { WidgetBuilderState } from 'sierra-client/features/insights/widget-builder/widget-builder-state'
import { WidgetDimensionSelector } from 'sierra-client/features/insights/widget-builder/widget-dimension-selector'
import { useTranslation } from 'sierra-client/hooks/use-translation'
import { DimensionRef, MeasureRef, ViewRef } from 'sierra-domain/api/insights'
import { Text, View } from 'sierra-ui/primitives'

export const WidgetDimensionContainer: React.FC<{
  selectedView?: ViewRef
  selectedMeasures: MeasureRef[]
  selectedDimensions: DimensionRef[]
  visualisation: WidgetBuilderState['visualisation']
  onChange: (dimensions: DimensionRef[]) => void
}> = ({ selectedView, selectedMeasures, selectedDimensions, visualisation, onChange }) => {
  const { t } = useTranslation()

  return (
    <AnimationContainer direction='column' gap='8' layoutId='dimension-container'>
      <View justifyContent='space-between'>
        <Text color='foreground/primary' bold capitalize='first'>
          {visualisation.type === 'table' && visualisation.table.tableType === 'list'
            ? t('manage.insights.widgetbuilder.attribute')
            : t('manage.insights.widgetbuilder.breakdown')}
        </Text>
      </View>

      <WidgetDimensionSelector
        key={JSON.stringify(selectedView)} // To reset all dimension selections when setting a new view
        selectedView={selectedView}
        selectedMeasures={selectedMeasures}
        selectedDimensions={selectedDimensions}
        visualisation={visualisation}
        onChange={onChange}
      />
    </AnimationContainer>
  )
}
