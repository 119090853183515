import { zodResolver } from '@hookform/resolvers/zod'
import _ from 'lodash'
import React from 'react'
import { Controller, useFieldArray, useForm } from 'react-hook-form'
import { SkillId, SkillLevelId, SkillLevelSettingId } from 'sierra-client/api/graphql/branded-types'
import { useTranslation } from 'sierra-client/hooks/use-translation'
import { FormInput, FormTextArea } from 'sierra-client/views/manage/shared/form'
import { Icon, Popover, SkillIconId, Tooltip } from 'sierra-ui/components'
import { Button, IconButton, ScrollView, Text, View } from 'sierra-ui/primitives'
import { spacing } from 'sierra-ui/theming'
import styled from 'styled-components'
import { z } from 'zod'
import { useTracking } from '../../tracking'

const UpsertSkillLevel = z.object({
  description: z.string().min(1, { message: 'Describe the level requirements.' }),
  skillLevelSettingId: SkillLevelSettingId,
  skillLevelId: SkillLevelId.optional(),
  name: z.string().min(1),
})

const Schema = z.object({
  id: SkillId.optional(),
  name: z.string().min(1, { message: 'Give the skill a name.' }),
  description: z.string().min(1, { message: 'Describe the skill.' }),
  levels: z.array(UpsertSkillLevel),
  icon: SkillIconId,
})
export type FormData = z.infer<typeof Schema>

const Form = styled.form`
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  gap: ${spacing['small']};
`

const PickerContainer = styled(View)`
  width: 304px;
  height: fit-content;
  max-height: 500px;
  flex-wrap: wrap;
  gap: 0px;
  padding: ${spacing[8]};
`

const TriggerButtonContainer = styled(View)`
  & * {
    height: 40px;
  }
`

const Clickable = styled.div`
  display: flex;
  align-items: center;
  gap: ${spacing[8]};
  cursor: pointer;
`

const IconPicker: React.FC<{ value: SkillIconId; onChange: (_: SkillIconId) => void }> = ({
  value,
  onChange,
}) => {
  const { t } = useTranslation()
  const [open, setOpen] = React.useState(false)
  return (
    <Popover
      align='end'
      isOpen={open}
      onOpenChange={setOpen}
      renderTrigger={() => (
        <TriggerButtonContainer>
          <Tooltip title={t('skills.form.tooltip.icon.description')}>
            <Clickable>
              <IconButton type='button' iconId={value} variant='secondary' />
              <Icon iconId='edit' color='foreground/muted' />
            </Clickable>
          </Tooltip>
        </TriggerButtonContainer>
      )}
    >
      <PickerContainer>
        {SkillIconId.options.map(iconId => (
          <IconButton
            key={iconId}
            iconId={iconId}
            onClick={() => {
              setOpen(false)
              onChange(iconId)
            }}
            variant='transparent'
          />
        ))}
      </PickerContainer>
    </Popover>
  )
}

const Grid = styled(View)`
  display: grid;
  grid-template-columns: 1fr max-content;
  grid-template-areas:
    'label .'
    'input icon';
`

const GridArea = styled(View)<{ area: string }>`
  grid-area: ${p => p.area};
  align-self: start;
`

const FormBottom = styled(View)`
  display: flex;
  flex-direction: column;
  margin-top: auto;
  margin-left: auto;
  padding: 8px 40px;
`
const ButtonWrapper = styled(View)`
  margin-top: auto;
  margin-left: auto;
`

type Props = {
  value: FormData
  onCancel: () => void
  onSubmit: (data: FormData) => void
  loading?: boolean
  hasActiveAIEngine: boolean
}

export const SkillForm: React.FC<Props> = ({ value, onCancel, onSubmit, loading, hasActiveAIEngine }) => {
  const { t } = useTranslation()
  const tracking = useTracking()
  const handler = useForm<FormData>({
    resolver: zodResolver(Schema),
    mode: 'onSubmit',
    values: value,
  })

  const levelsHandler = useFieldArray({ control: handler.control, name: 'levels' })

  const { isLoading, errors } = handler.formState

  return (
    <Form onSubmit={handler.handleSubmit(onSubmit)}>
      <ScrollView direction='column' gap='48' paddingLeft='40' paddingRight='40'>
        <View direction='column' gap='xsmall'>
          <Grid>
            <GridArea area='label'>
              <Text bold>{t('dictionary.name')}</Text>
            </GridArea>
            <GridArea area='input'>
              <FormInput
                placeholder={t('skills.form.placeholder.name')}
                name='name'
                control={handler.control}
                autoComplete={false}
              />
            </GridArea>
            <GridArea area='icon'>
              <Controller
                control={handler.control}
                name='icon'
                render={({ field }) => (
                  <IconPicker
                    value={field.value}
                    onChange={iconId => {
                      field.onChange(iconId)
                      tracking.skill.setIcon({ skillId: value.id, iconId: iconId })
                    }}
                  />
                )}
              />
            </GridArea>
          </Grid>
          <FormTextArea
            placeholder={t('skills.form.placeholder.description')}
            label={t('dictionary.description')}
            name='description'
            control={handler.control}
            rows={3}
            autoExpand
          />
        </View>
        <View direction='column' gap='small'>
          <View direction='column' gap='4'>
            <Text capitalize='first' bold color='foreground/primary'>
              {t('skills.level-description.title')}
            </Text>
            <Text capitalize='first' color='foreground/secondary'>
              {hasActiveAIEngine
                ? t('skills.level-description.ai-active-description')
                : t('skills.level-description.description')}
            </Text>
          </View>
          <View direction='column' gap='xsmall'>
            {levelsHandler.fields.map((field, index) => (
              <FormTextArea
                placeholder={t('skills.form.placeholder.level-description', {
                  levelDescription: field.name,
                })}
                key={field.id}
                label={field.name}
                labelColor='foreground/primary'
                name={`levels.${index}.description`}
                control={handler.control}
                rows={3}
                autoExpand
              />
            ))}
          </View>
        </View>
      </ScrollView>
      <FormBottom gap='xsmall'>
        {errors.icon && (
          <Text size='micro' color={'destructive/background'}>
            {t('skills.form.error.required.icon')}
          </Text>
        )}
        <ButtonWrapper>
          <Button type='button' onClick={onCancel} variant='secondary'>
            {t('dictionary.cancel')}
          </Button>
          <Button loading={isLoading || loading} type='submit'>
            {_.capitalize(t('dictionary.save'))}
          </Button>
        </ButtonWrapper>
      </FormBottom>
    </Form>
  )
}
