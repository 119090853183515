import { isChrome, isDesktop, isEdgeChromium } from 'react-device-detect'

/**
 * True if browser supports getDisplayMedia()
 * https://caniuse.com/mdn-api_mediadevices_getdisplaymedia
 */
const supportGetDisplayMedia: boolean =
  typeof window !== 'undefined' &&
  'navigator' in window &&
  'mediaDevices' in navigator &&
  'getDisplayMedia' in navigator.mediaDevices

/**
 * True if browser should allow session recording
 */
export const supportRecording: boolean = isDesktop && supportGetDisplayMedia

/**
 * True if browser supports getDisplayMedia() with audio capture
 * https://caniuse.com/mdn-api_mediadevices_getdisplaymedia_audio_capture_support
 */
export const supportAudioCapture: boolean = isDesktop && (isChrome || isEdgeChromium)
