import React from 'react'
import { useTranslation } from 'sierra-client/hooks/use-translation'
import { FCC } from 'sierra-client/types'
import { RoundedSearchBar } from 'sierra-client/views/manage/components/rounded-search-bar'
import { Text, View } from 'sierra-ui/primitives'
import { ConditionalWrapper } from 'sierra-ui/utils/conditional-wrapper'
import styled from 'styled-components'

type SmallTableHeaderProps = {
  rightAddon?: React.ReactNode
  title?: string
  subtitle?: React.ReactNode
  totalCount?: number
  hideHeadlines?: boolean
} & (
  | {
      withSearch?: false
    }
  | { withSearch: true; query: string | undefined; setQuery: (query: string) => void }
)

const Headlines = styled(View).attrs({
  direction: 'column',
})``

const PillAlignment = styled(View).attrs({
  direction: 'row',
  alignItems: 'center',
  justifyContent: 'flex-start',
})``

const Actions = styled(View).attrs({
  direction: 'row',
  alignItems: 'center',
  justifyContent: 'space-between',
})``

const Count = styled(View).attrs({
  radius: 'size-28',
  background: 'grey5',
  color: 'black',
  paddingLeft: '6',
  paddingRight: '6',
})``

export const SmallTableWrapper: FCC<SmallTableHeaderProps> = props => {
  const { children, title, subtitle, totalCount, rightAddon, hideHeadlines } = props
  const { t } = useTranslation()

  const actions =
    rightAddon !== undefined ? (
      <Actions>
        {props.withSearch === true && (
          <RoundedSearchBar
            value={props.query}
            onChange={props.setQuery}
            placeholder={t('manage.search.users')}
          />
        )}
        {rightAddon}
      </Actions>
    ) : (
      <></>
    )

  return hideHeadlines !== undefined && hideHeadlines ? (
    <></>
  ) : (
    <View direction='column' gap='6' marginBottom='8' marginTop='32'>
      <Headlines>
        <ConditionalWrapper
          condition={props.withSearch !== true && rightAddon !== undefined}
          renderWrapper={input => (
            <View justifyContent='space-between' alignItems='center' direction='row'>
              {input} {actions}
            </View>
          )}
        >
          <PillAlignment>
            {title !== undefined && (
              <Text size='large' bold>
                {title}
              </Text>
            )}
            {totalCount !== undefined && (
              <Count>
                <Text color='foreground/primary' size='micro' bold>
                  {totalCount}
                </Text>
              </Count>
            )}
          </PillAlignment>
        </ConditionalWrapper>
        {subtitle !== undefined && (
          <Text size='small' color='foreground/muted' spacing='xxsmall'>
            {subtitle}
          </Text>
        )}
      </Headlines>
      {props.withSearch === true && <>{actions}</>}
      {children}
    </View>
  )
}
