import { textBlockStylesV2 } from 'sierra-client/views/author/components/editor/editor-styles-v2'
import { v2_breakpoint } from 'sierra-ui/theming/breakpoints'
import { fonts, h1Lesson, h2Lesson, h3Lesson, h4Lesson } from 'sierra-ui/theming/fonts'
import styled from 'styled-components'

export const Prose = styled.div`
  ${textBlockStylesV2};

  th,
  td,
  p,
  li {
    ${fonts.body.regular};

    @media screen and (min-width: ${v2_breakpoint.phone}) {
      font-size: 1.125rem;
    }
  }

  && {
    h1 {
      ${h1Lesson}
    }

    h2 {
      ${h2Lesson}
    }

    h3 {
      ${h3Lesson}
    }

    h4 {
      ${h4Lesson}
    }
  }

  * > table {
    max-width: 100%;
    overflow-x: auto;
  }

  table {
    margin-bottom: 1.5rem;

    th,
    td {
      min-width: 8rem;
      padding: 0.5rem;
      border: 1px solid ${p => p.theme.home.textColor};
    }

    th {
      text-align: left;
      white-space: nowrap;
      color: ${p => p.theme.home.textColor};

      &[align='center'] {
        text-align: center;
      }

      &[align='right'] {
        text-align: right;
      }
    }
  }
`
