import { useDropAWordData } from 'sierra-client/views/v3-author/drop-a-word/drop-a-word-data-layer'
import { DropAWord } from 'sierra-client/views/v3-author/drop-a-word/renderer/drop-a-word'
import { DropAWordData } from 'sierra-domain/flexible-content/types'

export const SelfPacedDropAWordCard: React.FC<{
  fileData: DropAWordData
}> = ({ fileData }) => {
  const data = useDropAWordData()

  return <DropAWord title={fileData.title} onTitleChanged={undefined} data={data} />
}
