import { keepPreviousData } from '@tanstack/react-query'
import React, { useCallback } from 'react'
import { graphql } from 'sierra-client/api/graphql/gql'
import { UsersByIdsQuery } from 'sierra-client/api/graphql/gql/graphql'
import { convertGQLAvatar } from 'sierra-client/api/graphql/util/convert-gql-avatar'
import { graphQuery, useGraphQuery } from 'sierra-client/api/hooks/use-graphql-query'
import { IdentitiesSelector, Identity } from 'sierra-client/components/common/identities-selector'
import { useTranslation } from 'sierra-client/hooks/use-translation'
import { Context } from 'sierra-client/lib/filter/components/common'
import {
  ValueAnchor,
  addPredValue,
  fromPredicate,
  removePredValue,
} from 'sierra-client/lib/filter/components/predicate-utils'
import { UserId } from 'sierra-domain/api/uuid'
import { Predicate } from 'sierra-domain/filter/datatype/pred'
import { setPred } from 'sierra-domain/filter/operations'
import { isDefined } from 'sierra-domain/utils'
import { FormElement } from 'sierra-ui/components'

const usersQuery = graphql(`
  query UserAutoComplete($query: String!, $limit: Int!) {
    users(query: $query, limit: $limit) {
      data {
        id
        displayName
        email
        avatar {
          ...AvatarFragment
        }
      }
    }
  }
`)

const usersByIdsQuery = graphql(`
  query UsersByIds($ids: [UserId!]!) {
    users: usersFromIds(ids: $ids) {
      id
      displayName
      email
      avatar {
        ...AvatarFragment
      }
    }
  }
`)

const queryUsers = async (query: string): Promise<Identity[]> => {
  const response = await graphQuery(usersQuery, { query, limit: 100 })

  return response.users.data.map(user => {
    return {
      id: user.id,
      identity: {
        type: 'user',
        id: user.id,
      },
      name: user.displayName,
      email: user.email,
      avatar: convertGQLAvatar(user.avatar),
    }
  })
}

const useUsersFromPredicate = (predicate: Predicate): UsersByIdsQuery['users'] => {
  const userIds = fromPredicate(predicate)
    // TODO: Using the UserId type for 'value.user-id' caused type errors in the recursive Filter type.
    //  Seemed like a bug in zod, but I don't know. For now we'll cast the value here.
    .map(value => (value.type === 'value.user-id' ? (value.value as UserId) : undefined))
    .filter(isDefined)

  const response = useGraphQuery(
    {
      document: usersByIdsQuery,
      queryOptions: {
        enabled: userIds.length !== 0,
        staleTime: 60 * 1000,
        placeholderData: keepPreviousData,
      },
    },
    { ids: userIds }
  )

  if (userIds.length === 0) {
    return []
  }

  return response.data?.users ?? []
}

const useLabelsForUserPredicate = (predicate: Predicate): string[] => {
  const users = useUsersFromPredicate(predicate)
  const labels = users.map(it => it.displayName)
  return labels
}

const useQueryUsersByIds = (predicate: Predicate): Identity[] => {
  const users = useUsersFromPredicate(predicate)

  return users
    .map(user => {
      return {
        id: user.id,
        identity: {
          type: 'user' as const,
          id: user.id,
        },
        name: user.displayName,
        email: user.email,
        avatar: convertGQLAvatar(user.avatar),
      }
    })
    .filter(isDefined)
}

type UserAutoCompleteProps = {
  ctx: Context
  predicate: Predicate
}
export const UserAutoComplete: React.FC<UserAutoCompleteProps> = ({ ctx, predicate }) => {
  const selectedUsers = useQueryUsersByIds(predicate)
  const { t } = useTranslation()

  const onSelect = useCallback(
    (user: Identity) => {
      ctx.update(f => setPred(f, addPredValue(predicate, { type: 'value.user-id', value: user.id })))
    },
    [ctx, predicate]
  )

  const onUnselect = useCallback(
    (user: Identity) => {
      ctx.update(f => setPred(f, removePredValue(predicate, { type: 'value.user-id', value: user.id })))
    },
    [ctx, predicate]
  )

  return (
    <FormElement label={t('dictionary.user-plural')}>
      <IdentitiesSelector
        selectedIdentities={selectedUsers}
        onSelect={onSelect}
        onUnselect={onUnselect}
        fetchIdentities={queryUsers}
        placeholder='manage.search.users'
      />
    </FormElement>
  )
}

export const UserAutocompleteLabel = React.forwardRef<HTMLDivElement, { predicate: Predicate }>(
  ({ predicate, ...rest }, ref) => {
    const labels = useLabelsForUserPredicate(predicate)
    const label = labels.join(', ')

    return <ValueAnchor {...rest} ref={ref} label={label} />
  }
)
