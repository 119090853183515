import React, { useContext, useEffect, useState } from 'react'
import { config } from 'sierra-client/config/global-config'
import { LiveSessionContext } from 'sierra-client/context/live-session-context'
import { Logging } from 'sierra-client/core/logging'
import { usePathname } from 'sierra-client/hooks/router/use-pathname'
import { getGlobalRouter } from 'sierra-client/router'
import { useDispatch, useSelector } from 'sierra-client/state/hooks'
import {
  selectIsDisabled,
  selectIsInitializing,
  selectIsLoggedIn,
  selectIsPendingCodeVerification,
  selectIsPendingEmailVerification,
  selectIsRegistrationActive,
  selectRegistrationStep,
} from 'sierra-client/state/user/user-selector'
import { AuthenticationLayout } from 'sierra-client/views/authentication/authentication-layout'
import { AuthMode, AuthView } from 'sierra-client/views/authentication/flows/types'
import { LiveGuestAccess } from 'sierra-client/views/authentication/native/auth-views/live-guest-access'
import { ResetPassword } from 'sierra-client/views/authentication/native/auth-views/reset-password'
import { SignIn } from 'sierra-client/views/authentication/native/auth-views/sign-in'
import { SignUp } from 'sierra-client/views/authentication/native/auth-views/sign-up'
import { SignUpWithEmail } from 'sierra-client/views/authentication/native/auth-views/sign-up-with-email'
import { UserDisabled } from 'sierra-client/views/authentication/native/auth-views/user-disabled'
import { VerifyCode } from 'sierra-client/views/authentication/native/auth-views/verify-code'
import { VerifyEmail } from 'sierra-client/views/authentication/native/auth-views/verify-email'
import { AuthenticationContainer } from 'sierra-client/views/authentication/native/components/common'
import { AccountDetailsSetup } from 'sierra-client/views/authentication/native/registration-steps/account-details-setup'
import { SelectNewSkills } from 'sierra-client/views/authentication/native/registration-steps/select-new-skills-setup'
import { SkillsSetup } from 'sierra-client/views/authentication/native/registration-steps/skills-setup'
import { UserRegistrationStep } from 'sierra-domain/user'
import { assertNever } from 'sierra-domain/utils'
import { ConditionalWrapper } from 'sierra-ui/utils/conditional-wrapper'

function computeAuthView({
  isLoggedIn,
  mode,
  registrationStep,
  isPendingEmailVerification,
  isPendingCodeVerification,
  isDisabled,
}: {
  isLoggedIn: boolean
  mode: AuthMode
  registrationStep: UserRegistrationStep | undefined
  isPendingEmailVerification: boolean
  isPendingCodeVerification: boolean
  isDisabled: boolean
}): AuthView {
  if (isPendingCodeVerification) return 'sign-in-verify-code'
  if (isLoggedIn) {
    if (isDisabled) return 'user-disabled'
    switch (registrationStep) {
      case 'account-details':
        return 'sign-up-account-details'
      case 'skills':
        return 'sign-up-skills'
      case 'select-new-skills':
        return 'sign-up-select-new-skills'
      // These below will not be handled in this switch-case
      case 'done':
        break
      case undefined:
        break
      default:
        assertNever(registrationStep)
    }
    if (isPendingEmailVerification) {
      return 'sign-up-verify-email'
    }
    return 'none'
  }
  return mode
}

export const Authenticate: React.FC<unknown> = () => {
  const dispatch = useDispatch()
  const isLoggedIn = useSelector(selectIsLoggedIn)
  const isInitializing = useSelector(selectIsInitializing)
  const isRegistrationActive = useSelector(selectIsRegistrationActive)
  const registrationStep = useSelector(selectRegistrationStep)
  const isPendingEmailVerification = useSelector(selectIsPendingEmailVerification)
  const isPendingCodeVerification = useSelector(selectIsPendingCodeVerification)
  const isDisabled = useSelector(selectIsDisabled)
  const pathname = usePathname()
  const [mode, setMode] = useState<AuthMode>('sign-in')
  const [email, setEmail] = useState('')
  const orgConfig = config.organization
  const liveConfig = useContext(LiveSessionContext)

  const view = computeAuthView({
    isLoggedIn,
    mode,
    registrationStep,
    isPendingEmailVerification,
    isPendingCodeVerification,
    isDisabled,
  })

  useEffect(() => {
    if (!isInitializing && view !== 'none' && view !== 'user-disabled') {
      void dispatch(
        Logging.auth.authImpression({
          step: view,
        })
      )
    }
  }, [view, dispatch, isInitializing])

  useEffect(() => {
    if (isLoggedIn && !isRegistrationActive && !isPendingEmailVerification && pathname === '/login') {
      void getGlobalRouter().navigate({ to: '/' })
    }
  }, [pathname, isLoggedIn, isRegistrationActive, isPendingEmailVerification])

  const isRegistrationOpen = orgConfig.registration === 'open'

  const switchToSignUpWithEmail = (email: string): void => {
    setEmail(email)
    setMode('sign-up-with-email')
  }

  const switchToSignIn = (): void => {
    setMode('sign-in')
  }

  const switchToSignUp = isRegistrationOpen ? () => setMode('sign-up') : undefined

  const setModeLiveGuestAccess = (): void => setMode('live-guest-access')
  const switchToLiveGuestAccess =
    liveConfig?.meta.allowGuestAccess === true ? setModeLiveGuestAccess : undefined

  const views: Record<AuthView, JSX.Element | null> = {
    'sign-in': (
      <SignIn
        switchToForgotPassword={() => setMode('reset-password')}
        switchToSignUp={switchToSignUp}
        switchToLiveGuestAccess={switchToLiveGuestAccess}
      />
    ),
    'reset-password': (
      <ResetPassword switchToLogIn={() => setMode('sign-in')} switchToSignUp={switchToSignUp} />
    ),
    'sign-up-verify-email': <VerifyEmail />,
    'user-disabled': <UserDisabled />,
    'sign-up': (
      <SignUp
        switchToSignIn={switchToSignIn}
        switchToSignUpWithEmail={email => switchToSignUpWithEmail(email)}
        switchToLiveGuestAccess={switchToLiveGuestAccess}
      />
    ),
    'sign-up-with-email': <SignUpWithEmail email={email} switchToSignUp={() => setMode('sign-up')} />,
    'sign-up-account-details': <AccountDetailsSetup />,
    'sign-up-skills': <SkillsSetup />,
    'sign-up-select-new-skills': <SelectNewSkills />,
    'sign-in-verify-code': <VerifyCode />,
    'live-guest-access': <LiveGuestAccess />,
    'none': null,
  }

  const inner = views[view]

  return inner !== null ? (
    <AuthenticationLayout>
      <ConditionalWrapper
        condition={view !== 'sign-up-skills'}
        renderWrapper={children => <AuthenticationContainer>{children}</AuthenticationContainer>}
      >
        {inner}
      </ConditionalWrapper>
    </AuthenticationLayout>
  ) : null
}
