import React, { useEffect } from 'react'
import { postWithUserErrorException } from 'sierra-client/state/api'
import { store } from 'sierra-client/state/store'
import { FCC } from 'sierra-client/types'
import { useSetCardProgress } from 'sierra-client/views/flexible-content/progress-tracking/set-progress-provider'
import { useAssessmentContext } from 'sierra-client/views/v3-author/assessment-card/assessment-context'
import { EditorMode } from 'sierra-client/views/v3-author/slate'
import { ScopedFileId } from 'sierra-domain/collaboration/types'
import { Entity } from 'sierra-domain/entity'
import { XRealtimeStrategySelfPacedContentAssessmentReportAssessmentStatus } from 'sierra-domain/routes'
import { AssessmentCard } from 'sierra-domain/v3-author'

type AssessmentCardDataLayer =
  | {
      empty: true
    }
  | undefined

const FallbackContext = React.createContext<AssessmentCardDataLayer | undefined>(undefined)
const SelfPacedContext = React.createContext<AssessmentCardDataLayer | undefined>(undefined)

const SelfPacedDataProvider: FCC<{ element: Entity<AssessmentCard> }> = ({ children }) => {
  const { setCardStarted, setCardCompleted } = useSetCardProgress()
  const { state } = useAssessmentContext()

  useEffect(() => {
    if (state.status === 'start') {
      setCardStarted()
    }
  }, [state.status, setCardStarted])

  useEffect(() => {
    if (state.status === 'successful') {
      setCardCompleted()
      void postWithUserErrorException(
        XRealtimeStrategySelfPacedContentAssessmentReportAssessmentStatus,
        {
          contentId: state.contentId,
          fileId: ScopedFileId.extractId(state.fileId),
          status: 'passed',
        },
        store.dispatch
      )
    }
  }, [state, setCardCompleted])

  useEffect(() => {
    if (state.status === 'failed') {
      void postWithUserErrorException(
        XRealtimeStrategySelfPacedContentAssessmentReportAssessmentStatus,
        {
          contentId: state.contentId,
          fileId: ScopedFileId.extractId(state.fileId),
          status: 'failed',
        },
        store.dispatch
      )
    }
  }, [state, setCardCompleted])

  return <SelfPacedContext.Provider value={undefined}>{children}</SelfPacedContext.Provider>
}

const FallbackDataProvider: FCC<{ element: Entity<AssessmentCard> }> = ({ children }) => {
  return <FallbackContext.Provider value={undefined}>{children}</FallbackContext.Provider>
}

export const AssessmentDataProvider: FCC<{ element: Entity<AssessmentCard>; mode: EditorMode }> = ({
  mode,
  element,
  children,
}) => {
  if (mode === 'self-paced')
    return <SelfPacedDataProvider element={element}>{children}</SelfPacedDataProvider>
  else return <FallbackDataProvider element={element}>{children}</FallbackDataProvider>
}
