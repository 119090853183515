import { UseQueryResult } from '@tanstack/react-query'
import { CachedQueryOptions, prefetchCachedQuery, useCachedQuery } from 'sierra-client/state/api'
import { RecommendationsResponse } from 'sierra-domain/api/recommendations'
import { ZodRouteInput } from 'sierra-domain/api/types'
import { XRealtimeStrategyRecommend2 } from 'sierra-domain/routes'

const staleTime = 1 * 60 * 1000 // 1 min (? what makes sense here? Can probably go pretty high)

export const recommendationQueryOptions = {
  // Because of the refetchOnWindowFocus setting, these queries can end up running pretty often.
  // Since they can be a bit expensive on the backend we'll set a stale time to reduce the
  // issue a bit.
  staleTime,
  refetchOnMount: 'always', // We always want to refetch if navigating away and back
} satisfies CachedQueryOptions

const defaultInput = { limit: 20 } as const satisfies ZodRouteInput<typeof XRealtimeStrategyRecommend2>

export const prefetchRecommendations = (): Promise<void> => {
  return prefetchCachedQuery(XRealtimeStrategyRecommend2, defaultInput, { staleTime })
}

export function useRecommendations({
  enabled = true,
}: {
  enabled?: boolean
} = {}): UseQueryResult<RecommendationsResponse> {
  return useCachedQuery(XRealtimeStrategyRecommend2, defaultInput, {
    ...recommendationQueryOptions,
    enabled,
  })
}
