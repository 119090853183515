import { SkillId, SkillLevelSettingId, SkillRecommendationId } from 'sierra-client/api/graphql/branded-types'
import {
  Content,
  Course,
  SkillLevelSettingInput,
  SkillsSettingsInput,
} from 'sierra-client/api/graphql/gql/graphql'
import { Logger } from 'sierra-client/core/logging/logger'
import type { BadgeIconId, BadgeTheme } from 'sierra-client/features/skills'
import { CourseId } from 'sierra-domain/api/nano-id'
import { SkillIconId } from 'sierra-ui/components'

type Identifier = `skill_${string}`

export const createNewSkill = Logger.trackLogger<never>('skill_created' satisfies Identifier)
export const updateSkill = Logger.trackLogger<{ skillId: string; levelDescriptionLengths: number[] }>(
  'skill_updated' satisfies Identifier
)
export const subscribeLearnerToSkill = Logger.trackLogger<{ skillId: string; learnerId: string }>(
  'skill_subscribe_learner' satisfies Identifier
)

export const unsubscribeLearnerFromSkill = Logger.trackLogger<{ skillId: string; learnerId: string }>(
  'skill_unsubscribe_learner' satisfies Identifier
)

export const setTargetLevel = Logger.trackLogger<{ skillId: string; learnerId: string; level: string }>(
  'skill_set_target_level_learner' satisfies Identifier
)

export const setAchievedLevel = Logger.trackLogger<{ skillId: string; learnerId: string; level: string }>(
  'skill_set_achieved_level_learner' satisfies Identifier
)

export const bulkEditSubscribers = Logger.trackLogger<{
  targetLevel?: SkillLevelSettingId
  achievedLevel?: SkillLevelSettingId
}>('skill_bulk_edit_subscribers' satisfies Identifier)

export const pickSkillIcon = Logger.trackLogger<{ skillId?: SkillId; icon: SkillIconId }>(
  'skill_set_icon' satisfies Identifier
)

export const enterBadgeHover = Logger.trackLogger<{ theme: BadgeTheme; iconId: BadgeIconId }>(
  'skill_badge_enter_hover' satisfies Identifier
)

export const leaveBadgeHover = Logger.trackLogger<{ theme: BadgeTheme; iconId: BadgeIconId }>(
  'skill_badge_leave_hover' satisfies Identifier
)

export const clickBadge = Logger.trackLogger<{ theme: BadgeTheme; iconId: BadgeIconId }>(
  'skill_badge_click' satisfies Identifier
)

export const clickSkillActionMenu = Logger.trackLogger<{ skillId: SkillId; action: string }>(
  'skill_action_menu_click' satisfies Identifier
)

export const clickManageTab = Logger.trackLogger<{ skillId: SkillId }>(
  'skill_manage_tab_click' satisfies Identifier
)

export const clickManageSkillSubscriptions = Logger.trackLogger<never>(
  'skill_manage_skill_subscriptions_click' satisfies Identifier
)

export const subscribeSelf = Logger.trackLogger<{ skillId: SkillId }>(
  'skill_self_subscribe' satisfies Identifier
)

export const unsubscribeSelf = Logger.trackLogger<{ skillId: SkillId }>(
  'skill_self_unsubscribe' satisfies Identifier
)

export const updateFastTrack = Logger.trackLogger<{ contentId: string; skillId: SkillId; enabled: boolean }>(
  'skill_update_fast_track' satisfies Identifier
)

export const addContent = Logger.trackLogger<{
  skillId: SkillId
  content: { contentType: Content['contentType']; courseKind?: Course['courseKind'] }[]
}>('skill_add_content' satisfies Identifier)

export const removeContent = Logger.trackLogger<{ contentId: string; skillId: SkillId }>(
  'skill_remove_content' satisfies Identifier
)

export const updateSettings = Logger.trackLogger<{
  settings: SkillsSettingsInput
  levels: Pick<SkillLevelSettingInput, 'name' | 'defaultBadgeTheme'>[]
}>('skill_org_update_settings' satisfies Identifier)

export const updateLevel = Logger.trackLogger<{ contentId: string; level: SkillLevelSettingId }>(
  'skill_update_content_level' satisfies Identifier
)

export const importSanaTaxonomy = Logger.trackLogger<{ taxonomies: Array<{ title: string }> }>(
  'skill_import_sana_taxonomies' satisfies Identifier
)

export const acceptSmartSuggestion = Logger.trackLogger<{ skillRecommendationId: SkillRecommendationId }>(
  'skill_smart_suggestion_accept' satisfies Identifier
)

export const rejectSmartSuggestion = Logger.trackLogger<{ skillRecommendationId: SkillRecommendationId }>(
  'skill_smart_suggestion_reject' satisfies Identifier
)

export const changeLevelSmartSuggestion = Logger.trackLogger<{
  skillId: SkillId
  newLevelSettingId?: SkillLevelSettingId
  prevLevelSettingId?: SkillLevelSettingId
}>('skill_smart_suggestion_change_level' satisfies Identifier)

export const retriggerSmartSuggestion = Logger.trackLogger<{ courseId: CourseId }>(
  'skill_smart_suggestion_retrigger' satisfies Identifier
)
