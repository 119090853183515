/* eslint-disable react/jsx-no-literals */
import SanaLogo from 'sierra-client/assets/images/logo.svg'
import { getAvatarImage } from 'sierra-client/utils/avatar-img'
import { AlltOmJuridikCertificate } from 'sierra-client/views/learner/certificate/certificates/alltomjuridik'
import { BrightsCertificate } from 'sierra-client/views/learner/certificate/certificates/brights'
import { MerckCertificate } from 'sierra-client/views/learner/certificate/certificates/merck'
import { SmartLearningCertificate } from 'sierra-client/views/learner/certificate/certificates/smartlearning'
import { SveaSolarCertificate } from 'sierra-client/views/learner/certificate/certificates/svea-solar'
import {
  BigPadding,
  BiggerPadding,
  CourseSubTitle,
  CourseTitle,
  DateStyled,
  GiantPadding,
  H1Medlearn,
  H2MedlearnRed,
  HemfridLogo,
  LogoKarolinska,
  LogoMedLearn,
  LogoNovartis,
  LogoSocialstyrelsen,
  LogoVNS,
  LogoWrap,
  LogoWrapMedLearn,
  MedLearnInfo,
  MediumPadding,
  MedlearnDetails,
  NVSLogo,
  NovartisContainer,
  NovartisCourseName,
  NovartisFooter,
  NovartisLineSeparator,
  NovartisLogo,
  NovartisWrap,
  OnBehalf,
  P,
  PMedLearn,
  PMedLearnLeft,
  PMedLearnLeftLine,
  PrintButton,
  SanaLogoWrap,
  SignatureMedLearn,
  SignatureWrapMedLearn,
  SmallSanaLogo,
  TempHeading,
  UserName,
  Wrap,
  WrapButtons,
  WrapMedLearn,
} from 'sierra-client/views/learner/certificate/components'
import { CertificateProps } from 'sierra-client/views/learner/certificate/types'
import {
  courseIdsForAlltOmJuridik,
  courseIdsForSmartLearning,
  getFormattedCompletionDate,
  getImageUrl,
  handlePrint,
} from 'sierra-client/views/learner/certificate/utils'
import { FlexContainer } from 'sierra-client/views/learner/components/overview/common'
import { createNanoId12FromUUID } from 'sierra-domain/nanoid-extensions'
import { ORGANIZATION_IDS } from 'sierra-domain/organization'
import { RoundAvatar } from 'sierra-ui/components'
import { Heading, Spacer, Text } from 'sierra-ui/primitives'

export const Certificate = (props: CertificateProps): JSX.Element => {
  const { id, title, user, completionTimestamp } = props

  const formattedCompletionDate = getFormattedCompletionDate(completionTimestamp)

  if (user.organizationUuid === ORGANIZATION_IDS.AlltOmJuridik && courseIdsForAlltOmJuridik.has(id)) {
    return <AlltOmJuridikCertificate {...props} />
  }

  if (user.organizationUuid === ORGANIZATION_IDS.SmartLearning && courseIdsForSmartLearning.has(id)) {
    return <SmartLearningCertificate {...props} />
  }

  let body = <TempHeading>INVALID COURSE</TempHeading>

  if (id === createNanoId12FromUUID('51b974f9-e795-400f-906e-71c6a696eff2')) {
    // English 1
    body = (
      <>
        <CourseTitle>E-learning Course 1 on COVID-19</CourseTitle>
        <CourseSubTitle>
          Stop the spread of infection and protect personnel working in Swedish healthcare and social services
        </CourseSubTitle>
        <P>
          On behalf of the National Board of Health and Welfare, Karolinska Institutet has prepared two
          e-learning courses on COVID-19. The objective is to strengthen readiness and provide information on
          the most important principles and challenges involved in the work to prevent the spread of COVID-19.
          Focus is on principles of hygiene and the use of personal protective equipment (PPE).
        </P>
        <P bold>
          This is the first course. After completing this course, you should have a basic understanding and
          knowledge of COVID-19, and basic knowledge of routes of transmission, symptoms, groups at risk, and
          how to protect yourself and others in the best possible way. The course may be considered basic
          knowledge to be used in combination with local guidelines at your workplace.
        </P>
        <P>
          The course is aimed at all personnel within healthcare, social care, and social services within
          Region/County Council and Municipality, both medical staff and those otherwise affiliated with care
          activities.
        </P>
      </>
    )
  } else if (id === createNanoId12FromUUID('d02784d7-c12c-4546-aa6b-cee7fc2e8d64')) {
    // English 2
    body = (
      <>
        <CourseTitle>E-training 2 on COVID-19</CourseTitle>
        <CourseSubTitle>Triage and the use of personal protective equipment (PPE)</CourseSubTitle>
        <P>
          Karolinska Institutet has developed two COVID-19 e-trainings on behalf of the National Board of
          Health and Welfare. The purpose of the trainings is to strengthen preparedness and create a deeper
          understanding of the most important principles and challenges in the effort to prevent the spread of
          COVID-19. The focus is on infection control principles and the use of personal protective equipment.
        </P>
        <P bold>
          This is the second training. The purpose of this training is to review a number of important
          principles that apply when coming into contact with patients with suspected or confirmed COVID-19
          infection. After the training, you should have a basic understanding of some important principles
          that apply in performing your work in terms of the correct way to manage and treat this patient
          group. The knowledge you gain through the training should be used as a supplement to local
          guidelines.
        </P>
        <P>
          This training is intended for staff in healthcare, nursing and social services within the
          Region/County Council and Municipality, who may potentially work more closely with suspected or
          confirmed cases of COVID-19.
        </P>
      </>
    )
  } else if (id === createNanoId12FromUUID('3fd7afb9-fa64-4323-a7b5-e3f57c5db3d1')) {
    // Russian
    body = (
      <>
        <CourseTitle>онлайн-курс No 1 covid-19</CourseTitle>
        <CourseSubTitle>
          «Как предотвратить распространение инфекции и защитить сотрудников шведской системы здравоохранения
          и социальной службы»
        </CourseSubTitle>
        <P>
          Каролинский институт по заданию Социального управления подготовил два онлайн курса о covid-19. Цель
          – усилить готовность и дать знания о важнейших принципах и сложностях работы по предотвращению
          распространения covid-19. Фокус сделан на принципах гигиены и использования индивидуального
          защитного оборудования.
        </P>
        <P bold>
          Это первый курс. Цель этого курса–дать основополагающие знания и представления о covid-19. По
          окончании этого курса вы получите базовые знания о путях заражения, симптомах, группах риска, а
          также о том, как вам защитить себя и других наилучшим образом. Курс можно рассматривать как базовые
          знания, которые можно использовать в сочетании с местными инструкциями на вашем рабочем месте.
        </P>
        <P>
          Данный курс ориентирован на ВСЕХ сотрудников сферы здравоохранения и социальной службы регионов и
          муниципалитетов–как на тех из них, как те из них, кто выполняет непосредственно медицинскую работу,
          так и на тех, чья работа иным образом связана с деятельностью учреждений по лечению и уходу.
        </P>
      </>
    )
  } else if (id === createNanoId12FromUUID('2b5e7e7b-f986-4ed2-b59f-2f57928ab58f')) {
    // Arabic
    body = (
      <>
        <CourseTitle>التعليم الإلكتروني 1 حول موضوع كوفيد-19</CourseTitle>
        <CourseSubTitle>
          «منع انتشار العدوى وحماية العاملين في الرعاية الصحية والخدمات الاجتماعية السويدية»
        </CourseSubTitle>
        <P>
          بتكليف من المجلس الوطني للصحة والرعاية الاجتماعية قام معهد كارولينسكا بإعداد تأهيلين دراسيين حول
          كوفيد-19. والغرض من ذلك هو تعزيز التأهب وتقديم معرفة لأهم المبادئ والتحديات في العمل للحيلولة دون
          انتشار كوفيد-19 . هذا ويتّم التركيز على مبادئ النظافة الشخصية واستخدام معدات الحماية الشخصية.
        </P>
        <P bold>
          هذا هو التأهيل الدراسي الأول. بعد الانتهاء من التأهيل الدراسي يجب أن تكون قد حصلت على فهم أساسي
          ومعرفة حول كوفيد-19، ومعرفة أساسية بطرق انتشار العدوى والأعراض والمجموعات المعرّضة للخطر وكيفية
          حماية نفسك والآخرين على أفضل وجه. يمكن اعتبار هذا التأهيل الدراسي بمثابة معرفة أساسية لاستخدامها
          بالارتباط مع إرشاداتكم المحلية في مكان عملكم.
        </P>
        <P>
          يتوّجه هذا التأهيل الى جميع العاملين في مجالات الرعاية والعناية والخدمات الاجتماعية في الاقاليم
          والبلديات، سواء كانوا يعملون في الحقل الطبيّ أو يعملون بطرق أخرى ترتبط بنشاط الرعاية.
        </P>
      </>
    )
  } else if (id === createNanoId12FromUUID('9634b113-f2b9-4a49-8b5c-d4be79bfdfa4')) {
    // Persian
    body = (
      <>
        <CourseTitle>الکترونیکی ١ پیرامون covid-19 را گذرانده است</CourseTitle>
        <CourseSubTitle>
          از انتقال آلودگی جلوگیری نموده و از کارکنانی که در مراکز بهداشتی- درمانی و خدمات اجتماعی کار می‌کنند
          محافظت نمایید{' '}
        </CourseSubTitle>
        <P>
          انستیتو کارولینسکا به سفارش هیئت مدیره سازمان خدمات اجتماعی سوئد، دو دوره آموزشی دیجیتالی پیرامون
          covid-19 را ایجاد نموده است. هدف تقویت آمادگی و ارائه دانش درباره مهمترین اصول و چالش‌ها در تلاش‌های
          مربوط به جلوگیری از اشاعه covid-19 می‌باشد. اصول بهداشتی و استفاده از تجهیزات حفاظتی شخصی در کانون
          توجه قرار دارد.
        </P>
        <P bold>
          این دوره آموزشی اول می‌باشد. پس از گذراندن این دوره شما بایستی از یک درک مقدماتی پیرامون covid-19، و
          یک دانش پایه درباره راه‌های انتقال آلودگی، نشانه‌های بیماری، گروه‌های در خطر و همچنین نحوه محافظت از
          خود و دیگران به بهترین روش ممکن برخوردار باشید. این دوره آموزشی را می‌توان بعنوان دانستنی‌های بنیادی
          در نظر قرار داد که می‌توان از در ترکیب با موازین کلی محلی در محل کار خود، بکار بست.
        </P>
        <P>
          مخاطب این دوره آموزشی تمامی کادر درمانی، مراقبتی و خدمات اجتماعی در مناطق بهزیستی/ هیئت مدیره‌های
          استانی سوئد و کمون‌ها می‌باشند، هم افرادی که با امور پزشکی کار می‌کنند و هم دیگرانی که به روش‌‌های
          دیگر در پیوند با مراکز ارائه خدمات درمانی کار می‌کنند.
        </P>
      </>
    )
  } else if (id === createNanoId12FromUUID('a2c97b3b-83ff-4203-9b46-ba5843910e59')) {
    // Swedish 1
    body = (
      <>
        <CourseTitle>E-utbildning 1 om covid-19</CourseTitle>
        <CourseSubTitle>
          Hindra smittspridning och skydda personal som arbetar inom svensk hälso- och sjukvård och
          socialtjänst
        </CourseSubTitle>
        <P>
          Karolinska Institutet har på uppdrag av Socialstyrelsen tagit fram två e-utbildningar kring
          covid-19. Syftet är att stärka beredskap och ge kunskap om de viktigaste principerna och
          utmaningarna i arbetet att förhindra spridning av covid-19. Fokus ligger på hygienprinciper och
          användning av personlig skyddsutrustning.
        </P>
        <P bold>
          Detta är den första utbildningen. Efter genomgången utbildning ska du ha grundläggande förståelse
          och kunskap om covid-19, och en baskunskap kring smittvägar, symptom, riskgrupper samt hur du
          skyddar dig själv och andra på bästa sätt. Utbildningen kan ses som baskunskap att använda i
          kombination med era lokala riktlinjer på er arbetsplats.
        </P>
        <P>
          Utbildningen riktar sig till all personal inom vård, omsorg och socialtjänst inom Region/Landsting
          och Kommun, både de som arbetar medicinskt eller som på andra sätt arbetar i anslutning till
          vårdverksamheten.
        </P>
      </>
    )
  } else if (id === createNanoId12FromUUID('e4a01295-cf7d-4b12-9705-34795070b3ed')) {
    // Swedish 2
    body = (
      <>
        <CourseTitle>E-utbildning 2 om covid-19</CourseTitle>
        <CourseSubTitle>Triage och användande av personlig skyddsutrustning</CourseSubTitle>
        <P>
          Karolinska Institutet har på uppdrag av Socialstyrelsen tagit fram två e-utbildningar kring
          covid-19. Syftet är att stärka beredskap och ge kunskap om de viktigaste principerna och
          utmaningarna i arbetet att förhindra spridning av covid-19. Fokus ligger på hygienprinciper och
          användning av personlig skyddsutrustning.
        </P>
        <P bold>
          Detta är den andra utbildningen. Syftet med den här utbildningen är att gå igenom olika viktiga
          principer som gäller i kontakt med patienter med misstänkt eller bekräftad covid-19-infektion. Efter
          utbildningen ska du ha en baskunskap kring viktiga principer vid ditt arbete för korrekt hantering
          och behandling av denna patientgrupp och din kunskap ska användas som komplement till lokala
          riktlinjer.
        </P>
        <P>
          Utbildningen riktar sig till personal inom vård, omsorg och socialtjänst inom Region/Landsting och
          Kommun, som kan tänkas arbeta mera vårdnära med misstänkta eller bekräftade fall av covid-19.
        </P>
      </>
    )
  } else if (id === createNanoId12FromUUID('2d8a3845-c4e6-4ff4-92dd-60e2ac212f33')) {
    // Novartis (Probiotics – Linex)
    body = <></> // Configured below
  }

  if (id === 'NJ7vF7f3Qt84') {
    // Hemfrid English
    body = (
      <>
        <TempHeading>Course certificate</TempHeading>
        <UserName>
          {user.firstName} {user.lastName} has completed
        </UserName>
        <CourseTitle>{title}</CourseTitle>
        <MediumPadding />
        <P>
          In the Basic training, the participant has gone through how we at Hemfrid clean our customers&apos;
          homes, what detergents we use, our work materials and service.
        </P>
        <P bold>
          On behalf of Hemfrid, we sincerely hope that you enjoyed this course and that you feel well-equipped
          and inspired to make our customers even more satisfied with our services.
        </P>
        <MediumPadding />
        <DateStyled>{formattedCompletionDate}</DateStyled>
      </>
    )
  } else if (id === 'thtsJVBSGUJP') {
    // Hemfrid Swedish
    body = (
      <>
        <TempHeading>Kurscertifikat</TempHeading>
        <UserName>
          {user.firstName} {user.lastName} har avslutat
        </UserName>
        <CourseTitle>{title}</CourseTitle>
        <MediumPadding />
        <P>
          I grundutbildningen har deltagaren gått igenom hur vi på Hemfrid städar hemma hos våra kunder, vilka
          medel vi använder, vårt arbetsmaterial och service.
        </P>
        <P bold>
          Vi på Hemfrid hoppas innerligt att du tyckte om denna utbildning och att du känner dig välutrustad
          och inspirerad att göra våra kunder ännu nöjdare med våra tjänster.
        </P>
        <MediumPadding />
        <DateStyled>{formattedCompletionDate}</DateStyled>
      </>
    )
  } else if (id === 'EPBSSuZOQ6mn' || id === 'Olmmp2ygZ2nX') {
    body = (
      <>
        <NovartisWrap id='printwrapper'>
          <div>
            <NVSLogo src={getImageUrl('NVS-logo.png')} />
            <BigPadding />
            <Heading size='h1' color='grey35'>
              Certificate
              <br /> of completion
            </Heading>
            <Heading color='LEGACY_DEFAULT_HEADING_COLOR_REPLACE_ASAP' size='h1'>
              Strategic Imperatives
              <br /> Building
            </Heading>
            <Spacer size='medium' />
            <FlexContainer>
              {user.avatar !== undefined && (
                <>
                  <RoundAvatar
                    src={getAvatarImage(user.uuid, user.avatar)}
                    firstName={user.firstName}
                    lastName={user.lastName}
                    color={user.avatarColor}
                  />
                  <Spacer />
                </>
              )}
              <Text color='LEGACY_DEFAULT_TEXT_COLOR_REPLACE_ASAP' size='small'>
                {user.firstName} {user.lastName} has completed the course <br /> on Sana on{' '}
                {formattedCompletionDate}
              </Text>
            </FlexContainer>
          </div>
          <NovartisFooter>
            <NovartisLogo src={getImageUrl('novartis_logo_full.png')} />
            <SmallSanaLogo src={getImageUrl('logo25.png')} />
          </NovartisFooter>
        </NovartisWrap>
        <WrapButtons>
          <PrintButton variant='secondary' onClick={handlePrint}>
            Print or download
          </PrintButton>
        </WrapButtons>
      </>
    )
  }

  // TODO: Split this component into two: one based on course id, and one based on organization.
  // Otherwise there's a higher risk of missing a case.
  if (user.organizationUuid === ORGANIZATION_IDS.SveaSolar) {
    return <SveaSolarCertificate {...props} />
  }

  if (id === '8scxdDFJ1LLH') {
    return <BrightsCertificate {...props} />
  }

  // Merck
  if (id === 'JYsI1HGuolTX' || id === 'F8C-x9jyVEhM') {
    return <MerckCertificate {...props} />
  }

  if (
    id === createNanoId12FromUUID('51b974f9-e795-400f-906e-71c6a696eff2') ||
    id === createNanoId12FromUUID('d02784d7-c12c-4546-aa6b-cee7fc2e8d64')
  ) {
    // English
    return (
      <>
        <Wrap id='printwrapper'>
          <BigPadding />
          <TempHeading>Course certificate</TempHeading>
          <UserName>
            {user.firstName} {user.lastName} has completed
          </UserName>
          {body}
          <DateStyled>{formattedCompletionDate}</DateStyled>
          <LogoWrap>
            <LogoKarolinska src={getImageUrl('karolinska_logo_wide.png')} />
          </LogoWrap>
          <OnBehalf>
            On behalf of
            <LogoWrap>
              <LogoSocialstyrelsen src={getImageUrl('socialstyrelsen.png')} />
            </LogoWrap>
          </OnBehalf>
        </Wrap>
        <WrapButtons>
          <PrintButton variant='secondary' onClick={handlePrint}>
            Print or download
          </PrintButton>
        </WrapButtons>
      </>
    )
  } else if (id === createNanoId12FromUUID('3fd7afb9-fa64-4323-a7b5-e3f57c5db3d1')) {
    // Russian
    return (
      <>
        <Wrap id='printwrapper'>
          <MediumPadding />
          <TempHeading>Свидетельство об окончании курса</TempHeading>
          <UserName>
            {user.firstName} {user.lastName} окончил
          </UserName>
          {body}
          <DateStyled>{formattedCompletionDate}</DateStyled>
          <LogoWrap>
            <LogoKarolinska src={getImageUrl('karolinska_logo_wide.png')} />
          </LogoWrap>
          <OnBehalf>
            от имени
            <LogoWrap>
              <LogoSocialstyrelsen src={getImageUrl('socialstyrelsen.png')} />
            </LogoWrap>
          </OnBehalf>
        </Wrap>
        <WrapButtons>
          <PrintButton variant='secondary' onClick={handlePrint}>
            Print or download
          </PrintButton>
        </WrapButtons>
      </>
    )
  } else if (id === createNanoId12FromUUID('2b5e7e7b-f986-4ed2-b59f-2f57928ab58f')) {
    // Arabic
    return (
      <>
        <Wrap rtl id='printwrapper'>
          <BigPadding />
          <TempHeading>شهادة الدورة</TempHeading>
          <UserName>
            {user.firstName} {user.lastName} أكملَ/أكملت
          </UserName>
          {body}
          <DateStyled>{formattedCompletionDate}</DateStyled>
          <LogoWrap>
            <LogoKarolinska src={getImageUrl('karolinska_logo_wide.png')} />
          </LogoWrap>
          <OnBehalf>
            نيابة عن
            <LogoWrap>
              <LogoSocialstyrelsen src={getImageUrl('socialstyrelsen.png')} />
            </LogoWrap>
          </OnBehalf>
        </Wrap>
        <WrapButtons>
          <PrintButton variant='secondary' onClick={handlePrint}>
            Print or download
          </PrintButton>
        </WrapButtons>
      </>
    )
  } else if (id === createNanoId12FromUUID('9634b113-f2b9-4a49-8b5c-d4be79bfdfa4')) {
    // Persian
    return (
      <>
        <Wrap rtl id='printwrapper'>
          <BigPadding />
          <TempHeading>گواهی دوره</TempHeading>
          <UserName>
            {user.firstName} {user.lastName} کامل شده است
          </UserName>
          {body}
          <DateStyled>{formattedCompletionDate}</DateStyled>
          <LogoWrap>
            <LogoKarolinska src={getImageUrl('karolinska_logo_wide.png')} />
          </LogoWrap>
          <OnBehalf>
            <LogoWrap>
              <LogoSocialstyrelsen src={getImageUrl('socialstyrelsen.png')} />
            </LogoWrap>
            از طرف
          </OnBehalf>
        </Wrap>
        <WrapButtons>
          <PrintButton variant='secondary' onClick={handlePrint}>
            Print or download
          </PrintButton>
        </WrapButtons>
      </>
    )
  } else if (
    id === createNanoId12FromUUID('a2c97b3b-83ff-4203-9b46-ba5843910e59') ||
    id === createNanoId12FromUUID('e4a01295-cf7d-4b12-9705-34795070b3ed')
  ) {
    // Swedish
    return (
      <>
        <Wrap id='printwrapper'>
          <BigPadding />
          <TempHeading>Kursbevis</TempHeading>
          <UserName>
            {user.firstName} {user.lastName} har gått
          </UserName>
          {body}
          <DateStyled>{formattedCompletionDate}</DateStyled>
          <LogoWrap>
            <LogoKarolinska src={getImageUrl('karolinska_logo_wide.png')} />
          </LogoWrap>
          <OnBehalf>
            På uppdrag av
            <LogoWrap>
              <LogoSocialstyrelsen src={getImageUrl('socialstyrelsen.png')} />
            </LogoWrap>
          </OnBehalf>
        </Wrap>
        <WrapButtons>
          <PrintButton variant='secondary' onClick={handlePrint}>
            Print or download
          </PrintButton>
        </WrapButtons>
      </>
    )
  } else if (id === createNanoId12FromUUID('19332917-4c87-439b-8972-b904a9c75ddd')) {
    // Unlocking How You Learn
    return (
      <>
        <Wrap id='printwrapper'>
          <BigPadding />
          <SanaLogoWrap>
            <SanaLogo />
          </SanaLogoWrap>
          <TempHeading>Course certificate</TempHeading>
          <UserName>
            {user.firstName} {user.lastName} has completed
          </UserName>
          {body}
          <DateStyled>{formattedCompletionDate}</DateStyled>
        </Wrap>
        <WrapButtons>
          <PrintButton variant='secondary' onClick={handlePrint}>
            Print or download
          </PrintButton>
        </WrapButtons>
      </>
    )
  } else if (id === '-kq8IQl6OOad' || id === 'qoaLOT1MQOwh') {
    // Legacy UUID for "-kq8IQl6OOad": b9afd8e3-bf3d-4b70-9b71-71dbe549adf0
    // MedLearn
    return (
      <>
        <WrapMedLearn id='printwrapper'>
          <BiggerPadding />
          <LogoWrap>
            <LogoVNS src={getImageUrl('vns_logo.png')} />
          </LogoWrap>
          <TempHeading>
            <b>Intyg</b>
          </TempHeading>
          <CourseTitle>
            <i>
              för deltagade i kursen <br />
              Vårdnära Service
            </i>
          </CourseTitle>
          <UserName>
            <b>
              {user.firstName} {user.lastName}
            </b>
          </UserName>
          <PMedLearn>
            har {formattedCompletionDate} genomfört MedLearns digitala utbildning i vårdnära service.
          </PMedLearn>
          <PMedLearn>
            Utbildningen i vårdnära service syftar till att förbereda personal för arbete inom vård- och
            omsorgsnära tjänster med fokus på äldreboenden. Kursen innehåller följande moduler: introduktion,
            hygienrutiner, patientsäkerhet, lagar och förordningar, bemötande, sociala aktiviteter, lokalvård
            och livsmedelshantering.
          </PMedLearn>
          <SignatureWrapMedLearn>
            <SignatureMedLearn src={getImageUrl('medlearn_signature.jpg')} />
          </SignatureWrapMedLearn>
          <PMedLearnLeftLine />
          <PMedLearn>Sirpa Piirainen, Rektor Medlearn</PMedLearn>
          <MedLearnInfo>
            <LogoWrapMedLearn>
              <LogoMedLearn src={getImageUrl('medlearn_logo.png')} />
              <MedlearnDetails>
                <b>Medlearn AB</b>
                <br />
                Sveavägen 34, 6 tr, 111 34 Stockholm
                <br />
                Telefon +46 105 16 23 53
                <br />
                info@medlearn.se, www.medlearn.se
              </MedlearnDetails>
            </LogoWrapMedLearn>
          </MedLearnInfo>
          <GiantPadding />
          <H1Medlearn>Intensivutbildning för extrapersonal inom vård- och omsorg</H1Medlearn>
          <PMedLearnLeft>
            Medlearns tredagars intensivutbildning omfattar cirka 24 h aktiv studietid av både praktiska
            övningar och teori i form av filmer, text och kontrollfrågor. Kursen fokuserar på äldrevården och
            innehållet är kvalitetssäkrat av legitimerade lärare och sjuksköterskor på Medlearn. Efter
            avklarad kurs ska deltagaren kunna avlasta befintlig personal genom att självständigt utföra
            serviceinriktade uppgifter i vårdmiljö, som lokalvård och livsmedelshantering.
          </PMedLearnLeft>
          <H2MedlearnRed>Utbildningens innehåll</H2MedlearnRed>
          <PMedLearnLeft>
            Utbildningen är indelad i åtta kapitel. <br></br>Kapitel 1-4 handlar om basala hygienrutiner,
            patientsäkerhet, lagar och riktlinjer som är vägledande inom vården. Målet är att deltagarna ska
            ha grundläggande kunskaper om hygien, smittspridning, säkerhet och regler. De ska också ha en
            grundläggande förståelse för vad arbete inom vård och omsorg innebär.
          </PMedLearnLeft>
          <H2MedlearnRed>Bemötande, psykiskt välmående och social aktivering på distans</H2MedlearnRed>
          <PMedLearnLeft>
            Kapitel 5 och 6 fokuserar på bemötande, psykiskt välmående och social aktivering. Målet är att
            deltagarna ska förstå vilken betydelse bemötandet har för resultatet av vårdinsatser. De ska känna
            sig trygga i mötet med brukare och kunna avgöra när de behöver be om hjälp av en mer erfaren
            kollega. Här finns också en praktisk övning där deltagarna får träna på att sätta upp videosamtal
            och förslag på andra aktiviteter som passar att göra på distans.
          </PMedLearnLeft>
          <H2MedlearnRed>Lokalvård och livsmedelshantering</H2MedlearnRed>
          <PMedLearnLeft>
            De avslutande kapitlen, 7 och 8, handlar om lokalvård och livsmedelshantering. De ger en
            introduktion till arbetsteknik och livsmedelshygien samt grundläggande kunskaper i varumottagning
            och förvaring av livsmedel. <br></br>
            <br></br>För mer information om utbildningens innehåll se medlearn.se
          </PMedLearnLeft>
          <MedLearnInfo>
            <LogoWrapMedLearn>
              <LogoMedLearn src={getImageUrl('medlearn_logo.png')} />
              <MedlearnDetails>
                <b>Medlearn AB</b>
                <br />
                Sveavägen 34, 6 tr, 111 34 Stockholm
                <br />
                Telefon +46 105 16 23 53
                <br />
                info@medlearn.se, www.medlearn.se
              </MedlearnDetails>
            </LogoWrapMedLearn>
          </MedLearnInfo>
        </WrapMedLearn>
        <WrapButtons>
          <PrintButton variant='secondary' onClick={handlePrint}>
            Print or download
          </PrintButton>
        </WrapButtons>
      </>
    )
  } else if (id === createNanoId12FromUUID('2d8a3845-c4e6-4ff4-92dd-60e2ac212f33')) {
    // Novartis (Probiotics – Linex)
    return (
      <>
        <Wrap id='printwrapper'>
          <NovartisContainer>
            <BigPadding />
            <TempHeading>Certificate of Completion</TempHeading>
            <UserName>
              {user.firstName} {user.lastName}
            </UserName>
            <NovartisLineSeparator />
            <p>Successfully completed</p>
            <NovartisCourseName>{title}</NovartisCourseName>
            <DateStyled>Certificate date: {formattedCompletionDate}</DateStyled>
            <LogoNovartis src={getImageUrl('novartis_logo_full.png')} />
          </NovartisContainer>
        </Wrap>
        <WrapButtons>
          <PrintButton variant='secondary' onClick={handlePrint}>
            Print or download
          </PrintButton>
        </WrapButtons>
      </>
    )
  } else if (id === 'NJ7vF7f3Qt84' || id === 'thtsJVBSGUJP') {
    // Hemfrid
    return (
      <>
        <HemfridLogo src={'/static/images/hemfrid_logo.jpg'} />
        <Wrap id='printwrapper'>{body}</Wrap>
        <WrapButtons>
          <PrintButton variant='secondary' onClick={handlePrint}>
            Print or download
          </PrintButton>
        </WrapButtons>
      </>
    )
  } else {
    return body
  }
}
