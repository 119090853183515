class RequestChaosTest {
  private enabled: boolean = false
  private failureRate: number = 0.1

  isEnabled(): boolean {
    return this.enabled
  }

  enable(): void {
    this.enabled = true
  }

  disable(): void {
    this.enabled = false
  }

  run(url: string): void {
    if (!this.enabled) {
      return
    }

    const random = Math.floor(Math.random() * 100)

    if (random < this.failureRate * 100) {
      throw new Error(`Chaos Mode: Failure ${url}`)
    }
  }
}

export const requestChaosTest = new RequestChaosTest()
