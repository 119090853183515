import { useCallback, useMemo } from 'react'
import { AssetType, ImageSizeType, resolveNamespacedCourseImage } from 'sierra-client/api/content'
import { AssetContext } from 'sierra-domain/asset-context'
import { ImageUnion } from 'sierra-domain/content/v2/image-union'

export const useResolveAsset = ({
  image,
  size,
  assetContext,
  assetType,
}: {
  image: ImageUnion | undefined
  size: ImageSizeType
  assetContext: AssetContext
  assetType?: AssetType
}): string => {
  const url = useMemo(() => {
    return resolveNamespacedCourseImage(image, size, assetContext, assetType)
  }, [image, size, assetContext, assetType])
  return url
}

export const useResolveAssetWithoutFallback = ({
  image,
  size,
  assetContext,
  assetType,
}: {
  image: ImageUnion | undefined
  size: ImageSizeType
  assetContext: AssetContext
  assetType?: AssetType
}): string | undefined => {
  const url = useMemo(() => {
    if (image === undefined) {
      return undefined
    }

    return resolveNamespacedCourseImage(image, size, assetContext, assetType)
  }, [image, size, assetContext, assetType])

  return url
}

export type AssetResolver = ReturnType<typeof useAssetResolver>

export const useAssetResolver = ({
  size,
}: {
  size: ImageSizeType
}): ((image: ImageUnion | undefined, assetContext: AssetContext) => string) => {
  return useCallback(
    (image: ImageUnion | undefined, assetContext: AssetContext) => {
      return resolveNamespacedCourseImage(image, size, assetContext)
    },
    [size]
  )
}
