/* eslint-disable @typescript-eslint/no-unused-vars */
/* eslint-disable @typescript-eslint/no-explicit-any */
import { Awareness } from 'y-protocols/awareness'
import * as Y from 'yjs'

export function createFakeAwareness(doc: Y.Doc): Awareness {
  return {
    doc,
    clientID: 0,
    states: new Map(),
    meta: new Map(),
    _checkInterval: undefined,
    _observers: new Map(),
    getLocalState: function (): { [x: string]: any } | null {
      return {}
    },
    getStates: function (): Map<number, { [x: string]: any }> {
      return this.states
    },
    setLocalState: function (_state: { [x: string]: any } | null): void {},
    setLocalStateField: function (_field: string, _value: any): void {},
    once: function (_name: unknown, _f: () => void): void {},
    on: function (_name: unknown, f: () => void) {},
    off: function (_name: unknown, _f: () => void): void {},
    emit: function (_name: unknown, _args: any[]): void {},
    destroy: function (): void {},
  }
}
