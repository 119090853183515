import { Editor, EditorContent } from '@tiptap/react'
import { useEffect } from 'react'
import { token } from 'sierra-ui/theming'
import { fonts } from 'sierra-ui/theming/fonts'
import styled, { css } from 'styled-components'

const placeholderCss = css`
  pointer-events: none;
  color: ${token('foreground/muted')};
  white-space: nowrap;
`

const TaggedContentEditor = styled(EditorContent)`
  color: ${token('foreground/primary')};
  width: 100%;
  max-width: 100%;
  display: block;
  white-space: nowrap !important;
  pointer-events: all;
  padding-left: 6px;

  .ProseMirror {
    .tag-type-content {
      ${fonts.body.micro};
      font-weight: 500;

      display: inline-flex;
      flex-direction: row;
      justify-content: flex-start;
      align-items: center;

      padding: 4px 6px;
      border-radius: 6px;
      background-color: ${token('form/background/2')};

      & img {
        border-radius: 3px;
        width: 24px; /* 3:2 ratio */
        height: 16px;
        object-fit: cover;
      }

      & > div {
        height: 16px;
        width: 24px;
        display: flex;
        align-items: center;
        justify-content: center;
        position: relative;
        background-color: ${token('form/background/1')};
        border-radius: 3px;
      }

      & > span {
        padding-left: 4px;
      }
    }

    .tag-type-user {
      ${fonts.body.micro};
      font-weight: 500;

      display: inline-flex;
      flex-direction: row;
      justify-content: flex-start;
      align-items: center;

      padding: 4px 6px;
      border-radius: 6px;
      background-color: ${token('form/background/2')};

      & > div {
        border-radius: 50%;
        height: 16px;
        width: 16px;
        display: flex;
        align-items: center;
        justify-content: center;
        position: relative;

        & > span {
          font-size: 7px;
          color: white;
          position: absolute;
          line-height: 0;
        }
      }

      & img {
        border-radius: 50%;
        width: 16px;
        height: 16px;
        object-fit: cover;
      }

      & > span {
        padding-left: 4px;
      }
    }

    & div:first-of-type:first-letter {
      text-transform: uppercase;
    }

    &[contenteditable='false'] {
      color: ${token('foreground/muted')};
    }
  }

  .ProseMirror div.is-editor-empty:first-child::before {
    color: ${token('foreground/muted')};
    content: attr(data-placeholder);
    height: 0;
    float: left;
    ${placeholderCss}
  }
`

export const InsightsContentSearch: React.FC<{
  editor: Editor | null
  disabled?: boolean
  className?: string
}> = ({ editor, className, disabled }) => {
  /* Sync disabled prop with tiptap's internal editable state */
  useEffect(() => {
    editor?.setEditable(!(disabled === true))
  }, [disabled, editor])

  return <TaggedContentEditor editor={editor} className={className} />
}
