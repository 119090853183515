import _ from 'lodash'
import { PermissionOverrides } from 'sierra-client/components/shortcut-menu/permissions-inspector/types'
import { PermissionKey } from 'sierra-client/hooks/use-permissions/types'
import { ALL_PERMISSIONS } from 'sierra-domain/api/user'

export const isPermissionKey = (key: string): key is PermissionKey => key in ALL_PERMISSIONS

export const permissionOptions = ALL_PERMISSIONS

// Initialize with all permissions enabled
export const initializePermissionOverrides = (): PermissionOverrides => _.cloneDeep(permissionOptions)

export const permissionCategoryLabels: { [key in PermissionKey]: string } = {
  'path': 'Path permissions',
  'organization': 'Organization permissions',
  'teamspace': 'Teamspace permissions',
  'self-paced': 'Self-paced permissions',
  'live': 'Live permissions',
  'external-course': 'External course permissions',
  'event-group': 'Event group permissions',
  'course-group': 'Course group permissions',
  'live-session': 'Live session permissions',
  'calendar-event': 'Calendar event permissions',
  'calendar-event-enrollment-request': 'Calendar event enrollment request permissions',
  'program': 'Program permissions',
  'group': 'Group permissions',
  'skill': 'Skill permissions',
  'homework': 'Homework permissions',
}
