import { KnownViewRefs, ViewRef } from 'sierra-domain/api/insights'
import { IconId } from 'sierra-ui/components'

const knownViewRefTypeIconMap: Record<KnownViewRefs['type'], IconId> = {
  'view.native.users': 'user',
  'view.native.user-question': 'help',
  'view.native.user-course-feedback': 'star',
  'view.native.user-poll': 'poll',
  'view.native.user-reflection-responses': 'chat',
  'view.native.user-homework-submissions': 'task',
  'view.native.user-assessment': 'assessment',
  'view.native.user-course-progress': 'course',
  'view.native.user-path-progress': 'path',
  'view.native.user-program-progress': 'portfolio',
}

const checkIfKnownViewRef = (viewRef: unknown): viewRef is KnownViewRefs => {
  const result = KnownViewRefs.safeParse(viewRef)
  return result.success
}

export const getViewIconFromViewRef = (viewRef: ViewRef): IconId | undefined => {
  if (!checkIfKnownViewRef(viewRef)) return undefined

  return knownViewRefTypeIconMap[viewRef.type]
}
