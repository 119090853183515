import { createFileRoute } from '@tanstack/react-router'
import { useContentKindPermissions } from 'sierra-client/hooks/use-permissions'
import { PageIdentifier } from 'sierra-client/layout/sana-page'
import { LoadingPermissionsWrapper } from 'sierra-client/routes/access-denied.route'
import { ManageCourseGroupDetails } from 'sierra-client/views/manage/courses/manage-course-group-details'
import { ManagePageWrapper } from 'sierra-client/views/manage/utils/manage-page-wrapper'
import { CourseId } from 'sierra-domain/api/nano-id'
import { z } from 'zod'

// Note: ManagePageWrapper handles the role check.
const Page: React.FC = () => {
  const courseGroupId = Route.useParams({ select: p => p.courseGroupId })
  const permissions = useContentKindPermissions(courseGroupId)

  return (
    <ManagePageWrapper pageIdentifier={PageIdentifier.ManageCourseGroupDetail({ courseGroupId })}>
      <LoadingPermissionsWrapper
        isLoading={permissions.status === 'loading'}
        hasAccess={permissions.has('MANAGE')}
      >
        <ManageCourseGroupDetails key={courseGroupId} courseGroupId={courseGroupId} />
      </LoadingPermissionsWrapper>
    </ManagePageWrapper>
  )
}

export const Route = createFileRoute('/manage/course-group/$courseGroupId')({
  component: Page,
  params: {
    parse: z.object({ courseGroupId: CourseId }).parse,
    stringify: p => p,
  },
})
