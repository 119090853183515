import type Ably from 'ably'

export type ConnectionStatus = 'connecting' | 'connected' | 'disconnected'

export const ABLY_STATUS_MAPPING: Record<Ably.ConnectionState, ConnectionStatus> = {
  initialized: 'disconnected',
  connecting: 'connecting',
  connected: 'connected',
  disconnected: 'connecting',
  suspended: 'connecting',
  closing: 'disconnected',
  closed: 'disconnected',
  failed: 'disconnected',
}
