import {
  UseHistoryState,
  useHistoryState,
} from 'sierra-client/features/insights/widget-builder/use-history-state'
import {
  DimensionRef,
  MeasureArray,
  MeasureRef,
  NonEmptyDimensionArray,
  SortBy,
  TimeFilter,
  ViewRef,
  Widget,
} from 'sierra-domain/api/insights'
import { Filter } from 'sierra-domain/filter/datatype/filter'
import { assertNever } from 'sierra-domain/utils'

type VisualisationType =
  | {
      type: 'table'
      table: { tableType: 'standard' } | { tableType: 'list' }
    }
  | { type: 'pivot-table' }
  | { type: 'bar-chart' }
  | { type: 'line-chart' }
  | { type: 'metric' }
  | { type: 'progress-bar' }

type AllSelections = {
  view?: ViewRef
  dimensions: DimensionRef[]
  measures: MeasureRef[]
  filter?: Filter
  timeFilter?: TimeFilter
  sortBy?: SortBy
  limit?: number
}

export type WidgetBuilderState = {
  visualisation: VisualisationType
  selections: AllSelections
}

const toDimensionArray = (dimensions: DimensionRef[] | undefined): NonEmptyDimensionArray | undefined =>
  dimensions?.[0] !== undefined ? [dimensions[0], ...dimensions.slice(1, dimensions.length)] : undefined

const toMeasureArray = (measures: MeasureRef[] | undefined): MeasureArray | undefined =>
  measures?.[0] !== undefined ? [measures[0], ...measures.slice(1, measures.length)] : undefined

const emptyDimensionArray: DimensionRef[] = [] as const
const emptyMeasureArray: MeasureRef[] = [] as const

export const buildWidgetFromWidgetBuilderState = (state: WidgetBuilderState): Widget | undefined => {
  switch (state.visualisation.type) {
    case 'table': {
      switch (state.visualisation.table.tableType) {
        case 'standard': {
          const measures = toMeasureArray(state.selections.measures)
          if (measures === undefined) return undefined

          const dimensions = toDimensionArray(state.selections.dimensions) ?? []

          return {
            type: 'widget.aggregation-table',
            measures,
            dimensions,
            filter: state.selections.filter,
            timeFilter: state.selections.timeFilter,
            sortBy: state.selections.sortBy,
            limit: state.selections.limit,
          }
        }

        case 'list': {
          if (state.selections.view === undefined) return undefined

          const dimensions = toDimensionArray(state.selections.dimensions)
          if (dimensions === undefined) return undefined

          return {
            type: 'widget.list-table',
            view: state.selections.view,
            dimensions,
            filter: state.selections.filter,
            timeFilter: state.selections.timeFilter,
            limit: state.selections.limit,
            sortBy: state.selections.sortBy,
          }
        }

        default:
          return assertNever(state.visualisation.table)
      }
    }

    case 'pivot-table': {
      const measure = state.selections.measures[0]
      if (measure === undefined) return undefined

      const rowDimension = state.selections.dimensions[0]
      if (rowDimension === undefined) return undefined

      const columnDimension = state.selections.dimensions[1]
      if (columnDimension === undefined) return undefined

      return {
        type: 'widget.pivot-table',
        measures: [measure],
        dimensions: [rowDimension, columnDimension],
        filter: state.selections.filter,
      }
    }

    case 'bar-chart': {
      const measure = state.selections.measures[0]
      if (measure === undefined) return undefined

      const dimension = state.selections.dimensions[0]
      if (dimension === undefined) return undefined

      return {
        type: 'widget.bar-chart',
        measures: [measure],
        dimensions: [dimension],
        filter: state.selections.filter,
        timeFilter: state.selections.timeFilter,
        sortBy: state.selections.sortBy,
        limit: state.selections.limit,
      }
    }

    case 'line-chart': {
      const measure = state.selections.measures[0]
      if (measure === undefined) return undefined

      const dimension = state.selections.dimensions[0]
      if (dimension === undefined) return undefined

      return {
        type: 'widget.line-chart',
        measures: [measure],
        dimensions: [dimension],
        filter: state.selections.filter,
        timeFilter: state.selections.timeFilter,
        sortBy: state.selections.sortBy,
        limit: state.selections.limit,
      }
    }

    case 'metric': {
      const measure = state.selections.measures[0]
      if (measure === undefined) return undefined

      return {
        type: 'widget.metric',
        measures: [measure],
        filter: state.selections.filter,
        timeFilter: state.selections.timeFilter,
      }
    }

    case 'progress-bar': {
      const measure = state.selections.measures[0]
      if (measure === undefined) return undefined

      return {
        type: 'widget.progress-bar',
        measures: [measure],
        filter: state.selections.filter,
      }
    }

    default:
      assertNever(state.visualisation)
  }
}

export const buildWidgetBuilderStateFromWidget = (widget: Widget): WidgetBuilderState => {
  switch (widget.type) {
    case 'widget.aggregation-table':
      return {
        visualisation: { type: 'table', table: { tableType: 'standard' } },
        selections: {
          measures: widget.measures,
          dimensions: widget.dimensions,
          filter: widget.filter,
          timeFilter: widget.timeFilter,
        },
      }

    case 'widget.list-table':
      return {
        visualisation: { type: 'table', table: { tableType: 'list' } },
        selections: {
          view: widget.view,
          measures: emptyMeasureArray,
          dimensions: widget.dimensions,
          filter: widget.filter,
          timeFilter: widget.timeFilter,
        },
      }

    case 'widget.pivot-table':
      return {
        visualisation: { type: 'pivot-table' },
        selections: {
          measures: widget.measures,
          dimensions: widget.dimensions,
          filter: widget.filter,
        },
      }

    case 'widget.bar-chart':
      return {
        visualisation: { type: 'bar-chart' },
        selections: {
          measures: widget.measures,
          dimensions: widget.dimensions,
          filter: widget.filter,
        },
      }

    case 'widget.line-chart':
      return {
        visualisation: { type: 'line-chart' },
        selections: {
          measures: widget.measures,
          dimensions: widget.dimensions,
          filter: widget.filter,
        },
      }

    case 'widget.metric':
      return {
        visualisation: { type: 'metric' },
        selections: {
          measures: widget.measures,
          dimensions: emptyDimensionArray,
          filter: widget.filter,
          timeFilter: widget.timeFilter,
        },
      }

    case 'widget.progress-bar':
      return {
        visualisation: { type: 'metric' },
        selections: {
          measures: widget.measures,
          dimensions: emptyDimensionArray,
          filter: widget.filter,
        },
      }

    default:
      assertNever(widget)
  }
}

export const useWidgetBuilderState = (initialWidget?: Widget): UseHistoryState<WidgetBuilderState> => {
  return useHistoryState<WidgetBuilderState>(
    initialWidget !== undefined
      ? buildWidgetBuilderStateFromWidget(initialWidget)
      : {
          visualisation: { type: 'table', table: { tableType: 'standard' } },
          selections: { dimensions: [], measures: [] },
        }
  )
}
