import { Duration } from 'luxon'
import { getTableValue } from 'sierra-client/features/insights/display-widgets/data-utils'
import { createEmptyMeasureTableValue } from 'sierra-client/features/insights/display-widgets/normalize-data/empty-rows'
import { MeasureColumn, TableRow } from 'sierra-domain/api/insights'

const CLEAN_DURATIONS_LESS_THAN_SECONDS = 1

export const cleanShortDurations = ({
  tableRows,
  measure,
}: {
  tableRows: TableRow[]
  measure: MeasureColumn
}): TableRow[] => {
  if (measure.type.type !== 'type.duration') return tableRows

  const cleanedTableRows = tableRows.map(tableRow => {
    const tableValue = getTableValue(tableRow, measure.name)

    if (tableValue.type !== 'value.duration') return tableRow
    if (tableValue.value === undefined) return tableRow

    const duration = Duration.fromISO(tableValue.value)

    if (duration.as('seconds') < CLEAN_DURATIONS_LESS_THAN_SECONDS) {
      const emptyTableValue = createEmptyMeasureTableValue(tableValue)
      const emptyTableRow: TableRow = {
        values: {
          ...tableRow.values,
          [measure.name]: emptyTableValue,
        },
      }
      return emptyTableRow
    } else {
      return tableRow
    }
  })

  return cleanedTableRows
}
