import { cleanFilter } from 'sierra-client/features/insights/utils'
import { PartialDashboardWidget } from 'sierra-client/features/insights/widget-builder/types'
import {
  Dashboard,
  DashboardWidget,
  InsightsDashboardUpsertRequest,
  Widget,
} from 'sierra-domain/api/insights'
import { DomainRep } from 'sierra-domain/filter/datatype/domain'
import { nanoid12 } from 'sierra-domain/nanoid-extensions'
import { assertNever } from 'sierra-domain/utils'
import { NUMBER_OF_COLUMNS } from './constants'
import { WidgetPosition, getNextAvailablePositionForLayout } from './get-layout-position'

export const getDefaultSizingFromType = (type: Widget['type']): { width: number; height: number } => {
  switch (type) {
    case 'widget.metric':
      return {
        width: Math.max(NUMBER_OF_COLUMNS / 4, 1),
        height: 1,
      }
    case 'widget.progress-bar':
      return {
        width: Math.max(NUMBER_OF_COLUMNS / 2, 1),
        height: 1,
      }
    case 'widget.bar-chart':
    case 'widget.line-chart':
    case 'widget.aggregation-table':
    case 'widget.list-table':
    case 'widget.pivot-table':
    case undefined:
      return {
        width: Math.max(NUMBER_OF_COLUMNS / 2, 1),
        height: 2,
      }
    default:
      assertNever(type)
  }
}

export const getLayoutFromDashboard = (
  dashboard: Dashboard
): { i: string; x: number; y: number; w: number; h: number }[] => {
  return dashboard.widgets.map(dashboardWidget => ({
    ...dashboardWidget.layout,
    i: dashboardWidget.id,
    w: dashboardWidget.layout.width,
    h: dashboardWidget.layout.height,
  }))
}

export const createNewDashboardWidget = ({
  partialDashboardWidget,
  dashboard,
}: {
  partialDashboardWidget: PartialDashboardWidget
  dashboard: Dashboard
  widgetPosition?: WidgetPosition
}): DashboardWidget => {
  // Create new widget
  const sizing = getDefaultSizingFromType(partialDashboardWidget.widget.type)
  const currentLayout = getLayoutFromDashboard(dashboard)

  const position =
    currentLayout.length > 0 ? getNextAvailablePositionForLayout(currentLayout, sizing) : { x: 0, y: 0 }

  const widgetLayout = {
    ...position,
    width: sizing.width,
    height: sizing.height,
  }

  return {
    id: nanoid12(),
    title: partialDashboardWidget.title,
    widget: partialDashboardWidget.widget,
    layout: widgetLayout,
    settings: partialDashboardWidget.settings,
  }
}

const isDashboardWidget = (x: PartialDashboardWidget): x is DashboardWidget => {
  return 'layout' in x && 'id' in x
}

const removeNonSupportedFiltersForWidget = (
  dashboardWidget: DashboardWidget,
  allFilterDomainReps: DomainRep[]
): DashboardWidget => {
  const filter =
    dashboardWidget.widget.filter !== undefined
      ? cleanFilter(dashboardWidget.widget.filter, allFilterDomainReps)
      : undefined
  return {
    ...dashboardWidget,
    widget: {
      ...dashboardWidget.widget,
      filter,
    },
  }
}

export const getUpsertDashboardDataForWidget = ({
  dashboardWidget,
  dashboard,
  allFilterDomainReps,
}: {
  dashboardWidget: DashboardWidget | PartialDashboardWidget // Either an edited DashboardWidget or a new without layout and id PartialDashboardWidget
  dashboard: Dashboard

  allFilterDomainReps: DomainRep[]
}): InsightsDashboardUpsertRequest => {
  if (!isDashboardWidget(dashboardWidget)) {
    const newDashboardWidget = createNewDashboardWidget({
      partialDashboardWidget: dashboardWidget,
      dashboard,
    })

    const upsertData: InsightsDashboardUpsertRequest = {
      dashboard: {
        ...dashboard,
        widgets: [...dashboard.widgets, newDashboardWidget].map(widget =>
          removeNonSupportedFiltersForWidget(widget, allFilterDomainReps)
        ),
      },
    }
    return upsertData
  } else {
    const upsertData: InsightsDashboardUpsertRequest = {
      dashboard: {
        ...dashboard,
        widgets: dashboard.widgets
          .map(it => (it.id === dashboardWidget.id ? dashboardWidget : it))
          .map(widget => removeNonSupportedFiltersForWidget(widget, allFilterDomainReps)),
      },
    }
    return upsertData
  }
}
