import React from 'react'
import { useTranslationForBaseRoute } from 'sierra-client/hooks/use-translation'
import { DomainRep, Filter, filterEmptyFilters, orAll } from 'sierra-client/lib/filter'
import { AddDropdownAnchor, AddFilter } from 'sierra-client/lib/filter/components/add'
import { Context } from 'sierra-client/lib/filter/components/common'
import { groupFilterInit } from 'sierra-client/lib/filter/components/filter-utils'
import { ReadOnlyFilter, ReadOnlyFilterGroup } from 'sierra-client/lib/filter/components/read-only-filter'
import { FilterRoot } from 'sierra-client/lib/filter/components/root'
import {
  UserGroupAction,
  getInitialUserGroupDraftState,
  userGroupDraftStateReducer,
} from 'sierra-client/views/manage/users/components/edit-create-user-group-modal/draft-state'
import { UserGroupModalProps } from 'sierra-client/views/manage/users/components/edit-create-user-group-modal/types'
import { AssetContext } from 'sierra-domain/asset-context'
import { CloseModalButton, FormElement, Modal, ModalHeader } from 'sierra-ui/components'
import { Button, Heading, InputPrimitive, Spacer, Text, TextAreaPrimitive, View } from 'sierra-ui/primitives'
import styled from 'styled-components'

const ModalContainer = styled.div`
  padding: 2rem;
`

const FilterContainer = styled(View).attrs({ direction: 'row', wrap: 'wrap', gap: '4' })``

const StyledTextArea = styled(TextAreaPrimitive)`
  resize: vertical;
`

type SetFilterAction = Extract<UserGroupAction, { type: 'set-filter' }>
type SetFilter = (filter: SetFilterAction['filter']) => void

const EditableUserGroupFilter: React.FC<{
  filter: Filter
  setFilter: SetFilter
  domainReps: DomainRep[]
  assetContext: AssetContext
}> = React.memo(({ filter, setFilter, domainReps, assetContext }) => {
  const ctx = React.useMemo<Context>(
    () => ({
      domainReps,
      update: modify => setFilter(f => filterEmptyFilters(modify(f), false)),
      remove: () => setFilter(orAll([])),
    }),
    [domainReps, setFilter]
  )

  return (
    <FilterContainer>
      <AddFilter
        ctx={ctx}
        filter={filter}
        filterInit={groupFilterInit}
        renderTrigger={() => <AddDropdownAnchor />}
      />
      <FilterRoot ctx={ctx} filter={filter} assetContext={assetContext} />
    </FilterContainer>
  )
})

const EditCreateUserGroupModalInner: React.FC<UserGroupModalProps> = props => {
  const { onCancel, type, mode } = props

  const [userGroupDraft, userGroupDispatch] = React.useReducer(
    userGroupDraftStateReducer,
    props,
    getInitialUserGroupDraftState
  )

  // This is defined separately because it's important not to re-render the filter component too often.
  const setFilter = React.useCallback<SetFilter>(
    filter => userGroupDispatch({ type: 'set-filter', filter }),
    [userGroupDispatch]
  )

  // This is passed to the save and edit callbacks
  const saveParams = {
    name: userGroupDraft.name,
    description: userGroupDraft.description,
    filter:
      // Only include filter if editing smart group
      userGroupDraft.type === 'user-filter' && !userGroupDraft.readOnlyFilter
        ? userGroupDraft.filter
        : undefined,
  }

  const { t } = useTranslationForBaseRoute()

  return (
    <ModalContainer>
      <CloseModalButton ariaLabel={t('dictionary.close')} onClick={onCancel} />
      <ModalHeader>
        <Heading size='h5' bold>
          {mode === 'edit'
            ? t('user-groups.edit-group-details')
            : type === 'user-filter'
              ? t('create-smart-group')
              : t('user-groups.create-group')}
        </Heading>
      </ModalHeader>
      {userGroupDraft.type === 'user-filter' && (
        <>
          {mode === 'create' && (
            <>
              <Spacer size='xxsmall' />
              <Text size='regular'>{t('create-smart-group-description')}</Text>
            </>
          )}
          <Spacer size='small' />
          <Text size='small' bold>
            {t('smart-group-membership-criteria')}
          </Text>
          <Spacer size='6' />
          {userGroupDraft.readOnlyFilter ? (
            <ReadOnlyFilterGroup>
              <ReadOnlyFilter domainReps={userGroupDraft.domainReps} filter={userGroupDraft.filter} />
            </ReadOnlyFilterGroup>
          ) : (
            <EditableUserGroupFilter
              domainReps={userGroupDraft.domainReps}
              filter={userGroupDraft.filter}
              setFilter={setFilter}
              assetContext={{ type: 'unknown' }}
            />
          )}
        </>
      )}
      <Spacer size='small' />
      <FormElement label={t('dictionary.name')}>
        <InputPrimitive
          type='text'
          value={userGroupDraft.name}
          onChange={e => userGroupDispatch({ type: 'set-name', name: e.target.value })}
        />
      </FormElement>
      <Spacer size='xsmall' />
      <FormElement label={t('dictionary.description')}>
        <StyledTextArea
          value={userGroupDraft.description}
          onChange={e => userGroupDispatch({ type: 'set-description', description: e.target.value })}
          rows={3}
        />
      </FormElement>
      <Spacer size='xsmall' />
      <View direction='row' justifyContent='flex-end' alignItems='center' gap='8'>
        <Button variant='secondary' onClick={onCancel}>
          {t('dictionary.cancel')}
        </Button>
        {mode === 'edit' && (
          <Button disabled={!userGroupDraft.name} onClick={() => props.onEdit(saveParams)}>
            {t('shortcut-menu.groups.edit')}
          </Button>
        )}
        {mode === 'create' && (
          <Button disabled={!userGroupDraft.name} onClick={() => props.onCreate(saveParams)}>
            {t('shortcut-menu.groups.create')}
          </Button>
        )}
      </View>
    </ModalContainer>
  )
}

export const EditCreateUserGroupModal: React.FC<UserGroupModalProps> = props => {
  const { open, onCancel } = props

  return (
    <Modal open={open} size={{ width: 650 }} animation='pop' onClose={onCancel}>
      {open && <EditCreateUserGroupModalInner {...props} />}
    </Modal>
  )
}
