import { LivePresenceData } from 'sierra-client/realtime-data/channels/live-presence-channel/events'
import { createChannel, createEvent } from 'sierra-client/realtime-data/channels/types'
import { z } from 'zod'

export const LivePresenceReloadData = z.object({ message: z.string() })

export const livePresenceChannel = createChannel('live-presence', [
  createEvent('presence-updated', LivePresenceData),
  createEvent('reload', LivePresenceReloadData),
])
