import { nodeSourceCodeLocation } from 'sierra-client/features/react-debug-mode'
import { assert, assertNever, isDefined } from 'sierra-domain/utils'

function getAttributeValue(node: ChildNode, name: string): string | undefined {
  if (!('attributes' in node)) return undefined
  return (node as HTMLElement).attributes.getNamedItem(name)?.value
}
function isSlateElement(node: ChildNode): boolean {
  return getAttributeValue(node, 'data-slate-node') === 'element'
}

function isInlineVoidElement(node: ChildNode): boolean {
  return (
    getAttributeValue(node, 'data-slate-inline') === 'true' &&
    getAttributeValue(node, 'data-slate-void') === 'true'
  )
}

function isDataSlateString(node: ChildNode): boolean {
  return (
    getAttributeValue(node, 'data-slate-string') === 'true' ||
    getAttributeValue(node, 'data-slate-length') === '0'
  )
}

function isSlateText(node: ChildNode): boolean {
  return getAttributeValue(node, 'data-slate-node') === 'text'
}

function getContentEditability(node: ChildNode): boolean | undefined {
  const value = getAttributeValue(node, 'contentEditable') ?? getAttributeValue(node, 'contenteditable')
  if (value === 'true') return true
  else if (value === 'false') return false
  else return undefined
}

function isHTMLTextNode(node: ChildNode): boolean {
  return node.nodeType === 3
}

function assertWithNode(
  condition: boolean,
  message: string,
  nodeOrNodes: ChildNode | ChildNode[]
): asserts condition {
  assert(condition, () => {
    const nodes = Array.isArray(nodeOrNodes) ? nodeOrNodes : [nodeOrNodes]

    for (const node of nodes) {
      if (process.env.NODE_ENV === 'development') {
        const nodeToAlter = node.nodeType === 3 ? node : node.parentElement
        nodeToAlter?.parentElement?.style.setProperty('background-color', 'red')
        nodeToAlter?.parentElement?.style.setProperty('outline', '2px dashed red')
        nodeToAlter?.parentElement?.style.setProperty('outline-offset', '3px')
      }
      console.warn('[rules-of-blocks] violation found in node:', node)
    }

    const location = nodes.map(node => nodeSourceCodeLocation(node as HTMLElement)).filter(isDefined)[0]
    const locationString = location !== undefined ? ` [${location}]` : ''

    return message + locationString
  })
}

type NodeState = {
  readonly slateElements: ChildNode[]
  readonly slateTexts: ChildNode[]
  readonly slateDataStrings: ChildNode[]
  readonly containsNestedElement: boolean
}

type NodeContext = {
  contentEditable: boolean
  isInSlateElement: boolean
  isInSlateText: boolean
  isInSlateDataString: boolean
  isInInlineVoidElement: boolean
}

type NodeType =
  | 'slate-element'
  | 'slate-text'
  | 'slate-data-text'
  | 'html-text'
  | 'html-element'
  | 'non-rendering-tag'
function getNodeType(node: ChildNode): NodeType {
  let type: NodeType | undefined = undefined
  if (isSlateElement(node)) {
    type = 'slate-element'
  }

  if (isSlateText(node)) {
    assertWithNode(type === undefined, 'Node cannot be of multiple types', node)
    type = 'slate-text'
  }

  if (isDataSlateString(node)) {
    assertWithNode(type === undefined, 'Node cannot be of multiple types', node)
    type = 'slate-data-text'
  }

  if (isHTMLTextNode(node)) {
    assertWithNode(type === undefined, 'Node cannot be of multiple types', node)
    type = 'html-text'
  }

  if (['SCRIPT', 'STYLE'].includes(node.nodeName)) {
    assertWithNode(type === undefined, 'Node cannot be of multiple types', node)
    type = 'non-rendering-tag'
  }

  return type ?? 'html-element'
}

const emptyState: NodeState = {
  slateElements: [],
  slateTexts: [],
  slateDataStrings: [],
  containsNestedElement: false,
}

function determineNodeState(node: ChildNode, _context: NodeContext, depth: number): NodeState {
  assertWithNode(depth < 250, `[determineNodeState] Recursion depth exceeded at node ${node.nodeName}`, node)

  assertWithNode(
    getContentEditability(node) !== true,
    'A node should never explicitly be contentEditable=true',
    node
  )

  const type = getNodeType(node)

  let context = _context
  let state = emptyState
  const isContentEditableFalse = getContentEditability(node) === false
  context = isContentEditableFalse ? { ...context, contentEditable: false } : context

  switch (type) {
    case 'slate-element': {
      // Detected a slate element inside a slate element, this must be a nested element
      if (context.isInSlateElement) {
        assertWithNode(!context.isInSlateText, 'Nested slate element cannot be inside slate text', node)
        assertWithNode(
          !context.isInSlateDataString,
          'Nested slate element cannot be inside slate data string',
          node
        )
        // At this point we have reached a nested slate element
        return { ...state, containsNestedElement: true }
      }

      assertWithNode(
        context.contentEditable || context.isInInlineVoidElement,
        'Slate element must be content editable',
        node
      )

      state = { ...state, slateElements: [...state.slateElements, node] }
      context = { ...context, isInSlateElement: true }
      break
    }
    case 'slate-text': {
      assertWithNode(context.isInSlateElement, 'Slate leaf must be in a slate element', node)
      assertWithNode(
        context.contentEditable || context.isInInlineVoidElement,
        'Slate leaf must be content editable',
        node
      )

      state = { ...state, slateTexts: [...state.slateTexts, node] }
      context = { ...context, isInSlateText: true }
      break
    }
    case 'html-text': {
      if (context.isInSlateDataString) {
        assertWithNode(
          context.contentEditable || context.isInInlineVoidElement,
          'Text in a slate data string must be editable',
          node
        )
      } else {
        assertWithNode(!context.contentEditable, 'Text outside a slate leaf must not be editable', node)
      }
      return state
    }
    case 'slate-data-text': {
      assertWithNode(context.isInSlateText, 'Text container must be in a slate text node', node)
      assertWithNode(
        context.contentEditable || context.isInInlineVoidElement,
        'Text container must be content editable',
        node
      )
      context = { ...context, isInSlateDataString: true }
      state = { ...state, slateDataStrings: [...state.slateDataStrings, node] }
      break
    }
    case 'html-element': {
      break
    }
    case 'non-rendering-tag': {
      // We should not keep inspecting the tree at this point, so just return early
      return state
    }
    default:
      assertNever(type)
  }

  for (const child of node.childNodes) {
    const childState = determineNodeState(child, context, depth + 1)

    if (
      childState.slateDataStrings.length > 0 ||
      childState.slateElements.length > 0 ||
      childState.slateTexts.length > 0 ||
      childState.containsNestedElement
    ) {
      state = {
        slateElements: state.slateElements.concat(childState.slateElements),
        slateTexts: state.slateTexts.concat(childState.slateTexts),
        slateDataStrings: state.slateDataStrings.concat(childState.slateDataStrings),
        containsNestedElement: state.containsNestedElement || childState.containsNestedElement,
      }
    }
  }

  if (type === 'html-element' && context.contentEditable) {
    assertWithNode(
      state.slateElements.length !== 0 || context.isInSlateElement,
      `contentEditable=true node must contain 1 slate element or be contentEditable=false. Found ${state.slateElements.length}`,
      node
    )
    if (!state.containsNestedElement) {
      assertWithNode(
        state.slateTexts.length !== 0 || context.isInSlateText,
        `html element without nested element must contain >0 slate texts or be contentEditable=false`,
        node
      )
    }
  }

  return state
}

export function verifyEditability(node: ChildNode): void {
  const isInlineVoid = isInlineVoidElement(node)
  const state = determineNodeState(
    node,
    {
      contentEditable: true,
      isInSlateText: false,
      isInSlateDataString: false,
      isInSlateElement: false,
      isInInlineVoidElement: isInlineVoid,
    },
    0
  )

  assertWithNode(state.slateElements.length !== 0, `The root must contain 1 slate element`, node)
  assertWithNode(
    state.slateElements.length === 1,
    `The root cannot contain multiple slate elements. Found ${state.slateElements.length}`,
    state.slateElements
  )

  const textsCount = state.slateTexts.length
  if (!state.containsNestedElement) {
    assertWithNode(textsCount !== 0, `The root must contain >0 slate text`, node)
    assertWithNode(state.slateDataStrings.length !== 0, `The root must contain >0 slate data string`, node)
  }
}
