import { FadedRadioDotIcon } from 'sierra-client/components/recommendations/components/faded-radio-dot-icon'
import { useTranslation } from 'sierra-client/hooks/use-translation'
import { useUsersLegacy } from 'sierra-client/state/users/hooks'
import { ContentIcon } from 'sierra-client/views/workspace/components'
import { DaysToSession, StepsCompleted, TimeEstimate } from 'sierra-client/views/workspace/learn/components'
import { LearnEntity } from 'sierra-domain/api/entities'
import { InfoCard } from 'sierra-domain/api/infocard'
import { assertNever, getUserName, iife } from 'sierra-domain/utils'
import { TruncatedText } from 'sierra-ui/components'
import { Spacer, Text, View } from 'sierra-ui/primitives'

export const LearnEntityDetail: React.FC<{
  type: InfoCard['type'] | 'none'
  entity: LearnEntity
}> = ({ entity, type }) => {
  const { t } = useTranslation()

  const facilitatorIds = entity.entityType === 'live-session' ? entity.facilitatorIds : []
  const facilitators = useUsersLegacy(facilitatorIds)

  return (
    <View gap='none'>
      <ContentIcon type={entity.entityType} color='foreground/secondary' />
      <Spacer size='4' />
      <View gap='none'>
        {iife(() => {
          if (type === 'self-enroll-to-session') {
            return (
              <Text color='LEGACY_DEFAULT_TEXT_COLOR_REPLACE_ASAP' size='small'>
                {t('recommendation.multiple-dates')}
              </Text>
            )
          }

          const e = entity

          switch (e.entityType) {
            case 'live-session':
              return (
                <>
                  <DaysToSession startDate={e.start} endDate={e.end} showRange={true} />
                  <Spacer size='2' />
                  {facilitators.length > 0 && (
                    <>
                      <FadedRadioDotIcon />
                      <Spacer size='2' />
                      <TruncatedText color='LEGACY_DEFAULT_TEXT_COLOR_REPLACE_ASAP' lines={1} size='small'>
                        {t('recommendation.with-name', {
                          name: getUserName(facilitators[0]) ?? '',
                        })}
                      </TruncatedText>
                    </>
                  )}
                </>
              )
            case 'native:live':
              return (
                <Text color='LEGACY_DEFAULT_TEXT_COLOR_REPLACE_ASAP' size='small'>
                  {t('dictionary.course-singular')}
                </Text>
              )
            case 'program':
              return <StepsCompleted completedCount={e.completedStepCount} totalCount={e.totalStepCount} />
            case 'link':
            case 'path':
            case 'linkedin':
            case 'native:self-paced':
            case 'native:course-group':
            case 'scorm:course-group':
            case 'scorm':
            case 'native:event-group': {
              const inProgress = e.learnerContext.progress > 0 && e.learnerContext.progress < 1
              const timeLeft = (1.0 - e.learnerContext.progress) * e.timeEstimate

              return inProgress ? (
                <TimeEstimate timeEstimate={timeLeft} timeLeft={true} />
              ) : (
                <TimeEstimate timeEstimate={e.timeEstimate} timeLeft={false} />
              )
            }
            default:
              assertNever(e)
          }
        })}
      </View>
    </View>
  )
}
