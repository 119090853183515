import { isArray, isNumber, isObjectLike, isString } from 'lodash'

export type MetaAttributePrimitive = string | number | boolean | null | undefined
export type MetaAttributeObjectLike = Record<string, MetaAttributePrimitive>

// Max depth 2, to avoid logging too deep
export type Meta = Record<string, MetaAttributePrimitive | MetaAttributeObjectLike | unknown> & {
  /**
   * Tags reported to Sentry
   */
  tags?: Record<string, MetaAttributePrimitive>
  error?: unknown
  timestamp?: never
  fingerprint?: string | string[]
}

export type MaybeErrorProperties = {
  message?: any
  name?: any
  code?: any
  status?: any
  type?: any
  stack?: any
  text?: any
}

export class UnknownError extends Error {
  override name: any
  code?: any
  status?: any
  type?: any

  constructor(u: unknown) {
    let message = 'unknown error'
    if (isString(u)) message = u
    if (isNumber(u)) message = `${u}`

    if (isObjectLike(u) && !isArray(u)) {
      const asObj = u as MaybeErrorProperties
      if ('message' in asObj && typeof asObj.message === 'string') message = asObj.message
    }

    super(message)

    if (isObjectLike(u) && !isArray(u)) {
      const asObj = u as MaybeErrorProperties

      if ('name' in asObj) this.name = asObj.name
      if ('code' in asObj) this.code = asObj.code
      if ('status' in asObj) this.status = asObj.status
      if ('type' in asObj) this.type = asObj.type
      if ('stack' in asObj) this.stack = asObj.stack
    }
  }
}
