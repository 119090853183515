import React, { useState } from 'react'
import { useTranslation } from 'sierra-client/hooks/use-translation'
import { assessmentContainerRadius } from 'sierra-client/views/v3-author/assessment-card/assessment-container'
import { Icon } from 'sierra-ui/components'
import { Text, View } from 'sierra-ui/primitives'
import { palette } from 'sierra-ui/theming'
import styled from 'styled-components'

const AddQuestionButtonBase = styled(View).attrs({
  alignItems: 'center',
  justifyContent: 'center',
  contentEditable: false,
  animated: true,
})`
  cursor: pointer;
`

const AddQuestionButtonAnimated = styled(AddQuestionButtonBase).attrs({
  variants: {
    initial: {
      minHeight: '10px',
      transition: {
        duration: 0.15,
        ease: [0.25, 0.1, 0.25, 1],
        when: 'afterChildren',
      },
    },
    hover: {
      minHeight: '48px',
      transition: {
        duration: 0.15,
        ease: [0.25, 0.1, 0.25, 1],
        when: 'beforeChildren',
      },
    },
  },
})``

const AddQuestionButtonGrey = styled(AddQuestionButtonBase)`
  color: ${p => p.theme.color.grey25};
  background-color: ${palette.grey[2]};
  border-radius: ${p => p.theme.borderRadius[assessmentContainerRadius]};
  transition: color 150ms cubic-bezier(0.25, 0.1, 0.25, 1);
  margin: 0.5rem 0 0 0;

  &:hover {
    color: ${palette.primitives.black};
  }
`

const Label = styled(View).attrs({
  contentEditable: false,
  variants: {
    initial: {
      opacity: 0,
      transition: {
        ease: [0.25, 0.1, 0.25, 1],
        duration: 0.15,
      },
    },
    hover: {
      opacity: 1,
      transition: {
        ease: [0.25, 0.1, 0.25, 1],
        duration: 0.15,
      },
    },
  },
})``

export const AddQuestion: React.FC<{
  isGrey?: boolean
  hide?: boolean
  onClick: (e: React.MouseEvent) => void
}> = ({ isGrey = false, hide, onClick }) => {
  const [animate, setAnimate] = useState('initial')
  const { t } = useTranslation()

  if (hide === true) {
    return <React.Fragment />
  }

  const ButtonRenderer = isGrey ? AddQuestionButtonGrey : AddQuestionButtonAnimated
  let timer: NodeJS.Timeout

  return (
    <ButtonRenderer
      padding={isGrey ? 'xxsmall' : 'none'} // Can't move this into attrs, somehow results in a type mismatch
      animate={animate}
      onMouseEnter={() => {
        timer = setTimeout(() => setAnimate('hover'), 50)
      }}
      onMouseLeave={() => {
        clearTimeout(timer)
        setAnimate('initial')
      }}
      onClick={onClick}
    >
      <Label animated={!isGrey}>
        <Icon color='currentColor' iconId='add' size='size-16' />
        <Text size='regular' bold color='currentColor'>
          {t('assessment-card.add-question')}
        </Text>
      </Label>
    </ButtonRenderer>
  )
}
