import React, { useState } from 'react'
import { getMediaLibraryImageUrl } from 'sierra-client/api/content'
import { mediaLibraryManageModalOpened } from 'sierra-client/core/logging/media-library/logger'
import { useTranslation } from 'sierra-client/hooks/use-translation'
import { useCachedQuery } from 'sierra-client/state/api'
import { useDispatch } from 'sierra-client/state/hooks'
import {
  MediaFile,
  MediaLibraryUploadImageModal,
} from 'sierra-client/views/manage/settings/components/media-library-upload-modal'
import { XRealtimeAuthorMediaLibraryGetAllMedia } from 'sierra-domain/routes'
import { sample } from 'sierra-domain/utils'
import { Icon } from 'sierra-ui/components'
import { Button, Text, View } from 'sierra-ui/primitives'
import { token } from 'sierra-ui/theming'
import styled from 'styled-components'

const DESIRED_GRID_LENGTH = 15

const OuterMediaContainer = styled.div`
  position: relative;
  height: 280px;
  overflow: hidden;

  &:after {
    content: '';
    display: block;
    height: 100%;
    width: 100%;
    background: linear-gradient(transparent 15%, ${token('surface/default')} 100%);
    position: absolute;
    inset: 0;
  }
`

const MediaContainer = styled.div`
  column-count: 4;
  break-inside: avoid;
  column-gap: 8px;
`

const MediaImage = styled.img`
  border-radius: 10px;
  width: 100%;
  margin-bottom: 8px;
`

type AspectRatio = '1/1' | '4/3' | '16/9'

const MockupImageView = styled(View)<{ aspectRatio: AspectRatio }>`
  border-radius: 16px;
  width: 100%;
  display: inline-block;
  aspect-ratio: ${p => p.aspectRatio};
  margin-bottom: 16px;
`

const IconWrapper = styled(View)`
  height: 100%;
  width: 100%;
`

const aspectRatios: [AspectRatio, ...AspectRatio[]] = ['1/1', '4/3', '16/9']

const MockupImage: React.FC = () => {
  const [randomAspectRatio] = useState(() => sample(aspectRatios))

  return (
    <MockupImageView aspectRatio={randomAspectRatio} background='surface/strong'>
      <IconWrapper alignItems='center' justifyContent='center'>
        <Icon iconId='image' color={'foreground/muted'} />
      </IconWrapper>
    </MockupImageView>
  )
}

const MediaLibraryGrid: React.FC<{ mediaLibrary: MediaFile[] }> = ({ mediaLibrary }) => {
  const mediaLibraryLength = mediaLibrary.length
  const numberOfMockupItems = DESIRED_GRID_LENGTH - mediaLibraryLength

  return (
    <OuterMediaContainer>
      <MediaContainer>
        {mediaLibrary
          .flatMap(media =>
            media.image.type === 'media-library-image' ? [{ id: media.id, image: media.image }] : []
          )
          .map(({ id, image }) => (
            <MediaImage key={id} loading='lazy' src={getMediaLibraryImageUrl(image, { width: 128 })} />
          ))}
        {Array.from({ length: numberOfMockupItems }).map((_, index) => (
          <MockupImage key={index} />
        ))}
      </MediaContainer>
    </OuterMediaContainer>
  )
}

export const MediaLibrary: React.FC = () => {
  const [modalOpen, setModalOpen] = useState(false)
  const { t } = useTranslation()
  const dispatch = useDispatch()
  const content = useCachedQuery(XRealtimeAuthorMediaLibraryGetAllMedia, {})
  const mediaLibraryContent = content.isSuccess ? content.data.mediaLibraryFiles : undefined

  const isMediaLibraryEmpty = mediaLibraryContent?.length === 0

  const descriptionText = isMediaLibraryEmpty
    ? t('manage.media-library.setup-description')
    : t('manage.media-library.description')

  const buttonText = isMediaLibraryEmpty
    ? t('dictionary.setup-media-library')
    : t('dictionary.manage-media-library')

  return (
    <View direction='column'>
      <View direction='column' gap='4' marginBottom='24'>
        <Text bold>{t('dictionary.media-library')}</Text>
        <Text>{descriptionText}</Text>
      </View>
      <MediaLibraryUploadImageModal
        open={modalOpen}
        onClose={() => setModalOpen(false)}
        mediaLibraryContent={mediaLibraryContent}
        refetchMediaLibrary={content.refetch}
      />
      <MediaLibraryGrid mediaLibrary={mediaLibraryContent?.slice(0, DESIRED_GRID_LENGTH) ?? []} />
      <Button
        variant='secondary'
        onClick={() => {
          setModalOpen(true)
          void dispatch(mediaLibraryManageModalOpened({}))
        }}
      >
        {buttonText}
      </Button>
    </View>
  )
}
