import { TeamspaceIcon, TeamspaceIconProps } from 'sierra-client/features/teamspace'
import { useTranslation } from 'sierra-client/hooks/use-translation'
import { getGlobalRouter } from 'sierra-client/router'
import { isDefined } from 'sierra-domain/utils'
import { resolveTokenOrColor } from 'sierra-ui/color/token-or-color'
import { Icon, IconId } from 'sierra-ui/components'
import { Text, View } from 'sierra-ui/primitives'
import { token } from 'sierra-ui/theming'
import styled from 'styled-components'

const Line = styled.div`
  flex: 1;
  border-bottom: 1px solid ${p => resolveTokenOrColor('border/default', p.theme)};
`

const HeadlineContainer = styled(View)`
  background-color: ${token('surface/default')};
  position: sticky;
  top: 0px;
  z-index: 10;
`
const Headline: React.FC<{
  text: string
  leadingVisual: React.ReactNode
  onClick?: () => void
  nrOfShowingItems: number
  totalNrOfItems: number
}> = ({ text, leadingVisual, onClick, nrOfShowingItems, totalNrOfItems }) => {
  const { t } = useTranslation()

  return (
    <HeadlineContainer
      paddingTop='16'
      direction='column'
      justifyContent='flex-start'
      alignItems='stretch'
      gap='12'
    >
      <View grow justifyContent='space-between'>
        <View
          direction='row'
          justifyContent='flex-start'
          alignItems='center'
          gap='8'
          onClick={onClick}
          cursor={isDefined(onClick) ? 'pointer' : undefined}
        >
          {leadingVisual}
          <Text bold color='foreground/secondary'>
            {text}
          </Text>
        </View>
        <Text color='foreground/muted'>
          {t('table.showing-s-of-n', {
            part: nrOfShowingItems,
            total: totalNrOfItems,
          })}
        </Text>
      </View>
      <Line />
    </HeadlineContainer>
  )
}

export const SectionHeadline: React.FC<{
  text: string
  iconId: IconId
  nrOfShowingItems: number
  totalNrOfItems: number
}> = ({ text, iconId, nrOfShowingItems, totalNrOfItems }) => (
  <Headline
    nrOfShowingItems={nrOfShowingItems}
    totalNrOfItems={totalNrOfItems}
    text={text}
    leadingVisual={<Icon iconId={iconId} color='foreground/secondary' />}
  />
)

export const TeamspaceHeadline: React.FC<{
  teamspaceIconProps: TeamspaceIconProps
  href: string
  nrOfShowingItems: number
  totalNrOfItems: number
}> = ({ teamspaceIconProps, href, nrOfShowingItems, totalNrOfItems }) => {
  return (
    <Headline
      nrOfShowingItems={nrOfShowingItems}
      totalNrOfItems={totalNrOfItems}
      onClick={() => getGlobalRouter().navigate({ to: href })}
      text={teamspaceIconProps.displayName}
      leadingVisual={<TeamspaceIcon {...teamspaceIconProps} small />}
    />
  )
}
