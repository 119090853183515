import { convertTableValueToFilterValue } from 'sierra-client/features/insights/display-widgets/bar-chart-viewer/utils'
import {
  getMeasuresFromWidget,
  getViewFromWidget,
} from 'sierra-client/features/insights/display-widgets/utils'
import { useInsightsViewsFromMeasures } from 'sierra-client/features/insights/hooks/use-insights-views'
import { appendFilter } from 'sierra-client/lib/filter/components/filter-utils'
import {
  AggregationTableWidget,
  BarChartWidget,
  Dimension,
  DimensionRef,
  TableValue,
  areDimensionRefsEqual,
} from 'sierra-domain/api/insights'
import { Filter, andAll, createFilter } from 'sierra-domain/filter/datatype/filter'
import { Or } from 'sierra-domain/filter/datatype/pred'

export const useOnDrillDownDimension = ({
  onDrillDown,
  widget,
}: {
  onDrillDown?: (filter: Filter, newDimension: Dimension) => void
  widget?: AggregationTableWidget | BarChartWidget
}): ((tableValue: TableValue, dimensionRef: DimensionRef) => void) | undefined => {
  const { data: viewsData, isLoading } = useInsightsViewsFromMeasures(
    getMeasuresFromWidget(widget),
    getViewFromWidget(widget)
  )

  if (widget === undefined || onDrillDown === undefined || isLoading) return undefined

  const canFilterCurrentDimension =
    viewsData.availableFilters.find(dimension =>
      areDimensionRefsEqual(widget.dimensions[0], dimension.ref)
    ) !== undefined

  if (!canFilterCurrentDimension) return undefined

  const onDrillDownDimension = (tableValue: TableValue, dimensionRef: DimensionRef): void => {
    const dimension = widget.dimensions[0]

    const selectedFilter = viewsData.availableFilters.find(filter =>
      areDimensionRefsEqual(filter.ref, dimension)
    )
    if (selectedFilter === undefined) return

    const operator = selectedFilter.operators.find(
      operator => operator.type === 'operator.eq' || operator.type === 'operator.contains'
    )
    if (operator === undefined) {
      throw new Error('Filter does not have eq or contains operator')
    }

    const filterValue = convertTableValueToFilterValue(tableValue)
    const newLeaf = createFilter(selectedFilter.ref, operator, Or([filterValue]))

    const newFilter = widget.filter === undefined ? andAll([newLeaf]) : appendFilter(widget.filter, newLeaf)

    const newDimension = viewsData.availableDimensions.find(dimension =>
      areDimensionRefsEqual(dimension.ref, dimensionRef)
    )
    if (newDimension === undefined) return

    onDrillDown(newFilter, newDimension)
  }

  return onDrillDownDimension
}

export const useOnFilterOutTableValue = ({
  onFilterOut,
  widget,
}: {
  onFilterOut?: (filter: Filter) => void
  widget?: AggregationTableWidget | BarChartWidget
}): ((tableValue: TableValue) => void) | undefined => {
  const { data: viewsData, isLoading } = useInsightsViewsFromMeasures(
    getMeasuresFromWidget(widget),
    getViewFromWidget(widget)
  )

  if (widget === undefined || onFilterOut === undefined || isLoading) return undefined

  const canFilterCurrentDimension =
    viewsData.availableFilters.find(dimension =>
      areDimensionRefsEqual(widget.dimensions[0], dimension.ref)
    ) !== undefined

  if (!canFilterCurrentDimension) return undefined

  const availableFilters = viewsData.availableFilters

  const onFilterOutTableValue = (tableValue: TableValue): void => {
    const dimension = widget.dimensions[0]

    const selectedFilter = availableFilters.find(
      filter => JSON.stringify(filter.ref) === JSON.stringify(dimension)
    )
    if (selectedFilter === undefined) return

    const operator = selectedFilter.operators.find(
      operator => operator.type === 'operator.neq' || operator.type === 'operator.not-contains'
    )
    if (operator === undefined) {
      throw new Error('Filter does not have neq or not-contains operator')
    }

    const filterValue = convertTableValueToFilterValue(tableValue)
    let newLeaf: Filter
    if (filterValue.type === 'value.none') {
      const operator = selectedFilter.operators.find(operator => operator.type === 'operator.not-null')
      if (operator === undefined) {
        throw new Error('Tried to filter out value.none, but filter does not have not-null operator')
      }
      newLeaf = createFilter(selectedFilter.ref, operator, { type: 'predicate.none' })
    } else {
      newLeaf = createFilter(selectedFilter.ref, operator, Or([filterValue]))
    }

    const newFilter = widget.filter === undefined ? andAll([newLeaf]) : appendFilter(widget.filter, newLeaf)

    onFilterOut(newFilter)
  }

  return onFilterOutTableValue
}
