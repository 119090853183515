import { useCachedQuery } from 'sierra-client/state/api'
import { FlexibleContentRecord } from 'sierra-domain/collaboration/serialization'
import { ScopedCreateContentId } from 'sierra-domain/collaboration/types'
import { XRealtimeAuthorExportFlexibleContent } from 'sierra-domain/routes'

export function useFlexibleContentRecord({
  scopedCreateContentId,
}: {
  scopedCreateContentId: ScopedCreateContentId
}): FlexibleContentRecord | undefined {
  const query = useCachedQuery(XRealtimeAuthorExportFlexibleContent, {
    courseId: ScopedCreateContentId.extractId(scopedCreateContentId),
  })

  return query.data?.record
}
