import _ from 'lodash'
import { DateTime } from 'luxon'
import React, { useMemo, useRef, useState } from 'react'
import type { SkillId } from 'sierra-client/api/graphql/branded-types'
import { graphql } from 'sierra-client/api/graphql/gql'
import type { GetSkillTrophyQuery } from 'sierra-client/api/graphql/gql/graphql'
import { useGraphQuery } from 'sierra-client/api/hooks/use-graphql-query'
import { useSkillDebugControl } from 'sierra-client/features/skills/debug'
import { useTranslation } from 'sierra-client/hooks/use-translation'
import { Trans } from 'sierra-client/hooks/use-translation/trans'
import { assertNever, isDefined, isEmptyArray, isSingleArray } from 'sierra-domain/utils'
import { TokenOrColor, resolveTokenOrColor } from 'sierra-ui/color/token-or-color'
import { SkillIconId } from 'sierra-ui/components'
import { HideScrollbarUnlessHovered } from 'sierra-ui/components/layout-kit'
import { Skeleton, Text, View } from 'sierra-ui/primitives'
import { token } from 'sierra-ui/theming'
import { v2_breakpoint } from 'sierra-ui/theming/breakpoints'
import styled from 'styled-components'
import { SkillColor } from '../../colors'
import {
  SkillLevelProgressStatus,
  getLevelsWithAssignedContent,
  gqlProgressToSkillProgressStatus,
} from '../../helpers'
import { useTracking } from '../../tracking'
import { RichBadge } from '../badges'
import { useIntersectionObserver } from './helper'

const ProgressLine = styled.div<{ $progress: number; $color?: TokenOrColor }>`
  height: 4px;
  border-radius: 0.5rem;
  width: 100%;
  margin: 4px 0;

  ${p => {
    const start = `${resolveTokenOrColor(p.$color ?? 'blueBright', p.theme)} ${p.$progress * 100}%`
    const middle = `${token('border/default')(p)} ${p.$progress * 100}%`
    const end = `${token('border/default')(p)} 100%`
    return p.$progress > 0
      ? `background: linear-gradient(90deg, ${start}, ${middle}, ${end});`
      : `background-color: ${token('border/default')(p)}`
  }}
`

const TrophyContainer = styled(View)`
  width: 100%;
  overflow-x: auto;
  display: flex;
  gap: 16px;
  padding: 1px 4px 4px 1px;
  height: fit-content;
  flex-wrap: nowrap;
  ${HideScrollbarUnlessHovered};
`

const cardSize = {
  width: 504,
  height: 208,
  radius: 18,
} as const

const cardSizeMobile = {
  width: 264,
  height: 297,
} as const

const Card = styled(View)`
  min-width: ${cardSize.width}px;
  border-radius: ${cardSize.radius}px;
  outline: 1px solid ${token('border/default')};
  padding: 24px 36px;
  column-gap: 16px;
  height: ${cardSize.height}px;

  display: grid;
  grid-template-areas:
    'icon .'
    'icon text'
    'icon .';
  grid-template-columns: 150px 1fr;
  box-shadow: 0px 2px 4px 0px rgba(0, 0, 0, 0.08);

  @media (max-width: ${v2_breakpoint.phone}) {
    min-width: ${cardSizeMobile.width}px;
    height: ${cardSizeMobile.height}px;
    padding: 16px 24px;
    padding-top: 36px;
    text-align: center;

    grid-template-columns: 1fr;
    grid-template-rows: 160px auto;
    grid-template-areas:
      'icon'
      'text';
  }
`

const GridItem = styled(View)<{ area: string }>`
  height: 100%;
  width: ${p => (p.area === 'icon' ? '150px' : '100%')};
  grid-area: ${p => p.area};
  justify-self: center;
`

const EllipsedText = styled(Text)`
  display: inline-block;
  vertical-align: bottom;
  white-space: nowrap;
  max-width: 138px;
  overflow: hidden;
  text-overflow: ellipsis;
`

const isType =
  <X extends { type: string }, T extends X['type']>(type: T) =>
  (x: X): x is X & { type: T } => {
    return x.type === type
  }

const Trophy = React.forwardRef<
  HTMLDivElement,
  {
    icon: SkillIconId
    color: SkillColor
    skillName: string
    skillLevelName: string
    index: number
    progress?: SkillLevelProgressStatus
  }
>(({ icon, color, skillName, skillLevelName, index, progress }, ref) => {
  const { t } = useTranslation()
  const tracking = useTracking()

  const view = useMemo(() => {
    if (!progress) {
      return _.capitalize(t('dictionary.level')) + ` ${index}`
    }
    switch (progress.type) {
      case 'achieved': {
        return t('skills.trophy.achieved', {
          date: DateTime.fromISO(progress.at).toLocaleString({
            month: 'short',
            day: 'numeric',
            year: 'numeric',
          }),
        })
      }
      case 'locked': {
        return t('skills.trophy.sequentially-unlocked-content.finish-previous.text')
      }
      case 'in-progress': {
        const contentProgress = progress.content
        if (isEmptyArray(contentProgress)) {
          return t('skills.trophy.no-progress-to-be-made.text')
        }

        const restricted = contentProgress.some(isType('restricted'))
        if (restricted) {
          return t('skills.trophy.restricted-content.text')
        }

        const nonRestrictedUnfinishedProgress = contentProgress
          .filter(isType('progress'))
          .filter(p => p.progress < 1)

        if (isSingleArray(nonRestrictedUnfinishedProgress)) {
          return (
            <Trans
              i18nKey='skills.trophy.complete-x-to-achieve'
              components={{ span: <EllipsedText as='span' color='foreground/secondary' /> }}
              values={{
                name: nonRestrictedUnfinishedProgress[0].contentInfo.title,
              }}
            />
          )
        } else if (isEmptyArray(nonRestrictedUnfinishedProgress)) {
          return t('skills.trophy.no-progress-to-be-made.text')
        } else {
          return t('skills.trophy.complete-steps-to-achieve', {
            count: nonRestrictedUnfinishedProgress.length,
          })
        }
      }
      default:
        assertNever(progress)
    }
  }, [progress, index, t])

  const progressLine = useMemo(() => {
    if (!progress) {
      return null
    }
    switch (progress.type) {
      case 'achieved': {
        return null
      }
      case 'locked': {
        return null
      }
      case 'in-progress': {
        return [...progress.content]
          .sort((p1, p2) => {
            if (p1.type === 'restricted') return -1
            if (p2.type === 'restricted') return 1
            return p2.progress - p1.progress
          })
          .map(p => {
            if (p.type === 'restricted') {
              return <ProgressLine key={p.contentInfo.contentId} $progress={0} />
            } else {
              return <ProgressLine key={p.contentInfo.contentId} $progress={p.progress} />
            }
          })
      }
      default:
        assertNever(progress)
    }
  }, [progress])

  return (
    <Card ref={ref}>
      <GridItem area='icon'>
        {progress?.type === 'locked' ? (
          <RichBadge iconId='locked' theme='disabled' title={skillName} subtitle={skillLevelName} />
        ) : progress?.type === 'in-progress' ? (
          <RichBadge iconId={icon} theme='disabled' title={skillName} subtitle={skillLevelName} />
        ) : (
          <RichBadge
            iconId={icon}
            theme={color}
            title={skillName}
            subtitle={skillLevelName}
            onMouseEnter={p => {
              tracking.badge.enterHover({ theme: p.theme, iconId: p.iconId })
            }}
            onMouseLeave={p => {
              tracking.badge.leaveHover({ theme: p.theme, iconId: p.iconId })
            }}
          />
        )}
      </GridItem>
      <GridItem grow area='text'>
        <View grow direction='column' gap='none'>
          <Text color='foreground/primary' bold>
            {skillLevelName}
          </Text>
          <View grow direction='column' gap='16'>
            <View grow>
              <Text color='foreground/muted'>{view}</Text>
            </View>
            <View grow>{progressLine}</View>
          </View>
        </View>
      </GridItem>
    </Card>
  )
})

const Blurrer = styled.div<{ blurLeft?: boolean; blurRight?: boolean }>`
  position: absolute;
  top: 0;
  pointer-events: none;
  width: 100%;
  height: 100%;

  &::before {
    content: '';
    position: absolute;
    left: 0;
    height: 100%;
    width: 100px;
    display: ${p => (p.blurLeft === true ? 'block' : 'none')};
    background: linear-gradient(to left, rgba(255, 255, 255, 0), ${token('surface/default')} 100%);
  }

  &::after {
    content: '';
    height: 100%;
    width: 100px;
    right: 0;
    position: absolute;
    display: ${p => (p.blurRight === true ? 'block' : 'none')};
    background: linear-gradient(to right, rgba(255, 255, 255, 0), ${token('surface/default')} 100%);
  }
`

const _TrophyCase: React.FC<{ skill: NonNullable<GetSkillTrophyQuery['skill']> }> = ({ skill }) => {
  const parentRef = useRef<HTMLDivElement>(null)
  const firstItemRef = useRef<HTMLDivElement>(null)
  const lastItemRef = useRef<HTMLDivElement>(null)
  const [blurLeft, setBlurLeft] = useState(false)
  const [blurRight, setBlurRight] = useState(true)

  useIntersectionObserver({
    target: firstItemRef,
    root: parentRef.current,
    threshold: 1,
    onIntersect: entries => {
      if (Boolean(entries[0]?.isIntersecting)) {
        setBlurLeft(false)
      } else {
        setBlurLeft(true)
      }
    },
  })
  useIntersectionObserver({
    target: lastItemRef,
    root: parentRef.current,
    threshold: 1,
    onIntersect: entries => {
      if (Boolean(entries[0]?.isIntersecting)) {
        setBlurRight(false)
      } else {
        setBlurRight(true)
      }
    },
  })
  const myProgress = gqlProgressToSkillProgressStatus(skill.myProgress.progress, {
    sequentialUnlockingEnabled: skill.sequentialUnlockingEnabled,
  })

  // Only showcase the levels that actually have assigned content to it
  // Remove index 0 which is implicit level
  const assignedSkillLevels = getLevelsWithAssignedContent(skill.assignedContent)

  // If we have not assigned any content, show all of the levels, otherwise only show the assigned levels
  const skillLevelsToShow = isEmptyArray(assignedSkillLevels) ? skill.skillLevels : assignedSkillLevels

  const skillLevels = skillLevelsToShow.map((skillLevel, i) => {
    const icon = SkillIconId.catch('skill--graduation--cap').parse(skill.defaultBadgeIcon)
    const color = SkillColor.catch('bronze').parse(skillLevel.levelSetting.defaultBadgeTheme)
    const id = skillLevel.id
    const index = skillLevel.levelSetting.index
    const progress = myProgress.find(status => status.skillLevel.id === id)?.progress

    return (
      <Trophy
        ref={i === 0 ? firstItemRef : i === skillLevelsToShow.length - 1 ? lastItemRef : undefined}
        key={id}
        icon={icon}
        color={color}
        skillName={skill.name}
        index={index}
        progress={progress}
        skillLevelName={skillLevel.levelSetting.name}
      />
    )
  })

  return (
    <div style={{ position: 'relative' }}>
      <TrophyContainer ref={parentRef}>{skillLevels}</TrophyContainer>
      <Blurrer blurLeft={blurLeft} blurRight={blurRight} />
    </div>
  )
}

const getSkillTrophyQuery = graphql(`
  query GetSkillTrophy($id: SkillId!) {
    skill(id: $id) {
      name
      description
      defaultBadgeIcon
      sequentialUnlockingEnabled
      myProgress {
        progress {
          achievedAt
          progress {
            ...Progress
          }
          skillLevel {
            ...ProgressSkillLevel
          }
        }
      }
      skillLevels {
        id
        description
        levelSetting {
          id
          index
          name
          defaultBadgeTheme
          createdAt
          updatedAt
        }
      }
      assignedContent {
        skillLevel {
          id
          levelSetting {
            id
            index
            name
            defaultBadgeTheme
          }
        }
      }
    }
  }
`)

const SkeletonWrapper = styled(View)`
  min-width: ${cardSize.width}px;
  height: ${cardSize.height}px;
`

export const TrophyCase: React.FC<{ skillId: SkillId }> = ({ skillId }) => {
  const debugControl = useSkillDebugControl()
  const query = useGraphQuery({ document: getSkillTrophyQuery }, { id: skillId })
  const skill = query.data?.skill

  if (!debugControl.loading && isDefined(skill)) {
    return <_TrophyCase skill={skill} />
  }

  return (
    <TrophyContainer>
      <SkeletonWrapper>
        <Skeleton $width={cardSize.width} $height={cardSize.height} $radius={cardSize.radius} />
      </SkeletonWrapper>

      <SkeletonWrapper>
        <Skeleton $width={cardSize.width} $height={cardSize.height} $radius={cardSize.radius} />
      </SkeletonWrapper>

      <SkeletonWrapper>
        <Skeleton $width={cardSize.width} $height={cardSize.height} $radius={cardSize.radius} />
      </SkeletonWrapper>
    </TrophyContainer>
  )
}
