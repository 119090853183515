import type { SkillId } from 'sierra-client/api/graphql/branded-types'
import { graphql } from 'sierra-client/api/graphql/gql'
import { useGraphQuery } from 'sierra-client/api/hooks/use-graphql-query'
import { ErrorCard } from 'sierra-client/features/skills/components/error/error-card'
import {
  StatsWidgetContainer,
  StatsWidgetInnerContainer,
  StatsWidgetTitle,
  WIDGET_HEIGHT,
} from 'sierra-client/features/skills/components/stats/styles'
import { BarChart } from 'sierra-ui/missions/delphi/charts'
import { Skeleton } from 'sierra-ui/primitives'
import { maxWidth } from 'sierra-ui/utils/media-query-styles'
import styled, { css } from 'styled-components'

const getBadgesPerLevelsQuery = graphql(`
  query GetBadgesPerLevel($skillId: SkillId!) {
    badgesPerLevel(skillId: $skillId) {
      numBadges
      skillLevelSettingId
      skillLevelSettingName
    }
  }
`)

const ChartContainer = styled(StatsWidgetInnerContainer)<{ withPadding: boolean }>`
  height: 284px;
  ${maxWidth.desktop} {
    height: ${WIDGET_HEIGHT}px;
  }

  ${p =>
    p.withPadding &&
    css`
      padding-block-start: 24px;
    `}
`

export const BadgeDistribution: React.FC<{ skillId: SkillId }> = ({ skillId }) => {
  const query = useGraphQuery({ document: getBadgesPerLevelsQuery }, { skillId })

  return (
    <StatsWidgetContainer>
      <StatsWidgetTitle translationKey='skills.stats.badge-distribution.title' />
      <ChartContainer withPadding={query.isSuccess}>
        {query.isLoading ? (
          <Skeleton $width='100%' $height='100%' />
        ) : query.isError ? (
          <ErrorCard />
        ) : (
          <BarChart
            data={(query.data?.badgesPerLevel ?? []).map(it => ({
              id: it.skillLevelSettingId,
              label: it.skillLevelSettingName,
              values: {
                count: it.numBadges,
              },
            }))}
            keys={['count']}
            yLegend='Count'
            isInteractive
          />
        )}
      </ChartContainer>
    </StatsWidgetContainer>
  )
}
