import { useResolveAsset } from 'sierra-client/hooks/use-resolve-asset'
import { useTranslation } from 'sierra-client/hooks/use-translation'
import { getLearnEntityName } from 'sierra-client/views/manage/content/utils/content-utils'
import { Thumbnail } from 'sierra-client/views/workspace/components'
import { CourseForHref } from 'sierra-domain/api/backend-self-paced/types'
import { CourseId } from 'sierra-domain/api/nano-id'
import { isDefined } from 'sierra-domain/utils'
import { Icon, TruncatedText } from 'sierra-ui/components'
import { Text, View } from 'sierra-ui/primitives'
import styled from 'styled-components'

const StyledThumbnail = styled(Thumbnail)`
  width: 80px;
  min-height: 50px;
  max-height: 50px;
  margin-right: 0px;
  border-radius: 8px;
`

const CourseDescriptionWrapper = styled(View)`
  min-width: 296px;
  max-width: 296px;
`

const ContentRow: React.FC<{
  course: CourseForHref
  courseTitle: string
  extraInformation: string | undefined
}> = ({ course, courseTitle, extraInformation }) => {
  const { t } = useTranslation()

  const assetContext = { type: 'course' as const, courseId: CourseId.parse(course.id) }
  const thumbnailSrc = useResolveAsset({
    image: course.image,
    size: 'default',
    assetContext,
  })

  const contentLabel = t(getLearnEntityName(course.type))

  return (
    <View direction='row' justifyContent='flex-start' alignItems='center'>
      <StyledThumbnail lazy={true} image={thumbnailSrc} />
      <View direction='column' grow justifyContent='center' alignItems='flex-start'>
        <Text bold color='foreground/primary'>
          {courseTitle}
        </Text>
        <View gap='none'>
          <Text bold color='foreground/secondary'>
            {contentLabel}
          </Text>
          {isDefined(extraInformation) && extraInformation !== '00' && (
            <>
              <Icon iconId='radio-button--dot' color='foreground/secondary' />
              <Text color='foreground/secondary'> {extraInformation}</Text>
            </>
          )}
        </View>
      </View>
    </View>
  )
}

export const NextUpCourseInformation: React.FC<{
  course: CourseForHref
  courseTitle: string
  extraInformation: string | undefined
}> = ({ course, courseTitle, extraInformation }) => {
  return (
    <View direction='column' marginTop='12'>
      <ContentRow course={course} courseTitle={courseTitle} extraInformation={extraInformation} />
      {isDefined(course.description) && (
        <CourseDescriptionWrapper marginTop='16'>
          <TruncatedText color='foreground/secondary' lines={3}>
            {course.description}
          </TruncatedText>
        </CourseDescriptionWrapper>
      )}
    </View>
  )
}
