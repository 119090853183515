import { isUndefined, sum, sumBy } from 'lodash'
import { MovingAverage } from 'sierra-client/lib/statistics/moving-average'

export class RollingWindowAverage implements MovingAverage {
  private list: (number | undefined)[]
  private currentIndex = 0
  private currentAvg: number | undefined

  constructor(private size: number) {
    this.list = new Array<number | undefined>(size)
  }

  push(val: number): number {
    this.list[this.currentIndex] = val
    this.currentIndex = (this.currentIndex + 1) % this.size
    return (this.currentAvg = this.safeAverage()) ?? val
  }

  avg(): number | undefined {
    return this.currentAvg
  }

  filled(): boolean {
    return this.list[this.list.length - 1] !== undefined
  }

  private safeAverage(): number | undefined {
    const nrElements = sumBy(this.list, v => (isUndefined(v) ? 0 : 1))
    const res = sum(this.list) / nrElements
    return res !== Infinity && !isNaN(res) ? res : undefined
  }
}
