import { useTranslation } from 'sierra-client/hooks/use-translation'
import {
  UserPreviewConfig,
  UserPreviewTable,
} from 'sierra-client/views/manage/programs/enrollment-rules/modals/user-preview/user-preview-table'
import { PanelInPanel, PanelInPanelCloseButton } from 'sierra-ui/components/layout-kit/layout-panel'
import { Heading, View } from 'sierra-ui/primitives'
import styled from 'styled-components'

const Padding = styled(View).attrs({ padding: '40', direction: 'column' })`
  height: 100%;
`

type UserPreviewPanelProps = {
  open: boolean
  count: number
  onClose: () => void
  previewConfig: UserPreviewConfig
}

export const UserPreviewPanel: React.FC<UserPreviewPanelProps> = ({
  open,
  onClose,
  count,
  previewConfig,
}) => {
  const { t } = useTranslation()

  return (
    <PanelInPanel key='right' open={open} onEscapeKeyDown={onClose}>
      <Padding>
        <View>
          <PanelInPanelCloseButton onClose={onClose} label={t('dictionary.close')} />
          <Heading size='h5' bold avoidHanging={false}>
            {t('manage.programs.enrollment-rules.matching-users', {
              count,
            })}
          </Heading>
        </View>
        {open && <UserPreviewTable previewConfig={previewConfig} />}
      </Padding>
    </PanelInPanel>
  )
}
