import { useMutation } from '@tanstack/react-query'
import { DateTime } from 'luxon'
import { FC, default as React } from 'react'
import { toLocalTimeFormat } from 'sierra-client/core/format'
import { useTranslation } from 'sierra-client/hooks/use-translation'
import { selectPrependUrlWithProgramAndPath } from 'sierra-client/state/content/selectors'
import { useSelector } from 'sierra-client/state/hooks'
import {
  SelfEnrollSessionList,
  SelfEnrollSessionListData,
} from 'sierra-client/views/learner/program/components/self-enroll-session-list'
import { LocalSession, useSelfEnroll } from 'sierra-client/views/learner/self-enroll-live-session/hooks'
import { SelfEnrollSession } from 'sierra-domain/content/session'
import { ImageUnion } from 'sierra-domain/content/v2/content'
import { assertNever, iife } from 'sierra-domain/utils'
import { Button } from 'sierra-ui/primitives'

type SelfEnrollLiveSessionList = {
  courseId: string
  courseImage: ImageUnion | undefined
  sessions: SelfEnrollSession[]
  setIsEnrolled?: (sessionId: string, enroll: boolean) => void
}

const EnrollButton: FC<{
  session: LocalSession
  selfEnroll: (sessionId: string, enroll: boolean) => Promise<void>
}> = ({ session, selfEnroll }) => {
  const { t } = useTranslation()
  const doEnrollMutation = useMutation({
    mutationFn: (props: { sessionId: string; action: 'enroll' | 'unenroll' }) =>
      selfEnroll(props.sessionId, props.action === 'enroll'),
  })

  const sessionFull = session.totalSpots !== undefined && session.totalSpots <= session.usedSpots
  const buttonAction = session.isEnrolled ? ('unenroll' as const) : ('enroll' as const)

  return (
    <Button
      variant={buttonAction === 'enroll' ? 'secondary' : 'primary'}
      loading={doEnrollMutation.isPending}
      disabled={sessionFull && buttonAction === 'enroll'}
      onClick={() => doEnrollMutation.mutate({ sessionId: session.sessionId, action: buttonAction })}
    >
      {iife(() => {
        switch (buttonAction) {
          case 'enroll':
            return t('course-detail.enroll')
          case 'unenroll':
            return t('course-detail.enrolled')
          default:
            assertNever(buttonAction)
        }
      })}
    </Button>
  )
}

export const SelfEnrollLiveSessionList: React.FC<SelfEnrollLiveSessionList> = ({
  courseId,
  courseImage,
  setIsEnrolled,
  sessions: initialSessions,
}) => {
  const prependUrlWithProgramAndPath = useSelector(selectPrependUrlWithProgramAndPath)
  const { sessionList, selfEnroll } = useSelfEnroll(initialSessions, setIsEnrolled)

  const sessionsDataList = sessionList.map((it): SelfEnrollSessionListData<string> => {
    const start = DateTime.fromISO(it.startTime)
    const end = DateTime.fromISO(it.endTime)
    const time = `${start.toFormat('MMM d')}, ${toLocalTimeFormat(start)}–${toLocalTimeFormat(end)}`

    return {
      freeSpots: it.totalSpots !== undefined ? Math.max(0, it.totalSpots - it.usedSpots) : undefined,
      location: it.location?.value ?? 'Sana Live',
      sessionId: it.sessionId,
      time,
      title: it.title,
    }
  })

  return (
    <SelfEnrollSessionList
      browseAllLink={prependUrlWithProgramAndPath(`/course/${courseId}`)}
      courseImage={courseImage}
      courseId={courseId}
      sessions={sessionsDataList}
      renderButton={it => {
        const session = sessionList.find(session => session.sessionId === it.sessionId)
        if (!session) {
          return <></>
        }

        return <EnrollButton session={session} selfEnroll={selfEnroll} />
      }}
    />
  )
}
