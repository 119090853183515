import { UseQueryResult } from '@tanstack/react-query'
import { AnimatePresence, motion, useAnimation } from 'framer-motion'
import React, { useEffect } from 'react'
import { getWidgetIcon } from 'sierra-client/features/insights/display-widgets/utils'
import { insightsWidgetSearchLogger } from 'sierra-client/features/insights/logger'
import { useDebouncedState } from 'sierra-client/hooks/use-debounced-state'
import { useCachedQuery } from 'sierra-client/state/api'
import { useDispatch } from 'sierra-client/state/hooks'
import { InsightsSearchResponse } from 'sierra-domain/api/insights'
import { XAnalyticsSearch } from 'sierra-domain/routes'
import { Button, ScrollView } from 'sierra-ui/primitives'
import { token } from 'sierra-ui/theming'
import styled from 'styled-components'
import { HandleTemplateSelect } from './types'

const searchResultAnimations = {
  hidden: {
    opacity: 0,
    transition: {
      duration: 0.55,
    },
  },
  visible: {
    opacity: 1,
    transition: {
      duration: 0.55,
    },
  },
  exit: {
    opacity: 0,
    transition: {
      duration: 0.35,
    },
  },
}

const ResultButton = styled(Button)`
  background-color: ${token('form/background/3')};
  color: ${token('surface/default')};

  &:hover {
    background-color: ${token('form/background/3')};
  }
`
const StyledScrollView = styled(ScrollView).attrs({ animated: true })`
  &::-webkit-scrollbar {
    display: none;
  }

  scrollbar-width: none;

  & > div:last-child {
    margin-right: 24px;
  }
`

const useSearchInsights = (): {
  searchResults: UseQueryResult<InsightsSearchResponse>
  setSearchQuestion: (term: string) => void
} => {
  const [searchQuestion, setSearchQuestion] = useDebouncedState<string>('', { wait: 500 })
  const searchResults = useCachedQuery(
    XAnalyticsSearch,
    { question: searchQuestion !== '' ? searchQuestion : 'How' }, // Default query if searchQuery is empty }
    { staleTime: 10 * 1000 }
  )

  return {
    searchResults,
    setSearchQuestion,
  }
}

export const WidgetSearch: React.FC<{ searchQuery: string } & HandleTemplateSelect> = ({
  searchQuery,
  handleTemplateSelect,
}) => {
  const controls = useAnimation()
  const dispatch = useDispatch()
  const { searchResults, setSearchQuestion: setSearchQuery } = useSearchInsights()

  void setSearchQuery(searchQuery)

  useEffect(() => {
    if (searchResults.isPending || searchResults.isError) return
    if (searchResults.data.result.length < 1) {
      void controls.set('hidden')
    } else {
      void controls.start('visible')
    }
  }, [controls, searchResults])

  if (searchResults.isPending || searchResults.isError) return null
  return (
    <AnimatePresence>
      {searchResults.data.result.length > 0 && (
        <StyledScrollView
          direction='row'
          initial='hidden'
          animate={controls}
          exit='exit'
          variants={{
            visible: { transition: { staggerChildren: 0.08, delayChildren: 0 } },
            hidden: {},
            exit: {},
          }}
        >
          {searchResults.data.result.map(item => (
            <motion.div key={item.title} variants={searchResultAnimations}>
              <ResultButton
                icon={getWidgetIcon(item.widget.type)}
                onClick={() => {
                  handleTemplateSelect(item)
                  void dispatch(insightsWidgetSearchLogger({ searchQuery: item.title }))
                }}
              >
                {item.title}
              </ResultButton>
            </motion.div>
          ))}
        </StyledScrollView>
      )}
    </AnimatePresence>
  )
}
