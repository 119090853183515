import { useQueryClient } from '@tanstack/react-query'
import React from 'react'
import { usePost } from 'sierra-client/hooks/use-post'
import { getCachedQueryKey } from 'sierra-client/state/api'
import { ContentKind } from 'sierra-domain/api/learn'
import { FavoritableContentType } from 'sierra-domain/api/user'
import {
  XRealtimeContentListLearnerContent,
  XRealtimeUserAddFavorites,
  XRealtimeUserArchiveContent,
  XRealtimeUserRemoveFavorites,
  XRealtimeUserRemoveToDo,
  XRealtimeUserUnarchiveContent,
} from 'sierra-domain/routes'
import { assertNever } from 'sierra-domain/utils'

export const favoritableContentType = (
  learnerContentKind: ContentKind
): FavoritableContentType | undefined => {
  switch (learnerContentKind) {
    case 'native:self-paced':
    case 'native:course-group':
    case 'scorm:course-group':
    case 'linkedin':
    case 'scorm':
    case 'link':
    case 'homework':
      return 'course'
    case 'path':
      return 'path'
    case 'live-session':
      return 'live-session'
    case 'native:live':
    case 'program':
    case 'native:event-group':
      return undefined
    default:
      return assertNever(learnerContentKind)
  }
}

export const useUserContentHandler = ({
  initialFavorited,
  initialRemoved,
  initialArchived,
}: {
  initialFavorited: boolean
  initialRemoved: boolean
  initialArchived: boolean
}): {
  isFavorite: boolean
  addFavorite: (id: string, type: FavoritableContentType) => Promise<void>
  removeFavorite: (id: string, type: FavoritableContentType) => Promise<void>
  isRemovedFromTodo: boolean
  removeToDo: (id: string, type: FavoritableContentType) => Promise<void>
  isArchived: boolean
  archive: (id: string, type: FavoritableContentType) => Promise<void>
  unarchive: (id: string, type: FavoritableContentType) => Promise<void>
} => {
  const queryClient = useQueryClient()
  const { postWithUserErrorException } = usePost()
  const learnerContentQueryKey = getCachedQueryKey(XRealtimeContentListLearnerContent, {})

  const [isFavorite, setIsFavorite] = React.useState<boolean>(initialFavorited)
  const [isRemovedFromTodo, setIsRemovedFromTodo] = React.useState<boolean>(initialRemoved)
  const [isArchived, setIsArchived] = React.useState<boolean>(initialArchived)

  const addFavorite = async (id: string, type: FavoritableContentType): Promise<void> => {
    await postWithUserErrorException(XRealtimeUserAddFavorites, {
      items: [{ id, type }],
    })
    setIsFavorite(true)
    void queryClient.invalidateQueries({ queryKey: learnerContentQueryKey })
  }

  const removeFavorite = async (id: string, type: FavoritableContentType): Promise<void> => {
    await postWithUserErrorException(XRealtimeUserRemoveFavorites, {
      items: [{ id, type }],
    })
    setIsFavorite(false)
    void queryClient.invalidateQueries({ queryKey: learnerContentQueryKey })
  }

  const removeToDo = async (id: string, type: FavoritableContentType): Promise<void> => {
    await postWithUserErrorException(XRealtimeUserRemoveToDo, {
      items: [{ id, type }],
    })
    setIsRemovedFromTodo(true)
    void queryClient.invalidateQueries({ queryKey: learnerContentQueryKey })
  }

  const archive = async (id: string, type: FavoritableContentType): Promise<void> => {
    await postWithUserErrorException(XRealtimeUserArchiveContent, {
      items: [{ id, type }],
    })
    setIsArchived(true)
    void queryClient.invalidateQueries({ queryKey: learnerContentQueryKey })
  }

  const unarchive = async (id: string, type: FavoritableContentType): Promise<void> => {
    await postWithUserErrorException(XRealtimeUserUnarchiveContent, {
      items: [{ id, type }],
    })
    setIsArchived(false)
    void queryClient.invalidateQueries({ queryKey: learnerContentQueryKey })
  }

  return {
    isFavorite,
    addFavorite,
    removeFavorite,
    isRemovedFromTodo,
    removeToDo,
    isArchived,
    archive,
    unarchive,
  }
}
