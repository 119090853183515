import { createFileRoute } from '@tanstack/react-router'
import { useAtomValue } from 'jotai'
import { FC, useEffect, useRef, useState } from 'react'
import {
  getRootFolderOfTeamspace,
  useDeleteFolderMutation,
  useFolderAncestors,
  useFolderContent,
  useJoinTeamspace,
  useTeamspaceById,
  useUpdateTeamspaceFolderNameMutation,
} from 'sierra-client/api/hooks/use-teamspace'
import { IconMenu } from 'sierra-client/components/common/icon-menu'
import { RouterLink } from 'sierra-client/components/common/link'
import { ActionModal } from 'sierra-client/components/common/modals/action-modal'
import { PageTitle } from 'sierra-client/components/common/page-title'
import { TemplatePickerProvider } from 'sierra-client/components/templates'
import { requireLoggedIn } from 'sierra-client/core/require-logged-in'
import { GlobalSidebarOpenAtom } from 'sierra-client/features/global-sidebar'
import {
  CreateNewButtonWithOptions,
  GridContainer,
  NoContentContainer,
  PageHeader,
  PlainCreateNewButton,
  TeamspaceAccess,
  TeamspaceFolderModal,
  isTeamspaceRoleAbove,
  useCheckTeamspaceAccess,
  useTeamspaceAccessRedirect,
  useTeamspaceContentDrop,
} from 'sierra-client/features/teamspace'
import { folderDeletedLogger, folderViewedLogger } from 'sierra-client/features/teamspace/logger'
import { useTeamspacePermissions } from 'sierra-client/hooks/use-permissions'
import { useTranslation } from 'sierra-client/hooks/use-translation'
import { PageIdentifier, SanaPage } from 'sierra-client/layout/sana-page'
import { getGlobalRouter } from 'sierra-client/router'
import { useDispatch } from 'sierra-client/state/hooks'
import { FCC } from 'sierra-client/types'
import { PageContainer } from 'sierra-client/views/workspace/components'
import { TeamspaceContentTable } from 'sierra-client/views/workspace/teamspace/teamspace-content-table'
import { NanoId12 } from 'sierra-domain/api/nano-id'
import { FolderRow, Teamspace, TeamspaceContent } from 'sierra-domain/api/teamspace'
import { isDefined } from 'sierra-domain/utils'
import { Icon, MenuItem } from 'sierra-ui/components'
import { Button, Heading, LoadingSpinner, ScrollView, Text, View } from 'sierra-ui/primitives'
import { token } from 'sierra-ui/theming'
import { height_100dvh } from 'sierra-ui/utils'
import styled, { css } from 'styled-components'
import { z } from 'zod'

const StyledHeading = styled(Heading)`
  flex-grow: 1;
`

const MarginGridContainer = styled(GridContainer)`
  width: unset;
`

const FolderBreadcrumpItemContainer = styled.div<{ $isOver?: boolean }>`
  padding-inline-start: 3px;
  margin-inline-start: -3px;
  border-radius: 10px;

  ${Text} {
    max-width: 40ch;
    overflow: hidden;
    white-space: nowrap;
    text-overflow: ellipsis;
  }

  ${p =>
    p.$isOver === true
      ? css`
          outline: 2px solid ${p.theme.color.blueVivid};
        `
      : css`
          &:hover {
            background-color: ${token('form/background/2')};
          }
        `}
`

const FolderBreadcrumpItem: FCC<{
  href: string
  teamspace: Teamspace
  folder: FolderRow | undefined
}> = ({ href, folder, teamspace, children }) => {
  const { t } = useTranslation()
  const nodeRef = useRef<HTMLDivElement | null>(null)
  const { isOver, canDrop, drop, confirmModal } = useTeamspaceContentDrop(teamspace, folder)

  drop(nodeRef)

  return (
    <>
      <FolderBreadcrumpItemContainer ref={nodeRef} $isOver={isOver && canDrop}>
        <RouterLink href={href}>
          <View gap='4'>
            <Text color='foreground/muted' bold>
              {children}
            </Text>
            <Icon color='foreground/muted' iconId='chevron--right--small' />
          </View>
        </RouterLink>
      </FolderBreadcrumpItemContainer>

      <ActionModal
        open={confirmModal.isOpen}
        title={confirmModal.title}
        onClose={confirmModal.close}
        primaryAction={confirmModal.primaryAction}
        primaryActionLabel={t('dictionary.continue')}
      >
        {confirmModal.body}
      </ActionModal>
    </>
  )
}

const FolderBreadcrumb: FC<{
  teamspace: Teamspace
  currentFolderId: NanoId12
  folderAncestors: FolderRow[]
}> = ({ teamspace, currentFolderId, folderAncestors }) => {
  return (
    <View gap='4' wrap='wrap'>
      {folderAncestors.map(folder => {
        if (folder.displayName === 'root') {
          return (
            <FolderBreadcrumpItem
              key={folder.id}
              href={`/t/${teamspace.id}`}
              teamspace={teamspace}
              folder={folder}
            >
              {teamspace.displayName}
            </FolderBreadcrumpItem>
          )
        }

        if (folder.id === currentFolderId) {
          return (
            <Text key={folder.id} bold>
              {folder.displayName}
            </Text>
          )
        }

        return (
          <FolderBreadcrumpItem
            key={folder.id}
            href={`/t/${teamspace.id}/${folder.id}`}
            teamspace={teamspace}
            folder={folder}
          >
            {folder.displayName}
          </FolderBreadcrumpItem>
        )
      })}
    </View>
  )
}

const FolderPage: FC<{
  folder: FolderRow
  folderContent: TeamspaceContent[]
  teamspace: Teamspace
  folderAncestors: FolderRow[]
  reloadTeamspace: () => void
}> = ({ folder, folderContent, teamspace, folderAncestors, reloadTeamspace }) => {
  const { t } = useTranslation()
  const dispatch = useDispatch()

  const [renameModalOpen, setRenameModalOpen] = useState(false)
  const [deleteModalOpen, setDeleteModalOpen] = useState(false)

  const teamspaceRootFolder = getRootFolderOfTeamspace(teamspace)

  const isParentFolderRootFolder = folder.parentFolderId === teamspaceRootFolder?.id

  const allowEdit =
    teamspace.effectiveRole !== undefined && isTeamspaceRoleAbove(teamspace.effectiveRole, 'commenter')

  const renameFolderMutation = useUpdateTeamspaceFolderNameMutation()
  const deleteFolderMutation = useDeleteFolderMutation()

  const renameFolder = (displayName: string): void => {
    if (displayName.trim() === '') return

    renameFolderMutation.mutate(
      {
        teamspaceId: teamspace.id,
        folderId: folder.id,
        displayName: displayName.trim(),
      },
      {
        onSuccess: () => {
          setRenameModalOpen(false)
        },
      }
    )
  }

  const deleteFolder = (): void => {
    deleteFolderMutation.mutate(
      {
        teamspaceId: teamspace.id,
        folderId: folder.id,
      },
      {
        onSuccess: () => {
          const path =
            !isParentFolderRootFolder && folder.parentFolderId !== undefined
              ? `/t/${teamspace.id}/${folder.parentFolderId}`
              : `/t/${teamspace.id}/`

          setDeleteModalOpen(false)
          void getGlobalRouter().navigate({ to: path })
          void dispatch(folderDeletedLogger({ folderId: folder.id }))
        },
      }
    )
  }

  const menuItems: MenuItem[] = [
    {
      id: 'rename',
      type: 'label',
      label: t('admin.rename'),
      icon: 'edit',
      onClick: () => {
        setRenameModalOpen(true)
      },
    },
    {
      id: 'delete',
      type: 'label',
      color: 'destructive/background',
      label: t('teamspace.folder.remove-folder'),
      icon: 'trash-can',
      onClick: () => {
        setDeleteModalOpen(true)
      },
    },
  ]

  const teamspacePermissions = useTeamspacePermissions(teamspace.id)
  const isPublicTeamspace = teamspace.visibility === 'public'
  const isPublicTeamspaceAndCanJoin = isPublicTeamspace && teamspacePermissions.has('JOIN')
  const { join } = useJoinTeamspace(teamspace.id)
  const sidebarOpen = useAtomValue(GlobalSidebarOpenAtom)

  const [scrollRef, setScrollRef] = useState<HTMLDivElement | null>(null)

  const { isOver, canDrop, drop, confirmModal } = useTeamspaceContentDrop(teamspace, folder)
  drop(scrollRef)

  return (
    <>
      <PageTitle title={`${folder.displayName} - ${teamspace.displayName}`} />
      <ScrollView ref={setScrollRef} grow gap='none'>
        <PageContainer $sidebarOpen={sidebarOpen}>
          <PageHeader>
            <View justifyContent='space-between' paddingBottom='32'>
              <FolderBreadcrumb
                teamspace={teamspace}
                currentFolderId={folder.id}
                folderAncestors={folderAncestors}
              />

              <View>
                {allowEdit && folder.parentFolderId !== undefined && !isPublicTeamspaceAndCanJoin && (
                  <View gap='12'>
                    <TemplatePickerProvider teamspaceId={teamspace.id} folderId={folder.id}>
                      <CreateNewButtonWithOptions
                        parentFolder={{ folderId: folder.id, displayName: folder.displayName }}
                        teamspaceId={teamspace.id}
                        teamspaceName={teamspace.displayName}
                      />
                    </TemplatePickerProvider>
                    <IconMenu items={menuItems} color='foreground/muted' closeOnPick aria-label='menu' />
                  </View>
                )}

                {isPublicTeamspaceAndCanJoin && (
                  <Button
                    variant='primary'
                    onClick={() => {
                      join()
                      reloadTeamspace()
                    }}
                  >
                    {t('dictionary.join')}
                  </Button>
                )}
              </View>
            </View>
            <View justifyContent='space-between'>
              <StyledHeading bold size='h5'>
                {folder.displayName}
              </StyledHeading>
            </View>
          </PageHeader>

          <MarginGridContainer>
            <TeamspaceContentTable
              isOver={isOver && canDrop}
              currentFolderId={folder.id}
              teamspace={teamspace}
              scrollElement={scrollRef}
              content={folderContent}
              loadContent={() => {}}
              noContentComponent={
                <NoContentContainer>
                  <Icon size='size-24' color='foreground/muted' iconId='folder--shared' />
                  <View
                    direction='column'
                    gap='4'
                    justifyContent='center'
                    alignItems='center'
                    marginBottom='8'
                  >
                    <Text color='foreground/muted' bold>
                      {folder.displayName} {t('teamspace.is-empty')}
                    </Text>
                    <Text color='foreground/muted'>
                      {allowEdit
                        ? t('teamspace.no-content.folder.edit')
                        : t('teamspace.no-content.folder.no-edit')}
                    </Text>
                  </View>
                  {allowEdit && (
                    <TemplatePickerProvider teamspaceId={teamspace.id} folderId={folder.id}>
                      <PlainCreateNewButton variant='secondary' />
                    </TemplatePickerProvider>
                  )}
                </NoContentContainer>
              }
            />
          </MarginGridContainer>
        </PageContainer>
      </ScrollView>
      <TeamspaceFolderModal
        modalOpen={renameModalOpen}
        onClose={() => {
          setRenameModalOpen(false)
        }}
        onConfirm={(name: string) => {
          renameFolder(name)
        }}
        defaultFolderName={folder.displayName}
        confirmLabel={t('dictionary.save')}
      />
      <ActionModal
        open={deleteModalOpen}
        onClose={() => setDeleteModalOpen(false)}
        primaryAction={deleteFolder}
        title={t('teamspace.folder.remove-folder')}
        primaryActionLabel={t('dictionary.delete')}
        deleteAction
      >
        {t('teamspace.folder.delete-body-text')}
      </ActionModal>
      <ActionModal
        open={confirmModal.isOpen}
        title={confirmModal.title}
        onClose={confirmModal.close}
        primaryAction={confirmModal.primaryAction}
        primaryActionLabel={t('dictionary.continue')}
      >
        {confirmModal.body}
      </ActionModal>
    </>
  )
}

const Page: FC<{ teamspaceId: NanoId12; folderId: NanoId12 }> = ({ teamspaceId, folderId }) => {
  const folderContentQuery = useFolderContent(teamspaceId, folderId)
  const teamspaceQuery = useTeamspaceById(teamspaceId)

  const folderAncestorsQuery = useFolderAncestors(teamspaceId, folderId, {
    select(data) {
      return [...data].reverse()
    },
  })

  const dispatch = useDispatch()

  // Segment logging for viewed folder
  useEffect(() => {
    void dispatch(
      folderViewedLogger({
        folderId,
      })
    )
  }, [folderId, dispatch])

  if (
    !(
      isDefined(teamspaceQuery.data) &&
      isDefined(folderContentQuery.data) &&
      isDefined(folderAncestorsQuery.data)
    )
  ) {
    return <LoadingSpinner />
  }

  const teamspaceEffectiveRole = teamspaceQuery.data.effectiveRole

  const folder: FolderRow = {
    type: 'folder',
    id: folderContentQuery.data.id,
    displayName: folderContentQuery.data.displayName,
    parentFolderId: folderContentQuery.data.parentFolderId,
    createdAt: folderContentQuery.data.createdAt,
    updatedAt: folderContentQuery.data.updatedAt,
    teamspaceId: teamspaceId,
    teamspaceEffectiveRole: teamspaceEffectiveRole,
  }

  const folderAncestors: FolderRow[] = folderAncestorsQuery.data.map(folder => ({
    type: 'folder',
    id: folder.id,
    displayName: folder.displayName,
    parentFolderId: folder.parentFolderId,
    createdAt: folder.createdAt,
    updatedAt: folder.updatedAt,
    teamspaceId: teamspaceId,
    teamspaceEffectiveRole: teamspaceEffectiveRole,
  }))

  return (
    <SanaPage mode='light' page={PageIdentifier.NewCreatePage()}>
      <FolderPage
        folder={folder}
        folderContent={folderContentQuery.data.content}
        teamspace={teamspaceQuery.data}
        folderAncestors={folderAncestors}
        reloadTeamspace={teamspaceQuery.refetch}
      />
    </SanaPage>
  )
}

const LoadingContainer = styled(View)`
  position: absolute;
  inset: 0;
  width: 100vw;

  ${height_100dvh}
  & > * {
    margin: auto;
  }
`

const requireTeamspacePermission =
  <T extends Record<string, unknown>>(Component: typeof Page): React.ComponentType<T> =>
  () => {
    const { teamspaceId, folderId } = Route.useParams()

    const access = useCheckTeamspaceAccess(teamspaceId)

    useTeamspaceAccessRedirect(access)

    switch (access) {
      case TeamspaceAccess.ALLOWED:
        return <Component teamspaceId={teamspaceId} folderId={folderId} />

      case TeamspaceAccess.DENIED: {
        return null
      }

      case TeamspaceAccess.ERROR:
        return null

      case TeamspaceAccess.LOADING:
        return (
          <LoadingContainer>
            <View direction='column'>
              <LoadingSpinner />
            </View>
          </LoadingContainer>
        )
    }
  }

export const Route = createFileRoute('/t/$teamspaceId/$folderId')({
  component: requireLoggedIn(requireTeamspacePermission(Page)) as React.FC,
  params: {
    parse: z.object({ teamspaceId: NanoId12, folderId: NanoId12 }).parse,
    stringify: params => params,
  },
})
