import { useCallback, useEffect, useRef, useState } from 'react'
import { isNonNullable } from 'sierra-domain/utils'

type FakeProgressReturnType = {
  value: number
  startInfiniteProgress: (onUpdate?: (value: number) => void) => void
  finishProgress: (onUpdate?: (value: number) => void) => Promise<void>
}

export const useFakeProgress = ({
  target,
  finishDuration,
}: {
  target: number
  finishDuration: number
}): FakeProgressReturnType => {
  const intervalRef = useRef<ReturnType<typeof setInterval> | null>(null)
  const [value, setValue] = useState<number>(0)
  const valueRef = useRef<number>(value)
  valueRef.current = value

  const updateValue = useCallback((newValue: number) => {
    setValue(newValue)
    valueRef.current = newValue
  }, [])

  const clear = useCallback(() => {
    if (isNonNullable(intervalRef.current)) {
      clearInterval(intervalRef.current)
      intervalRef.current = null
      setValue(0)
    }
  }, [])

  const startInfiniteProgress = useCallback<FakeProgressReturnType['startInfiniteProgress']>(
    onUpdate => {
      const startTime = Date.now()

      clear()

      intervalRef.current = setInterval(() => {
        const t = (Date.now() - startTime) / 10000 // Dividing time by 10000 for a slower increment
        const easedValue = 1 - Math.pow(Math.E, -3 * t) // Exponential ease-out
        const res = target * easedValue
        updateValue(res)
        onUpdate?.(res)
      }, 20)
    },
    [clear, target, updateValue]
  )

  const finishProgress = useCallback<FakeProgressReturnType['finishProgress']>(
    onUpdate => {
      return new Promise(resolve => {
        const startTime = Date.now()

        clear()

        intervalRef.current = setInterval(() => {
          const startValue = valueRef.current
          const elapsed = Date.now() - startTime

          if (elapsed >= finishDuration) {
            updateValue(100)
            onUpdate?.(100)
            clear()
            resolve()
          } else {
            const t = elapsed / finishDuration
            const res = startValue + t * (100 - startValue) // Linear increment to 100
            updateValue(res)
            onUpdate?.(res)
          }
        }, 20)
      })
    },
    [clear, finishDuration, updateValue]
  )

  useEffect(() => {
    return clear
  }, [clear])

  return { value, startInfiniteProgress, finishProgress }
}
