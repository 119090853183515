/* eslint-disable @typescript-eslint/no-unused-vars */

export abstract class Auth {
  private static instance: Auth | undefined

  public static initialize(instance: Auth): void {
    this.instance = instance
    this.instance.initialize()
  }

  public static isInitialized(): boolean {
    return Auth.instance !== undefined
  }

  public static getInstance(): Auth {
    if (Auth.instance !== undefined) {
      return Auth.instance
    } else {
      throw Error('Instance not initialized')
    }
  }

  initialize(): void {}

  signInWithEmailAndPassword(email: string, password: string): Promise<void> {
    throw new Error('Not implemented')
  }

  signInWithToken(token: string): Promise<void> {
    throw new Error('Not implemented')
  }

  signOut(): Promise<void> {
    throw new Error('Not implemented')
  }

  confirmPasswordReset(code: string, newPassword: string): Promise<void> {
    throw new Error('Not implemented')
  }

  getToken(): string | undefined {
    return undefined
  }

  getCookie(): string | undefined {
    return undefined
  }

  synchronize(): Promise<void> {
    throw new Error('Not implemented')
  }
}
