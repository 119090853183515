import { createFileRoute } from '@tanstack/react-router'

import { PageIdentifier } from 'sierra-client/layout/sana-page'
import { ManageEventGroups } from 'sierra-client/views/manage/event-groups/manage-event-groups'
import { ManagePageWrapper } from 'sierra-client/views/manage/utils/manage-page-wrapper'

function ManageEventGroupPage(): JSX.Element | null {
  return (
    <ManagePageWrapper pageIdentifier={PageIdentifier.ManageEventGroups()}>
      <ManageEventGroups />
    </ManagePageWrapper>
  )
}

const RouteComponent = ManageEventGroupPage

export const Route = createFileRoute('/manage/in-person-events/')({
  component: RouteComponent as React.FC,
})
