import { UseQueryResult } from '@tanstack/react-query'
import { useCachedQuery } from 'sierra-client/state/api'
import { ContentType, GeneralSearchResponse } from 'sierra-domain/api/search'
import { XRealtimeUniversalSearchSearch } from 'sierra-domain/routes'
import { isNonEmptyString } from 'sierra-domain/utils'

interface UseInternalSearchProps {
  query: string
  contentTypes: Array<ContentType>
}

export function useInternalSearch({
  query,
  contentTypes,
}: UseInternalSearchProps): UseQueryResult<GeneralSearchResponse, unknown> {
  const results = useCachedQuery(
    XRealtimeUniversalSearchSearch,
    {
      structuredQuery: {
        text: query,
        structured: [],
      },
      filters: {
        searchSources: ['sana'],
        contentTypes,
      },
    },
    {
      enabled: isNonEmptyString(query.trim()),
    }
  )

  return results
}
