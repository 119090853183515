import { zodResolver } from '@hookform/resolvers/zod'
import React from 'react'
import { Controller, useForm } from 'react-hook-form'
import { SkillLevelSettingId } from 'sierra-client/api/graphql/branded-types'
import { SkillLevel } from 'sierra-client/api/graphql/gql/graphql'
import { getHighestSkillLevelSettingId } from 'sierra-client/features/skills/helpers'
import { useTranslation } from 'sierra-client/hooks/use-translation'
import { FormSingleSelectDropdown } from 'sierra-client/views/manage/shared/form'
import { isNonNullable } from 'sierra-domain/utils'
import { FormElement } from 'sierra-ui/components'
import { PanelInPanelCloseButton } from 'sierra-ui/components/layout-kit/layout-panel'
import { Button, Heading, Spacer, Text, View } from 'sierra-ui/primitives'
import styled from 'styled-components'
import z from 'zod'
import { useTracking } from '../../tracking'
import { AchievedLevelSelector, targetIsHigherOrEqualToAchieved } from './common'

const Padding = styled(View).attrs({ direction: 'column', gap: 'none' })`
  padding: 32px 40px 0 40px;
  height: 100%;
  overflow: hidden;
`

const Form = styled.form`
  display: flex;
  flex-direction: column;
  height: 100%;
  gap: 0;
  overflow: hidden;
  padding-top: 8px;
  justify-content: space-between;
`

const Footer = styled(View).attrs({
  justifyContent: 'flex-end',
  alignItems: 'center',
})`
  min-height: 90px;
  position: sticky;
  bottom: 0;
  padding-bottom: 24px;
`

const Top: React.FC<{ setBulkEditing: (open: boolean) => void }> = ({ setBulkEditing }) => {
  const { t } = useTranslation()

  const close = (): void => {
    setBulkEditing(false)
  }

  return (
    <View gap='8'>
      <PanelInPanelCloseButton onClose={close} label={t('dictionary.close')} />
      <Heading bold size='h5'>
        {t('manage.users.invite.bulk-editing.edit-all-users')}
      </Heading>
    </View>
  )
}

const Input = styled(View).attrs({ direction: 'column', gap: 'none' })`
  flex: 1;
`

const UserLevels = z.object({
  targetLevel: SkillLevelSettingId.optional(),
  achievedLevelUpTo: SkillLevelSettingId.optional(),
})

const FormSchema = z.object({
  levels: UserLevels,
})

const useFormSchema = (levels: Array<SkillLevel>): z.ZodEffects<typeof FormSchema> => {
  return FormSchema.superRefine((schema, ctx) => {
    const isValid = targetIsHigherOrEqualToAchieved({
      target: schema.levels.targetLevel,
      achieved: schema.levels.achievedLevelUpTo,
      levels,
    })
    if (!isValid) {
      ctx.addIssue({
        code: z.ZodIssueCode.custom,
        message: 'Target level must be higher or equal to any achieved level',
        path: ['levels', 'achievedLevelUpTo'],
      })
    }
  })
}

type BulkFormData = z.infer<typeof FormSchema>

export const BulkAssignUsersPanel: React.FC<{
  setBulkEditing: (open: boolean) => void
  setTargetLevel: (level?: SkillLevelSettingId) => void
  setAchievedLevel: (level?: SkillLevelSettingId) => void
  levels: SkillLevel[]
}> = ({ setBulkEditing, levels, setAchievedLevel, setTargetLevel }) => {
  const tracking = useTracking()
  const { t } = useTranslation()

  const highestSkillLevelId = getHighestSkillLevelSettingId(levels)

  const schema = useFormSchema(levels)

  const handler = useForm<BulkFormData>({
    resolver: zodResolver(schema),
    values: {
      levels: { targetLevel: highestSkillLevelId },
    },
  })

  const { isLoading } = handler.formState

  const onSubmit = (data: BulkFormData): void => {
    setTargetLevel(data.levels.targetLevel)
    setBulkEditing(false)
    setAchievedLevel(data.levels.achievedLevelUpTo)
    tracking.learner.bulkEdit({
      targetLevel: data.levels.targetLevel,
      achievedLevel: data.levels.achievedLevelUpTo,
    })
  }

  const onClose = (): void => {
    handler.reset()
    setBulkEditing(false)
  }

  const onBlur = async (): Promise<void> => {
    await handler.trigger()
  }

  return (
    <Padding>
      <Top setBulkEditing={setBulkEditing} />
      <Text>{t('manage.skills.bulk-edit.description')}</Text>
      <Spacer />
      <Form onSubmit={handler.handleSubmit(onSubmit)} onBlur={onBlur}>
        <View alignItems='flex-start' gap='16'>
          <Input>
            <Text bold>{t('skills.panel.subscribe-user.target-level')}</Text>
            <Spacer size='4' />
            <FormSingleSelectDropdown
              control={handler.control}
              name={'levels.targetLevel'}
              menuItems={levels.map(l => ({
                label: l.levelSetting.name,
                id: l.levelSetting.id,
                value: l.levelSetting.index,
                type: 'label',
              }))}
            />
          </Input>
          <Input>
            <Text bold>{t('skills.panel.subscribe-user.achieved-levels')}</Text>
            <Spacer size='4' />
            <Controller
              name={`levels.achievedLevelUpTo`}
              control={handler.control}
              render={({ field, fieldState }) => (
                <FormElement
                  isError={isNonNullable(fieldState.error)}
                  helper={fieldState.error ? fieldState.error.message : undefined}
                  label={undefined}
                >
                  <AchievedLevelSelector {...field} levels={levels} />
                </FormElement>
              )}
            />
          </Input>
        </View>
        <Footer>
          <View>
            <Button type='button' variant='secondary' onClick={onClose}>
              {t('cancel')}
            </Button>
            <Button type='submit' loading={isLoading}>
              {t('manage.skills.bulk-edit.apply-to-all')}
            </Button>
          </View>
        </Footer>
      </Form>
    </Padding>
  )
}
