import React from 'react'
import { AnimationContainer } from 'sierra-client/features/insights/widget-builder/data-selectors/animation-container'
import { TimeFilterSelector } from 'sierra-client/features/insights/widget-builder/data-selectors/time-filter-selector'
import { useTranslation } from 'sierra-client/hooks/use-translation'
import { TimeFilter } from 'sierra-domain/api/insights'
import { Button, Text, View } from 'sierra-ui/primitives'
import styled from 'styled-components'

const ClearSelectorButton = styled(Button).attrs({ variant: 'transparent' })`
  height: 100%;
`

export const TimeFilterContainer: React.FC<{
  selectedTimeFilter?: TimeFilter
  onChange: (timeFilter?: TimeFilter) => void
}> = ({ selectedTimeFilter, onChange }) => {
  const { t } = useTranslation()

  return (
    <AnimationContainer direction='column'>
      <View justifyContent='space-between'>
        <Text color='foreground/primary' bold>
          {t('manage.insights.widgetbuilder.timerange')}
        </Text>
        <View hide={selectedTimeFilter === undefined}>
          <ClearSelectorButton onClick={() => onChange(undefined)}>
            {t('dictionary.clear')}
          </ClearSelectorButton>
        </View>
      </View>
      <TimeFilterSelector selectedTimeFilter={selectedTimeFilter} onChange={onChange} />
    </AnimationContainer>
  )
}
