import { useEffect, useState } from 'react'
import { Text, View } from 'sierra-ui/primitives'
import { token } from 'sierra-ui/theming/tokens/tokens'
import styled from 'styled-components'

const ProgressShadow = styled.div`
  background-color: ${token('foreground/primary').opacity(0.2)};
  height: 20px;
  border-radius: 10px;
  width: 40%;
  overflow: hidden;
`
const Progress = styled.div<{ width: number }>`
  background-color: ${token('foreground/primary')};
  height: 20px;
  border-radius: 10px;
  width: ${({ width }) => Math.max(width, 0)}%;
  transition: width 0.5s ease;
`
const StyledView = styled(View)`
  display: flex;
  align-items: center;
  gap: 1rem;
`
const PointsContainer = styled.div`
  display: flex;
  width: 100px;
  gap: 4px;
`
const NumberText = styled(Text)`
  white-space: nowrap;
`
const LabelText = styled(Text)`
  white-space: nowrap;
`

export const ProgressBar: React.FC<{ startPoints: number }> = ({ startPoints }) => {
  const [points, setPoints] = useState(startPoints)
  useEffect(() => {
    const interval = setInterval(() => {
      setPoints(prevPoints => {
        if (prevPoints > 0) {
          return Math.max(prevPoints - 5, 0)
        } else {
          clearInterval(interval)
          return 0
        }
      })
    }, 150)
    return () => clearInterval(interval)
  }, [])
  const progressWidth = (points / 1000) * 100

  return (
    <StyledView gap='16'>
      <ProgressShadow>
        <Progress width={progressWidth} />
      </ProgressShadow>
      <PointsContainer>
        <NumberText size='large' style={{ fontVariantNumeric: 'tabular-nums' }} bold>
          {points}
        </NumberText>
        <LabelText size='large'>points</LabelText>
      </PointsContainer>
    </StyledView>
  )
}
