import React from 'react'
import { IssuedCertificate } from 'sierra-client/api/graphql/gql/graphql'
import { useSelector } from 'sierra-client/state/hooks'
import { selectUser } from 'sierra-client/state/user/user-selector'

export const getCertificateSharingLinkedinURL = (issuedCertificate: {
  name: string
  organizationId: string
  organizationName: string
  issueYear: string
  issueMonth: string
  expirationYear: string
  expirationMonth: string
  certId: string
}): string => {
  const issuedCertificateClean = Object.fromEntries(
    Object.entries(issuedCertificate).filter(([key, value]) => {
      if (key === 'organizationId' && value === '') {
        return false
      } else if (key === 'organizationName' && issuedCertificate.organizationId !== '') {
        return false
      }
      return true
    })
  )
  const linkParams = new URLSearchParams(issuedCertificateClean)
  return `https://www.linkedin.com/profile/add?startTask=CERTIFICATION_NAME&${linkParams.toString()}`
}

// Only grab the necessary values from issued certificate
type IssuedCertificateLike = Pick<IssuedCertificate, 'issuedAt' | 'expiresAt' | 'id'> & {
  certificate: Pick<IssuedCertificate['certificate'], 'title'>
}
/**
 * Helper to generate a linkedIn sharing url for you certificate.
 * It is a callback to allow for lazily grab it whenever needed that allows for easier code branching without react hooks
 * stopping you
 *
 * */
export const useGetIssuedCertificateSharingLinkedInURL = (): ((_: IssuedCertificateLike) => string) => {
  const user = useSelector(selectUser)
  const orgName = user?.organizationName ?? undefined
  const linkedInOrgId = user?.linkedInOrganizationId ?? undefined

  return React.useCallback(
    (issuedCertificate: IssuedCertificateLike) => {
      const title = issuedCertificate.certificate.title
      const issueDate = new Date(issuedCertificate.issuedAt)
      const issueYear = issueDate.getFullYear()
      const issueMonth = issueDate.getMonth() + 1
      const expiryDate =
        issuedCertificate.expiresAt !== null && issuedCertificate.expiresAt !== undefined
          ? new Date(issuedCertificate.expiresAt)
          : undefined
      const expiryYear = expiryDate?.getFullYear().toString()
      const expiryMonth = expiryDate ? expiryDate.getMonth() + 1 : undefined
      const issuedCertificateId = issuedCertificate.id

      const linkedInUrl = getCertificateSharingLinkedinURL({
        name: title,
        organizationId: linkedInOrgId ?? '',
        organizationName: orgName ?? '',
        issueYear: issueYear.toString(),
        issueMonth: issueMonth.toString(),
        expirationYear: expiryYear ?? '',
        expirationMonth: expiryMonth?.toString() ?? '',
        certId: issuedCertificateId,
      })

      return linkedInUrl
    },
    [orgName, linkedInOrgId]
  )
}
