import { getBrowserSessionTimestamp } from 'sierra-client/components/browser/update-browser-session'
import { createGlobalContext } from 'sierra-client/context/create-context'
import { getOSAndBrowserContext, segmentService } from 'sierra-client/core/segment'
import { UserSingleton } from 'sierra-client/core/user'
import { PageCategory, PageName } from 'sierra-domain/segment/page'

export type PageState = {
  category?: PageCategory
  name?: PageName
  properties?: {
    number?: number
  }
  requiresPageNumber?: boolean
  // TODO(elyes): fix anonymous identifies
  // anonymousId?: string
}

const initialState: PageState = {}

export const logPageToSegment = (state: PageState): void => {
  const userId = UserSingleton.getInstance().getUser()?.uuid
  const browserSessionTimestamp = getBrowserSessionTimestamp()
  // TODO(elyes): fix anonymous identifies
  const { category, name, properties, requiresPageNumber } = state

  const validRequiresPageNumber =
    ((requiresPageNumber ?? false) && properties?.number !== undefined) ||
    (!(requiresPageNumber ?? false) && properties?.number === undefined)

  if (!validRequiresPageNumber) {
    return
  }
  // TODO(elyes) fix anonymous identifies
  // if (!(userId || anonymousId) || !browserSessionTimestamp || !category || !name) {

  if (
    userId === undefined ||
    browserSessionTimestamp === null ||
    category === undefined ||
    name === undefined
  ) {
    return
  }

  // TODO(elyes) fix anonymous identifies
  // Allow anonymousId if we are on signup page. Because the user hasn't any userId yet.
  // if (!userId && anonymousId && name !== PageName.CreateAccount) { return }

  const pageRequest = {
    category,
    name,
    integrations: {
      Amplitude: { session_id: browserSessionTimestamp },
    },
    properties: {
      ...(properties ?? {}),
      url: window.location.href,
      path: window.location.pathname,
      referrer: window.document.referrer,
      title: window.document.title,
      // anonymous: !userId,
      anonymous: false,
    },
    context: getOSAndBrowserContext(),
  }

  void segmentService.page({ ...pageRequest, userId })

  // TODO(elyes): fix anonymous identifies
  // } else if (anonymousId) {
  //   segmentService.page({ ...pageRequest, anonymousId })
  // }
}

export const PageContext = createGlobalContext<PageState>(initialState, logPageToSegment)
