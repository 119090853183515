import { Atom, atom, useAtomValue } from 'jotai'
import React, { useEffect, useMemo } from 'react'
import { ApplicationNotifications } from 'sierra-client/components/common/notifications'
import { versionHistoryAccessed } from 'sierra-client/core/logging/authoring/logger'
import { RequireCoursePermission } from 'sierra-client/core/require-course-permission'
import { VersionHistoryCenterContent } from 'sierra-client/editor/version-history/center-content'
import { VersionHistoryHeader } from 'sierra-client/editor/version-history/header'
import { VersionHistoryLeftSidebar } from 'sierra-client/editor/version-history/left-sidebar'
import { NavigateToDefaultFile } from 'sierra-client/editor/version-history/navigate-to-default-file'
import { ResolveReplayUpdatesContext } from 'sierra-client/editor/version-history/replay-updates-context'
import {
  VersionHistoryRightFoldingColumn,
  VersionHistoryRightSidebar,
} from 'sierra-client/editor/version-history/right-sidebar'
import {
  HistoryWithTargetIndexAtom,
  useHistoryWithTargetIndexAtom,
} from 'sierra-client/editor/version-history/use-history-with-target-index-atom'
import { useVersionHistory } from 'sierra-client/editor/version-history/utils/use-version-history'
import { GlobalNestedSidebar } from 'sierra-client/features/global-sidebar'
import { useCreatePageYdoc } from 'sierra-client/hooks/use-create-page-ydoc'
import { accessDeniedRedirect, contentNotFoundRedirect } from 'sierra-client/router/navigation'
import { useDispatch } from 'sierra-client/state/hooks'
import { CreatePageSidebarSkeleton } from 'sierra-client/views/flexible-content/editor/content-sidebar/create-page-sidebar-skeleton'
import { CreatePageTitle } from 'sierra-client/views/flexible-content/editor/topbar/create-page-title'
import { Debug } from 'sierra-client/views/learner/components/debug'
import { AssetContext } from 'sierra-domain/asset-context'
import { ScopedCreateContentId } from 'sierra-domain/collaboration/types'
import { FileId } from 'sierra-domain/flexible-content/identifiers'
import { ExpandRange, ReplayUpdatesContext } from 'sierra-domain/version-history/types'
import { Column, ColumnContainer, Layout } from 'sierra-ui/components'
import { Text, View } from 'sierra-ui/primitives'
import styled from 'styled-components'

const PageColumnContainer = styled(ColumnContainer)`
  overflow: hidden;
`

const DebugContextIsLoading: React.FC<{ contextAtom: Atom<ReplayUpdatesContext> }> = ({ contextAtom }) => {
  const context = useAtomValue(contextAtom)
  if (context.type === 'loaded') {
    return null
  }

  return (
    <Debug>
      {
        // eslint-disable-next-line react/jsx-no-literals
        'Loading context'
      }
    </Debug>
  )
}

function _EditAccessVersionHistoryPage({
  historyWithTargetIndexAtom,
  scopedContentId,
  nodeId,
  assetContext,
  setExpandedRanges,
  expandedRanges,
}: {
  historyWithTargetIndexAtom: HistoryWithTargetIndexAtom | undefined
  scopedContentId: ScopedCreateContentId
  nodeId: FileId | undefined
  assetContext: AssetContext
  setExpandedRanges: React.Dispatch<React.SetStateAction<ExpandRange[]>>
  expandedRanges: ExpandRange[]
}): JSX.Element {
  const createPageYDoc = useCreatePageYdoc(scopedContentId)
  const contentType = ScopedCreateContentId.contentType(scopedContentId)
  const contentId = ScopedCreateContentId.extractId(scopedContentId)

  const replayUpdatesContextAtom = useMemo(() => atom<ReplayUpdatesContext>({ type: 'loading' }), [])

  const editHref =
    contentType === 'self-paced'
      ? `/create/s/${contentId}/${nodeId ?? ''}`
      : `/create/l/${contentId}/${nodeId ?? ''}`

  return (
    <>
      {historyWithTargetIndexAtom !== undefined && (
        <ResolveReplayUpdatesContext
          replayUpdateContextAtom={replayUpdatesContextAtom}
          targetIndexAtom={historyWithTargetIndexAtom.targetIndexAtom}
          history={historyWithTargetIndexAtom.history}
          contentId={contentId}
        />
      )}

      <ApplicationNotifications />
      {nodeId === undefined && (
        <NavigateToDefaultFile
          contentId={contentId}
          mode={contentType}
          replayContextAtom={replayUpdatesContextAtom}
        />
      )}

      <GlobalNestedSidebar
        renderSidebar={() => (
          <>
            <View paddingLeft='8' gap='8' marginBottom='10'>
              <CreatePageTitle createContentId={contentId} editable={false} />
            </View>
            {historyWithTargetIndexAtom !== undefined ? (
              <nav>
                <VersionHistoryLeftSidebar
                  createPageYDoc={createPageYDoc}
                  mode={contentType}
                  contextAtom={replayUpdatesContextAtom}
                  scopedCreateContentId={scopedContentId}
                  nodeId={'folder:root'}
                  selectedNode={nodeId}
                  history={historyWithTargetIndexAtom.history}
                />
              </nav>
            ) : (
              <CreatePageSidebarSkeleton />
            )}
          </>
        )}
      />
      <Layout>
        <VersionHistoryHeader
          editHref={editHref}
          contentId={contentId}
          historyWithTargetIndexAtom={historyWithTargetIndexAtom}
          assetContext={assetContext}
          replayUpdatesContextAtom={replayUpdatesContextAtom}
        />
        <PageColumnContainer gap='8' paddingLeft='8' paddingRight='8' paddingBottom='8'>
          <Column disableScrollbarGutter={true}>
            {historyWithTargetIndexAtom !== undefined && (
              <VersionHistoryCenterContent
                history={historyWithTargetIndexAtom.history}
                targetIndexAtom={historyWithTargetIndexAtom.targetIndexAtom}
                scopedCreateContentId={scopedContentId}
                replayUpdatesContextAtom={replayUpdatesContextAtom}
                nodeId={nodeId}
                assetContext={assetContext}
              />
            )}

            <DebugContextIsLoading contextAtom={replayUpdatesContextAtom} />

            {historyWithTargetIndexAtom === undefined && (
              <Debug>
                <Text>
                  {
                    // eslint-disable-next-line react/jsx-no-literals
                    'Loading History'
                  }
                </Text>
              </Debug>
            )}
          </Column>

          <VersionHistoryRightFoldingColumn open={true} width={294} disableScrollbarGutter>
            <VersionHistoryRightSidebar
              expandedRanges={expandedRanges}
              setExpandedRanges={setExpandedRanges}
              nodeId={nodeId}
              historyWithTargetIndexAtom={historyWithTargetIndexAtom}
            />
          </VersionHistoryRightFoldingColumn>
        </PageColumnContainer>
      </Layout>
    </>
  )
}

function EditAccessVersionHistoryPage({
  selectedPublishVersion,
  scopedContentId,
  nodeId,
  assetContext,
}: {
  selectedPublishVersion: string | undefined
  scopedContentId: ScopedCreateContentId
  nodeId: FileId | undefined
  assetContext: AssetContext
}): JSX.Element {
  const [expandedRanges, setExpandedRanges] = React.useState<ExpandRange[]>([])
  const historyWithTargetIndexAtom = useHistoryWithTargetIndexAtom(
    useVersionHistory(scopedContentId, expandedRanges),
    {
      selectedPublishVersion,
    }
  )

  return (
    <_EditAccessVersionHistoryPage
      setExpandedRanges={setExpandedRanges}
      expandedRanges={expandedRanges}
      historyWithTargetIndexAtom={historyWithTargetIndexAtom}
      scopedContentId={scopedContentId}
      nodeId={nodeId}
      assetContext={assetContext}
    />
  )
}

export function VersionHistoryPage({
  publishVersion,
  scopedContentId,
  nodeId,
  assetContext,
}: {
  publishVersion: string | undefined
  scopedContentId: ScopedCreateContentId
  nodeId: FileId | undefined
  assetContext: AssetContext
}): JSX.Element {
  const contentId = ScopedCreateContentId.extractId(scopedContentId)

  const dispatch = useDispatch()
  useEffect(() => {
    const windowLocationHref = new URL(window.location.href)
    const subPath = ScopedCreateContentId.urlSubPathForId(scopedContentId)
    const contentLink = `${windowLocationHref.origin}/history/${subPath}/${contentId}`

    void dispatch(
      versionHistoryAccessed({
        contentId,
        contentType: ScopedCreateContentId.contentType(scopedContentId),
        contentLink,
      })
    )
  }, [contentId, dispatch, scopedContentId])

  return (
    <RequireCoursePermission
      contentId={contentId}
      minPermissionLevel='edit'
      showSpinner
      onAccessDenied={accessDeniedRedirect}
      onContentNotFound={contentNotFoundRedirect}
    >
      <EditAccessVersionHistoryPage
        selectedPublishVersion={publishVersion}
        scopedContentId={scopedContentId}
        nodeId={nodeId}
        assetContext={assetContext}
      />
    </RequireCoursePermission>
  )
}
