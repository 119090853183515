import { Logger } from 'sierra-client/core/logging/logger'
import { DimensionRef, MeasureRef } from 'sierra-domain/api/insights'

export const insightsDashboardCreatedLogger = Logger.trackLogger<{ dashboardId: string | undefined }>(
  'insights_dashboard_created'
)

export const insightsDashboardDuplicateLogger = Logger.trackLogger<{ dashboardId: string }>(
  'insights_dashboard_duplicate'
)

export const insightsDashboardDeletedLogger = Logger.trackLogger<{ dashboardId: string }>(
  'insights_dashboard_deleted'
)

export const insightsDashboardRenamedLogger = Logger.trackLogger<{
  dashboardId: string
}>('insights_dashboard_renamed')

// Do we want to also have the dashboardId?
export const insightsWidgetSavedLogger = Logger.trackLogger<{
  widgetType: string | undefined
  measure: MeasureRef | undefined
  dimension: DimensionRef | undefined
}>('insights_widget_saved')

export const insightsWidgetDeletedLogger = Logger.trackLogger<{
  dashboardId: string
  widgetId: string
  widgetType: string
}>('insights_widget_deleted')

export const insightsWidgetModifiedLogger = Logger.trackLogger<{ widgetType: string }>(
  'insights_widget_modified'
)

export const insightsWidgetDuplicateLogger = Logger.trackLogger<{ dashboardId: string; widgetType: string }>(
  'insights_widget_duplicate'
)

export const insightsWidgetRenamedLogger = Logger.trackLogger<{ editLocation: string | undefined }>(
  'insights_widget_renamed'
)

export const insightsDashboardFilterAppliedLogger = Logger.trackLogger<{ dashboardId: string }>(
  'insights_dashboard_filter_applied'
)

export const insightsDashboardSharedLogger = Logger.trackLogger<{ dashboardId: string }>(
  'insights_dashboard_shared'
)

export const insightsDashboardSharedWithAllLogger = Logger.trackLogger<{
  dashboardId: string
  value: boolean
}>('insights_dashboard_shared_with_all')

export const insightsWidgetHistoryLogger = Logger.trackLogger<{ action: 'undo' | 'redo' }>(
  'insights_widget_history'
)

export const insightsWidgetSearchLogger = Logger.trackLogger<{ searchQuery: string }>(
  'insights_widget_search'
)

export const insightsWidgetPivotTablePaginationLogger = Logger.trackLogger<{ action: 'previous' | 'next' }>(
  'insights_widget_pivot_table_pagination'
)

export const insightsAssistantWidgetSuggestionSelectedLogger = Logger.trackLogger<{
  userQuery: string
  suggestion: string
  assistantFeedback: string
}>('insights_assistant_widget_suggestion_selected')
