import { useResolveAsset } from 'sierra-client/hooks/use-resolve-asset'
import { resolveThemeColor } from 'sierra-client/hooks/use-themes'
import {
  embedTypeToIconId,
  fileTypeToIconId,
} from 'sierra-client/views/flexible-content/editor/content-sidebar/common'
import { useThemeForFile } from 'sierra-client/views/flexible-content/polaris-card-theme'
import { AssetContext } from 'sierra-domain/asset-context'
import { ContentType } from 'sierra-domain/collaboration/types'
import { File, FileData } from 'sierra-domain/flexible-content/types'
import { color } from 'sierra-ui/color'
import { Icon, IconId, IconProps, IconSize } from 'sierra-ui/components'
import { palette } from 'sierra-ui/theming'
import { Theme } from 'sierra-ui/theming/legacy-theme'
import styled, { css } from 'styled-components'

type Size = 'micro' | 'small'

const getSize = (size: Size | undefined): string | undefined => {
  switch (size) {
    case 'micro':
      return '16px'
    case 'small':
      return '20px'
    default:
      return undefined
  }
}

const getIconSize = (size: Size | undefined): IconSize => {
  switch (size) {
    case 'micro':
      return 'size-10'
    case 'small':
      return 'size-12'
    default:
      return 'size-14'
  }
}

const getBorderRadius = (size: Size | undefined): string => {
  switch (size) {
    case 'micro':
      return '3px'
    case 'small':
    default:
      return '6px'
  }
}

export const NodeIcon = styled(Icon)<{
  fileTheme?: Theme
  color?: IconProps['color']
  $background?: string | undefined
  $componentSize?: string
  $borderRadius?: string
}>`
  flex-shrink: 0;
  width: ${p => (p.$componentSize !== undefined ? p.$componentSize : '24px')};
  height: ${p => (p.$componentSize !== undefined ? p.$componentSize : '18px')};
  border-radius: ${p => p.$borderRadius};
  border: 1px solid ${color(palette.primitives.black).opacity(0.05).toString()};

  ${p => {
    const theme: Theme = p.fileTheme ?? p.theme
    return css`
      && {
        background-color: ${resolveThemeColor(theme.home.backgroundColor)};

        svg {
          color: ${resolveThemeColor(p.color ?? theme.home.textColor)};
        }
      }
    `
  }}

  ${p =>
    p.$background !== undefined &&
    css`
      background-image: url('${p.$background}');
      background-size: cover;
    `}
`

export const getFileIcon = (fileData: FileData, createContentType?: ContentType): IconId => {
  if (fileData.type === 'embed' && fileData.urlType !== undefined) {
    return embedTypeToIconId(fileData.urlType)
  }
  return fileTypeToIconId(fileData.type, createContentType)
}

export type FileIconProps = {
  data: File['data']
  theme?: File['theme']
  backgroundImage?: File['backgroundImage']
}

export const FileIcon = ({
  file,
  size,
  assetContext,
}: {
  file: FileIconProps
  size?: Size
  assetContext: AssetContext
}): JSX.Element => {
  const fileTheme = useThemeForFile(file)

  const resolvedFileBackground = useResolveAsset({
    assetContext,
    image: file.backgroundImage,
    size: 'admin-sm',
  })

  const fileBackground = file.backgroundImage ? resolvedFileBackground : undefined

  return (
    <NodeIcon
      fileTheme={fileTheme}
      iconId={getFileIcon(file.data)}
      size={getIconSize(size)}
      $borderRadius={getBorderRadius(size)}
      $background={fileBackground}
      $componentSize={getSize(size)}
    />
  )
}
