import { useAtom } from 'jotai'
import { useEffect } from 'react'
import { scrollbarWidthAtom } from 'sierra-client/features/collapsable-sidebar/atoms'

export const useScrollbarWidth = (): number | undefined => {
  const [width, setWidth] = useAtom<number | undefined>(scrollbarWidthAtom)

  useEffect(() => {
    if (width !== undefined) {
      return
    }

    // Creating invisible container
    const outer = document.createElement('div')
    outer.style.visibility = 'hidden'
    outer.style.overflow = 'scroll' // forcing scrollbar to appear
    document.body.appendChild(outer)

    // Creating inner element and placing it in the container
    const inner = document.createElement('div')
    outer.appendChild(inner)

    // Calculating difference between container's full width and the child width
    const scrollbarWidth = outer.offsetWidth - inner.offsetWidth

    // Removing temporary elements from the DOM
    outer.parentNode?.removeChild(outer)

    setWidth(scrollbarWidth)

    return () => {
      outer.parentNode?.removeChild(outer)
    }
  }, [width, setWidth])

  return width
}
