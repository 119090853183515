import { FC, useMemo, useState } from 'react'
import {
  LocalUserInVideoAvatarBar,
  VideoAvatar,
} from 'sierra-client/components/liveV2/live-layer/video-avatar'
import {
  calculateVideoAvatarBarAvatarSize,
  minHeightWidthRatio,
} from 'sierra-client/components/liveV2/live-layer/video-avatar-bar/calculate-video-avatar-bar-avatar-size'
import { useOrderedVideoAvatarBarParticipants } from 'sierra-client/components/liveV2/live-layer/video-avatar-bar/use-ordered-video-avatar-bar-participants'
import { useRightSidebarContext } from 'sierra-client/components/liveV2/live-sidebar-provider'
import { useSelector } from 'sierra-client/state/hooks'
import {
  selectAugmentedParticipantsIncludingMe,
  selectCurrentRoomParticipantCount,
} from 'sierra-client/state/live-session/selectors'
import { View } from 'sierra-ui/primitives'
import { token } from 'sierra-ui/theming'
import styled from 'styled-components'

const Container = styled(View)`
  height: 100px;
`

const AvatarsContainer = styled(View)`
  height: 100%;
`

const AdditionalParticipantsContainer = styled(View)`
  min-width: 40px;
  border-radius: 8px;
  align-items: center;
  justify-content: center;
  text-align: center;
  user-select: none;
  display: flex;
  font-size: 0.75rem;
  font-weight: 500;
  pointer-events: all;
  cursor: pointer;

  transition: color 0.1s cubic-bezier(0.25, 0.1, 0.25, 1);

  color: ${token('foreground/muted').opacity(0.5)};
  background-color: ${token('surface/default').opacity(0.2)};

  &:hover {
    color: ${token('foreground/muted')};
  }
`

export const MobileVideoAvatarBar: FC = () => {
  const [containerRef, setContainerRef] = useState<HTMLDivElement | null>(null)

  const containerRect = containerRef?.getBoundingClientRect()
  const containerHeight = containerRect?.height ?? 0
  const containerWidth = containerRect?.width ?? 0

  const avatarMinWidth = containerHeight * minHeightWidthRatio
  const avatarsThatFit = containerWidth !== 0 ? Math.min(Math.floor(containerWidth / avatarMinWidth), 3) : 0

  const participants = useSelector(selectAugmentedParticipantsIncludingMe)
  const visibleParticipants = useOrderedVideoAvatarBarParticipants({
    numberOfAvatarsThatFit: avatarsThatFit,
    participants,
  })
  const remoteVisibleParticipants = useMemo(
    () => visibleParticipants.filter(participant => !participant.isCurrentClient),
    [visibleParticipants]
  )

  const participantCount = useSelector(selectCurrentRoomParticipantCount)
  const additionalParticipants = participantCount - remoteVisibleParticipants.length

  const nrOfVisibleParticipants = remoteVisibleParticipants.length + 1 // + me

  const { toggleState } = useRightSidebarContext()

  const participantSize = calculateVideoAvatarBarAvatarSize(
    nrOfVisibleParticipants,
    containerRect?.width ?? 0,
    containerHeight
  )

  return (
    <Container padding='none xxsmall'>
      <AvatarsContainer justifyContent='center' grow ref={setContainerRef}>
        <LocalUserInVideoAvatarBar height={participantSize.height} width={participantSize.width} />
        <>
          {remoteVisibleParticipants.map(participant => (
            <VideoAvatar
              key={participant.agoraUID}
              participant={participant}
              showVideo={true}
              dynamicSize={true}
              height={participantSize.height}
              width={participantSize.width}
            />
          ))}
        </>
      </AvatarsContainer>
      {additionalParticipants > 0 && (
        <AdditionalParticipantsContainer onClick={() => toggleState('list_open')}>
          +{additionalParticipants} more
        </AdditionalParticipantsContainer>
      )}
    </Container>
  )
}
