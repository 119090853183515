import { useMemo } from 'react'
import { useLocalPermissionsConfig } from 'sierra-client/components/shortcut-menu/permissions-inspector/atoms'
import { PermissionKey, PermissionOptions } from 'sierra-client/hooks/use-permissions/types'

export const useLocalPermissionValues = <K extends PermissionKey>(
  type: K | undefined
): PermissionOptions[K][] => {
  const localPermissionsConfig = useLocalPermissionsConfig()

  return useMemo(() => {
    if (type === undefined || localPermissionsConfig === undefined) return []

    return localPermissionsConfig[type]
  }, [localPermissionsConfig, type])
}
