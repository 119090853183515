import { motion } from 'framer-motion'
import React, {
  cloneElement,
  forwardRef,
  KeyboardEventHandler,
  ReactElement,
  useCallback,
  useEffect,
  useMemo,
  useState,
} from 'react'
import { getFileContentImage } from 'sierra-client/api/content'
import { useTranslation } from 'sierra-client/hooks/use-translation'
import { useSelector } from 'sierra-client/state/hooks'
import { selectUser } from 'sierra-client/state/user/user-selector'
import { getAvatarImage } from 'sierra-client/utils/avatar-img'
import { AutoexpandingReflectionInput } from 'sierra-client/views/v3-author/reflection-card/atoms'
import { User } from 'sierra-client/views/v3-author/reflection-card/user-reflection'
import { UserId } from 'sierra-domain/api/uuid'
import { isDefined } from 'sierra-domain/utils'
import { deriveTheme } from 'sierra-ui/color'
import { TokenOrColor } from 'sierra-ui/color/token-or-color'
import { ColorName } from 'sierra-ui/color/types'
import { AvatarStack, MenuItem, RoundAvatar } from 'sierra-ui/components'
import { Button, IconButton, Text, View } from 'sierra-ui/primitives'
import { SingleSelectDropdown } from 'sierra-ui/primitives/menu-dropdown'
import { LightTokenProvider, palette, token } from 'sierra-ui/theming'
import { LightTheme } from 'sierra-ui/theming/legacy-theme'
import styled from 'styled-components'

const PreviewThreadButton = styled.div`
  height: 28px;
  width: fit-content;
  border-radius: 14px;
  display: flex;
  flex-direction: row;
  white-space: nowrap;
  justify-content: center;
  align-items: center;
  padding: 0;
  padding-right: 8px;
  padding-left: 4px;
  background: ${token('surface/soft')};
`

const PreviewReaction = styled.button<{ highlighted: boolean }>`
  display: flex;
  align-items: center;
  height: 28px;
  padding: 0 8px 0 8px;
  background-color: ${props => (props.highlighted ? token('surface/soft') : 'transparent')};
  border-radius: 54px;
`

const PreviewReactionCount = styled(Text)`
  font-size: 10px;
  margin-left: 6px;
  font-variant-numeric: tabular-nums;
`

const PreviewInputContainer = styled.div`
  -webkit-column-break-inside: avoid;
  page-break-inside: avoid;
  break-inside: avoid;
  width: 100%;
`

const StyledReflectionInputInner = styled.div`
  font-size: 1rem;
  min-height: 200px;
  padding: 14px 20px 16px 20px;
  background-color: ${palette.primitives.white};
  color: ${palette.primitives.black};
  border-radius: 12px;
  display: flex;
  flex-direction: column;
  width: 100%;
  border: 1px solid ${palette.grey[5]};
  box-shadow: 0px 8px 24px 0px rgba(0, 0, 0, 0.04);
`

const GAP = 10
const SIZE = 1
const CardHolder = styled.div`
  display: grid;
  grid-auto-rows: ${SIZE}px;
  grid-template-columns: repeat(auto-fill, minmax(350px, 1fr));
  column-gap: ${GAP}px;
`

const ReflectionPreviewCardContainer = styled.div`
  padding: 1.5rem;
  margin-bottom: ${GAP}px;
  height: min-content;
  background-color: ${token('surface/soft')};
  border: 1px solid ${token('border/default')};
  border-radius: 12px;
  pointer-events: none;
`

const ReflectionCardFooter = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  margin-top: 1.5rem;
`

const ReflectionCardHeader = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  gap: 8px;
  margin-bottom: 0.5rem;
`

const ReflectionCardPreviewName = styled(Text)`
  font-size: 0.875rem;
  font-weight: 500;
`

const ReflectionTypingCardContainer = styled.div`
  padding: 1.5rem;
  margin-bottom: ${GAP}px;
  height: min-content;

  background-color: ${p => deriveTheme(p.theme).secondaryBackgroundColor};
  color: ${p => deriveTheme(p.theme).secondaryTextColor};

  font-size: 0.875rem;
  font-weight: 500;
  border-radius: 12px;
  -webkit-column-break-inside: avoid;
  page-break-inside: avoid;
  break-inside: avoid;
`

type CardProps = {
  lastName?: string
  firstName?: string
  text: string
  reactions?: Record<string, { count: number; highlighted: boolean }>
  replies?: number
  src?: string
  color?: TokenOrColor
}

// This is just copy of what is in learner
const GridItem: React.FC<{ contents: ReactElement<{ ref?: (ref: HTMLDivElement | null) => void }> }> = ({
  contents,
}) => {
  const [spans, setSpans] = useState<number>()
  const [ref, setRef] = useState<HTMLDivElement | null>(null)

  const updateSize: ResizeObserverCallback = useCallback(entries => {
    requestAnimationFrame(() => {
      const [entry] = entries
      if (entry === undefined) return

      const height = entry.target.clientHeight
      const spans = Math.ceil(height / SIZE)
      const padding = GAP

      setSpans(spans + padding)
    })
  }, [])

  useEffect(() => {
    if (ref === null) return

    const observer = new ResizeObserver(updateSize)
    observer.observe(ref)

    return () => observer.disconnect()
  }, [ref, updateSize])

  const handleRef = useCallback((ref: HTMLDivElement | null) => {
    setRef(ref)
  }, [])

  const style = useMemo(
    () => ({
      gridRowEnd: `span ${spans}`,
    }),
    [spans]
  )
  const contentElement = useMemo(() => cloneElement(contents, { ref: handleRef }), [contents, handleRef])

  return (
    <motion.div
      initial={{ opacity: 0 }}
      // Make sure that we "hide" the element if the calculations hasnt been done yet. This is to avoid shifts and weird overlaps
      animate={{ opacity: isDefined(spans) ? 1 : 0 }}
      transition={{ duration: 0.2 }}
      style={style}
    >
      {contentElement}
    </motion.div>
  )
}

const Card = forwardRef<HTMLDivElement, CardProps>(
  ({ firstName, lastName, text, reactions, replies, src, color }, ref) => {
    return (
      <ReflectionPreviewCardContainer ref={ref}>
        <ReflectionCardHeader>
          <RoundAvatar size='small' firstName={firstName} lastName={lastName} src={src} color={color} />
          <ReflectionCardPreviewName>{firstName}</ReflectionCardPreviewName>
        </ReflectionCardHeader>
        <Text>{text}</Text>
        <ReflectionCardFooter>
          <View direction='row' gap='4'>
            {Object.entries(reactions ?? {}).map(([emoji, { count, highlighted }]) => (
              <PreviewReaction key={emoji} highlighted={highlighted} onClick={() => {}}>
                {emoji}
                <PreviewReactionCount>{count}</PreviewReactionCount>
              </PreviewReaction>
            ))}
            <IconButton variant='transparent' iconId='face--add' />
          </View>
          <View direction='row' gap='4'>
            {replies !== undefined && (
              <PreviewThreadButton onClick={() => {}}>
                <AvatarStack
                  users={[
                    {
                      uuid: 'preview-1' as UserId,
                      firstName: 'Rita',
                      lastName: '',
                      avatar: getFileContentImage('images/home-avatars/rita.png', {
                        bucket: 'sierra-static',
                      }),
                      avatarColor: 'pinkBright' as ColorName,
                    },
                    {
                      uuid: 'preview-2' as UserId,
                      firstName: 'Jon',
                      lastName: '',
                      avatar: getFileContentImage('images/home-avatars/jon.png', { bucket: 'sierra-static' }),
                      avatarColor: 'blueBright' as ColorName,
                    },
                  ].slice(0, replies)}
                  size='minuscule'
                  max={2}
                  withTooltips
                />{' '}
                <Text size='micro' bold>
                  {replies} {replies === 1 ? 'Reply' : 'Replies'}
                </Text>
              </PreviewThreadButton>
            )}
            <IconButton variant='transparent' iconId='reply' />
          </View>
        </ReflectionCardFooter>
      </ReflectionPreviewCardContainer>
    )
  }
)

export const ReflectionCardPreview: React.FC = () => {
  const [tempPreviewReflection, setTempPreviewReflection] = useState<string>()
  const [previewReflection, setPreviewReflection] = useState<string>()
  const [anonymous, setAnonymous] = useState(false)

  const publish = (): void => {
    setPreviewReflection(tempPreviewReflection)
    setTempPreviewReflection('')
  }

  const handleKeyPress: KeyboardEventHandler<HTMLTextAreaElement> = event => {
    if (event.key === 'Enter' && !event.shiftKey) {
      event.preventDefault()
      event.stopPropagation()
      publish()
    }
  }
  const user = useSelector(selectUser)

  const { t } = useTranslation()

  const menuItems: MenuItem<'not-anonymous' | 'anonymous'>[] = [
    {
      id: 'not-anonymous',
      type: 'label',
      label: t('learner.reflection-card.post-as-yourself'),
      selected: anonymous === false,
    },
    {
      id: 'anonymous',
      type: 'label',
      selected: anonymous === true,
      label: t('learner.reflection-card.post-anonymously'),
    },
  ]

  const selectedItem =
    anonymous === true
      ? menuItems.find(item => item.id === 'anonymous')
      : menuItems.find(item => item.id === 'not-anonymous')

  return (
    <>
      <View padding='16 none' justifyContent='space-between' alignItems='center'>
        <Text color='foreground/primary' bold size='small'>
          {t('dictionary.reflection-plural')}
        </Text>
      </View>
      <CardHolder>
        <GridItem
          contents={
            <PreviewInputContainer>
              <LightTheme>
                <LightTokenProvider>
                  <StyledReflectionInputInner>
                    <ReflectionCardHeader>
                      <User $isMe>
                        <RoundAvatar
                          size='small'
                          firstName={user?.firstName}
                          lastName={user?.lastName}
                          src={getAvatarImage(user?.uuid, user?.avatar)}
                          color={user?.avatarColor}
                        />
                        <SingleSelectDropdown
                          bold
                          selectedItem={selectedItem}
                          menuItems={menuItems}
                          variant='ghost'
                          onSelect={item =>
                            item.id === 'anonymous' ? setAnonymous(true) : setAnonymous(false)
                          }
                        />
                      </User>
                    </ReflectionCardHeader>
                    <AutoexpandingReflectionInput
                      onChange={e => setTempPreviewReflection(e.target.value)}
                      placeholder={t('learner.reflection-card.placeholder')}
                      title='Add a reflection...'
                      value={tempPreviewReflection ?? ''}
                      onKeyDown={handleKeyPress}
                      autoExpand
                      rows={4}
                    />

                    <View marginTop={'auto'} justifyContent='flex-end' gap='8'>
                      <Button variant='secondary' onClick={() => {}} disabled>
                        {t('dictionary.skip')}
                      </Button>
                      <Button disabled={tempPreviewReflection?.length === 0} onClick={() => publish()}>
                        {t('dictionary.post')}
                      </Button>
                    </View>
                  </StyledReflectionInputInner>
                </LightTokenProvider>
              </LightTheme>
            </PreviewInputContainer>
          }
        />
        <GridItem
          contents={
            <Card
              text='Had an insightful call with the US team and brainstormed a new feature for improving team collaboration. Cant wait to see it come to life!'
              firstName='Jon'
              src={getFileContentImage('images/home-avatars/jon.png', { bucket: 'sierra-static' })}
              reactions={{
                '💯': { count: 4, highlighted: true },
                '❤️': { count: 2, highlighted: true },
                '✅': { count: 3, highlighted: false },
                '👍': { count: 1, highlighted: false },
              }}
            />
          }
        />
        <GridItem
          contents={
            <Card
              text='After noticing a bottleneck in our support process, I implemented a new ticketing system️ that has significantly reduced response times. So far we’ve seen a 2 point bump in our NPS (!!)'
              firstName='Joel'
              replies={1}
              src={getFileContentImage('images/home-avatars/joel.png', { bucket: 'sierra-static' })}
            />
          }
        />
        <GridItem
          contents={
            <Card
              text='Organized a remote trivia night for our team, and everyone had a blast! It was a great way to bond and relax, even though we are scattered across different time zones ❤️'
              firstName='Zak'
              reactions={{
                '❤️': { count: 6, highlighted: true },
              }}
              src={getFileContentImage('images/home-avatars/zak.png', { bucket: 'sierra-static' })}
            />
          }
        />
        <GridItem
          contents={
            <Card
              text='been working on automating repetitive tasks using a custom-built script, and now our team saves 10 hours a week, feels amazing to free up time for more creative work!'
              firstName='Lauren'
              replies={1}
              src={getFileContentImage('images/home-avatars/lauren.png', { bucket: 'sierra-static' })}
            />
          }
        />
        <GridItem
          contents={
            <Card
              text="Developed a comprehensive FAQ section for our website, addressing common user concerns. It's already reduced support inquiries by 40%"
              firstName='Rita'
              src={getFileContentImage('images/home-avatars/rita.png', { bucket: 'sierra-static' })}
              replies={2}
              reactions={{
                '✅': { count: 3, highlighted: true },
                '👍': { count: 4, highlighted: false },
              }}
            />
          }
        />

        {previewReflection !== undefined && (
          <GridItem
            contents={
              <Card
                firstName={user?.firstName}
                lastName={user?.lastName}
                src={getAvatarImage(user?.uuid, user?.avatar)}
                color={user?.avatarColor}
                text={previewReflection}
              />
            }
          />
        )}
        <GridItem
          contents={
            <ReflectionTypingCardContainer>
              <Text size='small' bold color='foreground/muted'>
                {t('learner.poll-card.x-is-typing', { name: 'Javier' })}...
              </Text>
            </ReflectionTypingCardContainer>
          }
        />
        <GridItem
          contents={
            <ReflectionTypingCardContainer>
              <Text size='small' bold color='foreground/muted'>
                {t('learner.poll-card.x-is-typing', { name: 'Tamara' })}...
              </Text>
            </ReflectionTypingCardContainer>
          }
        />
      </CardHolder>
    </>
  )
}
