import { motion } from 'framer-motion'
import { forwardRef } from 'react'
import { useSelector } from 'sierra-client/state/hooks'
import { selectUsersPositionInRaisedHandQueue } from 'sierra-client/state/live-session/selectors'
import { UserId } from 'sierra-domain/api/uuid'
import { Icon } from 'sierra-ui/components'
import { palette } from 'sierra-ui/theming'
import styled from 'styled-components'

const EmojiContainer = styled(motion.div)`
  display: flex;
  align-items: center;
  padding: 8px;
  border-radius: 12px;
  background-color: ${palette.primitives.white};
  border: 1px solid ${palette.grey[5]};
  color: ${palette.primitives.black};
`

type Props = {
  userId: UserId
  className?: string
  showOnlyOrder?: boolean
}

const NumberContainer = styled.div`
  width: 12px;
  height: 12px;
  display: flex;
  justify-content: center;
  align-items: center;
`

export const RaisingHand = forwardRef<HTMLDivElement, Props>(
  ({ userId, className, showOnlyOrder = false }, ref) => {
    const handRaisedPosition = useSelector(state => selectUsersPositionInRaisedHandQueue(state, userId))
    if (handRaisedPosition === undefined) return null

    return (
      <EmojiContainer
        ref={ref}
        className={className}
        initial={{ opacity: 0, y: 16 }}
        animate={{ opacity: 1, y: 0 }}
        exit={{ opacity: 0 }}
        transition={{
          opacity: {
            duration: 0.2,
          },
          y: {
            type: 'spring',
            stiffness: 250,
          },
        }}
      >
        {!showOnlyOrder && <Icon color={'black'} iconId='hand' size='size-16' />}
        {showOnlyOrder && <NumberContainer>{handRaisedPosition}</NumberContainer>}
      </EmojiContainer>
    )
  }
)
