import { useEffect } from 'react'
import { useInView } from 'react-intersection-observer'
import {
  Column,
  TableInstance,
  useGlobalFilter,
  usePagination,
  useRowSelect,
  useSortBy,
  useTable,
} from 'react-table'
import { GlobalFilter } from 'sierra-client/components/table/global-filter'
import { Table } from 'sierra-client/components/table/table'
import { TableScrollContainer } from 'sierra-client/components/table/table-utils'
import { InfiniteScrollMessage } from 'sierra-client/views/manage/components/common'
import { RoundedSearchBar } from 'sierra-client/views/manage/components/rounded-search-bar'
import { useFEInfinitePagination } from 'sierra-client/views/manage/hooks/use-fe-infinite-pagination'
import { palette } from 'sierra-ui/theming'
import styled from 'styled-components'

const SearchBar = styled.div`
  display: flex;
  align-items: center;
  position: sticky;
  top: 0;
  background-color: ${palette.primitives.white};
  padding: 1rem;
  z-index: 10;
`

type UseLibraryTableProps<E extends Record<string, unknown>> = {
  data: E[]
  columns: Column<E>[]
  getEntityId: (entity: E) => string
}

type UseLibraryTableData<E extends Record<string, unknown>> = {
  tableInstance: TableInstance<E>
  selectedIds: string[]
}

export const useLibraryTable = <E extends Record<string, unknown>>({
  data,
  columns,
  getEntityId,
}: UseLibraryTableProps<E>): UseLibraryTableData<E> => {
  const tableInstance = useTable<E>(
    {
      data,
      columns,
      autoResetGlobalFilter: false,
      autoResetSortBy: false,
    },
    useGlobalFilter,
    useSortBy,
    usePagination,
    useRowSelect
  )

  const selectedIds = tableInstance.selectedFlatRows.map(r => r.original).map(getEntityId)

  return {
    tableInstance,
    selectedIds,
  }
}

type LibraryTableProps<E extends Record<string, unknown>> = {
  tableInstance: TableInstance<E>
  isLoading?: boolean
  translations: {
    tableLoading: string
    tableEnd: string
    tableNoResults: string
    searchPlaceholder: string
  }
}

export const LibraryTable = <E extends Record<string, unknown>>({
  tableInstance,
  isLoading = false,
  translations,
}: LibraryTableProps<E>): JSX.Element => {
  const [inViewRef, shouldLoadMore] = useInView({ threshold: 0 })

  const { hasMore, nextPage, resetPage } = useFEInfinitePagination(tableInstance)

  useEffect(() => {
    if (shouldLoadMore && !isLoading) {
      nextPage()
    }
  }, [tableInstance.state.pageSize, nextPage, isLoading, shouldLoadMore])

  return (
    <TableScrollContainer>
      <SearchBar>
        <GlobalFilter
          tableInstance={tableInstance}
          render={props => <RoundedSearchBar {...props} placeholder={translations.searchPlaceholder} />}
        />
      </SearchBar>
      <Table
        tableInstance={tableInstance}
        getHeaderProps={column => ({
          onClick: () => {
            if (!column.canSort) return
            resetPage()
          },
        })}
      />
      {hasMore || isLoading ? (
        <InfiniteScrollMessage ref={inViewRef} text={translations.tableLoading} showSanaLogo />
      ) : (
        <InfiniteScrollMessage
          text={tableInstance.rows.length > 0 ? translations.tableEnd : translations.tableNoResults}
        />
      )}
    </TableScrollContainer>
  )
}
