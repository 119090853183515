import { TranslationKey } from 'sierra-client/hooks/use-translation/types'
import { LabelMenuItem } from 'sierra-ui/components'

type MenuItemWithoutTranslation = Omit<LabelMenuItem, 'label'> & {
  translationKey: TranslationKey
}

export const editRightsItems: MenuItemWithoutTranslation[] = [
  {
    type: 'label',
    translationKey: 'manage.insights.dashboard.roles.viewer',
    id: 'viewer',
  },
  {
    type: 'label',
    translationKey: 'manage.insights.dashboard.roles.editor',
    id: 'editor',
  },
  {
    type: 'label',
    translationKey: 'manage.insights.dashboard.roles.owner',
    id: 'owner',
  },
]
