import { useQuery, UseQueryResult } from '@tanstack/react-query'
import { PrimitiveAtom, useAtomValue, useSetAtom } from 'jotai'
import React, { useEffect, useMemo } from 'react'
import { TargetIndexAtom } from 'sierra-client/editor/version-history/use-history-with-target-index-atom'
import { typedPost } from 'sierra-client/state/api'
import { CreateContentId } from 'sierra-domain/api/nano-id'
import { deserialize, FlexibleContentRecord } from 'sierra-domain/collaboration/serialization'
import { XRealtimeAuthorGetCoursePublishedVersionSnapshot } from 'sierra-domain/routes'
import { iife } from 'sierra-domain/utils'
import { getPublishedVersionsUpdateDiff } from 'sierra-domain/version-history/get-published-versions-update-diff'
import { getYjsUpdateDiff } from 'sierra-domain/version-history/get-yjs-update-diff'
import { replayYjsUpdates } from 'sierra-domain/version-history/replay-yjs-updates'
import { History, ReplayUpdatesContext } from 'sierra-domain/version-history/types'

function usePublishedVersionQuery(
  contentId: CreateContentId,
  versionId: string | undefined
): UseQueryResult<FlexibleContentRecord | null> {
  return useQuery({
    queryKey: [XRealtimeAuthorGetCoursePublishedVersionSnapshot.path, { contentId, id: versionId }],
    queryFn: async () => {
      if (versionId === undefined) {
        return null
      }
      const { snapshot } = await typedPost(XRealtimeAuthorGetCoursePublishedVersionSnapshot, {
        contentId,
        id: versionId,
      })

      return snapshot
    },
  })
}

const loadingContext: ReplayUpdatesContext = { type: 'loading' }
export function useReplayUpdatesContext(
  contentId: CreateContentId,
  history: History,
  targetIndexAtom: TargetIndexAtom
): ReplayUpdatesContext {
  const targetIndex = useAtomValue(targetIndexAtom)
  const targetUpdate = history.updates[targetIndex]

  const yjsContext = useQuery({
    queryKey: ['yjsContext', { yDocId: contentId, targetIndex }],
    refetchOnWindowFocus: false,
    queryFn: async ({ signal }): Promise<ReplayUpdatesContext | null> => {
      if (targetUpdate?.type !== 'yjs') {
        return null
      }

      return {
        type: 'loaded',
        ...(await replayYjsUpdates(history.updates, targetUpdate, signal)),
        updateDiff: getYjsUpdateDiff(history.yjsUpdatesMeta, targetIndex),
      }
    },
  }).data

  const published = iife(() => {
    if (targetUpdate?.type !== 'published-version') {
      return undefined
    }

    const previousPublishedVersion = history.updates.findLast(
      (update, index) => index < targetIndex && update.type === 'published-version'
    )

    return {
      previousId:
        previousPublishedVersion?.type === 'published-version' ? previousPublishedVersion.id : undefined,
      currentId: targetUpdate.id,
    }
  })

  const previousPublishedVersionQuery = usePublishedVersionQuery(contentId, published?.previousId)
  const currentPublishedVersionQuery = usePublishedVersionQuery(contentId, published?.currentId)
  const publishedContext = useMemo((): ReplayUpdatesContext | undefined => {
    if (
      previousPublishedVersionQuery.data !== undefined &&
      // undefined means it is still loading, null means it does not exist
      // for the current published version, we need to check both, but in the
      // previous version we only care if it is still loading or not
      currentPublishedVersionQuery.data !== null &&
      currentPublishedVersionQuery.data !== undefined
    ) {
      const currentYDoc = deserialize({ '': currentPublishedVersionQuery.data })
      const previousYDoc = previousPublishedVersionQuery.data
        ? deserialize({ '': previousPublishedVersionQuery.data })
        : // No diff if there is no previous version
          currentYDoc
      return {
        type: 'loaded',
        previousYDoc,
        currentYDoc,
        updateDiff: getPublishedVersionsUpdateDiff({
          previous: previousPublishedVersionQuery.data,
          current: currentPublishedVersionQuery.data,
        }),
      }
    } else {
      return undefined
    }
  }, [currentPublishedVersionQuery.data, previousPublishedVersionQuery.data])

  return yjsContext ?? publishedContext ?? loadingContext
}

export const ResolveReplayUpdatesContext: React.FC<{
  replayUpdateContextAtom: PrimitiveAtom<ReplayUpdatesContext>
  targetIndexAtom: TargetIndexAtom
  history: History
  contentId: CreateContentId
}> = ({ replayUpdateContextAtom, targetIndexAtom, history, contentId }) => {
  const context = useReplayUpdatesContext(contentId, history, targetIndexAtom)
  const setContext = useSetAtom(replayUpdateContextAtom)

  useEffect(() => {
    setContext(context)
  }, [context, setContext])

  return null
}
