import { atom, useAtomValue, useSetAtom } from 'jotai'
import { useEffect } from 'react'
import { isDefined } from 'sierra-domain/utils'
import { v2_breakpoint_int } from 'sierra-ui/theming/breakpoints'

function measureWindowWidth(): number {
  return typeof window !== 'undefined' && isDefined(window) ? window.innerWidth : 0
}

const windowWidthAtom = atom<number>(measureWindowWidth())

const isTabletAtom = atom(get => v2_breakpoint_int.tablet >= get(windowWidthAtom))
export const useIsTablet = (): boolean => useAtomValue(isTabletAtom)

const isMobileAtom = atom(get => v2_breakpoint_int.phone >= get(windowWidthAtom))
export const useIsMobile = (): boolean => useAtomValue(isMobileAtom)

const isSmallDesktopAtom = atom(get => v2_breakpoint_int.desktop_small >= get(windowWidthAtom))
export const useIsSmallDesktop = (): boolean => useAtomValue(isSmallDesktopAtom)

export function MonitorWindowSize(): JSX.Element | null {
  const setWidth = useSetAtom(windowWidthAtom)
  useEffect(() => {
    const handleResize = (): void => setWidth(measureWindowWidth())

    window.addEventListener('resize', handleResize)
    return () => window.removeEventListener('resize', handleResize)
  }, [setWidth])

  return null
}
