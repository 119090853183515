import React from 'react'
import { Helmet } from 'react-helmet-async'
import { ApplicationNotifications } from 'sierra-client/components/common/notifications'
import { DomainThemeProvider } from 'sierra-client/config/domain-theme'
import { usePageViewLogging } from 'sierra-client/core/logging/hooks'
import { PageInstance } from 'sierra-client/core/logging/page/types'
import { IntercomLauncherVisibility } from 'sierra-client/intercom/intercom-visibility'
import { useScrollOffset } from 'sierra-client/layout/header/hooks'
import { ReduxErrors } from 'sierra-client/layout/sana-page/components/redux-errors'
import { PageInstanceProps } from 'sierra-client/state/logging/types'
import { token } from 'sierra-ui/theming'
import { ThemeMode, legacyDark, legacyLight } from 'sierra-ui/theming/legacy-theme'
import styled, { ThemeProvider, createGlobalStyle } from 'styled-components'

export * as PageIdentifier from 'sierra-client/core/logging/page/logger'

// TODO(seb): Move to global-styles.tsx when V2 is done
export const GlobalStylesV2 = createGlobalStyle`
  body {
    background-color: ${token('surface/default')};
  }

  #__next {
    /* This is mainly so we don't overflow on mobile when the sidebar is open. */
    position: relative;
    overflow-x: hidden;
    overflow-y: hidden;
    height: auto;
  }
`

const StyledPage = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  flex-basis: 100%;
  max-width: 100%;
  min-height: 100%;
`

const DESKTOP_MIN_WIDTH = '1312'

const DisableMobile = (): React.ReactElement => (
  <>
    <Helmet>
      <meta
        name='viewport'
        content={`initial-scale=0.5, width=${DESKTOP_MIN_WIDTH}`}
        key='viewport-addition'
      />
    </Helmet>
  </>
)

type SanaPageV2Props<Input extends PageInstanceProps, Output extends PageInstanceProps> = {
  children?: React.ReactNode
  page: PageInstance<Input, Output>
  mode?: ThemeMode
  disableMobile?: boolean
  showIntercomLauncher?: boolean
}

export const SanaPage = <Input extends PageInstanceProps, Output extends PageInstanceProps>({
  children,
  page,
  mode = 'light',
  disableMobile,
  showIntercomLauncher = false,
  className,
}: SanaPageV2Props<Input, Output> & { className?: string }): JSX.Element => {
  usePageViewLogging(page)
  useScrollOffset()

  return (
    <DomainThemeProvider defaultMode={mode}>
      {(disableMobile ?? false) && <DisableMobile />}
      <GlobalStylesV2 />

      {showIntercomLauncher && <IntercomLauncherVisibility visibility='visible' />}

      <StyledPage className={className}>{children}</StyledPage>
      <ReduxErrors />
      <ApplicationNotifications />
    </DomainThemeProvider>
  )
}

export const PlainSanaPage = <Input extends PageInstanceProps, Output extends PageInstanceProps>({
  children,
  page,
  mode,
  disableMobile,
}: SanaPageV2Props<Input, Output>): JSX.Element => {
  usePageViewLogging(page)
  useScrollOffset()

  return (
    <ThemeProvider theme={mode === 'light' ? legacyLight : legacyDark}>
      {(disableMobile ?? false) && <DisableMobile />}
      <GlobalStylesV2 />
      <StyledPage>{children}</StyledPage>
    </ThemeProvider>
  )
}

const FullWidthDiv = styled.div`
  width: 100%;
`

// This is mainly useful for certificates where we want more control over print styles, especially page breaks.
export const UnstyledSanaPage = <Input extends PageInstanceProps, Output extends PageInstanceProps>({
  children,
  page,
  mode,
  disableMobile,
}: SanaPageV2Props<Input, Output>): JSX.Element => {
  usePageViewLogging(page)

  return (
    <ThemeProvider theme={mode === 'light' ? legacyLight : legacyDark}>
      {(disableMobile ?? false) && <DisableMobile />}
      <FullWidthDiv>{children}</FullWidthDiv>
    </ThemeProvider>
  )
}
