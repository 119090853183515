import { Icon } from 'sierra-ui/components'
import styled from 'styled-components'

const WrapperCircle = styled.div`
  border-radius: 50%;
  background-color: ${p => p.theme.color.yellowBright};
  height: 1.25rem;
  width: 1.25rem;
  display: flex;
  justify-content: center;
  flex-shrink: 0;
  align-items: center;
`

export const WrappedFavoriteIcon: React.FC = () => (
  <WrapperCircle>
    <Icon size='size-12' iconId='star--filled' color='foreground/primary' />
  </WrapperCircle>
)
