import { useAtomValue } from 'jotai'
import React, { useCallback, useMemo } from 'react'
import { FilterGenerationPopover } from 'sierra-client/features/filter'
import { useTranslation } from 'sierra-client/hooks/use-translation'
import { Domain, DomainRep, Label, Value, setPred } from 'sierra-client/lib/filter'
import { Context, labelToString } from 'sierra-client/lib/filter/components/common'
import {
  equal,
  fromPredicate,
  resetPredValue,
  setPredValue,
  valueId,
} from 'sierra-client/lib/filter/components/predicate-utils'
import { TableAPI } from 'sierra-client/lib/tabular/api'
import { CtxFilterComponent } from 'sierra-client/lib/tabular/tables/utils'
import { FilterBase } from 'sierra-domain/filter/datatype/filter'
import { MenuItem } from 'sierra-ui/components'
import { SingleSelectDropdown } from 'sierra-ui/primitives/menu-dropdown'

const IGNORE = '_IGNORE_'
const ignoreValue = { type: 'value.string', value: IGNORE } as const
type MenuItemWithValue = MenuItem & {
  value?: Value
}
const Choice: React.FC<{
  ctx: Context
  filter: FilterBase
  domain: Extract<Domain, { type: 'domain.choices' }>
  label: Label
}> = ({ ctx, filter, domain, label }) => {
  const currentValueId = valueId(fromPredicate(filter.predicate)[0] ?? ignoreValue)
  const { dynamicT } = useTranslation()
  const items: MenuItemWithValue[] = useMemo(
    () => [
      {
        type: 'label' as const,
        id: valueId(ignoreValue),
        value: ignoreValue,
        label: labelToString(label, dynamicT),
        selected: currentValueId === valueId(ignoreValue),
      },
      ...domain.choices.map(c => ({
        type: 'label' as const,
        id: valueId(c.value),
        label: labelToString(c.label, dynamicT),
        selected: currentValueId === valueId(c.value),
        value: c.value,
      })),
    ],
    [label, dynamicT, currentValueId, domain.choices]
  )

  const onChange = useCallback(
    (item: MenuItemWithValue) => {
      const value = item.value
      if (value !== undefined) {
        if (equal(value, ignoreValue)) {
          ctx.update(f => setPred(f, resetPredValue(filter.predicate)))
        } else {
          ctx.update(f => setPred(f, setPredValue(filter.predicate, value)))
        }
      }
    },
    [ctx, filter.predicate]
  )

  const selectedItem = useMemo(
    () => items.find(item => item.id === valueId(fromPredicate(filter.predicate)[0] ?? ignoreValue)),
    [filter.predicate, items]
  )

  return (
    <SingleSelectDropdown
      truncateTrigger={false}
      selectedItem={selectedItem}
      menuItems={items}
      onSelect={onChange}
    />
  )
}

const FilterView: React.FC<{ ctx: Context; filter: FilterBase; domainRep: DomainRep }> = ({
  ctx,
  filter,
  domainRep,
}) => {
  const domain = domainRep.domain
  switch (domain.type) {
    case 'domain.choices': {
      return <Choice ctx={ctx} filter={filter} domain={domain} label={domainRep.label} />
    }
    // TODO: we currently only care about dropdowns here. Might need more views here going forward
    case 'domain.type':
    case 'domain.auto-complete': {
      return null
    }
  }
}

export const Filtering: React.FC<{ api: TableAPI }> = ({ api }) => {
  const filter = useAtomValue(api.atoms.useFilter)

  if (filter === undefined) {
    return null
  }

  return (
    <>
      <CtxFilterComponent ctx={filter.ctx} filter={filter.filter} render={FilterView} />
      {filter.domain !== undefined && <FilterGenerationPopover domain={filter.domain} ctx={filter.ctx} />}
    </>
  )
}
