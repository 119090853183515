import { TruncatedText, aiGradient } from 'sierra-ui/components'
import styled from 'styled-components'

export const AIThemedPill = styled.div`
  display: flex;
  align-items: center;
  gap: 4px;
  ${aiGradient}
  border-radius: 99999px;
  color: white;
  font-size: 10px;
  font-style: normal;
  font-weight: 500;
  position: relative;
  padding: 2px 8px 2px 6px;
  text-transform: lowercase;
`

export const SkillTitle = styled(TruncatedText).attrs({
  color: 'foreground/primary',
  lines: 1,
  size: 'small',
  bold: true,
})`
  width: fit-content;
`
