import _ from 'lodash'
import { untoggleList } from 'sierra-client/views/v3-author/list/with-list'
import { getCurrentlySelectedNode, isElementType } from 'sierra-client/views/v3-author/queries'
import { hasOnlyEmptyTextInNodes } from 'sierra-domain/slate-util'
import { EditorKeyboardEvent } from 'sierra-domain/v3-author'
import { createParagraph } from 'sierra-domain/v3-author/create-blocks'
import { Editor, Node, Path, Point, Range } from 'slate'

const changeIndent = (action: 'increase' | 'decrease', indent: number | undefined): number => {
  // listItemNode.indent might be undefined, resulting in NaN
  const result = (indent ?? 0) + 1 * (action === 'increase' ? 1 : -1)

  return _.clamp(result, 0, 4)
}

export const shortcuts = (event: EditorKeyboardEvent, editor: Editor): void => {
  const { selection } = editor
  if (selection === null) return

  for (const [listItemNode, listItemPath] of editor.nodes({ match: isElementType('list-item') })) {
    // Adjust levels
    if (event.key === 'Tab') {
      event.preventDefault() // Don't jump

      const indent = changeIndent(event.shiftKey ? 'decrease' : 'increase', listItemNode.indent ?? 0)
      editor.setNodes({ indent }, { at: listItemPath })
    }
  }

  for (const [checkListItemNode, checkListItemPath] of editor.nodes({
    match: isElementType('check-list-item'),
  })) {
    // Adjust levels
    if (event.key === 'Tab') {
      event.preventDefault() // Don't jump
      const indent = changeIndent(event.shiftKey ? 'decrease' : 'increase', checkListItemNode.indent ?? 0)
      editor.setNodes({ indent }, { at: checkListItemPath })
    }

    if (event.key === 'Enter' && (event.metaKey || event.ctrlKey)) {
      event.preventDefault() // Don't jump
      editor.setNodes({ checked: !checkListItemNode.checked }, { at: checkListItemPath })
    }
  }

  const listItemEntry = getCurrentlySelectedNode(editor)
  if (
    listItemEntry !== undefined &&
    isElementType(['list-item', 'check-list-item'], listItemEntry[0]) &&
    Range.isCollapsed(selection)
  ) {
    const [listItemNode, listItemPath] = listItemEntry

    if (event.key === 'Enter' && !event.shiftKey) {
      // Split nodes, add new ones

      const parentNode = Node.get(editor, Path.parent(listItemPath))
      const lastIndex =
        (isElementType(['bulleted-list', 'numbered-list', 'check-list'], parentNode)
          ? parentNode.children
          : []
        ).length - 1

      const isFirstItem = _.last(listItemPath) === 0
      const isLastListItem = _.last(listItemPath) === lastIndex && !isFirstItem // if it's the last one and not the first

      if (isLastListItem && hasOnlyEmptyTextInNodes([listItemNode])) {
        // If the current text is empty, create a paragraph instead
        const parentPath = Path.next(Path.parent(listItemPath))

        editor.withoutNormalizing(() => {
          editor.deselect()
          editor.removeNodes({ at: listItemPath })
          editor.insertNodes(createParagraph(), { at: parentPath })
          editor.select(parentPath)
        })

        event.preventDefault()
        return
      }

      const [match] = editor.nodes({
        match: n => isElementType('list-item', n) && _.isEqual(n.children, [{ text: '' }]),
      })

      if (match !== undefined) {
        const [, path] = match
        const start = editor.start(path)

        if (Point.equals(selection.anchor, start)) {
          untoggleList(editor)
          return
        }
      }
    }
  }
}
