import React from 'react'
import { AddableDragList } from 'sierra-client/features/insights/widget-builder/data-selectors/addable-drag-list'
import { WIDGET_BUILDER_TRANSITION } from 'sierra-client/features/insights/widget-builder/data-selectors/animation-constants'
import { MeasureSelector } from 'sierra-client/features/insights/widget-builder/data-selectors/measure-selector'
import { WidgetBuilderState } from 'sierra-client/features/insights/widget-builder/widget-builder-state'
import { useTranslation } from 'sierra-client/hooks/use-translation'
import { MeasureRef, areMeasureRefsEqual } from 'sierra-domain/api/insights'
import { Button, View } from 'sierra-ui/primitives'

export const MultiMeasureSelector: React.FC<{
  onChange: (newMeasures: MeasureRef[]) => void
  selectedMeasures: MeasureRef[]
  disabled?: boolean
  visualisation: WidgetBuilderState['visualisation']
}> = ({ onChange, selectedMeasures, visualisation }) => {
  const { t } = useTranslation()

  return (
    <>
      {selectedMeasures.length === 0 ? (
        <View grow animated transition={WIDGET_BUILDER_TRANSITION} padding='4 none'>
          <MeasureSelector
            onChange={newMeasure => {
              onChange([...selectedMeasures, newMeasure.ref])
            }}
            selectedMeasure={undefined}
            disabledMeasures={selectedMeasures}
            visualisation={visualisation}
          />
        </View>
      ) : (
        <View grow animated transition={WIDGET_BUILDER_TRANSITION}>
          <AddableDragList
            values={selectedMeasures}
            onChange={onChange}
            equalFunction={areMeasureRefsEqual}
            addComponent={
              <MeasureSelector
                onChange={newMeasure => {
                  onChange([...selectedMeasures, newMeasure.ref])
                }}
                selectedMeasure={undefined}
                disabledMeasures={selectedMeasures}
                visualisation={visualisation}
                renderTrigger={() => (
                  <Button variant='secondary' icon='add' grow>
                    {t('dictionary.add')}
                  </Button>
                )}
              />
            }
            renderItem={(value, index) => {
              return (
                <MeasureSelector
                  onChange={newMeasure => {
                    const newMeasures = [...selectedMeasures]
                    newMeasures[index] = newMeasure.ref
                    onChange(newMeasures)
                  }}
                  selectedMeasure={value}
                  disabledMeasures={selectedMeasures}
                  visualisation={visualisation}
                  onDelete={() =>
                    onChange([
                      ...selectedMeasures.slice(0, index),
                      ...selectedMeasures.slice(index + 1, selectedMeasures.length),
                    ])
                  }
                />
              )
            }}
          />
        </View>
      )}
    </>
  )
}
