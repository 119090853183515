import { useMemo } from 'react'
import {
  getDimensionColumn,
  getMeasureColumn,
} from 'sierra-client/features/insights/display-widgets/data-utils'
import { cleanShortDurations } from 'sierra-client/features/insights/display-widgets/normalize-data/clean-short-duration'
import { fillDataGapsInInTableResult } from 'sierra-client/features/insights/display-widgets/normalize-data/fill-time-series-gaps'
import { TableResult, WidgetWithAtLeastOneDimension } from 'sierra-domain/api/insights'

const normalizeTableResult = ({
  tableResult,
  widget,
  serverTimeNow,
}: {
  tableResult: TableResult
  widget: WidgetWithAtLeastOneDimension
  serverTimeNow: string
}): TableResult => {
  const indexDimension = getDimensionColumn(tableResult.schema.columns, widget.dimensions[0])

  // Early return for list table, should probably solve better
  if ('measures' in widget === false) return tableResult

  const measure = getMeasureColumn(tableResult.schema.columns, widget.measures[0])

  const tableRows = tableResult.rows

  const tableRowsWithCleanDurations = cleanShortDurations({ tableRows, measure })

  const tableRowsWithFilledGaps = fillDataGapsInInTableResult({
    tableRows: tableRowsWithCleanDurations,
    widget,
    serverTimeNow,
    indexDimension,
    measure,
  })

  const normalizedTableResult = { ...tableResult, rows: tableRowsWithFilledGaps }
  return normalizedTableResult
}

export const useNormalizedTableResult = (
  tableResult: TableResult,
  widget?: WidgetWithAtLeastOneDimension,
  disabled?: boolean
): TableResult => {
  // TODO: add server clock
  // const serverClock = useSelector(selectClock)
  // const serverTimeNow = getServerTimeNow(serverClock)

  const disabledBoolean = disabled ?? false

  const normalizedTableResult: TableResult = useMemo(() => {
    if (disabledBoolean) return tableResult

    if (widget === undefined) {
      throw new Error('Widget is required to normalize table result')
    }

    return normalizeTableResult({ tableResult, widget, serverTimeNow: new Date().toISOString() })
  }, [tableResult, widget, disabledBoolean])

  return normalizedTableResult
}
