import React, { useContext, useEffect, useMemo, useState } from 'react'
import { selectCardExercises } from 'sierra-client/state/card-progress/selectors'
import { useDispatch, useSelector } from 'sierra-client/state/hooks'
import { submitBlockInteraction } from 'sierra-client/state/self-paced/actions'
import { FCC } from 'sierra-client/types'
import { useFileContext } from 'sierra-client/views/flexible-content/file-context'
import { EditorMode } from 'sierra-client/views/v3-author/slate'
import { Entity } from 'sierra-domain/entity'
import { Takeaways } from 'sierra-domain/v3-author'

type TakeawaysBlock = Entity<Takeaways>

type DataLayer = {
  submitInteraction: (takeawayItemId: string) => void
  hasPreviousInteractions: () => boolean
}

type ReportedItemsMap = Record<string, boolean>

const LiveContext = React.createContext<DataLayer | undefined>(undefined)
const SelfPacedContext = React.createContext<DataLayer | undefined>(undefined)
const CreateContext = React.createContext<DataLayer | undefined>(undefined)
const ReviewContext = React.createContext<DataLayer | undefined>(undefined)
const RecapContext = React.createContext<DataLayer | undefined>(undefined)

const SelfPacedDataProvider: FCC<{ element: TakeawaysBlock }> = ({ children, element }) => {
  const blockId = element.id
  const dispatch = useDispatch()
  const { file, flexibleContentId } = useFileContext()
  const fileId = file.id
  const exercises = useSelector(state => selectCardExercises(state, flexibleContentId, fileId))

  const [reportedItems, setReportedItems] = useState<ReportedItemsMap>(
    element.children.reduce((acc, child) => {
      acc[child.id] = false
      return acc
    }, {} as ReportedItemsMap)
  )

  useEffect(() => {
    if (Object.values(reportedItems).every(state => state === true)) {
      void dispatch(
        submitBlockInteraction({
          flexibleContentId,
          fileId,
          interactionContext: 'course',
          interaction: {
            type: 'takeaways-completed',
            blockId,
            timestamp: new Date(),
          },
        })
      )
    }
  }, [blockId, dispatch, fileId, flexibleContentId, reportedItems])

  const dataLayer: DataLayer = useMemo(
    () => ({
      submitInteraction: id => {
        setReportedItems(state => {
          return state[id] === true
            ? state
            : {
                ...state,
                [id]: true,
              }
        })
      },
      hasPreviousInteractions: () => {
        const exercise = exercises?.[blockId]
        return exercise?.attemptedInLessonAt !== undefined
      },
    }),
    [blockId, exercises]
  )

  return <SelfPacedContext.Provider value={dataLayer}>{children}</SelfPacedContext.Provider>
}

const FallbackDataProvider: FCC<{ element: TakeawaysBlock }> = ({ children }) => {
  return <RecapContext.Provider value={undefined}>{children}</RecapContext.Provider>
}

export const TakeawaysDataLayerProvider: FCC<{ element: TakeawaysBlock; mode: EditorMode }> = ({
  mode,
  element,
  children,
}) => {
  if (mode === 'self-paced')
    return <SelfPacedDataProvider element={element}>{children}</SelfPacedDataProvider>
  else return <FallbackDataProvider element={element}>{children}</FallbackDataProvider>
}

export const useTakeawayDataLayer = (): DataLayer | undefined => {
  const liveData = useContext(LiveContext)
  const selfPacedData = useContext(SelfPacedContext)
  const createData = useContext(CreateContext)
  const reviewData = useContext(ReviewContext)

  return liveData ?? selfPacedData ?? createData ?? reviewData
}
