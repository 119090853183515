import { useVirtualizer } from '@tanstack/react-virtual'
import { useMemo } from 'react'
import { isDefined } from 'sierra-domain/utils'

type VirtualizedRows<T> = {
  visibleRows: Array<T>
  padding: {
    top: number
    bottom: number
  }
}

type VirtualizedRowProps<T> = {
  originalRows: Array<T>
  count: number
  overscan: number
  containerRef: HTMLDivElement | null
  estimateSize: () => number
}

export const useVirtualizedRow = <T,>(props: VirtualizedRowProps<T>): VirtualizedRows<T> => {
  const rowVirtualizer = useVirtualizer({
    getScrollElement: () => props.containerRef,
    estimateSize: props.estimateSize,
    count: props.originalRows.length,
    overscan: 20,
  })

  const virtualRows = rowVirtualizer.getVirtualItems()
  const totalSize = rowVirtualizer.getTotalSize()
  // We need to add padding-top/bottom to the table to make sure the entire container stays the same height, while not rendering the virtual items
  const paddingTop = virtualRows[0]?.start ?? 0
  const paddingBottom =
    virtualRows.length > 0 ? totalSize - (virtualRows[virtualRows.length - 1]?.end ?? 0) : 0

  return useMemo(() => {
    return {
      visibleRows: virtualRows
        .map(virtualRow => {
          const row = props.originalRows[virtualRow.index]
          return row
        })
        .filter(isDefined),
      padding: {
        top: paddingTop,
        bottom: paddingBottom,
      },
    }
  }, [paddingBottom, paddingTop, props.originalRows, virtualRows])
}
