import { useQuery } from '@tanstack/react-query'
import React, { createContext, useCallback, useContext, useEffect, useMemo, useRef, useState } from 'react'
import {
  getRecordingCacheKeys,
  useStartRecordingMutation,
  useStopRecordingMutation,
} from 'sierra-client/api/hooks/use-recording'
import { useLiveSessionContext } from 'sierra-client/components/liveV2/contexts/live-session-data'
import { ConfirmOtherRecordingModal } from 'sierra-client/components/liveV2/recording/confirm-record-modal'
import { ScreeRecordModal } from 'sierra-client/components/liveV2/recording/record-modal'
import { RecordingIssueModal } from 'sierra-client/components/liveV2/recording/recording-issue-modal'
import { getVideoCallService } from 'sierra-client/components/liveV2/services/video-call-service'
import {
  ScreenRecordEndedEvent,
  ScreenRecordStartedEvent,
  VideoCallService,
} from 'sierra-client/components/liveV2/services/video-call-service/video-call-service'
import { Logging } from 'sierra-client/core/logging'
import { logger } from 'sierra-client/logger/logger'
import { typedPost } from 'sierra-client/state/api'
import { useDispatch, useSelector } from 'sierra-client/state/hooks'
import {
  liveSessionLocalAwarenessStateMerged,
  recordingEnded,
  recordingStarted,
} from 'sierra-client/state/live-session/actions'
import { selectMyCurrentRecordingId } from 'sierra-client/state/live-session/selectors'
import { startScreenRecording, stopScreenRecording } from 'sierra-client/state/live/actions'
import { selectScreenRecordingEnabled } from 'sierra-client/state/live/selectors'
import { XRealtimeStrategyLiveSessionGetRecording } from 'sierra-domain/routes'

/**
 * The currently supported region capture HTML elements
 */
type RegionCaptureHTMLElement = HTMLDivElement | HTMLIFrameElement

interface CropTarget {
  fromElement: (element: RegionCaptureHTMLElement) => Promise<object>
}

declare let self: Window & {
  CropTarget: CropTarget
}

type ScreenRecordContext = {
  regionCaptureRef: React.MutableRefObject<RegionCaptureHTMLElement | null>
  stream?: MediaStream
  showScreenRecordModal: () => void
  startScreenRecord: () => void
  stopScreenRecord: () => Promise<void>
  toggleScreenRecord: () => Promise<void>
}

const ScreenRecordContextObj = createContext<ScreenRecordContext | undefined>(undefined)

type MotitorRecordingResult = { reset: () => void; error: boolean }

const useMonitorRecordingStatus = ({ recordingId }: { recordingId?: string }): MotitorRecordingResult => {
  const [recordingErrorOccured, setRecordingErrorOccured] = useState<boolean>(false)
  const isScreenRecording = useSelector(selectScreenRecordingEnabled)

  const { data } = useQuery({
    queryKey: getRecordingCacheKeys(recordingId),
    queryFn: async () => {
      if (recordingId === undefined) return

      const fetchResult = await typedPost(XRealtimeStrategyLiveSessionGetRecording, { recordingId })
      return fetchResult
    },
    enabled: recordingId !== undefined && isScreenRecording,
    refetchInterval: 10000, // Verify the recording status every 10 seconds
    refetchIntervalInBackground: true,
    networkMode: 'online',
  })

  useEffect(() => {
    if (data === undefined) return

    if (data.status !== 'ongoing' && isScreenRecording) {
      setRecordingErrorOccured(true)
    }
  }, [data, isScreenRecording])

  const returnData = useMemo(
    () => ({
      reset: () => setRecordingErrorOccured(false),
      error: recordingErrorOccured,
    }),
    [recordingErrorOccured]
  )

  return returnData
}

export const RecordingProvider: React.FC<{ children: React.ReactNode }> = props => {
  const dispatch = useDispatch()
  const isScreenRecording = useSelector(selectScreenRecordingEnabled)
  const recordingId = useSelector(selectMyCurrentRecordingId)
  const [stream, setStream] = useState<MediaStream>()
  const [showRecordModal, setShowRecordModal] = useState<boolean>(false)
  const regionCaptureRef = useRef<RegionCaptureHTMLElement | null>(null)
  const liveSession = useLiveSessionContext()
  const startRecordingMutation = useStartRecordingMutation()
  const { mutate: stopRecordingMutation } = useStopRecordingMutation()
  const { error: recordingErrorOccured, reset: resetRecordingError } = useMonitorRecordingStatus({
    recordingId,
  })

  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const getCropTarget = useCallback(async (): Promise<object | undefined> => {
    const supportRegionCapture = 'CropTarget' in self && 'fromElement' in self.CropTarget
    logger.debug(`Recording Provider: Region capture available: ${supportRegionCapture.toString()}`)
    if (!supportRegionCapture || regionCaptureRef.current === null) {
      return undefined
    }

    const cropTarget = await self.CropTarget.fromElement(regionCaptureRef.current)
    return cropTarget
  }, [regionCaptureRef])

  const startScreenRecord = useCallback(() => {
    // reset any error state
    resetRecordingError()

    startRecordingMutation.mutate(
      { liveSessionId: liveSession.liveSessionId },
      {
        onSuccess: async data => {
          await dispatch(
            startScreenRecording({
              recordingId: data.recordingId,
              recordingChannel: data.recordingChannel,
              useUserId: data.recordedUserId,
            })
          )
          void dispatch(Logging.liveSession.recordingStarted())
        },
      }
    )
  }, [dispatch, startRecordingMutation, liveSession.liveSessionId, resetRecordingError])

  const stopScreenRecord = useCallback(async () => {
    await dispatch(stopScreenRecording())
  }, [dispatch])

  const showScreenRecordModal = useCallback(() => {
    setShowRecordModal(true)
  }, [])

  const toggleScreenRecord = useCallback(async () => {
    if (isScreenRecording) {
      await stopScreenRecord()
    } else {
      showScreenRecordModal()
    }
  }, [isScreenRecording, stopScreenRecord, showScreenRecordModal])

  useEffect(() => {
    let videoCallService: VideoCallService | undefined

    const screenRecordStarted: ScreenRecordStartedEvent = async event => {
      // TODO: merge these two to one dispatch
      await dispatch(recordingStarted({ id: event.recordingId, liveSessionId: liveSession.liveSessionId }))
      void dispatch(liveSessionLocalAwarenessStateMerged({ isRecordingWithId: event.recordingId }))
      setStream(event.stream)
    }

    const screenRecordEnded: ScreenRecordEndedEvent = async event => {
      // TODO: merge these two to one dispatch
      setStream(undefined)
      void dispatch(liveSessionLocalAwarenessStateMerged({ isRecordingWithId: undefined }))
      await dispatch(recordingEnded({ id: event.recordingId }))

      stopRecordingMutation({ recordingId: event.recordingId })
    }

    void (async () => {
      videoCallService = await getVideoCallService()
      videoCallService.on('screen-record-started', screenRecordStarted)
      videoCallService.on('screen-record-ended', screenRecordEnded)
    })()

    return () => {
      videoCallService?.off('screen-record-started', screenRecordStarted)
      videoCallService?.off('screen-record-ended', screenRecordEnded)
    }
  }, [dispatch, stopRecordingMutation, liveSession.liveSessionId])

  useEffect(() => {
    return () => {
      if (recordingId !== undefined) {
        void (async () => {
          await stopScreenRecord()
          // run screenRecordEnded manually since it might have been unsubscribed already
          void dispatch(liveSessionLocalAwarenessStateMerged({ isRecordingWithId: undefined }))
          setStream(undefined)
        })()
      }
    }
  }, [recordingId, stopScreenRecord, dispatch])

  useEffect(() => {
    if (recordingErrorOccured) {
      void stopScreenRecord()
    }
  }, [recordingErrorOccured, stopScreenRecord])

  const screenRecordContextValue = useMemo(
    () => ({
      regionCaptureRef,
      stream,
      showScreenRecordModal,
      startScreenRecord,
      stopScreenRecord,
      toggleScreenRecord,
    }),
    [regionCaptureRef, stream, showScreenRecordModal, startScreenRecord, stopScreenRecord, toggleScreenRecord]
  )

  return (
    <ScreenRecordContextObj.Provider value={screenRecordContextValue}>
      <ScreeRecordModal
        showRecordModal={showRecordModal}
        setShowRecordModal={setShowRecordModal}
        startScreenRecord={startScreenRecord}
      />
      <ConfirmOtherRecordingModal />
      {recordingErrorOccured && (
        <RecordingIssueModal clearIssue={() => resetRecordingError()} restartRecording={startScreenRecord} />
      )}
      {props.children}
    </ScreenRecordContextObj.Provider>
  )
}

export const useScreenRecordContext = (): ScreenRecordContext => {
  const context = useContext(ScreenRecordContextObj)

  if (context === undefined) {
    throw new Error('This component must be wrapped in a RecordingProvider')
  }

  return context
}
