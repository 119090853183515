import { isEqual } from 'lodash'
import React, { FC, useCallback } from 'react'
import { BreakoutRoomPreview } from 'sierra-client/components/liveV2/breakout-rooms/breakout-room-preview'
import { NoVideoCallView } from 'sierra-client/components/liveV2/cards/no-video-call-view'
import { useBreakoutInSession } from 'sierra-client/components/liveV2/hooks/use-breakout-in-session'
import { useIsFacilitatorOrLearnerLedSession } from 'sierra-client/components/liveV2/hooks/use-is-facilitator-or-learner-led-session'
import { useSelectCurrentCard } from 'sierra-client/components/liveV2/hooks/use-select-current-card'
import { useHasLiveContext } from 'sierra-client/components/liveV2/live-context'
import { useBreakoutRoomFacilitatorActions } from 'sierra-client/components/liveV2/live-provider'
import { ParticipantGridContainer } from 'sierra-client/components/liveV2/participant-grid-container'
import { useMemoizedParticipantIds } from 'sierra-client/components/liveV2/participant-hooks/participant-grid-hooks'
import { useLobbyGridParticipants } from 'sierra-client/components/liveV2/participant-hooks/use-lobby-grid-participants'
import { useTranslation } from 'sierra-client/hooks/use-translation'
import { useDispatch, useSelector } from 'sierra-client/state/hooks'
import {
  selectAllBreakoutRooms,
  selectBreakoutRoom,
  selectIsFacilitator,
} from 'sierra-client/state/live-session/selectors'
import { joinBreakoutRoom } from 'sierra-client/state/live/actions'
import { selectCurrentBreakoutRoomId } from 'sierra-client/state/live/selectors'
import { CreateContentId } from 'sierra-domain/api/nano-id'
import { Button, Heading, ScrollView, Text, View } from 'sierra-ui/primitives'
import { palette, spacing } from 'sierra-ui/theming'
import { v2_breakpoint } from 'sierra-ui/theming/breakpoints'
import { useMediaQuery } from 'sierra-ui/utils'
import styled from 'styled-components'

export const LobbyContainer = styled.div`
  height: 100%;
  width: 100%;
  display: flex;
  flex-direction: column;

  background-color: ${p => p.theme.color.grey95};

  @media (prefers-color-scheme: dark) {
    background-color: ${p => p.theme.color.grey95};
    color: ${palette.primitives.white};
  }
  @media (prefers-color-scheme: light) {
    background-color: #fcfcfc;
    color: ${palette.primitives.black};
  }
`

const ParticipantsContainer = styled.div`
  width: 100%;
  height: 100%;
  min-height: 60%;
  display: flex;
  flex-direction: column;
  gap: 10px;

  @media screen and (min-width: ${v2_breakpoint.phone}) {
    flex-direction: row;
  }
`

const BreakoutRoomsContainer = styled.div`
  width: 100%;
  max-width: 94rem;
  margin: auto;
  padding-top: ${spacing['12']};
  display: grid;
  grid-gap: ${spacing['12']};
  @media screen and (min-width: ${v2_breakpoint.desktop}) {
    grid-template-columns: 1fr 1fr;
  }
`

const ParticipantContainer = styled.div`
  display: flex;
  max-width: 100%;
  flex: 1;
  width: 100%;
  overflow: hidden;

  @media screen and (min-width: ${v2_breakpoint.phone}) {
    flex: 1 1 30%;
  }
`

const Topic = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  height: 50%;
  flex: 1;

  @media screen and (min-width: ${v2_breakpoint.phone}) {
    flex: 0 1 40%;
  }
`

const _LeftView = styled(View)`
  align-self: center;
  height: 100%;
  flex: 0 1 30%;
`

const BreakoutRoomOverview: FC = () => {
  const currentBreakoutRoomId = useSelector(selectCurrentBreakoutRoomId)
  const allBreakoutRoomIds = useSelector(state => selectAllBreakoutRooms(state).map(room => room.id), isEqual)
  const breakoutInSession = useBreakoutInSession()
  const isFacilitatorOrLearnerLedSession = useIsFacilitatorOrLearnerLedSession()
  const dispatch = useDispatch()

  const handleOnClick = useCallback(
    async (roomId: string) => {
      await dispatch(joinBreakoutRoom({ breakoutRoom: roomId }))
    },
    [dispatch]
  )

  return (
    <>
      {breakoutInSession && isFacilitatorOrLearnerLedSession && currentBreakoutRoomId === undefined && (
        <BreakoutRoomsContainer>
          {allBreakoutRoomIds.map(roomId => (
            <BreakoutRoomPreview key={roomId} roomId={roomId} onClick={handleOnClick} />
          ))}
        </BreakoutRoomsContainer>
      )}
    </>
  )
}

const LeftView: FC = () => {
  const currentBreakoutRoomId = useSelector(selectCurrentBreakoutRoomId)
  const currentBreakoutRoom = useSelector(state => selectBreakoutRoom(state, currentBreakoutRoomId))
  const { t } = useTranslation()
  const isFacilitator = useSelector(selectIsFacilitator)
  const currentCard = useSelectCurrentCard()
  const topic = currentCard?.data.type === 'breakout-room' ? currentCard.data.topic : undefined
  const isDarkTheme = useMediaQuery('(prefers-color-scheme: dark)')
  const { returnToMainRoom } = useBreakoutRoomFacilitatorActions()

  return (
    <>
      {currentBreakoutRoom !== undefined && (
        <_LeftView direction='column' justifyContent='center' padding='xsmall' gap='small'>
          <Topic>
            {topic !== undefined ? (
              <>
                <Text size='small' bold color='currentColor'>
                  {t('dictionary.named-room', { name: currentBreakoutRoom.title })}
                </Text>
                <ScrollView>
                  <Heading color={isDarkTheme ? 'white' : 'black'} size='h4'>
                    {topic}
                  </Heading>
                </ScrollView>
              </>
            ) : (
              <>
                <Text size='small' bold color='currentColor'>
                  {t('dictionary.room')}
                </Text>

                <Heading color={isDarkTheme ? 'white' : 'black'} size='h4'>
                  {currentBreakoutRoom.title}
                </Heading>
              </>
            )}
          </Topic>

          {isFacilitator && (
            <View>
              <Button
                onClick={async () => {
                  await returnToMainRoom()
                }}
                icon='chevron--left'
              >
                {t('live.main-room')}
              </Button>
            </View>
          )}
        </_LeftView>
      )}
    </>
  )
}

const Grid: FC<{ flexibleContentId: CreateContentId }> = React.memo(({ flexibleContentId }) => {
  const lobbyGridParticipants = useLobbyGridParticipants({
    participantLimit: 17,
    flexibleContentId,
  })

  const participants = useMemoizedParticipantIds(lobbyGridParticipants.participants)
  const passiveParticipants = useMemoizedParticipantIds(lobbyGridParticipants.passiveParticipants)

  return (
    <ParticipantGridContainer
      participants={participants}
      passiveParticipants={passiveParticipants}
      flexibleContentId={flexibleContentId}
    />
  )
})

const LobbyInner = ({ flexibleContentId }: { flexibleContentId: CreateContentId }): JSX.Element => {
  return (
    <LobbyContainer>
      <ParticipantsContainer>
        <LeftView />
        <ParticipantContainer>
          <Grid flexibleContentId={flexibleContentId} />
        </ParticipantContainer>
      </ParticipantsContainer>
      <BreakoutRoomOverview />
    </LobbyContainer>
  )
}

export const Lobby = ({ flexibleContentId }: { flexibleContentId: CreateContentId }): JSX.Element => {
  const hasLiveContext = useHasLiveContext()

  return (
    <>
      {!hasLiveContext ? (
        <NoVideoCallView card='lobby' />
      ) : (
        <LobbyInner flexibleContentId={flexibleContentId} />
      )}
    </>
  )
}
