import { useCachedQuery } from 'sierra-client/state/api'
import { DomainRep } from 'sierra-domain/filter/datatype/domain'
import { XRealtimeAdminUsersFilterUsersDomain } from 'sierra-domain/routes'

export const useEnrollmentRuleDomainReps = (): { domainReps: DomainRep[] | undefined } => {
  const r = useCachedQuery(XRealtimeAdminUsersFilterUsersDomain, {
    key: 'user.enrollment-rule',
  })

  return {
    domainReps: r.data?.domains ?? undefined,
  }
}
