import _ from 'lodash'
import { SkillLevelId, SkillLevelSettingId } from 'sierra-client/api/graphql/branded-types'
import { useTranslation } from 'sierra-client/hooks/use-translation'
import { MenuItem, MenuItemPrimitive } from 'sierra-ui/components/menu/types'
import { SingleSelectDropdown } from 'sierra-ui/primitives/menu-dropdown'

export type SkillLevel = {
  id: SkillLevelId
  description: string
  levelSetting: {
    id: SkillLevelSettingId
    index: number
    name: string
    defaultBadgeTheme: string
  }
}

export const AchievedLevelSelector: React.FC<{
  levels: Array<SkillLevel>
  value?: SkillLevelSettingId
  onChange: (id?: SkillLevelSettingId) => void
}> = ({ levels, value, onChange: _onChange }) => {
  const { t } = useTranslation()

  const NONE = _.capitalize(t('dictionary.none'))

  const menuItems: Array<MenuItemPrimitive> = [
    ...levels.map(
      (l): MenuItemPrimitive => ({
        label: t('skills.form.subscribe.user.up-to-level.text', { level: l.levelSetting.name }),
        id: l.levelSetting.id,
        type: 'label' as const,
      })
    ),
    { type: 'separator' as const, id: 'separator' },
    { type: 'label' as const, id: NONE, label: NONE },
  ]

  const selectedItem = menuItems.find(m => m.id === value)

  const onChange = (item: MenuItem): void => {
    _onChange(item.id === NONE ? undefined : SkillLevelSettingId.parse(item.id))
  }
  return (
    <SingleSelectDropdown
      onSelect={onChange}
      placeholder={NONE}
      selectedItem={selectedItem}
      menuItems={menuItems}
    />
  )
}

export const targetIsHigherOrEqualToAchieved = ({
  target,
  achieved,
  levels,
}: {
  target?: SkillLevelSettingId
  achieved?: SkillLevelSettingId
  levels: Array<SkillLevel>
}): boolean => {
  const achievedLevel = levels.find(level => level.levelSetting.id === achieved)
  const targetLevel = levels.find(level => level.levelSetting.id === target)

  if (targetLevel === undefined || achievedLevel === undefined) {
    //let this be handled by validation in schema
    return true
  }
  //make sure that target level is higher than any achieved level
  return achievedLevel.levelSetting.index <= targetLevel.levelSetting.index
}
