import { useEffect } from 'react'
import { Logging } from 'sierra-client/core/logging'
import { PageInstance } from 'sierra-client/core/logging/page/types'
import { useDeepEqualityMemo } from 'sierra-client/hooks/use-deep-equality-memo'
import { useDispatch, useSelector } from 'sierra-client/state/hooks'
import { enterPage } from 'sierra-client/state/logging/slice'
import { PageInstanceProps } from 'sierra-client/state/logging/types'
import { selectUserState } from 'sierra-client/state/user/user-selector'

export const usePageViewLogging = <Input extends PageInstanceProps, Output extends PageInstanceProps>(
  page: PageInstance<Input, Output>
): void => {
  const dispatch = useDispatch()
  const props = useDeepEqualityMemo(page.props)
  const userState = useSelector(selectUserState)

  useEffect(
    () => {
      void dispatch(enterPage({ category: page.category, id: page.id, props: page.props }))

      if (page.userIdRequired && userState.status.type === 'user') {
        void dispatch(Logging.page(page))
      } else if (!page.userIdRequired) {
        void dispatch(Logging.page(page))
      }
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [page.category, page.id, props, page.userIdRequired, userState.status.type]
  )
}
