import { Draft, produce } from 'immer'
import { HotspotAction, HotspotState } from 'sierra-client/views/v3-author/images/hotspots/types'
import { nanoid12 } from 'sierra-domain/nanoid-extensions'

export const hotspotReducer = produce((draft: Draft<HotspotState>, action: HotspotAction) => {
  switch (action.type) {
    case 'set-add-mode':
      draft.addModeEnabled = action.value
      break
    case 'add': {
      if (!draft.addModeEnabled) break

      draft.hotspots[nanoid12()] = action.hotspot

      draft.addModeEnabled = false
      break
    }
    case 'update': {
      draft.hotspots[action.id] = action.hotspot
      break
    }
    case 'delete': {
      delete draft.hotspots[action.id]
      break
    }
  }
})
