import { UseQueryResult, useQuery } from '@tanstack/react-query'
import { useEffect } from 'react'
import { postZod } from 'sierra-client/api'
import { clockSynced } from 'sierra-client/state/collaboration/actions'
import { Clock } from 'sierra-client/state/collaboration/types'
import { useDispatch } from 'sierra-client/state/hooks'
import { TimeNowResponse } from 'sierra-domain/api/common'
import { Either, isLeft } from 'sierra-domain/either'
import { XRealtimeCollaborationTimeNow } from 'sierra-domain/routes'

const CLOCK_SYNC_INTERVAL_MS = 60_000

// eslint-disable-next-line @typescript-eslint/no-explicit-any
function parseServerDateOrFallback(postResult: Either<any, TimeNowResponse>): Date {
  return isLeft(postResult) ? new Date() : new Date(postResult.right.timeNow)
}

function useServerClock(): UseQueryResult<Clock> {
  return useQuery({
    queryKey: [XRealtimeCollaborationTimeNow.path],
    queryFn: async () => {
      const startMs = performance.now()
      const result = await postZod(XRealtimeCollaborationTimeNow, {})
      const relativeEpochMs = performance.now()

      const reportedServerDate = parseServerDateOrFallback(result)
      const approxServerDelayMs = (relativeEpochMs - startMs) / 2
      const serverEpochMs = reportedServerDate.getTime() + approxServerDelayMs
      return { serverEpochMs, relativeEpochMs }
    },
    staleTime: CLOCK_SYNC_INTERVAL_MS,
    refetchInterval: CLOCK_SYNC_INTERVAL_MS,
    refetchIntervalInBackground: false,
  })
}

/**
 * Syncs the server clock with the store at a regular interval. Can be instantiated multiple times.
 */
export function SyncClock(): null {
  const dispatch = useDispatch()
  const serverTimeQuery = useServerClock()

  useEffect(() => {
    if (serverTimeQuery.data) {
      void dispatch(clockSynced(serverTimeQuery.data))
    }
  }, [serverTimeQuery.data, dispatch])

  return null
}
