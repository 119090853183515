import { useCallback } from 'react'
import { useCurrentUserId } from 'sierra-client/api/hooks/use-user'
import { useTranslation } from 'sierra-client/hooks/use-translation'
import { Label, Value } from 'sierra-client/lib/filter'
import { labelToString } from 'sierra-client/lib/filter/components/common'
import { fromPredicate, valueId } from 'sierra-client/lib/filter/components/predicate-utils'
import { FilterBase } from 'sierra-domain/filter/datatype/filter'
import { domainRefHasType } from 'sierra-domain/filter/query'
import { STATIC_EMPTY_ARRAY, isNonEmptyArray, isSingleArray } from 'sierra-domain/utils'

/**
 * Based on the current domain, try to grab different labels based on logic
 * 0 chosen -> defaultLabel
 * 1 chosen -> write only that label
 * 2+ chosen -> write `<first_value>, +<n_values - 1>`
 * unknown -> write unknown i18n text
 * */
export const useDropdownLabel = (
  choices: Array<{ value: Value; label: Label }> = STATIC_EMPTY_ARRAY
): { dropdownLabel: (vs: Value[], defaultLabel: Label) => string } => {
  const { dynamicT, t } = useTranslation()

  const findLabel = useCallback(
    (v: Value) => {
      const maybeLabel = choices.find(c => {
        return valueId(c.value) === valueId(v)
      })?.label
      return maybeLabel
    },
    [choices]
  )

  const dropdownLabel = useCallback(
    (vs: Value[], defaultLabel: Label) => {
      if (isSingleArray(vs)) {
        const label = findLabel(vs[0])
        return label ? labelToString(label, dynamicT) : t('dictionary.unknown')
      }

      if (isNonEmptyArray(vs)) {
        const headLabel = findLabel(vs[0])
        return headLabel
          ? labelToString(headLabel, dynamicT) + `, +${vs.length - 1}`
          : t('dictionary.unknown')
      }
      return labelToString(defaultLabel, dynamicT)
    },
    [dynamicT, findLabel, t]
  )

  return { dropdownLabel }
}

/**
 * A hook to encapsulate all logic in regards seeing if the current user
 * is a reviewer
 */
export const useIsCurrentUserReviewer = (f: FilterBase): boolean => {
  const currentUserId = useCurrentUserId()

  // current user should always exist but if it doesnt, yeah nothing to show
  if (currentUserId.data === undefined) {
    return false
  }

  // We only allow for the domain of reviewers
  if (!domainRefHasType(f.domain, 'homeworkSubmission.reviewerIds')) {
    return false
  }

  const choices = fromPredicate(f.predicate)

  // If we have many results, then we are not the only reviewer
  if (!isSingleArray(choices)) {
    return false
  }

  // as it is a single array, just see if that chosen user is the current user
  const chosenUser =
    choices[0].type !== 'value.exercise-id' && choices[0].type !== 'value.file-id'
      ? choices[0].value
      : undefined
  return chosenUser === currentUserId.data
}
