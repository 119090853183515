/* eslint-disable react/jsx-no-literals */
import React from 'react'
import { PrintButton, Wrap, WrapButtons } from 'sierra-client/views/learner/certificate/components'
import { CertificateProps } from 'sierra-client/views/learner/certificate/types'
import {
  getFormattedCompletionDate,
  getImageUrl,
  handlePrint,
} from 'sierra-client/views/learner/certificate/utils'
import { Heading, Spacer, Text } from 'sierra-ui/primitives'
import styled, { createGlobalStyle, css } from 'styled-components'

const GlobalStyle = createGlobalStyle`
  body {
    padding-top: 0 !important;
  }
`

const StyledWrap = styled(Wrap)<{ $isChrome: boolean }>`
  @page {
    size: portrait;
  }

  @media print {
    height: 100vh;
    padding-bottom: 0;
  }

  /* HACK: Apply bottom margin to Chrome */
  --extra-bottom-margin: ${p => (p.$isChrome ? '25mm' : '0px')};
`

const Container = styled.div`
  position: relative;

  display: flex;
  flex-direction: column;
  align-items: center;
  height: 100%;
  max-height: 100%;
`

const FullPageBorder = styled.div`
  position: absolute;
  inset: 0;
  bottom: var(--extra-bottom-margin);
  border: 1px solid black;
`

/* -- Certificate-specific constants -- */

/* Box size in pixels */
const BOX_SIZE = 20

const verticalStyle = {
  top: `top: ${(-1 * BOX_SIZE) / 2}px;`,
  bottom: `bottom: calc(${BOX_SIZE / 2}px + var(--extra-bottom-margin));`,
}

const horizontalStyle = {
  left: `left: ${(-1 * BOX_SIZE) / 2}px;`,
  right: `right: ${(-1 * BOX_SIZE) / 2}px;`,
}

const COLORS = {
  BLUE: '#001c71',
  GOLD: '#be9f56',
}

type CornerBoxProps = {
  $verticalPosition: keyof typeof verticalStyle
  $horizontalPosition: keyof typeof horizontalStyle
}

const CornerBox = styled.div<CornerBoxProps>`
  position: absolute;
  ${p => css`
    ${verticalStyle[p.$verticalPosition]}
    ${horizontalStyle[p.$horizontalPosition]}
  `};

  /* Instruct the browser to not ignore background colors */
  -webkit-print-color-adjust: exact !important; /* Chrome, Safari 6 – 15.3, Edge */
  color-adjust: exact !important; /* Firefox 48 – 96 */
  print-color-adjust: exact !important; /* Firefox 97+, Safari 15.4+ */

  /* White background behind box */
  &::before {
    content: '';
    display: block;
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    background-color: #fff;
    width: ${3 * BOX_SIZE}px;
    height: ${3 * BOX_SIZE}px;
  }

  /* Colored box */
  &::after {
    content: '';
    display: block;
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    background-color: ${COLORS.GOLD};
    width: ${BOX_SIZE}px;
    height: ${BOX_SIZE}px;
  }
`

const AlltOmJuridikLogoImg = styled.img`
  position: absolute;
  bottom: 125px;
  left: 50%;
  transform: translateX(-50%);
  width: 350px;
`

const CertificateNameWrapper = styled.div`
  padding: 0 8px;
  border-bottom: 1px solid black;
`

const StyledWrapButtons = styled(WrapButtons)`
  left: 2rem;
  right: auto;
`

const Body = styled(Text)`
  max-width: 50ch;
  text-align: center;
`

const MainHeading = styled(Heading)`
  width: 100%;
  text-align: center;
`

export const AlltOmJuridikCertificate: React.FC<CertificateProps> = ({
  course,
  user,
  completionTimestamp,
}) => (
  <>
    <GlobalStyle />
    <StyledWrap $isChrome={navigator.userAgent.toLowerCase().indexOf('chrome') > -1}>
      <Container>
        <FullPageBorder />
        <CornerBox $verticalPosition='top' $horizontalPosition='left' />
        <CornerBox $verticalPosition='top' $horizontalPosition='right' />
        <CornerBox $verticalPosition='bottom' $horizontalPosition='left' />
        <CornerBox $verticalPosition='bottom' $horizontalPosition='right' />
        <Spacer size='96' />
        <MainHeading color='LEGACY_DEFAULT_HEADING_COLOR_REPLACE_ASAP' size='h3' bold avoidHanging={false}>
          Kursintyg
        </MainHeading>
        <MainHeading color='LEGACY_DEFAULT_HEADING_COLOR_REPLACE_ASAP' size='h4' bold avoidHanging={false}>
          {course.settings.title}
        </MainHeading>
        <Spacer size='96' />
        <CertificateNameWrapper>
          <Heading color='LEGACY_DEFAULT_HEADING_COLOR_REPLACE_ASAP' size='h3' avoidHanging={false}>
            {user.firstName} {user.lastName}
          </Heading>
        </CertificateNameWrapper>
        <Spacer size='64' />

        <Body color='LEGACY_DEFAULT_TEXT_COLOR_REPLACE_ASAP' size='large'>
          Detta dokument intygar att deltagaren har genomfört Allt om Juridiks digitala kurs{' '}
          {course.settings.title}.
        </Body>
        <Spacer size='small' />

        {course.readingTimes !== undefined && (
          <>
            <Body color='LEGACY_DEFAULT_TEXT_COLOR_REPLACE_ASAP' size='large'>
              <strong>Kurslängd:</strong> {Math.round(course.readingTimes.total / 60)} minuter
            </Body>
            <Spacer size='small' />
          </>
        )}

        <Text color='LEGACY_DEFAULT_TEXT_COLOR_REPLACE_ASAP' size='large'>
          {getFormattedCompletionDate(completionTimestamp)}
        </Text>
        <Spacer size='144' />
        <Spacer size='144' />

        <AlltOmJuridikLogoImg src={getImageUrl('alltomjuridik-certificate-logo.png')} />
        <Spacer size='large' />
      </Container>
    </StyledWrap>
    <StyledWrapButtons>
      <PrintButton variant='secondary' onClick={handlePrint}>
        Print or download
      </PrintButton>
    </StyledWrapButtons>
  </>
)
