import {
  XRealtimeAdminXapiCreateRemoteLrsServer,
  XRealtimeAdminXapiDeleteRemoteLrsServer,
  XRealtimeAdminXapiGetRemoteLrsServers,
  XRealtimeAdminXapiUpdateRemoteLrsServer,
} from 'sierra-domain/routes'

import { useCallback, useEffect, useState } from 'react'
import { usePost } from 'sierra-client/hooks/use-post'
import { TranslationKey } from 'sierra-client/hooks/use-translation/types'
import { getUserErrorTranslationKey } from 'sierra-client/utils/translation-utils'
import { NanoId12 } from 'sierra-domain/api/nano-id'
import { CreateXApiServerRequest, UpdateXApiServerRequest } from 'sierra-domain/api/x-api'
import { isLeft } from 'sierra-domain/either'

export type XApiServer = Omit<UpdateXApiServerRequest, 'serverId'> & {
  serverId?: NanoId12
  isNew: boolean
}

export const getDefaultXApiServerValues = (id?: NanoId12 | 'new'): XApiServer => {
  const isNew = id === 'new' || id === undefined
  return {
    serverId: isNew ? undefined : id,
    name: '',
    description: '',
    tokenUrl: '',
    statementsUrl: '',
    clientId: '',
    clientSecret: undefined,
    actorType: undefined,
    isNew,
  }
}

type UseManageXApiDetailsData = {
  xApiServer: XApiServer
  isLoading: boolean
  error?: { i18nArgs: [TranslationKey, Record<string, unknown>?] }
  fetchXApiServer: (serverId: NanoId12) => Promise<void>
  createXApiServer: (req: CreateXApiServerRequest) => Promise<boolean>
  updateXApiServer: (serverId: NanoId12, req: Omit<UpdateXApiServerRequest, 'serverId'>) => Promise<void>
  deleteXApiServer: (serverId: NanoId12) => Promise<void>
}

export const useManageXApiDetails = (serverIdOrNew?: NanoId12 | 'new'): UseManageXApiDetailsData => {
  const { postWithUserErrorException, postWithUserErrorCode } = usePost()

  const [isLoading, setIsLoading] = useState<boolean>(false)
  const [error, setError] = useState<UseManageXApiDetailsData['error']>()
  const [xApiServer, setXApiServer] = useState<XApiServer>(getDefaultXApiServerValues(serverIdOrNew))

  const fetchXApiServer = useCallback<UseManageXApiDetailsData['fetchXApiServer']>(
    async id => {
      setIsLoading(true)
      const response = await postWithUserErrorException(XRealtimeAdminXapiGetRemoteLrsServers, {})
      const server = response.find(s => s.serverId === id)
      if (server !== undefined) {
        setXApiServer(data => ({ ...data, ...server }))
      }
      setIsLoading(false)
    },
    [postWithUserErrorException]
  )

  const createXApiServer = useCallback<UseManageXApiDetailsData['createXApiServer']>(
    async req => {
      setIsLoading(true)
      setError(undefined)

      const response = await postWithUserErrorCode(XRealtimeAdminXapiCreateRemoteLrsServer, req)
      let success: boolean
      if (isLeft(response)) {
        success = false
        setError({ i18nArgs: [getUserErrorTranslationKey(response.left)] })
      } else {
        success = true
        setXApiServer(data => ({ ...data, ...response.right, isNew: false }))
      }
      setIsLoading(false)
      return success
    },
    [postWithUserErrorCode]
  )

  const updateXApiServer = useCallback<UseManageXApiDetailsData['updateXApiServer']>(
    async (id, req) => {
      setIsLoading(true)
      const response = await postWithUserErrorException(XRealtimeAdminXapiUpdateRemoteLrsServer, {
        serverId: id,
        ...req,
      })
      setXApiServer(data => ({ ...data, ...response }))
      setIsLoading(false)
    },
    [postWithUserErrorException]
  )

  const deleteXApiServer = useCallback<UseManageXApiDetailsData['deleteXApiServer']>(
    async id => {
      setIsLoading(true)
      await postWithUserErrorException(XRealtimeAdminXapiDeleteRemoteLrsServer, { serverId: id })
      setXApiServer(getDefaultXApiServerValues(undefined))
      setIsLoading(false)
    },
    [postWithUserErrorException]
  )

  useEffect(() => {
    const parsedServerId = NanoId12.safeParse(serverIdOrNew)
    if (!parsedServerId.success) return
    void fetchXApiServer(parsedServerId.data)
  }, [fetchXApiServer, serverIdOrNew])

  return {
    isLoading,
    xApiServer,
    error,
    createXApiServer,
    fetchXApiServer,
    updateXApiServer,
    deleteXApiServer,
  }
}
