import React, { useEffect, useMemo, useState } from 'react'
import { useTranslation } from 'sierra-client/hooks/use-translation'
import { QuestionTextWithMath } from 'sierra-client/views/learner/components/text-with-math'
import { useIsInPdfExport } from 'sierra-client/views/v3-author/export-pdf/use-is-pdf-export'
import { useParent } from 'sierra-client/views/v3-author/hooks'
import { useIsInLiveQuizContext } from 'sierra-client/views/v3-author/live-quiz/live-quiz-context'
import { assertElementType } from 'sierra-client/views/v3-author/queries'
import {
  AlternativeInput,
  StyledPrompt,
  StyledPromptText,
} from 'sierra-client/views/v3-author/question-card/multiple-choice-alternative/shared'
import { useQuestionCardData } from 'sierra-client/views/v3-author/question-card/question-card-data-layer'
import { useAlternativeContext } from 'sierra-client/views/v3-author/question-card/use-alternative-context'
import { useRenderingContext } from 'sierra-client/views/v3-author/rendering-context'
import { SlateFC, SlateFCWrapper, SlateWrapperProps } from 'sierra-client/views/v3-author/slate'
import { ColorName } from 'sierra-ui/color/types'
import { View } from 'sierra-ui/primitives'
import { Element, Node, Text as SlateText } from 'slate'
import { useSelected } from 'slate-react'
import styled from 'styled-components'

const isEmptyExplanation = (element: Node | undefined): boolean =>
  SlateText.isText(element) && element.text === ''

const ExplanationWrapper = styled(View).attrs({
  padding: 'none',
  direction: 'row',
  gap: 'none',
  alignItems: 'center',
})<{ hide: boolean }>`
  ${p => (p.hide ? `display: none; user-select: none;` : '')};
  border: none;
  grid-area: explanation;
`

const QuestionCardMultipleChoiceAlternativeExplanationWrapper = React.forwardRef<
  HTMLDivElement,
  SlateWrapperProps
>((props, ref) => {
  const { readOnly, element } = props
  assertElementType('question-card-multiple-choice-alternative-explanation', element)
  const { preview } = useRenderingContext()

  const explanationContext = useAlternativeContext()
  const [loaded, setLoaded] = useState(false)
  const { questionCardData } = useQuestionCardData()
  const parent = useParent({ nodeId: element.id })

  const wasSelectedByUser = useMemo(
    () =>
      questionCardData.response.type === 'multiple-choice' && // Check if the user has already selected an alternative
      Element.isElement(parent) &&
      parent.type === 'question-card-multiple-choice-alternative' &&
      questionCardData.response.alternatives[parent.id]?.status === 'correct',
    [parent, questionCardData.response]
  )

  const answered = questionCardData.evaluation !== undefined // Check if we've submitted the question
  const isEmpty = isEmptyExplanation(element.children[0])
  const showExplanations = useIsInPdfExport()
  const isSelected = useSelected()
  const hideExplanation: boolean = ((): boolean => {
    if (showExplanations === true) {
      return false
    }
    if (readOnly) {
      if (isEmpty) {
        return true
      }
      return !(wasSelectedByUser && answered)
    } else {
      if (isSelected) {
        return false
      }

      if (preview === true) {
        return true
      }

      if (!(explanationContext?.explanationOpen ?? false) && isEmpty) {
        return true
      }

      return false
    }
  })()

  // Used to handle transition between preview state and editor state. Checks if explanation isEmpty once on transition
  // but doesn't block it from opening when using the toggle button.
  useEffect(() => {
    if (!loaded) {
      if (isEmpty) {
        explanationContext?.off()
      } else {
        explanationContext?.on()
      }
    }

    setLoaded(true)
  }, [explanationContext, isEmpty, loaded, setLoaded])

  return <ExplanationWrapper hide={hideExplanation} {...props} ref={ref} />
})

export const QuestionCardMultipleChoiceAlternativeExplanationSwitchWrapper: SlateFCWrapper = React.forwardRef<
  HTMLDivElement,
  SlateWrapperProps
>((props, ref) => {
  const isLiveQuizContext = useIsInLiveQuizContext()

  if (isLiveQuizContext) {
    return null
  }

  return <QuestionCardMultipleChoiceAlternativeExplanationWrapper ref={ref} {...props} />
})

const ExplanationContainer: React.FC<
  React.PropsWithChildren<{
    readOnly: boolean
    showExplanation?: boolean
    isEmpty: boolean
    color?: ColorName
  }>
> = ({ children, isEmpty, readOnly, color }) => {
  const { t } = useTranslation()

  return (
    <AlternativeInput readOnly={readOnly} margin color={color}>
      {isEmpty && (
        <StyledPrompt contentEditable={false}>
          <StyledPromptText>{t('author.abbreviation-explanation-placeholder')}</StyledPromptText>
        </StyledPrompt>
      )}
      {children}
    </AlternativeInput>
  )
}

const QuestionCardMultipleChoiceAlternativeExplanationLearner: SlateFC = ({ children, element }) => {
  assertElementType('question-card-multiple-choice-alternative-explanation', element)
  return (
    <ExplanationContainer readOnly isEmpty={false} color='grey70'>
      <QuestionTextWithMath readOnly element={element}>
        {children}
      </QuestionTextWithMath>
    </ExplanationContainer>
  )
}

const QuestionCardMultipleChoiceAlternativeExplanationCreate: SlateFC = ({ element, children, readOnly }) => {
  assertElementType('question-card-multiple-choice-alternative-explanation', element)
  const isEmpty = isEmptyExplanation(element.children[0])

  return (
    <ExplanationContainer isEmpty={isEmpty} readOnly={readOnly}>
      <QuestionTextWithMath readOnly={readOnly} element={element}>
        {children}
      </QuestionTextWithMath>
    </ExplanationContainer>
  )
}

export const QuestionCardMultipleChoiceAlternativeExplanation: SlateFC = props => {
  const { element, readOnly } = props
  const { preview } = useRenderingContext()
  assertElementType('question-card-multiple-choice-alternative-explanation', element)

  const Renderer =
    !readOnly && preview === false
      ? QuestionCardMultipleChoiceAlternativeExplanationCreate
      : QuestionCardMultipleChoiceAlternativeExplanationLearner

  return <Renderer {...props} />
}
