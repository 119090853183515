import React from 'react'
import { useTranslation } from 'sierra-client/hooks/use-translation'
import { Icon } from 'sierra-ui/components'
import { InputPrimitive } from 'sierra-ui/primitives'
import styled from 'styled-components'

const Root = styled.div`
  position: relative;
`

const StyledInput = styled(InputPrimitive)`
  padding-left: 2rem;
`

const StyledIcon = styled(Icon)`
  position: absolute;
  top: 50%;
  transform: translateY(-50%);
  left: 0.5rem;
`

export const SearchInput: React.FC<{
  term: string
  onChange: (newTerm: string) => void
}> = ({ term, onChange }) => {
  const { t } = useTranslation()

  return (
    <Root>
      <StyledIcon iconId='search' color='foreground/secondary' />
      <StyledInput
        value={term}
        onChange={e => onChange(e.target.value)}
        placeholder={`${t('dictionary.search')}...`}
      />
    </Root>
  )
}
