import { useAtom } from 'jotai'
import { useId } from 'react'
import useMeasure from 'react-use-measure'
import { useTranslation } from 'sierra-client/hooks/use-translation'
import { QueryStateAtom } from 'sierra-client/lib/querystate/use-query-state'
import { CreatePageBucket as CreatePageBucketsTypes } from 'sierra-client/views/workspace/create-new/filters/types'
import { MenuItem } from 'sierra-ui/components'
import { Text, View } from 'sierra-ui/primitives'
import { SingleSelectDropdown } from 'sierra-ui/primitives/menu-dropdown'
import { token } from 'sierra-ui/theming'
import { focusRing } from 'sierra-ui/utils'
import { maxWidth } from 'sierra-ui/utils/media-query-styles'
import styled, { css } from 'styled-components'

const fadePresence = css<{ hide: boolean }>`
  opacity: 1;
  transition: opacity 0.2s;
  ${p =>
    p.hide &&
    css`
      opacity: 0;
      pointer-events: none;
    `}
`

const TabsContainer = styled(View)`
  position: absolute;
  ${fadePresence}
`

const FadeContainer = styled.div<{ hide: boolean }>`
  ${fadePresence}
`

const Tab = styled(View).attrs({ as: 'button' })`
  padding: 8px 12px;
  border-radius: 10px;
  cursor: pointer;

  &[aria-selected='true'] {
    background: ${token('surface/soft')};
    border: 1px solid ${token('surface/soft')};
    color: ${token('foreground/primary')};
  }

  &[aria-selected='false'] {
    background: transparent;
    border: 1px solid ${token('foreground/primary').opacity(0.08)};
    color: ${token('foreground/primary').opacity(0.9)};
  }

  &:focus-visible {
    ${focusRing};
  }

  ${maxWidth.phone} {
    &[aria-selected='true'] {
      background: ${token('surface/soft')};
      border: 1px solid ${token('surface/soft')};
      color: ${token('foreground/primary')};
    }
  }
`

const CreateNewPageTabsDropDown: React.FC<{
  currentTab: CreatePageBucketsTypes
  setCurrentTab: (value: CreatePageBucketsTypes) => void
  showDropdown: boolean
}> = ({ currentTab, setCurrentTab, showDropdown }) => {
  const { t } = useTranslation()

  const tabsItems: MenuItem<CreatePageBucketsTypes>[] = [
    {
      type: 'label',
      id: 'everything',
      selected: currentTab === 'everything',
      label: t('create-overview.everything'),
      onClick: () => setCurrentTab('everything'),
    },
    {
      type: 'label',
      id: 'created-by-me',
      selected: currentTab === 'created-by-me',
      label: t('workspace.create.my-content'),
      onClick: () => setCurrentTab('created-by-me'),
    },
    {
      type: 'label',
      id: 'shared-with-me',
      selected: currentTab === 'shared-with-me',
      label: t('create-overview.shared-with-me'),
      onClick: () => setCurrentTab('shared-with-me'),
    },
  ]

  return (
    <FadeContainer hide={!showDropdown}>
      <SingleSelectDropdown
        grow={false}
        bold
        onSelect={item => setCurrentTab(item.id)}
        menuItems={tabsItems}
        selectedItem={tabsItems.find(it => it.id === currentTab)}
      />
    </FadeContainer>
  )
}

export const CreatePageTabs: React.FC<{
  selectedTabAtom: QueryStateAtom<CreatePageBucketsTypes>
}> = ({ selectedTabAtom }) => {
  const [selectedTab, setSelectedTab] = useAtom(selectedTabAtom)
  const { t } = useTranslation()
  const [fullSpaceRef, { width: fullSpaceWidth }] = useMeasure()
  const [containerRef, { width: containerWidth }] = useMeasure()
  const tabDomId = useId()
  const showDropdown = fullSpaceWidth <= containerWidth

  return (
    <View grow ref={fullSpaceRef}>
      <CreateNewPageTabsDropDown
        showDropdown={showDropdown}
        currentTab={selectedTab}
        setCurrentTab={setSelectedTab}
      />
      <TabsContainer hide={showDropdown} ref={containerRef} id={tabDomId}>
        <Tab
          role='tab'
          aria-selected={selectedTab === 'everything'}
          aria-controls={tabDomId}
          key='everything'
          onClick={() => setSelectedTab('everything')}
        >
          <Text bold color='currentColor'>
            {t('create-overview.everything')}
          </Text>
        </Tab>
        <Tab
          role='tab'
          aria-selected={selectedTab === 'created-by-me'}
          aria-controls={tabDomId}
          key='created-by-me'
          onClick={() => setSelectedTab('created-by-me')}
        >
          <Text bold color='currentColor'>
            {t('workspace.create.my-content')}
          </Text>
        </Tab>
        <Tab
          role='tab'
          aria-selected={selectedTab === 'shared-with-me'}
          aria-controls={tabDomId}
          key='shared-with-me'
          onClick={() => setSelectedTab('shared-with-me')}
        >
          <Text bold color='currentColor'>
            {t('create-overview.shared-with-me')}
          </Text>
        </Tab>
      </TabsContainer>
    </View>
  )
}
