import { Text } from 'sierra-ui/primitives'
import { palette } from 'sierra-ui/theming'
import { breakpoints } from 'sierra-ui/theming/breakpoints'
import styled, { css } from 'styled-components'

const UnitListItemContainer = styled.div<{ isLocked: boolean }>`
  position: relative;
  width: 100;
  margin-bottom: 0.5rem;
  padding-left: 1.5rem;

  @media screen and (min-width: ${breakpoints.phone}) {
    padding-left: 2.5rem;
  }

  &:last-of-type {
    margin-bottom: 2rem;
  }

  ${p =>
    p.isLocked &&
    css`
      pointer-events: none;
      opacity: 0.5;
    `}
`

const _UnitListItemText = styled(Text).attrs({
  size: 'medium',
})``

const UnitListItemText = styled(_UnitListItemText)<{ $disabled?: boolean }>`
  ${p =>
    (p.$disabled ?? false) &&
    css`
      color: ${p.theme.color.grey35};
    `}

  margin-left: 1rem;
  margin-bottom: 1rem;

  @media screen and (min-width: ${breakpoints.phone}) {
    margin-left: 0.5rem;
  }
`

const UnitIconContainer = styled.div`
  position: absolute;
  top: 50%;
  left: 0;
  display: flex;
  justify-content: center;
  align-items: center;
  width: 2rem;
  height: 2rem;
  transform: translateY(-50%);
`

type UnitStatusIconProps = {
  isPassed?: boolean
}

const UnitStatusIcon = styled.div<UnitStatusIconProps>`
  position: absolute;
  top: 50%;
  left: 50%;
  width: 0.5rem;
  height: 0.5rem;
  border-radius: 50%;
  transform: translate(-50%, -50%);
  background-color: ${p => (p.isPassed ?? false ? p.theme.color.greenBright : palette.grey[5])};
`

export const BaseUnitListItem = ({ label }: { label: string }): JSX.Element => {
  return (
    <UnitListItemContainer isLocked={false}>
      <UnitIconContainer>
        <UnitStatusIcon isPassed={false} />
      </UnitIconContainer>
      <UnitListItemText color='LEGACY_DEFAULT_TEXT_COLOR_REPLACE_ASAP'>{label}</UnitListItemText>
    </UnitListItemContainer>
  )
}
