import { FC, useEffect, useMemo, useState } from 'react'
import { useCachedQuery } from 'sierra-client/state/api'
import { XRealtimeListTimezones } from 'sierra-domain/routes'
import { Autocomplete, DefaultStyledAutocompleteOption } from 'sierra-ui/components'
import { ListContainer } from 'sierra-ui/components/autocomplete/reference-implementation/atoms'
import { IconButton, Text, View } from 'sierra-ui/primitives'
import { token } from 'sierra-ui/theming'
import styled, { css } from 'styled-components'

export const SelectedItemContainer = styled(View)<{ $disabled?: boolean }>`
  justify-content: space-between;
  width: 100%;
  gap: 16px;
  padding-left: 12px;
  padding-right: 8px;
  height: 40px;
  border: 1px solid ${token('border/strong')};
  border-radius: 8px;

  ${p =>
    p.$disabled === true &&
    css`
      pointer-events: none;
      opacity: 0.5;
    `}
`

type TimeZone = { id: string; name: string }

type TimeZoneInputProps = {
  onChange: (timeZoneId: string | undefined) => void
  timeZoneId: string | undefined
  disabled?: boolean
}

export const TimeZoneInput: FC<TimeZoneInputProps> = ({ disabled, timeZoneId, onChange }) => {
  const timeZonesQuery = useCachedQuery(XRealtimeListTimezones, {}, { staleTime: Infinity })
  const timeZonesResponse = timeZonesQuery.data

  const timeZones = timeZonesResponse?.timezones
  const [selectedTimeZone, setSelectedTimeZone] = useState<TimeZone | undefined>()

  const [query, setQuery] = useState('')

  // Try to select the initial tz when time zones load.
  // we need the disabled prop in the dep array since we want to reset the input to the prop value if it's toggled
  useEffect(() => {
    if (timeZones !== undefined) {
      const tz = timeZones.find(it => it.id === timeZoneId)
      setSelectedTimeZone(tz)
    }
  }, [timeZones, timeZoneId, disabled])

  const onTimeZoneSelect = (zone: TimeZone): void => {
    setSelectedTimeZone(zone)
    if (zone.id !== timeZoneId) {
      onChange(zone.id)
    }
  }

  const matchingTimezones = useMemo(() => {
    return (
      timeZones?.filter(i => {
        const name = i.name.toLowerCase()
        const q = query.toLowerCase()

        if (query.trim() === '') {
          return true
        }

        return name.includes(q)
      }) ?? []
    )
  }, [query, timeZones])

  return timeZones === undefined || selectedTimeZone !== undefined ? (
    <SelectedItemContainer $disabled={disabled}>
      <View direction='column'>
        <Text>{selectedTimeZone?.name ?? timeZoneId}</Text>
      </View>
      {disabled !== true && (
        <IconButton
          variant='transparent'
          iconId='close'
          size='small'
          onClick={() => setSelectedTimeZone(undefined)}
        />
      )}
    </SelectedItemContainer>
  ) : (
    <Autocomplete
      query={query}
      onQueryChange={setQuery}
      matchingItems={matchingTimezones}
      onSelect={onTimeZoneSelect}
      renderMatchingItemList={({ getItemProps }) => (
        <ListContainer>
          {matchingTimezones.map((zone, index) => (
            <DefaultStyledAutocompleteOption key={zone.id} {...getItemProps(zone, index)}>
              <Text size='small'>{zone.name}</Text>
            </DefaultStyledAutocompleteOption>
          ))}
        </ListContainer>
      )}
    />
  )
}
