import { HeadingSizes } from 'sierra-ui/primitives'
import { fonts } from 'sierra-ui/theming/fonts'
import styled from 'styled-components'

type HeadingProps = {
  $size: HeadingSizes
}

export const Heading = styled.h1.attrs<HeadingProps>(p =>
  p.$size === 'h1' || p.$size === 'hSuper' ? {} : { as: p.$size }
)<HeadingProps>`
  ${p => fonts.nonResponsiveHeading[p.$size]}
  text-align: left;

  &,
  & * {
    hyphens: none;
    word-break: keep-all;
    overflow-wrap: normal;
  }
`
