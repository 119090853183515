import { Icon, Tooltip } from 'sierra-ui/components'
import { Heading, Spacer, Text, View } from 'sierra-ui/primitives'
import styled from 'styled-components'

const OverviewItemContainer = styled.div`
  display: flex;
  flex-direction: column;
`

type OverviewItemProps = {
  data: string | number
  label: string
  tooltip?: string
  disabled?: boolean
}

export const OverviewItem: React.FC<OverviewItemProps> = ({ data, label, disabled, tooltip }) => (
  <OverviewItemContainer>
    <Heading size='h3' color={disabled === true ? 'foreground/muted' : 'foreground/primary'}>
      {data.toString()}
    </Heading>
    <Spacer size='4' />
    <View gap='none' alignItems='center'>
      <Text size='small' color='foreground/muted'>
        {label}
      </Text>
      {tooltip !== undefined && (
        <>
          <Spacer size='4' />
          <Tooltip title={tooltip}>
            <Icon iconId='information' size='size-16' color='foreground/muted' />
          </Tooltip>
        </>
      )}
    </View>
  </OverviewItemContainer>
)
