import { useState } from 'react'
import { useIsPermissionsInspectorToggled } from 'sierra-client/components/shortcut-menu/permissions-inspector/atoms'
import { useEditLocalPermissionsConfig } from 'sierra-client/components/shortcut-menu/permissions-inspector/hooks/use-edit-local-permission-config'
import { useInitializePermissionsInspector } from 'sierra-client/components/shortcut-menu/permissions-inspector/hooks/use-initialize-permissions-inspector'
import {
  isPermissionKey,
  permissionCategoryLabels,
  permissionOptions,
} from 'sierra-client/components/shortcut-menu/permissions-inspector/utils'
import { PermissionKey, PermissionOptions } from 'sierra-client/hooks/use-permissions/types'
import { withPanel } from 'sierra-client/views/manage/utils/with-modal'
import { Icon } from 'sierra-ui/components'
import { Checkbox, Heading, ScrollView, Spacer, Text, View } from 'sierra-ui/primitives'
import { zIndex } from 'sierra-ui/theming'
import styled from 'styled-components'

const PermissionInspectorContainer = styled(View).attrs({ padding: '6 10' })`
  position: fixed;
  right: 100px;
  bottom: 0;

  display: flex;
  flex-direction: row;
  gap: 2px;

  z-index: ${zIndex.DEBUG_LAYER};
  transform: translateY(0);
  background: #3e3e3e;
  border-radius: 6px 6px 0 0;
  cursor: pointer;

  width: 95px;
`

const MinHeight = styled(View).attrs({ direction: 'column', grow: true })`
  height: 0;
`

// TODO: Access level presets for an org?

export const PermissionSection = <K extends PermissionKey>({
  title,
  category,
  permissions,
}: {
  title: string
  category: K
  permissions: PermissionOptions[K][]
}): React.ReactElement => {
  const { localPermissionsConfig, togglePermission } = useEditLocalPermissionsConfig()

  return (
    <View direction='column' gap='8' marginBottom='16'>
      <Text bold>{title}</Text>
      {permissions.map(p => (
        <View
          key={p}
          direction='row'
          justifyContent='flex-start'
          alignItems='center'
          paddingLeft='8'
          gap='8'
          cursor='pointer'
          onClick={() => togglePermission(category, p)}
        >
          <Checkbox checked={localPermissionsConfig[category].includes(p)} />
          <Text>{p}</Text>
        </View>
      ))}
    </View>
  )
}

const ConfigurationPanel = withPanel(
  {
    size: { width: 656 },
    padding: '32',
    disableScrollbarGutter: true,
    overlayVariant: 'light',
  },
  () => (
    <View grow direction='column' justifyContent='space-between'>
      <MinHeight>
        <Heading size='h5' bold>
          Permission overrides
        </Heading>
        <Spacer size='none' />
        <View direction='row'>
          <Icon color='yellowBright' iconId='warning--filled' />
          <Text color='foreground/muted'>
            Current permissions are overridden and don&apos;t reflect your user&apos;s permissions or access
            level
          </Text>
        </View>
        <Spacer size='16' />
        <ScrollView>
          {Object.entries(permissionOptions).flatMap(([key, value]) =>
            isPermissionKey(key) ? (
              <PermissionSection
                key={key}
                title={permissionCategoryLabels[key]}
                category={key}
                permissions={value}
              />
            ) : null
          )}
        </ScrollView>
      </MinHeight>
    </View>
  )
)

export const PermissionsInspector: React.FC = () => {
  const [isConfiguratorOpen, setIsConfiguratorOpen] = useState(false)

  const isPermissionsInspectorToggled = useIsPermissionsInspectorToggled()
  const { isInitialized } = useInitializePermissionsInspector()

  if (!isInitialized || !isPermissionsInspectorToggled) {
    return null
  }

  return (
    <>
      <PermissionInspectorContainer onClick={() => setIsConfiguratorOpen(!isConfiguratorOpen)}>
        <Text bold size='technical' unselectable='on' color='grey5'>
          Permissions
        </Text>
        <Icon color='yellowBright' iconId='warning--filled' />
      </PermissionInspectorContainer>
      <ConfigurationPanel
        open={isConfiguratorOpen}
        onClose={() => setIsConfiguratorOpen(!isConfiguratorOpen)}
      />
    </>
  )
}
