import { useContext } from 'react'
import {
  OutlineContext,
  ReducerContext,
} from 'sierra-client/features/program/admin/edit/edit-outline-reducer-provider'

export const useOutlineReducer = (): OutlineContext => {
  const context = useContext(ReducerContext)

  // eslint-disable-next-line @typescript-eslint/no-unnecessary-condition, @typescript-eslint/strict-boolean-expressions
  if (!context) {
    throw new Error('useOutlineReducer must be used within an EditOutlineReducerProvider')
  }

  return context
}
