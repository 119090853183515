import { useCallback } from 'react'
import { containerStyles, seeThroughStyles } from 'sierra-client/views/course-helper/shared/containers'
import { useSelfPacedFiles } from 'sierra-client/views/self-paced/files-provider'
import { ColorBuilder, color } from 'sierra-ui/color'
import { Icon } from 'sierra-ui/components'
import { View } from 'sierra-ui/primitives'
import styled, { css, useTheme } from 'styled-components'

const ButtonInternal = styled.button`
  position: relative;
  width: 60px;
  height: 52px;
  padding: 0;
  cursor: pointer;
  pointer-events: all;
  background-color: transparent;

  display: flex;
  justify-content: center;
  align-items: center;
  ${containerStyles};
  ${seeThroughStyles};
`

const ColoredIcon = styled(Icon)<{ $color: undefined | { backgroundColor: ColorBuilder } }>`
  ${p =>
    p.$color !== undefined &&
    css`
      box-shadow: none;
      ${p.$color.backgroundColor.brightness() > 0.9
        ? css`
            color: rgba(0, 0, 0, 0.4);
          `
        : css`
            color: white;
            opacity: 0.6;
          `};
    `}
`
export const Previous: React.FC = () => {
  const { prevFile, goTo } = useSelfPacedFiles()
  const theme = useTheme()
  const backgroundColor = color(theme.home.backgroundColor)

  const goToPrevious = useCallback(() => {
    if (prevFile !== undefined) goTo(prevFile.id)
  }, [goTo, prevFile])

  if (prevFile === undefined) {
    return <View />
  }

  return (
    <ButtonInternal onClick={goToPrevious} $seeThrough={{ backgroundColor: backgroundColor }}>
      <ColoredIcon iconId='previous--filled' size='size-20' $color={{ backgroundColor: backgroundColor }} />
    </ButtonInternal>
  )
}
