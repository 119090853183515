import { lazy, Suspense } from 'react'
import { useIsDebugMode } from 'sierra-client/hooks/use-is-debug-mode'

function lazyLoadRouterDevtools(): React.FC {
  if (process.env.NODE_ENV === 'production') {
    return () => null
  }

  const routerDevtools = import('@tanstack/router-devtools')
  return lazy(() => routerDevtools.then(res => ({ default: res.TanStackRouterDevtools })))
}

type LazyDevtoolsComponent = typeof import('@tanstack/router-devtools').TanStackRouterDevtools
const LazyDevtoolsComponent = lazyLoadRouterDevtools() as LazyDevtoolsComponent

/**
 * Style props for offsetting the devtools button so it doesn't cover the react-query devtools.
 */
const devtoolsButtonStyleProps = { style: { left: 60, bottom: 10 } }

/**
 * Lazy-loaded devtools for @tanstack/react-router, only loads in dev.
 */
export const LazyRouterDevtools = (): JSX.Element | null => {
  const debugMode = useIsDebugMode()

  if (process.env.NODE_ENV === 'production' || !debugMode) {
    return null
  }

  return (
    <Suspense>
      <LazyDevtoolsComponent
        toggleButtonProps={devtoolsButtonStyleProps}
        closeButtonProps={devtoolsButtonStyleProps}
      />
    </Suspense>
  )
}
