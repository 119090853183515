import { DateTime } from 'luxon'
import { useCallback, useMemo } from 'react'
import { useTranslation } from 'sierra-client/hooks/use-translation'
import { logger } from 'sierra-client/logger/logger'
import { useSelector } from 'sierra-client/state/hooks'
import { selectUser } from 'sierra-client/state/user/user-selector'
import { isDefined } from 'sierra-domain/utils'

export const useDateToLocaleString = (date: Date): string => {
  const { language } = useSelector(selectUser) || {}

  return useMemo(() => {
    let result
    const fallback = navigator.language
    const options = { month: 'short', year: 'numeric' } as const

    try {
      const locale = isDefined(language) && language.length > 0 ? language : fallback
      result = date.toLocaleString(locale, options)
    } catch (error) {
      logger.error('Failed to convert date to locale string', { error, language, date })
    } finally {
      result = date.toLocaleString(fallback, options)
    }

    return result
  }, [language, date])
}

export const useDateAndTimeAgo = (dateTime: DateTime): { formattedDate: string; formattedTime: string } => {
  const { t } = useTranslation()
  const formattedDate = useMemo(() => {
    const daysDiff = Math.abs(dateTime.startOf('day').diff(DateTime.now().startOf('day'), 'days').days)

    const time = dateTime.toLocaleString({ hour: '2-digit', minute: '2-digit' })

    // This displays "Today" and "Yesterday" for close days, and dates for anything further back in time
    if (daysDiff === 0) return `${t('version-history.time.today')}, ${time}`
    if (daysDiff === 1) return `${t('version-history.time.yesterday')}, ${time}`
    if (daysDiff < 7) return `${dateTime.toLocaleString({ weekday: 'long' })}, ${time}`
    else
      return dateTime.toLocaleString({
        month: 'short',
        day: 'numeric',
        year: 'numeric',
        hour: '2-digit',
        minute: '2-digit',
      })
  }, [dateTime, t])

  const formattedTime = useMemo(() => dateTime.toLocaleString({ timeStyle: 'short' }), [dateTime])

  return { formattedDate, formattedTime }
}

export const useCreateDateTime = (): ((date: Date) => DateTime) => {
  const userLanguage = useSelector(selectUser)?.language

  return useCallback(
    date => {
      if (userLanguage === undefined || userLanguage === '') return DateTime.fromJSDate(date).setLocale('en')
      else return DateTime.fromJSDate(date).setLocale(userLanguage)
    },
    [userLanguage]
  )
}
