import _ from 'lodash'
import {
  createContext,
  KeyboardEventHandler,
  useCallback,
  useContext,
  useEffect,
  useMemo,
  useRef,
  useState,
} from 'react'
import { InlineTextIndicator } from 'sierra-client/views/commenting/text-highlights-layer'
import { rangeToMergedRelativeRectangles } from 'sierra-client/views/commenting/utils'
import { ColorBuilder } from 'sierra-ui/color'
import { MUIClickAwayListener, MUIPopper } from 'sierra-ui/mui'
import { FCC } from 'sierra-ui/types'
import { BaseRange } from 'slate'
import { useSlate } from 'slate-react'
import styled from 'styled-components'
// import { LearnerNotesHighlights } from './learner-notes-highlights'
import { motion } from 'framer-motion'
import { TextHighlightsLayer } from 'sierra-client/views/commenting/text-highlights-layer'
import { useSelfPacedFiles } from 'sierra-client/views/self-paced/files-provider'
import { RangeContentReference } from 'sierra-domain/chat'
import { Button, TextAreaPrimitive, View } from 'sierra-ui/primitives'

import { useNotif } from 'sierra-client/components/common/notifications'
import { AppThemeTokenProvider } from 'sierra-client/config/token-provider'
import { useTranslation } from 'sierra-client/hooks/use-translation'
import { typedPost } from 'sierra-client/state/api'
import { useGetFileTitle } from 'sierra-client/state/flexible-content/file-title'
import { useSelfPacedBigContext } from 'sierra-client/views/flexible-content/self-paced-big-context'
import { EditableContent, editUrl } from 'sierra-domain/api/editable-content'
import { FileId } from 'sierra-domain/flexible-content/identifiers'
import { XRealtimeAuthorUpdateLearnerNotes } from 'sierra-domain/routes'
import { textInNodes } from 'sierra-domain/slate-util'
import { assertIsNonNullable } from 'sierra-domain/utils'
import { zIndex } from 'sierra-ui/theming'

interface LearnerNotesContextValue {
  range: BaseRange | undefined
  setRange: (value: BaseRange | undefined) => void
  highlightIndicator: InlineTextIndicator | undefined
  setHighlightIndicator: (value: InlineTextIndicator | undefined) => void
}

const LearnerNotesContextImpl = createContext<LearnerNotesContextValue | null>(null)

export function useLearnerNotes(): LearnerNotesContextValue | undefined {
  const context = useContext(LearnerNotesContextImpl)
  if (context === null) {
    return undefined
  }
  return context
}

const LearnerNotesContainer = styled(motion.div)`
  display: flex;
  flex-direction: column;
  z-index: 1;
  width: 480px;
  background-color: white;
  padding: 16px;
  box-shadow: 0px 16px 24px 1px rgba(0, 0, 0, 0.08);
  border-radius: 16px;
  gap: 16px;
`

const NoteBlockQuote = styled.div`
  position: relative;

  opacity: 0.8;
  background: rgba(251, 230, 191, 0.6);
  color: rgba(113, 52, 7, 1);
  transition:
    background-color 200ms ease-out,
    color 200ms ease-out;
  padding: 16px 32px;
  border-radius: 12px;
  display: flex;
  justify-content: flex-start;
  align-items: center;
  line-height: 20px;

  &::before {
    content: '';
    position: absolute;
    background: rgba(255, 198, 144, 1);

    opacity: 0.8;
    width: 4px;
    height: calc(100% - 32px);
    margin-left: 16px;
    border-radius: 2px;
    left: 0;
  }
`

const StyledPre = styled.pre`
  font-family: 'Sana Serif 05';

  white-space: pre-wrap;
`

const StyledTextArea = styled(TextAreaPrimitive)`
  padding: 0;
  outline: none;
  :hover {
    outline: none;
  }
  border-radius: 0;
  font-size: 16px;
`

const RemoteNotesWrapper: React.FC<{
  rangeText: string | undefined
  onDone: (courseId: EditableContent['id'], fileId?: FileId) => void
  onClose: () => void
}> = ({ rangeText, onDone, onClose }) => {
  const { t } = useTranslation()
  const [text, setText] = useState('')
  const [isLoading, setIsLoading] = useState(false)

  useEffect(() => {
    return () => setText('')
  }, [])

  const selfPacedFiles = useSelfPacedFiles()

  const courseId = selfPacedFiles.flexibleContentId

  const { slateDocumentMap } = useSelfPacedBigContext()

  const currentFile = selfPacedFiles.currentFile
  const getFileTitle = useGetFileTitle(slateDocumentMap)

  assertIsNonNullable(currentFile)

  const fileTitle = getFileTitle(currentFile)

  const handleSubmit = useCallback(async () => {
    setIsLoading(true)
    const result = await typedPost(XRealtimeAuthorUpdateLearnerNotes, {
      courseId,
      fileId: currentFile.id,
      fileTitle: fileTitle,
      markedText: rangeText ?? '',
      noteText: text,
    })
    setIsLoading(false)
    if (result.notesLocation !== undefined) {
      onDone(result.notesLocation.courseId, result.notesLocation.fileId)
    }
  }, [courseId, currentFile.id, fileTitle, onDone, rangeText, text])

  const handleKeyPress: KeyboardEventHandler<HTMLTextAreaElement> = async event => {
    event.stopPropagation()
    if ((event.ctrlKey || event.metaKey) && event.key === 'Enter') {
      event.preventDefault()
      await handleSubmit()
    }
    if (event.key === 'Escape') {
      onClose()
    }
  }

  return (
    <>
      <StyledTextArea
        autoFocus
        autoExpand
        placeholder={t('learner-notes.placeholder')} //'Write a note...'
        rows={2}
        value={text}
        onChange={event => setText(event.target.value)}
        onKeyDown={handleKeyPress}
      />
      <View justifyContent='flex-end' alignItems='flex-end'>
        <Button onClick={onClose} variant='transparent'>
          {t('cancel')}
        </Button>
        <Button loading={isLoading} variant='success' onClick={handleSubmit}>
          {t('modal.save')}
        </Button>
      </View>
    </>
  )
}

export const LearnerNotesProvider: FCC<{ container: HTMLElement; color: ColorBuilder }> = ({
  children,
  container,
  color,
}) => {
  const [range, setRange] = useState<BaseRange | undefined>(undefined)
  const [open, setOpen] = useState(false)
  const [highlightIndicator, setHighlightIndicator] = useState<InlineTextIndicator | undefined>()
  const notif = useNotif()
  const { t } = useTranslation()
  const anchorRef = useRef<HTMLDivElement | null>(null)

  const editor = useSlate()

  const rangeReference = useMemo((): RangeContentReference | undefined => {
    if (!range) return undefined

    const sourceText = editor
      .fragment(range)
      .map(node => textInNodes([node]).join(''))
      .join('\n\n')
      .trim()

    return {
      type: 'range',
      id: 'learner-note-highlight',
      unitId: 'temp-unit-id',
      rangePreview: sourceText,
    }
  }, [editor, range])

  useEffect(() => {
    const containerRect = container.getBoundingClientRect()

    if (range === undefined) return

    const rectangles = rangeToMergedRelativeRectangles({ editor, containerRect, range })
    const anchor = _.last(rectangles)

    if (anchor === undefined) return

    setOpen(true)

    setHighlightIndicator({
      id: 'learner-note-highlight',
      opacity: 0.2,
      rectangles: rectangles,
    })
  }, [container, editor, range])

  const handleOnDone = useCallback(
    (id: EditableContent['id'], fileId?: FileId) => {
      notif.push({
        type: 'custom',
        body: t('learner-notes.saved'), //'Saved to notes'
        target: '_blank',
        level: 'success',
        url: editUrl('native:self-paced', id, fileId),
      })
      setHighlightIndicator(undefined)
      setOpen(false)
    },
    [notif, t]
  )

  return (
    <LearnerNotesContextImpl.Provider value={{ range, setRange, highlightIndicator, setHighlightIndicator }}>
      <TextHighlightsLayer
        ref={anchorRef}
        color={color}
        indicators={highlightIndicator ? [highlightIndicator] : []}
      />
      <MUIPopper
        style={{ zIndex: zIndex.TOOLBAR }}
        open={open}
        disablePortal
        anchorEl={anchorRef.current}
        placement='top-start'
        modifiers={{
          offset: {
            enabled: true,
            offset: '0, 8',
          },
          flip: {
            enabled: true,
          },
          preventOverflow: {
            enabled: false,
          },
          hide: {
            enabled: false,
          },
          computeStyle: {
            gpuAcceleration: true,
          },
        }}
      >
        <AppThemeTokenProvider>
          <MUIClickAwayListener
            mouseEvent='onMouseDown'
            onClickAway={() => {
              setRange(undefined)
              setHighlightIndicator(undefined)
              setOpen(false)
            }}
          >
            <LearnerNotesContainer
              initial={{ opacity: 0, y: 6 }}
              animate={{ opacity: 1, y: 0 }}
              transition={{ duration: 0.15, ease: 'easeOut' }}
            >
              {rangeReference && (
                <NoteBlockQuote>
                  <StyledPre>
                    {rangeReference.rangePreview !== undefined && rangeReference.rangePreview.length > 0 ? (
                      <>{rangeReference.rangePreview}</>
                    ) : (
                      'Lorum ipsum dolor sit amet'
                    )}
                  </StyledPre>
                </NoteBlockQuote>
              )}

              <RemoteNotesWrapper
                rangeText={rangeReference?.rangePreview}
                onDone={handleOnDone}
                onClose={() => {
                  setOpen(false)
                  setHighlightIndicator(undefined)
                }}
              />
            </LearnerNotesContainer>
          </MUIClickAwayListener>
        </AppThemeTokenProvider>
      </MUIPopper>

      {children}
    </LearnerNotesContextImpl.Provider>
  )
}
