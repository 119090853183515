import { useAtomValue } from 'jotai/index'
import { useMemo } from 'react'
import { config } from 'sierra-client/config/global-config'
import { selectCourseIsCompleted } from 'sierra-client/state/card-progress/selectors'
import { useSelector } from 'sierra-client/state/hooks'
import { selectOrganizationRatingModalEnabled } from 'sierra-client/state/organization/selectors'
import { helperStateAtom } from 'sierra-client/views/course-helper/atoms'
import { useUserHasSubmittedRating } from 'sierra-client/views/course-helper/content/course-rating/use-user-has-submitted-rating'
import { HelperState } from 'sierra-client/views/course-helper/types'
import { useSelfPacedFiles } from 'sierra-client/views/self-paced/files-provider'
import { assertNever } from 'sierra-domain/utils'

const shouldStateShowCourseRating = (state: HelperState): boolean => {
  switch (state.type) {
    case 'course-completed':
    case 'next-course-in-program':
    case 'next-course-in-path':
      return true
    case 'none':
    case 'forward':
    case 'text':
    case 'program-completed':
    case 'program-pending':
    case 'course-pending':
    case 'undefined' /* TODO(seb): Remove once all are implemented */:
      return false
    default:
      assertNever(state)
  }
}

export const useShouldShowCourseRating = (): boolean => {
  const state = useAtomValue(helperStateAtom)
  const stateShouldShowCourseRating = useMemo(() => shouldStateShowCourseRating(state), [state])

  const { flexibleContentId } = useSelfPacedFiles()
  const hasAlreadySubmittedRating = useUserHasSubmittedRating(flexibleContentId)
  const organizationRatingEnabled = useSelector(selectOrganizationRatingModalEnabled)
  const courseCompleted = useSelector(state => selectCourseIsCompleted(state, flexibleContentId))

  const shouldRequestRating =
    !config.scorm.isScorm &&
    organizationRatingEnabled &&
    hasAlreadySubmittedRating === 'has-not-submitted-rating' &&
    courseCompleted &&
    stateShouldShowCourseRating

  return shouldRequestRating
}
