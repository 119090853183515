import { takeRight } from 'lodash'
import React, { FC, memo, useEffect, useMemo, useRef } from 'react'
import { ChatMessage } from 'sierra-client/components/chat/chat-message/chat-message'
import { EmojiMessages } from 'sierra-client/components/chat/chat-message/emoji-message'
import { EmojiPickerPopupProvider } from 'sierra-client/components/common/emoji'
import { useTranslation } from 'sierra-client/hooks/use-translation'
import { chatVisibilityChanged } from 'sierra-client/state/chat/actions'
import { MessageGroup, selectMessageGroups } from 'sierra-client/state/chat/selectors'
import { useDispatch, useSelector } from 'sierra-client/state/hooks'
import { ChatIdentifier } from 'sierra-domain/api/chat'
import { ScopedChatId } from 'sierra-domain/collaboration/types'
import { Icon } from 'sierra-ui/components'
import styled from 'styled-components'

const MessagesWrap = styled.div`
  flex-grow: 1;
  overflow: auto;
`

const EmptyMessage = styled.div`
  font-size: 14px;
  padding: 1.5rem;
  color: ${p => p.theme.color.grey25};
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  gap: 0.25rem;
  height: 100%;
`

const MessageGroupComp = ({
  chatId,
  chatIdentifier,
  messageGroup,
  setThreadId,
  withoutContentReferences,
}: {
  chatId: ScopedChatId
  chatIdentifier: ChatIdentifier
  messageGroup: MessageGroup
  setThreadId?: (id: string) => void
  withoutContentReferences: boolean
}): JSX.Element | null => {
  if (messageGroup.type === 'user') {
    return (
      <>
        {messageGroup.messageIds.map((msgId, index) => {
          return (
            <ChatMessage
              key={msgId}
              userId={messageGroup.userId}
              chatId={chatId}
              chatIdentifier={chatIdentifier}
              messageId={msgId}
              timeSent={messageGroup.timeSent}
              setThreadId={setThreadId}
              hideTitle={index > 0}
              withoutContentReferences={withoutContentReferences}
            />
          )
        })}
      </>
    )
  }

  return <EmojiMessages group={messageGroup} />
}

const MessageGroupCompMemo = memo(
  MessageGroupComp,
  (prevProps, newProps) =>
    prevProps.messageGroup.id === newProps.messageGroup.id &&
    prevProps.messageGroup.messageIds.length === newProps.messageGroup.messageIds.length
)

type MessageGroupProps = {
  chatId: ScopedChatId
  chatIdentifier: ChatIdentifier
  threadId: string
  setThreadId?: (id: string) => void
  withoutContentReferences: boolean
  componentKey: string
  visible: boolean
  filter: 'resolved' | 'unresolved' | null
  limitMessages?: number
}

const MessageGroups: FC<MessageGroupProps> = ({
  chatId,
  chatIdentifier,
  threadId,
  setThreadId,
  withoutContentReferences,
  componentKey,
  visible,
  filter,
  limitMessages,
}) => {
  const ref = useRef<HTMLDivElement>(null)
  const allMessageGroups = useSelector(state => selectMessageGroups(state, chatId, threadId, filter))
  const dispatch = useDispatch()
  const { t } = useTranslation()

  const messageGroups = useMemo(
    () => (limitMessages !== undefined ? takeRight(allMessageGroups, limitMessages) : allMessageGroups),
    [allMessageGroups, limitMessages]
  )

  useEffect(() => {
    const timeout = setTimeout(() => {
      if (ref.current !== null) ref.current.scrollTop = ref.current.scrollHeight
    }, 0)
    return () => clearTimeout(timeout)
  }, [])

  useEffect(() => {
    const timeout = setTimeout(() => {
      if (ref.current !== null) {
        const pixelsToBottom = ref.current.scrollHeight - ref.current.scrollTop - ref.current.clientHeight
        const scrollToBottomIfWithinPx = 200
        if (pixelsToBottom <= scrollToBottomIfWithinPx) {
          ref.current.scrollTop = ref.current.scrollHeight
        }
      }
    }, 0)

    return () => clearTimeout(timeout)
  }, [messageGroups])

  useEffect(() => {
    void dispatch(chatVisibilityChanged({ chatId, threadId, componentKey, visible: visible }))

    return () => {
      void dispatch(chatVisibilityChanged({ chatId, threadId, componentKey, visible: false }))
    }
  }, [chatId, threadId, visible, componentKey, dispatch])

  return (
    <MessagesWrap ref={ref}>
      {messageGroups.length === 0 && (
        <EmptyMessage>
          <Icon iconId='face' size='size-16' color='grey35' />
          {t(filter === 'resolved' ? 'live.chat.no-unresolved-comments' : 'live.chat.start-a-conversation')}
        </EmptyMessage>
      )}
      {messageGroups.map(messageGroup => {
        return (
          <MessageGroupCompMemo
            key={messageGroup.id}
            chatId={chatId}
            chatIdentifier={chatIdentifier}
            messageGroup={messageGroup}
            setThreadId={setThreadId}
            withoutContentReferences={withoutContentReferences}
          ></MessageGroupCompMemo>
        )
      })}
    </MessagesWrap>
  )
}

type MessagesProps = {
  chatId: ScopedChatId
  chatIdentifier: ChatIdentifier
  threadId: string
  setThreadId?: (id: string) => void
  withoutContentReferences?: boolean
  componentKey: string
  filter: 'resolved' | 'unresolved' | null
  /** Pass true to indicate that the messages are visible to the user.
   * This is used to keep track of if a user has seen a message or not (for notification purposes).
   */
  visible?: boolean
  limitMessages?: number
}

export const Messages: React.FC<MessagesProps> = ({
  chatId,
  chatIdentifier,
  threadId,
  setThreadId,
  withoutContentReferences = false,
  componentKey,
  visible = true,
  filter,
  limitMessages,
}) => {
  return (
    <EmojiPickerPopupProvider>
      <MessageGroups
        filter={filter}
        chatId={chatId}
        chatIdentifier={chatIdentifier}
        threadId={threadId}
        setThreadId={setThreadId}
        withoutContentReferences={withoutContentReferences}
        componentKey={componentKey}
        visible={visible}
        limitMessages={limitMessages}
      />
    </EmojiPickerPopupProvider>
  )
}
