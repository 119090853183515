import { useFetchProgramOutline } from 'sierra-client/views/manage/programs/staggered-assignments/queries/fetch-program-outline'
import { deserializeOutline } from 'sierra-client/views/manage/programs/staggered-assignments/utils'
import { ProgramOutline } from 'sierra-domain/api/manage'
import { ProgramId } from 'sierra-domain/api/uuid'

type UseProgramOutline = (input: { programId: ProgramId }) => {
  outline: ProgramOutline
  enrollmentCount: number
  initialOutlineLoading: boolean
}

export const useProgramOutline: UseProgramOutline = ({ programId }) => {
  const query = useFetchProgramOutline({ programId })
  const outline = query.data?.program ? deserializeOutline(query.data.program) : { steps: [], sections: [] }
  const enrollmentCount = query.data?.program?.enrollmentCount ?? 0
  const initialOutlineLoading = query.isPending

  return { outline, enrollmentCount, initialOutlineLoading }
}
