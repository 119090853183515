import { createSelector } from '@reduxjs/toolkit'
import { TemplatesState } from 'sierra-client/state/templates/types'
import { RootState } from 'sierra-client/state/types'

const selectTemplates = (rootState: RootState): TemplatesState => rootState.templates

export const selectSanaTemplates = createSelector([selectTemplates], templates => templates.sanaTemplates)

export const selectPartnerTemplates = createSelector(
  [selectTemplates],
  templates => templates.partnerTemplates
)
