import { createContext, useContext } from 'react'
import { useDeepEqualityMemo } from 'sierra-client/hooks/use-deep-equality-memo'
import { EditorMode } from 'sierra-client/views/v3-author/slate'
import { ChatIdentifier } from 'sierra-domain/api/chat'
import { AssetContext } from 'sierra-domain/asset-context'
import { ScopedChatId } from 'sierra-domain/collaboration/types'
import { SlashMenuEntryId } from 'sierra-domain/v3-author/slash-menu'

export type EditorContextData = {
  editorId: string
  chatId?: ScopedChatId
  chatIdentifier?: ChatIdentifier
  mode: EditorMode
  supportedSlashMenuEntryIds: readonly SlashMenuEntryId[]
  readOnly: boolean
  enableCommenting: boolean
  rulesOfBlocksViolations: 'warning' | 'error'
  assetContext: AssetContext
}

export const EditorContext = createContext<EditorContextData | undefined>(undefined)

/**
 * Note: The context is provided by an <EditorContext> component, so there must be one of these in the UI tree in
 * order to use this hook.
 */
export const useEditorContextValue = (): EditorContextData => {
  const context = useContext(EditorContext)
  if (context === undefined)
    throw new Error('No context found. This component must be wrapped in an <EditorContext> component.')

  return useDeepEqualityMemo(context)
}

/**
 * Note: The context is provided by an <EditorContext> component, so there must be one of these in the UI tree in
 * order to use this hook.
 */
export const useEditorContextValueSafe = (): EditorContextData | undefined => {
  const context = useContext(EditorContext)
  return useDeepEqualityMemo(context)
}

/**
 * The current id of the editor. This is most commonly used as a key to identify which editor state
 * to look at in the redux state.
 *
 * Note: see `useAuthoringContext`
 */
export const useEditorId = (): string => useEditorContextValue().editorId

export const useRulesOfBlockViolationSeveriy = (): EditorContextData['rulesOfBlocksViolations'] =>
  useEditorContextValue().rulesOfBlocksViolations

/**
 * The id of the chat attached to the current editor. This will be undefined if chat is currently
 * unsupported for this editor.
 *
 * Note: see `useAuthoringContext`
 */
export const useEditorChatId = (): ScopedChatId | undefined => useEditorContextValue().chatId
export const useEditorChatIdentifier = (): ChatIdentifier | undefined =>
  useEditorContextValue().chatIdentifier

/**
 * This value can be used to determine the context in which the editor is being used - i.e. if it
 * is being used to render content for learners or for creators.
 */
export const useEditorMode = (): EditorMode => useEditorContextValue().mode

/**
 * Indicates if the editor is in readOnly mode.
 */
export const useEditorReadOnly = (): boolean => useEditorContextValue().readOnly

/**
 * Indicates if commenting is enabled in the editor
 */
export const useEditorCommentingEnabled = (): boolean => useEditorContextValue().enableCommenting

export const useEditorAssetContext = (): AssetContext => useEditorContextValue().assetContext
