import rehypeKatex from 'rehype-katex'
import rehypeStringify from 'rehype-stringify'
import remarkGfm from 'remark-gfm'
import remarkMath from 'remark-math'
import remarkParse from 'remark-parse'
import remarkRehype from 'remark-rehype'
import { unified } from 'unified'

const processor = unified()
  .use(remarkParse)
  .use(remarkMath)
  .use(remarkRehype)
  .use(remarkGfm)
  .use(rehypeKatex, { trust: true, output: 'html' })
  .use(rehypeStringify)

export const renderMarkdown = (str: string): string => {
  if (!str) return ''

  return (processor.processSync(str) as any).toString() as string
}
