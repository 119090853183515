import { View } from 'sierra-ui/primitives'
import styled from 'styled-components'

export const GridContainer = styled.div`
  width: 100%;
  height: 100%;
  padding: 0;
  display: grid;
  grid-template-columns: repeat(12, 1fr);
  grid-column-gap: 1.5rem;
  grid-template-rows: auto 1fr;
  grid-row-gap: 0.5rem;

  > * {
    grid-column: 1/-1;
  }
`

export const PageHeader = styled.header.attrs({ role: 'banner' })`
  display: flex;
  flex-direction: column;
  margin-bottom: 32px;
`

export const NoContentContainer = styled(View).attrs({
  direction: 'column',
  gap: '16',
  justifyContent: 'center',
  alignItems: 'center',
})`
  min-height: 15rem;
  padding-top: 15vh;
`
