import { useCallback } from 'react'
import { useGetFormattedTime } from 'sierra-client/core/format'
import { useTranslation } from 'sierra-client/hooks/use-translation'
import { getGlobalRouter } from 'sierra-client/router'
import { BackToHome } from 'sierra-client/views/course-helper/content/actions'
import { RecommendationDisplay } from 'sierra-client/views/course-helper/content/course-helper-recommendations/recommendation-display'
import { HelperActions } from 'sierra-client/views/course-helper/shared/shared'
import { getLearnEntityName } from 'sierra-client/views/manage/content/utils/content-utils'
import { LearnEntity } from 'sierra-domain/api/entities'
import { Icon } from 'sierra-ui/components'
import { Text, View } from 'sierra-ui/primitives'

const RecommendationMetadata: React.FC<{ recommendation: LearnEntity }> = ({ recommendation }) => {
  const { t } = useTranslation()
  const contentLabel = t(getLearnEntityName(recommendation.entityType))
  const totalTime = recommendation.timeEstimate
  const progress = recommendation.learnerContext.progress
  const hasStarted = progress > 0
  const timeLeft = hasStarted ? Math.round((1 - progress) * totalTime) : totalTime

  const formattedTimeLeft = useGetFormattedTime(timeLeft, hasStarted)
  return (
    <View gap='none'>
      <Text bold color='foreground/secondary'>
        {contentLabel}
      </Text>
      {formattedTimeLeft.length > 0 && (
        <>
          <Icon iconId='radio-button--dot' color='foreground/secondary' />
          <Text color='foreground/secondary'>{formattedTimeLeft}</Text>
        </>
      )}
    </View>
  )
}

export const Recommendations: React.FC<{
  replaceHeadline?: React.ReactNode
  recommendations: LearnEntity[]
}> = ({ replaceHeadline, recommendations }) => {
  const { t } = useTranslation()
  const goBackHome = useCallback(() => getGlobalRouter().navigate({ to: '/' }), [])

  return (
    <View direction='column' gap='16'>
      {replaceHeadline === undefined ? (
        <View direction='column' gap='none'>
          <Text bold color='foreground/secondary'>
            {t('helper.recommended-for-you')}
          </Text>
        </View>
      ) : (
        replaceHeadline
      )}
      <View direction='column' gap='16'>
        {recommendations.map(e => (
          <RecommendationDisplay
            key={e.id}
            entity={e}
            metadata={<RecommendationMetadata recommendation={e} />}
            recommendationType='recommendations'
          />
        ))}
      </View>
      <HelperActions>
        <BackToHome onClick={goBackHome} />
      </HelperActions>
    </View>
  )
}
