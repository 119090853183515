import React from 'react'
import { getCourseDefaultImage } from 'sierra-client/api/content'
import { CourseImage } from 'sierra-client/components/common/modals/multi-assign-modal/types'
import { useAssetResolver } from 'sierra-client/hooks/use-resolve-asset'
import { LinkedInLearningChip } from 'sierra-client/views/manage/linked-in/linked-in-utils'
import { ScormChip } from 'sierra-client/views/manage/scorm/scorm-utils'
import { CourseRecommendationType } from 'sierra-domain/api/recommendation/course-recommendation'
import { AssetContext } from 'sierra-domain/asset-context'
import { ImageUnion } from 'sierra-domain/content/v2/content'
import { assertNever } from 'sierra-domain/utils'
import { palette, spacing } from 'sierra-ui/theming'
import { v2_breakpoint } from 'sierra-ui/theming/breakpoints'
import { fonts } from 'sierra-ui/theming/fonts'
import styled, { css } from 'styled-components'

const CardImage = styled.img`
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  object-fit: cover;

  && {
    margin-bottom: 0.5rem;
  }
`

const FloatIcon = styled.div`
  position: absolute;
  top: 1rem;
  left: 1rem;
`

const CardImageContainer = styled.div<{ $amount: number; $rounded: boolean; $isThumbnail: boolean }>`
  position: relative;
  overflow: hidden;
  background-color: ${p => p.theme.color.grey10};
  border-radius: ${p => (p.$rounded ? (p.$isThumbnail ? '0.25rem' : '0.5rem') : '0')};

  ${({ $amount, $isThumbnail }) => {
    if ($amount === 1) {
      return css`
        grid-column: span 4;
        grid-row: span 4;
      `
    } else if ($amount === 2) {
      return css`
        grid-column: span 2;
        grid-row: span 4;
      `
    } else if ($amount === 3) {
      return css`
        grid-column: span 2;
        grid-row: span 2;

        &:first-child {
          grid-column: span 2;
          grid-row: span 4;
        }
      `
    } else if ($amount === 4) {
      return css`
        grid-column: span 2;
        grid-row: span 2;
      `
    } else if ($amount === 5) {
      return css`
        grid-column: span 2;
        grid-row: span 2;

        &:nth-child(4),
        &:nth-child(5) {
          grid-column: span 1;
        }
      `
    } else if ($amount === 6) {
      return css`
        grid-column: span 2;
        grid-row: span 2;

        &:nth-child(4) {
          grid-column: span 1;
        }

        &:nth-child(5),
        &:nth-child(6) {
          grid-column: span 1;
          grid-row: span 1;
        }
      `
    } else if ($amount === 7) {
      return css`
        grid-column: span 2;
        grid-row: span 2;

        &:nth-child(1n + 4) {
          grid-column: span 1;
          grid-row: span 1;
        }
      `
    } else {
      const left = $amount - 7
      return `
        grid-column: span 2;
        grid-row: span 2;

        &:nth-child(1n + 4) {
          grid-column: span 1;
          grid-row: span 1;
        }

        &:nth-child(7) {
          &::before {
            content: '';
            position: absolute;
            top: 0;
            left: 0;
            width: 100%;
            height: 100%;
            background-color: rgba(0, 0, 0, 0.25);
            z-index: 2;
          }

          &::after {
            content: '+${left}';
            position: absolute;
            top: 50%;
            left: 50%;
            transform: translate(-50%, -50%);
            ${
              $isThumbnail
                ? css`
                    font-size: 0.5rem;
                  `
                : fonts.body.small
            };
            color: ${palette.primitives.white};
            z-index: 2;
          }
        }
      `
    }
  }}
`

const ImageGridContainer = styled.div<{ $primaryCard: boolean }>`
  position: relative;
  width: 100%;
  display: grid;
  padding-bottom: 56.25%;

  ${p =>
    p.$primaryCard &&
    css`
      @media screen and (min-width: ${v2_breakpoint.tablet}) {
        padding-bottom: 75%;
      }
    `}
`

const ImageGrid = styled.div<{ $isThumbnail: boolean }>`
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  width: 100%;
  height: 100%;
  display: grid;
  grid-gap: ${p => (p.$isThumbnail ? '2px' : spacing.xxsmall)};

  grid-template-columns: repeat(4, 1fr);
  grid-template-rows: repeat(4, 1fr);
`

type PreviewImagesProps = {
  images: CourseImage[] | undefined
  primaryCard?: boolean
  type: 'course' | 'path' | 'program' | 'thumbnail'
  courseKind?: CourseRecommendationType
}

// Note: This function can theoretically return an empty list.
// Note: We accept undefined values in the input array since they are replaced with a default image.
const resolveImages = (
  { images, type }: PreviewImagesProps,
  assetResolver: (image: ImageUnion | undefined, assetContext: AssetContext) => string
): string[] => {
  if (images === undefined || images.length === 0) {
    return [getCourseDefaultImage()]
  }

  switch (type) {
    case 'path':
    case 'thumbnail':
      return images.map(image => assetResolver(image.image, image.assetContext))
    case 'program':
    case 'course':
      if (images.length > 0 && images[0] !== undefined) {
        return [assetResolver(images[0].image, images[0].assetContext)]
      } else {
        return [getCourseDefaultImage()]
      }
    default:
      assertNever(type)
  }
}

export const PreviewImages: React.FC<PreviewImagesProps> = props => {
  const { primaryCard = false, type, courseKind } = props

  const assetResolver = useAssetResolver({ size: primaryCard ? 'course' : 'course-sm' })

  const imageURLs = resolveImages(props, assetResolver)
  const imageCount = imageURLs.length

  const courseMark =
    courseKind === 'scorm' ? (
      <ScormChip variant='course' circle={!primaryCard} />
    ) : courseKind === 'linkedin' ? (
      <LinkedInLearningChip variant='white' circle={!primaryCard} />
    ) : null

  return (
    <ImageGridContainer $primaryCard={primaryCard}>
      <ImageGrid $isThumbnail={type === 'thumbnail'}>
        {imageURLs.slice(0, 7).map((url, idx) => (
          <CardImageContainer
            key={`grid-image-${idx}-${url}`}
            $isThumbnail={type === 'thumbnail'}
            $amount={imageCount}
            $rounded={imageCount > 1}
          >
            {url !== '' && <CardImage src={url} loading='lazy' />}
          </CardImageContainer>
        ))}
      </ImageGrid>
      {courseMark !== null && <FloatIcon>{courseMark}</FloatIcon>}
    </ImageGridContainer>
  )
}
