import { useParams } from '@tanstack/react-router'
import React from 'react'
import { ShortcutMenu } from 'sierra-client/components/shortcut-menu'
import { useActiveRouteId } from 'sierra-client/hooks/router/use-active-route'
import { useDeveloperToolsEnabled } from 'sierra-client/hooks/use-developer-tools-enabled'
import { LiveContentId, SelfPacedContentId } from 'sierra-domain/api/nano-id'
import {
  ScopedCreateContentId,
  ScopedLiveContentId,
  ScopedSelfPacedContentId,
  YDocScope,
} from 'sierra-domain/collaboration/types'
import { FileId } from 'sierra-domain/flexible-content/identifiers'
import { assertIsNonNullable, isDefined } from 'sierra-domain/utils'
import { z } from 'zod'

const YDocMetadata = z.object({ scope: YDocScope, documentId: z.string() })
type YDocMetadata = z.infer<typeof YDocMetadata>

export function useYDocMetadata(): YDocMetadata | undefined {
  const routeId = useActiveRouteId()
  const { _splat, flexibleContentId, liveSessionId } = useParams({
    strict: false,
    select: p => ({
      _splat: p._splat,
      flexibleContentId: p.flexibleContentId,
      liveSessionId: p.liveSessionId,
    }),
  })

  const developerToolsEnabled = useDeveloperToolsEnabled()
  if (!developerToolsEnabled) return undefined

  if (routeId === '/create/l/$') {
    return YDocMetadata.safeParse({ scope: 'live-content', documentId: _splat?.split('/')[0] }).data
  }

  if (routeId === '/l/$liveSessionId/') {
    return YDocMetadata.safeParse({ scope: 'live-session', documentId: liveSessionId }).data
  }

  if (routeId === '/create/s/$') {
    return YDocMetadata.safeParse({ scope: 'self-paced-content', documentId: _splat?.split('/')[0] }).data
  }

  if (routeId === '/s/$flexibleContentId/' || routeId === '/s/$flexibleContentId/$fileId') {
    return YDocMetadata.safeParse({ scope: 'self-paced-content', documentId: flexibleContentId }).data
  }
}

export function openYDocDebug({ scope, documentId }: YDocMetadata): void {
  const url = `/debug/collaboration?documentId=${scope}:${documentId}`
  window.open(url, '_blank')
}

export function yDocHistoryUrl(scopedDocumentId: ScopedCreateContentId, fileId?: FileId): string {
  const shortcut = ScopedCreateContentId.urlSubPathForId(scopedDocumentId)
  const contentId = ScopedCreateContentId.extractId(scopedDocumentId)

  return `/history/${shortcut}/${contentId}${isDefined(fileId) ? `/${fileId}` : ''}`
}

export function openYDocHistory(scopedDocumentId: ScopedCreateContentId): void {
  const url = yDocHistoryUrl(scopedDocumentId)
  window.open(url, '_blank')
}

export function openYDocMetaHistory(meta: YDocMetadata): void {
  const scopedId =
    meta.scope === 'self-paced-content'
      ? ScopedSelfPacedContentId.fromId(SelfPacedContentId.parse(meta.documentId))
      : meta.scope === 'live-content'
        ? ScopedLiveContentId.fromId(LiveContentId.parse(meta.documentId))
        : undefined

  assertIsNonNullable(scopedId, 'Unable to open history for this content type')

  return openYDocHistory(scopedId)
}

function openYDocChatDebug({ scope, documentId }: YDocMetadata): void {
  const url = `/debug/collaboration?documentId=chat:${scope}:${documentId}`
  window.open(url, '_blank')
}

export const DebugYDocShortcutActions: React.FC = () => {
  const developerToolsEnabled = useDeveloperToolsEnabled()
  const meta = useYDocMetadata()
  if (!developerToolsEnabled || !meta) return null

  const { scope } = meta
  return (
    <ShortcutMenu.Group label='developer-tools'>
      <ShortcutMenu.Action
        label={{ type: 'untranslated', value: `Debug ${scope} yDoc` }}
        run={() => openYDocDebug(meta)}
        iconId='arrow--right'
        permission='ACCESS_LMS'
      />

      <ShortcutMenu.Action
        label={{ type: 'untranslated', value: `Debug ${scope} yDoc (chat)` }}
        run={() => openYDocChatDebug(meta)}
        iconId='arrow--right'
        permission='ACCESS_LMS'
      />

      {meta.scope === 'live-content' ||
        (meta.scope === 'self-paced-content' && (
          <ShortcutMenu.Action
            label={{ type: 'untranslated', value: `Debug ${scope} YDoc (history)` }}
            run={() => openYDocMetaHistory(meta)}
            iconId='arrow--right'
            permission='ACCESS_LMS'
          />
        ))}
    </ShortcutMenu.Group>
  )
}
