import React, { useContext } from 'react'
import { FolderId } from 'sierra-domain/flexible-content/identifiers'

export type CardLocation = { folderId: FolderId } | 'bottom' | undefined

export type CardPopper = {
  createCardLocation: CardLocation
  setCreateCardLocation: (createCardLocation: CardLocation) => void
  cardPopperOpen: boolean
  setCardPopperOpen: (cardPopperOpen: boolean) => void
  ref: React.RefObject<HTMLDivElement> | null
}

export const CardPopperContext = React.createContext<CardPopper | undefined>(undefined)

export const useCardPopperContext = (): CardPopper => {
  const context = useContext(CardPopperContext)
  if (context === undefined) throw Error('Component not wrapped in CardPopperContext')
  return context
}

export const CardPopperContextProvider = CardPopperContext.Provider
