import { useEffect, useRef, useState } from 'react'
import { useTemporaryFileUpload } from 'sierra-client/hooks/use-temporary-file-upload'
import { useTranslation } from 'sierra-client/hooks/use-translation'
import { isNonNullable } from 'sierra-domain/utils'
import { Icon } from 'sierra-ui/components'
import { IconButton, Text, View } from 'sierra-ui/primitives'
import { token } from 'sierra-ui/theming'
import styled from 'styled-components'

const MAX_IMAGE_SIZE_MB = 20

const HiddenInput = styled.input`
  display: none;
`

const FilePillContainer = styled(View).attrs({
  alignItems: 'center',
  background: 'surface/soft',
  radius: 'size-6',
})`
  padding-left: 4px;
`

const LinkLabel = styled(Text).attrs({
  as: 'a',
})`
  cursor: pointer;
  transition: color 120ms cubic-bezier(0.25, 0.1, 0.25, 1);
  color: ${token('foreground/secondary')};

  &:hover {
    color: ${token('foreground/primary')};
  }
`

export const FilePill: React.FC<{ onRemove: () => void; name: string }> = ({ onRemove, name }) => (
  <FilePillContainer>
    <Icon color='foreground/muted' iconId='document--blank' size='size-16' />
    <Text size='micro' bold>
      {name}
    </Text>
    <IconButton variant='transparent' iconId='close' size='small' onClick={onRemove} type='button' />
  </FilePillContainer>
)

// TODO: Reuse this in issue-certificate panel
const FileInput: React.FC<{ value?: File; onChange: (file?: File) => void }> = ({ value, onChange }) => {
  const { t } = useTranslation()

  const fileInput = useRef<HTMLInputElement>(null)
  const handleClickUpload = (): void => {
    if (fileInput.current === null) return
    fileInput.current.click()
  }

  // Reset current fileinput if value is empty
  useEffect(() => {
    if (value === undefined && fileInput.current) {
      fileInput.current.value = ''
    }
  }, [value])

  return (
    <View>
      <HiddenInput
        type='file'
        accept='.jpg,.jpeg,.png,.pdf'
        ref={fileInput}
        onChange={e => {
          const file = e.target.files?.[0]
          if (isNonNullable(file)) {
            onChange(file)
          }
        }}
      />
      {value ? (
        <FilePill
          onRemove={() => {
            onChange(undefined)
            // Reset the fileinput
            if (fileInput.current) {
              fileInput.current.value = ''
            }
          }}
          name={value.name}
        />
      ) : (
        <LinkLabel size='micro' color='foreground/muted' onClick={handleClickUpload}>
          {t('manage.certificates.attach-file')}
        </LinkLabel>
      )}
    </View>
  )
}

export type UploadedFile = { id: string; file: File }
export const FileInputWithUpload: React.FC<{
  value?: UploadedFile
  onChange: (file?: UploadedFile) => void
}> = ({ value, onChange }) => {
  const { uploadTemporaryFile } = useTemporaryFileUpload(MAX_IMAGE_SIZE_MB)

  const [assetContext] = useState({ type: 'certificate' as const })

  return (
    <FileInput
      value={value?.file}
      onChange={async file => {
        if (file) {
          const { fileId } = await uploadTemporaryFile(file, assetContext)
          onChange({ id: fileId, file: file })
        } else {
          onChange(undefined)
        }
      }}
    />
  )
}
