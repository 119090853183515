import { useState } from 'react'
import {
  useDeleteTeamspaceMutation,
  useUpdateTeamspaceMetadataMutation,
} from 'sierra-client/api/hooks/use-teamspace'
import { ActionModal } from 'sierra-client/components/common/modals/action-modal'
import { TeamspaceIconInput } from 'sierra-client/features/teamspace/components/teamspace-icon'
import { teamspaceDeletedLogger, teamspaceEditedLogger } from 'sierra-client/features/teamspace/logger'
import { useHasTeamspacePermission } from 'sierra-client/hooks/use-permissions'
import { useTranslation } from 'sierra-client/hooks/use-translation'
import { getGlobalRouter } from 'sierra-client/router'
import { useDispatch } from 'sierra-client/state/hooks'
import { NanoId12 } from 'sierra-domain/api/nano-id'
import { Teamspace } from 'sierra-domain/api/teamspace'
import { FormElement } from 'sierra-ui/components'
import { Button, InputPrimitive, Text, TextAreaPrimitive, View } from 'sierra-ui/primitives'
import { spacing, token } from 'sierra-ui/theming'
import styled from 'styled-components'

const DeleteActionContainer = styled(View)`
  border-radius: 8px;
  background-color: ${token('foreground/primary').opacity(0.05)};
  padding: ${spacing['16']} ${spacing['24']};
`
const DeleteBodyText = styled(Text)`
  max-width: 40ch;
`

const DeleteButton = styled(Button)`
  margin-top: 8px;
  max-width: fit-content;
`

const DeleteAction: React.FC<{ teamspaceId: NanoId12 }> = ({ teamspaceId }) => {
  const { t } = useTranslation()
  const dispatch = useDispatch()
  const [deleteModalOpen, setDeleteModalOpen] = useState(false)

  const deleteTeamspaceMutation = useDeleteTeamspaceMutation()

  return (
    <>
      <DeleteActionContainer direction='column'>
        <Text bold>{t('teamspace.delete-title')}</Text>
        <DeleteBodyText color='foreground/muted'>{t('teamspace.delete-body-text')}</DeleteBodyText>
        <DeleteButton
          variant='destructive'
          icon='trash-can'
          type='button'
          onClick={() => setDeleteModalOpen(true)}
        >
          {t('dictionary.delete')}
        </DeleteButton>
      </DeleteActionContainer>
      <ActionModal
        open={deleteModalOpen}
        deleteAction
        title={t('teamspace.delete-title')}
        onClose={() => {
          setDeleteModalOpen(false)
        }}
        primaryAction={async () => {
          await getGlobalRouter().navigate({ to: '/' })
          deleteTeamspaceMutation.mutate(
            { id: teamspaceId },
            {
              onSuccess: () => {
                return Promise.all([
                  dispatch(
                    teamspaceDeletedLogger({
                      teamspaceId,
                    })
                  ),
                ])
              },
            }
          )
        }}
        primaryActionLabel={t('dictionary.delete')}
      >
        {t('teamspace.delete-body-text')}
      </ActionModal>
    </>
  )
}

const EditTeamspaceGeneralTabForm = styled.form`
  flex: 1;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  min-height: 100%;
  padding-top: 24px;
`

export const EditTeamspaceGeneralTab: React.FC<{
  teamspace: Teamspace
  onClose: () => void
}> = ({ teamspace, onClose }) => {
  const { t } = useTranslation()
  const dispatch = useDispatch()

  const [teamspaceName, setTeamspaceName] = useState(teamspace.displayName)
  const [teamspaceNameError, setTeamspaceNameError] = useState<string | undefined>(undefined)
  const [teamspaceDescription, setTeamspaceDescription] = useState(teamspace.description ?? '')
  const [teamspaceIconTheme, setTeamspaceIconTheme] = useState(teamspace.iconTheme)

  const canDeleteTeamspace = useHasTeamspacePermission(teamspace.id, 'DELETE')

  const isSubmitButtonDisabled =
    teamspaceName === teamspace.displayName &&
    teamspaceDescription === teamspace.description &&
    teamspaceIconTheme === teamspace.iconTheme

  const updateTeamspaceMutation = useUpdateTeamspaceMetadataMutation()

  const onFormSubmit = (): void => {
    if (teamspaceName.trim() === '') {
      setTeamspaceNameError('Cannot be empty')
      return
    }

    updateTeamspaceMutation.mutate(
      {
        teamspaceId: teamspace.id,
        displayName: teamspaceName.trim(),
        description: teamspaceDescription.trim(),
        iconTheme: teamspaceIconTheme,
      },
      {
        onSuccess: () => {
          onClose()
          void dispatch(
            teamspaceEditedLogger({
              teamspaceId: teamspace.id,
              changedName: teamspaceName !== teamspace.displayName,
              changedDescription: teamspaceDescription !== teamspace.description,
            })
          )
        },
      }
    )
  }

  return (
    <EditTeamspaceGeneralTabForm
      onSubmit={event => {
        event.preventDefault()
        onFormSubmit()
      }}
    >
      <View direction='column' justifyContent='space-between' gap='24' grow>
        <View direction='column' gap='24'>
          <View justifyContent='space-between' alignItems='stretch' gap='24'>
            {/* Revisit once we support error state in the <FormElement /> */}
            <FormElement label={t('dictionary.name')} helper={teamspaceNameError}>
              <InputPrimitive
                value={teamspaceName}
                onChange={event => {
                  setTeamspaceName(event.target.value)
                  setTeamspaceNameError(undefined)
                }}
                placeholder={t('teamspaces.add-name-placeholder')}
                required
              />
            </FormElement>

            <FormElement label={t('dictionary.icon')} grow={false}>
              <TeamspaceIconInput
                displayName={teamspaceName}
                selectedThemeName={teamspaceIconTheme}
                onThemeChange={theme => {
                  setTeamspaceIconTheme(theme)
                }}
              />
            </FormElement>
          </View>

          <FormElement
            helper={teamspaceNameError}
            label={
              <View justifyContent='space-between'>
                {t('dictionary.description')}
                <Text color='grey25'>{t('input.optional')}</Text>
              </View>
            }
          >
            <TextAreaPrimitive
              value={teamspaceDescription}
              onChange={event => {
                setTeamspaceDescription(event.target.value)
              }}
              placeholder={t('teamspaces.add-description-placeholder')}
              rows={4}
              autoExpand
            />
          </FormElement>
        </View>
        {canDeleteTeamspace && <DeleteAction teamspaceId={teamspace.id} />}
      </View>
      <View justifyContent='flex-end' paddingTop='24'>
        <Button type='button' variant='secondary' onClick={onClose}>
          {t('modal.cancel')}
        </Button>
        <Button type='submit' disabled={isSubmitButtonDisabled}>
          {t('modal.save')}
        </Button>
      </View>
    </EditTeamspaceGeneralTabForm>
  )
}
