import { FC, ReactElement, useEffect, useRef, useState } from 'react'
import { FilterInput } from 'sierra-client/views/manage/components/filter-input'
import { Icon } from 'sierra-ui/components'
import { View } from 'sierra-ui/primitives'
import styled from 'styled-components'

const Wrapper = styled(View).attrs({
  radius: 'size-6',
  gap: 'none',
})<{ className?: string }>`
  height: 36px;

  &:hover input {
    border-color: ${p => p.theme.color['grey25']};
  }
`

const AnimatedSearchBar = styled(Wrapper).attrs({
  animated: true,
})`
  overflow: hidden;
  border: none;
  position: relative;

  &:focus-within {
    width: 300px;
  }
`

const SearchToggleButton = styled.button<{ disabled: boolean }>`
  display: flex;

  position: absolute;
  cursor: ${p => (p.disabled === true ? 'default' : 'pointer')};

  border: none;
  background: none;
  z-index: 2;
  border-radius: ${p => p.theme.borderRadius['size-10']};

  padding: 10px;
`
type InputWidths = {
  width: string
  transition: {
    duration: number
    ease: number[]
  }
}

const getInputWidths = (width: string): { expanded: InputWidths; minimized: InputWidths } => {
  return {
    expanded: {
      width, // 300px is a legacy thing from design
      transition: {
        duration: 0.2,
        ease: [0.86, 0.0, 0.07, 1.0],
      },
    },
    minimized: {
      width: '36px',
      transition: {
        duration: 0.2,
        ease: [0.86, 0.0, 0.07, 1.0],
      },
    },
  }
}

export type AnimatedSearchBarProps = {
  value?: string
  onChange: (value: string) => void
  placeholder: string
  initialOpen?: boolean
  expandedWidth?: string
  className?: string
  autoFocus?: boolean
  disableClose?: boolean
}

export const AnimatedSearch: FC<AnimatedSearchBarProps> = ({
  value,
  onChange,
  placeholder,
  initialOpen,
  className,
  autoFocus = false,
  expandedWidth = '300px',
  disableClose = false,
}): ReactElement => {
  const [isOpen, toggleOpen] = useState(initialOpen ?? false)
  const inputRef = useRef<HTMLInputElement>(null)
  const inputIsEmpty = value === undefined || value === '' // We can't close search if it's filled in
  const inputWidths = getInputWidths(expandedWidth)

  useEffect(() => {
    if (isOpen && inputRef.current && initialOpen !== true) {
      inputRef.current.focus()
    }

    if (!isOpen && inputRef.current) {
      inputRef.current.blur()
    }
  }, [isOpen, initialOpen])

  useEffect(() => {
    if (initialOpen === undefined) {
      return
    }

    toggleOpen(initialOpen)
  }, [initialOpen])

  return (
    <Wrapper className={className}>
      <SearchToggleButton
        onClick={() => {
          if (disableClose) {
            return
          }

          if (!inputIsEmpty) {
            return
          }

          toggleOpen(!isOpen)
        }}
        aria-label='Search'
        disabled={!inputIsEmpty || disableClose}
      >
        <Icon color='foreground/primary' iconId='search' />
      </SearchToggleButton>
      <AnimatedSearchBar variants={inputWidths} animate={isOpen ? 'expanded' : 'minimized'} initial={false}>
        <FilterInput
          ref={inputRef}
          value={value}
          onChange={onChange}
          placeholder={placeholder}
          autoFocus={autoFocus}
        />
      </AnimatedSearchBar>
    </Wrapper>
  )
}
