import { DomainRep, getDomainRep } from 'sierra-client/lib/filter'
import { Context } from 'sierra-client/lib/filter/components/common'
import { updateCtx } from 'sierra-client/lib/filter/components/filter'
import { Filter, FilterBase } from 'sierra-domain/filter/datatype/filter'
import { assertNever } from 'sierra-domain/utils'

/**
 * HOC to help with creating context aware filter components.
 * This makes sure that the update and remove function of ctx
 * If changes are needed for the `filter.and|or`, your own
 * ctxfiltercomponent should be created
 */
type Props = { ctx: Context; filter: Filter }
export const CtxFilterComponent: React.FC<
  Props & { render: React.FC<Props & { domainRep: DomainRep; filter: FilterBase }> }
> = ({ ctx, filter, render: View }) => {
  switch (filter.type) {
    case 'filter.filter': {
      const domainRep = getDomainRep(ctx.domainReps, filter.domain)
      return domainRep === undefined ? null : <View ctx={ctx} domainRep={domainRep} filter={filter} />
    }
    case 'filter.and':
    case 'filter.or':
      return (
        <>
          {filter.filters.map((filter, i) => {
            const newCtx = updateCtx(ctx, filter, i)
            return <CtxFilterComponent ctx={newCtx} filter={filter} key={i} render={View} />
          })}
        </>
      )
    default:
      assertNever(filter)
  }
}
