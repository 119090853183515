import { useAtomValue } from 'jotai'
import { Trans as TransExternal, TransProps as TransPropsExternal } from 'react-i18next' // eslint-disable-line no-restricted-imports
import { TranslationKey } from 'sierra-client/hooks/use-translation/types'
import { showTranslationStringsAtom } from 'sierra-client/state/settings'

export function Trans(props: TransPropsExternal<TranslationKey>): JSX.Element {
  const showTranslationStrings = useAtomValue(showTranslationStringsAtom)

  if (showTranslationStrings) return <>{props.i18nKey}</>
  else return <TransExternal {...props} />
}
