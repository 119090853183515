import { CursorEditor, withCursors, YjsEditor } from '@slate-yjs/core'
import { Editor } from 'slate'
import { Awareness } from 'y-protocols/awareness'

type AwarenessState = [clientId: number, data: { [x: string]: unknown }]

/**
 * The `withCursor` plugin in slate-yjs assumes that there is only one slate shared type
 * at a time in the document, but we need to support multiple shared types in our `Y.Doc`s.
 * By overriding the awareness protocol, we can ensure that only data from the relevant
 * slate shared type is exposed to slate-yjs.
 */
const withScopedAwareness = (awareness: Awareness, filter: FilterAwarenessStates): Awareness => {
  const getStates = awareness.getStates.bind(awareness)

  const scopedGetStates: typeof getStates = () => {
    const allStates = Array.from(getStates().entries())
    const relevantStates = allStates.filter(filter)
    return new Map(relevantStates)
  }

  const scopedAwareness: Awareness = {
    ...awareness,
    getStates: scopedGetStates,

    // Rest of the awareness is just taken from the original
    on: awareness.on.bind(awareness),
    off: awareness.off.bind(awareness),
    once: awareness.once.bind(awareness),
    emit: awareness.emit.bind(awareness),
    destroy: awareness.destroy.bind(awareness),
    getLocalState: awareness.getLocalState.bind(awareness),
    setLocalState: awareness.setLocalState.bind(awareness),
    setLocalStateField: awareness.setLocalStateField.bind(awareness),
  }

  return scopedAwareness
}

/**
 * Filter out relevant awareness states. For example, you may
 * only wish to receive awareness updates from the currently opened document.
 */
type FilterAwarenessStates = (_: AwarenessState) => boolean

export const withSanaCursors = <T extends Editor>(
  editor: T & YjsEditor,
  {
    awareness,
    filterAwarenessStates,
  }: {
    awareness: Awareness
    filterAwarenessStates: FilterAwarenessStates
  },
  data: Record<string, unknown>
): typeof editor & CursorEditor => {
  const scopedAwareness = withScopedAwareness(awareness, filterAwarenessStates)
  return withCursors(editor, scopedAwareness, data)
}
