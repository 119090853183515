//TODO(ludvig): Add locale, timezone to Context

export type Identity = { userId: string } | { userId?: string; anonymousId: string }

export type Integrations = {
  Amplitude: {
    session_id?: number
  }
}

export type UserAgentContext = {
  userAgent: string
  os: {
    name?: string
    version?: string
  }
  browser: {
    name?: string
    version?: string
  }
  device: {
    type?: string
  }
}

type PageContext = {
  page: {
    path: string
    referrer: string
    search: string
    title: string
    url: string
    category?: string
    id?: string
    props?: PageInstanceProps
  }
}

export type Context = UserAgentContext & PageContext

export interface TrackMessage<T extends Record<string, unknown>> {
  event: string
  context: Context
  integrations: Integrations
  properties: T
}

export interface PageMessage<P extends PageInstanceProps> {
  category: Category
  name: string
  context: Context
  integrations: Integrations
  properties: P
}

export enum Category {
  learner = 'learner',
  analytics = 'analytics',
  manage = 'manage',
  authoring = 'authoring',
  authentication = 'authentication',
  other = 'other',
}

export type PageInstanceProps = Record<string, string | never>

export type PageState = {
  category: Category
  id: string
  props: PageInstanceProps
}

export type LoggingState = {
  logs: (
    | { type: 'event'; message: TrackMessage<Record<string, unknown>> }
    | { type: 'page'; message: PageMessage<PageInstanceProps> }
  )[]
  page?: PageState
  enabled: boolean
}
