import { useSearch } from '@tanstack/react-router'
import { useAtomValue } from 'jotai'
import { useState } from 'react'
import { CustomInterfaceThemeProvider } from 'sierra-client/components/common/custom-interface-theme-provider'
import { RouterLink } from 'sierra-client/components/common/link'
import { config } from 'sierra-client/config/global-config'
import { Auth } from 'sierra-client/core/auth'
import { segmentService } from 'sierra-client/core/segment'
import { useTranslation } from 'sierra-client/hooks/use-translation'
import { DropdownMenu } from 'sierra-client/layout/header/components/dropdown-menu'
import { useNavLinks } from 'sierra-client/layout/header/components/nav-links'
import { responsiveHorizontalCSS } from 'sierra-client/layout/styles/content'
import { getGlobalRouter } from 'sierra-client/router'
import { useIsTablet } from 'sierra-client/state/browser/selectors'
import {
  useCloseUserMenu,
  useToggleUserMenu,
  useUserMenuIsOpen,
  userMenuStateAtom,
} from 'sierra-client/state/header'
import { useSelector } from 'sierra-client/state/hooks'
import { selectIsRestrictedUser } from 'sierra-client/state/user/user-selector'
import { UserSettings } from 'sierra-client/views/user-settings/user-settings'
import { UserLoggedOutRequestParams } from 'sierra-domain/segment/event'
import { Modal } from 'sierra-ui/components'
import { Heading } from 'sierra-ui/primitives'
import { LightTokenProvider, palette } from 'sierra-ui/theming'
import { v2_breakpoint } from 'sierra-ui/theming/breakpoints'
import { fonts } from 'sierra-ui/theming/fonts'
import { legacyLight } from 'sierra-ui/theming/legacy-theme'
import styled, { ThemeProvider, css } from 'styled-components'

type UserMenuHook = {
  isMenuOpen: boolean
  closeMenu: () => void
  toggleMenu: () => void
  userMenuElement: JSX.Element
  setUserDialogOpen: (open: boolean) => void
}

const StyledDropdownMenu = styled(({ className, ...props }) => (
  <DropdownMenu {...props} innerClassName={className} />
))`
  && {
    padding-top: 0;
    ${responsiveHorizontalCSS}
  }
`

const ItemWrapper = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  border-top: 1px solid ${palette.grey[5]};

  @media (min-width: ${v2_breakpoint.phone}) {
    align-items: flex-end;
  }
`

const Item = styled.a<{ active?: boolean }>`
  display: block;
  ${fonts.heading.h4};
  color: ${p => p.theme.color.grey35};
  margin-bottom: 1.5rem;
  transition: color 0.2s ease-out;

  cursor: pointer;

  &:hover {
    color: ${palette.primitives.black};
  }

  &:first-of-type {
    margin-top: 2rem;

    @media (min-width: ${v2_breakpoint.phone}) {
      margin-top: 3rem;
    }
  }

  ${p =>
    (p.active ?? false) &&
    css`
      color: ${palette.primitives.black};
    `}
`

export const useUserMenu = (): UserMenuHook => {
  const { t } = useTranslation()
  const settingsTab = useSearch({ strict: false, select: s => s.settingsTab })
  const menuState = useAtomValue(userMenuStateAtom)
  const isMenuOpen = useUserMenuIsOpen()
  const isTablet = useIsTablet()
  const isRestrictedUser = useSelector(selectIsRestrictedUser)
  const { links } = useNavLinks()

  const [userDialogOpen, setUserDialogOpen] = useState(settingsTab !== undefined)

  const toggleMenu = useToggleUserMenu()
  const closeMenu = useCloseUserMenu()

  const logOut = (): void => {
    closeMenu()
    void segmentService.track(new UserLoggedOutRequestParams())
    void Auth.getInstance().signOut()
    void getGlobalRouter().navigate({ to: '/' })
  }

  const isMenuDisabled = config.scorm.isScorm || isRestrictedUser

  const userMenuElement: JSX.Element = isMenuDisabled ? (
    <></>
  ) : (
    <LightTokenProvider>
      <StyledDropdownMenu containerSize={'none'} animate={menuState.current}>
        <ItemWrapper>
          {isTablet &&
            links.map(({ id, href, translationKey }) => (
              <RouterLink key={id} href={href}>
                <Item onClick={closeMenu} id={id}>
                  {t(translationKey)}
                </Item>
              </RouterLink>
            ))}
          <Item
            onClick={() => {
              closeMenu()
              setUserDialogOpen(true)
            }}
            id='settings'
          >
            {t('topbar.settings')}
          </Item>
          <Item id='logout' onClick={logOut}>
            {t('topbar.log-out')}
          </Item>
        </ItemWrapper>
      </StyledDropdownMenu>
      <ThemeProvider theme={legacyLight}>
        <CustomInterfaceThemeProvider>
          <Modal
            open={userDialogOpen}
            onClose={() => setUserDialogOpen(false)}
            padding='32'
            size={{ width: 653, height: 723 }}
          >
            <Heading size='h4' bold>
              {t('settings.my-settings')}
            </Heading>
            <UserSettings initialTab={settingsTab} onAfterSave={() => setUserDialogOpen(false)} />
          </Modal>
        </CustomInterfaceThemeProvider>
      </ThemeProvider>
    </LightTokenProvider>
  )

  return {
    isMenuOpen,
    closeMenu,
    toggleMenu,
    userMenuElement,
    setUserDialogOpen,
  }
}
