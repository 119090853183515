import { default as React } from 'react'
import { HeaderButtonGroupWrapper } from 'sierra-client/components/liveV2/header-buttons'
import { useSlateDocument } from 'sierra-client/state/flexible-content/use-slate-document'
import { useCreatePageYDocContext } from 'sierra-client/views/flexible-content/create-page-context'
import { ImageLayoutPicker } from 'sierra-client/views/flexible-content/editor/toolbar/layout-picker'
import { FileThemePicker } from 'sierra-client/views/flexible-content/file-theme-picker'
import { WaitForEditor } from 'sierra-client/views/flexible-content/polaris-editor-provider/use-polaris-editor'
import { Debug } from 'sierra-client/views/learner/components/debug'
import { updateNodeWithId } from 'sierra-client/views/v3-author/command'
import { isElementType } from 'sierra-client/views/v3-author/queries'
import { Entity } from 'sierra-domain/entity'
import { File } from 'sierra-domain/flexible-content/types'
import { BulletCard } from 'sierra-domain/v3-author'
import { CustomThemeName, PresetThemeName } from 'sierra-ui/theming/legacy-theme'
import { Editor } from 'slate'

function useBulletCard(fileId: File['id']): Omit<Entity<BulletCard>, 'children'> | undefined {
  const { yDoc } = useCreatePageYDocContext()
  const document = useSlateDocument(yDoc, fileId)
  const bulletCard = document.find(isElementType('bullet-card'))

  if (bulletCard === undefined) return undefined

  const { children, ...rest } = bulletCard
  return rest
}

type BulletCardToolbarProps = {
  file: File
  previewThemes?: (theme: undefined | CustomThemeName | PresetThemeName) => void
}

const BulletCardToolbarInner: React.FC<BulletCardToolbarProps & { editor: Editor }> = ({
  file,
  previewThemes,
  editor,
}) => {
  const bulletCard = useBulletCard(file.id)
  if (bulletCard === undefined) return <Debug>[debug] No bullet card found</Debug>

  const updateAlignment = (variant: BulletCard['variant']): void => {
    updateNodeWithId<Entity<BulletCard>>(editor, bulletCard.id, { variant })
  }

  const currentLayout = bulletCard.variant ?? ('trailing-image' as const)

  return (
    <HeaderButtonGroupWrapper gap='none'>
      <FileThemePicker previewThemes={previewThemes} file={file} />
      <ImageLayoutPicker onLayoutChange={updateAlignment} currentLayout={currentLayout} />
    </HeaderButtonGroupWrapper>
  )
}

export const BulletCardToolbar: React.FC<BulletCardToolbarProps> = props => (
  <WaitForEditor>{editor => <BulletCardToolbarInner editor={editor} {...props} />}</WaitForEditor>
)
