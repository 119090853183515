import { useSetAtom } from 'jotai'
import { useEffect } from 'react'
import { recentlyVisitedAtom } from 'sierra-client/editor/utils/recently-visited/atom'
import { RecentlyVisitedEntry } from 'sierra-client/editor/utils/recently-visited/types'
import { useDeepEqualityMemo } from 'sierra-client/hooks/use-deep-equality-memo'

export const TrackVisitedContent: React.FC<Omit<RecentlyVisitedEntry, 'date'>> = _entry => {
  const setAtom = useSetAtom(recentlyVisitedAtom)
  const entry = useDeepEqualityMemo(_entry)

  useEffect(() => {
    const newEntry: RecentlyVisitedEntry = RecentlyVisitedEntry.parse({
      ...entry,
      date: new Date(Date.now()),
    })

    setAtom(oldEntries => [...oldEntries, newEntry])
  }, [entry, setAtom])

  return null
}
