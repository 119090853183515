import { AnimatePresence, motion } from 'framer-motion'
import { DateTime } from 'luxon'
import { FC, useEffect, useRef, useState } from 'react'
import { IssuedCertificatesForUserQuery } from 'sierra-client/api/graphql/gql/graphql'
import { Thumbnail } from 'sierra-client/components/common/thumbnail'
import { useIsScrolledToLeftOrRight } from 'sierra-client/hooks/use-is-scrolled-left-or-right'
import { useTranslation } from 'sierra-client/hooks/use-translation'
import { useIsMobile, useIsSmallDesktop } from 'sierra-client/state/browser/selectors'
import { CertificatePreviewUrlModal } from 'sierra-client/views/manage/certificates/certificate-preview-url-modal'
import { useTracking } from 'sierra-client/views/workspace/utils/certificates/tracking'
import { iife, isDefined, isNotNull } from 'sierra-domain/utils'
import { dynamicColor } from 'sierra-ui/color'
import { Icon } from 'sierra-ui/components'
import { Text, View } from 'sierra-ui/primitives'
import { v2_breakpoint } from 'sierra-ui/theming/breakpoints'
import styled from 'styled-components'

type IssuedCertificateProps = {
  issuedCertificate: Exclude<
    IssuedCertificatesForUserQuery['user'],
    null | undefined
  >['issuedCertificates'][number]
  onClick: () => void
}

const Background = styled(View).attrs({
  direction: 'column',
  alignItems: 'center',
  justifyContent: 'center',
  padding: '8 24',
})<{ bgColor: string }>`
  background: ${p => dynamicColor(p.bgColor).opacity(0.5)};
  border-radius: 10px;
`

const IssuedCertificate: FC<IssuedCertificateProps> = ({ issuedCertificate, onClick }) => {
  const { t } = useTranslation()

  const expiredDate =
    issuedCertificate.expiresAt !== null && issuedCertificate.expiresAt !== undefined
      ? DateTime.fromISO(issuedCertificate.expiresAt).toISODate()
      : null
  const expiresAtText =
    expiredDate !== null
      ? t(`manage.ceritficates.valid-through`, { expiredDate })
      : t(`manage.certificates.valid-until-revoked`)
  return (
    <View direction='column' onClick={onClick} cursor='pointer' padding='none'>
      <Background bgColor={issuedCertificate.certificate.templateData.backgroundColor ?? '#eaeaea'}>
        <Thumbnail image={issuedCertificate.snapshotImageUrl} height={9} width={12.625} radius={0.25} />
      </Background>
      <View direction='column' gap='none'>
        <Text bold>{issuedCertificate.certificate.title}</Text>
        <Text color='foreground/muted'>{expiresAtText}</Text>
      </View>
    </View>
  )
}

const ScrollContainer = styled.div`
  width: 100%;
  display: flex;
  gap: 8px;

  padding: 16px 0;

  overflow-x: scroll;
  scroll-snap-type: x mandatory;
  scroll-behavior: smooth;
  scrollbar-width: none;
  overscroll-behavior-x: contain;
  -ms-overflow-style: none;

  &::-webkit-scrollbar {
    width: 0;
    height: 0;
  }

  @media screen and (min-width: ${v2_breakpoint.phone}) {
    gap: 1rem;
    padding-right: 1.5rem;
    scroll-padding-left: 1.5rem;
  }

  @media screen and (min-width: ${v2_breakpoint.desktop_small}) {
    gap: 1.5rem;
    padding-right: 4rem;
    scroll-padding-left: 4rem;
  }
`

const NavigationButton = styled.button`
  color: black;
  background: rgba(255, 255, 255, 0.1);
  backdrop-filter: blur(16px);
  border-radius: 10px;

  display: flex;
  align-items: center;
  justify-content: center;
  height: 40px;
  width: 40px;

  &:hover {
    cursor: pointer;
    background: rgba(255, 255, 255, 0.3);
  }
`

const NextButtonContainer = styled(motion.div).attrs({
  initial: { opacity: 0 },
  animate: { opacity: 1 },
  exit: { opacity: 0 },
})`
  position: absolute;
  top: 45%;
  transform: translateY(-50%);
  right: 16px;

  @media screen and (min-width: ${v2_breakpoint.phone}) {
    right: 24px;
  }
`

const PrevButtonContainer = styled(motion.div).attrs({
  initial: { opacity: 0 },
  animate: { opacity: 1 },
  exit: { opacity: 0 },
})`
  position: absolute;
  top: 45%;
  transform: translateY(-50%);
  left: 16px;

  @media screen and (min-width: ${v2_breakpoint.phone}) {
    left: 24px;
  }
`

export const UserIssuedCertificatesCarousel: FC<{
  issuedCertificates: Exclude<IssuedCertificatesForUserQuery['user'], null | undefined>['issuedCertificates']
}> = ({ issuedCertificates }) => {
  const scrollContainerRef = useRef<HTMLDivElement>(null)
  const [recommendationWidth, setScollContainerWidth] = useState(400)
  const track = useTracking()

  const { t } = useTranslation()

  const [previewUrls, setPreviewUrls] = useState<
    { previewUrl: string; downloadUrl?: string; supportingFileUrl?: string | null; id: string } | undefined
  >(undefined)
  const isMobile = useIsMobile()
  const isSmallDesktop = useIsSmallDesktop()
  const scrollDistance = iife(() => {
    if (!isSmallDesktop) return recommendationWidth + 24
    if (!isMobile) return recommendationWidth + 16

    return recommendationWidth
  })
  const { isScrolledToLeft, isScrolledToRight, updateScroll } = useIsScrolledToLeftOrRight(scrollContainerRef)

  useEffect(() => {
    if (isNotNull(scrollContainerRef.current)) {
      const firstChild = scrollContainerRef.current.children[0]
      if (isDefined(firstChild)) {
        setScollContainerWidth(firstChild.clientWidth)
      }
    }
  }, [])

  const scrollToPrev = (): void => {
    if (isNotNull(scrollContainerRef.current)) {
      scrollContainerRef.current.scrollBy({ left: -scrollDistance, behavior: 'smooth' })
    }
  }
  const scrollToNext = (): void => {
    if (isNotNull(scrollContainerRef.current)) {
      scrollContainerRef.current.scrollBy({ left: scrollDistance, behavior: 'smooth' })
    }
  }

  return issuedCertificates.length > 0 ? (
    <>
      <Text bold>{t('dictionary.certificate-plural')}</Text>
      <ScrollContainer ref={scrollContainerRef} onScroll={updateScroll}>
        {issuedCertificates.map(c => (
          <IssuedCertificate
            key={c.id}
            issuedCertificate={c}
            onClick={() => {
              setPreviewUrls({
                previewUrl: c.snapshotImageUrl,
                downloadUrl: c.pdfUrl,
                supportingFileUrl: c.supportingFileUrl,
                id: c.certificate.id,
              })
            }}
          />
        ))}
        <CertificatePreviewUrlModal
          url={previewUrls?.previewUrl}
          open={previewUrls?.previewUrl !== undefined}
          downloadUrl={previewUrls?.downloadUrl}
          supportingFileUrl={previewUrls?.supportingFileUrl}
          onDownload={() => {
            if (isDefined(previewUrls)) {
              track.download.click(previewUrls.id)
            }
          }}
          onShare={() => {
            if (isDefined(previewUrls)) {
              track.linkedin.share(previewUrls.id)
            }
          }}
          onClose={() => setPreviewUrls(undefined)}
        />
      </ScrollContainer>
      <AnimatePresence>
        {!isMobile && !isScrolledToLeft && (
          <PrevButtonContainer>
            <NavigationButton onClick={scrollToPrev}>
              <Icon color='currentColor' iconId={'arrow--left'} />
            </NavigationButton>
          </PrevButtonContainer>
        )}
      </AnimatePresence>
      <AnimatePresence>
        {!isMobile && !isScrolledToRight && (
          <NextButtonContainer>
            <NavigationButton onClick={scrollToNext}>
              <Icon color='currentColor' iconId={'arrow--right'} />
            </NavigationButton>
          </NextButtonContainer>
        )}
      </AnimatePresence>
    </>
  ) : null
}
