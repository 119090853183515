import { palette, spacing } from 'sierra-ui/theming'
import styled, { createGlobalStyle } from 'styled-components'

export const TabRootContainer = styled.div`
  display: flex;
  flex-direction: column;
  min-height: 450px;
  overflow-y: auto;
  overflow-x: hidden;
  padding: ${spacing['24']} ${spacing['32']} ${spacing['16']};
`

export const BottomContainer = styled.div`
  flex: 1;
  display: flex;

  > * {
    flex: 1;
  }
`

const AutocompleteContainerOuter = styled.div`
  position: relative;
  flex: 1;
`

// Using global style to target dropdown portal to remove top and bottom padding
// and prevent overflow of scroll bar.
const AutoCompleteDropdownPaddingFix = createGlobalStyle`
  .MuiPaper-root {
    overflow: hidden;
  }

  .MuiAutocomplete-listbox {
    overflow-x: hidden;
    padding-top: 0;
    padding-bottom: 1px;
    margin-bottom: -1px;
  }

  .MuiAutocomplete-option[aria-selected="true"] {
    background-color: transparent;

    &:hover {
      background-color: ${palette.grey[5]};
    }
  }

  .MuiAutocomplete-option[data-focus="true"] {
    background-color: ${palette.grey[5]};
  }
`

export const AutocompleteContainer: React.FC<{ children: React.ReactNode }> = ({ children }) => (
  <AutocompleteContainerOuter>
    <AutoCompleteDropdownPaddingFix />
    {children}
  </AutocompleteContainerOuter>
)

export const AutocompleteDropdownContainer = styled.div`
  position: absolute;
  right: 0;
  top: 1px;
`

export const ExistingAssignedUsersContainer = styled.div`
  margin-top: 16px;
  margin-bottom: 16px;
  max-height: 330px;
  overflow-x: visible;
  overflow-y: hidden;

  padding-right: 24px;
  margin-right: -24px;

  scrollbar-gutter: stable;

  &:hover {
    overflow-y: auto;
  }
`
