import React from 'react'
import { SanaEditor } from 'sierra-domain/v3-author'
import { Awareness } from 'y-protocols/awareness'
import * as Y from 'yjs'

export type PolarisEditorProviderValue = {
  editor: { type: 'available'; value: SanaEditor } | { type: 'unavailable'; reason: string }
  yDoc: Y.Doc
  awareness: Awareness
}

export const PolarisEditorContext = React.createContext<PolarisEditorProviderValue | undefined>(undefined)
