import { partition } from 'lodash'
import React from 'react'
import { useTranslation } from 'sierra-client/hooks/use-translation'
import { TranslationKey } from 'sierra-client/hooks/use-translation/types'
import { CourseSelector } from 'sierra-client/views/v3-author/scenario/data-sources/courses'
import { useScenarioCardActions } from 'sierra-client/views/v3-author/scenario/hooks'
import { ScenarioFile } from 'sierra-client/views/v3-author/scenario/utils'
import { CourseId } from 'sierra-domain/api/nano-id'
import { ScenarioDataSource } from 'sierra-domain/flexible-content/types'
import {
  assertNever,
  ExtractFrom,
  isDefined,
  isNonEmptyArray,
  isNotDefined,
  STATIC_EMPTY_ARRAY,
} from 'sierra-domain/utils'
import { Icon, IconId } from 'sierra-ui/components'
import { Button, IconButton, Text, TextAreaPrimitive, View } from 'sierra-ui/primitives'
import { MenuDropdownPrimitive } from 'sierra-ui/primitives/menu-dropdown'
import { token } from 'sierra-ui/theming'
import { useOnChanged } from 'sierra-ui/utils'
import styled from 'styled-components'

const sourceType2Icon = (type: ScenarioDataSource['type']): IconId => {
  switch (type) {
    case 'company-info':
      return 'building'
    case 'frequently-asked-questions':
      return 'help'
    case 'facts':
      return 'verified'
    case 'text':
      return 'skill--password'
    case 'course':
      return 'course'
    default:
      assertNever(type)
  }
}

const Card = styled(View).attrs({ direction: 'column' })<{ collapsed: boolean }>`
  padding: 12px 16px;
  border-radius: 16px;
  border: 1px solid #00000014;
  box-shadow: 0px 2px 4px 0px ${token('border/default')};
  cursor: pointer;
`

type TextBasedSource = {
  id: string
  label: string
  value?: string
  expanded?: boolean
  type: ExtractFrom<
    ScenarioDataSource['type'],
    'company-info' | 'frequently-asked-questions' | 'text' | 'facts'
  >
}

type CourseBasedSource = {
  id: string
  label: string
  courseIds: Array<CourseId>
  expanded?: boolean
  type: ExtractFrom<ScenarioDataSource['type'], 'course'>
}

type Source = TextBasedSource | CourseBasedSource

const TextSource: React.FC<{
  source: TextBasedSource
  onChange: (_: string) => void
  onDelete: () => void
  initExpanded?: boolean
}> = ({ source, onChange, onDelete, initExpanded = false }) => {
  const [expanded, setExpanded] = React.useState(initExpanded)
  return (
    <Card
      collapsed={!expanded}
      onClick={() => {
        setExpanded(e => !e)
      }}
    >
      <View padding='none' justifyContent='space-between'>
        <View>
          <Icon iconId={sourceType2Icon(source.type)} />
          <Text size='small' bold>
            {source.label}
          </Text>
          <IconButton
            size='x-small'
            variant='transparent'
            iconId={expanded ? 'chevron--up--small' : 'chevron--down--small'}
          />
        </View>
        <View>
          <IconButton size='x-small' variant='transparent' iconId='trash-can' onClick={onDelete} />
        </View>
      </View>
      {expanded && (
        <View onClick={e => e.stopPropagation()}>
          <TextAreaPrimitive
            autoFocus
            value={source.value ?? ''}
            onChange={e => {
              onChange(e.target.value)
            }}
            rows={4}
          />
        </View>
      )}
    </Card>
  )
}

const CourseSource: React.FC<{
  label: string
  selectedIds: Array<CourseId>
  onSelect: (_: CourseId) => void
  onUnselect: (_: CourseId) => void
  onDelete: () => void
  initExpanded?: boolean
}> = ({ label, selectedIds, onDelete, onSelect, onUnselect, initExpanded = false }) => {
  const [expanded, setExpanded] = React.useState(initExpanded)
  return (
    <Card
      collapsed={!expanded}
      onClick={() => {
        setExpanded(e => !e)
      }}
    >
      <View padding='none' justifyContent='space-between'>
        <View>
          <Icon iconId='course' />
          <Text size='small' bold>
            {label}
          </Text>
          <IconButton
            size='x-small'
            variant='transparent'
            iconId={expanded ? 'chevron--up--small' : 'chevron--down--small'}
          />
        </View>
        <View>
          <IconButton size='x-small' variant='transparent' iconId='trash-can' onClick={onDelete} />
        </View>
      </View>
      {expanded && (
        <View onClick={e => e.stopPropagation()}>
          <CourseSelector onDelete={onUnselect} onSelect={onSelect} selectedIds={selectedIds} />
        </View>
      )}
    </Card>
  )
}

type PossibleSource = { id: string; label: TranslationKey; type: ScenarioDataSource['type'] }
const POSSIBLE_SOURCES = [
  {
    id: 'company-info',
    label: 'scenario-card.data-sources.company-info.label',
    type: 'company-info',
  },
  {
    id: 'faq',
    label: 'scenario-card.data-sources.faq.label',
    type: 'frequently-asked-questions',
  },
  {
    id: 'facts',
    label: 'scenario-card.data-sources.facts.label',
    type: 'facts',
  },
  {
    id: 'text',
    label: 'dictionary.text',
    type: 'text',
  },
  {
    id: 'course',
    label: 'dictionary.course-singular',
    type: 'course',
  },
] satisfies Array<PossibleSource>

const Wrapper = styled(View)`
  width: 100%;
`

export const Background: React.FC<{ file: ScenarioFile; courseId: CourseId }> = ({ file, courseId }) => {
  const { t } = useTranslation()
  const dataSources = file.data.input.dataSources ?? STATIC_EMPTY_ARRAY
  const { setDataSource } = useScenarioCardActions({ file, courseId })
  const [internalSource, setInternalSource] = React.useState<Array<Source>>(() => {
    const [courses = [], rest = []] = partition(dataSources, s => s.type === 'course')

    const restItems: Array<TextBasedSource> = rest.map((item, index): TextBasedSource => {
      switch (item.type) {
        case 'company-info':
          return {
            id: item.type + index,
            label: t('scenario-card.data-sources.company-info.label'),
            value: item.text,
            type: item.type,
          }
        case 'frequently-asked-questions':
          return {
            id: item.type + index,
            label: t('scenario-card.data-sources.faq.label'),
            value: item.text,
            type: item.type,
          }
        case 'facts':
          return {
            id: item.type + index,
            label: t('scenario-card.data-sources.facts.label'),
            value: item.text,
            type: item.type,
          }
        case 'text':
          return {
            id: item.type + index,
            label: t('scenario-card.data-sources.text.label'),
            value: item.text,
            type: item.type,
          }
        default:
          assertNever(item)
      }
    })

    const courseItem: Array<CourseBasedSource> = isNonEmptyArray(courses)
      ? [
          {
            id: 'courses',
            label: t('scenario-card.data-sources.course.label'),
            courseIds: courses.map(c => c.courseId),
            type: 'course',
          },
        ]
      : []
    return [...restItems, ...courseItem] satisfies Array<Source>
  })

  useOnChanged((_, ss) => {
    // Only grab data that actually has a value
    const ds: Array<ScenarioDataSource> = ss.flatMap((s): Array<ScenarioDataSource> => {
      switch (s.type) {
        case 'company-info':
        case 'frequently-asked-questions':
        case 'facts':
        case 'text':
          if (isNotDefined(s.value)) {
            return []
          } else {
            return [
              {
                type: s.type,
                text: s.value,
              },
            ]
          }
        case 'course':
          return s.courseIds.map(courseId => ({
            type: s.type,
            courseId,
          }))
        default:
          assertNever(s)
      }
    })
    setDataSource(ds)
  }, internalSource)

  const addNewSource = (item: PossibleSource): void => {
    if (item.type === 'course') {
      const sItem = { id: item.id, label: t(item.label), type: item.type, courseIds: [], expanded: true }
      setInternalSource(ss => [...ss, sItem])
    } else {
      const sItem = { id: item.id, label: t(item.label), type: item.type, expanded: true }
      setInternalSource(ss => [...ss, sItem])
    }
  }

  return (
    <Wrapper direction='column' grow>
      <View direction='column' gap='2'>
        <View>
          <Text capitalize='first' size='small' bold color='foreground/primary'>
            {`${t('dictionary.add')} ${t('dictionary.context')}`}
          </Text>
          <Text size='small' bold color='foreground/muted'>
            {t('dictionary.optional')}
          </Text>
        </View>
        <Text size='small' color='foreground/secondary'>
          {t('scenario-card.data-sources.context.description.text')}
        </Text>
      </View>
      <View direction='column' gap='12'>
        {internalSource.map(item => {
          if (item.type === 'course') {
            return (
              <CourseSource
                key={item.id}
                initExpanded={item.expanded}
                label={item.label}
                selectedIds={item.courseIds}
                onSelect={cId => {
                  setInternalSource(ss =>
                    ss.map(source =>
                      source.id === item.id && source.type === item.type
                        ? { ...source, courseIds: [...source.courseIds, cId] }
                        : source
                    )
                  )
                }}
                onUnselect={cId => {
                  setInternalSource(ss =>
                    ss.map(source =>
                      source.id === item.id && source.type === item.type
                        ? { ...source, courseIds: source.courseIds.filter(c => c !== cId) }
                        : source
                    )
                  )
                }}
                onDelete={() => setInternalSource(ss => ss.filter(i => i.id !== item.id))}
              />
            )
          } else {
            return (
              <TextSource
                key={item.id}
                initExpanded={item.expanded}
                source={item}
                onChange={value =>
                  setInternalSource(ss =>
                    ss.map(source => (source.id === item.id ? { ...source, value } : source))
                  )
                }
                onDelete={() => setInternalSource(ss => ss.filter(source => source.id !== item.id))}
              />
            )
          }
        })}
        <MenuDropdownPrimitive
          align='center'
          onSelect={item => {
            const menuItem = POSSIBLE_SOURCES.find(b => b.id === item.id)
            if (isDefined(menuItem)) {
              addNewSource(menuItem)
            }
          }}
          menuItems={POSSIBLE_SOURCES.map(item => ({
            id: item.id,
            label: t(item.label),
            type: 'label',
            icon: sourceType2Icon(item.type),
          }))}
          closeOnSelect
          renderTrigger={() => (
            <Button variant='ghost' grow icon='add'>
              {t('dictionary.add')}
            </Button>
          )}
        />
      </View>
    </Wrapper>
  )
}
