import Lottie from 'lottie-react'
import prsStart from 'sierra-client/assets/animations/prs-start.json'
import prsSuccess from 'sierra-client/assets/animations/prs-success.json'
import ptStart from 'sierra-client/assets/animations/pt-start.json'
import ptSuccess from 'sierra-client/assets/animations/pt-success.json'
import { config } from 'sierra-client/config/global-config'
import { useTranslation } from 'sierra-client/hooks/use-translation'
import { getGlobalRouter } from 'sierra-client/router'
import { selectIsCourseCompleted } from 'sierra-client/state/card-progress/selectors'
import { useSelector } from 'sierra-client/state/hooks'
import { FCC } from 'sierra-client/types'
import { Breadcrumbs } from 'sierra-client/views/self-paced/adaptive-features/breadcrumbs'
import { useSelfPacedFiles } from 'sierra-client/views/self-paced/files-provider'
import { Icon, IconId, ModalHeader } from 'sierra-ui/components'
import { Button, Heading, IconButton, ScrollView, Spacer, Text, View } from 'sierra-ui/primitives'
import { token } from 'sierra-ui/theming'
import { v2_breakpoint } from 'sierra-ui/theming/breakpoints'
import { legacyLight } from 'sierra-ui/theming/legacy-theme'
import styled, { ThemeProvider } from 'styled-components'

const CloseModalIconButton = styled(IconButton).attrs({
  variant: 'transparent',
  iconId: 'close',
})`
  position: absolute;
  right: 0.5rem;
`

const AnimationContainer = styled.div`
  width: 64px;
  height: 64px;

  & svg *:not([fill~='transparent']) {
    fill: ${token('foreground/primary')};
  }
`
const prs = {
  start: prsStart,
  success: prsSuccess,
} as const

const pt = {
  start: ptStart,
  success: ptSuccess,
} as const

const TopContainer = styled(View)`
  width: 100%;
  margin-bottom: auto;
  position: relative;
`

const StartFinishAdaptiveModalWrapper: FCC<{
  heading: string
  topbar: string
  iconId: IconId
  moduleTitle?: string
  animationType: 'pt' | 'prs'
  animationState: 'start' | 'success'
  //closeModal: () => void
}> = ({ children, heading, topbar, iconId, moduleTitle, animationType, animationState }) => {
  const animation = animationType === 'pt' ? pt[animationState] : prs[animationState]

  return (
    <ThemeProvider theme={legacyLight}>
      <TopContainer padding='small' paddingBottom='none' gap='none' justifyContent='center'>
        <View direction='row'>
          <Icon iconId={iconId} size='size-16' color='foreground/primary' />
          <Text bold size='small'>
            {topbar}
          </Text>
          {moduleTitle !== undefined && (
            <Text size='small' color='grey40'>
              {moduleTitle}
            </Text>
          )}
        </View>
        {/* <CloseModalIconButton onClick={closeModal} /> */}
      </TopContainer>
      <View
        grow
        direction='column'
        justifyContent='center'
        alignItems='center'
        padding='none xxlarge'
        gap='none'
      >
        <View direction='column' alignItems='center' gap='none' paddingBottom='medium'>
          <Spacer size='32' />
          <AnimationContainer>
            <Lottie animationData={animation} />
          </AnimationContainer>
        </View>
        <View gap='none' padding='none small'>
          <Heading align='center' size='h2' bold avoidHanging={false}>
            {heading}
          </Heading>
        </View>
        {children}
      </View>
    </ThemeProvider>
  )
}

export const Separator = styled(View)`
  height: 1px;
  width: 100%;
  background-color: ${token('border/default')};
  border: 0;
`

export const BottomContainer = styled(View)`
  width: 100%;
  margin-top: auto;
`

export const BottomContainerText = styled(Text)`
  border: 0;
  cursor: pointer;
`

export const StartAdaptiveModal: FCC<{
  onStart: () => void
  closeModal: () => void
  heading: string
  topbar: string
  iconId: IconId
  moduleTitle?: string
  buttonText: string
  bottomContainerText?: string
  animationType: 'pt' | 'prs'
}> = ({
  onStart,
  closeModal,
  heading,
  topbar,
  iconId,
  children,
  moduleTitle,
  buttonText,
  bottomContainerText,
  animationType,
}) => {
  return (
    <>
      <StartFinishAdaptiveModalWrapper
        iconId={iconId}
        topbar={topbar}
        heading={heading}
        moduleTitle={moduleTitle}
        animationState='start'
        animationType={animationType}
      >
        {children}
        <View paddingTop='medium' gap='none'>
          <Button onClick={onStart}>{buttonText}</Button>
        </View>
        <Spacer size='32' />
      </StartFinishAdaptiveModalWrapper>
      {bottomContainerText !== undefined && (
        <BottomContainer direction='column' alignItems='center' padding='none small' gap='none'>
          <Separator />
          <View padding='small none' gap='none'>
            <BottomContainerText color='foreground/muted' size='small' bold onClick={closeModal}>
              {bottomContainerText}
            </BottomContainerText>
          </View>
        </BottomContainer>
      )}
    </>
  )
}

export const FinishedAdaptiveModal: React.FC<{
  onContinue: () => void
  topbar: string
  iconId: IconId
  text: string
  heading: string
  moduleTitle?: string
  completed?: number
  total?: number
  timeSaved?: number
  buttonText: string
  animationType: 'pt' | 'prs'
}> = ({
  onContinue,
  text,
  topbar,
  iconId,
  heading,
  completed,
  total,
  timeSaved,
  moduleTitle,
  buttonText,
  animationType,
}) => {
  const { t } = useTranslation()
  const { flexibleContentId } = useSelfPacedFiles()
  const isCourseCompleted = useSelector(state => selectIsCourseCompleted(state, flexibleContentId))

  const backToHome = (): void => {
    void getGlobalRouter().navigate({ to: '/' })
  }

  const onClickContinue = isCourseCompleted ? backToHome : onContinue

  return (
    <>
      <StartFinishAdaptiveModalWrapper
        iconId={iconId}
        topbar={topbar}
        heading={heading}
        moduleTitle={moduleTitle}
        animationState='success'
        animationType={animationType}
      >
        <View padding='xsmall' paddingBottom='medium' gap='none'>
          <Text color='LEGACY_DEFAULT_TEXT_COLOR_REPLACE_ASAP' size='regular' align='center'>
            {text}
          </Text>
        </View>
        {completed !== 0 && (
          <View gap='none'>
            {completed !== undefined && total !== undefined && (
              <Text size='regular' color='grey40'>
                {t('content.placement-test.completed', { completed: completed, total: total })}
              </Text>
            )}

            {timeSaved !== undefined && (
              <>
                <Icon color='grey40' iconId='radio-button--dot' size='size-12' />
                <Text size='regular' color='grey40' bold>
                  {t('content.placement-test.time-saved', { count: timeSaved })}
                </Text>
              </>
            )}
          </View>
        )}
        <View paddingTop='medium' paddingBottom='medium' gap='none'>
          {/* SCORM users shouldn't get the "go to home" button. */}
          {isCourseCompleted && config.scorm.isScorm ? (
            <Text color='LEGACY_DEFAULT_TEXT_COLOR_REPLACE_ASAP' size='regular' align='center'>
              {t('content.review.course-finished-scorm')}
            </Text>
          ) : (
            <Button onClick={onClickContinue}>{buttonText}</Button>
          )}
        </View>
      </StartFinishAdaptiveModalWrapper>
    </>
  )
}

const Info = styled(IconButton).attrs({
  iconId: 'information',
  variant: 'transparent',
})``

const ContinueButtonView = styled(View)`
  @media screen and (max-width: ${v2_breakpoint.phone}) {
    padding: 1rem 3rem 1rem 3rem;
  }
`

export const DuringAdaptiveModal: FCC<{
  closeModal: () => void
  index: number
  topbar: string
  iconId: IconId
  tooltip: string
  total: number
  onContinue: () => void
  hideContinue: boolean | undefined
  moduleTitle?: string
}> = ({
  children,
  closeModal,
  total,
  index,
  topbar,
  iconId,
  tooltip,
  hideContinue,
  onContinue,
  moduleTitle,
}) => {
  const isOnLastElement = index + 1 === total
  const { t } = useTranslation()

  return (
    <ThemeProvider theme={legacyLight}>
      <ModalHeader padding='xsmall small'>
        <View gap='12' direction='row' alignItems='center'>
          <Icon iconId={iconId} size='size-14' color='foreground/primary' />
          <View gap='6' direction='row' alignItems='center'>
            <Text size='small' bold>
              {topbar}
            </Text>
            {moduleTitle !== undefined && <Text size='small'>{moduleTitle}</Text>}
            <Info tooltip={tooltip} />
          </View>
        </View>
        <Breadcrumbs length={total} current={index + 1} />
        <CloseModalIconButton onClick={closeModal} />
      </ModalHeader>
      <ScrollView grow direction='column' alignItems='stretch'>
        <View grow direction='column' justifyContent='center'>
          {children}
        </View>
      </ScrollView>
      {hideContinue === false && (
        <ContinueButtonView hide={hideContinue} justifyContent='flex-end' padding='medium 144'>
          <Button variant='transparent' icon='next--filled' onClick={onContinue}>
            {t(isOnLastElement ? 'content.adaptive.continue' : 'content.adaptive.continue-of', {
              current: index + 2,
              total,
            })}
          </Button>
        </ContinueButtonView>
      )}
    </ThemeProvider>
  )
}
