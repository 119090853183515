/* eslint-disable */

/**
 * This is a near identical copy of `@tanstack/history@1.51.7`'s [createBrowserHistory](https://github.com/TanStack/router/blob/v1.51.7/packages/history/src/index.ts)
 * function, but with the `beforeunload` event listener removed.
 *
 * This temporary workaround helps make the navigation blocking more compatible with Ably JS, which uses the `beforeunload` event to close connections,
 * and gives us more fine grained control over what to block.
 *
 * @deprecated Should no longer used once this PR is merged upstream: https://github.com/TanStack/router/pull/1790
 * @see GitHub discussion here: https://github.com/TanStack/router/discussions/2263
 */
export function createBrowserHistory(opts?: {
  parseLocation?: () => HistoryLocation
  createHref?: (path: string) => string
  window?: any
}): RouterHistory {
  const win = opts?.window ?? (typeof document !== 'undefined' ? window : (undefined as any))

  const originalPushState = win.history.pushState
  const originalReplaceState = win.history.replaceState

  const createHref = opts?.createHref ?? (path => path)
  const parseLocation =
    opts?.parseLocation ??
    (() => parseHref(`${win.location.pathname}${win.location.search}${win.location.hash}`, win.history.state))

  let currentLocation = parseLocation()
  let rollbackLocation: HistoryLocation | undefined

  const getLocation = () => currentLocation

  let next:
    | undefined
    | {
        // This is the latest location that we were attempting to push/replace
        href: string
        // This is the latest state that we were attempting to push/replace
        state: any
        // This is the latest type that we were attempting to push/replace
        isPush: boolean
      }

  // We need to track the current scheduled update to prevent
  // multiple updates from being scheduled at the same time.
  let scheduled: Promise<void> | undefined

  // This function flushes the next update to the browser history
  const flush = () => {
    if (!next) {
      return
    }

    // We need to ignore any updates to the subscribers while we update the browser history
    history._ignoreSubscribers = true

    // Update the browser history
    ;(next.isPush ? win.history.pushState : win.history.replaceState)(next.state, '', next.href)

    // Stop ignoring subscriber updates
    history._ignoreSubscribers = false

    // Reset the nextIsPush flag and clear the scheduled update
    next = undefined
    scheduled = undefined
    rollbackLocation = undefined
  }

  // This function queues up a call to update the browser history
  const queueHistoryAction = (type: 'push' | 'replace', destHref: string, state: any) => {
    const href = createHref(destHref)

    if (!scheduled) {
      rollbackLocation = currentLocation
    }

    // Update the location in memory
    currentLocation = parseHref(destHref, state)

    // Keep track of the next location we need to flush to the URL
    next = {
      href,
      state,
      isPush: next?.isPush || type === 'push',
    }

    if (!scheduled) {
      // Schedule an update to the browser history
      scheduled = Promise.resolve().then(() => flush())
    }
  }

  const onPushPop = () => {
    currentLocation = parseLocation()
    history.notify()
  }

  const history = createHistory({
    getLocation,
    pushState: (href, state) => queueHistoryAction('push', href, state),
    replaceState: (href, state) => queueHistoryAction('replace', href, state),
    back: () => win.history.back(),
    forward: () => win.history.forward(),
    go: n => win.history.go(n),
    createHref: href => createHref(href),
    flush,
    destroy: () => {
      win.history.pushState = originalPushState
      win.history.replaceState = originalReplaceState
      win.removeEventListener(pushStateEvent, onPushPop)
      win.removeEventListener(popStateEvent, onPushPop)
    },
    onBlocked: onUpdate => {
      // If a navigation is blocked, we need to rollback the location
      // that we optimistically updated in memory.
      if (rollbackLocation && currentLocation !== rollbackLocation) {
        currentLocation = rollbackLocation
        // Notify subscribers
        onUpdate()
      }
    },
  })

  win.addEventListener(pushStateEvent, onPushPop)
  win.addEventListener(popStateEvent, onPushPop)

  win.history.pushState = function (...args: Array<any>) {
    const res = originalPushState.apply(win.history, args)
    if (!history._ignoreSubscribers) onPushPop()
    return res
  }

  win.history.replaceState = function (...args: Array<any>) {
    const res = originalReplaceState.apply(win.history, args)
    if (!history._ignoreSubscribers) onPushPop()
    return res
  }

  return history
}

export interface NavigateOptions {
  ignoreBlocker?: boolean
}
export interface RouterHistory {
  location: HistoryLocation
  subscribers: Set<() => void>
  subscribe: (cb: () => void) => () => void
  push: (path: string, state?: any, navigateOpts?: NavigateOptions) => void
  replace: (path: string, state?: any, navigateOpts?: NavigateOptions) => void
  go: (index: number, navigateOpts?: NavigateOptions) => void
  back: (navigateOpts?: NavigateOptions) => void
  forward: (navigateOpts?: NavigateOptions) => void
  createHref: (href: string) => string
  block: (blocker: BlockerFn) => () => void
  flush: () => void
  destroy: () => void
  notify: () => void
  _ignoreSubscribers?: boolean
}

export interface HistoryLocation extends ParsedPath {
  state: HistoryState
}

export interface ParsedPath {
  href: string
  pathname: string
  search: string
  hash: string
}

export interface HistoryState {
  key?: string
}

type ShouldAllowNavigation = any

export type BlockerFn = () => Promise<ShouldAllowNavigation> | ShouldAllowNavigation

const pushStateEvent = 'pushstate'
const popStateEvent = 'popstate'
const beforeUnloadEvent = 'beforeunload'

export function createHistory(opts: {
  getLocation: () => HistoryLocation
  pushState: (path: string, state: any) => void
  replaceState: (path: string, state: any) => void
  go: (n: number) => void
  back: () => void
  forward: () => void
  createHref: (path: string) => string
  flush?: () => void
  destroy?: () => void
  onBlocked?: (onUpdate: () => void) => void
}): RouterHistory {
  let location = opts.getLocation()
  const subscribers = new Set<() => void>()
  let blockers: Array<BlockerFn> = []

  const notify = () => {
    location = opts.getLocation()
    subscribers.forEach(subscriber => subscriber())
  }

  const tryNavigation = async (task: () => void, navigateOpts?: NavigateOptions) => {
    const ignoreBlocker = navigateOpts?.ignoreBlocker ?? false
    if (!ignoreBlocker && typeof document !== 'undefined' && blockers.length) {
      for (const blocker of blockers) {
        const allowed = await blocker()
        if (!allowed) {
          opts.onBlocked?.(notify)
          return
        }
      }
    }

    task()
  }

  return {
    get location() {
      return location
    },
    subscribers,
    subscribe: (cb: () => void) => {
      subscribers.add(cb)

      return () => {
        subscribers.delete(cb)
      }
    },
    push: (path, state, navigateOpts) => {
      state = assignKey(state)
      tryNavigation(() => {
        opts.pushState(path, state)
        notify()
      }, navigateOpts)
    },
    replace: (path, state, navigateOpts) => {
      state = assignKey(state)
      tryNavigation(() => {
        opts.replaceState(path, state)
        notify()
      }, navigateOpts)
    },
    go: (index, navigateOpts) => {
      tryNavigation(() => {
        opts.go(index)
        notify()
      }, navigateOpts)
    },
    back: navigateOpts => {
      tryNavigation(() => {
        opts.back()
        notify()
      }, navigateOpts)
    },
    forward: navigateOpts => {
      tryNavigation(() => {
        opts.forward()
        notify()
      }, navigateOpts)
    },
    createHref: str => opts.createHref(str),
    block: blocker => {
      blockers.push(blocker)

      return () => {
        blockers = blockers.filter(b => b !== blocker)
      }
    },
    flush: () => opts.flush?.(),
    destroy: () => opts.destroy?.(),
    notify,
  }
}

function assignKey(state: HistoryState | undefined) {
  if (!state) {
    state = {} as HistoryState
  }
  return {
    ...state,
    key: createRandomKey(),
  }
}

export function parseHref(href: string, state: HistoryState | undefined): HistoryLocation {
  const hashIndex = href.indexOf('#')
  const searchIndex = href.indexOf('?')

  return {
    href,
    pathname: href.substring(
      0,
      hashIndex > 0
        ? searchIndex > 0
          ? Math.min(hashIndex, searchIndex)
          : hashIndex
        : searchIndex > 0
          ? searchIndex
          : href.length
    ),
    hash: hashIndex > -1 ? href.substring(hashIndex) : '',
    search: searchIndex > -1 ? href.slice(searchIndex, hashIndex === -1 ? undefined : hashIndex) : '',
    state: state || {},
  }
}

function createRandomKey() {
  return (Math.random() + 1).toString(36).substring(7)
}
