import {
  XRealtimeAdminApiCreateClient,
  XRealtimeAdminApiCreateScimToken,
  XRealtimeAdminApiDeleteClient,
  XRealtimeAdminApiGetClients,
  XRealtimeAdminApiUpdateClientSecret,
} from 'sierra-domain/routes'

import { useCallback, useEffect, useState } from 'react'
import { usePost } from 'sierra-client/hooks/use-post'
import { ApiClientId } from 'sierra-domain/api/nano-id'
import {
  ApiClientActions,
  ApiClientWithSecret,
  CreateApiClientRequest,
  CreateScimTokenResponse,
} from 'sierra-domain/api/public-api'

type ApiClientDetails = Omit<ApiClientWithSecret, 'clientId'> & {
  clientId?: string
  isNew: boolean
  scimToken: string
}

type UseManageApiClientDetailsData = {
  apiClient: ApiClientDetails
  isLoading: boolean
  fetchApiClient: (clientId: string) => Promise<void>
  createApiClient: (req: CreateApiClientRequest) => Promise<ApiClientWithSecret>
  updateApiClient: (clientId: string, action: ApiClientActions) => Promise<void>
  deleteApiClient: (clientId: string) => Promise<void>
  generateScimToken: (clientId: string) => Promise<CreateScimTokenResponse['accessToken']>
}
const NEW_FLAG = 'new'

export const useManageApiClientDetails = (clientId: ApiClientId | 'new'): UseManageApiClientDetailsData => {
  const isNew = clientId === NEW_FLAG

  const { postWithUserErrorException } = usePost()
  const [isLoading, setIsLoading] = useState<boolean>(false)

  const [apiClient, setApiClient] = useState<ApiClientDetails>({
    clientId: isNew ? undefined : clientId,
    clientSecret: '',
    name: '',
    description: '',
    isNew,
    scimToken: '',
  })

  const fetchApiClient = useCallback<UseManageApiClientDetailsData['fetchApiClient']>(
    async id => {
      setIsLoading(true)
      const response = await postWithUserErrorException(XRealtimeAdminApiGetClients, {})
      const client = response.clients.find(c => c.clientId === id)
      if (client !== undefined) {
        setApiClient(data => ({ ...data, ...client }))
      }
      setIsLoading(false)
    },
    [postWithUserErrorException]
  )

  const createApiClient = useCallback<UseManageApiClientDetailsData['createApiClient']>(
    async req => {
      setIsLoading(true)

      const response = await postWithUserErrorException(XRealtimeAdminApiCreateClient, req)
      setApiClient(data => ({ ...data, ...response, isNew: false }))

      setIsLoading(false)
      return response
    },
    [postWithUserErrorException]
  )

  const updateApiClient = useCallback<UseManageApiClientDetailsData['updateApiClient']>(
    async (id, action) => {
      setIsLoading(true)
      const response = await postWithUserErrorException(XRealtimeAdminApiUpdateClientSecret, {
        clientId: id,
        action,
      })
      setApiClient(data => ({ ...data, clientSecret: response.clientSecret ?? '' }))
      setIsLoading(false)
    },
    [postWithUserErrorException]
  )

  const deleteApiClient = useCallback<UseManageApiClientDetailsData['deleteApiClient']>(
    async id => {
      setIsLoading(true)
      await postWithUserErrorException(XRealtimeAdminApiDeleteClient, { clientId: id })
      setApiClient({
        clientId: undefined,
        clientSecret: '',
        name: '',
        description: '',
        isNew: true,
        scimToken: '',
      })
      setIsLoading(false)
    },
    [postWithUserErrorException]
  )

  const generateScimToken = useCallback<UseManageApiClientDetailsData['generateScimToken']>(
    async req => {
      setIsLoading(true)

      const response = await postWithUserErrorException(XRealtimeAdminApiCreateScimToken, {
        clientId: req,
      })

      setIsLoading(false)
      setApiClient(data => ({ ...data, scimToken: response.accessToken }))
      return response.accessToken
    },
    [postWithUserErrorException]
  )

  useEffect(() => {
    if (isNew) return
    void fetchApiClient(clientId)
  }, [fetchApiClient, clientId, isNew])

  return {
    isLoading,
    apiClient,
    createApiClient,
    fetchApiClient,
    updateApiClient,
    deleteApiClient,
    generateScimToken,
  }
}
