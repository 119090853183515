import { UseMutationResult } from '@tanstack/react-query'
import { invalidateGetDashboard } from 'sierra-client/features/insights/api-hooks/use-get-dashboard'
import { invalidateListDashboards } from 'sierra-client/features/insights/api-hooks/use-list-dashboards'
import { getGlobalRouter } from 'sierra-client/router'
import { useTypedMutation } from 'sierra-client/state/api'
import { NanoId12 } from 'sierra-domain/api/nano-id'
import { RequestError } from 'sierra-domain/error'
import { XAnalyticsDashboardsDelete } from 'sierra-domain/routes'

export const useDeleteDashboard = (
  redirectPath?: string
): UseMutationResult<null, RequestError, { id: NanoId12 }> => {
  return useTypedMutation(XAnalyticsDashboardsDelete, {
    onSuccess: async (data, variables) => {
      if (redirectPath !== undefined) {
        await getGlobalRouter().navigate({ to: redirectPath })
      }

      await invalidateGetDashboard({ dashboardId: variables.id })
      await invalidateListDashboards()
    },
  })
}
