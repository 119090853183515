import { useSetAtom } from 'jotai'
import { useCallback } from 'react'
import { isAbortingMultiSelectionClearAtom } from 'sierra-client/views/flexible-content/editor/content-sidebar/multi-selection/atoms'
import { useMultiSelectionTimeoutRef } from 'sierra-client/views/flexible-content/editor/content-sidebar/multi-selection/setup/timeout-context'

type UseAbortMultiSelectionClear = {
  setAbortingMultiSelectionClear: (aborting: boolean) => void
}

/**/
export const useAbortMultiSelectionClear = (): UseAbortMultiSelectionClear => {
  const setAbortingMultiSelectionClearAtom = useSetAtom(isAbortingMultiSelectionClearAtom)
  const { ref: timeoutRef } = useMultiSelectionTimeoutRef()

  const setAbortingMultiSelectionClear: UseAbortMultiSelectionClear['setAbortingMultiSelectionClear'] =
    useCallback(
      aborting => {
        if (aborting) {
          setAbortingMultiSelectionClearAtom(true)
        } else {
          timeoutRef.current = setTimeout(() => setAbortingMultiSelectionClearAtom(false), 100)
        }
      },
      [setAbortingMultiSelectionClearAtom, timeoutRef]
    )

  return {
    setAbortingMultiSelectionClear,
  }
}
