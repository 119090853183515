import { useTeamspacePermissions } from 'sierra-client/hooks/use-permissions'
import { NanoId12 } from 'sierra-domain/api/nano-id'

export const enum TeamspaceAccess {
  ALLOWED = 'allowed',
  DENIED = 'denied',
  LOADING = 'loading',
  ERROR = 'error',
}

export const useCheckTeamspaceAccess = (teamspaceId: NanoId12): TeamspaceAccess => {
  const teamspacePermissions = useTeamspacePermissions(teamspaceId)

  switch (teamspacePermissions.status) {
    case 'loaded':
      return teamspacePermissions.has('VIEW_CONTENT') ? TeamspaceAccess.ALLOWED : TeamspaceAccess.DENIED
    case 'loading':
      return TeamspaceAccess.LOADING
    case 'user-unauthorized':
      return TeamspaceAccess.DENIED
    case 'resource-not-found':
    case 'error':
      return TeamspaceAccess.ERROR
  }
  teamspacePermissions.status satisfies never
}
