import _ from 'lodash'
import React from 'react'
import { useSlateDocument } from 'sierra-client/state/flexible-content/use-slate-document'
import { FileBackgroundPicker } from 'sierra-client/views/flexible-content/card-background/file-background-picker'
import { useCreatePageYDocContext } from 'sierra-client/views/flexible-content/create-page-context'
import { FileThemePicker } from 'sierra-client/views/flexible-content/file-theme-picker'
import { WaitForEditor } from 'sierra-client/views/flexible-content/polaris-editor-provider/use-polaris-editor'
import { Debug } from 'sierra-client/views/learner/components/debug'
import { isElementType } from 'sierra-client/views/v3-author/queries'
import { SettingButtonsToolbar } from 'sierra-client/views/v3-author/question-card/settings'
import { CreateContentId } from 'sierra-domain/api/nano-id'
import { Entity } from 'sierra-domain/entity'
import { File } from 'sierra-domain/flexible-content/types'
import { QuestionCard, QuestionVariations } from 'sierra-domain/v3-author'
import { CustomThemeName, PresetThemeName } from 'sierra-ui/theming/legacy-theme'
import { Editor } from 'slate'

function useQuestionCardOrVariations(
  fileId: File['id']
): Entity<QuestionVariations> | Omit<Entity<QuestionCard>, 'children'> | undefined {
  const { yDoc } = useCreatePageYDocContext()
  const document = useSlateDocument(yDoc, fileId)
  const questionVariations = document.find(isElementType('question-variations'))
  const questionCard = document.find(isElementType('question-card'))

  if (questionVariations !== undefined) return questionVariations
  if (questionCard !== undefined) return _.omit(questionCard, ['children'])
  return undefined
}

const QuestionCardSettings: React.FC<{ editor: Editor; file: File; createContentId: CreateContentId }> = ({
  editor,
  file,
  createContentId,
}) => {
  const questionCardOrVariations = useQuestionCardOrVariations(file.id)

  if (questionCardOrVariations === undefined) return <Debug>[debug] No question card found</Debug>

  return (
    <SettingButtonsToolbar
      file={file}
      editor={editor}
      questionCardOrVariations={questionCardOrVariations}
      createContentId={createContentId}
    />
  )
}

export const QuestionCardSettingsToolbar: React.FC<{
  file: File
  createContentId: CreateContentId
  previewThemes?: (theme: undefined | CustomThemeName | PresetThemeName) => void
}> = ({ file, createContentId, previewThemes }) => (
  <>
    <FileThemePicker previewThemes={previewThemes} file={file} />
    <FileBackgroundPicker file={file} assetContext={{ type: 'course', courseId: createContentId }} />
    <WaitForEditor>
      {editor => <QuestionCardSettings editor={editor} file={file} createContentId={createContentId} />}
    </WaitForEditor>
  </>
)
