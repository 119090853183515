import React, { useCallback, useState } from 'react'
import { VideoPlayer } from 'sierra-client/components/blocks/video'
import { useAddSubtitleTracks } from 'sierra-client/components/blocks/video/use-add-subtitle-tracks'
import { VideoIsTranscribingIndicator } from 'sierra-client/components/blocks/video/video-transcription-loading-indicator'
import { useTranslation } from 'sierra-client/hooks/use-translation'
import { useUploadVideoWithProgress } from 'sierra-client/hooks/use-video-upload-with-progress'
import { selectFlexibleContentFile } from 'sierra-client/state/flexible-content/selectors'
import { useSelector } from 'sierra-client/state/hooks'
import { FCC } from 'sierra-client/types'
import { Wrap as LessonMediaWrap } from 'sierra-client/views/author/components/block-editor/v2/blocks/image'
import {
  GenerateNarrationButton,
  GeneratingNarrationView,
  NarrationModal,
} from 'sierra-client/views/flexible-content/ai-narrations/ai-narrations-video-card'
import {
  NarrationState,
  NarrationStateSync,
} from 'sierra-client/views/flexible-content/card-narration/narration-state-sync'
import {
  useCreatePageContext,
  useCreatePageNodeIdContext,
  useCreatePageYDocContext,
} from 'sierra-client/views/flexible-content/create-page-context'
import { Toolbar, ToolbarIcon, ToolbarSeparator } from 'sierra-client/views/v3-author/block-toolbar'
import { removeNodeWithId, updateNodeWithId } from 'sierra-client/views/v3-author/command'
import {
  CreditInput,
  MediaUploadContentWrapper,
  MediaUploadWrapper,
  Trash,
  Upload,
} from 'sierra-client/views/v3-author/common/media-uploader/shared'
import {
  useEditorAssetContext,
  useEditorReadOnly,
} from 'sierra-client/views/v3-author/editor-context/editor-context'
import { acceptedVideoTypes, maxFileSizeGb } from 'sierra-client/views/v3-author/plugins/with-file-paste'
import { assertElementType } from 'sierra-client/views/v3-author/queries'
import { useRenderingContext } from 'sierra-client/views/v3-author/rendering-context'
import { EditorMode, SlateFC } from 'sierra-client/views/v3-author/slate'
import { ToolbarMandatoryToggle } from 'sierra-client/views/v3-author/toolbar-mandatory-toggle'
import { LiveFollowMeVideoPlayer } from 'sierra-client/views/v3-author/video/live-session-follow-me-video-player'
import { NanoId12 } from 'sierra-domain/api/nano-id'
import { AssetContext } from 'sierra-domain/asset-context'
import { Entity } from 'sierra-domain/entity'
import { Video as VideoBlock } from 'sierra-domain/v3-author'
import { useFocused, useSelected, useSlateStatic } from 'slate-react'
import styled, { css } from 'styled-components'

const VideoWrapper: React.FC<{
  url: string
  disableSubtitles: boolean
  disableSkipping: boolean
  setVideoDuration?: (durationInSeconds: number) => void
}> = ({ url, setVideoDuration, disableSkipping, disableSubtitles }) => {
  const addSubtitles = useAddSubtitleTracks(url, !disableSubtitles)

  return (
    <VideoPlayer
      options={{
        autoplay: false,
        controls: true,
        responsive: true,
        fluid: true,
        controlBar: {
          liveDisplay: false,
          pictureInPictureToggle: false,
        },
        sources: [{ src: url, type: 'video/mp4' }],
      }}
      disableSkipping={disableSkipping}
      onLoadedMetadata={player => {
        const duration = player.player().duration()
        if (!Number.isNaN(duration)) setVideoDuration?.(duration)
        addSubtitles(player)
      }}
    />
  )
}

const UploadVideo: React.FC<{
  element: Entity<VideoBlock>
  assetContext: AssetContext
  setNarrationModalOpen: (open: boolean) => void
}> = ({ element, assetContext, setNarrationModalOpen }) => {
  const { t } = useTranslation()
  const editor = useSlateStatic()
  const { uploadVideo } = useUploadVideoWithProgress()
  const focused = useFocused()
  const selected = useSelected()

  const [initialVideo] = useState(() => {
    const video = editor.initialVideoUploads[element.id]
    if (video) delete editor.initialVideoUploads[element.id]
    return video
  })

  return (
    <MediaUploadWrapper $selected={focused && selected}>
      <Trash onClick={() => removeNodeWithId(editor, element.id)} />
      <MediaUploadContentWrapper>
        <Upload
          iconId={'play--outline'}
          accept={acceptedVideoTypes}
          additionalInfo={` ${t('author.slate.up-to', {
            number: maxFileSizeGb,
            filetype: 'GB',
          })}`}
          initialFile={initialVideo}
          uploadMedia={uploadVideo}
          onUploaded={video => updateNodeWithId(editor, element.id, { video })}
          assetContext={assetContext}
          narrationButton={
            <GenerateNarrationButton
              setNarrationModalOpen={setNarrationModalOpen}
              destinationType='video-block'
            />
          }
          uploadTitle={'author.slate.video-upload-options-with-generate'}
          buttonVariant={'secondary'}
          uploadSupportBody={'author.slate.uploading-supports'}
        />
      </MediaUploadContentWrapper>
    </MediaUploadWrapper>
  )
}

function isSkippingDisabled(element: VideoBlock): boolean {
  return element.video?.disableSkipping === true
}

const VideoToolbar: React.FC<{ element: Entity<VideoBlock> }> = ({ element }) => {
  assertElementType('video', element)
  const readOnly = useEditorReadOnly()
  const { t } = useTranslation()
  const editor = useSlateStatic()
  const { variant } = element
  const [credit, setCredit] = useState<string | undefined>(element.credit)
  const noCredit = credit?.length === 0 || credit === undefined
  const [openCredit, setOpenCredit] = useState<boolean>(!noCredit)
  const disableSkipping = isSkippingDisabled(element)
  const supportsVariants = useRenderingContext().withGrid === true

  return (
    <>
      {((readOnly && !noCredit) || openCredit) && (
        <CreditInput
          disabled={readOnly}
          value={credit ?? ''}
          placeholder={t('author.block-editor.image-credit')}
          onChange={e => setCredit(e.currentTarget.value)}
          onBlur={() => {
            updateNodeWithId(editor, element.id, { credit })
          }}
        />
      )}

      <Toolbar elementId={element.id} ignoreEditorFocus={true}>
        {supportsVariants && (
          <>
            <ToolbarIcon
              tooltip={t('author.block-editor.image-center')}
              selected={variant === 'center'}
              iconId='resize--mini'
              onClick={() => {
                updateNodeWithId(editor, element.id, { variant: 'center' })
              }}
            />

            <ToolbarIcon
              tooltip={t('author.block-editor.image-narrow')}
              selected={variant === 'narrow'}
              iconId='resize--small'
              onClick={() => {
                updateNodeWithId(editor, element.id, { variant: 'narrow' })
              }}
            />

            <ToolbarIcon
              tooltip={t('author.block-editor.image-wide')}
              selected={variant === 'wide'}
              iconId='resize--medium'
              onClick={() => {
                updateNodeWithId(editor, element.id, { variant: 'wide' })
              }}
            />

            <ToolbarIcon
              tooltip={t('author.block-editor.image-fullWidth')}
              selected={variant === 'full-width'}
              iconId='resize--large'
              onClick={() => {
                updateNodeWithId(editor, element.id, { variant: 'full-width' })
              }}
            />
            <ToolbarSeparator />
          </>
        )}

        <ToolbarIcon
          tooltip={t('author.slate.clear-video')}
          iconId='close'
          onClick={() => updateNodeWithId(editor, element.id, { video: undefined })}
        />
        <ToolbarIcon
          selected={openCredit}
          tooltip={t('author.block-editor.video-credit-tooltip')}
          iconId='image-and-text'
          onClick={() => {
            setOpenCredit(!openCredit)
          }}
        />
        <ToolbarIcon
          selected={openCredit}
          tooltip={
            element.video?.disableSubtitles === true
              ? t('author.block-editor.video-enable-subtitles')
              : t('author.block-editor.video-disable-subtitles')
          }
          iconId={element.video?.disableSubtitles === true ? 'subtitles--off' : 'subtitles--on'}
          onClick={() => {
            if (element.video !== undefined) {
              updateNodeWithId(editor, element.id, {
                video: {
                  ...element.video,
                  disableSubtitles: element.video.disableSubtitles === true ? false : true,
                },
              })
            }
          }}
        />
        <ToolbarSeparator />
        <ToolbarMandatoryToggle
          id={element.id}
          isMandatory={disableSkipping === true}
          onClick={() => {
            if (element.video?.url !== undefined && element.video.durationInSeconds !== undefined) {
              updateNodeWithId(editor, element.id, {
                video: {
                  disableSkipping: !disableSkipping,
                  url: element.video.url,
                  durationInSeconds: element.video.durationInSeconds,
                },
              })
            }
          }}
        />

        <ToolbarIcon
          tooltip={t('author.article.remove-block')}
          iconId='trash-can'
          onClick={() => removeNodeWithId(editor, element.id)}
        />
      </Toolbar>
    </>
  )
}

const SelectionWrapper = styled.div<{ $isSelected: boolean }>`
  ${p =>
    p.$isSelected &&
    css`
      box-shadow: 0 0 0 3px #b4d5ff;
    `}
`

const WatchVideo: React.FC<{
  element: Entity<VideoBlock>
  video: Required<VideoBlock>['video']
  mode: EditorMode
}> = ({ element, video, mode }) => {
  const editor = useSlateStatic()
  const disableSkipping = isSkippingDisabled(element)
  const readOnly = useEditorReadOnly()
  const focused = useFocused()
  const selected = useSelected()

  const setVideoDuration = useCallback(
    (durationInSeconds: number) => {
      if (readOnly) return
      updateNodeWithId(editor, element.id, {
        video: { ...element.video, url: video.url, durationInSeconds },
      })
    },
    [editor, element.id, element.video, readOnly, video.url]
  )

  return (
    <>
      <SelectionWrapper $isSelected={selected && focused}>
        {mode === 'live' ? (
          <LiveFollowMeVideoPlayer
            fluid
            video={video}
            videoId={element.id}
            setVideoDuration={setVideoDuration}
          />
        ) : (
          <VideoWrapper
            disableSkipping={readOnly && disableSkipping === true}
            url={video.url}
            setVideoDuration={setVideoDuration}
            disableSubtitles={element.video?.disableSubtitles === true}
          />
        )}
      </SelectionWrapper>

      <VideoToolbar element={element} />
      {!readOnly && <VideoIsTranscribingIndicator videoUrl={video.url} />}
    </>
  )
}

const ReadOnlyVideo: FCC<{ videoElement: Entity<VideoBlock>; withGrid: boolean; mode: EditorMode }> = ({
  videoElement,
  withGrid,
  mode,
  children,
}) => {
  if (videoElement.video === undefined) return <>{children}</>

  return (
    <>
      {children}
      <LessonMediaWrap
        $withGrid={withGrid}
        contentEditable={false}
        variant={videoElement.variant ?? 'narrow'}
      >
        <WatchVideo element={videoElement} video={videoElement.video} mode={mode} />
      </LessonMediaWrap>
    </>
  )
}

const Container = styled.div`
  width: 100%;
  height: 400px;
  overflow: hidden;
  border-radius: 8px;
`

const CreateVideo: FCC<{ videoElement: Entity<VideoBlock>; withGrid: boolean; mode: EditorMode }> = ({
  videoElement,
  withGrid,
  mode,
  children,
}) => {
  const { video, variant } = videoElement
  const assetContext = useEditorAssetContext()

  const [narrationModalOpen, setNarrationModalOpen] = useState(false)
  const [narrationState, setNarrationState] = useState<NarrationState | undefined>(undefined)

  const { permission } = useCreatePageYDocContext()
  const { scopedCreateContentId, createContentId, operationState } = useCreatePageContext()
  const { nodeId: selectedNodeId } = useCreatePageNodeIdContext()
  const narrationMetadata =
    selectedNodeId !== undefined
      ? narrationState?.narrations[selectedNodeId]?.find(
          it =>
            it.destinationTypeApi.type === 'video-block' &&
            it.destinationTypeApi.slateElementId === videoElement.id
        )
      : undefined

  const file = useSelector(state => selectFlexibleContentFile(state, createContentId, selectedNodeId))
  const slateElementAiParse = NanoId12.safeParse(videoElement.id)
  const slateElementId = slateElementAiParse.success ? slateElementAiParse.data : undefined
  const aiNarrationsOpen =
    narrationModalOpen && narrationState !== undefined && file !== undefined && slateElementId !== undefined

  return (
    <>
      {children}
      <LessonMediaWrap $withGrid={withGrid} contentEditable={false} variant={variant ?? 'narrow'}>
        {video === undefined ? (
          narrationMetadata?.type === 'loading' ? (
            <Container>
              <GeneratingNarrationView
                generatingMetadata={narrationMetadata}
                contentId={createContentId}
                canEdit={permission === 'edit'}
              />
            </Container>
          ) : (
            <>
              <UploadVideo
                element={videoElement}
                assetContext={assetContext}
                setNarrationModalOpen={setNarrationModalOpen}
              />
              {aiNarrationsOpen && (
                <NarrationModal
                  narrationModalOpen={narrationModalOpen}
                  closeNarrationModal={() => {
                    setNarrationModalOpen(false)
                  }}
                  file={file}
                  operationState={operationState}
                  scopedCreateContentId={scopedCreateContentId}
                  narrationSettings={narrationState.settings}
                  narrationMetadata={narrationMetadata}
                  destinationTypeApi={{ type: 'video-block', slateElementId: slateElementId }}
                />
              )}
            </>
          )
        ) : (
          <WatchVideo element={videoElement} video={video} mode={mode} />
        )}
        {slateElementId !== undefined && (
          <NarrationStateSync
            contentId={createContentId}
            canEdit={permission === 'edit'}
            setNarrationState={setNarrationState}
            destinationTypeApi={{ type: 'video-block', slateElementId: slateElementId }}
          />
        )}
      </LessonMediaWrap>
    </>
  )
}
export const Video: SlateFC = ({ children, element, mode, readOnly }) => {
  assertElementType('video', element)
  const { withGrid } = useRenderingContext()

  if (readOnly) {
    return (
      <ReadOnlyVideo videoElement={element} withGrid={withGrid} mode={mode}>
        {children}
      </ReadOnlyVideo>
    )
  } else {
    return (
      <CreateVideo videoElement={element} withGrid={withGrid} mode={mode}>
        {children}
      </CreateVideo>
    )
  }
}
