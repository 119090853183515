import React from 'react'
import { WIDGET_BUILDER_TRANSITION } from 'sierra-client/features/insights/widget-builder/data-selectors/animation-constants'
import { useTranslation } from 'sierra-client/hooks/use-translation'
import { TranslationKey } from 'sierra-client/hooks/use-translation/types'
import { Icon, IconId, TruncatedText } from 'sierra-ui/components'
import { IconButton, View } from 'sierra-ui/primitives'
import { DefaultDropdownTrigger } from 'sierra-ui/primitives/menu-dropdown'
import styled from 'styled-components'

const DeleteContainer = styled(View)`
  position: absolute;
  right: -24px;
`

export const DropdownTriggerWithDelete = React.forwardRef<
  HTMLButtonElement,
  { label?: string; placeholder: TranslationKey; icon?: IconId; onDelete?: () => void }
>(({ label, placeholder, icon, onDelete, ...rest }, ref): JSX.Element => {
  const { t } = useTranslation()

  return (
    <View
      animated
      position='relative'
      grow
      whileHover={'show-rest'}
      initial='hide'
      animate='hide'
      variants={{ 'hide': {}, 'show-rest': {} }}
    >
      <DefaultDropdownTrigger grow ref={ref} {...rest}>
        <View justifyContent='space-between' grow>
          <View grow>
            {icon !== undefined && <Icon iconId={icon} />}
            <TruncatedText
              capitalize='first'
              lines={1}
              as='span'
              color={label === undefined ? 'foreground/muted' : 'foreground/primary'}
              bold
            >
              {label ?? t(placeholder)}
            </TruncatedText>
          </View>
        </View>
      </DefaultDropdownTrigger>

      {onDelete !== undefined ? (
        <DeleteContainer
          variants={{ 'hide': { opacity: 0 }, 'show-rest': { opacity: 1 } }}
          animated
          transition={WIDGET_BUILDER_TRANSITION}
        >
          <IconButton iconId='checkbox--cross' variant='transparent' size='small' onClick={onDelete} />
        </DeleteContainer>
      ) : null}
    </View>
  )
})
