import { JSONContent } from '@tiptap/react'
import { AnimatePresence, motion, useAnimation } from 'framer-motion'
import React, { useEffect, useState } from 'react'
import { insightsAssistantWidgetSuggestionSelectedLogger } from 'sierra-client/features/insights/logger'
import { useTranslation } from 'sierra-client/hooks/use-translation'
import { FakeStreamingText } from 'sierra-client/lib/assistant-input/search-insights/fake-streaming-text'
import { InsightsAssistantInput } from 'sierra-client/lib/assistant-input/search-insights/insights-assistant-input'
import { useDispatch } from 'sierra-client/state/hooks'
import { useInsightsContentSuggestionEditor } from 'sierra-client/views/insights/insights-content-search/use-insights-content-suggestion-editor'
import { serializeInsightContentSuggestions } from 'sierra-client/views/insights/insights-content-search/utils'
import { SuggestedWidget } from 'sierra-domain/api/insights'
import { AiBackground, Icon } from 'sierra-ui/components'
import { AiLoader } from 'sierra-ui/components/ai-kit/ai-loader'
import { Button, Text, View } from 'sierra-ui/primitives'
import { token } from 'sierra-ui/theming'
import styled from 'styled-components'
import { buildWidgetBuilderStateFromWidget, WidgetBuilderState } from './widget-builder-state'

const StyledSearchWrapper = styled(View)`
  width: 500px;
`

const StyledAiLoader = styled(AiLoader)`
  width: 100%;
  height: 100%;
`

const SearchWrapper = styled(View)`
  border: 1px solid ${token('border/strong')};
  border-radius: 10px;
  width: 100%;
`

const StyledAiBackgroundWrapper = styled(View).attrs({
  direction: 'column',
  justifyContent: 'center',
  alignItems: 'center',
  position: 'relative',
  overflow: 'hidden',
  padding: '16',
  radius: 'size-16',
})`
  height: 100%;
  width: 100%;
`

const ResponseWrapper = styled(View).attrs({
  direction: 'column',
  justifyContent: 'center',
  alignItems: 'center',
})`
  max-width: 360px;
  width: 100%;
  text-align: center;
`

const RestartButton = styled(Button).attrs({ variant: 'secondary' })`
  z-index: 10;
  position: absolute;
  bottom: 40px;
`

const SuggestionsWrapper = styled(motion.div)`
  display: flex;
  flex-direction: column;
  width: 100%;
  align-items: center;
  gap: 10px;
`

const SuggestedWidgetButton = styled(motion(Button)).attrs({ variant: 'ghost' })`
  z-index: 10;
`
export type GeneratingWidgetState =
  | {
      type: 'generating'
    }
  | { type: 'feedback'; feedback: string; suggestedWidgets: SuggestedWidget[] }
  | { type: 'idle' }

const createContent = (initialText?: string): JSONContent => ({
  type: 'doc',
  content: [{ type: 'paragraph', content: [{ type: 'text', text: initialText ?? '' }] }],
})
export const NLInsights: React.FC<{
  generatingWidgetState: GeneratingWidgetState
  setGeneratingWidgetState: React.Dispatch<React.SetStateAction<GeneratingWidgetState>>
  askInsightsQuestion: (query: string) => void
  setWidget: (newState: WidgetBuilderState) => void
}> = ({ generatingWidgetState, setGeneratingWidgetState, askInsightsQuestion, setWidget }) => {
  const { t } = useTranslation()
  const dispatch = useDispatch()
  const insightsEditor = useInsightsContentSuggestionEditor()

  const editorValue = serializeInsightContentSuggestions(insightsEditor)

  const handleClear = (): void => {
    insightsEditor?.commands.setContent(createContent())
    setGeneratingWidgetState({ type: 'idle' })
  }
  const controls = useAnimation()
  const [isTextComplete, setIsTextComplete] = useState(false)

  // Animate the suggestions wrapper when the feedback is complete
  useEffect(() => {
    if (generatingWidgetState.type === 'feedback' && isTextComplete) {
      void controls.start('visible')
    } else {
      void controls.set('hidden')
    }
  }, [controls, generatingWidgetState, isTextComplete])

  useEffect(() => {
    if (editorValue !== '') {
      const handleEnter = (e: KeyboardEvent): void => {
        if (e.key === 'Enter' && insightsEditor?.isFocused === true) {
          void askInsightsQuestion(editorValue)
        }
      }
      window.addEventListener('keydown', handleEnter)
      return () => {
        window.removeEventListener('keydown', handleEnter)
      }
    }
  }, [askInsightsQuestion, editorValue, insightsEditor?.isFocused])

  const handleTextComplete = (): void => {
    setIsTextComplete(true)
  }

  return (
    <>
      {generatingWidgetState.type === 'generating' ? (
        <StyledAiLoader label='Creating widget' progress={98} />
      ) : generatingWidgetState.type === 'feedback' ? (
        <StyledAiBackgroundWrapper>
          <AiBackground />
          <ResponseWrapper>
            <Icon iconId='help' size='font-size' color={'foreground/primary'} />
            <FakeStreamingText text={generatingWidgetState.feedback} bold onComplete={handleTextComplete} />
            <AnimatePresence>
              <SuggestionsWrapper
                initial='hidden'
                animate={controls}
                variants={{
                  visible: { transition: { staggerChildren: 0.1 } },
                  hidden: {},
                }}
              >
                {generatingWidgetState.suggestedWidgets.map((suggestion, index) => (
                  <SuggestedWidgetButton
                    key={index}
                    onClick={() => {
                      setWidget(buildWidgetBuilderStateFromWidget(suggestion.widget))
                      void dispatch(
                        insightsAssistantWidgetSuggestionSelectedLogger({
                          suggestion: suggestion.title,
                          userQuery: editorValue,
                          assistantFeedback: generatingWidgetState.feedback,
                        })
                      )
                    }}
                    variants={{
                      hidden: { opacity: 0, y: 20 },
                      visible: {
                        opacity: 1,
                        y: 0,
                        transition: {
                          duration: 0.5,
                        },
                      },
                    }}
                  >
                    {suggestion.title}
                  </SuggestedWidgetButton>
                ))}
              </SuggestionsWrapper>
            </AnimatePresence>
          </ResponseWrapper>
          <RestartButton onClick={handleClear} variant={'secondary'} icon='restart'>
            {t('dictionary.restart')}
          </RestartButton>
        </StyledAiBackgroundWrapper>
      ) : (
        <View direction='column' justifyContent='center' alignItems='center' grow>
          <Icon iconId='chart--column' size='size-16' color='foreground/muted' />
          <Text color='foreground/primary' bold align='center'>
            {t('insights.widget-builder.assistant.heading')}
          </Text>
          <Text color='foreground/muted' align='center'>
            {t('insights.widget-builder.assistant.sub-heading')}
          </Text>
          <StyledSearchWrapper wrap='wrap' paddingTop={'16'}>
            <SearchWrapper>
              <InsightsAssistantInput editor={insightsEditor} askInsightsQuestion={askInsightsQuestion} />
            </SearchWrapper>
          </StyledSearchWrapper>
        </View>
      )}
    </>
  )
}
