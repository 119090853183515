import { Loader } from '@googlemaps/js-api-loader'
import { config } from 'sierra-client/config/global-config'

const GoogleLibLoader = new Loader({
  apiKey: config.googleMapsApiKey,
  version: 'weekly',
  libraries: ['maps', 'places'],
})

// We want to make sure to use a single instance of the loaders, so we'll set up global promises.
let mapsLibraryPromise: Promise<google.maps.MapsLibrary> | undefined
let placesLibraryPromise: Promise<google.maps.PlacesLibrary> | undefined

export const loadMapsLibrary = async (): Promise<google.maps.MapsLibrary> => {
  if (mapsLibraryPromise === undefined) {
    mapsLibraryPromise = GoogleLibLoader.importLibrary('maps')
  }
  return mapsLibraryPromise
}

export const loadPlacesLibrary = async (): Promise<google.maps.PlacesLibrary> => {
  if (placesLibraryPromise === undefined) {
    placesLibraryPromise = GoogleLibLoader.importLibrary('places')
  }

  return placesLibraryPromise
}
