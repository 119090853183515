import React, { useEffect, useRef } from 'react'
import { FCC } from 'sierra-client/types'
import styled from 'styled-components'

type ScrollContainerProps = {
  $maxHeight?: string
  $minHeight?: string
  $blockDrag?: boolean
  $small?: boolean
}

const ScrollContainer = styled.div<ScrollContainerProps>`
  position: relative;
  min-height: ${p => p.$minHeight ?? '24rem'};
  max-height: ${p => p.$maxHeight ?? 'calc(100vh - 21.875rem)'};
  margin-left: -1rem;
  margin-right: -1rem;
  overflow-y: hidden;
  overflow-x: visible;
  scrollbar-gutter: stable;
  width: 100%;

  &:hover {
    overflow-y: auto;
  }

  tr {
    &:first-child {
      border: 0 !important;

      td {
        border-color: transparent;
      }
    }
  }
`

type TableScrollContainerProps = ScrollContainerProps & { scrollToTopDeps?: React.DependencyList }

export const TableScrollContainer: FCC<TableScrollContainerProps> = ({
  children,
  scrollToTopDeps = [],
  ...rest
}) => {
  const containerRef = useRef<HTMLDivElement>(null)

  useEffect(() => {
    containerRef.current?.scroll(0, 0)
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, scrollToTopDeps)

  return (
    <ScrollContainer {...rest} ref={containerRef}>
      {children}
    </ScrollContainer>
  )
}

export const SmallTableScrollContainer: FCC<TableScrollContainerProps> = props => (
  <TableScrollContainer $small $maxHeight='27rem' $minHeight='1rem' {...props} />
)
