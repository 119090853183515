import { RefObject, useEffect } from 'react'

interface UseIntersectionObserverArgs {
  target: RefObject<Element> // The target element to observe
  onIntersect: IntersectionObserverCallback // Callback function to execute when intersection occurs
  threshold?: number | number[] // Single or multiple thresholds at which to trigger the callback
  root?: Element | null // The element that is used as the viewport for checking visibility
  rootMargin?: string // Margin around the root, can have values similar to the CSS margin property
}

export const useIntersectionObserver = ({
  target,
  onIntersect,
  threshold = 0,
  root = null,
  rootMargin = '0px',
}: UseIntersectionObserverArgs): void => {
  useEffect(() => {
    const t = target.current
    if (!t) {
      return
    }

    const observer = new IntersectionObserver(onIntersect, {
      root,
      rootMargin,
      threshold,
    })

    observer.observe(t)

    return () => {
      observer.unobserve(t)
    }
  }, [target, onIntersect, threshold, root, rootMargin])
}
