import { useAtom } from 'jotai'
import { useCallback, useEffect, useState } from 'react'
import { useTranslation } from 'sierra-client/hooks/use-translation'
import { logger } from 'sierra-client/logger/logger'
import { useSelector } from 'sierra-client/state/hooks'
import { selectUser } from 'sierra-client/state/user/user-selector'
import { DesignTab } from 'sierra-client/views/manage/certificates/edit-certificate-panel/design-tab'
import { InformationTab } from 'sierra-client/views/manage/certificates/edit-certificate-panel/information-tab'
import {
  CertificateInput,
  getEmptyCertificate,
  normalizeCertificateForGraphQL,
  selectedCertificateAtom,
} from 'sierra-client/views/manage/certificates/edit-certificate-panel/store'
import {
  useEditCertificateTemplate,
  useResponsivePreview,
} from 'sierra-client/views/manage/certificates/edit-certificate-panel/use-edit-certificate'
import { PreviewHtmlModal } from 'sierra-client/views/manage/certificates/preview-html-modal'
import { fetchCertificate } from 'sierra-client/views/manage/certificates/use-certificate'
import { useUpsertCertificate } from 'sierra-client/views/manage/certificates/use-upsert-certificate'
import { withPanel } from 'sierra-client/views/manage/utils/with-modal'
import { dynamicColor } from 'sierra-ui/color'
import { Icon, Tabs } from 'sierra-ui/components'
import { Button, View } from 'sierra-ui/primitives'
import { token } from 'sierra-ui/theming'
import styled from 'styled-components'

const PreviewContainer = styled(View)<{ $backgroundColor: string }>`
  width: 100%;
  background-color: ${p => dynamicColor(p.$backgroundColor).opacity(0.5)};
  min-height: 300px;
  height: 600px;
  transition: background-color 100ms cubic-bezier(0.25, 0.1, 0.25, 1) 200ms;
  position: relative;
`

const Preview = styled.iframe<{ $backgroundColor: string; $textColor: string }>`
  border-radius: 1.5rem;
  background: ${p => p.$backgroundColor};
  color: ${p => p.$textColor};
  overflow: hidden;
  position: relative;
  left: 50%;
  top: 50%;
  transform: translate(-50%, -50%);
  transform-origin: center center;
  flex-shrink: 0;
  transition:
    background-color 100ms cubic-bezier(0.25, 0.1, 0.25, 1) 200ms,
    color 100ms cubic-bezier(0.25, 0.1, 0.25, 1) 200ms;
`

const TabsContainer = styled(View)`
  width: 100%;
  height: 100%;
`

const ActionsContainer = styled(View)`
  width: 100%;
  justify-content: flex-start;
`

const PreviewButton = styled(View).attrs({ children: <Icon color='currentColor' iconId='expand' /> })`
  color: ${token('foreground/secondary')};
  background-color: ${token('surface/default')};

  &:hover {
    background-color: ${token('surface/soft')};
  }

  position: absolute;
  right: 10px;
  bottom: 10px;
  padding: 8px;
  border-radius: 9999px;
`

type TabID = 'information' | 'design'

const TabWrapper = styled(View).attrs({ paddingTop: 'small', direction: 'column', gap: '16' })``

type EditCertificatePanelProps = {
  editedId?: string
  afterSubmit?: () => void
}

export const EditCertificatePanel = withPanel<EditCertificatePanelProps>(
  {
    size: { width: 656 },
    disableScrollbarGutter: true,
  },
  ({ onClose, editedId, afterSubmit }) => {
    const [currentTab, setCurrentTab] = useState<TabID>('information')
    const [certificate, setCertificate] = useAtom(selectedCertificateAtom)
    const [isSaving, setIsSaving] = useState(false)
    const [previewOpen, setPreviewOpen] = useState(false)
    const { t } = useTranslation()
    const orgName = useSelector(selectUser)?.organizationName

    const [previewContainerElement, setPreviewContainerElement] = useState<HTMLDivElement | null>(null)
    const [previewElement, setPreviewElement] = useState<HTMLIFrameElement | null>(null)

    const saveDisabled = isSaving || certificate.title === ''

    useResponsivePreview({
      previewContainerElement,
      previewElement,
    })

    const { renderedTemplate, fetchRenderedTemplate, fullWidth, fullHeight } =
      useEditCertificateTemplate(certificate)

    const upsertCertificate = useUpsertCertificate()

    const handleSubmit = async (): Promise<void> => {
      try {
        setIsSaving(true)
        await upsertCertificate(normalizeCertificateForGraphQL(certificate))
        afterSubmit?.()
        onClose()
      } catch (e) {
        logger.captureError(e)
      } finally {
        setIsSaving(false)
      }
    }

    useEffect(() => {
      const fetch = async (editedId: string | undefined): Promise<void> => {
        if (editedId !== undefined) {
          const certificate = await fetchCertificate(editedId)
          if (certificate !== undefined && certificate !== null) {
            await fetchRenderedTemplate(certificate)
            setCertificate(certificate)
          }
        } else {
          const certificate = getEmptyCertificate(orgName)
          await fetchRenderedTemplate(certificate)
          setCertificate(certificate)
        }
      }

      void fetch(editedId)

      // Always go to information tab when you've changed the current certificate
      setCurrentTab('information')
    }, [editedId, setCertificate, orgName, fetchRenderedTemplate])

    const sendUpdate = useCallback(
      (target: string, value: string) => {
        if (previewElement === null) return

        const document = previewElement.contentDocument
        const element = document?.querySelector('[data-update-target="' + target + '"]')

        if (element === null || element === undefined) return
        element.innerHTML = value
      },
      [previewElement]
    )

    const updateField = useCallback(
      (param: string, valueBefore: string, valueAfter: string) => {
        if (valueBefore === valueAfter) {
          return
        }

        setCertificate(cert => ({
          ...cert,
          [param]: valueAfter,
        }))
        sendUpdate(param, valueAfter)
      },
      [sendUpdate, setCertificate]
    )

    const refetchCertificate = (certificate: CertificateInput): void => {
      void fetchRenderedTemplate(certificate)
    }

    return (
      <>
        <PreviewContainer
          $backgroundColor={certificate.templateData.backgroundColor ?? 'transparent'}
          ref={setPreviewContainerElement}
        >
          <Preview
            $backgroundColor={certificate.templateData.backgroundColor ?? 'transparent'}
            $textColor={certificate.templateData.textColor}
            srcDoc={renderedTemplate}
            width={fullWidth}
            height={fullHeight}
            ref={setPreviewElement}
          />
          <PreviewButton onClick={() => setPreviewOpen(true)} />
        </PreviewContainer>
        <TabsContainer grow padding='none medium' paddingTop='small' direction='column'>
          <Tabs
            value={currentTab}
            onChange={(newTab: string) => setCurrentTab(newTab as TabID)}
            items={[
              {
                id: 'information',
                label: t('dictionary.information'),
                content: (
                  <TabWrapper>
                    <InformationTab updateField={updateField} refetch={refetchCertificate} />
                  </TabWrapper>
                ),
              },
              {
                id: 'design',
                label: t('dictionary.design'),
                content: (
                  <TabWrapper>
                    <DesignTab refetch={refetchCertificate} />
                  </TabWrapper>
                ),
              },
            ]}
          />
        </TabsContainer>
        <ActionsContainer padding='medium'>
          <View>
            <Button variant='secondary' onClick={onClose}>
              {t('dictionary.cancel')}
            </Button>
            <Button onClick={handleSubmit} loading={isSaving} disabled={saveDisabled}>
              {t('dictionary.save')}
            </Button>
          </View>
        </ActionsContainer>
        <PreviewHtmlModal
          open={previewOpen}
          onClose={() => setPreviewOpen(false)}
          fullWidth={fullWidth}
          fullHeight={fullHeight}
          src={renderedTemplate}
        />
      </>
    )
  }
)
