import React, { useState } from 'react'
import { queryClient } from 'sierra-client/api/query-client'
import { ActionModal } from 'sierra-client/components/common/modals/action-modal'
import { getFlag } from 'sierra-client/config/global-config'
import { useGetDashboardTemplate } from 'sierra-client/features/insights/api-hooks/use-get-dashboard-template'
import { useUpsertDashboard } from 'sierra-client/features/insights/api-hooks/use-upsert-dashboard'
import { redirectToDashboard } from 'sierra-client/features/insights/dashboard-selector/utils'
import { StickyDashboardHeader } from 'sierra-client/features/insights/dashboard/sticky-dashboard-header'
import { useDashboardFilter } from 'sierra-client/features/insights/dashboard/use-dashboard-filter'
import { getUpsertDashboardDataForWidget } from 'sierra-client/features/insights/dashboard/utils'
import { WidgetGrid } from 'sierra-client/features/insights/dashboard/widget-grid'
import { useAllFilterDomainReps } from 'sierra-client/features/insights/hooks/use-insights-views'
import { WidgetBuilderModal } from 'sierra-client/features/insights/widget-builder'
import { DashboardFilterSelector } from 'sierra-client/features/insights/widget-builder/data-selectors/dashboard-filter-selector'
import { PartialDashboardWidget } from 'sierra-client/features/insights/widget-builder/types'
import { WidgetBuilderModalState } from 'sierra-client/features/insights/widget-builder/widget-builder-modal'
import { useTranslation } from 'sierra-client/hooks/use-translation'
import { labelToString } from 'sierra-client/lib/filter/components/common'
import { getGlobalRouter } from 'sierra-client/router'
import { getCachedQueryKey, typedPost } from 'sierra-client/state/api'
import { DetailsHeader } from 'sierra-client/views/manage/components/details-header'
import { ManageHeadline } from 'sierra-client/views/manage/components/layout/manage-headline'
import {
  Dashboard,
  DashboardWidget,
  InsightsDashboardTemplateGetResponse,
  TemplateId,
} from 'sierra-domain/api/insights'
import { nanoid12 } from 'sierra-domain/nanoid-extensions'
import { XAnalyticsDashboardsGet } from 'sierra-domain/routes'
import { Button, InputPrimitive, LoadingSpinner, Text, View } from 'sierra-ui/primitives'
import styled from 'styled-components'

const DashboardModalView = styled(View).attrs({ direction: 'column' })`
  margin: 1rem 0rem 2rem;
  width: 30rem;
  max-width: 100%;
`

const ViewWrapper = styled(View).attrs({ direction: 'column', grow: true, gap: 'none' })`
  height: 100%;
  width: 100%;
`

const TemplateWithData: React.FC<{ template: InsightsDashboardTemplateGetResponse }> = ({ template }) => {
  const newInsightsHome = getFlag('insights/new-home')
  const [dashboardFilter, setDashboardFilter] = useDashboardFilter({
    dashboardId: template.id,
    filterDomains: template.filterDomains,
    initFilter: template.filter,
  })
  const { t, dynamicT } = useTranslation()
  const label = labelToString(template.name, dynamicT)
  const [modalOpen, setModalOpen] = useState(false)
  const upsertDashboard = useUpsertDashboard()
  const allFilterDomainReps = useAllFilterDomainReps()
  const [title, setTitle] = useState(t('content.copy-of-title', { title: label }))
  const [widgetBuilderModalState, setWidgetBuilderModalState] = useState<WidgetBuilderModalState>('closed')

  const handleSave = (): void => {
    setModalOpen(true)
  }

  const onWidgetBuilderModalSubmit = async (
    partialDashboardWidget: PartialDashboardWidget,
    _: () => void,
    dashboardId?: Dashboard['id']
  ): Promise<void> => {
    if (dashboardId === undefined) {
      throw new Error('No dashboard id supplied')
    }
    const queryKey = getCachedQueryKey(XAnalyticsDashboardsGet, { dashboardId })
    const dashboardResponse = await queryClient.fetchQuery({
      queryKey: queryKey,
      queryFn: () => typedPost(XAnalyticsDashboardsGet, { dashboardId }),
    })
    const upsertData = getUpsertDashboardDataForWidget({
      dashboardWidget: partialDashboardWidget,
      dashboard: dashboardResponse.dashboard.dashboard,
      allFilterDomainReps,
    })
    upsertDashboard.mutate(
      { dashboard: { id: dashboardId, ...upsertData.dashboard } },
      {
        onSuccess: () => {
          void redirectToDashboard(dashboardId)
        },
      }
    )
  }

  const handleSelectWidget = (dashboardWidget: DashboardWidget): void => {
    setWidgetBuilderModalState({ type: 'open-viewer', dashboardWidget })
  }

  return (
    <ViewWrapper>
      {newInsightsHome && (
        <DetailsHeader
          backlink={{ href: '/manage/insights/dashboards', label: 'manage.backlinks--dashboards' }}
        />
      )}

      <ManageHeadline>{label}</ManageHeadline>
      <Text>{labelToString(template.description, dynamicT)}</Text>

      <StickyDashboardHeader>
        <View grow alignItems='center'>
          {template.filterDomains !== undefined && template.filterDomains.length > 0 && (
            <View data-intercom-target='dashboard-filter' alignItems='center' gap='none'>
              <DashboardFilterSelector
                selectedFilter={dashboardFilter}
                onChange={setDashboardFilter}
                filterDomains={template.filterDomains}
                enableResetFilter={
                  template.filter !== undefined ? { initialFilter: template.filter } : undefined
                }
              />
            </View>
          )}
        </View>
        <View>
          <Button variant='secondary' icon='duplicate' onClick={handleSave}>
            {t('manage.insights.dashboard-template.save')}
          </Button>
        </View>
      </StickyDashboardHeader>

      <WidgetGrid
        key={template.id}
        dashboardWidgets={template.widgets}
        canEdit={false}
        dashboardFilter={dashboardFilter}
        onSelect={handleSelectWidget}
      />

      <WidgetBuilderModal
        state={widgetBuilderModalState}
        onClose={() => {
          setWidgetBuilderModalState('closed')
        }}
        onSubmit={onWidgetBuilderModalSubmit}
        isSubmitting={upsertDashboard.isPending}
        errorText={upsertDashboard.error?.status === 400 ? t('user-error.other.unknown') : undefined}
      />

      <ActionModal
        open={modalOpen}
        title={t('manage.insights.dashboard.duplicate')}
        onClose={() => {
          setModalOpen(false)
        }}
        primaryAction={async () => {
          const newWidgets = template.widgets.map(widget => ({ ...widget, id: nanoid12() }))
          const newDashboard = await upsertDashboard.mutateAsync({
            dashboard: { title, widgets: newWidgets },
          })
          await getGlobalRouter().navigate({
            to: `/manage/insights/dashboards/${newDashboard.dashboard.id}`,
            replace: true,
          })
        }}
        primaryActionLabel={t('dictionary.duplicate')}
      >
        <DashboardModalView>
          <View direction='row' justifyContent='space-between'>
            <label htmlFor='title'>
              <Text bold>{t('manage.insights.create-dashboard.title-label')}</Text>
            </label>
          </View>
          <InputPrimitive id='title' value={title} onChange={event => setTitle(event.target.value)} />
        </DashboardModalView>
      </ActionModal>
    </ViewWrapper>
  )
}

export const Template: React.FC<{ id: TemplateId }> = ({ id }) => {
  const tempateResponse = useGetDashboardTemplate({ id })

  if (tempateResponse.isPending) return <LoadingSpinner />

  if (tempateResponse.isError)
    return (
      <View>
        <Text>Could not load template</Text>
        <Text>{tempateResponse.error.message}</Text>
      </View>
    )

  return <TemplateWithData template={tempateResponse.data} />
}
