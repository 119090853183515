import storage from 'local-storage-fallback'
import { useCallback, useState } from 'react'
import { useSelector } from 'sierra-client/state/hooks'
import { selectUserOrganizationId } from 'sierra-client/state/user/user-selector'

const ONCE_LOCAL_STORAGE_KEY = 'once'

export type DataKey = `nudgeToRecord-${string}` | 'avatarsLaunchModal'

type OnceData = Partial<Record<DataKey, boolean>>

const onceConfig: Record<DataKey, { until: Date; orgIds?: string[] }> = {
  avatarsLaunchModal: { until: new Date('2025-10-01T00:00:00.000Z') },
}

const getOnceData = (): OnceData => {
  try {
    const localStorageOnceString = storage.getItem(ONCE_LOCAL_STORAGE_KEY)
    if (localStorageOnceString === null) return {}

    const parsed = JSON.parse(localStorageOnceString)
    if (typeof parsed !== 'object') return {}

    return parsed as OnceData
  } catch (e) {
    return {}
  }
}

const getIsKeyExpired = (key: DataKey): boolean => {
  const expirationDate: Date | undefined = onceConfig[key]?.until
  if (expirationDate === undefined) return false
  const now = new Date()
  return now > expirationDate
}

const getIsKeySameOrg = (key: DataKey, currentOrg?: string): boolean => {
  const targetOrgs = onceConfig[key]?.orgIds

  if (targetOrgs === undefined) return true
  if (currentOrg === undefined) return false

  return targetOrgs.includes(currentOrg)
}

const setOnceKey = (key: DataKey, seen: boolean): OnceData => {
  const onceData = getOnceData()
  onceData[key] = seen
  const stringified = JSON.stringify(onceData)
  storage.setItem(ONCE_LOCAL_STORAGE_KEY, stringified)

  return onceData
}

type UseOnceData = {
  showOnce: boolean
  setSeen: () => void
}

export const useOnce = (key: DataKey): UseOnceData => {
  const [onceData, setOnceData] = useState<OnceData>(getOnceData())
  const organizationId = useSelector(selectUserOrganizationId)

  const isKeySeen = onceData[key] === true
  const isKeyExpired = getIsKeyExpired(key)
  const isKeySameOrg = getIsKeySameOrg(key, organizationId)
  const showOnce = !isKeySeen && !isKeyExpired && isKeySameOrg

  const setSeen = useCallback(() => {
    const newOnceData = setOnceKey(key, true)
    setOnceData(newOnceData)
  }, [key])

  return {
    showOnce,
    setSeen,
  }
}
