import _ from 'lodash'
import { DateTime } from 'luxon'
import { Datestamp } from 'sierra-client/components/common/date-stamp'
import { useContentKindPermissions } from 'sierra-client/hooks/use-permissions'
import { useTranslation } from 'sierra-client/hooks/use-translation'
import { useUserLegacy } from 'sierra-client/state/users/hooks'
import { LiveSessionWithParticipants } from 'sierra-domain/api/admin'
import { LiveContentId } from 'sierra-domain/api/nano-id'
import { scheduledOrNull } from 'sierra-domain/content/session'
import { Icon, MenuItem, TruncatedTextWithTooltip } from 'sierra-ui/components'
import { Text, View } from 'sierra-ui/primitives'
import { MenuDropdownPrimitive } from 'sierra-ui/primitives/menu-dropdown'
import { palette } from 'sierra-ui/theming'
import { fonts } from 'sierra-ui/theming/fonts'
import styled from 'styled-components'

const Container = styled(View)`
  cursor: pointer;
  border: 1px solid ${palette.grey[5]};
  border-radius: 10px;
  padding: 9px;
  ${fonts.body.small};
`

const MenuItemContainer = styled(View)`
  width: 358px;
  display: flex;
  justify-content: space-between;
`

type SessionItemProps = {
  session: LiveSessionWithParticipants
  selected: boolean
  onClick: () => void
}
const SessionItem: React.FC<SessionItemProps> = ({ session, selected, onClick }) => {
  const facilitator = useUserLegacy(_.first(session.data.facilitatorIds))

  const schedule = scheduledOrNull(session.data)

  const startTime = schedule
    ? DateTime.fromISO(schedule.startTime).toLocaleString(DateTime.TIME_SIMPLE)
    : undefined
  const endTime = schedule
    ? DateTime.fromISO(schedule.endTime).toLocaleString(DateTime.TIME_SIMPLE)
    : undefined

  const seatsLeft =
    session.data.maxNumberOfUsers !== undefined
      ? session.data.maxNumberOfUsers - session.participantIds.length
      : undefined

  return (
    <MenuItemContainer padding='xsmall' onClick={onClick} cursor='pointer'>
      <View>
        <Datestamp date={scheduledOrNull(session.data)?.startTime} />
        <div>
          <Text color='foreground/primary' size='regular' bold>
            {_.truncate(session.data.title, { length: 36, omission: '...' })}
          </Text>
          <Text color={!selected ? 'foreground/muted' : 'foreground/primary'} size='micro'>
            {`${startTime} - ${endTime}`}
            {facilitator !== undefined && `, ${facilitator.firstName} ${facilitator.lastName}`}
            {seatsLeft !== undefined && `, ${seatsLeft} seats left`}
          </Text>
        </div>
      </View>
      {selected && <Icon color='black' iconId={'checkmark'} />}
    </MenuItemContainer>
  )
}

type LiveSessionPickerProps = {
  selectedSession?: LiveSessionWithParticipants
  sessions: LiveSessionWithParticipants[]
  onSessionPick: (
    pick:
      | { action: 'schedule-new-session' }
      | { action: 'schedule-existing-session'; session: LiveSessionWithParticipants }
  ) => void
  liveContentId: LiveContentId
}

export const LiveSessionPicker: React.FC<LiveSessionPickerProps> = ({
  sessions,
  selectedSession,
  onSessionPick,
  liveContentId,
}) => {
  const permissions = useContentKindPermissions(liveContentId)

  const { t } = useTranslation()

  const menuItems: MenuItem[] = [
    ...(sessions.map(s => ({
      type: 'canvas',
      id: s.liveSessionId,
      render: () => (
        <SessionItem
          session={s}
          selected={s.liveSessionId === selectedSession?.liveSessionId}
          onClick={() => {
            onSessionPick({ action: 'schedule-existing-session', session: s })
          }}
        />
      ),
    })) as MenuItem[]),
  ]

  if (permissions.has('SCHEDULE')) {
    menuItems.push({
      type: 'canvas',
      id: 'no-sessions',
      render: () => {
        return (
          <View
            grow
            padding='xxsmall xsmall'
            alignItems='center'
            onClick={() => {
              onSessionPick({ action: 'schedule-new-session' })
            }}
          >
            <Icon iconId='calendar' />
            <Text color={sessions.length > 0 ? 'foreground/muted' : 'foreground/primary'} size='small' bold>
              {t('sharing-modal.schedule-new-session')}
            </Text>
          </View>
        )
      },
    })
  }

  return (
    <MenuDropdownPrimitive
      renderTrigger={open => (
        <Container>
          <TruncatedTextWithTooltip size='small' bold={selectedSession !== undefined}>
            {_.truncate(selectedSession?.data.title ?? t('create.home.select-session'), {
              length: 14,
              omission: '...',
            })}
          </TruncatedTextWithTooltip>
          <Icon iconId={open ? 'chevron--up--small' : 'chevron--down--small'} />
        </Container>
      )}
      menuItems={menuItems}
      closeOnSelect
      onSelect={item => 'onClick' in item && item.onClick?.()}
    />
  )
}
