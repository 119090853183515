import { TranslationKey, TranslationLookup } from 'sierra-client/hooks/use-translation/types'
import { UserAccessLevel, isAccessLevelBelowOrEqualTo } from 'sierra-domain/access-level'
import { BaseUserRow } from 'sierra-domain/user/base-user-row'
import { UserStatus } from 'sierra-domain/user/status'
import { assertNever } from 'sierra-domain/utils'
import { MenuItem } from 'sierra-ui/components'

export const getAccessLevelTranslationKey = (accessLevel: UserAccessLevel): TranslationKey => {
  switch (accessLevel) {
    case 'admin':
      return 'admin.access-levels.admin'
    case 'group-admin':
      return 'admin.access-levels.group-admin'
    case 'guest':
      return 'admin.access-levels.guest'
    case 'learner':
      return 'admin.access-levels.learner'
    case 'owner':
      return 'admin.access-levels.owner'
    default:
      assertNever(accessLevel)
  }
}

export const getAccessLevelOptions = (
  t: TranslationLookup,
  ownUserAccessLevel?: UserAccessLevel
): MenuItem[] => {
  return UserAccessLevel.options.map(value => {
    const hasCorrectLevel =
      ownUserAccessLevel !== undefined && isAccessLevelBelowOrEqualTo(value, ownUserAccessLevel) // Check current access level not above own
    const isGuest = value === 'guest'
    const isOwner = value === 'owner'

    return {
      id: value,
      type: 'label',
      label: t(getAccessLevelTranslationKey(value)),
      disabled: !hasCorrectLevel || isOwner || isGuest,
    }
  })
}

export const getUserStatusTranslationKey = (userStatus: UserStatus): TranslationKey => {
  switch (userStatus) {
    case 'active':
      return 'filter.domain.user.status.active'
    case 'deactivated':
      return 'filter.domain.user.status.deactivated'
    case 'pending':
      return 'filter.domain.user.status.pending'
    default:
      assertNever(userStatus)
  }
}

export const getUserStatusLevelOptions = (t: TranslationLookup): MenuItem[] => {
  return UserStatus.options.map(value => {
    return {
      id: value,
      type: 'label',
      label: t(getUserStatusTranslationKey(value)),
      disabled: value === 'pending',
    }
  })
}
export const userBaseToCsv = (userRow: BaseUserRow): Record<string, string> => ({
  userId: userRow.userId,
  email: userRow.email,
  firstName: userRow.firstName ?? '',
  lastName: userRow.lastName ?? '',
})
