import { useTranslation } from 'sierra-client/hooks/use-translation'
import { TranslationKey } from 'sierra-client/hooks/use-translation/types'
import { FCC } from 'sierra-client/types'
import { isDefined } from 'sierra-domain/utils'
import { Icon, IconId } from 'sierra-ui/components'
import { Text, View } from 'sierra-ui/primitives'
import { token } from 'sierra-ui/theming'
import { v2_breakpoint } from 'sierra-ui/theming/breakpoints'
import styled, { css } from 'styled-components'

const TitleArea = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  gap: 16px;
  grid-area: title;
  max-width: 400px;
  text-wrap: balance;
`
const ContentArea = styled.div<{ $withoutTopPadding: boolean }>`
  grid-area: content;
  max-width: 680px;

  ${p =>
    p.$withoutTopPadding === true
      ? undefined
      : css`
          padding-top: 32px;
        `}
`
const GridContainer = styled.div`
  display: grid;
  grid-template-columns: 1fr 2fr;
  grid-template-areas: 'title content';
  grid-template-rows: auto;
  gap: 32px 48px;
  padding: 40px 0;
  border-top: 1px solid ${token('border/default')};

  @media (max-width: ${v2_breakpoint.tablet}) {
    grid-template-columns: 1fr;
    grid-template-rows: auto auto;
    grid-template-areas: 'title' 'content';

    ${ContentArea} {
      padding-top: 0;
    }
  }
`

export const SettingsSection: FCC<{ iconId?: IconId; title: TranslationKey; subtitle?: TranslationKey }> = ({
  iconId,
  title,
  subtitle,
  children,
}) => {
  const { t } = useTranslation()

  return (
    <GridContainer>
      <TitleArea>
        {isDefined(iconId) && <Icon iconId={iconId} color='foreground/muted' />}

        <View direction='column' gap='none'>
          <Text bold>{t(title)}</Text>
          {isDefined(subtitle) && <Text color='foreground/secondary'>{t(subtitle)}</Text>}
        </View>
      </TitleArea>

      <ContentArea $withoutTopPadding={iconId === undefined}>{children}</ContentArea>
    </GridContainer>
  )
}
