import React from 'react'
import { Text, View } from 'sierra-ui/primitives'
import { token } from 'sierra-ui/theming'
import styled from 'styled-components'

const Circle = styled(View).attrs({
  radius: 'size-28',
  color: 'foreground/primary',
  paddingLeft: '6',
  paddingRight: '6',
})`
  display: flex;
  justify-content: center;
  align-items: center;
  min-width: 16px;
  min-height: 16px;
  flex: 0 0 auto; /* Don't collapse */
  background: ${token('destructive/background')};
`

export const SeatRequestCount = React.forwardRef<HTMLDivElement, { count: number }>(({ count }, ref) => {
  return (
    <Circle ref={ref}>
      <Text color='white' size='micro' bold>
        {count}
      </Text>
    </Circle>
  )
})
