import { Awareness } from 'y-protocols/awareness'

type AwarenessSubscription = { destroy: () => void }

export const subscribeToAwareness = (
  awareness: Awareness,
  { onChange }: { onChange: (states: unknown[]) => void }
): AwarenessSubscription => {
  const onAwarenessChanged = (): void => {
    const states = Array.from(awareness.getStates().values())
    onChange(states)
  }

  awareness.on('change', onAwarenessChanged)
  return { destroy: () => awareness.off('change', onAwarenessChanged) }
}
