import { encode as toBase64 } from 'js-base64'
import { subscribeSse } from 'sierra-client/state/api'
import { OpenAIMessage, OutputControls } from 'sierra-domain/api/author-v2'
import { CourseId, NanoId12 } from 'sierra-domain/api/nano-id'
import {
  SSEXRealtimeAuthorGenerateBulletCardHTML,
  SSEXRealtimeAuthorGenerateGeneralCardHTML,
  SSEXRealtimeAuthorGenerateInteractiveCard,
} from 'sierra-domain/routes-sse'

type Input = Omit<Parameters<typeof subscribeSse>[0], 'route' | 'input'>

export const generateGeneralCardHTMLSse = ({
  messages,
  outputControls,
  courseId,
  pdfId,
  pdfImageIds,
  ...args
}: {
  messages: OpenAIMessage[]
  outputControls: OutputControls | undefined
  courseId: CourseId
  pdfId: NanoId12
  pdfImageIds: string[]
} & Input): ReturnType<typeof subscribeSse> => {
  return subscribeSse({
    route: SSEXRealtimeAuthorGenerateGeneralCardHTML,
    input: {
      outputControls,
      messages: toBase64(JSON.stringify(messages)),
      courseId,
      pdfId,
      pdfImageIds,
    },
    ...args,
  })
}

export const generateBulletCardHTMLSse = ({
  messages,
  outputControls,
  ...args
}: {
  messages: OpenAIMessage[]
  outputControls: OutputControls | undefined
} & Input): ReturnType<typeof subscribeSse> => {
  return subscribeSse({
    route: SSEXRealtimeAuthorGenerateBulletCardHTML,
    input: {
      outputControls,
      messages: toBase64(JSON.stringify(messages)),
    },
    ...args,
  })
}

export const generateInteractiveCardSse = ({
  messages,
  outputControls,
  ...args
}: {
  messages: OpenAIMessage[]
  outputControls: OutputControls | undefined
} & Input): ReturnType<typeof subscribeSse> => {
  return subscribeSse({
    route: SSEXRealtimeAuthorGenerateInteractiveCard,
    input: { messages: toBase64(JSON.stringify(messages)), outputControls },
    ...args,
  })
}
