import { useAtomValue } from 'jotai/index'
import { capitalize, minBy } from 'lodash'
import React, { useMemo, useRef } from 'react'
import { useSelectCurrentCard } from 'sierra-client/components/liveV2/hooks/use-select-current-card'
import { useLiveSessionIdContext } from 'sierra-client/components/liveV2/live-session-id-provider'
import { Logging } from 'sierra-client/core/logging'
import { EvaluationIndicator } from 'sierra-client/features/sana-now/header/participants-popover/evaluation-indicator'
import { HighlightPollResponseButton } from 'sierra-client/features/sana-now/header/participants-popover/highlight-poll-response-indicator'
import { HighlightReflectionResponseButton } from 'sierra-client/features/sana-now/header/participants-popover/highlight-reflection-response-button'
import {
  LightUserWithResponse,
  PollResponse,
  ReflectionCardResponse,
  SEARCH_HEIGHT,
  SlidingScaleResponse,
} from 'sierra-client/features/sana-now/header/participants-popover/utils'
import { getPollResponse } from 'sierra-client/features/sana-now/post-session-page/download-data-button'
import { useTranslation } from 'sierra-client/hooks/use-translation'
import { useDispatch, useSelector } from 'sierra-client/state/hooks'
import { currentPollDataAtom, PollData } from 'sierra-client/state/interactive-card-data/poll-card'
import { currentQuestionFacilitatorSummaryDataAtom } from 'sierra-client/state/interactive-card-data/question-card'
import {
  currentReflectionSummaryDataAtom,
  ReflectionResponseSummaryData,
} from 'sierra-client/state/interactive-card-data/reflection-card'
import { currentSlidingScaleDataAtom } from 'sierra-client/state/interactive-card-data/sliding-scale-card'
import { addFacilitator, removeFacilitator } from 'sierra-client/state/live-session/actions'
import { selectFacilitatorIds, selectIsFacilitator } from 'sierra-client/state/live-session/selectors'
import { selectUser } from 'sierra-client/state/user/user-selector'
import { getAvatarImage } from 'sierra-client/utils/avatar-img'
import { HoveredReveal } from 'sierra-client/views/flexible-content/editor/content-sidebar/hovered-reveal'
import { useFlexibleContentYDoc } from 'sierra-client/views/flexible-content/polaris-editor-provider/use-flexible-content-ydoc'
import { isElementType } from 'sierra-client/views/v3-author/queries'
import { UserId } from 'sierra-domain/api/uuid'
import { ScopedLiveSessionId } from 'sierra-domain/collaboration/types'
import { LightUser } from 'sierra-domain/user'
import { assertNever, getUserName, iife } from 'sierra-domain/utils'
import { getSlateDocument } from 'sierra-domain/v3-author/slate-yjs-extension'
import { RoundAvatar, TruncatedText } from 'sierra-ui/components'
import { IconButton, Spacer, Text, View } from 'sierra-ui/primitives'
import { token } from 'sierra-ui/theming'
import { Descendant } from 'slate'
import styled from 'styled-components'

const ListItemContainer = styled(View)`
  min-height: 42px;
`

const UserDataContainer = styled(View)`
  flex-grow: 1;
  flex-shrink: 0;
`

const StyledTruncatedText = styled(TruncatedText)`
  max-width: 60px;
`

export const ParticipantListItem: React.FC<{
  user: LightUserWithResponse
  showFacilitatorText?: boolean
}> = ({ user }) => {
  const { t } = useTranslation()
  const me = useSelector(selectUser)
  const containerRef = useRef<HTMLDivElement>(null)
  const userIsCurrentUser = me?.uuid === user.uuid
  const guestUserEmail = user.email.startsWith('sana-live')
  const facilitatorIds = useSelector(selectFacilitatorIds)
  const dispatch = useDispatch()
  const isCurrentUserFacilitator = useSelector(selectIsFacilitator)
  const liveSessionId = useLiveSessionIdContext().liveSessionId
  const isParticipantFacilitator = facilitatorIds.includes(user.uuid)

  const requestToggleFacilitator = (): void => {
    if (isParticipantFacilitator) {
      void dispatch(
        removeFacilitator({ userId: user.uuid, liveSessionId: ScopedLiveSessionId.extractId(liveSessionId) })
      )
      void dispatch(Logging.liveSession.facilitatorRemoved())
    } else {
      void dispatch(
        addFacilitator({ userId: user.uuid, liveSessionId: ScopedLiveSessionId.extractId(liveSessionId) })
      )
      void dispatch(Logging.liveSession.facilitatorAdded())
    }
  }

  return (
    <ListItemContainer
      ref={containerRef}
      alignItems='center'
      justifyContent='space-between'
      grow
      gap='16'
      padding='none none'
    >
      <UserDataContainer>
        <RoundAvatar
          size='medium'
          firstName={user.firstName}
          lastName={user.lastName}
          color={user.avatarColor}
          src={getAvatarImage(user.uuid, user.avatar)}
        />
        <View direction='column' gap='none'>
          <TruncatedText lines={1} size='small' bold>
            {userIsCurrentUser ? capitalize(t('dictionary.you')) : getUserName(user) ?? ''}
          </TruncatedText>

          <TruncatedText size='small' color='foreground/muted'>
            {guestUserEmail ? t('admin.access-levels.guest') : user.email}
          </TruncatedText>
        </View>
      </UserDataContainer>

      <View>
        {isCurrentUserFacilitator && isParticipantFacilitator && facilitatorIds.length > 1 && (
          <HoveredReveal anchor={containerRef}>
            <IconButton
              size='small'
              variant='secondary'
              tooltip={t('live.remove-as-facilitator')}
              onClick={requestToggleFacilitator}
              iconId={'close'}
            />
          </HoveredReveal>
        )}
        {isCurrentUserFacilitator && !isParticipantFacilitator && (
          <HoveredReveal anchor={containerRef}>
            <IconButton
              size='small'
              variant='secondary'
              tooltip={t('live.make-facilitator')}
              onClick={requestToggleFacilitator}
              iconId={'touch'}
            />
          </HoveredReveal>
        )}
        {iife(() => {
          switch (user.response?.type) {
            case 'question-card':
              return <EvaluationIndicator response={user.response} />
            case 'poll':
              return (
                <HoveredReveal anchor={containerRef}>
                  <HighlightPollResponseButton optionId={user.response.responseId} />
                </HoveredReveal>
              )
            case 'reflection-card':
              return (
                <HoveredReveal anchor={containerRef}>
                  <HighlightReflectionResponseButton reflectionId={user.response.responseId} />
                </HoveredReveal>
              )
            case 'sliding-scale-card':
              return (
                <StyledTruncatedText size='small' color='foreground/secondary' lines={1}>
                  {user.response.response}
                </StyledTruncatedText>
              )
            case undefined:
              return null
            default:
              assertNever(user.response)
          }
        })}
      </View>
    </ListItemContainer>
  )
}

const NumberBlob = styled.div`
  background-color: ${token('foreground/muted')};
  padding: 2px 5px 2px 5px;
  border-radius: 100px;
  font-size: 10px;
  font-weight: 500;
  color: ${token('destructive/foreground')};
  width: fit-content;
  min-width: 16px;
  vertical-align: middle;
  text-align: center;
`

const LabelContainer = styled(View)`
  position: sticky;
  top: ${SEARCH_HEIGHT}px;
  width: 100%;
  background-color: ${token('elevated/background')};
  z-index: 1;

  &::after {
    content: '';
    position: absolute;
    left: 0;
    right: 0;
    bottom: -16px;
    height: 16px;
    background: linear-gradient(to bottom, ${token('elevated/background')}, transparent);
    pointer-events: none;
  }
`

const ParticipantsList: React.FC<{ label: string; emptyText: string; users: LightUserWithResponse[] }> = ({
  label,
  users,
  emptyText,
}) => {
  return (
    <View direction='column'>
      <LabelContainer>
        <Text bold color={'foreground/secondary'}>
          {label}
        </Text>
        <NumberBlob>{users.length}</NumberBlob>
      </LabelContainer>
      {users.length === 0 && <Text color='foreground/muted'>{emptyText}</Text>}
      {users.map(user => (
        <ParticipantListItem key={user.uuid} user={user} />
      ))}
    </View>
  )
}

export const FilteredParticipantsList: React.FC<{
  users: LightUserWithResponse[]
  searchTerm: string
}> = ({ users, searchTerm }) => {
  const filteredUsers = useMemo(
    () =>
      users.filter(
        user =>
          user.firstName.toLowerCase().includes(searchTerm.toLowerCase()) ||
          user.lastName.toLocaleLowerCase().includes(searchTerm.toLowerCase())
      ),
    [searchTerm, users]
  )

  return filteredUsers.map(participant => <ParticipantListItem key={participant.uuid} user={participant} />)
}

export const DefaultParticipantsList: React.FC<{
  users: LightUser[]
  searchTerm: string
}> = ({ users, searchTerm }) => {
  const { t } = useTranslation()
  const facilitatorIds = useSelector(selectFacilitatorIds)
  const facilitators = users.filter(user => facilitatorIds.includes(user.uuid))
  const nonFacilitators = users.filter(user => !facilitatorIds.includes(user.uuid))

  if (searchTerm.trim().length > 0) {
    return <FilteredParticipantsList users={users} searchTerm={searchTerm} />
  }

  return (
    <>
      <ParticipantsList label={t('dictionary.facilitators')} users={facilitators} emptyText={''} />
      <Spacer size='24' />
      <ParticipantsList
        label={t('admin.author.sessions.participants')}
        users={nonFacilitators}
        emptyText={t('sana-now.participant-modal.no-participants-have-joined-yet')}
      />
    </>
  )
}

export const QuestionCardParticipantsList: React.FC<{ users: LightUser[]; searchTerm: string }> = ({
  users,
  searchTerm,
}) => {
  const { t } = useTranslation()
  const facilitatorAnswerSummaryAtom = useAtomValue(currentQuestionFacilitatorSummaryDataAtom)

  const fastestUserResponse = minBy(
    facilitatorAnswerSummaryAtom.filter(it => it.correct),
    it => new Date(it.createdAt).valueOf()
  )

  const usersWithResponses: LightUserWithResponse[] = users.map(user => {
    const response = facilitatorAnswerSummaryAtom.find(it => it.userId === user.uuid)
    return {
      ...user,
      response: response
        ? {
            type: 'question-card',
            responseId: response.questionId,
            correct: response.correct,
            attempts: response.attempts,
            fastestCorrect: response.correct && fastestUserResponse?.userId === response.userId,
          }
        : undefined,
    }
  })

  if (searchTerm.trim().length > 0) {
    return <FilteredParticipantsList users={usersWithResponses} searchTerm={searchTerm} />
  }

  return (
    <>
      <ParticipantsList
        label={t('dictionary.completed')}
        users={usersWithResponses.filter(user => user.response !== undefined)}
        emptyText={t('sana-now.participants.waiting-for-users')}
      />
      <Spacer size='24' />
      <ParticipantsList
        label={t('dictionary.waiting')}
        users={usersWithResponses.filter(user => user.response === undefined)}
        emptyText={t('sana-now.participants.waiting-for-users-done')}
      />
    </>
  )
}

const getPollResponses = ({
  document,
  pollResponses,
}: {
  document: Descendant[]
  pollResponses: PollData
}): { userId: UserId; response?: PollResponse }[] | undefined => {
  const pollCard = document.find(isElementType('poll-card'))
  if (pollCard === undefined) return undefined

  const userResponses = pollResponses.optionResults.flatMap(optionResult => {
    const pollResponse = getPollResponse(pollCard, optionResult.optionId)
    return optionResult.votedByUserIds.map(uuid => ({
      userId: uuid,
      response: {
        type: 'poll',
        responseId: optionResult.optionId,
        response: pollResponse,
      } satisfies PollResponse,
    }))
  })

  return userResponses
}

export const PollParticipantsList: React.FC<{ users: LightUser[]; searchTerm: string }> = ({
  users,
  searchTerm,
}) => {
  const { t } = useTranslation()
  const pollResponses = useAtomValue(currentPollDataAtom)

  const currentCard = useSelectCurrentCard()
  const { yDoc } = useFlexibleContentYDoc()
  const userResponses =
    currentCard !== undefined && pollResponses !== undefined
      ? getPollResponses({ document: getSlateDocument(yDoc, currentCard.id), pollResponses })
      : undefined

  const usersWithResponses: LightUserWithResponse[] = users.map(user => {
    const response = userResponses?.find(it => it.userId === user.uuid)
    return {
      ...user,
      response: response?.response,
    }
  })

  if (searchTerm.trim().length > 0) {
    return <FilteredParticipantsList users={usersWithResponses} searchTerm={searchTerm} />
  }

  return (
    <>
      <ParticipantsList
        label={t('dictionary.completed')}
        users={usersWithResponses.filter(user => user.response !== undefined)}
        emptyText={t('sana-now.participants.waiting-for-users')}
      />
      <Spacer size='24' />
      <ParticipantsList
        label={t('dictionary.waiting')}
        users={usersWithResponses.filter(user => user.response === undefined)}
        emptyText={t('sana-now.participants.waiting-for-users-done')}
      />
    </>
  )
}

const getReflectionResponses = ({
  document,
  reflectionResponseSummary,
}: {
  document: Descendant[]
  reflectionResponseSummary: ReflectionResponseSummaryData[]
}): { userId: string; response: ReflectionCardResponse }[] | undefined => {
  const reflectionCard = document.find(isElementType('reflection-card'))

  if (reflectionCard === undefined) return undefined

  return reflectionResponseSummary.map(response => ({
    userId: response.userId,
    response: {
      type: 'reflection-card',
      responseId: response.responseId,
      response: response.response ?? '',
    } satisfies ReflectionCardResponse,
  }))
}

export const ReflectionCardParticipantsList: React.FC<{ users: LightUser[]; searchTerm: string }> = ({
  users,
  searchTerm,
}) => {
  const { t } = useTranslation()
  const reflectionResponseSummary = useAtomValue(currentReflectionSummaryDataAtom)

  const currentCard = useSelectCurrentCard()
  const { yDoc } = useFlexibleContentYDoc()

  const userResponses = iife(() => {
    if (currentCard !== undefined) {
      const document = getSlateDocument(yDoc, currentCard.id)
      return getReflectionResponses({ document, reflectionResponseSummary })
    }
    return undefined
  })

  const usersWithResponses: LightUserWithResponse[] = users.map(user => {
    const response = userResponses?.find(it => it.userId === user.uuid)
    return {
      ...user,
      response: response?.response,
    }
  })

  if (searchTerm.trim().length > 0) {
    return <FilteredParticipantsList users={usersWithResponses} searchTerm={searchTerm} />
  }

  return (
    <>
      <ParticipantsList
        label={t('dictionary.completed')}
        users={usersWithResponses.filter(user => user.response !== undefined)}
        emptyText={t('sana-now.participants.waiting-for-users')}
      />
      <Spacer size='24' />
      <ParticipantsList
        label={t('dictionary.waiting')}
        users={usersWithResponses.filter(user => user.response === undefined)}
        emptyText={t('sana-now.participants.waiting-for-users-done')}
      />
    </>
  )
}

export const SlidingScaleParticipantsList: React.FC<{ users: LightUser[]; searchTerm: string }> = ({
  users,
  searchTerm,
}) => {
  const { t } = useTranslation()
  const realTimeSlidingScaleResponses = useAtomValue(currentSlidingScaleDataAtom)

  const userResponses =
    realTimeSlidingScaleResponses?.responses.map(response => ({
      userId: response.uuid,
      response: {
        type: 'sliding-scale-card',
        responseId: response.id,
        response: `${response.position}%`,
      } satisfies SlidingScaleResponse,
    })) ?? []

  const usersWithResponses: LightUserWithResponse[] = users.map(user => {
    const response = userResponses.find(it => it.userId === user.uuid)

    return {
      ...user,
      response: response?.response,
    }
  })

  if (searchTerm.trim().length > 0) {
    return <FilteredParticipantsList users={usersWithResponses} searchTerm={searchTerm} />
  }

  return (
    <>
      <ParticipantsList
        label={t('dictionary.completed')}
        users={usersWithResponses.filter(user => user.response !== undefined)}
        emptyText={t('sana-now.participants.waiting-for-users')}
      />
      <Spacer size='24' />
      <ParticipantsList
        label={t('dictionary.waiting')}
        users={usersWithResponses.filter(user => user.response === undefined)}
        emptyText={t('sana-now.participants.waiting-for-users-done')}
      />
    </>
  )
}
