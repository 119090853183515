import React, { useCallback, useEffect, useMemo, useState } from 'react'
import { IconMenu } from 'sierra-client/components/common/icon-menu'
import {
  HeaderButtonColorCSS,
  HeaderButtonGroupWrapper,
  HeaderGroupButtonCSS,
  HeaderGroupFirstButtonCSS,
  HeaderGroupIconButton,
} from 'sierra-client/components/liveV2/header-buttons'
import { EMBED_HELP_PAGE_URL } from 'sierra-client/config/links'
import { useTranslation } from 'sierra-client/hooks/use-translation'
import { useCreatePageContext } from 'sierra-client/views/flexible-content/create-page-context'
import {
  getEmbeddableUrl,
  getIsPlainEmbed,
  isUrlMatchingUrlType,
} from 'sierra-client/views/flexible-content/embed-card-utils'
import { apply } from 'sierra-domain/editor/operations'
import { EmbedData, File } from 'sierra-domain/flexible-content/types'
import { getUrlFromText } from 'sierra-domain/utils'
import { FormElement, MenuItem, Tooltip } from 'sierra-ui/components'
import { InputPrimitive } from 'sierra-ui/primitives'
import styled from 'styled-components'

const StyledIconMenu = styled(IconMenu)<{ $single: boolean }>`
  cursor: pointer;
  padding: 0 0.375rem;

  ${HeaderButtonColorCSS}

  ${p => (p.$single ? HeaderGroupButtonCSS : HeaderGroupFirstButtonCSS)}
`

export const EmbedCardToolbar: React.FC<{ file: File }> = ({ file }) => {
  const fileId = file.id
  const { operationState } = useCreatePageContext()
  const isLegacyEmbed = file.data.type === 'embed' && file.data.embedType === 'legacy' ? true : false
  const [switchChecked, toggleSwitchChecked] = useState(isLegacyEmbed)
  const url = file.data.type === 'embed' ? file.data.url : undefined
  const [textValue, setTextValue] = useState('')
  const handleTextChange = (e: React.ChangeEvent<HTMLInputElement>): void => {
    setTextValue(e.target.value)
  }
  const urlType = file.data.type === 'embed' ? file.data.urlType : undefined
  const { t } = useTranslation()

  const updateEmbedType = useCallback(
    (embedType: EmbedData['embedType']): void => {
      apply(operationState, {
        type: 'update-files',
        fileIds: [fileId],
        update: file => {
          if (file.data.type !== 'embed') throw Error(`File ${fileId} is not an embed`)
          file.data.embedType = embedType
        },
      })
    },
    [fileId, operationState]
  )

  const updateEmbedUrl = useCallback(
    async (input: string): Promise<void> => {
      const urlFromText = getUrlFromText(input)
      const url = urlFromText !== undefined ? getEmbeddableUrl(urlFromText, urlType) : ''
      const embedType = (await getIsPlainEmbed(url, urlType)) ? 'plain' : 'legacy'
      if (isUrlMatchingUrlType(url, urlType)) {
        apply(operationState, {
          type: 'update-files',
          fileIds: [fileId],
          update: file => {
            if (file.data.type !== 'embed') throw Error(`File ${fileId} is not an embed`)
            file.data.url = url
            file.data.embedType = embedType
          },
        })
      } else {
        apply(operationState, {
          type: 'update-files',
          fileIds: [fileId],
          update: file => {
            if (file.data.type !== 'embed') throw Error(`File ${fileId} is not an embed`)
            file.data.url = ''
            file.data.embedType = embedType
          },
        })
      }
    },
    [urlType, operationState, fileId]
  )

  const removeEmbedUrl = useCallback((): void => {
    apply(operationState, {
      type: 'update-files',
      fileIds: [fileId],
      update: file => {
        if (file.data.type !== 'embed') throw Error(`File ${fileId} is not an embed`)
        file.data.url = undefined
        file.data.embedType = undefined
      },
    })
  }, [operationState, fileId])

  useEffect(() => {
    void (() => {
      if (url !== undefined) {
        setTextValue(url)
        if (isLegacyEmbed) {
          toggleSwitchChecked(true)
        } else {
          toggleSwitchChecked(false)
        }
      }
      if (url === undefined) {
        setTextValue('')
      }
    })()
  }, [url, isLegacyEmbed])

  const isGeneralOrDriveEmbed = urlType === undefined || urlType === 'drive'

  const iconMenuItems: MenuItem[] = useMemo(
    () => [
      {
        id: 'urlinput',
        type: 'canvas',
        preventFocusOnHover: true,
        render() {
          return (
            <FormElement label={t('author.embed-card.embed-url')}>
              <InputPrimitive
                id='url'
                type='text'
                placeholder='https://'
                value={textValue}
                onChange={handleTextChange}
                onKeyDown={event => {
                  if (event.key === 'Enter') {
                    void updateEmbedUrl(textValue)
                    event.preventDefault()
                    return
                  }
                }}
              />
            </FormElement>
          )
        },
      },
      {
        id: 'toggleembed',
        type: 'switch',
        label: 'Use rich embed',
        hidden: url === undefined || !isGeneralOrDriveEmbed,
        onToggleChange: () => {
          if (switchChecked) {
            toggleSwitchChecked(false)
            updateEmbedType('plain')
          } else {
            toggleSwitchChecked(true)
            updateEmbedType('legacy')
          }
        },
        checked: switchChecked,
      },
      {
        id: 'remove',
        type: 'label',
        label: 'Remove',
        hidden: url === undefined,
        onClick: () => {
          removeEmbedUrl()
        },
      },
    ],
    [url, isGeneralOrDriveEmbed, switchChecked, t, textValue, updateEmbedUrl, updateEmbedType, removeEmbedUrl]
  )

  return (
    <HeaderButtonGroupWrapper gap='none'>
      <StyledIconMenu $single={urlType !== undefined} iconId='settings' items={iconMenuItems} withChevron />
      {urlType === undefined && (
        <Tooltip title='See embed tutorial'>
          <HeaderGroupIconButton
            onClick={() => {
              window.open(EMBED_HELP_PAGE_URL, '_blank')
            }}
            iconId='help'
          />
        </Tooltip>
      )}
    </HeaderButtonGroupWrapper>
  )
}
