import DOMPurify from 'dompurify'
import { getAssetContextFromEditableContent } from 'sierra-client/components/util/asset-contex'
import {
  MetadataWithIcon,
  NotificationContainer,
  Title,
  UserAvatar,
  useUsername,
} from 'sierra-client/features/activity-center/notifications/components'
import { PlainEntityImage } from 'sierra-client/features/activity-center/notifications/components/entity-image'
import { NotificationTitle } from 'sierra-client/features/activity-center/notifications/components/notification-title'
import { useResolveAsset } from 'sierra-client/hooks/use-resolve-asset'
import { buildDeeplink } from 'sierra-client/views/commenting/utils'
import { editUrl, getScopedCreateIdFromContent } from 'sierra-domain/api/editable-content'
import { HomeNotification } from 'sierra-domain/api/learn'
import { FileId } from 'sierra-domain/flexible-content/identifiers'
import { TruncatedText } from 'sierra-ui/components'
import { View } from 'sierra-ui/primitives'
import { token } from 'sierra-ui/theming'
import styled from 'styled-components'

const CommentBoxContainer = styled(View)`
  border-radius: 16px;
  padding: 12px;
  background-color: ${token('surface/soft')};
`
export const CommentNotification: React.FC<{
  notification: Extract<HomeNotification, { type: 'user-mentioned-in-comment' | 'user-thread-responded-to' }>
}> = ({ notification }) => {
  const { editable, reference } = notification
  const fileId =
    reference?.unitId !== undefined && FileId.safeParse(reference.unitId).success
      ? FileId.parse(reference.unitId)
      : undefined

  const href =
    fileId !== undefined
      ? buildDeeplink({
          contentId: getScopedCreateIdFromContent(editable),
          unitId: fileId,
          messageId: notification.messageId,
        })
      : editUrl(editable.type, editable.id, fileId)

  const username = useUsername(notification.authorUserId)

  const assetContext = getAssetContextFromEditableContent(editable)
  const imgSrc = useResolveAsset({
    image: editable.image,
    size: 'default',
    assetContext,
  })

  const unitId = reference?.unitId

  return (
    <NotificationContainer
      notification={notification}
      icon={<UserAvatar userId={notification.authorUserId} />}
      cardUrl={href}
      title={
        <NotificationTitle
          untranslated={`{{username}} <subtitle>${
            notification.type === 'user-thread-responded-to'
              ? 'responded to your comment'
              : 'mentioned you in a comment'
          }</subtitle>`}
          values={{
            username,
          }}
        />
      }
      content={
        <>
          <PlainEntityImage src={imgSrc} />
          <View direction='column' gap='4'>
            <Title size='regular' bold>
              {editable.title}
            </Title>
            <MetadataWithIcon type='course' subtitle={unitId !== undefined ? `on ${unitId}` : undefined} />

            <CommentBoxContainer>
              <TruncatedText lines={2} size='small' color='foreground/secondary'>
                {DOMPurify.sanitize(notification.message, { ALLOWED_TAGS: [] })}
              </TruncatedText>
            </CommentBoxContainer>
          </View>
        </>
      }
    />
  )
}
