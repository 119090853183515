import { MentionOptions } from '@tiptap/extension-mention'
import { ReactRenderer } from '@tiptap/react'
import { SuggestionProps as TipTapSuggestionProps } from '@tiptap/suggestion'
import { useEffect, useMemo } from 'react'
import {
  MentionList,
  MentionListProps,
  MentionListRef,
} from 'sierra-client/components/chat/mention/mention-list'
import * as settingsActions from 'sierra-client/state/author-course-settings/actions'
import { selectAvailableCollaborators } from 'sierra-client/state/author-course-settings/selectors'
import { Collaborator } from 'sierra-client/state/author-course-settings/types'
import { useDispatch, useSelector } from 'sierra-client/state/hooks'
import { useSlashMenuCreatePageContext } from 'sierra-client/views/flexible-content/create-page-context'
import { CourseId } from 'sierra-domain/api/nano-id'
import tippy, { Instance } from 'tippy.js'

// This solves a type issue introduced with an update of tiptap. It handles a null case which
// tippy could not handle.
// New type: (() => DOMRect | null) | null
// Is converted to old type: (() => DOMRect) | null
const handleNullReturnFromTipTapClientRect = (
  clientRect: TipTapSuggestionProps['clientRect']
): (() => DOMRect) | null => {
  if (clientRect !== null && clientRect !== undefined) {
    const rect = clientRect()
    if (rect === null) return null
    return () => rect
  }
  return null
}

type CustomMentionOptions = MentionOptions<Collaborator, { collaborator: Collaborator }>
type CustomSuggestion = CustomMentionOptions['suggestion']

export const useMentionSuggestion: () => CustomSuggestion | undefined = () => {
  const dispatch = useDispatch()
  const collaborators = useSelector(selectAvailableCollaborators)
  const createPageContext = useSlashMenuCreatePageContext()

  const courseIdResult = CourseId.safeParse(createPageContext?.createContentId)
  const courseId = courseIdResult.success ? courseIdResult.data : undefined

  useEffect(() => {
    if (courseId !== undefined) void dispatch(settingsActions.fetch({ courseId }))
  }, [dispatch, courseId])

  const suggestion = useMemo(
    (): CustomSuggestion => ({
      items: ({ query }) => {
        if (collaborators === 'loading') return []

        return collaborators
          .filter(collaborator =>
            `${collaborator.firstName} ${collaborator.lastName}`.toLowerCase().includes(query.toLowerCase())
          )
          .sort(({ role }) => {
            if (role === 'editor') return -1
            if (role === 'commenter') return 0
            return 1
          })
          .slice(0, 8)
      },

      command: ({ editor, range, props }) => {
        // increase range.to by one when the next node is of type "text"
        // and starts with a space character
        const nodeAfter = editor.view.state.selection.$to.nodeAfter
        const overrideSpace = nodeAfter?.text?.startsWith(' ')
        if (overrideSpace === true) range.to += 1

        const name = `${props.collaborator.firstName} ${props.collaborator.lastName}`

        editor
          .chain()
          .focus()
          .insertContentAt(range, [
            {
              type: 'mention',
              attrs: {
                uuid: props.collaborator.uuid,
                label: name,
                role: props.collaborator.role,
              },
            },
            { type: 'text', text: ' ' },
          ])
          .run()
      },

      render: () => {
        let component: ReactRenderer<MentionListRef, MentionListProps>
        let popup: Instance[]

        return {
          onStart: (props): void => {
            component = new ReactRenderer(MentionList, {
              props,
              editor: props.editor,
            })

            popup = tippy('body', {
              getReferenceClientRect: handleNullReturnFromTipTapClientRect(props.clientRect),
              appendTo: () => document.body,
              content: component.element,
              showOnCreate: true,
              interactive: true,
              trigger: 'manual',
              animation: 'fade',
              placement: 'bottom-start',
            })
          },

          onUpdate: (props): void => {
            component.updateProps(props)

            popup[0]?.setProps({
              getReferenceClientRect: handleNullReturnFromTipTapClientRect(props.clientRect),
            })
          },

          onKeyDown: (props): boolean => {
            if (props.event.key === 'Escape') {
              popup[0]?.hide()

              return true
            }

            if (component.ref === null) return false

            return component.ref.onKeyDown(props)
          },

          onExit: (): void => {
            popup[0]?.destroy()
            component.destroy()
          },
        }
      },
    }),
    [collaborators]
  )

  if (courseId === undefined) return undefined
  return suggestion
}
