import { createSlice, PayloadAction } from '@reduxjs/toolkit'
import { deepMergeJson, deepPatchJson } from '@sanalabs/json'
import { keyBy } from 'lodash'
import { DateTime } from 'luxon'
import { clearState } from 'sierra-client/state/actions'
import {
  addLiveQuizAnswer,
  liveSessionAwarenessStatesChanged,
  liveSessionCleared,
  liveSessionClearInSessionLocalAwarenessData,
  liveSessionDataChanged,
  liveSessionFacilitatorFlip,
  liveSessionGroupReflectionResponsesToggle,
  liveSessionLocalAwarenessStateMerged,
  liveSessionSetShowReflectionCardSummary,
  liveSessionStateChanged,
  recordingEnded,
  recordingStarted,
  setLiveQuizState,
  setLiveQuizUser,
  startBreakoutSession,
  startTimer,
} from 'sierra-client/state/live-session/actions'
import {
  CustomAwarenessData,
  LiveSessionState,
  LiveSessionStateDataSynced,
} from 'sierra-client/state/live-session/types'
import * as breakoutUtils from 'sierra-client/state/live-session/utils'
import { NanoId12 } from 'sierra-domain/api/nano-id'
import { UserId } from 'sierra-domain/api/uuid'
import { FileId } from 'sierra-domain/flexible-content/identifiers'
import { BreakoutRoom, LiveQuiz, LiveSessionData, UserControlEventAction } from 'sierra-domain/live-session'
import { nanoid12 } from 'sierra-domain/nanoid-extensions'

const initialState: LiveSessionState = {
  localAwarenessState: {},
  data: undefined,
  awareness: [],
}

function assertDataSynced(state: LiveSessionState): asserts state is LiveSessionStateDataSynced {
  if (state.data === undefined)
    throw new Error(`AssertionError: Expected data to be defined in ${JSON.stringify(state)}`)
}

const initialLiveQuizState: Omit<LiveQuiz, 'id'> = {
  status: { step: 'lobby' },
  users: {},
  questions: {},
}

export const liveSessionSlice = createSlice({
  name: 'liveSession',
  initialState,
  reducers: {
    setCurrentCardId(state, action: PayloadAction<FileId>) {
      assertDataSynced(state)
      state.data.currentCardId = action.payload
    },
    updateBreakoutSession(state, action: PayloadAction<BreakoutRoom[]>) {
      assertDataSynced(state)
      if (state.data.breakoutSession) {
        state.data.breakoutSession.breakoutRooms = keyBy(action.payload, 'id')
      }
    },
    addBreakoutRoom(state, action: PayloadAction<{ name: string } | undefined>) {
      assertDataSynced(state)
      if (state.data.breakoutSession) {
        const oldRooms = Object.values(state.data.breakoutSession.breakoutRooms)
        state.data.breakoutSession.breakoutRooms = state.data.breakoutSession.breakoutRooms = keyBy(
          breakoutUtils.addNewBreakoutRoom(oldRooms, action.payload?.name),
          'id'
        )
      }
    },
    renameBreakoutRoom(state, action: PayloadAction<{ roomId: NanoId12; newName: string }>) {
      assertDataSynced(state)
      if (state.data.breakoutSession) {
        const oldRooms = Object.values(state.data.breakoutSession.breakoutRooms)
        state.data.breakoutSession.breakoutRooms = state.data.breakoutSession.breakoutRooms = keyBy(
          breakoutUtils.renameBreakoutRoom(oldRooms, action.payload.roomId, action.payload.newName),
          'id'
        )
      }
    },
    removeUserBreakoutRoomAssignment(state, action: PayloadAction<{ userId: UserId }>) {
      assertDataSynced(state)
      if (state.data.breakoutSession) {
        const oldRooms = Object.values(state.data.breakoutSession.breakoutRooms)
        state.data.breakoutSession.breakoutRooms = keyBy(
          breakoutUtils.unassignParticipant(oldRooms, action.payload.userId),
          'id'
        )
      }
    },
    stopBreakoutSession(state) {
      assertDataSynced(state)
      state.data.videoCallMode = 'main'
    },
    toggleRaiseHand(state) {
      if (state.localAwarenessState.timeStartedRaisingHand !== undefined) {
        delete state.localAwarenessState.timeStartedRaisingHand
      } else {
        state.localAwarenessState.timeStartedRaisingHand = new Date().toISOString()
      }
    },
    removeRaisedHand(state) {
      if (state.localAwarenessState.timeStartedRaisingHand !== undefined) {
        delete state.localAwarenessState.timeStartedRaisingHand
      }
    },
    userWasActivlyEngaged(state) {
      const lastEngaged = state.localAwarenessState.timeUserWasEngaged
      if (lastEngaged === undefined) {
        state.localAwarenessState.timeUserWasEngaged = new Date().toISOString()
      } else if (DateTime.fromISO(lastEngaged).diffNow().as('seconds') < -5) {
        state.localAwarenessState.timeUserWasEngaged = new Date().toISOString()
      }
    },
    consumeControlEvent(state, action: PayloadAction<{ eventId: string; userId: string }>) {
      assertDataSynced(state)
      const {
        payload: { eventId, userId },
      } = action

      const userEventQueue = state.data.userControlEventsMap?.[userId] ?? []
      if (userEventQueue.length === 0) return

      const eventIndex = userEventQueue.findIndex(event => event.id === eventId)

      userEventQueue.splice(eventIndex, 1)
    },
    publishControlEvent(
      state,
      action: PayloadAction<{ userId: string; userAction: UserControlEventAction }>
    ) {
      assertDataSynced(state)
      const {
        payload: { userAction, userId },
      } = action

      if (state.data.userControlEventsMap === undefined) {
        state.data.userControlEventsMap = {}
      }

      const userEventQueue = state.data.userControlEventsMap[userId] ?? []

      userEventQueue.push({
        id: nanoid12(),
        action: userAction,
      })

      state.data.userControlEventsMap[userId] = userEventQueue
    },
    resetTimer(state) {
      assertDataSynced(state)
      state.data.timer = undefined
    },
    pinParticipant(state, action: PayloadAction<{ userId: UserId }>) {
      assertDataSynced(state)
      if (state.data.pinnedParticipants === undefined) {
        state.data.pinnedParticipants = {}
      }
      state.data.pinnedParticipants[action.payload.userId] = { userId: action.payload.userId }
    },
    unpinParticipant(state, action: PayloadAction<{ userId: UserId }>) {
      assertDataSynced(state)
      if (state.data.pinnedParticipants !== undefined) {
        delete state.data.pinnedParticipants[action.payload.userId]
      }
    },
    unpinAllParticipants(state) {
      assertDataSynced(state)
      if (state.data.pinnedParticipants !== undefined) {
        delete state.data.pinnedParticipants
      }
    },
  },
  extraReducers: builder => {
    builder.addCase(clearState, () => initialState)

    builder.addCase(liveSessionCleared, state => {
      state.data = undefined
      state.awareness = []
    })

    builder.addCase(liveSessionStateChanged, (state, { payload }) => {
      if (state.data !== undefined) {
        state.data.sessionState = payload
      }
    })

    builder.addCase(liveSessionDataChanged, (state, { payload }) => {
      const res = LiveSessionData.parse(payload)
      if (state.data === undefined) {
        state.data = res
      } else {
        deepPatchJson(state.data, res)
      }
    })

    builder.addCase(liveSessionFacilitatorFlip, (state, { payload }) => {
      assertDataSynced(state)
      let flipCards = state.data.flipCards
      if (flipCards === undefined) {
        const intitialFlipCards = {}
        state.data.flipCards = intitialFlipCards
        flipCards = intitialFlipCards
      }
      flipCards[payload.cardId] = { flip: payload.flip }
    })

    builder.addCase(liveSessionGroupReflectionResponsesToggle, (state, { payload }) => {
      assertDataSynced(state)
      let reflectionCards = state.data.reflectionCards
      if (reflectionCards === undefined) {
        const intitialReflectionCards = {}
        state.data.reflectionCards = intitialReflectionCards
        reflectionCards = intitialReflectionCards
      }
      reflectionCards[payload.reflectionId] = { groupBy: payload.groupBy }
    })

    builder.addCase(liveSessionSetShowReflectionCardSummary, (state, { payload }) => {
      assertDataSynced(state)
      let reflectionCards = state.data.reflectionCards
      if (reflectionCards === undefined) {
        const intitialReflectionCards = {}
        state.data.reflectionCards = intitialReflectionCards
        reflectionCards = intitialReflectionCards
      }
      const prevState = reflectionCards[payload.reflectionId]
      reflectionCards[payload.reflectionId] = {
        ...prevState,
        groupBy: prevState?.groupBy ?? 'individual',
        showSummary: payload.showSummary,
      }
    })

    builder.addCase(startBreakoutSession.fulfilled, (state, { payload }) => {
      assertDataSynced(state)
      state.data.videoCallMode = 'breakout'
      state.data.breakoutSession = payload
    })

    builder.addCase(startTimer.fulfilled, (state, { payload }) => {
      assertDataSynced(state)
      const { endTime } = payload
      state.data.timer = { endTime }
    })

    builder.addCase(recordingStarted.fulfilled, (state, { payload: { id, startTime, userId } }) => {
      assertDataSynced(state)

      if (state.data.recordings === undefined) {
        state.data.recordings = {}
      }
      state.data.recordings[id] = { id, startTime, userId }
    })

    builder.addCase(recordingEnded.fulfilled, (state, { payload: { id } }) => {
      assertDataSynced(state)

      if (state.data.recordings === undefined) {
        state.data.recordings = {}
      }
      const recording = state.data.recordings[id]
      if (recording !== undefined) {
        state.data.recordings[id] = {
          ...recording,
          id,
        }
      }
    })

    builder.addCase(liveSessionAwarenessStatesChanged, (state, { payload }) => {
      payload.forEach(state => CustomAwarenessData.parse(state))
      deepPatchJson(state.awareness, payload)
    })

    builder.addCase(liveSessionLocalAwarenessStateMerged, (state, { payload }) => {
      deepMergeJson(state.localAwarenessState, payload)
    })

    builder.addCase(liveSessionClearInSessionLocalAwarenessData, state => {
      deepMergeJson(state.localAwarenessState, {
        timeStartedRaisingHand: undefined,
        timeUserWasEngaged: undefined,
        followMeEnabled: undefined,
        isFollowingFollowMe: undefined,
        scrollAtSlateNodeElementId: undefined,
        followVideoState: undefined,
      })
    })

    builder.addCase(setLiveQuizState, (state, { payload }) => {
      assertDataSynced(state)

      let liveQuiz = state.data.liveQuiz

      if (liveQuiz === undefined) {
        const quiz = {}
        state.data.liveQuiz = quiz
        liveQuiz = quiz
      }

      const ext = liveQuiz[payload.fileId]

      if (ext !== undefined) {
        liveQuiz[payload.fileId] = {
          ...ext,
          status: payload.status,
        }
      } else {
        liveQuiz[payload.fileId] = {
          ...initialLiveQuizState,
          status: payload.status,
          id: payload.fileId,
        }
      }
    })

    builder.addCase(addLiveQuizAnswer, (state, { payload }) => {
      assertDataSynced(state)

      let liveQuiz = state.data.liveQuiz

      if (liveQuiz === undefined) {
        const quiz = {}
        state.data.liveQuiz = quiz
        liveQuiz = quiz
      }

      const quiz = liveQuiz[payload.fileId]

      const newQuiz: LiveQuiz =
        quiz !== undefined
          ? {
              ...quiz,
              questions: {
                ...quiz.questions,
                [payload.questionId]: {
                  answers: {
                    ...quiz.questions[payload.questionId]?.answers,
                    [payload.userId]: {
                      alternativeId: payload.alternativeId,
                    },
                  },
                },
              },
            }
          : {
              id: payload.fileId,
              ...initialLiveQuizState,
              questions: {
                [payload.questionId]: {
                  answers: {
                    [payload.userId]: {
                      alternativeId: payload.alternativeId,
                    },
                  },
                },
              },
            }

      liveQuiz[payload.fileId] = newQuiz
    })

    builder.addCase(setLiveQuizUser, (state, { payload }) => {
      assertDataSynced(state)

      let liveQuiz = state.data.liveQuiz

      if (liveQuiz === undefined) {
        const quiz = {}
        state.data.liveQuiz = quiz
        liveQuiz = quiz
      }

      const quiz = liveQuiz[payload.fileId]

      const newQuiz: LiveQuiz =
        quiz !== undefined
          ? {
              ...quiz,
              users: {
                ...quiz.users,
                [payload.user.userId]: payload.user,
              },
            }
          : {
              id: payload.fileId,
              ...initialLiveQuizState,
              users: {
                [payload.user.userId]: payload.user,
              },
            }

      liveQuiz[payload.fileId] = newQuiz
    })
  },
})
