import { TypedEventEmitter } from 'sierra-client/lib/typed-event-emitter'
import { asNonNullable } from 'sierra-domain/utils'

type Task = (...args: any[]) => Promise<void>

export class SequentialTaskRunner extends TypedEventEmitter<{
  drained: () => void
}> {
  private queue: Task[] = []
  private isRunning = false

  constructor(private maxOldTasks?: number) {
    super()
  }

  push(task: Task): void {
    this.queue.push(task)
    if (this.maxOldTasks !== undefined && this.queue.length > this.maxOldTasks) this.queue.shift()

    void this.runNextTask()
  }

  getIsRunning(): boolean {
    return this.isRunning
  }

  async drain(): Promise<void> {
    if (this.queue.length === 0) return

    return new Promise(resolve => {
      this.once('drained', resolve)
    })
  }

  private async runNextTask(): Promise<void> {
    if (this.isRunning) return

    if (this.queue.length === 0) {
      this.emit('drained')
      return
    }

    try {
      this.isRunning = true
      const task = asNonNullable(this.queue.shift())
      await task()
    } finally {
      this.isRunning = false
      void this.runNextTask()
    }
  }
}
