import { useSelector } from 'sierra-client/state/hooks'
import { LiveIssueInformation } from 'sierra-client/state/live/live-issue-information'
import {
  selectCameraPermission,
  selectMicrophonePermission,
  selectRecoverableIssues,
} from 'sierra-client/state/live/selectors'
import { isNonEmptyArray } from 'sierra-domain/utils'

export type LiveHardwarePermission =
  | { type: 'not-requested' }
  // Microphone and camera access are allowed
  | { type: 'granted' }
  // The user is currently being asked by the browse to allow access to camera and microphone
  | { type: 'prompt' }
  // There was an issue with reaching the user's camera or microphone
  | { type: 'error'; errors: [LiveIssueInformation, ...LiveIssueInformation[]] }
  // The user has denied access to the camera and microphone
  | { type: 'denied-all-permissions' }

export function useHardwarePermissionsState(): LiveHardwarePermission {
  const cameraPermission = useSelector(selectCameraPermission)
  const microphonePermission = useSelector(selectMicrophonePermission)
  const audioVideoIssues = useSelector(selectRecoverableIssues).filter(
    it => it.category === 'audio' || it.category === 'video'
  )

  if (isNonEmptyArray(audioVideoIssues)) {
    const cameraDenied = audioVideoIssues.some(it => it.name === 'camera-permission-denied')
    const microphoneDenied = audioVideoIssues.some(it => it.name === 'microphone-permission-denied')

    if (cameraDenied && microphoneDenied) return { type: 'denied-all-permissions' }
    else return { type: 'error', errors: audioVideoIssues }
  } else {
    if (cameraPermission === undefined || microphonePermission === undefined) {
      return { type: 'not-requested' }
    }
    if (cameraPermission === 'not-requested' || microphonePermission === 'not-requested') {
      return { type: 'not-requested' }
    }
    if (cameraPermission === 'allowed' || microphonePermission === 'allowed') return { type: 'granted' }
    return { type: 'prompt' }
  }
}
