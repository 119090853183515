import _ from 'lodash'
import { DateTime } from 'luxon'
import { useCallback, useMemo } from 'react'
import { useDeepEqualityMemo } from 'sierra-client/hooks/use-deep-equality-memo'
import { useTranslation } from 'sierra-client/hooks/use-translation'
import { TranslationLookup } from 'sierra-client/hooks/use-translation/types'

const SEVEN_DAYS_MS = 7 * 24 * 60 * 60 * 1000

const getTimeInMinutes = (timeInSeconds: number): number => {
  return Math.round(timeInSeconds / 60)
}

/** If the input does not extend undefined we don't include undefined in the output. */
export function useGetFormattedTime(timeInSeconds: number, remaining: boolean): string
export function useGetFormattedTime(timeInSeconds: undefined, remaining: boolean): undefined
export function useGetFormattedTime(timeInSeconds: number | undefined, remaining: boolean): string | undefined
export function useGetFormattedTime(
  timeInSeconds: number | undefined,
  remaining: boolean
): string | undefined {
  const { t } = useTranslation()
  if (timeInSeconds === undefined) return undefined
  if (timeInSeconds === 0) return ''
  const timeInMinutes = timeInSeconds < 60 ? 1 : getTimeInMinutes(timeInSeconds)

  const hours = Math.floor(timeInMinutes / 60)
  const minutes = timeInMinutes % 60

  return _.compact([
    timeInSeconds < 60 && '<',
    hours && t('time.hours', { count: hours }),
    minutes && t('time.minutes', { number: minutes }),
    remaining && t('time.remaining'),
  ]).join(' ')
}

export const useGetTimeSpent = (
  timeInMinutes?: number
): { formatTimeSpent: (timeInMinutes?: number) => string; value: string } => {
  const { t } = useTranslation()
  const formatTimeSpent = useCallback(
    (tim?: number): string => {
      if (tim === undefined) return ''
      if (tim === 0) return t('time.minutes', { number: 0 })

      const days = Math.floor(tim / 1440)
      const hours = Math.floor((tim % 1440) / 60)
      const minutes = tim % 60

      return _.compact([
        days && t('time.days', { count: days }),
        hours && t('time.hours', { count: hours }),
        minutes && (minutes < 1 ? '<' : '' + t('time.minutes', { number: minutes })),
      ]).join(' ')
    },
    [t]
  )

  return {
    formatTimeSpent,
    value: formatTimeSpent(timeInMinutes),
  }
}

export type UseTimerFormatter = {
  timeFormatter: (timeInSeconds: number | undefined, remaining?: boolean) => string | undefined
}

export const useTimeFormatter = (): UseTimerFormatter => {
  const { t } = useTranslation()

  const timeFormatter = useCallback<UseTimerFormatter['timeFormatter']>(
    (timeInSeconds, remaining) => {
      if (timeInSeconds === undefined) return undefined
      const timeInMinutes = timeInSeconds < 60 ? 1 : getTimeInMinutes(timeInSeconds)

      const hours = Math.floor(timeInMinutes / 60)
      const minutes = timeInMinutes % 60

      return _.compact([
        timeInSeconds < 60 && '<',
        hours && t('time.hours', { count: hours }),
        minutes && t('time.minutes', { number: minutes }),
        remaining === true && t('time.remaining'),
      ]).join(' ')
    },
    [t]
  )

  return {
    timeFormatter,
  }
}

export const percentage = (
  fraction: number | null | undefined,
  decimals = 0,
  appendPercent: boolean = true
): string => {
  const percentChar = appendPercent ? '%' : ''
  if (decimals === 0) {
    return `${Math.floor((fraction ?? 0) * 100).toFixed(decimals)}${percentChar}`
  }

  return `${((fraction ?? 0) * 100).toFixed(decimals)}${percentChar}`
}

export const date = (input: string | Date): string =>
  typeof input === 'string' ? DateTime.fromISO(input).toISODate() : input.toDateString()

export const float = (input: string | number, decimals = 1): string => {
  return Number(input).toFixed(decimals)
}

type GenericDate = string | DateTime | Date

function getAsDateTime(date: undefined): undefined
function getAsDateTime(date: Date): DateTime
function getAsDateTime(date: GenericDate): DateTime
function getAsDateTime(date: GenericDate | undefined): DateTime | undefined
function getAsDateTime(date: GenericDate | undefined | Date): DateTime | undefined {
  if (date === undefined) {
    return undefined
  } else if (typeof date === 'string') {
    return DateTime.fromISO(date)
  } else if (date instanceof Date) {
    return DateTime.fromJSDate(date)
  } else {
    return date
  }
}

function getAsDate(date: undefined): undefined
function getAsDate(date: Date): Date
function getAsDate(date: GenericDate): Date
function getAsDate(date: GenericDate | undefined): Date | undefined
function getAsDate(date: GenericDate | undefined | Date): Date | undefined {
  if (date === undefined) {
    return undefined
  } else if (typeof date === 'string') {
    return new Date(date)
  } else if (date instanceof Date) {
    return date
  } else {
    return date.toJSDate()
  }
}

export const getDaysDiff = (toDt: DateTime, fromDt: DateTime = DateTime.now()): number => {
  const firstStartOfDay = fromDt.toLocal().startOf('day')
  const secondStartOfDay = toDt.toLocal().startOf('day')

  const diff = secondStartOfDay.diff(firstStartOfDay, ['days']).toObject()

  return diff.days ?? 0
}

// We want the return type to be inferred here.
// eslint-disable-next-line @typescript-eslint/explicit-function-return-type
const getGetFormattedDaysLeft = (t: TranslationLookup) => {
  function getFormattedDaysLeft(dtOrISO: GenericDate): string
  function getFormattedDaysLeft(dtOrISO: undefined): undefined
  function getFormattedDaysLeft(dtOrISO: GenericDate | undefined): string | undefined
  function getFormattedDaysLeft(dtOrISO: GenericDate | undefined): string | undefined {
    const dt = getAsDateTime(dtOrISO)
    if (dt === undefined) return undefined

    const daysDiff = getDaysDiff(dt)

    return daysDiff < 0
      ? daysDiff === -1
        ? t('due-date.overdue-by-one-day')
        : t('due-date.overdue-by-n-days', { count: Math.abs(daysDiff) })
      : daysDiff === 0
        ? t('due-date.due-today')
        : daysDiff === 1
          ? t('due-date.due-tomorrow')
          : t('due-date.due-in-n-days', { count: daysDiff })
  }

  return getFormattedDaysLeft
}

type UseDaysLeftData = {
  daysLeft?: string
  getFormattedDaysLeft: ReturnType<typeof getGetFormattedDaysLeft>
  getFormattedDaysTo: (
    startDate: DateTime | string | Date,
    endDate: DateTime | string | Date,
    showRange?: boolean
  ) => { formattedDate: string; isNow: boolean } | undefined
}

export const toLocalTimeFormat = (dateTime: DateTime): string => {
  return dateTime.toLocaleString(DateTime.TIME_SIMPLE)
}

export const toLocalDayFormat = (dateTime: DateTime): string => {
  return dateTime.toLocaleString(DateTime.DATE_MED)
}

export const useGetDaysLeft = (date?: string | Date): UseDaysLeftData => {
  const { t } = useTranslation()

  const getFormattedDaysLeft = useMemo<UseDaysLeftData['getFormattedDaysLeft']>(
    () => getGetFormattedDaysLeft(t),
    [t]
  )

  const getFormattedDaysTo = useCallback<UseDaysLeftData['getFormattedDaysTo']>(
    (start, end, showRange) => {
      const startDate = getAsDateTime(start)
      const endDate = getAsDateTime(end)

      const startDaysDiff = getDaysDiff(startDate)

      const isNow = startDate < DateTime.now() && endDate > DateTime.now()
      const isPassed = endDate < DateTime.now()

      if (isNow) return { formattedDate: t('dictionary.live-now'), isNow: true }
      if (isPassed) return { formattedDate: t('dictionary.passed'), isNow: false }

      const date =
        startDaysDiff === 0
          ? t('dictionary.today')
          : startDaysDiff === 1
            ? t('dictionary.tomorrow')
            : startDate.toISODate()

      const startTime = toLocalTimeFormat(startDate)
      const endTime = toLocalTimeFormat(endDate)

      const formattedDate =
        showRange === true
          ? t('dictionary.date-at-time-range', { date, startTime, endTime })
          : t('dictionary.date-at-time', { date, time: startTime })

      return {
        formattedDate,
        isNow: false,
      }
    },
    [t]
  )

  return {
    daysLeft: getFormattedDaysLeft(date),
    getFormattedDaysLeft,
    getFormattedDaysTo,
  }
}

export const formatDateFromTo = ({
  dateFrom,
  dateTo,
}: {
  dateFrom?: string
  dateTo?: string
}): string | undefined => {
  if (dateFrom === undefined || dateTo === undefined) return undefined

  const dtDateFrom = DateTime.fromISO(dateFrom)
  const dtDateTo = DateTime.fromISO(dateTo)

  if (!dtDateFrom.isValid || !dtDateTo.isValid) return undefined

  const isoDateFrom = dtDateFrom.toISODate()
  const timeFrom = toLocalTimeFormat(dtDateFrom)
  const isoDateTo = dtDateTo.toISODate()
  const timeTo = toLocalTimeFormat(dtDateTo)

  const isSameDay = isoDateFrom === isoDateTo

  return isSameDay
    ? [isoDateFrom, ' ', timeFrom, '-', timeTo].join('')
    : [isoDateFrom, ' ', timeFrom, '-', isoDateTo, ' ', timeTo].join('')
}

export const joinFacilitators = (
  facilitators: { firstName?: string; lastName?: string }[],
  namesToDisplay: number | undefined = undefined
): string => {
  const displayed = namesToDisplay === undefined ? facilitators : _.take(facilitators, namesToDisplay)
  const hiddenCount = namesToDisplay === undefined ? 0 : Math.max(0, facilitators.length - namesToDisplay)

  return `${displayed
    .map(facilitator => _.compact([facilitator.firstName, facilitator.lastName]).join(' '))
    .join(', ')}${hiddenCount > 0 ? ` +${hiddenCount}` : ''}`
}

export function toShortISOString(date: Date): string {
  return date.toISOString().slice(0, 10)
}

type RelativeTimeUnitDiff = {
  unit: Intl.RelativeTimeFormatUnit
  unitDiff: number
}

function getAbsoluteRelativeTimeUnits(timeMs: number): RelativeTimeUnitDiff {
  const seconds = Math.abs(timeMs / 1000)
  if (seconds < 60) return { unit: 'second', unitDiff: seconds }

  const minutes = seconds / 60
  if (minutes < 60) return { unit: 'minute', unitDiff: minutes }

  const hours = minutes / 60
  if (hours < 24) return { unit: 'hour', unitDiff: hours }

  const days = hours / 24
  if (days < 7) return { unit: 'day', unitDiff: days }

  const weeks = days / 7
  if (weeks < 4) return { unit: 'week', unitDiff: weeks }

  const months = days / 30
  if (months < 12) return { unit: 'month', unitDiff: months }

  const years = months / 12
  return { unit: 'year', unitDiff: years }
}

function getRelativeTimeUnits(timeMs: number): RelativeTimeUnitDiff {
  const { unit, unitDiff } = getAbsoluteRelativeTimeUnits(timeMs)
  const roundedDiff = Math.round(unitDiff)
  return { unit, unitDiff: timeMs < 0 ? -roundedDiff : roundedDiff }
}

export enum TrimFutureDateAsNow {
  Enabled,
  Disabled,
}

export type LocalizedDateFormatters = {
  /** Formats a date to a string indicating how long ago the date was from today in the current language. */
  formatTimeAgo: (input: Date | string | number, style?: FormatStyle) => string
  /** Formats a date to a string indicating how long ago the date was from today or how much time is left until dhe date in the current language. */
  formatRelativeTime: (input: Date | string | number, style?: FormatStyle) => string
  /** Formats a date to a timestamp in the current language. */
  formatTimestamp: (
    input: Date | string | number,
    style?: FormatStyle,
    timeAgoStyle?: FormatStyle,
    trimFutureDateAsNow?: TrimFutureDateAsNow
  ) => string
}

export type FormatStyle = 'full' | 'compact'

const formats = {
  timestamp: { year: 'numeric', month: 'short', day: '2-digit', hour: 'numeric', minute: 'numeric' },
} as const

export function createLocalizedFormatters(locale: string): LocalizedDateFormatters {
  const timestampFormatter = new Intl.DateTimeFormat(locale, formats.timestamp)
  const relativeFormatter = new Intl.RelativeTimeFormat(locale, { style: 'long', numeric: 'auto' })
  const relativeShortFormatter = new Intl.RelativeTimeFormat(locale, { style: 'short', numeric: 'auto' })

  const formatTime = (
    input: Date | string | number,
    style?: FormatStyle,
    trimFutureDateAsNow = TrimFutureDateAsNow.Enabled
  ): string => {
    const now = new Date()
    let date = new Date(input)

    if (trimFutureDateAsNow === TrimFutureDateAsNow.Enabled && date > now) {
      // Prevent showing times in the future. Happens when incoming date comes from a server with different clock than client.
      date = new Date()
    }

    const { unit, unitDiff } = getRelativeTimeUnits(date.getTime() - now.getTime())

    if (style === 'compact') {
      return relativeShortFormatter.format(unitDiff, unit).replace(/\./g, '')
    }

    return relativeFormatter.format(unitDiff, unit)
  }

  const formatTimeAgo: LocalizedDateFormatters['formatTimeAgo'] = (input, style) =>
    formatTime(input, style, TrimFutureDateAsNow.Enabled)
  const formatRelativeTime: LocalizedDateFormatters['formatRelativeTime'] = (input, style) =>
    formatTime(input, style, TrimFutureDateAsNow.Disabled)

  return {
    formatTimeAgo,
    formatRelativeTime,
    formatTimestamp: (
      input,
      style = 'compact',
      timeAgoStyle = 'full',
      trimFutureDateAsNow = TrimFutureDateAsNow.Enabled
    ): string => {
      const date = new Date(input)
      const msDiff = Math.abs(new Date().getTime() - date.getTime())

      if (msDiff < SEVEN_DAYS_MS) {
        switch (trimFutureDateAsNow) {
          case TrimFutureDateAsNow.Enabled:
            return formatTimeAgo(date, timeAgoStyle)
          case TrimFutureDateAsNow.Disabled:
            return formatRelativeTime(date, timeAgoStyle)
        }
      }

      if (style === 'compact') {
        return toShortISOString(date)
      }

      return timestampFormatter.format(date)
    },
  }
}

/**
 * Memoized object with date formatters localized to the current language.
 */
export function useLocalizedFormatters(): LocalizedDateFormatters {
  const { lang } = useTranslation()
  return useMemo(() => createLocalizedFormatters(lang), [lang])
}

export function useDateTimeFormatter(format: Intl.DateTimeFormatOptions): Intl.DateTimeFormat {
  const memoizedFormat = useDeepEqualityMemo(format)
  const { lang } = useTranslation()
  return useMemo(() => new Intl.DateTimeFormat(lang, memoizedFormat), [lang, memoizedFormat])
}

type LiveSessionDateTimeInfo = {
  startTime: GenericDate
  endTime: GenericDate
  allDay: boolean
}

export function useLiveSessionDateTimeFormatter(): (info: LiveSessionDateTimeInfo) => string {
  const dateFormatter = useDateTimeFormatter({ dateStyle: 'medium' })
  const dateTimeFormatter = useDateTimeFormatter({ dateStyle: 'medium', timeStyle: 'short' })

  return useCallback(
    ({ startTime, endTime, allDay }) => {
      const start = getAsDate(startTime)
      const end = getAsDate(endTime)

      if (allDay) {
        return dateFormatter.formatRange(start, end)
      } else {
        return dateTimeFormatter.formatRange(start, end)
      }
    },
    [dateFormatter, dateTimeFormatter]
  )
}
