import React, { useEffect, useRef, useState } from 'react'
import { getStringWithoutOrphan } from 'sierra-client/config/util'
import { Button, Heading, Spacer, Text, TextSize } from 'sierra-ui/primitives'
import { breakpoints } from 'sierra-ui/theming/breakpoints'
import styled, { css } from 'styled-components'

type Props = {
  text?: string | null
  textSize?: TextSize
  expandText?: string
  minimizeText?: string
  largeText?: boolean
} & React.HTMLProps<HTMLParagraphElement>

const LargeTextContainer = styled(Heading)<{ showFullText: boolean }>`
  white-space: pre-wrap;
  max-width: 55ch;

  ${({ showFullText }) =>
    !showFullText &&
    css`
      display: -webkit-box;
      -webkit-line-clamp: 6;
      -webkit-box-orient: vertical;
      overflow: hidden;

      @media screen and (min-width: ${breakpoints.phone}) {
        -webkit-line-clamp: 4;
      }
    `}
`

const TextContainer = styled(Text)<{ showFullText: boolean }>`
  white-space: pre-wrap;
  max-width: 55ch;

  ${({ showFullText }) =>
    !showFullText &&
    css`
      display: -webkit-box;
      -webkit-line-clamp: 6;
      -webkit-box-orient: vertical;
      overflow: hidden;

      @media screen and (min-width: ${breakpoints.phone}) {
        -webkit-line-clamp: 4;
      }
    `}
`

export function EllipsedText({
  text,
  textSize = 'regular',
  expandText = 'Show more',
  minimizeText = 'Show less',
  largeText = false,
}: Props): JSX.Element | null {
  const [showFullText, setShowFullText] = useState<boolean>(false)
  const [isOverflowing, setIsOverflowing] = useState<boolean>(false)
  const textRef = useRef<HTMLParagraphElement | null>(null)

  const toggleShowFullText = (): void => setShowFullText(prevShow => !prevShow)

  useEffect(
    () => {
      if (textRef.current) {
        // Calculate if element is overflowing.
        const overflowing = textRef.current.offsetHeight < textRef.current.scrollHeight
        setIsOverflowing(overflowing)
      }
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [textRef.current, text]
  )

  if (text === undefined || text === null) {
    return null
  }
  const showButton = isOverflowing
  const buttonText = showFullText ? minimizeText : expandText

  return (
    <>
      {largeText ? (
        <LargeTextContainer
          color='LEGACY_DEFAULT_HEADING_COLOR_REPLACE_ASAP'
          size='h4'
          spacing='xxsmall'
          ref={textRef}
          showFullText={showFullText}
        >
          {getStringWithoutOrphan(text)}
        </LargeTextContainer>
      ) : (
        <TextContainer
          size={textSize === 'large' ? 'regular' : 'small'}
          tablet={textSize === 'large' ? 'large' : 'regular'}
          spacing='xxsmall'
          ref={textRef}
          showFullText={showFullText}
        >
          {getStringWithoutOrphan(text)}
        </TextContainer>
      )}
      {showButton ? (
        <>
          <Spacer size='xsmall' phone='small' />
          <Button variant='secondary' onClick={toggleShowFullText}>
            {buttonText}
          </Button>
        </>
      ) : null}
    </>
  )
}
