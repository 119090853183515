import { IconButton, LoadingSpinner, View } from 'sierra-ui/primitives'
import { token } from 'sierra-ui/theming'
import styled, { css } from 'styled-components'

export const HeaderItemCSS = css`
  height: 2.25rem;
  border-radius: ${p => p.theme.borderRadius['size-10']};
`

export const HeaderButtonColorCSS = css`
  transition: background-color 150ms cubic-bezier(0.25, 0.1, 0.25, 1);
  color: ${token('foreground/primary')};
  background-color: ${token('border/default')};

  &:hover {
    background-color: ${token('border/strong')};
  }
`

export const HeaderIconButton = styled(IconButton)`
  box-sizing: unset;
  ${HeaderItemCSS}
  ${HeaderButtonColorCSS}
`

export const HeaderButtonGroupWrapper = styled(View)`
  cursor: pointer;
  box-sizing: unset;
  ${HeaderItemCSS};
`

export const ButtonWrapper = styled.button<{ $disabledState?: boolean }>`
  display: flex;
  justify-content: flex-start;
  align-items: center;
  flex-grow: 0;
  flex-wrap: nowrap;
  flex-direction: row;
  cursor: ${p => (p.$disabledState === true ? 'auto' : 'pointer')};
  gap: 8px;
  padding: 0 0.75rem;
  ${HeaderItemCSS}
  ${HeaderButtonColorCSS}
`

export const HeaderGroupButtonCSS = css`
  height: 2.25rem;
  border-radius: 0;

  &:first-child {
    border-top-left-radius: 0.625rem;
    border-top-right-radius: 0;
    border-bottom-right-radius: 0;
    border-bottom-left-radius: 0.625rem;
  }

  &:last-child {
    border-top-left-radius: 0;
    border-top-right-radius: 0.625rem;
    border-bottom-right-radius: 0.625rem;
    border-bottom-left-radius: 0;
  }

  &:only-child {
    border-radius: 0.625rem;
  }
`

export const HeaderGroupLastButtonCSS = css`
  height: 2.25rem;

  border-top-left-radius: 0;
  border-top-right-radius: 0.625rem;
  border-bottom-right-radius: 0.625rem;
  border-bottom-left-radius: 0;
`

export const HeaderGroupFirstButtonCSS = css`
  height: 2.25rem;

  border-top-left-radius: 0.625rem;
  border-top-right-radius: 0;
  border-bottom-right-radius: 0;
  border-bottom-left-radius: 0.625rem;
`

export const HeaderGroupIconButton = styled(IconButton).attrs({ variant: 'transparent' })`
  cursor: pointer;
  box-sizing: unset;
  border: none;

  ${HeaderButtonColorCSS}
  ${HeaderGroupButtonCSS}
`

export const HeaderGroupLoadingSpinner = styled(LoadingSpinner)`
  ${HeaderButtonColorCSS}
  ${HeaderGroupButtonCSS}
`

export const HeaderGroupButton = styled.button`
  cursor: pointer;
  display: flex;

  & > * {
    margin: auto;
  }

  ${HeaderButtonColorCSS}
  ${HeaderGroupButtonCSS}
`
