import { ApproverSetting } from 'sierra-client/api/graphql/gql/graphql'
import { Logger } from 'sierra-client/core/logging/logger'

export type EventGroupCreatedProps = {
  eventGroupId: string
  nrOfSessions: number
}
export const eventGroupCreated = Logger.trackLoggerWithExtra<EventGroupCreatedProps, EventGroupCreatedProps>(
  'event_group_create_event_group_save_clicked',
  input => {
    return {
      ...input,
    }
  }
)

export type CalendarEventUserCheckedInProps = {
  calendarEventId: string
}
export const calendarEventUserCheckedIn = Logger.trackLoggerWithExtra<
  CalendarEventUserCheckedInProps,
  CalendarEventUserCheckedInProps
>('calendar_event_user_checked_in', input => {
  return {
    ...input,
  }
})

export type CalendarEventCreatedProps = {
  calendarEventId: string
  participantCount: number
  participantLimit: number | undefined
  approverSetting: ApproverSetting | undefined
  selfReportAttendance: boolean
  allDayEvent: boolean
  eventInDifferentTimeZoneFromCreator: boolean
}

export const calendarEventCreated = Logger.trackLoggerWithExtra<
  CalendarEventCreatedProps,
  CalendarEventCreatedProps
>('calendar_event_created', input => {
  return {
    ...input,
  }
})
