import { HTMLInputTypeAttribute } from 'react'
import { TranslationLookup } from 'sierra-client/hooks/use-translation/types'
import { TypeOfType } from 'sierra-domain/filter/datatype/domain'
import { ValueWithoutExerciseAndFile } from 'sierra-domain/filter/datatype/value'
import { assertNever } from 'sierra-domain/utils'

export const typeOfTypeToInputType = (typeOfType: TypeOfType): HTMLInputTypeAttribute => {
  switch (typeOfType.type) {
    case 'type.string':
      return 'string'
    case 'type.integer':
      return 'number'
    case 'type.date':
      return 'date'
    case 'type.boolean':
    case 'type.time':
    case 'type.uuid':
    case 'type.nanoid12':
    case 'type.phone-number':
      throw new Error('Unsupported data format used in input type.')
    default:
      assertNever(typeOfType)
  }
}

export const typeOfTypeToPlaceholder = (typeOfType: TypeOfType, t: TranslationLookup): string => {
  switch (typeOfType.type) {
    case 'type.string':
      return t('dictionary.enter')
    case 'type.integer':
      return '0'
    case 'type.date':
    case 'type.boolean':
    case 'type.time':
    case 'type.uuid':
    case 'type.nanoid12':
    case 'type.phone-number':
      return ''
    default:
      assertNever(typeOfType)
  }
}

export const typeOfTypeToValue = (typeOfType: TypeOfType, value: string): ValueWithoutExerciseAndFile => {
  switch (typeOfType.type) {
    case 'type.string':
      return { type: 'value.string', value }
    case 'type.integer':
      return { type: 'value.integer', value: parseInt(value) || 0 }
    case 'type.date':
      return { type: 'value.date', value }
    case 'type.boolean':
    case 'type.time':
    case 'type.uuid':
    case 'type.nanoid12':
    case 'type.phone-number':
      throw new Error('Unsupported data format used in value type.')
    default:
      assertNever(typeOfType)
  }
}
