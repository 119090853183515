import { useResetBooleanAfterDelay } from 'sierra-client/hooks/use-reset-boolean-after-delay'
import { useTranslation } from 'sierra-client/hooks/use-translation'
import { Button } from 'sierra-ui/primitives'

export const CopyLinkButton: React.FC<{ text: string; sendSegmentLog?: () => void }> = ({
  text,
  sendSegmentLog,
}) => {
  const { t } = useTranslation()

  const { isEnabled: recentlyClicked, setTrue: setRecentlyClicked } = useResetBooleanAfterDelay()

  const handleClick = (): void => {
    void window.navigator.clipboard.writeText(text)
    setRecentlyClicked()

    if (sendSegmentLog !== undefined) sendSegmentLog()
  }

  return (
    <Button
      variant='transparent'
      icon={recentlyClicked ? 'checkmark' : 'link'}
      decoratorPosition='left'
      onClick={handleClick}
    >
      {t('share.copy-link')}
    </Button>
  )
}
