import { unwrapToTextNodes } from 'sierra-client/views/v3-author/command'
import { isElementType } from 'sierra-client/views/v3-author/queries'
import { Editor, Node, Text } from 'slate'

const debug = (...messages: unknown[]): void => console.debug('[withLinks]', ...messages)

export const withLinks = (editor: Editor): Editor => {
  const { isInline, normalizeNode } = editor

  editor.isInline = element => element.type === 'link' || isInline(element)

  editor.normalizeNode = entry => {
    const [node, path] = entry

    if (isElementType('link', node)) {
      if (node.children.length === 1 && node.children[0]?.text === '') {
        debug('Unwrapping empty link at:', JSON.stringify(path))
        return unwrapToTextNodes(editor, { at: path })
      }

      for (const [child, childPath] of Node.children(editor, path)) {
        if (!Text.isText(child)) {
          debug('Links can only contain text nodes. Unwrapping at:', JSON.stringify(childPath))
          return unwrapToTextNodes(editor, { at: childPath })
        }
      }
    }

    normalizeNode(entry)
  }

  return editor
}
