import { useEffect, useState } from 'react'
import { getVideoCallService } from 'sierra-client/components/liveV2/services/video-call-service'
import {
  Tracks,
  VideoCallService,
} from 'sierra-client/components/liveV2/services/video-call-service/video-call-service'

export const useLocalTracks = (): Tracks => {
  const [tracks, setTracks] = useState<Tracks>({})

  useEffect(() => {
    let videoCallService: VideoCallService | undefined

    const handler = (): void => {
      if (videoCallService?.tracks) setTracks(videoCallService.tracks)
    }

    void (async () => {
      videoCallService = await getVideoCallService()
      videoCallService.on('track-changed', handler)
      setTracks(videoCallService.tracks)
    })()

    return () => {
      videoCallService?.off('track-changed', handler)
    }
  }, [])

  return tracks
}
