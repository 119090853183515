import {
  EditorTextActionsModalAction,
  EditorTextActionsModalState,
} from 'sierra-client/features/text-actions/types'
import { assert, assertNever } from 'sierra-domain/utils'

export const promptModalReducer = (
  state: EditorTextActionsModalState,
  action: EditorTextActionsModalAction
): EditorTextActionsModalState => {
  switch (action.type) {
    case 'abort':
      if (state.type === 'running') {
        state.onCancel()

        return { type: 'done', instruction: state.instruction, output: state.output }
      } else {
        return state
      }
    case 'start-generation':
      assert(state.type !== 'running', 'Cannot start generation while already running')

      return { type: 'running', instruction: action.instruction, output: '', onCancel: action.onCancel }
    case 'generation-error':
      assert(state.type === 'running', 'Cannot have generation error unless running')

      return { type: 'initial' }
    case 'generation-step':
      assert(state.type === 'running', 'Cannot receive generation step unless running')

      return {
        ...state,
        output: state.output + action.chunk,
      }
    case 'finish':
      assert(state.type === 'running', 'Cannot finish generation unless running')

      return { type: 'done', instruction: state.instruction, output: state.output }
    default:
      assertNever(action)
  }
}
