import _ from 'lodash'
import React, { useCallback, useEffect, useRef, useState } from 'react'
import { IdentitiesListContainer, ListItem } from 'sierra-client/components/common/identities-selector/atoms'
import { useTranslation } from 'sierra-client/hooks/use-translation'
import { useProgramReminderRecipients } from 'sierra-client/views/manage/programs/hooks/use-program-reminder-recipients'
import { useTracking } from 'sierra-client/views/manage/programs/hooks/use-tracking'
import { Member } from 'sierra-client/views/manage/programs/send-reminder/types'
import { getSrcFromFileAvatar } from 'sierra-client/views/manage/programs/send-reminder/utils'
import { ListVirtualizer } from 'sierra-client/views/workspace/components/list-virtualizer'
import { Autocomplete, RoundAvatar } from 'sierra-ui/components'
import { Text, View } from 'sierra-ui/primitives'
import styled from 'styled-components'

export type ProgramMemberWithId = Member & { id: string }

type VirtualizedMembersProps = {
  matchingMembers: ProgramMemberWithId[]
}

const StyledListItem = styled(ListItem)`
  justify-content: space-between;
`

const DisabledListItem = styled(ListItem)`
  justify-content: space-between;
  pointer-events: none;
  opacity: 0.5;
`

const Progress: React.FC<{ progress: number | undefined }> = ({ progress }) => {
  const { t } = useTranslation()

  const definedProgress = progress !== undefined ? progress : 0

  return progress === 1 ? (
    <Text color={'success/background'} bold>
      {t('dictionary.completed')}
    </Text>
  ) : progress === 0 ? (
    <Text color={'foreground/muted'}>{t('dictionary.not-started')}</Text>
  ) : (
    <Text>{`${Math.round(definedProgress * 100)}%`}</Text>
  )
}

const Item: React.FC<{ member: ProgramMemberWithId }> = ({ member }) => {
  const fullName = `${member.firstName} ${member.lastName}`

  return (
    <StyledListItem key={member.id} value={member.id}>
      <View>
        <RoundAvatar
          firstName={member.firstName !== null ? member.firstName : ''}
          color={member.avatar?.type === 'color' ? member.avatar.color : undefined}
          src={member.avatar ? getSrcFromFileAvatar(member.id, member.avatar) : undefined}
        />
        <View direction='column' gap='none'>
          <Text bold>{fullName}</Text>
          <Text>{member.email}</Text>
        </View>
      </View>
      <Progress progress={member.progress} />
    </StyledListItem>
  )
}

const DisabledItem: React.FC<{ member: ProgramMemberWithId }> = ({ member }) => {
  const fullName = `${member.firstName} ${member.lastName}`

  return (
    <DisabledListItem key={member.id} value={member.id}>
      <View>
        <RoundAvatar
          firstName={member.firstName !== null ? member.firstName : ''}
          color={member.avatar?.type === 'color' ? member.avatar.color : undefined}
          src={member.avatar ? getSrcFromFileAvatar(member.id, member.avatar) : undefined}
        />
        <View direction='column' gap='none'>
          <Text bold>{fullName}</Text>
          <Text>{member.email}</Text>
        </View>
      </View>
      <Progress progress={member.progress} />
    </DisabledListItem>
  )
}

const ItemRenderer: React.FC<{ member: ProgramMemberWithId }> = ({ member }) => {
  if (member.progress === 1 || (member.hoursSinceContact !== undefined && member.hoursSinceContact < 24)) {
    return <DisabledItem member={member} />
  } else {
    return <Item member={member} />
  }
}

const VirtualizedMembers = ({ matchingMembers }: VirtualizedMembersProps): JSX.Element => {
  const scrollingRef = useRef<HTMLDivElement>(null)

  return (
    <IdentitiesListContainer ref={scrollingRef}>
      <ListVirtualizer
        items={matchingMembers}
        scrollElement={scrollingRef.current}
        estimateSize={55}
        renderItem={member => {
          return <ItemRenderer member={member} />
        }}
      />
    </IdentitiesListContainer>
  )
}

export const RecipientsSelector: React.FC<{ programId: string }> = ({ programId }) => {
  const { t } = useTranslation()
  const tracking = useTracking()

  const { setSelectedMembers, selectedMembers, fetchMatching, matching, query, setQuery } =
    useProgramReminderRecipients(programId)

  const [selectedItems, setSelectedItems] = useState<ProgramMemberWithId[]>([])

  const onSelect = useCallback(
    (member: ProgramMemberWithId) => {
      setSelectedItems([...selectedItems, member])
      setSelectedMembers([...selectedMembers, member])
    },
    [selectedItems, selectedMembers, setSelectedMembers]
  )

  useEffect(() => {
    async function fetch(): Promise<void> {
      await fetchMatching()
    }

    void fetch()
  }, [fetchMatching])

  const debouncedTracker = _.debounce(tracking.reminder.inputClicked, 500) //this is to avoid tracking double clicks as two events

  return (
    <View onClick={() => debouncedTracker}>
      <Autocomplete
        query={query}
        onQueryChange={setQuery}
        matchingItems={matching}
        selectedItems={selectedItems}
        onSelect={onSelect}
        renderMatchingItemList={() => <VirtualizedMembers matchingMembers={matching} />}
        placeholder={t('manage.programs.send-reminder.search-enrolled-users')}
      />
    </View>
  )
}
