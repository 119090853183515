import React, { useState } from 'react'
import ReCAPTCHA from 'react-google-recaptcha'
import { HiddenInput } from 'sierra-client/components/common/inputs/hidden-input'
import { Auth } from 'sierra-client/core/auth'
import { errorLogger } from 'sierra-client/error/error-logger'
import { usePasswordStrength } from 'sierra-client/hooks/use-password-strength'
import { usePost } from 'sierra-client/hooks/use-post'
import { RECAPTCHA_SITE_KEY, useRecaptcha } from 'sierra-client/hooks/use-recaptcha'
import { useTranslation } from 'sierra-client/hooks/use-translation'
import { SecondaryButton } from 'sierra-client/layout/authentication-layout/auth-buttons'
import { getUserErrorTranslationKey } from 'sierra-client/utils/translation-utils'
import { AuthenticationForm } from 'sierra-client/views/authentication/authentication-layout'
import { AuthenticationContent } from 'sierra-client/views/authentication/native/components/authentication-content'
import { isLeft } from 'sierra-domain/either'
import { XRealtimeAuthCreateUser, XRealtimeAuthCreateUserFromGroupInvite } from 'sierra-domain/routes'
import { FormElement, PasswordInputPrimitive } from 'sierra-ui/components'
import { Button, InputPrimitive, Text, View } from 'sierra-ui/primitives'

type Default = {
  type: 'default'
}

type GroupInvite = {
  type: 'group-invite'
  token: string
  group: string
}

type Props = {
  email: string
  switchToSignUp: () => void
  onCreate?: () => void
  mode?: GroupInvite | Default
}

export const SignUpWithEmail: React.FC<Props> = ({
  email,
  switchToSignUp,
  onCreate,
  mode = { type: 'default' },
}) => {
  const { t } = useTranslation()
  const [password, setPassword] = useState('')
  const [passwordError, setPasswordError] = useState<string | undefined>(undefined)
  const [passwordConfirmation, setPasswordConfirmation] = useState('')
  const [passwordConfirmationError, setPasswordConfirmationError] = useState<string | undefined>(undefined)
  const [error, setError] = useState<string | undefined>()
  const { postWithUserErrorCode } = usePost()
  const { getRecaptcha, recaptchaRef } = useRecaptcha({
    errorCallback: (e: string) => {
      errorLogger.error(e)
      setError(e)
    },
  })
  const { isPasswordStrengthGood } = usePasswordStrength()

  const disableSubmit = password === '' || passwordConfirmation === ''

  const resetPasswordErrors = (): void => {
    setPasswordError(undefined)
    setPasswordConfirmationError(undefined)
  }

  const handleBlurPassword = async (): Promise<void> => {
    if (!password) return

    if (!(await isPasswordStrengthGood(password))) {
      setPasswordError(t('create-account.errors.password-strength'))
    }
  }

  const onPasswordChange = (e: React.ChangeEvent<HTMLInputElement>): void => {
    setPassword(e.target.value)
    resetPasswordErrors()
  }

  const onPasswordConfirmationChange = (e: React.ChangeEvent<HTMLInputElement>): void => {
    setPasswordConfirmation(e.target.value)
    resetPasswordErrors()
  }

  const submit = async (event: React.FormEvent<HTMLFormElement>): Promise<void> => {
    event.preventDefault()

    setError(undefined)

    let errors = false

    if (!password) {
      errors = true
      setPasswordError(t('create-account.errors.password'))
    } else if (!(await isPasswordStrengthGood(password))) {
      errors = true
      setPasswordError(t('create-account.errors.password-strength'))
    } else {
      setPasswordError(undefined)
    }

    if (password !== passwordConfirmation) {
      errors = true
      setPasswordConfirmationError(t('create-account.errors.password-confirmation'))
    } else {
      setPasswordConfirmationError(undefined)
    }

    if (errors) return

    if (mode.type === 'group-invite') {
      const result = await postWithUserErrorCode(XRealtimeAuthCreateUserFromGroupInvite, {
        token: mode.token,
        email,
        password,
      })

      if (isLeft(result)) {
        setError(t(getUserErrorTranslationKey(result.left)))
        return
      }
    } else {
      const recaptcha = await getRecaptcha()

      if (recaptcha === null) {
        return
      }

      const result = await postWithUserErrorCode(XRealtimeAuthCreateUser, {
        email,
        password,
        recaptcha,
      })

      if (isLeft(result)) {
        setError(t(getUserErrorTranslationKey(result.left)))
        return
      }
    }

    setError(undefined)

    onCreate?.()

    await Auth.getInstance().synchronize()
  }

  return (
    <AuthenticationContent message={t('login.sign-up')}>
      {/* Details about ReCAPTCHA setup here https://github.com/sanalabs/sierra-platform/blob/develop/docs/recaptcha.md */}
      {mode.type !== 'group-invite' && (
        <ReCAPTCHA sitekey={RECAPTCHA_SITE_KEY} size={'invisible'} ref={recaptchaRef} />
      )}
      <AuthenticationForm onSubmit={submit}>
        <View direction='column' gap='xsmall'>
          <FormElement label={t('create-account.fields.email')}>
            <InputPrimitive type='text' value={email} onChange={() => {}} disabled />
          </FormElement>
          {/* The HiddenInput is necessary for Chrome since it fills the field before the password field with username. */}
          <HiddenInput tabIndex={-1} type='text' value='' readOnly />
          <FormElement
            label={t('create-account.fields.password')}
            isError={passwordError !== undefined}
            helper={passwordError}
          >
            <PasswordInputPrimitive
              value={password}
              onChange={onPasswordChange}
              onBlur={handleBlurPassword}
              autoFocus
            />
          </FormElement>
          <FormElement
            label={t('accept-invite.fields.confirm-password')}
            isError={passwordConfirmationError !== undefined}
            helper={passwordConfirmationError}
          >
            <PasswordInputPrimitive value={passwordConfirmation} onChange={onPasswordConfirmationChange} />
          </FormElement>
          {error !== undefined && (
            <Text size='micro' color='redBright' spacing='xsmall'>
              {error}
            </Text>
          )}
          <Button variant='primary' type='submit' disabled={disableSubmit} grow>
            {t('create-account.continue')}
          </Button>
          <View justifyContent='center'>
            <SecondaryButton onClick={switchToSignUp}>{t('login.back')}</SecondaryButton>
          </View>
        </View>
      </AuthenticationForm>
    </AuthenticationContent>
  )
}
