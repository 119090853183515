import { MutationOptions, UseMutationResult, useMutation, type UseQueryResult } from '@tanstack/react-query'
import { Duration } from 'luxon'
import type { SkillId, SkillRecommendationId } from 'sierra-client/api/graphql/branded-types'
import { graphql } from 'sierra-client/api/graphql/gql'
import {
  TriggerAutoAssignMutation,
  type AcceptRecommendationMutation,
  type AcceptRecommendationMutationVariables,
  type RejectRecommendationMutation,
  type RejectRecommendationMutationVariables,
  type TriggerAutoAssignMutationVariables,
} from 'sierra-client/api/graphql/gql/graphql'
import { convertGQLImage } from 'sierra-client/api/graphql/util/convert-gql-image'
import { graphQuery, useGraphQuery, useResetGraphQuery } from 'sierra-client/api/hooks/use-graphql-query'
import { BadgeIconId, BadgeTheme } from 'sierra-client/features/skills/components/badges/types'
import { ContentSkill } from 'sierra-client/features/skills/components/content-skill/types'
import type { ContentForSkill } from 'sierra-client/features/skills/components/recommendation/types'
import { skillSettingsQuery } from 'sierra-client/features/skills/shared-gql-queries'
import { useOrganizationPermissions } from 'sierra-client/hooks/use-permissions'
import type { InvalidateQuery } from 'sierra-client/state/api'
import { CourseId } from 'sierra-domain/api/nano-id'
import { assertNever, isDefined } from 'sierra-domain/utils'

const skillRecommendationForCourse = graphql(`
  query SkillRecommendationForCourse($id: CourseId!) {
    course(id: $id) {
      skillRecommendations {
        ... on Done {
          __typename
          result {
            id
            assignedSkillLevel {
              id
              levelSetting {
                id
                defaultBadgeTheme
                name
              }
            }
            skill {
              ...ContentSkillDetails
            }
          }
        }
        ... on InProgress {
          __typename
        }
      }
    }
  }
`)

type RecommendationSkillMap = Map<ContentSkill['id'], SkillRecommendationId>
type SkillRecommendationForCourse =
  | {
      status: 'Done'
      skills: ContentSkill[]
      recommendationMap: RecommendationSkillMap
    }
  | {
      status: 'InProgress'
    }
  | {
      status: 'ContentNotFound'
    }

export const useSkillRecommendationForCourse = (
  courseId: CourseId
): UseQueryResult<SkillRecommendationForCourse, unknown> => {
  return useGraphQuery(
    {
      document: skillRecommendationForCourse,
      queryOptions: {
        refetchInterval: query => {
          if (
            query.state.data?.course?.skillRecommendations.__typename !== 'Done' &&
            query.state.data?.course !== null
          ) {
            return 2000
          } else {
            return false
          }
        },
        select({ course }) {
          if (isDefined(course)) {
            switch (course.skillRecommendations.__typename) {
              case 'InProgress':
                return {
                  status: 'InProgress',
                }

              case 'Done': {
                const skills: ContentSkill[] = []
                const recommendationMap: RecommendationSkillMap = new Map()

                for (const { assignedSkillLevel, skill, id } of course.skillRecommendations.result) {
                  skills.push({
                    id: skill.id,
                    name: skill.name,
                    iconId: BadgeIconId.catch('locked').parse(skill.defaultBadgeIcon),
                    theme: BadgeTheme.catch('disabled').parse(
                      assignedSkillLevel.levelSetting.defaultBadgeTheme
                    ),
                    levelSettingId: assignedSkillLevel.levelSetting.id,
                    levels: skill.skillLevels,
                  })

                  recommendationMap.set(skill.id, id)
                }

                return {
                  status: 'Done',
                  skills,
                  recommendationMap,
                }
              }

              default:
                assertNever(course.skillRecommendations)
            }
          }

          return { status: 'ContentNotFound' }
        },
      },
    },
    { id: courseId }
  )
}

export const useResetSkillRecommendationForCourseQuery = (courseId: CourseId): InvalidateQuery => {
  return useResetGraphQuery(skillRecommendationForCourse, { id: courseId })
}

const contentRecommendationForSkill = graphql(`
  query ContentRecommendationForSkill(
    $skillId: SkillId!
    $limit: Int
    $cursor: String
    $sortBy: [SkillRecommendationSortAttributeInput!]
  ) {
    skillRecommendedContent(skillId: $skillId, limit: $limit, cursor: $cursor, sortBy: $sortBy) {
      ... on SkillRecommendedContentInProgress {
        __typename
      }
      ... on SkillRecommendedContentDone {
        __typename
        result {
          data {
            id
            content {
              contentId
              title
              duration
              image {
                ...ImageFragment
              }
            }
            skill {
              ...ContentSkillDetails
            }
            assignedSkillLevel {
              id
              levelSetting {
                id
                defaultBadgeTheme
                name
              }
            }
          }
        }
      }
    }
  }
`)

export type ContentRecommendationItem = {
  skill: ContentSkill
  content: ContentForSkill
}

type RecommendationContentMap = Map<ContentForSkill['contentId'], SkillRecommendationId>
type ContentRecommendationForSkill =
  | {
      status: 'Done'
      items: ContentRecommendationItem[]
      recommendationMap: RecommendationContentMap
    }
  | {
      status: 'InProgress'
    }

export const useContentRecommendationForSkill = (
  skillId: SkillId
): UseQueryResult<ContentRecommendationForSkill, unknown> => {
  return useGraphQuery(
    {
      document: contentRecommendationForSkill,
      queryOptions: {
        refetchInterval: query => {
          if (query.state.data?.skillRecommendedContent.__typename !== 'SkillRecommendedContentDone') {
            return 2000
          } else {
            return false
          }
        },
        select(data) {
          const recommendation = data.skillRecommendedContent

          switch (recommendation.__typename) {
            case 'SkillRecommendedContentInProgress':
              return {
                status: 'InProgress',
              }

            case 'SkillRecommendedContentDone': {
              const items: ContentRecommendationItem[] = []
              const recommendationMap: RecommendationContentMap = new Map()

              for (const { content, assignedSkillLevel, skill, id } of recommendation.result.data) {
                items.push({
                  content: {
                    contentId: content.contentId,
                    title: content.title,
                    image: convertGQLImage(content.image),
                    duration: Duration.fromISO(content.duration).as('seconds'),
                  },
                  skill: {
                    id: skill.id,
                    name: skill.name,
                    iconId: BadgeIconId.catch('locked').parse(skill.defaultBadgeIcon),
                    theme: BadgeTheme.catch('disabled').parse(
                      assignedSkillLevel.levelSetting.defaultBadgeTheme
                    ),
                    levelSettingId: assignedSkillLevel.levelSetting.id,
                    levels: skill.skillLevels,
                  },
                })

                recommendationMap.set(content.contentId, id)
              }

              return {
                status: 'Done',
                items,
                recommendationMap,
              }
            }

            default:
              assertNever(recommendation)
          }
        },
      },
    },
    { skillId, limit: 3 }
  )
}

const triggerAutoAssignMutation = graphql(`
  mutation TriggerAutoAssign($courseId: CourseId!) {
    triggerAutoAssign(courseId: $courseId)
  }
`)

export const useTriggerAutoAssignMutation = (): UseMutationResult<
  TriggerAutoAssignMutation,
  unknown,
  TriggerAutoAssignMutationVariables,
  unknown
> => {
  return useMutation({
    mutationFn: vars => graphQuery(triggerAutoAssignMutation, vars),
  })
}

const acceptRecommendationMutation = graphql(`
  mutation AcceptRecommendation($id: SkillRecommendationId!, $levelOverride: SkillLevelSettingId) {
    acceptRecommendation(id: $id, levelOverride: $levelOverride)
  }
`)

export const useAcceptRecommendationMutation = (
  options: MutationOptions<
    AcceptRecommendationMutation,
    unknown,
    AcceptRecommendationMutationVariables,
    unknown
  > = {}
): UseMutationResult<
  AcceptRecommendationMutation,
  unknown,
  AcceptRecommendationMutationVariables,
  unknown
> => {
  return useMutation({
    ...options,
    mutationFn: vars => graphQuery(acceptRecommendationMutation, vars),
  })
}

const rejectRecommendationMutation = graphql(`
  mutation RejectRecommendation($id: SkillRecommendationId!) {
    rejectRecommendation(id: $id)
  }
`)

export const useRejectRecommendationMutation = (
  options: MutationOptions<
    RejectRecommendationMutation,
    unknown,
    RejectRecommendationMutationVariables,
    unknown
  > = {}
): UseMutationResult<
  RejectRecommendationMutation,
  unknown,
  RejectRecommendationMutationVariables,
  unknown
> => {
  return useMutation({
    ...options,
    mutationFn: vars => graphQuery(rejectRecommendationMutation, vars),
  })
}

export const useIsSkillAutoAssignAllowed = (): {
  status: 'pending' | 'success' | 'error'
  allowed: boolean
} => {
  const orgPermissions = useOrganizationPermissions()
  const skillSettings = useGraphQuery({ document: skillSettingsQuery }, {})

  const allowed =
    orgPermissions.has('AUTO_ASSIGN_SKILLS') && Boolean(skillSettings.data?.skillSettings.skillsEngineEnabled)

  let status: 'pending' | 'success' | 'error' = 'pending'

  if (orgPermissions.status === 'loaded' && skillSettings.status === 'success') {
    status = 'success'
  }
  if (
    orgPermissions.status === 'error' ||
    orgPermissions.status === 'resource-not-found' ||
    orgPermissions.status === 'user-unauthorized' ||
    skillSettings.status === 'error'
  ) {
    status = 'error'
  }

  return {
    status,
    allowed,
  }
}
