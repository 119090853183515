import { motion } from 'framer-motion'
import React from 'react'
import { useLivePageContext } from 'sierra-client/components/liveV2/contexts/live-page'
import { useRightSidebarContext } from 'sierra-client/components/liveV2/live-sidebar-provider'
import { NoteFormat, useAutoSummary } from 'sierra-client/components/liveV2/right-sidebar/use-auto-summary'
import { useTranslation } from 'sierra-client/hooks/use-translation'
import { IconButton, ScrollView, Text, View } from 'sierra-ui/primitives'
import { token } from 'sierra-ui/theming'
import styled from 'styled-components'

const Wrapper = styled(View).attrs({ direction: 'column' })`
  height: 100%;
  border: none;
  overflow: hidden;
`

const NoBorderView = styled(View)`
  border: none;
`

const Header = styled(NoBorderView)`
  padding: 1.125rem 1rem 0 1.5rem;
`

const Container = styled(View).attrs({
  direction: 'column',
  margin: 'none',
  gap: 'none',
})`
  height: 100%;
  color: ${token('foreground/primary')};
  background-color: ${token('surface/default')};
  border-radius: ${p => p.theme.borderRadius.regular};
  border: none;
`

const NoteListContainer = styled.dl`
  padding-left: 1rem;

  dt {
    list-style-type: disc;
    display: list-item;

    &::first-letter {
      text-transform: uppercase;
    }

    p {
      display: inline;
    }
  }

  dd,
  dt {
    margin-bottom: 0.375rem;
  }

  dd + dt {
    margin-top: 1.375rem;
  }
`

const Note = styled(motion.dd)``

const NoteList = (notes: NoteFormat): JSX.Element => {
  const { t } = useTranslation()
  if (notes.topics.length === 0)
    return (
      <Text size='small' color='foreground/muted'>
        {t('live.recap.no-notes-available')}
      </Text>
    )

  return (
    <NoteListContainer>
      {notes.topics.map((topic, index) => (
        <>
          <dt key={index}>
            <Text bold size='small'>
              {topic.topic}
            </Text>
          </dt>
          {topic.notes.map((note, jndex) => (
            <Note
              key={jndex}
              initial={{ opacity: 0 }}
              animate={{ opacity: 1 }}
              exit={{ opacity: 0 }}
              transition={{ timing: 1 }}
            >
              <Text size='small' color='foreground/muted'>
                {note}
              </Text>
            </Note>
          ))}
        </>
      ))}
    </NoteListContainer>
  )
}

const START_WITH_WHITESPACE_REGEX = /^[\s\t]/

export const AutoSummary: React.FC<{ showClose: boolean }> = ({ showClose }) => {
  const { liveSessionId } = useLivePageContext()
  const { t } = useTranslation()
  const { close } = useRightSidebarContext()

  const summaryData = useAutoSummary(liveSessionId)

  return (
    <Wrapper direction='column'>
      <Container>
        <Header>
          <NoBorderView grow>
            <Text bold size='small'>
              {t('dictionary.notes')}
            </Text>
          </NoBorderView>
          {showClose && <IconButton variant='transparent' iconId='close' onClick={close} />}
        </Header>
        <ScrollView gap='xxsmall' padding='small' paddingBottom='none' grow>
          {summaryData?.format === 'note' && <NoteList {...summaryData.data} />}{' '}
          {summaryData?.format === 'string' &&
            summaryData.data
              .split('\n')
              .filter(l => l !== '')
              .map((line, index) => (
                <View key={index} marginLeft={START_WITH_WHITESPACE_REGEX.test(line) ? 'small' : 'none'}>
                  <Text size='small'>{line.trim()}</Text>
                </View>
              ))}
        </ScrollView>
      </Container>
    </Wrapper>
  )
}
