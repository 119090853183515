import { AnimatePresence, motion } from 'framer-motion'
import { uniq } from 'lodash'
import React, { useMemo } from 'react'
import { resolveUserAvatar } from 'sierra-client/api/content'
import { isUserLoaded, useUsers } from 'sierra-client/state/users/hooks'
import { UserId } from 'sierra-domain/api/uuid'
import { AvatarStack } from 'sierra-ui/components'
import { AvatarStackItemId } from 'sierra-ui/components/avatars/avatar-stack'
import styled from 'styled-components'

const AnimatedAvatarContainer = styled(motion.div)`
  display: flex;
`

export const UserIdAvatars: React.FC<{
  userIds: UserId[]
  animateAvatars?: boolean
  onAvatarClick?: (id: AvatarStackItemId) => void
}> = ({ userIds, animateAvatars = true, onAvatarClick }) => {
  const uniqueUsers = useMemo(() => uniq(userIds), [userIds])
  const users = useUsers(uniqueUsers).filter(isUserLoaded).map(resolveUserAvatar)

  return (
    <AnimatePresence>
      {users.length === 0 ? null : (
        <AnimatedAvatarContainer
          initial={{ scale: animateAvatars ? 0.2 : 1 }}
          animate={{ scale: 1 }}
          exit={{ scale: animateAvatars ? 0 : 1 }}
          transition={{ duration: 0.3, type: 'spring', bounce: 0.4 }}
        >
          <AvatarStack
            users={users}
            onAvatarClick={onAvatarClick}
            size='minuscule'
            max={3}
            withRemainerIndication
            withTooltips
          />
        </AnimatedAvatarContainer>
      )}
    </AnimatePresence>
  )
}
