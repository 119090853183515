/**
 * Removes search params from the currently active URL by replacing the current history state.
 */
export function removeSearchParams(searchParamNames: string[]): void {
  const urlObj = new URL(location.href)

  for (const name of searchParamNames) {
    urlObj.searchParams.delete(name)
  }

  const newHref = urlObj.toString()

  if (location.href !== newHref) {
    window.history.replaceState({}, '', urlObj.toString())
  }
}
