import { getFlag } from 'sierra-client/config/global-config'
import { useSelector } from 'sierra-client/state/hooks'
import { selectIsOwnerUser } from 'sierra-client/state/user/user-selector'

export function useDeveloperToolsEnabled(): boolean {
  const isDevelopment = process.env.NODE_ENV === 'development'
  const isOwner = useSelector(selectIsOwnerUser)
  const developerToolsEnabled = getFlag('developer-tools')

  return isDevelopment || isOwner || developerToolsEnabled
}
