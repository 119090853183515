import { useEffect, useState } from 'react'
import { useIsInViewport } from 'sierra-client/hooks/use-is-in-viewport'
import { useSelfPacedCardScroll } from 'sierra-client/views/course-helper/shared/self-paced-card-scroll-context'
import { File } from 'sierra-domain/flexible-content/types'
import styled from 'styled-components'

const InvisibleElement = styled.span`
  height: 1px;
  width: 1px;
  flex: 0 0 1px;
  transform: translateY(-50px); /* To avoid forcing scroll all the way down */
`

export const SelfPacedCardEndReached: React.FC<{ file: File }> = ({ file }) => {
  const { completedRef, setScrollCompleted } = useSelfPacedCardScroll()
  const [hasScrolledToBottomOnce, setHasScrolledToBottomOnce] = useState(false)
  const fileRequiresScrollToBottom = file.data.type === 'general'
  const isCardScrolledToBottom = useIsInViewport(completedRef)
  const cardScrollCompleted = !fileRequiresScrollToBottom || hasScrolledToBottomOnce === true

  useEffect(() => {
    if (isCardScrolledToBottom) setHasScrolledToBottomOnce(true)

    setScrollCompleted(cardScrollCompleted)
  }, [cardScrollCompleted, isCardScrolledToBottom, setScrollCompleted])

  return fileRequiresScrollToBottom ? <InvisibleElement ref={completedRef} /> : null
}
