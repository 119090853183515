import React, { useContext } from 'react'
import { ThemePicker } from 'sierra-client/components/common/inputs/theme-picker'
import { config } from 'sierra-client/config/global-config'
import { useTranslation } from 'sierra-client/hooks/use-translation'
import { CourseTheme } from 'sierra-domain/content/v2/content'
import { Button, Text } from 'sierra-ui/primitives'
import {
  ContentThemeTransformingTokenProvider,
  LightTokenProvider,
  palette,
  spacing,
} from 'sierra-ui/theming'
import { getTheme } from 'sierra-ui/theming/legacy-theme'
import styled, { ThemeContext, ThemeProvider } from 'styled-components'

type ThemeName = CourseTheme['name']

export const themeNames: Record<ThemeName, string> = {
  'white': 'White',
  'black': 'Black',
  'orange-darker-bright': 'Orange Darker Orange Bright',
  'yellow-darker-bright': 'Yellow Darker Yellow Bright',
  'green-darker-vivid': 'Green Darker Green Vivid',
  'blue-darker-vivid': 'Blue Darker Blue Vivid',
  'purple-darker-vivid': 'Purple Darker Purple Vivid',
  'pink-darker-vivid': 'Pink Darker Pink Vivid',
  'red-darker-vivid': 'Red Darker Red Vivid',
  'orange-bright-darker': 'Orange Bright Orange Darker',
  'yellow-bright-darker': 'Yellow Bright Yellow Darker',
  'green-vivid-darker': 'Green Vivid Green Darker',
  'blue-dark-light': 'Blue Dark Blue Light',
  'purple-vivid-darker': 'Purple Vivid Purple Darker',
  'pink-vivid-white': 'Pink Vivid White',
  'red-vivid-darker': 'Red Vivid Red Darker',
  'orange-light-dark': 'Orange Light Orange Dark',
  'yellow-pastel-bright': 'Yellow Pastel Yellow Bright',
  'green-pastel-darker': 'Green Pastel Green Darker',
  'blue-vivid-pastel': 'Blue Vivid Blue Pastel',
  'purple-light-dark': 'Purple Light Purple Dark',
  'pink-pastel-dark': 'Pink Pastel Pink Dark',
  'red-pastel-vivid': 'Red Pastel Red Vivid',
  'pink-dark': 'Pink Dark',
  'pink-light': 'Pink Light',
  'purple': 'Purple',
  'blue-light': 'Blue Light',
  'blue-bright': 'Blue',
  'blue-dark': 'Blue Dark',
  'green-dark': 'Green Dark',
  'green-light': 'Green Light',
  'orange-light': 'Orange Light',
  'orange-bright': 'Orange Dark',
  'custom-1': 'Custom1',
  'custom-2': 'Custom2',
  'custom-3': 'Custom3',
  'custom-4': 'Custom4',
  'custom-5': 'Custom5',
  'custom-6': 'Custom6',
  'custom-7': 'Custom7',
  'custom-8': 'Custom8',
  'custom-9': 'Custom9',
  'custom-10': 'Custom10',
  'custom-11': 'Custom11',
  'custom-12': 'Custom12',
  'custom-13': 'Custom13',
  'custom-14': 'Custom14',
  'custom-15': 'Custom15',
  'custom-16': 'Custom16',
  'custom-17': 'Custom17',
  'custom-18': 'Custom18',
  'custom-19': 'Custom19',
  'custom-20': 'Custom20',
  'custom-21': 'Custom21',
  'custom-22': 'Custom22',
  'custom-23': 'Custom23',
  'custom-24': 'Custom24',
  'custom-25': 'Custom25',
  'custom-26': 'Custom26',
  'custom-27': 'Custom27',
  'custom-28': 'Custom28',
}

const ThemeContainer = styled.div<{ $themeName: ThemeName }>`
  flex: 1;
  display: flex;
  align-items: center;
  justify-content: space-between;
  height: ${spacing['80']};
  flex-basis: ${spacing['80']};
  padding: 0 1rem;
  background-color: ${p => p.theme.home.backgroundColor};
  border-radius: 16px;
  border: 1px solid;
  border-color: ${p => (p.$themeName === 'white' ? palette.grey[5] : p.theme.home.backgroundColor)};
  transition:
    color 0.2s ease,
    background-color 0.2s ease,
    border-color 0.2s ease;
`

type ThemePickerProps = {
  theme: ThemeName
  onChange: (t: ThemeName) => void
  disabled?: boolean
}

export const PreviewThemePicker: React.FC<ThemePickerProps> = ({ theme, onChange, disabled }) => {
  const { t } = useTranslation()
  const orgConfig = config.organization
  const themeContext = useContext(ThemeContext)
  const courseTheme = getTheme(themeContext, theme)

  const updatedThemeNames: Partial<Record<ThemeName, string>> = {
    ...themeNames,
    'custom-1': orgConfig.settings.brand.customThemes.customTheme1?.customThemeName ?? 'Custom 1',
    'custom-2': orgConfig.settings.brand.customThemes.customTheme2?.customThemeName ?? 'Custom 2',
    'custom-3': orgConfig.settings.brand.customThemes.customTheme3?.customThemeName ?? 'Custom 3',
    'custom-4': orgConfig.settings.brand.customThemes.customTheme4?.customThemeName ?? 'Custom 4',
    'custom-5': orgConfig.settings.brand.customThemes.customTheme5?.customThemeName ?? 'Custom 5',
    'custom-6': orgConfig.settings.brand.customThemes.customTheme6?.customThemeName ?? 'Custom 6',
    'custom-7': orgConfig.settings.brand.customThemes.customTheme7?.customThemeName ?? 'Custom 7',
    'custom-8': orgConfig.settings.brand.customThemes.customTheme8?.customThemeName ?? 'Custom 8',
    'custom-9': orgConfig.settings.brand.customThemes.customTheme9?.customThemeName ?? 'Custom 9',
    'custom-10': orgConfig.settings.brand.customThemes.customTheme10?.customThemeName ?? 'Custom 10',
    'custom-11': orgConfig.settings.brand.customThemes.customTheme11?.customThemeName ?? 'Custom 11',
    'custom-12': orgConfig.settings.brand.customThemes.customTheme12?.customThemeName ?? 'Custom 12',
    'custom-13': orgConfig.settings.brand.customThemes.customTheme13?.customThemeName ?? 'Custom 13',
    'custom-14': orgConfig.settings.brand.customThemes.customTheme14?.customThemeName ?? 'Custom 14',
    'custom-15': orgConfig.settings.brand.customThemes.customTheme15?.customThemeName ?? 'Custom 15',
    'custom-16': orgConfig.settings.brand.customThemes.customTheme16?.customThemeName ?? 'Custom 16',
    'custom-17': orgConfig.settings.brand.customThemes.customTheme17?.customThemeName ?? 'Custom 17',
    'custom-18': orgConfig.settings.brand.customThemes.customTheme18?.customThemeName ?? 'Custom 18',
    'custom-19': orgConfig.settings.brand.customThemes.customTheme19?.customThemeName ?? 'Custom 19',
    'custom-20': orgConfig.settings.brand.customThemes.customTheme20?.customThemeName ?? 'Custom 20',
    'custom-21': orgConfig.settings.brand.customThemes.customTheme21?.customThemeName ?? 'Custom 21',
    'custom-22': orgConfig.settings.brand.customThemes.customTheme22?.customThemeName ?? 'Custom 22',
    'custom-23': orgConfig.settings.brand.customThemes.customTheme23?.customThemeName ?? 'Custom 23',
    'custom-24': orgConfig.settings.brand.customThemes.customTheme24?.customThemeName ?? 'Custom 24',
    'custom-25': orgConfig.settings.brand.customThemes.customTheme25?.customThemeName ?? 'Custom 25',
    'custom-26': orgConfig.settings.brand.customThemes.customTheme26?.customThemeName ?? 'Custom 26',
    'custom-27': orgConfig.settings.brand.customThemes.customTheme27?.customThemeName ?? 'Custom 27',
    'custom-28': orgConfig.settings.brand.customThemes.customTheme28?.customThemeName ?? 'Custom 28',
  }

  return (
    <ThemeProvider theme={courseTheme}>
      <ContentThemeTransformingTokenProvider>
        <ThemeContainer $themeName={theme}>
          <Text size='small'>{updatedThemeNames[theme]}</Text>
          <LightTokenProvider>
            <ThemePicker
              currentTheme={theme}
              onClick={onChange}
              trigger={
                <Button variant={theme === 'white' ? 'secondary' : 'primary'} disabled={disabled}>
                  {t('manage.change-theme')}
                </Button>
              }
            />
          </LightTokenProvider>
        </ThemeContainer>
      </ContentThemeTransformingTokenProvider>
    </ThemeProvider>
  )
}
