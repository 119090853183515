import { keepPreviousData } from '@tanstack/react-query'
import React, { useCallback, useState } from 'react'
import { graphql } from 'sierra-client/api/graphql/gql'
import { useGraphQuery } from 'sierra-client/api/hooks/use-graphql-query'
import { Thumbnail } from 'sierra-client/components/common/thumbnail'
import { useTranslation } from 'sierra-client/hooks/use-translation'
import { Context } from 'sierra-client/lib/filter/components/common'
import {
  addPredValue,
  fromPredicate,
  removePredValue,
  ValueAnchor,
} from 'sierra-client/lib/filter/components/predicate-utils'
import { CertificateId } from 'sierra-domain/api/nano-id'
import { Predicate } from 'sierra-domain/filter/datatype/pred'
import { setPred } from 'sierra-domain/filter/operations'
import { isDefined } from 'sierra-domain/utils'
import { Autocomplete, DefaultStyledAutocompleteOption, FormElement } from 'sierra-ui/components'
import { ListContainer, SelectedPill } from 'sierra-ui/components/autocomplete/reference-implementation/atoms'
import { IconButton, Text } from 'sierra-ui/primitives'

const certificatesQuery = graphql(`
  query CertificatesAutoComplete($withTitle: String!, $limit: Int!) {
    certificates: certificates(withTitle: $withTitle, limit: $limit) {
      id
      title
      previewImageUrl
    }
  }
`)

const certificatesByIdsQuery = graphql(`
  query CertificatesByIds($ids: [CertificateId!]) {
    certificates(includeIds: $ids) {
      id
      title
      previewImageUrl
    }
  }
`)

const emptyArray: AutoCompleteCertificate[] = []

type AutoCompleteCertificate = {
  id: string
  title: string
  previewImageUrl: string
  value: { type: 'value.certificate-id'; value: string }
}

const useCertificatesFromPredicate = (predicate: Predicate): AutoCompleteCertificate[] | readonly [] => {
  const certificateIds = fromPredicate(predicate)
    .map(value =>
      value.type === 'value.certificate-id' || value.type === 'value.nanoid12' ? value.value : undefined
    )
    .filter(isDefined)

  const response = useGraphQuery(
    {
      document: certificatesByIdsQuery,
      queryOptions: {
        enabled: certificateIds.length !== 0,
        staleTime: 60 * 1000,
        placeholderData: keepPreviousData,
      },
    },
    { ids: certificateIds }
  )

  if (certificateIds.length === 0 || response.data === undefined) {
    return emptyArray
  }

  return response.data.certificates.map(certificate => ({
    id: certificate.id,
    title: certificate.title,
    previewImageUrl: certificate.previewImageUrl,
    value: { type: 'value.certificate-id', value: certificate.id },
  }))
}

const useLabelsForCertificatePredicate = (predicate: Predicate): string[] => {
  const certificates = useCertificatesFromPredicate(predicate)
  const labels = certificates.map(it => it.title)
  return labels
}

const useQueryCertificatesByIds = (predicate: Predicate): AutoCompleteCertificate[] => {
  const certificates = useCertificatesFromPredicate(predicate)

  return certificates.map(certificate => {
    return {
      id: certificate.id,
      title: certificate.title,
      previewImageUrl: certificate.previewImageUrl,
      value: { type: 'value.certificate-id', value: certificate.id },
    }
  })
}

type CertificateAutoCompleteProps = {
  ctx: Context
  predicate: Predicate
}

export const CertificateAutoComplete: React.FC<CertificateAutoCompleteProps> = ({ ctx, predicate }) => {
  const { t } = useTranslation()

  const selectedCertificates = useQueryCertificatesByIds(predicate)

  const [query, setQuery] = useState('')

  const onSelect = useCallback(
    (certificate: AutoCompleteCertificate) => {
      ctx.update(f => setPred(f, addPredValue(predicate, certificate.value)))
    },
    [ctx, predicate]
  )

  const onUnselect = useCallback(
    (certificate: AutoCompleteCertificate) => {
      ctx.update(f => setPred(f, removePredValue(predicate, certificate.value)))
    },
    [ctx, predicate]
  )

  const searchCertificates = useGraphQuery(
    {
      document: certificatesQuery,
      queryOptions: { staleTime: 60 * 1000, placeholderData: keepPreviousData },
    },
    { withTitle: query, limit: 20 }
  )

  const matchingCertificates: AutoCompleteCertificate[] =
    searchCertificates.data?.certificates.map(it => ({
      id: CertificateId.parse(it.id),
      title: it.title,
      previewImageUrl: it.previewImageUrl,
      value: { type: 'value.certificate-id', value: it.id },
    })) ?? emptyArray

  return (
    <FormElement label={t('dictionary.certificate-plural')}>
      <Autocomplete
        placeholder={t('menu.search.placeholder')}
        autofocus
        query={query}
        onQueryChange={setQuery}
        selectedItems={selectedCertificates}
        matchingItems={matchingCertificates}
        onSelect={onSelect}
        onUnselect={onUnselect}
        renderSelectedItem={(certificate, { onUnselect, ...props }) => (
          <SelectedPill key={certificate.id} {...props}>
            <Text color='foreground/primary' bold>
              {certificate.title}
            </Text>
            <Thumbnail image={certificate.previewImageUrl} height={1} width={1.375} radius={0.25} />
            <IconButton iconId='close' onClick={onUnselect} size='small' variant='transparent' />
          </SelectedPill>
        )}
        renderMatchingItemList={({ getItemProps }) => (
          <ListContainer>
            {matchingCertificates.map((certificate, index) => (
              <DefaultStyledAutocompleteOption key={certificate.id} {...getItemProps(certificate, index)}>
                <Thumbnail image={certificate.previewImageUrl} height={1} width={1.375} radius={0.25} />
                <Text color='foreground/primary'>{certificate.title}</Text>
              </DefaultStyledAutocompleteOption>
            ))}
          </ListContainer>
        )}
      />
    </FormElement>
  )
}

export const CertificateAutoCompleteLabel = React.forwardRef<HTMLDivElement, { predicate: Predicate }>(
  ({ predicate, ...rest }, ref) => {
    const labels = useLabelsForCertificatePredicate(predicate)
    const label = labels.join(', ')

    return <ValueAnchor {...rest} ref={ref} label={label} />
  }
)
