import { useQuery } from '@tanstack/react-query'
import { useMemo } from 'react'
import { graphql } from 'sierra-client/api/graphql/gql'
import { useGraphQuery } from 'sierra-client/api/hooks/use-graphql-query'
import { usePost } from 'sierra-client/hooks/use-post'
import { FeaturedOrganizationContent } from 'sierra-domain/api/admin'
import { XRealtimeAdminListFeaturedContentForOrganization } from 'sierra-domain/routes'

const featuredContentIdsQuery = graphql(`
  query FeaturedContentIds {
    featuredContentIds
  }
`)

type UseFeaturedContentIds = {
  featuredContentIds: string[]
  refetch: () => void
}

export const useFeaturedContentIds = (): UseFeaturedContentIds => {
  const { data, refetch } = useGraphQuery({ document: featuredContentIdsQuery }, {})

  const ids = useMemo(() => data?.featuredContentIds ?? [], [data?.featuredContentIds])

  return {
    featuredContentIds: ids,
    refetch,
  }
}

type UseFeaturedContent = {
  content?: FeaturedOrganizationContent[]
  isLoading: boolean
  refetch: () => void
}

export const useFeaturedContent = (): UseFeaturedContent => {
  const { postWithUserErrorException } = usePost()
  const { data, isLoading, refetch } = useQuery({
    queryKey: ['featured-content'],
    queryFn: async () => {
      const response = await postWithUserErrorException(XRealtimeAdminListFeaturedContentForOrganization, {})

      return response.content
    },
  })

  return {
    content: data ?? [],
    isLoading,
    refetch,
  }
}
