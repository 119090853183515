import { atom, useAtom } from 'jotai'
import { maxBy } from 'lodash'
import { useCallback, useEffect } from 'react'
import { courseSettingsChannel } from 'sierra-client/realtime-data/channels'
import { useCachedQuery } from 'sierra-client/state/api'
import { CreateContentId } from 'sierra-domain/api/nano-id'
import { XRealtimeAuthorGetCourseSettings } from 'sierra-domain/routes'

type UseCourseTitleData =
  | {
      loading: true
    }
  | {
      loading: false
      title: string
    }

type TitleResult = { title: string; updatedAt: Date }
const titleCacheAtom = atom<{ [courseId: string]: TitleResult | undefined }>({})

/**
 * Realtime updated course title, so it can be used in realtime environeents like the editor
 * It will cache previous course title values to make the title available immediatly before doing a request for the latest value
 *
 * Requires the author permission!
 */
export const useRealtimeCourseTitle = (courseId: CreateContentId): UseCourseTitleData => {
  const [liveTitleCache, setLiveTitleCache] = useAtom(titleCacheAtom)

  const updateCache = useCallback(
    (newData: TitleResult) => {
      setLiveTitleCache(cache => {
        const existing = cache[courseId]
        if (existing !== undefined) {
          return { ...cache, [courseId]: maxBy([newData, existing], 'updatedAt') }
        }
        return { ...cache, [courseId]: newData }
      })
    },
    [courseId, setLiveTitleCache]
  )

  courseSettingsChannel.useChannel({
    channelId: courseId,
    callback: event => {
      if (event.event === 'course-title-changed') {
        updateCache(event.data)
      }
    },
  })

  const queryResult = useCachedQuery(XRealtimeAuthorGetCourseSettings, { courseId })

  useEffect(() => {
    if (queryResult.data) {
      updateCache({
        title: queryResult.data.settings.title,
        updatedAt: new Date(queryResult.data.updatedAt),
      })
    }
  }, [queryResult.data, updateCache])

  const title = liveTitleCache[courseId]?.title

  if (title !== undefined) {
    return { loading: false, title }
  }

  return { loading: true }
}
