import React, { forwardRef, useMemo } from 'react'
import { useTranslation } from 'sierra-client/hooks/use-translation'
import { Or, defaultOp, domainRefHash } from 'sierra-client/lib/filter'
import { Context } from 'sierra-client/lib/filter/components/common'
import { getDomainRepItems } from 'sierra-client/lib/filter/components/get-domain-rep-items'
import { MenuItemWithDomainRep } from 'sierra-client/lib/filter/components/types'
import * as UI from 'sierra-client/lib/filter/ui'
import { FilterBase, createFilter } from 'sierra-domain/filter/datatype/filter'
import { MenuItem } from 'sierra-ui/components'
import { SingleSelectDropdown } from 'sierra-ui/primitives/menu-dropdown'

const AttrAnchor = forwardRef<HTMLDivElement, { label: string }>(({ label, ...rest }, anchorRef) => {
  return (
    <UI.Attribute.Wrapper {...rest} ref={anchorRef} readOnly={false}>
      <UI.Attribute.Text>{label}</UI.Attribute.Text>
    </UI.Attribute.Wrapper>
  )
})

const getAllSubItems = (menuItems: MenuItem[]): MenuItem[] => {
  return menuItems.flatMap(it =>
    it.type === 'nested' || it.type === 'group' ? getAllSubItems(it.menuItems) : it
  )
}

export const Domain: React.FC<{ ctx: Context; filter: FilterBase }> = React.memo(({ ctx, filter }) => {
  const { dynamicT, t } = useTranslation()

  const items: MenuItemWithDomainRep[] = useMemo(() => {
    if (ctx.menuItems !== undefined) {
      return ctx.menuItems
    }
    return getDomainRepItems(ctx.domainReps, dynamicT, filter, false)
  }, [ctx.domainReps, ctx.menuItems, dynamicT, filter])

  const onSelect = (item: MenuItemWithDomainRep): void => {
    if (item.value !== undefined) {
      const value = item.value
      ctx.update(() => createFilter(value.ref, defaultOp(value), Or([])))
    }
  }

  const selectedItem = useMemo(() => {
    const allItems = getAllSubItems(items)
    return allItems.find(it => it.id === domainRefHash(filter.domain))
  }, [filter.domain, items])

  return (
    <SingleSelectDropdown
      selectedItem={selectedItem}
      withSearch={items.length > 8}
      searchPlaceholder={t('menu.search.placeholder')}
      menuItems={items}
      onSelect={onSelect}
      renderTrigger={() =>
        selectedItem !== undefined ? (
          <AttrAnchor label={selectedItem.type === 'label' ? selectedItem.label : selectedItem.id} />
        ) : null
      }
    />
  )
})
