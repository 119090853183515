import { useSetAtom } from 'jotai/index'
import { useCallback, useState } from 'react'
import { graphQuery } from 'sierra-client/api/hooks/use-graphql-query'
import {
  initialUserAttributesAtom,
  userAttributesDataAtom,
} from 'sierra-client/views/manage/components/user-attributes/flows/user-attribute-settings/atoms'
import { getUserAttributesQuery } from 'sierra-client/views/manage/components/user-attributes/flows/user-attribute-settings/queries'
import { userDataToInvitationAttributes } from 'sierra-client/views/manage/components/user-attributes/flows/user-attribute-settings/utils'
import { useUserDetailData } from 'sierra-client/views/manage/users/manage-user-details/hooks/use-user-detail-data'
import { UserId } from 'sierra-domain/api/uuid'

export const useInitialiseAttributesDraft = (
  userId: UserId
): { reinitialise: () => Promise<void>; isLoading: boolean } => {
  const { refetch: refetchUserData, isLoading: isLoadingUserData } = useUserDetailData(userId)
  const setUserAttributesData = useSetAtom(userAttributesDataAtom)
  const setInitialUserAttributes = useSetAtom(initialUserAttributesAtom)
  const [isLoading, setIsLoading] = useState<boolean>(true)

  const reinitialise = useCallback(async () => {
    setIsLoading(true)
    const userDataRefetched = await refetchUserData()
    const userData = userDataRefetched.data
    const userAttributes = await graphQuery(getUserAttributesQuery, { userId })

    if (userData !== undefined) {
      const initial = userDataToInvitationAttributes(userData, userAttributes)
      setInitialUserAttributes(initial)
      setUserAttributesData(initial)
    }

    setIsLoading(false)
  }, [refetchUserData, setInitialUserAttributes, setUserAttributesData, userId])

  return {
    reinitialise,
    isLoading: isLoadingUserData || isLoading,
  }
}
