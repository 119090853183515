import _ from 'lodash'
import { useEffect, useRef } from 'react'

function defaultOnChange(change: unknown): void {
  // eslint-disable-next-line no-console
  console.log('[usePropMonitor] Changes:', change)
}

function copy(obj: Record<string, unknown>): Record<string, unknown> {
  const entries: [string, unknown][] = Object.entries(obj).map(([key, value]) => [
    key,
    typeof value === 'function' ? `New function ref [${value.name}]` : _.cloneDeep(value),
  ])
  return Object.fromEntries(entries)
}

/**
 * This hook can help you debug issues with react dependency arrays. If you are
 * not sure which prop in a dependency array is changing, pass the array as an object
 * literal to this hook (the object will let you attach a key to each value). The hook
 * will tell you when the value change.
 *
 * Example:
 * ```
 * // Render 1:
 * usePropMonitor({a: 'a', b: 'b' })
 *
 * // Render 2 (later):
 * usePropMonitor({a: 'a', b: 'b2' }) // "Changes: [{ b: 'b2' }]" is logged to the console!
 * ```
 */
export function usePropMonitor(
  dependencies: Record<string, unknown>,
  { onChange = defaultOnChange }: { onChange?: (change: Record<string, unknown>[]) => void } = {}
): void {
  const argsRef = useRef(dependencies)
  useEffect(() => {
    const changes = Object.entries(dependencies).flatMap(([currentKey, currentValue]) => {
      const previousValue = argsRef.current[currentKey]
      if (previousValue !== currentValue) return [copy({ [currentKey]: currentValue })]
      else return []
    })

    if (changes.length > 0) onChange(changes)

    argsRef.current = dependencies
  })
}
