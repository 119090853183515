import React from 'react'
import { useNotif } from 'sierra-client/components/common/notifications'
import { usePost } from 'sierra-client/hooks/use-post'
import { useTranslation } from 'sierra-client/hooks/use-translation'
import { Filter } from 'sierra-client/lib/filter'
import { SavedCustomHeatmapSummary } from 'sierra-domain/api/heatmap'
import { XRealtimeAdminHeatmapSaveHeatmap, XRealtimeAdminHeatmapUpdateHeatmap } from 'sierra-domain/routes'
import { FormElement, Input, Modal } from 'sierra-ui/components'
import { Button, Heading, Spacer, Text, TextAreaPrimitive, View } from 'sierra-ui/primitives'
import { v2_breakpoint } from 'sierra-ui/theming/breakpoints'
import styled from 'styled-components'

const SaveModalContainer = styled.div`
  display: flex;
  flex-direction: column;
  padding: 32px;
  width: calc(100vw - 64px);

  @media screen and (min-width: ${v2_breakpoint.tablet}) {
    width: 600px;
  }
`

type SaveReportModalContentsProps = {
  savedSummary?: SavedCustomHeatmapSummary
  query?: string
  filter: Filter
  contentIds?: string[]
  onClose: () => void
  onSave: (summary: SavedCustomHeatmapSummary) => void
}

const SaveReportModalContents: React.FC<SaveReportModalContentsProps> = ({
  savedSummary,
  query,
  filter,
  contentIds,
  onClose,
  onSave,
}) => {
  const { postWithUserErrorException } = usePost()
  const [title, setTitle] = React.useState(savedSummary?.title ?? '')
  const [description, setDescription] = React.useState(savedSummary?.description ?? '')
  const [loading, setLoading] = React.useState(false)
  const { push } = useNotif()
  const { t } = useTranslation()

  const handleSave = async (): Promise<void> => {
    setLoading(true)

    try {
      const heatmapId = savedSummary?.id

      // TODO: Return all of saved report from save endpoints?
      const dataToSave = {
        title,
        description,
        spec: {
          type: 'custom',
          query,
          filter,
          contentIds: contentIds ?? [],
        },
      } as const
      const res = await (heatmapId === undefined
        ? postWithUserErrorException(XRealtimeAdminHeatmapSaveHeatmap, {
            data: dataToSave,
          })
        : postWithUserErrorException(XRealtimeAdminHeatmapUpdateHeatmap, {
            id: heatmapId,
            data: dataToSave,
          }))

      onSave({
        type: 'custom',
        id: res.id,
        title,
        description,
        // TODO: Return actual value from save/update endpoints
        updatedAt: new Date().toISOString(),
      })

      push({ type: 'success' })

      onClose()
    } catch (e) {
      console.error(e)
      return
    } finally {
      setLoading(false)
    }
  }

  return (
    <SaveModalContainer>
      <Heading size='h4'>{t('manage.heatmap.add-favorite-title')}</Heading>
      <Text size='regular'>{t('manage.heatmap.add-favorite-description')}</Text>
      <Spacer size='xsmall' />
      <Input
        id='heatmap-title'
        label={t('manage.heatmap.name')}
        value={title}
        onChange={e => setTitle(e.target.value)}
      />

      <FormElement
        label={t('manage.heatmap.description')}
        htmlFor='heatmap-description'
        helper={t('input.optional')}
      >
        <TextAreaPrimitive
          id='heatmap-description'
          rows={3}
          autoExpand
          value={description}
          onChange={e => setDescription(e.target.value)}
        />
      </FormElement>
      <Spacer size='medium' />
      <View justifyContent='flex-end'>
        <Button variant='secondary' onClick={onClose}>
          {t('modal.cancel')}
        </Button>
        <Button variant='primary' onClick={handleSave} loading={loading}>
          {t('modal.save')}
        </Button>
      </View>
    </SaveModalContainer>
  )
}

type SaveReportModalProps = {
  open: boolean
  onClose: () => void
} & SaveReportModalContentsProps

export const SaveReportModal: React.FC<SaveReportModalProps> = ({ open, onClose, ...rest }) => (
  <Modal open={open} onClose={onClose} size='fit-content'>
    <SaveReportModalContents {...rest} onClose={onClose} />
  </Modal>
)
