import { AnimatePresence, motion } from 'framer-motion'
import { useEffect, useState } from 'react'
import { SkillId, type SkillRecommendationId } from 'sierra-client/api/graphql/branded-types'
import { graphql } from 'sierra-client/api/graphql/gql'
import { useGraphQuery } from 'sierra-client/api/hooks/use-graphql-query'
import { SimpleBadge } from 'sierra-client/features/skills/components/badges/simple-badge'
import { BadgeIconId } from 'sierra-client/features/skills/components/badges/types'
import { ContentSkillLevelDropdown } from 'sierra-client/features/skills/components/content-skill/content-skill-level-dropdown'
import { useSkillsAssignedToContent } from 'sierra-client/features/skills/components/content-skill/gql'
import type { ContentSkill } from 'sierra-client/features/skills/components/content-skill/types'
import {
  useAcceptRecommendationMutation,
  useIsSkillAutoAssignAllowed,
  useRejectRecommendationMutation,
  useResetSkillRecommendationForCourseQuery,
  useSkillRecommendationForCourse,
  useTriggerAutoAssignMutation,
} from 'sierra-client/features/skills/components/recommendation/gql'
import {
  SkillRecommendation,
  type ItemChangeHandler,
} from 'sierra-client/features/skills/components/recommendation/skill-recommendation'
import { useInvalidateGetSkillsQueries } from 'sierra-client/features/skills/shared-gql-queries'
import { useOnMount } from 'sierra-client/hooks/use-on-mount'
import { useTranslation } from 'sierra-client/hooks/use-translation'
import type { CourseId } from 'sierra-domain/api/nano-id'
import { isDefined, isNotDefined } from 'sierra-domain/utils'
import { MenuItem, TruncatedText } from 'sierra-ui/components'
import { Button, View } from 'sierra-ui/primitives'
import { IconMenu } from 'sierra-ui/primitives/menu-dropdown'
import { MenuDropdownPrimitive } from 'sierra-ui/primitives/menu-dropdown/menu-dropdown-primitive'
import { token } from 'sierra-ui/theming'
import styled, { css } from 'styled-components'
import { useTracking } from '../../tracking'

const skillsAvailableForContentQuery = graphql(`
  query SkillsAvailableForContent {
    skills {
      data {
        ...ContentSkillDetails
      }
    }
  }
`)

const SkillSelectionList = styled.ul<{ $withBorder: boolean }>`
  list-style: none;
  width: 100%;

  ${p =>
    p.$withBorder &&
    css`
      border-bottom: 1px solid ${token('form/border/1')};
    `}
`

const SkillSelectionRowContainer = styled(View)`
  background-color: ${token('surface/default')};
  height: 48px;
  padding-block: 12px;
  padding-inline: 12px 20px;
  border-top: 1px solid ${token('form/border/1')};
`

const BadgeContainer = styled.div`
  flex-shrink: 0;
  width: 24px;
  height: 24px;
`

const ActionsContainer = styled(View)`
  flex-shrink: 0;
`

const SkillSelectionRow: React.FC<{
  skill: ContentSkill
  onRemove: (skill: ContentSkill) => void
  onLevelChange: (skill: ContentSkill) => void
}> = ({ skill, onRemove, onLevelChange }) => {
  const { t } = useTranslation()

  return (
    <SkillSelectionRowContainer grow justifyContent='space-between'>
      <View gap='8'>
        <BadgeContainer>
          <SimpleBadge iconId={skill.iconId} theme={skill.theme} />
        </BadgeContainer>
        <TruncatedText lines={2}>{skill.name}</TruncatedText>
      </View>

      <ActionsContainer gap='24'>
        <View paddingRight='14'>
          <ContentSkillLevelDropdown skill={skill} onSelect={onLevelChange} />
        </View>
        <IconMenu
          size='small'
          iconId='overflow-menu--horizontal'
          variant='transparent'
          onSelect={() => {
            onRemove(skill)
          }}
          menuItems={[
            {
              id: 'remove',
              type: 'label',
              label: t('dictionary.remove'),
              icon: 'trash-can',
              color: 'destructive/background',
            },
          ]}
        />
      </ActionsContainer>
    </SkillSelectionRowContainer>
  )
}

export const ContentSkillSelection: React.FC<{
  selection: ContentSkill[]
  onSkillAdd: ItemChangeHandler
  onSkillRemove: ItemChangeHandler
  onSkillLevelChange: ItemChangeHandler
}> = ({ selection, onSkillAdd, onSkillRemove, onSkillLevelChange }) => {
  const { t } = useTranslation()

  const { data: availableSkills } = useGraphQuery(
    {
      document: skillsAvailableForContentQuery,
      queryOptions: {
        select(data) {
          return data.skills.data
        },
      },
    },
    {}
  )

  const menuItems: MenuItem<SkillId>[] =
    availableSkills
      ?.filter(skill => !selection.some(selected => selected.id === skill.id))
      .map(skill => ({
        type: 'label',
        id: skill.id,
        label: skill.name,
      })) ?? []

  const [dropdownOpen, setDropdownOpen] = useState(false)

  useEffect(() => {
    if (menuItems.length === 0 && dropdownOpen) {
      // The Radix dropdown menu component doesn't trigger the `onOpenChange`
      // callback when the `isOpen` state is programmatically changed.
      setDropdownOpen(false)
    }
  }, [dropdownOpen, menuItems.length])

  return (
    <View direction='column' gap='12'>
      <SkillSelectionList $withBorder={selection.length > 0}>
        <AnimatePresence>
          {selection.map(skill => (
            <motion.li
              key={skill.id}
              initial={{ opacity: 0, height: 0 }}
              animate={{ opacity: 1, height: 'auto' }}
              exit={{ opacity: 0, height: 0 }}
              transition={{ duration: 0.2, ease: [0.25, 0.1, 0.25, 1] }}
            >
              <SkillSelectionRow skill={skill} onRemove={onSkillRemove} onLevelChange={onSkillLevelChange} />
            </motion.li>
          ))}
        </AnimatePresence>
      </SkillSelectionList>

      <MenuDropdownPrimitive
        maxHeight={300}
        closeOnSelect={false}
        isOpen={dropdownOpen && menuItems.length > 0}
        onOpenChange={setDropdownOpen}
        menuItems={menuItems}
        disabled={menuItems.length === 0}
        withSearch
        searchPlaceholder={t('menu.search.placeholder')}
        onSelect={item => {
          const skill = availableSkills?.find(skill => skill.id === item.id)
          if (isDefined(skill)) {
            onSkillAdd({
              id: skill.id,
              name: skill.name,
              iconId: BadgeIconId.catch('locked').parse(skill.defaultBadgeIcon),
              theme: 'disabled',
              levelSettingId: undefined,
              levels: skill.skillLevels,
            })
          }
        }}
        renderTrigger={() => (
          <Button variant='secondary' icon='plus--circle'>
            {t('dictionary.add')}
          </Button>
        )}
      />
    </View>
  )
}

const _SkillRecommendation: React.FC<{
  courseId: CourseId
  onAccept: ItemChangeHandler
  onRetriggerAutoAssign: () => void
  skillsToIgnore: ContentSkill[]
}> = ({ courseId, skillsToIgnore, onAccept, onRetriggerAutoAssign }) => {
  const skillRecommendation = useSkillRecommendationForCourse(courseId)
  const resetSkillRecommendationQuery = useResetSkillRecommendationForCourseQuery(courseId)
  const tracking = useTracking()
  const [recommendedSkills, setRecommendedSkills] = useState<ContentSkill[] | undefined>(undefined)

  useEffect(() => {
    if (isNotDefined(recommendedSkills)) {
      if (skillRecommendation.data?.status === 'Done') {
        setRecommendedSkills(skillRecommendation.data.skills)
      } else if (skillRecommendation.data?.status === 'ContentNotFound') {
        setRecommendedSkills([])
      }
    }
  }, [skillsToIgnore, recommendedSkills, skillRecommendation.data])

  const getRecommendationId = (skillId: SkillId): SkillRecommendationId | undefined => {
    if (
      skillRecommendation.data?.status === 'Done' &&
      skillRecommendation.data.recommendationMap.has(skillId)
    ) {
      return skillRecommendation.data.recommendationMap.get(skillId)
    } else {
      return undefined
    }
  }

  const invalidateSkillsQueries = useInvalidateGetSkillsQueries()
  const { mutate: acceptRecommendationMutation } = useAcceptRecommendationMutation({
    onSuccess: () => {
      void invalidateSkillsQueries()
    },
  })
  const { mutate: rejectRecommendationMutation } = useRejectRecommendationMutation({
    onSuccess: () => {
      void invalidateSkillsQueries()
    },
  })

  return (
    <SkillRecommendation
      recommendation={recommendedSkills?.filter(
        skill => !skillsToIgnore.some(ignore => ignore.id === skill.id)
      )}
      onAccept={skill => {
        setRecommendedSkills(prev => (isDefined(prev) ? prev.filter(it => it.id !== skill.id) : prev))
        onAccept(skill)

        const recommendationId = getRecommendationId(skill.id)
        if (isDefined(recommendationId)) {
          acceptRecommendationMutation({ id: recommendationId })
          tracking.smartSuggestion.accept({ skillRecommendationId: recommendationId })
        }
      }}
      onReject={skill => {
        setRecommendedSkills(prev => (isDefined(prev) ? prev.filter(it => it.id !== skill.id) : prev))

        const recommendationId = getRecommendationId(skill.id)
        if (isDefined(recommendationId)) {
          rejectRecommendationMutation({ id: recommendationId })
          tracking.smartSuggestion.reject({ skillRecommendationId: recommendationId })
        }
      }}
      onLevelChange={skill => {
        setRecommendedSkills(
          prev =>
            prev?.map(it => {
              if (it.id === skill.id) {
                tracking.smartSuggestion.changeLevel({
                  skillId: skill.id,
                  newLevelSettingId: skill.levelSettingId,
                  prevLevelSettingId: it.levelSettingId,
                })
                return skill
              } else {
                return it
              }
            })
        )
      }}
      onRetriggerAutoAssign={() => {
        void resetSkillRecommendationQuery()
        setRecommendedSkills(undefined)
        onRetriggerAutoAssign()
        tracking.smartSuggestion.retrigger({ courseId: courseId })
      }}
    />
  )
}

type SelectionChangeHandlers = {
  onSkillAdd?: (skill: ContentSkill, onError: () => void) => void
  onSkillRemove?: (skill: ContentSkill, onError: () => void) => void
  onSkillLevelChange?: (skill: ContentSkill, onError: () => void) => void
}

const _ContentSkillSelectionWithRecommendation: React.FC<
  SelectionChangeHandlers & {
    autoAssignAllowed: boolean
    courseId: CourseId
    initialSelection: ContentSkill[] | undefined
  }
> = ({ courseId, initialSelection, autoAssignAllowed, onSkillAdd, onSkillRemove, onSkillLevelChange }) => {
  const [selectedSkills, setSelectedSkills] = useState<ContentSkill[]>(initialSelection ?? [])

  const { mutate: triggerAutoAssignMutation } = useTriggerAutoAssignMutation()
  const [isAutoAssignTriggered, setAutoAssignTriggered] = useState(false)
  const triggerAutoAssign = (): void => {
    if (autoAssignAllowed) {
      triggerAutoAssignMutation(
        { courseId },
        {
          onSettled: () => {
            setAutoAssignTriggered(true)
          },
        }
      )
    }
  }

  useOnMount(() => {
    triggerAutoAssign()
  })

  return (
    <div>
      {isAutoAssignTriggered && (
        <_SkillRecommendation
          courseId={courseId}
          skillsToIgnore={selectedSkills}
          onAccept={skill => {
            const snapshot = [...selectedSkills]
            setSelectedSkills(prev => {
              const isSkillAlreadyIncluded = prev.some(existingSkill => existingSkill.id === skill.id)
              return isSkillAlreadyIncluded ? prev : [...prev, skill]
            })
            onSkillAdd?.(skill, () => {
              setSelectedSkills(snapshot)
            })
          }}
          onRetriggerAutoAssign={triggerAutoAssign}
        />
      )}
      <ContentSkillSelection
        selection={selectedSkills}
        onSkillAdd={skill => {
          const snapshot = [...selectedSkills]
          setSelectedSkills(prev => [...prev, skill])
          onSkillAdd?.(skill, () => {
            setSelectedSkills(snapshot)
          })
        }}
        onSkillRemove={skill => {
          const snapshot = [...selectedSkills]
          setSelectedSkills(prev => prev.filter(s => s.id !== skill.id))
          onSkillRemove?.(skill, () => {
            setSelectedSkills(snapshot)
          })
        }}
        onSkillLevelChange={skill => {
          const snapshot = [...selectedSkills]
          setSelectedSkills(prev => prev.map(s => (s.id === skill.id ? skill : s)))
          onSkillLevelChange?.(skill, () => {
            setSelectedSkills(snapshot)
          })
        }}
      />
    </div>
  )
}

export const ContentSkillSelectionWithRecommendation: React.FC<
  SelectionChangeHandlers & {
    courseId: CourseId
  }
> = ({ courseId, onSkillAdd, onSkillRemove, onSkillLevelChange }) => {
  const contentId = `course:${courseId}`
  const assignedSkills = useSkillsAssignedToContent(contentId)
  const isSkillAutoAssignAllowed = useIsSkillAutoAssignAllowed()

  if (assignedSkills.isLoading || isSkillAutoAssignAllowed.status === 'pending') {
    return null
  }

  return (
    <_ContentSkillSelectionWithRecommendation
      courseId={courseId}
      autoAssignAllowed={isSkillAutoAssignAllowed.allowed}
      initialSelection={assignedSkills.data}
      onSkillAdd={onSkillAdd}
      onSkillRemove={onSkillRemove}
      onSkillLevelChange={onSkillLevelChange}
    />
  )
}
