import { color } from 'sierra-ui/color'
import { Icon } from 'sierra-ui/components'
import { theme } from 'sierra-ui/theming/legacy-theme'
import styled, { css } from 'styled-components'

const iconBaseStyle = css`
  cursor: pointer;
  transition: color 0.2s ease;

  &:hover {
    color: ${theme.color.grey85};
  }

  /* Larger click surface */
  &::after {
    content: '';
    position: absolute;
    top: 50%;
    left: 50%;
    width: 2rem;
    height: 2rem;
    transform: translate(-50%, -50%);
  }
`

export const CloseIcon = styled(Icon).attrs({
  iconId: 'close',
  size: 'size-14',
  color: color('#A6A6A6'),
})`
  ${iconBaseStyle};
  position: absolute;
  top: 2rem;
  right: 1.5rem;
  z-index: 10;
`

export const RemoveIcon = styled(Icon).attrs({
  iconId: 'close',
  size: 'size-14',
  color: color('#A6A6A6'),
})`
  position: relative;
  ${iconBaseStyle}
`
