import { DateTime } from 'luxon'
import React, { FC, useMemo } from 'react'
import { CellProps, Column, useTable } from 'react-table'
import { downloadBlob } from 'sierra-client/api'
import { RouterLink } from 'sierra-client/components/common/link'
import { Menu } from 'sierra-client/components/common/menu'
import { useNotif } from 'sierra-client/components/common/notifications'
import { Table } from 'sierra-client/components/table/table'
import { SmallTableScrollContainer } from 'sierra-client/components/table/table-utils'
import { useTranslation } from 'sierra-client/hooks/use-translation'
import { MenuContainer } from 'sierra-client/views/manage/components/common'
import { SmallTableWrapper } from 'sierra-client/views/manage/components/small-table-wrapper'
import {
  LiveSessionDetailsResponse,
  LiveSessionListRecordingsResponse,
  LiveSessionRecording,
} from 'sierra-domain/api/admin'
import { Icon } from 'sierra-ui/components'
import { MUIMenuItem } from 'sierra-ui/mui'
import { Text, View } from 'sierra-ui/primitives'
import { palette } from 'sierra-ui/theming'
import styled from 'styled-components'

type TableData = LiveSessionListRecordingsResponse & { isLoading: boolean }
type OnRemoveRecording = (recordingId: string) => void
type OnRecordingClicked = (recording: LiveSessionRecording) => void

const VideoPreview = styled.div`
  background-color: ${palette.primitives.black};
  width: 88px;
  height: 64px;
  border-radius: ${p => p.theme.borderRadius['size-8']};
  display: flex;
  align-items: center;
  justify-content: center;
`

const ManageLiveSessionRecordingsTable: FC<{
  liveSessionDetails: LiveSessionDetailsResponse
  data: TableData
  onRemoveRecording: OnRemoveRecording
  onRecordingClicked: OnRecordingClicked
}> = ({ liveSessionDetails, data, onRemoveRecording, onRecordingClicked }) => {
  const { t } = useTranslation()
  const notif = useNotif()
  const liveSessionId = liveSessionDetails.liveSession.liveSessionId
  const sessionIsClosed = liveSessionDetails.liveSession.data.endedAt !== undefined

  const title = (recording: LiveSessionRecording): string => {
    const obj = DateTime.fromISO(recording.createdAt)
    const date = obj.toFormat('yyyy-MM-dd')
    const time = obj.toFormat('HH:mm:ss')
    return `Recording ${date} at ${time}`
  }

  const subtitle = (recording: LiveSessionRecording): string => {
    return DateTime.fromISO(recording.createdAt).toFormat('LLL dd, HH:mm')
  }

  const downloadRecording = async (url: string): Promise<void> => {
    const response = await fetch(url, { method: 'GET' })
    const blob = new Blob([await response.blob()])

    downloadBlob(blob, 'recording.mp4')
  }

  const columns: Column<LiveSessionRecording>[] = useMemo(
    () => [
      {
        id: 'information',
        hideHeader: false,
        Header: () => null,
        Cell: (r: CellProps<LiveSessionRecording>) => {
          return (
            <View direction='row' gap='24'>
              <VideoPreview>
                <Icon iconId='play--filled' color='white' />
              </VideoPreview>
              <View direction='column' gap='2'>
                <Text color='LEGACY_DEFAULT_TEXT_COLOR_REPLACE_ASAP' size='small' bold>
                  {title(r.row.original)}
                </Text>
                <Text color='foreground/muted' size='small'>
                  {subtitle(r.row.original)}
                </Text>
              </View>
            </View>
          )
        },
      },
      {
        id: 'actions',
        width: '10%',
        Header: () => null,
        Cell: (r: CellProps<LiveSessionRecording>) => {
          return (
            <MenuContainer>
              <Menu onTriggerClick={() => {}} onClose={() => {}}>
                {sessionIsClosed && (
                  <RouterLink href={`/r/${liveSessionId}`}>
                    <MUIMenuItem>
                      <Text size='small' bold color='black'>
                        {t('learner-home.view-recap')}
                      </Text>
                    </MUIMenuItem>
                  </RouterLink>
                )}

                <MUIMenuItem
                  onClick={() => {
                    if (r.row.original.hlsUrl !== undefined) {
                      const url = `${window.location.origin}/r/${liveSessionId}`
                      void window.navigator.clipboard.writeText(url)
                      notif.push({
                        type: 'custom',
                        level: 'success',
                        body: 'Copied recap url to clipboard',
                      })
                    } else {
                      notif.push({
                        type: 'custom',
                        level: 'warning',
                        body: 'Something went wrong. Please try again',
                      })
                    }
                  }}
                >
                  <Text size='small' bold color='black'>
                    {t('share.copy-link')}
                  </Text>
                </MUIMenuItem>

                <MUIMenuItem
                  onClick={() => {
                    if (r.row.original.downloadUrl !== undefined) {
                      const downloadUrl = r.row.original.downloadUrl
                      void downloadRecording(downloadUrl)
                    } else {
                      notif.push({
                        type: 'custom',
                        level: 'warning',
                        body: 'Something went wrong. Please try again',
                      })
                    }
                  }}
                >
                  <Text size='small' bold color='black'>
                    {t('manage.reports.download')}
                  </Text>
                </MUIMenuItem>

                <MUIMenuItem onClick={() => onRemoveRecording(r.row.original.id)}>
                  <Text size='small' bold color='redBright'>
                    {t('content.delete-button')}
                  </Text>
                </MUIMenuItem>
              </Menu>
            </MenuContainer>
          )
        },
      },
    ],
    [sessionIsClosed, t, liveSessionId, notif, onRemoveRecording]
  )

  const table = useTable({ data: data.data, columns })

  return (
    <SmallTableWrapper title='Recordings'>
      <SmallTableScrollContainer>
        <Table
          tableInstance={table}
          getRowProps={row => ({
            onClick: () => {
              void onRecordingClicked(row.original)
            },
          })}
          small
        />
      </SmallTableScrollContainer>
    </SmallTableWrapper>
  )
}

const TableContainer = styled.div`
  & thead {
    display: none;
  }

  &&&& td {
    padding-left: 0;
  }
`

export const LiveSessionRecordingsTable: React.FC<{
  liveSessionDetails: LiveSessionDetailsResponse
  data: TableData
  onRemoveRecording: (recordingId: string) => void
  onRecordingClicked: (recording: LiveSessionRecording) => void
}> = ({ liveSessionDetails, data, onRemoveRecording, onRecordingClicked }) => {
  return (
    <TableContainer>
      {data.data.length === 0 ? (
        <>
          <Text size='small' bold>
            Recordings
          </Text>
          <View justifyContent='center' padding='large'>
            <Text size='small' color='grey40'>
              No recordings available
            </Text>
          </View>
        </>
      ) : (
        <ManageLiveSessionRecordingsTable
          data={data}
          onRemoveRecording={onRemoveRecording}
          onRecordingClicked={onRecordingClicked}
          liveSessionDetails={liveSessionDetails}
        />
      )}
    </TableContainer>
  )
}
