import React from 'react'
import { CreatePageSortFunctionId } from 'sierra-client/views/workspace/create-new/filters/types'
import { CheckboxMenuItem, LabelMenuItem } from 'sierra-ui/components/menu/types'
import { Checkbox, Text, View } from 'sierra-ui/primitives'
import { token } from 'sierra-ui/theming'
import styled from 'styled-components'

const Item = styled.div`
  display: grid;
  grid-template-columns: 1fr min-content;
  grid-auto-rows: min-content;
  align-items: center;
  margin: 10px 0;
`

const Label = styled(Text).attrs({ size: 'regular' })<{ checked: boolean }>`
  color: ${p => token(p.checked ? 'foreground/primary' : 'foreground/secondary')};
`
type CheckListProps<V = string> = {
  items: CheckboxMenuItem<V>[]
}

export const CheckList: React.FC<CheckListProps> = ({ items }) => (
  <View direction='column' grow gap='none'>
    {items.map(item => (
      <Item key={item.id}>
        <Label bold={item.checked} checked={item.checked}>
          {item.label}
        </Label>
        <Checkbox checked={item.checked} onCheckedChange={item.onToggleChange} />
      </Item>
    ))}
  </View>
)

type RadioButtonListProps<V = string> = {
  items: LabelMenuItem<V>[]
  selectedSortFunction: CreatePageSortFunctionId
}

export const RadioButtonList: React.FC<RadioButtonListProps> = ({ items, selectedSortFunction }) => (
  <View direction='column' grow gap='none'>
    {items.map(item => {
      const checked = selectedSortFunction === item.id
      return (
        <Item key={item.id}>
          <Label bold={checked} checked={checked}>
            {item.label}
          </Label>
          <Checkbox radio checked={checked} onCheckedChange={item.onClick} />
        </Item>
      )
    })}
  </View>
)
