import {
  SimpleBadgeBackgroundSvg,
  SimpleBadgeForegroundSvg,
} from 'sierra-client/features/skills/components/badges/svgs'
import {
  getThemeItemColor,
  simpleBadgeThemeMap,
} from 'sierra-client/features/skills/components/badges/themes'
import type { BadgeIconId, BadgeTheme } from 'sierra-client/features/skills/components/badges/types'
import { iconMap } from 'sierra-ui/components/icon/generated-icon-map'
import styled from 'styled-components'

const Container = styled.div`
  position: relative;
  flex: 1;

  svg {
    display: block;
  }
`

const BackgroundLayer = styled.div`
  position: relative;
`

const ForegroundLayer = styled.div<{ $color: string; $dropShadow: string }>`
  position: absolute;
  inset: 0;
  filter: ${p => p.$dropShadow};

  svg {
    fill: ${p => getThemeItemColor(p.$color)};
  }
`

const IconLayer = styled.div<{ $color: string; $dropShadow: string }>`
  position: absolute;
  inset: 0;
  width: 65%;
  margin: auto;
  display: flex;
  color: ${p => getThemeItemColor(p.$color)};
  filter: ${p => p.$dropShadow};

  svg {
    display: block;
    width: 100%;
    height: 100%;
    fill: ${p => getThemeItemColor(p.$color)};
  }
`

const LinearGradientBackgroundContainer = styled.div<{ $color: string }>`
  position: absolute;
  inset: 0;
  clip-path: url(#simple-badge-background-clip);
  background: ${p => getThemeItemColor(p.$color)};
`

export const SimpleBadge: React.FC<{
  theme: BadgeTheme
  iconId: BadgeIconId
}> = ({ theme, iconId }) => {
  const SvgIcon = iconMap[iconId]
  const colors = simpleBadgeThemeMap[theme]

  return (
    <Container>
      <BackgroundLayer>
        <SimpleBadgeBackgroundSvg />
        <LinearGradientBackgroundContainer $color={colors.backgroundColor} />
      </BackgroundLayer>

      <ForegroundLayer $dropShadow={colors.dropShadow} $color={colors.foregroundColor}>
        <SimpleBadgeForegroundSvg />
      </ForegroundLayer>

      <IconLayer $color={colors.iconColor} $dropShadow={colors.dropShadow}>
        <SvgIcon />
      </IconLayer>
    </Container>
  )
}
