import { createFileRoute } from '@tanstack/react-router'

import { PageIdentifier } from 'sierra-client/layout/sana-page'
import { ManageApi } from 'sierra-client/views/manage/api/manage-api'
import { useApiPageEnabled } from 'sierra-client/views/manage/permissions/use-api-page-enabled'
import { ManagePageWrapper } from 'sierra-client/views/manage/utils/manage-page-wrapper'

// Note: ManagePageWrapper handles the role check.
function ManageApiPage(): JSX.Element | null {
  const apiPageAvailable = useApiPageEnabled()

  if (apiPageAvailable !== true) {
    // todo(workflows): Redirect after initializing
    return null
  }

  return (
    <ManagePageWrapper pageIdentifier={PageIdentifier.ManageApiClients()}>
      <ManageApi />
    </ManagePageWrapper>
  )
}

const RouteComponent = ManageApiPage

export const Route = createFileRoute('/settings/api/')({
  component: RouteComponent as React.FC,
})
