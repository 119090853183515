import { useCallback, useEffect } from 'react'
import { liveSessionDataChannel } from 'sierra-client/realtime-data/channels'
import { useCachedQuery } from 'sierra-client/state/api'
import { useDispatch } from 'sierra-client/state/hooks'
import { setFacilitators as setFacilitatorsAction } from 'sierra-client/state/live-session-facilitators/slice'
import { ScopedLiveSessionId } from 'sierra-domain/collaboration/types'
import { XRealtimeStrategyLiveSessionGetFacilitators } from 'sierra-domain/routes'

let lastUpdated: Date | undefined = undefined

export const SyncFacilitators: React.FC<{ liveSessionId: ScopedLiveSessionId }> = ({
  liveSessionId: scopedId,
}) => {
  const liveSessionId = ScopedLiveSessionId.extractId(scopedId)
  const dispatch = useDispatch()

  const setFacilitators = useCallback(
    (update: { updatedAt: Date; facilitators: string[] }) => {
      if (lastUpdated === undefined || update.updatedAt > lastUpdated) {
        dispatch(setFacilitatorsAction(update.facilitators))
        lastUpdated = update.updatedAt
      }
    },
    [dispatch]
  )

  liveSessionDataChannel.useChannelEvent({
    channelId: liveSessionId,
    event: 'facilitators-changed',
    callback: data => {
      setFacilitators(data)
    },
  })

  const queryResult = useCachedQuery(XRealtimeStrategyLiveSessionGetFacilitators, {
    liveSessionId,
  })

  useEffect(() => {
    if (queryResult.data) {
      setFacilitators(queryResult.data)
    }
  }, [queryResult.data, setFacilitators])

  return null
}
