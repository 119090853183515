import React from 'react'
import { useTranslation } from 'sierra-client/hooks/use-translation'
import { TruncatedText } from 'sierra-ui/components'

export const BooleanCell: React.FC<{ cellData: boolean }> = ({ cellData }) => {
  const { t } = useTranslation()

  return (
    <TruncatedText lines={1} size='small'>
      {cellData ? t('dictionary.yes') : t('dictionary.no')}
    </TruncatedText>
  )
}
