import { toLabel } from 'sierra-client/features/insights/display-widgets/utils'
import { WidgetBuilderState } from 'sierra-client/features/insights/widget-builder/widget-builder-state'
import { TranslationLookup } from 'sierra-client/hooks/use-translation/types'
import { labelToString } from 'sierra-client/lib/tabular/datatype/label'
import { Label } from 'sierra-domain/filter/datatype/label'
import { assertNever } from 'sierra-domain/utils'

const getWidgetLabel = (t: TranslationLookup, visualisation: WidgetBuilderState['visualisation']): string => {
  switch (visualisation.type) {
    case 'bar-chart':
      return t('dictionary.bar-chart')
    case 'line-chart':
      return t('dictionary.line-chart')
    case 'progress-bar':
      return t('dictionary.progress-bar')
    case 'metric':
      return t('dictionary.simple-metric')
    case 'table':
      return t('dictionary.table')
    case 'pivot-table':
      return t('dictionary.pivot-table')
    default:
      assertNever(visualisation)
  }
}

export const disabledByMetricLabel = (t: TranslationLookup, labels: Label[]): string =>
  t('manage.insights.select-dimension.disabled-metric-tooltip', {
    metric: labels.length > 0 ? labels.map(label => labelToString(toLabel(label), t)).join(', ') : '',
  })

export const disabledByWidgetLabel = (
  t: TranslationLookup,
  visualisation: WidgetBuilderState['visualisation']
): string => {
  return t('manage.insights.select-dimension.disabled-widget-tooltip', {
    widget: getWidgetLabel(t, visualisation),
  })
}

export const disabledByAlreadySelectedLabel = (t: TranslationLookup): string =>
  t('manage.insights.select-dimension.already-selected-tooltip')
