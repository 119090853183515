import { RouterLink } from 'sierra-client/components/common/link'
import { v2_breakpoint } from 'sierra-ui/theming/breakpoints'
import { BorderRadius } from 'sierra-ui/types'
import styled, { css } from 'styled-components'

type AspectRatios = '2:3' | '3:4' | '3:2' | '1:1' | '4:3' | '16:9' | '21:9'

type ImageProps = {
  ratio: AspectRatios
  ratioPhone?: AspectRatios
  ratioTablet?: AspectRatios
  ratioDesktopSmall?: AspectRatios
  rounded?: boolean
  radius?: BorderRadius
  alt?: string
  src?: string | undefined
  link?: string | undefined
  faded?: boolean
}

const ratioToPadding: Record<AspectRatios, string> = {
  '2:3': '150%',
  '3:4': '125%',
  '1:1': '100%',
  '4:3': '75%',
  '3:2': '66.67%',
  '16:9': '56.25%',
  '21:9': '42.85%',
}

const ImageContainer = styled.div<{
  $ratio: AspectRatios
  $ratioPhone?: AspectRatios
  $ratioTablet?: AspectRatios
  $ratioDesktopSmall?: AspectRatios
  $rounded?: boolean
  $radius?: BorderRadius
}>`
  position: relative;
  overflow: hidden;
  background-color: ${p => p.theme.color.grey10};
  -webkit-mask-image: -webkit-radial-gradient(white, black);

  ${p =>
    (p.$rounded ?? false) &&
    css`
      border-radius: ${p.$radius !== undefined
        ? p.theme.borderRadius[p.$radius]
        : p.theme.borderRadius['size-4']};
    `}

  &::before {
    content: '';
    display: block;
    width: 100%;
    padding-bottom: ${p => ratioToPadding[p.$ratio]};

    ${p =>
      p.$ratioPhone &&
      css`
        @media screen and (min-width: ${v2_breakpoint.phone}) {
          padding-bottom: ${ratioToPadding[p.$ratioPhone]};
        }
      `}

    ${p =>
      p.$ratioTablet &&
      css`
        @media screen and (min-width: ${v2_breakpoint.tablet}) {
          padding-bottom: ${ratioToPadding[p.$ratioTablet]};
        }
      `}

    ${p =>
      p.$ratioDesktopSmall &&
      css`
        @media screen and (min-width: ${v2_breakpoint.desktop_small}) {
          padding-bottom: ${ratioToPadding[p.$ratioDesktopSmall]};
        }
      `}
  }
`

const Image = styled.img`
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  object-fit: cover;
  -webkit-mask-image: -webkit-radial-gradient(white, black);
`

const ImageLink = styled.a`
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  cursor: pointer;
`

export const SanaImage = ({
  ratio,
  ratioPhone,
  ratioTablet,
  ratioDesktopSmall,
  rounded,
  alt,
  src,
  className,
  link,
  radius,
}: ImageProps & Partial<HTMLDivElement>): JSX.Element => {
  return (
    <ImageContainer
      className={className}
      $ratio={ratio}
      $ratioPhone={ratioPhone}
      $ratioTablet={ratioTablet}
      $ratioDesktopSmall={ratioDesktopSmall}
      $rounded={rounded}
      $radius={radius}
    >
      {src !== undefined && <Image src={src} alt={alt} loading='lazy' />}
      {link !== undefined && (
        <RouterLink href={link}>
          <ImageLink />
        </RouterLink>
      )}
    </ImageContainer>
  )
}
