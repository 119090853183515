import { useMemo } from 'react'
import { useTranslation } from 'sierra-client/hooks/use-translation'
import { ImageFit } from 'sierra-domain/flexible-content/image-fit'
import { MenuItem } from 'sierra-ui/components'

export function useDefaultImageFitOption(): MenuItem<ImageFit> {
  const { t } = useTranslation()
  return useMemo(
    () => ({
      type: 'label',
      id: 'cover',
      label: t('author.image.cover'),
    }),
    [t]
  )
}

export function useImageFitOptions(): MenuItem<ImageFit>[] {
  const { t } = useTranslation()
  const defaultImageFitOption = useDefaultImageFitOption()
  return useMemo(
    () => [defaultImageFitOption, { type: 'label', id: 'contain', label: t('author.image.contain') }],
    [t, defaultImageFitOption]
  )
}
