import { motion } from 'framer-motion'
import React from 'react'
import { TemplatePickerProvider, useTemplatePicker } from 'sierra-client/components/templates'
import { iconVariants, textVariants } from 'sierra-client/features/collapsable-sidebar/animation-config'
import { useIsCreateAccessible } from 'sierra-client/hooks/use-create-enabled'
import { useTranslation } from 'sierra-client/hooks/use-translation'
import {
  ButtonStyles,
  ButtonStylesProps,
  usePrimitiveButtonPseudoStyles,
} from 'sierra-ui/primitives/button/button'
import { token } from 'sierra-ui/theming'
import styled from 'styled-components'

const StyledButton = styled.button<ButtonStylesProps>`
  ${ButtonStyles};
  background: ${token('button/background')};
  position: relative;
`

const IconText = styled(motion.div)`
  position: absolute;
  margin: auto;
  color: ${token('button/foreground')};
`

const WideModeContents = styled(motion.div)`
  display: flex;
  justify-content: center;
  align-items: center;
  color: ${token('button/foreground')};
`

const CreateNewButton: React.FC = () => {
  const { templatePickerOn } = useTemplatePicker()
  const pseudoStyles = usePrimitiveButtonPseudoStyles('primary')
  const { t } = useTranslation()
  return (
    <StyledButton
      $pseudoStyles={pseudoStyles}
      $grow
      onClick={() => {
        templatePickerOn()
      }}
    >
      <IconText variants={iconVariants}>+</IconText>
      <WideModeContents variants={textVariants}>{t('create.home.create-new')}</WideModeContents>
    </StyledButton>
  )
}

const CollapsableCreateNewButton: React.FC = () => (
  <TemplatePickerProvider>
    <CreateNewButton />
  </TemplatePickerProvider>
)

export const CollapsableCreateNewButtonViewer: React.FC = () => {
  const isCreateAccessible = useIsCreateAccessible()

  if (!isCreateAccessible) return null
  else
    return (
      <>
        <CollapsableCreateNewButton />
      </>
    )
}
