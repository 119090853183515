import * as Collapsible from '@radix-ui/react-collapsible'
import { AnimatePresence, motion } from 'framer-motion'
import { mapValues } from 'lodash'
import { useReducer, useState } from 'react'
import { ContentCard } from 'sierra-client/features/teamspace/components/modals/import-content-modal/content-row'
import { TeamspaceContentToMove } from 'sierra-client/features/teamspace/components/move-teamspace-content'
import { useTranslation } from 'sierra-client/hooks/use-translation'
import { NanoId12 } from 'sierra-domain/api/nano-id'
import { CourseGroupMovableContent } from 'sierra-domain/api/teamspace'
import { iife } from 'sierra-domain/utils'
import { Icon, Tooltip } from 'sierra-ui/components'
import { Text, View } from 'sierra-ui/primitives'
import { token } from 'sierra-ui/theming'
import { v2_breakpoint } from 'sierra-ui/theming/breakpoints'
import { useOnChanged } from 'sierra-ui/utils'
import styled from 'styled-components'

const RowContainer = styled.div`
  display: flex;
  flex-grow: 1;
  justify-content: space-between;
  align-items: center;
  margin: 0px 24px 16px 40px;
`

const ContentCardWrapper = styled.div`
  width: 100%;
  padding: 8px 16px 8px 8px;
  border-radius: 12px;
  transition: all 0.1s cubic-bezier(0.25, 0.1, 0.25, 1);

  &:hover {
    background-color: ${token('surface/soft')};
  }
`

const NestedContentLi = styled(motion.li)`
  display: flex;
  align-items: center;
  gap: 4px;
`

const NestedContentUl = styled(motion.ul)`
  position: relative;
  display: flex;
  flex-direction: column;
  gap: 24px;
  margin-left: 52px;
  padding-left: 18px;
  overflow: hidden;

  @media (min-width: ${v2_breakpoint.desktop_small}) {
    margin-left: 66px;
    padding-left: 18px;
  }

  ::before {
    content: '';
    position: absolute;
    left: 0;
    top: 0;
    bottom: 0;
    width: 4px;
    background-color: ${token('border/default')};
    border-radius: 10px;
  }
`

/**
 * if course group selected -> all editions selected (indeterminate checkbox)
 * if course group unselected -> all editions unselected
 * if one course-edition selected -> course group selected (indeterminate checkbox)
 * if all course-editions selected -> course group selected -> all editions selected (indeterminate checkbox)
 * */
type CourseGroupState = {
  group: {
    group: TeamspaceContentToMove
    checked: boolean | 'indeterminate'
  }
  editions: Record<NanoId12, { edition: TeamspaceContentToMove; checked: boolean | 'indeterminate' }>
}

type CourseGroupAction =
  | {
      type: 'set-selected'
      checked: boolean | 'indeterminate'
    }
  | {
      type: 'set-edition-checked'
      editionId: NanoId12
      checked: boolean | 'indeterminate'
    }
  | {
      type: 'set-all-editions-checked'
      checked: boolean | 'indeterminate'
    }

const reducer = (state: CourseGroupState, action: CourseGroupAction): CourseGroupState => {
  // This functions exists to flip group selection based if all/some editions are selected or not
  const calcSelected = (_state: CourseGroupState): CourseGroupState => {
    const groupState = iife((): CourseGroupState['group'] => {
      const editions = Object.values(_state.editions)

      const allChecked = editions.every(({ checked }) => checked === true)
      // If all editions are checked, then the course-group is selected
      if (allChecked) {
        return { ..._state.group, checked: true }
      }

      const someChecked = editions.some(({ checked }) => checked === true)
      // If some editions are checked, then the course-group is indeterminate
      if (someChecked) {
        return {
          ..._state.group,
          checked: 'indeterminate',
        }
      }

      return {
        ..._state.group,
        checked: false,
      }
    })

    return {
      ..._state,
      group: groupState,
    }
  }

  const setAllEditions = (
    editions: Record<NanoId12, { edition: TeamspaceContentToMove; checked: boolean | 'indeterminate' }>,
    checked: boolean | 'indeterminate'
  ): typeof editions => mapValues(editions, ({ edition }) => ({ edition, checked }))

  switch (action.type) {
    case 'set-selected': {
      const newState = {
        ...state,
        group: {
          ...state.group,
          checked: action.checked,
        },
        // Reset editions if we do not select the course-group
        editions: setAllEditions(state.editions, action.checked === true ? 'indeterminate' : false),
      }

      return newState
    }
    case 'set-edition-checked': {
      const newState = {
        ...state,
        editions: {
          ...state.editions,
          [action.editionId]: {
            ...state.editions[action.editionId],
            checked: action.checked,
          },
        },
      }
      return calcSelected(newState)
    }
    case 'set-all-editions-checked': {
      const newState = { ...state, editions: setAllEditions(state.editions, action.checked) }
      return calcSelected(newState)
    }
    default:
      return state
  }
}

export const CourseGroupRow: React.FC<{
  content: CourseGroupMovableContent
  isSelected: boolean
  onCheckedChange: (content: TeamspaceContentToMove, checked: boolean) => void
  teamspaceToImportTo: NanoId12
}> = ({ content, onCheckedChange, isSelected, teamspaceToImportTo }) => {
  const { t } = useTranslation()
  const [editionOpen, setEditionOpen] = useState(true)
  const [moveState, dispatch] = useReducer(reducer, {
    group: {
      group: {
        id: content.id,
        title: content.settings.title,
        currentTeamspaceId: content.currentTeamspaceId,
        type: 'non-editor-content',
      },
      checked: isSelected,
    },
    editions: content.editions.reduce(
      (acc, edition) => {
        acc[edition.courseId] = {
          edition: {
            id: edition.courseId,
            title: edition.title,
            currentTeamspaceId: edition.teamspace?.id,
            type: 'editable-content',
            courseGroup: content,
          },
          checked: false,
        }
        return acc
      },
      {} as Record<NanoId12, { edition: TeamspaceContentToMove; checked: boolean | 'indeterminate' }>
    ),
  })

  useOnChanged((_, selected) => {
    dispatch({ type: 'set-selected', checked: selected })
  }, isSelected)

  useOnChanged((_, teamspaceId) => {
    content.editions.forEach(edition => {
      if (edition.teamspace?.id === teamspaceId) {
        dispatch({ type: 'set-edition-checked', editionId: edition.courseId, checked: true })
      }
    })
  }, teamspaceToImportTo)

  useOnChanged((_, state) => {
    const allEditions = Object.values(state.editions).map(({ edition, checked }) => ({
      content: edition,
      // Make sure they are only to be moved unless the course-group itself is already selected
      checked: state.group.checked === true ? false : checked,
    }))
    const teamSpaceContentToMove = [
      {
        content: state.group.group,
        checked: state.group.checked,
      },
    ].concat(allEditions)

    teamSpaceContentToMove.forEach(({ content, checked }) => {
      if (checked !== 'indeterminate') {
        onCheckedChange(content, checked)
      }
    })
  }, moveState)

  return (
    <Collapsible.Root open={editionOpen} onOpenChange={setEditionOpen} asChild>
      <RowContainer>
        <ContentCardWrapper>
          <ContentCard
            content={content}
            onCheckedChange={(_, m) => {
              dispatch({ type: 'set-selected', checked: m })
            }}
            isSelected={moveState.group.checked}
            collapsable={{ collapsed: editionOpen }}
          />
          <AnimatePresence>
            {editionOpen && (
              <Collapsible.Content forceMount>
                <NestedContentUl
                  initial='collapsed'
                  animate='open'
                  exit='collapsed'
                  variants={{
                    open: {
                      opacity: 1,
                      height: 'auto',
                      marginTop: 20,
                      transition: { staggerChildren: 0.04 },
                    },
                    collapsed: {
                      opacity: 0,
                      height: 0,
                      marginTop: 0,
                    },
                  }}
                  transition={{ duration: 0.15, ease: [0.25, 0.1, 0.25, 1] }}
                >
                  {content.editions.map(edition => (
                    <NestedContentLi
                      key={edition.courseId}
                      variants={{
                        open: { opacity: 1 },
                        collapsed: { opacity: 0 },
                      }}
                    >
                      <ContentCard
                        content={{
                          id: edition.courseId,
                          kind: content.kind === 'scorm:course-group' ? 'scorm' : 'native:self-paced',
                          role: edition.role,
                          teamspace: edition.teamspace,
                          settings: {
                            title: edition.title,
                            image: edition.image,
                            templateSettings: undefined,
                          },
                          currentTeamspaceId: edition.teamspace?.id,
                          courseEdition: {
                            language: edition.language,
                          },
                        }}
                        isSelected={moveState.editions[edition.courseId]?.checked ?? false}
                        disabled={moveState.editions[edition.courseId]?.checked === 'indeterminate'}
                        onCheckedChange={(e, checked) => {
                          dispatch({ type: 'set-edition-checked', editionId: e.id, checked })
                        }}
                        hideThumbnail
                      />
                    </NestedContentLi>
                  ))}
                  {iife(() => {
                    const drafts = content.totalEditions - content.editions.length
                    if (drafts === 0) {
                      return null
                    }
                    return (
                      <View gap='6'>
                        <Text bold color='foreground/muted'>
                          {t('teamspace.course-group.editions.drafts.text', {
                            count: drafts,
                          })}
                        </Text>
                        <Tooltip
                          title={t('teamspace.course-group.editions.draft.information.text', {
                            count: drafts,
                          })}
                          delayDuration={200}
                        >
                          <Icon iconId='information' color='foreground/muted' />
                        </Tooltip>
                      </View>
                    )
                  })}
                </NestedContentUl>
              </Collapsible.Content>
            )}
          </AnimatePresence>
        </ContentCardWrapper>
      </RowContainer>
    </Collapsible.Root>
  )
}
