import { useAtomValue } from 'jotai'
import { DateTime } from 'luxon'
import React, { useMemo, useRef, useState } from 'react'
import { joinFacilitators } from 'sierra-client/core/format'
import { useTranslation } from 'sierra-client/hooks/use-translation'
import { TranslationLookup } from 'sierra-client/hooks/use-translation/types'
import { stringsColumn } from 'sierra-client/lib/tabular/column-definitions'
import { staticDataLoader, StaticLoaderSearchKeyBy } from 'sierra-client/lib/tabular/dataloader/static'
import { translatedLabel } from 'sierra-client/lib/tabular/datatype/label'
import {
  definition2Data,
  TableDataFromDefinition,
  TableDefinitionOf,
} from 'sierra-client/lib/tabular/datatype/tabledefinition'
import { TabularProviderFromTableAPI } from 'sierra-client/lib/tabular/provider'
import { BasicTabularSimpleSize } from 'sierra-client/lib/tabular/provider/components/basic'
import { UseTableAPI, useTableAPI } from 'sierra-client/lib/tabular/use-table-api'
import { defaultMenuActionVirtualColumn } from 'sierra-client/lib/tabular/utils'
import { getGlobalRouter } from 'sierra-client/router'
import { ExportCSVIconButton } from 'sierra-client/views/manage/components/export-csv'
import { RoundedSearchBar } from 'sierra-client/views/manage/components/rounded-search-bar'
import { formatLiveSessionTimeAsSchedule } from 'sierra-client/views/manage/event-groups/event-utils'
import { liveSessionToCsv } from 'sierra-client/views/manage/live-session/live-session-utils'
import { LiveSessionRow } from 'sierra-domain/api/manage'
import { scheduledOrNull } from 'sierra-domain/content/session'
import { MenuItem } from 'sierra-ui/components'
import { Spacer, Text, View } from 'sierra-ui/primitives'
import { SingleSelectDropdown } from 'sierra-ui/primitives/menu-dropdown'
import styled from 'styled-components'

type CourseLiveSessionData = LiveSessionRow

type CourseLiveSessionTableDefinition = TableDefinitionOf<
  CourseLiveSessionData,
  [
    { type: 'strings'; ref: 'date' },
    { type: 'strings'; ref: 'title' },
    { type: 'strings'; ref: 'attendees' },
    { type: 'strings'; ref: 'facilitators' },
  ]
>

type CourseLiveSessionTableData = TableDataFromDefinition<
  CourseLiveSessionData,
  CourseLiveSessionTableDefinition
>

const tableDefinition = (t: TranslationLookup): CourseLiveSessionTableDefinition => ({
  columns: [
    stringsColumn({
      getData: r => {
        const session = scheduledOrNull(r.data)
        const formattedDate = formatLiveSessionTimeAsSchedule(session)
        return formattedDate ?? t('dictionary.live-session.not-scheduled')
      },
      ref: 'date',
      header: translatedLabel('dictionary.date'),
    }),
    stringsColumn({
      getData: r => r.data.title,
      header: translatedLabel('table.name'),
      ref: 'title',
      hints: ['bold'],
    }),
    stringsColumn({
      getData: r => {
        const { assignmentsCount } = r
        const { maxNumberOfUsers } = r.data
        return [
          assignmentsCount,
          maxNumberOfUsers !== undefined && maxNumberOfUsers > 0 ? ` / ${maxNumberOfUsers}` : '',
        ].join('')
      },
      header: translatedLabel('table.assigned'),
      ref: 'attendees',
    }),
    stringsColumn({
      getData: r => joinFacilitators(r.facilitatorsInfo),
      header: translatedLabel('manage.live-session.facilitator'),
      ref: 'facilitators',
    }),
  ],
  nested: {},
  rows: {
    getId: r => r.liveSessionId,
  },
})

const searchKey: StaticLoaderSearchKeyBy<CourseLiveSessionTableData> = (tableData, row) =>
  tableData.rows[row]?.data.title.data ?? ''

type SessionDateFilter = 'upcoming' | 'all' | 'past'

type SessionFilter = {
  dateFilter: SessionDateFilter
}

const DEFAULT_FILTER: SessionFilter = {
  dateFilter: 'upcoming',
}

type CourseLiveSessionTableProps = {
  liveSessions: LiveSessionRow[]
  openAssignModal: (sessionIds: string[]) => void
}

type CourseLiveSessionTableMeta = {
  subset: CourseLiveSessionTableData
}

const useCourseLiveSessionTableAPI = ({
  liveSessions,
  openAssignModal,
}: CourseLiveSessionTableProps): UseTableAPI<CourseLiveSessionTableData, CourseLiveSessionTableMeta> => {
  const { t } = useTranslation()

  const loader = staticDataLoader(definition2Data(tableDefinition(t), liveSessions), searchKey)
  return useTableAPI({
    dataLoader: { loader },
    virtualColumns: {
      left: [],
      right: [
        defaultMenuActionVirtualColumn({
          getProps: ({ row }) => {
            const {
              liveSessionId,
              assignmentsCount,
              data: { maxNumberOfUsers },
            } = row.rawData
            const isFull = maxNumberOfUsers !== undefined && assignmentsCount >= maxNumberOfUsers
            return {
              menuItems: [
                {
                  type: 'label',
                  label: t('manage.view-details'),
                  id: 'view-details',
                  onClick: () =>
                    getGlobalRouter().navigate({
                      to: `/manage/events/${liveSessionId}`,
                    }),
                },
                {
                  type: 'label',
                  id: 'assign',
                  label: t('dictionary.assign'),
                  disabled: isFull,
                  onClick: () => openAssignModal([liveSessionId]),
                },
              ],
            }
          },
        }),
      ],
    },
  })
}

const MinWidth = styled.div`
  min-width: 160px;
`

const SearchAndFilter: React.FC<{
  filter: SessionFilter
  setFilter: (state: React.SetStateAction<SessionFilter>) => void
  tableAPI: UseTableAPI<CourseLiveSessionTableData, CourseLiveSessionTableMeta>
}> = ({ filter, setFilter, tableAPI }) => {
  const { t } = useTranslation()
  const query = useAtomValue(tableAPI.api.atoms.query)
  const filterItems: MenuItem<SessionDateFilter>[] = [
    {
      type: 'label',
      id: 'upcoming',
      label: t('manage.live-session.filter.upcoming-sessions'),
    },
    {
      type: 'label',
      id: 'all',
      label: t('admin.author.sessions.all'),
    },
    {
      type: 'label',
      id: 'past',
      label: t('manage.live-session.filter.past-sessions'),
    },
  ]

  return (
    <View grow justifyContent='space-between'>
      <RoundedSearchBar
        value={query}
        onChange={query => tableAPI.api.action.setQuery({ query })}
        placeholder={t('manage.search.sessions')}
      />
      <View>
        <MinWidth>
          <SingleSelectDropdown
            selectedItem={filterItems.find(item => item.id === filter.dateFilter)}
            menuItems={filterItems}
            onSelect={item => {
              setFilter(curr => ({ ...curr, dateFilter: item.id }))
            }}
          />
        </MinWidth>
      </View>
    </View>
  )
}

const TableWrapper = styled(View)`
  max-height: 27rem;
  overflow-y: auto;
`

const Table: React.FC = () => {
  const scrollRef = useRef(null)

  return (
    <TableWrapper ref={scrollRef} alignItems='flex-start' direction='column'>
      <BasicTabularSimpleSize scrollRef={scrollRef.current} />
    </TableWrapper>
  )
}

const Footer: React.FC<{ fetchCsv: () => Promise<Record<string, string>[]> }> = ({ fetchCsv }) => {
  const { t } = useTranslation()
  return (
    <View marginBottom='32' marginTop='32' justifyContent='flex-end'>
      <ExportCSVIconButton fetchCsvData={fetchCsv} filename={t('content.sessions')} />
    </View>
  )
}

export const CourseLiveSessionsTable: React.FC<CourseLiveSessionTableProps> = ({
  liveSessions,
  openAssignModal,
}) => {
  const [filter, setFilter] = useState<SessionFilter>(DEFAULT_FILTER)
  const filteredSessions = useMemo(() => {
    let sessions = liveSessions

    if (filter.dateFilter !== 'all') {
      sessions = sessions.filter(session => {
        if (session.data.type !== 'scheduled') return false

        const dt = DateTime.fromISO(session.data.endTime)

        if (filter.dateFilter === 'upcoming') {
          return dt >= DateTime.now()
        }

        if (filter.dateFilter === 'past') {
          return dt < DateTime.now()
        }

        return true
      })
    }

    return sessions
  }, [liveSessions, filter])
  const tableAPI = useCourseLiveSessionTableAPI({ liveSessions: filteredSessions, openAssignModal })
  const { t } = useTranslation()
  return (
    <TabularProviderFromTableAPI tableAPI={tableAPI}>
      <Text size='large' bold>
        {t('content.sessions')}
      </Text>
      <Spacer size='xsmall' />
      <SearchAndFilter filter={filter} setFilter={setFilter} tableAPI={tableAPI} />
      <Spacer size='xsmall' />
      <Table />
      <Footer fetchCsv={() => Promise.resolve(filteredSessions.map(liveSessionToCsv))} />
    </TabularProviderFromTableAPI>
  )
}
