import { UseQueryResult, keepPreviousData, useQuery } from '@tanstack/react-query'
import { useCallback, useEffect, useState } from 'react'
import { useTracking } from 'sierra-client/components/common/identities-selector/tracking'
import { FetchIdentities, Identity } from 'sierra-client/components/common/identities-selector/types'
import { useDebouncedAndLiveState } from 'sierra-client/hooks/use-debounced-state'
import { typedPost } from 'sierra-client/state/api'
import { XRealtimeAdminLookupIdentities } from 'sierra-domain/routes'
import { isDefined } from 'sierra-domain/utils'

type DataLayer = (params: {
  selected: Identity[]
  onSelect: (identity: Identity) => void
  fetchIdentities: FetchIdentities
  overrideCacheKey?: string
  staleTime?: number
  cacheTime?: number
  disabled?: boolean
  selectorId: string
}) => {
  query: string
  setQuery: (query: string, pasted?: boolean) => void
  searchQuery: UseQueryResult<Identity[]>
  unavailableUsers: string[]
}

/**
 * Helper for identity selector to resolve identity data.
 * Notice that this hook caches the key under the fetchIdenties functionname. This
 * means that IF fetchIdentities is an anonymous function, it will grab from the cached data
 * leading to potential incorrect data. This on the otherhand will make sure that if you
 * correctly name your functions, caching will be used correctly
 *
 * IF you for some reason do not have that possibility, do use the `overrideCacheKey`
 * to override the cache key to a namespace that works for your usecase
 *
 * */
export const useIdentitiesSelectorDataLayer: DataLayer = ({
  selected,
  onSelect,
  fetchIdentities,
  overrideCacheKey,
  cacheTime,
  staleTime = 60 * 1000,
  disabled = false,
  selectorId,
}) => {
  const [unavailableUsers, setUnavailableUsers] = useState<string[]>([])
  const [debouncedQuery, query, setQuery] = useDebouncedAndLiveState('', { wait: 250 })

  const track = useTracking(selectorId)

  const cacheKey = overrideCacheKey ?? fetchIdentities.name

  const searchQuery = useQuery({
    queryFn: () => fetchIdentities(debouncedQuery),
    // This should be unique for different identity fetchers. If used correctly we get caching for components using the same endpoint for identity fetching
    queryKey: ['identities-selector', debouncedQuery, cacheKey],
    staleTime: staleTime,
    gcTime: cacheTime,
    placeholderData: keepPreviousData,
    select: (identities: Identity[]) => {
      // Deduplicate already selected users
      return identities.filter(i => selected.find(s => s.id === i.id) === undefined)
    },
    enabled: !disabled,
  })

  const lookupEmails = useCallback(
    async (emails: string[]) => {
      const { lookups } = await typedPost(XRealtimeAdminLookupIdentities, {
        emails,
      })

      const available = Object.values(lookups).filter(isDefined)
      const unavailable = Object.entries(lookups)
        .map(([email, identity]) => (identity === null ? email : undefined))
        .filter(isDefined)

      setUnavailableUsers(unavailable)

      for (const identity of available) {
        onSelect({
          ...identity,
          id: identity.identity.id,
        })
      }

      setQuery('')
    },
    [onSelect, setQuery]
  )

  const setQueryWithPasteSupport = useCallback(
    (input: string, pasted?: boolean) => {
      setUnavailableUsers([])

      if (pasted !== true) {
        setQuery(input)
        return
      }

      const pattern = /\b\S+@\S+\.\S+\b/g
      const emails = input.match(pattern)

      if (emails === null) {
        setQuery(input)
      } else {
        track.pastedEmails({ numberOfEmails: emails.length })
        void lookupEmails([...emails])
      }
    },
    [setQuery, lookupEmails, track]
  )

  useEffect(() => {
    if (unavailableUsers.length === 0) {
      return
    }

    const timer = setTimeout(() => {
      setUnavailableUsers([])
    }, 6_000)

    return () => {
      clearTimeout(timer)
    }
  }, [unavailableUsers])

  return {
    query,
    setQuery: setQueryWithPasteSupport,
    searchQuery,
    unavailableUsers,
  }
}
