import { Icon, IconId } from 'sierra-ui/components'
import { View } from 'sierra-ui/primitives'

export const IconLabel: React.FC<{ iconId: IconId; label: string }> = ({ iconId, label }) => {
  return (
    <View direction='row' justifyContent='flex-start' alignItems='center'>
      <Icon iconId={iconId} size='size-16' color='currentColor' />
      <View>{label}</View>
    </View>
  )
}
