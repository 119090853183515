import { produce } from 'immer'
import { useCallback, useEffect, useState } from 'react'
import { usePost } from 'sierra-client/hooks/use-post'
import { Filter } from 'sierra-client/lib/filter'
import {
  UseGroupAdminsState,
  useGroupAdmins,
} from 'sierra-client/views/manage/components/hooks/use-group-admins'
import {
  ListUserGroupsMembersResponse,
  PotentialGroupAdmin,
  UnassignGroupRequest,
  UserGroupDetailResponse,
} from 'sierra-domain/api/manage'
import { UserGroupRequestFilter } from 'sierra-domain/filter/request'
import {
  XRealtimeAdminAssignmentsUnassignFromGroup,
  XRealtimeAdminGroupsDeleteGroups,
  XRealtimeAdminGroupsListUserGroupMembers,
  XRealtimeAdminGroupsUpdateGroup,
  XRealtimeAdminGroupsUserGroupDetail,
} from 'sierra-domain/routes'

type UserGroupType = UserGroupDetailResponse['type']

const groupTypeEditability: Record<UserGroupType, boolean> = {
  'user-manual': true,
  'user-filter': true,
  'user-scim': false,
  'user-api': false,
}

export const isModifiableGroupType = (groupType: UserGroupType): boolean => groupTypeEditability[groupType]

export type UseUserGroupDetailsData = {
  isLoading: boolean
  group?: UserGroupDetailResponse
  members: ListUserGroupsMembersResponse & { isLoading: boolean }
  fetchMembers: (
    groupId: string,
    filters?: Partial<UserGroupRequestFilter>,
    options?: {
      reset?: boolean
      forCsv?: boolean
    }
  ) => Promise<ListUserGroupsMembersResponse>
  unassignFromGroup: (
    groupId: string,
    unassigns: Partial<Omit<UnassignGroupRequest, 'groupId'>>
  ) => Promise<void>
  fetchGroup: (id: string) => Promise<void>
  deleteGroup: (id: string) => Promise<void>
  updateGroupDetails: (
    id: string,
    name: string,
    description: string,
    filter: Filter | undefined,
    invalidFilter: boolean
  ) => Promise<void>
  groupAdminState: UseGroupAdminsState
}

export const useUserGroupDetails = (groupId: string): UseUserGroupDetailsData => {
  const { postWithUserErrorException } = usePost()

  const [group, setGroup] = useState<UserGroupDetailResponse | undefined>(undefined)
  const [isLoading, setIsLoading] = useState(false)

  const [members, setMembers] = useState<UseUserGroupDetailsData['members']>({
    data: [],
    accessLevels: {},
    next: undefined,
    totalCount: 0,
    totalMembersInGroup: 0,
    customUserAttributesMetadata: [],
    isLoading: true,
  })

  const updateGroupAdmins = useCallback((newAdmins: PotentialGroupAdmin[]) => {
    setGroup(curr => (curr !== undefined ? { ...curr, admins: newAdmins } : undefined))
  }, [])

  const groupAdminState = useGroupAdmins({
    groupId,
    currentAdmins: group?.admins,
    updateGroupAdmins,
  })

  const fetchGroup = useCallback(
    async (groupId: string): Promise<void> => {
      const response = await postWithUserErrorException(XRealtimeAdminGroupsUserGroupDetail, {
        groupId,
      })
      setGroup(response)
    },
    [postWithUserErrorException]
  )

  const deleteGroup = useCallback(
    async (groupId: string): Promise<void> => {
      await postWithUserErrorException(XRealtimeAdminGroupsDeleteGroups, { groupIds: [groupId] })
    },
    [postWithUserErrorException]
  )

  const fetchMembers = useCallback<UseUserGroupDetailsData['fetchMembers']>(
    async (id, commonFilters, options = {}) => {
      setMembers(current => ({
        ...current,
        isLoading: true,
      }))

      const response = await postWithUserErrorException(XRealtimeAdminGroupsListUserGroupMembers, {
        groupId: id,
        sortBy: commonFilters?.sortBy ?? { type: 'name', direction: 'asc' },
        query: commonFilters?.query,
        next: commonFilters?.next,
        limit: options.forCsv === true ? 50_000 : commonFilters?.limit ?? 50,
      })

      // Don't set state for CSV export
      if (options.forCsv === true) {
        setMembers(current => ({ ...current, isLoading: false }))
        return response
      }

      setMembers(current =>
        options.reset === true
          ? { ...response, isLoading: false }
          : {
              data: current.data.concat(response.data),
              accessLevels: { ...current.accessLevels, ...response.accessLevels },
              next: response.next,
              totalCount: response.totalCount,
              totalMembersInGroup: response.totalMembersInGroup,
              customUserAttributesMetadata: response.customUserAttributesMetadata,
              isLoading: false,
            }
      )

      return response
    },
    [postWithUserErrorException]
  )

  const updateGroupDetails = useCallback<UseUserGroupDetailsData['updateGroupDetails']>(
    async (groupId, name, description, filter, invalidFilter) => {
      if (!name) return

      await postWithUserErrorException(XRealtimeAdminGroupsUpdateGroup, {
        groupId,
        name,
        description,
        filter,
      })
      setGroup(
        produce(g => {
          if (g === undefined) return

          g.name = name
          g.description = description

          if (g.type === 'user-filter' && filter !== undefined) {
            g.filter = filter
          }
        })
      )
      // If filter changed, re-fetch members
      if (filter !== undefined) {
        if (invalidFilter) {
          await fetchGroup(groupId)
        }
        await fetchMembers(groupId, {}, { reset: true })
      }
    },
    [postWithUserErrorException, fetchGroup, fetchMembers]
  )

  const unassignFromGroup = useCallback<UseUserGroupDetailsData['unassignFromGroup']>(
    async (groupId, { userIds }) => {
      await postWithUserErrorException(XRealtimeAdminAssignmentsUnassignFromGroup, {
        groupId,
        userIds,
      })

      await fetchGroup(groupId)
      if (userIds) {
        await fetchMembers(groupId, {}, { reset: true })
      }
    },
    [fetchMembers, fetchGroup, postWithUserErrorException]
  )

  useEffect(() => {
    void (async () => {
      //Cleanup
      setGroup(undefined)
      setIsLoading(true)
      await fetchGroup(groupId)
      setIsLoading(false)
    })()
  }, [fetchGroup, groupId, postWithUserErrorException])

  useEffect(() => {
    void fetchMembers(groupId, {}, { reset: true })
  }, [fetchMembers, groupId])

  return {
    isLoading,
    group,
    members,
    unassignFromGroup,
    fetchMembers,
    deleteGroup,
    updateGroupDetails,
    fetchGroup,
    groupAdminState,
  }
}
