import { useEffect } from 'react'

export const FeatureDetection: React.FC = () => {
  useEffect(() => {
    const { document } = window

    // create flex container with row-gap set
    const id = '___modernizr--flexgap'
    const container = document.createElement('div')
    container.setAttribute('id', id)
    container.style.display = 'flex'
    container.style.flexDirection = 'column'
    container.style.rowGap = '1px'

    container.appendChild(document.createElement('div'))
    container.appendChild(document.createElement('div'))
    document.body.appendChild(container)

    requestAnimationFrame(() => {
      // Flex gap is supported if the container measures 1px
      const isSupported = container.scrollHeight === 1

      container.parentNode?.removeChild(container)

      if (!isSupported) {
        document.body.classList.add('no-flexgap')
      }
    })
  }, [])

  return null
}
