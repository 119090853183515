import { useAtomValue } from 'jotai'
import { useId, useReducer, useState } from 'react'
import { useConfirmationModalContext } from 'sierra-client/components/common/modals/confirmation-modal'
import { isEditableYjsEditor } from 'sierra-client/editor'
import { previousInstructionsAtom } from 'sierra-client/features/text-actions/atoms'
import { BeginningTruncatedText } from 'sierra-client/features/text-actions/components/beginning-truncated-text'
import { TruncatedAndFadedText } from 'sierra-client/features/text-actions/components/truncated-and-faded-text'
import { useGenerateText } from 'sierra-client/features/text-actions/hooks/use-generate-text'
import { promptModalReducer } from 'sierra-client/features/text-actions/reducer'
import { EditorTextActionsState } from 'sierra-client/features/text-actions/types'
import { useOnMount } from 'sierra-client/hooks/use-on-mount'
import { useOnUnmount } from 'sierra-client/hooks/use-on-unmount'
import { useTranslation } from 'sierra-client/hooks/use-translation'
import { getRelativeRange, removeRelativeRangeLocal } from 'sierra-client/views/commenting/utils'
import { withModalOpenWhenData } from 'sierra-client/views/manage/utils/with-modal/with-modal'
import { assert, assertNever, iife } from 'sierra-domain/utils'
import { AiBackground, CloseModalButton, Icon, TruncatedText } from 'sierra-ui/components'
import {
  Button,
  Heading,
  IconButton,
  InputStyles,
  LoaderAnimation,
  Spacer,
  Text,
  View,
} from 'sierra-ui/primitives'
import { token } from 'sierra-ui/theming'
import { Theme } from 'sierra-ui/theming/legacy-theme'
import { mapThemeToTokens } from 'sierra-ui/theming/providers'
import { BaseRange, Point, Range, Transforms } from 'slate'
import { useSlateStatic } from 'slate-react'
import styled, { ThemeProvider } from 'styled-components'

function useModalCopy(mode: EditorTextActionsState['mode']): {
  heading: string
  sourceTextHeading: string
  acceptButtonText: string
} {
  const { t } = useTranslation()

  switch (mode) {
    case 'replace':
      return {
        heading: t('create.text-actions.custom-instructions'),
        sourceTextHeading: t('create.text-actions.original-text'),
        acceptButtonText: t('create.text-actions.accept-text'),
      }
    case 'extend':
      return {
        heading: t('assistant.continue-writing'),
        sourceTextHeading: t('create.text-actions.building-on'),
        acceptButtonText: t('dictionary.insert'),
      }
  }
  assertNever(mode)
}

function getInsertionRangeOrPoint({
  mode,
  relativeRange,
}: {
  mode: EditorTextActionsState['mode']
  relativeRange: BaseRange
}): Point | BaseRange {
  switch (mode) {
    case 'replace':
      return relativeRange
    case 'extend':
      return Range.end(relativeRange)
    default:
      assertNever(mode)
  }
}

const PreWrapText = styled(Text)`
  white-space: pre-wrap;
`

const OuterContainer = styled(View)`
  padding: 24px 40px;
`

const StyledAiBackground = styled(AiBackground)`
  z-index: -1;
`

const OutputContainer = styled.div`
  position: relative;
  display: flex;
  flex-direction: column;
  gap: 16px;
  min-height: 32px;
  border-radius: 16px;
  padding: 12px 16px;
  margin-left: -16px;
  margin-right: -16px;
  overflow: hidden;
`

const patchThemeForOutputContainer = (theme: Theme): Theme =>
  mapThemeToTokens({
    ...theme,
    home: {
      ...theme.home,
      textColor: token('org/secondary')({ theme }),
      backgroundColor: token('org/primary')({ theme }),
      actionButtonTextColor: token('org/primary')({ theme }),
      actionButtonBackgroundColor: token('org/secondary')({ theme }),
    },
  })

const OutputContainerTokenProvider: React.FC<{ children: React.ReactNode }> = ({ children }) => {
  return <ThemeProvider theme={patchThemeForOutputContainer}>{children}</ThemeProvider>
}

const StartGeneratingButton = styled(IconButton).attrs({
  variant: 'transparent',
  iconId: 'arrow-up--filled',
  size: 'default',
})`
  border-radius: 0;

  &:not(:hover),
  &:disabled:hover {
    background: transparent;
  }
`

const StyledIconButton = styled(IconButton)`
  background: ${token('form/background/4')};

  &:hover {
    background: ${token('form/background/3')};
  }

  border: none;
`

const RetryButton = styled(StyledIconButton).attrs({
  variant: 'secondary',
  iconId: 'restart',
})``

const TrashButton = styled(StyledIconButton).attrs({
  variant: 'secondary',
  iconId: 'trash-can',
})``

const InputForm = styled.form`
  display: flex;
  align-items: center;
  gap: 8px;
`

const InputWrapper = styled.div`
  display: flex;
  flex: 1;
  border-radius: 10px;
  gap: 8px;
  background: ${token('form/background/4')};
  padding-left: 12px;
  overflow: hidden;
`

const StyledInput = styled.input`
  ${InputStyles};
  background: transparent;
  outline: none !important;
`

const LoadingInput = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100%;
  height: 36px;
  border-radius: 10px;
`

const LoadingInputTextContainer = styled.span`
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 8px;
`

export const EditorTextActionsModal = withModalOpenWhenData<{
  editorTextActionsState: EditorTextActionsState
}>(
  { size: { width: 720 }, overlayVariant: 'light' },
  ({ editorTextActionsState, registerOnCloseHandler, onClose, onCloseWithoutValidation }) => {
    const { t } = useTranslation()
    const editor = useSlateStatic()

    const previousInstructionsDatalistId = useId()

    const previousInstructions = useAtomValue(previousInstructionsAtom)

    const [draftInstruction, setDraftInstruction] = useState(editorTextActionsState.initialInstructions ?? '')

    const confirmationModal = useConfirmationModalContext()

    const [state, dispatchAction] = useReducer(promptModalReducer, { type: 'initial' })

    const copy = useModalCopy(editorTextActionsState.mode)

    registerOnCloseHandler(() => {
      if (state.type !== 'initial') {
        confirmationModal.show({
          title: t('alert.are-you-sure'),
          bodyText: t('alert.changes-lost'),
          confirmLabel: t('dictionary.discard'),
          onConfirm: onCloseWithoutValidation,
        })

        return 'prevent-close'
      }
    })

    useOnUnmount(() => {
      if (isEditableYjsEditor(editor)) {
        removeRelativeRangeLocal({ editor, key: editorTextActionsState.key })
      }

      // Cancel current generation when component unmounts
      dispatchAction({ type: 'abort' })
    })

    const generateText = useGenerateText({
      dispatchAction,
      onStart: () => {
        // Reset current input
        setDraftInstruction('')
      },
    })

    const handleSubmit = (): void => {
      switch (state.type) {
        case 'initial':
          void generateText({
            sourceText: editorTextActionsState.sourceText,
            instruction: draftInstruction,
            mode: editorTextActionsState.mode,
          })
          break
        case 'running':
          // Should be unreachable!
          console.error('Cannot start text generation while already generating.')
          break
        case 'done':
          // When submitting a new instruction after completion, apply it to the generated text instead
          // of the original text. We also always use replace mode here, since we want to use the users
          // prompt as a new instruction, rather than just generating a continuation.
          void generateText({
            sourceText: state.output,
            instruction: draftInstruction,
            mode: 'replace',
          })
          break
        default:
          assertNever(state)
      }
    }

    useOnMount(() => {
      if (editorTextActionsState.initialInstructions !== undefined) {
        void generateText({
          sourceText: editorTextActionsState.sourceText,
          instruction: editorTextActionsState.initialInstructions,
          mode: editorTextActionsState.mode,
        })
      }
    })

    return (
      <OuterContainer direction='column'>
        <CloseModalButton ariaLabel={t('dictionary.close')} onClick={onClose} />
        <Heading size='h4' bold>
          {copy.heading}
        </Heading>

        {editorTextActionsState.sourceText.length > 0 && (
          <>
            <Text bold color='foreground/muted'>
              {copy.sourceTextHeading}
            </Text>

            {iife(() => {
              switch (editorTextActionsState.mode) {
                case 'extend':
                  return <BeginningTruncatedText text={editorTextActionsState.sourceText} />
                case 'replace':
                  return <TruncatedAndFadedText text={editorTextActionsState.sourceText} />
                default:
                  assertNever(editorTextActionsState.mode)
              }
            })}
          </>
        )}

        <Spacer size='8' />

        <OutputContainer>
          <StyledAiBackground base='org/primary' variant='primary' />
          <OutputContainerTokenProvider>
            {'instruction' in state && (
              <TruncatedText bold color='foreground/muted' lines={1} lineLength={50}>
                {state.instruction}
              </TruncatedText>
            )}
            {'output' in state && <PreWrapText>{state.output}</PreWrapText>}

            <InputForm
              onSubmit={e => {
                e.preventDefault()

                handleSubmit()
              }}
            >
              <InputWrapper>
                {state.type !== 'running' ? (
                  <>
                    <Icon iconId='keyboard' />
                    <StyledInput
                      placeholder={
                        state.type === 'initial'
                          ? t('create.text-actions.placeholder')
                          : `${t('create.text-actions.done-placeholder')}...`
                      }
                      list={previousInstructionsDatalistId}
                      value={draftInstruction}
                      onChange={e => setDraftInstruction(e.target.value)}
                      autoFocus
                    />
                    <StartGeneratingButton
                      type='submit'
                      tooltip={t('dictionary.generate')}
                      disabled={draftInstruction.trim().length === 0}
                    />
                  </>
                ) : (
                  <LoadingInput>
                    <LoadingInputTextContainer>
                      <LoaderAnimation size={24} />
                      <Text size='small' bold>
                        {t('author.slate.generating')}
                      </Text>
                    </LoadingInputTextContainer>
                  </LoadingInput>
                )}
              </InputWrapper>
              {state.type === 'running' && (
                <Button
                  variant='secondary'
                  onClick={e => {
                    e.preventDefault()
                    dispatchAction({ type: 'abort' })
                  }}
                >
                  {t('dictionary.cancel')}
                </Button>
              )}
              {state.type === 'done' && (
                <>
                  <RetryButton
                    tooltip={t('author.slate.retry')}
                    onClick={e => {
                      e.preventDefault()

                      void generateText({
                        sourceText: editorTextActionsState.sourceText,
                        instruction: state.instruction,
                        mode: editorTextActionsState.mode,
                      })
                    }}
                  />
                  <TrashButton tooltip={t('dictionary.discard')} onClick={onCloseWithoutValidation} />
                  <Button
                    variant='primary'
                    icon='checkmark--filled'
                    onClick={e => {
                      e.preventDefault()

                      if (!isEditableYjsEditor(editor)) return
                      const relativeRange = getRelativeRange(editor, editorTextActionsState.key)
                      assert(
                        relativeRange !== undefined,
                        `Could not find relative range with key ${editorTextActionsState.key}`
                      )

                      // We add a space before the output if we are in extend mode
                      const text =
                        editorTextActionsState.mode === 'extend' ? ' ' + state.output : state.output

                      Transforms.insertText(editor, text, {
                        at: getInsertionRangeOrPoint({ mode: editorTextActionsState.mode, relativeRange }),
                      })
                      onCloseWithoutValidation()
                    }}
                  >
                    {copy.acceptButtonText}
                  </Button>
                </>
              )}
              <datalist id={previousInstructionsDatalistId}>
                {previousInstructions.map((s, i) => (
                  <option key={i} value={s} />
                ))}
              </datalist>
            </InputForm>
          </OutputContainerTokenProvider>
        </OutputContainer>
      </OuterContainer>
    )
  }
)
