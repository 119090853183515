import { Avatar, IdentityWithMetadata } from 'sierra-domain/api/manage'
import { LightUser } from 'sierra-domain/user'

const getAvatarForUser = (user: LightUser): Avatar => {
  const initials = user.firstName.slice(0, 1) + user.lastName.slice(0, 1)

  return {
    type: 'color',
    initials,
    color: user.avatarColor,
  }
}

export const userToIdentityWithmetadata = (user: LightUser): IdentityWithMetadata => ({
  identity: {
    type: 'user',
    id: user.uuid,
  },
  name: user.firstName + ' ' + user.lastName,
  email: user.email,
  avatar: getAvatarForUser(user),
})
