import { queryClient } from 'sierra-client/api/query-client'
import { invalidateGetDashboard } from 'sierra-client/features/insights/api-hooks/use-get-dashboard'
import { getCachedQueryKey, useTypedMutation } from 'sierra-client/state/api'
import { InsightsDashboardGetResponse } from 'sierra-domain/api/insights'
import { XAnalyticsDashboardShareWithAll, XAnalyticsDashboardsGet } from 'sierra-domain/routes'

// eslint-disable-next-line @typescript-eslint/explicit-function-return-type
export const useDashboardShareWithAll = () => {
  return useTypedMutation(XAnalyticsDashboardShareWithAll, {
    onMutate: async variables => {
      const queryKey = getCachedQueryKey(XAnalyticsDashboardsGet, {
        dashboardId: variables.dashboardId,
      })

      // Cancel any outgoing refetches (so they don't overwrite our optimistic update)
      await queryClient.cancelQueries({ queryKey })

      // Snapshot the previous value
      const snapshottedDashboard = queryClient.getQueryData(queryKey)

      // Optimistically update to the new value
      queryClient.setQueryData(queryKey, (previousDashboard: InsightsDashboardGetResponse) => {
        const newDashboard: InsightsDashboardGetResponse = {
          ...previousDashboard,
          dashboard: {
            ...previousDashboard.dashboard,
            dashboard: { ...previousDashboard.dashboard.dashboard, shareWithAll: variables.shareWithAll },
          },
        }
        return newDashboard
      })

      // Return a context object with the snapshotted value
      return { snapshottedDashboard }
    },

    // If the mutation fails, use the context returned from onMutate to roll back
    onError: (error, variables, context) => {
      const queryKey = getCachedQueryKey(XAnalyticsDashboardsGet, {
        dashboardId: variables.dashboardId,
      })
      queryClient.setQueryData(queryKey, context?.snapshottedDashboard)
    },
    onSettled: async (data, error, variables) => {
      await invalidateGetDashboard({ dashboardId: variables.dashboardId })
    },
  })
}
