import { useMutation } from '@tanstack/react-query'
import { keyBy, maxBy } from 'lodash'
import React, { useCallback, useContext, useMemo, useState } from 'react'
import { useStableFunction } from 'sierra-client/hooks/use-stable-function'
import {
  DropAWordChangedData,
  DropAWordDeletedData,
  liveSessionDataChannel,
} from 'sierra-client/realtime-data/channels'
import { typedPost, useCachedQuery } from 'sierra-client/state/api'
import { useDispatch, useSelector } from 'sierra-client/state/hooks'
import { selectIsFacilitator } from 'sierra-client/state/live-session/selectors'
import { selectUser, selectUserId } from 'sierra-client/state/user/user-selector'
import { useHasManageAccess } from 'sierra-client/views/manage/permissions/use-has-manage-access'
import { dropAWordWordAdded, dropAWordWordDeleted } from 'sierra-client/views/v3-author/drop-a-word/logger'
import { PillDefinition } from 'sierra-client/views/v3-author/drop-a-word/renderer/types'
import { CreateContentId, LiveSessionId } from 'sierra-domain/api/nano-id'
import { DropAWordCardDeleteWordRequest, DropAWordCardUpsertWordRequest } from 'sierra-domain/api/strategy-v2'
import { UserId, UUID, uuid } from 'sierra-domain/api/uuid'
import { ScopedFileId } from 'sierra-domain/collaboration/types'
import { FileId } from 'sierra-domain/flexible-content/identifiers'
import { DropAWordData, File } from 'sierra-domain/flexible-content/types'
import {
  XRealtimeStrategyContentDataDropAWordDeleteWord,
  XRealtimeStrategyContentDataDropAWordUpsertWord,
  XRealtimeStrategyContentDataGetDropAWordResponses,
} from 'sierra-domain/routes'
import { asNonNullable, isDefined } from 'sierra-domain/utils'
import { color } from 'sierra-ui/color'
import { FCC } from 'sierra-ui/types'

const getMockUpPills = (userId: UserId | undefined): PillDefinition[] => {
  if (userId === undefined) return []
  const mocks: PillDefinition[] = [
    { word: 'lorem', id: uuid(), color: undefined, userId: userId, droppedAt: new Date() },
    { word: 'ipsum', id: uuid(), color: undefined, userId: userId, droppedAt: new Date() },
    { word: 'dolor', id: uuid(), color: undefined, userId: userId, droppedAt: new Date() },
    { word: 'sit amet', id: uuid(), color: undefined, userId: userId, droppedAt: new Date() },
    { word: 'consectetur', id: uuid(), color: undefined, userId: userId, droppedAt: new Date() },
    { word: 'adipiscing', id: uuid(), color: undefined, userId: userId, droppedAt: new Date() },
    { word: 'elit', id: uuid(), color: undefined, userId: userId, droppedAt: new Date() },
    { word: 'sed do eiusmod', id: uuid(), color: undefined, userId: userId, droppedAt: new Date() },
    { word: 'tempor', id: uuid(), color: undefined, userId: userId, droppedAt: new Date() },
    { word: 'incididunt', id: uuid(), color: undefined, userId: userId, droppedAt: new Date() },
    { word: 'ut labore', id: uuid(), color: undefined, userId: userId, droppedAt: new Date() },
    { word: 'et dolore', id: uuid(), color: undefined, userId: userId, droppedAt: new Date() },
    { word: 'magna', id: uuid(), color: undefined, userId: userId, droppedAt: new Date() },
    { word: 'aliqua', id: uuid(), color: undefined, userId: userId, droppedAt: new Date() },
    { word: 'Ut enim', id: uuid(), color: undefined, userId: userId, droppedAt: new Date() },
    { word: 'ad minim', id: uuid(), color: undefined, userId: userId, droppedAt: new Date() },
    { word: 'veniam', id: uuid(), color: undefined, userId: userId, droppedAt: new Date() },
  ]
  return mocks
}

type DropAWordFile = File & { data: DropAWordData }
export type DropAWordDataLayer =
  | {
      onAddWord: (word: string) => void
      deletePillWithId: (id: UUID) => void
      canDeletePillWithId: (id: UUID) => boolean
      pills: Array<PillDefinition> | undefined
      isInteractive: true
    }
  | { isInteractive: false; pills: Array<PillDefinition> | undefined }

const DataContext = React.createContext<DropAWordDataLayer | undefined>(undefined)

export const SelfPacedDropAWordDataProvider: FCC<{
  contentId: CreateContentId
  fileId: FileId
  isInteractive: boolean
  isCollaborator: boolean
}> = ({ children, fileId, contentId, isInteractive, isCollaborator }) => {
  const hasManageAccess = useHasManageAccess()
  const userId = useSelector(selectUserId)
  const dispatch = useDispatch()

  const dropAWordResponses = useCachedQuery(XRealtimeStrategyContentDataGetDropAWordResponses, {
    contentId,
    fileId,
  })

  const upsertWordMutation = useMutation({
    mutationFn: (data: DropAWordCardUpsertWordRequest) =>
      typedPost(XRealtimeStrategyContentDataDropAWordUpsertWord, data),
    onSuccess: () => dropAWordResponses.refetch(),
  })

  const deleteWordMutation = useMutation({
    mutationFn: (data: DropAWordCardDeleteWordRequest) =>
      typedPost(XRealtimeStrategyContentDataDropAWordDeleteWord, data),
    onSuccess: () => dropAWordResponses.refetch(),
  })

  const deletePillWithId = useStableFunction((responseId: UUID) => {
    deleteWordMutation.mutate({
      contentId,
      fileId,
      responseId,
    })

    const word = dropAWordResponses.data?.responses.find(pill => pill.id === responseId)?.word
    if (word === undefined || userId === undefined) return
    void dispatch(dropAWordWordDeleted({ wordLength: word.length, userId }))
  })

  const pills: PillDefinition[] | undefined = useMemo(
    () =>
      dropAWordResponses.data?.responses.map(response => ({
        id: response.id,
        word: response.word,
        color: color(response.color),
        userId: response.userId,
        droppedAt: response.droppedAt,
      })),
    [dropAWordResponses.data]
  )

  const canDeletePillWithId = useCallback(
    (responseId: UUID) => {
      if (isCollaborator || hasManageAccess) return true

      const pill = pills?.find(pill => pill.id === responseId)
      return pill?.userId === userId
    },
    [hasManageAccess, isCollaborator, pills, userId]
  )

  const onAddWord = useStableFunction((word: string) => {
    upsertWordMutation.mutate({
      contentId,
      fileId,
      word,
    })

    if (userId === undefined) return
    void dispatch(dropAWordWordAdded({ wordLength: word.length, userId }))
  })
  const value: DropAWordDataLayer =
    isInteractive === false
      ? {
          pills,
          isInteractive,
        }
      : {
          pills,
          isInteractive,
          deletePillWithId,
          canDeletePillWithId,
          onAddWord,
        }

  return <DataContext.Provider value={value}>{children}</DataContext.Provider>
}

export const CreateDropAWordDataProvider: FCC = ({ children }) => {
  const user = useSelector(selectUser)

  const mockupPills = getMockUpPills(user?.uuid)

  const [pills, setPills] = useState<PillDefinition[]>(mockupPills)
  const avatarColor = color(user?.avatarColor ?? 'blueVivid')
  const userId = useSelector(selectUserId)

  const value: DropAWordDataLayer = {
    pills,
    isInteractive: true,
    deletePillWithId: id => setPills(pills => pills.filter(pill => pill.id !== id)),
    canDeletePillWithId: () => true,
    onAddWord: word => {
      setPills(pills => [
        ...pills,
        { word: word, id: uuid(), color: avatarColor, userId: asNonNullable(userId), droppedAt: new Date() },
      ])
    },
  }

  return <DataContext.Provider value={value}>{children}</DataContext.Provider>
}

export const LiveDropAWordDataProvider: FCC<{
  liveSessionId: LiveSessionId
  file: DropAWordFile
  contentId: CreateContentId
  isInteractive: boolean
}> = ({ file, children, contentId, liveSessionId, isInteractive }) => {
  const [deletedIds, setDeletedIds] = useState<Set<UUID>>(new Set())
  const [realTimeDataMap, setRealTimeDataMap] = useState<{
    [id: string]: DropAWordChangedData
  }>({})
  const dispatch = useDispatch()

  const { isReceivingData } = liveSessionDataChannel.useChannel({
    channelId: liveSessionId,
    callback: newData => {
      const eventId = ScopedFileId.extractId(file.id)
      switch (newData.event) {
        case `drop-a-word-changed:${eventId}`: {
          const data = newData.data as DropAWordChangedData
          setRealTimeDataMap(previous => ({ ...previous, [data.id]: data }))
          break
        }
        case `drop-a-word-deleted:${eventId}`: {
          const data = newData.data as DropAWordDeletedData
          setDeletedIds(previous => new Set([...previous, data.id]))
          break
        }
      }
    },
  })

  const dropAWordResponses = useCachedQuery(
    XRealtimeStrategyContentDataGetDropAWordResponses,
    {
      contentId: contentId,
      fileId: file.id,
      liveSessionId,
    },
    {
      enabled: isReceivingData,
    }
  )

  const upsertUpsertWordMutation = useMutation({
    mutationFn: (data: DropAWordCardUpsertWordRequest) =>
      typedPost(XRealtimeStrategyContentDataDropAWordUpsertWord, data),
    onSuccess: () => dropAWordResponses.refetch(),
  })

  const dropAWordData = useMemo(() => {
    if (dropAWordResponses.data === undefined) return undefined

    const apiResponseMap = keyBy(dropAWordResponses.data.responses, 'id')
    const ids = new Set([...Object.keys(apiResponseMap), ...Object.keys(realTimeDataMap)] as UUID[])

    return {
      responses: Array.from(ids)
        .filter(id => !deletedIds.has(id))
        .map(id => {
          const realTimeData = realTimeDataMap[id]
          const apiData = apiResponseMap[id]

          const latest = maxBy([realTimeData, apiData], data =>
            data !== undefined ? new Date(data.updatedAt).valueOf() : 0
          )

          if (latest === undefined) return

          return {
            id: latest.id,
            word: latest.word,
            color: color(latest.color),
            userId: latest.userId,
            droppedAt: new Date(latest.droppedAt),
          }
        })
        .filter(isDefined),
    }
  }, [deletedIds, dropAWordResponses.data, realTimeDataMap])

  const isFacilitator = useSelector(selectIsFacilitator)
  const userId = useSelector(selectUserId)

  const canDeletePillWithId = useCallback(
    (id: string) => {
      if (isFacilitator) return true

      const pill = dropAWordData?.responses.find(pill => pill.id === id)
      return pill !== undefined && pill.userId === userId
    },
    [dropAWordData?.responses, isFacilitator, userId]
  )

  const onAddWord = useStableFunction((word: string) => {
    upsertUpsertWordMutation.mutate({
      fileId: file.id,
      liveSessionId,
      contentId,
      word,
    })

    if (userId === undefined) return
    void dispatch(dropAWordWordAdded({ wordLength: word.length, userId }))
  })

  const deleteWordMutation = useMutation({
    mutationFn: (data: DropAWordCardDeleteWordRequest) =>
      typedPost(XRealtimeStrategyContentDataDropAWordDeleteWord, data),
    onSuccess: () => dropAWordResponses.refetch(),
  })

  const deletePillWithId = useStableFunction((responseId: UUID) => {
    deleteWordMutation.mutate({
      contentId,
      fileId: file.id,
      responseId,
      liveSessionId,
    })

    const word = dropAWordData?.responses.find(pill => pill.id === responseId)?.word
    if (word === undefined || userId === undefined) return
    void dispatch(dropAWordWordDeleted({ wordLength: word.length, userId }))
  })
  const pills = dropAWordData?.responses
  const value: DropAWordDataLayer = isInteractive
    ? {
        deletePillWithId,
        canDeletePillWithId,
        onAddWord,
        pills,
        isInteractive,
      }
    : {
        pills,
        isInteractive,
      }

  return <DataContext.Provider value={value}>{children}</DataContext.Provider>
}

export function useDropAWordData(): DropAWordDataLayer {
  const data = useContext(DataContext)
  const userId = useSelector(selectUserId)
  const defaultData: DropAWordDataLayer & { isInteractive: false } = useMemo(
    () => ({
      pills: getMockUpPills(userId),
      isInteractive: false,
    }),
    [userId]
  )

  return data ?? defaultData
}
