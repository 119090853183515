import { JSONContent } from '@tiptap/react'
import { AnimatePresence, motion } from 'framer-motion'
import { useAtom } from 'jotai'
import localStorage from 'local-storage-fallback'
import _ from 'lodash'
import React, { useMemo, useState } from 'react'
import { convertGQLAvatar } from 'sierra-client/api/graphql/util/convert-gql-avatar'
import { useSubmissionReviewMutation } from 'sierra-client/api/hooks/use-homework'
import { Link } from 'sierra-client/components/common/link'
import { useLocalizedFormatters } from 'sierra-client/core/format'
import { gqlGradeStatusToGradeStatus } from 'sierra-client/features/homework'
import { useTranslation } from 'sierra-client/hooks/use-translation'
import { atomWithStorage } from 'sierra-client/state/storage'
import { FilePreview } from 'sierra-client/views/manage/homeworks/homework-file-preview'
import { CriteriaRow } from 'sierra-client/views/manage/homeworks/manage-homeworks-modal/criteria-row'
import {
  CriteriaValue,
  GQLReviewSubmission,
  PreviousSubmissions,
  Rating,
} from 'sierra-client/views/manage/homeworks/manage-homeworks-modal/types'
import { ManageReviewFreetextPreview } from 'sierra-client/views/manage/homeworks/manage-review-freetext-preview'
import { useSubmissionForReview } from 'sierra-client/views/manage/homeworks/use-review-modal-data'
import { useTracking } from 'sierra-client/views/manage/hooks/use-tracking'
import { withPanelOpenWhenData } from 'sierra-client/views/manage/utils/with-modal'
import {
  GradeText,
  LightText,
  parseStringToJsonContent,
} from 'sierra-client/views/v3-author/homework/homework-atoms'
import { ReviewComment } from 'sierra-client/views/v3-author/homework/homework-upload/review-comment'
import { GradeStatus } from 'sierra-domain/api/homework'
import { MimeType } from 'sierra-domain/homework'
import { asNonNullable, assertNever, getUserName, isNonEmptyJsonString } from 'sierra-domain/utils'
import { FormElement, Icon, MenuItem, Tooltip, TruncatedText, UserDisplay } from 'sierra-ui/components'
import { FreeTextEditor } from 'sierra-ui/missions/workflows/free-text-editor'
import { Button, IconButton, LoadingSpinner, Text, View } from 'sierra-ui/primitives'
import { SingleSelectDropdown } from 'sierra-ui/primitives/menu-dropdown'
import { token } from 'sierra-ui/theming'
import styled from 'styled-components'

const SubmissionsView = styled(View).attrs({ direction: 'column', gap: '64', grow: true })`
  width: 100%;
`

const SingleSelectDropdownWrapper = styled(View)`
  > div {
    width: 100%;

    > div {
      width: 100%;
    }
  }
`

const CardTitleText = styled(Text)`
  min-width: fit-content;
`

const HorizontalDivider = styled.hr`
  width: 100%;
  height: 1px;
  background-color: ${token('border/default')};
  flex-shrink: 0;
`

const FilePreviewWrapper = styled(View)`
  width: 100%;
  aspect-ratio: 19 / 9;
`

const CloseButton = styled(IconButton)`
  color: ${token('foreground/muted')};

  &:hover {
    color: ${token('foreground/primary')};
  }
`

const SubmitButtonContainer = styled(View).attrs({
  background: 'surface/default',
  justifyContent: 'flex-end',
  marginTop: '32',
})`
  position: sticky;
  bottom: 0;

  &::before {
    content: '';
    z-index: 3;
    display: block;
    position: absolute;
    top: -32px;
    left: 0;
    width: 100%;
    height: 32px;
    background: linear-gradient(
      to top,
      ${token('surface/default').opacity(1)} 30%,
      ${token('surface/default').opacity(0)}
    );
  }
`

const ModalHeaderContainer = styled(View).attrs({
  direction: 'column',
  gap: 'none',
  background: 'surface/default',
  marginBottom: '24',
})`
  position: sticky;
  top: 0;

  z-index: 3;

  &::after {
    content: '';
    z-index: 3;
    display: block;
    position: absolute;
    bottom: -24px;
    left: 0;
    width: 100%;
    height: 24px;
    background: linear-gradient(
      to bottom,
      ${token('surface/default').opacity(1)} 30%,
      ${token('surface/default').opacity(0)}
    );
  }
`

// todo(workflows): Show "view more" button when instructions are too long.
//  We can use `ResizeObserver` on the inner ProseMirror element. We'll add a max-height to
//  the parent element and then show a button that will remove the max-height when clicked.
//  (only show the button if the contents don't fit in the specified max height.)
const StyledFreeTextEditor = styled(FreeTextEditor)`
  max-height: fit-content;
`
const ExerciseBreadcrumb: React.FC<{
  homeworkData: NonNullable<GQLReviewSubmission['homework']>
}> = ({ homeworkData }) => {
  const { t } = useTranslation()

  const { course } = homeworkData

  return (
    <View grow>
      <Link next href={`/s/${course.courseId}/file:${homeworkData.cardId}`}>
        <Tooltip title={t('manage.homework.view-exercise-in-course-tooltip')}>
          <View gap='4'>
            <TruncatedText lines={1} as={LightText} size='small' color='foreground/muted'>
              {course.title}
            </TruncatedText>
            <Icon iconId='chevron--right--small' color='grey25' />
            <CardTitleText size='small'>
              {homeworkData.title ?? t('dictionary.homework-singular')}
            </CardTitleText>
          </View>
        </Tooltip>
      </Link>
    </View>
  )
}

type FileInfoWithURL = {
  fileId: string
  fileName: string
  mimeType: MimeType
  size: number
  url: string
}

const getFileInfoWithURL = (
  gqlFileSubmission: Extract<
    NonNullable<GQLReviewSubmission['submission']>,
    { __typename: 'FileHomeworkSubmission' }
  >
): FileInfoWithURL => {
  return {
    fileId: gqlFileSubmission.fileId,
    fileName: gqlFileSubmission.fileName,
    mimeType: gqlFileSubmission.fileType as MimeType, // hopefully OK -- this isn't typechecked in the GQL API.
    size: gqlFileSubmission.fileSize,
    url: gqlFileSubmission.fileUrl,
  }
}

const InternalManageHomeworksModal: React.FC<{
  data: GQLReviewSubmission
  previousSubmissions: PreviousSubmissions
  onClose: () => void
}> = ({ data, previousSubmissions, onClose }) => {
  const tracking = useTracking()
  const { t } = useTranslation()
  const { formatTimestamp } = useLocalizedFormatters()
  const { mutate: reviewSubmissionMutation } = useSubmissionReviewMutation()

  const reviewInstructions = data.homework.reviewInstructions?.tiptapJSONData ?? undefined

  const user = data.submitter

  // This should be OK -- otherwise we should handle it in the parent component.
  const submission = asNonNullable(data.submission)
  const submissionId = submission.id
  const submissionCreatedAt = submission.createdAt

  const fileInfo =
    submission.__typename === 'FileHomeworkSubmission' ? getFileInfoWithURL(submission) : undefined
  const text = submission.__typename === 'TextHomeworkSubmission' ? submission.text : undefined

  const gradeStatus = gqlGradeStatusToGradeStatus[data.gradeStatus]
  const currentFeedback = data.feedback ?? undefined

  const [grade, setGrade] = useState<GradeStatus>(gradeStatus)

  const [criteriaValues, setCriteriaValues] = useState<Record<string, CriteriaValue>>(() =>
    _.chain(data.criteria)
      .keyBy(criteria => criteria.id)
      .mapValues(({ data }): CriteriaValue => {
        switch (data.__typename) {
          case 'SubmissionReviewCriteria1to5':
            return {
              type: 'rating-1-to-5',
              // todo: move validation elsewhere
              rating: [1, 2, 3, 4, 5].includes(data.rating) ? (data.rating as Rating) : undefined,
            }
          default:
            assertNever(data.__typename)
        }
      })
      .value()
  )

  const newFeedbackAtom = useMemo(() => {
    const localStorageKey = `manage-review-free-text-submission-${submissionId}`

    const initialValue = parseStringToJsonContent(
      currentFeedback ?? localStorage.getItem(localStorageKey) ?? undefined
    )

    return atomWithStorage<JSONContent | undefined>(localStorageKey, initialValue)
  }, [submissionId, currentFeedback])
  const [newFeedback, setNewFeedback] = useAtom(newFeedbackAtom)

  const menuItems: MenuItem<Exclude<GradeStatus, 'not-submitted'>>[] = [
    {
      type: 'label',
      id: 'not-graded',
      label: t('manage.homework.select-grade'),
      selected: grade === 'not-graded',
    },
    {
      type: 'label',
      id: 'passed',
      label: t('homework.passed'),
      icon: 'checkmark--outline',
      selected: grade === 'passed',
    },
    {
      type: 'label',
      id: 'failed',
      label: t('homework.failed'),
      icon: 'close--circle',
      selected: grade === 'failed',
    },
  ]
  const selectedItem = menuItems.find(item => item.id === grade)

  return (
    <>
      <View direction='column' grow>
        {reviewInstructions !== undefined && (
          <>
            <StyledFreeTextEditor content={parseStringToJsonContent(reviewInstructions)} editable={false} />
            <HorizontalDivider />
          </>
        )}

        <SubmissionsView>
          <View direction='column'>
            <View gap='16' direction='column'>
              <View gap='16'>
                <View grow>
                  <UserDisplay
                    primaryText={
                      getUserName({
                        firstName: user.firstName ?? undefined,
                        lastName: user.lastName ?? undefined,
                      }) ?? ''
                    }
                    avatar={{
                      ...convertGQLAvatar(user.avatar),
                      firstName: user.firstName ?? '',
                      lastName: user.lastName ?? '',
                      size: 'medium',
                    }}
                  />
                </View>
                <View>
                  <LightText size='small'>{formatTimestamp(submissionCreatedAt)}</LightText>
                </View>
              </View>
              {fileInfo !== undefined && <FilePreview fileInfo={fileInfo} />}
              {text !== undefined && <ManageReviewFreetextPreview text={text} />}
              {data.homework.reviewCriteria.length > 0 && (
                <View direction='column' gap='24' margin='8 none'>
                  {data.homework.reviewCriteria.map(criteria => (
                    <CriteriaRow
                      key={criteria.id}
                      criteria={criteria}
                      value={criteriaValues[criteria.id]}
                      setValue={newValue => setCriteriaValues(prev => ({ ...prev, [criteria.id]: newValue }))}
                    />
                  ))}
                </View>
              )}
              <FormElement label={t('manage.homework.grade')}>
                <SingleSelectDropdownWrapper>
                  <SingleSelectDropdown
                    menuItems={menuItems}
                    selectedItem={selectedItem}
                    onSelect={item => setGrade(item.id)}
                    bold
                  />
                </SingleSelectDropdownWrapper>
              </FormElement>
              <FormElement label={t('dictionary.comment-singular')}>
                {currentFeedback === undefined ? (
                  <FreeTextEditor
                    content={newFeedback}
                    editable
                    onChange={setNewFeedback}
                    placeholder={t('dictionary.add-comment-placeholder')}
                    ariaLabel={t('dictionary.comment-singular')}
                    menuTranslations={{
                      list: t('dictionary.list'),
                      alignment: t('create.toolbar.text-alignment'),
                      text: t('dictionary.text'),
                      heading: t('font.heading'),
                    }}
                  />
                ) : (
                  <FreeTextEditor content={newFeedback} editable={false} />
                )}
              </FormElement>
            </View>
          </View>

          {previousSubmissions.length > 0 && (
            <View direction='column' gap='32' marginBottom='32'>
              <Text size='regular' bold>
                {t('manage.homework.previous-submissions')}
              </Text>
              {previousSubmissions.map(
                ({ submission: s, gradeStatus: g, feedback: f, reviewerId }, index) => {
                  /*
                It's nullable because we are reusing PotentialHomeworkSubmission, however for previous
                submissions we don't include "no-submission" submissions (duh).
                 */
                  const submission = asNonNullable(s)
                  const fileInfo =
                    submission.__typename === 'FileHomeworkSubmission'
                      ? getFileInfoWithURL(submission)
                      : undefined
                  const feedback = f ?? undefined
                  const gradeStatus = gqlGradeStatusToGradeStatus[g]
                  return (
                    <View key={submission.id} direction='column' gap='16'>
                      {
                        fileInfo !== undefined && (
                          <FilePreviewWrapper>
                            <FilePreview fileInfo={fileInfo} autoplay={false} />
                          </FilePreviewWrapper>
                        ) //TODO: Tomas add text preview
                      }
                      {'text' in submission && <ManageReviewFreetextPreview text={submission.text} />}

                      <View gap='16'>
                        <LightText size='small'>{formatTimestamp(submission.createdAt)}</LightText>
                        <View>
                          <GradeText gradeStatus={gradeStatus} />
                        </View>
                      </View>
                      {isNonEmptyJsonString(feedback) && (
                        <ReviewComment feedback={feedback} reviewerId={reviewerId ?? undefined} />
                      )}
                      {index + 1 < previousSubmissions.length && <HorizontalDivider />}
                    </View>
                  )
                }
              )}
            </View>
          )}
        </SubmissionsView>
      </View>

      <SubmitButtonContainer paddingBottom='medium'>
        <Button
          variant='secondary'
          onClick={() => {
            setNewFeedback({})
            onClose()
          }}
        >
          {t('dictionary.cancel')}
        </Button>
        <Button
          variant='primary'
          onClick={() => {
            reviewSubmissionMutation(
              {
                submissionId,
                gradeStatus: grade,
                feedback: JSON.stringify(newFeedback),
                homeworkId: data.homework.id,
                criteriaValues: _.chain(criteriaValues)
                  .entries()
                  .flatMap(([criteriaId, value]) => (value.rating !== undefined ? [[criteriaId, value]] : []))
                  .fromPairs()
                  .value(),
              },
              {
                onSuccess: () => {
                  setNewFeedback({})
                  onClose()
                  tracking.submission.submitReview({ homeworkId: data.homework.id, submissionId })
                },
              }
            )
          }}
        >
          {t('dictionary.submit')}
        </Button>
      </SubmitButtonContainer>
    </>
  )
}

const FadeInOut = styled(motion.div).attrs({
  initial: { opacity: 0 },
  animate: { opacity: 1 },
  exit: { opacity: 0 },
})`
  display: flex;
  flex-direction: column;
`

type ManageHomeworkModalProps = {
  reviewSubmissionId: string
}

export const ManageHomeworksModal = withPanelOpenWhenData<ManageHomeworkModalProps>(
  {
    size: { width: 700 },
  },
  ({ reviewSubmissionId, onClose }) => {
    const { t } = useTranslation()

    const gqlSubmissionQuery = useSubmissionForReview(reviewSubmissionId)

    const submissionData = gqlSubmissionQuery.data?.homeworkSubmission ?? undefined
    const homeworkData = submissionData?.homework

    return (
      <View direction='column' paddingLeft='medium' paddingRight='medium' gap='none' grow>
        <ModalHeaderContainer>
          <View justifyContent='space-between' paddingTop='medium'>
            <Text size='large' bold>
              {t('manage.homework.review')}
            </Text>

            <CloseButton
              size='default'
              variant='transparent'
              iconId='close'
              tooltip={t('dictionary.close')}
              onClick={onClose}
            />
          </View>
          {homeworkData !== undefined && <ExerciseBreadcrumb homeworkData={homeworkData} />}
        </ModalHeaderContainer>

        <AnimatePresence mode='wait'>
          {/* TODO: Fix error state */}
          {gqlSubmissionQuery.isPending ? (
            <FadeInOut key='loading' transition={{ duration: 0.1, delay: 0.1 }}>
              <LoadingSpinner />
            </FadeInOut>
          ) : submissionData !== undefined ? (
            <FadeInOut key='contents' transition={{ duration: 0.15, delay: 0.05 }}>
              <InternalManageHomeworksModal
                data={submissionData}
                // todo: add previousSubmission to GQL API
                previousSubmissions={submissionData.previousSubmissions}
                onClose={onClose}
              />
            </FadeInOut>
          ) : null}
        </AnimatePresence>
      </View>
    )
  }
)
