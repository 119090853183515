import { GroupProgramDetail } from 'sierra-client/views/manage/components/layout/common/group-program-detail'
import { UserGroupType } from 'sierra-domain/api/manage'
import { assertNever, iife } from 'sierra-domain/utils'
import { color } from 'sierra-ui/color'
import { Icon, IconId } from 'sierra-ui/components'
import { IconButton, Text, View } from 'sierra-ui/primitives'
import { SanaColor } from 'sierra-ui/types'
import styled from 'styled-components'

const { Title, Description, LastLine, Dot, Left, Actions } = GroupProgramDetail

type WrapperProps = {
  backgroundColor: SanaColor
}
const Wrapper = styled(GroupProgramDetail.Wrapper)<WrapperProps>`
  background-color: ${p => color(p.theme.color[p.backgroundColor]).opacity(0.1)};
`

const Circle = styled(View).attrs({
  radius: 'round',
  direction: 'row',
  alignItems: 'center',
  justifyContent: 'center',
})`
  width: 32px;
  height: 32px;
`

const CircleLabel = styled(Text).attrs({ color: 'white', bold: true, size: 'small' })``

const GroupType = styled(View).attrs({ justifyContent: 'center', alignItems: 'center' })``

const GroupLabel = styled(Text).attrs({ bold: true, size: 'small' })``

type HeaderStyles = {
  avatarColor: SanaColor
  icon?: IconId
}

const userGroupTypeToHeaderStyle: Record<UserGroupType, HeaderStyles> = {
  'user-scim': {
    avatarColor: 'orangeBright',
    icon: 'link',
  },
  'user-api': {
    avatarColor: 'orangeBright',
    icon: 'link',
  },
  'user-manual': {
    avatarColor: 'greenBright',
  },
  'user-filter': {
    avatarColor: 'purpleBright',
    icon: 'smart',
  },
}

export const ManageUserGroupHeader: React.FC<{
  type: UserGroupType
  title: string | undefined
  description: string | undefined
  canDelete: boolean
  onDelete: () => void
  canEdit: boolean
  onEdit: () => void
}> = ({ type, title, description, canDelete, onDelete, canEdit, onEdit }) => {
  const styles = userGroupTypeToHeaderStyle[type]

  const groupLabel = iife(() => {
    switch (type) {
      case 'user-scim':
      case 'user-api':
        return 'External group' // TODO: make this into a translation type
      case 'user-manual':
        return 'Group'
      case 'user-filter':
        return 'Smart group'
      default:
        assertNever(type)
    }
  })

  return (
    <Wrapper backgroundColor={styles.avatarColor}>
      <Circle background={styles.avatarColor}>
        <CircleLabel>{title?.[0] ?? ''}</CircleLabel>
      </Circle>
      <Title>{title ?? ''}</Title>
      <LastLine>
        <Left>
          <GroupType>
            {styles.icon !== undefined && <Icon iconId={styles.icon} />}
            <GroupLabel>{groupLabel}</GroupLabel>
          </GroupType>
          {description !== undefined && description !== '' && (
            <>
              <Dot />
              <Description>{description}</Description>
            </>
          )}
        </Left>
        {(canEdit || canDelete) && (
          <Actions>
            {canEdit && <IconButton variant='transparent' iconId='edit' onClick={onEdit} />}
            {canDelete && <IconButton variant='transparent' iconId='trash-can' onClick={onDelete} />}
          </Actions>
        )}
      </LastLine>
    </Wrapper>
  )
}
