/* eslint-disable react/jsx-no-literals */
import React from 'react'
import {
  BigPadding,
  PrintButton,
  Wrap,
  WrapButtons,
} from 'sierra-client/views/learner/certificate/components'
import { CertificateProps } from 'sierra-client/views/learner/certificate/types'
import {
  formatDateWithMonthAndYear,
  getImageUrl,
  handlePrint,
} from 'sierra-client/views/learner/certificate/utils'
import { Heading, Spacer, Text } from 'sierra-ui/primitives'
import styled, { css } from 'styled-components'

const StyledWrap = styled(Wrap)`
  @page {
    size: portrait;
  }

  break-after: page;
`

const Container = styled.div<{ $alignCenter?: boolean }>`
  display: flex;
  flex-direction: column;
  height: 100%;
  max-height: 100%;
  ${p =>
    p.$alignCenter !== undefined &&
    css`
      align-items: center;
    `}
`

const StyledWrapButtons = styled(WrapButtons)`
  left: 2rem;
  right: auto;
`

const UnderlinedHeadingContainer = styled.div`
  padding: 0 8px;
  border-bottom: 1px solid black;
`

const Body = styled(Text)`
  max-width: 50ch;
  text-align: center;
`

// Note: Fixed position elements render on every page when printing.

const BrightsLogoWrapper = styled.div`
  position: fixed;
  top: 48px;
  right: 48px;
  width: 100px;
`

const BrightsLogoImg = styled.img`
  width: 100%;
`
const FixedPositionLogo: React.FC = () => (
  <BrightsLogoWrapper>
    <BrightsLogoImg src={getImageUrl('brights-logo.svg')} />
  </BrightsLogoWrapper>
)

const FullScreenBorder = styled.div`
  position: fixed;
  inset: 32px;

  @media print {
    border: 1px solid black;
  }
`

const Page1: React.FC<CertificateProps> = ({ user, completionTimestamp }) => (
  <StyledWrap>
    <Container $alignCenter={true}>
      <BigPadding />
      <Heading color='LEGACY_DEFAULT_HEADING_COLOR_REPLACE_ASAP' size='h2'>
        Hållbarhetsacceleratorn 2023
      </Heading>
      <Spacer size='xxlarge' />
      <Text color='LEGACY_DEFAULT_TEXT_COLOR_REPLACE_ASAP' size='large'>
        This diploma is awarded to
      </Text>
      <Spacer size='small' />
      <UnderlinedHeadingContainer>
        <Heading color='LEGACY_DEFAULT_HEADING_COLOR_REPLACE_ASAP' size='h4'>
          {user.firstName} {user.lastName}
        </Heading>
      </UnderlinedHeadingContainer>
      <Spacer size='small' />
      <Body color='LEGACY_DEFAULT_TEXT_COLOR_REPLACE_ASAP' size='large'>
        for successfully completing the course Hållbarhetsacceleratorn
      </Body>
      <Spacer size='small' />
      <Text color='LEGACY_DEFAULT_TEXT_COLOR_REPLACE_ASAP' size='large' bold>
        {formatDateWithMonthAndYear(completionTimestamp)}
      </Text>
      <Spacer size='144' />
      <Heading color='LEGACY_DEFAULT_HEADING_COLOR_REPLACE_ASAP' size='h5'>
        Brights Learning
      </Heading>
    </Container>
  </StyledWrap>
)

const TwoColumnContainer = styled.ul`
  --gap: 32px;
  display: flex;
  flex-wrap: wrap;
  gap: var(--gap);
  margin-left: 32px;

  > li {
    flex-basis: calc(50% - var(--gap) / 2);
  }
`

const Page2: React.FC<CertificateProps> = () => (
  <StyledWrap>
    <Container>
      <Spacer size='small' />
      <Heading color='LEGACY_DEFAULT_HEADING_COLOR_REPLACE_ASAP' size='h4'>
        About the course
      </Heading>
      <Text color='LEGACY_DEFAULT_TEXT_COLOR_REPLACE_ASAP' size='large'>
        Hållbarhetsacceleratorn is a e-learning course within the fundamentals of Sustainability.
        Environmental, social, and economic sustainability are important for the future and the United Nations
        has adopted The Sustainable Development Goals in 2015 as a universal command to end poverty and
        protect the planet. As an organization, it is important that businesses integrate sustainability into
        their business model to reduce risks but also to achieve profitability. Also, sustainability is today
        an important issue were many stakeholders demand companies to implement a sustainable way of working
        which has resulted in a demand for skills and knowledge in the field.
      </Text>

      <Spacer size='medium' />
      <Heading color='LEGACY_DEFAULT_HEADING_COLOR_REPLACE_ASAP' size='h4'>
        Content
      </Heading>
      <TwoColumnContainer>
        <Text color='LEGACY_DEFAULT_TEXT_COLOR_REPLACE_ASAP' as='li' size='large'>
          How to drive a positive change in sustainability, at an individual and organizational level.
        </Text>
        <Text color='LEGACY_DEFAULT_TEXT_COLOR_REPLACE_ASAP' as='li' size='large'>
          Knowledge about the correlation between profitability and sustainability.
        </Text>
        <Text color='LEGACY_DEFAULT_TEXT_COLOR_REPLACE_ASAP' as='li' size='large'>
          The importance of sustainability skills, now and in the future.
        </Text>
        <Text color='LEGACY_DEFAULT_TEXT_COLOR_REPLACE_ASAP' as='li' size='large'>
          How to communicate about sustainability.
        </Text>
      </TwoColumnContainer>

      <Spacer size='medium' />
      <Heading color='LEGACY_DEFAULT_HEADING_COLOR_REPLACE_ASAP' size='h4'>
        Brights
      </Heading>
      <Text color='LEGACY_DEFAULT_TEXT_COLOR_REPLACE_ASAP' size='large'>
        The world is changing faster than ever and the need to learn has never been bigger. Rapidly shifting
        skills-needs create a tremendous challenge for companies, individuals, and society. At Brights, we
        rethink the ways we look at learning. We build solutions which start with motivation, put skills
        first, leads to return on learning and are better embedded into worklife. To succeed we need to adapt,
        to adapt we need to change and to change we need to learn.
      </Text>
    </Container>
  </StyledWrap>
)

export const BrightsCertificate: React.FC<CertificateProps> = props => (
  <>
    <FixedPositionLogo />
    <FullScreenBorder />
    <Page1 {...props} />
    <Page2 {...props} />
    <StyledWrapButtons>
      <PrintButton variant='secondary' onClick={handlePrint}>
        Print or download
      </PrintButton>
    </StyledWrapButtons>
  </>
)
