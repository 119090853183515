import { useMemo } from 'react'
import { useOrderedVideoAvatarBarParticipants } from 'sierra-client/components/liveV2/live-layer/video-avatar-bar/use-ordered-video-avatar-bar-participants'
import {
  UseGridParticipants,
  useGenericGridParticipants,
} from 'sierra-client/components/liveV2/participant-hooks/participant-grid-hooks'
import { useSelector } from 'sierra-client/state/hooks'
import { selectIsCurrentUserPinned } from 'sierra-client/state/live-session/selectors'
import { CreateContentId } from 'sierra-domain/api/nano-id'

export const useSidebarGridParticipants = ({
  participantLimit = 6,
  flexibleContentId,
}: {
  participantLimit: number
  flexibleContentId: CreateContentId
}): UseGridParticipants => {
  const { videosDisplayedInLobby, allParticipants, participantCount } =
    useGenericGridParticipants(flexibleContentId)

  const activeParticipants = useOrderedVideoAvatarBarParticipants({
    participants: allParticipants,
    numberOfAvatarsThatFit: participantCount <= participantLimit ? participantLimit : participantLimit - 1,
  })

  const passiveParticipants = useMemo(() => {
    const remoteVisibleAgoraUIDs = activeParticipants.map(p => p.agoraUID)

    return (
      allParticipants
        .filter(participant => !remoteVisibleAgoraUIDs.includes(participant.agoraUID))
        // If videosDisplayedInLobby then pinned users are displayed there. Filter them from the sidebar
        .filter(participant => !participant.isPinned)
    )
  }, [allParticipants, activeParticipants])

  const isCurrentUserPinned = useSelector(selectIsCurrentUserPinned)
  const localUserVisibleInGrid = !videosDisplayedInLobby || !isCurrentUserPinned

  const participants = useMemo(
    () =>
      localUserVisibleInGrid ? activeParticipants : activeParticipants.filter(it => !it.isCurrentClient),
    [localUserVisibleInGrid, activeParticipants]
  )

  const useLowResVideo = activeParticipants.length > 1
  const chatOverflowDirection = activeParticipants.length > 4 ? 'left' : 'none'

  return { participants, passiveParticipants, chatOverflowDirection, useLowResVideo }
}
