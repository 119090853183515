import { createFileRoute } from '@tanstack/react-router'
import { PageIdentifier, SanaPage } from 'sierra-client/layout/sana-page'
import { NativeAccountAction } from 'sierra-client/views/authentication/native/account-action'
import { z } from 'zod'

const RouteComponent = function Page(): React.ReactElement {
  const { code, mode } = Route.useSearch()

  return (
    <SanaPage page={PageIdentifier.AuthenticationLogin()}>
      {code !== undefined && mode !== undefined && <NativeAccountAction code={code} mode={mode} />}
    </SanaPage>
  )
}

export const Route = createFileRoute('/account-action')({
  component: RouteComponent as React.FC,
  validateSearch: z.object({
    mode: z.string().optional(),
    code: z.string().optional(),
  }),
})
