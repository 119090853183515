import React, { useState } from 'react'
import { queryClient } from 'sierra-client/api/query-client'
import { useUpsertDashboard } from 'sierra-client/features/insights/api-hooks/use-upsert-dashboard'
import { redirectToDashboard } from 'sierra-client/features/insights/dashboard-selector/utils'
import { getUpsertDashboardDataForWidget } from 'sierra-client/features/insights/dashboard/utils'
import { DashboardSuggestions } from 'sierra-client/features/insights/home/dashboard-suggestions'
import { AllDashboards } from 'sierra-client/features/insights/home/dashboards-all'
import { SavedHeatmaps } from 'sierra-client/features/insights/home/heatmaps'
import { LegacyReports } from 'sierra-client/features/insights/home/legacy-reports'
import { useAllFilterDomainReps } from 'sierra-client/features/insights/hooks/use-insights-views'
import { SearchWidgetResult } from 'sierra-client/features/insights/search-insights/types'
import { WidgetBuilderModal } from 'sierra-client/features/insights/widget-builder'
import { PartialDashboardWidget } from 'sierra-client/features/insights/widget-builder/types'
import { WidgetBuilderModalState } from 'sierra-client/features/insights/widget-builder/widget-builder-modal'
import { getCachedQueryKey, typedPost } from 'sierra-client/state/api'
import { Dashboard } from 'sierra-domain/api/insights'
import { XAnalyticsDashboardsGet } from 'sierra-domain/routes'
import { View } from 'sierra-ui/primitives'
import { minWidth } from 'sierra-ui/utils/media-query-styles'
import styled from 'styled-components'
import { InsightsAssistantWithSearch } from '../search-insights'

const PageContainer = styled(View)`
  width: 100%;
`

const RightSideView = styled(View)`
  width: 100%;
  ${minWidth.desktop_small} {
    max-width: 21rem;
  }
`

const ColumnWrapperView = styled(View)`
  flex-direction: column;
  ${minWidth.desktop_small} {
    flex-direction: row;
  }
`

/**
 * Home view for Insights that lists all dashboards with some metadata.
 * TODOs before relase:
 * - [ ] Fix the integration tests when flag is enabled
 * - [ ] Proper error handling
 * - [ ] Fix the avatar stack mapping
 * - [ ] Validate the copy and add translations
 * - [ ] Create a separate DB query that fetches collaborators for all dashboards in one query?
 */
export const InsightsHome: React.FC = () => {
  const mutateDashboard = useUpsertDashboard()
  const allFilterDomainReps = useAllFilterDomainReps()
  const [widgetBuilderModalState, setWidgetBuilderModalState] = useState<WidgetBuilderModalState>('closed')

  const handleInsightsAssistantTemplate = (template: SearchWidgetResult): void => {
    setWidgetBuilderModalState({
      type: 'open-template-from-home',
      dashboardWidget: {
        widget: template.widget,
        title: template.title,
        settings: {
          automaticTitle: false,
        },
      },
    })
  }

  const onModalSubmit = async (
    partialDashboardWidget: PartialDashboardWidget,
    onSuccess: () => void,
    dashboardId?: Dashboard['id']
  ): Promise<void> => {
    if (dashboardId === undefined) {
      throw new Error('No dashboard id supplied')
    }
    const queryKey = getCachedQueryKey(XAnalyticsDashboardsGet, { dashboardId })
    const dashboardResponse = await queryClient.fetchQuery({
      queryKey: queryKey,
      queryFn: () => typedPost(XAnalyticsDashboardsGet, { dashboardId }),
    })
    const upsertData = getUpsertDashboardDataForWidget({
      dashboardWidget: partialDashboardWidget,
      dashboard: dashboardResponse.dashboard.dashboard,
      allFilterDomainReps,
    })
    mutateDashboard.mutate(
      { dashboard: { id: dashboardId, ...upsertData.dashboard } },
      {
        onSuccess: () => {
          onSuccess()
          void redirectToDashboard(dashboardId)
        },
      }
    )
  }

  return (
    <PageContainer direction='column'>
      <InsightsAssistantWithSearch
        onAskAssistant={query => setWidgetBuilderModalState({ type: 'open-generate-from-home', query })}
        handleTemplateSelect={handleInsightsAssistantTemplate}
      />
      <WidgetBuilderModal
        state={widgetBuilderModalState}
        onClose={() => setWidgetBuilderModalState('closed')}
        onSubmit={onModalSubmit}
        isSubmitting={mutateDashboard.isPending}
      />
      <View direction='column' marginTop='32'>
        <DashboardSuggestions />
        <ColumnWrapperView gap='80' alignItems='flex-start'>
          <View direction='column' gap='32' grow>
            <AllDashboards />
          </View>
          <RightSideView direction='column' grow gap='32'>
            <SavedHeatmaps />
            <LegacyReports />
          </RightSideView>
        </ColumnWrapperView>
      </View>
    </PageContainer>
  )
}
