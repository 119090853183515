import { produce } from 'immer'
import { useAtom } from 'jotai'
import { useAtomValue } from 'jotai/index'
import { isEmpty, isEqual } from 'lodash'
import { useCallback, useMemo, useRef } from 'react'
import { inviteUsersEditAttributesOpened } from 'sierra-client/core/logging/users/logger'
import { usePost } from 'sierra-client/hooks/use-post'
import { AppLanguageCode } from 'sierra-client/i18n/app-languages.generated'
import { useDispatch } from 'sierra-client/state/hooks'
import {
  coursesPerUserAtom,
  currentlyEditingEmailsAtom,
  emptyUsersAtom,
  incompleteUsersAtom,
  invitationErrorsAtom,
  languagePerUserAtom,
  userAttributesConfigAtom,
} from 'sierra-client/views/manage/components/user-attributes/flows/invite-users/atoms'
import { verifyEmailMatches } from 'sierra-client/views/manage/components/user-attributes/flows/invite-users/normalize'
import {
  addOrReplaceAttributes as addOrReplaceAttributesUtil,
  addUsersToAttributesConfig,
  addUsersToCoursesPerUser,
  isEmptyInvitationErrors,
  updateCoursesPerUser as updateCoursesPerUserUtil,
} from 'sierra-client/views/manage/components/user-attributes/flows/invite-users/utils'
import { getAttribute as getAttributeUtil } from 'sierra-client/views/manage/components/user-attributes/utils'
import { Email } from 'sierra-domain/api/email'
import { EmailList } from 'sierra-domain/api/manage'
import {
  XRealtimeAdminEmailInvitesResentInvitesNew,
  XRealtimeAdminEmailInvitesValidateUserInvitationData,
} from 'sierra-domain/routes'
import { CoursesPerUser } from 'sierra-domain/user-attributes/courses-per-user'
import { UserAttributesConfig } from 'sierra-domain/user-attributes/user-attributes-config'
import { UserInvitationAttribute } from 'sierra-domain/user-attributes/user-invitation-attribute'
import { UserInvitationDomainRef } from 'sierra-domain/user-attributes/user-invitation-domain-ref'
import { UserValidationErrorsByEmail } from 'sierra-domain/user-attributes/user-invitation-validation-error'
import { isNullable } from 'sierra-domain/utils'
import { AvatarStackUserShape } from 'sierra-ui/components'

type UseInviteUsers = {
  hasChanged: boolean
  editingEmails: string[]
  incompleteUsers: AvatarStackUserShape[]
  emptyUsers: AvatarStackUserShape[]
  editingAttributes: boolean
  openAttributeEdit: (emails: string[]) => void
  closeAttributeEdit: () => void
  addOrReplaceAttributes: (newAttributes: UserInvitationAttribute[]) => void
  updateCoursesPerUser: (courses: string[]) => void
  getAttribute: (domainRef: UserInvitationDomainRef, email: string) => UserInvitationAttribute | undefined
  resetAttribute: (emails: Array<string>, domainRef: UserInvitationDomainRef) => void
  sendInvites: (_: { forceEmail: boolean }) => Promise<EmailList | undefined>
  validateInvites: (userAttributesConfig?: UserAttributesConfig) => Promise<boolean>
  addUsers: (
    emails: string[],
    accessLevelAttribute: UserInvitationAttribute,
    accessRoleAttribute: UserInvitationAttribute | undefined
  ) => void
  removeUser: (email: string) => void
  resetConfig: () => void
  hasAttributeErrors: (email: string, ref?: UserInvitationDomainRef) => boolean
  userAttributesConfig: UserAttributesConfig
  coursesPerUser: CoursesPerUser
  languagePerUser: Record<Email, AppLanguageCode>
  setLanguagePerUser: (languagePerUser: Record<Email, AppLanguageCode>) => void
}

/*
 * This hook is responsible for composing the user attribute config object and the user courses config object we send to the backend for validation and executing invitations
 * */
export const useComposeUserInvitationConfig = (): UseInviteUsers => {
  const [editingEmails, setEditingEmails] = useAtom(currentlyEditingEmailsAtom)
  const [userAttributesConfig, setUserAttributesConfig] = useAtom(userAttributesConfigAtom)
  const [coursesPerUser, setCoursesPerUser] = useAtom(coursesPerUserAtom)
  const [languagePerUser, setLanguagePerUser] = useAtom(languagePerUserAtom)
  const [invitationErrors, setInvitationErrors] = useAtom(invitationErrorsAtom)
  const incompleteUsers = useAtomValue(incompleteUsersAtom)
  const emptyUsers = useAtomValue(emptyUsersAtom)
  const dispatch = useDispatch()
  const isSendingAnEvent = useRef<boolean>(false)

  const { postWithUserErrorException } = usePost()

  const editingAttributes = useMemo(() => editingEmails.length > 0, [editingEmails])

  const hasChanged: UseInviteUsers['hasChanged'] = useMemo(
    () => !isEmpty(userAttributesConfig),
    [userAttributesConfig]
  )

  const openAttributeEdit: UseInviteUsers['openAttributeEdit'] = useCallback(
    emails => {
      setEditingEmails(emails)

      if (isSendingAnEvent.current) {
        return
      }

      setTimeout(async () => {
        isSendingAnEvent.current = true
        await dispatch(inviteUsersEditAttributesOpened({ emails }))
        isSendingAnEvent.current = false
      }, 500)
    },
    [dispatch, setEditingEmails]
  )

  const closeAttributeEdit: UseInviteUsers['closeAttributeEdit'] = useCallback(
    () => setEditingEmails([]),
    [setEditingEmails]
  )

  const getAttribute: UseInviteUsers['getAttribute'] = useCallback(
    (domainRef, email) => {
      const attribute = getAttributeUtil(domainRef, email, userAttributesConfig)
      return attribute
    },
    [userAttributesConfig]
  )

  const validateInvites: UseInviteUsers['validateInvites'] = useCallback(
    async optionalUserAttributesConfig => {
      const validation = await postWithUserErrorException(
        XRealtimeAdminEmailInvitesValidateUserInvitationData,
        {
          invitationDataPerUser: optionalUserAttributesConfig ?? userAttributesConfig,
        }
      )

      /* User errors */
      const errors: UserValidationErrorsByEmail = validation.errors === undefined ? {} : validation.errors
      setInvitationErrors(validation.success ? {} : errors)

      verifyEmailMatches(userAttributesConfig, coursesPerUser)

      return validation.success
    },
    [coursesPerUser, postWithUserErrorException, setInvitationErrors, userAttributesConfig]
  )

  const hasAttributeErrors: UseInviteUsers['hasAttributeErrors'] = useCallback(
    (email, ref) => {
      const errorsForUser = invitationErrors[email]

      if (ref === undefined) {
        return errorsForUser !== undefined && errorsForUser.length > 0
      }

      const errorsForRef = errorsForUser?.filter(e => isEqual(e.attribute, ref))
      return errorsForRef !== undefined && errorsForRef.length > 0
    },
    [invitationErrors]
  )

  const addOrReplaceAttributes: UseInviteUsers['addOrReplaceAttributes'] = useCallback(
    (newAttributes): void => {
      if (editingEmails.length === 0) {
        throw new Error(`Editing emails are empty while adding attributes ${JSON.stringify(newAttributes)}.`)
      }

      const updatedConfig = addOrReplaceAttributesUtil(userAttributesConfig, editingEmails, newAttributes)
      setUserAttributesConfig(updatedConfig)

      // Keep checking for errors on every change until none are left
      if (!isEmptyInvitationErrors(invitationErrors)) {
        void validateInvites(updatedConfig)
      }
    },
    [editingEmails, invitationErrors, setUserAttributesConfig, userAttributesConfig, validateInvites]
  )

  const resetAttribute: UseInviteUsers['resetAttribute'] = useCallback(
    (emails, rep) => {
      const change = (config: UserAttributesConfig, email: string): UserAttributesConfig =>
        produce(config, draft => {
          const userAttributes = draft[email]
          if (isNullable(userAttributes)) {
            return
          }

          const updatedAttributes = userAttributes.data.filter(attr => !isEqual(attr.ref, rep))
          draft[email] = { data: updatedAttributes }
        })

      const updatedConfig = emails.reduce((acc, email) => change(acc, email), userAttributesConfig)

      setUserAttributesConfig(updatedConfig)

      // This will remove the user errors for this particular user if there are any
      if (!isEmptyInvitationErrors(invitationErrors)) {
        void validateInvites(updatedConfig)
      }
    },
    [invitationErrors, setUserAttributesConfig, userAttributesConfig, validateInvites]
  )
  const updateCoursesPerUser: UseInviteUsers['updateCoursesPerUser'] = useCallback(
    (courses): void => {
      if (editingEmails.length === 0) {
        throw new Error(`Editing emails are empty while adding courses ${courses}.`)
      }

      const updatedCoursesPerUser = updateCoursesPerUserUtil(coursesPerUser, editingEmails, courses)
      setCoursesPerUser(updatedCoursesPerUser)
    },
    [editingEmails, setCoursesPerUser, coursesPerUser]
  )

  const addUsers: UseInviteUsers['addUsers'] = useCallback(
    (emails, accessLevelAttribute, accessRoleAttribute) => {
      const updatedUserAttributesConfig = addUsersToAttributesConfig(
        userAttributesConfig,
        emails,
        accessLevelAttribute,
        accessRoleAttribute
      )
      const updatedCoursesPerUser = addUsersToCoursesPerUser(coursesPerUser, emails)

      setUserAttributesConfig(updatedUserAttributesConfig)
      setCoursesPerUser(updatedCoursesPerUser)
    },
    [coursesPerUser, setCoursesPerUser, setUserAttributesConfig, userAttributesConfig]
  )

  const removeUser: UseInviteUsers['removeUser'] = useCallback(
    email => {
      const updatedUserAttributesConfig = produce(userAttributesConfig, draft => {
        delete draft[email]
      })
      const updatedCoursesPerUser = produce(coursesPerUser, draft => {
        delete draft[email]
      })
      setUserAttributesConfig(updatedUserAttributesConfig)
      setCoursesPerUser(updatedCoursesPerUser)

      // This will remove the user errors for this particular user if there are any
      if (!isEmptyInvitationErrors(invitationErrors)) {
        void validateInvites(updatedUserAttributesConfig)
      }
    },
    [
      userAttributesConfig,
      coursesPerUser,
      setUserAttributesConfig,
      setCoursesPerUser,
      invitationErrors,
      validateInvites,
    ]
  )

  const sendInvites: UseInviteUsers['sendInvites'] = useCallback(
    async ({ forceEmail }) => {
      const { alreadyUsedEmails } = await postWithUserErrorException(
        XRealtimeAdminEmailInvitesResentInvitesNew,
        {
          attributesPerUser: userAttributesConfig,
          coursesPerUser,
          languagePerUser,
          forceEmail: forceEmail,
        }
      )

      return alreadyUsedEmails
    },
    [postWithUserErrorException, userAttributesConfig, coursesPerUser, languagePerUser]
  )

  const resetConfig = useCallback(() => {
    setEditingEmails([])
    setUserAttributesConfig({})
    setCoursesPerUser({})
  }, [setEditingEmails, setUserAttributesConfig, setCoursesPerUser])

  return {
    hasChanged,
    editingEmails,
    incompleteUsers,
    emptyUsers,
    editingAttributes,
    openAttributeEdit,
    closeAttributeEdit,
    hasAttributeErrors,
    sendInvites,
    validateInvites,
    addUsers,
    getAttribute,
    addOrReplaceAttributes,
    resetAttribute,
    updateCoursesPerUser,
    removeUser,
    resetConfig,
    userAttributesConfig,
    coursesPerUser,
    languagePerUser,
    setLanguagePerUser,
  }
}
