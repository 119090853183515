import { useAtom, useAtomValue } from 'jotai'
import _ from 'lodash'
import React, { ReactNode, useEffect, useRef, useState } from 'react'
import {
  CardIcon,
  CardIconStatus,
  FolderIcon,
  NestedItemList,
  collapsableSidebarStateAtom,
} from 'sierra-client/features/collapsable-sidebar'
import { useIsDebugMode } from 'sierra-client/hooks/use-is-debug-mode'
import { useScrollToView } from 'sierra-client/hooks/use-scroll-to-view'
import { useTranslation } from 'sierra-client/hooks/use-translation'
import { selectCourseCardStatus, selectCourseModuleStatus } from 'sierra-client/state/card-progress/selectors'
import { useGetFileTitle } from 'sierra-client/state/flexible-content/file-title'
import { useSelector } from 'sierra-client/state/hooks'
import { AnimatedOutlineCSS } from 'sierra-client/views/flexible-content/editor/content-sidebar/browse-folder'
import {
  DistanceBetweenSidebarFiles,
  FileBackgroundCss,
  FileBaseProps,
  FileContainerBaseStylings,
  FolderTitleRowLearner,
} from 'sierra-client/views/flexible-content/editor/content-sidebar/file-base-stylings-css'
import { reviewSuggestionsAtom } from 'sierra-client/views/self-paced/sidebar-atom'
import { CreateContentId } from 'sierra-domain/api/nano-id'
import { CardStatus } from 'sierra-domain/api/strategy-v2'
import { SlateDocumentMap } from 'sierra-domain/collaboration/serialization'
import { FileId } from 'sierra-domain/flexible-content/identifiers'
import { File, Folder } from 'sierra-domain/flexible-content/types'
import { isDefined } from 'sierra-domain/utils'
import { Icon, Tooltip, TruncatedText } from 'sierra-ui/components'
import { IconButton, Text, View } from 'sierra-ui/primitives'
import { token } from 'sierra-ui/theming'
import styled, { css } from 'styled-components'

const Absolute = styled.div`
  position: absolute;
  top: 0;
  right: 0;
  opacity: 0;
`

export const FileContainer = styled(View).attrs({
  justifyContent: 'space-between',
  margin: 'none',
})<{ disabled?: boolean } & FileBaseProps>`
  position: relative;
  ${FileContainerBaseStylings};
  opacity: ${p => (p.disabled === true ? '0.2' : '1')};
  cursor: ${p => (p.disabled === true ? 'default' : 'pointer')};
  margin: 0 0 ${DistanceBetweenSidebarFiles} 0;
  transition: border-color 50ms cubic-bezier(0.25, 0.1, 0.25, 1);

  ${p =>
    p.$isCurrentItem === true &&
    css`
      &:hover {
        background-color: ${token('org/primary').opacity(0.08)};
      }
    `}

  ${FileBackgroundCss}
`

const LiveFolderTitleRow = styled(FolderTitleRowLearner)`
  position: relative;

  &:hover {
    ${Absolute} {
      opacity: 1;
    }
  }
`

const FolderContainer = styled(View)<{ $hasBorder: boolean }>`
  ${AnimatedOutlineCSS};
`

const DebugText = styled(Text)`
  position: absolute;
  top: 0;
  right: 4px;
  opacity: 0.5;
`

const iconStatusFromCardStatus = (cardStatus?: CardStatus): CardIconStatus | undefined => {
  if (cardStatus === undefined) {
    return undefined
  }
  if (cardStatus.moreStatus.some(({ status }) => status === 'failed')) {
    return 'failed'
  }

  if (cardStatus.moreStatus.some(({ status }) => status === 'pending')) {
    return 'pending'
  }

  return isDefined(cardStatus.completed) ? 'completed' : undefined
}

const _RenderFile = ({
  node,
  flexibleContentId,
  onClick,
  highlight = false,
  disabled = false,
  slateDocumentMap,
  isSelfPaced,
}: {
  node: File
  flexibleContentId: CreateContentId
  onClick: (fileId: FileId) => void
  highlight?: boolean
  disabled?: boolean
  slateDocumentMap: SlateDocumentMap
  isSelfPaced: boolean
}): JSX.Element => {
  const isDebug = useIsDebugMode()

  const handleOnClick = (): void => {
    onClick(node.id)
  }
  const cardStatus = useSelector(
    state => selectCourseCardStatus(state, flexibleContentId, node.id),
    _.isEqual
  )

  const iconStatus = iconStatusFromCardStatus(cardStatus)

  const { t } = useTranslation()
  const getFileTitle = useGetFileTitle(slateDocumentMap)
  const cardTitle = getFileTitle(node)

  const ref = useRef<HTMLDivElement | null>(null)
  useScrollToView(ref, { shouldScroll: highlight })

  const [reviewSuggestions, setReviewSuggestions] = useAtom(reviewSuggestionsAtom)

  // Remove this file from the review suggestion if the user visits it
  useEffect(() => {
    if (highlight && reviewSuggestions[node.id] === true) {
      setReviewSuggestions(previous => ({ ...previous, [node.id]: false }))
    }
  }, [highlight, node.id, reviewSuggestions, setReviewSuggestions])

  return (
    <FileContainer
      ref={ref}
      $isCurrentItem={highlight}
      onClick={disabled ? undefined : handleOnClick}
      $isHighlighted={reviewSuggestions[node.id] === true}
      disabled={disabled}
      gap='10'
    >
      <CardIcon status={iconStatus} isCurrentItem={highlight} showPlayIcon={isSelfPaced && highlight} />

      <View grow overflow='hidden' alignItems='center'>
        <TruncatedText
          bold
          color={
            highlight
              ? 'foreground/primary'
              : iconStatus === 'completed'
                ? 'foreground/muted'
                : 'foreground/secondary'
          }
          lines={1}
          size='small'
        >
          {cardTitle}
        </TruncatedText>
        {reviewSuggestions[node.id] === true && (
          <Tooltip title={t('assessment.review-recommendations.review-suggestion')}>
            <View paddingRight='4'>
              <Icon color='foreground/muted' iconId='idea' />
            </View>
          </Tooltip>
        )}
      </View>
      {isDebug && (
        <DebugText size='technical' color='foreground/muted'>
          {node.id}
        </DebugText>
      )}
    </FileContainer>
  )
}

export const RenderFile = React.memo(_RenderFile)

const Title = styled(Text)`
  flex: 1;
  width: 100%;
  white-space: nowrap;
  text-overflow: ellipsis;
  overflow: hidden;
  flex-shrink: 0;
`

export const FolderTitle: React.FC<{
  title: string
  folderId: string
  open: boolean
  setOpen: React.Dispatch<React.SetStateAction<boolean>>
  isCompleted: boolean
  numberOfContent: number
  isCurrentItem: boolean
}> = ({ title, folderId, open, setOpen, isCompleted, numberOfContent, isCurrentItem }) => {
  const isDebug = useIsDebugMode()

  return (
    <LiveFolderTitleRow onClick={() => setOpen(isOpen => !isOpen)}>
      <FolderIcon numberOfContent={numberOfContent} isCompleted={isCompleted} isCurrentItem={isCurrentItem} />
      <Tooltip title={title}>
        <Title bold size='small' color={isCurrentItem ? 'foreground/primary' : 'foreground/secondary'}>
          {title}
        </Title>
      </Tooltip>

      <IconButton
        size='small'
        iconId={open ? 'chevron--up--small' : 'chevron--down--small'}
        variant='transparent'
      />
      {isDebug && (
        <Absolute>
          <Text size='technical' color='foreground/muted'>
            {folderId}
          </Text>
        </Absolute>
      )}
    </LiveFolderTitleRow>
  )
}

export const RenderFolder = ({
  folder,
  children,
  currentCard,
  flexibleContentId,
}: {
  folder: Folder
  children?: ReactNode | undefined
  currentCard: File | undefined
  flexibleContentId: CreateContentId
}): JSX.Element => {
  const [open, setOpen] = useState(true)
  const moduleStatus = useSelector(
    state => selectCourseModuleStatus(state, flexibleContentId, folder.id),
    _.isEqual
  )
  const isFolderCompleted = moduleStatus?.completed !== undefined
  const isCurrentCardInFolder = isDefined(currentCard) && folder.nodeIds.includes(currentCard.id)
  const sidebarState = useAtomValue(collapsableSidebarStateAtom)

  useEffect(() => {
    if (sidebarState === 'collapsed') {
      setOpen(isCurrentCardInFolder)
    }
  }, [sidebarState, isCurrentCardInFolder, setOpen])

  return (
    <FolderContainer
      $hasBorder={isCurrentCardInFolder && sidebarState === 'collapsed'}
      direction='column'
      gap='none'
    >
      <FolderTitle
        isCurrentItem={isCurrentCardInFolder}
        title={folder.title}
        folderId={folder.id}
        open={open}
        setOpen={setOpen}
        isCompleted={isFolderCompleted}
        numberOfContent={folder.nodeIds.length}
      />
      <NestedItemList open={open}>{children}</NestedItemList>
    </FolderContainer>
  )
}
