import { nanoid12 } from 'sierra-domain/nanoid-extensions'
import { Descendant, Editor, Element, Operation, Path, Transforms } from 'slate'

// Pretty arbitrarily picked primes and bases
const HashBase = 1
const HashPrime = 1610612611

const pathHash = (path: Path): number => {
  let hashState = HashBase
  for (const idx of path) {
    hashState = Math.imul(hashState, HashPrime) ^ idx
  }
  return hashState
}

/** Returns a mapping from IDs to a hash of the corrsponding element's path, as well as if there are any duplicate IDs in the document.
 *
 * The hashes are identical to the output from `pathHash`.
 */
const pathsByElementId = (editor: Editor): { paths: Map<string, number>; duplicates: boolean } => {
  const paths = new Map<string, number>()
  let duplicates = false
  let hashState = HashBase

  const addIds = (children: Descendant[]): void => {
    const h = hashState
    for (let i = 0; i < children.length; i++) {
      hashState = Math.imul(h, HashPrime) ^ i

      const node = children[i]
      if (Element.isElement(node)) {
        if (!paths.has(node.id)) {
          paths.set(node.id, hashState)
        } else {
          duplicates = true
        }

        addIds(node.children)
      }
    }
    hashState = h
  }

  addIds(editor.children)

  return {
    paths,
    duplicates,
  }
}

/**
 * Assign unique IDs to each block in the editor
 */
export const withIds = (readIds: () => Set<string>): ((editor: Editor) => Editor) => {
  return editor => {
    const { normalizeNode } = editor

    let ducumentHasDuplicates = false
    let elementPaths: Map<string, number> = new Map()
    let lastOperations: Operation[] = []
    let lastOperationsLength = 0

    function uniqueId(id: string | undefined, path: Path): string {
      if (id === undefined) return nanoid12()
      const expectedPath = elementPaths.get(id)
      const hash = pathHash(path)
      // Check if other nodes in this document have the current id
      if (hash === expectedPath) return id
      id = nanoid12()
      elementPaths.set(id, hash)
      return id
    }

    editor.normalizeNode = entry => {
      // Slate will do operations in batches. It will add some operations, and then call normalizeNode
      // on all dirty nodes. The element paths don't change while we do this (unless some normalization operation changed the document)
      // so usually we can avoid rebuilding the lookup table.
      // This is particularly important if a user copy and pastes large chunks.
      if (editor.operations !== lastOperations || editor.operations.length !== lastOperationsLength) {
        lastOperations = editor.operations
        lastOperationsLength = lastOperations.length

        const { paths, duplicates } = pathsByElementId(editor)
        elementPaths = paths
        ducumentHasDuplicates = duplicates
        // Reserve ids used elsewhere in the course
        for (const reserved in readIds()) {
          if (elementPaths.has(reserved)) ducumentHasDuplicates = true
          elementPaths.set(reserved, pathHash([-1]))
        }
      }

      if (!ducumentHasDuplicates) return normalizeNode(entry)

      const [node, path] = entry
      if (Element.isElement(node)) {
        const newId = uniqueId(node.id, path)
        if (node.id !== newId) {
          Transforms.setNodes(editor, { id: newId }, { at: path })

          // Calling setNodes will add an additional operation on the stack. But this is fine, we have already accounted for
          // the new id in the `uniqueId` function. So we don't want to invalidate our lookup table.
          // This is important if a user copy and pastes a large chunk from the same document, as in that case many ids will be duplicated.
          lastOperations = editor.operations
          lastOperationsLength = lastOperations.length
          return
        }
      }

      normalizeNode(entry)
    }

    return editor
  }
}
