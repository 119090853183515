import React from 'react'
import { Icon } from 'sierra-ui/components'
import { token } from 'sierra-ui/theming'
import { fonts } from 'sierra-ui/theming/fonts'
import styled from 'styled-components'

const FilterInputContainer = styled.div<{ maxWidth?: string }>`
  position: relative;
  width: 100%;
  max-width: ${p => p.maxWidth ?? '40rem'};
  color: ${token('foreground/primary')};
  background-color: ${token('surface/default')};
`

const FilterInput = styled.input`
  ${fonts.body.small}
  width: 100%;
  height: 2.5rem;
  padding: 0.625rem 1rem 0.625rem 2.25rem;
  border-radius: 10px;
  border: 1px solid;
  border-color: ${token('border/default')};
  color: ${token('foreground/primary')};
  transition: border-color 200ms ease;
  background-color: transparent;

  &:hover {
    border-color: ${token('border/strong')};
  }

  &:focus {
    border-color: ${p => p.theme.color.blueBright};
  }

  &::placeholder {
    color: ${token('foreground/primary')};
    opacity: 0.5;
  }
`

const SearchIcon = styled(Icon).attrs({
  iconId: 'search',
})`
  position: absolute;
  top: 0.75rem;
  left: 0.75rem;
  color: ${token('foreground/primary')};
`

export type RoundedSearchBarProps = {
  value?: string
  onChange: (value: string) => void
  placeholder: string
  initialOpen?: boolean
  maxWidth?: string
  className?: string
  autoFocus?: boolean
}

export const RoundedSearchBar: React.FC<RoundedSearchBarProps> = ({
  value,
  onChange,
  placeholder,
  maxWidth,
  className,
  autoFocus = false,
}) => {
  return (
    <FilterInputContainer className={className} maxWidth={maxWidth}>
      <SearchIcon />
      <FilterInput
        type='text'
        maxLength={50}
        autoComplete='none'
        autoCorrect='none'
        autoCapitalize='none'
        spellCheck='false'
        value={value ?? ''}
        onChange={e => {
          onChange(e.target.value)
        }}
        placeholder={placeholder}
        autoFocus={autoFocus}
      />
    </FilterInputContainer>
  )
}
