const MILLIS_PER_SECOND = 1000

class ExponentialMovingAverage {
  private lastEventTime: Date
  private numerator: number
  private denominator: number
  private readonly exponentCoefficient: number

  constructor(halfLifeInSeconds: number, startTime: Date) {
    this.lastEventTime = startTime
    this.numerator = 0
    this.denominator = 0
    this.exponentCoefficient = Math.log(2.0) / halfLifeInSeconds
  }

  private updateTime(time: Date): void {
    const timeDeltaInMillis = time.getTime() - this.lastEventTime.getTime()
    if (timeDeltaInMillis < 0) {
      throw new Error('Time went backwards')
    }
    const factor = Math.exp(-(timeDeltaInMillis / MILLIS_PER_SECOND) * this.exponentCoefficient)
    this.numerator *= factor
    this.denominator *= factor
    this.denominator += (1 - factor) / this.exponentCoefficient
    this.lastEventTime = time
  }

  /**
   * @param time The current time.
   * @returns the average number of times per second that `addEvent` has been called, weighted by an exponential factor.
   */
  getAverage(time: Date): number {
    this.updateTime(time)
    if (this.denominator === 0) {
      return 0
    }
    return this.numerator / this.denominator
  }

  addEvent(time: Date): void {
    this.updateTime(time)
    this.numerator += 1
  }
}

export class DynamicThrottler {
  private lastInvocationTime: Date
  private averageRemoteEventsPerSecond: ExponentialMovingAverage
  private awaitingInvocation: boolean

  constructor(
    private readonly desiredEventsPerSecond: number,
    private readonly minAllowedLocalEventsPerSecond = 0.1,
    private readonly maxAllowedLocalEventsPerSecond = 10
  ) {
    const currentTime = new Date()
    this.lastInvocationTime = currentTime
    this.averageRemoteEventsPerSecond = new ExponentialMovingAverage(10.0, currentTime)
    this.awaitingInvocation = false
    if (desiredEventsPerSecond <= 0) {
      throw new Error('Expected the desired event rate to be strictly positive')
    }
    if (minAllowedLocalEventsPerSecond <= 0) {
      throw new Error('Expected the minimum allowed rate of local events to be strictly positive')
    }
    if (maxAllowedLocalEventsPerSecond <= minAllowedLocalEventsPerSecond) {
      throw new Error(
        'Expected the maximum allowed rate of local events to be strictly greater than the minimum allowed rate'
      )
    }
  }

  handleRemoteEvent(): void {
    this.handleRemoteEventAtTime(new Date())
  }

  handleRemoteEventAtTime(time: Date): void {
    this.averageRemoteEventsPerSecond.addEvent(time)
  }

  desiredLocalPeriodMillis(): number {
    const currentTime = new Date()
    const currentRemoteEventsPerSecond = this.averageRemoteEventsPerSecond.getAverage(currentTime)
    // The following formula has these properties:
    // - It tends to minAllowedLocalEventsPerSecond as currentRemoteEventsPerSecond -> ∞
    // - It tends to maxAllowedLocalEventsPerSecond as currentRemoteEventsPerSecond -> 0
    // However, there are no guarantees that the remote event rate will converge to desiredEventsPerSecond.
    // To achieve that, we might want to also take the current local event rate into account.
    const desiredLocalEventsPerSecond =
      this.minAllowedLocalEventsPerSecond +
      (this.maxAllowedLocalEventsPerSecond - this.minAllowedLocalEventsPerSecond) *
        (1.0 / (1.0 + currentRemoteEventsPerSecond / this.desiredEventsPerSecond))
    return MILLIS_PER_SECOND / desiredLocalEventsPerSecond
  }

  throttle(func: () => void): () => NodeJS.Timeout | undefined {
    return () => {
      if (this.awaitingInvocation) {
        return undefined
      }
      this.awaitingInvocation = true
      const currentTime = new Date()
      const timeSinceLastInvocation = currentTime.getTime() - this.lastInvocationTime.getTime()
      const desiredLocalPeriod = this.desiredLocalPeriodMillis()
      const timeout = desiredLocalPeriod - timeSinceLastInvocation
      const f = (): void => {
        this.awaitingInvocation = false
        this.lastInvocationTime = new Date()
        func()
      }
      if (timeout <= 0) {
        f()
        return undefined
      } else {
        return setTimeout(f, timeout)
      }
    }
  }
}
