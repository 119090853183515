import { TargetAndTransition, motion } from 'framer-motion'
import { hoverTransition } from 'sierra-client/views/course-helper/config'
import { HelperSize, HelperVisibility } from 'sierra-client/views/course-helper/types'
import { isDefined } from 'sierra-domain/utils'
import { ColorBuilder } from 'sierra-ui/color'
import { token } from 'sierra-ui/theming'
import styled, { css } from 'styled-components'

export const containerStyles = css`
  border-radius: 16px;
  background-color: ${token('surface/default')};
  box-shadow:
    0px 0px 3px 1px #0000000a,
    0px 8px 16px 0px #00000014;
`

export const opacityHoverBase = css`
  opacity: 0.85;
`

export const seeThroughStyles = css<{ $seeThrough: undefined | { backgroundColor: ColorBuilder } }>`
  ${p =>
    p.$seeThrough !== undefined &&
    css`
      backdrop-filter: blur(34px);
      box-shadow: none;
      ${p.$seeThrough.backgroundColor.brightness() > 0.9
        ? css`
            background: rgba(238, 238, 238, 0.6);
          `
        : css`
            background: linear-gradient(0deg, rgba(90, 90, 90, 0.4) 0%, rgba(90, 90, 90, 0.4) 100%),
              rgba(90, 90, 90, 0.05);
          `};
    `}
`

/* Desktop */
type DesktopBubbleVariantCustom = {
  size: HelperSize
  duration: number
  borderRadius: number
  opacity?: number
}

const desktopBubbleVariants: Record<
  HelperVisibility,
  (custom: DesktopBubbleVariantCustom) => TargetAndTransition
> = {
  hidden: (custom: DesktopBubbleVariantCustom) => ({
    opacity: 0,
    width: 0,
    height: 0,
    borderRadius: custom.borderRadius,
    transition: { duration: custom.duration },
  }),
  visible: (custom: DesktopBubbleVariantCustom) => ({
    opacity: custom.opacity ?? 1,
    width: custom.size.width,
    height: custom.size.height,
    borderRadius: custom.borderRadius,
    transition: { duration: custom.duration },
  }),
}

export const DesktopBubble = styled(motion.div).attrs({ variants: desktopBubbleVariants })<{
  $seeThrough: undefined | { backgroundColor: ColorBuilder }
}>`
  position: absolute;
  bottom: 0;
  right: 0;
  z-index: -1; /* The order is important for the hover to work */

  ${containerStyles};
  ${seeThroughStyles};
`

export const opacityHover = css`
  opacity: 1 !important;

  box-shadow:
    0px 0px 3px 1px #0000000a,
    0px 16px 20px 0px #00000014;
`

export const DesktopContentWrapper = styled(motion.div)<{
  $seeThrough: undefined | { backgroundColor: ColorBuilder }
  $disableHover: boolean
}>`
  overflow: hidden;
  position: relative;
  z-index: 1;

  ${p =>
    !p.$disableHover &&
    css`
      ${opacityHoverBase};
      ${hoverTransition};
    `}
  & + ${DesktopBubble} {
    ${p => !p.$disableHover && hoverTransition}
  }

  &:hover {
    ${p =>
      !p.$disableHover &&
      css`
        opacity: 1;
      `}
  }

  &:hover + ${DesktopBubble} {
    ${p => {
      if (p.$disableHover) {
        return
      }

      /* See through */
      if (isDefined(p.$seeThrough)) {
        return p.$seeThrough.backgroundColor.brightness() > 0.9
          ? css`
              background: rgba(238, 238, 238, 0.8);
            `
          : css`
              background: linear-gradient(0deg, rgba(90, 90, 90, 0.4) 0%, rgba(90, 90, 90, 0.4) 100%),
                rgba(90, 90, 90, 0.15);
            `
      }

      /* Normal */
      return opacityHover
    }}
  }
`
