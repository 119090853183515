import { graphql } from 'sierra-client/api/graphql/gql'
import { useGraphQuery } from 'sierra-client/api/hooks/use-graphql-query'
import { SkillColor } from 'sierra-client/features/skills'
import { SkillIconId } from 'sierra-ui/components'

const myAchievedBadgesQuery = graphql(`
  query MyAchievedBadges($courseId: CourseId) {
    me {
      badges(issuedViaCourseId: $courseId) {
        badgeIcon
        badgeTheme
        skillName
        skillLevelName
        issuanceDate
      }
    }
  }
`)

export type Badge = {
  type: 'badge'
  badgeIcon: SkillIconId
  badgeTheme: SkillColor
  skillName: string
  skillLevelName: string
}

export const useMyAchievedBadges = ({
  courseId,
  enabled,
}: {
  courseId: string
  enabled: boolean
}): { badges: Badge[]; isLoading: boolean } => {
  const graphQuery = useGraphQuery(
    { document: myAchievedBadgesQuery, queryOptions: { enabled } },
    { courseId }
  )

  const emptyBadges: Badge[] = []

  if (!enabled) {
    return { badges: emptyBadges, isLoading: false }
  }

  const myAchievedBadges = graphQuery.data?.me.badges ?? []

  const sortedBadges = myAchievedBadges.sort((b1, b2) => (b2.issuanceDate > b1.issuanceDate ? 1 : -1))

  const badges = sortedBadges.map(badge => {
    return {
      type: 'badge',
      badgeIcon: SkillIconId.catch('skill--graduation--cap').parse(badge.badgeIcon),
      badgeTheme: SkillColor.catch('bronze').parse(badge.badgeTheme),
      skillName: badge.skillName,
      skillLevelName: badge.skillLevelName,
    } as const satisfies Badge
  })

  return { badges: badges, isLoading: graphQuery.isLoading }
}
