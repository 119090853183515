import { useSetAtom } from 'jotai'
import { useTranslation } from 'react-i18next'
import { OpenSelectSkillModalAtom } from 'sierra-client/features/skills/components/learner-board/atoms'
import { useTracking } from 'sierra-client/features/skills/tracking'
import { isNonEmptyString } from 'sierra-domain/utils'
import { Button, Text, View } from 'sierra-ui/primitives'
import { token } from 'sierra-ui/theming'
import { v2_breakpoint } from 'sierra-ui/theming/breakpoints'
import { FCC } from 'sierra-ui/types'
import styled from 'styled-components'

const BoardContainer = styled(View).attrs({ gap: '8' })<{
  disableBorder?: boolean
}>`
  display: flex;
  flex-direction: column;
  position: relative;
  padding: ${p => (p.disableBorder === true ? 'none' : '8px')};
  width: 100%;
  height: 400px;
  border-radius: ${p => (p.disableBorder === true ? 'inherit' : '20px')};
  border: 1px solid transparent;
  border-color: ${p => (p.disableBorder === true ? 'transparent' : token('border/default')(p))};
  box-shadow: ${p => (p.disableBorder === true ? 'none' : '0px 2px 4px 0px #00000014')};
  align-items: stretch;

  header {
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: 8px;
  }

  @media screen and (min-width: ${v2_breakpoint.tablet}) {
    padding: 16px;

    header {
      padding: 0;
    }
  }
`

export const Board: FCC<{
  title?: string
  disableBorder?: boolean
  disableManageButton?: boolean
}> = ({ title, children, disableBorder = false, disableManageButton = false }) => {
  const { t } = useTranslation()
  const tracking = useTracking()
  const setSelectSkillOpen = useSetAtom(OpenSelectSkillModalAtom)

  return (
    <BoardContainer disableBorder={disableBorder}>
      <header>
        {isNonEmptyString(title) && (
          <Text size='large' bold>
            {title}
          </Text>
        )}
        {!disableManageButton && (
          <Button
            variant='transparent'
            onClick={() => {
              tracking.learner.clickSkillSubscriptionMenu()
              setSelectSkillOpen(true)
            }}
          >
            {t('skills.board.buttons.manage-skills.text')}
          </Button>
        )}
      </header>
      {children}
    </BoardContainer>
  )
}
