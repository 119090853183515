import _ from 'lodash'
import { isElementType, nodeAtPath } from 'sierra-client/views/v3-author/queries'
import { Editor, Element, Node, NodeEntry, Path, Text, Transforms } from 'slate'

export function isValidParagraphChild(node: Node): boolean {
  return Text.isText(node) || isElementType(['link', 'tag'], node)
}

function isInvalidParagraphChild(node: Node): node is Element {
  return !isValidParagraphChild(node)
}

function unwrapInvalidChildren(editor: Editor, { at: path }: { at: Path }): void {
  Transforms.unwrapNodes(editor, {
    at: path,
    voids: true,
    mode: 'all',
    match: (n, p) => {
      const matchesPath = _.isEqual(path, _.take(p, path.length))
      if (!matchesPath) return false

      return isInvalidParagraphChild(n) && matchesPath
    },
  })
}

export function unwrapNonParagraphChildrenAtPath(editor: Editor, path: Path): { didUnwrap: boolean } {
  if (Text.isText(nodeAtPath(editor.children, path))) return { didUnwrap: false }

  for (const [child, childPath] of Node.children(editor, path)) {
    if (isInvalidParagraphChild(child)) {
      unwrapInvalidChildren(editor, { at: childPath })
      return { didUnwrap: true }
    }
  }

  return { didUnwrap: false }
}

export function unwrapNonParagraphChildren(editor: Editor, entry: NodeEntry): { didUnwrap: boolean } {
  const [, path] = entry

  return unwrapNonParagraphChildrenAtPath(editor, path)
}
