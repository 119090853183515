import { Editor } from '@tiptap/react'
import { AnimatePresence } from 'framer-motion'
import React from 'react'
import { useTranslation } from 'sierra-client/hooks/use-translation'
import { FakeStreamingText } from 'sierra-client/lib/assistant-input/search-insights/fake-streaming-text'
import { SanaGradientIcon } from 'sierra-client/lib/assistant-input/search-insights/sana-gradient-icon'
import { useSuggestedQuestion } from 'sierra-client/lib/assistant-input/search-insights/use-suggested-question'
import { InsightsContentSearch } from 'sierra-client/views/insights/insights-content-search'
import { serializeInsightContentSuggestions } from 'sierra-client/views/insights/insights-content-search/utils'
import { TokenOrColor } from 'sierra-ui/color/token-or-color'
import { Icon, IconId } from 'sierra-ui/components'
import { IconButton, View } from 'sierra-ui/primitives'
import { token } from 'sierra-ui/theming'
import styled from 'styled-components'

const InputWrapper = styled(View)``
const InputContainer = styled(View)`
  &::-webkit-scrollbar {
    display: none;
  }
`

const StyledInsightsContentSearch = styled(InsightsContentSearch)<{ color: TokenOrColor }>`
  flex: 1;
  background-color: transparent;
  font-size: 14px;
  padding-left: 0;

  transition: all 120ms ease-in-out;
  font-weight: 500;

  & .ProseMirror {
    white-space: nowrap;
  }

  & .ProseMirror .tag-type-content,
  .ProseMirror .tag-type-user {
    line-height: 2;
    padding: 0 6px;
    font-size: 14px;
    align-items: baseline;

    & > div,
    > img {
      position: relative;
      top: 4px;
    }
  }

  & .ProseMirror > div {
    position: relative;
    line-height: 2.4;

    & > span {
      position: relative;
    }
  }
`

const StyledIcon = styled(Icon)`
  color: ${token('foreground/muted')};
  cursor: pointer;
  padding: 10px;

  &:hover {
    color: ${token('foreground/primary')};
  }
`

const StyledPlaceholderWrapper = styled(View)`
  position: absolute;
  left: 0;
  color: ${token('foreground/muted')};
  white-space: nowrap;
`

const NoWrap = styled(View)`
  white-space: nowrap;
`

export const InsightsAssistantInput: React.FC<{
  editor: Editor | null
  askInsightsQuestion: (value: string) => void
  actionIcon?: IconId
  placeholderText?: string
}> = ({ editor, askInsightsQuestion, actionIcon = 'next--filled', placeholderText = undefined }) => {
  const { t } = useTranslation()
  const suggestedQuestion = useSuggestedQuestion(editor, t)
  const renderActionIcon = (): JSX.Element | null => {
    const editorValue = serializeInsightContentSuggestions(editor)
    const showAskButton = editorValue !== ''
    if (showAskButton) {
      return (
        <NoWrap>
          <IconButton
            iconId={actionIcon}
            variant='transparent'
            onClick={() => {
              askInsightsQuestion(editorValue)
            }}
          />
        </NoWrap>
      )
    }
    return (
      <NoWrap>
        <StyledIcon iconId={actionIcon} size='size-16' color='foreground/muted' />
      </NoWrap>
    )
  }
  return (
    <InputWrapper grow gap='8' paddingLeft={'12'}>
      <View>
        <SanaGradientIcon />
      </View>
      <InputContainer position={'relative'} overflow='auto' grow>
        <StyledPlaceholderWrapper>
          <AnimatePresence>
            {serializeInsightContentSuggestions(editor) === '' && (
              <FakeStreamingText
                text={placeholderText === undefined ? suggestedQuestion : placeholderText}
                color={'foreground/muted'}
                delay={0.3}
                size='small'
              />
            )}
          </AnimatePresence>
        </StyledPlaceholderWrapper>
        <StyledInsightsContentSearch editor={editor} color={'foreground/muted'} />
      </InputContainer>
      {renderActionIcon()}
    </InputWrapper>
  )
}
