import { createFileRoute } from '@tanstack/react-router'
import { requireLoggedIn } from 'sierra-client/core/require-logged-in'
import { PathPage } from 'sierra-client/views/learner/path/path-page'
import { PathId } from 'sierra-domain/api/nano-id'
import { ProgramId } from 'sierra-domain/api/uuid'
import { z } from 'zod'

const RouteComponent = (): JSX.Element => {
  const pathId = Route.useParams({ select: select => select.pathId })
  return <PathPage pathId={pathId} />
}

export const Route = createFileRoute('/program/$programId/path/$pathId/')({
  component: requireLoggedIn(RouteComponent, { allowScorm: true }) as React.FC,
  params: {
    parse: z.object({ programId: ProgramId, pathId: PathId }).parse,
    stringify: p => p,
  },
})
