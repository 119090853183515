export enum TemplateTabCategory {
  Search,
  Recommended,
  Live,
  SelfPaced,
  Workshops,
  Onboarding,
  AllTeamTemplates,
  LiveTeamTemplates,
  SelfPacedTeamTemplates,
  ReadyMadeCourses,
  LeadershipDevelopment,
  SalesEnablement,
  Compliance,
  SanaOnboarding,
  ProductManagement,
  All,
  Community,
}

export type PrimaryTemplateFilter =
  | TemplateTabCategory.Recommended
  | TemplateTabCategory.Live
  | TemplateTabCategory.SelfPaced
  | TemplateTabCategory.AllTeamTemplates
  | TemplateTabCategory.Search
  | TemplateTabCategory.Community

export type SecondaryTemplateFilter =
  | TemplateTabCategory.All
  | TemplateTabCategory.Workshops
  | TemplateTabCategory.Onboarding
  | TemplateTabCategory.ReadyMadeCourses
  | TemplateTabCategory.LiveTeamTemplates
  | TemplateTabCategory.SelfPacedTeamTemplates
  | TemplateTabCategory.LeadershipDevelopment
  | TemplateTabCategory.SalesEnablement
  | TemplateTabCategory.Compliance
  | TemplateTabCategory.ProductManagement
  | TemplateTabCategory.SanaOnboarding

export type TemplateFilter = {
  primary: PrimaryTemplateFilter
  secondary?: SecondaryTemplateFilter
}
