import { ILocalVideoTrack, IRemoteVideoTrack } from 'agora-rtc-sdk-ng'
import { useEffect, useMemo, useState } from 'react'
import {
  ParticipantId,
  PrioriotyLevel,
  useCurrentVideoOwner,
  useParticipantsVideoPriority,
  useVideoIsSafeToUse,
} from 'sierra-client/components/liveV2/participant-provider'
import { useRemoteParticipantVideoTrack } from 'sierra-client/components/liveV2/services/video-call-service/hooks/use-remote-participant-track'
import { useLocalTracks } from 'sierra-client/components/liveV2/services/video-call-service/hooks/useLocalTracks'
import { VideoQuality } from 'sierra-client/components/liveV2/services/video-call-service/types'
import { useSelector } from 'sierra-client/state/hooks'
import { selectRemoteParticipants } from 'sierra-client/state/live/selectors'
import { NanoId12 } from 'sierra-domain/api/nano-id'
import { nanoid12 } from 'sierra-domain/nanoid-extensions'

export type VideoTrackWithOwner = {
  uid: string
  ownerId: string
  videoTrack: IRemoteVideoTrack | ILocalVideoTrack
}
export type ParticipantVideo = VideoTrackWithOwner | 'in-use' | undefined

const useRequestParticipantVideoTrack = (
  participantId: ParticipantId,
  priority: PrioriotyLevel = PrioriotyLevel.MEDIUM,
  quality: VideoQuality = 'auto',
  videoTrack?: IRemoteVideoTrack | ILocalVideoTrack | undefined
): ParticipantVideo => {
  const [ownerId] = useState<NanoId12>(nanoid12())
  const { requestVideo, removeVideoRequest } = useParticipantsVideoPriority()

  const videoIsSafe = useVideoIsSafeToUse(participantId, ownerId)
  const currentVideoOwner = useCurrentVideoOwner(participantId)

  const isCurrentOwner = currentVideoOwner?.ownerId === ownerId

  useEffect(() => {
    requestVideo(participantId, ownerId, priority, quality)

    return () => {
      removeVideoRequest(participantId, ownerId)
    }
  }, [participantId, ownerId, priority, quality, removeVideoRequest, requestVideo])

  const trackData = useMemo(
    () => (videoTrack ? { videoTrack, ownerId, uid: participantId } : undefined),
    [participantId, ownerId, videoTrack]
  )

  const videoAvailability = currentVideoOwner !== undefined ? 'in-use' : undefined
  return videoIsSafe && isCurrentOwner ? trackData : videoAvailability
}

export const useParticipantVideo = (
  agoraUid: string,
  priority: PrioriotyLevel = PrioriotyLevel.MEDIUM,
  quality: VideoQuality = 'auto'
): ParticipantVideo => {
  const participants = useSelector(selectRemoteParticipants)
  const participant = participants.find(({ id }) => id === agoraUid)
  const participantId = participant?.id

  const videoTrack = useRemoteParticipantVideoTrack(participantId)

  return useRequestParticipantVideoTrack(agoraUid, priority, quality, videoTrack)
}

export const useLocalParticipantVideo = (
  priority: PrioriotyLevel = PrioriotyLevel.MEDIUM,
  quality: VideoQuality = 'auto'
): ParticipantVideo => {
  const { localVideoTrack } = useLocalTracks()
  return useRequestParticipantVideoTrack('local', priority, quality, localVideoTrack)
}
