import { deriveTheme } from 'sierra-ui/color'
import { TextAreaPrimitive } from 'sierra-ui/primitives'
import styled from 'styled-components'

export const KeyboardShortcutText = styled.span`
  margin-left: 0.625rem;
  color: ${p => deriveTheme(p.theme).secondaryTextColor};
  font-size: 0.875rem;

  span {
    opacity: 0.6;
  }
`

export const AutoexpandingReflectionInput = styled(TextAreaPrimitive).attrs({
  autoExpand: true,
})`
  font-size: 1rem;
  outline-color: transparent !important;
  padding: 0;
  max-height: 600px;
  line-height: 1.5rem;
`
