import { DataViewer } from 'sierra-client/features/insights/display-widgets'
import { MeasureRef, MetricWidget } from 'sierra-domain/api/insights'
import { UUID } from 'sierra-domain/api/uuid'

const getTimeSpentOnSkill = (skillId: UUID): MetricWidget => {
  return {
    type: 'widget.metric',
    measures: [
      MeasureRef.parse({
        type: 'measure.native.session.duration',
      }),
    ],
    filter: {
      type: 'filter.and',
      filters: [
        {
          type: 'filter.filter',
          domain: {
            type: 'dimension.native.skill.skill',
          },
          operator: {
            type: 'operator.eq',
          },
          predicate: {
            type: 'predicate.or',
            values: [
              {
                type: 'value.skill-id',
                value: skillId,
              },
            ],
          },
        },
      ],
    },
  }
}

export const TimeSpentOnSkillWidget: React.FC<{ skillId: UUID }> = ({ skillId }) => {
  return <DataViewer widget={getTimeSpentOnSkill(skillId)} />
}
