import { createAction } from '@reduxjs/toolkit'
import { AnnotationType, ParagraphAndImageAnnotation } from 'sierra-client/state/pdf-annotation/types'
import {
  FontData,
  PdfAnnotationImage,
  PdfAnnotationToken,
  userAnnotationsFromServer,
} from 'sierra-domain/api/author-v2'
import { NanoId12 } from 'sierra-domain/api/nano-id'

export const setPrecomputedPdfAnnotations = createAction<{
  pdfAnnotationToken: PdfAnnotationToken[][]
  pdfAnnotationImage: PdfAnnotationImage[][]
  fonts: Partial<Record<NanoId12, FontData>>
  userAnnotations: userAnnotationsFromServer[][]
}>('pdf-annotation/set-precomputed-pdf-annotations')

export const addPdfAnnotation = createAction<{ pageIndex: number; annotation: ParagraphAndImageAnnotation }>(
  'pdf-annotation/add-annotation'
)
export const updatePdfAnnotation = createAction<ParagraphAndImageAnnotation>(
  'pdf-annotation/update-annotation'
)
export const deletePdfAnnotation = createAction<{ id: string; pageIndex: number }>(
  'pdf-annotation/delete-annotation'
)

export const setActiveAnnotationId = createAction<{ id?: string; pageIndex: number }>(
  'pdf-annotation/set-active-annotation'
)

export const setActiveAnnotationType = createAction<{
  type: AnnotationType
}>('pdf-annotation/set-active-annotation-type')

export const reorderAnnotation = createAction<{ key: string }>('pdf-annotation/reorder-annotation')

export const setPdfId = createAction<{ pdfId?: string | undefined }>('pdf-annotation/set-pdf-id')
export const magicWand = createAction<{ includeImages: boolean }>('pdf-annotation/magic-wand')
export const undoMagicWand = createAction('pdf-annotation/magic-wand-undo')

export const setFilename = createAction<{ filename?: string | undefined }>('pdf-annotation/set-filename')
