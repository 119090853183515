import { FC } from 'react'
import { useTranslation } from 'sierra-client/hooks/use-translation'
import { color } from 'sierra-ui/color'
import { MenuButton } from 'sierra-ui/components'
import { View } from 'sierra-ui/primitives'
import styled, { css } from 'styled-components'

const ButtonContainer = styled(View)<{ $selected: boolean }>`
  border: none;

  button:first-child {
    padding-left: 14px;
    padding-right: 8px;
  }

  button:last-child {
    padding-left: 1px;
    padding-right: 6px;
  }

  border-radius: ${p => p.theme.borderRadius['size-10']};

  ${p =>
    p.$selected
      ? css`
          background-color: ${color(p.theme.home.textColor).opacity(0.1).toString()};
          & button {
            color: ${p => p.theme.home.textColor};
          }
        `
      : css`
          & button {
            color: ${p => color(p.theme.home.textColor).opacity(0.4).toString()};
          }
        `}

  &:hover {
    background-color: ${p => color(p.theme.home.textColor).opacity(0.05).toString()};
  }

  & button:hover {
    color: ${p => color(p.theme.home.textColor).toString()};
  }
`

type MenuButtonProps = {
  onPrimaryClick: () => void
  onDelete: () => void
  onDuplicate: () => void
  selected: boolean
  children: string
}

export const VariationsButton: FC<MenuButtonProps> = ({
  children,
  onPrimaryClick,
  onDuplicate,
  onDelete,
  selected,
}) => {
  const { t } = useTranslation()
  const duplicateLabel = t('dictionary.duplicate')
  const deleteLabel = t('dictionary.delete')
  return (
    <ButtonContainer $selected={selected} gap='none' alignItems='center'>
      <MenuButton
        menuLabel={t('dictionary.details')}
        variant='secondary'
        onPrimaryClick={onPrimaryClick}
        menuItems={[
          {
            label: duplicateLabel,
            id: 'duplicate',
            type: 'label',
            icon: 'duplicate',
            onClick: onDuplicate,
          },
          { label: deleteLabel, id: 'delete', type: 'label', icon: 'trash-can', onClick: onDelete },
        ]}
        onSelect={item => {
          if (item.id === 'duplicate') {
            onDuplicate()
          } else {
            onDelete()
          }
        }}
      >
        {children}
      </MenuButton>
    </ButtonContainer>
  )
}
