import React, { useEffect } from 'react'
import { config } from 'sierra-client/config/global-config'
import { Auth } from 'sierra-client/core/auth'

export const CheckSession: React.FC = () => {
  const orgConfig = config.organization

  useEffect(() => {
    if (orgConfig.timeout !== null) {
      const interval = setInterval((): void => {
        void Auth.getInstance().synchronize()
      }, orgConfig.timeout / 4)

      return () => clearInterval(interval)
    }
  }, [orgConfig])

  useEffect(() => {
    void Auth.getInstance().synchronize()
  }, [orgConfig])

  return null
}
