import { Modal as BaseModal } from 'sierra-ui/components'
import { BorderRadius } from 'sierra-ui/types'
import styled, { css } from 'styled-components'

export const AdaptiveModal = styled(BaseModal).attrs({ borderSize: '4' })<{
  borderSize: number
  radius?: BorderRadius
  hideBorder?: boolean
}>`
  overflow: visible; /* For the pseudo element to show */

  &::before {
    position: absolute;
    top: -${p => p.borderSize}px;
    left: -${p => p.borderSize}px;
    width: calc(100% + ${p => p.borderSize * 2}px);
    height: calc(100% + ${p => p.borderSize * 2}px);

    display: block;
    content: '';
    z-index: -1;
    border-radius: calc(
      ${p => (p.radius === undefined ? p.theme.borderRadius['size-12'] : p.theme.borderRadius[p.radius])} +
        ${p => p.borderSize}px
    ); /* So it "hugs" the inner border-radius */
    opacity: 0;

    ${p =>
      p.hideBorder === true &&
      css`
        display: none;
      `};
  }
`
