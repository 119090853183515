import { token } from 'sierra-ui/theming'
import styled, { css } from 'styled-components'

export const Fade = styled.div<{ direction: 'up' | 'down' }>`
  position: absolute;

  left: 0;
  right: 0;
  height: 25px;
  ${p =>
    p.direction === 'up' &&
    css`
      background-image: linear-gradient(transparent, ${token('surface/default')});
      top: -25px;
    `}

  ${p =>
    p.direction === 'down' &&
    css`
      background-image: linear-gradient(${token('surface/default')}, transparent);
      bottom: -25px;
    `}
  pointer-events: none;
`
