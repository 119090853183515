import React, { useState } from 'react'
import { useTranslation } from 'sierra-client/hooks/use-translation'
import { CourseKind } from 'sierra-domain/api/common'
import { FormElement } from 'sierra-ui/components'
import { InputPrimitive, Switch, Text, View } from 'sierra-ui/primitives'

const isValidDuration = (duration: number): boolean => !Number.isNaN(duration) && duration >= 1

export const DurationPicker: React.FC<{
  calcAuto: boolean
  duration: number
  courseKind: CourseKind | null
  onChange: (newDuration: number) => void
  onToggle: (manualCalc: boolean) => void
  disabled?: boolean
  automaticEnabled: boolean
}> = ({ calcAuto, duration, courseKind, onChange, onToggle, disabled, automaticEnabled }) => {
  const { t } = useTranslation()
  const [currentDuration, setCurrentDuration] = useState<string | undefined>(duration.toString())
  const [calculating, setCalculating] = useState(false)
  const isExternalCourse = courseKind === 'link' || courseKind === 'linkedin' || courseKind === 'scorm'

  return (
    <View direction='column' gap='xxsmall'>
      <FormElement label={t('author.course.duration-minutes-label')}>
        {calculating ? (
          <InputPrimitive
            disabled={true}
            id='automaticCalc'
            onChange={() => {}}
            type='text'
            value={'Calculating estimated time...'}
          />
        ) : (
          <InputPrimitive
            disabled={(automaticEnabled && !isExternalCourse && calcAuto) || disabled}
            id='duration'
            type='number'
            defaultValue={duration}
            value={currentDuration ?? ''}
            onChange={e => {
              setCurrentDuration(e.target.value)

              const newDuration = parseInt(e.target.value)

              if (isValidDuration(newDuration)) {
                onChange(newDuration)
              }
            }}
          />
        )}
      </FormElement>
      {automaticEnabled && !isExternalCourse && (
        <Switch
          text={t('author.course.calculate-automatically')}
          checked={calcAuto}
          disabled={disabled}
          onChange={checked => {
            onToggle(checked)
            setCalculating(checked)
          }}
          ariaLabel='calculate automatically'
        />
      )}
      {!isValidDuration(parseInt(currentDuration ?? '')) && (
        <Text color='redVivid' size='micro'>
          {t('author.course.invalid-duration')}
        </Text>
      )}
    </View>
  )
}
