import { useAtomValue } from 'jotai'
import { loadable } from 'jotai/utils'
import React from 'react'
import { useGetDashboard } from 'sierra-client/features/insights/api-hooks/use-get-dashboard'
import { useListDashboards } from 'sierra-client/features/insights/api-hooks/use-list-dashboards'
import { useUpsertDashboard } from 'sierra-client/features/insights/api-hooks/use-upsert-dashboard'
import { latestVisitedDashboardAtom } from 'sierra-client/features/insights/atoms'
import {
  createNewDashboardAndRedirect,
  getDashboardToRedirectTo,
} from 'sierra-client/features/insights/dashboard-selector/utils'
import { DashboardErrorCard } from 'sierra-client/features/insights/dashboard/dashboard-error'
import { useTranslation } from 'sierra-client/hooks/use-translation'
import { getGlobalRouter } from 'sierra-client/router'
import { NanoId12 } from 'sierra-domain/api/nano-id'
import { LoadingSpinner } from 'sierra-ui/primitives'
import { useOnChanged } from 'sierra-ui/utils'

const RedirectToDashboard: React.FC<{ dashboardId: NanoId12 }> = ({ dashboardId }) => {
  useOnChanged(() => {
    void getGlobalRouter().navigate({ to: `/manage/insights/dashboards/${dashboardId}`, replace: true })
  }, [])

  return null
}

const RedirectToDefaultDashboard: React.FC = () => {
  const { t } = useTranslation()
  const upsertDashboard = useUpsertDashboard()

  useOnChanged(() => {
    void createNewDashboardAndRedirect('default', t, dashboard =>
      upsertDashboard.mutateAsync({ dashboard }).then(it => it.dashboard)
    )
  }, [])

  return null
}

const RedirectToDashboardInList: React.FC = () => {
  const queryResult = useListDashboards()

  if (queryResult.isPending) {
    return <LoadingSpinner />
  }

  if (queryResult.isError) {
    return <DashboardErrorCard />
  }

  const dashboardId = getDashboardToRedirectTo(queryResult.data.dashboards)
  if (dashboardId === undefined) {
    return <RedirectToDefaultDashboard />
  }

  return <RedirectToDashboard dashboardId={dashboardId} />
}

const RedirectToLastVisitedDashboard: React.FC<{ latestVisitedDashboardId: NanoId12 }> = ({
  latestVisitedDashboardId,
}) => {
  const queryResult = useGetDashboard({ dashboardId: latestVisitedDashboardId })

  if (queryResult.isPending) {
    return <LoadingSpinner />
  }

  if (queryResult.isError) {
    return <RedirectToDashboardInList />
  }

  return <RedirectToDashboard dashboardId={queryResult.data.dashboard.dashboard.id} />
}

export const LoadRecentOrDefaultDashboard: React.FC = () => {
  const latestVisitedDashboard = useAtomValue(loadable(latestVisitedDashboardAtom))

  if (latestVisitedDashboard.state === 'loading') {
    return <LoadingSpinner />
  }

  if (latestVisitedDashboard.state === 'hasError') {
    return <RedirectToDashboardInList />
  }

  // we get one render where latestVisitedDashboard.data is undefined
  // even though we have a value in local storage
  // the component will unmount before redirecting to the default dashboard
  if (latestVisitedDashboard.data === undefined) {
    return <RedirectToDashboardInList />
  }

  return <RedirectToLastVisitedDashboard latestVisitedDashboardId={latestVisitedDashboard.data} />
}
