import { DebouncedFunc, throttle } from 'lodash'
import { useEffect, useRef } from 'react'
import { getGlobalRouter } from 'sierra-client/router'
type UseThrottledCallbackProps<T> = {
  data: T
  memorizedCallback: (data: T) => void
  intervalMs?: number
  leading?: boolean
}

export const useThrottledCallback = <T,>({
  intervalMs = 2000,
  leading = false,
  data,
  memorizedCallback,
}: UseThrottledCallbackProps<T>): DebouncedFunc<UseThrottledCallbackProps<T>['memorizedCallback']> => {
  const saveFunction = useRef(throttle(memorizedCallback, intervalMs, { leading }))

  // Calls the throttle function with latest data
  useEffect(() => {
    saveFunction.current(data)
  }, [saveFunction, data])

  // Add listeners so we don't miss a save if we go to another page
  useEffect(() => {
    const current = saveFunction.current // Keep the reference
    window.addEventListener('beforeunload', current.flush)
    const unsubscribeRouter = getGlobalRouter().subscribe('onBeforeLoad', current.flush)
    return () => {
      current.flush()
      window.removeEventListener('beforeunload', current.flush)
      unsubscribeRouter()
    }
  }, [])

  return saveFunction.current
}
