import { createSlice } from '@reduxjs/toolkit'
import { clearState } from 'sierra-client/state/actions'
import { config } from 'sierra-client/state/author-course-settings'
import * as settingsActions from 'sierra-client/state/author-course-settings/actions'
import * as settingsSelectors from 'sierra-client/state/author-course-settings/selectors'
import { Collaborator, CourseSettingsState } from 'sierra-client/state/author-course-settings/types'

export const selectors = settingsSelectors

const initialState: CourseSettingsState = {
  isFetching: false,
  courseId: null,
  view: null,
  coursePublished: 'loading',
  originalSettings: 'loading',
  draftSettings: null,
  draftSettingsValidation: [],
  kind: null,
  collaborators: [] as Collaborator[],
  availableCollaborators: [] as Collaborator[],
  durationMinutes: null,
  readOnly: null,
}

export const authorCourseSettingsSlice = createSlice({
  name: config.name,
  initialState,
  reducers: {},
  extraReducers: builder => {
    builder.addCase(clearState, () => initialState)
    builder.addCase(settingsActions.setView, (state, action) => {
      state.view = action.payload
    })

    builder.addCase(settingsActions.fetch.pending, (state, action) => {
      state.isFetching = true
      state.courseId = action.meta.arg.courseId
    })
    builder.addCase(settingsActions.fetch.fulfilled, state => {
      state.isFetching = false
    })

    builder.addCase(settingsActions.fetchCourseSettings.fulfilled, (state, action) => {
      state.originalSettings = action.payload.settings
      state.kind = action.payload.kind
      state.readOnly = action.payload.readOnly
      state.draftSettings = state.originalSettings
    })

    builder.addCase(settingsActions.fetchCollaborators.pending, (state, action) => {
      if (action.meta.arg.courseId !== state.courseId) return
      if (state.availableCollaborators.length === 0) {
        // The set of available collaborators typically doesn't change much but we may
        // still refresh it because we want to update the set of current collaborators.
        // We only show the loading spinner the first time we get available collaborators to
        // avoid showing the loading spinner for a split second when removing or adding collaborators.
        state.availableCollaborators = 'loading'
      }
    })
    builder.addCase(settingsActions.fetchCollaborators.fulfilled, (state, action) => {
      if (action.meta.arg.courseId !== state.courseId) return
      state.availableCollaborators = action.payload.available
      state.collaborators = action.payload.current
    })

    builder.addCase(settingsActions.fetchCoursePublished.pending, state => {
      state.coursePublished = 'loading'
    })
    builder.addCase(settingsActions.fetchCoursePublished.fulfilled, (state, action) => {
      state.coursePublished = action.payload
    })

    builder.addCase(settingsActions.updateCourseSettings, (state, { payload }) => {
      if (state.draftSettings === null) return

      state.draftSettingsValidation = []

      state.draftSettings = {
        ...state.draftSettings,
        ...payload,
      }
    })

    builder.addCase(settingsActions.updateCourseSmartCards, (state, { payload }) => {
      if (state.draftSettings === null) return

      state.draftSettingsValidation = []

      const newSmartCards = (payload?.length ?? 0) > 0 ? payload : undefined

      state.draftSettings = {
        ...state.draftSettings,
        nativeCourseSettings: { ...state.draftSettings.nativeCourseSettings, smartCards: newSmartCards },
      }
    })

    builder.addCase(settingsActions.setSettingsValidationError, (state, action) => {
      state.draftSettingsValidation = action.payload
    })

    builder.addCase(settingsActions.resetDraftSettings, state => {
      state.draftSettings = state.originalSettings === 'loading' ? null : state.originalSettings
      state.draftSettingsValidation = []
    })
  },
})
