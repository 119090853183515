import { useInsightsViewsFromMeasures } from 'sierra-client/features/insights/hooks/use-insights-views'
import {
  MenuItemWithRef,
  useDimensionsMenuItems,
} from 'sierra-client/features/insights/widget-builder/data-selectors/single-dimension-selector'
import { WidgetBuilderState } from 'sierra-client/features/insights/widget-builder/widget-builder-state'
import { useTranslation } from 'sierra-client/hooks/use-translation'
import { TranslationLookup } from 'sierra-client/hooks/use-translation/types'
import { DimensionRef, MeasureRef, ViewRef } from 'sierra-domain/api/insights'
import { MenuItem } from 'sierra-ui/components'

export type DrillDownMenuItem = MenuItem & { ref?: DimensionRef }

const disableSelectedDimension = (
  selectedDimension: DimensionRef,
  menuItem: MenuItemWithRef,
  t: TranslationLookup
): MenuItemWithRef => {
  switch (menuItem.type) {
    case 'label':
      return menuItem.ref?.type === selectedDimension.type
        ? {
            ...menuItem,
            disabled: true,
            tooltip: t('manage.insights.select-dimension.same-dimension-tooltip'),
          }
        : menuItem
    case 'nested':
      return {
        ...menuItem,
        menuItems: menuItem.menuItems.map(menuItem =>
          disableSelectedDimension(selectedDimension, menuItem, t)
        ),
      }
    default:
      return menuItem
  }
}

export const useDrillDownMenuItems = ({
  selectedMeasures,
  selectedDimension,
  selectedView,
  visualisation,
  disabledByOrFilter,
}: {
  selectedMeasures?: MeasureRef[]
  selectedDimension?: DimensionRef
  selectedView?: ViewRef
  visualisation?: WidgetBuilderState['visualisation']
  disabledByOrFilter: boolean
}): DrillDownMenuItem[] => {
  const { t } = useTranslation()

  const { data: viewData } = useInsightsViewsFromMeasures(selectedMeasures, selectedView)

  const { menuItems: dimensionMenuItems } = useDimensionsMenuItems({
    visualisation,
    dimensionSelectorType: 'index',
    disabledDimensionsMetricLabelsMap: viewData?.disabledDimensionsByMetricLabels,
    disabledDimensions: undefined,
  })

  const dimensionMenuItemsWithSelectedDisabled =
    selectedDimension === undefined
      ? dimensionMenuItems
      : dimensionMenuItems.map(dimensionMenuItem =>
          disableSelectedDimension(selectedDimension, dimensionMenuItem, t)
        )

  const tooltip = disabledByOrFilter
    ? t('manage.insights.widget-builder.drill-down-disable-by-filter')
    : undefined

  const menuItems = [
    {
      id: 'filter-in',
      type: 'nested',
      label: t('dictionary.expand'),
      icon: 'search',
      menuItems: dimensionMenuItemsWithSelectedDisabled,
      withSearch: dimensionMenuItems.length > 8,
      searchPlaceholder: t('menu.search.placeholder'),
      disabled: disabledByOrFilter,
      tooltip,
    },
    {
      id: 'filter-out',
      type: 'label',
      label: t('dictionary.filter-out'),
      icon: 'view--off',
      disabled: disabledByOrFilter,
      tooltip,
    },
  ] satisfies DrillDownMenuItem[]

  return menuItems
}
