import fuzzysort from 'fuzzysort'
import { Atom, useAtomValue } from 'jotai'
import _ from 'lodash'
import React, { useCallback, useEffect, useMemo, useRef, useState } from 'react'
import { ActionModal } from 'sierra-client/components/common/modals/action-modal'
import { useNotif } from 'sierra-client/components/common/notifications'
import { useTeamspaceContentDrop } from 'sierra-client/features/teamspace'
import { useDebouncedState } from 'sierra-client/hooks/use-debounced-state'
import { useTranslation } from 'sierra-client/hooks/use-translation'
import { FCC } from 'sierra-client/types'
import { NewCourseSettingsModal } from 'sierra-client/views/course-settings/course-settings-modal'
import {
  SectionHeadline,
  TeamspaceHeadline,
} from 'sierra-client/views/workspace/create-new/common/section-headline'
import {
  editableContentTypeFilterRow,
  editablePublishStateFilterRow,
  editableSortFunctions,
} from 'sierra-client/views/workspace/create-new/filters/create-page-desktop-filters'
import {
  CreatePageSelectedFilters,
  CreatePageSortFunctionId,
} from 'sierra-client/views/workspace/create-new/filters/types'
import { useFilteredTeamspaceContent } from 'sierra-client/views/workspace/create-new/use-filtered-teamspace-content'
import { CreateContentTableRow } from 'sierra-client/views/workspace/create/create-content-table-row'
import { TeamspaceContentTableRow } from 'sierra-client/views/workspace/teamspace/teamspace-content-table-row'
import { CreatePageContent } from 'sierra-domain/api/create-page'
import { EditableContent } from 'sierra-domain/api/editable-content'
import { Teamspace, TeamspaceContent } from 'sierra-domain/api/teamspace'
import { Button, Text, View } from 'sierra-ui/primitives'
import styled, { css } from 'styled-components'

type BucketsToShow = 'everything' | 'created-by-me' | 'shared-with-me'

export function ExpandableContentList<Item>({
  items,
  children,
}: {
  id?: string
  leaveEmptyCell?: boolean
  items: Item[]
  children: ({ items }: { items: Item[] }) => React.ReactNode
}): JSX.Element {
  const { t } = useTranslation()
  const [numberOfContent, setNumberOfContent] = useState(15)
  const resolvedItems = useMemo(() => _.take(items, numberOfContent), [numberOfContent, items])

  return (
    <>
      {children({ items: resolvedItems })}
      {items.length > numberOfContent && (
        <View grow>
          <Button
            variant='ghost'
            grow
            icon='add'
            onClick={() => setNumberOfContent(numberOfContent => numberOfContent + 15)}
          >
            {t('dictionary.view-more')}
          </Button>
        </View>
      )}
    </>
  )
}

const EditableContentListView: React.FC<{
  content: EditableContent[]
  reloadContent: () => void
  isOver?: boolean
}> = ({ content, reloadContent, isOver }) => {
  const notifications = useNotif()

  const onDeleted = useCallback(() => {
    notifications.push({ type: 'course-removed' })
    reloadContent()
  }, [notifications, reloadContent])

  return (
    <View direction='column'>
      {content.map(item => (
        <CreateContentTableRow
          isOver={isOver}
          key={item.id}
          content={item}
          isPinned={item.pinned === true}
          onDeleted={onDeleted}
          reloadContent={reloadContent}
        />
      ))}
    </View>
  )
}

const DropAreaWrapper = styled(View)<{ showDropArea: boolean }>`
  border-radius: 12px;
  transition: background 100ms ease-in-out;

  /* Add extra space on the side for the highlight background */
  margin-inline: -12px;
  padding-inline: 12px;
  ${p =>
    p.showDropArea &&
    css`
      background: rgba(20, 170, 255, 0.1);
    `}
`

const MyContentTable: React.FC<{
  content: EditableContent[]
  reloadContent: () => void
}> = ({ content, reloadContent }) => {
  const myContentRef = useRef<HTMLDivElement | null>(null)
  const { isOver, canDrop, drop, confirmModal } = useTeamspaceContentDrop(undefined, undefined)
  drop(myContentRef)

  const { t } = useTranslation()

  if (content.length === 0) {
    return <></>
  }

  return (
    <DropAreaWrapper
      direction='column'
      marginTop='16'
      marginBottom='8'
      ref={myContentRef}
      showDropArea={isOver && canDrop}
    >
      <ExpandableContentList items={content}>
        {({ items }) => (
          <>
            <SectionHeadline
              text={t('workspace.create.my-content')}
              iconId='locked'
              nrOfShowingItems={items.length}
              totalNrOfItems={content.length}
            />
            <EditableContentListView
              content={items}
              reloadContent={reloadContent}
              isOver={isOver && canDrop}
            />
          </>
        )}
      </ExpandableContentList>

      <ActionModal
        open={confirmModal.isOpen}
        title={confirmModal.title}
        onClose={confirmModal.close}
        primaryAction={confirmModal.primaryAction}
        primaryActionLabel={t('dictionary.continue')}
      >
        {confirmModal.body}
      </ActionModal>
    </DropAreaWrapper>
  )
}

const SharedContentTable: React.FC<{
  content: EditableContent[]
  reloadContent: () => void
}> = ({ content, reloadContent }) => {
  const { t } = useTranslation()
  if (content.length === 0) {
    return <></>
  }

  return (
    <View direction='column' marginTop='16' marginBottom='8'>
      <ExpandableContentList items={content}>
        {({ items }) => (
          <>
            <SectionHeadline
              text={t('create-overview.shared-with-me')}
              iconId='folder--shared'
              nrOfShowingItems={items.length}
              totalNrOfItems={content.length}
            />
            <EditableContentListView content={items} reloadContent={reloadContent} />
          </>
        )}
      </ExpandableContentList>
    </View>
  )
}

const TeamspaceTable: React.FC<{
  teamspace: Teamspace
  searchTerm: string
  reloadContent: () => void
  displayContent?: TeamspaceContent[]
}> = ({ teamspace, searchTerm, reloadContent, displayContent }) => {
  const { t } = useTranslation()
  const notifications = useNotif()

  const teamspaceRef = useRef<HTMLDivElement | null>(null)
  const { isOver, canDrop, drop, confirmModal } = useTeamspaceContentDrop(teamspace, undefined)
  drop(teamspaceRef)

  if (displayContent === undefined || displayContent.length === 0) {
    return <></>
  }

  return (
    <DropAreaWrapper
      key={teamspace.id}
      direction='column'
      marginTop='16'
      marginBottom='8'
      ref={teamspaceRef}
      showDropArea={canDrop && isOver}
    >
      <ExpandableContentList items={displayContent}>
        {({ items }) => (
          <>
            <TeamspaceHeadline
              href={`/t/${teamspace.id}`}
              teamspaceIconProps={{
                displayName: teamspace.displayName,
                themeName: teamspace.iconTheme,
                small: true,
              }}
              nrOfShowingItems={items.length}
              totalNrOfItems={displayContent.length}
            />
            <View direction='column'>
              {items.map(item => {
                const id = item.type === 'folder' ? item.id : item.content.id
                return (
                  <TeamspaceContentTableRow
                    teamspace={teamspace}
                    isOver={isOver && canDrop}
                    key={id}
                    content={item}
                    isPinned={item.type !== 'folder' && item.content.pinned === true}
                    onDeleted={() => {
                      notifications.push({ type: 'course-removed' })
                      reloadContent()
                    }}
                    onPin={reloadContent}
                    onDuplicate={reloadContent}
                    onMoveToTeamspace={reloadContent}
                    showCurrentFolder={searchTerm !== ''}
                  />
                )
              })}
            </View>
          </>
        )}
      </ExpandableContentList>
      <ActionModal
        open={confirmModal.isOpen}
        title={confirmModal.title}
        onClose={confirmModal.close}
        primaryAction={confirmModal.primaryAction}
        primaryActionLabel={t('dictionary.continue')}
      >
        {confirmModal.body}
      </ActionModal>
    </DropAreaWrapper>
  )
}

const useDisplayContent = (
  content: EditableContent[],
  debouncedSearchTerm: string,
  selectedFilters: CreatePageSelectedFilters,
  selectedSortFunction: CreatePageSortFunctionId
): EditableContent[] => {
  const editableContent = useMemo(() => {
    const allContentTypes = selectedFilters.selectedContentType.length === 0
    const sortFunction = editableSortFunctions[selectedSortFunction]
    const sortedContent = sortFunction(content)

    const filteredContentType = allContentTypes
      ? sortedContent
      : selectedFilters.selectedContentType.flatMap(contentType =>
          sortedContent.filter(row => editableContentTypeFilterRow(contentType, row))
        )

    const allPublishStates = selectedFilters.selectedPublishState.length === 0

    const filteredContent = allPublishStates
      ? filteredContentType
      : selectedFilters.selectedPublishState.flatMap(contentType =>
          filteredContentType.filter(row => editablePublishStateFilterRow(contentType, row))
        )

    if (debouncedSearchTerm === '') {
      return filteredContent
    }

    const searchContent = fuzzysort
      .go(debouncedSearchTerm, filteredContent, {
        all: true,
        limit: debouncedSearchTerm.length > 0 ? 20 : undefined,
        key: 'title',
      })
      .map(({ obj }) => obj)

    return searchContent
  }, [selectedFilters, selectedSortFunction, content, debouncedSearchTerm])

  return editableContent
}

const NoResultsFoundWrapper = styled(View)`
  height: 200px;
`

const NoMatchingContent: React.FC<{ clear: () => void }> = ({ clear }) => {
  const { t } = useTranslation()

  return (
    <NoResultsFoundWrapper direction='column' justifyContent='center' alignItems='center' gap='24'>
      <View direction='column' justifyContent='flex-start' alignItems='center' gap='2'>
        <Text bold color='foreground/muted' align='center'>
          {t('dictionary.no-matching-content')}
        </Text>
        <Text color='foreground/muted' align='center'>
          {t('create-page.adjust-filters')}
        </Text>
      </View>
      <View onClick={clear} cursor='pointer'>
        <Text bold color='foreground/secondary' align='center' onClick={clear}>
          {t('dictionary.clear-all-filters')}
        </Text>
      </View>
    </NoResultsFoundWrapper>
  )
}

const Bucket: FCC<{ bucket: BucketsToShow; bucketsToShowAtom: Atom<BucketsToShow> }> = ({
  bucket,
  bucketsToShowAtom,
  children,
}) => {
  const bucketsToShow = useAtomValue(bucketsToShowAtom)
  const style = useMemo(
    () =>
      bucketsToShow === 'everything' || bucketsToShow === bucket
        ? ({} as const)
        : ({ display: 'none' } as const),
    [bucket, bucketsToShow]
  )
  // eslint-disable-next-line react/forbid-dom-props
  return <div style={style}>{children}</div>
}

const ContentTables: React.FC<{
  clear: () => void
  bucketsToShowAtom: Atom<BucketsToShow>
  createPageContent: CreatePageContent
  searchTerm: string
  selectedFilters: CreatePageSelectedFilters
  reloadContent: () => void
  selectedSortFunction: CreatePageSortFunctionId
}> = ({
  clear,
  bucketsToShowAtom,
  createPageContent,
  searchTerm,
  selectedFilters,
  reloadContent,
  selectedSortFunction,
}) => {
  const [debouncedSearchTerm, setDebouncedSearchTerm] = useDebouncedState(searchTerm, { wait: 500 })

  useEffect(() => {
    setDebouncedSearchTerm(searchTerm)
  }, [searchTerm, setDebouncedSearchTerm])

  const myContent = useDisplayContent(
    createPageContent.myCreations.content,
    debouncedSearchTerm,
    selectedFilters,
    selectedSortFunction
  )

  const sharedContent = useDisplayContent(
    createPageContent.shared.content,
    debouncedSearchTerm,
    selectedFilters,
    selectedSortFunction
  )

  const filteredTeamspaceContent = useFilteredTeamspaceContent(
    createPageContent.teamspaces,
    debouncedSearchTerm,
    selectedFilters,
    selectedSortFunction
  )

  const isEmptyTeamspaceContent = _.chain(filteredTeamspaceContent).values().flatten().value().length === 0
  const isEmpty = sharedContent.length === 0 && myContent.length === 0 && isEmptyTeamspaceContent

  return (
    <View grow direction='column' marginTop='8'>
      {isEmpty && <NoMatchingContent clear={clear} />}

      <Bucket bucket='created-by-me' bucketsToShowAtom={bucketsToShowAtom}>
        <MyContentTable content={myContent} reloadContent={reloadContent} />
      </Bucket>

      <Bucket bucket='shared-with-me' bucketsToShowAtom={bucketsToShowAtom}>
        <SharedContentTable content={sharedContent} reloadContent={reloadContent} />
      </Bucket>

      <Bucket bucket='everything' bucketsToShowAtom={bucketsToShowAtom}>
        <View direction='column'>
          {_.sortBy(createPageContent.teamspaces, it => it.displayName).map(teamspace => {
            return (
              <TeamspaceTable
                searchTerm={searchTerm}
                key={teamspace.id}
                teamspace={teamspace}
                displayContent={filteredTeamspaceContent[teamspace.id]}
                reloadContent={reloadContent}
              />
            )
          })}
        </View>
      </Bucket>
    </View>
  )
}

export const CreatePageBuckets: React.FC<{
  clear: () => void
  searchTermAtom: Atom<string>
  bucketsToShowAtom: Atom<BucketsToShow>
  selectedFilters: CreatePageSelectedFilters
  createPageContent: CreatePageContent
  reloadContent: () => void
  selectedSortFunction: CreatePageSortFunctionId
}> = ({
  clear,
  bucketsToShowAtom,
  searchTermAtom,
  selectedFilters,
  createPageContent,
  reloadContent,
  selectedSortFunction,
}) => {
  const searchTerm = useAtomValue(searchTermAtom)
  return (
    <>
      <ContentTables
        clear={clear}
        searchTerm={searchTerm}
        bucketsToShowAtom={bucketsToShowAtom}
        selectedFilters={selectedFilters}
        createPageContent={createPageContent}
        reloadContent={reloadContent}
        selectedSortFunction={selectedSortFunction}
      />
      <NewCourseSettingsModal onSave={reloadContent} onClose={reloadContent} />
    </>
  )
}
