import { createAction } from '@reduxjs/toolkit'
import { ConnectionStatus } from 'sierra-client/collaboration/types'
import { Clock } from 'sierra-client/state/collaboration/types'

export const clockSynced = createAction<Clock>('collaboration/clockSynced')

export const setInvalidCollaborationVersion = createAction<boolean>(
  'collaboration/set-invalid-collaboration-version'
)

export const removeCollabProvider = createAction<{
  clientId: string
}>('collaboration/removeCollabProvider')

export const collabProviderStatusChanged = createAction<{
  clientId: string
  channels: string
  status: ConnectionStatus
}>('collaboration/collab-provider-status-changed')
