import { joinFacilitators } from 'sierra-client/core/format'
import { TranslationKey } from 'sierra-client/hooks/use-translation/types'
import { LiveSessionAttendanceStatus } from 'sierra-domain/api/manage'
import { LiveSessionBase } from 'sierra-domain/content/session'
import { BaseUserRow } from 'sierra-domain/user/base-user-row'
import { z } from 'zod'

export const getAttendanceTranslationKey = (attendance: LiveSessionAttendanceStatus): TranslationKey => {
  switch (attendance) {
    case 'absent':
      return 'manage.live-session.attendance.absent'
    case 'present':
      return 'manage.live-session.attendance.present'
  }
}

type LiveSessionToCsvData = {
  liveSessionId: string
  data: LiveSessionBase
  facilitatorsInfo: z.infer<typeof BaseUserRow>[]
  assignedUsers?: number
}

export const liveSessionToCsv = ({
  data,
  facilitatorsInfo,
  liveSessionId,
  assignedUsers,
}: LiveSessionToCsvData): Record<string, string> => {
  const { title, location, maxNumberOfUsers, allowGuestAccess } = data
  const { startTime, endTime } =
    data.type === 'scheduled'
      ? {
          startTime: data.startTime,
          endTime: data.endTime,
        }
      : { startTime: undefined, endTime: undefined }

  const facilitatorPart = {
    facilitators: joinFacilitators(facilitatorsInfo),
  }
  const assignmentsPart =
    assignedUsers === undefined
      ? undefined
      : {
          assignedUsers: `${assignedUsers}`,
        }

  return {
    id: liveSessionId,
    title: title,
    startTime: startTime ?? '',
    endTime: endTime ?? '',
    physicalLocation: location?.value ?? '',
    allowGuests: allowGuestAccess ? 'true' : 'false',
    maxNumberOfUsers: `${maxNumberOfUsers ?? 0}`,
    ...facilitatorPart,
    ...assignmentsPart,
  }
}
