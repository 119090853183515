import { FC, useCallback, useMemo } from 'react'
import { IconMenu } from 'sierra-client/components/common/icon-menu'
import {
  HeaderButtonColorCSS,
  HeaderButtonGroupWrapper,
  HeaderGroupIconButton,
} from 'sierra-client/components/liveV2/header-buttons'
import { useTranslation } from 'sierra-client/hooks/use-translation'
import { useCreatePageContext } from 'sierra-client/views/flexible-content/create-page-context'
import { useReversibleEditorAction } from 'sierra-client/views/flexible-content/undo-redo/use-reversible-editor-action'
import { apply } from 'sierra-domain/editor/operations'
import { File } from 'sierra-domain/flexible-content/types'
import { MenuItem, Tooltip } from 'sierra-ui/components'
import styled, { css } from 'styled-components'

const StyledIconMenu = styled(IconMenu)<{ hasVideo: boolean }>`
  cursor: pointer;
  padding: 0 0.375rem;
  ${p =>
    p.hasVideo &&
    css`
      border-top-left-radius: 0.625rem;
      border-top-right-radius: 0;
      border-bottom-right-radius: 0;
      border-bottom-left-radius: 0.625rem;
    `}
  ${HeaderButtonColorCSS}
`

export const VideoControls: FC<{ file: File }> = ({ file }) => {
  const { operationState } = useCreatePageContext()
  const fileId = file.id

  const disableVideoSkipping = file.data.type === 'video' ? file.data.video.disableSkipping === true : false
  const disableSubtitles = file.data.type === 'video' ? file.data.video.disableSubtitles === true : false
  const { t } = useTranslation()
  const applyReversibleAction = useReversibleEditorAction()

  const updateDisableSkipping = useCallback((): void => {
    apply(operationState, {
      type: 'update-files',
      fileIds: [fileId],
      update: file => {
        if (file.data.type !== 'video') throw Error(`File ${fileId} is not a video`)
        file.data.video.disableSkipping = !disableVideoSkipping
      },
    })
  }, [fileId, operationState, disableVideoSkipping])

  const updateDisableSubtitles = useCallback((): void => {
    apply(operationState, {
      type: 'update-files',
      fileIds: [fileId],
      update: file => {
        if (file.data.type !== 'video') throw Error(`File ${fileId} is not a video`)
        file.data.video.disableSubtitles = !disableSubtitles
      },
    })
  }, [operationState, fileId, disableSubtitles])

  const items = useMemo(
    (): MenuItem[] => [
      {
        type: 'switch',
        id: 'test',
        label: t('author.block-editor.embed-disable-skipping'),
        checked: disableVideoSkipping,
        onToggleChange: updateDisableSkipping,
      },
      {
        type: 'switch',
        id: 'test',
        label: t('author.block-editor.video-disable-subtitles'),
        checked: disableSubtitles,
        onToggleChange: updateDisableSubtitles,
      },
    ],
    [disableSubtitles, disableVideoSkipping, t, updateDisableSkipping, updateDisableSubtitles]
  )

  const reversibleRemoveVideo = (): void => {
    if (!(file.data.type === 'video' && file.data.video.url !== undefined)) return
    applyReversibleAction({ type: 'remove-video', fileId })
  }

  const hasVideo = file.data.type === 'video' && file.data.video.url !== undefined

  return (
    <HeaderButtonGroupWrapper gap='none'>
      <StyledIconMenu withChevron={true} iconId='settings' items={items} hasVideo={hasVideo} />
      {hasVideo && (
        <Tooltip title={t('author.article.remove-video')}>
          <HeaderGroupIconButton iconId='video--off' onClick={reversibleRemoveVideo} />
        </Tooltip>
      )}
    </HeaderButtonGroupWrapper>
  )
}
