import { FCC } from 'sierra-client/types'
import { token } from 'sierra-ui/theming'
import { DesignToken } from 'sierra-ui/theming/tokens/types'
import styled, { createGlobalStyle, css } from 'styled-components'

const GlobalDnDStyles = createGlobalStyle`
  * {
    user-select: none;
    cursor: grabbing !important;
  }
`

type CardProps = {
  $x: number
  $y: number
  $width: number
  $height: number
  $backgroundToken: DesignToken
  $gradient: boolean
}
const Card = styled.div.attrs<CardProps>(({ $x, $y }) => ({
  style: {
    /**
     * We saw a warning from styled-components that this caused a lot of generated classes
     */
    transform: `translate(${$x}px, ${$y}px)`,
  },
}))<CardProps>`
  isolation: isolate;
  position: fixed;
  top: 0;
  left: 0;
  overflow: hidden;
  width: ${p => p.$width}px;
  height: ${p => p.$height}px;
  box-shadow: 0 6px 8px -4px rgba(0, 0, 0, 0.2);
  z-index: 999;
  display: flex;
  flex-flow: row nowrap;
  align-items: center;
  font-size: 14px;
  font-weight: bold;
  background: ${p => token(p.$backgroundToken)};
  border-radius: ${p => p.theme.borderRadius['size-16']};
  border: 1px solid ${token('border/default')};
  color: ${token('foreground/primary')};

  ${p =>
    p.$gradient &&
    css`
      &:before {
        position: absolute;
        content: '';
        inset: 0;
        background-image: linear-gradient(
          to bottom,
          transparent calc(100% - 25px),
          ${token(p.$backgroundToken)(p)} calc(100% - 10px)
        );
      }

      &:after {
        position: absolute;
        content: '';
        inset: 0;
        background-image: linear-gradient(
          to right,
          transparent calc(100% - 35px),
          ${token(p.$backgroundToken)(p)} calc(100% - 10px)
        );
      }
    `}
  img {
    max-width: 100%;
    max-height: 100%;
    border-radius: 4px;
  }
`

export const DragOverlayCard: FCC<{
  x: number
  y: number
  height?: number
  width?: number
  background?: DesignToken
  gradient?: boolean
}> = ({ children, x, y, width = 300, height = 70, background = 'surface/default', gradient = true }) => {
  return (
    <>
      <Card $x={x} $y={y} $width={width} $height={height} $backgroundToken={background} $gradient={gradient}>
        {children}
      </Card>
      <GlobalDnDStyles />
    </>
  )
}
