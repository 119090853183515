import { useTemplatePicker } from 'sierra-client/components/templates'
import { useTranslation } from 'sierra-client/hooks/use-translation'
import { Button } from 'sierra-ui/primitives'

export const CreateNewActionButton: React.FC<{ onClick?: () => void }> = ({ onClick }) => {
  const { t } = useTranslation()
  const { templatePickerOn } = useTemplatePicker()

  return (
    <Button
      variant='secondary'
      onClick={() => {
        onClick?.()
        templatePickerOn()
      }}
    >
      {t('create.home.create-new')}
    </Button>
  )
}
