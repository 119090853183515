import { AnimatePresence, motion } from 'framer-motion'
import { atom, useAtomValue } from 'jotai'
import _ from 'lodash'
import { DateTime } from 'luxon'
import React, { useEffect, useMemo, useRef, useState } from 'react'
import { UpcomingSessionContentCard } from 'sierra-client/components/content-card/content-card'
import { useIsScrolledToLeftOrRight } from 'sierra-client/hooks/use-is-scrolled-left-or-right'
import { useTranslation } from 'sierra-client/hooks/use-translation'
import { useIsMobile, useIsSmallDesktop } from 'sierra-client/state/browser/selectors'
import { useDispatch } from 'sierra-client/state/hooks'
import { contentCardWidthPx } from 'sierra-client/views/learner/home/components/content-card-grid'
import {
  homePageContentCardClickedLogger,
  homePageViewAllClickedLogger,
} from 'sierra-client/views/learner/home/logger'
import { UpcomingSessions } from 'sierra-domain/api/learn'
import { iife, isDefined, isNotNull } from 'sierra-domain/utils'
import { Icon } from 'sierra-ui/components'
import { Button, Text, View } from 'sierra-ui/primitives'
import { v2_breakpoint } from 'sierra-ui/theming/breakpoints'
import styled, { css } from 'styled-components'

export const AvailableUpcomingSessionsSpaceAtom = atom<number>(0)

const RevealScrollLeftButton = styled.div`
  position: absolute;
  width: 200px;
  height: 100%;
  top: 0;
  left: 0;
`

const RevealScrollRightButton = styled.div`
  position: absolute;
  width: 200px;
  height: 100%;
  top: 0;
  right: 0;
`

const ScrollLeftButtonContainer = styled(motion.div).attrs({
  initial: { opacity: 0, x: -8 },
  animate: { opacity: 1, x: 0 },
  exit: { opacity: 0, x: -8 },
  transition: { duration: 0.18 },
})`
  position: absolute;
  top: 50%;
  left: 24px;
`

const ScrollRightButtonContainer = styled(motion.div).attrs({
  initial: { opacity: 0, x: 8 },
  animate: { opacity: 1, x: 0 },
  exit: { opacity: 0, x: 8 },
  transition: { duration: 0.18 },
})`
  position: absolute;
  top: 50%;
  right: 24px;
`

const ScrollButton = styled.button`
  background: #fff;
  box-shadow: 0px 4px 12px 0px rgba(0, 0, 0, 0.08);
  color: black;
  backdrop-filter: blur(16px);
  border-radius: 10px;

  display: flex;
  align-items: center;
  justify-content: center;
  height: 40px;
  width: 40px;

  &:hover {
    cursor: pointer;
  }
`

const ScrollButtons: React.FC<{
  isScrolledToLeft: boolean
  isScrolledToRight: boolean
  scrollContainerRef: React.RefObject<HTMLUListElement>
}> = ({ isScrolledToLeft, isScrolledToRight, scrollContainerRef }) => {
  const [showScrollLeftButton, setShowScrollLeftButton] = useState(false)
  const [showScrollRightButton, setShowScrollRightButton] = useState(false)
  const [recommendationWidth, setScollContainerWidth] = useState(400)

  const isMobile = useIsMobile()
  const isSmallDesktop = useIsSmallDesktop()

  const scrollDistance = iife(() => {
    if (!isSmallDesktop) return recommendationWidth + 24
    if (!isMobile) return recommendationWidth + 16

    return recommendationWidth
  })

  const scrollToLeft = (): void => {
    if (isNotNull(scrollContainerRef.current)) {
      scrollContainerRef.current.scrollBy({ left: -scrollDistance, behavior: 'smooth' })
    }
  }
  const scrollToRight = (): void => {
    if (isNotNull(scrollContainerRef.current)) {
      scrollContainerRef.current.scrollBy({ left: scrollDistance, behavior: 'smooth' })
    }
  }

  useEffect(() => {
    if (isNotNull(scrollContainerRef.current)) {
      const firstChild = scrollContainerRef.current.children[0]
      if (isDefined(firstChild)) {
        setScollContainerWidth(firstChild.clientWidth)
      }
    }
  }, [scrollContainerRef])

  return (
    <>
      {!isMobile && !isScrolledToLeft && (
        <RevealScrollLeftButton
          onMouseEnter={() => setShowScrollLeftButton(true)}
          onMouseLeave={() => setShowScrollLeftButton(false)}
        >
          <AnimatePresence>
            {showScrollLeftButton && (
              <ScrollLeftButtonContainer key='scrollbutton-left'>
                <ScrollButton onClick={scrollToLeft}>
                  <Icon color='currentColor' iconId={'arrow--left'} />
                </ScrollButton>
              </ScrollLeftButtonContainer>
            )}
          </AnimatePresence>
        </RevealScrollLeftButton>
      )}
      {!isMobile && !isScrolledToRight && (
        <RevealScrollRightButton
          onMouseEnter={() => setShowScrollRightButton(true)}
          onMouseLeave={() => setShowScrollRightButton(false)}
        >
          <AnimatePresence>
            {showScrollRightButton && (
              <ScrollRightButtonContainer key='scrollbutton-right'>
                <ScrollButton onClick={scrollToRight}>
                  <Icon color='currentColor' iconId={'arrow--right'} />
                </ScrollButton>
              </ScrollRightButtonContainer>
            )}
          </AnimatePresence>
        </RevealScrollRightButton>
      )}
    </>
  )
}

const MoreSessionsIndicator = styled(View)`
  border-radius: 12px;
  padding: 10px;
`

const UpcomingListItem = styled.li`
  display: flex;
  flex-direction: column;
  gap: 16px;
  height: 100%;
  padding-inline: 28px;
  border-right: 1px solid rgba(0, 0, 0, 0.1);
`

const UpcomingSessionContent: React.FC<{
  date: Date
  sessions: UpcomingSessions[]
}> = ({ date, sessions }) => {
  const { t } = useTranslation()
  const dispatch = useDispatch()

  const sortedSessions = _.sortBy(sessions, it => it.session.startTime)
  const remainingSessions = sessions.length - 2

  return (
    <UpcomingListItem>
      <View direction='column' gap='4'>
        <Text color='foreground/primary' bold>
          {DateTime.fromJSDate(date).toLocaleString({ month: 'short', day: '2-digit' })}
        </Text>
        <Text color='foreground/muted'> {DateTime.fromJSDate(date).toLocaleString({ year: 'numeric' })}</Text>
      </View>
      <View direction='column' gap='40'>
        {_.take(sortedSessions, 2).map(session => (
          <UpcomingSessionContentCard
            session={session}
            key={session.session.id}
            includeDate={false}
            onCardClick={() => {
              void dispatch(
                homePageContentCardClickedLogger({
                  contentTitle: session.session.title,
                  contentType: session.entity.entityType,
                  section: 'upcoming-session',
                })
              )
            }}
          />
        ))}
      </View>
      {remainingSessions > 0 && (
        <MoreSessionsIndicator background='surface/strong' justifyContent='center'>
          <Text size='micro' bold color='foreground/secondary'>
            {t('home.upcoming-sessions.more-this-day', { count: remainingSessions })}
          </Text>
        </MoreSessionsIndicator>
      )}
    </UpcomingListItem>
  )
}

const UpcomingSectionSection = styled.section`
  border-radius: 20px;
  background: rgba(0, 0, 0, 0.03);
  margin-left: 48px;
  margin-right: 48px;
  margin-top: 6px;
  margin-bottom: 6px;
  padding: 32px 0px;
  position: relative;

  @media screen and (max-width: ${v2_breakpoint.phone}) {
    margin-left: 16px;
    margin-right: 8px;
  }
`

const UpcominSessionWrapper = styled(View)`
  overflow: hidden;
  width: 100%;
`

function UpcomingSessionCardSection<Item>({
  items,
  children,
  leaveEmptyCell = false,
}: {
  leaveEmptyCell?: boolean
  items: Item[]
  children: ({
    numberOfCards,
    items,
    canHaveAnEmptyCell,
  }: {
    numberOfCards: number
    items: Item[]
    canHaveAnEmptyCell: boolean
  }) => React.ReactNode
}): JSX.Element {
  const cardWidth = contentCardWidthPx
  const gap = 0

  const availableSpace = useAtomValue(AvailableUpcomingSessionsSpaceAtom)

  const widthWithoutPadding = availableSpace - 48 - 32
  let _numberOfCards = Math.floor(widthWithoutPadding / cardWidth)
  const remainingWidth = widthWithoutPadding - _numberOfCards * cardWidth
  const totalGapsWidth = (_numberOfCards - 1) * gap

  if (totalGapsWidth > remainingWidth) _numberOfCards -= 1

  const numberOfCards = _numberOfCards < 2 ? 10 : _numberOfCards
  const resolvedItems = useMemo(
    () => _.take(items, leaveEmptyCell === true ? numberOfCards - 1 : numberOfCards),
    [items, leaveEmptyCell, numberOfCards]
  )

  return (
    <UpcominSessionWrapper direction='column' gap='32'>
      {children({ numberOfCards, items: resolvedItems, canHaveAnEmptyCell: _numberOfCards >= 2 })}
    </UpcominSessionWrapper>
  )
}

const HideScrollbar = css`
  overflow-x: scroll;
  scroll-behavior: smooth;
  scrollbar-width: none;
  overscroll-behavior-x: contain;
  -ms-overflow-style: none;

  &::-webkit-scrollbar {
    width: 0;
    height: 0;
  }
`

const UpcomingSessionGrid = styled.ul<{
  numberOfColumns: number
  totalPossibleNumberofCards: number
  withForcedEmptyCell: boolean
}>`
  --otherReelWidth: calc(
    ((100% + 24px) - (${p => p.totalPossibleNumberofCards - 1} * 16px)) / ${p => p.totalPossibleNumberofCards}
  );
  display: grid;
  grid-template-columns: repeat(
    ${p => p.numberOfColumns},
    max(${contentCardWidthPx}px, calc(var(--otherReelWidth) + 73px))
  );

  grid-auto-flow: column;
  width: 100%;
  overflow-x: auto;
  overflow-y: hidden;

  padding-inline: 12px;

  li:last-child {
    border-right: 1px solid transparent;
  }

  ${HideScrollbar}
`

const ViewAllContainer = styled(View)`
  z-index: 1;
`
export const UpcomingSessionsSection: React.FC<{ upcomingSessions: UpcomingSessions[] }> = ({
  upcomingSessions,
}) => {
  const { t } = useTranslation()
  const dispatch = useDispatch()

  const groupedUpcomingSessions = Object.entries(
    _.groupBy(upcomingSessions, s => {
      return DateTime.fromJSDate(s.session.startTime).toLocaleString({
        year: 'numeric',
        month: '2-digit',
        day: '2-digit',
      })
    })
  )

  const sortedUpcomingSessions = _.sortBy(groupedUpcomingSessions, it => it[1][0]?.session.startTime)

  const logViewAllButtonClick = (): void => {
    void dispatch(
      homePageViewAllClickedLogger({
        section: 'Upcoming sessions',
      })
    )
  }

  const scrollContainerRef = useRef<HTMLUListElement>(null)
  const { isScrolledToLeft, isScrolledToRight, updateScroll } = useIsScrolledToLeftOrRight(scrollContainerRef)

  return (
    <UpcomingSectionSection>
      <View marginBottom='24' marginLeft='40'>
        <Icon color='foreground/secondary' iconId='calendar' />
        <Text bold as='h2' color='foreground/secondary'>
          {t('home.upcoming-sessions.title')}
        </Text>
      </View>

      <UpcomingSessionCardSection items={sortedUpcomingSessions}>
        {({ numberOfCards, canHaveAnEmptyCell }) => (
          <>
            <UpcomingSessionGrid
              onScroll={updateScroll}
              ref={scrollContainerRef}
              numberOfColumns={sortedUpcomingSessions.length}
              totalPossibleNumberofCards={numberOfCards}
              withForcedEmptyCell={canHaveAnEmptyCell}
            >
              {sortedUpcomingSessions.map(it => {
                const firstUpcoming = it[1][0]

                if (firstUpcoming === undefined) {
                  return null
                }

                return (
                  <UpcomingSessionContent
                    date={firstUpcoming.session.startTime}
                    sessions={it[1]}
                    key={firstUpcoming.session.id}
                  />
                )
              })}
            </UpcomingSessionGrid>
            <ScrollButtons
              isScrolledToLeft={isScrolledToLeft}
              isScrolledToRight={isScrolledToRight}
              scrollContainerRef={scrollContainerRef}
            />
            <ViewAllContainer marginLeft='40'>
              <Button
                href='/browse?s=closest-in-time&c=event'
                icon='arrow--right'
                decoratorPosition='right'
                variant='secondary'
                onClick={logViewAllButtonClick}
              >
                {t('dictionary.view-all')}
              </Button>
            </ViewAllContainer>
          </>
        )}
      </UpcomingSessionCardSection>
    </UpcomingSectionSection>
  )
}
