import { useSetAtom } from 'jotai'
import React, { useEffect } from 'react'
import { useDevelopmentSnackbar } from 'sierra-client/hooks/use-debug-notif'
import { narrationDataChannel } from 'sierra-client/realtime-data/channels/narration-channel'
import { useCachedQuery } from 'sierra-client/state/api'
import { isGeneratingAvatarsAtom } from 'sierra-client/views/flexible-content/editor/content-sidebar/is-generating-avatars-atom'
import { DestinationTypeApi, FileIdNarrationMap, NarrationSettings } from 'sierra-domain/api/author-v2'
import { CreateContentId, NanoId12 } from 'sierra-domain/api/nano-id'
import { ContentType } from 'sierra-domain/collaboration/types'
import { FileId } from 'sierra-domain/flexible-content/identifiers'
import { XRealtimeAuthorGetGeneratedNarrationState } from 'sierra-domain/routes'

export type NarrationState = {
  narrations: FileIdNarrationMap
  settings: NarrationSettings
}

const SyncNarrationState = ({
  contentId,
  setNarrationState,
  destinationTypeApi,
}: {
  contentId: CreateContentId
  setNarrationState: (narrationState: NarrationState) => void
  destinationTypeApi: DestinationTypeApi
}): null => {
  const { reportInDev } = useDevelopmentSnackbar()

  const refetchDataRef = React.useRef<(() => void) | undefined>(undefined)

  const { isReceivingData } = narrationDataChannel.useChannel({
    channelId: contentId,
    callback: ({ event, data }) => {
      switch (event) {
        case 'settings-usage-updated':
          reportInDev(`Narrations - Minutes used: ${data.usage.minutesUsed}`, { variant: 'info' })
          refetchDataRef.current?.()
          return
        case 'narration-updated':
          reportInDev(`Narrations - new state in ${data.fileId}: ${data.narration.type}`, {
            variant: 'info',
          })
          refetchDataRef.current?.()
          return
        default:
          return
      }
    },
  })

  const query = useCachedQuery(
    XRealtimeAuthorGetGeneratedNarrationState,
    { contentId, destinationTypeApi },
    // Once we are connected to ably we fetch the state from the backend.
    // This means we don't risk missing new updates that come in while the
    // state is being fetched
    {
      enabled: isReceivingData,
      // Poll when we detect some content is loading, otherwise the backend
      // will not be able to ingest the videos from synthesia if it finished in
      // the background
      refetchInterval: ({ state: { data } }) =>
        data !== undefined &&
        Object.values(data.narrations).some(
          narrations => narrations?.some(narration => narration.type === 'loading')
        )
          ? 2000
          : undefined,
    }
  )

  refetchDataRef.current = query.refetch
  const narrations = query.data?.narrations
  const settings = query.data?.settings

  useEffect(() => {
    if (narrations && settings) {
      setNarrationState({ narrations, settings })
    }
  }, [narrations, setNarrationState, settings])

  const setIsGeneratingAvatarsAtom = useSetAtom(isGeneratingAvatarsAtom)
  useEffect(() => {
    setIsGeneratingAvatarsAtom(() => {
      const newState: Record<FileId, boolean> = {}
      for (const [fileId, values] of Object.entries(narrations ?? {})) {
        const isLoading = values?.some(narration => narration.type === 'loading') ?? false
        newState[fileId as FileId] = isLoading
      }
      return newState
    })
  }, [narrations, setIsGeneratingAvatarsAtom])

  return null
}

type ExtendedDestinationTypeApi =
  | { type: 'card-narration'; contentType: ContentType }
  | { type: 'video-card' }
  | { type: 'video-block'; slateElementId: NanoId12 }

export const NarrationStateSync: React.FC<{
  contentId: CreateContentId
  canEdit: boolean
  setNarrationState: (narrationState: NarrationState) => void
  destinationTypeApi: ExtendedDestinationTypeApi
}> = ({ contentId, canEdit, setNarrationState, destinationTypeApi }) => {
  const isEnabledInContentType =
    destinationTypeApi.type === 'card-narration' ? destinationTypeApi.contentType === 'self-paced' : true
  const enabled = isEnabledInContentType && canEdit

  if (!enabled) return null

  return (
    <SyncNarrationState
      contentId={contentId}
      setNarrationState={setNarrationState}
      destinationTypeApi={destinationTypeApi}
    />
  )
}
