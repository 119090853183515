import { useAtomValue } from 'jotai'
import React, { useMemo } from 'react'
import { useCurrentUserId } from 'sierra-client/api/hooks/use-user'
import { RecommendationCard } from 'sierra-client/components/recommendations/recommendation-card'
import { ExternalTrainigs } from 'sierra-client/features/external-trainings'
import { GlobalSidebarOpenAtom } from 'sierra-client/features/global-sidebar'
import { useRecommendations } from 'sierra-client/hooks/use-recommendations'
import { useTranslation } from 'sierra-client/hooks/use-translation'
import { TranslationLookup } from 'sierra-client/hooks/use-translation/types'
import { prefetchCachedQuery, useCachedQuery } from 'sierra-client/state/api'
import { GridContainer, PageContainer } from 'sierra-client/views/workspace/components'
import { LearnContentTable } from 'sierra-client/views/workspace/learn/learn-content-table'
import {
  LearnInsightsCard,
  LearnInsightsCardSkeleton,
} from 'sierra-client/views/workspace/learn/learn-insights-card'
import { LearnEntity } from 'sierra-domain/api/entities'
import { LearnerContent } from 'sierra-domain/api/learn'
import { Recommendation } from 'sierra-domain/api/recommendations'
import { XRealtimeContentListLearnerContent } from 'sierra-domain/routes'
import { isDefined } from 'sierra-domain/utils'
import { Skeleton, Spacer, Text, View } from 'sierra-ui/primitives'
import { v2_breakpoint } from 'sierra-ui/theming/breakpoints'
import styled from 'styled-components'

const RecommendationContainer = styled.div`
  display: flex;
  flex-direction: column;
  gap: 1.5rem;
  grid-column: 1/-1;
  grid-row: span 2;

  @media (min-width: ${v2_breakpoint.desktop_small}) {
    grid-column: 1/9;
  }
`

const InsightsContainer = styled.div`
  display: flex;
  flex-direction: column;
  gap: 1.5rem;
  grid-column: 1/-1;
  grid-row: span 1;

  @media (min-width: ${v2_breakpoint.desktop_small}) {
    grid-column: 9 / 13;
    grid-row: span 2;
    display: flex;
    align-items: stretch;
    flex-direction: column;
  }
`

function useLearnRecommendation(): Recommendation | undefined {
  const query = useRecommendations().data?.recommendations

  return useMemo(
    () =>
      query
        ?.map(it => {
          const entity = 'entity' in it.card ? it.card.entity : undefined
          const parseResult = LearnEntity.safeParse(entity)
          return parseResult.success || it.card.type === 'create-suggestion' ? it : undefined
        })
        .find(isDefined),
    [query]
  )
}

const applyEmptyTitleFallback = (t: TranslationLookup, c: LearnerContent): LearnerContent => ({
  ...c,
  title: c.title === '' ? t('admin.author.no-title') : c.title,
})

export const prefetchLearnerContent = (): Promise<void> => {
  return prefetchCachedQuery(XRealtimeContentListLearnerContent, {})
}

function useLearnerContent(): { content: LearnerContent[]; isLoadingContent: boolean } {
  const { t } = useTranslation()

  const query = useCachedQuery(XRealtimeContentListLearnerContent, {})

  const data = query.data
  return useMemo(() => {
    if (data === undefined) return { content: [], isLoadingContent: true }
    else {
      const content = data.content.map(c => applyEmptyTitleFallback(t, c))
      return { content, isLoadingContent: false }
    }
  }, [data, t])
}

export const CardContainer = styled.div`
  width: 100%;
  aspect-ratio: 16 / 9.5;
  @media (max-width: ${v2_breakpoint.desktop_small}) {
    aspect-ratio: unset;
    min-height: 560px;
  }
  height: auto;
`

const RecommendationCardSkeleton: React.FC = () => <Skeleton $radius={32} $width={'100%'} $height={'100%'} />

export const LearnPage: React.FC = () => {
  const { t } = useTranslation()

  const { content, isLoadingContent } = useLearnerContent()
  const recommendation = useLearnRecommendation()

  const sidebarOpen = useAtomValue(GlobalSidebarOpenAtom)

  const userIdQuery = useCurrentUserId()
  const userId = userIdQuery.data

  return (
    <PageContainer $sidebarOpen={sidebarOpen}>
      <GridContainer>
        <View justifyContent='space-between' wrap='wrap'>
          <Text bold size='large'>
            {t('dictionary.recommended')}
          </Text>
          <ExternalTrainigs userId={userId} />
        </View>
        <RecommendationContainer>
          <CardContainer>
            {isDefined(recommendation) ? (
              <RecommendationCard recommendation={recommendation} />
            ) : (
              <RecommendationCardSkeleton />
            )}
          </CardContainer>
        </RecommendationContainer>

        <InsightsContainer>
          {!isLoadingContent ? <LearnInsightsCard content={content} /> : <LearnInsightsCardSkeleton />}
        </InsightsContainer>
        <Spacer size='none' />
        <LearnContentTable content={content} isLoading={isLoadingContent} />
      </GridContainer>
    </PageContainer>
  )
}
