import _ from 'lodash'
import { YDocIntegrityChecker } from 'sierra-client/collaboration/ydoc-integrity-checker'
import { TypedEventEmitter } from 'sierra-client/lib/typed-event-emitter'
import { UpdateHash, hashUpdate } from 'sierra-domain/base-64'
import { RequestError } from 'sierra-domain/error'
import { nanoid12 } from 'sierra-domain/nanoid-extensions'
import * as Y from 'yjs'

type YDocSyncerState = {
  pendingLocalUpdates: Uint8Array[]
  currentlySyncingUpdates: Map<UpdateHash, Uint8Array>
  lastSyncAt: number | undefined
}

export type YDocSyncerStatus = { type: 'idle' | 'syncing'; lastSyncAt: number | undefined }

function isSynced(state: YDocSyncerState): boolean {
  return state.currentlySyncingUpdates.size === 0 && state.pendingLocalUpdates.length === 0
}

function stateToStatus(state: YDocSyncerState): YDocSyncerStatus {
  return {
    type: isSynced(state) ? 'idle' : 'syncing',
    lastSyncAt: state.lastSyncAt,
  }
}

function debug(...args: unknown[]): void {
  console.debug('[YDocSyncer]:', ...args)
}

export class YDocSyncer extends TypedEventEmitter<{
  /**
   * Emitted when syncer status changes.
   */
  statusChanged: (status: YDocSyncerStatus) => void
}> {
  private readonly remoteOrigin = `remote-origin-${nanoid12()}`
  private readonly yDoc: Y.Doc
  private readonly yDocIntegrityChecker: YDocIntegrityChecker
  private readonly throttledSyncLocalIntoRemote: _.DebouncedFunc<() => Promise<void>>
  private clearRetryTimeout: (() => void) | undefined

  private readonly state: YDocSyncerState = {
    pendingLocalUpdates: [],
    currentlySyncingUpdates: new Map(),
    lastSyncAt: undefined,
  }

  private readState = (): YDocSyncerState => {
    return this.state
  }

  private writeState = (update: (previous: YDocSyncerState) => void): void => {
    const isSyncedBefore = isSynced(this.state)
    const statusBefore = stateToStatus(this.state)

    // Update the state (and lastSyncAt if we just synced)
    update(this.state)
    const isSyncedAfter = isSynced(this.state)
    if (!isSyncedBefore && isSyncedAfter) {
      this.state.lastSyncAt = Date.now()
    }

    // Emit status change if it changed
    const statusAfter = stateToStatus(this.state)
    if (!_.isEqual(statusBefore, statusAfter)) {
      this.emit('statusChanged', statusAfter)
    }
  }

  private onLocalUpdate = (update: Uint8Array, origin?: unknown): void => {
    if (origin === this.remoteOrigin) {
      return
    }

    this.writeState(state => state.pendingLocalUpdates.push(update))
    void this.throttledSyncLocalIntoRemote()
  }

  onRemoteUpdates = (updates: Uint8Array[]): void => {
    this.yDoc.transact(() => {
      for (const update of updates) {
        Y.applyUpdate(this.yDoc, update, this.remoteOrigin)
      }
    }, this.remoteOrigin)
  }

  constructor({
    yDoc,
    syncIntoRemote,
    yDocIntegrityChecker,
    throttleMs = 200,
    retryBackoffMs = 250,
    onUnrecoverableError,
  }: {
    yDoc: Y.Doc
    syncIntoRemote: (update: Uint8Array) => Promise<null>
    yDocIntegrityChecker: YDocIntegrityChecker
    onUnrecoverableError: (error: string) => void
    throttleMs?: number
    /** How much to increase the delay by when retrying updates. */
    retryBackoffMs?: number
  }) {
    super()

    this.yDoc = yDoc

    this.yDocIntegrityChecker = yDocIntegrityChecker
    yDocIntegrityChecker.on('retryUpdate', this.onLocalUpdate)

    this.yDoc.on('update', this.onLocalUpdate)

    const lastFailure: { hash: string | undefined; numberOfFailures: number } = {
      hash: undefined,
      numberOfFailures: 0,
    }

    this.throttledSyncLocalIntoRemote = _.throttle(async () => {
      const pendingLocalUpdates = this.readState().pendingLocalUpdates
      if (pendingLocalUpdates.length === 0) {
        // Nothing to sync
        return
      }

      const update = Y.mergeUpdates(pendingLocalUpdates)
      const updateHash = hashUpdate(update)
      this.writeState(state => {
        state.pendingLocalUpdates = []
        state.currentlySyncingUpdates.set(updateHash, update)
      })

      try {
        await syncIntoRemote(update)
        this.yDocIntegrityChecker.validateUpdate(update)
      } catch (err) {
        if (err instanceof RequestError) {
          const isErrorCode = err.status >= 400 && err.status < 500
          if (isErrorCode) {
            // In case of bad requests or authentication issues we should not retry.
            onUnrecoverableError(`Failed to publish ydoc update with error: ${err.status}, ${err.message}`)
            throw err
          }
        }

        // We failed to sync the update, so add it back to pending
        // local updates and try again.
        this.writeState(state => {
          state.pendingLocalUpdates.push(update)
          state.currentlySyncingUpdates.delete(updateHash)
        })

        /**
         * If we get a failure for, for example a network issue, we want to retry the update with a delay.
         * This delay should increase linearly with each failure, so that we do not overwhelm the backend
         * if many clients fail due to, for example, the server being overloaded.
         *
         * We use the hash of the update to track whether it has failed multiple times in a row, so that
         * if the user enters new input the retry cycle will start again.
         */
        const isRepeatFailure = lastFailure.hash === updateHash
        const { numberOfFailures } = lastFailure
        const delay = isRepeatFailure ? Math.min(5000, lastFailure.numberOfFailures * retryBackoffMs) : 0
        if (isRepeatFailure) {
          debug(`Failed to sync update ${numberOfFailures} times in a row. Retrying after ${delay}ms`)
        }
        lastFailure.numberOfFailures = isRepeatFailure ? numberOfFailures + 1 : 1
        lastFailure.hash = updateHash

        this.clearRetryTimeout?.()

        const retryTimer = setTimeout(() => {
          void this.throttledSyncLocalIntoRemote()
        }, delay)

        this.clearRetryTimeout = () => {
          clearTimeout(retryTimer)
        }
      } finally {
        // Regardless of success or failure, we are done syncing this update.
        this.writeState(state => {
          state.currentlySyncingUpdates.delete(updateHash)
        })
      }
    }, throttleMs)
  }

  destroy = (): void => {
    this.clearRetryTimeout?.()
    this.throttledSyncLocalIntoRemote.cancel()

    this.yDoc.off('update', this.onLocalUpdate)
    this.yDocIntegrityChecker.off('retryUpdate', this.onLocalUpdate)

    this.off()
  }
}
