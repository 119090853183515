import _, { random } from 'lodash'
import React, { useCallback, useContext, useMemo, useState } from 'react'
import { FCC } from 'sierra-client/types'
import { seededShuffle } from 'sierra-client/utils/seeded-shuffle'
import { SlateNodeWithType, transformNodes } from 'sierra-domain/slate-util'
import { SlateRootElement } from 'sierra-domain/v3-author'
import { Node } from 'slate'

type ShuffleElementsDataLayer = {
  shuffleQuestionsInDocument: (document: Node[]) => SlateRootElement[]
  reshuffle: (questionId: string) => void
  reshuffleAllQuestions: () => void
}

const ShuffleContext = React.createContext<ShuffleElementsDataLayer | undefined>(undefined)

export const ShuffleElementsProvider: FCC = ({ children }) => {
  const [seedIndex, setSeedIndex] = useState(random(0, 100))
  //This thing should be unique for each question so that retries don't affect other questions in the same general card
  //The corresponding entry will be added/changed only if the question gets retried. The whole record is reset on reload or rerender.
  const [seedIndexForQuestions, setSeedIndexForQuestions] = useState<{ [questionId: string]: number }>({})

  const setSeedIndexForQuestion = useCallback(
    (questionId: string): void => {
      const tempRecord = _.cloneDeep(seedIndexForQuestions)
      tempRecord[questionId] = random(0, 100)
      setSeedIndexForQuestions(tempRecord)
    },
    [seedIndexForQuestions, setSeedIndexForQuestions]
  )

  const getSeedIndex = useCallback(
    (Id: string): number => {
      return seedIndexForQuestions[Id] ?? seedIndex
    },
    [seedIndex, seedIndexForQuestions]
  )

  const reshuffle = useCallback(
    (questionId: string) => {
      setSeedIndexForQuestion(questionId)
    },
    [setSeedIndexForQuestion]
  )

  const reshuffleAllQuestions = useCallback(() => {
    setSeedIndex(random(0, 100))
  }, [])

  const shuffleQuestion = useCallback(
    (element: SlateNodeWithType): SlateNodeWithType => {
      if (!('type' in element && element.type === 'question-card')) return element

      const question = {
        ...element,
        children: [
          ...element.children.map(element => {
            if (!('type' in element)) return element

            switch (element.type) {
              case 'question-card-pick-the-best-option-body':
              case 'question-card-select-all-that-apply-body':
                return {
                  ...element,
                  children: seededShuffle(element.children, String(getSeedIndex(element.id)) + element.id),
                }
              default:
                return element
            }
          }),
        ],
      }
      return question
    },
    [getSeedIndex]
  )

  const shuffleQuestionsInDocument = useCallback(
    (document: Node[]): SlateRootElement[] => {
      const visibleNodes: SlateRootElement[] = []

      for (const node of document) {
        const slateParse = SlateRootElement.safeParse(node)

        if (slateParse.success) {
          // const element = slateParse.data
          // const shuffledElement = shuffleQuestion(element)
          visibleNodes.push(slateParse.data)
        }
      }

      return transformNodes(visibleNodes, shuffleQuestion)
    },
    [shuffleQuestion]
  )

  const value = useMemo(() => {
    return { shuffleQuestionsInDocument, reshuffle, reshuffleAllQuestions }
  }, [reshuffle, reshuffleAllQuestions, shuffleQuestionsInDocument])

  return <ShuffleContext.Provider value={value}>{children}</ShuffleContext.Provider>
}

export const useQuestionShuffle = (): ShuffleElementsDataLayer => {
  const selfPacedShuffle = useContext(ShuffleContext)

  if (!selfPacedShuffle) {
    throw new Error('Question card data not provided')
  }

  return selfPacedShuffle
}
