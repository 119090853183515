import { createSelector } from '@reduxjs/toolkit'
import { createCachedSelector } from 're-reselect'
import {
  FlexibleContentCollaborator,
  FlexibleContentState,
  isFolder,
} from 'sierra-client/state/flexible-content/types'
import { RootState } from 'sierra-client/state/types'
import { selectUserId } from 'sierra-client/state/user/user-selector'
import { SelectionInfo } from 'sierra-client/views/flexible-content/editor/content-sidebar/multi-selection/types'
import { LiveSessionWithParticipants } from 'sierra-domain/api/admin'
import { CreateContentId, LiveContentId } from 'sierra-domain/api/nano-id'
import { FileId, FolderId, NodeId } from 'sierra-domain/flexible-content/identifiers'
import { File, Folder, NodeMap } from 'sierra-domain/flexible-content/types'
import { guardWith } from 'sierra-domain/utils'

/**
 * Returns all subtree node Ids under a folder, folder id included.
 */
export const getSubtreeIds = (node: Folder, nodeMap: NodeMap): NodeId[] => {
  const ids: NodeId[] = [node.id]

  for (const nodeId of node.nodeIds) {
    const childNode = nodeMap[nodeId]
    if (!childNode) continue

    if (childNode.type === 'folder') {
      const subTreeIds = getSubtreeIds(childNode, nodeMap)
      ids.push(...subTreeIds)
    } else {
      ids.push(childNode.id)
    }
  }

  return ids
}

const selectState = (state: RootState): FlexibleContentState => state.flexibleContent

export const selectFlexibleContent = createCachedSelector(
  [selectState, (state: unknown, createContentId: CreateContentId | undefined) => createContentId],
  (state, createContentId) => {
    return createContentId !== undefined ? state.flexibleContent[createContentId] : undefined
  }
)((state, createContentId) => createContentId ?? '')

export const selectFlexibleContentIsLoaded = createSelector(
  selectFlexibleContent,
  content => content !== undefined
)

export const selectFlexibleContentNodes = createSelector(selectFlexibleContent, content => content?.nodeMap)

const selectFlexibleContentNode = createSelector(
  [
    selectFlexibleContent,
    (state: unknown, createLiveContentId: CreateContentId) => createLiveContentId,
    (state: unknown, createLiveContentId: CreateContentId, nodeId: NodeId | undefined) => nodeId,
  ],
  (content, createLiveContentId, nodeId) => (nodeId === undefined ? undefined : content?.nodeMap[nodeId])
)

export const selectFlexibleContentFolder = createSelector(selectFlexibleContentNode, node => {
  if (!node) return undefined
  if (node.type !== 'folder') throw new Error(`Expected node type to be 'folder' but received '${node.type}'`)
  return node
})

function buildFlatSelectionInfo(
  nodeMap: NodeMap,
  currentNodeId: NodeId,
  parentFolderId: FolderId = 'folder:root'
): SelectionInfo[] {
  const currentNode = nodeMap[currentNodeId]

  if (currentNode === undefined) {
    return []
  }

  if (currentNode.type === 'link') {
    return []
  }

  if (currentNode.type === 'file') {
    return [{ file: currentNode, parentFolderId }]
  }

  return currentNode.nodeIds.flatMap(id => buildFlatSelectionInfo(nodeMap, id, currentNode.id))
}

export const selectFlatSelectionInfoList = createSelector(selectFlexibleContent, flexibleContent => {
  if (flexibleContent === undefined) return []
  return buildFlatSelectionInfo(flexibleContent.nodeMap, 'folder:root')
})

export const selectFlexibleContentFile = createSelector(selectFlexibleContentNode, node => {
  if (!node) return undefined
  if (node.type !== 'file') throw new Error(`Expected node type to be 'file' but received '${node.type}'`)
  return node
})

export const getAssessmentPassingCriteria = (file: File): number => {
  if (file.data.type !== 'assessment-card') {
    throw new Error(`Expected node type to be 'assessment-card' but received '${file.data.type}'`)
  }

  return file.data.settings.passingCriteria
}

export const getAssessmentAllowRetry = (file: File): boolean => {
  if (file.data.type !== 'assessment-card') {
    throw new Error(`Expected node type to be 'assessment-card' but received '${file.data.type}'`)
  }

  return file.data.settings.allowRetry ?? true
}

export const getAssessmentHideCorrectAnswers = (file: File): boolean => {
  if (file.data.type !== 'assessment-card') {
    throw new Error(`Expected node type to be 'assessment-card' but received '${file.data.type}'`)
  }

  return file.data.settings.hideCorrectAnswers ?? false
}

export const getAssessmentTimeLimit = (file: File): number | undefined | 'wrong-card-type' => {
  if (file.data.type !== 'assessment-card') {
    return 'wrong-card-type'
  }

  return file.data.settings.timeLimit ?? undefined
}

export const selectFlexibleContentChildIds = createSelector(
  [selectFlexibleContentNodes, selectFlexibleContentFolder],
  (nodeMap, node) => (!nodeMap || !node ? [] : getSubtreeIds(node, nodeMap))
)

export const selectSubNodeIds = createSelector(
  [selectFlexibleContentNodes, selectFlexibleContentNode],
  (nodeMap, node) =>
    !nodeMap || !node || node.type !== 'folder'
      ? []
      : getSubtreeIds(node, nodeMap).filter(id => id !== node.id)
)

const selectNodeIds = createSelector([selectFlexibleContentNodes], nodeMap => {
  if (nodeMap === undefined) return []
  const rootFolder = nodeMap['folder:root']
  if (!guardWith(Folder, rootFolder)) return []

  return getSubtreeIds(rootFolder, nodeMap)
})

export const selectFiles = createSelector([selectFlexibleContentNodes], (nodeMap): File[] => {
  if (nodeMap === undefined) return []
  const rootFolder = nodeMap['folder:root']
  if (!guardWith(Folder, rootFolder)) return []

  return getSubtreeIds(rootFolder, nodeMap)
    .map(id => nodeMap[id])
    .filter((node): node is File => node?.type === 'file')
})

export const selectFileIds = createSelector([selectNodeIds], nodeIds => {
  return nodeIds.filter(guardWith(FileId))
})

export const selectNodeParentFolder = createCachedSelector(
  [selectFlexibleContent, selectFlexibleContentNode],
  (content, node): Folder | undefined => {
    if (node === undefined || content?.nodeMap === undefined) {
      return undefined
    }

    return Object.values(content.nodeMap)
      .filter(isFolder)
      .find(parent => parent.nodeIds.includes(node.id))
  }
)((state, flexibleContentId, nodeId) => `${flexibleContentId}:${nodeId}`)

export const selectNodeParentFolderId = createCachedSelector(
  selectNodeParentFolder,
  (folder): FolderId | undefined => folder?.id
)((state, flexibleContentId, nodeId) => `${flexibleContentId}:${nodeId}`)

// Live Sessions

const selectFlexibleContentLiveSessionsMap = createSelector(selectState, slice => slice.folderLiveSessions)
export const selectFlexibleContentLiveSessions = createCachedSelector(
  [
    selectFlexibleContentLiveSessionsMap,
    (state: unknown, flexibleContentId: LiveContentId) => flexibleContentId,
  ],
  (contentMap, flexibleContentId): LiveSessionWithParticipants[] => contentMap[flexibleContentId] ?? []
)((contentMap, contentId) => contentId)

export const selectParentFolders = createCachedSelector(
  [selectFlexibleContentNodes, selectFlexibleContentNode],
  (nodeMap, node): FolderId[] => {
    const family: FolderId[] = []

    if (nodeMap === undefined || node === undefined) return family

    const folders = Object.values(nodeMap).filter(isFolder)
    let parent: Folder | undefined

    do {
      parent = folders.find(folder => folder.nodeIds.includes(parent === undefined ? node.id : parent.id))
      if (parent !== undefined) family.push(parent.id)
    } while (parent !== undefined)

    return family
  }
)((state, flexibleContentId, nodeId) => `${flexibleContentId}:${nodeId}`)

const selectAllCollaborators = createSelector(selectState, slice => slice.collaborators)

const selectAllCollaboratorStates = createSelector(selectAllCollaborators, states =>
  Object.values(states).flat()
)
export const selectNodeCollaborators = createCachedSelector(
  [selectAllCollaboratorStates, (ignore: unknown, nodeId: NodeId) => nodeId],
  (states: FlexibleContentCollaborator[], nodeId) =>
    states.filter((it): boolean => it.nodeId === nodeId).map(({ user }) => user)
)((ignore, nodeId) => nodeId)

export const selectCurrentChatTypers = createSelector(
  [selectAllCollaboratorStates, selectUserId],
  (states, currentUserId) =>
    states.flatMap(({ user, isWritingToChatThreadId }) => {
      if (user.uuid === currentUserId) return []
      if (isWritingToChatThreadId === undefined) return []

      return [{ userId: user.uuid, threadId: isWritingToChatThreadId }]
    })
)

export const selectContentHasFiles = createSelector([selectFlexibleContent], content => {
  const nodeIds = content !== undefined ? Object.keys(content.nodeMap) : []
  return nodeIds.some(id => id.startsWith('file'))
})

export const selectClosestFileIdWhenDeleting = createCachedSelector(
  [
    selectFlexibleContent,
    (state: RootState, createContentId: CreateContentId) => selectFileIds(state, createContentId),
    (state: RootState, createContentId: CreateContentId, parentFolderId: FolderId) =>
      selectFlexibleContentFolder(state, createContentId, parentFolderId),
    (state: RootState, createContentId: CreateContentId, parentFolderId: FolderId, fileId: FileId) => fileId,
  ],
  (content, allFileIdsInCourse, currentFolder, fileId) => {
    if (currentFolder === undefined) {
      return undefined
    }

    const fileIdsInCurrentFolder = currentFolder.nodeIds.filter(guardWith(FileId))
    const indexInCurrentFolder = fileIdsInCurrentFolder.indexOf(fileId)
    const indexInCourse = allFileIdsInCourse.indexOf(fileId)

    if (indexInCurrentFolder === -1 || indexInCourse === -1) {
      return undefined
    }

    const nextFileId =
      // Try going to the previous file in the current folder if it exists...
      fileIdsInCurrentFolder[indexInCurrentFolder - 1] ??
      // ...or the next file in the current folder...
      fileIdsInCurrentFolder[indexInCurrentFolder + 1] ??
      // ...or the previous file in the full course...
      allFileIdsInCourse[indexInCourse - 1] ??
      // ...or the next file in the full course
      allFileIdsInCourse[indexInCourse + 1]

    return nextFileId
  }
)((state, createContentId, nodeId) => `${createContentId}:${nodeId}`)
