import { fonts } from 'sierra-ui/theming/fonts'
/* eslint-disable react/forbid-component-props */
import { CursorEditor } from '@slate-yjs/core'
import { CursorOverlayData, useRemoteCursorOverlayPositions } from '@slate-yjs/react'
import { useAtomValue } from 'jotai'
import _ from 'lodash'
import React, { CSSProperties, PropsWithChildren, useEffect, useState } from 'react'
import { useEditorJotaiDocument } from 'sierra-client/editor/editor-jotai-context'
import { generationAtom } from 'sierra-client/state/generation'
import {
  GenerativeCaretContainer,
  GenerativeCaretInner,
  getCursorPosition,
} from 'sierra-client/views/v3-author/cursors/cursor-text-generation'
import { DisappearingSpan } from 'sierra-client/views/v3-author/cursors/disappearing-span'
import { HoverTarget } from 'sierra-client/views/v3-author/cursors/hover-target'
import { ReactEditor, useSlateStatic } from 'slate-react'
import styled from 'styled-components'

/**
 * Copied and modified from https://github.com/BitPhinix/slate-yjs/blob/next/examples/frontend/src/pages/RemoteCursorOverlay/Overlay.tsx
 */

type CaretProps = {
  position: CursorOverlayData<CursorData>['caretPosition']
  data: CursorData
}

export type CursorData = {
  name: string
  color: string
  userId?: string
}

const CaretContainer = styled(HoverTarget)`
  width: 0.125rem;
  position: absolute;
  transition: all 100ms;
`

const CaretInner = styled(DisappearingSpan)`
  position: absolute;
  border-radius: 2px 2px 2px 0;
  padding: 2px 4px;
  pointer-events: none;
  user-select: none;
  transform: translateY(-100%);
  font-size: 12px;
  font-weight: parseInt(${fonts.weight.medium});
  line-height: 1rem;
  letter-spacing: normal;
  color: white;
  white-space: nowrap;
  transition: all 100ms;
`

function Caret({ position, data }: CaretProps): JSX.Element {
  const caretStyle: CSSProperties = {
    ...position,
    background: data.color,
  }

  const labelStyle: CSSProperties = {
    transform: 'translateY(-100%)',
    background: data.color,
  }

  const [shouldShowName, setShouldShowName] = useState(false)

  return (
    <CaretContainer onHover={setShouldShowName} style={caretStyle}>
      <CaretInner $shouldShowName={shouldShowName} style={labelStyle}>
        {data.name}
      </CaretInner>
    </CaretContainer>
  )
}

const Selection = styled.div`
  position: absolute;
  pointer-events: none;
`

const convertToBackground = (hex: string): string => {
  const match = hex.match(/\w\w/g)
  if (match === null) return 'transparent'
  const [r, g, b] = match.map(x => parseInt(x, 16))
  return `rgba(${r ?? 0},${g ?? 0},${b ?? 0},${0.25})`
}

function RemoteSelection({
  data,
  selectionRects,
  caretPosition,
}: CursorOverlayData<CursorData>): JSX.Element | null {
  if (!data || _.isEqual(data, {})) {
    return null
  }

  const { color } = data
  const selectionStyle: CSSProperties = {
    // Add a opacity to the background color
    backgroundColor: convertToBackground(color),
  }

  const drawRects = _.uniqBy(
    selectionRects.map(position => ({
      key: JSON.stringify(position),
      ...position,
    })),
    'key'
  )

  return (
    <React.Fragment>
      {drawRects.map(position => {
        return <Selection style={{ ...selectionStyle, ...position }} key={position.key} />
      })}
      {caretPosition && <Caret position={caretPosition} data={data} />}
    </React.Fragment>
  )
}

type RemoteCursorsProps = PropsWithChildren<{
  containerRef: React.RefObject<HTMLDivElement>
}>

function GenerativeCursor(): JSX.Element | null {
  const editor = useSlateStatic()
  const generationState = useAtomValue(generationAtom)
  const generationPointRef = generationState?.generationPointRef

  const [position, setPosition] = useState({ x: 0, y: 0 })

  useEffect(() => {
    if (generationPointRef === undefined) return

    let cancelled = false

    const updateCursorPosition = (): void => {
      const currentPoint = generationPointRef.current

      if (currentPoint === null) return

      try {
        const domRange = ReactEditor.toDOMRange(editor, { anchor: currentPoint, focus: currentPoint })

        const newPosition = getCursorPosition(domRange)

        setPosition(newPosition)
      } catch (e) {
        // It's not uncommon for toDOMRange to fail. We'll ignore it and hope it works
        // on the next tick.
      }
    }

    function loop(): void {
      if (cancelled) return

      updateCursorPosition()

      requestAnimationFrame(loop)
    }

    loop()

    return () => {
      cancelled = true
    }
  }, [generationPointRef, editor])

  if (generationPointRef === undefined) {
    return null
  }

  return (
    <GenerativeCaretContainer style={{ left: position.x, top: position.y }} contentEditable={false}>
      <GenerativeCaretInner style={{ left: position.x, top: position.y }} contentEditable={false}>
        {'Assistant'}
      </GenerativeCaretInner>
    </GenerativeCaretContainer>
  )
}

function TextCursors({
  containerRef,
}: {
  containerRef: React.RefObject<HTMLDivElement>
}): JSX.Element | null {
  const [cursors, refresh] = useRemoteCursorOverlayPositions<CursorData>({ containerRef })
  const document = useEditorJotaiDocument()
  useEffect(() => {
    refresh()
  }, [document, refresh])

  return (
    <>
      {cursors.map(cursor => (
        <RemoteSelection key={cursor.clientId} {...cursor} />
      ))}
    </>
  )
}

export function CursorOverlay({ containerRef, children }: RemoteCursorsProps): JSX.Element {
  const editor = useSlateStatic()
  return (
    <>
      {children}
      <GenerativeCursor />
      {CursorEditor.isCursorEditor(editor) && <TextCursors containerRef={containerRef} />}
    </>
  )
}
