import { ActionModal } from 'sierra-client/components/common/modals/action-modal'
import { NotificationContext } from 'sierra-client/context/notification-context'
import { useTranslation } from 'sierra-client/hooks/use-translation'

export const SanaUpdatedModal = (): JSX.Element => {
  const { t } = useTranslation()
  const [{ isSanaUpdated }] = NotificationContext.useGlobalState()

  return (
    <ActionModal
      open={isSanaUpdated}
      onClose={() => {}}
      title={t('sana-updated-modal.heading')}
      primaryAction={() => window.location.reload()}
      primaryActionLabel={t('sana-updated-modal.button')}
    >
      {t('sana-updated-modal.message')}
    </ActionModal>
  )
}
