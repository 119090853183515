import Mention, { MentionOptions } from '@tiptap/extension-mention'
import { Collaborator } from 'sierra-client/state/author-course-settings/types'

export const CustomMention = Mention.extend<MentionOptions<Collaborator, { collaborator: Collaborator }>>({
  addAttributes() {
    return {
      uuid: {
        default: undefined,
        parseHTML: element => element.getAttribute('data-mention-uuid'),
        renderHTML: attributes => {
          if (attributes.uuid === undefined) return {}

          return {
            'data-mention-uuid': attributes.uuid,
          }
        },
      },
      role: {
        default: undefined,
        parseHTML: element => element.getAttribute('data-mention-role'),
        renderHTML: attributes => {
          if (attributes.role === undefined) return {}

          return {
            'data-mention-role': attributes.role,
          }
        },
      },
      label: {
        default: undefined,
        parseHTML: element => element.getAttribute('data-label'),
        renderHTML: attributes => {
          if (attributes.label === undefined) return {}

          return {
            'data-label': attributes.label,
          }
        },
      },
    }
  },
})
