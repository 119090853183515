import { atom, useAtomValue } from 'jotai'
import React from 'react'
import { rangeOfNode } from 'sierra-client/views/v3-author/queries'
import { BaseRange, NodeEntry, Range, Text } from 'slate'
import { useSlateStatic } from 'slate-react'
import styled from 'styled-components'

type Decorate = (entry: NodeEntry) => Range[]

export const previewColorAtom = atom<string | undefined>(undefined)

type RangeWithColor = Range & { color: string }
function decorateTextColors(entry: NodeEntry, selection: BaseRange, color: string): RangeWithColor[] {
  const [anchor, focus] = rangeOfNode(entry)
  const overlap = Range.intersection(selection, { anchor, focus })
  if (overlap === null || Range.isCollapsed(overlap)) return []

  return [
    {
      ...overlap,
      color,
    },
  ]
}

export function useTextColors(): Decorate {
  const editor = useSlateStatic()
  const previewColor = useAtomValue(previewColorAtom)

  const decorate: Decorate = React.useCallback(
    ([node, path]) => {
      if (previewColor === undefined) return []
      if (!Text.isText(node)) return []
      const { selection } = editor
      if (selection === null || Range.isCollapsed(selection)) return []

      return decorateTextColors([node, path], selection, previewColor)
    },
    [editor, previewColor]
  )
  return decorate
}

export const Color = styled.span<{ $color: string }>`
  color: ${p => p.$color};
`
