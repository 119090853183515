import DOMPurify from 'dompurify'
import fuzzysort from 'fuzzysort'
import _ from 'lodash'
import { DateTime } from 'luxon'
import React, { useCallback, useMemo } from 'react'
import { useLiveSessionContext } from 'sierra-client/components/liveV2/contexts/live-session-data'
import { TranscriptionLoading } from 'sierra-client/components/liveV2/summary/loading'
import { RecordingInfo } from 'sierra-client/components/liveV2/summary/types'
import { useDebouncedAndLiveState } from 'sierra-client/hooks/use-debounced-state'
import { useIsDebugMode } from 'sierra-client/hooks/use-is-debug-mode'
import { useTranslation } from 'sierra-client/hooks/use-translation'
import { useCachedQuery, useTypedMutation } from 'sierra-client/state/api'
import { useSelector } from 'sierra-client/state/hooks'
import { selectIsFacilitator } from 'sierra-client/state/live-session/selectors'
import { useUserLegacy } from 'sierra-client/state/users/hooks'
import { LiveSessionTranscriptionWithRelativeTimes } from 'sierra-domain/api/live-session'
import {
  XRealtimeAuthorLiveSessionsUpdateLiveSettings,
  XRealtimeContentGetLiveSession,
} from 'sierra-domain/routes'
import { getUserName } from 'sierra-domain/utils'
import { Icon, Tooltip } from 'sierra-ui/components'
import { InputPrimitive, Spacer, Text, View } from 'sierra-ui/primitives'
import { token } from 'sierra-ui/theming'
import { fonts } from 'sierra-ui/theming/fonts'
import styled, { css } from 'styled-components'

const Timestamp = styled(View)`
  gap: 4px;
  font-variant-numeric: tabular-nums;
  white-space: nowrap;
  padding: 2px 8px;
  border-radius: 100px;
  background-color: ${token('surface/default').shift(0.1)};
  color: ${token('foreground/secondary')};

  transition: all 0.1s cubic-bezier(0.25, 0.5, 0.25, 1);
`

const Username = styled(Text)``

const SegmentHeadline = styled.div`
  display: flex;
  flex-direction: row;
  gap: 8px;
  flex-grow: 1;
`

const SegmentContent = styled(View)`
  color: ${token('foreground/primary').opacity(0.4)};
  transition: all 0.1s cubic-bezier(0.25, 0.5, 0.25, 1);
`

const StyledSegment = styled.div`
  display: flex;
  flex-direction: row;
  gap: 2rem;
  align-items: flex-start;

  margin-bottom: 1.25rem;
  border-radius: ${p => p.theme.borderRadius.regular};
  ${fonts.body.small};

  ${p =>
    p.onClick !== undefined &&
    css`
      &:hover {
        cursor: pointer;

        ${SegmentContent} {
          color: ${token('foreground/secondary')};
        }

        ${Timestamp} {
          background-color: ${token('surface/default').shift(0.2)};
          color: ${token('foreground/secondary')};
        }
      }
    `}
  mark {
    position: relative;
    font-weight: normal;
    color: ${token('foreground/primary')};
    background-color: ${token('surface/default').opacity(0.1)};
    padding: 2px 3px;
    margin: -2px -3px;
    border-radius: 4px;
  }
`

type TranscriptionItem = {
  type: 'transcription'
  segment: LiveSessionTranscriptionWithRelativeTimes
  highlightedText?: string | null
  timestamp: number
}

type RecordingInfoItem = {
  type: 'recording'
  event: 'start' | 'end'
  recording: RecordingInfo
  timestamp: number
}

type ListItem = TranscriptionItem | RecordingInfoItem

function getTimestampString(time: DateTime): string {
  // The relative time calculations are weird and therefore might result in an extra hour
  const normalizedTime = time.hour > 0 ? time.minus({ hours: 1 }) : time
  const timeFormatted = normalizedTime.toFormat('HH:mm:ss')
  if (timeFormatted.startsWith('00:')) {
    return timeFormatted.slice(3)
  }
  return timeFormatted
}

const TranscriptionSegment: React.FC<{
  segment: LiveSessionTranscriptionWithRelativeTimes
  highlightedText?: string | null
  onClick?: (segment: LiveSessionTranscriptionWithRelativeTimes) => void
}> = ({ segment, highlightedText, onClick }) => {
  const startTime =
    segment.relativeStartTime !== undefined ? DateTime.fromSeconds(segment.relativeStartTime) : undefined
  const user = useUserLegacy(segment.userId)
  const debug = useIsDebugMode()

  return (
    <>
      <StyledSegment
        onClick={
          segment.isInRecording
            ? () => {
                onClick?.(segment)
              }
            : undefined
        }
      >
        {startTime !== undefined && (
          <Timestamp>
            <Text size='micro' color='currentColor'>
              {getTimestampString(startTime)}
            </Text>
          </Timestamp>
        )}
        <SegmentContent direction='column'>
          <SegmentHeadline>
            {user !== undefined && (
              <>
                <Username size='small' color='currentColor' bold>
                  {getUserName(user)}
                </Username>
              </>
            )}
            {debug === true && segment.audioChunkId !== undefined && <>({segment.audioChunkId})</>}
          </SegmentHeadline>
          {highlightedText === null || highlightedText === undefined ? (
            segment.text
          ) : (
            <div dangerouslySetInnerHTML={{ __html: DOMPurify.sanitize(highlightedText) }} />
          )}
        </SegmentContent>
      </StyledSegment>
    </>
  )
}

const TranscriptionDivider = styled.div`
  position: relative;
  background-color: ${token('form/border/3')};
  height: 1px;
  margin: 40px 0;
`

const TranscriptionEvent = styled(Text).attrs({ size: 'micro', color: 'currentColor' })`
  position: relative;
  left: 70px;
  padding: 2px 8px;
  border-radius: 16px;
  transform: translateY(-50%);
  background-color: ${token('form/border/3')};
  display: inline-block;
`

const RecordingItem = ({ recordingItem }: { recordingItem: RecordingInfoItem }): JSX.Element => {
  return (
    <TranscriptionDivider>
      <TranscriptionEvent>
        {recordingItem.event === 'start' ? 'Recording started' : 'Recording ended'}
      </TranscriptionEvent>
    </TranscriptionDivider>
  )
}

const NoResultsFound: React.FC = () => {
  return (
    <>
      <View grow alignItems='center' justifyContent='center'>
        <Text size='small' color='currentColor'>
          No matching text in transcription
        </Text>
      </View>
    </>
  )
}

const TranscriptionSearchResults: React.FC<{
  listItems: ListItem[]
  onSegmentClick?: (segment: LiveSessionTranscriptionWithRelativeTimes) => void
}> = ({ listItems, onSegmentClick }) => {
  return (
    <>
      {listItems.map((item, index) => {
        switch (item.type) {
          case 'transcription':
            return (
              <TranscriptionSegment
                key={index}
                onClick={onSegmentClick}
                segment={item.segment}
                highlightedText={item.highlightedText}
              />
            )
          case 'recording':
            return <RecordingItem recordingItem={item} />
        }
      })}
    </>
  )
}

const PlaceholderText = styled(Text)`
  color: ${token('foreground/muted')};
`

const HideTranscriptionButton = styled.button`
  background-color: transparent;
  display: flex;
  flex-direction: row;
  gap: 8px;
  align-items: center;
  cursor: pointer;
  color: ${token('foreground/muted')};
  transition: 100ms;
  transition-property: color;
  &:hover {
    color: ${token('foreground/secondary')};
  }
`

const HiddenTranscriptionBox = styled(View).attrs({
  alignItems: 'center',
  justifyContent: 'center',
  direction: 'column',
})`
  background-color: ${token('surface/soft')};
  border-radius: 16px;
  padding-block: 48px;
  padding-inline: 210px;
`

export const SearchableTranscript = ({
  transcription,
  recordings,
  onSegmentClick,
}: {
  onSegmentClick: (segment: LiveSessionTranscriptionWithRelativeTimes) => void
  transcription?: LiveSessionTranscriptionWithRelativeTimes[]
  recordings?: RecordingInfo[]
}): JSX.Element => {
  const [debouncedSearchTerm, liveSearchTerm, setSearchTerm] = useDebouncedAndLiveState('', { wait: 500 })
  const { t } = useTranslation()

  const liveSession = useLiveSessionContext()

  const liveSessionQueryResult = useCachedQuery(XRealtimeContentGetLiveSession, {
    liveSessionId: liveSession.liveSessionId,
  })

  const isFacilitator = useSelector(selectIsFacilitator)

  const updateSettingsMutation = useTypedMutation(XRealtimeAuthorLiveSessionsUpdateLiveSettings)

  const transcribeSession = liveSessionQueryResult.data?.data.transcribeSession

  const isHidingTranscription = useMemo(() => {
    return liveSessionQueryResult.data?.data.hideTranscription ?? liveSession.data.hideTranscription
  }, [liveSession.data.hideTranscription, liveSessionQueryResult.data?.data.hideTranscription])

  const onToggleHideTranscriptionClick = useCallback(async () => {
    if (isFacilitator) {
      updateSettingsMutation.mutate(
        {
          liveSessionId: liveSession.liveSessionId,
          hideTranscription: !isHidingTranscription,
        },
        {
          onSuccess: () => {
            void liveSessionQueryResult.refetch()
          },
        }
      )
    }
  }, [
    isFacilitator,
    updateSettingsMutation,
    liveSession.liveSessionId,
    isHidingTranscription,
    liveSessionQueryResult,
  ])

  const filteredTranscriptions = useMemo(() => {
    if (transcription === undefined) return

    return fuzzysort.go(debouncedSearchTerm, transcription, {
      key: 'text',
      all: true,
      limit: debouncedSearchTerm === '' ? undefined : 10,
    })
  }, [transcription, debouncedSearchTerm])

  const listItems = useMemo<ListItem[] | undefined>(() => {
    if (filteredTranscriptions === undefined) return

    return _.chain<ListItem>(
      filteredTranscriptions.map(result => {
        let highlightedText = undefined
        try {
          highlightedText = fuzzysort.highlight(result, '<mark>', '</mark>')
        } catch (error) {
          // ignore
        }
        return {
          type: 'transcription',
          segment: result.obj,
          timestamp: result.obj.relativeStartTime ?? 0,
          highlightedText,
        }
      })
    )
      .concat(
        recordings?.flatMap(recording => [
          {
            type: 'recording',
            event: 'start',
            recording,
            timestamp: recording.recordingRelativeStartTime ?? 0,
          },
          {
            type: 'recording',
            event: 'end',
            recording,
            timestamp: recording.recordingRelativeEndTime ?? 0,
          },
        ]) ?? []
      )
      .orderBy('timestamp')
      .value()
  }, [filteredTranscriptions, recordings])

  return (
    <>
      <View paddingBottom={'16'} justifyContent='space-between' direction='row'>
        <Text size='regular' bold>
          {t('dictionary.transcription')}
        </Text>
        {transcription !== undefined && isFacilitator && (
          <Tooltip
            title={
              isHidingTranscription
                ? t('live.recap.show-transcription-for-everybody')
                : t('live.recap.hide-transcription-for-everybody')
            }
          >
            <HideTranscriptionButton onClick={onToggleHideTranscriptionClick}>
              <Icon iconId={isHidingTranscription ? 'view' : 'view--off'} />
              <Text color={'currentColor'}>
                {isHidingTranscription ? t('dictionary.show') : t('dictionary.hide')}
              </Text>
            </HideTranscriptionButton>
          </Tooltip>
        )}
      </View>
      {transcribeSession === false ? (
        <PlaceholderText color='foreground/secondary' size='regular'>
          {t('live.recap.smart-features-not-enabled')}
        </PlaceholderText>
      ) : transcription === undefined ? (
        <PlaceholderText color='foreground/muted' size='regular'>
          {t('live.recap.no-transcription-available')}
        </PlaceholderText>
      ) : isHidingTranscription ? (
        <HiddenTranscriptionBox>
          <Icon iconId='view--off' size='size-24' color={'foreground/muted'} />
          <Text align='center' color={'foreground/muted'} bold>
            {t('live.recap.transcriptions-are-hidden')}
          </Text>
          <Text align='center' color={'foreground/muted'}>
            {t('live.recap.transcriptions-hidden-by-facilitator')}
          </Text>
        </HiddenTranscriptionBox>
      ) : (
        <>
          <InputPrimitive
            placeholder={`${t('dictionary.search')}...`}
            onChange={event => {
              setSearchTerm(event.target.value)
            }}
            value={liveSearchTerm}
          />
          <Spacer />
          {listItems?.length === 0 && debouncedSearchTerm !== '' && <NoResultsFound />}
          {listItems !== undefined && listItems.length > 0 ? (
            <TranscriptionSearchResults onSegmentClick={onSegmentClick} listItems={listItems} />
          ) : (
            <TranscriptionLoading />
          )}
        </>
      )}
    </>
  )
}
