import { configureStore } from '@reduxjs/toolkit'
import * as Sentry from '@sentry/react'
import { actionTransformer, stateTransformer } from 'sierra-client/error/redux-safe-forwarding-transformers'
import {
  logToNewRelicMiddleware,
  reportRejectedAsyncThunks,
  userTiming,
} from 'sierra-client/state/middleware'
import { reducer } from 'sierra-client/state/sana-reducer'

export const store = configureStore({
  reducer,
  middleware: gDM =>
    gDM({
      immutableCheck: false,
    }).concat(reportRejectedAsyncThunks, logToNewRelicMiddleware, userTiming),
  enhancers: getDefaultEnhancers =>
    getDefaultEnhancers().concat(Sentry.createReduxEnhancer({ actionTransformer, stateTransformer })),
})

export type AppStore = typeof store
export type AppDispatch = typeof store.dispatch
