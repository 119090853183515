import { Dispatch } from '@reduxjs/toolkit'
import { JSONContent } from '@tiptap/core'
import { Mention as MentionExtension } from '@tiptap/extension-mention'
import _ from 'lodash'
import { Mention, getMentions } from 'sierra-client/components/chat/mention/create-commenters-from-mentions'
import { tiptapContentToString } from 'sierra-client/components/chat/tiptap'
import { postWithUserErrorException } from 'sierra-client/state/api'
import { UserId } from 'sierra-domain/api/uuid'
import { Chat, CommentContentReference } from 'sierra-domain/chat'
import {
  ScopedChatId,
  ScopedLiveContentId,
  ScopedSelfPacedContentId,
} from 'sierra-domain/collaboration/types'
import {
  XRealtimeAuthorSendMentionNotifications,
  XRealtimeAuthorSendThreadResponseNotification,
} from 'sierra-domain/routes'
import { assertIsNonNullable } from 'sierra-domain/utils'

type SendMentionArgs = {
  id: string
  chatId: ScopedChatId
  threadId: string
  tiptapJsonData: Record<string, unknown>
  userId: UserId
  contentReference?: CommentContentReference
  chat: Chat
}

const translateMentionsToUUIDs = (jsonData: JSONContent): JSONContent => {
  // The mention extension doesn't allow us to access the uuid when generating the displayed label.
  // So we clone the body and replace the labels with the uuids (showing mentions as @uuid instead of @user_name)
  // We send the user uuids to the backend to allow it to translate sana-mentions to slack-mentions for example.
  const newBody = _.cloneDeep(jsonData)
  for (const { content } of newBody.content ?? []) {
    if (content) {
      for (const element of content) {
        if (element.type === 'mention') {
          const mention = element as Mention
          mention.attrs.label = `${mention.attrs.uuid}`
        }
      }
    }
  }
  return newBody
}

const sendThreadNotifications = (
  message: SendMentionArgs,
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  dispatch: Dispatch<any>,
  excludedUserIds: string[]
): void => {
  const scopedCourseId = ScopedChatId.inner(message.chatId)

  // Only send notifications for these types of content.
  // We don't want to send notifications when chatting in a live session for example.
  // But we do want to send notifications when editing the live sesion.
  const courseId =
    ScopedLiveContentId.tryExtractId(scopedCourseId) ?? ScopedSelfPacedContentId.tryExtractId(scopedCourseId)

  if (courseId === null || message.threadId === 'root') {
    return
  }

  const thread = message.chat.messages[message.threadId]
  assertIsNonNullable(thread)

  if (excludedUserIds.includes(thread.userId)) {
    return
  }

  void postWithUserErrorException(
    XRealtimeAuthorSendThreadResponseNotification,
    {
      location: {
        courseId,
        contentReference: message.contentReference,
        chatId: message.chatId,
        threadId: message.threadId,
        messageId: message.id,
      },
      threadAuthorUserId: thread.userId,
      authorUserId: message.userId,
      message: tiptapContentToString(translateMentionsToUUIDs(message.tiptapJsonData as JSONContent), [
        MentionExtension.configure({
          HTMLAttributes: {
            class: 'mention',
          },
        }),
      ]),
    },
    dispatch
  )
}

const sendMentionNotifications = (
  message: SendMentionArgs,
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  dispatch: Dispatch<any>
): string[] => {
  const scopedCourseId = ScopedChatId.inner(message.chatId)

  // Only send mention notifications for these types of content.
  // We don't want to send notifications when chatting in a live session for example.
  // But we do want to send notifications when editing the live sesion.
  const courseId =
    ScopedLiveContentId.tryExtractId(scopedCourseId) ?? ScopedSelfPacedContentId.tryExtractId(scopedCourseId)

  if (courseId === null) {
    return []
  }

  const jsonData = message.tiptapJsonData as JSONContent
  const mentions = getMentions(jsonData)
  if (mentions === undefined || mentions.length === 0) return []

  const mentionedUserIds = mentions.map(m => m.attrs.uuid)

  void postWithUserErrorException(
    XRealtimeAuthorSendMentionNotifications,
    {
      location: {
        courseId,
        contentReference: message.contentReference,
        chatId: message.chatId,
        threadId: message.threadId,
        messageId: message.id,
      },
      mentionedUserIds,
      authorUserId: message.userId,
      message: tiptapContentToString(translateMentionsToUUIDs(jsonData), [
        MentionExtension.configure({
          HTMLAttributes: {
            class: 'mention',
          },
        }),
      ]),
    },
    dispatch
  )

  return mentionedUserIds
}

export const sendChatNotifications = (
  message: SendMentionArgs,
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  dispatch: Dispatch<any>
): void => {
  const mentionedUsers = sendMentionNotifications(message, dispatch)
  // Send notifications if a user responds to the chat,
  // but exclude any who we also sent mention notifications to.
  sendThreadNotifications(message, dispatch, mentionedUsers)
}
