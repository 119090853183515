import { useEffect } from 'react'
import { CSVLink } from 'react-csv'
import { useInView } from 'react-intersection-observer'
import {
  TableInstance,
  TableOptions,
  useExpanded,
  useGlobalFilter,
  usePagination,
  useRowSelect,
  useSortBy,
  useTable,
} from 'react-table'
import { TableBulkActions } from 'sierra-client/components/table/select'
import { Table, TableProps } from 'sierra-client/components/table/table'
import {
  TableMediumSearchHeader,
  TableMediumSearchTrigger,
} from 'sierra-client/components/table/table-medium'
import { SmallTableScrollContainer } from 'sierra-client/components/table/table-utils'
import { useTranslation } from 'sierra-client/hooks/use-translation'
import {
  ActionButton,
  BetweenContainer,
  InfiniteScrollMessage,
} from 'sierra-client/views/manage/components/common'
import { ExportCSVIconButton, getCsvFileName } from 'sierra-client/views/manage/components/export-csv'
import { useFEInfinitePagination } from 'sierra-client/views/manage/hooks/use-fe-infinite-pagination'
import { Separator } from 'sierra-client/views/showcase/common'
import { Spacer } from 'sierra-ui/primitives'

type UseManageTableSmallProps<E extends Record<string, unknown>> = {
  tableOptions: TableOptions<E>
  getEntityId: (entity: E) => string
}

type UseManageTableSmallData<E extends Record<string, unknown>> = {
  tableInstance: TableInstance<E>
  selectedIds: string[]
}

export const useManageTableSmall = <E extends Record<string, unknown>>({
  tableOptions,
  getEntityId,
}: UseManageTableSmallProps<E>): UseManageTableSmallData<E> => {
  const tableInstance = useTable<E>(
    {
      autoResetGlobalFilter: false,
      autoResetSortBy: false,
      ...tableOptions,
    },
    useGlobalFilter,
    useSortBy,
    useExpanded,
    usePagination,
    useRowSelect
  )

  const selectedIds = tableInstance.selectedFlatRows.map(r => getEntityId(r.original))

  return {
    tableInstance,
    selectedIds,
  }
}

type ManageTableSmallProps<E extends Record<string, unknown>> = {
  tableInstance: TableInstance<E>
  isLoading?: boolean
  focusedId?: string
  bulkActions?: JSX.Element
  footerButton?: JSX.Element
  warningMessage?: JSX.Element
  isSearchOpen?: boolean
  hideSelect?: boolean
  $maxHeight?: string
  translations: {
    searchPlaceholder: string
    tableLoading: string
    tableNoResults: string
    csvPrefix: string
  }
  searchTrigger?: (open: boolean) => void
  getEntityId: (entity: E) => string
  onViewDetails?: (id: string) => void
  mapEntityToCsv: (entity: E) => Record<string, unknown>
  getCellProps?: TableProps<E>['getCellProps']
  renderSubComponent?: TableProps<E>['renderSubComponent']
}

export const ManageTableSmall = <E extends Record<string, unknown>>({
  tableInstance,
  isLoading = false,
  focusedId,
  bulkActions,
  footerButton,
  warningMessage,
  isSearchOpen,
  hideSelect,
  $maxHeight,
  translations,
  searchTrigger,
  getEntityId,
  renderSubComponent,
  onViewDetails,
  mapEntityToCsv,
  getCellProps,
}: ManageTableSmallProps<E>): JSX.Element => {
  const { t } = useTranslation()
  const [inViewRef, shouldLoadMore] = useInView({ threshold: 0 })

  const { hasMore, nextPage, resetPage } = useFEInfinitePagination(tableInstance)

  useEffect(() => {
    if (shouldLoadMore && !isLoading) {
      nextPage()
    }
  }, [tableInstance.state.pageSize, nextPage, isLoading, shouldLoadMore])
  // @TODO Check
  return (
    <div>
      <SmallTableScrollContainer scrollToTopDeps={[tableInstance.state.globalFilter]} $maxHeight={$maxHeight}>
        <Table
          renderSubComponent={renderSubComponent}
          tableInstance={tableInstance}
          getRowProps={row => ({
            onClick: onViewDetails === undefined ? undefined : () => onViewDetails(getEntityId(row.original)),
            isFocused: getEntityId(row.original) === focusedId,
          })}
          getCellProps={getCellProps}
          getHeaderProps={column => ({
            onClick: () => {
              if (!column.canSort) return
              resetPage()
            },
          })}
          headerOverride={
            isSearchOpen === true || tableInstance.selectedFlatRows.length > 0 ? (
              <TableBulkActions
                hideSelect={hideSelect}
                tableInstance={tableInstance}
                search={
                  searchTrigger === undefined ? undefined : isSearchOpen === true ? (
                    <TableMediumSearchHeader
                      tableInstance={tableInstance}
                      searchPlaceholder={translations.searchPlaceholder}
                      onClose={() => {
                        searchTrigger(false)
                        tableInstance.setGlobalFilter('')
                      }}
                    />
                  ) : (
                    <TableMediumSearchTrigger onClick={() => searchTrigger(true)} />
                  )
                }
              >
                <CSVLink
                  data={tableInstance.selectedFlatRows.map(r => r.original).map(mapEntityToCsv)}
                  filename={getCsvFileName(translations.csvPrefix)}
                >
                  <ActionButton color='blueBright'>
                    {t('manage.export')} {'.csv'}
                  </ActionButton>
                </CSVLink>
                {bulkActions}
              </TableBulkActions>
            ) : undefined
          }
          small
        />
        {(hasMore || isLoading) && (
          <InfiniteScrollMessage
            ref={inViewRef}
            padding='medium'
            text={translations.tableLoading}
            showSanaLogo
          />
        )}
        {tableInstance.rows.length === 0 && !isLoading && (
          <InfiniteScrollMessage padding='medium' text={translations.tableNoResults} />
        )}
      </SmallTableScrollContainer>
      {warningMessage !== undefined && (
        <>
          <Separator top='none' bottom='none' />
          {warningMessage}
        </>
      )}
      <Spacer size='small' />
      <BetweenContainer>
        {footerButton !== undefined ? footerButton : <div />}
        <ExportCSVIconButton
          fetchCsvData={() => tableInstance.rows.map(r => r.original).map(mapEntityToCsv)}
          filename={translations.csvPrefix}
        />
      </BetweenContainer>
    </div>
  )
}
