import { LearnEntity } from 'sierra-domain/api/entities'
import { LearnerContent } from 'sierra-domain/api/learn'
import { assertNever } from 'sierra-domain/utils'
import { Icon, IconProps } from 'sierra-ui/components'

const getIconId = (type: LearnerContent['type'], template: boolean): IconProps['iconId'] => {
  switch (type) {
    case 'native:self-paced':
    case 'native:course-group':
      return template ? 'bookmark--outline' : 'bookmark--filled'
    case 'linkedin':
    case 'scorm':
    case 'link':
    case 'scorm:course-group':
      return 'link'
    case 'native:live':
    case 'live-session':
      return template ? 'play--circle--outline' : 'play--circle--filled'
    case 'path':
      return 'path'
    case 'homework':
      return 'task'
    case 'program':
      return 'browse'
    case 'native:event-group':
      return 'calendar'
    default:
      assertNever(type)
  }
}

export const getEntityIconId = (type: LearnEntity['entityType'], template: boolean): IconProps['iconId'] => {
  switch (type) {
    case 'native:self-paced':
    case 'native:course-group':
      return template ? 'bookmark--outline' : 'bookmark--filled'
    case 'linkedin':
    case 'scorm':
    case 'link':
    case 'scorm:course-group':
      return 'link'
    case 'native:live':
    case 'live-session':
      return template ? 'play--circle--outline' : 'play--circle--filled'
    case 'path':
      return 'path'
    case 'program':
      return 'browse'
    case 'native:event-group':
      return 'calendar'
    default:
      assertNever(type)
  }
}

export const ContentIcon: React.FC<{
  type: LearnerContent['type']
  template?: boolean
  color?: IconProps['color']
}> = ({ type, template = false, color }) => (
  <Icon iconId={getIconId(type, template)} size='size-16' color={color} />
)
