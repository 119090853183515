import { findPathToNodeWithId } from 'sierra-client/views/v3-author/command'
import { GenerateTakeawaysResponse } from 'sierra-domain/api/author-v2'
import { Entity } from 'sierra-domain/entity'
import { nanoid12 } from 'sierra-domain/nanoid-extensions'
import { Takeaways } from 'sierra-domain/v3-author'
import { createParagraph } from 'sierra-domain/v3-author/create-blocks'
import { Editor, Node } from 'slate'

export const textBeforeNodeWithId = (editor: Editor, nodeId: string): string => {
  const path = findPathToNodeWithId(editor, nodeId)
  if (path === undefined) return ''

  const [nodeIndex] = path
  const texts: string[] = []
  for (const [, childPath] of Node.children(editor, [])) {
    const [childIndex] = childPath

    if (childIndex! <= nodeIndex!) {
      const text = Editor.string(editor, childPath)
      texts.push(text)
    }
  }
  return texts.join('\n').trim()
}

export const toTakeawaysGroup = (response: GenerateTakeawaysResponse, blockId: string): Entity<Takeaways> => {
  const takeaways: string[] = response.takeaways !== undefined ? response.takeaways.values : []

  return {
    children: takeaways.map(kp => ({
      children: [createParagraph({ children: [{ text: kp }] })],
      type: 'takeaway-item',
      id: nanoid12(),
    })),
    id: blockId,
    type: 'takeaways',
  }
}
