import React, { useEffect, useMemo, useState } from 'react'
import { config } from 'sierra-client/config/global-config'
import { usePost } from 'sierra-client/hooks/use-post'
import { useTranslation } from 'sierra-client/hooks/use-translation'
import { SecondaryButton } from 'sierra-client/layout/authentication-layout/auth-buttons'
import { getUserErrorTranslationKey } from 'sierra-client/utils/translation-utils'
import { AuthenticationForm } from 'sierra-client/views/authentication/authentication-layout'
import {
  ContinueAsGuest,
  ContinueWithGoogle,
  ContinueWithSaml,
  Separator,
  getAuthMethodForEmail,
  intersperse,
  useLogIn,
} from 'sierra-client/views/authentication/common'
import { AuthenticationContent } from 'sierra-client/views/authentication/native/components/authentication-content'
import { SamlAuthMethod } from 'sierra-domain/api/private'
import { isLeft } from 'sierra-domain/either'
import { XRealtimeAuthCheckUserAvailability } from 'sierra-domain/routes'
import { getValidatedEmail } from 'sierra-domain/utils'
import { FormElement } from 'sierra-ui/components'
import { Button, InputPrimitive, Text, View } from 'sierra-ui/primitives'

type Props = {
  switchToSignUpWithEmail: (email: string) => void
  switchToSignIn: () => void
  switchToLiveGuestAccess?: () => void
}

export const SignUp: React.FC<Props> = ({
  switchToSignUpWithEmail,
  switchToSignIn,
  switchToLiveGuestAccess,
}: Props) => {
  const { t } = useTranslation()
  const [email, setEmail] = useState('')
  const [emailError, setEmailError] = useState<string | undefined>(undefined)
  const [error, setError] = useState<string | undefined>()
  const { postWithUserErrorCode } = usePost()
  const orgConfig = config.organization

  const { handleSamlLogIn } = useLogIn()

  const method = useMemo(() => {
    const validatedEmail = getValidatedEmail(email.toLowerCase().trim())
    if (validatedEmail === undefined) return undefined

    return getAuthMethodForEmail(orgConfig.auth, validatedEmail)
  }, [orgConfig, email])

  useEffect(() => {
    if (email === '') setEmailError(undefined)
  }, [email])

  const hasSamlLogin = orgConfig.auth.some(v => v.type === 'saml')
  const hasSocialLogin = orgConfig.auth.some(v => v.type === 'social')
  const hasPasswordLogin = orgConfig.auth.some(v => v.type === 'password')

  const handleEmailChange = (e: React.ChangeEvent<HTMLInputElement>): void => {
    setEmailError(undefined)
    setError(undefined)
    setEmail(e.target.value)
  }

  const submit = async (event: React.FormEvent<HTMLFormElement>): Promise<void> => {
    event.preventDefault()

    setError(undefined)

    const validatedEmail = getValidatedEmail(email.toLowerCase().trim())

    if (validatedEmail === undefined) {
      setEmailError(t('create-account.errors.email'))
      return
    } else {
      setEmailError(undefined)
    }

    if (method === undefined) {
      setEmailError(t('create-account.errors.email-domain-invalid'))
      return
    }

    const result = await postWithUserErrorCode(XRealtimeAuthCheckUserAvailability, {
      email: validatedEmail,
    })

    if (isLeft(result)) {
      setError(t(getUserErrorTranslationKey(result.left)))
      return
    }

    switchToSignUpWithEmail(validatedEmail)
  }

  const createContinueWithPassword = (): React.ReactElement => (
    <AuthenticationForm onSubmit={submit} key='sign-up-form'>
      <View direction='column' gap='xsmall'>
        <FormElement label={t('login.email')} helper={emailError} isError={emailError !== undefined}>
          <InputPrimitive type='text' value={email} onChange={handleEmailChange} autoComplete='username' />
        </FormElement>
        {error !== undefined && (
          <>
            <Text size='micro' color='redBright'>
              {error}
            </Text>
          </>
        )}
        {method !== undefined && method.type === 'saml' ? (
          <Button variant='primary' onClick={() => handleSamlLogIn(method.provider)} grow>
            {t('login.sign-up')}
          </Button>
        ) : (
          <Button type='submit' variant='primary' id={'sign-up-button'} disabled={email === ''} grow>
            {t('login.sign-up')}
          </Button>
        )}
      </View>
    </AuthenticationForm>
  )

  const methods = [
    hasSocialLogin ? <ContinueWithGoogle /> : null,
    hasSamlLogin ? (
      <>
        {orgConfig.auth
          .flatMap<SamlAuthMethod>(v => (v.type === 'saml' ? [v] : []))
          .map(v => (
            <ContinueWithSaml key={v.provider} provider={v.provider} display={v.display} icon={v.icon} />
          ))}
      </>
    ) : null,
    hasPasswordLogin ? createContinueWithPassword() : null,
    switchToLiveGuestAccess !== undefined ? (
      <ContinueAsGuest switchToLiveGuestAccess={switchToLiveGuestAccess} />
    ) : null,
  ].flatMap<JSX.Element>(v => (v !== null ? [v] : []))

  return (
    <AuthenticationContent>
      {hasPasswordLogin && (
        <>
          <Text color='foreground/primary' size='small' onClick={switchToSignIn} bold align='center'>
            {t('login.already-an-account')} <SecondaryButton>{t('login.sign-in')}</SecondaryButton>
          </Text>
        </>
      )}
      {intersperse(<Separator />, methods)}
    </AuthenticationContent>
  )
}
