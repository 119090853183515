import _ from 'lodash'
import { useTranslation } from 'sierra-client/hooks/use-translation'
import { useIsMobile } from 'sierra-client/state/browser/selectors'
import { Placeholder } from 'sierra-client/views/v3-author/components'
import { useSiblings } from 'sierra-client/views/v3-author/hooks'
import { assertElementType, isElementType } from 'sierra-client/views/v3-author/queries'
import { SlateFC } from 'sierra-client/views/v3-author/slate'
import { hasOnlyEmptyTextInNodes } from 'sierra-domain/slate-util'
import { Text } from 'sierra-ui/primitives'
import styled, { css } from 'styled-components'

const StyledPlaceholder = styled(Placeholder)<{ $shouldRightAlign: boolean }>`
  ${p =>
    p.$shouldRightAlign &&
    css`
      right: 0;
    `}
`

export const SlidingScaleOption: SlateFC = ({ element, children }) => {
  assertElementType('sliding-scale-card-option', element)
  const isEmpty = hasOnlyEmptyTextInNodes([element])
  const { t } = useTranslation()

  const optionSiblings = useSiblings({ nodeId: element.id }).filter(
    isElementType('sliding-scale-card-option')
  )
  const isLastOption = _.last(optionSiblings)?.id === element.id

  const isMobile = useIsMobile()

  return (
    <Text size={isMobile ? 'micro' : 'regular'} bold color='foreground/secondary'>
      {isEmpty && (
        <StyledPlaceholder $shouldRightAlign={isLastOption}>
          {t('author.sliding-scale.add-statement')}
        </StyledPlaceholder>
      )}
      {children}
    </Text>
  )
}
