import { produce } from 'immer'
import _ from 'lodash'
import { useCallback, useEffect, useMemo, useReducer, useState } from 'react'
import { usePost } from 'sierra-client/hooks/use-post'
import { PotentialProgramAdmin, ProgramRoleChange } from 'sierra-domain/api/manage'
import {
  XRealtimeAdminProgramsListPotentialAdmins,
  XRealtimeAdminProgramsSetRoles,
} from 'sierra-domain/routes'
import { assertNever } from 'sierra-domain/utils'

type ChangeRoleAction = 'add' | 'remove'

const roleChangeByAction: Record<ChangeRoleAction, ProgramRoleChange> = {
  add: { type: 'assign', role: 'admin' },
  remove: { type: 'unassign' },
}

const adminStatusByAction: Record<ChangeRoleAction, boolean> = {
  add: true,
  remove: false,
}

type ChangeAdminRoleParams = {
  adminIds: string[]
}

// ---

type AdminsState = {
  currentAdmins: PotentialProgramAdmin[]
  potentialAdmins: PotentialProgramAdmin[]
}

const EMPTY_ADMINS_STATE: AdminsState = {
  currentAdmins: [],
  potentialAdmins: [],
}

type AdminsStateAction =
  | { type: 'reset-state' }
  | {
      type: 'replace-current'
      admins: PotentialProgramAdmin[]
    }
  | {
      type: 'replace-potential'
      admins: PotentialProgramAdmin[]
    }
  | {
      type: 'add-or-remove-as-program-admin'
      roleAction: ChangeRoleAction
      adminIds: string[]
    }

// I think it may have been a mistake to try and keep these two sources of data in sync.
// Not sure what a perfect solution looks like though.
// It would probably be better if this hook handled all of the fetching instead of trying to reuse
// the `admins` property that is returned in the `program-detail` response.
// It could also make sense not to store the potential admin state here. That's really something that's
// only relevant for the "manage admins" modal. It was originally done to avoid losing state when the modal
// closes but that might not be necessary and if so there are other ways to handle that.
export const adminsReducer = produce<AdminsState, [AdminsStateAction]>((draft, action) => {
  switch (action.type) {
    case 'reset-state': {
      return EMPTY_ADMINS_STATE
    }
    case 'replace-current': {
      // Replace the list of current program admins.
      // If there are matching users in the list of potential admins, update them as well.
      draft.currentAdmins = action.admins

      // Add users that are in current but not potential
      const potentialAdminIds = new Set(draft.potentialAdmins.map(u => u.userId))
      draft.potentialAdmins.push(...action.admins.filter(u => !potentialAdminIds.has(u.userId)))

      // Update existing users in potential
      const adminById = Object.fromEntries(action.admins.map(u => [u.userId, u]))
      draft.potentialAdmins = draft.potentialAdmins.map(u => adminById[u.userId] ?? u)
      break
    }
    case 'replace-potential': {
      // Replace the list of potential program admins.
      // Since the list contains data about whether the user is currently an admin,
      // we will use it to replace the list of current admins.
      // This only works because we don't paginate the fetching of potential admins.
      draft.potentialAdmins = action.admins
      draft.currentAdmins = action.admins.filter(u => u.isAdminForProgram)
      break
    }
    case 'add-or-remove-as-program-admin': {
      // Assuming that a request has been made to add or remove a user as program admin,
      // update the local lists to reflect that.
      // Note: This action assumes that potential admins have been initialized and are up to date.
      const adminIds = new Set(action.adminIds)
      const isAdmin = adminStatusByAction[action.roleAction]
      for (const admin of draft.potentialAdmins) {
        if (adminIds.has(admin.userId)) {
          admin.isAdminForProgram = isAdmin
        }
      }

      draft.currentAdmins = draft.potentialAdmins.filter(u => u.isAdminForProgram)
      break
    }
    default:
      assertNever(action)
  }
})

// --

type UseProgramAdminsParams = {
  programId: string
}

export type UseProgramAdminsState = {
  loading: boolean
  currentAdmins: PotentialProgramAdmin[]
  setCurrentAdmins: (newAdmins: PotentialProgramAdmin[]) => void
  potentialAdmins: PotentialProgramAdmin[]
  fetchAll: () => Promise<void>
  addAdmins: (params: ChangeAdminRoleParams) => Promise<void>
  removeAdmins: (params: ChangeAdminRoleParams) => Promise<void>
}

// This hook returns a container around a program's admin state, including actions for adding and
// removing admins and for refetching from the database.
// The intention is for the program details hook to let this hook handle all admin-related state.
// The hook return type can be passed to components that handle program admins.
// TODO: We may want to move some of this closer to the component.
export const useProgramAdmins = ({ programId }: UseProgramAdminsParams): UseProgramAdminsState => {
  const { postWithUserErrorException } = usePost()

  const [adminState, adminStateDispatch] = useReducer(adminsReducer, EMPTY_ADMINS_STATE)

  const [loading, setLoading] = useState(false)

  // This is not called automatically. The "manage admins" modal should call it when it mounts.
  const fetchAll = useCallback(async () => {
    setLoading(true)

    try {
      const res = await postWithUserErrorException(XRealtimeAdminProgramsListPotentialAdmins, {
        programId,
      })

      adminStateDispatch({ type: 'replace-potential', admins: res.users })
    } finally {
      setLoading(false)
    }
  }, [postWithUserErrorException, programId])

  const addOrRemoveAdmins = useCallback(
    async (action: ChangeRoleAction, { adminIds }: ChangeAdminRoleParams) => {
      const roleChange = roleChangeByAction[action]

      await postWithUserErrorException(XRealtimeAdminProgramsSetRoles, {
        programId,
        users: Object.fromEntries(adminIds.map(adminId => [adminId, roleChange])),
      })

      // Update local state
      adminStateDispatch({ type: 'add-or-remove-as-program-admin', roleAction: action, adminIds })
    },
    [postWithUserErrorException, programId]
  )

  const addAdmins = useMemo(() => _.partial(addOrRemoveAdmins, 'add'), [addOrRemoveAdmins])
  const removeAdmins = useMemo(() => _.partial(addOrRemoveAdmins, 'remove'), [addOrRemoveAdmins])

  const setCurrentAdminsExternal = useCallback<UseProgramAdminsState['setCurrentAdmins']>(
    admins => adminStateDispatch({ type: 'replace-current', admins }),
    []
  )

  const currentAdmins = adminState.currentAdmins
  const potentialAdmins = useMemo(
    () => adminState.potentialAdmins.filter(u => !u.isAdminForProgram),
    [adminState]
  )

  useEffect(() => {
    // Reset state when programId changes.
    adminStateDispatch({ type: 'reset-state' })
  }, [programId])

  return useMemo(
    () => ({
      loading,
      currentAdmins,
      potentialAdmins,
      setCurrentAdmins: setCurrentAdminsExternal,
      addAdmins,
      removeAdmins,
      fetchAll,
    }),
    [loading, setCurrentAdminsExternal, addAdmins, removeAdmins, fetchAll, currentAdmins, potentialAdmins]
  )
}
