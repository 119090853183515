import { useTranslation } from 'sierra-client/hooks/use-translation'
import { LinkButton, ProgressButton } from 'sierra-client/views/workspace/learn/action-button'
import { convertLearnEntityToLinkable } from 'sierra-client/views/workspace/utils/entity-to-linkable'
import { continueUrl } from 'sierra-client/views/workspace/utils/urls'
import { LearnEntity } from 'sierra-domain/api/entities'
import { InfoCard } from 'sierra-domain/api/infocard'

export const LearnEntityActionButton: React.FC<{
  entity: LearnEntity
  type: InfoCard['type']
  onClick?: () => void
  buttonText?: string
}> = ({ entity, type, buttonText }) => {
  const { t } = useTranslation()

  const link = continueUrl(convertLearnEntityToLinkable(entity))

  if (buttonText !== undefined) {
    return <LinkButton href={link} text={buttonText} />
  }

  if (entity.entityType === 'live-session') {
    return <LinkButton href={link} text={t('dictionary.join')} />
  } else if (type === 'self-enroll-to-session') {
    return <LinkButton href={link} text={t('recommendation.choose-session')} />
  } else if (entity.entityType === 'program') {
    return (
      <>
        {entity.learnerContext.passedAt === undefined && (
          <ProgressButton progress={entity.learnerContext.progress} href={link} />
        )}

        <LinkButton href={`/program/${entity.id}`} text={t('recommendation.program-details')} />
      </>
    )
  } else {
    return <ProgressButton progress={entity.learnerContext.progress} href={link} />
  }
}
