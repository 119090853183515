import _ from 'lodash'
import { TypedEventEmitter } from 'sierra-client/lib/typed-event-emitter'
import { logger } from 'sierra-client/logger/logger'
import { VerifyYDocUpdatesResponse } from 'sierra-domain/api/common'
import { UpdateHash, hashUpdate } from 'sierra-domain/base-64'
import { RequestError } from 'sierra-domain/error'
import { assertNever } from 'sierra-domain/utils'
import { retry } from 'ts-retry-promise'

/**
 * This class verifies that updates that have been sent to the server have
 * been successfully received and stored by the server. If an update failed to
 * send or be stored, it will be resent once. If it still fails, an error will
 * be thrown.
 *
 * The purpose is to add a redudancy check to our collaboration infra. In the past,
 * subtle errors have been introduced that have prevented updates from being
 * saved or stored for various reasons. The consequences of this are *extremely*
 * severe (content loss, data corruption, etc), so this class is designed to
 * catch such errors early and repair any potential damage.
 */
export class YDocIntegrityChecker extends TypedEventEmitter<{
  retryUpdate: (update: Uint8Array) => void
}> {
  private readonly throttledCheckIntegrity: _.DebouncedFunc<() => Promise<void>>
  private isDestroyed = false

  constructor(
    private readonly handleError: (message: string, error?: unknown) => void,
    verifyUpdateHashes: (updateHashes: UpdateHash[]) => Promise<VerifyYDocUpdatesResponse>,
    {
      throttleMs = 5000,
      retryDelayMs = 200,
    }: {
      throttleMs?: number
      retryDelayMs?: number
    } = {}
  ) {
    super()

    const verifyUpdateHashesWithRetry = async (
      updateHashes: UpdateHash[]
    ): Promise<VerifyYDocUpdatesResponse> => {
      return retry(() => verifyUpdateHashes(updateHashes), {
        delay: retryDelayMs,
        retries: 'INFINITELY',
        backoff: 'LINEAR',
        logger: message => logger.debug(`retrying verify-document-update: ${message}`),
        timeout: 30_000,
        retryIf: error =>
          !this.isDestroyed && !RequestError.isAccessError(error) && !RequestError.isNotFound(error),
      })
    }

    this.throttledCheckIntegrity = _.throttle(async () => {
      const updatesToCheck = new Map(this.updatesToCheck)
      const updateHashes: UpdateHash[] = [...updatesToCheck.keys()]
      this.updatesToCheck.clear()

      try {
        const result = await verifyUpdateHashesWithRetry(updateHashes)
        if (this.isDestroyed) {
          return
        }

        const resultType = result.type

        this.totalMessagesChecked += updateHashes.length

        switch (result.type) {
          case 'backend-missing-updates': {
            this.totalMessagesDropped += result.missingUpdateHashes.length
            const { missingUpdateHashes } = result

            this.debug(`Failed to verify ${missingUpdateHashes.length} updates`)
            for (const missingUpdateHash of missingUpdateHashes) {
              const missingUpdate = updatesToCheck.get(missingUpdateHash)

              if (missingUpdate === undefined) {
                this.handleError(`Missing update (${missingUpdateHash}) not found in updatesToCheck`)
                break
              }

              if (this.updatesThatFailedToSend.has(missingUpdateHash)) {
                this.handleError(`Failed to send local update (${missingUpdateHash}) after 2 attempts`)
                break
              }

              this.debug('Resending update', missingUpdateHash)
              this.updatesThatFailedToSend.add(missingUpdateHash)
              this.emit('retryUpdate', missingUpdate)
            }
            break
          }
          case 'success': {
            this.debug(`Successfully verified ${updateHashes.length} updates`)
            for (const updateHash of updateHashes) {
              this.updatesThatFailedToSend.delete(updateHash)
            }
            break
          }
          default:
            this.handleError(`Unhandled case: ${resultType}`)
            assertNever(result)
        }
      } catch (error) {
        if (this.isDestroyed) {
          // We can't act on the error if the checker has been destroyed
          return
        }

        // If we cannot resolve the endpoint we need to crash
        if (RequestError.isNotFound(error)) {
          this.handleError('Backend not found when verifying updates', error)
        }

        // If the user does not have permission to access the endpoint we need to crash
        if (RequestError.isAccessError(error)) {
          this.handleError('Permission error when verifying updates', error)
        }

        // An error here does **not** mean that the backend rejected the update. In that case, the backend
        // would have responded with a 200 and a `type: 'backend-missing-updates'` response. Instead, an error
        // here implies that there is a connection issue between the client and the backend (maybe the client is
        // offline or maybe the server is overloaded). In these cases, we should schedule the updates to be
        // checked again in the future, but not crash the client.

        // When there is a communication error, we put the updates to check back into the queue but
        // we do not automatically schedule them to be checked again. This is to avoid the situation where
        // clients spam an already overloaded backend.
        updatesToCheck.forEach((update, updateHash) => {
          this.updatesToCheck.set(updateHash, update)
        })

        logger.error(
          'Unable to reach the backend when verifying updates. Are the clients and server online?',
          { error, updatesToCheckCount: updateHashes.length }
        )
      } finally {
        if (!this.isDestroyed) {
          this.debugLogDropRate()
        }
      }
    }, throttleMs)
  }

  private debug(...messages: unknown[]): void {
    console.debug(`[${YDocIntegrityChecker.name}]`, ...messages)
  }

  private debugLogDropRate(): void {
    const successRate =
      this.totalMessagesChecked > 0 ? 1 - this.totalMessagesDropped / this.totalMessagesChecked : 1
    const logs = [`Message delivery success rate: ${(successRate * 100).toFixed(2)}%`]
    if (this.totalMessagesDropped > 0)
      logs.push(`(dropped ${this.totalMessagesDropped} out of ${this.totalMessagesChecked} messages)`)
    this.debug(...logs)
  }

  private readonly updatesToCheck: Map<UpdateHash, Uint8Array> = new Map()
  private readonly updatesThatFailedToSend = new Set<UpdateHash>()

  private totalMessagesChecked = 0
  private totalMessagesDropped = 0

  validateUpdate(update: Uint8Array): void {
    if (this.isDestroyed) {
      this.handleError('Cannot validate update after YDocIntegrityChecker has been destroyed')
      return
    }

    this.updatesToCheck.set(hashUpdate(update), update)
    void this.throttledCheckIntegrity()
  }

  destroy(): void {
    if (this.isDestroyed) {
      this.handleError('Cannot destroy YDocIntegrityChecker more than once')
      return
    }

    this.off()
    this.throttledCheckIntegrity.cancel()
    this.isDestroyed = true
  }
}
