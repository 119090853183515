import { DefaultCardContext } from 'sierra-client/components/recommendations/components/card-context/default-card-context'
import { ProgramCardContext } from 'sierra-client/components/recommendations/components/card-context/program-card-context'
import { Recommendation } from 'sierra-domain/api/recommendations'

export const CardContext: React.FC<{
  card: Recommendation['card']
}> = ({ card }) => {
  if ('entity' in card && card.entity.entityType === 'program') {
    return <ProgramCardContext card={card} />
  }

  return <DefaultCardContext card={card} />
}
