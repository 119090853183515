import { Atom, useAtomValue } from 'jotai'
import React, { useEffect, useMemo } from 'react'
import { cardViewed } from 'sierra-client/core/logging/authoring/logger'
import { HighlightChanges } from 'sierra-client/editor/version-history//utils/highlight-changes'
import { CenteredLoadingContainer } from 'sierra-client/editor/version-history/absolute-centered-spinner'
import { TargetIndexAtom } from 'sierra-client/editor/version-history/use-history-with-target-index-atom'
import { useLastDefinedValue } from 'sierra-client/hooks/use-last-defined-value'
import { useTranslation } from 'sierra-client/hooks/use-translation'
import { useDispatch } from 'sierra-client/state/hooks'
import { FCC } from 'sierra-client/types'
import { BreakoutRoomCard } from 'sierra-client/views/flexible-content/breakout-room-card'
import { CreateCardCanvas } from 'sierra-client/views/flexible-content/create-page'
import { FullHeightWithPadding } from 'sierra-client/views/flexible-content/editor/content-sidebar/full-height-with-padding'
import { EmbedCard } from 'sierra-client/views/flexible-content/embed-card'
import { FileContext } from 'sierra-client/views/flexible-content/file-context'
import { getEditorLayoutForFileType } from 'sierra-client/views/flexible-content/get-editor-layout-for-file-type'
import { CreateImageCard } from 'sierra-client/views/flexible-content/image-card'
import { LobbyCard } from 'sierra-client/views/flexible-content/lobby-card'
import { PolarisCardTheme } from 'sierra-client/views/flexible-content/polaris-card-theme'
import { VideoCard } from 'sierra-client/views/flexible-content/video-card'
import { StaticEditor, useStaticEditor } from 'sierra-client/views/self-paced/review/static-editor'
import { useSafeFlexibleContentId } from 'sierra-client/views/v3-author/hooks'
import { QACard } from 'sierra-client/views/v3-author/qa-card/QA-card'
import { UserId } from 'sierra-domain/api/uuid'
import { AssetContext } from 'sierra-domain/asset-context'
import { ScopedCreateContentId } from 'sierra-domain/collaboration/types'
import { safeGetFile } from 'sierra-domain/editor/operations/y-utilts'
import { FileId } from 'sierra-domain/flexible-content/identifiers'
import { assertIsSlateFile, File } from 'sierra-domain/flexible-content/types'
import { assertNever, assertWith } from 'sierra-domain/utils'
import { SlateDocument } from 'sierra-domain/v3-author'
import { getSlateDocumentSafe } from 'sierra-domain/v3-author/slate-yjs-extension'
import { History, ReplayUpdatesContext } from 'sierra-domain/version-history/types'
import { LoadingSpinner, Text, View } from 'sierra-ui/primitives'
import { token } from 'sierra-ui/theming'
import styled from 'styled-components'

const CenteredContainerWithBorder = styled.div`
  height: 100%;
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 16px;

  & > div {
    border-radius: 6px;
    border: 1px solid ${token('border/default')};
    padding: 8px;
  }
`

const NoFile: React.FC = () => {
  const { t } = useTranslation()

  return (
    <CenteredContainerWithBorder>
      <View direction='column'>
        <Text bold>{t('version-history.selected-card-not-found-title')}</Text>
        <Text color='foreground/secondary'>{t('version-history.selected-card-not-found-description')}</Text>
      </View>
    </CenteredContainerWithBorder>
  )
}

const NotSupported: FCC = ({ children }) => {
  return (
    <CenteredContainerWithBorder>
      <View direction='column'>
        <Text bold>{children}</Text>
      </View>
    </CenteredContainerWithBorder>
  )
}

const NoPointerEvents = styled.div`
  pointer-events: none;
  height: 100%;
`

const Switch: React.FC<{
  file: File
  authorIds: UserId[] | undefined
  currentDocument: SlateDocument | undefined
  previousDocument: SlateDocument | undefined
}> = ({ file, authorIds, currentDocument, previousDocument }) => {
  const { t } = useTranslation()
  const editor = useStaticEditor(currentDocument ?? [])

  const flexibleContentId = useSafeFlexibleContentId()
  const assetContext = useMemo(
    () =>
      flexibleContentId !== undefined
        ? { type: 'course' as const, courseId: flexibleContentId }
        : { type: 'unknown' as const },
    [flexibleContentId]
  )

  switch (file.data.type) {
    case 'slate-card':
    case 'poll':
    case 'sliding-scale':
    case 'flip-cards':
    case 'bullet':
    case 'general':
    case 'question-card':
    case 'assessment-card':
    case 'project-card':
    case 'sticky-notes':
    case 'reflections':
    case 'homework': {
      assertIsSlateFile(file)

      return (
        <HighlightChanges
          authorId={authorIds?.length === 1 ? authorIds[0] : undefined}
          editor={editor}
          previousDocument={previousDocument ?? []}
          currentDocument={currentDocument ?? []}
        >
          <NoPointerEvents>
            <StaticEditor
              editor={editor}
              key={file.id}
              file={file}
              document={currentDocument ?? []}
              mode='version-history'
              layout={getEditorLayoutForFileType(file.data.type)}
              assetContext={assetContext}
            />
          </NoPointerEvents>
        </HighlightChanges>
      )
    }
    case 'live-lobby':
      return <LobbyCard />
    case 'breakout-room':
      return <BreakoutRoomCard data={file.data} />
    case 'video':
      return (
        <VideoCard
          readOnly
          video={file.data.video}
          setVideoDuration={() => {}}
          onUploadDone={() => {}}
          assetContext={assetContext}
          file={file}
        />
      )
    case 'image':
      return (
        <CreateImageCard
          data={file.data}
          onUploadDone={() => {}}
          readOnly
          mode='create'
          assetContext={assetContext}
        />
      )
    case 'embed':
      return <EmbedCard data={file.data} onUploadDone={() => {}} readOnly />
    case 'stupid-questions':
      return <QACard fileId={file.id} data={file.data} readOnly />

    case 'notepad':
    case 'external-notepad':
    case 'scenario':
    case 'drop-a-word':
      return (
        <NotSupported>
          {file.data.type === 'notepad' || file.data.type === 'external-notepad'
            ? t('version-history.notepads-not-supported')
            : t('dictionary.empty')}
        </NotSupported>
      )
    // @deprecated
    case 'roleplay':
      return null

    default:
      assertNever(file.data)
  }
}

const Content: React.FC<{
  authorIds?: UserId[]
  context: ReplayUpdatesContext & { type: 'loaded' }
  scopedCreateContentId: ScopedCreateContentId
  file: File
}> = ({ authorIds, file, context, scopedCreateContentId }) => {
  const fileId = file.id
  const documents = useMemo(() => {
    return {
      current: getSlateDocumentSafe(context.currentYDoc, fileId),
      previous: getSlateDocumentSafe(context.previousYDoc, fileId),
    }
  }, [fileId, context])

  const dispatch = useDispatch()

  useEffect(() => {
    void dispatch(
      cardViewed({
        contentId: ScopedCreateContentId.extractId(scopedCreateContentId),
        fileId: fileId,
        cardType: file.data.type,
        mode: 'version-history',
      })
    )
  }, [dispatch, file.data.type, file.id, fileId, scopedCreateContentId])

  return (
    <FileContext file={file} scopedCreateContentId={scopedCreateContentId} liveSessionId={undefined}>
      <Switch
        file={file}
        authorIds={authorIds}
        currentDocument={documents.current}
        previousDocument={documents.previous}
      />
    </FileContext>
  )
}

const CardTheme: FCC<{ file: File; assetContext: AssetContext }> = ({ file, assetContext, children }) => (
  <PolarisCardTheme {...file}>
    <CreateCardCanvas card={file} assetContext={assetContext}>
      {children}
    </CreateCardCanvas>
  </PolarisCardTheme>
)

export const VersionHistoryCenterContent = ({
  history,
  targetIndexAtom,
  scopedCreateContentId,
  nodeId,
  assetContext,
  replayUpdatesContextAtom,
}: {
  history: History
  replayUpdatesContextAtom: Atom<ReplayUpdatesContext>
  targetIndexAtom: TargetIndexAtom
  scopedCreateContentId: ScopedCreateContentId
  nodeId: FileId | undefined
  assetContext: AssetContext
}): JSX.Element | null => {
  const targetIndex = useAtomValue(targetIndexAtom)
  const authorIds = history.updates[targetIndex]?.userIds
  const context = useAtomValue(replayUpdatesContextAtom)

  const file = useMemo(() => {
    if (nodeId === undefined) return undefined
    if (context.type === 'loading') return undefined

    const file = safeGetFile(context.currentYDoc, nodeId)
    assertWith(File.optional(), file)
    return file
  }, [context, nodeId])

  const lastLoadedFile = useLastDefinedValue(file)
  const lastLoadedContext = useLastDefinedValue<(ReplayUpdatesContext & { type: 'loaded' }) | undefined>(
    context.type === 'loaded' ? context : undefined
  )

  return (
    <FullHeightWithPadding>
      {lastLoadedContext && (
        <>
          {lastLoadedFile === undefined ? (
            <NoFile />
          ) : (
            <CardTheme file={lastLoadedFile} assetContext={assetContext}>
              <Content
                authorIds={authorIds}
                file={lastLoadedFile}
                scopedCreateContentId={scopedCreateContentId}
                context={lastLoadedContext}
              />
            </CardTheme>
          )}
        </>
      )}

      {context.type === 'loading' && (
        <CenteredLoadingContainer>
          <LoadingSpinner color='black' size='64' />
        </CenteredLoadingContainer>
      )}
    </FullHeightWithPadding>
  )
}
