import { useAtom } from 'jotai'
import { useGetFormattedTime } from 'sierra-client/core/format'
import { useTranslation } from 'sierra-client/hooks/use-translation'
import { getGlobalRouter } from 'sierra-client/router'
import { helperCollapsedAtom } from 'sierra-client/views/course-helper/atoms'
import {
  CourseOverview,
  PathOverview,
  ProgramOverview,
  Start,
} from 'sierra-client/views/course-helper/content/actions'
import { EndOfCourseRecommendations } from 'sierra-client/views/course-helper/content/course-helper-recommendations/end-of-course-recommendations'
import { CourseFallback } from 'sierra-client/views/course-helper/content/fallback/fallback'
import { ScormCourseFallback } from 'sierra-client/views/course-helper/content/fallback/scorm-fallback'
import { NextUpCourseInformation } from 'sierra-client/views/course-helper/content/next-up-course-information'
import { HelperContent } from 'sierra-client/views/course-helper/shared/shared'
import { HelperState, PendingReason } from 'sierra-client/views/course-helper/types'
import { useSelfPacedFiles } from 'sierra-client/views/self-paced/files-provider'
import {
  CourseForHref,
  PathInformation,
  ProgramInformation,
} from 'sierra-domain/api/backend-self-paced/types'
import { NanoId12 } from 'sierra-domain/api/nano-id'
import { MoreStatus } from 'sierra-domain/api/strategy-v2'
import { assertNever, iife, isDefined } from 'sierra-domain/utils'
import { IconId, TruncatedText } from 'sierra-ui/components'
import { Button, Text, View } from 'sierra-ui/primitives'
import styled from 'styled-components'

const DescriptionWrapper = styled(View)`
  min-width: 416px;
  max-width: 416px;
`

const NextUpInformation: React.FC<{
  courseTitle: string
  course: CourseForHref
  currentCourseId: NanoId12
  goToNextUp: () => void
  nextUpText: string
  secondaryButton: React.ReactNode
}> = ({ courseTitle, course, goToNextUp, nextUpText, secondaryButton }) => {
  const duration = course.duration !== undefined ? course.duration / 1000 : undefined
  const formattedDuration = useGetFormattedTime(duration, false)

  return (
    <View direction='column' marginTop='16'>
      <Text color='foreground/secondary' bold>
        {nextUpText}
      </Text>
      <NextUpCourseInformation
        course={course}
        courseTitle={courseTitle}
        extraInformation={formattedDuration}
      />
      <View justifyContent='flex-end'>
        {secondaryButton}
        <Start onClick={goToNextUp} />
      </View>
    </View>
  )
}

const _ProgramInformation: React.FC<{
  programInformation: ProgramInformation
  courseId: NanoId12
  goToNextUp: () => void
}> = ({ programInformation, courseId, goToNextUp }) => {
  const { t } = useTranslation()

  if (programInformation.nextStep !== undefined) {
    return (
      <NextUpInformation
        course={programInformation.nextStep.courseForHref}
        courseTitle={programInformation.nextStep.nextStepTitle}
        currentCourseId={courseId}
        goToNextUp={goToNextUp}
        secondaryButton={
          <ProgramOverview
            onClick={() =>
              getGlobalRouter().navigate({
                to: '/program/$programId',
                params: { programId: programInformation.programId },
              })
            }
          />
        }
        nextUpText={t('helper.next-up-in', {
          title: programInformation.programTitle,
        })}
      />
    )
  }

  return (
    <View justifyContent='flex-end' marginTop='16'>
      <ProgramOverview
        onClick={() =>
          getGlobalRouter().navigate({
            to: '/program/$programId',
            params: { programId: programInformation.programId },
          })
        }
      />
      <CourseOverview onClick={goToNextUp} />
    </View>
  )
}

const _PathInformation: React.FC<{
  pathInformation: PathInformation
  courseId: NanoId12
  goToNextUp: () => void
}> = ({ pathInformation, courseId, goToNextUp }) => {
  const { t } = useTranslation()

  if (pathInformation.nextStep !== undefined) {
    return (
      <NextUpInformation
        course={pathInformation.nextStep.courseForHref}
        courseTitle={pathInformation.nextStep.nextStepTitle}
        currentCourseId={courseId}
        goToNextUp={goToNextUp}
        secondaryButton={
          <PathOverview
            onClick={() =>
              void getGlobalRouter().navigate({
                to: '/path/$pathId',
                params: { pathId: pathInformation.pathId },
              })
            }
          />
        }
        nextUpText={t('helper.next-up-in', {
          title: pathInformation.pathTitle,
        })}
      />
    )
  }

  return (
    <View justifyContent='flex-end' marginTop='16'>
      <PathOverview
        onClick={() =>
          void getGlobalRouter().navigate({
            to: '/path/$pathId',
            params: { pathId: pathInformation.pathId },
          })
        }
      />
      <CourseOverview onClick={goToNextUp} />
    </View>
  )
}

const InformationRow: React.FC<{
  status: MoreStatus
}> = ({ status }) => {
  const { t } = useTranslation()

  const title = iife(() => {
    switch (status.reason) {
      case 'homework_pending':
        return t('helper.course-pending.all-done')
    }
  })

  const body = iife(() => {
    switch (status.reason) {
      case 'homework_pending':
        return t('helper.course-pending.review-email')
      case 'homework_failed':
        return t('helper.course-pending.exercise-failed.body')
      case 'homework_failed_with_no_retries':
        return t('homework.failed-with-no-retries.description')
      default:
        assertNever(status.reason)
    }
  })

  return (
    <DescriptionWrapper
      direction='column'
      justifyContent='flex-start'
      alignItems='flex-start'
      marginRight='48'
      marginBottom='24'
    >
      {isDefined(title) && (
        <Text bold color='foreground/secondary'>
          {title}
        </Text>
      )}
      <TruncatedText color='foreground/secondary' lines={2}>
        {body}
      </TruncatedText>
    </DescriptionWrapper>
  )
}

const LinkToFailedExercise: React.FC<{
  pendingReason: PendingReason
}> = ({ pendingReason }) => {
  const { goTo } = useSelfPacedFiles()
  const { t } = useTranslation()
  const [, setCollapsed] = useAtom(helperCollapsedAtom)

  return (
    <View justifyContent='flex-end'>
      <Button
        onClick={() => {
          goTo(pendingReason.fileId)
          if (pendingReason.moreStatus.reason === 'homework_failed') {
            setCollapsed(true)
          }
        }}
      >
        {t('dictionary.view')}
      </Button>
    </View>
  )
}

const useGetIconAndTitle = (reason: MoreStatus['reason'] | undefined): { title: string; icon: IconId } => {
  const { t } = useTranslation()

  const title = iife(() => {
    if (reason === undefined) return t('helper.course-pending.pending-course')
    switch (reason) {
      case 'homework_pending':
        return t('homework.awaiting-review')
      case 'homework_failed':
      case 'homework_failed_with_no_retries':
        return t('home.exercise.failed.text')
      default:
        assertNever(reason)
    }
  })

  const icon = iife(() => {
    if (reason === undefined) return 'course--card--pending'
    switch (reason) {
      case 'homework_pending':
        return 'time'
      case 'homework_failed':
      case 'homework_failed_with_no_retries':
        return 'close--circle--filled'
      default:
        assertNever(reason)
    }
  })

  return { title, icon }
}

export const CoursePending: React.FC<{ helperState: Extract<HelperState, { type: 'course-pending' }> }> = ({
  helperState,
}) => {
  const { nextUp, goToNextUp } = useSelfPacedFiles()
  const { title, icon } = useGetIconAndTitle(helperState.pendingReason?.moreStatus.reason)
  const { currentFile } = useSelfPacedFiles()

  if (nextUp.type !== 'course-pending') return <CourseFallback />

  return (
    <HelperContent title={title} icon={icon}>
      {isDefined(helperState.pendingReason) && (
        <InformationRow status={helperState.pendingReason.moreStatus} />
      )}
      {iife(() => {
        switch (true) {
          case isDefined(currentFile) &&
            isDefined(helperState.pendingReason) &&
            helperState.pendingReason.moreStatus.status === 'failed' &&
            currentFile.id !== helperState.pendingReason.fileId:
            return <LinkToFailedExercise pendingReason={helperState.pendingReason} />
          case isDefined(nextUp.programInformation):
            return (
              <_ProgramInformation
                programInformation={nextUp.programInformation}
                courseId={nextUp.courseId}
                goToNextUp={() => goToNextUp(nextUp)}
              />
            )
          case isDefined(nextUp.pathInformation):
            return (
              <_PathInformation
                pathInformation={nextUp.pathInformation}
                courseId={nextUp.courseId}
                goToNextUp={() => goToNextUp(nextUp)}
              />
            )
          default:
            return <EndOfCourseRecommendations />
        }
      })}
    </HelperContent>
  )
}

export const ScormCoursePending: React.FC<{
  helperState: Extract<HelperState, { type: 'course-pending' }>
}> = ({ helperState }) => {
  const { title, icon } = useGetIconAndTitle(helperState.pendingReason?.moreStatus.reason)

  return (
    <HelperContent title={title} icon={icon}>
      {isDefined(helperState.pendingReason) && (
        <InformationRow status={helperState.pendingReason.moreStatus} />
      )}
      <ScormCourseFallback />
    </HelperContent>
  )
}
