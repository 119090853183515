import { useState } from 'react'
import { useCreateTeamspaceMutation } from 'sierra-client/api/hooks/use-teamspace'
import { getRandomPresetThemeName } from 'sierra-client/components/common/inputs/theme-picker'
import { useNotif } from 'sierra-client/components/common/notifications'
import {
  AddCollaboratorsForm,
  CollaboratorIdentity,
} from 'sierra-client/features/teamspace/components/collaborators-selector'
import {
  PanelBody,
  PanelFooter,
  PanelGroupButton,
} from 'sierra-client/features/teamspace/components/modals/panels/panel-elements'
import { TeamspaceIconInput } from 'sierra-client/features/teamspace/components/teamspace-icon'
import { TeamspaceVisibilitySelector } from 'sierra-client/features/teamspace/components/teamspace-permission-selector'
import { teamspaceCreatedLogger } from 'sierra-client/features/teamspace/logger'
import { useTranslation } from 'sierra-client/hooks/use-translation'
import { getGlobalRouter } from 'sierra-client/router'
import { useDispatch } from 'sierra-client/state/hooks'
import { TeamspaceVisibility } from 'sierra-domain/api/teamspace'
import { FormElement } from 'sierra-ui/components'
import { Button, Heading, InputPrimitive, Text, TextAreaPrimitive, View } from 'sierra-ui/primitives'
import styled from 'styled-components'

const ShortText = styled(Text)`
  width: 80%;
`

const Form = styled.form`
  height: 100%;
  display: flex;
  flex-direction: column;
`

const Header = styled.header`
  padding-bottom: 40px;
`

export const AddTeamspacePanel: React.FC<{
  onClose: () => void
  onCancel: () => void
}> = ({ onClose, onCancel }) => {
  const { t } = useTranslation()
  const dispatch = useDispatch()
  const notification = useNotif()

  const [teamspaceName, setTeamspaceName] = useState('')
  const [teamspaceNameError, setTeamspaceNameError] = useState<string | undefined>(undefined)
  const [teamspaceDescription, setTeamspaceDescription] = useState('')
  const [teamspaceIconTheme, setTeamspaceIconTheme] = useState<string | undefined>(getRandomPresetThemeName())
  const [teamspaceVisibility, setTeamspaceVisibility] = useState<TeamspaceVisibility>('private')
  const [teamspaceMembers, setTeamspaceMembers] = useState<CollaboratorIdentity[]>([])

  const createTeamspaceMutation = useCreateTeamspaceMutation()

  return (
    <Form
      onSubmit={event => {
        event.preventDefault()

        if (teamspaceName.trim() === '') {
          setTeamspaceNameError('Cannot be empty')
          return
        }

        createTeamspaceMutation.mutate(
          {
            displayName: teamspaceName.trim(),
            description: teamspaceDescription.trim(),
            visibility: teamspaceVisibility,
            iconTheme: teamspaceIconTheme,
            userMembers: teamspaceMembers
              .filter(member => member.identity.type === 'user')
              .map(member => ({
                userId: member.identity.id,
                role: member.role,
              })),
            groupMembers: teamspaceMembers
              .filter(member => member.identity.type === 'userGroup')
              .map(member => ({
                groupId: member.identity.id,
                role: member.role,
              })),
          },
          {
            onSuccess: async data => {
              void dispatch(
                teamspaceCreatedLogger({
                  usersAdded: teamspaceMembers.length,
                })
              )
              onClose()
              setTeamspaceName('')
              setTeamspaceDescription('')
              setTeamspaceIconTheme(undefined)
              setTeamspaceVisibility('private')
              setTeamspaceMembers([])

              return Promise.all([
                getGlobalRouter().navigate({ to: `/t/${data.teamspaceId}` }),

                notification.push({
                  type: 'custom',
                  level: 'info',
                  body: t('teamspace.new-teamspace-added'),
                }),
              ])
            },
          }
        )
      }}
    >
      <PanelBody>
        <Header>
          <View direction='column' gap='8'>
            <Heading bold size='h5' color='foreground/secondary'>
              {t('teamspaces.create-new')}
            </Heading>
            <ShortText size='regular'>{t('teamspaces.teamspace-description')}</ShortText>
          </View>
        </Header>

        <View direction='column' gap='24'>
          <View justifyContent='space-between' alignItems='stretch' gap='24'>
            {/* Revisit once we support error state in the <FormElement /> */}
            <FormElement label={t('dictionary.name')} helper={teamspaceNameError}>
              <InputPrimitive
                value={teamspaceName}
                onChange={event => {
                  setTeamspaceName(event.target.value)
                  setTeamspaceNameError(undefined)
                }}
                placeholder={t('teamspaces.add-name-placeholder')}
                required
              />
            </FormElement>

            <FormElement label={t('dictionary.icon')} grow={false}>
              <TeamspaceIconInput
                displayName={teamspaceName.trim() !== '' ? teamspaceName : 'A'}
                selectedThemeName={teamspaceIconTheme}
                onThemeChange={theme => {
                  setTeamspaceIconTheme(theme)
                }}
              />
            </FormElement>
          </View>

          <FormElement
            helper={teamspaceNameError}
            label={
              <View justifyContent='space-between'>
                {t('dictionary.description')}
                <Text color='grey25'>{t('input.optional')}</Text>
              </View>
            }
          >
            <TextAreaPrimitive
              value={teamspaceDescription}
              onChange={event => {
                setTeamspaceDescription(event.target.value)
              }}
              placeholder={t('teamspaces.add-description-placeholder')}
              rows={4}
              autoExpand
            />
          </FormElement>

          <TeamspaceVisibilitySelector
            visibility={teamspaceVisibility}
            setVisibility={setTeamspaceVisibility}
          />

          <FormElement
            label={
              <View justifyContent='space-between'>
                {t('teamspaces.invite')}
                <Text color='grey25'>{t('input.optional')}</Text>
              </View>
            }
          >
            <AddCollaboratorsForm
              defaultIdentities={[]}
              onChange={collaborators => {
                setTeamspaceMembers(collaborators)
              }}
            />
          </FormElement>
        </View>
      </PanelBody>
      <PanelFooter>
        <PanelGroupButton>
          <Button variant='secondary' onClick={onCancel}>
            {t('dictionary.cancel')}
          </Button>
          <Button type='submit' disabled={teamspaceName.length === 0}>
            {t('dictionary.create')}
          </Button>
        </PanelGroupButton>
      </PanelFooter>
    </Form>
  )
}
