import React from 'react'
import { useTranslation } from 'sierra-client/hooks/use-translation'
import {
  SectionHeadline,
  SectionInfo,
} from 'sierra-client/views/manage/components/user-attributes/flows/components/layout'
import { ContentDomainAutoComplete } from 'sierra-client/views/manage/components/user-attributes/flows/invite-users/panels/set-attributes-panel/learning-journey/content-domain-auto-complete'
import { CoursesAutoComplete } from 'sierra-client/views/manage/components/user-attributes/flows/invite-users/panels/set-attributes-panel/learning-journey/courses-auto-complete'
import { useInvitationDomains } from 'sierra-client/views/manage/components/user-attributes/hooks/use-invitation-domains'
import { Name } from 'sierra-client/views/manage/components/user-attributes/renderers/common'
import { Spacer, View } from 'sierra-ui/primitives'
import styled from 'styled-components'

const Section = styled(View).attrs({
  direction: 'column',
  gap: '4',
})``

export const LearningJourneySection: React.FC<{ canAssignContent: boolean }> = ({ canAssignContent }) => {
  const { t } = useTranslation()

  const { programsDomain, groupsDomain } = useInvitationDomains()

  return (
    <Section>
      <SectionHeadline>{t('manage.users.invite.customize-journey.title')}</SectionHeadline>
      <SectionInfo>{t('manage.users.invite.customize-journey.body')}</SectionInfo>
      <Spacer />
      <View direction='column' gap='16'>
        <View direction='column' id='impact-intercom-product-tour--edit-attributes-add-to-group'>
          <Name>{t('admin.organization.users.add-to-group')}</Name>
          <ContentDomainAutoComplete domainRep={groupsDomain} />
        </View>
        {canAssignContent && (
          <>
            <View direction='column'>
              <Name>{t('admin.organization.users.add-to-program')}</Name>
              <ContentDomainAutoComplete domainRep={programsDomain} />
            </View>
            <View direction='column'>
              <Name>{t('dictionary.course-plural')}</Name>
              <CoursesAutoComplete />
            </View>
          </>
        )}
      </View>
      <Spacer />
    </Section>
  )
}
