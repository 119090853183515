import React, { ChangeEvent, useEffect, useState } from 'react'
import { usePost } from 'sierra-client/hooks/use-post'
import { useTranslation } from 'sierra-client/hooks/use-translation'
import { addTagAction } from 'sierra-client/state/content/actions'
import { useDispatch } from 'sierra-client/state/hooks'
import { DialogIcon, DialogTitleWithIcons } from 'sierra-client/views/manage/components/dialog-utils'
import { TagId } from 'sierra-domain/api/nano-id'
import { TagData } from 'sierra-domain/content/tag'
import { XRealtimeAdminTagsCreateTag } from 'sierra-domain/routes'
import { FormElement, Modal } from 'sierra-ui/components'
import { Button, InputPrimitive, TextAreaPrimitive, View } from 'sierra-ui/primitives'
import styled from 'styled-components'

type CreateTagModalProps = {
  open: boolean
  onClose: () => void
  onAfterSave: (tagId: TagId) => void
  defaultTagName?: string
}

const TagForm = styled.form`
  display: flex;
  flex-direction: column;
  height: 100%;
  justify-content: space-between;
  width: 100%;
  gap: 24px;
`

export const CreateTagModal: React.FC<CreateTagModalProps> = ({
  open,
  onClose,
  onAfterSave,
  defaultTagName,
}) => {
  const { t } = useTranslation()
  const { postWithUserErrorException } = usePost()
  const dispatch = useDispatch()

  const [formValues, setFormValues] = useState<TagData>({
    name: '',
    description: '',
  })
  const [isNameValid, setIsNameValid] = useState(true)

  useEffect(() => {
    if (defaultTagName !== undefined && open) {
      setFormValues({ name: defaultTagName, description: ' ' })
    }
  }, [defaultTagName, open])

  const createTag = async (): Promise<void> => {
    if (formValues.name.length === 0) {
      setIsNameValid(false)
      return
    }

    const response = await postWithUserErrorException(XRealtimeAdminTagsCreateTag, formValues)
    void dispatch(
      addTagAction({
        id: response.id,
        tag: { id: response.id, data: formValues, enabled: true },
        courses: [],
        totalCoursesCount: 0,
        learnersCount: 0,
      })
    )
    setFormValues({ name: '', description: '' })
    onAfterSave(response.id)
  }

  const closeAndReset = (): void => {
    setFormValues({ name: '', description: '' })
    onClose()
  }

  return (
    <Modal open={open} onClose={closeAndReset}>
      <View>
        <DialogTitleWithIcons rightIcon={<DialogIcon onClick={closeAndReset} />}>
          {t('manage.skill.new-skill')}
        </DialogTitleWithIcons>
      </View>
      <View grow padding='24 32'>
        <TagForm onSubmit={e => e.preventDefault()}>
          <View direction='column'>
            <FormElement
              label={t('manage.tags.tag-name')}
              helper={isNameValid ? '' : t('dictionary.required')}
            >
              <InputPrimitive
                id='title'
                value={formValues.name}
                onChange={e => setFormValues(values => ({ ...values, name: e.target.value }))}
                placeholder={t('admin.tags.name-placeholder')}
              />
            </FormElement>
            <FormElement label={t('admin.organization.description')}>
              <TextAreaPrimitive
                id='description'
                value={formValues.description ?? ''}
                onChange={(e: ChangeEvent<HTMLTextAreaElement>) =>
                  setFormValues(values => ({ ...values, description: e.target.value }))
                }
                placeholder={t('admin.skills.description-placeholder')}
              />
            </FormElement>
          </View>
          <Button onClick={createTag}>{t('dictionary.save')}</Button>
        </TagForm>
      </View>
    </Modal>
  )
}
