import { createSelector } from '@reduxjs/toolkit'
import _ from 'lodash'
import { SelfPacedState } from 'sierra-client/state/self-paced/types'
import { RootState } from 'sierra-client/state/types'
import { QuestionCardResponse } from 'sierra-domain/card/question-card'

const selectState = (state: RootState): SelfPacedState => state.selfPaced

export const selectQuestionCardResponse = createSelector(selectState, data =>
  _.memoize(
    (questionCardId: string): QuestionCardResponse | undefined =>
      data.questionCards[questionCardId]?.responses[questionCardId]
  )
)

export const selectQuestionCards = createSelector(selectState, data =>
  _.memoize((): SelfPacedState['questionCards'] | undefined => data.questionCards)
)
