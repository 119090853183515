import { Logger } from 'sierra-client/core/logging/logger'

type Identifier = `enrollment_${string}`

export const ruleCreated = Logger.trackLoggerWithExtra<{ id: string }, { id: string }>(
  'enrollment_rule_created' satisfies Identifier,
  input => {
    return {
      ...input,
    }
  }
)

export const ruleDeleted = Logger.trackLoggerWithExtra<{ id: string }, { id: string }>(
  'enrollment_rule_deleted' satisfies Identifier,
  input => {
    return {
      ...input,
    }
  }
)

export const ruleActivate = Logger.trackLoggerWithExtra<
  { id: string; active: boolean },
  { id: string; active: boolean }
>('enrollment_rule_activate' satisfies Identifier, input => {
  return {
    ...input,
  }
})
