import React, { useCallback, useContext, useEffect, useReducer } from 'react'
import * as logic from 'sierra-client/components/shortcut-menu/logic'
import { FCC } from 'sierra-client/types'

function debug(...messages: unknown[]): void {
  console.debug('[shortcut-menu]', ...messages)
}

function isMetaKey({ ctrlKey, metaKey }: { ctrlKey: boolean; metaKey: boolean }): boolean {
  const isCtrl = ctrlKey
  const isCmd = metaKey
  return isCtrl || isCmd
}

function isShortcutMenuKeyPress(event: KeyboardEvent): boolean {
  const isShortcutKey = event.key === 'k'
  return isShortcutKey && isMetaKey(event)
}

function useShortcutMenuToggler(dispatch: (action: logic.Action) => void): void {
  const onKeyDown = useCallback(
    (e: KeyboardEvent): void => {
      if (isShortcutMenuKeyPress(e)) {
        debug('[shortcut-menu] Detected shortcut key. Opening shortcut menu')
        e.preventDefault()
        void dispatch({ type: 'open' })
      }

      if (e.key === 'Escape') {
        void dispatch({ type: 'back' })
      }
    },
    [dispatch]
  )

  useEffect(() => {
    document.addEventListener('keydown', onKeyDown)
    return () => {
      document.removeEventListener('keydown', onKeyDown)
    }
  }, [onKeyDown])
}

type ShortcutMenuContext = {
  state: logic.State
  dispatch: (actions: logic.Action) => void
}

const ReactShortcutMenuStateContext = React.createContext<ShortcutMenuContext['state'] | undefined>(undefined)
const ReactShortcutDispatchContext = React.createContext<ShortcutMenuContext['dispatch'] | undefined>(
  undefined
)

const ShortcutMenuContextInner: FCC<{ state: ShortcutMenuContext['state'] }> = ({ state, children }) => {
  return (
    <ReactShortcutMenuStateContext.Provider value={state}>{children}</ReactShortcutMenuStateContext.Provider>
  )
}

export const ShortcutMenuContext: FCC = ({ children }) => {
  const [state, dispatch] = useReducer(logic.step, logic.initialState)
  useShortcutMenuToggler(dispatch)
  return (
    <ReactShortcutDispatchContext.Provider value={dispatch}>
      <ShortcutMenuContextInner state={state}>{children}</ShortcutMenuContextInner>
    </ReactShortcutDispatchContext.Provider>
  )
}

export function useShortcutMenuDispatch(): ShortcutMenuContext['dispatch'] {
  const context = useContext(ReactShortcutDispatchContext)
  if (context === undefined)
    throw new Error(
      'No ShortcutMenuContext found, ensure that this component is rendered inside of a <ShortcutMenuContext> at some level.'
    )

  return context
}

export function useShortcutMenuState(): ShortcutMenuContext['state'] {
  const context = useContext(ReactShortcutMenuStateContext)
  if (context === undefined)
    throw new Error(
      'No ShortcutMenuContext found, ensure that this component is rendered inside of a <ShortcutMenuContext> at some level.'
    )

  return context
}
