import _ from 'lodash'
import { useSnackbar } from 'notistack'
import React, { useState } from 'react'
import { Modal } from 'sierra-client/components/common/modals/modal'
import { useIsDebugMode } from 'sierra-client/hooks/use-is-debug-mode'
import { useSelector } from 'sierra-client/state/hooks'
import { selectLogs } from 'sierra-client/state/logging/selectors'
import { selectIsOwnerUser } from 'sierra-client/state/user/user-selector'
import { assertNever, iife } from 'sierra-domain/utils'
import { fonts } from 'sierra-ui/theming/fonts'
import { useOnChanged } from 'sierra-ui/utils'
import styled from 'styled-components'

const Pre = styled.pre`
  font-family: monospace;
`

const ReadMore = styled.div.attrs({ children: 'more' })`
  cursor: pointer;
  font-weight: ${fonts.weight.bold};
  text-transform: uppercase;
`

const LogSnacks: React.FC = () => {
  const { enqueueSnackbar } = useSnackbar()
  const logs = useSelector(selectLogs)
  const [initialLogs] = useState(() => logs)
  const [selectedLog, setSelectedLog] = useState<(typeof logs)[number]>()

  useOnChanged((previousLogs, allLogs) => {
    const offset = (previousLogs ?? initialLogs).length
    const onlyNewLogs = _.drop(allLogs, offset)
    onlyNewLogs.forEach((log, index) => {
      const indexInOriginal = index + offset
      const isDuplicate = _.isEqual(allLogs[indexInOriginal - 1], log)
      const message = iife(() => {
        switch (log.type) {
          case 'event':
            return log.message.event
          case 'page':
            return `page: ${log.message.name}`
          default:
            assertNever(log)
        }
      })

      enqueueSnackbar(`${isDuplicate ? 'duplicate: ' : ''}${message}`, {
        variant: isDuplicate ? 'warning' : 'default',
        action: () => <ReadMore onClick={() => setSelectedLog(log)} />,
      })
    })
  }, logs)

  return (
    <Modal open={selectedLog !== undefined} onClose={() => setSelectedLog(undefined)} size='large'>
      <Pre>{selectedLog && JSON.stringify(selectedLog.message, null, 2)}</Pre>
    </Modal>
  )
}

export const Logs = (): JSX.Element | null => {
  const isEnabled = useIsDebugMode()
  const isOwner = useSelector(selectIsOwnerUser)

  if (!isOwner) return null
  if (!isEnabled) return null

  return <LogSnacks />
}
