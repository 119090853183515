import React, { useState } from 'react'
import { useResolveAsset } from 'sierra-client/hooks/use-resolve-asset'
import { ImageUploadControl } from 'sierra-client/views/manage/paths/components/image-upload-control'
import { UploadImageModal } from 'sierra-client/views/upload-image-modal/upload-image-modal'
import type { AssetContext } from 'sierra-domain/asset-context'
import { ImageUnion } from 'sierra-domain/content/v2/content'
import { ImageData } from 'sierra-domain/flexible-content/types'

interface ImageUploadProps {
  value?: ImageUnion
  onChange: (image: ImageUnion) => void
  disabled?: boolean
  assetContext: AssetContext
}

export const ImageUpload: React.FC<ImageUploadProps> = ({ value, onChange, disabled, assetContext }) => {
  const [showImageModal, setShowImageModal] = useState(false)

  const resolvedImage = useResolveAsset({ image: value, assetContext, size: 'default' })

  const handleCourseImageFromStock = (image: ImageData['image']): void => {
    if (disabled === true) return

    if (image !== undefined) {
      onChange(image)
    }
  }

  function handleUpload(image: ImageData['image'] | undefined): void {
    if (image === undefined) return
    handleCourseImageFromStock(image)
    setShowImageModal(false)
  }

  return (
    <ImageUploadControl image={resolvedImage} onUpload={() => setShowImageModal(true)} disabled={disabled}>
      {showImageModal && (
        <UploadImageModal
          open={showImageModal}
          onUploadDone={(image: ImageData['image']) => handleUpload(image)}
          onClose={() => setShowImageModal(false)}
          assetContext={assetContext}
        />
      )}
    </ImageUploadControl>
  )
}
