import { JsonObject } from '@sanalabs/json'
import type Ably from 'ably'
import _ from 'lodash'
import { useEffect } from 'react'
import { UnknownAction } from 'redux'
import { DynamicThrottler } from 'sierra-client/collaboration/awareness/dynamic-throttler'
import { synchronousCachedSubscribe } from 'sierra-client/collaboration/redux-synchronous-subscriber'
import { useShowErrorToUser } from 'sierra-client/hooks/use-show-error-to-user'
import { TypedEventEmitter } from 'sierra-client/lib/typed-event-emitter'
import { store } from 'sierra-client/state/store'
import { toBase64, toUint8Array } from 'sierra-domain/base-64'
import { BaseAwarenessState } from 'sierra-domain/collaboration/types'
import {
  Awareness,
  applyAwarenessUpdate,
  encodeAwarenessUpdate,
  removeAwarenessStates,
} from 'y-protocols/awareness'

class AblyPresenceSync<T extends JsonObject> extends TypedEventEmitter<{
  error: (error: unknown) => void
  change: (states: (BaseAwarenessState & T)[]) => unknown
}> {
  private static actions: Ably.PresenceAction[] = ['enter', 'leave', 'update']

  private pendingLocalUpdate: T | undefined
  private numPendingLocalUpdates: number = 0
  private lastSyncedLocalUpdate: T | undefined

  private numPendingRemoteUpdates: number = 0
  private throttler: DynamicThrottler
  private throttledSyncLocalIntoRemote: () => NodeJS.Timeout | undefined
  private activeTimeout: NodeJS.Timeout | undefined
  private remoteIntoLocalThrottleMs = 200

  constructor(
    private readonly presence: Ably.RealtimePresence,
    private readonly connectionId: string,
    remoteIntoLocalThrottleMs?: number
  ) {
    super()

    this.throttler = new DynamicThrottler(1.0)
    this.throttledSyncLocalIntoRemote = this.throttler.throttle(this.syncLocalIntoRemote)
    this.remoteIntoLocalThrottleMs = remoteIntoLocalThrottleMs ?? this.remoteIntoLocalThrottleMs
  }

  /** init is separate from the constructor so that the user can 1) construct 2) set up listeners 3) init() */
  init(): void {
    this.presence
      .subscribe(AblyPresenceSync.actions, this.presenceHandler)
      .catch(err => this.emit('error', err))

    void this.syncRemoteIntoLocal()
  }

  private presenceHandler = (): void => {
    void this.syncRemoteIntoLocal()
  }

  // Local -> Remote

  addLocalUpdate(data: T | undefined): void {
    if (data === undefined || _.isEqual(data, {})) {
      return
    }

    this.pendingLocalUpdate = data
    this.numPendingLocalUpdates += 1

    if (this.activeTimeout !== undefined) {
      return
    }

    this.activeTimeout = this.throttledSyncLocalIntoRemote()
  }

  private syncLocalIntoRemote = (): void => {
    this.activeTimeout = undefined

    if (this.numPendingLocalUpdates < 1 || this.pendingLocalUpdate === undefined) {
      throw new Error('Expecting non-empty local update.')
    }

    if (!_.isEqual(this.pendingLocalUpdate, this.lastSyncedLocalUpdate)) {
      this.presence.update(_.cloneDeep(this.pendingLocalUpdate)).catch(err => this.emit('error', err))
    }

    this.lastSyncedLocalUpdate = this.pendingLocalUpdate
    this.pendingLocalUpdate = undefined
    this.numPendingLocalUpdates = 0
  }

  // Remote -> Local

  private syncRemoteIntoLocal(): void {
    this.numPendingRemoteUpdates += 1
    this.throttler.handleRemoteEvent()
    void this.throttledSyncRemoteIntoLocal()
  }

  private throttledSyncRemoteIntoLocal = _.throttle(async () => {
    if (this.numPendingRemoteUpdates < 1) {
      throw new Error('Expecting numPendingRemoteUpdates > 0.')
    }
    this.numPendingRemoteUpdates = 0

    let ablyStates: Ably.PresenceMessage[] = []

    try {
      ablyStates = await this.presence.get()
    } catch (err) {
      this.emit('error', err)
    }

    const states = ablyStates.map(
      p =>
        ({
          ...p.data,
          clientId: p.connectionId,
          isCurrentClient: p.connectionId === this.connectionId,
        }) satisfies BaseAwarenessState & T
    )

    this.emit('change', states)
  }, this.remoteIntoLocalThrottleMs)

  destroy(): void {
    this.throttledSyncRemoteIntoLocal.cancel()
    if (this.activeTimeout !== undefined) {
      clearTimeout(this.activeTimeout)
    }
    this.presence.unsubscribe(AblyPresenceSync.actions, this.presenceHandler)
    super.off()
  }
}

class AblyPresenceReduxSyncWrapper<T extends JsonObject> {
  public readonly ablyPresenceSync: AblyPresenceSync<T>
  private unsubscribeSelector: () => void

  constructor(
    presence: Ably.RealtimePresence,
    connectionId: string,
    private readonly setAwarenessStates: (awarenessStates: (BaseAwarenessState & T)[]) => UnknownAction,
    private readonly selectLocalAwarenessState: (state: any) => T | undefined
  ) {
    this.ablyPresenceSync = new AblyPresenceSync(presence, connectionId)

    this.ablyPresenceSync.on('change', states => {
      const latestReduxAwareness = this.selectLocalAwarenessState(store.getState())

      if (!_.isEqual(states, latestReduxAwareness)) {
        store.dispatch(this.setAwarenessStates(states))
      }
    })

    this.ablyPresenceSync.init()

    this.unsubscribeSelector = synchronousCachedSubscribe(
      store,
      selectLocalAwarenessState,
      this.ablyPresenceSync.addLocalUpdate.bind(this.ablyPresenceSync)
    )
  }
  destroy(): void {
    this.ablyPresenceSync.destroy()
    this.unsubscribeSelector()
  }
}

export const AblyPresenceReduxSync = <T extends JsonObject>({
  presence,
  connectionId,
  setAwarenessStates,
  selectLocalAwarenessState,
}: {
  presence: Ably.RealtimePresence
  connectionId: string
  setAwarenessStates: (awarenessStates: (BaseAwarenessState & T)[]) => UnknownAction
  selectLocalAwarenessState: (state: any) => T | undefined
}): null => {
  const errorHandler = useShowErrorToUser()

  useEffect(() => {
    const presenceWrapper = new AblyPresenceReduxSyncWrapper(
      presence,
      connectionId,
      setAwarenessStates,
      selectLocalAwarenessState
    )

    presenceWrapper.ablyPresenceSync.on('error', errorHandler)

    return () => presenceWrapper.destroy()
  }, [connectionId, errorHandler, presence, selectLocalAwarenessState, setAwarenessStates])

  return null
}

type StateChangeEvent = {
  added: number[]
  updated: number[]
  removed: number[]
}

type EncodedStateChangeEvent = {
  updateBase64: string
  origin: number
}

/**
 * @deprecated: `AblyYjsAwarenessWrapper` is going to be removed.
 * If you need to access a yDoc's awareness, use the `useAblyYdoc` hook instead.
 */
export class AblyYjsAwarenessWrapper {
  private readonly ablyPresenceSync: AblyPresenceSync<EncodedStateChangeEvent>
  private readonly clientId: number
  private destroyAwareness: () => void

  constructor(
    private readonly awareness: Awareness,
    presence: Ably.RealtimePresence,
    connectionId: string
  ) {
    this.ablyPresenceSync = new AblyPresenceSync(presence, connectionId)

    this.clientId = awareness.clientID
    const awarenessChangeListener = (event: StateChangeEvent): void => {
      if (event.added.includes(this.clientId) || event.updated.includes(this.clientId)) {
        const update: EncodedStateChangeEvent = {
          updateBase64: toBase64(encodeAwarenessUpdate(this.awareness, [this.clientId])),
          origin: this.clientId,
        }
        this.ablyPresenceSync.addLocalUpdate(update)
      }
    }
    this.awareness.on('update', awarenessChangeListener) //This needs to be 'update' and not 'change' since we want to be able to update an unchanged awareness
    this.destroyAwareness = () => this.awareness.off('update', awarenessChangeListener)
    this.ablyPresenceSync.on('change', updates => {
      for (const update of updates) {
        applyAwarenessUpdate(this.awareness, toUint8Array(update.updateBase64), update.origin)
      }
      const remainingClients = new Set(updates.map(update => update.origin))
      remainingClients.add(this.clientId)
      const removedClients = Array.from(this.awareness.getStates().keys()).filter(
        clientId => !remainingClients.has(clientId)
      )
      if (removedClients.length > 0) {
        removeAwarenessStates(this.awareness, removedClients, this.clientId)
      }
    })

    this.ablyPresenceSync.init()
  }

  destroy(): void {
    this.ablyPresenceSync.destroy()
    this.destroyAwareness()
  }
}
