import * as React from 'react'
import {
  PivotPagination,
  PivotTableHeader,
  TableBody,
} from 'sierra-client/features/insights/tabular/pivot/components'
import * as U from 'sierra-client/lib/tabular/components/util'
import { scrollViewStyles } from 'sierra-ui/primitives'
import { isDefined } from 'sierra-ui/utils/is-defined'
import styled from 'styled-components'

const Table = styled.table`
  width: 100%;
`

// If height is not fixed, allow parent to scroll
const TableFlowContainer = styled.div<{ $width: number; $height?: number }>`
  ${props => (isDefined(props.$height) ? scrollViewStyles : ``)}
  width: ${props => props.$width}px;
  height: ${props => props.$height}px;
`

const StickyTableCaption = styled.caption`
  position: sticky;
  top: 0;
  z-index: 12;
  margin-left: 200px;
` // A bit of a hack to make it melt together with table headers

export const PivotTabular: React.FC<{
  currentIndex: number
  totalSize: number
  onChange: (newIndex: number) => void
  pivotTableLimit: number
  footer?: React.ReactNode
}> = ({ totalSize, currentIndex, onChange, pivotTableLimit, footer = null }) => {
  return React.useMemo(
    () => (
      <U.WithParentSize>
        {({ width, height, setScrollRef, scrollRef }) => (
          <TableFlowContainer $width={width} $height={height} ref={setScrollRef}>
            <Table>
              {totalSize > pivotTableLimit && (
                <StickyTableCaption>
                  <PivotPagination
                    currentIndex={currentIndex}
                    totalSize={totalSize}
                    onChange={onChange}
                    pivotTableLimit={pivotTableLimit}
                  />
                </StickyTableCaption>
              )}
              <PivotTableHeader />
              <TableBody containerRef={scrollRef} />
              {footer}
            </Table>
          </TableFlowContainer>
        )}
      </U.WithParentSize>
    ),
    [currentIndex, footer, onChange, pivotTableLimit, totalSize]
  )
}
