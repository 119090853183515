import _ from 'lodash'

import React, { useState } from 'react'
import {
  useSelectCurrentCard,
  useSelectCurrentCardBackgroundColor,
} from 'sierra-client/components/liveV2/hooks/use-select-current-card'
import { TransparentNowButton } from 'sierra-client/features/sana-now/header/buttons'
import {
  DefaultParticipantsList,
  PollParticipantsList,
  QuestionCardParticipantsList,
  ReflectionCardParticipantsList,
  SlidingScaleParticipantsList,
} from 'sierra-client/features/sana-now/header/participants-popover/participants-lists'
import { SEARCH_HEIGHT } from 'sierra-client/features/sana-now/header/participants-popover/utils'
import { usePresenceAtomParticipantIds } from 'sierra-client/features/sana-now/hooks/use-presence-atom'
import { useTranslation } from 'sierra-client/hooks/use-translation'
import { useSelector } from 'sierra-client/state/hooks'
import { selectIsFacilitator } from 'sierra-client/state/live-session/selectors'
import { useUsersLegacy } from 'sierra-client/state/users/hooks'
import { LightUser } from 'sierra-domain/user'
import { iife, isDefined } from 'sierra-domain/utils'
import { Drawer, Icon, Popover } from 'sierra-ui/components'
import { IconButton, ScrollView, View } from 'sierra-ui/primitives'
import { token } from 'sierra-ui/theming'
import { fonts } from 'sierra-ui/theming/fonts'
import styled from 'styled-components'

const CloseButton = styled(IconButton).attrs({
  iconId: 'close',
  variant: 'transparent',
})`
  z-index: 3;
  position: absolute;
  top: 18px;
  right: 18px;
`

const OuterContainer = styled(ScrollView).attrs({
  radius: 'size-20',
  direction: 'column',
  gap: 'none',
})`
  max-height: 350px;
  width: 420px;
  padding: 0 24px 24px 24px;
`

const MobileOuterContainer = styled(OuterContainer)`
  position: relative;
  max-height: unset;
  width: unset;
`

const SearchContainer = styled(View)`
  position: sticky;
  width: 100%;
  height: ${SEARCH_HEIGHT}px;
  flex-shrink: 0;
  top: 0;
  background-color: ${token('elevated/background')};
  z-index: 2;
`

const FilterInput = styled.input`
  ${fonts.body.small}
  width: 100%;
  transition: border-color 200ms ease;
  background: transparent;
  color: ${token('foreground/primary')};

  &::placeholder {
    color: ${token('foreground/primary')};
    opacity: 0.5;
  }
`

const SearchIcon = styled(Icon).attrs({
  iconId: 'search',
})`
  color: ${token('foreground/primary')};
`

const PopoverContent: React.FC<{ users: LightUser[] }> = ({ users }) => {
  const { t } = useTranslation()
  const isCurrentUserFacilitator = useSelector(selectIsFacilitator)
  const currentCard = useSelectCurrentCard()
  const [searchTerm, setSearchTerm] = useState<string>('')

  return (
    <>
      <SearchContainer>
        <SearchIcon />
        <FilterInput
          type='text'
          maxLength={50}
          spellCheck='false'
          value={searchTerm}
          onChange={e => setSearchTerm(e.target.value)}
          placeholder={t('dictionary.search') + '...'}
        />
      </SearchContainer>

      {iife(() => {
        if (!isCurrentUserFacilitator) {
          return <DefaultParticipantsList users={users} searchTerm={searchTerm} />
        }

        switch (currentCard?.data.type) {
          case 'question-card':
            return <QuestionCardParticipantsList users={users} searchTerm={searchTerm} />
          case 'poll':
            return <PollParticipantsList users={users} searchTerm={searchTerm} />
          case 'reflections':
            return <ReflectionCardParticipantsList users={users} searchTerm={searchTerm} />
          case 'sliding-scale':
            return <SlidingScaleParticipantsList users={users} searchTerm={searchTerm} />
          default:
            return <DefaultParticipantsList users={users} searchTerm={searchTerm} />
        }
      })}
    </>
  )
}

export const ParticipantsPopoverButton = (): JSX.Element => {
  const participantIds = usePresenceAtomParticipantIds()
  const users = _.uniqBy(useUsersLegacy(participantIds).filter(isDefined), user => user.uuid)

  const backgroundColor = useSelectCurrentCardBackgroundColor()

  const [open, setOpen] = useState(false)

  return (
    <Popover
      align='end'
      sideOffset={8}
      renderTrigger={() => (
        <TransparentNowButton
          $backgroundColor={backgroundColor}
          icon='user'
          decoratorPosition='left'
          variant='primary'
          onClick={() => {
            setOpen(!open)
          }}
        >
          {users.length.toString()}
        </TransparentNowButton>
      )}
    >
      <OuterContainer>
        <PopoverContent users={users} />
      </OuterContainer>
    </Popover>
  )
}

export const MobileParticipantsPopoverButton: React.FC = () => {
  const participantIds = usePresenceAtomParticipantIds()
  const users = _.uniqBy(useUsersLegacy(participantIds).filter(isDefined), user => user.uuid)
  const backgroundColor = useSelectCurrentCardBackgroundColor()
  const [open, setOpen] = useState(false)

  return (
    <>
      <TransparentNowButton
        $backgroundColor={backgroundColor}
        icon='user'
        decoratorPosition='left'
        variant='primary'
        onClick={() => {
          setOpen(!open)
        }}
      >
        {users.length.toString()}
      </TransparentNowButton>
      <Drawer disableScrollbarGutter overlayVariant='none' onClose={() => setOpen(false)} open={open}>
        <CloseButton onClick={() => setOpen(false)} />
        <MobileOuterContainer>
          <PopoverContent users={users} />
        </MobileOuterContainer>
      </Drawer>
    </>
  )
}
