import React, { useEffect, useState } from 'react'
import { usePost } from 'sierra-client/hooks/use-post'
import { useTranslation } from 'sierra-client/hooks/use-translation'
import { getGlobalRouter } from 'sierra-client/router'
import { useSelector } from 'sierra-client/state/hooks'
import {
  selectIsLoggedIn,
  selectIsPendingCodeVerification,
  selectIsPendingCodeVerificationOrLoggedIn,
} from 'sierra-client/state/user/user-selector'
import { getUserErrorTranslationKey } from 'sierra-client/utils/translation-utils'
import { AuthenticationLayout } from 'sierra-client/views/authentication/authentication-layout'
import { AssignUser } from 'sierra-client/views/authentication/native/auth-views/assign-user'
import { ResetPassword } from 'sierra-client/views/authentication/native/auth-views/reset-password'
import { SignIn } from 'sierra-client/views/authentication/native/auth-views/sign-in'
import { SignUp } from 'sierra-client/views/authentication/native/auth-views/sign-up'
import { SignUpWithEmail } from 'sierra-client/views/authentication/native/auth-views/sign-up-with-email'
import { VerifyCode } from 'sierra-client/views/authentication/native/auth-views/verify-code'
import { AuthenticationContent } from 'sierra-client/views/authentication/native/components/authentication-content'
import { AuthenticationContainer } from 'sierra-client/views/authentication/native/components/common'
import { isLeft } from 'sierra-domain/either'
import { XRealtimeAuthCheckGroupInvite } from 'sierra-domain/routes'
import { Text } from 'sierra-ui/primitives'

type TokenErrorProps = {
  error: string
}

const TokenError: React.FC<TokenErrorProps> = ({ error }) => {
  const { t } = useTranslation()

  return (
    <AuthenticationContainer>
      <AuthenticationContent message={t('create-account.heading')}>
        <Text size='small' color='redBright'>
          {error}
        </Text>
      </AuthenticationContent>
    </AuthenticationContainer>
  )
}

type Props = {
  token: string
}

type Mode = 'sign-up' | 'sign-up-with-email' | 'sign-in' | 'reset-password'
type AuthView =
  | 'sign-up'
  | 'sign-up-with-email'
  | 'assign-user'
  | 'sign-in'
  | 'reset-password'
  | 'sign-in-verify-code'

function computeAuthView({
  isLoggedIn,
  wasCreated,
  mode,
  isPendingCodeVerification,
}: {
  isLoggedIn: boolean
  wasCreated: boolean
  mode: Mode
  isPendingCodeVerification: boolean
}): AuthView {
  if (isPendingCodeVerification) return 'sign-in-verify-code'
  if (isLoggedIn && !wasCreated) return 'assign-user'
  return mode
}

export const GroupInvite: React.FC<Props> = ({ token }) => {
  const { t } = useTranslation()
  const isLoggedIn = useSelector(selectIsLoggedIn)
  const isPendingCodeVerificationOrLoggedIn = useSelector(selectIsPendingCodeVerificationOrLoggedIn)
  const isPendingCodeVerification = useSelector(selectIsPendingCodeVerification)

  const [tokenError, setTokenError] = useState<string | undefined>(undefined)
  const [tokenChecked, setTokenChecked] = useState<boolean>(false)
  const [groupName, setGroupName] = useState<string>('')
  const [wasCreated, setWasCreated] = useState<boolean>(false)
  const [wasAssigned, setWasAssigned] = useState<boolean>(false)
  const { postWithUserErrorCode } = usePost()
  const [mode, setMode] = useState<Mode>('sign-up')

  const [email, setEmail] = useState('')

  const switchToSignUpWithEmail = (email: string): void => {
    setEmail(email)
    setMode('sign-up-with-email')
  }

  const switchToSignIn = (): void => {
    setEmail(email)
    setMode('sign-in')
  }

  const view = computeAuthView({ isLoggedIn, wasCreated, mode, isPendingCodeVerification })

  useEffect(() => {
    void (async () => {
      const result = await postWithUserErrorCode(XRealtimeAuthCheckGroupInvite, {
        token,
      })

      if (isLeft(result)) {
        setTokenError(t(getUserErrorTranslationKey(result.left)))
      } else {
        setTokenChecked(true)
        setGroupName(result.right.name)
      }
    })()
  }, [token, postWithUserErrorCode, t])

  useEffect(() => {
    if (tokenChecked && isPendingCodeVerificationOrLoggedIn && (wasCreated || wasAssigned)) {
      void getGlobalRouter().navigate({ to: '/' })
    }
  }, [isPendingCodeVerificationOrLoggedIn, tokenChecked, wasCreated, wasAssigned])

  if (tokenError !== undefined)
    return (
      <AuthenticationLayout>
        <TokenError error={tokenError} />
      </AuthenticationLayout>
    )

  const views: Record<AuthView, JSX.Element | null> = {
    'reset-password': <ResetPassword switchToLogIn={() => setMode('sign-in')} />,
    'sign-up': (
      <SignUp
        switchToSignUpWithEmail={email => switchToSignUpWithEmail(email)}
        switchToSignIn={() => switchToSignIn()}
      />
    ),
    'sign-up-with-email': (
      <SignUpWithEmail
        mode={{ type: 'group-invite', group: groupName, token }}
        email={email}
        onCreate={() => setWasCreated(true)}
        switchToSignUp={() => setMode('sign-up')}
      />
    ),
    'sign-in': (
      <SignIn
        switchToSignUp={() => setMode('sign-up')}
        switchToForgotPassword={() => setMode('reset-password')}
      />
    ),
    'sign-in-verify-code': <VerifyCode />,
    'assign-user': <AssignUser token={token} onAssign={() => setWasAssigned(true)} name={groupName} />,
  }

  const inner = views[view]

  return inner !== null ? (
    <AuthenticationLayout>
      <AuthenticationContainer>{inner}</AuthenticationContainer>
    </AuthenticationLayout>
  ) : null
}
