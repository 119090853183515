import { AnimatePresence, motion } from 'framer-motion'
import { BreakoutRoomData } from 'sierra-domain/flexible-content/types'
import { Input, MenuItem } from 'sierra-ui/components'
import { InputPrimitive, View } from 'sierra-ui/primitives'
import { SingleSelectDropdown } from 'sierra-ui/primitives/menu-dropdown'
import { LightTokenProvider, token } from 'sierra-ui/theming'
import { fonts } from 'sierra-ui/theming/fonts'
import styled from 'styled-components'

const CardContainer = styled(View).attrs({
  grow: true,
  background: 'black',
})<{ readOnly: boolean }>`
  position: relative;
  align-self: stretch;
  justify-content: center;

  ${p => p.readOnly && 'pointer-events: none;'}
`

const SettingsWrapper = styled(View)`
  max-width: 25em;
  min-width: 16.5em;
  padding: 2em 2em 0.5em 2em;
  background: ${token('elevated/background')};
  border-radius: 1em;
  margin: 2em;
  min-height: 23em;
`
const OptionHeading = styled.div`
  font-weight: ${fonts.weight.bold};
  flex: 1;
  padding-right: 1rem;
  color: ${token('foreground/primary')};
`

const OptionDescription = styled.div`
  font-weight: ${fonts.weight.thin};
  flex: 1;
  padding-right: 1rem;
  padding-top: 0;
  color: ${token('foreground/muted')};
`

const PreviewWrapper = styled.div`
  background: ${token('elevated/background')};
  border-radius: 1em;
  margin: 2em;
  text-align: center;
  padding: 2em;
  max-width: 28em;
  width: 100%;
  min-height: 23em;
  display: flex;
  flex-direction: column;
`

const TopicInput = styled(Input)`
  max-width: 20rem;
  margin-bottom: 2rem;
`

const RoomsPreview = styled(motion.div)`
  width: 100%;
  display: flex;
  flex-direction: row;
  justify-content: center;
  flex-wrap: wrap;
  margin-bottom: 1em;

  > div:first-child,
  > div:last-child {
    opacity: 0.4;
    transform: scale(0.8);
  }
`

const RoomPreview = styled.div<{ size: number }>`
  width: ${props => (props.size >= 5 ? '7em' : '6em')};
  height: 4em;
  margin: 0.8em 0.4em;
  display: flex;
  flex-wrap: wrap;
  align-content: center;
  justify-content: center;
`

const ParticipantPlaceholder = styled(motion.div)`
  background: ${token('foreground/muted')};
  width: 2em;
  height: 2em;
  border-radius: 0.3em;
  margin: 0.1em;
`

const PreviewTitle = styled.p`
  font-weight: ${fonts.weight.bold};
  padding: 0.5rem;
`

const PreviewDescription = styled.p`
  font-weight: ${fonts.weight.regular};
  color: ${token('foreground/secondary')};
  max-width: 18em;
  margin-left: auto;
  margin-right: auto;
`

const AnimatedParticipantPlaceholder = (): JSX.Element => (
  <ParticipantPlaceholder
    layout
    initial={{ opacity: 0 }}
    animate={{ opacity: 1 }}
    exit={{ opacity: 0 }}
    transition={{ duration: 0.75, ease: [0.25, 0.1, 0.25, 1] }}
  />
)

export type BreakoutRoomCardProps = {
  data: BreakoutRoomData
  onFormFieldChange?: (
    change:
      | { key: 'algorithm'; value: 'random' }
      | { key: 'duration'; value: number | '' }
      | { key: 'roomSize'; value: number }
      | { key: 'topic'; value: string }
  ) => void
}

export const BreakoutRoomCard: (props: BreakoutRoomCardProps) => JSX.Element = props => {
  const readOnly = props.onFormFieldChange === undefined
  const participants = [...Array(props.data.settings.roomSize).keys()]
  const maxDurationInMinutes = 5 * 60

  const roomsizeItems: MenuItem[] = [
    { id: '2', type: 'label', label: '2 people' },
    { id: '3', type: 'label', label: '3 people' },
    { id: '4', type: 'label', label: '4 people' },
    { id: '5', type: 'label', label: '5 people' },
    { id: '6', type: 'label', label: '6 people' },
  ]

  const selectedRoomSize = roomsizeItems.find(item => item.id === props.data.settings.roomSize.toString())

  const algorithmItems: MenuItem[] = [{ id: 'random', type: 'label', label: 'Randomly populate' }]

  return (
    <CardContainer readOnly={readOnly}>
      <LightTokenProvider>
        <SettingsWrapper direction='column' gap='small'>
          <View direction='column'>
            <OptionHeading>Preferred room size</OptionHeading>
            <OptionDescription>Select the amount of people per room</OptionDescription>

            <SingleSelectDropdown
              menuItems={roomsizeItems}
              selectedItem={selectedRoomSize}
              onSelect={item =>
                props.onFormFieldChange?.({ key: 'roomSize', value: Number.parseInt(item.id) })
              }
            />
          </View>
          <View direction='column'>
            <OptionHeading>Split the group</OptionHeading>
            <OptionDescription>Select the amount of people per room</OptionDescription>

            <SingleSelectDropdown
              menuItems={algorithmItems}
              selectedItem={algorithmItems[0]}
              onSelect={item => {
                if (item.id === 'random') props.onFormFieldChange?.({ key: 'algorithm', value: item.id })
              }}
            />
          </View>
          <View direction='column'>
            <OptionHeading>Duration (in minutes)</OptionHeading>

            <InputPrimitive
              type='number'
              id='duration'
              placeholder='Leave blank for unbounded'
              value={
                props.data.settings.duration !== undefined ? props.data.settings.duration.toString() : ''
              }
              onChange={e => {
                const minutes = Number.parseInt(e.target.value)
                props.onFormFieldChange?.({
                  key: 'duration',
                  value:
                    e.target.value === '' || Number.isNaN(minutes)
                      ? ''
                      : Math.min(minutes, maxDurationInMinutes),
                })
              }}
            />
          </View>
        </SettingsWrapper>

        <PreviewWrapper>
          <TopicInput
            id='breakout-room-topic'
            placeholder='Add a topic...'
            onChange={event => {
              const topic = event.target.value
              props.onFormFieldChange?.({ key: 'topic', value: topic })
            }}
            value={props.data.topic ?? ''}
          />
          <RoomsPreview>
            <AnimatePresence>
              <RoomPreview key={1} size={props.data.settings.roomSize}>
                {participants.map(index => (
                  <AnimatedParticipantPlaceholder key={index} />
                ))}
              </RoomPreview>
              <RoomPreview key={2} size={props.data.settings.roomSize}>
                {participants.map(index => (
                  <AnimatedParticipantPlaceholder key={index} />
                ))}
              </RoomPreview>
              <RoomPreview key={3} size={props.data.settings.roomSize}>
                {participants.map(index => (
                  <AnimatedParticipantPlaceholder key={index} />
                ))}
              </RoomPreview>
            </AnimatePresence>
          </RoomsPreview>
          <PreviewTitle>Automatically creating rooms</PreviewTitle>
          <PreviewDescription>
            {`The group will be automatically split to fit ${props.data.settings.roomSize} people in each room`}
          </PreviewDescription>
        </PreviewWrapper>
      </LightTokenProvider>
    </CardContainer>
  )
}
