import type { IRemoteAudioTrack, IRemoteVideoTrack } from 'agora-rtc-sdk-ng'
import { useEffect, useState } from 'react'
import { useVideoCallService } from 'sierra-client/components/liveV2/live-context'
import { getVideoCallService } from 'sierra-client/components/liveV2/services/video-call-service'
import { VideoCallService } from 'sierra-client/components/liveV2/services/video-call-service/video-call-service'
import { useSelector } from 'sierra-client/state/hooks'
import { selectRemoteParticipant } from 'sierra-client/state/live/selectors'

export const useRemoteParticipantVideoTrack = (
  remoteParticipantId?: string
): IRemoteVideoTrack | undefined => {
  const [videoTrack, setVideoTrack] = useState<IRemoteVideoTrack | undefined>(undefined)

  useEffect(() => {
    let aborted: boolean = false
    let videoCallService: VideoCallService | undefined
    if (remoteParticipantId === undefined) return

    const setTrack = async (): Promise<void> => {
      const track = await videoCallService?.getRemoteUserVideoTrack(remoteParticipantId)

      if (aborted) return
      setVideoTrack(track)
    }

    void (async () => {
      videoCallService = await getVideoCallService()
      videoCallService.on('participants-changed', setTrack)
      void setTrack()
    })()

    return () => {
      aborted = true
      setVideoTrack(undefined)
      videoCallService?.off('participants-changed', setTrack)
    }
  }, [remoteParticipantId])

  return videoTrack
}

export const useRemoteParticipantAudioTrack = (
  remoteParticipantId?: string
): IRemoteAudioTrack | undefined => {
  const [audioTrack, setaudioTrack] = useState<IRemoteAudioTrack | undefined>(undefined)
  const remoteUser = useSelector(selectRemoteParticipant)(remoteParticipantId)
  const videoCallService = useVideoCallService()

  useEffect(() => {
    let aborted: boolean = false
    if (videoCallService === undefined || remoteUser?.id === undefined) return

    const setTrack = async (): Promise<void> => {
      const track = await videoCallService.getRemoteUserAudioTrack(remoteUser.id)

      if (aborted) return
      setaudioTrack(track)
    }

    void setTrack()

    return () => {
      aborted = true
    }
  }, [videoCallService, remoteUser?.id, remoteUser?.isSubscribedToAudio])

  return audioTrack
}
