import { debounce } from 'lodash'
import { useMemo, useState } from 'react'
import { TableInstance } from 'react-table'

type GlobalFilterProps<T extends Record<string, unknown> = Record<string, unknown>> = {
  tableInstance: TableInstance<T>
  debounceMs?: number
  render: (props: { value: string; onChange: (value: string) => void }) => JSX.Element
}

export const GlobalFilter = <T extends Record<string, unknown> = Record<string, unknown>>({
  tableInstance,
  debounceMs,
  render,
}: GlobalFilterProps<T>): JSX.Element => {
  const { state, setGlobalFilter } = tableInstance
  const [filterValue, setFilterValue] = useState<string>((state.globalFilter as string | undefined) ?? '')
  const debouncedSetFilter = useMemo(
    () => debounce(setGlobalFilter, debounceMs),
    [setGlobalFilter, debounceMs]
  )

  return render({
    value: filterValue,
    onChange: value => {
      setFilterValue(value)
      debouncedSetFilter(value)
    },
  })
}
