import { Logger } from 'sierra-client/core/logging/logger'

export const homePageContentCardClickedLogger = Logger.trackLoggerWithExtra<
  { contentTitle: string; contentType: string; section: string },
  { contentTitle: string; contentType: string; section: string }
>(
  'home_page_content_card_clicked',

  input => {
    return {
      ...input,
    }
  }
)

export const homePageViewAllClickedLogger = Logger.trackLoggerWithExtra<
  { section: string },
  { section: string }
>(
  'home_page_view_all_clicked',

  input => {
    return {
      ...input,
    }
  }
)
