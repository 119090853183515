/* eslint-disable @typescript-eslint/explicit-function-return-type */
import { UseQueryResult, useInfiniteQuery, useMutation } from '@tanstack/react-query'
import { useCallback } from 'react'
import { gcsBlobUpload } from 'sierra-client/api/blob-upload-with-progress'
import { queryClient } from 'sierra-client/api/query-client'
import { useHasOrganizationPermission } from 'sierra-client/hooks/use-permissions'
import { usePost } from 'sierra-client/hooks/use-post'
import { getCachedQueryKey, typedInvalidateQuery, typedPost, useCachedQuery } from 'sierra-client/state/api'
import {
  FileTooBigError,
  UploadMediaWithProgress,
} from 'sierra-client/views/v3-author/common/media-uploader/types'
import {
  GetExerciseRequest,
  GetHomeworkSubmissionsForUserRequest,
  HomeworkDetailsRequest,
  HomeworkDetailsResponse,
  ListExercisesRequest,
  PostHomeworkFileResponse,
  PostHomeworkSubmissionRequest,
  SetFileUploadedRequest,
  SubmitReviewRequest,
} from 'sierra-domain/homework'
import {
  XRealtimeAdminHomeworkCountMySubmissionsAwatingReview,
  XRealtimeAdminHomeworkGetHomework,
  XRealtimeAdminHomeworkHomeworkDetails,
  XRealtimeAdminHomeworkListHomeworks,
  XRealtimeAdminHomeworkSubmitReview,
  XRealtimeStrategySelfPacedContentHomeworkListPreviousSubmissions,
  XRealtimeStrategySelfPacedContentHomeworkSetFileUploaded,
  XRealtimeStrategySelfPacedContentHomeworkSubmitHomework,
  XRealtimeStrategySelfPacedContentHomeworkUploadFile,
} from 'sierra-domain/routes'

const MAX_FILE_SIZE_GB = 6
const MAX_FILE_SIZE_BYTES = MAX_FILE_SIZE_GB * 1024 * 1024 * 1024

type UploadHomeworkFile = UploadMediaWithProgress<PostHomeworkFileResponse>

export const useUploadHomework = (): { uploadHomework: UploadHomeworkFile } => {
  const mutation = useMutation({
    mutationFn: async (data: SetFileUploadedRequest) =>
      typedPost(XRealtimeStrategySelfPacedContentHomeworkSetFileUploaded, data),
  })
  const { postWithUserErrorException } = usePost()

  const uploadHomework = useCallback<UploadHomeworkFile>(
    async (blob, assetContext, onProgress) => {
      if (blob.size > MAX_FILE_SIZE_BYTES) {
        throw new FileTooBigError(`The file is too large (max size ${MAX_FILE_SIZE_GB} GB)`)
      }

      // TODO(gustav): Upload to new buckets
      const { fileId, url: uploadUrl } = await postWithUserErrorException(
        XRealtimeStrategySelfPacedContentHomeworkUploadFile,
        {}
      )

      await gcsBlobUpload({
        url: uploadUrl,
        blob,
        contentType: blob.type,
        onProgress: progress => onProgress('uploading', progress),
      })

      const file = blob as File

      const data = {
        fileId,
        fileName: file.name,
        fileSize: file.size,
      }

      const result = await mutation.mutateAsync(data)
      return result
    },
    [postWithUserErrorException, mutation]
  )

  return { uploadHomework }
}

export const useHomeworkSubmissionsForLearner = ({
  courseId,
  fileId,
}: GetHomeworkSubmissionsForUserRequest) => {
  return useCachedQuery(
    XRealtimeStrategySelfPacedContentHomeworkListPreviousSubmissions,
    {
      courseId,
      fileId,
    },
    { staleTime: 60 * 1000 }
  )
}

export const useHomeworkSubmissionMutation = () => {
  const mutation = useMutation({
    mutationFn: async (params: PostHomeworkSubmissionRequest) => {
      await typedPost(XRealtimeStrategySelfPacedContentHomeworkSubmitHomework, params)
    },
    onSettled: async (data, error, variables) => {
      await typedInvalidateQuery(XRealtimeStrategySelfPacedContentHomeworkListPreviousSubmissions, {
        courseId: variables.courseId,
        fileId: variables.fileId,
      })
    },
  })

  return mutation
}

export const useHomeworkDetailsQuery = (
  params: HomeworkDetailsRequest
): UseQueryResult<HomeworkDetailsResponse> => {
  return useCachedQuery(XRealtimeAdminHomeworkHomeworkDetails, params)
}

export const useGetHomeworksSubmissionAssingedCount = (): number => {
  const canManageHomeworks = useHasOrganizationPermission('MANAGE_HOMEWORK')
  const result = useCachedQuery(
    XRealtimeAdminHomeworkCountMySubmissionsAwatingReview,
    {},
    { enabled: canManageHomeworks }
  )

  return result.data?.count ?? 0
}

export const useGetHomeworksPill = (): string | undefined => {
  const count = useGetHomeworksSubmissionAssingedCount()
  return count > 0 ? `${count}` : undefined
}

export const MANAGE_HOMEWORK_TABULAR_QUERY_KEYS = ['graphql', 'homeworks-tabular'] as const

export const useSubmissionReviewMutation = () => {
  const mutation = useMutation({
    mutationFn: async (params: SubmitReviewRequest & { homeworkId: string }) => {
      const { homeworkId, ...submitReviewRequestParams } = params
      await typedPost(XRealtimeAdminHomeworkSubmitReview, submitReviewRequestParams)
    },
    onSettled: async (data, error, variables) => {
      await typedInvalidateQuery(XRealtimeAdminHomeworkHomeworkDetails, { homeworkId: variables.homeworkId })
      await typedInvalidateQuery(XRealtimeAdminHomeworkCountMySubmissionsAwatingReview, {})
      await queryClient.invalidateQueries({ queryKey: MANAGE_HOMEWORK_TABULAR_QUERY_KEYS })
    },
  })

  return mutation
}

export const useHomeworkQuery = (input: GetExerciseRequest) => {
  return useCachedQuery(XRealtimeAdminHomeworkGetHomework, input)
}

const PAGINATION_STEP = 30

export const useListHomeworksQuery = (input: ListExercisesRequest) => {
  const queryKey = getCachedQueryKey(XRealtimeAdminHomeworkListHomeworks, input)

  return useInfiniteQuery({
    queryKey,
    queryFn: ({ pageParam }) =>
      typedPost(XRealtimeAdminHomeworkListHomeworks, {
        commonFilters: {
          maxResults: pageParam,
          reviewerId: input.commonFilters.reviewerId,
          sortBy: input.commonFilters.sortBy,
        },
      }),
    initialPageParam: PAGINATION_STEP,
    getNextPageParam: lastPage =>
      lastPage.hasMore === true ? lastPage.data.length + PAGINATION_STEP : undefined,
  })
}
