import React, { useMemo } from 'react'
import { getAvatarColor, getAvatarUrl } from 'sierra-client/api/graphql/util/convert-gql-avatar'
import { useResolveAsset } from 'sierra-client/hooks/use-resolve-asset'
import { useTranslation } from 'sierra-client/hooks/use-translation'
import {
  InsightsContentQueryRef,
  InsightsContentSuggestion,
} from 'sierra-client/views/insights/insights-content-search/types'
import { contentTypeToTranslation } from 'sierra-client/views/insights/insights-content-search/utils'
import { CourseId, PathId } from 'sierra-domain/api/nano-id'
import { ProgramId } from 'sierra-domain/api/uuid'
import { AssetContext } from 'sierra-domain/asset-context'
import { assertNever } from 'sierra-domain/utils'
import { RoundAvatar } from 'sierra-ui/components'
import { Text, View } from 'sierra-ui/primitives'
import styled from 'styled-components'

const ContentThumbnail = styled.img`
  border-radius: 6px;
  width: 48px;
  height: 32px;
  object-fit: cover;
`

const ContentEmpty = styled.div`
  border-radius: 6px;
  width: 48px;
  height: 32px;
  background-color: #f0f0f0;
`

/* We need to apply overflow hidden to several elements to make the ellipsis work */
const Overflow = styled(View)`
  overflow: hidden;
`

const Ellipsis = styled(Text)`
  overflow: hidden;
  text-overflow: ellipsis;
`

type SuggestionItem = {
  item: InsightsContentSuggestion
  onClick: (() => void) | undefined
  onMouseEnter: (() => void) | undefined
}

const WrappedContentThumbnail: React.FC<{ queryRef: InsightsContentQueryRef }> = ({ queryRef }) => {
  const assetContext: AssetContext = useMemo(() => {
    const [, contentId] = queryRef.contentId.split(':')

    switch (queryRef.contentType) {
      case 'course':
        return { type: 'course', courseId: CourseId.parse(contentId) }
      case 'path':
        return { type: 'path', pathId: PathId.parse(contentId) }
      case 'program':
        return { type: 'program', programId: ProgramId.parse(contentId) }
      default:
        assertNever(queryRef.contentType)
    }
  }, [queryRef])

  const imageSrc = useResolveAsset({ image: queryRef.image, assetContext, size: 'default' })

  return (
    <>
      {queryRef.image !== undefined ? (
        <ContentThumbnail src={imageSrc} alt={queryRef.title} />
      ) : (
        <ContentEmpty />
      )}
    </>
  )
}

export const SuggestionItem: React.FC<SuggestionItem> = ({ item, onClick, onMouseEnter }) => {
  const { t } = useTranslation()
  const name = item.ref.title.split(' ')

  return (
    <Overflow grow onClick={onClick} onMouseEnter={onMouseEnter}>
      {((): React.ReactNode => {
        switch (item.ref.refType) {
          case 'user':
            return (
              <>
                <RoundAvatar
                  firstName={name[0]}
                  lastName={name[1]}
                  color={getAvatarColor(item.ref.avatar)}
                  src={getAvatarUrl(item.ref.userId, item.ref.avatar)}
                />
                <Overflow direction='column' gap='2'>
                  <Overflow>
                    <Ellipsis bold>{item.ref.title}</Ellipsis>
                  </Overflow>
                  <Overflow>
                    <Ellipsis color='foreground/secondary'>{item.ref.email}</Ellipsis>
                  </Overflow>
                </Overflow>
              </>
            )
          case 'content':
            // content (courses, paths, programs)
            return (
              <>
                {item.ref.image !== undefined ? (
                  <WrappedContentThumbnail queryRef={item.ref} />
                ) : (
                  <ContentEmpty />
                )}
                <Overflow direction='column' gap='2'>
                  <Overflow>
                    <Ellipsis bold>{item.ref.title}</Ellipsis>
                  </Overflow>
                  <Overflow>
                    <Ellipsis color='foreground/secondary'>
                      {t(contentTypeToTranslation(item.ref.contentType))}
                    </Ellipsis>
                  </Overflow>
                </Overflow>
              </>
            )
          case 'group':
            return (
              <>
                <RoundAvatar
                  firstName={name[0]}
                  lastName={name[1]}
                  color={getAvatarColor(item.ref.avatar)}
                  src={undefined}
                />
                <Overflow direction='column' gap='2'>
                  <Overflow>
                    <Ellipsis bold>{item.ref.title}</Ellipsis>
                  </Overflow>
                </Overflow>
              </>
            )
        }
      })()}
    </Overflow>
  )
}
