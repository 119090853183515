import { motion } from 'framer-motion'
import { FCC } from 'sierra-ui/types'
import styled from 'styled-components'

const FadingContainer = styled(motion.div)`
  width: 100%;
`

const sharedDuration = 0.15
const ease = 'easeInOut'

export const Fading: FCC<{ duration?: number }> = ({ children, duration = sharedDuration }) => {
  return (
    <FadingContainer
      variants={{
        hidden: {
          opacity: 0,
        },
        visible: {
          opacity: 1,
        },
      }}
      initial='hidden'
      animate='visible'
      exit='hidden'
      transition={{
        duration,
        ease,
      }}
    >
      {children}
    </FadingContainer>
  )
}

export const AdditionalPublishContainer = styled(motion.div).attrs({
  variants: {
    open: {
      opacity: 1,
      height: 'auto',
      filter: 'blur(0px)',

      transition: {
        duration: sharedDuration,
        when: 'beforeChildren',
        ease,
      },
    },
    closed: {
      opacity: 0,
      height: 30,
      filter: 'blur(3px)',

      transition: {
        duration: sharedDuration,
        ease,
        when: 'afterChildren',
      },
    },
  },
  initial: 'closed',
  animate: 'open',
  exit: 'closed',
})`
  display: flex;
  flex-direction: column;
  gap: 32px;
  flex-grow: 1;
  overflow: hidden;
`

export const ButtonsContainer = styled(motion.div).attrs({
  variants: {
    open: {
      opacity: 1,
    },
    closed: {
      opacity: 0,
    },
  },
  initial: 'closed',
  animate: 'open',
  exit: 'closed',
  transition: {
    duration: 0.2,
  },
})`
  display: flex;
  justify-content: flex-end;
  gap: 4px;
`
