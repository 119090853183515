import { useCallback, useRef, useState } from 'react'
import { Participant } from 'sierra-client/components/liveV2/participant'
import { PrioriotyLevel } from 'sierra-client/components/liveV2/participant-provider'
import { useSelector } from 'sierra-client/state/hooks'
import { selectScreenShareParticipant } from 'sierra-client/state/live-session/selectors'
import styled from 'styled-components'

const Container = styled.div`
  position: absolute;
  width: 100%;
  height: 100%;
  top: 0;
  left: 0;
`

const ScreenShareContainer = styled.div`
  max-width: 100%;
  height: 100%;
  flex: 1;
  background-color: ${p => p.theme.color.grey90};
  border-radius: 8px;

  & video {
    object-fit: contain !important;
  }
`

type ElementWithPartialScreenShareSupport = {
  requestFullscreen?: HTMLElement['requestFullscreen']
  webkitRequestFullscreen?: HTMLElement['requestFullscreen']
  msRequestFullScreen?: HTMLElement['requestFullscreen']
}

type DocumentWithPartialScreenShareSupport = {
  exitFullscreen?: Document['exitFullscreen']
  webkitExitFullscreen?: Document['exitFullscreen']
  msExitFullscreen?: Document['exitFullscreen']
}

const requestFullScreen = async (element: HTMLElement): Promise<void> => {
  const e: ElementWithPartialScreenShareSupport = element as any

  if (e.requestFullscreen) await e.requestFullscreen()
  else if (e.webkitRequestFullscreen) await e.webkitRequestFullscreen()
  else if (e.msRequestFullScreen) await e.msRequestFullScreen()
}

const closeFullscreen = async (): Promise<void> => {
  const doc: DocumentWithPartialScreenShareSupport = window.document as any

  if (doc.exitFullscreen) {
    await doc.exitFullscreen()
  } else if (doc.webkitExitFullscreen) {
    /* Safari */
    await doc.webkitExitFullscreen()
  } else if (doc.msExitFullscreen) {
    /* IE11 */
    await doc.msExitFullscreen()
  }
}

export const ScreenshareModal = (): JSX.Element | null => {
  const [isFullscreen, setIsFullscreen] = useState(false)
  const containerRef = useRef<HTMLDivElement>(null)
  const screenSharingParticipant = useSelector(selectScreenShareParticipant)

  const onDoubleClick = useCallback(async (): Promise<void> => {
    if (containerRef.current === null) return

    if (!isFullscreen) {
      try {
        await requestFullScreen(containerRef.current)
      } finally {
        setIsFullscreen(true)
      }
    } else {
      try {
        await closeFullscreen()
      } finally {
        setIsFullscreen(false)
      }
    }
  }, [isFullscreen])

  if (screenSharingParticipant === undefined) return null

  return (
    <Container>
      <ScreenShareContainer ref={containerRef} onDoubleClick={onDoubleClick}>
        <Participant
          priorityLevel={PrioriotyLevel.HIGH}
          participant={screenSharingParticipant}
          isScreenShare={true}
        />
      </ScreenShareContainer>
    </Container>
  )
}
