import { getFileContentVideo } from 'sierra-client/api/content'
import { VideoPlayer } from 'sierra-client/components/blocks/video'
import { supportAudioCapture } from 'sierra-client/components/liveV2/recording/recording-utils'
import { Modal } from 'sierra-ui/components'
import { Button, Heading, IconButton, Text, View } from 'sierra-ui/primitives'
import { token } from 'sierra-ui/theming'
import { fonts } from 'sierra-ui/theming/fonts'
import { legacyLight } from 'sierra-ui/theming/legacy-theme'
import styled, { ThemeProvider } from 'styled-components'

const VideoWrapper = styled(View)`
  width: 100%;
  aspect-ratio: 16 / 9;

  > div {
    width: 100%;
    height: 100%;
  }

  .vjs-overwrite {
    cursor: default !important;
  }
`

const CloseButton = styled(IconButton).attrs({ variant: 'transparent' })`
  position: absolute;
  right: 1rem;
  top: 1rem;
`

const LineSeparator = styled.div`
  border-top: 1px solid ${token('border/default')};
  width: 100%;
  height: 1px;
`

const StyledHeading = styled(Heading)`
  font-weight: ${fonts.weight.medium};
`

const BoldSpan = styled.span`
  font-weight: ${fonts.weight.bold};
`

export const ScreeRecordModal: React.FC<{
  showRecordModal: boolean
  setShowRecordModal: React.Dispatch<React.SetStateAction<boolean>>
  startScreenRecord: () => void
}> = ({ showRecordModal, setShowRecordModal, startScreenRecord }) => {
  return (
    <Modal
      size={{ width: 575 }}
      open={showRecordModal}
      onClose={() => setShowRecordModal(false)}
      padding='medium xxsmall'
    >
      <ThemeProvider theme={legacyLight}>
        <CloseButton iconId='close' onClick={() => setShowRecordModal(false)} />
        <View padding='none xlarge' paddingBottom='xlarge' direction='column' alignItems='center' gap='small'>
          <VideoWrapper>
            <VideoPlayer
              options={{
                width: 1920,
                height: 1080,
                autoplay: true,
                loop: true,
                playsinline: true,
                muted: true,
                sources: [
                  {
                    src: getFileContentVideo('screenshare-chrome.mp4', 'videos', { bucket: 'sierra-static' }),
                  },
                ],
              }}
            />
          </VideoWrapper>
          <View direction='column' gap='xxsmall'>
            <StyledHeading size='h5' align='center'>
              Recording a session
            </StyledHeading>
            <Text size='small' align='center'>
              {`Capture your session to share later. To start the recording, `}
              <BoldSpan>{`select this tab`}</BoldSpan>
              {` in the next step, and `}
              <BoldSpan>{`ensure you're also sharing the audio`}</BoldSpan>
              {`.`}
            </Text>
          </View>
          {!supportAudioCapture && (
            <Text size='small' align='center' color='redVivid' bold>
              {`We currently don't support recording a session in this browser. Please change to Chrome or Edge.`}
            </Text>
          )}
          <View>
            <Button
              variant='secondary'
              onClick={() => {
                setShowRecordModal(false)
              }}
            >
              Cancel
            </Button>
            <Button
              variant='primary'
              disabled={!supportAudioCapture}
              onClick={() => {
                startScreenRecord()
                setShowRecordModal(false)
              }}
            >
              Set up
            </Button>
          </View>
        </View>
        <LineSeparator />
        <View padding='none medium' paddingTop='xsmall'>
          <Text size='micro' align='center' color='foreground/muted'>
            Participants will be notified when you start a recording. You can access recordings in the session
            recap after the session has ended.
          </Text>
        </View>
      </ThemeProvider>
    </Modal>
  )
}
