import { unwrapToTextNodes } from 'sierra-client/views/v3-author/command'
import { isElementType } from 'sierra-client/views/v3-author/queries'
import { Editor, Element, Node, Range, Text, Transforms } from 'slate'

const debug = (...messages: unknown[]): void => console.debug('[withCode]', ...messages)

const illegalCodeMarks = ['bold', 'italic', 'underline', 'code']

export const withCode = (editor: Editor): Editor => {
  const { insertBreak, normalizeNode } = editor

  editor.insertBreak = () => {
    const { selection } = editor
    if (selection && Range.isCollapsed(selection)) {
      const [match] = Editor.nodes(editor, {
        match: n => !Editor.isEditor(n) && Element.isElement(n) && n.type === 'code',
      })

      if (match) {
        editor.insertText('\n')
        return
      }
    }

    insertBreak()
  }

  editor.normalizeNode = entry => {
    const [node, path] = entry
    if (!isElementType('code', node)) return normalizeNode(entry)

    for (const [child, childPath] of Node.children(editor, path)) {
      if (!Text.isText(child)) {
        debug('Unwrapping non-text node at', JSON.stringify(childPath))
        return unwrapToTextNodes(editor, { at: childPath })
      }

      // Some marks are missing here unfortunately and they should **not** be added.
      // Changing this may affect existing documents by causing an unseen edit.
      // Rather than removing the actual marks, we should probably change the leaf renderer
      // so that it does not display marks in this block in the future.
      const illegalMarks = Object.keys(child).filter(mark => illegalCodeMarks.includes(mark))
      if (illegalMarks.length > 0) {
        debug(`Removing illegal marks ${JSON.stringify(illegalMarks)} at ${JSON.stringify(path)}`)
        for (const mark of illegalMarks) {
          Transforms.setNodes(editor, { [mark]: undefined }, { at: childPath })
        }
        return
      }
    }

    if (Array.from(Node.children(editor, path)).length > 1) {
      debug('Merging multiple text nodes at', JSON.stringify(path))
      return editor.mergeNodes({ at: path.concat(1) })
    }

    normalizeNode(entry)
  }

  return editor
}
