import { getCurrentlySelectedLeaf } from 'sierra-client/views/v3-author/queries'
import { EditorKeyboardEvent } from 'sierra-domain/v3-author'
import { Editor, Range } from 'slate'

export const onKeyDown = (event: EditorKeyboardEvent, editor: Editor): void => {
  const selection = editor.selection
  if (!selection) return
  if (event.key === 'ArrowRight') {
    const currentNode = getCurrentlySelectedLeaf(editor)
    if (
      currentNode?.[0].subscript === true ||
      (currentNode?.[0].supscript === true && Range.isCollapsed(selection))
    ) {
      const textLength = currentNode[0].text.length
      if (selection.anchor.offset >= textLength) {
        event.preventDefault()
        event.stopPropagation()
        Editor.insertNode(editor, { text: ' ' })
      }
    }
  }
}
