import { FC } from 'react'
import { useTranslation } from 'sierra-client/hooks/use-translation'
import { LabelMenuItem } from 'sierra-ui/components'
import { SingleSelectDropdown } from 'sierra-ui/primitives/menu-dropdown'

type Visibility = 'visible-everyone' | 'private'

export const VisibleToAllDropdown: FC<{
  shareWithAll: boolean
  onSelect: (value: boolean) => void
}> = ({ shareWithAll, onSelect }) => {
  const { t } = useTranslation()

  const visibilityToMenuItems: { [K in Visibility]: LabelMenuItem<Visibility> } = {
    'private': {
      id: 'private',
      type: 'label',
      label: t('manage.insights.share-dashboard-form.visible-to-all-dropdown.private'),
    },
    'visible-everyone': {
      id: 'visible-everyone',
      type: 'label',
      label: t('manage.insights.share-dashboard-form.visible-to-all-dropdown.visible'),
    },
  }
  const menuItems = Object.values(visibilityToMenuItems)

  const selectedVisibility = shareWithAll === true ? 'visible-everyone' : 'private'
  const selectedItem = visibilityToMenuItems[selectedVisibility]

  return (
    <SingleSelectDropdown
      onSelect={item => {
        onSelect(item.id === 'visible-everyone')
      }}
      selectedItem={selectedItem}
      menuItems={menuItems}
    />
  )
}
