import { RefObject, useCallback, useEffect, useRef, useState } from 'react'
import { FCC } from 'sierra-client/types'

const useIsScrolledToBottom = (
  ref: RefObject<HTMLDivElement | null>
): { isScrolledToBottom: boolean; updateScroll: () => void } => {
  const [isScrolledToBottom, setIsScrolledToBottom] = useState(false)

  const updateScroll = useCallback(() => {
    const { current } = ref
    if (current === null) return

    const { scrollTop, scrollHeight, clientHeight } = current
    if (scrollTop + clientHeight === scrollHeight) {
      setIsScrolledToBottom(true)
    } else {
      setIsScrolledToBottom(false)
    }
  }, [ref])

  return { updateScroll, isScrolledToBottom }
}

/**
 * A container which detects when the user has scrolled to the bottom of it.
 */
export const ScrollContainer: FCC<{
  className?: string
  onScrollToBottom?: () => void
}> = ({ children, className, onScrollToBottom = () => {} }) => {
  const scrollRef = useRef<HTMLDivElement | null>(null)
  const { isScrolledToBottom, updateScroll } = useIsScrolledToBottom(scrollRef)

  useEffect(() => {
    if (isScrolledToBottom) onScrollToBottom()
  }, [isScrolledToBottom, onScrollToBottom])

  return (
    <div ref={scrollRef} className={className} onScroll={updateScroll}>
      {children}
    </div>
  )
}
