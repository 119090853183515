import { useRef } from 'react'
import { usePropMonitor } from 'sierra-client/hooks/use-prop-monitor'

function onWarnDefault(name: string, count: number): void {
  console.warn(`Unexpected unstable prop: ${name} triggered rerender ${count} times
You probably want to lift the definition of the prop outside the component or wrap it in useMemo.`)
}

/**
 * Use this hook in a component or hook that receives a prop that
 * shouldn't change, to alert the developer of incorrect code in runtime.
 */
export const useWarnUnstablePropReference = (
  prop: unknown,
  name: string,
  { onWarn }: { onWarn: (name: string, count: number) => void } = { onWarn: onWarnDefault }
): void => {
  const counterRef = useRef(0)

  usePropMonitor(
    { prop },
    {
      onChange: () => {
        counterRef.current += 1
        onWarn(name, counterRef.current)
      },
    }
  )
}
