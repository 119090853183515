import { useEffect, useState } from 'react'
import { useLocalizedFormatters } from 'sierra-client/core/format'
import { token } from 'sierra-ui/theming'
import styled from 'styled-components'

const ONE_MINUTE_MS = 60000
const ONE_HOUR_MS = ONE_MINUTE_MS * 60
const ONE_DAY_MS = ONE_MINUTE_MS * 60

const MessageTime = styled.span`
  font-size: 12px;
  line-height: 24px;
  color: ${token('foreground/secondary')};
  white-space: nowrap;
`

export const TimeSinceSent = ({ timestamp }: { timestamp: string }): JSX.Element => {
  // Use an effect to periodically trigger the timestamp to re-render, since the time is relative
  // It's updated every minute for recent timestamps, and every hour for older ones
  const [lastRender, setLastRender] = useState<number>(Date.now())
  const { formatTimestamp } = useLocalizedFormatters()

  useEffect(() => {
    let timeout: ReturnType<typeof setTimeout>
    const timeSinceTimestamps = Date.now() - new Date(timestamp).valueOf()

    if (timeSinceTimestamps < ONE_HOUR_MS) {
      timeout = setTimeout(() => setLastRender(Date.now()), ONE_MINUTE_MS)
    } else if (timeSinceTimestamps < ONE_DAY_MS) {
      timeout = setTimeout(() => setLastRender(Date.now()), ONE_HOUR_MS)
    }
    return () => clearTimeout(timeout)
  }, [lastRender, timestamp])

  return <MessageTime>{formatTimestamp(timestamp, 'compact', 'compact')}</MessageTime>
}
