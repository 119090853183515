import { useAtomValue } from 'jotai'
import { useEffect, useState } from 'react'
import {
  ComputePressureAtom,
  RenderRateVolatilityEmaAtom,
  StatsSchedulingTimeEmaAtom,
} from 'sierra-client/components/liveV2/live-layer/call-stats/atoms'
import { getCurrentPressure } from 'sierra-client/components/liveV2/live-layer/call-stats/cpu-pressure'
import {
  isDenoiserSupported,
  isVirtualBackgroundSupported,
} from 'sierra-client/components/liveV2/services/video-call-service'
import { logger } from 'sierra-client/logger/logger'
import { useDispatch } from 'sierra-client/state/hooks'
import {
  addIssue,
  setBackgroundBlur,
  setNoiseCancellation,
  setPreferredVideoQuality,
} from 'sierra-client/state/live/actions'
import { useOnChanged } from 'sierra-ui/utils'

export const DetectPerformanceIssue = (): null => {
  const rrvEma = useAtomValue(RenderRateVolatilityEmaAtom)
  const schedulingEma = useAtomValue(StatsSchedulingTimeEmaAtom)
  const computePressure = useAtomValue(ComputePressureAtom)
  const dispatch = useDispatch()
  const [currentPresure, setCurrentPressure] = useState(
    getCurrentPressure({ rrvEma, schedulingEma, computePressure })
  )

  useEffect(() => {
    setCurrentPressure(getCurrentPressure({ rrvEma, schedulingEma, computePressure }))
  }, [rrvEma, schedulingEma, computePressure])

  useOnChanged((_prev, current) => {
    logger.debug(`[DetectPerformanceIssue] pressure changed - currently ${current}`, {
      rrvEma,
      schedulingEma,
      computePressure,
      detectedPressure: current,
    })

    if (current === 'critical') {
      logger.debug('[DetectPerformanceIssue] Performance issue detected, reducing performance', {
        rrvEma,
        schedulingEma,
        computePressure,
        detectedPressure: current,
      })

      dispatch(addIssue('bad-performance'))

      // disable incomming video
      void dispatch(setPreferredVideoQuality('off'))

      // disable audio and video processing
      if (isVirtualBackgroundSupported) void dispatch(setBackgroundBlur({ enabled: false }))
      if (isDenoiserSupported) void dispatch(setNoiseCancellation({ enabled: false }))
    }
  }, currentPresure)

  return null
}
