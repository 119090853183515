import { useTranslation } from 'sierra-client/hooks/use-translation'
import {
  IconPicker,
  IconPickerOption,
} from 'sierra-client/views/flexible-content/editor/toolbar/icon-picker/icon-picker'
import { TitleCard } from 'sierra-domain/v3-author'

type TextAlignment = TitleCard['variant']
type TextAlignmentPickerProps = {
  onUpdateAlignment: (alignment: TextAlignment) => void
  currentAlignment: TextAlignment
}

export const TextAlignmentPicker: React.FC<TextAlignmentPickerProps> = ({
  onUpdateAlignment,
  currentAlignment,
}) => {
  const { t } = useTranslation()

  const alignmentOptions: IconPickerOption[] = [
    { key: 'left-aligned', iconId: 'text--align--left' },
    { key: 'center-aligned', iconId: 'text--align--center' },
    { key: 'right-aligned', iconId: 'text--align--right' },
  ]

  return (
    <IconPicker
      tooltip={t('create.toolbar.text-alignment')}
      label={t('create.toolbar.text-alignment')}
      options={alignmentOptions}
      onUpdate={option => onUpdateAlignment(option.key as TextAlignment)}
      activeOption={
        alignmentOptions.find(({ key }) => key === currentAlignment) ?? {
          key: 'left-aligned',
          iconId: 'text--align--left',
        }
      }
    />
  )
}
