import { useCallback } from 'react'
import { useNotif } from 'sierra-client/components/common/notifications'
import { usePost } from 'sierra-client/hooks/use-post'
import { useTranslation } from 'sierra-client/hooks/use-translation'
import { UploadMedia } from 'sierra-client/views/v3-author/common/media-uploader/types'
import { XRealtimeAdminTemporaryUploadUrl } from 'sierra-domain/routes'

export type TemporaryFile = {
  fileId: string
}

type TemporaryFileUpload = {
  uploadTemporaryFile: UploadMedia<TemporaryFile>
}

export const useTemporaryFileUpload = (maxSizeInMB: number | undefined): TemporaryFileUpload => {
  const { postWithUserErrorException } = usePost()
  const { t } = useTranslation()
  const notif = useNotif()

  const uploadTemporaryFile = useCallback<UploadMedia<TemporaryFile>>(
    async blob => {
      if (maxSizeInMB !== undefined && blob.size > maxSizeInMB * 1024 * 1024) {
        notif.push({
          type: 'error',
          body: t('manage.media-library.file-too-large', { size: maxSizeInMB }),
        })

        throw new Error(`File too large (max size ${maxSizeInMB} MB)`)
      }

      const res = await postWithUserErrorException(XRealtimeAdminTemporaryUploadUrl, {})

      const { fileId, url: uploadUrl } = res.url

      // Upload the file to Google
      await fetch(uploadUrl, {
        method: 'PUT',
        body: blob,
        headers: { 'Content-Type': blob.type },
      })

      return {
        fileId,
      }
    },
    [maxSizeInMB, notif, postWithUserErrorException, t]
  )

  return { uploadTemporaryFile }
}
