import { FC, useMemo, useState } from 'react'
import { useTranslation } from 'sierra-client/hooks/use-translation'
import { SettingsSection } from 'sierra-client/views/settings/components/settings-section'
import {
  NotificationState,
  OrgNotificationSettingsResponse,
  PatchOrgNotificationSettingsRequest,
} from 'sierra-domain/api/org-notification-settings'
import { FormElement, MenuItem } from 'sierra-ui/components'
import { SingleSelectDropdown } from 'sierra-ui/primitives/menu-dropdown'
import styled from 'styled-components'

const MaxWidth = styled.div`
  max-width: 400px;
`

export const TranslationSettingsSection: FC<{
  settings: OrgNotificationSettingsResponse
  onChange: (_: PatchOrgNotificationSettingsRequest) => void
}> = ({ settings, onChange }) => {
  const { t } = useTranslation()

  const [translateFormState, setTranslateFormState] = useState<NotificationState>(
    settings.translationSettings.state
  )

  const enableTranslationItem = useMemo((): MenuItem => {
    return {
      type: 'label',
      id: 'translation-enabled',
      label: t('settings.notifications-page.translation-settings.enable'),
    }
  }, [t])
  const disableTranslationItem = useMemo((): MenuItem => {
    return {
      type: 'label',
      id: 'translation-disabled',
      label: t('settings.notifications-page.translation-settings.disable'),
    }
  }, [t])

  function onFormChange(item: MenuItem): void {
    const state = item === enableTranslationItem ? NotificationState.Enabled : NotificationState.Disabled

    setTranslateFormState(state)

    onChange({
      translationSettings: {
        state: state,
      },
    })
  }

  return (
    <SettingsSection
      iconId='translate'
      title='settings.notifications-page.translation-settings.title'
      subtitle='settings.notifications-page.translation-settings.subtitle'
    >
      <MaxWidth>
        <FormElement
          label={t('settings.notifications-page.translation-settings.form-label')}
          helper={t('settings.notifications-page.translation-settings.form-helper')}
        >
          <SingleSelectDropdown
            placeholder='lorem ipsum'
            selectedItem={
              translateFormState === NotificationState.Enabled
                ? enableTranslationItem
                : disableTranslationItem
            }
            menuItems={[disableTranslationItem, enableTranslationItem]}
            onSelect={onFormChange}
          />
        </FormElement>
      </MaxWidth>
    </SettingsSection>
  )
}
