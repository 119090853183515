import { HTMLMotionProps, motion } from 'framer-motion'
import { SkillId } from 'sierra-client/api/graphql/branded-types'
import { useGraphQuery } from 'sierra-client/api/hooks/use-graphql-query'
import { SkillColor } from 'sierra-client/features/skills/colors'
import { RichBadge } from 'sierra-client/features/skills/components/badges'
import {
  getMySubcriptionsQuery,
  useInvalidateGetMySubcriptionsQuery,
  useSubscribeLearnerMutation,
} from 'sierra-client/features/skills/shared-gql-queries'
import { useTranslation } from 'sierra-client/hooks/use-translation'
import { useIsMobile } from 'sierra-client/state/browser/selectors'
import { useSelector } from 'sierra-client/state/hooks'
import { selectUserId } from 'sierra-client/state/user/user-selector'
import { SkillIconId } from 'sierra-ui/components'
import { Button, Heading, IconButton, Spacer, Text, View } from 'sierra-ui/primitives'
import { token } from 'sierra-ui/theming'
import styled from 'styled-components'
import { useTracking } from '../../tracking'

const Banner = styled(View)`
  border-radius: 16px;
  height: 100%;
  width: 100%;
  flex-direction: column;
  justify-content: center;
  text-align: center;
  background: ${token('surface/default')};
  border: 1px solid var(--border-default, ${token('border/default')});
  box-shadow: 0px 16px 24px 0px #00000014;
  padding: 0 24px;
`

const BANNER_HEIGHT = 393

const CHILD_ANIMATION_VARIANTS: Record<number, HTMLMotionProps<'div'>> = {
  0: {
    initial: { x: 25, y: 40, scale: 0.9 },
    animate: { x: 0, y: 0, scale: 1 },
    transition: { duration: 0.6, delay: 0.0 },
  },
  1: {
    initial: { y: 40, scale: 0.9 },
    animate: { y: 0, scale: 1.1 },
    transition: { duration: 0.6, delay: 0.0 },
  },
  2: {
    initial: { x: -25, y: 40, scale: 0.9 },
    animate: { x: 0, y: 0, scale: 1 },
    transition: { duration: 0.6, delay: 0.0 },
  },
}

const BadgesContainer = styled(motion.div)`
  width: 100%;
  height: 100%;
  max-width: 500px;
  max-height: 150px;
  position: relative;
  overflow: hidden;
`
const BadgeAnimator = styled(motion.div)`
  width: 15%;
  bottom: 35%;
  position: absolute;

  &:nth-child(1) {
    left: 30%;
  }
  &:nth-child(2) {
    margin-inline: auto;
    left: 0;
    right: 0;
    z-index: 1;
  }
  &:nth-child(3) {
    right: 30%;
  }
`

const BadgeFade = styled(motion.div)`
  z-index: 1;
  position: absolute;
  bottom: 0;
  width: 100%;
  height: 100%;
  background-image: linear-gradient(to top, white 60%, transparent);
  transform-origin: bottom;
`

const CloseButton = styled(IconButton)`
  position: absolute;
  top: 24px;
  right: 24px;
`

export const SkillSubscribeBanner: React.FC<{
  skillId: SkillId
  onClose: () => void
}> = ({ skillId, onClose }) => {
  const { t } = useTranslation()

  const userId = useSelector(selectUserId)
  const tracking = useTracking()
  const invalidateSubscriptionQuery = useInvalidateGetMySubcriptionsQuery()
  const isMobile = useIsMobile()

  const { data } = useGraphQuery({ document: getMySubcriptionsQuery }, { id: skillId })

  const skill = data?.skill

  const { mutate } = useSubscribeLearnerMutation({
    onSuccess: async () => {
      tracking.learner.selfSubscribe({ skillId })
      await invalidateSubscriptionQuery()
    },
  })

  const icon = SkillIconId.catch('skill--graduation--cap').parse(skill?.defaultBadgeIcon)
  const skillName = skill?.name ?? ''
  const skillLevelSettingIds = skill?.skillLevels.map(level => level.levelSetting.id) ?? []

  return (
    <motion.div
      initial='hidden'
      animate='visible'
      exit='hidden'
      variants={{
        hidden: { opacity: 0, bottom: -BANNER_HEIGHT },
        visible: {
          opacity: 1,
          bottom: 0,
        },
      }}
      style={{
        height: `${BANNER_HEIGHT}px`,
        width: '100%',
        zIndex: 10,
        position: 'sticky',
        bottom: 0,
        padding: isMobile ? '4px' : '8px',
        overflow: 'hidden',
      }}
    >
      <Banner>
        <BadgesContainer initial={{ opacity: 0 }} animate={{ opacity: 1 }} transition={{ duration: 0.5 }}>
          {skill?.skillLevels.slice(0, 3).map((level, index) => {
            const badgeTheme = SkillColor.catch('bronze').parse(level.levelSetting.defaultBadgeTheme)
            const { initial, animate, transition } = CHILD_ANIMATION_VARIANTS[index] ?? {}

            return (
              <BadgeAnimator
                key={`badge_${index}`}
                initial={initial}
                animate={animate}
                transition={transition}
              >
                <RichBadge
                  theme={badgeTheme}
                  title={skill.name}
                  subtitle={level.levelSetting.name}
                  iconId={icon}
                />
              </BadgeAnimator>
            )
          })}
          <BadgeFade initial={{ y: '20%' }} animate={{ y: '70%' }} transition={{ duration: 0.8 }} />
        </BadgesContainer>
        <Heading bold size='h5'>
          {t('skills.not-subscribed.banner.title', { skillName })}
        </Heading>
        <Text>{t('skills.not-subscribed.banner.subtitle', { skillName })}</Text>
        <Spacer size='32' />
        <Button
          variant={'primary'}
          onClick={() => {
            if (userId) {
              mutate({
                subscriptions: skillLevelSettingIds.map(id => ({
                  skillId,
                  userId,
                  skillLevelSettingId: id,
                  alreadyAchieved: false,
                })),
              })
            }
          }}
        >
          {t('skills.users.follow.text')}
        </Button>
        <CloseButton variant='transparent' onClick={onClose} size='small' iconId='close' />
      </Banner>
    </motion.div>
  )
}
