import { capitalize } from 'lodash'
import { FC, useState } from 'react'
import { useObserveElementSize } from 'sierra-client/hooks/use-observe-element-size'
import { useTranslation } from 'sierra-client/hooks/use-translation'
import { useCachedQuery } from 'sierra-client/state/api'
import { useSelector } from 'sierra-client/state/hooks'
import { selectUser } from 'sierra-client/state/user/user-selector'
import { isUserLoaded, useUsers } from 'sierra-client/state/users/hooks'
import { getAvatarImage } from 'sierra-client/utils/avatar-img'
import { LiveSessionId } from 'sierra-domain/api/nano-id'
import { XRealtimeStrategyLiveSessionGetAttendance } from 'sierra-domain/routes'
import { LightUser } from 'sierra-domain/user'
import { getUserName } from 'sierra-domain/utils'
import { Icon, RoundAvatar, TruncatedText } from 'sierra-ui/components'
import { Text, View } from 'sierra-ui/primitives'
import { token } from 'sierra-ui/theming'
import styled from 'styled-components'

const ListItemContainer = styled(View)`
  min-height: 42px;
`

const ParticipantListItem: React.FC<{
  user: LightUser
}> = ({ user }) => {
  const { t } = useTranslation()
  const me = useSelector(selectUser)
  const userIsCurrentUser = me?.uuid === user.uuid
  const guestUserEmail = user.email.startsWith('sana-live')

  return (
    <ListItemContainer grow gap='16' padding='none none'>
      <RoundAvatar
        size='medium'
        firstName={user.firstName}
        lastName={user.lastName}
        color={user.avatarColor}
        src={getAvatarImage(user.uuid, user.avatar)}
      />
      <View direction='column' gap='none'>
        <TruncatedText lines={1} size='small' bold>
          {userIsCurrentUser ? capitalize(t('dictionary.you')) : getUserName(user) ?? ''}
        </TruncatedText>

        <Text size='small' color='foreground/muted'>
          {guestUserEmail ? 'guest user' : user.email}
        </Text>
      </View>
    </ListItemContainer>
  )
}

const Blob = styled(Text).attrs({ bold: true, size: 'technical', color: 'foreground/secondary' })`
  background-color: ${token('surface/strong')};
  border-radius: 50px;
  width: fit-content;
  padding: 2.5px 8px;
`

const ParticipantsGrid = styled.div<{ $columns: number }>`
  display: grid;
  grid-template-columns: repeat(${p => p.$columns}, 1fr);
  row-gap: 16px;
  column-gap: 48px;
`

const ParticipantsContainer = styled(View).attrs({ direction: 'column' })`
  margin-bottom: 36px;
`

const ShowMoreParticipantsButton = styled.button`
  display: flex;
  flex-direction: row;
  background-color: transparent;
  align-items: center;
  gap: 8px;
  width: fit-content;
  cursor: pointer;
  padding: 0;
  padding-top: 16px;
`
const DEFAULT_PARTICIPANT_LIST_LENGTH = 12

export const Participants: FC<{ liveSessionId: LiveSessionId }> = props => {
  const { t } = useTranslation()
  const [participantsExpanded, setParticipantsExpanded] = useState(false)
  const [containerElement, setContainerElement] = useState<HTMLDivElement | null>(null)
  const { width: containerWidth } = useObserveElementSize(containerElement)

  const attendees =
    useCachedQuery(XRealtimeStrategyLiveSessionGetAttendance, {
      liveSessionId: props.liveSessionId,
    }).data?.attendees ?? []

  const participants = useUsers(attendees).filter(isUserLoaded)
  const shortParticipants = participants.slice(0, DEFAULT_PARTICIPANT_LIST_LENGTH)

  return (
    <ParticipantsContainer ref={setContainerElement}>
      <View paddingBottom={'16'} direction='row' alignItems='center'>
        <Text size='regular' bold>
          {t('admin.author.sessions.participants')}
        </Text>
        <Blob>{participants.length}</Blob>
      </View>
      <ParticipantsGrid $columns={Math.floor(containerWidth / 225)}>
        {(participantsExpanded ? participants : shortParticipants).map(user => (
          <ParticipantListItem key={user.uuid} user={user} />
        ))}
      </ParticipantsGrid>
      {participants.length > DEFAULT_PARTICIPANT_LIST_LENGTH && (
        <ShowMoreParticipantsButton onClick={() => setParticipantsExpanded(previous => !previous)}>
          <Icon
            iconId={participantsExpanded ? 'chevron--up--small' : 'chevron--down--small'}
            color={'grey25'}
          />
          <Text bold color={'grey25'}>
            {' '}
            {participantsExpanded
              ? t('admin.author.sessions.hide-participants', {
                  count: participants.length - shortParticipants.length,
                })
              : t('admin.author.sessions.show-participants', {
                  count: participants.length - shortParticipants.length,
                })}
          </Text>
        </ShowMoreParticipantsButton>
      )}
    </ParticipantsContainer>
  )
}
