import React from 'react'
import { useTranslation } from 'sierra-client/hooks/use-translation'
import { Icon, Tooltip } from 'sierra-ui/components'
import { Spacer, Text } from 'sierra-ui/primitives'
import styled from 'styled-components'

// const ScormWrapper = styled.div`
//   /* background-color: #ffffff33;
//   color: #fff; */
//   background-color: #e5e7eb80;
//   display: flex;
//   align-items: center;
//   border-radius: 4px;
//   padding: 4px;
// `

// export const ScormChip: React.FC<{ variant: 'background' | 'text' }> = () => {
//   return (
//     <ScormWrapper>
//       <Icon iconId='document-import' size='smaller' color={color.black} />
//     </ScormWrapper>
//   )
// }

type ScormChipWrapperProps = { $color: string; $backgroundColor: string; $circle?: boolean }
const ScormChipWrapper = styled(Text)<ScormChipWrapperProps>`
  display: inline-flex;
  align-items: center;
  background-color: ${p => p.$backgroundColor};
  padding: ${p => (p.$circle === true ? '0.5rem' : '0.25rem 0.75rem')};
  border-radius: 100px;
  color: ${p => p.$color};
`
type ScormChipProps = { variant: 'manage' | 'course'; circle?: boolean }
const CHIP_PROPS: Record<ScormChipProps['variant'], ScormChipWrapperProps> = {
  manage: {
    $backgroundColor: '#E5E7EB80',
    $color: '#000',
  },
  course: {
    $backgroundColor: '#FFFFFF33',
    $color: '#FFF',
  },
}

export const ScormChip: React.FC<ScormChipProps> = ({ variant, circle = false }) => {
  const { t } = useTranslation()
  const scormText = 'SCORM'

  const chipProps = CHIP_PROPS[variant]
  return (
    <Tooltip title={t('scorm.tooltip')}>
      <ScormChipWrapper
        color='LEGACY_DEFAULT_TEXT_COLOR_REPLACE_ASAP'
        size='small'
        bold
        {...chipProps}
        $circle={circle}
      >
        <Icon iconId='document--import' size='size-16' />
        {circle === false && (
          <>
            <Spacer size='xxsmall' />
            {scormText}
          </>
        )}
      </ScormChipWrapper>
    </Tooltip>
  )
}
