import { useCallback, useEffect } from 'react'
import { logger } from 'sierra-client/logger/logger'
import { useDispatch, useSelector } from 'sierra-client/state/hooks'
import { liveSessionLocalAwarenessStateMerged } from 'sierra-client/state/live-session/actions'
import {
  selectLatestEventForUser,
  selectLiveSessionIsMyHandRaised,
} from 'sierra-client/state/live-session/selectors'
import { liveSessionSlice } from 'sierra-client/state/live-session/slice'
import { muteAudio, stopScreenSharing } from 'sierra-client/state/live/actions'
import { selectAudioState, selectIsScreenSharing } from 'sierra-client/state/live/selectors'
import { selectUserId } from 'sierra-client/state/user/user-selector'
import { UserControlEvent } from 'sierra-domain/live-session'

export const UserControlEventsWatcher = (): null => {
  const userId = useSelector(selectUserId)
  const dispatch = useDispatch()
  const audioState = useSelector(selectAudioState)
  const isScreenSharing = useSelector(selectIsScreenSharing)
  const event = useSelector(state => selectLatestEventForUser(state, userId))
  const isMyHandRaised = useSelector(selectLiveSessionIsMyHandRaised)

  const consumeMuteEvent = useCallback(
    async (event: UserControlEvent) => {
      if (userId === undefined) return

      try {
        if (audioState === 'on') {
          void dispatch(muteAudio())
        }
      } catch (error) {
        logger.captureError(error)
      } finally {
        void dispatch(liveSessionSlice.actions.consumeControlEvent({ eventId: event.id, userId }))
      }
    },
    [audioState, dispatch, userId]
  )

  const consumeStopRaisingHandEvent = useCallback(
    (event: UserControlEvent) => {
      if (userId === undefined) return

      try {
        if (isMyHandRaised) {
          void dispatch(liveSessionSlice.actions.removeRaisedHand())
        }
      } catch (error) {
        logger.captureError(error)
      } finally {
        void dispatch(liveSessionSlice.actions.consumeControlEvent({ eventId: event.id, userId }))
      }
    },
    [dispatch, isMyHandRaised, userId]
  )

  const consumeStopScreenShareEvent = useCallback(
    (event: UserControlEvent) => {
      if (userId === undefined) return

      try {
        if (isScreenSharing) {
          void dispatch(stopScreenSharing())
        }
      } catch (error) {
        logger.captureError(error)
      } finally {
        void dispatch(liveSessionSlice.actions.consumeControlEvent({ eventId: event.id, userId }))
      }
    },
    [dispatch, isScreenSharing, userId]
  )

  const consumeStopFollowMe = useCallback(
    (event: UserControlEvent) => {
      if (userId === undefined) return

      try {
        void dispatch(liveSessionLocalAwarenessStateMerged({ followMeEnabled: false }))
      } catch (error) {
        logger.captureError(error)
      } finally {
        void dispatch(liveSessionSlice.actions.consumeControlEvent({ eventId: event.id, userId }))
      }
    },
    [dispatch, userId]
  )

  useEffect(() => {
    if (!event) return

    if (event.action === 'mute') {
      consumeMuteEvent(event).catch(logger.captureError)
    }

    if (event.action === 'stop_raising_hand') {
      consumeStopRaisingHandEvent(event)
    }

    if (event.action === 'stop_screen_share') {
      void consumeStopScreenShareEvent(event)
    }

    if (event.action === 'stop_follow_me') {
      void consumeStopFollowMe(event)
    }
  }, [event, consumeMuteEvent, consumeStopRaisingHandEvent, consumeStopScreenShareEvent, consumeStopFollowMe])

  return null
}
