import { isElementType } from 'sierra-client/views/v3-author/queries'
import { unwrapNonParagraphChildren } from 'sierra-client/views/v3-author/unwrap-non-paragraph-children'
import { Editor } from 'slate'

export const withParagraph = (editor: Editor): Editor => {
  const { normalizeNode } = editor
  editor.normalizeNode = entry => {
    const [node] = entry

    if (!isElementType('paragraph', node)) return normalizeNode(entry)

    const { didUnwrap } = unwrapNonParagraphChildren(editor, entry)
    if (didUnwrap) return

    normalizeNode(entry)
  }

  return editor
}
