import { useMutation, useQueryClient } from '@tanstack/react-query'
import _ from 'lodash'
import { ComponentProps, useRef, useState } from 'react'
import { SkillId } from 'sierra-client/api/graphql/branded-types'
import { graphql } from 'sierra-client/api/graphql/gql'
import { SkillInput, SkillTaxonomyImportInput } from 'sierra-client/api/graphql/gql/graphql'
import { graphQuery, useGraphQuery, useInvalidateGraphQuery } from 'sierra-client/api/hooks/use-graphql-query'
import { ActionModal } from 'sierra-client/components/common/modals/action-modal'
import { useNonExpiringNotif, useNotif } from 'sierra-client/components/common/notifications'
import { SkillPanel } from 'sierra-client/features/skills/components/actions/common'
import { SkillForm, FormData as SkillFormData } from 'sierra-client/features/skills/components/forms/skills'
import { getSkillsCountAndLevelsQuery } from 'sierra-client/features/skills/shared-gql-queries'
import { useTracking } from 'sierra-client/features/skills/tracking'
import { useTranslation } from 'sierra-client/hooks/use-translation'
import { ItemOf, isDefined, isNonEmptyString, isNotDefined } from 'sierra-domain/utils'
import { SkillIconId } from 'sierra-ui/components'
import { Button, LoadingSpinner, View } from 'sierra-ui/primitives'
import { ImportSkillForm, SubmitState } from '../forms/sana-taxonomy'

export const QUERY_KEY = ['graphql', 'skills-table'] as const

const skillToGraphqlInput = (skill: SkillFormData): SkillInput => {
  return {
    skillId: skill.id,
    name: skill.name,
    description: skill.description,
    badgeIcon: skill.icon,
    levels: skill.levels.map(levels => ({
      description: levels.description,
      skillLevelSettingId: levels.skillLevelSettingId,
      skillLevelId: levels.skillLevelId,
    })),
  }
}

export const getSkillLevelSettingsQuery = graphql(`
  query GetSkillLevelSettings {
    skillSettings {
      levelsEngineEnabled
      skillsEngineEnabled
      sequentialUnlockingEnabled
    }
    skillLevelSettings {
      id
      name
      index
      defaultBadgeTheme
    }
  }
`)

const CreateSkill: React.FC<Omit<ComponentProps<typeof SkillForm>, 'value' | 'hasActiveAIEngine'>> = ({
  onCancel,
  onSubmit,
  loading,
}) => {
  const levelSettingsQuery = useGraphQuery({ document: getSkillLevelSettingsQuery }, {})

  if (levelSettingsQuery.isPending) {
    return <LoadingSpinner />
  }
  if (levelSettingsQuery.isError) {
    //TODO: do something with error
    return null
  }

  if (!isDefined(levelSettingsQuery.data)) {
    return <LoadingSpinner />
  }

  return (
    <SkillForm
      value={{
        name: '',
        description: '',
        icon: 'skill--graduation--cap',
        levels: levelSettingsQuery.data.skillLevelSettings.map(x => ({
          name: x.name,
          description: '',
          skillLevelSettingId: x.id,
        })),
      }}
      onCancel={onCancel}
      onSubmit={onSubmit}
      loading={loading}
      hasActiveAIEngine={levelSettingsQuery.data.skillSettings.skillsEngineEnabled}
    />
  )
}

const getUpdateSkillsDataQuery = graphql(`
  query GetUpdateSkillData($id: SkillId!) {
    skill(id: $id) {
      name
      description
      defaultBadgeIcon
      skillLevels {
        id
        description
        levelSetting {
          id
          index
          name
        }
      }
    }
  }
`)

const UpdateSkill: React.FC<
  Omit<ComponentProps<typeof SkillForm>, 'value' | 'hasActiveAIEngine'> & { skillId: SkillId }
> = ({ onCancel, onSubmit, loading, skillId }) => {
  const query = useGraphQuery({ document: getUpdateSkillsDataQuery }, { id: skillId })
  const levelSettingsQuery = useGraphQuery({ document: getSkillLevelSettingsQuery }, {})

  const skill = query.data?.skill

  if (!isDefined(skill) || !isDefined(levelSettingsQuery.data)) {
    return <LoadingSpinner />
  }

  type Levels = Array<ItemOf<SkillFormData['levels']> & { index: number }>

  const currentLevels: Levels = skill.skillLevels.map(s => ({
    name: s.levelSetting.name,
    description: s.description,
    index: s.levelSetting.index,
    skillLevelSettingId: s.levelSetting.id,
    skillLevelId: s.id,
  }))

  const allLevels: Levels = levelSettingsQuery.data.skillLevelSettings.map(level => ({
    index: level.index,
    name: level.name,
    description: '',
    badgeTheme: level.defaultBadgeTheme,
    skillLevelSettingId: level.id,
  }))

  const missingLevels: Levels = _.differenceBy(
    allLevels,
    currentLevels,
    'index' satisfies keyof ItemOf<Levels>
  )

  const levels: Levels = currentLevels.concat(missingLevels)

  const value: SkillFormData = {
    id: skillId,
    name: skill.name,
    icon: SkillIconId.catch('skill--graduation--cap').parse(skill.defaultBadgeIcon),
    description: skill.description,
    levels: levels,
  }

  return (
    <SkillForm
      value={value}
      onCancel={onCancel}
      onSubmit={onSubmit}
      loading={loading}
      hasActiveAIEngine={levelSettingsQuery.data.skillSettings.skillsEngineEnabled}
    />
  )
}

const upsertSkillMutation = graphql(`
  mutation UpsertSkill($input: SkillInput!) {
    upsertSkill(skill: $input)
  }
`)

export const EditSkillPanel: React.FC<{
  skillId?: SkillId
  onClose: () => void
  onSuccess: () => void
  open: boolean
}> = ({ onSuccess, skillId, onClose, open }) => {
  const { t } = useTranslation()
  const tracking = useTracking()

  const { mutate, ...res } = useMutation({
    mutationFn: (input: SkillInput) => {
      return graphQuery(upsertSkillMutation, { input })
    },
    onSuccess: (_, { skillId, levels }) => {
      onSuccess()
      if (skillId) {
        const levelDescriptionLengths = levels.map(level => level.description.length)
        tracking.skill.update({ skillId, levelDescriptionLengths })
      }
    },
  })

  return (
    <SkillPanel
      id='update-skill'
      onClose={onClose}
      isOpen={open}
      title={_.capitalize(t('skills.modal.update-skill.title'))}
    >
      {isDefined(skillId) && (
        <UpdateSkill
          skillId={skillId}
          onCancel={onClose}
          onSubmit={data => mutate(skillToGraphqlInput(data))}
          loading={res.isPending}
        />
      )}
    </SkillPanel>
  )
}

const deleteSkillMutation = graphql(`
  mutation DeleteSkill($id: SkillId!) {
    deleteSkill(skillId: $id)
  }
`)

export const DeleteSkillModal: React.FC<{
  skillId?: SkillId
  onClose: () => void
  onSuccess: () => void
}> = ({ skillId, onClose, onSuccess }) => {
  const { t } = useTranslation()
  const tracking = useTracking()

  const { mutate, ...res } = useMutation({
    mutationFn: (id: SkillId) => {
      return graphQuery(deleteSkillMutation, { id: id })
    },
    onSuccess: () => {
      onSuccess()
      tracking.skill.create()
    },
  })

  if (!isNonEmptyString(skillId)) {
    return null
  }

  return (
    <ActionModal
      isLoading={res.isPending}
      open={true}
      onClose={onClose}
      deleteAction
      hideCloseIcon
      primaryAction={() => mutate(skillId)}
    >
      {t('skills.modal.delete-skill.description')}
    </ActionModal>
  )
}

export const CreateSkillAction: React.FC = () => {
  const { t } = useTranslation()
  const tracking = useTracking()
  const queryClient = useQueryClient()
  const invalidateSkillsCountQuery = useInvalidateGraphQuery(getSkillsCountAndLevelsQuery)
  const [dialogOpen, setDialogOpen] = useState(false)

  const query = useGraphQuery({ document: getSkillLevelSettingsQuery }, {})

  const levelSettings = query.data?.skillLevelSettings

  const { mutate, ...res } = useMutation({
    mutationFn: (input: SkillInput) => {
      return graphQuery(upsertSkillMutation, { input })
    },
    onSuccess: () => {
      setDialogOpen(false)
      tracking.skill.create()
      void queryClient.invalidateQueries({ queryKey: QUERY_KEY })
      void invalidateSkillsCountQuery()
    },
  })

  return (
    <>
      <View gap='12'>
        <Button
          variant='secondary'
          disabled={isNotDefined(levelSettings) || levelSettings.length === 0}
          onClick={() => setDialogOpen(true)}
        >
          {_.capitalize(t('skills.new-skill'))}
        </Button>
      </View>
      <SkillPanel
        id='create-skill'
        onClose={() => {
          setDialogOpen(false)
        }}
        isOpen={dialogOpen}
        title={t('skills.modal.create-skill.title')}
      >
        <CreateSkill
          onCancel={() => setDialogOpen(false)}
          onSubmit={data => mutate(skillToGraphqlInput(data))}
          loading={res.isPending}
        />
      </SkillPanel>
    </>
  )
}

const generateDescriptionForTaxonomy = graphql(`
  mutation GenerateDescriptionForTaxonomy($input: SkillTaxonomyImportInput!) {
    adaptSkillTaxonomy(skillTaxonomyImportInput: $input) {
      results {
        skillTitle
        generatedDescriptions {
          skillLevel
          description
        }
      }
    }
  }
`)

export const ImportSkillAction: React.FC<{
  buttonVariant?: ComponentProps<typeof Button>['variant']
  disabled?: boolean
}> = ({ buttonVariant = 'secondary', disabled }) => {
  const { t } = useTranslation()
  const notif = useNotif()
  const eternalNotif = useNonExpiringNotif()
  const tracking = useTracking()
  const queryClient = useQueryClient()
  const invalidateSkillsCountQuery = useInvalidateGraphQuery(getSkillsCountAndLevelsQuery)
  const [dialogOpen, setDialogOpen] = useState(false)
  const loadingNotifId = useRef<string | null>(null)

  const query = useGraphQuery({ document: getSkillLevelSettingsQuery }, {})

  const levelSettings = query.data?.skillLevelSettings

  const { mutate, ...res } = useMutation({
    mutationFn: (input: Array<SkillTaxonomyImportInput>) => {
      const queries = Promise.all(input.map(i => graphQuery(generateDescriptionForTaxonomy, { input: i })))

      return queries
    },
    onMutate: taxonomies => {
      loadingNotifId.current = eternalNotif.push({
        type: 'progress',
        level: 'info',
        icon: 'loading',
        body: `${t('skills.import.sana-taxonomy.importing.loading.text', { count: taxonomies.length })}`,
        progressDuration: { type: 'fake', duration: 10000 },
      })
    },
    onError: _errors => {
      notif.push({
        type: 'custom',
        level: 'error',
        icon: 'close--circle',
        body: t('skills.import.sana-taxonomy.importing.error.text'),
      })
    },
    onSuccess: res => {
      tracking.skill.importSanaTaxonomy({
        taxonomies: res.flatMap(d => d.adaptSkillTaxonomy.results.map(x => ({ title: x.skillTitle }))),
      })
      notif.push({
        type: 'custom',
        level: 'success',
        icon: 'checkmark',
        body: t('skills.import.sana-taxonomy.importing.success.text', { count: res.length }),
      })
    },
    onSettled: () => {
      if (loadingNotifId.current !== null) {
        eternalNotif.remove(loadingNotifId.current)
      }
      void queryClient.invalidateQueries({ queryKey: QUERY_KEY })
      void invalidateSkillsCountQuery()
    },
  })

  const getSubmitState = (): SubmitState => {
    if (res.isSuccess) {
      return { type: 'done' }
    }

    if (res.isPending) {
      return { type: 'loading', skills: res.variables.length }
    }

    return { type: 'notAsked' }
  }

  return (
    <>
      <View gap='12'>
        <Button
          variant={buttonVariant}
          disabled={isNotDefined(levelSettings) || levelSettings.length === 0 || disabled}
          onClick={() => setDialogOpen(true)}
        >
          {_.capitalize(t('skills.modal.import-sana-taxonomy.title'))}
        </Button>
      </View>

      <SkillPanel
        id='import-sana-taxonomy'
        onClose={() => {
          setDialogOpen(false)
        }}
        isOpen={dialogOpen}
        title={t('skills.modal.import-sana-taxonomy.title')}
      >
        <ImportSkillForm
          onClose={() => setDialogOpen(false)}
          submitState={getSubmitState()}
          onSubmit={taxonomies => {
            mutate(
              taxonomies.map(taxonomy => ({
                badge: taxonomy.icon,
                description: taxonomy.description,
                title: taxonomy.title,
                skillLevels: taxonomy.levels.map(level => ({
                  description: level.description,
                  name: level.title,
                })),
              }))
            )
            setDialogOpen(false)
          }}
        />
      </SkillPanel>
    </>
  )
}
