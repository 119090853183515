import { AnimatePresence, motion } from 'framer-motion'
import { useEffect, useState } from 'react'
import { SimpleBadge } from 'sierra-client/features/skills/components/badges/simple-badge'
import { ContentSkillLevelDropdown } from 'sierra-client/features/skills/components/content-skill/content-skill-level-dropdown'
import { ContentSkill } from 'sierra-client/features/skills/components/content-skill/types'
import { rowAnimationProps } from 'sierra-client/features/skills/components/recommendation/motion'
import { useFakeProgress } from 'sierra-client/hooks/use-fake-progress'
import { useOnMount } from 'sierra-client/hooks/use-on-mount'
import { useTranslation } from 'sierra-client/hooks/use-translation'
import { isDefined } from 'sierra-domain/utils'
import { Icon, ProgressBar, aiColor, aiColorTwo } from 'sierra-ui/components'
import { Button, IconButton, LoaderAnimation, Text, View } from 'sierra-ui/primitives'
import { useOnChanged } from 'sierra-ui/utils'
import styled from 'styled-components'

const ProgressBarContainer = styled.div`
  width: 100%;
  max-width: 200px;
  display: flex;
  align-items: center;
`

export const Row = styled.div.attrs({
  variant: 'ghost',
})`
  display: flex;
  justify-content: space-between;
  height: 48px;
  padding-block: 12px;
  padding-inline: 12px 20px;
  border-radius: 12px;
  background-image: linear-gradient(to right bottom, ${aiColor} 0%, ${aiColorTwo} 100%);
`

type TopBarStatus = 'InProgress' | 'Done' | 'NoRecommendationFound'

const TopBar: React.FC<{
  status: TopBarStatus
  onClose: () => void
  onRetriggerAutoAssign: () => void
}> = ({ status, onClose, onRetriggerAutoAssign }) => {
  const { t } = useTranslation()
  const { value, startInfiniteProgress, finishProgress } = useFakeProgress({
    target: 100,
    finishDuration: 4000,
  })

  useOnChanged(value => {
    if (value === 'Done') {
      void finishProgress()
    }
  }, status)

  useOnMount(() => {
    if (status === 'InProgress') {
      startInfiniteProgress()
    }
  })

  const [retriggerAttemptCount, setRetriggerAttemptCount] = useState(0)

  if (status === 'NoRecommendationFound') {
    return (
      <Row>
        <View>
          <Icon iconId='sana-symbol' size='size-24' />
          <Text color='foreground/primary'>{t('skills.suggestion.no-match')}</Text>
        </View>
        <View>
          {retriggerAttemptCount === 0 && (
            <Button
              type='button'
              icon='restart'
              variant='transparent'
              onClick={() => {
                setRetriggerAttemptCount(prev => prev + 1)
                onRetriggerAutoAssign()
                startInfiniteProgress()
              }}
            >
              {t('skills.suggestion.re-trigger')}
            </Button>
          )}
          <IconButton
            iconId='close'
            variant='transparent'
            size='small'
            color='foreground/muted'
            aria-label={t('dictionary.close')}
            onClick={onClose}
          />
        </View>
      </Row>
    )
  }

  return (
    <Row>
      <View>
        <LoaderAnimation size={24} />
        <Text color='foreground/primary'>{t('skills.suggestion.loading')}</Text>
      </View>
      <ProgressBarContainer>
        <ProgressBar percent={value} variant='ai' />
      </ProgressBarContainer>
    </Row>
  )
}

const BadgeContainer = styled.div`
  width: 24px;
  max-width: 24px;
`

const RecommendationList = styled.ul`
  display: flex;
  flex-direction: column;
  gap: 4px;
  list-style: none;
`

export type ItemChangeHandler = (skill: ContentSkill) => void

const RecommendationItem: React.FC<{
  skill: ContentSkill
  onAccept: ItemChangeHandler
  onReject: ItemChangeHandler
  onLevelChange: ItemChangeHandler
}> = ({ skill, onAccept, onReject, onLevelChange }) => {
  const { t } = useTranslation()

  return (
    <Row>
      <View gap='8'>
        <BadgeContainer>
          <SimpleBadge theme={skill.theme} iconId={skill.iconId} />
        </BadgeContainer>
        <View gap='6'>
          <Text size='small' bold>
            {skill.name}
          </Text>
          <Text color='foreground/secondary'>{t('skills.suggestion.label')}</Text>
        </View>
      </View>
      <View gap='12'>
        <ContentSkillLevelDropdown skill={skill} onSelect={onLevelChange} />
        <View gap='2'>
          <IconButton
            size='small'
            variant='transparent'
            color='foreground/muted'
            iconId='checkbox--cross--filled'
            onClick={() => onReject(skill)}
            tooltip={t('skills.suggestion.dismiss')}
          />
          <IconButton
            size='small'
            variant='transparent'
            color='success/background'
            iconId='checkbox--checkmark--filled'
            onClick={() => onAccept(skill)}
            tooltip={t('skills.suggestion.accept')}
          />
        </View>
      </View>
    </Row>
  )
}

export const SkillRecommendation: React.FC<{
  recommendation: ContentSkill[] | undefined
  onAccept: ItemChangeHandler
  onReject: ItemChangeHandler
  onLevelChange: ItemChangeHandler
  onRetriggerAutoAssign: () => void
}> = ({ recommendation, onAccept, onReject, onLevelChange, onRetriggerAutoAssign }) => {
  const [status, setStatus] = useState<TopBarStatus>('InProgress')
  const [showTopBar, setShowTopBar] = useState(true)

  useEffect(() => {
    let timer = undefined

    if (isDefined(recommendation)) {
      setStatus(recommendation.length > 0 ? 'Done' : 'NoRecommendationFound')

      // Add a delay to let the loading bar complete before the exit animation
      timer = setTimeout(() => {
        if (recommendation.length > 0) {
          setShowTopBar(false)
        }
      }, 400)
    } else {
      setStatus('InProgress')
    }

    return () => {
      clearTimeout(timer)
    }
  }, [recommendation])

  return (
    <View direction='column' gap='4'>
      <AnimatePresence>
        {showTopBar && (
          <motion.div layout {...rowAnimationProps}>
            <TopBar
              status={status}
              onClose={() => {
                setShowTopBar(false)
              }}
              onRetriggerAutoAssign={onRetriggerAutoAssign}
            />
          </motion.div>
        )}
      </AnimatePresence>
      <AnimatePresence>
        {isDefined(recommendation) && recommendation.length > 0 && (
          <RecommendationList>
            {recommendation.map(recSkill => (
              <motion.li key={recSkill.id} layout {...rowAnimationProps}>
                <RecommendationItem
                  skill={recSkill}
                  onAccept={onAccept}
                  onReject={onReject}
                  onLevelChange={onLevelChange}
                />
              </motion.li>
            ))}
          </RecommendationList>
        )}
      </AnimatePresence>
    </View>
  )
}
