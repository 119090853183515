import { forwardRef, useEffect, useImperativeHandle, useState } from 'react'
import { useTranslation } from 'sierra-client/hooks/use-translation'
import { TranslationKey } from 'sierra-client/hooks/use-translation/types'
import { Collaborator } from 'sierra-client/state/author-course-settings/types'
import { getAvatarImage } from 'sierra-client/utils/avatar-img'
import { getUserName } from 'sierra-domain/utils'
import { RoundAvatar } from 'sierra-ui/components'
import { Text, View } from 'sierra-ui/primitives'
import { palette } from 'sierra-ui/theming'
import styled from 'styled-components'

const MentionItems = styled.div`
  background: ${palette.primitives.white};
  border-radius: 6px;
  box-shadow: 0px 16px 24px rgba(0, 0, 0, 0.08);
  border: 1px solid ${palette.grey[5]};
  min-width: 250px;
  max-height: 220px;
  overflow-y: scroll;
  position: relative;

  & * {
    cursor: pointer;
  }
`

const TextContainer = styled(View)`
  padding: 0.75rem 0;
  margin: 0 1rem;
  border-bottom: 1px solid ${palette.grey[5]};
`

const MentionItem = styled.button<{ $selected: boolean }>`
  background: ${p => (p.$selected ? palette.grey[2] : 'transparent')};
  display: block;
  margin: 0;
  padding: 0;
  text-align: left;
  width: 100%;
  transition: background-color 0.1s cubic-bezier(0.25, 0.1, 0.25, 1);

  &:hover {
    background-color: ${palette.grey[2]};
  }

  &:last-of-type {
    ${TextContainer} {
      border-bottom: 0px solid ${palette.grey[5]};
    }
  }
`

const Role = styled(Text)`
  color: ${p => p.theme.color.grey25};
  margin-left: 1.5rem;
`

export type MentionListProps = {
  items: Collaborator[]
  command: ({ collaborator }: { collaborator: Collaborator }) => void
}

export type MentionListRef = {
  onKeyDown: (props: { event: Event }) => boolean
}

const roleToTranslationKey: Record<NonNullable<Collaborator['role']>, TranslationKey> = {
  owner: 'dictionary.owner',
  editor: 'author.role-editor',
  commenter: 'author.role-commenter',
}

export const MentionList = forwardRef<MentionListRef, MentionListProps>(({ items, command }, ref) => {
  const { t } = useTranslation()
  const [selectedIndex, setSelectedIndex] = useState(0)

  const selectItem = (index: number): void => {
    const item = items[index]

    if (item) command({ collaborator: item })
  }

  const upHandler = (): void => {
    setSelectedIndex((selectedIndex + items.length - 1) % items.length)
  }

  const downHandler = (): void => {
    setSelectedIndex((selectedIndex + 1) % items.length)
  }

  const enterHandler = (): void => {
    selectItem(selectedIndex)
  }

  useEffect(() => setSelectedIndex(0), [items])

  useImperativeHandle(ref, () => ({
    onKeyDown: ({ event }: { event: any }) => {
      event.stopPropagation()

      if (event.key === 'ArrowUp') {
        upHandler()
        return true
      }

      if (event.key === 'ArrowDown') {
        downHandler()
        return true
      }

      if (event.key === 'Enter' || event.key === 'Tab') {
        enterHandler()
        return true
      }

      return false
    },
  }))

  return (
    <MentionItems>
      {items.length > 0 ? (
        items.map((item, index) => (
          <MentionItem $selected={selectedIndex === index} key={index} onClick={() => selectItem(index)}>
            <TextContainer justifyContent='space-between'>
              <View>
                <RoundAvatar
                  firstName={item.firstName}
                  lastName={item.lastName}
                  src={getAvatarImage(item.uuid, item.avatar)}
                  color={item.avatarColor}
                  size='tiny'
                />
                <Text size='small' color='grey80' bold>
                  {getUserName(item)}
                </Text>
              </View>
              {item.role !== undefined && (
                <Role color='grey25' size='micro'>
                  {t(roleToTranslationKey[item.role])}
                </Role>
              )}
            </TextContainer>
          </MentionItem>
        ))
      ) : (
        <MentionItem $selected={false}>
          <TextContainer justifyContent='center'>No result</TextContainer>
        </MentionItem>
      )}
    </MentionItems>
  )
})
