import React, { useMemo } from 'react'
import { useInsightsViews } from 'sierra-client/features/insights/api-hooks/use-views'
import { getAvailableViewData } from 'sierra-client/features/insights/hooks/use-insights-views'
import { useOrganizationPermissions } from 'sierra-client/hooks/use-permissions'
import { useTranslation } from 'sierra-client/hooks/use-translation'
import { useCachedQuery } from 'sierra-client/state/api'
import { InsightsSerializeQueryResponse, Widget } from 'sierra-domain/api/insights'
import { DomainRep } from 'sierra-domain/filter/datatype/domain'
import { XAnalyticsSerializeQuery } from 'sierra-domain/routes'
import { Popover } from 'sierra-ui/components'
import { IconButton, LoadingSpinner, Text, View } from 'sierra-ui/primitives'
import { IconMenu } from 'sierra-ui/primitives/menu-dropdown'
import { getInsightsQueryOptions } from '../api-hooks/query-options'
import { cleanRequestFilter, toQueryRequest } from '../api-hooks/use-query-analytics-data'

export const copyTextToClipboard = async (text: string): Promise<void> => {
  try {
    await navigator.clipboard.writeText(text)
  } catch {
    throw new Error('Not able to copy')
  }
}

const curl = (host: string, query: string): string => {
  const escapedQuery = query.replace(/'/g, "'\\''").replace(/"/g, '\\"')

  return `curl -X POST '${host}/api/v1/reports/query' -H 'Content-Type: application/json' -H 'Authorization: Bearer <token>' -d '{ "query": "${escapedQuery}", "format": "csv" }'`
}

type CopySQL = 'copy-sql' | 'copy-curl'

const WidgetSqlViewer: React.FC<{ widget: Widget }> = ({ widget }) => {
  const { data: viewsData } = useInsightsViews()
  const allFilterDomainReps: DomainRep[] = useMemo(() => {
    return viewsData === undefined ? [] : getAvailableViewData({ viewsData }).availableFilters
  }, [viewsData])
  const { t } = useTranslation()
  const query = toQueryRequest(widget)
  const requestWithCleanedFilter = cleanRequestFilter(query, allFilterDomainReps)

  const sql = useCachedQuery(XAnalyticsSerializeQuery, requestWithCleanedFilter, {
    ...getInsightsQueryOptions<InsightsSerializeQueryResponse>(),
    staleTime: 5 * 60 * 1000,
    meta: {
      feature: 'insights',
      tags: { feature: 'insights' },
      reactComponent: 'SqlViewer',
      widget,
      queryRequest: query,
    },
  })
  if (sql.isPending) {
    return <LoadingSpinner />
  }
  if (sql.isError) {
    return (
      <IconButton
        iconId={'code'}
        variant='transparent'
        disabled
        tooltip={t('insights.serialize-sql.error')}
      />
    )
  }

  const sqlQuery = sql.data.query.replace(/\n/g, ' ').trim()
  const curlCommand = curl(window.location.origin, sqlQuery)

  return (
    <Popover renderTrigger={() => <IconButton iconId='code' variant='transparent' />} align='end'>
      <View paddingLeft='16' padding='none' direction='row'>
        <Text size='technical'>{sqlQuery}</Text>
        <IconMenu<CopySQL>
          iconId='clipboard'
          variant='transparent'
          menuItems={[
            {
              id: 'copy-sql',
              label: t('insights.serialize-sql.copy-sql'),
              type: 'label',
            },
            {
              id: 'copy-curl',
              label: t('insights.serialize-sql.copy-curl'),
              type: 'label',
            },
          ]}
          onSelect={async item => {
            switch (item.id) {
              case 'copy-sql':
                await copyTextToClipboard(sqlQuery)
                break
              case 'copy-curl':
                await copyTextToClipboard(curlCommand)
                break
            }
          }}
        />
      </View>
    </Popover>
  )
}

export const SqlViewer: React.FC<{ widget?: Widget }> = ({ widget }) => {
  const orgPermissions = useOrganizationPermissions()

  const { t } = useTranslation()

  if (!orgPermissions.has('MANAGE_API_CLIENTS')) {
    return null
  }

  if (widget === undefined) {
    return (
      <IconButton
        iconId={'code'}
        variant='transparent'
        disabled
        tooltip={t('insights.serialize-sql.incomplete')}
      />
    )
  }

  return <WidgetSqlViewer widget={widget} />
}
