import React from 'react'
import { ActionModal } from 'sierra-client/components/common/modals/action-modal'
import { useTranslation } from 'sierra-client/hooks/use-translation'

interface DeletePathModal {
  onDelete: () => Promise<void>
  isOpen: boolean
  onClose: () => void
}

export const DeletePathModal: React.FC<DeletePathModal> = ({ onDelete, isOpen, onClose }) => {
  const { t } = useTranslation()

  return (
    <ActionModal
      title={t('admin.organization.paths.delete-path')}
      open={isOpen}
      onClose={onClose}
      deleteAction
      primaryActionLabel={t('admin.delete')}
      primaryAction={() => {
        void onDelete()
        onClose()
      }}
    >
      {t('admin.confirm-delete-path')}
    </ActionModal>
  )
}

interface DuplicatePathModal {
  onDuplicate: () => Promise<void>
  isOpen: boolean
  onClose: () => void
}

export const DuplicatePathModal: React.FC<DuplicatePathModal> = ({ onDuplicate, isOpen, onClose }) => {
  const { t } = useTranslation()

  return (
    <ActionModal
      title={t('admin.organization.paths.duplicate-path')}
      open={isOpen}
      onClose={onClose}
      primaryActionLabel={t('dictionary.duplicate')}
      primaryAction={() => {
        void onDuplicate()
        onClose()
      }}
    >
      {t('admin.confirm-duplicate-path')}
    </ActionModal>
  )
}
