import { useMutation, useQuery } from '@tanstack/react-query'
import { useState } from 'react'
import { Modal } from 'sierra-client/components/common/modals/modal'
import { usePost } from 'sierra-client/hooks/use-post'
import { useTranslation } from 'sierra-client/hooks/use-translation'
import { ContentIdsFilter, OutputFormatFilter } from 'sierra-client/views/manage/reports/components'
import { ReportFilter, getDefaultValuesForReport } from 'sierra-client/views/manage/reports/use-reports'
import { Report, ReportJob, SubmitJobResponse } from 'sierra-domain/api/analytics-reporting'
import {
  XRealtimeAdminHomeworkListCourseIdsWithHomework,
  XRealtimeAnalyticsReportsSubmitJob,
} from 'sierra-domain/routes'
import { Button, Spacer, Text } from 'sierra-ui/primitives'

type ExerciseSubmissionReportModalProps = {
  isOpen: boolean
  onClose: () => void
  report: Report
  title?: string
  addJob: (job: ReportJob) => void
}

export const ExerciseSubmissionReportModal: React.FC<ExerciseSubmissionReportModalProps> = ({
  report,
  isOpen,
  onClose,
  title,
  addJob,
}) => {
  const { postWithUserErrorException } = usePost()
  const { t } = useTranslation()
  const [formValues, setFormValues] = useState<ReportFilter>(getDefaultValuesForReport([], report))

  const { data: selectableIds } = useQuery({
    queryKey: [XRealtimeAdminHomeworkListCourseIdsWithHomework.path],
    queryFn: async () => {
      const response = await postWithUserErrorException(XRealtimeAdminHomeworkListCourseIdsWithHomework, {})
      return response.data
    },
    enabled: isOpen,
  })

  const { isPending, error, mutate } = useMutation<SubmitJobResponse, Error, ReportFilter>({
    mutationFn: (reportFilter: ReportFilter) =>
      postWithUserErrorException(XRealtimeAnalyticsReportsSubmitJob, {
        reportId: report.id,
        ...reportFilter,
      }),

    onSuccess: submittedJob => {
      addJob(submittedJob.job)
      onClose()
    },
  })

  const createReport = (reportFilter: ReportFilter): void => {
    mutate(reportFilter)
  }

  return (
    <Modal
      open={isOpen}
      onClose={onClose}
      title={t('manage.reports.filter.modal.title', { reportName: title })}
      size='small'
    >
      {report.parameters.map(param => {
        switch (param.type) {
          case 'contentIds':
            return (
              <ContentIdsFilter
                key={param.type}
                contentTypes={formValues.contentTypes ?? []}
                selectedIds={formValues.contentIds}
                updateSelectedIds={contentIds => setFormValues(values => ({ ...values, contentIds }))}
                selectableIds={selectableIds}
              />
            )
          case 'outputFormat':
            return (
              <OutputFormatFilter
                key={param.type}
                selectedFormat={formValues.outputFormat}
                allowedFormats={param.formats}
                updateFormat={outputFormat => setFormValues(values => ({ ...values, outputFormat }))}
              />
            )
        }
      })}
      <Button grow disabled={isPending} onClick={() => createReport(formValues)}>
        {isPending ? t('admin.analytics.downloading') : t('admin.analytics.download-report')}
      </Button>
      {error?.message !== undefined && (
        <>
          <Spacer size='xxsmall' />
          <Text size='small' color='redBright'>
            {error.message}
          </Text>
        </>
      )}
    </Modal>
  )
}
