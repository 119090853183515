import { useMemo } from 'react'
import { useDeepEqualityMemo } from 'sierra-client/hooks/use-deep-equality-memo'
import { useTranslation } from 'sierra-client/hooks/use-translation'
import { FCC } from 'sierra-client/types'
import { TruncatedText } from 'sierra-ui/components'
import { Button, Spacer } from 'sierra-ui/primitives'
import { token } from 'sierra-ui/theming'
import styled from 'styled-components'

const ImageInfo = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: space-between;
  height: 100%;
  flex-wrap: wrap;
  padding-right: 16px;
  flex: 1;
`

const ImageUploadContainer = styled.div`
  display: flex;
  flex-grow: 1;
  gap: 16px;
  align-items: center;
  height: 180px;
  border-radius: 16px;
  background-color: ${token('form/background/1')};
  border: 1px solid ${token('form/border/1')};
  padding: 16px;
  overflow: hidden;
`

const CoverImage = styled.img`
  border-radius: 8px;
  height: 100%;
  width: 50%;
  object-fit: cover;
`

const Placeholder = styled.div`
  height: 100%;
  width: 50%;
  background-color: ${token('form/background/2')};
  border-radius: 8px;
`

export type ImageUploadControlProps = {
  onUpload: () => void
  image: string | undefined
  disabled?: boolean
  recommendedDimensions?: { height: number; width: number }
  supportedFormats?: Array<'.jpg' | '.png'>
}

const defaultDimensions: NonNullable<ImageUploadControlProps['recommendedDimensions']> = {
  width: 1920,
  height: 1080,
}
const defaultFormats: NonNullable<ImageUploadControlProps['supportedFormats']> = ['.jpg', '.png']

export const ImageUploadControl: FCC<ImageUploadControlProps> = ({
  onUpload,
  image,
  children,
  disabled,
  recommendedDimensions = defaultDimensions,
  supportedFormats = defaultFormats,
}) => {
  const { t } = useTranslation()

  const { height, width } = useMemo(() => {
    return { height: recommendedDimensions.height, width: recommendedDimensions.width }
  }, [recommendedDimensions.height, recommendedDimensions.width])

  const formats = useDeepEqualityMemo(supportedFormats)

  return (
    <ImageUploadContainer>
      <>
        {image === undefined ? <Placeholder /> : <CoverImage src={image} />}
        <ImageInfo>
          <div>
            <TruncatedText lines={2} size='small' color='grey35'>
              {t('manage.upload.recommended-dimensions', {
                height,
                width,
              })}
            </TruncatedText>
            <Spacer size='4' />
            <TruncatedText lines={1} size='small' color='grey35'>
              {t('manage.upload.supported-formats', { formats: formats.join(', ') })}
            </TruncatedText>
          </div>
          <Button variant='secondary' type='button' onClick={onUpload} disabled={disabled}>
            {image === undefined ? t('manage.upload-image') : t('manage.change-image')}
          </Button>
        </ImageInfo>
      </>

      {children}
    </ImageUploadContainer>
  )
}
