import _ from 'lodash'
import { isInElement, unwrapToTextNodes } from 'sierra-client/views/v3-author/command'
import { isElementType } from 'sierra-client/views/v3-author/queries'
import { createParagraph } from 'sierra-domain/v3-author/create-blocks'
import { Editor, Node, Range, Text, Transforms } from 'slate'

function debug(...messages: unknown[]): void {
  console.debug('[withMarkdown]', ...messages)
}

function getMarks(text: Text): string[] {
  return Object.keys(text).filter(it => it !== 'text')
}

export const withMarkdown = (editor: Editor): Editor => {
  const { insertBreak, normalizeNode } = editor

  editor.insertBreak = () => {
    if (!isInElement(editor, 'markdown')) return insertBreak()
    const { selection } = editor
    if (selection === null || !Range.isCollapsed(selection)) return insertBreak()

    const { offset } = selection.focus
    const [textNode] = Node.texts(editor, { from: selection.focus.path, to: selection.anchor.path })
    if (textNode) {
      const [{ text }] = textNode
      const isAtTheEndOfBlock = offset === text.length
      const isLastLineANewLine = text[offset - 1] === '\n'
      if (isAtTheEndOfBlock && isLastLineANewLine) {
        debug('Pressed enter at the end of a newline at the end of a markdown block, exiting the block')
        editor.deleteBackward('character')
        return Transforms.insertNodes(editor, createParagraph())
      }
    }

    debug('Inserting a newline in a markdown block, replacing with \\n')
    return Transforms.insertText(editor, '\n')
  }

  editor.normalizeNode = entry => {
    const [node, path] = entry
    if (!isElementType('markdown', node)) return normalizeNode(entry)

    for (const [child, childPath] of Node.children(editor, path)) {
      if (Text.isText(child)) {
        const marks = getMarks(child)
        if (marks.length > 0) {
          const removeMarks = Object.fromEntries(marks.map(mark => [mark, undefined]))
          debug(`Removing ${JSON.stringify(marks)} from text at ${JSON.stringify(childPath)}`)
          return Transforms.setNodes(editor, removeMarks, { at: childPath })
        }

        const index = _.last(childPath) ?? 0
        if (index > 0) {
          debug(`Merging text at index ${index}, path: ${JSON.stringify(childPath)}`)
          return Transforms.mergeNodes(editor, { at: childPath })
        }
      } else {
        debug(`Unwrapping non-text in markdown at path: ${JSON.stringify(childPath)}`)
        return unwrapToTextNodes(editor, { at: childPath })
      }
    }

    normalizeNode(entry)
  }

  return editor
}
