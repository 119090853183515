import { Editor } from 'slate'

const debug = (...messages: unknown[]): void => console.debug('[withAddMark]', ...messages)

/**
 * Calling `addMark` with a value of `false` or `undefined`
 * can cause sync issues in yjs. To avoid this, we call `removeMark` explicitly
 * in these cases instead.
 */
export const withAddMark = <T extends Editor>(editor: T): T => {
  const { addMark } = editor

  editor.addMark = (key, value) => {
    if (value === false || value === undefined) {
      debug(`Attempted to add a ${value} mark, removing instead`, key, value)
      return editor.removeMark(key)
    } else {
      return addMark(key, value)
    }
  }

  return editor
}
