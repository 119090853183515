import React, { useState } from 'react'
import { Thumbnail } from 'sierra-client/components/common/thumbnail'
import { useTranslation } from 'sierra-client/hooks/use-translation'
import {
  IssuedCertificateItem,
  useGetLinkedInUrl,
} from 'sierra-client/views/workspace/learn/my-certificates/utils'

import { CertificatePreviewUrlModal } from 'sierra-client/views/manage/certificates/certificate-preview-url-modal'
import { ExpiredText } from 'sierra-client/views/workspace/learn/my-certificates/expired-text'
import { useTracking } from 'sierra-client/views/workspace/utils/certificates/tracking'
import { Button, Text, View } from 'sierra-ui/primitives'
import { token } from 'sierra-ui/theming'
import styled from 'styled-components'

const CTAContainer = styled(View)`
  opacity: 0;
  transition: opacity 100ms cubic-bezier(0.25, 0.1, 0.25, 1);
`
const CTAButton = styled(Button)`
  text-transform: capitalize;
`
const DetailButton = styled(CTAButton)`
  background-color: transparent;
  color: ${token('foreground/muted')};

  &:hover {
    background-color: ${token('surface/soft')};
    color: inherit;
  }
`
const Container = styled(View)`
  cursor: pointer;

  &:hover {
    ${CTAContainer} {
      opacity: 1;
    }
  }
`

const CertificateItem: React.FC<{
  item: IssuedCertificateItem
  hasCTA?: boolean
  initiallyOpen?: boolean
}> = ({ item, hasCTA = false, initiallyOpen = false }) => {
  const { t } = useTranslation()
  const [open, setOpen] = useState(initiallyOpen)
  const linkedInUrl = useGetLinkedInUrl(item)
  const track = useTracking()

  return (
    <>
      <Container
        tabIndex={0}
        onClick={() => {
          setOpen(true)
          track.myList.click(item.certificate.id)
        }}
        justifyContent='space-between'
      >
        <View alignItems='center'>
          <Thumbnail image={item.snapshotImageUrl} height={2} width={2.85} radius={0.25} />
          <View direction='column' gap='none'>
            <Text color='LEGACY_DEFAULT_TEXT_COLOR_REPLACE_ASAP' bold>
              {item.certificate.title}
            </Text>
            <ExpiredText revokedAt={item.revokedAt} expiresAt={item.expiresAt} />
          </View>
        </View>
        {hasCTA && (
          <CTAContainer tabIndex={0}>
            <CTAButton
              variant='secondary'
              onClick={e => {
                setOpen(true)
                track.myList.click(item.certificate.id)
                e.stopPropagation()
              }}
            >
              {t('dictionary.view')}
            </CTAButton>
            <DetailButton
              onClick={e => {
                window.open(item.pdfUrl, '_self')
                e.stopPropagation()
              }}
              variant='ghost'
            >
              {t('dictionary.download')}
            </DetailButton>
          </CTAContainer>
        )}
      </Container>
      <CertificatePreviewUrlModal
        animated
        open={open}
        onClose={() => setOpen(false)}
        url={item.snapshotImageUrl}
        downloadUrl={item.pdfUrl}
        onShare={() => {
          track.linkedin.share(item.certificate.id)
        }}
        onDownload={() => {
          track.download.click(item.certificate.id)
        }}
        supportingFileUrl={item.supportingFileUrl}
        linkedInUrl={linkedInUrl}
        certificateTitle={item.certificate.title}
        canShare={true}
        btnText={t('dictionary.close')}
      />
    </>
  )
}

export const CertificateList: React.FC<{ items: Array<IssuedCertificateItem>; hasCTA?: boolean }> = ({
  items,
  hasCTA,
}) => {
  const urlParams = new URLSearchParams(window.location.search)
  const openCertificate = urlParams.get('myCert')
  return (
    <View direction='column' gap='12'>
      {items.map(item => (
        <CertificateItem
          key={item.certificate.id}
          item={item}
          hasCTA={hasCTA}
          initiallyOpen={item.id === openCertificate}
        />
      ))}
    </View>
  )
}
