import _ from 'lodash'
import { headingLevelToListItemLevel } from 'sierra-client/views/v3-author/list/utils'
import { isElementType, nodeAtPath, parentType } from 'sierra-client/views/v3-author/queries'
import {
  isValidParagraphChild,
  unwrapNonParagraphChildren,
  unwrapNonParagraphChildrenAtPath,
} from 'sierra-client/views/v3-author/unwrap-non-paragraph-children'
import { textInNodes } from 'sierra-domain/slate-util'
import { createCheckListItem, createParagraph } from 'sierra-domain/v3-author/create-blocks'
import { Editor, Element, Node, Path, Range, Transforms } from 'slate'

const debug = (...messages: unknown[]): void => console.debug('[withCheckList]', ...messages)

export const withCheckLists = (editor: Editor): Editor => {
  const { normalizeNode, deleteBackward, insertBreak } = editor

  editor.normalizeNode = entry => {
    const [node, path] = entry

    if (Element.isElement(node) && node.type === 'check-list') {
      // Make sure all child nodes are list-items
      for (const [child, childPath] of Node.children(editor, path)) {
        const isCheckListItem = Element.isElement(child) && child.type === 'check-list-item'
        if (!isCheckListItem) {
          const checkListItemLevel =
            Element.isElement(child) && child.type === 'heading'
              ? headingLevelToListItemLevel(child.level)
              : Element.isElement(child) && child.type === 'paragraph' && textInNodes([child])[0] !== ''
                ? child.level
                : undefined

          debug('Converting non-check-list-item node from check-list')
          return Transforms.wrapNodes(editor, createCheckListItem({ level: checkListItemLevel }), {
            at: childPath,
          })
        }
      }

      // Merge adjacent lists

      if (_.last(path) !== 0) {
        const previousPath = Path.previous(path)
        const previousNode = nodeAtPath(editor.children, previousPath)
        // Merge previous list
        if (
          Element.isElement(previousNode) &&
          previousNode.type === node.type &&
          previousNode.id !== node.id
        ) {
          return Transforms.mergeNodes(editor, { at: path })
        }
      }

      const nextPath = Path.next(path)
      const nextNode = nodeAtPath(editor.children, nextPath)
      // Merge next list
      if (Element.isElement(nextNode) && nextNode.type === node.type && nextNode.id !== node.id) {
        return Transforms.mergeNodes(editor, { at: nextPath })
      }
    }

    // check-list-item nodes must be wrapped in a check-list
    if (isElementType('check-list-item', node)) {
      const parent = parentType(editor, path)
      const isInCheckList = parent === 'check-list'
      if (!isInCheckList) {
        debug(`Detected check list item outside of check list. Converting to a paragraph`)

        Transforms.unwrapNodes(editor, { at: path, mode: 'all', voids: true })
        return Transforms.wrapNodes(editor, createParagraph(), { at: path })
      }

      for (const [child] of Array.from(Node.children(editor, path))) {
        const isValidType = isValidParagraphChild(child)
        if (!isValidType) {
          debug(`Unwrapping unexpected element ${JSON.stringify(child)} in list-item at`, path)
          return unwrapNonParagraphChildren(editor, entry)
        }
      }
    }

    normalizeNode(entry)
  }

  editor.insertBreak = () => {
    const { selection } = editor

    if (selection && Range.isCollapsed(selection)) {
      const [match] = Editor.nodes(editor, { match: isElementType('check-list-item') })

      if (match !== undefined) {
        Transforms.splitNodes(editor, { at: selection, always: true })
        const [match] = Editor.nodes(editor, { match: isElementType('check-list-item') })
        if (match !== undefined) {
          const [, path] = match
          Transforms.setNodes(editor, { checked: false }, { at: path })
        }
        return
      }
    }

    insertBreak()
  }

  editor.deleteBackward = unit => {
    const { selection } = editor

    // If the cursor is at the start of the first check-list-item in a check-list,
    // deleting backward should transform the current check-list item into a paragraph and split out the rest of the check-list.
    if (selection && Range.isCollapsed(selection)) {
      const [checkListItem, checkListItemPath] = Editor.node(editor, Path.parent(selection.anchor.path))
      const [checkList, checkListPath] = Editor.node(editor, Path.parent(checkListItemPath))

      if (!(Element.isElement(checkList) && checkList.type === 'check-list')) return deleteBackward(unit)

      if (!(Element.isElement(checkListItem) && checkListItem.type === 'check-list-item'))
        return deleteBackward(unit)

      const listLength = Array.from(checkList.children).length
      const isFirstListItem = _.last(checkListItemPath) === 0
      const isLastListItem = _.last(checkListItemPath) === listLength - 1
      const isCursorAtTheStartOfTheNode = selection.anchor.offset === 0 && selection.focus.offset === 0
      if (!isCursorAtTheStartOfTheNode) return deleteBackward(unit)

      debug('Deleted backwards at the start of a check list item. Converting it into a paragraph.')

      const path = checkListPath

      // We dont want to normalize here since merginf adjacent lists interferes with it.
      Editor.withoutNormalizing(editor, () => {
        // Split after if not last list item
        if (!isLastListItem) Transforms.splitNodes(editor, { at: Path.next(checkListItemPath) })

        // Split before
        Transforms.splitNodes(editor, { at: checkListItemPath })

        Transforms.wrapNodes(editor, createParagraph(), { at: Path.next(path) })

        unwrapNonParagraphChildrenAtPath(editor, Path.next(path))

        if (isFirstListItem) Transforms.removeNodes(editor, { at: path })
      })

      return
    }

    deleteBackward(unit)
  }

  return editor
}
