/* eslint-disable @typescript-eslint/no-explicit-any */
import { Context, createContext, useContext, useReducer } from 'react'

export const createGlobalContext = <T,>(
  initialState: T,
  callback?: (state: T) => void
): {
  Context: Context<any>
  Provider: ({ children }: { children: any }) => JSX.Element
  useGlobalState: () => [T, (r: T | ((state: T, stateUpdate: any) => T)) => void]
} => {
  type Reducer = (state: T, stateUpdate: any) => T

  const reducer: Reducer = (state: T, stateUpdate: any) => {
    if (typeof stateUpdate === 'function') {
      return stateUpdate(state)
    } else {
      return stateUpdate
    }
  }

  const Context = createContext<any>(undefined)

  const Provider = ({ children }: { children: any }): JSX.Element => (
    <Context.Provider value={useReducer(reducer, initialState)}>{children}</Context.Provider>
  )

  const useGlobalState = (): [T, (r: Reducer | T) => void] => {
    const [getter, setter] = useContext(Context)
    const setterWithCallback = (stateUpdate: Reducer | T): void => {
      setter(stateUpdate)
      if (callback) callback(reducer(getter as T, stateUpdate))
    }
    return [getter, setterWithCallback]
  }

  return {
    Context,
    Provider,
    useGlobalState,
  }
}
