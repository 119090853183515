import React from 'react'
import { useTranslation } from 'sierra-client/hooks/use-translation'
import { getAttendanceTranslationKey } from 'sierra-client/views/manage/live-session/live-session-utils'
import { LiveSessionAttendanceStatus } from 'sierra-domain/api/manage'
import { MUIMenuItem } from 'sierra-ui/mui'
import { Text } from 'sierra-ui/primitives'

export const AttendanceMenuItems: React.FC<{ onClick: (type: LiveSessionAttendanceStatus) => void }> = ({
  onClick,
}) => {
  const { t } = useTranslation()
  return (
    <>
      <MUIMenuItem onClick={() => onClick('present')}>
        <Text color='LEGACY_DEFAULT_TEXT_COLOR_REPLACE_ASAP' size='small'>
          {t(getAttendanceTranslationKey('present'))}
        </Text>
      </MUIMenuItem>
      <MUIMenuItem onClick={() => onClick('absent')}>
        <Text color='LEGACY_DEFAULT_TEXT_COLOR_REPLACE_ASAP' size='small'>
          {t(getAttendanceTranslationKey('absent'))}
        </Text>
      </MUIMenuItem>
    </>
  )
}
