import { useGetFormattedTime } from 'sierra-client/core/format'
import { NextUpCourseInformation } from 'sierra-client/views/course-helper/content/next-up-course-information'
import { ContentWithEnrollmentInformation } from 'sierra-client/views/course-helper/content/next-up-enrollment-metadata/next-up-enrollement-metadata'
import { CourseForHref } from 'sierra-domain/api/backend-self-paced/types'
import { isDefined } from 'sierra-domain/utils'

export const NextUpCourseInPathOrProgram: React.FC<{
  course: CourseForHref
  courseTitle: string
  goToNextUp: () => void
  goToOverViewPage: () => void
  buttons: React.ReactNode
}> = ({ course, courseTitle, goToOverViewPage, buttons }) => {
  const duration = isDefined(course.duration) ? course.duration / 1000 : undefined
  const formattedDuration = useGetFormattedTime(duration, false)

  if (course.type === 'native:live' || course.type === 'native:event-group') {
    return (
      <ContentWithEnrollmentInformation
        course={course}
        courseTitle={courseTitle}
        contentInformation={{
          id: course.id,
          type: course.type,
        }}
        goToOverViewPage={goToOverViewPage}
      />
    )
  }

  return (
    <>
      <NextUpCourseInformation
        course={course}
        courseTitle={courseTitle}
        extraInformation={formattedDuration}
      />
      {buttons}
    </>
  )
}
