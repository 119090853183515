import _ from 'lodash'
import React, { FC } from 'react'
import { useTranslation } from 'sierra-client/hooks/use-translation'
import { CardGridV2 } from 'sierra-client/views/learner/lesson/grid'
import {
  StickyNotesColorPicker,
  stickyNoteColors,
} from 'sierra-client/views/sticky-notes-card/sticky-notes-color-picker'
import { removeNodeWithId, updateNodeWithId } from 'sierra-client/views/v3-author/command'
import { useSiblings } from 'sierra-client/views/v3-author/hooks'
import { assertElementType, isElementType } from 'sierra-client/views/v3-author/queries'
import { RenderingContext } from 'sierra-client/views/v3-author/rendering-context'
import { SlateFC } from 'sierra-client/views/v3-author/slate'
import { hasOnlyEmptyTextInNodes } from 'sierra-domain/slate-util'
import { asNonNullable } from 'sierra-domain/utils'
import { createStickyNotesCardSection } from 'sierra-domain/v3-author/create-blocks'
import { color as resolveColor } from 'sierra-ui/color'
import { ColorName } from 'sierra-ui/color/types'
import { RoundAvatar } from 'sierra-ui/components'
import { Button, IconButton, Spacer, View } from 'sierra-ui/primitives'
import { fonts } from 'sierra-ui/theming/fonts'
import { Path } from 'slate'
import { ReactEditor, useSlateStatic } from 'slate-react'
import styled from 'styled-components'

const SectionGrid = styled.div`
  display: grid;
  grid-gap: 1rem;
  grid-template-columns: repeat(auto-fit, 15rem);
  list-style: none;
  margin: 0;
  user-select: none;
`

const SectionHeader = styled(View).attrs({
  gap: 'none',
})`
  padding-bottom: 0.75rem;
  border-bottom: 1px solid rgba(0, 0, 0, 0.05);
  margin-bottom: 1.5rem;
`

const NoteWrapper = styled.div<{ $color: string; $fontSize: string }>`
  position: relative;
  width: 15rem;
  height: 15rem;
  padding: 1.5rem;
  font-size: ${p => p.$fontSize};
  transition: background-color 0.1s ease-in-out;
  background-color: ${p => p.$color};
  box-shadow: 0px 4px 12px 0px rgba(0, 0, 0, 0.03);
`

const NoteFooter = styled(View).attrs({ gap: 'none' })`
  position: absolute;
  bottom: 1.25rem;
  left: 1.5rem;
  right: 1.5rem;
`

const NoteFooterText = styled.div`
  ${fonts.body.micro};
  font-weight: 500;
  color: rgba(0, 0, 0, 0.25);
`

const Note: FC<{
  color: string
  text: string
  fontSize: string
  userName: string
  userColor: ColorName
}> = ({ color, text, fontSize, userName, userColor }) => (
  <NoteWrapper $color={color} $fontSize={fontSize}>
    {text}

    <NoteFooter>
      <RoundAvatar size='small' firstName={userName} color={userColor} />
      <Spacer size='xxsmall' />
      <NoteFooterText>{userName}</NoteFooterText>
      <View grow />
      <IconButton
        iconId='star'
        variant='transparent'
        color={resolveColor('rgba(0, 0, 0, 0.25)')}
        tabIndex={-1}
        tooltip='React to note'
      />
    </NoteFooter>
  </NoteWrapper>
)

export const StickyNotesCardContainer = styled(CardGridV2).attrs(p => ({
  children: (
    <RenderingContext
      withGrid={false}
      preventDrag={true}
      disableMenu={true}
      placeholder='sticky-notes.title.placeholder'
    >
      <>{p.children}</>
    </RenderingContext>
  ),
}))`
  color: ${p => p.theme.home.textColor};
  flex: 0 0 100%;
  padding-top: 3rem;
  padding-bottom: 3rem;
  grid-auto-rows: minmax(min-content, max-content);
  row-gap: 2rem;

  & > p {
    ${fonts.heading.h4}
  }
`

export const StickyNotesCardSectionContainer = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
  min-width: 1rem;
  margin-top: 1.5rem;
`

const SectionText = styled.p`
  position: relative;
  ${fonts.heading.h5}
  font-weight: 500;
  color: #000;
  border-radius: 0.125rem;
  margin-left: 0.25rem;

  &::placeholder {
    color: rgba(0, 0, 0, 0.25);
  }
`

const ShiftRight = styled.div`
  margin-left: auto;
`

const Placeholder = styled.span.attrs({ contentEditable: false })`
  color: rgba(0, 0, 0, 0.25);
  pointer-events: none;
  position: absolute;
  margin-left: auto;
  margin-right: auto;
  left: 0;
  right: 0;
  text-align: inherit;
  white-space: nowrap;
`

export const StickyNotesCardSection: SlateFC = ({ element, children, readOnly }) => {
  assertElementType('sticky-notes-card-section', element)
  const editor = useSlateStatic()
  const siblingSections = useSiblings({ nodeId: element.id }).filter(
    isElementType('sticky-notes-card-section')
  )
  const isEmpty = hasOnlyEmptyTextInNodes([element])
  const isLastSection = _.last(siblingSections)?.id === element.id
  const { t } = useTranslation()

  const [colorPreview, setColorPreview] = React.useState<string | undefined>(undefined)
  const selectedColor = element.color ?? asNonNullable(stickyNoteColors[0])
  const noteColor = colorPreview ?? element.color ?? asNonNullable(stickyNoteColors[0])

  return (
    <>
      <SectionHeader>
        <StickyNotesColorPicker
          selectedColor={selectedColor}
          onMouseOver={color => setColorPreview(color)}
          onMouseOut={color => setColorPreview(previous => (previous === color ? undefined : previous))}
          onClick={color => updateNodeWithId(editor, element.id, { color })}
        />
        <SectionText>
          {isEmpty && <Placeholder>Name your section...</Placeholder>}
          {children}
        </SectionText>
        {!readOnly && siblingSections.length > 1 && (
          <ShiftRight contentEditable={false}>
            <IconButton
              iconId='trash-can'
              variant='transparent'
              tabIndex={-1}
              onClick={() => removeNodeWithId(editor, element.id)}
              tooltip='Delete section'
            />
          </ShiftRight>
        )}
      </SectionHeader>
      <SectionGrid contentEditable={false}>
        <Note
          color={noteColor}
          fontSize='21px'
          text={'Ducimus accusamus ut voluptatem dolore ratione!'}
          userName='Johanna'
          userColor='greenBright'
        />
        <Note
          color={noteColor}
          fontSize='14px'
          text={
            'Dolor delectus!! Consequatur aut architecto accusantium consequatur. Distinctio autem delectus est nemo porro autem quam enim laborum. Eaque sit et sunt quis velit?'
          }
          userName='Milton'
          userColor='blueBright'
        />
      </SectionGrid>
      {isLastSection && !readOnly && (
        <View contentEditable={false} marginTop='large'>
          <Button
            variant='secondary'
            icon='add'
            onClick={() => {
              const path = ReactEditor.findPath(editor, element)
              const insertAt = Path.next(path)
              editor.insertNodes(createStickyNotesCardSection(), { at: insertAt })
            }}
          >
            {t('sticky-notes.add-board')}
          </Button>
        </View>
      )}
    </>
  )
}
