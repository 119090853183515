import React from 'react'
import { getCourseDefaultImage } from 'sierra-client/api/content'
import { IconListItem, VerticalIconList } from 'sierra-client/components/common/icon-list'
import { SanaImage } from 'sierra-client/components/common/image'
import { Pill, PillRow } from 'sierra-client/components/common/pill'
import { useLocalizedFormatters, useTimeFormatter } from 'sierra-client/core/format'
import { useTranslation } from 'sierra-client/hooks/use-translation'
import { EllipsedText } from 'sierra-client/views/learner/components/overview/ellipsed-text'
import { CollapsableItem } from 'sierra-client/views/learner/course/components/collapsable-item'
import { BaseUnitListItem } from 'sierra-client/views/learner/course/components/module-and-quiz-list'
import {
  getDifficultyLevelIconId,
  getDifficultyLevelTranslationKey,
} from 'sierra-client/views/manage/linked-in/linked-in-utils'
import { LinkedInCourseRecord } from 'sierra-domain/api/partner'
import { ImageUnion } from 'sierra-domain/content/v2/image-union'
import { Heading, Spacer, Text } from 'sierra-ui/primitives'
import { spacing, token } from 'sierra-ui/theming'
import styled from 'styled-components'

const Container = styled.div`
  padding-top: 2rem;
`

const Divider = styled.div`
  width: 100%;
  background-color: ${token('border/strong')};
  height: 1px;
`

const InfoGrid = styled.div`
  display: grid;
  grid-template-columns: 1fr 16rem;
  gap: ${spacing['80']};
`

type LinkedInCourseDetailsProps = {
  course?: LinkedInCourseRecord
  action?: JSX.Element
}

export const LinkedInCourseDetailsOutline: React.FC<{ contents: LinkedInCourseRecord['contents'] }> = ({
  contents,
}) => {
  const { t } = useTranslation()

  return (
    <>
      {contents.map((module, moduleIndex) => (
        <CollapsableItem
          key={moduleIndex}
          label={module.name}
          sublabel={
            module.contents === undefined
              ? ''
              : module.contents.length > 1
                ? t('content.n-articles', { n: module.contents.length })
                : t('content.1-article')
          }
          iconId='document--import'
          onlyOneOpen={false}
          isLocked={false}
        >
          {module.contents?.map((lesson, lessonIndex) => (
            <BaseUnitListItem key={lessonIndex} label={lesson.name} />
          ))}
        </CollapsableItem>
      ))}
    </>
  )
}

export const LinkedInCourseDetails: React.FC<LinkedInCourseDetailsProps> = ({ course, action }) => {
  const { t } = useTranslation()
  const { formatTimestamp } = useLocalizedFormatters()
  const { timeFormatter } = useTimeFormatter()

  const resolveImage = (image: ImageUnion | undefined): string => {
    if (image?.type === 'url') {
      return image.url
    }
    return getCourseDefaultImage()
  }

  if (course === undefined) return null

  return (
    <Container>
      <SanaImage src={resolveImage(course.image)} ratio='21:9' rounded />
      <Spacer size='small' />
      <InfoGrid>
        <div>
          <Heading color='LEGACY_DEFAULT_HEADING_COLOR_REPLACE_ASAP' size='h4' bold>
            {course.title}
          </Heading>
          <Spacer size='small' />
          <EllipsedText text={course.description} />
          <Spacer size='medium' />
          <Text color='LEGACY_DEFAULT_TEXT_COLOR_REPLACE_ASAP' size='small' bold>
            {t('course-overview.outline')}
          </Text>

          <Spacer size='xsmall' />
          <Divider />
          <LinkedInCourseDetailsOutline contents={course.contents} />
        </div>
        <div>
          {action && (
            <>
              {action}
              <Spacer size='small' />
              <Divider />
              <Spacer size='small' />
            </>
          )}

          <Text color='LEGACY_DEFAULT_TEXT_COLOR_REPLACE_ASAP' size='small' bold>
            {t('course-overview.details')}
          </Text>
          <Spacer size='xxsmall' />

          <VerticalIconList>
            <IconListItem iconId='time' text={timeFormatter(course.timeToComplete) ?? ''} />
            <IconListItem iconId='calendar' text={formatTimestamp(course.lastUpdatedAt)} />
            {course.difficultyLevel !== undefined ? (
              <IconListItem
                iconId={getDifficultyLevelIconId(course.difficultyLevel)}
                text={t(getDifficultyLevelTranslationKey(course.difficultyLevel))}
              />
            ) : null}
          </VerticalIconList>

          <Spacer size='small' />
          <Divider />
          <Spacer size='small' />

          <Text color='LEGACY_DEFAULT_TEXT_COLOR_REPLACE_ASAP' size='small' bold>
            {t('manage.linked-in.subjects-and-topics')}
          </Text>
          <Spacer size='xxsmall' />
          <PillRow $multiline>
            {course.tags.map(liTag => (
              <Pill key={liTag}>{liTag}</Pill>
            ))}
          </PillRow>

          <Spacer size='small' />
          <Divider />
          <Spacer size='small' />

          <Text color='LEGACY_DEFAULT_TEXT_COLOR_REPLACE_ASAP' size='small' bold>
            {t('manage.linked-in.instructor', { count: course.contributors.length })}
          </Text>
          <Spacer size='xxsmall' />
          <Text color='LEGACY_DEFAULT_TEXT_COLOR_REPLACE_ASAP' size='small'>
            {course.contributors.map(c => c.name).join(', ')}
          </Text>
        </div>
      </InfoGrid>
    </Container>
  )
}
