import { useTranslation } from 'sierra-client/hooks/use-translation'
import { Bubble } from 'sierra-client/views/manage/programs/components/bubble'
import { AddEnrollmentRule } from 'sierra-client/views/manage/programs/enrollment-rules/add-enrollment-rule'
import { EnrollmentRule } from 'sierra-client/views/manage/programs/enrollment-rules/enrollment-rule'
import { useEnrollmentRulesByContext } from 'sierra-client/views/manage/programs/enrollment-rules/use-enrollment-rules-by-context'
import { useEnrollmentRuleDomainReps } from 'sierra-client/views/manage/programs/enrollment-rules/utils'
import { EnrollmentRuleContext } from 'sierra-domain/api/manage'
import { ProgramId } from 'sierra-domain/api/uuid'
import { ImageUnion } from 'sierra-domain/content/v2/content'
import { Text, View } from 'sierra-ui/primitives'
import styled from 'styled-components'

const Wrapper = styled(View).attrs({ direction: 'column', gap: '16' })``

const HeadlineWrapper = styled(View).attrs({
  direction: 'row',
  alignItems: 'center',
  justifyContent: 'flex-start',
})``

const Headline = styled(Text).attrs({ size: 'small', bold: true })``

export const EnrollmentRuleSection: React.FC<{
  programImage: ImageUnion | undefined
  context: Extract<EnrollmentRuleContext, { type: 'program' }>
  canCreateEnrollmentRule: boolean
  addRule: () => void
}> = ({ canCreateEnrollmentRule, addRule, context, programImage }) => {
  const { t } = useTranslation()

  const { rules, filterOutRule } = useEnrollmentRulesByContext(context)
  const { domainReps } = useEnrollmentRuleDomainReps()

  return (
    <Wrapper grow>
      <HeadlineWrapper>
        <Headline>{t('manage.programs.enrollment-rule.table-headline')}</Headline>
        <Bubble>{rules.length}</Bubble>
      </HeadlineWrapper>
      {domainReps !== undefined && (
        <>
          {rules.map(rule => (
            <EnrollmentRule
              key={rule.id}
              programId={ProgramId.parse(context.id)}
              programImage={programImage}
              rule={rule}
              domainReps={domainReps}
              onDelete={() => {
                filterOutRule(rule.id)
              }}
              readonly={!canCreateEnrollmentRule}
            />
          ))}
          {canCreateEnrollmentRule && <AddEnrollmentRule onClick={addRule} />}
        </>
      )}
    </Wrapper>
  )
}
