import { useAtomValue } from 'jotai'
import { useCallback, useEffect, useState } from 'react'
import { isChromium, isDesktop, isMacOs } from 'react-device-detect'
import { useNotif } from 'sierra-client/components/common/notifications'
import { useLiveContext } from 'sierra-client/components/liveV2/live-context'
import { useDispatch, useSelector } from 'sierra-client/state/hooks'
import { disableVideo, enableVideo, muteAudio, unmuteAudio } from 'sierra-client/state/live/actions'
import { selectAudioState, selectCallIsActive, selectVideoState } from 'sierra-client/state/live/selectors'
import { pushToTalkEnabledAtom } from 'sierra-client/state/live/settings'

const liveKeyboardShortcuts = {
  macOS: {
    audio: '⌘ + D',
    video: '⌘ + E',
    pushToTalk: '⌥ + Space',
  },
  windows: {
    audio: 'Ctrl + D',
    video: 'Ctrl + E',
    pushToTalk: 'Ctrl + Space',
  },
} as const

export const getLiveAudioShortcutHint = (): string => {
  if (isMacOs || isChromium) {
    return liveKeyboardShortcuts.macOS.audio
  }
  return liveKeyboardShortcuts.windows.audio
}

export const getLiveVideoShortcutHint = (): string => {
  if (isMacOs || isChromium) {
    return liveKeyboardShortcuts.macOS.video
  }
  return liveKeyboardShortcuts.windows.video
}

export const getLivePushToTalkShortcutHint = (): string => {
  if (isMacOs || isChromium) {
    return liveKeyboardShortcuts.macOS.pushToTalk
  }
  return liveKeyboardShortcuts.windows.pushToTalk
}

const useKeyboardShortcut = (key: string, callback: () => void): void => {
  const callIsActive = useSelector(selectCallIsActive)
  const { left } = useLiveContext()
  const supportsLiveKeyboardShortcuts = isDesktop

  const onKeyDown = useCallback(
    (ev: KeyboardEvent): void => {
      if (left || !callIsActive) return
      if (isMacOs || isChromium) {
        if (ev.metaKey && ev.key === key) {
          ev.preventDefault()
          callback()
        }
      } else {
        if (ev.ctrlKey && ev.key === key) {
          ev.preventDefault()
          callback()
        }
      }
    },
    [callIsActive, callback, key, left]
  )

  useEffect(() => {
    if (supportsLiveKeyboardShortcuts) {
      document.addEventListener('keydown', onKeyDown)
    }
    return () => {
      if (supportsLiveKeyboardShortcuts) {
        document.removeEventListener('keydown', onKeyDown)
      }
    }
  }, [supportsLiveKeyboardShortcuts, onKeyDown])
}

const usePushToTalk = (onPushToTalkStarted: () => void, onPushToTalkEnded: () => void): void => {
  const callIsActive = useSelector(selectCallIsActive)
  const audioState = useSelector(selectAudioState)
  const [startedPushToTalk, setStartedPushToTalk] = useState(false)
  const { left } = useLiveContext()
  const isEnabledByUser = useAtomValue(pushToTalkEnabledAtom)

  const onKeyDown = useCallback(
    (ev: KeyboardEvent): void => {
      if (left || !callIsActive || audioState === 'on') return
      if (isMacOs || isChromium) {
        if (ev.altKey && ev.code === 'Space' && !startedPushToTalk) {
          ev.preventDefault()
          setStartedPushToTalk(true)
          onPushToTalkStarted()
        }
      } else {
        if (ev.ctrlKey && ev.code === 'Space' && !startedPushToTalk) {
          ev.preventDefault()
          setStartedPushToTalk(true)
          onPushToTalkStarted()
        }
      }
    },
    [audioState, callIsActive, left, onPushToTalkStarted, startedPushToTalk]
  )

  const onKeyUp = useCallback(
    (ev: KeyboardEvent): void => {
      if (left || !callIsActive || audioState !== 'on') return
      if (isMacOs || isChromium) {
        if (startedPushToTalk) {
          ev.preventDefault()
          setStartedPushToTalk(false)
          onPushToTalkEnded()
        }
      } else {
        if (startedPushToTalk) {
          ev.preventDefault()
          setStartedPushToTalk(false)
          onPushToTalkEnded()
        }
      }
    },
    [audioState, callIsActive, left, onPushToTalkEnded, startedPushToTalk]
  )

  useEffect(() => {
    if (isEnabledByUser) {
      document.addEventListener('keydown', onKeyDown)
      document.addEventListener('keyup', onKeyUp)
    }
    return () => {
      document.removeEventListener('keydown', onKeyDown)
      document.removeEventListener('keyup', onKeyUp)
    }
  }, [onKeyDown, onKeyUp, isEnabledByUser])
}

export const useLiveKeyboardShortcuts = (): void => {
  const dispatch = useDispatch()
  const notif = useNotif()
  const audioState = useSelector(selectAudioState)
  const videoState = useSelector(selectVideoState)

  const toggleAudioMuted = useCallback(async () => {
    if (audioState === 'on') {
      await dispatch(muteAudio())
      notif.push({ type: 'custom', level: 'info', body: 'Microphone off', icon: 'microphone--off' })
    } else {
      await dispatch(unmuteAudio())
      notif.push({ type: 'custom', level: 'info', body: 'Microphone on', icon: 'microphone' })
    }
  }, [audioState, dispatch, notif])

  const toggleVideo = useCallback(() => {
    if (videoState === 'on') {
      void dispatch(disableVideo())
    } else {
      void dispatch(enableVideo())
    }
  }, [dispatch, videoState])

  useKeyboardShortcut(
    'd',
    useCallback(() => toggleAudioMuted(), [toggleAudioMuted])
  )
  useKeyboardShortcut(
    'e',
    useCallback(() => toggleVideo(), [toggleVideo])
  )
  usePushToTalk(
    useCallback(() => {
      if (audioState !== 'on') {
        void dispatch(unmuteAudio())
      }
    }, [audioState, dispatch]),
    useCallback(() => {
      if (audioState !== 'muted') {
        void dispatch(muteAudio())
      }
    }, [audioState, dispatch])
  )
}
