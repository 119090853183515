import React from 'react'
import { RouterLink } from 'sierra-client/components/common/link'
import { getFlag } from 'sierra-client/config/global-config'
import { useTranslation } from 'sierra-client/hooks/use-translation'
import { useGetReportDescription, useGetReportTitle } from 'sierra-client/views/manage/reports/report-utils'
import { useReports } from 'sierra-client/views/manage/reports/use-reports'
import { TruncatedTextWithTooltip } from 'sierra-ui/components'
import { Button, LoadingSpinner, Text, View } from 'sierra-ui/primitives'
import { token } from 'sierra-ui/theming'
import styled from 'styled-components'

const AllReportsView = styled(View)`
  border-top: 1px solid ${token('border/default')};
  text-align: center;
`

const RightAlignedView = styled(View)`
  position: absolute;
  right: 0rem;
`

const BoxShadowView = styled(View).attrs({
  direction: 'column',
  padding: '24',
  radius: 'size-16',
})`
  box-shadow: 0px 2px 4px 0px #00000014;
  outline: 1px solid ${p => p.theme.tokens['border/default']};
`

export const LegacyReports: React.FC = () => {
  const { t } = useTranslation()
  const { reports, isLoading } = useReports()
  const getReportTitle = useGetReportTitle()
  const getReportDescription = useGetReportDescription()
  const isLegacyReportsOnHomeEnabled = getFlag('insights/home-reports')

  if (!isLegacyReportsOnHomeEnabled) return null

  return (
    <View grow>
      <View direction='column' gap='12' grow>
        <Text size='small' bold color={'foreground/muted'}>
          {t('manage.insights.home.reports-header')}
        </Text>
        <BoxShadowView
          direction='column'
          borderColor={'border/default'}
          padding='24'
          radius='size-16'
          gap='24'
        >
          {isLoading ? (
            <LoadingSpinner />
          ) : (
            <View direction='column' gap='24'>
              {reports.slice(0, 5).map(report => (
                <View
                  key={report.id}
                  animated
                  position='relative'
                  whileHover={'show-rest'}
                  initial='hide'
                  animate='hide'
                  variants={{ 'hide': {}, 'show-rest': {} }}
                >
                  <View direction='column' gap='2' grow alignItems='flex-start'>
                    <TruncatedTextWithTooltip size='small' bold>
                      {getReportTitle(report)}
                    </TruncatedTextWithTooltip>

                    <TruncatedTextWithTooltip size='small'>
                      {getReportDescription(report)}
                    </TruncatedTextWithTooltip>
                  </View>
                  <RightAlignedView
                    variants={{ 'hide': { opacity: 0 }, 'show-rest': { opacity: 1 } }}
                    animated
                    background={'surface/default'}
                  >
                    <RouterLink href={`/manage/insights/dashboards/templates/${report.id}`}>
                      <Button variant='secondary'>{t('dictionary.view')}</Button>
                    </RouterLink>
                  </RightAlignedView>
                </View>
              ))}
            </View>
          )}
          <AllReportsView paddingTop='16' justifyContent='center'>
            <RouterLink href={`/manage/reports`}>
              <Text size='small' bold color='foreground/muted'>
                {t('manage.insights.home.reports-view-all')}
              </Text>
            </RouterLink>
          </AllReportsView>
        </BoxShadowView>
      </View>
    </View>
  )
}
