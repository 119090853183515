import { motion } from 'framer-motion'
import { useSetAtom } from 'jotai'
import { FC, useEffect, useMemo, useState } from 'react'
import { useCurrentLiveSessionQuery } from 'sierra-client/api/hooks/use-live-session'
import { ApplicationNotifications, useNotif } from 'sierra-client/components/common/notifications'
import { PageTitle } from 'sierra-client/components/common/page-title'
import { Lobby } from 'sierra-client/components/liveV2/cards/lobby'
import {
  LiveSessionContextProvider,
  useLiveSessionContext,
} from 'sierra-client/components/liveV2/contexts/live-session-data'
import { Header } from 'sierra-client/components/liveV2/header'
import { useCurrentMode } from 'sierra-client/components/liveV2/hooks/use-current-mode'
import { useSelectCurrentCard } from 'sierra-client/components/liveV2/hooks/use-select-current-card'
import { LiveLayerPageWrapper } from 'sierra-client/components/liveV2/live-layer-page-wrapper'
import { ScreenshareModal } from 'sierra-client/components/liveV2/live-layer/screenshare-modal'
import { MobileVideoAvatarBar } from 'sierra-client/components/liveV2/live-layer/video-avatar-bar/mobile-video-avatar-bar'
import { useRightSidebarContext } from 'sierra-client/components/liveV2/live-sidebar-provider'
import { FlexibleContentProgressBar } from 'sierra-client/components/liveV2/progress-bar'
import { RecordingPreview } from 'sierra-client/components/liveV2/recording/recording-preview'
import {
  CombinedRightSidebar,
  RightSidebarContent,
  useShowVideoAvatarBar,
} from 'sierra-client/components/liveV2/right-sidebar/combined-sidebar'
import {
  CardScrollPositionTracker,
  FollowSharedScrollPosition,
} from 'sierra-client/components/liveV2/scroll-position-tracker'
import { ShortcutMenu } from 'sierra-client/components/shortcut-menu'
import { config } from 'sierra-client/config/global-config'
import { Logging } from 'sierra-client/core/logging'
import {
  ErrorBoundaryFallbackComponent,
  StrategicErrorBoundary,
} from 'sierra-client/error/strategic-error-boundary'
import { sidebarVersionAtom } from 'sierra-client/features/collapsable-sidebar'
import { LiveCardRenderer, SanaNowPage, SyncBackendAwarenssWithRedux } from 'sierra-client/features/sana-now'
import { useIsIdle } from 'sierra-client/hooks/use-is-idle'
import { getMaxParticipantsInLive } from 'sierra-client/hooks/use-max-participants-in-live'
import { logger } from 'sierra-client/logger/logger'
import { accessDeniedRedirect, notFound404Redirect } from 'sierra-client/router/navigation'
import { useIsMobile, useIsTablet } from 'sierra-client/state/browser/selectors'
import {
  selectFlexibleContent,
  selectFlexibleContentFolder,
} from 'sierra-client/state/flexible-content/selectors'
import { useDispatch, useSelector } from 'sierra-client/state/hooks'
import {
  selectAllSessionParticipants,
  selectCurrentCardId,
  selectIsFacilitator,
  selectIsSomeoneScreenSharing,
} from 'sierra-client/state/live-session/selectors'
import { FCC } from 'sierra-client/types'
import { CreateCardErrorForLearner } from 'sierra-client/views/flexible-content/create-card-error'
import { PolarisCardTheme } from 'sierra-client/views/flexible-content/polaris-card-theme'
import { SetCardProgressProvider } from 'sierra-client/views/flexible-content/progress-tracking/set-progress-provider'
import { UnsupportedFileType } from 'sierra-client/views/flexible-content/unsupported-file-type'
import { hideGlobalAssistantAtom } from 'sierra-client/views/global-assistant/atoms'
import {
  LiveSessionGlobalSidebar,
  SetGlobalSidebarContentReference,
} from 'sierra-client/views/liveV2/live-session-global-sidebar'
import { LiveCardCanvasPadding } from 'sierra-client/views/liveV2/participant-navigation-context'
import { WithContentRef } from 'sierra-client/views/liveV2/with-content-ref'
import { CardCanvas } from 'sierra-client/views/shared/card-canvas'
import { CreateContentId, LiveContentId, LiveSessionId } from 'sierra-domain/api/nano-id'
import { AssetContext } from 'sierra-domain/asset-context'
import { ScopedLiveContentId, ScopedLiveSessionId } from 'sierra-domain/collaboration/types'
import { RequestError } from 'sierra-domain/error'
import { isLiveFile } from 'sierra-domain/flexible-content/support'
import { File } from 'sierra-domain/flexible-content/types'
import { Column, ColumnContainer, Icon, Layout, Panel } from 'sierra-ui/components'
import { Button, LoadingSpinner, Text, View, ViewProps } from 'sierra-ui/primitives'
import { token } from 'sierra-ui/theming'
import { v2_breakpoint } from 'sierra-ui/theming/breakpoints'
import { getTheme, useCardTheme } from 'sierra-ui/theming/legacy-theme'
import { unit, useOnChanged, useWindowSize } from 'sierra-ui/utils'
import { maxWidth } from 'sierra-ui/utils/media-query-styles'
import styled, { ThemeProvider, css } from 'styled-components'

const PolarisCardRenderer = ({
  flexibleContentId,
  liveSessionId,
}: {
  flexibleContentId: LiveContentId
  liveSessionId: LiveSessionId
}): JSX.Element => {
  const scopedCreateContentId = ScopedLiveContentId.fromId(flexibleContentId)
  const flexibleContentFolder = useSelector(state =>
    selectFlexibleContentFolder(state, flexibleContentId, 'folder:root')
  )
  const dispatch = useDispatch()
  const currentCard = useSelectCurrentCard()

  useOnChanged(() => {
    if (currentCard !== undefined) {
      void dispatch(
        Logging.liveSession.cardViewed({
          cardType: currentCard.data.type,
        })
      )
    }
  }, [currentCard?.id])

  if (flexibleContentFolder === undefined || currentCard === undefined) {
    return <>Loading…</>
  }

  if (!isLiveFile(currentCard)) {
    return <UnsupportedFileType mode='live' file={currentCard} flexibleContentId={flexibleContentId} />
  }

  return (
    <SetCardProgressProvider courseId={flexibleContentId} file={currentCard}>
      <LiveCardRenderer
        liveSessionId={liveSessionId}
        scopedCreateContentId={scopedCreateContentId}
        file={currentCard}
      />
    </SetCardProgressProvider>
  )
}

const PolarisOuterContainer = styled(Layout)`
  display: flex;
  flex-direction: column;

  ${maxWidth.phone} {
    flex: 1;
  }

  background-color: ${token('surface/default')};
`

const PolarisContainer = styled.div`
  display: flex;
  flex-direction: column;
  height: 100%;
  gap: 8px;
  overflow: hidden;
  position: relative;
`

const _LiveColumnContainer = styled(ColumnContainer)<{ $marginTop?: number }>`
  flex-wrap: wrap;
  display: flex;
  position: relative;
  margin-top: ${p => (p.$marginTop !== undefined ? `${p.$marginTop}px` : 0)};
`

const LiveColumnContainer: FCC<ViewProps & { topContainerRef: HTMLDivElement | null }> = ({
  children,
  topContainerRef,
  ...props
}) => {
  const [topContainerHeight, setTopContainerHeight] = useState<number | undefined>(undefined)

  const isMobile = useIsMobile()

  useEffect(() => {
    if (!isMobile) return
    if (topContainerRef === null) return

    const calculateTopBarHeight = (): void => {
      const rect = topContainerRef.getBoundingClientRect()
      setTopContainerHeight(rect.height)
    }

    const resizeObserver = new ResizeObserver(calculateTopBarHeight)
    resizeObserver.observe(topContainerRef)

    return () => {
      resizeObserver.unobserve(topContainerRef)
    }
  }, [isMobile, topContainerRef])

  return (
    <_LiveColumnContainer {...props} $marginTop={topContainerHeight}>
      {children}
    </_LiveColumnContainer>
  )
}

const CardColumn = styled(Column)<{ $hideBorder: boolean }>`
  flex-basis: 1px;

  ${p =>
    !p.$hideBorder &&
    css`
      box-shadow: 0px 4px 12px rgba(0, 0, 0, 0.04);
      border: 1px solid ${token('border/default')};
    `}
`

const LiveCardCanvas = styled(CardCanvas)<LiveCardCanvasPadding>`
  flex-direction: column;
  overflow: auto;

  & > * {
    /* The faciliators are a child of the card container but should not have a minimum height */
    min-height: 100%;
    height: auto;
  }

  ${p =>
    p.paddingRight !== undefined &&
    css`
      padding-right: ${unit(p.paddingRight)};
    `}

  ${p =>
    p.paddingBottom !== undefined &&
    p.paddingBottom !== 0 &&
    css`
      /* Needs a pseudo element as padding would be ignored in overflow: auto */

      &::after {
        display: block;
        content: '';
        padding-bottom: ${unit(p.paddingBottom)};
      }
    `} @media screen and (
    max-width: ${v2_breakpoint.phone}) {
    border-radius: 10px;
  }
`

const LobbyTheme: FCC = ({ children }) => {
  const card = useCardTheme()
  const theme = useMemo(() => getTheme(card, 'black'), [card])
  return <ThemeProvider theme={theme}>{children}</ThemeProvider>
}

export const Theme: FCC<{ currentCard: File; flexibleContentId: CreateContentId }> = ({
  children,
  currentCard,
  flexibleContentId,
}) => {
  const currentMode = useCurrentMode(flexibleContentId)

  return currentMode === 'card' || currentMode === 'live-lobby' ? (
    <PolarisCardTheme {...currentCard}>{children}</PolarisCardTheme>
  ) : (
    <LobbyTheme>{children}</LobbyTheme>
  )
}

const PolarisProgressBar = (): JSX.Element | null => {
  const liveSession = useLiveSessionContext()
  const contentId = liveSession.data.flexibleContentId

  const flexibleContent = useSelector(state => selectFlexibleContent(state, contentId))
  const currentCardId = useSelectCurrentCard()?.id

  if (flexibleContent === undefined || currentCardId === undefined) return null
  return (
    <FlexibleContentProgressBar mode='live' flexibleContent={flexibleContent} currentFileId={currentCardId} />
  )
}

const MaxParticipantsReachedMonitor = (): null => {
  const isFacilitator = useSelector(selectIsFacilitator)
  const liveSessionParticipants = useSelector(selectAllSessionParticipants)
  const numberOfParticipants = liveSessionParticipants.length + 1
  const notification = useNotif()
  const maxParticipants = getMaxParticipantsInLive()

  useEffect(() => {
    if (isFacilitator && numberOfParticipants >= maxParticipants) {
      // @TODO add translations?
      notification.push({
        type: 'custom',
        level: 'info',
        title: 'You have reached the maximum number of participants',
        body: `(${numberOfParticipants}/${maxParticipants})`,
      })
    }
  }, [notification, isFacilitator, numberOfParticipants, maxParticipants])

  return null
}

const TabletAndMobileVideoAvatarBar = ({
  flexibleContentId,
}: {
  flexibleContentId: CreateContentId
}): JSX.Element | null => {
  const showVideoAvatarBar = useShowVideoAvatarBar(flexibleContentId)
  const isMobileOrTablet = useIsTablet()

  if (showVideoAvatarBar && isMobileOrTablet) return <MobileVideoAvatarBar />

  return null
}

const _ProgressBarContainer = styled(motion.div)`
  padding: 0.5rem 1rem;
  width: 100%;
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  margin: 0 auto;
  z-index: 1;
  will-change: opacity, scale;
  height: 120px;
  border-radius: 10px 10px 0 0;

  /* This is needed to prevent the progress bar from being clickable and thus preventing the user from clicking on the behind it */
  pointer-events: none;

  @media screen and (max-width: ${v2_breakpoint.phone}) {
    padding: 1.5rem;
  }
`

const ProgressBarContainer: FCC = ({ children }) => {
  const isIdle = useIsIdle()
  return (
    <_ProgressBarContainer
      initial={{ opacity: 1 }}
      animate={{ opacity: isIdle ? 0 : 1 }}
      exit={{ opacity: 0 }}
      transition={{ ease: [0.25, 0.1, 0.25, 1], duration: 0.35 }}
      whileHover={{ opacity: 1 }}
    >
      {children}
    </_ProgressBarContainer>
  )
}

const ReenablePointerEvents = styled.div`
  pointer-events: auto;
`

const FollowMeLiveCardCanvas: FCC<{ currentCard: File; assetContext: AssetContext }> = ({
  children,
  currentCard,
  assetContext,
}) => {
  const [scrollContainer, setScrollContainer] = useState<HTMLDivElement | null>(null)
  return (
    <>
      <FollowSharedScrollPosition currentCard={currentCard} scrollContainerRef={scrollContainer} />
      <CardScrollPositionTracker currentCard={currentCard} scrollContainerRef={scrollContainer} />
      <LiveCardCanvas
        card={currentCard}
        key={currentCard.id}
        ref={setScrollContainer}
        assetContext={assetContext}
      >
        {children}
      </LiveCardCanvas>
    </>
  )
}

const MainCardContentColumnContent: FC<{
  flexibleContentId: LiveContentId
  assetContext: AssetContext
  liveSessionId: LiveSessionId
}> = ({ flexibleContentId, assetContext, liveSessionId }): JSX.Element => {
  const isSomeoneScreenSharing = useSelector(selectIsSomeoneScreenSharing)
  const currentMode = useCurrentMode(flexibleContentId)
  const currentCard = useSelectCurrentCard()

  if (isSomeoneScreenSharing) return <ScreenshareModal />
  if (!currentCard || currentMode === 'breakout-joined' || currentMode === 'breakout-started')
    return <Lobby flexibleContentId={flexibleContentId} />

  return (
    <PolarisCardTheme {...currentCard}>
      <StrategicErrorBoundary id='live-cards' strategies={['remount']} Fallback={CreateCardErrorForLearner}>
        <FollowMeLiveCardCanvas currentCard={currentCard} assetContext={assetContext}>
          <PolarisCardRenderer
            key='PolarisCardRenderer'
            flexibleContentId={flexibleContentId}
            liveSessionId={liveSessionId}
          />
        </FollowMeLiveCardCanvas>
      </StrategicErrorBoundary>
    </PolarisCardTheme>
  )
}

const MainCardContentColumn: FC<{
  flexibleContentId: LiveContentId
  assetContext: AssetContext
  liveSessionId: LiveSessionId
}> = ({ flexibleContentId, assetContext, liveSessionId }): JSX.Element => {
  const currentMode = useCurrentMode(flexibleContentId)

  return (
    <CardColumn disableScrollbarGutter borderRadius={'10px'} $hideBorder={currentMode === 'live-lobby'}>
      <RecordingPreview />
      <ProgressBarContainer>
        <ReenablePointerEvents>
          <PolarisProgressBar />
        </ReenablePointerEvents>
      </ProgressBarContainer>
      <MainCardContentColumnContent
        liveSessionId={liveSessionId}
        flexibleContentId={flexibleContentId}
        assetContext={assetContext}
      />
    </CardColumn>
  )
}

const PolarisUIContainer = styled(View)<{ $height?: number }>`
  pointer-events: none;
  position: fixed;
  top: 0;
  left: 0;
  z-index: 1;
  width: 100%;
  transition: height 300ms cubic-bezier(0.25, 0.1, 0.25, 1);
  height: ${p => (p.$height !== undefined ? `${p.$height}px` : '100dvh')};
  display: grid;
  grid-template-rows: auto 1fr auto;
  grid-template-columns: 1fr;
  grid-template-areas:
    'top'
    'content'
    'bottom';
`

const TopUIContainer = styled(View)`
  pointer-events: all;
  grid-area: top;
  overflow: hidden;
  padding-bottom: 1rem;

  @media (prefers-color-scheme: dark) {
    background: linear-gradient(
      180deg,
      #000000 0%,
      #000000 6.25%,
      rgba(0, 0, 0, 0.992188) 12.5%,
      rgba(0, 0, 0, 0.973633) 18.75%,
      rgba(0, 0, 0, 0.9375) 25%,
      rgba(0, 0, 0, 0.87793) 31.25%,
      rgba(0, 0, 0, 0.789062) 37.5%,
      rgba(0, 0, 0, 0.665039) 43.75%,
      rgba(0, 0, 0, 0.5) 50%,
      rgba(0, 0, 0, 0.334961) 56.25%,
      rgba(0, 0, 0, 0.210938) 62.5%,
      rgba(0, 0, 0, 0.12207) 68.75%,
      rgba(0, 0, 0, 0.0625) 75%,
      rgba(0, 0, 0, 0.0263672) 81.25%,
      rgba(0, 0, 0, 0.0078125) 87.5%,
      rgba(0, 0, 0, 0.000976562) 93.75%,
      rgba(0, 0, 0, 0) 100%
    );
  }

  @media (prefers-color-scheme: light) {
    background: linear-gradient(
      180deg,
      #fcfcfc 0%,
      #fcfcfc 6.25%,
      rgba(252, 252, 252, 0.992188) 12.5%,
      rgba(252, 252, 252, 0.973633) 18.75%,
      rgba(252, 252, 252, 0.9375) 25%,
      rgba(252, 252, 252, 0.87793) 31.25%,
      rgba(252, 252, 252, 0.789062) 37.5%,
      rgba(252, 252, 252, 0.665039) 43.75%,
      rgba(252, 252, 252, 0.5) 50%,
      rgba(252, 252, 252, 0.334961) 56.25%,
      rgba(252, 252, 252, 0.210938) 62.5%,
      rgba(252, 252, 252, 0.12207) 68.75%,
      rgba(252, 252, 252, 0.0625) 75%,
      rgba(252, 252, 252, 0.0263672) 81.25%,
      rgba(252, 252, 252, 0.0078125) 87.5%,
      rgba(252, 252, 252, 0.000976562) 93.75%,
      rgba(252, 252, 252, 0) 100%
    );
  }
`

const Polaris = ({
  flexibleContentId,
  liveSessionId,
}: {
  flexibleContentId: LiveContentId
  liveSessionId: LiveSessionId
}): JSX.Element => {
  const rightSidebarContext = useRightSidebarContext()

  const isMobile = useIsMobile()

  const { height: windowHeight } = useWindowSize()

  const [topContainerRef, setTopContainerRef] = useState<HTMLDivElement | null>(null)

  const assetContext = useMemo(
    () => ({ type: 'course' as const, courseId: flexibleContentId }),
    [flexibleContentId]
  )

  if (isMobile) {
    return (
      <PolarisOuterContainer height='auto'>
        <PolarisUIContainer gap='none' $height={windowHeight}>
          <TopUIContainer direction='column' ref={setTopContainerRef}>
            <Header assetContext={assetContext} />
            <TabletAndMobileVideoAvatarBar flexibleContentId={flexibleContentId} />
          </TopUIContainer>
        </PolarisUIContainer>
        <PolarisContainer>
          <LiveColumnContainer
            gap='xxsmall'
            margin='none'
            padding='xxsmall'
            topContainerRef={topContainerRef}
          >
            <ProgressBarContainer>
              <PolarisProgressBar />
            </ProgressBarContainer>
            <MainCardContentColumnContent
              flexibleContentId={flexibleContentId}
              assetContext={assetContext}
              liveSessionId={liveSessionId}
            />
          </LiveColumnContainer>
          <Panel
            animation='slide-right'
            open={rightSidebarContext.isOpen}
            onClose={rightSidebarContext.close}
          >
            <RightSidebarContent showClose />
          </Panel>
        </PolarisContainer>
        <MaxParticipantsReachedMonitor />
      </PolarisOuterContainer>
    )
  }

  return (
    <PolarisOuterContainer>
      <Header assetContext={assetContext} />
      <PolarisContainer>
        <TabletAndMobileVideoAvatarBar flexibleContentId={flexibleContentId} />
        <LiveColumnContainer
          gap='xxsmall'
          margin='none'
          padding='xxsmall'
          paddingTop='none'
          topContainerRef={topContainerRef}
        >
          <MainCardContentColumn
            liveSessionId={liveSessionId}
            flexibleContentId={flexibleContentId}
            assetContext={assetContext}
          />
          <CombinedRightSidebar flexibleContentId={flexibleContentId} />
        </LiveColumnContainer>
      </PolarisContainer>

      <MaxParticipantsReachedMonitor />
    </PolarisOuterContainer>
  )
}

const LoadingContainer = styled.div`
  background-color: ${token('surface/default')};
  display: flex;
  justify-content: center;
  align-items: center;
  flex: 1;
`

const Loading = (): JSX.Element => (
  <LoadingContainer>
    <LoadingSpinner color='white' size='large' />
  </LoadingContainer>
)

const Centered = styled(View).attrs({ direction: 'column', alignItems: 'center', gap: 'small' })`
  max-width: 30rem;
`

const Container = styled(View).attrs({
  alignItems: 'center',
  justifyContent: 'center',
  grow: true,
  background: 'surface/soft',
})`
  height: 100%;
`

const ErrorFallback: ErrorBoundaryFallbackComponent = ({ remountSubtree }) => {
  return (
    <Container>
      <Centered>
        <Icon iconId='warning' size='size-24' color='foreground/muted' />

        <View direction='column'>
          <Text align='center' size='large' bold>
            Your session crashed unexpectedly
          </Text>
          <Text align='center' size='small' color='foreground/muted'>
            Something happened on our end and the Sana team has been notified of the issue. Your session data
            up until now is safe and sound.
          </Text>
          <Text align='center' size='small' color='foreground/muted'>
            Please rejoin the session or contact support if this happens again.
          </Text>
        </View>

        <Button onClick={remountSubtree} icon='reset'>
          Rejoin session
        </Button>
      </Centered>
    </Container>
  )
}

const IDLE_TIMEOUT = 60 * 60 * 1000 // 1 hour

export const LivePage = ({ liveSessionId }: { liveSessionId: LiveSessionId }): JSX.Element => {
  const queryResult = useCurrentLiveSessionQuery()
  const setSidebarVersion = useSetAtom(sidebarVersionAtom)
  const setHideGlobalAssistant = useSetAtom(hideGlobalAssistantAtom)

  useEffect(() => {
    setHideGlobalAssistant(true)
    return () => setHideGlobalAssistant(false)
  }, [setHideGlobalAssistant])

  useEffect(() => {
    if (queryResult.isError) {
      if (queryResult.error instanceof RequestError) {
        if (queryResult.error.status === 404) {
          void notFound404Redirect()
        }
        if (RequestError.isAccessError(queryResult.error)) {
          void accessDeniedRedirect()
        }
      } else if (!queryResult.isRefetchError) {
        throw queryResult.error
      } else {
        logger.error('failed to fetch live session data', { error: queryResult.error })
      }
    }
  }, [queryResult.isError, queryResult.error, liveSessionId, queryResult.isRefetchError])

  // Reset sidebar to new sidebar once we leave now / live
  useEffect(
    () => () => {
      setSidebarVersion('new-sidebar')
    },
    [setSidebarVersion]
  )

  if (!queryResult.isSuccess && !queryResult.data) {
    return <Loading />
  }

  const liveSession = queryResult.data
  const flexibleContentId = LiveContentId.parse(liveSession.data.flexibleContentId)
  const scopedLiveSessionId = ScopedLiveSessionId.fromId(liveSessionId)
  const isSanaNow = liveSession.data.videoCallSetting.type !== 'sana'

  return (
    <>
      <StrategicErrorBoundary id='live' strategies={[]} Fallback={ErrorFallback}>
        <LiveSessionContextProvider value={liveSession}>
          <PageTitle title={liveSession.data.title} />
          <WithContentRef
            flexibleContentId={flexibleContentId}
            liveSessionId={scopedLiveSessionId}
            render={props =>
              isSanaNow ? (
                <>
                  <ApplicationNotifications />
                  <SanaNowPage flexibleContentId={flexibleContentId} />
                </>
              ) : (
                <>
                  <LiveLayerPageWrapper sessionId={props.liveSessionId}>
                    <Polaris
                      flexibleContentId={props.flexibleContentId}
                      liveSessionId={liveSession.liveSessionId}
                    />
                    <LiveSessionGlobalSidebar />
                  </LiveLayerPageWrapper>
                  <SyncBackendAwarenssWithRedux idleTimeout={IDLE_TIMEOUT} liveSessionId={liveSessionId} />
                </>
              )
            }
          />
          <SetGlobalSidebarContentReference />
        </LiveSessionContextProvider>
      </StrategicErrorBoundary>
      <ShortcutMenu.Action
        group='live'
        label='shortcut-menu.default.go-to-recap'
        run={({ store }) => {
          const { location } = window
          const cardId = selectCurrentCardId(store.getState())
          const id = liveSession.liveSessionId
          const url = new URL(`${location.origin}/r/${id}/${cardId !== undefined ? cardId : ''}`)

          const host = config.auth.host
          if (host !== undefined) url.searchParams.set('x-host', host)

          window.open(url, '_blank')
        }}
        iconId='arrow--right'
        permission='ACCESS_MANAGE'
      />

      <ShortcutMenu.Action
        group='live'
        label='shortcut-menu.default.edit-in-create'
        run={({ store }) => {
          const cardId = selectCurrentCardId(store.getState())
          const id = liveSession.data.flexibleContentId
          const url = new URL(`${location.origin}/create/l/${id}/${cardId !== undefined ? cardId : ''}`)

          const host = config.auth.host
          if (host !== undefined) url.searchParams.set('x-host', host)

          window.open(url, '_blank')
        }}
        iconId='play--circle--filled'
        permission='ACCESS_EDITOR'
      />
    </>
  )
}
