import * as Separator from '@radix-ui/react-separator'
import React, { FC } from 'react'
import { NotificationCard } from 'sierra-client/features/activity-center/notification-card'
import { HomeNotification } from 'sierra-domain/api/learn'
import { View } from 'sierra-ui/primitives'
import { token } from 'sierra-ui/theming'
import styled from 'styled-components'

const StyledSeparator = styled(Separator.Root)`
  background-color: ${token('border/default')};

  &[data-orientation='horizontal'] {
    height: 1px;
    width: 100%;
  }

  &[data-orientation='vertical'] {
    height: 100%;
    width: 1px;
  }
`

const SeparatorPadding = styled.div`
  padding: 0 20px;
`

export const NotificationList: FC<{ notifications: HomeNotification[] }> = ({ notifications }) => {
  return (
    <View direction='column' gap='4'>
      {notifications.map((notification, i) => (
        <React.Fragment key={notification.id}>
          {i > 0 && (
            <SeparatorPadding>
              <StyledSeparator decorative orientation='horizontal' />
            </SeparatorPadding>
          )}
          <NotificationCard notification={notification} />
        </React.Fragment>
      ))}
    </View>
  )
}
