import { useAtom, useSetAtom } from 'jotai'
import { useCallback, useEffect } from 'react'
import {
  addContentStepPanelOpenAtom,
  editOutlinePanelOpenAtom,
  emailNotificationOpenAtom,
  emailTemplateIdAtom,
  isSavingOutlineAtom,
} from 'sierra-client/views/manage/programs/staggered-assignments/atoms'
import { useEmailTemplate } from 'sierra-client/views/manage/programs/staggered-assignments/hooks/use-email-template'
import { useScrollHighlight } from 'sierra-client/views/manage/programs/staggered-assignments/hooks/use-scroll-highlight'

const panelTimeout = 250

export type OutlineEditPanels = {
  panels: { editOutlineOpen: boolean; addContentOpen: boolean; addEmailNotificationOpen: boolean }
  setPanels: {
    editOutline: { on: () => void; off: () => void }
    addContent: { on: () => void; off: () => void }
    addEmailNotification: { on: (id?: string) => void; off: () => void }
  }
  isSavingOutline: boolean
  setIsSavingOutline: (isSaving: boolean) => void
}

export const useOutlineEditPanels = (): OutlineEditPanels => {
  const [editOutlineOpen, setEditOutlineOpen] = useAtom(editOutlinePanelOpenAtom)
  const [addEmailNotificationOpen, setEmailNotificationOpen] = useAtom(emailNotificationOpenAtom)
  const setAddEmailTemplateId = useSetAtom(emailTemplateIdAtom)
  const [addContentOpen, setAddContentOpen] = useAtom(addContentStepPanelOpenAtom)
  const [isSavingOutline, setIsSavingOutline] = useAtom(isSavingOutlineAtom)
  const { resetHighlight } = useScrollHighlight({ stepId: undefined })
  const { resetTemplate } = useEmailTemplate()

  const editOutlineOn = useCallback(() => setEditOutlineOpen(true), [setEditOutlineOpen])
  const editOutlineOff = useCallback(() => setEditOutlineOpen(false), [setEditOutlineOpen])

  const addContentOn = useCallback(() => {
    setTimeout(() => setAddContentOpen(true), panelTimeout)
  }, [setAddContentOpen])
  const addContentOff = useCallback(() => setAddContentOpen(false), [setAddContentOpen])

  const addEmailNotificationOn: OutlineEditPanels['setPanels']['addEmailNotification']['on'] = useCallback(
    id => {
      setTimeout(() => {
        setEmailNotificationOpen(true)
        setAddEmailTemplateId(id)
      }, panelTimeout)
    },
    [setEmailNotificationOpen, setAddEmailTemplateId]
  )
  const addEmailNotificationOff = useCallback(() => {
    resetTemplate()
    setAddEmailTemplateId(undefined)
    setEmailNotificationOpen(false)
  }, [setAddEmailTemplateId, setEmailNotificationOpen, resetTemplate])

  useEffect(() => {
    if (editOutlineOpen === false) {
      resetHighlight()
    }
  }, [editOutlineOpen, resetHighlight])

  return {
    panels: {
      editOutlineOpen,
      addContentOpen,
      addEmailNotificationOpen,
    },
    setPanels: {
      editOutline: { on: editOutlineOn, off: editOutlineOff },
      addContent: { on: addContentOn, off: addContentOff },
      addEmailNotification: { on: addEmailNotificationOn, off: addEmailNotificationOff },
    },
    isSavingOutline,
    setIsSavingOutline,
  }
}
