import { DateTime } from 'luxon'
import { z } from 'zod'

export const getSessionTimes = (
  startTime?: DateTime,
  endTime?: DateTime
): { date: string; weekDay: string; time: string } | undefined => {
  const atLeastOneDTnotUndefined = startTime ?? endTime
  if (atLeastOneDTnotUndefined === undefined) return undefined

  const date = atLeastOneDTnotUndefined.toFormat('MMM d')
  const weekDay = atLeastOneDTnotUndefined.toFormat('cccc')
  let time = atLeastOneDTnotUndefined.toFormat('t')

  if (startTime !== undefined && endTime !== undefined) {
    const startRange = startTime.toFormat('t')
    const endRange = endTime.toFormat('t')
    time = `${startRange}-${endRange}`
  }

  return {
    date,
    weekDay,
    time,
  }
}

export const getLiveSessionTimes = (
  startTime?: string,
  endTime?: string
): { date: string; weekDay: string; time: string } | undefined => {
  const startDT = startTime === undefined ? undefined : DateTime.fromISO(startTime)
  const endDT = endTime === undefined ? undefined : DateTime.fromISO(endTime)

  return getSessionTimes(startDT, endDT)
}

export const liveSessionFilterSchema = z.object({
  programs: z.array(z.string()),
  userGroups: z.array(z.string()),
  assignedToLiveSession: z.enum(['assigned', 'not-assigned']).optional(),
  status: z.enum(['passed', 'not-started', 'started']).optional(),
})

export type LSFilter = z.infer<typeof liveSessionFilterSchema>
