import _ from 'lodash'
import { DateTime } from 'luxon'
import { RouterLink } from 'sierra-client/components/common/link'
import { useTranslation } from 'sierra-client/hooks/use-translation'
import { useCachedQuery } from 'sierra-client/state/api'
import { MyCertificates } from 'sierra-client/views/workspace/learn/my-certificates'
import { LearnerContent } from 'sierra-domain/api/learn'
import { XRealtimeUserStatistics } from 'sierra-domain/routes'
import { color } from 'sierra-ui/color'
import { Icon } from 'sierra-ui/components'
import { Heading, Skeleton, Text, View } from 'sierra-ui/primitives'
import { token } from 'sierra-ui/theming'
import { v2_breakpoint } from 'sierra-ui/theming/breakpoints'
import { fonts } from 'sierra-ui/theming/fonts'
import styled from 'styled-components'

const TagsWrapper = styled(View)`
  width: 100%;
`

const TagContainer = styled(View)`
  ${fonts.body.small};
  padding: 0.5rem 1rem;
  border-radius: 100px;
  background-color: ${token('surface/soft')};
  display: inline-flex;
  max-width: 100%;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
`

const InsightsCard = styled.div`
  border: 2px solid ${token('border/default')};
  border-radius: 28px;

  padding: 1.5rem;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  flex: 1;
  @media (max-width: ${v2_breakpoint.desktop_small}) {
    padding: 1.5rem;
  }

  overflow-x: hidden;

  h4 {
    text-transform: lowercase;
  }
`

const contentHasFullProgress = (content: LearnerContent): boolean =>
  content.progress !== undefined && content.progress >= 1.0

const contentIsPassedLiveSession = (content: LearnerContent): boolean =>
  content.type === 'live-session' && DateTime.local() > DateTime.fromISO(content.endDate)

const CapitalizedText = styled(Text)`
  text-transform: capitalize;
`

const DividerView = styled(View)`
  > :not(:first-child)::before {
    content: '';
    border-top: 1px solid ${p => color(p.theme.home.backgroundColor).shift(0.05).toString()};
    width: 100%;
    padding: 12px 0;
  }
`

export const LearnInsightsCard: React.FC<{ content: LearnerContent[] }> = ({ content }) => {
  const topTags = useCachedQuery(XRealtimeUserStatistics, {}).data?.topTags
  const { t } = useTranslation()

  return (
    <InsightsCard>
      <View direction='column' gap='12'>
        <CapitalizedText size='small' color='foreground/muted' bold>
          {t('dictionary.progress')}
        </CapitalizedText>
        <View gap='none' direction='column'>
          <Heading size='h4' bold>
            {t('manage.content.n-contents', { count: content.length })}
          </Heading>
          {content.length > 0 && (
            <Heading size='h4' color='greenBright' bold>
              {_.sumBy(content, c => (contentHasFullProgress(c) || contentIsPassedLiveSession(c) ? 1 : 0))}{' '}
              {t('dictionary.completed')}
            </Heading>
          )}
        </View>
      </View>
      <DividerView direction='column' gap='small'>
        <MyCertificates />
        {topTags !== undefined && topTags.length > 0 && (
          <TagsWrapper gap='none' wrap='wrap' direction='column'>
            <Text size='small' color='foreground/muted' bold>
              {t('learner-dashboard.most-studied-tags')}
            </Text>
            <View wrap='wrap' paddingTop='12'>
              {topTags
                .slice(0, 5) // naive way to prevent vertical overflow
                .map(tag => (
                  <RouterLink key={tag.id} href={`/browse?t=${tag.id}`}>
                    <TagContainer key={tag.id} gap={'xxsmall'}>
                      <Text bold size='small'>
                        {tag.data.name}
                      </Text>
                      <Text color='foreground/muted' size='small'>
                        <View gap={'4'}>
                          <Icon iconId='time' />
                          {tag.timeSpentMinutes < 60
                            ? t('time.minutes', { number: Math.floor(tag.timeSpentMinutes) })
                            : t('time.hours', { count: Math.floor(tag.timeSpentMinutes / 60) })}
                        </View>
                      </Text>
                    </TagContainer>
                  </RouterLink>
                ))}
            </View>
          </TagsWrapper>
        )}
      </DividerView>
    </InsightsCard>
  )
}

export const LearnInsightsCardSkeleton: React.FC = () => (
  <InsightsCard>
    <View direction='column' gap='12'>
      <Text size='small' bold>
        <Skeleton $width={70} $height={18} $radius={4} />
      </Text>
      <View gap='none' direction='column'>
        <Heading color='LEGACY_DEFAULT_HEADING_COLOR_REPLACE_ASAP' size='h4' bold>
          <Skeleton $width={150} $height={18} $radius={4} />
        </Heading>
        <Skeleton $width={170} $height={18} $radius={4} />
      </View>
    </View>
  </InsightsCard>
)
