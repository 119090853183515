import React, { AriaRole, useEffect } from 'react'
import { useActiveRouteId } from 'sierra-client/hooks/router/use-active-route'
import { FoldingColumn, FoldingColumnProps } from 'sierra-ui/components'
import { Text } from 'sierra-ui/primitives'
import { token, zIndex } from 'sierra-ui/theming'
import { useOnChanged, useWindowSize } from 'sierra-ui/utils'
import styled, { css } from 'styled-components'

export function useSidebarShouldOverlayContent(): boolean {
  const windowWidth = useWindowSize().width
  const isSmallScreen = windowWidth < 768

  return isSmallScreen
}

const ClickAwayDiv = styled.div`
  position: fixed;
  z-index: calc(${zIndex.ON_MODAL} - 1);
  inset: 0;
  background-color: rgba(0, 0, 0, 0.05);
  animation: fade-in 50ms ease-in-out forwards;

  @keyframes fade-in {
    from {
      opacity: 0;
    }
    to {
      opacity: 1;
    }
  }
`

const StyledFoldingColumn = styled(FoldingColumn)<{ $overlaysContent: boolean }>`
  position: relative;
  overflow: hidden;

  background-color: ${token('surface/default')};

  ${Text}, & {
    transition:
      background-color 100ms ease,
      color 100ms ease;
  }

  padding: 0;

  ${p =>
    p.$overlaysContent &&
    css`
      position: absolute;
      z-index: ${zIndex.ON_MODAL};
      top: 0;
      left: 0;
      bottom: 0;
    `}
`

export type SidebarFoldingColumnProps = Omit<FoldingColumnProps, 'width'> & {
  setOpen: (_: boolean) => void
  width?: number
  // Determines if the default state of the sidebar should be opened or closed.
  // If closed, the sidebar will only open when the user explicitly opens it.
  preferredState?: 'opened' | 'closed'
}

export const SidebarFoldingColumn = React.forwardRef<
  HTMLDivElement,
  SidebarFoldingColumnProps & { role?: AriaRole | undefined; id?: string }
>(({ children, open, preferredState = 'opened', setOpen, width = 294, ...rest }, ref) => {
  const sidebarOverlaysContent = useSidebarShouldOverlayContent()

  // Close when making the window too small, and open it when the window is made large enough again unless
  // `preferredState === 'closed'`
  useEffect(() => {
    if (preferredState === 'closed' && !sidebarOverlaysContent) return
    else setOpen(!sidebarOverlaysContent)
  }, [sidebarOverlaysContent, setOpen, preferredState])

  // Close the sidebar when the route changes if the sidebar overlays content
  const routeId = useActiveRouteId()

  useOnChanged(() => {
    if (sidebarOverlaysContent) {
      setOpen(false)
    }
  }, routeId)

  return (
    <>
      <StyledFoldingColumn
        ref={ref}
        $overlaysContent={sidebarOverlaysContent}
        open={open}
        width={width}
        {...rest}
        disableScrollbarGutter
      >
        {children}
      </StyledFoldingColumn>
      {sidebarOverlaysContent && open && <ClickAwayDiv onClick={() => setOpen(false)} />}
    </>
  )
})
