import { useSetAtom } from 'jotai'
import React, { FC, useMemo } from 'react'
import {
  CenterContent,
  CenterHeaderContent,
  pointerEventMixing,
} from 'sierra-client/components/common/header-view'
import { useIsFacilitatorOrLearnerLedSession } from 'sierra-client/components/liveV2/hooks/use-is-facilitator-or-learner-led-session'
import { useSelectCurrentCard } from 'sierra-client/components/liveV2/hooks/use-select-current-card'
import { liveToolbarAtom } from 'sierra-client/components/liveV2/live-layer/live-toolbar-ref'
import EmojiPickerPopupProvider from 'sierra-client/editor/emoji-picker-popup-provider'
import { ResponsiveNowButton } from 'sierra-client/features/sana-now/header/buttons'
import {
  EmojiReactionButton,
  EmojiReactions,
} from 'sierra-client/features/sana-now/header/emoji-reaction-button'
import { LeftFileNavigationButtons } from 'sierra-client/features/sana-now/header/file-navigation-left'
import { RightFileNavigationButtons } from 'sierra-client/features/sana-now/header/file-navigation-right'
import { FollowMeButton } from 'sierra-client/features/sana-now/header/follow-me-button'
import { OptionsButton } from 'sierra-client/features/sana-now/header/options-button'
import { useEndSessionModal } from 'sierra-client/features/sana-now/header/use-end-session-modal'
import { SanaNowTimer } from 'sierra-client/features/sana-now/timer'
import { useStableFunction } from 'sierra-client/hooks/use-stable-function'
import { useTranslation } from 'sierra-client/hooks/use-translation'
import { useSelector } from 'sierra-client/state/hooks'
import { selectUserIdToFollow } from 'sierra-client/state/live-session/selectors'
import { isDefined } from 'sierra-domain/utils'
import { View } from 'sierra-ui/primitives'
import { maxWidth } from 'sierra-ui/utils/media-query-styles'
import styled from 'styled-components'

const LeaveSessionButton = (): JSX.Element => {
  const { t } = useTranslation()
  const { show: showLeaveModal } = useEndSessionModal()

  return (
    <ResponsiveNowButton
      iconId='skill--figure--walk'
      onClick={showLeaveModal}
      title={t('sana-now.header.end-session-button-text')}
    />
  )
}

const TransparentFooterViewContainer = styled(View)`
  position: absolute;
  bottom: 0;
  left: 0;
  z-index: 1;
  padding: 24px 12px;
  width: 100%;
  overflow-x: auto;
  /** We take up more height than we need in order to not cut off the reactions container.
    * It should be fine since we also disable pointer events on this container.
    */
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: flex-end;

  pointer-events: none;
`

const ButtonSpacingView = styled(View)`
  ${pointerEventMixing};
  gap: 24px;
  justify-content: center;

  ${maxWidth.phone} {
    gap: 0;
    justify-content: space-between;
    padding: 0 1rem;
  }
`

const useIsFollowMeSupported = (): boolean => {
  const currentCard = useSelectCurrentCard()

  return useMemo(
    () => currentCard?.data.type === 'general' || currentCard?.data.type === 'video',
    [currentCard?.data.type]
  )
}

const LearnerFooter: React.FC = () => {
  const followMeIsSupported = useIsFollowMeSupported()
  const userToFollow = useSelector(selectUserIdToFollow)

  const setNowToolbarAtom = useSetAtom(liveToolbarAtom)
  const setNowToolbar = useStableFunction((container: HTMLElement | null) => {
    if (container === null) {
      setNowToolbarAtom(null)
    } else {
      setNowToolbarAtom({ container, shouldHide: false })
    }
  })

  if (followMeIsSupported) {
    return (
      <CenterContent ref={setNowToolbar}>
        <FollowMeButton />
        {isDefined(userToFollow) ? <EmojiReactionButton /> : <EmojiReactions transparent />}
      </CenterContent>
    )
  }

  return (
    <CenterContent ref={setNowToolbar}>
      <EmojiReactions transparent />
    </CenterContent>
  )
}

const FacilitatorFooter: React.FC = () => {
  const followMeIsSupported = useIsFollowMeSupported()
  const setNowToolbarAtom = useSetAtom(liveToolbarAtom)
  const setNowToolbar = useStableFunction((container: HTMLElement | null) => {
    if (container === null) {
      setNowToolbarAtom(null)
    } else {
      setNowToolbarAtom({ container, shouldHide: false })
    }
  })

  return (
    <ButtonSpacingView grow ref={setNowToolbar}>
      <LeftFileNavigationButtons />
      <View>
        <SanaNowTimer />
        {followMeIsSupported && <FollowMeButton />}
        <EmojiReactionButton responsive />
        <LeaveSessionButton />
        <OptionsButton />
      </View>
      <RightFileNavigationButtons />
    </ButtonSpacingView>
  )
}

const FooterCenterContent: FC = (): JSX.Element => {
  const isFacilitatorOrLearnerLedSession = useIsFacilitatorOrLearnerLedSession()

  return (
    <EmojiPickerPopupProvider>
      {isFacilitatorOrLearnerLedSession ? <FacilitatorFooter /> : <LearnerFooter />}
    </EmojiPickerPopupProvider>
  )
}

export const Footer: React.FC = () => {
  return (
    <TransparentFooterViewContainer>
      <CenterHeaderContent $show={true}>
        <FooterCenterContent />
      </CenterHeaderContent>
    </TransparentFooterViewContainer>
  )
}
