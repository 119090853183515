import { atom } from 'jotai'
import { UpsertCertificateRequestInput } from 'sierra-client/api/graphql/gql/graphql'

export const durationUnits = ['DAYS', 'MONTHS', 'YEARS'] as const
export const templateNames = ['MINIMAL', 'CLASSIC', 'EXTERNAL'] as const // TODO Fix hardcoded template names

export const getEmptyCertificate = (orgName?: string): UpsertCertificateRequestInput => ({
  title: '',
  description: '',
  validityPeriod: { expires: false, duration: undefined, timeUnit: 'MONTHS' },
  certificateAuthority: orgName ?? '',
  templateData: {
    backgroundColor: '#F4F4F1',
    backgroundImage: undefined,
    textColor: '#000000',
    orientation: 'HORIZONTAL',
    logotype: undefined,
    templateId: templateNames[0],
  },
})

export const normalizeCertificateForGraphQL = (
  certificate: UpsertCertificateRequestInput
): UpsertCertificateRequestInput => {
  return {
    ...certificate,
    validityPeriod:
      certificate.validityPeriod.expires === true ? certificate.validityPeriod : { expires: false },
    templateData: {
      ...certificate.templateData,
      logotype: certificate.templateData.logotype ?? undefined, // Normalize logotype because graphQL doesnt handle null well for some reason
      backgroundImage: certificate.templateData.backgroundImage ?? undefined, // Normalize backgroundImage because graphQL doesnt handle null for some reason
    },
  }
}

export type CertificateInput = UpsertCertificateRequestInput

export const selectedCertificateAtom = atom<UpsertCertificateRequestInput>(getEmptyCertificate())
