import { produce } from 'immer'
import { useAtomValue } from 'jotai'
import { useAtom } from 'jotai/index'
import _ from 'lodash'
import { useCallback, useMemo } from 'react'
import {
  emailPlaceholdersAtom,
  emailTemplateAtom,
  initialEmailTemplateAtom,
  previewEmailTemplateAtom,
} from 'sierra-client/views/manage/programs/staggered-assignments/atoms'
import {
  EmailPlaceholderKeys,
  EmailTemplate,
  EmailTemplateKeys,
  EmailTemplateValues,
} from 'sierra-client/views/manage/programs/staggered-assignments/types'
import { emptyEmailTemplate } from 'sierra-client/views/manage/programs/staggered-assignments/utils'

type UseEmailTemplate = {
  initialEmailTemplate: EmailTemplate
  setInitialEmailTemplate: (initialTemplate: EmailTemplate) => void
  emailTemplate: EmailTemplate
  previewEmailTemplate: EmailTemplate
  updateEmailTemplate: (updates: Partial<EmailTemplate>) => void
  resetTemplate: () => void
  hasChanged: boolean
  hasCorrectlyFilledTemplate: boolean
}

export const useEmailTemplate = (): UseEmailTemplate => {
  const [initialEmailTemplate, setInitialEmailTemplate] = useAtom(initialEmailTemplateAtom)
  const [emailTemplate, setEmailTemplate] = useAtom(emailTemplateAtom)
  const [previewEmailTemplate, setPreviewEmailTemplate] = useAtom(previewEmailTemplateAtom)
  const emailPlaceholders = useAtomValue(emailPlaceholdersAtom)

  const hasChanged = !_.isEqual(initialEmailTemplate, emailTemplate)

  const isValidUrl = (url: string): boolean => {
    try {
      return Boolean(new URL(url))
    } catch (e) {
      return false
    }
  }

  const hasCorrectlyFilledTemplate = useMemo(() => {
    if (emailTemplate.headline === '') {
      return false
    }

    if (emailTemplate.buttonText === '') {
      return false
    }

    if (emailTemplate.customButtonUrl !== undefined && !isValidUrl(emailTemplate.customButtonUrl)) {
      return false
    }

    return true
  }, [emailTemplate.buttonText, emailTemplate.customButtonUrl, emailTemplate.headline])

  const updateEmailTemplate: UseEmailTemplate['updateEmailTemplate'] = useCallback(
    updates => {
      const newPreviewEmailTemplate = { ...previewEmailTemplate }
      const updatedTemplateData = produce(draft => {
        for (const [key, value] of Object.entries(updates) as [EmailTemplateKeys, EmailTemplateValues][]) {
          if (key === 'image') {
            if (typeof value === 'string') {
              return
            } else {
              draft[key] = value
            }
          } else if (key === 'customButtonUrl') {
            if (value === null || (typeof value !== 'string' && value !== undefined)) {
              return
            } else {
              draft[key] = value
            }
          } else {
            if (typeof value !== 'string') {
              return
            } else if (value === '') {
              newPreviewEmailTemplate[key] = emailPlaceholders[key as EmailPlaceholderKeys] // Ensure preview never contains empty strings
            } else {
              newPreviewEmailTemplate[key] = value
            }
            draft[key] = value
          }
        }
      }, emailTemplate)
      setEmailTemplate(updatedTemplateData)
      setPreviewEmailTemplate(newPreviewEmailTemplate)
    },
    [emailPlaceholders, emailTemplate, previewEmailTemplate, setEmailTemplate, setPreviewEmailTemplate]
  )

  const resetTemplate: UseEmailTemplate['resetTemplate'] = useCallback(() => {
    setInitialEmailTemplate(emptyEmailTemplate)
    setEmailTemplate(emptyEmailTemplate)
    setPreviewEmailTemplate(emailPlaceholders)
  }, [emailPlaceholders, setEmailTemplate, setInitialEmailTemplate, setPreviewEmailTemplate])

  return {
    initialEmailTemplate,
    setInitialEmailTemplate,
    emailTemplate,
    previewEmailTemplate,
    updateEmailTemplate,
    hasChanged,
    resetTemplate,
    hasCorrectlyFilledTemplate,
  }
}
