import { SkillColor } from 'sierra-client/features/skills/colors'
import { RichBadge } from 'sierra-client/features/skills/components/badges'
import { useTranslation } from 'sierra-client/hooks/use-translation'
import { SkillIconId } from 'sierra-ui/components'
import { Button, Text, View } from 'sierra-ui/primitives'
import { DarkTokenProvider } from 'sierra-ui/theming'
import styled from 'styled-components'
import { useTracking } from '../tracking'

const StyledView = styled(View).attrs({
  direction: 'column',
  alignItems: 'center',
  justifyContent: 'center',
  gap: '32',
})`
  height: 100%;
  width: 100%;
`

const BadgeContainer = styled(View).attrs({ direction: 'column' })`
  width: 400px;
`

type BadgeAchievementModalProps = {
  onClick: () => void
  btnText: string
  animateOut?: boolean
  badgeIcon: SkillIconId
  badgeTheme: SkillColor
  skillName: string
  skillLevelName: string
}

export const BadgeAchievement: React.FC<BadgeAchievementModalProps> = ({
  onClick,
  btnText,
  badgeIcon,
  badgeTheme,
  skillName,
  skillLevelName,
  animateOut = false,
}) => {
  const { t } = useTranslation()
  const tracking = useTracking()

  const icon = SkillIconId.catch('skill--graduation--cap').parse(badgeIcon)
  const color = SkillColor.catch('bronze').parse(badgeTheme)

  return (
    <DarkTokenProvider>
      <StyledView>
        <View
          animated
          initial={{ opacity: 0, y: 5 }}
          animate={animateOut ? { y: 5, opacity: 0 } : { y: 0, opacity: 1 }}
          transition={{ duration: 0.4, type: 'spring', stiffness: 200, damping: 25 }}
        >
          <Text bold size='regular'>
            {t('skills.badge-achievement.new-achievement')}
          </Text>
        </View>
        <BadgeContainer
          animated
          initial={{ scale: 0.5, opacity: 0 }}
          animate={animateOut ? { scale: 0.5, opacity: 0 } : { scale: 1, opacity: 1 }}
          transition={{ duration: 0.2, type: 'spring', stiffness: 200, damping: 25 }}
        >
          <RichBadge
            iconId={icon}
            theme={color}
            title={skillName}
            subtitle={skillLevelName}
            onMouseEnter={p => {
              if (p.theme !== 'disabled') {
                tracking.badge.enterHover({ theme: p.theme, iconId: p.iconId })
              }
            }}
            onMouseLeave={p => {
              if (p.theme !== 'disabled') {
                tracking.badge.leaveHover({ theme: p.theme, iconId: p.iconId })
              }
            }}
          />
        </BadgeContainer>
        <View
          direction='column'
          alignItems='center'
          gap='40'
          animated
          initial={{ opacity: 0, y: -5 }}
          animate={animateOut ? { y: -5, opacity: 0 } : { y: 0, opacity: 1 }}
          transition={{ duration: 0.4, type: 'spring', stiffness: 200, damping: 25 }}
        >
          <Button variant='ghost' onClick={onClick}>
            {btnText}
          </Button>
        </View>
      </StyledView>
    </DarkTokenProvider>
  )
}
