import { capitalize } from 'lodash'
import React, { useCallback, useEffect, useMemo, useRef, useState } from 'react'
import { resolveUserAvatar } from 'sierra-client/api/content'
import { Modal } from 'sierra-client/components/common/modals/modal'
import {
  SquareAvatar,
  SquareAvatarSize,
  desktopSquareAvatarSize,
  mobileSquareAvatarSize,
} from 'sierra-client/components/common/square-avatar'
import { useBreakoutInSession } from 'sierra-client/components/liveV2/hooks/use-breakout-in-session'
import { useTranslation } from 'sierra-client/hooks/use-translation'
import { useDispatch, useSelector } from 'sierra-client/state/hooks'
import {
  selectAllSessionParticipantsIncludingMe,
  selectAssignedBreakoutRoom,
  selectIsFacilitator,
} from 'sierra-client/state/live-session/selectors'
import { selectCallIsActive, selectCurrentBreakoutRoomId } from 'sierra-client/state/live/selectors'
import { selectUserId } from 'sierra-client/state/user/user-selector'
import { useUserLegacy, useUsersLegacy } from 'sierra-client/state/users/hooks'
import { getUserName, isDefined } from 'sierra-domain/utils'
import { AvatarStack } from 'sierra-ui/components'
import { Button, Heading, LoadingSpinner, Text, View } from 'sierra-ui/primitives'
import { palette } from 'sierra-ui/theming'
import { v2_breakpoint } from 'sierra-ui/theming/breakpoints'
import { legacyLight } from 'sierra-ui/theming/legacy-theme'
import styled, { ThemeProvider } from 'styled-components'

import { DateTime } from 'luxon'
import { CountDown } from 'sierra-client/components/liveV2/count-down-hoc'
import { SequentialTaskRunner } from 'sierra-client/lib/sequential-task-runner'
import { joinBreakoutRoom, leaveBreakoutRoom, muteAudio } from 'sierra-client/state/live/actions'
import { UserId } from 'sierra-domain/api/uuid'

const SECONDS_TO_JOIN = 7

const ParticipantPreview = ({ participantId }: { participantId: UserId }): JSX.Element => {
  const user = useUserLegacy(participantId)

  return (
    <SquareAvatar
      size='large'
      firstName={user?.firstName}
      lastName={user?.lastName}
      image={user?.avatar}
      color={user?.avatarColor}
      showName
    />
  )
}

const AdditionalParticipants = styled.div<{ $size: SquareAvatarSize }>`
  position: relative;
  display: flex;
  justify-content: center;
  align-items: center;
  overflow: hidden;
  border-radius: 8px;
  background-color: ${palette.primitives.black};
  color: white;

  width: ${desktopSquareAvatarSize};
  height: ${desktopSquareAvatarSize};
  @media screen and (max-width: ${v2_breakpoint.phone}) {
    width: ${mobileSquareAvatarSize};
    height: ${mobileSquareAvatarSize};
  }
`

const UserName = ({ participantId }: { participantId: UserId }): JSX.Element => {
  const { t } = useTranslation()
  const userId = useSelector(selectUserId)
  const user = useUserLegacy(participantId)
  if (userId === user?.uuid) return <span>{capitalize(t('dictionary.you'))}</span>

  return <span>{getUserName(user)}</span>
}

const UserNames = ({ participantIds }: { participantIds: UserId[] }): JSX.Element => {
  const userId = useSelector(selectUserId)
  const sortedParticipantIds = [
    ...participantIds.filter(participant => participant === userId),
    ...participantIds.filter(participant => participant !== userId),
  ]

  return (
    <Text size='small' align='center'>
      {sortedParticipantIds.map((participantId, index, { length }) => (
        <React.Fragment key={participantId}>
          <UserName participantId={participantId} />
          {index + 1 === length ? ' ' : index + 2 === length ? ' and ' : ', '}
        </React.Fragment>
      ))}
      {`will be ${sortedParticipantIds.length <= 1 ? 'alone' : ''} in this room`}
    </Text>
  )
}

const RoomPreview = styled(View)<{ size?: number }>`
  ${props => props.size !== undefined && props.size === 4 && 'max-width: 20rem'}
`

const StyledView = styled(View)`
  min-height: 2rem;
`

const StyldedHr = styled.hr`
  width: 100%;
  height: 1px;
  background-color: ${palette.grey[5]};
`

const CountDownText = (props: { endTime: DateTime; onTimeoutDone: () => void }): JSX.Element => {
  const { t } = useTranslation()

  return (
    <CountDown
      endTime={props.endTime}
      onTimeoutDone={props.onTimeoutDone}
      render={secondsToJoin => (
        <Text color='grey35' size='small'>
          {secondsToJoin < 0
            ? t('live.breakout-rooms.joining-now')
            : t('live.breakout-rooms.automatic-join-in-n-seconds', { seconds: secondsToJoin })}
        </Text>
      )}
    />
  )
}

export const BreakoutRoomController = (): JSX.Element => {
  const { t } = useTranslation()
  const assignedBreakoutRoom = useSelector(selectAssignedBreakoutRoom)
  const allParticipants = useSelector(selectAllSessionParticipantsIncludingMe)
  const breakoutRoomsOngoing = useBreakoutInSession()
  const currentBreakoutRoomId = useSelector(selectCurrentBreakoutRoomId)
  const joined = useSelector(selectCallIsActive)
  const isFacilitator = useSelector(selectIsFacilitator)
  const dispatch = useDispatch()

  const [isOpen, setIsOpen] = useState<boolean>(false)
  const [isJoining, setIsJoining] = useState<boolean>(false)
  const [timeoutEndTime, setTimeoutEndTime] = useState<DateTime | undefined>(undefined)

  const participants = assignedBreakoutRoom
    ? assignedBreakoutRoom.participants
    : allParticipants.map(p => p.userId)

  const title = assignedBreakoutRoom
    ? t('live.breakout-rooms.joining-room', {
        room: assignedBreakoutRoom.title ?? t('live.breakout-rooms.breakout-room'),
      })
    : t('live.breakout-rooms.returning-to-main')

  const firstFiveParticipants = useMemo(() => participants.slice(0, 5), [participants])
  const restOfParticipants = useMemo(() => participants.slice(5), [participants])

  const shouldJoinABreakoutRoom =
    breakoutRoomsOngoing &&
    assignedBreakoutRoom !== undefined &&
    assignedBreakoutRoom.id !== currentBreakoutRoomId

  const userIsInABreakoutRoom = currentBreakoutRoomId !== undefined
  const userIsNotAssignedToARoom = assignedBreakoutRoom === undefined
  const userCanBeInAnyRoom = isFacilitator

  const shouldReturnToMainRoom =
    (userIsInABreakoutRoom && !breakoutRoomsOngoing) ||
    (userIsInABreakoutRoom && userIsNotAssignedToARoom && !userCanBeInAnyRoom)

  const close = useCallback(() => {
    setIsOpen(false)
    setIsJoining(false)
    setTimeoutEndTime(undefined)
  }, [])

  const join = useCallback(async () => {
    setIsJoining(true)

    if (shouldJoinABreakoutRoom) {
      await dispatch(joinBreakoutRoom({ breakoutRoom: assignedBreakoutRoom.id })).unwrap()
    } else if (shouldReturnToMainRoom) {
      try {
        await dispatch(muteAudio()).unwrap()
      } catch (error) {
        // ignore error
      }
      await dispatch(leaveBreakoutRoom()).unwrap()
    }
  }, [assignedBreakoutRoom?.id, dispatch, shouldJoinABreakoutRoom, shouldReturnToMainRoom])

  useEffect(() => {
    if (!joined) return
    const userShouldMove = shouldJoinABreakoutRoom || shouldReturnToMainRoom

    if (!userShouldMove) return close()

    setIsOpen(true)
    setIsJoining(false)
    setTimeoutEndTime(DateTime.local().plus({ seconds: SECONDS_TO_JOIN }))
  }, [assignedBreakoutRoom?.id, joined, shouldJoinABreakoutRoom, shouldReturnToMainRoom, dispatch, close])

  const joinTaskQueue = useRef(new SequentialTaskRunner(1))
  const raceSafeJoin = useCallback(() => {
    // Ensure only one join is happening at a time
    joinTaskQueue.current.push(async () => {
      await join()
    })
  }, [join])

  const isJoiningInASec = isJoining
  const users = useUsersLegacy(restOfParticipants)
  const definedUsers = users.filter(isDefined).map(resolveUserAvatar)

  return (
    <ThemeProvider theme={legacyLight}>
      <Modal open={isOpen} hideCloseIcon>
        <View alignItems='center' direction='column' gap='medium' padding='medium' paddingBottom='none'>
          <Heading size='h4' bold>
            {title}
          </Heading>
          <RoomPreview
            alignItems='center'
            gap='xxsmall'
            wrap='wrap'
            justifyContent='center'
            size={participants.length}
          >
            {firstFiveParticipants.map(participantId => (
              <ParticipantPreview key={participantId} participantId={participantId} />
            ))}
            {restOfParticipants.length === 1 && restOfParticipants[0] !== undefined && (
              <ParticipantPreview participantId={restOfParticipants[0]} />
            )}
            {restOfParticipants.length > 1 && (
              <AdditionalParticipants $size='large'>
                <AvatarStack size={'small'} max={3} withTooltips users={definedUsers} />
              </AdditionalParticipants>
            )}
          </RoomPreview>
          <UserNames participantIds={participants} />
          {shouldReturnToMainRoom && (
            <Text color='foreground/secondary' size='small' align='center'>
              {t('live.breakout-rooms.mic-muted-on-return')}
            </Text>
          )}
          <Button variant='ghost' onClick={raceSafeJoin} disabled={isJoining}>
            {isJoining ? t('live.breakout-rooms.joining') : t('live.breakout-rooms.join-now')}
          </Button>
          <View direction='column' gap='xxsmall' marginTop='medium'>
            <StyldedHr />
            <StyledView>
              {timeoutEndTime && <CountDownText endTime={timeoutEndTime} onTimeoutDone={raceSafeJoin} />}
              {isJoiningInASec && <LoadingSpinner size='small' padding='2' />}
            </StyledView>
          </View>
        </View>
      </Modal>
    </ThemeProvider>
  )
}
