import { CopyPasteWithAssetsOptions } from 'sierra-client/views/v3-author/configuration/copy-paste-with-assets-options'
import { withPasteHtml } from 'sierra-client/views/v3-author/paste/with-paste-html'
import { isSupportedFile } from 'sierra-client/views/v3-author/plugins/with-file-paste'
import { Editor } from 'slate'

const debug = (...messages: unknown[]): void => console.debug('[withPaste]', ...messages)

/**
 * Pasted content is not necessarily normalized already.
 * We should always run a normalization pass to ensure we are in a good state.
 */
const withNormalizedInsert = (editor: Editor): Editor => {
  const { insertData } = editor

  editor.insertData = data => {
    insertData(data)

    debug('Content was pasted. Normalizing the editor.')
    Editor.normalize(editor, { force: true })
  }

  return editor
}
type PasteFile = (card: string) => void
type PastePlugin = (
  pasteFile: PasteFile,
  copyPasteWithAssetsOptions: CopyPasteWithAssetsOptions
) => (editor: Editor) => Editor

export const withPaste: PastePlugin =
  (pasteFile, copyPasteWithAssetsOptions: CopyPasteWithAssetsOptions) => editor => {
    const { insertData } = editor

    editor.insertData = data => {
      const { selection } = editor

      if (selection !== null) {
        const draggedFiles = Array.from(data.files).filter(isSupportedFile)

        if (draggedFiles.length > 0 && draggedFiles.length === data.files.length) {
          // The withFilePaste plugin handles insertion of files
          return insertData(data)
        }
      }
      return insertData(data)
    }

    return withNormalizedInsert(withPasteHtml(editor, pasteFile, copyPasteWithAssetsOptions))
  }
