import React from 'react'
import { HeaderGroupButton } from 'sierra-client/components/liveV2/header-buttons'
import { useTranslation } from 'sierra-client/hooks/use-translation'
import { FCC } from 'sierra-client/types'
import { MultiThemeIcon } from 'sierra-client/views/flexible-content/editor/content-sidebar/multi-selection/components/multi-theme-icon'
import { SingleThemeIcon } from 'sierra-client/views/flexible-content/editor/content-sidebar/multi-selection/components/single-theme-icon'
import { color } from 'sierra-ui/color'
import { Icon, Popover, Tooltip } from 'sierra-ui/components'
import { LightTokenProvider, palette, token } from 'sierra-ui/theming'
import { Theme, legacyLight } from 'sierra-ui/theming/legacy-theme'
import { focusRing } from 'sierra-ui/utils'
import { FocusRing } from 'sierra-ui/utils/focus-ring'
import styled, { ThemeProvider, css } from 'styled-components'

const ColorBox = styled.span.attrs({ focusRingSize: 'small' })<
  { $color?: string; $isActive: boolean } & FocusRing
>`
  height: 1rem;
  width: 1rem;
  background-color: ${p => p.$color};
  border: 1px solid #fff;
  border-radius: 0.25rem;
  margin-right: 6px;
  outline: 1px solid #fff;
  outline-offset: -1px;

  ${p =>
    p.$isActive === true
      ? focusRing
      : css`
          box-shadow: 0px 0px 0px 1px rgba(0, 0, 0, 0.125);
        `}
`

const StyledChevronIcon = styled(Icon).attrs({
  size: 'size-16',
  color: 'currentColor',
})`
  color: ${p => p.theme.color.grey40};
  transition: color 150ms cubic-bezier(0.25, 0.1, 0.25, 1);
`

const StyledButton = styled.button<{ open: boolean }>`
  display: flex;
  padding: 0.375rem;
  margin-left: -0.375rem;
  border: none;
  background-color: transparent;
  align-items: center;
  border-radius: 6px;
  cursor: pointer;

  transition: background-color 150ms cubic-bezier(0.25, 0.1, 0.25, 1);

  ${p =>
    p.open === true
      ? css`
          ${SingleThemeIcon} {
            box-shadow: 0px 0px 0px 2px rgba(5, 88, 249, 0.25);
          }
        `
      : css``}
  &:hover {
    background: rgba(0, 0, 0, 0.05);
  }

  &:hover ${StyledChevronIcon} {
    color: ${color('grey80').toString()};
  }
`

const StyledHeaderButton = styled(HeaderGroupButton)<{ open: boolean }>`
  transition: background-color 150ms cubic-bezier(0.25, 0.1, 0.25, 1);

  ${p =>
    p.open === true &&
    css`
      ${SingleThemeIcon} {
        box-shadow: 0px 0px 0px 2px rgba(5, 88, 249, 0.25);
      }
    `}
  &:hover ${MultiThemeIcon} {
    border-color: ${token('form/border/3')};
  }
`

const MenuButton = React.forwardRef<
  HTMLButtonElement,
  { children?: React.ReactNode; onClick: () => void; open: boolean }
>(({ children, onClick, open }, ref) => {
  const { t } = useTranslation()
  return (
    <Tooltip title={t('author.theme')}>
      <StyledHeaderButton onClick={onClick} ref={ref} open={open}>
        {children}
      </StyledHeaderButton>
    </Tooltip>
  )
})

const Menu: FCC<{ theme?: Theme; isOpen: boolean; setOpen: (newOpen: boolean) => void }> = ({
  theme,
  isOpen,
  setOpen,
  children,
}) => {
  return (
    <LightTokenProvider>
      <Popover
        isOpen={isOpen}
        onOpenChange={setOpen}
        sideOffset={4}
        renderTrigger={() => (
          <MenuButton open={isOpen} onClick={() => setOpen(!isOpen)}>
            <ThemeProvider theme={theme ?? legacyLight}>
              <MultiThemeIcon open={isOpen} />
              <StyledChevronIcon iconId={isOpen ? 'chevron--up--small' : 'chevron--down--small'} />
            </ThemeProvider>
          </MenuButton>
        )}
      >
        {children}
      </Popover>
    </LightTokenProvider>
  )
}

const AlternativeMenu: FCC<{
  selectedColor?: string
  isOpen: boolean
  setOpen: (newOpen: boolean) => void
}> = ({ children, selectedColor, isOpen, setOpen }) => {
  return (
    <LightTokenProvider>
      <Popover
        isOpen={isOpen}
        onOpenChange={setOpen}
        sideOffset={8}
        renderTrigger={() => (
          <StyledButton onClick={() => setOpen(!isOpen)} open={isOpen}>
            <ColorBox $color={selectedColor} $isActive={isOpen} />
            <StyledChevronIcon iconId={isOpen ? 'chevron--up--small' : 'chevron--down--small'} />
          </StyledButton>
        )}
      >
        {children}
      </Popover>
    </LightTokenProvider>
  )
}

const Color = styled.div<{ color: string; isSelected?: boolean }>`
  background-color: ${p => p.color};
  border-radius: 5px;
  height: 24px;
  width: 24px;
  padding: 0;
  margin: 0;
  text-align: center;
  cursor: pointer;
  border: 1px solid transparent;

  ${p =>
    p.isSelected === true &&
    css`
      box-shadow: 0px 0px 0px 2px rgba(5, 88, 249, 0.25);
    `}
  ${p =>
    p.color === palette.primitives.white &&
    css`
      border: 1px solid ${palette.grey[5]};
    `}
`

export const ColorPicker = {
  AlternativeMenu,
  Menu,
  Color,
}
