import { useSelfPacedFiles } from 'sierra-client/views/self-paced/files-provider'
import { Text, View } from 'sierra-ui/primitives'

export const Undefined: React.FC = () => {
  const { nextUp } = useSelfPacedFiles()

  return (
    <View padding='16'>
      Undefined state:
      <Text bold size='regular'>
        {nextUp.type}
      </Text>
    </View>
  )
}
