import { atom, useAtomValue, useSetAtom } from 'jotai'
import { useEffect } from 'react'
import { IntercomProvider, useIntercom } from 'react-use-intercom'
import { config, getFlag } from 'sierra-client/config/global-config'
import { CustomHelpDesk } from 'sierra-client/intercom/custom-help-desk'
import { isIntercomBootedAtom } from 'sierra-client/intercom/intercom-visibility'
import { useSelector } from 'sierra-client/state/hooks'
import { selectOrganization } from 'sierra-client/state/organization/selectors'
import { selectUser } from 'sierra-client/state/user/user-selector'
import { useHasManageAccess } from 'sierra-client/views/manage/permissions/use-has-manage-access'
import { createGlobalStyle } from 'styled-components'

export const intercomOverrideAtom = atom(getFlag('intercom'))

/*
  For certain components (such as modals), radix will focus trap, causing the intercom icon to be visible, but being inclickable.
*/
const IntercomPointerEventVisibility = createGlobalStyle`
    .intercom-lightweight-app {
        pointer-events: auto;
    }
`

export const IntercomLoader = (): null => {
  const intercom = useIntercom()
  const user = useSelector(selectUser)
  const organization = useSelector(selectOrganization)
  const hasManageAccess = useHasManageAccess()

  const setIsIntercomBooted = useSetAtom(isIntercomBootedAtom)

  useEffect(() => {
    if (user !== undefined && organization !== undefined) {
      intercom.shutdown()
      intercom.boot({
        name: `${user.firstName} ${user.lastName}`,
        userId: user.uuid,
        email: user.email,
        hideDefaultLauncher: true,
        userHash: user.userIntercomHash,
        customAttributes: {
          organization_uuid: user.organizationUuid,
          organization_name: user.organizationName,
          organization_domain: organization.domain,
          is_admin: hasManageAccess,
          access_level: user.accessLevel,
        },
      })

      setIsIntercomBooted(true)
    }
  }, [user, intercom, organization, hasManageAccess, setIsIntercomBooted])

  return null
}

export type SanaIntercomProviderProps = {
  children: React.ReactNode
}

export const SanaIntercomProvider = ({ children }: SanaIntercomProviderProps): JSX.Element => {
  const intercomOverride = useAtomValue(intercomOverrideAtom)
  const { 'custom-help-desk-url': customHelpDeskUrl, 'custom-help-desk-image': customHelpDeskImage } =
    config.organization.properties

  // eslint-disable-next-line @typescript-eslint/strict-boolean-expressions
  const hasCustomHelpDesk = !!(customHelpDeskUrl && customHelpDeskImage)
  const shouldInitialize = (!hasCustomHelpDesk && getFlag('intercom')) || intercomOverride

  return (
    <IntercomProvider
      apiBase='https://api-iam.eu.intercom.io'
      appId={config.intercomAppId}
      autoBoot={false}
      shouldInitialize={shouldInitialize}
    >
      {shouldInitialize && (
        <>
          <IntercomLoader />
          <IntercomPointerEventVisibility />
        </>
      )}

      {hasCustomHelpDesk && <CustomHelpDesk url={customHelpDeskUrl} image={customHelpDeskImage} />}

      {children}
    </IntercomProvider>
  )
}
