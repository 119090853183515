import { assertElementType } from 'sierra-client/views/v3-author/queries'
import { SlateFC } from 'sierra-client/views/v3-author/slate'

export const QuestionCardFreeTextBody: SlateFC = ({ element, children }) => {
  assertElementType('question-card-free-text-body', element)
  return (
    <>
      <div contentEditable={false}>Not supported {element.type}</div>
      {children}
    </>
  )
}
