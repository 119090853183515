import { LabelProps } from 'sierra-ui/components'
import { ThemeMode } from 'sierra-ui/theming/legacy-theme'

type LabelColors = Pick<LabelProps, '$bgColor' | '$borderColor' | '$color'>
export type PillVariant = 'outlined' | 'filled'
export type PillState = 'default' | 'hovered' | 'active'
type PillVariantColors = Record<PillState, Record<ThemeMode, LabelColors>>
type OneTypeToRuleThemAll = Record<PillVariant, PillVariantColors>

// Outlined
const outlinedDefault: LabelColors = {
  $bgColor: 'white',
  $borderColor: 'grey10',
  $color: 'black',
}
const outlinedHover: LabelColors = {
  ...outlinedDefault,
  $borderColor: 'grey25',
}
const outlinedActive: LabelColors = {
  $bgColor: 'blueVivid',
  $borderColor: 'blueVivid',
  $color: 'white',
}

const outlinedColors: PillVariantColors = {
  default: {
    light: outlinedDefault,
    dark: outlinedDefault,
  },

  hovered: {
    light: outlinedHover,
    dark: outlinedHover,
  },
  active: {
    light: outlinedActive,
    dark: outlinedActive,
  },
}

// Filled - light
const filledDefaultLight: LabelColors = {
  $bgColor: 'grey5',
  $borderColor: 'grey5',
  $color: 'black',
}
const filledHoverLight: LabelColors = {
  $bgColor: 'grey10',
  $borderColor: 'grey10',
  $color: 'black',
}
const filledActiveLight: LabelColors = {
  $bgColor: 'black',
  $borderColor: 'black',
  $color: 'white',
}

// Filled - dark
const filledDefaultDark: LabelColors = {
  $bgColor: 'grey85',
  $borderColor: 'grey85',
  $color: 'white',
}
const filledHoverDark: LabelColors = {
  $bgColor: 'grey80',
  $borderColor: 'grey80',
  $color: 'white',
}
const filledActiveDark: LabelColors = {
  $bgColor: 'white',
  $borderColor: 'white',
  $color: 'black',
}

const filledColors: PillVariantColors = {
  default: {
    light: filledDefaultLight,
    dark: filledDefaultDark,
  },

  hovered: {
    light: filledHoverLight,
    dark: filledHoverDark,
  },
  active: {
    light: filledActiveLight,
    dark: filledActiveDark,
  },
}

export const allPillVariants: OneTypeToRuleThemAll = {
  outlined: outlinedColors,
  filled: filledColors,
}
