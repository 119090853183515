import React from 'react'
import { UpdateReflectionsSummaryResponse } from 'sierra-domain/api/strategy-v2'
import { UserId } from 'sierra-domain/api/uuid'

export type SortOrder = 'created-time-desc' | 'created-time-asc' | 'popular'

type TypingUser = { anonymous: boolean; userId: UserId }

export type ReflectionResponseReaction = {
  reaction: string
  count: number
  reactedByUserIds: UserId[]
}

export type ReflectionResponseAnswer = {
  reflectionId: string
  responseId: string
  userId?: UserId
  isMyAnswer: boolean
  answerId: string
  answer: string
  createdAt: string
  timeEdited?: string
  anonymous: boolean
  reactions: ReflectionResponseReaction[]
}

export type ReflectionResponse = {
  id: string
  skipped: boolean
  response?: string
  userId?: UserId
  isMyResponse: boolean
  timeCreated?: string
  timeEdited?: string
  anonymous?: boolean
  reactions: ReflectionResponseReaction[]
  answers: ReflectionResponseAnswer[]
}

export type ReflectionsDataLayer = {
  reflectionId: string
  responses: ReflectionResponse[] | undefined
  buckets?: { bucketName: string; responseIds: string[] }[]
  userResponses: ReflectionResponse[]
  sortOrder: SortOrder
  usersTyping: TypingUser[]
  totalReflectionsCount: number
  availableSortOptions: SortOrder[]
  loadMore?: () => void
  userHasResponded?: boolean
  setSortOrder?: (sortOrder: SortOrder) => void
  reflectionsSummary:
    | {
        summaryEnabledInEditor: true
        summary: UpdateReflectionsSummaryResponse['summary']
        responseIds?: UpdateReflectionsSummaryResponse['responseIds']
        updatedAt: UpdateReflectionsSummaryResponse['updatedAt']
        loading: boolean
        updateSummary: () => void
      }
    | { summaryEnabledInEditor: false }
} & (
  | {
      readOnly: true
    }
  | {
      readOnly: false | undefined
      anonymous?: boolean
      setAnonymous?: (anonymous: boolean) => void
      skip: () => void
      submitReflection: (response: string) => void
      deleteReflection: (responseId: string) => void
      reactToResponse?: (responseId: string, reaction: string, answerId?: string) => void
      unReactToResponse?: (responseId: string, reaction: string) => void
      editResponse?: ({ responseId, newResponse }: { responseId: string; newResponse: string }) => void
      setIsTypingReflection?: (typing: boolean) => void
      submitReflectionAnswer?: (answer: string, responseId: string, anonymous: boolean) => void
      editReflectionAnswer?: (newAnswer: string, responseId: string, answerId: string) => void
      deleteReflectionAnswer?: (responseId: string, answerId: string) => void
    }
)

export const LiveContext = React.createContext<ReflectionsDataLayer | undefined>(undefined)
export const SelfPacedContext = React.createContext<ReflectionsDataLayer | undefined>(undefined)
export const CreateContext = React.createContext<ReflectionsDataLayer | undefined>(undefined)
export const RecapContext = React.createContext<ReflectionsDataLayer | undefined>(undefined)
