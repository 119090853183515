import { isEqual } from 'lodash'
import React, { useCallback, useMemo } from 'react'
import { useTranslation } from 'sierra-client/hooks/use-translation'
import { labelToString } from 'sierra-client/lib/filter/components/common'
import { valueId } from 'sierra-client/lib/filter/components/predicate-utils'
import {
  getValueRepFromDomainChoices,
  valueRepToMenuLabelItem,
} from 'sierra-client/views/manage/components/user-attributes/flows/invite-users/utils'
import { useUserAttributesDraft } from 'sierra-client/views/manage/components/user-attributes/flows/user-attribute-settings/hooks/use-user-attributes-draft'
import { getCurrentValueRepFromDomainChoices } from 'sierra-client/views/manage/components/user-attributes/flows/user-attribute-settings/utils'
import {
  createInvitationAttribute,
  findAttribute,
} from 'sierra-client/views/manage/components/user-attributes/utils'
import { UserCustomAttributeDomainRepWithParentChoices } from 'sierra-domain/user-attributes/user-invitation-domain-rep'
import { isDefined, isNonEmptyArray } from 'sierra-domain/utils'
import { LabelMenuItem, MenuItem } from 'sierra-ui/components'
import { SingleSelectDropdown } from 'sierra-ui/primitives/menu-dropdown'
import { useOnChanged } from 'sierra-ui/utils'

export const EditDomainWithParentChoicesInput: React.FC<{
  domainRep: UserCustomAttributeDomainRepWithParentChoices
}> = ({ domainRep }) => {
  const { dynamicT, t } = useTranslation()

  const { userAttributesDraft, addOrReplaceAttributes, readOnlyAttributes, resetAttribute } =
    useUserAttributesDraft()

  const parentRef = domainRep.domain.parentRef
  const parentValues = useMemo(
    () => findAttribute(userAttributesDraft.data, parentRef)?.values.map(v => v.value),
    [parentRef, userAttributesDraft.data]
  )
  const hasParentValue = isNonEmptyArray(parentValues)

  // If parent value changes, make sure we reset the current attribute
  useOnChanged((prev, next) => {
    // Make sure to only reset it if both old and new values are defined and different
    if (isDefined(prev) && isDefined(next) && isEqual(prev, next)) {
      resetAttribute(domainRep.ref)
    }
  }, parentValues)

  const currentAttributeItem: LabelMenuItem | undefined = useMemo(() => {
    return valueRepToMenuLabelItem(
      getCurrentValueRepFromDomainChoices(domainRep, userAttributesDraft),
      dynamicT
    )
  }, [domainRep, userAttributesDraft, dynamicT])

  const setAttribute = useCallback(
    (item: MenuItem): void => {
      const attributeValue = getValueRepFromDomainChoices(domainRep.domain, item.id)
      const attribute = createInvitationAttribute(domainRep.ref, [attributeValue.value])

      addOrReplaceAttributes([attribute])
    },
    [domainRep.domain, domainRep.ref, addOrReplaceAttributes]
  )

  const menuItems: LabelMenuItem[] = useMemo(
    () =>
      domainRep.domain.choices
        .filter(choice => parentValues?.includes(choice.parentValue.value))
        .map(choice => ({
          type: 'label',
          id: valueId(choice.value),
          value: choice.value,
          label: labelToString(
            getValueRepFromDomainChoices(domainRep.domain, valueId(choice.value)).label,
            dynamicT
          ),
        })),
    [domainRep.domain, dynamicT, parentValues]
  )

  return (
    <SingleSelectDropdown
      withSearch={menuItems.length > 8}
      disabled={
        readOnlyAttributes || domainRep.editableInternally === false || !hasParentValue
      } /* Can't be edited but can be shown */
      onSelect={setAttribute}
      selectedItem={currentAttributeItem}
      menuItems={menuItems}
      placeholder={t('dictionary.select')}
    />
  )
}
