import { useTranslation } from 'sierra-client/hooks/use-translation'
import type { TranslationKey } from 'sierra-client/hooks/use-translation/types'
import { Text } from 'sierra-ui/primitives'
import styled from 'styled-components'

const ErrorContainer = styled.div`
  height: 100%;
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
`

const ErrorText = styled.div`
  max-width: 60ch;
  margin: 0 auto;
`

export const ErrorCard: React.FC<{ translationKey?: TranslationKey }> = ({
  translationKey = 'manage.insights.error-boundary',
}) => {
  const { t } = useTranslation()
  return (
    <ErrorContainer>
      <ErrorText>
        <Text color='foreground/muted'>{t(translationKey)}</Text>
      </ErrorText>
    </ErrorContainer>
  )
}
