import DOMPurify from 'dompurify'
import { createMutableDataTransfer } from 'sierra-client/views/v3-author/paste/create-mutable-data-transfer'
import { iife } from 'sierra-domain/utils'
import { CustomElement, CustomText, SanaEditor } from 'sierra-domain/v3-author'

function debug(...messages: unknown[]): void {
  console.debug('[withSanitizedSlatePaste]', ...messages)
}

function sanitizeSlateData(data: DataTransfer): DataTransfer {
  const rawSlateData = data.getData('application/x-slate-fragment')
  if (!rawSlateData) {
    return data
  }

  debug('Attempting to paste slate data. Performing type validation first.')

  const cleanedSlateData = iife(() => {
    const decodedSlateData = decodeURIComponent(window.atob(rawSlateData))
    const parsedSlateData = CustomElement.or(CustomText).array().safeParse(JSON.parse(decodedSlateData))
    if (parsedSlateData.success) {
      debug('Successfully validated pasted slate data')
      const reencodedSlateData = window.btoa(encodeURIComponent(JSON.stringify(parsedSlateData.data)))
      return reencodedSlateData
    } else {
      debug('Failed to validate pasted slate data')
      return undefined
    }
  })

  const transfer = createMutableDataTransfer({ basedOn: data })
  const slateValidationSucceeded = cleanedSlateData !== undefined

  if (!slateValidationSucceeded) {
    debug('Pasting slate data failed validation. Removing slate data from data transfer')
    transfer.clearData('application/x-slate-fragment')
    debug('Pasting slate data failed validation. Removing data-slate-fragment attribute from data/html')

    const htmlData = data.getData('text/html')
    const sanitizedHtmlData = DOMPurify.sanitize(htmlData, { FORBID_ATTR: ['data-slate-fragment'] })
    transfer.setData('text/html', sanitizedHtmlData)
  } else {
    debug('Pasting slate data passed validation. Replacing slate data in data transfer')
    transfer.setData('application/x-slate-fragment', cleanedSlateData)
  }

  return transfer
}

export function withSanitizedSlatePaste(editor: SanaEditor): SanaEditor {
  const { insertData } = editor
  editor.insertData = data => {
    const sanitizedData = sanitizeSlateData(data)
    insertData(sanitizedData)
  }

  return editor
}
