import { CreateContentId } from 'sierra-domain/api/nano-id'
import { z } from 'zod'

export const CopyPasteClipboardKey = 'application/x-sierra-copy-paste'

export const CopyPasteContext = z.object({
  createContentId: CreateContentId,
  signedAssetsUrl: z.string(),
})

export type CopyPasteContext = z.infer<typeof CopyPasteContext>
