import { Extension } from '@tiptap/core'

export const SendOnEnter = Extension.create<{ send: () => void }>({
  name: 'SendOnEnter',

  addOptions() {
    return {
      send: () => {},
    }
  },

  addKeyboardShortcuts() {
    return {
      Enter: () => {
        this.options.send()
        return true
      },
    }
  },
})
