import { useMutation } from '@tanstack/react-query'
import React, { ComponentProps } from 'react'
import { graphql } from 'sierra-client/api/graphql/gql'
import { SkillLevelSettingInput, SkillsSettingsInput } from 'sierra-client/api/graphql/gql/graphql'
import { graphQuery, useGraphQuery, useInvalidateGraphQuery } from 'sierra-client/api/hooks/use-graphql-query'
import { SkillPanel } from 'sierra-client/features/skills/components/actions/common'
import { getSkillLevelSettingsQuery } from 'sierra-client/features/skills/components/actions/skills'
import {
  BadgeTheme,
  FormData as SettingsFormData,
  SkillLevelSetting,
  SkillSettingsForm,
} from 'sierra-client/features/skills/components/skill-settings/skill-settings-form'
import {
  getSkillsCountAndLevelsQuery,
  skillSettingsQuery,
} from 'sierra-client/features/skills/shared-gql-queries'
import { useTracking } from 'sierra-client/features/skills/tracking'
import { useTranslation } from 'sierra-client/hooks/use-translation'
import { isDefined, isNonEmptyString } from 'sierra-domain/utils'
import { Button, IconButton, LoadingSpinner } from 'sierra-ui/primitives'

const toGraphql = (
  data: SettingsFormData
): { settings: SkillsSettingsInput; levels: Array<SkillLevelSettingInput> } => ({
  levels: data.levels.map(level => ({
    id: level.skillLevelId,
    name: level.name,
    defaultBadgeTheme: level.badgeTheme,
    index: level.index,
  })),
  settings: {
    levelsEngineEnabled: data.smartSuggestions,
    skillsEngineEnabled: data.smartSuggestions,
    sequentialUnlockingEnabled: data.sequentialUnlocking,
    showLeaderboard: data.skillsShowLeaderboard,
    hideSkills: !data.skillsVisibleToAll,
  },
})

const defaultSkillLevels: Array<SkillLevelSetting> = [
  {
    name: 'Novice',
    index: 1,
    badgeTheme: 'titanium',
  },
  {
    name: 'Intermediate',
    index: 2,
    badgeTheme: 'silver',
  },
  {
    name: 'Expert',
    index: 3,
    badgeTheme: 'gold',
  },
]

const SkillSettings: React.FC<
  Pick<ComponentProps<typeof SkillSettingsForm>, 'onSubmit' | 'onCancel' | 'loading'>
> = ({ onCancel, onSubmit, loading }) => {
  const query = useGraphQuery({ document: skillSettingsQuery })

  return isDefined(query.data) && query.data.skillLevelSettings.length === 0 ? (
    <SkillSettingsForm
      levels={defaultSkillLevels}
      settings={{
        smartSuggestions: false,
        sequentialUnlockingEnabled: false,
        skillsVisibleToAll: false,
        skillsShowLeaderboard: false,
      }}
      onCancel={onCancel}
      onSubmit={onSubmit}
      loading={loading}
    />
  ) : isDefined(query.data) ? (
    <SkillSettingsForm
      levels={query.data.skillLevelSettings.map(l => ({
        skillLevelId: l.id,
        name: l.name,
        index: l.index,
        badgeTheme: BadgeTheme.parse(l.defaultBadgeTheme),
      }))}
      settings={{
        smartSuggestions: query.data.skillSettings.skillsEngineEnabled,
        sequentialUnlockingEnabled: query.data.skillSettings.sequentialUnlockingEnabled,
        skillsVisibleToAll: !query.data.skillSettings.hideSkills,
        skillsShowLeaderboard: query.data.skillSettings.showLeaderboard,
      }}
      onCancel={onCancel}
      onSubmit={onSubmit}
      loading={loading}
    />
  ) : (
    <LoadingSpinner />
  )
}

const updateSettingsMutation = graphql(`
  mutation UpdateSkillSettings($settings: SkillsSettingsInput!, $levelsSettings: [SkillLevelSettingInput!]!) {
    updateSkillSettings(settings: $settings) {
      levelsEngineEnabled
      skillsEngineEnabled
      sequentialUnlockingEnabled
      showLeaderboard
      hideSkills
    }
    upsertSkillLevelSettings(skillLevelSettings: $levelsSettings)
  }
`)

export const SkillSettingAction: React.FC<{ buttonText?: string }> = ({ buttonText }) => {
  const { t } = useTranslation()
  const tracking = useTracking()
  const invalidateSkillLevelSettingsQuery = useInvalidateGraphQuery(getSkillLevelSettingsQuery)
  const invalidateSkillCountAndLevelsQuery = useInvalidateGraphQuery(getSkillsCountAndLevelsQuery)
  const invalidateSkillVisibilityQuery = useInvalidateGraphQuery(skillSettingsQuery)
  const [dialogOpen, setDialogOpen] = React.useState(false)

  const { mutate, ...res } = useMutation({
    mutationFn: ({
      settings,
      levels,
    }: {
      settings: SkillsSettingsInput
      levels: Array<SkillLevelSettingInput>
    }) => {
      return graphQuery(updateSettingsMutation, { settings, levelsSettings: levels })
    },
    onSuccess: (_, { settings, levels }) => {
      setDialogOpen(false)
      tracking.org.updateSettings({
        settings,
        levels: levels.map(level => ({ name: level.name, defaultBadgeTheme: level.defaultBadgeTheme })),
      })
      void invalidateSkillLevelSettingsQuery()
      void invalidateSkillCountAndLevelsQuery()
      void invalidateSkillVisibilityQuery()
    },
  })

  return (
    <>
      {isNonEmptyString(buttonText) ? (
        <Button onClick={() => setDialogOpen(true)} icon='settings--adjust' iconColor='currentColor'>
          {buttonText}
        </Button>
      ) : (
        <IconButton onClick={() => setDialogOpen(true)} iconId='settings--adjust' variant='secondary' />
      )}
      <SkillPanel
        onClose={() => setDialogOpen(false)}
        isOpen={dialogOpen}
        id='skill-settings'
        title={t('dictionary.settings')}
      >
        <SkillSettings
          onCancel={() => setDialogOpen(false)}
          onSubmit={data => mutate(toGraphql(data))}
          loading={res.isPending}
        />
      </SkillPanel>
    </>
  )
}
