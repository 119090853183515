import React, { useEffect } from 'react'
import { useTypedMutation } from 'sierra-client/state/api'
import { LiveContentId } from 'sierra-domain/api/nano-id'
import { XRealtimeStrategyLiveSessionMigrateToExternalNotepads } from 'sierra-domain/routes'
import { LoadingSpinner } from 'sierra-ui/primitives'

export const MigrateToExternalNotepad: React.FC<{ liveContentId: LiveContentId }> = ({ liveContentId }) => {
  const compactCourse = useTypedMutation(XRealtimeStrategyLiveSessionMigrateToExternalNotepads).mutate

  useEffect(() => {
    compactCourse({ courseId: liveContentId })
  }, [compactCourse, liveContentId])

  return <LoadingSpinner />
}
