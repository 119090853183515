import copy from 'clipboard-copy'
import React, { useEffect, useState } from 'react'
import { ActionModal } from 'sierra-client/components/common/modals/action-modal'
import { useNotif } from 'sierra-client/components/common/notifications'
import { useTranslation } from 'sierra-client/hooks/use-translation'
import { getGlobalRouter } from 'sierra-client/router'
import { ApiClientSecretBox } from 'sierra-client/views/manage/api/components/api-client-secret-box'
import { useManageApiClientDetails } from 'sierra-client/views/manage/api/use-manage-client-api-details'
import { DetailsHeader } from 'sierra-client/views/manage/components/details-header'
import { Separator } from 'sierra-client/views/showcase/common'
import { ApiClientId } from 'sierra-domain/api/nano-id'
import { CreateApiClientRequest } from 'sierra-domain/api/public-api'
import { FormElement, Input } from 'sierra-ui/components'
import { Button, IconButton, Spacer, Text, TextAreaPrimitive, View } from 'sierra-ui/primitives'
import styled from 'styled-components'

const Container = styled(View).attrs({ direction: 'column', grow: true })`
  max-width: 40rem;
`

interface ManageApiClientDetailsProps {
  clientId: ApiClientId | 'new'
}

export const ManageApiClientDetails: React.FC<ManageApiClientDetailsProps> = ({ clientId }) => {
  const { t } = useTranslation()
  const notifications = useNotif()

  const { isLoading, apiClient, createApiClient, updateApiClient, deleteApiClient, generateScimToken } =
    useManageApiClientDetails(clientId)
  const [action, setAction] = useState<undefined | { modal: 'delete' | 'revoke' | 'renew' | 'scim' }>(
    undefined
  )
  const [clientForm, setClientForm] = useState<CreateApiClientRequest>({ name: '', description: '' })

  useEffect(() => {
    setClientForm({ name: apiClient.name, description: apiClient.description })
  }, [apiClient.name, apiClient.description])

  const handleCreate = async (): Promise<void> => {
    if (isLoading) return
    if (clientForm.name === '') return

    // Create
    if (apiClient.isNew) {
      await createApiClient(clientForm)
      notifications.push({ type: 'success', title: t('manage.api.notifications.created'), body: '' })
      return
    }
  }

  // Form change
  const handleFormChange = <T extends keyof CreateApiClientRequest, R extends CreateApiClientRequest[T]>(
    field: T,
    value: R
  ): void => {
    setClientForm(f => ({
      ...f,
      [field]: value,
    }))
  }

  //Flags
  const disableUpdate = clientForm.name === apiClient.name && clientForm.description === apiClient.description

  return (
    <Container>
      <DetailsHeader
        backlink={{
          href: '/settings/api',
          label: 'manage.backlinks--api',
        }}
      />

      <View direction='column' gap='none'>
        <Text size='large' bold>
          {apiClient.name === '' ? t('manage.api.title-new') : apiClient.name}
        </Text>

        <Spacer size='32' />

        <Input
          id='name'
          placeholder={t('dictionary.name')}
          label={t('dictionary.name')}
          value={clientForm.name}
          disabled={!apiClient.isNew}
          onChange={e => handleFormChange('name', e.target.value)}
        />

        <FormElement label={t('admin.organization.description')} htmlFor='description'>
          <TextAreaPrimitive
            id='description'
            placeholder={t('dictionary.description')}
            disabled={!apiClient.isNew}
            value={clientForm.description}
            rows={3}
            autoExpand
            onChange={e => handleFormChange('description', e.target.value)}
          />
        </FormElement>

        <Spacer size='16' />

        {apiClient.clientId !== undefined ? (
          <View>
            <Input
              grow
              id='clientId'
              placeholder={t('manage.api.client-id')}
              label={t('manage.api.client-id')}
              disabled
              value={apiClient.clientId}
              onChange={() => {}}
              spacing='none'
            />
            <IconButton
              iconId='duplicate'
              variant='transparent'
              onClick={() => copy(apiClient.clientId ?? '')}
              tooltip={t('dictionary.copy-to-clipboard')}
            />
          </View>
        ) : null}

        {apiClient.clientSecret !== '' ? (
          <ApiClientSecretBox
            label={t('manage.api.client-secret')}
            description={t('manage.api.client-secret-description')}
            secret={apiClient.clientSecret}
          />
        ) : null}
      </View>
      <View direction='column' gap='none'>
        {apiClient.isNew ? (
          <>
            <Button disabled={isLoading || disableUpdate} onClick={handleCreate}>
              {t('dictionary.add')}
            </Button>
          </>
        ) : (
          <>
            {apiClient.scimToken !== '' ? (
              <>
                <Separator />
                <ApiClientSecretBox
                  secret={apiClient.scimToken}
                  description={t('manage.api.client-scim-description')}
                  label={t('manage.api.client-scim')}
                />
              </>
            ) : (
              <></>
            )}

            <Separator />
            <View>
              <Button disabled={isLoading} variant='secondary' onClick={() => setAction({ modal: 'revoke' })}>
                {t('manage.api.revoke')}
              </Button>
              <Button disabled={isLoading} variant='secondary' onClick={() => setAction({ modal: 'renew' })}>
                {t('manage.api.generate-new-key')}
              </Button>
              <Button variant='secondary' onClick={() => setAction({ modal: 'scim' })}>
                {t('manage.api.generate-new-scim')}
              </Button>
            </View>
            <Separator />
            <View gap='small'>
              <Button
                variant='destructive'
                onClick={() => setAction({ modal: 'delete' })}
                icon='trash-can'
                decoratorPosition='left'
              >
                {t('admin.delete')}
              </Button>
            </View>
          </>
        )}
      </View>

      <ActionModal
        open={action?.modal === 'delete'}
        onClose={() => setAction(undefined)}
        primaryAction={async () => {
          if (apiClient.clientId === undefined) return
          await deleteApiClient(apiClient.clientId)
          void getGlobalRouter().navigate({ to: '/settings/api' })
        }}
        title={t('manage.api.confirmation.delete-title')}
        primaryActionLabel={t('dictionary.continue')}
        deleteAction
      >
        {t('manage.api.confirmation.delete-description')}
      </ActionModal>
      <ActionModal
        open={action?.modal === 'revoke'}
        onClose={() => setAction(undefined)}
        primaryAction={async () => {
          if (apiClient.clientId === undefined) return
          await updateApiClient(apiClient.clientId, 'revoke')
          setAction(undefined)
        }}
        title={t('manage.api.confirmation.revoke-title')}
        primaryActionLabel={t('dictionary.continue')}
        deleteAction
      >
        {t('manage.api.confirmation.revoke-description')}
      </ActionModal>
      <ActionModal
        open={action?.modal === 'renew'}
        onClose={() => setAction(undefined)}
        primaryAction={async () => {
          if (apiClient.clientId === undefined) return
          await updateApiClient(apiClient.clientId, 'renew')
          setAction(undefined)
        }}
        title={t('manage.api.confirmation.renew-title')}
        primaryActionLabel={t('dictionary.continue')}
        deleteAction
      >
        {t('manage.api.confirmation.renew-description')}
      </ActionModal>

      <ActionModal
        open={action?.modal === 'scim'}
        onClose={() => setAction(undefined)}
        primaryAction={async () => {
          if (apiClient.clientId === undefined) return
          await generateScimToken(apiClient.clientId)
          setAction(undefined)
        }}
        title={t('manage.api.confirmation.scim-title')}
        primaryActionLabel={t('dictionary.continue')}
      >
        {t('manage.api.client-confirm-description')}
      </ActionModal>
    </Container>
  )
}
