import React from 'react'
import { iife } from 'sierra-domain/utils'
import { TruncatedText } from 'sierra-ui/components'
import { palette, spacing } from 'sierra-ui/theming'
import styled, { css } from 'styled-components'

const FontStyles = css`
  font-family: 'JetBrainsMono', 'Monaco', 'Menlo', 'Fira code', 'Fira Mono', monospace;
  color: ${palette.grey['40']};
`
const Container = styled.div`
  font-size: 0.875rem;

  div {
    ${FontStyles}

    &:not(:last-child) {
      margin-bottom: ${spacing['4']};
    }
  }
`
const StyledTruncatedText = styled(TruncatedText)`
  ${FontStyles}
`

function isErrorLike(value: unknown): value is { message: string } {
  if (typeof value !== 'object' || value === null) {
    return false
  }
  if (!('message' in value)) {
    return false
  }
  if (typeof value.message !== 'string') {
    return false
  }
  return true
}

export const ErrorDetails: React.FC<{
  sentryEventId: string | undefined
  error: Error
}> = ({ sentryEventId, error }) => {
  const eventIdText = sentryEventId !== undefined ? `Event ID: ${sentryEventId}` : undefined

  const errorText = iife(() => {
    const base = `${error.name}: ${error.message}`
    if (isErrorLike(error.cause)) {
      return `${base} (${error.cause.message})`
    } else {
      return base
    }
  })

  return (
    <Container>
      {eventIdText !== undefined && <div>{eventIdText}</div>}
      <div>
        <StyledTruncatedText lines={3}>{errorText}</StyledTruncatedText>
      </div>
    </Container>
  )
}
