import { useMutation, useQueryClient } from '@tanstack/react-query'
import { useAtomValue } from 'jotai'
import _ from 'lodash'
import React, { useMemo, useRef, useState } from 'react'
import { SkillId, SkillLevelSettingId } from 'sierra-client/api/graphql/branded-types'
import { graphql } from 'sierra-client/api/graphql/gql'
import {
  SkillSubscriptionInput,
  type GetAssignUserPanelDataQuery,
} from 'sierra-client/api/graphql/gql/graphql'
import { graphQuery, useGraphQuery } from 'sierra-client/api/hooks/use-graphql-query'
import { IdentitiesListContainer, ListItem } from 'sierra-client/components/common/identities-selector/atoms'
import {
  UsersForm,
  type FormData,
} from 'sierra-client/features/skills/components/assign-users/assign-users-form'
import { BulkAssignUsersPanel } from 'sierra-client/features/skills/components/assign-users/bulk-assign-users-panel'
import {
  QUERY_KEY,
  SubscribersTableData,
} from 'sierra-client/features/skills/components/tabular/skill-subscribers-tabular'
import type {
  GroupItem,
  UserItem,
} from 'sierra-client/features/skills/hooks/use-users-and-groups-auto-complete'
import { useUsersAndGroupsAutoComplete } from 'sierra-client/features/skills/hooks/use-users-and-groups-auto-complete'
import { subscribeLearnersMutation } from 'sierra-client/features/skills/shared-gql-queries'
import { useTracking } from 'sierra-client/features/skills/tracking'
import { useTranslation } from 'sierra-client/hooks/use-translation'
import { UseTableAPI } from 'sierra-client/lib/tabular/use-table-api'
import { getSrcFromFileAvatar } from 'sierra-client/views/manage/programs/send-reminder/utils'
import { ListVirtualizer } from 'sierra-client/views/workspace/components/list-virtualizer'
import { isDefined, isNonEmptyArray } from 'sierra-domain/utils'
import { Autocomplete, Layout, Panel, RoundAvatar } from 'sierra-ui/components'
import { PanelInPanel } from 'sierra-ui/components/layout-kit/layout-panel'
import { Heading, LoadingSpinner, Spacer, Text, View } from 'sierra-ui/primitives'
import { token } from 'sierra-ui/theming'
import { height_100dvh } from 'sierra-ui/utils'
import styled from 'styled-components'

type AssignUsersPanelProps = {
  open: boolean
  setOpen: (open: boolean) => void
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  tableAPI: UseTableAPI<SubscribersTableData, any>
}

const StyledListItem = styled(ListItem)`
  justify-content: space-between;
`

const Padding = styled(View).attrs({ direction: 'column', gap: 'none' })`
  padding: 32px 40px 0 40px;
`

const UserItem: React.FC<{ item: UserItem }> = ({ item }) => {
  const nameArray = item.displayName.split(' ')

  return (
    <StyledListItem key={item.id} value={item.id}>
      <View>
        <RoundAvatar
          firstName={nameArray[0]}
          lastName={nameArray[1] ?? undefined}
          color={item.avatar.type === 'color' ? item.avatar.color : undefined}
          src={getSrcFromFileAvatar(item.id, item.avatar)}
        />
        <View direction='column' gap='none'>
          <Text bold>{item.displayName}</Text>
          <Text>{item.email}</Text>
        </View>
      </View>
    </StyledListItem>
  )
}

const GroupItem: React.FC<{ item: GroupItem }> = ({ item }) => {
  const { t } = useTranslation()

  return (
    <StyledListItem key={item.id} value={item.id}>
      <View>
        <RoundAvatar
          firstName={item.displayName}
          color={item.avatar.type === 'color' ? item.avatar.color : undefined}
          src={getSrcFromFileAvatar(item.id, item.avatar)}
        />
        <View direction='column' gap='none'>
          <Text bold>{item.displayName}</Text>
          <Text>{t('skills.members', { count: item.membersCount })}</Text>
        </View>
      </View>
    </StyledListItem>
  )
}

const RenderItem: React.FC<{ item: UserItem | GroupItem }> = ({ item }) => {
  return item.type === 'user' ? <UserItem item={item} /> : <GroupItem item={item} />
}

const ListTitle: React.FC<{ title: string }> = ({ title }) => {
  return (
    <View paddingTop='12' paddingRight='12' paddingLeft='12'>
      <Text size='micro' bold color='foreground/muted'>
        {title}
      </Text>
    </View>
  )
}

const Divider = styled.hr`
  height: 1px;
  background-color: ${token('border/default')};
  margin-left: 12px;
  width: calc(100% - 12px);
`

const VirtualizedMembers: React.FC<{ matching: Array<UserItem | GroupItem> }> = ({ matching }) => {
  const { t } = useTranslation()
  const scrollingRef = useRef<HTMLDivElement>(null)

  const items = useMemo(
    () =>
      _.groupBy(matching, item => item.type) as Record<
        (UserItem | GroupItem)['type'],
        Array<UserItem | GroupItem>
      >,
    [matching]
  )

  return (
    <IdentitiesListContainer ref={scrollingRef}>
      {isNonEmptyArray(items.user) && (
        <>
          <ListTitle title={_.capitalize(t('dictionary.learners'))} />
          <ListVirtualizer
            items={items.user}
            scrollElement={scrollingRef.current}
            estimateSize={55}
            renderItem={item => {
              return <RenderItem item={item} />
            }}
          />
        </>
      )}
      {isNonEmptyArray(items.user) && isNonEmptyArray(items.group) && <Divider />}
      {isNonEmptyArray(items.group) && (
        <>
          <ListTitle title={_.capitalize(t('dictionary.groups'))} />
          <ListVirtualizer
            items={items.group}
            scrollElement={scrollingRef.current}
            estimateSize={55}
            renderItem={item => {
              return <RenderItem item={item} />
            }}
          />
        </>
      )}
    </IdentitiesListContainer>
  )
}

const _AssignUsersPanel: React.FC<
  AssignUsersPanelProps & { skill: NonNullable<GetAssignUserPanelDataQuery['skill']> }
> = ({ open, setOpen, tableAPI, skill }) => {
  const { t } = useTranslation()
  const tracking = useTracking()
  const queryClient = useQueryClient()

  const [bulkEditing, setBulkEditing] = useState<boolean>(false)
  const [bulkTargetLevel, setBulkTargetLevel] = useState<SkillLevelSettingId>()
  const [bulkAchievedLevelUpTo, setBulkAchievedLevelUpTo] = useState<SkillLevelSettingId>()

  const inputRef = useRef<HTMLInputElement>(null)

  const tableData = useAtomValue(tableAPI.api.atoms.tableData)
  const resolvedSkillLevels = useMemo(() => {
    return skill.skillLevels.map(s => ({
      ...s,
      badgeIssuedVia: s.badgeIssuedVia,
    }))
  }, [skill])

  const { query, setQuery, matching, onSelect, onUnSelect, selected, reset } = useUsersAndGroupsAutoComplete(
    tableData,
    open,
    resolvedSkillLevels
  )

  const { mutate: subscribeUsers } = useMutation({
    mutationFn: (input: Array<SkillSubscriptionInput>) => {
      return graphQuery(subscribeLearnersMutation, { subscriptions: input })
    },
    onSuccess: ({ subscribeLearners: successful }, input) => {
      if (successful) {
        input.forEach(learner => {
          tracking.learner.subscribe({ skillId: learner.skillId, learnerId: learner.userId })
          if (learner.skillLevelSettingId) {
            const track = learner.alreadyAchieved
              ? tracking.learner.setAchievedLevel
              : tracking.learner.setTargetLevel
            track({
              skillId: learner.skillId,
              learnerId: learner.userId,
              level: learner.skillLevelSettingId,
            })
          }
        })
        void queryClient.invalidateQueries({ queryKey: QUERY_KEY })
      }
    },
  })

  const getAllAchievedLevels = (skillLevelId: SkillLevelSettingId): (typeof skill)['skillLevels'] => {
    const achievedLevelTo = skill.skillLevels.find(level => level.levelSetting.id === skillLevelId)
    if (!isDefined(achievedLevelTo)) {
      return []
    }
    return skill.skillLevels.filter(level => level.levelSetting.index <= achievedLevelTo.levelSetting.index)
  }

  const onSubscribe = (formData: FormData): void => {
    // for all users, for each achieved level, subscribe
    const subscribePayload: Array<SkillSubscriptionInput> = formData.learners.flatMap(learner => {
      const levels = learner.achievedLevelsUpTo ? getAllAchievedLevels(learner.achievedLevelsUpTo) : []
      return levels
        .map(level => ({
          skillId: SkillId.parse(skill.id),
          userId: learner.id,
          alreadyAchieved: true,
          skillLevelSettingId: level.levelSetting.id,
        }))
        .concat(
          isDefined(learner.targetLevel)
            ? [
                {
                  skillId: SkillId.parse(skill.id),
                  userId: learner.id,
                  alreadyAchieved: false,
                  skillLevelSettingId: SkillLevelSettingId.parse(learner.targetLevel),
                },
              ]
            : []
        )
    })

    if (isNonEmptyArray(subscribePayload)) {
      subscribeUsers(subscribePayload)
    }

    setOpen(false)
    reset()
  }

  const onClose = (): void => {
    setOpen(false)
    reset()
  }

  const focusInput = (): void => {
    if (inputRef.current !== null) {
      inputRef.current.focus()
    }
  }

  return (
    <Layout
      height='100%'
      panels={[
        <PanelInPanel
          key='bulk-assign-skill-users'
          open={bulkEditing}
          onEscapeKeyDown={() => {
            setBulkEditing(false)
          }}
        >
          <BulkAssignUsersPanel
            levels={resolvedSkillLevels}
            setBulkEditing={setBulkEditing}
            setTargetLevel={setBulkTargetLevel}
            setAchievedLevel={levelId => {
              setBulkAchievedLevelUpTo(levelId)
            }}
          />
        </PanelInPanel>,
      ]}
    >
      <Padding>
        <Heading bold size='h5'>
          {t('manage.skills.enroll-learners.add-users-to', { skillName: skill.name })}
        </Heading>
        <Spacer size='32' />
        <Autocomplete
          ref={inputRef}
          query={query}
          onQueryChange={setQuery}
          matchingItems={matching}
          onSelect={onSelect}
          renderMatchingItemList={() => <VirtualizedMembers matching={matching} />}
          placeholder={t('manage.skills.enroll-learners.search-placeholder')}
        />
      </Padding>
      <UsersForm
        setOpenBulkEdit={setBulkEditing}
        selected={selected}
        levels={skill.skillLevels}
        onSubmit={onSubscribe}
        onClose={onClose}
        onRemoveItem={onUnSelect}
        bulkTargetLevel={bulkTargetLevel}
        bulkAchievedLevelUpTo={bulkAchievedLevelUpTo}
        setBulkTargetLevel={setBulkTargetLevel}
        setBulkAchievedLevelUpTo={setBulkAchievedLevelUpTo}
        focusInput={focusInput}
      />
    </Layout>
  )
}

const CenteredContainer = styled.div`
  display: flex;
  justify-content: center;
  width: 100%;
  ${height_100dvh};
`

const getAssignUserPanelDataQuery = graphql(`
  query GetAssignUserPanelData($id: SkillId!) {
    skill(id: $id) {
      id
      name
      skillLevels {
        id
        description
        levelSetting {
          id
          index
          name
          defaultBadgeTheme
          createdAt
          updatedAt
        }
        badgeIssuedVia {
          ...BadgeIssuedVia
        }
      }
    }
  }
`)

export const AssignUsersPanel: React.FC<AssignUsersPanelProps & { skillId: SkillId }> = ({
  open,
  setOpen,
  tableAPI,
  skillId,
}) => {
  const query = useGraphQuery({ document: getAssignUserPanelDataQuery }, { id: skillId })
  const skill = query.data?.skill

  return (
    <Panel
      size={{ width: 650 }}
      animation='slide-right'
      onClose={() => {
        setOpen(false)
      }}
      open={open}
    >
      {open && isDefined(skill) ? (
        <_AssignUsersPanel open={open} setOpen={setOpen} tableAPI={tableAPI} skill={skill} />
      ) : (
        <CenteredContainer>
          <LoadingSpinner />
        </CenteredContainer>
      )}
    </Panel>
  )
}
