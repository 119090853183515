import React from 'react'
import { ContentTableRootView } from 'sierra-client/components/common/content-table-row'
import { useLocalizedFormatters } from 'sierra-client/core/format'
import { useTranslation } from 'sierra-client/hooks/use-translation'
import { useIsMobile } from 'sierra-client/state/browser/selectors'
import { useSelector } from 'sierra-client/state/hooks'
import { selectUserId } from 'sierra-client/state/user/user-selector'
import { useUserLegacy } from 'sierra-client/state/users/hooks'
import { UserId } from 'sierra-domain/api/uuid'
import { getUserName } from 'sierra-domain/utils'
import { TruncatedText } from 'sierra-ui/components'
import { Text, TextProps, View } from 'sierra-ui/primitives'
import { token } from 'sierra-ui/theming'
import { breakpoints, v2_breakpoint } from 'sierra-ui/theming/breakpoints'
import { narrowDotSeparator } from 'sierra-ui/utils'
import styled, { css } from 'styled-components'

export const OpenCtaContainer = styled(View)<{ showContainer: boolean }>`
  opacity: 0;
  transition: opacity 100ms cubic-bezier(0.25, 0.1, 0.25, 1);
  ${props =>
    props.showContainer &&
    css`
      opacity: 1;
    `}
`

export const MetadataWrapper = styled(View)`
  color: ${token('foreground/muted')};
  transition: color 100ms cubic-bezier(0.25, 0.1, 0.25, 1);

  & > *:not(:first-child)::before {
    content: '${narrowDotSeparator}';
    padding-inline-end: 6px;
    color: ${token('foreground/muted')};
    font-weight: bold;
  }
`

export const TruncatedAutoWidth = styled(TruncatedText)`
  width: auto;
`

const NoWrapText = styled(Text)`
  text-wrap: nowrap;
`

export const CoursePublishStateLabel: React.FC<{
  published: boolean
  pending: boolean
}> = ({ published, pending }) => {
  const { t } = useTranslation()

  const labelText = (() => {
    if (published) {
      if (pending) {
        return t('admin.author.pending')
      } else {
        return t('admin.author.published')
      }
    } else {
      return t('admin.author.draft')
    }
  })()

  return (
    <NoWrapText size='small' bold color='foreground/muted'>
      {labelText}
    </NoWrapText>
  )
}

export const RowContainer = styled.div<{
  isOver: boolean
  isDragging: boolean
  isDroppable: boolean
}>`
  position: relative;

  ${p =>
    p.isDragging &&
    css`
      pointer-events: none;
    `}
  ${p =>
    p.isOver === true &&
    p.isDroppable === true &&
    css`
      &::before {
        content: '';
        border-radius: 10px;
        box-shadow: 0px 0px 0px 2px ${p.theme.color.blueVivid};
        position: absolute;
        width: 100%;
        height: 100%;
        padding-inline: 10px;
        margin-left: -5px;
      }
    `} @media (
  min-width: ${v2_breakpoint.phone}) {
    ${ContentTableRootView}:hover {
      ${p =>
        p.isOver !== true &&
        css`
          ${OpenCtaContainer} {
            opacity: 1;
          }

          ${MetadataWrapper} {
            color: ${token('foreground/primary')(p)};
          }
        `}
    }

    &:hover {
      ${MetadataWrapper} ${Text} {
        color: ${token('foreground/primary')};
      }

      ${MetadataWrapper} ${TruncatedAutoWidth} {
        color: ${token('foreground/primary')};
      }
    }
  }

  @media (min-width: ${breakpoints.tablet}) {
    margin: 0;
  }
`

export const LastUpdateLabel: React.FC<{
  lastEditorId: UserId | undefined
  updatedAt: Date | undefined
  color?: 'foreground/muted' | 'foreground/primary' | 'foreground/secondary'
  textSize?: TextProps['size']
}> = ({ updatedAt, lastEditorId, color = 'foreground/muted', textSize = 'small' }) => {
  const { t } = useTranslation()
  const { formatTimeAgo } = useLocalizedFormatters()
  const userId = useSelector(selectUserId)
  const lastEditor = useUserLegacy(lastEditorId)

  const isNotMobile = !useIsMobile()

  const text = `${t('dictionary.edited')} ${updatedAt ? formatTimeAgo(updatedAt.getTime()).toString() : ''}
      ${
        lastEditorId !== undefined && isNotMobile
          ? `
    ${t('dictionary.by')} ${userId === lastEditorId ? t('dictionary.you') : getUserName(lastEditor)}
    `
          : ''
      }
      `

  return (
    <TruncatedAutoWidth size={textSize} color={color}>
      {text}
    </TruncatedAutoWidth>
  )
}
