import { useAtom, useAtomValue } from 'jotai/index'
import { useEffect, useMemo } from 'react'
import { prefetchCachedQuery, useCachedQuery } from 'sierra-client/state/api'
import { userInvitationDomainsAtom } from 'sierra-client/views/manage/components/user-attributes/atoms'
import {
  extractUserAccessLevelDomain,
  extractUserAccessRoleDomain,
  extractUserCustomAttributeDomains,
  extractUserGroupsDomain,
  extractUserManagerDomain,
  extractUserPhoneNumberDomain,
  extractUserProgramsDomain,
} from 'sierra-client/views/manage/components/user-attributes/utils'
import { XRealtimeAdminEmailUserInvitationDomain } from 'sierra-domain/routes'
import {
  UserAccessLevelDomainRep,
  UserAccessRoleDomainRep,
  UserCustomAttributeDomainRep,
  UserGroupsDomainRep,
  UserManagerDomainRep,
  UserPhoneNumberDomainRep,
  UserProgramsDomainRep,
} from 'sierra-domain/user-attributes/user-invitation-domain-rep'

const staleTime = 5 * 60 * 1000 // 5 minutes

export const prefetchUserInvitationDomain = (): Promise<void> => {
  return prefetchCachedQuery(XRealtimeAdminEmailUserInvitationDomain, {}, { staleTime })
}

export const useUserInvitationDomainLoader = (): {
  isLoading: boolean
} => {
  const [invitationDomain, setUserInvitationDomain] = useAtom(userInvitationDomainsAtom)

  const { data, isLoading } = useCachedQuery(XRealtimeAdminEmailUserInvitationDomain, {}, { staleTime })
  useEffect(() => setUserInvitationDomain(data), [data, setUserInvitationDomain])

  return { isLoading: isLoading || invitationDomain === undefined }
}

type InvitationDomainParts = {
  accessLevelDomain: UserAccessLevelDomainRep
  accessRoleDomain: UserAccessRoleDomainRep | undefined
  groupsDomain: UserGroupsDomainRep
  programsDomain: UserProgramsDomainRep
  managerDomain: UserManagerDomainRep | undefined
  customAttributeDomains: UserCustomAttributeDomainRep[]
  phoneNumberDomain: UserPhoneNumberDomainRep | undefined
}

export const useInvitationDomains = (): InvitationDomainParts => {
  const userInvitationDomain = useAtomValue(userInvitationDomainsAtom)

  if (userInvitationDomain === undefined) {
    throw new Error(
      'User invitation domain should not be undefined. Did you call useUserInvitationDomainLoader?'
    )
  }

  const accessLevelDomain = useMemo(() => {
    const extractedAccessLevelDomain = extractUserAccessLevelDomain(userInvitationDomain)

    if (extractedAccessLevelDomain === undefined) {
      throw new Error('Could not find user access level in user invitation domain.')
    }

    return extractedAccessLevelDomain
  }, [userInvitationDomain])

  const accessRoleDomain = useMemo(
    () => extractUserAccessRoleDomain(userInvitationDomain),
    [userInvitationDomain]
  )

  const groupsDomain = useMemo(() => {
    const extractedGroupsDomain = extractUserGroupsDomain(userInvitationDomain)

    if (extractedGroupsDomain === undefined) {
      throw new Error('Could not find user groups in user invitation domain.')
    }

    return extractedGroupsDomain
  }, [userInvitationDomain])

  const programsDomain = useMemo(() => {
    const extractedProgramsDomain = extractUserProgramsDomain(userInvitationDomain)

    if (extractedProgramsDomain === undefined) {
      throw new Error('Could not find user programs in user invitation domain.')
    }

    return extractedProgramsDomain
  }, [userInvitationDomain])

  const phoneNumberDomain = useMemo(() => {
    return extractUserPhoneNumberDomain(userInvitationDomain)
  }, [userInvitationDomain])

  /*
   * The manager attribute is optional to cover the use case where a group-admin tries to set a manager for a user.
   * In this case the domain will be undefined and the AttributeRow will be hidden.
   */
  const managerDomain = useMemo(() => extractUserManagerDomain(userInvitationDomain), [userInvitationDomain])

  const customAttributeDomains = useMemo(() => {
    const extractedCustomAttributeDomains = extractUserCustomAttributeDomains(userInvitationDomain)
    return extractedCustomAttributeDomains
  }, [userInvitationDomain])

  return {
    accessLevelDomain,
    accessRoleDomain,
    groupsDomain,
    programsDomain,
    managerDomain,
    customAttributeDomains,
    phoneNumberDomain,
  }
}
