import { Nanoid12 } from 'sierra-domain/flexible-content/identifiers'
import { z } from 'zod'

const TemplateCategories = z.enum([
  'ready-made-courses',
  'onboarding',
  'workshops',
  'leadership-development',
  'compliance',
  'sales-enablement',
  'sana-onboarding',
  'product-management',
])

const TemplateDataObject = z.object({
  id: Nanoid12,
  contentType: z.enum(['native:self-paced', 'native:live']),
  key: z.string(),
  label: z.string(),
  description: z.string(),
  categories: z.array(TemplateCategories),
  author: z.string().optional(),
})

export const TemplateData = z.array(TemplateDataObject)
export type TemplateData = z.infer<typeof TemplateData>
