import React from 'react'
import {
  AdornmentRenderer,
  DueDatePill,
  SessionPill,
} from 'sierra-client/components/recommendations/components/card-context/common'
import {
  CardInfo,
  RecommendationCard,
} from 'sierra-client/components/recommendations/components/card-context/types'
import { useTranslation } from 'sierra-client/hooks/use-translation'
import { assertNever } from 'sierra-domain/utils'
import { View } from 'sierra-ui/primitives'

const useDefaultCardInfo = (card: RecommendationCard): CardInfo => {
  const { t } = useTranslation()

  switch (card.type) {
    case 'popular':
      return { text: t('dictionary.popular') }
    case 'upcoming-session':
      return {
        text: t('recommendation.upcoming-session'),
        adornment: {
          type: 'pill',
          component: <SessionPill startDateISO={card.entity.start} />,
        },
      }
    case 'ongoing-session':
      return {
        text: t('recommendation.live-now'),
        adornment: {
          type: 'pill',
          component: <SessionPill startDateISO={card.entity.start} />,
        },
      }
    case 'upcoming-due-date':
      return {
        text: t('recommendation.upcoming-due-date'),
        adornment: {
          type: 'pill',
          component: <DueDatePill dueISO={card.entity.learnerContext.assignment?.dueDate} />,
        },
      }
    case 'overdue':
      return {
        text: t('recommendation.overdue'),
        adornment: {
          type: 'pill',
          component: <DueDatePill dueISO={card.entity.learnerContext.assignment?.dueDate} />,
        },
      }
    case 'recently-assigned':
      return { text: t('recommendation.recently-assigned') }
    case 'discover':
      return { text: t('recommendation.recommended-for-you') }
    case 'recently-edited':
      return {
        text: t('recommendation.recently-edited'),
        adornment: { type: 'icon', iconId: 'edit' },
      }
    case 'continue-editing':
      return {
        text: t('recommendation.continue-editing'),
        adornment: { type: 'icon', iconId: 'edit' },
      }
    case 'create-suggestion':
      return {
        text: t('recommendation.try-creating'),
        adornment: { type: 'icon', iconId: 'edit' },
      }
    case 'assigned':
      return {
        text: t('learner-home.assigned-to-you'),
      }
    case 'recap':
      return { text: t('learner-home.view-recap') }
    case 'self-enroll-to-session':
      return {
        text: t('recommendation.multiple-sessions'),
        adornment: { type: 'icon', iconId: 'play--circle--filled' },
      }
    case 'added-as-collaborator':
      return {
        text: t('recommendation.added-as-collaborator'),
        adornment: { type: 'icon', iconId: 'edit' },
      }
    case 'in-progress':
      return { text: t('recommendation.in-progress') }
    case 'featured':
      return {
        text: t('recommendation.featured'),
        adornment: { type: 'icon', iconId: 'bullhorn' },
      }
    case 'invited-to-collaborate':
    case 'others-are-learning':
    case 'new-comments':
      return { text: '' } // ??
    default:
      assertNever(card)
  }
}

export const DefaultCardContext: React.FC<{
  card: RecommendationCard
}> = ({ card }) => {
  const cardInfo = useDefaultCardInfo(card)

  return (
    <View>
      <AdornmentRenderer cardInfo={cardInfo} />
    </View>
  )
}
