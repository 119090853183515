import { InlineCompletion } from 'sierra-client/editor/inline-completion'
import { getLineNumber } from 'sierra-client/editor/syntax-highlighting'
import * as Components from 'sierra-client/views/v3-author/components'
import { color } from 'sierra-ui/color'
import { spacing } from 'sierra-ui/theming'
import { RenderLeafProps } from 'slate-react'
import styled, { css } from 'styled-components'

function syntaxHighlightingClasses(leaf: Record<string, unknown>): string {
  const codeSyntaxClasses = Object.keys(leaf).filter(
    it => it !== 'text' && it !== 'bold' && it !== 'italic' && it !== 'underline'
  )
  return codeSyntaxClasses.length > 0 ? ['token', ...codeSyntaxClasses].join(' ') : ''
}

const LeafSpan = styled.span<{ $isEmpty: boolean; $color: string | undefined }>`
  &[data-line-number] {
    /* Support line numbers when the "data-line-number" attribute is set */
    &:before {
      position: absolute;
      left: -${spacing[8]};
      content: attr(data-line-number);
      width: 20px;
      z-index: 1;
      text-align: right;
      color: ${color('grey20').toString()};
      user-select: none;
      margin-right: ${spacing[12]};
    }
  }

  transition: color 100ms;
  ${p => p.$color !== undefined && `color: ${p.$color};`}

  /**
   * The following is a workaround for a Chromium bug where,
   * if you have an inline at the end of a block,
   * clicking the end of a block puts the cursor inside the inline
   * instead of inside the final {text: ''} node
   * https://github.com/ianstormtaylor/slate/issues/4704#issuecomment-1006696364
   */
  ${p =>
    p.$isEmpty &&
    css`
      padding-left: 0.1px;
    `}
`

export const RenderLeafInner = ({ attributes, children, leaf, text }: RenderLeafProps): JSX.Element => {
  if (leaf.bold ?? false) children = <Components.Bold>{children}</Components.Bold>
  if (leaf.code ?? false) children = <code>{children}</code>
  if (leaf.italic ?? false) children = <Components.Italic>{children}</Components.Italic>
  if (leaf.underline ?? false) children = <Components.Underline>{children}</Components.Underline>
  if (leaf.strikethrough ?? false) children = <Components.Strikethrough>{children}</Components.Strikethrough>
  if (leaf.subscript ?? false) children = <Components.Subscript>{children}</Components.Subscript>
  if (leaf.supscript ?? false) children = <Components.Supscript>{children}</Components.Supscript>

  const lineNumber = getLineNumber(leaf)
  const lineNumberAttribute = lineNumber !== undefined ? { 'data-line-number': lineNumber } : {}

  return (
    <LeafSpan
      $isEmpty={leaf.text === ''}
      $color={leaf.color}
      {...attributes}
      {...lineNumberAttribute}
      className={syntaxHighlightingClasses(leaf)}
    >
      {children}
      <InlineCompletion leaf={text} />
    </LeafSpan>
  )
}

export const renderLeaf = (props: RenderLeafProps): JSX.Element => <RenderLeafInner {...props} />
