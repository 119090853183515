import React, { useCallback, useEffect, useMemo, useState } from 'react'
import { resolveUserAvatar } from 'sierra-client/api/content'
import { Identity } from 'sierra-client/components/common/identities-selector'
import { useAdminIdentitiesFetcher } from 'sierra-client/components/common/identities-selector/identity-fetchers'
import { useNotif } from 'sierra-client/components/common/notifications'
import { getFlag } from 'sierra-client/config/global-config'
import { useDeepEqualityMemo } from 'sierra-client/hooks/use-deep-equality-memo'
import { usePost } from 'sierra-client/hooks/use-post'
import { useResolveAsset } from 'sierra-client/hooks/use-resolve-asset'
import { useTranslation } from 'sierra-client/hooks/use-translation'
import { postWithUserErrorCode, typedPost } from 'sierra-client/state/api'
import { useDispatch } from 'sierra-client/state/hooks'
import { useUsersLegacy } from 'sierra-client/state/users/hooks'
import { useInvalidateProgramMembersDataloader } from 'sierra-client/views/manage/programs/components/program-users-table'
import { EnrollModalComponents } from 'sierra-client/views/manage/programs/enrollment-rules/modals/common'
import { useStartDateSelector } from 'sierra-client/views/manage/programs/enrollment-rules/modals/start-date-selector'
import { UserPreviewPanel } from 'sierra-client/views/manage/programs/enrollment-rules/modals/user-preview/user-preview-panel'
import { UserPreviewConfig } from 'sierra-client/views/manage/programs/enrollment-rules/modals/user-preview/user-preview-table'
import { EnrollmentStartTime, IdentityRef } from 'sierra-domain/api/manage'
import { ProgramId, UserId } from 'sierra-domain/api/uuid'
import { ImageUnion } from 'sierra-domain/content/v2/content'
import { isLeft } from 'sierra-domain/either'
import {
  XRealtimeAdminProgramsEnrollUsers,
  XRealtimeAdminProgramsPreviewEnrollUsers,
  XRealtimeAdminProgramsPreviewEnrollUsersCount,
} from 'sierra-domain/routes'
import { isDefined } from 'sierra-domain/utils'
import { AvatarStackUserShape, Layout } from 'sierra-ui/components'
import { PanelInPanelOverlay } from 'sierra-ui/components/layout-kit/layout-panel'
import { MatchingUsers } from 'sierra-ui/missions/workflows/panels/components/matching-users'
import { Button, Spacer, Switch, View } from 'sierra-ui/primitives'

const { ProgramHeader, Content, EnrollModal, Footer, StartDateSection, Line, IdentitySection } =
  EnrollModalComponents

type IdentityPreviewProps = {
  time: EnrollmentStartTime
  programGroupId: string
  identityRefs: IdentityRef[]
}

const useIdentityPreviews = ({
  time,
  programGroupId,
  identityRefs: identityRefsInput,
}: IdentityPreviewProps): {
  count: number
  previewUsers: AvatarStackUserShape[]
} => {
  const [users, setUsers] = useState<UserId[]>([])
  const [count, setCount] = useState<number>(0)
  const { postWithUserErrorException } = usePost()
  const resolvedUsers = useUsersLegacy(users).filter(isDefined).map(resolveUserAvatar)
  const identityRefs = useDeepEqualityMemo(identityRefsInput)

  useEffect(() => {
    void (async () => {
      const previewUsers = await postWithUserErrorException(XRealtimeAdminProgramsPreviewEnrollUsers, {
        time,
        programGroupId,
        identities: identityRefs,
      })

      const newCount = await typedPost(XRealtimeAdminProgramsPreviewEnrollUsersCount, {
        identities: identityRefs,
      })

      setUsers(previewUsers.users.map(u => u.userId))
      setCount(newCount.count)
    })()
  }, [postWithUserErrorException, time, programGroupId, identityRefs])

  return {
    previewUsers: resolvedUsers,
    count,
  }
}

export const EnrollOnceModal: React.FC<{
  programId: ProgramId
  image?: ImageUnion
  title?: string
  open: boolean
  onClose: () => void
  onSave: () => Promise<void>
}> = ({ programId, title, image, open, onClose, onSave }) => {
  const { t, dynamicT } = useTranslation()
  const notifications = useNotif()
  const dispatch = useDispatch()
  const [userPreviewOpen, setUserPreviewOpen] = useState(false)
  const startDateProps = useStartDateSelector(undefined)
  const { resetStartDate, startDate: time } = startDateProps
  const [isRequired, setIsRequired] = useState(false)
  const [identities, setIdentities] = useState<Identity[]>([])
  const identityRefs = useDeepEqualityMemo(identities.map(i => i.identity))

  const invalidateProgramMembersTable = useInvalidateProgramMembersDataloader()

  const fetchIdentities = useAdminIdentitiesFetcher({ withTypes: ['user', 'user-group'] })

  const onSelect = useCallback((identity: Identity) => {
    setIdentities(identities => [...identities, identity])
  }, [])

  const onUnselect = useCallback((identity: Identity) => {
    setIdentities(identities => identities.filter(i => i.id !== identity.id))
  }, [])

  const { previewUsers, count } = useIdentityPreviews({
    time,
    identityRefs: identities.map(i => i.identity),
    programGroupId: programId,
  })

  const reset = useCallback(() => {
    onClose() // close modal
    resetStartDate() // reset start date
    setIdentities([]) // remove all identities
  }, [onClose, resetStartDate, setIdentities])

  const save = async (): Promise<void> => {
    const newIdentities = identities.map(identity => identity.identity)

    try {
      const result = await postWithUserErrorCode(
        XRealtimeAdminProgramsEnrollUsers, // This should handle enrollment-rule/duplicate-name
        {
          programGroupId: programId,
          identities: newIdentities,
          time,
          isRequiredAssignment: isRequired,
        },
        dispatch,
        {
          notifications: true,
        }
      )

      await onSave()

      if (isLeft(result)) {
        notifications.push({
          type: 'custom',
          body: `An unknown error occurred while enrolling: ${result.left}.`,
          level: 'error',
        })
      } else {
        reset()
        notifications.push({
          type: 'custom',
          body: dynamicT(
            newIdentities.length === 1
              ? 'manage.programs.enrollment-rules.notifications.once-creation-success_one'
              : 'manage.programs.enrollment-rules.notifications.once-creation-success_other'
          ),
          level: 'success',
        })

        void invalidateProgramMembersTable()
      }
    } catch (e) {
      notifications.push({
        type: 'custom',
        body: dynamicT(
          newIdentities.length === 1
            ? 'manage.programs.enrollment-rules.notifications.once-creation-failed_one'
            : 'manage.programs.enrollment-rules.notifications.once-creation-failed_other'
        ),
        level: 'error',
      })
    }
  }

  const userPreviewConfig = useMemo<UserPreviewConfig>(
    () => ({
      type: 'once',
      time,
      programGroupId: programId,
      identities: identityRefs,
    }),
    [time, programId, identityRefs]
  )

  const imageSrc = useResolveAsset({
    image,
    assetContext: { type: 'program', programId },
    size: 'admin',
  })

  const isSaveDisabled = identities.length === 0
  const background = image !== undefined ? imageSrc : undefined

  return (
    <EnrollModal open={open} onClose={reset}>
      <Layout
        panels={[
          <UserPreviewPanel
            key='right'
            previewConfig={userPreviewConfig}
            count={count}
            onClose={() => setUserPreviewOpen(false)}
            open={userPreviewOpen}
          />,
        ]}
      >
        {userPreviewOpen && <PanelInPanelOverlay onClick={() => setUserPreviewOpen(false)} />}
        {title !== undefined && (
          <ProgramHeader background={background} onClose={onClose}>
            {t('manage.programs.enrollment-rules.enroll-in', { title })}
          </ProgramHeader>
        )}
        <Content>
          <IdentitySection
            selectedIdentities={identities}
            onSelect={onSelect}
            onUnselect={onUnselect}
            fetchIdentities={fetchIdentities}
            placeholder='manage.programs.enrollment-rules.add-users-or-groups'
          />

          <Line />
          <StartDateSection {...startDateProps} />
          <Line />
          {getFlag('required-assignments') && (
            <>
              <Switch
                checked={isRequired}
                onChange={val => setIsRequired(val)}
                text={t('dictionary.required')}
              />
              <Line />
            </>
          )}
          <MatchingUsers
            users={previewUsers}
            label={t('manage.programs.enrollment-rules.matching-users', {
              count,
            })}
            previewLabel={t('manage.programs.enrollment-rules.preview')}
            onPreviewClick={() => setUserPreviewOpen(true)}
          />
        </Content>
        <Footer>
          <Spacer size='none' />
          <View direction='row' alignItems='center' justifyContent='flex-end'>
            <Button variant='ghost' onClick={reset}>
              {t('modal.cancel')}
            </Button>
            <Button variant='secondary' onClick={save} disabled={isSaveDisabled}>
              {t('manage.programs.enrollment-rules.enroll')}
            </Button>
          </View>
        </Footer>
      </Layout>
    </EnrollModal>
  )
}
