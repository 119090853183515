import { useState } from 'react'
import { RouterLink } from 'sierra-client/components/common/link'
import { useTimeFormatter } from 'sierra-client/core/format'
import {
  MetadataWithIcon,
  NotificationContainer,
  Title,
} from 'sierra-client/features/activity-center/notifications/components'
import { ContentContainer } from 'sierra-client/features/activity-center/notifications/components/content-card'
import { EntityImage } from 'sierra-client/features/activity-center/notifications/components/entity-image'
import { NotificationIcon } from 'sierra-client/features/activity-center/notifications/components/notification-icon'
import { NotificationTitle } from 'sierra-client/features/activity-center/notifications/components/notification-title'
import { convertLearnEntityToLinkable } from 'sierra-client/views/workspace/utils/entity-to-linkable'
import { detailsUrl } from 'sierra-client/views/workspace/utils/urls'
import { entityDescription } from 'sierra-domain/api/entities'
import { HomeNotification } from 'sierra-domain/api/learn'
import { assertIsNonNullable } from 'sierra-domain/utils'
import { TruncatedText } from 'sierra-ui/components'
import { Button, Text, View } from 'sierra-ui/primitives'
import { dotSeparator } from 'sierra-ui/utils'

export const UserAssignedNotification: React.FC<{
  notification: Extract<HomeNotification, { type: 'user-assigned' }>
}> = ({ notification }) => {
  const { timeFormatter } = useTimeFormatter()
  const [isOpen, setIsOpen] = useState(false)
  if (notification.assignments.length === 0) return null
  else if (notification.assignments.length > 1) {
    const numCollapsed = 3
    const slicedAssignments = isOpen
      ? notification.assignments
      : notification.assignments.slice(0, numCollapsed)
    const canExpand = notification.assignments.length > numCollapsed

    return (
      <>
        <NotificationContainer
          notification={notification}
          icon={<NotificationIcon iconId='course' />}
          title={
            <NotificationTitle
              titleKey='notification.new-assignments'
              values={{ count: notification.assignments.length }}
            />
          }
          content={
            <ContentContainer>
              {slicedAssignments.map(({ entity }) => (
                <View key={entity.id} direction='row' gap='8'>
                  <EntityImage entity={entity} size='small' />
                  <RouterLink href={detailsUrl(convertLearnEntityToLinkable(entity))}>
                    <Title size='small' bold>
                      {entity.title}
                    </Title>
                  </RouterLink>
                  {entity.learnerContext.assignment?.dueDate !== undefined && (
                    <>
                      <Text color='foreground/muted'>{dotSeparator}</Text>
                      <Text size='small' color='foreground/secondary'>
                        Due {entity.learnerContext.assignment.dueDate}
                      </Text>
                    </>
                  )}
                </View>
              ))}
              {canExpand && (
                <Button variant='transparent' onClick={() => setIsOpen(!isOpen)}>
                  {isOpen ? 'Show less' : `+ ${notification.assignments.length - numCollapsed} more`}
                </Button>
              )}
            </ContentContainer>
          }
        />
      </>
    )
  } else {
    const entity = notification.assignments[0]?.entity
    assertIsNonNullable(entity)

    const linkable = convertLearnEntityToLinkable(entity)
    const url = detailsUrl(linkable)

    return (
      <NotificationContainer
        notification={notification}
        icon={<NotificationIcon iconId='course' />}
        title={<NotificationTitle untranslated='New assignment' />}
        cardUrl={url}
        content={
          <View alignItems='flex-start'>
            <EntityImage entity={entity} />
            <View direction='column' gap='none'>
              <Title size='regular' bold>
                {entity.title}
              </Title>
              <MetadataWithIcon type='course' subtitle={timeFormatter(entity.timeEstimate)} />

              <TruncatedText lines={2} size='small' color='foreground/secondary'>
                {entityDescription(entity) ?? ''}
              </TruncatedText>
            </View>
          </View>
        }
      />
    )
  }
}
