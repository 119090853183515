import {
  PreviewUrlContainer,
  PreviewUrlContainerProps,
} from 'sierra-client/views/manage/certificates/preview-url-container'
import { Modal } from 'sierra-ui/components'
import styled from 'styled-components'

type CertificatePreviewModalUrlProps = PreviewUrlContainerProps & { open: boolean; onClose: () => void }

const StyledModal = styled(Modal)`
  border-radius: ${p => p.theme.borderRadius['size-8']};
  background: transparent;
  overflow: hidden;
`

export const CertificatePreviewUrlModal: React.FC<CertificatePreviewModalUrlProps> = ({
  open,
  onClose,
  ...props
}) => {
  return (
    <StyledModal open={open} onClose={onClose} size='fit-content'>
      <PreviewUrlContainer {...props} onClick={onClose} />
    </StyledModal>
  )
}
