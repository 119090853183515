import { TranslationKey, isTranslationKey } from 'sierra-client/hooks/use-translation/types'
import { TabularLabel, translatedLabel } from 'sierra-client/lib/tabular/datatype/label'
import { MeasureArray, ViewRef, Widget } from 'sierra-domain/api/insights'
import { Label as FilterLabel } from 'sierra-domain/filter/datatype/label'
import { assertNever } from 'sierra-domain/utils'
import { IconId } from 'sierra-ui/components'

export const getMeasuresFromWidget = (widget?: Widget): MeasureArray | undefined => {
  if (widget === undefined) return undefined
  if ('measures' in widget) return widget.measures
  else return undefined
}

export const getViewFromWidget = (widget?: Widget): ViewRef | undefined => {
  if (widget === undefined) return undefined
  if ('view' in widget) return widget.view
  else return undefined
}

export const getWidgetIcon = (widgetType: Widget['type']): IconId => {
  switch (widgetType) {
    case 'widget.bar-chart':
      return 'chart--column'
    case 'widget.line-chart':
      return 'trend--up'
    case 'widget.progress-bar':
      return 'gauge'
    case 'widget.aggregation-table':
      return 'table'
    case 'widget.metric':
      return 'hashtag'
    case 'widget.pivot-table':
      return 'table--split'
    case 'widget.list-table':
      return 'list--bulleted'
    default:
      assertNever(widgetType)
  }
}

export const toLabel = (label: FilterLabel): TabularLabel => {
  switch (label.type) {
    case 'label.default':
      return { type: 'untranslated', label: label.label }
    case 'label.translation': {
      const { translationKey } = label
      if (isTranslationKey(translationKey)) return translatedLabel(translationKey as TranslationKey)
      else return { type: 'untranslated', label: label.defaultLabel }
    }
    case 'label.concatenation': {
      const first = toLabel(label.first)
      const second = toLabel(label.second)
      return { type: 'concatenated', label1: first, label2: second, separator: label.separator }
    }
  }
}
