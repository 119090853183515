import React, { useEffect, useRef } from 'react'
import { VideoTrackWithOwner } from 'sierra-client/components/liveV2/hooks/use-participant-video'
import { useParticipantsVideoPriority } from 'sierra-client/components/liveV2/participant-provider'
import { useTranslation } from 'sierra-client/hooks/use-translation'
import styled from 'styled-components'

const MediaPlayerContainer = styled.div<{ $pipActiveContent: string }>`
  width: 100%;
  height: 100%;
  border-radius: 8px;
  overflow: hidden;

  video {
    width: 100%;
    display: block;
    &:picture-in-picture {
      opacity: 0;
    }
  }

  /* Specific picture in picture overlay */
  &:has(video:picture-in-picture) {
    &::before {
      color: #ddd;
      content: ${p => `'${p.$pipActiveContent}'`};
      position: absolute;
      width: 75%;
      text-align: center;
      top: 50%;
      left: 50%;
      transform: translate(-50%, -50%);
    }
  }

  div {
    background: none !important;
  }
`

export const MediaPlayer: React.FC<{ video: VideoTrackWithOwner }> = ({ video }) => {
  const container = useRef<HTMLDivElement>(null)
  const { setVideoIsPlaying } = useParticipantsVideoPriority()
  const { t } = useTranslation()

  useEffect(() => {
    if (!container.current) return

    setVideoIsPlaying(video.uid, video.ownerId, true)
    video.videoTrack.play(container.current)

    return () => {
      setVideoIsPlaying(video.uid, video.ownerId, false)
      video.videoTrack.stop()
    }
  }, [container, setVideoIsPlaying, video])

  return (
    <MediaPlayerContainer
      $pipActiveContent={t('live.return-from-picture-in-picture')}
      ref={container}
    ></MediaPlayerContainer>
  )
}
