import { isFile } from 'sierra-client/state/flexible-content/types'
import { Node } from 'sierra-domain/flexible-content/types'
import { assertNever } from 'sierra-domain/utils'

export function isMetaKey({ ctrlKey, metaKey }: { ctrlKey: boolean; metaKey: boolean }): boolean {
  const isCtrl = ctrlKey
  const isCmd = metaKey
  return isCtrl || isCmd
}

export function canChangeTheme(node: Node): boolean {
  if (!isFile(node)) {
    return false
  }

  switch (node.data.type) {
    case 'slate-card':
    case 'drop-a-word':
    case 'bullet':
    case 'poll':
    case 'reflections':
    case 'sliding-scale':
    case 'flip-cards':
    case 'assessment-card':
    case 'notepad':
    case 'external-notepad':
    case 'homework':
    case 'general':
    case 'question-card':
    case 'image':
    case 'stupid-questions':
    case 'scenario':
      return true
    case 'roleplay':
    case 'live-lobby':
    case 'breakout-room':
    case 'sticky-notes':
    case 'project-card':
    case 'video':
    case 'embed':
      return false
    default:
      assertNever(node.data)
  }
}
