import { createFileRoute } from '@tanstack/react-router'

import React from 'react'
import { requireOwner } from 'sierra-client/core/require-owner'
import { useCachedQuery, useTypedMutation } from 'sierra-client/state/api'
import {
  XRealtimeAdminUsersListRecentlyDeletedUsers,
  XRealtimeAdminUsersUndeleteUser,
} from 'sierra-domain/routes'
import { Button, Text, View } from 'sierra-ui/primitives'
import styled from 'styled-components'

const Ul = styled.ul`
  li + li {
    margin-top: 16px;
  }
`

export const Page: React.FC = () => {
  const deletedUsersQuery = useCachedQuery(XRealtimeAdminUsersListRecentlyDeletedUsers, {})
  const undeleteUserMutation = useTypedMutation(XRealtimeAdminUsersUndeleteUser, {
    onSuccess: () => deletedUsersQuery.refetch(),
  })
  const deletedUsers = deletedUsersQuery.data?.users
  return (
    <div>
      {deletedUsersQuery.isPending && <>Loading deleted users</>}
      {deletedUsersQuery.isError && <>Error loading deleted users</>}
      {deletedUsers?.length === 0 && <>No recently deleted users found</>}
      {undeleteUserMutation.isPending === true && <>Undeleting user...</>}
      {undeleteUserMutation.isError && <>Error undeleteing user</>}

      <View padding='48'>
        <Ul>
          {deletedUsers !== undefined &&
            deletedUsers.map(user => (
              <li key={user.id}>
                <View direction='column'>
                  <View gap='8'>
                    <Text size='large'>
                      {user.firstName} {user.lastName} ({user.email}) [{user.id}] - deleted at:{' '}
                      {new Date(user.deletedAt).toLocaleString()}
                    </Text>
                    <Button
                      loading={undeleteUserMutation.isPending}
                      onClick={() => {
                        if (confirm('Are you sure you want to undelete this user?')) {
                          undeleteUserMutation.mutate({ userId: user.id })
                        }
                      }}
                    >
                      Undelete
                    </Button>
                  </View>
                </View>
              </li>
            ))}
        </Ul>
      </View>
    </div>
  )
}

const RouteComponent = requireOwner(Page)

export const Route = createFileRoute('/recovery/users')({
  component: RouteComponent as React.FC,
})
