import { DateTime } from 'luxon'
import { FC, useCallback, useEffect, useRef, useState } from 'react'
import { useLiveSessionStateMutation } from 'sierra-client/api/hooks/use-live-session'
import { useLiveSessionContext } from 'sierra-client/components/liveV2/contexts/live-session-data'
import { useIsFacilitatorOrLearnerLedSession } from 'sierra-client/components/liveV2/hooks/use-is-facilitator-or-learner-led-session'
import { Logging } from 'sierra-client/core/logging'
import { usePresenceAtom } from 'sierra-client/features/sana-now/hooks/use-presence-atom'
import { usePeriodicRefresh } from 'sierra-client/hooks/use-periodic-refresh'
import { useSelectAtomValue } from 'sierra-client/hooks/use-select-atom-value'
import { useTranslation } from 'sierra-client/hooks/use-translation'
import { useDispatch } from 'sierra-client/state/hooks'
import { liveSessionStateChanged } from 'sierra-client/state/live-session/actions'
import { CloseModalButton, Modal, ModalHeader } from 'sierra-ui/components'
import { Button, Spacer, Text, View } from 'sierra-ui/primitives'
import styled from 'styled-components'

const BodyText = styled(Text)`
  max-width: 40ch;
`

// Not sure if this is necessary
const SESSION_EMPTY_TIME_BEFORE_PROMPT = 30 * 1000

const CountDownText = ({
  time,
  onCountdownComplete,
}: {
  time: DateTime
  onCountdownComplete: () => void
}): JSX.Element => {
  const didCallCallbackRef = useRef(false)
  const { t } = useTranslation()
  const secondsRemaining = usePeriodicRefresh(time, t => t.diffNow('seconds').seconds, 1000)

  useEffect(() => {
    if (secondsRemaining <= -1 && didCallCallbackRef.current === false) {
      didCallCallbackRef.current = true
      onCountdownComplete()
    }
  }, [onCountdownComplete, secondsRemaining])

  const numberValue = Math.max(Math.floor(secondsRemaining), 0)

  return (
    <Text size='large' bold>
      {t('sana-now.idle-session-modal.modal-countdown-title', { count: numberValue })}
    </Text>
  )
}

const Actions = styled(View).attrs({ gap: '8' })`
  align-self: flex-end;
`

const Content = styled(View).attrs({ direction: 'column', justifyContent: 'flex-start', grow: true })``

const InnerSessionIdlePrompt: FC<{ onSessionEnded?: () => void }> = ({ onSessionEnded }) => {
  const { t } = useTranslation()
  const [autoCloseTime, setAutoCloseTime] = useState<DateTime | null>(null)
  const enabledRef = useRef(false)
  const atom = usePresenceAtom()
  const participantCount = useSelectAtomValue(atom, it => it.length)
  const liveSession = useLiveSessionContext()
  const mutation = useLiveSessionStateMutation()
  const dispatch = useDispatch()

  useEffect(() => {
    if (participantCount === 0 && enabledRef.current === true) {
      const timeout = setTimeout(() => {
        setAutoCloseTime(DateTime.now().plus({ minutes: 1 }))
      }, SESSION_EMPTY_TIME_BEFORE_PROMPT)

      return () => clearTimeout(timeout)
    } else if (participantCount > 0) {
      // We want to make sure it only runs after the session has been active, otherwise there is a small delay when people join
      // which causes the session to appear empty for a short period of time
      enabledRef.current = true
    }
  }, [participantCount])

  const onClose = (): void => {
    void dispatch(Logging.liveSession.autoCloseSessionModalRejected())
    setAutoCloseTime(null)
  }

  const handleEndSession = useCallback(
    (reason: 'confirmed' | 'timeout') => {
      mutation.mutate(
        {
          liveSessionId: liveSession.liveSessionId,
          state: 'ended',
        },
        {
          onSuccess: async () => {
            dispatch(liveSessionStateChanged('ended'))
            onSessionEnded?.()
          },
        }
      )

      if (reason === 'timeout') {
        void dispatch(Logging.liveSession.autoCloseSessionModalTimedOut())
      } else {
        void dispatch(Logging.liveSession.autoCloseSessionModalConfirmed())
      }
    },
    [dispatch, liveSession.liveSessionId, mutation, onSessionEnded]
  )

  return (
    <Modal padding='24' margin='8' size='fit-content' open={autoCloseTime !== null} onClose={onClose}>
      <CloseModalButton ariaLabel={t('dictionary.close')} onClick={onClose} />
      <ModalHeader alignItems={'flex-start'}>
        {autoCloseTime !== null && (
          <CountDownText
            time={autoCloseTime}
            onCountdownComplete={() => {
              handleEndSession('timeout')
            }}
          />
        )}
      </ModalHeader>
      <Content>
        <BodyText size='regular' color='foreground/muted'>
          {t('sana-now.idle-session-modal.body-text')}
        </BodyText>
      </Content>
      <Spacer size='32' />
      <Actions>
        <Button variant='secondary' onClick={onClose}>
          {t('sana-now.idle-session-modal.keep-open-button-text')}
        </Button>
        <Button variant={'destructive'} onClick={() => handleEndSession('confirmed')}>
          {t('sana-now.idle-session-modal.end-now-button-text')}
        </Button>
      </Actions>
    </Modal>
  )
}

export const SessionIdlePrompt: FC<{ onSessionEnded?: () => void }> = ({
  onSessionEnded,
}): JSX.Element | null => {
  const isFacilitatorOrLearnerLedSession = useIsFacilitatorOrLearnerLedSession()
  if (!isFacilitatorOrLearnerLedSession) {
    return null
  }

  return <InnerSessionIdlePrompt onSessionEnded={onSessionEnded} />
}
