import { Logger } from 'sierra-client/core/logging/logger'
import { CourseId, FileId } from 'sierra-domain/api/nano-id'
import { ScenarioDataSource } from 'sierra-domain/flexible-content/types'

type BaseTrackingData = {
  courseId: CourseId
  fileId: FileId
}

type Identifier = `scenario_card_${string}`

export const setScenarioSuggestion = Logger.trackLogger<{ title: string } & BaseTrackingData>(
  'scenario_card_set_scenario_suggestion' satisfies Identifier
)

export const setCompletionCriteriaSuggestion = Logger.trackLogger<{ criteria: string } & BaseTrackingData>(
  'scenario_card_set_completion_criteria_suggestion' satisfies Identifier
)

export const setContext = Logger.trackLogger<{ context: Array<ScenarioDataSource> } & BaseTrackingData>(
  'scenario_card_set_context' satisfies Identifier
)

export const setDescription = Logger.trackLogger<{ description: string } & BaseTrackingData>(
  'scenario_card_set_description' satisfies Identifier
)

export const setLearnerRole = Logger.trackLogger<{ role: string } & BaseTrackingData>(
  'scenario_card_set_learner_role' satisfies Identifier
)

export const setAssistantRole = Logger.trackLogger<{ role: string } & BaseTrackingData>(
  'scenario_card_set_assistant_role' satisfies Identifier
)

export const setCompletionCriteria = Logger.trackLogger<{ criteria: string } & BaseTrackingData>(
  'scenario_card_set_completion_criteria' satisfies Identifier
)

export const setAssistantBehaviour = Logger.trackLogger<{ behaviour: string } & BaseTrackingData>(
  'scenario_card_set_assistant_behaviour' satisfies Identifier
)

export const editLearnerRole = Logger.trackLogger<{ role: string } & BaseTrackingData>(
  'scenario_card_edit_learner_role' satisfies Identifier
)

export const editLearnerScenario = Logger.trackLogger<{ scenario: string } & BaseTrackingData>(
  'scenario_card_edit_learner_scenario' satisfies Identifier
)

export const editLearnerObjective = Logger.trackLogger<{ objective: string } & BaseTrackingData>(
  'scenario_card_edit_learner_objective' satisfies Identifier
)

export const editAssistantRole = Logger.trackLogger<{ role: string } & BaseTrackingData>(
  'scenario_card_edit_assistant_role' satisfies Identifier
)

export const editAssistantScenario = Logger.trackLogger<{ scenario: string } & BaseTrackingData>(
  'scenario_card_edit_assistant_scenario' satisfies Identifier
)

export const editAssistantObjective = Logger.trackLogger<{ objective: string } & BaseTrackingData>(
  'scenario_card_edit_assistant_objective' satisfies Identifier
)

export const editScenarioTitle = Logger.trackLogger<{ title: string } & BaseTrackingData>(
  'scenario_card_edit_scenario_title' satisfies Identifier
)

export const editScenarioDescription = Logger.trackLogger<{ description: string } & BaseTrackingData>(
  'scenario_card_edit_scenario_description' satisfies Identifier
)

export const clickPreview = Logger.trackLogger<BaseTrackingData>(
  'scenario_card_click_preview' satisfies Identifier
)

export const completedFailure = Logger.trackLogger<BaseTrackingData>(
  'scenario_card_completed_with_failed_objectives' satisfies Identifier
)

export const completedSuccess = Logger.trackLogger<BaseTrackingData>(
  'scenario_card_completed_success' satisfies Identifier
)

export const complianceFailure = Logger.trackLogger<BaseTrackingData>(
  'scenario_card_compliance_failure' satisfies Identifier
)

export const sentMessages = Logger.trackLogger<{ messages: number } & BaseTrackingData>(
  'scenario_card_sent_messages' satisfies Identifier
)

export const switchLearnerAndAssistant = Logger.trackLogger<BaseTrackingData>(
  'scenario_card_switch_learner_and_assistant' satisfies Identifier
)

export const setAssistantAvatar = Logger.trackLogger<{ avatar: string } & BaseTrackingData>(
  'scenario_card_set_assistant_avatar' satisfies Identifier
)

export const restartScenario = Logger.trackLogger<BaseTrackingData>(
  'scenario_card_restart_scenario' satisfies Identifier
)

export const undoLastMessage = Logger.trackLogger<BaseTrackingData>(
  'scenario_card_undo_last_message' satisfies Identifier
)

export const tryAgain = Logger.trackLogger<BaseTrackingData>('scenario_card_try_again' satisfies Identifier)

export const startScenario = Logger.trackLogger<BaseTrackingData>(
  'scenario_card_start_scenario' satisfies Identifier
)

export const endConversation = Logger.trackLogger<BaseTrackingData>(
  'scenario_card_end_conversation' satisfies Identifier
)
