import {
  InvalidateQueries,
  typedInvalidateAllQueries,
  useTypedInvalidateQueries,
} from 'sierra-client/state/api'
import {
  XRealtimeAuthorListEditableContent,
  XRealtimeContentListLearnerContent,
  XRealtimeTeamspaceBrowseTeamspaces,
  XRealtimeTeamspaceGetFolderAncestors,
  XRealtimeTeamspaceGetFolderContent,
  XRealtimeTeamspaceGetPotentialIdentities,
  XRealtimeTeamspaceGetTeamspaceById,
  XRealtimeTeamspaceGetTeamspaceForCourse,
  XRealtimeTeamspaceListSidebarTeamspaces,
  XRealtimeTeamspaceListTeamspaces,
} from 'sierra-domain/routes'

export const useInvalidateEditableContentCache = (): InvalidateQueries => {
  const invalidate = useTypedInvalidateQueries([XRealtimeAuthorListEditableContent])

  return invalidate
}

export const useInvalidateTeamspaceCache = (): InvalidateQueries => {
  const invalidate = useTypedInvalidateQueries([
    XRealtimeTeamspaceListTeamspaces,
    XRealtimeTeamspaceBrowseTeamspaces,
    XRealtimeTeamspaceListSidebarTeamspaces,
    XRealtimeTeamspaceGetTeamspaceForCourse,
    XRealtimeTeamspaceGetTeamspaceById,
    XRealtimeTeamspaceGetPotentialIdentities,
  ])

  return invalidate
}

export const useInvalidateFoldersCache = (): InvalidateQueries => {
  const invalidate = useTypedInvalidateQueries([
    XRealtimeTeamspaceGetFolderContent,
    XRealtimeTeamspaceGetFolderAncestors,
  ])

  return invalidate
}

export const useInvalidateLearnerContentCache = (): InvalidateQueries => {
  const invalidate = useTypedInvalidateQueries([XRealtimeContentListLearnerContent])

  return invalidate
}

export const invalidateGlobalSidebarTeamspaceCache = async (): Promise<void> => {
  await typedInvalidateAllQueries(XRealtimeTeamspaceListSidebarTeamspaces)
}
