import { useEffect, useMemo, useState } from 'react'

export const useIsInViewport = (ref: React.RefObject<HTMLElement>): boolean => {
  const [isIntersecting, setIntersecting] = useState<boolean>(false)

  const observer = useMemo(
    () => new IntersectionObserver(([entry]) => setIntersecting(entry?.isIntersecting === true)),
    []
  )

  useEffect(() => {
    if (ref.current !== null) {
      observer.observe(ref.current)
    }

    // Remove the observer as soon as the component is unmounted
    return () => {
      observer.disconnect()
    }
  }, [observer, ref])

  return isIntersecting
}
