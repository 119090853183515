import React, { ReactElement } from 'react'
import { PageTitle } from 'sierra-client/components/common/page-title'
import { useScormContext } from 'sierra-client/context/scorm-context'
import { selectCourseById } from 'sierra-client/state/content/selectors'
import { useSelector } from 'sierra-client/state/hooks'
import { EditionPageLink } from 'sierra-client/views/learner/course/edition-page/utils'
import { LinkLabel } from 'sierra-client/views/workspace/learn/link-label'
import { CreateContentId } from 'sierra-domain/api/nano-id'
import { ScrollView, View } from 'sierra-ui/primitives'
import styled from 'styled-components'

const ScrollWrapper = styled(ScrollView)`
  height: 100%;
`

const Content = styled(View)`
  height: 100%;
`

const FileWrapper = styled(View)`
  max-width: 100%;
`

const StyledLinkLabel = styled(LinkLabel).attrs({
  size: 'micro',
  phone: 'micro',
})``

export const FileView = ({
  flexibleContentId,
  fileTree,
  disableScroll = false,
}: {
  flexibleContentId: CreateContentId
  fileTree: ReactElement
  disableScroll?: boolean
}): JSX.Element => {
  const course = useSelector(state => selectCourseById(state, flexibleContentId))
  const courseSettings = course?.settings
  const { scormCourseGroupId } = useScormContext()

  const Wrapper = disableScroll ? React.Fragment : ScrollWrapper

  return (
    <>
      <PageTitle title={courseSettings?.title} />
      <Wrapper>
        <Content direction='column' gap='none'>
          {scormCourseGroupId !== undefined && (
            // We don't translate this string because we want to make it easier for the user to find the link
            // if they accidentally chose the wrong language.
            <EditionPageLink>
              <StyledLinkLabel>Change language</StyledLinkLabel>
            </EditionPageLink>
          )}
          <FileWrapper role='navigation'>{fileTree}</FileWrapper>
        </Content>
      </Wrapper>
    </>
  )
}
