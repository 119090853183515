import { useBreakoutInSession } from 'sierra-client/components/liveV2/hooks/use-breakout-in-session'
import { selectCurrentCard } from 'sierra-client/components/liveV2/hooks/use-select-current-card'
import { useSelector } from 'sierra-client/state/hooks'
import { selectCurrentBreakoutRoomId } from 'sierra-client/state/live/selectors'
import { CreateContentId } from 'sierra-domain/api/nano-id'

export const useCurrentMode = (
  createContentId: CreateContentId
): 'breakout-joined' | 'breakout-started' | 'card' | 'live-lobby' => {
  const breakoutInSession = useBreakoutInSession()
  const currentBreakoutRoomId = useSelector(selectCurrentBreakoutRoomId)
  return useSelector(state => {
    const currentCard = selectCurrentCard(state, createContentId)
    if (currentCard.data.type === 'breakout-room') {
      return 'card'
    } else if (currentBreakoutRoomId !== undefined) {
      return 'breakout-joined'
    } else if (breakoutInSession) {
      return 'breakout-started'
    } else if (currentCard.data.type === 'live-lobby') {
      return 'live-lobby'
    } else {
      return 'card'
    }
  })
}
