import { LiveSessionPresence } from 'sierra-domain/api/live-session-presence'
import { UserId } from 'sierra-domain/api/uuid'
import { DateFromISOString } from 'sierra-domain/zod-extensions'
import { z } from 'zod'

export const livePresenceUpdatedData = z.object({
  type: z.literal('updated'),
  updatedAt: DateFromISOString,
  presence: LiveSessionPresence,
})

export const livePresenceRemovedData = z.object({
  type: z.literal('removed'),
  sessionId: z.string(),
  userId: UserId,
  removedReason: z.union([z.literal('session-removed'), z.literal('session-timeout')]),
  removedAt: DateFromISOString,
})

export const LivePresenceUpdate = z.discriminatedUnion('type', [
  livePresenceUpdatedData,
  livePresenceRemovedData,
])
export type LivePresenceUpdate = z.infer<typeof LivePresenceUpdate>
export type LivePresenceUpdatedData = z.infer<typeof livePresenceUpdatedData>

export const LivePresenceData = z.object({ presenceUpdates: LivePresenceUpdate.array() })
