import { createFileRoute } from '@tanstack/react-router'
import { PageIdentifier } from 'sierra-client/layout/sana-page'
import { useSettingsPageEnabled } from 'sierra-client/views/manage/permissions/use-settings-page-enabled'
import { ManagePageWrapper } from 'sierra-client/views/manage/utils/manage-page-wrapper'
import { EmailsAndNotifications } from 'sierra-client/views/settings/emails-and-notifications'

function NotificationsPage(): JSX.Element | null {
  const hasSettingsAccess = useSettingsPageEnabled()

  if (hasSettingsAccess !== true) {
    return null
  }

  return (
    <ManagePageWrapper pageIdentifier={PageIdentifier.ManageSettings()}>
      <EmailsAndNotifications />
    </ManagePageWrapper>
  )
}

const RouteComponent = NotificationsPage

export const Route = createFileRoute('/settings/notifications')({
  component: RouteComponent as React.FC,
})
