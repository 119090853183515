const baseHtmlExamples = `
  Format the output as HTML. Omit the <style> section of the document.

  Start your output with a heading.
  All text in headings should be bold. For example:
  <h1>
    <strong>
      {Heading text}
    </strong>
  </h1>

  Lists can be added like this (both ul and ol are supported):
  <ul>
    <li>
      <p>
        {List item 1}
      </p>
    </li>
  </ul>
`

export function getGeneralCardPrompt({ keepAllContent }: { keepAllContent: boolean }): string {
  return `Style the provided text with html text and tags${
    keepAllContent
      ? `, and make sure to include all the text in the original document - don't summarize or shorten content`
      : ``
  }.
  ${baseHtmlExamples}


  Structure some of the text into columns. Try not to put a lot of text into columns.
  Inserting columns is done like this:
  <div class="row">
    <div class="column">
      {Here is the text of the first column}
    </div>
    <div class="column">
      {Here is the text of the second column}
    </div>
    <div class="column">
      {You can even have three columns}
    </div>
  </div>
  `
}

export function getBulletCardPrompt({ keepAllContent }: { keepAllContent: boolean }): string {
  return `Style the text with html text tags${
    keepAllContent
      ? `and make sure to include all the text in the original document - don't summarize or shorten content`
      : ''
  }.
  
  ${baseHtmlExamples}  
  `
}
