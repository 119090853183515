import { PostableDashboard } from 'sierra-client/features/insights/create-dashboard'
import { TranslationLookup } from 'sierra-client/hooks/use-translation/types'
import { DashboardWidget, DimensionRef, MeasureRef } from 'sierra-domain/api/insights'
import { Filter } from 'sierra-domain/filter/datatype/filter'
import { nanoid12 } from 'sierra-domain/nanoid-extensions'

export const getEmptyDashboard = (t: TranslationLookup): PostableDashboard => {
  const dashboard: PostableDashboard = {
    title: t('manage.insights.dashboard-selector.new-dashboard-title'),
    widgets: [],
  }

  return dashboard
}

const standardUserFilter: Filter = {
  type: 'filter.filter',
  domain: { type: 'dimension.native.user.type' },
  operator: { type: 'operator.eq' },
  predicate: { type: 'predicate.or', values: [{ type: 'value.string', value: 'standard' }] },
}

const activeOrPendingUserFilter: Filter = {
  type: 'filter.filter',
  domain: { type: 'dimension.native.user.status' },
  operator: { type: 'operator.eq' },
  predicate: {
    type: 'predicate.or',
    values: [
      { type: 'value.string', value: 'active' },
      { type: 'value.string', value: 'pending' },
    ],
  },
}

const defaultFilters: Filter[] = [standardUserFilter, activeOrPendingUserFilter]

const defaultFilter: Filter = { type: 'filter.and', filters: defaultFilters }

export const getDefaultDashboard = (t: TranslationLookup): PostableDashboard => {
  const widgets: Array<DashboardWidget> = [
    {
      id: nanoid12(),
      title: t('manage.insights.dashboard.default.widget.total-no-of-learners'),
      layout: { x: 0, y: 0, width: 4, height: 1 },
      widget: {
        type: 'widget.metric',
        measures: [MeasureRef.parse({ type: 'measure.native.user.count' })],
        filter: defaultFilter,
      },
      settings: { automaticTitle: false },
    },
    {
      id: nanoid12(),
      title: t('manage.insights.dashboard.default.widget.mau-learners'),
      layout: { x: 4, y: 0, width: 4, height: 1 },
      widget: {
        type: 'widget.metric',
        measures: [MeasureRef.parse({ type: 'measure.native.active-user.mau' })],
        filter: defaultFilter,
      },
      settings: { automaticTitle: false },
    },
    {
      id: nanoid12(),
      title: t('manage.insights.dashboard.default.widget.wau-learners'),
      layout: { x: 8, y: 0, width: 4, height: 1 },
      widget: {
        type: 'widget.metric',
        measures: [MeasureRef.parse({ type: 'measure.native.active-user.wau' })],
        filter: defaultFilter,
      },
      settings: { automaticTitle: false },
    },
    {
      id: nanoid12(),
      title: t('manage.insights.dashboard.default.widget.time-learning'),
      layout: { x: 0, y: 1, width: 8, height: 2 },
      widget: {
        type: 'widget.line-chart',
        dimensions: [
          DimensionRef.parse({
            type: 'dimension.native.time',
            unit: 'unit.temporal.year_month',
          }),
        ],
        measures: [MeasureRef.parse({ type: 'measure.native.session.duration' })],
        filter: defaultFilter,
      },
      settings: { automaticTitle: false },
    },
    {
      id: nanoid12(),
      title: t('manage.insights.dashboard.default.widget.top-self-starting-learners'),
      layout: { x: 8, y: 1, width: 4, height: 3 },
      widget: {
        type: 'widget.aggregation-table',
        dimensions: [DimensionRef.parse({ type: 'dimension.native.user.user' })],
        measures: [MeasureRef.parse({ type: 'measure.native.user-course-progress.started-count' })],
        filter: {
          type: 'filter.and',
          filters: [
            ...defaultFilters,
            {
              type: 'filter.filter',
              domain: { type: 'dimension.native.user-course-progress.assigned' },
              operator: { type: 'operator.eq' },
              predicate: {
                type: 'predicate.or',
                values: [{ type: 'value.boolean', value: false }],
              },
            },
          ],
        },
        sortBy: [
          {
            column: {
              type: 'column.measure',
              measure: MeasureRef.parse({ type: 'measure.native.user-course-progress.started-count' }),
            },
            order: 'desc',
          },
        ],
        limit: 10,
      },
      settings: { automaticTitle: false },
    },
    {
      id: nanoid12(),
      title: t('manage.insights.dashboard.default.widget.completion-of-assigned-courses'),
      layout: { x: 0, y: 3, width: 4, height: 1 },
      widget: {
        type: 'widget.progress-bar',
        measures: [MeasureRef.parse({ type: 'measure.native.user-course-progress.passed-rate' })],
        filter: {
          type: 'filter.and',
          filters: [
            ...defaultFilters,
            {
              type: 'filter.filter',
              domain: { type: 'dimension.native.user-course-progress.assigned' },
              operator: { type: 'operator.eq' },
              predicate: {
                type: 'predicate.or',
                values: [{ type: 'value.boolean', value: true }],
              },
            },
          ],
        },
      },
      settings: { automaticTitle: false },
    },
    {
      id: nanoid12(),
      title: t('manage.insights.dashboard.default.widget.start-rate-of-assigned-courses'),
      layout: { x: 4, y: 3, width: 4, height: 1 },
      widget: {
        type: 'widget.progress-bar',
        measures: [MeasureRef.parse({ type: 'measure.native.user-course-progress.start-rate' })],
        filter: {
          type: 'filter.and',
          filters: [
            ...defaultFilters,
            {
              type: 'filter.filter',
              domain: { type: 'dimension.native.user-course-progress.assigned' },
              operator: { type: 'operator.eq' },
              predicate: { type: 'predicate.or', values: [{ type: 'value.boolean', value: true }] },
            },
          ],
        },
      },
      settings: { automaticTitle: false },
    },
    {
      id: nanoid12(),
      title: t('manage.insights.dashboard.default.widget.time-spent-per-group'),
      layout: { x: 0, y: 4, width: 12, height: 2 },
      widget: {
        type: 'widget.bar-chart',
        dimensions: [DimensionRef.parse({ type: 'dimension.native.group.group' })],
        measures: [MeasureRef.parse({ type: 'measure.native.session.duration' })],
        filter: defaultFilter,
        sortBy: [
          {
            column: {
              type: 'column.measure',
              measure: MeasureRef.parse({ type: 'measure.native.session.duration' }),
            },
            order: 'desc',
          },
        ],
        limit: 5,
      },
      settings: { automaticTitle: false },
    },
  ]

  const dashboard: PostableDashboard = { title: t('manage.insights.dashboard.default.title'), widgets }

  return dashboard
}
