import React, { FC } from 'react'
import { VideoPlayerCardPreview } from 'sierra-client/components/liveV2/cards/video-player-card'
import { SlateCardPreview } from 'sierra-client/features/content-preview/components/slate-card-preview'
import { useResolveAsset } from 'sierra-client/hooks/use-resolve-asset'
import { PolarisCardTheme } from 'sierra-client/views/flexible-content/polaris-card-theme'
import { AssetContext } from 'sierra-domain/asset-context'
import { File } from 'sierra-domain/flexible-content/types'
import { assertNever } from 'sierra-domain/utils'
import { SlateDocument } from 'sierra-domain/v3-author'
import { View } from 'sierra-ui/primitives'
import { token } from 'sierra-ui/theming'
import styled, { css } from 'styled-components'

const renderWidth = 800
const aspectRatio = '4 / 3'

const Switch: React.FC<{
  file: File
  slateDocument: SlateDocument | undefined
  assetContext: AssetContext
}> = ({ file, slateDocument, assetContext }) => {
  switch (file.data.type) {
    case 'slate-card':
    case 'poll':
    case 'sliding-scale':
    case 'flip-cards':
    case 'bullet':
    case 'general':
    case 'notepad':
    case 'question-card':
    case 'assessment-card':
    case 'project-card':
    case 'sticky-notes':
    case 'reflections':
      return <SlateCardPreview file={file} slateDocument={slateDocument} assetContext={assetContext} />
    case 'homework':
      return null
    case 'live-lobby':
      return null
    case 'breakout-room':
      return null
    case 'video':
      return <VideoPlayerCardPreview video={file.data.video} />
    case 'image':
      return null
    case 'stupid-questions':
      return null
    case 'embed':
      return null
    case 'drop-a-word':
      return null
    case 'scenario':
      return null
    case 'roleplay':
      return null
    case 'external-notepad':
      return null
    default:
      assertNever(file.data)
  }
}

const ScaleContainer = styled(View)<{ $backgroundUrl: string | undefined; $width: number }>`
  aspect-ratio: ${aspectRatio};
  height: auto;
  width: ${renderWidth}px;
  user-select: none;
  pointer-events: none;
  overflow: hidden;
  padding: 8px;
  transform: scale(${p => p.$width / renderWidth});
  transform-origin: top left;

  ${p =>
    p.$backgroundUrl !== undefined
      ? css`
          background-image: url(${p.$backgroundUrl});
          background-position: center;
          background-size: cover;
        `
      : css`
          background-color: ${token('surface/default')(p)};
        `}
`

const Container = styled.div<{ $width: number }>`
  width: ${p => p.$width}px;
  height: auto;
  aspect-ratio: ${aspectRatio};
  overflow: hidden;
  border-radius: 6px;
`

export const ContentPreview: FC<{
  slateDocument?: SlateDocument
  file: File
  width: number
  assetContext: AssetContext
}> = ({ file, slateDocument, width, assetContext }) => {
  const resolvedBackgroundUrl = useResolveAsset({
    image: file.backgroundImage,
    assetContext,
    size: 'course-sm',
  })
  const backgroundUrl = file.backgroundImage !== undefined ? resolvedBackgroundUrl : undefined

  return (
    <PolarisCardTheme {...file}>
      <Container $width={width}>
        <ScaleContainer $width={width} $backgroundUrl={backgroundUrl}>
          <Switch file={file} slateDocument={slateDocument} assetContext={assetContext} />
        </ScaleContainer>
      </Container>
    </PolarisCardTheme>
  )
}
