import { useCallback, useEffect, useState } from 'react'
import { usePost } from 'sierra-client/hooks/use-post'
import { useDownloadReport, useIsReportingEnabled } from 'sierra-client/views/manage/reports/report-utils'
import {
  AssignmentType,
  ContentType,
  OutputFormat,
  Report,
  ReportJob,
} from 'sierra-domain/api/analytics-reporting'
import { XRealtimeAnalyticsReportsJobsList, XRealtimeAnalyticsReportsListReports } from 'sierra-domain/routes'

export type ReportFilter = {
  assignmentType?: AssignmentType
  contentTypes?: ContentType[]
  contentIds?: string[]
  groups?: string[]
  programs?: string[]
  customUserAttributes?: string[]
  outputFormat?: OutputFormat
}

export const getDefaultValuesForReport = (customUserAttributes: string[], report?: Report): ReportFilter => {
  if (!report) return {}

  const initialValues: ReportFilter = {}
  report.parameters.forEach(param => {
    switch (param.type) {
      case 'contentTypes': {
        initialValues.contentTypes = [...param.allowedTypes]
        break
      }
      case 'assignmentType':
        initialValues.assignmentType = 'all'
        break
      case 'contentIds':
        initialValues.contentIds = []
        break
      case 'groups':
        initialValues.groups = []
        break
      case 'programs':
        initialValues.programs = []
        break
      case 'customUserAttributes':
        initialValues.customUserAttributes = customUserAttributes
        break
      case 'outputFormat':
        initialValues.outputFormat = param.formats[0]
        break
    }
  })
  return initialValues
}

type UseReportData = {
  isLoading: boolean
  reports: Report[]
}

export const useReports = (): UseReportData => {
  const { postWithUserErrorException } = usePost()
  const [isLoading, setIsLoading] = useState<boolean>(false)
  const [reports, setReports] = useState<Report[]>([])
  const isReportingEnabled = useIsReportingEnabled()

  useEffect(() => {
    if (!isReportingEnabled) return

    let cancelled = false
    const fetchReports = async (): Promise<void> => {
      setIsLoading(true)
      const response = await postWithUserErrorException(XRealtimeAnalyticsReportsListReports, {})

      if (cancelled) return

      setReports(response.reports)
      setIsLoading(false)
    }

    void fetchReports()

    return () => {
      cancelled = true
    }
  }, [postWithUserErrorException, isReportingEnabled])

  return {
    isLoading,
    reports,
  }
}

type UseReportJobsData = {
  isLoading: boolean
  jobs: ReportJob[]
  addJob: (job: ReportJob) => void
}

export const useReportJobs = ({ pollInterval = 5000 }: { pollInterval?: number } = {}): UseReportJobsData => {
  const { postWithUserErrorException } = usePost()
  const [isLoading, setIsLoading] = useState<boolean>(false)
  const [jobs, setJobs] = useState<ReportJob[]>([])
  const [downloadedJobs, setDownloadedJobs] = useState<string[]>([])
  const downloadReport = useDownloadReport()
  const isReportingEnabled = useIsReportingEnabled()

  useEffect(() => {
    if (!isReportingEnabled) return

    let cancelled = false
    const fetchJobs = async (): Promise<void> => {
      setIsLoading(true)
      const response = await postWithUserErrorException(XRealtimeAnalyticsReportsJobsList, {})
      if (cancelled) return

      setJobs(response.jobs)
      setIsLoading(false)
    }

    void fetchJobs()

    return () => {
      cancelled = true
    }
  }, [postWithUserErrorException, isReportingEnabled])

  const fetchJobUpdates = useCallback(async (): Promise<void> => {
    if (!isReportingEnabled) return

    const jobIds = jobs
      .filter(job => job.status === 'pending' || job.status === 'running')
      .map(job => job.jobId)

    if (jobIds.length > 0) {
      const response = await postWithUserErrorException(XRealtimeAnalyticsReportsJobsList, {
        jobIds,
      })

      const updatedJobs: Record<string, ReportJob> = response.jobs.reduce(
        (acc, job) => {
          acc[job.jobId] = job
          return acc
        },
        {} as Record<string, ReportJob>
      )

      setJobs(jobs => jobs.map(job => updatedJobs[job.jobId] ?? job))

      for (const job of response.jobs) {
        if (job.status === 'successful' && !downloadedJobs.includes(job.jobId)) {
          const error = await downloadReport(job.jobId)

          if (error === undefined) {
            setDownloadedJobs(downloadedJobs => [job.jobId, ...downloadedJobs])
          }
        }
      }
    }
  }, [postWithUserErrorException, jobs, downloadReport, downloadedJobs, isReportingEnabled])

  useEffect(() => {
    const intervalId = setInterval(() => void fetchJobUpdates(), pollInterval)
    return () => clearInterval(intervalId)
  }, [fetchJobUpdates, pollInterval])

  return {
    isLoading,
    jobs,
    addJob: job => setJobs(jobs => [job, ...jobs]),
  }
}
