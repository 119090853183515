import { useEffect } from 'react'
import { useDebouncedState } from 'sierra-client/hooks/use-debounced-state'
import { usePost } from 'sierra-client/hooks/use-post'
import { XRealtimeAdminUsersListUsers } from 'sierra-domain/routes'
import { BaseUserRow } from 'sierra-domain/user/base-user-row'

export function useUserSearch(query: string): BaseUserRow[] {
  const { postWithUserErrorException } = usePost()

  const [results, setResults] = useDebouncedState<BaseUserRow[]>([], { maxWait: 200 })

  useEffect(() => {
    void postWithUserErrorException(XRealtimeAdminUsersListUsers, {
      statusFilter: undefined,
      commonFilters: {
        query: query,
        sortBy: undefined,
        lastUserId: undefined,
        requestedUserIds: undefined,
        maxResults: 20,
        groupIds: undefined,
      },
    }).then(res => {
      setResults(res.data.map(res => res.userInfo))
    })
  }, [query, postWithUserErrorException, setResults])

  return results
}
