import { useMemo } from 'react'
import { RouteMatchItem, useActiveRouteMatch } from 'sierra-client/hooks/router/use-active-route'
import { CreateContentId, LiveContentId, NanoId12, SelfPacedContentId } from 'sierra-domain/api/nano-id'
import {
  ScopedChatId,
  ScopedLiveContentId,
  ScopedSelfPacedContentId,
} from 'sierra-domain/collaboration/types'
import { FileId } from 'sierra-domain/flexible-content/identifiers'
import { assertIsNonNullable, guardWith, isDefined } from 'sierra-domain/utils'
import z from 'zod'

export type EditorIds =
  | {
      mode: 'live'
      nodeId: FileId | undefined
      contentId: LiveContentId
      scopedChatId: ScopedChatId
      scopedContentId: ScopedLiveContentId
    }
  | {
      mode: 'self-paced'
      nodeId: FileId | undefined
      contentId: SelfPacedContentId
      scopedChatId: ScopedChatId
      scopedContentId: ScopedSelfPacedContentId
    }

export const SelfPacedIds = z.object({
  flexibleContentId: SelfPacedContentId,
  fileId: FileId.or(z.literal('next')).optional(),
  programId: z.string().optional(),
  pathId: NanoId12.optional(),
})

export type SelfPacedIds = z.infer<typeof SelfPacedIds>

function parseEditorIdsFromMatch(match: RouteMatchItem): EditorIds | undefined {
  switch (match.routeId) {
    case '/create/l/$':
    case '/create/s/$':
    case '/history/l/$':
    case '/history/s/$': {
      const [baseContentId, baseNodeId] = match.params._splat?.split('/') ?? []
      const nodeId = FileId.optional().parse(baseNodeId)

      if (match.routeId === '/create/l/$') {
        const contentId = LiveContentId.parse(baseContentId)
        const scopedContentId = ScopedLiveContentId.fromId(contentId)
        const scopedChatId = ScopedChatId.fromId(scopedContentId)
        return { mode: 'live', nodeId, contentId, scopedContentId, scopedChatId }
      } else {
        const contentId = SelfPacedContentId.parse(baseContentId)
        const scopedContentId = ScopedSelfPacedContentId.fromId(contentId)
        const scopedChatId = ScopedChatId.fromId(scopedContentId)
        return { mode: 'self-paced', nodeId, contentId, scopedContentId, scopedChatId }
      }
    }

    default:
      return undefined
  }
}

export function useRouterEditorIds(): EditorIds | undefined {
  return useActiveRouteMatch({
    select: match => parseEditorIdsFromMatch(match),
  })
}

function parseSelfPacedIdsFromMatch(match: RouteMatchItem): SelfPacedIds | undefined {
  switch (match.routeId) {
    case '/s/$flexibleContentId/':
    case '/s/$flexibleContentId/$fileId':
    case '/path/$pathId/s/$flexibleContentId/':
    case '/path/$pathId/s/$flexibleContentId/$fileId':
    case '/program/$programId/s/$flexibleContentId/':
    case '/program/$programId/s/$flexibleContentId/$fileId':
    case '/program/$programId/path/$pathId/s/$flexibleContentId/':
    case '/program/$programId/path/$pathId/s/$flexibleContentId/$fileId': {
      return SelfPacedIds.parse(match.params)
    }

    case '/course/$courseId/':
    case '/course/$courseId/next':
    case '/course/$courseId/scorm':
    case '/course/$courseId/$':
    case '/path/$pathId/course/$courseId/':
    case '/path/$pathId/course/$courseId/next':
    case '/path/$pathId/course/$courseId/scorm':
    case '/path/$pathId/course/$courseId/$':
    case '/program/$programId/course/$courseId/':
    case '/program/$programId/course/$courseId/next':
    case '/program/$programId/course/$courseId/scorm':
    case '/program/$programId/course/$courseId/$':
    case '/program/$programId/path/$pathId/course/$courseId/':
    case '/program/$programId/path/$pathId/course/$courseId/next':
    case '/program/$programId/path/$pathId/course/$courseId/scorm':
    case '/program/$programId/path/$pathId/course/$courseId/$': {
      return SelfPacedIds.parse({ flexibleContentId: match.params.courseId })
    }

    default:
      return undefined
  }
}

export function useRouterSelfPacedIds(): SelfPacedIds | undefined {
  return useActiveRouteMatch({ select: parseSelfPacedIdsFromMatch })
}

/**
 * Same as useSafeRouterSelfPacedIds but only returns flexibleContentId so as to not cause rerenders when file changes
 */
export function useSafeRouterSelfPacedFlexibleContentId(): SelfPacedIds['flexibleContentId'] | undefined {
  return useActiveRouteMatch({
    select: match => {
      try {
        return parseSelfPacedIdsFromMatch(match)?.flexibleContentId
      } catch {
        return undefined
      }
    },
  })
}

export function useRouterSelfPacedIdsWithFileId():
  | { fileId: FileId; flexibleContentId: SelfPacedContentId }
  | undefined {
  const ids = useRouterSelfPacedIds()
  return useMemo(() => {
    if (!isDefined(ids)) return undefined
    if (!guardWith(FileId, ids.fileId)) return undefined
    const fileId: FileId = ids.fileId
    return { fileId, flexibleContentId: ids.flexibleContentId }
  }, [ids])
}

export function useRequiredRouterSelfPacedIds(): SelfPacedIds {
  const query = useRouterSelfPacedIds()
  assertIsNonNullable(query)
  return query
}

export function useRequiredRouterEditorIds(): EditorIds {
  const query = useRouterEditorIds()
  assertIsNonNullable(query)
  return query
}

export function useSafeRouterEditorFlexibleContentId(): CreateContentId | undefined {
  return useActiveRouteMatch({
    select: match => {
      try {
        return parseEditorIdsFromMatch(match)?.contentId
      } catch {
        return undefined
      }
    },
  })
}
