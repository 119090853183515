import { useScenarioCardActions } from 'sierra-client/views/v3-author/scenario/hooks'
import { ScenarioFile } from 'sierra-client/views/v3-author/scenario/utils'
import { CourseId } from 'sierra-domain/api/nano-id'
import { ScenarioDisposition } from 'sierra-domain/flexible-content/types'
import { Text, View } from 'sierra-ui/primitives'
import { token } from 'sierra-ui/theming'
import styled from 'styled-components'

const Card = styled(View)<{ selected: boolean }>`
  outline: 1px solid ${token('border/default')};
  border-radius: 14px;
  padding: 12px 16px;
  flex: 1;
  height: 102px;
  background-color: ${p => (p.selected ? token('form/background/2') : 'inherit')};

  transition: all 0.2s cubic-bezier(0.25, 0.5, 0.25, 1);
  &:hover {
    cursor: pointer;
    outline: 1px solid ${p => (p.selected ? 'none' : token('form/border/4')(p))};
  }
`
const DifficultyCard: React.FC<{
  title: string
  description: string
  onSelect: () => void
  selected: boolean
}> = ({ title, description, onSelect, selected }) => {
  return (
    <Card direction='column' justifyContent='space-between' onClick={onSelect} selected={selected}>
      <Text color='foreground/primary'>{title}</Text>
      <Text color='foreground/muted'>{description}</Text>
    </Card>
  )
}

type Difficulty = 'Easy-going' | 'Pragmatic' | 'Difficult'
const Difficulties: Array<{
  title: Difficulty
  description: string
  disposition: Array<ScenarioDisposition>
}> = [
  {
    title: 'Easy-going',
    description: 'Cooperative and friendly',
    disposition: [
      {
        disposition: 'expressive',
        counterDisposition: 'reserved',
        degree: { current: 100, max: 100, min: 0 },
      },
      { disposition: 'friendly', counterDisposition: 'angry', degree: { current: 100, max: 100, min: 0 } },
    ],
  },
  {
    title: 'Pragmatic',
    description: 'Neutral and Calm',
    disposition: [
      {
        disposition: 'expressive',
        counterDisposition: 'reserved',
        degree: { current: 50, max: 100, min: 0 },
      },
      { disposition: 'friendly', counterDisposition: 'angry', degree: { current: 50, max: 100, min: 0 } },
    ],
  },
  {
    title: 'Difficult',
    description: 'Uncooperative and Upset',
    disposition: [
      {
        disposition: 'expressive',
        counterDisposition: 'reserved',
        degree: { current: 10, max: 100, min: 0 },
      },
      { disposition: 'friendly', counterDisposition: 'angry', degree: { current: 10, max: 100, min: 0 } },
    ],
  },
]

const getDifficulty = (dispositions: Array<ScenarioDisposition>): Difficulty => {
  const cooperativeness = dispositions.find(d => d.disposition === 'expressive')
  const friendliness = dispositions.find(d => d.disposition === 'friendly')
  if (cooperativeness === undefined || friendliness === undefined) {
    return 'Pragmatic'
  }
  if (cooperativeness.degree.current === 100 && friendliness.degree.current === 100) {
    return 'Easy-going'
  }
  if (cooperativeness.degree.current === 50 && friendliness.degree.current === 50) {
    return 'Pragmatic'
  }
  return 'Difficult'
}

export const AssistantDifficulty: React.FC<{ file: ScenarioFile; courseId: CourseId }> = ({
  file,
  courseId,
}) => {
  const { character } = file.data.input
  const { setAssistantDisposition } = useScenarioCardActions({ file, courseId })

  return (
    <View justifyContent='space-between'>
      {Difficulties.map(difficulty => {
        const selected = getDifficulty(character?.disposition ?? [])
        return (
          <DifficultyCard
            key={difficulty.title}
            title={difficulty.title}
            description={difficulty.description}
            onSelect={() => {
              setAssistantDisposition(difficulty.disposition)
            }}
            selected={selected === difficulty.title}
          />
        )
      })}
    </View>
  )
}
