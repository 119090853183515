import { Logger } from 'sierra-client/core/logging/logger'

export const teamspaceCreatedLogger = Logger.trackLoggerWithExtra<
  { usersAdded: number },
  { usersAdded: number }
>(
  'teamspace_created',

  input => {
    return {
      ...input,
    }
  }
)

export const teamspaceViewedLogger = Logger.trackLoggerWithExtra<
  { teamspaceId: string },
  { teamspaceId: string }
>(
  'teamspace_viewed',

  input => {
    return {
      ...input,
    }
  }
)

export const teamspaceDeletedLogger = Logger.trackLoggerWithExtra<
  { teamspaceId: string },
  { teamspaceId: string }
>(
  'teamspace_deleted',

  input => {
    return {
      ...input,
    }
  }
)

export const teamspaceEditedLogger = Logger.trackLoggerWithExtra<
  {
    teamspaceId: string
    changedName?: boolean
    changedDescription?: boolean
    changedVisibility?: boolean
    changedDefaultRole?: boolean
  },
  {
    teamspaceId: string
    changedName?: boolean
    changedDescription?: boolean
    changedVisibility?: boolean
    changedDefaultRole?: boolean
  }
>(
  'teamspace_edited',

  input => {
    return {
      ...input,
    }
  }
)

export const teamspaceAddedNewMembersLogger = Logger.trackLoggerWithExtra<
  { teamspaceId: string; usersAdded: number },
  { teamspaceId: string; usersAdded: number }
>(
  'teamspace_added_new_members',

  input => {
    return {
      ...input,
    }
  }
)

export const teamspaceJoinedLogger = Logger.trackLoggerWithExtra<
  { teamspaceId: string },
  { teamspaceId: string }
>(
  'teamspace_joined',

  input => {
    return {
      ...input,
    }
  }
)

export const teamspaceLeftLogger = Logger.trackLoggerWithExtra<
  { teamspaceId: string },
  { teamspaceId: string }
>(
  'teamspace_left',

  input => {
    return {
      ...input,
    }
  }
)

export const teamspaceContentMovedLogger = Logger.trackLoggerWithExtra<
  {
    teamspaceId: string | undefined
    contentId: string
    movedBy: 'dnd' | 'itemMenu' | 'importModal' | 'manage'
    contentType: 'editable-content' | 'folder' | 'non-editor-content' | undefined
    parentFolderId: string | undefined
  },
  {
    teamspaceId: string | undefined
    contentId: string
    movedBy: 'dnd' | 'itemMenu' | 'importModal' | 'manage'
    contentType: 'editable-content' | 'folder' | 'non-editor-content' | undefined
    parentFolderId: string | undefined
  }
>(
  'teamspace_content_moved',

  input => {
    return {
      ...input,
    }
  }
)

export const teamspaceContentCreatedLogger = Logger.trackLoggerWithExtra<
  { teamspaceId: string | undefined; templateName: string },
  { teamspaceId: string | undefined; templateName: string }
>(
  'teamspace_content_created',

  input => {
    return {
      ...input,
    }
  }
)

export const teamspaceContentClickedLogger = Logger.trackLoggerWithExtra<
  { teamspaceId: string; contentId: string; clickedFrom: 'sidebar' | 'page' },
  { teamspaceId: string; contentId: string; clickedFrom: 'sidebar' | 'page' }
>(
  'teamspace_content_clicked',

  input => {
    return {
      ...input,
    }
  }
)

export const folderViewedLogger = Logger.trackLoggerWithExtra<{ folderId: string }, { folderId: string }>(
  'teamspace_folder_viewed',

  input => {
    return {
      ...input,
    }
  }
)

export const folderDeletedLogger = Logger.trackLoggerWithExtra<{ folderId: string }, { folderId: string }>(
  'teamspace_folder_deleted',

  input => {
    return {
      ...input,
    }
  }
)

export const folderCreatedLogger = Logger.trackLoggerWithExtra<
  { folderId: string; teamspaceId: string; parentFolderId: string | undefined },
  { folderId: string; teamspaceId: string; parentFolderId: string | undefined }
>(
  'teamspace_folder_created',

  input => {
    return {
      ...input,
    }
  }
)
