import type { AIDenoiserExtension } from 'agora-extension-ai-denoiser'
import type { IAudioProcessor } from 'agora-rte-extension'

// These types are needed because the typing in agora-extension-ai-denoiser is not correct
export type MyAudioProcessor = IAudioProcessor & { onoverload?: (elapsedTime: number) => void }
type MyDenoiserExtension = AIDenoiserExtension & { createProcessor: () => MyAudioProcessor }

// Helper to ensure we only load one instance of the audio denoiser
let denioserPromise: Promise<MyDenoiserExtension> | undefined
export const loadAudioDenoiser = async (): Promise<MyDenoiserExtension> => {
  if (denioserPromise) return denioserPromise

  denioserPromise = (async () => {
    const { AIDenoiserExtension } = await import('agora-extension-ai-denoiser')
    const AgoraRTC = (await import('agora-rtc-sdk-ng')).default

    // TODO: The data files for the denoiser are copied from node_modules/agora-extension-ai-denoiser/external
    // to client/public/agora-extensions/denoiser and checked in there. This should be a build step.
    const denoiser = new AIDenoiserExtension({
      assetsPath: '/agora-extensions/denoiser',
    }) as MyDenoiserExtension
    AgoraRTC.registerExtensions([denoiser])

    return denoiser
  })()

  return denioserPromise
}
