import { Linkable } from 'sierra-client/views/workspace/utils/urls'
import { LearnEntity, ProgramStep } from 'sierra-domain/api/entities'
import { assertNever } from 'sierra-domain/utils'

export const convertLearnEntityToLinkable = (entity: LearnEntity): Linkable => {
  // The only reason this function definition is in the function body is because the
  // functions call eachother so eslint will otherwise complain that a function is called
  // before it's defined.
  const convertNextProgramStepToLinkable = (programStep: ProgramStep): Linkable => {
    switch (programStep.type) {
      case 'course':
        return convertLearnEntityToLinkable(programStep.course)
      case 'path':
        return convertLearnEntityToLinkable(programStep.path)
      default:
        assertNever(programStep)
    }
  }

  const id = entity.id

  switch (entity.entityType) {
    case 'program': {
      const nextStep =
        entity.nextStepId !== undefined ? entity.steps.find(step => step.id === entity.nextStepId) : undefined
      const nextStepLinkable = nextStep !== undefined ? convertNextProgramStepToLinkable(nextStep) : undefined

      return {
        id,
        type: 'program',
        nextStep:
          nextStepLinkable !== undefined && nextStepLinkable.type !== 'program'
            ? nextStepLinkable
            : undefined,
      }
    }
    case 'path':
      return {
        id,
        type: 'path',
      }
    case 'link':
    case 'linkedin':
      return {
        id,
        type: entity.entityType,
        url: undefined, // TODO: Not included in model
      }
    case 'native:course-group':
    case 'scorm:course-group':
      return {
        id,
        type: entity.entityType,
        activeEditionId: undefined, // TODO: Not included in model
      }
    case 'native:self-paced':
    case 'scorm':
      return {
        id,
        type: entity.entityType,
        courseGroupId: entity.courseGroupId,
      }
    case 'live-session':
      return {
        id,
        type: 'live-session',
        courseId: entity.backingCourse,
      }
    case 'native:live':
      return {
        id,
        type: entity.entityType,
      }
    case 'native:event-group':
      return {
        id,
        type: entity.entityType,
      }
    default:
      assertNever(entity)
  }
}
