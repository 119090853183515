import { useCallback } from 'react'
import { subscribeSse } from 'sierra-client/state/api'
import { useDispatch } from 'sierra-client/state/hooks'
import { addNotification, toNotificationState } from 'sierra-client/state/notifications/actions'
import { AnyZodSseRoute, ZodRouteInput, ZodRouteOutput } from 'sierra-domain/api/types'
import { SseEvent } from 'sierra-domain/routes-sse'

export type SubscribeSseType = <Route extends AnyZodSseRoute>(
  route: Route,
  input: ZodRouteInput<Route>,
  onMessage?: (event: SseEvent<ZodRouteOutput<Route>>) => void,
  onClose?: () => void,
  signal?: AbortSignal
) => Promise<SseEvent<ZodRouteOutput<Route>>[]>

export const useSseWithErrorNotification = (): {
  subscribeSse: SubscribeSseType
} => {
  const dispatch = useDispatch()

  const onError = useCallback(
    (error: unknown) => {
      console.error(error)
      void dispatch(addNotification(toNotificationState({ type: 'error' })))
    },
    [dispatch]
  )

  return {
    subscribeSse: useCallback(
      (key, input, onMessage, onClose, signal) => {
        return subscribeSse({ route: key, input, onMessage, onClose, onError, signal })
      },
      [onError]
    ),
  }
}
