import styled from 'styled-components'

export const BottomContentContainer = styled.div`
  position: absolute;
  bottom: 0;
  display: flex;
  align-items: center;
  width: 100%;
  color: ${p => p.theme.home.textColor};
  pointer-events: none;
  justify-content: space-between;
  padding: 1rem;
`
