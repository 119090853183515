import { BadgeIconId } from '../badges/types'

export type TAXONOMY_LEVEL = { title: string; description: string }
export type TAXONOMY = {
  title: string
  description: string
  icon: BadgeIconId
  levels: Array<TAXONOMY_LEVEL>
}
export const _SANA_TAXONOMY: Array<TAXONOMY> = [
  {
    title: 'Creative Thinking',
    description:
      'This skill involves the ability to think in new and innovative ways. It encompasses the generation of new ideas, solutions, and approaches.',
    icon: 'skill--paintpalette',
    levels: [
      {
        title: 'Novice',
        description:
          'Engages with basic creative processes and contributes ideas under guidance. Seeks inspiration from established frameworks and examples.',
      },
      {
        title: 'Intermediate',
        description:
          'Independently crafts and refines innovative ideas suitable for various contexts. Demonstrates the ability to think laterally and apply creativity systematically.',
      },
      {
        title: 'Advanced',
        description:
          'Pioneers innovative solutions and actively transforms concepts into impactful actions. Leads creative teams and inspires groundbreaking ideas in organizational contexts.',
      },
    ],
  },
  {
    title: 'Analytical Thinking',
    description:
      'Analytical thinking involves the ability to deconstruct information into smaller components in order to draw conclusions. It includes critical thinking and the ability to focus on details.',
    icon: 'skill--growth',
    levels: [
      {
        title: 'Novice',
        description:
          'Capable of basic data analysis and interpretation with support. Begins to understand how to apply analytical tools to simple datasets.',
      },
      {
        title: 'Intermediate',
        description:
          'Skillfully conducts complex analyses and interprets intricate data. Integrates diverse information sources to provide comprehensive insights.',
      },
      {
        title: 'Advanced',
        description:
          'Manipulates and leverages complex data to guide strategic decisions. Develops methodologies that redefine the analysis process for efficiency and effectiveness.',
      },
    ],
  },
  {
    title: 'Systems Thinking',
    icon: 'skill--cognitive',
    description:
      'Systems thinking is the process of understanding how things, regarded as systems, influence one another within a whole. It involves recognizing patterns and cycles in systems, rather than just individual elements.',
    levels: [
      {
        title: 'Novice',
        description:
          'Recognizes basic system components and their interactions. Learns to identify simple causal relationships within systems.',
      },
      {
        title: 'Intermediate',
        description:
          'Analyzes and elucidates complex system interactions. Understands and predicts the behaviors of systems under various conditions.',
      },
      {
        title: 'Advanced',
        description:
          'Designs and enhances complex systems for optimal performance. Leads system redesigns that significantly improve organizational efficiency and effectiveness.',
      },
    ],
  },
  {
    title: 'Data Analysis',
    icon: 'skill--testtube',
    description:
      'Data analysis involves inspecting, cleansing, transforming, and modeling data with the goal of discovering useful information, informing conclusions, and supporting decision-making.',
    levels: [
      {
        title: 'Novice',
        description:
          'Executes basic data operations with oversight. Gains familiarity with data tools and simple statistical techniques.',
      },
      {
        title: 'Intermediate',
        description:
          'Utilizes advanced tools and techniques to solve data-driven problems. Capable of managing larger datasets and performing more sophisticated analyses.',
      },
      {
        title: 'Advanced',
        description:
          'Develops predictive models and strategic insights from complex datasets. Champions the use of data science to drive decisions and innovations at the strategic level.',
      },
    ],
  },
  {
    title: 'Communication',
    icon: 'skill--bubble',
    description:
      'Communication is the act of conveying meanings from one entity or group to another through the use of mutually understood signs, symbols, and semiotic rules. It is essential for collaboration and effective functioning in every aspect of an organization.',
    levels: [
      {
        title: 'Novice',
        description:
          'Effectively conveys information in straightforward scenarios. Develops skills in presenting clear messages using basic communication tools.',
      },
      {
        title: 'Intermediate',
        description:
          'Masters diverse communication settings and styles. Adapts messaging effectively to different audiences and mediums.',
      },
      {
        title: 'Advanced',
        description:
          'Excels in persuasive and strategic communication across all platforms. Influences and shapes organizational and public opinion through expert communication skills.',
      },
    ],
  },
  {
    title: 'Writing',
    icon: 'skill--newspaper',
    description:
      'Writing is the process of using symbols (letters of the alphabet, punctuation and spaces) to communicate thoughts and ideas in a readable form. It requires not only grammar and style but also the ability to encapsulate and communicate complex concepts clearly.',
    levels: [
      {
        title: 'Novice',
        description:
          'Crafts basic texts with guidance for improvement. Learns to structure sentences and paragraphs to enhance clarity.',
      },
      {
        title: 'Intermediate',
        description:
          'Creates clear, structured documents tailored to specific purposes. Demonstrates the ability to engage and inform the reader effectively.',
      },
      {
        title: 'Advanced',
        description:
          'Produces compelling and influential content for a wide range of audiences. Leads in the creation of written materials that set industry standards or influence public policy.',
      },
    ],
  },
  {
    title: 'Multi-lingualism',
    icon: 'skill--globe--desk',
    description:
      'Multi-lingualism is the ability to communicate effectively in more than one language. It enhances cognitive and social abilities and provides advantages in professional and personal settings.',
    levels: [
      {
        title: 'Novice',
        description:
          'Manages simple conversations and texts in another language. Builds vocabulary and basic grammar skills.',
      },
      {
        title: 'Intermediate',
        description:
          'Communicates effectively in multiple languages in familiar situations. Handles complex conversations and can switch between languages smoothly.',
      },
      {
        title: 'Advanced',
        description:
          'Demonstrates fluency and cultural understanding in multiple languages for professional use. Engages in high-level negotiations or presentations in different languages.',
      },
    ],
  },
  {
    title: 'Talent Planning and Development',
    icon: 'skill--sun--horizon',
    description:
      'Talent planning and development involves identifying, developing, and nurturing talent within an organization to meet current and future business needs. It includes aspects of recruitment, training, and career progression.',
    levels: [
      {
        title: 'Novice',
        description:
          'Actively participates in talent development processes. Learns to identify potential talent and understand basic development needs.',
      },
      {
        title: 'Intermediate',
        description:
          'Effectively oversees and tracks talent development initiatives. Designs and implements development programs that enhance skills and careers.',
      },
      {
        title: 'Advanced',
        description:
          'Leads and executes comprehensive talent development strategies. Shapes the organizational culture to foster continuous learning and growth.',
      },
    ],
  },
  {
    title: 'Time Management',
    icon: 'skill--stopwatch',
    description:
      "Time management is the ability to plan and control how one spends the hours in a day to effectively accomplish their goals. This skill involves prioritizing tasks, managing distractions, and allocating time where it's most needed.",
    levels: [
      {
        title: 'Novice',
        description:
          'Manages personal time effectively with minimal reminders. Learns to prioritize tasks and recognize the importance of time allocation.',
      },
      {
        title: 'Intermediate',
        description:
          'Expertly prioritizes and allocates time across multiple tasks. Develops strategies to manage time efficiently in team settings.',
      },
      {
        title: 'Advanced',
        description:
          'Innovates and optimizes time management strategies for teams and projects. Leads initiatives that significantly enhance productivity through effective time management.',
      },
    ],
  },
  {
    title: 'Project Management',
    icon: 'skill--list--clipboard',
    description:
      'Project management involves planning, organizing, motivating, and controlling resources to achieve specific goals. It is a critical skill for any organization that works on complex projects that require precise management of logistics, budgets, and timelines.',
    levels: [
      {
        title: 'Novice',
        description:
          'Supports project tasks effectively with guidance. Learns the basics of project planning and resource allocation.',
      },
      {
        title: 'Intermediate',
        description:
          'Independently manages projects, ensuring resource and timeline coordination. Handles moderate to complex projects with minimal oversight.',
      },
      {
        title: 'Advanced',
        description:
          'Directs complex projects with diverse stakeholders and high uncertainty. Demonstrates mastery in leading large-scale and high-impact projects across the organization.',
      },
    ],
  },
  {
    title: 'Supply-chain Management',
    icon: 'skill--link',
    description:
      'Supply-chain management involves overseeing and managing the entire flow of goods and services, including all processes that transform raw materials into final products.',
    levels: [
      {
        title: 'Novice',
        description:
          'Understands the basics of supply chain operations and assists with daily tasks under supervision. Learns to coordinate with suppliers and manage inventory at a basic level.',
      },
      {
        title: 'Intermediate',
        description:
          'Manages components of the supply chain effectively, ensuring smooth operations and addressing moderate issues. Develops strategies to improve supply chain efficiency and cost-effectiveness.',
      },
      {
        title: 'Advanced',
        description:
          'Strategically oversees and optimizes the entire supply chain, driving innovations and efficiency improvements at a global scale. Implements advanced analytics to predict trends and manage risks.',
      },
    ],
  },
  {
    title: 'Accounting',
    icon: 'skill--table',
    description:
      'Accounting is the process of recording financial transactions pertaining to a business. It involves summarizing, analyzing, and reporting these transactions to oversight agencies and tax collection entities.',
    levels: [
      {
        title: 'Novice',
        description:
          'Performs basic accounting tasks such as data entry and managing invoices under close supervision. Begins to understand financial regulations and reporting requirements.',
      },
      {
        title: 'Intermediate',
        description:
          'Handles complex accounting activities, prepares financial statements, and ensures compliance with regulations. Analyzes financial data to support business decisions and operational improvements.',
      },
      {
        title: 'Advanced',
        description:
          'Leads accounting strategies, oversees financial operations, and provides expert advice for financial decision-making. Develops and implements policies to enhance financial transparency and integrity.',
      },
    ],
  },
  {
    title: 'Finance',
    icon: 'skill--credit--card',
    description:
      'Finance involves the management of large amounts of money, especially by governments or large companies. It includes budgeting, borrowing, forecasting, investing, lending, and saving.',
    levels: [
      {
        title: 'Novice',
        description:
          'Understands fundamental financial concepts and assists with financial planning and simple analyses. Begins to participate in budget preparation and financial forecasting.',
      },
      {
        title: 'Intermediate',
        description:
          'Manages and optimizes financial resources, conducts detailed financial analyses, and contributes to financial strategy. Oversees investment decisions and assesses financial risks.',
      },
      {
        title: 'Advanced',
        description:
          'Directs corporate finance strategies, oversees large-scale investments, and drives financial policy and innovation. Provides leadership in financial negotiations and strategic business expansions.',
      },
    ],
  },
  {
    title: 'Quality Assurance',
    icon: 'skill--level',
    description:
      'Quality assurance is the maintenance of a desired level of quality in a service or product, especially by means of attention to every stage of the process of delivery or production.',
    levels: [
      {
        title: 'Novice',
        description:
          'Conducts basic quality checks and learns to use tools for maintaining quality standards. Gains understanding of quality control processes and compliance requirements.',
      },
      {
        title: 'Intermediate',
        description:
          'Develops and implements quality assurance processes, conducts audits, and improves system reliability. Trains staff on quality standards and leads initiatives to enhance product quality.',
      },
      {
        title: 'Advanced',
        description:
          'Leads quality assurance programs, sets organizational quality standards, and ensures compliance with international quality norms. Champions continuous improvement and total quality management.',
      },
    ],
  },
  {
    title: 'Risk Management',
    icon: 'skill--umbrella',
    description:
      'Risk management involves identifying, assessing, and prioritizing risks followed by coordinated efforts to minimize, monitor, and control the probability or impact of unfortunate events.',
    levels: [
      {
        title: 'Novice',
        description:
          'Learns to identify basic risks and assists in the implementation of mitigation strategies. Understands the fundamentals of risk analysis and insurance options.',
      },
      {
        title: 'Intermediate',
        description:
          'Manages risk assessments and mitigation plans, ensuring they are integrated into organizational processes. Develops risk management policies and conducts training on risk awareness.',
      },
      {
        title: 'Advanced',
        description:
          'Designs and leads comprehensive risk management strategies that protect and enhance organizational value. Advises senior management on risk avoidance and risk transfer strategies.',
      },
    ],
  },
  {
    title: 'Sales',
    icon: 'skill--party--popper',
    description:
      'Sales involve the exchange of a commodity for money; the action of selling something. It includes understanding customer needs, marketing, negotiating, and closing deals.',
    levels: [
      {
        title: 'Novice',
        description:
          'Understands the basics of the sales process and supports sales operations under guidance. Learns product knowledge and customer engagement techniques.',
      },
      {
        title: 'Intermediate',
        description:
          'Manages sales transactions, develops relationships with customers, and achieves sales targets. Implements sales tactics and strategies to increase market share.',
      },
      {
        title: 'Advanced',
        description:
          'Strategically leads sales teams, designs sales strategies, and drives major revenue-generating initiatives. Mentors sales personnel and establishes performance benchmarks.',
      },
    ],
  },
  {
    title: 'Productivity',
    icon: 'skill--list--checked',
    description:
      'Productivity is the effectiveness of productive effort, especially in industry, as measured in terms of the rate of output per unit of input.',
    levels: [
      {
        title: 'Novice',
        description:
          'Learns and applies basic techniques to improve personal and team productivity. Begins to understand workflow management and efficiency tools.',
      },
      {
        title: 'Intermediate',
        description:
          'Implements productivity tools and techniques effectively across teams, improving operational efficiency. Analyzes productivity metrics to identify areas for improvement.',
      },
      {
        title: 'Advanced',
        description:
          'Leads organizational initiatives to maximize productivity through innovative methods and strategic planning. Develops comprehensive programs that enhance both individual and collective productivity.',
      },
    ],
  },
  {
    title: 'Marketing',
    icon: 'skill--lightbulb',
    description:
      "Marketing involves promoting and selling products or services, including market research and advertising. It's about understanding consumer needs and developing strategies to meet those needs.",
    levels: [
      {
        title: 'Novice',
        description:
          'Assists in the execution of marketing campaigns and learns about market analysis techniques. Gains experience in content creation and digital marketing basics.',
      },
      {
        title: 'Intermediate',
        description:
          'Develops and implements marketing strategies, analyzes market trends, and adjusts campaigns for effectiveness. Coordinates cross-functional teams to ensure cohesive brand messaging.',
      },
      {
        title: 'Advanced',
        description:
          'Leads marketing departments, crafts strategic marketing visions, and pioneers market expansion techniques. Utilizes advanced analytics to measure impact and optimize marketing ROI.',
      },
    ],
  },
  {
    title: 'Customer Service',
    icon: 'skill--phone',
    description:
      'Customer service is the assistance and advice provided by a company to those people who buy or use its products or services. It involves ensuring customer satisfaction with a product or service.',
    levels: [
      {
        title: 'Novice',
        description:
          'Handles basic customer inquiries and learns to resolve common issues effectively. Develops skills in communication and problem-solving.',
      },
      {
        title: 'Intermediate',
        description:
          'Manages complex customer service situations, ensures high levels of customer satisfaction, and trains others. Implements feedback systems to improve service quality.',
      },
      {
        title: 'Advanced',
        description:
          'Oversees customer service operations, sets standards for service excellence, and ensures alignment with business goals. Develops strategies to enhance customer loyalty and retention.',
      },
    ],
  },
  {
    title: 'Customer Relationship Management',
    icon: 'skill--favorite',
    description:
      'Customer relationship management (CRM) is a process in which a business or other organization administers its interactions with customers, typically using data analysis to study large amounts of information.',
    levels: [
      {
        title: 'Novice',
        description:
          'Understands basic CRM tools and processes, and manages customer data entry. Begins to analyze customer data to improve service and engagement.',
      },
      {
        title: 'Intermediate',
        description:
          'Utilizes CRM systems to enhance customer relationships and streamline communication. Develops targeted marketing strategies based on customer data insights.',
      },
      {
        title: 'Advanced',
        description:
          'Leads the development and implementation of CRM strategies, enhancing customer engagement and organizational growth. Integrates CRM with other business systems to provide comprehensive insights.',
      },
    ],
  },
  {
    title: 'Collaboration',
    icon: 'skill--user--group',
    description:
      'Collaboration involves working jointly with others to achieve a common goal. It includes effective communication, conflict resolution, and teamwork.',
    levels: [
      {
        title: 'Novice',
        description:
          'Participates in team activities and learns to communicate effectively with team members. Begins to understand the dynamics of group work and contributes to team discussions.',
      },
      {
        title: 'Intermediate',
        description:
          'Facilitates team interactions, mediates conflicts, and contributes significantly to achieving team goals. Develops strategies for effective teamwork and enhances group productivity.',
      },
      {
        title: 'Advanced',
        description:
          'Leads collaborative efforts, designs team structures, and cultivates a culture of cooperation and mutual respect across the organization. Sets standards for collaborative excellence and mentors teams in advanced collaborative techniques.',
      },
    ],
  },
  {
    title: 'Manufacturing',
    icon: 'skill--hammer',
    description:
      'Manufacturing involves the production of goods using labor, machines, tools, and chemical or biological processing.',
    levels: [
      {
        title: 'Novice',
        description:
          'Assists in basic manufacturing tasks and learns about production processes and safety standards. Gains hands-on experience with manufacturing equipment and starts to understand workflow optimization.',
      },
      {
        title: 'Intermediate',
        description:
          'Manages production lines, oversees quality control, and optimizes manufacturing processes. Implements innovations in production techniques and trains staff in new manufacturing technologies.',
      },
      {
        title: 'Advanced',
        description:
          'Directs manufacturing operations, develops strategies for efficiency and sustainability, and leads industry advancements in manufacturing technologies. Champions cutting-edge manufacturing practices and sustainability initiatives.',
      },
    ],
  },
  {
    title: 'Installation',
    icon: 'skill--wrench--and--screwdriver',
    description:
      'Installation refers to setting up and configuring equipment or systems to make them operational. This includes understanding technical specifications and ensuring proper setup.',
    levels: [
      {
        title: 'Novice',
        description:
          'Performs basic installation tasks under supervision and learns to follow installation guides and specifications. Develops skills in handling tools and equipment safely and efficiently.',
      },
      {
        title: 'Intermediate',
        description:
          'Independently performs installations, troubleshoots issues, and ensures systems are operational according to standards. Manages medium-scale installation projects and mentors junior installers.',
      },
      {
        title: 'Advanced',
        description:
          'Oversees large-scale installations, designs installation protocols, and ensures high standards of accuracy and reliability across projects. Leads strategic installation initiatives and develops best practice frameworks.',
      },
    ],
  },
  {
    title: 'Programming',
    icon: 'skill--code',
    description:
      'Programming involves writing, testing, debugging, and maintaining the source code of computer programs. This skill is fundamental in software development and computer science.',
    levels: [
      {
        title: 'Novice',
        description:
          'Writes simple code under guidance and learns basic programming languages and debugging techniques. Develops a foundational understanding of software development cycles and version control systems.',
      },
      {
        title: 'Intermediate',
        description:
          'Develops complex software applications, optimizes code, and integrates systems effectively. Understands advanced programming concepts and works proficiently in multiple programming environments.',
      },
      {
        title: 'Advanced',
        description:
          'Leads software development projects, architects complex systems, and pioneers new software technologies and methodologies. Mentors developers and sets industry standards in software engineering.',
      },
    ],
  },
  {
    title: 'Mobile Development',
    icon: 'skill--phone',
    description:
      'Mobile development is the process of creating software applications that run on mobile devices. It requires knowledge of mobile operating systems and mobile user interfaces.',
    levels: [
      {
        title: 'Novice',
        description:
          'Learns basic mobile programming languages and assists in simple app development tasks. Gains experience with mobile design principles and starts to build small applications.',
      },
      {
        title: 'Intermediate',
        description:
          'Designs and builds functional mobile applications, ensures user-friendly interfaces, and tests for performance across devices. Manages multiple app projects and optimizes applications for various mobile platforms.',
      },
      {
        title: 'Advanced',
        description:
          'Leads mobile development teams, sets standards for mobile software excellence, and innovates in mobile computing technologies. Develops strategic partnerships and drives the adoption of new mobile technologies.',
      },
    ],
  },
  {
    title: 'Web Development',
    icon: 'skill--keyboard',
    description:
      'Web development involves building and maintaining websites. It encompasses web design, web content development, client-side/server-side scripting, and network security configuration.',
    levels: [
      {
        title: 'Novice',
        description:
          'Assists in website maintenance and learns HTML, CSS, and basic scripting languages. Begins to engage with client-side scripting and responsive design principles.',
      },
      {
        title: 'Intermediate',
        description:
          'Creates robust and scalable websites, implements advanced features, and optimizes for performance and security. Manages complex web projects and integrates cutting-edge web technologies.',
      },
      {
        title: 'Advanced',
        description:
          'Leads complex web development projects, designs architectural strategies for large-scale web applications, and innovates in web technologies. Sets benchmarks for web performance and security standards.',
      },
    ],
  },
  {
    title: 'Computer Hardware and Networking',
    icon: 'skill--connection',
    description:
      'This field involves the physical components of computers and the network systems that link them. It includes the installation, configuration, and maintenance of hardware and network services.',
    levels: [
      {
        title: 'Novice',
        description:
          'Assists with basic hardware setups and learns networking fundamentals. Begins to troubleshoot simple hardware issues and understands basic network configurations.',
      },
      {
        title: 'Intermediate',
        description:
          'Manages network operations, performs complex hardware installations, and ensures system compatibility and security. Designs and implements network solutions for medium to large environments.',
      },
      {
        title: 'Advanced',
        description:
          'Designs and oversees large-scale network infrastructures, develops hardware deployment strategies, and ensures high levels of system performance and security. Leads innovations in network architecture and data center management.',
      },
    ],
  },
  {
    title: 'Cybersecurity',
    icon: 'skill--key',
    description:
      'Cybersecurity involves protecting systems, networks, and programs from digital attacks. It includes implementing practices to secure sensitive information and prevent unauthorized access.',
    levels: [
      {
        title: 'Novice',
        description:
          'Learns basic security protocols and assists in implementing security measures. Begins to understand threat landscapes and participates in security audits.',
      },
      {
        title: 'Intermediate',
        description:
          'Manages security systems, conducts vulnerability assessments, and responds to security breaches effectively. Develops and implements security policies and trains staff on security best practices.',
      },
      {
        title: 'Advanced',
        description:
          'Leads cybersecurity initiatives, develops comprehensive security frameworks, and advises on policies to protect against advanced cyber threats. Establishes organizational resilience and crisis management strategies.',
      },
    ],
  },
  {
    title: 'Cloud Computing',
    icon: 'skill--cloud',
    description:
      'Cloud computing is the delivery of different services through the Internet, including data storage, servers, databases, networking, and software.',
    levels: [
      {
        title: 'Novice',
        description:
          'Learns basic cloud services and assists in managing cloud storage and simple cloud applications. Becomes familiar with cloud platforms and starts to understand scalability and security issues.',
      },
      {
        title: 'Intermediate',
        description:
          'Implements and manages robust cloud solutions, optimizes cloud resource utilization, and ensures data security. Manages complex cloud migrations and integrates cloud services with existing IT infrastructure.',
      },
      {
        title: 'Advanced',
        description:
          'Architects complex cloud infrastructures, leads cloud transformation projects, and innovates in cloud technology deployment and security. Develops strategic cloud policies and guides large-scale cloud adoption.',
      },
    ],
  },
  {
    title: 'Self-awareness',
    icon: 'skill--location--circle',
    description:
      "Self-awareness is the conscious knowledge of one's own character, feelings, motives, and desires. It plays a crucial role in personal development and emotional intelligence.",
    levels: [
      {
        title: 'Novice',
        description:
          'Reflects on personal strengths and weaknesses and begins to seek feedback to understand personal behaviors. Engages in self-reflection regularly and starts to apply insights to improve personal effectiveness.',
      },
      {
        title: 'Intermediate',
        description:
          'Demonstrates a clear understanding of personal impact on others, manages emotions effectively, and uses self-knowledge to improve interactions. Actively seeks growth opportunities and enhances emotional intelligence.',
      },
      {
        title: 'Advanced',
        description:
          'Exhibits high levels of self-awareness, mentors others in personal development, and uses deep self-knowledge to lead and inspire organizational change. Champions emotional intelligence and self-awareness as core organizational values.',
      },
    ],
  },
  {
    title: 'Self-control',
    icon: 'skill--stop--outlined',
    description:
      "Self-control is the ability to regulate one's emotions, thoughts, and behaviors in response to external demands.",
    levels: [
      {
        title: 'Novice',
        description:
          'Begins to recognize personal triggers and attempts to manage reactions in challenging situations. Learns basic techniques to maintain composure.',
      },
      {
        title: 'Intermediate',
        description:
          'Effectively uses strategies to control impulses and manage emotions during stressful situations. Demonstrates consistent self-discipline in personal and professional settings.',
      },
      {
        title: 'Advanced',
        description:
          'Masters self-control, teaches others how to manage their emotions and behaviors, and consistently maintains poise in highly stressful or adverse situations.',
      },
    ],
  },
  {
    title: 'Working Independently',
    icon: 'skill--figure--walk',
    description:
      "Working independently involves completing tasks effectively without supervision. It requires self-motivation and the ability to solve problems on one's own.",
    levels: [
      {
        title: 'Novice',
        description:
          'Performs simple tasks independently and seeks guidance when necessary. Begins to manage time effectively to meet deadlines.',
      },
      {
        title: 'Intermediate',
        description:
          'Manages more complex tasks independently, makes informed decisions without supervision, and efficiently organizes workload.',
      },
      {
        title: 'Advanced',
        description:
          'Excels in autonomously driving projects, innovates without external input, and mentors others in developing their independent working skills.',
      },
    ],
  },
  {
    title: 'Initiative',
    icon: 'skill--laser--burst',
    description:
      'Initiative is taking action proactively. It involves recognizing and doing what needs to be done before being asked to do it.',
    levels: [
      {
        title: 'Novice',
        description:
          'Shows willingness to take on responsibilities and assists others when needed. Starts to identify areas where help is needed without being told.',
      },
      {
        title: 'Intermediate',
        description:
          'Regularly seeks new tasks and challenges, proposes solutions to problems before escalation, and leads by example in taking proactive steps.',
      },
      {
        title: 'Advanced',
        description:
          'Drives major projects and strategic changes within the organization, inspires a culture of proactivity, and is recognized as a leader in initiating impactful actions.',
      },
    ],
  },
  {
    title: 'Prioritization',
    icon: 'skill--list--numbered',
    description:
      'Prioritization involves the ability to decide which tasks are most important and to focus on those tasks in order to improve efficiency and effectiveness.',
    levels: [
      {
        title: 'Novice',
        description:
          'Learns to distinguish between urgent and important tasks. Begins to use basic tools to organize daily activities.',
      },
      {
        title: 'Intermediate',
        description:
          'Effectively manages and adjusts priorities based on project demands or deadlines. Balances multiple responsibilities smoothly and efficiently.',
      },
      {
        title: 'Advanced',
        description:
          'Strategically sets priorities for teams and projects, aligns organizational resources to critical goals, and mentors others in advanced prioritization techniques.',
      },
    ],
  },
  {
    title: 'Attention to Detail',
    icon: 'skill--pin',
    description:
      'Attention to detail is the ability to achieve thoroughness and accuracy when accomplishing a task.',
    levels: [
      {
        title: 'Novice',
        description:
          'Notices basic errors and starts to pay closer attention to detail in own work. Develops a routine to check work for mistakes.',
      },
      {
        title: 'Intermediate',
        description:
          'Consistently produces error-free work by systematically managing tasks and reviewing all details. Trains others to improve their observational skills.',
      },
      {
        title: 'Advanced',
        description:
          'Sets standards for accuracy and thoroughness in the organization, leads quality assurance initiatives, and develops systems to minimize errors across teams.',
      },
    ],
  },
  {
    title: 'Mental Health',
    icon: 'skill--bandage',
    description:
      'Mental health involves maintaining a balanced psychological state. This includes managing emotions, stress, and mental health challenges effectively.',
    levels: [
      {
        title: 'Novice',
        description:
          'Becomes aware of personal mental health needs and seeks resources to enhance well-being. Learns basic stress-relief techniques.',
      },
      {
        title: 'Intermediate',
        description:
          'Actively manages mental health using a variety of strategies, maintains resilience in stressful situations, and supports colleagues in recognizing mental health signs.',
      },
      {
        title: 'Advanced',
        description:
          'Advocates for mental health awareness, creates supportive environments for mental well-being, and leads organizational initiatives to promote mental health.',
      },
    ],
  },
  {
    title: 'Stress Management',
    icon: 'skill--thermometer',
    description:
      "Stress management involves techniques and strategies to control a person's level of stress, especially chronic stress, in order to improve everyday functioning.",
    levels: [
      {
        title: 'Novice',
        description:
          'Recognizes personal stressors and begins to apply basic stress reduction techniques. Learns to maintain functionality under stress.',
      },
      {
        title: 'Intermediate',
        description:
          'Effectively uses advanced stress management techniques and maintains high performance under pressure. Helps peers in developing their stress management strategies.',
      },
      {
        title: 'Advanced',
        description:
          'Masters stress management, trains others in stress resilience, and implements organizational strategies to reduce workplace stress.',
      },
    ],
  },
  {
    title: 'Change Management',
    icon: 'skill--turn--up--right',
    description:
      'Change management involves preparing, supporting, and helping individuals, teams, and organizations in making organizational change.',
    levels: [
      {
        title: 'Novice',
        description:
          'Understands the basics of change and adapts to new processes or environments with support. Begins to assist others through change.',
      },
      {
        title: 'Intermediate',
        description:
          'Manages transitions effectively, supports team members during change, and communicates benefits of change to enhance adaptation.',
      },
      {
        title: 'Advanced',
        description:
          'Leads change management initiatives, designs strategies for seamless organizational change, and mentors leaders on effective change management.',
      },
    ],
  },
  {
    title: 'Persistence',
    icon: 'skill--signpost--right',
    description:
      'Persistence is the ability to keep doing something despite difficulty or delay in achieving success.',
    levels: [
      {
        title: 'Novice',
        description:
          'Shows initial effort to overcome obstacles but may require encouragement. Learns to stay focused and continue working towards goals despite challenges.',
      },
      {
        title: 'Intermediate',
        description:
          'Overcomes significant challenges through sustained effort and determination. Motivates others to persist by setting an example of resilience.',
      },
      {
        title: 'Advanced',
        description:
          'Demonstrates exceptional perseverance in the face of adversity. Leads major projects to success despite significant obstacles and inspires a culture of persistence.',
      },
    ],
  },
  {
    title: 'Empathy',
    icon: 'skill--theatermasks',
    description:
      'Empathy is the ability to understand and share the feelings of another. It is crucial for developing deep and meaningful human connections.',
    levels: [
      {
        title: 'Novice',
        description:
          'Begins to recognize emotional cues in others and responds with basic empathetic communication. Learns to listen actively and show support.',
      },
      {
        title: 'Intermediate',
        description:
          'Shows deep understanding of others’ emotions and perspectives. Effectively communicates empathy and supports others in emotional distress.',
      },
      {
        title: 'Advanced',
        description:
          'Masters empathy in personal and professional contexts, teaches empathy skills, and fosters an empathetic culture within the organization.',
      },
    ],
  },
  {
    title: 'Asking Questions',
    icon: 'skill--help',
    description:
      'Asking questions involves the ability to inquire for information for clarity and understanding. It is essential for learning and effective communication.',
    levels: [
      {
        title: 'Novice',
        description:
          'Learns to ask basic questions to gain information and clarify understanding. Begins to feel comfortable inquiring during discussions.',
      },
      {
        title: 'Intermediate',
        description:
          'Asks insightful questions that facilitate deeper understanding and promote discussion. Encourages others to ask questions by fostering an open environment.',
      },
      {
        title: 'Advanced',
        description:
          'Excels in using questioning as a tool to lead discussions, uncover information, and drive innovation. Mentors others in the art of critical questioning.',
      },
    ],
  },
  {
    title: 'Feedback',
    icon: 'skill--star--bubble',
    description:
      'Feedback involves giving and receiving constructive criticism to foster growth and improve performance.',
    levels: [
      {
        title: 'Novice',
        description:
          'Begins to understand the importance of feedback and practices giving basic, constructive comments. Learns to accept feedback graciously.',
      },
      {
        title: 'Intermediate',
        description:
          'Provides thoughtful, specific feedback that balances strengths and areas for improvement. Actively seeks feedback to enhance personal growth.',
      },
      {
        title: 'Advanced',
        description:
          'Masters the art of giving and receiving feedback, facilitates feedback training sessions, and creates a culture of open communication and continuous improvement.',
      },
    ],
  },
  {
    title: 'Mentoring',
    icon: 'skill--graduation--cap',
    description:
      'Mentoring involves guiding a less experienced person by building trust and modeling positive behaviors.',
    levels: [
      {
        title: 'Novice',
        description:
          'Assists in mentoring activities under supervision, learning to provide support and advice to mentees. Begins to understand the mentor-mentee dynamics.',
      },
      {
        title: 'Intermediate',
        description:
          'Effectively mentors individuals, providing guidance, support, and encouragement to help them grow professionally and personally.',
      },
      {
        title: 'Advanced',
        description:
          'Leads mentoring programs, trains other mentors, and shapes organizational mentoring strategies to foster talent development and succession planning.',
      },
    ],
  },
  {
    title: 'Coaching',
    icon: 'skill--sport--field',
    description:
      'Coaching is a development process where an experienced person supports a learner in achieving specific personal or professional goals.',
    levels: [
      {
        title: 'Novice',
        description:
          'Learns coaching techniques and begins to apply them in supporting others in simpler contexts. Focuses on goal clarity and basic skill development.',
      },
      {
        title: 'Intermediate',
        description:
          'Provides effective coaching by helping others identify their goals, encouraging self-discovery, and offering constructive feedback.',
      },
      {
        title: 'Advanced',
        description:
          'Excels in advanced coaching methodologies, customizes approaches for individual needs, and mentors other coaches in developing their skills.',
      },
    ],
  },
  {
    title: 'Negotiation',
    icon: 'skill--bubble--left--and--right',
    description:
      'Negotiation is the process of discussing an issue to reach an agreement or compromise between parties.',
    levels: [
      {
        title: 'Novice',
        description:
          'Learns basic negotiation strategies and practices applying them in non-critical negotiations. Begins to understand the importance of win-win solutions.',
      },
      {
        title: 'Intermediate',
        description:
          'Effectively negotiates by understanding and articulating the needs of all parties, and works towards mutually beneficial solutions.',
      },
      {
        title: 'Advanced',
        description:
          'Masters negotiation techniques, handles high-stakes or complex negotiations with ease, and trains others in advanced negotiation skills.',
      },
    ],
  },
  {
    title: 'Ethical Leadership',
    icon: 'skill--shield',
    description:
      'Ethical leadership involves leading by example in a manner that respects ethical beliefs and values, and inspires others to follow those principles.',
    levels: [
      {
        title: 'Novice',
        description:
          'Understands basic ethical standards and practices making decisions that reflect integrity and fairness.',
      },
      {
        title: 'Intermediate',
        description:
          'Demonstrates consistent ethical behavior and decision-making. Actively promotes ethics in professional settings and addresses ethical dilemmas effectively.',
      },
      {
        title: 'Advanced',
        description:
          'Champions ethical leadership, sets organizational ethics policies, and is recognized as a role model for integrity and ethical decision-making.',
      },
    ],
  },
  {
    title: 'Social Justice',
    icon: 'skill--megaphone',
    description:
      'Social justice involves promoting a fair society by challenging injustice and valuing diversity.',
    levels: [
      {
        title: 'Novice',
        description:
          'Becomes aware of social justice issues and participates in discussions or activities focused on equity. Learns about the impact of social inequalities.',
      },
      {
        title: 'Intermediate',
        description:
          'Actively advocates for social justice, engages in initiatives to promote equity, and educates others about the importance of diversity and inclusion.',
      },
      {
        title: 'Advanced',
        description:
          'Leads transformative social justice initiatives, influences policy changes, and mentors others in advocating for systemic changes in society.',
      },
    ],
  },
  {
    title: 'Socio-cultural Awareness',
    icon: 'skill--ear',
    description:
      'Socio-cultural awareness involves understanding, respecting, and valuing diverse cultural perspectives and practices.',
    levels: [
      {
        title: 'Novice',
        description:
          'Begins to recognize and appreciate cultural differences. Learns about various cultural norms and practices.',
      },
      {
        title: 'Intermediate',
        description:
          'Demonstrates sensitivity and adaptability to different cultural contexts. Facilitates cross-cultural communication and collaboration.',
      },
      {
        title: 'Advanced',
        description:
          'Leads initiatives to enhance socio-cultural competence within organizations. Develops strategies to integrate cultural awareness into business practices and community engagements.',
      },
    ],
  },
  {
    title: 'Technology Ethics',
    icon: 'skill--gear',
    description:
      'Technology ethics involves the study of ethical issues associated with the use of technology, including considerations of privacy, responsibility, and the impact on society.',
    levels: [
      {
        title: 'Novice',
        description:
          'Learns about basic ethical issues related to technology use. Begins to apply ethical considerations in the use of personal and organizational technology.',
      },
      {
        title: 'Intermediate',
        description:
          'Analyzes and addresses ethical dilemmas in technology, advocating for ethical technology use. Implements policies to ensure ethical standards in technology deployment.',
      },
      {
        title: 'Advanced',
        description:
          'Leads discussions and policy-making on technology ethics, shaping industry standards and promoting responsible innovation and technology use.',
      },
    ],
  },
  {
    title: 'Sustainability',
    icon: 'skill--tree',
    description:
      'Sustainability involves practices that do not deplete natural resources and thus can be maintained long-term. It focuses on environmental, social, and economic health.',
    levels: [
      {
        title: 'Novice',
        description:
          'Becomes aware of sustainability issues and participates in basic sustainability practices. Learns about the impact of sustainable and unsustainable practices.',
      },
      {
        title: 'Intermediate',
        description:
          'Implements sustainable practices in personal and professional areas. Advocates for sustainability and educates others about its benefits.',
      },
      {
        title: 'Advanced',
        description:
          'Champions sustainability initiatives, leads organizational strategies for sustainable development, and influences public and private policies towards sustainable practices.',
      },
    ],
  },
] as const
export const SANA_TAXONOMY: Array<TAXONOMY> = _SANA_TAXONOMY.sort((a, b) => a.title.localeCompare(b.title))
