import React, { useState } from 'react'
import { useTranslation } from 'sierra-client/hooks/use-translation'
import { EventForm } from 'sierra-client/views/manage/event-groups/components/event-form'
import {
  formatEventSchedule,
  getEventLocationString,
} from 'sierra-client/views/manage/event-groups/event-utils'
import {
  CalendarEventSchedule,
  CreateCalendarEventData,
  EventLocation,
} from 'sierra-client/views/manage/event-groups/types'
import { IdentityWithMetadata } from 'sierra-domain/api/manage'
import { Icon } from 'sierra-ui/components'
import { IconButton, Text, View } from 'sierra-ui/primitives'
import { token } from 'sierra-ui/theming'
import { dotSeparator } from 'sierra-ui/utils'
import styled from 'styled-components'

const EventIconsContainer = styled(View)`
  position: absolute;
  right: 0px;
  top: 0;
  gap: 0;
`

const StyledIconButton = styled(IconButton).attrs({ variant: 'transparent', size: 'small' })`
  color: ${token('foreground/muted')};

  &:hover {
    background: unset;
    color: ${token('foreground/primary')};
  }
`

const IconCircle = styled.div`
  width: 32px;
  height: 32px;
  border-radius: 50%;
  background-color: ${token('border/default')};
  display: flex;
  justify-content: center;
  align-items: center;
`

const VerticalEventLineContainer = styled(View)`
  height: 100%;
`

const VerticalLine = styled.div`
  width: 4px;
  background-color: ${token('border/default')};
  flex-grow: 1;
  border-radius: 2px;
`

const SessionText = styled(Text)`
  width: max-content;
`

export const CollapsableEventForm: React.FC<{
  event: CreateCalendarEventData
  removeEvent: () => void
  updateSchedule: (newSchedule: CalendarEventSchedule) => void
  updateLocation: (newLocation: EventLocation | undefined) => void
  updateParticipantIdentities?: (newParticipantIdentities: IdentityWithMetadata[]) => void | undefined
  updateFacilitatorIdentities: (newFacilitatorIdentities: IdentityWithMetadata[]) => void
}> = ({
  event,
  removeEvent,
  updateSchedule,
  updateLocation,
  updateParticipantIdentities,
  updateFacilitatorIdentities,
}) => {
  const { t } = useTranslation()

  const [hover, setHover] = useState(false)

  const [collapsed, setCollapsed] = useState(false)
  const toggleCollapse = (): void => setCollapsed(!collapsed)

  return (
    <View
      position='relative'
      onMouseEnter={() => setHover(true)}
      onMouseLeave={() => setHover(false)}
      alignItems='center'
    >
      {hover && (
        <EventIconsContainer>
          <StyledIconButton iconId='trash-can' onClick={removeEvent} />
          <StyledIconButton iconId={collapsed ? 'chevron--down' : 'chevron--up'} onClick={toggleCollapse} />
        </EventIconsContainer>
      )}

      {!collapsed && (
        <VerticalEventLineContainer direction='column' alignItems='center' justifyContent='flex-start'>
          <IconCircle>
            <Icon iconId='calendar' />
          </IconCircle>
          <VerticalLine />
        </VerticalEventLineContainer>
      )}

      {collapsed ? (
        <View grow>
          <IconCircle>
            <Icon iconId='calendar' />
          </IconCircle>
          <Text bold color='foreground/muted'>
            {getEventLocationString(event.location) ?? t('events.no-location')} {dotSeparator}{' '}
            {formatEventSchedule(event.schedule, { month: 'long' })}
          </Text>
        </View>
      ) : (
        <View grow direction='column' gap='8' paddingTop='6'>
          <SessionText bold color='foreground/muted'>
            {t('dictionary.session')}
          </SessionText>
          <EventForm
            event={event}
            updateSchedule={updateSchedule}
            updateLocation={updateLocation}
            updateParticipantIdentities={updateParticipantIdentities}
            updateFacilitatorIdentities={updateFacilitatorIdentities}
          />
        </View>
      )}
    </View>
  )
}
