import { useAtom } from 'jotai'
import { DateTime } from 'luxon'
import React from 'react'
import { useLivePageContext } from 'sierra-client/components/liveV2/contexts/live-page'
import { useLiveTranscription } from 'sierra-client/components/liveV2/right-sidebar/use-live-transcription'
import {
  LanguageBehavior,
  ModelType,
  transcriptionsSettingsAtom,
} from 'sierra-client/components/liveV2/transcription-controller'
import { useIsDebugMode } from 'sierra-client/hooks/use-is-debug-mode'
import { useUserLegacy } from 'sierra-client/state/users/hooks'
import { getAvatarImage } from 'sierra-client/utils/avatar-img'
import { LiveSessionTranscription } from 'sierra-domain/api/live-session'
import { getUserName } from 'sierra-domain/utils'
import { MenuItem, RoundAvatar } from 'sierra-ui/components'
import { Button, ScrollView, Text, View } from 'sierra-ui/primitives'
import { SingleSelectDropdown } from 'sierra-ui/primitives/menu-dropdown'
import { legacyLight } from 'sierra-ui/theming/legacy-theme'
import styled, { ThemeProvider } from 'styled-components'

const Container = styled(View).attrs({
  direction: 'column',
  margin: 'none',
  gap: 'none',
})`
  height: 100%;
  overflow: hidden;
  color: ${p => p.theme.home.textColor};
  background-color: ${p => p.theme.home.backgroundColor};
  border-radius: ${p => p.theme.borderRadius.regular};
  border: none;
`

const modelTypeItems: MenuItem<string>[] = ModelType.options.map(model => ({
  type: 'label',
  id: model,
  label: model,
}))

const languageBehaviorItems: MenuItem<string>[] = LanguageBehavior.options.map(behavior => ({
  type: 'label',
  id: behavior,
  label: behavior,
}))

const ChangeTranscriptionSettings: React.FC = () => {
  const [settings, setSettings] = useAtom(transcriptionsSettingsAtom)

  return (
    <>
      <SingleSelectDropdown
        menuItems={modelTypeItems}
        selectedItem={modelTypeItems.find(item => item.id === settings.modelType)}
        onSelect={item => setSettings(prev => ({ ...prev, modelType: ModelType.parse(item.id) }))}
      />

      <SingleSelectDropdown
        menuItems={languageBehaviorItems}
        selectedItem={languageBehaviorItems.find(item => item.id === settings.languageBehavior)}
        onSelect={item =>
          setSettings(prev => ({ ...prev, languageBehavior: LanguageBehavior.parse(item.id) }))
        }
      />
    </>
  )
}

const TranscriptionSegment: React.FC<{
  segment: LiveSessionTranscription
}> = ({ segment }) => {
  const startTime = segment.startTime !== undefined ? DateTime.fromSeconds(segment.startTime) : undefined
  const endTime = segment.endTime !== undefined ? DateTime.fromSeconds(segment.endTime) : undefined
  const user = useUserLegacy(segment.userId)

  return (
    <View direction='column'>
      {user !== undefined && (
        <View alignItems='center'>
          <RoundAvatar
            size='tiny'
            firstName={user.firstName}
            src={getAvatarImage(user.uuid, user.avatar)}
            color={user.avatarColor}
          />
          <Text size='small' color='grey40' bold>
            {getUserName(user)}
          </Text>
          {startTime !== undefined && endTime !== undefined && (
            <Text size='small' color='grey40'>
              {endTime.hour > 0
                ? `${startTime.toFormat('hh:mm:ss')} - ${endTime.toFormat('hh:mm:ss')}`
                : `${startTime.toFormat('mm:ss')} - ${endTime.toFormat('mm:ss')}`}
            </Text>
          )}
        </View>
      )}
      <Text>{segment.text}</Text>
    </View>
  )
}

export const LiveTranscription: React.FC = () => {
  const { liveSessionId } = useLivePageContext()
  const isDebugMode = useIsDebugMode()

  const { transcriptions, refetch, isLoading, isError } = useLiveTranscription(liveSessionId)

  if (!isDebugMode) return null

  return (
    <ThemeProvider theme={legacyLight}>
      <Container padding='16'>
        <Text bold>Live transcriptions</Text>
        {isLoading && <Text bold>Loading</Text>}
        {isError && <Text bold>Error</Text>}
        <Button onClick={refetch}>refetch</Button>

        <ChangeTranscriptionSettings />

        <ScrollView gap='small' padding='small' paddingBottom='none' grow>
          {transcriptions?.map((transcription, index) => (
            <TranscriptionSegment key={index} segment={transcription} />
          ))}
        </ScrollView>
      </Container>
    </ThemeProvider>
  )
}
