import { XRealtimeAdminCoursesListEnrolledUsers } from 'sierra-domain/routes'

import { useSearch } from '@tanstack/react-router'
import _ from 'lodash'
import { Dispatch, SetStateAction, useCallback, useEffect, useMemo } from 'react'
import { useThrottledAndLiveState } from 'sierra-client/hooks/use-throttled-state'
import { removeSearchParams } from 'sierra-client/router/search-params'
import { useCachedQuery } from 'sierra-client/state/api'
import { CourseStatus, ListContentEnrolledUsersResponse } from 'sierra-domain/api/manage'
import { EventGroupId } from 'sierra-domain/api/nano-id'

export type LSFilter = {
  groups: string[]
  programs: string[]
  assignedToCalendarEvent?: 'assigned' | 'not-assigned'
  status?: CourseStatus
  query: string
}

const parseAssignmentFilter = (value: LSFilter['assignedToCalendarEvent']): boolean | undefined => {
  if (value === 'assigned') return true
  if (value === 'not-assigned') return false
  return undefined
}

type UseEventGroupAssignmentDataSettings = {
  courseId: EventGroupId
  initialUsersToLoad?: number
}
export type UseEventGroupAssignmentDataOutput = {
  allAssignedUsers: ListContentEnrolledUsersResponse
  isLoading: boolean
  filter: LSFilter
  setFilter: Dispatch<SetStateAction<LSFilter>>
  refresh: () => void
  hasFilterChanged: boolean
}

export const useEventGroupAssignmentData = ({
  courseId,
}: UseEventGroupAssignmentDataSettings): UseEventGroupAssignmentDataOutput => {
  const initialGroupIdFilter = useSearch({ strict: false, select: search => search.groupId })

  const initialFilter = {
    programs: typeof initialGroupIdFilter === 'string' ? [initialGroupIdFilter] : [],
    groups: typeof initialGroupIdFilter === 'string' ? [initialGroupIdFilter] : [],
    assignedToCalendarEvent: undefined,
    query: '',
  }

  const [throttledFilter, filter, setFilter] =
    useThrottledAndLiveState<UseEventGroupAssignmentDataOutput['filter']>(initialFilter)

  useEffect(() => {
    if (initialGroupIdFilter !== undefined) {
      removeSearchParams(['groupId'])
    }
  }, [initialGroupIdFilter])

  const { data, refetch, isLoading } = useCachedQuery(
    XRealtimeAdminCoursesListEnrolledUsers,
    {
      courseId,
      isAssignedToCalendarEvent: parseAssignmentFilter(filter.assignedToCalendarEvent),
      commonFilters: {
        lastUserId: undefined,
        requestedUserIds: undefined,
        sortBy: { direction: 'asc', type: 'name' },
        groupIds: filter.groups.concat(filter.programs),
        query: throttledFilter.query,
        maxResults: 150,
      },
    },
    {
      refetchOnWindowFocus: false,
    }
  )

  const refresh = useCallback(() => refetch(), [refetch])
  const allAssignedUsers = useMemo(() => data ?? { data: [], hasMore: false }, [data])

  return {
    allAssignedUsers,
    isLoading,
    refresh,
    filter,
    setFilter,
    hasFilterChanged: !_.isEqual(filter, initialFilter),
  }
}
