import { useAtom } from 'jotai'
import _ from 'lodash'
import React, { useMemo, useRef } from 'react'
import { useLocalizedFormatters } from 'sierra-client/core/format'
import { useDebouncedAction } from 'sierra-client/hooks/use-debounced-action'
import { useOrganizationPermissions } from 'sierra-client/hooks/use-permissions'
import { useTranslation } from 'sierra-client/hooks/use-translation'
import { TranslationLookup } from 'sierra-client/hooks/use-translation/types'
import {
  canvasColumn,
  dateTimesColumn,
  pillsColumn,
  stringsColumn,
  usersColumn,
} from 'sierra-client/lib/tabular/column-definitions'
import { TabularToolbar } from 'sierra-client/lib/tabular/components/tabular-toolbar'
import { createDataLoader } from 'sierra-client/lib/tabular/control/dataloader'
import { translatedLabel } from 'sierra-client/lib/tabular/datatype/label'
import {
  definition2Data,
  TableDataFromDefinition,
  TableDefinitionOf,
} from 'sierra-client/lib/tabular/datatype/tabledefinition'
import { exportTableData } from 'sierra-client/lib/tabular/export'
import { TabularProviderFromTableAPI } from 'sierra-client/lib/tabular/provider'
import { BasicTabularSimpleSize } from 'sierra-client/lib/tabular/provider/components/basic'
import { UseTableAPI, useTableAPI } from 'sierra-client/lib/tabular/use-table-api'
import { defaultMenuActionVirtualColumn, defaultSelectVirtualColumn } from 'sierra-client/lib/tabular/utils'
import { getGlobalRouter } from 'sierra-client/router'
import { typedPost } from 'sierra-client/state/api'
import { getAvatarImage } from 'sierra-client/utils/avatar-img'
import { AnimatedSearch } from 'sierra-client/views/manage/components/animated-search'
import { ActionButton } from 'sierra-client/views/manage/components/common'
import { DueDateCell, getAbsoluteDueDate } from 'sierra-client/views/manage/components/due-date'
import { ExportCSVIconButton } from 'sierra-client/views/manage/components/export-csv'
import {
  ContentUserCsv,
  parseEnrolledUserToCsv,
} from 'sierra-client/views/manage/utils/content-enrollment-to-csv'
import { getProgressPillRep } from 'sierra-client/views/manage/utils/progress-pill-tabular-rep'
import { queryKeyTabularContentUsers } from 'sierra-client/views/manage/utils/query-keys'
import {
  BaseListUsersRequest,
  DueDateAbsolute,
  DueDateSource,
  ListContentEnrolledUsersResponse,
} from 'sierra-domain/api/manage'
import { PathId } from 'sierra-domain/api/nano-id'
import { UserId } from 'sierra-domain/api/uuid'
import {
  XRealtimeAdminCoursesListEnrolledUsers,
  XRealtimeAdminPathsListEnrolledUsers,
} from 'sierra-domain/routes'
import { assertNever, isDefined, ItemOf } from 'sierra-domain/utils'
import { Icon, Tooltip, TruncatedText } from 'sierra-ui/components'
import { Button, scrollViewStyles, Spacer, Text, View } from 'sierra-ui/primitives'
import styled from 'styled-components'

const assignedViaText = (row: ContentUserData, t: TranslationLookup): string => {
  if (row.programs.length === 0 && row.paths.length === 0) {
    return row.assignedAt !== undefined
      ? t('manage.users.assignment-type.individual')
      : t('manage.users.self-started')
  } else if (row.paths.length === 0) {
    return _.compact(row.programs.map(g => g.name)).join(', ')
  } else if (row.programs.length === 0) {
    return _.compact(row.paths.map(g => g.title)).join(', ')
  }
  return (
    _.compact(row.programs.map(g => g.name)).join(', ') +
    ', ' +
    _.compact(row.paths.map(g => g.title)).join(', ')
  )
}

type ContentUserData = ItemOf<ListContentEnrolledUsersResponse['data']>

type ContentUsersTableDefinition = TableDefinitionOf<
  ContentUserData,
  [
    { type: 'users'; ref: 'users' },
    { type: 'pills'; ref: 'progress' },
    { type: 'dateTimes'; ref: 'last-activity' },
    { type: 'strings'; ref: 'assignment-type' },
    { type: 'canvas'; ref: 'due-date' },
    { type: 'canvas'; ref: 'available-from' },
  ]
>

type ContentUsersTableData = TableDataFromDefinition<ContentUserData, ContentUsersTableDefinition>

const AvailableFromCell: React.FC<{ row: ContentUserData }> = ({ row }) => {
  const { formatTimestamp } = useLocalizedFormatters()
  const { t } = useTranslation()

  const isSelfStarted = row.assignedAt === undefined && row.paths.length === 0 && row.programs.length === 0
  if (isDefined(row.assignedAt)) {
    return (
      <TruncatedText lines={1} size='small' color='grey50'>
        {formatTimestamp(row.assignedAt)}
      </TruncatedText>
    )
  } else if (isSelfStarted) {
    return <></>
  } else {
    return (
      <Tooltip title={t('table.user-content.tooltip.pending.text')} delayDuration={400}>
        <View gap='4' cursor='default'>
          <Text color='foreground/secondary'>{t('table.available-from-pending')}</Text>
          <Icon iconId='information' color='foreground/secondary' />
        </View>
      </Tooltip>
    )
  }
}

const tableDefinition = (t: TranslationLookup): ContentUsersTableDefinition => ({
  columns: [
    usersColumn({
      getData: r => {
        const u = r.userInfo.baseUserInfo
        return {
          id: u.userId,
          active: u.status === 'active',
          email: u.email,
          status: u.status,
          firstName: u.firstName,
          lastName: u.lastName,
          avatar: getAvatarImage(u.userId, u.avatar),
          avatarColor: u.avatarColor,
        }
      },
      header: translatedLabel('table.name'),
      ref: 'users',
    }),
    pillsColumn({
      getData: r => getProgressPillRep({ t, homeworks: r.homeworks, progress: r.progress }),
      header: translatedLabel('admin.analytics.table.progress'),
      ref: 'progress',
    }),
    dateTimesColumn({
      getData: r => r.lastActivity,
      header: translatedLabel('table.last-activity'),
      ref: 'last-activity',
    }),
    stringsColumn({
      getData: r => assignedViaText(r, t),
      header: translatedLabel('table.assigned-via'),
      ref: 'assignment-type',
    }),
    canvasColumn({
      getData: r => {
        return {
          view: <DueDateCell dueDatesEffective={r.dueDatesEffective} expectedSource={'direct'} />,
          meta: {
            sorts: () => r.dueDatesEffective?.effectiveDueDate ?? '',
            exports: () => r.dueDatesEffective?.effectiveDueDate ?? '',
          },
        }
      },
      header: translatedLabel('due-date.due-date'),
      ref: 'due-date',
    }),
    canvasColumn({
      getData: r => {
        return {
          view: <AvailableFromCell row={r} />,
          meta: {
            sorts: () => r.assignedAt?.toISOString() ?? '',
            exports: () => r.assignedAt?.toISOString() ?? '',
          },
        }
      },
      header: translatedLabel('table.available-from'),
      ref: 'available-from',
    }),
  ],
  nested: {},
  rows: {
    getId: r => r.userInfo.baseUserInfo.userId,
  },
})

type ContentUserTableMeta = { cursor: UserId | undefined }

const fetchUsers = (
  {
    contentId,
    contentType,
  }: { contentId: string; contentType: 'course' } | { contentId: string; contentType: 'path' },
  commonFilters: BaseListUsersRequest
): Promise<ListContentEnrolledUsersResponse> => {
  switch (contentType) {
    case 'course':
      return typedPost(XRealtimeAdminCoursesListEnrolledUsers, {
        courseId: contentId,
        commonFilters,
      })
    case 'path':
      return typedPost(XRealtimeAdminPathsListEnrolledUsers, {
        pathId: PathId.parse(contentId),
        commonFilters,
      })
    default:
      assertNever(contentType)
  }
}

const useContentUsersTableAPI = ({
  contentId,
  contentType,
  assignedCount,
  onRemoveUser,
  onSetUserDueDate,
  openCompleteUserContent,
  openResetUserContent,
  canEditAssignments,
}: Pick<
  ContentUsersTableProps,
  | 'contentId'
  | 'contentType'
  | 'assignedCount'
  | 'onRemoveUser'
  | 'onSetUserDueDate'
  | 'openCompleteUserContent'
  | 'openResetUserContent'
  | 'canEditAssignments'
>): UseTableAPI<ContentUsersTableData, ContentUserTableMeta> => {
  const { t } = useTranslation()

  const orgPermissions = useOrganizationPermissions()
  const canResetProgress = orgPermissions.has('RESET_LEARNER_PROGRESS')
  const canSetContentCompletion = orgPermissions.has('SET_CONTENT_COMPLETION')
  const canSetContentDueDates = orgPermissions.has('SET_CONTENT_DUE_DATES')

  return useTableAPI({
    virtualColumns: {
      left: [defaultSelectVirtualColumn()],
      right: [
        defaultMenuActionVirtualColumn({
          getProps: ({ row }) => {
            const { rawData } = row
            const user = rawData.userInfo.baseUserInfo
            const { effectiveDueDate, effectiveSource } = rawData.dueDatesEffective ?? {}
            return {
              menuItems: [
                {
                  type: 'label',
                  label: t('manage.view-details'),
                  id: 'view-details',
                  onClick: () =>
                    getGlobalRouter().navigate({
                      to: `/manage/users/${user.userId}`,
                    }),
                  icon: 'user',
                },
                {
                  type: 'label',
                  label: t('due-date.set-due-date'),
                  id: 'set-due-date',
                  onClick: () => {
                    onSetUserDueDate?.(user.userId, getAbsoluteDueDate(effectiveDueDate), effectiveSource)
                  },
                  hidden: !canSetContentDueDates || onSetUserDueDate === undefined,
                  icon: 'time',
                  disabled: rawData.assignedAt === undefined,
                },
                {
                  type: 'label',
                  label: t('manage.complete-progress'),
                  id: 'complete-content',
                  onClick: () => {
                    openCompleteUserContent(user.userId)
                  },
                  icon: 'checkmark--outline',
                  disabled: rawData.progress === 1,
                  hidden: !canSetContentCompletion,
                },
                {
                  type: 'label',
                  label: t('manage.reset-progress'),
                  id: 'reset-content',
                  onClick: () => {
                    openResetUserContent(user.userId)
                  },
                  icon: 'reset',
                  disabled: rawData.progress === 0 || rawData.progress === undefined,
                  hidden: !canResetProgress,
                  color: 'destructive/background',
                },
                {
                  type: 'label',
                  label: t('dictionary.unassign'),
                  id: 'unassign',
                  onClick: () => {
                    onRemoveUser([user.userId])
                  },
                  icon: 'user--remove',
                  color: 'destructive/background',
                  hidden: !canEditAssignments,
                  disabled:
                    rawData.programs.length !== 0 ||
                    rawData.paths.length !== 0 ||
                    rawData.assignedAt === undefined,
                },
              ],
            }
          },
        }),
      ],
    },
    dataLoader: useMemo(
      () => ({
        options: {
          queryKey: queryKeyTabularContentUsers(contentId),
        },
        loader: createDataLoader<ContentUserData, ContentUserTableMeta, ContentUsersTableData>({
          fetchInit: async ({ predicate }) => {
            const { data, hasMore } = await fetchUsers({ contentId, contentType }, { query: predicate.query })
            const lastUserId = data[data.length - 1]?.userInfo.baseUserInfo.userId
            return {
              data: data,
              meta: { cursor: lastUserId },
              done: !hasMore,
              totalCount: assignedCount,
            }
          },
          fetchMore: async ({ predicate, meta }) => {
            const { data, hasMore } = await fetchUsers(
              { contentId, contentType },
              { query: predicate.query, lastUserId: meta.cursor }
            )

            const lastUserId = data[data.length - 1]?.userInfo.baseUserInfo.userId
            return {
              data: data,
              meta: { cursor: lastUserId },
              done: !hasMore,
              totalCount: assignedCount,
            }
          },
          transformResults(data) {
            return [definition2Data(tableDefinition(t), data)]
          },
        }),
      }),
      [contentId, contentType, assignedCount, t]
    ),
  })
}

const TableWrapper = styled(View)`
  ${scrollViewStyles};
  min-height: 3rem;
  max-height: 27rem;
  width: 100%;
`

const Table: React.FC = () => {
  const scrollRef = useRef(null)

  return (
    <TableWrapper ref={scrollRef} alignItems='flex-start' direction='column'>
      <BasicTabularSimpleSize scrollRef={scrollRef.current} />
    </TableWrapper>
  )
}

type FooterProps = {
  canEditAssignments: boolean
  manageLearners: () => void
  fetchCsvData: () => Promise<ContentUserCsv[]>
}

const Footer: React.FC<FooterProps> = ({ canEditAssignments, manageLearners, fetchCsvData: fetchCsv }) => {
  const { t } = useTranslation()

  return (
    <View marginBottom='32' marginTop='32' justifyContent={canEditAssignments ? 'space-between' : 'flex-end'}>
      {canEditAssignments === true && <Button onClick={manageLearners}>{t('manage.manage-learners')}</Button>}
      <ExportCSVIconButton fetchCsvData={fetchCsv} filename={t('admin.analytics.users')} />
    </View>
  )
}

type ToolbarProps = {
  openUnassignUsers: (userIds: UserId[]) => void
  tableAPI: UseTableAPI<ContentUsersTableData, ContentUserTableMeta>
}
export const ContentUserTableToolbar: React.FC<ToolbarProps> = ({ tableAPI, openUnassignUsers }) => {
  const { api, dataLoader } = tableAPI
  const { t } = useTranslation()
  const csvButtonText = `${t('manage.export')} .csv`
  const [selection] = useAtom(tableAPI.selectionAtom)

  return (
    <TabularToolbar
      countsTranslationKeys={{
        totalKey: 'manage.content.n-contents',
        filterKey: 'manage.program-members.n-filtered',
        selectedKey: 'manage.tables.n-selected',
      }}
      api={api}
      clearFilters={false}
      actions={
        selection.type !== 'none' ? (
          <>
            <ActionButton
              onClick={() => exportTableData({ api, fileType: 'csv', dataLoader, t })}
              color='blueBright'
            >
              {csvButtonText}
            </ActionButton>
            {selection.type === 'manual' && (
              <ActionButton
                onClick={() => {
                  openUnassignUsers(Array.from(selection.rows).map(r => UserId.parse(r)))
                }}
                color='redBright'
              >
                {t('dictionary.unassign')}
              </ActionButton>
            )}
          </>
        ) : undefined
      }
      enableAllSelection={true}
    />
  )
}

export type ContentUsersTableProps = {
  contentId: string
  contentType: 'path' | 'course'
  assignedCount: number
  openEnrollUsers: () => void
  onRemoveUser: (userIds: UserId[]) => void
  onSetUserDueDate?: (userId: UserId, currentDueDate?: DueDateAbsolute, origin?: DueDateSource) => void
  openCompleteUserContent: (userId: UserId) => void
  openResetUserContent: (userId: UserId) => void
  canEditAssignments: boolean
}

export const ContentUsersTable: React.FC<ContentUsersTableProps> = ({
  contentId,
  contentType,
  assignedCount,
  onRemoveUser,
  openEnrollUsers,
  onSetUserDueDate,
  openCompleteUserContent,
  openResetUserContent,
  canEditAssignments,
}) => {
  const { t } = useTranslation()
  const tableAPI = useContentUsersTableAPI({
    contentId,
    contentType,
    assignedCount,
    onRemoveUser,
    onSetUserDueDate,
    openCompleteUserContent,
    openResetUserContent,
    canEditAssignments,
  })

  const search = useDebouncedAction((query: string) => tableAPI.api.action.setQuery({ query }))

  const fetchAllData = async (): Promise<ContentUserCsv[]> => {
    const { data } = await fetchUsers({ contentId, contentType }, { query: '', maxResults: assignedCount })
    return data.map(parseEnrolledUserToCsv)
  }
  return (
    <TabularProviderFromTableAPI tableAPI={tableAPI}>
      <AnimatedSearch placeholder={t('manage.search.users')} onChange={search} />
      <Spacer size='xsmall' />
      <ContentUserTableToolbar tableAPI={tableAPI} openUnassignUsers={onRemoveUser} />
      <Table />
      <Footer
        canEditAssignments={canEditAssignments}
        fetchCsvData={fetchAllData}
        manageLearners={openEnrollUsers}
      />
    </TabularProviderFromTableAPI>
  )
}

export type FetchUserAssignments<T = ListContentEnrolledUsersResponse> = (
  contentId: string,
  filters?: Partial<BaseListUsersRequest>,
  options?: {
    reset?: boolean
    forCsv?: boolean
  }
) => Promise<T>
