import { useQuery } from '@tanstack/react-query'
import { PrimitiveAtom, atom, useAtom, useAtomValue, useSetAtom } from 'jotai'
import React, { useMemo, useState } from 'react'
import { GlobalSidebarOpenAtom } from 'sierra-client/features/global-sidebar'
import { useStableFunction } from 'sierra-client/hooks/use-stable-function'
import { useTranslation } from 'sierra-client/hooks/use-translation'
import { useQueryStateAtom } from 'sierra-client/lib/querystate/use-query-state'
import { useIsMobile } from 'sierra-client/state/browser/selectors'
import { FCC } from 'sierra-client/types'
import { AnimatedSearch } from 'sierra-client/views/manage/components/animated-search'
import { PageContainer } from 'sierra-client/views/workspace/components'
import { CreatePageBuckets } from 'sierra-client/views/workspace/create-new/create-page-buckets'
import { CreatePageFilters } from 'sierra-client/views/workspace/create-new/filters'
import {
  CreatePageBucketSerializer,
  CreatePageContentFilter,
  CreatePageSortFunctionId,
} from 'sierra-client/views/workspace/create-new/filters/types'
import { Loading } from 'sierra-client/views/workspace/create-new/loading-page'
import { PinnedTable } from 'sierra-client/views/workspace/create-new/pinned'
import { RecentlyEdited } from 'sierra-client/views/workspace/create-new/sections/recently-edited'
import { createPageQuery } from 'sierra-client/views/workspace/utils/create-page-utils'
import { CreatePageContent } from 'sierra-domain/api/create-page'
import { Text, View } from 'sierra-ui/primitives'
import { maxWidth } from 'sierra-ui/utils/media-query-styles'
import styled from 'styled-components'

const CreateTableWrapper = styled(View)`
  width: 100%;
`

const CreateGrid = styled(View)`
  width: 100%;

  ${maxWidth.desktop_small} {
    flex-direction: column-reverse;
  }
`

const StyledAnimatedSearch = styled(AnimatedSearch)`
  ${maxWidth.phone} {
    flex: 1;
  }
`

const CreatePageSearch: React.FC<{ searchTermAtom: PrimitiveAtom<string> }> = ({ searchTermAtom }) => {
  const { t } = useTranslation()
  const isMobile = useIsMobile()
  const [searchTerm, setSearchTerm] = useAtom(searchTermAtom)
  return (
    <StyledAnimatedSearch
      onChange={setSearchTerm}
      value={searchTerm}
      placeholder={t('dictionary.search')}
      expandedWidth={isMobile ? '100%' : '200px'}
      disableClose={isMobile}
      initialOpen={isMobile}
    />
  )
}

const ListsContainer: FCC = ({ children }) => {
  const sidebarOpen = useAtomValue(GlobalSidebarOpenAtom)

  return <PageContainer $sidebarOpen={sidebarOpen}>{children}</PageContainer>
}

const CreateTableContent: React.FC<{
  createPageContent: CreatePageContent
  reloadCreatePageContent: () => void
}> = ({ createPageContent, reloadCreatePageContent }) => {
  const { t } = useTranslation()

  const tabStateAtom = useQueryStateAtom(CreatePageBucketSerializer, 'everything', 'tab')

  const searchTermAtom = useMemo(() => atom(''), [])
  const setSearchTerm = useSetAtom(searchTermAtom)

  const [selectedContentType, setSelectedContentType] = useState<CreatePageContentFilter['contentType'][]>([])
  const [selectedPublishState, setSelectedPublishState] = useState<CreatePageContentFilter['publishState'][]>(
    []
  )
  const [selectedSort, setSelectedSort] = useState<CreatePageSortFunctionId>('edited-desc')

  const clear = useStableFunction(() => {
    setSearchTerm('')
    setSelectedContentType([])
    setSelectedPublishState([])
    setSelectedSort('edited-desc')
  })

  return (
    <>
      <View direction='column' grow gap='none'>
        <View direction='column' gap='16' id='product-tour-create-page-content'>
          <Text size='regular' bold>
            {t('create-overview.all-content')}
          </Text>
          <View>
            <CreatePageSearch searchTermAtom={searchTermAtom} />

            <CreatePageFilters
              selectedTabAtom={tabStateAtom}
              selectedFilters={{ selectedContentType, selectedPublishState }}
              setSelectedFilters={{
                setSelectedContentType,
                setSelectedPublishState,
              }}
              selectedSortFunction={selectedSort}
              setSelectedSortFunction={setSelectedSort}
            />
          </View>
        </View>
        <CreatePageBuckets
          clear={clear}
          selectedFilters={{ selectedContentType, selectedPublishState }}
          searchTermAtom={searchTermAtom}
          createPageContent={createPageContent}
          reloadContent={reloadCreatePageContent}
          bucketsToShowAtom={tabStateAtom}
          selectedSortFunction={selectedSort}
        />
      </View>
    </>
  )
}

export const CreateNewPage: React.FC = () => {
  const createPageContentQuery = useQuery(createPageQuery)
  const createPageContent = createPageContentQuery.data
  const reloadCreatePageContent = createPageContentQuery.refetch

  if (!createPageContent) {
    return <Loading />
  }

  return (
    <>
      <RecentlyEdited createPageContent={createPageContent} />
      <ListsContainer>
        <CreateGrid alignItems='flex-start'>
          <CreateTableWrapper alignItems='flex-start'>
            <CreateTableContent
              createPageContent={createPageContent}
              reloadCreatePageContent={reloadCreatePageContent}
            />
          </CreateTableWrapper>
          <PinnedTable pinnedContent={createPageContent.pinned} refetchContent={reloadCreatePageContent} />
        </CreateGrid>
      </ListsContainer>
    </>
  )
}
