import { useEffect } from 'react'
import { useSetCardProgress } from 'sierra-client/views/flexible-content/progress-tracking/context'
import { File } from 'sierra-domain/flexible-content/types'

type ProgressConfig = {
  autoTrackViewed: boolean
  autoTrackCompleted: boolean
}

const notepadConfig: ProgressConfig = {
  autoTrackViewed: true,
  autoTrackCompleted: true,
}

const AUTO_TRACK_PROGRESS_CONFIG: { [filetype in File['data']['type']]: ProgressConfig } = {
  // Cards that can be track as completed automatically when they are viewed
  'breakout-room': {
    autoTrackViewed: true,
    autoTrackCompleted: true,
  },
  'drop-a-word': {
    autoTrackViewed: true,
    autoTrackCompleted: true,
  },
  'bullet': {
    autoTrackViewed: true,
    autoTrackCompleted: true,
  },
  'live-lobby': {
    autoTrackViewed: true,
    autoTrackCompleted: true,
  },
  'image': {
    autoTrackViewed: true,
    autoTrackCompleted: true,
  },
  'embed': {
    autoTrackViewed: true,
    autoTrackCompleted: true,
  },
  'slate-card': {
    autoTrackViewed: true,
    autoTrackCompleted: true,
  },
  'notepad': notepadConfig,
  'external-notepad': notepadConfig,
  'flip-cards': {
    autoTrackViewed: true,
    autoTrackCompleted: true,
  },
  'sticky-notes': {
    autoTrackViewed: true,
    autoTrackCompleted: true,
  },
  'project-card': {
    autoTrackViewed: true,
    autoTrackCompleted: true,
  },

  // Not auto tracked cards
  'general': {
    autoTrackViewed: true,
    autoTrackCompleted: false,
  },
  'poll': {
    autoTrackViewed: true,
    autoTrackCompleted: false,
  },
  'sliding-scale': {
    autoTrackViewed: true,
    autoTrackCompleted: false,
  },
  'video': {
    autoTrackViewed: true,
    autoTrackCompleted: false,
  },
  'question-card': {
    autoTrackViewed: true,
    autoTrackCompleted: false,
  },
  'assessment-card': {
    autoTrackViewed: false,
    autoTrackCompleted: false,
  },
  'reflections': {
    autoTrackViewed: true,
    autoTrackCompleted: false,
  },
  'homework': {
    autoTrackViewed: true,
    autoTrackCompleted: false,
  },
  'stupid-questions': {
    autoTrackViewed: true,
    autoTrackCompleted: true,
  },
  'scenario': {
    autoTrackViewed: true,
    autoTrackCompleted: true,
  },
  'roleplay': {
    autoTrackViewed: true,
    autoTrackCompleted: true,
  },
}

/**
 * This component will automatically track progress when files are rendered according to the config
 */
export const DefaultProgressTracker: React.FC<{ file: File }> = ({ file }) => {
  const { setCardStarted, setCardCompleted, setCardViewedAndCompleted } = useSetCardProgress()

  useEffect(() => {
    const config = AUTO_TRACK_PROGRESS_CONFIG[file.data.type]
    if (config.autoTrackViewed && config.autoTrackCompleted) setCardViewedAndCompleted()
    else if (config.autoTrackViewed) setCardStarted()
    else if (config.autoTrackCompleted) setCardCompleted()
  }, [file, setCardCompleted, setCardStarted, setCardViewedAndCompleted])

  return null
}
