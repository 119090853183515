import _ from 'lodash'
import { withoutSavingToUndoStack } from 'sierra-client/editor'
import { convertListItemToText } from 'sierra-client/views/v3-author/list/utils'
import { findNode, isElementType, nodeAtPath } from 'sierra-client/views/v3-author/queries'
import { assertNever, iife } from 'sierra-domain/utils'
import { CustomElement, CustomElementType, CustomText, Mark, SanaEditor } from 'sierra-domain/v3-author'
import {
  createCheckList,
  createOrderedList,
  createUnorderedList,
} from 'sierra-domain/v3-author/create-blocks'
import { Editor, Element, Node, NodeEntry, Path, Editor as SlateEditor, Text, Transforms } from 'slate'
import { ReactEditor } from 'slate-react'
import smoothscroll from 'smoothscroll-polyfill'

function scrollIntoView(element: HTMLElement, options?: ScrollIntoViewOptions): void {
  smoothscroll.polyfill()
  element.scrollIntoView(options ?? { block: 'center', behavior: 'smooth' })
}

export const isMarkActive = (editor: SlateEditor, mark: Mark): boolean => {
  const marks = editor.getMarks() ?? {}
  return marks[mark] === true
}

export const toggleMark = (editor: SlateEditor, mark: Mark): void => {
  if (isMarkActive(editor, mark)) {
    editor.removeMark(mark)
  } else {
    editor.addMark(mark, true)
  }
}

// Get the nearest element of a given type within the current selection
function getCurrentEntryOfType(
  editor: Editor,
  type: CustomElementType | CustomElementType[]
): NodeEntry<CustomElement> | undefined {
  const next = editor.nodes({ match: isElementType(type) }).next()

  return next.done !== true ? next.value : undefined
}

export function isInElement(editor: Editor, type: CustomElementType | CustomElementType[]): boolean {
  return getCurrentEntryOfType(editor, type) !== undefined
}

type Block =
  | 'block-quote'
  | 'heading'
  | 'numbered-list'
  | 'bulleted-list'
  | 'preamble'
  | 'code'
  | 'takeaways'
  | 'paragraph'
  | 'check-list'

export const isBlockActive = (editor: SlateEditor, block: Block): boolean => isInElement(editor, block)

const isListElement = isElementType(['numbered-list', 'bulleted-list', 'check-list'])

// todo(foundation): What's the difference between this function and toggleLists?
export const toggleBlock = (
  editor: SlateEditor,
  block: Extract<CustomElementType, 'numbered-list' | 'bulleted-list'>
): void => {
  const isActive = isBlockActive(editor, block)
  const newType = isActive ? 'paragraph' : 'list-item'

  editor.withoutNormalizing(() => {
    editor.unwrapNodes({
      match: isListElement,
      split: true,
    })

    editor.setNodes({
      type: newType,
      indent: newType === 'list-item' ? 0 : undefined, // set a default indent of 0
    })

    if (newType === 'list-item') {
      switch (block) {
        case 'bulleted-list':
          editor.wrapNodes(createUnorderedList())
          break
        case 'numbered-list':
          editor.wrapNodes(createOrderedList())
          break
        default:
          assertNever(block)
      }
    }
  })
}

export const toggleHeadingOrParagraph = (
  editor: SlateEditor,
  block: 'heading' | 'paragraph',
  level: number | undefined
): void => {
  Transforms.unwrapNodes(editor, {
    match: isElementType('check-list'),
    split: true,
  })

  const type = block === 'heading' ? 'heading' : 'paragraph'

  Transforms.setNodes(editor, {
    type,
    level: level,
  })
}

export const updateNodeWithId = <Node extends CustomElement>(
  editor: Editor,
  preambleId: string,
  updates: Partial<Node> | ((n: Node) => Node)
): void => {
  const result = findNode(editor, n => n.id === preambleId)
  if (result.length === 0) return

  const [node, path] = result
  if (typeof updates === 'function') {
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    Transforms.setNodes<Node>(editor, updates(node as any), { at: path })
  } else {
    Transforms.setNodes(editor, { ...updates }, { at: path })
  }
}

export const findPathToNodeWithId = (editor: Editor, nodeId: string): Path | undefined => {
  const result = findNode(editor, n => n.id === nodeId)
  if (result.length === 0) return

  const [, path] = result
  return path
}

export const removeNodeWithId = (editor: Editor, nodeId: string): void => {
  const path = findPathToNodeWithId(editor, nodeId)
  Transforms.removeNodes(editor, { at: path })
}

export const replaceNodeWithId = (
  editor: Editor,
  nodeId: string,
  newNode: CustomElement | CustomText,
  saveRemove = true,
  saveInsert = true
): void => {
  const path = findPathToNodeWithId(editor, nodeId)
  const remove = (): void => Transforms.removeNodes(editor, { at: path, voids: true })
  const insert = (): void => Transforms.insertNodes(editor, newNode, { at: path })

  if (saveRemove) {
    remove()
  } else {
    withoutSavingToUndoStack(editor, remove)
  }

  if (saveInsert) {
    insert()
  } else {
    withoutSavingToUndoStack(editor, insert)
  }
}

export const insertNodeAfterNodeWithId = (
  editor: Editor,
  nodeId: string,
  newNode: CustomElement | CustomText
): void => {
  const path = findPathToNodeWithId(editor, nodeId)
  if (path === undefined) return
  const nextItem = (_.last(path) ?? 0) + 1
  const newPath: Path = [...path.slice(0, -1), nextItem]
  console.debug('[insertNodeAfterNodeWithId]', JSON.stringify({ nextItem, path, newPath }))
  Transforms.insertNodes(editor, newNode, { at: newPath })
}

/**
 * Return a relative path from the current node into its first leaf node.
 */
const pathToLeaf = (node: Node): number[] => {
  if (Element.isElement(node)) {
    return [0, ...pathToLeaf(node.children[0])]
  } else return []
}

export const selectNodeWithIdSynchronous = (editor: SanaEditor, nodeId: string): void => {
  const [node, nodePath] = findNode(editor, n => n.id === nodeId)
  editor.pushActionsLogEntry({
    type: 'select-node-with-id-synchronous',
    foundNode: node !== undefined && nodePath !== undefined,
  })
  if (node === undefined || nodePath === undefined) return

  const path = [...nodePath, ...pathToLeaf(node)]
  Transforms.select(editor, { anchor: { path, offset: 0 }, focus: { path, offset: 0 } })
}

export const scrollToSelectedNode = (editor: SanaEditor, options?: ScrollIntoViewOptions): void => {
  editor.pushActionsLogEntry('scroll-to-selected-node')

  const { selection } = editor
  if (selection === null) return

  const [node, path] = Editor.node(editor, selection)

  if (typeof window === 'object') {
    requestAnimationFrame(() => {
      try {
        const domNode = ReactEditor.toDOMNode(editor, node)
        console.debug('[scrollToSelectedNode] scrolling to', JSON.stringify(path))
        scrollIntoView(domNode, options)
      } catch (e) {
        console.warn(`Failed to scroll to node at: ${JSON.stringify(path)},`, e)
        // Do nothing
      }
    })
  }
}

export const scrollToNodeWithId = (editor: SanaEditor, nodeId: string): void => {
  const [node, path] = findNode(editor, n => n.id === nodeId)
  if (node === undefined || path === undefined) return

  requestAnimationFrame(() => {
    try {
      const domNode = ReactEditor.toDOMNode(editor, node)
      scrollIntoView(domNode)
    } catch (e) {
      console.warn(`Failed to scroll to node with id ${nodeId},`, e)
      // Do nothing
    }
  })
}

export const toggleListLevel = (editor: SlateEditor, level: number | undefined): void => {
  Transforms.setNodes(
    editor,
    { level },
    {
      match: isElementType(['list-item', 'check-list-item']),
    }
  )
}

// todo(foundation): What's the difference between this function and toggleBlock?
export const toggleLists = (
  editor: SlateEditor,
  block: 'bulleted-list' | 'numbered-list' | 'check-list'
): void => {
  const isActive = isBlockActive(editor, block)

  editor.withoutNormalizing(() => {
    editor.unwrapNodes({
      match: isListElement,
      split: true,
    })

    editor.setNodes(
      block === 'check-list'
        ? {
            type: 'check-list-item',
            checked: false,
          }
        : {
            type: 'list-item',
          }
    )

    if (!isActive) {
      const list = iife(() => {
        switch (block) {
          case 'check-list':
            return createCheckList()
          case 'numbered-list':
            return createOrderedList()
          case 'bulleted-list':
            return createUnorderedList()
        }
      })

      Transforms.wrapNodes(editor, list)
    } else {
      for (const [node, path] of Editor.nodes(editor)) {
        if (isElementType(['list-item', 'check-list-item'], node)) {
          Transforms.wrapNodes(editor, convertListItemToText(node), { at: path })
          Transforms.unwrapNodes(editor, { at: path.concat(0) })
        }
      }
    }
  })
}

export const pathsByElementId = (editor: Editor): { [nodeId: string]: Path } => {
  const paths: { [nodeId: string]: Path } = {}

  for (const [{ id }, path] of Node.elements(editor)) {
    paths[id] = path
  }

  return paths
}

export function unwrapToTextNodes(editor: Editor, { at: path }: { at: Path }): void {
  const node = nodeAtPath(editor.children, path)
  if (node === undefined) return
  if (!Element.isElement(node)) return

  if (node.children.length === 0) {
    Transforms.removeNodes(editor, { at: path })
  } else {
    Transforms.unwrapNodes(editor, {
      at: path,
      voids: true,
      mode: 'all',
      match: (n, p) => {
        const isText = Text.isText(n)
        const matchesPath = _.isEqual(path, _.take(p, path.length))
        return !isText && matchesPath
      },
    })
  }
}
