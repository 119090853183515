import { useEffect, useMemo } from 'react'
import { DocumentSyncChecker, DocumentTypeChecker } from 'sierra-client/editor/document-type-checker'
import { EditorJotaiContext } from 'sierra-client/editor/editor-jotai-context'
import { EditorTextActionsProvider } from 'sierra-client/features/text-actions'
import { useStableFunction } from 'sierra-client/hooks/use-stable-function'
import { EditorContext, EditorContextData } from 'sierra-client/views/v3-author/editor-context/editor-context'
import { Shortcut } from 'sierra-client/views/v3-author/slash-menu'
import { EditorMode } from 'sierra-client/views/v3-author/slate'
import { TagsContext, TagsList, useGetTags } from 'sierra-client/views/v3-author/tags/tags-menu'
import { ChatIdentifier } from 'sierra-domain/api/chat'
import { AssetContext } from 'sierra-domain/asset-context'
import { ScopedChatId } from 'sierra-domain/collaboration/types'
import { SlashMenuState, allSlashMenuEntryIds } from 'sierra-domain/v3-author/slash-menu'
import { Editor } from 'slate'
import { Slate as SlateContext } from 'slate-react'

export type EditorContextSlateOptions = Omit<Parameters<typeof SlateContext>[0], 'onChange'>

export type EditorContextProviderProps = EditorContextSlateOptions & {
  editor: Editor
  editorId: string
  chatId?: ScopedChatId
  chatIdentifier?: ChatIdentifier
  mode: EditorMode
  supportedSlashMenuEntryIds?: SlashMenuState['idsSupportedByEditor']
  readOnly: boolean
  enableCommenting: boolean
  rulesOfBlocksViolations?: 'error' | 'warning'
  assetContext: AssetContext
}

export const EditorContextProvider = ({
  children,
  editorId,
  editor,
  chatId,
  chatIdentifier,
  mode,
  readOnly,
  enableCommenting,
  supportedSlashMenuEntryIds = allSlashMenuEntryIds.filter(id => id !== 'question-card'),
  rulesOfBlocksViolations = 'warning',
  assetContext,
  ...rest
}: EditorContextProviderProps): JSX.Element => {
  const contextValue: EditorContextData = useMemo(
    () => ({
      editorId,
      chatId,
      chatIdentifier,
      mode,
      supportedSlashMenuEntryIds,
      readOnly,
      enableCommenting,
      rulesOfBlocksViolations,
      assetContext,
    }),
    [
      editorId,
      chatId,
      chatIdentifier,
      mode,
      supportedSlashMenuEntryIds,
      readOnly,
      enableCommenting,
      rulesOfBlocksViolations,
      assetContext,
    ]
  )

  const tagsContextValue = useGetTags(editor)
  const { updateTags, targetRect: tagsTargetRect, index: tagsIndex } = tagsContextValue

  const onChange = useStableFunction(() => {
    updateTags()
  })

  useEffect(() => {
    if (chatId === undefined) {
      console.debug('[EditorContext] No chatId was set. The chat is disabled.')
    }
  }, [chatId])

  return (
    <EditorContext.Provider value={contextValue}>
      <SlateContext {...rest} editor={editor} onChange={onChange}>
        <EditorJotaiContext
          readOnly={readOnly}
          editor={editor}
          key={editorId}
          supportedSlashMenuEntryIds={supportedSlashMenuEntryIds}
        >
          <TagsContext mode={mode} value={tagsContextValue}>
            <EditorTextActionsProvider key={editorId}>
              <Shortcut supportedSlashMenuEntryIds={supportedSlashMenuEntryIds} />
              {tagsTargetRect && !readOnly && (
                <TagsList targetRect={tagsTargetRect} currentIndex={tagsIndex} />
              )}

              {/* No need to run checks on the document if it is not editable */}
              {!readOnly && (
                <>
                  <DocumentTypeChecker key={`types-${editorId}`} />
                  <DocumentSyncChecker key={`sync-${editorId}`} />
                </>
              )}

              {children}
            </EditorTextActionsProvider>
          </TagsContext>
        </EditorJotaiContext>
      </SlateContext>
    </EditorContext.Provider>
  )
}
