import { EditorContent } from '@tiptap/react'
import React, { useEffect, useState } from 'react'
import { UseCompletedPostSessionSummaryResult } from 'sierra-client/api/hooks/use-post-session-summary'
import { SummaryListLoading } from 'sierra-client/components/liveV2/summary/loading'
import { useShouldAct } from 'sierra-client/components/liveV2/summary/should-act'
import { useSummaryEditor } from 'sierra-client/components/liveV2/summary/summary-editor'
import { useTranslation } from 'sierra-client/hooks/use-translation'
import { assertNever } from 'sierra-domain/utils'
import { spacing, token } from 'sierra-ui/theming'
import { fonts } from 'sierra-ui/theming/fonts'
import styled, { css } from 'styled-components'
import { Awareness } from 'y-protocols/awareness'
import * as Y from 'yjs'

const tiptapPlaceholder = css`
  .ProseMirror p.is-editor-empty:first-child::before {
    color: ${token('foreground/muted')};
    content: attr(data-placeholder);
    float: left;
    height: 0;
    pointer-events: none;
  }
`

const tiptapCursorStyles = css`
  /* Give a remote user a caret */
  .collaboration-cursor__caret {
    border-left: 1px solid var(--cursor-color);
    border-right: 1px solid var(--cursor-color);

    /** NOTE: Under normal circumstances we don't need a background color here since the
     * element has no content and zero width. However, some zoom levels on Chrome can result
     * in it having a non-zero width which leads to a distracting gap between the borders. */
    background-color: var(--cursor-color);
    margin-left: -1px;
    margin-right: -1px;
    pointer-events: none;
    position: relative;
    word-break: normal;
    z-index: 100;
  }

  /* Render the username above the caret */
  .collaboration-cursor__label {
    background-color: var(--cursor-color);
    border-radius: 3px 3px 3px 0;
    font-size: 12px;
    font-style: normal;
    font-weight: 600;
    left: -1px;
    line-height: normal;
    padding: 0.1rem 0.3rem;
    position: absolute;
    top: -1.4em;
    user-select: none;
    white-space: nowrap;
    color: white;
  }
`

const StyledEditorContent = styled(EditorContent)`
  p,
  p::placeholder {
    ${fonts.body.small};
  }

  .ProseMirror,
  .ProseMirror [data-placeholder] {
    ${fonts.body.small};
  }

  color: ${token('foreground/primary')};

  ul,
  ol {
    list-style-type: disc;
    list-style-position: outside;
    padding-left: 1rem;
  }

  li::marker {
    color: ${token('foreground/muted')};
  }

  li p:first-child {
    margin-bottom: ${spacing['4']};
  }

  li p:first-child::first-letter {
    text-transform: uppercase;
  }

  li p:not(:first-child) {
    color: ${token('foreground/primary')};
    font-weight: ${fonts.weight.regular};
  }

  li {
    color: ${token('foreground/primary')};
    margin-bottom: ${spacing['24']};
    font-weight: ${fonts.weight.medium};
    line-height: 150%;
  }

  li p {
    margin-left: 12px;
  }

  ${tiptapPlaceholder};
  ${tiptapCursorStyles};
`

export const TranscriptActions: React.FC<{
  yDoc: Y.Doc
  awareness: Awareness
  summaryData: UseCompletedPostSessionSummaryResult
}> = ({ yDoc, awareness, summaryData }) => {
  const { t } = useTranslation()
  const placeholder = t('live.recap.add-actions-placeholder')
  const [editorReady, setEditorReady] = useState<boolean>(false)
  const { editor, fragment } = useSummaryEditor({ placeholder, yDoc, awareness })
  const isActor = useShouldAct()

  useEffect(() => {
    if (editor === null || summaryData.status === 'loading') return

    if (summaryData.status === 'error') {
      return setEditorReady(true)
    }

    if (fragment.toJSON().length > 0) return setEditorReady(true)
    if (!isActor) return setEditorReady(true)

    const data = summaryData.data

    switch (data.format) {
      case 'note': {
        if (data.data.actions !== undefined) {
          editor.commands.setContent(
            `<ul><li>${data.data.actions
              .map(action => `<p>${action}</p>`)
              .join('</li><li>')}</li><li>Add an action...</li></ul>`
          )
        }
        break
      }
      case 'string':
        editor.commands.setContent(
          `<ul><li>${data.data
            .split('\n')
            .filter(item => item !== '')
            .join('</li><li>')}</li></ul>`
        )
        break
      case 'unavailable':
        // Keep input empty until backend returns summary
        break
      default:
        assertNever(data)
    }

    setEditorReady(true)

    return () => {}
  }, [fragment, awareness, editor, isActor, summaryData])

  if (editorReady) return <StyledEditorContent editor={editor} />

  return <SummaryListLoading />
}
