import { Prompt, PromptText } from 'sierra-client/views/v3-author/components'
import { color } from 'sierra-ui/color'
import { ColorName } from 'sierra-ui/color/types'
import { spacing } from 'sierra-ui/theming'
import styled, { css } from 'styled-components'

const paddingMixing = css`
  padding: ${spacing['10']} ${spacing['12']};
`

type AlternativeInputProps = {
  readOnly: boolean
  margin?: boolean
  color?: ColorName
}

export const AlternativeInput = styled.div<AlternativeInputProps>`
  margin-top: ${p => p.margin === true && spacing.xxsmall};
  width: 100%;
  position: relative;
  color: ${p => (p.color === undefined ? `inherit` : color(p.color)).toString()};
  border: 1px solid ${p => (p.readOnly ? `transparent` : `${p.theme.color.grey10}`)};
  border-radius: ${p => p.theme.borderRadius['size-6']};
  ${paddingMixing};
  min-height: 1.5rem;
`

export const StyledPrompt = styled(Prompt)`
  top: 0;
  left: 0;
  ${paddingMixing};
  height: 100%;
`

export const StyledPromptText = styled(PromptText)`
  color: ${p => p.theme.color.grey15};
`

export const ExplanationLine = styled.div.attrs({ contentEditable: false })`
  grid-area: vertical;
  max-height: calc(100% - ${spacing.xxsmall});
  transform: translateY(${spacing.xxsmall});
  background: rgba(0, 0, 0, 0.05);
  width: 4px;
  border-radius: 2px;
  margin-left: calc((1rem - 4px) / 2);
  user-select: none;
`
