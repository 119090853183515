import _ from 'lodash'
import React from 'react'
import { useTranslation } from 'sierra-client/hooks/use-translation'
import { useIsSmallDesktop } from 'sierra-client/state/browser/selectors'
import { CreatePageTabs } from 'sierra-client/views/workspace/create-new/filters/shared/create-page-tabs'
import {
  useContenTypeMenuItems,
  usePublishStateMenuItems,
  useSortByMenuItems,
} from 'sierra-client/views/workspace/create-new/filters/shared/filter-functions'
import {
  CreatePageContentFilter,
  CreatePageFilterRenderer,
  CreatePageSelectedFilters,
  CreatePageSetSelectedFilters,
  CreatePageSortFunctionId,
} from 'sierra-client/views/workspace/create-new/filters/types'
import { EditableContent } from 'sierra-domain/api/editable-content'
import { TeamspaceContent } from 'sierra-domain/api/teamspace'
import { assertNever } from 'sierra-domain/utils'
import { Icon, IconId, MenuItem, TruncatedText } from 'sierra-ui/components'
import { Text, View } from 'sierra-ui/primitives'
import {
  DefaultDropdownTrigger,
  IconMenu,
  MultiSelectDropdown,
  SingleSelectDropdown,
} from 'sierra-ui/primitives/menu-dropdown'
import styled from 'styled-components'

export const editableContentTypeFilterRow = (
  contentType: CreatePageContentFilter['contentType'],
  row: EditableContent
): boolean => {
  const isPath = row.type === 'path'

  switch (contentType) {
    case 'native:self-paced':
      return row.type === 'native:self-paced' && row.templateSettings === undefined
    case 'native:live':
      return row.type === 'native:live'
    case 'path':
      return row.type === 'path'
    case 'template':
      return !isPath && row.templateSettings !== undefined
    case 'folder':
    case 'scorm':
    case 'linkedin':
    case 'native:event-group':
    case 'native:course-group':
    case 'scorm:course-group':
    case 'link':
      return false //These are not editable content types so we can safely return false here.
    default:
      assertNever(contentType)
  }
}

export const editablePublishStateFilterRow = (
  publishState: CreatePageContentFilter['publishState'],
  row: EditableContent
): boolean => {
  const isPath = row.type === 'path'

  switch (publishState) {
    case 'pending':
      return !isPath && row.published && row.pending
    case 'published':
      return !isPath && row.published && !row.pending
    case 'draft':
      return !isPath && !row.published
    default:
      assertNever(publishState)
  }
}

export const editableSortFunctions: Record<
  CreatePageSortFunctionId,
  (xs: EditableContent[]) => EditableContent[]
> = {
  'edited-desc': xs => _.orderBy(xs, [x => x.timestamp], ['desc']),
  'alphabetically-asc': xs => _.orderBy(xs, [x => x.title.toLowerCase()], ['asc']),
  'alphabetically-desc': xs => _.orderBy(xs, [x => x.title.toLowerCase()], ['desc']),
}

export const teamspaceContentTypeFilterRow = (
  contentType: CreatePageContentFilter['contentType'],
  row: TeamspaceContent
): boolean => {
  const isFolder = row.type === 'folder'
  const isEditableContent = row.type === 'editable'
  const isNonEditorCourse = row.type === 'non-editor-course'
  const isSelfpaced = isEditableContent && row.content.type === 'native:self-paced'
  const isLive = isEditableContent && row.content.type === 'native:live'

  switch (contentType) {
    case 'native:self-paced':
      return isSelfpaced && row.content.templateSettings === undefined
    case 'native:live':
      return isLive && row.content.templateSettings === undefined
    case 'folder':
      return isFolder
    case 'template':
      return isEditableContent && row.content.templateSettings !== undefined
    case 'scorm':
      return isNonEditorCourse && row.content.type === 'scorm'
    case 'link':
      return isNonEditorCourse && row.content.type === 'link'
    case 'linkedin':
      return isNonEditorCourse && row.content.type === 'linkedin'
    case 'native:event-group':
      return isNonEditorCourse && row.content.type === 'native:event-group'
    case 'native:course-group':
      return isNonEditorCourse && row.content.type === 'native:course-group'
    case 'scorm:course-group':
      return isNonEditorCourse && row.content.type === 'scorm:course-group'
    case 'path':
      return false // We can not have paths in teamspaces, so we can safely return false here.
    default:
      assertNever(contentType)
  }
}

export const teamspaceSortFunctions: Record<
  CreatePageSortFunctionId,
  (xs: TeamspaceContent[]) => TeamspaceContent[]
> = {
  'edited-desc': xs =>
    _.orderBy(xs, [x => (x.type === 'folder' ? x.updatedAt : x.content.timestamp)], ['desc']),
  'alphabetically-asc': xs =>
    _.orderBy(
      xs,
      [x => (x.type === 'folder' ? x.displayName.toLowerCase() : x.content.title.toLowerCase())],
      ['asc']
    ),
  'alphabetically-desc': xs =>
    _.orderBy(
      xs,
      [x => (x.type === 'folder' ? x.displayName.toLowerCase() : x.content.title.toLowerCase())],
      ['desc']
    ),
}

export const teamspacePublishStateFilterRow = (
  publishState: CreatePageContentFilter['publishState'],
  row: TeamspaceContent
): boolean => {
  const isEditableContent = row.type === 'editable'
  const isSelfpaced = isEditableContent && row.content.type === 'native:self-paced'

  switch (publishState) {
    case 'pending':
      return isSelfpaced && row.content.published && row.content.pending
    case 'published':
      return isSelfpaced && row.content.published && !row.content.pending
    case 'draft':
      return isSelfpaced && !row.content.published
    default:
      assertNever(publishState)
  }
}

const BigNotificationDot = styled.div`
  border-radius: 50%;
  width: 16px;
  height: 16px;
  background-color: #0558f9;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-shrink: 0;
`
const SmallNotificationWrapper = styled(View)`
  position: relative;
`
const SmallNotification = styled.div`
  border-radius: 50%;
  width: 8px;
  height: 8px;
  background-color: white;
  position: absolute;
  top: 0;
  right: -2px;
  padding: 1.5px;
  display: flex;
  justify-content: center;
  align-items: center;
`

const SmallNotificationDot = styled.div`
  border-radius: 50%;
  width: 100%;
  height: 100%;
  background-color: #0558f9;
`

const NotificationPreComponent: React.FC<{ iconId: IconId; showNotification: boolean }> = ({
  iconId,
  showNotification,
}) => {
  return (
    <SmallNotificationWrapper>
      <Icon iconId={iconId} />
      {showNotification && (
        <SmallNotification>
          <SmallNotificationDot />
        </SmallNotification>
      )}
    </SmallNotificationWrapper>
  )
}

const FilterDropDown: React.FC<{
  selectedFilters: CreatePageSelectedFilters
  setSelectedFilters: CreatePageSetSelectedFilters
}> = ({ selectedFilters, setSelectedFilters }) => {
  const { t } = useTranslation()
  const { selectedContentType, selectedPublishState } = selectedFilters
  const { setSelectedContentType, setSelectedPublishState } = setSelectedFilters
  const isSmallDesktop = useIsSmallDesktop()

  const contentTypeNestedMenuItems = useContenTypeMenuItems(selectedContentType, setSelectedContentType)
  const publishStateNestedMenuItems = usePublishStateMenuItems(selectedPublishState, setSelectedPublishState)

  const showContentTypeNotification = selectedContentType.length > 0
  const showPublishStateNotification = selectedPublishState.length > 0
  const hideClearFilter = selectedContentType.length === 0 && selectedPublishState.length === 0
  const [isOpenFiltersDropdown, setIsOpenFiltersDropdown] = React.useState<undefined | boolean>(undefined)

  const filterDropDownItems: MenuItem[] = [
    {
      type: 'nested',
      id: 'content-type',
      label: t('create-page.filters.content-type'),
      menuItems: contentTypeNestedMenuItems,
      preventClose: true,
      preComponent: <NotificationPreComponent iconId='edit' showNotification={showContentTypeNotification} />,
    },
    {
      type: 'nested',
      id: 'publish-state',
      label: t('create-page.filters.publish-state'),
      menuItems: publishStateNestedMenuItems,
      preventClose: true,
      preComponent: (
        <NotificationPreComponent iconId='wiki' showNotification={showPublishStateNotification} />
      ),
    },
    {
      id: 'separator',
      type: 'separator',
      hidden: hideClearFilter,
    },
    {
      type: 'label',
      id: 'clear-filters',
      preventClose: false,
      hidden: hideClearFilter,
      label: t('manage.clear-filters'),
      onClick: () => {
        setSelectedContentType([])
        setSelectedPublishState([])
        setIsOpenFiltersDropdown(false)
      },
    },
  ]

  const numberOfNotification = selectedContentType.length + selectedPublishState.length

  if (isSmallDesktop) {
    return (
      <IconMenu iconId='filter' variant='transparent' onSelect={() => {}} menuItems={filterDropDownItems} />
    )
  }

  return (
    <MultiSelectDropdown
      bold
      selectedItems={[]}
      onUnselect={() => {}}
      onSelect={() => {}}
      menuItems={filterDropDownItems}
      isOpen={isOpenFiltersDropdown}
      onOpenChange={(newValue: boolean) => setIsOpenFiltersDropdown(newValue)}
      renderTrigger={() => (
        <DefaultDropdownTrigger grow={false}>
          <View>
            {numberOfNotification > 0 ? (
              <BigNotificationDot>
                <Text bold color='white' size='technical'>
                  {numberOfNotification}
                </Text>
              </BigNotificationDot>
            ) : (
              <Icon iconId='filter' />
            )}
            <TruncatedText bold as='span' lines={1}>
              {t('dictionary.filter')}
            </TruncatedText>
          </View>
        </DefaultDropdownTrigger>
      )}
    />
  )
}

const RelevancyDropDown: React.FC<{
  selectedSortFunction: CreatePageSortFunctionId
  setSelectedSortFunction: React.Dispatch<React.SetStateAction<CreatePageSortFunctionId>>
}> = ({ selectedSortFunction, setSelectedSortFunction }) => {
  const sortOptionItems = useSortByMenuItems(selectedSortFunction)

  return (
    <SingleSelectDropdown
      grow={false}
      bold
      onSelect={item => setSelectedSortFunction(item.id)}
      menuItems={sortOptionItems}
      selectedItem={sortOptionItems.find(item => item.id === selectedSortFunction)}
    />
  )
}

export const CreatePageDesktopFilters: React.FC<CreatePageFilterRenderer> = ({
  selectedTabAtom,
  selectedFilters,
  setSelectedFilters,
  selectedSortFunction,
  setSelectedSortFunction,
}) => {
  const isSmallDesktop = useIsSmallDesktop()

  return (
    <>
      <CreatePageTabs selectedTabAtom={selectedTabAtom} />
      <View justifyContent='flex-end'>
        <View direction={isSmallDesktop ? 'row-reverse' : 'row'}>
          <FilterDropDown selectedFilters={selectedFilters} setSelectedFilters={setSelectedFilters} />
          <RelevancyDropDown
            selectedSortFunction={selectedSortFunction}
            setSelectedSortFunction={setSelectedSortFunction}
          />
        </View>
      </View>
    </>
  )
}
