import { useQuery } from '@tanstack/react-query'
import { getFileContentImage } from 'sierra-client/api/content'
import { typedPost } from 'sierra-client/state/api'
import { CreateContentId } from 'sierra-domain/api/nano-id'
import { UUID } from 'sierra-domain/api/uuid'
import { File } from 'sierra-domain/flexible-content/types'
import { XRealtimeAuthorGetNarrationVideoUrl } from 'sierra-domain/routes'
import { assertIsNonNullable, assertNever } from 'sierra-domain/utils'

export type NarrationVideoState =
  | { type: 'success'; videoUrl: string }
  | { type: 'error' }
  | {
      type: 'generating'
      thumbnailUrl: string | undefined
      estimatedCompletedAt: Date
      createdAt: Date
      narrationId: UUID
    }
  | { type: 'no-narration' }
  | { type: 'loading' }

/**
 *
 * NOTE: the `narration.generatedNarrationId` is deprecated, and we only
 * keep it around for backwards compatibility. New narrations should only
 * be using the `url` field to store the video URLs.
 */
export function useNarrationVideo(
  narration: File['narration'],
  contentId: CreateContentId
): NarrationVideoState {
  const query = useQuery({
    queryKey: [`${XRealtimeAuthorGetNarrationVideoUrl.path}${narration?.generatedNarrationId}${contentId}`],
    enabled: narration?.generatedNarrationId !== undefined,
    queryFn: async (): Promise<NarrationVideoState> => {
      assertIsNonNullable(narration, 'narration must be defined here')
      const narrationId = narration.generatedNarrationId
      assertIsNonNullable(narrationId, 'narration.generatedNarrationId must be defined here')

      const generatedNarration = await typedPost(XRealtimeAuthorGetNarrationVideoUrl, {
        narrationId: narrationId,
        contentId,
        destinationTypeApi: {
          type: 'card-narration',
        },
      })

      switch (generatedNarration.type) {
        case 'completed':
          return { type: 'success', videoUrl: generatedNarration.videoUrl }
        case 'error':
          return { type: 'error' }
        case 'generating': {
          const thumbnailUrl =
            generatedNarration.avatarThumbnailUrl === undefined
              ? undefined
              : getFileContentImage(generatedNarration.avatarThumbnailUrl, { bucket: 'sierra-static' })

          return {
            type: 'generating',
            thumbnailUrl,
            createdAt: generatedNarration.createdAt,
            estimatedCompletedAt: generatedNarration.estimatedCompletedAt,
            narrationId: narrationId,
          }
        }
        case 'cancelled': {
          return narration.url !== undefined
            ? { type: 'success', videoUrl: narration.url }
            : { type: 'no-narration' }
        }
        default:
          assertNever(generatedNarration)
      }
    },
    refetchInterval: query => {
      if (query.state.data?.type === 'generating') {
        return 5000
      } else {
        return false
      }
    },
  })

  if (narration === undefined) return { type: 'no-narration' }
  if (narration.generatedNarrationId === undefined) {
    const fallbackState: NarrationVideoState =
      narration.url !== undefined ? { type: 'success', videoUrl: narration.url } : { type: 'no-narration' }

    return fallbackState
  }
  return query.data ?? { type: 'loading' as const }
}
