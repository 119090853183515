import React from 'react'
import { isMobile } from 'react-device-detect'
import { RouterLink } from 'sierra-client/components/common/link'
import { ChromeLogo } from 'sierra-client/components/common/logos/chrome-logo'
import { EdgeLogo } from 'sierra-client/components/common/logos/edge-logo'
import { SafariLogo } from 'sierra-client/components/common/logos/safari-logo'
import { useHardwarePermissionsState } from 'sierra-client/components/liveV2/hardware-permissions'
import {
  PreLobbyHardwareControls,
  PreLobbyHardwareSelection,
} from 'sierra-client/components/liveV2/live-layer/pre-lobby-hardware-controls'
import { LocalParticipant } from 'sierra-client/components/liveV2/participant'
import { LIVE_PRE_LOBBY_PERMISSIONS_DENIED_HELP_PAGE } from 'sierra-client/config/links'
import { useTranslation } from 'sierra-client/hooks/use-translation'
import { useSelector } from 'sierra-client/state/hooks'
import { selectUser } from 'sierra-client/state/user/user-selector'
import { Icon } from 'sierra-ui/components'
import { Button, Spacer, Text, View } from 'sierra-ui/primitives'
import { token } from 'sierra-ui/theming'
import { minWidth } from 'sierra-ui/utils/media-query-styles'
import styled from 'styled-components'

const VideoContainer = styled.div`
  position: relative;
  isolation: isolate;
  aspect-ratio: 16/9;
  width: 100%;
  border-radius: 16px;
  overflow: hidden;

  ${minWidth.tablet} {
    aspect-ratio: 4/3;
  }
`

const InformationContainer = styled(VideoContainer)`
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  border-radius: 16px;
  text-align: center;
  padding: 16px 10px;

  @media (prefers-color-scheme: dark) {
    background: ${token('surface/soft')};
  }
  @media (prefers-color-scheme: light) {
    box-shadow: 0px 16px 24px rgba(0, 0, 0, 0.05);
  }
`

const BigInformationContainer = styled(View).attrs({ gap: 'none' })`
  position: relative;
  isolation: isolate;
  padding: 64px 48px 40px 48px;
  display: flex;
  align-items: center;
  flex-direction: column;
  border-radius: 16px;
  text-align: center;

  & > * {
    max-width: 410px;
  }

  @media (prefers-color-scheme: dark) {
    background: ${token('surface/soft')};
  }
  @media (prefers-color-scheme: light) {
    box-shadow: 0px 16px 24px rgba(0, 0, 0, 0.05);
  }
`

const IconContainer = styled.div`
  flex-shrink: 0;
  width: 48px;
  height: 48px;
  aspect-ratio: 1/1;
  border-radius: 9999px;
  background: rgba(123, 138, 156, 0.08);
  margin-bottom: 24px;
  display: flex;
  align-items: center;
  justify-content: center;
`

const PermissionsPrompt: React.FC = () => {
  const { t } = useTranslation()
  return (
    <InformationContainer>
      <IconContainer>
        <Icon iconId='video' />
      </IconContainer>

      <Text bold={true} size='large'>
        {t('live-page.permissions-prompt__title')}
      </Text>
      <Text size='small' color='foreground/muted'>
        {t('live-page.permissions-prompt__body')}
      </Text>
    </InformationContainer>
  )
}

const TitleText = styled(Text).attrs({ bold: true, size: 'large' })`
  line-height: 1.4;
`

const PermissionsDenied: React.FC = () => {
  const { t } = useTranslation()
  return (
    <InformationContainer>
      <IconContainer>
        <Icon iconId='warning--filled' />
      </IconContainer>
      <TitleText bold={true} size='large'>
        {t('live-page.permissions-denied__title')}
      </TitleText>
      <Spacer size='8' />
      <Text color={'foreground/muted'}>
        Please check your browser settings and make sure Sana have access to your microphone and camera.
      </Text>
      <Spacer size='32' />
      <View justifyContent='center' gap='none'>
        <RouterLink href={LIVE_PRE_LOBBY_PERMISSIONS_DENIED_HELP_PAGE} target='_blank' rel='noreferrer'>
          <Button variant='secondary'>{t('live-page.live-issue-information__view_help_center')}</Button>
        </RouterLink>
      </View>
    </InformationContainer>
  )
}

const LeftAlignedView = styled(View)`
  text-align: left;
`

const InstructionContainer = styled(View).attrs({ alignItems: 'flex-start', gap: '24' })``
const LogoContainer = styled(View)`
  width: 32px;
`

const InstructionsBlock = styled(View).attrs({ marginTop: '32', marginBottom: '24' })`
  background: rgba(123, 138, 156, 0.05);
`

const MoreInfoPermissionsDenied: React.FC = () => {
  const { t } = useTranslation()
  return (
    <BigInformationContainer>
      <IconContainer>
        <Icon iconId='warning--filled' />
      </IconContainer>
      <TitleText>{t('live-page.permissions-denied__title')}</TitleText>
      <Spacer size='8' />
      <Text color={'foreground/muted'} size='small'>
        {t('live-page.permissions-denied__subheading')}
      </Text>
      <InstructionsBlock direction='column' radius='size-16' padding='32' gap='24'>
        <InstructionContainer direction='row'>
          <LogoContainer>
            <ChromeLogo />
          </LogoContainer>
          <LeftAlignedView direction='column' gap='4'>
            <Text bold size='small'>
              Google Chrome
            </Text>
            <Text color={'foreground/secondary'} size='small'>
              {t('live-page.permissions-denied__chrome_settings')}{' '}
            </Text>
          </LeftAlignedView>
        </InstructionContainer>
        <InstructionContainer direction='row'>
          <LogoContainer>
            <EdgeLogo />
          </LogoContainer>
          <LeftAlignedView direction='column' gap='4'>
            <Text bold size='small'>
              Microsoft Edge
            </Text>
            <Text color={'foreground/secondary'} size='small'>
              {t('live-page.permissions-denied__edge_settings')}{' '}
            </Text>
          </LeftAlignedView>
        </InstructionContainer>
        <InstructionContainer direction='row'>
          <LogoContainer>
            <SafariLogo />
          </LogoContainer>
          <LeftAlignedView direction='column' gap='4'>
            <Text bold size='small'>
              Safari
            </Text>
            <Text color={'foreground/secondary'} size='small'>
              {t('live-page.permissions-denied__safari_settings')}
            </Text>
          </LeftAlignedView>
        </InstructionContainer>
      </InstructionsBlock>
      <View justifyContent='center' gap='4'>
        <Text color={'foreground/muted'}>{t('live-page.live-issue-information__didnt-work')}</Text>
        <Text bold color={'foreground/muted'}>
          <a href={LIVE_PRE_LOBBY_PERMISSIONS_DENIED_HELP_PAGE} target='_blank' rel='noreferrer'>
            {t('live-page.live-issue-information__help-link-view')}
          </a>
        </Text>
      </View>
    </BigInformationContainer>
  )
}

const PreLobbyVideo: React.FC<{
  setShowAutoMutedMessage: (show: boolean) => void
  disableAutoMute: React.MutableRefObject<boolean>
}> = ({ setShowAutoMutedMessage, disableAutoMute }) => {
  const permission = useHardwarePermissionsState()
  const user = useSelector(selectUser)

  if (user === undefined) return null

  if (permission.type === 'prompt') return <PermissionsPrompt />
  if (permission.type === 'denied-all-permissions')
    return isMobile === true ? <PermissionsDenied /> : <MoreInfoPermissionsDenied />
  return (
    <View direction='column' gap='16'>
      <VideoContainer>
        <LocalParticipant hideName={true} />
        <PreLobbyHardwareControls
          setShowAutoMutedMessage={setShowAutoMutedMessage}
          disableAutoMute={disableAutoMute}
        />
      </VideoContainer>

      <PreLobbyHardwareSelection />
    </View>
  )
}

export const PreLobbyRightContent: React.FC<{
  setShowAutoMutedMessage: (show: boolean) => void
  disableAutoMute: React.MutableRefObject<boolean>
}> = props => {
  return <PreLobbyVideo {...props} />
}
