import { createContext, Dispatch, useEffect, useMemo, useReducer } from 'react'
import { useGraphQuery } from 'sierra-client/api/hooks/use-graphql-query'
import { editOutlineReducer } from 'sierra-client/features/program/admin/edit/edit-outline-reducer'
import { OutlineAction, OutlineState } from 'sierra-client/features/program/admin/edit/types'
import { FCC } from 'sierra-client/types'
import { useTracking } from 'sierra-client/views/manage/programs/hooks/use-tracking'
import { fetchProgramOutlineQuery } from 'sierra-client/views/manage/programs/staggered-assignments/queries/fetch-program-outline'
import { deserializeOutline } from 'sierra-client/views/manage/programs/staggered-assignments/utils'
import { ProgramOutline } from 'sierra-domain/api/manage'

export type OutlineContext = {
  outline: ProgramOutline
  dispatch: Dispatch<OutlineAction>
}

export const ReducerContext = createContext<OutlineContext>({
  outline: { steps: [], sections: [] },
  dispatch: () => {},
})

export const EditOutlineReducerProvider: FCC<{ programId: string }> = ({ children, programId }) => {
  const tracking = useTracking()
  const query = useGraphQuery({ document: fetchProgramOutlineQuery }, { programId })

  const [state, dispatch] = useReducer(
    (state: OutlineState, action: OutlineAction) => editOutlineReducer(state, action, tracking),
    { status: 'pending' }
  )

  useEffect(() => {
    if (query.isSuccess) {
      const outline = query.data.program
        ? deserializeOutline(query.data.program)
        : { steps: [], sections: [] }
      dispatch({ type: 'SET_INITIAL_OUTLINE', newOutline: outline })
    }
  }, [query.data?.program, query.isSuccess])

  const memoizedContext = useMemo(
    () => ({ outline: state.status === 'pending' ? { steps: [], sections: [] } : state.outline, dispatch }),
    [state, dispatch]
  )

  return <ReducerContext.Provider value={memoizedContext}>{children}</ReducerContext.Provider>
}
