import { throttle } from 'lodash'
import { useEffect, useMemo, useRef } from 'react'
import { useDispatch, useSelector } from 'sierra-client/state/hooks'
import { liveSessionLocalAwarenessStateMerged } from 'sierra-client/state/live-session/actions'
import {
  selectFollowMeIsEnabled,
  selectIsFollowingScroll,
  selectSpotligtUserPosition,
} from 'sierra-client/state/live-session/selectors'
import { WaitForEditor } from 'sierra-client/views/flexible-content/polaris-editor-provider/use-polaris-editor'
import { File, SlateFileType } from 'sierra-domain/flexible-content/types'
import { assertWith, guardWith } from 'sierra-domain/utils'
import { SanaEditor } from 'sierra-domain/v3-author'
import { Element } from 'slate'
import { ReactEditor } from 'slate-react'

const findTopElementId = (editor: SanaEditor): string | undefined => {
  const nodes = editor.children
  let closest: { node: Element; rect: DOMRect } | undefined = undefined

  for (const node of nodes) {
    if (!Element.isElement(node)) continue
    try {
      const domNode = ReactEditor.toDOMNode(editor, node)
      const rect = domNode.getBoundingClientRect()

      const rectIsCloser = closest === undefined || Math.abs(rect.top) < Math.abs(closest.rect.top)

      if (closest === undefined || rectIsCloser) {
        closest = { node, rect }
      } else {
        // since the elements are in order, we can stop looking of we aren't getting closer anymore
        break
      }
    } catch (error) {
      // ignore issues
    }
  }

  return closest?.node.id
}

const getDomElementFromSlateId = (id: string, editor: SanaEditor): HTMLElement | undefined => {
  const nodes = editor.children
  for (const node of nodes) {
    if (!Element.isElement(node)) continue
    try {
      if (node.id === id) {
        return ReactEditor.toDOMNode(editor, node)
      }
    } catch (error) {
      // ignore issues
    }
  }
}

const SlateFollowSharedScrollPosition: React.FC<{
  scrollContainerRef: HTMLDivElement | null
  currentCard: File
  editor: SanaEditor
}> = ({ currentCard, editor }) => {
  assertWith(SlateFileType, currentCard.data.type)

  const spotligtUser = useSelector(selectSpotligtUserPosition)

  useEffect(() => {
    const currentId = spotligtUser?.scrollAtSlateNodeElementId
    if (currentId === undefined) return

    const domNode = getDomElementFromSlateId(currentId, editor)

    // For some reason the scrollIntoView doesn't work if we call it directly
    // unsure why, but this works
    const timeout = setTimeout(() => {
      domNode?.scrollIntoView({ behavior: 'smooth' })
    }, 100)

    return () => {
      clearTimeout(timeout)
    }
  }, [spotligtUser?.scrollAtSlateNodeElementId, editor])

  return null
}

export const FollowSharedScrollPosition: React.FC<{
  scrollContainerRef: HTMLDivElement | null
  currentCard: File
}> = ({ currentCard, scrollContainerRef }) => {
  const isCurrentlyFollowing = useSelector(selectIsFollowingScroll)
  if (currentCard.data.type !== 'general' || !isCurrentlyFollowing) return null

  if (guardWith(SlateFileType, currentCard.data.type)) {
    return (
      <WaitForEditor>
        {editor => (
          <SlateFollowSharedScrollPosition
            editor={editor}
            currentCard={currentCard}
            scrollContainerRef={scrollContainerRef}
          />
        )}
      </WaitForEditor>
    )
  }

  return null
}

const SlateCardScrollPositionTracker: React.FC<{
  scrollContainerRef: HTMLDivElement | null
  currentCard: File
  editor: SanaEditor
}> = ({ scrollContainerRef, currentCard, editor }) => {
  assertWith(SlateFileType, currentCard.data.type)
  const dispatch = useDispatch()
  const lastDispatchedId = useRef<string | undefined>(undefined)

  const setNewScrollPositionId = useMemo(
    () =>
      throttle(() => {
        const id = findTopElementId(editor)
        if (id === lastDispatchedId.current) return

        void dispatch(liveSessionLocalAwarenessStateMerged({ scrollAtSlateNodeElementId: id }))
        lastDispatchedId.current = id
      }, 500),
    [dispatch, editor]
  )

  useEffect(() => {
    setNewScrollPositionId()

    return () => {
      void dispatch(liveSessionLocalAwarenessStateMerged({ scrollAtSlateNodeElementId: undefined }))
    }
  }, [dispatch, setNewScrollPositionId])

  // reset the scroll position when the card changes
  useEffect(() => {
    return () => {
      void dispatch(liveSessionLocalAwarenessStateMerged({ scrollAtSlateNodeElementId: undefined }))
    }
  }, [dispatch, currentCard.id])

  useEffect(() => {
    if (scrollContainerRef) {
      scrollContainerRef.onscroll = setNewScrollPositionId

      return () => {
        scrollContainerRef.onscroll = null
      }
    }
  }, [scrollContainerRef, setNewScrollPositionId])

  return null
}

export const CardScrollPositionTracker: React.FC<{
  scrollContainerRef: HTMLDivElement | null
  currentCard: File
}> = ({ scrollContainerRef, currentCard }) => {
  const followMeIsEnabled = useSelector(selectFollowMeIsEnabled)
  if (currentCard.data.type !== 'general' || !followMeIsEnabled) return null

  if (guardWith(SlateFileType, currentCard.data.type)) {
    return (
      <WaitForEditor>
        {editor => (
          <SlateCardScrollPositionTracker
            editor={editor}
            scrollContainerRef={scrollContainerRef}
            currentCard={currentCard}
          />
        )}
      </WaitForEditor>
    )
  }

  return null
}
