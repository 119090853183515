import { useSetAtom } from 'jotai'
import _ from 'lodash'
import { useEffect } from 'react'
import { PresenceStateAtom, useAddStateEvent } from 'sierra-client/features/sana-now/presence/state'
import { useDeepEqualityMemo } from 'sierra-client/hooks/use-deep-equality-memo'
import {
  LivePresenceUpdate,
  LivePresenceUpdatedData,
} from 'sierra-client/realtime-data/channels/live-presence-channel/events'
import { livePresenceChannel } from 'sierra-client/realtime-data/channels/live-presence-channel/live-presence-channel'
import { useCachedQuery } from 'sierra-client/state/api'
import { LiveSessionId, NanoId12 } from 'sierra-domain/api/nano-id'
import { XRealtimeUniversalPresenceGetLiveSessionPresence } from 'sierra-domain/routes'
import { guardWith, isDefined } from 'sierra-domain/utils'

// Presences are stored as updates since it makes it easier to merge
// between backend and live updated data.

function mergePresences(
  current: LivePresenceUpdatedData[],
  incoming: LivePresenceUpdate[]
): LivePresenceUpdatedData[] {
  const incomingById = _.keyBy(incoming, it => (it.type === 'removed' ? it.sessionId : it.presence.sessionId))
  const currentById = _.keyBy(current, it => it.presence.sessionId)

  const allSessionIds = _.uniq([...current.map(it => it.presence.sessionId), ...Object.keys(incomingById)])

  const entries: LivePresenceUpdatedData[] = allSessionIds
    .filter(guardWith(NanoId12))
    .map((sessionId): LivePresenceUpdatedData | undefined => {
      const currentPresence = currentById[sessionId]
      const incomingUpdate = incomingById[sessionId]

      if (incomingUpdate === undefined) return currentPresence
      if (incomingUpdate.type === 'removed') {
        return currentPresence !== undefined &&
          incomingUpdate.removedAt.getTime() > currentPresence.updatedAt.getTime()
          ? undefined
          : currentPresence
      }

      if (currentPresence === undefined) return incomingUpdate

      return currentPresence.updatedAt.getTime() > incomingUpdate.updatedAt.getTime()
        ? currentPresence
        : incomingUpdate
    })
    .filter(isDefined)

  return entries
}

const ListenToData = ({ liveSessionId }: { liveSessionId: LiveSessionId }): null => {
  const setLiveSessionPresences = useSetAtom(PresenceStateAtom)
  const addEvent = useAddStateEvent()

  const { refetch: refetchAllPresences, data: _backendData } = useCachedQuery(
    XRealtimeUniversalPresenceGetLiveSessionPresence,
    {
      liveSessionId,
    }
  )

  const data = useDeepEqualityMemo(_backendData)
  useEffect(() => {
    if (data !== undefined) {
      setLiveSessionPresences(current => {
        const dataAsUpdates: LivePresenceUpdate[] = data.presences.map(it => ({
          type: 'updated',
          presence: it,
          updatedAt: data.updatedAt,
        }))

        addEvent({ source: 'fetch', updates: dataAsUpdates })
        // The backend data is always the most up-to-date, but there might have been live updates sent after
        // the backend data arrived at the client. We want to keep those newer updates, but anything older then the backend data
        // can be safely removed
        const backendDataTime = data.updatedAt.getTime()
        const currentEntries = current[liveSessionId] ?? []
        const removeOldEntries = currentEntries.filter(it => it.updatedAt.getTime() < backendDataTime)

        return {
          ...current,
          [liveSessionId]: mergePresences(removeOldEntries, dataAsUpdates),
        }
      })
    }
  }, [addEvent, data, liveSessionId, setLiveSessionPresences])

  const { isReceivingData } = livePresenceChannel.useChannel({
    callback: event => {
      if (event.event === 'reload') {
        addEvent({ source: 'realtime-reload', updates: [] })
        void refetchAllPresences()
      } else {
        addEvent({ source: 'realtime', updates: event.data.presenceUpdates })
        setLiveSessionPresences(current => ({
          ...current,
          [liveSessionId]: mergePresences(current[liveSessionId] ?? [], event.data.presenceUpdates),
        }))
      }
    },
    channelId: liveSessionId,
  })

  useEffect(() => {
    // We fetch the full list of presences from the backend while attempting to connect to Ably when
    // this component mounts. Since both of these are asynchronous, there is a potential for us to
    // miss updates that happen in between these two things. To resolve this race condition, we do
    // another full fetch once we have established a connection to Ably.
    if (isReceivingData) {
      void refetchAllPresences()
    }
  }, [isReceivingData, refetchAllPresences])

  // Cleanup all precences when the component unmounts
  useEffect(() => {
    return () => {
      setLiveSessionPresences(current => {
        const copy = { ...current }
        delete copy[liveSessionId]
        return copy
      })
    }
  }, [liveSessionId, setLiveSessionPresences])

  return null
}

export const SubscribeToRemotePresence = ({
  liveSessionId,
}: {
  liveSessionId: LiveSessionId
}): JSX.Element | null => {
  return <ListenToData liveSessionId={liveSessionId} />
}
