import React, { useEffect, useState } from 'react'
import { Modal } from 'sierra-client/components/common/modals/modal'
import { usePost } from 'sierra-client/hooks/use-post'
import { useTranslation } from 'sierra-client/hooks/use-translation'
import {
  AssignmentTypeFilter,
  ContentIdsFilter,
  ContentTypesFilter,
  CustomUserAttributesFilter,
  GroupsFilter,
  OutputFormatFilter,
  ProgramsFilter,
  useCustomUserAttributes,
} from 'sierra-client/views/manage/reports/components'
import { ReportFilter, getDefaultValuesForReport } from 'sierra-client/views/manage/reports/use-reports'
import { Report, ReportJob } from 'sierra-domain/api/analytics-reporting'
import { XRealtimeAnalyticsReportsSubmitJob } from 'sierra-domain/routes'
import { Button, Spacer, Text } from 'sierra-ui/primitives'

interface ReportModalProps {
  isOpen: boolean
  onClose: () => void
  report: Report
  defaultValues?: ReportFilter
  title?: string
  addJob: (job: ReportJob) => void
}

export const ReportModal: React.FC<ReportModalProps> = ({
  isOpen,
  onClose,
  report,
  defaultValues,
  title = '',
  addJob,
}) => {
  const { postWithUserErrorException } = usePost()
  const { t } = useTranslation()
  const allowedCustomUserAttributes = useCustomUserAttributes()
  const [formValues, setFormValues] = useState<ReportFilter>(
    defaultValues || getDefaultValuesForReport(allowedCustomUserAttributes, report)
  )
  const [error, setError] = useState<string | undefined>()
  const [loading, setLoading] = useState(false)

  useEffect(() => {
    setFormValues(defaultValues || getDefaultValuesForReport(allowedCustomUserAttributes, report))
  }, [defaultValues, report, allowedCustomUserAttributes])

  const createReport = async (reportFilter: ReportFilter): Promise<void> => {
    setError(undefined)
    setLoading(true)

    const submittedJob = await postWithUserErrorException(XRealtimeAnalyticsReportsSubmitJob, {
      reportId: report.id,
      ...reportFilter,
    })

    addJob(submittedJob.job)

    setLoading(false)
    onClose()
  }

  return (
    <Modal
      open={isOpen}
      onClose={onClose}
      title={t('manage.reports.filter.modal.title', { reportName: title })}
      size='small'
    >
      {report.parameters.length > 0 ? (
        report.parameters.map(param => {
          switch (param.type) {
            case 'contentIds':
              return (
                <ContentIdsFilter
                  key={param.type}
                  contentTypes={formValues.contentTypes ?? []}
                  selectedIds={formValues.contentIds}
                  updateSelectedIds={contentIds => setFormValues(values => ({ ...values, contentIds }))}
                />
              )
            case 'groups':
              return (
                <GroupsFilter
                  key={param.type}
                  selectedIds={formValues.groups}
                  updateSelectedIds={groups => setFormValues(values => ({ ...values, groups }))}
                />
              )
            case 'programs':
              return (
                <ProgramsFilter
                  key={param.type}
                  selectedIds={formValues.programs}
                  updateSelectedIds={programs => setFormValues(values => ({ ...values, programs }))}
                />
              )
            case 'contentTypes':
              return (
                <ContentTypesFilter
                  key={param.type}
                  allowedTypes={param.allowedTypes}
                  selectedContentTypes={formValues.contentTypes ?? []}
                  updateContentTypes={contentTypes => setFormValues(values => ({ ...values, contentTypes }))}
                />
              )
            case 'assignmentType':
              return (
                <AssignmentTypeFilter
                  key={param.type}
                  selectedType={formValues.assignmentType}
                  updateAssignmentType={assignmentType =>
                    setFormValues(values => ({ ...values, assignmentType }))
                  }
                />
              )
            case 'customUserAttributes':
              return (
                <CustomUserAttributesFilter
                  key={param.type}
                  allowedAttributes={allowedCustomUserAttributes}
                  selectedAttributes={formValues.customUserAttributes ?? []}
                  updateAttributes={customUserAttributes =>
                    setFormValues(values => ({ ...values, customUserAttributes }))
                  }
                />
              )
            case 'outputFormat':
              return (
                <OutputFormatFilter
                  key={param.type}
                  selectedFormat={formValues.outputFormat}
                  allowedFormats={param.formats}
                  updateFormat={outputFormat => setFormValues(values => ({ ...values, outputFormat }))}
                />
              )
          }
        })
      ) : (
        <>
          <Text color='LEGACY_DEFAULT_TEXT_COLOR_REPLACE_ASAP' size='regular'>
            {t('manage.reports.filter.no-filters')}
          </Text>
          <Spacer size='medium' />
        </>
      )}
      <Button grow disabled={loading} onClick={() => createReport(formValues)}>
        {loading ? t('admin.analytics.downloading') : t('admin.analytics.download-report')}
      </Button>
      {error !== undefined && (
        <>
          <Spacer size='xxsmall' />
          <Text size='small' color='redBright'>
            {error}
          </Text>
        </>
      )}
    </Modal>
  )
}
