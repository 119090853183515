import _ from 'lodash'
import React, { useCallback, useMemo, useState } from 'react'
import { useNotif } from 'sierra-client/components/common/notifications'
import { useResolveAsset } from 'sierra-client/hooks/use-resolve-asset'
import { useTranslation } from 'sierra-client/hooks/use-translation'
import { ContentRep } from 'sierra-client/lib/tabular/datatype/internal/reps/content-rep'
import { postWithUserErrorCode } from 'sierra-client/state/api'
import { useDispatch } from 'sierra-client/state/hooks'
import { bareContentId } from 'sierra-client/views/manage/content/utils/content-utils'
import { useContentList } from 'sierra-client/views/manage/courses/course-groups/hooks/use-content-list'
import { useTracking } from 'sierra-client/views/manage/courses/course-groups/hooks/use-tracking'
import {
  CourseGroupComponents,
  RemoveEdition,
  UpdateEdition,
  courseSummaryToEligibleCourse,
} from 'sierra-client/views/manage/courses/course-groups/modals/common'
import { CourseGroupEdition } from 'sierra-domain/api/admin'
import { CourseKind } from 'sierra-domain/api/common'
import { CourseId } from 'sierra-domain/api/nano-id'
import { ImageUnion } from 'sierra-domain/content/v2/content'
import { isLeft } from 'sierra-domain/either'
import { XRealtimeAdminCoursesCreateCourseGroup } from 'sierra-domain/routes'
import { asNonNullable, assertIsNonNullable } from 'sierra-domain/utils'

const {
  CourseGroupModal,
  CourseGroupHeader,
  Content,
  Footer,
  Cancel,
  Save,
  CourseEditionSection,
  AddCourseEditionButton,
  UpdateGroupTitle,
} = CourseGroupComponents

type CourseGroupEditionInfo = Omit<CourseGroupEdition, 'courseId'> & {
  courseId: CourseId | undefined
  incomplete: boolean
  key: number
}

export type CreateEditionPrefillData = {
  courseId: CourseId
  courseKind: CourseKind
  title: string
  description?: string
  image?: ImageUnion
}

export const contentRepToEditionPrefillData = (rep: ContentRep): CreateEditionPrefillData => ({
  courseId: bareContentId(rep.id) as CourseId,
  courseKind: asNonNullable(rep.courseKind),
  title: rep.title,
  description: undefined, // Doesn't exist on ContentRep
  image: rep.image,
})

function createEmptyEdition(): CourseGroupEditionInfo {
  return {
    title: '',
    description: '',
    courseId: undefined,
    language: '',
    isDefault: false,
    incomplete: true,
    key: Math.random(),
  }
}

export const CreateCourseGroupModal: React.FC<{
  prefillData: [CreateEditionPrefillData, ...CreateEditionPrefillData[]]
  onClose: () => void
  onSave: () => void
}> = ({ prefillData, onClose, onSave }) => {
  const tracking = useTracking()
  const { t } = useTranslation()
  const notifications = useNotif()
  const dispatch = useDispatch()

  // temporary
  const { title, image: prefillImage, courseKind, courseId: prefilledFromCourseId } = prefillData[0]

  const [courseEditions, setCourseEditions] = useState<CourseGroupEditionInfo[]>(() => [
    ...prefillData.map(({ courseId, title, description }, i) => ({
      title,
      description,
      courseId,
      language: '',
      isDefault: i === 0,
      incomplete: true,
      key: Math.random(),
    })),
    ...(prefillData.length === 1 ? [createEmptyEdition()] : []),
  ])

  const defaultEdition = useMemo(() => courseEditions.find(x => x.isDefault), [courseEditions])

  const [parentTitle, setParentTitle] = useState(defaultEdition?.title ?? '')

  const addNewCourseEdition = useCallback(() => {
    const newEdition = { ...createEmptyEdition(), isDefault: courseEditions.length === 0, key: Math.random() }
    setCourseEditions(courseEditions => [...courseEditions, newEdition])
    tracking.courseGroup.editionCreate()
  }, [courseEditions.length, tracking.courseGroup])

  const removeEdition: RemoveEdition = useCallback(
    editionIndex => {
      if (editionIndex < courseEditions.length) {
        const edition = courseEditions[editionIndex]
        tracking.courseGroup.editionDelete(edition?.courseId)
        setCourseEditions(courseEditions => courseEditions.filter((_, index) => index !== editionIndex))
      }
    },
    [courseEditions, tracking.courseGroup]
  )

  const updateEdition: UpdateEdition = useCallback(
    (editionIndex, courseId, title, description, language, isDefault) => {
      setCourseEditions(existingEditions => {
        if (existingEditions.length <= editionIndex) {
          throw new Error('Should never happen')
        }

        const newEdition = {
          title,
          description,
          courseId,
          language,
          isDefault,
          incomplete: !title || courseId === undefined || !language,
          key: existingEditions[editionIndex]?.key ?? 1,
        }

        return existingEditions.map((ce, index) => (index === editionIndex ? newEdition : ce))
      })
    },
    []
  )

  const updateDefaultEdition = useCallback(
    (editionIndex: number) =>
      setCourseEditions(currentEditions =>
        currentEditions.map((edition, i) => ({
          ...edition,
          isDefault: i === editionIndex,
        }))
      ),
    []
  )

  const alreadyInUseLanguages = useMemo(() => courseEditions.map(it => it.language), [courseEditions])

  const alreadyInUseCourseIds = useMemo(
    () => courseEditions.map(it => it.courseId).filter(it => it !== undefined),
    [courseEditions]
  )

  const allEditionsValid = useMemo(() => {
    const allComplete = courseEditions.every(it => !it.incomplete)
    const hasOneDefault = _.sumBy(courseEditions, x => (x.isDefault ? 1 : 0)) === 1

    return allComplete && hasOneDefault
  }, [courseEditions])

  const courseList = useContentList(courseKind)

  const eligibleCourses = useMemo(() => courseList.map(courseSummaryToEligibleCourse), [courseList])

  const reset = useCallback(() => {
    setCourseEditions([])
    onClose() // close modal
  }, [onClose])

  const prefilledImageSrc = useResolveAsset({
    image: prefillImage,
    assetContext: { type: 'course', courseId: prefilledFromCourseId },
    size: 'admin',
  })

  const background = prefillImage !== undefined ? prefilledImageSrc : undefined
  const isSaveDisabled = title === '' || courseEditions.length < 2 || !allEditionsValid

  const save = useCallback(async () => {
    try {
      if (courseEditions.length < 2 || !allEditionsValid) return

      // Editions without a courseId are not valid, so this should never happen
      const filteredEditions = courseEditions.map((it): CourseGroupEdition => {
        const { courseId, ...rest } = it

        assertIsNonNullable(
          courseId,
          'Assertion failed: Should not attempt to save edition without courseId.'
        )

        return { courseId, ...rest }
      })

      const result = await postWithUserErrorCode(
        XRealtimeAdminCoursesCreateCourseGroup,
        {
          groupTitle: parentTitle.trim() !== '' ? parentTitle : defaultEdition?.title ?? 'New course group',
          groupDescription: defaultEdition?.description ?? '',
          courseEditions: filteredEditions,
        },
        dispatch,
        {
          notifications: true,
        }
      )

      void onSave()

      if (isLeft(result)) {
        /*if (result.editionsAlreadyInUse && result.editionsAlreadyInUse.length > 0) {
          const notificationType = 'course-group/editions-already-in-use' as NotificationType
          notifications.push({ type: notificationType })
        } else*/
        {
          throw Error(`An unknown error occurred while creating a course-group`)
        }
      } else {
        reset()
        notifications.push({ type: 'course-group-created' })
        tracking.courseGroup.save(result.right.courseGroupId ?? 'unknown id')
      }
    } catch (e) {
      notifications.push({ type: 'course-group-creation-failed' })
    }
  }, [
    courseEditions,
    allEditionsValid,
    parentTitle,
    defaultEdition?.title,
    defaultEdition?.description,
    dispatch,
    onSave,
    reset,
    notifications,
    tracking.courseGroup,
  ])

  return (
    <CourseGroupModal open onClose={reset}>
      <CourseGroupHeader background={background} onClose={reset} />
      <Content>
        <UpdateGroupTitle title={parentTitle} setTitle={setParentTitle} placeholder={defaultEdition?.title} />

        {courseEditions.map(({ key, courseId, title, description, language, isDefault }, index) => (
          <CourseEditionSection
            key={key}
            edition={{
              id: courseId,
              no: index + 1,
              title,
              description,
              language,
              isDefault,
            }}
            courseList={eligibleCourses}
            editionCount={courseEditions.length}
            updateEdition={updateEdition}
            removeEdition={removeEdition}
            alreadyInUseCourseIds={alreadyInUseCourseIds}
            alreadyInUseLanguages={alreadyInUseLanguages}
            updateCallbacks={{
              updateDefaultEdition,
            }}
          />
        ))}
        <AddCourseEditionButton onClick={addNewCourseEdition} />
      </Content>
      <Footer>
        <Cancel onClick={reset}>{t('modal.cancel')}</Cancel>
        <Save onClick={save} disabled={isSaveDisabled}>
          {t('modal.save')}
        </Save>
      </Footer>
    </CourseGroupModal>
  )
}
