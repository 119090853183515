import React from 'react'
import { config } from 'sierra-client/config/global-config'
import { useMobileTopbarShown } from 'sierra-client/features/collapsable-sidebar/hooks/use-mobile-topbar-shown'
import { useToggleMobileGlobalSidebar } from 'sierra-client/features/collapsable-sidebar/hooks/use-toggle-mobile-global-sidebar'
import { useSelector } from 'sierra-client/state/hooks'
import { selectUser } from 'sierra-client/state/user/user-selector'
import { getAvatarImage } from 'sierra-client/utils/avatar-img'
import { getUserName } from 'sierra-domain/utils'
import { RoundAvatar } from 'sierra-ui/components'
import { IconButton, Text, View } from 'sierra-ui/primitives'
import styled from 'styled-components'

const Wrapper = styled(View).attrs({
  direction: 'row',
  justifyContent: 'space-between',
  alignItems: 'center',
  padding: '12',
  background: 'surface/default',
})`
  min-height: 48px;
  max-height: 48px;
  height: 48px;
  overflow: hidden;
`

const Left = styled(View)``

const Icons = styled(View).attrs({
  direction: 'row',
  justifyContent: 'flex-start',
  alignItems: 'center',
  gap: 'none',
})``

const MenuButton = styled(IconButton).attrs({ variant: 'transparent', iconId: 'hamburger--2stripes' })``

const Home: React.FC = () => {
  const user = useSelector(selectUser)
  const { toggle } = useToggleMobileGlobalSidebar()
  const { isScorm } = config.scorm

  return (
    <>
      <Left>
        {user && !isScorm && (
          <>
            <RoundAvatar
              size='tiny'
              firstName={user.firstName}
              lastName={user.lastName}
              src={getAvatarImage(user.uuid, user.avatar)}
              color={user.avatarColor}
            />
            <Text size='small' bold>
              {getUserName(user)}
            </Text>
          </>
        )}
      </Left>
      <Icons>
        {/*<NotificationButton />*/}
        <MenuButton onClick={toggle} />
      </Icons>
    </>
  )
}

const SelfPaced: React.FC = () => {
  const { toggle } = useToggleMobileGlobalSidebar()

  return (
    <>
      <Left>
        <Text size='small' bold>
          Course title
        </Text>
      </Left>
      <Icons>
        <MenuButton onClick={toggle} />
      </Icons>
    </>
  )
}

const Live: React.FC = () => {
  const { toggle } = useToggleMobileGlobalSidebar()

  return (
    <>
      <Left>
        <Text size='small' bold>
          Course title
        </Text>
      </Left>
      <Icons>
        {/*<ChatButton />*/}
        <MenuButton onClick={toggle} />
      </Icons>
    </>
  )
}

type MobileTopbarType = 'home' | 'self-paced' | 'live'

type MobileTopbarProps = {
  type?: MobileTopbarType
}

export const MobileTopbar: React.FC<MobileTopbarProps> = ({ type = 'home' }) => {
  const mobileTopbarShown = useMobileTopbarShown()

  if (!mobileTopbarShown) {
    return <></>
  }

  return (
    <Wrapper>
      {((): JSX.Element => {
        switch (type) {
          case 'live':
            return <Live />
          case 'self-paced':
            return <SelfPaced />
          case 'home':
          default:
            return <Home />
        }
      })()}
    </Wrapper>
  )
}
