import { AppThemeTokenProvider } from 'sierra-client/config/token-provider'
import { PageIdentifier, SanaPage } from 'sierra-client/layout/sana-page'
import { responsiveHorizontalCSS } from 'sierra-client/layout/styles/content'
import { Heading, Spacer, View } from 'sierra-ui/primitives'
import styled from 'styled-components'

const PaddingContainer = styled(View).attrs({ direction: 'column', gap: 'none', alignItems: 'flex-start' })`
  ${responsiveHorizontalCSS};
`

const StyledHeading = styled(Heading)`
  & {
    text-align: center;
    width: 100%;
  }
`

export const NotFoundPage = (): JSX.Element => {
  return (
    <AppThemeTokenProvider>
      <SanaPage page={PageIdentifier.FourZeroFour()} showIntercomLauncher={true} mode='light'>
        <PaddingContainer>
          <Spacer size='96' />
          <StyledHeading size='h2'>404 - Page Not Found</StyledHeading>
        </PaddingContainer>
      </SanaPage>
    </AppThemeTokenProvider>
  )
}
