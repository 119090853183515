import { useRouter, useSearch } from '@tanstack/react-router'
import { Atom, useAtomValue } from 'jotai'
import React from 'react'
import { getFallbackFileId } from 'sierra-client/views/flexible-content/navigate-to-default-file'
import { CreateContentId } from 'sierra-domain/api/nano-id'
import { ContentType, ScopedCreateContentId } from 'sierra-domain/collaboration/types'
import { ReplayUpdatesContext } from 'sierra-domain/version-history/types'

export const NavigateToDefaultFile: React.FC<{
  contentId: CreateContentId
  mode: ContentType
  replayContextAtom: Atom<ReplayUpdatesContext>
}> = ({ mode, contentId, replayContextAtom }) => {
  const router = useRouter()
  const replayContext = useAtomValue(replayContextAtom)
  const search = useSearch({ strict: false })

  if (replayContext.type === 'loading') {
    return null
  }
  const fallbackFileId = getFallbackFileId(replayContext.currentYDoc)

  if (fallbackFileId !== undefined) {
    const scope = ScopedCreateContentId.urlSubPath(mode)
    void router.navigate({
      to: `/history/${scope}/$`,
      params: { _splat: `${contentId}/${fallbackFileId}` },
      replace: true,
      search,
    })
  }

  return null
}
