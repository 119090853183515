import { useTranslation } from 'sierra-client/hooks/use-translation'
import { EmptyBody, EmptySection } from 'sierra-client/views/manage/components/common'
import { Icon } from 'sierra-ui/components'
import { Spacer, Text, View } from 'sierra-ui/primitives'

export const EmptyProgramsSection: React.FC = () => {
  const { t } = useTranslation()
  return (
    <View direction='column' gap='none'>
      <Text size='large' bold>
        {t('dictionary.program-plural')}
      </Text>
      <Spacer size='small' />
      <EmptySection>
        <Icon iconId='path' size='size-16' color='foreground/primary' />
        <Spacer size='xsmall' />
        <Text size='regular' bold>
          {t('manage.event-groups.no-programs.title')}
        </Text>
        <Spacer size='4' />
        <EmptyBody size='small' align='center'>
          {t('manage.event-groups.no-programs.body')}
        </EmptyBody>
      </EmptySection>
    </View>
  )
}
