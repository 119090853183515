import { DateTime } from 'luxon'
import React from 'react'
import { useSetLiveSessionStartedMutation } from 'sierra-client/api/hooks/use-live-session'
import { MeetLogo } from 'sierra-client/components/common/logos/meet-logo'

import { TeamsLogo } from 'sierra-client/components/common/logos/teams-logo'
import { ZoomLogo } from 'sierra-client/components/common/logos/zoom-logo'
import { useLiveSessionContext } from 'sierra-client/components/liveV2/contexts/live-session-data'
import { toLocalDayFormat, toLocalTimeFormat } from 'sierra-client/core/format'
import { useFormattedTimeToSession } from 'sierra-client/hooks/use-formatted-time-to-session'
import { useTranslation } from 'sierra-client/hooks/use-translation'
import { Trans } from 'sierra-client/hooks/use-translation/trans'
import { getOrInferVideoCallProviderFromVideoCallSetting } from 'sierra-client/lib/sana-now/get-video-call-provider-from-video-call-setting'
import { useSelector } from 'sierra-client/state/hooks'
import { selectIsFacilitator } from 'sierra-client/state/live-session/selectors'
import { LiveSessionBase } from 'sierra-domain/content/session'
import { iife } from 'sierra-domain/utils'
import { Icon, Layout } from 'sierra-ui/components'
import { Button, Heading, Spacer, Text, View } from 'sierra-ui/primitives'
import { token } from 'sierra-ui/theming'
import { maxWidth } from 'sierra-ui/utils/media-query-styles'
import styled from 'styled-components'

const PolarisPreLobbyOuterContainer = styled(Layout)`
  display: flex;
  flex-direction: column;

  ${maxWidth.tablet} {
    flex: 1;
  }

  background-color: ${token('surface/soft')};
`

const Divider = styled.hr`
  width: 50%;
  height: 1px;
  background-color: ${token('border/default')};
  margin: unset;
`

const TextStyledToHeading = styled(Text)`
  font-size: 40px;
  font-weight: 400;
  line-height: 44px;
  letter-spacing: -0.01em;
`

const TextStyledToHeadingSubtitle = styled(Text)`
  font-size: 24px;
  line-height: 44px;
  letter-spacing: -0.01em;
  color: ${token('foreground/secondary')};
`

const FormatSessionTimestamp = ({ session }: { session: LiveSessionBase }): JSX.Element | null => {
  const { t } = useTranslation()

  if (session.type === 'not-scheduled') {
    return (
      <Heading bold size='h5' color='grey40'>
        {t('dictionary.live-session.not-scheduled')}
      </Heading>
    )
  }
  const start = DateTime.fromISO(session.startTime)
  const end = DateTime.fromISO(session.endTime)

  if (session.allDay) {
    if (start.toJSDate().getDate() === end.toJSDate().getDate()) {
      return (
        <Heading bold size='h5' color='grey40'>
          {toLocalDayFormat(start)}
        </Heading>
      )
    } else
      return (
        <Heading bold size='h5' color='grey40'>
          {toLocalDayFormat(start)} – {toLocalDayFormat(end)}
        </Heading>
      )
  }
  return (
    <Heading bold size='h5' color='grey40'>
      {start.toFormat('MMM d') + ', ' + toLocalTimeFormat(start)} – {toLocalTimeFormat(end)}
    </Heading>
  )
}

const FIFTEEN_MINUTES = 15 * 60 * 1000

export const SanaNowPreLobby: React.FC<{ formattedTime?: string }> = () => {
  const { t } = useTranslation()
  const { formattedTime, msRemaining } = useFormattedTimeToSession() ?? {}
  const liveSession = useLiveSessionContext()

  const sessionWillStartSoon = msRemaining === undefined || msRemaining < FIFTEEN_MINUTES
  const sessionShouldHaveStarted = msRemaining !== undefined && msRemaining <= 0

  const isFacilitator = useSelector(selectIsFacilitator)
  const shouldShowStartButton = isFacilitator || (liveSession.data.learnerLedSession && sessionWillStartSoon)
  const startSessionMutation = useSetLiveSessionStartedMutation()

  if (liveSession.data.videoCallSetting.type === 'sana') {
    throw Error('Encountered sana video call setting in sana now pre-lobby')
  }

  const videoCallProvider = getOrInferVideoCallProviderFromVideoCallSetting(liveSession.data.videoCallSetting)

  const copy = iife(() => {
    switch (videoCallProvider) {
      case 'google-meet':
        return {
          logo: <MeetLogo width={18} height={18} />,
          title: t('sana-now.pre-lobby.join-video-call--title-meet'),
          subTitle: t('sana-now.pre-lobby.join-video-call--subtitle-meet'),
          buttonText: t('sana-now.pre-lobby.join-video-call--button-text-meet'),
        }
      case 'microsoft-teams':
        return {
          logo: <TeamsLogo width={18} height={18} />,
          title: t('sana-now.pre-lobby.join-video-call--title-teams'),
          subTitle: t('sana-now.pre-lobby.join-video-call--subtitle-teams'),
          buttonText: t('sana-now.pre-lobby.join-video-call--button-text-teams'),
        }
      case 'zoom':
        return {
          logo: <ZoomLogo width={18} height={18} />,
          title: t('sana-now.pre-lobby.join-video-call--title-zoom'),
          subTitle: t('sana-now.pre-lobby.join-video-call--subtitle-zoom'),
          buttonText: t('sana-now.pre-lobby.join-video-call--button-text-zoom'),
        }
      // TODO(Sana now) what copy for other cases?
      default:
        return {
          logo: <Icon iconId='video-call' size='size-16' />,
          title: t('sana-now.pre-lobby.join-video-call--title-other'),
          subTitle: t('sana-now.pre-lobby.join-video-call--subtitle-other'),
          buttonText: t('sana-now.pre-lobby.join-video-call--button-text'),
        }
    }
  })

  const meetingUrl =
    liveSession.data.videoCallSetting.type === 'url' ? liveSession.data.videoCallSetting.url : undefined

  return (
    <PolarisPreLobbyOuterContainer>
      <View grow justifyContent='center' alignItems='center' direction='column' gap='none'>
        <FormatSessionTimestamp session={liveSession.data} />
        <Spacer size='12' />
        <TextStyledToHeading align='center'>
          {sessionShouldHaveStarted ? (
            <Trans i18nKey='sana-now.pre-lobby.meeting-should-have-started' />
          ) : (
            <Trans
              i18nKey='sana-now.pre-lobby.meeting-starts-in-time'
              values={{ title: liveSession.data.title, time: formattedTime }}
              components={{
                bold: <strong />,
              }}
            />
          )}
        </TextStyledToHeading>

        <TextStyledToHeadingSubtitle>
          {iife(() => {
            if (liveSession.data.learnerLedSession === true && !sessionWillStartSoon) {
              return t('sana-now.pre-lobby.learner-led-start-explanation')
            } else t('sana-now.pre-lobby.meeting-starts-in-time--subtitle')
          })}
        </TextStyledToHeadingSubtitle>

        {shouldShowStartButton && (
          <View paddingTop={'32'}>
            <Button
              variant='success'
              loading={startSessionMutation.isPending}
              onClick={() => startSessionMutation.mutate({ liveSessionId: liveSession.liveSessionId })}
            >
              {t('sana-now.pre-lobby.start-session-button')}
            </Button>
          </View>
        )}

        {meetingUrl !== undefined && (
          <>
            <Spacer size='48' />
            <Divider />
            <Spacer size='48' />
            <View gap='6'>
              {copy.logo}
              <Text size='regular' bold align='center'>
                {copy.title}
              </Text>
            </View>
            <Spacer size='8' />
            <Text style={{ maxWidth: '378px' }} size='small' color={'foreground/secondary'} align='center'>
              {copy.subTitle}
            </Text>
            <View paddingTop={'32'}>
              <Button
                decoratorPosition='right'
                icon='new--tab--fill'
                variant='primary'
                onClick={() => window.open(meetingUrl, '_blank', 'noopener,noreferrer')}
              >
                {copy.buttonText}
              </Button>
            </View>
          </>
        )}
      </View>
    </PolarisPreLobbyOuterContainer>
  )
}
