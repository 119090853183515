import { useMemo } from 'react'
import { useHref } from 'sierra-client/hooks/router/use-href'
import { ManagePath } from 'sierra-client/views/manage/components/layout/types'

type ManageRouterPath = [ManagePath, string | undefined, string | undefined]

export const useManageRouting = (): ManageRouterPath | ['overview'] | [] => {
  const href = useHref()

  return useMemo(() => {
    if (href.startsWith('/manage/')) {
      const subPath = href.split('/manage/')[1]
      if (subPath === undefined) {
        return ['overview']
      }

      const subPathWithoutQuery = subPath.split('?')[0]
      if (subPathWithoutQuery === undefined) {
        return ['overview']
      }

      return subPathWithoutQuery.split('/') as ManageRouterPath
    }

    return []
  }, [href])
}
