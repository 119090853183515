import { useEffect, useState } from 'react'
import { useTranslation } from 'sierra-client/hooks/use-translation'
import { Modal } from 'sierra-ui/components'
import { Button, IconButton, InputPrimitive, Text, View } from 'sierra-ui/primitives'
import styled from 'styled-components'

const CloseButton = styled(IconButton).attrs({ iconId: 'close', variant: 'transparent' })`
  position: absolute;
  top: 16px;
  right: 16px;
`

const Form = styled.form`
  display: flex;
  flex-direction: column;
  gap: 32px;
`

export const TeamspaceFolderModal: React.FC<{
  modalOpen: boolean
  defaultFolderName: string
  confirmLabel: string
  onConfirm: (name: string) => void
  onClose: () => void
}> = ({ modalOpen, onConfirm, onClose, defaultFolderName, confirmLabel }) => {
  const { t } = useTranslation()
  const [name, setName] = useState(defaultFolderName)

  useEffect(() => {
    setName(defaultFolderName)
  }, [defaultFolderName, modalOpen])

  return (
    <Modal size={{ width: 500 }} open={modalOpen} onClose={onClose}>
      <View direction='column' margin='32'>
        <CloseButton onClick={onClose} />

        <Form
          onSubmit={event => {
            event.preventDefault()
            onConfirm(name)
          }}
        >
          <View direction='column' gap='24'>
            <View gap='8' direction='column'>
              <Text size='large' bold>
                {t('teamspace.folders.add-folder-title')}
              </Text>
              <Text color='foreground/secondary'>{t('teamspace.folders.add-folder-body')}</Text>
            </View>
            <InputPrimitive
              value={name}
              placeholder={t('teamspace.folders.add-folder-placeholder')}
              onChange={(event: React.ChangeEvent<HTMLInputElement>) => {
                setName(event.target.value)
              }}
              autoFocus
            />
          </View>
          <View justifyContent='flex-end' gap='8'>
            <Button
              type='reset'
              variant='secondary'
              onClick={() => {
                onClose()
              }}
            >
              {t('admin.organization.confirm-cancel')}
            </Button>
            <Button type='submit' variant='primary' disabled={name === ''}>
              {confirmLabel}
            </Button>
          </View>
        </Form>
      </View>
    </Modal>
  )
}
