import _ from 'lodash'
import { DraftEditionConfig } from 'sierra-client/views/manage/courses/course-groups/types'

export const isEditionEmpty = ({ editionId, title, language }: DraftEditionConfig): boolean =>
  editionId === undefined || title === '' || language === ''

export const hasOnlyOneDefaultEdition = (editions: DraftEditionConfig[] | undefined = []): boolean => {
  const defaultEditionCount = _.sumBy(editions, e => (e.isDefault ? 1 : 0))
  return defaultEditionCount === 1
}

export const areIdsUnique = (editions: DraftEditionConfig[] | undefined = []): boolean => {
  const editionIds = editions.map(d => d.editionId)
  return _.uniq(editionIds).length === editionIds.length
}

export const areEditionsValid = (editions: DraftEditionConfig[] | undefined = []): boolean => {
  const allEditionsUnique = areIdsUnique(editions)
  const onlyOneDefaultEdition = hasOnlyOneDefaultEdition(editions)

  const noEmptyEditions = editions.every(e => !isEditionEmpty(e))
  const hasAtLeastTwoEditions = editions.length >= 2

  return allEditionsUnique && onlyOneDefaultEdition && noEmptyEditions && hasAtLeastTwoEditions
}
