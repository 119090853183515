import { useEffect, useState } from 'react'

/**
  Returns `false` on the first render, triggers a re-render and returns `true` for all
  subsequent renders.
*/
export const useWasRenderedOnce = (): boolean => {
  const [wasRenderedOnce, setWasRenderedOnce] = useState(false)

  useEffect(() => {
    setWasRenderedOnce(true)
  }, [])

  return wasRenderedOnce
}
