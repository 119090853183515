import { atom, useAtomValue, useSetAtom } from 'jotai'
import React, { FC, useCallback, useEffect, useRef, useState } from 'react'
import { Logging } from 'sierra-client/core/logging'
import { useShouldShowPrompt } from 'sierra-client/editor/blocks/paragraph/use-should-show-prompt'
import { useSlashMenuState } from 'sierra-client/editor/editor-jotai-context'
import { useDebouncedAndLiveState } from 'sierra-client/hooks/use-debounced-state'
import { useIsDebugMode } from 'sierra-client/hooks/use-is-debug-mode'
import { useOnMount } from 'sierra-client/hooks/use-on-mount'
import { useStableFunction } from 'sierra-client/hooks/use-stable-function'
import { useIsUnique } from 'sierra-client/hooks/use-uniqueness-check'
import { useCachedQuery } from 'sierra-client/state/api'
import { useGetFileTitle } from 'sierra-client/state/flexible-content/file-title'
import { useDispatch } from 'sierra-client/state/hooks'
import { useCreatePageContextSafe } from 'sierra-client/views/flexible-content/create-page-context'
import { useFileContext } from 'sierra-client/views/flexible-content/file-context'
import { useEditorReadOnly } from 'sierra-client/views/v3-author/editor-context/editor-context'
import { useElement } from 'sierra-client/views/v3-author/element-context'
import { getNodesInRange } from 'sierra-client/views/v3-author/paste/with-copy'
import { printNodeToMd } from 'sierra-client/views/v3-author/print-nodes-as-markdown'
import { rangeOfNode } from 'sierra-client/views/v3-author/queries'
import { getAllNodeIds } from 'sierra-client/views/v3-author/slash-menu/content-generation'
import { setHandleAcceptCompletionKeyDownCallback } from 'sierra-client/views/v3-author/use-on-key-down'
import { SlateDocumentMap } from 'sierra-domain/collaboration/serialization'
import { safeGetFile } from 'sierra-domain/editor/operations/y-utilts'
import { FileId } from 'sierra-domain/flexible-content/identifiers'
import { File } from 'sierra-domain/flexible-content/types'
import { createNanoId12FromString } from 'sierra-domain/nanoid-extensions'
import { XRealtimeAuthorGenerateContinueWriting } from 'sierra-domain/routes'
import { isCurrentSelectionCollapsed, textInNodes } from 'sierra-domain/slate-util'
import { guardWith } from 'sierra-domain/utils'
import { CustomElementType } from 'sierra-domain/v3-author'
import { getSlateDocumentSafe } from 'sierra-domain/v3-author/slate-yjs-extension'
import { Editor, Path, Point, Range, Text } from 'slate'
import { ReactEditor, useSelected, useSlateSelector, useSlateStatic } from 'slate-react'
import styled from 'styled-components'
import * as Y from 'yjs'

const CompletionSpan = styled.span.attrs({ contentEditable: false })`
  opacity: 0.3;
  user-select: none;

  cursor: pointer;
  :hover {
    opacity: 0.6;
  }
`

function findLatestWords(editor: Editor, n: number): string {
  const { selection } = editor
  if (selection === null || !Range.isCollapsed(selection)) return ''
  const { focus } = selection
  const anchor = Editor.start(editor, [])
  const range = { anchor, focus }

  // Start from the user's cursor and move backwards one word at a time until the desired number of words have been collected
  let distance = n
  const start = focus
  let end = start
  for (const point of Editor.positions(editor, { at: range, reverse: true, unit: 'word' })) {
    end = point
    if (distance === 0) break

    distance--
  }

  const range2 = { anchor: start, focus: end }
  const nodes = getNodesInRange(editor, range2)
  const mdResult = printNodeToMd(editor, nodes).replace(/\n*$/, '')
  return mdResult
}

const latestNewSelectedTextNodeAtom = atom<Text | undefined>(undefined)

const debounceTime = 1000
const wordCount = 50

const getSuggestionId = (context: string, suggestion: string): string => {
  return createNanoId12FromString(context + suggestion)
}

function getFileText(yDoc: Y.Doc, fileId: FileId, getFileTitle: (file: File) => string): string {
  const document = getSlateDocumentSafe(yDoc, fileId) ?? []
  const file = safeGetFile(yDoc, fileId)
  const title = file !== undefined ? getFileTitle(file) : undefined
  return (title !== undefined ? `<title>${title}</title>\n\n` : '') + textInNodes(document).join('\n')
}

const emptyMap: SlateDocumentMap = {}
function useGetAdditionalContext(): () => string | undefined {
  const yDoc = useCreatePageContextSafe()?.operationState.yDoc
  const fileId = useFileContext().file.id
  const getFileTitle = useGetFileTitle(yDoc ?? emptyMap)

  return useStableFunction(() => {
    if (yDoc === undefined) {
      return undefined
    }
    const allFileIdsWithIndex =
      getAllNodeIds(yDoc)
        ?.filter(guardWith(FileId))
        .map((id, index): [id: FileId, index: number] => [id, index]) ?? []
    const currentFileIndex = allFileIdsWithIndex.findIndex(([id]) => id === fileId)
    let additionalContext = getFileText(yDoc, fileId, getFileTitle)

    if (currentFileIndex === -1) {
      return additionalContext
    }

    // We want to fill a large context window for the completion, so look outside the current file for more text
    const filesToTraverse = [
      // Start by looking backwards for more context
      ...allFileIdsWithIndex.slice(0, currentFileIndex).reverse(),
      // Then look forwards if we need even more
      ...allFileIdsWithIndex.slice(currentFileIndex + 1),
    ]

    for (const [fileId, originalIndex] of filesToTraverse) {
      const newText = getFileText(yDoc, fileId, getFileTitle)
      if (originalIndex < currentFileIndex) {
        // Prepend the text from the previous file
        additionalContext = `${newText}\n\n` + additionalContext
      } else {
        // Append the text from the next file
        additionalContext += `\n\n${newText}`
      }

      // Stop once we have enough
      if (
        additionalContext.length >
        // We should keep this quite small to get output faster
        750
      ) {
        break
      }
    }

    return additionalContext
  })
}

const StreamingCompletion: React.FC<{ leaf: Text }> = ({ leaf }) => {
  const isLatestNewSelectedTextNode = useAtomValue(latestNewSelectedTextNodeAtom) === leaf
  const editor = useSlateStatic()
  const dispatch = useDispatch()
  const getAdditionalContext = useGetAdditionalContext()

  const [debouncedContext, liveContext, setLiveContext] = useDebouncedAndLiveState<{
    context: string
  }>({ context: '' }, { wait: debounceTime })
  const [initialLeaf] = useState(leaf)

  const hasEditedText = initialLeaf !== leaf

  const isLongEnough = debouncedContext.context.length > 10
  const queryEnabled =
    isLongEnough &&
    debouncedContext.context === liveContext.context &&
    (hasEditedText || isLatestNewSelectedTextNode) &&
    debouncedContext.context === liveContext.context

  const [completionSuggestion, setCompletionSuggestion] = useState<string | undefined>(undefined)
  const additionalContext = getAdditionalContext()
  const completionQuery = useCachedQuery(
    XRealtimeAuthorGenerateContinueWriting,
    { context: debouncedContext.context, additionalContext },
    { enabled: queryEnabled, retry: false, retryOnMount: false }
  )
  const queryResult = completionQuery.data?.completion

  const suggestionIdRef = useRef<string>('')
  suggestionIdRef.current = getSuggestionId(debouncedContext.context, queryResult ?? '')

  useEffect(() => {
    const suggestion = queryResult === '' ? undefined : queryResult
    setCompletionSuggestion(suggestion)
    if (suggestion !== undefined)
      void dispatch(Logging.inlineCompletion.autoCompleteSuggested({ suggestionId: suggestionIdRef.current }))
  }, [dispatch, queryResult])

  useEffect(() => {
    const context = findLatestWords(editor, wordCount)
    setCompletionSuggestion(undefined)
    setLiveContext({ context })
  }, [editor, leaf, setLiveContext])

  const acceptCompletion = useCallback(
    (source: 'click' | 'tab') => {
      if (completionSuggestion !== undefined) {
        editor.insertText(completionSuggestion)
        setCompletionSuggestion(undefined)
        void dispatch(
          Logging.inlineCompletion.autoCompleteAccepted({ suggestionId: suggestionIdRef.current, source })
        )
      }
    },
    [completionSuggestion, dispatch, editor]
  )

  useEffect(() => {
    if (completionSuggestion === undefined) return

    setHandleAcceptCompletionKeyDownCallback(evt => {
      if (!ReactEditor.isFocused(editor)) return

      switch (evt.key) {
        case 'Escape': {
          evt.preventDefault()
          evt.stopPropagation()
          setCompletionSuggestion(undefined)
          void dispatch(
            Logging.inlineCompletion.autoCompleteRejected({ suggestionId: suggestionIdRef.current })
          )
          break
        }
        case 'Tab': {
          evt.preventDefault()
          evt.stopPropagation()
          acceptCompletion('tab')
          break
        }
      }
    })

    return () => {
      setHandleAcceptCompletionKeyDownCallback(undefined)
    }
  }, [acceptCompletion, completionSuggestion, completionQuery.data, editor, setLiveContext, dispatch])

  const isDebug = useIsDebugMode()

  return (
    <CompletionSpan onClick={() => acceptCompletion('click')} contentEditable={false}>
      {queryEnabled && completionSuggestion}
      {isDebug && (
        <>
          {queryEnabled && completionQuery.isFetching && '⏳'}
          {!isLongEnough && '📏' + debouncedContext.context.length}
          {!hasEditedText && '🖊️'}
          {!queryEnabled && '😴'}
          {isLatestNewSelectedTextNode && '🆕'}
        </>
      )}
    </CompletionSpan>
  )
}

const StreamingCompletionUniqueGuard: FC<{ leaf: Text }> = ({ leaf }): JSX.Element | null => {
  const isUnique = useIsUnique('StreamingCompletion')

  if (!isUnique) {
    return null
  }

  return <StreamingCompletion leaf={leaf} />
}

function selectionIsAtEndOfNode(editor: Editor, path: Path): boolean {
  const { selection } = editor
  if (selection === null) return false

  if (!Range.isCollapsed(selection)) return false

  const [, end] = rangeOfNode(editor, path)
  const isAtEnd = Point.equals(Range.end(selection), end)

  return isAtEnd
}

const SupportedInlineCompletionTypes: CustomElementType[] = ['paragraph', 'heading', 'list-item']

export const InlineCompletion: React.FC<{ leaf: Text }> = ({ leaf }) => {
  const readOnly = useEditorReadOnly()
  const isSelected = useSelected()
  const slashMenuState = useSlashMenuState()
  const isCollapsedSelection = useSlateSelector(isCurrentSelectionCollapsed)
  const element = useElement()
  const shouldShowPrompt = useShouldShowPrompt({ element })
  const lastLeaf = element.children.at(-1)
  const isLastLeaf = leaf === lastLeaf
  const setLatestNewSelectedTextNode = useSetAtom(latestNewSelectedTextNodeAtom)
  const isSupportedCompletionType = SupportedInlineCompletionTypes.includes(element.type)
  const isSlashMenuActive = slashMenuState.type === 'active'

  const selectShouldShowInlineCompletion = useCallback(
    (editor: Editor) => {
      if (readOnly) return false
      if (!isSupportedCompletionType) return false
      if (shouldShowPrompt) return false
      if (!isLastLeaf) return false
      if (!isSelected) return false
      if (isSlashMenuActive) return false

      // We want to be able to support this case, but currently there is an issue
      // where slate inserts a <br> tag into empty leaves, which breaks the layout
      if (leaf.text === '') return false

      // Only show completion if selection is at end of current node
      try {
        const leafPath = ReactEditor.findPath(editor, leaf)
        const isAtEndOfNode = selectionIsAtEndOfNode(editor, leafPath)

        return isAtEndOfNode
      } catch (e) {
        return false
      }
    },
    [isLastLeaf, isSelected, isSupportedCompletionType, leaf, readOnly, shouldShowPrompt, isSlashMenuActive]
  )

  const showInlineCompletion = useSlateSelector(selectShouldShowInlineCompletion)

  useOnMount(() => {
    if (leaf.text === '' && isSelected && isCollapsedSelection === true) {
      setLatestNewSelectedTextNode(leaf)
    }
  })

  if (!showInlineCompletion) return null

  return <StreamingCompletionUniqueGuard leaf={leaf} />
}
