import { FC } from 'react'
import { Icon, IconId } from 'sierra-ui/components'
import { token } from 'sierra-ui/theming'
import styled from 'styled-components'

const Container = styled.div`
  display: flex;
  background-color: ${token('surface/default').opacity(0.95)};
  border-radius: 10px;
  border: 1px solid ${token('border/default')};
  padding: 10px;
  gap: 8px;
  width: fit-content;
  max-width: 270px;
  align-items: center;
  overflow: hidden;
`

const Image = styled.img`
  border-radius: 4px;
  height: 20px;
  width: 24px;
`

const Title = styled.p`
  font-size: 14px;
  color: ${token('foreground/primary')};
  white-space: nowrap;
  text-overflow: ellipsis;
  overflow: hidden;
`

const cloudinaryPrefix = '//res.cloudinary.com/sana-ai/image/fetch/c_fill,h_40,w_48/'

export const IconGhostContent: FC<
  { imageUrl: string; title: string } | { iconId: IconId; title: string }
> = props => {
  return (
    <Container>
      {'imageUrl' in props && <Image src={cloudinaryPrefix + props.imageUrl} />}
      {'iconId' in props && <Icon iconId={props.iconId} />}
      <Title>{props.title}</Title>
    </Container>
  )
}
