import { Assignments } from 'sierra-client/views/course-helper/content/course-helper-recommendations/assignments'
import { Recommendations } from 'sierra-client/views/course-helper/content/course-helper-recommendations/recommendations'
import { useCourseHelperContent } from 'sierra-client/views/course-helper/content/course-helper-recommendations/use-course-helper-content'
import { CourseFallback } from 'sierra-client/views/course-helper/content/fallback/fallback'
import { isDefined } from 'sierra-domain/utils'

export const EndOfCourseRecommendations: React.FC<{
  headline?: JSX.Element
}> = ({ headline }) => {
  const { data } = useCourseHelperContent()
  const assigned = data?.assigned
  const recommended = data?.recommended

  if (isDefined(assigned) && assigned.length > 1) {
    return <Assignments assignments={assigned} replaceHeadline={headline} />
  } else if (isDefined(recommended) && recommended.length > 1) {
    return <Recommendations recommendations={recommended} replaceHeadline={headline} />
  } else {
    return <CourseFallback />
  }
}
