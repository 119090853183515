import { FC } from 'react'
import { useSelector } from 'sierra-client/state/hooks'
import { selectUser } from 'sierra-client/state/user/user-selector'
import { useUserLegacy } from 'sierra-client/state/users/hooks'
import { getAvatarImage } from 'sierra-client/utils/avatar-img'
import { UserId } from 'sierra-domain/api/uuid'
import { RoundAvatar, Tooltip } from 'sierra-ui/components'
import { View } from 'sierra-ui/primitives'
import { palette } from 'sierra-ui/theming'
import { fonts } from 'sierra-ui/theming/fonts'
import styled from 'styled-components'

export const SectionHeader = styled(View)`
  ${fonts.body.regular};
  font-weight: 500;
  padding-left: 0.5rem;
  padding-bottom: 0.25rem;
`

export const SectionGrid = styled.div`
  display: grid;
  grid-gap: 0.75rem;
  list-style: none;
  margin: 0;
  width: 320px;
  background-color: #f8f8f8;
  padding: 24px;
  border-radius: 12px;
`

export const KanbanContainer = styled.div`
  display: inline-flex;
  gap: 1.5rem;
  justify-content: flex-start;
  align-items: flex-start;
  padding-right: 8rem;
  width: fit-content;
`

export const BaseNoteContainer = styled.div<{ $color?: string }>`
  position: relative;
  width: 100%;
  background-color: ${p => p.$color ?? palette.primitives.white};
  border-radius: 8px;
`

export const NoteBody = styled.div`
  padding: 16px 20px 16px 20px;
  ${fonts.body.small};
`

export const NoteFooter = styled(View).attrs({ gap: 'none' })`
  padding: 0 20px 18px 20px;
`

const FooterText = styled.div<{ $isMe: boolean }>`
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
  ${fonts.body.micro};
  font-weight: 500;
  color: ${p => (p.$isMe === true ? 'black' : 'rgba(0, 0, 0, 0.25)')};
`

export const Avatar: FC<{ userId?: UserId }> = ({ userId }) => {
  const myUser = useSelector(selectUser)
  const otherUser = useUserLegacy(userId)
  const user = userId === undefined ? myUser : otherUser
  const isMe = user?.uuid === myUser?.uuid

  return (
    <Tooltip title={`${user?.firstName} ${user?.lastName}`}>
      <View gap='xxsmall'>
        <RoundAvatar
          size='tiny'
          firstName={user?.firstName}
          lastName={user?.lastName}
          src={getAvatarImage(user?.uuid, user?.avatar)}
          color={user?.avatarColor}
        />
        <FooterText $isMe={isMe}>{isMe ? '' : ''}</FooterText>
      </View>
    </Tooltip>
  )
}
