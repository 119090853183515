import { RouterLink } from 'sierra-client/components/common/link'
import { NotificationContainer } from 'sierra-client/features/activity-center/notifications/components'
import { NotificationIcon } from 'sierra-client/features/activity-center/notifications/components/notification-icon'
import { NotificationTitle } from 'sierra-client/features/activity-center/notifications/components/notification-title'
import { HomeNotification } from 'sierra-domain/api/learn'

export const HomeworksReadyNotification: React.FC<{
  notification: Extract<HomeNotification, { type: 'homeworks-available-for-review' }>
}> = ({ notification }) => {
  return (
    <NotificationContainer
      notification={notification}
      icon={<NotificationIcon iconId='clipboard' />}
      title={
        <RouterLink href='/manage/exercises'>
          <NotificationTitle titleKey='notification.new-exercise-available-for-review' />
        </RouterLink>
      }
      content={undefined}
    />
  )
}
