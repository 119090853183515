type DevTools = {
  setTraceUpdatesEnabled(val: boolean): void
  _traceUpdatesEnabled: boolean
}

type DevToolsHook = {
  reactDevtoolsAgent: DevTools | undefined
}

type Window = {
  __REACT_DEVTOOLS_GLOBAL_HOOK__: DevToolsHook | undefined
}

function getDevToolsHook(): DevToolsHook {
  const devToolsHook = (window as unknown as Window).__REACT_DEVTOOLS_GLOBAL_HOOK__
  if (!devToolsHook) throw new Error('Unable to find __REACT_DEVTOOLS_GLOBAL_HOOK__ on window')
  return devToolsHook
}

export function getDevTools(): DevTools | undefined {
  const devToolsHook = getDevToolsHook()

  const reactDevtoolsAgent = devToolsHook.reactDevtoolsAgent
  if (!reactDevtoolsAgent) {
    alert(
      'reactDevToolsAgent was not found on window.__REACT_DEVTOOLS_GLOBAL_HOOK__' +
        '\nPlease open the developer tools and wait for the react dev tools extension to initialize.'
    )
    return undefined
  }

  return reactDevtoolsAgent
}
