import { DateTime } from 'luxon'
import React, { useMemo } from 'react'
import { useEditableContentById } from 'sierra-client/api/hooks/use-editable-content'
import { RouterLink } from 'sierra-client/components/common/link'
import { Thumbnail } from 'sierra-client/components/common/thumbnail'
import { config } from 'sierra-client/config/global-config'
import type { PanelState } from 'sierra-client/features/sana-now/create-session-panel/panel-state'
import { mapUserToIdentity } from 'sierra-client/features/sana-now/create-session-panel/session-form-state'
import { useDebouncedAndLiveState } from 'sierra-client/hooks/use-debounced-state'
import { useContentKindPermissions } from 'sierra-client/hooks/use-permissions'
import { useResetBooleanAfterDelay } from 'sierra-client/hooks/use-reset-boolean-after-delay'
import { useResolveAsset } from 'sierra-client/hooks/use-resolve-asset'
import { useTranslation } from 'sierra-client/hooks/use-translation'
import { Trans } from 'sierra-client/hooks/use-translation/trans'
import { useCachedQuery } from 'sierra-client/state/api'
import { isUserLoaded, useUsers } from 'sierra-client/state/users/hooks'
import { RoundedSearchBar } from 'sierra-client/views/manage/components/rounded-search-bar'
import {
  oneHourFromNowFloored,
  twoHoursFromNowFloored,
} from 'sierra-client/views/manage/event-groups/components/shared'
import {
  constructScheduleFromLiveData,
  formatEventSchedule,
} from 'sierra-client/views/manage/event-groups/event-utils'
import { CalendarEventSchedule } from 'sierra-client/views/manage/event-groups/types'
import { ExpandableContentList } from 'sierra-client/views/workspace/create-new/create-page-buckets'
import { LiveSessionWithParticipants } from 'sierra-domain/api/admin'
import { IdentityWithMetadata } from 'sierra-domain/api/manage'
import { LiveContentId } from 'sierra-domain/api/nano-id'
import { LiveSession, LiveSessionBase } from 'sierra-domain/content/session'
import { ImageUnion } from 'sierra-domain/content/v2/image-union'
import { XRealtimeAuthorLiveSessionsListLiveSessions } from 'sierra-domain/routes'
import { isDefined } from 'sierra-domain/utils'
import { Icon, Tooltip, TruncatedTextWithTooltip } from 'sierra-ui/components'
import { Button, Heading, IconButton, Text, View } from 'sierra-ui/primitives'
import { spacing } from 'sierra-ui/theming'
import { FCC } from 'sierra-ui/types'
import styled from 'styled-components'

const SessionsListItemContainer = styled(View).attrs({ direction: 'column' })`
  padding: ${spacing['10']} 0;
`

const FormatSessionTimestamp = ({ session }: { session: LiveSessionBase }): JSX.Element | null => {
  const { t } = useTranslation()

  if (session.type === 'not-scheduled') {
    if (session.endedAt === undefined) {
      return (
        <Text size='small' color='grey40'>
          {t('dictionary.live-session.not-scheduled')}
        </Text>
      )
    }

    const endedAt = DateTime.fromISO(session.endedAt)
    const dateAndMonth = endedAt.toLocaleString({
      month: 'short',
      day: 'numeric',
    })

    const endTime = endedAt.toLocaleString(DateTime.TIME_SIMPLE)

    return (
      <Text size='small' color='black'>
        <Text bold size='small' as='span'>
          {dateAndMonth},
        </Text>{' '}
        {endTime}
      </Text>
    )
  }

  const start = DateTime.fromISO(session.startTime)
  const end = DateTime.fromISO(session.endTime)
  const schedule = constructScheduleFromLiveData(session)
  const format = formatEventSchedule(schedule)
  const formatComma = format.split(',')

  if (session.allDay && start.toJSDate().getDate() === end.toJSDate().getDate()) {
    return (
      <Text size='small' color='grey40'>
        {t('admin.author.sessions.all-day')}
      </Text>
    )
  }

  return (
    <>
      <Text size='small' color='black'>
        <Text bold size='small' as='span'>
          {formatComma[0]},
        </Text>
        {formatComma[1]}
      </Text>
    </>
  )
}

const GreyedOutContainer = styled(View)`
  opacity: 0.5;
  transition: opacity 0.2s ease-in-out;
`

const HiddenView = styled(View)`
  opacity: 0;
  transition: all 0.1s cubic-bezier(0.25, 0.1, 0.25, 1);

  ${SessionsListItemContainer}:hover & {
    opacity: 1;
  }
`

const ActionWrapper = styled(View)`
  position: sticky;
  bottom: 0;
`

const PanelContainer = styled.div`
  position: relative;
  min-height: 100%;
  display: flex;
  flex-direction: column;
`
const StyledTruncatedTextWithTooltip = styled(TruncatedTextWithTooltip)`
  flex-shrink: 0;
  width: fit-content;
`

const HoverView = styled(View)`
  opacity: 0.5;
  max-width: 176px;

  ${SessionsListItemContainer}:hover & {
    opacity: 1;
  }
`

const BlinkingDot = styled.div`
  width: 8px;
  height: 8px;
  background-color: red;
  border-radius: 50%;
  position: relative;

  &::before {
    content: '';
    width: 20px;
    height: 20px;
    background-color: rgba(255, 0, 0, 0.2);
    border-radius: 50%;
    position: absolute;
    top: -6px;
    left: -6px;
    z-index: -1;
    animation: pulse 2s infinite;
  }

  @keyframes pulse {
    0% {
      transform: scale(0.8);
      opacity: 0.7;
    }
    50% {
      transform: scale(1);
      opacity: 1;
    }
    100% {
      transform: scale(0.8);
      opacity: 0.7;
    }
  }
`

const constructSchedule = (session: LiveSessionBase): CalendarEventSchedule => {
  if (session.type === 'not-scheduled') {
    return {
      type: 'with-time',
      timeZone: DateTime.local().zoneName,
      startTime: oneHourFromNowFloored(),
      endTime: twoHoursFromNowFloored(),
    }
  }

  return constructScheduleFromLiveData(session)
}

const MulipleFacilitatorsWrapper: FCC<{ facilitators: IdentityWithMetadata[] }> = ({
  facilitators,
  children,
}) => {
  const { lang } = useTranslation()

  const parts = useMemo(() => {
    return new Intl.ListFormat(lang, { style: 'long', type: 'conjunction' })
      .formatToParts(facilitators.map(it => it.name))
      .map(part => part.value)
      .join('')
  }, [facilitators, lang])

  return (
    <Tooltip title={parts}>
      <Text bold color='currentColor' as='span'>
        {children}
      </Text>
    </Tooltip>
  )
}

const getSessionLink = (session: LiveSession): string => {
  const link =
    session.data.endedAt === undefined && session.data.roomId !== undefined
      ? `${window.location.origin}/room/${session.data.roomId}`
      : `${window.location.origin}/l/${session.liveSessionId}/`

  const url = new URL(link)
  const host = config.auth.host

  if (host !== undefined) url.searchParams.set('x-host', host)

  return url.href
}

const SessionListItem = ({
  session,
  setState,
  courseImage,
  openSessionLinkText,
}: {
  session: LiveSessionWithParticipants
  setState: (state: PanelState) => void
  courseImage?: ImageUnion
  openSessionLinkText: string
}): JSX.Element => {
  const { t } = useTranslation()
  const { isEnabled: recentlyClicked, setTrue: setRecentlyClicked } = useResetBooleanAfterDelay()

  const handleSessionCopyLink = (session: LiveSession): void => {
    void window.navigator.clipboard.writeText(getSessionLink(session))
    setRecentlyClicked()
  }

  const assetContext = useMemo(
    () => ({ type: 'course' as const, courseId: session.data.flexibleContentId }),
    [session.data.flexibleContentId]
  )

  const imgSrc = useResolveAsset({
    image: courseImage,
    size: 'admin-sm',
    assetContext,
  })

  const usersAsIndendities = useUsers(session.participantIds).filter(isUserLoaded).map(mapUserToIdentity)
  const facilitatorsAsIdentities = useUsers(session.data.facilitatorIds)
    .filter(isUserLoaded)
    .map(mapUserToIdentity)

  return (
    <SessionsListItemContainer key={session.liveSessionId}>
      <View justifyContent='space-between' gap='10 16'>
        <Thumbnail radius={0.625} height={3} width={5} image={imgSrc} />
        <View gap='2' direction='column' grow>
          <FormatSessionTimestamp session={session.data} />

          <View direction='row' gap='none' alignItems='center'>
            <HoverView gap='none'>
              <StyledTruncatedTextWithTooltip bold color='foreground/secondary'>
                {session.data.title}
              </StyledTruncatedTextWithTooltip>
              <Icon iconId='radio-button--dot' color='foreground/secondary' size='size-10'></Icon>
              {isDefined(session.data.location) && (
                <StyledTruncatedTextWithTooltip size='small' color='foreground/secondary' lineLength={32}>
                  {session.data.location.value}
                </StyledTruncatedTextWithTooltip>
              )}
              <Icon iconId='radio-button--dot' color='foreground/secondary' size='size-10'></Icon>
              <StyledTruncatedTextWithTooltip color='foreground/secondary'>
                <Trans
                  i18nKey={
                    facilitatorsAsIdentities.length === 1
                      ? 'author.schedule-sana-now-session.facilitated-by-name'
                      : 'author.schedule-sana-now-session.facilitated-by-count'
                  }
                  values={{ count: facilitatorsAsIdentities.length, name: facilitatorsAsIdentities[0]?.name }}
                  components={{
                    bold: <MulipleFacilitatorsWrapper facilitators={facilitatorsAsIdentities} />,
                  }}
                />
              </StyledTruncatedTextWithTooltip>
            </HoverView>
          </View>
        </View>
        <HiddenView gap='8'>
          <IconButton
            iconId={recentlyClicked ? 'checkmark' : 'clipboard'}
            variant='secondary'
            tooltip={t('share.copy-link')}
            onClick={() => handleSessionCopyLink(session)}
          />
          <IconButton
            iconId='edit'
            variant='secondary'
            tooltip={t('dictionary.edit-details')}
            onClick={() => {
              setState({
                type: 'update-session',
                liveSessionId: session.liveSessionId,
                initialData: {
                  schedule: constructSchedule(session.data),
                  allowGuestAccess: session.data.allowGuestAccess,
                  disallowEarlyJoin: session.data.disallowEarlyJoin,
                  enableRecap: session.data.enableRecap,
                  facilitators: facilitatorsAsIdentities,
                  participants: usersAsIndendities,
                  enableSelfEnrollment: !session.data.hideFromSelfEnrollment,
                  location: session.data.location?.value,
                  maxNumberOfUsers: session.data.maxNumberOfUsers,
                  title: session.data.title,
                  transcribeSession: session.data.transcribeSession,
                  hideTranscription: session.data.hideTranscription,
                  learnerLedSession: session.data.learnerLedSession,
                  videoCallSetting: session.data.videoCallSetting,
                },
              })
            }}
          />
          <RouterLink href={getSessionLink(session)} target='_blank'>
            <Button variant='secondary'>{openSessionLinkText}</Button>
          </RouterLink>
        </HiddenView>
      </View>
    </SessionsListItemContainer>
  )
}

type ListSessionsProps = {
  liveContentId: LiveContentId
  contentImage?: ImageUnion
  setState: (state: PanelState) => void
}

const sessionIsOngoing = (session: LiveSessionBase): boolean =>
  session.startedAt !== undefined && session.endedAt === undefined

export const ListSessions: React.FC<ListSessionsProps> = ({ liveContentId, setState }) => {
  const { t } = useTranslation()
  const [, searchTerm, setSearchTerm] = useDebouncedAndLiveState<string>('', { wait: 250 })

  const liveSessionsQuery = useCachedQuery(XRealtimeAuthorLiveSessionsListLiveSessions, {
    flexibleContentId: liveContentId,
  })

  const permissions = useContentKindPermissions(liveContentId)

  const sessions = useMemo(() => {
    const allSessions = liveSessionsQuery.data?.liveSessions ?? []

    const ongoingSessions = allSessions.filter(session => sessionIsOngoing(session.data))

    const pastSessions = allSessions
      .filter(
        session =>
          (session.data.type === 'not-scheduled' && session.data.endedAt !== undefined) ||
          (session.data.type === 'scheduled' &&
            (DateTime.fromISO(session.data.endTime) < DateTime.now() || session.data.endedAt !== undefined))
      )
      .sort((a, b) => {
        const aEndTime = a.data.type === 'scheduled' ? a.data.endTime : a.data.endedAt
        const bEndTime = b.data.type === 'scheduled' ? b.data.endTime : b.data.endedAt

        if (aEndTime !== undefined && bEndTime !== undefined) {
          return DateTime.fromISO(bEndTime).toMillis() - DateTime.fromISO(aEndTime).toMillis()
        }

        return 0
      })

    const upcomingSessions = allSessions
      .filter(
        session =>
          session.data.type === 'scheduled' &&
          session.data.startedAt === undefined &&
          DateTime.fromISO(session.data.endTime) > DateTime.now()
      )
      .sort((a, b) => {
        if (a.data.type === 'scheduled' && b.data.type === 'scheduled') {
          return DateTime.fromISO(a.data.startTime).toMillis() - DateTime.fromISO(b.data.startTime).toMillis()
        }
        return 0
      })

    return { upcomingSessions, ongoingSessions, pastSessions, allSessions }
  }, [liveSessionsQuery.data?.liveSessions])

  const manageUrl = new URL(window.location.origin)
  manageUrl.pathname = `/manage/courses/${liveContentId}`
  const host = config.auth.host
  if (host !== undefined) manageUrl.searchParams.set('x-host', host)
  const content = useEditableContentById(liveContentId)

  return (
    <PanelContainer>
      <View padding='small' paddingRight='10' paddingBottom={'none'} direction='column' gap='24' grow>
        <View>
          <Heading size={'h5'} bold>
            {t('admin.author.sessions.all')}
          </Heading>
        </View>
        <View>
          <RoundedSearchBar
            placeholder={t('admin.author.find-sessions')}
            value={searchTerm}
            onChange={text => {
              setSearchTerm(text)
            }}
            maxWidth='100%'
          />
        </View>

        {/* ONGOING SESSIONS */}

        {sessions.ongoingSessions.length !== 0 && (
          <View direction='column' gap='none'>
            <View direction='row' gap='10'>
              <Text bold color='foreground/primary'>
                {t('admin.author.sessions.ongoing')}
              </Text>
              <BlinkingDot></BlinkingDot>
            </View>
            <ExpandableContentList items={sessions.ongoingSessions}>
              {({ items }) => (
                <>
                  {items.map(session => (
                    <SessionListItem
                      key={session.liveSessionId}
                      session={session}
                      setState={setState}
                      courseImage={content.data?.image}
                      openSessionLinkText={t('author.schedule-sana-now-session.start-now--ongoing')}
                    />
                  ))}
                </>
              )}
            </ExpandableContentList>
          </View>
        )}
        {/* UPCOMING SESSIONS */}
        {sessions.upcomingSessions.length !== 0 && (
          <View direction='column' gap='none'>
            <Text bold color='foreground/primary'>
              {t('admin.author.sessions.upcoming')}
            </Text>
            <ExpandableContentList items={sessions.upcomingSessions}>
              {({ items }) => (
                <>
                  {items.map(session => (
                    <SessionListItem
                      key={session.liveSessionId}
                      session={session}
                      setState={setState}
                      courseImage={content.data?.image}
                      openSessionLinkText={t('author.schedule-sana-now-session.start-now--upcomming')}
                    />
                  ))}
                </>
              )}
            </ExpandableContentList>
          </View>
        )}

        {/* PAST SESSIONS */}
        {sessions.pastSessions.length !== 0 && (
          <View direction='column' gap='none'>
            <Text bold color='foreground/primary'>
              {t('admin.author.sessions.past')}
            </Text>
            <GreyedOutContainer direction='column' gap='none'>
              <ExpandableContentList items={sessions.pastSessions}>
                {({ items }) => (
                  <>
                    {items.map(session => (
                      <SessionListItem
                        key={session.liveSessionId}
                        session={session}
                        setState={setState}
                        courseImage={content.data?.image}
                        openSessionLinkText={t('author.schedule-sana-now-session.start-now--past')}
                      />
                    ))}
                  </>
                )}
              </ExpandableContentList>
            </GreyedOutContainer>
          </View>
        )}
      </View>

      <ActionWrapper background='surface/default' padding='small'>
        <Button
          disabled={!permissions.has('SCHEDULE')}
          variant='primary'
          onClick={() => setState({ type: 'create-session' })}
        >
          {t('admin.author.sessions.create-schedule')}
        </Button>

        {permissions.has('MANAGE') && (
          <Button variant='secondary' onClick={() => window.open(manageUrl)}>
            {t('admin.author.manage')}
          </Button>
        )}
      </ActionWrapper>
    </PanelContainer>
  )
}
