import { useMutation } from '@tanstack/react-query'
import { DateTime } from 'luxon'
import { ChangeEvent, useState } from 'react'
import { graphql } from 'sierra-client/api/graphql/gql'
import { ApproverSetting, CreateCalendarEventInput } from 'sierra-client/api/graphql/gql/graphql'
import { graphQuery } from 'sierra-client/api/hooks/use-graphql-query'
import { Logging } from 'sierra-client/core/logging'
import { useHasContentKindPermission } from 'sierra-client/hooks/use-permissions'
import { useTranslation } from 'sierra-client/hooks/use-translation'
import { useDispatch } from 'sierra-client/state/hooks'
import { useMeAsIdentity } from 'sierra-client/views/manage/certificates/issue-certificate-panel/shared/by-proxy-selector'
import { CalendarEventBottomOptions } from 'sierra-client/views/manage/event-groups/components/calendar-event-bottom-options'
import { EventList } from 'sierra-client/views/manage/event-groups/components/events-list'
import {
  TextButton,
  oneHourFromNowFloored,
  twoHoursFromNowFloored,
} from 'sierra-client/views/manage/event-groups/components/shared'
import {
  eventScheduleToGqlInput,
  eventScheduleValid,
  gqlEventScheduleToEventSchedule,
} from 'sierra-client/views/manage/event-groups/event-utils'
import { CreateCalendarEventData } from 'sierra-client/views/manage/event-groups/types'
import { withPanel } from 'sierra-client/views/manage/utils/with-modal'
import { IdentityWithMetadata } from 'sierra-domain/api/manage'
import { CalendarEventId, EventGroupId } from 'sierra-domain/api/nano-id'
import { nanoid12 } from 'sierra-domain/nanoid-extensions'
import { isDefined } from 'sierra-domain/utils'
import { FormElement } from 'sierra-ui/components'
import { FreeTextEditor } from 'sierra-ui/missions/workflows/free-text-editor'
import { Button, Heading, IconButton, InputPrimitive, Spacer, View } from 'sierra-ui/primitives'
import { spacing, token } from 'sierra-ui/theming'
import styled from 'styled-components'

const OuterContainer = styled(View)`
  position: relative;
  min-height: 100%;
`

const TopContainer = styled(View)`
  width: 100%;
  height: 100%;
  overflow: auto;
  scrollbar-gutter: stable;
`

const Divider = styled.hr`
  width: 100%;
  height: 1px;
  margin: unset;
  background-color: ${token('border/default')};
`

const BottomContainer = styled.div`
  position: sticky;
  bottom: 0;
  right: 0;
  left: 0;
  background: ${token('surface/default')};
  padding: ${spacing['medium']};
  padding-top: 0;
`

const DeleteEventIconContainer = styled(View)`
  position: absolute;
  right: -8px;
  top: -8px;
  gap: 0;
`
const CloseModalIconContainer = styled.div`
  position: absolute;
  right: 8px;
  top: 16px;
`

const StyledIconButton = styled(IconButton).attrs({ variant: 'transparent', size: 'small' })`
  color: ${token('foreground/muted')};

  &:hover {
    background: unset;
    color: ${token('foreground/primary')};
  }
`

type CreateCalendarEventPanelProps = {
  afterSubmit?: () => void
  eventGroupId: EventGroupId
  eventGroupTitle: string
  eventGroupDescription?: string
}

const createCalendarEventsMutation = graphql(`
  mutation createCalendarEvents($input: [CreateCalendarEventInput!]!) {
    createCalendarEvents(input: $input) {
      id
      title
      approverSetting
      selfReportAttendance
      participantLimit

      schedule {
        ...CalendarEventScheduleFragment
      }

      assignedUsers {
        __typename
      }
    }
  }
`)

export const CreateCalendarEventPanel = withPanel<CreateCalendarEventPanelProps>(
  {
    size: { width: 656 },
    disableScrollbarGutter: true,
    overlayVariant: 'light',
  },
  ({ onClose, afterSubmit, eventGroupId, eventGroupTitle }) => {
    const { t } = useTranslation()
    const dispatch = useDispatch()
    const [title, setTitle] = useState(eventGroupTitle)
    const [showMoreOptions, setShowMoreOptions] = useState(false)
    const [participantLimit, setParticipantLimit] = useState<number | undefined>(undefined)
    const [selfReportAttendance, setSelfReportAttendance] = useState<boolean>(true)
    const [approverSetting, setApproverSetting] = useState<ApproverSetting | undefined>()
    const [specificReviewerIdentities, setSpecificReviewerIdentities] = useState<IdentityWithMetadata[]>([])
    const [description, setDescription] = useState('')
    const [showDescription, setShowDescription] = useState(false)
    const [showDescriptionClose, setShowDescriptionClose] = useState(false)
    const userIdentity = useMeAsIdentity()
    const canEditAssignments = useHasContentKindPermission(eventGroupId, 'EDIT_ASSIGNMENTS')

    const [events, setEvents] = useState<CreateCalendarEventData[]>(() => [
      {
        // NOTE! Ignored by the backend.
        id: nanoid12() as CalendarEventId,
        location: undefined,
        schedule: {
          type: 'with-time',
          timeZone: DateTime.local().zoneName,
          startTime: oneHourFromNowFloored(),
          endTime: twoHoursFromNowFloored(),
        },
        participantIdentities: [],
        facilitatorIdentities: isDefined(userIdentity) ? [userIdentity] : [],
        specificReviewerIdentities: [],
      },
    ])

    const hasEventWithInvalidTime = events.some(event => !eventScheduleValid(event.schedule))

    const calendarEventsMutation = useMutation({
      mutationFn: async (input: CreateCalendarEventInput[]) => {
        return graphQuery(createCalendarEventsMutation, { input })
      },
      onSuccess: data => {
        data.createCalendarEvents.map(event => {
          const schedule = gqlEventScheduleToEventSchedule(event.schedule)

          const differingTimeZones =
            schedule.type === 'with-time' && schedule.timeZone !== DateTime.local().zoneName

          void dispatch(
            Logging.eventGroup.calendarEventCreated({
              calendarEventId: event.id,
              participantCount: event.assignedUsers.length,
              approverSetting: event.approverSetting ?? undefined,
              participantLimit: event.participantLimit ?? undefined,
              selfReportAttendance: event.selfReportAttendance,
              allDayEvent: schedule.type === 'all-day',
              eventInDifferentTimeZoneFromCreator: differingTimeZones,
            })
          )
        })
      },
    })

    const handleSubmit = async (): Promise<void> => {
      await calendarEventsMutation.mutateAsync(
        events.map(event => ({
          title: title,
          schedule: eventScheduleToGqlInput(event.schedule),
          description: description,
          location: JSON.stringify(event.location),
          eventGroupId,
          participantLimit,
          selfReportAttendance,
          approverSetting,
          participantUserIds: event.participantIdentities.flatMap(id =>
            id.identity.type === 'user' ? [id.identity.id] : []
          ),
          participantUserGroupIds: event.participantIdentities.flatMap(id =>
            id.identity.type === 'userGroup' ? [id.identity.id] : []
          ),
          facilitatorUserIds: event.facilitatorIdentities.flatMap(id =>
            id.identity.type === 'user' ? [id.identity.id] : []
          ),
          specificReviewerUserIds: specificReviewerIdentities.flatMap(id =>
            id.identity.type === 'user' ? [id.identity.id] : []
          ),
        }))
      )

      afterSubmit?.()
      onClose()
    }
    return (
      <OuterContainer direction='column'>
        <CloseModalIconContainer>
          <StyledIconButton iconId='close' onClick={onClose} />
        </CloseModalIconContainer>
        <TopContainer
          gap='none'
          padding='none medium'
          paddingTop='small'
          justifyContent='flex-start'
          direction='column'
        >
          <Heading bold size='h5'>
            {t('admin.author.sessions.new')}
          </Heading>

          <Spacer size='24' />

          <FormElement grow={false} label={t('dictionary.title')}>
            <InputPrimitive
              placeholder={t('manage.events.session-name')}
              id='session-title'
              value={title}
              onChange={(e: ChangeEvent<HTMLInputElement>) => setTitle(e.target.value)}
            />
          </FormElement>

          {showDescription ? (
            <>
              <Spacer size='24' />
              <View
                alignItems='flex-start'
                position='relative'
                onMouseEnter={() => setShowDescriptionClose(true)}
                onMouseLeave={() => setShowDescriptionClose(false)}
              >
                <FormElement label={t('dictionary.description')}>
                  <FreeTextEditor
                    inputId='session-description'
                    content={description}
                    editable
                    useHtml
                    textOptionsEnabled={false}
                    listOptionsEnabled={false}
                    alignmentOptionsEnabled={false}
                    strikethroughEnabled={false}
                    underlineEnabled={false}
                    onChange={e => {
                      setDescription(e)
                    }}
                    menuTranslations={{
                      list: t('dictionary.list'),
                      alignment: t('create.toolbar.text-alignment'),
                      text: t('dictionary.text'),
                      heading: t('font.heading'),
                    }}
                  />
                </FormElement>
                {showDescriptionClose && (
                  <DeleteEventIconContainer>
                    <StyledIconButton
                      iconId='trash-can'
                      onClick={() => {
                        setShowDescription(false)
                        setDescription('')
                      }}
                    />
                  </DeleteEventIconContainer>
                )}
              </View>
            </>
          ) : (
            <>
              <Spacer size='4' />
              <TextButton onClick={() => setShowDescription(true)}>
                {t('event-groups.add-a-description')}
              </TextButton>
            </>
          )}

          <Spacer size='24' />

          <EventList events={events} setEvents={setEvents} canEditAssignments={canEditAssignments} />

          <Spacer size='24' />
        </TopContainer>

        <BottomContainer>
          {showMoreOptions && (
            <>
              <Divider />

              <Spacer size={'40'} />
              <CalendarEventBottomOptions
                specificReviewerIdentities={specificReviewerIdentities}
                setSpecificReviewerIdentities={setSpecificReviewerIdentities}
                participantLimit={participantLimit}
                setParticipantLimit={setParticipantLimit}
                selfReportAttendance={selfReportAttendance}
                setSelfReportAttendance={setSelfReportAttendance}
                approverSetting={approverSetting}
                setApproverSetting={setApproverSetting}
              />
              <Spacer size={'48'} />
            </>
          )}
          <View>
            <Button
              variant='secondary'
              onClick={() => {
                setShowMoreOptions(prev => !prev)
              }}
            >
              {t('dictionary.more-options')}
            </Button>

            <View marginLeft='auto'>
              <Button variant='secondary' onClick={onClose}>
                {t('dictionary.cancel')}
              </Button>
              <Button
                onClick={handleSubmit}
                loading={calendarEventsMutation.isPending}
                disabled={calendarEventsMutation.isPending || hasEventWithInvalidTime}
              >
                {t('dictionary.save')}
              </Button>
            </View>
          </View>
        </BottomContainer>
      </OuterContainer>
    )
  }
)
