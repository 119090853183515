import { palette } from 'sierra-ui/theming'
import { fonts } from 'sierra-ui/theming/fonts'
/* eslint-disable react/forbid-component-props */
/* eslint-disable react/forbid-dom-props */
import {
  closestCorners,
  DndContext,
  DraggableAttributes,
  DragOverlay,
  MouseSensor,
  TouchSensor,
  UniqueIdentifier,
  useSensor,
  useSensors,
} from '@dnd-kit/core'
import { SyntheticListenerMap } from '@dnd-kit/core/dist/hooks/utilities'
import {
  AnimateLayoutChanges,
  defaultAnimateLayoutChanges,
  rectSortingStrategy,
  SortableContext,
  useSortable,
} from '@dnd-kit/sortable'
import { CSS } from '@dnd-kit/utilities'
import _ from 'lodash'
import React, { CSSProperties, FC, forwardRef, useEffect, useRef, useState } from 'react'
import { createPortal } from 'react-dom'
import { Trans } from 'sierra-client/hooks/use-translation/trans'
import { useSelector } from 'sierra-client/state/hooks'
import { selectUser } from 'sierra-client/state/user/user-selector'
import { FCC } from 'sierra-client/types'
import { StickyNoteEditor } from 'sierra-client/views/sticky-notes-card/editor'
import { getSectionNoteIds, useNote } from 'sierra-client/views/sticky-notes-card/helpers'
import {
  Avatar,
  BaseNoteContainer,
  FooterText,
  NoteBody,
  NoteFooter,
  SectionGrid,
  SectionHeader,
  SectionText,
} from 'sierra-client/views/sticky-notes-card/shared-styles'
import {
  stickyNoteColors,
  StickyNotesColorPicker,
} from 'sierra-client/views/sticky-notes-card/sticky-notes-color-picker'
import { Data } from 'sierra-client/views/sticky-notes-card/types'
import {
  arrayMoveSafeguard,
  NoteId,
  SectionId,
  StickyNotesCardYjsApi,
} from 'sierra-domain/card/sticky-notes-card'
import { asNonNullable, assert } from 'sierra-domain/utils'
import { color, dynamicColor } from 'sierra-ui/color'
import { Icon } from 'sierra-ui/components'
import { IconButton, Spacer, View } from 'sierra-ui/primitives'
import styled, { css } from 'styled-components'
import { Awareness } from 'y-protocols/awareness'

type NoteProps = {
  $isActive?: boolean
  $isOverlay?: boolean
  $isDragging?: boolean
  $color: string
}
const Note = styled(BaseNoteContainer)<NoteProps>`
  cursor: ${p => (p.$isOverlay === true ? 'grabbing' : 'grab')};
  box-shadow: 0px 0px 0px 0px rgba(0, 0, 0, 0);
  ${p =>
    !(p.$isActive === true) &&
    !(p.$isOverlay === true) &&
    css`
      box-shadow: 0px 4px 12px 0px rgba(0, 0, 0, 0.03);
    `};

  ${p =>
    p.$isActive === true &&
    css`
      outline: 3px solid rgba(0, 0, 0, 0.05);
      outline-offset: -3px;
      border-radius: 3px;
    `};

  ${p =>
    p.$isDragging === true &&
    css`
      opacity: 0;
    `};

  ${p =>
    p.$isOverlay === true &&
    css`
      box-shadow: 0px 16px 28px 0px rgba(0, 0, 0, 0.08);
    `};

  &:hover {
    background-color: ${p => dynamicColor(p.$color).shift(0.005)};
  }
`

const NewNoteContainer = styled(BaseNoteContainer)`
  user-select: none;
  border-radius: 3px;
  cursor: pointer;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  ${fonts.body.small};
  color: rgba(0, 0, 0, 0.25);

  &:hover {
    background-color: ${palette.grey[5]};
    color: rgba(0, 0, 0, 0.4);
  }
`

const AddIcon = styled(Icon).attrs({ iconId: 'add--alt' })`
  color: inherit;
`

const NewNote: FC<{ onClick: () => void }> = ({ onClick }) => (
  <NewNoteContainer onClick={onClick} tabIndex={-1}>
    <AddIcon />
    <Spacer size='xxsmall' />
    <p>
      <Trans i18nKey='sticky-notes.add-note' />
    </p>
  </NewNoteContainer>
)

function getSectionIdMapFromApi(api: StickyNotesCardYjsApi): Record<NoteId, SectionId> {
  return Object.fromEntries(api.getPositionArray().map(pos => [pos.noteId, pos.sectionId]))
}

const useNoteIdToSectionIdMap = (api: StickyNotesCardYjsApi): Record<NoteId, SectionId> | undefined => {
  const [map, setMap] = useState<Record<NoteId, SectionId>>(() => {
    return getSectionIdMapFromApi(api)
  })

  useEffect(() => {
    const handler = (): void => setMap(getSectionIdMapFromApi(api))

    handler()

    api.yPositionArray.observe(handler)

    return () => api.yPositionArray.unobserve(handler)
  }, [api])

  return map
}

const Item = forwardRef<
  HTMLDivElement,
  {
    noteId: NoteId
    awareness: Awareness
    api: StickyNotesCardYjsApi
    isOverlay?: boolean
    isDragging?: boolean
    style?: CSSProperties
    attributes?: DraggableAttributes
    listeners?: SyntheticListenerMap
  }
>(({ noteId, awareness, api, isOverlay, isDragging, attributes, listeners, style }, ref) => {
  const [metadata, setMetadata] = useNote(api, noteId)
  const currentUser = useSelector(selectUser)
  const [isActive, setIsActive] = useState(false)
  const [previewColor, setPreviewColor] = useState<string | undefined>()
  const isClickingColorPickerRef = useRef(false)

  useEffect(() => {
    const handler = (e: MouseEvent): void => {
      isClickingColorPickerRef.current =
        Boolean((e.target as HTMLElement | null)?.classList.contains('sticky-note-color-picker')) ||
        Boolean((e.target as HTMLElement | null)?.closest('.sticky-note-color-picker'))
    }

    document.addEventListener('mousedown', handler)

    return () => document.removeEventListener('mousedown', handler)
  }, [])

  if (!metadata || !currentUser) return null

  const hasReacted = currentUser.uuid in metadata.reactions
  const reactionCount = Object.keys(metadata.reactions).length

  return (
    <Note
      ref={ref}
      style={style}
      $isActive={isActive}
      $color={previewColor ?? (metadata.color || asNonNullable(stickyNoteColors[0]))}
      $isOverlay={isOverlay}
      $isDragging={isDragging}
      {...attributes}
      {...listeners}
      tabIndex={-1}
    >
      <NoteBody className='NoteBody' id={`note-${noteId}`}>
        <StickyNoteEditor
          noteId={noteId}
          awareness={awareness}
          api={api}
          user={currentUser}
          onFocus={() => setIsActive(true)}
          onBlur={() => {
            if (!isClickingColorPickerRef.current) {
              setIsActive(false)
            }
          }}
        />
      </NoteBody>
      <NoteFooter>
        <Avatar userId={metadata.userId} />
        <View grow />
        {isActive ? (
          <>
            <StickyNotesColorPicker
              className='sticky-note-color-picker'
              selectedColor={metadata.color}
              onMouseOver={color => setPreviewColor(color)}
              onMouseOut={color => setPreviewColor(previous => (previous === color ? undefined : previous))}
              onClick={color => {
                api.setNoteMetadata(noteId, metadata => ({ ...metadata, color }))
              }}
              onClose={() => {
                setTimeout(() => {
                  document.querySelector<HTMLElement>(`#note-${noteId} .ProseMirror`)?.focus()
                }, 0)
              }}
            />
            <IconButton
              variant='transparent'
              iconId='trash-can'
              color={color('rgba(0, 0, 0, 0.25)')}
              onMouseDown={() => {
                api.deleteNote(noteId)
              }}
              tabIndex={-1}
              tooltip='Delete note'
            />
          </>
        ) : (
          <>
            <FooterText $isMe={hasReacted}>{reactionCount > 0 && reactionCount}</FooterText>
            <Spacer size='4' />
            <IconButton
              variant='transparent'
              iconId={hasReacted ? 'star--filled' : 'star'}
              color={color(hasReacted ? 'black' : 'rgba(0, 0, 0, 0.25)')}
              onClick={() => {
                setMetadata(previous => {
                  const { reactions } = previous
                  if (currentUser.uuid in reactions) {
                    return { ...previous, reactions: _.omit(reactions, currentUser.uuid) }
                  } else {
                    return {
                      ...previous,
                      reactions: {
                        ...reactions,
                        [currentUser.uuid]: { timestamp: new Date().toISOString() },
                      },
                    }
                  }
                })
              }}
              tabIndex={-1}
              tooltip='React to note'
            />
          </>
        )}
      </NoteFooter>
    </Note>
  )
})

const SortableItem: FC<{ noteId: string; awareness: Awareness; api: StickyNotesCardYjsApi }> = ({
  noteId,
  awareness,
  api,
}) => {
  const { attributes, listeners, setNodeRef, transform, transition, isDragging } = useSortable({
    id: noteId,
  })

  const style = {
    transform: CSS.Transform.toString(transform),
    transition,
  }

  return (
    <Item
      ref={setNodeRef}
      noteId={noteId}
      awareness={awareness}
      api={api}
      style={style}
      attributes={attributes}
      listeners={listeners}
      isDragging={isDragging}
    />
  )
}

const OverlayItem: FC<{ noteId: string; awareness: Awareness; api: StickyNotesCardYjsApi }> = ({
  noteId,
  awareness,
  api,
}) => {
  return <Item noteId={noteId} awareness={awareness} api={api} isOverlay />
}

/**
 * Yjs doesn't yet have native array move functionality. Kevin says he will release it in a few months.
 * Meanwhile, this observer ensures that content is not duplicated when two clients move the same item at the same time.
 * The easiest implementation is to keep the last unique item and delete the rest:
 */
const useArrayMoveSafeguard = (api: StickyNotesCardYjsApi): void => {
  useEffect(() => {
    const handler = (): void => arrayMoveSafeguard(api)

    api.yPositionArray.observe(handler)

    return () => api.yPositionArray.unobserve(handler)
  }, [api])
}

const animateLayoutChanges: AnimateLayoutChanges = args =>
  defaultAnimateLayoutChanges({ ...args, wasDragging: true })

const DroppableContainer: FCC<{ noteIds: NoteId[]; id: SectionId }> = ({ noteIds, id, children }) => {
  const { setNodeRef, transition, transform } = useSortable({
    id,
    data: {
      type: 'container',
      children: noteIds,
    },
    animateLayoutChanges,
  })

  return (
    <div
      ref={setNodeRef}
      style={{
        transition,
        transform: CSS.Translate.toString(transform),
      }}
    >
      {children}
    </div>
  )
}

export const LiveStickyNotesView: React.FC<{
  awareness: Awareness
  api: StickyNotesCardYjsApi
  sections: Data['sections']
}> = ({ awareness, api, sections }) => {
  const user = useSelector(selectUser)
  const noteIdToSectionIdMap = useNoteIdToSectionIdMap(api)
  const [draggingId, setDraggingId] = useState<UniqueIdentifier>()
  const recentlyMovedToNewContainer = useRef(false)

  const sensors = useSensors(
    useSensor(MouseSensor, {
      activationConstraint: {
        distance: 5,
      },
    }),
    useSensor(TouchSensor, {
      activationConstraint: {
        delay: 250,
        tolerance: 5,
      },
    })
  )

  useArrayMoveSafeguard(api)

  useEffect(() => {
    requestAnimationFrame(() => {
      recentlyMovedToNewContainer.current = false
    })
  }, [noteIdToSectionIdMap])

  if (!user || !noteIdToSectionIdMap) return null

  const sectionIds = sections.map(section => section.id)

  /* We are basing the dnd functionality this on the following example from dnd-kita
   * https://master--5fc05e08a4a65d0021ae0bf2.chromatic.com/?path=/story/presets-sortable-multiple-containers--vertical-grid
   * The code is here: https://github.com/clauderic/dnd-kit/blob/master/stories/2%20-%20Presets/Sortable/MultipleContainers.tsx
   */
  return (
    <DndContext
      sensors={sensors}
      collisionDetection={closestCorners}
      // measuring={{
      //   droppable: {
      //     strategy: MeasuringStrategy.Always,
      //   },
      // }}
      onDragStart={event => {
        setDraggingId(event.active.id)
      }}
      onDragOver={({ active, over }) => {
        const overId = over?.id
        if (overId === undefined) return

        const activeId = active.id
        assert(typeof activeId === 'string' && typeof overId === 'string')

        const activeSectionId = noteIdToSectionIdMap[activeId]
        const isOverSection = overId.startsWith('section:')
        const overSectionId = isOverSection ? overId.replace(/^section:/, '') : noteIdToSectionIdMap[overId]

        assert(activeSectionId !== undefined && overSectionId !== undefined)

        const activeSection = sections.find(section => section.id === activeSectionId)
        const overSection = sections.find(section => section.id === overSectionId)

        if (!activeSection || !overSection) return
        if (activeSection === overSection) return
        let newIndex: number

        const overSectionNoteIds = getSectionNoteIds(api, overSectionId, sectionIds)
        const overIndex = overSectionNoteIds.indexOf(overId)
        if (isOverSection) {
          newIndex = overSectionNoteIds.length + 1
        } else {
          const isBelowOverItem =
            over &&
            active.rect.current.translated &&
            active.rect.current.translated.top > over.rect.top + over.rect.height

          const modifier = isBelowOverItem === true ? 1 : 0

          newIndex = overIndex >= 0 ? overIndex + modifier : overSectionNoteIds.length + 1
        }

        console.debug('[onDragOver]', activeSectionId, overSectionId, activeId, overId, newIndex)

        recentlyMovedToNewContainer.current = true

        const noteIdBeforeNewIndex = overSectionNoteIds[newIndex - 1]

        // TODO: Consider having a local copy, so that we only propagate to remote when we drop the note
        const positionArray = api.getPositionArray()
        const activeGlobalIndex = positionArray.findIndex(pos => pos.noteId === activeId)
        assert(activeGlobalIndex >= 0)

        const newGlobalIndex = positionArray.findIndex(pos => pos.noteId === noteIdBeforeNewIndex) + 1

        api.yData.doc?.transact(() => {
          api.yPositionArray.delete(activeGlobalIndex)
          const insertIndex = Math.min(newGlobalIndex, api.yPositionArray.length)
          api.yPositionArray.insert(insertIndex, [{ sectionId: overSection.id, noteId: activeId }])
        })
      }}
      onDragEnd={({ active, over }) => {
        setDraggingId(undefined)

        const activeId = active.id
        assert(typeof activeId === 'string')

        const overId = over?.id
        if (overId === undefined) return
        assert(typeof overId === 'string')

        const activeSectionId = noteIdToSectionIdMap[activeId]
        const overSectionId = overId.startsWith('section:')
          ? overId.replace(/^section:/, '')
          : noteIdToSectionIdMap[overId]

        assert(overSectionId !== undefined && activeSectionId !== undefined)

        if (activeSectionId === overSectionId) {
          api.moveNotePos(activeId, overId)
        } else {
          throw new Error('Not implemented')
        }
      }}
    >
      {sections.map(section => {
        const sectionNoteIds = getSectionNoteIds(api, section.id, sectionIds)

        const addNote = (pos: 'start' | 'end'): void => {
          if (api.yMetadataMap.size >= 200) {
            const msg = "You've reached the limit of 200 sticky notes"
            console.warn(msg)
            alert(msg)
            return
          }

          const noteId = api.addNote({ pos, userId: user.uuid, sectionId: section.id, color: section.color })

          setTimeout(() => {
            document.querySelector<HTMLElement>(`#note-${noteId} .ProseMirror`)?.focus()
          }, 0)
        }

        return (
          <DroppableContainer key={section.id} noteIds={sectionNoteIds} id={`section:${section.id}`}>
            <SectionHeader>
              <SectionText>{section.title}</SectionText>
              <View grow />
              <IconButton
                variant='transparent'
                iconId='add--alt'
                tabIndex={-1}
                onClick={() => addNote('start')}
                tooltip='Add a note'
              />
            </SectionHeader>
            <SectionGrid>
              <SortableContext items={sectionNoteIds} strategy={rectSortingStrategy}>
                {sectionNoteIds.map(noteId => (
                  <SortableItem key={noteId} noteId={noteId} awareness={awareness} api={api} />
                ))}
              </SortableContext>
              <NewNote onClick={() => addNote('end')} />
            </SectionGrid>
          </DroppableContainer>
        )
      })}
      {createPortal(
        <DragOverlay>
          {typeof draggingId === 'string' ? (
            <OverlayItem noteId={draggingId} awareness={awareness} api={api} />
          ) : null}
        </DragOverlay>,
        document.body
      )}
    </DndContext>
  )
}
