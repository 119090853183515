import { useContext, useMemo } from 'react'
import { PolarisEditorContext } from 'sierra-client/views/flexible-content/polaris-editor-provider/polaris-editor-context'
import * as Y from 'yjs'

export const useFlexibleContentYDoc = (): { yDoc: Y.Doc } => {
  const context = useContext(PolarisEditorContext)

  if (context === undefined) throw new Error('Expected component to be wrapped in a <PolarisEditorProvider>')

  const { yDoc } = context

  return useMemo(() => ({ yDoc }), [yDoc])
}
