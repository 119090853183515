import { atom } from 'jotai'
import { MatrixPosition } from 'sierra-domain/api/strategy-v2'
import { MatrixResponseId, UserId } from 'sierra-domain/api/uuid'

export type MatrixResponseData = {
  matrixResponseId: MatrixResponseId
  position: MatrixPosition
  userId?: UserId
}

export type MatrixData = {
  responses: MatrixResponseData[]
}
export const currentMatrixDataAtom = atom<MatrixData | undefined>(undefined)
