import _ from 'lodash'
import { isElementType, parentType } from 'sierra-client/views/v3-author/queries'
import { isValidInteractiveCardChild } from 'sierra-domain/v3-author'
import {
  createParagraph,
  createPollCardAlternative,
  createPollCardAlternativeContainer,
} from 'sierra-domain/v3-author/create-blocks'
import { Editor, Node, Range, Text, Transforms } from 'slate'

const debug = (...messages: unknown[]): void => console.debug('[withPollCard]', ...messages)

export const withPollCardAlternative = (editor: Editor): Editor => {
  const { normalizeNode, insertBreak } = editor

  editor.normalizeNode = entry => {
    const [node, path] = entry

    if (isElementType('poll-card-alternative', node)) {
      if (parentType(editor, path) !== 'poll-card-alternative-container') {
        debug(
          `poll-card-alternative must be the direct child of a poll-card-alternative-container, unwrapping at`,
          JSON.stringify(path)
        )
        return Transforms.unwrapNodes(editor, { at: path, voids: true, mode: 'all' })
      }

      for (const [child, childPath] of Node.children(editor, path)) {
        if (!Text.isText(child)) {
          debug(`Unwrapping unexpected element at`, JSON.stringify(childPath))
          return Transforms.unwrapNodes(editor, { at: childPath, voids: true, mode: 'all' })
        }
      }
    }

    normalizeNode(entry)
  }

  editor.insertBreak = () => {
    const { selection } = editor
    if (!(selection && Range.isCollapsed(selection))) return insertBreak()
    const [entry] = Editor.nodes(editor, { match: isElementType('poll-card-alternative') })
    if (!entry) return insertBreak()

    const [, path] = entry
    insertBreak()
    debug(`Removing inherited image in new poll-card-alternative at`, JSON.stringify(path))
    const siblingPath = [..._.dropRight(path, 1), (_.last(path) ?? 0) + 1]
    return Transforms.setNodes(editor, { image: undefined }, { at: siblingPath })
  }

  return editor
}

export const withPollCardAlternativeContainer = (editor: Editor): Editor => {
  const { normalizeNode } = editor

  editor.normalizeNode = entry => {
    const [node, path] = entry

    if (isElementType('poll-card-alternative-container', node)) {
      if (parentType(editor, path) !== 'poll-card') {
        debug(
          `poll-card-alternative-container must be the direct child of a poll-card, unwrapping at`,
          JSON.stringify(path)
        )
        return Transforms.unwrapNodes(editor, { at: path, voids: true, mode: 'all' })
      }

      const children = Array.from(Node.children(editor, path))
      for (const [child, childPath] of children) {
        if (!isElementType('poll-card-alternative', child)) {
          debug(`Removing unexpected element at`, JSON.stringify(childPath))
          return Transforms.removeNodes(editor, { at: childPath, voids: true })
        }
      }

      if (children.length === 0) {
        const at = path.concat(0)
        debug(`Adding missing poll-card-alternative-container-child at`, JSON.stringify(at))
        return Transforms.insertNodes(editor, createPollCardAlternative(), { at, voids: true })
      }
    }

    normalizeNode(entry)
  }

  return editor
}

export const withPollCard = (editor: Editor): Editor => {
  const { normalizeNode } = editor

  editor.normalizeNode = entry => {
    const [node, path] = entry

    if (isElementType('poll-card', node)) {
      if (parentType(editor, path) !== 'editor') {
        debug('Poll-card must be at the root, unwrapping at', JSON.stringify(path))
        return Transforms.unwrapNodes(editor, { at: path })
      }
      const children = Array.from(Node.children(editor, path))

      const alternativeContainerIndex = children.findIndex(([child]) =>
        isElementType('poll-card-alternative-container', child)
      )

      if (alternativeContainerIndex === -1) {
        const at = path.concat(children.length)
        debug(`Adding missing poll-card-alternative-container at`, JSON.stringify(at))
        return Transforms.insertNodes(editor, createPollCardAlternativeContainer(), { at })
      }

      const hasInteractiveCardChild = children.some(([child]) => isValidInteractiveCardChild(child))
      if (!hasInteractiveCardChild) {
        debug(`Adding missing paragraph at`, path.concat(0))
        return Transforms.insertNodes(editor, createParagraph(), { at: path.concat(0) })
      }

      for (const [child, childPath] of children) {
        // Remove invalid children
        if (
          !(isElementType('poll-card-alternative-container', child) || isValidInteractiveCardChild(child))
        ) {
          return Transforms.removeNodes(editor, { at: childPath })
        }

        // Only keep the first poll-card-alternative-container
        const index = _.last(childPath) ?? 0
        if (isElementType('poll-card-alternative-container', child) && index > alternativeContainerIndex) {
          debug(`Removing duplicate poll-card-alternative-container at`, childPath)
          return Transforms.removeNodes(editor, { at: childPath })
        }
      }
    }
    normalizeNode(entry)
  }

  return editor
}
