import { FC, useEffect, useState } from 'react'
import { ChatMessageText } from 'sierra-client/components/liveV2/chat-message'
import {
  AVATAR_GAP,
  minHeightWidthRatio,
} from 'sierra-client/components/liveV2/live-layer/video-avatar-bar/calculate-video-avatar-bar-avatar-size'
import { useLiveSessionIdContext } from 'sierra-client/components/liveV2/live-session-id-provider'
import { useRightSidebarContext } from 'sierra-client/components/liveV2/live-sidebar-provider'
import { ParticipantGridContainer } from 'sierra-client/components/liveV2/participant-grid-container'
import { useMemoizedParticipantIds } from 'sierra-client/components/liveV2/participant-hooks/participant-grid-hooks'
import { useSidebarGridParticipants } from 'sierra-client/components/liveV2/participant-hooks/use-sidebar-grid-participants'
import { useLastNoOfMessages } from 'sierra-client/state/chat/hooks'
import { useDispatch, useSelector } from 'sierra-client/state/hooks'
import { setUsersDisplayed } from 'sierra-client/state/live/actions'
import { selectUsersDisplayed } from 'sierra-client/state/live/selectors'
import { selectUser } from 'sierra-client/state/user/user-selector'
import { CreateContentId } from 'sierra-domain/api/nano-id'
import { ScopedChatId } from 'sierra-domain/collaboration/types'
import { View } from 'sierra-ui/primitives'
import { palette, token } from 'sierra-ui/theming'
import { useOnChanged } from 'sierra-ui/utils'
import styled from 'styled-components'

const ADDITIONAL_PARTICIPANTS_CONTAINER_HEIGHT = 32

const Container = styled(View)`
  width: 100%;
  height: 100%;
  justify-content: center;
  align-items: center;
  will-change: opacity, transform;
  flex-direction: column;
`

const AdditionalParticipantsContainer = styled.div`
  position: relative;
  border-radius: 8px;
  min-height: ${ADDITIONAL_PARTICIPANTS_CONTAINER_HEIGHT}px;
  min-width: 42px;
  color: ${palette.primitives.white};
  align-items: center;
  justify-content: center;
  text-align: center;
  user-select: none;
  display: flex;
  font-size: 0.75rem;
  font-weight: 500;
  pointer-events: all;
  cursor: pointer;
  width: 100%;

  transition: color 0.1s cubic-bezier(0.25, 0.1, 0.25, 1);

  color: ${token('foreground/muted').opacity(0.5)};
  background-color: ${token('surface/default').opacity(0.2)};

  &:hover {
    color: ${token('foreground/muted')};
  }
`

const AvatarsContainer = styled(View)`
  width: 100%;
  height: calc(100% - ${ADDITIONAL_PARTICIPANTS_CONTAINER_HEIGHT}px);
`

const AdditionalParticipants: React.FC<{ additionalParticipants: number }> = ({ additionalParticipants }) => {
  const { toggleState } = useRightSidebarContext()
  const { liveSessionId } = useLiveSessionIdContext()
  const chatId = ScopedChatId.fromId(liveSessionId)
  const me = useSelector(selectUser)
  const usersDisplayed = useSelector(selectUsersDisplayed)
  const [excludeUuids, setExcludeUuids] = useState<string[]>([])
  const messages = useLastNoOfMessages({ chatId, noOfMessages: 1, exclude: excludeUuids })

  useEffect(() => {
    const userIds = [...usersDisplayed]
    if (me !== undefined) {
      userIds.push(me.uuid)
    }
    setExcludeUuids(userIds)
  }, [me, usersDisplayed])

  return (
    <AdditionalParticipantsContainer onClick={() => toggleState('list_open')}>
      +{additionalParticipants} more
      {messages.map(message => (
        <ChatMessageText message={message} chatMessagePosition='left' key={message.id} showSender={true} />
      ))}
    </AdditionalParticipantsContainer>
  )
}

const MIN_AVATARS = 5
const avatarsThatFit = (width: number, height: number, minHeightWithRatio: number): number => {
  const avatarMinHeight = width * minHeightWithRatio
  return Math.floor(height / (avatarMinHeight + AVATAR_GAP))
}

const useAvatarsThatFit = (containerRef: HTMLDivElement | null): number => {
  const [res, setRes] = useState(MIN_AVATARS)

  useEffect(() => {
    if (containerRef === null) return

    const observer = new ResizeObserver(() => {
      const containerRect = containerRef.getBoundingClientRect()
      const containerHeight = containerRect.height
      const containerWidth = containerRect.width
      const fit = Math.max(MIN_AVATARS, avatarsThatFit(containerWidth, containerHeight, minHeightWidthRatio))
      setRes(fit)
    })

    observer.observe(containerRef)
    return () => observer.disconnect()
  }, [containerRef])

  return res
}

export const SidebarParticipantGrid: FC<{
  oneColumnLayout: boolean
  participantLimit?: number
  flexibleContentId: CreateContentId
}> = ({ oneColumnLayout, participantLimit, flexibleContentId }) => {
  const dispatch = useDispatch()
  const [containerRef, setContainerRef] = useState<HTMLDivElement | null>(null)

  const maxAvatars = useAvatarsThatFit(containerRef)
  const numberOfAvatars = participantLimit ?? maxAvatars

  const sidebarGridParticipants = useSidebarGridParticipants({
    participantLimit: numberOfAvatars,
    flexibleContentId,
  })

  const participants = useMemoizedParticipantIds(sidebarGridParticipants.participants)
  const passiveParticipants = useMemoizedParticipantIds(sidebarGridParticipants.passiveParticipants)

  const participantIds = participants.flatMap(participant =>
    participant.userId !== undefined ? [participant.userId] : []
  )
  useOnChanged((_prev, curr) => {
    // TODO: should this also include pinned participants and yourself?
    void dispatch(setUsersDisplayed(curr))
  }, participantIds)

  return (
    <Container>
      <AvatarsContainer grow justifyContent='center' direction='column' ref={setContainerRef}>
        <ParticipantGridContainer
          flexibleContentId={flexibleContentId}
          participants={participants}
          passiveParticipants={!oneColumnLayout ? passiveParticipants : undefined}
          useLowResVideo
          oneColumnLayout={oneColumnLayout}
        />
      </AvatarsContainer>
      {passiveParticipants.length > 0 && oneColumnLayout && (
        <AdditionalParticipants additionalParticipants={passiveParticipants.length} />
      )}
    </Container>
  )
}
