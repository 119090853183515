import { useAtomValue, useSetAtom } from 'jotai'
import { useCallback, useEffect, useRef } from 'react'
import {
  selectFlexibleContentFile,
  selectNodeParentFolderId,
} from 'sierra-client/state/flexible-content/selectors'
import { useSelector } from 'sierra-client/state/hooks'
import { FCC } from 'sierra-client/types'
import {
  isAbortingMultiSelectionClearAtom,
  multiSelectionAtom,
} from 'sierra-client/views/flexible-content/editor/content-sidebar/multi-selection/atoms'
import {
  MultiSelectionTimeoutProvider,
  useMultiSelectionTimeoutRef,
} from 'sierra-client/views/flexible-content/editor/content-sidebar/multi-selection/setup/timeout-context'
import { isMetaKey } from 'sierra-client/views/flexible-content/editor/content-sidebar/multi-selection/utils'
import { CreateContentId } from 'sierra-domain/api/nano-id'
import { FileId, FolderId } from 'sierra-domain/flexible-content/identifiers'
import { File } from 'sierra-domain/flexible-content/types'

export const _SetupMultiSelection: FCC<{
  createContentId: CreateContentId
  parentFolderId: FolderId
  currentCard: File
}> = ({ createContentId, currentCard, parentFolderId, children }) => {
  const setMultiSelection = useSetAtom(multiSelectionAtom)
  const isAnyMultiSelectionMenuOpen = useAtomValue(isAbortingMultiSelectionClearAtom)
  const setAbortingMultiSelectionClearAtom = useSetAtom(isAbortingMultiSelectionClearAtom)
  const { ref: timeoutRef } = useMultiSelectionTimeoutRef()

  // Selection should always include the current card
  useEffect(() => {
    setMultiSelection(previous => {
      const containsCurrentCard = Object.keys(previous).includes(currentCard.id)

      if (containsCurrentCard) {
        return previous
      } else {
        return {
          ...previous,
          [currentCard.id]: { file: currentCard, parentFolderId },
        }
      }
    })
  }, [currentCard, parentFolderId, setMultiSelection])

  const resetMultiSelection = useCallback((): void => {
    if (timeoutRef.current !== null) {
      clearTimeout(timeoutRef.current)
    }

    // Selection should always include the current card
    setMultiSelection({
      [currentCard.id]: { file: currentCard, parentFolderId },
    })
    setAbortingMultiSelectionClearAtom(false)
  }, [currentCard, parentFolderId, setAbortingMultiSelectionClearAtom, setMultiSelection, timeoutRef])

  // When switching course, clear the selection
  useEffect(resetMultiSelection, [createContentId, currentCard.id, resetMultiSelection])

  // When clicking anywhere on the page, we remove the selection
  // unless we open a dropdown menu
  useEffect(() => {
    const clearSelection = (e: MouseEvent): void => {
      if (isMetaKey(e) || isAnyMultiSelectionMenuOpen) {
        return
      }

      resetMultiSelection()
    }
    document.addEventListener('click', clearSelection)

    return () => {
      document.removeEventListener('click', clearSelection)
    }
  }, [setMultiSelection, isAnyMultiSelectionMenuOpen, resetMultiSelection])

  return children
}

type SetupMultiSelection = { createContentId: CreateContentId; currentCardId: FileId | undefined }

export const SetupMultiSelection: FCC<SetupMultiSelection> = ({
  currentCardId,
  createContentId,
  children,
}) => {
  const timeoutRef = useRef(null)
  const currentCard = useSelector(state => selectFlexibleContentFile(state, createContentId, currentCardId))
  const parentFolderId = useSelector(state => selectNodeParentFolderId(state, createContentId, currentCardId))

  if (currentCard === undefined) {
    return children
  }

  return (
    <MultiSelectionTimeoutProvider value={{ ref: timeoutRef }}>
      <_SetupMultiSelection
        createContentId={createContentId}
        currentCard={currentCard}
        parentFolderId={parentFolderId ?? 'folder:root'}
      >
        {children}
      </_SetupMultiSelection>
    </MultiSelectionTimeoutProvider>
  )
}
