import { RefObject, useCallback, useEffect } from 'react'
import { useDragLayer } from 'react-dnd'
import { isDefined } from 'sierra-domain/utils'

type UseDragScroll = (input: { listRef: RefObject<HTMLElement>; dragTypes: Array<string | symbol> }) => void

export const useDragScroll: UseDragScroll = ({ listRef, dragTypes }): void => {
  const { isDragging, type } = useDragLayer(monitor => {
    return {
      type: monitor.getItemType(),
      isDragging: monitor.isDragging(),
    }
  })

  const handleScroll = useCallback(
    (y: number): void => {
      const list = listRef.current

      if (list === null) return

      const { top, bottom, height } = list.getBoundingClientRect()
      const threshold = Math.min(200, Math.max(30, height * 0.2))
      const speed = 6

      if (y - top < threshold) {
        list.scrollTop -= speed
      } else if (bottom - y < threshold) {
        list.scrollTop += speed
      }
    },
    [listRef]
  )

  useEffect(() => {
    const list = listRef.current

    const respondingToType = isDefined(type) && dragTypes.includes(type)

    if (!isDragging || list === null || !respondingToType) {
      return
    }

    let lastKnownY: number | null = null
    let frame: number | null = null

    function onMove(): void {
      if (lastKnownY !== null) {
        handleScroll(lastKnownY)
      }
      frame = requestAnimationFrame(onMove)
    }

    function updateMousePosition(event: PointerEvent): void {
      lastKnownY = event.clientY
    }

    list.addEventListener('pointermove', updateMousePosition)
    frame = requestAnimationFrame(onMove)

    return () => {
      list.removeEventListener('pointermove', updateMousePosition)
      if (frame !== null) {
        cancelAnimationFrame(frame)
      }
    }
  }, [listRef, handleScroll, isDragging, type, dragTypes])
}
