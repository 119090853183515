import React, { useCallback, useEffect } from 'react'
import { isChromium, isMacOs } from 'react-device-detect'
import { useTranslation } from 'sierra-client/hooks/use-translation'
import { IconButton, IconButtonGroup } from 'sierra-ui/primitives'

export const keyboardShortcuts = {
  macOS: {
    undo: '⌘ + Z',
    redo: '⌘ + ⇧ + Z',
  },
  windows: {
    undo: 'Ctrl + Z',
    redo: 'Ctrl + ⇧ + Z',
  },
} as const

export const getShortcutHint = (key: 'undo' | 'redo'): string => {
  if (isMacOs || isChromium) {
    return keyboardShortcuts.macOS[key]
  }
  return keyboardShortcuts.windows[key]
}

function isShiftKey({ shiftKey }: { shiftKey: boolean }): boolean {
  return shiftKey
}

function isMetaKey({ ctrlKey, metaKey }: { ctrlKey: boolean; metaKey: boolean }): boolean {
  const isCtrl = ctrlKey
  const isCmd = metaKey
  return isCtrl || isCmd
}

function isUndoKeyPress(event: KeyboardEvent): boolean {
  const isShortcutKey = event.key === 'z'
  return isShortcutKey && isMetaKey(event)
}

function isRedoKeyPress(event: KeyboardEvent): boolean {
  const isShortcutKey = event.key === 'z'
  return isShortcutKey && isMetaKey(event) && isShiftKey(event)
}

export const QueryHistory: React.FC<{
  canUndo: boolean
  undo: () => void
  canRedo: boolean
  redo: () => void
}> = ({ canUndo, undo, canRedo, redo }) => {
  const { t } = useTranslation()

  const onKeyDown = useCallback(
    (e: KeyboardEvent): void => {
      if (isRedoKeyPress(e)) {
        e.preventDefault()
        redo()
        return
      }
      if (isUndoKeyPress(e)) {
        e.preventDefault()
        undo()
        return
      }
    },
    [redo, undo]
  )

  useEffect(() => {
    document.addEventListener('keydown', onKeyDown)
    return () => {
      document.removeEventListener('keydown', onKeyDown)
    }
  }, [onKeyDown])

  return canUndo || canRedo ? (
    <IconButtonGroup>
      <IconButton
        disabled={!canUndo}
        onClick={undo}
        variant='transparent'
        iconId='undo'
        tooltip={`${t('dictionary.undo')} (${getShortcutHint('undo')})`}
      />
      <IconButton
        disabled={!canRedo}
        onClick={redo}
        variant='transparent'
        iconId='redo'
        tooltip={`${t('dictionary.redo')} (${getShortcutHint('redo')})`}
      />
    </IconButtonGroup>
  ) : null
}
