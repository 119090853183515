import React, { useMemo } from 'react'
import { graphql } from 'sierra-client/api/graphql/gql'
import {
  ProgramPageSelfEnrollCalendarEventFragment,
  ProgramPageSelfEnrollCalendarEventListQuery,
  ProgramPageSelfEnrollCalendarEventListQueryVariables,
} from 'sierra-client/api/graphql/gql/graphql'
import { useGraphQuery } from 'sierra-client/api/hooks/use-graphql-query'
import { useAssetResolver } from 'sierra-client/hooks/use-resolve-asset'
import { useTranslation } from 'sierra-client/hooks/use-translation'
import { selectPrependUrlWithProgramAndPath } from 'sierra-client/state/content/selectors'
import { useSelector } from 'sierra-client/state/hooks'
import { CalendarEventEnrollmentRequestButton } from 'sierra-client/views/learner/event-group/calendar-event-enrollment-request-button'
import { getListItemEnrollmentStatus } from 'sierra-client/views/learner/event-group/get-list-item-enrollment-status'
import {
  SelfEnrollSessionList,
  SelfEnrollSessionListData,
} from 'sierra-client/views/learner/program/components/self-enroll-session-list'
import {
  formatGqlEventSchedule,
  gqlEventLocationString,
} from 'sierra-client/views/manage/event-groups/event-utils'
import { CalendarEventId, CourseId } from 'sierra-domain/api/nano-id'
import { ImageUnion } from 'sierra-domain/content/v2/content'
import { isNonNullable, isNullable } from 'sierra-domain/utils'

const selfEnrollListQuery = graphql(`
  query ProgramPageSelfEnrollCalendarEventList($id: CourseId!) {
    course(id: $id) {
      __typename
      ... on NativeEventGroup {
        calendarEvents(input: { isUpcoming: true, isSelfEnrollable: true }) {
          id
          ...ProgramPageSelfEnrollCalendarEvent
        }
      }
    }
  }
`)

export const programPageSelfEnrollCalendarEventFragment = graphql(`
  fragment ProgramPageSelfEnrollCalendarEvent on CalendarEvent {
    id
    title
    seatsRemaining
    approverSetting
    userEnrollmentRequest {
      requestStatus
    }
    userAssignment {
      __typename
    }
    location {
      ...CalendarEventLocationFragment
    }
    schedule {
      ...CalendarEventScheduleFragment
    }
  }
`)

export const selfEnrollMutation = graphql(`
  mutation ProgramPageSelfEnrollToEvent($input: EnrollInEventInput!) {
    selfEnrollToEvent(input: $input) {
      __typename
    }
  }
`)

export const selfUnenrollMutation = graphql(`
  mutation ProgramPageSelfUnnrollFromEvent($input: UnenrollInEventInput!) {
    selfUnenrollFromEvent(input: $input) {
      __typename
    }
  }
`)

type SelfEnrollCalendarEvents = {
  eventGroupId: string
  courseId: string
  courseImage: ImageUnion | undefined
  sessions?: Array<ProgramPageSelfEnrollCalendarEventFragment>
}

export const SelfEnrollCalendarEvents: React.FC<SelfEnrollCalendarEvents> = ({
  courseId,
  eventGroupId,
  courseImage,
  sessions,
}) => {
  const eventGroup = useGraphQuery<
    ProgramPageSelfEnrollCalendarEventListQuery,
    ProgramPageSelfEnrollCalendarEventListQueryVariables
  >(
    { document: selfEnrollListQuery, queryOptions: { enabled: sessions === undefined } },
    { id: eventGroupId }
  )

  const prependUrlWithProgramAndPath = useSelector(selectPrependUrlWithProgramAndPath)

  const { t } = useTranslation()

  const { upcomingSessions, events } = useMemo((): {
    upcomingSessions: SelfEnrollSessionListData<CalendarEventId>[]
    events: ProgramPageSelfEnrollCalendarEventFragment[]
  } => {
    const queryEvents =
      eventGroup.data?.course?.__typename === 'NativeEventGroup'
        ? eventGroup.data.course.calendarEvents
        : undefined
    const events = queryEvents ?? sessions ?? []
    return {
      upcomingSessions: events.map(event => {
        const userIsEnrolledToEvent = isNonNullable(event.userAssignment)

        return {
          sessionId: event.id,
          buttonAction: userIsEnrolledToEvent ? 'unenroll' : 'enroll',
          freeSpots: event.seatsRemaining ?? undefined,
          location: gqlEventLocationString(event.location) ?? t('dictionary.in-person'),
          time: formatGqlEventSchedule(event.schedule),
          title: event.title,
        }
      }),
      events,
    }
  }, [eventGroup.data, sessions, t])

  const assetResolver = useAssetResolver({ size: 'admin-sm' })

  return (
    <SelfEnrollSessionList
      courseId={courseId}
      sessions={upcomingSessions}
      courseImage={courseImage}
      browseAllLink={prependUrlWithProgramAndPath(`/event/${eventGroupId}`)}
      renderButton={session => {
        const event = events.find(it => it.id === session.sessionId)
        if (!event) return <></>

        const requiresApproval = isNonNullable(event.approverSetting)
        const currentRequestStatus = isNonNullable(event.userEnrollmentRequest)
          ? event.userEnrollmentRequest.requestStatus
          : undefined
        const selfEnrollAvailable = isNullable(event.seatsRemaining) || event.seatsRemaining > 0

        const enrollmentStatus = getListItemEnrollmentStatus({
          isEnrolled: event.userAssignment?.__typename === 'CalendarEventAssignment',
          currentRequestStatus,
          requiresApproval,
          selfEnrollAvailable,
        })

        const calendarEventInfo = {
          left: event.title,
          imageUrl: assetResolver(courseImage, { type: 'course', courseId: CourseId.parse(courseId) }),
          right: formatGqlEventSchedule(event.schedule),
        }

        return (
          <CalendarEventEnrollmentRequestButton
            calendarEventId={session.sessionId}
            calendarEventInfo={calendarEventInfo}
            enrollmentStatus={enrollmentStatus}
            itemChanged={() => eventGroup.refetch()}
          />
        )
      }}
    />
  )
}
