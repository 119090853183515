import { useEffect } from 'react'
import { useToggle } from 'sierra-client/hooks/use-toggle'
import { FCC } from 'sierra-client/types'
import styled from 'styled-components'

const Container = styled.div<{ visible: boolean }>`
  transition: opacity 150ms cubic-bezier(0.25, 0.1, 0.25, 1);
  opacity: ${p => (p.visible ? 1 : 0)};
`

export const HoveredReveal: FCC<{ forcedVisible?: boolean; anchor: React.RefObject<HTMLElement> }> = ({
  forcedVisible,
  children,
  anchor,
}) => {
  const [visible, { on, off }] = useToggle()

  useEffect(() => {
    const anchorCurrent = anchor.current

    if (anchorCurrent === null) return

    anchorCurrent.addEventListener('mouseenter', on)
    anchorCurrent.addEventListener('mouseleave', off)

    return () => {
      anchorCurrent.removeEventListener('mouseenter', on)
      anchorCurrent.removeEventListener('mouseleave', off)
    }
  }, [anchor, off, on])

  return <Container visible={(forcedVisible === true ? true : undefined) ?? visible}>{children}</Container>
}
