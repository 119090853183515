import React, { useState } from 'react'
import { IconId } from 'sierra-ui/components'
import { MUIMenu, MUIMenuProps, MUIPopoverProps } from 'sierra-ui/mui'
import { IconButton } from 'sierra-ui/primitives'

export type CustomMenuProps = { icon?: IconId } & Omit<MUIMenuProps, 'open'> &
  Omit<MUIPopoverProps, 'open'> & {
    trigger?: React.ReactNode
    onTriggerClick?: () => void
    disabled?: boolean
  }

/**
 *
 * @deprecated
 * use primitives/menu-dropdown/icon-menu.tsx or other primitive instead
 */
export const Menu = ({
  getContentAnchorEl = undefined,
  disabled = false,
  anchorOrigin = {
    vertical: 'bottom',
    horizontal: 'center',
  },
  transformOrigin = {
    vertical: 'top',
    horizontal: 180,
  },
  onClose,
  onTriggerClick,
  trigger, // replaces the entire trigger component
  children,
  icon = 'overflow-menu--horizontal',
  className,
  ...rest
}: CustomMenuProps): JSX.Element => {
  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null)

  return (
    <>
      <div
        className={className}
        onClick={e => {
          e.preventDefault()
          e.stopPropagation()
          if (disabled) return

          setAnchorEl(e.currentTarget)
          onTriggerClick !== undefined && onTriggerClick()
        }}
      >
        {trigger !== undefined ? (
          trigger
        ) : (
          <IconButton variant='transparent' disabled={disabled} iconId={icon} />
        )}
      </div>

      <MUIMenu
        open={Boolean(anchorEl)}
        onClose={onClose}
        getContentAnchorEl={getContentAnchorEl}
        anchorEl={anchorEl}
        anchorOrigin={anchorOrigin}
        transformOrigin={transformOrigin}
        onClick={event => {
          event.preventDefault()
          event.stopPropagation()
          setAnchorEl(null)
        }}
        {...rest}
      >
        {children}
      </MUIMenu>
    </>
  )
}
