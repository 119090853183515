import { useSetAtom } from 'jotai'
import _ from 'lodash'
import React, { useRef } from 'react'
import { useDrop } from 'react-dnd'
import { DragItemTypes, EditorSidebarDragItem } from 'sierra-client/components/common/dnd/dnd-types'
import { ConfirmationModalProvider } from 'sierra-client/components/common/modals/confirmation-modal'
import { selectSubNodeIds } from 'sierra-client/state/flexible-content/selectors'
import { flexibleContentSlice } from 'sierra-client/state/flexible-content/slice'
import { useDispatch, useSelector } from 'sierra-client/state/hooks'
import {
  useCreatePageContext,
  useCreatePageNodeIdContext,
} from 'sierra-client/views/flexible-content/create-page-context'
import { BrowseFile } from 'sierra-client/views/flexible-content/editor/content-sidebar/browse-file'
import { BrowseFolder } from 'sierra-client/views/flexible-content/editor/content-sidebar/browse-folder'
import { sidebarContainerAtom } from 'sierra-client/views/flexible-content/editor/content-sidebar/content-sidebar-atoms'
import { SpeakerTabs } from 'sierra-client/views/flexible-content/editor/content-sidebar/speaker-notes/tabs'
import { ScopedCreateContentId } from 'sierra-domain/collaboration/types'
import { FileId, FolderId, LinkId, NodeId } from 'sierra-domain/flexible-content/identifiers'
import { Folder } from 'sierra-domain/flexible-content/types'
import { guardWith } from 'sierra-domain/utils'
import styled, { css } from 'styled-components'

const NodeRenderer: React.FC<{
  nodeId: NodeId
  parentFolderId: FolderId
}> = ({ nodeId, parentFolderId }) => {
  const { createContentId } = useCreatePageContext()
  const nodeIds = useSelector(state => selectSubNodeIds(state, createContentId, nodeId), _.isEqual)
  const { nodeId: currentFileId } = useCreatePageNodeIdContext()

  if (nodeId.startsWith('folder')) {
    const folderId = nodeId as FolderId
    const fileIds = nodeIds.map(fileId => {
      if (!fileId.startsWith('file'))
        throw new Error(`Folder ${nodeId} can only contain files, got ${fileId}`)
      return fileId as FileId
    })

    return (
      <BrowseFolder parentFolderId={parentFolderId} folderId={folderId} currentCardId={currentFileId}>
        {fileIds.map(fileId => (
          <BrowseFile key={fileId} fileId={fileId} parentFolderId={folderId} />
        ))}
      </BrowseFolder>
    )
  }

  if (nodeId.startsWith('file')) {
    const fileId = nodeId as FileId
    return <BrowseFile parentFolderId={parentFolderId} fileId={fileId} />
  }

  if (guardWith(LinkId, nodeId)) {
    return <div>Links not supported ({nodeId})</div>
  }

  return <div>Unexpected nodeId {JSON.stringify(nodeId)}</div>
}

const DropZone = styled.div<{ $dropIndicator: boolean }>`
  width: 100%;
  padding-inline: 48px;
  height: 4px;
  border-top: 2px solid transparent;

  ${p =>
    p.$dropIndicator &&
    css`
      border-color: ${p.theme.color.blueVivid};
    `}
`

const LastDropZone: React.FC<{ lastNodeId: NodeId | undefined }> = ({ lastNodeId }) => {
  const { createContentId } = useCreatePageContext()
  const nodeRef = useRef<HTMLDivElement | null>(null)
  const dispatch = useDispatch()

  const [{ isOver }, dropRef] = useDrop<
    EditorSidebarDragItem,
    { nodeId: NodeId },
    { isOver: boolean; canDrop: boolean }
  >(() => {
    return {
      accept: [DragItemTypes.EditorSidebar],
      collect: monitor => ({
        isOver: monitor.isOver({ shallow: true }),
        canDrop: monitor.canDrop(),
      }),
      canDrop: () => true,
      drop: (item, monitor) => {
        if (monitor.didDrop()) {
          return
        }

        const nodeId = item.id

        dispatch(
          flexibleContentSlice.actions.moveNode({
            createContentId,
            nodeId,
            parentFolderId: item.parentFolderId,
            destinationId: 'folder:root' as FolderId,
            nextTo: lastNodeId !== undefined ? { nodeId: lastNodeId, after: true } : undefined,
          })
        )

        return { nodeId }
      },
    }
  }, [createContentId, dispatch, lastNodeId])

  dropRef(nodeRef)

  return <DropZone $dropIndicator={isOver} ref={nodeRef} />
}

export const CreatePageSidebarItems: React.FC<{ withSpeakerTabs: boolean; rootFolder: Folder }> = ({
  withSpeakerTabs,
  rootFolder,
}) => {
  const { scopedCreateContentId } = useCreatePageContext()
  const setSidebarContainerAtom = useSetAtom(sidebarContainerAtom)

  const isLive = ScopedCreateContentId.isLiveContentId(scopedCreateContentId)

  return (
    <ConfirmationModalProvider>
      {isLive && withSpeakerTabs && <SpeakerTabs mode='create' />}
      <nav ref={setSidebarContainerAtom}>
        {rootFolder.nodeIds.map(nodeId => (
          <NodeRenderer key={nodeId} nodeId={nodeId} parentFolderId={rootFolder.id} />
        ))}
        <LastDropZone lastNodeId={_.last(rootFolder.nodeIds)} />
      </nav>
    </ConfirmationModalProvider>
  )
}
