import { StrategicErrorBoundary } from 'sierra-client/error/strategic-error-boundary'
import { dynamic } from 'sierra-client/utils/dynamic'
import { DropAWordMatterJsProps } from 'sierra-client/views/v3-author/drop-a-word/renderer/types'
import { FCC } from 'sierra-ui/types'

const CardMatterJs = dynamic(
  () => import('sierra-client/views/v3-author/drop-a-word/renderer/default-export-matter-js')
)

export const DropAWordMatterJsCard: FCC<DropAWordMatterJsProps> = ({ children, ...props }) => {
  return (
    <StrategicErrorBoundary id='drop-a-word-matter-js-card-error-boundary' strategies={['remount', 'reload']}>
      <CardMatterJs {...props}>{children}</CardMatterJs>
    </StrategicErrorBoundary>
  )
}
