import { atom, useAtomValue, useSetAtom } from 'jotai'
import { selectAtom } from 'jotai/utils'
import { debounce } from 'lodash'
import { useEffect, useMemo } from 'react'
import { useVideoCallService } from 'sierra-client/components/liveV2/live-context'
import { useDispatch, useSelector } from 'sierra-client/state/hooks'
import { liveSessionSlice } from 'sierra-client/state/live-session/slice'
import { selectClientId } from 'sierra-client/state/live/selectors'

type CurrentSpeaker = {
  speakerUID: string
  volume: number
}

const speakerThreshold = 0.1

const AllSpeakersAtom = atom<CurrentSpeaker[]>([])

export const SpeakerProvider = (): null => {
  const dispatch = useDispatch()
  const setAllSpeakers = useSetAtom(AllSpeakersAtom)

  const agoraUID = useSelector(selectClientId)
  const videoCallService = useVideoCallService()

  const debouncedUserWasEngaged = useMemo(
    () =>
      debounce(() => dispatch(liveSessionSlice.actions.userWasActivlyEngaged()), 1000, {
        maxWait: 5000,
        leading: true,
      }),
    [dispatch]
  )

  useEffect(() => {
    let aborted = false
    if (videoCallService === undefined) return

    const updateSpeaker = async (): Promise<void> => {
      const allSpeakers = await videoCallService.getCurrentSpeakers()
      if (aborted) return
      setAllSpeakers(allSpeakers.map(s => ({ speakerUID: s.id, volume: s.volume })))

      const userAsSpeaker = allSpeakers.find(s => s.id === agoraUID)
      if (userAsSpeaker !== undefined && userAsSpeaker.volume > speakerThreshold) {
        debouncedUserWasEngaged()
      }
    }

    const interval = setInterval(() => updateSpeaker(), 500)

    return () => {
      aborted = true
      clearInterval(interval)
    }
  }, [agoraUID, debouncedUserWasEngaged, setAllSpeakers, videoCallService])

  return null
}

/**
 * If the user is speaking it will return the volume of the user,
 * if the user is muted it will return undefined
 */
export const useRemoteSpeakerAudioVolume = (agoraUID: string | undefined): number | undefined => {
  const userSpeakerAtom = useMemo(
    () => selectAtom(AllSpeakersAtom, speakers => speakers.find(s => s.speakerUID === agoraUID)),
    [agoraUID]
  )

  const speaker = useAtomValue(userSpeakerAtom)
  if (speaker === undefined) return undefined
  if (agoraUID === undefined) return undefined

  return speaker.volume
}
