import { useEffect } from 'react'
import { useDebouncedState } from 'sierra-client/hooks/use-debounced-state'
import { usePost } from 'sierra-client/hooks/use-post'
import { CourseSummary } from 'sierra-domain/api/admin'
import { CourseKind } from 'sierra-domain/api/common'
import { XRealtimeAdminCoursesListCourses } from 'sierra-domain/routes'

export function useContentList(kind: CourseKind | undefined): CourseSummary[] {
  const { postWithUserErrorException } = usePost()

  const [courses, setCourses] = useDebouncedState<CourseSummary[]>([], {
    maxWait: 200,
  })

  useEffect(() => {
    if (kind === undefined) {
      setCourses([])
      return
    }

    void postWithUserErrorException(XRealtimeAdminCoursesListCourses, {
      contentKinds: [kind],
    }).then(res => {
      setCourses(
        res.courses
          .filter(c => !c.isCourseEdition && c.title !== '')
          .sort((a, b) => a.title.localeCompare(b.title))
      )
    })
  }, [postWithUserErrorException, setCourses, kind])

  return courses
}
