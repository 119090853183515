import { deriveTheme } from 'sierra-ui/color'
import { Text } from 'sierra-ui/primitives'
import styled from 'styled-components'

export const InteractiveCardInstruction = styled(Text)`
  color: ${p => deriveTheme(p.theme).textColor};
  opacity: 0.75;
  font-size: 0.875rem;
  font-weight: 500;
  user-select: none;
`
