import { useEffect } from 'react'
import { useContentKindPermissions } from 'sierra-client/hooks/use-permissions'
import { FCC } from 'sierra-client/types'
import { CreateContentId } from 'sierra-domain/api/nano-id'
import { LoadingSpinner, View } from 'sierra-ui/primitives'
import styled from 'styled-components'

const LoadingContainer = styled(View)`
  position: absolute;
  inset: 0;

  & > * {
    margin: auto;
  }
`

const Spinner: React.FC = () => (
  <LoadingContainer>
    <View direction='column'>
      <LoadingSpinner />
    </View>
  </LoadingContainer>
)

const permissionMap = {
  view: 'LEARN',
  comment: 'COMMENT',
  edit: 'EDIT_CONTENT',
} as const

export const RequireCoursePermission: FCC<{
  contentId: CreateContentId
  showSpinner?: true
  onAccessDenied?: () => void
  onContentNotFound?: () => void
  minPermissionLevel?: keyof typeof permissionMap
}> = ({
  children,
  contentId,
  showSpinner,
  onAccessDenied,
  onContentNotFound,
  minPermissionLevel = 'comment',
}) => {
  const permissions = useContentKindPermissions(contentId)
  const hasRequiredPermission = permissions.has(permissionMap[minPermissionLevel])

  useEffect(() => {
    if (permissions.status === 'loading') return
    if (hasRequiredPermission) return
    if (permissions.status === 'resource-not-found') {
      onContentNotFound?.()
      return
    }
    onAccessDenied?.()
  }, [hasRequiredPermission, minPermissionLevel, onAccessDenied, onContentNotFound, permissions.status])

  if (permissions.status === 'loading') {
    if (showSpinner) return <Spinner />
    else return null
  }

  if (hasRequiredPermission) return <>{children}</>
  else return null
}
