import { useAtomValue } from 'jotai'
import { minBy } from 'lodash'
import { useMemo } from 'react'
import { useSelectCurrentCard } from 'sierra-client/components/liveV2/hooks/use-select-current-card'
import {
  CollapsableSidebarItem,
  EmptyPlaceholder,
  EmptyText,
  Response,
} from 'sierra-client/components/liveV2/right-sidebar/components'
import {
  ListItem,
  participantUniqueId,
} from 'sierra-client/components/liveV2/right-sidebar/participant-list-item'
import { getPollResponse } from 'sierra-client/features/sana-now'
import { useTranslation } from 'sierra-client/hooks/use-translation'
import { useSelector } from 'sierra-client/state/hooks'
import { PollData, currentPollDataAtom } from 'sierra-client/state/interactive-card-data/poll-card'
import { currentQuestionFacilitatorSummaryDataAtom } from 'sierra-client/state/interactive-card-data/question-card'
import {
  ReflectionResponseSummaryData,
  currentReflectionSummaryDataAtom,
} from 'sierra-client/state/interactive-card-data/reflection-card'
import { currentSlidingScaleDataAtom } from 'sierra-client/state/interactive-card-data/sliding-scale-card'
import { selectAllSessionParticipantsIncludingMe } from 'sierra-client/state/live-session/selectors'
import { useFlexibleContentYDoc } from 'sierra-client/views/flexible-content/polaris-editor-provider/use-flexible-content-ydoc'
import { isElementType } from 'sierra-client/views/v3-author/queries'
import { getSlateDocument } from 'sierra-domain/v3-author/slate-yjs-extension'
import { Descendant } from 'slate'

type UserResponse = {
  userId?: string
  response: Response
}

const CompletionParticipantLists = (props: {
  userResponses: UserResponse[] | null
  isReflectionCard?: boolean
}): JSX.Element => {
  const participants = useSelector(selectAllSessionParticipantsIncludingMe)
  const { t } = useTranslation()

  const participantsWithResponse = useMemo(
    () =>
      participants.map(participant => ({
        participant,
        response: props.userResponses?.find(data => data.userId === participant.userId)?.response,
      })),
    [participants, props.userResponses]
  )

  const completeParticipants = participantsWithResponse.filter(p => p.response !== undefined)
  const waitingParticipants = participantsWithResponse.filter(p => p.response === undefined)

  return (
    <>
      <CollapsableSidebarItem
        title={props.isReflectionCard === true ? t('dictionary.answered') : t('dictionary.completed')}
        itemCount={completeParticipants.length}
      >
        {completeParticipants.length === 0 ? (
          <EmptyPlaceholder>
            <EmptyText>{t('live.no-people-completed')}</EmptyText>
          </EmptyPlaceholder>
        ) : (
          <ul>
            {completeParticipants.map(({ participant, response }) => (
              <ListItem
                key={participantUniqueId(participant)}
                participant={participant}
                showRaisedHand
                response={response}
              />
            ))}
          </ul>
        )}
      </CollapsableSidebarItem>
      <CollapsableSidebarItem
        title={props.isReflectionCard === true ? t('dictionary.not-answered') : t('dictionary.waiting')}
        itemCount={waitingParticipants.length}
      >
        {waitingParticipants.length === 0 ? (
          <EmptyPlaceholder>
            <EmptyText>{t('live.people.no-people-waiting')}</EmptyText>
          </EmptyPlaceholder>
        ) : (
          <ul>
            {waitingParticipants.map(({ participant }) => (
              <ListItem key={participantUniqueId(participant)} participant={participant} showRaisedHand />
            ))}
          </ul>
        )}
      </CollapsableSidebarItem>
    </>
  )
}

const getPollResponses = ({
  document,
  pollResponses,
}: {
  document: Descendant[]
  pollResponses?: PollData
}): UserResponse[] | null => {
  const pollCard = document.find(isElementType('poll-card'))
  if (pollCard === undefined) return null

  if (pollResponses !== undefined) {
    const userResponses = pollResponses.optionResults.flatMap(optionResult => {
      const pollResponse = getPollResponse(pollCard, optionResult.optionId)
      return optionResult.votedByUserIds.map(uuid => ({
        userId: uuid,
        response: { responseId: optionResult.optionId, response: pollResponse },
      }))
    })
    return userResponses
  }
  return null
}

const getReflectionResponses = ({
  document,
  reflectionResponseSummary,
}: {
  document: Descendant[]
  reflectionResponseSummary: ReflectionResponseSummaryData[]
}): UserResponse[] | null => {
  const reflectionCard = document.find(isElementType('reflection-card'))

  if (reflectionCard === undefined) return null

  return reflectionResponseSummary.map(response => ({
    userId: response.userId,
    response: {
      responseId: response.responseId,
      response: response.response,
      skipped: false,
    },
  }))
}

export const PollParticipantLists = (): JSX.Element => {
  const pollResponses = useAtomValue(currentPollDataAtom)

  const currentCard = useSelectCurrentCard()
  const { yDoc } = useFlexibleContentYDoc()
  const userResponses =
    currentCard !== undefined
      ? getPollResponses({ document: getSlateDocument(yDoc, currentCard.id), pollResponses })
      : null

  return <CompletionParticipantLists userResponses={userResponses} />
}

export const ReflectionParticipantLists = (): JSX.Element => {
  const reflectionResponseSummary = useAtomValue(currentReflectionSummaryDataAtom)

  const currentCard = useSelectCurrentCard()
  const { yDoc } = useFlexibleContentYDoc()

  let userResponses: UserResponse[] | null = null
  if (currentCard !== undefined) {
    const document = getSlateDocument(yDoc, currentCard.id)
    userResponses = getReflectionResponses({ document, reflectionResponseSummary })
  }

  return <CompletionParticipantLists isReflectionCard userResponses={userResponses} />
}

export const SlidingScaleParticipantLists = (): JSX.Element => {
  const realTimeSlidingScaleResponses = useAtomValue(currentSlidingScaleDataAtom)

  const userResponses =
    realTimeSlidingScaleResponses?.responses.map(response => ({
      userId: response.uuid,
      response: {
        responseId: response.id,
        response: `${response.position}%`,
      },
    })) ?? null

  return <CompletionParticipantLists userResponses={userResponses} />
}

export const QuestionParticipantLists = (): JSX.Element => {
  const facilitatorAnswerSummaryAtom = useAtomValue(currentQuestionFacilitatorSummaryDataAtom)

  const fastestUserResponse = minBy(
    facilitatorAnswerSummaryAtom.filter(it => it.correct),
    it => new Date(it.createdAt).valueOf()
  )

  const userResponses = facilitatorAnswerSummaryAtom.map(summary => ({
    userId: summary.userId,
    response: {
      responseId: summary.questionId,
      correct: summary.correct,
      attempts: summary.attempts,
      fastestCorrect: summary.correct && fastestUserResponse?.userId === summary.userId,
    },
  }))

  return <CompletionParticipantLists userResponses={[...userResponses]} />
}
