import React from 'react'
import { useTranslation } from 'sierra-client/hooks/use-translation'
import { useResponsivePreview } from 'sierra-client/views/manage/certificates/edit-certificate-panel/use-edit-certificate'
import { withModal } from 'sierra-client/views/manage/utils/with-modal'
import { Modal } from 'sierra-ui/components'
import { Button, View } from 'sierra-ui/primitives'
import styled from 'styled-components'

const StyledModal = styled(Modal)`
  border-radius: ${p => p.theme.borderRadius['size-8']};
  background: transparent;
`

const PreviewContainer = styled(View)<{ $width: number; $height: number }>`
  width: ${p => p.$width}px;
  height: ${p => p.$height}px;
  transition: background-color 100ms cubic-bezier(0.25, 0.1, 0.25, 1) 200ms;
  position: relative;
  border-radius: ${p => p.theme.borderRadius['size-8']};
`

const Preview = styled.iframe`
  border-radius: ${p => p.theme.borderRadius['size-20']};
  overflow: hidden;
  position: relative;
  left: 50%;
  top: 50%;
  transform: translate(-50%, -50%);
  transform-origin: center center;
  flex-shrink: 0;
  transition:
    background-color 100ms cubic-bezier(0.25, 0.1, 0.25, 1) 200ms,
    color 100ms cubic-bezier(0.25, 0.1, 0.25, 1) 200ms;
`

type PreviewHtmlModalProps = {
  src: string
  fullWidth?: number
  fullHeight?: number
  displayWidth?: number
  displayHeight?: number
  animated?: boolean
}

export const PreviewHtmlModal = withModal<PreviewHtmlModalProps>(
  {
    ModalComponent: StyledModal,
    size: 'fit-content',
    disableScrollbarGutter: true,
  },
  ({
    onClose,
    src,
    fullWidth = 1920,
    fullHeight = 1357,
    displayWidth = 960,
    displayHeight = 679,
    animated = false,
  }) => {
    const { t } = useTranslation()

    const [previewContainerElement, setPreviewContainerElement] = React.useState<HTMLDivElement | null>(null)
    const [previewElement, setPreviewElement] = React.useState<HTMLIFrameElement | null>(null)

    const { outputSize } = useResponsivePreview({
      previewContainerElement,
      previewElement,
      maxWidth: displayWidth,
      maxHeight: displayHeight,
      padding: 0,
      scaleToContainer: true,
    })

    return (
      <>
        <View animated={animated} direction='column'>
          <PreviewContainer
            $width={outputSize.width}
            $height={outputSize.height}
            ref={setPreviewContainerElement}
          >
            <Preview width={fullWidth} height={fullHeight} srcDoc={src} ref={setPreviewElement} />
          </PreviewContainer>
        </View>
        <View
          marginTop={'8'}
          justifyContent='flex-end'
          animated={animated}
          initial={{ opacity: 0, y: -5 }}
          animate={{ opacity: 1, y: 0 }}
          transition={{ duration: 0.2, delay: 1 }}
        >
          <Button variant='secondary' onClick={onClose}>
            {t('dictionary.dismiss')}
          </Button>
        </View>
      </>
    )
  }
)
