import { useEffect, useMemo } from 'react'
import { useAssetResolver } from 'sierra-client/hooks/use-resolve-asset'
import { selectNextUpFileId } from 'sierra-client/state/card-progress/selectors'
import { useSelector } from 'sierra-client/state/hooks'
import { preloadFileImage } from 'sierra-client/views/flexible-content/card-background/preload-file-image'
import { useSelfPacedFiles } from 'sierra-client/views/self-paced/files-provider'
import { AssetContext } from 'sierra-domain/asset-context'

export const NextCardImagePreload = (): null => {
  const { currentFile, flexibleContentId, fileIds, getFile } = useSelfPacedFiles()
  const nextFileId = useSelector(state => selectNextUpFileId(state, flexibleContentId))(
    currentFile?.id,
    fileIds
  )
  const nextFile = nextFileId !== undefined ? getFile(nextFileId) : undefined

  const assetResolver = useAssetResolver({ size: 'default' })

  const assetContext: AssetContext = useMemo(() => {
    return { type: 'course', courseId: flexibleContentId }
  }, [flexibleContentId])

  useEffect(() => {
    if (nextFile) preloadFileImage(nextFile, assetResolver, assetContext)
  }, [assetContext, assetResolver, nextFile])

  return null
}
