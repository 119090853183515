import { useLocation, type ParsedLocation } from '@tanstack/react-router'
import { type useHref } from 'sierra-client/hooks/router/use-href' // eslint-disable-line @typescript-eslint/no-unused-vars -- used by tsdoc
import { type GlobalRouter } from 'sierra-client/router'

/**
 * Optimized hook for getting the current pathname, and triggering re-renders when it changes.
 *
 * Note: the pathname does _not_ include search strings or hashes. To include those, see {@link useHref} or use {@link useLocation} directly.
 *
 * @example
 * ```ts
 * // On full URL: https://example.com/some/path?query=string#hash
 * const pathname = usePathname()
 * // Result: '/some/path'
 * ```
 */
export function usePathname(): string {
  return useLocation<GlobalRouter, ParsedLocation, string>({ select: location => location.pathname })
}
