import { isNaN, parseInt } from 'lodash'
import { DateTime, Duration } from 'luxon'
import { ChangeEvent, useCallback, useEffect, useRef, useState } from 'react'
import { useIsFacilitatorOrLearnerLedSession } from 'sierra-client/components/liveV2/hooks/use-is-facilitator-or-learner-led-session'
import {
  useSelectCurrentCard,
  useSelectCurrentCardBackgroundColor,
} from 'sierra-client/components/liveV2/hooks/use-select-current-card'
import { TimerButton, TimerState, TimerText } from 'sierra-client/components/liveV2/timer-button'
import { ShortcutMenu } from 'sierra-client/components/shortcut-menu'
import { AppThemeTokenProvider } from 'sierra-client/config/token-provider'
import { Logging } from 'sierra-client/core/logging'
import { NowButtonCSS } from 'sierra-client/features/sana-now/header/buttons'
import { useTranslation } from 'sierra-client/hooks/use-translation'
import { useIsMobile } from 'sierra-client/state/browser/selectors'
import { getServerTimeNow, selectClock } from 'sierra-client/state/collaboration/selectors'
import { useDispatch, useSelector } from 'sierra-client/state/hooks'
import { startTimer } from 'sierra-client/state/live-session/actions'
import { selectTimerEndTime } from 'sierra-client/state/live-session/selectors'
import { liveSessionSlice } from 'sierra-client/state/live-session/slice'
import { Icon, Modal, Tooltip } from 'sierra-ui/components'
import { Button, InputPrimitive, Text, View } from 'sierra-ui/primitives'
import styled from 'styled-components'

const TransparentTimerButton = styled(TimerButton)`
  ${NowButtonCSS}
  white-space: nowrap;
`

const ringingAnimation = {
  initial: {
    rotate: 0,
  },
  ringing: {
    rotate: [
      0, 1, 0, -1, 0, 2, 0, -2, 0, 3, 0, -3, 0, 3, 0, -3, 0, 3, 0, -3, 0, 3, 0, -3, 0, 3, 0, -3, 0, 3, 0, -3,
      0, 3, 0, -3, 0, 3, 0, -3, 0, 3, 0, -3, 0, 3, 0, -3, 0, 3, 0, -3, 0, 3, 0, -3, 0, 3, 0, -3, 0, 3, 0, -3,
      0, 3, 0, -3, 0, 3, 0, -3, 0, 3, 0, -3, 0, 3, 0, -3, 0, 3, 0, -3, 0, 2, 0, -2, 0, 1, 0, -1, 0,
    ],
    transition: {
      rotate: { duration: 2, ease: [0.25, 0.1, 0.25, 1] },
    },
  },
}

export const TimerCountdown = (): JSX.Element | null => {
  const endTime = useSelector(selectTimerEndTime)
  const clock = useSelector(selectClock)
  const isFacilitatorOrLearnerLedSession = useIsFacilitatorOrLearnerLedSession()

  const [countDownTimeRemaining, setCountDownTimeRemaining] = useState<Duration | undefined>()

  const getTimerState = (): TimerState => {
    if (countDownTimeRemaining === undefined) return 'normal'
    if (countDownTimeRemaining.as('seconds') <= 1) return 'done'
    if (countDownTimeRemaining.as('seconds') <= 10) return 'warning'
    return 'normal'
  }

  const backgroundColor = useSelectCurrentCardBackgroundColor()

  useEffect(() => {
    let interval: ReturnType<typeof setInterval>
    if (endTime === undefined) {
      setCountDownTimeRemaining(undefined)
    } else {
      interval = setInterval(() => {
        const now = DateTime.fromISO(getServerTimeNow(clock))
        const diff = endTime.diff(now)
        if (diff.as('seconds') < 0) {
          setCountDownTimeRemaining(Duration.fromMillis(0))
          clearInterval(interval)
        } else {
          setCountDownTimeRemaining(diff)
        }
      }, 200)
    }

    return () => {
      clearInterval(interval)
    }
  }, [clock, endTime])

  if (endTime === undefined || countDownTimeRemaining === undefined || isFacilitatorOrLearnerLedSession) {
    return null
  }

  return (
    <TransparentTimerButton
      $backgroundColor={backgroundColor}
      id='tour-live-timer'
      $clickable={false}
      $timerState={getTimerState()}
      initial='initial'
      animate={getTimerState() === 'done' ? 'ringing' : 'initial'}
      variants={ringingAnimation}
    >
      <Icon iconId='timer' color='currentColor' />
      <TimerText color='currentColor' size='small'>
        {countDownTimeRemaining.toFormat('mm:ss')}
      </TimerText>
    </TransparentTimerButton>
  )
}

export const SanaNowTimer = (): JSX.Element | null => {
  const isFacilitatorOrLearnerLedSession = useIsFacilitatorOrLearnerLedSession()
  const dispatch = useDispatch()
  const endTime = useSelector(selectTimerEndTime)
  const clock = useSelector(selectClock)
  const card = useSelectCurrentCard()
  const previousCardId = useRef<string | undefined>(card?.id)

  const { t } = useTranslation()
  const isMobile = useIsMobile()

  const [time, setTime] = useState<string>('')

  const [modalOpen, setModalOpen] = useState(false)

  const [countDownTimeRemaining, setCountDownTimeRemaining] = useState<Duration | undefined>()

  const handleStartTimer = useCallback((): void => {
    if (time === '') return

    const [minutes, seconds] = time.split(':').map(parseInt)

    let duration = minutes !== undefined && !isNaN(minutes) ? minutes * 60 : 0
    duration += seconds !== undefined && !isNaN(seconds) ? seconds : 0

    void dispatch(startTimer({ durationSeconds: duration }))
    void dispatch(Logging.liveSession.timerStarted())

    setModalOpen(false)
  }, [dispatch, time])

  const handleResetTimer = useCallback((): void => {
    void dispatch(liveSessionSlice.actions.resetTimer())
    setCountDownTimeRemaining(undefined)
    setModalOpen(false)
  }, [dispatch])

  // Switching card should reset the timer
  useEffect(() => {
    if (card?.id !== previousCardId.current && countDownTimeRemaining?.as('milliseconds') === 0) {
      handleResetTimer()
      previousCardId.current = card?.id
    }
  }, [card, countDownTimeRemaining, handleResetTimer])

  useEffect(() => {
    let interval: ReturnType<typeof setInterval>
    if (endTime === undefined) {
      setCountDownTimeRemaining(undefined)
    } else {
      interval = setInterval(() => {
        const now = DateTime.fromISO(getServerTimeNow(clock))
        const diff = endTime.diff(now)
        if (diff.as('seconds') < 0) {
          setCountDownTimeRemaining(Duration.fromMillis(0))
          clearInterval(interval)
        } else {
          setCountDownTimeRemaining(diff)
        }
      }, 200)
    }

    return () => {
      clearInterval(interval)
    }
  }, [clock, endTime])

  const handleTimeChanged = (e: ChangeEvent<HTMLInputElement>): void => {
    const newValue = e.target.value

    if (newValue === '') {
      setTime(newValue)
      return
    }

    const match = /^[0-9]{1,2}(:[0-5]?[0-9]?)?$/gi.test(newValue)
    if (!match) return

    setTime(newValue)
  }

  const setTimerText = t('dictionary.set-timer')
  const editTimerText = t('dictionary.edit-timer')
  const active = countDownTimeRemaining

  const showModal = useCallback(() => {
    setModalOpen(true)
  }, [])

  const backgroundColor = useSelectCurrentCardBackgroundColor()

  const getTimerState = (): TimerState => {
    if (countDownTimeRemaining === undefined) return 'normal'
    if (countDownTimeRemaining.as('seconds') <= 1) return 'done'
    if (countDownTimeRemaining.as('seconds') <= 10) return 'warning'
    return 'normal'
  }

  // If no timer is set, don't show anything for a learner
  if (!isFacilitatorOrLearnerLedSession && countDownTimeRemaining === undefined) return null

  return (
    <>
      <Tooltip title={isFacilitatorOrLearnerLedSession ? (active ? editTimerText : setTimerText) : ''}>
        <TransparentTimerButton
          $backgroundColor={backgroundColor}
          id='tour-live-timer'
          $clickable={isFacilitatorOrLearnerLedSession}
          onClick={isFacilitatorOrLearnerLedSession ? () => showModal() : undefined}
          $timerState={getTimerState()}
          initial='initial'
          animate={getTimerState() === 'done' ? 'ringing' : 'initial'}
          variants={ringingAnimation}
        >
          <Icon iconId='timer' color='currentColor' />
          {countDownTimeRemaining === undefined ? (
            !isMobile && (
              <Text size='small' color='currentColor' bold>
                {setTimerText}
              </Text>
            )
          ) : (
            <TimerText color='currentColor' size='small'>
              {countDownTimeRemaining.toFormat('mm:ss')}
            </TimerText>
          )}
        </TransparentTimerButton>
      </Tooltip>
      {isFacilitatorOrLearnerLedSession && (
        <ShortcutMenu.Action
          group='live'
          permission='ACCESS_LMS'
          iconId='timer'
          label={{ type: 'untranslated', value: setTimerText }}
          run={showModal}
        />
      )}
      <AppThemeTokenProvider followSystemColorScheme={true}>
        <Modal
          open={modalOpen}
          onClose={() => setModalOpen(false)}
          size={{ width: 400 }}
          overlayVariant='light'
        >
          <View padding='small medium' gap='xsmall' direction='column'>
            <View direction='row' grow>
              <Icon iconId='timer' />
              <Text color='foreground/primary' size='regular'>
                Timer
              </Text>
            </View>
            <InputPrimitive
              id='timer-input'
              type='text'
              onChange={handleTimeChanged}
              value={time}
              placeholder='02:00'
              onKeyDown={e => {
                if (e.key === 'Enter') handleStartTimer()
              }}
            />
            <View>
              <Button disabled={time === ''} onClick={handleStartTimer}>
                Start
              </Button>
              <Button variant='secondary' onClick={handleResetTimer}>
                Reset
              </Button>
            </View>
          </View>
        </Modal>
      </AppThemeTokenProvider>
    </>
  )
}
