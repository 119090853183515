import React from 'react'
import {
  HeaderItem,
  manageExpandedAtom,
  useManageLayoutSidebarItems,
} from 'sierra-client/features/collapsable-sidebar'
import { useTranslation } from 'sierra-client/hooks/use-translation'
import { useHasManageAccess } from 'sierra-client/views/manage/permissions/use-has-manage-access'

const ManageSidebarInner: React.FC = () => {
  const manageItems = useManageLayoutSidebarItems()
  const { t } = useTranslation()

  return (
    <HeaderItem
      type='nested'
      id='tour-manage'
      data-testid='manage-item'
      title={t('global-sidebar.manage')}
      iconId='building'
      items={manageItems}
      expandedAtom={manageExpandedAtom}
      nestedItemPadding='8 14'
    />
  )
}

export const ManageSidebar: React.FC = () => {
  const hasManageAccess = useHasManageAccess()

  if (!hasManageAccess) return null
  else return <ManageSidebarInner />
}
