import { useVirtualizer } from '@tanstack/react-virtual'
import React, { useCallback, useEffect, useMemo, useRef } from 'react'
import { useTranslation } from 'sierra-client/hooks/use-translation'
import { labelToString } from 'sierra-client/lib/filter/components/common'
import { valueId } from 'sierra-client/lib/filter/components/predicate-utils'
import {
  AutocompleteOption,
  AutocompleteOptions,
} from 'sierra-client/views/manage/components/user-attributes/flows/components/layout'
import { useComposeUserInvitationConfig } from 'sierra-client/views/manage/components/user-attributes/flows/invite-users/hooks/use-compose-user-invitation-config'
import {
  GreyPill,
  RemoveButtonInPills,
} from 'sierra-client/views/manage/components/user-attributes/flows/invite-users/panels/set-attributes-panel/auto-complete-pills'
import {
  getCommonValueListDomainChoices,
  getValueRepFromDomainChoices,
} from 'sierra-client/views/manage/components/user-attributes/flows/invite-users/utils'
import { createInvitationAttribute } from 'sierra-client/views/manage/components/user-attributes/utils'
import { UserInvitationAttribute } from 'sierra-domain/user-attributes/user-invitation-attribute'
import {
  UserGroupsDomainRep,
  UserProgramsDomainRep,
} from 'sierra-domain/user-attributes/user-invitation-domain-rep'
import { isDefined } from 'sierra-domain/utils'
import { Autocomplete, DefaultStyledAutocompleteOption } from 'sierra-ui/components'
import { Text, View } from 'sierra-ui/primitives'

type InvitationDomainEntity = { id: string; title: string }

const VirtualizedListOfInvitationDomainEntity: React.FC<{ matchingCourses: InvitationDomainEntity[] }> = ({
  matchingCourses,
}) => {
  const scrollingRef = useRef<HTMLDivElement>(null)
  const virtualizer = useVirtualizer({
    count: matchingCourses.length,
    getScrollElement: () => scrollingRef.current,
    estimateSize: () => 55,
    overscan: 20,
  })

  return (
    <AutocompleteOptions ref={scrollingRef}>
      <AutocompleteOption>
        {virtualizer.getVirtualItems().map(virtualItem => {
          const index = virtualItem.index
          const item = matchingCourses[index]

          if (!isDefined(item)) return null

          return (
            <DefaultStyledAutocompleteOption key={item.id} value={item.id}>
              <Text bold>{item.title}</Text>
            </DefaultStyledAutocompleteOption>
          )
        })}
      </AutocompleteOption>
    </AutocompleteOptions>
  )
}

export const ContentDomainAutoComplete: React.FC<{
  domainRep: UserProgramsDomainRep | UserGroupsDomainRep
}> = ({ domainRep }) => {
  const choices = domainRep.domain.choices

  const [query, setQuery] = React.useState('')
  const [matchingItems, setMatchingItems] = React.useState<InvitationDomainEntity[]>([])
  const [showPlaceholder, setShowPlaceholder] = React.useState(false)

  const { addOrReplaceAttributes, userAttributesConfig, editingEmails } = useComposeUserInvitationConfig()

  const { t, dynamicT } = useTranslation()

  const selectedItems: InvitationDomainEntity[] = useMemo(() => {
    const valueList = getCommonValueListDomainChoices(domainRep, userAttributesConfig, editingEmails)

    if (valueList === undefined) {
      //case if values are different
      setShowPlaceholder(true)
      return []
    }

    if (valueList.length === 0) {
      //case if common values are empty
      setShowPlaceholder(false)
      return []
    }

    setShowPlaceholder(false)
    return valueList.map(vRep => ({ id: valueId(vRep.value), title: labelToString(vRep.label, dynamicT) }))
  }, [dynamicT, domainRep, editingEmails, userAttributesConfig])

  const onSelectItem = React.useCallback(
    (item: InvitationDomainEntity) => {
      const attributeValues: UserInvitationAttribute['values'] = selectedItems.map(
        item => getValueRepFromDomainChoices(domainRep.domain, item.id).value
      )

      attributeValues.push(getValueRepFromDomainChoices(domainRep.domain, item.id).value)

      const attribute = createInvitationAttribute(domainRep.ref, attributeValues)

      addOrReplaceAttributes([attribute])
    },
    [addOrReplaceAttributes, domainRep.domain, domainRep.ref, selectedItems]
  )

  const onUnselectItem = React.useCallback(
    (item: InvitationDomainEntity) => {
      const attributeValues: UserInvitationAttribute['values'] = selectedItems
        .map(item => getValueRepFromDomainChoices(domainRep.domain, item.id).value)
        .filter(v => valueId(v) !== item.id) // Filter out the item we want to unselect

      // Replace the current attribute with the updated value list
      const attribute = createInvitationAttribute(domainRep.ref, attributeValues)

      addOrReplaceAttributes([attribute])
    },
    [addOrReplaceAttributes, domainRep.domain, domainRep.ref, selectedItems]
  )

  const updateMatchingItems = useCallback(() => {
    const matching =
      query.length === 0
        ? choices.filter(i => i.label.type === 'label.default' && i.label.label !== '')
        : choices.filter(i => {
            const label = labelToString(i.label, dynamicT).toLowerCase()

            const q = query.toLowerCase()

            return label.includes(q)
          })

    const excludeSelected = matching.filter(i => !selectedItems.some(s => s.id === valueId(i.value)))

    const matchingIds = excludeSelected
      .map(i => ({
        id: valueId(i.value),
        title: labelToString(i.label, dynamicT),
      }))
      .filter(m => !selectedItems.map(s => s.id).includes(m.id)) // Filter already selected items

    setMatchingItems(matchingIds)
  }, [choices, dynamicT, query, selectedItems])

  const onQueryChange = React.useCallback(
    (value: string) => {
      setQuery(value)
      updateMatchingItems()
    },
    [updateMatchingItems]
  )

  useEffect(() => {
    updateMatchingItems()
  }, [updateMatchingItems])

  return (
    <View>
      <Autocomplete
        query={query}
        onQueryChange={onQueryChange}
        matchingItems={matchingItems}
        selectedItems={selectedItems}
        onSelect={onSelectItem}
        onUnselect={onUnselectItem}
        placeholder={showPlaceholder ? t('manage.users.invite.bulk-editing.mixed') : ''}
        renderSelectedItem={(course, { onUnselect, ...props }) => {
          return (
            <GreyPill key={course.id} {...props}>
              <View gap='6'>
                <Text bold>{course.title}</Text>
              </View>
              <RemoveButtonInPills onClick={onUnselect} aria-label={t('dictionary.remove')} />
            </GreyPill>
          )
        }}
        renderMatchingItemList={() => (
          <VirtualizedListOfInvitationDomainEntity matchingCourses={matchingItems} />
        )}
      />
    </View>
  )
}
