import { Logger } from 'sierra-client/core/logging/logger'
import { InfoCard } from 'sierra-domain/api/infocard'

export type HomeRecommendationImpressionProps = {
  type: InfoCard['type']
}

export const homeRecommendationImpression = Logger.trackLoggerWithExtra<
  HomeRecommendationImpressionProps,
  HomeRecommendationImpressionProps
>('home_recommendation_impression', input => ({ ...input }))

export type HomeRecommendationClickProps = {
  type: InfoCard['type']
}

export const homeRecommendationClicked = Logger.trackLoggerWithExtra<
  HomeRecommendationClickProps,
  HomeRecommendationClickProps
>('home_recommendation_clicked', input => ({ ...input }))
