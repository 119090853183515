import { FC, useMemo } from 'react'
import { FileIcon } from 'sierra-client/views/flexible-content/editor/content-sidebar/icons'
import { useMultiSelection } from 'sierra-client/views/flexible-content/editor/content-sidebar/multi-selection/hooks/use-multi-selection'
import { AssetContext } from 'sierra-domain/asset-context'
import { File as FileType } from 'sierra-domain/flexible-content/types'
import { Icon, IconId } from 'sierra-ui/components'
import { token } from 'sierra-ui/theming'
import styled from 'styled-components'

const Stack = styled.div`
  position: relative;
`

const NodeWrapper = styled.div`
  display: flex;
  background-color: ${token('surface/default')};
  border-radius: 10px;
  box-shadow:
    0px 2px 4px 0px #00000014,
    0px 0px 0px 1px #0000000a;
  padding: 10px;
  gap: 8px;
  width: fit-content;
  max-width: 270px;
  width: 196px;
  align-items: center;
  overflow: hidden;
  transform: translate3D(0px, 0px, 10px);
  position: relative;
  z-index: 9999;
`

const GhostFile = styled(NodeWrapper)<{ $order: number }>`
  position: absolute;
  top: 0;
  left: 2px;
  height: 100%;
  transform-origin: top left;
  transform: translate3D(0px, 0px, -1px) rotate(${p => `calc(${p.$order} * 2deg)`});
  z-index: ${p => 3 - p.$order};
`

const Title = styled.p`
  font-size: 14px;
  color: ${token('foreground/primary')};
  white-space: nowrap;
  text-overflow: ellipsis;
  overflow: hidden;
  font-weight: bold;
`

type FolderProps = { type: 'folder'; iconId: IconId; title: string }
type FileProps = { type: 'file'; file: FileType; title: string; assetContext: AssetContext }

const Folder: React.FC<FolderProps> = ({ iconId, title }) => (
  <NodeWrapper>
    <Icon iconId={iconId} />
    <Title>{title}</Title>
  </NodeWrapper>
)

const File: React.FC<FileProps> = ({ file, title, assetContext }) => {
  const { selectionCount } = useMultiSelection()

  const ghosts = useMemo(
    () =>
      Array.from({ length: selectionCount })
        .slice(0, 3)
        .map((_, index) => {
          if (index === 0) {
            return null
          }
          return <GhostFile key={index} $order={index} />
        }),
    [selectionCount]
  )

  return (
    <>
      {ghosts}
      <NodeWrapper>
        <FileIcon file={file} size='micro' assetContext={assetContext} />
        <Title>{title}</Title>
      </NodeWrapper>
    </>
  )
}

export const NodeGhostContent: FC<FolderProps | FileProps> = props => (
  <Stack>{props.type === 'file' ? <File {...props} /> : <Folder {...props} />}</Stack>
)
