import { graphql } from 'sierra-client/api/graphql/gql'
import { graphQuery } from 'sierra-client/api/hooks/use-graphql-query'
import { UserId } from 'sierra-domain/api/uuid'

export const updateCourseCompletions = (): ((courseIds: string[], userId: UserId) => Promise<void>) => {
  const completeCourses = async (courseIds: string[], userId: UserId): Promise<void> => {
    await graphQuery(
      graphql(`
        mutation completeCourses($courseIds: [CourseId!]!, $userId: UserId!) {
          completeCourses(courseIds: $courseIds, targetUserId: $userId)
        }
      `),
      {
        courseIds,
        userId,
      }
    )
  }

  return completeCourses
}
