import React, { useMemo, useState } from 'react'
import { ChipInputWithAutocomplete } from 'sierra-client/components/common/chip-input-with-autocomplete'
import { Pill } from 'sierra-client/components/common/pill'
import { useOrderedTags } from 'sierra-client/hooks/use-ordered-tags'
import { useTranslation } from 'sierra-client/hooks/use-translation'
import { TagData } from 'sierra-domain/content/tag'
import { Entity } from 'sierra-domain/entity'
import { FormElement } from 'sierra-ui/components'
import { createMUIAutocompleteFilterOptions } from 'sierra-ui/mui'
import { Text, View } from 'sierra-ui/primitives'
import styled from 'styled-components'

const StyledAutocomplete = styled(ChipInputWithAutocomplete)`
  flex: 1;
`

const defaultFilterOptions = createMUIAutocompleteFilterOptions()

type EmptyOption = { element: JSX.Element }
type SkillOption = Entity<TagData>

type Props = {
  currentTags: string[]
  onTagChange: (skills: string[]) => void
  onBlur?: () => void
  error?: boolean
  helperText?: string
}

export const TagInput: React.FC<Props> = ({
  currentTags,
  onTagChange,
  error = false,
  helperText = '',
  onBlur,
}) => {
  const { t } = useTranslation()

  const [tagInputValue, setTagInputValue] = useState('')

  const orderedTags = useOrderedTags()

  const skillOptions: SkillOption[] = useMemo(() => {
    return orderedTags.filter(s => s.name !== '')
  }, [orderedTags])

  const selectedSkills = skillOptions.filter(({ id }) => currentTags.includes(id))

  return (
    <FormElement label={t('table.skills')} htmlFor='skill-input'>
      <StyledAutocomplete
        id='skill-input'
        options={skillOptions}
        getOptionLabel={option => (option as SkillOption).name}
        value={selectedSkills}
        onChange={(_, values) => onTagChange((values as SkillOption[]).map(option => option.id))}
        inputPlaceholder={t('author.course-settings.skill-placeholder')}
        inputValue={tagInputValue}
        onInputChange={(event, value, reason) => {
          if (reason === 'reset') return
          setTagInputValue(value)
        }}
        filterOptions={(options, state) => {
          const results = defaultFilterOptions(options, state)

          if (results.length === 0) {
            return [
              {
                element: (
                  <View gap='none'>
                    <Text color='foreground/muted' size='small'>
                      {t('course-settings.no-matches')}
                    </Text>
                  </View>
                ),
              },
            ]
          }

          return results
        }}
        closeIcon={<></>}
        onClose={() => setTagInputValue('')}
        renderOption={(option, state) => {
          const emptyOrSkill = option as SkillOption | EmptyOption
          // if 'type' is in emptyOrSkill, means it's empty
          if ('element' in emptyOrSkill) return emptyOrSkill.element
          const skill = emptyOrSkill
          return (
            <Pill key={skill.id} variant='outlined' active={state.selected} onClick={() => {}}>
              {skill.name}
            </Pill>
          )
        }}
        renderTags={(value, getTagProps) => {
          const skills = value as SkillOption[]
          return skills.map((skill, index) => {
            const tagProps = getTagProps({ index }) as { onDelete: () => void }
            return (
              <Pill
                key={skill.id}
                variant='outlined'
                active={false}
                iconId='close'
                onClick={tagProps.onDelete}
              >
                {skill.name}
              </Pill>
            )
          })
        }}
        error={error}
        helperText={helperText}
        onBlur={onBlur}
        limitTags={4}
        compactOptions
        clearOnEscape
        multiple
      />
    </FormElement>
  )
}
