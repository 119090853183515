import React, { useState } from 'react'
import { ChipInputWithAutocomplete } from 'sierra-client/components/common/chip-input-with-autocomplete'
import { Pill } from 'sierra-client/components/common/pill'
import { useTranslation } from 'sierra-client/hooks/use-translation'
import { InputGroup } from 'sierra-ui/components'
import { createMUIAutocompleteFilterOptions } from 'sierra-ui/mui'
import { Text, View } from 'sierra-ui/primitives'
import styled from 'styled-components'

const StyledAutocomplete = styled(ChipInputWithAutocomplete)`
  flex: 1;
`

const defaultFilterOptions = createMUIAutocompleteFilterOptions()

type EmptyOption = { element: JSX.Element }
export type ContentOption = { id: string; title: string; type: string }

type Props = {
  currentContent: string[]
  contentOptions: ContentOption[]
  onContentChange: (content: string[]) => void
  onBlur?: () => void
  error?: boolean
  helperText?: string
  inputPlaceholder?: string
}

//TODO(ludvig): Sort options so they are grouped correctly
export const FilterInput: React.FC<Props> = ({
  currentContent,
  contentOptions,
  onContentChange,
  error = false,
  helperText = '',
  inputPlaceholder,
  onBlur,
}) => {
  const { t } = useTranslation()

  const [inputValue, setInputValue] = useState('')

  const selectedContent = contentOptions.filter(({ id }) => currentContent.includes(id))

  const getTranslationString = (type: string): string => {
    switch (type) {
      case 'course':
        return t('dictionary.course-plural')
      case 'path':
        return t('dictionary.path-plural')
      case 'program':
        return t('dictionary.program-plural')
      case 'usergroup':
        return t('dictionary.group-plural')
      default:
        return ''
    }
  }

  return (
    <InputGroup $spacing='none'>
      <StyledAutocomplete
        id='report-filter-input'
        options={contentOptions}
        groupBy={option => getTranslationString((option as ContentOption).type)}
        getOptionLabel={option => (option as ContentOption).title}
        value={selectedContent}
        onChange={(_, values) => onContentChange((values as ContentOption[]).map(option => option.id))}
        inputPlaceholder={inputPlaceholder}
        inputValue={inputValue}
        onInputChange={(event, value, reason) => {
          if (reason === 'reset') return
          setInputValue(value)
        }}
        filterOptions={(options, state) => {
          const results = defaultFilterOptions(options, state)

          if (results.length === 0) {
            return [
              {
                element: (
                  <View alignItems='flex-start' gap='none'>
                    <Text color='foreground/muted' size='small'>
                      {t('course-settings.no-matches')}
                    </Text>
                  </View>
                ),
              },
            ]
          }

          return results
        }}
        closeIcon={<></>}
        onClose={() => setInputValue('')}
        renderOption={(option, state) => {
          const emptyOrContent = option as ContentOption | EmptyOption
          // if 'type' is in emptyOrContent, means it's empty
          if ('element' in emptyOrContent) return emptyOrContent.element
          const content = emptyOrContent
          return (
            <Pill key={content.id} variant='outlined' active={state.selected} onClick={() => {}}>
              {content.title}
            </Pill>
          )
        }}
        renderTags={(value, getTagProps) => {
          const contents = value as ContentOption[]
          return contents.map((content, index) => {
            const tagProps = getTagProps({ index }) as { onDelete: () => void }
            return (
              <Pill
                key={content.id}
                variant='outlined'
                active={false}
                iconId={'close'}
                onClick={tagProps.onDelete}
              >
                {content.title}
              </Pill>
            )
          })
        }}
        error={error}
        helperText={helperText}
        onBlur={onBlur}
        limitTags={4}
        compactOptions
        clearOnEscape
        multiple
      />
    </InputGroup>
  )
}
