import { useAtomValue } from 'jotai/index'
import { ChangeEvent, FC, useEffect, useRef, useState } from 'react'
import { useCoursePermissionSettings } from 'sierra-client/api/hooks/use-course-permission'
import { useCurrentUser } from 'sierra-client/api/hooks/use-user'
import { mapUserToIdentity } from 'sierra-client/components/common/identities-selector/identities-utils'
import {
  useAdminIdentitiesFetcher,
  useFacilitatorIdentitiesFetcher,
} from 'sierra-client/components/common/identities-selector/identity-fetchers'
import { Link } from 'sierra-client/components/common/link'
import { ListSessions } from 'sierra-client/features/sana-now/create-session-panel/list-sessions'
import { MeetingIntegrationPicker } from 'sierra-client/features/sana-now/create-session-panel/meeting-integration-picker'
import type { PanelState } from 'sierra-client/features/sana-now/create-session-panel/panel-state'
import { savedVideoCallSettingTypeAtom } from 'sierra-client/features/sana-now/create-session-panel/saved-video-call-setting-type-atom'
import {
  SessionFormData,
  useSessionFormData,
} from 'sierra-client/features/sana-now/create-session-panel/session-form-state'
import { getMaxParticipantsInLive } from 'sierra-client/hooks/use-max-participants-in-live'
import { useContentKindPermissions, useLiveSessionPermissions } from 'sierra-client/hooks/use-permissions'
import { useTranslation } from 'sierra-client/hooks/use-translation'
import { setUnion } from 'sierra-client/lib/querystate/utils'
import { useInvalidateCachedQuery, useTypedMutation } from 'sierra-client/state/api'
import {
  CalendarEventUserSelector,
  EventParticipantSelectorProps,
} from 'sierra-client/views/manage/event-groups/components/calendar-event-user-selector'
import { EventScheduleForm } from 'sierra-client/views/manage/event-groups/components/event-schedule-form'
import { withPanel } from 'sierra-client/views/manage/utils/with-modal'
import { LiveContentId, LiveSessionId } from 'sierra-domain/api/nano-id'
import {
  XRealtimeAuthorLiveSessionsDeleteLiveSession,
  XRealtimeAuthorLiveSessionsListLiveSessions,
  XRealtimeAuthorLiveSessionsUpsertLiveSession,
  XRealtimeAuthorSetCoursePermissionSettings,
} from 'sierra-domain/routes'
import { assertIsNonNullable } from 'sierra-domain/utils'
import { FormElement, Icon } from 'sierra-ui/components'
import { Button, Heading, IconButton, InputPrimitive, Spacer, Switch, Text, View } from 'sierra-ui/primitives'
import { token } from 'sierra-ui/theming'
import styled from 'styled-components'

const ACTION_BUTTONS_CONTAINER_HEIGHT_PX = 100
const GRADIENT_HEIGHT_PX = 20

const Container = styled(View).attrs({
  direction: 'column',
  grow: true,
  justifyContent: 'flex-start',
  gap: 'none',
})`
  position: relative;
  padding: 32px 40px 0;
`

const HorizontalDivider = styled.hr`
  width: 100%;
  height: 1px;
  background-color: ${token('border/default')};
  flex-shrink: 0;
`

const ActionButtonsContainer = styled(View).attrs({ direction: 'row', justifyContent: 'space-between' })`
  position: sticky;
  height: ${ACTION_BUTTONS_CONTAINER_HEIGHT_PX}px;
  background: ${token('surface/default')};
  bottom: 0;
  left: 0;
  right: 0;

  ::after {
    content: '';
    position: absolute;
    left: 0;
    right: 0;
    top: -${GRADIENT_HEIGHT_PX}px;
    height: ${GRADIENT_HEIGHT_PX}px;
    background: linear-gradient(to top, rgba(255, 255, 255, 1), rgba(255, 255, 255, 0));
  }
`

const LearnMoreLink = styled(Link)`
  color: ${token('foreground/muted')};
`

const DeleteView = styled(View)``

const CreateOrUpdateSessionPanelContent: FC<{
  onClose: () => void
  liveContentId: LiveContentId
  liveSessionId?: LiveSessionId
  canDelete: boolean
  canEditMetadata: boolean
  canEditAssignment: boolean
  initialData?: Partial<SessionFormData>
  onSessionCreated?: (liveSessionId: LiveSessionId) => void
}> = ({
  liveContentId,
  liveSessionId,
  onClose,
  initialData,
  canDelete,
  canEditAssignment,
  canEditMetadata,
  onSessionCreated,
}) => {
  const { t } = useTranslation()
  const currentUser = useCurrentUser().data
  assertIsNonNullable(currentUser)

  const isEditing = initialData !== undefined
  const savedVideoCallSettingTypeAtomValue = useAtomValue(savedVideoCallSettingTypeAtom)
  const savedVideoCallSettingType = isEditing ? undefined : savedVideoCallSettingTypeAtomValue

  const maxParticipants = getMaxParticipantsInLive()

  const invalidateListQuery = useInvalidateCachedQuery(XRealtimeAuthorLiveSessionsListLiveSessions, {
    flexibleContentId: liveContentId,
  })

  const { updateValue, values, validationResult, runValidate } = useSessionFormData(
    liveContentId,
    initialData
  )

  const [moreSettingsOpen, setMoreSettingsOpen] = useState(false)
  const [isMeetingToolAuthenticated, setIsMeetingToolAuthenticated] = useState(true)
  const [isMeetingToolLoading, setIsMeetingToolLoading] = useState(false)

  const setSelectedParticipantIdentities: EventParticipantSelectorProps['setSelectedIdentities'] = update => {
    const updatedIdentities = update(values.participants)
    updateValue('participants', updatedIdentities)
  }

  const setSelectedFacilitatorIdentities: EventParticipantSelectorProps['setSelectedIdentities'] = update => {
    const updatedIdentities = update(values.facilitators)
    if (updatedIdentities.length === 0) {
      updatedIdentities.push(mapUserToIdentity(currentUser))
    }
    updateValue('facilitators', updatedIdentities)
  }

  const fetchParticipantIdentities = useAdminIdentitiesFetcher()
  const fetchFacilitatorIdentities = useFacilitatorIdentitiesFetcher()

  const courseVisibilityQuery = useCoursePermissionSettings(liveContentId)

  const upsertLiveSessionMutation = useTypedMutation(XRealtimeAuthorLiveSessionsUpsertLiveSession)
  const updateCoursePermissionsMutation = useTypedMutation(XRealtimeAuthorSetCoursePermissionSettings)
  const deleteSessionMutation = useTypedMutation(XRealtimeAuthorLiveSessionsDeleteLiveSession)

  const moreSettingsRef = useRef<HTMLDivElement>(null)

  useEffect(() => {
    if (moreSettingsOpen) {
      // TODO: Do we want this scroll effect?
      moreSettingsRef.current?.scrollIntoView({ behavior: 'smooth' })
    }
  }, [moreSettingsOpen])

  const handleSchedule = (): void => {
    const result = runValidate()
    if (Object.values(result).some(r => !r.isValid)) {
      console.error('Form validation failed', result)
      return
    }
    if (courseVisibilityQuery.data === undefined) return

    // To prevent time zone bugs with all-day sessions we set their zone to UTC at midnight
    // What could happens otherwise is:
    //   send time 00:15 in +1 timezone on date 2nd
    //   backend parses to instant, which is utc, we get 23:15 on date 1st
    //   all day event is on wrong day
    const startISOString =
      values.schedule.type === 'all-day'
        ? values.schedule.startDate.startOf('day').setZone('UTC', { keepLocalTime: true }).toISO()
        : values.schedule.startTime.toISO()

    const endISOString =
      values.schedule.type === 'all-day'
        ? values.schedule.endDate.startOf('day').setZone('UTC', { keepLocalTime: true }).toISO()
        : values.schedule.endTime.toISO()

    const facilitatorIds = values.facilitators.flatMap(f =>
      f.identity.type === 'user' ? [f.identity.id] : []
    )
    const participantIds = values.participants.map(p => p.identity.id)

    if (participantIds.length > 0 && courseVisibilityQuery.data.visibilityInOrg === 'private') {
      updateCoursePermissionsMutation.mutate({
        courseId: liveContentId,
        visibilityInOrg: 'visible-admins',
        shareLinkAccessLevel: courseVisibilityQuery.data.shareLinkAccessLevel,
      })
    }

    upsertLiveSessionMutation.mutate(
      {
        liveSessionId: liveSessionId,
        liveSessionType: 'scheduled',
        participantIds: Array.from(setUnion(new Set(participantIds), new Set(facilitatorIds))),
        data: {
          facilitatorIds: facilitatorIds,
          allDay: values.schedule.type === 'all-day',
          endTime: endISOString,
          startTime: startISOString,
          allowGuestAccess: values.allowGuestAccess,
          disallowEarlyJoin: values.disallowEarlyJoin,
          enableRecap: values.enableRecap,
          hideTranscription: values.hideTranscription,
          hideFromSelfEnrollment: !values.enableSelfEnrollment,
          location: values.location !== undefined ? { type: 'physical', value: values.location } : undefined,
          maxNumberOfUsers: values.maxNumberOfUsers,
          title: values.title,
          flexibleContentId: liveContentId,
          learnerLedSession: values.learnerLedSession,
          transcribeSession: values.transcribeSession,
          videoCallSetting: values.videoCallSetting,
          type: 'scheduled',
        },
      },
      {
        onSuccess: async res => {
          await invalidateListQuery()
          onSessionCreated?.(res.liveSessionId)
          onClose()
        },
      }
    )
  }

  return (
    <Container>
      <Heading size='h5' bold>
        {isEditing ? t('admin.author.sessions.edit') : t('admin.author.sessions.create')}
      </Heading>

      <Spacer size='24' />

      <FormElement
        helper={!validationResult.title.isValid ? validationResult.title.errorMessage : undefined}
        isError={!validationResult.title.isValid}
        grow={false}
        label={t('dictionary.title')}
      >
        <InputPrimitive
          placeholder={t('manage.events.session-name')}
          id='session-title'
          value={values.title}
          onChange={(e: ChangeEvent<HTMLInputElement>) => updateValue('title', e.target.value)}
          disabled={!canEditMetadata}
        />
      </FormElement>

      <Spacer size='16' />

      <EventScheduleForm
        disabled={!canEditMetadata}
        schedule={values.schedule}
        updateSchedule={updated => updateValue('schedule', updated)}
        hideTimeZoneInput
      />

      <Spacer size='16' />

      <MeetingIntegrationPicker
        onChange={videoCallSetting => updateValue('videoCallSetting', videoCallSetting)}
        videoCallSetting={values.videoCallSetting}
        defaultVideoCallSettingChoice={savedVideoCallSettingType}
        isValid={validationResult.videoCallSetting.isValid}
        isLoading={isMeetingToolLoading}
        disabled={!canEditMetadata}
        onAuthStatusChange={setIsMeetingToolAuthenticated}
        onLoadingChange={setIsMeetingToolLoading}
      />

      <Spacer size='16' />

      <FormElement grow={false} label={t('dictionary.facilitators')}>
        <CalendarEventUserSelector
          selectedIdentities={values.facilitators}
          setSelectedIdentities={setSelectedFacilitatorIdentities}
          fetchIdentities={fetchFacilitatorIdentities}
          disabled={!canEditMetadata}
        />
      </FormElement>

      <Spacer size='16' />

      <FormElement grow={false} label={t('dictionary.invite-people')}>
        <CalendarEventUserSelector
          selectedIdentities={values.participants}
          setSelectedIdentities={setSelectedParticipantIdentities}
          fetchIdentities={fetchParticipantIdentities}
          disabled={!canEditAssignment}
        />
      </FormElement>

      <Spacer size='6' />

      <View justifyContent='space-between'>
        <View>
          <Icon iconId='building' color='foreground/muted' />
          <Text size='micro' color='foreground/muted'>
            {t('admin.author.create-session-panel.inviting-user-will-make-visible-in-manage-explanation')}
          </Text>
        </View>
        <LearnMoreLink
          target='_blank'
          rel='noopener noreferrer'
          href='https://help.sana.ai/'
          size='micro'
          bold
        >
          {t('content.learn-more')}
        </LearnMoreLink>
      </View>
      <Spacer size='32' />
      <HorizontalDivider />
      <Spacer size='32' />

      <View direction='column' gap='16'>
        <View direction='row' justifyContent={'space-between'}>
          <View direction='column' gap='2'>
            <Text size='small' bold>
              {t('admin.author.create-session-panel.learned-led-session-toggle--title')}
            </Text>
            <Text size='micro' color='foreground/muted'>
              {t('admin.author.create-session-panel.learned-led-session-toggle--description')}
            </Text>
          </View>
          <div>
            <Switch
              checked={values.learnerLedSession}
              onChange={() => updateValue('learnerLedSession', !values.learnerLedSession)}
              ariaLabel={t('admin.author.create-session-panel.learned-led-session-toggle--title')}
              disabled={!canEditMetadata}
            />
          </div>
        </View>

        <View direction='row' justifyContent={'space-between'}>
          <View direction='column' gap='2'>
            <Text size='small' bold>
              {t('admin.author.create-session-panel.allow-guest-access--title')}
            </Text>
            <Text size='micro' color='foreground/muted'>
              {t('admin.author.create-session-panel.allow-guest-access--description')}
            </Text>
          </View>
          <div>
            <Switch
              checked={values.allowGuestAccess}
              onChange={checked => updateValue('allowGuestAccess', checked)}
              ariaLabel={t('admin.author.create-session-panel.allow-guest-access--title')}
              disabled={!canEditMetadata}
            />
          </div>
        </View>

        <View direction='row' justifyContent={'space-between'}>
          <View direction='column' gap='2'>
            <Text size='small' bold>
              {t('admin.author.create-session-panel.transcribe-session--title')}
            </Text>
            <Text size='micro' color='foreground/muted'>
              {t('admin.author.create-session-panel.transcribe-session--description')}
            </Text>
          </View>
          <div>
            <Switch
              checked={values.transcribeSession}
              onChange={checked => updateValue('transcribeSession', checked)}
              ariaLabel={t('admin.author.create-session-panel.transcribe-session--title')}
              disabled={!canEditMetadata}
            />
          </div>
        </View>
      </View>

      {moreSettingsOpen && (
        <>
          <Spacer size='32' />
          <HorizontalDivider />
          <Spacer size='32' />

          <View ref={moreSettingsRef} direction='column' gap='32'>
            <View>
              <Icon color='foreground/muted' iconId='settings' />
              <Text color='foreground/muted' bold>
                {t('admin.author.create-session-panel.more-options')}
              </Text>
            </View>

            <FormElement grow={false} label={t('admin.author.sessions.location')}>
              <InputPrimitive
                placeholder={t('admin.author.sessions.location-placeholder')}
                id='session-location'
                value={values.location ?? ''}
                onChange={(e: ChangeEvent<HTMLInputElement>) => updateValue('location', e.target.value)}
                disabled={!canEditMetadata}
              />
            </FormElement>

            <View direction='column' gap='16'>
              <View direction='row' justifyContent={'space-between'}>
                <View direction='column' gap='2'>
                  <Text size='small' bold>
                    {t('admin.author.create-session-panel.session-capacity--title')}
                  </Text>
                  <Text size='micro' color='foreground/muted'>
                    {t('admin.author.create-session-panel.session-capacity--description')}
                  </Text>
                </View>
                <div>
                  <InputPrimitive
                    disabled={!canEditMetadata}
                    type='number'
                    placeholder={t('admin.author.create-session-panel.participant-limit', {
                      count: maxParticipants,
                    })}
                    id='session-capacity'
                    value={`${values.maxNumberOfUsers}`}
                    onChange={(e: ChangeEvent<HTMLInputElement>) => {
                      if (e.target.value === '') {
                        updateValue('maxNumberOfUsers', undefined)
                        return
                      }

                      const newLimit = Number.parseInt(e.target.value)
                      if (!Number.isNaN(newLimit) && newLimit > 0 && newLimit <= maxParticipants) {
                        updateValue('maxNumberOfUsers', newLimit)
                      }
                    }}
                  />
                </div>
              </View>

              <View direction='row' justifyContent={'space-between'}>
                <View direction='column' gap='2'>
                  <Text size='small' bold>
                    {t('admin.author.create-session-panel.enable-self-enrollment--title')}
                  </Text>
                  <Text size='micro' color='foreground/muted'>
                    {t('admin.author.create-session-panel.enable-self-enrollment--description')}
                  </Text>
                </View>
                <div>
                  <Switch
                    checked={values.enableSelfEnrollment}
                    onChange={checked => updateValue('enableSelfEnrollment', checked)}
                    ariaLabel={t('admin.author.create-session-panel.enable-self-enrollment--title')}
                    disabled={!canEditMetadata}
                  />
                </div>
              </View>

              <View direction='row' justifyContent={'space-between'}>
                <View direction='column' gap='2'>
                  <Text size='small' bold>
                    {t('admin.author.create-session-panel.enable-recap--title')}
                  </Text>
                  <Text size='micro' color='foreground/muted'>
                    {t('admin.author.create-session-panel.enable-recap--description')}
                  </Text>
                </View>
                <div>
                  <Switch
                    checked={values.enableRecap}
                    onChange={checked => updateValue('enableRecap', checked)}
                    ariaLabel={t('admin.author.create-session-panel.enable-recap--title')}
                    disabled={!canEditMetadata}
                  />
                </div>
              </View>
            </View>

            {liveSessionId !== undefined && (
              <DeleteView
                alignItems='center'
                padding='8'
                radius='regular'
                borderColor='border/default'
                gap='24'
              >
                <View direction='column'>
                  <Text size='small' bold>
                    {t('admin.author.sessions.delete-session')}
                  </Text>
                  <Text size='small' color='foreground/muted'>
                    {t('admin.author.sessions.deleting-a-session-info')}
                  </Text>
                </View>
                <Button
                  variant='destructive'
                  disabled={!canDelete}
                  loading={deleteSessionMutation.isPending}
                  onClick={() =>
                    deleteSessionMutation.mutate(
                      {
                        liveSessionId: liveSessionId,
                      },
                      {
                        onSuccess: async () => {
                          await invalidateListQuery()
                          onClose()
                        },
                      }
                    )
                  }
                >
                  {t('dictionary.delete')}
                </Button>
              </DeleteView>
            )}
          </View>
        </>
      )}

      <Spacer size='32' />

      <ActionButtonsContainer>
        {!moreSettingsOpen ? (
          <Button onClick={() => setMoreSettingsOpen(true)} variant={'secondary'}>
            {t('dictionary.more-options')}
          </Button>
        ) : (
          // Empty div to keep the other buttons aligned to the right
          <div />
        )}
        <View>
          <Button variant={'secondary'} onClick={onClose}>
            {t('dictionary.cancel')}
          </Button>
          <Button
            loading={
              updateCoursePermissionsMutation.isPending ||
              upsertLiveSessionMutation.isPending ||
              isMeetingToolLoading
            }
            variant={'success'}
            onClick={handleSchedule}
            disabled={!isMeetingToolAuthenticated || isMeetingToolLoading}
          >
            {isEditing ? t('modal.save-changes') : t('dictionary.schedule-singular')}
          </Button>
        </View>
      </ActionButtonsContainer>
    </Container>
  )
}

const CreatePanelContent = ({
  onClose,
  liveContentId,
  state,
}: {
  onClose: () => void
  liveContentId: LiveContentId
  state: PanelState & { type: 'create-session' }
}): JSX.Element => {
  const liveContentPermissions = useContentKindPermissions(liveContentId)

  return (
    <CreateOrUpdateSessionPanelContent
      onClose={onClose}
      liveContentId={liveContentId}
      canDelete={true}
      canEditMetadata={true}
      canEditAssignment={liveContentPermissions.has('EDIT_ASSIGNMENTS')}
      initialData={state.initialData}
      onSessionCreated={state.onSessionCreated}
    />
  )
}

const EditPanelContent = ({
  onClose,
  liveContentId,
  state,
}: {
  onClose: () => void
  liveContentId: LiveContentId
  state: PanelState & { type: 'update-session' }
}): JSX.Element => {
  const liveSessionPermission = useLiveSessionPermissions(state.liveSessionId)
  const canDelete = liveSessionPermission.has('DELETE')
  const canEditMetadata = liveSessionPermission.has('EDIT_METADATA')
  const canEditAssignment = liveSessionPermission.has('EDIT_ASSIGNMENTS')

  return (
    <CreateOrUpdateSessionPanelContent
      onClose={onClose}
      liveContentId={liveContentId}
      canDelete={canDelete}
      canEditMetadata={canEditMetadata}
      canEditAssignment={canEditAssignment}
      initialData={state.initialData}
      liveSessionId={state.liveSessionId}
    />
  )
}

const CloseIconContainer = styled.div`
  position: absolute;
  top: 16px;
  right: 16px;
`

export const ManageSessionsPanel = withPanel<{
  liveContentId: LiveContentId
  state: PanelState
  setState: (newState: PanelState) => void
}>(
  {
    size: { width: 656 },
    disableScrollbarGutter: true,
    overlayVariant: 'light',
  },
  ({ onClose: onClosePanel, liveContentId, state, setState }) => {
    const onCloseEditPanel = (): void => setState({ type: 'list-sessions' })

    return (
      <>
        {state.type === 'create-session' && (
          <CreatePanelContent onClose={onCloseEditPanel} liveContentId={liveContentId} state={state} />
        )}

        {state.type === 'update-session' && (
          <EditPanelContent onClose={onCloseEditPanel} liveContentId={liveContentId} state={state} />
        )}

        {state.type === 'list-sessions' && <ListSessions liveContentId={liveContentId} setState={setState} />}

        <CloseIconContainer>
          <IconButton variant='transparent' size='small' iconId='close' onClick={onClosePanel} />
        </CloseIconContainer>
      </>
    )
  }
)
