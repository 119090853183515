import { useEffect, useState } from 'react'
import {
  RichBadgeBackgroundSvg,
  RichBadgeForegroundSvg,
} from 'sierra-client/features/skills/components/badges/svgs'
import { getThemeItemColor, richBadgeThemeMap } from 'sierra-client/features/skills/components/badges/themes'
import { BadgeTheme, type BadgeIconId } from 'sierra-client/features/skills/components/badges/types'
import { TruncatedText } from 'sierra-ui/components'
import { iconMap } from 'sierra-ui/components/icon/generated-icon-map'
import styled from 'styled-components'

type AnimationValues = {
  radialX: number
  radialY: number
  shadowX: number
  shadowY: number
  rotateX: number
  rotateY: number
  iconProjectedShadowOpacity: number
}

const initialValues: Readonly<AnimationValues> = {
  rotateX: 0,
  rotateY: 0,
  radialX: 0,
  radialY: 0,
  shadowX: -1,
  shadowY: -1,
  iconProjectedShadowOpacity: 0,
}

const Container = styled.div`
  position: relative;
  width: 100%;
  height: 100%;

  svg {
    display: block;
  }

  --radial-x: ${initialValues.radialX}%;
  --radial-y: ${initialValues.radialY}%;

  --shadow-x: ${initialValues.shadowX}px;
  --shadow-y: ${initialValues.shadowY}px;

  --rotate-x: ${initialValues.rotateX}deg;
  --rotate-y: ${initialValues.rotateY}deg;

  --title-font-size: 10px;
  --title-letter-spacing: 1px;
  --subtitle-font-size: 8px;
  --subtitle-letter-spacing: 0.5px;
  --depth-ratio: 1;
  --icon-projected-shadow-opacity: ${initialValues.iconProjectedShadowOpacity};

  transform: rotateX(var(--rotate-x)) rotateY(var(--rotate-y));
  transform-style: preserve-3d;
  will-change: transform;
  perspective: 1000px;
`

const BackgroundLayer = styled.div`
  position: relative;
`

const RadialGradientBackgroundContainer = styled.div<{ $background: string; $backgroundColor: string }>`
  position: absolute;
  inset: 0;
  clip-path: url(#rich-badge-background-clip);
  background: ${p => getThemeItemColor(p.$background)};
  background-color: ${p => getThemeItemColor(p.$backgroundColor)};
  background-blend-mode: overlay;
`

const LinearGradientForegroundContainer = styled.div<{ $color: string }>`
  position: absolute;
  inset: 0;
  clip-path: url(#rich-badge-foreground-clip);
  background: ${p => getThemeItemColor(p.$color)};
`

const ForegroundLayer = styled.div<{ $dropShadow: string }>`
  position: absolute;
  inset: 0;
  width: 100%;
  filter: ${p => p.$dropShadow};

  transform: translateZ(calc(10px * var(--depth-ratio)));
`

const IconLayer = styled.div<{ $color: string; $dropShadow: string }>`
  position: absolute;
  inset: 0;
  height: 58%;
  padding-top: 18%;
  color: ${p => getThemeItemColor(p.$color)};
  filter: ${p => p.$dropShadow}
    drop-shadow(
      calc(-10 * var(--shadow-x)) calc(-10 * var(--shadow-y)) 12px
        rgba(0, 0, 0, var(--icon-projected-shadow-opacity))
    );

  transform: translateZ(calc(30px * var(--depth-ratio)));

  svg {
    width: 100%;
    height: 100%;
    fill: ${p => getThemeItemColor(p.$color)};
  }
`

const TextLayer = styled.div`
  position: absolute;
  width: 100%;
  bottom: 0;
  height: 33%;
  padding-top: 4%;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  text-align: center;
  gap: 4%;

  transform: translateZ(calc(20px * var(--depth-ratio)));
`

const TitleContainer = styled.div`
  padding-inline: 16%;
  font-size: var(--title-font-size);
  letter-spacing: var(--title-letter-spacing);
`

const SubtitleContainer = styled.div`
  padding-inline: 30%;
  font-size: var(--subtitle-font-size);
  letter-spacing: var(--subtitle-letter-spacing);
`

const StyledText = styled(TruncatedText)<{ $color: string }>`
  color: ${p => getThemeItemColor(p.$color)};
  font-size: inherit;
`

// Shadow appears thicker when badge is still and thinner when badge is moving
function dropShadowWidth(width: number): number {
  return Math.max(0.6, width * 0.003)
}

function animatedDropShadowWidth(width: number): number {
  return Math.max(0.6, width * 0.01)
}

type BadgeProps = {
  theme: BadgeTheme
  title: string
  subtitle: string
  iconId: BadgeIconId
}

export const RichBadge: React.FC<
  BadgeProps & {
    onMouseEnter?: (_: BadgeProps) => void
    onMouseLeave?: (_: BadgeProps) => void
  }
> = ({ onMouseEnter, onMouseLeave, ...props }) => {
  const { theme, title, subtitle, iconId } = props
  const SvgIcon = iconMap[iconId]
  const colors = richBadgeThemeMap[theme]

  const [badgeRef, setBadgeRef] = useState<HTMLElement | null>(null)

  useEffect(() => {
    if (badgeRef === null) return

    const handleResize = (): void => {
      const badgeRect = badgeRef.getBoundingClientRect()

      badgeRef.style.setProperty('--title-font-size', `${badgeRect.width * 0.056}px`)
      badgeRef.style.setProperty('--title-letter-spacing', `${badgeRect.width * 0.003}px`)
      badgeRef.style.setProperty('--subtitle-font-size', `${badgeRect.width * 0.042}px`)
      badgeRef.style.setProperty('--subtitle-letter-spacing', `${badgeRect.width * 0.001}px`)
      badgeRef.style.setProperty('--depth-ratio', `${badgeRect.width * 0.004}`)
      badgeRef.style.setProperty('--shadow-x', `${dropShadowWidth(badgeRect.width) * -1}px`)
      badgeRef.style.setProperty('--shadow-y', `${dropShadowWidth(badgeRect.width) * -1}px`)
    }

    const resizeObserver = new ResizeObserver(handleResize)
    resizeObserver.observe(badgeRef)

    return () => {
      resizeObserver.unobserve(badgeRef)
    }
  }, [badgeRef])

  useEffect(() => {
    if (badgeRef === null || theme === 'disabled') return

    let t = 0.05

    let stop = false
    let currentValues = initialValues
    let targetValues = initialValues

    const resetAnimation = (): void => {
      // Remove icon shadow when still
      badgeRef.style.removeProperty('--icon-projected-shadow-opacity')
    }

    const lerp = (prop: keyof AnimationValues): number => {
      return currentValues[prop] * (1 - t) + targetValues[prop] * t
    }

    const handleMouseMove = (event: MouseEvent): void => {
      const badgeRect = badgeRef.getBoundingClientRect()
      const halfWidth = badgeRect.width / 2
      const halfHeight = badgeRect.height / 2

      const centerX = badgeRect.left + halfWidth
      const centerY = badgeRect.top + halfHeight

      const xDist = event.clientX - centerX
      const yDist = event.clientY - centerY

      const x = Math.min(Math.max(xDist / 3, -halfWidth), halfWidth)
      const y = Math.min(Math.max(yDist / 3, -halfHeight), halfHeight)

      targetValues = {
        rotateX: (y / halfHeight) * 45,
        rotateY: -(x / halfWidth) * 45,
        radialX: ((event.clientX - badgeRect.left) / badgeRect.width) * 100,
        radialY: ((event.clientY - badgeRect.top) / badgeRect.height) * 100,
        shadowX: (xDist / badgeRect.width) * animatedDropShadowWidth(badgeRect.width),
        shadowY: (yDist / badgeRect.height) * animatedDropShadowWidth(badgeRect.width),
        iconProjectedShadowOpacity: 0.1,
      }
    }

    const renderAnimation = (): void => {
      currentValues = {
        rotateX: lerp('rotateX'),
        rotateY: lerp('rotateY'),
        shadowX: lerp('shadowX'),
        shadowY: lerp('shadowY'),
        radialX: lerp('radialX'),
        radialY: lerp('radialY'),
        iconProjectedShadowOpacity: lerp('iconProjectedShadowOpacity'),
      }

      badgeRef.style.setProperty('--rotate-x', `${currentValues.rotateX}deg`)
      badgeRef.style.setProperty('--rotate-y', `${currentValues.rotateY}deg`)
      badgeRef.style.setProperty('--radial-x', `${currentValues.radialX}%`)
      badgeRef.style.setProperty('--radial-y', `${currentValues.radialY}%`)
      badgeRef.style.setProperty('--shadow-x', `${currentValues.shadowX}px`)
      badgeRef.style.setProperty('--shadow-y', `${currentValues.shadowY}px`)
      badgeRef.style.setProperty(
        '--icon-projected-shadow-opacity',
        `${currentValues.iconProjectedShadowOpacity}`
      )

      const isTransitionComplete =
        Math.abs(currentValues.rotateX - targetValues.rotateX) < t &&
        Math.abs(currentValues.rotateY - targetValues.rotateY) < t &&
        Math.abs(currentValues.shadowX - targetValues.shadowX) < t &&
        Math.abs(currentValues.shadowY - targetValues.shadowY) < t &&
        Math.abs(currentValues.radialX - targetValues.radialX) < t &&
        Math.abs(currentValues.radialY - targetValues.radialY) < t

      if (stop && isTransitionComplete) {
        resetAnimation()
      } else {
        requestAnimationFrame(renderAnimation)
      }
    }

    const handleMouseEnter = (): void => {
      onMouseEnter?.(props)

      stop = false
      t = 0.5

      renderAnimation()
    }

    const handleMouseLeave = (): void => {
      onMouseLeave?.(props)

      stop = true
      t = 0.04

      const badgeRect = badgeRef.getBoundingClientRect()

      targetValues = {
        ...initialValues,
        shadowX: dropShadowWidth(badgeRect.width) * -1,
        shadowY: dropShadowWidth(badgeRect.width) * -1,
      }
    }

    badgeRef.addEventListener('mousemove', handleMouseMove)
    badgeRef.addEventListener('mouseenter', handleMouseEnter)
    badgeRef.addEventListener('mouseleave', handleMouseLeave)

    return () => {
      stop = true

      resetAnimation()

      badgeRef.removeEventListener('mousemove', handleMouseMove)
      badgeRef.removeEventListener('mouseenter', handleMouseEnter)
      badgeRef.removeEventListener('mouseleave', handleMouseLeave)
    }
  }, [badgeRef, theme, iconId, onMouseEnter, props, onMouseLeave])

  return (
    <Container ref={setBadgeRef}>
      <BackgroundLayer>
        <RichBadgeBackgroundSvg />
        <RadialGradientBackgroundContainer
          $background={colors.background}
          $backgroundColor={colors.backgroundColor}
        />
      </BackgroundLayer>

      <ForegroundLayer $dropShadow={colors.dropShadow}>
        <RichBadgeForegroundSvg />
        <LinearGradientForegroundContainer $color={colors.foreground} />
      </ForegroundLayer>

      <IconLayer $color={colors.iconColor} $dropShadow={colors.dropShadow}>
        <SvgIcon />
      </IconLayer>

      <TextLayer>
        <TitleContainer>
          <StyledText bold $color={colors.titleColor} lines={2}>
            {title.toUpperCase()}
          </StyledText>
        </TitleContainer>
        <SubtitleContainer>
          <StyledText bold $color={colors.subtitleColor}>
            {subtitle.toUpperCase()}
          </StyledText>
        </SubtitleContainer>
      </TextLayer>
    </Container>
  )
}
