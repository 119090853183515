import { useEffect } from 'react'
import { logger } from 'sierra-client/logger/logger'
import { LiveContentId, SelfPacedContentId } from 'sierra-domain/api/nano-id'
import { File } from 'sierra-domain/flexible-content/types'

export const UnsupportedFileType: React.FC<{
  mode: 'live' | 'now' | 'self-paced' | 'backend-self-paced'
  file: File
  flexibleContentId: LiveContentId | SelfPacedContentId
}> = ({ mode, file, flexibleContentId }) => {
  useEffect(() => {
    logger.captureError(new Error(`Unsupported file type in ${mode}`), {
      fileType: file.type,
      flexibleContentId,
      fileId: file.id,
    })
  }, [file.id, file.type, flexibleContentId, mode])

  return <>Unsupported Card</>
}
