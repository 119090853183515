import React, { useMemo } from 'react'
import { Identity } from 'sierra-client/components/common/identities-selector'
import { IdentitiesSingleSelector } from 'sierra-client/components/common/identities-selector/identities-selector'
import { mapUserToIdentity } from 'sierra-client/components/common/identities-selector/identities-utils'
import { useCanIssueAsUsersFetcher } from 'sierra-client/components/common/identities-selector/identity-fetchers'
import { useTranslation } from 'sierra-client/hooks/use-translation'
import { useSelector } from 'sierra-client/state/hooks'
import { selectUser } from 'sierra-client/state/user/user-selector'
import { UserId } from 'sierra-domain/api/uuid'
import { isNonNullable } from 'sierra-domain/utils'

type IssueByProxySelectorProps = {
  userIds: UserId[]
  onSelect: (issuedByProxy: Identity) => void
  onUnselect: (issuedByProxy: Identity) => void
  value?: Identity
  placeholder?: string
  disabled?: boolean
}

export const useMeAsIdentity = (): Identity | undefined => {
  const { t } = useTranslation()
  const me = useSelector(selectUser)

  return useMemo(
    () => (me ? mapUserToIdentity(me, /*displaySuffix=*/ `(${t('dictionary.you')})`) : undefined),
    [me, t]
  )
}

export const IssueByProxySelector = React.memo<IssueByProxySelectorProps>(
  ({ userIds, onSelect, onUnselect, disabled, value, placeholder }) => {
    const { t } = useTranslation()
    const fetchCanIssueAsUserIdentities = useCanIssueAsUsersFetcher(userIds)
    const meIdentity = useMeAsIdentity()

    function onSelectIssueAsAsUser(identity: Identity): void {
      onSelect(identity)
    }

    function onUnselectIssueAsUser(identity: Identity): void {
      onUnselect(identity)
    }

    const isMe = isNonNullable(meIdentity) && isNonNullable(value) && meIdentity.id === value.id

    return (
      <IdentitiesSingleSelector
        disabled={disabled}
        fetchIdentities={fetchCanIssueAsUserIdentities}
        value={value ? (isMe ? meIdentity : value) : undefined}
        onSelect={onSelectIssueAsAsUser}
        onUnselect={onUnselectIssueAsUser}
        placeholder={placeholder ?? t('manage.certificates.issue.issuer-select')}
        fetchOptions={{
          cacheKey: 'issue-by-proxy-selector-' + userIds.join(''),
        }}
      />
    )
  }
)
