import React, { useEffect, useState } from 'react'
import { ActionModal } from 'sierra-client/components/common/modals/action-modal'
import { getFlag } from 'sierra-client/config/global-config'
import { useDeleteDashboard } from 'sierra-client/features/insights/api-hooks/use-delete-dashboard'
import { useListDashboards } from 'sierra-client/features/insights/api-hooks/use-list-dashboards'
import { useUpsertDashboard } from 'sierra-client/features/insights/api-hooks/use-upsert-dashboard'
import { usePost } from 'sierra-client/hooks/use-post'
import { useTranslation } from 'sierra-client/hooks/use-translation'
import { getGlobalRouter } from 'sierra-client/router'
import { Dashboard } from 'sierra-domain/api/insights'
import { nanoid12 } from 'sierra-domain/nanoid-extensions'
import { XAnalyticsDashboardGenerateTitle } from 'sierra-domain/routes'
import { IconButton, InputPrimitive, Text, View } from 'sierra-ui/primitives'
import styled from 'styled-components'

export type DashboardModalAction = 'rename' | 'duplicate' | 'delete' | undefined

const DashboardModalView = styled(View).attrs({ direction: 'column' })`
  margin: 1rem 0rem 2rem;
  width: 30rem;
  max-width: 100%;
`

export const DashboardModal: React.FC<{
  action: DashboardModalAction
  onClose: () => void
  dashboard: Dashboard
}> = ({ action, onClose, dashboard }) => {
  const { t } = useTranslation()
  const [title, setTitle] = useState(dashboard.title)
  const upsertDashboard = useUpsertDashboard()
  const newInsightsHome = getFlag('insights/new-home')
  const listDashboards = useListDashboards()
  const nextDashboard = listDashboards.data?.dashboards.find(
    listedDashboard => listedDashboard.dashboard.id !== dashboard.id
  )
  const deleteDashboard = useDeleteDashboard(
    newInsightsHome
      ? '/manage/insights/dashboards'
      : nextDashboard !== undefined
        ? `/manage/insights/dashboards/${nextDashboard.dashboard.id}`
        : '/manage/insights'
  )
  const { postWithUserErrorException } = usePost()
  const [generatingTitle, setGeneratingTitle] = useState(false)

  useEffect(() => {
    if (action === 'duplicate') {
      setTitle(t('content.copy-of-title', { title: dashboard.title }))
    } else {
      setTitle(dashboard.title)
    }
  }, [t, action, dashboard.title])

  const generateTitle = async (): Promise<void> => {
    setGeneratingTitle(true)

    try {
      const response = await postWithUserErrorException(XAnalyticsDashboardGenerateTitle, {
        dashboardId: dashboard.id,
        currentTitle: title,
      })
      setTitle(response.title)
    } finally {
      setGeneratingTitle(false)
    }
  }

  switch (action) {
    case 'rename':
      return (
        <ActionModal
          open
          title={t('manage.insights.dashboard.rename')}
          onClose={onClose}
          primaryAction={async () => {
            await upsertDashboard.mutateAsync({ dashboard: { ...dashboard, title } })
          }}
          primaryActionLabel={t('dictionary.save')}
          isLoading={generatingTitle}
        >
          <DashboardModalView>
            <View direction='row' justifyContent='space-between'>
              <label htmlFor='title'>
                <Text bold>{t('manage.insights.create-dashboard.title-label')}</Text>
              </label>
              <IconButton
                iconId='smart'
                disabled={generatingTitle}
                onClick={generateTitle}
                variant='transparent'
              />
            </View>
            <InputPrimitive
              id='title'
              value={title}
              disabled={generatingTitle}
              onChange={event => setTitle(event.target.value)}
            />
          </DashboardModalView>
        </ActionModal>
      )
    case 'duplicate':
      return (
        <ActionModal
          open
          title={t('manage.insights.dashboard.duplicate')}
          onClose={onClose}
          primaryAction={async () => {
            const newWidgets = dashboard.widgets.map(widget => ({ ...widget, id: nanoid12() }))
            const newDashboard = await upsertDashboard.mutateAsync({
              dashboard: { title, widgets: newWidgets },
            })
            await getGlobalRouter().navigate({
              to: `/manage/insights/dashboards/${newDashboard.dashboard.id}`,
              replace: true,
            })
          }}
          primaryActionLabel={t('dictionary.duplicate')}
        >
          <DashboardModalView>
            <View direction='row' justifyContent='space-between'>
              <label htmlFor='title'>
                <Text bold>{t('manage.insights.create-dashboard.title-label')}</Text>
              </label>
            </View>
            <InputPrimitive id='title' value={title} onChange={event => setTitle(event.target.value)} />
          </DashboardModalView>
        </ActionModal>
      )
    case 'delete':
      return (
        <ActionModal
          open
          deleteAction
          title={t('manage.insights.dashboard.delete')}
          onClose={onClose}
          primaryAction={async () => {
            await deleteDashboard.mutateAsync({ id: dashboard.id })
          }}
          primaryActionLabel={t('dictionary.delete')}
        >
          <Text size='regular'>{t('manage.insights.dashboard.delete-description')}</Text>
        </ActionModal>
      )
    default:
      return <></>
  }
}
