import { useCallback, useMemo, useState } from 'react'
import { CellProps, Column } from 'react-table'
import { PageTitle } from 'sierra-client/components/common/page-title'
import { GlobalFilter } from 'sierra-client/components/table/global-filter'
import { SelectableHeader, SelectableRow } from 'sierra-client/components/table/select'
import { SortableHeader } from 'sierra-client/components/table/sortable-header'
import { date } from 'sierra-client/core/format'
import { useOrganizationPermissions } from 'sierra-client/hooks/use-permissions'
import { usePost } from 'sierra-client/hooks/use-post'
import { useTranslation } from 'sierra-client/hooks/use-translation'
import {
  RenderCountsPrimitive,
  ToolbarContainer,
  ToolbarLeftContainer,
} from 'sierra-client/lib/tabular/components/tabular-toolbar'
import { getGlobalRouter } from 'sierra-client/router'
import { AnimatedSearch } from 'sierra-client/views/manage/components/animated-search'
import { BetweenContainer } from 'sierra-client/views/manage/components/common'
import { ExportCSVButton } from 'sierra-client/views/manage/components/export-csv'
import { ColumnLayout } from 'sierra-client/views/manage/components/layout/column-layout'
import {
  ManageTableLarge,
  useManageTableLarge,
} from 'sierra-client/views/manage/components/manage-table-large'
import { mapUserGroupToCsv, useUserGroups } from 'sierra-client/views/manage/user-groups/use-user-groups'
import { EditCreateUserGroupModal } from 'sierra-client/views/manage/users/components/edit-create-user-group-modal'
import { useTracking } from 'sierra-client/views/manage/users/utils/use-tracking'
import { PageHeader } from 'sierra-client/views/settings/components/page-header'
import { UserGroupRow } from 'sierra-domain/api/manage'
import { XRealtimeAdminGroupsCreateUserGroup } from 'sierra-domain/routes'
import { ColorName } from 'sierra-ui/color/types'
import { Icon, RoundAvatar } from 'sierra-ui/components'
import { Button, Text, View } from 'sierra-ui/primitives'
import styled from 'styled-components'

const StyledSelectableRow = styled(SelectableRow)`
  left: 0;
  width: 2rem;
`

const StyledSelectableHeader = styled(SelectableHeader)`
  left: 0;
  width: 2rem;
` as typeof SelectableHeader

const StyledRoundAvatar = styled(RoundAvatar)<{ hide: boolean }>`
  pointer-events: none;
  opacity: ${p => (p.hide ? 0 : 1)};
  transition: opacity 0.1s cubic-bezier(0.25, 0.1, 0.25, 1);
`

export type ManageUserGroupsProps = {
  sortById: string | undefined
  sortOrder: string | undefined
}

export const ManageUserGroups = (props: ManageUserGroupsProps): JSX.Element | null => {
  const { sortById, sortOrder } = props
  const { t } = useTranslation()
  const tracking = useTracking()
  const { postWithUserErrorException } = usePost()

  const orgPermissions = useOrganizationPermissions()
  const canCreateGroup = orgPermissions.has('CREATE_GROUP')

  const [createModalOpen, setCreateModalOpen] = useState(false)

  const sort = useMemo(() => {
    return sortById !== undefined ? { id: sortById, desc: sortOrder === 'desc' } : undefined
  }, [sortById, sortOrder])

  // Actions
  const createUserGroup = async ({
    name,
    description,
  }: {
    name: string
    description: string
  }): Promise<void> => {
    const { groupId } = await postWithUserErrorException(XRealtimeAdminGroupsCreateUserGroup, {
      type: 'user-manual',
      name,
      description,
    })

    tracking.static.create({ id: groupId, name, description })
    void getGlobalRouter().navigate({ to: `/manage/user-groups/${groupId}` })
  }

  const { userGroupsData, isLoading } = useUserGroups()
  const [focusedId] = useState<string | undefined>()

  const goToUserGroupDetails = useCallback(async (groupId: string) => {
    await getGlobalRouter().navigate({ to: `/manage/user-groups/${groupId}` })
  }, [])

  // Table
  const groupColumns: Column<UserGroupRow>[] = useMemo(
    () => [
      {
        id: 'select',
        label: '',
        Header: p => <StyledSelectableHeader {...p} />,
        width: '2rem',
        Cell: (p: CellProps<UserGroupRow>) => {
          const groupName = p.row.original.name
          const groupType = p.row.original.type
          const hideAvatar = p.selectedFlatRows.length > 0
          return (
            <View gap='none'>
              <StyledSelectableRow {...p} />
              <StyledRoundAvatar
                size='medium'
                firstName={groupName}
                hide={hideAvatar}
                color={
                  {
                    'user-scim': 'orangeBright',
                    'user-api': 'orangeBright',
                    'user-manual': 'greenBright',
                    'user-filter': 'purpleBright',
                  }[groupType] as ColorName
                }
              />
            </View>
          )
        },
      },
      {
        id: 'name',
        accessor: row => row.name,
        Header: p => {
          return (
            <>
              <SortableHeader label={t('table.name')} {...p} />
            </>
          )
        },
        Cell: (p: CellProps<UserGroupRow>) => {
          const groupName = p.row.original.name
          const groupType = p.row.original.type
          const invalidFilter = p.row.original.invalidFilter
          return (
            <>
              <Text
                size='small'
                bold
                color={Boolean(invalidFilter) ? 'warning/background' : 'foreground/primary'}
              >
                {groupType === 'user-filter' && (
                  <>
                    {Boolean(invalidFilter) ? (
                      <Icon iconId={'warning--filled'} color={'warning/background'} />
                    ) : (
                      <Icon iconId={'smart'} />
                    )}
                    &nbsp;&nbsp;
                  </>
                )}
                {groupName !== undefined && groupName !== '' ? groupName : 'N/A'}
              </Text>
            </>
          )
        },
        width: '40%',
      },
      {
        id: 'members',
        Header: p => <SortableHeader label={t('table.members')} {...p} />,
        accessor: (group: UserGroupRow) => group.numLearners,
        width: '20%',
      },
      {
        id: 'type',
        Header: p => <SortableHeader label={t('table.type')} {...p} />,
        accessor: (group: UserGroupRow) =>
          ({
            'user-api': t('user-group-type-external'),
            'user-manual': t('user-group-type-static'),
            'user-scim': t('user-group-type-external'),
            'user-filter': t('user-group-type-smart'),
          })[group.type],
        width: '20%',
      },
      {
        id: 'createdAt',
        Header: p => <SortableHeader label={t('table.createdAt')} {...p} />,
        accessor: (group: UserGroupRow) => group.createdAt,
        Cell: (p: CellProps<UserGroupRow>) => (
          <>{p.row.original.createdAt === undefined ? '' : date(p.row.original.createdAt)}</>
        ),
        width: '20%',
      },
    ],
    [t]
  )

  const { tableInstance } = useManageTableLarge({
    tableOptions: {
      data: userGroupsData,
      columns: groupColumns,
      initialState: {
        sortBy: sort === undefined ? [] : [sort],
      },
    },
    getEntityId: s => s.id,
  })

  return (
    <>
      <PageTitle title={t('dictionary.group-plural')} />

      <EditCreateUserGroupModal
        open={createModalOpen}
        mode='create'
        type='user-manual'
        onCancel={() => setCreateModalOpen(false)}
        onCreate={async draft => {
          await createUserGroup({ name: draft.name, description: draft.description })
          setCreateModalOpen(false)
        }}
      />
      <ColumnLayout>
        <PageHeader title='admin.analytics.groups' withoutMargin />

        <View direction='column' gap='16'>
          <BetweenContainer>
            <GlobalFilter
              tableInstance={tableInstance}
              render={props => <AnimatedSearch {...props} placeholder={t('manage.search.groups')} />}
            />

            <BetweenContainer>
              {canCreateGroup && (
                <Button variant='secondary' onClick={() => setCreateModalOpen(true)}>
                  {t('admin.create-new')}
                </Button>
              )}
            </BetweenContainer>
          </BetweenContainer>

          <ToolbarContainer>
            <ToolbarLeftContainer>
              <RenderCountsPrimitive>
                {t('manage.n-groups', { count: userGroupsData.length })}
              </RenderCountsPrimitive>
            </ToolbarLeftContainer>
            <ExportCSVButton
              color='secondary'
              fetchCsvData={() => tableInstance.rows.map(r => r.original).map(mapUserGroupToCsv)}
              filename='admin.analytics.group'
            />
          </ToolbarContainer>
        </View>

        <ManageTableLarge
          tableInstance={tableInstance}
          isLoading={isLoading}
          hasAvatarColumn={true}
          focusedId={focusedId}
          onViewDetails={goToUserGroupDetails}
          getEntityId={g => g.id}
          translations={{
            tableLoading: t('manage.groups.table-loading'),
            tableEnd: t('manage.groups.table-end'),
            tableNoResults: t('manage.groups.no-results'),
            csvPrefix: t('admin.analytics.groups'),
          }}
        />
      </ColumnLayout>
    </>
  )
}
