import { motion, useDragControls as useMotionDragControls } from 'framer-motion'
import { useAtom, useAtomValue } from 'jotai'
import React, { useCallback, useMemo, useState } from 'react'
import { useIsDebugMode } from 'sierra-client/hooks/use-is-debug-mode'
import type { ControlGroup, ControlProperty } from 'sierra-client/lib/use-debug/atom'
import { controlsAtom, sessionStorageExpandedAtom } from 'sierra-client/lib/use-debug/atom'
import { atomWithStorage } from 'sierra-client/state/storage'
import { Checkbox, IconButton, Text, View } from 'sierra-ui/primitives'
import { DarkTokenProvider, zIndex } from 'sierra-ui/theming'
import styled from 'styled-components'
import { z } from 'zod'

const Container = styled(motion.div)`
  gap: 8px;
  position: fixed;
  right: 16px;
  top: 16px;
  z-index: ${zIndex.ON_MODAL + 1};
  color: white;
  background: #3e3e3e;
  border-radius: 6px;
  padding: 8px;
  display: flex;
  flex-direction: column;

  width: 250px;
`

const Li = styled.li`
  display: flex;
  gap: 8px;
`

const Name = styled(Text).attrs({ size: 'technical' })`
  flex: 1;
`

const IconButtonOpacity = styled(IconButton)<{ $hidden: boolean }>`
  opacity: ${p => (p.$hidden ? 0 : 1)};
`

const AdjustPropertyInput: React.FC<{
  property: ControlProperty & { type: 'string' }
  onChange: (value: ControlProperty) => void
}> = ({ property, onChange }) => {
  const [value, setValue] = useState(property.value)
  return (
    <>
      <View>
        <IconButtonOpacity
          $hidden={value === property.value}
          tooltip='Press enter to sync'
          iconId='reset'
          size='small'
        />

        <input
          type='text'
          value={value}
          onBlur={() => {
            onChange({ ...property, value })
          }}
          onKeyDown={e => {
            if (e.key === 'Enter') {
              onChange({ ...property, value })
            }
          }}
          onChange={e => {
            setValue(e.target.value)
          }}
        />
      </View>
    </>
  )
}

const AdjustNumberInput: React.FC<{
  property: ControlProperty & { type: 'number' }
  onChange: (value: ControlProperty) => void
}> = ({ property, onChange }) => {
  const [rawValue, setRawValue] = useState(String(property.value))

  // For now we'll ignore NaN and so on
  const validatedValue = rawValue === '' ? 0 : parseInt(rawValue)

  return (
    <View>
      <IconButtonOpacity
        $hidden={validatedValue === property.value}
        tooltip='Press enter to sync'
        iconId='reset'
        size='small'
      />

      <input
        type='number'
        value={rawValue}
        onBlur={() => {
          onChange({ ...property, value: validatedValue })
          setRawValue(String(validatedValue))
        }}
        onKeyDown={e => {
          if (e.key === 'Enter') {
            onChange({ ...property, value: validatedValue })
            setRawValue(String(validatedValue))
          }
        }}
        onChange={e => {
          setRawValue(e.target.value)
        }}
      />
    </View>
  )
}

const AdjustProperty: React.FC<{
  property: ControlProperty
  onChange: (value: ControlProperty) => void
}> = ({ property, onChange }) => {
  switch (property.type) {
    case 'string':
      return <AdjustPropertyInput property={property} onChange={onChange} />
    case 'number':
      return <AdjustNumberInput property={property} onChange={onChange} />
    case 'boolean':
      return (
        <Checkbox
          checked={property.value}
          onCheckedChange={e => onChange({ ...property, value: z.boolean().parse(e) })}
        />
      )
    case 'list':
      return (
        <select
          value={property.value}
          onChange={e =>
            onChange({
              ...property,
              value: z.string().parse(e.target.value),
            })
          }
        >
          {property.values.map(value => (
            <option
              key={
                // `any` is used here to avoid excessive passing of generics.
                // eslint-disable-next-line @typescript-eslint/no-explicit-any
                value as any
              }
            >
              {value}
            </option>
          ))}
        </select>
      )

    case 'range':
      return (
        <View>
          {property.value}
          <input
            type='range'
            value={property.value}
            min={property.min}
            max={property.max}
            step={property.step}
            onChange={e => {
              onChange({
                ...property,
                value: Number(e.target.value),
              })
            }}
          />
        </View>
      )

    case 'color':
      return (
        <input
          type='color'
          value={property.value}
          onChange={e => {
            onChange({
              ...property,
              value: e.target.value,
            })
          }}
        />
      )
  }
}

const ControlGroup: React.FC<{ group: ControlGroup }> = ({ group }) => {
  const [properties, setProperties] = useAtom(group.properties)
  const [expansionStates, setExpansionStates] = useAtom(sessionStorageExpandedAtom)
  const isExpanded = expansionStates[group.id]?.isExpanded ?? false
  const toggleExpansion = useCallback(() => {
    setExpansionStates(prev => {
      const previousValue = prev[group.id]?.isExpanded ?? false
      return {
        ...prev,
        [group.id]: { isExpanded: !previousValue },
      }
    })
  }, [group.id, setExpansionStates])

  return (
    <View direction='column' gap='8'>
      <View gap='8'>
        <View grow onClick={toggleExpansion}>
          <Text size='small' bold>
            {group.name}
          </Text>
        </View>
        <IconButton
          variant='secondary'
          size='small'
          iconId={isExpanded ? 'arrow--up' : 'arrow--down'}
          onClick={toggleExpansion}
        />
      </View>
      {isExpanded &&
        Object.entries(properties).map(([name, value]) => (
          <ul key={name}>
            <Li>
              <Name>{name}</Name>
              <AdjustProperty
                property={value}
                onChange={newValue => setProperties(prev => ({ ...prev, [name]: newValue }))}
              />
            </Li>
          </ul>
        ))}
    </View>
  )
}

const DragIcon = styled(IconButton)`
  cursor: grab;

  &:active {
    cursor: grabbing;
  }
`

const DragBounds = styled.div`
  position: fixed;
  inset: 0;
`

function useLocalStorageExpanded(
  key: string
): [expanded: boolean, setExpanded: React.Dispatch<React.SetStateAction<boolean>>] {
  const expandedAtom = useMemo(() => atomWithStorage(key, false), [key])
  return useAtom(expandedAtom)
}

const _DebugControls: React.FC = () => {
  const groups = useAtomValue(controlsAtom)
  const [isExpanded, setIsExpanded] = useLocalStorageExpanded('debug-controls')
  const controls = useMotionDragControls()
  const dragBoundsRef = React.useRef<HTMLDivElement>(null)

  if (groups.length === 0) return null
  return (
    <DarkTokenProvider>
      <DragBounds ref={dragBoundsRef} />
      <Container
        drag
        dragListener={false}
        dragControls={controls}
        dragMomentum={false}
        dragConstraints={dragBoundsRef}
      >
        <View gap='8'>
          <div onPointerDown={ev => controls.start(ev)}>
            <DragIcon iconId='draggable' size='small' variant='secondary' />
          </div>
          <View grow />

          <IconButton
            iconId={isExpanded ? 'minimize' : 'maximize'}
            size='small'
            variant='secondary'
            onClick={() => setIsExpanded(prev => !prev)}
          />
        </View>

        {isExpanded && groups.map(group => <ControlGroup key={group.id} group={group} />)}
      </Container>
    </DarkTokenProvider>
  )
}

export const DebugControls: React.FC = () => {
  const isDebugMode = useIsDebugMode()
  if (!isDebugMode) return null
  return <_DebugControls />
}
