import { LearnCourse, LearnProgram, ProgramStep } from 'sierra-domain/api/entities'
import { assertNever } from 'sierra-domain/utils'

const getNextStep = (program: LearnProgram): ProgramStep | undefined => {
  const nextStepId = program.nextStepId

  if (nextStepId === undefined) return undefined

  return program.steps.find(step => step.id === nextStepId)
}

export const getNextCourse = (program: LearnProgram): LearnCourse | undefined => {
  const nextStep = getNextStep(program)

  if (nextStep === undefined) return undefined

  // TODO: Do we care about returning the step due date here?

  switch (nextStep.type) {
    case 'course':
      return nextStep.course
    case 'path': {
      const nextCourseId = nextStep.nextCourseId

      if (nextCourseId === undefined) return undefined

      return nextStep.path.content.find(course => course.id === nextCourseId)
    }
    default:
      assertNever(nextStep)
  }
}
