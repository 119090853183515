import React, { useEffect, useState } from 'react'
import { ActionModal } from 'sierra-client/components/common/modals/action-modal'
import { useTranslation } from 'sierra-client/hooks/use-translation'
import { typedPost } from 'sierra-client/state/api'
import { getAvatarImage } from 'sierra-client/utils/avatar-img'
import { StaggeredAssignmentsMode } from 'sierra-client/views/manage/programs/staggered-assignments/types'
import { getOnlyInProgressUsers } from 'sierra-client/views/manage/programs/staggered-assignments/utils'
import { ProgramMembersPreviewResponse } from 'sierra-domain/api/manage'
import { XRealtimeAdminProgramsProgramMembersPreview } from 'sierra-domain/routes'
import { AvatarStackUserShape } from 'sierra-ui/components'
import { MatchingUsers } from 'sierra-ui/missions/workflows/panels/components/matching-users'
import { PseudoOptionVertical } from 'sierra-ui/missions/workflows/panels/form-elements/pseudo-option-vertical'
import { Spacer, Text, View } from 'sierra-ui/primitives'

export type StaggeredAssignmentsSaveAction = (mode: StaggeredAssignmentsMode) => void

const fetchProgramMembersPreview = ({
  programId,
  onlyInProgressUsers,
}: {
  programId: string
  onlyInProgressUsers: boolean
}): Promise<ProgramMembersPreviewResponse> =>
  typedPost(XRealtimeAdminProgramsProgramMembersPreview, {
    programId,
    onlyInProgressUsers,
    maxUsersInfoToReturn: 3,
  })

export const ApplyChangesModal: React.FC<{
  programId: string
  open: boolean
  canChangeUserVersion: boolean
  onClose: () => void
  primaryAction: StaggeredAssignmentsSaveAction
}> = ({ open, onClose, canChangeUserVersion, primaryAction, programId }) => {
  const [users, setUsers] = useState<AvatarStackUserShape[] | undefined>(undefined)
  const [userCount, setUserCount] = useState<number | undefined>(0)
  const [mode, setMode] = useState<StaggeredAssignmentsMode>('new')
  const { t } = useTranslation()

  useEffect(() => {
    void (async () => {
      const programMembersPreviewResponse = await fetchProgramMembersPreview({
        programId,
        onlyInProgressUsers: getOnlyInProgressUsers(mode),
      })
      const users: AvatarStackUserShape[] = programMembersPreviewResponse.usersInfo.map(
        ({ userId, firstName, lastName, avatar, avatarColor }) => ({
          uuid: userId,
          firstName: firstName ?? '',
          lastName: lastName ?? '',
          avatar: getAvatarImage(userId, avatar),
          avatarColor,
        })
      )

      setUsers(users)
      setUserCount(programMembersPreviewResponse.membersCount)
    })()
  }, [mode, programId])

  return (
    <ActionModal
      title={
        canChangeUserVersion
          ? t('manage.program.program-details.outline.apply-changes-modal-title')
          : t('admin.confirm-modal-headline')
      }
      open={open}
      onClose={onClose}
      primaryAction={() => primaryAction(mode)}
      primaryActionLabel={t('dictionary.confirm')}
    >
      <Spacer size='24' />
      {canChangeUserVersion ? (
        <>
          <View direction='column' gap='16'>
            <PseudoOptionVertical
              id='changes-new'
              bold={false}
              label={t('manage.program.program-details.outline.apply-changes-modal-new.options.new.title')}
              subline={t(
                'manage.program.program-details.outline.apply-changes-modal-new.options.new.description'
              )}
              checked={mode === 'new'}
              onChange={selected => {
                if (selected) {
                  setMode('new')
                }
              }}
            />
            <PseudoOptionVertical
              id='changes-inprogress'
              bold={false}
              label={t(
                'manage.program.program-details.outline.apply-changes-modal-new.options.in-progress.title'
              )}
              subline={t(
                'manage.program.programa-details.outline.apply-changes-modal-new.options.in-progress.description'
              )}
              checked={mode === 'in-progress'}
              onChange={selected => {
                if (selected) {
                  setMode('in-progress')
                }
              }}
            />
            <PseudoOptionVertical
              id='changes-everyone'
              bold={false}
              label={t(
                'manage.program.program-details.outline.apply-changes-modal-new.options.even-completed.title'
              )}
              subline={t(
                'manage.program.program-details.outline.apply-changes-modal-new.options.even-completed.description'
              )}
              checked={mode === 'even-completed'}
              onChange={selected => {
                if (selected) {
                  setMode('even-completed')
                }
              }}
            />
            <Spacer size='none' />
            {users !== undefined && userCount !== undefined && (
              <MatchingUsers
                users={mode === 'new' ? [] : users}
                label={t('manage.program.programa-details.outline.apply-changes-modal-new.message', {
                  count: mode === 'new' ? 0 : userCount,
                })}
              />
            )}
          </View>
          <Spacer size='32' />
        </>
      ) : (
        <Text>
          {t('manage.program.program-details.outline.apply-changes-modal-new.options.new.description')}
        </Text>
      )}
    </ActionModal>
  )
}
