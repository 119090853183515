import { DateTime } from 'luxon'
import { useState } from 'react'
import { IconMenu } from 'sierra-client/components/common/icon-menu'
import { Link } from 'sierra-client/components/common/link'
import { ActionModal } from 'sierra-client/components/common/modals/action-modal'
import { usePost } from 'sierra-client/hooks/use-post'
import { useToggle } from 'sierra-client/hooks/use-toggle'
import { useTranslation } from 'sierra-client/hooks/use-translation'
import { getGlobalRouter } from 'sierra-client/router'
import * as settingsActions from 'sierra-client/state/author-course-settings/actions'
import { Collaborator } from 'sierra-client/state/author-course-settings/types'
import { useDispatch } from 'sierra-client/state/hooks'
import { getAvatarImage } from 'sierra-client/utils/avatar-img'
import { LiveSessionWithParticipants } from 'sierra-domain/api/admin'
import { CourseRole } from 'sierra-domain/api/manage'
import { CourseId } from 'sierra-domain/api/nano-id'
import { UserId } from 'sierra-domain/api/uuid'
import { XRealtimeAuthorTransferContentOwnership } from 'sierra-domain/routes'
import { AvatarColor } from 'sierra-domain/user/avatar-color'
import { assertNever, getUserName, isDefined } from 'sierra-domain/utils'
import {
  DatePickerPrimitive,
  Icon,
  MenuItem,
  RoundAvatar,
  Tooltip,
  TruncatedText,
} from 'sierra-ui/components'
import { IconButton, Skeleton, Text, View } from 'sierra-ui/primitives'
import { SingleSelectDropdown } from 'sierra-ui/primitives/menu-dropdown'
import { token, zIndex } from 'sierra-ui/theming'
import styled from 'styled-components'

export type SharingModalUser = {
  type: 'user'
  uuid: UserId
  firstName?: string
  lastName?: string
  email?: string
  avatar?: string
  avatarColor?: AvatarColor
}

export type SharingModalUserOrLoadMore = SharingModalUser | { type: 'load-more' }
export const isSharingModalUser = (
  userOrLoadMore: SharingModalUserOrLoadMore
): userOrLoadMore is SharingModalUser => userOrLoadMore.type === 'user'

const RootView = styled(View)`
  justify-content: space-between;
  align-items: center;
  pointer-events: auto;
  width: 100%;
  height: 2rem;
  position: relative;

  &:after {
    content: '';
    position: absolute;
    bottom: -7.5px;
    left: 0;
    right: 0;
    height: 1px;
    background-color: ${token('border/default')};
  }
`

const NameText = styled(Text).attrs({ size: 'small', color: 'foreground/secondary', bold: true })`
  flex-shrink: 0;
`

const EmailText = styled(TruncatedText).attrs({ lines: 1, size: 'small', color: 'foreground/muted' })`
  white-space: nowrap;
  text-overflow: ellipsis;
`

const OwnerText = styled(Text).attrs({ size: 'small', color: 'foreground/muted' })`
  flex-shrink: 0;
  min-width: 0;
  width: max-content;
`

const AddedText = styled(Text).attrs({ size: 'small', color: 'foreground/muted' })`
  flex-shrink: 0;
`

type SharingModalMenuItemProps = { user: Omit<SharingModalUser, 'type'>; selected: boolean }

export const SharingModalMenuItem: React.FC<SharingModalMenuItemProps> = ({ user, selected }) => {
  const { t } = useTranslation()

  return (
    <RootView>
      <View>
        <RoundAvatar
          firstName={user.firstName}
          lastName={user.lastName}
          src={getAvatarImage(user.uuid, user.avatar)}
          color={user.avatarColor}
          size='tiny'
        />
        <NameText>{getUserName(user)}</NameText>
        <EmailText>{user.email ?? ''}</EmailText>
      </View>
      {selected && <AddedText>{t('dictionary.added')}</AddedText>}
    </RootView>
  )
}

const MoreResultsText = styled(Text)`
  width: 100%;
  text-align: center;
`

export const MoreResultsMenuItem: React.FC = () => {
  const { t } = useTranslation()

  return (
    <RootView>
      <MoreResultsText color='foreground/muted' size='small'>
        {t('sharing-modal.use-filter-to-find-more-users')}
      </MoreResultsText>
    </RootView>
  )
}

const ControlsContainer = styled(View)`
  & div[role='tooltip'] {
    z-index: ${zIndex.MENU};
  }
`

const MenuItemBottomBorder = styled.div`
  height: 1px;
  width: calc(100% - 16px);
  margin-right: 16px;
  background-color: ${token('border/default')};
`

const WithControlsRootContainer = styled.div``

const InnerView = styled(View)`
  display: flex;
  align-items: center;
  width: 100%;
  height: 2.75rem;
  justify-content: space-between;
  padding-top: 16px;
  padding-bottom: 16px;
`

const WithControlsInnerView = styled(View)<{ isMenuOpen?: boolean }>`
  width: 100%;
  height: 2.75rem;
  padding-top: 16px;
  padding-bottom: 16px;
  overflow: hidden;

  & ${ControlsContainer} {
    display: ${p => (p.isMenuOpen === true ? 'flex' : 'none')};
  }

  &:hover {
    & ${ControlsContainer} {
      display: flex;
    }
  }

  & ${EmailText} {
    display: ${p => (p.isMenuOpen === true ? 'block' : 'none')};
  }

  &:hover {
    & ${EmailText} {
      display: block;
    }
  }
`

const StyledIconMenu = styled(IconMenu)`
  margin-right: 16px;
`

type SharingModalAssignUser = SharingModalUser & {
  dueDate?: string
}

type SharingModalMenuItemWithAssignSelfPacedControlsProps = {
  user: SharingModalAssignUser

  onSetDueDate?: (dueDate: string) => void
  onRemoveDueDate?: () => void
  onUnassign?: () => void
}

export const SharingModalMenuItemWithAssignSelfPacedControls: React.FC<
  SharingModalMenuItemWithAssignSelfPacedControlsProps
> = ({ user, onUnassign, onSetDueDate, onRemoveDueDate }) => {
  const { t } = useTranslation()

  const [calendarOpen, setCalendarOpen] = useState(false)
  const [isMenuOpen, { on: openMenu, off: closeMenu }] = useToggle(false)

  const fullName = getUserName(user)
  const useEmailAsName = fullName === undefined

  return (
    <WithControlsRootContainer>
      <WithControlsInnerView isMenuOpen={isMenuOpen || calendarOpen} justifyContent='space-between'>
        <View>
          <RoundAvatar
            firstName={user.firstName}
            lastName={user.lastName}
            src={getAvatarImage(user.uuid, user.avatar)}
            color={user.avatarColor}
            size='tiny'
          />
          <NameText>{useEmailAsName ? user.email : fullName}</NameText>
          {!useEmailAsName && <EmailText>{user.email ?? ''}</EmailText>}
        </View>
        <ControlsContainer>
          <DatePickerPrimitive
            isOpen={calendarOpen}
            onOpenChange={setCalendarOpen}
            disablePastDates
            renderTrigger={() => (
              <IconButton variant='transparent' iconId={'calendar'} tooltip={t('due-date.set-due-date')} />
            )}
            value={user.dueDate !== undefined ? DateTime.fromFormat(user.dueDate, 'yyyy-MM-dd') : undefined}
            onChange={newDate => {
              if (onSetDueDate) onSetDueDate(newDate?.toFormat('yyyy-MM-dd') ?? '')
            }}
          />

          <StyledIconMenu
            onOpen={openMenu}
            onClose={closeMenu}
            iconId='overflow-menu--vertical'
            items={[
              {
                id: 'view-user-in-manage',
                type: 'label',
                label: t('dictionary.view'),
                onClick: () => getGlobalRouter().navigate({ to: `/manage/users/${user.uuid}` }),
              },
              {
                hidden: user.dueDate === undefined || onRemoveDueDate === undefined,
                id: 'remove-due-date',
                type: 'label',
                label: t('due-date.modal.remove'),
                onClick: onRemoveDueDate,
              },
              {
                hidden: onUnassign === undefined,
                id: 'unassign',
                type: 'label',
                label: t('dictionary.unassign'),
                onClick: onUnassign,
              },
            ]}
          />
        </ControlsContainer>
      </WithControlsInnerView>
      <MenuItemBottomBorder />
    </WithControlsRootContainer>
  )
}

type SharingModalMenuItemWithAssignLiveControlsProps = {
  user: SharingModalUser & { liveSessions: LiveSessionWithParticipants[] }
  onUnassign?: () => void
}

export const SharingModalMenuItemWithAssignLiveControls: React.FC<
  SharingModalMenuItemWithAssignLiveControlsProps
> = ({ user, onUnassign }) => {
  const { t } = useTranslation()

  const [isMenuOpen, { on: openMenu, off: closeMenu }] = useToggle(false)

  const sessionTooltipLabel =
    user.liveSessions.length === 1 ? user.liveSessions[0]?.data.title : 'Multiple sessions'

  const fullName = getUserName(user)
  const useEmailAsName = fullName === undefined

  return (
    <WithControlsRootContainer>
      <WithControlsInnerView isMenuOpen={isMenuOpen} justifyContent='space-between'>
        <View>
          <RoundAvatar
            firstName={user.firstName}
            lastName={user.lastName}
            src={getAvatarImage(user.uuid, user.avatar)}
            color={user.avatarColor}
            size='tiny'
          />
          <NameText>{useEmailAsName ? user.email : fullName}</NameText>
          {!useEmailAsName && <EmailText>{user.email ?? ''}</EmailText>}
        </View>
        <ControlsContainer>
          <Tooltip title={sessionTooltipLabel}>
            <Icon iconId='play--circle--filled'></Icon>
          </Tooltip>
          <StyledIconMenu
            onOpen={openMenu}
            onClose={closeMenu}
            closeOnPick
            iconId='overflow-menu--vertical'
            items={[
              {
                id: 'view-user-in-manage',
                type: 'label',
                label: t('dictionary.view'),
                onClick: () => getGlobalRouter().navigate({ to: `/manage/users/${user.uuid}` }),
              },
              {
                id: 'unassign',
                type: 'label',
                label: t('dictionary.unassign'),
                hidden: onUnassign === undefined,
                onClick: onUnassign,
              },
            ]}
          />
        </ControlsContainer>
      </WithControlsInnerView>
      <MenuItemBottomBorder />
    </WithControlsRootContainer>
  )
}

const CollaboratorTabContainer = styled(View)`
  border-bottom: 1px solid ${token('border/default')};
`

export const SharingModalMenuItemWithCollaborateControls: React.FC<{
  user: Collaborator
  contentId: CourseId
  showTransferOwnershipAction: boolean
  readOnly: boolean
  onCollaboratorRoleChange: (user: Collaborator) => void
}> = ({ user, contentId, showTransferOwnershipAction, readOnly, onCollaboratorRoleChange }) => {
  const { t } = useTranslation()
  const [
    showOwnerTransferConfirmation,
    { on: ownerTransferConfirmationOn, off: ownerTransferConfirmationOff },
  ] = useToggle()
  const { postWithUserErrorException } = usePost()
  const dispatch = useDispatch()

  const handleTransferOfOwnership = async (): Promise<void> => {
    await postWithUserErrorException(XRealtimeAuthorTransferContentOwnership, {
      contentId,
      userId: user.uuid,
    })
    void dispatch(settingsActions.fetchCollaborators({ courseId: contentId }))
  }

  const dropdownItems: MenuItem<CourseRole | 'remove'>[] = [
    {
      type: 'label' as const,
      label: t('author.can-edit'),
      id: 'editor' as const,
      selected: user.role === 'editor',
    },
    {
      type: 'label' as const,
      label: t('author.can-view-and-comment'),
      id: 'commenter' as const,
      selected: user.role === 'commenter',
    },
    { type: 'label' as const, label: t('admin.remove'), id: 'remove' as const },
    showTransferOwnershipAction
      ? {
          type: 'label' as const,
          label: t('author.owner-transfer.transfer-ownership'),
          id: 'owner' as const,
        }
      : undefined,
  ].filter(isDefined)

  const selectedItem =
    dropdownItems.find(item => item.id === user.role) ?? dropdownItems.find(item => item.id === 'commenter')

  return (
    <CollaboratorTabContainer justifyContent='space-between' gap='none'>
      <WithControlsInnerView isMenuOpen={false}>
        <RoundAvatar
          firstName={user.firstName}
          lastName={user.lastName}
          src={getAvatarImage(user.uuid, user.avatar)}
          color={user.avatarColor}
          size='tiny'
        />

        <NameText>{getUserName(user)}</NameText>
        <EmailText>{user.email ?? ''}</EmailText>
      </WithControlsInnerView>
      <ControlsContainer>
        {user.role === 'owner' ? (
          <View marginRight='xsmall'>
            <OwnerText>{t('dictionary.owner')}</OwnerText>
          </View>
        ) : readOnly ? (
          <View marginRight='xsmall'>
            <OwnerText>{dropdownItems.find(item => item.id === user.role)?.label}</OwnerText>
          </View>
        ) : (
          <SingleSelectDropdown<CourseRole | 'remove'>
            variant='ghost'
            selectedItem={selectedItem}
            menuItems={dropdownItems}
            truncateTrigger={false}
            onSelect={item => {
              switch (item.id) {
                case 'owner':
                  ownerTransferConfirmationOn()
                  break
                case 'remove':
                  onCollaboratorRoleChange(user)
                  break
                case 'editor':
                case 'commenter': {
                  const newRole = item.id
                  // Only perform request if role is different, collaborator will be removed if role is the same
                  if (user.role !== newRole) {
                    onCollaboratorRoleChange({ ...user, role: newRole })
                  }
                  break
                }
                default:
                  assertNever(item.id)
              }
            }}
          />
        )}
      </ControlsContainer>
      <ActionModal
        open={showOwnerTransferConfirmation}
        title={t('author.owner-transfer.transfer-ownership')}
        onClose={ownerTransferConfirmationOff}
        primaryAction={handleTransferOfOwnership}
        primaryActionLabel={t('dictionary.transfer')}
      >
        {t('author.owner-transfer.body')}
      </ActionModal>
    </CollaboratorTabContainer>
  )
}

const SkeletonInnerView = styled(WithControlsInnerView)`
  justify-content: flex-start;
`
const AvatarSkeleton = styled(Skeleton)`
  border-radius: 50%;
  width: 1.25rem;
  height: 1.25rem;
`

const TextSkeleton = styled(Skeleton)`
  height: 12px;
  width: 65%;
`

export const ShareModalListItemSkeleton: React.FC = () => {
  return (
    <WithControlsRootContainer>
      <View>
        <SkeletonInnerView>
          <AvatarSkeleton />
          <TextSkeleton />
        </SkeletonInnerView>
      </View>
      <MenuItemBottomBorder />
    </WithControlsRootContainer>
  )
}

const RemainingUsersContainer = styled.div`
  padding-right: 16px;
`
const EmptyGreyAvatar = styled.div`
  margin-left: -8px;
  height: 20px;
  width: 20px;
  border-radius: 50%;
  background-color: ${token('foreground/muted')};
  border: 1px solid ${token('border/default')};
`

export const RemainingUsersMenuItem: React.FC<{ remainingUsersCount: number; onClick: () => void }> = ({
  remainingUsersCount,
  onClick,
}) => {
  const { t } = useTranslation()

  return (
    <RemainingUsersContainer>
      <InnerView>
        <View>
          <View marginLeft='xxsmall' gap='none'>
            <EmptyGreyAvatar />
            <EmptyGreyAvatar />
            <EmptyGreyAvatar />
          </View>
          <Text size={'small'} color='foreground/muted'>
            {t('sharing-modal.and-x-more', { x: remainingUsersCount })}
          </Text>
        </View>
        <Link size='small' onClick={onClick}>
          {t('dictionary.view')}
        </Link>
      </InnerView>
    </RemainingUsersContainer>
  )
}
