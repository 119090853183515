import { UseQueryResult } from '@tanstack/react-query'
import { CachedQueryOptions, useCachedQuery } from 'sierra-client/state/api'
import { ListLearnerContentResponse } from 'sierra-domain/api/learn'
import { XRealtimeContentListLearnerContent } from 'sierra-domain/routes'

export const useListLearnerContent = <TSelectData = ListLearnerContentResponse>(
  options: CachedQueryOptions<TSelectData> = {}
): UseQueryResult<TSelectData> => {
  return useCachedQuery(
    XRealtimeContentListLearnerContent,
    {},
    {
      retry: false,
      ...options,
    }
  )
}
