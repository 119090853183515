import { Editor, Node, Text } from 'slate'

// Not sure if this catches all cases, but it should work.
function isYjsRoot(node: { type?: string }): boolean {
  return node.type === undefined
}

function formatNodeMeta(nodeMeta: string | undefined): string {
  if (nodeMeta === undefined || nodeMeta.length === 0) return ''

  return ` (${nodeMeta})`
}

const propsToIgnore = ['id', 'type', 'children', 'text', 'placeholder']

export function formatProps(props: Record<string, unknown>): string | undefined {
  const res: [string, string][] = []

  for (const [prop, value] of Object.entries(props)) {
    if (propsToIgnore.includes(prop)) {
      continue
    }

    if (value !== null && typeof value === 'object') {
      res.push([prop, JSON.stringify(value)])
    } else {
      res.push([prop, String(value)])
    }
  }

  if (res.length === 0) {
    return undefined
  }

  return res.map(([prop, value]) => `${prop}=${value}`).join(',')
}

type PrintTreeOptions = Partial<{
  includeText: boolean
  includeIds: boolean
  includeProps: boolean
  editorId: string
}>

export function explainTree(
  node: Node | Node[],
  options: PrintTreeOptions = {},
  level: number = 0,
  output: string[] = [],
  isLastChild: boolean = false,
  passedLevels: number[] = []
): string[] {
  if (Array.isArray(node)) return node.flatMap(n => explainTree(n, options))

  const { includeText = true, includeIds = true, includeProps = true } = options
  const indent = (input: string, level: number): string => {
    if (level === 0) return input
    return `${Array(level - 1)
      .fill(0)
      .map((_, index) => (passedLevels.includes(index) ? '   ' : '│  '))
      .join('')}${isLastChild ? '└─ ' : '├─ '}${input}`
  }

  if (Text.isText(node)) {
    if (includeText) {
      const nodePropsMeta = formatNodeMeta(includeProps ? formatProps(node) : undefined)
      output.push(indent(`"${node.text}"${nodePropsMeta}`, level))
    }

    return output
  } else if (Editor.isEditor(node)) {
    const nodeMeta = formatNodeMeta(options.editorId)
    output.push(`SlateRoot${nodeMeta}`)
  } else if (isYjsRoot(node)) {
    const nodeMeta = formatNodeMeta(options.editorId)
    output.push(`YjsRoot${nodeMeta}`)
  } else {
    const nodeMetaInner = [includeIds ? node.id : undefined, includeProps ? formatProps(node) : undefined]
      .filter(Boolean)
      .join(',')

    const nodeMeta = formatNodeMeta(nodeMetaInner)
    const nodePrint = indent(`${node.type}${nodeMeta}`, level)
    output.push(nodePrint)
  }

  for (const [i, child] of node.children.entries()) {
    const isLastChild = i === node.children.length - 1
    const newPassedLevels = isLastChild ? passedLevels.concat(level) : passedLevels.concat()
    output = explainTree(child, options, level + 1, output, isLastChild, newPassedLevels)
  }
  return output
}

export function treeToString(node: Node | Node[], options?: PrintTreeOptions): string {
  return explainTree(node, options).join('\n')
}

export function printTree(node: Node | Node[], options?: PrintTreeOptions): void {
  // eslint-disable-next-line no-console
  console.log(treeToString(node, options))
}
