import { default as React } from 'react'
import { useTranslation } from 'sierra-client/hooks/use-translation'
import { getUserStatusTranslationKey } from 'sierra-client/views/manage/users/user-utils'
import { BaseUserRow } from 'sierra-domain/user/base-user-row'
import { Label } from 'sierra-ui/components'
import { palette } from 'sierra-ui/theming'
import styled, { css } from 'styled-components'

const Wrapper = styled.div`
  display: flex;
  align-items: center;
`

// todo(patrik): decide wether to kill or redesign
export const Status: React.FC<{ progress: number; riskScore: number }> = ({ progress, riskScore }) => {
  const { t } = useTranslation()

  if (progress === 1.0) {
    return (
      <Wrapper>
        {/* <StatusGreen filled /> */}
        {t('manage.status.completed')}
      </Wrapper>
    )
  }

  if (riskScore === 0.0) {
    return (
      <Wrapper>
        {/* <StatusGreen /> */}
        {t('manage.status.on-track')}
      </Wrapper>
    )
  }

  return (
    <Wrapper>
      {/* <StatusRed /> */}
      {t('manage.status.needs-attention')}
    </Wrapper>
  )
}

export const UserStatusLabel: React.FC<{ status: BaseUserRow['status'] }> = ({ status }) => {
  const { t } = useTranslation()

  switch (status) {
    case 'pending':
      return (
        <Label $size='small' $bgColor='white' $color='grey30' $borderColor='grey30'>
          {t(getUserStatusTranslationKey(status))}
        </Label>
      )
    case 'deactivated':
      return (
        <Label $size='small' $bgColor='grey30' $color='white'>
          {t(getUserStatusTranslationKey(status))}
        </Label>
      )
    case 'active':
      return null
  }
}

type StatusTextProps = {
  filled?: boolean
  color: 'green' | 'red' | 'grey'
}

// todo(damjan): replace

export const StatusText = styled.span<StatusTextProps>`
  border-radius: 6px;
  padding: 3.25px 6px 3.75px;
  font-size: 0.75rem;
  align-self: flex-start;

  ${p => {
    const color = {
      green: p.theme.color.greenBright,
      grey: p.theme.color.grey35,
      red: p.theme.color.redVivid,
    }[p.color]

    return p.filled === true
      ? css`
          background-color: ${color};
          color: ${palette.primitives.white};
        `
      : css`
          color: ${color};
          border: 1px solid ${color};
        `
  }}
`
