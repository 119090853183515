import React from 'react'
import { getNamespacedImage } from 'sierra-client/api/content'
import { RouterLink } from 'sierra-client/components/common/link'
import { SanaLogo } from 'sierra-client/components/common/logos/sana-logo'
import { config } from 'sierra-client/config/global-config'
import { useSelector } from 'sierra-client/state/hooks'
import { selectIsRestrictedUser } from 'sierra-client/state/user/user-selector'
import { token } from 'sierra-ui/theming'
import { v2_breakpoint } from 'sierra-ui/theming/breakpoints'
import styled, { css } from 'styled-components'

const StyledSanaLogo = styled(SanaLogo)`
  display: flex;
  align-items: center;
  cursor: pointer;

  & svg {
    transition: color 100ms ease;
    color: ${token('foreground/primary')};
  }
`

const LogoContainer = styled.div<{ $isRestrictedUser: boolean; $isScorm: boolean }>`
  display: flex;
  align-items: center;

  ${p =>
    (p.$isRestrictedUser || p.$isScorm) &&
    css`
      pointer-events: none;
    `}
`

const OtherLogo = styled.img`
  max-height: 2rem;
  object-fit: contain;

  cursor: pointer;

  vertical-align: middle;

  @media screen and (min-width: ${v2_breakpoint.tablet}) {
    max-height: 2.5rem;
  }
`

const LogoWrapper = styled.div<{ inverted: boolean }>`
  transition: filter 0.3s ease-out;
  ${p =>
    p.inverted === true &&
    css`
      filter: saturate(0) brightness(0.25) invert(1);
    `};
`

const Logo = React.forwardRef<
  HTMLDivElement,
  { logo?: string; inverted: boolean } & React.HTMLAttributes<HTMLDivElement>
>(({ logo, inverted, ...props }, ref) =>
  logo !== undefined ? (
    <LogoWrapper {...props} inverted={inverted} ref={ref}>
      <OtherLogo
        src={getNamespacedImage({ type: 'organization-settings' }, logo, 'image', {
          optimize: true,
          format: 'png',
          width: 800,
        })}
      />
    </LogoWrapper>
  ) : (
    <StyledSanaLogo ref={ref} {...props} />
  )
)

export const HeaderLogo = React.forwardRef<HTMLDivElement>(
  (
    {
      className,
      inverted = false,
    }: {
      className?: string
      inverted?: boolean
    },
    ref
  ) => {
    const isRestrictedUser = useSelector(selectIsRestrictedUser)
    return (
      <LogoContainer
        $isRestrictedUser={isRestrictedUser}
        $isScorm={config.scorm.isScorm}
        className={className}
      >
        <RouterLink href={'/'}>
          <Logo logo={config.organization.settings.brand.logo ?? undefined} inverted={inverted} ref={ref} />
        </RouterLink>
      </LogoContainer>
    )
  }
)
