import log from 'loglevel'
import { Logger } from 'sierra-client/core/logging/logger'
import { Identity, PageInstanceProps, PageMessage, TrackMessage } from 'sierra-client/state/logging/types'

export class LocalLogger extends Logger {
  override track(identity: Identity, message: TrackMessage<Record<string, unknown>>): Promise<void> {
    log.debug('logging event', { ...identity, ...message })
    return Promise.resolve()
  }

  override page(identity: Identity, message: PageMessage<PageInstanceProps>): Promise<void> {
    log.debug('logging page', { ...identity, ...message })
    return Promise.resolve()
  }
}
