import { isElementType, parentType } from 'sierra-client/views/v3-author/queries'
import { isValidInteractiveCardChild } from 'sierra-domain/v3-author'
import { createParagraph } from 'sierra-domain/v3-author/create-blocks'
import { Editor, Node, Text, Transforms } from 'slate'

const debug = (...messages: unknown[]): void => console.debug('[withSections]', ...messages)

export const withHorizontalStack = (editor: Editor): Editor => {
  const { normalizeNode } = editor

  editor.normalizeNode = entry => {
    const [node, path] = entry

    if (isElementType('horizontal-stack', node)) {
      const children = Array.from(Node.children(editor, path))

      if (children.length === 0) {
        debug(`removing empty horizontal-stack at`, JSON.stringify(path))
        return Transforms.removeNodes(editor, { at: path })
      }

      if (children.length === 1) {
        debug(`horizontal-stack must contain at least two children, unwrapping at`, JSON.stringify(path))
        return Transforms.unwrapNodes(editor, { at: path })
      }

      for (const [child, childPath] of children) {
        if (Text.isText(child)) {
          debug(`Removing unexpected text node at`, JSON.stringify(childPath))
          return Transforms.removeNodes(editor, { at: childPath })
        } else if (!isElementType('vertical-stack', child)) {
          const unwrapPath = children.length === 1 ? path : childPath
          debug(`Unwrapping unexpected element from horizontal-stack at`, JSON.stringify(unwrapPath))
          return Transforms.unwrapNodes(editor, { at: unwrapPath, voids: true })
        }
      }
    }

    normalizeNode(entry)
  }

  return editor
}

export const withVerticalStack = (editor: Editor): Editor => {
  const { normalizeNode } = editor

  editor.normalizeNode = entry => {
    const [node, path] = entry

    if (isElementType('vertical-stack', node)) {
      const parent = parentType(editor, path)
      if (parent !== 'horizontal-stack') {
        debug(`vertical-stack must be wrapped in a horizontal-stack, unwrapping at`, JSON.stringify(path))
        return Transforms.unwrapNodes(editor, { at: path })
      }

      const children = Array.from(Node.children(editor, path))

      for (const [child, childPath] of children) {
        if (Text.isText(child)) {
          debug(`Wrapping text in paragraph at`, JSON.stringify(childPath))
          return Transforms.wrapNodes(editor, createParagraph(), { at: childPath })
        }

        if (!isValidInteractiveCardChild(child)) {
          debug(`Unwrapping unexpected node in ${node.type} to paragraph at`, JSON.stringify(childPath))
          return Transforms.unwrapNodes(editor, { at: childPath, voids: true })
        }
        const at = path.concat(0)
        if (children.length === 0) {
          debug(`Adding missing paragraph child in vertical-stack at`, JSON.stringify(at))
          return Transforms.insertNodes(editor, createParagraph(), { at })
        }
      }
    }

    normalizeNode(entry)
  }

  return editor
}
