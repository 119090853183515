import { useTranslation } from 'sierra-client/hooks/use-translation'
import { FCC } from 'sierra-client/types'
import {
  AutoexpandingReflectionInput,
  KeyboardShortcutText,
} from 'sierra-client/views/v3-author/reflection-card/atoms'
import { ReflectionCard } from 'sierra-domain/v3-author'
import { KeyboardButtonGroup } from 'sierra-ui/components'
import { Button, View } from 'sierra-ui/primitives'
import { LightTokenProvider, spacing, token } from 'sierra-ui/theming'
import { LightTheme } from 'sierra-ui/theming/legacy-theme'
import styled from 'styled-components'

const StyledGrid = styled.div`
  padding: ${spacing['80']} ${spacing.large};
  /* display: flex;
  flex-direction: column;
  flex-grow: 1;
  position: relative; */
`

const ReflectionsBlockContainer = styled.div`
  margin-top: 2rem;
`

const StyledReflectionInputInner = styled(View).attrs({
  direction: 'column',
  background: 'surface/default',
})`
  font-size: 1rem;
  border: 1px solid ${token('border/default')};
  border-radius: 12px;
  padding: 1.5rem;
  margin-bottom: 1rem;
`

const PreviewInputContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  padding-right: 1.5rem;
  padding-bottom: 3rem;
  width: 50%;
  min-width: 300px;
`

const ButtonContainer = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
`

export const ReflectionCardPreview: FCC<{ node: ReflectionCard }> = ({ children }) => {
  const { t } = useTranslation()

  return (
    <StyledGrid>
      {children}
      <ReflectionsBlockContainer>
        <PreviewInputContainer>
          <LightTheme>
            <LightTokenProvider>
              <StyledReflectionInputInner>
                <AutoexpandingReflectionInput
                  placeholder={t('dictionary.add-a-note')}
                  value={''}
                  onChange={() => {}}
                />
              </StyledReflectionInputInner>
            </LightTokenProvider>
          </LightTheme>
          <ButtonContainer>
            <Button variant='ghost' onClick={() => {}}>
              {t('dictionary.submit')}
            </Button>
            <KeyboardShortcutText>
              <span>{t('dictionary.or-press')}</span>
              <KeyboardButtonGroup
                listen
                codes={[/mac/i.test(navigator.platform) ? 'cmd' : 'ctrl', 'Enter']}
              />
            </KeyboardShortcutText>
          </ButtonContainer>
        </PreviewInputContainer>
      </ReflectionsBlockContainer>
    </StyledGrid>
  )
}
