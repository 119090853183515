import { SortOrder } from 'sierra-client/api/graphql/gql/graphql'
import { lowercase } from 'sierra-domain/utils'

type SortOrderLike = 'ascending' | 'descending'
/* Helper to convert common sorting words to the SortOrder type used by graphql
 *
 * Example:
 * ```typescript
 * toGQLSortOrder("ascending") -> "ASC"
 * toGQLSortOrder("ASCENDING") -> "ASC"
 *
 * toGQLSortOrder("descending") -> "DESC"
 * toGQLSortOrder("DESCENDING") -> "DESC"
 * ```
 */
export const toGQLSortOrder = (s: SortOrderLike | Uppercase<SortOrderLike>): SortOrder => {
  const order = lowercase(s)
  switch (order) {
    case 'ascending':
      return 'ASC'
    case 'descending':
      return 'DESC'
  }
}
