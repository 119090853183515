import _ from 'lodash'
import { useCallback, useEffect, useMemo, useState } from 'react'
import { NoteId, NoteMetadata, SectionId, StickyNotesCardYjsApi } from 'sierra-domain/card/sticky-notes-card'

export const getSectionNoteIds = (
  api: StickyNotesCardYjsApi,
  sectionId: SectionId,
  sectionIds: SectionId[]
): NoteId[] => {
  const positions = api.getPositionArray()

  const sectionNotesIds = positions.filter(pos => pos.sectionId === sectionId).map(pos => pos.noteId)

  const isFirstSection = sectionId === sectionIds[0]

  const orphanNoteIds = isFirstSection
    ? positions.filter(pos => !sectionIds.includes(pos.sectionId)).map(pos => pos.noteId)
    : []

  return [...sectionNotesIds, ...orphanNoteIds]
}

export const useSectionNoteIds = (
  api: StickyNotesCardYjsApi,
  sectionId: SectionId,
  sectionIds: SectionId[]
): NoteId[] => {
  const [notes, setNotes] = useState<NoteId[]>([])

  useEffect(() => {
    let timeout: NodeJS.Timeout
    const syncNotes = (): void => {
      setNotes(current => {
        const notes = getSectionNoteIds(api, sectionId, sectionIds)
        if (_.isEqual(notes, current)) return current
        return notes
      })
    }

    const updateNotes = (): void => {
      syncNotes()
      timeout = setTimeout(() => {
        updateNotes()
      }, 2000)
    }

    updateNotes()
    return () => clearTimeout(timeout)
  }, [api, sectionId, sectionIds])

  return notes
}

export const useNote = (
  api: StickyNotesCardYjsApi,
  noteId: NoteId
): [NoteMetadata | undefined, (update: (old: NoteMetadata) => NoteMetadata) => void] => {
  const [note, _setNote] = useState<NoteMetadata>(() => {
    const yMetadata = api.getYNoteMetadata(noteId)

    return NoteMetadata.parse(yMetadata.toJSON())
  })

  useEffect(() => {
    const yMetadata = api.getYNoteMetadata(noteId)

    const handler = (): void => _setNote(NoteMetadata.parse(yMetadata.toJSON()))
    handler()
    yMetadata.observeDeep(handler)

    return () => yMetadata.unobserveDeep(handler)
  }, [api, noteId])

  const setNote = useCallback(
    (update: (old: NoteMetadata) => NoteMetadata) => api.setNoteMetadata(noteId, update),
    [noteId, api]
  )

  return useMemo(() => [note, setNote], [note, setNote])
}
