import React from 'react'
import { useTimeFormatter } from 'sierra-client/core/format'
import { RenderHint } from 'sierra-client/lib/tabular/hints'
import { formatPercentage } from 'sierra-client/views/measure/analytics-v2/util'
import { TruncatedText } from 'sierra-ui/components'

export const NumberCell: React.FC<{ cellData: number; hints: RenderHint[] }> = ({ cellData, hints }) => {
  const { timeFormatter } = useTimeFormatter()

  return hints.includes('percentage') ? (
    <TruncatedText lines={1} size='small'>
      {formatPercentage(cellData)}
    </TruncatedText>
  ) : hints.includes('duration') ? (
    <TruncatedText lines={1} size='small'>
      {cellData === 0 ? '-' : `${timeFormatter(cellData)}`}
    </TruncatedText>
  ) : (
    <TruncatedText lines={1} size='small'>
      {`${cellData}`}
    </TruncatedText>
  )
}
