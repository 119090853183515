import { useSelector } from 'sierra-client/state/hooks'
import { selectCurrentUserIsActor } from 'sierra-client/state/live-session/selectors'

/**
 * Not sure if this actually works, the awareness states seems to not be updating on a closed session...
 * @returns
 */
export const useShouldAct = (): boolean => {
  return useSelector(selectCurrentUserIsActor)
}
