import { DateTime, Interval } from 'luxon'
import {
  CalendarEventLocationFragmentFragment,
  CalendarEventScheduleFragmentFragment,
  CalendarEventScheduleInput,
} from 'sierra-client/api/graphql/gql/graphql'
import { CalendarEventSchedule, EventLocation } from 'sierra-client/views/manage/event-groups/types'
import { CalendarEventId } from 'sierra-domain/api/nano-id'
import { ScheduledLiveSessionBase } from 'sierra-domain/content/session'
import { ImageUnion } from 'sierra-domain/content/v2/content'
import { isNonNullable } from 'sierra-domain/utils'

export type EventRow = {
  data: {
    id: CalendarEventId
    schedule: CalendarEventSchedule
    title: string
    participantLimit?: number
    location?: EventLocation
    image?: ImageUnion | undefined
    assignedUsers: {
      id: string
    }[]
    facilitators: {
      id: string
      displayName: string
    }[]
  }
}

type EventGroupSessionToCsvData = {
  eventGroupId: string
  data: EventRow['data']
  assignedUsers?: number
}

export const getEventLocationString = (location: EventLocation | undefined): string | undefined => {
  if (location === undefined) return undefined

  switch (location.type) {
    case 'google-place':
      return location.secondaryText !== undefined
        ? location.mainText + ', ' + location.secondaryText
        : location.mainText
    case 'simple': {
      if (location.address === '') return undefined

      return location.address
    }
  }
}

export const gqlEventLocationToEventLocation = (
  gqlLocation: CalendarEventLocationFragmentFragment | null | undefined
): EventLocation | undefined => {
  if (!isNonNullable(gqlLocation)) return undefined

  const location = gqlLocation

  switch (location.__typename) {
    case 'SimpleLocation':
      return {
        type: 'simple',
        address: location.address,
      }
    case 'GooglePlaceLocation':
      return {
        type: 'google-place',
        placeId: location.placeId,
        mainText: location.mainText,
        secondaryText: location.secondaryText ?? undefined,
      }
  }
}

export const gqlEventLocationString = (
  gqlLocation: CalendarEventLocationFragmentFragment | null | undefined
): string | undefined => getEventLocationString(gqlEventLocationToEventLocation(gqlLocation))

export const gqlEventScheduleToEventSchedule = (
  gqlEventSchedule: CalendarEventScheduleFragmentFragment
): CalendarEventSchedule => {
  // if (!isNonNullable(gqlEventSchedule)) return undefined

  const schedule = gqlEventSchedule

  switch (schedule.__typename) {
    case 'CalendarEventScheduleAllDay':
      return {
        type: 'all-day',
        startDate: DateTime.fromISO(schedule.startDate),
        endDate: DateTime.fromISO(schedule.endDate),
      }
    case 'CalendarEventScheduleWithTime':
      return {
        type: 'with-time',
        timeZone: schedule.timeZone,
        startTime: DateTime.fromISO(schedule.startTime),
        endTime: DateTime.fromISO(schedule.endTime),
      }
  }
}

export const eventScheduleToGqlInput = (schedule: CalendarEventSchedule): CalendarEventScheduleInput => {
  switch (schedule.type) {
    case 'all-day':
      return {
        allDay: true,
        // It is important to send all-day start and end time as UTC, because otherwise the timezone might cause a shift
        // in the date, causing the set date to be different from what the user intends.
        startTime: schedule.startDate.setZone('UTC', { keepLocalTime: true }).toISO(),
        endTime: schedule.endDate.setZone('UTC', { keepLocalTime: true }).toISO(),
        timeZone: undefined,
      }
    case 'with-time': {
      return {
        allDay: false,
        startTime: schedule.startTime.toISO(),
        endTime: schedule.endTime.toISO(),
        timeZone: schedule.timeZone,
      }
    }
  }
}

export const getEventScheduleStart = (schedule: CalendarEventSchedule): DateTime => {
  switch (schedule.type) {
    case 'all-day':
      return schedule.startDate
    case 'with-time':
      return schedule.startTime
  }
}

export const getEventScheduleEnd = (schedule: CalendarEventSchedule): DateTime => {
  switch (schedule.type) {
    case 'all-day':
      return schedule.endDate
    case 'with-time':
      return schedule.endTime
  }
}

export const getEventScheduleTimeZone = (schedule: CalendarEventSchedule): string | undefined => {
  switch (schedule.type) {
    case 'all-day':
      return undefined
    case 'with-time':
      return schedule.timeZone
  }
}

export const getGqlEventScheduleStart = (
  gqlEventSchedule: CalendarEventScheduleFragmentFragment
): DateTime => {
  const schedule = gqlEventScheduleToEventSchedule(gqlEventSchedule)
  return getEventScheduleStart(schedule)
}

export const getGqlEventScheduleEnd = (gqlEventSchedule: CalendarEventScheduleFragmentFragment): DateTime => {
  const schedule = gqlEventScheduleToEventSchedule(gqlEventSchedule)
  return getEventScheduleEnd(schedule)
}

export const eventScheduleIsUpcoming = (schedule: CalendarEventSchedule): boolean => {
  switch (schedule.type) {
    case 'with-time':
      return schedule.endTime > DateTime.now()
    case 'all-day':
      return schedule.endDate > DateTime.now().minus({ hour: 24 })
  }
}

export const gqlEventScheduleIsUpcoming = (
  gqlEventSchedule: CalendarEventScheduleFragmentFragment
): boolean => {
  const schedule = gqlEventScheduleToEventSchedule(gqlEventSchedule)
  return eventScheduleIsUpcoming(schedule)
}

type FormatEventScheduleOptions = {
  month: 'short' | 'long'
}

const DEFAULT_FORMAT_SCHEDULE_OPTIONS: FormatEventScheduleOptions = {
  month: 'short',
}

const formatEventScheduleAllDay = (
  schedule: Extract<CalendarEventSchedule, { type: 'all-day' }>,
  options: FormatEventScheduleOptions
): string => {
  const hasSameEndDate = schedule.startDate.hasSame(schedule.endDate, 'day')
  if (hasSameEndDate) {
    return schedule.startDate.toLocaleString({ month: options.month, day: 'numeric' })
  }

  const hasSameMonth = schedule.startDate.hasSame(schedule.endDate, 'month')
  if (hasSameMonth) {
    const interval = Interval.fromDateTimes(schedule.startDate, schedule.endDate)
    const format = interval.toLocaleString({
      day: 'numeric',
      month: options.month,
    })

    return `${format}`
  }

  const startDate = schedule.startDate.toLocaleString({
    month: options.month,
    day: 'numeric',
  })
  const endDate = schedule.endDate.toLocaleString({ month: options.month, day: 'numeric' })

  return `${startDate} - ${endDate}`
}

const formatEventScheduleWithTime = (
  schedule: Extract<CalendarEventSchedule, { type: 'with-time' }>,
  options: FormatEventScheduleOptions
): string => {
  const isMultiDayEvent = !schedule.startTime.hasSame(schedule.endTime, 'day')

  const hasDifferentTimeZoneFromLocal = schedule.timeZone !== DateTime.local().zoneName
  const timeZonePart = hasDifferentTimeZoneFromLocal ? ` (${DateTime.local().zoneName})` : ''

  if (isMultiDayEvent) {
    const startTime = schedule.startTime.toLocaleString({
      month: options.month,
      day: 'numeric',
      hour: '2-digit',
      minute: '2-digit',
    })
    const endTime = schedule.endTime.toLocaleString({
      month: options.month,
      day: 'numeric',
      hour: '2-digit',
      minute: '2-digit',
    })

    return `${startTime} - ${endTime}${timeZonePart}`
  }

  const dateAndMonth = schedule.startTime.toLocaleString({
    month: options.month,
    day: 'numeric',
  })

  const startTime = schedule.startTime.toLocaleString(DateTime.TIME_SIMPLE)
  const endTime = schedule.endTime.toLocaleString(DateTime.TIME_SIMPLE)

  return `${dateAndMonth}, ${startTime}-${endTime}${timeZonePart}`
}

export const formatEventSchedule = (
  schedule: CalendarEventSchedule,
  options: FormatEventScheduleOptions = DEFAULT_FORMAT_SCHEDULE_OPTIONS
): string => {
  switch (schedule.type) {
    case 'all-day':
      return formatEventScheduleAllDay(schedule, options)
    case 'with-time':
      return formatEventScheduleWithTime(schedule, options)
  }
}

export const formatLiveSessionTimeAsSchedule = (
  session?: ScheduledLiveSessionBase | null
): string | undefined => {
  if (!session) {
    return undefined
  }

  const { allDay, startTime, endTime } = session
  const startDateTime = DateTime.fromISO(startTime)
  const endDateTime = DateTime.fromISO(endTime)

  const schedule: CalendarEventSchedule =
    allDay === true
      ? {
          type: 'all-day',
          startDate: startDateTime,
          endDate: endDateTime,
        }
      : {
          type: 'with-time',
          startTime: startDateTime,
          endTime: endDateTime,
          timeZone: startDateTime.zoneName,
        }

  return formatEventSchedule(schedule, { month: 'long' })
}

export const formatGqlEventSchedule = (
  gqlEventSchedule: CalendarEventScheduleFragmentFragment,
  options?: FormatEventScheduleOptions
): string => formatEventSchedule(gqlEventScheduleToEventSchedule(gqlEventSchedule), options)

export const eventScheduleValid = (schedule: CalendarEventSchedule): boolean => {
  const start = getEventScheduleStart(schedule)
  const end = getEventScheduleEnd(schedule)
  if (schedule.type === 'all-day') {
    return start.startOf('day') <= end.startOf('day')
  } else {
    return start <= end
  }
}

export const eventGroupToCsv = ({
  eventGroupId,
  data,
  assignedUsers,
}: EventGroupSessionToCsvData): Record<string, string> => {
  const { title, schedule, location, participantLimit } = data

  const assignmentsPart =
    assignedUsers === undefined
      ? undefined
      : {
          assignedUsers: `${assignedUsers}`,
        }

  return {
    id: eventGroupId,
    title: title,
    startTime: getEventScheduleStart(schedule).toISO(),
    endTime: getEventScheduleEnd(schedule).toISO(),
    location: getEventLocationString(location) ?? '',
    maxNumberOfUsers: `${participantLimit ?? 0}`,
    facilitators: data.facilitators.map(f => f.displayName).join(', '),
    ...assignmentsPart,
  }
}

export const constructScheduleFromLiveData = (sessionData: {
  allDay: boolean
  startTime: string
  endTime: string
}): CalendarEventSchedule => {
  const startTime = DateTime.fromISO(sessionData.startTime)
  const endTime = DateTime.fromISO(sessionData.endTime)
  const isAllDay = sessionData.allDay

  if (isAllDay) {
    return {
      type: 'all-day',
      startDate: startTime.startOf('day'),
      endDate: endTime.startOf('day'),
    }
  }

  return {
    type: 'with-time',
    timeZone: DateTime.local().zoneName,
    startTime,
    endTime,
  }
}

export const eventScheduleIsToday = (schedule: CalendarEventSchedule): boolean => {
  const start = getEventScheduleStart(schedule)
  const end = getEventScheduleEnd(schedule)

  return start.hasSame(DateTime.now(), 'day') || end.hasSame(DateTime.now(), 'day')
}

export const eventScheduleIsTomorrow = (schedule: CalendarEventSchedule): boolean => {
  const start = getEventScheduleStart(schedule)
  const end = getEventScheduleEnd(schedule)

  return (
    start.hasSame(DateTime.now().plus({ days: 1 }), 'day') ||
    end.hasSame(DateTime.now().plus({ days: 1 }), 'day')
  )
}
