import _ from 'lodash'
import { Dispatch, SetStateAction } from 'react'
import { SharingPill } from 'sierra-client/components/sharing/tabs/components/pill'
import { getUserName } from 'sierra-domain/utils'
import { Tooltip } from 'sierra-ui/components'
import { Text } from 'sierra-ui/primitives'
import { spacing } from 'sierra-ui/theming'
import styled from 'styled-components'

const ELLIPSIS = '...'

type BaseUser<K extends string> = {
  firstName?: string
  lastName?: string
  email?: string
} & {
  [key in K]: string
}

type RenderTagsProps<K extends string, T extends BaseUser<K>> = {
  selectedUsers: T[]
  setSelectedUsers: Dispatch<SetStateAction<T[]>>
  maxNumberOfTags?: number
  uuidKey: K
}

const TooltipList = styled.ul`
  list-style: none;

  li:not(:last-child) {
    margin-bottom: ${spacing['2']};
  }
`

export const RenderTags = <K extends string, T extends BaseUser<K>>({
  selectedUsers,
  setSelectedUsers,
  maxNumberOfTags = 2,
  uuidKey,
}: RenderTagsProps<K, T>): JSX.Element => {
  const remainder = selectedUsers.length - maxNumberOfTags

  return (
    <>
      {selectedUsers.slice(0, maxNumberOfTags).map(user => {
        const fullName = getUserName(user) ?? ''
        const displayName = fullName.trim().length === 0 ? user.email : fullName
        const truncatedDisplayName = _.truncate(displayName, {
          length: selectedUsers.length === 1 ? 26 : 10,
          omission: ELLIPSIS,
        })

        const sharingPill = (
          <SharingPill
            onClick={() => setSelectedUsers(previous => previous.filter(u => u[uuidKey] !== user[uuidKey]))}
          >
            {truncatedDisplayName}
          </SharingPill>
        )

        return (
          <div key={user[uuidKey]}>
            {truncatedDisplayName.endsWith(ELLIPSIS) ? (
              <Tooltip title={displayName}>
                <div>{sharingPill}</div>
              </Tooltip>
            ) : (
              sharingPill
            )}
          </div>
        )
      })}
      {remainder > 0 && (
        <Text size='small' bold>
          <Tooltip
            title={
              <TooltipList>
                {selectedUsers.slice(-remainder).map(user => (
                  <li key={user[uuidKey]}>{getUserName(user)}</li>
                ))}
              </TooltipList>
            }
          >
            <span>+{remainder}</span>
          </Tooltip>
        </Text>
      )}
    </>
  )
}
