import _ from 'lodash'
import { useEffect } from 'react'
import { useCachedQuery } from 'sierra-client/state/api'
import { chatChanged } from 'sierra-client/state/chat/actions'
import { useDispatch } from 'sierra-client/state/hooks'
import { useCreatePageContextSafe } from 'sierra-client/views/flexible-content/create-page-context'
import { ChatData, ChatIdentifier } from 'sierra-domain/api/chat'
import { Message } from 'sierra-domain/chat'
import { ScopedChatId, ScopedLiveSessionId } from 'sierra-domain/collaboration/types'
import { XRealtimeChatGetMessages } from 'sierra-domain/routes'
import { iife } from 'sierra-domain/utils'
import { util } from 'zod'
import assertNever = util.assertNever

const backendChatDataToClientMessage = (message: ChatData): Message => {
  switch (message.type) {
    case 'message': {
      const reactions = _.keyBy(
        message.reactions.map(r => ({
          id: r.reactionId,
          userId: r.userId,
          reaction: r.reaction,
          timeSent: r.createdAt,
        })),
        'id'
      )

      if (message.commentData !== undefined) {
        return {
          id: message.messageId,
          type: 'tiptap-comment',
          userId: message.userId,
          timeSent: message.createdAt,
          timeEdited: message.editedAt,
          tiptapJsonData: message.messageData,
          contentReference: message.commentData.contentRef,
          resolvedAt: message.commentData.resolved?.resolvedAt.toISOString(),
          resolvedBy: message.commentData.resolved?.resolvedBy,
          detachedFromContent: message.commentData.detachedFromContent,
          reactions,
        }
      }

      return {
        id: message.messageId,
        type: 'tiptap-plain',
        userId: message.userId,
        timeSent: message.createdAt,
        timeEdited: message.editedAt,
        tiptapJsonData: message.messageData,
        reactions,
      }
    }
    case 'reply':
      return {
        id: message.messageId,
        type: 'tiptap-plain',
        userId: message.userId,
        responseToMessageId: message.responseToMessageId,
        timeSent: message.createdAt,
        timeEdited: message.editedAt,
        tiptapJsonData: message.messageData,
        reactions: _.keyBy(
          message.reactions.map(r => ({
            id: r.reactionId,
            userId: r.userId,
            reaction: r.reaction,
            timeSent: r.createdAt,
          })),
          'id'
        ),
      }
    case 'emoji':
      return {
        id: message.messageId,
        type: 'emoji',
        userId: message.userId,
        timeSent: message.createdAt,
        emoji: message.messageData.emoji,
      }
  }
}

export const SyncSanaNowBackendChatToRedux = ({
  chatIdentifier,
}: {
  chatIdentifier: ChatIdentifier
}): null => {
  const createPageContext = useCreatePageContextSafe()

  const chatId = iife(() => {
    switch (chatIdentifier.type) {
      case 'live-session':
        return ScopedChatId.fromId(ScopedLiveSessionId.fromId(chatIdentifier.liveSessionId))
      case 'course':
        if (createPageContext === undefined) return undefined
        return ScopedChatId.fromId(createPageContext.scopedCreateContentId)
      default:
        assertNever(chatIdentifier)
    }
  })

  const dispatch = useDispatch()

  const sanaNowChatQuery = useCachedQuery(XRealtimeChatGetMessages, {
    chatIdentifier,
  })

  useEffect(() => {
    if (chatId === undefined) return
    if (sanaNowChatQuery.data === undefined) return

    const { messages } = sanaNowChatQuery.data
    const formattedMessages: Message[] = messages.map(backendChatDataToClientMessage)

    const chat = { messages: _.keyBy(formattedMessages, 'id') }

    dispatch(chatChanged({ chatId, chat }))
  }, [chatId, dispatch, sanaNowChatQuery.data])

  return null
}
