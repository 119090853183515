import { UseQueryResult } from '@tanstack/react-query'
import { graphql } from 'sierra-client/api/graphql/gql'
import { VideoProviderAuthenticatedQuery } from 'sierra-client/api/graphql/gql/graphql'
import { useGraphQuery } from 'sierra-client/api/hooks/use-graphql-query'

export const useVideoCallProviderAuthenticatedQuery = (): UseQueryResult<
  VideoProviderAuthenticatedQuery,
  unknown
> => {
  return useGraphQuery(
    {
      document: graphql(`
        query VideoProviderAuthenticated {
          viewer {
            integrations {
              googleMeetAuthenticated
              googleMeetClientId
              microsoftTeamsAuthenticated
              microsoftTeamsClientId
              zoomAuthenticated
              zoomClientId
            }
          }
        }
      `),
    },
    {}
  )
}
