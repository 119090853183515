import { getAvatarImage } from 'sierra-client/utils/avatar-img'
import { Avatar } from 'sierra-domain/api/manage'

export function parseDuration(duration: string): {
  hours: number
  minutes: number
  seconds: number
  milliseconds: number
} {
  // Remove the "PT" prefix from the duration string
  const durationString = duration.slice(2)

  // Split the string into its components
  const components = durationString.split(/H|M|S/)

  const hours = components[0] !== undefined ? parseInt(components[0]) : 0
  const minutes = components[1] !== undefined ? parseInt(components[1]) : 0
  const seconds = components[2] !== undefined ? parseInt(components[2]) : 0
  const milliseconds = components[3] !== undefined ? parseFloat(components[3]) * 1000 : 0

  return { hours, minutes, seconds, milliseconds }
}

export const getSrcFromFileAvatar = (userId: string, avatar: Avatar): string | undefined => {
  return avatar.type === 'image'
    ? avatar.image.type === 'file'
      ? getAvatarImage(userId, avatar.image.file)
      : undefined
    : undefined
}
