import _ from 'lodash'
import React, { useMemo, useRef, useState } from 'react'
import GridLayout, { ItemCallback, ReactGridLayoutProps } from 'react-grid-layout'
import 'react-grid-layout/css/styles.css'
import { SizeMeProps, withSize } from 'react-sizeme'
import { WidgetCard } from 'sierra-client/features/insights/display-widgets/widget-card'
import { Filter } from 'sierra-client/lib/filter'
import { DashboardWidget } from 'sierra-domain/api/insights'
import { View } from 'sierra-ui/primitives'
import styled, { css } from 'styled-components'
import { NUMBER_OF_COLUMNS } from './constants'
import { ResizeHandle } from './resize-handle'

const WidgetItem = styled.div<{ activeCorner: 'nw' | 'ne' | 'sw' | 'se' | undefined }>`
  border-radius: 12px;

  &:hover {
    ${props => `.react-resizable-handle-${props.activeCorner} {
      opacity: 1;
    }`}
  }
`

const WidgetGridContainer = styled(View)<{ enableTransition: boolean }>`
  display: block;
  margin: 0 2px;

  & .react-grid-placeholder {
    border-radius: 16px;
    background-color: ${p => p.theme.color.grey20};
  }
  & .react-grid-item {
    ${props =>
      !props.enableTransition &&
      css`
        transition: none;
      `}
  }
`

const ResponsiveGridLayout = withSize({ monitorWidth: true })(({
  size,
  ...rest
}: ReactGridLayoutProps & SizeMeProps) => {
  return <GridLayout width={size.width ?? undefined} {...rest} />
})

type WidgetGridProps = {
  dashboardWidgets: DashboardWidget[]
  canEdit: boolean
  dashboardFilter?: Filter
  onSelect: (dashboardWidget: DashboardWidget) => void
  onRemove?: (dashboardWidget: DashboardWidget) => void
  onDuplicate?: (dashboardWidget: DashboardWidget) => void
  onTitleChange?: (widgetId: string, newTitle: string, automatic: boolean) => void
  onLayoutChange?: (newWidgets: DashboardWidget[]) => void
}

export const WidgetGrid: React.FC<WidgetGridProps> = ({
  onSelect,
  onRemove,
  onDuplicate,
  dashboardWidgets,
  onLayoutChange,
  onTitleChange,
  canEdit,
  dashboardFilter,
}) => {
  const handleLayoutChange = (layout: GridLayout.Layout[]): void => {
    const newDashboardWidgets = dashboardWidgets.map(dashboardWidget => {
      const layoutItem = layout.find(layoutItem => layoutItem.i === dashboardWidget.id)

      const newDashboardWidget: DashboardWidget = {
        ...dashboardWidget,
        layout:
          layoutItem === undefined
            ? dashboardWidget.layout
            : {
                x: layoutItem.x,
                y: layoutItem.y,
                width: layoutItem.w,
                height: layoutItem.h,
              },
      }

      return newDashboardWidget
    })

    let layoutHasChanged = false

    for (const newWidget of newDashboardWidgets) {
      const oldWidget = dashboardWidgets.find(dashboardWidget => dashboardWidget.id === newWidget.id)

      if (!_.isEqual(oldWidget?.layout, newWidget.layout)) {
        layoutHasChanged = true
        break
      }
    }

    if (layoutHasChanged) {
      onLayoutChange && onLayoutChange(newDashboardWidgets)
    }
  }

  const layout = useMemo(() => {
    const gridLayout: GridLayout.Layout[] = dashboardWidgets.map(dashboardWidget => {
      const widgetLayout: GridLayout.Layout = {
        ...dashboardWidget.layout,
        i: dashboardWidget.id,
        w: dashboardWidget.layout.width,
        h: dashboardWidget.layout.height,
        minW: 3,
      }

      switch (dashboardWidget.widget.type) {
        case 'widget.aggregation-table':
        case 'widget.bar-chart':
        case 'widget.line-chart':
          widgetLayout.minH = 2
          break
        case 'widget.metric':
        case 'widget.progress-bar':
          widgetLayout.minH = 1
          break
      }

      switch (dashboardWidget.widget.type) {
        case 'widget.progress-bar':
          widgetLayout.maxH = 1
          break
      }

      return widgetLayout
    })
    return gridLayout
  }, [dashboardWidgets])

  const [wasInteractedWith, setWasInteractedWith] = useState(false)

  const dragStartPositionRef = useRef<{ x: number; y: number }>()

  const onDragStart: ItemCallback = (layout, oldItem, newItem, placeholder, event) => {
    dragStartPositionRef.current = {
      x: event.clientX,
      y: event.clientY,
    }
    if (!wasInteractedWith) {
      setWasInteractedWith(true)
    }
  }

  const onDragStop: ItemCallback = (layout, oldItem, newItem, placeholder, event) => {
    if (!canEdit) return
    if (dragStartPositionRef.current === undefined) return

    const widget = dashboardWidgets.find(widget => widget.id === newItem.i)
    if (widget === undefined) return

    // If the widget was dragged less than 6 pixels, we count it as a click
    const xDiff = Math.abs(dragStartPositionRef.current.x - event.clientX)
    const yDiff = Math.abs(dragStartPositionRef.current.y - event.clientY)
    if (xDiff + yDiff < 6) {
      // We give some lenience to clicks that move slightly and still count them as normal clicks
      onSelect(widget)
    }
  }

  const onResizeStart: ItemCallback = () => {
    if (!wasInteractedWith) {
      setWasInteractedWith(true)
    }
  }

  const [activeCorner, setActiveCorner] = useState<'nw' | 'ne' | 'sw' | 'se' | undefined>(undefined)
  const calculateClosestCorner = (
    event: React.MouseEvent<HTMLDivElement, MouseEvent>,
    currentTarget: (EventTarget & HTMLDivElement) | null
  ): void => {
    if (currentTarget === null) return

    const rect = currentTarget.getBoundingClientRect()
    const x = event.clientX - rect.left
    const y = event.clientY - rect.top
    const top = y < rect.height / 2
    const left = x < rect.width / 2
    const corner = top ? (left ? 'nw' : 'ne') : left ? 'sw' : 'se'

    setActiveCorner(corner)
  }

  const throttledOnMouseMove = _.throttle((event: React.MouseEvent<HTMLDivElement, MouseEvent>) => {
    return calculateClosestCorner(event, event.currentTarget)
  }, 20)

  return (
    <WidgetGridContainer enableTransition={wasInteractedWith} data-intercom-target='widget-grid'>
      <ResponsiveGridLayout
        layout={layout}
        cols={NUMBER_OF_COLUMNS}
        rowHeight={200}
        isResizable={canEdit}
        isDraggable={canEdit}
        margin={[16, 16]}
        containerPadding={[0, 0]}
        onLayoutChange={canEdit ? handleLayoutChange : undefined}
        onDragStart={onDragStart}
        onDragStop={onDragStop}
        onResizeStart={onResizeStart}
        resizeHandles={['sw', 'nw', 'se', 'ne']}
        resizeHandle={<ResizeHandle handleAxis='se' />}
        draggableHandle='.react-grid-layout-drag-handle' // This needs to map to a component with this specific className
      >
        {dashboardWidgets.map(dashboardWidget => {
          return (
            <WidgetItem
              key={dashboardWidget.id}
              onMouseMove={throttledOnMouseMove}
              activeCorner={activeCorner}
            >
              <WidgetCard
                dashboardFilter={dashboardFilter}
                dashboardWidget={dashboardWidget}
                onSelect={onSelect}
                onRemove={canEdit ? onRemove : undefined}
                onDuplicate={canEdit ? onDuplicate : undefined}
                onTitleChange={canEdit ? onTitleChange : undefined}
              />
            </WidgetItem>
          )
        })}
      </ResponsiveGridLayout>
    </WidgetGridContainer>
  )
}
