import { yTextToSlateElement } from '@slate-yjs/core'
import { ScopedYDocId } from 'sierra-domain/collaboration/types'
import { patchEditor } from 'sierra-domain/patch-editor'
import { SanaEditor } from 'sierra-domain/v3-author'
import { BaseEditor, Editor } from 'slate'
import * as Y from 'yjs'

export type ReadOnlyYjsEditor = SanaEditor & {
  connect: () => void
  disconnect: () => void
  connected: boolean
  sharedRoot: Y.XmlText
  _isReadOnlyYjsEditor: true
  yDocId: ScopedYDocId
}

export function isReadOnlyYjsEditor(editor: BaseEditor): editor is ReadOnlyYjsEditor {
  return '_isReadOnlyYjsEditor' in editor && editor._isReadOnlyYjsEditor === true
}

/**
 * In @slate-yjs/core version 0.3.1, the editor normalizes the document when it is connected.
 * This is a problem for readOnly editors, because they should never mutate the underlying yDoc.
 * To get around this, we set up a read-only yDoc that mirrors the content of the yType, without
 * writing changes back to the original remote yDoc.
 */
export function withReadOnlyYjsEditor(
  _editor: Editor,
  yType: Y.XmlText,
  yDocId: ScopedYDocId
): ReadOnlyYjsEditor {
  const editor = _editor as ReadOnlyYjsEditor
  editor.connected = false
  editor._isReadOnlyYjsEditor = true
  editor.sharedRoot = yType
  editor.yDocId = yDocId

  function syncRemoteIntoLocal(): void {
    patchEditor(editor, yTextToSlateElement(yType).children)
  }
  editor.connect = function connect(): void {
    yType.observeDeep(syncRemoteIntoLocal)
    editor.children = yTextToSlateElement(yType).children
    editor.connected = true
    editor.onChange()
  }

  editor.disconnect = function disconnect(): void {
    yType.unobserveDeep(syncRemoteIntoLocal)
    editor.connected = false
  }

  return editor
}
