import { Link } from 'sierra-client/components/common/link'
import { FCC } from 'sierra-client/types'
import { CSSPropTwo, Spacing } from 'sierra-ui/types'
import { resolveSizes } from 'sierra-ui/utils'
import styled, { css } from 'styled-components'

export type ItemLinkProps = {
  $gap: CSSPropTwo<Spacing>
  $padding: CSSPropTwo<Spacing>
}

export const itemLinkCSS = css<ItemLinkProps>`
  display: flex;
  align-items: center;
  flex-direction: row;
  gap: ${p => resolveSizes(p.$gap)};
  padding: ${p => resolveSizes(p.$padding)};
  height: 36px;
  border-radius: ${p => p.theme.borderRadius['size-10']};
  transition: background-color 200ms cubic-bezier(0.25, 0.1, 0.25, 1);
`

const _ItemLink = styled(Link).attrs({ bold: false, next: true })<ItemLinkProps>`
  ${itemLinkCSS}
`

const _PlainItem = styled.span`
  ${itemLinkCSS}
`

export const ItemLink: FCC<{
  href?: string
  openInNewTab?: boolean
  gap: CSSPropTwo<Spacing>
  padding: CSSPropTwo<Spacing>
  preload?: boolean
}> = ({ children, href, gap, padding, openInNewTab, preload }) => {
  if (href === undefined) {
    return (
      <_PlainItem $gap={gap} $padding={padding}>
        {children}
      </_PlainItem>
    )
  }

  return (
    <_ItemLink $gap={gap} $padding={padding} href={href} openInNewTab={openInNewTab} preload={preload}>
      {children}
    </_ItemLink>
  )
}
