import styled, { css } from 'styled-components'

const IconWrapper = styled.span`
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-items: stretch;

  width: 1.0625rem;
  height: 0.5rem;

  cursor: pointer;
`

const line = css`
  display: block;
  width: 100%;
  height: 1px;

  background-color: ${p => p.theme.text.default};
  transition: transform 0.1s ease-out;
`

type LineProps = {
  $isOpen: boolean
}

const TopLine = styled.span<LineProps>`
  ${line};
  transform-origin: top left;

  ${p =>
    p.$isOpen &&
    css`
      transform: translateY(-0.21875rem) translateX(0.1875rem) rotate(45deg);
    `};
`

const BottomLine = styled.span<LineProps>`
  ${line};
  transform-origin: bottom left;

  ${p =>
    p.$isOpen &&
    css`
      transform: translateY(0.0625rem) translateX(0.1875rem) rotate(-45deg);
    `}
`

export const AnimatedMenuIcon = ({
  className,
  onClick,
  isOpen,
}: {
  className?: string
  onClick: () => void
  isOpen: boolean
}): JSX.Element => {
  return (
    <IconWrapper className={className} onClick={onClick}>
      <TopLine $isOpen={isOpen} />
      <BottomLine $isOpen={isOpen} />
    </IconWrapper>
  )
}
