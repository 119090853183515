import { createFileRoute } from '@tanstack/react-router'

import { PageIdentifier } from 'sierra-client/layout/sana-page'
import { ManageCertificates } from 'sierra-client/views/manage/certificates/manage-certificates'
import { useAreCertificatesEnabled } from 'sierra-client/views/manage/certificates/use-are-certificates-enabled'
import { ManagePageWrapper } from 'sierra-client/views/manage/utils/manage-page-wrapper'

// Note: ManagePageWrapper handles the role check.
function ManageCertificatesPage(): JSX.Element | null {
  const certificatesEnabled = useAreCertificatesEnabled()

  if (!certificatesEnabled) {
    // TODO: Check if permissions are loaded and then redirect somewhere else.
    return null
  }

  return (
    <ManagePageWrapper pageIdentifier={PageIdentifier.ManageCertificates()}>
      <ManageCertificates />
    </ManagePageWrapper>
  )
}

const RouteComponent = ManageCertificatesPage

export const Route = createFileRoute('/manage/certificates/')({
  component: RouteComponent as React.FC,
})
