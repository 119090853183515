import { useMutation, useQueryClient } from '@tanstack/react-query'
import _ from 'lodash'
import React, { useCallback, useState } from 'react'
import { SkillId, SkillLevelSettingId } from 'sierra-client/api/graphql/branded-types'
import { graphql } from 'sierra-client/api/graphql/gql'
import {
  type SetAllContentAsSkillLevelExamMutationVariables,
  type SetContentAsSkillLevelExamMutationVariables,
} from 'sierra-client/api/graphql/gql/graphql'
import { graphQuery } from 'sierra-client/api/hooks/use-graphql-query'
import { useConfirmationModalContext } from 'sierra-client/components/common/modals/confirmation-modal'
import { useNotif } from 'sierra-client/components/common/notifications'
import {
  AssignContentPanel,
  useSetOpenAssignContentPanel,
} from 'sierra-client/features/skills/components/assign-content/assign-content'
import { AssignUsersPanel } from 'sierra-client/features/skills/components/assign-users/assign-users-panel'
import { ContentRecommendation } from 'sierra-client/features/skills/components/recommendation/content-recommendation'
import { useIsSkillAutoAssignAllowed } from 'sierra-client/features/skills/components/recommendation/gql'
import { useInvalidateSkillDetailOverviewData } from 'sierra-client/features/skills/components/skill-detail/skill-detail-overview'
import { BadgeDistribution } from 'sierra-client/features/skills/components/stats/badge-distribution'
import { SubscribersStats } from 'sierra-client/features/skills/components/stats/subscribers-stats'
import { useInvalidateSkillContentTableQuery } from 'sierra-client/features/skills/components/tabular/skill-content-table-query'
import {
  SkillContentTabular,
  SkillContentTabularActions,
  useSkillContentTableAPI,
} from 'sierra-client/features/skills/components/tabular/skill-content-tabular'
import {
  QUERY_KEY,
  SkillSubscribersTabular,
  SkillUsersTabularActions,
  useSubscribersTableAPI,
} from 'sierra-client/features/skills/components/tabular/skill-subscribers-tabular'
import {
  assignSkillToContentMutation,
  setLearnerLevelMutation,
  unassignSkillFromContentMutation,
  useUnsubscribeLearnerMutation,
} from 'sierra-client/features/skills/shared-gql-queries'
import { useTracking } from 'sierra-client/features/skills/tracking'
import { useTranslation } from 'sierra-client/hooks/use-translation'
import type { TranslationKey } from 'sierra-client/hooks/use-translation/types'
import type { ContentRep } from 'sierra-client/lib/tabular/datatype/internal/reps/content-rep'
import { TabularProviderFromTableAPI } from 'sierra-client/lib/tabular/provider'
import { getRowDataFromTableAPI } from 'sierra-client/lib/tabular/utils'
import { UserId } from 'sierra-domain/api/uuid'
import { assertNever, isDefined } from 'sierra-domain/utils'
import { Text, View } from 'sierra-ui/primitives'
import { maxWidth } from 'sierra-ui/utils/media-query-styles'
import styled from 'styled-components'

const AssignedContentTableWrapper = styled(View).attrs({ direction: 'column', grow: true })`
  width: 100%;
`
const UsersTableWrapper = styled(View).attrs({ direction: 'column', grow: true })`
  width: 100%;
`
const StatsContainer = styled(View).attrs({ grow: true })`
  width: 100%;
  justify-content: flex-start;
  flex-direction: column;

  ${maxWidth.desktop} {
    flex-direction: row;
    gap: 16px;
  }

  ${maxWidth.tablet} {
    flex-direction: column;
  }
`

const CentralWrapper = styled(View).attrs({ direction: 'column', grow: true })`
  width: 100%;
  gap: 24px;
`

const Container = styled(View).attrs({ grow: true })`
  display: grid;
  grid-template-columns: repeat(24, 1fr);
  width: 100%;

  ${CentralWrapper} {
    grid-column: 1 / span 17;
    place-self: normal;
  }

  ${StatsContainer} {
    grid-column: 19 / span 6;
    place-self: normal;
  }

  ${maxWidth.desktop} {
    display: flex;
    flex-direction: column;
    column-gap: 16px;
    row-gap: 24px;
  }
`

const SkillUsersSection: React.FC<{ skillId: SkillId }> = ({ skillId }) => {
  const tracking = useTracking()
  const queryClient = useQueryClient()
  const [isDialogOpen, setIsOpen] = useState(false)

  const { mutate: unsubscribeUser } = useUnsubscribeLearnerMutation({
    onSuccess: (_, input) => {
      if (Array.isArray(input.subscriptions)) {
        input.subscriptions.forEach(learner =>
          tracking.learner.unsubscribe({ skillId: learner.skillId, learnerId: learner.userId })
        )
      } else {
        tracking.learner.unsubscribe({
          skillId: input.subscriptions.skillId,
          learnerId: input.subscriptions.userId,
        })
      }

      void queryClient.invalidateQueries({ queryKey: QUERY_KEY })
    },
  })

  const { mutate: setLearnerLevel } = useMutation({
    mutationFn: (input: {
      userId: UserId
      skillId: SkillId
      skillLevelSettingId: SkillLevelSettingId
      alreadyAchieved: boolean
    }) => {
      return graphQuery(setLearnerLevelMutation, {
        skillId: input.skillId,
        userId: input.userId,
        skillLevelSettingId: input.skillLevelSettingId,
        alreadyAchieved: input.alreadyAchieved,
      })
    },
    onSuccess: (_, input) => {
      if (input.alreadyAchieved) {
        tracking.learner.setAchievedLevel({
          skillId: input.skillId,
          learnerId: input.userId,
          level: input.skillLevelSettingId,
        })
      } else {
        tracking.learner.setTargetLevel({
          skillId: input.skillId,
          learnerId: input.userId,
          level: input.skillLevelSettingId,
        })
      }
      void queryClient.invalidateQueries({ queryKey: QUERY_KEY })
    },
  })

  const usersTabularActions: SkillUsersTabularActions = React.useMemo(
    () => ({
      onUnsubscribeUser: ({ userId }) => {
        unsubscribeUser({ subscriptions: { userId, skillId } })
      },
      onSubscribeUsers: () => {
        setIsOpen(true)
      },
      onSetTargetLevel: ({ skillLevelSettingId, userId, skillId }) => {
        setLearnerLevel({
          userId,
          skillId,
          skillLevelSettingId,
          alreadyAchieved: false,
        })
      },
      onSetLevelsAchieved: ({ skillLevelSettingId, userId, skillId }) => {
        setLearnerLevel({
          userId,
          skillId,
          skillLevelSettingId,
          alreadyAchieved: true,
        })
      },
      onSendReminder: () => {},
    }),
    [setLearnerLevel, skillId, unsubscribeUser]
  )
  const usersTableAPI = useSubscribersTableAPI(skillId, usersTabularActions)

  return (
    <TabularProviderFromTableAPI tableAPI={usersTableAPI}>
      <SkillSubscribersTabular actions={usersTabularActions} />
      <AssignUsersPanel open={isDialogOpen} setOpen={setIsOpen} tableAPI={usersTableAPI} skillId={skillId} />
    </TabularProviderFromTableAPI>
  )
}

const convertContentTypeToTranslationKey = (type: ContentRep['contentType']): TranslationKey => {
  switch (type) {
    case 'course':
      return 'content-table.types.course'
    case 'path':
      return 'content-table.types.path'
    case 'program':
      return 'content-table.types.program'
    case 'homework':
      return 'content-table.types.course'
    default:
      assertNever(type)
  }
}

const setContentAsSkillLevelExamMutation = graphql(`
  mutation SetContentAsSkillLevelExam(
    $contentId: InputContentId!
    $skillId: SkillId!
    $skillLevelSettingId: SkillLevelSettingId!
  ) {
    setContentAsSkillLevelExam(
      contentId: $contentId
      skillId: $skillId
      skillLevelSettingId: $skillLevelSettingId
    )
  }
`)

const setAllContentAsSkillLevelExamMutation = graphql(`
  mutation SetAllContentAsSkillLevelExam($skillId: SkillId!, $skillLevelSettingId: SkillLevelSettingId!) {
    setAllContentAsSkillLevelExam(skillId: $skillId, skillLevelSettingId: $skillLevelSettingId)
  }
`)

const SkillContentSection: React.FC<{
  skillId: SkillId
}> = ({ skillId }) => {
  const { t } = useTranslation()
  const notification = useNotif()
  const confirmationModal = useConfirmationModalContext()
  const setOpenAssignContentPanel = useSetOpenAssignContentPanel()
  const tracking = useTracking()

  const invalidateSkillContentTableQuery = useInvalidateSkillContentTableQuery(skillId)
  const invalidateSkillDetailOverviewData = useInvalidateSkillDetailOverviewData()

  const { mutate: fastTrackMutation } = useMutation({
    mutationFn: (input: SetContentAsSkillLevelExamMutationVariables) => {
      return graphQuery(setContentAsSkillLevelExamMutation, input)
    },
    onSuccess: () => {
      void invalidateSkillContentTableQuery()
      void invalidateSkillDetailOverviewData()
    },
    onError: () => {
      notification.push({ type: 'error' })
    },
  })

  const { mutate: resetFastTrackMutation } = useMutation({
    mutationFn: (input: SetAllContentAsSkillLevelExamMutationVariables) => {
      return graphQuery(setAllContentAsSkillLevelExamMutation, input)
    },
    onSuccess: () => {
      void invalidateSkillContentTableQuery()
      void invalidateSkillDetailOverviewData()
    },
    onError: () => {
      notification.push({ type: 'error' })
    },
  })

  const onFastTrackToggleSuccess = useCallback(
    (ref: string, enabled: boolean) => {
      tracking.content.updateFastTrack({ contentId: ref, skillId, enabled })
    },
    [skillId, tracking.content]
  )

  const onRemoveHandler = useCallback(
    async (ref: string) => {
      await graphQuery(unassignSkillFromContentMutation, { contentIds: [ref], skillId: skillId })
      tracking.content.removeContent({ contentId: ref, skillId })
      void invalidateSkillContentTableQuery()
      void invalidateSkillDetailOverviewData()
    },
    [invalidateSkillContentTableQuery, invalidateSkillDetailOverviewData, skillId, tracking.content]
  )

  const onLevelChangeHandler = useCallback(
    async (ref: string, skillId: SkillId, skillLevelSettingId: SkillLevelSettingId) => {
      await graphQuery(assignSkillToContentMutation, {
        contentIds: [ref],
        skillId: skillId,
        skillLevelSettingId: skillLevelSettingId,
      })
      tracking.content.updateLevel({ contentId: ref, level: skillLevelSettingId })
      await Promise.all([invalidateSkillContentTableQuery(), invalidateSkillDetailOverviewData()])
    },
    [tracking.content, invalidateSkillContentTableQuery, invalidateSkillDetailOverviewData]
  )

  const contentTabularActions: SkillContentTabularActions = React.useMemo(
    () => ({
      onChangeLevel: async (ref, skillId, skillLevelSettingId) => {
        await onLevelChangeHandler(ref, skillId, skillLevelSettingId)
      },
      onRemoveContent: async ref => {
        await onRemoveHandler(ref)
      },
      onAddContent: () => {
        setOpenAssignContentPanel(true)
      },
      onMakeFastTrack: (ref, api) => {
        const data = getRowDataFromTableAPI(api, ref)

        if (!isDefined(data)) return

        const selectedLevel = data.level.data.items.find(it => it.label === data.level.data.selected)

        if (isDefined(selectedLevel)) {
          if (data.NAME.data.isLevelFastTrack) {
            resetFastTrackMutation(
              {
                skillId,
                skillLevelSettingId: SkillLevelSettingId.parse(selectedLevel.id),
              },
              {
                onSuccess: () => {
                  onFastTrackToggleSuccess(ref, false)
                },
              }
            )
          } else {
            confirmationModal.show({
              title: t('skills.level-fast-tack.confirm-modal.title'),
              bodyText: t('skills.level-fast-tack.confirm-modal.body', {
                contentType: _.lowerCase(t(convertContentTypeToTranslationKey(data.NAME.data.contentType))),
              }),
              hideCloseIcon: true,
              onConfirm: () => {
                fastTrackMutation(
                  {
                    contentId: ref,
                    skillId,
                    skillLevelSettingId: SkillLevelSettingId.parse(selectedLevel.id),
                  },
                  {
                    onSuccess: () => {
                      onFastTrackToggleSuccess(ref, true)
                    },
                  }
                )
              },
            })
          }
        } else {
          notification.push({ type: 'error' })
        }
      },
    }),
    [
      skillId,
      setOpenAssignContentPanel,
      resetFastTrackMutation,
      onFastTrackToggleSuccess,
      onLevelChangeHandler,
      onRemoveHandler,
      confirmationModal,
      t,
      fastTrackMutation,
      notification,
    ]
  )

  const tableAPI = useSkillContentTableAPI(skillId, contentTabularActions)

  return (
    <TabularProviderFromTableAPI tableAPI={tableAPI}>
      <SkillContentTabular actions={contentTabularActions} />
      <AssignContentPanel skillId={skillId} />
    </TabularProviderFromTableAPI>
  )
}

export const SkillDetailManage: React.FC<{ skillId: SkillId }> = ({ skillId }) => {
  const { t } = useTranslation()
  const isSkillAutoAssignAllowed = useIsSkillAutoAssignAllowed()

  return (
    <Container>
      <CentralWrapper>
        {isSkillAutoAssignAllowed.allowed && <ContentRecommendation skillId={skillId} />}
        <AssignedContentTableWrapper>
          <Text size='regular' bold>
            {t('live.edit-content')}
          </Text>
          <SkillContentSection skillId={skillId} />
        </AssignedContentTableWrapper>
        <UsersTableWrapper>
          <Text size='regular' bold>
            {t('admin.organization.tab.users')}
          </Text>
          <SkillUsersSection skillId={skillId} />
        </UsersTableWrapper>
      </CentralWrapper>
      <StatsContainer direction='column' gap='32'>
        <BadgeDistribution skillId={skillId} />
        <SubscribersStats skillId={skillId} />
      </StatsContainer>
    </Container>
  )
}
