import { SkillId } from 'sierra-client/api/graphql/branded-types'
import { Link } from 'sierra-client/components/common/link'
import { SelectSkillsFormVariant } from 'sierra-client/features/skills/components/select-skills/types'
import { isDefined } from 'sierra-domain/utils'
import { Icon, SkillIconId, TruncatedText } from 'sierra-ui/components'
import { Text, View } from 'sierra-ui/primitives'
import { token } from 'sierra-ui/theming'
import { ConditionalWrapper } from 'sierra-ui/utils'
import styled from 'styled-components'

const Badge = styled.div`
  display: flex;
  justify-content: center;
  flex-shrink: 0;
  border-radius: 100%;
  width: 40px;
  height: 40px;
  background-color: ${token('surface/soft')};
`

const SkillLink = styled(Link)`
  &:hover {
    text-decoration: underline;
  }
`

export const SkillPill: React.FC<{
  skillId: SkillId
  defaultBadgeIcon: SkillIconId
  title: string
  subtitle?: string
  variant: SelectSkillsFormVariant
}> = ({ skillId, defaultBadgeIcon, title, subtitle, variant }) => {
  return (
    <View gap='12'>
      <Badge aria-hidden>
        <Icon iconId={defaultBadgeIcon} color='foreground/primary' />
      </Badge>
      <View direction='column' gap='2' margin='none'>
        <ConditionalWrapper
          condition={variant === 'learner-manage'}
          renderWrapper={children => (
            <SkillLink href={`skill/${skillId}`} target='_blank'>
              {children}
            </SkillLink>
          )}
        >
          <TruncatedText lines={2} size='small' bold>
            {title}
          </TruncatedText>
        </ConditionalWrapper>
        {isDefined(subtitle) && (
          <Text size='small' color='foreground/muted'>
            {subtitle}
          </Text>
        )}
      </View>
    </View>
  )
}
