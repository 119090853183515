import { useAtomValue } from 'jotai'
import { useCallback, useEffect, useState } from 'react'
import { useCurrentMode } from 'sierra-client/components/liveV2/hooks/use-current-mode'
import { handlePiPError } from 'sierra-client/components/liveV2/hooks/use-participant-picture-in-picture/error-handling'
import { useTrackUsage } from 'sierra-client/components/liveV2/hooks/use-participant-picture-in-picture/tracking'
import { CurrentVideo } from 'sierra-client/components/liveV2/hooks/use-participant-picture-in-picture/types'
import { useSelector } from 'sierra-client/state/hooks'
import { selectActiveParticipantsWithVideo } from 'sierra-client/state/live-session/selectors'
import { pictureInPictureEnabledAtom } from 'sierra-client/state/live/settings'
import { CreateContentId } from 'sierra-domain/api/nano-id'
import { useOnChanged } from 'sierra-ui/utils'

const getVideoEl = async (agoraUID: string): Promise<HTMLVideoElement | null> => {
  const videoEl = document.querySelector<HTMLVideoElement>(`video[id*='${agoraUID}']`)
  if (videoEl !== null) {
    return videoEl
  }
  // Single retry, sometimes when switching between lobby/sidebar the new video element isnt rendered yet
  return new Promise(resolve => {
    setTimeout(() => {
      resolve(document.querySelector<HTMLVideoElement>(`video[id*='${agoraUID}']`))
    }, 100)
  })
}

export const useParticipantPictureInPicture = (flexibleContentId: CreateContentId): boolean => {
  const mostActiveParticipants = useSelector(selectActiveParticipantsWithVideo)(1)
  const participant = mostActiveParticipants[0]

  const isEnabledByUser = useAtomValue(pictureInPictureEnabledAtom)

  const [currentVideo, setCurrentVideo] = useState<CurrentVideo | undefined>()
  const currentMode = useCurrentMode(flexibleContentId)

  useTrackUsage(currentVideo)

  const closePiP = async (): Promise<void> => {
    if (document.pictureInPictureElement !== null) {
      try {
        await document.exitPictureInPicture()
        setCurrentVideo(undefined)
      } catch (e) {
        handlePiPError(e, null)
      }
    }
  }

  const setPiP = async (videoEl: HTMLVideoElement, agoraUID: string): Promise<void> => {
    await videoEl.requestPictureInPicture()
    setCurrentVideo({ video: videoEl, participantAgoraUID: agoraUID })
  }

  const updatePiP = useCallback(
    async (agoraUID: string | undefined): Promise<void> => {
      if (isEnabledByUser && agoraUID !== undefined) {
        const videoEl = await getVideoEl(agoraUID)

        if (videoEl === null) {
          setCurrentVideo(undefined)
          return
        }
        // If the current pip element is the same as the one we would switch to, we don't need to do anything
        if (videoEl === document.pictureInPictureElement) return

        try {
          await setPiP(videoEl, agoraUID)
        } catch (e) {
          handlePiPError(e, videoEl, () => setPiP(videoEl, agoraUID))
        }
      } else if (currentVideo !== undefined && agoraUID === undefined) {
        void closePiP()
      }
    },
    [isEnabledByUser, currentVideo]
  )

  // Setup handler for closing PiP when tab is visible and you're clicking anywhere
  useEffect(() => {
    if (currentVideo !== undefined) {
      document.body.addEventListener('click', closePiP, true)
    }

    return () => {
      if (currentVideo !== undefined) {
        document.body.removeEventListener('click', closePiP, true)
      }
    }
  }, [currentVideo])

  // Setup handlers to listen for when changing tabs
  const handleVisibilityChange = useCallback((): void => {
    if (document.visibilityState !== 'visible') {
      void updatePiP(participant?.agoraUID)
    }
  }, [updatePiP, participant])

  useEffect(() => {
    document.addEventListener('visibilitychange', handleVisibilityChange)
    return () => {
      document.removeEventListener('visibilitychange', handleVisibilityChange)
    }
  }, [handleVisibilityChange])

  // If we have an active PiP and mode changes we need to update the video element
  useOnChanged(() => {
    if (document.pictureInPictureElement !== null) {
      void updatePiP(participant?.agoraUID)
    }
  }, currentMode)

  const closePiPIfCurrentVideoIsInactivated = async (): Promise<void> => {
    if (currentVideo !== undefined) {
      // Sleep to make sure the video element has time to close :(
      await new Promise<void>(res => setTimeout(() => res(), 100))
      const videoEl = await getVideoEl(currentVideo.participantAgoraUID)
      if (videoEl === null) {
        return closePiP()
      }
    }
  }

  // Most active participant has been updated
  useOnChanged(() => {
    if (document.visibilityState !== 'visible') {
      /*
        We are on another tab, update the PiP with the currently most active participant
      */
      void updatePiP(participant?.agoraUID)
    } else {
      /* 
        PiP is active, tab is visible. We want to avoid changing the active PiP.
        If participant changed due to current video being inactivated/disconnected we close the PiP.
      */
      void closePiPIfCurrentVideoIsInactivated()
    }
  }, participant)

  return currentVideo !== undefined
}
