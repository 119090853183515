import React, { useMemo, useState } from 'react'
import { toQueryRequest } from 'sierra-client/features/insights/api-hooks/use-query-analytics-data'
import { LimitSelector } from 'sierra-client/features/insights/widget-builder/data-selectors'
import { usePost } from 'sierra-client/hooks/use-post'
import { TableAPI } from 'sierra-client/lib/tabular/api'
import {
  TabularToolbar,
  TabularToolbarDownloadButton,
} from 'sierra-client/lib/tabular/components/tabular-toolbar'
import { useReportJobs } from 'sierra-client/views/manage/reports/use-reports'
import { OutputFormat } from 'sierra-domain/api/analytics-reporting'
import {
  AggregationTableWidget,
  BarChartWidget,
  LineChartWidget,
  ListTableWidget,
  PivotTableWidget,
} from 'sierra-domain/api/insights'
import { XRealtimeAnalyticsReportsSubmitJob } from 'sierra-domain/routes'

export const DownloadTableDataButton: React.FC<{
  widget: AggregationTableWidget | ListTableWidget | BarChartWidget | LineChartWidget | PivotTableWidget
}> = ({ widget }) => {
  const { postWithUserErrorException } = usePost()
  const { jobs, addJob } = useReportJobs({ pollInterval: 1000 })
  const [currentJobId, setCurrentJobId] = useState<string>()

  const handleAsyncDownload = async (outputFormat: OutputFormat): Promise<void> => {
    const request = toQueryRequest(widget)

    const submittedJob = await postWithUserErrorException(XRealtimeAnalyticsReportsSubmitJob, {
      reportId: 'analytics',
      report: 'data',
      query: request.query,
      outputFormat,
    })

    setCurrentJobId(submittedJob.job.jobId)
    addJob(submittedJob.job)
  }
  const currentJob = useMemo(
    () => jobs.find(job => job.reportId === 'analytics' && job.jobId === currentJobId),
    [currentJobId, jobs]
  )

  const onDownloadCSV = (): Promise<void> => handleAsyncDownload('csv')

  const onDownloadXLSX = (): Promise<void> => handleAsyncDownload('xlsx')
  return (
    <TabularToolbarDownloadButton
      onDownloadCSV={onDownloadCSV}
      onDownloadXLSX={onDownloadXLSX}
      loading={currentJob?.status === 'pending'}
    />
  )
}

export const TableToolbar: React.FC<{
  api: TableAPI
  limit?: number
  onSetLimit?: (newLimit?: number) => void
  widget: AggregationTableWidget | ListTableWidget | BarChartWidget | LineChartWidget | PivotTableWidget
  dbSofLimitReached: boolean
}> = ({ api, limit, onSetLimit, widget, dbSofLimitReached }) => {
  return (
    <TabularToolbar
      api={api}
      clearFilters={false}
      countsTranslationKeys={
        dbSofLimitReached ? { totalKey: 'manage.rows.more-than-n-rows' } : { totalKey: 'manage.rows.n-rows' }
      }
      actions={
        <>
          {onSetLimit !== undefined && (
            <LimitSelector widgetType={widget.type} onSetLimit={onSetLimit} limit={limit} />
          )}
          <DownloadTableDataButton widget={widget} />
        </>
      }
    />
  )
}
