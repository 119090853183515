import Ably from 'ably'
import { Atom, useAtom, useAtomValue } from 'jotai'
import React, { useEffect, useRef, useState } from 'react'
import { ChannelMap } from 'sierra-client/realtime-data/real-time-data-provider/channel-map'
import { AblyConnectionState, connectionStateAtom } from 'sierra-client/state/realtime-data'
import { atomWithStorage } from 'sierra-client/state/storage'
import { FPSCounter } from 'sierra-client/utils/fps-counter'
import { ColorName } from 'sierra-ui/color/types'
import { Icon, IconId, Tooltip } from 'sierra-ui/components'
import { Text, View } from 'sierra-ui/primitives'
import { DarkTokenProvider, zIndex } from 'sierra-ui/theming'
import styled, { createGlobalStyle } from 'styled-components'

const backgroundColor = '#3e3e3e'

const Container = styled.div`
  position: fixed;
  right: 0;
  bottom: 0;
  z-index: ${zIndex.ON_MODAL};
  color: white;
  background-color: ${backgroundColor};
  border-radius: 6px 0 0 0;
  display: flex;
  flex-direction: column;
  gap: 2px;
  padding: 4px;
  min-width: 90px;
`

const SpinningIcon = styled(Icon)<{ $spin: boolean }>`
  ${p => p.$spin && `animation: spin 1s infinite linear;`}
  @keyframes spin {
    0% {
      transform: rotate(0deg);
    }
    100% {
      transform: rotate(360deg);
    }
  }
`

const ClickableView = styled(View)`
  cursor: pointer;
  &:hover {
    opacity: 0.5;
  }
`

function channelStateMeta(channelState: Ably.ChannelState): [ColorName, IconId] {
  switch (channelState) {
    case 'attached':
      return ['greenBright', 'checkmark--filled']

    case 'suspended':
      return ['orangeBright', 'warning']
    case 'failed':
      return ['redBright', 'warning--filled']

    case 'attaching':
      return ['yellowBright', 'loading']
    case 'initialized':
      return ['yellowBright', 'loading']

    case 'detaching':
      return ['yellowBright', 'close--circle']
    case 'detached':
      return ['grey5', 'close--circle--filled']
  }
}

const ChannelNameText = styled(Text)`
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
`

function truncateChannelName(channelName: string): string {
  const parts = channelName.split(':')
  const numberOfSubParts = parts.map(part => part.split('-').length).reduce((a, b) => a + b, 0)

  const lengthPerPart = 12 / numberOfSubParts

  return parts
    .map(part => {
      return part
        .split('-')
        .map(word => word.slice(0, lengthPerPart))
        .join('-')
    })
    .join(':')
}

const ChannelFullDebug = styled(View)`
  padding-inline: 4px;
  position: absolute;
  gap: 4px;
  right: 0;
  top: 0;
  bottom: 0;
  background-color: ${backgroundColor};
  white-space: nowrap;
  width: fit-content;
`
const ChannelContainer = styled(View)`
  position: relative;
  opacity: 0.75;
  &:hover {
    opacity: 1;
    ${ChannelFullDebug} {
      display: flex;
    }
  }
  ${ChannelFullDebug} {
    display: none;
  }
`

const DetachIcon = styled(Icon)`
  cursor: pointer;
`

const ChannelDebug: React.FC<{ channel: Ably.RealtimeChannel }> = ({ channel }) => {
  const [channelState, setChannelState] = useState(channel.state)
  const channelName = channel.name
  const [color, iconId] = channelStateMeta(channelState)

  useEffect(() => {
    function onChange(stateChange: Pick<Ably.ChannelStateChange, 'current'>): void {
      setChannelState(stateChange.current)
    }
    onChange({ current: channel.state })
    channel.on(onChange)
    return () => channel.off(onChange)
  }, [channel])

  return (
    <li>
      <ChannelContainer gap='4' alignItems='center'>
        <SpinningIcon $spin={iconId === 'loading'} size='size-12' iconId={iconId} color={color} />
        <ChannelNameText size='small' color='grey5'>
          {truncateChannelName(channelName)}
        </ChannelNameText>

        <ChannelFullDebug>
          <SpinningIcon $spin={iconId === 'loading'} size='size-12' iconId={iconId} color={color} />
          <Text bold>{channelName}</Text>
          <Text bold size='small' color='grey5'>
            {channelState}
          </Text>

          <Tooltip title={channelState === 'detached' ? 'Attach' : 'Detach'}>
            <DetachIcon
              iconId={channelState === 'detached' ? 'plus--circle' : 'close--circle'}
              size='size-12'
              onClick={async () => {
                if (channelState === 'detached') {
                  await channel.attach()
                } else {
                  await channel.detach()
                }
              }}
            />
          </Tooltip>
        </ChannelFullDebug>
      </ChannelContainer>
    </li>
  )
}

const Ul = styled.ul`
  list-style: none;
`

const ChannelList: React.FC<{ channels: Ably.RealtimeChannel[] }> = ({ channels }) => {
  return (
    <Ul>
      {channels.map(channel => {
        return <ChannelDebug key={channel.name} channel={channel} />
      })}
    </Ul>
  )
}

const Separator = styled.div`
  width: 100%;
  border-bottom: 1px solid #666;
`

function connectionStateMeta(connectionState: AblyConnectionState): [ColorName, IconId] {
  switch (connectionState) {
    case 'suspended':
    case 'disconnected':
    case 'closed':
    case 'initialized':
      return ['grey5', 'radio-button--dot']
    case 'connected':
      return ['greenBright', 'checkmark--filled']
    case 'closing':
    case 'connecting':
      return ['yellowBright', 'loading']
    case 'failed':
      return ['redBright', 'warning']
  }
}

const ablyExpandedAtom = atomWithStorage('__ably_debug_expanded', true)

function useActiveChannels(channelsAtom: Atom<ChannelMap>): Ably.RealtimeChannel[] {
  const channels = useAtomValue(channelsAtom)
  const detachedChannelsRef = useRef(new Set<string>())

  const [hiddenChannels, setHiddenChannels] = useState(() => {
    const detachedWhenMounting = Object.values(channels)
      .filter(channel => channel.state === 'detached')
      .map(channel => channel.name)
    return new Set(detachedWhenMounting)
  })

  useEffect(() => {
    const cleanUpFunctions: (() => void)[] = []
    for (const channel of Object.values(channels)) {
      const onChange = (change: Pick<Ably.ChannelStateChange, 'current'>): void => {
        if (change.current === 'detached') {
          detachedChannelsRef.current.add(channel.name)

          setTimeout(() => {
            const isStillDetached = detachedChannelsRef.current.has(channel.name)
            if (isStillDetached) {
              setHiddenChannels(hiddenChannels => new Set([...hiddenChannels, channel.name]))
            }
          }, 3000)
        } else {
          detachedChannelsRef.current.delete(channel.name)
          setHiddenChannels(
            hiddenChannels => new Set(Array.from(hiddenChannels).filter(name => name !== channel.name))
          )
        }
      }

      onChange({ current: channel.state })
      channel.on(onChange)
      cleanUpFunctions.push(() => channel.off(onChange))
    }

    return () => {
      for (const cleanUp of cleanUpFunctions) {
        cleanUp()
      }
    }
  }, [channels])

  return Object.values(channels).filter(channel => !hiddenChannels.has(channel.name))
}

const tanstackRouterLabel = 'Open TanStack Router Devtools'
const tanstackQueryLabel = 'Open Tanstack query devtools'

const HideTanstackDevtools = createGlobalStyle`
  button[aria-label='${tanstackRouterLabel}'], button[aria-label='${tanstackQueryLabel}'] {
    display: none !important; 
  }
`

function clickButtonWithAriaLabel(label: string): void {
  for (const button of document.querySelectorAll('button')) {
    if (button.getAttribute('aria-label') === label) {
      button.click()
      return
    }
  }

  throw new Error(`Unable to find button with label ${label}`)
}

export const RealTimeDataDebugView = (props: {
  client: Ably.Realtime | undefined
  allChannelsAtom: Atom<ChannelMap>
}): JSX.Element | null => {
  const connectionState = useAtomValue(connectionStateAtom)
  const [open, setOpen] = useAtom(ablyExpandedAtom)

  const [color, iconId] = connectionStateMeta(connectionState)
  const activeChannels = useActiveChannels(props.allChannelsAtom)

  return (
    <DarkTokenProvider>
      <Container>
        <section>
          <Tooltip title={`State: ${connectionState}`}>
            <ClickableView
              alignItems='center'
              justifyContent='center'
              gap='4'
              onClick={() => setOpen(open => !open)}
            >
              <View gap='4'>
                <Text bold size='technical' align='center' unselectable='on' color='grey5'>
                  Ably ({activeChannels.length})
                </Text>
                <SpinningIcon $spin={iconId === 'loading'} size='size-12' iconId={iconId} color={color} />
              </View>
            </ClickableView>
          </Tooltip>
          {open && <ChannelList channels={activeChannels} />}
        </section>

        <Separator />

        {process.env.NODE_ENV === 'development' && (
          <>
            <HideTanstackDevtools />

            <View alignItems='center' justifyContent='center'>
              <ClickableView
                alignItems='center'
                justifyContent='center'
                onClick={() => {
                  clickButtonWithAriaLabel(tanstackRouterLabel)
                }}
              >
                <Text bold size='technical' align='center' unselectable='on' color='grey5'>
                  Router
                </Text>
              </ClickableView>

              <ClickableView
                alignItems='center'
                justifyContent='center'
                onClick={() => {
                  clickButtonWithAriaLabel(tanstackQueryLabel)
                }}
              >
                <Text bold size='technical' align='center' unselectable='on' color='grey5'>
                  Query
                </Text>
              </ClickableView>
            </View>
            <Separator />
          </>
        )}

        <FPSCounter />
      </Container>
    </DarkTokenProvider>
  )
}
