import { useAtom } from 'jotai'
import { ChangeEvent, FC } from 'react'
import { useTranslation } from 'sierra-client/hooks/use-translation'
import {
  CertificateInput,
  selectedCertificateAtom,
} from 'sierra-client/views/manage/certificates/edit-certificate-panel/store'
import { ValidityInformationInput } from 'sierra-client/views/manage/certificates/edit-certificate-panel/validity-information-input'
import { FormElement } from 'sierra-ui/components'
import { InputPrimitive, TextAreaPrimitive, View } from 'sierra-ui/primitives'

interface InformationTabProps {
  updateField: (param: string, valueBefore: string, valueAfter: string) => void
  refetch: (certificate: CertificateInput) => void
}

export const InformationTab: FC<InformationTabProps> = ({ updateField, refetch }) => {
  const [certificate] = useAtom(selectedCertificateAtom)
  const { t } = useTranslation()

  const handleUpdateTitle = (value: string): void => {
    updateField('title', certificate.title, value)
  }

  const handleUpdateDescription = (value: string): void => {
    updateField('description', certificate.description, value)
  }

  const handleUpdateCertificateAuthority = (value: string): void => {
    updateField('certificateAuthority', certificate.certificateAuthority, value)
  }

  return (
    <View direction='column' gap='16'>
      <FormElement label={t('dictionary.title')}>
        <InputPrimitive
          placeholder={t('manage.certificates.certificate-name')}
          value={certificate.title}
          onChange={(e: ChangeEvent<HTMLInputElement>) => handleUpdateTitle(e.target.value)}
        />
      </FormElement>
      <FormElement label={t('dictionary.description')}>
        <TextAreaPrimitive
          placeholder={t('manage.certificates.input-placeholder-description')}
          rows={3}
          value={certificate.description}
          onChange={(e: ChangeEvent<HTMLTextAreaElement>) => handleUpdateDescription(e.target.value)}
        />
      </FormElement>
      <FormElement label={t('manage.certificates.edit-certificate-modal.certificate-authority')}>
        <InputPrimitive
          value={certificate.certificateAuthority}
          onChange={e => handleUpdateCertificateAuthority(e.target.value)}
        />
      </FormElement>
      <ValidityInformationInput refetch={refetch} />
    </View>
  )
}
