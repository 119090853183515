import { getDefaultStore, useSetAtom } from 'jotai'
import { useEffect } from 'react'
import {
  initialCollapsableSidebarStateAtom,
  internalCollapsableSidebarStateAtom,
} from 'sierra-client/features/collapsable-sidebar/atoms'

const store = getDefaultStore()

/* Safes the last reported sidebar state to localStorage */
export const useUpdateInitialSidebarState = (): void => {
  const setInitialSidebarState = useSetAtom(initialCollapsableSidebarStateAtom)

  useEffect(() => {
    const beforeUnmount = (): void => {
      const lastValue = store.get(internalCollapsableSidebarStateAtom)
      setInitialSidebarState(lastValue === 'sticky' ? 'sticky' : 'collapsed')
    }

    window.addEventListener('beforeunload', beforeUnmount)

    return () => {
      window.removeEventListener('beforeunload', beforeUnmount)
    }
  }, [setInitialSidebarState])
}
