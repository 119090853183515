import React from 'react'
import { MessageContent } from 'sierra-client/components/chat/chat-message/message-content'
import { TimeSinceSent } from 'sierra-client/components/chat/chat-message/time-since-sent'
import { useUserLegacy } from 'sierra-client/state/users/hooks'
import { getAvatarImage } from 'sierra-client/utils/avatar-img'
import { ChatIdentifier } from 'sierra-domain/api/chat'
import { UserId } from 'sierra-domain/api/uuid'
import { ScopedChatId } from 'sierra-domain/collaboration/types'
import { getUserName } from 'sierra-domain/utils'
import { RoundAvatar } from 'sierra-ui/components'
import { View } from 'sierra-ui/primitives'
import { token } from 'sierra-ui/theming'
import { fonts } from 'sierra-ui/theming/fonts'
import styled from 'styled-components'

const MessageUser = styled.span`
  font-weight: ${fonts.weight.bold};
  color: ${token('foreground/primary')};
  /* TODO: Fix for rendering for font-size. Using p.theme ... currently renders a double font-size property, making it null and void */
  font-size: 14px;
  padding-right: 0.5rem;
  line-height: 24px;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
`

export const StyledMessage = styled.div`
  & + & {
    padding-top: 0.25rem;
  }

  letter-spacing: inherit;
  position: relative;

  &:last-of-type {
    padding-bottom: 2rem;
  }
`

const MessageTitle = styled.div`
  display: flex;
  justify-content: space-between;
  gap: 0.5rem;
  padding: 1rem 1.5rem 0.25rem 1.5rem;
`

const Message = styled.div`
  display: flex;
  /* padding: 0 1.5rem; */
  padding: 0 0.5rem 0 2.5rem;
`

type MessageProps = {
  chatId: ScopedChatId
  chatIdentifier: ChatIdentifier
  userId: UserId
  messageId: string
  timeSent: string
  setThreadId?: (id: string) => void
  hideTitle: boolean
  withoutContentReferences: boolean
}

export const ChatMessage: React.FC<MessageProps> = ({
  chatId,
  chatIdentifier,
  userId,
  messageId,
  timeSent,
  setThreadId,
  hideTitle,
  withoutContentReferences,
}) => {
  const user = useUserLegacy(userId)

  return (
    <StyledMessage>
      {!hideTitle && (
        <MessageTitle>
          <View>
            <RoundAvatar
              size='tiny'
              firstName={user?.firstName}
              src={getAvatarImage(userId, user?.avatar)}
              color={user?.avatarColor}
            />
            <MessageUser>{getUserName(user)}</MessageUser>
          </View>
          <TimeSinceSent timestamp={timeSent} />
        </MessageTitle>
      )}
      <Message>
        {/* {!hideTitle && (
          <RoundAvatar
            size='small'
            firstName={user?.firstName}
            image={avatarImg(user?.avatar)}
            color={user?.avatarColor}
          />
        )} */}
        {/* {hideTitle && <Spacer />} */}
        <MessageContent
          chatId={chatId}
          chatIdentifier={chatIdentifier}
          messageId={messageId}
          setThreadId={setThreadId}
          withoutContentReferences={withoutContentReferences}
        />
      </Message>
    </StyledMessage>
  )
}
