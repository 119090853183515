import { HideScrollbarUnlessHovered } from 'sierra-ui/components/layout-kit'
import styled from 'styled-components'

export const SelfPacedCardRendererContainer = styled.div`
  display: flex;
  flex-direction: column;
  overflow: auto;
  overflow-anchor: none;

  ${HideScrollbarUnlessHovered}
  > * {
    &:first-child {
      display: flex;
      flex: 1 1 auto;
      height: auto;
    }
  }
`
