import { useState } from 'react'
import { RouterLink } from 'sierra-client/components/common/link'
import { getAssetContextFromEditableContent } from 'sierra-client/components/util/asset-contex'
import {
  NotificationContainer,
  Title,
  UserAvatar,
  useUsername,
} from 'sierra-client/features/activity-center/notifications/components'
import { PlainEntityImage } from 'sierra-client/features/activity-center/notifications/components/entity-image'
import { NotificationTitle } from 'sierra-client/features/activity-center/notifications/components/notification-title'
import { useResolveAsset } from 'sierra-client/hooks/use-resolve-asset'
import { useTranslation } from 'sierra-client/hooks/use-translation'
import { useTypedMutation } from 'sierra-client/state/api'
import { editUrl } from 'sierra-domain/api/editable-content'
import { HomeNotification } from 'sierra-domain/api/learn'
import { CourseRole } from 'sierra-domain/api/manage'
import { XRealtimeAdminCoursesSetCourseRoles } from 'sierra-domain/routes'
import { Button, View } from 'sierra-ui/primitives'
import { SingleSelectDropdown } from 'sierra-ui/primitives/menu-dropdown'

export const CourseRoleDropdown: React.FC<{
  selectedRole: 'editor' | 'commenter'
  onSelect: (value: 'editor' | 'commenter') => void
}> = ({ selectedRole, onSelect }) => {
  const { t } = useTranslation()

  const rolesToMenuItems = {
    editor: {
      id: 'editor',
      type: 'label',
      label: t('author.role-editor'),
    },
    commenter: {
      id: 'commenter',
      type: 'label',
      label: t('author.role-commenter'),
    },
  } as const
  const menuItems = Object.values(rolesToMenuItems)
  const selectedItem = rolesToMenuItems[selectedRole]

  return (
    <SingleSelectDropdown
      variant='ghost'
      onSelect={item => {
        onSelect(item.id)
      }}
      selectedItem={selectedItem}
      menuItems={menuItems}
    />
  )
}

export const CollaboratorNotification: React.FC<{
  notification: Extract<HomeNotification, { type: 'collaborator-access-requested' }>
}> = ({ notification }) => {
  const { t } = useTranslation()
  const { editable, requesterUserId } = notification
  const href = editUrl(editable.type, editable.id)
  const inviteUserMutation = useTypedMutation(XRealtimeAdminCoursesSetCourseRoles, {})
  const [role, setRole] = useState<'editor' | 'commenter'>('editor')
  const [requesterRole, setRequesterRole] = useState<CourseRole | undefined>(notification.requesterRole)
  const username = useUsername(requesterUserId)

  const assetContext = getAssetContextFromEditableContent(editable)
  const imgSrc = useResolveAsset({
    image: editable.image,
    size: 'default',
    assetContext,
  })

  return (
    <NotificationContainer
      notification={notification}
      icon={<UserAvatar userId={requesterUserId} />}
      title={
        <NotificationTitle
          titleKey='notification.user-requested-collaborator-access'
          values={{ user: username }}
        />
      }
      content={
        <>
          <View alignItems='center' grow>
            <View grow>
              <RouterLink href={href}>
                <PlainEntityImage src={imgSrc} />
              </RouterLink>
              <RouterLink href={href}>
                <Title size='regular' bold>
                  {editable.title}
                </Title>
              </RouterLink>
            </View>

            <View>
              {requesterRole === undefined ? (
                <>
                  <CourseRoleDropdown selectedRole={role} onSelect={setRole} />
                  <Button
                    disabled={inviteUserMutation.isPending}
                    onClick={() => {
                      inviteUserMutation.mutate(
                        {
                          assignments: [{ assign: true, userId: requesterUserId, role: role }],
                          courseId: editable.id,
                        },
                        { onSuccess: () => setRequesterRole(role) }
                      )
                    }}
                  >
                    {t('notification.grant-access')}
                  </Button>
                </>
              ) : (
                <Button
                  disabled={inviteUserMutation.isPending}
                  onClick={() =>
                    inviteUserMutation.mutate(
                      {
                        assignments: [{ assign: false, userId: requesterUserId, role: role }],
                        courseId: editable.id,
                      },
                      { onSuccess: () => setRequesterRole(undefined) }
                    )
                  }
                >
                  {t('notification.revoke-access')}
                </Button>
              )}
            </View>
          </View>
        </>
      }
    />
  )
}
