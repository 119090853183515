import React from 'react'
import { useTranslation } from 'sierra-client/hooks/use-translation'
import { UserContentFilter } from 'sierra-client/views/manage/users/utils/use-user-assigned-content'
import { CourseStatus } from 'sierra-domain/api/manage'
import { MenuItem } from 'sierra-ui/components'
import { SingleSelectDropdown } from 'sierra-ui/primitives/menu-dropdown'
import styled from 'styled-components'

const MinWidth = styled.div`
  min-width: 160px;
`

type UserContentFilterProps<T extends keyof UserContentFilter> = {
  value: UserContentFilter[T]
  onChange: (value?: UserContentFilter[T]) => void
}

const IGNORE_FILTER = 'all' as const

export const UserContentProgressFilter: React.FC<UserContentFilterProps<'progress'>> = ({
  value,
  onChange,
}) => {
  const { t } = useTranslation()

  const items: MenuItem<CourseStatus | typeof IGNORE_FILTER>[] = [
    {
      type: 'label',
      id: IGNORE_FILTER,
      label: t('manage.content.filters.any-progress'),
    },
    {
      type: 'label',
      id: 'not-started',
      label: t('manage.users.status.not-started'),
    },
    {
      type: 'label',
      id: 'started',
      label: t('learner-dashboard.started'),
    },
    {
      type: 'label',
      id: 'passed',
      label: t('learner-dashboard.completed'),
    },
  ]
  const selectedItem = items.find(item => item.id === value) ?? items.find(item => item.id === IGNORE_FILTER)
  return (
    <MinWidth>
      <SingleSelectDropdown
        menuItems={items}
        selectedItem={selectedItem}
        onSelect={item => {
          if (item.id === IGNORE_FILTER) {
            onChange(undefined)
          } else {
            onChange(item.id)
          }
        }}
      />
    </MinWidth>
  )
}
