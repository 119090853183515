import React from 'react'
import {
  LiveQuizAvatar,
  LiveQuizAvatarProps,
} from 'sierra-client/views/v3-author/live-quiz/live-quiz-user/live-quiz-avatar'
import { sample } from 'sierra-domain/utils'

export const LIVE_QUIZ_AVATAR_EMOJIS = [
  '🤠',
  '🥳',
  '🤓',
  '😎',
  '🤩',
  '🥸',
  '😇',
  '🤖',
  '😷',
  '🥺',
  '😈',
  '👿',
  '👹',
  '👺',
  '🤑',
  '🤥',
  '😶',
  '😮',
  '😱',
  '😰',
  '😤',
  '😖',
  '🥵',
  '🥶',
  '😵',
  '🫠',
  '🫣',
  '🫢',
  '😜',
  '😝',
  '😛',
  '🤑',
  '😬',
  '🤐',
  '😇',
  '😍',
  '🥰',
  '😘',
  '😗',
  '😙',
  '😚',
  '🥲',
  '😊',
  '🤭',
  '🫡',
  '🙃',
  '🥴',
  '😕',
  '😟',
  '😦',
  '😯',
  '😲',
  '🥹',
  '😢',
  '😭',
  '😤',
] as const

export const LiveQuizAvatarPicker: React.FC<
  Omit<LiveQuizAvatarProps, 'onClickAvatar' | 'onShuffle'> & {
    onSelectIcon: (emoji: (typeof LIVE_QUIZ_AVATAR_EMOJIS)[number]) => void
  }
> = ({ onSelectIcon, ...avatar }) => {
  const onShuffle = (): void => {
    onSelectIcon(sample(LIVE_QUIZ_AVATAR_EMOJIS))
  }

  return <LiveQuizAvatar {...avatar} onShuffle={onShuffle} />
}
