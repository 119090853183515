import { CachedQueryOptions } from 'sierra-client/state/api'
import { RequestError } from 'sierra-domain/error'

export const getInsightsQueryOptions = <TQueryFnData = unknown>(): CachedQueryOptions<TQueryFnData> => {
  const useQueryOptions: CachedQueryOptions<TQueryFnData> = {
    retry: (failureCount, error) => {
      if (failureCount > 3) {
        return false
      }
      if (error instanceof RequestError) {
        // Dont retry errors where our backend responded
        return false
      }
      return true
    },
  }
  return useQueryOptions
}
