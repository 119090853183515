import { useQuery } from '@tanstack/react-query'
import { Variants } from 'framer-motion'
import { useAtomValue } from 'jotai'
import _ from 'lodash'
import React from 'react'
import { Link } from 'sierra-client/components/common/link'
import { useLocalizedFormatters } from 'sierra-client/core/format'
import { useGetDashboardTemplate } from 'sierra-client/features/insights/api-hooks/use-get-dashboard-template'
import { listDashboardTemplatesQuery } from 'sierra-client/features/insights/api-hooks/use-list-dashboard-templates'
import { useListDashboards } from 'sierra-client/features/insights/api-hooks/use-list-dashboards'
import { DashboardMeta, dashboardsMetaAtom } from 'sierra-client/features/insights/atoms'
import { collaboratorToAvatarStackItem } from 'sierra-client/features/insights/home/dashboard-sharing-info'
import { WidgetPreview } from 'sierra-client/features/insights/home/widget-preview'
import { useTranslation } from 'sierra-client/hooks/use-translation'
import { labelToString } from 'sierra-client/lib/filter/components/common'
import { InsightsDashboardTemplate, ListedUserFilterDashboard } from 'sierra-domain/api/insights'
import { assertNever, isDefined } from 'sierra-domain/utils'
import { AvatarStack, TruncatedText } from 'sierra-ui/components'
import { LoadingSpinner, Text, View } from 'sierra-ui/primitives'
import { token } from 'sierra-ui/theming'
import styled from 'styled-components'

const RecentlyViewedScroller = styled(View)`
  &::-webkit-scrollbar {
    display: none;
  }
`

const NoWrapText = styled(Text)`
  white-space: nowrap;
`

const DashboardPreviewContainer = styled(View).attrs({
  direction: 'column',
  borderColor: 'surface/strong',
  padding: '16',
  radius: 'size-20',
  position: 'relative',
})`
  min-width: 340px;
  max-width: 340px;
`

const AvatarStackContainer = styled(View).attrs({
  position: 'absolute',
})`
  left: 0.6rem;
  top: 0.9rem;
`

const DashboardWidgetGridContainer = styled(View)`
  height: 220px;
  display: grid;
  grid-template-columns: repeat(12, 1fr);
  grid-auto-rows: 1fr 1fr;
  gap: 6px;
  background: ${token('surface/default')};
  border-radius: 16px;
`

const variants = {
  hidden: { opacity: 0, x: 20 },
  visible: {
    opacity: 1,
    x: 0,

    transition: { type: 'spring', bounce: 0, duration: 0.5, staggerChildren: 0.1 },
  },
} as const satisfies Variants

type Suggestion =
  | {
      type: 'dashboard'
      dashboard: ListedUserFilterDashboard
      meta: DashboardMeta
    }
  | {
      type: 'template'
      template: InsightsDashboardTemplate
    }

const DashboardSuggestion: React.FC<{ dashboard: ListedUserFilterDashboard; meta: DashboardMeta }> = ({
  dashboard,
  meta,
}) => {
  const { formatTimeAgo } = useLocalizedFormatters()

  const sortedWidgetsMeta = _.sortBy(meta.widgetsMeta, ['layout.x', 'layout.y'])
  const filteredWidgetsMeta = sortedWidgetsMeta.filter(widget => widget.layout.y + widget.layout.height < 3)

  return (
    <DashboardPreviewContainer animated variants={variants}>
      <Link href={`/manage/insights/dashboards/${dashboard.dashboard.id}`} next>
        <AvatarStackContainer>
          <AvatarStack
            withRemainerIndication
            withOutlines
            withTooltips
            size='minuscule'
            max={5}
            users={dashboard.collaborators.map(collaboratorToAvatarStackItem)}
          />
        </AvatarStackContainer>
        <DashboardWidgetGridContainer grow>
          {filteredWidgetsMeta.map((widget, index) => (
            <WidgetPreview
              key={index}
              type={widget.type}
              x={widget.layout.x}
              y={widget.layout.y}
              width={widget.layout.width}
              height={widget.layout.height}
            />
          ))}
        </DashboardWidgetGridContainer>
        <View marginTop='16' gap='6'>
          <View grow>
            <TruncatedText lines={1} bold size='large'>
              {dashboard.dashboard.title}
            </TruncatedText>
          </View>
          <View>
            <NoWrapText color='foreground/muted' size='micro'>
              {formatTimeAgo(meta.lastVisitedTimestampMs)}
            </NoWrapText>
          </View>
        </View>
      </Link>
    </DashboardPreviewContainer>
  )
}

const TemplateSuggestion: React.FC<{ template: InsightsDashboardTemplate }> = ({ template }) => {
  const { dynamicT } = useTranslation()
  const templateResponse = useGetDashboardTemplate({ id: template.id })

  if (templateResponse.data === undefined) return null

  const widgetsMeta = templateResponse.data.widgets.map(widget => ({
    type: widget.widget.type,
    layout: widget.layout,
  })) satisfies DashboardMeta['widgetsMeta']
  const sortedWidgetsMeta = _.sortBy(widgetsMeta, ['layout.x', 'layout.y'])
  const filteredWidgetsMeta = sortedWidgetsMeta.filter(widget => widget.layout.y + widget.layout.height < 3)

  return (
    <DashboardPreviewContainer animated variants={variants}>
      <Link href={`/manage/insights/dashboards/templates/${template.id}`} next>
        <DashboardWidgetGridContainer grow>
          {filteredWidgetsMeta.map((widget, index) => (
            <WidgetPreview
              key={index}
              type={widget.type}
              x={widget.layout.x}
              y={widget.layout.y}
              width={widget.layout.width}
              height={widget.layout.height}
            />
          ))}
        </DashboardWidgetGridContainer>
        <View marginTop='16' gap='6'>
          <View grow>
            <TruncatedText lines={1} bold size='large'>
              {labelToString(template.name, dynamicT)}
            </TruncatedText>
          </View>
        </View>
      </Link>
    </DashboardPreviewContainer>
  )
}

export const DashboardSuggestions: React.FC = () => {
  const { t } = useTranslation()
  const dashboardsResponse = useListDashboards()
  const templateResponse = useQuery(listDashboardTemplatesQuery)
  const dashboardsMeta = useAtomValue(dashboardsMetaAtom)

  if (dashboardsResponse.isPending) return <LoadingSpinner />
  if (templateResponse.isPending) return <LoadingSpinner />

  if (dashboardsResponse.isError) return null
  if (templateResponse.isError) return null

  const dashboardsWithMeta = dashboardsResponse.data.dashboards
    .map(dashboard => {
      const meta = dashboardsMeta[dashboard.dashboard.id]
      if (meta === undefined) return undefined

      return { dashboard, meta }
    })
    .filter(isDefined)

  const sortedDashboards = dashboardsWithMeta.sort((a, b) => {
    return b.meta.lastVisitedTimestampMs - a.meta.lastVisitedTimestampMs
  })

  const dashboardSuggestions = sortedDashboards.map(dashboard => ({
    type: 'dashboard',
    dashboard: dashboard.dashboard,
    meta: dashboard.meta,
  })) satisfies Array<Suggestion>

  const templateSuggestions = templateResponse.data.map(template => ({
    type: 'template',
    template,
  })) satisfies Array<Suggestion>

  const suggestions = [...dashboardSuggestions, ...templateSuggestions].slice(
    0,
    6
  ) satisfies Array<Suggestion>

  if (suggestions.length < 1) return null

  return (
    <View direction='column' marginBottom='32' gap='16'>
      <Text bold size='regular'>
        {t('manage.insights.home.suggested-dashboards-header')}
      </Text>
      <RecentlyViewedScroller
        gap='16'
        direction='row'
        overflow='auto'
        alignItems='flex-start'
        animated
        initial='hidden'
        animate='visible'
        variants={variants}
      >
        {suggestions.map(suggestion => {
          switch (suggestion.type) {
            case 'dashboard':
              return (
                <DashboardSuggestion
                  key={suggestion.dashboard.dashboard.id}
                  dashboard={suggestion.dashboard}
                  meta={suggestion.meta}
                />
              )
            case 'template':
              return <TemplateSuggestion key={suggestion.template.id} template={suggestion.template} />
            default:
              assertNever(suggestion)
          }
        })}
      </RecentlyViewedScroller>
    </View>
  )
}
