import { useAtomValue, useSetAtom } from 'jotai'
import { capitalize } from 'lodash'
import React from 'react'
import { HeaderItem, useToggleMobileGlobalSidebar } from 'sierra-client/features/collapsable-sidebar'
import { ItemsList } from 'sierra-client/features/collapsable-sidebar/items/shared/items-list'
import { SidebarGlossaryNavigation } from 'sierra-client/features/global-sidebar/components/sidebar-glossary-navigation'
import { sidebarMainNavigationClickedLogger } from 'sierra-client/features/global-sidebar/logger'
import {
  SidebarLocation,
  SidebarLocationEditor,
  SidebarLocationLearner,
} from 'sierra-client/features/global-sidebar/types'
import { useContentKindPermissions } from 'sierra-client/hooks/use-permissions'
import { useRouterSelfPacedIds } from 'sierra-client/hooks/use-router-ids'
import { useTranslation } from 'sierra-client/hooks/use-translation'
import { getGlobalRouter } from 'sierra-client/router'
import { useDispatch } from 'sierra-client/state/hooks'
import { assistantActiveAtom } from 'sierra-client/views/global-assistant/atoms'
import { editUrlFromScopedId } from 'sierra-domain/api/editable-content'
import {
  ScopedCreateContentId,
  ScopedLiveContentId,
  ScopedSelfPacedContentId,
} from 'sierra-domain/collaboration/types'
import { FileId } from 'sierra-domain/flexible-content/identifiers'
import { iife } from 'sierra-domain/utils'
import { GlobalSidebarLiveSessionContentReferenceAtom } from '../atoms'
import { SidebarOngoingSession } from './sidebar-ongoing-sessions'

const EditCourse: React.FC<{ scopedCreateContentId: ScopedCreateContentId; fileId: FileId | undefined }> = ({
  scopedCreateContentId,
  fileId,
}) => {
  const { t } = useTranslation()
  const canEdit = useContentKindPermissions(ScopedCreateContentId.extractId(scopedCreateContentId)).has(
    'EDIT_CONTENT'
  )

  const editorUrl = editUrlFromScopedId(scopedCreateContentId, fileId)

  if (canEdit) {
    return (
      <HeaderItem
        type='label'
        href={editorUrl}
        iconId='edit'
        title={t('author.edit.edit-content')}
        openInNewTab
      />
    )
  } else {
    return null
  }
}

export const SidebarCourseNavigation: React.FC<{
  location: SidebarLocationEditor | SidebarLocationLearner
  exitLocation: SidebarLocation | undefined
}> = ({ location, exitLocation }) => {
  const { t } = useTranslation()
  const dispatch = useDispatch()
  const setShowAssistantSearch = useSetAtom(assistantActiveAtom)
  const { close: closeMobileSidebar } = useToggleMobileGlobalSidebar()
  const liveContentReference = useAtomValue(GlobalSidebarLiveSessionContentReferenceAtom)

  const selfPacedIds = useRouterSelfPacedIds()
  const scopedCreateContentId = iife(() => {
    if (selfPacedIds?.flexibleContentId !== undefined) {
      return ScopedSelfPacedContentId.fromId(selfPacedIds.flexibleContentId)
    }

    if (liveContentReference?.contentId !== undefined) {
      return ScopedLiveContentId.fromId(liveContentReference.contentId)
    }
  })

  const fileId: FileId | undefined = iife(() => {
    if (selfPacedIds?.fileId === 'next') {
      // safeParse is technically unnecessary here, but typesript cannot correctly narrow the types here
      return FileId.safeParse(selfPacedIds.fileId).data
    }

    if (liveContentReference?.fileId !== undefined) {
      return liveContentReference.fileId
    }
  })

  return (
    <nav>
      <ItemsList $marginTop={16}>
        <HeaderItem
          type='simple-button-item'
          iconId='arrow--left'
          title={capitalize(t('dictionary.back'))}
          onClick={() => getGlobalRouter().navigate({ to: exitLocation?.href ?? '/' })}
        />
        <HeaderItem
          type='simple-button-item'
          iconId='search'
          title={t('dictionary.search')}
          onClick={() => {
            void dispatch(sidebarMainNavigationClickedLogger({ navigatedTo: 'search' }))
            setShowAssistantSearch(true)
            closeMobileSidebar()
          }}
        />

        {scopedCreateContentId !== undefined && (
          <EditCourse scopedCreateContentId={scopedCreateContentId} fileId={fileId} />
        )}
        {location.courseType === 'native:self-paced' && <SidebarGlossaryNavigation mode={location.mode} />}
        {location.courseType === 'native:live' && location.mode === 'editor' && <SidebarOngoingSession />}
      </ItemsList>
    </nav>
  )
}
