import _ from 'lodash'
import { useMemo, useRef } from 'react'

/**
Keeps a list in a stable order. Elements that are _.isEqual are preserved in the
same order as in the previous call. New elements are added to the end.

Accepts a second argument for equality.

Example:
```
// First render
useStableOrder(['a', 'b', 'c', 'd']) // ['a', 'b', 'c', 'd']
// Second render
useStableOrder(['e', 'd', 'b', 'f']) // ['b', 'd', 'e', 'f']
```
*/
export const useStableOrder = <T>(list: Array<T>, equals: (a: T, b: T) => boolean = _.isEqual): Array<T> => {
  const previousRef = useRef<Array<T>>([])

  return useMemo(() => {
    const previousList = previousRef.current

    const sortedList = _.sortBy(list, (element, newIndex) => {
      const previousIndex = previousList.findIndex(e => equals(element, e))

      return previousIndex >= 0 ? Number.MIN_SAFE_INTEGER + previousIndex : newIndex
    })

    previousRef.current = sortedList

    return sortedList
  }, [list, equals])
}
