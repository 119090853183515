import { IAgoraRTCClient } from 'agora-rtc-sdk-ng'
import React, { createContext, useCallback, useContext, useEffect, useMemo, useState } from 'react'
import deviceConnectedSfx from 'sierra-client/assets/sounds/device-connected.mp3'
import { useNotif } from 'sierra-client/components/common/notifications'
import { useObservabilityTags } from 'sierra-client/components/liveV2/hooks/use-observability-tags'
import { useRestoreInitialUserSettings } from 'sierra-client/components/liveV2/hooks/use-restore-initial-user-settings'
import { useSanaSound } from 'sierra-client/components/liveV2/hooks/use-sana-sound'
import { LiveContextObj } from 'sierra-client/components/liveV2/live-context'
import { LiveSessionSyncCallState } from 'sierra-client/components/liveV2/live-session-call-state-sync'
import { LiveSessionIssueDetector } from 'sierra-client/components/liveV2/live-session-issue-detector'
import { LiveSessionIssueModal } from 'sierra-client/components/liveV2/live-session-issue-modal'
import {
  CameraError,
  DenoiserError,
  MicrophoneError,
  ScreenShareError,
  VideoCallServiceError,
  VirtualBackgroundError,
} from 'sierra-client/components/liveV2/services/video-call-service/helpers/errors'
import { useSetupVideoCallService } from 'sierra-client/components/liveV2/services/video-call-service/hooks/use-setup-video-call-service'
import { Mode } from 'sierra-client/components/liveV2/types'
import { useStageController } from 'sierra-client/components/liveV2/use-stage-controller'
import { errorLogger } from 'sierra-client/error/error-logger'
import { useOnUnmount } from 'sierra-client/hooks/use-on-unmount'
import { logger } from 'sierra-client/logger/logger'
import { useDispatch, useSelector } from 'sierra-client/state/hooks'
import { liveSessionClearInSessionLocalAwarenessData } from 'sierra-client/state/live-session/actions'
import { selectIsFacilitator } from 'sierra-client/state/live-session/selectors'
import { liveSessionSlice } from 'sierra-client/state/live-session/slice'
import {
  addIssue,
  joinBreakoutRoom as joinBreakoutRoomAction,
  leaveBreakoutRoom as leaveBreakoutRoomAction,
  leaveCall,
} from 'sierra-client/state/live/actions'
import { selectClientId } from 'sierra-client/state/live/selectors'
import { selectUserId } from 'sierra-client/state/user/user-selector'

const log = logger.getLogger({ name: 'LiveProvider' })

type FacilitatorBreakoutControlContext = {
  joinBreakoutRoom: (roomId: string) => Promise<void>
  returnToMainRoom: () => Promise<void>
}

type CurrentClientContext = { client: IAgoraRTCClient | undefined }

const FacilitatorBreakoutControlContextObj = createContext<FacilitatorBreakoutControlContext | undefined>(
  undefined
)
const CurrentClientContextObj = createContext<CurrentClientContext | undefined>(undefined)

export const LiveProvider: React.FC<React.PropsWithChildren<{ mode: Mode }>> = ({ children, mode }) => {
  const [currentClient, setCurrentClient] = useState<IAgoraRTCClient | undefined>(undefined)
  const [left, setLeft] = useState<boolean>(false)

  const isFacilitator = useSelector(selectIsFacilitator)
  const dispatch = useDispatch()
  const agoraUID = useSelector(selectClientId)
  const userId = useSelector(selectUserId)

  useStageController()

  const { push } = useNotif()
  const [playSwitchedDevice] = useSanaSound(deviceConnectedSfx, { volume: 0.1 })

  const handleVideoCallError = useCallback(
    (error: VideoCallServiceError) => {
      // Common error conditions
      if (error instanceof CameraError && error.code === 'PERMISSION_DENIED') {
        void dispatch(addIssue('camera-permission-denied'))
      } else if (error instanceof CameraError && error.code === 'NOT_READABLE') {
        void dispatch(addIssue('camera-not-readable'))
      } else if (error instanceof MicrophoneError && error.code === 'PERMISSION_DENIED') {
        void dispatch(addIssue('microphone-permission-denied'))
      } else if (error instanceof MicrophoneError && error.code === 'NOT_READABLE') {
        void dispatch(addIssue('microphone-not-readable'))
      } else if (error instanceof ScreenShareError) {
        void dispatch(addIssue('screen-share-permission-denied'))
      } else if (error instanceof DenoiserError && error.code === 'ERROR_INITIALIZING') {
        void dispatch(addIssue('denoiser-failed-to-start'))
      } else if (error instanceof DenoiserError && error.code === 'OVERLOADED') {
        void dispatch(addIssue('denoiser-overloaded'))
      } else if (error instanceof VirtualBackgroundError && error.code === 'ERROR_INITIALIZING') {
        void dispatch(addIssue('virtual-background-failed-to-start'))
      } else if (error instanceof VirtualBackgroundError && error.code === 'OVERLOADED') {
        void dispatch(addIssue('virtual-background-overloaded'))
      } else if (error instanceof CameraError && error.code === 'DEVICE_NOT_FOUND') {
        void dispatch(addIssue('camera-not-found'))
      } else if (error instanceof MicrophoneError && error.code === 'DEVICE_NOT_FOUND') {
        void dispatch(addIssue('microphone-not-found'))
      } else {
        log.captureWarning(error)
        push({ type: 'error', body: error.message })
      }
    },
    [push, dispatch]
  )

  // Setup the call by subscribing to the error events and start the camera and microphone
  const { isReady, videoCallService } = useSetupVideoCallService({ handleVideoCallError })
  useRestoreInitialUserSettings(isReady)

  // Play a sound and show a toast when a device is switched to
  useEffect(() => {
    const handler = ({ newDeviceName }: { newDeviceName: string }): void => {
      playSwitchedDevice()
      push({ type: 'custom', level: 'info', body: `${newDeviceName} connected` })
    }

    videoCallService?.on('device-switched', handler)
    return () => {
      videoCallService?.off('device-switched', handler)
    }
  }, [playSwitchedDevice, videoCallService, push])

  useEffect(() => {
    void (async (): Promise<void> => {
      if (!videoCallService) return

      try {
        const client = await videoCallService.getWrappedClient()
        setCurrentClient(client.getAgoraClient())
      } catch (error) {
        push({ type: 'error', body: 'Could not create video client' })
        errorLogger.captureError(error)
      }
    })()
  }, [push, videoCallService])

  const leave = useCallback(async (): Promise<void> => {
    await dispatch(leaveCall())
    dispatch(liveSessionClearInSessionLocalAwarenessData())
    setLeft(true)
  }, [dispatch])

  // workaround to make sure we close tracks when navigating away from the page
  useOnUnmount(() => {
    void leave()
  })

  const liveContextValue = useMemo(
    () => ({ leave, left, isReady, videoCallService }),
    [leave, left, isReady, videoCallService]
  )

  const currentClientContextValue = useMemo(() => ({ client: currentClient }), [currentClient])

  const facilitatorBreakoutControlContextValue = useMemo(
    () => ({
      joinBreakoutRoom: async (roomId: string) => {
        if (userId === undefined || !isFacilitator) return
        dispatch(liveSessionSlice.actions.removeUserBreakoutRoomAssignment({ userId }))
        await dispatch(joinBreakoutRoomAction({ breakoutRoom: roomId }))
      },
      returnToMainRoom: async () => {
        if (userId === undefined || !isFacilitator) return
        dispatch(liveSessionSlice.actions.removeUserBreakoutRoomAssignment({ userId }))
        await dispatch(leaveBreakoutRoomAction())
      },
    }),
    [dispatch, isFacilitator, userId]
  )

  useObservabilityTags({
    agoraUID,
    agoraChannelName: currentClient?.channelName,
    agoraConnectionState: currentClient?.connectionState,
  })

  return (
    <LiveContextObj.Provider value={liveContextValue}>
      <CurrentClientContextObj.Provider value={currentClientContextValue}>
        <FacilitatorBreakoutControlContextObj.Provider value={facilitatorBreakoutControlContextValue}>
          <LiveSessionSyncCallState />
          {isReady && <LiveSessionIssueDetector mode={mode} />}
          <LiveSessionIssueModal />
          {children}
        </FacilitatorBreakoutControlContextObj.Provider>
      </CurrentClientContextObj.Provider>
    </LiveContextObj.Provider>
  )
}

export const useCurrentClientContext = (): CurrentClientContext => {
  const context = useContext(CurrentClientContextObj)

  if (context === undefined) {
    throw new Error('This component must be wrapped in a LiveProvider')
  }

  return context
}

export const useBreakoutRoomFacilitatorActions = (): FacilitatorBreakoutControlContext => {
  const context = useContext(FacilitatorBreakoutControlContextObj)

  if (context === undefined) {
    throw new Error('This component must be wrapped in a LiveContext')
  }

  return context
}
