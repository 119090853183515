import fuzzysort from 'fuzzysort'
import _, { capitalize } from 'lodash'
import { DateTime } from 'luxon'
import React, { useMemo } from 'react'
import { Link } from 'sierra-client/components/common/link'
import {
  ContentCardContent,
  UpcomingSessionContentCard,
} from 'sierra-client/components/content-card/content-card'
import { useDebouncedAndLiveState } from 'sierra-client/hooks/use-debounced-state'
import { useTranslation } from 'sierra-client/hooks/use-translation'
import {
  nanoId12ListWithZodSerializer,
  stringWithZodSerializer,
  useQueryState,
} from 'sierra-client/lib/querystate/use-query-state'
import { prefetchCachedQuery, useCachedQuery } from 'sierra-client/state/api'
import { useDispatch } from 'sierra-client/state/hooks'
import { homePageContentCardClickedLogger } from 'sierra-client/views/learner/home/logger'
import { AnimatedSearch } from 'sierra-client/views/manage/components/animated-search'
import { VerticalSeparator } from 'sierra-client/views/workspace/components'
import { LearnEntity } from 'sierra-domain/api/entities'
import { HomeBrowseEntity, UpcomingSessions } from 'sierra-domain/api/learn'
import { NanoId12 } from 'sierra-domain/api/nano-id'
import { Tag } from 'sierra-domain/content/tag'
import { XRealtimeContentHomeBrowse } from 'sierra-domain/routes'
import { isDefined } from 'sierra-domain/utils'
import { Icon, MenuItem, TruncatedText } from 'sierra-ui/components'
import { Heading, Text, View } from 'sierra-ui/primitives'
import {
  DefaultDropdownTriggerProps,
  MultiSelectDropdown,
  SingleSelectDropdown,
} from 'sierra-ui/primitives/menu-dropdown'
import { token } from 'sierra-ui/theming'
import { focusStateSmall } from 'sierra-ui/utils/focus-ring'
import styled from 'styled-components'
import { z } from 'zod'

const ContentType = z.enum(['any-type', 'course', 'event', 'path', 'program'])
type ContentType = z.infer<typeof ContentType>
const ContentTypeSerializer = stringWithZodSerializer(ContentType)

const CategoriesTypeSerializer = nanoId12ListWithZodSerializer()

const HomeBrowseFilter = z.enum([
  'newest',
  'popular',
  'closest-in-time',
  'alphabetically-asc',
  'alphabetically-desc',
])
type HomeBrowseFilter = z.infer<typeof HomeBrowseFilter>
const SortingTypeSerializer = stringWithZodSerializer(HomeBrowseFilter)

export const BrowseGrid = styled.ul`
  height: 100%;
  width: 100%;
  gap: 16px;
  margin-top: 8px;

  display: grid;

  @media screen and (min-width: 768px) {
    grid-template-columns: repeat(3, 1fr);
  }

  @media screen and (min-width: 1200px) {
    grid-template-columns: repeat(4, 1fr);
  }

  @media screen and (min-width: 1600px) {
    grid-template-columns: repeat(5, 1fr);
  }
`
const StyledDropDownTrigger = styled.button<{
  $variant: 'default' | 'ghost' | 'transparent'
  $grow: boolean
  $disabled: boolean
}>`
  position: relative;
  min-height: 36px;
  width: fit-content;
  padding: 8px 14px;
  padding-right: 32px;
  border-radius: 0.5rem;
  font-size: 14px;
  line-height: 1.4;
  font-weight: 500;
  display: flex;
  justify-content: space-between;
  align-items: center;
  flex-wrap: wrap;
  text-align: left;
  user-select: none;

  cursor: pointer;

  ${p => p.$grow && 'width: 100%;'}

  transition: border-color 120ms;

  color: ${token('foreground/muted')};

  &:focus-visible {
    ${focusStateSmall}
  }

  &[data-state='open'],
  &:hover {
    color: ${token('foreground/primary')};
  }

  background-color: ${token('surface/default')};
  border: 1px solid ${token('form/border/1')};

  &:hover {
    border-color: ${token('form/border/2')};
  }

  &[data-state='open'],
  &:active {
    border-color: ${token('form/border/2')};
  }
`

const ChevronContainer = styled(View)`
  position: absolute;
  right: 8px;
`

const DefaultDropdownTrigger = React.forwardRef<HTMLButtonElement, DefaultDropdownTriggerProps>(
  ({ children, grow, variant = 'default', disabled = false, ...rest }, ref) => {
    return (
      <StyledDropDownTrigger $variant={variant} $grow={grow} $disabled={disabled} ref={ref} {...rest}>
        {children}
        <ChevronContainer>
          <Icon size='size-16' iconId='chevron--down--small' />
        </ChevronContainer>
      </StyledDropDownTrigger>
    )
  }
)

const matchingEntityTypes: Record<ContentType, LearnEntity['entityType'][]> = {
  'any-type': [],
  'course': ['native:self-paced', 'link', 'linkedin', 'scorm', 'scorm:course-group'],
  'path': ['path'],
  'program': ['program'],
  'event': ['native:event-group', 'live-session', 'native:live'],
}

type SortFunctionId = 'newest' | 'popular' | 'alphabetically-asc' | 'alphabetically-desc' | 'closest-in-time'
type BrowseContent = (HomeBrowseEntity & { type: 'entity' }) | (UpcomingSessions & { type: 'upcoming' })

const sortFunctions: Record<SortFunctionId, (xs: BrowseContent[]) => BrowseContent[]> = {
  'newest': xs =>
    _.orderBy(xs, [x => x.type === 'entity' && x.publishedAt !== undefined && x.publishedAt], ['desc']),
  'popular': xs =>
    _.orderBy(xs, [x => x.type === 'entity' && x.popularRanking > 0 && x.popularRanking], ['desc']).filter(
      p => p.type === 'entity' && p.popularRanking !== 0
    ),
  'alphabetically-asc': xs =>
    _.orderBy(xs, [x => x.type === 'entity' && x.entity.title.toLowerCase()], ['asc']),
  'alphabetically-desc': xs =>
    _.orderBy(xs, [x => x.type === 'entity' && x.entity.title.toLowerCase()], ['desc']),
  'closest-in-time': xs => _.sortBy(xs, [x => (x.type === 'entity' ? x.upcomingTime : x.session.startTime)]),
}

const HomeBreadcrumb = styled(Link)`
  color: ${token('foreground/muted')};
  transition: color 0.15s cubic-bezier(0.25, 0.1, 0.25, 1);

  &:hover {
    color: ${token('foreground/primary')};
  }
`

const CategoryDescriptionWrapper = styled(View)`
  border-top: 1px solid rgba(0, 0, 0, 0.05);
`

const CategoryTitle = styled(Text)`
  margin-top: 28px;
`

const CategoryDescription = styled(Text)`
  max-width: 80ch;
`

const CategoryInformation: React.FC<{ category: Tag }> = ({ category }) => {
  return (
    <CategoryDescriptionWrapper direction='column' gap='8'>
      <CategoryTitle size='regular' bold>
        {category.data.name}
      </CategoryTitle>
      <CategoryDescription>{category.data.description}</CategoryDescription>
    </CategoryDescriptionWrapper>
  )
}

const MaxWidthTruncatedText = styled(TruncatedText)`
  max-width: 80ch;
`

const UpcomingSessionGrid: React.FC<{
  upcomingSessions: UpcomingSessions[]
}> = ({ upcomingSessions }) => {
  const dispatch = useDispatch()

  const groupedUpcomingSessions = Object.entries(
    _.groupBy(upcomingSessions, s => {
      return DateTime.fromJSDate(s.session.startTime).toLocaleString({
        year: 'numeric',
        month: '2-digit',
      })
    })
  )

  const sortedUpcomingSessions = _.sortBy(groupedUpcomingSessions, it => it[1][0]?.session.startTime)

  return (
    <View direction='column' gap='32'>
      {sortedUpcomingSessions.map(session => {
        const date = session[1][0]

        if (date === undefined) {
          return null
        }

        const sessions = _.sortBy(session[1], it => it.session.startTime)

        return (
          <View direction='column' key={session[0]}>
            <View paddingTop='16'>
              <Text color='foreground/primary' bold>
                {capitalize(DateTime.fromJSDate(date.session.startTime).toLocaleString({ month: 'long' }))}
              </Text>
              <Text color='foreground/muted'>
                {DateTime.fromJSDate(date.session.startTime).toLocaleString({ year: 'numeric' })}
              </Text>
            </View>
            <BrowseGrid>
              {sessions.map(it => {
                return (
                  <UpcomingSessionContentCard
                    key={it.session.id}
                    session={it}
                    includeDate={true}
                    onCardClick={() => {
                      void dispatch(
                        homePageContentCardClickedLogger({
                          contentTitle: it.session.title,
                          contentType: it.entity.entityType,
                          section: 'upcoming-session',
                        })
                      )
                    }}
                  />
                )
              })}
            </BrowseGrid>
          </View>
        )
      })}
    </View>
  )
}

export const prefetchBrowseContent = (): Promise<void> => {
  return prefetchCachedQuery(XRealtimeContentHomeBrowse, {})
}

export const HomeV3Browse: React.FC = () => {
  const { t } = useTranslation()
  const dispatch = useDispatch()

  const query = useCachedQuery(XRealtimeContentHomeBrowse, {})
  const content = query.data?.entities
  const upcomingSessions = query.data?.upcomingSessions

  const categories = query.data?.categories ?? []

  const [sortFunctionId, setSortFunctionId] = useQueryState(SortingTypeSerializer, 'alphabetically-asc', 's')
  const [contentType, setContentType] = useQueryState(ContentTypeSerializer, 'any-type', 'c')
  const [selectedCategoryIds, setSelectedCategoryIds] = useQueryState(CategoriesTypeSerializer, [], 't')

  const [debouncedSearchTerm, liveSearchTerm, setSearchTerm] = useDebouncedAndLiveState('', { wait: 200 })

  const categoriesDropDownItems: MenuItem<NanoId12>[] = categories.map(c => {
    return {
      type: 'checkbox',
      id: c.id,
      label: c.data.name,
      value: c.data.name,
      onToggleChange: () => {},
      checked: selectedCategoryIds.includes(c.id),
    }
  })

  const contentTypeMenuItems: MenuItem<ContentType>[] = [
    {
      type: 'label',
      id: 'any-type',
      label: t('filter.content.any-type'),
    },
    {
      type: 'label',
      id: 'course',
      label: t('dictionary.course-singular'),
    },
    {
      type: 'label',
      id: 'path',
      label: t('dictionary.path-singular'),
    },
    {
      type: 'label',
      id: 'program',
      label: t('dictionary.program-singular'),
    },
    {
      type: 'label',
      id: 'event',
      label: t('content.sessions'),
    },
  ]
  const getContentType = (type: ContentType): MenuItem<ContentType> => {
    return (
      contentTypeMenuItems.find(it => it.id === type) ??
      ({
        type: 'label',
        id: 'any-type',
        label: t('filter.content.any-type'),
      } as MenuItem<ContentType>)
    )
  }

  const sortingTypeMenuItems: MenuItem<HomeBrowseFilter>[] = [
    {
      type: 'label',
      id: 'newest',
      label: t('home.browse.newest'),
    },
    {
      type: 'label',
      id: 'popular',
      label: t('dictionary.popular'),
    },
    {
      type: 'label',
      id: 'closest-in-time',
      label: t('course-detail.filters.date-time-asc'),
    },
    {
      type: 'label',
      id: 'alphabetically-asc',
      label: t('sort.alphabetically.a-z'),
    },
    {
      type: 'label',
      id: 'alphabetically-desc',
      label: t('sort.alphabetically.z-a'),
    },
  ]
  const getSortingType = (type: HomeBrowseFilter): MenuItem<HomeBrowseFilter> => {
    return (
      sortingTypeMenuItems.find(it => it.id === type) ??
      ({
        type: 'label',
        id: 'closest-in-time',
        label: t('course-detail.filters.date-time-asc'),
      } as MenuItem<HomeBrowseFilter>)
    )
  }

  const singleSelectedCategory =
    selectedCategoryIds.length === 1 ? categories.find(c => c.id === selectedCategoryIds[0]) : undefined

  const filteredOnUpcomingSessions = sortFunctionId === 'closest-in-time' && contentType === 'event'

  const displayEntities = useMemo(() => {
    const entities = content ?? []
    const upcoming = upcomingSessions ?? []
    const sortFunction = sortFunctions[sortFunctionId]
    const searchContent = debouncedSearchTerm === '' ? entities : _.unionBy([...entities], 'entity.id')

    const matchingContent = searchContent
      .filter(
        entity =>
          contentType === 'any-type' || matchingEntityTypes[contentType].includes(entity.entity.entityType)
      )
      .map(it => {
        return Object.assign(it, { type: 'entity' }) as BrowseContent
      })

    const upcomingSessionsContent = filteredOnUpcomingSessions
      ? upcoming.map(it => {
          return Object.assign(it, { type: 'upcoming' }) as BrowseContent
        })
      : []

    const sortedContent = sortFunction(matchingContent.concat(upcomingSessionsContent))

    const categoriesMatchedContent =
      selectedCategoryIds.length === 0
        ? sortedContent
        : sortedContent.filter(c => c.entity.tags.find(tag => selectedCategoryIds.includes(tag.id)))

    if (debouncedSearchTerm === '') {
      return categoriesMatchedContent
    }

    const filteredContent = fuzzysort
      .go(debouncedSearchTerm, categoriesMatchedContent, {
        all: true,
        limit: debouncedSearchTerm.length > 0 ? 20 : undefined,
        key: 'entity.title',
      })
      .map(({ obj }) => obj)

    return filteredContent
  }, [
    content,
    upcomingSessions,
    sortFunctionId,
    debouncedSearchTerm,
    filteredOnUpcomingSessions,
    selectedCategoryIds,
    contentType,
  ])

  return (
    <View direction='column' gap='24' padding='48' paddingTop='16'>
      <View gap='24' direction='column'>
        <View gap='4' marginBottom='8'>
          <HomeBreadcrumb bold={true} size='small' href='/'>
            {t('topbar.home')}
          </HomeBreadcrumb>
          <Icon color='foreground/muted' iconId='chevron--right--small' />
          <Text bold={true}>{t('dictionary.browse')} </Text>
        </View>

        <Heading size='h4' bold={true}>
          {t('dictionary.browse')}
        </Heading>
        <View justifyContent='space-between' alignItems='center' wrap='wrap'>
          <View gap='12' wrap='wrap'>
            <AnimatedSearch
              value={liveSearchTerm}
              onChange={setSearchTerm}
              placeholder={`${t('dictionary.search')}...`}
            />
            <VerticalSeparator />
            <View gap='8'>
              {categoriesDropDownItems.length > 0 && (
                <MultiSelectDropdown
                  selectedItems={categoriesDropDownItems.filter(it => selectedCategoryIds.includes(it.id))} // OK as longs as id === value
                  onSelect={item => setSelectedCategoryIds(prev => [...prev, item.id])}
                  onUnselect={item => setSelectedCategoryIds(prev => prev.filter(it => it !== item.id))}
                  menuItems={categoriesDropDownItems}
                  renderTrigger={() => (
                    <DefaultDropdownTrigger grow={false}>
                      <MaxWidthTruncatedText lines={1} bold>
                        {(selectedCategoryIds.length === 0
                          ? t('home.browse.all-categories')
                          : selectedCategoryIds.length === 1
                            ? categoriesDropDownItems.find(it => it.id === selectedCategoryIds[0])?.label
                            : t('home.browse.categories', { count: selectedCategoryIds.length })) ?? ''}
                      </MaxWidthTruncatedText>
                    </DefaultDropdownTrigger>
                  )}
                />
              )}
              <SingleSelectDropdown
                searchPlaceholder={t('menu.search.placeholder')}
                menuItems={contentTypeMenuItems}
                selectedItem={getContentType(contentType)}
                onSelect={item => {
                  setContentType(item.id)
                  if (item.id === 'any-type') {
                    setSortFunctionId('newest')
                  }
                  if (item.id === 'event') {
                    setSortFunctionId('closest-in-time')
                  }
                }}
                grow={false}
                bold
              />
            </View>
          </View>
          <SingleSelectDropdown
            bold
            searchPlaceholder={t('menu.search.placeholder')}
            menuItems={sortingTypeMenuItems}
            selectedItem={getSortingType(sortFunctionId)}
            onSelect={item => setSortFunctionId(item.id)}
            grow={false}
          />
        </View>
      </View>

      {isDefined(singleSelectedCategory) &&
        isDefined(singleSelectedCategory.data.description) &&
        singleSelectedCategory.data.description.trim().length > 0 && (
          <CategoryInformation category={singleSelectedCategory} />
        )}

      {filteredOnUpcomingSessions ? (
        <UpcomingSessionGrid
          upcomingSessions={displayEntities
            .filter(it => it.type === 'upcoming')
            .map(it => {
              const { type, ...rest } = it
              return rest as UpcomingSessions
            })}
        />
      ) : (
        <BrowseGrid>
          {displayEntities.map(entity =>
            entity.type === 'entity' ? (
              <ContentCardContent key={entity.entity.id} entity={entity.entity} />
            ) : (
              <UpcomingSessionContentCard
                session={entity}
                key={entity.session.id}
                includeDate={false}
                onCardClick={() => {
                  void dispatch(
                    homePageContentCardClickedLogger({
                      contentTitle: entity.session.title,
                      contentType: entity.entity.entityType,
                      section: 'upcoming-session',
                    })
                  )
                }}
              />
            )
          )}
        </BrowseGrid>
      )}
    </View>
  )
}
