import { useEffect, useMemo, useState } from 'react'
import {
  useHomeworkSubmissionMutation,
  useHomeworkSubmissionsForLearner,
  useUploadHomework,
} from 'sierra-client/api/hooks/use-homework'
import { useTranslation } from 'sierra-client/hooks/use-translation'
import { selectCardIsCompleted } from 'sierra-client/state/card-progress/selectors'
import { selectCurrentCourseId } from 'sierra-client/state/content/selectors'
import { useDispatch, useSelector } from 'sierra-client/state/hooks'
import { useSetCardProgress } from 'sierra-client/views/flexible-content/progress-tracking/context'
import { MediaUploader } from 'sierra-client/views/v3-author/common/media-uploader/media-uploader'
import {
  Box,
  HomeworkFileIconWithImage,
  LightText,
} from 'sierra-client/views/v3-author/homework/homework-atoms'

import { JSONContent } from '@tiptap/core'
import { AnimatePresence } from 'framer-motion'
import { useAtom, useAtomValue } from 'jotai'
import { graphql } from 'sierra-client/api/graphql/gql'
import { convertGQLBasicUserToAvatarStackUserShape } from 'sierra-client/api/graphql/util/convert-gql-basic-user'
import { useGraphQuery } from 'sierra-client/api/hooks/use-graphql-query'
import { AppThemeTokenProvider } from 'sierra-client/config/token-provider'
import { fetchCourseProgress } from 'sierra-client/state/card-progress/actions'
import { atomWithStorage } from 'sierra-client/state/storage'
import { dynamic } from 'sierra-client/utils/dynamic'
import { useTracking } from 'sierra-client/views/manage/hooks/use-tracking'
import {
  OldSubmission,
  StyledRouterLink,
  SubmissionWrapper,
} from 'sierra-client/views/v3-author/homework/homework-upload/old-submission'
import { CreateContentId, NanoId12 } from 'sierra-domain/api/nano-id'
import { ScopedFileId } from 'sierra-domain/collaboration/types'
import { FileId } from 'sierra-domain/flexible-content/identifiers'
import { HomeworkData } from 'sierra-domain/flexible-content/types'
import {
  GetHomeworkSubmissionsForUserResponse,
  HomeworkFileWithUrl,
  PostHomeworkFileResponse,
} from 'sierra-domain/homework'
import { isDefined } from 'sierra-domain/utils'
import { AvatarStack, Icon, Tooltip, TruncatedText } from 'sierra-ui/components'
import { FreeTextEditor } from 'sierra-ui/missions/workflows/free-text-editor'
import { Button, IconButton, LoadingSpinner, Spacer, Text, View } from 'sierra-ui/primitives'
import styled from 'styled-components'

const ExerciseRecording = dynamic(() =>
  import('sierra-client/views/v3-author/homework/homework-upload/exercise-record').then(
    res => res.ExerciseRecording
  )
)

const BoxWrapper = styled(Box).attrs({
  alignItems: 'stretch',
  justifyContent: 'center',
})`
  flex: auto;
  position: relative;
  min-height: 20rem;
`

const VideoBoxWrapper = styled(BoxWrapper)`
  aspect-ratio: 1;
  min-height: unset;
`

const StyledMediaUploader = styled(MediaUploader)`
  width: 100%;
  min-height: 20rem;
  border-radius: ${p => p.theme.borderRadius['size-12']};
` as typeof MediaUploader

const NewSubmissionWrapper = styled(SubmissionWrapper).attrs({ padding: '24' })`
  min-height: 20rem;
`

const FreeTextEditorWrapper = styled.div`
  display: flex;
  flex: 1;
`

const homeworkReviewersQuery = graphql(`
  query HomeworkReviewersQuery($courseId: CourseId!, $fileId: CourseCardId!) {
    homeworks(courseId: $courseId, fileId: $fileId) {
      assignedReviewers {
        id
        firstName
        lastName
        displayName
        avatar {
          ...AvatarFragment
        }
      }
    }
  }
`)

const ReviewersText: React.FC<{ courseId: NanoId12; fileId: NanoId12 }> = ({ courseId, fileId }) => {
  const { t } = useTranslation()

  const query = useGraphQuery({ document: homeworkReviewersQuery }, { courseId, fileId })
  const reviewers = query.data?.homeworks[0]?.assignedReviewers ?? []
  const users = reviewers.map(reviewer => convertGQLBasicUserToAvatarStackUserShape(reviewer))

  if (reviewers.length < 1) return null

  return (
    <View gap='2'>
      <Text color='foreground/muted' size='small'>
        {t('content.homework.assigned-reviewer-info')}
      </Text>
      <AvatarStack withTooltips withRemainerIndication max={5} users={users} size='small' />
    </View>
  )
}

const KILO = 1000

const getSizeText = (size: number): string => {
  if (size >= Math.pow(KILO, 3)) return `${(size / Math.pow(KILO, 3)).toFixed(0)} GB`
  if (size >= Math.pow(KILO, 2)) return `${(size / Math.pow(KILO, 2)).toFixed(0)} MB`
  if (size >= Math.pow(KILO, 1)) return `${(size / Math.pow(KILO, 1)).toFixed(0)} kB`
  return `${size} B`
}

type PendingFileSubmission = { fileInfo: HomeworkFileWithUrl }

const NewFileSubmission: React.FC<{
  uploadedFile: HomeworkFileWithUrl
  onSubmit?: (submission: PendingFileSubmission) => void
  onRemoved?: (submission: PendingFileSubmission) => void
  submitButtonLoading?: boolean
}> = ({ uploadedFile, onSubmit, onRemoved, submitButtonLoading = false }) => {
  const { t } = useTranslation()

  return (
    <NewSubmissionWrapper
      key={uploadedFile.fileId}
      animated
      initial={{ opacity: 0 }}
      animate={{ opacity: 1 }}
      exit={{ opacity: 0 }}
      layout
    >
      {/* Todo: Improve preview of user submission */}
      <Text size='small' bold>
        {t('content.homework.your-submission')}
      </Text>
      <View grow gap='16' alignItems='flex-start' paddingTop='12'>
        <HomeworkFileIconWithImage fileInfo={uploadedFile} />
        <View direction='column' grow gap='4'>
          <TruncatedText lines={1} size='small' bold>
            {uploadedFile.fileName}
          </TruncatedText>
          <LightText size='small'>{getSizeText(uploadedFile.size)}</LightText>
        </View>

        <View alignItems='center'>
          <Tooltip title={t('dictionary.view')}>
            <StyledRouterLink href={uploadedFile.url} target='_blank' rel='noopener noreferrer'>
              <Icon iconId='view' color='foreground/muted' />
            </StyledRouterLink>
          </Tooltip>
          <IconButton
            iconId='trash-can'
            variant='transparent'
            tooltip={t('dictionary.remove')}
            onClick={() => {
              if (onRemoved !== undefined) onRemoved({ fileInfo: uploadedFile })
            }}
          />
        </View>
      </View>
      <View grow paddingBottom='8' justifyContent='flex-end' alignItems='flex-end'>
        <Button
          variant='secondary'
          onClick={() => {
            if (onRemoved !== undefined) onRemoved({ fileInfo: uploadedFile })
          }}
        >
          {t('dictionary.cancel')}
        </Button>
        <Button
          variant='primary'
          loading={submitButtonLoading}
          disabled={submitButtonLoading}
          onClick={() => {
            if (onSubmit !== undefined) onSubmit({ fileInfo: uploadedFile })
          }}
        >
          {t('content.homework.submit')}
        </Button>
      </View>
    </NewSubmissionWrapper>
  )
}

const useSetCardAsCompleted = ({
  fileId,
  shouldComplete,
}: {
  fileId: FileId
  shouldComplete: boolean
}): void => {
  const courseId = useSelector(selectCurrentCourseId)
  const isCardCompleted = useSelector(state =>
    courseId !== undefined ? selectCardIsCompleted(state, courseId, fileId) : undefined
  )

  const { setCardCompleted } = useSetCardProgress()

  useEffect(() => {
    if (isCardCompleted === false && shouldComplete) {
      setCardCompleted()
    }
  }, [setCardCompleted, isCardCompleted, shouldComplete])
}

const HomeworkUploadInner: React.FC<{
  courseId: CreateContentId
  fileId: FileId
  data: HomeworkData
  submissions: GetHomeworkSubmissionsForUserResponse
}> = ({ courseId, fileId, data, submissions }) => {
  const tracking = useTracking()
  const dispatch = useDispatch()
  const { uploadHomework } = useUploadHomework()
  const { t } = useTranslation()
  const submissionMutation = useHomeworkSubmissionMutation()

  // TODO: might be good idea move the uploaded file state to the DB or to Redux
  const [uploadedFile, setUploadedFile] = useState<PostHomeworkFileResponse>()

  const fileNanoId = ScopedFileId.extractId(fileId)

  const sortedSubmissions = submissions.data.sort(
    (a, b) => new Date(b.createdAt).getTime() - new Date(a.createdAt).getTime()
  )
  const latestSubmission = sortedSubmissions.at(0)

  const [openSubmissionId, setOpenSubmissionId] = useState<string | undefined>(latestSubmission?.submissionId)

  useEffect(() => {
    setOpenSubmissionId(latestSubmission?.submissionId)
  }, [latestSubmission?.submissionId])

  const numberOfSubmissions = submissions.data.length
  const allowedNumberOfSubmissions = data.limitOfSubmissions
  const submissionsLeft =
    isDefined(allowedNumberOfSubmissions) && isDefined(numberOfSubmissions)
      ? allowedNumberOfSubmissions - numberOfSubmissions
      : undefined

  const hasPassedSubmission = submissions.data.some(
    submission => submission.reviewInfo.gradeStatus === 'passed'
  )

  useSetCardAsCompleted({ fileId, shouldComplete: hasPassedSubmission })

  const allowSubmissions = useMemo(() => {
    if (hasPassedSubmission) return false
    if (allowedNumberOfSubmissions !== undefined) {
      if (allowedNumberOfSubmissions > numberOfSubmissions) return true
      else return false
    } else return true
  }, [hasPassedSubmission, allowedNumberOfSubmissions, numberOfSubmissions])

  const isAddingNewSubmission = openSubmissionId === undefined && allowSubmissions

  const localstorageKey = `free-text-exercise-${fileId}`

  const freetextAtom = useMemo(() => atomWithStorage<JSONContent>(localstorageKey, {}), [localstorageKey])
  const initialValue = useAtomValue(freetextAtom)
  const [contentInLocalStorage, saveContentInLocalStore] = useAtom(freetextAtom)

  const handleUpdate = (content: JSONContent): void => {
    saveContentInLocalStore(content)
  }

  const handleSubmit = (submission: PostHomeworkFileResponse | { text: string }): void => {
    submissionMutation.mutate(
      {
        fileId: fileNanoId,
        courseId,
        ...('text' in submission
          ? { submission: { type: 'text', text: submission.text } }
          : { submission: { type: 'file', file: submission } }),
      },
      {
        onSuccess: () => {
          setUploadedFile(undefined)
          // Reset freetext in localstorage
          saveContentInLocalStore({})
          void dispatch(fetchCourseProgress({ courseId, fileId }))
          tracking.submission.submit({ fileId: fileNanoId, courseId })
        },
      }
    )
  }

  return (
    <View direction='column' marginTop='24' marginBottom='24'>
      <Text size='small' bold>
        {t('content.homework.submit')}
      </Text>
      <AppThemeTokenProvider>
        <AnimatePresence>
          {isAddingNewSubmission && (
            <>
              {data.submissionType === 'text' && (
                <BoxWrapper direction='column' animated layout grow padding='small' gap='none'>
                  <Text bold>{t('content.homework.your-submission')}</Text>
                  <Spacer size='xxsmall' />
                  <FreeTextEditorWrapper>
                    <FreeTextEditor
                      editable
                      placeholder={t('homework.add-free-text-placeholder')}
                      content={initialValue}
                      onChange={handleUpdate}
                      menuTranslations={{
                        list: t('dictionary.list'),
                        alignment: t('create.toolbar.text-alignment'),
                        text: t('dictionary.text'),
                        heading: t('font.heading'),
                      }}
                    />
                  </FreeTextEditorWrapper>
                  <Spacer size='small' />
                  <View justifyContent='space-between'>
                    <ReviewersText courseId={courseId} fileId={fileNanoId} />
                    <Button
                      loading={submissionMutation.isPending}
                      disabled={submissionMutation.isPending}
                      onClick={() => {
                        handleSubmit({ text: JSON.stringify(contentInLocalStorage) })
                      }}
                    >
                      {t('dictionary.submit')}
                    </Button>
                  </View>
                </BoxWrapper>
              )}

              {data.submissionType === 'file' && (
                <>
                  {uploadedFile === undefined ? (
                    data.fileSubmissionSubtype === 'video' ? (
                      <VideoBoxWrapper direction='column' animated layout grow padding='xsmall'>
                        <ExerciseRecording
                          uploadMedia={uploadHomework}
                          onUploaded={result => {
                            setUploadedFile(result)
                          }}
                        />
                      </VideoBoxWrapper>
                    ) : (
                      <BoxWrapper animated layout direction='column'>
                        <StyledMediaUploader
                          iconId={'upload'}
                          accept={[
                            'application/pdf',
                            'video/mp4',
                            'image/gif',
                            'image/jpeg',
                            'image/png',
                            'application/zip',
                          ]}
                          uploadMedia={uploadHomework}
                          onUploaded={result => {
                            setUploadedFile(result)
                          }}
                          assetContext={{ type: 'homework' }}
                        />
                      </BoxWrapper>
                    )
                  ) : (
                    <NewFileSubmission
                      submitButtonLoading={submissionMutation.isPending}
                      uploadedFile={uploadedFile}
                      onSubmit={() => {
                        handleSubmit(uploadedFile)
                      }}
                      onRemoved={() => {
                        setUploadedFile(undefined)
                        setOpenSubmissionId(latestSubmission?.submissionId)
                      }}
                    />
                  )}
                </>
              )}
            </>
          )}

          {sortedSubmissions.map(submission => (
            <OldSubmission
              key={submission.submissionId}
              isOpen={openSubmissionId === submission.submissionId}
              setOpenSubmissionId={setOpenSubmissionId}
              submission={submission}
              allowSubmission={allowSubmissions}
              submissionsLeft={submissionsLeft}
            />
          ))}
        </AnimatePresence>
      </AppThemeTokenProvider>
    </View>
  )
}

export const HomeworkUpload: React.FC<{ courseId: CreateContentId; fileId: FileId; data: HomeworkData }> = ({
  courseId,
  fileId,
  data,
}) => {
  const { t } = useTranslation()

  const fileNanoId = ScopedFileId.extractId(fileId)
  const submissionsQuery = useHomeworkSubmissionsForLearner({ fileId: fileNanoId, courseId })

  if (submissionsQuery.isPending) {
    return <LoadingSpinner />
  }

  if (submissionsQuery.isError) {
    return (
      <View direction='column' marginTop='24' marginBottom='24' gap='16'>
        <Text size='small' bold>
          {t('dictionary.something-went-wrong')}
        </Text>
        <Button icon='restart' variant='secondary' onClick={() => window.location.reload()}>
          {t('dictionary.reload')}
        </Button>
      </View>
    )
  }

  return (
    <HomeworkUploadInner
      courseId={courseId}
      fileId={fileId}
      data={data}
      submissions={submissionsQuery.data}
    />
  )
}
