import { Logger } from 'sierra-client/core/logging/logger'
import { segmentService } from 'sierra-client/core/segment'
import { Identity, PageInstanceProps, PageMessage, TrackMessage } from 'sierra-client/state/logging/types'

const analyticsBrowser = segmentService.analyticsBrowser

export class SegmentLogger extends Logger {
  override async track(
    identity: Identity,
    message: TrackMessage<Record<string, unknown>>,
    loggingEnabled?: boolean
  ): Promise<void> {
    if (loggingEnabled === true) {
      const { event, properties, ...messageOptions } = message
      await analyticsBrowser.track(event, properties, { ...messageOptions, ...identity })
    }
  }

  override async page(
    identity: Identity,
    message: PageMessage<PageInstanceProps>,
    loggingEnabled?: boolean
  ): Promise<void> {
    if (loggingEnabled === true) {
      const { category, name, properties, ...messageOptions } = message
      await analyticsBrowser.page(category, name, properties, {
        ...messageOptions,
        ...identity,
      })
    }
  }
}
