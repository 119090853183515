import { PrimitiveAtom, atom } from 'jotai'
import React, { useMemo } from 'react'
import { FCC } from 'sierra-client/types'
import { AssessmentState } from 'sierra-client/views/v3-author/assessment-card/assessment-context'
import { AssessmentStatus } from 'sierra-domain/api/strategy-v2'

type BigAssessmentsContext = {
  AssessmentsBackend: PrimitiveAtom<{
    [fileId: string]: AssessmentStatus
  }>
  AssessmentsState: PrimitiveAtom<{ [fileId: string]: AssessmentState }>
}

const AssessmentsManagerContext = React.createContext<BigAssessmentsContext | undefined>(undefined)

const AssessmentsManagerProvider = AssessmentsManagerContext.Provider

export function useAssessmentsManagerContext(): BigAssessmentsContext {
  const context = React.useContext(AssessmentsManagerContext)

  if (context === undefined)
    throw new Error('useAssessmentsManagerContext must be used within a AssessmentsManagerProvider')

  return context
}

export const AssessmentsManager: FCC = ({ children }) => {
  const AssessmentsBackend = useMemo(
    () =>
      atom<{
        [fileId: string]: AssessmentStatus
      }>({}),
    []
  )

  const AssessmentsState = useMemo(() => atom<{ [fileId: string]: AssessmentState }>({}), [])

  const assessmentContext = useMemo(
    () => ({
      AssessmentsBackend,
      AssessmentsState,
    }),
    [AssessmentsBackend, AssessmentsState]
  )
  return <AssessmentsManagerProvider value={assessmentContext}>{children}</AssessmentsManagerProvider>
}
