import { AssetResolver } from 'sierra-client/hooks/use-resolve-asset'
import { prefetchImageUrl } from 'sierra-client/views/flexible-content/card-background'
import { AssetContext } from 'sierra-domain/asset-context'
import { File } from 'sierra-domain/flexible-content/types'

// A helper that will fetch a files background and, if it's an image card it will fetch the image too
// There are no garanties that the image will be cached or for how long, that is up to the browser
// but it works most of the time for most people
export const preloadFileImage = (
  file: File,
  assetResolver: AssetResolver,
  assetContext: AssetContext
): void => {
  const background = file.backgroundImage
  const imageData = file.data.type === 'image' ? file.data.image : undefined

  if (background !== undefined) {
    const imageUrl = assetResolver(background, assetContext)
    void prefetchImageUrl(imageUrl)
  }

  if (imageData !== undefined) {
    const imageUrl = assetResolver(imageData, assetContext)
    void prefetchImageUrl(imageUrl)
  }
}
