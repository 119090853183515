import { RefObject, useCallback, useEffect, useState } from 'react'

export const useIsScrolledToLeftOrRight = (
  ref: RefObject<HTMLDivElement | HTMLUListElement | null>
): { isScrolledToLeft: boolean; isScrolledToRight: boolean; updateScroll: () => void } => {
  const [isScrolledToLeft, setIsScrolledToLeft] = useState(true)
  const [isScrolledToRight, setIsScrolledToRight] = useState(false)

  const updateScroll = useCallback(() => {
    const { current } = ref
    if (current === null) return

    const { scrollLeft, scrollWidth, clientWidth } = current

    if (scrollLeft === 0) {
      setIsScrolledToLeft(true)
    } else {
      setIsScrolledToLeft(false)
    }

    const isNotScrollable = current.scrollWidth === current.clientWidth
    const scrolledToRight = scrollLeft + clientWidth === scrollWidth
    if (isNotScrollable || scrolledToRight) {
      setIsScrolledToRight(true)
    } else {
      setIsScrolledToRight(false)
    }
  }, [ref])

  useEffect(updateScroll, [updateScroll])

  return { updateScroll, isScrolledToLeft, isScrolledToRight }
}
