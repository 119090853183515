import { Skeleton, Spacer, View } from 'sierra-ui/primitives'

export const Loading: React.FC = () => {
  return (
    <View marginTop='24' marginLeft='48' direction='column'>
      <Skeleton $radius={8} $width={200} $height={24} />
      <View gap='16'>
        <Skeleton $radius={8} $width={300} $height={172} />
        <Skeleton $radius={8} $width={300} $height={172} />
        <Skeleton $radius={8} $width={300} $height={172} />
      </View>
      <Spacer size='32' />
      <View grow marginRight='48' alignItems='flex-start'>
        <View direction='column' grow gap='16'>
          <Skeleton $radius={8} $width={150} $height={24} />
          <Skeleton $radius={8} $height={38} />
          <Spacer size='8' />
          <Skeleton $radius={8} $height={500} />
        </View>
        <Skeleton $radius={8} $width={336} $height={400} />
      </View>
    </View>
  )
}
