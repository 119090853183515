import { UseQueryResult } from '@tanstack/react-query'
import { getInsightsQueryOptions } from 'sierra-client/features/insights/api-hooks/query-options'
import { prefetchCachedQuery, typedInvalidateQuery, useCachedQuery } from 'sierra-client/state/api'
import { InsightsDashboardGetRequest, InsightsDashboardGetResponse } from 'sierra-domain/api/insights'
import { XAnalyticsDashboardsGet } from 'sierra-domain/routes'

const staleTime = 10 * 1000 // 10 seconds

export const prefetchGetDashboard = (request: InsightsDashboardGetRequest): Promise<void> => {
  return prefetchCachedQuery(XAnalyticsDashboardsGet, request, {
    ...getInsightsQueryOptions<InsightsDashboardGetResponse>(),
    staleTime,
  })
}

export const useGetDashboard = (
  request: InsightsDashboardGetRequest
): UseQueryResult<InsightsDashboardGetResponse> => {
  const dashboardData = useCachedQuery(XAnalyticsDashboardsGet, request, {
    ...getInsightsQueryOptions<InsightsDashboardGetResponse>(),
    staleTime,
  })

  return dashboardData
}

export const invalidateGetDashboard = async (request: InsightsDashboardGetRequest): Promise<void> => {
  await typedInvalidateQuery(XAnalyticsDashboardsGet, request)
}
