import {
  LiveSessionContext,
  useLiveSessionContext,
} from 'sierra-client/components/liveV2/contexts/live-session-data'

type ExcludeSanaLive<T> = T extends { type: 'sana' } ? never : T

type NowSessionContext = LiveSessionContext & {
  data: { videoCallSetting: ExcludeSanaLive<LiveSessionContext['data']['videoCallSetting']> }
}

export const useNowSessionContext = (): NowSessionContext => {
  const liveSession = useLiveSessionContext()
  if (liveSession.data.videoCallSetting.type === 'sana') {
    throw Error('Encountered sana video call setting in sana now pre-lobby')
  }

  return liveSession as NowSessionContext
}
