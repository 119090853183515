import { useAtomValue } from 'jotai'
import { config } from 'sierra-client/config/global-config'
import { sidebarVersionAtom } from 'sierra-client/features/collapsable-sidebar/atoms'
import { usePathname } from 'sierra-client/hooks/router/use-pathname'
import { useIsMobile } from 'sierra-client/state/browser/selectors'

const useIsLiveSessionPath = (): boolean => {
  const pathname = usePathname()
  const isLiveSession = pathname.startsWith('/l/') || pathname.startsWith('/room/')

  return isLiveSession
}

export const useMobileTopbarShown = (): boolean => {
  const isLiveSession = useIsLiveSessionPath()
  const isMobile = useIsMobile()

  return isMobile && !isLiveSession
}

export const useShouldHideOldSidebarIcon = (): boolean => {
  const sidebarVersion = useAtomValue(sidebarVersionAtom)
  const isSanaNow = sidebarVersion === 'sana-now-sidebar'

  const mobileTopbarShown = useMobileTopbarShown()
  const { isScorm } = config.scorm

  if (isScorm) {
    return true
  }

  if (mobileTopbarShown) {
    return true
  }

  if (isSanaNow) {
    return true
  }

  return true
}
