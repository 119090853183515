import { createAction, createAsyncThunk } from '@reduxjs/toolkit'
import { logger } from 'sierra-client/logger/logger'
import { selectNotifications } from 'sierra-client/state/notifications/selectors'
import { NotificationPushType, NotificationState } from 'sierra-client/state/notifications/types'
import { RootState } from 'sierra-client/state/types'
import { UserErrorCode } from 'sierra-domain/error'
import { v4 } from 'uuid'

class ReduxThunkError extends Error {
  reduxAction: string
  status: number | undefined
  constructor({
    name,
    message,
    stack,
    reduxAction,
    status,
  }: {
    name?: string
    message?: string
    stack?: string
    status?: number
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    reduxAction: any
  }) {
    super(message ?? 'Exception in a Redux Thunk')

    if (name !== undefined) {
      this.name = name
    }
    if (stack !== undefined) {
      this.stack = stack
    }

    if (status !== undefined) {
      this.status = status
    }

    this.reduxAction = JSON.stringify(reduxAction)
  }
}

export const rejectedActionSeverity = (action: any): 'warning' | 'error' => {
  const error = (action.error ?? {}) as { code?: string; name?: string; class?: string }
  if (
    (error.name === 'RequestError' || error.class === 'RequestError') &&
    typeof error.code === 'string' &&
    Number.parseInt(error.code) < 500
  ) {
    return 'warning'
  }
  return 'error'
}

export const reportRejectedAction = createAsyncThunk(
  'notifications/report-rejected-redux-error',
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  (action: any) => {
    const error = action?.error ?? {}
    const exception = new ReduxThunkError({
      ...error,
      reduxAction: action,
    })

    const serverity = rejectedActionSeverity(action)
    if (serverity === 'warning') {
      logger.captureWarning(exception, { action: action.type })
    } else {
      logger.captureError(exception, {
        action: action.type,
      })
    }
  }
)

export const reportUserError = createAction<{ code: UserErrorCode }>('notifications/report-user-error')

export const removeNotification = createAction<{ id: string }>('notifications/remove-notification')

export const removeNotifications = createAction<{ ids: string[] }>('notifications/remove-notifications')

export function toNotificationState(notification: NotificationPushType): NotificationState {
  return {
    id: v4(),
    expires: Date.now() + 3000,
    ...notification,
  }
}

export const addNotification = createAction<NotificationState>('notifications/add-notification')

export const addNonExpiringNotification = createAction<NotificationState>(
  'notifications/add-non-expiring-notification'
)

export const pruneNotifications = createAsyncThunk<void, void, { state: RootState }>(
  'notifications/prune-notifications',
  (_, { getState, dispatch }) => {
    const state = getState()

    const notifications = selectNotifications(state)
    const now = Date.now()

    const ids = notifications.filter(v => v.expires !== undefined && v.expires <= now).map(v => v.id)

    if (ids.length > 0) void dispatch(removeNotifications({ ids }))
  }
)
