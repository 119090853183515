import * as Tooltip from '@radix-ui/react-tooltip'
import { useMemo } from 'react'
import { getNamespacedImage } from 'sierra-client/api/content'
import { useSelector } from 'sierra-client/state/hooks'
import { selectUser } from 'sierra-client/state/user/user-selector'
import { FCC } from 'sierra-client/types'
import { BrowserPreviewSvg } from 'sierra-client/views/manage/settings/components/logo-upload/svg/browser-preview-svg'
import { SidebarPreviewSvg } from 'sierra-client/views/manage/settings/components/logo-upload/svg/sidebar-preview-svg'
import { color } from 'sierra-ui/color'
import { IconButton, Text, View } from 'sierra-ui/primitives'
import { token } from 'sierra-ui/theming'
import styled from 'styled-components'

type SidebarPreviewProps = {
  logo: string | null
}

type LogoPosition = { $top: number; $left: number }

const Position = styled.div<LogoPosition>`
  position: absolute;
  top: ${p => p.$top}px;
  left: ${p => p.$left}px;
  z-index: 1;
`

const Logo = styled.img`
  width: 12px;
  height: 12px;
  border-radius: 3px;
`

const EmptyLogo = styled.span`
  width: 12px;
  height: 12px;

  border-radius: 3px;
  border: 1px dashed ${token('form/border/2')};
`

const LogoAndOrgName: React.FC<SidebarPreviewProps & LogoPosition> = ({ logo, ...position }) => {
  const orgName = useSelector(selectUser)?.organizationName

  const src = useMemo(
    () =>
      logo === null
        ? null
        : getNamespacedImage({ type: 'organization-settings' }, logo, 'image', {
            width: 12,
          }),
    [logo]
  )

  return (
    <Position {...position}>
      <View direction='row' gap='8'>
        {src === null ? <EmptyLogo /> : <Logo src={src} />}
        <Text bold size='micro' color='black'>
          {orgName}
        </Text>
      </View>
    </Position>
  )
}

const RelativeImage = styled.div`
  position: relative;
  width: 148px;
  height: 103px;
`

export const SidebarPreview: React.FC<SidebarPreviewProps> = props => (
  <RelativeImage>
    <SidebarPreviewSvg />
    <LogoAndOrgName $top={22} $left={24} {...props} />
  </RelativeImage>
)

export const BrowserPreview: React.FC<SidebarPreviewProps> = props => (
  <RelativeImage>
    <BrowserPreviewSvg />
    <LogoAndOrgName $top={16} $left={18} {...props} />
  </RelativeImage>
)

const InfoIconButton = styled(IconButton).attrs({
  iconId: 'information',
  color: 'grey25',
  size: 'small',
  variant: 'transparent',
})``

const Content = styled.div`
  padding: 7px;
  border-radius: 13px;
  background-color: ${color('black').opacity(0.08)};
  overflow: hidden;
`

const Image = styled.div`
  border-radius: 10px;
  padding: 0;
  overflow: hidden;

  display: flex;
  flex-direction: row;
  justify-content: stretch;
  align-items: flex-start;
`

export const LogoUploadTooltip: FCC = ({ children }) => (
  <Tooltip.Provider delayDuration={0}>
    <Tooltip.Root>
      <Tooltip.Trigger asChild>
        <InfoIconButton />
      </Tooltip.Trigger>
      <Tooltip.Portal>
        <Tooltip.Content sideOffset={5} asChild>
          <Content>
            <Image>{children}</Image>
          </Content>
        </Tooltip.Content>
      </Tooltip.Portal>
    </Tooltip.Root>
  </Tooltip.Provider>
)
