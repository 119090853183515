import { AnimatePresence, motion } from 'framer-motion'
import { useAtom, useAtomValue, useSetAtom } from 'jotai'
import { forwardRef, useCallback, useMemo, useState } from 'react'
import { OrganizationLogo } from 'sierra-client/components/organization-logo'
import {
  companyLogoVariants,
  headerItemVariants,
} from 'sierra-client/features/collapsable-sidebar/animation-config'
import {
  collapsableSidebarStateAtom,
  sidebarHasNestedMenuOpenAtom,
} from 'sierra-client/features/collapsable-sidebar/atoms'
import { GlobalSidebarIdAtom } from 'sierra-client/features/global-sidebar/atoms'
import { useIsSidebarNavigationAllowed } from 'sierra-client/features/global-sidebar/hooks/use-is-sidebar-navigation-allowed'
import { useOrganizationClusterMenuItems } from 'sierra-client/features/multi-tenancy'
import { useStableFunction } from 'sierra-client/hooks/use-stable-function'
import { useTranslation } from 'sierra-client/hooks/use-translation'
import { useUserMenu } from 'sierra-client/layout/header/components/user-menu'
import {
  isSettingsMenuItem,
  useSettingsMenuitems,
} from 'sierra-client/layout/header/workspace-header/settings-menu'
import { useSelector } from 'sierra-client/state/hooks'
import { selectOrganization } from 'sierra-client/state/organization/selectors'
import { HoveredReveal } from 'sierra-client/views/flexible-content/editor/content-sidebar/hovered-reveal'
import { useOrganizationSettings } from 'sierra-client/views/manage/settings/use-organization-settings'
import { Icon, MenuItem, TruncatedTextWithTooltip } from 'sierra-ui/components'
import { IconButton, View } from 'sierra-ui/primitives'
import { MenuDropdownPrimitive } from 'sierra-ui/primitives/menu-dropdown'
import { token } from 'sierra-ui/theming'
import styled, { css } from 'styled-components'

const ExpandIconButton = styled(Icon)`
  color: ${token('foreground/muted')};
  transition: color 100ms cubic-bezier(0.25, 0.1, 0.25, 1);
`

const LogoAndName = styled(View).attrs({
  direction: 'row',
  justifyContent: 'space-between',
  alignItems: 'center',
  gap: 'none',
  animated: true,
})<{ $open: boolean }>`
  transition: background-color 0.15s cubic-bezier(0.25, 0.1, 0.25, 1);
  border-radius: 10px;
  padding-right: 8px;
  cursor: pointer;

  ${p =>
    p.$open &&
    css`
      background-color: ${token('surface/strong')};
    `}
  &:hover {
    background-color: ${token('surface/strong')};

    ${ExpandIconButton} {
      color: ${token('foreground/primary')};
    }
  }
`

const LogoWrapper = styled(View).attrs({
  direction: 'row',
  justifyContent: 'center',
  alignItems: 'center',
})`
  width: 36px;
  height: 36px;
  flex-shrink: 0;
`

const CompanyNameText = styled(TruncatedTextWithTooltip).attrs({
  bold: true,
  size: 'small',
})``

const AnimatedCompanyName = styled(motion.div)`
  min-width: 0;
  flex: 1 0 0;
`

const CompanyName: React.FC<{ children: string }> = ({ children }) => (
  <AnimatedCompanyName>
    <CompanyNameText>{children}</CompanyNameText>
  </AnimatedCompanyName>
)

const Wrapper = styled(View).attrs({
  animated: true,
  grow: true,
  justifyContent: 'space-between',
  variants: headerItemVariants,
})`
  background-color: ${token('surface/default')};
`

const AnimatedToggle = styled(IconButton).attrs({
  variant: 'transparent',
  size: 'default',
  iconId: 'open-sidebar',
})<{ flipped: boolean }>`
  & svg {
    transition: transform 0.2s cubic-bezier(0.25, 0.1, 0.25, 1);
    ${p =>
      p.flipped &&
      css`
        transform: rotate(180deg);
      `}
  }
`

const AnimateOutOnExit = styled(View).attrs({
  animated: true,
  justifyContent: 'center',
  alignItems: 'center',
  gap: 'none',
  ...companyLogoVariants,
})``

const LogoMenuTrigger = forwardRef<HTMLDivElement, { open: boolean }>(({ open, ...props }, ref) => {
  const { settings } = useOrganizationSettings()
  const org = useSelector(selectOrganization)
  const sidebarStatus = useAtomValue(collapsableSidebarStateAtom)
  const allowNavigation = useIsSidebarNavigationAllowed()

  const orgName = settings.brand.platformName ?? org?.name

  return (
    <LogoAndName ref={ref} $open={open} {...props}>
      <View gap='none'>
        <LogoWrapper>
          <OrganizationLogo orgName={orgName} brandSettings={settings.brand} />
        </LogoWrapper>
        <AnimatePresence>
          {(sidebarStatus === 'sticky' || sidebarStatus === 'hovered') && (
            <AnimateOutOnExit>
              {orgName !== undefined && <CompanyName>{orgName}</CompanyName>}
            </AnimateOutOnExit>
          )}
        </AnimatePresence>
      </View>

      {(sidebarStatus === 'sticky' || sidebarStatus === 'hovered') && allowNavigation && (
        <AnimateOutOnExit>
          <ExpandIconButton iconId={open ? 'chevron--up--small' : 'chevron--down--small'} />
        </AnimateOutOnExit>
      )}
    </LogoAndName>
  )
})

export const Header: React.FC<{ hoverElement?: React.RefObject<HTMLElement> }> = ({ hoverElement }) => {
  const { userMenuElement, setUserDialogOpen } = useUserMenu()
  const { menuItems, onMenuSelect } = useSettingsMenuitems(setUserDialogOpen)
  const organizationClusterMenuItems = useOrganizationClusterMenuItems()
  const [isSettingsOpen, setIsSettingsOpen] = useState(false)

  const setSidebarHasNestedMenuOpen = useSetAtom(sidebarHasNestedMenuOpenAtom)
  const [sidebarStatus, setSidebarStatus] = useAtom(collapsableSidebarStateAtom)

  const onOpenChange = useStableFunction((newIsOpen: boolean) => {
    setSidebarHasNestedMenuOpen(newIsOpen)
    setIsSettingsOpen(newIsOpen)
  })

  const { t } = useTranslation()
  const allowNavigation = useIsSidebarNavigationAllowed()
  const globalSidebarId = useAtomValue(GlobalSidebarIdAtom)

  const onToggleClick = (): void => {
    setSidebarStatus(sidebarStatus === 'sticky' ? 'hovered' : 'sticky')
  }

  const onMenuSelectGeneric = useCallback(
    (item: MenuItem) => {
      if (isSettingsMenuItem(item)) {
        onMenuSelect(item)
      }
    },
    [onMenuSelect]
  )

  const toggle = (
    <AnimatedToggle
      aria-label={t('sidebar.main-navigation-toggle')}
      aria-pressed={sidebarStatus === 'sticky'}
      aria-controls={globalSidebarId}
      flipped={sidebarStatus === 'sticky'}
      onClick={onToggleClick}
    />
  )

  const menuItemsWithOrgCluster = useMemo(() => {
    return [...organizationClusterMenuItems, ...menuItems]
  }, [menuItems, organizationClusterMenuItems])

  return (
    <>
      <Wrapper gap='none'>
        <MenuDropdownPrimitive
          isOpen={isSettingsOpen}
          onOpenChange={onOpenChange}
          disabled={!allowNavigation}
          renderTrigger={open => <LogoMenuTrigger open={open} />}
          menuItems={menuItemsWithOrgCluster}
          onSelect={onMenuSelectGeneric}
          closeOnSelect
          container={hoverElement?.current}
        />
        {hoverElement === undefined ? null : (
          <HoveredReveal forcedVisible={isSettingsOpen} anchor={hoverElement}>
            {toggle}
          </HoveredReveal>
        )}
      </Wrapper>
      {userMenuElement}
    </>
  )
}
