import { useEffect, useMemo } from 'react'
import { browserName, browserVersion } from 'react-device-detect'
import { Helmet } from 'react-helmet-async'
import { LivePageContextProvider } from 'sierra-client/components/liveV2/contexts/live-page'
import { LivePage } from 'sierra-client/views/liveV2/live-session'
import styled, { createGlobalStyle } from 'styled-components'

// Preload sounds for later use.
import chatSfx from 'sierra-client/assets/sounds/chat.mp3'
import deviceConnectedSfx from 'sierra-client/assets/sounds/device-connected.mp3'
import handRaiseSfx from 'sierra-client/assets/sounds/hand-raise.mp3'
import joinSfx from 'sierra-client/assets/sounds/join.mp3'
import leaveSfx from 'sierra-client/assets/sounds/leave.mp3'
import reactionSfx from 'sierra-client/assets/sounds/reaction.mp3'
import testSpeakerSfx from 'sierra-client/assets/sounds/smart-progression.mp3'
import { useDispatch } from 'sierra-client/state/hooks'
import { locationChanged } from 'sierra-client/state/v2/navigation-actions'
import { LiveSessionId } from 'sierra-domain/api/nano-id'

const audioPaths = [chatSfx, deviceConnectedSfx, handRaiseSfx, joinSfx, leaveSfx, reactionSfx, testSpeakerSfx]

const GlobalStyles = createGlobalStyle`
  #__next {
    /* This is mainly so we don't overflow on mobile when the sidebar is open. */
    position: relative;
    overflow-x: hidden;
    overflow-y: hidden;
    height: auto;
  }
`

const PageContainer = styled.div`
  flex: 1;
  display: flex;
  flex-direction: column;
  width: 100%;
  height: 100%;
`

export function LiveRoute({ liveSessionId }: { liveSessionId: LiveSessionId }): JSX.Element {
  const pageQueryContext = useMemo(() => ({ liveSessionId }), [liveSessionId])
  const dispatch = useDispatch()

  useEffect(() => {
    void dispatch(locationChanged({ currentLiveSessionId: liveSessionId }))

    return () => {
      void dispatch(locationChanged({ currentLiveSessionId: undefined }))
    }
  }, [dispatch, liveSessionId])

  if (browserName === 'Safari' && parseInt(browserVersion) < 14) {
    const msg =
      'Safari versions older than 14 are not supported. We recommend the latest version of Google Chrome.'
    window.alert(msg)
    return <p>{msg}</p>
  }

  return (
    <>
      <Helmet>
        {audioPaths.map(path => (
          <link key={path} as='audio' rel='preload' type='audio/mpeg' href={path} />
        ))}
      </Helmet>

      <PageContainer>
        <GlobalStyles />
        <LivePageContextProvider value={pageQueryContext}>
          <LivePage liveSessionId={liveSessionId} />
        </LivePageContextProvider>
      </PageContainer>
    </>
  )
}
