import { createFileRoute } from '@tanstack/react-router'
import { requireLoggedIn } from 'sierra-client/core/require-logged-in'
import { ScormCoursePage } from 'sierra-client/views/learner/course/scorm-course-page'
import { Route as IndexRoute } from './index.route'

const RouteComponent = (): JSX.Element | null => {
  const courseId = Route.useParams({ select: params => params.courseId })
  return <ScormCoursePage courseId={courseId} />
}

export const Route = createFileRoute('/program/$programId/path/$pathId/course/$courseId/scorm')({
  component: requireLoggedIn(RouteComponent, { allowScorm: true }) as React.FC,
  params: IndexRoute.options.params,
})
