import { CopyPasteWithAssetsOptions } from 'sierra-client/views/v3-author/configuration/copy-paste-with-assets-options'
import {
  CopyPasteClipboardKey,
  CopyPasteContext,
} from 'sierra-client/views/v3-author/paste/copy-paste-context'
import { rangeEncapsulatesNodeEntry } from 'sierra-client/views/v3-author/queries'
import { CreateContentId } from 'sierra-domain/api/nano-id'
import { ScopedFileId } from 'sierra-domain/collaboration/types'
import { BaseRange, Descendant, Editor, Element, Node, NodeEntry, Path, Range, Text } from 'slate'

function isDescendant(node: Node): node is Descendant {
  return !Editor.isEditor(node)
}

export function getNodesInRange(editor: Editor, range: BaseRange): Descendant[] {
  const [start, end] = Range.edges(range)
  const [startOffset, endOffset] = [start.offset, end.offset]

  const nodes = Array.from(
    Editor.nodes(editor, {
      at: range,
      mode: 'highest',
      match: (node, path) =>
        isDescendant(node) && (Text.isText(node) || rangeEncapsulatesNodeEntry(range, [node, path])),
    })
  )

  return nodes
    .flatMap(([node, path], index, list): NodeEntry<Descendant>[] => {
      const { length } = list

      if (Editor.isEditor(node)) return []
      if (Element.isElement(node)) return [[node, path]]

      const isFirst = index === 0
      const isLast = length - 1 === index
      let text
      if (isFirst && isLast) {
        text = node.text.slice(startOffset, endOffset)
      } else if (isFirst) {
        text = node.text.slice(startOffset)
      } else if (isLast) {
        text = node.text.slice(0, endOffset)
      }

      return [[{ ...node, text: text ?? node.text }, path]]
    })
    .reduce((acc, [node, path], index, initialList) => {
      const previousEntry = initialList[index - 1]

      if (previousEntry) {
        const [previousNode, theOtherPath] = previousEntry
        const previousNodeIsText = Text.isText(previousNode)
        const currentNodeIsText = Text.isText(node)
        const notSiblings = !Path.isSibling(path, theOtherPath)

        if (previousNodeIsText && currentNodeIsText && notSiblings) {
          return [...acc, { text: '\n' }, node]
        } else return [...acc, node]
      } else return [...acc, node]
    }, [] as Descendant[])
}

type CopyPasteMetadata = {
  signedUrl: string
  createContentId: CreateContentId
  tokenGeneratedAt: Date
}

export const withCopy = (
  copyPasteWithAssetsOptions: CopyPasteWithAssetsOptions
): ((editor: Editor) => Editor) => {
  return editor => {
    const { setSelection, setFragmentData } = editor

    // When copy-pasting content with assets, we need to generate a token to download the assets
    // As the copy action needs to be synchronous, we need to generate the token in the background
    // This token is generated when the use selects content. The token is valid for 15 minutes, and
    // we refresh it every minute.
    //
    // This token is later provided in getFragment below, and passed in hidden-copy-paste-container.
    // When the hidden-copy-paste-container is extracted and removed from the pasted payload is pasted.
    // And the signedAssetsUrl is used to download the assets.

    let copyPasteToken: CopyPasteMetadata | undefined = undefined
    let isGeneratingToken = false

    editor.setSelection = props => {
      const { anchor, focus } = props

      // Only generate a token after the user has interacted with the document
      if (copyPasteWithAssetsOptions.type === 'disabled') {
        return setSelection(props)
      }
      if (anchor === undefined || focus === undefined) {
        return setSelection(props)
      }
      if (Range.isCollapsed({ anchor, focus }) && anchor.path.every(i => i === 0) && anchor.offset === 0) {
        return setSelection(props)
      }

      // Refresh the token every minute, when the user interacts with the editor
      if (
        (copyPasteToken === undefined ||
          new Date().getTime() - copyPasteToken.tokenGeneratedAt.getTime() > 1000 * 60) &&
        !isGeneratingToken
      ) {
        isGeneratingToken = true

        const nanoFileId = ScopedFileId.extractId(copyPasteWithAssetsOptions.fileId)

        void copyPasteWithAssetsOptions
          .generateCopyPasteToken(copyPasteWithAssetsOptions.createContentId, nanoFileId)
          .then(res => {
            copyPasteToken = {
              signedUrl: res,
              createContentId: copyPasteWithAssetsOptions.createContentId,
              tokenGeneratedAt: new Date(),
            }
          })
          .finally(() => {
            isGeneratingToken = false
          })
      }

      return setSelection(props)
    }

    editor.setFragmentData = data => {
      if (copyPasteToken !== undefined) {
        const copyPasteContext: CopyPasteContext = {
          createContentId: copyPasteToken.createContentId,
          signedAssetsUrl: copyPasteToken.signedUrl,
        }
        data.setData(CopyPasteClipboardKey, JSON.stringify(copyPasteContext))
      }

      setFragmentData(data)
    }

    editor.getFragment = (): Descendant[] => {
      const range = editor.selection

      if (range === null) return []

      return getNodesInRange(editor, range)
    }

    return editor
  }
}
