import { FlexibleContentState } from 'sierra-client/state/flexible-content/types'
import { CreateContentId } from 'sierra-domain/api/nano-id'
import { FolderId } from 'sierra-domain/flexible-content/identifiers'
import { Folder } from 'sierra-domain/flexible-content/types'
import { assertIsNonNullable } from 'sierra-domain/utils'

export const getFolder = ({
  state,
  createContentId,
  folderId,
}: {
  state: FlexibleContentState
  createContentId: CreateContentId
  folderId: FolderId
}): Folder => {
  const folder = state.flexibleContent[createContentId]?.nodeMap[folderId]
  assertIsNonNullable(folder)
  if (folder.type !== 'folder') {
    throw new Error(`Expected node type to be 'folder' but received '${folder.type}'`)
  }

  return folder
}
