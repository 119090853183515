import React, { FC, ReactNode, useContext } from 'react'
import { useDeepEqualityMemo } from 'sierra-client/hooks/use-deep-equality-memo'
import { TableDimensions } from 'sierra-domain/v3-author'
import { Path } from 'slate'

type TableContextValue = {
  tablePath?: Path
  withHeaders?: boolean
  insideHeader: boolean
  dimensions?: TableDimensions
  hasHeader?: boolean
}

const TableContext = React.createContext<TableContextValue>({
  insideHeader: false,
})

export const TableContextProvider: FC<{
  children: ReactNode
  value: Partial<TableContextValue>
}> = ({ children, value: overrides }) => {
  const currentValues = useContext(TableContext)
  const value = useDeepEqualityMemo({ ...currentValues, ...overrides })

  return <TableContext.Provider value={value}>{children}</TableContext.Provider>
}

export const useTableContext = (): TableContextValue => {
  return useContext(TableContext)
}
