import { FC, useEffect, useMemo, useState } from 'react'
import { useDebouncedAndLiveState } from 'sierra-client/hooks/use-debounced-state'
import { useTranslation } from 'sierra-client/hooks/use-translation'
import { loadPlacesLibrary } from 'sierra-client/views/manage/event-groups/components/google-apis'
import { getEventLocationString } from 'sierra-client/views/manage/event-groups/event-utils'
import { EventLocation } from 'sierra-client/views/manage/event-groups/types'
import { Autocomplete, DefaultStyledAutocompleteOption, Icon } from 'sierra-ui/components'
import { IconButton, Text, View } from 'sierra-ui/primitives'
import { token } from 'sierra-ui/theming'
import { useOnChanged } from 'sierra-ui/utils'
import styled from 'styled-components'

const ListContainer = styled.div`
  max-height: 400px;
  overflow: auto;
  padding: 0.5rem;
  background: ${token('surface/default')};
`

const SelectedItemContainer = styled(View)`
  justify-content: space-between;
  overflow: hidden;
  border: 1px solid ${token('border/strong')};
  background: ${token('surface/soft')};
  border-radius: 8px;
`

const useGoogleAutocompleteService = (): google.maps.places.AutocompleteService | undefined => {
  const [autocompleteService, setAutocompleteService] = useState<
    google.maps.places.AutocompleteService | undefined
  >(undefined)

  useEffect(() => {
    const load = async (): Promise<void> => {
      const placesLibrary = await loadPlacesLibrary()

      setAutocompleteService(new placesLibrary.AutocompleteService())
    }

    void load()
  }, [])

  return autocompleteService
}

type EventLocationWithId = EventLocation & { id: string }
type AutocompleteResult = Extract<EventLocationWithId, { type: 'google-place' }>

export const EventLocationInput: FC<{
  updateLocation: (newLocation: EventLocation | undefined) => void
  initialLocation?: EventLocation
}> = ({ updateLocation, initialLocation }) => {
  const { t } = useTranslation()
  const autocompleteService = useGoogleAutocompleteService()
  const [debouncedInputLocation, inputLocation, setInputLocation] = useDebouncedAndLiveState(
    getEventLocationString(initialLocation) ?? ''
  )

  const [autocompleteResults, setAutocompleteResults] = useState<AutocompleteResult[]>()
  const [selectedGooglePlaceResult, setSelectedGooglePlaceResult] = useState<AutocompleteResult | undefined>(
    () => {
      if (initialLocation?.type === 'google-place') {
        return { ...initialLocation, id: initialLocation.placeId }
      }
      return undefined
    }
  )

  const currentLocation = useMemo<EventLocation | undefined>(() => {
    if (selectedGooglePlaceResult !== undefined) {
      const { id, ...rest } = selectedGooglePlaceResult
      return rest
    }

    if (debouncedInputLocation !== '') {
      return { type: 'simple', address: debouncedInputLocation }
    }

    return undefined
  }, [selectedGooglePlaceResult, debouncedInputLocation])

  useOnChanged((_prevLocation, currLocation) => {
    updateLocation(currLocation)
  }, currentLocation)

  useEffect(() => {
    const load = async (): Promise<void> => {
      if (autocompleteService === undefined) return
      if (debouncedInputLocation === '') {
        setAutocompleteResults([])
        return
      }

      const res = await autocompleteService.getPlacePredictions({
        input: debouncedInputLocation,
      })

      setAutocompleteResults(
        res.predictions.map(x => ({
          id: x.place_id,
          type: 'google-place',
          placeId: x.place_id,
          mainText: x.structured_formatting.main_text,
          secondaryText: x.structured_formatting.secondary_text,
        }))
      )
    }

    void load()
  }, [autocompleteService, debouncedInputLocation])

  const matchingItems = useMemo<EventLocationWithId[]>(() => {
    const searchResults = autocompleteResults ?? []
    if (inputLocation.trim() !== '') {
      return [{ id: 'current-input', type: 'simple', address: inputLocation }, ...searchResults]
    }

    return searchResults
  }, [autocompleteResults, inputLocation])

  const onItemSelected = (item: EventLocationWithId): void => {
    if (item.type === 'simple') {
      setSelectedGooglePlaceResult(undefined)
      setInputLocation(item.address)
    } else {
      setSelectedGooglePlaceResult(item)
    }
  }

  return selectedGooglePlaceResult !== undefined ? (
    <SelectedItemContainer direction='column'>
      <View grow justifyContent='space-between' padding='16'>
        <View direction='column'>
          <Text bold>{selectedGooglePlaceResult.mainText}</Text>
          <Text>{selectedGooglePlaceResult.secondaryText}</Text>
        </View>
        <IconButton
          variant='transparent'
          iconId='close'
          onClick={() => {
            setInputLocation(getEventLocationString(selectedGooglePlaceResult) ?? '')
            setSelectedGooglePlaceResult(undefined)
          }}
        />
      </View>
    </SelectedItemContainer>
  ) : (
    <Autocomplete
      disabled={false}
      placeholder={t('event-groups.add-location')}
      query={inputLocation}
      onQueryChange={setInputLocation}
      matchingItems={matchingItems}
      onSelect={onItemSelected}
      renderMatchingItemList={({ getItemProps }) => (
        <ListContainer>
          {matchingItems.map((item, index) => (
            <DefaultStyledAutocompleteOption key={item.id} {...getItemProps(item, index)}>
              {item.type === 'simple' && (
                <>
                  <Icon color={'foreground/muted'} iconId='edit' />
                  <View marginLeft='4' gap='8' direction='row'>
                    <Text bold>{item.address}</Text>
                  </View>
                </>
              )}
              {item.type === 'google-place' && (
                <>
                  <Icon color={'foreground/muted'} iconId='location' />
                  <View marginLeft='4' gap='8' direction='row'>
                    <Text bold>{item.mainText}</Text>
                    <Text>{item.secondaryText}</Text>
                  </View>
                </>
              )}
            </DefaultStyledAutocompleteOption>
          ))}
        </ListContainer>
      )}
    />
  )
}
