import React, { useCallback, useMemo } from 'react'
import { useNotif } from 'sierra-client/components/common/notifications'
import {
  MiniMenuAssistantButton,
  MiniSeparator,
} from 'sierra-client/editor/blocks/paragraph/generation/paragraph-generation'
import { derivePlaceholderKey } from 'sierra-client/editor/blocks/paragraph/mini-menu/derive-placeholder'
import { MiniIconColorCss } from 'sierra-client/editor/blocks/paragraph/mini-menu/mini-icon-color-css'
import { useReadLatestValidSelection, useSetSlashMenuState } from 'sierra-client/editor/editor-jotai-context'
import { useTranslation } from 'sierra-client/hooks/use-translation'
import { Trans } from 'sierra-client/hooks/use-translation/trans'
import { TranslationKey } from 'sierra-client/hooks/use-translation/types'
import { useDispatch } from 'sierra-client/state/hooks'
import {
  useCreatePageContextSafe,
  useSlashMenuCreatePageContext,
} from 'sierra-client/views/flexible-content/create-page-context'
import { scrollToSelectedNode, selectNodeWithIdSynchronous } from 'sierra-client/views/v3-author/command'
import { Prompt, PromptText, SlashHighlight } from 'sierra-client/views/v3-author/components'
import {
  useEditorContextValue,
  useEditorReadOnly,
} from 'sierra-client/views/v3-author/editor-context/editor-context'
import { useEditorUploadFile } from 'sierra-client/views/v3-author/file-attachment/use-editor-upload-file'
import { getCurrentlySelectedNode } from 'sierra-client/views/v3-author/queries'
import {
  isSlashMenuEntryEnabled,
  slashMenuEntries,
} from 'sierra-client/views/v3-author/slash-menu/slash-menu-entries'
import { assertNever, isDefined } from 'sierra-domain/utils'
import { SlashMenuEntryId } from 'sierra-domain/v3-author/slash-menu'
import { IconId, Tooltip } from 'sierra-ui/components'
import { IconButton } from 'sierra-ui/primitives'
import { useSlateStatic } from 'slate-react'
import styled from 'styled-components'

const MiniMenu = styled.span`
  display: flex;
  align-items: center;
  pointer-events: auto;
  gap: 1rem;
`

const MiniIcon = styled(IconButton).attrs({ variant: 'ghost', size: 'small' })`
  cursor: pointer;
  background: transparent;
  padding: 0;

  ${MiniIconColorCss}
`

type SlashMenuEntryIcon = {
  title: TranslationKey
  iconId: IconId
  onClick: () => void
}

const shortcutIcons = ['heading2', 'bullet-list', 'image', 'block-quote'] as const

type ShortcutIcon = (typeof shortcutIcons)[number]

function isShortcutIcon(str: string): str is ShortcutIcon {
  return shortcutIcons.includes(str as ShortcutIcon)
}

const MiniMenuEntryComponent: React.FC<{ shortcutIcon: ShortcutIcon; currentId: string }> = ({
  shortcutIcon,
  currentId,
}) => {
  const createPageContext = useSlashMenuCreatePageContext()
  const editor = useSlateStatic()
  const { t, dynamicT } = useTranslation()
  const readLatestSelection = useReadLatestValidSelection()
  const notification = useNotif()
  const showUploadFileModal = useEditorUploadFile().showUploadModal
  const dispatch = useDispatch()

  const createOnClickListener = useCallback(
    ({ entryId }: { entryId: SlashMenuEntryId }) =>
      () => {
        const latestSelection = readLatestSelection()

        editor.pushActionsLogEntry({ type: 'select-mini-menu-entry', id: entryId })
        const entry = slashMenuEntries[entryId]
        entry.edit({
          createPageContext,
          editor,
          lastSelection: latestSelection,
          dispatch,
          currentId,
          forceInsert: true,
          notification,
          showUploadFileModal,
          dynamicT,
        })
      },
    [
      createPageContext,
      currentId,
      dispatch,
      dynamicT,
      editor,
      readLatestSelection,
      notification,
      showUploadFileModal,
    ]
  )

  const entryIcon: SlashMenuEntryIcon = useMemo(() => {
    switch (shortcutIcon) {
      case 'heading2':
        return {
          title: 'font.display',
          iconId: 'headline-1',
          onClick: createOnClickListener({ entryId: 'heading2' }),
        }
      case 'bullet-list':
        return {
          title: 'author.slate.bullet-list',
          iconId: 'list--bulleted',
          onClick: createOnClickListener({ entryId: 'bullet-list' }),
        }
      case 'image':
        return {
          title: 'author.slate.image',
          iconId: 'image',
          onClick: createOnClickListener({ entryId: 'image' }),
        }
      case 'block-quote':
        return {
          title: 'author.slate.block-quote',
          iconId: 'quote',
          onClick: createOnClickListener({ entryId: 'block-quote' }),
        }
      default:
        assertNever(shortcutIcon)
    }
  }, [createOnClickListener, shortcutIcon])

  const { title, iconId, onClick } = entryIcon
  return (
    <Tooltip title={t(title)}>
      <MiniIcon iconId={iconId} onClick={onClick} />
    </Tooltip>
  )
}

export const PromptWithMiniMenu: React.FC<{ currentId: string; shouldShowMiniMenu: boolean }> = ({
  currentId,
  shouldShowMiniMenu,
}) => {
  const editor = useSlateStatic()
  const setSlashMenuState = useSetSlashMenuState()
  const editorContextValue = useEditorContextValue()
  const readOnly = useEditorReadOnly()
  const currentPath = getCurrentlySelectedNode(editor)?.[1]
  const placeholderKey = derivePlaceholderKey(editor, currentPath)

  const toggleMenu = useCallback((): void => {
    const currentNode = getCurrentlySelectedNode(editor)

    if (currentNode === undefined) return
    const [, path] = currentNode

    setSlashMenuState(shortcutState => {
      const { idsSupportedByEditor } = shortcutState
      return shortcutState.type === 'idle'
        ? {
            type: 'active',
            searchText: '',
            selectedIndex: 0,
            startPoint: { path, offset: 0 },
            top: 0,
            left: 0,
            idsSupportedByEditor,
            idsMatchingSearchText: idsSupportedByEditor.filter(id =>
              isSlashMenuEntryEnabled(editor, slashMenuEntries[id])
            ),
          }
        : { type: 'idle', idsSupportedByEditor }
    })
  }, [editor, setSlashMenuState])

  const onClickMiniMore = useCallback(() => {
    selectNodeWithIdSynchronous(editor, currentId)
    scrollToSelectedNode(editor)
    toggleMenu()
  }, [currentId, editor, toggleMenu])

  const onClickMiniMenu = useCallback((e: React.MouseEvent<HTMLSpanElement, MouseEvent>) => {
    // Don't select the block when clicking on the mini menu only
    e.stopPropagation()
  }, [])

  const isCreatePage = isDefined(useCreatePageContextSafe())
  if (readOnly) return null

  return (
    <Prompt contentEditable={false}>
      <PromptText>
        {/* This adds the "Write [question, poll, reflection] here or " ahead of the prompt */}
        {placeholderKey !== undefined ? (
          <>
            <Trans
              i18nKey={placeholderKey satisfies TranslationKey}
              components={{ slash: <SlashHighlight /> }}
            />{' '}
            <Trans
              i18nKey={'author.slate.browse-options-alt' satisfies TranslationKey}
              components={{ slash: <SlashHighlight /> }}
            />
          </>
        ) : (
          <Trans
            i18nKey={'author.slate.browse-options' satisfies TranslationKey}
            components={{ slash: <SlashHighlight /> }}
          />
        )}
      </PromptText>

      {shouldShowMiniMenu && (
        <>
          <MiniMenu onClick={onClickMiniMenu}>
            {isCreatePage && <MiniMenuAssistantButton />}
            {editorContextValue.supportedSlashMenuEntryIds.filter(isShortcutIcon).map(entryId => (
              <MiniMenuEntryComponent key={entryId} shortcutIcon={entryId} currentId={currentId} />
            ))}
            <MiniSeparator />
            <MiniIcon iconId='overflow-menu--vertical' onClick={onClickMiniMore} />
          </MiniMenu>
        </>
      )}
    </Prompt>
  )
}
