import { createFileRoute } from '@tanstack/react-router'
import { PageIdentifier } from 'sierra-client/layout/sana-page'
import { useTagsEnabled } from 'sierra-client/views/manage/permissions/use-tags-enabled'
import { ManageTagDetails } from 'sierra-client/views/manage/tags/manage-tag-details'
import { ManagePageWrapper } from 'sierra-client/views/manage/utils/manage-page-wrapper'
import { TagId } from 'sierra-domain/api/nano-id'
import { z } from 'zod'

// Note: ManagePageWrapper handles the role check.
const Page: React.FC = () => {
  const tagId = Route.useParams({ select: p => p.tagId })
  const tagsAvailable = useTagsEnabled()

  if (!tagsAvailable) {
    // todo(workflows): Redirect after initializing

    return null
  }

  return (
    <ManagePageWrapper pageIdentifier={PageIdentifier.ManageTagDetail({ tagId })}>
      <ManageTagDetails key={tagId} tagId={tagId} />
    </ManagePageWrapper>
  )
}

export const Route = createFileRoute('/manage/tags/$tagId')({
  component: Page,
  params: {
    parse: z.object({ tagId: TagId }).parse,
    stringify: p => p,
  },
})
