import { produce } from 'immer'
import { postWithUserErrorException } from 'sierra-client/state/api'
import { fetchCourseProgress } from 'sierra-client/state/card-progress/actions'
import {
  embedResponseToInteraction,
  evaluateResponse,
  extractQuestionBody,
  interactionToQuestionCardResponse,
  questionCardResponseToInteraction,
} from 'sierra-client/state/self-paced/utils'
import { store } from 'sierra-client/state/store'
import { isElementType } from 'sierra-client/views/v3-author/queries'
import { CreateContentId, NanoId12 } from 'sierra-domain/api/nano-id'
import { InteractionContext, SelfPacedQuestionCardUpsertResponseRequest } from 'sierra-domain/api/strategy-v2'
import { BlockResponse, QuestionCardResponseData } from 'sierra-domain/card/question-card'
import { Entity } from 'sierra-domain/entity'
import { FileId } from 'sierra-domain/flexible-content/identifiers'
import {
  XRealtimeStrategySelfPacedContentQuestionCardUpsertQuestionCardResponse,
  XRealtimeStrategySelfPacedContentUpsertBlockInteraction,
} from 'sierra-domain/routes'
import { assert, assertIsNonNullable } from 'sierra-domain/utils'
import { SlateRootElement } from 'sierra-domain/v3-author'

type AdaptiveExercise = {
  fileId: FileId
  element: Entity<SlateRootElement>[]
  response?: QuestionCardResponseData | BlockResponse
  evaluation?: ReturnType<typeof evaluateResponse>
}

export type AdaptiveState =
  | { status: 'loading'; index: number; contentId: CreateContentId }
  | {
      index: number
      status: 'start' | 'during' | 'successful'
      elements: AdaptiveExercise[]
      contentId: CreateContentId
    }

export function currentElement(state: AdaptiveState): AdaptiveExercise | undefined {
  if (state.status !== 'during') {
    return undefined
  }

  const { elements, index } = state
  return elements[index]
}

function unsafeCurrentElement(state: AdaptiveState): AdaptiveExercise {
  const element = currentElement(state)

  assertIsNonNullable(
    element,
    `Expected to find element at index ${state.index} in state: ${JSON.stringify(state, null, 2)}`
  )

  return element
}

export function updateResponse(
  state: AdaptiveState,
  response: QuestionCardResponseData | BlockResponse | undefined
): AdaptiveState {
  return produce(state, draft => {
    const element = unsafeCurrentElement(draft)
    element.response = response
  })
}

export function resetResponse(state: AdaptiveState): AdaptiveState {
  return produce(state, draft => {
    const question = unsafeCurrentElement(draft)
    question.response = undefined
    question.evaluation = undefined
  })
}

export function goToNextElement(state: AdaptiveState): AdaptiveState {
  return produce(state, draft => {
    if (draft.status === 'loading') return

    const question = unsafeCurrentElement(draft)
    if (question.evaluation !== undefined) draft.index++

    const isFinished = draft.index === draft.elements.length
    if (isFinished) {
      draft.status = 'successful'
    }
  })
}

export function start(state: AdaptiveState): AdaptiveState {
  return produce(state, draft => {
    if (draft.status !== 'start') return

    draft.status = 'during'
    draft.index = 0
  })
}

async function defaultPostResponse(request: SelfPacedQuestionCardUpsertResponseRequest): Promise<void> {
  await postWithUserErrorException(
    XRealtimeStrategySelfPacedContentQuestionCardUpsertQuestionCardResponse,
    request,
    store.dispatch
  )
  await store.dispatch(
    fetchCourseProgress({ courseId: NanoId12.parse(request.contentId), fileId: request.fileId })
  )
}

export const createSubmitQuestionResponse = (interactionContext: InteractionContext) =>
  async function submitQuestionResponse(
    state: AdaptiveState,
    { postResponse }: { postResponse: typeof defaultPostResponse } = { postResponse: defaultPostResponse }
  ): Promise<AdaptiveState> {
    if (state.status === 'loading') return state

    const question = unsafeCurrentElement(state)
    const { element, response } = question
    if (!response) return state

    assert(response.type !== 'block', 'Response is not a question response')
    const questionCard = element.find(isElementType('question-card'))

    assertIsNonNullable(questionCard, "Can't find question card in placement-test elements.")

    const questionCardBody = extractQuestionBody(questionCard)

    const interaction = questionCardResponseToInteraction({
      element: questionCardBody,
      questionCardResponse: response,
      forSubmit: true,
    })

    await postResponse({
      contentId: state.contentId,
      fileId: question.fileId,
      questionExerciseId: questionCardBody.id,
      interactionContext,
      interaction,
    })

    const evaluation = evaluateResponse({
      element: questionCardBody,
      response,
      forSubmit: true,
    })

    const responseFromInteraction = interactionToQuestionCardResponse({
      element: questionCardBody,
      interaction,
    })

    return produce(state, draft => {
      const element = unsafeCurrentElement(draft)
      element.response = responseFromInteraction
      element.evaluation = evaluation
    })
  }

export const createSubmitEmbedResponse = (interactionContext: InteractionContext) =>
  async function submitEmbedResponse(state: AdaptiveState): Promise<AdaptiveState> {
    if (state.status === 'loading') return state

    const embed = unsafeCurrentElement(state)
    const { element, response } = embed

    if (!response) return state

    assert(response.type === 'block', 'Response is a question response')

    const embedBlock = element.find(isElementType('embed'))
    assertIsNonNullable(embedBlock, "Can't find embed block in placement-test elements.")

    await postWithUserErrorException(
      XRealtimeStrategySelfPacedContentUpsertBlockInteraction,
      {
        contentId: state.contentId,
        fileId: embed.fileId,
        interaction: embedResponseToInteraction({ blockId: embedBlock.id, blockResponse: response }),
        interactionContext,
      },
      store.dispatch
    )

    await store.dispatch(fetchCourseProgress({ courseId: state.contentId, fileId: embed.fileId }))

    return produce(state, draft => {
      const element = unsafeCurrentElement(draft)
      element.evaluation = response.correct
    })
  }
