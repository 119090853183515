import { useCallback } from 'react'
import { AssignModalSelection } from 'sierra-client/components/common/modals/multi-assign-modal/types'
import { usePost } from 'sierra-client/hooks/use-post'
import { useDispatch } from 'sierra-client/state/hooks'
import { resetSelfPacedSlice } from 'sierra-client/state/self-paced/actions'
import { updateCourseCompletions } from 'sierra-client/views/manage/users/useContentCompletion'
import { UnassignUserRequest } from 'sierra-domain/api/manage'
import { UserId } from 'sierra-domain/api/uuid'
import { isRight } from 'sierra-domain/either'
import {
  XRealtimeAdminAssignmentsSetProgramMemberships,
  XRealtimeAdminAssignmentsSetUserGroupMemberships,
  XRealtimeAdminAssignmentsUnassignFromUser,
  XRealtimeAdminEmailInvitesResendInvite,
  XRealtimeAdminUsersDeleteUsers,
  XRealtimeAdminUsersResetCourse,
  XRealtimeUserResetState,
} from 'sierra-domain/routes'

export type UseUserDetails = {
  // updateUser: (userData: UserDetailResponse) => Promise<void>
  deleteUser: (userId: UserId) => Promise<void>
  resendInvite: (userId: UserId, forceEmail: boolean) => Promise<boolean>
  unassignFromUser: (userId: UserId, unassigns: Partial<Omit<UnassignUserRequest, 'userId'>>) => Promise<void>
  handleEnrollGroupsSave: (userId: UserId, selections: AssignModalSelection[]) => Promise<void>
  handleEnrollProgramsSave: (userId: UserId, selections: AssignModalSelection[]) => Promise<void>
  resetProgress: (userId: UserId) => Promise<void>
  completeCourses: (userId: UserId, contentIds: string[]) => Promise<void>
  resetCourse: (userId: UserId, contentType: string, contentId: string) => Promise<void>
}

export const useUserDetails = (): UseUserDetails => {
  const { postWithUserErrorException, postWithUserErrorCode } = usePost()
  const dispatch = useDispatch()

  const deleteUser = useCallback<UseUserDetails['deleteUser']>(
    async userId => {
      await postWithUserErrorException(XRealtimeAdminUsersDeleteUsers, { userIds: [userId] })
    },
    [postWithUserErrorException]
  )

  const resendInvite = useCallback<UseUserDetails['resendInvite']>(
    async (id, forceEmail) => {
      const response = await postWithUserErrorCode(XRealtimeAdminEmailInvitesResendInvite, {
        id: id,
        forceEmail: forceEmail,
      })

      return isRight(response)
    },
    [postWithUserErrorCode]
  )

  const handleEnrollGroupsSave = useCallback<UseUserDetails['handleEnrollGroupsSave']>(
    async (userId, selections) => {
      if (typeof userId === 'string') {
        await postWithUserErrorException(XRealtimeAdminAssignmentsSetUserGroupMemberships, {
          userIds: [userId],
          groupIds: selections.flatMap(item => (item.type === 'user-group' ? [item.id] : [])),
        })
      }
    },
    [postWithUserErrorException]
  )

  const handleEnrollProgramsSave = useCallback<UseUserDetails['handleEnrollProgramsSave']>(
    async (userId, selections) => {
      if (typeof userId === 'string') {
        await postWithUserErrorException(XRealtimeAdminAssignmentsSetProgramMemberships, {
          userIds: [userId],
          programIds: selections.flatMap(item => (item.type === 'program' ? [item.id] : [])),
          userIdsWithRequiredAssignments: [],
          programIdsWithRequiredAssignments: selections.flatMap(item =>
            item.type === 'program' && item.isRequired === true ? [item.id] : []
          ),
        })
      }
    },
    [postWithUserErrorException]
  )

  const resetProgress = useCallback<UseUserDetails['resetProgress']>(
    async userId => {
      await postWithUserErrorException(XRealtimeUserResetState, { userId })
    },
    [postWithUserErrorException]
  )

  const completeCourses = useCallback<UseUserDetails['completeCourses']>(async (userId, courseIds) => {
    const courseCompletions = updateCourseCompletions()
    await courseCompletions(courseIds, userId)
  }, [])

  const resetCourse = useCallback<UseUserDetails['resetCourse']>(
    async (userId, contentType, contentId) => {
      dispatch(resetSelfPacedSlice())
      await postWithUserErrorException(XRealtimeAdminUsersResetCourse, {
        userId,
        contentType,
        contentId,
      })
    },
    [dispatch, postWithUserErrorException]
  )

  const unassignFromUser = useCallback<UseUserDetails['unassignFromUser']>(
    async (userId, { courseIds, pathIds, groupIds, liveSessionIds }) => {
      await postWithUserErrorException(XRealtimeAdminAssignmentsUnassignFromUser, {
        userId,
        courseIds,
        pathIds,
        groupIds,
        liveSessionIds,
      })
    },
    [postWithUserErrorException]
  )

  return {
    deleteUser,
    resendInvite,
    unassignFromUser,
    handleEnrollGroupsSave,
    handleEnrollProgramsSave,
    resetProgress,
    completeCourses,
    resetCourse,
  }
}
