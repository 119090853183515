import { FC } from 'react'
import { useTranslation } from 'sierra-client/hooks/use-translation'
import { IconButton } from 'sierra-ui/primitives'
import styled from 'styled-components'

const CloseButtonContainer = styled.div`
  position: absolute;
  top: 20px;
  right: 20px;
`

export const CourseGenCloseButton: FC<{ onClick: () => void }> = ({ onClick }) => {
  const { t } = useTranslation()

  return (
    <CloseButtonContainer>
      <IconButton
        variant='ghost'
        iconId='close'
        size='small'
        aria-label={t('dictionary.close')}
        onClick={onClick}
      />
    </CloseButtonContainer>
  )
}
