import React from 'react'
import { SlateFC } from 'sierra-client/views/v3-author/slate'
import { color } from 'sierra-ui/color'
import { spacing } from 'sierra-ui/theming'
import { fadeIn } from 'sierra-ui/theming/keyframes'
import styled, { css } from 'styled-components'

export const Bold = styled.strong`
  font-weight: 500;
`
export const Italic = styled.em``
export const Underline = styled.u``
export const Strikethrough = styled.s``
export const Supscript = styled.sup`
  position: relative;
  bottom: 0.6em;
  font-size: 0.6em;
`
export const Subscript = styled.sub`
  position: relative;
  top: 0.2em;
  font-size: 0.6em;
`

export const SlashHighlight = styled.span`
  color: ${p => color(p.theme.home.textColor).opacity(0.5).toString()};
  border-radius: 4px;
  border: 1px solid ${p => color(p.theme.home.backgroundColor).shift(0.2).opacity(0.4).toString()};
  background-color: ${p => color(p.theme.home.backgroundColor).shift(0.2).opacity(0.25).toString()};
  padding: ${spacing['4']} 0.5rem;
`

export const EmptyComponent: SlateFC = ({ children }) => <>{children}</>

type CentredPlaceholder = { $isCentred?: boolean }

const PlaceholderInner = styled.span.attrs({ contentEditable: false })<CentredPlaceholder>`
  display: inline-block;
  min-width: min-content;
  pointer-events: none;
  position: absolute;
  margin-left: auto;
  margin-right: auto;
  user-select: none;
  text-align: inherit;
  white-space: nowrap;
  opacity: 0.3;

  ${p =>
    p.$isCentred === true &&
    css`
      transform: translateX(-50%);
    `}
`

export const Placeholder = React.forwardRef<
  HTMLSpanElement,
  { children?: React.ReactNode; className?: string } & CentredPlaceholder
>(({ children, className, $isCentred }, ref) => {
  return (
    <PlaceholderInner ref={ref} className={className} $isCentred={$isCentred}>
      <span>{children}</span>
    </PlaceholderInner>
  )
})

export const DisplayNone = styled.div.attrs({ contentEditable: false })`
  display: none;
  user-select: none;
  pointer-events: none;
`

export const Prompt = styled.span`
  left: 2px;
  padding: inherit;
  position: absolute;
  height: fit-content;
  cursor: text;
  user-select: none;
  white-space: nowrap;
  opacity: 0;
  display: inline-flex;
  width: 100%;
  flex-direction: row;
  justify-content: space-between;
  gap: 1rem;
  align-items: center;
  pointer-events: none;

  animation: ${fadeIn} 0.05s ease-in 0ms forwards;
`

export const PromptText = styled.span`
  user-select: none;
  pointer-events: none;
  cursor: text;
  margin-right: 1.5rem;
  color: ${p => color(p.theme.home.textColor).opacity(0.5).toString()};
`
