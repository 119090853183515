import { TrackState } from 'sierra-client/components/liveV2/services/video-call-service/types'
import { FollowVideoState } from 'sierra-domain/api/live-session-presence'
import { UserId } from 'sierra-domain/api/uuid'
import { BaseAwarenessState } from 'sierra-domain/collaboration/types'
import { LiveSessionData } from 'sierra-domain/live-session'
import { z } from 'zod'

export const CustomAwarenessData = z.object({
  userId: UserId.optional(),
  agoraUID: z.string().optional(),
  agoraScreenShareUID: z.string().optional(),
  timeStartedRaisingHand: z.string().optional(),
  isWritingToReflection: z.string().optional(),
  isWritingToReflectionAnonymous: z.string().optional(),
  isWritingToChatThreadId: z.string().optional(),
  timeUserWasEngaged: z.string().optional(),
  breakoutRoomId: z.string().optional(),
  isRecordingWithId: z.string().optional(),
  videoState: TrackState.optional(),
  audioState: TrackState.optional(),

  // Follow me data
  followMeEnabled: z.boolean().optional(),
  isFollowingFollowMe: z.boolean().optional(),
  scrollAtSlateNodeElementId: z.string().optional(),
  followVideoState: FollowVideoState.optional(),
})

export type CustomAwarenessData = z.infer<typeof CustomAwarenessData>
export type FollowVideoState = z.infer<typeof FollowVideoState>

export type AwarenessData = BaseAwarenessState & CustomAwarenessData

export type LiveSessionState = {
  data: LiveSessionData | undefined
  awareness: AwarenessData[]
  localAwarenessState: CustomAwarenessData
}

export type LiveSessionStateDataSynced = LiveSessionState & { data: LiveSessionData }

const ScreenShareParticipant = z.object({
  userId: UserId,
  agoraUID: z.string(),
  agoraScreenShareUID: z.string(),
})

export const LiveParticipant = z.object({
  userId: UserId.optional(),
  agoraUID: z.string(),
})

export const Participant = LiveParticipant

export type LiveScreenSharingParticipant = z.infer<typeof ScreenShareParticipant>
export type LiveParticipant = z.infer<typeof LiveParticipant> | LiveScreenSharingParticipant
export type Participant = z.infer<typeof Participant>

const AwarenessParticipant = z.object({
  type: z.literal('awareness'),
  userId: UserId,
  agoraUID: z.string(),
  isCurrentClient: z.boolean(),
  agoraScreenShareUID: z.string().optional(),
  breakoutRoomId: z.string().optional(),
})
export type AwarenessParticipant = z.infer<typeof AwarenessParticipant>
