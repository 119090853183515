/* eslint-disable react/forbid-component-props */
import { useFps } from 'sierra-client/hooks/use-fps'
import { useIsDebugMode } from 'sierra-client/hooks/use-is-debug-mode'
import { Text } from 'sierra-ui/primitives'
import styled from 'styled-components'

const FPSCount = styled(Text)`
  font-family: monospace;
  color: white;
`

const FPSCounterInner: React.FC = () => {
  const fps = useFps()
  const color = fps >= 60 ? 'white' : fps > 50 ? 'yellow' : fps > 30 ? 'red' : 'darkRed'

  return (
    <FPSCount size='technical' align='center' style={{ color }} bold>
      {fps.toString().padStart(3, ' ')}fps
    </FPSCount>
  )
}

export const FPSCounter: React.FC = () => {
  const isDebug = useIsDebugMode()
  if (!isDebug) return null
  else return <FPSCounterInner />
}
