import React, { forwardRef, useCallback, useMemo, useState } from 'react'
import { useTranslation } from 'sierra-client/hooks/use-translation'
import { Filter } from 'sierra-client/lib/filter'
import { Context } from 'sierra-client/lib/filter/components/common'
import { FilterInitializer, appendFilter } from 'sierra-client/lib/filter/components/filter-utils'
import {
  FilterGenerationState,
  GenerateFilterInput,
  generateFilterMenuItem,
} from 'sierra-client/lib/filter/components/generate-filter-input'
import { getDomainRepItems } from 'sierra-client/lib/filter/components/get-domain-rep-items'
import { MenuItemWithDomainRep } from 'sierra-client/lib/filter/components/types'
import * as UI from 'sierra-client/lib/filter/ui'
import { isDefined } from 'sierra-domain/utils'
import { Button, IconButton } from 'sierra-ui/primitives'
import { SingleSelectDropdown } from 'sierra-ui/primitives/menu-dropdown'

export const FilterIconAnchor = forwardRef<HTMLButtonElement, Record<string, never>>((props, anchorRef) => (
  <UI.FilterSection.Wrapper readOnly={false}>
    <IconButton
      {...props}
      ref={anchorRef}
      color='foreground/primary'
      variant='transparent'
      iconId='target'
      size='default'
    />
  </UI.FilterSection.Wrapper>
))

export const AddDropdownAnchor = forwardRef<HTMLButtonElement, Record<string, never>>((props, anchorRef) => {
  const { t } = useTranslation()
  return (
    <Button variant='secondary' icon='add' ref={anchorRef} {...props}>
      {t('user-filter.add-filter')}
    </Button>
  )
})

export const AddFilter: React.FC<{
  ctx: Context
  filter: Filter
  filterInit: FilterInitializer
  filterUsedDomains?: boolean
  generateFilter?: (query: string) => Promise<Filter>
  /* Needs to be an actual interactive (e.g button) element to trigger the dropdown */
  renderTrigger: () => JSX.Element
}> = React.memo(({ ctx, filter, filterInit, filterUsedDomains = true, renderTrigger, generateFilter }) => {
  const { dynamicT, t } = useTranslation()
  const [filterGenerationState, setFilterGenerationState] = useState<FilterGenerationState>({
    type: 'closed',
  })

  const items: MenuItemWithDomainRep[] = useMemo(() => {
    return [
      generateFilter !== undefined ? generateFilterMenuItem : undefined,
      ...getDomainRepItems(ctx.domainReps, dynamicT, filter, filterUsedDomains),
    ].filter(isDefined) as MenuItemWithDomainRep[]
  }, [ctx.domainReps, dynamicT, filter, filterUsedDomains, generateFilter])

  const onChange = useCallback(
    (item: MenuItemWithDomainRep) => {
      if (item.id === generateFilterMenuItem.id) {
        setTimeout(() => {
          setFilterGenerationState({ type: 'writing' })
        }, 300)
      } else if (item.value !== undefined) {
        const domainRep = item.value

        /* Closing the "Add filter" dropdown sets the focus back
         * to the trigger, so we can't create the new filter
         * before that's done.
         */
        setTimeout(() => {
          ctx.update(f => appendFilter(f, filterInit(domainRep)))
        }, 300)
      }
    },
    [ctx, filterInit]
  )

  return (
    <>
      {generateFilter !== undefined && (
        <GenerateFilterInput
          filterGenerationState={filterGenerationState}
          setFilterGenerationState={setFilterGenerationState}
          generateFilter={generateFilter}
        />
      )}
      <SingleSelectDropdown
        withSearch={items.length > 8}
        searchPlaceholder={t('menu.search.placeholder')}
        menuItems={items}
        onSelect={onChange}
        renderTrigger={renderTrigger}
      />
    </>
  )
})
