import { createFileRoute } from '@tanstack/react-router'
import { PageIdentifier } from 'sierra-client/layout/sana-page'
import { ManageUserGroupDetails } from 'sierra-client/views/manage/user-groups/manage-user-group-details'
import { ManagePageWrapper } from 'sierra-client/views/manage/utils/manage-page-wrapper'
import { GroupId } from 'sierra-domain/api/uuid'
import { z } from 'zod'

// Note: ManagePageWrapper handles the role check.
const Page: React.FC = () => {
  const groupId = Route.useParams({ select: p => p.groupId })

  return (
    <ManagePageWrapper pageIdentifier={PageIdentifier.ManageUserGroupDetail({ userGroupId: groupId })}>
      <ManageUserGroupDetails key={groupId} groupId={groupId} />
    </ManagePageWrapper>
  )
}

export const Route = createFileRoute('/manage/user-groups/$groupId')({
  component: Page,
  params: {
    parse: z.object({ groupId: GroupId }).parse,
    stringify: p => p,
  },
})
