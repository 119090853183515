import React, { useEffect, useRef } from 'react'
import { SelectCertificate } from 'sierra-client/components/certificates/select-certificate'
import { Link } from 'sierra-client/components/common/link'
import { Thumbnail } from 'sierra-client/components/common/thumbnail'
import { useTranslation } from 'sierra-client/hooks/use-translation'
import { TruncatedText } from 'sierra-ui/components'
import { Button, IconButton, Text, View } from 'sierra-ui/primitives'
import styled from 'styled-components'

const StyledTruncatedText = styled(TruncatedText)`
  font-weight: normal;
`

export type CertificateItem = {
  id: string
  title?: string
  description?: string
  previewImageUrl?: string
}

type CertificateListItemProps = {
  certificate: CertificateItem
  canEdit: boolean
  onRemove: (certificateId: string) => Promise<void>
}

const CertificateListItem: React.FC<CertificateListItemProps> = ({ certificate, canEdit, onRemove }) => {
  return (
    <View grow borderColor='grey10' radius='size-8' justifyContent='space-between' padding='8 12'>
      <Link href={`/manage/certificates/${certificate.id}`} target='_blank'>
        <View>
          <View>
            <Thumbnail width={2.5} height={2} radius={0.375} image={certificate.previewImageUrl} />
          </View>
          <View direction='column' gap='none'>
            <Text color='foreground/primary' bold>
              {certificate.title}
            </Text>
            <StyledTruncatedText color='foreground/muted' lines={1}>
              {certificate.description ?? ''}
            </StyledTruncatedText>
          </View>
        </View>
      </Link>
      {canEdit && (
        <IconButton iconId='trash-can' variant='transparent' onClick={() => onRemove(certificate.id)} />
      )}
    </View>
  )
}

type CertificateSelectorProps = {
  selectedCertificates: CertificateItem[]
  canEdit: boolean
  onAdd: (certificateId: string) => Promise<void>
  onRemove: (certificateId: string) => Promise<void>
}

export const CertificateSelector: React.FC<CertificateSelectorProps> = ({
  selectedCertificates,
  canEdit,
  onAdd,
  onRemove,
}) => {
  const [isAddingCertificate, setIsAddingCertificate] = React.useState(false)
  const { t } = useTranslation()
  const certificateIds = React.useMemo(() => {
    return selectedCertificates.map(c => c.id)
  }, [selectedCertificates])

  const handleAdd = async (certificateId: string): Promise<void> => {
    await onAdd(certificateId)
    setIsAddingCertificate(false)
  }

  const autocompleteRef = useRef<HTMLInputElement>(null)
  useEffect(() => {
    if (autocompleteRef.current !== null && isAddingCertificate) {
      autocompleteRef.current.focus()
    }
  }, [isAddingCertificate])

  return (
    <View direction='column'>
      {selectedCertificates.map(c => (
        <CertificateListItem key={c.id} certificate={c} canEdit={canEdit} onRemove={onRemove} />
      ))}
      {!canEdit ? null : isAddingCertificate ? (
        <SelectCertificate
          ref={autocompleteRef}
          onClose={() => setIsAddingCertificate(false)}
          onSelect={handleAdd}
          selectedCertificates={certificateIds}
        />
      ) : (
        <View>
          <Button variant='secondary' onClick={() => setIsAddingCertificate(true)}>
            {t('manage.certificates.add-certificate-to-course')}
          </Button>
        </View>
      )}
    </View>
  )
}
