import { useEffect, useState } from 'react'

export function useFps(): number {
  const [fps, setFps] = useState<number>(60)
  useEffect(() => {
    let isCancelled = false
    const frames1Second: number[] = []
    let fps: number = 60
    function loop(): void {
      if (isCancelled) return
      requestAnimationFrame(() => {
        const current = performance.now()
        frames1Second.push(current)
        while (frames1Second[0] !== undefined && frames1Second[0] <= current - 1000) {
          frames1Second.shift()
        }
        fps = frames1Second.length
        loop()
      })
    }
    loop()

    const interval = setInterval(() => setFps(fps), 128)

    return () => {
      isCancelled = true
      clearInterval(interval)
    }
  }, [])

  return fps
}
