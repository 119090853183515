import fuzzysort from 'fuzzysort'
import { sortBy } from 'lodash'
import React, { useCallback, useMemo, useRef, useState } from 'react'
import { useTranslation } from 'sierra-client/hooks/use-translation'
import * as settingsActions from 'sierra-client/state/author-course-settings/actions'
import * as settingsSelectors from 'sierra-client/state/author-course-settings/selectors'
import { useDispatch, useSelector } from 'sierra-client/state/hooks'
import { SettingsTabComponent } from 'sierra-client/views/course-settings/types'
import { AnimatedSearch } from 'sierra-client/views/manage/components/animated-search'
import { Abbreviation } from 'sierra-domain/content/v2/content'
import { nanoid12 } from 'sierra-domain/nanoid-extensions'
import { Button, IconButton, InputPrimitive, Spacer, Text, View } from 'sierra-ui/primitives'
import { token } from 'sierra-ui/theming'
import styled, { css, keyframes } from 'styled-components'

const EmptyGlossary: React.FC = () => {
  const { t } = useTranslation()

  return (
    <View direction='row' justifyContent='center' alignItems='center'>
      <View direction='column' alignItems='center' gap='2'>
        <Spacer size='80' />
        <Text size='small' color='foreground/muted' bold>
          {t('create.glossary.empty-headline')}
        </Text>
        <Text size='small' color='foreground/muted'>
          {t('create.glosssary.empty-info')}
        </Text>
      </View>
    </View>
  )
}

const Header = styled(View).attrs({ direction: 'column' })``

const Form = styled(View).attrs({
  direction: 'row',
  justifyContent: 'flex-start',
  alignItems: 'stretch',
})`
  position: relative;
`

const Search = styled(AnimatedSearch).attrs({ expandedWidth: '100%', initialOpen: true, disableClose: true })`
  flex: 1;
`

const Actions = styled(View).attrs({ direction: 'row', gap: '6', alignItems: 'center' })`
  display: flex;
`

const HiddenIconButton = styled(IconButton)<{ $hidden?: boolean }>`
  ${p =>
    p.$hidden === true &&
    css`
      opacity: 0;
      pointer-events: none;
    `}
`

const RowEdit = styled.div`
  display: grid;
  grid-template-rows: min-content;
  grid-template-columns: 1fr 1fr min-content;
  align-items: flex-start;
  justify-content: flex-start;
  gap: 8px;
  padding: 12px 0;
  border-bottom: 1px solid ${token('form/border/1')};
`

const fadeIn = keyframes`
    to {
        opacity: 1;
    }
`

const FadeInRowEdit = styled(RowEdit)`
  opacity: 0;
  animation: ${fadeIn} 150ms cubic-bezier(0.25, 0.1, 0.25, 1) forwards;
`

const GlossaryTable = styled.table`
  width: 100%;
`

const GlossaryTableBody = styled.tbody``

const GlossaryRow = styled(RowEdit).attrs({ as: 'tr' })`
  &:last-of-type {
    border-bottom: 0;
  }

  & ${Actions} {
    opacity: 0;
    transition: opacity 150ms cubic-bezier(0.25, 0.1, 0.25, 1);
  }

  &:hover ${Actions} {
    opacity: 1;
  }
`

const GlossaryCell = styled.td``

export const GlossaryTab: SettingsTabComponent = () => {
  const { t } = useTranslation()
  const dispatch = useDispatch()

  const [query, setQuery] = useState('')
  const [editingDraft, setEditingDraft] = useState<Abbreviation | undefined>(undefined)
  const [newDraft, setNewDraft] = useState(false)
  const [draft, setDraft] = useState<Omit<Abbreviation, 'id'>>({ abbreviation: '', explanation: '' })
  const newAbbreviationRef = useRef<HTMLInputElement>(null)
  const currentlyEditingAbbreviationRef = useRef<HTMLInputElement>(null)

  const glossaryEntries = useSelector(settingsSelectors.allAbbreviations)
  const rows = useMemo(() => sortBy(glossaryEntries, 'abbreviation'), [glossaryEntries])
  const filteredRows = useMemo(
    () => fuzzysort.go(query, rows, { key: 'abbreviation', all: true }).map(({ obj }) => obj),
    [rows, query]
  )

  /* Editing items */
  const isTableEmpty = rows.length === 0

  const onItemChange = useCallback(
    ({ rows, id, item }: { rows: Abbreviation[]; id: string; item: Abbreviation }): void => {
      const updatedAbbreviations = rows.map((entry: Abbreviation) => (entry.id === id ? item : entry))
      void dispatch(settingsActions.updateCourseSettings({ abbreviations: updatedAbbreviations }))
    },
    [dispatch]
  )

  const onHandleSubmit = useCallback(
    (item: Abbreviation) => {
      if (item.abbreviation.length === 0 || item.explanation.length === 0) return
      void onItemChange({ rows, id: item.id, item })
      setEditingDraft(undefined)
    },
    [rows, onItemChange]
  )

  const onHandleDelete = useCallback(
    (rows: Abbreviation[], abbreviation: Abbreviation) => {
      const updatedAbbreviations = rows.filter((entry: Abbreviation) => entry.id !== abbreviation.id)
      void dispatch(settingsActions.updateCourseSettings({ abbreviations: updatedAbbreviations }))
    },
    [dispatch]
  )

  /* Adding items */
  const isDraftComplete = useMemo(() => {
    return !(draft.abbreviation.length === 0 || draft.explanation.length === 0)
  }, [draft.abbreviation.length, draft.explanation.length])

  const openDraftEdit = (): void => {
    setEditingDraft(undefined)

    if (query !== '' && filteredRows.length === 0) {
      // If we have searched, reset the query and use it as a new editing draft
      setDraft({ abbreviation: query, explanation: '' })
      setQuery('')
    }

    setTimeout(() => newAbbreviationRef.current?.focus(), 100)

    setNewDraft(true)
  }

  const closeDraftEdit = (): void => {
    setDraft({ abbreviation: '', explanation: '' })
    setNewDraft(false)
  }

  const onDraftSubmit = (): void => {
    if (!isDraftComplete) return
    setNewDraft(false)
    const id = nanoid12()
    const updatedAbbreviations = rows.concat({ id, ...draft })
    void dispatch(settingsActions.updateCourseSettings({ abbreviations: updatedAbbreviations }))

    setDraft({ abbreviation: '', explanation: '' })
  }

  const onDraftEnter = (event: React.KeyboardEvent<HTMLInputElement>): void => {
    if (event.key === 'Enter') {
      void onDraftSubmit()
    }
  }

  return (
    <>
      <Header>
        <Text bold>{t('dictionary.glossary')}</Text>
        <Form>
          <Search placeholder={t('dictionary.search')} value={query} onChange={setQuery} />
          <Button variant='secondary' onClick={openDraftEdit}>
            {t('create.glossary.add-to-glossary')}
          </Button>
        </Form>
        <Text color='foreground/muted' size='micro'>
          {t('create.glossary.info')}
        </Text>
      </Header>
      <Spacer size='40' />
      {newDraft && (
        <>
          <FadeInRowEdit>
            <InputPrimitive
              ref={newAbbreviationRef}
              value={draft.abbreviation}
              placeholder={t('author.abbreviation-placeholder')}
              onChange={event => setDraft({ ...draft, abbreviation: event.target.value })}
              onKeyDown={onDraftEnter}
            />
            <InputPrimitive
              value={draft.explanation}
              placeholder={t('author.abbreviation-explanation-placeholder')}
              onChange={event => setDraft({ ...draft, explanation: event.target.value })}
              onKeyDown={onDraftEnter}
            />
            <Actions>
              <IconButton onClick={onDraftSubmit} disabled={!isDraftComplete} iconId='checkmark' />
              <IconButton
                color='foreground/secondary'
                variant='transparent'
                onClick={closeDraftEdit}
                iconId='trash-can'
              />
            </Actions>
          </FadeInRowEdit>
        </>
      )}
      {isTableEmpty ? (
        !newDraft ? (
          <EmptyGlossary />
        ) : null
      ) : (
        <GlossaryTable>
          <GlossaryTableBody>
            {filteredRows.map(item => {
              const isEditing = item.id === editingDraft?.id
              return (
                <GlossaryRow key={item.id}>
                  <GlossaryCell>
                    <InputPrimitive
                      ref={isEditing ? currentlyEditingAbbreviationRef : null}
                      readOnly={!isEditing}
                      value={isEditing ? editingDraft.abbreviation : item.abbreviation}
                      onChange={e => {
                        if (editingDraft === undefined) return
                        setEditingDraft({ ...editingDraft, abbreviation: e.target.value })
                      }}
                      placeholder={t('author.abbreviation-placeholder')}
                      color={isEditing ? 'foreground/primary' : 'foreground/secondary'}
                    />
                  </GlossaryCell>
                  <GlossaryCell>
                    <InputPrimitive
                      readOnly={!isEditing}
                      value={isEditing ? editingDraft.explanation : item.explanation}
                      onChange={e => {
                        if (editingDraft === undefined) return
                        setEditingDraft({ ...editingDraft, explanation: e.target.value })
                      }}
                      placeholder={t('author.abbreviation-explanation-placeholder')}
                      color={isEditing ? 'foreground/primary' : 'foreground/muted'}
                    />
                  </GlossaryCell>
                  <GlossaryCell>
                    <Actions>
                      {isEditing ? (
                        <Actions>
                          <IconButton
                            variant='transparent'
                            color='foreground/muted'
                            onClick={() => onHandleSubmit(editingDraft)}
                            iconId='checkmark'
                          />
                        </Actions>
                      ) : (
                        <IconButton
                          variant='secondary'
                          onClick={() => {
                            setTimeout(() => currentlyEditingAbbreviationRef.current?.focus(), 100)
                            setEditingDraft(item)
                          }}
                          iconId='edit'
                        />
                      )}
                      <HiddenIconButton
                        $hidden={isEditing}
                        variant='transparent'
                        color='foreground/muted'
                        onClick={() => onHandleDelete(rows, item)}
                        iconId='trash-can'
                      />
                    </Actions>
                  </GlossaryCell>
                </GlossaryRow>
              )
            })}
          </GlossaryTableBody>
        </GlossaryTable>
      )}
    </>
  )
}
