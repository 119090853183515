import React, { useState } from 'react'
import { config } from 'sierra-client/config/global-config'
import { usePost } from 'sierra-client/hooks/use-post'
import { useTranslation } from 'sierra-client/hooks/use-translation'
import { SecondaryButton } from 'sierra-client/layout/authentication-layout/auth-buttons'
import { getUserErrorTranslationKey } from 'sierra-client/utils/translation-utils'
import { AuthenticationContent } from 'sierra-client/views/authentication/native/components/authentication-content'
import { isLeft } from 'sierra-domain/either'
import { XRealtimeAuthSendResetPasswordEmail } from 'sierra-domain/routes'
import { getValidatedEmail } from 'sierra-domain/utils'
import { FormElement } from 'sierra-ui/components'
import { Button, InputPrimitive, Spacer, Text, View } from 'sierra-ui/primitives'

type Props = {
  switchToLogIn: () => void
  switchToSignUp?: () => void
}

export const ResetPassword = ({ switchToLogIn, switchToSignUp }: Props): JSX.Element => {
  const { t } = useTranslation()
  const { postWithUserErrorCode } = usePost()
  const [email, setEmail] = useState<string>('')
  const [emailError, setEmailError] = useState<string | undefined>(undefined)
  const [error, setError] = useState<undefined | string>(undefined)
  const [hasSentEmail, setHasSentEmail] = useState(false)
  const orgConfig = config.organization

  const { platformName } = orgConfig.settings.brand
  const organizationName = platformName ?? 'Sana'

  const hasPasswordLogin = orgConfig.auth.some(v => v.type === 'password')

  const handleEmailChange = (e: React.ChangeEvent<HTMLInputElement>): void => {
    setEmail(e.target.value)
  }

  const submit = async (): Promise<void> => {
    setError(undefined)

    const validatedEmail = getValidatedEmail(email.toLowerCase().trim())

    if (validatedEmail === undefined) {
      setEmailError(t('create-account.errors.email'))
      return
    }
    setEmailError(undefined)

    const result = await postWithUserErrorCode(XRealtimeAuthSendResetPasswordEmail, {
      email: validatedEmail,
    })

    if (isLeft(result)) {
      setError(t(getUserErrorTranslationKey(result.left)))
      return
    }

    setError(undefined)
    setHasSentEmail(true)
  }

  const handleKeyDown = (e: React.KeyboardEvent<HTMLInputElement>): void => {
    if (e.key === 'Enter') {
      void submit()
    }
  }

  return (
    <AuthenticationContent message={t('forgot-password.heading')}>
      {switchToSignUp && hasPasswordLogin && (
        <>
          <Text color='foreground/primary' onClick={switchToSignUp} size='small' bold align='center'>
            {t('login.new-to-organization', { organizationName })}{' '}
            <SecondaryButton>{t('login.create-an-account')}</SecondaryButton>
          </Text>
          <Spacer />
        </>
      )}
      <FormElement label={''} helper={emailError} isError={emailError !== undefined} grow>
        <InputPrimitive
          type='text'
          placeholder={t('forgot-password.email')}
          value={email}
          onChange={handleEmailChange}
          onKeyDown={handleKeyDown}
          disabled={hasSentEmail}
          autoComplete='username'
        />
      </FormElement>
      {hasSentEmail && (
        <Text color='foreground/primary' size='regular' spacing='xsmall'>
          {t('forgot-password.check-inbox')}
        </Text>
      )}
      {error !== undefined && (
        <Text size='small' color='redBright' spacing='xsmall'>
          {error}
        </Text>
      )}
      <Button variant='primary' id={'login-button'} onClick={submit} disabled={hasSentEmail} grow>
        {t('forgot-password.reset-password')}
      </Button>
      <View justifyContent='center'>
        <SecondaryButton onClick={switchToLogIn}>{t('login.back')}</SecondaryButton>
      </View>
    </AuthenticationContent>
  )
}
