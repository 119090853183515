import { DateTime } from 'luxon'
import React, { useEffect, useMemo } from 'react'
import { HeaderIconButton, HeaderItemCSS } from 'sierra-client/components/liveV2/header-buttons'
import { useLiveSessionIdContext } from 'sierra-client/components/liveV2/live-session-id-provider'
import { useScreenRecordContext } from 'sierra-client/components/liveV2/recording/recording-provider'
import { supportRecording } from 'sierra-client/components/liveV2/recording/recording-utils'
import { useOnce } from 'sierra-client/hooks/use-once'
import { useTranslation } from 'sierra-client/hooks/use-translation'
import { useSelector } from 'sierra-client/state/hooks'
import {
  selectIsFacilitator,
  selectIsRecording,
  selectOtherRecordingsInProgress,
} from 'sierra-client/state/live-session/selectors'
import { useUserLegacy } from 'sierra-client/state/users/hooks'
import { getAvatarImage } from 'sierra-client/utils/avatar-img'
import { Recording } from 'sierra-domain/live-session'
import { getUserName } from 'sierra-domain/utils'
import { Icon, RoundAvatar, Tooltip } from 'sierra-ui/components'
import { token } from 'sierra-ui/theming'
import { ConditionalWrapper } from 'sierra-ui/utils/conditional-wrapper'
import styled from 'styled-components'

const LearnerRecordingIcon = styled(Icon)`
  box-sizing: unset;
  padding: 0 0.625rem;
  ${HeaderItemCSS}

  background-color: ${token('border/default')};

  color: ${p => p.theme.color.redVivid};
`

const RecordingText: React.FC<{ recording: Recording }> = props => {
  const user = useUserLegacy(props.recording.userId)
  const { t } = useTranslation()
  const userName = getUserName(user) ?? ''

  return (
    <>
      <Tooltip title={t('live.person-is-recording', { name: userName })}>
        <LearnerRecordingIcon iconId='record' color='currentColor' />
      </Tooltip>
    </>
  )
}

const LearnerButton: React.FC = () => {
  const otherRecordingInProgress = useSelector(selectOtherRecordingsInProgress)[0]

  if (otherRecordingInProgress === undefined) return null
  return <RecordingText recording={otherRecordingInProgress} />
}

const OtherFacilitatorIsRecording: React.FC<{ recording: Recording }> = props => {
  const user = useUserLegacy(props.recording.userId)
  const userName = getUserName(user) ?? ''
  const { t } = useTranslation()
  const recordingSince = DateTime.fromISO(props.recording.startTime).toFormat('HH:mm')

  return (
    <Tooltip title={t('live.person-is-recording-since', { name: userName, time: recordingSince })}>
      <RoundAvatar
        size='tiny'
        firstName={user?.firstName}
        lastName={user?.lastName}
        src={getAvatarImage(user?.uuid, user?.avatar)}
      />
    </Tooltip>
  )
}

const FacilitatorRecordButtonContainer = styled.div`
  position: relative;
`

const FacilitatorRecordButton: React.FC<{ liveSessionId: string | undefined }> = ({ liveSessionId }) => {
  const screenRecord = useScreenRecordContext()
  const isScreenRecording = useSelector(selectIsRecording)
  const { t } = useTranslation()

  const { showOnce, setSeen } = useOnce(
    liveSessionId === undefined ? `nudgeToRecord-once` : `nudgeToRecord-${liveSessionId}`
  )

  useEffect(() => {
    let timeout: ReturnType<typeof setTimeout>

    if (showOnce) {
      timeout = setTimeout(setSeen, 7000)
    }

    return () => clearTimeout(timeout)
  }, [setSeen, showOnce])

  const tooltipText = useMemo(() => {
    if (!isScreenRecording) return t('live.record')
    else return t('live.stop-recording')
  }, [isScreenRecording, t])

  return (
    <FacilitatorRecordButtonContainer>
      <ConditionalWrapper
        condition={true}
        renderWrapper={children => {
          return <Tooltip title={tooltipText}>{children}</Tooltip>
        }}
      >
        <div id='tour-live-recording'>
          <HeaderIconButton
            iconId={!isScreenRecording ? 'record' : 'stop--outlined'}
            color={!isScreenRecording ? 'currentColor' : 'redVivid'}
            onClick={async () => {
              await screenRecord.toggleScreenRecord()
            }}
          />
        </div>
      </ConditionalWrapper>
    </FacilitatorRecordButtonContainer>
  )
}

const FacilitatorButton: React.FC = () => {
  const isScreenRecording = useSelector(selectIsRecording)
  const otherRecordingInProgress = useSelector(selectOtherRecordingsInProgress)[0]
  const liveSessionId = useLiveSessionIdContext().liveSessionId

  return otherRecordingInProgress !== undefined && !isScreenRecording ? (
    <OtherFacilitatorIsRecording recording={otherRecordingInProgress} />
  ) : (
    <FacilitatorRecordButton liveSessionId={liveSessionId} />
  )
}

export const RecordingButtonForFacilitator: React.FC = () => {
  const isFacilitator = useSelector(selectIsFacilitator)
  if (!isFacilitator) return null
  if (!supportRecording) return null

  return <FacilitatorButton />
}

export const RecordingButtonForLearner: React.FC = () => {
  const isFacilitator = useSelector(selectIsFacilitator)
  if (isFacilitator) return null

  return <LearnerButton />
}
