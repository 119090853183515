import { useCallback, useMemo } from 'react'
import { config } from 'sierra-client/config/global-config'
import { FCC } from 'sierra-client/types'
import { dynamicColor } from 'sierra-ui/color'
import { Theme } from 'sierra-ui/theming/legacy-theme'
import { TokenOverride, TokenOverrideProvider } from 'sierra-ui/theming/tokens/context'
import { ThemeProvider } from 'styled-components'

export const CustomInterfaceThemeProvider: FCC = ({ children }) => {
  const {
    settings: {
      brand: { interfaceTheme },
    },
  } = config.organization

  const buttonBackgroundColor = interfaceTheme?.buttonBackgroundColor
  const buttonTextColor = interfaceTheme?.buttonTextColor
  const tabColor = interfaceTheme?.tabColor

  const patchTheme = useCallback(
    (theme: Theme): Theme => ({
      ...theme,
      home: {
        ...theme.home,
        tabColor: tabColor !== undefined ? dynamicColor(tabColor).toRgbaString() : theme.home.tabColor,
        actionButtonTextColor:
          buttonTextColor !== undefined
            ? dynamicColor(buttonTextColor).toRgbaString()
            : theme.home.actionButtonTextColor,
        actionButtonBackgroundColor:
          buttonBackgroundColor !== undefined
            ? dynamicColor(buttonBackgroundColor).toRgbaString()
            : theme.home.actionButtonBackgroundColor,
      },
    }),
    [buttonBackgroundColor, buttonTextColor, tabColor]
  )

  return <ThemeProvider theme={patchTheme}>{children}</ThemeProvider>
}

export const GlobalOrganizationThemingProvider: FCC = ({ children }) => {
  const {
    settings: {
      brand: { interfaceTheme },
    },
  } = config.organization

  const buttonBackgroundColor = interfaceTheme?.buttonBackgroundColor
  const buttonTextColor = interfaceTheme?.buttonTextColor

  const tokenOverrides = useMemo((): TokenOverride[] => {
    const foregroundColor = buttonTextColor !== undefined ? dynamicColor(buttonTextColor) : undefined
    const backgroundColor =
      buttonBackgroundColor !== undefined ? dynamicColor(buttonBackgroundColor) : undefined

    return [
      ['button/foreground', foregroundColor],
      ['button/background', backgroundColor],
      ['org/primary', backgroundColor],
      ['org/secondary', foregroundColor],
    ]
  }, [buttonBackgroundColor, buttonTextColor])

  return <TokenOverrideProvider value={tokenOverrides}>{children}</TokenOverrideProvider>
}
