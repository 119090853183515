import { createContext, useContext } from 'react'
import { VideoCallService } from 'sierra-client/components/liveV2/services/video-call-service/video-call-service'

type LiveContext = {
  leave: () => Promise<void>
  left: boolean
  isReady: boolean
  videoCallService?: VideoCallService
}

export const LiveContextObj = createContext<LiveContext | undefined>(undefined)

export const useHasLiveContext = (): boolean => {
  const context = useContext(LiveContextObj)

  return context !== undefined
}

export const useLiveContext = (): LiveContext => {
  const context = useContext(LiveContextObj)

  if (context === undefined) {
    throw new Error('This component must be wrapped in a LiveProvider')
  }

  return context
}

export const useVideoCallService = (): VideoCallService | undefined => {
  const context = useContext(LiveContextObj)

  if (context === undefined) {
    throw new Error('This component must be wrapped in a LiveContext')
  }

  return context.videoCallService
}
