import { useActiveRouteMatch } from 'sierra-client/hooks/router/use-active-route'
import { LiveContentId, SelfPacedContentId } from 'sierra-domain/api/nano-id'
import {
  ScopedCreateContentId,
  ScopedLiveContentId,
  ScopedSelfPacedContentId,
} from 'sierra-domain/collaboration/types'
import { NodeId } from 'sierra-domain/flexible-content/identifiers'
import { z } from 'zod'

const CourseIdsSplatSchema = z.union([
  z.tuple([LiveContentId.or(SelfPacedContentId)]),
  z.tuple([LiveContentId.or(SelfPacedContentId), NodeId]),
])

type UseCourseRouteResult =
  | {
      contentId: ScopedCreateContentId
      unitId?: NodeId
    }
  | undefined

export function useCreateRouteIds(): UseCourseRouteResult {
  return useActiveRouteMatch({
    select: match => {
      switch (match.routeId) {
        case '/create/s/$': {
          const result = CourseIdsSplatSchema.safeParse(match.params._splat?.split('/')).data
          if (result === undefined) return undefined

          const [selfPacedContentId, nodeId] = result
          return {
            contentId: ScopedSelfPacedContentId.fromId(selfPacedContentId as SelfPacedContentId),
            unitId: nodeId,
          }
        }

        case '/create/l/$': {
          const result = CourseIdsSplatSchema.safeParse(match.params._splat?.split('/')).data
          if (result === undefined) return undefined

          const [selfPacedContentId, nodeId] = result
          return {
            contentId: ScopedLiveContentId.fromId(selfPacedContentId as LiveContentId),
            unitId: nodeId,
          }
        }

        default:
          return undefined
      }
    },
  })
}
