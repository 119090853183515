import { FC, useCallback, useEffect, useMemo, useState } from 'react'
import { useTranslation } from 'sierra-client/hooks/use-translation'
import { useCachedQuery } from 'sierra-client/state/api'
import { Tag as TagId } from 'sierra-domain/api/common'
import { Tag, TagData } from 'sierra-domain/content/tag'
import { XRealtimeContentListTags } from 'sierra-domain/routes'
import { Autocomplete, DefaultStyledAutocompleteOption } from 'sierra-ui/components/autocomplete/autocomplete'
import {
  ListContainer,
  SelectedPill,
  SelectedPillRemoveButton,
} from 'sierra-ui/components/autocomplete/reference-implementation/atoms'
import { Skeleton, Text, View } from 'sierra-ui/primitives'

type TagChangeHandler = (tags: Tag[]) => void

const TagsAutoComplete: FC<{
  initialSelected: Tag[]
  tags: Tag[]
  onChange: TagChangeHandler
}> = ({ initialSelected, tags, onChange }) => {
  const { t } = useTranslation()
  const [query, setQuery] = useState('')
  const [matchingItems, setMatchingItems] = useState<Tag[]>([])
  const [selectedItems, setSelectedItems] = useState<Tag[]>(initialSelected)

  const tagsLookup = useMemo(() => {
    return tags.reduce(
      (acc, item) => {
        acc[item.id] = item.data
        return acc
      },
      {} as Record<string, TagData>
    )
  }, [tags])

  const updateMatchingItems = useCallback(() => {
    const data = tags.filter(tag => selectedItems.find(selected => selected.id === tag.id) === undefined)
    const matching =
      query.length === 0
        ? data
        : data.filter(it => {
            const name = it.data.name.toLowerCase()
            return name.includes(query.toLowerCase())
          })

    setMatchingItems(matching)
  }, [query, selectedItems, tags])

  const handleSelectItem = useCallback((item: Tag) => {
    setSelectedItems(previous => [...previous, item])
  }, [])

  const handleUnselectItem = useCallback((item: Tag) => {
    setSelectedItems(previous => previous.filter(it => it.id !== item.id))
  }, [])

  const handleQueryChange = useCallback((value: string) => {
    setQuery(value)
  }, [])

  useEffect(() => {
    updateMatchingItems()
  }, [updateMatchingItems])

  useEffect(() => {
    onChange(selectedItems)
  }, [onChange, selectedItems])

  return (
    <Autocomplete
      query={query}
      onQueryChange={handleQueryChange}
      matchingItems={matchingItems}
      selectedItems={selectedItems}
      onSelect={handleSelectItem}
      onUnselect={handleUnselectItem}
      renderSelectedItem={(item, { onUnselect, ...props }) => (
        <SelectedPill key={item.id} {...props}>
          <View direction='column' marginLeft='4'>
            <Text bold>{tagsLookup[item.id]?.name}</Text>
          </View>
          <SelectedPillRemoveButton aria-label={t('dictionary.remove')} onClick={onUnselect} />
        </SelectedPill>
      )}
      renderMatchingItemList={({ getItemProps }) => (
        <ListContainer>
          {matchingItems.map((item, index) => {
            return (
              <DefaultStyledAutocompleteOption key={item.id} {...getItemProps(item, index)}>
                <Text>{tagsLookup[item.id]?.name}</Text>
              </DefaultStyledAutocompleteOption>
            )
          })}
        </ListContainer>
      )}
    />
  )
}

export const CourseTagsSelector: FC<{ selectedTags: TagId[]; onChange: TagChangeHandler }> = ({
  selectedTags,
  onChange,
}) => {
  const tagsQuery = useCachedQuery(XRealtimeContentListTags, {}, { select: data => data.rankedTags })
  const initialIds = selectedTags.map(it => it.id)
  const initialSelected = tagsQuery.data?.filter(it => initialIds.includes(it.id)) ?? []

  return (
    <>
      {tagsQuery.data === undefined ? (
        <Skeleton $radius={8} $height={40} />
      ) : (
        <TagsAutoComplete tags={tagsQuery.data} initialSelected={initialSelected} onChange={onChange} />
      )}
    </>
  )
}
