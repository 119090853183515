import { MouseEventHandler } from 'react'
import { useTranslation } from 'sierra-client/hooks/use-translation'
import { TranslationKey } from 'sierra-client/hooks/use-translation/types'
import { AiGradientBase, Icon, IconId, aiGradient } from 'sierra-ui/components'
import { token } from 'sierra-ui/theming'
import { fonts } from 'sierra-ui/theming/fonts'
import styled, { css } from 'styled-components'

type HelperVariant = 'primary' | 'secondary' | 'ai'

type HelperActionProps = {
  title: TranslationKey
  variant: HelperVariant
  icon?: IconId
  decoratorPosition?: 'right' | 'left'
  onClick?: MouseEventHandler<HTMLButtonElement>
}

const HelperActionInternal = styled.button<{ $variant: HelperVariant } & AiGradientBase>`
  display: flex;
  justify-content: flex-start;
  align-items: center;
  gap: 8px;

  padding: 16px 24px;
  border-radius: 16px;
  border: 1px solid transparent;

  ${fonts.body.small};
  font-weight: ${fonts.weight.bold};
  cursor: pointer;

  ${p => {
    switch (p.$variant) {
      case 'primary':
        return css`
          color: ${token('button/foreground')};
          background: ${token('button/background')};
        `
      case 'secondary':
        return css`
          color: ${token('foreground/primary')};
          background: ${token('surface/default')};
          border-color: ${token(`form/border/1`)};
        `
      case 'ai':
        return css`
          color: ${token('button/foreground')};
          ${aiGradient}
        `
    }
  }}
`

/* We're implementing a custom button here because animations will be different, as will be sizes and a few other details */
const HelperAction: React.FC<HelperActionProps> = ({
  title,
  icon,
  decoratorPosition = 'left',
  variant,
  onClick,
}) => {
  const { t } = useTranslation()

  return (
    <HelperActionInternal $variant={variant} base='#5B59EA' onClick={onClick}>
      {icon !== undefined && decoratorPosition === 'left' && <Icon iconId={icon} color='currentColor' />}
      {t(title)}
      {icon !== undefined && decoratorPosition === 'right' && <Icon iconId={icon} color='currentColor' />}
    </HelperActionInternal>
  )
}

/* Primary actions */
export const Start = styled(HelperAction).attrs({
  title: 'helper.start',
  variant: 'primary',
  decoratorPosition: 'right',
  icon: 'next--filled',
})``

export const CourseOverview = styled(HelperAction).attrs({
  title: 'manage.view-course-details',
  variant: 'primary',
  decoratorPosition: 'right',
  icon: 'next--filled',
})``

export const ViewDetails = styled(HelperAction).attrs({
  title: 'manage.view-details',
  variant: 'primary',
  decoratorPosition: 'right',
  icon: 'next--filled',
})``

export const ChooseSession = styled(HelperAction).attrs({
  title: 'home.assigned-content.choose-session',
  variant: 'primary',
  decoratorPosition: 'right',
  icon: 'calendar',
})``

/* Secondary */
export const BackToHome = styled(HelperAction).attrs({
  title: 'helper.back-to-home',
  variant: 'secondary',
  icon: 'home',
  iconColor: 'foreground/secondary',
})``

export const ViewAllAssignments = styled(HelperAction).attrs({
  title: 'helper.view-assignments',
  variant: 'secondary',
  icon: 'skill--books--vertical',
  iconColor: 'foreground/secondary',
})``

export const ProgramOverview = styled(HelperAction).attrs({
  title: 'helper.program-overview',
  variant: 'secondary',
})``

export const PathOverview = styled(HelperAction).attrs({
  title: 'helper.path-overview',
  variant: 'secondary',
})``
