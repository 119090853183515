/* eslint-disable @typescript-eslint/explicit-function-return-type */
import { useNotif } from 'sierra-client/components/common/notifications'
import { useTypedInvalidateQueries, useTypedMutation } from 'sierra-client/state/api'
import {
  XRealtimeAdminCoursesListCourseCollaboratorGroups,
  XRealtimeAdminCoursesUpdateCourseCollaboratorGroups,
} from 'sierra-domain/routes'

export const useCourseCollaboratorGroupsMutation = () => {
  const invalidateQueries = useTypedInvalidateQueries([XRealtimeAdminCoursesListCourseCollaboratorGroups])
  const notification = useNotif()

  return useTypedMutation(XRealtimeAdminCoursesUpdateCourseCollaboratorGroups, {
    onSettled: () => {
      void invalidateQueries()
    },
    onError: () => {
      notification.push({ type: 'error' })
    },
  })
}
