import React from 'react'
import {
  AdaptiveState,
  createSubmitEmbedResponse,
  createSubmitQuestionResponse,
  currentElement,
  goToNextElement,
  resetResponse,
  start,
  updateResponse,
} from 'sierra-client/views/self-paced/adaptive-features/adaptive-context'

const submitQuestionResponse = createSubmitQuestionResponse('placementTest')
const submitEmbedResponse = createSubmitEmbedResponse('placementTest')

export const PlacementTests = {
  currentElement,
  updateResponse,
  submitQuestionResponse,
  submitEmbedResponse,
  resetResponse,
  goToNextElement,
  start,
}

export type PlacementTestContext = {
  state: AdaptiveState
  setState: React.Dispatch<React.SetStateAction<AdaptiveState>>
}

const ReactPlacementTestContext = React.createContext<PlacementTestContext | undefined>(undefined)
export const PlacementTestContextProvider = ReactPlacementTestContext.Provider

export function usePlacementTestContext(): PlacementTestContext {
  const context = React.useContext(ReactPlacementTestContext)

  if (context === undefined)
    throw new Error('usePlacementTestContext must be used within a PlacementTestContextProvider')
  return context
}
