import { createFileRoute } from '@tanstack/react-router'
import { PageIdentifier } from 'sierra-client/layout/sana-page'
import { ManageXApiDetails } from 'sierra-client/views/manage/api/manage-x-api-details'
import { useXApiPageEnabled } from 'sierra-client/views/manage/permissions/use-xapi-page-enabled'
import { ManagePageWrapper } from 'sierra-client/views/manage/utils/manage-page-wrapper'
import { XApiServerId } from 'sierra-domain/api/nano-id'
import { z } from 'zod'

// Note: ManagePageWrapper handles the role check.
const Page: React.FC = () => {
  const xApiServerId = Route.useParams({ select: p => p.xApiServerId })
  const xApiPageAvailable = useXApiPageEnabled()

  if (xApiPageAvailable !== true) {
    // todo(workflows): Redirect after initializing
    return null
  }

  return (
    <ManagePageWrapper
      pageIdentifier={
        xApiServerId === 'new'
          ? PageIdentifier.ManageXApiNewServer()
          : PageIdentifier.ManageXApiServerDetail({ xApiServerId })
      }
    >
      <ManageXApiDetails key={xApiServerId} serverIdOrNew={xApiServerId} />
    </ManagePageWrapper>
  )
}

export const Route = createFileRoute('/manage/x-api/$xApiServerId')({
  component: Page,
  params: {
    parse: z.object({ xApiServerId: z.union([XApiServerId, z.literal('new')]) }).parse,
    stringify: p => p,
  },
})
