import { useCallback } from 'react'
import { useTranslation } from 'sierra-client/hooks/use-translation'
import { useOutlineEditPanels } from 'sierra-client/views/manage/programs/staggered-assignments/hooks/use-outline-edit-panels'
import { Icon } from 'sierra-ui/components'
import { Button, Text, View } from 'sierra-ui/primitives'
import styled from 'styled-components'

const Wrapper = styled(View).attrs({
  background: 'surface/soft',
  padding: '40',
  radius: 'size-20',
  direction: 'column',
  marginTop: '16',
  justifyContent: 'center',
  alignItems: 'center',
  gap: '16',
})``

export const EmptyOutline: React.FC<{ canEdit: boolean }> = ({ canEdit }) => {
  const { t } = useTranslation()

  const {
    setPanels: {
      editOutline: { on: editOutlineOn },
      addContent: { on: addStepOn },
    },
  } = useOutlineEditPanels()

  const onClick = useCallback(() => {
    addStepOn()
    editOutlineOn()
  }, [editOutlineOn, addStepOn])

  return (
    <Wrapper>
      <Icon iconId='bookmark' color='foreground/primary' />
      <View direction='column' alignItems='center' gap='2'>
        <Text bold color='foreground/primary'>
          {t('manage.programs.add-step')}
        </Text>
        <Text color='grey40'>{t('manage.program.program-details.outline.empty-content-info')}</Text>
      </View>
      {canEdit && (
        <Button variant='ghost' onClick={onClick}>
          {t('manage.programs.add-step')}
        </Button>
      )}
    </Wrapper>
  )
}
