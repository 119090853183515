import { go } from 'fuzzysort'
import * as DL from 'sierra-client/lib/tabular/control/dataloader'
import { TableData } from 'sierra-client/lib/tabular/datatype/tabledata'
import { mergeTableData, sortTableData } from 'sierra-client/lib/tabular/sorting'

export const sliceTableData = <TD extends TableData>(tableData: TD, start: number, end: number): TD => ({
  ...tableData,
  rows: tableData.rows.slice(start, end),
})

const DEFAULT_LIMIT = 100

export type StaticLoaderSearchKeyBy<TD extends TableData = TableData> = (
  tableData: TD,
  index: number
) => string

const filter = <TD extends TableData>(
  tableData: TD,
  byKey: StaticLoaderSearchKeyBy<TD>,
  query: string
): TD => {
  const results = go(
    query,
    tableData.rows.map((row, i) => ({ row: row, searchString: byKey(tableData, i) })),
    {
      key: 'searchString',
    }
  )

  if (results.length === 0) {
    return {
      ...tableData,
      ids: [],
      nested: {},
      data: [],
    }
  }

  const idx = results.map(x => x.obj.row)

  const tds = idx.map(id => {
    const i = tableData.rows.findIndex(r => r.id === id.id)

    return {
      ...tableData,
      rows: [tableData.rows[i]],
    }
  })

  const returned = mergeTableData(tds)

  return returned
}

export const staticDataLoader = <TD extends TableData>(
  tableData: TD,
  byKey?: StaticLoaderSearchKeyBy<TD>
): DL.DataLoaderStateMachine<TD, { subset: TD }> => ({
  onInit(init) {
    const { sorting } = init.modifier

    const sortedTableData = sorting !== undefined ? sortTableData(tableData, sorting) : tableData

    const subset =
      byKey && init.predicate.query !== undefined
        ? filter(sortedTableData, byKey, init.predicate.query)
        : sortedTableData

    const slice = sliceTableData(subset, 0, init.control.limit ?? DEFAULT_LIMIT)
    const done = slice.rows.length === subset.rows.length

    const state = {
      ...init,
      meta: { subset },
      data: [slice],
      pagination: {
        total: subset.rows.length,
        loaded: slice.rows.length,
        done,
      },
    }

    return Promise.resolve(done ? DL.DoneState(state) : DL.MoreState(state))
  },

  onMore(state, more) {
    const slice = sliceTableData(
      state.meta.subset,
      state.pagination.loaded,
      state.pagination.loaded + (more.control.limit ?? DEFAULT_LIMIT)
    )
    const loaded = state.pagination.loaded + slice.rows.length
    const done = loaded === tableData.rows.length
    const next = {
      ...state,
      ...more,
      data: [...state.data, slice],
      pagination: {
        total: tableData.rows.length,
        loaded,
        done,
      },
    }
    return Promise.resolve(done ? DL.DoneState(next) : DL.MoreState(next))
  },
})
