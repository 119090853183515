import { createSelector } from '@reduxjs/toolkit'
import _ from 'lodash'
import { DateTime } from 'luxon'
import { shallowEqual } from 'react-redux'
import { OutputSelector, createSelectorCreator, lruMemoize } from 'reselect'
import {
  AwarenessData,
  AwarenessParticipant,
  LiveScreenSharingParticipant,
  LiveSessionState,
} from 'sierra-client/state/live-session/types'
import { selectClientId, selectRemoteParticipants } from 'sierra-client/state/live/selectors'
import { RootState } from 'sierra-client/state/types'
import { selectUser, selectUserId } from 'sierra-client/state/user/user-selector'
import { UserId } from 'sierra-domain/api/uuid'
import { FileId } from 'sierra-domain/flexible-content/identifiers'
import { requireDefinedProps } from 'sierra-domain/require-defined-props'
import { asNonNullable, isDefined } from 'sierra-domain/utils'

const selectState = (state: RootState): LiveSessionState => state.liveSession

export const selectLiveSessionData = createSelector(selectState, state => state.data)
export const selectDataHasLoaded = createSelector(selectState, state => !!state.data)

const selectLiveSessionAwareness = createSelector(selectState, state => state.awareness)

// Create a selector that uses _.isEqual to check its argument
const createIsEqualSelector = createSelectorCreator({ memoize: lruMemoize, memoizeOptions: [_.isEqual] })

// value _.isEqual to the previous return value
// Since the awareness changes very frequently, it's important to ensure the selectors only change if the
// return value is changed
const createAwarenessSelector = <T>(
  func: (awareness: AwarenessData[]) => T
): OutputSelector<[(_: RootState) => AwarenessData[]], T> =>
  createIsEqualSelector([selectLiveSessionAwareness], func)

export const selectLiveSessionLocalAwarenessState = createSelector(
  selectState,
  state => state.localAwarenessState
)

export const selectLiveSessionLocalAwarenessStateUserId = createSelector(
  selectLiveSessionLocalAwarenessState,
  awareness => awareness.userId
)

const selectAllUsersInAwareness = createAwarenessSelector(awareness =>
  awareness.map(x => ({ userId: x.userId, isCurrentClient: x.isCurrentClient }))
)

/**
 * Selects a single user to be an "actor" to avoid multiple users trying to do the same action at the same time.
 */
export const selectCurrentUserIsActor = createSelector(
  selectAllUsersInAwareness,
  users => users.length === 0 || _.sortBy(users, 'userId')[0]?.isCurrentClient === true
)

export const selectSpotligtUserPosition = createAwarenessSelector(awareness => {
  const spotlightUser = _.chain(awareness)
    .filter(
      ({ scrollAtSlateNodeElementId, isCurrentClient }) =>
        isCurrentClient === false && scrollAtSlateNodeElementId !== undefined
    )
    .first()
    .value() as AwarenessData | undefined

  if (spotlightUser !== undefined) {
    return {
      userId: spotlightUser.userId,
      scrollAtSlateNodeElementId: spotlightUser.scrollAtSlateNodeElementId,
    }
  }
})

export const selectUserIdToFollow = createAwarenessSelector(
  awareness =>
    awareness.find(({ followMeEnabled, isCurrentClient }) => followMeEnabled === true && !isCurrentClient)
      ?.userId
)

export const selectFollowMeVideoPlaybackState = createAwarenessSelector(
  awareness =>
    awareness.find(
      ({ followMeEnabled, isCurrentClient, followVideoState }) =>
        followMeEnabled === true && !isCurrentClient && followVideoState !== undefined
    )?.followVideoState
)

export const selectUsersFollowingFollowMe = createAwarenessSelector(awareness =>
  awareness
    .filter(({ isFollowingFollowMe, isCurrentClient }) => isFollowingFollowMe === true && !isCurrentClient)
    .map(({ userId }) => userId)
)

export const selectUsersTypingInChat = createAwarenessSelector(awareness =>
  awareness.flatMap(({ isWritingToChatThreadId, isCurrentClient, userId }) => {
    if (isWritingToChatThreadId !== undefined && !isCurrentClient && userId !== undefined) {
      return [{ userId, threadId: isWritingToChatThreadId }]
    }

    return []
  })
)

export const selectIsFollowingScroll = createSelector(
  selectLiveSessionLocalAwarenessState,
  state => state.isFollowingFollowMe === true
)

export const selectFollowMeIsEnabled = createSelector(
  selectLiveSessionLocalAwarenessState,
  state => state.followMeEnabled === true
)

export const selectFlipCardStates = createSelector(selectState, state => state.data?.flipCards)

export const selectReflectionCardStates = createSelector(selectState, state => state.data?.reflectionCards)

const selectAllRemoteAwarenessParticipants = createAwarenessSelector<AwarenessParticipant[]>(awareness =>
  awareness
    .map(({ userId, agoraUID, agoraScreenShareUID, breakoutRoomId, isCurrentClient }) =>
      userId !== undefined && agoraUID !== undefined && isCurrentClient === false
        ? {
            type: 'awareness' as const,
            userId,
            agoraUID,
            agoraScreenShareUID,
            breakoutRoomId,
            isCurrentClient,
          }
        : undefined
    )
    .filter(isDefined)
)

export const selectAllSessionParticipantsIncludingMe = createSelector(
  [selectLiveSessionLocalAwarenessState, selectAllRemoteAwarenessParticipants],
  ({ userId, agoraUID, agoraScreenShareUID, breakoutRoomId }, remoteParticipants) => {
    if (userId !== undefined && agoraUID !== undefined) {
      return [
        {
          type: 'awareness' as const,
          userId,
          agoraUID,
          agoraScreenShareUID,
          breakoutRoomId,
          isCurrentClient: true,
        },
        ...remoteParticipants,
      ]
    }

    return remoteParticipants
  }
)

export const selectAllSessionParticipants = createSelector(
  selectAllSessionParticipantsIncludingMe,
  participants => participants.filter(({ isCurrentClient }) => isCurrentClient === false)
)

const selectAllCurrentRecordingIds = createAwarenessSelector(awareness =>
  awareness
    .filter(participant => !participant.isCurrentClient && participant.isRecordingWithId !== undefined)
    .map(participant => participant.isRecordingWithId)
    .filter(isDefined)
)

export const selectSessionParticipant = createSelector(
  selectAllSessionParticipantsIncludingMe,
  participants => (participantAgoraId: string) =>
    participants.find(participant => participant.agoraUID === participantAgoraId)
)

export const selectAllSessionParticipantsIncludingMeCount = createSelector(
  selectAllSessionParticipantsIncludingMe,
  participants => participants.length
)

// TODO: select the current breakout room directly

const selectCurrentRoomParticipants = createSelector(
  selectAllSessionParticipants,
  selectLiveSessionLocalAwarenessState,
  (participants, me) => {
    return participants.filter(({ breakoutRoomId }) => breakoutRoomId === me.breakoutRoomId)
  }
)

const selectCurrentRoomParticipantsIncludingMe = createSelector(
  selectAllSessionParticipantsIncludingMe,
  selectLiveSessionLocalAwarenessState,
  (participants, me) => participants.filter(({ breakoutRoomId }) => breakoutRoomId === me.breakoutRoomId)
)

export const selectCurrentRoomParticipantCount = createSelector(
  selectCurrentRoomParticipants,
  participants => participants.length
)

export const selectRoomParticipants = createSelector(
  [selectAllSessionParticipantsIncludingMe, (state, breakoutRoomId) => breakoutRoomId],
  (participants, breakoutRoomId) =>
    participants.filter(participant => participant.breakoutRoomId === breakoutRoomId)
)

export const selectScreenShareParticipants = createSelector(
  selectCurrentRoomParticipantsIncludingMe,
  participants =>
    participants.filter(
      participant => participant.agoraScreenShareUID !== undefined
    ) as LiveScreenSharingParticipant[]
)

export const selectScreenShareParticipant = createSelector(
  selectScreenShareParticipants,
  screenShareParticipants => screenShareParticipants[0]
)

export const selectIsSomeoneScreenSharing = createSelector(
  selectCurrentRoomParticipantsIncludingMe,
  participants => participants.some(participant => participant.agoraScreenShareUID !== undefined)
)

// TODO: this should probably be based on agoraUID instead of userId
const selectUserIdToLastSpokeMap = createAwarenessSelector(awareness => {
  return _.reduce(
    awareness,
    (userMap, { userId, timeUserWasEngaged }) => {
      if (userId !== undefined) {
        userMap[userId] = timeUserWasEngaged
      }
      return userMap
    },
    {} as Record<string, string | undefined>
  )
})

const selectAgoraUidToLastSpokeMap = createAwarenessSelector(awareness => {
  return awareness.reduce<Record<string, DateTime | undefined>>((agoraUidMap, participant) => {
    if (participant.agoraUID !== undefined) {
      agoraUidMap[participant.agoraUID] =
        participant.timeUserWasEngaged === undefined
          ? undefined
          : DateTime.fromISO(participant.timeUserWasEngaged)
    }
    return agoraUidMap
  }, {})
})

export const selectLiveSessionRaisedHands = createAwarenessSelector(awareness =>
  awareness
    .filter(requireDefinedProps('timeStartedRaisingHand', 'userId'))
    .map(({ userId, timeStartedRaisingHand, agoraUID }) => ({ userId, timeStartedRaisingHand, agoraUID }))
)

export const selectUsersPositionInRaisedHandQueue = createSelector(
  [selectLiveSessionRaisedHands, (state: unknown, userId: string | undefined) => userId],
  (raisedHands, userId) => {
    if (userId === undefined) return undefined
    const index = _.chain(raisedHands)
      .sortBy('timeStartedRaisingHand')
      .findIndex(raisedHand => raisedHand.userId === userId)
      .value()

    if (index === -1) return undefined

    return index + 1
  }
)

export const selectLiveSessionIsMyHandRaised = createSelector(
  selectLiveSessionLocalAwarenessState,
  state => state.timeStartedRaisingHand !== undefined
)

export const selectUuidsForParticipants = createSelector(selectAllSessionParticipants, participants =>
  _.memoize((agoraSearchUIDs: string[]): (string | undefined)[] =>
    agoraSearchUIDs.map(
      agoraId =>
        participants.find(
          ({ agoraUID, agoraScreenShareUID }) => agoraUID === agoraId || agoraScreenShareUID === agoraId
        )?.userId
    )
  )
)

export const selectLatestEventForUser = createSelector(
  [selectLiveSessionData, (state: unknown, userId: string | undefined) => userId],
  (data, userId) => (userId !== undefined ? data?.userControlEventsMap?.[userId]?.[0] : undefined)
)

export const selectCurrentCardId = createSelector(selectLiveSessionData, data => data?.currentCardId)

export const selectFacilitatorIds = createSelector(
  (state: RootState) => state.liveSessionFacilitators.facilitators,
  liveSessionToFacilitators => liveSessionToFacilitators
)

export const selectIsFacilitator = createSelector(
  selectFacilitatorIds,
  selectUser,
  (facilitatorIds, user) => user !== undefined && facilitatorIds.includes(user.uuid)
)

export const selectUserIsFacilitator = createSelector(selectFacilitatorIds, facilitatorIds =>
  _.memoize((userId: string | undefined) => (userId === undefined ? false : facilitatorIds.includes(userId)))
)

const selectUsersOrderedByActivity = createSelector(
  selectCurrentRoomParticipants,
  selectUserIdToLastSpokeMap,
  (participants, activityData) =>
    _.chain(participants)
      .orderBy(
        [
          p =>
            activityData[p.userId] !== undefined
              ? DateTime.fromISO(asNonNullable(activityData[p.userId])).toMillis()
              : -1,
          'userId',
        ],
        ['desc', 'asc']
      )
      .value()
)

const selectPinnedParticipants = createSelector(
  selectLiveSessionData,
  data => Object.keys(data?.pinnedParticipants ?? {}),
  {
    memoizeOptions: [
      {
        resultEqualityCheck: _.isEqual,
      },
    ],
    argsMemoizeOptions: [{ resultEqualityCheck: shallowEqual }],
  }
)

const selectParticipantsIncludingMeWithCameraOn = createAwarenessSelector(awareness =>
  awareness.filter(it => it.videoState === 'on').map(({ userId, agoraUID }) => ({ userId, agoraUID }))
)

const selectParticipantsIncludingMeWithMicrophoneOn = createAwarenessSelector(awareness =>
  awareness.filter(it => it.audioState === 'on').map(({ userId, agoraUID }) => ({ userId, agoraUID }))
)

const selectParticipantsOnStage = createSelector(
  [
    selectCurrentRoomParticipantsIncludingMe,
    selectAgoraUidToLastSpokeMap,
    selectPinnedParticipants,
    selectScreenShareParticipants,
    selectParticipantsIncludingMeWithCameraOn,
    selectParticipantsIncludingMeWithMicrophoneOn,
    (state: RootState, stageSize: number) => stageSize,
  ],
  (
    roomParticipants,
    agoraUidToLastSpokeMap,
    pinnedUserIds,
    selectScreenShareParticipants,
    participantsWithCameraOn,
    participantsWithMicrophoneOn,
    stageSize
  ) => {
    // When everyone can fit on the stage in a small call (<10), everyone should be on the stage
    if (roomParticipants.length <= stageSize && roomParticipants.length < 10) {
      return roomParticipants.map(({ agoraUID }) => ({ agoraUID }))
    }

    const agoraUidCameraOnSet = new Set(participantsWithCameraOn.map(participant => participant.agoraUID))
    const agoraUidMicrophoneOnSet = new Set(
      participantsWithMicrophoneOn.map(participant => participant.agoraUID)
    )
    const agoraUidIsScreensharingSet = new Set(
      selectScreenShareParticipants.map(participant => participant.agoraUID)
    )

    // Filter out users that don't have a reason to be on stage
    // - they have not spoken recently
    // - they don't have their camera on
    // - they don't have their microphone on
    const candidatesForStage = roomParticipants.filter(user => {
      const lastSpeakTime = agoraUidToLastSpokeMap[user.agoraUID]
      const hasSpokenLastTenSeconds =
        lastSpeakTime !== undefined && lastSpeakTime.diffNow().as('seconds') > -10
      if (hasSpokenLastTenSeconds) return true

      const hasCameraOn = agoraUidCameraOnSet.has(user.agoraUID)
      if (hasCameraOn) return true

      const hasMicrophoneOn = agoraUidMicrophoneOnSet.has(user.agoraUID)
      if (hasMicrophoneOn) return true

      return false
    })

    // Sort the candidates by importance order and fill the stage
    const usersOrdered = _.chain(candidatesForStage)
      .map(user => ({
        isPinned: pinnedUserIds.includes(user.userId),
        isScreenSharing: agoraUidIsScreensharingSet.has(user.agoraUID),
        lastActiveTime: agoraUidToLastSpokeMap[user.agoraUID]?.toMillis() ?? 0,
        cameraOn: agoraUidCameraOnSet.has(user.agoraUID),
        microphoneOn: agoraUidMicrophoneOnSet.has(user.agoraUID),
        agoraUID: user.agoraUID,
      }))
      .orderBy(
        ['isPinned', 'isScreenSharing', 'lastActiveTime', 'cameraOn', 'microphoneOn', 'agoraUID'],
        ['desc', 'desc', 'desc', 'desc', 'desc', 'desc']
      )
      .take(stageSize)
      .map(({ agoraUID }) => ({ agoraUID }))
      .value()

    return usersOrdered
  }
)

export const selectShouldUserBeOnStage = createSelector(
  [selectParticipantsOnStage, selectClientId],
  (participantsOnStage, agoraUID) =>
    agoraUID !== undefined
      ? participantsOnStage.some(({ agoraUID: publishingAgoraUID }) => publishingAgoraUID === agoraUID)
      : false
)

export type AugmentedParticipant = {
  type: 'augmented'
  userId: UserId
  agoraUID: string
  agoraScreenShareUID: string | undefined
  isPinned: boolean
  lastActive: number
  handRaisedTime: string | undefined
  isCurrentClient: boolean
  isOnStageWithAudio: boolean
  isOnStageWithVideo: boolean
}

/** Select all users in the same room and add metadata about activity and pin status etc. */
export const selectAugmentedParticipantsIncludingMe = createSelector(
  [
    selectCurrentRoomParticipantsIncludingMe,
    selectPinnedParticipants,
    selectLiveSessionRaisedHands,
    selectAgoraUidToLastSpokeMap,
    selectRemoteParticipants,
  ],
  (
    users,
    pinnedParticipants,
    handRaised,
    agoraUidToLastSpokeMap,
    remoteParticipants
  ): AugmentedParticipant[] => {
    const userIdToHandraisedMap = _.keyBy(handRaised, 'userId')
    const agoraUidsOnStageWithAudio = new Set(
      remoteParticipants.filter(it => it.isPublishingAudio).map(participant => participant.id)
    )
    const agoraUidsOnStageWithVideo = new Set(
      remoteParticipants.filter(it => it.isPublishingVideo).map(participant => participant.id)
    )

    return users.map(user => {
      const isPinned = pinnedParticipants.includes(user.userId)
      const userHandRaised = userIdToHandraisedMap[user.userId]
      const lastSpeakTime = agoraUidToLastSpokeMap[user.agoraUID]
      const handRaisedTime = userHandRaised?.timeStartedRaisingHand
      const isOnStageWithAudio = agoraUidsOnStageWithAudio.has(user.agoraUID)
      const isOnStageWithVideo = agoraUidsOnStageWithVideo.has(user.agoraUID)

      // We treat activity as both speaking and hand raising
      // this should allow new hand raises to move to the top
      // while still allowing new speakers to move move up if
      // they are more recent
      const lastActive =
        _.max([
          handRaisedTime !== undefined ? DateTime.fromISO(handRaisedTime).toMillis() : undefined,
          lastSpeakTime?.toMillis(),
        ]) ?? 0

      return {
        type: 'augmented',
        userId: user.userId,
        agoraUID: user.agoraUID,
        agoraScreenShareUID: user.agoraScreenShareUID,
        isPinned,
        lastActive,
        handRaisedTime,
        isCurrentClient: user.isCurrentClient,
        isOnStageWithAudio,
        isOnStageWithVideo,
      }
    })
  }
)

export const selectBreakoutSession = createSelector(selectLiveSessionData, data => data?.breakoutSession)

export const selectVideoCallMode = createSelector(
  selectLiveSessionData,
  data => data?.videoCallMode ?? 'main'
)

export const selectAllBreakoutRooms = createSelector(selectBreakoutSession, breakoutSession =>
  Object.values(breakoutSession?.breakoutRooms ?? {})
)

export const selectBreakoutRoom = createSelector(
  [selectBreakoutSession, (state, roomId) => roomId],
  (breakoutSession, roomId) =>
    Object.values(breakoutSession?.breakoutRooms ?? {}).find(room => room.id === roomId)
)

export const selectAssignedRoomIdForUser = createSelector(
  [selectAllBreakoutRooms, (state, userId: UserId) => userId],
  (allRooms, userId) => allRooms.find(room => room.participants.includes(userId))?.id
)

export const selectAssignedBreakoutRoom = createSelector(
  [selectVideoCallMode, selectBreakoutSession, selectUserId],
  (videoCallMode, breakoutSession, userId) => {
    if (videoCallMode !== 'breakout' || userId === undefined || breakoutSession === undefined)
      return undefined
    return Object.values(breakoutSession.breakoutRooms)
      .filter(room => room.participants.includes(userId))
      .map(room => ({ ...room }))[0]
  }
)

export const selectTimerEndTime = createSelector(selectLiveSessionData, data => {
  const timerEndTime = data?.timer?.endTime
  if (timerEndTime === undefined) return undefined

  return DateTime.fromISO(timerEndTime)
})

const selectAllWritingToReflection = createAwarenessSelector(awareness =>
  _.chain(awareness)
    .filter(({ isCurrentClient }) => !isCurrentClient)
    .filter(
      ({ isWritingToReflection, isWritingToReflectionAnonymous }) =>
        isWritingToReflection !== undefined || isWritingToReflectionAnonymous !== undefined
    )
    .map(({ userId, isWritingToReflection, isWritingToReflectionAnonymous }) =>
      userId !== undefined ? { userId, isWritingToReflection, isWritingToReflectionAnonymous } : undefined
    )
    .filter(isDefined)
    .sortBy('userId')
    .value()
)

export const selectWritingToReflection = createSelector(selectAllWritingToReflection, participants =>
  _.memoize((reflectionId: string): { userId: UserId; anonymous: boolean }[] =>
    participants
      .filter(
        ({ isWritingToReflection, isWritingToReflectionAnonymous }) =>
          isWritingToReflection === reflectionId || isWritingToReflectionAnonymous === reflectionId
      )
      .map(({ userId, isWritingToReflectionAnonymous }) => ({
        userId,
        anonymous: isWritingToReflectionAnonymous === reflectionId,
      }))
  )
)

export const selectAllSessionLearners = createSelector(
  [selectAllSessionParticipantsIncludingMe, selectFacilitatorIds],
  (participants, facilitatorIds) => participants.filter(({ userId }) => !facilitatorIds.includes(userId))
)

/**
 * @deprecated use useLiveSessionState hook instead, that takes the backend state into account as well
 */
export const selectSessionState = createSelector(selectLiveSessionData, data => data?.sessionState)

const selectRecordings = createSelector(selectLiveSessionData, data =>
  data?.recordings !== undefined ? data.recordings : undefined
)

export const selectMyCurrentRecordingId = createSelector(
  selectLiveSessionLocalAwarenessState,
  currentClient => currentClient.isRecordingWithId
)

export const selectIsRecording = createSelector(
  selectMyCurrentRecordingId,
  myCurrentRecordingId => myCurrentRecordingId !== undefined
)

const selectOthersRecordings = createSelector(selectRecordings, selectUserId, (recordings, userId) =>
  Object.entries(recordings ?? {})
    .filter(([, recording]) => recording.userId !== userId)
    .map(([, recording]) => recording)
)

export const selectOtherRecordingsInProgress = createSelector(
  selectOthersRecordings,
  selectAllCurrentRecordingIds,
  (othersRecordings, currentRecordingIds) =>
    othersRecordings.filter(recording => currentRecordingIds.includes(recording.id))
)

export const selectIsSomeoneRecording = createSelector(
  selectOtherRecordingsInProgress,
  othersRecordings => othersRecordings.length > 0
)

export const selectPinnedParticipantsLength = createSelector(
  selectPinnedParticipants,
  participants => participants.length
)

export const selectCanPinMore = createSelector(selectPinnedParticipantsLength, length => length < 4)

export const selectPinnedParticipantsInCall = createSelector(
  selectPinnedParticipants,
  selectAllSessionParticipants,
  (pinnedParticipants, allParticipants) =>
    allParticipants.filter(participant => pinnedParticipants.includes(participant.userId))
)

export const selectIsSomeonePinned = createSelector(
  selectPinnedParticipants,
  selectAllSessionParticipantsIncludingMe,
  (pinnedParticipants, allParticipants) =>
    allParticipants.some(participant => pinnedParticipants.includes(participant.userId))
)

export const selectIsEveryonePinned = createSelector(
  selectPinnedParticipants,
  selectAllSessionParticipantsIncludingMe,
  (pinnedParticipants, allParticipants) => {
    return (
      allParticipants.filter(participant => !pinnedParticipants.includes(participant.userId)).length === 0
    )
  }
)

export const selectIsParticipantPinned = createSelector(selectPinnedParticipants, pinnedParticipants =>
  _.memoize((userId: string | undefined): boolean =>
    userId === undefined ? false : pinnedParticipants.includes(userId)
  )
)

export const selectIsCurrentUserPinned = createSelector(
  selectUser,
  selectIsParticipantPinned,
  (user, isParticipantPinned) => isParticipantPinned(user?.uuid)
)

export const selectActiveParticipantsWithVideo = createSelector(
  [selectUsersOrderedByActivity, selectParticipantsIncludingMeWithCameraOn],
  (users, usersWithCameraOn) =>
    _.memoize(
      (number: number) => {
        const shownUsers = _.chain(users)
          .filter(u => usersWithCameraOn.some(cameraOn => cameraOn.agoraUID === u.agoraUID))
          .take(number)
          .map(({ agoraUID, userId }) => ({ agoraUID, userId }))
          .value()

        return shownUsers
      },
      (...args) => args.join('_')
    )
)

// Live quiz
export const selectLiveQuizFromSession = createSelector(selectLiveSessionData, data => {
  return data?.liveQuiz
})

export const selectLiveQuizStateById = createSelector(
  [selectLiveQuizFromSession, (selectLiveSessionData, assessmentFileId: FileId) => assessmentFileId],
  (liveQuiz, assessmentFileId) => liveQuiz?.[assessmentFileId]
)
