import { useRef } from 'react'
import { useIsMobile } from 'sierra-client/state/browser/selectors'
import { FCC } from 'sierra-client/types'
import { Text, View } from 'sierra-ui/primitives'
import { spacing, token } from 'sierra-ui/theming'
import { fonts } from 'sierra-ui/theming/fonts'
import styled, { css } from 'styled-components'

type ButtonProps = {
  onPrimaryClick?: () => void
}

export const folderButtonCss = css`
  font-size: small;
  background-color: ${token('surface/soft')};
  font-weight: ${fonts.weight.medium};
  border: 2px solid transparent;
  height: 2rem;
  padding: ${spacing['4']} 0;
`

const FolderButton = styled(View)`
  position: relative;
  ${folderButtonCss}
  opacity: 0;
  transition: opacity 150ms cubic-bezier(0.77, 0, 0.175, 1);
  cursor: pointer;

  &:hover {
    opacity: 1;
    transition: opacity 150ms cubic-bezier(0.77, 0, 0.175, 1);
  }
`

export const HiddenAddCardButton: FCC<ButtonProps> = ({ children, onPrimaryClick }) => {
  const nodeRef = useRef<HTMLDivElement>(null)
  const isMobile = useIsMobile()

  return (
    <>
      {onPrimaryClick === undefined || isMobile ? (
        <></>
      ) : (
        <FolderButton ref={nodeRef} radius='regular' onClick={onPrimaryClick} justifyContent='center'>
          <Text>{children}</Text>
        </FolderButton>
      )}
    </>
  )
}
