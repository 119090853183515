import { getGlobalRouter } from 'sierra-client/router'

export type ReplaceCurrentLocationFunc = (masked?: boolean) => Promise<void>

function createCurrentLocationReplacer(to: string): ReplaceCurrentLocationFunc {
  return masked => {
    const mask = masked !== false ? { to: window.location.pathname + window.location.search } : undefined
    return getGlobalRouter().navigate({ to, mask, replace: true })
  }
}

/** Redirects to the "Access denied" error page without changing the current URL. */
export const accessDeniedRedirect = createCurrentLocationReplacer('/access-denied')

/** Redirects to the "Content not found" error page without changing the current URL. */
export const contentNotFoundRedirect = createCurrentLocationReplacer('/content-not-found')

/** Redirects to the "404 Not found" error page without changing the current URL. */
export const notFound404Redirect = createCurrentLocationReplacer('/404')
