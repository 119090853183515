import { useAtom } from 'jotai/index'
import { collapsableSidebarBooleanStateAtom } from 'sierra-client/features/collapsable-sidebar/atoms'
import { useStableFunction } from 'sierra-client/hooks/use-stable-function'
import { useIsMobile } from 'sierra-client/state/browser/selectors'

type ToggleMobileGlobalSidebar = {
  isOpen: boolean
  toggle: () => void
  close: () => void
}

export const useToggleMobileGlobalSidebar = (): ToggleMobileGlobalSidebar => {
  const isMobile = useIsMobile()
  const [isOpen, setIsOpen] = useAtom(collapsableSidebarBooleanStateAtom)

  const toggle = useStableFunction(() => {
    if (!isMobile) {
      return
    }
    setIsOpen(!isOpen)
  })

  const close = useStableFunction(() => {
    if (!isMobile) {
      return
    }

    setIsOpen(false)
  })

  return {
    isOpen,
    toggle,
    close,
  }
}
