import { motion } from 'framer-motion'
import { useAtom, useAtomValue } from 'jotai'
import React, { MutableRefObject, useEffect, useState } from 'react'
import { manageExpandedAtom } from 'sierra-client/features/collapsable-sidebar/hooks/use-expand'
import { useTranslation } from 'sierra-client/hooks/use-translation'
import { FCC } from 'sierra-client/types'
import { scrollViewStyles } from 'sierra-ui/primitives'
import { token, zIndex } from 'sierra-ui/theming'
import styled from 'styled-components'
import {
  bottomContainerVariants,
  innerAboveVariants,
  innerBelowVariants,
  sidebarContentsVariants,
} from './animation-config'
import {
  collapsableSidebarStateAtom,
  initialCollapsableSidebarStateAtom,
  scrollbarWidthAtom,
  sidebarHasNestedMenuOpenAtom,
} from './atoms'
import { Header } from './items/header'

import { Fade } from 'sierra-client/features/collapsable-sidebar/items/shared/fade'
import { CollapsableSidebarState } from 'sierra-client/features/collapsable-sidebar/types'
import { useToggle } from 'sierra-client/hooks/use-toggle'
import { useOnChanged } from 'sierra-ui/utils'
import { spacingConfig } from './items/spacing-config'

const Padding = styled.div`
  position: relative; /* Hovered sidebar inside */

  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  align-items: stretch;

  padding: ${spacingConfig.outerPadding}px;

  z-index: ${zIndex.MENU};
  height: 100%;
  flex: 0 0 auto;
`

const InnerBelow = styled(motion.div)`
  flex: 1; /* Full height */
`

/* Handles scrolling */
const InnerAbove = styled(motion.div)`
  position: absolute;
  top: ${spacingConfig.outerPadding}px;
  left: ${spacingConfig.outerPadding}px;

  height: calc(100% - 2 * ${spacingConfig.outerPadding}px);
  background-color: ${token('surface/default')};
  border-radius: 12px;
  padding: 0 0 36px 0;

  isolation: isolate; /* We create a new stacking context so that the TopContainer sits on top of the SidebarContent */

  ${scrollViewStyles}
`

const BottomContainer = styled(motion.div)`
  position: absolute;
  bottom: ${spacingConfig.outerPadding}px;
  left: ${spacingConfig.outerPadding}px;
  right: 0;
  padding: 0 ${spacingConfig.innerPadding}px ${spacingConfig.innerPadding}px ${spacingConfig.innerPadding}px;
  background-color: ${token('surface/default')};
`
const TopContainer = styled(motion.div)<{ $showBorder: boolean }>`
  position: sticky;
  top: 0;
  left: 0;
  right: 0;
  padding: ${spacingConfig.innerPadding}px ${spacingConfig.innerPadding}px 0 ${spacingConfig.innerPadding}px;

  background-color: ${token('surface/default')};
  z-index: 1; /* See InnerAbove */
  transition: border-color 0.2s cubic-bezier(0.25, 0.1, 0.25, 1);
  border-bottom: 1px solid ${p => (p.$showBorder ? token('border/default') : 'transparent')};
`

/* Animated to a constant width to avoid reflow due to the scrollbar gutter*/
const SidebarContent = styled(motion.nav)`
  overflow: hidden;
  padding-left: ${spacingConfig.innerPadding}px;
  padding-right: ${spacingConfig.innerPadding}px;
  padding-bottom: ${spacingConfig.innerPadding}px;
  z-index: 0; /* See InnerAbove */
  isolation: isolate;
`

const BottomContainerSpacer = styled.span<{ height: number }>`
  display: block;
  height: ${p => p.height}px;
`

type SidebarProps = {
  containerRef: MutableRefObject<HTMLDivElement | null>
  bottomComponent: React.ReactNode | undefined
}

// Detects if the mouse is wtihin the sidebar width
const HoverDetector: React.FC<{
  sidebarState: CollapsableSidebarState | undefined
  setIsHovered: (isHovered: boolean) => void
}> = ({ sidebarState, setIsHovered }) => {
  const sidebarWidth = sidebarState !== undefined ? sidebarContentsVariants[sidebarState].width : 0

  // If the sidebar is hovered, we extend the width of the hover zone slightly so that pressing
  // buttons at the edge of the sidebar is easier
  const width = sidebarState === 'hovered' ? sidebarWidth + 50 : 0

  useEffect(() => {
    function handleMouseMove(event: MouseEvent): void {
      setIsHovered(event.clientX < width)
    }

    document.addEventListener('mousemove', handleMouseMove)

    return () => {
      document.removeEventListener('mousemove', handleMouseMove)
    }
  }, [sidebarWidth, setIsHovered, width])

  return null
}

export const CollapsableSidebar: FCC<SidebarProps> = ({ containerRef, bottomComponent, children }) => {
  const [sidebarState, setSidebarState] = useAtom(collapsableSidebarStateAtom)
  const initialState = useAtomValue(initialCollapsableSidebarStateAtom)
  const scrollbarWidth = useAtomValue(scrollbarWidthAtom)
  const [spacerSize, setSpacerSize] = useState(0)
  const isManageExpanded = useAtomValue(manageExpandedAtom)
  const { t } = useTranslation()

  // All of this is to have mouse events that operate based on the browser DOM instead of the React virtual DOM,
  // since this container contains portaled elements that live somewhere else in the react DOM
  const [hoverElement, setHoverElement] = useState<HTMLDivElement | null>(null)

  const sidebarHasNestedMenuOpen = useAtomValue(sidebarHasNestedMenuOpenAtom)
  const [hovered, { off: handleMouseLeave, on: handleMouseEnter }] = useToggle(false)

  const [hoverDetectorHovered, setHoverDetectorHovered] = useState(false)

  const shouldBeHovered = sidebarHasNestedMenuOpen || hovered || hoverDetectorHovered
  useOnChanged((_old, newShouldBeHovered) => {
    if (sidebarState !== 'sticky') {
      setSidebarState(newShouldBeHovered ? 'hovered' : 'collapsed')
    }
  }, shouldBeHovered)

  useEffect(() => {
    if (hoverElement === null) return

    hoverElement.addEventListener('mouseenter', handleMouseEnter)
    hoverElement.addEventListener('mouseleave', handleMouseLeave)

    return () => {
      hoverElement.removeEventListener('mouseenter', handleMouseEnter)
      hoverElement.removeEventListener('mouseleave', handleMouseLeave)
    }
  }, [handleMouseEnter, handleMouseLeave, hoverElement])

  return (
    <Padding
      ref={setHoverElement}
      role='navigation'
      aria-label={t('sidebar.main-label')}
      aria-expanded={sidebarState !== 'collapsed'}
    >
      <HoverDetector sidebarState={sidebarState} setIsHovered={setHoverDetectorHovered} />

      <InnerBelow variants={innerBelowVariants} initial={initialState} animate={sidebarState} />
      <InnerAbove
        ref={containerRef}
        variants={innerAboveVariants}
        initial={initialState}
        animate={sidebarState}
        id='tour-global-sidebar'
      >
        <TopContainer
          custom={Math.max(spacingConfig.innerPadding, scrollbarWidth ?? 0)}
          variants={bottomContainerVariants}
          initial={initialState}
          animate={sidebarState}
          $showBorder={isManageExpanded}
        >
          <Fade direction='down' />
          <div
            // eslint-disable-next-line react/forbid-dom-props
            style={{ width: '100%', overflow: 'hidden' }}
          >
            <Header hoverElement={containerRef} />
          </div>
        </TopContainer>
        <SidebarContent
          variants={sidebarContentsVariants}
          custom={Math.max(spacingConfig.innerPadding, scrollbarWidth ?? 0)}
          initial={initialState}
          animate={sidebarState}
        >
          {children}
        </SidebarContent>
        {bottomComponent !== undefined && <BottomContainerSpacer height={spacerSize} />}
      </InnerAbove>
      <BottomContainer
        ref={container => setSpacerSize((container?.getBoundingClientRect().height ?? 0) + 20)}
        custom={Math.max(spacingConfig.innerPadding, scrollbarWidth ?? 0)}
        variants={bottomContainerVariants}
        initial={initialState}
        animate={sidebarState}
      >
        <Fade direction='up' />
        <div style={{ width: '100%', overflow: 'hidden' }}>{bottomComponent}</div>
      </BottomContainer>
    </Padding>
  )
}
