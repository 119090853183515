import { HomeCourseImpressionProps } from 'sierra-client/core/logging/course/logger'
import { Logger } from 'sierra-client/core/logging/logger'

export const pathCourseImpression = Logger.trackLoggerWithExtra<
  HomeCourseImpressionProps,
  HomeCourseImpressionProps
>('path_course_impression', input => input)

type CreatedPathProps = {
  pathId: string
  pathTitle: string
}

export const pathCreated = Logger.trackLogger<CreatedPathProps>('path_created')
