import React from 'react'
import { useTranslation } from 'sierra-client/hooks/use-translation'
import { labelToString } from 'sierra-client/lib/filter/components/common'
import {
  UserCustomAttributeDomainRep,
  UserCustomAttributeDomainRepChoices,
  UserCustomAttributeDomainRepType,
  UserCustomAttributeDomainRepWithParentChoices,
} from 'sierra-domain/user-attributes/user-invitation-domain-rep'
import { assertNever } from 'sierra-domain/utils'
import { Icon, Tooltip } from 'sierra-ui/components'
import { Text, View } from 'sierra-ui/primitives'
import styled from 'styled-components'

export const AttributeRowList = styled(View).attrs({
  direction: 'column',
  gap: '16',
  paddingTop: '4',
  paddingBottom: '16',
})``

export type InputSwitch = React.FC<{
  domainRep: UserCustomAttributeDomainRep
}>

const Row = styled(View).attrs({ alignItems: 'center', justifyContent: 'space-between' })``

const Width = styled(View)`
  width: 240px;
`

const Optional: React.FC = () => {
  const { t } = useTranslation()
  return (
    <Text bold color='foreground/muted'>
      {t('dictionary.mandatory')}
    </Text>
  )
}

const Label = styled(View).attrs({
  direction: 'row',
  justifyContent: 'flex-start',
  alignItems: 'center',
  gap: '8',
})``

export const Name = styled(Text).attrs({ bold: true })``

export const AttributeRow: React.FC<{
  id?: string | undefined
  label: string
  isMandatory?: boolean
  showWarning?: boolean
  warningTooltip?: string
  children: React.ReactNode | React.ReactNode[]
}> = ({ id, label, isMandatory, showWarning, warningTooltip, children }) => (
  <Row id={id}>
    <Label>
      <Name>{label}</Name>
      {isMandatory === true && <Optional />}
    </Label>
    <View>
      {showWarning === true && (
        <Tooltip title={warningTooltip ?? ''}>
          <Icon iconId='warning--filled' color='yellowBright' />
        </Tooltip>
      )}
      <Width>{children}</Width>
    </View>
  </Row>
)

export const CustomAttributeRow: React.FC<{
  id?: string | undefined
  InputSwitch: InputSwitch
  domainRep: UserCustomAttributeDomainRep
  showWarning: boolean
}> = ({ id, InputSwitch, domainRep, showWarning }) => {
  const { dynamicT } = useTranslation()

  return (
    <AttributeRow
      id={id}
      label={labelToString(domainRep.label, dynamicT)}
      showWarning={showWarning}
      isMandatory={domainRep.isMandatory}
    >
      <InputSwitch key={domainRep.ref.key} domainRep={domainRep} />
    </AttributeRow>
  )
}

export const CommonInputSwitch: React.FC<{
  domainRep: UserCustomAttributeDomainRep
  ChoicesRenderer: React.FC<{ domainRep: UserCustomAttributeDomainRepChoices }>
  TypeOfRenderer: React.FC<{ domainRep: UserCustomAttributeDomainRepType }>
  WithParentChoicesRenderer: React.FC<{ domainRep: UserCustomAttributeDomainRepWithParentChoices }>
}> = ({ domainRep, ChoicesRenderer, TypeOfRenderer, WithParentChoicesRenderer }) => {
  switch (domainRep.domain.type) {
    case 'domain.choices':
      return <ChoicesRenderer domainRep={domainRep as UserCustomAttributeDomainRepChoices} />
    case 'domain.type':
      return <TypeOfRenderer domainRep={domainRep as UserCustomAttributeDomainRepType} />
    case 'domain.auto-complete':
    case 'domain.phone-number':
      return <></>
    case 'domain.with-parent-choices':
      return (
        <WithParentChoicesRenderer domainRep={domainRep as UserCustomAttributeDomainRepWithParentChoices} />
      )
    default:
      assertNever(domainRep.domain)
  }
}
