import React from 'react'
import { newImageData } from 'sierra-client/state/flexible-content/factory'
import { useSelector } from 'sierra-client/state/hooks'
import { selectUser } from 'sierra-client/state/user/user-selector'
import { useCreatePageContext } from 'sierra-client/views/flexible-content/create-page-context'
import { usePdfData } from 'sierra-client/views/flexible-content/use-pdf-convert'
import { MediaUploader } from 'sierra-client/views/v3-author/common/media-uploader/media-uploader'
import { AssetContext } from 'sierra-domain/asset-context'
import { CreateOperation, apply } from 'sierra-domain/editor/operations'
import { createFile } from 'sierra-domain/flexible-content/types'
import { assertIsNonNullable } from 'sierra-domain/utils'
import { Modal } from 'sierra-ui/components'

type ImportModalProps = {
  open: boolean
  onClose: () => void
  assetContext: AssetContext
}

export const ImportModal: React.FC<ImportModalProps> = ({ open, onClose, assetContext }) => {
  const { operationState } = useCreatePageContext()
  const { uploadPdfFile } = usePdfData()
  const user = useSelector(selectUser)

  if (!user) throw new Error('User not found')

  return (
    <Modal open={open} onClose={onClose} disableScrollbarGutter>
      <MediaUploader
        iconId={'document--import'}
        accept={['application/pdf']}
        assetContext={assetContext}
        uploadMedia={uploadPdfFile}
        onUploaded={({ data }, filename) => {
          assertIsNonNullable(filename)
          const addFileOperations = data.imagePaths.map(
            (image, index): CreateOperation => ({
              folderId: 'folder:root',
              type: 'add-file',
              file: createFile({
                title: `Page ${index + 1}`,
                data: newImageData(image),
                metadata: {
                  createdBy: user.uuid,
                  createdAt: new Date().toISOString(),
                  createdFromAction: { type: 'pdf-import', filename: filename },
                },
              }),
              destination: { type: 'add-at-end' },
            })
          )
          apply(operationState, ...addFileOperations)

          onClose()
        }}
      />
    </Modal>
  )
}
