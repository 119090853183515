import { createRef } from 'react'
import ReCAPTCHA from 'react-google-recaptcha'

export const useRecaptcha = ({
  errorCallback,
}: {
  errorCallback: (_: string) => void
}): {
  getRecaptcha: () => Promise<string | null>
  recaptchaRef: React.RefObject<ReCAPTCHA>
} => {
  const recaptchaRef = createRef<ReCAPTCHA>()

  const getRecaptcha = async (): Promise<string | null> => {
    if (!recaptchaRef.current) {
      errorCallback('Error getting reCAPTCHA reference.')
      return null
    }

    const recaptcha = await recaptchaRef.current.executeAsync()

    // The ReCAPTCHA widget causes errors if it is unmounted from the DOM, but for some
    // reason, reset seems to avoid these issue. The issue in question is
    // https://github.com/dozoisch/react-google-recaptcha/issues/129
    recaptchaRef.current.reset()

    if (recaptcha === null) {
      errorCallback('Error getting reCAPTCHA response.')
      return null
    }

    return recaptcha
  }

  return { recaptchaRef, getRecaptcha }
}

export const RECAPTCHA_SITE_KEY = '6LeP07saAAAAALrWom3RTQKSD-XZNewJUnFyNaHQ'
