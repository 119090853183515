import _ from 'lodash'
import React, { useEffect, useMemo, useState } from 'react'
import { CustomInterfaceThemeProvider } from 'sierra-client/components/common/custom-interface-theme-provider'
import { Message } from 'sierra-client/components/common/message'
import { Modal as LegacyModal } from 'sierra-client/components/common/modals/modal'
import { PageTitle } from 'sierra-client/components/common/page-title'
import { useTranslation } from 'sierra-client/hooks/use-translation'
import { FileButtonInput } from 'sierra-client/views/manage/settings/components/file-button-input'
import {
  BrowserPreview,
  LogoUploadTooltip,
  SidebarPreview,
} from 'sierra-client/views/manage/settings/components/logo-upload/logo-upload-tooltip'
import { Logotype } from 'sierra-client/views/manage/settings/components/logo-upload/logotype'
import { MediaLibrary } from 'sierra-client/views/manage/settings/components/media-library'
import {
  DEFAULT_CUSTOM_THEME,
  DEFAULT_CUSTOM_THEMES,
  DEFAULT_FONTS,
  DEFAULT_ORGANIZATION_SETTINGS,
  useOrganizationSettings,
} from 'sierra-client/views/manage/settings/use-organization-settings'
import { PageHeader } from 'sierra-client/views/settings/components/page-header'
import { SettingsSection } from 'sierra-client/views/settings/components/settings-section'
import {
  Brand,
  CustomTheme,
  CustomThemes,
  FontVariant,
  FontVariantFilename,
  InterfaceTheme,
  OrganizationSettings,
} from 'sierra-domain/api/private'
import { color, dynamicColor } from 'sierra-ui/color'
import { ColorName } from 'sierra-ui/color/types'
import { ColorInput, FormElement, Icon, Input, Modal } from 'sierra-ui/components'
import { Button, Heading, IconButton, Spacer, Text, View } from 'sierra-ui/primitives'
import { palette, spacing, token } from 'sierra-ui/theming'
import { legacyColors } from 'sierra-ui/theming/legacy-colors'
import {
  PresetThemeName,
  getCustomThemeIdFromBackendId,
  isBaseColor,
  presetThemes,
} from 'sierra-ui/theming/legacy-theme'
import styled, { css } from 'styled-components'

const ThemeGrid = styled.div`
  display: grid;
  grid-template-columns: repeat(4, 7.5rem);
  grid-auto-rows: 7.5rem;
  gap: 1rem;
`

const CustomThemeGrid = styled.div`
  display: grid;
  grid-template-columns: repeat(4, 7.5rem);
  grid-auto-rows: 7.5rem;
  gap: 1rem;
`

const ThemeItemBase = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-items: flex-start;
  padding: 0.75rem;
  border-radius: ${spacing['4']};
  cursor: pointer;
`

const ThemeItem = styled(ThemeItemBase)<{ $bgColor?: ColorName }>`
  ${p =>
    p.$bgColor !== undefined &&
    css`
      background-color: ${p.theme.color[p.$bgColor]};
    `}

  ${p =>
    p.$bgColor === 'white' &&
    css`
      box-shadow: 0 0 1px inset rgba(0, 0, 0, 0.4);
    `}
`
const InterfaceThemeItem = styled(ThemeItemBase)<{ $bgColor: string }>`
  background-color: ${p => p.$bgColor};
`

const CustomThemeItem = styled(ThemeItem)<{ $customBgColor: string }>`
  background-color: ${p => p.$customBgColor};
`

const CreateThemeItem = styled(ThemeItemBase)`
  box-shadow: 0 0 1px inset rgba(0, 0, 0, 0.4);
  background-color: ${palette.primitives.white};
  background-image: repeating-linear-gradient(
      45deg,
      ${palette.grey[5]} 25%,
      transparent 25%,
      transparent 75%,
      ${palette.grey[5]} 75%,
      ${palette.grey[5]}
    ),
    repeating-linear-gradient(
      45deg,
      ${palette.grey[5]} 25%,
      ${palette.primitives.white} 25%,
      ${palette.primitives.white} 75%,
      ${palette.grey[5]} 75%,
      ${palette.grey[5]}
    );
  background-position:
    0 0,
    10px 10px;
  background-size: 20px 20px;
`

const FontUpload = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 1rem 1.5rem;
  border: 1px solid ${token('border/default')};
  border-radius: ${p => p.theme.borderRadius['size-8']};
  background-color: ${token('surface/soft')};
`

const InfoContainer = styled.div`
  display: flex;
  align-items: center;
`

const Line = styled.div`
  height: 1rem;
  width: 1px;
  background-color: ${palette.grey[5]};
`

const ThemeModal = styled(LegacyModal)``

const ModalThemeArea = styled.div<{ $bgColor: string }>`
  padding: 2rem;
  border-radius: 0.5rem;
  background-color: ${p => (p.$bgColor !== '' ? p.$bgColor : palette.primitives.white)};
  border: 1px solid ${p => (p.$bgColor !== '' ? p.$bgColor : token('border/default'))};
`
const InterfaceThemeModalPreviewArea = styled.div`
  padding: 2rem;
  border-radius: 0.5rem;
  background-color: ${palette.grey[2]};
`

const ThemeName = styled(Text)`
  text-transform: capitalize;
`

const CustomThemeText = styled(Text)<{ $color: string }>`
  color: ${p => p.$color};
`

const CustomThemeActions = styled.div`
  display: flex;
  justify-content: space-between;
  width: 100%;
  opacity: 0.6;
`

const ThemeHeading = styled(Heading)<{ $color: string }>`
  color: ${p => (p.$color !== '' ? p.$color : p.theme.color.grey10)};
`

const ThemeText = styled(Text)<{ $color: string }>`
  color: ${p => (p.$color !== '' ? p.$color : p.theme.color.grey10)};
`

const ThemeButton = styled.div<{ $bgColor: string }>`
  display: inline-flex;
  padding: 0.75rem 0.875rem;
  border-radius: 1rem;
  background-color: ${p => (p.$bgColor !== '' ? p.$bgColor : p.theme.color.grey10)};
`

const TabsPreviewHolder = styled.div`
  display: flex;
  width: fit-content;
  justify-content: center;
  gap: 0.5rem;
  padding: 0.75rem 0.85rem;
  margin: auto;
  border-radius: 0.5rem;
  background-color: white;
`

const Tab = styled.div<{ $bgColor: string }>`
  width: fit-content;
  background-color: ${p => p.$bgColor};
  padding: 0.5rem 0.75rem;
  border-radius: 6px;
`

const CustomTab = styled.div<{ $bgColor: string }>`
  width: fit-content;
  background-color: ${p => p.$bgColor};
  padding: 0.5rem 0.75rem;
  border-radius: 6px;
`

const PrimaryButton = styled.div<{ $bgColor: string }>`
  width: fit-content;
  padding: 0.3125rem 0.875rem;
  border-radius: 1rem;
  background-color: ${p => (isBaseColor(p.$bgColor) ? p.theme.color[p.$bgColor] : p.$bgColor)};
  margin: auto;
`

const ThemeButtonBox = styled.div<{ $bgColor: string }>`
  width: 2rem;
  height: 0.5rem;
  background-color: ${p => (p.$bgColor !== '' ? p.$bgColor : palette.primitives.white)};
`

const InputGrid = styled.div`
  display: grid;
  grid-template-columns: 1fr 1fr;
  column-gap: 1.5rem;
`

const RightAlignContainer = styled.div`
  display: flex;
  gap: 0.5rem;
  justify-content: end;
`

const SpaceBetweenContainer = styled.div`
  display: flex;
  justify-content: space-between;
  gap: 0.5rem;
`

const ModalActionsContainer = styled.div`
  display: flex;
  width: fit-content;
  gap: 0.5rem;
`

const StickySave = styled.div`
  position: sticky;
  bottom: 0;
  padding: 3rem 0 2rem 0;
  background-image: linear-gradient(transparent 25%, ${token('surface/default')});
  display: flex;
  flex-direction: column;
  align-items: flex-end;
`

const Divider = styled.div`
  height: 1px;
  width: 100%;
  background-color: ${token('border/strong')};
  margin: ${spacing['16']} 0;
`

const FontInfo = ({ fileName, onRemove }: { fileName: string | null; onRemove: () => void }): JSX.Element => {
  return (
    <InfoContainer>
      <Text color='LEGACY_DEFAULT_TEXT_COLOR_REPLACE_ASAP' size='small'>
        {fileName}
      </Text>
      <Spacer />
      <IconButton variant='transparent' onClick={onRemove} iconId='trash-can' />
      <Spacer />
      <Line />
      <Spacer />
      <Icon iconId='checkmark' size='size-16' color={color('#22AC00')} />
    </InfoContainer>
  )
}

const useCommaSeparatedList = (items: string[]): string => {
  const { lang } = useTranslation()

  const parts = useMemo(() => {
    return new Intl.ListFormat(lang, { style: 'long', type: 'disjunction' }).formatToParts(items)
  }, [items, lang])

  return parts.map(part => part.value).join('')
}

const SquaredLogoHelpText: React.FC = () => {
  const { t } = useTranslation()
  const formatList = useCommaSeparatedList(['PNG', 'SVG'])
  const formatHelpText = t('dictionary.squared') + ' ' + formatList
  return (
    <View gap='none'>
      <Text align='center' size='micro' color='foreground/muted'>
        {formatHelpText}
      </Text>
      <Icon iconId='radio-button--dot' size='size-10' color='foreground/muted' />
      <Text align='center' size='micro' color='foreground/muted'>
        {t('manage.settings.logotype-squared.size')}
      </Text>
    </View>
  )
}

const WideLogoHelpText: React.FC = () => {
  const { t } = useTranslation()
  const formatList = useCommaSeparatedList(['PNG', 'SVG'])

  return (
    <View gap='none'>
      <Text align='center' size='micro' color='foreground/muted'>
        {formatList}
      </Text>
      <Icon iconId='radio-button--dot' size='size-10' color='foreground/muted' />
      <Text align='center' size='micro' color='foreground/muted'>
        {t('manage.settings.logotype-wide.size')}
      </Text>
    </View>
  )
}

const FavIconHelpText: React.FC = () => {
  const formatList = useCommaSeparatedList(['PNG', 'GIF', 'ICO'])
  const size = '32×32px'

  return (
    <View gap='none'>
      <Text align='center' size='micro' color='foreground/muted'>
        {formatList}
      </Text>
      <Icon iconId='radio-button--dot' size='size-10' color='foreground/muted' />
      <Text align='center' size='micro' color='foreground/muted'>
        {size}
      </Text>
    </View>
  )
}

export const SettingsBrandingView: React.FC<unknown> = () => {
  const { t } = useTranslation()
  const { settings, updateSettings } = useOrganizationSettings()

  const [themeModalOpen, setThemeModalOpen] = useState<boolean>(false)
  const [interfaceThemeModalOpen, setInterfaceThemeModalOpen] = useState<boolean>(false)

  const [organizationDataForm, setOrganizationDataForm] = useState<OrganizationSettings>(
    DEFAULT_ORGANIZATION_SETTINGS
  )

  const [customThemes, setCustomThemes] = useState<CustomThemes>(DEFAULT_CUSTOM_THEMES)
  const [currentThemeKey, setCurrentThemeKey] = useState<keyof CustomThemes>('customTheme1')
  const [currentCustomTheme, setCurrentCustomTheme] = useState<CustomTheme>(DEFAULT_CUSTOM_THEME)
  const [bgError, setBgError] = useState<boolean>(false)
  const [colorError, setColorError] = useState<boolean>(false)
  const [textColorError, setTextColorError] = useState<boolean>(false)
  const [tabColorError, setTabColorError] = useState<boolean>(false)
  const [nameError, setNameError] = useState<boolean>(false)

  const emptyCustomThemes = _.flatMap<CustomThemes, keyof CustomThemes>(customThemes, (theme, themeName) =>
    theme === null ? [themeName as keyof CustomThemes] : []
  )
  const defaultTheme = organizationDataForm.brand.defaultTheme?.name ?? 'white'

  const defaultInterfaceTheme: InterfaceTheme = {
    buttonBackgroundColor: legacyColors['blueBright'],
    buttonTextColor: legacyColors['white'],
    tabColor: legacyColors['blueBright'],
  }

  const interfaceTheme: InterfaceTheme = organizationDataForm.brand.interfaceTheme ?? defaultInterfaceTheme

  const [currentInterfaceTheme, setCurrentInterfaceTheme] = useState<InterfaceTheme>(interfaceTheme)

  // initialise form values
  useEffect(() => {
    setOrganizationDataForm(settings)
    if (Object.values(settings.brand.customThemes).every(x => x === null)) return
    setCustomThemes(settings.brand.customThemes)
  }, [settings])

  // Brand change
  const handleBrandChange = <T extends keyof Brand, R extends Brand[T]>(field: T, value: R): void => {
    setOrganizationDataForm(organizationDataForm => ({
      ...organizationDataForm,
      brand: {
        ...organizationDataForm.brand,
        [field]: value,
      },
    }))
  }

  const handleCurrentCustomThemeChange = <T extends keyof CustomTheme, R extends CustomTheme[T]>(
    field: T,
    value: R
  ): void => {
    setCurrentCustomTheme(currentCustomTheme => ({
      ...currentCustomTheme,
      [field]: value,
    }))
  }

  const handleCurrentInterfaceThemeChange = <T extends keyof InterfaceTheme, R extends InterfaceTheme[T]>(
    field: T,
    value: R
  ): void => {
    setCurrentInterfaceTheme(currentInterfaceTheme => ({
      ...currentInterfaceTheme,
      [field]: value,
    }))
  }

  const handleFontChange = async (
    fontVariant: FontVariant,
    resultName: string,
    fileName: string
  ): Promise<void> => {
    const fontFileKey: FontVariantFilename = `${fontVariant}OriginalFileName`

    handleBrandChange('fonts', {
      ...(organizationDataForm.brand.fonts ?? DEFAULT_FONTS),
      [fontVariant]: resultName,
      [fontFileKey]: fileName,
    })
  }

  const hexColorRegexPattern = /#[0-9A-Fa-f]{6}\b/

  const handleBgcolorValidation = (e: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>): void => {
    if (hexColorRegexPattern.test(e.target.value)) {
      setBgError(false)
    } else {
      setBgError(true)
    }
  }

  const handleColorValidation = (e: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>): void => {
    if (hexColorRegexPattern.test(e.target.value)) {
      setColorError(false)
    } else {
      setColorError(true)
    }
  }

  const handleTextColorValidation = (e: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>): void => {
    if (hexColorRegexPattern.test(e.target.value)) {
      setTextColorError(false)
    } else {
      setTextColorError(true)
    }
  }

  const handleTabColorValidation = (e: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>): void => {
    if (hexColorRegexPattern.test(e.target.value)) {
      setTabColorError(false)
    } else {
      setTabColorError(true)
    }
  }

  const handleNameValidation = (e: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>): void => {
    if (e.target.value !== '') {
      setNameError(false)
    } else {
      setNameError(true)
    }
  }

  const saveCustomTheme = (theme: CustomTheme, themeKey: keyof CustomThemes): void => {
    setCustomThemes({
      ...customThemes,
      [themeKey]: theme,
    })
    handleBrandChange('customThemes', {
      ...organizationDataForm.brand.customThemes,
      [themeKey]: theme,
    })
    setCurrentCustomTheme(DEFAULT_CUSTOM_THEME)
    setThemeModalOpen(false)
  }

  const saveInterfaceTheme = (theme: InterfaceTheme): void => {
    handleBrandChange('interfaceTheme', theme)
    setInterfaceThemeModalOpen(false)
  }

  return (
    <>
      <PageTitle title={t('settings.branding-page.title')} />

      <View direction='column' grow>
        <PageHeader
          title='settings.branding-page.title'
          subtitle='settings.branding-page.subtitle'
          helpLink='https://help.sana.ai/en/articles/96582-customizing-your-sana-domain'
        />

        <SettingsSection
          iconId='media--library'
          title='settings.branding-page.media-library.title'
          subtitle='settings.branding-page.media-library.subtitle'
        >
          <MediaLibrary />
        </SettingsSection>

        <SettingsSection iconId='color-palette' title='manage.settings.customization.title'>
          <Text size='small' bold>
            {t('manage.settings.logotype.title')}
          </Text>

          <Spacer size='xxsmall' />

          <Text size='small' lineLength={60}>
            {t('manage.settings.logotype.body')}
          </Text>

          <Spacer size='medium' />

          <View direction='column'>
            <Logotype
              size='square-logo'
              image={organizationDataForm.brand.squareLogo}
              onDelete={() => handleBrandChange('squareLogo', null)}
              headline='manage.settings.logotype-squared.title'
              description='manage.settings.logotype-squared.description'
              helpText={<SquaredLogoHelpText />}
              info={
                <LogoUploadTooltip>
                  <SidebarPreview logo={organizationDataForm.brand.squareLogo} />
                </LogoUploadTooltip>
              }
              action={
                <FileButtonInput
                  buttonProps={{
                    variant: 'secondary',
                    children:
                      organizationDataForm.brand.squareLogo !== null
                        ? t('dictionary.replace')
                        : t('dictionary.upload'),
                  }}
                  fileInputProps={{ accept: 'image/png, image/jpeg, .svg' }}
                  onChange={fileUrl => handleBrandChange('squareLogo', fileUrl)}
                  fileType='organization-settings'
                />
              }
            />
            <Logotype
              size='old-logo'
              image={organizationDataForm.brand.logo}
              onDelete={() => handleBrandChange('logo', null)}
              headline='manage.settings.logotype-wide.title'
              description='manage.settings.logotype-wide.description'
              helpText={<WideLogoHelpText />}
              action={
                <FileButtonInput
                  id='tour-settings-logo'
                  buttonProps={{
                    variant: 'secondary',
                    children:
                      organizationDataForm.brand.logo !== null
                        ? t('dictionary.replace')
                        : t('dictionary.upload'),
                  }}
                  fileInputProps={{ accept: 'image/png, image/jpeg, .svg' }}
                  onChange={fileUrl => handleBrandChange('logo', fileUrl)}
                  fileType='organization-settings'
                />
              }
            />

            <Logotype
              size='favicon'
              image={organizationDataForm.brand.favicon}
              onDelete={() => handleBrandChange('favicon', null)}
              headline='manage.settings.favicon.title'
              description='manage.settings.favicon.description'
              helpText={<FavIconHelpText />}
              info={
                <LogoUploadTooltip>
                  <BrowserPreview logo={organizationDataForm.brand.favicon} />
                </LogoUploadTooltip>
              }
              action={
                <FileButtonInput
                  id='tour-settings-favicon'
                  buttonProps={{
                    variant: 'secondary',
                    children:
                      organizationDataForm.brand.favicon !== null
                        ? t('dictionary.replace')
                        : t('dictionary.upload'),
                  }}
                  fileInputProps={{ accept: 'image/png, image/gif, .ico' }}
                  onChange={fileUrl => handleBrandChange('favicon', fileUrl)}
                  fileType='organization-settings'
                />
              }
            />
          </View>

          <Spacer size='medium' />

          <>
            <Text size='small' bold>
              {t('manage.settings.fonts.title')}
            </Text>
            <Spacer size='xxsmall' />
            <Text size='small' lineLength={40}>
              {t('manage.settings.fonts.body')}
            </Text>
            <Spacer size='medium' />
            <FontUpload>
              <Text size='small'>{t('manage.settings.fonts.regular')}</Text>
              {organizationDataForm.brand.fonts?.regular !== undefined &&
              organizationDataForm.brand.fonts.regular !== '' ? (
                <FontInfo
                  fileName={organizationDataForm.brand.fonts.regularOriginalFileName}
                  onRemove={() =>
                    handleBrandChange('fonts', {
                      ...(organizationDataForm.brand.fonts ?? DEFAULT_FONTS),
                      regular: '',
                      regularOriginalFileName: null,
                    })
                  }
                />
              ) : (
                <FileButtonInput
                  id='tour-settings-fonts'
                  buttonProps={{
                    children: t('author.slate.upload-image-tab'),
                  }}
                  fileInputProps={{ accept: '.woff,.woff2' }}
                  onChange={(resultName, fileName) => handleFontChange('regular', resultName, fileName)}
                  fileType='organization-settings'
                />
              )}
            </FontUpload>
            <Spacer size='xxsmall' />
            <FontUpload>
              <Text size='small' bold>
                {t('manage.settings.fonts.bold')}
              </Text>
              {organizationDataForm.brand.fonts?.bold !== undefined &&
              organizationDataForm.brand.fonts.bold !== '' ? (
                <FontInfo
                  fileName={organizationDataForm.brand.fonts.boldOriginalFileName}
                  onRemove={() =>
                    handleBrandChange('fonts', {
                      ...(organizationDataForm.brand.fonts ?? DEFAULT_FONTS),
                      bold: '',
                      boldOriginalFileName: null,
                    })
                  }
                />
              ) : (
                <FileButtonInput
                  buttonProps={{
                    children: t('author.slate.upload-image-tab'),
                  }}
                  fileInputProps={{ accept: '.woff,.woff2' }}
                  onChange={(resultName, fileName) => handleFontChange('bold', resultName, fileName)}
                  fileType='organization-settings'
                />
              )}
            </FontUpload>
            <Spacer size='xxsmall' />
            <FontUpload>
              <Text size='small' italic>
                {t('manage.settings.fonts.italic')}
              </Text>
              {organizationDataForm.brand.fonts?.italic !== undefined &&
              organizationDataForm.brand.fonts.italic !== '' ? (
                <FontInfo
                  fileName={organizationDataForm.brand.fonts.italicOriginalFileName}
                  onRemove={() =>
                    handleBrandChange('fonts', {
                      ...(organizationDataForm.brand.fonts ?? DEFAULT_FONTS),
                      italic: '',
                      italicOriginalFileName: null,
                    })
                  }
                />
              ) : (
                <FileButtonInput
                  buttonProps={{
                    children: t('author.slate.upload-image-tab'),
                  }}
                  fileInputProps={{ accept: '.woff,.woff2' }}
                  onChange={(resultName, fileName) => handleFontChange('italic', resultName, fileName)}
                  fileType='organization-settings'
                />
              )}
            </FontUpload>
            <Spacer size='xxsmall' />
            <FontUpload>
              <Text size='small' italic bold>
                {t('manage.settings.fonts.bold-italic')}
              </Text>
              {organizationDataForm.brand.fonts?.boldItalic !== undefined &&
              organizationDataForm.brand.fonts.boldItalic !== '' ? (
                <FontInfo
                  fileName={organizationDataForm.brand.fonts.boldItalicOriginalFileName}
                  onRemove={() =>
                    handleBrandChange('fonts', {
                      ...(organizationDataForm.brand.fonts ?? DEFAULT_FONTS),
                      boldItalic: '',
                      boldItalicOriginalFileName: null,
                    })
                  }
                />
              ) : (
                <FileButtonInput
                  buttonProps={{
                    children: t('author.slate.upload-image-tab'),
                  }}
                  fileInputProps={{ accept: '.woff,.woff2' }}
                  onChange={(resultName, fileName) => handleFontChange('boldItalic', resultName, fileName)}
                  fileType='organization-settings'
                />
              )}
            </FontUpload>
            <Spacer size='40' />
            <Text size='small' bold>
              {t('manage.settings.custom.headline.title')}
            </Text>
            <Spacer size='xxsmall' />
            <Text size='small' lineLength={40}>
              {t('manage.settings.custom.headline.description')}
            </Text>
            <Spacer size='medium' />
            <FontUpload>
              <Heading size='h3'>Headline</Heading>
              {organizationDataForm.brand.fonts?.headline !== undefined &&
              organizationDataForm.brand.fonts.headline !== '' ? (
                <FontInfo
                  fileName={organizationDataForm.brand.fonts.headlineOriginalFileName}
                  onRemove={() =>
                    handleBrandChange('fonts', {
                      ...(organizationDataForm.brand.fonts ?? DEFAULT_FONTS),
                      headline: '',
                      headlineOriginalFileName: null,
                    })
                  }
                />
              ) : (
                <FileButtonInput
                  buttonProps={{
                    children: t('author.slate.upload-image-tab'),
                  }}
                  fileInputProps={{ accept: '.woff,.woff2' }}
                  onChange={(resultName, fileName) => handleFontChange('headline', resultName, fileName)}
                  fileType='organization-settings'
                />
              )}
            </FontUpload>
            <Spacer size='xxsmall' />
            <FontUpload>
              <Heading size='h3'>Headline Italic</Heading>
              {organizationDataForm.brand.fonts?.headlineItalic !== undefined &&
              organizationDataForm.brand.fonts.headlineItalic !== '' ? (
                <FontInfo
                  fileName={organizationDataForm.brand.fonts.headlineItalicOriginalFileName}
                  onRemove={() =>
                    handleBrandChange('fonts', {
                      ...(organizationDataForm.brand.fonts ?? DEFAULT_FONTS),
                      headlineItalic: '',
                      headlineItalicOriginalFileName: null,
                    })
                  }
                />
              ) : (
                <FileButtonInput
                  buttonProps={{
                    children: t('author.slate.upload-image-tab'),
                  }}
                  fileInputProps={{ accept: '.woff,.woff2' }}
                  onChange={(resultName, fileName) =>
                    handleFontChange('headlineItalic', resultName, fileName)
                  }
                  fileType='organization-settings'
                />
              )}
            </FontUpload>
            <Spacer size='xxsmall' />
            <FontUpload>
              <Heading size='h3' bold>
                Headline Bold
              </Heading>
              {organizationDataForm.brand.fonts?.headlineBold !== undefined &&
              organizationDataForm.brand.fonts.headlineBold !== '' ? (
                <FontInfo
                  fileName={organizationDataForm.brand.fonts.headlineBoldOriginalFileName}
                  onRemove={() =>
                    handleBrandChange('fonts', {
                      ...(organizationDataForm.brand.fonts ?? DEFAULT_FONTS),
                      headlineBold: '',
                      headlineBoldOriginalFileName: null,
                    })
                  }
                />
              ) : (
                <FileButtonInput
                  buttonProps={{
                    children: t('author.slate.upload-image-tab'),
                  }}
                  fileInputProps={{ accept: '.woff,.woff2' }}
                  onChange={(resultName, fileName) => handleFontChange('headlineBold', resultName, fileName)}
                  fileType='organization-settings'
                />
              )}
            </FontUpload>
            <Spacer size='xxsmall' />
            <FontUpload>
              <Heading size='h3' bold>
                Headline Bold Italic
              </Heading>
              {organizationDataForm.brand.fonts?.headlineBoldItalic !== undefined &&
              organizationDataForm.brand.fonts.headlineBoldItalic !== '' ? (
                <FontInfo
                  fileName={organizationDataForm.brand.fonts.headlineBoldItalicOriginalFileName}
                  onRemove={() =>
                    handleBrandChange('fonts', {
                      ...(organizationDataForm.brand.fonts ?? DEFAULT_FONTS),
                      headlineBoldItalic: '',
                      headlineBoldItalicOriginalFileName: null,
                    })
                  }
                />
              ) : (
                <FileButtonInput
                  buttonProps={{
                    children: t('author.slate.upload-image-tab'),
                  }}
                  fileInputProps={{ accept: '.woff,.woff2' }}
                  onChange={(resultName, fileName) =>
                    handleFontChange('headlineBoldItalic', resultName, fileName)
                  }
                  fileType='organization-settings'
                />
              )}
            </FontUpload>
            <Spacer size='small' />
            <Message padding='small'>
              <Text size='small' bold color='foreground/secondary'>
                {t('manage.settings.fonts.help.title')}
              </Text>
              <Spacer size='xxsmall' />
              <Text size='small' color='foreground/muted'>
                {t('manage.settings.fonts.help.body-one')}
              </Text>
              <Spacer size='small' />
              <Text size='small' color='foreground/muted'>
                {t('manage.settings.fonts.help.body-two')}
              </Text>
              <Spacer size='xxsmall' />
              <Divider />
              <Text size='micro' color='foreground/muted'>
                {t('manage.settings.fonts.help.disclaimer')}
              </Text>
            </Message>
          </>

          <Spacer size='xxlarge' />
          <Text size='small' bold>
            {t('manage.settings.interface-theme.title')}
          </Text>
          <Spacer size='xxsmall' />
          <Text size='small' lineLength={40}>
            {t('manage.settings.interface-theme.body')}
          </Text>
          <Spacer />
          <ThemeGrid>
            <InterfaceThemeItem
              id='tour-settings-interface-theme'
              $bgColor={
                organizationDataForm.brand.interfaceTheme?.buttonBackgroundColor ??
                defaultInterfaceTheme.buttonBackgroundColor
              }
              onClick={() => {
                setCurrentInterfaceTheme(interfaceTheme)
                setInterfaceThemeModalOpen(true)
              }}
            >
              <CustomThemeText
                color='LEGACY_DEFAULT_TEXT_COLOR_REPLACE_ASAP'
                size='small'
                $color={
                  organizationDataForm.brand.interfaceTheme?.buttonTextColor ??
                  defaultInterfaceTheme.buttonTextColor
                }
                bold
              >
                {t('manage.settings.interface-theme.title')}
              </CustomThemeText>
              <CustomThemeActions>
                <Icon
                  iconId='edit'
                  size='size-16'
                  color={dynamicColor(
                    organizationDataForm.brand.interfaceTheme
                      ? organizationDataForm.brand.interfaceTheme.buttonTextColor
                      : defaultInterfaceTheme.buttonTextColor
                  )}
                  onClick={() => {
                    setCurrentInterfaceTheme(interfaceTheme)
                    setInterfaceThemeModalOpen(true)
                  }}
                />
              </CustomThemeActions>
            </InterfaceThemeItem>
          </ThemeGrid>
          <Spacer size='80' />
          <Text size='small' bold>
            {t('manage.settings.themes.title')}
          </Text>
          <Spacer size='xxsmall' />
          <Text size='small' lineLength={40}>
            {t('manage.settings.themes.body')}
          </Text>
          <Spacer size='medium' />
          <Text size='small' bold>
            {t('manage.settings.sana-themes')}
          </Text>
          <Spacer />
          <ThemeGrid>
            {_.map(presetThemes, (theme, key: PresetThemeName) =>
              key.includes('custom') ? null : (
                <ThemeItem
                  key={`theme-${key}`}
                  $bgColor={theme.backgroundColor}
                  onClick={() => handleBrandChange('defaultTheme', { type: 'preset', name: key })}
                >
                  <ThemeName size='small' color={theme.textColor} bold>
                    {key.replace('-', ' ')}
                  </ThemeName>
                  {key === defaultTheme && (
                    <Text size='small' color={color(theme.textColor).opacity(0.5)}>
                      {t('modal.selected')}
                    </Text>
                  )}
                </ThemeItem>
              )
            )}
          </ThemeGrid>
          <Spacer size='xxlarge' />
          <Text size='small' bold>
            {t('manage.settings.themes.custom.title')}
          </Text>
          <Spacer />
          <CustomThemeGrid>
            {_.map(customThemes, (theme, key: keyof CustomThemes) => {
              if (theme === null) return null

              const themeId = getCustomThemeIdFromBackendId(key)

              return (
                <CustomThemeItem
                  key={key}
                  $customBgColor={theme.backgroundColor}
                  onClick={() => handleBrandChange('defaultTheme', { type: 'preset', name: themeId })}
                >
                  <CustomThemeText
                    color='LEGACY_DEFAULT_TEXT_COLOR_REPLACE_ASAP'
                    size='small'
                    $color={theme.foregroundColor}
                    bold
                  >
                    {theme.customThemeName}
                  </CustomThemeText>
                  {themeId === defaultTheme && (
                    <CustomThemeText
                      color='LEGACY_DEFAULT_TEXT_COLOR_REPLACE_ASAP'
                      size='small'
                      $color={dynamicColor(theme.foregroundColor).opacity(0.5)}
                    >
                      {t('modal.selected')}
                    </CustomThemeText>
                  )}
                  <CustomThemeActions>
                    <Icon
                      iconId='edit'
                      size='size-16'
                      color={dynamicColor(theme.foregroundColor)}
                      onClick={e => {
                        e.stopPropagation()
                        setCurrentCustomTheme(theme)
                        setCurrentThemeKey(key)
                        setThemeModalOpen(true)
                      }}
                    />
                    <Icon
                      iconId='close'
                      size='size-16'
                      color={dynamicColor(theme.foregroundColor)}
                      onClick={e => {
                        e.stopPropagation()
                        setCustomThemes({
                          ...customThemes,
                          [key]: null,
                        })
                        handleBrandChange('customThemes', {
                          ...organizationDataForm.brand.customThemes,
                          [key]: null,
                        })
                      }}
                    />
                  </CustomThemeActions>
                </CustomThemeItem>
              )
            })}
            {emptyCustomThemes.length > 0 && (
              <CreateThemeItem
                onClick={() => {
                  const newThemeKey = emptyCustomThemes[0]
                  if (newThemeKey === undefined) return
                  setThemeModalOpen(true)
                  setCurrentThemeKey(newThemeKey)
                }}
              >
                <Text size='small' bold color='black'>
                  {t('manage.create-new')}
                </Text>
              </CreateThemeItem>
            )}
          </CustomThemeGrid>
          <Spacer size='large' />
          <StickySave>
            <Button onClick={() => updateSettings(organizationDataForm)}>{t('modal.save-changes')}</Button>
          </StickySave>
        </SettingsSection>
        <ThemeModal
          title={currentCustomTheme.customThemeName === '' ? 'New Theme' : currentCustomTheme.customThemeName}
          open={themeModalOpen}
          onClose={() => {
            setThemeModalOpen(false)
            setBgError(false)
            setColorError(false)
            setNameError(false)
            setCurrentCustomTheme(DEFAULT_CUSTOM_THEME)
          }}
        >
          <ModalThemeArea $bgColor={currentCustomTheme.backgroundColor}>
            <ThemeHeading
              color='LEGACY_DEFAULT_HEADING_COLOR_REPLACE_ASAP'
              size='h3'
              $color={currentCustomTheme.foregroundColor}
              bold
            >
              {'Course or path headline'}
            </ThemeHeading>
            <Spacer size='xxsmall' />
            <ThemeText
              color='LEGACY_DEFAULT_TEXT_COLOR_REPLACE_ASAP'
              size='regular'
              lineLength={30}
              $color={currentCustomTheme.foregroundColor}
            >
              {'Reprehenderit voluptate quidem voluptatibus quidem et est.'}
            </ThemeText>
            <Spacer />
            <ThemeButton $bgColor={currentCustomTheme.foregroundColor}>
              <ThemeButtonBox $bgColor={currentCustomTheme.backgroundColor} />
            </ThemeButton>
          </ModalThemeArea>
          <Spacer size='small' />
          <InputGrid>
            <FormElement label='Background Color' helper={bgError ? t('settings.errors.valid-hex') : ''}>
              <ColorInput
                onChange={e => {
                  setBgError(false)
                  handleCurrentCustomThemeChange('backgroundColor', e.target.value)
                }}
                onBlur={handleBgcolorValidation}
                value={currentCustomTheme.backgroundColor}
              />
            </FormElement>

            <FormElement label='Text Color' helper={colorError ? t('settings.errors.valid-hex') : ''}>
              <ColorInput
                onChange={e => {
                  setColorError(false)
                  handleCurrentCustomThemeChange('foregroundColor', e.target.value)
                }}
                onBlur={handleColorValidation}
                value={currentCustomTheme.foregroundColor}
              />
            </FormElement>
          </InputGrid>
          <Input
            id='theme-name'
            label='Theme Name'
            placeholder='Give your theme a name'
            value={currentCustomTheme.customThemeName}
            onChange={e => {
              setNameError(false)
              handleCurrentCustomThemeChange('customThemeName', e.target.value)
            }}
            onBlur={e => handleNameValidation(e)}
            isError={nameError}
            helperText={nameError ? t('settings.errors.valid-theme-name') : ''}
          />
          <Spacer size='small' />
          <RightAlignContainer>
            <CustomInterfaceThemeProvider>
              <Button
                variant='secondary'
                onClick={() => {
                  setThemeModalOpen(false)
                  setBgError(false)
                  setColorError(false)
                  setNameError(false)
                  setCurrentCustomTheme(DEFAULT_CUSTOM_THEME)
                }}
              >
                {t('modal.cancel')}
              </Button>
              <Button
                onClick={() => saveCustomTheme(currentCustomTheme, currentThemeKey)}
                disabled={Object.values(currentCustomTheme).some(x => x === '') || colorError || bgError}
              >
                {t('dictionary.save')}
              </Button>
            </CustomInterfaceThemeProvider>
          </RightAlignContainer>
        </ThemeModal>
        <Modal
          padding='32'
          size={{
            width: 600,
          }}
          open={interfaceThemeModalOpen}
          onClose={() => {
            setInterfaceThemeModalOpen(false)
            setBgError(false)
            setColorError(false)
            setCurrentInterfaceTheme(organizationDataForm.brand.interfaceTheme ?? defaultInterfaceTheme)
          }}
        >
          <View justifyContent='center' paddingBottom='8'>
            <Text bold size='large'>
              {t('manage.settings.interface-theme.title')}
            </Text>
          </View>

          <InterfaceThemeModalPreviewArea>
            <TabsPreviewHolder>
              <CustomTab $bgColor={dynamicColor(currentInterfaceTheme.tabColor).opacity(0.05).toString()}>
                <CustomThemeText
                  color='LEGACY_DEFAULT_TEXT_COLOR_REPLACE_ASAP'
                  size='small'
                  $color={currentInterfaceTheme.tabColor}
                  bold
                >
                  {t('manage.settings.interface-theme.menu-tab')}
                </CustomThemeText>
              </CustomTab>
              <Tab $bgColor='white'>
                <CustomThemeText
                  color='LEGACY_DEFAULT_TEXT_COLOR_REPLACE_ASAP'
                  size='small'
                  $color='black'
                  bold
                >
                  {t('manage.settings.interface-theme.menu-tab')}
                </CustomThemeText>
              </Tab>
              <Tab $bgColor='white'>
                <CustomThemeText
                  color='LEGACY_DEFAULT_TEXT_COLOR_REPLACE_ASAP'
                  size='small'
                  $color='black'
                  bold
                >
                  {t('manage.settings.interface-theme.menu-tab')}
                </CustomThemeText>
              </Tab>
            </TabsPreviewHolder>
            <Spacer size='medium' />
            <PrimaryButton $bgColor={currentInterfaceTheme.buttonBackgroundColor}>
              <CustomThemeText
                color='LEGACY_DEFAULT_TEXT_COLOR_REPLACE_ASAP'
                size='small'
                $color={currentInterfaceTheme.buttonTextColor}
                bold
              >
                {t('manage.settings.interface-theme.primary-button')}
              </CustomThemeText>
            </PrimaryButton>
            <Spacer size='small' />
          </InterfaceThemeModalPreviewArea>
          <Spacer size='small' />
          <InputGrid>
            <FormElement
              label={t('manage.settings.interface-theme.color1')}
              helper={bgError ? t('settings.errors.valid-hex') : ''}
            >
              <ColorInput
                placeholder='0558F9'
                onChange={e => {
                  setBgError(false)
                  handleCurrentInterfaceThemeChange('buttonBackgroundColor', e.target.value)
                }}
                onBlur={handleBgcolorValidation}
                value={currentInterfaceTheme.buttonBackgroundColor}
              />
            </FormElement>
            <FormElement
              label={t('manage.settings.interface-theme.color2')}
              helper={textColorError ? t('settings.errors.valid-hex') : ''}
            >
              <ColorInput
                placeholder='ffffff'
                onChange={e => {
                  setTextColorError(false)
                  handleCurrentInterfaceThemeChange('buttonTextColor', e.target.value)
                }}
                onBlur={handleTextColorValidation}
                value={currentInterfaceTheme.buttonTextColor}
              />
            </FormElement>
          </InputGrid>
          <FormElement
            label={t('manage.settings.interface-theme.color3')}
            helper={tabColorError ? t('settings.errors.valid-hex') : ''}
          >
            <ColorInput
              placeholder='0558F9'
              onChange={e => {
                setTabColorError(false)
                handleCurrentInterfaceThemeChange('tabColor', e.target.value)
              }}
              onBlur={handleTabColorValidation}
              value={currentInterfaceTheme.tabColor}
            />
          </FormElement>
          <Spacer size='small' />
          <SpaceBetweenContainer>
            <Text size='small' lineLength={30} color='foreground/muted'>
              {t('manage.settings.interface-theme.modal.text')}
            </Text>
            <ModalActionsContainer>
              <Button
                variant='secondary'
                onClick={() => {
                  setInterfaceThemeModalOpen(false)
                  setBgError(false)
                  setColorError(false)
                  setCurrentInterfaceTheme(organizationDataForm.brand.interfaceTheme ?? defaultInterfaceTheme)
                }}
              >
                {t('modal.cancel')}
              </Button>
              <Button
                onClick={() => saveInterfaceTheme(currentInterfaceTheme)}
                disabled={Object.values(currentInterfaceTheme).some(x => x === '')}
              >
                {t('modal.save-changes')}
              </Button>
            </ModalActionsContainer>
          </SpaceBetweenContainer>
        </Modal>
      </View>
    </>
  )
}
