import { LayoutType } from 'sierra-client/views/flexible-content/editor-inner'
import { SlateFileType } from 'sierra-domain/flexible-content/types'
import { assertNever } from 'sierra-domain/utils'

export function getEditorLayoutForFileType(fileType: SlateFileType): LayoutType {
  switch (fileType) {
    case 'general':
    case 'notepad':
    case 'external-notepad':
      return 'page-card'
    case 'sliding-scale':
      return 'sliding-scale-card'
    case 'slate-card':
    case 'reflections':
    case 'poll':
    case 'bullet':
    case 'question-card':
    case 'sticky-notes':
    case 'assessment-card':
    case 'flip-cards':
    case 'homework':
    case 'project-card':
    case 'scenario':
    case 'roleplay':
      return 'default'
    default:
      assertNever(fileType)
  }
}
