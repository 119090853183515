/* eslint-disable @typescript-eslint/explicit-function-return-type */
import {
  useInvalidateEditableContentCache,
  useInvalidateFoldersCache,
  useInvalidateTeamspaceCache,
} from 'sierra-client/api/hooks/queries-invalidation'
import { useNotif } from 'sierra-client/components/common/notifications'
import { Logging } from 'sierra-client/core/logging'
import { useTranslation } from 'sierra-client/hooks/use-translation'
import { CachedQueryOptions, useCachedQuery, useTypedMutation } from 'sierra-client/state/api'
import { useDispatch } from 'sierra-client/state/hooks'
import { getFlexibleContentSubpath } from 'sierra-client/views/workspace/utils/urls'
import { ListEditableContentRequest, ListEditableContentResponse } from 'sierra-domain/api/author-v2'
import {
  XRealtimeAuthorCreateFlexibleContent,
  XRealtimeAuthorDuplicateFlexibleContent,
  XRealtimeAuthorListEditableContent,
} from 'sierra-domain/routes'
import { isDefined } from 'sierra-domain/utils'

export const useListEditableContent = <TSelectData = ListEditableContentResponse>(
  options: CachedQueryOptions<ListEditableContentResponse, TSelectData> = {},
  input?: ListEditableContentRequest
) => {
  return useCachedQuery(XRealtimeAuthorListEditableContent, input ?? {}, {
    retry: false,
    ...options,
  })
}

export const useEditableContentById = (contentId: string) => {
  return useListEditableContent({
    select: data => data.content.find(content => content.id === contentId),
  })
}

export const useCreateEditableContentMutation = () => {
  const invalidateCache = useInvalidateEditableContentCache()

  return useTypedMutation(XRealtimeAuthorCreateFlexibleContent, {
    onSettled: () => {
      void invalidateCache()
    },
  })
}

export const useDuplicateEditableContentMutation = () => {
  const notification = useNotif()
  const { t } = useTranslation()
  const dispatch = useDispatch()

  const invalidateEditableContentCache = useInvalidateEditableContentCache()
  const invalidateFoldersCache = useInvalidateFoldersCache()
  const invalidateTeamspaceCache = useInvalidateTeamspaceCache()

  return useTypedMutation(XRealtimeAuthorDuplicateFlexibleContent, {
    onSettled: () => {
      void invalidateEditableContentCache()
      void invalidateFoldersCache()
      void invalidateTeamspaceCache()
    },
    onSuccess: (data, input) => {
      const { newFlexibleContentId, type, teamspace } = data
      const subPath = getFlexibleContentSubpath(type)

      const getDirectory = (): string => {
        if (isDefined(teamspace)) {
          return isDefined(teamspace.folderDisplayName) && teamspace.folderDisplayName !== 'root'
            ? `${teamspace.displayName}/${teamspace.folderDisplayName}`
            : `${teamspace.displayName}`
        }
        return t('author.content.my-content')
      }

      notification.push({
        type: 'custom',
        level: 'info',
        target: '_blank',
        url: `/create/${subPath}/${newFlexibleContentId}`,
        body: t('notifications.course-duplicated-in', { directory: getDirectory() }),
        expires: Date.now() + 10000,
      })

      if (input.targetLanguage !== undefined) {
        void dispatch(
          Logging.authoring.courseTranslated({
            courseId: input.originalFlexibleContentId,
            language: input.targetLanguage,
            courseKind: type,
          })
        )
      } else {
        void dispatch(
          Logging.authoring.courseDuplicated({
            courseId: input.originalFlexibleContentId,
            courseKind: type,
          })
        )
      }
    },
    onError: (_, input) => {
      notification.push({
        type: input.targetLanguage === undefined ? 'duplication-error' : 'translation-too-long-error',
      })
    },
  })
}
