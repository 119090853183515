import { DateTime } from 'luxon'
import { useMemo } from 'react'
import { useLiveSessionContext } from 'sierra-client/components/liveV2/contexts/live-session-data'
import { usePeriodicRefresh } from 'sierra-client/hooks/use-periodic-refresh'

type FormattedTimeData = {
  formattedTime: string | undefined
  msRemaining: number
}

export const useFormattedTimeToSession = (): FormattedTimeData | undefined => {
  const liveSession = useLiveSessionContext()

  const timeRemaining = usePeriodicRefresh(
    liveSession.data.type === 'scheduled' ? liveSession.data.startTime : undefined,
    startTime => {
      if (startTime === undefined) return

      return DateTime.fromISO(startTime).diff(DateTime.now(), ['days', 'hours', 'minutes'])
    },
    60 * 1000
  )

  return useMemo(
    () =>
      timeRemaining === undefined
        ? undefined
        : {
            formattedTime: [
              { unit: 'd', count: timeRemaining.days },
              { unit: 'hr', count: timeRemaining.hours },
              { unit: 'min', count: timeRemaining.minutes },
            ]
              .filter(({ count }) => count !== 0)
              .slice(0, 2)
              .map(({ unit, count }) => `${Math.floor(count)} ${unit}`)
              .join(' '),
            msRemaining: timeRemaining.valueOf(),
          },
    [timeRemaining]
  )
}
