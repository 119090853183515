import { useEffect, useState } from 'react'

export const useIsVerticalScrollbarVisible = (): {
  setScrollviewRef: (ref: HTMLElement | null) => void
  isScrollbarVisible: boolean
} => {
  const [scrollviewRef, setScrollviewRef] = useState<HTMLElement | null>(null)
  const [isScrollbarVisible, setIsScrollbarVisible] = useState(false)

  useEffect(() => {
    if (scrollviewRef === null) return

    const detectScrollbar = (): void => {
      if (scrollviewRef.scrollHeight > scrollviewRef.clientHeight) {
        setIsScrollbarVisible(true)
      } else {
        setIsScrollbarVisible(false)
      }
    }

    const resizeObserver = new ResizeObserver(detectScrollbar)
    resizeObserver.observe(scrollviewRef)

    return () => {
      resizeObserver.unobserve(scrollviewRef)
    }
  }, [scrollviewRef])

  return {
    setScrollviewRef,
    isScrollbarVisible,
  }
}
