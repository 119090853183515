import fuzzysort from 'fuzzysort'
import _ from 'lodash'
import { Shortcut } from 'sierra-client/components/shortcut-menu/types'
import { TranslationKey, TranslationLookup } from 'sierra-client/hooks/use-translation/types'
import { assertNever } from 'sierra-domain/utils'

export type Result = Shortcut

type Search = {
  shortcuts: Shortcut[]
  search: (text: string) => Result[]
}

type AnnotatedItem = {
  search: string
  keywords?: string
  value: Shortcut
}

/**
 * Annotate an item with a primary search key and some secondary (optional) labels.
 *
 * This can be used to expand the criteria for when something is matched. For example, if links
 * should match the word "open", that can be added as a keyword here.
 *
 * Note: `keywords` needs to be a `string` instead of a `string[]` due a limitation in
 * the library we use for fuzzy search
 */
function annotate(value: Shortcut, t: TranslationLookup): AnnotatedItem {
  const openKey: string = t('notification.open')
  const search = t(value.label as TranslationKey)
  switch (value.type) {
    case 'link': {
      const { href, label, group } = value
      return { search, keywords: [href, `${openKey} ${label}`, group ?? ''].join(','), value }
    }
    case 'action':
    case 'component':
    case 'toggle':
      return {
        search,
        keywords: value.group ?? '',
        value,
      }
    default:
      assertNever(value)
  }
}

function search({ shortcuts }: Pick<Search, 'shortcuts'>, query: string, t: TranslationLookup): Result[] {
  if (query === '') return shortcuts

  const keys: (keyof AnnotatedItem)[] = ['search', 'keywords']
  const possibleResults = [...shortcuts].map(item => annotate(item, t))

  const maxNumberOfResults = Math.max(shortcuts.length, 25)
  const allResults = fuzzysort.go(query, possibleResults, { keys, limit: maxNumberOfResults })

  const sortByKey: keyof (typeof allResults)[number] = 'score'
  const results = _.chain(allResults)
    .orderBy(sortByKey, 'desc')
    .map(({ obj }) => obj.value)
    .value()

  return results
}

type SearchOptions = Partial<Omit<Search, 'search'> & { t: TranslationLookup }>
export function createSearch({ t = _.identity, shortcuts = [], ...options }: SearchOptions = {}): Search {
  return {
    shortcuts,
    search: text => search({ shortcuts }, text, t),
    ...options,
  }
}
