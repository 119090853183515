import styled from 'styled-components'

import { DateTime } from 'luxon'
import { TranslationLookup } from 'sierra-client/hooks/use-translation/types'
import { IdentityWithMetadata } from 'sierra-domain/api/manage'
import { z } from 'zod'

const luxonDateTimeSchema = (errorMsg?: string): z.ZodType<DateTime, z.ZodTypeDef, DateTime> =>
  z.custom<DateTime>(val => DateTime.isDateTime(val), errorMsg ?? 'Type was not of luxon DateTime')

type MIMEType = string & z.BRAND<'MIME'>
const fileSchema = (
  allowedTypes: MIMEType[],
  errorMsg?: string
): z.ZodEffects<z.ZodType<File, z.ZodTypeDef, File>, File, File> =>
  z
    .instanceof(File)
    // Make sure we check that the file type is part of includes
    .refine(file => allowedTypes.includes(file.type as MIMEType), errorMsg ?? 'Invalid file type')

// eslint-disable-next-line @typescript-eslint/explicit-function-return-type
export const createSchema = (t: TranslationLookup) => {
  const dateSchema = z
    .object({
      issue: luxonDateTimeSchema(t('validation.error.datetime.invalid')).optional(),
      expiry: luxonDateTimeSchema(t('validation.error.datetime.invalid')).optional(),
    })
    .refine(
      val => {
        if (val.expiry === undefined || val.issue === undefined) {
          return true
        }
        return val.expiry.diff(val.issue, 'days').days >= 1
      },
      {
        message: t('manage.certificates.expiry-date-error-message'),
        path: ['expiry'],
      }
    )

  const noteSchema = z.string({ required_error: t('validation.error.any.required') })

  const uploadFileSchema = z.object({
    id: z.string(),
    file: fileSchema(
      ['image/jpeg', 'image/png', 'application/pdf'] as MIMEType[],
      t('validation.error.file.invalid')
    ),
  })

  return z.object({
    date: dateSchema,
    note: noteSchema.optional(),
    file: uploadFileSchema.optional().nullable(),
    issuer: IdentityWithMetadata.optional().nullable(),
  })
}

export type FormData = z.infer<ReturnType<typeof createSchema>>

export const Form = styled.form`
  height: 100%;
  display: flex;
  justify-content: space-between;
  flex-direction: column;
`
