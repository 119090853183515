import { useQueryClient } from '@tanstack/react-query'
import { useBlocker } from '@tanstack/react-router'
import React, { Fragment, ReactNode, useCallback, useState } from 'react'
import { ActionModal } from 'sierra-client/components/common/modals/action-modal'
import { useNotif } from 'sierra-client/components/common/notifications'
import { PageTitle } from 'sierra-client/components/common/page-title'
import { percentage } from 'sierra-client/core/format'
import { useOrganizationPermissions, useProgramPermissions } from 'sierra-client/hooks/use-permissions'
import { useTranslation } from 'sierra-client/hooks/use-translation'
import { stringWithZodSerializer, useQueryState } from 'sierra-client/lib/querystate/use-query-state'
import { getGlobalRouter } from 'sierra-client/router'
import { AdminModal, AdminSection } from 'sierra-client/views/manage/components/admin-section'
import { ManageContainer, ManageSubPageSection } from 'sierra-client/views/manage/components/common'
import { DetailsHeader } from 'sierra-client/views/manage/components/details-header'
import { ManageProgramHeader } from 'sierra-client/views/manage/components/layout/manage-program-header'
import { ProgramDetailUsersSection } from 'sierra-client/views/manage/programs/components/program-users-table'
import { Statistic, Statistics } from 'sierra-client/views/manage/programs/components/statistics'
import { EditProgramModal } from 'sierra-client/views/manage/programs/edit-program-modal'
import { EnrollButton } from 'sierra-client/views/manage/programs/enrollment-rules/enroll-button'
import { EnrollmentRuleSection } from 'sierra-client/views/manage/programs/enrollment-rules/enrollment-rule-section'
import { EnrollOnceModal } from 'sierra-client/views/manage/programs/enrollment-rules/modals/enroll-once-modal'
import { EnrollmentRuleModal } from 'sierra-client/views/manage/programs/enrollment-rules/modals/enrollment-rule-modal'
import { useInvalidateEnrollmentRuleList } from 'sierra-client/views/manage/programs/enrollment-rules/use-enrollment-rules-by-context'
import { useEnrollmentRuleDomainReps } from 'sierra-client/views/manage/programs/enrollment-rules/utils'
import {
  PROGRAM_QUERY_KEY_PREFIX,
  useProgramAverageTimeToComplete,
  useProgramDetails,
} from 'sierra-client/views/manage/programs/hooks/use-program-details'
import { ProgramContentSection } from 'sierra-client/views/manage/programs/tabs'
import { ModalActions, activePanelSerializer } from 'sierra-client/views/manage/programs/types'
import { ProgramHeatmap } from 'sierra-client/views/manage/reports/components/heatmap/program-heatmap'
import { ProgramProgress } from 'sierra-domain/api/manage'
import { ProgramId, UserId } from 'sierra-domain/api/uuid'
import { Tabs, TruncatedText } from 'sierra-ui/components'
import { durationToLargestUnitString } from 'sierra-ui/missions/delphi/charts/utils'
import { Button, Text, View } from 'sierra-ui/primitives'
import { palette, token } from 'sierra-ui/theming'
import { v2_breakpoint } from 'sierra-ui/theming/breakpoints'
import styled from 'styled-components'
import z from 'zod'

const ContentWrapper = styled(View)``

const AnalyticsSection = styled(View).attrs({ direction: 'row', gap: '16', alignItems: 'stretch' })`
  @media screen and (max-width: ${v2_breakpoint.desktop}) {
    flex-direction: column;
  }
`

const AnalyticsHeadline = styled(Text).attrs({ size: 'small', bold: true })``

const TopSection = styled(View).attrs({ direction: 'column', grow: true })`
  flex: 1;
  min-width: 0;

  @media screen and (max-width: ${v2_breakpoint.desktop}) {
    flex: 100%;
  }
`

const InsightsSection = TopSection
const HeatmapSection = TopSection

const HeatmapPreview = styled(View).attrs({ direction: 'column', alignItems: 'flex-start' })`
  position: relative;
  border-radius: ${p => p.theme.borderRadius['size-16']};
  border: 1px solid ${token('border/default')};
  flex: 0 1 100%;

  /*
  &:after {
    content: '';
    position: absolute;
    width: 180px;
    height: calc(100% - 1rem);
    right: 0;
    top: 0;
    pointer-events: none;
    background: linear-gradient(270deg, #ffffff 2.94%, rgba(255, 255, 255, 0) 133.82%);
    opacity: 0.7;
    border-radius: ${p => p.theme.borderRadius['size-16']} ${p => p.theme.borderRadius['size-16']} 0 0;
  }
   */
`

const PreviewScrollContainer = styled(View).attrs({
  direction: 'row',
  alignItems: 'flex-start',
})`
  max-width: 100%;
  position: relative;
  overflow-x: scroll;
  padding: 1.25rem;
  padding-right: 3rem;
  padding-bottom: 4rem;
`

const PreviewColumnContainer = styled(View)`
  padding: 0 1rem;
  min-width: 180px;
  position: relative;

  &:after {
    content: '';
    position: absolute;
    top: 0;
    right: 0;
    height: 100%;
    width: 1px;
    border-right: 1px solid ${palette.grey[5]};
  }

  &:last-of-type:after {
    border-right: none;
  }
`

const HeatmapButton = styled(Button).attrs({ variant: 'ghost' })`
  margin: 1rem;
  margin-top: -56px;
  z-index: 1;

  /* HACK: This is an internal detail from the UI library. We'll use it here
           as a workaround to match the variant='ghost' behavior of the old button.
           
           The UI snapshots show the old appearance and the threshold isn't low enough
           to catch the difference. */
  --primitive-button__background: transparent;
`

const CourseTitleContainer = styled(View)`
  padding: 0.5rem 0;
  position: relative;
`

const LowercaseText = styled(Text)`
  text-transform: lowercase;
`

type PreviewColumnProps = {
  title: string
  progress: ProgramProgress
}

const PreviewColumn = ({ title, progress }: PreviewColumnProps): JSX.Element => {
  const { t } = useTranslation()
  return (
    <PreviewColumnContainer direction='column' gap='16'>
      <CourseTitleContainer>
        <TruncatedText size='small' bold lines={1}>
          {title}
        </TruncatedText>
      </CourseTitleContainer>
      <View direction='column' gap='16'>
        <LowercaseText size='small' color='greenBright' bold>
          {percentage(progress.availableUsers === 0 ? 0 : progress.passedUsers / progress.availableUsers)}{' '}
          {t('dictionary.completed')}
        </LowercaseText>
        <LowercaseText size='small' bold>
          {percentage(
            progress.availableUsers === 0
              ? 0
              : (progress.startedUsers - progress.passedUsers) / progress.availableUsers
          )}{' '}
          {t('dictionary.in-progress')}
        </LowercaseText>
        <LowercaseText size='small' color='grey25' bold>
          {percentage(
            progress.availableUsers === 0
              ? 0
              : (progress.availableUsers - progress.startedUsers) / progress.availableUsers
          )}{' '}
          {t('dictionary.not-started')}
        </LowercaseText>
      </View>
    </PreviewColumnContainer>
  )
}

const AverageTimeToComplete = ({ programId }: { programId: ProgramId }): JSX.Element => {
  const { t } = useTranslation()
  const averageTimeToComplete = useProgramAverageTimeToComplete(programId)

  return (
    <Statistic
      top={t('manage.programs.insights.avg-completion-time.title')}
      primitive={durationToLargestUnitString(averageTimeToComplete)}
      bottom=''
      tooltip={t('manage.programs.insights.avg-completion-time.tooltip')}
    />
  )
}

type ProgramDetailProps = {
  programId: ProgramId
}

type TabItem = {
  id: 'content' | 'people'
  label: string
  content: ReactNode
}

const zodTabType = z.enum(['content', 'people'])
const selectedTabSerializer = stringWithZodSerializer(zodTabType)

export const ManageProgramDetails: React.FC<ProgramDetailProps> = ({ programId }) => {
  const { t } = useTranslation()
  const notifications = useNotif()
  const { domainReps } = useEnrollmentRuleDomainReps()
  const orgPermissions = useOrganizationPermissions()
  const programPermissions = useProgramPermissions(programId)

  const queryClient = useQueryClient()

  const tabSectionRef = React.useRef<HTMLDivElement | null>(null) // For scrolling

  const { program, isLoading, unassignFromProgram, deleteProgram, programAdminState } =
    useProgramDetails(programId)

  const [action, setAction] = useState<ModalActions>({ modal: undefined })

  const blocker = useBlocker({ condition: program !== undefined && program.name.length === 0 })

  const onRemoveUsers = useCallback(
    (ids: UserId[], callback?: () => void) => setAction({ modal: 'unassign-users', targets: ids, callback }),
    []
  )

  const invalidateEnrollmentRuleList = useInvalidateEnrollmentRuleList()

  const [panel, setPanel] = useQueryState(activePanelSerializer, 'none', 'panel')
  const [selectedTab, selectTab] = useQueryState(selectedTabSerializer, 'content', 'tab')

  const refreshProgram = useCallback(async () => {
    void queryClient.invalidateQueries({ queryKey: PROGRAM_QUERY_KEY_PREFIX })
    await invalidateEnrollmentRuleList({ type: 'program', id: programId })
  }, [queryClient, invalidateEnrollmentRuleList, programId])

  if (program === undefined || isLoading) return <></>

  const tabItems: TabItem[] = [
    {
      id: 'content',
      label: t('manage.programs.tabs.content'),
      content: (
        <ProgramContentSection
          programId={programId}
          canEdit={programPermissions.has('EDIT_OUTLINE')}
          canChangeUserVersion={programPermissions.has('UPDATE_USERS_VERSION')}
        />
      ),
    },
    {
      id: 'people',
      label: t('manage.programs.tabs.people'),
      content: (
        <>
          <View justifyContent='flex-end' padding='xsmall'>
            <EnrollButton
              canCreateEnrollmentRule={programPermissions.has('MANAGE_ENROLLMENT_RULES')}
              onCreateEnrollmentRule={() => setAction({ modal: 'create-enrollment-rule' })}
              onEnrollOnce={() => setAction({ modal: 'users' })}
              canEnrollUsers={programPermissions.has('ENROLL_USERS')}
            />
          </View>
          <ProgramDetailUsersSection
            programId={programId}
            canChangeUserVersion={programPermissions.has('UPDATE_USERS_VERSION')}
            canEnrollUsers={programPermissions.has('ENROLL_USERS')}
            onRemove={onRemoveUsers}
          />
        </>
      ),
    },
  ]

  return (
    <>
      <PageTitle title={program.name} />

      <ManageContainer>
        <DetailsHeader
          backlink={{
            href: '/manage/programs',
            label: 'manage.backlinks--programs',
          }}
        />

        <ManageProgramHeader
          programId={programId}
          title={program.name}
          dateString={program.createdAt}
          onEdit={
            programPermissions.has('EDIT_METADATA')
              ? () => {
                  setAction({ modal: 'edit-program' })
                  setPanel('edit')
                }
              : undefined
          }
          image={program.image}
        />
        <div ref={tabSectionRef} />
        <ContentWrapper direction='column' gap='32' paddingBottom='96'>
          {program.steps.length > 0 && program.enrolledUsers > 0 && (
            <AnalyticsSection>
              <InsightsSection>
                <AnalyticsHeadline>{t('dictionary.insights')}</AnalyticsHeadline>
                <Statistics>
                  <Statistic
                    top={t('manage.programs.insights.total-enrolled')}
                    primitive={program.enrolledUsers.toString()}
                    bottom=''
                  />
                  <Statistic
                    top={t('manage.programs.insights.active')}
                    primitive={program.weeklyActiveUsers.toString()}
                    bottom=''
                  />
                  <AverageTimeToComplete programId={programId} />
                </Statistics>
                {/*
              <GroupStatusCard group={program} showHeatmap={() => setAction({ modal: 'heatmap' })} />
              <AssignmentsCard
                group={program}
                scrollToContent={() =>
                  tabSectionRef.current?.scrollIntoView({ block: 'start', behavior: 'smooth' })
                }
              />
            */}
              </InsightsSection>
              <HeatmapSection>
                <AnalyticsHeadline>{t('manage.heatmap.title')}</AnalyticsHeadline>
                <HeatmapPreview>
                  <PreviewScrollContainer gap='none'>
                    <PreviewColumn title={t('manage.insights.total')} progress={program.progress} />
                    {program.steps.map(step => {
                      if (step.type === 'email') {
                        return <Fragment key={step.id} />
                      }

                      return <PreviewColumn key={step.id} title={step.title} progress={step.progress} />
                    })}
                  </PreviewScrollContainer>
                  <HeatmapButton onClick={() => setAction({ modal: 'heatmap' })}>
                    {t('manage.insights.view-heatmap')}
                  </HeatmapButton>
                </HeatmapPreview>
              </HeatmapSection>
            </AnalyticsSection>
          )}
          <Tabs value={selectedTab} onChange={selectTab} items={tabItems} />
          <ManageSubPageSection>
            <View direction='row' alignItems='flex-start'>
              <EnrollmentRuleSection
                context={{ type: 'program', id: programId }}
                canCreateEnrollmentRule={programPermissions.has('MANAGE_ENROLLMENT_RULES')}
                addRule={() => setAction({ modal: 'create-enrollment-rule' })}
                programImage={program.image}
              />
              <AdminSection
                canEdit={programPermissions.has('EDIT_ADMINS')}
                currentAdmins={programAdminState.currentAdmins}
                onClickViewAll={() => setAction({ modal: 'program-admins' })}
              />
            </View>
          </ManageSubPageSection>
        </ContentWrapper>
        {/* Modals */}
        <ActionModal
          open={action.modal === 'unassign-users'}
          isLoading={isLoading}
          onClose={() => setAction({ modal: undefined })}
          primaryAction={async (): Promise<void> => {
            if (action.modal !== 'unassign-users') return
            await unassignFromProgram(programId, { userIds: action.targets })
            action.callback?.()
            setAction({ modal: undefined })
            notifications.push({
              type: 'custom',
              level: 'success',
              body: t('notifications.done'),
            })
          }}
          primaryActionLabel={t('admin.remove')}
          title={
            action.modal === 'unassign-users' && action.targets.length === 1
              ? t('manage.program.remove-user-singular.title')
              : t('manage.program.remove-user-plural.title')
          }
          deleteAction
        >
          {action.modal === 'unassign-users' && action.targets.length === 1
            ? t('manage.program.remove-user-singular.message')
            : t('manage.program.remove-user-plural.message')}
        </ActionModal>
        <ActionModal
          open={blocker.status === 'blocked'}
          title={t('manage.empty-program')}
          primaryActionLabel={t('manage.delete-program')}
          onClose={blocker.reset}
          primaryAction={async () => {
            blocker.proceed()
            await deleteProgram(programId)
          }}
          deleteAction
        >
          {t('manage.program-name-or-delete')}
        </ActionModal>
        {action.modal === 'heatmap' && (
          <ProgramHeatmap
            onClose={() => setAction({ modal: undefined })}
            programId={programId}
            programName={program.name}
          />
        )}
        <AdminModal
          open={action.modal === 'program-admins'}
          hasFullAccess={orgPermissions.has('ACCESS_ALL_PROGRAMS')}
          onClose={() => setAction({ modal: undefined })}
          canEdit={programPermissions.has('EDIT_ADMINS')}
          adminState={programAdminState}
        />
        {/* Create enrollment rule */}
        {programPermissions.has('MANAGE_ENROLLMENT_RULES') && domainReps !== undefined && (
          <EnrollmentRuleModal
            programId={programId}
            image={program.image}
            domainReps={domainReps}
            open={action.modal === 'create-enrollment-rule'}
            onClose={() => setAction({ modal: undefined })}
            onSave={refreshProgram}
            mode='create'
          />
        )}
        {programPermissions.has('EDIT_METADATA') && (
          <EditProgramModal
            open={panel === 'edit'}
            action={action}
            setAction={setAction}
            setPanel={setPanel}
            canManageCertificates={programPermissions.has('MANAGE_CERTIFICATES')}
            onDelete={async (): Promise<void> => {
              await deleteProgram(programId)
              await getGlobalRouter().navigate({ to: '/manage/programs' })
            }}
            update={refreshProgram}
            programId={programId}
          />
        )}
        <EnrollOnceModal
          programId={programId}
          image={program.image}
          title={program.name}
          open={action.modal === 'users'}
          onClose={() => {
            setAction({ modal: undefined })
          }}
          onSave={refreshProgram}
        />
      </ManageContainer>
    </>
  )
}
