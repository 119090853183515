import { useRef } from 'react'
import { Either, left, right } from 'sierra-domain/either'

interface TooFast {
  tryAgainInMs: number
}

/**
 * Returns 'ok' if at least `intervalMs` have elapsed since the last call.
 * Otherwise, returns an object with the number of milliseconds until the next call.
 */
export function useAtLeastElapsed(intervalMs: number): () => Either<'ok', TooFast> {
  const lastCalled = useRef<number>(Date.UTC(1970, 1, 1))
  return () => {
    const now = Date.now()
    const elapsedMs = now - lastCalled.current
    if (elapsedMs >= intervalMs) {
      lastCalled.current = Date.now()
      return left('ok')
    } else {
      return right({ tryAgainInMs: intervalMs - elapsedMs })
    }
  }
}
