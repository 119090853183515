import React, { useMemo } from 'react'
import { AdditionalParticipants } from 'sierra-client/components/liveV2/additional-participants'
import { useParticipantPictureInPicture } from 'sierra-client/components/liveV2/hooks/use-participant-picture-in-picture'
import { FirstOneHereView } from 'sierra-client/components/liveV2/live-layer/first-one-here-view'
import { LocalParticipant, Participant } from 'sierra-client/components/liveV2/participant'
import { ParticipantIdentifier } from 'sierra-client/components/liveV2/types'
import { useSelector } from 'sierra-client/state/hooks'
import { selectClientId } from 'sierra-client/state/live/selectors'
import { CreateContentId } from 'sierra-domain/api/nano-id'
import { isDefined } from 'sierra-domain/utils'
import { ChatOverflowDirection, ParticipantGrid } from 'sierra-ui/missions/live'

const _ParticipantGridContainer: React.FC<{
  className?: string
  flexibleContentId: CreateContentId
  participants: ParticipantIdentifier[]
  passiveParticipants?: ParticipantIdentifier[]
  chatOverflowDirection?: ChatOverflowDirection
  useLowResVideo?: boolean
  oneColumnLayout?: boolean
}> = ({
  flexibleContentId,
  className,
  participants,
  passiveParticipants,
  chatOverflowDirection,
  useLowResVideo,
  oneColumnLayout = false,
}) => {
  useParticipantPictureInPicture(flexibleContentId)
  const clientId = useSelector(selectClientId)

  const participantTiles = useMemo(() => {
    const stableParticipantsTiles = participants.map(participant =>
      participant.agoraUID === clientId
        ? {
            element: <LocalParticipant />,
            key: participant.agoraUID,
          }
        : {
            element: (
              <Participant
                quality={useLowResVideo === true ? 'low' : 'auto'}
                key={participant.agoraUID}
                participant={participant}
                chatOverflowDirection={chatOverflowDirection}
              />
            ),
            key: participant.agoraUID,
          }
    )

    const passiveParticipantsTile =
      passiveParticipants !== undefined && passiveParticipants.length > 0
        ? {
            element: <AdditionalParticipants participants={passiveParticipants} />,
            key: 'additional participants',
          }
        : undefined

    return [...stableParticipantsTiles, passiveParticipantsTile].filter(isDefined)
  }, [chatOverflowDirection, clientId, participants, passiveParticipants, useLowResVideo])

  return (
    <ParticipantGrid
      oneColumnLayout={oneColumnLayout}
      participants={participantTiles}
      className={className}
      FirstOneHereView={FirstOneHereView}
    />
  )
}

export const ParticipantGridContainer = React.memo(_ParticipantGridContainer)
