import { ProgramOutline } from 'sierra-domain/api/manage'
import { isDefined, isNotDefined } from 'sierra-domain/utils'

type OutlineCluster = {
  sectionIndex: number | null
  members: ProgramOutline['steps']
}

export const clusterSections = (outline: ProgramOutline): OutlineCluster[] => {
  const sectionReferences = new Set(outline.sections.map((_, index) => index))
  let totalStepCount = 0

  const clusters = outline.steps.reduce((clusters, step) => {
    totalStepCount += 1

    const previousCluster = clusters[clusters.length - 1]

    if (isDefined(step.sectionIndex)) {
      sectionReferences.delete(step.sectionIndex)
    }

    // There's no cluster at all at this point. Return a new cluster.
    if (isNotDefined(previousCluster)) {
      return [
        {
          sectionIndex: step.sectionIndex ?? null,
          members: [step],
        },
      ]
    }

    const lastItem = previousCluster.members[previousCluster.members.length - 1]

    /**
     * Append to the previous cluster if the steps are pointing to the same section.
     * Steps on the root level are clustered invidiually.
     */
    const shouldAppend =
      isDefined(lastItem) && isDefined(lastItem.sectionIndex) && lastItem.sectionIndex === step.sectionIndex

    if (shouldAppend) {
      previousCluster.members.push(step)

      // Add the current item to the previous existing cluster.
      return [...clusters.slice(0, -1), previousCluster]
    } else {
      // Add the current item to a new cluster, and append it to the existing list
      return [
        ...clusters,
        {
          sectionIndex: step.sectionIndex ?? null,
          members: [step],
        },
      ]
    }
  }, [] as OutlineCluster[])

  // Append empty section clusters
  const sectionClusters = Array.from(sectionReferences)
    .map(sectionIndex => {
      const section = outline.sections[sectionIndex]

      return section === undefined
        ? undefined
        : {
            sectionIndex,
            section,
            selfIndex: 'selfIndex' in section ? section.selfIndex ?? null : null,
          }
    })
    .filter(isDefined)

  for (const sectionCluster of sectionClusters) {
    const at = sectionCluster.selfIndex ?? totalStepCount
    const cluster = {
      sectionIndex: sectionCluster.sectionIndex,
      members: [],
    }

    // Find out where we should insert the empty section cluster
    // by counting the steps in the clusters
    if (at === 0) {
      clusters.splice(0, 0, cluster)
    } else {
      let count = 0
      for (let i = 0; i < clusters.length; i++) {
        const stepCount = clusters[i]?.members.length
        if (stepCount !== undefined) {
          count += stepCount
          if (count >= at) {
            clusters.splice(i + 1, 0, cluster)
            break
          }
        }
      }
    }
  }

  return clusters
}
