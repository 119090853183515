import fuzzysort from 'fuzzysort'
import { useCallback, useMemo, useState } from 'react'
import { ContentRow } from 'sierra-client/components/common/content-elements'
import { useNotif } from 'sierra-client/components/common/notifications'
import { useShortcutMenuDispatch } from 'sierra-client/components/shortcut-menu/context'
import { useCourseSearch } from 'sierra-client/components/shortcut-menu/courses-search'
import { resolveIndex } from 'sierra-client/components/shortcut-menu/resolve-index'
import {
  SearchBar,
  SearchBarContainer,
  SearchResult,
  SearchResults,
} from 'sierra-client/components/shortcut-menu/search-ui'
import { useUserSearch } from 'sierra-client/components/shortcut-menu/user-search'
import { usePost } from 'sierra-client/hooks/use-post'
import { getAvatarImage } from 'sierra-client/utils/avatar-img'
import { CourseSummary } from 'sierra-domain/api/admin'
import { CourseId } from 'sierra-domain/api/nano-id'
import { UserId } from 'sierra-domain/api/uuid'
import { XRealtimeAdminAssignmentsSetContentAssignmentsWithDueDates } from 'sierra-domain/routes'
import { BaseUserRow } from 'sierra-domain/user/base-user-row'
import { asNonNullable, getUserName } from 'sierra-domain/utils'
import { RoundAvatar, getAvatarPropsFromBaseUserInfo } from 'sierra-ui/components'
import { Text } from 'sierra-ui/primitives'
import { fonts } from 'sierra-ui/theming/fonts'
import styled from 'styled-components'

const HighlightableText = styled(Text)`
  display: flex;
  align-items: center;

  margin: 0 1.5rem;

  .highlight {
    font-weight: ${fonts.weight.bold};
  }
`

const UserViewComponent: React.FC<{ user: BaseUserRow }> = ({ user }) => {
  return (
    <>
      <RoundAvatar {...getAvatarPropsFromBaseUserInfo(user)} src={getAvatarImage(user.userId, user.avatar)} />
      <Text size='regular'>{getUserName(user)}</Text>
      <Text size='regular'>{user.email}</Text>
    </>
  )
}

const CourseViewComponent: React.FC<{ course: CourseSummary }> = ({ course }) => {
  return (
    <>
      <ContentRow
        assetContext={{ type: 'course', courseId: CourseId.parse(course.courseId) }}
        contentType='course'
        courseKind={course.kind}
        title={course.title}
        image={course.image}
        isCourseEdition={course.isCourseEdition}
      />
    </>
  )
}

export const UserAndCourseAssignment: React.FC<{
  preselectCourse?: string
  preUser?: string
}> = ({ preselectCourse, preUser }) => {
  const { postWithUserErrorException } = usePost()
  const dispatch = useShortcutMenuDispatch()
  const [courseQuery, setCourseQuery] = useState(preselectCourse ?? '')
  const [query, setQuery] = useState(preUser ?? '')
  const [searchState, setSearchState] = useState('course')

  const userResults = useUserSearch(query)
  const courseResults = useCourseSearch()

  const [courseIndex, setCourseIndex] = useState(0)
  const [userIndex, setUserIndex] = useState(0)

  const [course, setCourse] = useState<CourseSummary>()

  const notifications = useNotif()

  const filteredCourses = useMemo(
    () =>
      courseQuery === ''
        ? courseResults
        : fuzzysort.go(courseQuery, courseResults, { keys: ['title'] }).map(({ obj }) => obj),
    [courseQuery, courseResults]
  )

  const assign = useCallback(
    async (userId: UserId, courseId: string): Promise<void> => {
      await postWithUserErrorException(XRealtimeAdminAssignmentsSetContentAssignmentsWithDueDates, {
        assignments: [
          {
            assignee: { type: 'user', id: userId },
            content: { id: courseId, type: 'course' },
          },
        ],
      })

      notifications.push({
        type: 'custom',
        level: 'success',
        body: 'Assignment successful', //TODO: add translation?
      })

      void dispatch({ type: 'close' })
    },
    [dispatch, postWithUserErrorException, notifications]
  )

  function onResultSelected(result: CourseSummary | undefined): void {
    setSearchState('user')
    setCourse(result)
  }

  return (
    <>
      {searchState === 'course' && (
        <>
          <SearchBarContainer>
            <SearchBar
              value={courseQuery}
              label={`Assign content`}
              onChange={text => {
                setCourseQuery(text)
                setCourseIndex(0)
              }}
              onIndexChanged={value => {
                setCourseIndex(prev =>
                  resolveIndex(value === 'decrement' ? prev - 1 : prev + 1, filteredCourses)
                )
              }}
              onResultSelected={() => {
                onResultSelected(filteredCourses[courseIndex])
              }}
            />
          </SearchBarContainer>
          <HighlightableText size='small' color='grey35' bold>
            Select content
          </HighlightableText>
          <SearchResults>
            {filteredCourses.slice(0, 20).map(res => (
              <SearchResult
                onClick={() => {
                  setSearchState('user')
                  setCourse(res)
                }}
                $selected={filteredCourses[courseIndex]?.courseId === res.courseId}
                key={res.courseId}
              >
                <CourseViewComponent course={res} />
              </SearchResult>
            ))}
          </SearchResults>
        </>
      )}
      {searchState === 'user' && (
        <>
          <SearchBarContainer>
            <SearchBar
              value={query}
              label={`Assign ${course?.title}`}
              onChange={text => {
                setQuery(text)
                setUserIndex(0)
              }}
              onIndexChanged={value => {
                setUserIndex(prev => resolveIndex(value === 'decrement' ? prev - 1 : prev + 1, userResults))
              }}
              onResultSelected={() =>
                assign(asNonNullable(userResults[userIndex]?.userId), asNonNullable(course?.courseId))
              }
            />
          </SearchBarContainer>
          <HighlightableText size='small' color='grey35' bold>
            Assign to
          </HighlightableText>
          <SearchResults>
            {userResults.map(user => (
              <SearchResult
                onClick={() => assign(asNonNullable(user.userId), asNonNullable(course?.courseId))}
                $selected={userResults[userIndex]?.userId === user.userId}
                key={user.userId}
              >
                <UserViewComponent user={user} />
              </SearchResult>
            ))}
          </SearchResults>
        </>
      )}
    </>
  )
}
