import React from 'react'
import { ActionModal } from 'sierra-client/components/common/modals/action-modal'
import { useTranslation } from 'sierra-client/hooks/use-translation'
import { useComposeUserInvitationConfig } from 'sierra-client/views/manage/components/user-attributes/flows/invite-users/hooks/use-compose-user-invitation-config'
import { MatchingUsers } from 'sierra-ui/missions/workflows/panels/components/matching-users'
import { Spacer } from 'sierra-ui/primitives'

export const EmptyUsers: React.FC<{
  open: boolean
  isLoading: boolean
  onAbort: () => void
  onConfirm: () => void
}> = ({ open, isLoading, onAbort, onConfirm }) => {
  const { t } = useTranslation()
  const { emptyUsers } = useComposeUserInvitationConfig()

  return (
    <ActionModal
      title={t('manage.users.user-attributes.alerts.missing-user-details.title')}
      open={open}
      isLoading={isLoading}
      onClose={onAbort}
      primaryAction={onConfirm}
      primaryActionLabel={t('manage.users.invite.send-invites')}
      secondaryAction={onAbort}
      secondaryActionLabel={t('modal.cancel')}
      size={{ width: 500, height: 300 }}
    >
      {t('manage.users.user-attributes.alerts.missing-user-details.description-2')}
      <Spacer />
      <MatchingUsers
        users={emptyUsers}
        label={t('manage.users.user-attributes.alerts.missing-user-details.body', {
          count: emptyUsers.length,
        })}
        padding='16'
      />
      <Spacer />
    </ActionModal>
  )
}
