import { useEffect } from 'react'
import { TeamspaceAccess } from 'sierra-client/features/teamspace/hooks/use-check-teamspace-access'
import { accessDeniedRedirect, notFound404Redirect } from 'sierra-client/router/navigation'

export const useTeamspaceAccessRedirect = (access: TeamspaceAccess): void => {
  useEffect(() => {
    switch (access) {
      case TeamspaceAccess.ALLOWED:
        break

      case TeamspaceAccess.DENIED:
        void accessDeniedRedirect()
        break

      case TeamspaceAccess.ERROR:
        void notFound404Redirect()
        break

      case TeamspaceAccess.LOADING:
        break

      default:
        access satisfies never
    }
  }, [access])
}
