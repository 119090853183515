import { UseQueryResult } from '@tanstack/react-query'
import { useCachedQuery } from 'sierra-client/state/api'
import { HeatmapUserFilter } from 'sierra-client/views/manage/reports/components/heatmap/types'
import { HeatmapAvailableContentResponse } from 'sierra-domain/api/heatmap'
import { CourseId } from 'sierra-domain/api/nano-id'
import { XRealtimeAdminHeatmapAvailableContent2 } from 'sierra-domain/routes'

type AvailableContentParams = {
  userFilter?: HeatmapUserFilter | undefined
  contentQuery?: string
  includeIds?: Array<CourseId>
  limit?: number
  skip?: boolean
}

export const useAvailableContent = ({
  userFilter,
  contentQuery,
  includeIds,
  limit = 30,
  skip = false,
}: AvailableContentParams): UseQueryResult<HeatmapAvailableContentResponse['data']> => {
  const availableContentRes = useCachedQuery(
    XRealtimeAdminHeatmapAvailableContent2,
    {
      query: userFilter?.type === 'filter' ? userFilter.query : undefined,
      filter: userFilter?.type === 'filter' ? userFilter.filter : undefined,
      programIds: userFilter?.type === 'program' ? userFilter.programIds : undefined,
      includeIds: includeIds,
      limit,
      contentQuery: contentQuery,
    },
    {
      enabled: !skip,
      staleTime: 60_000,
      gcTime: 60_000,
      select: res => res.data,
      placeholderData: x => x,
    }
  )

  return availableContentRes
}
