import { CustomInterfaceThemeProvider } from 'sierra-client/components/common/custom-interface-theme-provider'
import { config } from 'sierra-client/config/global-config'
import { AppThemeTokenProvider } from 'sierra-client/config/token-provider'
import { useTranslation } from 'sierra-client/hooks/use-translation'
import { HeaderLogo } from 'sierra-client/layout/header/home/components/logo'
import { FCC } from 'sierra-client/types'
import { AuthenticationView } from 'sierra-client/views/authentication/native/components/common'
import { Heading, Spacer, View } from 'sierra-ui/primitives'
import { token } from 'sierra-ui/theming'
import { legacyLight } from 'sierra-ui/theming/legacy-theme'
import styled, { ThemeProvider } from 'styled-components'

const BoxedContent = styled(View)`
  width: 100%;
  background-color: ${token('surface/default')};
  border-radius: 20px;
  padding: 4rem 3rem 3rem;
`

export const AuthenticationContent: FCC<{
  message?: string
  productTourId?: string
}> = ({ children, message, productTourId }) => {
  const { t } = useTranslation()
  const orgConfig = config.organization
  const welcomeMessage = orgConfig.settings.brand.welcomeMessage

  return (
    <ThemeProvider theme={legacyLight}>
      <AppThemeTokenProvider>
        <CustomInterfaceThemeProvider>
          <BoxedContent id={productTourId} direction='column' alignItems='center' grow>
            <HeaderLogo />
            <Spacer size='xxsmall' />
            <Heading color='foreground/primary' size='h3' bold align='center'>
              {message ?? welcomeMessage ?? t('create-account.welcome')}
            </Heading>
            <Spacer size='xsmall' />
            <AuthenticationView direction='column' alignItems='center' grow gap='xsmall'>
              {children}
            </AuthenticationView>
          </BoxedContent>
        </CustomInterfaceThemeProvider>
      </AppThemeTokenProvider>
    </ThemeProvider>
  )
}
