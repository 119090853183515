import { produce } from 'immer'
import React from 'react'
import { useTranslation } from 'sierra-client/hooks/use-translation'
import { DropdownWidth } from 'sierra-client/views/manage/users/components/user-settings-panel/general-tab/common'
import { useGeneralSettingsDraft } from 'sierra-client/views/manage/users/components/user-settings-panel/general-tab/use-general-settings-draft'
import { getUserStatusLevelOptions } from 'sierra-client/views/manage/users/user-utils'
import { UserStatus } from 'sierra-domain/user/status'
import { MenuItem } from 'sierra-ui/components'
import { SingleSelectDropdown } from 'sierra-ui/primitives/menu-dropdown'

export const UserStatusDropdown: React.FC = () => {
  const { t } = useTranslation()
  const menuItems: MenuItem[] = getUserStatusLevelOptions(t)
  const { generalSettingsDraft, setGeneralSettingsDraft, readOnlyMetadata } = useGeneralSettingsDraft()

  const handleSelect = (item: MenuItem): void =>
    setGeneralSettingsDraft(
      produce(generalSettingsDraft, edit => {
        edit.userStatus = item.id as UserStatus
      })
    )

  return (
    <DropdownWidth>
      <SingleSelectDropdown
        disabled={readOnlyMetadata || generalSettingsDraft.userStatus === 'pending'}
        selectedItem={menuItems.find(i => i.id === generalSettingsDraft.userStatus)}
        menuItems={menuItems}
        onSelect={handleSelect}
      />
    </DropdownWidth>
  )
}
