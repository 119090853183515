import { UseQueryResult } from '@tanstack/react-query'
import { graphql } from 'sierra-client/api/graphql/gql'
import {
  FetchProgramEnrollmentCountQueryVariables,
  FetchProgramOutlineQuery,
  FetchProgramOutlineQueryVariables,
} from 'sierra-client/api/graphql/gql/graphql'
import { graphQuery, useGraphQuery, useInvalidateGraphQuery } from 'sierra-client/api/hooks/use-graphql-query'

export const fetchProgramOutlineQuery = graphql(`
  query fetchProgramOutline($programId: ProgramId!, $version: Int = null) {
    program(id: $programId, version: $version) {
      enrollmentCount
      sections {
        id
        title
        description
      }
      steps {
        __typename
        schedule {
          __typename
          ... on AbsoluteDate {
            date
          }
          ... on RelativeDate {
            offset {
              value
              ... on Days {
                __typename
              }
            }
          }
          ... on Directly {
            isDirectly
          }
          ... on OnCompletionOfPrevious {
            offset {
              value
              ... on Days {
                __typename
              }
            }
          }
        }
        ... on EmailProgramStep {
          title
          type
          sectionIndex
          sectionId
          image {
            ...ImageFragment
          }
          resourceId
          emailTemplateBindingsId
        }
        ... on CourseProgramStep {
          type
          sectionIndex
          sectionId
          dueDate {
            ... on Absolute {
              __typename
              date
            }
            ... on Relative {
              __typename
              days
            }
          }
          autoAssignLiveSession
          courseId
          course {
            courseKind
            image {
              ...ImageFragment
            }
            title
          }
          enableSelfEnrollment
        }
        ... on PathProgramStep {
          type
          sectionIndex
          sectionId
          dueDate {
            ... on Absolute {
              __typename
              date
            }
            ... on Relative {
              __typename
              days
            }
          }
          autoAssignLiveSession
          enableSelfEnrollment
          pathId
          path {
            title
            image {
              ...ImageFragment
            }
          }
        }
      }
      id
      name
      version
      description
      stepsCount
    }
  }
`)

export const useFetchProgramOutline = (
  params: FetchProgramOutlineQueryVariables
): UseQueryResult<FetchProgramOutlineQuery, unknown> => {
  const query = useGraphQuery({ document: fetchProgramOutlineQuery }, { ...params })
  return query
}

export const useInvalidateFetchProgramOutline = (
  params: FetchProgramOutlineQueryVariables
): (() => Promise<void>) => {
  const invalidateFetchProgramOutline = useInvalidateGraphQuery(fetchProgramOutlineQuery, params)
  return invalidateFetchProgramOutline
}

export const fetchProgramOutline = async (
  params: FetchProgramOutlineQueryVariables
): Promise<FetchProgramOutlineQuery> => graphQuery(fetchProgramOutlineQuery, params)

const fetchProgramEnrollmentCountQuery = graphql(`
  query fetchProgramEnrollmentCount($programId: ProgramId!, $version: Int = null) {
    program(id: $programId, version: $version) {
      enrollmentCount
    }
  }
`)
export const fetchProgramEnrollmentCount = async (
  params: FetchProgramEnrollmentCountQueryVariables
): Promise<number> =>
  graphQuery(fetchProgramEnrollmentCountQuery, params).then(x => x.program?.enrollmentCount ?? 0)
