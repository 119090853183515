import React, { useRef } from 'react'
import { percentage, useTimeFormatter } from 'sierra-client/core/format'
import { useContentKindPermissions } from 'sierra-client/hooks/use-permissions'
import { useTranslation } from 'sierra-client/hooks/use-translation'
import { TabularProviderFromTableAPI } from 'sierra-client/lib/tabular/provider'
import { BasicTabularSimpleSize } from 'sierra-client/lib/tabular/provider/components/basic'
import { ExportCSVIconButton } from 'sierra-client/views/manage/components/export-csv'
import { CourseGroupActionModalControl } from 'sierra-client/views/manage/courses/course-group-action-modals'
import { useCourseGroupEditionsTableAPI } from 'sierra-client/views/manage/courses/use-manage-course-group-editions-table'
import { CourseGroupDetailRow } from 'sierra-domain/api/manage'
import { CourseId } from 'sierra-domain/api/nano-id'
import { Button, scrollViewStyles, View } from 'sierra-ui/primitives'
import styled from 'styled-components'

type CourseGroupCsv = {
  name: string
  added: string
  learners: number
  progress: string
  duration: string
}

const mapCourseGroupToCsv = (
  timeFormatter: (timeInSeconds: number | undefined, remaining?: boolean) => string | undefined
): ((cg: CourseGroupDetailRow) => CourseGroupCsv) => {
  return (cg: CourseGroupDetailRow) => ({
    name: cg.title,
    added: cg.publishedAt?.toISOString() ?? '',
    learners: cg.enrollmentCount,
    progress: percentage(cg.completionRate),
    duration: cg.timeTotal === 0 ? '-' : timeFormatter(cg.timeTotal) ?? '-',
  })
}

const TableWrapper = styled(View)`
  ${scrollViewStyles};
  min-height: 10rem;
  max-height: calc(100vh - 21.875rem);
  height: 100%;
  width: 100%;
`

const Table: React.FC = () => {
  const scrollRef = useRef(null)

  return (
    <TableWrapper ref={scrollRef} alignItems='flex-start' direction='column'>
      <BasicTabularSimpleSize scrollRef={scrollRef.current} />
    </TableWrapper>
  )
}

type FooterProps = {
  canEditAssignments: boolean
  csvData: CourseGroupCsv[]
  manageEditions: () => void
}

const Footer: React.FC<FooterProps> = ({ canEditAssignments, csvData, manageEditions }) => {
  const { t } = useTranslation()
  return (
    <View marginBottom='32' marginTop='32' justifyContent={canEditAssignments ? 'space-between' : 'flex-end'}>
      {canEditAssignments === true && (
        <Button onClick={manageEditions} variant='secondary'>
          {t('manage.course-groups.update-course-group.manage-editions')}
        </Button>
      )}
      <ExportCSVIconButton fetchCsvData={() => csvData} filename={t('admin.analytics.groups')} />
    </View>
  )
}

type Props = {
  data: CourseGroupDetailRow[]
  courseGroupId: CourseId
  modal: CourseGroupActionModalControl
}

export const CourseGroupEditionsTable: React.FC<Props> = ({ data, modal, courseGroupId }) => {
  const courseGroupPermissions = useContentKindPermissions(courseGroupId)
  const { timeFormatter } = useTimeFormatter()

  const tableAPI = useCourseGroupEditionsTableAPI({
    data,
    courseGroupId,
    modal,
  })

  const mapCsv = (data: CourseGroupDetailRow[]): CourseGroupCsv[] => {
    return data.map(mapCourseGroupToCsv(timeFormatter))
  }

  return (
    <TabularProviderFromTableAPI tableAPI={tableAPI}>
      <Table />
      <Footer
        canEditAssignments={courseGroupPermissions.has('EDIT_ASSIGNMENTS')}
        csvData={mapCsv(data)}
        manageEditions={() => modal.open({ modal: 'update-course-group' })}
      />
    </TabularProviderFromTableAPI>
  )
}
