import { UseQueryResult } from '@tanstack/react-query'
import { CachedQueryOptions, useCachedQuery } from 'sierra-client/state/api'
import { useSelfPacedFiles } from 'sierra-client/views/self-paced/files-provider'
import { CourseHelperContentResponse } from 'sierra-domain/api/learn'
import { XRealtimeCourseHelperContent } from 'sierra-domain/routes'

export const useCourseHelperContent = <TSelectData = CourseHelperContentResponse,>(
  options: CachedQueryOptions<TSelectData> = {}
): UseQueryResult<TSelectData> => {
  const { flexibleContentId } = useSelfPacedFiles()

  return useCachedQuery(
    XRealtimeCourseHelperContent,
    { courseId: flexibleContentId },
    {
      retry: false,
      ...options,
    }
  )
}
