import { AnimatePresence, motion } from 'framer-motion'
import React, { useMemo } from 'react'
import { useDuplicateEditableContentMutation } from 'sierra-client/api/hooks/use-editable-content'
import { ContentTableRow } from 'sierra-client/components/common/content-table-row'
import { useContentDrag } from 'sierra-client/components/common/dnd/use-content-table-drag'
import { IconMenu } from 'sierra-client/components/common/icon-menu'
import { ActionModal } from 'sierra-client/components/common/modals/action-modal'
import { Thumbnail as CommonThumbnail } from 'sierra-client/components/common/thumbnail'
import { getAssetContextFromTeamspaceContent } from 'sierra-client/components/util/asset-contex'
import { IconMenuWithMoveToTeamspaces } from 'sierra-client/features/teamspace'
import { teamspaceContentClickedLogger } from 'sierra-client/features/teamspace/logger'
import { useDeleteCourse } from 'sierra-client/hooks/use-delete-course'
import { usePost } from 'sierra-client/hooks/use-post'
import { useResolveAsset } from 'sierra-client/hooks/use-resolve-asset'
import { useTranslation } from 'sierra-client/hooks/use-translation'
import { getGlobalRouter } from 'sierra-client/router'
import * as settingsActions from 'sierra-client/state/author-course-settings/actions'
import { useIsSmallDesktop } from 'sierra-client/state/browser/selectors'
import { useDispatch } from 'sierra-client/state/hooks'
import { getContentClassificationData } from 'sierra-client/views/manage/content/utils/content-utils'
import { CTAActions } from 'sierra-client/views/workspace/components/content-table-cta-buttons'
import {
  CoursePublishStateLabel,
  LastUpdateLabel,
  MetadataWrapper,
  RowContainer,
} from 'sierra-client/views/workspace/components/content-table-row-components'
import { StyledThumbnail } from 'sierra-client/views/workspace/create/styled-thumbnail'
import {
  SelfEnrollActionMetaData,
  getCurrentFolderDisplayName,
  pinnableContentType,
} from 'sierra-client/views/workspace/teamspace/common-table-row'
import { Linkable, continueUrl, manageUrl } from 'sierra-client/views/workspace/utils/urls'
import { editUrl } from 'sierra-domain/api/editable-content'
import { CourseId } from 'sierra-domain/api/nano-id'
import {
  Teamspace,
  TeamspaceEditableContent,
  TeamspaceNonEditorCourse,
  isCourseGroup,
} from 'sierra-domain/api/teamspace'
import { XRealtimeUserAddPins, XRealtimeUserRemovePins } from 'sierra-domain/routes'
import {
  NonEmptyArray,
  STATIC_EMPTY_ARRAY,
  iife,
  isDefined,
  isNonEmptyArray,
  noOp,
} from 'sierra-domain/utils'
import { Icon, MenuItem, Tooltip } from 'sierra-ui/components'
import { IconButton, Text, View } from 'sierra-ui/primitives'
import { token } from 'sierra-ui/theming'
import { v2_breakpoint } from 'sierra-ui/theming/breakpoints'
import styled from 'styled-components'

const EditorRow: React.FC<{
  teamspaceContent: TeamspaceEditableContent | TeamspaceNonEditorCourse
  teamspace?: Teamspace
  isPinned: boolean
  isCourseEdition?: boolean
  showCurrentFolder: boolean
  onDeleted?: () => void
  onPin?: (id: string, isPinned: boolean) => void
  isOver: boolean
  onDuplicate?: () => void
  onMoveToTeamspace?: () => void
  collapsable?: {
    collapsed: boolean
    onChange: (_: boolean) => void
  }
  readOnly?: boolean
}> = ({
  teamspaceContent,
  teamspace,
  isPinned,
  isCourseEdition,
  showCurrentFolder,
  onDeleted,
  onPin,
  isOver,
  onDuplicate,
  onMoveToTeamspace,
  collapsable,
  // Allow the editorrow to be read only which hides CTA:s
  readOnly = false,
}) => {
  const dispatch = useDispatch()
  const { t, dynamicT } = useTranslation()
  const { postWithUserErrorException } = usePost()
  const isDesktop = !useIsSmallDesktop()
  const duplicateEditableContentMutation = useDuplicateEditableContentMutation()
  const content = teamspaceContent.content

  const assetContext = useMemo(
    () => getAssetContextFromTeamspaceContent(teamspaceContent),
    [teamspaceContent]
  )
  const { drag, isDragging, canDrag } = useContentDrag(content, assetContext)

  const { type } = content
  const isSelfpaced = type === 'native:self-paced'
  const isTemplate = (isSelfpaced || type === 'native:live') && content.templateSettings !== undefined
  const isEditableContent = teamspaceContent.type === 'editable'
  const isNonEditorCourseType = teamspaceContent.type === 'non-editor-course'

  const linkable: Linkable = {
    id: content.id,
    type: content.type,
  }
  const link = editUrl(content.type, content.id)

  const { remove, loading, showDeleteConfirm, openDeleteConfirm, closeDeleteConfirm } = useDeleteCourse({
    courseId: CourseId.parse(content.id),
    onDeleted: onDeleted ?? noOp,
  })

  const addPin = async (): Promise<void> => {
    await postWithUserErrorException(XRealtimeUserAddPins, {
      items: [{ id: content.id, type: pinnableContentType(content.type) }],
    }).then(() => onPin?.(content.id, true))
  }

  const removePin = async (): Promise<void> => {
    await postWithUserErrorException(XRealtimeUserRemovePins, {
      items: [{ id: content.id, type: pinnableContentType(content.type) }],
    }).then(() => onPin?.(content.id, false))
  }

  const defaultItems: MenuItem[] = [
    {
      id: 'edit',
      type: 'label',
      label: t('dictionary.edit'),
      icon: 'edit',
      onClick: () => {
        void getGlobalRouter().navigate({ to: editUrl(content.type, content.id) })
      },
    },
    {
      hidden: isSelfpaced && !content.published,
      id: 'view',
      type: 'label',
      label: t('dictionary.view'),
      icon: 'view',
      onClick: () => {
        void getGlobalRouter().navigate({ to: continueUrl(linkable) as string })
      },
    },
    {
      hidden: isNonEditorCourseType,
      id: 'duplicate',
      type: 'label',
      icon: 'duplicate',
      animateIcon: false,
      label: t('dictionary.duplicate'),
      onClick: () => {
        duplicateEditableContentMutation.mutate(
          {
            originalFlexibleContentId: CourseId.parse(content.id),
            title: t('content.copy-of-title', { title: content.title }),
          },
          { onSuccess: onDuplicate }
        )
      },
    },
    {
      hidden: isSelfpaced && !content.published,
      id: 'manage',
      type: 'label',
      label: t('admin.author.manage'),
      icon: 'trend--up',
      onClick: () => {
        void getGlobalRouter().navigate({ to: manageUrl(linkable) as string })
      },
    },
    {
      id: 'settings',
      type: 'label',
      label: t('settings.settings'),
      icon: 'settings',
      onClick: async () => {
        await dispatch(settingsActions.fetch({ courseId: CourseId.parse(content.id) }))
        void dispatch(settingsActions.setView('course-settings'))
      },
    },
    {
      hidden: Boolean(isCourseEdition),
      id: 'pin',
      type: 'label',
      label: isPinned ? t('workspace.create.unpin-from-top') : t('workspace.create.pin-to-top'),
      icon: 'pin',
      onClick: async (): Promise<void> => {
        if (isPinned) {
          await removePin()
        } else {
          await addPin()
        }
      },
    },
    {
      id: 'delete',
      type: 'label',
      label: t('dictionary.delete'),
      icon: 'trash-can',
      color: 'destructive/background',
      onClick: openDeleteConfirm,
    },
  ]

  const currentFolderDisplayName = isDefined(teamspace)
    ? getCurrentFolderDisplayName(teamspace, teamspaceContent.parentFolderId)
    : undefined
  const currentFolderTitle =
    showCurrentFolder && isDefined(currentFolderDisplayName) ? `${currentFolderDisplayName} / ` : undefined

  const hideDetailsButton =
    teamspaceContent.content.type === 'native:live' ||
    teamspaceContent.content.type === 'native:event-group' ||
    ('published' in teamspaceContent.content && !teamspaceContent.content.published)

  const contentClassification = getContentClassificationData({
    contentType: 'course',
    courseKind: content.type,
    isCourseEdition: Boolean(isCourseEdition),
    isTemplate: isTemplate,
  })

  const thumbnailSrc = useResolveAsset({
    image: content.image,
    size: 'thumbnail',
    assetContext,
  })

  return (
    <RowContainer
      // TODO: `react-dnd` isn't maintained. Replace it with another library and remove this type assertion.
      // Do not allow for dragging in course editions
      ref={Boolean(isCourseEdition) ? null : (drag as (el: HTMLDivElement) => void)}
      role='listitem'
      isDroppable={false}
      isOver={isOver}
      isDragging={isDragging}
    >
      <ContentTableRow
        href={readOnly ? undefined : link}
        canDrag={canDrag && isCourseEdition === false}
        isOver={isOver}
        onRowClick={() => {
          if (content.teamspaceId !== undefined) {
            void dispatch(
              teamspaceContentClickedLogger({
                contentId: content.id,
                teamspaceId: content.teamspaceId,
                clickedFrom: 'page',
              })
            )
          }
        }}
        left={
          <>
            <View gap='8' grow>
              {isPinned && (
                <Tooltip title={t('workspace.create.pinned')}>
                  <Icon iconId='pin--filled' color='foreground/muted' />
                </Tooltip>
              )}
              <View wrap={isDesktop ? 'nowrap' : 'wrap'} gap='6'>
                {isDefined(currentFolderTitle) && (
                  <Text bold color='foreground/muted'>
                    {currentFolderTitle}
                  </Text>
                )}
                <Text size='small' bold>
                  {content.title}
                </Text>

                {isDefined(collapsable) && (
                  <IconButton
                    iconId={collapsable.collapsed ? 'chevron--up--small' : 'chevron--down--small'}
                    variant='transparent'
                    size='small'
                    aria-label={t('course-editions.view-actions')}
                    onClick={e => {
                      e.stopPropagation()
                      e.preventDefault()
                      collapsable.onChange(!collapsable.collapsed)
                    }}
                  />
                )}
              </View>
            </View>
            <MetadataWrapper wrap={isDesktop ? 'nowrap' : 'wrap'} gap='6' grow>
              <View gap='4'>
                {isDefined(contentClassification.iconId) && (
                  <Icon iconId={contentClassification.iconId} size='size-12' color='foreground/muted' />
                )}
                <Text capitalize='first' color='foreground/muted' bold size='small'>
                  {t(contentClassification.translationKey)}
                </Text>
              </View>
              {teamspaceContent.type === 'editable' && isDefined(teamspaceContent.content.language) && (
                <Text size='small' color='foreground/muted'>
                  {dynamicT(`language.${teamspaceContent.content.language}`)}
                </Text>
              )}
              {teamspaceContent.selfEnrollAction !== undefined && (
                <SelfEnrollActionMetaData selfEnrollAction={teamspaceContent.selfEnrollAction} />
              )}
              {isEditableContent && (
                <LastUpdateLabel
                  lastEditorId={teamspaceContent.content.lastEditedBy}
                  updatedAt={content.timestamp}
                />
              )}
              {isSelfpaced && !isTemplate && (
                <CoursePublishStateLabel published={content.published} pending={content.pending} />
              )}
            </MetadataWrapper>
          </>
        }
        thumbnail={<StyledThumbnail image={thumbnailSrc} />}
        right={<div></div>}
        actions={
          readOnly ? (
            <></>
          ) : (
            <>
              <CTAActions
                canEdit
                content={teamspaceContent.content}
                linkable={linkable}
                link={link}
                hideDetailsButton={hideDetailsButton}
              />
              {Boolean(isCourseEdition) ? (
                <IconMenu
                  iconId='overflow-menu--vertical'
                  closeOnPick
                  items={defaultItems}
                  color='foreground/muted'
                />
              ) : (
                <IconMenuWithMoveToTeamspaces
                  onMoveSuccess={onMoveToTeamspace}
                  defaultItems={defaultItems}
                  content={content}
                />
              )}
            </>
          )
        }
      />
      <ActionModal
        open={showDeleteConfirm}
        isLoading={loading}
        onClose={closeDeleteConfirm}
        primaryAction={remove}
        title={t('content.delete-headline')}
        primaryActionLabel={t('content.delete-button')}
        deleteAction
      >
        <View marginTop='8' marginBottom='8'>
          <Text size='regular'>{t('content.delete-confirm')}</Text>
        </View>
        <View marginBottom='32'>
          <CommonThumbnail image={thumbnailSrc} radius={0.5} width={2} height={2} />
          <Text size='regular' bold>
            {content.title}
          </Text>
        </View>
      </ActionModal>
    </RowContainer>
  )
}

const NestedContentUl = styled(motion.ul)`
  position: relative;
  display: flex;
  flex-direction: column;
  gap: 0px;
  margin-left: 52px;
  padding-left: 18px;
  overflow: hidden;

  @media (min-width: ${v2_breakpoint.desktop_small}) {
    margin-left: 66px;
    padding-left: 18px;
  }

  ::before {
    content: '';
    position: absolute;
    left: 0;
    top: 0;
    bottom: 0;
    width: 4px;
    background-color: ${token('border/default')};
    border-radius: 10px;
  }
`
const NestedContentLi = styled(motion.li)`
  width: 100%;
  display: inline-block;
  align-items: center;
  gap: 4px;
`

const TeamspaceContentCourseGroupEditions: React.FC<{
  open: boolean
  editions: NonEmptyArray<TeamspaceEditableContent | TeamspaceNonEditorCourse>
  totalEditions?: number
  onDuplicate?: () => void
}> = ({ open, editions, onDuplicate, totalEditions = editions.length }) => {
  const { t } = useTranslation()
  return (
    <AnimatePresence>
      {open && (
        <NestedContentUl
          initial='collapsed'
          animate='open'
          exit='collapsed'
          variants={{
            open: {
              opacity: 1,
              height: 'auto',
              marginTop: 0,
              transition: { staggerChildren: 0.04 },
            },
            collapsed: {
              opacity: 0,
              height: 0,
              marginTop: 0,
            },
          }}
          transition={{ duration: 0.15, ease: [0.25, 0.1, 0.25, 1] }}
        >
          {editions.map(edition => (
            <NestedContentLi
              key={edition.content.id}
              variants={{
                open: { opacity: 1 },
                collapsed: { opacity: 0 },
              }}
            >
              <EditorRow
                showCurrentFolder
                teamspaceContent={edition}
                isPinned={false}
                isOver={false}
                onDuplicate={onDuplicate}
                isCourseEdition
              />
            </NestedContentLi>
          ))}
          {iife(() => {
            const drafts = totalEditions - editions.length
            if (drafts === 0) {
              return null
            }
            return (
              <View gap='6' paddingTop='16'>
                <Text bold color='foreground/muted'>
                  {t('teamspace.course-group.editions.drafts.text', {
                    count: drafts,
                  })}
                </Text>
                <Tooltip
                  title={t('teamspace.course-group.editions.draft.information.text', {
                    count: drafts,
                  })}
                  delayDuration={200}
                >
                  <Icon iconId='information' color='foreground/muted' />
                </Tooltip>
              </View>
            )
          })}
        </NestedContentUl>
      )}
    </AnimatePresence>
  )
}

export const CourseGroupTableRow: React.FC<{
  teamspaceContent: TeamspaceNonEditorCourse
  groupedContent?: Array<TeamspaceEditableContent | TeamspaceNonEditorCourse>
  isPinned: boolean
  onDeleted: () => void
  onPin: (id: string, isPinned: boolean) => void
  isOver: boolean
  teamspace: Teamspace
  showCurrentFolder: boolean
  onDuplicate?: () => void
  onMoveToTeamspace?: () => void
  readOnlyParent?: boolean
}> = ({
  teamspaceContent,
  groupedContent = STATIC_EMPTY_ARRAY,
  isPinned,
  onDeleted,
  onPin,
  isOver,
  teamspace,
  showCurrentFolder,
  onDuplicate,
  onMoveToTeamspace,
  readOnlyParent,
}) => {
  const [editionOpen, setEditionOpen] = React.useState(true)
  return (
    <View grow direction='column' gap='none'>
      <EditorRow
        onMoveToTeamspace={onMoveToTeamspace}
        teamspaceContent={teamspaceContent}
        isPinned={isPinned}
        isOver={isOver}
        onPin={onPin}
        onDeleted={onDeleted}
        onDuplicate={onDuplicate}
        teamspace={teamspace}
        showCurrentFolder={showCurrentFolder}
        collapsable={{ collapsed: editionOpen, onChange: setEditionOpen }}
        readOnly={readOnlyParent}
      />
      {isNonEmptyArray(groupedContent) && (
        <TeamspaceContentCourseGroupEditions
          open={editionOpen}
          editions={groupedContent}
          totalEditions={isCourseGroup(teamspaceContent) ? teamspaceContent.content.numEditions : undefined}
          onDuplicate={onDuplicate}
        />
      )}
    </View>
  )
}

export const EditorContentTableRow: React.FC<{
  teamspaceContent: TeamspaceEditableContent | TeamspaceNonEditorCourse
  groupedContent: Array<TeamspaceEditableContent | TeamspaceNonEditorCourse>
  teamspace: Teamspace
  isPinned: boolean
  isOver: boolean
  showCurrentFolder: boolean
  onPin: (id: string, isPinned: boolean) => void
  onDeleted: () => void
  onDuplicate?: () => void
  onMoveToTeamspace?: () => void
}> = ({
  teamspaceContent,
  groupedContent,
  isPinned,
  onDeleted,
  onPin,
  isOver,
  teamspace,
  showCurrentFolder,
  onDuplicate,
  onMoveToTeamspace,
}) => {
  if (teamspaceContent.type === 'non-editor-course' && isCourseGroup(teamspaceContent)) {
    return (
      <CourseGroupTableRow
        teamspaceContent={teamspaceContent}
        groupedContent={groupedContent}
        isPinned={isPinned}
        onDeleted={onDeleted}
        onPin={onPin}
        isOver={isOver}
        teamspace={teamspace}
        showCurrentFolder={showCurrentFolder}
        onDuplicate={onDuplicate}
        onMoveToTeamspace={onMoveToTeamspace}
      />
    )
  }
  return (
    <EditorRow
      onMoveToTeamspace={onMoveToTeamspace}
      teamspaceContent={teamspaceContent}
      isPinned={isPinned}
      isOver={isOver}
      onPin={onPin}
      onDeleted={onDeleted}
      onDuplicate={onDuplicate}
      teamspace={teamspace}
      showCurrentFolder={showCurrentFolder}
    />
  )
}
