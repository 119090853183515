import React, { useMemo } from 'react'
import { SkillId } from 'sierra-client/api/graphql/branded-types'
import { SkillsTableData } from 'sierra-client/features/skills/components/tabular/types'
import { TranslationKey } from 'sierra-client/hooks/use-translation/types'
import { TabularToolbar } from 'sierra-client/lib/tabular/components/tabular-toolbar'
import { TableCallbacks, TabularProviderFromTableAPI } from 'sierra-client/lib/tabular/provider'
import { BasicTabular } from 'sierra-client/lib/tabular/provider/components/basic'
import { UseTableAPI } from 'sierra-client/lib/tabular/use-table-api'
import { getGlobalRouter } from 'sierra-client/router'
import { View } from 'sierra-ui/primitives'
import styled from 'styled-components'

const TableWrapper = styled(View)`
  height: 100%;
  width: 100%;
`

export const SkillsTabular: React.FC<{
  tableAPI: UseTableAPI<SkillsTableData, any>
}> = ({ tableAPI }) => {
  const memoizedCallbacks = useMemo<TableCallbacks<SkillsTableData>>(
    () => ({
      onRow: id =>
        getGlobalRouter().navigate({
          to: `/skill/$skillId`,
          params: { skillId: id.ref as SkillId },
          search: { tab: 'manage' },
        }),
    }),
    []
  )

  const countTranslationKeys: Record<string, TranslationKey> = {
    totalKey: 'skills.n-skills',
  }

  return (
    <TabularProviderFromTableAPI tableAPI={tableAPI} callbacks={memoizedCallbacks}>
      <View gap='small' direction='column' grow>
        <TabularToolbar
          api={tableAPI.api}
          clearFilters={false}
          countsTranslationKeys={countTranslationKeys}
        />

        <TableWrapper>
          <BasicTabular />
        </TableWrapper>
      </View>
    </TabularProviderFromTableAPI>
  )
}
