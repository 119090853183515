import React, { useMemo } from 'react'
import { Column, useGlobalFilter, useSortBy, useTable } from 'react-table'
import { Modal } from 'sierra-client/components/common/modals/modal'
import { SortableHeader } from 'sierra-client/components/table/sortable-header'
import { Table } from 'sierra-client/components/table/table'
import { useLocalizedFormatters } from 'sierra-client/core/format'
import { useTranslation } from 'sierra-client/hooks/use-translation'
import { JobStatus } from 'sierra-client/views/manage/reports/components/job-status'
import { useGetReportTitle } from 'sierra-client/views/manage/reports/report-utils'
import { ScrollContainer } from 'sierra-client/views/v3-author/images/scroll-container'
import { Report, ReportJob } from 'sierra-domain/api/analytics-reporting'

interface JobsModalProps {
  isOpen: boolean
  onClose: () => void
  jobs: ReportJob[]
  reports: Report[]
}

export const JobsModal: React.FC<JobsModalProps> = ({ jobs, reports, isOpen, onClose }) => {
  const { t } = useTranslation()
  const { formatTimestamp } = useLocalizedFormatters()
  const getReportTitle = useGetReportTitle()

  const reportsMap: Record<string, Report> = useMemo(() => {
    return reports.reduce(
      (acc, report) => {
        acc[report.id] = report
        return acc
      },
      {} as Record<string, Report>
    )
  }, [reports])

  const jobColumns: Array<Column<ReportJob>> = useMemo(
    () => [
      {
        Header: p => <SortableHeader label={t('table.report')} {...p} />,
        accessor: 'reportId',
        Cell: p => {
          if (p.row.original.reportId === 'analytics') return <>{t('manage.insights.headline')}</>

          const report = reportsMap[p.row.original.reportId]

          return <>{report !== undefined ? getReportTitle(report) : ''}</>
        },
        width: '40%',
      },
      {
        Header: p => <SortableHeader label={t('table.status')} {...p} />,
        accessor: 'status',
        Cell: p => {
          return <JobStatus job={p.row.original} />
        },
        width: '20%',
      },
      {
        Header: p => <SortableHeader label={t('table.created')} {...p} />,
        accessor: 'createdAt',
        Cell: p => {
          return <>{formatTimestamp(p.row.original.createdAt)}</>
        },
        width: '20%',
      },
      {
        Header: p => <SortableHeader label={t('table.finished')} {...p} />,
        accessor: 'finishedAt',
        Cell: p => {
          return (
            <>{p.row.original.finishedAt !== undefined ? formatTimestamp(p.row.original.finishedAt) : ''}</>
          )
        },
        width: '20%',
      },
    ],
    [t, formatTimestamp, getReportTitle, reportsMap]
  )

  const jobsTable = useTable(
    { data: jobs, columns: jobColumns, autoResetGlobalFilter: false, autoResetSortBy: false },
    useGlobalFilter,
    useSortBy
  )

  return (
    <Modal open={isOpen} onClose={onClose} size='large' title={t('manage.reports.jobs')}>
      <ScrollContainer>
        <Table tableInstance={jobsTable} />
      </ScrollContainer>
    </Modal>
  )
}
