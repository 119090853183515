import { useVirtualizer } from '@tanstack/react-virtual'
import { isDefined } from 'sierra-domain/utils'
import styled from 'styled-components'

type ListVirtualizerContainerProps = {
  $height: number
}
const ListVirtualizerContainer = styled.div.attrs((p: { $height: number }) => ({
  style: {
    height: `${p.$height}px`,
  },
}))<ListVirtualizerContainerProps>`
  width: 100%;
  position: relative;
`

type ListVirtualizerRowProps = { $y: number }
const ListVirtualizerRow = styled.div.attrs((p: { $y: number }) => ({
  style: {
    transform: `translateY(${p.$y}px)`,
  },
}))<ListVirtualizerRowProps>`
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
`

type ListVirtualizerProps<T> = {
  scrollElement: HTMLDivElement | null
  items: T[]
  estimateSize: number
  renderItem: (item: T, index: number) => React.ReactNode
}

export const ListVirtualizer = <T,>({
  items,
  scrollElement,
  estimateSize,
  renderItem,
}: ListVirtualizerProps<T>): React.JSX.Element => {
  const virtualizer = useVirtualizer({
    count: items.length,
    getScrollElement: () => scrollElement,
    estimateSize: () => estimateSize,
    overscan: 20,
  })

  return (
    <ListVirtualizerContainer $height={virtualizer.getTotalSize()}>
      {virtualizer.getVirtualItems().map(virtualRow => {
        const item = items[virtualRow.index]

        if (!isDefined(item)) {
          return null
        }

        return (
          <ListVirtualizerRow
            key={virtualRow.key}
            ref={virtualizer.measureElement}
            data-index={virtualRow.index}
            $y={virtualRow.start}
          >
            {renderItem(item, virtualRow.index)}
          </ListVirtualizerRow>
        )
      })}
    </ListVirtualizerContainer>
  )
}
