import { FC } from 'react'
import { Skeleton, View } from 'sierra-ui/primitives'
import styled from 'styled-components'

const LeftSide = styled(View)`
  flex-grow: 1;
`
const RightSide = styled(View)`
  flex-grow: 2;
`

export const SectionSkeleton: FC = () => {
  return (
    <View justifyContent='space-between' alignItems='flex-start' gap='32'>
      <LeftSide direction='column' grow gap='16'>
        <Skeleton $width={16} $height={16} $radius='50%' />
        <Skeleton $width={'50%'} $height={20} $radius={4} />

        <View direction='column' gap='4'>
          <Skeleton $width={'75%'} $height={10} $radius={4} />
          <Skeleton $width={'70%'} $height={10} $radius={4} />
        </View>
      </LeftSide>

      <RightSide direction='column' grow gap='16' alignItems='flex-end' paddingTop='24'>
        <Skeleton $width={'100%'} $height={120} $radius={16} />
        <Skeleton $width={'100%'} $height={40} $radius={16} />
        <Skeleton $width={'100%'} $height={100} $radius={16} />
      </RightSide>
    </View>
  )
}
