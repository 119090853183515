import { useRef } from 'react'
import { useStableFunction } from 'sierra-client/hooks/use-stable-function'

export type OnCloseHandler = () => 'prevent-close' | void
export type RegisterOnCloseHandler = (onClose: OnCloseHandler | undefined) => void

export const useExtensibleClose = (
  onCloseRaw: (() => void) | undefined
): { onClose: () => void; registerOnCloseHandler: RegisterOnCloseHandler } => {
  const onCloseHandlerRef = useRef<OnCloseHandler | undefined>(undefined)

  const onClose = useStableFunction((): void => {
    const result = onCloseHandlerRef.current?.()
    if (result === 'prevent-close') return
    onCloseHandlerRef.current = undefined
    onCloseRaw?.()
  })

  const registerOnCloseHandler: RegisterOnCloseHandler = childOnClose => {
    onCloseHandlerRef.current = childOnClose
  }

  return { onClose, registerOnCloseHandler }
}
