import React from 'react'
import { useSelector } from 'sierra-client/state/hooks'
import { selectUserId } from 'sierra-client/state/user/user-selector'
import { BlockCommentIndicator } from 'sierra-client/views/v3-author/commenting/block-comment-indicator'
import { DisplayNone } from 'sierra-client/views/v3-author/components'
import { MatrixDataProvider } from 'sierra-client/views/v3-author/matrix/data-layer'
import { CreateMatrix, LearnMatrix } from 'sierra-client/views/v3-author/matrix/matrix'
import { assertElementType } from 'sierra-client/views/v3-author/queries'
import { RenderingContext } from 'sierra-client/views/v3-author/rendering-context'
import { SlateFC, SlateWrapperProps } from 'sierra-client/views/v3-author/slate'
import { BlockWrapper } from 'sierra-client/views/v3-author/wrapper'

export const MatrixContainer = React.forwardRef<HTMLDivElement, SlateWrapperProps>((props, ref) => {
  const { children, attributes, element, mode } = props
  assertElementType('matrix', element)
  const userId = useSelector(selectUserId)

  if (userId === undefined) return <DisplayNone>{children}</DisplayNone>

  return (
    <RenderingContext preventDrag={true} disableMenu={false} withGrid={true}>
      <BlockWrapper {...props} {...attributes} ref={ref}>
        <MatrixDataProvider element={element} mode={mode}>
          {mode === 'create' || mode === 'template' || mode === 'version-history' ? (
            <>
              <CreateMatrix>{children}</CreateMatrix>
              <BlockCommentIndicator element={element} />
            </>
          ) : (
            <LearnMatrix>{children}</LearnMatrix>
          )}
        </MatrixDataProvider>
      </BlockWrapper>
    </RenderingContext>
  )
})

export const Matrix: SlateFC = ({ element, children }) => {
  assertElementType('matrix', element)

  return <>{children}</>
}
