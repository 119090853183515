import React, { useState } from 'react'
import { Identity } from 'sierra-client/components/common/identities-selector'
import { useTranslation } from 'sierra-client/hooks/use-translation'
import { Alert } from 'sierra-client/views/manage/components/user-attributes/flows/components/alert'
import { CopyLink } from 'sierra-client/views/manage/components/user-attributes/flows/invite-users/panels/invite-link/copy-link'
import { GroupSelectorSingle } from 'sierra-client/views/manage/components/user-attributes/flows/invite-users/panels/invite-link/single-selector'
import { isNonNullable } from 'sierra-domain/utils'
import { Text, View } from 'sierra-ui/primitives'
import styled from 'styled-components'

const CapText = styled(Text)`
  &:first-letter {
    text-transform: capitalize;
  }
`

export const LinkInvite: React.FC = () => {
  const { t } = useTranslation()
  const [selectedIdentity, setSelectedIdentity] = useState<Identity>()

  const groupId = selectedIdentity?.id

  return (
    <View direction='column' gap='medium'>
      <Alert variant='info'>{t('manage.users.invite.invite-via-link.group.info')}</Alert>
      <View direction='column' gap='8'>
        <CapText bold size='regular'>
          {t('manage.users.invite.invite-via-link.group.title')}
        </CapText>
        <GroupSelectorSingle
          onSelect={setSelectedIdentity}
          onUnselect={() => setSelectedIdentity(undefined)}
          selected={selectedIdentity}
        />
      </View>
      {isNonNullable(groupId) && <CopyLink groupId={groupId} />}
    </View>
  )
}
