import { useLocalizedFormatters } from 'sierra-client/core/format'
import { useTranslation } from 'sierra-client/hooks/use-translation'
import { Heading, Text, View } from 'sierra-ui/primitives'
import { narrowDotSeparator } from 'sierra-ui/utils'
import styled from 'styled-components'

const wrapperProps = {
  radius: 'size-20',
  direction: 'column',
  padding: '24',
  marginBottom: '40',
} as const

const Wrapper = styled(View).attrs(wrapperProps)``

const Title = styled(Heading).attrs({ bold: true, size: 'h4' })``

const LastLine = styled(View).attrs({
  direction: 'row',
  justifyContent: 'space-between',
  alignItems: 'center',
})``

const Left = styled(View)``

const DotWrapper = styled(Text).attrs({ color: 'grey40', bold: true, size: 'small' })``

const Description = styled(Text).attrs({ size: 'small' })``

const Dot: React.FC = () => <DotWrapper>{narrowDotSeparator}</DotWrapper>

const Actions = styled(View).attrs({ gap: '8' })``

const CreatedAt: React.FC<{ dateString: string | undefined }> = ({ dateString }) => {
  const { t } = useTranslation()
  const { formatTimestamp } = useLocalizedFormatters()

  if (dateString === undefined) {
    return null
  }

  return (
    <>
      {t('manage.programs.created-at', {
        date: formatTimestamp(dateString),
      })}
    </>
  )
}

export const GroupProgramDetail = {
  Wrapper,
  Title,
  LastLine,
  Description,
  Dot,
  Left,
  Actions,
  CreatedAt,
}
