import { motion } from 'framer-motion'
import React, { useCallback, useEffect } from 'react'
import { useSelectCurrentCardBackgroundColor } from 'sierra-client/components/liveV2/hooks/use-select-current-card'
import { Logging } from 'sierra-client/core/logging'
import { ResponsiveNowButton, TransparentNowButton } from 'sierra-client/features/sana-now/header/buttons'
import {
  usePresenceAtomFollowMeIsEnabled,
  usePresenceAtomUserToFollow,
  usePresenceAtomUsersFollowingFollowMe,
} from 'sierra-client/features/sana-now/hooks/use-presence-atom'
import { useTranslation } from 'sierra-client/hooks/use-translation'
import { useIsMobile } from 'sierra-client/state/browser/selectors'
import { useDispatch, useSelector } from 'sierra-client/state/hooks'
import { liveSessionLocalAwarenessStateMerged } from 'sierra-client/state/live-session/actions'
import { selectIsFacilitator, selectIsFollowingScroll } from 'sierra-client/state/live-session/selectors'
import { liveSessionSlice } from 'sierra-client/state/live-session/slice'
import { useUser } from 'sierra-client/state/users/hooks'
import { Tooltip } from 'sierra-ui/components'
import styled from 'styled-components'

const FollowMeIndicator = styled(motion.div)`
  border-radius: 2px;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  pointer-events: none;
  border: 0px solid ${p => p.theme.color.blueVivid};
  position: fixed;
`

const StartFollowingFollowMeButton: React.FC<{ takeOverFollowMe: () => void }> = () => {
  const isCurrentlyFollowing = useSelector(selectIsFollowingScroll)
  const userToFollow = usePresenceAtomUserToFollow()
  const backgroundColor = useSelectCurrentCardBackgroundColor()

  const userResult = useUser(userToFollow)
  const user = userResult?.status === 'loaded' ? userResult : undefined

  const dispatch = useDispatch()
  const { t } = useTranslation()

  const startFollowing = useCallback((): void => {
    void dispatch(liveSessionLocalAwarenessStateMerged({ isFollowingFollowMe: true }))
  }, [dispatch])

  const stopFollowing = useCallback((): void => {
    void dispatch(liveSessionLocalAwarenessStateMerged({ isFollowingFollowMe: false }))
    void dispatch(Logging.liveSession.followMeUserCancelled())
  }, [dispatch])

  useEffect(() => {
    void dispatch(liveSessionLocalAwarenessStateMerged({ isFollowingFollowMe: true }))

    return () => {
      void dispatch(liveSessionLocalAwarenessStateMerged({ isFollowingFollowMe: false }))
    }
  }, [dispatch])

  // Cleanup to ensure the isFollowingScroll is reset
  useEffect(() => {
    if (userToFollow === undefined) {
      void dispatch(liveSessionLocalAwarenessStateMerged({ isFollowingFollowMe: false }))
    }
  }, [userToFollow, dispatch])

  return (
    <>
      {isCurrentlyFollowing && (
        <FollowMeIndicator
          initial={{ borderWidth: 0 }}
          animate={{
            borderWidth: '4px',
            transition: { ease: [0.25, 0.5, 0.25, 1], duration: 0.1, fill: 'both' },
          }}
        />
      )}

      <TransparentNowButton
        $backgroundColor={backgroundColor}
        icon='location--current'
        onClick={isCurrentlyFollowing ? stopFollowing : startFollowing}
      >
        {`${isCurrentlyFollowing ? t('dictionary.following') : t('dictionary.follow')} ${user?.firstName}`}
      </TransparentNowButton>
    </>
  )
}

export const FollowMeButton: React.FC = () => {
  const isFacilitator = useSelector(selectIsFacilitator)
  const dispatch = useDispatch()
  const { t } = useTranslation()
  const isMobile = useIsMobile()
  const backgroundColor = useSelectCurrentCardBackgroundColor()

  const followMeIsEnabled = usePresenceAtomFollowMeIsEnabled()
  const userToFollow = usePresenceAtomUserToFollow()
  const usersFollowingFollowMe = usePresenceAtomUsersFollowingFollowMe()

  const onStartFollowMe = (): void => {
    void dispatch(liveSessionLocalAwarenessStateMerged({ followMeEnabled: true }))

    void dispatch(Logging.liveSession.followMeEnabled())
  }

  const onStopFollowMe = (): void => {
    void dispatch(liveSessionLocalAwarenessStateMerged({ followMeEnabled: false }))
  }

  const takeOverFollowMe = (): void => {
    if (userToFollow === undefined) return

    void dispatch(
      liveSessionSlice.actions.publishControlEvent({
        userId: userToFollow,
        userAction: 'stop_follow_me',
      })
    )

    onStartFollowMe()
  }

  if (followMeIsEnabled) {
    return isMobile ? (
      <TransparentNowButton
        $backgroundColor={backgroundColor}
        icon='close--circle--filled'
        onClick={onStopFollowMe}
      >
        {usersFollowingFollowMe.length.toString()}
      </TransparentNowButton>
    ) : (
      <Tooltip title={t('dictionary.stop')}>
        <TransparentNowButton
          $backgroundColor={backgroundColor}
          icon='close--circle--filled'
          onClick={onStopFollowMe}
        >
          {t('live.count-follower', {
            count: usersFollowingFollowMe.length,
          })}
        </TransparentNowButton>
      </Tooltip>
    )
  }

  if (userToFollow !== undefined) return <StartFollowingFollowMeButton takeOverFollowMe={takeOverFollowMe} />

  if (isFacilitator) {
    return (
      <ResponsiveNowButton iconId='location--current' onClick={onStartFollowMe} title={t('live.follow-me')} />
    )
  }

  return null
}
