import * as Sentry from '@sentry/react'
import { curryRight, truncate, TruncateOptions } from 'lodash'
import { setGlobalAttributes } from 'sierra-client/error/new-relic-logger'
import { useOnChanged } from 'sierra-ui/utils'

// Sentry has a limit of 32 characters on the tag name and 200 characters on the tag value.
// We truncate the values to their maximum length, minus the `...` to show that the value was truncated.
const omission = '...'
const keyTruncate = curryRight<string, TruncateOptions, string>(truncate)({ length: 32 - omission.length })
const valTruncate = curryRight<string, TruncateOptions, string>(truncate)({
  length: 200 - omission.length,
  omission,
})

type TagRecord = Record<string, string | undefined>
export const useObservabilityTags = (tags: TagRecord): void => {
  useOnChanged(() => {
    const truncatedTags: TagRecord = {}
    for (const [key, value] of Object.entries(tags)) {
      const truncatedKey = keyTruncate(key)
      const truncatedValue = value !== undefined ? valTruncate(value) : undefined

      Sentry.setTag(truncatedKey, truncatedValue)
      truncatedTags[truncatedKey] = truncatedValue
    }

    setGlobalAttributes(truncatedTags)

    return () => {
      const emptiedTags: Record<string, undefined> = {}
      for (const key of Object.keys(truncatedTags)) {
        Sentry.setTag(key, undefined)
        emptiedTags[key] = undefined
      }
      setGlobalAttributes(emptiedTags)
    }
  }, tags)

  return
}
