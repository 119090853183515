import { useMemo } from 'react'
import { Logging } from 'sierra-client/core/logging'
import { useDispatch } from 'sierra-client/state/hooks'

type CourseGroupTracking = {
  courseGroup: {
    save: (courseGroupId: string) => void
    editionCreate: () => void
    editionDelete: (editionId?: string) => void
  }
}
export const useTracking = (): CourseGroupTracking => {
  const dispatch = useDispatch()
  return useMemo(
    () => ({
      courseGroup: {
        save: id => dispatch(Logging.courseGroup.save({ id })),
        editionCreate: () => dispatch(Logging.courseGroup.editionCreate()),
        editionDelete: id => dispatch(Logging.courseGroup.editionDelete({ editionId: id })),
      },
    }),
    [dispatch]
  )
}
