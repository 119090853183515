import { createFileRoute } from '@tanstack/react-router'
import { requireLoggedIn } from 'sierra-client/core/require-logged-in'
import { store } from 'sierra-client/state/store'
import { fetchCourseStatusById } from 'sierra-client/state/v2/courses-actions'
import { CoursePage } from 'sierra-client/views/learner/course/course-page'
import { CourseId } from 'sierra-domain/api/nano-id'
import { z } from 'zod'

const RouteComponent = (): JSX.Element => {
  const courseId = Route.useParams({ select: params => params.courseId })
  return <CoursePage courseId={courseId} />
}

export const Route = createFileRoute('/course/$courseId/')({
  component: requireLoggedIn(RouteComponent, { allowScorm: true }) as React.FC,
  validateSearch: z.object({
    'smart-review': z.boolean().optional(),
  }),
  params: {
    parse: z.object({ courseId: CourseId }).parse,
    stringify: p => p,
  },
  loader: ({ context, params }) => {
    if (context.userId !== undefined) {
      void store.dispatch(fetchCourseStatusById({ courseId: params.courseId }))
    }
  },
})
