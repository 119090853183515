import _ from 'lodash'
import type { SkillLevelId, SkillLevelSettingId } from 'sierra-client/api/graphql/branded-types'
import { isLevelFastTrack } from 'sierra-client/api/graphql/fragments/badge'
import {
  GetSkillOverviewQuery,
  ProgressContentFragmentFragment,
  ProgressFragment,
  ProgressSkillLevelFragment,
} from 'sierra-client/api/graphql/gql/graphql'
import { isGQLType } from 'sierra-client/api/graphql/util/types'
import {
  SkillDetailEntity,
  createSkillDetailLearnEntity,
  createSkillDetailRestrictedEntity,
} from 'sierra-client/features/skills/components/skill-detail/types'
import { isDefined } from 'sierra-domain/utils'

export const getHighestSkillLevelSettingId = (
  levels: Array<{ levelSetting: { id: SkillLevelSettingId; index: number } }>
): SkillLevelSettingId | undefined => {
  const highestSkillLevel = levels.toSorted((a, b) => b.levelSetting.index - a.levelSetting.index)[0]
  return highestSkillLevel?.levelSetting.id
}

type ProgressContent =
  | {
      type: 'progress'
      contentInfo: ProgressContentFragmentFragment
      progress: number
    }
  | {
      type: 'restricted'
      contentInfo: { contentId: string }
    }

export type SkillLevelProgressStatus =
  | {
      type: 'in-progress' | 'locked'
      content: Array<ProgressContent>
    }
  | {
      type: 'achieved'
      content: Array<ProgressContent>
      at: string
    }

export type SkillProgressStatus = Array<{
  achievedAt?: string | null
  progress: SkillLevelProgressStatus
  skillLevel: ProgressSkillLevelFragment
}>

const _gqlProgressToSkillProgressStatus = (
  pss: ReadonlyArray<{
    achievedAt?: string | null
    progress: ReadonlyArray<ProgressFragment>
    skillLevel: ProgressSkillLevelFragment
  }>,
  { sequentialUnlockingEnabled }: { sequentialUnlockingEnabled: boolean }
): SkillProgressStatus => {
  const _ps: SkillProgressStatus = pss.map(ps => {
    const content = ps.progress.map(p => {
      if (isGQLType('ProgressVisible', p)) {
        return {
          contentInfo: p.userContentProgress.content,
          progress: p.userContentProgress.progress,
          type: 'progress' as const,
        }
      } else {
        return {
          contentInfo: {
            contentId: p.contentId,
          },
          type: 'restricted' as const,
        }
      }
    })

    if (isDefined(ps.achievedAt)) {
      return {
        ...ps,
        progress: {
          type: 'achieved',
          at: ps.achievedAt,
          content: content,
        },
      }
    }

    return {
      ...ps,
      progress: {
        type: 'in-progress',
        content: content,
      },
    }
  })
  if (!sequentialUnlockingEnabled) {
    return _ps
  }

  return _ps.reduce(
    (acc, x) => {
      const isLocked = acc.foundInProgress
      return {
        foundInProgress: acc.foundInProgress || x.progress.type === 'in-progress',
        data: acc.data.concat(isLocked ? { ...x, progress: { ...x.progress, type: 'locked' } } : x),
      }
    },
    { foundInProgress: false, data: [] } as {
      foundInProgress: boolean
      data: SkillProgressStatus
    }
  ).data
}

export const gqlProgressToSkillProgressStatus = (
  pss: Array<{
    achievedAt?: string | null
    progress: Array<ProgressFragment>
    skillLevel: ProgressSkillLevelFragment
  }>,
  { sequentialUnlockingEnabled }: { sequentialUnlockingEnabled: boolean }
): SkillProgressStatus => {
  return _gqlProgressToSkillProgressStatus(
    pss.map(ps => ({
      ...ps,
      progress: ps.progress,
      skillLevel: ps.skillLevel,
    })),
    { sequentialUnlockingEnabled }
  )
}

// Helper to get the levels that has any assigned content
export const getLevelsWithAssignedContent = <
  CONTENT extends {
    skillLevel: {
      id: string
      levelSetting: { index: number }
    }
  },
>(
  content: Array<CONTENT>
): Array<CONTENT['skillLevel']> => {
  return _.uniqBy(
    content.map(c => c.skillLevel),
    level => level.id
  )
    .filter(level => level.levelSetting.index > 0)
    .sort((a, b) => a.levelSetting.index - b.levelSetting.index)
}

export const toLearnerSkillLevelEntities = (
  myProgress: NonNullable<GetSkillOverviewQuery['skill']>['myProgress'],
  hasSequentialUnlock: boolean,
  skillDescriptions: { id: SkillLevelId; description: string }[]
): SkillDetailEntity[] => {
  const skillProgress = gqlProgressToSkillProgressStatus(myProgress.progress, {
    sequentialUnlockingEnabled: hasSequentialUnlock,
  })

  return skillProgress
    .filter(level => level.skillLevel.levelSetting.index > 0)
    .map(({ progress, skillLevel }) => {
      const skillLevelDescription = skillDescriptions.find(l => l.id === skillLevel.id)?.description

      return {
        displayProgress: true,
        skillLevel: {
          id: skillLevel.id,
          name: skillLevel.levelSetting.name,
          index: skillLevel.levelSetting.index,
          isLocked: progress.type === 'locked',
          description: skillLevelDescription,
        },
        entities: progress.content
          .map(item => {
            if (item.type === 'restricted') {
              return createSkillDetailRestrictedEntity(item.contentInfo.contentId)
            }

            const content = item.contentInfo

            return createSkillDetailLearnEntity({
              content,
              isLevelFastTrack: isLevelFastTrack(skillLevel.badgeIssuedVia, content.contentId),
              progress: item.progress,
            })
          })
          .filter(isDefined),
      }
    })
}
