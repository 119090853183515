import { useEffect } from 'react'
import { useSelectCurrentCard } from 'sierra-client/components/liveV2/hooks/use-select-current-card'
import { CameraResolution } from 'sierra-client/components/liveV2/services/video-call-service/types'
import { useDispatch, useSelector } from 'sierra-client/state/hooks'
import {
  selectIsCurrentUserPinned,
  selectPinnedParticipantsInCall,
} from 'sierra-client/state/live-session/selectors'
import { setCameraResolution } from 'sierra-client/state/live/actions'
import { selectCallIsActive, selectRemoteParticipants } from 'sierra-client/state/live/selectors'

const selectCameraResolution = (
  noOfRemoteUsers: number = 0,
  forceHighRes: boolean = false
): CameraResolution => {
  if (forceHighRes) return 'high'

  if (noOfRemoteUsers < 3) return 'high'

  if (noOfRemoteUsers <= 6) return 'mid'
  return 'low'
}

export const CameraTrackController = (): null => {
  const participants = useSelector(selectRemoteParticipants)
  const dispatch = useDispatch()
  const isCurrentUserPinned = useSelector(selectIsCurrentUserPinned)
  const pinnedParticipants = useSelector(selectPinnedParticipantsInCall)
  const joined = useSelector(selectCallIsActive)

  const currentCard = useSelectCurrentCard()
  const forceHighRes =
    isCurrentUserPinned && currentCard?.data.type === 'live-lobby' && pinnedParticipants.length < 3

  useEffect(() => {
    if (!joined) return
    if (participants.length < 1) return

    const config = selectCameraResolution(participants.length, forceHighRes)

    void dispatch(setCameraResolution({ resolution: config }))
  }, [participants.length, forceHighRes, dispatch, joined])

  return null
}
