import { useMemo } from 'react'
import { graphql } from 'sierra-client/api/graphql/gql'
import { MyIssuedCertificatesQuery } from 'sierra-client/api/graphql/gql/graphql'
import { useGraphQuery } from 'sierra-client/api/hooks/use-graphql-query'
import { useGetIssuedCertificateSharingLinkedInURL } from 'sierra-client/views/workspace/utils/outgoing-urls'

const myIssuedCertificatesQuery = graphql(`
  query MyIssuedCertificates {
    me {
      issuedCertificates {
        id
        certificateId
        snapshotImageUrl
        pdfUrl
        issuedByContentId
        issuedByProgramId
        issuedAt
        expiresAt
        certificate {
          title
        }
      }
    }
  }
`)

export type Certificate = {
  type: 'certificate'
  url?: string
  downloadUrl?: string
  linkedInUrl?: string
  certificateTitle?: string
  issuedCertificate?: MyIssuedCertificatesQuery['me']['issuedCertificates'][number]
}

export const useMyIssuedCertificates = ({
  courseId,
  programId,
  enabled,
}: {
  courseId?: string
  programId?: string
  enabled: boolean
}): { certificates: Certificate[]; isLoading: boolean } => {
  const getLinkedinUrl = useGetIssuedCertificateSharingLinkedInURL()
  const graphQuery = useGraphQuery({
    document: myIssuedCertificatesQuery,
    queryOptions: {
      enabled,
      select: data => {
        const myIssuedCertificates = data.me.issuedCertificates

        const issuedCertificatesFiltered =
          programId !== undefined
            ? myIssuedCertificates.filter(certificate => certificate.issuedByProgramId === programId)
            : courseId !== undefined
              ? myIssuedCertificates.filter(
                  certificate => certificate.issuedByContentId === `course:${courseId}`
                )
              : undefined

        const sortedCertificates = issuedCertificatesFiltered?.sort((c1, c2) =>
          c2.issuedAt > c1.issuedAt ? 1 : -1
        )

        if (sortedCertificates === undefined) return []

        return sortedCertificates.map(certificate => {
          const linkedInUrl = getLinkedinUrl(certificate)
          return {
            type: 'certificate',
            url: certificate.snapshotImageUrl,
            downloadUrl: certificate.pdfUrl,
            linkedInUrl,
            certificateTitle: certificate.certificate.title,
            issuedCertificate: certificate,
          } satisfies Certificate
        })
      },
    },
  })

  return useMemo(
    () => ({ certificates: graphQuery.data ?? [], isLoading: graphQuery.isLoading }),
    [graphQuery.data, graphQuery.isLoading]
  )
}
