import * as HelpLinks from 'sierra-client/config/links'
import { TranslationKey } from 'sierra-client/hooks/use-translation/types'
import { getIframelyUrl } from 'sierra-client/views/v3-author/embed/utils/handlers'
import { UrlType } from 'sierra-domain/flexible-content/types'
import { ColorName } from 'sierra-ui/color/types'
import { ButtonProps } from 'sierra-ui/primitives'
import { SanaColor } from 'sierra-ui/types'

export type HexCodeBackgrounds = '#625DF5' | '#1C2A4C' | '#050038' | '#2B2A35' | '#1AB7EA'

type TextStyling = {
  color: ColorName
  opacity: number
}

type Styling = {
  backgroundColor: SanaColor | HexCodeBackgrounds
  textStyling: TextStyling
  buttonVariant: ButtonProps['variant']
}

export const EmbedIcons: Record<UrlType, string> = {
  airtable: 'https://storage.googleapis.com/sierra-static/images/embed-airtable-icon-large.svg',
  figma: 'https://storage.googleapis.com/sierra-static/images/embed-figma-icon-large.svg',
  drive: 'https://storage.googleapis.com/sierra-static/images/embed-google-drive-icon-large.svg',
  loom: 'https://storage.googleapis.com/sierra-static/images/embed-loom-icon-large.svg',
  miro: 'https://storage.googleapis.com/sierra-static/images/embed-miro-icon-large.svg',
  pitch: 'https://storage.googleapis.com/sierra-static/images/embed-pitch-icon-large.svg',
  trello: 'https://storage.googleapis.com/sierra-static/images/embed-trello-icon-large.svg',
  youtube: 'https://storage.googleapis.com/sierra-static/images/embed-youtube-icon-large.svg',
  vimeo: 'https://storage.googleapis.com/sierra-static/images/embed-vimeo-icon-large.svg',
}

export const EmbedHelpLinks: Record<UrlType, string> = {
  airtable: HelpLinks.AIRTABLE_EMBED_HELP_PAGE_URL,
  figma: HelpLinks.FIGMA_EMBED_HELP_PAGE_URL,
  drive: HelpLinks.GOOGLE_EMBED_HELP_PAGE_URL,
  loom: HelpLinks.LOOM_EMBED_HELP_PAGE_URL,
  miro: HelpLinks.MIRO_EMBED_HELP_PAGE_URL,
  pitch: HelpLinks.PITCH_EMBED_HELP_PAGE_URL,
  trello: HelpLinks.TRELLO_EMBED_HELP_PAGE_URL,
  youtube: HelpLinks.YOUTUBE_EMBED_HELP_PAGE_URL,
  vimeo: HelpLinks.VIMEO_EMBED_HELP_PAGE_URL,
}

export const getSpecificStyling = (urlType?: UrlType): Styling => {
  if (urlType === 'figma')
    return {
      backgroundColor: 'grey90',
      textStyling: {
        color: 'white',
        opacity: 0.4,
      },
      buttonVariant: 'primary',
    }
  else if (urlType === 'airtable')
    return {
      backgroundColor: 'grey2',
      textStyling: {
        color: 'black',
        opacity: 0.4,
      },
      buttonVariant: 'primary',
    }
  else if (urlType === 'drive')
    return {
      backgroundColor: 'grey2',
      textStyling: {
        color: 'black',
        opacity: 0.4,
      },
      buttonVariant: 'primary',
    }
  else if (urlType === 'loom')
    return {
      backgroundColor: '#625DF5',
      textStyling: {
        color: 'white',
        opacity: 0.5,
      },
      buttonVariant: 'primary',
    }
  else if (urlType === 'trello')
    return {
      backgroundColor: '#1C2A4C',
      textStyling: {
        color: 'white',
        opacity: 0.4,
      },
      buttonVariant: 'primary',
    }
  else if (urlType === 'miro')
    return {
      backgroundColor: '#050038',
      textStyling: {
        color: 'white',
        opacity: 0.4,
      },
      buttonVariant: 'primary',
    }
  else if (urlType === 'pitch')
    return {
      backgroundColor: '#2B2A35',
      textStyling: {
        color: 'white',
        opacity: 0.4,
      },
      buttonVariant: 'primary',
    }
  else if (urlType === 'youtube')
    return {
      backgroundColor: 'grey2',
      textStyling: {
        color: 'black',
        opacity: 0.4,
      },
      buttonVariant: 'primary',
    }
  else if (urlType === 'vimeo')
    return {
      backgroundColor: '#1AB7EA',
      textStyling: {
        color: 'white',
        opacity: 0.5,
      },
      buttonVariant: 'primary',
    }
  else
    return {
      backgroundColor: 'grey2',
      textStyling: {
        color: 'black',
        opacity: 0.25,
      },
      buttonVariant: 'primary',
    }
}

export const defaultToPlainEmbed = async (url: string): Promise<boolean> => {
  const response = await fetch(getIframelyUrl(url), {})
  const payload = (await response.json()) as { status: number | undefined }
  const status = payload.status
  const iframelySuccess = status === undefined || status === 200
  return !iframelySuccess
}

export const getIsPlainEmbed = async (url: string, urlType?: UrlType): Promise<boolean> => {
  if (urlType === undefined) return defaultToPlainEmbed(url)
  const plainEmbedTypes: string[] = ['figma', 'miro', 'pitch', 'trello', 'drive']
  return plainEmbedTypes.includes(urlType)
}

export const isUrlMatchingUrlType = (url: string, urlType?: UrlType): boolean => {
  if (urlType === 'figma') return url.includes('figma.com/embed?')
  if (urlType === 'airtable') return url.includes('airtable.com/shrv')
  if (urlType === 'drive') return url.includes('docs.google.com/') || url.includes('drive.google.com')
  if (urlType === 'loom') return url.includes('www.loom.com/share/')
  if (urlType === 'miro') return url.includes('miro') && (url.includes('share') || url.includes('embed'))
  if (urlType === 'pitch') return url.includes('pitch.com/embed') || url.includes('pitch.com/public')
  if (urlType === 'youtube')
    return (
      url.includes('youtube.com/embed') ||
      url.includes('youtube.com/watch') ||
      url.includes('youtube.com/live')
    )
  if (urlType === 'trello') return url.includes('trello.com') && url.includes('.html')
  if (urlType === 'vimeo') return url.includes('vimeo.com')
  else return true
}

export const getEmbeddableUrl = (url: string, urlType: UrlType | undefined): string => {
  if (urlType === 'figma') {
    if (isUrlMatchingUrlType(url, 'figma')) return url
    else {
      const figmaUrlEmbedPrefix = 'https://www.figma.com/embed?embed_host=share&url='
      return figmaUrlEmbedPrefix + url
    }
  }
  if (urlType === 'trello') {
    if (isUrlMatchingUrlType(url, 'trello')) return url
    else return url.substring(0, url.lastIndexOf('/')) + '.html'
  }
  return url
}

export const helperText: Record<UrlType, TranslationKey> = {
  figma: 'author.embed-card.helper-text-figma',
  airtable: 'author.embed-card.helper-text-airtable',
  drive: 'author.embed-card.helper-text-drive',
  loom: 'author.embed-card.helper-text-loom',
  miro: 'author.embed-card.helper-text-miro',
  pitch: 'author.embed-card.helper-text-pitch',
  youtube: 'author.embed-card.helper-text-youtube',
  trello: 'author.embed-card.helper-text-trello',
  vimeo: 'author.embed-card.helper-text-vimeo',
}

export const inputPlaceholder: Record<UrlType, TranslationKey> = {
  figma: 'author.embed-card.input-placeholder-figma',
  airtable: 'author.embed-card.input-placeholder-airtable',
  drive: 'author.embed-card.input-placeholder-drive',
  loom: 'author.embed-card.input-placeholder-loom',
  miro: 'author.embed-card.input-placeholder-miro',
  pitch: 'author.embed-card.input-placeholder-pitch',
  youtube: 'author.embed-card.input-placeholder-youtube',
  trello: 'author.embed-card.input-placeholder-trello',
  vimeo: 'author.embed-card.input-placeholder-vimeo',
}
