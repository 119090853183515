import * as VisuallyHidden from '@radix-ui/react-visually-hidden'
import React, { useState } from 'react'
import { useTranslation } from 'sierra-client/hooks/use-translation'
import { PdfUploadStatus, acceptedPdfFiles } from 'sierra-client/views/v3-author/common/media-uploader/types'
import { OutputControls, defaultCourseLength } from 'sierra-domain/api/author-v2'
import { assertIsNonNullable, assertNever } from 'sierra-domain/utils'
import { Icon, Slider } from 'sierra-ui/components'
import { Button, Text, TextAreaPrimitive, View } from 'sierra-ui/primitives'
import { token } from 'sierra-ui/theming'
import styled from 'styled-components'

const SlidersWrapper = styled(View)`
  width: 100%;
`

const Container = styled(View)`
  position: absolute;
  width: 100%;
  height: 100%;
  background: ${token('surface/default')};
  overflow: auto;
`

const FullWidthView = styled(View)`
  width: 100%;
`

const TextArea = styled(TextAreaPrimitive)`
  width: 100%;
  height: 100%;
  resize: none;
  flex: 1;
`

const FullWidthButton = styled(Button)`
  width: 100%;
`

const midpoint = 50

function getOutputControls(
  courseLength: number[],
  interactivity: number[],
  additionalInstructions: string
): OutputControls {
  const courseLengthValue = courseLength[0]
  const interactivityValue = interactivity[0]
  assertIsNonNullable(courseLengthValue)
  assertIsNonNullable(interactivityValue)

  return {
    courseLength: courseLengthValue,
    interactivity: interactivityValue,
    additionalInstructions: additionalInstructions.trim(),
  }
}

export const CourseGenOutputControls: React.FC<{
  pdfUploadStatus: PdfUploadStatus
  setOutputControls: (controls: OutputControls) => void
  handleFileUpload: (file: File) => void
}> = ({ pdfUploadStatus, setOutputControls, handleFileUpload }) => {
  const { t } = useTranslation()

  const [additionalInstructions, setAdditionalInstructions] = useState('')
  const [courseLength, setCourseLength] = useState<number[]>([defaultCourseLength])
  const [interactivity, setInteractivity] = useState<number[]>([midpoint])
  const fileInputRef = React.useRef<HTMLInputElement | null>(null)

  switch (pdfUploadStatus.type) {
    case 'idle':
      return null
    case 'error':
    case 'success':
    case 'uploading':
    case 'processing':
      return (
        <Container direction='column' padding='48 32' paddingBottom='32' alignItems='center' gap='32'>
          <FullWidthView direction='column' gap='16' grow>
            <View direction='column'>
              <Text size='regular' bold align='center'>
                {t('author.generate-from-doc.configure')}
              </Text>
              <Text size='regular' color='foreground/muted' align='center'>
                {t('author.generate-from-doc.configure-body')}
              </Text>
            </View>
            <SlidersWrapper direction='column' marginTop='32' marginBottom='32' gap='24'>
              <Text bold align='center'>
                {t('author.generate-from-doc.course-length')}
              </Text>
              <Slider
                onValueChange={setCourseLength}
                defaultValue={midpoint}
                value={courseLength}
                step={10}
                leftLabel={t('author.generate-from-doc.summarize')}
                rightLabel={t('author.generate-from-doc.match-pdf')}
              />
              <Text bold align='center'>
                {t('author.generate-from-doc.interactivity')}
              </Text>
              <Slider
                value={interactivity}
                onValueChange={setInteractivity}
                defaultValue={midpoint}
                step={10}
                leftLabel={t('author.generate-from-doc.none')}
                rightLabel={t('author.generate-from-doc.a-lot')}
              />
            </SlidersWrapper>

            <Text bold align='center'>
              {t('author.generate-from-course.tone-of-voice')}
            </Text>
            <TextArea
              value={additionalInstructions}
              onChange={e => {
                setAdditionalInstructions(e.target.value)
              }}
              placeholder={t('author.generate-from-course.tone-of-voice')}
            />
          </FullWidthView>

          {pdfUploadStatus.type === 'error' ? (
            <View direction='column' gap='4'>
              <FullWidthView
                gap='12'
                alignItems='center'
                background='destructive/background'
                padding='24'
                radius='size-8'
              >
                <Icon iconId='close--circle--filled' color='destructive/foreground' />
                <View direction='column' gap='2'>
                  <Text color='destructive/foreground' size='micro'>
                    {pdfUploadStatus.title}
                  </Text>
                  <Text color='destructive/foreground' size='micro'>
                    {pdfUploadStatus.message}
                  </Text>
                </View>
              </FullWidthView>

              <FullWidthButton
                onClick={() => {
                  assertIsNonNullable(fileInputRef.current)
                  fileInputRef.current.click()
                }}
              >
                {t('author.generate-from-course.try-again')}
              </FullWidthButton>

              <VisuallyHidden.Root>
                <input
                  ref={fileInputRef}
                  type='file'
                  name='files[]'
                  accept={acceptedPdfFiles}
                  onChange={event => {
                    const file = event.target.files?.[0]
                    if (file) {
                      void handleFileUpload(file)
                    }
                  }}
                />
              </VisuallyHidden.Root>
            </View>
          ) : (
            <FullWidthButton
              onClick={() => {
                const controls = getOutputControls(courseLength, interactivity, additionalInstructions)
                setOutputControls(controls)
              }}
            >
              {t('dictionary.generate')}
            </FullWidthButton>
          )}
        </Container>
      )
    default:
      assertNever(pdfUploadStatus)
  }
}
