import { useEffect } from 'react'
import { mergeupdateAndBroadcastAwareness } from 'sierra-client/collaboration/awareness/broadcast'
import { useSelector } from 'sierra-client/state/hooks'
import { selectUser } from 'sierra-client/state/user/user-selector'
import { LightUser } from 'sierra-domain/user'
import { Awareness } from 'y-protocols/awareness'
import * as Y from 'yjs'

/**
 * Broadcast data about this user so that other course collaborators can see who is active.
 */
export const useBroadcastEditorAwarenessState = ({
  editorId,
  yDoc,
  awareness,
}: {
  editorId: string
  yDoc: Y.Doc
  awareness: Awareness
}): void => {
  const user = useSelector(selectUser)
  useEffect(() => {
    if (!user) return

    const userData: LightUser = {
      uuid: user.uuid,
      firstName: user.firstName,
      lastName: user.lastName,
      avatar: user.avatar,
      avatarColor: user.avatarColor,
      email: user.email,
    }

    mergeupdateAndBroadcastAwareness(awareness, {
      clientId: yDoc.clientID,
      editorId,
      user: userData,
    })

    return () => {
      // This is unfortunately required for slate-yjs cursors to function when switching documents
      awareness.setLocalStateField('selection', undefined)
      awareness.setLocalStateField('editorId', undefined)
      awareness.setLocalStateField('focus', undefined)
      awareness.setLocalStateField('anchor', undefined)
    }
  }, [user, awareness, yDoc, editorId])
}
