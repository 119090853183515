import { NotificationContainer } from 'sierra-client/features/activity-center/notifications/components'
import { NotificationIcon } from 'sierra-client/features/activity-center/notifications/components/notification-icon'
import { NotificationTitle } from 'sierra-client/features/activity-center/notifications/components/notification-title'
import { HomeNotification } from 'sierra-domain/api/learn'
import { Button } from 'sierra-ui/primitives'

export const SmartCardsAvailableNotification: React.FC<{
  notification: Extract<HomeNotification, { type: 'smart-cards-available' }>
}> = ({ notification }) => {
  return (
    <NotificationContainer
      notification={notification}
      icon={<NotificationIcon iconId='grid' />}
      title={
        <NotificationTitle
          untranslated='Smart cards available for {{courseTitle}}'
          values={{ courseTitle: notification.courseTitle }}
        />
      }
      content={<Button href={`/course/${notification.courseId}`}>View course</Button>}
    />
  )
}
