import { withYHistory, withYjs, YHistoryEditor, YjsEditor } from '@slate-yjs/core'
import { store } from 'sierra-client/state/store'
import { selectUser } from 'sierra-client/state/user/user-selector'
import {
  EditableYjsEditor,
  EditableYjsEditorAttributes,
} from 'sierra-client/views/v3-author/collaboration/types'
import { CursorData } from 'sierra-client/views/v3-author/cursors/cursor-overlay'
import { withSanaCursors } from 'sierra-client/views/v3-author/cursors/with-sana-cursors'
import { ScopedYDocId } from 'sierra-domain/collaboration/types'
import { getUserName } from 'sierra-domain/utils'
import { theme } from 'sierra-ui/theming/legacy-theme'
import { Editor } from 'slate'
import { Awareness } from 'y-protocols/awareness'
import * as Y from 'yjs'

function readCursorData(): CursorData | undefined {
  const user = selectUser(store.getState())
  if (user === undefined) console.debug('No cursor data available')
  else
    return {
      name: getUserName(user) ?? '',
      color: theme.color[user.avatarColor],
      userId: user.uuid,
    }
}

type CollaborationOptions = { yType: Y.XmlText; awareness: Awareness; editorId: string; yDocId: ScopedYDocId }

function withEditableYjsEditor<T extends Editor>(
  editor: T,
  yDocId: ScopedYDocId
): T & EditableYjsEditorAttributes {
  const yjsEditor = editor as T & EditableYjsEditorAttributes
  yjsEditor._isEditableYjsEditor = true
  yjsEditor.yDocId = yDocId
  return yjsEditor
}

function _withCollaboration<T extends Editor>(
  editor: T,
  { yType, awareness, editorId }: CollaborationOptions
): T & YjsEditor & YHistoryEditor {
  const yjsEditor = withYjs(editor, yType, { autoConnect: false })

  const cursorEditor = withSanaCursors(
    yjsEditor,
    {
      awareness,
      filterAwarenessStates: ([, value]) => value.editorId === editorId,
    },
    { data: readCursorData() }
  )
  const historyEditor = withYHistory(cursorEditor)

  return historyEditor
}

export type GenericCollaborationOptions = {
  yType: Y.XmlText
  awareness: Awareness
  editorId: string
  yDocId: ScopedYDocId
}

export const withCollaboration = <T extends Editor>(
  editor: T,
  options: GenericCollaborationOptions
): T & EditableYjsEditor => {
  const { yType, ...rest } = options

  if (yType instanceof Y.XmlText) {
    const yjsEditor = _withCollaboration(editor, { yType, ...rest })
    return withEditableYjsEditor(yjsEditor, options.yDocId)
  }

  throw new Error('Attempted to launch the editor with a y-array, this is no longer supported')
}
