import { Meta } from 'sierra-client/error/log-meta'
import { TranslationKey } from 'sierra-client/hooks/use-translation/types'
import { filterInvalidValuesForEntity } from 'sierra-client/lib/filter/components/auto-complete/auto-complete'
import { InsightsDashboardRole, Widget } from 'sierra-domain/api/insights'
import { DomainRep } from 'sierra-domain/filter/datatype/domain'
import { Filter, FilterBase, andAll } from 'sierra-domain/filter/datatype/filter'
import { getDomainRep } from 'sierra-domain/filter/query'

export const getRoleTranslationKey = (role: InsightsDashboardRole): TranslationKey => {
  switch (role) {
    case 'viewer':
      return 'manage.insights.dashboard.roles.viewer'
    case 'editor':
      return 'manage.insights.dashboard.roles.editor'
    case 'owner':
      return 'manage.insights.dashboard.roles.owner'
  }
}

const removeEmptyValue = (dashboardFilter: FilterBase): FilterBase | undefined => {
  switch (dashboardFilter.predicate.type) {
    case 'predicate.and':
    case 'predicate.or':
      return dashboardFilter.predicate.values.length === 0 ? undefined : dashboardFilter
    default:
      return dashboardFilter
  }
}

const removeFiltersWithNoValues = (dashboardFilter: Filter): Filter | undefined => {
  switch (dashboardFilter.type) {
    case 'filter.filter':
      return removeEmptyValue(dashboardFilter)
    case 'filter.and':
    case 'filter.or':
      return {
        ...dashboardFilter,
        filters: dashboardFilter.filters
          .map(filter => removeFiltersWithNoValues(filter))
          .reduce<Filter[]>((filters, filter) => {
            if (filter === undefined) {
              return filters
            } else {
              return [...filters, filter]
            }
          }, []),
      }
  }
}

const isDashboardFilterEmpty = (dashboardFilter: Filter): boolean => {
  switch (dashboardFilter.type) {
    case 'filter.and':
    case 'filter.or':
      return dashboardFilter.filters.length === 0
    case 'filter.filter':
    default:
      return false
  }
}

const removeInvalidAutocompleteFilters = (
  filter: Filter,
  allFilterDomainReps: DomainRep[]
): Filter | undefined => {
  switch (filter.type) {
    case 'filter.filter': {
      const domainRep = getDomainRep(allFilterDomainReps, filter.domain)
      if (domainRep?.domain.type === 'domain.auto-complete') {
        const newPredicate = filterInvalidValuesForEntity(filter.predicate, domainRep.domain.entity)

        return { ...filter, predicate: newPredicate }
      }

      return filter
    }
    case 'filter.and':
    case 'filter.or':
      return {
        ...filter,
        filters: filter.filters
          .map(filter => removeInvalidAutocompleteFilters(filter, allFilterDomainReps))
          .reduce<Filter[]>((filters, filter) => {
            if (filter === undefined) {
              return filters
            } else {
              return [...filters, filter]
            }
          }, []),
      }
  }
}

export const cleanFilter = (
  dashboardFilter: Filter,
  allFilterDomainReps: DomainRep[]
): Filter | undefined => {
  const filtersWithoutNoValues = removeFiltersWithNoValues(dashboardFilter)
  const filtersWithoutInvalidValues =
    filtersWithoutNoValues === undefined
      ? undefined
      : removeInvalidAutocompleteFilters(filtersWithoutNoValues, allFilterDomainReps)

  if (filtersWithoutInvalidValues === undefined) return undefined
  if (isDashboardFilterEmpty(filtersWithoutInvalidValues)) return undefined
  return filtersWithoutInvalidValues
}

export const addDashboardFilterToWidget = (
  widget: Widget,
  allFilterDomainReps: DomainRep[],
  dashboardFilter?: Filter
): Widget => {
  if (dashboardFilter === undefined) {
    return widget
  }

  const cleanedFilter = cleanFilter(dashboardFilter, allFilterDomainReps)
  if (cleanedFilter === undefined) {
    return widget
  }

  const widgetWithDashboardFilter: Widget = {
    ...widget,
    filter: widget.filter === undefined ? cleanedFilter : andAll([cleanedFilter, widget.filter]),
  }

  return widgetWithDashboardFilter
}

export const insightsErrorBoundaryBeforeSend = (error: Error, widget?: Partial<Widget>): Meta => {
  const widgetMeasure =
    widget !== undefined && 'measures' in widget && widget.measures !== undefined
      ? widget.measures.map(measure => String(measure.type)).join(', ')
      : undefined
  const widgetType = widget !== undefined && widget.type

  const tags: Meta['tags'] = { widgetMeasure, widgetType }

  if (widget !== undefined && 'dimensions' in widget && widget.dimensions !== undefined) {
    tags['widgetDimension'] = widget.dimensions.map(dimension => String(dimension.type)).join(', ')
  }

  const meta = {
    tags,
    cause: error.cause,
  } satisfies Meta

  return meta
}

export const doFilterContainType = (filter: Filter | undefined, type: Filter['type']): boolean => {
  if (filter === undefined) return false

  switch (filter.type) {
    case 'filter.filter':
      return filter.type === type
    case 'filter.and':
    case 'filter.or':
      if (filter.type === type) return true
      else return filter.filters.some(filter => doFilterContainType(filter, type))
  }
}
