import { useMemo } from 'react'
import { SingleDimensionSelector } from 'sierra-client/features/insights/widget-builder/data-selectors'
import { WIDGET_BUILDER_TRANSITION } from 'sierra-client/features/insights/widget-builder/data-selectors/animation-constants'
import {
  MeasureSelector,
  SingleMeasureChangeHandler,
} from 'sierra-client/features/insights/widget-builder/data-selectors/measure-selector'
import { WidgetFilterSelector } from 'sierra-client/features/insights/widget-builder/data-selectors/widget-filter-selector'
import { WidgetBuilderState } from 'sierra-client/features/insights/widget-builder/widget-builder-state'
import { useTranslation } from 'sierra-client/hooks/use-translation'
import { DimensionRef, MeasureRef } from 'sierra-domain/api/insights'
import { Filter } from 'sierra-domain/filter/datatype/filter'
import { isDefined } from 'sierra-domain/utils'
import { IconButton, Text, View } from 'sierra-ui/primitives'
import styled from 'styled-components'

const AnimationContainer = styled(View).attrs({
  animated: true,
  layout: 'position',
  transition: WIDGET_BUILDER_TRANSITION,
})``

const SwitcherPosition = styled(View)`
  position: absolute;
  top: 32px;
  left: -26px;
`

export const PivotTableSelectors: React.FC<{
  measure?: MeasureRef
  dimensions: DimensionRef[]
  filter?: Filter
  visualisation: WidgetBuilderState['visualisation']
  onChangeFilter: (newFilter: Filter) => void
  onChangeMeasure: SingleMeasureChangeHandler
  onChangeDimensions: (newDimensions: DimensionRef[]) => void
  widgetBuilderState: WidgetBuilderState
}> = ({
  measure,
  dimensions,
  filter,
  visualisation,
  onChangeFilter,
  onChangeMeasure,
  onChangeDimensions,
  widgetBuilderState,
}) => {
  const { t } = useTranslation()

  const measureArray = useMemo(() => {
    return [measure].filter(isDefined)
  }, [measure])

  return (
    <>
      <AnimationContainer direction='column' gap='8' layoutId='measure-container'>
        <View justifyContent='space-between'>
          <Text color='foreground/primary' bold capitalize='first'>
            {t('manage.insights.widgetbuilder.metric')}
          </Text>
        </View>

        <View padding='4 none' grow>
          <MeasureSelector
            selectedMeasure={measure}
            onChange={onChangeMeasure}
            disabledMeasures={measureArray}
            visualisation={visualisation}
          />
        </View>
      </AnimationContainer>
      <AnimationContainer direction='column' gap='8' layoutId='dimension-container' grow>
        <View justifyContent='space-between'>
          <Text color='foreground/primary' bold capitalize='first'>
            {t('manage.insights.widgetbuilder.breakdown')}
          </Text>
        </View>

        <View
          direction='column'
          grow
          padding='4 none'
          position='relative'
          animated
          initial='hide'
          animate='hide'
          whileHover='show'
        >
          <SingleDimensionSelector
            selectedDimension={dimensions[0]}
            selectedMeasures={measureArray}
            onChange={newDim => onChangeDimensions([newDim, dimensions[1]].filter(isDefined))}
            disabledDimensions={dimensions}
            visualisation={visualisation}
            dimensionSelectorType='breakdown'
          />
          <SwitcherPosition
            animated
            variants={{ show: { opacity: 1, rotate: 90 }, hide: { opacity: 0, rotate: 90 } }}
            transition={WIDGET_BUILDER_TRANSITION}
          >
            <IconButton
              size='small'
              iconId='compare'
              variant='transparent'
              onClick={() => onChangeDimensions([dimensions[1], dimensions[0]].filter(isDefined))}
            />
          </SwitcherPosition>
          <SingleDimensionSelector
            selectedDimension={dimensions[1]}
            selectedMeasures={measureArray}
            onChange={newDim => onChangeDimensions([dimensions[0], newDim].filter(isDefined))}
            disabledDimensions={dimensions}
            visualisation={visualisation}
            dimensionSelectorType='breakdown'
          />
        </View>
      </AnimationContainer>

      <AnimationContainer direction='column' justifyContent='space-between' position='relative'>
        <View justifyContent='space-between'>
          <Text color='foreground/primary' bold>
            {t('manage.insights.widgetbuilder.filter')}
          </Text>
        </View>

        <WidgetFilterSelector
          visualisation={visualisation}
          selectedMeasures={measureArray}
          selectedFilter={filter}
          onChange={onChangeFilter}
          widgetBuilderState={widgetBuilderState}
        />
      </AnimationContainer>
    </>
  )
}
