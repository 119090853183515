import { useCallback, useMemo } from 'react'
import { isDefined } from 'sierra-domain/utils'
import { Icon, MenuItem } from 'sierra-ui/components'
import { View } from 'sierra-ui/primitives'
import { MultiSelectDropdown } from 'sierra-ui/primitives/menu-dropdown'
import { token } from 'sierra-ui/theming'
import styled from 'styled-components'

const toggleSetMember = <A,>(s: Set<A>, a: A): Set<A> =>
  s.has(a) ? new Set((s.delete(a), s)) : new Set(s.add(a))

export type Selections = Array<{ groupName: string; items: Array<{ id: string; label: string }> }>

const Trigger = styled(View)`
  color: ${token('foreground/muted')};
  transition: all 100ms;
  cursor: pointer;
  padding: 0.5rem;
  justify-content: center;

  &:hover,
  &:active {
    color: ${token('foreground/primary')};
  }
`

export const ColumnSelector: React.FC<{
  onUpdate: (s: Set<string>) => void
  selected: Set<string>
  selections: Selections
}> = ({ onUpdate, selections, selected }) => {
  const groups: MenuItem[] = useMemo(
    () =>
      selections.map(({ groupName, items }) => ({
        type: 'group',
        id: groupName,
        label: groupName,
        menuItems: items.map(({ id, label }) => ({
          id,
          label,
          value: label,
          type: 'checkbox',
          checked: selected.has(id),
          onToggleChange: () => {}, // Handled by dropdown
        })),
      })),
    [selected, selections]
  )

  const onChange = useCallback(
    (item: MenuItem) => onUpdate(toggleSetMember(selected, item.id)),
    [onUpdate, selected]
  )

  const selectedItems = useMemo(() => {
    const selectedIds = Array.from(selected.values())
    const allItems = groups.flatMap(item => (item.type === 'group' ? item.menuItems : item))
    return selectedIds.map(it => allItems.find(menuItem => menuItem.id === it)).filter(isDefined)
  }, [groups, selected])

  return (
    <MultiSelectDropdown
      menuItems={groups}
      onSelect={onChange}
      onUnselect={onChange}
      closeOnSelect={false}
      selectedItems={selectedItems}
      renderTrigger={() => (
        <Trigger>
          <Icon iconId='table' color='currentColor' />
          <Icon iconId={'chevron--down--small'} size='size-16' color='currentColor' />
        </Trigger>
      )}
    />
  )
}
