import { PrimitiveAtom, atom } from 'jotai'

export type Properties = {
  [name: string]: ControlProperty
}

export type ControlGroup = {
  id: string
  name: string
  properties: PrimitiveAtom<Record<string, ControlProperty>>
}

export type ControlValue =
  | number
  | { value: number; min: number; max: number; step?: number }
  | { color: string }
  | boolean
  | string[]
  | string

export type ControlProperty<Values extends string[] = string[]> =
  | { type: 'number'; value: number }
  | { type: 'list'; value: Values[number]; values: Readonly<Values[]> }
  | { type: 'boolean'; value: boolean }
  | { type: 'color'; value: string }
  | { type: 'range'; value: number; min: number; max: number; step?: number }
  | { type: 'string'; value: string }

export const controlsAtom = atom<ControlGroup[]>([])

export type DebugValues = { [name: string]: ControlValue }
export type DebugReturnValues<T> = {
  [name in keyof T]: T[name] extends string[]
    ? T[name][number]
    : T[name] extends boolean
      ? boolean
      : T[name] extends string
        ? string
        : number
}

export const sessionStorageExpandedAtom = atom<{ [id: string]: { isExpanded: boolean } }>({})
