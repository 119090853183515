import React from 'react'
import { HeaderItem } from 'sierra-client/features/collapsable-sidebar'
import { settingsExpandedAtom } from 'sierra-client/features/collapsable-sidebar/hooks/use-expand'
import { LabelHeaderItem } from 'sierra-client/features/collapsable-sidebar/items/header-item'
import { useTranslation } from 'sierra-client/hooks/use-translation'
import { useSettingsPageEnabled } from 'sierra-client/views/manage/permissions/use-settings-page-enabled'
import { PageName } from 'sierra-domain/segment/page'

export const SettingsSidebarInner: React.FC = () => {
  const { t } = useTranslation()
  const path = window.location.pathname

  const items: LabelHeaderItem[] = [
    {
      id: PageName.SettingsGeneral,
      type: 'label',
      title: t('global-sidebar.general'),
      href: '/settings/general',
      isSelected: path === '/settings/general',
    },

    {
      id: PageName.SettingsNotifications,
      type: 'label',
      title: t('global-sidebar.notifications'),
      href: '/settings/notifications',
      isSelected: path === '/settings/notifications',
    },
    {
      id: PageName.SettingsBranding,
      type: 'label',
      title: t('global-sidebar.branding'),
      href: '/settings/branding',
      isSelected: path === '/settings/branding',
    },
    {
      id: PageName.OrganizationApi,
      type: 'label',
      title: t('global-sidebar.api'),
      href: '/settings/api',
      isSelected: path === '/settings/api',
    },
  ]

  return (
    <HeaderItem
      type='nested'
      id='tour-settings'
      data-testid='settings-item'
      title={t('settings.settings')}
      iconId='settings--adjust'
      items={items}
      expandedAtom={settingsExpandedAtom}
      nestedItemPadding='8 14'
    />
  )
}

export const SettingsSidebar: React.FC = () => {
  const hasSettingsAccess = useSettingsPageEnabled()

  if (hasSettingsAccess !== true) return null
  else return <SettingsSidebarInner />
}
