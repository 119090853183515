import { useAtomValue } from 'jotai/index'
import { helperStateAtom } from 'sierra-client/views/course-helper/atoms'
import { useIsMobileHelper } from 'sierra-client/views/course-helper/hooks/use-is-mobile-helper'
import { HelperState, HelperVisibility } from 'sierra-client/views/course-helper/types'
import { useSelfPacedFiles } from 'sierra-client/views/self-paced/files-provider'
import { assertNever } from 'sierra-domain/utils'
import { css } from 'styled-components'

export const hoverTransition = css`
  transition: all 0.2s cubic-bezier(0.25, 0.1, 0.25, 1);
`

/* MOBILE */
/* States which take as little space as possible on mobile */
export const helperInlineStates: HelperState['type'][] = ['none', 'forward', 'text']

export const mobileHelperConfig = {
  bubbleDuration: 0.15,
  fadeDuration: 0.1,
  borderRadius: 16,
}

/* DESKTOP */
export const desktopHelperConfig = {
  bubbleDuration: 0.15,
  fadeDuration: 0.1,
}

export const useDesktopHelperBorderRadius = (): number => {
  const state = useAtomValue(helperStateAtom)

  switch (state.type) {
    case 'course-completed':
    case 'program-completed':
    case 'program-pending':
    case 'next-course-in-program':
    case 'next-course-in-path':
    case 'course-pending':
      return 26
    case 'text':
    case 'forward':
    case 'none':
    case 'undefined':
      return 16
    default:
      assertNever(state)
  }
}

type MobileHelperPrevious = 'visible' | 'disabled' | 'invisible'

export const useMobileHelperShowPrevious = (): MobileHelperPrevious => {
  const state = useAtomValue(helperStateAtom)
  const { prevFile } = useSelfPacedFiles()
  const isMobileHelper = useIsMobileHelper()

  if (!isMobileHelper) {
    return 'invisible'
  }

  switch (state.type) {
    case 'text':
    case 'forward':
      return prevFile === undefined ? 'disabled' : 'visible'
    case 'course-completed':
    case 'program-completed':
    case 'program-pending':
    case 'next-course-in-program':
    case 'next-course-in-path':
    case 'course-pending':
    case 'none':
    case 'undefined':
      return 'invisible'
    default:
      assertNever(state)
  }
}

export const useHelperVisibility = (): HelperVisibility => {
  const state = useAtomValue(helperStateAtom)

  switch (state.type) {
    case 'none':
      return 'hidden'
    default:
      return 'visible'
  }
}
