export const SearchIcons: Record<string, string | undefined> = {
  drive: 'https://storage.googleapis.com/sierra-static/images/google-drive-logo.svg',
  notion: 'https://storage.googleapis.com/sierra-static/images/notion-logo.svg',
  microsoft: 'https://storage.googleapis.com/sierra-static/images/microsoft-logo.svg',
  sharepoint: 'https://storage.googleapis.com/sierra-static/images/sharepoint-logo.svg',
  teams: 'https://storage.googleapis.com/sierra-static/images/teams-logo.svg',
  teams_clipped: 'https://storage.googleapis.com/sierra-static/images/teams-logo-clipped.svg',
  sana: 'https://storage.googleapis.com/sierra-static/images/sana-logo-black.png',
  video: 'https://storage.googleapis.com/sierra-static/images/video-logo.svg',
  helpjuice: 'https://storage.googleapis.com/sierra-static/images/helpjuice-logo.svg',
  undefined: undefined,
}
