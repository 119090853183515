import { produce } from 'immer'
import { DomainRep, Filter } from 'sierra-client/lib/filter'
import { UserGroupModalProps } from 'sierra-client/views/manage/users/components/edit-create-user-group-modal/types'
import { assertNever } from 'sierra-domain/utils'

type BaseUserGroupDraftState = {
  name: string
  description: string
}

type ManualUserGroupDraftState = BaseUserGroupDraftState & {
  type: 'user-manual'
}

type UserFilterGroupDraftState = BaseUserGroupDraftState & {
  type: 'user-filter'
  domainReps: DomainRep[]
  filter: Filter
  readOnlyFilter: boolean
}

export type UserGroupDraftState = ManualUserGroupDraftState | UserFilterGroupDraftState

// Initialize state from modal props
export const getInitialUserGroupDraftState = (props: UserGroupModalProps): UserGroupDraftState => {
  // Initialize name and description if editing, else set to empty string
  const { name, description } = (() => {
    switch (props.mode) {
      case 'create':
        return {
          name: '',
          description: '',
        }
      case 'edit':
        return {
          name: props.name,
          description: props.description,
        }
      default:
        assertNever(props)
    }
  })()

  const { type } = props

  switch (type) {
    case 'user-filter':
      return {
        type,
        name,
        description,
        filter: props.filter,
        domainReps: props.domainReps,
        readOnlyFilter: props.readOnlyFilter,
      }
    case 'user-manual':
      return {
        type,
        name,
        description,
      }
    default:
      assertNever(props)
  }
}

// -- Reducer --

export type UserGroupAction =
  | {
      type: 'set-name'
      name: string
    }
  | {
      type: 'set-description'
      description: string
    }
  | {
      type: 'set-filter'
      filter: Filter | ((filter: Filter) => Filter)
    }

export const userGroupDraftStateReducer = produce<UserGroupDraftState, [UserGroupAction]>(
  (draft, action): void => {
    switch (action.type) {
      case 'set-name':
        draft.name = action.name
        break
      case 'set-description':
        draft.description = action.description
        break
      case 'set-filter':
        if (draft.type === 'user-filter') {
          if (typeof action.filter === 'function') {
            draft.filter = action.filter(draft.filter)
          } else {
            draft.filter = action.filter
          }
        }
        break
      default:
        assertNever(action)
    }
  }
)
