import React, { FC } from 'react'

const GlobalAssistantLazy = React.lazy(
  () => import('sierra-client/views/global-assistant/components/global-assistant')
)

export const GlobalAssistantComponent: FC = () => {
  return (
    <React.Suspense>
      <GlobalAssistantLazy />
    </React.Suspense>
  )
}
