import { useTranslation } from 'sierra-client/hooks/use-translation'
import { TranslationKey } from 'sierra-client/hooks/use-translation/types'
import { LinkedInImportStatus } from 'sierra-client/views/manage/linked-in/use-linkedin-couses'
import { LinkedInDifficultyLevel } from 'sierra-domain/api/partner'
import { Icon, IconId, Tooltip } from 'sierra-ui/components'
import { Spacer, Text } from 'sierra-ui/primitives'
import styled from 'styled-components'

export const LINKED_IN_DIFFICULTY_LEVELS: LinkedInDifficultyLevel[] = ['BEGINNER', 'INTERMEDIATE', 'ADVANCED']

export const getDifficultyLevelTranslationKey = (dl: LinkedInDifficultyLevel): TranslationKey => {
  switch (dl) {
    case 'ADVANCED':
      return 'manage.linked-in.difficulty.advanced'
    case 'INTERMEDIATE':
      return 'manage.linked-in.difficulty.intermediate'
    case 'BEGINNER_INTERMEDIATE':
      return 'manage.linked-in.difficulty.beginner-intermediate'
    case 'BEGINNER':
      return 'manage.linked-in.difficulty.beginner'
    case 'GENERAL':
      return 'manage.linked-in.difficulty.general'
  }
}

export const getDifficultyLevelIconId = (dl: LinkedInDifficultyLevel): IconId => {
  switch (dl) {
    case 'ADVANCED':
      return 'difficulty--4'
    case 'INTERMEDIATE':
      return 'difficulty--3'
    case 'BEGINNER_INTERMEDIATE':
      return 'difficulty--3'
    case 'BEGINNER':
      return 'difficulty--2'
    case 'GENERAL':
      return 'difficulty--1'
  }
}

export const getImportSelectKey = (status?: LinkedInImportStatus): TranslationKey => {
  switch (status) {
    case 'imported':
      return 'manage.linked-in.imported'
    case 'selected':
      return 'modal.selected'
    default:
      return 'modal.select'
  }
}
export const LINKED_IN_HEX_COLOR = '#1566C2'

type LinkedInLearningChipWrapperProps = { $color: string; $backgroundColor: string; $circle?: boolean }
const LinkedInLearningChipWrapper = styled(Text)<LinkedInLearningChipWrapperProps>`
  display: inline-flex;
  align-items: center;
  background-color: ${p => p.$backgroundColor};
  padding: ${p => (p.$circle === true ? '0.5rem' : '0.25rem 0.75rem')};
  border-radius: 100px;
  color: ${p => p.$color};
`
type LinkedInLearningChipProps = { variant: 'blue' | 'white'; circle?: boolean }
const CHIP_PROPS: Record<LinkedInLearningChipProps['variant'], LinkedInLearningChipWrapperProps> = {
  blue: {
    $backgroundColor: '#E5E7EB80',
    $color: LINKED_IN_HEX_COLOR,
  },
  white: {
    $backgroundColor: '#FFFFFF33',
    $color: '#FFF',
  },
}

export const LinkedInLearningChip: React.FC<LinkedInLearningChipProps> = ({ variant, circle = false }) => {
  const { t } = useTranslation()
  const linkedInText = 'LinkedIn Learning'

  const chipProps = CHIP_PROPS[variant]
  return (
    <Tooltip title={t('manage.linked-in.tooltip')}>
      <LinkedInLearningChipWrapper
        color='LEGACY_DEFAULT_TEXT_COLOR_REPLACE_ASAP'
        size='small'
        bold
        {...chipProps}
        $circle={circle}
      >
        <Icon iconId='logo--linkedin' />
        {circle === false && (
          <>
            <Spacer size='xxsmall' />
            {linkedInText}
          </>
        )}
      </LinkedInLearningChipWrapper>
    </Tooltip>
  )
}
