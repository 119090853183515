import { WidgetBuilderState } from 'sierra-client/features/insights/widget-builder/widget-builder-state'
import {
  ColumnRef,
  SortBy,
  SortByColumnRef,
  areDimensionRefsEqual,
  areMeasureRefsEqual,
} from 'sierra-domain/api/insights'

type MeasureColumn = Extract<ColumnRef, { type: 'column.measure' }>
type DimensionColumn = Extract<ColumnRef, { type: 'column.dimension' }>
type ValueColumn = Extract<ColumnRef, { type: 'column.value' }>

const normalizeMeasureSorting = (
  column: MeasureColumn,
  partialWidget: WidgetBuilderState
): MeasureColumn | undefined => {
  if (
    partialWidget.selections.measures[0] !== undefined &&
    areMeasureRefsEqual(column.measure, partialWidget.selections.measures[0])
  ) {
    return column
  }
}

const normalizeDimensionSorting = (
  column: DimensionColumn,
  partialWidget: WidgetBuilderState
): DimensionColumn | undefined => {
  switch (partialWidget.visualisation.type) {
    case 'metric':
    case 'progress-bar':
      return undefined
    case 'bar-chart':
    case 'line-chart':
    case 'table':
      return partialWidget.selections.dimensions[0] !== undefined &&
        areDimensionRefsEqual(column.dimension, partialWidget.selections.dimensions[0])
        ? column
        : undefined
  }
}

const normalizeValueSorting = (): ValueColumn | undefined => {
  return undefined
}

export const normalizeSorting = (partialWidget: WidgetBuilderState): SortBy | undefined => {
  if (partialWidget.selections.sortBy === undefined) {
    return undefined
  }

  const newSorting: SortBy = partialWidget.selections.sortBy
    .map(it => ({
      ...it,
      column:
        it.column.type === 'column.measure'
          ? normalizeMeasureSorting(it.column, partialWidget)
          : it.column.type === 'column.dimension'
            ? normalizeDimensionSorting(it.column, partialWidget)
            : normalizeValueSorting(),
    }))
    .filter((sortBy): sortBy is SortByColumnRef => sortBy.column !== undefined)

  return newSorting
}
