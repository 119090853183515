import React, { useCallback, useMemo } from 'react'
import { useTranslation } from 'sierra-client/hooks/use-translation'
import { PhoneNumberInput } from 'sierra-client/views/manage/components/phone-number-input'
import { useComposeUserInvitationConfig } from 'sierra-client/views/manage/components/user-attributes/flows/invite-users/hooks/use-compose-user-invitation-config'
import { getValueFromConfig } from 'sierra-client/views/manage/components/user-attributes/flows/invite-users/utils'
import { createInvitationAttribute } from 'sierra-client/views/manage/components/user-attributes/utils'
import { UserPhoneNumberDomainRep } from 'sierra-domain/user-attributes/user-invitation-domain-rep'

export const InvitationDomainPhoneNumberInput: React.FC<{
  phoneNumberDomain: UserPhoneNumberDomainRep
  onValidationError: (error: string | undefined) => void
}> = ({ phoneNumberDomain, onValidationError }) => {
  const { dynamicT } = useTranslation()
  const { addOrReplaceAttributes, resetAttribute, userAttributesConfig, editingEmails } =
    useComposeUserInvitationConfig()

  const currentPhoneNumber: string | undefined = useMemo(() => {
    if (editingEmails.length === 0) {
      return undefined
    }
    if (editingEmails.length === 1) {
      const firstEmail = editingEmails[0]
      if (firstEmail === undefined) {
        return undefined
      }
      const data = getValueFromConfig(phoneNumberDomain, userAttributesConfig, firstEmail)
      return data?.type === 'value.phone-number' ? data.value : undefined
    } else {
      return dynamicT('manage.users.invite.bulk-editing.mixed')
    }
  }, [phoneNumberDomain, userAttributesConfig, editingEmails, dynamicT])

  const setPhoneNumberAttribute = useCallback(
    (number: string | undefined): void => {
      const attribute = createInvitationAttribute({ type: 'user.phone-number' }, [
        { type: 'value.phone-number', value: number ?? '' },
      ])

      if (number?.length === 0) {
        resetAttribute(editingEmails, phoneNumberDomain.ref)
      } else {
        addOrReplaceAttributes([attribute])
      }
    },
    [editingEmails, phoneNumberDomain, addOrReplaceAttributes, resetAttribute]
  )

  return (
    <PhoneNumberInput
      value={currentPhoneNumber}
      onChange={number => setPhoneNumberAttribute(number)}
      onValidationError={onValidationError}
      phoneNumberDomain={phoneNumberDomain}
    />
  )
}
