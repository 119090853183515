import { useMemo } from 'react'

import { Logging } from 'sierra-client/core/logging'
import { useDispatch } from 'sierra-client/state/hooks'

type SettingsTracking = {
  notifications: {
    enableMSTeams: () => void
  }
}

export const useTracking = (): SettingsTracking => {
  const dispatch = useDispatch()

  return useMemo(
    () => ({
      notifications: {
        enableMSTeams: () => dispatch(Logging.settings.enableMSTeams()),
      },
    }),
    [dispatch]
  )
}
