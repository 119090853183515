import { useMutation, UseMutationResult } from '@tanstack/react-query'
import { Editor } from '@tiptap/react'
import { motion } from 'framer-motion'
import { PrimitiveAtom, useAtom, useSetAtom } from 'jotai'
import React, { useEffect, useRef, useState } from 'react'
import { getFileContentImage, getFileContentVideo } from 'sierra-client/api/content'
import { useConfirmationModalContext } from 'sierra-client/components/common/modals/confirmation-modal'
import { useNotif } from 'sierra-client/components/common/notifications'
import { useStableFunction } from 'sierra-client/hooks/use-stable-function'
import { useTranslation } from 'sierra-client/hooks/use-translation'
import { SanaGradientIcon } from 'sierra-client/lib/assistant-input/search-insights/sana-gradient-icon'
import { subscribeSse, typedPost, useTypedMutation } from 'sierra-client/state/api'
import { useDispatch } from 'sierra-client/state/hooks'
import {
  generateVideoCardAvatarAvatarGenerated,
  generateVideoCardAvatarScriptGenerated,
} from 'sierra-client/views/flexible-content/ai-narrations/logger'
import { NarrationEditor } from 'sierra-client/views/flexible-content/ai-narrations/narration-editor'
import { UploadVideoButton } from 'sierra-client/views/flexible-content/ai-narrations/upload-video-button'
import { Debug } from 'sierra-client/views/learner/components/debug'
import { Avatar, DestinationTypeApi, NarrationMetadata, NarrationSettings } from 'sierra-domain/api/author-v2'
import { AvatarId } from 'sierra-domain/api/avatar-id'
import { CreateContentId } from 'sierra-domain/api/nano-id'
import { AssetContext } from 'sierra-domain/asset-context'
import { CreateOperationState } from 'sierra-domain/editor/operations'
import { RequestError } from 'sierra-domain/error'
import { FileId } from 'sierra-domain/flexible-content/identifiers'
import {
  XRealtimeAuthorCancelGenerateNarrationAvatar,
  XRealtimeAuthorGenerateNarrationAvatar,
} from 'sierra-domain/routes'
import { SSEXRealtimeAuthorGenerateNarrationScript } from 'sierra-domain/routes-sse'
import { assertIsNonNullable, assertNever, iife } from 'sierra-domain/utils'
import { TokenOrColor } from 'sierra-ui/color/token-or-color'
import { AiText, Icon, ProgressBar, Tooltip, TruncatedText } from 'sierra-ui/components'
import { useAiColors } from 'sierra-ui/components/ai-kit/ai-color-utils'
import { aiGradientLeftToRight } from 'sierra-ui/components/ai-kit/ai-gradient'
import {
  Button,
  Heading,
  IconButton,
  InputStyles,
  LoaderAnimation,
  Text,
  TextAreaPrimitive,
  View,
} from 'sierra-ui/primitives'
import { ButtonShapeStyles } from 'sierra-ui/primitives/button/button'
import { token, useTokenValue } from 'sierra-ui/theming'
import styled, { css } from 'styled-components'
import { z } from 'zod'

const DisabledCss = css<{ $disabled: boolean }>`
  ${p =>
    p.$disabled &&
    css`
      pointer-events: none;
      opacity: 0.5;
    `}
`

const AIButton = styled(motion.button).attrs({
  layout: 'position',
  base: 'org/primary' as const,
  variant: 'primary' as const,
})<{
  $loading: boolean
  $disabled: boolean
}>`
  ${ButtonShapeStyles};
  ${aiGradientLeftToRight};
  opacity: ${p => (p.$disabled ? 0.5 : p.$loading ? 0.75 : 1)};
  transform-origin: center center;

  cursor: ${p => (p.$disabled ? 'not-allowed' : 'pointer')};
`

const TextAreaWrapper = styled.div`
  ${InputStyles};

  margin: 0;
  outline-offset: -1px;
  flex: 1;
  display: flex;
  flex-direction: column;
  padding: 0;
  min-height: 0;
  overflow: auto;
`

const GenerateButton = styled(AIButton)`
  background: transparent;
`

const AvatarsContainer = styled.div<{ $disabled: boolean }>`
  display: flex;
  gap: 8px;
  justify-content: space-between;
  height: 118px;
  align-items: center;

  ${DisabledCss}
`

const NoWrapText = styled(Text)`
  white-space: nowrap;
`

const GenerateScriptButton = React.forwardRef<
  HTMLButtonElement,
  {
    courseId: CreateContentId
    fileId: FileId
    script: string
    clearContent: () => void
    insertContent: (content: string) => void
    generateScriptLogging: () => void
  }
>(({ courseId, fileId, script, clearContent, insertContent, generateScriptLogging }, ref) => {
  const scriptGenerationAbortControllerRef = useRef<AbortController | undefined>(undefined)
  const { t } = useTranslation()
  const notif = useNotif()

  const generateScriptMutation = useMutation({
    mutationFn: async (): Promise<void> => {
      scriptGenerationAbortControllerRef.current?.abort()
      const abortScriptGenerationSignal = new AbortController()
      scriptGenerationAbortControllerRef.current = abortScriptGenerationSignal
      const previousScript = script
      clearContent()

      await subscribeSse({
        route: SSEXRealtimeAuthorGenerateNarrationScript,
        input: { courseId: courseId, fileId: fileId },
        onMessage: event => {
          if (event.data.error === 'not-enough-content') {
            notif.push({
              body: t('notifications.question-generation.short-context.text'),
              type: 'error',
            })
            clearContent()
            insertContent(previousScript)
          } else {
            insertContent(event.data.text)
          }
        },
        signal: abortScriptGenerationSignal.signal,
      })

      scriptGenerationAbortControllerRef.current = undefined
    },
  })

  if (generateScriptMutation.isError) {
    throw generateScriptMutation.error
  }

  const isGenerating = generateScriptMutation.isPending

  const getToken = useTokenValue()
  const { aiColor } = useAiColors({ base: getToken('org/primary') })

  const confirmationModal = useConfirmationModalContext()

  return (
    <GenerateButton
      $disabled={false}
      ref={ref}
      type='button'
      $loading={isGenerating}
      onClick={() => {
        if (isGenerating) {
          scriptGenerationAbortControllerRef.current?.abort()
          return
        }

        if (script.length === 0) {
          generateScriptMutation.mutate()
          generateScriptLogging()
          return
        }

        confirmationModal.show({
          bodyText: t('ai-narrations.script-overwrite-warning'),
          confirmLabel: t('dictionary.generate'),
          onConfirm: () => {
            generateScriptMutation.mutate()
            generateScriptLogging()
          },
        })
      }}
    >
      <View gap='8'>
        {isGenerating ? (
          <LoaderAnimation size={18} color={aiColor.toHexString() as TokenOrColor} />
        ) : (
          <SanaGradientIcon />
        )}
        <AiText>
          {isGenerating ? t('ai-narrations.generating-script') : t('ai-narrations.generate-script')}
        </AiText>
      </View>
    </GenerateButton>
  )
})

export type AINarrationControlsState =
  | {
      type: 'open'
      preSelectAvatar?: AvatarId
    }
  | {
      type: 'closed'
    }

export const AINarrationNudgeState = z.record(z.string(), z.object({ shown: z.boolean() }))
export type AINarrationNudgeState = z.infer<typeof AINarrationNudgeState>

type NarrationTab = 'narrator' | 'voice-over' | 'record-yourself'

const NarratorImgContainer = styled(motion.div)`
  position: relative;
  height: 72px;
  width: 72px;
  cursor: pointer;

  :hover {
    transform: scale(1.1);
  }
`

const NarratorSelectedIcon = styled(Icon).attrs({
  iconId: 'checkmark--filled',
  color: 'white',
})`
  top: 10px;
  right: 10px;
  position: absolute;

  filter: drop-shadow(0px 4px 12px rgba(0, 0, 0, 0.5));

  animation: fade-in 0.2s ease-in-out;
  @keyframes fade-in {
    0% {
      opacity: 0;
    }
    100% {
      opacity: 1;
    }
  }
`

const BoxShadowCss = css<{ $selected: boolean }>`
  ${p =>
    p.$selected &&
    css`
      box-shadow:
        0px 8px 16px 0px #00000014,
        0px 0px 0px 1px #0000000a;
    `}
`

const NarratorImg = styled.img<{ $selected: boolean }>`
  height: 100%;
  width: 100%;
  object-fit: cover;
  border-radius: 20px;
  background-color: rgba(0, 0, 0, 0.05);

  ${BoxShadowCss}
`

const _NarratorVideo = styled.video<{ $selected: boolean }>`
  width: 100%;
  aspect-ratio: 1;
  object-fit: cover;
  max-width: 100%;
  border-radius: 20px;

  ${BoxShadowCss}
`

const NarratorVideoPlayButton = styled(IconButton)`
  border-radius: 100%;
  position: absolute;
  margin: 0 auto;
  left: 0;
  right: 0;
  bottom: 10px;
`

const NarratorVideoContainer = styled.div`
  position: relative;

  ${NarratorVideoPlayButton} {
    opacity: 0;
    transition: opacity 0.2s;
  }

  :hover {
    ${NarratorVideoPlayButton} {
      opacity: 1;
    }
  }
`

const NarratorVideo: React.FC<{
  src: string
  isPlayingVideoWithSrc: string | undefined
  setIsPlayingVideoWithSrc: React.Dispatch<React.SetStateAction<string | undefined>>
  $selected: boolean
}> = ({ src, isPlayingVideoWithSrc, setIsPlayingVideoWithSrc, $selected }) => {
  const videoRef = useRef<HTMLVideoElement | null>(null)
  useEffect(() => {
    if (videoRef.current === null) {
      return
    }

    const aDifferentVideoIsPlaying = isPlayingVideoWithSrc !== src
    const thisVideoPlayerIsPlaying = !videoRef.current.paused
    if (aDifferentVideoIsPlaying && thisVideoPlayerIsPlaying) {
      void videoRef.current.pause()
    }
  }, [isPlayingVideoWithSrc, src])

  const playPause = useStableFunction(() => {
    if (videoRef.current === null) {
      throw new Error('Video ref has not been set')
    }

    if (videoRef.current.paused) {
      void videoRef.current.play()
    } else {
      void videoRef.current.pause()
    }
  })

  const thisVideoIsPlaying = isPlayingVideoWithSrc === src

  return (
    <NarratorVideoContainer>
      <_NarratorVideo
        $selected={$selected}
        src={src}
        ref={videoRef}
        onPlay={() => setIsPlayingVideoWithSrc(src)}
        onPause={() =>
          setIsPlayingVideoWithSrc(previous => {
            if (previous === src) {
              return undefined
            }

            return previous
          })
        }
        playsInline
      />
      <NarratorVideoPlayButton
        color='white'
        variant='ghost'
        iconId={thisVideoIsPlaying ? 'pause--filled' : 'play--filled'}
        onClick={playPause}
      />
    </NarratorVideoContainer>
  )
}

const rotations = [1.5, -1, 0, -1.5, 1]

const ChooseAvatar: React.FC<{
  avatars: Avatar[]
  avatarId: AvatarId
  setAvatarId: (newAvatarId: AvatarId) => void
  quotaExceeded: boolean
}> = ({ avatars, avatarId, setAvatarId, quotaExceeded }) => {
  const [isPlayingVideoWithSrc, setIsPlayingVideoWithSrc] = useState<string | undefined>(undefined)

  const getAnimationState = useStableFunction((rotation: number, selected: boolean) => {
    return selected
      ? ({ width: 118, height: 118, rotate: rotation < 0 ? `1deg` : `-1deg` } as const)
      : ({ width: 72, height: 72, rotate: `${rotation}deg` } as const)
  })

  return (
    <AvatarsContainer $disabled={quotaExceeded}>
      {
        // The current UI is designed specifically for 5 avatars, so
        // we only take the first 5 here. If the backend returns more in the
        // future, we'll need to adapt the UI too. So this will be safe as uong
        // as at least 5 avatars are returned from the backend
        avatars.slice(0, 5).map((avatar, index) => {
          const selected = avatar.id === avatarId
          const rotation = rotations[index % rotations.length] ?? 0

          return (
            <NarratorImgContainer
              role='button'
              onClick={() => {
                setAvatarId(avatar.id)
              }}
              key={avatar.id}
              initial={getAnimationState(rotation, selected)}
              animate={getAnimationState(rotation, selected)}
              transition={{
                type: 'spring',
                stiffness: 3000,
                damping: 200,
                mass: 3,
              }}
            >
              {avatar.previewVideoUrl !== undefined ? (
                <NarratorVideo
                  $selected={selected}
                  isPlayingVideoWithSrc={isPlayingVideoWithSrc}
                  setIsPlayingVideoWithSrc={setIsPlayingVideoWithSrc}
                  src={getFileContentVideo(avatar.previewVideoUrl, 'videos', {
                    bucket: 'sierra-static',
                  })}
                />
              ) : (
                <NarratorImg
                  $selected={selected}
                  src={getFileContentImage(avatar.thumbnailUrl, { bucket: 'sierra-static' })}
                />
              )}
              {selected && <NarratorSelectedIcon />}
            </NarratorImgContainer>
          )
        })
      }
    </AvatarsContainer>
  )
}

const NarrationsContent = styled.div`
  position: relative;
  display: flex;
  flex-direction: column;
  height: 100%;
`

const CloseButton = styled(IconButton).attrs({
  variant: 'transparent',
  iconId: 'close',
  size: 'small',
})`
  position: absolute;
  top: 16px;
  right: 16px;
`

const GenerateScriptButtonContainer = styled(View).attrs({
  justifyContent: 'space-between',
  paddingRight: 'xsmall',
})`
  position: relative;

  &::before {
    content: '';
    position: absolute;
    top: -15px;
    left: 1px;
    right: 20px;
    height: 15px;
    background: linear-gradient(transparent, ${token('surface/default')});
  }
`

const NarrationsTopContent = styled.div`
  display: flex;
  flex: 1;
  flex-direction: column;
  padding-top: 28px;
  padding-inline: 32px;
  height: 100%;
  overflow: auto;
  min-height: 0;
  margin-bottom: 58px;
`

const GRADIENT_HEIGHT_PX = 20

const NarrationsBottomContent = styled.div`
  display: flex;

  padding-block-end: 24px;
  justify-content: space-between;
  background-color: white;
  padding-top: 16px;
  position: absolute;
  bottom: 0;
  left: 32px;
  right: 32px;

  ::after {
    content: '';
    position: absolute;
    left: 0;
    right: 0;
    top: -${GRADIENT_HEIGHT_PX}px;
    height: ${GRADIENT_HEIGHT_PX}px;
    background: linear-gradient(to top, rgba(255, 255, 255, 1), rgba(255, 255, 255, 0));
  }
`

const NarrationSubtext = styled(Text)`
  line-height: 1.4;
`

const BetaBadge = styled.div`
  ${aiGradientLeftToRight};
  padding: 2.5px 6px;
  border-radius: 8px;
`

const ScriptSection = styled(View)<{ $disabled: boolean }>`
  min-height: 200px;
  overflow: auto;

  ${DisabledCss}
`

const QuotaIconContainer = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 10px;
  background: ${token('warning/background').opacity(0.08)};
  border-radius: 10px;
`

const QuotaWrapper = styled(View)`
  width: 100%;
  border-radius: 16px;
  gap: 16px;
  padding-block: 20px;
  padding-inline: 24px;
  flex-direction: column;
  box-shadow:
    0px 2px 4px 0px #00000014,
    0px 0px 0px 1px #0000000a;
`

const QuotaExceededContainer = styled(View)`
  gap: 20px;
`

const NarrationTabHeading: React.FC<{ destinationType: 'video' | 'card' }> = ({ destinationType }) => {
  const { t } = useTranslation()

  return (
    <View gap='8' direction='column'>
      <View>
        <Heading size='h5' bold>
          {destinationType === 'video'
            ? t('author.video.generate-video')
            : t('ai-narrations.generate-narration')}
        </Heading>
        {destinationType === 'video' && (
          <BetaBadge>
            <Text bold color='button/foreground' size='micro'>
              {t('dictionary.beta')}
            </Text>
          </BetaBadge>
        )}
      </View>

      <NarrationSubtext size='regular' color='foreground/secondary'>
        {destinationType === 'video'
          ? t('ai-narrations.avatar-nudge-text')
          : t('ai-narrations.narrator-nudge-text')}
      </NarrationSubtext>
    </View>
  )
}

export type ScriptState =
  | {
      type: 'tiptap'
      editor: Editor
      script: string
    }
  | {
      type: 'plain'
      setScript: React.Dispatch<React.SetStateAction<string>>
      script: string
    }

const TextArea = styled(TextAreaPrimitive)`
  border: none;
  outline: none;
  cursor: text;

  margin: 0;
  height: auto;

  flex: 1;
  display: flex;
  min-height: 0;
  overflow: auto;
  flex-direction: column;

  :hover {
    outline: none;
  }
`

const NarrationTabScript: React.FC<{
  fileId: FileId
  characterLimit: number
  courseId: CreateContentId
  quotaExceeded: boolean
  scriptState: ScriptState
  generateScriptLogging: () => void
  destinationType: DestinationTypeApi['type']
}> = ({
  fileId,
  characterLimit,
  courseId,
  quotaExceeded,
  scriptState,
  generateScriptLogging,
  destinationType,
}) => {
  const { t } = useTranslation()

  const allowedCharactersLeftInScript = characterLimit - scriptState.script.length
  const showCharacterLimitWarning = allowedCharactersLeftInScript < 100

  return (
    <ScriptSection
      $disabled={quotaExceeded}
      direction='column'
      gap='8'
      marginBottom='16'
      marginTop={'32'}
      grow
    >
      <Text size='small' bold>
        {t('dictionary.script')}
      </Text>

      <TextAreaWrapper>
        {scriptState.type === 'plain' ? (
          <TextArea
            placeholder={
              destinationType === 'video-card'
                ? t('ai-narration.script.placeholder.video-card')
                : t('ai-narration.script.placeholder.card')
            }
            value={scriptState.script}
            onChange={e => scriptState.setScript(e.target.value)}
            resize='none'
          />
        ) : (
          <NarrationEditor
            editor={scriptState.editor}
            placeholder={t('ai-narration.script.placeholder.card')}
          />
        )}

        <GenerateScriptButtonContainer>
          <GenerateScriptButton
            generateScriptLogging={generateScriptLogging}
            courseId={courseId}
            fileId={fileId}
            script={scriptState.script}
            insertContent={text =>
              scriptState.type === 'tiptap'
                ? scriptState.editor.commands.insertContent(text)
                : scriptState.setScript(prev => prev + text)
            }
            clearContent={() =>
              scriptState.type === 'tiptap'
                ? scriptState.editor.commands.clearContent()
                : scriptState.setScript('')
            }
          />

          {showCharacterLimitWarning && (
            <Text
              bold
              size='technical'
              color={allowedCharactersLeftInScript <= 0 ? 'destructive/background' : 'warning/background'}
            >
              {allowedCharactersLeftInScript < 0
                ? t('ai-narrations.script-too-long', { count: scriptState.script.length - characterLimit })
                : t('ai-narrations.script-characters-left', {
                    count: characterLimit - scriptState.script.length,
                  })}
            </Text>
          )}
        </GenerateScriptButtonContainer>
      </TextAreaWrapper>
    </ScriptSection>
  )
}

const NarrationTabDebug: React.FC<{
  narrationMetadata: NarrationMetadata | undefined
  minutesUsed: number
  quota: number
  showDebugData: boolean
  scriptOrAvatarNeedToBeUpdatedToGenerate: boolean
}> = ({ narrationMetadata, minutesUsed, quota, showDebugData, scriptOrAvatarNeedToBeUpdatedToGenerate }) => {
  return (
    <Debug>
      {showDebugData && (
        <View direction='column' gap='2'>
          <View gap='2'>
            <Text size='technical' bold>{`scriptOrAvatarNeedToBeUpdatedToGenerate:`}</Text>
            <Text size='technical'>{`${scriptOrAvatarNeedToBeUpdatedToGenerate}`}</Text>
          </View>

          <View gap='2'>
            <Text bold size='technical'>{`quota:`}</Text>
            <Text size='technical'>{quota}</Text>
          </View>

          <View gap='2'>
            <Text bold size='technical'>{`minutes used:`}</Text>
            <Text size='technical'>{minutesUsed}</Text>
          </View>

          {narrationMetadata !== undefined && (
            <>
              {Object.entries(narrationMetadata).map(([key, value]) => (
                <View key={key}>
                  <NoWrapText size='technical' bold>
                    <strong>{key}</strong>
                  </NoWrapText>
                  <TruncatedText size='technical' lines={1}>
                    {JSON.stringify(value)}
                  </TruncatedText>
                </View>
              ))}
            </>
          )}
        </View>
      )}
    </Debug>
  )
}

const QuotaContainer: React.FC<{
  quotaExceeded: boolean
  errorGeneratingNarration: boolean
  synthesiaUnavailable: boolean
  minutesUsed: number
  quota: number
  destinationType: DestinationTypeApi['type']
  errorCreatingNarration: Error | null
}> = ({
  quotaExceeded,
  errorGeneratingNarration,
  synthesiaUnavailable,
  minutesUsed,
  quota,
  destinationType,
  errorCreatingNarration,
}) => {
  const { t } = useTranslation()

  if (quotaExceeded) {
    return (
      <QuotaWrapper>
        <QuotaExceededContainer>
          <QuotaIconContainer>
            <Icon size='size-16' iconId='warning--filled' color='warning/background' />
          </QuotaIconContainer>

          <View direction='column' gap='4'>
            <Text bold size='small'>
              {t('ai-narration.quota-exceeded-title')}
            </Text>
            <Text size='small' color='foreground/secondary'>
              {t('ai-narration.quota-exceeded-description')}
            </Text>
          </View>
        </QuotaExceededContainer>
      </QuotaWrapper>
    )
  } else if (synthesiaUnavailable) {
    return (
      <View background='warning/background' radius='size-8' padding='10 16'>
        <Icon iconId='warning--filled' color='destructive/foreground' />
        <Text bold size='micro' color='warning/foreground'>
          {t('ai-narrations.synthesia-unavailable')}
        </Text>
      </View>
    )
  } else if (
    errorCreatingNarration instanceof RequestError &&
    errorCreatingNarration.text.toLowerCase().includes('moderation')
  ) {
    return (
      <View background='destructive/background' radius='size-8' padding='10 16'>
        <Icon iconId='warning--filled' color='destructive/foreground' />
        <Text bold size='micro' color='destructive/foreground'>
          {t('ai-narrations.content-moderation-error')}
        </Text>
      </View>
    )
  } else if (errorGeneratingNarration || errorCreatingNarration !== null) {
    return (
      <View background='destructive/background' radius='size-8' padding='10 16'>
        <Icon iconId='warning--filled' color='destructive/foreground' />
        <Text bold size='micro' color='destructive/foreground'>
          {t('ai-narrations.generation-error')}
        </Text>
      </View>
    )
  } else {
    return (
      <QuotaWrapper>
        <ProgressBar
          percent={(minutesUsed / quota) * 100}
          variant={quota - minutesUsed <= 5 ? 'warning/background' : 'org'}
        />

        <View direction='column' gap='4'>
          <Text bold size='small'>
            {t('ai-narrration.quota-title', { count: quota - minutesUsed })}
          </Text>
          <Text size='small' color='foreground/secondary'>
            {destinationType === 'card-narration'
              ? t('ai-narration.quota-description-card-narration', { count: quota })
              : t('ai-narration.quota-description-video-avatar', { count: quota })}
          </Text>
        </View>
      </QuotaWrapper>
    )
  }
}

const LegacyNarrationControls: React.FC<{
  fileId: FileId
  operationState: CreateOperationState
  forceShowSynthesiaUnavailable: boolean
  assetContext: AssetContext
  switchToLegacyMenu: () => void
  cancelGeneratingVideo: (() => void) | undefined
}> = ({ cancelGeneratingVideo, fileId, operationState, assetContext, switchToLegacyMenu }) => {
  const { t } = useTranslation()
  const confirmationModalContext = useConfirmationModalContext()

  return (
    <View grow>
      <Button
        onClick={() => {
          if (cancelGeneratingVideo !== undefined) {
            confirmationModalContext.show({
              bodyText: t('ai-narrations.cancel-warning'),
              confirmLabel: t('ai-narrations.cancel'),
              onConfirm: () => {
                cancelGeneratingVideo()
                switchToLegacyMenu()
              },
            })
          } else {
            switchToLegacyMenu()
          }
        }}
        variant='secondary'
        icon='record'
      >
        {t('dictionary.record')}
      </Button>
      <UploadVideoButton
        cancelGeneratingVideo={cancelGeneratingVideo}
        operationState={operationState}
        fileId={fileId}
        assetContext={assetContext}
      />
    </View>
  )
}

const NarrationTabAiButton: React.FC<{
  contentId: CreateContentId
  quotaExceeded: boolean
  scriptOrAvatarNeedToBeUpdatedToGenerate: boolean
  script: string
  scriptMinimumLength: number
  allowedCharactersLeftInScript: number
  isGeneratingVideo: boolean
  characterLimit: number
  narrationMetadata: NarrationMetadata | undefined
  generateAvatarLogging: () => void
  destinationTypeApi: DestinationTypeApi
  generateVideoMutation: UseMutationResult<
    | {
        type: 'synthesia-unavailable'
      }
    | {
        type: 'generating'
        narrationId: string & z.BRAND<'UUID'>
      },
    Error,
    void,
    unknown
  >
}> = ({
  contentId,
  quotaExceeded,
  scriptOrAvatarNeedToBeUpdatedToGenerate,
  script,
  scriptMinimumLength,
  allowedCharactersLeftInScript,
  isGeneratingVideo,
  characterLimit,
  narrationMetadata,
  generateAvatarLogging,
  destinationTypeApi,
  generateVideoMutation,
}) => {
  const { t } = useTranslation()
  const confirmationModal = useConfirmationModalContext()

  const narrationId = iife(() => {
    if (narrationMetadata === undefined) {
      return undefined
    }

    return narrationMetadata.narrationId
  })

  const cancelMutation = useTypedMutation(XRealtimeAuthorCancelGenerateNarrationAvatar, {})

  if (cancelMutation.error) {
    throw cancelMutation.error
  }

  const generateButtonState: {
    disabled: boolean
    tooltip: string | undefined
    warningBeforeSubmit: string | undefined
    loading: boolean
  } = iife(() => {
    const loading = generateVideoMutation.isPending || cancelMutation.isPending

    if (quotaExceeded) {
      return {
        disabled: true,
        tooltip: t('ai-narrations.quota-exceeded'),
        warningBeforeSubmit: undefined,
        loading,
      }
    } else if (scriptOrAvatarNeedToBeUpdatedToGenerate) {
      return {
        disabled: true,
        tooltip: t('ai-narrations.change-needed'),
        warningBeforeSubmit: undefined,
        loading,
      }
    } else if (script.length < scriptMinimumLength) {
      return {
        disabled: true,
        tooltip: t('ai-narrations.script-min-length-warning', { count: scriptMinimumLength }),
        warningBeforeSubmit: undefined,
        loading,
      }
    } else if (allowedCharactersLeftInScript < 0) {
      return {
        disabled: true,
        tooltip: t('ai-narrations.script-max-length-warning', { count: script.length - characterLimit }),
        warningBeforeSubmit: undefined,
        loading,
      }
    } else if (cancelMutation.isPending) {
      return {
        disabled: true,
        tooltip: t('ai-narrations.cancelling-generation'),
        warningBeforeSubmit: undefined,
        loading,
      }
    } else if (isGeneratingVideo) {
      return {
        disabled: false,
        tooltip: undefined,
        warningBeforeSubmit: t('ai-narrations.generation-overwrite-warning'),
        loading,
      }
    } else {
      return {
        disabled: false,
        tooltip: undefined,
        warningBeforeSubmit: undefined,
        loading,
      }
    }
  })

  return (
    <View gap='10'>
      <Tooltip title={generateButtonState.tooltip}>
        <AIButton
          $disabled={generateButtonState.disabled}
          $loading={generateButtonState.loading}
          onClick={() => {
            if (generateButtonState.disabled) {
              return
            } else if (generateButtonState.warningBeforeSubmit !== undefined) {
              assertIsNonNullable(narrationId, 'Cannot cancel a narration that does not exist')
              confirmationModal.show({
                bodyText: generateButtonState.warningBeforeSubmit,
                confirmLabel: t('dictionary.generate'),
                onConfirm: () => {
                  cancelMutation.mutate(
                    { narrationId, contentId, destinationTypeApi },
                    {
                      onSuccess: () => {
                        void generateVideoMutation.mutate()
                      },
                    }
                  )
                },
              })
            } else {
              generateVideoMutation.mutate()
              generateAvatarLogging()
            }
          }}
        >
          <View gap='8'>
            {generateButtonState.loading ? (
              <LoaderAnimation size={18} color='button/foreground' />
            ) : (
              <Icon color='button/foreground' iconId='sana-symbol' />
            )}
            <Text color='button/foreground'>
              {iife(() => {
                if (narrationMetadata !== undefined) {
                  return t('dictionary.regenerate')
                }

                return t('dictionary.generate')
              })}
            </Text>
          </View>
        </AIButton>
      </Tooltip>
    </View>
  )
}

export type LegacyButtons =
  | {
      includeLegacyButtons: false
    }
  | {
      includeLegacyButtons: true
      fileId: FileId
      operationState: CreateOperationState
      forceShowSynthesiaUnavailable: boolean
      assetContext: AssetContext
      cancelGeneratingVideo: (() => void) | undefined
      switchToLegacyMenu: () => void
    }

const NarrationTabBottomContent: React.FC<{
  destinationTypeApi: DestinationTypeApi
  contentId: CreateContentId
  quotaExceeded: boolean
  scriptOrAvatarNeedToBeUpdatedToGenerate: boolean
  script: string
  scriptMinimumLength: number
  allowedCharactersLeftInScript: number
  isGeneratingVideo: boolean
  characterLimit: number
  narrationMetadata: NarrationMetadata | undefined
  generateAvatarLogging: () => void
  generateVideoMutation: UseMutationResult<
    | {
        type: 'synthesia-unavailable'
      }
    | {
        type: 'generating'
        narrationId: string & z.BRAND<'UUID'>
      },
    Error,
    void,
    unknown
  >
  legacyButtons: LegacyButtons
}> = ({
  destinationTypeApi,
  contentId,
  quotaExceeded,
  scriptOrAvatarNeedToBeUpdatedToGenerate,
  script,
  scriptMinimumLength,
  allowedCharactersLeftInScript,
  isGeneratingVideo,
  characterLimit,
  narrationMetadata,
  generateAvatarLogging,
  generateVideoMutation,
  legacyButtons,
}) => {
  return (
    <NarrationsBottomContent>
      {legacyButtons.includeLegacyButtons === true && (
        <LegacyNarrationControls
          cancelGeneratingVideo={legacyButtons.cancelGeneratingVideo}
          fileId={legacyButtons.fileId}
          operationState={legacyButtons.operationState}
          forceShowSynthesiaUnavailable={legacyButtons.forceShowSynthesiaUnavailable}
          assetContext={legacyButtons.assetContext}
          switchToLegacyMenu={legacyButtons.switchToLegacyMenu}
        />
      )}
      <NarrationTabAiButton
        generateAvatarLogging={generateAvatarLogging}
        contentId={contentId}
        quotaExceeded={quotaExceeded}
        scriptOrAvatarNeedToBeUpdatedToGenerate={scriptOrAvatarNeedToBeUpdatedToGenerate}
        script={script}
        scriptMinimumLength={scriptMinimumLength}
        allowedCharactersLeftInScript={allowedCharactersLeftInScript}
        isGeneratingVideo={isGeneratingVideo}
        characterLimit={characterLimit}
        narrationMetadata={narrationMetadata}
        generateVideoMutation={generateVideoMutation}
        destinationTypeApi={destinationTypeApi}
      />
    </NarrationsBottomContent>
  )
}

export const VideoCardNarration: React.FC<{
  contentId: CreateContentId
  fileId: FileId
  narrationMetadata: NarrationMetadata | undefined
  quotaExceeded: boolean
  characterLimit: number
  minutesUsed: number
  quota: number
  avatars: Avatar[]
  showDebugData: boolean
  errorGeneratingNarration: boolean
  forceShowSynthesiaUnavailable: boolean
  onClose: () => void
  avatarId: AvatarId
  setAvatarId: React.Dispatch<React.SetStateAction<AvatarId>>
  scriptState: ScriptState
  destinationTypeApi: DestinationTypeApi
}> = ({
  contentId,
  fileId,
  quotaExceeded,
  characterLimit,
  narrationMetadata,
  minutesUsed,
  quota,
  avatars,
  showDebugData,
  errorGeneratingNarration,
  forceShowSynthesiaUnavailable,
  onClose,
  avatarId,
  setAvatarId,
  scriptState,
  destinationTypeApi,
}) => {
  const { t } = useTranslation()
  const dispatch = useDispatch()

  const generateVideoMutation = useMutation({
    mutationFn: () =>
      typedPost(XRealtimeAuthorGenerateNarrationAvatar, {
        contentId,
        fileId,
        script: scriptState.script,
        avatarId,
        destinationTypeApi: destinationTypeApi,
      }),
  })

  const synthesiaUnavailable =
    forceShowSynthesiaUnavailable || generateVideoMutation.data?.type === 'synthesia-unavailable'

  const isGeneratingVideo = narrationMetadata?.type === 'loading' || generateVideoMutation.isPending

  const scriptMinimumLength = 50

  const allowedCharactersLeftInScript = characterLimit - scriptState.script.length
  const scriptOrAvatarNeedToBeUpdatedToGenerate = iife(() => {
    if (narrationMetadata === undefined) {
      return false
    }

    return avatarId === narrationMetadata.avatarId && scriptState.script === narrationMetadata.script
  })

  return (
    <NarrationsContent>
      <CloseButton onClick={onClose} />
      <NarrationsTopContent>
        <NarrationTabHeading destinationType='video' />
        <View marginTop={'32'} direction='column' gap='8'>
          <Text size='small' bold>
            {t('dictionary.narrator')}
          </Text>

          <ChooseAvatar
            avatars={avatars}
            avatarId={avatarId}
            setAvatarId={setAvatarId}
            quotaExceeded={quotaExceeded}
          />
        </View>
        <NarrationTabScript
          destinationType={destinationTypeApi.type}
          generateScriptLogging={() => {
            void dispatch(
              generateVideoCardAvatarScriptGenerated({
                avatarDestinationType: destinationTypeApi.type,
              })
            )
          }}
          quotaExceeded={quotaExceeded}
          fileId={fileId}
          courseId={contentId}
          characterLimit={characterLimit}
          scriptState={scriptState}
        />
        <NarrationTabDebug
          narrationMetadata={narrationMetadata}
          minutesUsed={minutesUsed}
          quota={quota}
          showDebugData={showDebugData}
          scriptOrAvatarNeedToBeUpdatedToGenerate={scriptOrAvatarNeedToBeUpdatedToGenerate}
        />
        <View paddingBottom='24'>
          <QuotaContainer
            quotaExceeded={quotaExceeded}
            errorGeneratingNarration={errorGeneratingNarration}
            errorCreatingNarration={generateVideoMutation.error}
            synthesiaUnavailable={synthesiaUnavailable}
            quota={quota}
            minutesUsed={minutesUsed}
            destinationType={destinationTypeApi.type}
          />
        </View>
      </NarrationsTopContent>
      <NarrationTabBottomContent
        destinationTypeApi={destinationTypeApi}
        generateAvatarLogging={() => {
          void dispatch(
            generateVideoCardAvatarAvatarGenerated({
              avatarDestinationType: destinationTypeApi.type,
            })
          )
        }}
        contentId={contentId}
        quotaExceeded={quotaExceeded}
        scriptOrAvatarNeedToBeUpdatedToGenerate={scriptOrAvatarNeedToBeUpdatedToGenerate}
        script={scriptState.script}
        scriptMinimumLength={scriptMinimumLength}
        allowedCharactersLeftInScript={allowedCharactersLeftInScript}
        isGeneratingVideo={isGeneratingVideo}
        characterLimit={characterLimit}
        narrationMetadata={narrationMetadata}
        generateVideoMutation={generateVideoMutation}
        legacyButtons={{
          includeLegacyButtons: false,
        }}
      />
    </NarrationsContent>
  )
}

export const NarrationTab: React.FC<{
  contentId: CreateContentId
  fileId: FileId
  operationState: CreateOperationState
  aiNarrationControlStateAtom: PrimitiveAtom<AINarrationControlsState>
  legacyNarrationStateAtom: PrimitiveAtom<boolean>
  narrationMetadata: NarrationMetadata | undefined
  quotaExceeded: boolean
  characterLimit: number
  minutesUsed: number
  quota: number
  avatars: Avatar[]
  showDebugData: boolean
  errorGeneratingNarration: boolean
  forceShowSynthesiaUnavailable: boolean
  assetContext: AssetContext
  avatarId: AvatarId
  setAvatarId: React.Dispatch<React.SetStateAction<AvatarId>>
  scriptState: ScriptState
}> = ({
  contentId,
  fileId,
  operationState,
  aiNarrationControlStateAtom,
  legacyNarrationStateAtom,
  quotaExceeded,
  characterLimit,
  narrationMetadata,
  minutesUsed,
  quota,
  avatars,
  showDebugData,
  errorGeneratingNarration,
  forceShowSynthesiaUnavailable,
  assetContext,
  avatarId,
  setAvatarId,
  scriptState,
}) => {
  const { t } = useTranslation()
  const dispatch = useDispatch()

  const [aiNarrationControlState, setAiNarrationControlState] = useAtom(aiNarrationControlStateAtom)
  const setLegacyNarrationState = useSetAtom(legacyNarrationStateAtom)

  useEffect(() => {
    if (aiNarrationControlState.type === 'open' && aiNarrationControlState.preSelectAvatar !== undefined) {
      setAvatarId(aiNarrationControlState.preSelectAvatar)
    }
  }, [aiNarrationControlState, setAvatarId])

  const generateVideoMutation = useMutation({
    mutationFn: () =>
      typedPost(XRealtimeAuthorGenerateNarrationAvatar, {
        contentId,
        fileId,
        script: scriptState.script,
        avatarId,
        destinationTypeApi: { type: 'card-narration' },
      }),
  })

  const synthesiaUnavailable =
    forceShowSynthesiaUnavailable || generateVideoMutation.data?.type === 'synthesia-unavailable'

  const isGeneratingVideo = narrationMetadata?.type === 'loading' || generateVideoMutation.isPending

  const scriptMinimumLength = 50

  const allowedCharactersLeftInScript = characterLimit - scriptState.script.length
  const scriptOrAvatarNeedToBeUpdatedToGenerate = iife(() => {
    if (narrationMetadata === undefined) {
      return false
    }

    return avatarId === narrationMetadata.avatarId && scriptState.script === narrationMetadata.script
  })

  const switchToLegacyMenu = useStableFunction(() => {
    setAiNarrationControlState({ type: 'closed' })
    setLegacyNarrationState(true)
  })

  const cancelMutation = useTypedMutation(XRealtimeAuthorCancelGenerateNarrationAvatar, {})
  const cancelGeneratingVideo =
    narrationMetadata?.type === 'loading'
      ? () =>
          cancelMutation.mutate({
            narrationId: narrationMetadata.narrationId,
            contentId,
            destinationTypeApi: { type: 'card-narration' },
          })
      : undefined

  return (
    <NarrationsContent>
      <CloseButton onClick={() => setAiNarrationControlState({ type: 'closed' })} />
      <NarrationsTopContent>
        <NarrationTabHeading destinationType='card' />

        <View marginTop={'32'} direction='column' gap='8'>
          <Text size='small' bold>
            {t('dictionary.narrator')}
          </Text>

          <ChooseAvatar
            avatars={avatars}
            avatarId={avatarId}
            setAvatarId={setAvatarId}
            quotaExceeded={quotaExceeded}
          />
        </View>

        <NarrationTabScript
          destinationType='card-narration'
          fileId={fileId}
          courseId={contentId}
          characterLimit={characterLimit}
          quotaExceeded={quotaExceeded}
          scriptState={scriptState}
          generateScriptLogging={() => {
            void dispatch(
              generateVideoCardAvatarScriptGenerated({
                avatarDestinationType: 'card-narration',
              })
            )
          }}
        />

        <NarrationTabDebug
          narrationMetadata={narrationMetadata}
          minutesUsed={minutesUsed}
          quota={quota}
          showDebugData={showDebugData}
          scriptOrAvatarNeedToBeUpdatedToGenerate={scriptOrAvatarNeedToBeUpdatedToGenerate}
        />

        <View paddingBottom='24'>
          <QuotaContainer
            quotaExceeded={quotaExceeded}
            errorGeneratingNarration={errorGeneratingNarration}
            errorCreatingNarration={generateVideoMutation.error}
            synthesiaUnavailable={synthesiaUnavailable}
            quota={quota}
            minutesUsed={minutesUsed}
            destinationType='card-narration'
          />
        </View>
        <NarrationTabBottomContent
          destinationTypeApi={{ type: 'card-narration' }}
          generateAvatarLogging={() => {
            void dispatch(
              generateVideoCardAvatarAvatarGenerated({
                avatarDestinationType: 'card-narration',
              })
            )
          }}
          contentId={contentId}
          quotaExceeded={quotaExceeded}
          scriptOrAvatarNeedToBeUpdatedToGenerate={scriptOrAvatarNeedToBeUpdatedToGenerate}
          script={scriptState.script}
          scriptMinimumLength={scriptMinimumLength}
          allowedCharactersLeftInScript={allowedCharactersLeftInScript}
          isGeneratingVideo={isGeneratingVideo}
          characterLimit={characterLimit}
          narrationMetadata={narrationMetadata}
          generateVideoMutation={generateVideoMutation}
          legacyButtons={{
            includeLegacyButtons: true,
            fileId,
            operationState,
            forceShowSynthesiaUnavailable,
            assetContext,
            switchToLegacyMenu,
            cancelGeneratingVideo,
          }}
        />
      </NarrationsTopContent>
    </NarrationsContent>
  )
}

export type AiNarrationsDebugControls = {
  quotaExceededOverride: 'no-override' | 'exceeded'
  errorGeneratingNarrationOverride: 'no-override' | 'error'
  synthesiaUnavailableOverride: 'no-override' | 'unavailable'
  showDebugData: boolean
  minutesUsedOverride: 'no-override' | '5%' | '50%' | '95%' | '100%' | '150%'
}

export function calculateQuotaExceeded(
  debugControls: AiNarrationsDebugControls,
  isDebugMode: boolean,
  settings: NarrationSettings
): { minutesUsed: number; quotaExceeded: boolean } {
  const { quotaExceededOverride, minutesUsedOverride } = debugControls
  const { usage, quota } = settings

  const minutesUsed = iife(() => {
    const actualUsage = usage?.minutesUsed ?? 0

    switch (minutesUsedOverride) {
      case 'no-override':
        return actualUsage
      case '5%':
        return Math.ceil(quota * 0.05)
      case '50%':
        return Math.ceil(quota * 0.5)
      case '95%':
        return Math.ceil(quota * 0.95)
      case '100%':
        return quota
      case '150%':
        return Math.ceil(quota * 1.5)
      default:
        assertNever(minutesUsedOverride)
    }
  })

  const quotaExceeded = iife(() => {
    const actualValue = minutesUsed >= quota
    if (isDebugMode && quotaExceededOverride === 'exceeded') {
      return true
    } else {
      return actualValue
    }
  })

  return { minutesUsed, quotaExceeded }
}
