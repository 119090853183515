import { FC } from 'react'
import { ExerciseState } from 'sierra-client/api/graphql/gql/graphql'
import { useTranslation } from 'sierra-client/hooks/use-translation'
import { TranslationKey } from 'sierra-client/hooks/use-translation/types'
import { getGlobalRouter } from 'sierra-client/router'
import { useIsMobile } from 'sierra-client/state/browser/selectors'
import { getHomeworkStates } from 'sierra-client/views/learner/program/utils/utils'
import { LinkURL } from 'sierra-client/views/workspace/utils/urls'
import { LearnEntity } from 'sierra-domain/api/entities'
import { getPrioritizedHomework } from 'sierra-domain/homework/utils'
import { isNonEmptyArray } from 'sierra-domain/utils'
import { IconId, MenuButton } from 'sierra-ui/components'
import { Button, IconButton, View } from 'sierra-ui/primitives'
import { v2_breakpoint } from 'sierra-ui/theming/breakpoints'
import styled from 'styled-components'

const ButtonView = styled(View)`
  @media screen and (max-width: ${v2_breakpoint.phone}) {
    width: 100%;
    display: block;
  }
`

export const StepButton: FC<{
  url: LinkURL | undefined
  contentDetailUrl: LinkURL | undefined
  progress: number
  completed: boolean
  exercises?: ExerciseState[]
  locked: boolean
  contentType: LearnEntity['entityType']
  isNextStep?: boolean
  disableStart?: boolean
  disableReset: boolean
  isHardFailed: boolean
  onReset: () => void
}> = ({
  url,
  contentDetailUrl,
  progress,
  completed,
  exercises,
  locked,
  contentType,
  isNextStep = false,
  disableStart,
  disableReset,
  isHardFailed,
  onReset,
}) => {
  const { t } = useTranslation()
  const isMobile = useIsMobile()

  const onDetailsClick = (): void => {
    if (contentDetailUrl !== undefined) {
      void getGlobalRouter().navigate({ to: contentDetailUrl as string })
    }
  }

  if (isHardFailed) {
    return (
      <ButtonView onClick={event => event.preventDefault()}>
        <Button grow variant='secondary' onClick={onDetailsClick}>
          {t('dictionary.details')}
        </Button>
      </ButtonView>
    )
  }

  const isCourse = (): boolean => {
    if (
      contentType !== 'native:live' &&
      contentType !== 'native:event-group' &&
      contentType !== 'path' &&
      contentType !== 'program'
    ) {
      return true
    }

    return false
  }

  const getIcon = (): IconId | undefined => {
    if (completed) return undefined
    if (isNonEmptyArray(exercises)) {
      const homeworkStates = getHomeworkStates(exercises)
      const selectedHomework = getPrioritizedHomework(homeworkStates)
      if (selectedHomework?.grade === 'failed') return 'restart'
      if (selectedHomework?.grade === 'failed-with-no-retries') return undefined
      return 'restart'
    }

    if (isCourse()) return 'play--filled'
    return undefined
  }

  const getLabel = (): TranslationKey => {
    if (completed) {
      return 'dictionary.review'
    }

    if (isNonEmptyArray(exercises)) {
      const homeworkStates = getHomeworkStates(exercises)
      const selectedHomework = getPrioritizedHomework(homeworkStates)

      switch (selectedHomework?.grade) {
        case 'failed':
          return 'my-library.items.homework.retry'
        case 'failed-with-no-retries':
          return 'dictionary.details'
        case 'not-graded':
          return 'dictionary.review'
        default:
          break
      }
    }

    switch (contentType) {
      case 'native:live':
        return 'dictionary.join'
      case 'path':
      case 'program':
      case 'native:event-group':
        return 'dictionary.view'
      default:
        return progress === 0 ? 'dictionary.start' : 'dictionary.continue'
    }
  }

  const onStartClick = (): void => {
    if (url !== undefined) {
      void getGlobalRouter().navigate({ to: url as string })
    }
  }

  if (locked) {
    return (
      <ButtonView justifyContent='flex-end'>
        <Button variant='secondary' grow disabled icon='locked'>
          {t('dictionary.locked')}
        </Button>
      </ButtonView>
    )
  }

  const icon = getIcon()
  const title = getLabel()

  if (!isMobile && isCourse() && !isNextStep && !completed) {
    return (
      <View onClick={event => event.preventDefault()}>
        <IconButton
          grow
          disabled={disableStart}
          variant='secondary'
          iconId={icon ?? 'play--filled'}
          onClick={onStartClick}
        />
      </View>
    )
  }

  return (
    <ButtonView
      justifyContent='flex-end'
      onClick={event => {
        event.preventDefault()
      }}
    >
      <MenuButton
        grow
        onSelect={item => {
          switch (item.id) {
            case 'start':
              return onStartClick()
            case 'reset':
              return onReset()
          }
        }}
        iconId={icon}
        disabled={disableStart}
        variant={isNextStep ? 'primary' : 'secondary'}
        menuItems={[
          {
            id: 'start',
            type: 'label',
            label: t('dictionary.continue'),
            disabled: disableStart,
          },
          {
            type: 'label',
            id: 'reset',
            label: t('dictionary.start-over'),
            description: t('manage.reset-progress-and-view'),
            disabled: disableReset,
          },
        ]}
        onPrimaryClick={() => onStartClick()}
        menuLabel={t('dictionary.more-options')}
      >
        {t(title)}
      </MenuButton>
    </ButtonView>
  )
}
