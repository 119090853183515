import { createDraftSafeSelector } from '@reduxjs/toolkit'
import { selectCurrentCourse } from 'sierra-client/state/content/selectors'
import { ResultsState } from 'sierra-client/state/content/types'
import { RootState } from 'sierra-client/state/types'
import { isDefined } from 'sierra-domain/utils'

const selectResults = (rootState: RootState): ResultsState => rootState.results
const selectDueDateStatuses = createDraftSafeSelector(selectResults, state => state.dueDates)

export const selectDueDate = createDraftSafeSelector(
  [selectCurrentCourse, selectDueDateStatuses],
  (course, dueDateStatuses): string | undefined =>
    isDefined(course) ? dueDateStatuses[course.id] : undefined
)
