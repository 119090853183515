import _ from 'lodash'
import { useMemo } from 'react'
import { ShortcutMenuSettingsItem } from 'sierra-client/components/shortcut-menu'
import { Logging } from 'sierra-client/core/logging'
import { isEditableYjsEditor, withoutSavingToUndoStack } from 'sierra-client/editor'
import { useTranslation } from 'sierra-client/hooks/use-translation'
import { UnsplashImage, fetchImages } from 'sierra-client/hooks/use-unsplash-images'
import { postWithUserErrorCode, postWithUserErrorException } from 'sierra-client/state/api'
import { NotificationPushType, NotificationType } from 'sierra-client/state/notifications/types'
import { store } from 'sierra-client/state/store'
import { RootStateThunkDispatch } from 'sierra-client/state/types'
import * as commands from 'sierra-client/views/v3-author/command'
import { getPositionInDocument, replaceOrInsert } from 'sierra-client/views/v3-author/slash-menu/domain'
import {
  textBeforeNodeWithId,
  toTakeawaysGroup,
} from 'sierra-client/views/v3-author/slash-menu/slash-menu-question-utils'
import { SlashMenuEntry } from 'sierra-client/views/v3-author/slash-menu/types'
import { CreateContentId } from 'sierra-domain/api/nano-id'
import { serialize } from 'sierra-domain/collaboration/serialization'
import { ImageUnion } from 'sierra-domain/content/v2/content'
import { isLeft, isRight } from 'sierra-domain/either'
import { Entity } from 'sierra-domain/entity'
import { NodeId } from 'sierra-domain/flexible-content/identifiers'
import { FlexibleContentJsonData } from 'sierra-domain/flexible-content/types'
import { nanoid12 } from 'sierra-domain/nanoid-extensions'
import {
  XRealtimeAuthorGenerateParagraph,
  XRealtimeAuthorGeneratePollFlexibleContent,
  XRealtimeAuthorGenerateQuestionFlexibleContent,
  XRealtimeAuthorGenerateQuestionFromText,
  XRealtimeAuthorGenerateTakeawaysGeneralCard,
  XRealtimeAuthorTextToKeywords,
} from 'sierra-domain/routes'
import { isDefined } from 'sierra-domain/utils'
import { Image, QuestionCard, Takeaways } from 'sierra-domain/v3-author'
import * as Blocks from 'sierra-domain/v3-author/create-blocks'
import {
  createParagraph,
  createPlaceholder,
  createQuestionCard,
  createQuestionCardMultipleChoiceAlternative,
  createQuestionCardMultipleChoiceBody,
  createQuestionMultipleChoiceAlternativeExplanation,
  createQuestionMultipleChoiceAlternativeOption,
} from 'sierra-domain/v3-author/create-blocks'
import { Editor, Element, Node, Path, Transforms } from 'slate'
import * as Y from 'yjs'

export const generateParagraph =
  (): SlashMenuEntry['edit'] =>
  async ({ editor, lastSelection }) => {
    if (lastSelection === null) return
    const positionInDocument = getPositionInDocument(lastSelection)

    const startPointId = nanoid12()
    Transforms.insertNodes(
      editor,
      { ...createPlaceholder(), id: startPointId },
      { at: [positionInDocument + 1] }
    )
    // All the non-question text in the document up to the question that is being generated.
    const prompt = textBeforeNodeWithId(editor, startPointId)
    const { text: OpenAiParagraph } = await postWithUserErrorException(
      XRealtimeAuthorGenerateParagraph,
      { prompt, type: 'completion' },
      store.dispatch
    )
    console.debug('[content-generation] result:', OpenAiParagraph)
    commands.replaceNodeWithId(editor, startPointId, {
      id: startPointId,
      type: 'paragraph',
      children: [
        {
          text: OpenAiParagraph,
        },
      ],
    })
  }

export const getAllNodeIds = (yDoc: Y.Doc): NodeId[] | undefined => {
  const serializedYDoc = serialize(yDoc)
  const nodeMap = FlexibleContentJsonData.parse(serializedYDoc['']?.jsonData).nodeMap

  const rootNodeIds = nodeMap['folder:root']?.type === 'folder' ? nodeMap['folder:root'].nodeIds : undefined

  const getAllNodes = (nodeIds: NodeId[]): NodeId[] => {
    return nodeIds
      .map(it => {
        const node = nodeMap[it]
        if (node !== undefined && node.type === 'folder') {
          return getAllNodes(node.nodeIds)
        } else return it
      })
      .flat()
  }

  return rootNodeIds ? getAllNodes(rootNodeIds) : undefined
}

type GeneratedQuestion = {
  text: string
  alternatives: {
    text: string
    type: 'correct' | 'incorrect' | 'partially-correct'
  }[]
  selection: 'single' | 'multiple'
}

type QuestionSelection = 'single' | 'multiple'

export const convertGeneratedQuestionToCard = (question: GeneratedQuestion): Entity<QuestionCard> =>
  createQuestionCard({
    children: [
      createParagraph({ children: [{ text: question.text }] }),
      createQuestionCardMultipleChoiceBody({
        children: question.alternatives.map(it =>
          createQuestionCardMultipleChoiceAlternative({
            children: [
              createQuestionMultipleChoiceAlternativeOption({ children: [{ text: it.text }] }),
              createQuestionMultipleChoiceAlternativeExplanation(),
            ],
            id: nanoid12(),
            status: it.type === 'partially-correct' ? 'correct' : it.type,
          })
        ),
      }),
    ],
  })

export const generateQuestionFlexibleContent = async (
  courseId: CreateContentId,
  afterNodes: NodeId[],
  afterSlateBlocks: string[],
  notification?: {
    push: (v: NotificationPushType) => void
  },
  selection?: QuestionSelection
): Promise<GeneratedQuestion | undefined> => {
  const execute = async (): Promise<undefined | GeneratedQuestion> => {
    // All the non-question text in the document up to the question that is being generated.
    const response = await postWithUserErrorCode(
      XRealtimeAuthorGenerateQuestionFlexibleContent,
      {
        flexibleContentId: courseId,
        afterNodes: afterNodes.map(it => it.split(':')[1] ?? ''),
        selection: selection ?? 'single',
        afterSlateBlocks,
      },
      store.dispatch
    )
    if (isRight(response)) {
      const generatedQuestion: GeneratedQuestion = {
        text: response.right.question?.text ?? '',
        alternatives:
          response.right.question?.alternatives ?? [''].map(it => ({ text: it, type: 'incorrect' })),
        selection: response.right.question?.selection ?? 'single',
      }

      void store.dispatch(
        Logging.authoring.questionGenerated({
          courseId,
          generateQuestionResponse: response.right,
        })
      )

      return generatedQuestion
    } else if (isLeft(response)) {
      if (
        response.left === 'generate-question/short-context' ||
        response.left === 'generate-question/no-question' ||
        response.left === 'generate-question/sensitive-context'
      ) {
        const notificationCode = response.left as NotificationType
        notification?.push({ type: notificationCode })
      } else {
        notification?.push({ type: 'error' })
      }
    }
  }
  try {
    const firstTry = await execute() // Don't remove; this ensures that the exception is propagated
    return firstTry
  } catch (_) {
    try {
      const secondTry = await execute() // Don't remove; this ensures that the exception is propagated
      return secondTry
    } catch (e) {
      notification?.push({ type: 'error' })
    }
  }
}

export const generateQuestionFromString = async (
  text: string,
  notification?: {
    push: (v: NotificationPushType) => void
  }
): Promise<Entity<QuestionCard> | undefined> => {
  const execute = async (): Promise<Entity<QuestionCard> | undefined> => {
    // All the non-question text in the document up to the question that is being generated.
    const response = await postWithUserErrorCode(
      XRealtimeAuthorGenerateQuestionFromText,
      {
        text,
      },
      store.dispatch
    )
    if (isRight(response)) {
      const generatedQuestion: GeneratedQuestion = {
        text: response.right.question?.text ?? '',
        alternatives:
          response.right.question?.alternatives ?? [''].map(it => ({ text: it, type: 'incorrect' })),
        selection: response.right.question?.selection ?? 'single',
      }

      // void store.dispatch(
      //   Logging.authoring.questionGenerated({
      //     courseId,
      //     generateQuestionResponse: response.right,
      //   })
      // )
      return convertGeneratedQuestionToCard(generatedQuestion)
    } else if (isLeft(response)) {
      if (
        response.left === 'generate-question/short-context' ||
        response.left === 'generate-question/no-question' ||
        response.left === 'generate-question/sensitive-context'
      ) {
        const notificationCode = response.left as NotificationType
        notification?.push({ type: notificationCode })
      } else {
        notification?.push({ type: 'error' })
      }
    }
  }
  try {
    const firstTry = await execute() // Don't remove; this ensures that the exception is propagated
    return firstTry
  } catch (_) {
    try {
      const secondTry = await execute() // Don't remove; this ensures that the exception is propagated
      return secondTry
    } catch (e) {
      notification?.push({ type: 'error' })
    }
  }
}

export type GeneratedPoll = {
  text: string
  alternatives: string[]
}

export type PollTypes = 'reflection' | 'introduction' | 'check-in'

export const generatePollFlexibleContent = async (
  courseId: CreateContentId,
  afterNodes: NodeId[],
  type: PollTypes,
  notification?: {
    push: (v: NotificationPushType) => void
  }
): Promise<GeneratedPoll | undefined> => {
  const execute = async (): Promise<undefined | GeneratedPoll> => {
    // All the non-question text in the document up to the question that is being generated.
    const response = await postWithUserErrorCode(
      XRealtimeAuthorGeneratePollFlexibleContent,
      {
        flexibleContentId: courseId,
        afterNodes: afterNodes.map(it => it.split(':')[1] ?? ''),
        type,
      },
      store.dispatch
    )
    if (isRight(response)) {
      //TODO: logging
      const generatedPoll: GeneratedPoll = {
        text: response.right.poll?.text ?? '',
        alternatives: response.right.poll?.alternatives ?? [],
      }

      return generatedPoll
    } else if (isLeft(response)) {
      if (response.left === 'generate-question/no-question') {
        const notificationCode = 'generate-poll/no-poll' as NotificationType
        notification?.push({ type: notificationCode })
      }
      if (response.left === 'generate-question/short-context') {
        const notificationCode = 'generate-poll/short-context' as NotificationType
        notification?.push({ type: notificationCode })
      } else {
        notification?.push({ type: 'error' })
      }
    }
  }
  try {
    const firstTry = await execute() // Don't remove; this ensures that the exception is propagated
    return firstTry
  } catch (_) {
    try {
      const secondTry = await execute() // Don't remove; this ensures that the exception is propagated
      return secondTry
    } catch (e) {
      notification?.push({ type: 'error' })
    }
  }
}

type PollItem = ShortcutMenuSettingsItem & { id: PollTypes }

export function useGeneratePollItems(): PollItem[] {
  const { t } = useTranslation()
  return useMemo(
    (): PollItem[] => [
      {
        id: 'check-in',
        label: t('author.slate.generate-poll.check-in'),
      },
      {
        id: 'introduction',
        label: t('author.slate.generate-poll.introductory'),
      },
      {
        id: 'reflection',
        label: t('author.slate.generate-poll.reflective'),
      },
    ],
    [t]
  )
}

const getGenerationContextBlockIds = ({
  editor,
  path,
}: {
  editor: Editor
  path: Path | undefined
}): string[] => {
  if (path === undefined) return []
  const [positionInDocument] = path
  if (positionInDocument === undefined) {
    throw new Error('[getPositionInDocument] Position unvailable.')
  }
  const contextSize = positionInDocument === 0 ? 1 : 2 // Editor state might not be synced with backend so we include more than just the immediate block
  const contextNodeIds = []
  for (let i = contextSize - 1; i >= 0; i--) {
    const node = Node.get(editor, [positionInDocument - i])
    if (Element.isElement(node)) {
      contextNodeIds.push(node.id)
    }
  }
  return contextNodeIds
}

type TakeawaysGenerationArgs = {
  flexibleContentId: CreateContentId
  afterBlocks: string[]
  previousNodeIds: string[]
}

const generateAndInsertTakeaways = async ({
  editor,
  takeawaysGenerationArgs,
  blockId,
  notification,
  saveInsert,
}: {
  editor: Editor
  takeawaysGenerationArgs: TakeawaysGenerationArgs
  blockId: string
  notification?: {
    push: (v: NotificationPushType) => void
  }
  saveInsert?: boolean
}): Promise<undefined | Entity<Takeaways>> => {
  const dispatch: RootStateThunkDispatch = store.dispatch

  const execute = async (): Promise<undefined | Entity<Takeaways>> => {
    const generatedTakeaways = await postWithUserErrorCode(
      XRealtimeAuthorGenerateTakeawaysGeneralCard,
      takeawaysGenerationArgs,
      dispatch
    )

    if (isRight(generatedTakeaways)) {
      const takeaways = toTakeawaysGroup(generatedTakeaways.right, blockId)
      void dispatch(
        Logging.authoring.takeawaysGenerated({
          courseId: takeawaysGenerationArgs.flexibleContentId,
          fileId: _.last(takeawaysGenerationArgs.previousNodeIds) ?? 'no file',
          generatedTakeaways: generatedTakeaways.right,
        })
      )
      commands.replaceNodeWithId(editor, blockId, takeaways, false, saveInsert)
      return takeaways
    } else if (isLeft(generatedTakeaways)) {
      if (generatedTakeaways.left === 'generate-takeaways/no-takeaway') {
        notification?.push({ type: generatedTakeaways.left as NotificationType })
      }
      if (generatedTakeaways.left === 'generate-question/short-context') {
        const notificationCode = 'generate-takeaways/short-context' as NotificationType
        notification?.push({ type: notificationCode })
      } else {
        notification?.push({ type: 'error' })
      }
      const emptyTakeaways: Entity<Takeaways> = {
        children: [''].map(kp => ({
          children: [Blocks.createParagraph({ children: [{ text: kp }] })],
          type: 'takeaway-item',
          id: nanoid12(),
        })),
        type: 'takeaways',
        id: blockId,
      }
      commands.replaceNodeWithId(editor, blockId, emptyTakeaways, false, saveInsert)
    }
  }

  try {
    const firstTry = await execute() // Don't remove; this ensures that the exception is propagated
    return firstTry
  } catch (_) {
    try {
      const secondTry = await execute() // Don't remove; this ensures that the exception is propagated
      return secondTry
    } catch (e) {
      const emptyTakeaways: Entity<Takeaways> = {
        children: [''].map(kp => ({
          children: [Blocks.createParagraph({ children: [{ text: kp }] })],
          type: 'takeaway-item',
          id: nanoid12(),
        })),
        type: 'takeaways',
        id: blockId,
      }
      commands.replaceNodeWithId(editor, blockId, emptyTakeaways, false, saveInsert)
    }
  }
}

export const generateTakeaways: SlashMenuEntry['edit'] = ({
  editor,
  lastSelection,
  notification,
  createPageContext,
}) => {
  const contextNodeIds = getGenerationContextBlockIds({ editor, path: lastSelection?.anchor.path })

  const takeawaysGenerationArgs = (() => {
    if (!isEditableYjsEditor(editor)) return
    if (createPageContext !== undefined && contextNodeIds.length > 0) {
      const { createContentId, nodeId } = createPageContext
      const yDoc = editor.sharedRoot.doc
      const nodeIds = yDoc ? getAllNodeIds(yDoc) : [nodeId]
      if (nodeIds === undefined) throw new Error('Unable to generate a question in a course without nodeIds')
      if (nodeId === undefined)
        throw new Error('Unable to generate a question. The current node is not defined')

      const previousNodes = nodeIds.includes(nodeId) ? nodeIds.slice(0, nodeIds.indexOf(nodeId) + 1) : nodeIds

      const returnValue: TakeawaysGenerationArgs = {
        flexibleContentId: createContentId,
        afterBlocks: contextNodeIds,
        previousNodeIds: previousNodes.map(id => id?.split(':')[1] ?? ''),
      }
      return returnValue
    } else return undefined
  })()

  if (takeawaysGenerationArgs !== undefined) {
    const blockId = nanoid12()
    // Don't save the placeholder to history
    withoutSavingToUndoStack(editor, () => {
      replaceOrInsert({
        editor,
        element: { ...Blocks.createPlaceholder(), id: blockId },
        id: contextNodeIds[contextNodeIds.length - 1],
      })
    })

    // Create takeaways and insert
    void generateAndInsertTakeaways({
      editor,
      takeawaysGenerationArgs,
      blockId,
      notification,
    })
  }
}

export const generateUnsplashImageFromString = async (
  text: string,
  notification?: {
    push: (v: NotificationPushType) => void
  }
): Promise<Entity<Image> | undefined> => {
  const execute = async (): Promise<Entity<Image> | undefined> => {
    const response = await postWithUserErrorCode(
      XRealtimeAuthorTextToKeywords,
      {
        text,
      },
      store.dispatch
    )

    if (isRight(response)) {
      const query: string | undefined = response.right.keywords[0]

      if (query === undefined) return
      const results = await fetchImages(query)

      const image = _.shuffle(_.take(results, 20))[0]

      const toUnsplashImage = (image: UnsplashImage): ImageUnion => ({
        type: 'unsplash',
        url: image.urls.raw,
        width: image.width,
        height: image.height,
        user: {
          name: image.user.name,
          username: image.user.username,
        },
      })

      if (!isDefined(image)) return undefined
      const unsplashImage = toUnsplashImage(image)
      return Blocks.createImage({ image: unsplashImage, variant: 'narrow' })
    } else if (isLeft(response)) {
      notification?.push({ type: 'error' })
    }
  }
  try {
    const firstTry = await execute() // Don't remove; this ensures that the exception is propagated
    return firstTry
  } catch (_) {
    try {
      const secondTry = await execute() // Don't remove; this ensures that the exception is propagated
      return secondTry
    } catch (e) {
      notification?.push({ type: 'error' })
    }
  }
}
