import { produce } from 'immer'
import {
  useLocalPermissionsConfig,
  useSetLocalPermissionsConfig,
} from 'sierra-client/components/shortcut-menu/permissions-inspector/atoms'
import { PermissionOverrides } from 'sierra-client/components/shortcut-menu/permissions-inspector/types'
import { PermissionKey, PermissionOptions } from 'sierra-client/hooks/use-permissions/types'

type UseLocalPermissionsConfig = {
  localPermissionsConfig: PermissionOverrides
  togglePermission: <K extends PermissionKey>(category: K, permission: PermissionOptions[K]) => void
}

export const useEditLocalPermissionsConfig = (): UseLocalPermissionsConfig => {
  const localPermissionsConfig = useLocalPermissionsConfig()
  const setLocalPermissionsConfig = useSetLocalPermissionsConfig()

  if (localPermissionsConfig === undefined) {
    throw new Error('Permissions config is not initialized.')
  }

  const togglePermission: UseLocalPermissionsConfig['togglePermission'] = (category, permission) => {
    setLocalPermissionsConfig(
      produce((draft: PermissionOverrides | undefined) => {
        if (draft === undefined) {
          throw new Error(
            `Permissions config is not initialized but trying to toggle permission [${category}]: "${permission}".`
          )
        }

        const permissions = draft[category]

        const index = permissions.indexOf(permission)
        if (index === -1) {
          permissions.push(permission)
        } else {
          permissions.splice(index, 1)
        }
      })
    )
  }

  return {
    localPermissionsConfig,
    togglePermission,
  }
}
