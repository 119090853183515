import copy from 'clipboard-copy'
import React, { useEffect, useState } from 'react'
import { useTranslation } from 'sierra-client/hooks/use-translation'
import { Button, IconButton, Text, View } from 'sierra-ui/primitives'
import styled, { css } from 'styled-components'

const SecretBox = styled.div<{ $background: boolean }>`
  position: relative;
  display: flex;
  justify-content: center;
  align-items: center;

  height: 10rem;

  border: 1px solid rgba(0, 0, 0, 0.26);
  border-radius: ${p => p.theme.borderRadius.small};

  ${p =>
    p.$background &&
    css`
      background-image: url('https://storage.googleapis.com/sierra-static/images/manage-api-secret-background.svg');
    `}
`

const StyledIconButton = styled(IconButton)`
  position: absolute;
  top: 4px;
  right: 12px;
`

type ApiClientSecretBoxProps = {
  secret: string
  label: string
  description?: string
}
export const ApiClientSecretBox: React.FC<ApiClientSecretBoxProps> = ({ secret, label, description }) => {
  const [visible, setVisible] = useState(false)
  const { t } = useTranslation()

  useEffect(() => {
    setVisible(false)
  }, [secret])

  return (
    <View direction='column'>
      <Text color='LEGACY_DEFAULT_TEXT_COLOR_REPLACE_ASAP' size='regular' bold>
        {label}
      </Text>
      {description !== undefined && (
        <Text color='grey70' size='micro'>
          {description}
        </Text>
      )}
      <SecretBox $background={visible}>
        {visible ? (
          <>
            <Text color='LEGACY_DEFAULT_TEXT_COLOR_REPLACE_ASAP' size='regular'>
              {secret}
            </Text>
            <StyledIconButton
              iconId='duplicate'
              variant='transparent'
              onClick={() => copy(secret)}
              tooltip={t('dictionary.copy-to-clipboard')}
            />
          </>
        ) : (
          <Button variant='secondary' onClick={() => setVisible(true)}>
            {t('manage.api.reveal-secret')}
          </Button>
        )}
      </SecretBox>
    </View>
  )
}
