import { createFileRoute } from '@tanstack/react-router'

import { useEffect } from 'react'

const RouteComponent = function Page(): null {
  useEffect(() => {
    setTimeout(() => {
      throw new Error('test error')
    }, 0)
  }, [])

  return null
}

export const Route = createFileRoute('/debug/error-async')({
  component: RouteComponent as React.FC,
})
