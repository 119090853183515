import { ChangedNodeSelection } from 'sierra-client/editor/version-history//utils/changed-node-selections'
import { nodeAtPath, rangeEncapsulatesNodeEntry } from 'sierra-client/views/v3-author/queries'
import { SerializedDOMRect, rectEncapsulatingAll, serializeDomRect } from 'sierra-domain/utils'
import { SanaEditor } from 'sierra-domain/v3-author'
import { Editor, Element, Path, Range as SlateRange, Text } from 'slate'
import { ReactEditor } from 'slate-react'

/**
 * We often do not want to highlight the outer slate node, as this may strecth to the edges of the page.
 * Instead, we try to identify the content within the node and draw a box around just that.
 */
function slateElementRect(domNode: HTMLElement): SerializedDOMRect {
  const rects = Array.from(domNode.children).flatMap(it => {
    const rect = it.getBoundingClientRect()
    return [serializeDomRect(rect)]
  })

  if (rects.length > 0) return rectEncapsulatingAll(rects)
  else return serializeDomRect(domNode.getBoundingClientRect())
}

/**
 * Get the DOMRects which encapsulate the given range.
 */
export function editorDOMRects(editor: SanaEditor, range: ChangedNodeSelection): SerializedDOMRect[] {
  try {
    const domRange = ReactEditor.toDOMRange(editor, range)

    const [start, end] = SlateRange.edges(range)

    const nodeIterator = Editor.nodes(editor, {
      at: range,
      match: (node, path) => {
        if (Editor.isEditor(node)) return false

        const parentPath = Path.parent(path)
        const parentNode = nodeAtPath(editor.children, parentPath)
        const parentIsEncapsulatedByRange =
          Element.isElement(parentNode) &&
          rangeEncapsulatesNodeEntry(range, [parentNode, parentPath]) &&
          range.type !== 'text'

        if (parentIsEncapsulatedByRange) return false

        const isText = Text.isText(node)
        if (isText) return true

        const nodeIsEncapsulatedByRange =
          rangeEncapsulatesNodeEntry(range, [node, path]) && range.type !== 'text'
        return nodeIsEncapsulatedByRange
      },
      mode: 'highest',
    })

    return Array.from(nodeIterator).flatMap(([node, path]) => {
      const domNode = ReactEditor.toDOMNode(editor, node)

      const isStartNode = Path.equals(path, start.path)
      const isEndNode = Path.equals(path, end.path)

      if (Element.isElement(node)) return [slateElementRect(domNode)]

      if (isStartNode || isEndNode) {
        const nodeRange = document.createRange()
        nodeRange.selectNode(domNode)

        if (isStartNode) nodeRange.setStart(domRange.startContainer, domRange.startOffset)
        if (isEndNode) nodeRange.setEnd(domRange.endContainer, domRange.endOffset)

        return Array.from(nodeRange.getClientRects()).map(serializeDomRect)
      } else {
        return Array.from(domNode.getClientRects()).map(serializeDomRect)
      }
    })
  } catch (e) {
    return []
  }
}
