import { useMemo } from 'react'
import { getNamespacedImage } from 'sierra-client/api/content'
import { useTranslation } from 'sierra-client/hooks/use-translation'
import { TranslationKey } from 'sierra-client/hooks/use-translation/types'
import { IconButton, Text, View } from 'sierra-ui/primitives'
import { token } from 'sierra-ui/theming'
import styled, { css } from 'styled-components'

type LogotypeSize = 'old-logo' | 'square-logo' | 'favicon'

const logotypeToSize: Record<LogotypeSize, number> = {
  'old-logo': 160,
  'square-logo': 56,
  'favicon': 32,
}

const logotypeToRadius: Record<LogotypeSize, number> = {
  'old-logo': 16,
  'square-logo': 16,
  'favicon': 8,
}

const Wrapper = styled(View).attrs({ direction: 'row', grow: true, gap: 'none', alignItems: 'stretch' })`
  overflow: hidden;

  border-radius: 24px;
  border: 1px solid ${token('form/border/1')};

  flex: 1;
`

const LeftContainer = styled(View).attrs({
  direction: 'column',
  justifyContent: 'center',
  alignItems: 'center',
  grow: true,
})`
  position: relative;
  min-width: 250px;
  max-width: 250px;
  background-color: ${token('surface/soft')};

  &::before {
    position: absolute;
    content: '';
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-size: 12%;
    background-repeat: repeat;
    background-image: url('/static/images/ui/checker.png');
    opacity: 0.035;
  }
`

const Logo = styled.img<{ $size: LogotypeSize }>`
  width: ${p => logotypeToSize[p.$size]}px;
  height: ${p => (p.$size === 'old-logo' ? 50 : logotypeToSize[p.$size])}px;
  border-radius: ${p => logotypeToRadius[p.$size]}px;
  object-fit: contain;

  ${p =>
    p.$size === 'old-logo' &&
    css`
      object-fit: contain;
    `}

  z-index: 1;
`

const EmptyLogo = styled.span<{ $size: LogotypeSize }>`
  width: ${p => logotypeToSize[p.$size]}px;
  height: ${p => (p.$size === 'old-logo' ? 50 : logotypeToSize[p.$size])}px;

  border-radius: ${p => logotypeToRadius[p.$size]}px;
  border: 2px dashed ${token('form/border/2')};

  z-index: 1;
`

const Delete = styled(IconButton).attrs({ iconId: 'trash-can', size: 'default', variant: 'transparent' })`
  position: absolute;
  right: 7px;
  bottom: 7px;
`

const RightContainer = styled(View).attrs({
  padding: '24 32',
  direction: 'column',
  justifyContent: 'space-between',
  alignItems: 'flex-start',
  gap: '24',
  grow: true,
})``

const Headline = styled(View).attrs({
  direction: 'row',
  alignItems: 'center',
  gap: '6',
})``

const Description = styled(View).attrs({ direction: 'column', alignItems: 'flex-start', gap: '4' })``

export const Logotype: React.FC<{
  image: string | null
  size: LogotypeSize
  headline: TranslationKey
  description: TranslationKey
  helpText: JSX.Element
  onDelete: () => void
  info?: JSX.Element
  action: JSX.Element
}> = ({ image, size, headline, description, helpText, info, action, onDelete }) => {
  const { t } = useTranslation()

  const src = useMemo(
    () =>
      image === null
        ? null
        : getNamespacedImage({ type: 'organization-settings' }, image, 'image', {
            width: logotypeToSize[size],
          }),
    [image, size]
  )

  return (
    <Wrapper>
      <LeftContainer>
        {src === null ? <EmptyLogo $size={size} /> : <Logo $size={size} src={src} />}
        {src !== null && <Delete onClick={onDelete} />}
      </LeftContainer>
      <RightContainer>
        <Description>
          <Headline>
            <Text size='small' bold color='foreground/primary'>
              {t(headline)}
            </Text>
            {info}
          </Headline>
          <Text size='small' color='foreground/secondary'>
            {t(description)}
          </Text>
          {helpText}
        </Description>

        {action}
      </RightContainer>
    </Wrapper>
  )
}
