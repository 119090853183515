import { FilterPopover } from 'sierra-client/features/insights/display-widgets/filter-popover'
import {
  getMeasuresFromWidget,
  getViewFromWidget,
} from 'sierra-client/features/insights/display-widgets/utils'
import { useInsightsViewsFromMeasures } from 'sierra-client/features/insights/hooks/use-insights-views'
import { useTranslation } from 'sierra-client/hooks/use-translation'
import { Widget } from 'sierra-domain/api/insights'
import { getNumberOfFilters } from 'sierra-domain/filter/datatype/filter'
import { Text, View } from 'sierra-ui/primitives'

export const WidgetSubtitle: React.FC<{ widget: Widget }> = ({ widget }) => {
  const { t } = useTranslation()
  const { data: viewData } = useInsightsViewsFromMeasures(
    getMeasuresFromWidget(widget),
    getViewFromWidget(widget)
  )
  const numberOfFilters = widget.filter !== undefined ? getNumberOfFilters(widget.filter) : 0

  if (numberOfFilters === 0 || widget.filter === undefined || viewData?.availableFilters === undefined) {
    return (
      <Text size='small' color='foreground/muted' bold capitalize='first'>
        {numberOfFilters !== 0
          ? t('manage.insights.filters-count', { count: numberOfFilters })
          : t('admin.analytics.all-learners')}
      </Text>
    )
  }

  return (
    <View>
      <FilterPopover
        filter={widget.filter}
        domainReps={viewData.availableFilters}
        renderTrigger={() => (
          <Text size='small' color='foreground/muted' capitalize='first'>
            {numberOfFilters !== 0
              ? t('manage.insights.filters-count', { count: numberOfFilters })
              : t('admin.analytics.all-learners')}
          </Text>
        )}
      />
    </View>
  )
}
