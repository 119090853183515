import { UseQueryResult } from '@tanstack/react-query'
import { graphql } from 'sierra-client/api/graphql/gql'
import { ExercisesQuery } from 'sierra-client/api/graphql/gql/graphql'
import { useGraphQuery } from 'sierra-client/api/hooks/use-graphql-query'
import { CourseId } from 'sierra-domain/api/nano-id'

const exercisesQuery = graphql(`
  query Exercises($courseId: CourseId!) {
    homeworks(courseId: $courseId) {
      id
      cardId
      title
      courseId
      submissionsLimit
      file {
        indexInCourse
      }
    }
  }
`)
export const useExercises = (courseId: CourseId): UseQueryResult<ExercisesQuery, unknown> => {
  return useGraphQuery({ document: exercisesQuery }, { courseId })
}
