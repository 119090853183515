import React from 'react'
import { useTranslation } from 'sierra-client/hooks/use-translation'
import { labelToString } from 'sierra-client/lib/filter/components/common'
import { CategoryHeadline } from 'sierra-client/views/manage/components/user-attributes/flows/components/layout'
import { useUserAttributesDraft } from 'sierra-client/views/manage/components/user-attributes/flows/user-attribute-settings/hooks/use-user-attributes-draft'
import { EditDomainChoicesInput } from 'sierra-client/views/manage/components/user-attributes/flows/user-attribute-settings/panels/domain-renderers/choices'
import { EditDomainTypeOfInput } from 'sierra-client/views/manage/components/user-attributes/flows/user-attribute-settings/panels/domain-renderers/type-of'
import { EditDomainWithParentChoicesInput } from 'sierra-client/views/manage/components/user-attributes/flows/user-attribute-settings/panels/domain-renderers/with-parent-choices'
import { useInvitationDomains } from 'sierra-client/views/manage/components/user-attributes/hooks/use-invitation-domains'
import {
  AttributeRow,
  AttributeRowList,
  CommonInputSwitch,
  CustomAttributeRow,
} from 'sierra-client/views/manage/components/user-attributes/renderers/common'
import { UserCustomAttributeDomainRep } from 'sierra-domain/user-attributes/user-invitation-domain-rep'
import { Spacer } from 'sierra-ui/primitives'

const EditDomainInputSwitch: React.FC<{
  domainRep: UserCustomAttributeDomainRep
}> = ({ domainRep }) => (
  <CommonInputSwitch
    domainRep={domainRep}
    ChoicesRenderer={EditDomainChoicesInput}
    TypeOfRenderer={EditDomainTypeOfInput}
    WithParentChoicesRenderer={EditDomainWithParentChoicesInput}
  />
)

const ManagerRow: React.FC = () => {
  const { managerDomain } = useInvitationDomains()
  const { dynamicT } = useTranslation()

  if (managerDomain === undefined) {
    return <></>
  }

  return (
    <AttributeRow label={labelToString(managerDomain.label, dynamicT)}>
      <EditDomainChoicesInput domainRep={managerDomain} />
    </AttributeRow>
  )
}

export const EditCustomAttributeSection: React.FC = () => {
  const { customAttributeDomains, managerDomain } = useInvitationDomains()
  const { getAttributeErrors } = useUserAttributesDraft()
  const { t } = useTranslation()

  return (
    <>
      <AttributeRowList>
        {managerDomain !== undefined && (
          <>
            <CategoryHeadline>{t('manage.default-attributes')}</CategoryHeadline>
            <ManagerRow />
          </>
        )}
      </AttributeRowList>
      {customAttributeDomains.length > 0 && (
        <>
          <Spacer size='xsmall' />
          <AttributeRowList>
            <CategoryHeadline>{t('manage.custom-attributes')}</CategoryHeadline>
            {customAttributeDomains.map(domainRep => {
              return (
                <CustomAttributeRow
                  InputSwitch={EditDomainInputSwitch}
                  key={domainRep.ref.key}
                  domainRep={domainRep}
                  showWarning={getAttributeErrors(domainRep.ref)}
                />
              )
            })}
          </AttributeRowList>
        </>
      )}
    </>
  )
}
