import { UseMutateFunction, useMutation } from '@tanstack/react-query'
import { queryClient } from 'sierra-client/api/query-client'
import { getCachedQueryKey, typedPost, useCachedQuery } from 'sierra-client/state/api'
import {
  GetMicrosoftTeamsConfigResponse,
  SetMicrosoftTeamsConfigRequest,
} from 'sierra-domain/api/notification-channels'
import { XRealtimeGetMicrosoftTeamsConfig, XRealtimeSetMicrosoftTeamsConfig } from 'sierra-domain/routes'

const PLACEHOLDER_CONFIG = {
  enabled: false,
  microsoftTenantId: null,
  installationCount: 0,
}

export const useMSTeamsInfo = (): {
  config: GetMicrosoftTeamsConfigResponse
  setConfig: UseMutateFunction<
    null,
    Error,
    {
      enable: boolean | null
      microsoftTenantId?: string | null | undefined
    },
    unknown
  >
  isPending: boolean
} => {
  const { data } = useCachedQuery(XRealtimeGetMicrosoftTeamsConfig, {})

  const mutation = useMutation({
    mutationFn: (data: SetMicrosoftTeamsConfigRequest) => typedPost(XRealtimeSetMicrosoftTeamsConfig, data),
    onMutate: async newConfig => {
      const queryKey = getCachedQueryKey(XRealtimeGetMicrosoftTeamsConfig, {})

      await queryClient.cancelQueries({ queryKey })

      const previousConfig = queryClient.getQueryData([queryKey])

      queryClient.setQueryData(queryKey, newConfig)

      return { previousConfig }
    },
    onSettled: async () => {
      const queryKey = getCachedQueryKey(XRealtimeGetMicrosoftTeamsConfig, {})
      await queryClient.invalidateQueries({ queryKey })
    },
  })

  const config = data ?? PLACEHOLDER_CONFIG

  return {
    config,
    setConfig: mutation.mutate,
    isPending: mutation.isPending,
  }
}
