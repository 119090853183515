import { useEffect, useState } from 'react'
import { StrategicErrorBoundary } from 'sierra-client/error/strategic-error-boundary'
import { FCC } from 'sierra-client/types'

import _ from 'lodash'
import React from 'react'
import {
  selectFlexibleContentFile,
  selectNodeCollaborators,
} from 'sierra-client/state/flexible-content/selectors'
import { useSelector, useStore } from 'sierra-client/state/hooks'
import { CreateCardError } from 'sierra-client/views/flexible-content/create-card-error'
import {
  useCreatePageContext,
  useCreatePageNodeIdContext,
} from 'sierra-client/views/flexible-content/create-page-context'
import { getEditorErrorMeta } from 'sierra-client/views/flexible-content/editor/editor-error-meta'
import { usePolarisContextIfAvailable } from 'sierra-client/views/flexible-content/polaris-editor-provider/polaris-editor-provider'

function getErrorText(event: ErrorEvent | PromiseRejectionEvent): string {
  const message = 'message' in event ? event.message : ''
  const reasonWithMessage: unknown = _.get(event, ['reason', 'message'])

  return `${message} ${JSON.stringify(reasonWithMessage)} ${JSON.stringify(event)}`
}

function isSlateYjsError(event: ErrorEvent | PromiseRejectionEvent): boolean {
  const errorText = getErrorText(event)
  const yjsErrors = ['inside sharedRoot', 'yText', 'already connected', 'yOffset'] as const
  return yjsErrors.some(error => errorText.includes(error))
}

function useDetectSlateYjsError(): Error | undefined {
  const [error, setError] = useState<Error | undefined>(undefined)
  useEffect(() => {
    function onError(event: ErrorEvent | PromiseRejectionEvent): void {
      if (isSlateYjsError(event)) {
        setError(new Error(`slate-yjs error ${getErrorText(event)}`, { cause: event }))
      }
    }

    function onUnhandledRejection(event: PromiseRejectionEvent): void {
      if (isSlateYjsError(event)) {
        setError(new Error(`slate-yjs error ${getErrorText(event)}`, { cause: event }))
      }
    }

    window.addEventListener('error', onError)
    window.addEventListener('unhandledrejection', onUnhandledRejection)
    return () => {
      window.removeEventListener('error', onError)
      window.removeEventListener('unhandledrejection', onUnhandledRejection)
    }
  }, [])

  return error
}

/**
 * Errors in slate-yjs are extremely serious as they can lead to data loss and corruption.
 * We therefore want to catch them and bring them into the regular react tree error flow.
 */
const RethrowSlateYjsErrors: React.FC = () => {
  const slateYjsError = useDetectSlateYjsError()

  if (slateYjsError !== undefined) {
    throw slateYjsError
  }

  return null
}

export const CreatePageErrorBoundary: FCC = ({ children }) => {
  const { createContentId } = useCreatePageContext()
  const { nodeId } = useCreatePageNodeIdContext()

  const file = useSelector(state => selectFlexibleContentFile(state, createContentId, nodeId))
  const { editor } = usePolarisContextIfAvailable() ?? {}
  const store = useStore()

  return (
    <StrategicErrorBoundary
      id='editor'
      strategies={['remount']}
      Fallback={CreateCardError}
      resetKeys={[nodeId]}
      disableInAutomatedTests={true}
      beforeSend={() => {
        return {
          fileType: file?.type,
          file: `=> ${JSON.stringify(file)}`,

          numberOfCollaborators:
            nodeId !== undefined
              ? `${selectNodeCollaborators(store.getState(), nodeId).length}`
              : 'no file id',

          ...(editor?.type === 'available' ? getEditorErrorMeta(editor.value) : {}),
        }
      }}
    >
      <RethrowSlateYjsErrors />
      {children}
    </StrategicErrorBoundary>
  )
}
