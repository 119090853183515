import React from 'react'
import { RouterLink } from 'sierra-client/components/common/link'
import { ActionModal } from 'sierra-client/components/common/modals/action-modal'
import { percentage } from 'sierra-client/core/format'
import { useHasContentKindPermission } from 'sierra-client/hooks/use-permissions'
import { usePost } from 'sierra-client/hooks/use-post'
import { useToggle } from 'sierra-client/hooks/use-toggle'
import { useTranslation } from 'sierra-client/hooks/use-translation'
import { getGlobalRouter } from 'sierra-client/router'
import { continueUrl } from 'sierra-client/views/workspace/utils/urls'
import { isGradeFailed } from 'sierra-domain/api/homework'
import { LearnerContent, LinkLearnerContent } from 'sierra-domain/api/learn'
import { NanoId12 } from 'sierra-domain/api/nano-id'
import { XRealtimeUserResetCourse } from 'sierra-domain/routes'
import { assertNever, isDefined } from 'sierra-domain/utils'
import { IconId, MenuButton, MenuItem } from 'sierra-ui/components'
import { Button, ButtonProps } from 'sierra-ui/primitives'
import { ControlledOrNotControlled, narrowDotSeparator } from 'sierra-ui/utils'

export const LinkButton: React.FC<{
  href: string
  text: string
  icon?: IconId
  variant?: ButtonProps['variant']
  openInNewTab?: boolean
}> = ({ href, text, icon, variant = 'secondary', openInNewTab = false }) => {
  return (
    <RouterLink href={href} openInNewTab={openInNewTab}>
      <Button variant={variant} icon={icon}>
        {text}
      </Button>
    </RouterLink>
  )
}

type ActionMenuItem = MenuItem<'start-over' | 'continue'>

export const ProgressButton: React.FC<
  {
    progress: number | undefined
    href: string
    includeSecondary?: boolean
    onSecondaryClick?: () => Promise<void>
    onClick?: () => void
    isExternal?: boolean
  } & ControlledOrNotControlled
> = ({
  progress,
  href,
  includeSecondary = false,
  onSecondaryClick,
  onClick,
  isOpen,
  onOpenChange,
  isExternal = false,
}) => {
  const { t } = useTranslation()
  let button
  const [startOverOpen, { on: openStartOver, off: closeStartOver }] = useToggle()

  const onClickAndRoute = async (): Promise<void> => {
    onClick?.()
    if (isExternal) {
      window.open(href, '_blank', 'noopener,noreferrer')
    } else {
      await getGlobalRouter().navigate({ to: href })
    }
  }

  const onSecondaryClickAndRoute = async (): Promise<void> => {
    await onSecondaryClick?.()
    if (isExternal) {
      window.open(href, '_blank', 'noopener,noreferrer')
    } else {
      await getGlobalRouter().navigate({ to: href })
    }
    await getGlobalRouter().navigate({ to: href })
  }

  const menuItemStartOver = {
    id: 'start-over',
    type: 'label',
    label: t('dictionary.start-over'),
    description: t('manage.reset-progress-and-view'),
    onClick: openStartOver,
  } satisfies ActionMenuItem

  const controlled = isOpen !== undefined ? { isOpen, onOpenChange } : {}

  if (progress !== undefined && progress > 0.0 && progress < 1.0) {
    // in progress: continue
    button = includeSecondary ? (
      <MenuButton
        {...controlled}
        onSelect={item => {
          switch (item.id) {
            case 'continue':
              void onClickAndRoute()
              return
            case 'start-over':
              return openStartOver()
          }
        }}
        variant='secondary'
        menuItems={[
          {
            id: 'continue',
            type: 'label',
            label: t('dictionary.continue'),
            onClick: onClickAndRoute,
          },
          menuItemStartOver,
        ]}
        onPrimaryClick={onClickAndRoute}
        menuLabel={t('dictionary.more-options')}
      >
        {t('dictionary.continue')} {narrowDotSeparator} {percentage(progress)}
      </MenuButton>
    ) : (
      <RouterLink href={href} openInNewTab={isExternal}>
        <Button variant='secondary' onClick={onClick}>
          {t('dictionary.continue')}
        </Button>
      </RouterLink>
    )
  } else if (progress !== undefined && progress === 1.0) {
    // completed: review
    button = includeSecondary ? (
      <MenuButton
        {...controlled}
        onSelect={item => {
          switch (item.id) {
            case 'revisit':
              void onClickAndRoute()
              return
            case 'start-over':
              return openStartOver()
          }
        }}
        variant='secondary'
        menuItems={[
          {
            id: 'revisit',
            type: 'label',
            label: t('dictionary.revisit'),
            description: t('manage.view-with-progress-kept'),
          },
          menuItemStartOver,
        ]}
        onPrimaryClick={onClickAndRoute}
        menuLabel={t('dictionary.more-options')}
      >
        {t('dictionary.revisit')}
      </MenuButton>
    ) : (
      <RouterLink href={href} openInNewTab={isExternal}>
        <Button variant='secondary' onClick={onClick}>
          {t('dictionary.revisit')}
        </Button>
      </RouterLink>
    )
  } else {
    button = (
      <RouterLink href={href} openInNewTab={isExternal}>
        <Button variant='secondary' onClick={onClick}>
          {t('dictionary.start')}
        </Button>
      </RouterLink>
    )
  }

  return (
    <>
      <ActionModal
        open={startOverOpen}
        onClose={closeStartOver}
        primaryAction={onSecondaryClickAndRoute}
        title={t('manage.start-over')}
        primaryActionLabel={t('dictionary.start-over')}
        deleteAction={false}
      >
        {t('manage.start-over-description')}
      </ActionModal>
      {button}
    </>
  )
}

export const ActionButton: React.FC<
  {
    row: LearnerContent
    showReset?: boolean
  } & ControlledOrNotControlled
> = ({ row, showReset = false, isOpen, onOpenChange }) => {
  const { t } = useTranslation()
  const { postWithUserErrorException } = usePost()
  const controlled = isOpen !== undefined ? { isOpen, onOpenChange } : {}
  const isLink = row.type === 'link'

  const resetProgress = async (): Promise<void> => {
    await postWithUserErrorException(XRealtimeUserResetCourse, {
      contentType: 'course',
      contentId: NanoId12.parse(row.id),
    })
  }

  switch (row.type) {
    case 'native:live':
      return (
        <ProgressButton
          {...controlled}
          progress={row.progress}
          href={continueUrl(row)}
          includeSecondary={showReset === true}
          onSecondaryClick={resetProgress}
        />
      )
    case 'native:self-paced': {
      const completed = isDefined(row.completedAt)

      // Only show failed exercise buttons if the course isnt completed
      if (!completed) {
        const firstFailedExercises = row.exercises.filter(exercise => isGradeFailed(exercise.grade)).at(0)
        if (firstFailedExercises?.grade === 'failed') {
          return (
            <LinkButton
              icon='restart'
              text={t('my-library.items.homework.retry')}
              variant='primary'
              href={`/s/${row.id}/file:${firstFailedExercises.fileId}`}
            />
          )
        } else if (firstFailedExercises?.grade === 'failed-with-no-retries') {
          return (
            <LinkButton
              text={t('dictionary.review')}
              variant='secondary'
              href={`/s/${row.id}/file:${firstFailedExercises.fileId}`}
            />
          )
        }
      }

      return (
        <ProgressButton
          {...controlled}
          progress={row.progress}
          href={continueUrl(row)}
          // TODO(course-editions): Support progress reset on course group
          // TODO(program-learner): Support progress reset on program (?)
          includeSecondary={
            showReset === true //TODO: Perhaps do reset and get error back if it has assessments?
          }
          onSecondaryClick={resetProgress}
        />
      )
    }
    case 'native:course-group':
    case 'scorm:course-group':
    case 'linkedin':
    case 'scorm':
    case 'link':
    case 'program':
      return row.progress === 0 ? (
        <LinkButton text={t('dictionary.start')} href={continueUrl(row)} openInNewTab={isLink} />
      ) : (
        <ProgressButton
          {...controlled}
          progress={row.progress}
          href={continueUrl(row)}
          // TODO(course-editions): Support progress reset on course group
          // TODO(program-learner): Support progress reset on program (?)
          includeSecondary={
            row.type !== 'native:course-group' &&
            row.type !== 'scorm:course-group' &&
            row.type !== 'program' &&
            showReset === true //TODO: Perhaps do reset and get error back if it has assessments?
          }
          onSecondaryClick={
            row.type !== 'native:course-group' && row.type !== 'scorm:course-group' && row.type !== 'program'
              ? resetProgress
              : undefined
          }
          isExternal={isLink}
        />
      )

    case 'path':
      return <ProgressButton {...controlled} progress={row.progress} href={continueUrl(row)} />
    case 'homework':
      return <LinkButton text={t('dictionary.view')} href={`/s/${row.courseId}/file:${row.fileId}`} />
    case 'live-session':
      if (row.endedAt !== undefined) {
        return row.hasRecap ? (
          <LinkButton text={t('learner-home.view-summary')} href={`/l/${row.id}`} />
        ) : (
          <LinkButton text={t('dictionary.view')} href={`/l/${row.id}`} />
        )
      } else {
        return <LinkButton text={t('dictionary.join')} href={`/l/${row.id}`} />
      }
    case 'native:event-group':
      return <LinkButton text={t('dictionary.view')} href={`/event/${row.id}`} />
    default:
      assertNever(row)
  }
}

export const LinkActionButton: React.FC<
  {
    row: LinkLearnerContent
    showReset?: boolean
    isOpen: boolean
    onOpenChange: (newIsOpen: boolean) => void
  } & ControlledOrNotControlled
> = ({ row, showReset = false, isOpen, onOpenChange }) => {
  const allowStartOver = useHasContentKindPermission(row.id, 'LEARN_MARK_AS_COMPLETED')
  return (
    <ActionButton
      row={row}
      showReset={showReset && allowStartOver}
      isOpen={isOpen}
      onOpenChange={onOpenChange}
    />
  )
}
