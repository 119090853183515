import { FC, useMemo } from 'react'
import { graphql } from 'sierra-client/api/graphql/gql'
import { convertGQLImage } from 'sierra-client/api/graphql/util/convert-gql-image'
import { useGraphQuery } from 'sierra-client/api/hooks/use-graphql-query'
import { useTranslation } from 'sierra-client/hooks/use-translation'
import { FCC } from 'sierra-client/types'
import {
  CoursePreview,
  ProgramPreview,
} from 'sierra-client/views/manage/certificates/issued-by-selector/previews'
import {
  IssuedByCourse,
  IssuedByProgram,
} from 'sierra-client/views/manage/certificates/issued-by-selector/types'
import { useManageCourses } from 'sierra-client/views/manage/courses/use-manage-courses'
import { CourseId } from 'sierra-domain/api/nano-id'
import { ProgramId } from 'sierra-domain/api/uuid'
import { Button, View } from 'sierra-ui/primitives'
import { token } from 'sierra-ui/theming'
import styled, { css } from 'styled-components'

const ButtonContainer = styled(View)``

const ItemContainer = styled(View).attrs({ padding: 'small', grow: true })<{ $selected: boolean }>`
  background: ${token('surface/default')};
  border-bottom: 1px solid ${token('border/default')};
  position: relative;

  /* Hide unless hovered or selected */
  ${p =>
    p.$selected === false &&
    css`
      ${ButtonContainer} {
        visibility: hidden;
      }
    `}

  &:hover {
    background-color: ${token('surface/soft')};

    ${ButtonContainer} {
      visibility: visible;
    }
  }
`

const SelectableRow: FCC<{ selected: boolean; onSelect: () => void; onDeselect: () => void }> = ({
  children,
  selected,
  onSelect,
  onDeselect,
}) => {
  const { t } = useTranslation()
  return (
    <ItemContainer $selected={selected}>
      {children}
      <ButtonContainer>
        {selected ? (
          <Button variant='secondary' icon='checkmark' decoratorPosition='right' onClick={onDeselect}>
            {t('dictionary.added')}
          </Button>
        ) : (
          <Button onClick={onSelect}>{t('dictionary.add')}</Button>
        )}
      </ButtonContainer>
    </ItemContainer>
  )
}

const Container = styled(View).attrs({ direction: 'column', gap: 'none' })`
  width: 100%;
  cursor: pointer;
`

export const CourseTab: FC<{
  query: string
  selectedIds: Set<IssuedByCourse['id']>
  onSelect: (id: IssuedByCourse['id']) => void
  onDeselect: (id: IssuedByCourse['id']) => void
}> = ({ query, onSelect, onDeselect, selectedIds }) => {
  const allCourses = useManageCourses()
  const filteredContent = useMemo(() => {
    const lowercaseQuery = query.toLocaleLowerCase()
    const filteredContent = allCourses.courses
      .filter(c => c.title.toLocaleLowerCase().includes(lowercaseQuery))
      .map(c => ({ ...c, id: c.courseId }))

    return filteredContent.map<IssuedByCourse>(c => ({
      id: c.courseId,
      title: c.title,
      image: c.image,
      courseId: c.courseId,
      courseKind: c.kind,
    }))
  }, [allCourses.courses, query])

  return (
    <Container>
      {filteredContent.map(content => {
        return (
          <View key={content.id}>
            <SelectableRow
              selected={selectedIds.has(content.courseId)}
              onSelect={() => onSelect(content.courseId)}
              onDeselect={() => onDeselect(content.courseId)}
            >
              <CoursePreview
                {...content}
                assetContext={{ type: 'course', courseId: CourseId.parse(content.courseId) }}
              />
            </SelectableRow>
          </View>
        )
      })}
    </Container>
  )
}

// TODO: Use infinite fetching on scroll
const programsQuery = graphql(`
  query programs($query: String) {
    managedPrograms(query: $query, limit: 100) {
      data {
        id
        name
        description
        image {
          ...ImageFragment
        }
      }
    }
  }
`)

export const ProgramTab: FC<{
  query: string
  selectedIds: Set<IssuedByProgram['id']>
  onSelect: (id: IssuedByProgram['id']) => void
  onDeselect: (id: IssuedByProgram['id']) => void
}> = ({ query, onSelect, onDeselect, selectedIds }) => {
  const programsData = useGraphQuery(
    { document: programsQuery, queryOptions: { refetchOnWindowFocus: false } },
    { query }
  )

  const filteredContent = useMemo(() => {
    const filteredContent = programsData.data?.managedPrograms.data ?? []

    return filteredContent.map<IssuedByProgram>(p => ({
      ...p,
      description: p.description ?? undefined,
      image: convertGQLImage(p.image),
    }))
  }, [programsData.data])

  return (
    <Container>
      {filteredContent.map(content => {
        return (
          <View key={content.id}>
            <SelectableRow
              selected={selectedIds.has(content.id)}
              onSelect={() => onSelect(content.id)}
              onDeselect={() => onDeselect(content.id)}
            >
              <ProgramPreview
                {...content}
                assetContext={{ type: 'program', programId: ProgramId.parse(content.id) }}
              />
            </SelectableRow>
          </View>
        )
      })}
    </Container>
  )
}
