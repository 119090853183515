import { motion } from 'framer-motion'
import {
  headerItemVariants,
  titleVariants,
} from 'sierra-client/features/collapsable-sidebar/animation-config'
import { FCC } from 'sierra-client/types'
import { color } from 'sierra-ui/color'
import { resolveTokenOrColor } from 'sierra-ui/color/token-or-color'
import { Text, View } from 'sierra-ui/primitives'
import { token } from 'sierra-ui/theming'
import styled, { css } from 'styled-components'

export const ItemTitleCSS = css`
  overflow: hidden;
  width: 100%;

  ${Text} {
    white-space: nowrap;
  }
`

export const HeaderItemTitle = styled(View).attrs({
  animated: true,
  variants: titleVariants,
  direction: 'row',
  gap: 'none',
  grow: true,
  justifyContent: 'space-between',
})`
  ${ItemTitleCSS}
`

export const ItemWrapperCSS = css<{ $isSelected: boolean }>`
  overflow: hidden; /* Toggle button + menu will stay but should be cut off */
  border-radius: 10px;
  cursor: pointer;
  height: 36px;
  transition: background-color 0.2s;
  list-style: none;

  ${p =>
    p.$isSelected &&
    css`
      background-color: ${color(resolveTokenOrColor('org/primary', p.theme)).opacity(0.08)};
    `}
  &:hover {
    background-color: ${p => color(resolveTokenOrColor('org/primary', p.theme)).opacity(0.08)};
  }
`

export const HeaderItemWrapper = styled(motion.li).attrs({
  variants: headerItemVariants,
})<{ $isSelected: boolean }>`
  ${ItemWrapperCSS};
  position: relative;
  z-index: 0;
`

const ItemWrapper = styled(View).attrs({
  animated: true,
  variants: headerItemVariants,
  as: 'button',
})<{ $isSelected: boolean }>`
  width: 100%;
  display: flex;
  flex-direction: row;
  align-items: center;
  gap: 10px;
  padding: 8px 10px;
  background-color: transparent;

  ${ItemWrapperCSS};

  color: ${token('foreground/secondary')};

  &:hover {
    color: ${token('foreground/primary')};
  }
`

export const ItemButton: FCC<{
  'isSelected': boolean
  'onClick': () => void
  'data-testid'?: string
  'id'?: string
}> = ({ children, isSelected, onClick, ...props }) => {
  return (
    <ItemWrapper $isSelected={isSelected} onClick={onClick} {...props}>
      {children}
    </ItemWrapper>
  )
}
