import { GridArea } from 'sierra-client/features/program/components/grid'
import { ProgramSection } from 'sierra-domain/api/manage'
import { Text } from 'sierra-ui/primitives'
import { token } from 'sierra-ui/theming'
import { FCC } from 'sierra-ui/types'
import styled from 'styled-components'

const SectionContainer = styled.div`
  display: grid;
  grid-template-columns: 16px 1fr;
  grid-template-rows: min-content 1fr;
  grid-template-areas: 'line meta' 'steps steps';
  gap: 8px 16px;
  padding: 16px;
  background: ${token('surface/soft')};
  border-radius: 16px;
`
const StepContainer = styled.div`
  grid-area: steps;
  display: flex;
  flex-direction: column;
  gap: 32px;
`

type SectionProps = {
  section: ProgramSection
}

export const Section: FCC<SectionProps> = ({ children, section }) => {
  return (
    <SectionContainer>
      <GridArea area='meta'>
        <Text bold color='foreground/secondary'>
          {section.title}
        </Text>
        <Text color='foreground/muted'>{section.description}</Text>
      </GridArea>

      <StepContainer>{children}</StepContainer>
    </SectionContainer>
  )
}
