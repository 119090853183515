import { useTokenValue } from 'sierra-ui/theming'

export const TablePreviewSVG: React.FC = () => {
  const getToken = useTokenValue()
  return (
    <svg width='100%' height='100%' viewBox='0 0 211 206' fill='none' xmlns='http://www.w3.org/2000/svg'>
      <g clipPath='url(#clip0_6890_11663)'>
        <g clipPath='url(#clip1_6890_11663)'>
          <mask id='path-5-inside-1_6890_11663' fill={getToken('surface/default')}>
            <path d='M13 43H198V219H13V43Z' />
          </mask>
          <g clipPath='url(#clip2_6890_11663)'>
            <mask id='path-7-inside-2_6890_11663' fill={getToken('surface/default')}>
              <path d='M13 43H198V65H13V43Z' />
            </mask>
            <rect width='46.25' height='22' transform='translate(13 43)' fill={getToken('surface/default')} />
            <rect
              x='17'
              y='51'
              width='38'
              height='6'
              rx='3'
              fill={getToken('foreground/primary')}
              fillOpacity='0.3'
            />
            <rect
              width='46.25'
              height='22'
              transform='translate(59.25 43)'
              fill={getToken('surface/default')}
            />
            <rect
              x='63.25'
              y='51'
              width='38'
              height='6'
              rx='3'
              fill={getToken('foreground/primary')}
              fillOpacity='0.05'
            />
            <rect
              width='46.25'
              height='22'
              transform='translate(105.5 43)'
              fill={getToken('surface/default')}
            />
            <rect
              x='109.5'
              y='51'
              width='38'
              height='6'
              rx='3'
              fill={getToken('foreground/primary')}
              fillOpacity='0.05'
            />
            <rect
              width='46.25'
              height='22'
              transform='translate(151.75 43)'
              fill={getToken('surface/default')}
            />
            <rect
              x='155.75'
              y='51'
              width='38'
              height='6'
              rx='3'
              fill={getToken('foreground/primary')}
              fillOpacity='0.05'
            />
          </g>
          <path
            d='M198 64H13V66H198V64Z'
            fill={getToken('foreground/primary')}
            fillOpacity='0.05'
            mask='url(#path-7-inside-2_6890_11663)'
          />
          <g clipPath='url(#clip3_6890_11663)'>
            <mask id='path-13-inside-3_6890_11663' fill={getToken('surface/default')}>
              <path d='M13 65H198V87H13V65Z' />
            </mask>
            <rect width='46.25' height='22' transform='translate(13 65)' fill={getToken('surface/default')} />
            <rect
              x='17'
              y='73'
              width='38'
              height='6'
              rx='3'
              fill={getToken('foreground/primary')}
              fillOpacity='0.3'
            />
            <rect
              width='46.25'
              height='22'
              transform='translate(59.25 65)'
              fill={getToken('surface/default')}
            />
            <rect
              x='63.25'
              y='73'
              width='38'
              height='6'
              rx='3'
              fill={getToken('foreground/primary')}
              fillOpacity='0.05'
            />
            <rect
              width='46.25'
              height='22'
              transform='translate(105.5 65)'
              fill={getToken('surface/default')}
            />
            <rect
              x='109.5'
              y='73'
              width='38'
              height='6'
              rx='3'
              fill={getToken('foreground/primary')}
              fillOpacity='0.05'
            />
            <rect
              width='46.25'
              height='22'
              transform='translate(151.75 65)'
              fill={getToken('surface/default')}
            />
            <rect
              x='155.75'
              y='73'
              width='38'
              height='6'
              rx='3'
              fill={getToken('foreground/primary')}
              fillOpacity='0.05'
            />
          </g>
          <path
            d='M198 86H13V88H198V86Z'
            fill={getToken('foreground/primary')}
            fillOpacity='0.05'
            mask='url(#path-13-inside-3_6890_11663)'
          />
          <g clipPath='url(#clip4_6890_11663)'>
            <mask id='path-19-inside-4_6890_11663' fill={getToken('surface/default')}>
              <path d='M13 87H198V109H13V87Z' />
            </mask>
            <rect width='46.25' height='22' transform='translate(13 87)' fill={getToken('surface/default')} />
            <rect
              x='17'
              y='95'
              width='38'
              height='6'
              rx='3'
              fill={getToken('foreground/primary')}
              fillOpacity='0.3'
            />
            <rect
              width='46.25'
              height='22'
              transform='translate(59.25 87)'
              fill={getToken('surface/default')}
            />
            <rect
              x='63.25'
              y='95'
              width='38'
              height='6'
              rx='3'
              fill={getToken('foreground/primary')}
              fillOpacity='0.05'
            />
            <rect
              width='46.25'
              height='22'
              transform='translate(105.5 87)'
              fill={getToken('surface/default')}
            />
            <rect
              x='109.5'
              y='95'
              width='38'
              height='6'
              rx='3'
              fill={getToken('foreground/primary')}
              fillOpacity='0.05'
            />
            <rect
              width='46.25'
              height='22'
              transform='translate(151.75 87)'
              fill={getToken('surface/default')}
            />
            <rect
              x='155.75'
              y='95'
              width='38'
              height='6'
              rx='3'
              fill={getToken('foreground/primary')}
              fillOpacity='0.05'
            />
          </g>
          <path
            d='M198 108H13V110H198V108Z'
            fill={getToken('foreground/primary')}
            fillOpacity='0.05'
            mask='url(#path-19-inside-4_6890_11663)'
          />
          <g clipPath='url(#clip5_6890_11663)'>
            <mask id='path-25-inside-5_6890_11663' fill={getToken('surface/default')}>
              <path d='M13 109H198V131H13V109Z' />
            </mask>
            <rect
              width='46.25'
              height='22'
              transform='translate(13 109)'
              fill={getToken('surface/default')}
            />
            <rect
              x='17'
              y='117'
              width='38'
              height='6'
              rx='3'
              fill={getToken('foreground/primary')}
              fillOpacity='0.3'
            />
            <rect
              width='46.25'
              height='22'
              transform='translate(59.25 109)'
              fill={getToken('surface/default')}
            />
            <rect
              x='63.25'
              y='117'
              width='38'
              height='6'
              rx='3'
              fill={getToken('foreground/primary')}
              fillOpacity='0.05'
            />
            <rect
              width='46.25'
              height='22'
              transform='translate(105.5 109)'
              fill={getToken('surface/default')}
            />
            <rect
              x='109.5'
              y='117'
              width='38'
              height='6'
              rx='3'
              fill={getToken('foreground/primary')}
              fillOpacity='0.05'
            />
            <rect
              width='46.25'
              height='22'
              transform='translate(151.75 109)'
              fill={getToken('surface/default')}
            />
            <rect
              x='155.75'
              y='117'
              width='38'
              height='6'
              rx='3'
              fill={getToken('foreground/primary')}
              fillOpacity='0.05'
            />
          </g>
          <path
            d='M198 130H13V132H198V130Z'
            fill={getToken('foreground/primary')}
            fillOpacity='0.05'
            mask='url(#path-25-inside-5_6890_11663)'
          />
          <g clipPath='url(#clip6_6890_11663)'>
            <mask id='path-31-inside-6_6890_11663' fill={getToken('surface/default')}>
              <path d='M13 131H198V153H13V131Z' />
            </mask>
            <rect
              width='46.25'
              height='22'
              transform='translate(13 131)'
              fill={getToken('surface/default')}
            />
            <rect
              x='17'
              y='139'
              width='38'
              height='6'
              rx='3'
              fill={getToken('foreground/primary')}
              fillOpacity='0.3'
            />
            <rect
              width='46.25'
              height='22'
              transform='translate(59.25 131)'
              fill={getToken('surface/default')}
            />
            <rect
              x='63.25'
              y='139'
              width='38'
              height='6'
              rx='3'
              fill={getToken('foreground/primary')}
              fillOpacity='0.05'
            />
            <rect
              width='46.25'
              height='22'
              transform='translate(105.5 131)'
              fill={getToken('surface/default')}
            />
            <rect
              x='109.5'
              y='139'
              width='38'
              height='6'
              rx='3'
              fill={getToken('foreground/primary')}
              fillOpacity='0.05'
            />
            <rect
              width='46.25'
              height='22'
              transform='translate(151.75 131)'
              fill={getToken('surface/default')}
            />
            <rect
              x='155.75'
              y='139'
              width='38'
              height='6'
              rx='3'
              fill={getToken('foreground/primary')}
              fillOpacity='0.05'
            />
          </g>
          <path
            d='M198 152H13V154H198V152Z'
            fill={getToken('foreground/primary')}
            fillOpacity='0.05'
            mask='url(#path-31-inside-6_6890_11663)'
          />
          <g clipPath='url(#clip7_6890_11663)'>
            <mask id='path-37-inside-7_6890_11663' fill={getToken('surface/default')}>
              <path d='M13 153H198V175H13V153Z' />
            </mask>
            <rect
              width='46.25'
              height='22'
              transform='translate(13 153)'
              fill={getToken('surface/default')}
            />
            <rect
              x='17'
              y='161'
              width='38'
              height='6'
              rx='3'
              fill={getToken('foreground/primary')}
              fillOpacity='0.3'
            />
            <rect
              width='46.25'
              height='22'
              transform='translate(59.25 153)'
              fill={getToken('surface/default')}
            />
            <rect
              x='63.25'
              y='161'
              width='38'
              height='6'
              rx='3'
              fill={getToken('foreground/primary')}
              fillOpacity='0.05'
            />
            <rect
              width='46.25'
              height='22'
              transform='translate(105.5 153)'
              fill={getToken('surface/default')}
            />
            <rect
              x='109.5'
              y='161'
              width='38'
              height='6'
              rx='3'
              fill={getToken('foreground/primary')}
              fillOpacity='0.05'
            />
            <rect
              width='46.25'
              height='22'
              transform='translate(151.75 153)'
              fill={getToken('surface/default')}
            />
            <rect
              x='155.75'
              y='161'
              width='38'
              height='6'
              rx='3'
              fill={getToken('foreground/primary')}
              fillOpacity='0.05'
            />
          </g>
          <path
            d='M198 174H13V176H198V174Z'
            fill={getToken('foreground/primary')}
            fillOpacity='0.05'
            mask='url(#path-37-inside-7_6890_11663)'
          />
          <g clipPath='url(#clip8_6890_11663)'>
            <mask id='path-43-inside-8_6890_11663' fill={getToken('surface/default')}>
              <path d='M13 175H198V197H13V175Z' />
            </mask>
            <rect
              width='46.25'
              height='22'
              transform='translate(13 175)'
              fill={getToken('surface/default')}
            />
            <rect
              x='17'
              y='183'
              width='38'
              height='6'
              rx='3'
              fill={getToken('foreground/primary')}
              fillOpacity='0.3'
            />
            <rect
              width='46.25'
              height='22'
              transform='translate(59.25 175)'
              fill={getToken('surface/default')}
            />
            <rect
              x='63.25'
              y='183'
              width='38'
              height='6'
              rx='3'
              fill={getToken('foreground/primary')}
              fillOpacity='0.05'
            />
            <rect
              width='46.25'
              height='22'
              transform='translate(105.5 175)'
              fill={getToken('surface/default')}
            />
            <rect
              x='109.5'
              y='183'
              width='38'
              height='6'
              rx='3'
              fill={getToken('foreground/primary')}
              fillOpacity='0.05'
            />
            <rect
              width='46.25'
              height='22'
              transform='translate(151.75 175)'
              fill={getToken('surface/default')}
            />
            <rect
              x='155.75'
              y='183'
              width='38'
              height='6'
              rx='3'
              fill={getToken('foreground/primary')}
              fillOpacity='0.05'
            />
          </g>
          <path
            d='M198 196H13V198H198V196Z'
            fill={getToken('foreground/primary')}
            fillOpacity='0.05'
            mask='url(#path-43-inside-8_6890_11663)'
          />
          <g clipPath='url(#clip9_6890_11663)'>
            <mask id='path-49-inside-9_6890_11663' fill={getToken('surface/default')}>
              <path d='M13 197H198V219H13V197Z' />
            </mask>
            <rect
              width='46.25'
              height='22'
              transform='translate(13 197)'
              fill={getToken('surface/default')}
            />
            <rect
              width='46.25'
              height='22'
              transform='translate(59.25 197)'
              fill={getToken('surface/default')}
            />
            <rect
              width='46.25'
              height='22'
              transform='translate(105.5 197)'
              fill={getToken('surface/default')}
            />
            <rect
              width='46.25'
              height='22'
              transform='translate(151.75 197)'
              fill={getToken('surface/default')}
            />
          </g>
          <path
            d='M198 218H13V220H198V218Z'
            fill={getToken('foreground/primary')}
            fillOpacity='0.05'
            mask='url(#path-49-inside-9_6890_11663)'
          />
        </g>
        <path
          d='M13 44H198V42H13V44Z'
          fill={getToken('foreground/primary')}
          fillOpacity='0.05'
          mask='url(#path-5-inside-1_6890_11663)'
        />
        <rect x='1' y='125' width='209' height='80' fill='url(#paint0_linear_6890_11663)' />
      </g>

      <defs>
        <linearGradient
          id='paint0_linear_6890_11663'
          x1='105.5'
          y1='125'
          x2='105.5'
          y2='205'
          gradientUnits='userSpaceOnUse'
        >
          <stop stopColor='white' stopOpacity='0' />
          <stop offset='1' stopColor='white' stopOpacity='0.8' />
        </linearGradient>
        <clipPath id='clip0_6890_11663'>
          <rect x='1' y='1' width='209' height='204' rx='10' fill={getToken('surface/default')} />
        </clipPath>
        <clipPath id='clip1_6890_11663'>
          <path d='M13 43H198V219H13V43Z' fill={getToken('surface/default')} />
        </clipPath>
        <clipPath id='clip2_6890_11663'>
          <path d='M13 43H198V65H13V43Z' fill={getToken('surface/default')} />
        </clipPath>
        <clipPath id='clip3_6890_11663'>
          <path d='M13 65H198V87H13V65Z' fill={getToken('surface/default')} />
        </clipPath>
        <clipPath id='clip4_6890_11663'>
          <path d='M13 87H198V109H13V87Z' fill={getToken('surface/default')} />
        </clipPath>
        <clipPath id='clip5_6890_11663'>
          <path d='M13 109H198V131H13V109Z' fill={getToken('surface/default')} />
        </clipPath>
        <clipPath id='clip6_6890_11663'>
          <path d='M13 131H198V153H13V131Z' fill={getToken('surface/default')} />
        </clipPath>
        <clipPath id='clip7_6890_11663'>
          <path d='M13 153H198V175H13V153Z' fill={getToken('surface/default')} />
        </clipPath>
        <clipPath id='clip8_6890_11663'>
          <path d='M13 175H198V197H13V175Z' fill={getToken('surface/default')} />
        </clipPath>
        <clipPath id='clip9_6890_11663'>
          <path d='M13 197H198V219H13V197Z' fill={getToken('surface/default')} />
        </clipPath>
      </defs>
    </svg>
  )
}
