import { LiveSessionWithParticipants } from 'sierra-domain/api/admin'
import { CreateContentId, LiveContentId } from 'sierra-domain/api/nano-id'
import { FileId, FolderId, NodeId } from 'sierra-domain/flexible-content/identifiers'
import { File, FlexibleContentJsonData, Folder, Node } from 'sierra-domain/flexible-content/types'
import { LightUser } from 'sierra-domain/user'
import { z } from 'zod'

type ContentLiveSessionMap = Record<LiveContentId, LiveSessionWithParticipants[]>

export type FlexibleContentState = {
  flexibleContent: Record<CreateContentId, FlexibleContentJsonData>
  collaborators: Record<CreateContentId, FlexibleContentCollaborator[]>
  folderLiveSessions: ContentLiveSessionMap
}

export type MoveNodeActionPayload = {
  createContentId: CreateContentId
  nodeId: NodeId
  parentFolderId: FolderId
  destinationId: FolderId
  nextTo?: {
    nodeId: NodeId
    after: boolean
  }
}

export const isFolder = (node: Node | undefined): node is Folder => {
  return node?.type === 'folder'
}

export const isFile = (node: Node | undefined): node is File => {
  return node?.type === 'file'
}

export const isFolderId = (id: NodeId): id is FolderId => {
  return id.startsWith('folder')
}

export const isFileId = (id: NodeId): id is FileId => {
  return id.startsWith('file')
}

const LegacyCreateContentId = z
  .object({ flexibleContentId: LiveContentId })
  .transform(({ flexibleContentId }) => ({ createContentId: flexibleContentId }) as const)

export const FlexibleContentCollaborator = z
  .object({
    clientId: z.number(),
    nodeId: NodeId.optional(),
    user: LightUser,
    isWritingToChatThreadId: z.string().optional(),
  })
  .and(z.union([LegacyCreateContentId, z.object({ createContentId: CreateContentId })]))

export type FlexibleContentCollaborator = z.infer<typeof FlexibleContentCollaborator>
