import { DateTime } from 'luxon'
import React from 'react'
import { useTranslation } from 'sierra-client/hooks/use-translation'
import {
  ClickableLabel,
  StartDateSelectorProps,
} from 'sierra-client/views/manage/programs/enrollment-rules/modals/start-date-selector/common'
import { InputDatePicker, InputGroup } from 'sierra-ui/components'
import { View } from 'sierra-ui/primitives'
import styled from 'styled-components'

const AbsoluteDateWrapper = styled(View).attrs({ gap: '24' })`
  & ${InputGroup} {
    margin-bottom: 0;
  }
`

export const Absolute: React.FC<StartDateSelectorProps & { onClick: () => void }> = ({
  startDate,
  setStartDate,
  disabled,
  onClick,
}) => {
  const { t } = useTranslation()

  const Headline = (
    <ClickableLabel onClick={onClick}>
      {t('manage.programs.enrollment-rules.start-date.specific-date')}
    </ClickableLabel>
  )

  if (startDate.type !== 'absolute') {
    return Headline
  }

  return (
    <AbsoluteDateWrapper>
      {Headline}

      <InputDatePicker
        autoFocus
        value={DateTime.fromISO(startDate.date)}
        disablePastDates
        disabled={disabled}
        onChange={date => {
          if (date !== undefined) {
            const formatted = date.toFormat('yyyy-MM-dd')
            setStartDate({ type: 'absolute', date: formatted })
          }
        }}
      />
    </AbsoluteDateWrapper>
  )
}
