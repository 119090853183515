import { useState } from 'react'
import { useUpdateTeamspacePermissionsMutation } from 'sierra-client/api/hooks/use-teamspace'
import {
  TeamspaceDefaultRoleSelector,
  TeamspaceVisibilitySelector,
} from 'sierra-client/features/teamspace/components/teamspace-permission-selector'
import { teamspaceEditedLogger } from 'sierra-client/features/teamspace/logger'
import { useTranslation } from 'sierra-client/hooks/use-translation'
import { useDispatch } from 'sierra-client/state/hooks'
import { Teamspace, TeamspaceRole, TeamspaceVisibility } from 'sierra-domain/api/teamspace'
import { Button, View } from 'sierra-ui/primitives'
import styled from 'styled-components'

const EditTeamspacePermissionsTabForm = styled.form`
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  min-height: 100%;
  padding-top: 24px;
`

export const EditTeamspacePermissionsTab: React.FC<{
  teamspace: Teamspace
  onClose: () => void
}> = ({ teamspace, onClose }) => {
  const { t } = useTranslation()
  const dispatch = useDispatch()

  const [visibility, setVisibility] = useState<TeamspaceVisibility>(teamspace.visibility)
  const [defaultRole, setDefaultRole] = useState<TeamspaceRole>(teamspace.defaultRole)

  const isOwner = teamspace.effectiveRole === 'owner'
  const isFormDirty = visibility !== teamspace.visibility || defaultRole !== teamspace.defaultRole
  const enableSubmitButton = isOwner && isFormDirty

  const { mutate } = useUpdateTeamspacePermissionsMutation()

  const onFormSubmit = (): void => {
    mutate(
      {
        teamspaceId: teamspace.id,
        visibility: visibility,
        defaultRole: defaultRole,
      },
      {
        onSuccess: async () => {
          await dispatch(
            teamspaceEditedLogger({
              teamspaceId: teamspace.id,
              changedVisibility: visibility !== teamspace.visibility,
              changedDefaultRole: defaultRole !== teamspace.defaultRole,
            })
          )
          onClose()
        },
      }
    )
  }

  return (
    <EditTeamspacePermissionsTabForm
      onSubmit={event => {
        event.preventDefault()
        onFormSubmit()
      }}
    >
      <View direction='column' gap='24'>
        <TeamspaceVisibilitySelector
          disabled={!isOwner}
          visibility={visibility}
          setVisibility={setVisibility}
        />
        <TeamspaceDefaultRoleSelector
          disabled={!isOwner}
          defaultRole={defaultRole}
          setDefaultRole={setDefaultRole}
        />
      </View>
      <View justifyContent='flex-end' paddingTop='24'>
        <Button onClick={onClose} variant='secondary'>
          {t('modal.cancel')}
        </Button>
        <Button type='submit' disabled={!enableSubmitButton}>
          {t('modal.save')}
        </Button>
      </View>
    </EditTeamspacePermissionsTabForm>
  )
}
