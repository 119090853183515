import _ from 'lodash'
import { omitUndefinedFields } from 'sierra-domain/omit-undefined-fields'
import { EditorKeyboardEvent, Mark, SanaEditor } from 'sierra-domain/v3-author'
import {
  AnnotatedEditorActionLogEntry,
  Key,
  SafeKey,
  SafeKeys,
} from 'sierra-domain/v3-author/action-log-entry'
import { Range } from 'slate'

const logSize = 20

export function withEditorActionsLog(editor: SanaEditor): SanaEditor {
  const actionsLog: AnnotatedEditorActionLogEntry[] = []

  editor.pushActionsLogEntry = entry => {
    actionsLog.push({ entry, selection: _.cloneDeep(editor.selection) })

    // Only keep the last `logSize` entries of the log
    if (actionsLog.length > logSize) {
      actionsLog.splice(0, actionsLog.length - logSize)
    }
  }

  editor.readRecentActionsLog = () => {
    return actionsLog
  }

  const { addMark, removeMark, setSelection } = editor

  editor.addMark = (mark, value) => {
    if (value === undefined) {
      editor.pushActionsLogEntry({ removeMark: mark as Mark })
    } else {
      editor.pushActionsLogEntry({ addMark: mark as Mark })
    }

    return addMark(mark, value)
  }

  editor.removeMark = mark => {
    editor.pushActionsLogEntry({ removeMark: mark as Mark })
    return removeMark(mark)
  }

  let rangedSelectionState: 'none' | 'ranged' = 'none'
  editor.setSelection = selection => {
    if (Range.isRange(selection) && !Range.isCollapsed(selection)) {
      if (rangedSelectionState !== 'ranged') {
        editor.pushActionsLogEntry('start-ranged-selection')
      }

      rangedSelectionState = 'ranged'
    } else {
      if (rangedSelectionState === 'ranged') {
        editor.pushActionsLogEntry('end-ranged-selection')
      }
      rangedSelectionState = 'none'
    }

    return setSelection(selection)
  }

  return editor
}

function falseToUndefined(bool: boolean): true | undefined {
  return bool ? bool : undefined
}

export const EditorActionLog = {
  onKeyDown(editor: SanaEditor, keyEvent: EditorKeyboardEvent): void {
    const meta = omitUndefinedFields({
      shift: falseToUndefined(keyEvent.shiftKey),
      alt: falseToUndefined(keyEvent.altKey),
      ctrl: falseToUndefined(keyEvent.ctrlKey),
      meta: falseToUndefined(keyEvent.metaKey),
    })

    const canIncludeKey = SafeKeys.includes(keyEvent.key as SafeKey)
    const key: Key = canIncludeKey ? (keyEvent.key as SafeKey) : 'Other'
    editor.pushActionsLogEntry({ key, ...meta })
  },
}
