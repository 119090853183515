import React, { useMemo, useRef } from 'react'
import { Control, UseFormSetValue, UseFormWatch } from 'react-hook-form'
import { graphql } from 'sierra-client/api/graphql/gql'
import { useGraphQuery } from 'sierra-client/api/hooks/use-graphql-query'
import { MeetLogo } from 'sierra-client/components/common/logos/meet-logo'
import { SanaLogo } from 'sierra-client/components/common/logos/sana-logo'
import { TeamsLogo } from 'sierra-client/components/common/logos/teams-logo'
import { ZoomLogo } from 'sierra-client/components/common/logos/zoom-logo'
import { ReactSimpleOauth2LoginRef } from 'sierra-client/components/sana-now-integration-oauth/shared'
import {
  Platform,
  platformOauthComponent,
} from 'sierra-client/components/sana-now-integration-oauth/use-platform-oauth'
import { useTranslation } from 'sierra-client/hooks/use-translation'
import { GeneralSettingsFormData } from 'sierra-client/views/manage/settings/types'
import { FormSwitch } from 'sierra-client/views/manage/shared/form'
import { iife, isDefined } from 'sierra-domain/utils'
import { Button, Text, View } from 'sierra-ui/primitives'
import { token } from 'sierra-ui/theming'

import styled, { css } from 'styled-components'

const liveIntegrationsStatusQuery = graphql(`
  query manageOrgMeetingToolsSettings {
    viewer {
      integrations {
        googleMeetClientId
        googleMeetAuthenticated
        microsoftTeamsClientId
        microsoftTeamsAuthenticated
        zoomClientId
        zoomAuthenticated
      }
    }
  }
`)

const StyledSanaLogo = styled(SanaLogo)`
  padding: unset;
`

const Card = styled.div<{ $disabled?: boolean }>`
  display: flex;
  align-items: center;
  justify-content: space-between;
  gap: 24px;
  padding: 20px 24px;
  border-radius: 16px;
  box-shadow: 0px 2px 4px 0px #00000014;
  outline: 1px solid ${token('border/default')};

  ${({ $disabled }) =>
    $disabled === true &&
    css`
      pointer-events: none;
      opacity: 0.5;
    `}
`

const VideoCallProvider: React.FC<{
  platform: Platform
  control: Control<GeneralSettingsFormData>
  watchValues: UseFormWatch<GeneralSettingsFormData>
  onAuthSuccess: () => void
  disabled?: boolean
}> = ({ platform, onAuthSuccess, control, watchValues, disabled }) => {
  const { t } = useTranslation()
  const { data, refetch } = useGraphQuery({ document: liveIntegrationsStatusQuery })
  const oauthRef = useRef<ReactSimpleOauth2LoginRef | null>(null)

  const handleOnAuthSuccess = (): void => {
    void refetch()
    onAuthSuccess()
  }

  const formControlName = useMemo(() => {
    switch (platform) {
      case 'meet':
        return 'meetSettings.enabled'
      case 'teams':
        return 'teamsSettings.enabled'
      case 'zoom':
        return 'zoomSettings.enabled'
    }
  }, [platform])

  const isEnabled = watchValues(formControlName)

  const clientId = useMemo(() => {
    switch (platform) {
      case 'meet':
        return data?.viewer.integrations.googleMeetClientId
      case 'teams':
        return data?.viewer.integrations.microsoftTeamsClientId
      case 'zoom':
        return data?.viewer.integrations.zoomClientId
    }
  }, [
    data?.viewer.integrations.googleMeetClientId,
    data?.viewer.integrations.microsoftTeamsClientId,
    data?.viewer.integrations.zoomClientId,
    platform,
  ])

  const isAuthenticated =
    useMemo(() => {
      switch (platform) {
        case 'meet':
          return data?.viewer.integrations.googleMeetAuthenticated
        case 'teams':
          return data?.viewer.integrations.microsoftTeamsAuthenticated
        case 'zoom':
          return data?.viewer.integrations.zoomAuthenticated
      }
    }, [
      data?.viewer.integrations.googleMeetAuthenticated,
      data?.viewer.integrations.microsoftTeamsAuthenticated,
      data?.viewer.integrations.zoomAuthenticated,
      platform,
    ]) === true

  const OAuthButton = platformOauthComponent(platform)

  const copy = useMemo(() => {
    switch (platform) {
      case 'meet':
        return {
          title: t('dictionary.google-meet'),
          subtitle: iife(() => {
            if (disabled === true) {
              return t('manage.settings.meeting-tools.meet-coming-soon')
            }
            if (!isAuthenticated) {
              return t('manage.settings.meeting-tools.meet-subtitle-not-enabled')
            }
            if (isEnabled) return t('manage.api.enabled')
            else return t('manage.api.disabled')
          }),
          logo: <MeetLogo width={32} height={32} />,
        }
      case 'teams':
        return {
          title: t('dictionary.microsoft-teams'),
          subtitle: iife(() => {
            if (!isAuthenticated) {
              return t('manage.settings.meeting-tools.teams-subtitle-not-enabled')
            } else if (isEnabled) return t('manage.api.enabled')
            else return t('manage.api.disabled')
          }),
          logo: <TeamsLogo width={32} height={32} />,
        }
      case 'zoom':
        return {
          title: t('dictionary.zoom'),
          subtitle: iife(() => {
            if (!isAuthenticated) {
              return t('manage.settings.meeting-tools.zoom-subtitle-not-enabled')
            } else if (isEnabled) return t('manage.api.enabled')
            else return t('manage.api.disabled')
          }),
          logo: <ZoomLogo width={32} height={32} />,
        }
    }
  }, [platform, t, disabled, isAuthenticated, isEnabled])

  const showFormSwitch = isAuthenticated || isEnabled || disabled === true
  return (
    <>
      <Card $disabled={disabled}>
        <View gap='16'>
          <View>{copy.logo}</View>
          <View direction='column' gap='none'>
            <Text bold>{copy.title}</Text>
            <Text color='foreground/secondary'>{copy.subtitle}</Text>
          </View>
        </View>
        {showFormSwitch ? (
          <FormSwitch control={control} name={formControlName} />
        ) : (
          <Button
            variant='secondary'
            onClick={() => {
              oauthRef.current?.onBtnClick()
            }}
          >
            {t('manage.settings.meeting-tools.configure')}
          </Button>
        )}
      </Card>
      {isDefined(clientId) && (
        <OAuthButton clientId={clientId} onSuccess={handleOnAuthSuccess} ref={oauthRef} />
      )}
    </>
  )
}

export const MeetingToolsSettings: React.FC<{
  control: Control<GeneralSettingsFormData>
  watchValues: UseFormWatch<GeneralSettingsFormData>
  setValue: UseFormSetValue<GeneralSettingsFormData>
}> = ({ control, setValue, watchValues }) => {
  const { t } = useTranslation()
  const liveEnabled = watchValues('sanaLiveSettings.enabled')

  return (
    <View direction='column' gap='40'>
      <View direction='column' gap='16'>
        <Card>
          <View gap='16'>
            <View>
              <StyledSanaLogo height={32} />
            </View>
            <View direction='column' gap='none'>
              <Text bold>{t('dictionary.sana')}</Text>
              <Text color='foreground/secondary'>
                {liveEnabled ? t('manage.api.enabled') : t('manage.api.disabled')}
              </Text>
            </View>
          </View>
          <FormSwitch control={control} name={'sanaLiveSettings.enabled'} />
        </Card>

        <VideoCallProvider
          control={control}
          watchValues={watchValues}
          platform='teams'
          onAuthSuccess={() => {
            setValue('teamsSettings.enabled', true, { shouldDirty: true })
          }}
        />
        <VideoCallProvider
          control={control}
          watchValues={watchValues}
          platform='zoom'
          onAuthSuccess={() => {
            setValue('zoomSettings.enabled', true, { shouldDirty: true })
          }}
        />

        <VideoCallProvider
          control={control}
          platform='meet'
          watchValues={watchValues}
          onAuthSuccess={() => {
            setValue('meetSettings.enabled', true, { shouldDirty: true })
          }}
        />
      </View>
    </View>
  )
}
