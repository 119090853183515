import { useTranslation } from 'sierra-client/hooks/use-translation'
import { useDispatch, useSelector } from 'sierra-client/state/hooks'
import { clearIssue } from 'sierra-client/state/live/actions'
import { selectUnrecoverableIssues } from 'sierra-client/state/live/selectors'
import { Debug } from 'sierra-client/views/learner/components/debug'
import { Icon, IconId, Modal } from 'sierra-ui/components'
import { Button, Text, View } from 'sierra-ui/primitives'
import { legacyLight } from 'sierra-ui/theming/legacy-theme'
import styled, { ThemeProvider } from 'styled-components'

const ModalContentView = styled(View).attrs({ direction: 'column', alignItems: 'center', gap: 'xsmall' })`
  max-width: 30rem;
`

export const BasicIssueModal: React.FC<{
  open: boolean
  title: string
  longDescription: string
  cta: React.ReactNode
  helpLink?: string
  iconId?: IconId
}> = ({ open, title, longDescription, helpLink, cta, iconId }) => {
  const { t } = useTranslation()

  return (
    <Modal open={open} size='fit-content' overlayVariant='dark' padding='xlarge' animation='fade'>
      <ThemeProvider theme={legacyLight}>
        <ModalContentView>
          <Icon iconId={iconId ?? 'wifi--off'} size='size-24' color='foreground/primary' />
          <View direction='column'>
            <Text align='center' size='regular' bold>
              {title}
            </Text>
            <Text align='center' size='small'>
              {longDescription}
            </Text>
            {helpLink !== undefined && (
              <Text align='center' size='small'>
                <a href={helpLink} target='_blank' rel='noreferrer'>
                  {t('live-page.live-issue-information__help-link')}
                </a>
              </Text>
            )}
          </View>
          {cta}
        </ModalContentView>
      </ThemeProvider>
    </Modal>
  )
}

export const LiveSessionIssueModal: React.FC = () => {
  const unrecoverableIssues = useSelector(selectUnrecoverableIssues)
  const firstUnrecoverableIssue = unrecoverableIssues[0] ?? undefined
  const { t } = useTranslation()
  const dispatch = useDispatch()

  const dismiss = (): void => {
    if (firstUnrecoverableIssue !== undefined) dispatch(clearIssue(firstUnrecoverableIssue.name))
  }

  const reload = (): void => {
    window.location.reload()
  }

  return (
    <>
      {firstUnrecoverableIssue !== undefined && (
        <BasicIssueModal
          open={true}
          title={t(firstUnrecoverableIssue.title)}
          longDescription={t(firstUnrecoverableIssue.longDescription)}
          helpLink={firstUnrecoverableIssue.helpLink}
          cta={
            <>
              <Button onClick={reload}>{t('dictionary.refresh')}</Button>

              <Debug>
                <Button onClick={dismiss}>[Debug] Dismiss</Button>
              </Debug>
            </>
          }
        />
      )}
    </>
  )
}
