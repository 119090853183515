import styled from 'styled-components'

export const HiddenInput = styled.input`
  display: none;
  opacity: 0;
  width: 0;
  height: 0;
  overflow: hidden;
  pointer-events: none;
`
