import React from 'react'
import { FCC } from 'sierra-client/types'
import { Icon, IconProps } from 'sierra-ui/components'
import { Spacer, View } from 'sierra-ui/primitives'
import { token } from 'sierra-ui/theming'
import styled from 'styled-components'

const LineSeparator = styled.div`
  border-top: 1px solid ${token('border/strong')};
  width: 100%;
  height: 1px;
`

const ClickableIcon = styled(Icon)`
  position: relative;
  cursor: pointer;
  transition: color 0.2s ease;
  color: ${p => p.theme.color.grey35};

  &:hover {
    color: ${p => p.theme.color.grey85};
  }

  /* Larger click surface */
  &::after {
    content: '';
    position: absolute;
    top: 50%;
    left: 50%;
    width: 2rem;
    height: 2rem;
    transform: translate(-50%, -50%);
  }
`

// Width of an icon button
const EmptySpace = styled.div`
  width: 0.875rem;
`

export type DialogTitleWithIconsProps = {
  hideSeparator?: boolean | undefined
  leftIcon?: JSX.Element
  rightIcon?: JSX.Element
}

export const DialogTitleWithIcons: FCC<DialogTitleWithIconsProps> = ({
  children,
  hideSeparator,
  leftIcon,
  rightIcon,
}) => {
  return (
    <View
      grow
      direction='column'
      paddingLeft='medium'
      paddingRight='medium'
      paddingTop='small'
      paddingBottom={'none'}
      gap='none'
    >
      <View justifyContent='space-between' alignItems='center' grow>
        {leftIcon || <EmptySpace />}
        {children}
        {rightIcon || <EmptySpace />}
      </View>

      {hideSeparator !== true && (
        <>
          <Spacer size='small' />
          <LineSeparator />
        </>
      )}
    </View>
  )
}

type DialogIconProps = {
  onClick?: () => void
  iconProps?: Partial<IconProps>
  className?: string
}

export const DialogIcon: React.FC<DialogIconProps> = ({ onClick, iconProps, className }) => {
  return (
    <ClickableIcon
      iconId='close'
      color='currentColor'
      size='size-14'
      className={className}
      onClick={onClick}
      {...iconProps}
    />
  )
}
