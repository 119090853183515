import React, { useEffect } from 'react'
import { InsightsAssistantInput } from 'sierra-client/lib/assistant-input/search-insights/insights-assistant-input'
import { useInsightsContentSuggestionEditor } from 'sierra-client/views/insights/insights-content-search/use-insights-content-suggestion-editor'
import { serializeInsightContentSuggestions } from 'sierra-client/views/insights/insights-content-search/utils'
import { AiGradientBase, aiGradient } from 'sierra-ui/components'
import { View } from 'sierra-ui/primitives'
import styled, { css } from 'styled-components'
import { HandleTemplateSelect } from './types'
import { WidgetSearch } from './widget-search'

const FullWidthContainer = styled(View)<AiGradientBase>`
  width: 100%;
  height: 84px;
  justify-content: space-between;
  ${aiGradient};
`

const InputContainer = styled(View)<{ $isNotEmpty: boolean }>`
  flex: 0 0 400px;
  width: 400px;
  transition: flex 0.3s ease;

  &:focus-within {
    flex: 0 0 500px;
  }

  ${p =>
    p.$isNotEmpty &&
    css`
      flex: 0 0 500px;
    `}
`

export const InsightsAssistantWithSearch: React.FC<
  {
    onAskAssistant: (query: string) => void
  } & HandleTemplateSelect
> = ({ onAskAssistant, handleTemplateSelect }) => {
  const insightsEditor = useInsightsContentSuggestionEditor()
  const askAnythingQuery = serializeInsightContentSuggestions(insightsEditor)

  useEffect(() => {
    if (askAnythingQuery !== '') {
      const handleEnter = (e: KeyboardEvent): void => {
        if (e.key === 'Enter' && insightsEditor?.isFocused === true) {
          onAskAssistant(askAnythingQuery)
        }
      }
      window.addEventListener('keydown', handleEnter)

      return () => {
        window.removeEventListener('keydown', handleEnter)
      }
    }
  }, [askAnythingQuery, insightsEditor?.isFocused, onAskAssistant])

  return (
    <FullWidthContainer
      direction='row'
      data-intercom-target='insights-search'
      radius='size-16'
      padding='8 none'
      base='org/primary'
      gap='16'
    >
      <InputContainer
        direction='column'
        background={'surface/default'}
        marginLeft={'24'}
        radius='size-10'
        $isNotEmpty={askAnythingQuery !== ''}
      >
        <InsightsAssistantInput editor={insightsEditor} askInsightsQuestion={onAskAssistant} />
      </InputContainer>

      <View overflow='hidden'>
        <WidgetSearch searchQuery={askAnythingQuery} handleTemplateSelect={handleTemplateSelect} />
      </View>
    </FullWidthContainer>
  )
}
