import fetch from 'isomorphic-unfetch'
import { merge, throttle, truncate } from 'lodash'
import pino from 'pino'
import { config } from 'sierra-client/config/global-config'
import { getAdblockStatus, setAdblockStatus } from 'sierra-client/error/is-adblock-enabled'
import { MaybeErrorProperties, Meta, UnknownError } from 'sierra-client/error/log-meta'

// eslint-disable-next-line turbo/no-undeclared-env-vars
const API_KEY = process.env.NEWRELIC_API_KEY as string
export const NEW_RELIC_LOGS_URL = `https://nr-proxy.x.sana.ai/log/v1?Api-Key=${API_KEY}`
export const NEW_RELIC_LOGS_URL_NON_PROXY = `https://log-api.eu.newrelic.com/log/v1?Api-Key=${API_KEY}`

export const LOG_SEND_INTERVAL = 5000

export type NewRelicMessage = {
  message: string
  timestamp: number
  attributes?: Record<string, any>
  level: string
}

type NewRelicAPIPayload = {
  common: {
    attributes?: any
  }
  logs: NewRelicMessage[]
}

let messages: NewRelicMessage[] = []
let globalAttributes: Record<string, string | number | undefined> = {}

const send = throttle(
  async (): Promise<void> => {
    if (messages.length === 0) return
    const messagesToSend = messages
    messages = []

    const payload: NewRelicAPIPayload = {
      common: {
        attributes: globalAttributes,
      },
      logs: messagesToSend,
    }

    const body = JSON.stringify([payload])
    const adblockStatus = getAdblockStatus()
    try {
      if (adblockStatus === 'unknown') {
        // We don't know if adblock is enabled, without proxy and see if it works
        try {
          await fetch(NEW_RELIC_LOGS_URL_NON_PROXY, { body, method: 'POST' })
          setAdblockStatus('not-blocked')
        } catch (error) {
          setAdblockStatus('blocked')
          fetch(NEW_RELIC_LOGS_URL, { body, method: 'POST' }).catch(() => {
            // ignore
          })
        }
      } else if (adblockStatus === 'blocked') {
        fetch(NEW_RELIC_LOGS_URL, { body, method: 'POST' }).catch(() => {
          // ignore
        })
      } else {
        // sendBeacon is blocked by some adblockers, so we use fetch as a fallback
        navigator.sendBeacon(NEW_RELIC_LOGS_URL, body)
      }
    } catch {
      //ignore
    }
  },
  LOG_SEND_INTERVAL,
  { trailing: true, leading: false }
)

// Since send is throttled, we ensure the function is flushed if the windown unloads
// or becomes unvisible. This should ensure logs are always sent.
let queueFlushInitialized = false
const initializeSendQueueFlush = (): void => {
  if (queueFlushInitialized) return

  if (typeof window !== 'undefined') {
    window.addEventListener('unload', () => {
      void send.flush()
    })

    document.addEventListener('visibilitychange', () => {
      if (document.visibilityState === 'hidden') {
        void send.flush()
      }
    })

    queueFlushInitialized = true
  }
}

export const sendToNewRelic = (message: NewRelicMessage): void => {
  initializeSendQueueFlush()

  messages.push(message)
  void send()
  // Ensure we don't batch up too much data
  if (messages.length > 7) {
    void send.flush()
  }
}

// Ensure the logs aren't too long
// Their own implementation uses 1024 as a cap for
const getErrorInfo = (error?: Error): Record<string, string | undefined> | undefined => {
  if (!error) return undefined
  const maybeErrorProps = error as MaybeErrorProperties

  return {
    message: truncate(error.message, { length: 1024 }),
    stack: error.stack !== undefined ? truncate(error.stack, { length: 1024 }) : undefined,
    class: error.name === 'Error' ? error.constructor.name : error.name,
    code: maybeErrorProps.code,
    type: maybeErrorProps.type,
    status: maybeErrorProps.status,
    text:
      maybeErrorProps.text !== undefined && typeof maybeErrorProps.text === 'string'
        ? truncate(maybeErrorProps.text, { length: 1024 })
        : undefined,
  }
}

export const handlePinoSend = (level: pino.Level, logEvent: pino.LogEvent): void => {
  const newRelicLogsEnabled = Boolean(config.newRelicApiKey)
  if (newRelicLogsEnabled !== true) return

  const [message, meta] = logEvent.messages as [string, Meta | undefined]

  const { error, ...metadata } = meta ?? {}

  const errorInfo = getErrorInfo(
    error !== undefined && !(error instanceof Error) ? new UnknownError(error) : error
  )

  const attributes = merge({ error: errorInfo }, { meta: metadata }, ...logEvent.bindings) as Record<
    string,
    any
  >
  const timestamp = logEvent.ts

  sendToNewRelic({
    message,
    timestamp,
    attributes,
    level,
  })
}

export const setGlobalAttributes = (attributes: Record<string, string | number | undefined>): void => {
  globalAttributes = { ...globalAttributes, ...attributes }
}
