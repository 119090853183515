import { Duration } from 'luxon'
import React from 'react'
import { TruncatedText } from 'sierra-ui/components'
import { durationToLargestUnitString } from 'sierra-ui/missions/delphi/charts/utils'

export const DurationCell: React.FC<{ cellData?: Duration }> = ({ cellData }) => {
  return (
    <TruncatedText lines={1} size='small'>
      {durationToLargestUnitString(cellData)}
    </TruncatedText>
  )
}
