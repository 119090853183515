import { graphql } from 'sierra-client/api/graphql/gql'
import { graphQuery } from 'sierra-client/api/hooks/use-graphql-query'
import { CalendarEventId } from 'sierra-domain/api/nano-id'

type SoftDeleteCalendarEventMutation = (calendarEventId: CalendarEventId) => Promise<CalendarEventId>
export const useSoftDeleteCalendarEvent = (): SoftDeleteCalendarEventMutation => {
  const softDeleteCalendarEvent: SoftDeleteCalendarEventMutation = async calendarEventId => {
    const result = await graphQuery(
      graphql(`
        mutation softDeleteCalendarEvent($calendarEventId: CalendarEventId!) {
          softDeleteCalendarEvent(calendarEventId: $calendarEventId) {
            calendarEventId
          }
        }
      `),
      {
        calendarEventId,
      }
    )
    return result.softDeleteCalendarEvent.calendarEventId
  }

  return softDeleteCalendarEvent
}
