import { buildSelfPacedUrlPath } from 'sierra-client/views/self-paced/useNavigate'
import { CourseForHref, PendingCourse } from 'sierra-domain/api/backend-self-paced/types'
import { CourseWithStatus } from 'sierra-domain/api/course-with-status'
import { assertNever } from 'sierra-domain/utils'

export const prefixWithProgramAndOrPath = ({
  href,
  pathId,
  programId,
}: {
  href: string
  pathId: string | undefined
  programId: string | undefined
}): string =>
  [
    programId !== undefined ? `/program/${programId}` : '',
    pathId !== undefined ? `/path/${pathId}` : '',
    href,
  ].join('')

export function toCourseForHref(course: CourseWithStatus): CourseForHref {
  return {
    id: course.id,
    type: course.type,
    nextSessionId: course.type === 'native:live' ? course.nextSession?.liveSessionId : undefined,
    completedSessionId: course.type === 'native:live' ? course.completedSession?.liveSessionId : undefined,
    courseGroupId: course.type === 'native:self-paced' ? course.courseGroupId : undefined,
  }
}

export const getHrefToCourse = ({
  course,
  programId,
  pathId,
  skipCourseOverview = false,
}: {
  course: CourseForHref
  programId: string | undefined
  pathId: string | undefined
  skipCourseOverview?: boolean
}): string => {
  switch (course.type) {
    case 'native:live': {
      if (course.completedSessionId !== undefined) return `/l/${course.completedSessionId}`
      if (course.nextSessionId !== undefined) return `/l/${course.nextSessionId}`

      return prefixWithProgramAndOrPath({ href: `/course/${course.id}`, pathId, programId })
    }
    case 'native:event-group':
      return prefixWithProgramAndOrPath({ href: `/event/${course.id}`, pathId, programId })
    case 'native:self-paced':
      return buildSelfPacedUrlPath({
        programId,
        courseGroupId: course.courseGroupId,
        pathId,
        flexibleContentId: course.id,
        skipCourseOverview,
      })
    case 'linkedin':
    case 'link':
    case 'scorm':
    case 'native:course-group':
    case 'scorm:course-group':
      return prefixWithProgramAndOrPath({ href: `/course/${course.id}`, pathId, programId })
    default:
      assertNever(course.type)
  }
}

export const getHrefToNextUpAfterPendingCourse = ({
  pendingCourse,
}: {
  pendingCourse: PendingCourse
}): string => {
  /**
   * If we have programInformation with a next step -> nextUp should be the href to that next step
   * If we have pathInformation with a next step -> nextUp should be the href to that next step
   * Otherwise -> nextUp should be the href to that course
   */
  const pathId = pendingCourse.pathInformation?.pathId
  const programId = pendingCourse.programInformation?.programId

  const nextCourse =
    pendingCourse.programInformation?.nextStep?.courseForHref ??
    pendingCourse.pathInformation?.nextStep?.courseForHref

  if (nextCourse === undefined) return `/course/${pendingCourse.courseId}`

  switch (nextCourse.type) {
    case 'native:live': {
      if (nextCourse.completedSessionId !== undefined) return `/l/${nextCourse.completedSessionId}`
      if (nextCourse.nextSessionId !== undefined) return `/l/${nextCourse.nextSessionId}`

      return prefixWithProgramAndOrPath({ href: `/course/${nextCourse.id}`, pathId, programId })
    }
    case 'native:event-group':
      return prefixWithProgramAndOrPath({ href: `/event/${nextCourse.id}`, pathId, programId })
    case 'native:self-paced':
      return buildSelfPacedUrlPath({
        programId,
        courseGroupId: nextCourse.courseGroupId,
        pathId,
        flexibleContentId: nextCourse.id,
        skipCourseOverview: true,
      })
    case 'linkedin':
    case 'link':
    case 'scorm':
    case 'native:course-group':
    case 'scorm:course-group':
      return prefixWithProgramAndOrPath({ href: `/course/${nextCourse.id}`, pathId, programId })
    default:
      assertNever(nextCourse.type)
  }
}
