import React, { FC } from 'react'
import { RenderBulletCard } from 'sierra-client/features/content-preview/components/bullet-card-preview'
import { Heading } from 'sierra-client/features/content-preview/components/heading'
import { RenderLeaf } from 'sierra-client/features/content-preview/components/leaf-preview'
import { PreamblePreview } from 'sierra-client/features/content-preview/components/preamble-preview'
import { ReflectionCardPreview } from 'sierra-client/features/content-preview/components/reflection-card-preview'
import {
  HorizontalWrapper,
  VerticalContent,
} from 'sierra-client/features/content-preview/components/stacks-preview'
import { getHeadingSize } from 'sierra-client/views/v3-author/heading/heading'
import { CardInnerStyled } from 'sierra-client/views/v3-author/title-card'
import { CardGrid } from 'sierra-client/views/v3-author/wrapper'
import { AssetContext } from 'sierra-domain/asset-context'
import { File, FileType } from 'sierra-domain/flexible-content/types'
import { SlateDocument } from 'sierra-domain/v3-author'
import { spacing } from 'sierra-ui/theming'
import { Descendant, Element, Text } from 'slate'
import styled, { css } from 'styled-components'

const RenderSlateElement: FC<{ node: Descendant; children: React.ReactNode; assetContext: AssetContext }> = ({
  node,
  children,
  assetContext,
}) => {
  if (!Element.isElement(node)) return <RenderLeaf leaf={node}>{children}</RenderLeaf>

  switch (node.type) {
    case 'paragraph':
      return (
        <p style={{ minWidth: 10 }}>
          {children}
          <>
            <br />
          </>
        </p>
      )
    case 'heading':
      return <Heading $size={getHeadingSize(node.level ?? 2)}>{children}</Heading>
    case 'title-card':
      return (
        <CardGrid>
          <CardInnerStyled $variant={node.variant ?? 'left-aligned'}>{children}</CardInnerStyled>
        </CardGrid>
      )
    case 'bullet-card': {
      return (
        <RenderBulletCard node={node} assetContext={assetContext}>
          {children}
        </RenderBulletCard>
      )
    }
    case 'list-item': {
      return <li>{children}</li>
    }
    case 'bulleted-list': {
      return <ul>{children}</ul>
    }
    case 'numbered-list': {
      return <ol>{children}</ol>
    }
    case 'horizontal-stack': {
      return <HorizontalWrapper>{children}</HorizontalWrapper>
    }
    case 'vertical-stack': {
      return <VerticalContent>{children}</VerticalContent>
    }
    case 'preamble': {
      return <PreamblePreview preamble={node}>{children}</PreamblePreview>
    }
    case 'reflection-card': {
      return <ReflectionCardPreview node={node}>{children}</ReflectionCardPreview>
    }
  }
}

const RenderSlate: FC<{ node: Descendant; assetContext: AssetContext }> = ({ node, assetContext }) => {
  return (
    <RenderSlateElement node={node} assetContext={assetContext}>
      {Text.isText(node)
        ? node.text
        : node.children.map((n, index) => (
            <RenderSlate key={Element.isElement(n) ? n.id : index} node={n} assetContext={assetContext} />
          ))}
    </RenderSlateElement>
  )
}

const SlateCardContainer = styled.div<{ $fileType: FileType }>`
  display: flex;
  flex-direction: column;

  width: 100%;
  min-height: 100%;

  flex: 1 1 auto;
  height: auto;

  isolation: isolate; /* TODO(seb): Not sure what this is used for or if it's still needed */
  background-color: transparent;

  & > * + * {
    margin-top: ${spacing['12']};
  }

  & > p + h1,
  p + h2,
  p + h3,
  p + h4 {
    margin-top: ${spacing['4']};
  }

  ${p =>
    (p.$fileType === 'general' || p.$fileType === 'notepad' || p.$fileType === 'external-notepad') &&
    css`
      padding: ${spacing['80']} ${spacing.large};
    `}

  ${p =>
    p.$fileType === 'sliding-scale' &&
    css`
      && {
        > * {
          --middle: minmax(1rem, 6rem);
          --gutter: 1fr;
        }
      }
      padding: ${spacing['80']} ${spacing['8']};
      justify-content: center;
    `}
`

export const SlateCardPreview: FC<{
  file: File
  slateDocument: SlateDocument | undefined
  assetContext: AssetContext
}> = ({ slateDocument, file, assetContext }) => {
  return (
    <SlateCardContainer $fileType={file.data.type}>
      {slateDocument?.map((element, index) => (
        <RenderSlate
          key={Element.isElement(element) ? element.id : index}
          node={element}
          assetContext={assetContext}
        />
      ))}
    </SlateCardContainer>
  )
}
