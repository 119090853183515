import { createSlice } from '@reduxjs/toolkit'
import { clearState } from 'sierra-client/state/actions'
import { browseCommentThread, cancelBrowseComment } from 'sierra-client/state/commenting/actions'
import { CommentingSliceState } from 'sierra-client/state/commenting/types'

const initialState = {} as CommentingSliceState

export const commentingSlice = createSlice({
  name: 'commenting',
  initialState,
  reducers: {},
  extraReducers: builder => {
    builder.addCase(clearState, () => initialState)
    builder.addCase(cancelBrowseComment.fulfilled, state => {
      state.browsing = undefined
    })
    builder.addCase(browseCommentThread.fulfilled, (state, { payload }) => {
      state.browsing = {
        comment: payload.comment,
        reason: payload.reason,
      }
    })
  },
})
