import { createContext, useContext, useMemo, useState } from 'react'
import { FCC } from 'sierra-client/types'
import { FileId } from 'sierra-domain/flexible-content/identifiers'

type TabId = 'Outline' | 'Notes'
type Tab = { id: TabId }

type Context = {
  setTab: (name: TabId) => void
  tabs: Tab[]
  currentTab: TabId
  focusedSpeakerNote?: FileId | undefined
  setFocusedSpeakerNote?: (fileId: FileId | undefined) => void
}

const SpeakerNoteReactContext = createContext<Context | undefined>(undefined)
const SpeakerNoteContextProvider = SpeakerNoteReactContext.Provider

export const SpeakerNoteContext: FCC = ({ children }) => {
  const [currentTab, setTab] = useState<TabId>('Outline')
  const [focusedSpeakerNote, setFocusedSpeakerNote] = useState<FileId | undefined>(undefined)

  const value = useMemo(
    (): Context => ({
      currentTab,
      tabs: [{ id: 'Outline' }, { id: 'Notes' }],
      focusedSpeakerNote,
      setFocusedSpeakerNote,
      setTab,
    }),
    [currentTab, focusedSpeakerNote]
  )

  return <SpeakerNoteContextProvider value={value}>{children}</SpeakerNoteContextProvider>
}

export const useSpeakerNoteContext = (): Context => {
  const context = useContext(SpeakerNoteReactContext)

  if (context === undefined) {
    throw Error('useSpeakerNoteTabContext is undefined')
  }

  return context
}
