import { useSetAtom } from 'jotai/index'
import React, { FC, useEffect, useMemo, useState } from 'react'
import { useLiveSessionContext } from 'sierra-client/components/liveV2/contexts/live-session-data'
import {
  useSelectCurrentCard,
  useSelectCurrentCardBackgroundColor,
} from 'sierra-client/components/liveV2/hooks/use-select-current-card'
import {
  CardScrollPositionTracker,
  FollowSharedScrollPosition,
} from 'sierra-client/components/liveV2/scroll-position-tracker'
import { Logging } from 'sierra-client/core/logging'
import { StrategicErrorBoundary } from 'sierra-client/error/strategic-error-boundary'
import { sidebarVersionAtom } from 'sierra-client/features/collapsable-sidebar'
import { AudioSfx } from 'sierra-client/features/sana-now/audio-sfx'
import { LiveCardRenderer } from 'sierra-client/features/sana-now/card-renderer'
import { Footer } from 'sierra-client/features/sana-now/header/footer'
import { Header } from 'sierra-client/features/sana-now/header/header'
import { useSessionActiveState } from 'sierra-client/features/sana-now/hooks/use-session-active-state'
import { SessionIdlePrompt } from 'sierra-client/features/sana-now/idle-session-end-prompt'
import { PictureInPictureSideArea } from 'sierra-client/features/sana-now/picture-in-picture-area'
import { SanaNowPostSessionRecap } from 'sierra-client/features/sana-now/post-session-page/post-session-recap'
import { RenderReactions } from 'sierra-client/features/sana-now/reaction-animations'
import { SanaNowPreLobby } from 'sierra-client/features/sana-now/sana-now-pre-lobby'
import { SideBar } from 'sierra-client/features/sana-now/sidebar'
import { SyncBackendAwarenssWithRedux } from 'sierra-client/features/sana-now/sync-awareness/sync-backend-awareness-with-redux'
import { useResolveAssetWithoutFallback } from 'sierra-client/hooks/use-resolve-asset'
import { selectFlexibleContentFolder } from 'sierra-client/state/flexible-content/selectors'
import { useDispatch, useSelector } from 'sierra-client/state/hooks'
import { FCC } from 'sierra-client/types'
import { createThumbHashUrlFromImage } from 'sierra-client/utils/image-thumbhash'
import {
  CardBackgroundProps,
  cardBackgroundStyles,
} from 'sierra-client/views/flexible-content/card-background'
import { CreateCardErrorForLearner } from 'sierra-client/views/flexible-content/create-card-error'
import { PolarisCardTheme } from 'sierra-client/views/flexible-content/polaris-card-theme'
import { SetCardProgressProvider } from 'sierra-client/views/flexible-content/progress-tracking/set-progress-provider'
import { UnsupportedFileType } from 'sierra-client/views/flexible-content/unsupported-file-type'
import { Debug } from 'sierra-client/views/learner/components/debug'
import { LiveSessionGlobalSidebar } from 'sierra-client/views/liveV2/live-session-global-sidebar'
import { LiveContentId, LiveSessionId } from 'sierra-domain/api/nano-id'
import { AssetContext } from 'sierra-domain/asset-context'
import { ScopedCreateContentId, ScopedLiveContentId } from 'sierra-domain/collaboration/types'
import { ImageFit } from 'sierra-domain/flexible-content/image-fit'
import { isLiveFile } from 'sierra-domain/flexible-content/support'
import { File } from 'sierra-domain/flexible-content/types'
import { assertNever, iife } from 'sierra-domain/utils'
import { ColorBuilder } from 'sierra-ui/color'
import { Column, ColumnContainer, Layout } from 'sierra-ui/components'
import { HideScrollbarUnlessHovered } from 'sierra-ui/components/layout-kit'
import { Text } from 'sierra-ui/primitives'
import { useOnChanged } from 'sierra-ui/utils'
import { maxWidth } from 'sierra-ui/utils/media-query-styles'
import styled from 'styled-components'

const PolarisOuterContainer = styled(Layout)<CardBackgroundProps & { $backgroundColor: ColorBuilder }>`
  display: flex;
  flex-direction: row;

  ${maxWidth.phone} {
    flex: 1;
  }

  background-color: ${p => p.$backgroundColor};
  ${cardBackgroundStyles};
`

const PolarisContainer = styled.div`
  display: flex;
  flex-grow: 1;
  flex-direction: column;
  height: 100%;
  gap: 8px;
  overflow: hidden;
  position: relative;
`

const LiveColumnContainer = styled(ColumnContainer)<{ $marginTop?: number }>`
  flex-wrap: wrap;
  display: flex;
  position: relative;
  margin-top: ${p => (p.$marginTop !== undefined ? `${p.$marginTop}px` : 0)};
`

const CardColumn = styled(Column)<{ $hideBorder: boolean } & CardBackgroundProps>`
  flex-basis: 1px;
  ${cardBackgroundStyles};
`

const LiveCardCanvas = styled.div`
  display: flex;
  flex-direction: column;
  overflow: auto;
  position: relative;
  width: 100%;
  height: 100%;

  & > * {
    /* The faciliators are a child of the card container but should not have a minimum height */
    min-height: 100%;
    height: auto;
  }

  ${HideScrollbarUnlessHovered}
`

const FollowMeLiveCardCanvas: FCC<{ currentCard: File }> = ({ children, currentCard }) => {
  const [scrollContainer, setScrollContainer] = useState<HTMLDivElement | null>(null)
  return (
    <>
      <FollowSharedScrollPosition currentCard={currentCard} scrollContainerRef={scrollContainer} />
      <CardScrollPositionTracker currentCard={currentCard} scrollContainerRef={scrollContainer} />
      <LiveCardCanvas key={currentCard.id} ref={setScrollContainer}>
        {children}
      </LiveCardCanvas>
    </>
  )
}

const PolarisCardRenderer = ({
  scopedCreateContentId,
  liveSessionId,
}: {
  scopedCreateContentId: ScopedLiveContentId
  liveSessionId: LiveSessionId
}): JSX.Element => {
  const flexibleContentId = ScopedCreateContentId.extractId(scopedCreateContentId)
  const flexibleContentFolder = useSelector(state =>
    selectFlexibleContentFolder(state, flexibleContentId, 'folder:root')
  )
  const dispatch = useDispatch()
  const currentCard = useSelectCurrentCard()

  useOnChanged(() => {
    if (currentCard !== undefined) {
      void dispatch(
        Logging.liveSession.cardViewed({
          cardType: currentCard.data.type,
        })
      )
    }
  }, [currentCard?.id])

  if (flexibleContentFolder === undefined || currentCard === undefined) {
    return <>Loading…</>
  }

  if (!isLiveFile(currentCard)) {
    return <UnsupportedFileType mode='now' flexibleContentId={flexibleContentId} file={currentCard} />
  }

  return (
    <SetCardProgressProvider courseId={flexibleContentId} file={currentCard}>
      <LiveCardRenderer
        liveSessionId={liveSessionId}
        scopedCreateContentId={scopedCreateContentId}
        file={currentCard}
      />
    </SetCardProgressProvider>
  )
}

const MainCardContentColumnContent: FC<{
  scopedCreateContentId: ScopedLiveContentId
  liveSessionId: LiveSessionId
}> = ({ scopedCreateContentId, liveSessionId }): JSX.Element => {
  // TODO do we need a new implementation of this?
  const currentCard = useSelectCurrentCard()

  // TODO
  if (!currentCard)
    return (
      <Debug>
        <Text>[Debug] no card :-(</Text>
      </Debug>
    )

  return (
    <PolarisCardTheme {...currentCard}>
      <StrategicErrorBoundary id='live-cards' strategies={['remount']} Fallback={CreateCardErrorForLearner}>
        <FollowMeLiveCardCanvas currentCard={currentCard}>
          <PolarisCardRenderer
            key='PolarisCardRenderer'
            scopedCreateContentId={scopedCreateContentId}
            liveSessionId={liveSessionId}
          />
        </FollowMeLiveCardCanvas>
      </StrategicErrorBoundary>
    </PolarisCardTheme>
  )
}

const MainCardContentColumn: FC<{
  scopedCreateContentId: ScopedLiveContentId
  backgroundImageUrl: string | undefined
  backgroundImageFit: ImageFit | undefined
  liveSessionId: LiveSessionId
}> = ({ scopedCreateContentId, backgroundImageUrl, backgroundImageFit, liveSessionId }): JSX.Element => {
  return (
    <CardColumn
      disableScrollbarGutter
      borderRadius={'0px'}
      $hideBorder={false}
      $background={backgroundImageUrl}
      $imageFit={backgroundImageFit}
    >
      <MainCardContentColumnContent
        scopedCreateContentId={scopedCreateContentId}
        liveSessionId={liveSessionId}
      />

      <RenderReactions />
      <Header />
      <Footer />
    </CardColumn>
  )
}

const useTrackLiveSessionJoined = (): void => {
  const liveSession = useLiveSessionContext()
  const dispatch = useDispatch()

  useEffect(() => {
    void dispatch(
      Logging.liveSession.sanaNowSessionJoined({
        liveSessionId: liveSession.liveSessionId,
      })
    )
  }, [dispatch, liveSession.liveSessionId])
}

const IDLE_TIMEOUT = 10 * 60 * 1000 // 10 minutes

const SanaNowSession: React.FC<{ flexibleContentId: LiveContentId }> = ({ flexibleContentId }) => {
  const scopedCreateContentId = ScopedLiveContentId.fromId(flexibleContentId)
  const card = useSelectCurrentCard()
  const assetContext: AssetContext = useMemo(
    () => ({ type: 'course' as const, courseId: flexibleContentId }),
    [flexibleContentId]
  )
  const backgroundUrl = useResolveAssetWithoutFallback({
    assetContext,
    image: card?.backgroundImage,
    size: 'default',
  })
  const imageFit =
    iife(() => {
      const cardData = card?.data

      switch (cardData?.type) {
        case 'image':
        case 'slate-card':
          return cardData.imageFit
        case 'live-lobby':
        case 'reflections':
        case 'poll':
        case 'sliding-scale':
        case 'bullet':
        case 'video':
        case 'general':
        case 'notepad':
        case 'question-card':
        case 'breakout-room':
        case 'embed':
        case 'sticky-notes':
        case 'assessment-card':
        case 'flip-cards':
        case 'homework':
        case 'project-card':
        case 'drop-a-word':
        case 'stupid-questions':
        case 'scenario':
        case 'roleplay':
        case 'external-notepad':
        case undefined:
          return undefined
        default:
          assertNever(cardData)
      }
    }) ?? 'cover'

  const backgroundColor = useSelectCurrentCardBackgroundColor()

  const liveSession = useLiveSessionContext()
  useTrackLiveSessionJoined()

  const placeholderUrl = createThumbHashUrlFromImage(card?.backgroundImage)

  if (liveSession.data.videoCallSetting.type === 'sana') {
    throw Error('Encountered sana video call setting in sana now pre-lobby')
  }

  return (
    <>
      <PolarisOuterContainer
        $backgroundColor={backgroundColor}
        $placeholderUrl={placeholderUrl}
        // If background image image-fit isn't 'cover', it might contain information that we don't want
        // to cover by UI elements.
        $background={imageFit === 'cover' ? backgroundUrl : undefined}
        $imageFit={imageFit}
      >
        <PolarisContainer>
          <LiveColumnContainer gap='none' margin='none' padding='none' paddingTop='none'>
            <MainCardContentColumn
              scopedCreateContentId={scopedCreateContentId}
              backgroundImageUrl={imageFit !== 'cover' ? backgroundUrl : undefined}
              backgroundImageFit={imageFit}
              liveSessionId={liveSession.liveSessionId}
            />
            <SideBar flexibleContentId={flexibleContentId} />

            <PictureInPictureSideArea />
          </LiveColumnContainer>
        </PolarisContainer>
      </PolarisOuterContainer>
      <LiveSessionGlobalSidebar />
      <SyncBackendAwarenssWithRedux idleTimeout={IDLE_TIMEOUT} liveSessionId={liveSession.liveSessionId} />
      <SessionIdlePrompt />
      <AudioSfx />
    </>
  )
}

export const SanaNowPage: React.FC<{ flexibleContentId: LiveContentId }> = ({ flexibleContentId }) => {
  const sessionActiveState = useSessionActiveState()
  const setSidebarVersion = useSetAtom(sidebarVersionAtom)

  /* We don't want to show the new sidebar */
  useEffect(() => {
    if (sessionActiveState.type === 'can-join') {
      setSidebarVersion('sana-now-sidebar')
    } else {
      setSidebarVersion('new-sidebar')
    }
  }, [sessionActiveState.type, setSidebarVersion])

  switch (sessionActiveState.type) {
    case 'not-started':
      return <SanaNowPreLobby />
    case 'can-join':
      return <SanaNowSession flexibleContentId={flexibleContentId} />
    case 'ended':
      return <SanaNowPostSessionRecap />
    default:
      assertNever(sessionActiveState.type)
  }
}
