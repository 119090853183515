import { HorizontalLineDivider } from 'sierra-client/components/common/horizontal-line-divider'
import { ScrollView, Text, View } from 'sierra-ui/primitives'
import { spacing } from 'sierra-ui/theming'
import styled from 'styled-components'

export const WrapperView = styled(View).attrs({ direction: 'column', gap: '32', grow: true })``

export const ContentScrollView = styled(ScrollView).attrs({ direction: 'column', gap: '32', grow: true })`
  max-height: calc(100vh - 9.25rem);
`

export const HeadingWrapperView = styled(View).attrs({ direction: 'column', gap: '4' })``

export const ContentWrapperView = styled(View).attrs({ direction: 'column', gap: '24', margin: 'none 2' })``

export const MinWidthText = styled(Text)`
  min-width: fit-content;
`

export const FullWidthView = styled(View)`
  width: 100%;
`

export const FullWidthHorizontalLineDivider = styled(HorizontalLineDivider)`
  width: 100%;
  height: 1px;
  margin: ${spacing[8]} 0;
`
