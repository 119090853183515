import { useEffect, useState } from 'react'
import { getNodeExists, getNodeMap } from 'sierra-domain/editor/operations/y-utilts'
import { NodeId } from 'sierra-domain/flexible-content/identifiers'
import * as Y from 'yjs'

function getNodeExistsForNullableId(yDoc: Y.Doc, nodeId: NodeId | undefined): boolean {
  return nodeId !== undefined && getNodeExists(yDoc, nodeId)
}

/**
 * Check whether a node exists in the YDoc node map.
 */
export const useNodeExists = (yDoc: Y.Doc, nodeId: NodeId | undefined): boolean => {
  const [nodeExists, setNodeExists] = useState<boolean>(() => {
    return getNodeExistsForNullableId(yDoc, nodeId)
  })

  useEffect(() => {
    if (nodeId === undefined) {
      setNodeExists(false)
      return
    }

    function onChanged(): void {
      setNodeExists(getNodeExistsForNullableId(yDoc, nodeId))
    }

    onChanged()

    const nodeMap = getNodeMap(yDoc)

    nodeMap.observe(onChanged)
    return () => nodeMap.unobserve(onChanged)
  }, [nodeId, yDoc])

  return nodeId !== undefined && nodeExists
}
