/**
 * NOTE: this will not work for async functions, since it measures the execution of the function, and async functions return immediately.
 * To measure time for an async function to resolve use the `traceAsync` function
 * @param name of the trace
 * @param fn
 * @returns
 */
export const trace = <T>(name: string, fn: () => T): T => {
  const id = performance.now()
  const startMark = `${name}_start_${id}`
  const endMark = `${name}_end_${id}`

  performance.mark(startMark)
  const result = fn()
  performance.mark(endMark)

  try {
    performance.measure(name, startMark, endMark)
  } catch (e) {
    // Do nothing
  }

  return result
}

/**
 * Measure the time it takes an async promise to resolve
 *
 * NOTE: this will not measure the amount of work done in the async function, for example there can be other work outside the async function that causes an async function to take longer to resolve.
 * In order to measure synchronous work, use the `trace` function
 * @param name
 * @param fn
 * @returns
 */
export const traceAsync = async <T>(name: string, fn: () => Promise<T>): Promise<T> => {
  const id = performance.now()
  const startMark = `${name}_async_start_${id}`
  const endMark = `${name}_async_end_${id}`

  performance.mark(startMark)
  const result = await fn()
  performance.mark(endMark)

  try {
    performance.measure(name, startMark, endMark)
  } catch (e) {
    // Do nothing
  }

  return result
}
