import { useSearch } from '@tanstack/react-router'
import React, { useEffect, useMemo } from 'react'
import { navigateToCreateContentId } from 'sierra-client/state/flexible-content/navigate'
import { getSubtreeIds } from 'sierra-client/state/flexible-content/selectors'
import { useNodeExists } from 'sierra-client/views/flexible-content/polaris-editor-provider/use-node-exists'
import { ScopedCreateContentId } from 'sierra-domain/collaboration/types'
import { getNodeExists } from 'sierra-domain/editor/operations/y-utilts'
import { FileId } from 'sierra-domain/flexible-content/identifiers'
import { Folder, NodeMap } from 'sierra-domain/flexible-content/types'
import { guardWith, isDefined } from 'sierra-domain/utils'
import * as Y from 'yjs'

function getYMap(yMap: Y.Map<unknown>, key: string): Y.Map<unknown> | undefined {
  const result = yMap.get(key)
  if (result instanceof Y.Map) return result
  else return undefined
}

export function getFallbackFileId(yDoc: Y.Doc): FileId | undefined {
  const jsonData = getYMap(yDoc.getMap(''), 'jsonData')
  if (jsonData === undefined) return undefined
  const nodeMap = getYMap(jsonData, 'nodeMap')?.toJSON()

  const res = NodeMap.safeParse(nodeMap)
  if (!res.success) return undefined

  const rootFolder = res.data['folder:root']

  if (isDefined(nodeMap) && guardWith(Folder, rootFolder))
    return getSubtreeIds(rootFolder, res.data).find(guardWith(FileId))
  else return undefined
}

export function useFallbackFileId(yDoc: Y.Doc | undefined, nodeId: FileId | undefined): FileId | undefined {
  return useMemo(
    () => (isDefined(yDoc) && !isDefined(nodeId) ? getFallbackFileId(yDoc) : undefined),
    [yDoc, nodeId]
  )
}

export const NavigateToDefaultFile: React.FC<{
  yDoc: Y.Doc
  scopedCreateContentId: ScopedCreateContentId
  fallbackFileId: FileId | undefined
  routerNodeId: FileId | undefined
}> = ({ yDoc, fallbackFileId, routerNodeId, scopedCreateContentId }) => {
  const routeTargetFileExists = useNodeExists(yDoc, routerNodeId)

  const { hasChatMessage, hasRequestAccess } = useSearch({
    strict: false,
    select: s => ({
      hasChatMessage: s['chat_message'] !== undefined,
      hasRequestAccess: s['request-access'] !== undefined,
    }),
  })

  useEffect(() => {
    // Get latest value, since the hook can be one frame behind. It's most important to not have
    // false negatives since that can lead to redirect loops.
    const targetExists =
      routeTargetFileExists || (routerNodeId !== undefined && getNodeExists(yDoc, routerNodeId))

    if (
      //
      !hasChatMessage &&
      !hasRequestAccess &&
      !targetExists &&
      routerNodeId !== fallbackFileId
    ) {
      void navigateToCreateContentId({ scopedCreateContentId, nodeId: fallbackFileId }, { replace: true })
    } else if (
      //
      hasChatMessage &&
      routerNodeId === undefined &&
      fallbackFileId === undefined
    ) {
      // This handles the case where a user is tagged in chat but not on a file
      void navigateToCreateContentId({ scopedCreateContentId, nodeId: undefined }, { replace: true })
    }
  }, [
    yDoc,
    routeTargetFileExists,
    fallbackFileId,
    routerNodeId,
    scopedCreateContentId,
    hasChatMessage,
    hasRequestAccess,
  ])

  return null
}
