import _ from 'lodash'
import { useMemo, useState } from 'react'
import { useConfirmationModalContext } from 'sierra-client/components/common/modals/confirmation-modal'
import { useBreakoutInSession } from 'sierra-client/components/liveV2/hooks/use-breakout-in-session'
import { useSelectCurrentCard } from 'sierra-client/components/liveV2/hooks/use-select-current-card'
import { useRightSidebarContext } from 'sierra-client/components/liveV2/live-sidebar-provider'
import { BreakoutParticipantLists } from 'sierra-client/components/liveV2/right-sidebar/breakout-lists'
import {
  PollParticipantLists,
  QuestionParticipantLists,
  ReflectionParticipantLists,
  SlidingScaleParticipantLists,
} from 'sierra-client/components/liveV2/right-sidebar/completion-lists'
import {
  CollapsableSidebarItem,
  Divider,
  EmptyPlaceholder,
  EmptyText,
} from 'sierra-client/components/liveV2/right-sidebar/components'
import { HandRaiseText } from 'sierra-client/components/liveV2/right-sidebar/hand-raise-text'
import { ParticipantListItem } from 'sierra-client/components/liveV2/right-sidebar/participant-list-item'
import { useTranslation } from 'sierra-client/hooks/use-translation'
import { useDispatch, useSelector } from 'sierra-client/state/hooks'
import {
  selectAllSessionParticipantsIncludingMe,
  selectFacilitatorIds,
  selectIsFacilitator,
  selectLiveSessionRaisedHands,
  selectPinnedParticipantsLength,
  selectUuidsForParticipants,
} from 'sierra-client/state/live-session/selectors'
import { liveSessionSlice } from 'sierra-client/state/live-session/slice'
import { selectRemoteParticipants } from 'sierra-client/state/live/selectors'
import { selectUser } from 'sierra-client/state/user/user-selector'
import { useUsersLegacy } from 'sierra-client/state/users/hooks'
import { Button, IconButton, ScrollView, Text, View } from 'sierra-ui/primitives'
import { LightTokenProvider } from 'sierra-ui/theming'
import { legacyLight } from 'sierra-ui/theming/legacy-theme'
import styled, { ThemeProvider } from 'styled-components'

const CloseRoomsButton = (): JSX.Element => {
  const breakoutInSession = useBreakoutInSession()
  const confirmationModalContext = useConfirmationModalContext()
  const dispatch = useDispatch()
  const { t } = useTranslation()

  if (!breakoutInSession) return <></>
  return (
    <Button
      variant='destructive'
      onClick={() => {
        confirmationModalContext.show({
          bodyText: t('live.breakout-room.do-you-want-to-end-the-breakout-rooms'),
          onConfirm: () => dispatch(liveSessionSlice.actions.stopBreakoutSession()),
          confirmLabel: t('live.close-breakout-rooms'),
        })
      }}
    >
      {t('live.close-breakout-rooms')}
    </Button>
  )
}

const MuteAllButton = (): JSX.Element => {
  const [loading, setLoading] = useState(false)
  const dispatch = useDispatch()
  const agoraParticipants = useSelector(selectRemoteParticipants)
  const unmutedParticipants = useMemo(
    () => agoraParticipants.filter(participant => participant.isPublishingAudio),
    [agoraParticipants]
  )
  const facilitatorIds = useSelector(selectFacilitatorIds)
  const userIds = useSelector(selectUuidsForParticipants)(
    unmutedParticipants.map(participant => participant.id)
  ).filter(userId => userId !== undefined && !facilitatorIds.includes(userId))
  const { t } = useTranslation()

  const muteAll = (): void => {
    setLoading(true)
    for (const userId of userIds) {
      if (userId !== undefined) {
        void dispatch(liveSessionSlice.actions.publishControlEvent({ userId, userAction: 'mute' }))
      }
    }
    // Fake a loading stage, so that the clients have time to act on the control event
    setTimeout(() => setLoading(false), 1500)
  }

  return (
    <Button
      loading={loading}
      disabled={userIds.length === 0}
      variant='secondary'
      onClick={muteAll}
      icon='microphone--off'
    >
      {t('live.mute-all')}
    </Button>
  )
}

const AllParticipantList = (): JSX.Element => {
  const { t } = useTranslation()
  const participants = useSelector(selectAllSessionParticipantsIncludingMe)
  const userIds = useMemo(() => participants.map(({ userId }) => userId), [participants])
  const me = useSelector(selectUser)
  const resolvedUsers = useUsersLegacy(userIds)

  const sortedParticipants = useMemo(
    () =>
      _.sortBy(participants, [
        participant => {
          if (participant.userId === me?.uuid) {
            return '' // I am first in the list
          }
          const user = resolvedUsers.find(r => r?.uuid === participant.userId)
          return user !== undefined ? _.compact([user.firstName, user.lastName]).join(' ') : undefined
        },
      ]),
    [participants, resolvedUsers, me?.uuid]
  )

  return (
    <>
      <CollapsableSidebarItem title={t('dicitionary.all')} itemCount={participants.length}>
        {participants.length === 0 ? (
          <EmptyPlaceholder>
            <EmptyText>No people</EmptyText>
          </EmptyPlaceholder>
        ) : (
          <ul>
            {sortedParticipants.map(participant => (
              <ParticipantListItem key={participant.agoraUID} participant={participant} />
            ))}
          </ul>
        )}
      </CollapsableSidebarItem>
    </>
  )
}

const Wrapper = styled(View).attrs({ direction: 'column' })`
  height: 100%;
  border: none;
  overflow: hidden;
`

const Container = styled(View).attrs({
  direction: 'column',
  margin: 'none',
  gap: 'none',
})`
  height: 100%;
  color: ${p => p.theme.home.textColor};
  background-color: ${p => p.theme.home.backgroundColor};
  border-radius: ${p => p.theme.borderRadius.regular};
  border: none;
`

const NoBorderView = styled(View)`
  border: none;
`

const Header = styled(NoBorderView)`
  padding: 1.125rem 1rem 0 1.5rem;
`

export const ParticipantExplorer = ({ showClose = true }: { showClose: boolean }): JSX.Element => {
  const isFacilitator = useSelector(selectIsFacilitator)
  const currentCard = useSelectCurrentCard()
  const rightSidebarContext = useRightSidebarContext()
  const breakoutInSession = useBreakoutInSession()
  const dispatch = useDispatch()
  const canUnpinAll = useSelector(selectPinnedParticipantsLength) > 0
  const { t } = useTranslation()

  const raisedHandsParticipants = useSelector(state => {
    const participants = selectAllSessionParticipantsIncludingMe(state)
    const raisedHands = selectLiveSessionRaisedHands(state)
    const raisedHandsIndex = _.keyBy(raisedHands, 'userId')

    return _.chain(participants)
      .filter(participant => raisedHandsIndex[participant.userId] !== undefined)
      .sortBy(participant => raisedHandsIndex[participant.userId]?.timeStartedRaisingHand)
      .value()
  }, _.isEqual)

  return (
    <ThemeProvider theme={legacyLight}>
      <LightTokenProvider>
        <Wrapper direction='column'>
          <Container>
            <Header>
              <NoBorderView grow>
                <Text bold size='small'>
                  {t('live.people.people')}
                </Text>
              </NoBorderView>
              {showClose && (
                <IconButton
                  iconId='close'
                  size='small'
                  variant='transparent'
                  tooltip={t('dictionary.close')}
                  onClick={rightSidebarContext.close}
                />
              )}
            </Header>
            <HandRaiseText userIds={raisedHandsParticipants.map(participant => participant.userId)} />
            <ScrollView gap='small' padding='small' paddingBottom='none' grow>
              {breakoutInSession ? (
                <BreakoutParticipantLists />
              ) : isFacilitator && currentCard !== undefined ? (
                <>
                  {currentCard.data.type === 'poll' ? (
                    <PollParticipantLists />
                  ) : currentCard.data.type === 'reflections' ? (
                    <ReflectionParticipantLists />
                  ) : currentCard.data.type === 'question-card' ? (
                    <QuestionParticipantLists />
                  ) : currentCard.data.type === 'sliding-scale' ? (
                    <SlidingScaleParticipantLists />
                  ) : (
                    <AllParticipantList />
                  )}
                </>
              ) : (
                <AllParticipantList />
              )}
            </ScrollView>
            {isFacilitator && (
              <>
                <Divider />
                <ScrollView direction='row' padding='xsmall small'>
                  <CloseRoomsButton />
                  <MuteAllButton />
                  <Button
                    icon='pin--filled'
                    variant='secondary'
                    disabled={!canUnpinAll}
                    onClick={() => {
                      void dispatch(liveSessionSlice.actions.unpinAllParticipants())
                    }}
                  >
                    {t('live.unpin-all')}
                  </Button>
                </ScrollView>
              </>
            )}
          </Container>
        </Wrapper>
      </LightTokenProvider>
    </ThemeProvider>
  )
}
