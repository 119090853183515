import React, { useEffect } from 'react'
import { useTableCellMultiSelection } from 'sierra-client/views/v3-author/table/multi-select/use-table-cell-multi-selection'

export const TableCellMultiSelectionSetup: React.FC = () => {
  const { clearSelection } = useTableCellMultiSelection()

  useEffect(() => {
    const handleClickOutside = (e: MouseEvent): void => {
      const target = e.target as HTMLElement
      if (!target.closest('table')) {
        clearSelection()
      }
    }

    document.addEventListener('click', handleClickOutside)

    return () => {
      document.removeEventListener('click', handleClickOutside)
    }
  }, [clearSelection])

  return null
}
