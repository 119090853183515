import { DateTime } from 'luxon'
import React from 'react'
import { ColorInheritingLink } from 'sierra-client/lib/tabular/components/cell-components'
import { CellPill } from 'sierra-client/lib/tabular/datatype/internal/cell-with-data'
import { isDefined } from 'sierra-domain/utils'
import { Icon, Tooltip, TruncatedText } from 'sierra-ui/components'
import { Pill, Text } from 'sierra-ui/primitives'
import { token, useTokenValue } from 'sierra-ui/theming'
import styled from 'styled-components'

const PrimaryLabel = styled(Text).attrs({ size: 'micro', bold: true })`
  white-space: nowrap;
`

const WarningPill = styled(Pill).attrs({ variant: 'outline' })`
  border: 1px solid ${token('warning/background').opacity(0.6)};
`

const SecondaryLabel = styled(TruncatedText).attrs({ lines: 1, size: 'micro', color: 'foreground/muted' })``

const formatDateTime = (dateTimeString: string): string =>
  DateTime.fromISO(dateTimeString).toLocaleString({
    month: 'short',
    day: 'numeric',
    year: 'numeric',
    hour: '2-digit',
    minute: '2-digit',
  })

export const PillCell: React.FC<{ cell: CellPill }> = ({ cell }) => {
  const { data } = cell

  const getToken = useTokenValue()

  switch (data.type) {
    case 'success':
      return (
        <Pill variant='success'>
          <Icon size='size-12' iconId='checkmark--filled' color='success/foreground' />
          <PrimaryLabel color='success/foreground'>{data.text}</PrimaryLabel>
          {isDefined(data.subtext) && (
            <SecondaryLabel tooltip={isDefined(data.tooltip) ? data.tooltip : undefined}>
              {data.subtext}
            </SecondaryLabel>
          )}
        </Pill>
      )
    case 'error':
      return (
        <Pill variant='error'>
          <Icon size='size-12' iconId='close--circle--filled' color='destructive/background' />
          <PrimaryLabel color='destructive/background'>{data.text}</PrimaryLabel>
          {isDefined(data.subtext) && (
            <SecondaryLabel tooltip={isDefined(data.tooltip) ? data.tooltip : undefined}>
              {data.subtext}
            </SecondaryLabel>
          )}
        </Pill>
      )
    case 'ghost':
      return (
        <Pill variant='ghost'>
          <PrimaryLabel>{data.text}</PrimaryLabel>
          {isDefined(data.subtext) && (
            <SecondaryLabel tooltip={isDefined(data.tooltip) ? data.tooltip : undefined}>
              {data.subtext}
            </SecondaryLabel>
          )}
        </Pill>
      )
    case 'older':
      return (
        <ColorInheritingLink
          href={data.link}
          next
          target={cell.hints.includes('open-in-new-tab') ? '_blank' : undefined}
        >
          <Tooltip title={isDefined(data.subtext) ? formatDateTime(data.subtext) : undefined}>
            <WarningPill>
              <Icon
                size='size-12'
                iconId='recently-viewed'
                color={getToken('warning/background').opacity(0.6)}
              />
              <PrimaryLabel color={getToken('warning/background').opacity(0.6)}>{data.text}</PrimaryLabel>
            </WarningPill>
          </Tooltip>
        </ColorInheritingLink>
      )
    case 'most-recent':
      return (
        <ColorInheritingLink
          href={data.link}
          next
          target={cell.hints.includes('open-in-new-tab') ? '_blank' : undefined}
        >
          <Tooltip title={isDefined(data.subtext) ? formatDateTime(data.subtext) : undefined}>
            <Pill variant='outline'>
              <PrimaryLabel color='foreground/secondary'>{data.text}</PrimaryLabel>
            </Pill>
          </Tooltip>
        </ColorInheritingLink>
      )
  }
}
