import { createFileRoute } from '@tanstack/react-router'
import { useEffect } from 'react'
import { useScormContext } from 'sierra-client/context/scorm-context'
import { requireLoggedIn } from 'sierra-client/core/require-logged-in'
import { getGlobalRouter } from 'sierra-client/router'
import { CourseKind } from 'sierra-domain/api/common'
import { CourseId, SelfPacedContentId } from 'sierra-domain/api/nano-id'
import { z } from 'zod'

const SplatParamSchema = z.tuple([z.enum(['course-group', 'course']), CourseKind, CourseId])

function Page(): JSX.Element | null {
  const [type, courseKind, courseId] = Route.useParams({
    select: params => SplatParamSchema.parse(params._splat?.split('/')),
  })

  const ping = Route.useSearch({ select: search => search.ping })

  const { scormCourseGroupId, setPing, setCourseKind, setScormCourseGroupId } = useScormContext()

  useEffect(() => {
    setCourseKind(courseKind)

    if (ping === true) setPing(true)

    if (type === 'course-group') {
      // redirect in next render, after setScormCourseGroupId has been set
      setScormCourseGroupId(courseId)
    } else if (courseKind === 'native:self-paced')
      void getGlobalRouter().navigate({
        to: '/s/$flexibleContentId',
        params: { flexibleContentId: courseId as SelfPacedContentId },
        replace: true,
      })
    else if (courseKind === 'native:course-group') {
      void getGlobalRouter().navigate({
        to: '/course/$courseId',
        params: { courseId },
        replace: true,
      })
    }
  }, [setScormCourseGroupId, setCourseKind, setPing, type, courseKind, courseId, ping])

  useEffect(() => {
    if (scormCourseGroupId !== undefined) {
      void getGlobalRouter().navigate({ to: `/select-edition`, replace: true })
    }
  }, [scormCourseGroupId])

  return null
}

export const Route = createFileRoute('/scorm-dispatch/$')({
  component: requireLoggedIn(Page, { allowScorm: true }) as React.FC,
  validateSearch: z.object({
    ping: z.boolean().optional(),
  }),
})
