import { useMutation, useQuery } from '@tanstack/react-query'
import { useAtomValue, useSetAtom } from 'jotai'
import { keyBy, maxBy } from 'lodash'
import React, { useCallback, useContext, useEffect, useMemo, useState } from 'react'
import { useLiveSessionIdContext } from 'sierra-client/components/liveV2/live-session-id-provider'
import { SlidingScaleChangedData, liveSessionDataChannel } from 'sierra-client/realtime-data/channels'
import { typedPost } from 'sierra-client/state/api'
import { useSelector } from 'sierra-client/state/hooks'
import {
  SlidingScaleData,
  currentSlidingScaleDataAtom,
} from 'sierra-client/state/interactive-card-data/sliding-scale-card'
import { selectUserId } from 'sierra-client/state/user/user-selector'
import { FCC } from 'sierra-client/types'
import { useCreatePageContext } from 'sierra-client/views/flexible-content/create-page-context'
import { useFileContext } from 'sierra-client/views/flexible-content/file-context'
import { useSetCardProgress } from 'sierra-client/views/flexible-content/progress-tracking/set-progress-provider'
import { useRecapContext } from 'sierra-client/views/recap/recap-context'
import { EditorMode } from 'sierra-client/views/v3-author/slate'
import { LiveSessionId } from 'sierra-domain/api/nano-id'
import { SlidingScaleCardUpsertPositionRequest } from 'sierra-domain/api/strategy-v2'
import { ScopedLiveSessionId } from 'sierra-domain/collaboration/types'
import { Entity } from 'sierra-domain/entity'
import {
  XRealtimeStrategyContentDataSlidingScaleGetSlidingScaleResults,
  XRealtimeStrategyContentDataSlidingScaleUpsertPosition,
} from 'sierra-domain/routes'
import { isDefined } from 'sierra-domain/utils'
import { SlidingScaleCard as SlateSlidingScaleCard } from 'sierra-domain/v3-author'

type SlidingScaleDataLayer = {
  slidingScaleData?: SlidingScaleData
  userPosition?: number | undefined
  setPosition?: (position: number) => void
}

const LiveContext = React.createContext<SlidingScaleDataLayer | undefined>(undefined)
const SelfPacedContext = React.createContext<SlidingScaleDataLayer | undefined>(undefined)
const CreateContext = React.createContext<SlidingScaleDataLayer | undefined>(undefined)
const RecapContext = React.createContext<SlidingScaleDataLayer | undefined>(undefined)

const FallbackDataProvider: FCC<{ element: Entity<SlateSlidingScaleCard> }> = ({ children }) => {
  return <CreateContext.Provider value={undefined}>{children}</CreateContext.Provider>
}

const LiveRealTimeDataHandler = ({
  liveSessionId,
  slidingScaleId,
  onData,
  onReadyToReceiveData,
}: {
  liveSessionId: LiveSessionId
  slidingScaleId: string
  onData: (newData: SlidingScaleChangedData) => void
  onReadyToReceiveData: (receiving: boolean) => void
}): null => {
  const { isReceivingData } = liveSessionDataChannel.useChannelEvent({
    channelId: liveSessionId,
    event: 'sliding-scale-changed',
    eventId: slidingScaleId,
    callback: onData,
  })

  useEffect(() => {
    onReadyToReceiveData(isReceivingData)
  }, [onReadyToReceiveData, isReceivingData])

  return null
}

const BackendDataProvider: FCC<{
  element: Entity<SlateSlidingScaleCard>
  liveSessionId?: LiveSessionId
  onCardCompleted: () => void | undefined
  readOnly: boolean
}> = ({ element, children, liveSessionId, onCardCompleted, readOnly }) => {
  const userId = useSelector(selectUserId)
  const { file, flexibleContentId } = useFileContext()
  const setSlidingScaleData = useSetAtom(currentSlidingScaleDataAtom)

  const slidingScaleId = element.id
  const [userPosition, setUserPosition] = useState<number | undefined>(undefined)
  const [realTimeReady, setRealTimeReady] = useState(false)
  const [realTimeDataMap, setRealTimeDataMap] = useState<{
    [id: string]: SlidingScaleChangedData
  }>({})

  const slidingScaleResponses = useQuery({
    queryKey: [
      XRealtimeStrategyContentDataSlidingScaleGetSlidingScaleResults.path,
      {
        contentId: flexibleContentId,
        slidingScaleId,
        fileId: file.id,
        liveSessionId,
      },
    ],
    queryFn: () =>
      typedPost(XRealtimeStrategyContentDataSlidingScaleGetSlidingScaleResults, {
        contentId: flexibleContentId,
        slidingScaleId,
        fileId: file.id,
        liveSessionId,
      }),
    enabled: realTimeReady || liveSessionId === undefined,
  })

  const onNewRealTimeData = useCallback((newData: SlidingScaleChangedData) => {
    setRealTimeDataMap(data => ({ ...data, [newData.id]: newData }))
  }, [])

  const upsertUserPositionMutation = useMutation({
    mutationFn: (data: SlidingScaleCardUpsertPositionRequest) =>
      typedPost(XRealtimeStrategyContentDataSlidingScaleUpsertPosition, data),
    onSuccess: () => slidingScaleResponses.refetch(),
  })

  // TODO this could be flaky if a the user has made changes more recent than the incomming data
  useEffect(() => {
    if (slidingScaleResponses.data === undefined) return

    const remoteUserPosition = slidingScaleResponses.data.responses.find(
      response => response.userId === userId
    )?.position

    if (remoteUserPosition !== undefined) {
      setUserPosition(remoteUserPosition)
    }
  }, [slidingScaleResponses.data, userId])

  // Complete a card if a user has voted on an option
  useEffect(() => {
    if (userPosition !== undefined) {
      onCardCompleted()
    }
  }, [userPosition, onCardCompleted])

  const setPosition = useCallback(
    async (position: number) => {
      if (userId === undefined) throw new Error('no userId')

      setUserPosition(position)
      upsertUserPositionMutation.mutate({
        contentId: flexibleContentId,
        position,
        slidingScaleId,
        fileId: file.id,
        liveSessionId,
      })
    },
    [file.id, flexibleContentId, liveSessionId, slidingScaleId, upsertUserPositionMutation, userId]
  )

  const slidingScaleData = useMemo(() => {
    if (slidingScaleResponses.data === undefined) return

    const apiResponseMap = keyBy(slidingScaleResponses.data.responses, 'id')
    const ids = new Set([...Object.keys(apiResponseMap), ...Object.keys(realTimeDataMap)])

    return {
      responses: Array.from(ids)
        .map(id => {
          const realTimeData = realTimeDataMap[id]
          const apiData = apiResponseMap[id]

          const latest = maxBy([realTimeData, apiData], data =>
            data !== undefined ? new Date(data.updatedAt).valueOf() : 0
          )

          if (latest === undefined) return

          return {
            id: latest.id,
            uuid: latest.userId,
            position: latest.position,
          }
        })
        .filter(isDefined),
      totalResponses: slidingScaleResponses.data.totalResponses,
    }
  }, [slidingScaleResponses.data, realTimeDataMap])

  setSlidingScaleData(slidingScaleData)

  const value = useMemo(
    () => ({ slidingScaleData, userPosition, setPosition: readOnly ? undefined : setPosition }),
    [slidingScaleData, userPosition, readOnly, setPosition]
  )

  return (
    <SelfPacedContext.Provider value={value}>
      <>
        {children}
        {liveSessionId !== undefined && (
          <LiveRealTimeDataHandler
            liveSessionId={liveSessionId}
            slidingScaleId={slidingScaleId}
            onData={onNewRealTimeData}
            onReadyToReceiveData={setRealTimeReady}
          />
        )}
      </>
    </SelfPacedContext.Provider>
  )
}

const LiveDataProvider: FCC<{ element: Entity<SlateSlidingScaleCard> }> = ({ element, children }) => {
  const { liveSessionId: scopedLiveSessionId } = useLiveSessionIdContext()
  const { setCardCompleted } = useSetCardProgress()
  const liveSessionId = ScopedLiveSessionId.extractId(scopedLiveSessionId)

  return (
    <BackendDataProvider
      element={element}
      liveSessionId={liveSessionId}
      onCardCompleted={setCardCompleted}
      readOnly={false}
    >
      {children}
    </BackendDataProvider>
  )
}

const SelfPacedDataProvider: FCC<{ element: Entity<SlateSlidingScaleCard> }> = ({ element, children }) => {
  const { setCardCompleted } = useSetCardProgress()
  return (
    <BackendDataProvider element={element} onCardCompleted={setCardCompleted} readOnly={false}>
      {children}
    </BackendDataProvider>
  )
}

const CreateDataProvider: FCC<{ element: Entity<SlateSlidingScaleCard> }> = ({ element, children }) => {
  const editOrResponsesState = useAtomValue(useCreatePageContext().editOrResponsesStateAtom)

  if (editOrResponsesState.type === 'edit') {
    return <>{children}</>
  }

  return (
    <BackendDataProvider
      element={element}
      liveSessionId={editOrResponsesState.liveSessionId}
      onCardCompleted={() => {}}
      readOnly
    >
      {children}
    </BackendDataProvider>
  )
}

const BackendRecapProvider: FCC<{ element: Entity<SlateSlidingScaleCard> }> = ({ element, children }) => {
  const recapContext = useRecapContext()
  if (recapContext === undefined) throw new Error('no recap context')
  const scopedLiveSessionId = recapContext.liveSessionId
  const liveSessionId = ScopedLiveSessionId.extractId(scopedLiveSessionId)

  return (
    <BackendDataProvider element={element} liveSessionId={liveSessionId} onCardCompleted={() => {}} readOnly>
      {children}
    </BackendDataProvider>
  )
}

const modeToProvider: Record<EditorMode, FCC<{ element: Entity<SlateSlidingScaleCard> }> | undefined> = {
  'recap': BackendRecapProvider,
  'live': LiveDataProvider,
  'self-paced': SelfPacedDataProvider,
  'placement-test': undefined,
  'version-history': undefined,
  'create': CreateDataProvider,
  'review': undefined,
  'template': undefined,
}

export const SlidingScaleDataProvider: FCC<{ element: Entity<SlateSlidingScaleCard>; mode: EditorMode }> = ({
  mode,
  element,
  children,
}) => {
  const Provider = modeToProvider[mode] ?? FallbackDataProvider

  return <Provider element={element}>{children}</Provider>
}

export const useSlidingScaleData = (): SlidingScaleDataLayer => {
  const liveData = useContext(LiveContext)
  const selfPacedData = useContext(SelfPacedContext)
  const createData = useContext(CreateContext)
  const recapData = useContext(RecapContext)

  const data = liveData ?? selfPacedData ?? createData ?? recapData

  if (!data) {
    throw new Error('Sliding scale data not provided')
  }

  return data
}
