import _ from 'lodash'
import React, { useCallback, useEffect, useMemo, useState } from 'react'
import { CellProps, Column, useGlobalFilter, useSortBy, useTable } from 'react-table'
import { SortableHeader } from 'sierra-client/components/table/sortable-header'
import { Table } from 'sierra-client/components/table/table'
import { usePost } from 'sierra-client/hooks/use-post'
import { useTranslation } from 'sierra-client/hooks/use-translation'
import { Empty, MenuContainer, TableContainer } from 'sierra-client/views/manage/components/common'
import { ProgramHeatmap } from 'sierra-client/views/manage/reports/components/heatmap/program-heatmap'
import { UserFilterHeatmap } from 'sierra-client/views/manage/reports/components/heatmap/user-filter-heatmap'
import { useFilterUsersDomainReps } from 'sierra-client/views/manage/users/use-filter-users'
import { HeatmapSpec, SavedCustomHeatmapSummary, SavedHeatmapSummary } from 'sierra-domain/api/heatmap'
import {
  XRealtimeAdminHeatmapDeleteHeatmap,
  XRealtimeAdminHeatmapGetHeatmap,
  XRealtimeAdminHeatmapListHeatmaps,
} from 'sierra-domain/routes'
import { assert, assertNever } from 'sierra-domain/utils'
import { Text } from 'sierra-ui/primitives'
import { IconMenu } from 'sierra-ui/primitives/menu-dropdown'

// custom

type SavedCustomHeatmapViewProps = {
  heatmapId?: string
  onClose: () => void
  onSave: (summary: SavedCustomHeatmapSummary) => void
}

type SavedCustomHeatmapData = {
  summary: SavedCustomHeatmapSummary
  spec: HeatmapSpec
}

export const SavedCustomHeatmapView: React.FC<SavedCustomHeatmapViewProps> = ({
  heatmapId,
  onClose,
  onSave,
}) => {
  const { postWithUserErrorException } = usePost()

  const [savedHeatmapData, setSavedHeatmapData] = useState<SavedCustomHeatmapData | undefined>(undefined)
  const domainReps = useFilterUsersDomainReps()

  useEffect(() => {
    if (heatmapId === undefined) return

    let cancelled = false

    void postWithUserErrorException(XRealtimeAdminHeatmapGetHeatmap, {
      heatmapId,
    }).then(({ data }) => {
      if (cancelled) return

      setSavedHeatmapData(
        data !== undefined
          ? {
              summary: {
                type: 'custom',
                id: data.id,
                title: data.title,
                description: data.description,
                updatedAt: data.updatedAt,
              },
              spec: data.spec,
            }
          : undefined
      )
    })

    return () => {
      cancelled = true
    }
  }, [postWithUserErrorException, heatmapId])

  const handleUpdate = useCallback(
    (newSummary: SavedCustomHeatmapSummary): void => {
      setSavedHeatmapData(curr =>
        curr !== undefined
          ? {
              ...curr,
              summary: { ...curr.summary, title: newSummary.title, description: newSummary.description },
            }
          : undefined
      )

      // Propagate update to parent
      onSave(newSummary)
    },
    [onSave]
  )

  if (savedHeatmapData === undefined) return null

  if (savedHeatmapData.spec.type !== 'custom') return null

  return (
    <UserFilterHeatmap
      heatmapSummary={savedHeatmapData.summary}
      onClose={onClose}
      onSaveHeatmap={handleUpdate}
      query={savedHeatmapData.spec.query}
      filter={savedHeatmapData.spec.filter}
      domainReps={domainReps}
      initialContentIds={savedHeatmapData.spec.contentIds}
    />
  )
}

// hook

type UseListHeatmaps = {
  loading: boolean
  data: SavedHeatmapSummary[]
  removeCustomHeatmap: (id: string) => Promise<void>
  removeProgramHeatmap: (id: string) => void
  updateLocalHeatmap: (summary: SavedCustomHeatmapSummary) => void
}

export const useListHeatmaps = (): UseListHeatmaps => {
  const { postWithUserErrorException } = usePost()

  const [loading, setLoading] = useState(true)
  const [data, setData] = useState<SavedHeatmapSummary[]>([])

  useEffect(() => {
    let cancelled = false
    setLoading(true)

    const listHeatmaps = async (): Promise<void> => {
      const res = await postWithUserErrorException(XRealtimeAdminHeatmapListHeatmaps, {})

      if (!cancelled) {
        setData(res.data)
        setLoading(false)
      }
    }

    void listHeatmaps()

    return () => {
      cancelled = true
    }
  }, [postWithUserErrorException])

  const removeCustomHeatmap = useCallback<UseListHeatmaps['removeCustomHeatmap']>(
    async idToRemove => {
      await postWithUserErrorException(XRealtimeAdminHeatmapDeleteHeatmap, { heatmapId: idToRemove })

      setData(curr => curr.filter(summary => summary.type === 'custom' && summary.id !== idToRemove))
    },
    [postWithUserErrorException]
  )

  // Remove the program heatmap from the local list only. The inner component will handle the backend call.
  const removeProgramHeatmap = useCallback<UseListHeatmaps['removeProgramHeatmap']>(programIdToRemove => {
    setData(curr =>
      curr.filter(summary => summary.type !== 'program' || summary.programId !== programIdToRemove)
    )
  }, [])

  // TODO: Should this function be responsible for the remote call as well? That way we'd centralize the logic
  const updateLocalHeatmap = useCallback<UseListHeatmaps['updateLocalHeatmap']>(summary => {
    setData(curr => {
      const withoutExistingValue = curr.filter(x => x.type !== 'custom' || x.id !== summary.id)

      return _.orderBy(
        [...withoutExistingValue, summary],
        x => (x.type === 'custom' ? x.updatedAt : x.createdAt),
        'desc'
      )
    })
  }, [])

  return useMemo(
    () => ({
      loading,
      data,
      removeCustomHeatmap,
      removeProgramHeatmap,
      updateLocalHeatmap,
    }),
    [loading, data, removeCustomHeatmap, removeProgramHeatmap, updateLocalHeatmap]
  )
}

export const SavedHeatmapsView: React.FC = () => {
  const { t } = useTranslation()

  const { data, removeCustomHeatmap, removeProgramHeatmap, updateLocalHeatmap } = useListHeatmaps()

  const [openHeatmapSummary, setOpenHeatmapSummary] = useState<SavedHeatmapSummary | undefined>(undefined)

  const columns: Column<SavedHeatmapSummary>[] = useMemo(
    () => [
      {
        id: 'title',
        accessor: 'title',
        Header: p => <SortableHeader label={t('table.name')} {...p} />,
        width: '30%',
      },
      {
        id: 'description',
        accessor: 'description',
        Header: p => <SortableHeader label={t('table.description')} {...p} />,
        width: '50%',
      },
      {
        id: 'type',
        Header: p => <SortableHeader label={t('table.type')} {...p} />,
        Cell: (p: CellProps<SavedHeatmapSummary>) => (
          <>{p.row.original.type === 'custom' ? t('author.custom') : t('dictionary.program-singular')}</>
        ),
        width: '15%',
      },
      {
        id: 'actions',
        width: '5%',
        disableSortBy: true,
        disableGlobalFilter: true,
        Header: '',
        Cell: (p: CellProps<SavedHeatmapSummary>) => {
          const summary = p.row.original

          return (
            <MenuContainer onClick={e => e.stopPropagation()}>
              <IconMenu
                iconId='overflow-menu--horizontal'
                variant='transparent'
                menuItems={[
                  {
                    type: 'label',
                    id: 'view' as const,
                    label: t('dictionary.view'),
                  },
                  {
                    type: 'label',
                    id: 'remove' as const,
                    label: t('admin.remove'),
                    hidden: summary.type !== 'custom',
                    color: 'destructive/background',
                  },
                ]}
                onSelect={item => {
                  switch (item.id) {
                    case 'view':
                      setOpenHeatmapSummary(summary)
                      break
                    case 'remove':
                      assert(summary.type === 'custom')
                      void removeCustomHeatmap(summary.id)
                      break
                    default:
                      assertNever(item.id)
                  }
                }}
              />
            </MenuContainer>
          )
        },
      },
    ],
    [t, removeCustomHeatmap]
  )

  const tableInstance = useTable(
    { data, columns: columns, autoResetGlobalFilter: false, autoResetSortBy: false },
    useGlobalFilter,
    useSortBy
  )

  return (
    <>
      <Text size='regular' bold>
        {t('manage.heatmap.saved-heatmaps')}
      </Text>
      {data.length === 0 ? (
        <Empty
          hideIcon={true}
          title={t('manage.heatmap.saved-heatmaps-empty-title')}
          body={t('manage.heatmap.saved-heatmaps-empty-description')}
        />
      ) : (
        <TableContainer>
          <Table
            tableInstance={tableInstance}
            getRowProps={row => ({ onClick: () => setOpenHeatmapSummary(row.original) })}
          />
        </TableContainer>
      )}
      {openHeatmapSummary?.type === 'custom' ? (
        <SavedCustomHeatmapView
          heatmapId={openHeatmapSummary.id}
          onClose={() => setOpenHeatmapSummary(undefined)}
          onSave={updateLocalHeatmap}
        />
      ) : openHeatmapSummary?.type === 'program' ? (
        <ProgramHeatmap
          programId={openHeatmapSummary.programId}
          programName={openHeatmapSummary.title}
          programHeatmapIsFavorited={true}
          onClose={({ isFavorited }) => {
            setOpenHeatmapSummary(undefined)

            if (isFavorited === false) {
              removeProgramHeatmap(openHeatmapSummary.programId)
            }
          }}
        />
      ) : null}
    </>
  )
}
