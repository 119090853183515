import React, { useEffect, useRef } from 'react'
import { Auth } from 'sierra-client/core/auth'
import { useTranslation } from 'sierra-client/hooks/use-translation'
import { AuthenticationLayout } from 'sierra-client/views/authentication/authentication-layout'
import { AuthenticationContent } from 'sierra-client/views/authentication/native/components/authentication-content'
import { AuthenticationContainer } from 'sierra-client/views/authentication/native/components/common'
import { Button } from 'sierra-ui/primitives'
import { z } from 'zod'

export const AuthenticateMessage = z.object({
  name: z.literal('authenticate'),
})

export const ExternalAuthenticate: React.FC<unknown> = () => {
  const { t } = useTranslation()
  const popupRef = useRef<Window | null>(null)

  const handleSignIn = (): void => {
    if (popupRef.current === null) {
      const popup = window.open('/authenticate', '_blank', 'popup,width=1000,height=600')

      if (popup !== null) {
        popup.window.addEventListener('load', () => {
          popup.window.addEventListener('unload', () => {
            popupRef.current = null
          })
        })

        popupRef.current = popup
      }
    }
  }

  const handleMessage = (event: MessageEvent): void => {
    if (event.origin !== window.location.origin) return

    const either = AuthenticateMessage.safeParse(event.data)

    if (either.success) {
      void Auth.getInstance().synchronize()
    }
  }

  // bearer:disable javascript_lang_message_handler_origin
  useEffect(() => {
    window.addEventListener('message', handleMessage)
    return () => {
      window.removeEventListener('message', handleMessage)
      if (popupRef.current !== null) popupRef.current.close()
    }
  }, [])

  return (
    <AuthenticationLayout>
      <AuthenticationContainer>
        <AuthenticationContent>
          <Button variant='primary' onClick={handleSignIn} grow>
            {t('login.sign-in')}
          </Button>
        </AuthenticationContent>
      </AuthenticationContainer>
    </AuthenticationLayout>
  )
}
