import { useCreatePageContext } from 'sierra-client/views/flexible-content/create-page-context'
import { LiveContentId, SelfPacedContentId } from 'sierra-domain/api/nano-id'
import {
  ScopedCreateContentId,
  ScopedLiveContentId,
  ScopedSelfPacedContentId,
} from 'sierra-domain/collaboration/types'

export function useContentIds(): { selfPacedContentId?: SelfPacedContentId; liveContentId?: LiveContentId } {
  const { scopedCreateContentId } = useCreatePageContext()
  return {
    liveContentId: ScopedCreateContentId.isLiveContentId(scopedCreateContentId)
      ? ScopedLiveContentId.extractId(scopedCreateContentId)
      : undefined,
    selfPacedContentId: ScopedCreateContentId.isSelfPacedId(scopedCreateContentId)
      ? ScopedSelfPacedContentId.extractId(scopedCreateContentId)
      : undefined,
  }
}
