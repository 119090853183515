import { UseMutationResult, UseQueryResult } from '@tanstack/react-query'
import { queryClient } from 'sierra-client/api/query-client'
import { getCachedQueryKey, useCachedQuery, useTypedMutation } from 'sierra-client/state/api'
import { CreateContentId, NanoId12 } from 'sierra-domain/api/nano-id'
import { RequestError } from 'sierra-domain/error'
import {
  ClearHomeworkAdminsToNotifyRequest,
  GetHomeworkAdminsToNotifyResponse,
  GetHomeworkAssignedReviewersResponse,
  MutateHomeworkAdminToNotifyRequest,
  MutateHomeworkReviewerRequest,
} from 'sierra-domain/filter/request'
import {
  XRealtimeAdminHomeworkClearHomeworkAdminsToNotify,
  XRealtimeAdminHomeworkGetHomeworkAdminsToNotify,
  XRealtimeAdminHomeworkGetHomeworkAssignedReviewers,
  XRealtimeAdminHomeworkMutateHomeworkAdminToNotify,
  XRealtimeAdminHomeworkMutateHomeworkReviewer,
} from 'sierra-domain/routes'
import { assertNever, isDefined } from 'sierra-domain/utils'

const REFETCH_INTERVAL_MS = 10 * 1000

export const useHomeworkReviewersQuery = ({
  courseId,
  fileId,
}: {
  courseId: CreateContentId
  fileId: NanoId12
}): UseQueryResult<GetHomeworkAssignedReviewersResponse> => {
  const query = useCachedQuery(
    XRealtimeAdminHomeworkGetHomeworkAssignedReviewers,
    {
      courseId,
      fileId,
    },
    { refetchInterval: REFETCH_INTERVAL_MS }
  )
  return query
}

export const useHomeworkReviewerMutation = ({
  courseId,
  fileId,
}: {
  courseId: CreateContentId
  fileId: NanoId12
}): UseMutationResult<null, RequestError, MutateHomeworkReviewerRequest> => {
  const mutation = useTypedMutation(XRealtimeAdminHomeworkMutateHomeworkReviewer, {
    onMutate: async variable => {
      const queryKey = getCachedQueryKey(XRealtimeAdminHomeworkGetHomeworkAssignedReviewers, {
        courseId,
        fileId,
      })

      await queryClient.cancelQueries({ queryKey })

      const reviewersSnapshot = queryClient.getQueryData(queryKey)

      queryClient.setQueryData(
        queryKey,
        (
          previousReviewersResponse: GetHomeworkAssignedReviewersResponse
        ): GetHomeworkAssignedReviewersResponse => {
          const previousReviewers = previousReviewersResponse.reviewers ?? []

          switch (variable.action) {
            case 'add':
              return {
                reviewers: [...previousReviewers, variable.reviewerId],
              }
            case 'remove':
              return {
                reviewers: previousReviewers.filter(reviewerId => reviewerId !== variable.reviewerId),
              }
            default:
              assertNever(variable.action)
          }
        }
      )

      return { reviewersSnapshot }
    },
    onError: (error, variable, context) => {
      const queryKey = getCachedQueryKey(XRealtimeAdminHomeworkGetHomeworkAssignedReviewers, {
        courseId,
        fileId,
      })
      if (isDefined(context)) {
        queryClient.setQueryData(queryKey, context.reviewersSnapshot)
      }
    },
    onSettled: async () => {
      const queryKey = getCachedQueryKey(XRealtimeAdminHomeworkGetHomeworkAssignedReviewers, {
        courseId,
        fileId,
      })
      await queryClient.invalidateQueries({ queryKey })
    },
  })

  return mutation
}

export const useHomeworkAdminsToNotifyQuery = ({
  courseId,
  fileId,
}: {
  courseId: CreateContentId
  fileId: NanoId12
}): UseQueryResult<GetHomeworkAdminsToNotifyResponse> => {
  const query = useCachedQuery(
    XRealtimeAdminHomeworkGetHomeworkAdminsToNotify,
    {
      courseId,
      fileId,
    },
    { refetchInterval: REFETCH_INTERVAL_MS }
  )
  return query
}

export const useHomeworkAdminsToNotifyMutation = ({
  courseId,
  fileId,
}: {
  courseId: CreateContentId
  fileId: NanoId12
}): UseMutationResult<null, RequestError, MutateHomeworkAdminToNotifyRequest> => {
  const mutation = useTypedMutation(XRealtimeAdminHomeworkMutateHomeworkAdminToNotify, {
    onMutate: async variable => {
      const queryKey = getCachedQueryKey(XRealtimeAdminHomeworkGetHomeworkAdminsToNotify, {
        courseId,
        fileId,
      })

      await queryClient.cancelQueries({ queryKey })

      const adminsToNotifySnapshot = queryClient.getQueryData(queryKey)

      queryClient.setQueryData(
        queryKey,
        (
          previousAdminsToNotifyResponse: GetHomeworkAdminsToNotifyResponse
        ): GetHomeworkAdminsToNotifyResponse => {
          const previousAdmins = previousAdminsToNotifyResponse.admins ?? []

          switch (variable.action) {
            case 'add':
              return {
                admins: [...previousAdmins, variable.userId],
              }
            case 'remove':
              return {
                admins: previousAdmins.filter(reviewerId => reviewerId !== variable.userId),
              }
            default:
              assertNever(variable.action)
          }
        }
      )

      return { adminsToNotifySnapshot }
    },
    onError: (error, variable, context) => {
      const queryKey = getCachedQueryKey(XRealtimeAdminHomeworkGetHomeworkAdminsToNotify, {
        courseId,
        fileId,
      })
      if (isDefined(context)) {
        queryClient.setQueryData(queryKey, context.adminsToNotifySnapshot)
      }
    },
    onSettled: async () => {
      const queryKey = getCachedQueryKey(XRealtimeAdminHomeworkGetHomeworkAdminsToNotify, {
        courseId,
        fileId,
      })
      await queryClient.invalidateQueries({ queryKey })
    },
  })
  return mutation
}

export const useClearHomeworkAdminsToNotifyMutation = ({
  courseId,
  fileId,
}: {
  courseId: CreateContentId
  fileId: NanoId12
}): UseMutationResult<null, RequestError, ClearHomeworkAdminsToNotifyRequest> => {
  const mutation = useTypedMutation(XRealtimeAdminHomeworkClearHomeworkAdminsToNotify, {
    onMutate: async () => {
      const queryKey = getCachedQueryKey(XRealtimeAdminHomeworkGetHomeworkAdminsToNotify, {
        courseId,
        fileId,
      })

      await queryClient.cancelQueries({ queryKey })

      const adminsToNotifySnapshot = queryClient.getQueryData(queryKey)

      queryClient.setQueryData(queryKey, (): GetHomeworkAdminsToNotifyResponse => {
        return { admins: [] }
      })

      return { adminsToNotifySnapshot }
    },
    onError: (error, variable, context) => {
      const queryKey = getCachedQueryKey(XRealtimeAdminHomeworkGetHomeworkAdminsToNotify, {
        courseId,
        fileId,
      })
      if (isDefined(context)) {
        queryClient.setQueryData(queryKey, context.adminsToNotifySnapshot)
      }
    },
    onSettled: async () => {
      const queryKey = getCachedQueryKey(XRealtimeAdminHomeworkGetHomeworkAdminsToNotify, {
        courseId,
        fileId,
      })
      await queryClient.invalidateQueries({ queryKey })
    },
  })
  return mutation
}
