/* eslint-disable @typescript-eslint/no-explicit-any */
import FormData from 'form-data'
import fetch from 'isomorphic-unfetch'
import _ from 'lodash'
import { restrictCallCount } from 'sierra-client/api/restrict-call-count'
import { config, getFlag } from 'sierra-client/config/global-config'
import { Auth } from 'sierra-client/core/auth'
import { requestChaosTest } from 'sierra-client/debug/chaos-mode'
import { logger } from 'sierra-client/logger/logger'
import { getGlobalRouter } from 'sierra-client/router'
import { getLastItem } from 'sierra-client/utils/array.utils'
import { AuthorUploadFileRequest } from 'sierra-domain/api/author-v2'
import { AnyZodRoute, ZodRouteInput, ZodRouteOutput, defaultRouteOptions } from 'sierra-domain/api/types'
import { AssetContext } from 'sierra-domain/asset-context'
import { Either, isLeft, left, right } from 'sierra-domain/either'
import { RequestError } from 'sierra-domain/error'
import { XRealtimeAuthorUploadFile } from 'sierra-domain/routes'
import { assertNever, iife } from 'sierra-domain/utils'
import { encode as encodeExtension, formatErrors } from 'sierra-domain/zod-extensions'
import { z } from 'zod'

export type FetchParams = Omit<RequestInit, 'method' | 'headers' | 'body' | 'credentials' | 'mode'>

async function callPlain(
  method: string,
  base: undefined | string,
  path: string,
  payload: Record<string, any> | FormData,
  token?: string,
  fetchParams?: FetchParams
): Promise<any> {
  const headers: { [key: string]: string } = {
    Accept: 'application/json',
  }

  let body: string | FormData
  if (payload instanceof FormData) {
    body = payload
  } else {
    headers['Content-Type'] = 'application/json'
    body = JSON.stringify(payload)
    restrictCallCount(`${path}-${body}`)
  }

  if (process.env.GITHUB_SHA !== undefined) {
    headers['Release-Version'] = process.env.GITHUB_SHA
  }

  if (process.env.GIT_TIMESTAMP !== undefined) {
    headers['Release-Timestamp'] = process.env.GIT_TIMESTAMP
  }

  if (token !== undefined) headers['Authorization'] = `Bearer ${token}`

  headers['Client-Path'] = window.location.pathname

  const topMatchedRouteId = getLastItem(getGlobalRouter().state.matches)?.routeId
  if (topMatchedRouteId !== undefined) {
    headers['Client-Route-Id'] = topMatchedRouteId
  }

  const url = base !== undefined ? `${base}${path}` : path

  let response

  if (config.scorm.isScorm && getFlag('scorm/post-without-body-workaround')) {
    if (body instanceof FormData) {
      throw new Error('FormData is not supported with SCORM')
    }
    const init: RequestInit = {
      ...fetchParams,
      method,
      headers: {
        ...headers,
        'X-Body': btoa(body),
        'X-Path': path,
      },
    }
    if (typeof window === 'undefined') init['mode'] = 'no-cors'
    response = await fetch('/x-realtime/scorm-api', init)
  } else {
    const init: RequestInit = {
      ...fetchParams,
      method,
      headers,
      body: body as any,
      credentials: 'include',
    }
    if (typeof window === 'undefined') init['mode'] = 'no-cors'
    response = await fetch(url, init)
  }

  const text = await response.text()

  requestChaosTest.run(url)

  if (response.ok) return JSON.parse(text)

  throw new RequestError(method, path, headers, payload, response.status, text)
}

function encode<Route extends AnyZodRoute>(route: Route, input: ZodRouteInput<Route>): ZodRouteInput<Route> {
  return encodeExtension(route.request, input)
}

function encodeRequestOrFormData<Route extends AnyZodRoute>(
  route: Route,
  input: ZodRouteInput<Route> | FormData
): Record<string, unknown> | FormData {
  if (input instanceof FormData) return input
  return encode(route, input) as Record<string, unknown>
}

function reportZodError(response: unknown, path: string, errors: z.ZodError): void {
  try {
    // eslint-disable-next-line no-console
    console.group(`[postZod] ${path}`)
    // eslint-disable-next-line no-console
    console.log('Failed to parse: ', response, 'from ', path)
    // eslint-disable-next-line no-console
    console.log(
      'Issues: ',
      errors.issues.map(issue => ({
        path: issue.path,
        message: `${issue.message} got ${_.get(response, issue.path)}`,
      }))
    )
    // eslint-disable-next-line no-console
    console.groupEnd()
  } catch (e) {
    //ignore
  }
}

const ResponseErrorWithCode = z.object({
  // This is nullable in the backend, but we will ignore that (why?)
  code: z.string(),
  message: z.string(),
})
type ResponseErrorWithCode = z.infer<typeof ResponseErrorWithCode>

function decodeResponse<Route extends AnyZodRoute>(
  route: Route,
  response: unknown
): Either<ResponseErrorWithCode, ZodRouteOutput<Route>> {
  const ResponseData = route.value

  // If the backend returns an `error` property of the expected error format, the request
  // has failed. Return an unsuccessful result containing the error details.
  const errorResponseResult = z.object({ error: ResponseErrorWithCode }).safeParse(response)
  if (errorResponseResult.success) {
    return left(errorResponseResult.data.error)
  }

  const responseResult = z.object({ data: ResponseData }).safeParse(response)
  if (!responseResult.success) {
    const errors = responseResult.error

    reportZodError(response, route.path, errors)

    throw new Error(
      `Failure in zod validation of data in response from ${route.path}: ${formatErrors(errors)}`
    )
  } else {
    return right(responseResult.data.data)
  }
}

export async function postZod<Route extends AnyZodRoute>(
  route: Route,
  input: ZodRouteInput<Route> | FormData,
  fetchParams: FetchParams = {}
): Promise<Either<ResponseErrorWithCode, ZodRouteOutput<Route>>> {
  const { authentication } = { ...defaultRouteOptions, ...route.options }

  const token: string | undefined = authentication ? Auth.getInstance().getToken() : undefined

  try {
    const result: unknown = await callPlain(
      'post',
      undefined,
      route.path,
      encodeRequestOrFormData(route, input),
      token,
      fetchParams
    )

    return decodeResponse(route, result)
  } catch (error) {
    const status = error instanceof Error && 'status' in error ? error.status : undefined
    const message = error instanceof Error && 'message' in error ? error.message : undefined
    logger.info('[postZod] request error', { route: route.path, status, message, error })
    throw error
  }
}

export const downloadBlob = (blob: Blob, filename: string): void => {
  const blobUrl = window.URL.createObjectURL(blob)

  // Downloading a file requires clicking an <a/>, so create a temporary one
  const anchorEl = document.createElement('a')
  anchorEl.style.display = 'none'
  anchorEl.href = blobUrl
  anchorEl.download = filename

  document.body.appendChild(anchorEl)
  anchorEl.click()
  document.body.removeChild(anchorEl)

  window.URL.revokeObjectURL(blobUrl)
}

export type FileUploadResult = { urlId: string; name: string; size: number }

export async function uploadFile(file: File, assetContext: AssetContext): Promise<FileUploadResult> {
  const { name, size } = file

  const data = iife((): AuthorUploadFileRequest => {
    switch (assetContext.type) {
      case 'course':
        return { type: 'course', courseId: assetContext.courseId }
      case 'path':
        return { type: 'path', pathId: assetContext.pathId }
      case 'program':
        return { type: 'program', programId: assetContext.programId }
      case 'certificate':
        return { type: 'certificate' }
      case 'tag':
        return { type: 'tag' }
      case 'courseTemplate':
      case 'organization':
      case 'organization-settings':
      case 'homework':
      case 'unknown':
      case 'pdf-image':
        return {} // This should never happen
      default:
        assertNever(assetContext)
    }
  })

  const signedUrlResult = await postZod(XRealtimeAuthorUploadFile, data)

  if (isLeft(signedUrlResult)) throw Error(JSON.stringify(signedUrlResult.left))

  const { url: sierraFilesUrl, fileId, namespacedBucketUrl } = signedUrlResult.right

  const uploadUrls: string[] = [sierraFilesUrl, namespacedBucketUrl].filter(s => s !== undefined)

  // Double write to both URLs if available
  await Promise.all(
    uploadUrls.map(async url => {
      await fetch(url, {
        method: 'PUT',
        body: file,
        headers: { 'Content-Type': file.type },
      })
    })
  )

  return { urlId: fileId, name, size }
}

export async function showUploadModal({
  onUploadStarted,
  assetContext,
}: {
  onUploadStarted: () => void
  assetContext: AssetContext
}): Promise<FileUploadResult> {
  return new Promise((resolve, reject) => {
    // Uploading a file requires creating an input tag with type "file" and clicking it
    const input: HTMLInputElement = document.createElement('input')
    input.type = 'file'
    input.onchange = async event => {
      onUploadStarted()
      const castEvent = event as unknown as { target: { files: [File] } }
      try {
        const [fileBlob] = castEvent.target.files
        const result = await uploadFile(fileBlob, assetContext)
        resolve(result)
      } catch (err) {
        reject(err)
      } finally {
        document.body.removeChild(input)
      }
    }

    input.style.display = 'none'

    document.body.appendChild(input)
    input.click()
  })
}
