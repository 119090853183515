import { isElementType } from 'sierra-client/views/v3-author/queries'
import { createQuestionCard } from 'sierra-domain/v3-author/create-blocks'
import { Editor, Node, Transforms } from 'slate'

function debug(...messages: unknown[]): void {
  console.debug('[withQuestionVariations]', ...messages)
}

export function withQuestionVariations(editor: Editor): Editor {
  const { normalizeNode } = editor

  editor.normalizeNode = entry => {
    const [node, path] = entry
    if (!isElementType('question-variations', node)) return normalizeNode(entry)

    if (node.children.length === 0) {
      const at = path.concat(0)
      debug('Adding missing question-card at', JSON.stringify(at))
      return Transforms.insertNodes(editor, createQuestionCard(), { at })
    }

    for (const [child, childPath] of Node.children(editor, path)) {
      if (!isElementType('question-card', child)) {
        debug('Removing invalid node from question-variations', JSON.stringify(childPath))

        // Instead of removing the node here it may be reasonably to wrap it in a question-card,
        // but given the complex structure of question cards it likely will not have the intended outcome.
        // For example, if there are multiple text elements as children here as a result of unwrapping
        // an unexpected node, we may get a huge number of question cards which does not make sense.
        Transforms.removeNodes(editor, { at: childPath })
        return
      }
    }

    return normalizeNode(entry)
  }
  return editor
}
