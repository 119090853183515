import { AvatarColor } from 'sierra-domain/user/avatar-color'

const colorPool: AvatarColor[] = [
  'pinkBright',
  'blueBright',
  'greenBright',
  'redVivid',
  'orangeBright',
  'purpleVivid',
  'blueVivid',
]

export const getAvatarColorAt = (index: number): AvatarColor => {
  const number = Math.floor(colorPool.length + index) % colorPool.length
  const color = colorPool[number]

  return color ?? 'pinkBright'
}
