import React, { useCallback, useMemo, useState } from 'react'
import { Column } from 'react-table'
import { SortableHeader } from 'sierra-client/components/table/sortable-header'
import { TableMediumSearchTrigger } from 'sierra-client/components/table/table-medium'
import { useTranslation } from 'sierra-client/hooks/use-translation'
import { getGlobalRouter } from 'sierra-client/router'
import { mapXApiToCsv, useManageXApi } from 'sierra-client/views/manage/api/use-manage-x-api'
import {
  ManageTableSmall,
  useManageTableSmall,
} from 'sierra-client/views/manage/components/manage-table-small'
import { SmallTableWrapper } from 'sierra-client/views/manage/components/small-table-wrapper'
import { XApiServerResponse } from 'sierra-domain/api/x-api'
import { Button } from 'sierra-ui/primitives'

export const ManageXApi: React.FC = () => {
  const { t } = useTranslation()
  const [isSearchOpen, setIsSearchOpen] = useState(false)

  const { xApiServers, isLoading } = useManageXApi()

  const goToApiDetails = useCallback((serverId: string) => {
    void getGlobalRouter().navigate({ to: `/manage/x-api/${serverId}` })
  }, [])

  const createApiClient = async (): Promise<void> => {
    await getGlobalRouter().navigate({
      to: '/manage/x-api/$xApiServerId',
      params: { xApiServerId: 'new' },
      replace: true,
    })
  }

  const columns: Array<Column<XApiServerResponse>> = useMemo(
    () => [
      {
        Header: p => (
          <>
            {/* <SelectableHeader {...p} /> */}
            <SortableHeader label={t('table.name')} {...p} />
          </>
        ),
        accessor: 'name',
        Cell: p => {
          return (
            <>
              {/* <SelectableRow {...p} /> */}
              {p.row.original.name}
            </>
          )
        },
        width: '20%',
      },
      {
        Header: t('dictionary.description'),
        accessor: 'description',
        width: '20%',
      },
      {
        Header: t('manage.x-api.client-id'),
        accessor: 'clientId',
        width: '20%',
      },
      {
        Header: t('manage.x-api.actor-type'),
        accessor: 'actorType',
        width: '20%',
      },
      {
        Header: t('manage.x-api.statements-url'),
        accessor: 'statementsUrl',
        width: '20%',
      },
      {
        id: 'actions',
        width: '10%',
        Header: <TableMediumSearchTrigger onClick={() => setIsSearchOpen(true)} />,
        Cell: '',
      },
    ],
    [t]
  )
  const { tableInstance } = useManageTableSmall({
    tableOptions: { data: xApiServers, columns: columns },
    getEntityId: c => c.serverId,
  })

  return (
    <SmallTableWrapper title={t('manage.x-api.title')} subtitle={t('manage.x-api.subtitle')}>
      <ManageTableSmall
        tableInstance={tableInstance}
        onViewDetails={goToApiDetails}
        getEntityId={c => c.serverId}
        mapEntityToCsv={mapXApiToCsv}
        isSearchOpen={isSearchOpen}
        searchTrigger={setIsSearchOpen}
        translations={{
          tableLoading: t('manage.x-api.table-loading'),
          searchPlaceholder: 't.todo-search-placeholder',
          tableNoResults: t('manage.x-api.no-results'),
          csvPrefix: t('manage.x-api.title'),
        }}
        isLoading={isLoading}
        focusedId={undefined}
        bulkActions={<></>}
        footerButton={<Button onClick={createApiClient}>{t('manage.x-api.create-new')}</Button>}
      />
    </SmallTableWrapper>
  )
}
