import _ from 'lodash'
import { isElementType, parentType } from 'sierra-client/views/v3-author/queries'
import { hasOnlyEmptyTextInNodes } from 'sierra-domain/slate-util'
import { isValidInteractiveCardChild } from 'sierra-domain/v3-author'
import {
  createFlipCard,
  createFlipCardBack,
  createFlipCardFront,
  createFlipCardsContainer,
  createParagraph,
} from 'sierra-domain/v3-author/create-blocks'
import { Editor, Element, Node, Range, Text, Transforms } from 'slate'

const debug = (...messages: unknown[]): void => console.debug('[withFlipCards]', ...messages)

const validFlipCardSideChildren = ['paragraph', 'heading', 'bulleted-list', 'numbered-list']

const isFlipCardFront = isElementType('flip-card-front')
const isFlipCardBack = isElementType('flip-card-back')
const isFlipCardFrontOrBack = isElementType(['flip-card-front', 'flip-card-back'])

const mergeFlipCardSides = (flipCardNode: Node): Node => {
  if (!isElementType('flip-card', flipCardNode)) return flipCardNode

  const frontChildren = flipCardNode.children.flatMap(n => (isFlipCardFront(n) ? n.children : []))
  const backChildren = flipCardNode.children.flatMap(n => (isFlipCardBack(n) ? n.children : []))

  return {
    ...flipCardNode,
    children: [
      createFlipCardFront({ children: frontChildren }),
      createFlipCardBack({ children: backChildren }),
    ],
  }
}

export const withFlipCardSides = (editor: Editor): Editor => {
  const { normalizeNode } = editor
  editor.normalizeNode = entry => {
    const [node, path] = entry
    if (isFlipCardFrontOrBack(node)) {
      if (parentType(editor, path) !== 'flip-card') {
        debug(
          `flip-card-back and flip-card-front must be the direct child of a flip-card, unwrapping at`,
          JSON.stringify(path)
        )
        return Transforms.unwrapNodes(editor, { at: path, voids: true, mode: 'all' })
      }

      const children = Array.from(Node.children(editor, path))

      for (const [child, childPath] of children) {
        if (Element.isElement(child) && !validFlipCardSideChildren.includes(child.type)) {
          debug(
            `converting unexpected ${child.type} in ${node.type} to paragraph at`,
            JSON.stringify(childPath)
          )
          return Transforms.wrapNodes(editor, createParagraph(), { at: childPath })
        } else if (Text.isText(child)) {
          debug(
            `converting unexpected text child ${JSON.stringify(child)} in ${node.type} to paragraph at`,
            JSON.stringify(childPath)
          )
          return Transforms.wrapNodes(editor, createParagraph(), { at: childPath })
        }
      }
    }
    normalizeNode(entry)
  }
  return editor
}

export const withFlipCard = (editor: Editor): Editor => {
  const { normalizeNode, deleteBackward } = editor

  editor.normalizeNode = entry => {
    const [node, path] = entry

    if (isElementType('flip-card', node)) {
      if (parentType(editor, path) !== 'flip-cards-card-container') {
        debug(
          `flip-card must be the direct child of a flip-cards-card-container, unwrapping at`,
          JSON.stringify(path)
        )
        return Transforms.unwrapNodes(editor, { at: path, voids: true, mode: 'all' })
      }

      const children = Array.from(Node.children(editor, path))

      for (const [child, childPath] of children) {
        if (!isFlipCardFrontOrBack(child)) {
          debug(`Removing unexpected element from flip-card at`, JSON.stringify(childPath))
          return Transforms.removeNodes(editor, { at: childPath, voids: true })
        }
      }

      if (children.length === 0) {
        const at = path.concat(0)
        debug(`Adding missing flip card sides at`, JSON.stringify(at))
        return Transforms.insertNodes(editor, [createFlipCardFront(), createFlipCardBack()], {
          at,
          voids: true,
        })
      }

      const numberOfFronts = children.reduce((acc, [n]) => (isFlipCardFront(n) ? acc + 1 : acc), 0)
      const numberOfBacks = children.reduce((acc, [n]) => (isFlipCardBack(n) ? acc + 1 : acc), 0)

      if (numberOfFronts > 1 || numberOfBacks > 1) {
        debug(`Merging ${numberOfFronts} duplicated flip-card-front nodes at`, path)
        const mergedFlipCard = mergeFlipCardSides(node)

        Transforms.removeNodes(editor, {
          at: path,
        })
        return Transforms.insertNodes(editor, mergedFlipCard, {
          at: path,
        })
      }

      if (numberOfFronts === 0) {
        debug(`Found ${numberOfFronts} flip-card-front nodes, inserting one at `, path.concat(1))
        return Transforms.insertNodes(editor, createFlipCardFront(), {
          at: path.concat(1),
          voids: true,
        })
      }

      if (numberOfBacks === 0) {
        debug(`Found ${numberOfBacks} flip-card-back nodes, inserting one at `, path.concat(1))
        return Transforms.insertNodes(editor, createFlipCardBack(), {
          at: path.concat(1),
        })
      }
    }

    normalizeNode(entry)
  }

  editor.deleteBackward = unit => {
    const { selection } = editor
    if (selection && Range.isCollapsed(selection)) {
      const [flipCard] = Editor.nodes(editor, {
        match: isElementType('flip-card'),
      })
      if (flipCard !== undefined) {
        const isEmpty = hasOnlyEmptyTextInNodes([flipCard[0]])
        if (isEmpty) return Transforms.removeNodes(editor, { at: flipCard[1] })
      }

      return deleteBackward(unit)
    }
  }
  return editor
}

export const withFlipCardsCardContainer = (editor: Editor): Editor => {
  const { normalizeNode } = editor

  editor.normalizeNode = entry => {
    const [node, path] = entry

    if (isElementType('flip-cards-card-container', node)) {
      if (parentType(editor, path) !== 'flip-cards') {
        debug(
          `flip-cards-card-container must be the direct child of a flip-cards, unwrapping at`,
          JSON.stringify(path)
        )
        return Transforms.unwrapNodes(editor, { at: path, voids: true, mode: 'all' })
      }

      const children = Array.from(Node.children(editor, path))
      for (const [child, childPath] of children) {
        if (!isElementType('flip-card', child)) {
          debug(`Removing unexpected element from flip-cards-card-container at`, JSON.stringify(childPath))
          return Transforms.removeNodes(editor, { at: childPath, voids: true })
        }
      }

      if (children.length === 0) {
        const at = path.concat(0)
        debug(`Adding missing flip-card at`, JSON.stringify(at))
        return Transforms.insertNodes(editor, createFlipCard(), { at, voids: true })
      }
    }

    normalizeNode(entry)
  }

  return editor
}

export const withFlipCards = (editor: Editor): Editor => {
  const { normalizeNode } = editor

  editor.normalizeNode = entry => {
    const [node, path] = entry

    if (isElementType('flip-cards', node)) {
      const children = Array.from(Node.children(editor, path))

      const flipCardContainerIndex = children.findIndex(([child]) =>
        isElementType('flip-cards-card-container', child)
      )

      if (flipCardContainerIndex === -1) {
        const at = path.concat(children.length)
        debug(`Adding missing flip-cards-card-container at`, JSON.stringify(at))
        return Transforms.insertNodes(editor, createFlipCardsContainer(), { at })
      }

      for (const [child, childPath] of children) {
        // Remove invalid flip card children
        if (!(isElementType('flip-cards-card-container', child) || isValidInteractiveCardChild(child))) {
          return Transforms.removeNodes(editor, { at: childPath })
        }

        // Only keep the first flip-cards-card-container
        const index = _.last(childPath) ?? 0
        if (isElementType('flip-cards-card-container', child) && index > flipCardContainerIndex) {
          debug(`Removing duplicate flip-cards-card-container at`, childPath)
          return Transforms.removeNodes(editor, { at: childPath })
        }
      }
    }
    normalizeNode(entry)
  }

  return editor
}
