import fuzzysort from 'fuzzysort'
import _ from 'lodash'
import React, { useCallback, useEffect, useMemo, useRef, useState } from 'react'
import { useListEditableContent } from 'sierra-client/api/hooks/use-editable-content'
import { useNonExpiringNotif, useNotif } from 'sierra-client/components/common/notifications'
import { useShortcutMenuDispatch } from 'sierra-client/components/shortcut-menu/context'
import { resolveIndex } from 'sierra-client/components/shortcut-menu/resolve-index'
import {
  SearchBar,
  SearchBarContainer,
  SearchContainer,
  SearchLoading,
  SearchResult,
  SearchResults,
} from 'sierra-client/components/shortcut-menu/search-ui'
import { useDebouncedAndLiveState } from 'sierra-client/hooks/use-debounced-state'
import { useTranslation } from 'sierra-client/hooks/use-translation'
import { EditableContent } from 'sierra-domain/api/editable-content'
import { assertNever, iife } from 'sierra-domain/utils'

interface SearchContentProps<T> {
  numberOfCoursesToDisplay: number
  ContentComponent: React.FC<EditableContent>
  onContentSelected: (content: EditableContent) => Promise<T>
  successMessage: (result: T) => string
}

export const SearchContent = <T,>({
  numberOfCoursesToDisplay,
  ContentComponent,
  onContentSelected,
  successMessage,
}: SearchContentProps<T>): React.JSX.Element => {
  const [searchText, liveSearchText, setSearchText] = useDebouncedAndLiveState<string>('', { wait: 32 })
  const [rawSelectedIndex, setSelectedIndex] = useState(0)
  const editableContentQuery = useListEditableContent()
  const dispatch = useShortcutMenuDispatch()

  // Reset index once results have loaded
  useEffect(() => setSelectedIndex(0), [editableContentQuery.isSuccess])

  const { t } = useTranslation()
  const searchKeywords = useCallback(
    (c: EditableContent): string[] => {
      const title = c.title === '' ? t('admin.author.no-title') : c.title

      const meta = iife(() => {
        switch (c.type) {
          case 'native:self-paced':
            return ['self-paced', 'self paced']
          case 'native:live':
            return ['live-content', 'live content']
          case 'path':
            return ['path']
          default:
            assertNever(c)
        }
      })

      return [title, ...meta].map(it => it.toLocaleLowerCase().trim())
    },
    [t]
  )

  const searchData: (EditableContent & { search: string })[] | undefined = useMemo(() => {
    if (editableContentQuery.isSuccess)
      return editableContentQuery.data.content.map(it => ({ ...it, search: searchKeywords(it).join('') }))
    else return undefined
  }, [editableContentQuery.isSuccess, editableContentQuery.data, searchKeywords])

  const searchResults = useMemo(() => {
    if (searchData === undefined) return undefined

    const searchResults = fuzzysort.go(searchText, searchData, { key: 'search' }).map(({ obj }) => obj)
    const results = searchText.length === 0 ? searchData : searchResults
    return _.take(results, numberOfCoursesToDisplay)
  }, [searchText, searchData, numberOfCoursesToDisplay])

  const selectedIndex = rawSelectedIndex % (searchResults?.length ?? 0)

  const inputRef = useRef<HTMLInputElement | null>(null)
  useEffect(() => inputRef.current?.focus(), [inputRef])

  const notif = useNotif()
  const { push, remove } = useNonExpiringNotif()
  const notificationId = useRef<string>()

  const exportContent = useCallback(
    async (content: EditableContent): Promise<void> => {
      void dispatch({ type: 'close' })
      notificationId.current = push({ type: 'custom', level: 'info', body: 'Working...' })

      await onContentSelected(content).then(response => {
        remove(notificationId.current!)
        notif.push({ type: 'custom', level: 'success', body: successMessage(response) })
      })
    },
    [dispatch, notif, push, remove, onContentSelected, successMessage]
  )

  return (
    <SearchContainer>
      <SearchBarContainer>
        <SearchBar
          value={liveSearchText}
          onChange={text => {
            setSearchText(text)
            setSelectedIndex(0)
          }}
          onIndexChanged={value => {
            setSelectedIndex(prev =>
              resolveIndex(value === 'decrement' ? prev - 1 : prev + 1, searchResults ?? [])
            )
          }}
          onResultSelected={() => {
            const c = searchResults?.[selectedIndex]
            if (c === undefined) return
            void exportContent(c)
          }}
        />
      </SearchBarContainer>

      <SearchResults>
        {!editableContentQuery.isSuccess ? (
          <SearchLoading />
        ) : (
          searchResults?.map((c, index) => (
            <SearchResult
              onClick={() => {
                setSelectedIndex(index)
                void exportContent(c)
              }}
              key={c.id}
              $selected={index === selectedIndex}
            >
              <ContentComponent {...c} />
            </SearchResult>
          ))
        )}
      </SearchResults>
    </SearchContainer>
  )
}
