import _ from 'lodash'
import { PollOptionData } from 'sierra-client/state/interactive-card-data/poll-card'
import { InteractivePollConfig } from 'sierra-client/views/v3-author/poll-card/types'
import { NanoId12 } from 'sierra-domain/api/nano-id'
import { UserId } from 'sierra-domain/api/uuid'
import { BreakoutRoom } from 'sierra-domain/live-session'
import { nanoid12 } from 'sierra-domain/nanoid-extensions'

const filterEmptyRooms = (rooms: BreakoutRoom[]): BreakoutRoom[] =>
  rooms.filter(({ participants }) => participants.length > 0)

// Spread those of participants not already in any room evenly across rooms
const placeNotPlaced = (rooms: BreakoutRoom[], participants: UserId[]): BreakoutRoom[] => {
  const notInAnyRoom = _.uniq(participants.filter(p => !rooms.flatMap(room => room.participants).includes(p)))

  for (const participant of notInAnyRoom) {
    // Find the smallest room and move this participant there. Prefer non-empty rooms,
    // but if there is none, go for non-empty rooms (which are sorted as having Number.MAX_SAFE_INTEGER)
    // participants.
    const smallestRoom = _.sortBy(rooms, room =>
      room.participants.length > 0 ? room.participants.length : Number.MAX_SAFE_INTEGER
    )[0]

    if (smallestRoom !== undefined) {
      smallestRoom.participants.push(participant)
    }
  }
  return rooms
}

const metric = (numParticipants: number, numRooms: number, desiredRoomSize: number): number => {
  const particpantsPerRoom = Math.floor(numParticipants / numRooms)
  const numRoomsWithExtraParticipant = numParticipants % numRooms

  if (numParticipants > 1 && desiredRoomSize > 1 && particpantsPerRoom <= 1) {
    // If we have a meaningful room, we don't want rooms with one person in them
    return Number.MAX_SAFE_INTEGER
  }

  return (
    numRoomsWithExtraParticipant * Math.abs(particpantsPerRoom + 1 - desiredRoomSize) +
    (numRooms - numRoomsWithExtraParticipant) * Math.abs(particpantsPerRoom - desiredRoomSize)
  )
}

const removeDuplicateParticipants = (rooms: BreakoutRoom[]): BreakoutRoom[] => {
  const allParticipants = new Set<string>()
  return rooms.map(room => {
    const users = _.uniq(room.participants).filter(user => !allParticipants.has(user))
    users.forEach(user => allParticipants.add(user))

    return { ...room, participants: users }
  })
}

export const ensureDesiredRoomSize = (
  rooms: BreakoutRoom[],
  desiredRoomSize: number | undefined
): BreakoutRoom[] => {
  if (desiredRoomSize === undefined) return rooms

  return rooms.flatMap(room => {
    const minNumRooms = Math.floor(room.participants.length / desiredRoomSize)
    const maxNumRooms = minNumRooms + 1

    // Which of minNumRooms and maxNumRooms gives a room size closest to our desire?
    const numRooms =
      metric(room.participants.length, minNumRooms, desiredRoomSize) <
      metric(room.participants.length, maxNumRooms, desiredRoomSize)
        ? minNumRooms
        : maxNumRooms

    if (numRooms === 1) return room

    const particpantsPerRoom = Math.floor(room.participants.length / numRooms)
    const numRoomsWithExtraParticipant = room.participants.length % numRooms

    const newParticipants: UserId[][] = []
    let numParticipantsPlaced = 0
    for (let i = 0; i < numRooms; i++) {
      if (i < numRoomsWithExtraParticipant) {
        newParticipants.push(
          room.participants.slice(numParticipantsPlaced, numParticipantsPlaced + particpantsPerRoom + 1)
        )
        numParticipantsPlaced += particpantsPerRoom + 1
      } else {
        newParticipants.push(
          room.participants.slice(numParticipantsPlaced, numParticipantsPlaced + particpantsPerRoom)
        )
        numParticipantsPlaced += particpantsPerRoom
      }
    }

    return newParticipants.map((ps, index) => ({
      id: nanoid12(),
      title: `${room.title} ${index + 1}`,
      participants: ps,
    }))
  })
}

export const createBasedOnPoll = (
  { choices }: Pick<InteractivePollConfig, 'choices'>,
  pollResponses: PollOptionData[] | undefined,
  participants: UserId[],
  desiredRoomSize: number | undefined = undefined
): BreakoutRoom[] => {
  const rooms = choices.map<BreakoutRoom>(choice => {
    const choiceParticipants: UserId[] =
      pollResponses
        ?.find(response => response.optionId === choice.id)
        ?.votedByUserIds.filter(userId => participants.includes(userId)) ?? []

    return {
      id: nanoid12(),
      title: choice.description,
      participants: choiceParticipants,
    }
  })

  return ensureDesiredRoomSize(filterEmptyRooms(removeDuplicateParticipants(rooms)), desiredRoomSize)
}

export const createRandomly = (participants: UserId[], desiredRoomSize: number): BreakoutRoom[] => {
  // Create one big room with all participants in random order and then ensure desired
  // room size on it
  return ensureDesiredRoomSize(
    [
      {
        id: nanoid12(),
        title: 'Random',
        participants: _.chain(participants).uniq().shuffle().value(),
      },
    ],
    desiredRoomSize
  )
}

export const createFromPrevious = (participants: UserId[], previousRooms: BreakoutRoom[]): BreakoutRoom[] => {
  // Create new rooms with the same participants as the previous rooms but new ids
  const newRooms = _.cloneDeep(previousRooms).map(room => ({ ...room, id: nanoid12() }))
  return placeNotPlaced(newRooms, participants)
}

export const moveParticipant = (
  rooms: BreakoutRoom[],
  participant: UserId,
  newRoom?: NanoId12 | undefined
): BreakoutRoom[] => {
  if (newRoom !== undefined) {
    const room = rooms.find(room => room.id === newRoom)
    if (room === undefined) return rooms
    if (room.participants.includes(participant)) return rooms
  }

  return rooms.map(room => {
    if (room.id !== newRoom) {
      return { ...room, participants: room.participants.filter(p => p !== participant) }
    } else {
      return { ...room, participants: [...room.participants, participant] }
    }
  })
}

export const addNewBreakoutRoom = (rooms: BreakoutRoom[], name?: string): BreakoutRoom[] => {
  return [...rooms, { id: nanoid12(), title: name ?? `Room ${rooms.length + 1}`, participants: [] }]
}

export const renameBreakoutRoom = (rooms: BreakoutRoom[], id: NanoId12, newName: string): BreakoutRoom[] => {
  return rooms.map(room => (room.id === id ? { ...room, title: newName } : room))
}

export const unassignParticipant = (rooms: BreakoutRoom[], participant: UserId): BreakoutRoom[] => {
  return rooms.map(room => {
    return { ...room, participants: room.participants.filter(p => p !== participant) }
  })
}
