import {
  isDenoiserSupported,
  isPictureInPictureSupported,
} from 'sierra-client/components/liveV2/services/video-call-service'
import { atomWithStorage } from 'sierra-client/state/storage'

export const pictureInPictureEnabledAtom = atomWithStorage(
  'livePictureInPictureEnabled',
  isPictureInPictureSupported
)
export const noiseCancellationEnabledAtom = atomWithStorage(
  'liveNoiseCancellationEnabled',
  isDenoiserSupported
)
export const backgroundBlurEnabledAtom = atomWithStorage('liveBackgroundBlurEnabled', false)
export const pushToTalkEnabledAtom = atomWithStorage('livePushToTalkEnabled', false)
