import { Pill, PillRow } from 'sierra-client/components/common/pill'
import { tagsDataSelectors } from 'sierra-client/state/content/selectors'
import { useSelector } from 'sierra-client/state/hooks'
import { TagId } from 'sierra-domain/api/nano-id'

export const TagsBasic: React.FC<{ tagIds: (TagId | undefined)[]; onClick?: (id: string) => void }> = ({
  tagIds,
  onClick,
}) => {
  const tags = useSelector(tagsDataSelectors.selectEntities)

  return (
    <PillRow $multiline>
      {tagIds.flatMap(id => {
        if (id === undefined) return []
        const tag = tags[id]

        if (tag === undefined) return []

        return [
          <Pill key={id} onClick={onClick !== undefined ? () => onClick(id) : undefined}>
            {tag.data.name}
          </Pill>,
        ]
      })}
    </PillRow>
  )
}
