import { Dispatch, SetStateAction, useCallback, useEffect, useMemo, useState } from 'react'
import { useDebouncedAndLiveState } from 'sierra-client/hooks/use-debounced-state'
import { usePost } from 'sierra-client/hooks/use-post'
import {
  LinkedInCourseRecord,
  LinkedInLearningAssetsRequest,
  LinkedInLearningAssetsResponse,
} from 'sierra-domain/api/partner'
import {
  XRealtimePartnerLinkedinImport,
  XRealtimePartnerLinkedinImportedUrns,
  XRealtimePartnerLinkedinLearningAssets,
} from 'sierra-domain/routes'

export type LinkedInImportStatus = 'imported' | 'selected' | undefined
export type PartnerCourseRecordWithStatus = LinkedInCourseRecord & {
  importStatus: LinkedInImportStatus
}

type UseLinkedInCoursesData = {
  data: Array<PartnerCourseRecordWithStatus>
  isImporting: boolean
  isLoading: boolean
  hasMore: boolean
  debouncedFilter: LinkedInLearningAssetsRequest['filter']
  liveFilter: LinkedInLearningAssetsRequest['filter']
  importedIds: Record<string, LinkedInImportStatus>
  toggleSelectedCourse: (urn: string) => void
  fetchLinkedInData: (req: LinkedInLearningAssetsRequest) => Promise<LinkedInLearningAssetsResponse>
  importLinkedInCourses: (urns: string[]) => Promise<void>
  setFilter: Dispatch<SetStateAction<LinkedInLearningAssetsRequest['filter']>>
  next: () => void
}

export const useLinkedInCourses = (): UseLinkedInCoursesData => {
  const { postWithUserErrorException } = usePost()

  const [debouncedFilter, liveFilter, setFilter] = useDebouncedAndLiveState<
    LinkedInLearningAssetsRequest['filter']
  >(
    {
      difficultyLevels: [],
      filterAssetTypes: ['COURSE'],
      filterKeyword: '',
      filterTargetLocaleLanguage: 'en',
      presentationSortBy: 'RELEVANCE',
    },
    { wait: 500 }
  )
  const [data, setData] = useState<LinkedInCourseRecord[]>([])
  const [isLoading, setIsLoading] = useState(false)
  const [isImporting, setIsImporting] = useState(false)
  const [hasMore, setHasMore] = useState(true)
  const [importedIds, setImportedIds] = useState<UseLinkedInCoursesData['importedIds']>({})

  // Fetching

  const fetchImportedUrns = useCallback(async () => {
    const response = await postWithUserErrorException(XRealtimePartnerLinkedinImportedUrns, {})
    const mapped = response.urns.reduce(
      (acc, urn) => {
        acc[urn] = 'imported'
        return acc
      },
      {} as Record<string, LinkedInImportStatus>
    )
    setImportedIds(mapped)
  }, [postWithUserErrorException])

  const fetchLinkedInData = useCallback<UseLinkedInCoursesData['fetchLinkedInData']>(
    async liRequest => {
      setIsLoading(true)
      const response = await postWithUserErrorException(XRealtimePartnerLinkedinLearningAssets, liRequest)
      setData(data => data.concat(response.courses))
      setHasMore(response.paging.start + response.paging.count < response.paging.total)
      // Add await so inViewRef does not trigger another call before it finishes rendering
      setTimeout(() => setIsLoading(false), 300)

      return response
    },
    [postWithUserErrorException]
  )

  const importLinkedInCourses = useCallback<UseLinkedInCoursesData['importLinkedInCourses']>(
    async urns => {
      setIsImporting(true)
      await Promise.all(urns.map(urn => postWithUserErrorException(XRealtimePartnerLinkedinImport, { urn })))
      await fetchImportedUrns()
      setIsImporting(false)
    },
    [fetchImportedUrns, postWithUserErrorException]
  )

  // Selection

  const dataWithStatus = useMemo(
    () => data.map(item => ({ ...item, importStatus: importedIds[item.urn] })),
    [data, importedIds]
  )

  const toggleSelectedCourse = useCallback<UseLinkedInCoursesData['toggleSelectedCourse']>(urn => {
    setImportedIds(ids => {
      const current = ids[urn]
      if (current === 'imported') return ids
      const next: LinkedInImportStatus = current === 'selected' ? undefined : 'selected'
      return { ...ids, [urn]: next }
    })
  }, [])

  const next = async (): Promise<void> => {
    await fetchLinkedInData({
      filter: liveFilter,
      paging: { start: data.length, count: 20 },
    })
  }

  // Triggers

  useEffect(() => {
    setData([]) // Resets data for another query
    void fetchLinkedInData({ filter: debouncedFilter, paging: { start: 0, count: 20 } })
  }, [debouncedFilter, fetchLinkedInData])

  useEffect(() => {
    void fetchImportedUrns()
  }, [fetchImportedUrns])

  return {
    data: dataWithStatus,
    isLoading,
    hasMore,
    importedIds,
    debouncedFilter,
    liveFilter,
    isImporting,
    setFilter,
    importLinkedInCourses,
    toggleSelectedCourse,
    fetchLinkedInData,
    next,
  }
}
