import React, { InputHTMLAttributes, useRef, useState } from 'react'
import { usePost } from 'sierra-client/hooks/use-post'
import { useTranslation } from 'sierra-client/hooks/use-translation'
import { I18nArgs } from 'sierra-client/hooks/use-translation/types'
import { XRealtimeAdminSettingsUploadFile, XRealtimeUserUploadFile } from 'sierra-domain/routes'
import { Button, ButtonProps, Text } from 'sierra-ui/primitives'
import styled from 'styled-components'

const FileInput = styled.input`
  display: none;
`

type FileButtonInputProps = {
  buttonProps: ButtonProps
  fileInputProps: InputHTMLAttributes<HTMLInputElement>
  maxSizeInMB?: number
  onChange: (resultName: string, fileName: string) => void
  id?: string
  fileType: FileType
}

type FileType = 'user' | 'organization-settings'

export const FileButtonInput: React.FC<FileButtonInputProps> = ({
  buttonProps,
  fileInputProps,
  maxSizeInMB,
  onChange,
  id,
  fileType,
}) => {
  const { postWithUserErrorException } = usePost()
  const { t } = useTranslation()

  const fileRef = useRef<HTMLInputElement>(null)
  const [error, setError] = useState<I18nArgs | undefined>(undefined)

  const handleButtonClick = (ref: React.MutableRefObject<HTMLInputElement | null>): void => {
    if (!ref.current) return
    ref.current.click()
  }

  const handleFileChange = async (e: React.ChangeEvent<HTMLInputElement>): Promise<void> => {
    const file = e.target.files ? e.target.files[0] : undefined
    if (file === undefined) return

    if (maxSizeInMB !== undefined && file.size > maxSizeInMB * 1000 * 1000) {
      setError(['settings.errors.profile-image-max-size', { size: maxSizeInMB }])
      return
    } else {
      setError(undefined)
    }

    const endpoint = fileType === 'user' ? XRealtimeUserUploadFile : XRealtimeAdminSettingsUploadFile

    const { fileId, url: uploadUrl, namespacedUrl } = await postWithUserErrorException(endpoint, {})

    if (fileType === 'user') {
      await fetch(namespacedUrl, {
        method: 'PUT',
        body: file,
        headers: { 'Content-Type': file.type },
      })
    } else {
      // Upload to all available targets
      const uploadUrls = [uploadUrl, namespacedUrl].filter(url => url !== undefined)
      await Promise.all(
        uploadUrls.map(async url => {
          await fetch(url, {
            method: 'PUT',
            body: file,
            headers: { 'Content-Type': file.type },
          })
        })
      )
    }

    onChange(fileId, file.name)
  }

  return (
    <>
      <Button {...buttonProps} onClick={() => handleButtonClick(fileRef)} id={id} />
      {error && (
        <Text color='redBright' size='small'>
          {t(...error)}
        </Text>
      )}
      <FileInput {...fileInputProps} ref={fileRef} type='file' onChange={e => handleFileChange(e)} />
    </>
  )
}
