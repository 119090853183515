import { DateTime } from 'luxon'
import { getFileContentImage } from 'sierra-client/api/content'
import { useSelector } from 'sierra-client/state/hooks'
import { selectOrganization } from 'sierra-client/state/organization/selectors'
import { createNanoId12FromUUID } from 'sierra-domain/nanoid-extensions'
import { ORGANIZATION_IDS } from 'sierra-domain/organization'

export const getImageUrl = (name: string): string =>
  getFileContentImage(`images/${name}`, { bucket: 'sierra-static' })

export const handlePrint = (): void => {
  if (window.navigator.userAgent.includes('Safari')) {
    window.document.execCommand('print', false, undefined)
  } else {
    window.print()
  }
}

export const getFormattedCompletionDate = (completionTimestamp: string): string =>
  DateTime.fromISO(completionTimestamp).setZone('local').toFormat('yyyy-MM-dd')

export const formatDateWithMonthAndYear = (timestamp: string): string =>
  DateTime.fromISO(timestamp).setZone('local').toLocaleString({ month: 'long', year: 'numeric' })

const validCertificateCourseIds = new Set<string>([
  createNanoId12FromUUID('51b974f9-e795-400f-906e-71c6a696eff2'), // English COVID-19 Course 1
  createNanoId12FromUUID('d02784d7-c12c-4546-aa6b-cee7fc2e8d64'), // English COVID-19 Course 2
  createNanoId12FromUUID('3fd7afb9-fa64-4323-a7b5-e3f57c5db3d1'), // Russian COVID-19
  createNanoId12FromUUID('2b5e7e7b-f986-4ed2-b59f-2f57928ab58f'), // Arabic COVID-19
  createNanoId12FromUUID('9634b113-f2b9-4a49-8b5c-d4be79bfdfa4'), // Persian COVID-19
  createNanoId12FromUUID('a2c97b3b-83ff-4203-9b46-ba5843910e59'), // Svenska COVID-19 Utbildning 1
  createNanoId12FromUUID('e4a01295-cf7d-4b12-9705-34795070b3ed'), // Svenska COVID-19 Utbildning 2
  createNanoId12FromUUID('b9afd8e3-bf3d-4b70-9b71-71dbe549adf0'), // Medlearn - Vårdnära service
  createNanoId12FromUUID('2d8a3845-c4e6-4ff4-92dd-60e2ac212f33'), // Novartis (Probiotics – Linex)
  'NJ7vF7f3Qt84', // Hemfrid - Basic Training for Existing Service Staff
  'thtsJVBSGUJP', // Hemfrid - Grundutbildning för Befintlig Servicepersonal
  'EPBSSuZOQ6mn', // Novartis - Strategic Imperatives Building
  'Olmmp2ygZ2nX', // Novartis - Strategic Imperatives Building
  // 'Z1ag_me0tyaQ', // Svea Solar - Personligt Fallskydd
  // 'riFBl4VYLFiS', // Svea Solar - Personal Fall Protection
  'JYsI1HGuolTX', // Merck - MERCK CARE™   Oncology Patient   Support Program
  'F8C-x9jyVEhM', // Merck - Programme de soutien aux patients MERCK SANTÉ™ – Oncologie
  // 'YGFk5HDjR8nz', // Sierra - Short Course,
  '8scxdDFJ1LLH', // Brights - Hållbarhetsacceleratorn
])

export const courseIdsForAlltOmJuridik = new Set<string>([
  'HRTJXzokGsq_',
  'IPCFKtmKngOR',
  'iRtN5vlQHN8N',
  'PJym4mujmkkI',
  'b_DXUcwGvfpL',
  'RtumrRGDIIYh',
  'tR-pdpugZQH8',
  'wLYf5k2IJ3S7',
  'Ifuj8wCckWiK',
  'Amljo8AiXW2m',
  'oBS4hJEWnBpr',
  'b5QEVvKctUik',
  'nM20EyNKa7zs',
  '1KkP5m1vhpHX',
  '_36C3yml_V-_',
  '9f3M9wZtoBJq',
  '1fKRsVSPmVsb',
  'gxaGv0na-f3l',
  'tZxI4JH5tyDp',
  'zrYxLat8L4cd',
  'bj-cIeX8cRta',
  'rOKRALCM9LUK',
  'h67wKQROY-o5',
  'nB0bHyavvT-z',
  'C9maXh3EXj4w',
  'zxlpgwh2J8uR',
  'LAVA2GOb5t0L',
  'hbRdCLULbCxz',
  'xYajRIS9bQiz',
  'aDnkjZtUe7Ms',
  'tellfwLDzIZI',
  'fMtmP_a09ByM',
  '5wisvOfAVJgu',
  'vn_yba2wA8Og',
  '-kpQUB_LryoB',
  'H-AeBKQVKUN2',
  'CopedtS0r1Nh',
  'wqRHfjtDcsEM',
  'Qu55dp4gkNeW',
  'BoKC6b4fRLbx',
  'gIq7G7sR_o8i',
  'KuFvsPHqS3TU',
  '2WSvSJTIIH-L',
  'Uj4vG65VqGwF',
  'IV4xW6nnd9Fs',
  'VXclqn8uFueA',
  'hB1A7_k7Zo4T',
  '0lFZNXRFmw3l',
  'ZS7EHqFXNKoI',
  'gH-xSmSW1NlB',
  '5QSESV3QiTas',
  're5loiHeWD_B',
  'M84XBXpOwr_b',
  '3SLodcEQXN-V',
  'uMP89-OxHZap',
  'v2S3u8vs3Ew5',
  '4CTn2DkloG6n',
  'P0qqCYsKHzNr',
  'CJE3xDPAQitp',
  '1j9sNugOC3mY',
  '-zjh4FGsmfp9',
  'oofSNvIgaIjv',
  'wpNWVFYksv-i',
  '_onRfGKaooG7',
  'ug8nI6HUZdXu',
  'SOijH1WYj_AU',
  'h9dWX7GkK11R',
  'zaie1_U9TgUs',
  'KH19qFX2I9ge',
  'OGTadt1-sITE',
  'd7Ueo-3f-zZ9',
  'kJgaPDq2e_uL',
  'JcTbd7vaaUJa',
  '43sea5OFp-VQ',
  'a7_MpjnyCCiU',
  'QzbLLe846TGD',
  'o_MSvCcewdiY',
  'N-VmHxaaRfHB',
  'sl7rPVFxK3sm',
  'SxNFvz7ZtU0u',
  'gIghBzK5tKdw',
  '8HKRjamjEADk',
  'SazODL9RSBys',
  '-WYVGNQwsPdI',
  'NMF2fHIJ8NvM',
  '73CUA-uBuQlg',
  'HC08nJFE2-gh',
  'mAx3Few0Jj8R',
  '1DZDmp7f4PHA',
  'wH0ZATzIyots',
  'VOIw1CsfAOV6',
  'DF5I54NUx6HX',
  'trceYekrwyOX',
  'VGA_j_LW3YdS',
  'prk_r7w5ew69',
  'yNzPe3R9gtNa',
  'pWeytgeDQ0ER',
  '4ZsH4SO14Zwt',
  'k1QPAu72a8K6',
  '6_fQmQAB0srK',
  'U6ovzbOAVFW1',
  'UEPVEvRf2M5f',
  '8-THkMJw0jc4',
  '4V5dOMrdbdTv',
  'MVAtSdz6o9Gu',
  '31nXvRQBRAks',
  'VDMJ4Fjpgs-8',
  'OEhgbJW3I-8f',
  'hT3_GagY5MrJ',
  'C_xaJH5aeK2q',
  'A1B1jVkV22Bx',
  '82ZD_n-LZaiO',
  'ON1P_T2-f-0g',
  'jIiie4YTH91_',
  '-UC5KJLYy64m',
  'i_IPYUtJLZWh',
  'MsitIpwWswTD',
  'ZScnzpS-ZT6u',
  'Xry7qywo2O0Z',
  '3Md52Ww-2zbB',
  'W7OReGTpsdKK',
  'g5nhCsfGDNWC',
  'Z3nXtaV2efB0',
  'nSZY-kPHqerh',
  'VpU1nAcbewzg',
  'YbjtnRFTacrj',
  '9wnOO-xTny-K',
  'yZPDwoBL9QAs',
  '5tQ_zMPeOsfy',
  'chMqYngVfzNP',
  'o7H0Cktjzc-T',
  'VqkoGcWvKHN7',
  'CfuzAfu5GzUz',
])

export const courseIdsForSmartLearning = new Set<string>([
  'C1CuXbA8xjB9',
  'lMwfDX2X6GfM',
  'j3Kp72HJCAkE',
  'mG0njrkyv1rN',
])

// Some organizations want certs enabled for all courses.
const organizationsWithCertificatesForAllCourses = new Set<string>([
  ORGANIZATION_IDS.SveaSolar,
  // ORGANIZATION_IDS.Sierra,
])

export const useIsValidCertificateCourseId = ({ courseId }: { courseId: string }): boolean => {
  const organization = useSelector(selectOrganization)

  // TODO: These course ids should be scoped by organization
  if (validCertificateCourseIds.has(courseId)) {
    return true
  }

  if (organization === undefined) {
    return false
  }

  if (organizationsWithCertificatesForAllCourses.has(organization.uuid)) {
    return true
  }

  if (organization.uuid === ORGANIZATION_IDS.AlltOmJuridik) {
    return courseIdsForAlltOmJuridik.has(courseId)
  }

  if (organization.uuid === ORGANIZATION_IDS.SmartLearning) {
    return courseIdsForSmartLearning.has(courseId)
  }

  return false
}
