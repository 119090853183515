import React, { MutableRefObject, useEffect } from 'react'
import { Divider } from 'sierra-client/components/liveV2/right-sidebar/components'
import { OrganizationLogo } from 'sierra-client/components/organization-logo'
import { useToggleMobileGlobalSidebar } from 'sierra-client/features/collapsable-sidebar/hooks/use-toggle-mobile-global-sidebar'
import { LabelHeaderItem } from 'sierra-client/features/collapsable-sidebar/items/header-item'
import { Fade } from 'sierra-client/features/collapsable-sidebar/items/shared/fade'
import { ItemsList } from 'sierra-client/features/collapsable-sidebar/items/shared/items-list'
import { useIsSidebarNavigationAllowed } from 'sierra-client/features/global-sidebar/hooks/use-is-sidebar-navigation-allowed'
import { usePathname } from 'sierra-client/hooks/router/use-pathname'
import { useTranslation } from 'sierra-client/hooks/use-translation'
import { useUserMenu } from 'sierra-client/layout/header/components/user-menu'
import { useSettingsMenuitems } from 'sierra-client/layout/header/workspace-header/settings-menu'
import { getGlobalRouter } from 'sierra-client/router'
import { useIsMobile } from 'sierra-client/state/browser/selectors'
import { useSelector } from 'sierra-client/state/hooks'
import { selectOrganization } from 'sierra-client/state/organization/selectors'
import { FCC } from 'sierra-client/types'
import { useOrganizationSettings } from 'sierra-client/views/manage/settings/use-organization-settings'
import { Drawer, TruncatedTextWithTooltip } from 'sierra-ui/components'
import { IconButton, ScrollView, Spacer, View } from 'sierra-ui/primitives'
import { token } from 'sierra-ui/theming'
import styled from 'styled-components'

const Header = styled(View).attrs({
  animated: true,
  direction: 'column',
  paddingTop: '16',
  paddingRight: '16',
  paddingBottom: '16',
  paddingLeft: '24',
  gap: 'none',
})``

const Border = styled.div`
  position: absolute;
  left: 0;
  right: 0;
  top: 0;
  height: 1px;
  background-color: ${token('border/default')};
`
const Bottom = styled(View).attrs({
  animated: true,
  direction: 'row',
  justifyContent: 'space-between',
  alignItems: 'center',
})`
  position: relative;
`

const Content = styled(ScrollView).attrs({
  grow: true,
  animated: true,
  paddingRight: '16',
  paddingLeft: '16',
  gap: 'none',
  variant:
    'sticky' /* We are setting the variant to sticky as we want the sidebar items to have the same state as on desktop */,
})``

const CompanyInfo = styled(View).attrs({
  direction: 'row',
  justifyContent: 'center',
  alignItems: 'center',
  gap: '12',
})``

const CompanyName = styled(TruncatedTextWithTooltip).attrs({ bold: true, size: 'regular' })``

const CloseButton = styled(IconButton).attrs({ iconId: 'close', variant: 'transparent' })``

const useAutoCloseMobileSidebar = (): void => {
  const pathname = usePathname()
  const mobileSidebarAvailable = useIsMobile()
  const { close: closeMobileSidebar } = useToggleMobileGlobalSidebar()

  useEffect(() => {
    if (mobileSidebarAvailable) {
      closeMobileSidebar()
    }
  }, [closeMobileSidebar, mobileSidebarAvailable, pathname])
}

const Settings: React.FC = () => {
  const allowNavigation = useIsSidebarNavigationAllowed()
  const { userMenuElement, setUserDialogOpen } = useUserMenu()
  const { menuItems, onMenuSelect } = useSettingsMenuitems(setUserDialogOpen)

  if (!allowNavigation) return null

  return (
    <>
      <Divider $marginTop='16' $marginBottom='16' $grow />
      <ItemsList>
        {menuItems.map(i => (
          <LabelHeaderItem
            key={i.id}
            title={i.label}
            iconId={i.icon}
            onClick={() => onMenuSelect(i)}
            isSelected={false}
          />
        ))}
        {userMenuElement}
      </ItemsList>
      <Spacer size='24' />
    </>
  )
}

export const MobileSidebar: FCC<{
  containerRef: MutableRefObject<HTMLDivElement | null>
  renderBottomComponent: () => React.ReactNode
  renderTopComponent: () => React.ReactNode
}> = ({ containerRef, children, renderBottomComponent, renderTopComponent }) => {
  const { t } = useTranslation()
  const { isOpen: isMobileSidebarOpen, close: closeMobileSidebar } = useToggleMobileGlobalSidebar()
  const org = useSelector(selectOrganization)
  const { settings } = useOrganizationSettings()
  const orgName = settings.brand.platformName ?? org?.name

  useAutoCloseMobileSidebar()

  /* Close mobile sidebar when we unmount */
  useEffect(() => closeMobileSidebar, [closeMobileSidebar])

  return (
    <Drawer
      open={isMobileSidebarOpen}
      onClose={closeMobileSidebar}
      overlayVariant='none'
      role='navigation'
      aria-label={t('sidebar.main-label')}
      aria-hidden={!isMobileSidebarOpen}
      aria-expanded={isMobileSidebarOpen}
    >
      <Header>
        <View justifyContent='space-between' grow>
          <CompanyInfo onClick={() => void getGlobalRouter().navigate({ to: '/' })}>
            <OrganizationLogo orgName={orgName ?? 'Sana'} brandSettings={settings.brand} />
            <CompanyName>{orgName ?? 'Sana'}</CompanyName>
          </CompanyInfo>
          <CloseButton onClick={closeMobileSidebar} />
        </View>
        {renderTopComponent()}
      </Header>
      <Content ref={containerRef}>
        {children}
        <Settings />
      </Content>
      <Bottom>
        <Fade direction='up' />
        <Border />
        {renderBottomComponent()}
      </Bottom>
    </Drawer>
  )
}
