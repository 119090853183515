import _ from 'lodash'
import { useEffect, useRef, useState } from 'react'
import { graphql } from 'sierra-client/api/graphql/gql'
import {
  HandleEventGroupEnrollmentRequestsPanel_CalendarEventsFragment,
  RequestStatus,
} from 'sierra-client/api/graphql/gql/graphql'
import { useTranslation } from 'sierra-client/hooks/use-translation'
import { RequestItem } from 'sierra-client/views/learner/event-group/handle-event-group-enrollment-panel/request-item'
import {
  formatGqlEventSchedule,
  gqlEventLocationString,
} from 'sierra-client/views/manage/event-groups/event-utils'
import { withPanel } from 'sierra-client/views/manage/utils/with-modal'
import { isNonNullable } from 'sierra-domain/utils'
import { resolveTokenOrColor } from 'sierra-ui/color/token-or-color'
import { Tabs } from 'sierra-ui/components'
import { TabItem } from 'sierra-ui/components/tabs/tabs'
import { CalendarEventDisplay } from 'sierra-ui/missions/foundation/calendar-events/calendar-event-display'
import { Button, Heading, ScrollView, Text, View } from 'sierra-ui/primitives'
import styled from 'styled-components'

/* We need this to make the flex-child scrollable and not scroll on the parent */
const MinHeight = styled(View).attrs({ direction: 'column', grow: true, gap: '24' })`
  height: 0;
`

export const handleEventGroupEnrollmentRequestsPanelCalendarEventsFragment = graphql(`
  fragment HandleEventGroupEnrollmentRequestsPanel_calendarEvents on CalendarEvent {
    id
    enrollmentRequestsToHandle {
      requestId
      requestStatus
      ...EnrollmentRequestItem
    }
    schedule {
      ...CalendarEventScheduleFragment
    }
    location {
      ...CalendarEventLocationFragment
    }
    title
    seatsRemaining
    approverSetting
  }
`)

const Section = styled(View).attrs({ direction: 'column' })``

const SectionHeader = styled(View).attrs({
  direction: 'column',
  justifyContent: 'center',
  alignItems: 'stretch',
  grow: true,
})`
  position: sticky;
  top: 0;
  z-index: 1;
  background-color: ${p => resolveTokenOrColor('surface/default', p.theme)};
`

const RequestsList = styled(View).attrs({ direction: 'column', padding: '16 8', gap: '24' })``

const RequestBucket: React.FC<{
  calendarEvents: Array<HandleEventGroupEnrollmentRequestsPanel_CalendarEventsFragment>
  imageUrl: string
  refetch: () => void
}> = ({ calendarEvents, imageUrl, refetch }) => {
  const { t } = useTranslation()

  if (calendarEvents.length === 0) return null

  return (
    <ScrollView grow paddingRight='6' paddingBottom='16'>
      {calendarEvents.map(calendarEvent => {
        return (
          <Section key={calendarEvent.id}>
            <SectionHeader>
              <CalendarEventDisplay
                imageSize='small'
                imageUrl={imageUrl}
                secondaryText={gqlEventLocationString(calendarEvent.location)}
                left={formatGqlEventSchedule(calendarEvent.schedule)}
                right={
                  isNonNullable(calendarEvent.seatsRemaining)
                    ? t('manage.live-session.n-seats-available', { count: calendarEvent.seatsRemaining })
                    : undefined
                }
              />
            </SectionHeader>
            <RequestsList>
              {calendarEvent.enrollmentRequestsToHandle.map(request => {
                const requestItemFragment = request
                return (
                  <RequestItem
                    key={request.requestId}
                    isApproveDisabled={calendarEvent.seatsRemaining === 0}
                    request={requestItemFragment}
                    refetch={refetch}
                  />
                )
              })}
            </RequestsList>
          </Section>
        )
      })}
    </ScrollView>
  )
}

const Footer = styled(View).attrs({
  paddingBottom: '8',
  direction: 'row',
  justifyContent: 'flex-end',
  alignItems: 'center',
  gap: '8',
})``

const RequestCountWrapper = styled(View).attrs({
  border: 'none',
  justifyContent: 'center',
  alignItems: 'center',
})<{ dark: boolean }>`
  border-radius: 99px;
  min-width: 14px;
  min-height: 14px;
  padding: 0 4px;

  transition: background-color 300ms;

  background: ${p =>
    p.dark ? resolveTokenOrColor('black', p.theme) : resolveTokenOrColor('grey15', p.theme)};
`

const WhiteText = styled(Text).attrs({ size: 'technical', bold: true })`
  &&& {
    color: ${p => resolveTokenOrColor('white', p.theme)};
  }
`

const RequestCount: React.FC<{ count: number; isSelected: boolean }> = ({ count, isSelected }) => {
  const initialRender = useRef(true)
  const [flashOnCountChange, setFlashOnCountChange] = useState(false)

  useEffect(() => {
    if (initialRender.current) {
      initialRender.current = false
      return
    }

    setFlashOnCountChange(true)
    const timeout = setTimeout(() => setFlashOnCountChange(false), 300)

    return () => clearTimeout(timeout)
  }, [count])

  return (
    <RequestCountWrapper dark={isSelected || flashOnCountChange}>
      <WhiteText>{count}</WhiteText>
    </RequestCountWrapper>
  )
}

const TabLabel: React.FC<{ count: number; label: string; isSelected: boolean }> = ({
  count,
  label,
  isSelected,
}) => (
  <View direction='row' gap='6' justifyContent='flex-start' alignItems='center'>
    <RequestCount count={count} isSelected={isSelected} />
    <Text>{label}</Text>
  </View>
)

export const HandleEventGroupEnrollmentRequestsPanel = withPanel<{
  calendarEvents: Array<HandleEventGroupEnrollmentRequestsPanel_CalendarEventsFragment>
  imageUrl: string
  onClose: (forceClose?: boolean) => void
  refetch: () => void
}>(
  {
    size: { width: 656 },
    padding: '32',
    disableScrollbarGutter: true,
  },
  props => {
    const { t } = useTranslation()
    const calendarEvents = props.calendarEvents.map(event => event)

    const pendingCalendarEvents = calendarEvents
      .map(calendarEvent => ({
        ...calendarEvent,
        enrollmentRequestsToHandle: calendarEvent.enrollmentRequestsToHandle.filter(
          request => request.requestStatus === 'PENDING'
        ),
      }))
      .filter(calendarEvent => calendarEvent.enrollmentRequestsToHandle.length > 0)

    const approvedCalendarEvents = calendarEvents
      .map(calendarEvent => ({
        ...calendarEvent,
        enrollmentRequestsToHandle: calendarEvent.enrollmentRequestsToHandle.filter(
          request => request.requestStatus === 'APPROVED'
        ),
      }))
      .filter(calendarEvent => calendarEvent.enrollmentRequestsToHandle.length > 0)

    const rejectedCalendarEvents = calendarEvents
      .map(calendarEvent => ({
        ...calendarEvent,
        enrollmentRequestsToHandle: calendarEvent.enrollmentRequestsToHandle.filter(
          request => request.requestStatus === 'REJECTED'
        ),
      }))
      .filter(calendarEvent => calendarEvent.enrollmentRequestsToHandle.length > 0)

    const [tab, setTab] = useState<RequestStatus>('PENDING')

    const tabItems: TabItem<RequestStatus>[] = [
      {
        id: 'PENDING',
        label: (
          <TabLabel
            count={_.sumBy(pendingCalendarEvents, event => event.enrollmentRequestsToHandle.length)}
            label={t('in-person-events.tabs.pending')}
            isSelected={tab === 'PENDING'}
          />
        ),
        content: (
          <RequestBucket
            refetch={props.refetch}
            calendarEvents={pendingCalendarEvents}
            imageUrl={props.imageUrl}
          />
        ),
      },
      {
        id: 'APPROVED',
        label: (
          <TabLabel
            count={_.sumBy(approvedCalendarEvents, event => event.enrollmentRequestsToHandle.length)}
            label={t('in-person-events.tabs.approved')}
            isSelected={tab === 'APPROVED'}
          />
        ),
        content: (
          <RequestBucket
            refetch={props.refetch}
            calendarEvents={approvedCalendarEvents}
            imageUrl={props.imageUrl}
          />
        ),
      },
      {
        id: 'REJECTED',
        label: (
          <TabLabel
            count={_.sumBy(rejectedCalendarEvents, event => event.enrollmentRequestsToHandle.length)}
            label={t('in-person-events.tabs.rejected')}
            isSelected={tab === 'REJECTED'}
          />
        ),
        content: (
          <RequestBucket
            refetch={props.refetch}
            calendarEvents={rejectedCalendarEvents}
            imageUrl={props.imageUrl}
          />
        ),
      },
    ]

    return (
      <>
        <MinHeight>
          <Heading size='h5' bold>
            {t('event-groups.manage-requests')}
          </Heading>

          <Tabs value={tab} onChange={(tab: RequestStatus) => setTab(tab)} hideLine items={tabItems} />

          <Footer>
            <Button onClick={props.onClose} variant='secondary'>
              {t('dictionary.done')}
            </Button>
          </Footer>
        </MinHeight>
      </>
    )
  }
)
