import { SkillLevelSettingId } from 'sierra-client/api/graphql/branded-types'
import { BadgeTheme } from 'sierra-client/features/skills/components/badges/types'
import { ContentSkill } from 'sierra-client/features/skills/components/content-skill/types'
import { useTranslation } from 'sierra-client/hooks/use-translation'
import { isDefined } from 'sierra-domain/utils'
import { MenuItem } from 'sierra-ui/components'
import { DefaultDropdownTriggerProps, SingleSelectDropdown } from 'sierra-ui/primitives/menu-dropdown'

type Variant = DefaultDropdownTriggerProps['variant']

export const ContentSkillLevelDropdown: React.FC<{
  skill: ContentSkill
  onSelect: (skill: ContentSkill) => void
  variant?: Variant
  grow?: boolean
}> = ({ skill, onSelect, variant = 'transparent', grow = false }) => {
  const { t } = useTranslation()

  const menuItems: MenuItem<SkillLevelSettingId>[] = skill.levels.map(level => ({
    id: level.levelSetting.id,
    label: level.levelSetting.name,
    type: 'label',
  }))

  return (
    <SingleSelectDropdown
      grow={grow}
      variant={variant}
      placeholder={t('dictionary.select')}
      selectedItem={menuItems.find(level => level.id === skill.levelSettingId)}
      onSelect={item => {
        const skillLevelSetting = skill.levels.find(level => level.levelSetting.id === item.id)?.levelSetting

        if (isDefined(skillLevelSetting)) {
          onSelect({
            ...skill,
            levelSettingId: item.id,
            theme: BadgeTheme.catch('disabled').parse(skillLevelSetting.defaultBadgeTheme),
          })
        }
      }}
      menuItems={menuItems}
    />
  )
}
