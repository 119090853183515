import { percentage } from 'sierra-client/core/format'
import { I18nArgs, TranslationLookup } from 'sierra-client/hooks/use-translation/types'
import { PillRep } from 'sierra-client/lib/tabular/datatype/internal/reps/pill-rep'
import { HomeworkState } from 'sierra-domain/api/homework'
import { getPrioritizedHomework } from 'sierra-domain/homework/utils'
import { assertNever, isDefined, isNotDefined } from 'sierra-domain/utils'

const getTooltip = (homework?: HomeworkState): I18nArgs | undefined => {
  if (isNotDefined(homework)) return undefined

  const { grade, submissions, maxSubmissions } = homework

  if (isDefined(maxSubmissions)) {
    switch (grade) {
      case 'not-graded':
        return [
          'homework.progress-tooltip.not-graded.max-submissions',
          { count: submissions, maxSubmissions },
        ]
      case 'failed':
      case 'failed-with-no-retries':
        return ['homework.progress-tooltip.failed.max-submissions', { count: submissions, maxSubmissions }]
      case 'not-submitted':
      case 'passed':
      case 'dismissed':
        return undefined
      default:
        assertNever(grade)
    }
  } else {
    switch (grade) {
      case 'not-graded':
        return ['homework.progress-tooltip.not-graded', { count: submissions }]
      case 'failed':
      case 'failed-with-no-retries':
        return ['homework.progress-tooltip.failed', { count: submissions }]
      case 'not-submitted':
      case 'passed':
      case 'dismissed':
        return undefined
      default:
        assertNever(grade)
    }
  }
}

export const getProgressPillRep = ({
  homeworks,
  t,
  progress,
}: {
  progress?: number
  homeworks: HomeworkState[]
  t: TranslationLookup
}): PillRep => {
  const selectedHomework = getPrioritizedHomework(homeworks)
  const hasFailedWithNoRetries = selectedHomework?.grade === 'failed-with-no-retries'
  const isComplete = progress === 1
  const hasPendingHomework = selectedHomework?.grade === 'not-graded' || selectedHomework?.grade === 'failed'
  const tooltip = getTooltip(selectedHomework)

  if (isComplete) {
    return {
      type: 'success',
      text: t('dictionary.completed'),
    }
  }

  if (hasFailedWithNoRetries) {
    return {
      type: 'error',
      text: t('homework.failed'),
    }
  }

  return {
    type: 'ghost',
    text: percentage(progress),
    subtext: hasPendingHomework ? t('manage.users.status.homework.not-graded') : undefined,
    tooltip: tooltip ? t(...tooltip) : undefined,
  }
}
