import React from 'react'
import { date, useLocalizedFormatters } from 'sierra-client/core/format'
import { RenderHint } from 'sierra-client/lib/tabular/hints'
import { TruncatedText } from 'sierra-ui/components'

export const DateTimeCell: React.FC<{ cellData?: Date; hints: RenderHint[] }> = ({ cellData, hints }) => {
  const { formatTimeAgo } = useLocalizedFormatters()

  return hints.includes('date') ? (
    <TruncatedText lines={1} size='small'>
      {cellData ? date(cellData.toISOString()) : ''}
    </TruncatedText>
  ) : hints.includes('time-ago') ? (
    <TruncatedText lines={1} size='small'>
      {cellData ? formatTimeAgo(cellData) : ''}
    </TruncatedText>
  ) : (
    <TruncatedText lines={1} size='small'>
      {cellData ? date(cellData.toISOString()) : ''}
    </TruncatedText>
  )
}
