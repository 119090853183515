import React from 'react'
import { TextToSpeech } from 'sierra-client/editor/text-to-speech'
import { useTranslation } from 'sierra-client/hooks/use-translation'
import { Toolbar, ToolbarIcon, ToolbarSeparator } from 'sierra-client/views/v3-author/block-toolbar'
import { removeNodeWithId, updateNodeWithId } from 'sierra-client/views/v3-author/command'
import { assertElementType } from 'sierra-client/views/v3-author/queries'
import { SlateFC } from 'sierra-client/views/v3-author/slate'
import { PreambleAlignment, PreambleColor } from 'sierra-domain/content/v2/content'
import { Heading } from 'sierra-ui/primitives'
import { palette } from 'sierra-ui/theming'
import { Theme } from 'sierra-ui/theming/legacy-theme'
import { useSlateStatic } from 'slate-react'
import styled, { css } from 'styled-components'

const getColor = (theme: Theme, color?: PreambleColor): string => {
  switch (color) {
    case 'default':
      return theme.home.textColor
    case 'black-orange':
      return palette.primitives.black
    case 'blue':
      return theme.color.blueDark
    case 'red':
      return theme.color.redDark
    case 'green':
      return theme.color.greenBright
    default:
      return palette.primitives.black
  }
}

export const PreambleContent = styled(Heading).attrs({
  size: 'h4',
})<{ $alignment: 'center' | 'left'; $color: PreambleColor }>`
  position: relative;

  ${p => `color: ${getColor(p.theme, p.$color)};`};
  ${p =>
    p.$alignment === 'left'
      ? css`
          padding-left: 2rem;
          padding-bottom: 1rem;
        `
      : css`
          text-align: center;
          padding: 0.5rem 0;
        `};

  &:before {
    content: '';
    background-color: ${p =>
      p.$color === 'black-orange' ? p.theme.color.redVivid : getColor(p.theme, p.$color)};
    border-radius: 1rem;
    opacity: 0.1;
    position: absolute;
    left: 0;

    ${p =>
      p.$alignment === 'left'
        ? css`
            width: 0.25rem;
            height: 95%;
            opacity: 0.1;
          `
        : css`
            width: 2.5rem;
            height: 0.25rem;
            margin: auto;
            top: 0;
            right: 0;
          `};
  }
`

const PreambleToolbar: React.FC<{
  nodeId: string
  aligned: PreambleAlignment
}> = ({ nodeId: id, aligned }) => {
  const { t } = useTranslation()
  const editor = useSlateStatic()

  return (
    <Toolbar elementId={id}>
      <ToolbarIcon
        tooltip={t('author.block-editor.preamble-left')}
        iconId='layout--left-block'
        selected={aligned === 'left'}
        onClick={() => updateNodeWithId(editor, id, { aligned: 'left' })}
      />
      <ToolbarIcon
        tooltip={t('author.block-editor.preamble-center')}
        iconId='layout--center'
        selected={aligned === 'center'}
        onClick={() => updateNodeWithId(editor, id, { aligned: 'center' })}
      />

      <ToolbarSeparator />

      <ToolbarIcon
        tooltip={t('author.block-editor.remove')}
        iconId='trash-can'
        onClick={() => removeNodeWithId(editor, id)}
      />
    </Toolbar>
  )
}

export const Preamble: SlateFC = ({ children, element: preamble, ...props }) => {
  assertElementType('preamble', preamble)

  const aligned = preamble.aligned ?? 'left'
  const color = preamble.color ?? 'default'

  return (
    <>
      <TextToSpeech element={preamble} />
      <PreambleContent
        color='LEGACY_DEFAULT_HEADING_COLOR_REPLACE_ASAP'
        $alignment={aligned}
        $color={color}
        {...props}
      >
        {children}
        <PreambleToolbar nodeId={preamble.id} aligned={aligned} />
      </PreambleContent>
    </>
  )
}
