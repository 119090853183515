import { UseQueryResult } from '@tanstack/react-query'
import { useMemo } from 'react'
import { useCachedQuery } from 'sierra-client/state/api'
import { GetSelfPacedCoursePublishStateResponse } from 'sierra-domain/api/author-v2'
import { SelfPacedContentId } from 'sierra-domain/api/nano-id'
import { XRealtimeAuthorGetCoursePublishState } from 'sierra-domain/routes'

function useQuerySelfPacedPublishState(
  contentId: SelfPacedContentId | undefined
): UseQueryResult<GetSelfPacedCoursePublishStateResponse> {
  const query = useCachedQuery(
    XRealtimeAuthorGetCoursePublishState,
    { contentId: contentId! },
    {
      enabled: contentId !== undefined,
      refetchOnWindowFocus: true,
      refetchIntervalInBackground: false,
    }
  )

  return query
}

export type SelfPacedPublishState = {
  state: GetSelfPacedCoursePublishStateResponse | undefined
  refresh: () => Promise<void>
}

export function useSelfPacedPublishState(contentId: SelfPacedContentId | undefined): SelfPacedPublishState {
  const { data, refetch } = useQuerySelfPacedPublishState(contentId)

  return useMemo(
    () => ({
      state: data,
      refresh: async () => {
        await refetch()
      },
    }),
    [data, refetch]
  )
}
