import { useSetAtom } from 'jotai'
import _ from 'lodash'
import { previousInstructionsAtom } from 'sierra-client/features/text-actions/atoms'
import { EditorTextActionsModalAction } from 'sierra-client/features/text-actions/types'
import { useStableFunction } from 'sierra-client/hooks/use-stable-function'
import { subscribeSse } from 'sierra-client/state/api'
import {
  SSEXRealtimeAuthorGenerateParagraph,
  SSEXRealtimeAuthorGenerateRewrittenText,
} from 'sierra-domain/routes-sse'
import { assertNever } from 'sierra-domain/utils'

type UseGenerateTextParams = {
  onStart?: () => void
  dispatchAction: (action: EditorTextActionsModalAction) => void
}

type UseGenerateText = (generateParams: {
  sourceText: string
  instruction: string
  mode: 'extend' | 'replace'
}) => Promise<void>

export const useGenerateText = ({ onStart, dispatchAction }: UseGenerateTextParams): UseGenerateText => {
  const setPreviousInstructions = useSetAtom(previousInstructionsAtom)

  return useStableFunction(async function generateText({ sourceText, instruction, mode }) {
    if (instruction.length === 0) {
      console.warn('Ignoring empty instruction')
      return
    }

    const abortController = new AbortController()

    onStart?.()

    setPreviousInstructions(prev =>
      _.chain([instruction, ...prev])
        .uniq()
        .slice(0, 10)
        .value()
    )

    dispatchAction({
      type: 'start-generation',
      instruction: instruction,
      onCancel: () => {
        abortController.abort()
      },
    })

    try {
      switch (mode) {
        case 'replace':
          await subscribeSse({
            route: SSEXRealtimeAuthorGenerateRewrittenText,
            input: {
              sourceText,
              instructions: instruction.replaceAll(/\s+/g, ' ').trim(),
            },
            onMessage: event => {
              dispatchAction({
                type: 'generation-step',
                chunk: event.data.text,
              })
            },
            signal: abortController.signal,
          })
          break
        case 'extend':
          await subscribeSse({
            route: SSEXRealtimeAuthorGenerateParagraph,
            input: {
              prompt: sourceText,
              type: 'continuation',
            },
            onMessage: event => {
              dispatchAction({
                type: 'generation-step',
                chunk: event.data.text,
              })
            },
            signal: abortController.signal,
          })
          break
        default:
          assertNever(mode)
      }

      if (!abortController.signal.aborted) {
        dispatchAction({
          type: 'finish',
        })
      }
    } catch (e) {
      dispatchAction({
        type: 'generation-error',
      })
    }
  })
}
