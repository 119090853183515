import { FC } from 'react'
import { Link } from 'sierra-client/components/common/link'
import { useTranslation } from 'sierra-client/hooks/use-translation'
import { TranslationKey } from 'sierra-client/hooks/use-translation/types'
import { Icon } from 'sierra-ui/components'
import { View } from 'sierra-ui/primitives'

export type Backlink = { href: `/manage/${string}` | `/settings/${string}`; label: TranslationKey }

export const DetailsBacklink: FC<{ backlink: Backlink }> = ({ backlink }) => {
  const { t } = useTranslation()

  return (
    <Link next href={backlink.href} size='small'>
      <View>
        <Icon iconId='chevron--left--small' color='currentColor' />
        {t(backlink.label)}
      </View>
    </Link>
  )
}
