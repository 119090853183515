import { useMemo } from 'react'
import { useTranslation } from 'sierra-client/hooks/use-translation'
import { APP_LANGUAGES, AppLanguageCode } from 'sierra-client/i18n/app-languages.generated'
import {
  COURSE_TRANSLATION_LANGUAGES,
  CourseTranslationLanguageCode,
} from 'sierra-client/i18n/course-translation-languages'
import { useSelector } from 'sierra-client/state/hooks'
import { selectOrganization } from 'sierra-client/state/organization/selectors'
import { Organization } from 'sierra-domain/organization'

type ItemWithLabel = { label: string }
const compareLabel = (a: ItemWithLabel, b: ItemWithLabel): number => a.label.localeCompare(b.label)

export type UseOrgLanguageMenuItemsProps = {
  /**
   * Whether set the label in the same language as the item. Default behavior is to translate the label to the user's language.
   * @default false
   */
  labelInItemLanguage?: boolean | undefined
}

export type LanguageMenuItem<T = string> = {
  id: T
  type: 'label'
  label: string
}

function languagesToItemList<T>(languages: T[], getLabel: (code: T) => string): LanguageMenuItem<T>[] {
  return languages
    .map(code => ({
      id: code,
      type: 'label' as const,
      label: getLabel(code),
    }))
    .sort(compareLabel)
}

function getOrgLanguageCodes(organization: Pick<Organization, 'languages'> | undefined): AppLanguageCode[] {
  return (organization?.languages ?? ['en', 'sv']) as AppLanguageCode[]
}

/**
 * Returns a memoized list of language menu items for the organization's languages.
 */
export function useLanguageItems(props?: UseOrgLanguageMenuItemsProps): LanguageMenuItem<AppLanguageCode>[] {
  const { t } = useTranslation()
  const organization = useSelector(selectOrganization)
  const { labelInItemLanguage } = props ?? {}

  return useMemo(() => {
    const languageCodes = (organization?.languages ?? ['en', 'sv']) as AppLanguageCode[]

    const getLabel =
      labelInItemLanguage === true
        ? (code: AppLanguageCode) => APP_LANGUAGES[code].localizedName
        : (code: AppLanguageCode) => t(`language.${code}`)

    return languagesToItemList(languageCodes, getLabel)
  }, [organization, labelInItemLanguage, t])
}

/**
 * Returns a memoized list of language menu items for course translations with a translated label.
 * - For app languages, use {@link useLanguageItems} instead.
 */
export function useCourseTranslationItems(): LanguageMenuItem<CourseTranslationLanguageCode>[] {
  const { t } = useTranslation()
  return useMemo(() => languagesToItemList(COURSE_TRANSLATION_LANGUAGES, code => t(`language.${code}`)), [t])
}

/**
 * Returns a memoized list of language menu items for course groups,
 * which is a combination of all app languages and all course translation languages.
 */
export function useCourseGroupLanguageItems(): LanguageMenuItem<
  AppLanguageCode | CourseTranslationLanguageCode
>[] {
  const { t } = useTranslation()
  const organization = useSelector(selectOrganization)

  return useMemo(() => {
    const orgLanguages = getOrgLanguageCodes(organization)
    const uniqueCombinedLanguageCodes = [...new Set([...orgLanguages, ...COURSE_TRANSLATION_LANGUAGES])]

    return languagesToItemList(uniqueCombinedLanguageCodes, code => t(`language.${code}`))
  }, [organization, t])
}
