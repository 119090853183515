import { GridArea } from 'sierra-client/features/program/components/grid'
import { FCC } from 'sierra-ui/types'
import styled from 'styled-components'

const RootSectionContainer = styled.div`
  padding-inline: 16px;
`

export const RootSection: FCC = ({ children }) => {
  return (
    <RootSectionContainer>
      <GridArea area='content'>{children}</GridArea>
    </RootSectionContainer>
  )
}
