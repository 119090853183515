import { MovingAverage } from 'sierra-client/lib/statistics/moving-average'
import { assert } from 'sierra-domain/utils'

export class ExponentialMovingAverage implements MovingAverage {
  private alpha: number
  private currentEma: number | undefined
  private measurementCount: number = 0

  constructor(private period: number) {
    assert(period > 0 && Number.isInteger(period))
    this.alpha = 2 / (period + 1)
  }

  push(val: number): number {
    if (this.currentEma === undefined) {
      this.currentEma = val
    } else {
      this.currentEma = this.alpha * val + (1 - this.alpha) * this.currentEma
    }
    if (this.measurementCount < this.period) {
      this.measurementCount++
    }
    return this.currentEma
  }

  avg(): number | undefined {
    return this.currentEma
  }

  filled(): boolean {
    return this.measurementCount === this.period
  }
}
