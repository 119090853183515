import React from 'react'
import { ActionModal } from 'sierra-client/components/common/modals/action-modal'
import { useTranslation } from 'sierra-client/hooks/use-translation'

interface TagModalProps {
  isOpen: boolean
  primaryAction: () => void
  onClose: () => void
}

export const RemoveTagModal: React.FC<TagModalProps> = ({ primaryAction, isOpen, onClose }) => {
  const { t } = useTranslation()

  return (
    <ActionModal
      title={t('manage.skill.modal.remove-title')}
      open={isOpen}
      onClose={onClose}
      primaryActionLabel={t('admin.remove')}
      primaryAction={primaryAction}
      deleteAction
    >
      {t('manage.skill.modal.remove-message')}
    </ActionModal>
  )
}
