import { useCallback } from 'react'
import { useMobileHelperShowPrevious } from 'sierra-client/views/course-helper/config'
import { containerStyles } from 'sierra-client/views/course-helper/shared/containers'
import { useSelfPacedFiles } from 'sierra-client/views/self-paced/files-provider'
import { Icon } from 'sierra-ui/components'
import styled, { css } from 'styled-components'

export const PreviousWrapper = styled.button<{ disabled?: boolean }>`
  width: 52px;
  min-width: 52px;

  height: 48px;
  min-height: 48px;

  padding: 0;
  cursor: pointer;
  pointer-events: all;

  display: flex;
  justify-content: center;
  align-items: center;

  ${containerStyles};

  ${p =>
    p.disabled === true &&
    css`
      pointer-events: none;
    `}
`

export const Previous: React.FC = () => {
  const showPrevious = useMobileHelperShowPrevious()
  const { prevFile, goTo } = useSelfPacedFiles()

  const goToPrevious = useCallback(() => {
    if (prevFile !== undefined) goTo(prevFile.id)
  }, [goTo, prevFile])

  if (showPrevious === 'invisible') {
    return null
  }

  return (
    <PreviousWrapper onClick={goToPrevious} disabled={showPrevious === 'disabled'}>
      <Icon
        iconId='previous--filled'
        size='size-20'
        color={showPrevious === 'disabled' ? 'form/disabled/foreground' : 'foreground/primary'}
      />
    </PreviousWrapper>
  )
}
