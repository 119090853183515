import React, { useCallback } from 'react'
import { useNotif } from 'sierra-client/components/common/notifications'
import { useTranslation } from 'sierra-client/hooks/use-translation'
import { getUserErrorTranslationKey } from 'sierra-client/utils/translation-utils'
import { useDownloadReport } from 'sierra-client/views/manage/reports/report-utils'
import { ReportJob } from 'sierra-domain/api/analytics-reporting'
import { Button, LoadingSpinner, Text, View } from 'sierra-ui/primitives'
import styled from 'styled-components'

type JobStatusProps = {
  job: ReportJob
}

const OffsetView = styled(View)`
  padding-left: 0.5rem;
  color: ${p => p.theme.color.grey40};
`

export const JobStatus: React.FC<JobStatusProps> = ({ job }) => {
  const downloadReport = useDownloadReport()
  const notifications = useNotif()
  const { t } = useTranslation()
  const [hideButton, setHideButton] = React.useState(false)

  const triggerDownloadReport = useCallback(
    async (e: React.MouseEvent) => {
      e.preventDefault()
      e.stopPropagation()

      const error = await downloadReport(job.jobId)

      if (error !== undefined) {
        notifications.push({ type: 'error', title: t(getUserErrorTranslationKey(error)) })
      } else {
        setHideButton(true)
      }
    },
    [job.jobId, notifications, t, downloadReport]
  )

  // Reset hide state if job status changes
  React.useEffect(() => {
    setHideButton(false)
  }, [job.status])

  switch (job.status) {
    case 'pending':
      return (
        <OffsetView>
          <LoadingSpinner color='currentColor' size='small' />
          <Text size='small' color='grey40'>
            {t('manage.reports.pending')}
          </Text>
        </OffsetView>
      )
    case 'running':
      return (
        <OffsetView>
          <LoadingSpinner color='currentColor' size='small' />
          <Text size='small' color='grey40'>
            {t('manage.reports.running')}
          </Text>
        </OffsetView>
      )
    case 'successful':
      if (hideButton) return null

      return (
        <Button
          icon='download'
          decoratorPosition='left'
          variant='transparent'
          onClick={triggerDownloadReport}
        >
          {t('manage.reports.download')}
        </Button>
      )
    case 'no-data':
      return (
        <OffsetView>
          <Text size='small' color='grey40'>
            {t('manage.reports.no-data')}
          </Text>
        </OffsetView>
      )
    case 'error':
      return (
        <OffsetView>
          <Text size='small' color='grey40'>
            {t('manage.reports.error')}
          </Text>
        </OffsetView>
      )
  }
}
