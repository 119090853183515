import React, { DragEvent, FC, useCallback, useId, useMemo, useRef, useState } from 'react'
import { getMediaLibraryImageUrl } from 'sierra-client/api/content'
import { useNotif } from 'sierra-client/components/common/notifications'
import {
  mediaLibraryImageTagged,
  mediaLibraryImageUploaded,
  mediaLibraryTagAdded,
} from 'sierra-client/core/logging/media-library/logger'
import { usePost } from 'sierra-client/hooks/use-post'
import { useTranslation } from 'sierra-client/hooks/use-translation'
import { useCachedQuery } from 'sierra-client/state/api'
import { useDispatch } from 'sierra-client/state/hooks'
import {
  createDataURlFromThumbhashBase64,
  createImageThumbhashBase64,
} from 'sierra-client/utils/image-thumbhash'
import { ScrollContainer } from 'sierra-client/views/v3-author/images/scroll-container'
import { ImageUnion } from 'sierra-domain/content/v2/content'
import {
  XRealtimeAdminConfirmMediaLibraryImageUpload,
  XRealtimeAdminMediaLibraryCreateTag,
  XRealtimeAdminMediaLibraryDeleteMedia,
  XRealtimeAdminMediaLibraryDeleteTag,
  XRealtimeAdminMediaLibraryGetUploadUrl,
  XRealtimeAdminMediaLibraryRenameTag,
  XRealtimeAdminMediaLibraryTagMedia,
  XRealtimeAdminMediaLibraryUntagMedia,
  XRealtimeAuthorMediaLibraryGetAllAvailableTags,
} from 'sierra-domain/routes'
import { isDefined } from 'sierra-domain/utils'
import { EditableText, Icon, MenuItem, Modal } from 'sierra-ui/components'
import { Button, Heading, IconButton, Text, View } from 'sierra-ui/primitives'
import { IconMenu } from 'sierra-ui/primitives/menu-dropdown'
import { token } from 'sierra-ui/theming'
import { v2_breakpoint } from 'sierra-ui/theming/breakpoints'
import styled, { css } from 'styled-components'

type Tag = {
  id: string
  name: string
}

const useAllMediaTag = (): Tag => {
  const { t } = useTranslation()

  return {
    name: t('dictionary.all-media'),
    id: 'all-media',
  }
}

export type MediaFile = {
  image: ImageUnion
  id: string
  tags: Tag[]
  size: number
}

const CloseButtonWrapper = styled.div`
  position: absolute;
  top: 8px;
  right: 8px;
`

const HiddenInput = styled.input`
  opacity: 0;
  width: 0;
  height: 0;
  overflow: hidden;
  pointer-events: none;
`

const MediaItemOuterContainer = styled.div`
  display: inline-block;
  width: 100%;
  margin-bottom: 24px;
`

const MediaIconFlowMenuContainer = styled.div`
  visibility: hidden;
`

const MediaItemInnerContainer = styled(View).attrs({ direction: 'column', gap: '4' })`
  &:hover {
    ${MediaIconFlowMenuContainer} {
      visibility: visible;
    }
  }
`

const MediaImageContainer = styled.div`
  background-color: ${token('surface/strong')};
  border-radius: 16px;
  width: 100%;
`

const MediaImage = styled.img<{ $placeholderBackground?: string }>`
  display: block;
  border-radius: 16px;
  width: 100%;
  height: auto;

  background: ${p =>
    p.$placeholderBackground !== undefined ? `center / cover url(${p.$placeholderBackground})` : undefined};
`

const MediaMasonryContainer = styled.div`
  column-count: 4;
  break-inside: avoid;
  column-gap: 16px;
  height: 100%;
`

const acceptedMimeTypes = ['image/gif', 'image/jpeg', 'image/png', 'image/webp']

const DropZone = styled.label<{ $dragging: boolean }>`
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  width: 100%;
  height: 100%;
  color: ${token('foreground/muted')};

  ${p =>
    p.$dragging === true &&
    css`
      background: #eaf9ff;
      color: ${token('foreground/primary')};
    `}
`

const TabContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: flex-start;
  width: 180px;
  gap: 4px;
`

const TabOverFlowMenuContainer = styled.div`
  margin-left: auto;
  visibility: hidden;
`

const Tab = styled(View)<{ selected: boolean }>`
  cursor: pointer;
  background: transparent;
  padding: 8px 12px;
  margin: 0rem 2px;
  border-radius: 10px;
  width: 100%;
  transition: all 150ms cubic-bezier(0.25, 0.1, 0.25, 1);
  white-space: nowrap;
  height: 36px;

  &:hover {
    ${p =>
      p.selected === false &&
      css`
        background-color: ${token('surface/soft')};
        transition: all 150ms cubic-bezier(0.25, 0.1, 0.25, 1);
      `}
    ${TabOverFlowMenuContainer} {
      visibility: visible;
    }
  }

  ${p =>
    p.selected === true &&
    css`
      background-color: ${token('surface/soft')};
    `}
`

const SideBarWrapper = styled(View)`
  padding-right: 24px;
  height: 100%;

  @media screen and (max-width: ${v2_breakpoint.desktop_small}) {
    padding-right: 12px;
  }

  @media screen and (max-width: ${v2_breakpoint.tablet}) {
    border-right: none;
  }
`

const UploadButton: FC<{
  handleFileUpload: (e: React.ChangeEvent<HTMLInputElement>) => Promise<void>
  handleClick: () => void
  fileInput: React.RefObject<HTMLInputElement>
  isUploading: boolean
}> = ({ handleFileUpload, handleClick, fileInput, isUploading }) => {
  const { t } = useTranslation()
  return (
    <>
      <HiddenInput
        type='file'
        multiple
        accept={acceptedMimeTypes.toString()}
        ref={fileInput}
        onChange={handleFileUpload}
      />
      <Button loading={isUploading} icon='upload' variant='secondary' onClick={handleClick}>
        {t('author.slate.upload-image-tab')}
      </Button>
    </>
  )
}

const StyledEditableTitle = styled(EditableText)`
  max-width: 24ch;
  white-space: nowrap;
  overflow: hidden;

  background: transparent;

  transform: translateY(1px);
`

const TagTab: FC<{
  tag: Tag
  onRename: (updatedValue: string) => void
  selected: boolean
  onClick?: (tab: Tag) => void
  refetchTags: () => void
  focusedTag: string | undefined
}> = ({ tag, onRename, selected, onClick, refetchTags, focusedTag }) => {
  const { postWithUserErrorException } = usePost()
  const { t } = useTranslation()
  const [contentEditable, setContentEditable] = useState(false)
  const inputRef = useRef<HTMLParagraphElement>(null)

  const allMediaTag = useAllMediaTag()

  return (
    <Tab selected={selected} onClick={() => onClick?.(tag)}>
      <Icon
        iconId={selected ? 'tag--filled' : 'tag'}
        color={selected ? 'foreground/primary' : 'foreground/muted'}
      />
      <StyledEditableTitle
        ref={inputRef}
        aria-labelledby='editable-title-label'
        contentEditable={contentEditable || focusedTag === tag.id}
        setContentEditable={setContentEditable}
        value={tag.name}
        bold
        id={tag.id}
        size={'small'}
        color={selected ? 'foreground/primary' : 'foreground/muted'}
        className='contentTitle'
        onRename={onRename}
        withSingleClick={false}
      />

      <TabOverFlowMenuContainer onClick={e => e.stopPropagation()}>
        <IconMenu
          iconId={'overflow-menu--horizontal'}
          size='small'
          variant='transparent'
          onSelect={async () => {
            await postWithUserErrorException(XRealtimeAdminMediaLibraryDeleteTag, { tagId: tag.id })
            refetchTags()

            onClick?.(allMediaTag)
          }}
          menuItems={[
            {
              id: 'delete',
              type: 'label',
              color: 'destructive/background',
              label: t('dictionary.remove'),
              icon: 'trash-can',
            },
          ]}
        />
      </TabOverFlowMenuContainer>
    </Tab>
  )
}

const focusNewTagInput = (id: string): void => {
  // Wrap in timeout so focus works
  setTimeout(() => {
    const el = document.getElementById(id)
    if (!el) return

    el.focus()

    const range = document.createRange()
    const selection = window.getSelection()
    //Move cursor to the end of the current title
    requestAnimationFrame(() => {
      const textNode = el.childNodes[0]
      if (textNode !== undefined) {
        range.setStart(textNode, el.innerText.length)
        range.collapse(true)

        selection?.removeAllRanges()
        selection?.addRange(range)
      }
    })
  }, 100)
}

const NewTagTab: FC<{
  navigateToTab: (tab: Tag) => void
  refetchTags: () => void
  startRename: (tagId: string) => void
}> = ({ navigateToTab, refetchTags, startRename }) => {
  const { t } = useTranslation()
  const { postWithUserErrorException } = usePost()
  const dispatch = useDispatch()

  const createTag = useCallback(async (): Promise<void> => {
    const result = await postWithUserErrorException(XRealtimeAdminMediaLibraryCreateTag, {
      tagName: 'Untitled tag',
    })

    void dispatch(mediaLibraryTagAdded({}))
    refetchTags()
    const newTagId = result.id
    navigateToTab({ id: newTagId, name: 'Untitled tag' })

    startRename(newTagId)
  }, [dispatch, navigateToTab, postWithUserErrorException, startRename, refetchTags])

  return (
    <Tab selected={false} onClick={createTag}>
      <Icon iconId='new-tag' color='foreground/muted' />
      <Text color='foreground/muted' bold>
        {t('manage.skill.new-skill')}
      </Text>
    </Tab>
  )
}

const ModalSidebar: FC<{
  handleFileUpload: (e: React.ChangeEvent<HTMLInputElement>) => Promise<void>
  handleClick: () => void
  fileInput: React.RefObject<HTMLInputElement>
  tags: Tag[]
  refetchTags: () => void
  currentTab: Tag
  onTabChange: (tab: Tag) => void
  isMediaLibraryNotEmpty: boolean
  isUploading: boolean
}> = props => {
  const { t } = useTranslation()
  const { postWithUserErrorException } = usePost()
  const allMediaTag = useAllMediaTag()
  const [focusedTag, setFocusedTag] = useState<string | undefined>(undefined)

  const startRename = useCallback((tagId: string): void => {
    setFocusedTag(tagId)
    focusNewTagInput(tagId)
  }, [])

  return (
    <SideBarWrapper
      direction='column'
      alignItems='flex-start'
      gap={'24 none'}
      paddingTop='24'
      paddingLeft='32'
      paddingBottom='24'
    >
      <Heading bold size='h5'>
        {t('dictionary.media-library')}
      </Heading>
      <TabContainer>
        <Tab selected={props.currentTab.id === allMediaTag.id} onClick={() => props.onTabChange(allMediaTag)}>
          <Icon
            iconId='media--library'
            color={props.currentTab.id === allMediaTag.id ? 'foreground/primary' : 'foreground/muted'}
          />
          <Text
            bold
            size='small'
            color={props.currentTab.id === allMediaTag.id ? 'foreground/primary' : 'foreground/muted'}
          >
            {allMediaTag.name}
          </Text>
        </Tab>
        {props.tags.map(tag => (
          <TagTab
            focusedTag={focusedTag}
            onClick={props.onTabChange}
            selected={props.currentTab.id === tag.id}
            key={tag.id}
            tag={tag}
            refetchTags={props.refetchTags}
            onRename={async newName => {
              if (newName.trim().length > 0) {
                await postWithUserErrorException(XRealtimeAdminMediaLibraryRenameTag, {
                  tagId: tag.id,
                  newName: newName.trim(),
                })
                setFocusedTag(undefined)
                props.refetchTags()
              }
            }}
          />
        ))}

        <NewTagTab
          startRename={startRename}
          refetchTags={props.refetchTags}
          navigateToTab={props.onTabChange}
        />
      </TabContainer>

      {props.isMediaLibraryNotEmpty && (
        <View grow justifyContent='flex-end' direction='column' gap='16'>
          <UploadButton
            handleFileUpload={props.handleFileUpload}
            handleClick={props.handleClick}
            fileInput={props.fileInput}
            isUploading={props.isUploading}
          />
          <Text lineLength={32} color='foreground/muted' size='micro'>
            {t('manage.media-library.empty')}
          </Text>
        </View>
      )}
    </SideBarWrapper>
  )
}

const Form = styled.form`
  height: 100%;
  width: 100%;
`

const UploadWrapper = styled(View)`
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
`

const MAX_IMAGE_SIZE_MB = 20
const MAX_IMAGE_SIZE_BYTES = MAX_IMAGE_SIZE_MB * 1024 * 1024

type UseMediaLibraryActions = {
  uploadToMediaLibrary: (
    blob: Blob,
    tagId: string | undefined,
    uploadedBy: 'browse' | 'drag-and-drop'
  ) => Promise<{ mediaId: string; url: string }>
  deleteFromMediaLibrary: (mediaId: string) => Promise<void>
}

const getImageMetadataFromBlob = async (
  blob: Blob
): Promise<{ width: number; height: number; thumbHashBase64?: string }> => {
  const img = new Image()
  img.src = URL.createObjectURL(blob)
  await img.decode()
  const thumbHashBase64 = createImageThumbhashBase64(img)

  return {
    width: img.width,
    height: img.height,
    thumbHashBase64,
  }
}

const useMediaLibraryActions = (): UseMediaLibraryActions => {
  const { postWithUserErrorException } = usePost()
  const dispatch = useDispatch()
  const { t } = useTranslation()
  const notif = useNotif()

  const uploadToMediaLibrary = useCallback<UseMediaLibraryActions['uploadToMediaLibrary']>(
    async (blob, tagId, uploadedBy) => {
      const size = blob.size
      if (blob.size > MAX_IMAGE_SIZE_BYTES) {
        notif.push({
          type: 'error',
          body: t('manage.media-library.file-too-large', { size: MAX_IMAGE_SIZE_MB }),
        })
        throw new Error(`File too large (max size ${MAX_IMAGE_SIZE_MB} MB)`)
      }

      const { width, height, thumbHashBase64 } = await getImageMetadataFromBlob(blob)

      const { mediaId, url } = await postWithUserErrorException(XRealtimeAdminMediaLibraryGetUploadUrl, {
        size,
        width,
        height,
        tagId,
        thumbHashBase64,
      })

      await fetch(url, {
        method: 'PUT',
        body: blob,
        headers: { 'Content-Type': blob.type },
      })

      await postWithUserErrorException(XRealtimeAdminConfirmMediaLibraryImageUpload, {
        mediaId,
      }).then(() => {
        void dispatch(mediaLibraryImageUploaded({ uploadedBy }))
      })

      return {
        mediaId,
        url,
      }
    },
    [dispatch, notif, postWithUserErrorException, t]
  )

  const deleteFromMediaLibrary = useCallback<UseMediaLibraryActions['deleteFromMediaLibrary']>(
    async (mediaId: string) => {
      await postWithUserErrorException(XRealtimeAdminMediaLibraryDeleteMedia, { mediaId })
    },
    [postWithUserErrorException]
  )

  return { uploadToMediaLibrary, deleteFromMediaLibrary }
}

const ScrollGridContainer = styled(ScrollContainer)`
  max-height: 100%;
  min-height: 100%;
  width: 100%;
  overflow-y: scroll;
  overflow-x: hidden;
  padding: 24px;
  display: flex;
  flex-direction: column;
  gap: 16px;
`

const TagLabel = styled(View)`
  border-radius: 50px;
  background-color: ${token('surface/soft')};
  padding: 3px 8px 3px 8px;
  cursor: pointer;
`

const useGetTags = (): { tags: Tag[]; refetchTags: () => void } => {
  const tagsQuery = useCachedQuery(XRealtimeAuthorMediaLibraryGetAllAvailableTags, {})
  const tags: Tag[] = tagsQuery.data?.tags ?? []
  return { tags, refetchTags: tagsQuery.refetch }
}

type UseTagMedia = {
  tagMedia: (mediaId: string, tagId: string) => Promise<void>
  untagMedia: (mediaId: string, tagId: string) => Promise<void>
}

const useTagMediaActions = (): UseTagMedia => {
  const { postWithUserErrorException } = usePost()

  const untagMedia = useCallback<UseTagMedia['untagMedia']>(
    async (mediaId: string, tagId: string) => {
      await postWithUserErrorException(XRealtimeAdminMediaLibraryUntagMedia, { mediaId, tagId })
    },
    [postWithUserErrorException]
  )

  const tagMedia = useCallback<UseTagMedia['tagMedia']>(
    async (mediaId: string, tagId: string) => {
      await postWithUserErrorException(XRealtimeAdminMediaLibraryTagMedia, { mediaId, tagId })
    },
    [postWithUserErrorException]
  )

  return { tagMedia, untagMedia }
}

const MediaLibraryImage: FC<{
  media: MediaFile
  refetchMediaLibrary: () => void
  onLabelClicked: (tag: Tag) => void
  availableTags: Tag[]
}> = ({ media, onLabelClicked, refetchMediaLibrary, availableTags }) => {
  const [isImageLoaded, setIsImageLoaded] = useState(false)
  const { t } = useTranslation()
  const { deleteFromMediaLibrary } = useMediaLibraryActions()

  const { tagMedia, untagMedia } = useTagMediaActions()
  const dispatch = useDispatch()

  const handleTagMedia = async (mediaId: string, tagId: string, checked: boolean): Promise<void> => {
    if (!checked) {
      await tagMedia(mediaId, tagId)
      void dispatch(mediaLibraryImageTagged({}))
      refetchMediaLibrary()
    } else {
      await untagMedia(mediaId, tagId)
      refetchMediaLibrary()
    }
  }

  if (media.image.type !== 'media-library-image') {
    console.error('Found non-media-library image in media library: ', media)
    return null
  }

  const placeholder =
    media.image.thumbHashBase64 !== undefined && !isImageLoaded
      ? createDataURlFromThumbhashBase64(media.image.thumbHashBase64)
      : undefined

  return (
    <MediaItemOuterContainer key={media.id}>
      <MediaItemInnerContainer>
        <MediaImageContainer>
          <MediaImage
            src={getMediaLibraryImageUrl(media.image, { width: 256 })}
            loading='lazy'
            width={media.image.width}
            height={media.image.height}
            $placeholderBackground={placeholder}
            onLoad={() => setIsImageLoaded(true)}
          />
        </MediaImageContainer>
        <View direction='column' gap='4'>
          <View justifyContent='space-between'>
            <View wrap='wrap' gap='6'>
              <Text color='foreground/muted' size='micro'>
                {(media.size / 1024).toFixed(1)}KB
              </Text>

              <Icon iconId='radio-button--dot' size='size-12' />
              <Text color='foreground/muted' size='micro'>
                {media.image.width} x {media.image.height}
              </Text>
            </View>
            <MediaIconFlowMenuContainer>
              <IconMenu
                onSelect={async item => {
                  if (item.id === 'delete') {
                    await deleteFromMediaLibrary(media.id)
                    refetchMediaLibrary()
                  }
                }}
                iconId='overflow-menu--horizontal'
                size='small'
                variant='transparent'
                menuItems={[
                  {
                    id: 'delete',
                    type: 'label',
                    color: 'destructive/background',
                    label: t('dictionary.remove'),
                    icon: 'trash-can',
                  },
                  {
                    id: 'tag',
                    type: 'nested',
                    hidden: !(availableTags.length > 0),
                    label: t('content.skill'),
                    icon: 'tag',
                    menuItems: availableTags.map(tag => {
                      const checked = media.tags.some(mediaTag => mediaTag.id === tag.id)
                      return {
                        id: tag.id,
                        type: 'checkbox',
                        label: tag.name,
                        checked: checked,
                        onToggleChange: () => {
                          void handleTagMedia(media.id, tag.id, checked)
                        },
                      } as MenuItem
                    }),
                  },
                ]}
              />
            </MediaIconFlowMenuContainer>
          </View>
          <View gap='4'>
            {media.tags.map(tag => (
              <TagLabel
                key={tag.id}
                alignItems='center'
                justifyContent='center'
                onClick={() => onLabelClicked(tag)}
              >
                <Text size='technical' bold color='grey90'>
                  {tag.name}
                </Text>
              </TagLabel>
            ))}
          </View>
        </View>
      </MediaItemInnerContainer>
    </MediaItemOuterContainer>
  )
}

const MediaLibraryContent: FC<{
  mediaLibraryContent: MediaFile[]
  refetchMediaLibrary: () => void
  onLabelClicked: (tag: Tag) => void
  availableTags: Tag[]
  tabName: string
}> = ({ mediaLibraryContent, refetchMediaLibrary, availableTags, onLabelClicked, tabName }) => {
  return (
    <ScrollGridContainer>
      <Text bold size='large'>
        {tabName}
      </Text>
      <MediaMasonryContainer>
        {mediaLibraryContent.map(media => (
          <MediaLibraryImage
            key={media.id}
            availableTags={availableTags}
            media={media}
            onLabelClicked={onLabelClicked}
            refetchMediaLibrary={refetchMediaLibrary}
          />
        ))}
      </MediaMasonryContainer>
    </ScrollGridContainer>
  )
}

const EmptyIcon = styled(Icon)`
  margin-bottom: 8px;
`

const EmptyLibraryComponent: FC<{
  handleFileUpload: (e: React.ChangeEvent<HTMLInputElement>) => Promise<void>
  handleClick: () => void
  fileInput: React.RefObject<HTMLInputElement>
  isUploading: boolean
}> = props => {
  const { t } = useTranslation()

  return (
    <UploadWrapper grow paddingTop='32' paddingLeft='48' direction='column' gap='16'>
      <EmptyIcon iconId={'tag'} color={'foreground/muted'} size='size-24' />
      <View direction='column' alignItems='center'>
        <Text bold color='foreground/muted'>
          {t('mycontent.nothing-here-yet')}
        </Text>
        <Text lineLength={45} color='foreground/muted' align='center'>
          {t('manage.media-library.empty')}
        </Text>
      </View>
      <UploadButton
        handleFileUpload={props.handleFileUpload}
        handleClick={props.handleClick}
        fileInput={props.fileInput}
        isUploading={props.isUploading}
      />
    </UploadWrapper>
  )
}

const ImagePickerWrapper = styled(View)`
  width: 100%;
  height: 100%;
  flex-direction: row;
`

const ImageSourcesWrapper = styled(View)`
  width: 100%;
  height: 100%;
`

export const MediaLibraryUploadImageModal: FC<{
  onClose: () => void
  open: boolean
  mediaLibraryContent: MediaFile[] | undefined
  refetchMediaLibrary: () => void
}> = ({ open, onClose, mediaLibraryContent, refetchMediaLibrary }) => {
  const { uploadToMediaLibrary } = useMediaLibraryActions()
  const fileInput = useRef<HTMLInputElement>(null)
  const fileInputDomId = useId()
  const [isDragging, setIsDragging] = useState(false)
  const notifications = useNotif()

  const allMediaTag = useAllMediaTag()

  const [tab, setTab] = useState<Tag>(allMediaTag)

  const { tags, refetchTags } = useGetTags()

  const handleDragEnter = useCallback((event: DragEvent<HTMLFormElement>) => {
    event.preventDefault()
    event.stopPropagation()
    setIsDragging(true)
  }, [])

  const handleDragLeave = useCallback((event: DragEvent<HTMLFormElement>) => {
    event.preventDefault()
    event.stopPropagation()
    setIsDragging(false)
  }, [])

  const handleClick = (): void => {
    if (fileInput.current === null) return
    fileInput.current.click()
  }

  const uploadMedia = async (files: File[], uploadedBy: 'browse' | 'drag-and-drop'): Promise<void> => {
    const promises = files.map(async file => {
      if (!acceptedMimeTypes.includes(file.type)) {
        notifications.push({
          type: 'error',
        })
        return
      }

      if (acceptedMimeTypes.includes(file.type)) {
        const tagId = tags.map(tag => tag.id).find(id => id === tab.id)
        await uploadToMediaLibrary(file, tagId, uploadedBy)
      }
    })

    await Promise.all(promises)
  }

  const [isUploading, setIsUploading] = useState(false)
  const handleFileUpload = async (e: React.ChangeEvent<HTMLInputElement>): Promise<void> => {
    if (e.target.files === null) return

    setIsUploading(true)
    try {
      await uploadMedia(Array.from(e.target.files), 'browse')
    } finally {
      setIsUploading(false)
    }
    refetchMediaLibrary()
  }

  const content =
    isDefined(mediaLibraryContent) && mediaLibraryContent.length > 0 ? mediaLibraryContent : undefined
  const filteredContent = useMemo(() => {
    return tab.id === allMediaTag.id
      ? content
      : content?.filter(media => media.tags.some(tag => tag.id === tab.id))
  }, [allMediaTag.id, content, tab.id])

  return (
    <Modal size='full-screen' open={open} onClose={onClose} disableScrollbarGutter>
      <CloseButtonWrapper>
        <IconButton iconId='close' onClick={onClose} variant='transparent' />
      </CloseButtonWrapper>
      <ImagePickerWrapper alignItems='flex-start' gap='none'>
        <Form
          onDragOver={handleDragEnter}
          onDragEnter={handleDragEnter}
          onDragEnd={handleDragLeave}
          onDragLeave={handleDragLeave}
          onDrop={async event => {
            event.preventDefault()
            event.stopPropagation()
            setIsDragging(false)

            await uploadMedia(Array.from(event.dataTransfer.files), 'drag-and-drop')
            refetchMediaLibrary()
          }}
        >
          <DropZone htmlFor={fileInputDomId} $dragging={isDragging}>
            <ModalSidebar
              isMediaLibraryNotEmpty={isDefined(filteredContent) && filteredContent.length > 0}
              currentTab={tab}
              onTabChange={(tag: Tag) => setTab(tag)}
              tags={tags}
              refetchTags={refetchTags}
              handleClick={handleClick}
              handleFileUpload={handleFileUpload}
              fileInput={fileInput}
              isUploading={isUploading}
            />

            <ImageSourcesWrapper>
              {isDefined(filteredContent) && filteredContent.length > 0 ? (
                <MediaLibraryContent
                  mediaLibraryContent={filteredContent}
                  refetchMediaLibrary={refetchMediaLibrary}
                  availableTags={tags}
                  onLabelClicked={(tag: Tag) => setTab(tag)}
                  tabName={tab.name}
                />
              ) : (
                <EmptyLibraryComponent
                  handleClick={handleClick}
                  handleFileUpload={handleFileUpload}
                  fileInput={fileInput}
                  isUploading={isUploading}
                />
              )}
            </ImageSourcesWrapper>
          </DropZone>
        </Form>
      </ImagePickerWrapper>
    </Modal>
  )
}
