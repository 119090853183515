import React from 'react'
import { editorGrid } from 'sierra-client/editor/layout'
import { TextToSpeech } from 'sierra-client/editor/text-to-speech'
import {
  CheckListProvider,
  useCheckListSelfpacedData,
} from 'sierra-client/views/v3-author/list/checklist-data-layer'
import { getListItemSize, ListItemSizes } from 'sierra-client/views/v3-author/list/utils'
import { assertElementType } from 'sierra-client/views/v3-author/queries'
import { SlateFC, SlateWrapperProps } from 'sierra-client/views/v3-author/slate'
import { NanoId12 } from 'sierra-domain/api/nano-id'
import { Checkbox } from 'sierra-ui/primitives'
import { spacing } from 'sierra-ui/theming'
import { fonts } from 'sierra-ui/theming/fonts'
import styled, { css } from 'styled-components'

export const CheckListItemCss = css`
  position: relative;
`

const CheckListCss = css`
  list-style: none;
  text-indent: -15px;

  &&& {
    row-gap: 0;
  }

  & li {
    ${CheckListItemCss}
  }

  ul li::before,
  &::before {
    color: ${p => p.theme.home.textColor};
    position: absolute;
    left: 0;
  }
`

const _CheckList = styled.ul`
  ${CheckListCss}
  position: relative;

  ${editorGrid}
`

export const CheckListWrapper = React.forwardRef<HTMLUListElement, SlateWrapperProps>(
  ({ element, children, attributes, mode, ...props }, ref) => {
    return (
      <_CheckList {...attributes} {...props} ref={ref}>
        {children}
        <TextToSpeech element={element} />
      </_CheckList>
    )
  }
)

export const CheckListItem: SlateFC = ({ children, ...props }) => <span {...props}>{children}</span>

const ChecklistItem = styled.li<{
  checked: boolean
  $indent?: number
  $level: ListItemSizes
}>`
  ${p =>
    !p.theme.editor.withGrid &&
    css`
      && {
        padding-left: 0;
      }
    `}
  display: flex;
  color: ${p => p.theme.home.textColor};
  margin: ${spacing['4']} 0;
  margin-left: ${p => p.$indent !== undefined && p.$indent > 0 && `calc(${p.$indent} * 1.5rem - 8px)`};
  align-items: center;
  gap: ${spacing[4]};
  text-indent: -0.8px;

  ${p =>
    p.$level === 'large' || p.$level === 'regular' || p.$level === 'small'
      ? fonts.body[p.$level]
      : fonts.heading[p.$level]}

  ${p =>
    p.checked &&
    css`
      text-decoration: line-through;
    `}
`
const CheckboxWrapper = styled.div`
  display: flex;
  align-items: center;
  padding-right: 0.2em;
`

export const CheckListItemContainer = React.forwardRef(
  (
    { element, children, mode, readOnly, ...attributes }: SlateWrapperProps,
    ref: React.ForwardedRef<HTMLLIElement>
  ) => {
    assertElementType('check-list-item', element)

    const selfPacedCheckData = useCheckListSelfpacedData()
    const { checkedIds } = selfPacedCheckData

    const isItemChecked =
      checkedIds === undefined ? element.checked : checkedIds.includes(NanoId12.parse(element.id))

    return (
      <ChecklistItem
        ref={ref}
        {...attributes}
        checked={isItemChecked}
        $indent={element.indent ?? 0}
        $level={getListItemSize(element.level)}
      >
        <CheckboxWrapper contentEditable={false}>
          <Checkbox
            level={element.level}
            id={element.id}
            checked={isItemChecked}
            onCheckedChange={async () => {
              if (readOnly && (mode === 'create' || mode === 'version-history')) return
              await selfPacedCheckData.setCheck(!isItemChecked, NanoId12.parse(element.id))
            }}
          />
        </CheckboxWrapper>
        {children}
      </ChecklistItem>
    )
  }
)

export const CheckList: SlateFC = props => {
  assertElementType('check-list', props.element)

  return (
    <CheckListProvider element={props.element} mode={props.mode}>
      {props.children}
    </CheckListProvider>
  )
}
