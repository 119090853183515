import React, { useCallback, useEffect } from 'react'
import { useTranslation } from 'sierra-client/hooks/use-translation'
import { InsightsAssistantInput } from 'sierra-client/lib/assistant-input/search-insights/insights-assistant-input'
import { SanaGradientIcon } from 'sierra-client/lib/assistant-input/search-insights/sana-gradient-icon'
import { Filter } from 'sierra-client/lib/filter'
import { MenuItemWithDomainRep } from 'sierra-client/lib/filter/components/types'
import { useInsightsContentSuggestionEditor } from 'sierra-client/views/insights/insights-content-search/use-insights-content-suggestion-editor'
import { serializeInsightContentSuggestions } from 'sierra-client/views/insights/insights-content-search/utils'
import { AiText } from 'sierra-ui/components'
import { IconButton, Text, View } from 'sierra-ui/primitives'
import { token } from 'sierra-ui/theming'
import { useOnChanged } from 'sierra-ui/utils'
import styled from 'styled-components'

const InputContainer = styled(View)`
  border-radius: 8px;
  border: 1px solid ${token('border/default')};
  flex: 0 0 250px;
  width: 250px;
`

const GeneratingView = styled(View)`
  height: 36px;
  border: 1px solid ${token('border/strong')};
`

export type FilterGenerationState = { type: 'closed' } | { type: 'writing' } | { type: 'generating' }

export const GenerateFilterInput: React.FC<{
  filterGenerationState: FilterGenerationState
  setFilterGenerationState: React.Dispatch<React.SetStateAction<FilterGenerationState>>
  generateFilter: (query: string) => Promise<Filter | undefined>
}> = ({ filterGenerationState, setFilterGenerationState, generateFilter }) => {
  const { t } = useTranslation()

  const insightsEditor = useInsightsContentSuggestionEditor()
  const askAnythingQuery = serializeInsightContentSuggestions(insightsEditor)

  useOnChanged(previouState => {
    if (previouState === 'closed' && filterGenerationState.type === 'writing') {
      insightsEditor?.commands.setContent('')
      insightsEditor?.commands.focus('end')
    }
  }, filterGenerationState.type)

  const submitFilterGeneration = useCallback(
    async (query: string) => {
      setFilterGenerationState({ type: 'generating' })
      try {
        await generateFilter(query)
        setFilterGenerationState({ type: 'closed' })
      } catch (e) {
        setFilterGenerationState({ type: 'writing' })
        insightsEditor?.commands.focus('end')
      }
    },
    [generateFilter, insightsEditor?.commands, setFilterGenerationState]
  )

  useEffect(() => {
    if (filterGenerationState.type !== 'writing') return
    const handleKeyPress = async (event: KeyboardEvent): Promise<void> => {
      if (event.key === 'Enter' && askAnythingQuery !== '' && insightsEditor?.isFocused === true) {
        void submitFilterGeneration(askAnythingQuery)
      }
    }

    window.addEventListener('keydown', handleKeyPress)
    return () => {
      window.removeEventListener('keydown', handleKeyPress)
    }
  }, [
    askAnythingQuery,
    filterGenerationState,
    generateFilter,
    insightsEditor?.isFocused,
    setFilterGenerationState,
    submitFilterGeneration,
  ])
  if (filterGenerationState.type === 'writing') {
    return (
      <View gap='2 2'>
        <InputContainer direction='column'>
          <InsightsAssistantInput
            editor={insightsEditor}
            askInsightsQuestion={submitFilterGeneration}
            actionIcon='arrow--enter'
            placeholderText={t('insights.filter.generation.input.placeholder')}
          />
        </InputContainer>
        <IconButton
          variant='transparent'
          iconId='close'
          onClick={() => setFilterGenerationState({ type: 'closed' })}
        />
      </View>
    )
  }
  if (filterGenerationState.type === 'generating') {
    return (
      <GeneratingView position='relative' overflow='hidden' padding='10' radius='size-8'>
        <SanaGradientIcon />
        <AiText base='org/primary'>{t('insights.filter.generating.process')}</AiText>
      </GeneratingView>
    )
  } else {
    return null
  }
}

export const GenerateFilterItem: React.FC = () => {
  const { t } = useTranslation()
  return (
    <View>
      <SanaGradientIcon />
      <Text>{t('insights.generate.filter.menu.label')}</Text>
    </View>
  )
}

export const generateFilterMenuItem = {
  type: 'canvas' as const,
  label: 'AI filter',
  id: 'ai-filter',
  render: GenerateFilterItem,
} as MenuItemWithDomainRep
