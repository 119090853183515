import _ from 'lodash'
import React, { useEffect, useMemo, useState } from 'react'
import { IconListItem } from 'sierra-client/components/common/icon-list'
import { Auth } from 'sierra-client/core/auth'
import { useRankedTags } from 'sierra-client/hooks/use-ordered-tags'
import { usePost } from 'sierra-client/hooks/use-post'
import { useThrottledAndLiveState } from 'sierra-client/hooks/use-throttled-state'
import { useTranslation } from 'sierra-client/hooks/use-translation'
import { useDispatch } from 'sierra-client/state/hooks'
import { fetchTagsData } from 'sierra-client/state/v2/tags-actions'
import { getUserErrorTranslationKey } from 'sierra-client/utils/translation-utils'
import { AuthenticationFooter } from 'sierra-client/views/authentication/native/components/authentication-footer'
import {
  ScaleOnHover,
  SetupCard,
  SetupContainer,
  SetupGrid,
  SetupWrapper,
} from 'sierra-client/views/authentication/native/components/common'
import { RoundedSearchBar } from 'sierra-client/views/manage/components/rounded-search-bar'
import { isLeft } from 'sierra-domain/either'
import { XRealtimeUserSetRegistrationStep, XRealtimeUserSetTags } from 'sierra-domain/routes'
import { Heading, Spacer, Text } from 'sierra-ui/primitives'

const requiredNumberOfSkills = 3

export const SkillsSetup: React.FC<unknown> = () => {
  const { t } = useTranslation()
  const dispatch = useDispatch()
  const { postWithUserErrorCode } = usePost()
  const [throttledSearchTerm, searchTerm, setSearchTerm] = useThrottledAndLiveState('', 100, {
    leading: true,
    trailing: true,
  })
  const [error, setError] = useState<string | undefined>(undefined)
  const [selectedSkillIds, setSelectedSkillIds] = useState<string[]>([])
  const skills = useRankedTags()
  const skillCount = skills.length >= 100 ? `${Math.floor(skills.length / 100) * 100}+` : skills.length

  useEffect(() => {
    void dispatch(fetchTagsData())
  }, [dispatch])

  const onSkillClick = (id: string): void => {
    const newIds = _.includes(selectedSkillIds, id)
      ? _.without(selectedSkillIds, id)
      : [...selectedSkillIds, id]

    setSelectedSkillIds(newIds)
  }

  const { filteredSkills } = useMemo(() => {
    const filteredSkills = skills.filter(s => {
      // Filters out based on query
      if (throttledSearchTerm !== '' && !s.name.toLowerCase().includes(throttledSearchTerm.toLowerCase()))
        return false
      return true
    })
    return { filteredSkills }
  }, [skills, throttledSearchTerm])

  const submit = async (): Promise<void> => {
    setError(undefined)

    const result1 = await postWithUserErrorCode(XRealtimeUserSetTags, {
      tags: selectedSkillIds,
    })

    if (isLeft(result1)) {
      setError(t(getUserErrorTranslationKey(result1.left)))
      return
    }

    const result2 = await postWithUserErrorCode(XRealtimeUserSetRegistrationStep, {
      step: 'done',
    })

    if (isLeft(result2)) {
      setError(t(getUserErrorTranslationKey(result2.left)))
      return
    }

    await Auth.getInstance().synchronize()
  }

  return (
    <SetupWrapper>
      <SetupContainer>
        <Heading color='foreground/primary' size='h2' align='center' bold>
          {t('create-account.skills')}
        </Heading>
        <Spacer size='xsmall' />
        <Text color='foreground/primary' size='small' phone='regular' align='center'>
          {t('skills.select-n-skills', { n: requiredNumberOfSkills })}
        </Text>

        <Spacer size='xsmall' />

        <RoundedSearchBar
          placeholder={t('onboarding.skills.search-placeholder', { n: skillCount })}
          value={searchTerm}
          onChange={text => setSearchTerm(text)}
          maxWidth='100%'
        />
        {error !== undefined && (
          <>
            <Spacer />
            <Text size='small' color='redBright'>
              {error}
            </Text>
          </>
        )}
        <Spacer size='xxlarge' />
      </SetupContainer>
      {filteredSkills.length > 0 ? (
        <SetupGrid>
          {filteredSkills.map(skill => {
            return (
              <ScaleOnHover
                key={skill.id}
                initial={{ scale: 1 }}
                exit={{ scale: 1 }}
                transition={{ ease: [0.25, 0.1, 0.25, 1], duration: 0.18 }}
                whileHover={{ scale: 1.03 }}
                whileTap={{ scale: 1.01 }}
              >
                <SetupCard
                  key={skill.id}
                  $active={selectedSkillIds.includes(skill.id)}
                  onClick={() => onSkillClick(skill.id)}
                >
                  <Heading color='foreground/primary' size='h5' bold>
                    {skill.name}
                  </Heading>
                  {selectedSkillIds.includes(skill.id) && (
                    <IconListItem
                      iconId='checkmark--outline'
                      text={t('modal.selected')}
                      faded={false}
                      color='foreground/secondary'
                    />
                  )}
                </SetupCard>
              </ScaleOnHover>
            )
          })}
        </SetupGrid>
      ) : (
        <Text color='foreground/primary' size='regular' align='center'>
          {t('manage.tags.no-results')}
        </Text>
      )}
      <AuthenticationFooter
        step={2}
        nextUpText={undefined}
        isShowing={selectedSkillIds.length > 0}
        isDisabled={selectedSkillIds.length < Math.min(skills.length, requiredNumberOfSkills)}
        onContinue={submit}
      />
    </SetupWrapper>
  )
}
