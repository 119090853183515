import { createFileRoute } from '@tanstack/react-router'

import React from 'react'
import { requireOwner } from 'sierra-client/core/require-owner'
import { stringWithZodSerializer, useQueryState } from 'sierra-client/lib/querystate/use-query-state'
import { useCachedQuery } from 'sierra-client/state/api'
import { XRealtimeAdminAccessDebug } from 'sierra-domain/routes'
import { FormElement, MenuItem } from 'sierra-ui/components'
import { Button, InputPrimitive, View } from 'sierra-ui/primitives'
import { SingleSelectDropdown } from 'sierra-ui/primitives/menu-dropdown'
import styled from 'styled-components'
import { z } from 'zod'

const Ul = styled.ul`
  li + li {
    margin-top: 4px;
  }
`

type DebugAccessFormValues = {
  userId: string
  resourceType: string
  resourceId: string
}

type DebugAccessFormProps = {
  values: DebugAccessFormValues
  onChange: (values: DebugAccessFormValues) => void
}

const FormWrapper = styled.div`
  display: grid;
  grid-template-columns: 1fr 1fr;
  grid-column-gap: 1.5rem;
  margin-top: 16px;
  margin-left: 16px;
`

const ViewWrapper = styled.div`
  margin-top: 16px;
  margin-left: 32px;
`

const SectionWrapper = styled.div`
  margin-top: 16px;
`

const DebugAccessForm: React.FC<DebugAccessFormProps> = ({ values, onChange }) => {
  const { userId, resourceType, resourceId } = values

  const items: MenuItem[] = [
    // Corresponds to the Resource.Type enum strings in the backend
    { type: 'label', label: 'Organization', id: 'organization' },
    { type: 'label', label: 'Program', id: 'program' },
    { type: 'label', label: 'Teamspace', id: 'teamspace' },
    { type: 'label', label: 'Path', id: 'path' },
    { type: 'label', label: 'Group', id: 'group' },
    { type: 'label', label: 'Self-paced Course', id: 'self-paced-course' },
    { type: 'label', label: 'Live Course', id: 'live-course' },
    { type: 'label', label: 'External Course', id: 'external-course' },
    { type: 'label', label: 'Course Group', id: 'course-group' },
    { type: 'label', label: 'Self-paced Course Reflection', id: 'self-paced-course-reflection' },
    { type: 'label', label: 'Live Session', id: 'live-session' },
    { type: 'label', label: 'Live Session Reflection', id: 'live-session-reflection' },
    { type: 'label', label: 'In-person Event', id: 'event-group' },
    { type: 'label', label: 'Calendar Event', id: 'calendar-event' },
    { type: 'label', label: 'Calendar Event Enrollment Request', id: 'calendar-event-enrollment-request' },
    { type: 'label', label: 'Certificate', id: 'certificate' },
    { type: 'label', label: 'Issued Certificate', id: 'issued-certificate' },
    { type: 'label', label: 'Exercise', id: 'homework' },
    { type: 'label', label: 'Skill', id: 'skill' },
  ]

  return (
    <>
      <FormWrapper>
        <FormElement label={'User ID'} isError={userId === ''}>
          <InputPrimitive value={userId} onChange={e => onChange({ ...values, userId: e.target.value })} />
        </FormElement>
        <FormElement label={'Resource Type'} isError={resourceType === ''}>
          <SingleSelectDropdown
            selectedItem={items.find(item => item.id === resourceType)}
            menuItems={items}
            onSelect={e => onChange({ ...values, resourceType: e.id })}
            withSearch={true}
          />
        </FormElement>
        <FormElement label={'Resource ID'} isError={resourceId === ''}>
          <InputPrimitive
            value={resourceId}
            onChange={e => onChange({ ...values, resourceId: e.target.value })}
            disabled={resourceType === 'organization'}
          />
        </FormElement>
      </FormWrapper>
    </>
  )
}

function hasBadParameters(userId: string, resourceType: string, resourceId: string): boolean {
  return userId === '' || resourceType === '' || (resourceId === '' && resourceType !== 'organization')
}

export const Page: React.FC = () => {
  const [userId, setUserId] = useQueryState(stringWithZodSerializer(z.string()), '', 'userId')
  const [resourceType, setResourceType] = useQueryState(
    stringWithZodSerializer(z.string()),
    '',
    'resourceType'
  )
  const [resourceId, setResourceId] = useQueryState(stringWithZodSerializer(z.string()), '', 'resourceId')

  const accessDebugQuery = useCachedQuery(
    XRealtimeAdminAccessDebug,
    {
      userId: userId,
      resourceType: resourceType,
      resourceId: resourceId,
    },
    { enabled: false, retry: false }
  )

  return (
    <div>
      <DebugAccessForm
        values={{
          userId: userId,
          resourceType: resourceType,
          resourceId: resourceId,
        }}
        onChange={values => {
          setUserId(values.userId)
          setResourceType(values.resourceType)
          if (values.resourceType === 'organization') {
            setResourceId('')
          } else {
            setResourceId(values.resourceId)
          }
        }}
      />
      <ViewWrapper>
        <View>
          <Button
            disabled={hasBadParameters(userId, resourceType, resourceId) || accessDebugQuery.isLoading}
            loading={accessDebugQuery.isLoading}
            onClick={async () => {
              await accessDebugQuery.refetch()
            }}
          >
            Fetch
          </Button>
        </View>
      </ViewWrapper>
      <ViewWrapper>
        {accessDebugQuery.isError && <>Error fetching access state</>}
        {accessDebugQuery.data?.permissions !== undefined && (
          <SectionWrapper>
            <h3>Permissions</h3>
            <Ul>
              {accessDebugQuery.data.permissions.map((permission, idx) => (
                <li key={idx}>{permission}</li>
              ))}
            </Ul>
          </SectionWrapper>
        )}
        {accessDebugQuery.data?.topLevelRoles !== undefined && (
          <SectionWrapper>
            <h3>Assigned Roles</h3>
            <Ul>
              {accessDebugQuery.data.topLevelRoles.map((topLevelRole, idx) => (
                <li key={idx}>{topLevelRole}</li>
              ))}
            </Ul>
          </SectionWrapper>
        )}
        {accessDebugQuery.data?.rolesFromInheritance !== undefined && (
          <SectionWrapper>
            <h3>Inherited roles</h3>
            <Ul>
              {accessDebugQuery.data.rolesFromInheritance.map((inheritedRole, idx) => (
                <li key={idx}>{inheritedRole}</li>
              ))}
            </Ul>
          </SectionWrapper>
        )}
        {accessDebugQuery.data?.topLevelPolicies !== undefined && (
          <SectionWrapper>
            <h3>Policies</h3>
            <Ul>
              {accessDebugQuery.data.topLevelPolicies.map((policy, idx) => (
                <li key={idx}>{policy}</li>
              ))}
            </Ul>
          </SectionWrapper>
        )}
        {accessDebugQuery.data?.policyGraphs !== undefined && (
          <SectionWrapper>
            <h3>Policy graphs</h3>
            {accessDebugQuery.data.policyGraphs.map((policyGraph, idx) => (
              <SectionWrapper key={idx}>
                <pre>{JSON.stringify(JSON.parse(policyGraph), null, 2)}</pre>
              </SectionWrapper>
            ))}
          </SectionWrapper>
        )}
      </ViewWrapper>
    </div>
  )
}

const RouteComponent = requireOwner(Page)

export const Route = createFileRoute('/debugaccess')({
  component: RouteComponent as React.FC,
})
