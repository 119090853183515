import { AnalyticsBrowser } from '@segment/analytics-next'
import Bowser from 'bowser'
import { debounce } from 'lodash'
import { getBrowserSessionTimestamp } from 'sierra-client/components/browser/update-browser-session'
import { UserSingleton } from 'sierra-client/core/user'
import { Context } from 'sierra-domain/segment/common'
import { TrackRequestParams } from 'sierra-domain/segment/event'
import { PageRequest } from 'sierra-domain/segment/page'

export function getOSAndBrowserContext(): Context {
  const userAgent = window.navigator.userAgent
  const context = Bowser.parse(userAgent)

  return {
    userAgent,
    os: context.os,
    device: context.platform,
    browser: context.browser,
  }
}

class SegmentService {
  #analyticsBrowser: AnalyticsBrowser

  constructor() {
    this.#analyticsBrowser = new AnalyticsBrowser()
  }

  initialize(writeKey: string): void {
    this.#analyticsBrowser.load({ writeKey })
  }

  get analyticsBrowser(): AnalyticsBrowser {
    return this.#analyticsBrowser
  }

  async track(trackRequestParams: TrackRequestParams, userId?: string): Promise<void> {
    const browserSessionTimestamp = getBrowserSessionTimestamp()
    const requestUserId = userId ?? UserSingleton.getInstance().getUser()?.uuid
    if (browserSessionTimestamp === null || requestUserId === undefined) return

    const trackRequest = trackRequestParams.getRequest({
      browserSessionTimestamp,
      userId: requestUserId,
      context: getOSAndBrowserContext(),
    })

    const { event, properties, ...trackOptions } = trackRequest
    await this.#analyticsBrowser.track(event, properties, trackOptions)
  }

  async #pageUnlimited(pageRequest: PageRequest): Promise<void> {
    await this.#analyticsBrowser.page(pageRequest)
  }

  page = debounce(this.#pageUnlimited, 200)
}

export const segmentService = new SegmentService()
