import { useCallback, useState } from 'react'
import { ConnectDropTarget, useDrop } from 'react-dnd'
import {
  getRootFolderOfTeamspace,
  isContentInFolder,
  isParentFolderOfCurrentFolder,
  isSubFolderOfCurrentFolder,
  useMoveCoursesMutation,
  useMoveFolderMutation,
} from 'sierra-client/api/hooks/use-teamspace'
import { ContentTableDragItem, DragItemTypes } from 'sierra-client/components/common/dnd/dnd-types'
import { useNotif } from 'sierra-client/components/common/notifications'
import { teamspaceContentMovedLogger } from 'sierra-client/features/teamspace/logger'
import { useToggle } from 'sierra-client/hooks/use-toggle'
import { useTranslation } from 'sierra-client/hooks/use-translation'
import { useDispatch } from 'sierra-client/state/hooks'
import { EditableContent, NonEditorTeamspaceCourseType } from 'sierra-domain/api/editable-content'
import { NanoId12 } from 'sierra-domain/api/nano-id'
import { FolderRow, Teamspace } from 'sierra-domain/api/teamspace'

const nonEditorTeamspaceCourseTypes = ['scorm', 'native:event-group', 'link', 'linkedin']

export const useTeamspaceContentDrop = (
  teamspace: Teamspace | undefined,
  folder: FolderRow | undefined
): {
  isOver: boolean
  canDrop: boolean
  drop: ConnectDropTarget
  confirmModal: { isOpen: boolean; primaryAction: () => void; close: () => void; title: string; body: string }
} => {
  const { mutate: moveContentMutate } = useMoveCoursesMutation()
  const { mutate: moveFolderMutate } = useMoveFolderMutation()

  const { t } = useTranslation()
  const notification = useNotif()
  const dispatch = useDispatch()

  const [isConfirmationOpen, { on: openConfirmation, off: closeConfirmation }] = useToggle(false)

  const [contentId, setContentId] = useState<NanoId12 | undefined>(undefined)
  const [contentType, setContentType] = useState<
    'folder' | EditableContent['type'] | NonEditorTeamspaceCourseType | undefined
  >(undefined)
  const [currentTeamspaceId, setCurrentTeamspaceId] = useState<string | undefined>(undefined)
  const showFolderName = folder !== undefined && folder.parentFolderId !== undefined

  const onSuccessfulContentMove = useCallback(
    (
      contentId: string,
      parentFolderId: string | undefined,
      contentType: 'folder' | EditableContent['type'] | NonEditorTeamspaceCourseType | undefined
    ): void => {
      const teamspaceName = teamspace !== undefined ? teamspace.displayName : t('author.content.my-content')
      const loggingContentType =
        contentType === 'folder'
          ? 'folder'
          : contentType === undefined
            ? undefined
            : nonEditorTeamspaceCourseTypes.includes(contentType)
              ? 'non-editor-content'
              : 'editable-content'

      const body =
        teamspace !== undefined && showFolderName
          ? `${t('teamspace.move.successful')} ${folder.displayName} ${t('dictionary.in')} ${
              teamspace.displayName
            }`
          : `${t('teamspace.move.successful')} ${teamspaceName}`

      notification.push({
        type: 'custom',
        level: 'info',
        body: body,
      })

      void dispatch(
        teamspaceContentMovedLogger({
          teamspaceId: teamspace?.id,
          contentId,
          movedBy: 'dnd',
          contentType: loggingContentType,
          parentFolderId,
        })
      )
    },
    [dispatch, folder, notification, showFolderName, t, teamspace]
  )

  const onSuccessfulFolderMove = useCallback(
    (teamspaceName: string, folderId: string, parentFolderId: string | undefined): void => {
      const body = showFolderName
        ? `${t('teamspace.folder.move.successful')} ${folder.displayName} ${t(
            'dictionary.in'
          )} ${teamspaceName}`
        : `${t('teamspace.folder.move.successful')} ${teamspaceName}`

      notification.push({
        type: 'custom',
        level: 'info',
        body: body,
      })

      void dispatch(
        teamspaceContentMovedLogger({
          teamspaceId: teamspace?.id,
          contentId: folderId,
          movedBy: 'dnd',
          contentType: 'folder',
          parentFolderId,
        })
      )
    },
    [dispatch, folder, notification, showFolderName, t, teamspace?.id]
  )

  const [{ isOver, canDrop }, drop] = useDrop<
    ContentTableDragItem,
    void,
    { isOver: boolean; canDrop: boolean }
  >(() => {
    return {
      accept: DragItemTypes.ContentTableContent,
      collect: monitor => {
        return {
          isOver: monitor.isOver({ shallow: true }),
          canDrop: monitor.canDrop(),
        }
      },
      canDrop: item => {
        if (item.itemType === 'folder') {
          // Folders can only be moved to a teamspace and not My Content
          if (teamspace === undefined) return false

          if (teamspace.effectiveRole !== 'editor' && teamspace.effectiveRole !== 'owner') return false

          // Folders can not be dropped on themselves
          if (folder !== undefined && item.id === folder.id) return false

          // Folders can not be dropped in a subfolder of themselves
          if (folder !== undefined && isSubFolderOfCurrentFolder(teamspace, item.id, folder)) return false

          if (
            item.currentTeamspaceId === teamspace.id &&
            isParentFolderOfCurrentFolder(teamspace, item.id, folder)
          )
            return false

          return !(folder !== undefined && item.id === folder.id)
        }

        // Content can be moved to the same teamspace it already is in, if it is a new folder
        if (teamspace !== undefined && item.currentTeamspaceId === teamspace.id) {
          return (
            folder !== undefined &&
            !isContentInFolder(teamspace, folder.id, item.id) &&
            (teamspace.effectiveRole === 'editor' || teamspace.effectiveRole === 'owner')
          )
        }

        // User is dragging to my content
        if (teamspace === undefined) {
          //check that if user is dragging from my content as well, and that the content is an editable content
          const isDroppingToSelf =
            item.currentTeamspaceId !== undefined &&
            item.highestCollaboratorRole === 'owner' &&
            !['scorm', 'native:event-group', 'link', 'linkedin', 'path'].includes(item.itemType)

          return isDroppingToSelf
        }

        return (
          teamspace.id !== item.currentTeamspaceId &&
          (teamspace.effectiveRole === 'editor' || teamspace.effectiveRole === 'owner')
        )
      },
      drop: item => {
        const targetTeamspaceId = teamspace?.id
        const draggingFromTeamspace = item.currentTeamspaceId !== undefined

        // Dragging from teamspace
        if (draggingFromTeamspace) {
          // Dropping in a folder within the same teamspace
          if (
            teamspace !== undefined &&
            folder !== undefined &&
            item.currentTeamspaceId === teamspace.id &&
            folder.teamspaceId === teamspace.id
          ) {
            if (item.itemType === 'folder') {
              moveFolderMutate(
                {
                  folderId: NanoId12.parse(item.id),
                  teamspaceId: NanoId12.parse(item.currentTeamspaceId),
                  targetTeamspaceId: teamspace.id,
                  targetFolderId: folder.id,
                },
                {
                  onSuccess: () => {
                    onSuccessfulFolderMove(teamspace.displayName, item.id, folder.id)
                  },
                }
              )
              return
            } else {
              moveContentMutate(
                {
                  courseIds: [NanoId12.parse(item.id)],
                  targetTeamspaceId: NanoId12.parse(item.currentTeamspaceId),
                  targetFolderId: folder.id,
                },
                {
                  onSuccess: () => {
                    onSuccessfulContentMove(item.id, folder.id, 'folder')
                  },
                }
              )
              return
            }
          }
          // dragging from a teamspace: show confirmation
          setContentId(NanoId12.parse(item.id))
          setContentType(item.itemType)
          setCurrentTeamspaceId(item.currentTeamspaceId)
          openConfirmation()
        } else if (item.itemType === 'folder' && folder !== undefined && teamspace !== undefined) {
          moveFolderMutate(
            {
              folderId: NanoId12.parse(item.id),
              teamspaceId: NanoId12.parse(item.currentTeamspaceId),
              targetTeamspaceId: teamspace.id,
              targetFolderId: folder.id,
            },
            {
              onSuccess: () => {
                onSuccessfulFolderMove(teamspace.displayName, item.id, folder.id)
              },
            }
          )
        } else {
          const targetFolderId = folder !== undefined ? folder.id : undefined

          moveContentMutate(
            {
              courseIds: [NanoId12.parse(item.id)],
              targetTeamspaceId,
              targetFolderId,
            },
            {
              onSuccess: () => {
                onSuccessfulContentMove(item.id, undefined, item.itemType)
              },
            }
          )
        }
      },
    }
  }, [
    teamspace,
    folder,
    openConfirmation,
    moveFolderMutate,
    onSuccessfulFolderMove,
    moveContentMutate,
    onSuccessfulContentMove,
  ])

  const close = (): void => {
    setContentId(undefined)
    closeConfirmation()
  }

  const primaryAction =
    contentId === undefined
      ? () => {}
      : () => {
          if (contentType !== undefined && contentType === 'folder') {
            const targetFolder = folder !== undefined ? folder : getRootFolderOfTeamspace(teamspace)

            if (targetFolder === undefined || teamspace === undefined || currentTeamspaceId === undefined) {
              return () => {}
            }
            moveFolderMutate(
              {
                folderId: contentId,
                teamspaceId: NanoId12.parse(currentTeamspaceId),
                targetTeamspaceId: teamspace.id,
                targetFolderId: targetFolder.id,
              },
              {
                onSuccess: () => {
                  onSuccessfulFolderMove(teamspace.displayName, contentId, targetFolder.id)
                },
              }
            )
            close()
            return
          }

          const targetFolderId = folder !== undefined ? folder.id : undefined
          moveContentMutate(
            {
              courseIds: [NanoId12.parse(contentId)],
              targetTeamspaceId: teamspace?.id ?? undefined,
              targetFolderId,
            },
            {
              onSuccess: () => {
                onSuccessfulContentMove(contentId, undefined, contentType)
              },
            }
          )
          close()
        }

  const title =
    contentType !== undefined && contentType === 'folder'
      ? t('teamspace.folder.move-content.title')
      : t('teamspace.move-content.title')
  const body =
    contentType !== undefined && contentType === 'folder'
      ? t('teamspace.folder.move-content.body')
      : t('teamspace.move-content.body')

  return {
    isOver,
    canDrop,
    drop,
    confirmModal: { isOpen: isConfirmationOpen, close, primaryAction, title, body },
  }
}
