import isHotkey from 'is-hotkey'
import * as Commands from 'sierra-client/views/v3-author/command'
import { EditorKeyboardEvent, Mark } from 'sierra-domain/v3-author'
import { Editor as SlateEditor } from 'slate'

const keyboardShortcuts: Record<string, Mark> = {
  'mod+b': 'bold',
  'mod+i': 'italic',
  'mod+u': 'underline',
  'mod+`': 'code',
  'mod+shift+x': 'strikethrough',
}

export function runKeyboardShortcuts(event: EditorKeyboardEvent, editor: SlateEditor): void {
  for (const [shortcut, mark] of Object.entries(keyboardShortcuts)) {
    if (isHotkey(shortcut, event)) {
      event.preventDefault()
      Commands.toggleMark(editor, mark)
    }
  }
}
