import { EditorContent } from '@tiptap/react'
import { tiptapCursorStyles } from 'sierra-client/views/sticky-notes-card/common/tiptap-styles'
import styled from 'styled-components'

export const NarrationEditor = styled(EditorContent)`
  cursor: auto;

  flex: 1;
  display: flex;
  min-height: 0;
  overflow: auto;
  flex-direction: column;

  .ProseMirror {
    flex: 1;
    min-height: 0;
    overflow: auto;
    font: inherit;
    padding: 18px 16px;
    padding-bottom: 0;

    > * + * {
      margin-top: 0;
    }

    p {
      line-height: 1.2;
    }
  }

  .ProseMirror p:not(.is-editor-empty) {
    font-size: inherit;
  }

  ${tiptapCursorStyles};
`
