import { atom } from 'jotai'
import { AppLanguageCode } from 'sierra-client/i18n/app-languages.generated'
import { userShapeFromEmail } from 'sierra-client/views/manage/components/user-attributes/flows/invite-users/utils'
import { Email } from 'sierra-domain/api/email'
import { CoursesPerUser } from 'sierra-domain/user-attributes/courses-per-user'
import { UserAttributesConfig } from 'sierra-domain/user-attributes/user-attributes-config'
import { UserValidationErrorsByEmail } from 'sierra-domain/user-attributes/user-invitation-validation-error'
import { AvatarStackUserShape } from 'sierra-ui/components'

export const currentlyEditingEmailsAtom = atom<string[]>([])

export const userAttributesConfigAtom = atom<UserAttributesConfig>({})
export const coursesPerUserAtom = atom<CoursesPerUser>({})
export const languagePerUserAtom = atom<Record<Email, AppLanguageCode>>({})

export const invitationErrorsAtom = atom<UserValidationErrorsByEmail>({})

export const incompleteUsersAtom = atom<AvatarStackUserShape[]>(get => {
  const invitationErrorsByMail = get(invitationErrorsAtom)
  const emails = Object.keys(invitationErrorsByMail)
  const users = emails.map(userShapeFromEmail)

  return users
})

export const emptyUsersAtom = atom<AvatarStackUserShape[]>(get => {
  const userAttributes: UserAttributesConfig = get(userAttributesConfigAtom)
  const emptyUsers: string[] = []
  for (const email in userAttributes) {
    const attributes = userAttributes[email]?.data
    if (attributes?.length === 1) emptyUsers.push(email) //if user is in config, they have at least accessLevel filled in
  }
  const users = emptyUsers.map(userShapeFromEmail)
  return users
})
