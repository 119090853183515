import { YjsEditor, yTextToSlateElement } from '@slate-yjs/core'
import _ from 'lodash'
import { default as React, useEffect, useRef, useState } from 'react'
import { isEditableYjsEditor } from 'sierra-client/editor'
import { useEditorJotaiDocument } from 'sierra-client/editor/editor-jotai-context'
import { useDebouncedAndLiveState } from 'sierra-client/hooks/use-debounced-state'
import { useDevelopmentSnackbar } from 'sierra-client/hooks/use-debug-notif'
import { useIsDebugMode } from 'sierra-client/hooks/use-is-debug-mode'
import { getRedactedDocumentTreeString } from 'sierra-client/views/flexible-content/editor/editor-error-meta'
import { nodeAtPath } from 'sierra-client/views/v3-author/queries'
import { redactSlateDocument } from 'sierra-domain/editor/redact-slate-document'
import { SlateNodeWithType, transformNodes } from 'sierra-domain/slate-util'
import { SlateRootElement } from 'sierra-domain/v3-author'
import { useOnChanged } from 'sierra-ui/utils'
import { Node, Text } from 'slate'
import { useSlateStatic } from 'slate-react'

function newNodes(previous: Node[], current: Node[]): Node[] {
  return current.filter((node, index) => !_.isEqual(previous[index], node))
}

function clearMarksIfTextIsEmpty(node: SlateNodeWithType): SlateNodeWithType[] {
  if ('text' in node) {
    if (node.text === '') return []
    if ('type' in node) return [_.omit(node, 'type') as SlateNodeWithType]
  }
  return [node]
}

export function clearEmptyMarks(nodes: SlateNodeWithType[]): SlateNodeWithType[] {
  return transformNodes(nodes, clearMarksIfTextIsEmpty)
}

export function isNewLineIssue(documentA: Node[], documentB: Node[]): boolean {
  const allTextsInA = documentA.flatMap(node => Array.from(Node.texts(node)))
  for (const [nodeA, pathA] of allTextsInA) {
    const nodeB = nodeAtPath(documentB, pathA)

    const textA = nodeA.text
    const textB = Text.isText(nodeB) ? nodeB.text : ''

    const numberOfNewlinesInA = Array.from(textA).filter(char => char === '\n').length
    const numberOfNewlinesInB = Array.from(textB).filter(char => char === '\n').length

    if (numberOfNewlinesInA !== numberOfNewlinesInB) return true
  }
  return false
}

function useDebouncedAndLiveDocument(): [Node[], Node[]] {
  const document = useEditorJotaiDocument()
  const [debouncedDocument, liveDocument, setDebouncedDocument] = useDebouncedAndLiveState(document, {
    wait: 500,
    maxWait: 2000,
  })
  useEffect(() => setDebouncedDocument(document), [document, setDebouncedDocument])

  return [debouncedDocument, liveDocument]
}

export const DocumentSyncChecker: React.FC = () => {
  const [document] = useDebouncedAndLiveDocument()
  const editor = useSlateStatic()
  const [isBroken, setIsBroken] = useState(false)

  const { reportInDev } = useDevelopmentSnackbar()
  const isDebug = useIsDebugMode() || process.env.NODE_ENV === 'development'

  useEffect(() => {
    if (isBroken) {
      if (isEditableYjsEditor(editor)) {
        const slateDocument = editor.children
        const yjsDocument = yTextToSlateElement(editor.sharedRoot).children

        console.debug('Slate documents', {
          slateDocument: clearEmptyMarks(redactSlateDocument(slateDocument)),
          yjsDocument: clearEmptyMarks(redactSlateDocument(yjsDocument)),
        })
      }

      throw new Error(`Document is out of sync with Yjs`)
    }
  }, [editor, isBroken, isDebug])

  const crashCountRef = useRef(0)

  useEffect(() => {
    if (!isEditableYjsEditor(editor)) return
    if (!YjsEditor.connected(editor)) return
    if (document.length === 0) return

    const areDocumentsEqual = (): boolean => {
      const slateDocument = clearEmptyMarks(editor.children)
      const yjsDocument = clearEmptyMarks(yTextToSlateElement(editor.sharedRoot).children)
      return _.isEqual(slateDocument, yjsDocument)
    }

    const documentsAreEqual = areDocumentsEqual()
    if (documentsAreEqual) return

    const slateDocument = editor.children
    const yjsDocument = yTextToSlateElement(editor.sharedRoot).children
    const crashCount = crashCountRef.current
    if (crashCount < 10) {
      crashCountRef.current = crashCount + 1
      editor.disconnect()
      editor.connect()
      const isNewLineIssueDetected = isNewLineIssue(slateDocument, yjsDocument)
      editor.pushActionsLogEntry(
        isNewLineIssueDetected ? 'reset-newline-bug-document' : 'reset-general-sync-bug-document'
      )

      if (isDebug) {
        const message = isNewLineIssueDetected
          ? 'Resetting document with newline issue'
          : 'Resetting document with general slate-yjs sync issue'
        reportInDev(message, { variant: 'warning' })
      }
      return
    } else {
      setIsBroken(true)
    }
  }, [document, editor, reportInDev, isDebug])

  return null
}

export const DocumentTypeChecker: React.FC = () => {
  const [debouncedDocument] = useDebouncedAndLiveDocument()

  const [updates, setDocumentUpdates] = useState<Node[]>([])
  useOnChanged((previous, current) => {
    if (_.isEqual(previous, current)) return
    setDocumentUpdates(newNodes(previous ?? [], current))
  }, debouncedDocument)

  useEffect(() => {
    for (const update of updates) {
      const parseResult = SlateRootElement.safeParse(update)
      if (parseResult.success) return
      const node = getRedactedDocumentTreeString(update)

      throw new Error(
        `[DocumentTypeChecker] Type violation detected in slate document: ${node}\n\n${parseResult.error.message}`
      )
    }
  }, [updates])

  return null
}
