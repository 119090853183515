import _ from 'lodash'
import { Dispatch, SetStateAction, useCallback, useEffect, useState } from 'react'
import { useDeepEqualityMemo } from 'sierra-client/hooks/use-deep-equality-memo'

type Options = { wait?: number } & Partial<NonNullable<Parameters<typeof _.debounce>[2]>>

export const useDebouncedState = <S = undefined>(
  initialValue: S,
  options?: Options
): [S, Dispatch<SetStateAction<S>>] => {
  const safeOptions = useDeepEqualityMemo(options)
  const [value, setValue] = useState(initialValue)

  const debouncedSetValue =
    // eslint-disable-next-line react-hooks/exhaustive-deps
    useCallback(_.debounce(setValue, safeOptions?.wait ?? 300, safeOptions), [setValue, safeOptions])

  useEffect(() => () => debouncedSetValue.cancel(), [debouncedSetValue])

  return [value, debouncedSetValue]
}

export const useDebouncedAndLiveState = <S = undefined>(
  initialValue: S,
  options?: Options
): [debouncedValue: S, liveValue: S, setValue: Dispatch<SetStateAction<S>>] => {
  const [liveValue, setLiveValue] = useState(initialValue)
  const [debouncedValue, setDebouncedValue] = useDebouncedState(initialValue, options)

  useEffect(() => {
    setDebouncedValue(liveValue)
  }, [setDebouncedValue, liveValue])

  return [debouncedValue, liveValue, setLiveValue]
}
