import _ from 'lodash'
import { useMemo } from 'react'
import { useSelectCurrentCard } from 'sierra-client/components/liveV2/hooks/use-select-current-card'
import {
  GridParticipant,
  sortParticipantsByPriority,
} from 'sierra-client/components/liveV2/participant-hooks/participant-grid-hooks'
import { useStableOrder } from 'sierra-client/hooks/use-stable-order'
import { useSelector } from 'sierra-client/state/hooks'
import { selectIsSomeoneScreenSharing } from 'sierra-client/state/live-session/selectors'

// We'll define this helper function outside of the component so that we get a stable reference.
const hasEqualAgoraUID = (a: GridParticipant, b: GridParticipant): boolean => a.agoraUID === b.agoraUID

/**
 * Selects the users who should be displayed in the video avatar bar
 * and orders them in a way that pinned users are always first
 * and the order is stable (i.e. the order of the users is not changed once they are added)
 */
export const useOrderedVideoAvatarBarParticipants = ({
  participants,
  numberOfAvatarsThatFit,
}: {
  participants: GridParticipant[]
  numberOfAvatarsThatFit: number
}): GridParticipant[] => {
  const currentCard = useSelectCurrentCard()
  const isSomeoneScreenSharing = useSelector(selectIsSomeoneScreenSharing)
  const excludePinnedParticipants = currentCard?.data.type === 'live-lobby' && !isSomeoneScreenSharing

  const activeParticipants = useMemo(
    () =>
      _.chain(participants)
        .filter(it => (excludePinnedParticipants ? !it.isPinned : true))
        .thru(sortParticipantsByPriority)
        .take(numberOfAvatarsThatFit)
        .value(),
    [excludePinnedParticipants, numberOfAvatarsThatFit, participants]
  )

  const stableParticipants = useStableOrder(activeParticipants, hasEqualAgoraUID)

  const participantsWithPinnedFirst = useMemo(
    () =>
      _.sortBy(
        stableParticipants,
        u => (u.isCurrentClient ? 0 : 1),
        u => (u.isPinned ? 0 : 1)
      ),
    [stableParticipants]
  )

  return participantsWithPinnedFirst
}
