import { useCallback } from 'react'
import { useIntercom } from 'react-use-intercom'

type DirectIntercomEvents = '[TOUR]-add-details-for-users'

/* eslint-disable @typescript-eslint/no-unused-vars */
type SanityChecker<T extends `[TOUR]-${string}`> = never

// If DirectIntercomEvents does not follow the sanity checker, the following line will fail to compile
type _ = SanityChecker<DirectIntercomEvents>
/* eslint-enable @typescript-eslint/no-unused-vars */

/**
 * Make sure we have a consistent way of tracking DIRECT events to intercom. This is to allow us to
 * to trigger intercom features such as tours directly without the usage of URL:s.
 * Usage of Segment was attempted but was flaky and not consistent enough.
 *
 * This also tries to conform all events to a specific name to make it less likely to have duplicates.
 *
 * @param eventName The name of the event to track
 * @param metadata Optional metadata to send with the event
 * */
export const useIntercomDirectTrackEvent = (): ((
  eventName: DirectIntercomEvents,
  metadata?: object
) => void) => {
  const intercom = useIntercom()
  return useCallback((eventName, metadata) => intercom.trackEvent(eventName, metadata), [intercom])
}
