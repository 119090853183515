import React from 'react'
import { useTranslation } from 'sierra-client/hooks/use-translation'
import { getAvatarColorAt } from 'sierra-client/utils/random-avatar-color'
import {
  FooterWrapper,
  PanelHeadline,
  PanelLayout,
  ScrollGradientTop,
} from 'sierra-client/views/manage/components/user-attributes/flows/components/layout'
import { useComposeUserInvitationConfig } from 'sierra-client/views/manage/components/user-attributes/flows/invite-users/hooks/use-compose-user-invitation-config'
import { AttributesSection } from 'sierra-client/views/manage/components/user-attributes/flows/invite-users/panels/set-attributes-panel/attributes/attributes-section'
import { LearningJourneySection } from 'sierra-client/views/manage/components/user-attributes/flows/invite-users/panels/set-attributes-panel/learning-journey/learning-journey-section'
import { splitEmail } from 'sierra-client/views/manage/components/user-attributes/flows/invite-users/utils'
import { RoundAvatar } from 'sierra-ui/components'
import { PanelInPanelCloseButton } from 'sierra-ui/components/layout-kit/layout-panel'
import { Button, Spacer, View } from 'sierra-ui/primitives'
import styled from 'styled-components'

/* PaddingRight added to not cut off the focus container */
const ScrollContainer = styled(View).attrs({ direction: 'column', marginLeft: '12', paddingRight: '6' })`
  flex: 1 0 0;
  min-height: 0;
  overflow: auto;
`

const Top: React.FC = () => {
  const { closeAttributeEdit, editingEmails } = useComposeUserInvitationConfig()
  const { t } = useTranslation()

  const currentUserEmail = editingEmails[0]
  if (currentUserEmail === undefined) return <></>

  const bulkEditing = editingEmails.length > 1

  const { identifier } = splitEmail(currentUserEmail)
  const color = getAvatarColorAt(0)

  return (
    <View gap='8'>
      <PanelInPanelCloseButton onClose={closeAttributeEdit} label={t('dictionary.close')} />
      {!bulkEditing ? (
        <>
          <View marginRight='8'>
            <RoundAvatar firstName={identifier} color={color} />
          </View>
          <PanelHeadline>{currentUserEmail}</PanelHeadline>
        </>
      ) : (
        <PanelHeadline>{t('manage.users.invite.bulk-editing.edit-all-users')}</PanelHeadline>
      )}
    </View>
  )
}

export const SetAttributesPanel: React.FC<{ canAssignContent: boolean }> = ({ canAssignContent }) => {
  const { t } = useTranslation()
  const { closeAttributeEdit } = useComposeUserInvitationConfig()

  return (
    <PanelLayout padding='24' paddingBottom='none' paddingRight='none' grow>
      <ScrollGradientTop top='35' height='24'>
        <Top />
      </ScrollGradientTop>
      <ScrollContainer>
        <Spacer />
        <AttributesSection />
        <Spacer />
        <div data-intercom-target='learning journey'>
          <LearningJourneySection canAssignContent={canAssignContent} />
        </div>
      </ScrollContainer>
      <FooterWrapper>
        <View paddingRight='16'>
          <Button variant='primary' onClick={closeAttributeEdit}>
            {t('dictionary.done')}
          </Button>
        </View>
      </FooterWrapper>
    </PanelLayout>
  )
}
