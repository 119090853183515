import { LightUser } from 'sierra-domain/user'

export const SEARCH_HEIGHT = 55

export type QuestionCardResponse = {
  type: 'question-card'
  responseId: string
  correct: boolean
  attempts: number
  fastestCorrect: boolean
}

export type PollResponse = {
  type: 'poll'
  responseId: string
  response: string
}

export type ReflectionCardResponse = {
  type: 'reflection-card'
  responseId: string
  response: string
}

export type SlidingScaleResponse = {
  type: 'sliding-scale-card'
  responseId: string
  response: string
}

export type LightUserWithResponse = LightUser & {
  response?: QuestionCardResponse | PollResponse | ReflectionCardResponse | SlidingScaleResponse
}
