import { Variant, motion, useAnimation } from 'framer-motion'
import React, { useEffect, useState } from 'react'
import { TokenOrColor } from 'sierra-ui/color/token-or-color'
import { Text, TextSize } from 'sierra-ui/primitives'

type FakeStreamingTextProps = {
  text: string | string[] | undefined
  el?: keyof JSX.IntrinsicElements
  delay?: number
  bold?: boolean
  color?: TokenOrColor
  size?: TextSize
  className?: string
  animation?: {
    hidden: Variant
    visible: Variant
  }
  onComplete?: () => void
}

const defaultAnimations = {
  hidden: {
    opacity: 0,
  },
  visible: {
    opacity: 1,
  },
}

export const FakeStreamingText = ({
  text,
  animation = defaultAnimations,
  delay = 0,
  bold = false,
  color,
  size = 'regular',
  onComplete,
}: FakeStreamingTextProps): React.ReactNode => {
  const controls = useAnimation()
  const fallbackText = text ?? ''
  const textArray = Array.isArray(fallbackText) ? fallbackText : [fallbackText]
  const [isComplete, setIsComplete] = useState(false)

  useEffect(() => {
    if (text === undefined) {
      void controls.set('hidden')
      setIsComplete(false)
    } else {
      void controls.start('visible')
    }
  }, [controls, text])

  useEffect(() => {
    if (isComplete && onComplete) {
      onComplete()
    }
  }, [isComplete, onComplete])

  const handleAnimationComplete = (): void => {
    setIsComplete(true)
  }

  return (
    <motion.span
      initial='hidden'
      animate={controls}
      variants={{
        visible: {
          transition: {
            staggerChildren: 0.08,
            delayChildren: delay,
            when: 'beforeChildren',
            staggerDirection: 1,
          },
        },
        hidden: {},
      }}
      onAnimationComplete={handleAnimationComplete}
      aria-hidden
    >
      <Text size={size} bold={bold} color={color}>
        {textArray.map((line, lineIndex) => (
          <motion.span style={{ display: 'block' }} key={`${line}-${lineIndex}`}>
            {line.split(' ').map((word, wordIndex) => (
              <motion.span style={{ display: 'inline-block' }} key={`${word}-${wordIndex}`}>
                <motion.span
                  key={`${word}-${wordIndex}`}
                  style={{ display: 'inline-block' }}
                  variants={{
                    ...animation,
                    visible: {
                      ...animation.visible,
                      transition: {
                        duration: 0.25,
                      },
                    },
                  }}
                >
                  {word}
                </motion.span>
                <motion.span style={{ display: 'inline-block' }}>&nbsp;</motion.span>
              </motion.span>
            ))}
          </motion.span>
        ))}
      </Text>
    </motion.span>
  )
}
