import { motion } from 'framer-motion'
import { Dispatch, useEffect, useMemo, useReducer } from 'react'
import { useCurrentMode } from 'sierra-client/components/liveV2/hooks/use-current-mode'
import { useSelectCurrentCard } from 'sierra-client/components/liveV2/hooks/use-select-current-card'
import { DebugView } from 'sierra-client/components/liveV2/live-layer/call-stats/debug-view'
import { SidebarParticipantGrid } from 'sierra-client/components/liveV2/live-layer/video-avatar-bar/sidebar-participant-grid'
import { useLiveSessionIdContext } from 'sierra-client/components/liveV2/live-session-id-provider'
import { useRightSidebarContext } from 'sierra-client/components/liveV2/live-sidebar-provider'
import { AutoSummary } from 'sierra-client/components/liveV2/right-sidebar/auto-summary'
import { LiveTranscription } from 'sierra-client/components/liveV2/right-sidebar/live-transcription'
import { ParticipantExplorer } from 'sierra-client/components/liveV2/right-sidebar/participant-explorer'
import { ChatContainer } from 'sierra-client/features/sana-now'
import { useLiveSessionPermissions } from 'sierra-client/hooks/use-permissions'
import { useIsTablet } from 'sierra-client/state/browser/selectors'
import { useSelector } from 'sierra-client/state/hooks'
import {
  selectIsEveryonePinned,
  selectIsSomeonePinned,
  selectIsSomeoneScreenSharing,
} from 'sierra-client/state/live-session/selectors'
import { CreateContentId } from 'sierra-domain/api/nano-id'
import { ScopedLiveSessionId } from 'sierra-domain/collaboration/types'
import styled from 'styled-components'

const VIDEO_AVATAR_BAR_WIDTH = 170
const COMBINED_SIDEBAR_WIDTH = 350
const HIDDEN_WIDTH = 0

const VideoAvatarColumnCombinedSidebar = styled(motion.div)`
  height: 100%;
  overflow: hidden;
`

const StyledParticipantGrid = styled.div<{ $combinedSidebar: boolean }>`
  flex: 1 0 25%;
  max-height: ${p => (p.$combinedSidebar ? '25%' : '100%')};
`

type SidebarState = 'only-video-avatar-bar' | 'combined-sidebar' | 'sidebar-content' | 'hidden'

type SidebarTransitionState = {
  currentState: SidebarState
  transitioning: boolean
  nextState?: SidebarState
}
type Action = { type: 'change-state'; nextState: SidebarState } | { type: 'animation-done' }

const sidebarStateWidths: Record<SidebarState, number> = {
  'only-video-avatar-bar': VIDEO_AVATAR_BAR_WIDTH,
  'combined-sidebar': COMBINED_SIDEBAR_WIDTH,
  'sidebar-content': COMBINED_SIDEBAR_WIDTH,
  'hidden': HIDDEN_WIDTH,
}

export const RightSidebarContent = ({ showClose = false }): JSX.Element => {
  const rightSidebarContext = useRightSidebarContext()
  const { liveSessionId } = useLiveSessionIdContext()
  const liveSessionNanoId12 = ScopedLiveSessionId.extractId(liveSessionId)

  const liveSessionPermission = useLiveSessionPermissions(liveSessionNanoId12)
  const hasLiveSessionChatPermission = liveSessionPermission.has('CHAT')

  if (rightSidebarContext.state === 'debug_open') return <DebugView />

  if (rightSidebarContext.state === 'list_open') return <ParticipantExplorer showClose={showClose} />

  if (rightSidebarContext.state === 'auto_summary_open') return <AutoSummary showClose={showClose} />

  if (rightSidebarContext.state === 'live_transcription_open') return <LiveTranscription />

  return hasLiveSessionChatPermission ? (
    <ChatContainer showClose={showClose} liveSessionId={liveSessionId} />
  ) : (
    <></>
  )
}

export const useShowVideoAvatarBar = (flexibleContentId: CreateContentId): boolean => {
  const isSomeoneScreenSharing = useSelector(selectIsSomeoneScreenSharing)
  const currentMode = useCurrentMode(flexibleContentId)
  const isSomeonePinned = useSelector(selectIsSomeonePinned)
  const isEveryonePinned = useSelector(selectIsEveryonePinned)
  const currentCard = useSelectCurrentCard()

  const showVideoAvatarBar = useMemo(() => {
    if (isSomeoneScreenSharing) return true
    if (currentMode === 'live-lobby') {
      if (isEveryonePinned) return false
      if (isSomeonePinned) return true
      return false
    }
    if (currentMode === 'card' && currentCard?.data.type !== 'breakout-room') return true
    return false
  }, [isSomeoneScreenSharing, currentMode, currentCard, isSomeonePinned, isEveryonePinned])

  return showVideoAvatarBar
}

const InnerSizeContainer = styled.div<{ $width: number }>`
  width: ${p => p.$width}px;
  height: 100%;
  display: flex;
  flex-direction: column;
  gap: 8px;
  overflow: visible;
  margin-left: auto;
`

const UpdateReducerState = ({
  flexibleContentId,
  dispatch,
}: {
  flexibleContentId: CreateContentId
  dispatch: Dispatch<Action>
}): JSX.Element | null => {
  const showSidebarVideos = useShowVideoAvatarBar(flexibleContentId)
  const rightSidebarContext = useRightSidebarContext()
  const isTablet = useIsTablet()

  useEffect(() => {
    if (!rightSidebarContext.isOpen && showSidebarVideos && !isTablet) {
      return dispatch({ type: 'change-state', nextState: 'only-video-avatar-bar' })
    }

    if (rightSidebarContext.isOpen && !isTablet && showSidebarVideos) {
      return dispatch({ type: 'change-state', nextState: 'combined-sidebar' })
    }

    if (rightSidebarContext.isOpen && (isTablet || !showSidebarVideos)) {
      return dispatch({ type: 'change-state', nextState: 'sidebar-content' })
    }

    return dispatch({ type: 'change-state', nextState: 'hidden' })
  }, [rightSidebarContext.isOpen, showSidebarVideos, isTablet, dispatch])

  return null
}

export const CombinedRightSidebar = ({
  flexibleContentId,
}: {
  flexibleContentId: CreateContentId
}): JSX.Element | null => {
  const [state, dispatch] = useReducer(
    (state: SidebarTransitionState, action: Action): SidebarTransitionState => {
      switch (action.type) {
        case 'change-state':
          if (sidebarStateWidths[action.nextState] !== sidebarStateWidths[state.currentState]) {
            return {
              nextState: action.nextState,
              currentState: state.currentState,
              transitioning: true,
            }
          } else {
            return {
              currentState: action.nextState,
              transitioning: false,
              nextState: undefined,
            }
          }
        case 'animation-done':
          return {
            transitioning: false,
            nextState: undefined,
            currentState: state.nextState ?? state.currentState,
          }
      }
    },
    { currentState: 'hidden', transitioning: false }
  )

  return (
    <>
      <UpdateReducerState dispatch={dispatch} flexibleContentId={flexibleContentId} />
      {!(state.currentState === 'hidden' && state.nextState === undefined) && (
        <VideoAvatarColumnCombinedSidebar
          animate={{
            width: sidebarStateWidths[state.nextState ?? state.currentState],
          }}
          initial={{
            width: 0,
          }}
          transition={{
            ease: [0.25, 0.1, 0.25, 1],
            duration: 0.2,
          }}
          onAnimationComplete={() => dispatch({ type: 'animation-done' })}
        >
          <InnerSizeContainer $width={sidebarStateWidths[state.currentState]}>
            {(state.currentState === 'only-video-avatar-bar' ||
              state.currentState === 'combined-sidebar') && (
              <StyledParticipantGrid $combinedSidebar={state.currentState === 'combined-sidebar'}>
                <SidebarParticipantGrid
                  oneColumnLayout={state.currentState === 'only-video-avatar-bar'}
                  participantLimit={state.currentState === 'combined-sidebar' ? 6 : undefined}
                  flexibleContentId={flexibleContentId}
                />
              </StyledParticipantGrid>
            )}

            {(state.currentState === 'sidebar-content' || state.currentState === 'combined-sidebar') &&
              state.transitioning === false && (
                <motion.div
                  initial={{ opacity: 0, y: 20 }}
                  animate={{ opacity: 1, y: 0 }}
                  transition={{
                    ease: [0.25, 0.1, 0.25, 1],
                    duration: 0.2,
                  }}
                  style={{ height: '100%', overflow: 'hidden' }}
                >
                  <RightSidebarContent />
                </motion.div>
              )}
          </InnerSizeContainer>
        </VideoAvatarColumnCombinedSidebar>
      )}
    </>
  )
}
