import { graphql } from 'sierra-client/api/graphql/gql'
import { BadgeIssuedViaFragment } from 'sierra-client/api/graphql/gql/graphql'

export const badgeIssuedViaFragment = graphql(`
  fragment BadgeIssuedVia on BadgeIssuedVia {
    ... on OnAllContentsCompletion {
      __typename
      dummy
    }
    ... on OnSpecificContentCompletion {
      __typename
      id
    }
  }
`)

export const isLevelFastTrack = (
  gqlBadgeIssuedViaFragment: BadgeIssuedViaFragment,
  contentId: string
): boolean => {
  const b = gqlBadgeIssuedViaFragment
  return b.__typename === 'OnSpecificContentCompletion' && b.id === contentId
}
