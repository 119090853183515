import React from 'react'
import { useTranslation } from 'sierra-client/hooks/use-translation'
import { FileContainer } from 'sierra-client/learn/learn-ui/file-view/helpers'
import { SanaGradientIcon } from 'sierra-client/lib/assistant-input/search-insights/sana-gradient-icon'
import { useSelfPacedFiles } from 'sierra-client/views/self-paced/files-provider'
import { isDefined } from 'sierra-domain/utils'
import { AiText, Icon, TruncatedText } from 'sierra-ui/components'
import { View } from 'sierra-ui/primitives'
import styled from 'styled-components'

export const NotStartedOrCompletedPlacementTestItem: React.FC<{
  onClick: () => void
  isCompleted: boolean
}> = ({ onClick, isCompleted }) => {
  const { t } = useTranslation()
  const color = isCompleted ? 'foreground/muted' : 'foreground/secondary'
  const iconId = isCompleted ? 'course--card--completed' : 'sana-symbol'

  return (
    <FileContainer $isCurrentItem={false} onClick={onClick} gap='10'>
      <Icon iconId={iconId} color={color} />
      <View grow overflow='hidden'>
        <TruncatedText bold color={color} lines={1} size='small'>
          {t('content.placement-test.placement-test')}
        </TruncatedText>
      </View>
    </FileContainer>
  )
}

const AiIconWrapper = styled.span`
  display: inline-flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  overflow: visible;

  & svg {
    display: block;
    object-fit: cover;

    width: 1rem !important;
    height: 1rem !important;
  }
`

export const ActivePlacementTestItem: React.FC<{ onClick: () => void }> = ({ onClick }) => {
  const { t } = useTranslation()

  return (
    <FileContainer $isCurrentItem={true} onClick={onClick} gap='10'>
      <AiIconWrapper>
        <SanaGradientIcon base='#5B59EA' />
      </AiIconWrapper>
      <View grow overflow='hidden'>
        <AiText bold base='#5B59EA' size='small'>
          {t('content.placement-test.placement-test')}
        </AiText>
      </View>
    </FileContainer>
  )
}

export const ReviewTestItem: React.FC<{
  numberOfLearnings: number
  onClick: () => void
  isCompleted: boolean
}> = ({ numberOfLearnings, onClick, isCompleted }) => {
  const { t } = useTranslation()
  const { currentReview } = useSelfPacedFiles()
  const modalIsOpen = isDefined(currentReview)
  const color = modalIsOpen ? 'foreground/primary' : isCompleted ? 'foreground/muted' : 'foreground/secondary'
  const iconId = isCompleted ? 'course--card--completed' : 'sana-symbol'

  return (
    <FileContainer $isCurrentItem={modalIsOpen} onClick={onClick} gap='10'>
      <Icon iconId={iconId} color={color} />
      <View grow overflow='hidden'>
        <TruncatedText bold color={color} lines={1} size='small'>
          {t('content.review.review-learnings', { count: numberOfLearnings })}
        </TruncatedText>
      </View>
    </FileContainer>
  )
}
