import React, { ReactNode } from 'react'
import * as Components from 'sierra-client/views/v3-author/components'
import { Text } from 'slate'
import styled from 'styled-components'

const LeafSpan = styled.span<{ $color: string | undefined }>`
  ${p => p.$color !== undefined && `color: ${p.$color};`}
`
export const RenderLeaf: React.FC<{ leaf: Text; children: ReactNode }> = ({ leaf, children }) => {
  if (leaf.bold ?? false) children = <Components.Bold>{children}</Components.Bold>
  if (leaf.code ?? false) children = <code>{children}</code>
  if (leaf.italic ?? false) children = <Components.Italic>{children}</Components.Italic>
  if (leaf.underline ?? false) children = <Components.Underline>{children}</Components.Underline>
  if (leaf.strikethrough ?? false) children = <Components.Strikethrough>{children}</Components.Strikethrough>
  if (leaf.subscript ?? false) children = <Components.Subscript>{children}</Components.Subscript>
  if (leaf.supscript ?? false) children = <Components.Supscript>{children}</Components.Supscript>

  return <LeafSpan $color={leaf.color}>{children}</LeafSpan>
}
