import React, { useEffect } from 'react'
import { useNonExpiringNotif } from 'sierra-client/components/common/notifications'
import { useTranslation } from 'sierra-client/hooks/use-translation'
import { ContentPermission } from 'sierra-domain/api/common'

export function useViewAndCommentOnlyNotification(permission: ContentPermission): void {
  const { push, remove } = useNonExpiringNotif()
  const { t } = useTranslation()
  useEffect(() => {
    let notificationId: string | undefined = undefined
    if (permission === 'comment')
      notificationId = push({ body: t('author.comment-only'), level: 'info', type: 'custom' })
    if (permission === 'view')
      notificationId = push({ body: t('author.view-only'), level: 'info', type: 'custom' })
    return () => {
      if (notificationId !== undefined) remove(notificationId)
    }
  }, [permission, push, remove, t])
}

export const ViewAndCommentOnlyNotification: React.FC<{ permission: ContentPermission }> = ({
  permission,
}) => {
  useViewAndCommentOnlyNotification(permission)
  return null
}
