import { AnimatePresence, motion } from 'framer-motion'
import { useAtom, useSetAtom } from 'jotai'
import _ from 'lodash'
import { DateTime } from 'luxon'
import { FC, useEffect, useMemo, useState } from 'react'
import useMeasure from 'react-use-measure'
import { useSelfPacedPublishState } from 'sierra-client/api/hooks/use-self-paced-publish-state'
import { liveToolbarAtom } from 'sierra-client/components/liveV2/live-layer/live-toolbar-ref'
import { Logging } from 'sierra-client/core/logging'
import { useTranslation } from 'sierra-client/hooks/use-translation'
import { useCachedQuery } from 'sierra-client/state/api'
import { selectFlexibleContentFile } from 'sierra-client/state/flexible-content/selectors'
import { useDispatch, useSelector } from 'sierra-client/state/hooks'
import {
  useCreatePageContext,
  useEditOrResponsesStateSafe,
} from 'sierra-client/views/flexible-content/create-page-context'
import { isElementType } from 'sierra-client/views/v3-author/queries'
import { LiveSessionId } from 'sierra-domain/api/nano-id'
import {
  ContentType,
  ScopedCreateContentId,
  ScopedLiveContentId,
  ScopedSelfPacedContentId,
} from 'sierra-domain/collaboration/types'
import { LiveSessionBase } from 'sierra-domain/content/session'
import { FileId } from 'sierra-domain/flexible-content/identifiers'
import { File, isSlateFile } from 'sierra-domain/flexible-content/types'
import { XRealtimeAuthorLiveSessionsListLiveSessions } from 'sierra-domain/routes'
import { iife, isNonEmptyArray } from 'sierra-domain/utils'
import {
  getSlateDocumentFromSharedType,
  getSlateDocumentSharedType,
} from 'sierra-domain/v3-author/slate-yjs-extension'
import { Icon, MenuItem, Tooltip } from 'sierra-ui/components'
import { GroupMenuItem } from 'sierra-ui/components/menu/types'
import { Button, Text, View } from 'sierra-ui/primitives'
import { MenuDropdownPrimitive, MenuDropdownPrimitiveProps } from 'sierra-ui/primitives/menu-dropdown'
import { token } from 'sierra-ui/theming'
import { FCC } from 'sierra-ui/types'
import { Node } from 'slate'
import styled, { css } from 'styled-components'
import * as Y from 'yjs'

const BlinkingDot = styled.div`
  width: 8px;
  height: 8px;
  background-color: red;
  border-radius: 50%;
  position: relative;
  flex-shrink: 0;
  margin-left: 5px;
  margin-right: 3px;

  &::before {
    content: '';
    width: 20px;
    height: 20px;
    background-color: rgba(255, 0, 0, 0.2);
    border-radius: 50%;
    position: absolute;
    top: -6px;
    left: -6px;
    animation: pulse 2s infinite;
  }

  @keyframes pulse {
    0% {
      transform: scale(0.8);
      opacity: 0.7;
    }
    50% {
      transform: scale(1);
      opacity: 1;
    }
    100% {
      transform: scale(0.8);
      opacity: 0.7;
    }
  }
`

const Container = styled(motion.div)`
  background-color: ${token('surface/default')};
  padding: 6px;
  border-radius: 70px;
  position: absolute;
  bottom: 16px;
  right: 50%;
  transform: translateX(50%);
  overflow: hidden;
  box-shadow:
    0px 8px 16px 0px rgba(0, 0, 0, 0.08),
    0px 0px 0px 1px rgba(0, 0, 0, 0.04);
`

const InnerWidthContainer = styled(motion.div)`
  display: flex;
  align-items: center;
  width: fit-content;
`

const AnimatingContainer: FCC<{ closed: boolean }> = ({ children, closed }) => {
  const [sizeRef, { width }] = useMeasure()
  const [minWidth, setMinWidth] = useState<number | undefined>(undefined)

  useEffect(() => {
    setMinWidth(previous => {
      if (width === 0) return previous
      else if (previous === undefined) return width
      else return Math.min(width, previous)
    })
  }, [width])

  const targetWidth = closed ? minWidth ?? width : width

  return (
    <Container
      animate={{ width: targetWidth + 12 }}
      transition={{ ease: [0.25, 0.1, 0.25, 1], duration: 0.25 }}
    >
      <InnerWidthContainer ref={sizeRef}>{children}</InnerWidthContainer>
    </Container>
  )
}

const InnerContainer = styled.div`
  display: flex;
  gap: 2px;
  padding: 5px;
  border-radius: 20px;
  background-color: ${token('surface/strong')};
  align-items: center;
`

const Separator = styled.div`
  height: 16px;
  width: 1px;
  background-color: ${token('border/strong')};
  margin: 0 12px 0 8px;
`

const getSessionLabel = (session: LiveSessionBase): string => {
  const time = iife(() => {
    if (session.startedAt !== undefined) return session.startedAt
    if (session.type === 'scheduled') return session.startTime
    return session.endedAt
  })

  if (time !== undefined) return DateTime.fromISO(time).toFormat('MMMM dd, HH:mm')

  return 'unknown'
}

const EditOrResponsesButton = styled(Button).attrs({ variant: 'ghost' })<{ $selected: boolean }>`
  padding: 4px 8px;
  height: 30px;
  border-radius: 18px;
  ${p =>
    p.$selected
      ? css`
          background: ${token('surface/default')};
          color: ${token('foreground/primary')};
          outline: 1px solid ${token('form/border/2')};
          box-shadow: 0px 2px 4px 0px rgba(0, 0, 0, 0.08);
        `
      : css`
          background: transparent;
          color: ${token('foreground/secondary')};
          &:hover {
            color: ${token('foreground/primary')};
          }
        `}
`

const SessionPicker = styled(motion.button)`
  display: flex;
  height: 30px;
  align-items: center;
  cursor: pointer;
  gap: 4px;
  background-color: transparent;
  width: max-content;
`

const Label = styled(Text)`
  width: 100%;
  color: ${token('foreground/secondary')};
  :hover {
    color: ${token('foreground/primary')};
  }
`

export const ChevronIcon = styled(Icon)`
  flex-shrink: 0;
  color: ${token('foreground/muted')};
`

type ItemIdType = LiveSessionId | 'section-title' | 'separator' | 'more-sessions-button'

export function SessionPickerDropdown({
  onSelect,
  menuItems,
  label,
  decorator,
}: Pick<MenuDropdownPrimitiveProps<ItemIdType>, 'onSelect' | 'menuItems'> & {
  label: string
  decorator: JSX.Element | undefined
}): JSX.Element {
  return (
    <MenuDropdownPrimitive
      closeOnSelect
      onSelect={onSelect}
      menuItems={menuItems}
      maxHeight={300}
      renderTrigger={isOpen => (
        <SessionPicker initial={{ opacity: 0 }} animate={{ opacity: 1 }} exit={{ opacity: 0 }}>
          {decorator !== undefined && <View marginRight='4'>{decorator}</View>}
          <Label size='small' bold>
            {label}
          </Label>
          <ChevronIcon iconId={isOpen ? 'chevron--up--small' : 'chevron--down--small'} />
        </SessionPicker>
      )}
    />
  )
}

const LiveDataHistorySwitch: FC<{ contentId: ScopedLiveContentId }> = ({ contentId }) => {
  const { t } = useTranslation()
  const dispatch = useDispatch()
  const [editOrResponsesState, setEditOrResponsesState] = useAtom(
    useCreatePageContext().editOrResponsesStateAtom
  )
  const liveSessionsQuery = useCachedQuery(
    XRealtimeAuthorLiveSessionsListLiveSessions,
    {
      flexibleContentId: ScopedLiveContentId.extractId(contentId),
    },
    {
      select(data) {
        return _.sortBy(data.liveSessions, session => session.data.startedAt)
      },
    }
  )
  const [maxVisibleSessions, setMaxVisibleSessions] = useState(10)

  const sortedSessions = useMemo(() => {
    return liveSessionsQuery.data !== undefined
      ? _.orderBy(liveSessionsQuery.data, it => new Date(it.data.startedAt ?? 0), 'desc')
      : undefined
  }, [liveSessionsQuery.data])

  const visibleSessionsData = useMemo(() => {
    return sortedSessions !== undefined ? _.take(sortedSessions, maxVisibleSessions) : undefined
  }, [sortedSessions, maxVisibleSessions])

  const hasSessions = isNonEmptyArray(visibleSessionsData)
  const lastLiveSession = visibleSessionsData?.at(0)
  const hasMoreSessions = sortedSessions !== undefined && sortedSessions.length > maxVisibleSessions

  const selectedLiveSessionId =
    editOrResponsesState.type === 'responses' ? editOrResponsesState.liveSessionId : undefined

  const menuItemGroup = useMemo(() => {
    const menuItems =
      visibleSessionsData?.flatMap<MenuItem<ItemIdType> & { isOngoing?: boolean }>(session => {
        const isOngoing = session.data.startedAt !== undefined && session.data.endedAt === undefined

        if (isOngoing) {
          return {
            isOngoing,
            type: 'canvas',
            label: getSessionLabel(session.data),
            id: session.liveSessionId,
            render: () => (
              <View>
                <BlinkingDot />
                <Text size='small' bold={selectedLiveSessionId === session.liveSessionId}>
                  {getSessionLabel(session.data)}
                </Text>
              </View>
            ),
          }
        }

        return {
          isOngoing,
          type: 'canvas',
          label: getSessionLabel(session.data),
          id: session.liveSessionId,
          render: () => (
            <View>
              <Icon iconId='calendar' />
              <Text size='small' bold={selectedLiveSessionId === session.liveSessionId}>
                {getSessionLabel(session.data)}
              </Text>
            </View>
          ),
        }
      }) ?? []

    // add a separator
    if (menuItems.length > 1) {
      menuItems.splice(1, 0, { id: 'separator', type: 'separator' })
    }

    // add button to show more sessions
    if (hasMoreSessions) {
      menuItems.push({ id: 'separator', type: 'separator' })
      menuItems.push({
        type: 'canvas',
        id: 'more-sessions-button',
        render() {
          return (
            <View
              onClick={e => {
                e.stopPropagation()
                setMaxVisibleSessions(prev => prev + 10)
              }}
            >
              <Icon iconId='add' />
              <Text bold size='small'>
                {t('author.history-switch.session-list.view-more-button-text')}
              </Text>
            </View>
          )
        },
      })
    }

    return [
      {
        type: 'group',
        id: 'section-title',
        label: t('author.history-switch.session-list.title'),
        menuItems,
      },
    ] satisfies [GroupMenuItem<ItemIdType>]
  }, [hasMoreSessions, selectedLiveSessionId, t, visibleSessionsData])

  const setToolbarAtom = useSetAtom(liveToolbarAtom)
  const selectedItem = menuItemGroup[0].menuItems.find(item => item.id === selectedLiveSessionId)

  return (
    <div
      ref={container => {
        if (container !== null) {
          setToolbarAtom({ container, shouldHide: false })
        } else {
          setToolbarAtom(null)
        }
      }}
    >
      <AnimatingContainer closed={editOrResponsesState.type !== 'responses'}>
        <InnerContainer>
          <EditOrResponsesButton
            $selected={editOrResponsesState.type === 'edit'}
            onClick={() => {
              void dispatch(
                Logging.authoring.dataHistorySwitchEditToggled({
                  courseId: contentId,
                  courseKind: 'live',
                })
              )
              setEditOrResponsesState(prev => ({ ...prev, type: 'edit' }))
            }}
          >
            {t('author.history-switch.edit-button-text')}
          </EditOrResponsesButton>
          <Tooltip title={!hasSessions ? t('author.history-switch.see-responses-button-tooltip--live') : ''}>
            <EditOrResponsesButton
              $selected={editOrResponsesState.type === 'responses'}
              onClick={() => {
                void dispatch(
                  Logging.authoring.dataHistorySwitchResponsesToggled({
                    courseId: contentId,
                    courseKind: 'live',
                  })
                )
                setEditOrResponsesState(previous => ({
                  ...previous,
                  type: 'responses',
                  liveSessionId: lastLiveSession?.liveSessionId,
                }))
              }}
              disabled={!hasSessions}
            >
              {t('author.history-switch.see-responses-button-text')}
            </EditOrResponsesButton>
          </Tooltip>
        </InnerContainer>
        <AnimatePresence>
          {editOrResponsesState.type === 'responses' && (
            <>
              <Separator />
              <SessionPickerDropdown
                label={selectedItem?.label ?? ''}
                decorator={selectedItem?.isOngoing === true ? <BlinkingDot /> : undefined}
                menuItems={menuItemGroup}
                onSelect={session => {
                  if (
                    session.id === 'section-title' ||
                    session.id === 'separator' ||
                    session.id === 'more-sessions-button'
                  )
                    return

                  const id = session.id
                  setEditOrResponsesState(prev => ({ ...prev, type: 'responses', liveSessionId: id }))
                }}
              />
            </>
          )}
        </AnimatePresence>
      </AnimatingContainer>
    </div>
  )
}

const SelfPacedDataHistorySwitch: FC<{ contentId: ScopedSelfPacedContentId }> = ({ contentId }) => {
  const { t } = useTranslation()
  const [editOrResponsesState, setEditOrResponsesState] = useAtom(
    useCreatePageContext().editOrResponsesStateAtom
  )
  const dispatch = useDispatch()

  const publishState = useSelfPacedPublishState(ScopedSelfPacedContentId.extractId(contentId))
  const setToolbarAtom = useSetAtom(liveToolbarAtom)

  return (
    <div
      ref={container => {
        if (container !== null) {
          setToolbarAtom({ container, shouldHide: false })
        } else {
          setToolbarAtom(null)
        }
      }}
    >
      <Container>
        <InnerContainer>
          <EditOrResponsesButton
            $selected={editOrResponsesState.type === 'edit'}
            onClick={() => {
              void dispatch(
                Logging.authoring.dataHistorySwitchEditToggled({
                  courseId: contentId,
                  courseKind: 'self-paced',
                })
              )
              setEditOrResponsesState(prev => ({ ...prev, type: 'edit' }))
            }}
          >
            {t('author.history-switch.edit-button-text')}
          </EditOrResponsesButton>
          <EditOrResponsesButton
            $selected={editOrResponsesState.type === 'responses'}
            onClick={() => {
              void dispatch(
                Logging.authoring.dataHistorySwitchResponsesToggled({
                  courseId: contentId,
                  courseKind: 'self-paced',
                })
              )
              setEditOrResponsesState(prev => ({ ...prev, type: 'responses', liveSessionId: undefined }))
            }}
            disabledWithReason={
              publishState.state?.published !== true
                ? t('author.history-switch.see-responses-button-tooltip--self-paced')
                : undefined
            }
          >
            {t('author.history-switch.see-responses-button-text')}
          </EditOrResponsesButton>
        </InnerContainer>
      </Container>
    </div>
  )
}

const SUPPORTED_FILES: Record<File['data']['type'], boolean> = {
  'slate-card': false,
  'bullet': false,
  'image': false,
  'video': false,
  'embed': false,
  'flip-cards': false,
  'homework': false,
  'stupid-questions': false,
  'scenario': false,
  'notepad': false,
  'breakout-room': false,
  'live-lobby': false,
  'project-card': false,
  'external-notepad': false,
  'roleplay': false,

  'general': true,
  'question-card': true,
  'assessment-card': true,
  'reflections': true,
  'sliding-scale': true,
  'drop-a-word': true,
  'sticky-notes': true,
  'poll': true,
}

export function fileSupportsShowingResponses(file: File, contentType: ContentType): boolean {
  if (contentType === 'live' && file.data.type === 'assessment-card') {
    // Assessments are being built in live at the moment, we are not sure what
    // the data model will be yet, so we need to figure that out before enabling this
    return false
  }

  return SUPPORTED_FILES[file.data.type]
}

function useGeneralCardHasElementsWithResponses(yDoc: Y.Doc, file: File | undefined): boolean {
  const [hasQuestionCard, setHasQuestionCard] = useState(false)
  useEffect(() => {
    if (file === undefined || !isSlateFile(file)) {
      return
    }

    const slateSharedType = getSlateDocumentSharedType(yDoc, file.id)
    function onDocumentChanged(): void {
      const slateDocument = getSlateDocumentFromSharedType(slateSharedType)

      for (const rootNode of slateDocument) {
        for (const [node] of Node.elements(rootNode)) {
          if (isElementType('question-card', node) || isElementType('sliding-scale-card', node)) {
            setHasQuestionCard(true)
            return
          }
        }
      }
      setHasQuestionCard(false)
    }

    onDocumentChanged()

    slateSharedType.observeDeep(onDocumentChanged)
    return () => {
      setHasQuestionCard(false)
      slateSharedType.unobserveDeep(onDocumentChanged)
    }
  }, [file, yDoc])

  return hasQuestionCard
}

export const DataHistorySwitch: FC<{ contentId: ScopedCreateContentId; fileId: FileId; yDoc: Y.Doc }> = ({
  yDoc,
  contentId,
  fileId,
}) => {
  const card = useSelector(state =>
    selectFlexibleContentFile(state, ScopedCreateContentId.extractId(contentId), fileId)
  )
  const hasElementsWithResponses = useGeneralCardHasElementsWithResponses(yDoc, card)
  const isResponsesMode = useEditOrResponsesStateSafe()?.type === 'responses'

  return (
    <AnimatePresence>
      {iife(() => {
        if (
          card?.data.type === undefined ||
          !fileSupportsShowingResponses(card, ScopedCreateContentId.contentType(contentId))
        ) {
          return null
        }

        if (
          // On general cards, only show the switch if there are question cards
          card.data.type === 'general' &&
          !hasElementsWithResponses &&
          // But if we are already in responses mode, for example from having switched on another card,
          // show the responses switch so the user can go back to edit mode
          !isResponsesMode
        ) {
          return null
        }

        return (
          <motion.div
            initial={{
              y: 16,
              opacity: 0,
            }}
            animate={{
              y: 0,
              opacity: 1,
              transition: { ease: [0.25, 0.1, 0.25, 1], duration: 0.35 },
            }}
            exit={{
              y: 16,
              opacity: 0,
              transition: { ease: [0.25, 0.1, 0.25, 1], duration: 0.15 },
            }}
          >
            {iife(() => {
              if (ScopedCreateContentId.isSelfPacedId(contentId)) {
                return <SelfPacedDataHistorySwitch contentId={contentId} />
              }

              if (ScopedCreateContentId.isLiveContentId(contentId)) {
                return <LiveDataHistorySwitch contentId={contentId} />
              }
            })}
          </motion.div>
        )
      })}
    </AnimatePresence>
  )
}
