import { useMutation } from '@tanstack/react-query'
import React, { useState } from 'react'
import { graphql } from 'sierra-client/api/graphql/gql'
import {
  EnrollmentRequestItemFragment,
  RequestStatus,
  RespondToCalendarEventEnrollmentRequestsInput,
} from 'sierra-client/api/graphql/gql/graphql'
import { convertGQLAvatarToAvatarProps } from 'sierra-client/api/graphql/util/convert-gql-avatar'
import { graphQuery } from 'sierra-client/api/hooks/use-graphql-query'
import { useTranslation } from 'sierra-client/hooks/use-translation'
import { TranslationLookup } from 'sierra-client/hooks/use-translation/types'
import { isNonNullable } from 'sierra-domain/utils'
import { Tooltip, UserDisplay } from 'sierra-ui/components'
import { Button, Text, TextAreaPrimitive, View } from 'sierra-ui/primitives'
import { IconMenu } from 'sierra-ui/primitives/menu-dropdown'
import styled from 'styled-components'

export const enrollmentRequestItemFragment = graphql(`
  fragment EnrollmentRequestItem on CalendarEventEnrollmentRequest {
    requestId
    requesterComment
    requestStatus
    handlerAnswer
    handler {
      ...BasicUserFragment
    }
    requester {
      ...BasicUserFragment
    }
  }
`)

const Request = styled.div`
  display: grid;
  grid-column-gap: 12px;
  grid-row-gap: 8px;
  grid-template-columns: min-content 1fr min-content;
  grid-template-rows: min-content min-content min-content;
  grid-template-areas: 'avatar name actions' '. comment comment' '. answer answer';
`

const Actions = styled(View).attrs({ direction: 'row' })``

const Comment = styled(Text).attrs({ color: 'foreground/muted', size: 'micro' })`
  grid-area: comment;
`

const Answer = styled(View).attrs({ direction: 'column', animated: true })`
  grid-area: answer;
`

const AnswerActions = styled(View).attrs({ direction: 'row', justifyContent: 'flex-end', gap: '6' })``

const getLabel = (
  responseStatus: Exclude<RequestStatus, 'PENDING'>,
  handlerAnswerFilled: boolean,
  t: TranslationLookup
): string => {
  if (handlerAnswerFilled) {
    if (responseStatus === 'APPROVED') {
      return t('dictionary.approve')
    } else {
      return t('dictionary.reject')
    }
  } else {
    if (responseStatus === 'APPROVED') {
      return t('in-person-events.approve-without-note')
    } else {
      return t('in-person-events.reject-without-note')
    }
  }
}

const respondToEnrollmentRequestMutation = graphql(`
  mutation respondToCalendarEventEnrollmentRequests($input: RespondToCalendarEventEnrollmentRequestsInput!) {
    respondToCalendarEventEnrollmentRequests(input: $input) {
      requestId
    }
  }
`)

const EditRequestAnswer: React.FC<{
  request: EnrollmentRequestItemFragment
  responseStatus: Exclude<RequestStatus, 'PENDING'>
  setResponseStatus: (status: RequestStatus) => void
  refetch: () => void
}> = ({ request, responseStatus, setResponseStatus, refetch }) => {
  const [handlerAnswer, setHandlerAnswer] = useState('')
  const { t } = useTranslation()

  const requester = request.requester

  const respondMutation = useMutation({
    mutationFn: async (input: RespondToCalendarEventEnrollmentRequestsInput) => {
      return graphQuery(respondToEnrollmentRequestMutation, { input })
    },
    onSuccess: () => {
      setHandlerAnswer('')
      refetch()
    },
  })

  return (
    <Answer>
      <TextAreaPrimitive
        placeholder={t('in-person-events.answer-placeholder', { name: requester.displayName })}
        value={handlerAnswer}
        onChange={e => setHandlerAnswer(e.target.value)}
      />
      <AnswerActions>
        <Button variant='secondary' onClick={() => setResponseStatus('PENDING')}>
          {t('dictionary.cancel')}
        </Button>
        <Button
          variant={responseStatus === 'REJECTED' ? 'destructive' : 'success'}
          onClick={() => {
            respondMutation.mutate({
              responses: [
                {
                  requestId: request.requestId,
                  requestStatus: responseStatus,
                  handlerAnswer,
                },
              ],
            })
          }}
        >
          {getLabel(responseStatus, handlerAnswer !== '', t)}
        </Button>
      </AnswerActions>
    </Answer>
  )
}

const RequestEdit: React.FC<{
  request: EnrollmentRequestItemFragment
  isApproveDisabled: boolean
  refetch: () => void
}> = ({ request, isApproveDisabled, refetch }) => {
  // const requestId = request.requestId
  const [responseStatus, setResponseStatus] = useState<RequestStatus>('PENDING')
  const { t } = useTranslation()

  return (
    <>
      <Actions>
        {responseStatus === 'PENDING' && (
          <>
            <Tooltip title={isApproveDisabled ? t('in-person-events.approve-disabled') : undefined}>
              <Button
                icon='checkbox--checkmark--filled'
                iconColor={isApproveDisabled ? 'grey25' : 'greenBright'}
                variant='secondary'
                disabled={isApproveDisabled}
                onClick={() => setResponseStatus('APPROVED')}
              >
                {t('dictionary.approve')}
              </Button>
            </Tooltip>
            <Button
              icon='checkbox--cross--filled'
              iconColor='redVivid'
              variant='secondary'
              onClick={() => setResponseStatus('REJECTED')}
            >
              {t('dictionary.reject')}
            </Button>
          </>
        )}
      </Actions>
      {isNonNullable(request.requesterComment) && request.requesterComment.trim().length > 0 && (
        <Comment>&quot;{request.requesterComment}&quot;</Comment>
      )}
      {responseStatus !== 'PENDING' && (
        <EditRequestAnswer
          request={request}
          responseStatus={responseStatus}
          setResponseStatus={setResponseStatus}
          refetch={refetch}
        />
      )}
    </>
  )
}

const RequestExists: React.FC<{
  request: EnrollmentRequestItemFragment
  refetch: () => void
  isApproveDisabled: boolean
}> = ({ request, refetch, isApproveDisabled }) => {
  const { t } = useTranslation()
  const handledBy = request.handler
  const handlerAnswer = request.handlerAnswer ?? ''
  const [responseStatus, setResponseStatus] = useState<RequestStatus>('PENDING')

  return (
    <>
      <Actions>
        {request.requestStatus === 'APPROVED' && (
          <>
            <IconMenu
              iconId='overflow-menu--horizontal'
              size='small'
              variant='transparent'
              onSelect={() => {
                setResponseStatus(responseStatus === 'PENDING' ? 'REJECTED' : 'PENDING')
              }}
              menuItems={[
                {
                  id: 'reject',
                  type: 'label',
                  icon: 'checkbox--cross--filled',
                  iconColor: 'destructive/background',
                  label: t('in-person-events.reject'),
                },
              ]}
            />
            {/*<Icon iconId='checkbox--checkmark--filled' size='size-16' color='success/background' />*/}
          </>
        )}
        {request.requestStatus === 'REJECTED' && (
          <>
            <IconMenu
              iconId='overflow-menu--horizontal'
              size='small'
              variant='transparent'
              onSelect={() => {
                setResponseStatus(responseStatus === 'PENDING' ? 'APPROVED' : 'PENDING')
              }}
              menuItems={[
                {
                  id: 'approve',
                  type: 'label',
                  label: t('in-person-events.change-to-approved'),
                  iconColor: 'success/background',
                  icon: 'checkbox--checkmark--filled',
                  disabled: isApproveDisabled,
                  tooltip: isApproveDisabled ? t('in-person-events.approve-disabled') : undefined,
                },
              ]}
            />
            {/*<Icon iconId='checkbox--cross--filled' size='size-16' color='destructive/background' />*/}
          </>
        )}
      </Actions>
      {handlerAnswer.length > 0 ? (
        <Comment>
          &quot;{request.handlerAnswer}&quot;
          {isNonNullable(handledBy) ? ` - ${t('dictionary.by')} ${handledBy.displayName}` : ''}
        </Comment>
      ) : (
        <Comment>{t('in-person-events.join-request-handled-by', { name: handledBy?.displayName })}</Comment>
      )}
      {responseStatus !== 'PENDING' && (
        <EditRequestAnswer
          request={request}
          responseStatus={responseStatus}
          setResponseStatus={setResponseStatus}
          refetch={refetch}
        />
      )}
    </>
  )
}

export const RequestItem: React.FC<{
  request: EnrollmentRequestItemFragment
  refetch: () => void
  isApproveDisabled: boolean
}> = ({ request, refetch, isApproveDisabled }) => {
  const requester = request.requester

  return (
    <>
      <Request>
        <UserDisplay
          primaryText={requester.displayName}
          secondaryText={requester.email}
          avatar={convertGQLAvatarToAvatarProps(requester.id, requester.avatar)}
          Wrapper={React.Fragment}
        />
        {request.requestStatus === 'PENDING' ? (
          <RequestEdit request={request} refetch={refetch} isApproveDisabled={isApproveDisabled} />
        ) : (
          <RequestExists request={request} refetch={refetch} isApproveDisabled={isApproveDisabled} />
        )}
      </Request>
    </>
  )
}
