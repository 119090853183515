import { flatten, uniq } from 'lodash'
import { useMemo } from 'react'
import { Emoji } from 'sierra-client/components/common/emoji'
import { useTranslation } from 'sierra-client/hooks/use-translation'
import { selectMessageReactions } from 'sierra-client/state/chat/selectors'
import { useSelector } from 'sierra-client/state/hooks'
import { selectUserId } from 'sierra-client/state/user/user-selector'
import { useUsersLegacy } from 'sierra-client/state/users/hooks'
import { ScopedChatId } from 'sierra-domain/collaboration/types'
import { commaSeparatedList } from 'sierra-domain/utils'
import { Tooltip } from 'sierra-ui/components'
import { spacing, token } from 'sierra-ui/theming'
import styled from 'styled-components'

const ReactionContainer = styled.div`
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  gap: 4px;
  margin-top: ${spacing['4']};
`

const Reaction = styled.button<{ dark: boolean }>`
  display: flex;
  align-items: center;
  padding: 8px;
  background-color: ${p => (p.dark ? token('surface/soft') : 'transparent')};
  border-width: 1px;
  border-style: solid;
  border-color: ${token('surface/soft')};
  border-radius: 6px;

  @media (prefers-color-scheme: dark) {
    background-color: ${p => (p.dark ? token('surface/strong') : 'transparent')};
    border-color: ${token('surface/strong')};
  }

  &:hover {
    cursor: pointer;
    border-color: ${p => (p.dark ? 'transparent' : token('border/strong'))};
    background-color: ${token('border/default')};
  }
`

const ReactionCount = styled.span`
  font-size: 10px;
  margin-left: 6px;
  color: ${token('foreground/secondary')};
`

export const MessageReactions = ({
  chatId,
  messageId,
  onReaction,
}: {
  chatId: ScopedChatId
  messageId: string
  onReaction: (reaction: string) => void
}): JSX.Element | null => {
  const userId = useSelector(selectUserId)
  const reactions = useSelector(state => selectMessageReactions(state, chatId, messageId))
  const uniqueReactions = Object.keys(reactions)
  const { t } = useTranslation()

  const userIds = useMemo(
    () => uniq(flatten(Object.values(reactions)).map(reaction => reaction.userId)),
    [reactions]
  )
  const users = useUsersLegacy(userIds)

  if (uniqueReactions.length === 0) return null

  return (
    <ReactionContainer>
      {uniqueReactions.map(reaction => {
        const count = reactions[reaction]?.length ?? 0
        const dark = !!reactions[reaction]?.find(userReaction => userReaction.userId === userId)
        const react = (): void => onReaction(reaction)
        const reactedUserIds = reactions[reaction]?.map(reaction => reaction.userId) ?? []

        const userNames = users
          .filter(user => user && reactedUserIds.includes(user.uuid) && user.firstName)
          .map(user => (userId === user?.uuid ? t('dictionary.you') : user?.firstName))

        return (
          <Tooltip key={reaction} title={commaSeparatedList(userNames)}>
            <Reaction dark={dark} onClick={react}>
              <Emoji emojiUnicode={reaction} size={14} />
              {count > 1 ? <ReactionCount>{count}</ReactionCount> : null}
            </Reaction>
          </Tooltip>
        )
      })}
    </ReactionContainer>
  )
}
