import { TranslationLookup } from 'sierra-client/hooks/use-translation/types'
import { DashboardWidget, DimensionRef, MeasureRef } from 'sierra-domain/api/insights'
import { IconId } from 'sierra-ui/components'

export type HardcodedWidgetTemplate = {
  icon: IconId
  widget: WidgetWithDashboardMetadata
}
export type WidgetWithDashboardMetadata = Omit<DashboardWidget, 'layout' | 'id'>

const getCompletionRateTemplate = (t: TranslationLookup): WidgetWithDashboardMetadata => {
  return {
    title: t('insights.template.completionRate.title'),
    widget: {
      type: 'widget.bar-chart',
      dimensions: [DimensionRef.parse({ type: 'dimension.native.course.course' })],
      measures: [MeasureRef.parse({ type: 'measure.native.user-course-progress.passed-rate' })],
      filter: {
        type: 'filter.and',
        filters: [
          {
            type: 'filter.filter',
            domain: {
              type: 'dimension.native.user-course-progress.assigned',
            },
            operator: {
              type: 'operator.eq',
            },
            predicate: {
              type: 'predicate.or',
              values: [
                {
                  type: 'value.boolean',
                  value: true,
                },
              ],
            },
          },
        ],
      },
      sortBy: [
        {
          column: {
            type: 'column.measure',
            measure: MeasureRef.parse({ type: 'measure.native.user-course-progress.passed-rate' }),
          },
          order: 'desc',
        },
      ],
    },
    settings: {
      automaticTitle: false,
    },
  }
}

const getTimeSpentLearning = (t: TranslationLookup): WidgetWithDashboardMetadata => {
  return {
    title: t('insights.template.timeSpentLearning.title'),
    widget: {
      type: 'widget.bar-chart',
      measures: [MeasureRef.parse({ type: 'measure.native.session.duration' })],
      dimensions: [DimensionRef.parse({ type: 'dimension.native.user.user' })],
      filter: undefined,
    },
    settings: {
      automaticTitle: false,
    },
  }
}

const getMostPopularCourse = (t: TranslationLookup): WidgetWithDashboardMetadata => {
  return {
    title: t('insights.template.mostPopularCourse.title'),
    widget: {
      type: 'widget.bar-chart',
      dimensions: [DimensionRef.parse({ type: 'dimension.native.course.course' })],
      measures: [MeasureRef.parse({ type: 'measure.native.user-course-progress.started-count' })],
      filter: undefined,
      sortBy: [
        {
          column: {
            type: 'column.measure',
            measure: MeasureRef.parse({ type: 'measure.native.user-course-progress.started-count' }),
          },
          order: 'desc',
        },
      ],
    },
    settings: {
      automaticTitle: false,
    },
  }
}

export const getHardcodedTemplates = (t: TranslationLookup): HardcodedWidgetTemplate[] => {
  return [
    {
      icon: 'time',
      widget: getTimeSpentLearning(t),
    },
    {
      icon: 'gauge',
      widget: getCompletionRateTemplate(t),
    },
    {
      icon: 'user--group',
      widget: getMostPopularCourse(t),
    },
  ]
}
