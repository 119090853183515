import { v2_breakpoint } from 'sierra-ui/theming/breakpoints'
import { css } from 'styled-components'

export const horizontalPadding = css`
  padding-left: 1rem;
  padding-right: 1rem;

  @media screen and (min-width: ${v2_breakpoint.phone}) {
    padding-left: 2rem;
    padding-right: 2rem;
  }

  @media screen and (min-width: ${v2_breakpoint.desktop_small}) {
    padding-left: 4rem;
    padding-right: 4rem;
  }
`

export const responsiveHorizontalCSS = css`
  width: 100%;
  margin-left: auto;
  margin-right: auto;

  ${horizontalPadding};
`

export const removeScrollbar = css`
  scrollbar-width: none;

  &::-webkit-scrollbar {
    display: none;
  }
`
