import _ from 'lodash'
import React, { useMemo, useState } from 'react'
import { useStableFunction } from 'sierra-client/hooks/use-stable-function'
import { AvatarId } from 'sierra-domain/api/avatar-id'
import { apply, CreateOperationState } from 'sierra-domain/editor/operations'
import { File, GeneratedNarrationDraft } from 'sierra-domain/flexible-content/types'
function getDefaultNarrationDraftState(defaultAvatarId: AvatarId): GeneratedNarrationDraft {
  return {
    avatarId: defaultAvatarId,
    script: '',
  }
}
export function useAiNarrationDraftState({
  file,
  operationState,
  defaultAvatarId,
  defaultScript,
}: {
  file: File
  operationState: CreateOperationState
  defaultAvatarId: AvatarId
  defaultScript: string
}): {
  avatarId: AvatarId
  setAvatarId: React.Dispatch<React.SetStateAction<AvatarId>>
  script: string
  setScript: React.Dispatch<React.SetStateAction<string>>
} {
  const script = file.generatedNarrationDraft?.script ?? defaultScript
  const avatarId = file.generatedNarrationDraft?.avatarId ?? defaultAvatarId
  const [liveScript, setLiveScript] = useState<string>(script)
  // We don't want to update the saved script in the yDoc too often, since this may
  // cause the size of the yDoc to grow unexpedtedly large.
  const debouncedSaveScript = useMemo(
    () =>
      _.debounce((newScript: string) => {
        apply(operationState, {
          type: 'update-files',
          fileIds: [file.id],
          update: file => {
            const newDraftState =
              file.generatedNarrationDraft ?? getDefaultNarrationDraftState(defaultAvatarId)
            newDraftState.script = newScript
            file.generatedNarrationDraft = newDraftState
          },
        })
      }, 500),
    [defaultAvatarId, file.id, operationState]
  )
  const setScript: React.Dispatch<React.SetStateAction<string>> = useStableFunction(update => {
    setLiveScript(liveScript => {
      const newScript = typeof update === 'function' ? update(liveScript) : update
      debouncedSaveScript(newScript)
      return newScript
    })
  })
  const setAvatarId: React.Dispatch<React.SetStateAction<AvatarId>> = useStableFunction(update => {
    const newAvatarId = typeof update === 'function' ? update(avatarId) : update
    // No need to debounce this one since it does not save often
    apply(operationState, {
      type: 'update-files',
      fileIds: [file.id],
      update: file => {
        const newDraftState = file.generatedNarrationDraft ?? getDefaultNarrationDraftState(defaultAvatarId)
        newDraftState.avatarId = newAvatarId
        file.generatedNarrationDraft = newDraftState
      },
    })
  })
  return {
    script: liveScript,
    setScript,
    avatarId,
    setAvatarId,
  }
}
