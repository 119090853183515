import { zodResolver } from '@hookform/resolvers/zod'
import React, { useState } from 'react'
import { Controller, useFieldArray, useForm } from 'react-hook-form'
import type { SkillLevelSettingId } from 'sierra-client/api/graphql/branded-types'
import { getHighestSkillLevelSettingId } from 'sierra-client/features/skills/helpers'
import { SkillUserToSubscribe } from 'sierra-client/features/skills/hooks/use-users-and-groups-auto-complete'
import { useTranslation } from 'sierra-client/hooks/use-translation'
import { RoundAvatarFromAvatar } from 'sierra-client/views/manage/components/round-avatar-from-avatar'
import { FormSingleSelectDropdown } from 'sierra-client/views/manage/shared/form'
import { isNonNullable } from 'sierra-domain/utils'
import { FormElement, Icon } from 'sierra-ui/components'
import { Button, ScrollView, Spacer, Text, View } from 'sierra-ui/primitives'
import { IconMenu } from 'sierra-ui/primitives/menu-dropdown'
import { token } from 'sierra-ui/theming'
import styled from 'styled-components'
import z from 'zod'
import { AchievedLevelSelector, SkillLevel, targetIsHigherOrEqualToAchieved } from './common'

const Form = styled.form`
  display: flex;
  flex-direction: column;
  height: 100%;
  gap: 0;
  overflow: hidden;
  padding-top: 8px;
  justify-content: space-between;
`

const Footer = styled(View).attrs({
  alignItems: 'center',
})<{ usersLength: number }>`
  min-height: 90px;
  position: sticky;
  bottom: 0;
  padding: 0 40px 32px 40px;
  justify-content: ${({ usersLength }) => (usersLength === 0 ? 'flex-end' : 'space-between')};
`

const ScrollGradientTop = styled(View).attrs({ direction: 'column', padding: 'none' })`
  position: relative;

  &::before {
    content: '';
    display: block;
    position: absolute;
    top: 0px;
    left: 0;
    width: 100%;
    height: 30px;
    z-index: 2;
    background: linear-gradient(
      to bottom,
      ${token('surface/default').opacity(1)} 30%,
      ${token('surface/default').opacity(0)}
    );
  }
`

const ScrollGradientBottom = styled(View).attrs({ direction: 'column', padding: 'none' })`
  position: relative;

  &::before {
    content: '';
    display: block;
    position: absolute;
    top: -25px;
    left: 0;
    width: 100%;
    height: 30px;
    z-index: 2;
    background: linear-gradient(
      to top,
      ${token('surface/default').opacity(1)} 30%,
      ${token('surface/default').opacity(0)}
    );
  }
`

const ScrollableLearners = styled(ScrollView).attrs({ direction: 'column' })`
  padding: 16px 0;
`

const Padding = styled(View).attrs({ direction: 'column', gap: 'none' })`
  padding: 0 40px;
`

const GridView = styled(View)`
  display: grid;
  align-items: flex-start;
  grid-template-areas:
    'avatar name overflow'
    'line targetLevel achievedLevels';
  grid-template-columns: 32px 1fr 1fr;
  column-gap: 16px;
  row-gap: 8px;
`

const GridAvatar = styled(RoundAvatarFromAvatar)`
  grid-area: avatar;
  align-self: center;
`

const GridName = styled(Text).attrs({ bold: true })`
  grid-area: name;
  align-self: center;
`

const GridButton = styled.div`
  grid-area: overflow;
  justify-self: flex-end;
`

const GridTargetLevelInput = styled.div`
  grid-area: targetLevel;
  padding: 8px 0;
`

const GridAchievedLevelsInput = styled.div`
  grid-area: achievedLevels;
  padding: 8px 0;
`

const VerticalLine = styled.div`
  width: 4px;
  background-color: ${token('border/default')};
  flex-grow: 1;
  border-radius: 2px;
  grid-area: line;
  align-self: flex-start;
  height: 95%;
  justify-self: center;
`

// eslint-disable-next-line @typescript-eslint/explicit-function-return-type
const FormSchema = (levels: Array<SkillLevel>) =>
  z.object({
    learners: z.array(
      SkillUserToSubscribe.refine(
        l => {
          return targetIsHigherOrEqualToAchieved({
            achieved: l.achievedLevelsUpTo,
            target: l.targetLevel,
            levels: levels,
          })
        },
        { message: 'Target level must be higher or equal to any achieved level' }
      )
    ),
  })

type FormSchema = ReturnType<typeof FormSchema>

const useFormSchema = (levels: Array<SkillLevel>): FormSchema => {
  return FormSchema(levels)
}

export type FormData = z.infer<FormSchema>

const EmptyWrapper = styled(View).attrs({
  direction: 'column',
  padding: '64',
  justifyContent: 'center',
  alignItems: 'center',
  gap: 'none',
})`
  height: 80%;
`

const EmptyUsers: React.FC = () => {
  const { t } = useTranslation()

  return (
    <EmptyWrapper>
      <Icon iconId='user--add' color='foreground/muted' />
      <Spacer size='16' />
      <Text bold color='foreground/muted'>
        {t('manage.skills.enroll-learners.add-users-to-skill')}
      </Text>
      <Spacer size='2' />
      <Text color='foreground/muted'>{t('manage.skills.enroll-learners.empty-description')}</Text>
      <Spacer size='24' />
    </EmptyWrapper>
  )
}

const AddMoreIcon = styled(Icon).attrs({ iconId: 'add' })`
  padding: 7px;
  color: ${token('foreground/muted')};
  border: 1px solid ${token('form/border/2')};
  border-radius: 50%;
`

const AddMoreText = styled(Text).attrs({ bold: true, color: 'foreground/muted' })``

const WrapperButton = styled.button`
  width: fit-content;
  background-color: transparent;
  padding: 0;
  cursor: pointer;

  & p,
  span {
    transition-duration: 120ms;
    transition-timing-function: cubic-bezier(0.25, 0.5, 0.25, 1);
    transition-property: background-color, color, border-color;
  }

  &:hover {
    p {
      color: ${token('foreground/primary')};
    }

    span {
      color: ${token('foreground/primary')};
      border: 1px solid ${token('form/border/3')};
    }
  }
`

const AddMore: React.FC<{ onClick: () => void }> = ({ onClick }) => {
  const { t } = useTranslation()

  return (
    <WrapperButton onClick={onClick} type='button'>
      <View>
        <AddMoreIcon />
        <AddMoreText>{t('manage.skills.enroll-learners.add-more-users')}</AddMoreText>
      </View>
    </WrapperButton>
  )
}

export const UsersForm: React.FC<{
  selected: SkillUserToSubscribe[]
  onSubmit: (d: FormData) => void
  onClose: () => void
  levels: SkillLevel[]
  onRemoveItem: (email: string) => void
  setOpenBulkEdit: (open: boolean) => void
  bulkTargetLevel?: SkillLevelSettingId
  bulkAchievedLevelUpTo?: SkillLevelSettingId
  setBulkAchievedLevelUpTo: (levelId?: SkillLevelSettingId) => void
  setBulkTargetLevel: (level?: SkillLevelSettingId) => void
  focusInput: () => void
}> = ({
  selected,
  onSubmit,
  levels,
  onClose,
  onRemoveItem,
  setOpenBulkEdit,
  bulkAchievedLevelUpTo,
  bulkTargetLevel,
  setBulkAchievedLevelUpTo,
  setBulkTargetLevel,
  focusInput,
}) => {
  const [usersInBulk, setUsersInBulk] = useState<string[]>([])

  const highestSkillLevelId = getHighestSkillLevelSettingId(levels)

  const { t } = useTranslation()
  const schema = useFormSchema(levels)

  const handler = useForm<FormData>({
    resolver: zodResolver(schema),
    values: {
      learners: selected.map(s => {
        if (usersInBulk.includes(s.email)) {
          return {
            ...s,
            targetLevel: bulkTargetLevel,
            achievedLevelsUpTo: bulkAchievedLevelUpTo,
          }
        } else {
          return { ...s }
        }
      }),
    },
  })

  const learnersHandler = useFieldArray({ control: handler.control, name: 'learners' })
  const { isLoading, errors } = handler.formState

  const onBulkEdit = (): void => {
    setOpenBulkEdit(true)
    setUsersInBulk(selected.map(s => s.email))
  }

  const closeAndWipeBulkStates = (): void => {
    onClose()
    setBulkAchievedLevelUpTo()
    setBulkTargetLevel(highestSkillLevelId)
  }

  const onBlur = async (): Promise<void> => {
    await handler.trigger()
  }

  return (
    <>
      <ScrollGradientTop />
      <Form onSubmit={handler.handleSubmit(onSubmit)} onBlur={onBlur}>
        {selected.length === 0 ? (
          <EmptyUsers />
        ) : (
          <ScrollableLearners>
            <Padding>
              {learnersHandler.fields.map((l, i) => (
                <GridView key={l.id}>
                  <VerticalLine />
                  <GridName>{l.displayName}</GridName>
                  <GridAvatar size='medium' userId={l.id} avatar={l.avatar} />
                  <GridButton>
                    <IconMenu
                      size='small'
                      variant='transparent'
                      iconId='overflow-menu--horizontal'
                      menuItems={[
                        {
                          id: 'remove',
                          type: 'label',
                          label: t('dictionary.remove'),
                          icon: 'trash-can',
                          color: 'destructive/background',
                        },
                      ]}
                      onSelect={item => {
                        switch (item.id) {
                          case 'remove':
                            return onRemoveItem(l.email)
                        }
                      }}
                    />
                  </GridButton>
                  <GridTargetLevelInput>
                    <Text bold>{t('skills.panel.subscribe-user.target-level')}</Text>
                    <Spacer size={'4'} />
                    <FormSingleSelectDropdown
                      control={handler.control}
                      name={`learners.${i}.targetLevel`}
                      menuItems={levels.map(l => ({
                        label: l.levelSetting.name,
                        id: l.levelSetting.id,
                        value: l.levelSetting.index,
                        type: 'label',
                      }))}
                    />
                  </GridTargetLevelInput>
                  <GridAchievedLevelsInput>
                    <Text bold>{t('skills.panel.subscribe-user.achieved-levels')}</Text>
                    <Spacer size={'4'} />
                    <Controller
                      name={`learners.${i}.achievedLevelsUpTo`}
                      control={handler.control}
                      render={({ field }) => {
                        const error = errors.learners?.[i]?.root
                        return (
                          <FormElement
                            isError={isNonNullable(error)}
                            helper={error ? error.message : undefined}
                            label={undefined}
                          >
                            <AchievedLevelSelector {...field} levels={levels} />
                          </FormElement>
                        )
                      }}
                    />
                  </GridAchievedLevelsInput>
                </GridView>
              ))}
              <Spacer size='8' />
              <AddMore onClick={focusInput} />
            </Padding>
          </ScrollableLearners>
        )}
        <ScrollGradientBottom />
        <Footer usersLength={selected.length}>
          {selected.length === 0 ? null : (
            <Button type='button' variant='secondary' onClick={onBulkEdit}>
              {t('manage.skills.edit-all')}
            </Button>
          )}
          <View>
            {Object.values(errors).map(error => {
              return (
                <Text size='micro' color={'destructive/background'} key={error.message}>
                  {error.message}
                </Text>
              )
            })}
            <View>
              <Button type='button' variant='secondary' onClick={closeAndWipeBulkStates}>
                {t('cancel')}
              </Button>
              <Button type='submit' loading={isLoading} disabled={selected.length === 0}>
                {t('dictionary.add')}
              </Button>
            </View>
          </View>
        </Footer>
      </Form>
    </>
  )
}
