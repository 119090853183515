import React, { useCallback } from 'react'
import { useDragLayer } from 'react-dnd'
import { DragItem } from 'sierra-client/components/common/dnd/dnd-types'
import { DragOverlayPosition } from 'sierra-client/components/common/dnd/drag-overlay-position'
import { IconGhostContent } from 'sierra-client/components/common/dnd/icon-ghost-content'
import { NodeGhostContent } from 'sierra-client/components/common/dnd/node-ghost-content'
import { useAssetResolver } from 'sierra-client/hooks/use-resolve-asset'

export const NodeGhost: React.FC = () => {
  const { item, isDragging } = useDragLayer<{
    isDragging: boolean
    item: DragItem
  }>(monitor => ({
    isDragging: monitor.isDragging(),
    item: monitor.getItem(),
  }))

  const assetResolver = useAssetResolver({ size: 'course-sm' })

  const renderItem = useCallback(() => {
    if ('image' in item && item.type !== 'poll-alternative') {
      const imageUrl = assetResolver(item.image, item.assetContext)
      return <IconGhostContent title={item.title} imageUrl={imageUrl} />
    }

    if ('file' in item) {
      return (
        <NodeGhostContent type='file' title={item.title} file={item.file} assetContext={item.assetContext} />
      )
    }

    if ('iconId' in item) {
      return <NodeGhostContent type='folder' title={item.title} iconId={item.iconId} />
    }

    return null
  }, [item, assetResolver])

  if (!isDragging) return null
  if (item.type !== 'content-table' && item.type !== 'editor-sidebar') return null

  return <DragOverlayPosition>{renderItem()}</DragOverlayPosition>
}
