import React, { useState } from 'react'
import { useTranslation } from 'sierra-client/hooks/use-translation'
import { DialogIcon, DialogTitleWithIcons } from 'sierra-client/views/manage/components/dialog-utils'
import { LinkedInContentTable } from 'sierra-client/views/manage/linked-in/linked-in-content-table'
import { Modal } from 'sierra-ui/components'
import { Text, View } from 'sierra-ui/primitives'

interface LinkedInImportDialogProps {
  isOpen: boolean
  onClose: () => void
  onAfterImport: () => void
}

export const LinkedInImportDialog: React.FC<LinkedInImportDialogProps> = ({
  isOpen,
  onClose,
  onAfterImport,
}) => {
  const { t } = useTranslation()
  const [courseDetailsUrn, setCourseDetailsUrn] = useState<string | undefined>()

  const closeAndReset = (): void => {
    onClose()
    setCourseDetailsUrn(undefined)
  }

  return (
    <Modal open={isOpen} size={{ width: 1050, height: 830 }} onClose={closeAndReset}>
      <View>
        <DialogTitleWithIcons
          leftIcon={
            courseDetailsUrn !== undefined ? (
              <DialogIcon
                onClick={() => setCourseDetailsUrn(undefined)}
                iconProps={{ iconId: 'arrow--left' }}
              />
            ) : undefined
          }
          rightIcon={<DialogIcon onClick={closeAndReset} />}
        >
          <Text color='foreground/primary' bold size='regular'>
            {t('manage.course.import-courses')}
          </Text>
        </DialogTitleWithIcons>
      </View>
      <View direction='column' alignItems='stretch' justifyContent='flex-start' grow>
        {isOpen && (
          <LinkedInContentTable
            courseDetailsUrn={courseDetailsUrn}
            setCourseDetailsUrn={setCourseDetailsUrn}
            onAfterImport={() => {
              closeAndReset()
              onAfterImport()
            }}
            onClose={closeAndReset}
          />
        )}
      </View>
    </Modal>
  )
}
