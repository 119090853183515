import { useAtomValue } from 'jotai'
import _ from 'lodash'
import React, { useCallback, useContext, useMemo } from 'react'
import { useTranslation as useTranslationExternal } from 'react-i18next' // eslint-disable-line no-restricted-imports
import { useActiveRouteId } from 'sierra-client/hooks/router/use-active-route'
import { useMemoByCompare } from 'sierra-client/hooks/use-memo-by-compare'
import { DynamicT, TranslationFunctions, TranslationLookup } from 'sierra-client/hooks/use-translation/types'
import { chaosTestingEnabledAtom, showTranslationStringsAtom } from 'sierra-client/state/settings'
function random(min: number, max: number): number {
  return Math.floor(Math.random() * (max - min + 1)) + min
}

function generateChaosString(): string {
  const alphabet = 'abcdefghijklmnopqrstuvwxyz0123456789'

  function generateWord(): string {
    return Array(random(5, 10))
      .fill('')
      .map(() => alphabet[random(0, alphabet.length - 1)])
      .join('')
  }

  const words = Array(random(4, 8)).fill('').map(generateWord).join(' ')

  return words
}

export type UseTranslationResult = TranslationFunctions & { lang: string }

const TranslationContext = React.createContext<UseTranslationResult | undefined>(undefined)

export const TranslationContextProvider: React.FC<{ children: React.ReactNode }> = ({ children }) => {
  const {
    t: baseT,
    i18n: { language: lang },
  } = useTranslationExternal()

  const showTranslationStrings = useAtomValue(showTranslationStringsAtom)
  const chaosTestingEnabled = useAtomValue(chaosTestingEnabledAtom)

  const t: TranslationLookup = chaosTestingEnabled
    ? generateChaosString
    : !showTranslationStrings
      ? baseT
      : _.identity

  const dynamicT = useCallback<DynamicT>(
    (key, args, defaultValue) => {
      if (showTranslationStrings) return key
      if (chaosTestingEnabled) return generateChaosString()

      const result = defaultValue !== undefined ? baseT(key, { ...args, defaultValue }) : baseT(key, args)

      if (result === key && defaultValue !== undefined) {
        return defaultValue
      } else {
        return result
      }
    },
    [baseT, showTranslationStrings, chaosTestingEnabled]
  )

  const value = useMemo(() => ({ t, dynamicT, lang }), [t, dynamicT, lang])

  return <TranslationContext.Provider value={value}>{children}</TranslationContext.Provider>
}

/**
 * A typed hook for accessing our translations.
 *
 * Normal usage:
 * ```
 * const { t }  = useTranslation()
 *
 * // Getting a translation
 * t('admin.access-levels.admin')
 * ```
 *
 * The `t` function requires a string which can statically be resolved to a translation in our language JSON schema.
 * If you cannot guaranteee this at compile-time, you can use `dynamicT` instead:
 * ```
 * const {dynamicT} = useTranslation()
 *
 * // Getting a translation dynamically:
 * // If the translation exists, you will get the resolved string, otherwise you will get back the key you passed in.
 * dynamicT('i-hope-this-exists')
 * ```
 */
export function useTranslation(): UseTranslationResult {
  const value = useContext(TranslationContext)

  if (value === undefined) {
    throw new Error('Expected component to be wrapped with `<TranslationContextProvider>')
  }

  return value
}

// This functions allows for using translations that should not change based on query params, e.g. query states
export function useTranslationForBaseRoute(): UseTranslationResult {
  const { translation } = useMemoByCompare(
    { routeId: useActiveRouteId(), translation: useTranslation() },
    (prev, next) => prev.routeId === next.routeId
  )

  return translation
}
