import { useEffect } from 'react'
import { useInvalidateCachedQuery, useTypedMutation } from 'sierra-client/state/api'
import { ChatIdentifier } from 'sierra-domain/api/chat'
import { ScopedChatId } from 'sierra-domain/collaboration/types'
import { XRealtimeChatGetMessages, XRealtimeChatMigrateChat } from 'sierra-domain/routes'

export const TriggerChatMigration: React.FC<{
  chatId: ScopedChatId
  chatIdentifier: ChatIdentifier
}> = ({ chatId, chatIdentifier }): null => {
  const refetchChat = useInvalidateCachedQuery(XRealtimeChatGetMessages, {
    chatIdentifier,
  })
  const { mutate } = useTypedMutation(XRealtimeChatMigrateChat, {
    onSettled: () => refetchChat(),
  })

  useEffect(() => {
    mutate({ ydocId: chatId })
  }, [chatId, mutate])

  return null
}
