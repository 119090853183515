import { forwardRef } from 'react'
import { editorGrid, interactiveCardGrid } from 'sierra-client/editor/layout'
import { useRenderingContext } from 'sierra-client/views/v3-author/rendering-context'
import { SlateWrapperProps } from 'sierra-client/views/v3-author/slate'
import styled from 'styled-components'

/*
 * Shared styles between all wrappers.
 */
export const BaseWrapper = styled.div`
  position: relative;
`

export const BaseInlineWrapper = styled.span``

/*
 * BlockWrapper falls back to this when the grid is disabled.
 * For paragraphs inside a quote, there's no need for a grid for example.
 */
const PlainWrapper = styled(BaseWrapper)``

/*
 * This wrapper is used for inline blocks.
 */
const InlineWrapper = styled(BaseWrapper)`
  display: inline;
`

/*
 * This wrapper can be used to force a grid around the block.
 * Image uses this, we want to be able to control the variants anywhere.
 */
export const GridWrapper = styled(BaseWrapper)`
  ${editorGrid}
`

export const CardWrapper = styled.div`
  display: flex;
  width: 100%;
  height: 100%;
  flex: 1;
`

export const CardGrid = styled.div`
  display: grid;
  flex: 1;
  grid-template-columns: repeat(14, 1fr);
  gap: 1.5rem;
`

/*
 * This wrapper adapts to the context. Disable the grid with a RenderingContextProvider.
 */
export const BlockWrapper = forwardRef<HTMLDivElement, SlateWrapperProps & { className?: string }>(
  ({ children, element, ...props }, ref) => {
    const inline = props.attributes['data-slate-inline'] === true
    const { withGrid } = useRenderingContext()
    const Wrapper = inline ? InlineWrapper : withGrid ? GridWrapper : PlainWrapper

    return (
      <Wrapper {...props} ref={ref}>
        {children}
      </Wrapper>
    )
  }
)

export const InteractiveCardWrapper = styled(BlockWrapper)`
  ${interactiveCardGrid}
`
