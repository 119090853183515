import React from 'react'
import { Link } from 'sierra-client/components/common/link'
import { useTranslation } from 'sierra-client/hooks/use-translation'
import { Icon } from 'sierra-ui/components'
import { Switch, Text, View } from 'sierra-ui/primitives'
import { token } from 'sierra-ui/theming'
import styled from 'styled-components'

const DescriptionWrapper = styled(View)`
  padding-left: 22px;
  max-width: calc(100% - 22px);
  white-space: pre-wrap;
`

const StyledLink = styled(Link)`
  color: ${token('foreground/link')};

  &:hover {
    color: ${token('foreground/link')};
  }
`

export const RequiredAssignmnentSwitch: React.FC<{
  isRequired: boolean
  setIsRequired: (value: boolean) => void
}> = ({ isRequired, setIsRequired }) => {
  const { t } = useTranslation()

  return (
    <>
      <View justifyContent='space-between'>
        <View gap='6'>
          <Icon iconId='arrow-up--filled' color='foreground/secondary' />
          <Text bold>{t('assignment.high-priority-assignment')}</Text>
        </View>
        <Switch
          checked={isRequired}
          onChange={val => setIsRequired(val)}
          text=''
          size='small'
          textPos='left'
        />
      </View>
      <DescriptionWrapper>
        <Text size='micro' bold color='foreground/muted'>
          {t('assignment.high-priority-assignment.description')}{' '}
          <StyledLink target='_blank' href='https://help.sana.ai/' size='micro' bold>
            {t('dictionary.learn-more')}
          </StyledLink>
        </Text>
      </DescriptionWrapper>
    </>
  )
}
