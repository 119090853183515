import { PrimitiveAtom, useAtomValue } from 'jotai'
import { useNarrationVideo } from 'sierra-client/views/flexible-content/ai-narrations/use-narration-video'
import { NarrationMetadata } from 'sierra-domain/api/author-v2'
import { CreateContentId } from 'sierra-domain/api/nano-id'
import { File } from 'sierra-domain/flexible-content/types'

export type CreatePageNarration =
  | (NarrationMetadata & { type: 'loading' })
  | { type: 'record-or-upload' }
  | { type: 'completed'; videoUrl: string }

export function useCreatePageNarration(
  narrationMetadata: NarrationMetadata | undefined,
  card: File | undefined,
  createContentId: CreateContentId,
  legacyNarrationStateAtom: PrimitiveAtom<boolean>
): CreatePageNarration | undefined {
  const resolvedVideoUrl = useNarrationVideo(card?.narration, createContentId)
  const narrationIsOpen = useAtomValue(legacyNarrationStateAtom)

  const isGeneratingVideo = narrationMetadata?.type === 'loading'
  if (narrationIsOpen) {
    return { type: 'record-or-upload' }
  } else if (isGeneratingVideo) {
    return narrationMetadata
  } else if (resolvedVideoUrl.type === 'success') {
    return { type: 'completed', videoUrl: resolvedVideoUrl.videoUrl }
  } else {
    return undefined
  }
}
