import { produce } from 'immer'
import { DateTime, DurationLike } from 'luxon'
import { Identity } from 'sierra-client/components/common/identities-selector'
import {
  DraftIssued,
  DraftIssuedWithError,
  IssuedCertificatesAction,
  IssuedCertificatesState,
} from 'sierra-client/views/manage/certificates/issue-certificate-panel/types'
import { IdentityRef } from 'sierra-domain/api/manage'
import { assertNever } from 'sierra-domain/utils'

export const EMPTY_ISSUED_CERTIFICATES_STATE: IssuedCertificatesState = {
  issues: [],
  resolvingGroup: null,
  hasError: false,
}

// ---

const isIssuedCertificateInvalid = (issued: DraftIssued): boolean => {
  if (
    issued.issuedAt.invalidReason !== null ||
    (issued.expiresAt !== undefined && issued.expiresAt.invalidReason !== null)
  ) {
    return true
  }
  if (issued.expiresAt !== undefined && issued.expiresAt <= issued.issuedAt) {
    return true
  }

  return false
}

// Add computed `hasError` property.
const getIssuedWithError = (issued: DraftIssued): DraftIssuedWithError => ({
  ...issued,
  hasError: isIssuedCertificateInvalid(issued),
})

const makeNewIssuedWithError = (
  identity: Identity,
  validityPeriod: DurationLike | undefined,
  issuedByProxy: Identity | undefined
): DraftIssuedWithError => {
  const issuedAt = DateTime.now()

  return getIssuedWithError({
    identity,
    issuedAt,
    expiresAt: validityPeriod !== undefined ? issuedAt.plus(validityPeriod) : undefined,
    supportingFile: undefined,
    supportingNote: undefined,
    issuedByProxy,
  })
}

const isSameIdentity = (a: IdentityRef, b: IdentityRef): boolean => a.type === b.type && a.id === b.id

const findIndexByIdentity = (issues: DraftIssuedWithError[], identity: IdentityRef): number =>
  issues.findIndex(issue => isSameIdentity(issue.identity.identity, identity))

const hasIdentity = (issues: DraftIssuedWithError[], identity: IdentityRef): boolean =>
  findIndexByIdentity(issues, identity) !== -1

// ---

export const issuedCertificatesReducer = produce<IssuedCertificatesState, [IssuedCertificatesAction]>(
  (draft, action) => {
    switch (action.type) {
      case 'add': {
        const newIssued = makeNewIssuedWithError(action.identity, action.validityPeriod, action.issuedByProxy)

        if (!hasIdentity(draft.issues, action.identity.identity)) {
          draft.issues.push(newIssued)
        } else {
          console.error(
            'Attempted to add an identity that already exists in the list of issued certificates.'
          )
        }

        break
      }
      case 'add-group': {
        draft.resolvingGroup = action.identityRef
        break
      }
      case 'resolved-group': {
        draft.resolvingGroup = null
        break
      }
      case 'upsert': {
        const newIssued = getIssuedWithError(action.payload)

        const index = findIndexByIdentity(draft.issues, action.payload.identity.identity)
        if (index !== -1) {
          draft.issues[index] = newIssued
        } else {
          draft.issues.push(newIssued)
        }
        break
      }
      case 'remove': {
        const index = findIndexByIdentity(draft.issues, action.payload)
        if (index !== -1) {
          draft.issues.splice(index, 1)
        }
        break
      }
      default:
        assertNever(action)
    }

    // Set global computed state
    draft.hasError = draft.issues.some(issue => issue.hasError)
  }
)
