import { useEffect, useState } from 'react'
import { useSetLocalPermissionsConfig } from 'sierra-client/components/shortcut-menu/permissions-inspector/atoms'
import { initializePermissionOverrides } from 'sierra-client/components/shortcut-menu/permissions-inspector/utils'
import { useSelector } from 'sierra-client/state/hooks'
import { selectIsOwnerUser } from 'sierra-client/state/user/user-selector'

type UseInitializePermissionsInspector = {
  isInitialized: boolean
}

export const useInitializePermissionsInspector = (): UseInitializePermissionsInspector => {
  const [isInitialized, setIsInitialized] = useState(false)
  const setLocalPermissionsConfig = useSetLocalPermissionsConfig()

  const isOwnerUser = useSelector(selectIsOwnerUser)

  useEffect(() => {
    if (isOwnerUser) {
      setLocalPermissionsConfig(initializePermissionOverrides())
      setIsInitialized(true)
    }
  }, [isOwnerUser, setLocalPermissionsConfig])

  return { isInitialized }
}
