import { useAtom } from 'jotai/index'
import { useCallback, useRef, useState } from 'react'
import {
  highlightedStepIdAtom,
  timedHighlightedStepIdAtom,
} from 'sierra-client/views/manage/programs/staggered-assignments/atoms'
import { StepHighlightReason } from 'sierra-client/views/manage/programs/staggered-assignments/types'

type UseScrollHighlight = {
  setHighlight: (highlightId: string, reason: StepHighlightReason, timeOut?: number) => void
  resetHighlight: () => void
  activateHighlight: () => void
  aboutToHighlight: boolean
  stepIsHighlighted: boolean
  reason: StepHighlightReason | undefined
  updateReason: (reason: StepHighlightReason | undefined) => void
}

export const highlightAnimationDuration = 1.5

export const useScrollHighlight = ({ stepId }: { stepId: string | undefined }): UseScrollHighlight => {
  const [highlightedStep, setHighlightedStep] = useAtom(highlightedStepIdAtom)
  const [timedHighlight, setTimedHighlight] = useAtom(timedHighlightedStepIdAtom)
  const [hasScrolled, setHasScrolled] = useState(false)
  const aboutToHighlight =
    highlightedStep !== undefined && highlightedStep.highlightId === stepId && hasScrolled === false
  const stepIsHighlighted = timedHighlight !== undefined && timedHighlight.highlightId === stepId
  const highlightTimout = useRef<number | undefined>()

  const resetHighlight: UseScrollHighlight['resetHighlight'] = useCallback(() => {
    clearTimeout(highlightTimout.current)
    setHighlightedStep(undefined)
    setTimedHighlight(undefined)
  }, [setHighlightedStep, setTimedHighlight])

  const setHighlight: UseScrollHighlight['setHighlight'] = useCallback(
    (highlightId, reason, timeOut = 0) => {
      const highlightTimer = setTimeout(() => {
        setHighlightedStep({ highlightId, reason })
      }, timeOut) as unknown as number // Wait for the panel to open
      highlightTimout.current = highlightTimer
    },
    [setHighlightedStep]
  )
  const activateHighlight: UseScrollHighlight['activateHighlight'] = useCallback(() => {
    setTimedHighlight(highlightedStep)
    setHasScrolled(true)
  }, [highlightedStep, setTimedHighlight])

  const reason =
    highlightedStep !== undefined && highlightedStep.highlightId === stepId
      ? timedHighlight?.reason
      : undefined

  const updateReason: UseScrollHighlight['updateReason'] = useCallback(() => {
    if (timedHighlight?.highlightId !== undefined) {
      setTimedHighlight({ highlightId: timedHighlight.highlightId, reason: undefined })
    }
  }, [setTimedHighlight, timedHighlight?.highlightId])

  return {
    setHighlight,
    resetHighlight,
    aboutToHighlight, // Used to check if we should scroll
    stepIsHighlighted,
    activateHighlight,
    reason,
    updateReason,
  }
}
