import Ably from 'ably'

type AblyClientResult = { client: Ably.Realtime; clientId: string }
let createdAblyClients: AblyClientResult[] = []

export const AblyDebug = {
  registerClient: (client: AblyClientResult): void => {
    createdAblyClients.push(client)
  },
  unregisterClient: (clientId: AblyClientResult['clientId']): void => {
    createdAblyClients = createdAblyClients.filter(c => c.clientId !== clientId)
  },
  getAllClients: (): AblyClientResult[] => {
    return createdAblyClients
  },
} as const
