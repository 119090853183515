import { assertElementType } from 'sierra-client/views/v3-author/queries'
import { RenderingContext } from 'sierra-client/views/v3-author/rendering-context'
import { SlateFC } from 'sierra-client/views/v3-author/slate'
import { CardGrid } from 'sierra-client/views/v3-author/wrapper'
import { v2_breakpoint } from 'sierra-ui/theming/breakpoints'
import styled, { css } from 'styled-components'

export const CardInnerStyled = styled.div<{ $variant: 'left-aligned' | 'center-aligned' | 'right-aligned' }>`
  padding-top: 10rem;
  padding-bottom: 10rem;
  height: min-content;
  margin: auto 0;

  grid-column: 3 / span 10;

  ${p => {
    if (p.$variant === 'center-aligned')
      return css`
        text-align: center;
        display: flex;
        flex-direction: column;
        align-items: center;

        & > * {
          text-align: center;
        }
      `
    if (p.$variant === 'right-aligned')
      return css`
        text-align: right;
        margin-left: auto;
        max-width: 50%;
        width: 100%;
      `
  }}

  @media screen and (max-width: ${v2_breakpoint.tablet}px) {
    grid-column: 2 / span 12;
  }
`

export const TitleCardComponent: SlateFC = ({ element, children }) => {
  assertElementType('title-card', element)

  return (
    <RenderingContext preventDrag={true} withGrid={false} disableMenu={false}>
      <CardGrid>
        <CardInnerStyled $variant={element.variant ?? 'left-aligned'}>{children}</CardInnerStyled>
      </CardGrid>
    </RenderingContext>
  )
}
