import { token } from 'sierra-ui/theming'
import styled from 'styled-components'

type Props = {
  $progress: number
  $large?: boolean
}

export const ProgressLine = styled.div<Props>`
  flex: 1;
  height: ${p => (p.$large === true ? '4px' : '2px')};
  border-radius: 0.5rem;

  ${p =>
    p.$progress > 0
      ? `background: linear-gradient(90deg, ${p.theme.color.greenBright} ${p.$progress * 100}%, ${
          p.theme.color.greenBright
        } ${p.$progress * 100}%, ${token('border/default')(p)} ${p.$progress * 100}%, ${token(
          'border/default'
        )(p)} 100%);`
      : `background-color: ${token('border/default')(p)}`}
`
