/* eslint-disable react/jsx-no-literals */
import { useMutation, useQuery } from '@tanstack/react-query'
import { useAtomValue } from 'jotai'
import React from 'react'
import { AblyDebug } from 'sierra-client/collaboration/ably-debug'
import { SquareAvatar } from 'sierra-client/components/common/square-avatar'
import { useVideoCallService } from 'sierra-client/components/liveV2/live-context'
import {
  CallStatsAtom,
  ComputePressureAtom,
  LocalTracksStatsAtom,
  PerformanceStatsAtom,
  RenderRateVolatilityEmaAtom,
  SectionTitle,
  StatsSchedulingTimeEmaAtom,
  StatsText,
  formatBitrate,
  formatBytes,
  formatFps,
  formatTime,
} from 'sierra-client/components/liveV2/live-layer/call-stats/atoms'
import { getCurrentPressure } from 'sierra-client/components/liveV2/live-layer/call-stats/cpu-pressure'
import { useLiveSessionIdContext } from 'sierra-client/components/liveV2/live-session-id-provider'
import { networkQualityDescriptions } from 'sierra-client/components/liveV2/services/video-call-service/helpers/network-quality'
import { IssueControl } from 'sierra-client/components/liveV2/simulate-hardware-error'
import { useIsDebugMode } from 'sierra-client/hooks/use-is-debug-mode'
import { selectCollabProviderStatuses } from 'sierra-client/state/collaboration/selectors'
import { useSelector } from 'sierra-client/state/hooks'
import {
  selectCallState,
  selectNetworkQualityStats,
  selectRemoteParticipants,
} from 'sierra-client/state/live/selectors'
import { ScopedLiveSessionId } from 'sierra-domain/collaboration/types'
import { Tabs } from 'sierra-ui/components'
import { Button, InputPrimitive, LoadingSpinner, Text, View } from 'sierra-ui/primitives'
import { IconMenu } from 'sierra-ui/primitives/menu-dropdown'
import { spacing, token } from 'sierra-ui/theming'
import styled from 'styled-components'
import z from 'zod'

const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
  background-color: ${token('surface/soft')};
  color: ${token('foreground/primary')};
  padding-top: ${spacing.large};
  height: 100%;
  overflow: auto;
`

const Section = styled(View).attrs({ direction: 'column', padding: 'xsmall' })``

const BOT_CONTROL_URL = 'https://pfc-server-4fdsufnlta-lz.a.run.app'
const BOT_CONTROL_AUTH_HEADER = 'x-auth-token'
const BOT_CONTROL_AUTH_SECRET = 'laiTQyY232tf28jjdjdLN0Qqdq4PzxsbrGXjCAtPApvfOx9EMpWmMJjN9ZkGS1xt'

const request = async (method: 'POST' | 'GET', endpoint: string, body?: any): Promise<unknown> => {
  const res = await fetch(`${BOT_CONTROL_URL}/v1${endpoint}`, {
    method,
    body: body !== undefined ? JSON.stringify(body) : undefined,
    headers: {
      'Content-Type': 'application/json',
      [BOT_CONTROL_AUTH_HEADER]: BOT_CONTROL_AUTH_SECRET,
    },
  })

  try {
    return await res.json()
  } catch {
    // ignore
  }
}

const BotClient = z.object({
  id: z.string(),
  name: z.string(),
  openSince: z.string(),
  health: z.string(),
  status: z.string(),
  url: z.string().optional(),
})
type BotClient = z.infer<typeof BotClient>

const BotApi = {
  open: async (url: string) => {
    await request('POST', '/open', { url })
  },
  openAsync: async (url: string) => {
    await request('POST', '/open-async', { url })
  },
  closeClient: async (id: string) => {
    await request('POST', '/close', { id })
  },
  getActiveClients: async (sessionId: string) => {
    const result = await request('GET', `/active-clients/${sessionId}`)
    return z.array(BotClient).parse(result)
  },
  toggleAudio: (id: string) => {
    return request('POST', `/action`, { id, action: 'toggle-audio' })
  },
  toggleVideo: (id: string) => {
    return request('POST', `/action`, { id, action: 'toggle-video' })
  },
}

const BotItemWrapper = styled(View).attrs({
  gap: '12',
  padding: '6 none',
  alignItems: 'center',
  position: 'relative',
})`
  border: none;
`

const BotItem = ({ bot }: { bot: BotClient }): JSX.Element => {
  const isReady = bot.status === 'joined'

  return (
    <BotItemWrapper>
      {isReady ? <SquareAvatar size='small' firstName={bot.name} /> : <LoadingSpinner />}
      <View direction='column' grow gap='none'>
        <StatsText>{bot.name} </StatsText>
      </View>

      <>
        <IconMenu
          variant='transparent'
          iconId='overflow-menu--vertical'
          onSelect={item => {
            switch (item.id) {
              case 'toggleAudio':
                return BotApi.toggleAudio(bot.id)
              case 'toggleVideo':
                return BotApi.toggleVideo(bot.id)
              case 'close':
                return BotApi.closeClient(bot.id)
            }
          }}
          menuItems={[
            {
              id: 'toggleAudio',
              type: 'label',
              icon: 'microphone--off',
              label: 'toggle audio',
            },
            {
              id: 'toggleVideo',
              type: 'label',
              icon: 'video--off',
              label: 'toggle video',
            },
            {
              id: 'close',
              type: 'label',
              icon: 'close',
              label: 'close',
            },
          ]}
        />
      </>
    </BotItemWrapper>
  )
}

const StyledForm = styled.form`
  color: ${token('foreground/primary')};
  display: flex;
  flex-direction: column;
  gap: ${spacing.xxsmall};
`

const CloseAllText = styled(Text)`
  cursor: pointer;
  margin-left: auto;
`

const BotControlls = (): JSX.Element => {
  const isDebugMode = useIsDebugMode()
  const [nrOfBots, setNrOfBots] = React.useState(1)

  const liveSessionId = ScopedLiveSessionId.extractId(useLiveSessionIdContext().liveSessionId)
  const bots = useQuery({
    queryKey: ['BotApi.getActiveClients', { liveSessionId }],
    queryFn: () => BotApi.getActiveClients(liveSessionId),
    refetchInterval: 2000,
  })

  const addBotMutation = useMutation({
    mutationFn: async () => {
      const host = window.location.origin.replace('localhost', 'master.staging')
      for (let i = 0; i < nrOfBots; i++) {
        await BotApi.openAsync(`${host}/l/${liveSessionId}`)
        // To avoid spamming the service we wait a bit between each request
        await new Promise(resolve => setTimeout(resolve, 300))
      }
    },
    onSuccess: () => bots.refetch(),
  })

  const closeAllBotsMutation = useMutation({
    mutationFn: async () => {
      for (const bot of bots.data ?? []) {
        await BotApi.closeClient(bot.id)
        // To avoid spamming the service we wait a bit between each request
        await new Promise(resolve => setTimeout(resolve, 200))
      }
    },
    onSuccess: () => bots.refetch(),
  })

  return (
    <>
      {isDebugMode && (
        <View direction='column' marginTop='16'>
          <StyledForm
            onSubmit={e => {
              e.preventDefault()
              addBotMutation.mutate()
            }}
          >
            <SectionTitle>Add bots</SectionTitle>
            <View alignItems='center'>
              <InputPrimitive
                disabled={addBotMutation.isPending}
                value={`${nrOfBots}`}
                type='number'
                onChange={e => {
                  setNrOfBots(Math.min(Math.max(Number.parseInt(e.target.value), 1), 100))
                }}
              />
              <Button
                disabled={addBotMutation.isPending}
                loading={addBotMutation.isPending}
                onClick={() => addBotMutation.mutate()}
              >
                {'Add bot'}
              </Button>
            </View>
          </StyledForm>

          <Section gap='4'>
            <View>
              <SectionTitle>Bots ({bots.data?.length ?? 0})</SectionTitle>
              <CloseAllText color='foreground/secondary' onClick={() => closeAllBotsMutation.mutate()}>
                close all
              </CloseAllText>
            </View>
            {bots.data?.map(bot => <BotItem bot={bot} key={bot.id} />)}
          </Section>
        </View>
      )}
    </>
  )
}

const PerformanceSection = (): JSX.Element => {
  const rrvEma = useAtomValue(RenderRateVolatilityEmaAtom)
  const schedulingEma = useAtomValue(StatsSchedulingTimeEmaAtom)
  const computePressure = useAtomValue(ComputePressureAtom)
  const { renderRateVolatility, statsDurationTime, statsSchedulingTime } = useAtomValue(PerformanceStatsAtom)

  return (
    <Section>
      <SectionTitle>Performance</SectionTitle>
      <StatsText>{`rrvEma: ${rrvEma}`}</StatsText>
      <StatsText>{`schedulingEma: ${schedulingEma}`}</StatsText>
      <StatsText>{`computePressure: ${computePressure}`}</StatsText>
      <StatsText>{`est. pressure: ${getCurrentPressure({
        rrvEma,
        schedulingEma,
        computePressure,
      })}`}</StatsText>
      <StatsText>
        Stats scheduling/duration: {statsSchedulingTime?.toFixed(0)}ms/{statsDurationTime?.toFixed(0)}ms
      </StatsText>
      <StatsText>Render rate volatility: {renderRateVolatility?.toFixed(2)}%</StatsText>
    </Section>
  )
}

const CallStats = (): JSX.Element => {
  const isDebugMode = useIsDebugMode()
  const networkQualityStats = useSelector(selectNetworkQualityStats)
  const videoCallService = useVideoCallService()
  const remoteUsers = useSelector(selectRemoteParticipants)

  const callStats = useAtomValue(CallStatsAtom)
  const { videoStats, audioStats } = useAtomValue(LocalTracksStatsAtom)
  const ablyConnections = useSelector(selectCollabProviderStatuses)
  const callState = useSelector(selectCallState)

  return (
    <>
      {isDebugMode && (
        <>
          <Section>
            <SectionTitle>Call state</SectionTitle>
            <StatsText>Connection: {callState.call.connectionState}</StatsText>
            <StatsText>force proxy: {`${callState.forceCloudProxy}`}</StatsText>
            <StatsText>proxy enabled: {`${callState.call.isUsingProxy}`}</StatsText>
          </Section>
          {networkQualityStats !== undefined && (
            <Section>
              <SectionTitle>Network quality</SectionTitle>
              <StatsText>{`Downlink: ${networkQualityStats.downlink.currentDownlink} (${
                networkQualityDescriptions[networkQualityStats.downlink.currentDownlink]
              })`}</StatsText>
              <StatsText>{`Downlink windowed: ${networkQualityStats.downlink.windowedDownlink} (${
                networkQualityDescriptions[networkQualityStats.downlink.windowedDownlink]
              })`}</StatsText>
              <StatsText>{`Uplink: ${networkQualityStats.uplink.currentUplink} (${
                networkQualityDescriptions[networkQualityStats.uplink.currentUplink]
              })`}</StatsText>
              <StatsText>{`Uplink windowed: ${networkQualityStats.uplink.windowedUplink} (${
                networkQualityDescriptions[networkQualityStats.uplink.windowedUplink]
              })`}</StatsText>
            </Section>
          )}

          {callStats && (
            <Section>
              <SectionTitle>Call stats</SectionTitle>
              <StatsText>{`Duration: ${callStats.Duration} sec`}</StatsText>
              <StatsText>{`Uplink bandwidth: ${formatBitrate(
                callStats.OutgoingAvailableBandwidth * 1000
              )}`}</StatsText>
              <StatsText>{`Round-trip time: ${formatTime(callStats.RTT)}`}</StatsText>
              <StatsText>{`Received bitrate: ${formatBitrate(callStats.RecvBitrate)}`}</StatsText>
              <StatsText>{`Received bytes: ${formatBytes(callStats.RecvBytes)}`}</StatsText>
              <StatsText>{`Sent bitrate: ${formatBitrate(callStats.SendBitrate)}`}</StatsText>
              <StatsText>{`Sent bytes: ${formatBytes(callStats.SendBytes)}`}</StatsText>
              <StatsText>{`User count: ${callStats.UserCount}`}</StatsText>
              <StatsText>{`Received video streams: ${
                remoteUsers.filter(u => u.isSubscribedToVideo).length
              }`}</StatsText>
            </Section>
          )}

          {videoStats && (
            <Section>
              <SectionTitle>Local video</SectionTitle>
              <StatsText>{`FPS captured ${formatFps(videoStats.captureFrameRate)}`}</StatsText>
              <StatsText>{`FPS sent ${formatFps(videoStats.sendFrameRate)}`}</StatsText>
              <StatsText>{`Captured res: ${videoStats.captureResolutionWidth}x${videoStats.captureResolutionHeight}`}</StatsText>
              <StatsText>{`Sent res: ${videoStats.sendResolutionWidth}x${videoStats.sendResolutionHeight}`}</StatsText>
              <StatsText>{`Codec: ${videoStats.codecType}`}</StatsText>
              <StatsText>{`Package loss: ${videoStats.currentPacketLossRate}`}</StatsText>
              <StatsText>{`Encode delay: ${formatTime(videoStats.encodeDelay)}`}</StatsText>
              <StatsText>{`Sent bitrate: ${formatBitrate(videoStats.sendBitrate)}`}</StatsText>
              <StatsText>{`Sent bytes: ${formatBytes(videoStats.sendBytes)}`}</StatsText>
              <StatsText>{`Sent packages: ${videoStats.sendPackets}`}</StatsText>
              <StatsText>{`Sent lost packages: ${videoStats.sendPacketsLost}`}</StatsText>
              <StatsText>{`Target bitrate: ${formatBitrate(videoStats.targetSendBitrate)}`}</StatsText>
              <StatsText>{`Sent duration: ${videoStats.totalDuration} sec`}</StatsText>
              <StatsText>{`Freeze time duration: ${videoStats.totalFreezeTime} sec`}</StatsText>
            </Section>
          )}

          {audioStats && (
            <Section>
              <SectionTitle>Local audio</SectionTitle>
              <StatsText>{`Codec: ${audioStats.codecType}`}</StatsText>
              <StatsText>{`Package loss: ${audioStats.currentPacketLossRate}`}</StatsText>
              <StatsText>{`Sent bitrate: ${formatBitrate(audioStats.sendBitrate)}`}</StatsText>
              <StatsText>{`Sent bytes: ${formatBytes(audioStats.sendBytes)}`}</StatsText>
              <StatsText>{`Sent packages: ${audioStats.sendPackets}`}</StatsText>
              <StatsText>{`Sent lost packages: ${audioStats.sendPacketsLost}`}</StatsText>
              <StatsText>{`Sent volume: ${audioStats.sendVolumeLevel}`}</StatsText>
            </Section>
          )}

          <PerformanceSection />

          <Section>
            <SectionTitle>Debug actions</SectionTitle>
            <Button
              onClick={async () => {
                await videoCallService?.leaveCall()
              }}
            >
              Leave Agora call
            </Button>

            <Button
              onClick={async () => {
                ;((await videoCallService?.getWrappedClient()) as any)?._leave()
              }}
            >
              Disconnect (warning)
            </Button>
          </Section>

          <Section>
            <SectionTitle>Ably</SectionTitle>
            {ablyConnections.map(connection => {
              const client = AblyDebug.getAllClients().find(c => c.clientId === connection.clientId)
              if (!client) return null

              const clientStatus = client.client.connection.state === 'connected' ? 'online' : 'offline'

              return (
                <React.Fragment key={connection.clientId}>
                  <StatsText>
                    {connection.clientId}: {connection.channels} {connection.status}
                  </StatsText>
                  <Button
                    onClick={() => {
                      if (clientStatus === 'online') {
                        client.client.close()
                      } else {
                        client.client.connect()
                      }
                    }}
                  >
                    {clientStatus === 'online' ? 'Disconnect' : 'Connect'}
                  </Button>
                </React.Fragment>
              )
            })}
          </Section>
        </>
      )}
    </>
  )
}

const TabItems = [
  {
    id: 'call-stats',
    label: 'Call stats',
    content: <CallStats />,
  },
  {
    id: 'bot-control',
    label: 'Bot control',
    content: <BotControlls />,
  },
  {
    id: 'issues',
    label: 'Issues',
    content: <IssueControl />,
  },
]

export const DebugView = (): JSX.Element => {
  const [tab, setTab] = React.useState('call-stats')
  return (
    <Wrapper>
      <Tabs items={TabItems} value={tab} onChange={newValue => setTab(newValue)} />
    </Wrapper>
  )
}
