import { ScrollView, View } from 'sierra-ui/primitives'
import { token } from 'sierra-ui/theming'
import styled from 'styled-components'

const PADDING_VALUE = 40
const PADDING_DEFAULT = `${PADDING_VALUE}px`
const SCROLLBAR_WIDTH = 14

export const PanelBody = styled(ScrollView).attrs({
  grow: true,
})`
  padding-inline: ${PADDING_DEFAULT} ${PADDING_VALUE - SCROLLBAR_WIDTH}px;
  padding-block: ${PADDING_DEFAULT} 16px;
`

export const PanelFooter = styled.footer`
  position: relative;
  padding: 16px ${PADDING_DEFAULT};

  &::before {
    content: '';
    position: absolute;
    top: 0;
    left: ${PADDING_DEFAULT};
    right: ${PADDING_DEFAULT};
    border-top: 1px solid ${token('border/default')};
  }
`

export const PanelGroupButton = styled(View).attrs({
  justifyContent: 'flex-end',
  gap: '8',
})``
