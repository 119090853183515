/* Resets the self-paced slice when the enclosing component is unmounted */
import { useEffect } from 'react'
import { useDispatch } from 'sierra-client/state/hooks'
import { resetSelfPacedSlice } from 'sierra-client/state/self-paced/actions'

export const useResetSelfPacedSlice = (): void => {
  const dispatch = useDispatch()

  useEffect(
    () => () => {
      dispatch(resetSelfPacedSlice())
    },
    [dispatch]
  )
}
