import { Label } from 'sierra-ui/components'
import styled from 'styled-components'

export const OverdueLabel = styled(Label).attrs({
  $size: 'large',
  $color: 'white',
})`
  padding: 4px 8px;
  border-radius: 10px;
  height: 21px;
  border: none;

  font-family: 'Sana Sans', sans-serif;
  font-weight: 500;
  font-size: 10px;
  line-height: 15px;

  background-color: ${p => p.theme.color[p.$bgColor]};
  color: ${p => p.theme.color[p.$color]};
`
