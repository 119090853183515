import React, { useContext } from 'react'
import { editorGrid } from 'sierra-client/editor/layout'
import { useTranslation } from 'sierra-client/hooks/use-translation'
import { SizeCss } from 'sierra-client/views/author/components/block-editor/v2/blocks/image'
import { Toolbar, ToolbarIcon } from 'sierra-client/views/v3-author/block-toolbar'
import { removeNodeWithId } from 'sierra-client/views/v3-author/command'
import { assertElementType } from 'sierra-client/views/v3-author/queries'
import {
  QuestionSkeletonInner,
  SkeletonQuestionAlternative,
  SkeletonQuestionBody,
  StyledSkeletonBlockWrapper,
} from 'sierra-client/views/v3-author/question-card'
import { RenderingContext, useRenderingContext } from 'sierra-client/views/v3-author/rendering-context'

import { SlateFC, SlateWrapperProps } from 'sierra-client/views/v3-author/slate'
import { BaseWrapper, BlockWrapper } from 'sierra-client/views/v3-author/wrapper'
import { assertNever } from 'sierra-domain/utils'
import { Embed } from 'sierra-domain/v3-author'
import { color } from 'sierra-ui/color'
import { LoadingSpinner, Skeleton, Text, View } from 'sierra-ui/primitives'
import { palette, spacing } from 'sierra-ui/theming'
import { useFocused, useSelected, useSlateStatic } from 'slate-react'
import styled, { DefaultTheme, ThemeContext, css } from 'styled-components'

const Wrapper = styled.div<{ themeContext: DefaultTheme }>`
  width: 100%;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  user-select: none;
  cursor: pointer;
  padding: ${spacing['80']};
  background-color: ${palette.primitives.white};
  border-radius: 0.5rem;
`

const ImagePlaceholderWrapper = styled.div<{
  $withGrid: boolean
  variant: Embed['variant']
  themeContext: DefaultTheme
}>`
  user-select: none;
  background-color: ${p => color(p.theme.home.textColor).opacity(0.05).toString()};
  border-radius: 0.5rem;
  ${SizeCss}
`

const SquareBox = styled.div`
  position: relative;
  width: 100%;
  padding-top: 100%;
`

const StyledImageWrapper = styled(BaseWrapper)`
  padding: 0;
`

const PlaceholderContainer = styled(View)`
  position: absolute;
  top: 0;
  bottom: 0;
  right: 0;
  left: 0;
`

const ParagraphInner = styled(Text)`
  position: relative;
  color: ${p => p.theme.home.textColor};

  & span[data-slate-placeholder='true'] {
    white-space: nowrap;
  }

  height: 24px;

  ${editorGrid}
`

export const PlaceholderWrapper = React.forwardRef<HTMLDivElement, SlateWrapperProps>(
  ({ element, readOnly, children, ...rest }, ref) => {
    assertElementType('placeholder', element)
    const selected = useSelected()
    const focused = useFocused()

    if (element.blockType === 'question-card') {
      return (
        <StyledSkeletonBlockWrapper $selected={selected && focused} ref={ref} {...rest}>
          {children}
          <RenderingContext withGrid={false}>
            <QuestionSkeletonInner contentEditable={false} />
          </RenderingContext>
        </StyledSkeletonBlockWrapper>
      )
    }
    return (
      <BlockWrapper element={element} readOnly={true} ref={ref} {...rest}>
        {children}
      </BlockWrapper>
    )
  }
)

const LoadingSpinnerSelected = styled.div<{ $selected: boolean }>`
  ${p =>
    p.$selected &&
    css`
      border-radius: 10px;
      box-shadow: 0 0 0 3px #b4d5ff;
    `}
`
export const Placeholder: SlateFC = ({ readOnly, children, element }) => {
  const { t } = useTranslation()
  const editor = useSlateStatic()
  assertElementType('placeholder', element)
  const themeContext = useContext(ThemeContext)

  const { withGrid } = useRenderingContext()

  const focused = useFocused()
  const selected = useSelected()

  if (readOnly) return null

  switch (element.blockType) {
    case 'question-card':
      return (
        <>
          {children}
          <ParagraphInner contentEditable={false}>
            <Skeleton $width={350} $radius={8} />
          </ParagraphInner>
          <SkeletonQuestionBody>
            <SkeletonQuestionAlternative />
            <SkeletonQuestionAlternative />
            <SkeletonQuestionAlternative />
            <SkeletonQuestionAlternative />
          </SkeletonQuestionBody>
          <Toolbar elementId={element.id}>
            <ToolbarIcon
              tooltip={t('author.block-editor.remove')}
              iconId='trash-can'
              onClick={() => removeNodeWithId(editor, element.id)}
            />
          </Toolbar>
        </>
      )
    case 'image':
      return (
        <>
          <StyledImageWrapper>
            <ImagePlaceholderWrapper
              $withGrid={withGrid}
              variant={'narrow'}
              contentEditable={false}
              themeContext={themeContext}
            >
              <SquareBox>
                <PlaceholderContainer grow justifyContent='center' direction='column' alignItems='center'>
                  <LoadingSpinner size='medium' padding={'xxsmall'} />
                </PlaceholderContainer>
              </SquareBox>
            </ImagePlaceholderWrapper>
            {children}
          </StyledImageWrapper>
          <Toolbar elementId={element.id}>
            <ToolbarIcon
              tooltip={t('author.block-editor.remove')}
              iconId='trash-can'
              onClick={() => removeNodeWithId(editor, element.id)}
            />
          </Toolbar>
        </>
      )
    case 'page': // note: blockType: 'page' is not used anymore
    case undefined:
      return (
        <>
          {children}
          <Wrapper contentEditable={false} themeContext={themeContext}>
            <View grow justifyContent='center' direction='column' alignItems='center'>
              <LoadingSpinnerSelected $selected={selected && focused}>
                <LoadingSpinner size='medium' padding={'xxsmall'} />
              </LoadingSpinnerSelected>
            </View>
          </Wrapper>

          <Toolbar elementId={element.id}>
            <ToolbarIcon
              tooltip={t('author.block-editor.remove')}
              iconId='trash-can'
              onClick={() => removeNodeWithId(editor, element.id)}
            />
          </Toolbar>
        </>
      )
    default:
      assertNever(element.blockType)
  }
}
