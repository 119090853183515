import { Logging } from 'sierra-client/core/logging'
import { BulkEditPayload } from 'sierra-client/core/logging/certificates/logger'
import { useDispatch } from 'sierra-client/state/hooks'

type CertificateTracking = {
  created: () => void
  deleted: (id: string) => void
  edited: (id: string) => void
  myList: {
    click: (id: string) => void
  }
  linkedin: {
    share: (id: string) => void
  }
  download: {
    click: (id: string) => void
  }
  issueTo: {
    click: (id: string, n: number) => void
    addGroup: (certificateId: string, groupId: string, numberOfMembers: number) => void
  }
  bulkEdit: {
    edit: (d: BulkEditPayload) => void
  }
}
export const useTracking = (): CertificateTracking => {
  const dispatch = useDispatch()

  return {
    created: () => dispatch(Logging.certificates.created()),
    deleted: id => dispatch(Logging.certificates.deleted({ certificateId: id })),
    edited: id => dispatch(Logging.certificates.edited({ certificateId: id })),
    myList: {
      click: id => dispatch(Logging.certificates.myListClicked({ certificateId: id })),
    },
    linkedin: {
      share: id =>
        dispatch(
          Logging.certificates.linkedInShareClicked({
            certificateId: id,
          })
        ),
    },
    download: {
      click: id =>
        dispatch(
          Logging.certificates.downloadClicked({
            certificateId: id,
          })
        ),
    },
    issueTo: {
      click: (id, n) =>
        dispatch(
          Logging.certificates.issueToClicked({
            certificateId: id,
            nIssuedTo: n,
          })
        ),
      addGroup: (certificateId, groupId, numberOfMembers) =>
        dispatch(
          Logging.certificates.addGroupToIssuing({
            certificateId,
            groupId,
            numberOfMembers,
          })
        ),
    },
    bulkEdit: {
      edit: d => dispatch(Logging.certificates.bulkEditEdit(d)),
    },
  }
}
