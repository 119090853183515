import { useSelector } from 'sierra-client/state/hooks'
import {
  selectIsDisabled,
  selectIsLoggedIn,
  selectIsPendingEmailVerification,
  selectIsRegistrationActive,
} from 'sierra-client/state/user/user-selector'

export function useIsFurtherAuthenticationRequired(): boolean {
  const isLoggedIn = useSelector(selectIsLoggedIn)
  const isRegistrationActive = useSelector(selectIsRegistrationActive)
  const isPendingEmailVerification = useSelector(selectIsPendingEmailVerification)
  const isDisabled = useSelector(selectIsDisabled)

  return !isLoggedIn || isRegistrationActive || isPendingEmailVerification || isDisabled
}
