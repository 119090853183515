import { useLocation, type ParsedLocation } from '@tanstack/react-router'
import { type usePathname } from 'sierra-client/hooks/router/use-pathname' // eslint-disable-line @typescript-eslint/no-unused-vars -- used by tsdoc
import { type GlobalRouter } from 'sierra-client/router'

/**
 * Optimized hook for getting the current href, aka the full pathname including search strings and hashes.
 *
 * Note: to only get the pathname, see {@link usePathname} or use {@link useLocation} directly.
 *
 * @example
 * ```ts
 * // On full URL: https://example.com/some/path?query=string#hash
 * const href = useHref()
 * // Result: '/some/path?query=string#hash'
 * ```
 */
export function useHref(): string {
  return useLocation<GlobalRouter, ParsedLocation, string>({ select: location => location.href })
}
