import React, { useState } from 'react'
import { getCourseDefaultImage } from 'sierra-client/api/content'
import { useTranslation } from 'sierra-client/hooks/use-translation'
import { Template } from 'sierra-client/state/templates/types'
import { assertNever, iife } from 'sierra-domain/utils'
import { Label } from 'sierra-ui/components'
import { Button, Spacer, Text, View } from 'sierra-ui/primitives'
import styled, { css } from 'styled-components'

const hoverAnimation = css`
  transform: scale(1.03);
  transition: transform 0.3s ease;
`

const ImageFrame = styled.div<{ $borderOnHover?: boolean }>`
  position: relative;
  aspect-ratio: 21 / 16;
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  overflow: hidden;
  border-radius: 20px;
  transform: translateZ(0);

  border: ${p => (p.$borderOnHover === true ? '1px solid rgba(0,0,0,0.05)' : 'none')};

  &:hover {
    border: ${p => (p.$borderOnHover === true ? '1px solid rgba(0,0,0,0.15)' : 'none')};
  }
`

const UseButton = styled(Button)`
  background-color: white;
  color: black;
  opacity: 90%;
  border: none;

  &:hover {
    background-color: white;
    color: black;
    opacity: 100%;
  }

  transition: opacity 0.2s;
`

const StyledImage = styled.img<{ $animate?: boolean }>`
  aspect-ratio: 21 / 16;
  width: 100%;
  border-radius: 20px;
  object-fit: cover;

  ${p => p.$animate === true && hoverAnimation}
`

const RootView = styled(View)`
  &:hover ${StyledImage} {
    transform: scale(1);
  }
`

const Description = styled(Text)`
  overflow: hidden;
  display: -webkit-box;
  -webkit-line-clamp: 2;
  -webkit-box-orient: vertical;
`

const ButtonWrapper = styled.div`
  position: absolute;
  bottom: 1rem;
  right: 1rem;
`

type TemplateMiniatureProps = {
  template: Template
  onClick: () => void
  onButtonClick: () => void
}

export const TemplateMiniature: React.FC<TemplateMiniatureProps> = ({ template, onClick, onButtonClick }) => {
  const { t } = useTranslation()
  const [hover, setHover] = useState(false)

  return (
    <RootView
      onMouseEnter={() => setHover(true)}
      onMouseLeave={() => setHover(false)}
      onClick={onClick}
      cursor='pointer'
      direction='column'
      alignItems='flex-start'
      position='relative'
    >
      <ImageFrame $borderOnHover={template.disableAnimation === true || template.imagePath === undefined}>
        <StyledImage
          $animate={template.disableAnimation !== true && template.imagePath !== undefined}
          src={template.imagePath ?? getCourseDefaultImage({ width: 640 })}
        />
        {hover && (
          <ButtonWrapper>
            <UseButton
              onClick={e => {
                e.stopPropagation()
                onButtonClick()
              }}
            >
              {t('dictionary.use')}
            </UseButton>
          </ButtonWrapper>
        )}
      </ImageFrame>
      <div>
        <View gap='4' alignItems='flex-start'>
          <Text size='regular' bold>
            {template.label}
          </Text>
          {iife(() => {
            switch (template.contentType) {
              case 'native:live':
                return (
                  <Label
                    style={{ lineHeight: 1.4 }}
                    $size='small'
                    $bgColor='transparent'
                    $color='redVivid'
                    iconId='play--circle--filled'
                  >
                    {t('dictionary.live')}
                  </Label>
                )
              case 'native:self-paced':
                return (
                  <Label
                    style={{ lineHeight: 1.4 }}
                    $size='small'
                    $bgColor='transparent'
                    $color='grey25'
                    iconId='bookmark--filled'
                  >
                    {t('dictionary.course-singular')}
                  </Label>
                )
              default:
                assertNever(template.contentType)
            }
          })}
        </View>

        {template.author !== undefined && (
          <>
            <Description color='grey40' size='small'>
              {t('template-picker.by-author') + ' '}
              <Text color='grey40' size='small' bold as='span'>
                {template.author}
              </Text>
            </Description>
            <Spacer size='xxsmall' />
          </>
        )}

        {template.description !== undefined && (
          <Description color='grey40' size='small'>
            {template.description}
          </Description>
        )}
      </div>
    </RootView>
  )
}
