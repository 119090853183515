import { createSlice } from '@reduxjs/toolkit'
import {
  getAssessmentQuestionCardResponses,
  getQuestionCardResponse,
  resetQuestionCard,
  resetSelfPacedSlice,
  submitQuestionCardResponse,
} from 'sierra-client/state/self-paced/actions'
import { SelfPacedState } from 'sierra-client/state/self-paced/types'

const initialState: SelfPacedState = {
  questionCards: {},
}

export const selfPacedSlice = createSlice({
  name: 'selfPaced',
  initialState,
  reducers: {},
  extraReducers: builder => {
    builder.addCase(resetSelfPacedSlice, state => {
      state.questionCards = {}
    })
    builder.addCase(submitQuestionCardResponse.fulfilled, (state, { payload }) => {
      const { questionCard, questionExerciseId } = payload

      state.questionCards[questionExerciseId] = questionCard
    })

    builder.addCase(getQuestionCardResponse.fulfilled, (state, { payload }) => {
      const { questionCard, questionExerciseId } = payload

      if (questionCard !== undefined) {
        state.questionCards[questionExerciseId] = questionCard
      }
    })
    builder.addCase(getAssessmentQuestionCardResponses.fulfilled, (state, { payload }) => {
      const { questionCards, questionExerciseIds } = payload
      if (questionCards.length > 0) {
        questionExerciseIds.forEach(
          questionExerciseId =>
            (state.questionCards[questionExerciseId] = questionCards.find(
              it => it.responses[questionExerciseId]
            ))
        )
      }
    })
    builder.addCase(resetQuestionCard, (state, { payload }) => {
      const { questionExerciseId } = payload
      state.questionCards[questionExerciseId] = undefined
    })
  },
})
