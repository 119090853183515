import { QueryKey } from '@tanstack/react-query'
import { typedPost } from 'sierra-client/state/api'
import { CreateContentId, LiveSessionId } from 'sierra-domain/api/nano-id'
import { ZodRouteOutput } from 'sierra-domain/api/types'
import { XRealtimeStrategyContentDataQuestionCardGetQuestionCardSummary } from 'sierra-domain/routes'
import { assertIsNonNullable } from 'sierra-domain/utils'

type SummaryResponse = ZodRouteOutput<
  typeof XRealtimeStrategyContentDataQuestionCardGetQuestionCardSummary
>['result'][string]
export class QuestionResponsesDataLoader {
  constructor(
    private contentId: CreateContentId,
    private liveSessionId: LiveSessionId | undefined
  ) {}

  resolvers: Map<
    string,
    {
      promise: Promise<SummaryResponse>
      resolve: (value: SummaryResponse) => void
      reject: (error: unknown) => void
    }
  > = new Map()

  getQueryKey(questionId: string): QueryKey {
    return [
      XRealtimeStrategyContentDataQuestionCardGetQuestionCardSummary.path,
      {
        contentId: this.contentId,
        liveSessionId: this.liveSessionId,
        questionId,
      },
    ]
  }

  async load(questionId: string): Promise<SummaryResponse> {
    setTimeout(() => {
      void this.process()
    }, 10)

    const alreadyBatched = this.resolvers.get(questionId)
    if (alreadyBatched) {
      return alreadyBatched.promise
    }

    let res: ((value: SummaryResponse) => void) | undefined = undefined
    let rej: ((error: unknown) => void) | undefined = undefined

    const resultPromise = new Promise<SummaryResponse>((_res, _rej) => {
      res = _res
      rej = _rej
    })
    assertIsNonNullable(res)
    assertIsNonNullable(rej)
    this.resolvers.set(questionId, { resolve: res, reject: rej, promise: resultPromise })

    return resultPromise
  }

  private async process(): Promise<void> {
    const resolvers = this.resolvers
    this.resolvers = new Map()

    const questionIds = Array.from(resolvers.keys())
    if (questionIds.length === 0) {
      return
    }

    try {
      const queryRes = await typedPost(XRealtimeStrategyContentDataQuestionCardGetQuestionCardSummary, {
        contentId: this.contentId,
        liveSessionId: this.liveSessionId,
        questionIds,
      })

      for (const questionId of questionIds) {
        const questionIdResult = queryRes.result[questionId]
        if (questionIdResult !== undefined) {
          resolvers.get(questionId)?.resolve(questionIdResult)
        } else {
          resolvers.get(questionId)?.reject(new Error(`Question not found: ${questionId}`))
        }
      }
    } catch (e) {
      for (const questionId of questionIds) {
        resolvers.get(questionId)?.reject(e)
      }
    }
  }
}
