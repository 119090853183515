import { Logger } from 'sierra-client/core/logging/logger'

type AvatarLoggingProps = {
  avatarDestinationType: 'card-narration' | 'video-card' | 'video-block'
}
export const generateVideoCardAvatarModalOpened = Logger.trackLoggerWithExtra<
  AvatarLoggingProps,
  AvatarLoggingProps
>(
  'generated_video_card_avatar_modal_clicked',

  input => {
    return {
      ...input,
    }
  }
)

export const generateVideoCardAvatarScriptGenerated = Logger.trackLoggerWithExtra<
  AvatarLoggingProps,
  AvatarLoggingProps
>(
  'generated_video_card_avatar_script_generated',

  input => {
    return {
      ...input,
    }
  }
)

export const generateVideoCardAvatarAvatarGenerated = Logger.trackLoggerWithExtra<
  AvatarLoggingProps,
  AvatarLoggingProps
>(
  'generated_video_card_avatar_avatar_generated',

  input => {
    return {
      ...input,
    }
  }
)
