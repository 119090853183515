import React from 'react'
import { useQueryAnalyticsData } from 'sierra-client/features/insights/api-hooks/use-query-analytics-data'
import {
  DataViewerActionProps,
  WidgetMapper,
} from 'sierra-client/features/insights/display-widgets/data-viewers/widget-mapper'
import { useAllFilterDomainReps } from 'sierra-client/features/insights/hooks/use-insights-views'
import { Widget } from 'sierra-domain/api/insights'
import { LoadingSpinner, View } from 'sierra-ui/primitives'

export const DataViewer: React.FC<
  {
    widget: Widget
    previewMode?: boolean
    showToolbar?: boolean
  } & DataViewerActionProps
> = ({ widget, previewMode = false, showToolbar = true, onDrillDown, onFilterOut, onSort, onSetLimit }) => {
  const allFilterDomainReps = useAllFilterDomainReps()

  const queryResponse = useQueryAnalyticsData({ widget, allFilterDomainReps })

  if (queryResponse.isPending) {
    return (
      <View direction='column' alignItems='center' justifyContent='center' grow>
        <LoadingSpinner />
      </View>
    )
  }

  if (queryResponse.isError) {
    throw new Error('Fetching failed', { cause: queryResponse.error.message })
  }

  const queryResult = queryResponse.data.result

  switch (queryResult.type) {
    case 'result.table':
      return (
        <WidgetMapper
          widget={widget}
          tableResult={queryResult}
          previewMode={previewMode}
          showToolbar={showToolbar}
          onDrillDown={onDrillDown}
          onFilterOut={onFilterOut}
          onSort={onSort}
          onSetLimit={onSetLimit}
        />
      )
  }
}
