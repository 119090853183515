import { keepPreviousData } from '@tanstack/react-query'
import React, { useCallback, useState } from 'react'
import { graphql } from 'sierra-client/api/graphql/gql'
import {
  FilesByIdsQuery,
  FilesQuery,
  FileType as GraphQLFileType,
} from 'sierra-client/api/graphql/gql/graphql'
import { convertGQLImage } from 'sierra-client/api/graphql/util/convert-gql-image'
import { useGraphQuery } from 'sierra-client/api/hooks/use-graphql-query'
import { useTranslation } from 'sierra-client/hooks/use-translation'
import { TranslationKey } from 'sierra-client/hooks/use-translation/types'
import { Context } from 'sierra-client/lib/filter/components/common'
import {
  ValueAnchor,
  addPredValue,
  fromPredicate,
  removePredValue,
} from 'sierra-client/lib/filter/components/predicate-utils'
import { createFileFromType } from 'sierra-client/lib/tabular/utils/create-file-from-type'
import { FileIcon, FileIconProps } from 'sierra-client/views/flexible-content/editor/content-sidebar/icons'
import { NanoId12 } from 'sierra-domain/api/nano-id'
import { AssetContext } from 'sierra-domain/asset-context'
import { AutoCompleteEntity } from 'sierra-domain/filter/datatype/domain'
import { Predicate } from 'sierra-domain/filter/datatype/pred'
import { ValueFileId } from 'sierra-domain/filter/datatype/value'
import { setPred } from 'sierra-domain/filter/operations'
import { FileType } from 'sierra-domain/flexible-content/types'
import { assertNever, isDefined, isNotNull } from 'sierra-domain/utils'
import { Autocomplete, DefaultStyledAutocompleteOption, FormElement } from 'sierra-ui/components'
import { ListContainer, SelectedPill } from 'sierra-ui/components/autocomplete/reference-implementation/atoms'
import { IconButton, Text } from 'sierra-ui/primitives'

type AutoCompleteCard = {
  id: NanoId12
  fileType: string
  title?: string
  image?: ReturnType<typeof convertGQLImage>
  value: ValueFileId
}

const emptyArray = [] as const satisfies AutoCompleteCard[]

const filesToAutoCompleteCards = (
  files: FilesByIdsQuery['filesWithIds'] | FilesQuery['files']
): AutoCompleteCard[] => {
  const cards = files.map(file => {
    const id = NanoId12.parse(file.id)

    const card = {
      id,
      title: isNotNull(file.title) ? file.title : undefined,
      fileType: file.fileType,
      image: convertGQLImage(file.image),
      value: { type: 'value.file-id', fileId: id, courseId: file.courseId },
    } satisfies AutoCompleteCard

    return card
  })

  return cards
}

const filesByIdsQuery = graphql(`
  query FilesByIds($ids: [CourseFileIdInput!]!) {
    filesWithIds(ids: $ids) {
      id
      title
      courseId
      fileType
      image {
        ...ImageFragment
      }
    }
  }
`)

const useCardsFromPredicate = (predicate: Predicate): AutoCompleteCard[] => {
  const cardIds = fromPredicate(predicate)
    // TODO: Using the NanoId12 type for 'value.nanoid12' caused type errors in the recursive Filter type.
    //  Seems like a bug in zod maybe? For now we'll cast the value here.
    .map(value =>
      value.type === 'value.file-id'
        ? { courseId: NanoId12.parse(value.courseId), fileId: NanoId12.parse(value.fileId) }
        : undefined
    )
    .filter(isDefined)

  const response = useGraphQuery(
    {
      document: filesByIdsQuery,
      queryOptions: {
        enabled: cardIds.length !== 0,
        staleTime: 60 * 1000,
        placeholderData: keepPreviousData,
      },
    },
    { ids: cardIds }
  )

  if (cardIds.length === 0) {
    return emptyArray
  }

  if (response.data === undefined) {
    return emptyArray
  }

  const cards = filesToAutoCompleteCards(response.data.filesWithIds)
  return cards
}

const filesByQuery = graphql(`
  query Files($query: String!, $fileType: FileType) {
    files(query: $query, fileType: $fileType) {
      id
      courseId
      title
      fileType
      image {
        ...ImageFragment
      }
    }
  }
`)

type SupportedEntity = Extract<AutoCompleteEntity, 'entity.file' | 'entity.assessment' | 'entity.homework'>

const getGraphQlFileType = (entity: SupportedEntity): GraphQLFileType | undefined => {
  switch (entity) {
    case 'entity.assessment':
      return 'ASSESSMENT_CARD'
    case 'entity.homework':
      return 'HOMEWORK'
    case 'entity.file':
      return undefined
    default:
      assertNever(entity)
  }
}

const useCardsFromQuery = (query: string, entity: SupportedEntity): AutoCompleteCard[] => {
  const fileType = getGraphQlFileType(entity)
  const response = useGraphQuery(
    { document: filesByQuery, queryOptions: { staleTime: 60 * 1000, placeholderData: keepPreviousData } },
    { query, fileType }
  )

  if (response.data === undefined) {
    return emptyArray
  }

  const cards = filesToAutoCompleteCards(response.data.files)
  return cards
}

const CardIcon: React.FC<{ card: AutoCompleteCard; assetContext: AssetContext }> = ({
  card,
  assetContext,
}) => {
  const fileType = FileType.parse(card.fileType)
  const baseFileData = createFileFromType(fileType)
  const file = { ...baseFileData, backgroundImage: card.image } satisfies FileIconProps

  return <FileIcon file={file} assetContext={assetContext} />
}

const getFormLabel = (entity: SupportedEntity): TranslationKey => {
  switch (entity) {
    case 'entity.assessment':
      return 'dictionary.assessment-plural'
    case 'entity.homework':
      return 'dictionary.homework-plural'
    case 'entity.file':
      return 'dictionary.card-plural'
    default:
      assertNever(entity)
  }
}

type CardAutoCompleteProps = {
  ctx: Context
  predicate: Predicate
  entity: SupportedEntity
  assetContext: AssetContext
}

export const CardAutoComplete: React.FC<CardAutoCompleteProps> = ({
  ctx,
  predicate,
  entity,
  assetContext,
}) => {
  const { t } = useTranslation()

  const selectedCards = useCardsFromPredicate(predicate)

  const [query, setQuery] = useState('')
  const matchingCards = useCardsFromQuery(query, entity)

  const onSelect = useCallback(
    (content: AutoCompleteCard) => {
      ctx.update(f => setPred(f, addPredValue(predicate, content.value)))
    },
    [ctx, predicate]
  )

  const onUnselect = useCallback(
    (content: AutoCompleteCard) => {
      ctx.update(f => setPred(f, removePredValue(predicate, content.value)))
    },
    [ctx, predicate]
  )

  return (
    <FormElement label={t(getFormLabel(entity))}>
      <Autocomplete
        placeholder={t('menu.search.placeholder')}
        autofocus
        query={query}
        onQueryChange={setQuery}
        selectedItems={selectedCards}
        matchingItems={matchingCards}
        onSelect={onSelect}
        onUnselect={onUnselect}
        renderSelectedItem={(card, { onUnselect, ...props }) => {
          return (
            <SelectedPill key={card.id} {...props}>
              <CardIcon card={card} assetContext={assetContext} />
              <Text color='foreground/primary' bold>
                {card.title}
              </Text>

              <IconButton iconId='close' onClick={onUnselect} size='small' variant='transparent' />
            </SelectedPill>
          )
        }}
        renderMatchingItemList={({ getItemProps }) => (
          <ListContainer>
            {matchingCards.map((card, index) => {
              return (
                <DefaultStyledAutocompleteOption key={card.id} {...getItemProps(card, index)}>
                  <CardIcon card={card} assetContext={assetContext} />
                  <Text color='foreground/primary'>{card.title}</Text>
                </DefaultStyledAutocompleteOption>
              )
            })}
          </ListContainer>
        )}
      />
    </FormElement>
  )
}

export const CardAutoCompleteLabel = React.forwardRef<HTMLDivElement, { predicate: Predicate }>(
  ({ predicate, ...rest }, ref) => {
    const selectedCards = useCardsFromPredicate(predicate)
    const labels = selectedCards.map(card => card.title)
    const label = labels.join(', ')

    return <ValueAnchor {...rest} ref={ref} label={label} />
  }
)
