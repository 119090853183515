import { useEffect } from 'react'
import { useDebouncedState } from 'sierra-client/hooks/use-debounced-state'
import { usePost } from 'sierra-client/hooks/use-post'
import { CourseSummary } from 'sierra-domain/api/admin'
import { XRealtimeAdminCoursesListCourses } from 'sierra-domain/routes'

export function useCourseSearch(): CourseSummary[] {
  const { postWithUserErrorException } = usePost()

  const [results, setResults] = useDebouncedState<CourseSummary[]>([], {
    maxWait: 200,
  })

  useEffect(() => {
    void postWithUserErrorException(XRealtimeAdminCoursesListCourses, {}).then(res => {
      setResults(
        res.courses
          .filter(c => c.kind !== 'native:live' && c.title !== '')
          .sort((a, b) => a.title.localeCompare(b.title))
      )
    })
  }, [postWithUserErrorException, setResults])

  return results
}
