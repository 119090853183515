import { useAtom } from 'jotai'
import _ from 'lodash'
import { useRef, type FormEventHandler } from 'react'
import { SelectedSkillsAtom } from 'sierra-client/features/skills/components/select-skills/atoms'
import { SkillPill } from 'sierra-client/features/skills/components/select-skills/skill-pill'
import {
  SelectSkillSection,
  selectSkillSectionPaddingInline,
} from 'sierra-client/features/skills/components/select-skills/styles'
import type {
  SelectSkillEntity,
  SelectSkillsFormVariant,
} from 'sierra-client/features/skills/components/select-skills/types'
import { useTranslation } from 'sierra-client/hooks/use-translation'
import { ListVirtualizer } from 'sierra-client/views/workspace/components/list-virtualizer'
import { Icon, Tooltip, TruncatedText } from 'sierra-ui/components'
import { Button, Checkbox, ScrollView, View } from 'sierra-ui/primitives'
import { token } from 'sierra-ui/theming'
import { minWidth } from 'sierra-ui/utils/media-query-styles'
import styled from 'styled-components'

const Form = styled.form`
  flex: 1;
  display: flex;
  flex-direction: column;
  gap: 24px;
  overflow: hidden;
`

const RowContainer = styled.label`
  display: flex;
  flex-grow: 1;
  justify-content: space-between;
  align-items: center;
  margin-inline: ${selectSkillSectionPaddingInline}px ${selectSkillSectionPaddingInline - 12}px;
  margin-block: 0 16px;
  cursor: pointer;
`

const ContentWrapper = styled(View).attrs({ justifyContent: 'space-between' })`
  width: 100%;
  padding: 24px;
  border-radius: 16px;
  background: ${token('surface/default')};
  border: 1px solid ${token('form/border/1')};
  transition: border-color 0.1s cubic-bezier(0.25, 0.1, 0.25, 1);

  &:hover {
    border-color: ${token('form/border/2')};
  }
`

const SkillMetadataWrapper = styled.div`
  display: grid;
  gap: 4px;

  ${minWidth.desktop_small} {
    grid-template-columns: 200px 1fr;
    gap: 42px;
  }
`

const SkillRow: React.FC<{
  selected: boolean
  skill: SelectSkillEntity
  variant: SelectSkillsFormVariant
  onSelectedChange: (selected: boolean) => void
}> = ({ selected, skill, onSelectedChange, variant }) => {
  const { t } = useTranslation()

  return (
    <RowContainer>
      <ContentWrapper>
        <SkillMetadataWrapper>
          <SkillPill
            skillId={skill.id}
            defaultBadgeIcon={skill.defaultBadgeIcon}
            title={skill.name}
            subtitle={t('skills.subscribers', { count: skill.uniqueSubscribersCount ?? 0 })}
            variant={variant}
          />

          <TruncatedText lines={3} size='small' color='foreground/secondary'>
            {skill.description}
          </TruncatedText>
        </SkillMetadataWrapper>

        <View marginLeft='8'>
          {skill.isAssignedToUser ? (
            <Tooltip title={t('skills.modal.select-skills.assigned-to-you')}>
              <Icon iconId='locked' size='size-16' color='foreground/secondary' />
            </Tooltip>
          ) : (
            <Checkbox
              disabled={skill.isAssignedToUser}
              checked={selected}
              onCheckedChange={checked => {
                if (checked !== 'indeterminate') {
                  onSelectedChange(checked)
                }
              }}
            />
          )}
        </View>
      </ContentWrapper>
    </RowContainer>
  )
}

export const SelectSkillsForm: React.FC<{
  variant: SelectSkillsFormVariant
  skills: SelectSkillEntity[]
  onCancel: () => void
  onSubmit: (skills: SelectSkillEntity[]) => void
  loading?: boolean
}> = ({ variant, skills, onSubmit, onCancel, loading }) => {
  const { t } = useTranslation()
  const nodeRef = useRef<HTMLDivElement | null>(null)

  const [selectedSkills, setSelectedSkills] = useAtom(SelectedSkillsAtom)

  const handleSubmit: FormEventHandler<HTMLFormElement> = event => {
    event.preventDefault()
    if (selectedSkills.status === 'ready') {
      onSubmit(selectedSkills.skills)
    }
  }

  return (
    <Form onSubmit={handleSubmit}>
      <ScrollView ref={nodeRef} grow>
        <ListVirtualizer
          scrollElement={nodeRef.current}
          items={skills}
          estimateSize={90}
          renderItem={item => {
            return (
              <SkillRow
                skill={item}
                variant={variant}
                selected={
                  selectedSkills.status === 'ready'
                    ? selectedSkills.skills.some(it => it.id === item.id)
                    : false
                }
                onSelectedChange={selected => {
                  setSelectedSkills(prev => {
                    if (prev.status === 'ready') {
                      return {
                        status: 'ready',
                        skills: selected ? [...prev.skills, item] : _.reject(prev.skills, { id: item.id }),
                      }
                    }
                    return prev
                  })
                }}
              />
            )
          }}
        />
      </ScrollView>

      <SelectSkillSection gap='8' justifyContent='flex-end'>
        {variant === 'learner-manage' && (
          <>
            <Button variant='secondary' type='button' onClick={onCancel}>
              {t('dictionary.cancel')}
            </Button>
            <Button type='submit' loading={loading}>
              {t('dictionary.save')}
            </Button>
          </>
        )}
        {variant === 'onboarding' &&
          (selectedSkills.status === 'ready' && selectedSkills.skills.length > 0 ? (
            <Button type='submit' loading={loading}>
              {t('dictionary.continue')}
            </Button>
          ) : (
            <Button variant='secondary' type='button' onClick={onCancel}>
              {t('dictionary.skip')}
            </Button>
          ))}
      </SelectSkillSection>
    </Form>
  )
}
