import { SyncYJson } from '@sanalabs/y-redux'
import { useEffect, useState } from 'react'
import { AblyPresenceReduxSync } from 'sierra-client/collaboration/awareness/sync-ably-presence'
import { useAblyYDoc } from 'sierra-client/collaboration/use-ably-ydoc'
import { useChannelPresenceIfConnected } from 'sierra-client/realtime-data/use-channel-presence'
import {
  liveSessionAwarenessStatesChanged,
  liveSessionDataChanged,
} from 'sierra-client/state/live-session/actions'
import {
  selectLiveSessionData,
  selectLiveSessionLocalAwarenessState,
} from 'sierra-client/state/live-session/selectors'
import { ScopedLiveSessionId } from 'sierra-domain/collaboration/types'
import * as Y from 'yjs'

function useAblyYMapProvider(liveSessionId: ScopedLiveSessionId): Y.Map<unknown> | undefined {
  const [yMap, setYMap] = useState<Y.Map<unknown> | undefined>(undefined)
  const provider = useAblyYDoc(liveSessionId)

  useEffect(() => {
    if (provider === undefined) return
    setYMap(provider.yDoc.getMap('data-v3'))
  }, [provider])

  return yMap
}

export const LiveSessionYMapSyncAbly = ({
  liveSessionId,
}: {
  liveSessionId: ScopedLiveSessionId
}): JSX.Element | null => {
  const yMap = useAblyYMapProvider(liveSessionId)

  if (yMap === undefined) return null

  return <SyncYJson yJson={yMap} setData={liveSessionDataChanged} selectData={selectLiveSessionData} />
}

// Presence
export const LiveSessionAblyPresenceReduxSync = ({
  liveSessionId,
}: {
  liveSessionId: ScopedLiveSessionId
}): JSX.Element | null => {
  const channelPresence = useChannelPresenceIfConnected(liveSessionId)
  if (channelPresence === undefined) return null

  return (
    <>
      <AblyPresenceReduxSync
        presence={channelPresence.presence}
        connectionId={channelPresence.connectionId}
        setAwarenessStates={liveSessionAwarenessStatesChanged}
        selectLocalAwarenessState={selectLiveSessionLocalAwarenessState}
      />
    </>
  )
}
