import { useTranslation } from 'sierra-client/hooks/use-translation'
import { QuestionTextWithMath } from 'sierra-client/views/learner/components/text-with-math'
import { useIsInLiveQuizContext } from 'sierra-client/views/v3-author/live-quiz/live-quiz-context'
import { assertElementType } from 'sierra-client/views/v3-author/queries'
import {
  AlternativeInput,
  StyledPrompt,
  StyledPromptText,
} from 'sierra-client/views/v3-author/question-card/multiple-choice-alternative/shared'
import { useRenderingContext } from 'sierra-client/views/v3-author/rendering-context'
import { SlateFC } from 'sierra-client/views/v3-author/slate'
import { Text, View } from 'sierra-ui/primitives'
import { Text as SlateText } from 'slate'
import styled from 'styled-components'

export const QuestionCardMultipleChoiceAlternativeOptionWrapper = styled(View).attrs({
  padding: 'none',
  direction: 'row',
  gap: 'none',
  alignItems: 'center',
})`
  border: none;
  grid-area: option;
`

const OptionContainer: React.FC<React.PropsWithChildren<{ showPrompt: boolean; readOnly: boolean }>> = ({
  children,
  showPrompt,
  readOnly,
}) => {
  const { t } = useTranslation()
  return (
    <AlternativeInput readOnly={readOnly}>
      {showPrompt && (
        <StyledPrompt contentEditable={false}>
          <StyledPromptText>{t('author.slate.option')}</StyledPromptText>
        </StyledPrompt>
      )}
      {children}
    </AlternativeInput>
  )
}

const QuestionCardMultipleChoiceAlternativeOptionLearner: SlateFC = ({ element, children }) => {
  assertElementType('question-card-multiple-choice-alternative-option', element)

  return (
    <OptionContainer readOnly showPrompt={false}>
      <QuestionTextWithMath readOnly element={element}>
        {children}
      </QuestionTextWithMath>
    </OptionContainer>
  )
}

const QuestionCardMultipleChoiceAlternativeOptionCreate: SlateFC = ({ element, children, readOnly }) => {
  assertElementType('question-card-multiple-choice-alternative-option', element)

  const isEmpty = (() => {
    const it = element.children[0]
    return !readOnly && SlateText.isText(it) && it.text === ''
  })()

  return (
    <OptionContainer readOnly={readOnly} showPrompt={isEmpty}>
      <QuestionTextWithMath readOnly={readOnly} element={element}>
        {children}
      </QuestionTextWithMath>
    </OptionContainer>
  )
}

const QuestionCardLiveQuizMultipleChoiceAlternativeOption: SlateFC = props => {
  return <Text>{props.children}</Text>
}

export const QuestionCardMultipleChoiceAlternativeOption: SlateFC = props => {
  const { element, readOnly } = props
  const { preview } = useRenderingContext()
  assertElementType('question-card-multiple-choice-alternative-option', element)

  const Renderer =
    !readOnly && preview === false
      ? QuestionCardMultipleChoiceAlternativeOptionCreate
      : QuestionCardMultipleChoiceAlternativeOptionLearner

  const isLiveQuizContext = useIsInLiveQuizContext()

  if (isLiveQuizContext) {
    return <QuestionCardLiveQuizMultipleChoiceAlternativeOption {...props} />
  }

  return <Renderer {...props} />
}
