import React, { useContext } from 'react'
import { FCC } from 'sierra-client/types'
import { FileSubmissionSubtype, HomeworkCriteria, SubmissionType } from 'sierra-domain/flexible-content/types'

type SetLimitOfSubmissions = (limitOfSubmissions: number | undefined) => void

export type HomeworkCardCreateContext = {
  limitOfSubmissions: number | undefined
  setLimitOfSubmissions: SetLimitOfSubmissions
  submissionType: SubmissionType
  fileSubmissionSubtype: FileSubmissionSubtype | undefined
  setSubmissionType: (submissionType: SubmissionType, fileSubmissionSubtype?: FileSubmissionSubtype) => void
  criteria: HomeworkCriteria[]
  createCriteria: () => void
  updateCriteria: (id: string, newCriteria: HomeworkCriteria) => void
  deleteCriteria: (id: string) => void
  moveCriteria: (params: { from: number; to: number }) => void

  // NOTE: These lists are deprecated and not longer updated. Use Postgres instead.
  // usersToBeNotified: UsersToBeNotified
  // setUsersToBeNotified: SetUsersToBeNotified
  // reviewers: Reviewers
  // setReviewers: SetReviewers
}

const ReactHomeworkCardCreateContext = React.createContext<HomeworkCardCreateContext | undefined>(undefined)

export const HomeworkCardCreateContext: FCC<{ context: HomeworkCardCreateContext }> = ({
  children,
  context,
}) => {
  return (
    <ReactHomeworkCardCreateContext.Provider value={context}>
      {children}
    </ReactHomeworkCardCreateContext.Provider>
  )
}

export function useHomeworkCardCreateContext(): HomeworkCardCreateContext {
  const context = useContext(ReactHomeworkCardCreateContext)

  if (context === undefined) {
    throw new Error('Expected component to be wrapped in a HomeworkCardCreateContext')
  }

  return context
}
