import { useContext } from 'react'
import {
  RealTimeDataClient,
  RealTimeDataContext,
} from 'sierra-client/realtime-data/real-time-data-provider/realtime-data-context'

export const useRealTimeDataClient = (): RealTimeDataClient => {
  const client = useContext(RealTimeDataContext)

  if (!client) {
    throw new Error('RealTimeDataClientProvider not found')
  }

  return client
}
