import fc from 'fast-check'
import { ShortcutMenu } from 'sierra-client/components/shortcut-menu'
import { runEditorActions } from 'sierra-client/editor/actions/run-editor-actions'
import { useDeveloperToolsEnabled } from 'sierra-client/hooks/use-developer-tools-enabled'
import { EditorKeyboardEvent } from 'sierra-domain/v3-author'
import {
  EditorAction,
  EditorActionArbitrary,
  createEditorActionArbitrary,
} from 'sierra-domain/v3-author/arbitraries'
import { useSlateStatic } from 'slate-react'

const RandomEditorActionsInner: React.FC<{
  onKeyDown: (event: EditorKeyboardEvent) => void
}> = ({ onKeyDown }) => {
  const editor = useSlateStatic()
  return (
    <>
      <ShortcutMenu.Action
        group='developer-tools'
        label={{ value: 'Random editor actions', type: 'untranslated' }}
        run={() => {
          if (confirm('WARNING: This will modify your current document, do you wish to continue?')) {
            const actions: EditorAction[] = fc.sample(EditorActionArbitrary, 500)
            void runEditorActions(editor, actions, onKeyDown)
          }
        }}
        iconId='warning'
        permission='ACCESS_LMS'
      />
      <ShortcutMenu.Action
        group='developer-tools'
        label={{ value: 'Random editor actions (no insert-nodes)', type: 'untranslated' }}
        run={() => {
          if (confirm('WARNING: This will modify your current document, do you wish to continue?')) {
            const actions: EditorAction[] = fc.sample(
              createEditorActionArbitrary({
                weightOverrides: {
                  'insert-nodes': 0,
                },
              }),
              500
            )
            void runEditorActions(editor, actions, onKeyDown)
          }
        }}
        iconId='warning'
        permission='ACCESS_LMS'
      />
    </>
  )
}
export const RandomEditorActions: React.FC<{
  onKeyDown: (event: EditorKeyboardEvent) => void
}> = ({ onKeyDown }) => {
  const isDev = useDeveloperToolsEnabled()
  if (!isDev) return null
  return <RandomEditorActionsInner onKeyDown={onKeyDown} />
}
