import { Text } from 'sierra-ui/primitives'
import { token } from 'sierra-ui/theming'
import styled from 'styled-components'

export const LinkLabel = styled(Text).attrs({
  as: 'a',
  size: 'micro',
  phone: 'small',
  bold: true,
  color: 'foreground/secondary',
})`
  cursor: pointer;
  transition: color 120ms cubic-bezier(0.25, 0.1, 0.25, 1);

  &:hover {
    color: ${token('foreground/primary')};
  }
`
