import { color, deriveTheme } from 'sierra-ui/color'
import { Icon } from 'sierra-ui/components'
import { palette, token } from 'sierra-ui/theming'
import { DesignToken } from 'sierra-ui/theming/tokens/types'
import styled, { css } from 'styled-components'

const RadioCheckboxWrapper = styled.div.attrs({ contentEditable: false })<
  {
    $checked: boolean
    radio: boolean
  } & RadioCheckboxTheme
>`
  grid-area: checkbox;
  user-select: none;

  display: flex;
  justify-content: center;
  align-items: center;
  align-self: center;

  width: 1rem;
  height: 1rem;
  min-width: 1rem;
  min-height: 1rem;
  max-width: 1rem;
  max-height: 1rem;
  border-width: 1px;
  border-style: solid;
  border-radius: ${p => (p.radio === true ? '100%' : '2px')};
  background-color: transparent;

  border-color: ${p =>
    p.$checked
      ? color(deriveTheme(p.theme).secondaryTextColor).opacity(0.15).toString()
      : color(deriveTheme(p.theme).secondaryTextColor).opacity(0.15).toString()};
  color: ${p => (p.$checked ? palette.primitives.black : deriveTheme(p.theme).textColor)};

  ${p =>
    p.borderColor !== undefined &&
    css`
      border-color: ${token(p.borderColor)};
    `}

  ${p =>
    p.iconColor !== undefined &&
    css`
      color: ${token(p.iconColor)};
    `}

  ${p =>
    p.backgroundColor !== undefined &&
    css`
      background-color: ${token(p.backgroundColor)};
    `}
`

export type RadioCheckboxTheme = {
  borderColor?: DesignToken
  iconColor?: DesignToken
  backgroundColor?: DesignToken
}

export const RadioCheckbox: React.FC<
  {
    checked: boolean
    radio: boolean
    onClick?: (e: React.MouseEvent<HTMLDivElement>) => void
  } & RadioCheckboxTheme
> = ({ checked, radio, onClick, ...themeColors }) => {
  return (
    <RadioCheckboxWrapper $checked={checked} radio={radio} onClick={onClick} {...themeColors}>
      {checked && <Icon iconId={'checkmark'} size={'size-12'} />}

      <span />
    </RadioCheckboxWrapper>
  )
}
