import { UseQueryResult } from '@tanstack/react-query'
import React, { useRef, useState } from 'react'
import { useInView } from 'react-intersection-observer'
import { useDebouncedState } from 'sierra-client/hooks/use-debounced-state'
import { useCachedQuery } from 'sierra-client/state/api'
import { useScenarioCardActions } from 'sierra-client/views/v3-author/scenario/hooks'
import { ScenarioFile } from 'sierra-client/views/v3-author/scenario/utils'
import { CourseId } from 'sierra-domain/api/nano-id'
import { FileId } from 'sierra-domain/flexible-content/identifiers'
import {
  XRealtimeSelfPacedScenarioGetScenarioObjectiveSuggestions,
  XRealtimeSelfPacedScenarioGetScenarioSuggestions,
} from 'sierra-domain/routes'
import { iife, isNonEmptyArray } from 'sierra-domain/utils'
import { HideScrollbarUnlessHovered } from 'sierra-ui/components/layout-kit'
import { Skeleton, Text, View } from 'sierra-ui/primitives'
import { token } from 'sierra-ui/theming'
import { FCC } from 'sierra-ui/types'
import { useOnChanged } from 'sierra-ui/utils'
import styled from 'styled-components'

const Blurrer = styled.div<{ blurLeft?: boolean; blurRight?: boolean }>`
  position: absolute;
  top: 0;
  pointer-events: none;
  width: 100%;
  height: 100%;

  &::before {
    content: '';
    position: absolute;
    left: 0;
    height: 100%;
    width: 20px;
    display: ${p => (p.blurLeft === true ? 'block' : 'none')};
    background: linear-gradient(to left, rgba(255, 255, 255, 0), ${token('surface/default')} 100%);
  }

  &::after {
    content: '';
    height: 100%;
    width: 40px;
    right: 0;
    position: absolute;
    display: ${p => (p.blurRight === true ? 'block' : 'none')};
    background: linear-gradient(to right, rgba(255, 255, 255, 0), ${token('surface/default')} 100%);
  }
`

const ButtonWrapper = styled(View)`
  position: relative;
  overflow: hidden;
  white-space: nowrap;
  border-radius: 6px;
  width: fit-content;
  cursor: pointer;
  background: ${token('form/background/2')};
  padding: 3.5px 6px 3.5px 6px;
  height: 25px;

  &:hover {
    p {
      color: ${token('foreground/primary')};
    }
  }
`
const SuggestionButton: FCC<{ onClick: () => void }> = ({ children, onClick }) => {
  return (
    <ButtonWrapper onClick={onClick}>
      <Text color='foreground/secondary' size='micro' bold>
        {children}
      </Text>
    </ButtonWrapper>
  )
}

const LoadingSkeleton = React.forwardRef<HTMLDivElement, unknown>((_, ref) => (
  <Skeleton noDelay ref={ref} $radius='6px' $height={25} $width={200} />
))

const Wrapper = styled(View).attrs({ wrap: 'nowrap' })`
  overflow: auto;
  ${HideScrollbarUnlessHovered}
`

export const ScenarioSuggestions: React.FC<{ courseId: CourseId; file: ScenarioFile }> = ({
  courseId,
  file,
}) => {
  const suggestions = useCachedQuery(
    XRealtimeSelfPacedScenarioGetScenarioSuggestions,
    {
      courseId: courseId,
      fileId: file.id,
    },
    {
      staleTime: 60_000,
      gcTime: 60_000,
      refetchOnWindowFocus: false,
    }
  )
  const { setInputScenario } = useScenarioCardActions({ courseId, file: file })
  const parentRef = useRef<HTMLDivElement>(null)
  const [blurLeft, setBlurLeft] = useState(false)
  const [blurRight, setBlurRight] = useState(true)
  const { ref: firstItemRef } = useInView({
    root: parentRef.current,
    threshold: 0.99,
    onChange: inView => {
      if (inView) {
        setBlurLeft(false)
      } else {
        setBlurLeft(true)
      }
    },
  })
  const { ref: lastItemRef } = useInView({
    root: parentRef.current,
    threshold: 0.99,
    onChange: inView => {
      if (inView) {
        setBlurRight(false)
      } else {
        setBlurRight(true)
      }
    },
  })

  return (
    <View position='relative'>
      <Wrapper ref={parentRef}>
        {iife(() => {
          if (suggestions.isLoading || suggestions.isPending || suggestions.isRefetching) {
            return (
              <View>
                {Array.from({ length: 3 }).map((_, index) => (
                  <LoadingSkeleton
                    ref={index === 0 ? firstItemRef : index === 2 ? lastItemRef : undefined}
                    key={index}
                  />
                ))}
              </View>
            )
          }

          if (isNonEmptyArray(suggestions.data)) {
            return suggestions.data.map((suggestion, index) => (
              <div
                key={suggestion.title}
                ref={
                  isNonEmptyArray(suggestions.data)
                    ? index === 0
                      ? firstItemRef
                      : index === suggestions.data.length - 1
                        ? lastItemRef
                        : undefined
                    : undefined
                }
              >
                <SuggestionButton
                  onClick={() => {
                    setInputScenario(suggestion.title, suggestion.description)
                  }}
                >
                  {suggestion.title}
                </SuggestionButton>
              </div>
            ))
          }
        })}
      </Wrapper>
      <Blurrer blurLeft={blurLeft} blurRight={blurRight} />
    </View>
  )
}

const useDebouncedObjectiveSuggestions = ({
  topic,
  courseId,
  fileId,
}: {
  topic: {
    title: string
    description: string
  }
  courseId: CourseId
  fileId: FileId
}): UseQueryResult<Array<{ title: string; objective: string }>, Error> => {
  const [debouncedTopic, setTopic] = useDebouncedState(topic, { wait: 1500 })

  // debounce based on topic title so we do not do request on every update of value
  useOnChanged((p, n) => {
    if (p?.title !== n.title) {
      setTopic(topic)
    }
  }, topic)

  const suggestions = useCachedQuery(
    XRealtimeSelfPacedScenarioGetScenarioObjectiveSuggestions,
    {
      courseId: courseId,
      fileId: fileId,
      topic: debouncedTopic,
    },
    {
      staleTime: 60_000,
      gcTime: 60_000,
      refetchOnWindowFocus: false,
    }
  )

  return suggestions
}

export const ScenarioObjectiveSuggestions: React.FC<{
  topic: {
    title: string
    description: string
  }
  courseId: CourseId
  file: ScenarioFile
}> = ({ topic, courseId, file }) => {
  const suggestions = useDebouncedObjectiveSuggestions({
    courseId: courseId,
    fileId: file.id,
    topic: topic,
  })
  const { setLearnerGoal } = useScenarioCardActions({ courseId, file })
  const parentRef = useRef<HTMLDivElement>(null)
  const [blurLeft, setBlurLeft] = useState(false)
  const [blurRight, setBlurRight] = useState(true)
  const { ref: firstItemRef } = useInView({
    root: parentRef.current,
    threshold: 0.99,
    onChange: inView => {
      if (inView) {
        setBlurLeft(false)
      } else {
        setBlurLeft(true)
      }
    },
  })
  const { ref: lastItemRef } = useInView({
    root: parentRef.current,
    threshold: 0.99,
    onChange: inView => {
      if (inView) {
        setBlurRight(false)
      } else {
        setBlurRight(true)
      }
    },
  })

  return (
    <View position='relative'>
      <Wrapper ref={parentRef}>
        {iife(() => {
          if (suggestions.isLoading || suggestions.isPending || suggestions.isRefetching) {
            return (
              <View>
                {Array.from({ length: 3 }).map((_, index) => (
                  <LoadingSkeleton
                    ref={index === 0 ? firstItemRef : index === 2 ? lastItemRef : undefined}
                    key={index}
                  />
                ))}
              </View>
            )
          }

          if (isNonEmptyArray(suggestions.data)) {
            return suggestions.data.map((suggestion, index) => (
              <div
                key={suggestion.title}
                ref={
                  isNonEmptyArray(suggestions.data)
                    ? index === 0
                      ? firstItemRef
                      : index === suggestions.data.length - 1
                        ? lastItemRef
                        : undefined
                    : undefined
                }
              >
                <SuggestionButton
                  onClick={() => {
                    setLearnerGoal(suggestion.objective)
                  }}
                >
                  {suggestion.title}
                </SuggestionButton>
              </div>
            ))
          }
        })}
      </Wrapper>
      <Blurrer blurLeft={blurLeft} blurRight={blurRight} />
    </View>
  )
}
