import { SlateRootElement } from 'sierra-domain/v3-author'
import { createParagraph } from 'sierra-domain/v3-author/create-blocks'
import { Editor, Element, Text, Transforms } from 'slate'

const debug = (...messages: unknown[]): void => console.debug('[withCardNormalization]', ...messages)

type ValidRootElementsList = ['paragraph', ...SlateRootElement['type'][]]

type Options = {
  validRootElements: ValidRootElementsList
}

export const withCardNormalization = (editor: Editor, options: Options): Editor => {
  const { normalizeNode } = editor

  editor.normalizeNode = entry => {
    const [node, path] = entry
    if (path.length !== 1) return normalizeNode(entry)

    const type = Element.isElement(node) ? node.type : undefined
    const isValidNode = type !== undefined && (options.validRootElements as string[]).includes(type)

    if (Text.isText(node)) {
      debug(`Wrapping text node in paragraph at ${JSON.stringify(path)})`)
      return Transforms.wrapNodes(editor, createParagraph(), { at: path })
    }

    if (!isValidNode) {
      debug(`Unwrapping invalid '${type ?? 'text'}' node in paragraph at ${JSON.stringify(path)})`)
      return Transforms.unwrapNodes(editor, { at: path })
    }

    return normalizeNode(entry)
  }

  return editor
}
