/**
 * Sync the call service state with the livesession awareness data
 */

import { useEffect } from 'react'
import { useDispatch, useSelector } from 'sierra-client/state/hooks'
import { liveSessionLocalAwarenessStateMerged } from 'sierra-client/state/live-session/actions'
import {
  selectAudioState,
  selectClientId,
  selectCurrentBreakoutRoomId,
  selectIsScreenSharing,
  selectScreenShareClientId,
  selectVideoState,
} from 'sierra-client/state/live/selectors'
import { selectUser } from 'sierra-client/state/user/user-selector'

export const LiveSessionSyncCallState = (): null => {
  const dispatch = useDispatch()
  const screenShareClientId = useSelector(selectScreenShareClientId)
  const isScreenSharing = useSelector(selectIsScreenSharing)
  const clientId = useSelector(selectClientId)
  const breakoutRoomId = useSelector(selectCurrentBreakoutRoomId)
  const user = useSelector(selectUser)
  const videoState = useSelector(selectVideoState)
  const audioState = useSelector(selectAudioState)

  const userId = user?.uuid

  useEffect(() => {
    void dispatch(
      liveSessionLocalAwarenessStateMerged({
        agoraScreenShareUID: isScreenSharing ? screenShareClientId : undefined,
        agoraUID: clientId,
        userId: userId,
        breakoutRoomId,
        videoState,
        audioState,
      })
    )
  }, [
    audioState,
    breakoutRoomId,
    clientId,
    dispatch,
    isScreenSharing,
    screenShareClientId,
    userId,
    videoState,
  ])

  return null
}
