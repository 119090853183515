import React, { Suspense, useContext } from 'react'
import { FCC } from 'sierra-client/types'
import {
  CreateContext,
  LiveContext,
  RecapContext,
  ReflectionsDataLayer,
  SelfPacedContext,
} from 'sierra-client/views/v3-author/reflection-card/reflection-card-contexts'
import { EditorMode } from 'sierra-client/views/v3-author/slate'
import { Entity } from 'sierra-domain/entity'
import { ReflectionCard as SlateReflectionCard } from 'sierra-domain/v3-author'

type DataLayerProps = FCC<{ element: Entity<SlateReflectionCard>; mode: EditorMode }>

const LazyReflectionCardDataLayer = React.lazy(
  () => import('sierra-client/views/v3-author/reflection-card/reflection-card-data-layer-implementation')
)

const PlaceholderContext = React.createContext<ReflectionsDataLayer>({
  reflectionId: '',
  responses: [],
  userResponses: [],
  sortOrder: 'popular',
  usersTyping: [],
  totalReflectionsCount: 0,
  availableSortOptions: [],
  readOnly: true,
  reflectionsSummary: {
    summaryEnabledInEditor: true,
    summary: undefined,
    updatedAt: '',
    responseIds: [],
    loading: false,
    updateSummary: () => null,
  },
})

const DataLayerPlaceholder: FCC = ({ children }) => {
  return <>{children}</>
}

export const ReflectionsDataProvider: DataLayerProps = props => {
  return (
    <Suspense fallback={<DataLayerPlaceholder>{props.children}</DataLayerPlaceholder>}>
      <LazyReflectionCardDataLayer {...props} />
    </Suspense>
  )
}

export const useReflectionsData = (): ReflectionsDataLayer => {
  const liveData = useContext(LiveContext)
  const selfPacedData = useContext(SelfPacedContext)
  const createData = useContext(CreateContext)
  const recapData = useContext(RecapContext)
  const placeholderData = useContext(PlaceholderContext)

  return liveData ?? selfPacedData ?? createData ?? recapData ?? placeholderData
}
