import { default as KeyboardBackend } from 'react-dnd-accessible-backend'
import { createTransition, DndProvider } from 'react-dnd-multi-backend'
import { TouchBackend } from 'react-dnd-touch-backend'
import { FadedBoxDragOverlay } from 'sierra-client/components/common/dnd/faded-box-drag-overlay'
import { NodeGhost } from 'sierra-client/components/common/dnd/node-ghost'
import { FCC } from 'sierra-client/types'
import { CustomDragFlipCardOverLay } from 'sierra-client/views/v3-author/flip-cards/flip-card-dnd'
import { CustomDragPollAlternativeOverLay } from 'sierra-client/views/v3-author/poll-card/alternative-dnd'
import { isDefined } from 'sierra-domain/utils'

const CustomKeyboardTransition = createTransition('keydown', (event: Event) => {
  const isKeyboard = 'code' in event && event.code === 'Space'
  return isKeyboard
})

const CustomPointerTransition = createTransition('pointerdown', (event: Event) => {
  const isMouse = 'pointerType' in event && event.pointerType === 'mouse'
  const isTouch = 'touches' in event && isDefined(event.touches)

  return isMouse || isTouch
})

const pipeline = {
  backends: [
    {
      id: 'touch',
      backend: TouchBackend,
      preview: false,
      options: {
        enableMouseEvents: true,
        enableTouchEvents: true,
      },
      transition: CustomPointerTransition,
    },
    {
      id: 'keyboard',
      backend: KeyboardBackend,
      context: { window, document },
      options: {
        isDragTrigger: (event: KeyboardEvent) => event.code === 'Space',
        preview: false,
      },
      transition: CustomKeyboardTransition,
    },
  ],
}

export const DndContext: FCC = ({ children }) => {
  return (
    <DndProvider options={pipeline}>
      {children}
      <NodeGhost />
      <FadedBoxDragOverlay />
      <CustomDragFlipCardOverLay />
      <CustomDragPollAlternativeOverLay />
    </DndProvider>
  )
}
