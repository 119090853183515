import _ from 'lodash'
import { useMemo } from 'react'
import { UnsplashSuggestions, useUnsplashImages } from 'sierra-client/hooks/use-unsplash-images'
import { useCachedQuery } from 'sierra-client/state/api'
import { findNode } from 'sierra-client/views/v3-author/queries'
import { textBeforeNodeWithId } from 'sierra-client/views/v3-author/slash-menu/slash-menu-question-utils'
import { XRealtimeAuthorTextToKeywords } from 'sierra-domain/routes'
import { iife } from 'sierra-domain/utils'
import { SanaEditor } from 'sierra-domain/v3-author'
import { Element } from 'slate'
import { useSlateStatic } from 'slate-react'

type QueryStrategy = { type: 'from-text'; text: string } | { type: 'from-query'; query: string }

// If the image node has an alt text, we assume that's a highly accurate description of the image and it makes
// sense to use that information rather than trying to extrapolate from previous text. When the user inserts
// text blocks there will never be an alt text, but this is not the case when we've generated image nodes
// using the assistant.
const queryStrategy = (editor: SanaEditor, nodeId: string): QueryStrategy => {
  const [node] = findNode(editor, n => n.id === nodeId)

  if (
    Element.isElement(node) &&
    node.type === 'image' &&
    node.altText !== undefined &&
    node.altText.trim() !== ''
  ) {
    return { type: 'from-query', query: node.altText }
  }

  return {
    type: 'from-text',
    text: textBeforeNodeWithId(editor, nodeId).trim(),
  }
}

export function useUnsplashSuggestions({ nodeId }: { nodeId: string }): {
  query: string | undefined
  suggestions: Omit<UnsplashSuggestions, 'requestMore' | 'resolvedQuery'> | undefined
} {
  const editor = useSlateStatic()

  const strategy = useMemo(() => queryStrategy(editor, nodeId), [editor, nodeId])

  const keywordsQueryEnabled = strategy.type === 'from-text'
  const keywordsQuery = useCachedQuery(
    XRealtimeAuthorTextToKeywords,
    { text: strategy.type === 'from-text' ? strategy.text : '' },
    { enabled: keywordsQueryEnabled, retryOnMount: false, retry: 0 }
  )

  const keywords = useMemo(() => {
    const words = keywordsQuery.data?.keywords
    if (words !== undefined) return _.chain(words).take(3).shuffle().first().value()
    else return undefined
  }, [keywordsQuery.data])

  const unsplashQuery = iife(() => {
    switch (strategy.type) {
      case 'from-text':
        return keywords
      case 'from-query':
        return strategy.query
    }
  })

  const suggestions = useUnsplashImages({
    query: unsplashQuery,
  })

  return useMemo(() => {
    const query = suggestions.resolvedQuery
    if (keywordsQueryEnabled && keywordsQuery.isFetching) {
      return { query, suggestions: { images: [], isLoading: true } }
    } else return { query, suggestions }
  }, [keywordsQuery.isFetching, keywordsQueryEnabled, suggestions])
}
