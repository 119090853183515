import React, { useCallback } from 'react'
import { BadgeAchievement } from 'sierra-client/features/skills'
import { useTranslation } from 'sierra-client/hooks/use-translation'
import { PreviewUrlContainer } from 'sierra-client/views/manage/certificates/preview-url-container'
import { Badge } from 'sierra-client/views/self-paced/use-my-achieved-badges'
import { Certificate } from 'sierra-client/views/self-paced/use-my-issued-certificates'
import { useTracking } from 'sierra-client/views/workspace/utils/certificates/tracking'
import { isDefined } from 'sierra-domain/utils'
import { AchievementModal } from 'sierra-ui/components/modal/achievement-modal'

type AchievementsProps = {
  open: boolean
  onClose: () => void
  achievements: (Certificate | Badge)[]
}

export const Achievements: React.FC<AchievementsProps> = ({ onClose, achievements, open }) => {
  const { t } = useTranslation()
  const track = useTracking()

  const [currentAchievementIndex, setCurrentAchievementIndex] = React.useState<number>(0)
  const [animateOut, setAnimateOut] = React.useState<boolean>(false)
  const [animateModalOut, setAnimateModalOut] = React.useState<boolean>(false)

  const currentAchievement = achievements[currentAchievementIndex]

  const buttonText =
    currentAchievementIndex < achievements.length - 1 ? t('dictionary.continue') : t('dictionary.close')

  const onClick = useCallback((): void => {
    if (currentAchievementIndex < achievements.length - 1) {
      setAnimateOut(true)

      setTimeout(() => {
        setCurrentAchievementIndex(currentAchievementIndex + 1)
        setTimeout(() => {
          setAnimateOut(false)
        }, 300)
      }, 700)
    } else {
      setAnimateOut(true)
      setAnimateModalOut(true)

      setTimeout(() => {
        onClose()
        setAnimateOut(false)
        setAnimateModalOut(false)
      }, 500)
    }
  }, [achievements.length, currentAchievementIndex, onClose])

  if (!currentAchievement) return null

  return (
    <AchievementModal onClose={onClose} open={open} animateOut={animateModalOut}>
      {currentAchievement.type === 'certificate' ? (
        <PreviewUrlContainer
          {...currentAchievement}
          canShare
          btnText={buttonText}
          onClick={onClick}
          onShare={() => {
            if (isDefined(currentAchievement.issuedCertificate)) {
              track.linkedin.share(currentAchievement.issuedCertificate.certificateId)
            }
          }}
          onDownload={() => {
            if (isDefined(currentAchievement.issuedCertificate)) {
              track.download.click(currentAchievement.issuedCertificate.certificateId)
            }
          }}
          animateOut={animateOut}
          isAchievement={true}
        />
      ) : (
        <BadgeAchievement
          btnText={buttonText}
          onClick={onClick}
          animateOut={animateOut}
          {...currentAchievement}
        />
      )}
    </AchievementModal>
  )
}
