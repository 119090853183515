import React from 'react'
import { OnEmojiSelectedCallbackFn, OnOutsideClickCallbackFn } from 'sierra-client/editor/emoji-types'

export interface EmojiPickerContext {
  open: (
    anchor: Element,
    onEmojiSelected: OnEmojiSelectedCallbackFn,
    onOutsideClick?: OnOutsideClickCallbackFn
  ) => void
  close: () => void
  isOpen: boolean
  currentAnchor: Element | null
}

export const EmojiPickerPopupContextObj = React.createContext<EmojiPickerContext | undefined>(undefined)
