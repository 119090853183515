import { useSelector } from 'sierra-client/state/hooks'
import { selectUser } from 'sierra-client/state/user/user-selector'
import { UploadFileResult, usePdfData } from 'sierra-client/views/flexible-content/use-pdf-convert'
import { MediaUploader } from 'sierra-client/views/v3-author/common/media-uploader/media-uploader'
import { AssetContext } from 'sierra-domain/asset-context'

type PdfUpload = {
  onUploaded: (data: UploadFileResult) => void
  assetContext: AssetContext
}

export const PdfUpload: React.FC<PdfUpload> = ({ onUploaded, assetContext }) => {
  const { uploadPdfFile } = usePdfData()
  const user = useSelector(selectUser)

  if (!user) throw new Error('User not found')

  return (
    <MediaUploader
      iconId={'document--import'}
      accept={['application/pdf']}
      uploadMedia={uploadPdfFile}
      onUploaded={onUploaded}
      assetContext={assetContext}
    />
  )
}
