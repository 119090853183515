import React from 'react'
import { useTranslation } from 'sierra-client/hooks/use-translation'
import { AccessRole } from 'sierra-domain/api/manage'
import { Text } from 'sierra-ui/primitives'
import styled from 'styled-components'

const UnderlinedLink = styled(Text).attrs({ as: 'a' })``

export const AccessRoleIndicator: React.FC<{ accessRole: AccessRole; onClick: () => void }> = ({
  accessRole,
  onClick,
}) => {
  const { dynamicT, t } = useTranslation()
  // Aid during initial phase to show that user has a role and not a level
  const roleIndicatorText = t('manage.users.user-settings.access-role-title')

  return (
    <UnderlinedLink onClick={() => onClick()}>
      {dynamicT(accessRole.translationKey, undefined, accessRole.defaultLabel)} ({roleIndicatorText})
    </UnderlinedLink>
  )
}
