import { useCallback, useId, useState } from 'react'
import { ChipInputWithAutocomplete } from 'sierra-client/components/common/chip-input-with-autocomplete'
import { useNotif } from 'sierra-client/components/common/notifications'
import { Pill } from 'sierra-client/components/common/pill'
import { useTranslation } from 'sierra-client/hooks/use-translation'
import { getAvatarImage } from 'sierra-client/utils/avatar-img'
import { SettingsTabComponent } from 'sierra-client/views/manage/paths/path-settings/types'
import { PathCollaborator } from 'sierra-domain/api/admin'
import { getUserName } from 'sierra-domain/utils'
import { FormElement, RoundAvatar } from 'sierra-ui/components'
import { Button, IconButton, LoadingSpinner, Spacer, Text } from 'sierra-ui/primitives'
import styled from 'styled-components'

const AutocompleteContainer = styled.div`
  flex: 1;
  display: grid;
  grid: 1fr / 1fr 0fr;
  gap: 1.5rem;
  align-items: center;
`

const StyledAutocomplete = styled(ChipInputWithAutocomplete)`
  flex: 1;

  & {
    .MuiOutlinedInput-root {
      padding: 0.5rem;
    }
  }
`
const SuggestionItem = styled.li`
  display: flex;
  align-items: center;
`

const CollaboratorList = styled.ul`
  list-style: none;
  position: relative;

  li.grid {
    display: grid;
    grid: 1fr / 0fr 1fr;
    grid-auto-columns: max-content;
    grid-auto-flow: column;
    gap: 1rem;
    justify-content: start;
    align-items: center;
    padding: 0.5rem 0;
    font-size: 1em;
  }
`

const getOptionLabel = (option: PathCollaborator): string => getUserName(option) ?? ''

export const CollaboratorsTab: SettingsTabComponent = ({
  pathId,
  onSave,
  canEditCollaborators,
  collaborators,
  potentialCollaborators,
  updateCollaborators,
}) => {
  const inputId = useId()

  const { t } = useTranslation()
  const notifications = useNotif()

  const [query, setQuery] = useState('')
  const [selections, setSelections] = useState<PathCollaborator[]>([])

  const handleInviteSelections = async (): Promise<void> => {
    await updateCollaborators({ pathId, add: selections.map(c => c.userId), remove: [] })
    setSelections([])
    onSave()

    notifications.push({
      type: 'custom',
      level: 'success',
      body: t('notifications.collaborator-invited-singular'),
    })
  }

  const handleRemoveCollaborator = async (collaborator: PathCollaborator): Promise<void> => {
    await updateCollaborators({ pathId, add: [], remove: [collaborator.userId] })
    onSave()
  }

  const filterOptions = useCallback(
    (options: any): PathCollaborator[] => {
      const allCollaborators = options as PathCollaborator[]
      const collaboratorIds = new Set(collaborators.map(c => c.userId))

      return allCollaborators.filter(collaborator => {
        if (collaboratorIds.has(collaborator.userId)) return false

        const labelTokens = getOptionLabel(collaborator)
          .split(' ')
          .map(t => t.toLowerCase())
        const queryTokens = query.split(' ').map(t => t.toLowerCase())

        return labelTokens.some(lt => queryTokens.some(qt => lt.startsWith(qt)))
      })
    },
    [collaborators, query]
  )

  return (
    <FormElement label={t('author.invite-collaborators')} htmlFor={inputId}>
      {potentialCollaborators.length === 0 /* TODO: add loading state to potential collaborators hook */ ? (
        <LoadingSpinner padding='large' />
      ) : (
        <AutocompleteContainer>
          <StyledAutocomplete
            id={inputId}
            inputValue={query}
            onInputChange={(event, value) => setQuery(value)}
            value={selections}
            onChange={(event, values) => {
              setSelections(values as PathCollaborator[])
            }}
            options={potentialCollaborators}
            getOptionLabel={option => getOptionLabel(option as PathCollaborator)}
            filterOptions={filterOptions}
            filterSelectedOptions
            limitTags={5}
            multiple
            freeSolo={false}
            disabled={!canEditCollaborators}
            inputPlaceholder={t('author.course-settings.skill-placeholder')}
            renderTags={values => {
              const collaborators = values as PathCollaborator[]

              return collaborators.map(collaborator => (
                <Pill key={collaborator.userId} variant='outlined' active={false}>
                  {getOptionLabel(collaborator)}
                </Pill>
              ))
            }}
            renderOption={option => {
              const collaborator = option as PathCollaborator

              return (
                <SuggestionItem>
                  <RoundAvatar
                    firstName={collaborator.firstName}
                    lastName={collaborator.lastName}
                    src={getAvatarImage(collaborator.userId, collaborator.avatar)}
                    color={collaborator.avatarColor}
                  />
                  <Spacer />
                  <Text bold>{getOptionLabel(collaborator)}</Text>
                </SuggestionItem>
              )
            }}
          />
          <Button
            onClick={handleInviteSelections}
            disabled={!canEditCollaborators || selections.length === 0}
          >
            {t('dictionary.invite')}
          </Button>
        </AutocompleteContainer>
      )}
      <CollaboratorList>
        {collaborators.length === 0 ? (
          <li>
            <Text color='grey35'>{t('author.no-collaborators')}</Text>
          </li>
        ) : (
          collaborators.map((collaborator: PathCollaborator) => (
            <li key={collaborator.userId} className='grid'>
              <RoundAvatar
                firstName={collaborator.firstName}
                lastName={collaborator.lastName}
                src={getAvatarImage(collaborator.userId, collaborator.avatar)}
                color={collaborator.avatarColor}
              />
              <Text bold>{getOptionLabel(collaborator)}</Text>
              {canEditCollaborators && (
                <IconButton
                  onClick={() => handleRemoveCollaborator(collaborator)}
                  iconId='close'
                  variant='transparent'
                />
              )}
            </li>
          ))
        )}
      </CollaboratorList>
    </FormElement>
  )
}
