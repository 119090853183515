import React from 'react'
import { RouterLink } from 'sierra-client/components/common/link'
import { Thumbnail } from 'sierra-client/components/common/thumbnail'
import { useResolveAsset } from 'sierra-client/hooks/use-resolve-asset'
import { useTranslation } from 'sierra-client/hooks/use-translation'
import {
  ContentItem,
  getContentClassificationData,
} from 'sierra-client/views/manage/content/utils/content-utils'
import { AssetContext } from 'sierra-domain/asset-context'
import { ImageUnion } from 'sierra-domain/content/v2/content'
import { Icon, Tooltip, TruncatedText } from 'sierra-ui/components'
import { Text, View } from 'sierra-ui/primitives'
import styled from 'styled-components'

type BaseContentProps = Pick<ContentItem, 'contentType' | 'courseKind'>

const LinkWrapper = styled.a`
  cursor: pointer;
`

const StyledThumbnail = styled(Thumbnail)`
  --size: 2.5rem;
  width: var(--size);
  min-width: var(--size);
  height: var(--size);
  min-height: var(--size);
  border-radius: 0.75rem;
`

type ContentRowProps = BaseContentProps & {
  image?: ImageUnion
  title?: string
  href?: string
  isCourseEdition: boolean
  assetContext: AssetContext
}

const FlexRow = styled.div`
  display: flex;
  justify-content: flex-start;
  align-items: flex-start;
  gap: 0.5rem;
`

export const ContentRow: React.FC<ContentRowProps> = ({
  image,
  title,
  href,
  contentType,
  courseKind,
  isCourseEdition,
  assetContext,
}) => {
  const { t } = useTranslation()

  const imgSrc = useResolveAsset({ image, assetContext, size: 'admin-sm' })
  const { iconId, translationKey } = getContentClassificationData({
    contentType,
    courseKind,
    isCourseEdition,
  })

  const showPill = contentType === 'path'

  const titleElementText = (
    <Tooltip title={t('manage.heatmap.show-details')}>
      <TruncatedText lines={showPill ? 1 : 2} size='small' bold as='span' color='grey40'>
        {title ?? ''}
      </TruncatedText>
    </Tooltip>
  )

  return (
    <View alignItems='center' gap='xxsmall'>
      <StyledThumbnail image={imgSrc} />
      <View direction='column' gap='none' margin='none'>
        <FlexRow>
          {href === undefined ? (
            titleElementText
          ) : (
            <RouterLink href={href}>
              <LinkWrapper>{titleElementText}</LinkWrapper>
            </RouterLink>
          )}
        </FlexRow>
        {showPill && (
          <View gap='4'>
            <Icon iconId={iconId} color='grey40' size='size-14' />
            <Text size='small' color='grey40'>
              {t(translationKey)}
            </Text>
          </View>
        )}
      </View>
    </View>
  )
}
