import { isElementType } from 'sierra-client/views/v3-author/queries'
import { createParagraph } from 'sierra-domain/v3-author/create-blocks'
import { Editor, Node, Path, Transforms } from 'slate'

const debug = (...messages: unknown[]): void => console.debug('[withBlankLine]', ...messages)

/**
 * Ensure that the last line in the editor is always an empty paragraph.
 */
export const withBlankLine = <T extends Editor>(editor: T): T => {
  const { normalizeNode } = editor

  editor.normalizeNode = ([node, path]) => {
    if (path.length === 0) {
      const lastChild = Node.children(editor, path, { reverse: true }).next().value

      if (lastChild) {
        const [element, elementPath] = lastChild

        const isParagraph = isElementType('paragraph', element)
        if (!isParagraph) {
          debug('Adding missing blank line at the end of the document.')
          return Transforms.insertNodes(editor, createParagraph(), { at: Path.next(elementPath) })
        }
      } else {
        debug('Adding missing blank line to an empty editor.')
        return Transforms.insertNodes(editor, createParagraph(), { at: [0] })
      }
    }

    normalizeNode([node, path])
  }

  return editor
}
