import { graphql } from 'sierra-client/api/graphql/gql'
import {
  CreateEmailTemplateBindingsMutation,
  DeleteEmailTemplateBindingsMutation,
  FetchEmailHtmlQuery,
  FetchEmailTemplateBindingsQuery,
  RenderEmailHtmlRequest,
  SendTestEmailMutation,
  UpdateEmailTemplateBindingsMutation,
  UpsertEmailTemplateBindingsRequest,
} from 'sierra-client/api/graphql/gql/graphql'
import { graphQuery } from 'sierra-client/api/hooks/use-graphql-query'

export const updateEmailTemplateBindings = async (
  req: UpsertEmailTemplateBindingsRequest
): Promise<UpdateEmailTemplateBindingsMutation> =>
  graphQuery(
    graphql(`
      mutation updateEmailTemplateBindings($req: UpsertEmailTemplateBindingsRequest!) {
        upsertEmailTemplateBindings(req: $req) {
          bindings {
            emailTemplateBindingsId
            resourceId
            ... on EmailTemplateBindingsForProgram {
              description
              buttonText
              customButtonUrl
              headline
              image {
                ...ImageFragment
              }
            }
          }
        }
      }
    `),
    { req }
  )

export const createEmailTemplateBindings = async (
  req: UpsertEmailTemplateBindingsRequest
): Promise<CreateEmailTemplateBindingsMutation> =>
  graphQuery(
    graphql(`
      mutation createEmailTemplateBindings($req: UpsertEmailTemplateBindingsRequest!) {
        upsertEmailTemplateBindings(req: $req) {
          bindings {
            emailTemplateBindingsId
            resourceId
            ... on EmailTemplateBindingsForProgram {
              description
              buttonText
              customButtonUrl
              headline
              image {
                ...ImageFragment
              }
            }
          }
        }
      }
    `),
    { req }
  )

export const deleteEmailTemplateBindings = async (
  ids: string[]
): Promise<DeleteEmailTemplateBindingsMutation> =>
  graphQuery(
    graphql(`
      mutation deleteEmailTemplateBindings($ids: [EmailTemplateBindingsId!]!) {
        deleteEmailTemplateBindings(ids: $ids) {
          ids
        }
      }
    `),
    { ids }
  )

export const sendTestEmail = async (req: RenderEmailHtmlRequest): Promise<SendTestEmailMutation> =>
  graphQuery(
    graphql(`
      mutation sendTestEmail($req: RenderEmailHtmlRequest!) {
        sendTestEmail(req: $req) {
          success
        }
      }
    `),
    { req }
  )

export const fetchEmailTemplateBindings = async (id: string): Promise<FetchEmailTemplateBindingsQuery> =>
  graphQuery(
    graphql(`
      query fetchEmailTemplateBindings($id: EmailTemplateBindingsId!) {
        emailTemplateBindings(id: $id) {
          ... on EmailTemplateBindingsForProgram {
            buttonText
            customButtonUrl
            description
            headline
            image {
              ...ImageFragment
            }
          }
        }
      }
    `),
    { id }
  )

export const fetchEmailHtml = async (req: RenderEmailHtmlRequest): Promise<FetchEmailHtmlQuery> =>
  graphQuery(
    graphql(`
      query fetchEmailHtml($req: RenderEmailHtmlRequest!) {
        emailHtml(req: $req) {
          html
        }
      }
    `),
    { req }
  )
