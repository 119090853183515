import { BarChartViewer } from 'sierra-client/features/insights/display-widgets/bar-chart-viewer'
import { LineChartViewer } from 'sierra-client/features/insights/display-widgets/line-chart-viewer/line-chart-viewer'
import { MetricWidget } from 'sierra-client/features/insights/display-widgets/metric/metric-widget'
import { PivotTable } from 'sierra-client/features/insights/display-widgets/pivot/pivot-table'
import { ProgressBarWidget } from 'sierra-client/features/insights/display-widgets/progress-bar/progress-bar-widget'
import { InsightsTable } from 'sierra-client/features/insights/display-widgets/table/table'
import { WidgetSortHandler } from 'sierra-client/features/insights/widget-builder/types'
import { Dimension, TableResult, Widget } from 'sierra-domain/api/insights'
import { Filter } from 'sierra-domain/filter/datatype/filter'
import { assertNever } from 'sierra-domain/utils'
import { View } from 'sierra-ui/primitives'
import { token } from 'sierra-ui/theming'
import styled from 'styled-components'

// Flex-basis is needed to make sure the table has some size as initial for it to grow from
// in its flex parent
const TableContainer = styled(View).attrs({ direction: 'column', alignItems: 'stretch', grow: true })`
  width: 100%;
  height: 100%;
  flex-basis: 1px;
  background-color: ${token('surface/default')};
`

const ChartViewWrapper = styled(View).attrs({ direction: 'column' })<{ previewMode: boolean }>`
  height: ${props => (props.previewMode ? '400px' : '100%')};
`

export type DataViewerActionProps = {
  onDrillDown?: (filter: Filter, newDimension: Dimension) => void
  onFilterOut?: (filter: Filter) => void
  onSetLimit?: (limit?: number) => void
  onSort?: WidgetSortHandler
}

export const WidgetMapper: React.FC<
  {
    widget: Widget
    tableResult: TableResult
    previewMode: boolean
    showToolbar: boolean
  } & DataViewerActionProps
> = ({ widget, tableResult, previewMode, showToolbar, onDrillDown, onFilterOut, onSetLimit, onSort }) => {
  switch (widget.type) {
    case 'widget.aggregation-table': {
      return (
        <TableContainer>
          <InsightsTable
            tableResult={tableResult}
            showToolbar={showToolbar}
            onSort={onSort}
            sortBy={widget.sortBy}
            onSetLimit={onSetLimit}
            limit={widget.limit}
            onFilterOut={previewMode ? onFilterOut : undefined}
            onDrillDown={previewMode ? onDrillDown : undefined}
            selectedDimensions={widget.dimensions}
            selectedMeasures={widget.measures}
            disableNormalizeTableResult
            widget={widget}
          />
        </TableContainer>
      )
    }
    case 'widget.list-table': {
      return (
        <TableContainer>
          <InsightsTable
            tableResult={tableResult}
            showToolbar={showToolbar}
            onSort={onSort}
            sortBy={widget.sortBy}
            onSetLimit={onSetLimit}
            limit={widget.limit}
            onFilterOut={previewMode ? onFilterOut : undefined}
            selectedDimensions={widget.dimensions}
            selectedView={widget.view}
            disableNormalizeTableResult
            widget={widget}
          />
        </TableContainer>
      )
    }
    case 'widget.bar-chart':
      return (
        <View direction='column' grow>
          <ChartViewWrapper previewMode={previewMode}>
            <BarChartViewer
              tableResult={tableResult}
              widget={widget}
              onDrillDown={onDrillDown}
              onFilterOut={onFilterOut}
            />
          </ChartViewWrapper>
          {previewMode && (
            <TableContainer>
              <InsightsTable
                tableResult={tableResult}
                onSort={onSort}
                sortBy={widget.sortBy}
                onSetLimit={onSetLimit}
                limit={widget.limit}
                selectedDimensions={widget.dimensions}
                selectedMeasures={widget.measures}
                onFilterOut={onFilterOut}
                onDrillDown={onDrillDown}
                widget={widget}
              />
            </TableContainer>
          )}
        </View>
      )

    case 'widget.line-chart':
      return (
        <View direction='column' grow>
          <ChartViewWrapper previewMode={previewMode}>
            <LineChartViewer tableResult={tableResult} widget={widget} />
          </ChartViewWrapper>
          {previewMode && (
            <TableContainer>
              <InsightsTable
                tableResult={tableResult}
                sortBy={widget.sortBy}
                onSetLimit={onSetLimit}
                limit={widget.limit}
                onFilterOut={onFilterOut}
                onDrillDown={onDrillDown}
                selectedDimensions={widget.dimensions}
                selectedMeasures={widget.measures}
                widget={widget}
              />
            </TableContainer>
          )}
        </View>
      )
    case 'widget.metric':
      return <MetricWidget tableResult={tableResult} widget={widget} previewMode={previewMode} />
    case 'widget.progress-bar':
      return <ProgressBarWidget tableResult={tableResult} widget={widget} previewMode={previewMode} />
    case 'widget.pivot-table':
      return (
        <TableContainer>
          <PivotTable tableResult={tableResult} showToolbar={showToolbar} widget={widget} />
        </TableContainer>
      )
    default:
      assertNever(widget)
  }
}
