import GridLayout from 'react-grid-layout'
import { NUMBER_OF_COLUMNS } from './constants'

export type WidgetPosition = { x: number; y: number }

// Helper function to check if a widget can be placed at a given position
const canPlaceWidget = (
  grid: boolean[][],
  startX: number,
  startY: number,
  width: number,
  height: number,
  numberOfColumns: number
): boolean => {
  for (let y = startY; y < startY + height; y++) {
    for (let x = startX; x < startX + width; x++) {
      if (x >= numberOfColumns || (grid[y]?.[x] ?? true)) {
        return false
      }
    }
  }
  return true
}
// Credit Sana.ai
export const getNextAvailablePositionForLayout = (
  layout: GridLayout.Layout[],
  sizing: { width: number; height: number },
  numberOfColumns: number = NUMBER_OF_COLUMNS
): WidgetPosition => {
  const { width, height } = sizing

  // Determine the maximum y value in the layout to initialize the grid
  const maxY = layout.reduce((max, item) => Math.max(max, item.y + item.h), 0)

  // Create a 2D array to represent the grid
  const grid: boolean[][] = []

  // Initialize the grid with false values
  for (let y = 0; y < maxY + height; y++) {
    grid[y] = Array(numberOfColumns).fill(false)
  }

  // Mark the occupied positions in the grid
  layout.forEach(item => {
    for (let y = item.y; y < item.y + item.h; y++) {
      for (let x = item.x; x < item.x + item.w; x++) {
        const row = grid[y]
        if (row) {
          row[x] = true
        }
      }
    }
  })

  // Find the next available position
  for (let y = 0; y < grid.length; y++) {
    for (let x = 0; x < numberOfColumns; x++) {
      if (canPlaceWidget(grid, x, y, width, height, numberOfColumns)) {
        return { x, y }
      }
    }
  }

  // If no position is found, place it at the end of the grid
  return { x: 0, y: grid.length }
}
