import _ from 'lodash'
import React, { useContext, useLayoutEffect, useRef, useState } from 'react'
import { InView } from 'react-intersection-observer'
import { IconList, IconListItem } from 'sierra-client/components/common/icon-list'
import { ProgressLine } from 'sierra-client/components/common/progress-line'
import { TagsBasic } from 'sierra-client/components/common/tags-basic'
import { useGetDaysLeft, useGetFormattedTime } from 'sierra-client/core/format'
import { useTranslation } from 'sierra-client/hooks/use-translation'
import { TranslationKey } from 'sierra-client/hooks/use-translation/types'
import { selectCurrentProgramId } from 'sierra-client/state/content/selectors'
import { useSelector } from 'sierra-client/state/hooks'
import {
  DetailsBottom,
  DetailsTop,
  FixedButtons,
  FlexContainer,
  OverviewDetails,
  OverviewGrid,
  ProgressLineWrapper,
} from 'sierra-client/views/learner/components/overview/common'
import { EllipsedText } from 'sierra-client/views/learner/components/overview/ellipsed-text'
import { HeaderButton } from 'sierra-client/views/learner/home/components/start-with'
import { buildSelfPacedUrlPath } from 'sierra-client/views/self-paced/useNavigate'
import { Separator } from 'sierra-client/views/showcase/common'
import { CourseWithStatus } from 'sierra-domain/api/course-with-status'
import { TagId } from 'sierra-domain/api/nano-id'
import { StrategyPathResponse } from 'sierra-domain/api/strategy-v2'
import { Entity } from 'sierra-domain/entity'
import { assertNever } from 'sierra-domain/utils'
import { Spacer, Text, View } from 'sierra-ui/primitives'
import { spacing } from 'sierra-ui/theming'
import { getTheme } from 'sierra-ui/theming/legacy-theme'
import styled, { ThemeContext, ThemeProvider } from 'styled-components'

const PathOutline = styled.div`
  margin-bottom: ${spacing['80']};
  margin-top: ${spacing['48']};
`

const StartButton: React.FC<{
  course: CourseWithStatus
  pathId: string
  programId?: string
  actionButtonLabelKey: TranslationKey
}> = ({ course, pathId, programId, actionButtonLabelKey }) => {
  const { t } = useTranslation()

  switch (course.type) {
    case 'native:self-paced':
      return (
        <HeaderButton
          href={buildSelfPacedUrlPath({
            pathId,
            programId,
            flexibleContentId: course.id,
            skipCourseOverview: true,
          })}
        >
          {t(actionButtonLabelKey)}
        </HeaderButton>
      )
    case 'native:live':
      return null
    case 'native:course-group':
    case 'scorm:course-group':
      return (
        <HeaderButton href={`/path/${pathId}/course/${course.id}/next`}>
          {t(actionButtonLabelKey)}
        </HeaderButton>
      )
    case 'scorm':
      return (
        <HeaderButton href={`/path/${pathId}/course/${course.id}/scorm`}>
          {t(actionButtonLabelKey)}
        </HeaderButton>
      )
    case 'linkedin':
      return (
        <HeaderButton href={course.record.url} external>
          {t('manage.linked-in.launch')}
        </HeaderButton>
      )
    case 'link':
      return (
        <HeaderButton href={`/path/${pathId}/course/${course.id}`}>{t(actionButtonLabelKey)}</HeaderButton>
      )
    case 'native:event-group':
      return null
    default:
      assertNever(course)
  }
}

type PathSummaryProps = {
  children?: React.ReactNode
  pathData: Entity<StrategyPathResponse>
  inheritedSkills: TagId[]
  totalReadingTime: number
  totalReadingTimeLeft: number
}

export const PathSummary = ({
  children,
  pathData,
  inheritedSkills,
  totalReadingTime,
  totalReadingTimeLeft,
}: PathSummaryProps): JSX.Element => {
  const {
    id: pathId,
    path: { settings, description },
    courses,
    dueDate,
  } = pathData

  const { t } = useTranslation()
  const detailRef = useRef<HTMLDivElement>(null)
  const [offsetHeight, setOffsetHeight] = useState(0)
  const [negativeMargin, setNegativeMargin] = useState(0)
  const [revealButton, setRevealButton] = useState(false)
  const currentProgramId = useSelector(selectCurrentProgramId)

  const themeContext = useContext(ThemeContext)
  const pathTheme = getTheme(themeContext, settings.theme?.name ?? 'white')
  const formattedTimeLeft = useGetFormattedTime(totalReadingTimeLeft, true)
  const { daysLeft } = useGetDaysLeft(dueDate)

  const nextUpCourse = courses.find(course => course.status.passedTimestamp === undefined)

  const hasCompletedPath = !nextUpCourse

  const hasStartedLearning = courses.some(course => course.status.progressTimestamp !== undefined)

  const actionButtonLabelKey = hasCompletedPath
    ? 'path-overview.retake-path'
    : hasStartedLearning
      ? 'path-overview.resume-path'
      : 'path-overview.start-path'

  const nextUpOrFirstCourse = nextUpCourse ?? courses[0]

  const startButton =
    nextUpOrFirstCourse === undefined ? undefined : (
      <StartButton
        course={nextUpOrFirstCourse}
        pathId={pathId}
        programId={currentProgramId}
        actionButtonLabelKey={actionButtonLabelKey}
      />
    )

  useLayoutEffect(() => {
    if (typeof window === 'undefined') return

    const updateMarginAndOffset = (): void => {
      if (detailRef.current) {
        const height = detailRef.current.clientHeight / 2 + 112
        setOffsetHeight(height)
      }

      // Calulcate to see if inbetween smallest and largest hero height
      const heightSpan = _.clamp(window.innerWidth * 0.47, 576, 736)
      setNegativeMargin(heightSpan / 2 + 32)
    }

    updateMarginAndOffset()

    window.addEventListener('resize', updateMarginAndOffset)

    return () => window.removeEventListener('resize', updateMarginAndOffset)
  }, [detailRef, setOffsetHeight, setNegativeMargin])

  return (
    <>
      <OverviewGrid>
        <OverviewDetails
          ref={detailRef}
          $offsetHeight={offsetHeight}
          $negativeMargin={negativeMargin}
          $largeMobileMargin={true}
        >
          <DetailsTop>
            <Text size='regular' bold>
              {t('path-overview.details')}
            </Text>
            <ProgressLineWrapper>
              <ProgressLine $progress={1 - totalReadingTimeLeft / totalReadingTime} />
            </ProgressLineWrapper>

            <View gap='8' direction='column'>
              <IconList>
                <IconListItem
                  iconId={hasCompletedPath ? 'checkmark' : 'time'}
                  color='foreground/primary'
                  text={hasCompletedPath ? t('path-overview.path-completed') : formattedTimeLeft}
                />
              </IconList>

              {daysLeft !== undefined && !hasCompletedPath && (
                <IconListItem iconId='event--schedule' color='foreground/primary' text={daysLeft} />
              )}
            </View>

            <InView as='div' onChange={InView => setRevealButton(!InView)}>
              <FlexContainer>
                <ThemeProvider theme={pathTheme}>{startButton}</ThemeProvider>
              </FlexContainer>
            </InView>
            {inheritedSkills.length > 0 && <Separator top='none' bottom='small' />}
          </DetailsTop>
          <DetailsBottom>
            {inheritedSkills.length > 0 && (
              <>
                <Text size='regular' bold>
                  {t('course-overview.skills')}
                </Text>
                <Spacer size='xsmall' axis='vertical' />
                <TagsBasic tagIds={inheritedSkills} />
              </>
            )}
          </DetailsBottom>
        </OverviewDetails>
        <PathOutline>
          {description !== undefined && (
            <>
              <Text bold size='small' color='foreground/secondary'>
                {t('path-overview.description.title')}
              </Text>
              <EllipsedText
                text={description}
                color='foreground/primary'
                textSize='large'
                minimizeText={t('course-overview.description-show-less')}
                expandText={t('course-overview.description-show-more')}
              />
              <Spacer size='medium' phone='xlarge' />
            </>
          )}
          {children}
        </PathOutline>
      </OverviewGrid>
      <FixedButtons $reveal={revealButton}>
        <ThemeProvider theme={pathTheme}>{startButton}</ThemeProvider>
      </FixedButtons>
    </>
  )
}
