import { AccessibleOrganization } from 'sierra-domain/multi-tenancy'

export const organizationComparator = (
  first: AccessibleOrganization,
  second: AccessibleOrganization
): number => {
  if (first.isClusterParent) {
    return -1
  } else if (second.isClusterParent) {
    return 1
  }

  return first.name.localeCompare(second.name)
}
