import { useCurrentClientContext } from 'sierra-client/components/liveV2/live-provider'
import { shouldShowWarning } from 'sierra-client/components/liveV2/services/video-call-service/helpers/network-quality'

export const useShowBadNetwork = (agoraUID: string | undefined): boolean => {
  const { client } = useCurrentClientContext()
  if (agoraUID === undefined) return false
  if (client === undefined) throw new Error(`Client is required to determine remote network quality`)

  const remoteNetworkQuality = client.getRemoteNetworkQuality()
  const networkQualityForParticipant = remoteNetworkQuality[agoraUID]
  const showWarning =
    networkQualityForParticipant !== undefined &&
    shouldShowWarning(
      networkQualityForParticipant.uplinkNetworkQuality,
      networkQualityForParticipant.downlinkNetworkQuality
    )

  return showWarning
}
