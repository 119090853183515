import { Logger } from 'sierra-client/core/logging/logger'

export const sidebarMainNavigationClickedLogger = Logger.trackLoggerWithExtra<
  { navigatedTo: string },
  { navigatedTo: string }
>(
  'sidebar_main_navigation_clicked',

  input => {
    return {
      ...input,
    }
  }
)
