import _ from 'lodash'
import React, { useEffect } from 'react'
import { mergeupdateAndBroadcastAwareness, subscribeToAwareness } from 'sierra-client/collaboration/awareness'
import { collaboratorsUpdated } from 'sierra-client/state/flexible-content/actions'
import { FlexibleContentCollaborator } from 'sierra-client/state/flexible-content/types'
import { useDispatch, useSelector } from 'sierra-client/state/hooks'
import { selectUser } from 'sierra-client/state/user/user-selector'
import { CreateContentId } from 'sierra-domain/api/nano-id'
import { ScopedCreateContentId } from 'sierra-domain/collaboration/types'
import { NodeId } from 'sierra-domain/flexible-content/identifiers'
import { LightUser } from 'sierra-domain/user'
import { Awareness } from 'y-protocols/awareness'
import * as Y from 'yjs'

const useSyncAwareness = ({
  awareness,
  yDoc,
  createContentId,
}: {
  awareness: Awareness | undefined
  yDoc: Y.Doc | undefined
  createContentId: CreateContentId
}): void => {
  const dispatch = useDispatch()
  useEffect(() => {
    if (!awareness) return
    if (!yDoc) return

    let collaboratorCache: unknown = undefined
    const onChange = (states: unknown[]): void => {
      const otherClients = states.map(state => {
        const clientId: unknown = _.get(state, 'clientId')
        const isCurrentClient = clientId !== undefined && clientId !== yDoc.clientID

        return { ...(state as Record<string, unknown>), isCurrentClient }
      })

      const decodedClients = otherClients.map(data => FlexibleContentCollaborator.safeParse(data))

      const collaborators = decodedClients.flatMap(decoded => (decoded.success ? [decoded.data] : []))

      if (_.isEqual(collaboratorCache, collaborators)) return
      collaboratorCache = collaborators

      void dispatch(collaboratorsUpdated({ collaborators, createContentId }))
    }

    const { destroy } = subscribeToAwareness(awareness, { onChange })
    return destroy
  }, [awareness, yDoc, dispatch, createContentId])
}

const useBroadcastAwareness = ({
  createContentId,
  nodeId,
  yDoc,
  awareness,
}: {
  createContentId: CreateContentId
  nodeId?: NodeId
  yDoc: Y.Doc | undefined
  awareness: Awareness | undefined
}): void => {
  const user = useSelector(selectUser)
  useEffect(() => {
    if (!user) return
    if (!awareness) return
    if (!yDoc) return

    const userData: LightUser = {
      uuid: user.uuid,
      firstName: user.firstName,
      lastName: user.lastName,
      avatar: user.avatar,
      avatarColor: user.avatarColor,
      email: user.email,
    }

    const data: FlexibleContentCollaborator = {
      user: { ...userData },
      nodeId,
      createContentId,
      clientId: yDoc.clientID,
    }

    mergeupdateAndBroadcastAwareness(awareness, data)
  }, [user, awareness, yDoc, createContentId, nodeId])
}

export const SyncCreateAwareness: React.FC<{
  awareness: Awareness
  yDoc: Y.Doc
  scopedCreateContentId: ScopedCreateContentId
  nodeId: NodeId | undefined
}> = ({ awareness, yDoc, nodeId, scopedCreateContentId }) => {
  const createContentId = ScopedCreateContentId.extractId(scopedCreateContentId)
  useSyncAwareness({ awareness, yDoc, createContentId })
  useBroadcastAwareness({ awareness, yDoc, createContentId, nodeId })
  return null
}
