import { useEffect } from 'react'
import { useStableFunction } from 'sierra-client/hooks/use-stable-function'

export function useOnUnmount(unstableOnUnmount: () => void): void {
  const stableOnUnmount = useStableFunction(unstableOnUnmount)

  useEffect(() => {
    return () => {
      stableOnUnmount()
    }
  }, [stableOnUnmount])
}
