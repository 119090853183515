import { motion } from 'framer-motion'
import { FCC } from 'sierra-client/types'
import styled from 'styled-components'

export const Fading: FCC<{ delay?: number }> = ({ children, delay }) => {
  return (
    <motion.div
      variants={{
        hidden: {
          opacity: 0,
        },
        visible: {
          opacity: 1,
        },
      }}
      initial='hidden'
      animate='visible'
      exit='hidden'
      transition={{
        duration: 0.2,
        delay: delay ?? 0,
      }}
    >
      {children}
    </motion.div>
  )
}

export const Folding = styled(motion.div).attrs({
  variants: {
    open: {
      height: 'auto',
      opacity: 1,
      filter: 'blur(0px)',
      y: 0,
    },
    closed: {
      height: 0,
      opacity: 0,
      filter: 'blur(3px)',
      y: -4,
    },
  },
  initial: 'closed',
  animate: 'open',
  exit: 'closed',
  transition: {
    duration: 0.2,
  },
})`
  overflow: visible;
`

export const Sliding: FCC = ({ children }) => {
  return (
    <motion.div
      layout
      transition={{
        duration: 0.2,
      }}
    >
      {children}
    </motion.div>
  )
}
