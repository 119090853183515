import React, { useMemo } from 'react'
import { useTranslation } from 'sierra-client/hooks/use-translation'
import { Operator, setPred } from 'sierra-client/lib/filter'
import { Context, humanShowOp } from 'sierra-client/lib/filter/components/common'
import * as UI from 'sierra-client/lib/filter/ui'
import { DomainRep } from 'sierra-domain/filter/datatype/domain'
import { Filter, FilterBase, andAll, createFilter, orAll } from 'sierra-domain/filter/datatype/filter'
import { And, Or, Predicate } from 'sierra-domain/filter/datatype/pred'
import { assertNever } from 'sierra-domain/utils'
import { MenuItem } from 'sierra-ui/components'
import { SingleSelectDropdown } from 'sierra-ui/primitives/menu-dropdown'

const setOp = (f: Filter, op: Operator): Filter => {
  switch (f.type) {
    case 'filter.filter':
      return createFilter(f.domain, op, f.predicate)
    case 'filter.or':
      return orAll(f.filters)
    case 'filter.and':
      return andAll(f.filters)
    default:
      assertNever(f)
  }
}

const setPredAnd = (pred: Predicate): Predicate => {
  switch (pred.type) {
    case 'predicate.none':
      return And([])
    case 'predicate.or':
      return And(pred.values)
    default:
      return pred
  }
}

const setPredOr = (pred: Predicate): Predicate => {
  switch (pred.type) {
    case 'predicate.none':
      return Or([])
    case 'predicate.and':
      return Or(pred.values)
    default:
      return pred
  }
}

const onFilter = (f: Filter, fn: (f: FilterBase) => Filter): Filter => {
  switch (f.type) {
    case 'filter.filter':
      return fn(f)
    case 'filter.or':
    case 'filter.and':
      return f
    default:
      assertNever(f)
  }
}

/*
 * This is a temporary client side fix to switch the Predicate type based on the operator used.
 * This is because certain operators only have intuitive behavior when used with a specific Predicate type.
 * We will avoid this problem soon by having the backend declare the Predicate type for each operator.
 */
const setPredForOp = (f: Filter, op: Operator): Filter => {
  switch (op.type) {
    case 'operator.eq':
      return onFilter(f, ff => setPred(ff, setPredOr(ff.predicate)))
    case 'operator.neq':
      return onFilter(f, ff => setPred(ff, setPredAnd(ff.predicate)))
    case 'operator.is-null':
    case 'operator.not-null':
      return onFilter(f, ff => setPred(ff, { type: 'predicate.none' }))
    default:
      return f
  }
}

const OpAnchor = React.forwardRef<HTMLDivElement, { label?: string }>(({ label, ...props }, ref) => {
  return (
    <UI.Operator.Wrapper readOnly={false} ref={ref} {...props}>
      <UI.Operator.Text>{label}</UI.Operator.Text>
    </UI.Operator.Wrapper>
  )
})

type OperatorMenuItem = MenuItem<Operator['type']> & {
  operator: Operator
}

export const OperatorComponent: React.FC<{ ctx: Context; domainRep: DomainRep; operator: Operator }> =
  React.memo(({ ctx, domainRep, operator }) => {
    const { t } = useTranslation()

    const items: OperatorMenuItem[] = useMemo(
      () => [
        {
          type: 'group',
          id: 'operator.is-null',
          label: t('user-filter.operators'),
          operator: { type: 'operator.is-null' },
          menuItems: domainRep.operators.map(op => ({
            id: op.type,
            type: 'label',
            label: humanShowOp(op, t),
            operator: op,
            selected: op.type === operator.type,
          })),
        },
      ],
      [domainRep.operators, operator.type, t]
    )

    const selectedItem = items
      .flatMap(it => (it.type === 'group' ? it.menuItems : it))
      .find(it => it.id === operator.type)

    return (
      <SingleSelectDropdown
        selectedItem={selectedItem}
        menuItems={items}
        onSelect={item => ctx.update(f => setPredForOp(setOp(f, { type: item.id }), { type: item.id }))}
        renderTrigger={() => <OpAnchor label={selectedItem?.label} />}
      />
    )
  })
