import { motion } from 'framer-motion'
import { VoiceType } from 'sierra-domain/flexible-content/types'
import { Text, View } from 'sierra-ui/primitives'
import styled from 'styled-components'

type Character = {
  id: string
  name: string
  image: string
  voice: VoiceType
}
export const CHARACTERS: Array<Character> = [
  {
    id: 'James',
    name: 'James',
    image: 'https://storage.googleapis.com/sierra-static/images/scenario-avatar-james.png',
    voice: 'shimmer',
  },
  {
    id: 'Johanna',
    name: 'Johanna',
    image: 'https://storage.googleapis.com/sierra-files/bbab7b13-0faa-443a-9f35-0b99c74102f0',
    voice: 'nova',
  },
  {
    id: 'Josh',
    name: 'Josh',
    image: 'https://storage.googleapis.com/sierra-files/a8a4432e-bc0e-4329-b0ca-32a6d2aa4033',
    voice: 'onyx',
  },
  {
    id: 'May',
    name: 'May',
    image: 'https://storage.googleapis.com/sierra-files/aaa5b587-4300-4c0a-a2eb-65119d1f4599',
    voice: 'alloy',
  },
  {
    id: 'Kumail',
    name: 'Kumail',
    image: 'https://storage.googleapis.com/sierra-files/cb5b8dd3-e5b2-45bd-91af-87f9ca5b9df8',
    voice: 'echo',
  },
]

const CharacterWrapper = styled(motion.ul)`
  display: flex;
  gap: 16px;
  width: 100%;
  list-style-type: none;
  align-items: center;
  justify-content: center;
  height: 138px;
`

const CharacterCard = styled(motion.li)<{ $backgroundImage: string; selected: boolean }>`
  display: flex;
  justify-content: center;
  align-items: flex-end;
  border-radius: 16px;
  box-shadow: 0px 8px 16px 0px #00000014;
  cursor: pointer;

  background-image: url(${p => p.$backgroundImage}),
    linear-gradient(180deg, rgba(0, 0, 0, 0) 47.32%, rgba(0, 0, 0, 0.35) 100%);
  background-position: center;
  background-size: cover;

  &:hover {
    filter: opacity(1);
  }
  ${p =>
    p.selected &&
    `
outline: 6px solid #FFFFFF
`}
`

export const CharactersView: React.FC<{ selectedId?: string; onSelect: (_: Character) => void }> = ({
  selectedId = CHARACTERS[2]?.id,
  onSelect,
}) => {
  return (
    <CharacterWrapper>
      {CHARACTERS.map((character, index) => {
        const selected = selectedId === character.id
        return (
          <CharacterCard
            animate={selected ? 'selected' : 'default'}
            selected={selected}
            variants={{
              default: { rotate: index % 2 === 0 ? 2 : -2, width: 72, height: 72 },
              selected: { rotate: index % 2 === 0 ? 2 : -2, width: 138, height: 138 },
            }}
            onClick={() => {
              onSelect(character)
            }}
            key={character.id}
            $backgroundImage={`https://res.cloudinary.com/sana-ai/image/fetch/q_auto:best,f_auto,w_360,dpr_2.0,c_limit/${character.image}`}
          >
            <View hide={!selected} justifyContent='center' alignItems='center'>
              <Text size='small' bold color='elevated/background'>
                {character.name}
              </Text>
            </View>
          </CharacterCard>
        )
      })}
    </CharacterWrapper>
  )
}
