import { Duration } from 'luxon'
import { toLabel } from 'sierra-client/features/insights/display-widgets/utils'
import { getPercentage } from 'sierra-client/features/insights/display-widgets/value-utils'
import { TranslationLookup } from 'sierra-client/hooks/use-translation/types'
import { labelToString } from 'sierra-client/lib/tabular/datatype/label'
import { TableValue } from 'sierra-domain/api/insights'
import { assertNever } from 'sierra-domain/utils'
import { durationToLargestUnitString } from 'sierra-ui/missions/delphi/charts/utils'

export const getQuarterLabelFromTableValue = (
  tableValue: Extract<TableValue, { type: 'value.quarter' }>
): string => {
  if (tableValue.year === undefined || tableValue.quarter === undefined) return ''
  return `${tableValue.year} Q${tableValue.quarter}`
}

export const getMonthLabelFromTableValue = (
  tableValue: Extract<TableValue, { type: 'value.month' }>
): string => {
  if (tableValue.year === undefined || tableValue.month === undefined) return ''
  return `${tableValue.year}-${String(tableValue.month).padStart(2, '0')}`
}

export const getWeekLabelFromTableValue = (
  tableValue: Extract<TableValue, { type: 'value.week' }>
): string => {
  if (tableValue.year === undefined || tableValue.week === undefined) return ''
  return `${tableValue.year} W${String(tableValue.week).padStart(2, '0')}`
}

export const getIdFromTableValue = (tableValue: TableValue | undefined): string => {
  if (tableValue === undefined) return 'none'

  switch (tableValue.type) {
    case 'value.string':
    case 'value.boolean':
    case 'value.long':
    case 'value.double':
    case 'value.ratio':
    case 'value.progress':
    case 'value.date':
    case 'value.timestamp':
    case 'value.uuid':
    case 'value.duration':
      return tableValue.value?.toString() ?? 'none'
    case 'value.enum':
      return tableValue.value ?? tableValue.invalidValue ?? 'none'
    case 'value.course':
      return tableValue.course?.id ?? 'none'
    case 'value.user':
      return tableValue.user?.id ?? 'none'
    case 'value.group':
      return tableValue.group?.id ?? 'none'
    case 'value.program':
      return tableValue.program?.id ?? 'none'
    case 'value.path':
      return tableValue.path?.id ?? 'none'
    case 'value.card':
      return tableValue.card?.id ?? 'none'
    case 'value.exercise':
      return tableValue.exercise?.id ?? 'none'
    case 'value.poll-option':
      return tableValue.pollOption?.id ?? 'none'
    case 'value.skill':
      return tableValue.skill?.id ?? 'none'
    case 'value.skill-level':
      return tableValue.skillLevel?.id ?? 'none'
    case 'value.year':
      return tableValue.year?.toString() ?? 'none'
    case 'value.quarter':
      return getQuarterLabelFromTableValue(tableValue)
    case 'value.month':
      return getMonthLabelFromTableValue(tableValue)
    case 'value.week':
      return getWeekLabelFromTableValue(tableValue)
    case 'value.certificate':
      return tableValue.certificate?.id ?? 'none'
    case 'value.course-tag':
      return tableValue.tag?.id ?? 'none'
    case 'value.course-version':
      return tableValue.courseVersionSnapshot ?? 'none'
    case 'value.none':
      return 'none'
    default:
      assertNever(tableValue)
  }
}

export const getLabelFromTableValue = (tableValue: TableValue | undefined, t: TranslationLookup): string => {
  if (tableValue === undefined) return 'none'

  switch (tableValue.type) {
    case 'value.string':
    case 'value.long':
    case 'value.double':
    case 'value.date':
    case 'value.timestamp':
    case 'value.uuid':
      return tableValue.value?.toString() ?? ''
    case 'value.boolean': {
      switch (tableValue.value) {
        case true:
          return t('dictionary.yes')
        case false:
          return t('dictionary.no')
        default:
          return ''
      }
    }
    case 'value.ratio':
    case 'value.progress':
      return getPercentage(tableValue.value)
    case 'value.duration':
      return tableValue.value !== undefined
        ? durationToLargestUnitString(Duration.fromISO(tableValue.value))
        : '-'
    case 'value.enum':
      return tableValue.label !== undefined
        ? labelToString(toLabel(tableValue.label), t)
        : tableValue.value ?? ''
    case 'value.course':
      return tableValue.course?.title ?? ''
    case 'value.user':
      return tableValue.user?.displayName ?? ''
    case 'value.group':
      return tableValue.group?.name ?? ''
    case 'value.program':
      return tableValue.program?.name ?? ''
    case 'value.path':
      return tableValue.path?.title ?? ''
    case 'value.card':
      return tableValue.card?.title ?? ''
    case 'value.exercise':
      return tableValue.exercise?.title ?? ''
    case 'value.poll-option':
      return tableValue.pollOption?.title ?? ''
    case 'value.skill':
      return tableValue.skill?.name ?? ''
    case 'value.skill-level':
      return tableValue.skillLevel?.name ?? ''
    case 'value.year':
      return tableValue.year?.toString() ?? ''
    case 'value.quarter':
      return getQuarterLabelFromTableValue(tableValue)
    case 'value.month':
      return getMonthLabelFromTableValue(tableValue)
    case 'value.week':
      return getWeekLabelFromTableValue(tableValue)
    case 'value.certificate':
      return tableValue.certificate?.title ?? ''
    case 'value.course-tag':
      return tableValue.tag?.name ?? ''
    case 'value.course-version':
      return tableValue.isMostRecent === true ? 'Most recent' : 'Older'
    case 'value.none':
      return ''
    default:
      assertNever(tableValue)
  }
}
