import { motion } from 'framer-motion'
import { AutocompleteOption } from 'sierra-ui/components'
import { Pill } from 'sierra-ui/primitives'
import { token } from 'sierra-ui/theming'
import { focusStateSmall } from 'sierra-ui/utils/focus-ring'
import styled from 'styled-components'

export const IdentitiesListContainer = styled.div`
  gap: 0;
  background: ${token('surface/default')};
  max-height: 500px;
  overflow: auto;
`
export const SelectedIdentityPill = styled(Pill)`
  gap: 10px;

  &[data-highlighted] {
    ${focusStateSmall}
    background: ${token('surface/soft')};
  }
`

export const ListItem = styled(AutocompleteOption)`
  display: flex;
  align-items: center;
  padding: 8px;
  gap: 8px;
  border-bottom: 1px solid ${token('border/strong')};
  transition: all 150ms;
  cursor: pointer;

  &:last-child {
    border-bottom: none;
  }

  &[data-highlighted],
  &:hover {
    /* TODO: The secondary background is weak here */
    background-color: ${token('foreground/primary').opacity(0.1)};
  }
`

export const CountPill = styled.div`
  display: flex;
  padding: 0.125rem 0.375rem;
  flex-direction: column;
  align-items: flex-start;
  background: ${token('surface/soft')};
  color: ${token('foreground/secondary')};
  border-radius: 8px;
  font-size: 12px;
`

export const Notice = styled(motion.div).attrs({
  variants: {
    hidden: {
      opacity: 0,
      marginTop: 0,
      padding: '0 16px',
      transition: {
        when: 'beforeChildren',
      },
    },
    visible: {
      opacity: 1,
      marginTop: 16,
      padding: '16px 16px',
      transition: {
        delay: 0.1,
        duration: 0.17,
        when: 'afterChildren',
      },
    },
  },
  initial: 'hidden',
  animate: 'visible',
  exit: 'hidden',
})`
  background: ${token('surface/soft')};
  color: ${token('foreground/primary')};
  border-radius: 8px;

  ul {
    list-style: none;
  }
`
