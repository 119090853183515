import { motion } from 'framer-motion'
import React, { FC, useCallback, useEffect, useMemo, useState } from 'react'
import { HeaderView } from 'sierra-client/components/common/header-view'
import { HorizontalLineDivider } from 'sierra-client/components/common/horizontal-line-divider'
import { useConfirmationModalContext } from 'sierra-client/components/common/modals/confirmation-modal'
import { CardTimer } from 'sierra-client/components/liveV2/card-timer'
import { useLiveSessionContext } from 'sierra-client/components/liveV2/contexts/live-session-data'
import { FileNavigationButtons } from 'sierra-client/components/liveV2/file-navigation/file-navigation-buttons'
import {
  ButtonWrapper,
  HeaderButtonColorCSS,
  HeaderIconButton,
  HeaderItemCSS,
} from 'sierra-client/components/liveV2/header-buttons'
import { useIsFacilitatorOrLearnerLedSession } from 'sierra-client/components/liveV2/hooks/use-is-facilitator-or-learner-led-session'
import { useLeaveModal } from 'sierra-client/components/liveV2/hooks/use-leave-modal'
import { useSelectCurrentCard } from 'sierra-client/components/liveV2/hooks/use-select-current-card'
import { OptionsButton } from 'sierra-client/components/liveV2/live-layer/options-button'
import { useLiveSessionIdContext } from 'sierra-client/components/liveV2/live-session-id-provider'
import { useRightSidebarContext } from 'sierra-client/components/liveV2/live-sidebar-provider'
import {
  RecordingButtonForFacilitator,
  RecordingButtonForLearner,
} from 'sierra-client/components/liveV2/recording/recording-button'
import { ShortcutMenu } from 'sierra-client/components/shortcut-menu'
import { Logging } from 'sierra-client/core/logging'
import { useIsDebugMode } from 'sierra-client/hooks/use-is-debug-mode'
import { useHasContentKindPermission, useLiveSessionPermissions } from 'sierra-client/hooks/use-permissions'
import { useTranslation } from 'sierra-client/hooks/use-translation'
import { useIsMobile } from 'sierra-client/state/browser/selectors'
import { selectUnreadMessagesCount } from 'sierra-client/state/chat/selectors'
import { useDispatch, useSelector } from 'sierra-client/state/hooks'
import { liveSessionLocalAwarenessStateMerged } from 'sierra-client/state/live-session/actions'
import {
  selectAllSessionParticipantsIncludingMeCount,
  selectFollowMeIsEnabled,
  selectIsFacilitator,
  selectIsFollowingScroll,
  selectScreenShareParticipant,
  selectUserIdToFollow,
  selectUsersFollowingFollowMe,
  selectVideoCallMode,
} from 'sierra-client/state/live-session/selectors'
import { liveSessionSlice } from 'sierra-client/state/live-session/slice'
import { stopScreenSharing } from 'sierra-client/state/live/actions'
import { selectUserId } from 'sierra-client/state/user/user-selector'
import { useUserLegacy } from 'sierra-client/state/users/hooks'
import { getAvatarImage } from 'sierra-client/utils/avatar-img'
import { BreakoutRoomsModal } from 'sierra-client/views/liveV2/breakout-rooms'
import { AssetContext } from 'sierra-domain/asset-context'
import { ScopedChatId, ScopedLiveSessionId } from 'sierra-domain/collaboration/types'
import { Icon, RoundAvatar, Tooltip } from 'sierra-ui/components'
import { IconButton, Text, View } from 'sierra-ui/primitives'
import { IconMenu } from 'sierra-ui/primitives/menu-dropdown'
import { palette, token } from 'sierra-ui/theming'
import styled from 'styled-components'

const HeaderIconLink = styled.a`
  display: flex;
  justify-content: flex-start;
  align-items: center;
  flex-grow: 0;
  flex-wrap: nowrap;
  flex-direction: row;
  cursor: pointer;
  box-sizing: unset;
  padding: 0 0.625rem;
  ${HeaderItemCSS}
  ${HeaderButtonColorCSS}
`

const CounterWrapper = styled(motion.div)<{ highlight?: boolean }>`
  padding: 1px 8px;
  border-radius: 1rem;

  background-color: ${p =>
    p.highlight === true ? p.theme.color.redVivid : token('foreground/primary').opacity(0.15)(p)};
`

const CounterText = styled(Text).attrs({
  size: 'technical',
  bold: true,
})<{ highlight?: boolean }>`
  font-variant-numeric: tabular-nums;
  /* Hardcoded until we have a solution for "Always white"/"destructive accent" */
  color: ${p => (p.highlight === true ? palette.primitives.white : token('foreground/primary'))};
`

const FloatingPill = styled(View)``

const TimerWrapper = styled(View)<{ highlight?: boolean }>`
  z-index: 1;
  border: 0;

  & > * {
    border: 0;
    padding: 0 8px;
    height: 2.25rem;
  }
`

const RollingSummaryButton = (): JSX.Element => {
  const { toggleState, state, isOpen, close } = useRightSidebarContext()
  const liveSession = useLiveSessionContext()
  const transcriptionsEnabled = liveSession.data.transcribeSession
  const { t } = useTranslation()

  useEffect(() => {
    if (state === 'auto_summary_open' && isOpen && !transcriptionsEnabled) {
      close()
    }
  }, [close, isOpen, state, transcriptionsEnabled])

  const toggleAutoSummary = useCallback(() => {
    if (liveSession.data.transcribeSession || state === 'auto_summary_open') {
      toggleState('auto_summary_open')
    }
  }, [liveSession.data.transcribeSession, state, toggleState])

  const isButtonEnabled = useMemo(
    () => transcriptionsEnabled || (state === 'auto_summary_open' && isOpen),
    [isOpen, state, transcriptionsEnabled]
  )

  const tooltipText = useMemo(() => {
    if (transcriptionsEnabled) {
      return t('live.auto-summary')
    }
    return t('live.auto-summary-disabled.tooltip')
  }, [t, transcriptionsEnabled])

  return (
    <Tooltip title={tooltipText}>
      <ButtonWrapper
        $disabledState={!isButtonEnabled}
        disabled={!isButtonEnabled}
        onClick={toggleAutoSummary}
      >
        <Icon iconId='growth' color='currentColor' size='size-16' />
        {!liveSession.data.transcribeSession && (
          <CounterWrapper
            initial={{ opacity: 0 }}
            animate={{ opacity: 1 }}
            exit={{ opacity: 0 }}
            transition={{ duration: 0.25, ease: [0.25, 0.1, 0.25, 1] }}
          >
            <Text size='micro' bold color='currentColor'>
              {t('dictionary.off')}
            </Text>
          </CounterWrapper>
        )}
      </ButtonWrapper>
    </Tooltip>
  )
}

const LiveTranscriptionButton = (): JSX.Element | null => {
  const { toggleState } = useRightSidebarContext()
  const toggleLiveTranscription = useCallback(() => toggleState('live_transcription_open'), [toggleState])
  const { t } = useTranslation()
  const debugEnabled = useIsDebugMode()

  if (!debugEnabled) return null

  return (
    <HeaderIconButton
      iconId='growth'
      color='currentColor'
      tooltip={t('live.live-transcription')}
      onClick={toggleLiveTranscription}
    />
  )
}

const ChatButton = ({ chatId }: { chatId: ScopedChatId }): JSX.Element => {
  const { toggleState } = useRightSidebarContext()
  const unreadCount = useSelector(state => selectUnreadMessagesCount(state, chatId, 'root'))
  const toggleChat = useCallback(() => toggleState('chat_open'), [toggleState])
  const { t } = useTranslation()

  return (
    <ButtonWrapper onClick={toggleChat}>
      {unreadCount > 0 && (
        <CounterWrapper
          highlight
          initial={{ opacity: 0 }}
          animate={{ opacity: 1 }}
          exit={{ opacity: 0 }}
          transition={{ duration: 0.25, ease: [0.25, 0.1, 0.25, 1] }}
        >
          <CounterText highlight>{unreadCount}</CounterText>
        </CounterWrapper>
      )}
      <Text size='small' bold color='currentColor'>
        {t('dictionary.chat')}
      </Text>
    </ButtonWrapper>
  )
}

const OpenDebugTabButton = (): JSX.Element | null => {
  const { toggleState } = useRightSidebarContext()
  const debugEnabled = useIsDebugMode()
  const toggleDebugTab = useCallback(() => toggleState('debug_open'), [toggleState])

  if (!debugEnabled) return null

  return <HeaderIconButton tooltip='Show debug' iconId='favorite' onClick={toggleDebugTab} />
}

const ParticipantListButton = (): JSX.Element => {
  const noOfParticipants = useSelector(selectAllSessionParticipantsIncludingMeCount)
  const { toggleState } = useRightSidebarContext()
  const { t } = useTranslation()

  const toggleList = useCallback(() => toggleState('list_open'), [toggleState])

  return (
    <>
      <ButtonWrapper onClick={toggleList} id='tour-livesession-people'>
        <CounterWrapper
          initial={{ opacity: 0 }}
          animate={{ opacity: 1 }}
          exit={{ opacity: 0 }}
          transition={{ duration: 0.25, ease: [0.25, 0.1, 0.25, 1] }}
        >
          <CounterText color='LEGACY_DEFAULT_TEXT_COLOR_REPLACE_ASAP'>{noOfParticipants}</CounterText>
        </CounterWrapper>
        <Text size='small' bold color='currentColor'>
          {t('admin.author.sessions.participants')}
        </Text>
      </ButtonWrapper>
    </>
  )
}

const BreakoutButton: React.FC<{
  showBreakoutSettings: boolean
  setShowBreakoutSettings: (_: boolean) => void
}> = ({ showBreakoutSettings, setShowBreakoutSettings }) => {
  const dispatch = useDispatch()
  const videoCallMode = useSelector(selectVideoCallMode)
  const confirmationModalContext = useConfirmationModalContext()
  const { t } = useTranslation()

  const toggleBreakout = (): void => {
    if (showBreakoutSettings === false) {
      setShowBreakoutSettings(true)
    }
    if (videoCallMode === 'breakout') {
      void dispatch(liveSessionSlice.actions.stopBreakoutSession())
      setShowBreakoutSettings(false)
    }
  }

  const startSession = t('live.open-breakout-rooms')
  const stopSession = t('live.close-breakout-rooms')
  return (
    <>
      {videoCallMode === 'breakout' ? (
        <HeaderIconButton
          iconId='close--circle--filled'
          onClick={() => {
            confirmationModalContext.show({
              bodyText: t('live.breakout-room.do-you-want-to-end-the-breakout-rooms'),
              confirmLabel: t('live.close-breakout-rooms'),
              onConfirm: () => {
                toggleBreakout()
              },
            })
          }}
          tooltip={stopSession}
          color={'currentColor'}
        />
      ) : (
        <div id='tour-live-breakout'>
          <HeaderIconButton
            iconId='breakout-room'
            onClick={toggleBreakout}
            tooltip={startSession}
            color={'currentColor'}
          />
        </div>
      )}
      <ShortcutMenu.Action
        group='live'
        permission='ACCESS_LMS'
        label={{ type: 'untranslated', value: videoCallMode === 'breakout' ? stopSession : startSession }}
        iconId={videoCallMode === 'breakout' ? 'close--circle' : 'user--group'}
        run={toggleBreakout}
      />
    </>
  )
}

const LocationIcon = styled(Icon)`
  color: ${token('foreground/primary')};
`

const FollowMeIndicator = styled(motion.div)`
  border-radius: 2px;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  pointer-events: none;
  border: 0px solid ${p => p.theme.color.blueVivid};
  position: absolute;
`

const StartFollowingFollowMeButton: React.FC<{ takeOverFollowMe: () => void }> = ({ takeOverFollowMe }) => {
  const isCurrentlyFollowing = useSelector(selectIsFollowingScroll)
  const userToFollow = useSelector(selectUserIdToFollow)
  const isFacilitator = useSelector(selectIsFacilitator)

  const showTakeOverFollowMe = isFacilitator

  const user = useUserLegacy(userToFollow)
  const dispatch = useDispatch()
  const { t } = useTranslation()

  const startFollowing = useCallback((): void => {
    void dispatch(liveSessionLocalAwarenessStateMerged({ isFollowingFollowMe: true }))
  }, [dispatch])

  const stopFollowing = useCallback((): void => {
    void dispatch(liveSessionLocalAwarenessStateMerged({ isFollowingFollowMe: false }))
    void dispatch(Logging.liveSession.followMeUserCancelled())
  }, [dispatch])

  useEffect(() => {
    void dispatch(liveSessionLocalAwarenessStateMerged({ isFollowingFollowMe: true }))

    return () => {
      void dispatch(liveSessionLocalAwarenessStateMerged({ isFollowingFollowMe: false }))
    }
  }, [dispatch])

  // Cleanup to ensure the isFollowingScroll is reset
  useEffect(() => {
    if (userToFollow === undefined) {
      void dispatch(liveSessionLocalAwarenessStateMerged({ isFollowingFollowMe: false }))
    }
  }, [userToFollow, dispatch])

  return (
    <>
      {isCurrentlyFollowing && (
        <FollowMeIndicator
          initial={{ borderWidth: 0 }}
          animate={{
            borderWidth: '4px',
            transition: { ease: [0.25, 0.5, 0.25, 1], duration: 0.1, fill: 'both' },
          }}
        />
      )}

      <ButtonWrapper onClick={isCurrentlyFollowing ? stopFollowing : startFollowing}>
        <LocationIcon color='currentColor' iconId='location--current' />
        <Text color='currentColor' bold size='small'>
          {`${isCurrentlyFollowing ? t('dictionary.following') : t('dictionary.follow')}  ${user?.firstName}`}
        </Text>
        {showTakeOverFollowMe && (
          <IconMenu
            variant='transparent'
            size='small'
            iconId='chevron--down--small'
            onSelect={item => {
              switch (item.id) {
                case 'request-take-over-follow-me':
                  return takeOverFollowMe()
              }
            }}
            menuItems={[
              {
                id: 'request-take-over-follow-me',
                type: 'label',
                label: t('live.take-over-follow-me'),
              },
            ]}
          />
        )}
        {!showTakeOverFollowMe && isCurrentlyFollowing && (
          <>
            <Tooltip title={t('live.stop-following')}>
              <IconButton variant='transparent' iconId='close--circle--filled' onClick={stopFollowing} />
            </Tooltip>
          </>
        )}
      </ButtonWrapper>
    </>
  )
}

const RelativePositionContainer = styled.div`
  position: relative;
`

export const FollowMePill: React.FC = () => {
  const isFacilitator = useSelector(selectIsFacilitator)
  const userToFollow = useSelector(selectUserIdToFollow)
  const followMeIsEnabled = useSelector(selectFollowMeIsEnabled)
  const usersFollowingFollowMe = useSelector(selectUsersFollowingFollowMe)
  const dispatch = useDispatch()
  const { t } = useTranslation()

  const onStartFollowMe = (): void => {
    void dispatch(liveSessionLocalAwarenessStateMerged({ followMeEnabled: true }))

    void dispatch(Logging.liveSession.followMeEnabled())
  }

  const onStopFollowMe = (): void => {
    void dispatch(liveSessionLocalAwarenessStateMerged({ followMeEnabled: false }))
  }

  const takeOverFollowMe = (): void => {
    if (userToFollow === undefined) return

    void dispatch(
      liveSessionSlice.actions.publishControlEvent({
        userId: userToFollow,
        userAction: 'stop_follow_me',
      })
    )

    onStartFollowMe()
  }

  if (followMeIsEnabled) {
    return (
      <ButtonWrapper onClick={onStopFollowMe}>
        <Text bold size='small'>
          {t('live.count-follower', {
            count: usersFollowingFollowMe.length,
          })}
        </Text>
        <Tooltip title={t('dictionary.stop')}>
          <IconButton
            size='small'
            color={'foreground/primary'}
            variant='transparent'
            iconId='close--circle--filled'
            onClick={onStopFollowMe}
          />
        </Tooltip>
      </ButtonWrapper>
    )
  }

  if (userToFollow !== undefined) return <StartFollowingFollowMeButton takeOverFollowMe={takeOverFollowMe} />

  if (isFacilitator) {
    return (
      <RelativePositionContainer>
        <ButtonWrapper onClick={onStartFollowMe}>
          <LocationIcon color='currentColor' iconId='location--current' />
          <Text size='small' bold>
            {t('live.follow-me')}
          </Text>
        </ButtonWrapper>
      </RelativePositionContainer>
    )
  }

  return null
}

const ScreenshareTitleText = Text

const ScreensharePill: React.FC = () => {
  const isFacilitator = useSelector(selectIsFacilitator)
  const screenShareParticipant = useSelector(selectScreenShareParticipant)
  const userId = useSelector(selectUserId)
  const screenShareUser = useUserLegacy(screenShareParticipant?.userId)
  const isMe = screenShareUser !== undefined && userId === screenShareUser.uuid
  const dispatch = useDispatch()
  const { t } = useTranslation()

  const onClickStopPresenting = async (): Promise<void> => {
    if (isMe) {
      await dispatch(stopScreenSharing())
    } else if (screenShareParticipant !== undefined) {
      void dispatch(
        liveSessionSlice.actions.publishControlEvent({
          userId: screenShareParticipant.userId,
          userAction: 'stop_screen_share',
        })
      )
    }
  }

  if (screenShareParticipant === undefined || screenShareUser === undefined) return null

  return (
    <FloatingPill gap='xxsmall'>
      <RoundAvatar
        size='small'
        firstName={screenShareUser.firstName}
        lastName={screenShareUser.lastName}
        src={getAvatarImage(screenShareUser.uuid, screenShareUser.avatar)}
        color={screenShareUser.avatarColor}
        withOutline
      />
      <ScreenshareTitleText bold size='small'>
        {isMe
          ? t('live.you-are-presenting')
          : t('live.name-is-presenting', { name: screenShareUser.firstName })}
      </ScreenshareTitleText>
      {(isMe || isFacilitator) && (
        <>
          <HorizontalLineDivider marginLeft marginRight />
          <Tooltip
            title={
              isMe
                ? t('live.stop-presenting')
                : t('live.stop-name-presenting', {
                    name: screenShareUser.firstName,
                  })
            }
          >
            <IconButton
              variant='transparent'
              color='white'
              iconId='close--circle--filled'
              onClick={onClickStopPresenting}
            />
          </Tooltip>
        </>
      )}
    </FloatingPill>
  )
}

const useIsFollowMeSupported = (): boolean => {
  const currentCard = useSelectCurrentCard()

  return useMemo(
    () => currentCard?.data.type === 'general' || currentCard?.data.type === 'video',
    [currentCard?.data.type]
  )
}

const MobileHeaderCenterContent: FC = () => {
  const isFacilitatorOrLearnerLedSession = useIsFacilitatorOrLearnerLedSession()
  const followMeIsSupported = useIsFollowMeSupported()

  return (
    <>
      {followMeIsSupported && <FollowMePill />}
      {isFacilitatorOrLearnerLedSession && (
        <FloatingPill>
          <FileNavigationButtons />
        </FloatingPill>
      )}
      <TimerWrapper>
        <CardTimer />
      </TimerWrapper>
    </>
  )
}

const HeaderCenterContent: FC<{
  showBreakoutSettings: boolean
  setShowBreakoutSettings: (show: boolean) => void
}> = ({ showBreakoutSettings, setShowBreakoutSettings }): JSX.Element => {
  const liveSession = useLiveSessionContext()
  const isFacilitatorOrLearnerLedSession = useIsFacilitatorOrLearnerLedSession()
  const contentId = liveSession.data.flexibleContentId
  const card = useSelectCurrentCard()
  const { t } = useTranslation()
  const followMeIsSupported = useIsFollowMeSupported()
  const canEditContent = useHasContentKindPermission(contentId, 'EDIT_CONTENT')

  return (
    <>
      {followMeIsSupported && <FollowMePill />}
      {isFacilitatorOrLearnerLedSession ? (
        <View gap='8'>
          <BreakoutButton
            showBreakoutSettings={showBreakoutSettings}
            setShowBreakoutSettings={setShowBreakoutSettings}
          />
          <CardTimer />
          <RecordingButtonForFacilitator />
          <FileNavigationButtons />
          {/* This check should be based on write access to the content, but we don't have that yet */}
          {canEditContent && card?.id !== undefined && (
            <>
              <Tooltip title={t('live.edit-content')}>
                <HeaderIconLink
                  href={`/create/l/${contentId}/${card.id}`}
                  target='_blank'
                  rel='noopener noreferrer'
                >
                  <Icon size='size-16' iconId='edit' color='currentColor' />
                </HeaderIconLink>
              </Tooltip>
            </>
          )}
        </View>
      ) : (
        <TimerWrapper>
          <CardTimer />
        </TimerWrapper>
      )}
      <ScreensharePill />
    </>
  )
}

const HeaderRightContent: FC = (): JSX.Element => {
  const { liveSessionId } = useLiveSessionIdContext()
  const chatId = ScopedChatId.fromId(liveSessionId)
  const liveSessionNanoId12 = ScopedLiveSessionId.extractId(liveSessionId)
  const liveSessionPermission = useLiveSessionPermissions(liveSessionNanoId12)
  const hasLiveChatPermission = liveSessionPermission.has('CHAT')

  return (
    <View gap='xxsmall'>
      <OpenDebugTabButton />
      {hasLiveChatPermission && <ChatButton chatId={chatId} />}
      <ParticipantListButton />
      <RollingSummaryButton />
      <LiveTranscriptionButton />
      <OptionsButton />
      <RecordingButtonForLearner />
    </View>
  )
}

export const Header = ({ assetContext }: { assetContext: AssetContext }): JSX.Element | null => {
  const leaveModal = useLeaveModal()
  const isFacilitator = useSelector(selectIsFacilitator)
  const isMobile = useIsMobile()
  const { t } = useTranslation()
  const [showBreakoutSettings, setShowBreakoutSettings] = useState<boolean>(false)

  return (
    <>
      <HeaderView
        hideBackButton={true}
        border={isFacilitator}
        onClickBack={leaveModal.show}
        backButtonTooltip={t(leaveModal.copy.tooltip)}
        disabledHover={true}
        centerContent={
          isMobile ? (
            <MobileHeaderCenterContent />
          ) : (
            <HeaderCenterContent
              setShowBreakoutSettings={setShowBreakoutSettings}
              showBreakoutSettings={showBreakoutSettings}
            />
          )
        }
        rightContent={<HeaderRightContent />}
        assetContext={assetContext}
      />
      {showBreakoutSettings ? <BreakoutRoomsModal onClose={() => setShowBreakoutSettings(false)} /> : null}
    </>
  )
}
