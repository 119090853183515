import { createAsyncThunk } from '@reduxjs/toolkit'
import { removeSearchParams } from 'sierra-client/router/search-params'
import { BrowseCommentPayload } from 'sierra-client/state/commenting/types'
import { RootState } from 'sierra-client/state/types'
type ReduxAPI = {
  state: RootState
}

export const browseCommentThread = createAsyncThunk<BrowseCommentPayload, BrowseCommentPayload, ReduxAPI>(
  'commenting/set-browsing-thread',
  async ({ reason, comment }) => {
    const {
      id: threadId,
      contentReference: { unitId },
    } = comment

    if (unitId === undefined) {
      throw Error(`Missing unitId in comment ${threadId}`)
    }

    return {
      reason,
      comment,
    }
  }
)

export const cancelBrowseComment = createAsyncThunk<void, void, ReduxAPI>(
  'commenting/cancel-browsing',
  () => {
    removeSearchParams(['chat_message'])
  }
)
