import { createFileRoute } from '@tanstack/react-router'
import React, { useCallback, useEffect, useState } from 'react'

import { graphql } from 'sierra-client/api/graphql/gql'
import { useGraphQuery } from 'sierra-client/api/hooks/use-graphql-query'
import { SanaImage } from 'sierra-client/components/common/image'
import { useTranslation } from 'sierra-client/hooks/use-translation'
import { PageIdentifier } from 'sierra-client/layout/sana-page'
import {
  ManageDetailContent,
  ManageDetailGrid,
  ManageDetailSidebar,
} from 'sierra-client/views/manage/components/common'
import { DetailsHeader } from 'sierra-client/views/manage/components/details-header'
import { ColumnLayout } from 'sierra-client/views/manage/components/layout/column-layout'
import { CreateCalendarEventPanel } from 'sierra-client/views/manage/event-groups/create-calendar-event-panel'
import { ManageEventGroupCalendarEventsTable } from 'sierra-client/views/manage/event-groups/manage-event-group-calendar-events-table'
import { ManagePageWrapper } from 'sierra-client/views/manage/utils/manage-page-wrapper'
import { CalendarEventId, CourseId, EventGroupId } from 'sierra-domain/api/nano-id'
import { Icon } from 'sierra-ui/components'

import { convertGQLImage } from 'sierra-client/api/graphql/util/convert-gql-image'
import { useContentKindPermissions } from 'sierra-client/hooks/use-permissions'
import { usePost } from 'sierra-client/hooks/use-post'
import { useResolveAsset } from 'sierra-client/hooks/use-resolve-asset'
import { getGlobalRouter } from 'sierra-client/router'
import { LoadingPermissionsWrapper } from 'sierra-client/routes/access-denied.route'
import { useDispatch } from 'sierra-client/state/hooks'
import { fetchTagsData } from 'sierra-client/state/v2/tags-actions'
import { NewCourseSettingsModal } from 'sierra-client/views/course-settings/course-settings-modal'
import { SeatRequestCount } from 'sierra-client/views/learner/event-group/handle-event-group-enrollment-panel/common'
import { HandleEnrollmentRequests } from 'sierra-client/views/learner/event-group/handle-event-group-enrollment-panel/handle-enrollment-requests'
import { getPendingApprovalRequests } from 'sierra-client/views/learner/event-group/handle-event-group-enrollment-panel/utils'
import { ContentGroupsTable } from 'sierra-client/views/manage/components/content-groups-table'
import { DeleteCourseModal } from 'sierra-client/views/manage/courses/components/modals'
import {
  ActionsSection,
  OtherCourseDetailsSection,
  QuickSettingsSection,
} from 'sierra-client/views/manage/courses/components/sections'
import { TeamspaceDropdown } from 'sierra-client/views/manage/courses/components/teamspace-dropdown'
import { useManageCourseDetails } from 'sierra-client/views/manage/courses/use-manage-course-details'
import { EmptyProgramsSection } from 'sierra-client/views/manage/event-groups/components/empty-programs-section'
import { EventGroupAssignmentTable } from 'sierra-client/views/manage/event-groups/event-group-assignment-table/event-group-assignment-table'
import {
  EventRow,
  gqlEventLocationToEventLocation,
  gqlEventScheduleToEventSchedule,
} from 'sierra-client/views/manage/event-groups/event-utils'
import { useSoftDeleteCalendarEvent } from 'sierra-client/views/manage/event-groups/use-soft-delete-calendar-event'
import { AssetContext } from 'sierra-domain/asset-context'
import { XRealtimeAuthorDeleteCourse } from 'sierra-domain/routes'
import { Heading, Spacer, Text, View } from 'sierra-ui/primitives'
import { token } from 'sierra-ui/theming'
import styled from 'styled-components'
import { z } from 'zod'

export const Separator = styled.span`
  display: block;
  width: 100%;
  height: 0;
  border-bottom: 1px solid ${token('border/default')};
`

const getEventGroupQuery = graphql(`
  query getEventGroupWithCalendarEvents($id: CourseId!) {
    course(id: $id) {
      title
      description
      image {
        ...ImageFragment
      }
      __typename
      ... on NativeEventGroup {
        calendarEventsWithApprovalRequests {
          ...EventGroupPageDetails_eventsWithApprovalRequests
        }

        calendarEvents {
          __typename
          id
          schedule {
            ...CalendarEventScheduleFragment
          }
          location {
            ...CalendarEventLocationFragment
          }
          description
          title
          participantLimit

          assignedUsers {
            user {
              id
            }
          }

          facilitators {
            id
            displayName
          }
        }
      }
    }
  }
`)

const ManageEventGroupDetail: React.FC<{
  eventGroupId: EventGroupId
}> = ({ eventGroupId }) => {
  const { t } = useTranslation()
  const [createCalendarEventPanelOpen, setCreateCalendarEventPanelOpen] = useState(false)
  const { postWithUserErrorException } = usePost()
  const dispatch = useDispatch()
  const eventGroupPermissions = useContentKindPermissions(eventGroupId)
  const canEdit = eventGroupPermissions.has('EDIT')
  const canDelete = eventGroupPermissions.has('DELETE')

  const eventGroupCourseId = CourseId.parse(eventGroupId)

  useEffect(() => {
    void dispatch(fetchTagsData())
  }, [dispatch])

  const deleteEventGroup = useCallback(async () => {
    await postWithUserErrorException(XRealtimeAuthorDeleteCourse, {
      courseId: eventGroupCourseId,
    })

    void getGlobalRouter().navigate({ to: '/manage/in-person-events', replace: true })
  }, [postWithUserErrorException, eventGroupCourseId])

  const { courseData, courseProgramAssignments, saveVisibility, fetchCourse } =
    useManageCourseDetails(eventGroupCourseId)

  const queryResult = useGraphQuery(
    {
      document: getEventGroupQuery,
      queryOptions: {
        gcTime: 0,
        refetchOnWindowFocus: false,
      },
    },
    {
      id: eventGroupId,
    }
  )

  const softDeleteCalendarEvent = useSoftDeleteCalendarEvent()

  const deleteCalendarEvent = async (calendarEventId: CalendarEventId): Promise<void> => {
    await softDeleteCalendarEvent(calendarEventId)
    void queryResult.refetch()
  }

  const eventGroupData = queryResult.data

  const calenderEventRequests =
    eventGroupData?.course?.__typename === 'NativeEventGroup'
      ? eventGroupData.course.calendarEventsWithApprovalRequests
      : []

  const pendingCalenderEventRequests = getPendingApprovalRequests(calenderEventRequests)

  const calendarEvents =
    eventGroupData?.course?.__typename === 'NativeEventGroup' ? eventGroupData.course.calendarEvents : []

  const calendarEventData: EventRow[] = calendarEvents.map(event => ({
    data: {
      title: event.title,
      schedule: gqlEventScheduleToEventSchedule(event.schedule),
      id: event.id,
      location: gqlEventLocationToEventLocation(event.location),
      image: convertGQLImage(eventGroupData?.course?.image),
      assignedUsers: event.assignedUsers.map(({ user }) => user),
      participantLimit: event.participantLimit ?? undefined,
      facilitators: event.facilitators,
    },
  }))

  const [action, setAction] = useState<{ modal: 'delete' } | undefined>()

  const assetContext: AssetContext = {
    type: 'course',
    courseId: eventGroupCourseId,
  }
  const imageUrl = useResolveAsset({ image: courseData?.image, assetContext, size: 'default' })

  if (courseData === undefined) return null

  return (
    <ColumnLayout>
      <DetailsHeader
        backlink={{
          href: '/manage/in-person-events',
          label: 'manage.backlinks--in-person-events',
        }}
      />

      <NewCourseSettingsModal onSave={fetchCourse} />

      <DeleteCourseModal
        isOpen={action?.modal === 'delete'}
        onClose={() => setAction(undefined)}
        onDelete={async () => {
          await deleteEventGroup()
        }}
      />

      <ManageDetailGrid>
        <ManageDetailSidebar>
          <SanaImage src={imageUrl} ratio='16:9' rounded />
          <Spacer size='16' />

          <Heading size='h4' bold>
            {courseData.title}
          </Heading>
          <Spacer size='8' />
          <View>
            <Icon color={'foreground/muted'} iconId='building' />
            <Text color={'foreground/muted'}>{t('dictionary.physical-event')}</Text>
          </View>
          <Spacer size='24' />

          <ActionsSection
            courseId={eventGroupCourseId}
            courseKind={courseData.kind}
            canEdit={courseData.canEdit && canEdit}
            canEditMetadata={canEdit}
            canDelete={canDelete}
            onClickDelete={() => setAction({ modal: 'delete' })}
          />

          {calenderEventRequests.length > 0 && (
            <>
              <Spacer size='32' />

              <Separator />

              <Spacer size='32' />

              <View direction='column' gap='12'>
                <View direction='row' justifyContent='flex-start' alignItems='center' gap='8'>
                  <Text bold color='foreground/primary'>
                    {t('event-groups.seat-request-headline')}
                  </Text>
                  {pendingCalenderEventRequests.length > 0 && (
                    <SeatRequestCount count={pendingCalenderEventRequests.length} />
                  )}
                </View>

                <HandleEnrollmentRequests
                  eventsWithApprovalRequests={calenderEventRequests}
                  refetch={() => {
                    void queryResult.refetch()
                  }}
                  imageUrl={imageUrl}
                />
              </View>
            </>
          )}

          <QuickSettingsSection
            courseId={eventGroupCourseId}
            courseKind={courseData.kind}
            isVisible={courseData.isVisible}
            saveVisibility={saveVisibility}
          />

          <OtherCourseDetailsSection
            description={courseData.description}
            tags={courseData.tags}
            includedInPaths={courseData.includedInPaths}
          />
          <Spacer size='32' />
          <Separator />
          <Spacer size='32' />
          <TeamspaceDropdown courseId={eventGroupCourseId} courseType={'native:event-group'} />
        </ManageDetailSidebar>
        <ManageDetailContent>
          <ManageEventGroupCalendarEventsTable
            openCreateCalendarEventPanel={setCreateCalendarEventPanelOpen}
            events={calendarEventData}
            eventGroupId={eventGroupId}
            deleteCalendarEvent={deleteCalendarEvent}
          />

          <Spacer size='xxlarge' />

          <EventGroupAssignmentTable
            afterSubmit={() => {
              void queryResult.refetch()
            }}
            events={calendarEventData}
            courseId={eventGroupId}
            openCreateCalendarEventPanel={setCreateCalendarEventPanelOpen}
          />

          {courseProgramAssignments.length === 0 && (
            <>
              <Spacer size='xxlarge' />
              <EmptyProgramsSection />
            </>
          )}
          {courseProgramAssignments.length > 0 && (
            <>
              {/* the content groups table has built in spacing for some reason */}
              <Spacer size='medium' />
              <ContentGroupsTable assignedPrograms={courseProgramAssignments} />
            </>
          )}
        </ManageDetailContent>
        <Spacer size='48' />
      </ManageDetailGrid>
      <CreateCalendarEventPanel
        eventGroupTitle={eventGroupData?.course?.title ?? ''}
        eventGroupId={eventGroupId}
        open={createCalendarEventPanelOpen}
        onClose={() => {
          setCreateCalendarEventPanelOpen(false)
        }}
        afterSubmit={() => {
          setCreateCalendarEventPanelOpen(false)
          void queryResult.refetch()
        }}
      />
    </ColumnLayout>
  )
}

const Page: React.FC = () => {
  const eventGroupId = Route.useParams({ select: p => p.eventGroupId })
  const permissions = useContentKindPermissions(eventGroupId)

  return (
    <ManagePageWrapper pageIdentifier={PageIdentifier.ManageEventGroupDetail({ eventGroupId })}>
      <LoadingPermissionsWrapper
        isLoading={permissions.status === 'loading'}
        hasAccess={permissions.has('MANAGE')}
      >
        <ManageEventGroupDetail eventGroupId={eventGroupId} />
      </LoadingPermissionsWrapper>
    </ManagePageWrapper>
  )
}

export const Route = createFileRoute('/manage/in-person-events/$eventGroupId/')({
  component: Page,
  validateSearch: z.object({
    groupId: z.string().optional(),
  }),
  params: {
    parse: z.object({ eventGroupId: EventGroupId }).parse,
    stringify: p => p,
  },
})
