import React from 'react'
import { useTranslation } from 'sierra-client/hooks/use-translation'
import { Text } from 'sierra-ui/primitives'
import styled from 'styled-components'

const StyledText = styled(Text).attrs({ size: 'micro', color: 'foreground/muted' })``

export const UsersToNotifyText: React.FC<{ centerAligned?: boolean }> = ({ centerAligned = false }) => {
  const { t } = useTranslation()

  return (
    <StyledText align={centerAligned ? 'center' : undefined}>
      {t('homework.failed-with-no-retries.description')}
    </StyledText>
  )
}
