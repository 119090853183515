import { UseQueryResult } from '@tanstack/react-query'
import { getInsightsQueryOptions } from 'sierra-client/features/insights/api-hooks/query-options'
import { prefetchCachedQuery, useCachedQuery } from 'sierra-client/state/api'
import { InsightViews } from 'sierra-domain/api/insights'
import { XAnalyticsListViews } from 'sierra-domain/routes'

const staleTime = 5 * 60 * 1000

export const prefetchInsightsViews = (): Promise<void> => {
  return prefetchCachedQuery(XAnalyticsListViews, {}, { staleTime })
}

export const useInsightsViews = (): UseQueryResult<InsightViews> => {
  const response = useCachedQuery(
    XAnalyticsListViews,
    {},
    { ...getInsightsQueryOptions<InsightViews>(), staleTime }
  )
  return response
}
