import React from 'react'
import { useTokenValue } from 'sierra-ui/theming'

export const LineChartPreviewSVG: React.FC = () => {
  const getToken = useTokenValue()
  return (
    <svg width='100%' height='100%' viewBox='0 0 195 27' fill='none' xmlns='http://www.w3.org/2000/svg'>
      <path
        d='M1 7.12245L20.3 15.2857L38.7227 13.7551L58.9 26L77.3227 15.7959L97.5 14.2653L116.8 1L136.1 20.898L155.4 23.449L174.7 4.06122L194 12.2245'
        stroke={getToken('org/primary')}
        strokeWidth='2'
        strokeLinecap='round'
        strokeLinejoin='round'
      />
    </svg>
  )
}
