import { createContext, FC, ReactNode, useContext, useMemo } from 'react'
import { useToggle } from 'sierra-client/hooks/use-toggle'

type AlternativeContext =
  | {
      explanationOpen: boolean
      toggle: () => void
      off: () => void
      on: () => void
    }
  | undefined

const AlternativeContext = createContext<AlternativeContext>(undefined)

export const AlternativeContextProvider: FC<{
  children: ReactNode
}> = ({ children }) => {
  const [explanationOpen, { toggle, on, off }] = useToggle(false)

  const contextValue = useMemo(
    () => ({
      explanationOpen,
      toggle,
      on,
      off,
    }),
    [explanationOpen, toggle, on, off]
  )

  return <AlternativeContext.Provider value={contextValue}>{children}</AlternativeContext.Provider>
}

export const useAlternativeContext = (): AlternativeContext => {
  return useContext(AlternativeContext)
}
