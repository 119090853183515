import React from 'react'
import { HeaderButtonGroupWrapper } from 'sierra-client/components/liveV2/header-buttons'
import { FileBackgroundPicker } from 'sierra-client/views/flexible-content/card-background/file-background-picker'
import { FileThemePicker } from 'sierra-client/views/flexible-content/file-theme-picker'
import type { AssetContext } from 'sierra-domain/asset-context'
import { File } from 'sierra-domain/flexible-content/types'
import { CustomThemeName, PresetThemeName } from 'sierra-ui/theming/legacy-theme'

export const FlipCardToolbar: React.FC<{
  file: File
  previewThemes?: (theme: undefined | CustomThemeName | PresetThemeName) => void
  assetContext: AssetContext
}> = ({ file, previewThemes, assetContext }) => {
  return (
    <HeaderButtonGroupWrapper gap='none'>
      <FileThemePicker previewThemes={previewThemes} file={file} />
      <FileBackgroundPicker file={file} assetContext={assetContext} />
    </HeaderButtonGroupWrapper>
  )
}
