import _ from 'lodash'
import { Dispatch, SetStateAction, useCallback, useEffect, useMemo, useRef, useState } from 'react'
import {
  ItemType,
  SubjectType,
  UserItem,
} from 'sierra-client/components/common/modals/multi-assign-modal/types'
import { getGroupTypeFromSubjectType } from 'sierra-client/components/common/modals/multi-assign-modal/utils'
import { useDebouncedAndLiveState } from 'sierra-client/hooks/use-debounced-state'
import { usePost } from 'sierra-client/hooks/use-post'
import { ListUsersRequest, ListUsersUserRow } from 'sierra-domain/api/manage'
import { UserId } from 'sierra-domain/api/uuid'
import {
  XRealtimeAdminGroupsListGroups,
  XRealtimeAdminPathsListPathsLatest,
  XRealtimeAdminUsersListContentToUsers,
  XRealtimeAdminUsersListUsers,
} from 'sierra-domain/routes'
import { useOnChanged } from 'sierra-ui/utils'

type UseAssignUsersInput = {
  subjectType: SubjectType
  subjects: string[]
  searchQuery: string
}

type UseAssignUsersOutput = {
  isLoading: boolean
  hasMore: boolean
  availableUsers: ListUsersUserRow[]
  listItems: UserItem[]
  filterLive: UserFilter
  setFilter: Dispatch<SetStateAction<UserFilter>>
}

type UserFilter = Partial<ListUsersRequest['commonFilters']>

export const useAssignUsers = ({
  subjects,
  subjectType,
  searchQuery,
}: UseAssignUsersInput): UseAssignUsersOutput => {
  const { postWithUserErrorException } = usePost()

  const [hasMore, setHasMore] = useState(false)
  const [isLoading, setLoading] = useState(false)
  const [availableUsers, setAvailableUsers] = useState<ListUsersUserRow[]>([])
  // subjects ids
  const [subjectsCommonAssignments, setSubjectsCommonAssignments] = useState<string[]>([])

  const [filterDebounced, filterLive, setFilter] = useDebouncedAndLiveState<UserFilter>({
    lastUserId: undefined,
    query: searchQuery,
  })

  useEffect(() => {
    setFilter({ query: searchQuery, lastUserId: undefined })
  }, [searchQuery, setFilter])

  // This ref contains an id that identifies linked requests. If it changes, pending requests should abort
  // (or at least not change the state)
  const fetchIdRef = useRef<number>(0)

  const listItems = useMemo<UserItem[]>(() => {
    // const commonAssignments = _.intersectionBy(...subjectsCommonAssignments, assignment => assignment.userId)

    return availableUsers.map(
      (user): UserItem => ({
        type: 'user',
        id: user.userInfo.userId,
        title: [user.userInfo.firstName, user.userInfo.lastName].join(' '),
        firstName: user.userInfo.firstName,
        lastName: user.userInfo.lastName,
        email: user.userInfo.email,
        image: user.userInfo.avatar,
        color: user.userInfo.avatarColor,
        disabled: subjectsCommonAssignments.includes(user.userInfo.userId),
        status: user.userInfo.status,
      })
    )
  }, [availableUsers, subjectsCommonAssignments])

  const fetchSubjectAssignments = useCallback<(subjectType: SubjectType, ids: string[]) => Promise<string[]>>(
    async (subjectType, ids) => {
      if (subjectType === 'user-group') {
        const response = await postWithUserErrorException(XRealtimeAdminGroupsListGroups, {
          type: getGroupTypeFromSubjectType(subjectType),
          groupIds: ids,
        })

        return _.intersection(...response.data.flatMap(g => [g.learnerIds]))
      }

      if (subjectType === 'course') {
        const { courseToUsers } = await postWithUserErrorException(XRealtimeAdminUsersListContentToUsers, {
          courseIds: ids,
          pathIds: undefined,
          liveSessionIds: undefined,
          calendarEventIds: undefined,
        })

        return _.intersection(..._.values(courseToUsers))
      }

      if (subjectType === 'path') {
        const { pathToUsers } = await postWithUserErrorException(XRealtimeAdminUsersListContentToUsers, {
          courseIds: undefined,
          pathIds: ids,
          liveSessionIds: undefined,
          calendarEventIds: undefined,
        })

        return _.intersection(..._.values(pathToUsers))
      }

      if (subjectType === 'live-session') {
        const { liveSessionToUsers } = await postWithUserErrorException(
          XRealtimeAdminUsersListContentToUsers,
          {
            courseIds: undefined,
            pathIds: undefined,
            liveSessionIds: ids,
            calendarEventIds: undefined,
          }
        )
        return _.intersection(..._.values(liveSessionToUsers))
      }

      if (subjectType === 'calendar-event') {
        const { calendarEventToUsers } = await postWithUserErrorException(
          XRealtimeAdminUsersListContentToUsers,
          {
            courseIds: undefined,
            pathIds: undefined,
            liveSessionIds: undefined,
            calendarEventIds: ids,
          }
        )
        return _.intersection(..._.values(calendarEventToUsers))
      }

      return []
    },
    [postWithUserErrorException]
  )

  const fetchUsers = useCallback(
    async (filter: UserFilter, fetchId: number): Promise<void> => {
      setLoading(true)

      const response = await postWithUserErrorException(XRealtimeAdminUsersListUsers, {
        statusFilter: undefined,
        commonFilters: {
          requestedUserIds: undefined,
          lastUserId: undefined,
          query: undefined,
          maxResults: undefined,
          sortBy: undefined,
          groupIds: undefined,
          ...filter,
        },
      })

      if (fetchId !== fetchIdRef.current) return

      setLoading(false)
      setHasMore(response.hasMore)
      setAvailableUsers(previous => previous.concat(response.data))
    },
    [postWithUserErrorException]
  )

  // Fetch assignmnets on load
  useEffect(() => {
    const fetch = async (): Promise<void> => {
      const assignments = await fetchSubjectAssignments(subjectType, subjects)
      setSubjectsCommonAssignments(assignments)
    }
    void fetch()
  }, [fetchSubjectAssignments, subjectType, subjects])

  useOnChanged((previousFilter, currentFilter) => {
    // Don't refetch if request object values didn't change
    if (_.isEqual(previousFilter, currentFilter)) return

    if (previousFilter !== undefined) {
      const { lastUserId: prevLastUserId, ...prevRequestParameters } = previousFilter
      const { lastUserId: currLastUserId, ...currRequestParameters } = currentFilter

      // Reset the data and cancel any pending requests if any of the query parameters changed
      const parametersChanged = !_.isEqual(prevRequestParameters, currRequestParameters)

      // or if the pagination was reset
      const paginationReset = prevLastUserId !== undefined && currLastUserId === undefined

      if (parametersChanged || paginationReset) {
        setAvailableUsers([])
        fetchIdRef.current++
      }
    }

    void fetchUsers(currentFilter, fetchIdRef.current)
  }, filterDebounced)

  return {
    availableUsers,
    hasMore,
    isLoading,
    listItems,
    filterLive,
    setFilter,
  }
}

export type UseContentSubjectAssignmentsProps =
  | {
      subjectType: Exclude<SubjectType, 'user'>
      subjects: string[]
    }
  | {
      subjectType: 'user'
      subjects: UserId[]
    }

type UseContentSubjectAssignmentsData = {
  isLoading: boolean
  alreadyAssigned: Record<ItemType, string[]>
}

export const useContentSubjectAssignments = ({
  subjectType,
  subjects,
}: UseContentSubjectAssignmentsProps): UseContentSubjectAssignmentsData => {
  const { postWithUserErrorException } = usePost()
  const [isLoading, setLoading] = useState(false)
  const unmounted = useRef(false)
  const [alreadyAssigned, setAlreadyAssigned] = useState<UseContentSubjectAssignmentsData['alreadyAssigned']>(
    {
      'course': [],
      'path': [],
      'live-session': [],
      'user-group': [],
      'program': [],
      'user': [],
    }
  )

  const fetchSubjectAssignments = useCallback(async (): Promise<void> => {
    setLoading(true)

    if (subjectType === 'user') {
      const users = await postWithUserErrorException(XRealtimeAdminUsersListUsers, {
        statusFilter: undefined,
        commonFilters: {
          requestedUserIds: subjects,
          lastUserId: undefined,
          query: undefined,
          maxResults: undefined,
          sortBy: undefined,
          groupIds: undefined,
        },
      })
      if (unmounted.current) return

      const courseIds = _.intersection(...users.data.map(it => it.courseIds))
      const pathIds = _.intersection(...users.data.map(it => it.pathIds))
      const liveSessionIds = _.intersection(...users.data.map(it => it.liveSessionIds))

      setAlreadyAssigned({
        'course': courseIds,
        'path': pathIds,
        'live-session': liveSessionIds,
        'user': [],
        'user-group': [],
        'program': [],
      })
    }

    if (subjectType === 'user-group' || subjectType === 'program') {
      const { data: groups } = await postWithUserErrorException(XRealtimeAdminGroupsListGroups, {
        type: getGroupTypeFromSubjectType(subjectType),
        groupIds: subjects,
      })
      if (unmounted.current) return

      const courseIds = _.intersection(...groups.map(it => it.courseIds))
      const pathIds = _.intersection(...groups.map(it => it.pathIds))
      setAlreadyAssigned({
        'course': courseIds,
        'path': pathIds,
        'live-session': [], // @TODO liveSessionIds not yet on groups
        'user': [],
        'user-group': [],
        'program': [],
      })
    }

    if (subjectType === 'path') {
      const { paths } = await postWithUserErrorException(XRealtimeAdminPathsListPathsLatest, {})
      if (unmounted.current) return

      const pathsSubject = paths.filter(it => subjects.includes(it.pathId))

      const courseIds = _.intersection(...pathsSubject.map(it => it.courses.map(c => c.courseId)))
      setAlreadyAssigned({
        'course': courseIds,
        'path': [],
        'live-session': [], // @TODO liveSessionIds not yet on groups
        'user': [],
        'user-group': [],
        'program': [],
      })
    }
    setLoading(false)
  }, [postWithUserErrorException, subjectType, subjects])

  useEffect(() => {
    unmounted.current = false
    void fetchSubjectAssignments()
    return () => {
      unmounted.current = true
    }
  }, [fetchSubjectAssignments])

  return {
    isLoading,
    alreadyAssigned,
  }
}
