import React from 'react'
import { RouterLink } from 'sierra-client/components/common/link'
import { FCC } from 'sierra-client/types'
import { isDefined } from 'sierra-domain/utils'
import { Skeleton, View } from 'sierra-ui/primitives'
import { token } from 'sierra-ui/theming'
import { v2_breakpoint } from 'sierra-ui/theming/breakpoints'
import styled, { css } from 'styled-components'

// Exported so that we can style children when hovered...
export const ContentTableRootView = styled(View)<{ isOver?: boolean; canDrag?: boolean }>`
  padding: 0.5rem 0rem;
  margin: 0.5rem 0rem;
  position: relative;
  align-items: flex-start;
  border-radius: 0.5rem;
  transition: all 0.1s cubic-bezier(0.25, 0.1, 0.25, 1);
  cursor: ${p => (p.canDrag === true ? 'grab' : 'inherit')};

  &:hover {
    ${p =>
      p.isOver !== true &&
      css`
        background-color: ${token('surface/default')};
      `}
  }
`

const RightHalfContainer = styled(View)`
  display: flex;
  flex-direction: column;
  align-items: flex-start;

  @media (min-width: ${v2_breakpoint.desktop_small}) {
    flex-direction: row;
    justify-content: space-between;
  }
`

const ActionContainer = styled(View)`
  flex-shrink: 0;
`

const StyledLink = styled(RouterLink)`
  display: flex;
  flex-grow: 1;
  gap: 6px;
`

const LeftHalfContainer: FCC<{ href?: string; onClick?: () => void }> = ({ href, onClick, children }) => {
  if (isDefined(href)) {
    return (
      <StyledLink href={href} onClick={onClick} preloadDelay={300}>
        {children}
      </StyledLink>
    )
  }

  return (
    <View grow gap='16' alignItems='flex-start'>
      {children}
    </View>
  )
}

const ContentAndSessionSize = styled(View).attrs({
  direction: 'column',
  gap: '16',
})`
  flex: 1 1 100%;
`

const SessionListWrapper = styled.div`
  max-width: 500px;
`

export const ContentTableRow: React.FC<{
  href?: string
  onRowClick?: () => void
  thumbnail?: JSX.Element
  left?: JSX.Element
  right?: JSX.Element
  actions?: JSX.Element
  sessionList?: JSX.Element | false
  isOver?: boolean
  canDrag?: boolean
}> = ({ href, onRowClick, thumbnail, left, right, actions, sessionList, isOver, canDrag }) => {
  return (
    <ContentTableRootView isOver={isOver} canDrag={canDrag}>
      {thumbnail !== undefined && thumbnail}
      <View grow direction='column'>
        <View grow justifyContent='space-between'>
          <LeftHalfContainer href={href} onClick={onRowClick}>
            <ContentAndSessionSize>
              <View alignItems='center' gap='xsmall' grow>
                <View direction={'column'} gap='2' grow>
                  {left !== undefined && left}
                </View>
              </View>
            </ContentAndSessionSize>
          </LeftHalfContainer>
          <RightHalfContainer>
            {right !== undefined && right}
            <ActionContainer gap='xxsmall'>{actions !== undefined && actions}</ActionContainer>
          </RightHalfContainer>
        </View>
        <SessionListWrapper>{sessionList}</SessionListWrapper>
      </View>
    </ContentTableRootView>
  )
}

const ThumbnailSkeleton = styled(Skeleton)`
  width: 56px;
  height: 40px;
  min-width: 56px;
  min-height: 40px;
  border-radius: 8px;

  @media (min-width: ${v2_breakpoint.desktop_small}) {
    width: 70px;
    height: 50px;
    min-width: 70px;
    min-height: 50px;
    border-radius: 12px;
  }
`

const SkeletonWrapper = styled.div<{ opacity: number }>`
  opacity: ${p => p.opacity};
`

export const ContentTableRowSkeleton: React.FC<{ opacity?: number }> = ({ opacity = 1 } = {}) => (
  <SkeletonWrapper opacity={opacity}>
    <ContentTableRow
      thumbnail={<ThumbnailSkeleton />}
      left={
        <>
          <Skeleton $width={130} $radius={6} $height={16} />
          <Skeleton $width={100} $height={16} $radius={6} />
        </>
      }
      actions={<Skeleton $radius={6} $width={16} $height={16} />}
      right={
        <>
          <Skeleton $radius={6} $width={16} $height={16} />
        </>
      }
    />
  </SkeletonWrapper>
)
