import { useAtom } from 'jotai'
import { tableCellMultiSelectionAtom } from 'sierra-client/views/v3-author/table/multi-select/table-selection-atom'
import { UseTableCellMultiSelection } from 'sierra-client/views/v3-author/table/multi-select/types'

export const useTableCellMultiSelection = (): UseTableCellMultiSelection => {
  const [selection, setSelection] = useAtom(tableCellMultiSelectionAtom)

  const addSelection: UseTableCellMultiSelection['addSelection'] = cellId => {
    setSelection(prev => [...prev, cellId])
  }

  const removeSelection: UseTableCellMultiSelection['removeSelection'] = cellId => {
    setSelection(prev => prev.filter(id => id !== cellId))
  }

  const containsSelection: UseTableCellMultiSelection['containsSelection'] = cellId =>
    selection.includes(cellId)

  const clearSelection: UseTableCellMultiSelection['clearSelection'] = () => setSelection([])

  const getSelection: UseTableCellMultiSelection['getSelection'] = () => selection

  return {
    addSelection,
    removeSelection,
    containsSelection,
    clearSelection,
    getSelection,
  }
}
