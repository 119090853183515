import _ from 'lodash'
import { File } from 'sierra-domain/flexible-content/types'
import { assert } from 'sierra-domain/utils'
import { ColorBuilder, color } from 'sierra-ui/color'
import { ColorValue } from 'sierra-ui/color/types'

export type ScenarioFile = File & { data: { type: 'scenario' } }
export function assertScenarioCard(file: File): asserts file is ScenarioFile {
  assert(file.data.type === 'scenario')
}

export const replaceOrAdd = <T>(array: Array<T> | undefined, item: T, compF: (_: T) => boolean): Array<T> => {
  if (!array) return [item]
  const index = array.findIndex(compF)
  if (index !== -1) {
    return [...array.slice(0, index), item, ...array.slice(index + 1)]
  }
  return [...array, item]
}

function isSolidWhite(colorString: ColorValue | ColorBuilder): boolean {
  return _.isEqual(color(colorString).toRgba(), { r: 255, g: 255, b: 255, a: 1 })
}

export function getScenarioBackgroundColor(colorName: ColorValue): ColorBuilder {
  if (isSolidWhite(colorName)) {
    return color('#f3f2f5')
  } else {
    return color(colorName)
  }
}
