import _ from 'lodash'
import { useMemo } from 'react'
import { selectCardExercises } from 'sierra-client/state/card-progress/selectors'
import { useSelector } from 'sierra-client/state/hooks'
import { useQuestionShuffle } from 'sierra-client/views/flexible-content/question-shuffling/shuffle-elements-provider'
import { useSelfPacedBigContext } from 'sierra-client/views/flexible-content/self-paced-big-context'
import { filterNodesByExercises } from 'sierra-client/views/self-paced/slate-card'
import { findNode } from 'sierra-client/views/v3-author/queries'
import { CreateContentId } from 'sierra-domain/api/nano-id'
import { FileId } from 'sierra-domain/flexible-content/identifiers'
import { SlateDocument } from 'sierra-domain/v3-author'

function useCompletedPartOfDocument({
  courseId,
  fileId,
}: {
  courseId: CreateContentId
  fileId: FileId
}): SlateDocument {
  const { slateDocumentMap } = useSelfPacedBigContext()
  const document = useMemo(() => slateDocumentMap[fileId]?.nodes, [fileId, slateDocumentMap])
  const exercises = useSelector(state => selectCardExercises(state, courseId, fileId), _.isEqual)

  return useMemo(() => {
    return filterNodesByExercises(document ?? [], exercises)
  }, [document, exercises])
}

export function useFilteredDocument({
  courseId,
  fileId,
}: {
  courseId: CreateContentId
  fileId: FileId
}): SlateDocument | undefined {
  const completedPartOfDocument = useCompletedPartOfDocument({ courseId, fileId })
  const { shuffleQuestionsInDocument } = useQuestionShuffle()
  return useMemo(() => {
    return shuffleQuestionsInDocument(completedPartOfDocument)
  }, [completedPartOfDocument, shuffleQuestionsInDocument])
}

export function useFilteredDocumentContainsNodeWithId(
  courseId: CreateContentId,
  fileId: FileId,
  nodeWithId: string | undefined
): boolean {
  const document = useCompletedPartOfDocument({ courseId, fileId })
  return useMemo(() => {
    return document.some(element => findNode(element, node => node.id === nodeWithId).length > 0)
  }, [document, nodeWithId])
}
