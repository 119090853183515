import { Heading, Text, View } from 'sierra-ui/primitives'
import { token } from 'sierra-ui/theming'
import styled from 'styled-components'

export const PanelLayout = styled(View).attrs({
  direction: 'column',
  gap: 'none',
  alignItems: 'stretch',
  justifyContent: 'space-between',
})`
  flex: 1;
  position: relative;
  height: 100%;
`

export const PanelHeadline = styled(Heading).attrs({ size: 'h5', bold: true })``
export const CategoryHeadline = styled(Text).attrs({ color: 'foreground/muted', size: 'micro', bold: true })``

export const SectionHeadline = styled(Text).attrs({ bold: true })``

export const SectionInfo = styled(Text).attrs({ color: 'foreground/muted' })``

export const AutocompleteOptions = styled.div`
  gap: 0;
  background: ${token('surface/default')};
  max-height: 500px;
  overflow: auto;
`

export const AutocompleteOption = styled.div`
  width: 100%;
  position: relative;
`

export const FooterWrapper = styled(View).attrs({
  direction: 'row',
  alignItems: 'center',
  justifyContent: 'flex-end',
  paddingBottom: '32',
  paddingLeft: 'none',
  gap: '8',
})`
  position: sticky;
  bottom: 0;
  left: 0;
  width: 100%;
  z-index: 1;

  &::before {
    content: '';
    z-index: 2;
    display: block;
    position: absolute;
    top: 0;
    transform: translateY(-16px);
    left: 0;
    width: 100%;
    height: 16px;
    background: linear-gradient(
      to top,
      ${token('surface/default').opacity(1)} 30%,
      ${token('surface/default').opacity(0)}
    );
  }
`

export const ScrollGradientTop = styled(View).attrs({ direction: 'column', padding: 'none' })<{
  top: string
  height: string
}>`
  position: relative;

  &::before {
    content: '';
    display: block;
    position: absolute;
    top: ${p => p.top}px;
    left: 0;
    width: 100%;
    height: ${p => p.height}px;
    z-index: 2;
    background: linear-gradient(
      to bottom,
      ${token('surface/default').opacity(1)} 30%,
      ${token('surface/default').opacity(0)}
    );
  }
`
