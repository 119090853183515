import { FC, useEffect, useRef } from 'react'
import { loadMapsLibrary } from 'sierra-client/views/manage/event-groups/components/google-apis'
import { GooglePlaceLocation } from 'sierra-client/views/manage/event-groups/types'
import { isNonNullable } from 'sierra-domain/utils'

export const EventLocationMap: FC<{ location: GooglePlaceLocation; className?: string }> = ({
  location,
  className,
}) => {
  const ref = useRef<HTMLDivElement>(null)

  useEffect(() => {
    void loadMapsLibrary().then(library => {
      if (ref.current === null) return

      const map = new library.Map(ref.current, {
        disableDefaultUI: true,
        clickableIcons: false,
        disableDoubleClickZoom: true,
        keyboardShortcuts: false,
        gestureHandling: 'none',
        zoom: 15,
        mapId: 'event-location-map',
      })

      const service = new google.maps.places.PlacesService(map)

      service.getDetails({ placeId: location.placeId }, function (place, status) {
        if (status === google.maps.places.PlacesServiceStatus.OK) {
          if (isNonNullable(place) && isNonNullable(place.geometry?.location)) {
            map.setCenter(place.geometry.location)

            new google.maps.Marker({
              map,
              position: place.geometry.location,
            })
          }
        }
      })
    })
  }, [location.placeId])

  return <div className={className} ref={ref}></div>
}
