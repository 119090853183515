import React, { useId, useRef, useState } from 'react'
import { IconId, MenuItem } from 'sierra-ui/components'
import { IconButton } from 'sierra-ui/primitives'
import { IconButtonProps } from 'sierra-ui/primitives/icon-button/icon-button'
import { MenuDropdownPrimitive } from 'sierra-ui/primitives/menu-dropdown'
import { useOnChanged } from 'sierra-ui/utils/use-on-changed'

type IconMenuProps = {
  items: Array<MenuItem>
  iconId?: Extract<
    | 'time'
    | 'overflow-menu--vertical'
    | 'overflow-menu--horizontal'
    | 'image'
    | 'no-image'
    | 'draggable'
    | 'link'
    | 'menu'
    | 'settings'
    | 'add',
    IconId
  >
  closeOnPick?: boolean
  onClose?: () => void
  onOpen?: () => void
  withChevron?: boolean
  withSearch?: boolean
  searchPlaceholder?: string
  ariaLabel?: string
} & Omit<IconButtonProps, 'iconId' | 'onClick' | 'cursor' | 'className' | 'text'>

/**
 *
 * @deprecated
 * use primitives/menu-dropdown/icon-menu.tsx instead
 */
export const IconMenu: React.FC<IconMenuProps> = ({
  iconId = 'overflow-menu--vertical',
  items,
  closeOnPick,
  onClose,
  onOpen,
  disabled,
  withSearch,
  searchPlaceholder,
  ...props
}) => {
  const anchorRef = useRef<HTMLButtonElement>(null)
  const triggerDomId = useId()
  const menuDomId = useId()

  const [open, setOpen] = useState(false)

  useOnChanged((oldOpen, newOpen) => {
    if (oldOpen === undefined) return

    if (newOpen) {
      onOpen?.()
    } else {
      onClose?.()
    }
  }, open)

  const handleItemClick = (item: MenuItem): void => {
    if (item.preventClose === true) {
      return
    }

    if (closeOnPick === true) {
      setOpen(false)
    }
  }

  return (
    <MenuDropdownPrimitive
      id={menuDomId}
      renderTrigger={() => (
        <IconButton
          id={triggerDomId}
          variant='transparent'
          iconId={iconId}
          ref={anchorRef}
          disabled={disabled}
          aria-expanded={open}
          aria-haspopup='true'
          aria-controls={menuDomId}
          {...props}
        />
      )}
      menuItems={items}
      onSelect={handleItemClick}
      isOpen={open}
      onOpenChange={setOpen}
      modal={false}
    />
  )
}
