import { JSONContent } from '@tiptap/core'
import React from 'react'
import { useTranslation } from 'sierra-client/hooks/use-translation'
import { GradeStatus } from 'sierra-domain/api/homework'
import { HomeworkFileWithUrl, MimeType } from 'sierra-domain/homework'
import { assertNever } from 'sierra-domain/utils'
import { Icon, IconId } from 'sierra-ui/components'
import { Text, View } from 'sierra-ui/primitives'
import { token } from 'sierra-ui/theming'
import styled from 'styled-components'

export const Box = styled(View)`
  background-color: ${token('surface/default')};
  border-radius: ${p => p.theme.borderRadius['size-12']};
  border: 1px solid ${token('border/default')};
  box-shadow: 0px 4px 12px rgba(0, 0, 0, 0.04);
`

export const LightText = styled(Text).attrs({
  color: 'foreground/muted',
})``

export const ImageWrapper = styled(View)`
  width: 48px;
  min-width: 48px;
  height: 48px;
  min-height: 48px;
  background-color: ${token('surface/soft')};
  border-radius: ${p => p.theme.borderRadius['size-12']};
  align-items: center;
  justify-content: center;
  overflow: hidden;

  & img,
  & video {
    height: 100%;
    width: 100%;
    object-fit: cover;
  }
`

export const isImageMimeType = (mimeType: MimeType): boolean => {
  switch (mimeType) {
    case 'image/gif':
    case 'image/jpeg':
    case 'image/png':
      return true
    case 'application/pdf':
    case 'video/mp4':
    case 'application/zip':
    case 'application/x-zip':
    case 'application/x-zip-compressed':
    case 'x-zip-compressed':
      return false
    default:
      assertNever(mimeType)
  }
}

const IconForType: Record<MimeType, IconId> = {
  'application/pdf': 'PDF',
  'image/gif': 'image',
  'image/jpeg': 'image',
  'image/png': 'image',
  'video/mp4': 'play--filled',
  'application/zip': 'ZIP--alt',
  'application/x-zip': 'ZIP--alt',
  'application/x-zip-compressed': 'ZIP--alt',
  'x-zip-compressed': 'ZIP--alt',
}

export const HomeworkFileIcon: React.FC<{ mimeType: MimeType }> = ({ mimeType }) => {
  return <Icon iconId={IconForType[mimeType]} size='size-16' color='grey40' />
}

export const HomeworkFileIconWithImage: React.FC<{ fileInfo: HomeworkFileWithUrl }> = ({ fileInfo }) => {
  return (
    <ImageWrapper>
      {isImageMimeType(fileInfo.mimeType) ? (
        <img src={fileInfo.url} />
      ) : (
        <HomeworkFileIcon mimeType={fileInfo.mimeType} />
      )}
    </ImageWrapper>
  )
}

export const GradeText: React.FC<{ gradeStatus: GradeStatus }> = ({ gradeStatus }) => {
  const { t } = useTranslation()

  switch (gradeStatus) {
    case 'not-submitted':
      throw new Error('Status should not be possible here')
    case 'not-graded':
      return (
        <>
          <Icon size='size-14' iconId='time' color='foreground/muted' />
          <Text size='small' color='foreground/muted' bold>
            {t('homework.awaiting-review')}
          </Text>
        </>
      )
    case 'failed':
      return (
        <>
          <Icon size='size-14' iconId='close--circle' color='foreground/secondary' />
          <Text size='small' bold color='foreground/secondary'>
            {t('homework.not-passed')}
          </Text>
        </>
      )
    case 'passed':
      return (
        <>
          <Icon size='size-14' iconId='checkmark--outline' color='success/background' />
          <Text size='small' bold color='success/background'>
            {t('homework.passed')}
          </Text>
        </>
      )
    case 'dismissed':
      return (
        <>
          <Icon size='size-14' iconId='no-explanation' color='foreground/muted' />
          <Text size='small' color='foreground/muted' bold>
            {t('homework.dismissed')}
          </Text>
        </>
      )
    case 'failed-with-no-retries':
      return (
        <>
          <Icon size='size-14' iconId='close--circle' color='foreground/secondary' />
          <Text size='small' bold color='foreground/secondary'>
            {t('homework.not-passed')}
          </Text>
        </>
      )
    default:
      assertNever(gradeStatus)
  }
}

export const parseStringToJsonContent = (string: string | undefined): JSONContent | undefined => {
  if (string === undefined) return undefined
  else return JSON.parse(string) as JSONContent
}
