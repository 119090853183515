import React from 'react'
import { SerializedDOMRect } from 'sierra-domain/utils'
import { zIndex } from 'sierra-ui/theming'

type FixedRectProps = Partial<SerializedDOMRect> & {
  children?: React.ReactNode
  outline?: string
  outlineStyle?: string
  background?: string
  borderRadius?: string
  boxShadow?: string
  backdropFilter?: string
}

const MAX_Z_INDEX = Math.max(...Object.values(zIndex)) + 1

export const AbsoluteRect = React.forwardRef<HTMLDivElement, FixedRectProps>(
  (
    {
      height,
      width,
      outline,
      outlineStyle,
      background,
      children,
      borderRadius,
      boxShadow,
      backdropFilter,
      ...rect
    },
    ref
  ) => {
    return (
      <div
        ref={ref}
        // eslint-disable-next-line react/forbid-dom-props
        style={{
          position: 'absolute',
          cursor: 'pointer',
          color: 'white',
          backdropFilter,
          backgroundColor: background ?? 'transparent',
          outline: `1px solid ${outline ?? 'transparent'}`,
          outlineStyle: outlineStyle ?? 'solid',
          pointerEvents: 'none',
          ...rect,
          height: height ?? 'auto',
          width: width ?? 'auto',
          zIndex: MAX_Z_INDEX,
          borderRadius,
          boxShadow,
        }}
      >
        {children}
      </div>
    )
  }
)
