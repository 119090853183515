import { BasicUserFragmentFragment } from 'sierra-client/api/graphql/gql/graphql'
import {
  convertGQLAvatar,
  getAvatarColor,
  getAvatarUrl,
} from 'sierra-client/api/graphql/util/convert-gql-avatar'
import type { UserShape } from 'sierra-client/lib/tabular/datatype/internal/reps/user-rep'
import type { AvatarStackUserShape } from 'sierra-ui/components/avatars/avatar-stack'

type BasicUserShapeValue = Pick<
  BasicUserFragmentFragment,
  'firstName' | 'lastName' | 'displayName' | 'avatar' | 'id'
>

export const convertGQLBasicUserToUserShape = (basicUserShapeValue: BasicUserShapeValue): UserShape => {
  const avatar = convertGQLAvatar(basicUserShapeValue.avatar)

  return {
    id: basicUserShapeValue.id,
    firstName: basicUserShapeValue.firstName ?? basicUserShapeValue.displayName,
    lastName: basicUserShapeValue.lastName ?? '',
    avatar: getAvatarUrl(basicUserShapeValue.id, avatar),
    avatarColor: getAvatarColor(avatar) ?? 'pinkBright',
  }
}

export const convertGQLBasicUserToAvatarStackUserShape = (
  basicUserShapeValue: BasicUserShapeValue
): AvatarStackUserShape => {
  const user = convertGQLBasicUserToUserShape(basicUserShapeValue)

  return {
    firstName: user.firstName ?? '-',
    lastName: user.lastName ?? '-',
    avatarColor: user.avatarColor ?? 'pinkBright',
    avatar: user.avatar,
    uuid: user.id,
  }
}
