import type {
  AREAS,
  DeviceInfo,
  IAgoraRTC,
  ICameraVideoTrack,
  ILocalAudioTrack,
  ILocalTrack,
  ILocalVideoTrack,
  IMicrophoneAudioTrack,
  IRemoteAudioTrack,
  IRemoteVideoTrack,
} from 'agora-rtc-sdk-ng'
import { debounce, isEqual } from 'lodash'
import { browserVersion, isChrome, isDesktop } from 'react-device-detect'
import { canAccessBrowserApi } from 'sierra-client/components/browser/browser-permissions'
import { AgoraClient } from 'sierra-client/components/liveV2/services/video-call-service/agora-client'
import { AGORA_CONFIG } from 'sierra-client/components/liveV2/services/video-call-service/helpers/agora-config'
import {
  isDenoiserSupported,
  isVirtualBackgroundSupported,
} from 'sierra-client/components/liveV2/services/video-call-service/helpers/browser-support'
import {
  getCameraConfig,
  getLowCameraConfig,
  getMicrophoneConfig,
  getScreenVideoConfig,
} from 'sierra-client/components/liveV2/services/video-call-service/helpers/encoder-config'
import {
  AgoraRTCError,
  CameraError,
  DenoiserError,
  MicrophoneError,
  ScreenShareError,
  UnknownError,
  VideoCallServiceError,
  VirtualBackgroundError,
} from 'sierra-client/components/liveV2/services/video-call-service/helpers/errors'
import {
  MyAudioProcessor,
  loadAudioDenoiser,
} from 'sierra-client/components/liveV2/services/video-call-service/helpers/load-audio-denoiser'
import {
  MyVirtualBackgroundProcessor,
  loadVirtualBackground,
} from 'sierra-client/components/liveV2/services/video-call-service/helpers/load-virtual-background'
import { connectionIsHealthy } from 'sierra-client/components/liveV2/services/video-call-service/helpers/network-quality'
import {
  CallMode,
  CallState,
  CameraResolution,
  DevicePermissionState,
  MediaDevice,
  TrackState,
  VideoSubscribeConfig,
} from 'sierra-client/components/liveV2/services/video-call-service/types'
import { TypedEventEmitter } from 'sierra-client/lib/typed-event-emitter'
import { logger } from 'sierra-client/logger/logger'
import { GeofenceAreas } from 'sierra-domain/api/private'
import { getLiveChannelName } from 'sierra-domain/live/get-live-channel-name'

// Taken from https://docs.agora.io/en/Video/API%20Reference/web_ng/globals.html#videoencoderconfigurationpreset
// const AGORA_SCREENSHARE_PRESETS = {
//   '720p_1': {
//     width: 1280,
//     height: 720,
//     frameRate: 5,
//   },
//   '480p_1': {
//     width: 640,
//     height: 480,
//     frameRate: 5,
//   },
// } as const

// https://w3c.github.io/mediacapture-region/#browser-capture-media-stream-track
type BrowserCaptureMediaStreamTrack = {
  cropTo: (cropTarget: object | null) => Promise<undefined>
} & MediaStreamTrack

type ExtendedVideoTrackConstraints = MediaTrackConstraints & {
  displaySurface: 'browser' | 'window' | 'monitor'
}

type ExtendedAudioTrackConstraints = MediaTrackConstraints & {
  echoCancellation?: boolean
  suppressLocalAudioPlayback?: boolean
}

type ExtendedDisplayMediaStreamConstraints = MediaStreamConstraints & {
  /**
   * When enabling preferCurrentTab, Chrome supresses the audio from the tab to be heard in the speakers.
   * This makes it unusable since you can't hear other participants.
   * When this ticket is solved, we can enable this feature.
   * https://bugs.chromium.org/p/chromium/issues/detail?id=1317964
   */
  preferCurrentTab?: boolean
  surfaceSwitching?: 'include' | 'exclude'
  selfBrowserSurface?: 'include' | 'exclude'
  systemAudio?: 'include' | 'exclude'
  video?: boolean | ExtendedVideoTrackConstraints | undefined
  audio?: boolean | ExtendedAudioTrackConstraints | undefined
}

type ExtendedCallState = CallState & {
  transcription: {
    transcriptionToken?: string
  }
}

export const AGORA_REGIONS = {
  ASIA: 'Asia, excluding Mainland China',
  CHINA: 'China',
  EUROPE: 'Europe',
  INDIA: 'India',
  JAPAN: 'Japan',
  NORTH_AMERICA: 'North America',
  SOUTH_AMERICA: 'South America',
} as const

export type Tracks = {
  localNonPublishedAudioTrack?: IMicrophoneAudioTrack
  localAudioTrack?: IMicrophoneAudioTrack
  localVideoTrack?: ICameraVideoTrack
}

type ScreenShareTracks = [ILocalVideoTrack] | [ILocalVideoTrack, ILocalAudioTrack]

type VideoCallServiceConstructorProps = {
  sdk: IAgoraRTC
}

export type ScreenRecordStartedEvent = (args: {
  stream: MediaStream
  recordingId: string
  recordingChannel: string
}) => void

export type ScreenRecordEndedEvent = (args: { recordingId: string }) => void

type VideoCallServiceEvents = {
  'error': (error: VideoCallServiceError) => void
  'device-switched': (args: { type: 'camera' | 'microphone'; newDeviceName: string }) => void
  'devices-updated': () => void
  'track-changed': (args: { type: 'audio' | 'video' }) => void
  'audio-state-changed': () => void
  'video-state-changed': () => void
  'screen-share-started': () => void
  'screen-share-published': () => void
  'screen-share-ended': () => void
  'screen-record-started': ScreenRecordStartedEvent
  'screen-record-ended': ScreenRecordEndedEvent
  'call-mode-changed': () => void
  'on-stage': () => void
  'off-stage': () => void
  'network-quality-changed': () => void
  'subscribe-video-quality-changed': () => void
  'participants-changed': () => void
  'state-changed': () => void
  'connection-state-changed': () => void
}

const getTrackState = (track: ICameraVideoTrack | IMicrophoneAudioTrack | undefined): TrackState => {
  if (track === undefined) {
    return 'unavailable'
  } else if (!track.enabled) {
    return 'disabled'
  } else if (track.muted) {
    return 'muted'
  } else {
    return 'on'
  }
}

const isValidMicrophone = (microphone: DeviceInfo): boolean => {
  const invalidMicrophones = [
    'Microsoft Teams Audio',
    'mmhmm Audio (Virtual)',
    'ZoomAudioDevice (Virtual)',
    'iPhone',
  ]
  return invalidMicrophones.find(mic => microphone.device.label.includes(mic)) === undefined
}

const delayedLog = (message: string, delay: number = 1000): (() => void) => {
  const timeout = setTimeout(() => {
    logger.info(`delayedLog triggered after ${delay}ms: ${message}`)
  }, delay)

  const cancelDelayedLog = (): void => {
    clearTimeout(timeout)
  }
  return cancelDelayedLog
}

export type VideoCallServiceSetupProps = {
  forceCloudProxy: boolean
  tenantId: string
  geofenceAreas?: GeofenceAreas[]
  useExperimentalStatistics: boolean
  useSlowJoinProxy: boolean
}

export class VideoCallService extends TypedEventEmitter<VideoCallServiceEvents> {
  sdk: IAgoraRTC

  tracks: Tracks
  errors: VideoCallServiceError[] = []
  private screenShareTracks: ScreenShareTracks | undefined
  private screenRecordMediaStream: MediaStream | undefined
  private screenRecordVideoTrack: ILocalVideoTrack | undefined
  private cameraResolution: CameraResolution | undefined
  private screenRecordAudioTrack: ILocalAudioTrack | undefined
  private screenRecordingId: string | undefined
  private callMode: CallMode = { state: 'not-joined' }
  private autoSwitchDevices: boolean = true
  private microphonePermission: DevicePermissionState = 'not-requested'
  private cameraPermission: DevicePermissionState = 'not-requested'
  private config: VideoCallServiceSetupProps | undefined

  private isOnStage: boolean = false

  /**
   * Cached connection clients, use the getter getScreenRecordClient, getScreenShareClient or getClient instead
   */
  private __screenRecordClient: Promise<AgoraClient> | undefined
  private __screenShareClient: Promise<AgoraClient> | undefined
  private __client: Promise<AgoraClient> | undefined

  /**
   * Proccessor plugins are initialized when they are enabled
   */
  private noiseCancellationProcessor: MyAudioProcessor | undefined
  private backgroundBlurProcessor: MyVirtualBackgroundProcessor | undefined

  private constructor({ sdk }: VideoCallServiceConstructorProps) {
    super()
    this.tracks = {}
    this.sdk = sdk
  }

  setup = async (config: VideoCallServiceSetupProps): Promise<void> => {
    this.config = config
    this.sdk.on('camera-changed', this.onCamerasChanged)
    this.sdk.on('microphone-changed', this.onMicophonesChanged)

    // TODO: Reset the areas somehow? set the region to global if no reagion is set?
    if (config.geofenceAreas) {
      this.sdk.setArea(config.geofenceAreas as AREAS[])
    }

    try {
      // eslint-disable-next-line @typescript-eslint/no-explicit-any
      const sdk: any = this.sdk
      sdk.setParameter('NEW_ICE_RESTART', true)
    } catch (error) {
      logger.warn('Failed to set NEW_ICE_RESTART flag', { error })
    }

    await this.updateDevices()
    await this.setupCameraTrack()
    await this.setupMicrophoneTrack()
  }

  teardown = async (): Promise<void> => {
    await this.leaveCall()

    this.sdk.off('camera-changed', this.onCamerasChanged)
    this.sdk.off('microphone-changed', this.onMicophonesChanged)

    this.tracks.localAudioTrack?.close()
    this.tracks.localVideoTrack?.close()
    this.tracks.localNonPublishedAudioTrack?.close()

    if (this.noiseCancellationProcessor) {
      this.noiseCancellationProcessor.onoverload = undefined
      this.noiseCancellationProcessor = undefined
    }

    if (this.backgroundBlurProcessor) {
      this.backgroundBlurProcessor.onoverload = undefined
      this.backgroundBlurProcessor = undefined
    }
    this.config = undefined
  }

  get audioState(): TrackState {
    return getTrackState(this.tracks.localAudioTrack)
  }

  get videoState(): TrackState {
    return getTrackState(this.tracks.localVideoTrack)
  }

  get noiseCancellationEnabled(): boolean {
    return this.noiseCancellationProcessor?.enabled === true
  }

  get backgroundBlurEnabled(): boolean {
    return this.backgroundBlurProcessor?.enabled === true
  }

  private getScreenRecordClient(): Promise<AgoraClient> {
    if (this.__screenRecordClient) return this.__screenRecordClient

    // TODO: do this better
    if (this.config === undefined)
      throw new Error('Setup config need to be set before a call client can be created')

    this.__screenRecordClient = AgoraClient.createWrappedClient(this.sdk, {
      proxy: this.config.forceCloudProxy,
      useExperimentalStatistics: this.config.useExperimentalStatistics,
      // The recording service agora has doesn't support VP9
      useVp9: false,
      useSlowJoinProxy: this.config.useSlowJoinProxy,
    })
    return this.__screenRecordClient
  }

  private getScreenShareClient(): Promise<AgoraClient> {
    if (this.__screenShareClient) return this.__screenShareClient

    // TODO: do this better
    if (this.config === undefined)
      throw new Error('Setup config need to be set before a call client can be created')

    this.__screenShareClient = AgoraClient.createWrappedClient(this.sdk, {
      proxy: this.config.forceCloudProxy,
      useExperimentalStatistics: this.config.useExperimentalStatistics,
      useVp9: true,
      useSlowJoinProxy: this.config.useSlowJoinProxy,
    })
    return this.__screenShareClient
  }

  private getClient(): Promise<AgoraClient> {
    if (this.__client) return this.__client

    // TODO: do this better
    if (this.config === undefined)
      throw new Error('Setup config need to be set before a call client can be created')

    const lowStreamParameter = getLowCameraConfig()

    this.__client = AgoraClient.createWrappedClient(this.sdk, {
      proxy: this.config.forceCloudProxy,
      onParticipantsChanged: () => this.emit('participants-changed'),
      onNetworkQualityChanged: () => this.emit('network-quality-changed'),
      onConnectionStateChanged: () => this.emit('connection-state-changed'),
      lowStreamParameter,
      useExperimentalStatistics: this.config.useExperimentalStatistics,
      useVp9: true,
      useSlowJoinProxy: this.config.useSlowJoinProxy,
    })
    return this.__client
  }

  private async createScreenShareTracks(): Promise<ScreenShareTracks> {
    const tracks = await this.sdk.createScreenVideoTrack(getScreenVideoConfig(), 'auto')
    if (Array.isArray(tracks)) return tracks
    return [tracks]
  }

  getCallModeState = (): CallMode['state'] => {
    return this.callMode.state
  }

  getCurrentCallState = async (): Promise<ExtendedCallState> => {
    const [
      screenRecordClient,
      screenShareClient,
      client,
      currentCamera,
      currentMicrophone,
      cameras,
      microphones,
    ] = await Promise.all([
      this.getScreenRecordClient(),
      this.getScreenShareClient(),
      this.getClient(),
      this.getCurrentCamera(),
      this.getCurrentMicrophone(),
      this.cameraPermission === 'allowed' ? this.getCameras() : undefined,
      this.microphonePermission === 'allowed' ? this.getMicrophones() : undefined,
    ])

    const data = {
      noiseCancellationEnabled: this.noiseCancellationEnabled,
      backgroundBlurEnabled: this.backgroundBlurEnabled,
      forceCloudProxy: this.config?.forceCloudProxy ?? false,
      geofenceAreas: this.config?.geofenceAreas,
      audio: {
        state: this.audioState,
        trackLabel: this.tracks.localAudioTrack?.getTrackLabel(),
        trackId: this.tracks.localAudioTrack?.getTrackId(),
        deviceId: currentMicrophone?.deviceId,
      },
      video: {
        state: this.videoState,
        trackLabel: this.tracks.localVideoTrack?.getTrackLabel(),
        trackId: this.tracks.localVideoTrack?.getTrackId(),
        deviceId: currentCamera?.deviceId,
        resolution: this.cameraResolution,
      },
      screenShare: {
        connectionState: screenShareClient.connectionState,
        enabled: screenShareClient.isPublishing && connectionIsHealthy(screenShareClient.connectionState),
        clientId: screenShareClient.clientId,
      },
      screenRecord: {
        connectionState: screenRecordClient.connectionState,
        enabled: screenRecordClient.isPublishing && connectionIsHealthy(screenRecordClient.connectionState),
        clientId: screenRecordClient.clientId,
      },
      call: {
        callMode: this.callMode,
        clientConnected: client.isConnected,
        connectionState: client.connectionState,
        joinState: client.joinState,
        channelName: client.channelName,
        clientId: client.clientId,
        isPublishing: client.isPublishing,
        isPublishingAudio: client.isPublishingAudio,
        isPublishingVideo: client.isPublishingVideo,
        role: client.role,
        remoteParticipants: client.getRemoteUsers(),
        networkQualityStats: client.getNetworkQualityStats(),
        videoSubscribeConfig: client.getVideoSubscribeConfig(),
        isUsingProxy: client.getIsUsingProxy(),
      },
      devices: {
        cameraPermission: this.cameraPermission,
        cameras,
        microphonePermission: this.microphonePermission,
        microphones,
      },
      transcription: {
        transcriptionToken: client.getTranscriptionToken(),
      },
    }

    return data
  }

  override emit: TypedEventEmitter<VideoCallServiceEvents>['emit'] = (event, ...args) => {
    super.emit(event, ...args)
    if (event !== 'state-changed') this.emitStateChanged()
  }

  // This happens very often so let's reduce the spamminess of it a bit
  private emitStateChanged = debounce(() => super.emit('state-changed'), 100, {
    leading: true,
    trailing: true,
    maxWait: 500,
  })

  /**
   * This is needed as we try to migrate the old streaming control setup to the new one
   * But this wrapped client should not be used outside of this class
   *
   * @deprecated
   * @returns
   */
  getWrappedClient = async (): Promise<AgoraClient> => {
    const client = await this.getClient()
    return client
  }

  /**
   * This will use a cached version of the media devices unless forceUpdate is true
   * Calling this may trigger a permission check
   */
  private _cachedCameras: MediaDevice[] | undefined
  getCameras = async (forceUpdate: boolean = false): Promise<MediaDevice[]> => {
    if (forceUpdate || this._cachedCameras === undefined) {
      const cameras = await this.sdk.getCameras()
      const newCameras = cameras.map(camera => ({ deviceId: camera.deviceId, label: camera.label }))

      if (!isEqual(this._cachedCameras, newCameras)) {
        this._cachedCameras = newCameras
        this.emit('devices-updated')
      }
    }

    return this._cachedCameras ?? []
  }

  /**
   * This will use a cached version of the media devices unless forceUpdate is true
   * Calling this may trigger a permission check
   */
  private _cachedMicrophones: MediaDevice[] | undefined
  getMicrophones = async (forceUpdate: boolean = false): Promise<MediaDevice[]> => {
    if (forceUpdate || this._cachedMicrophones === undefined) {
      const microphones = await this.sdk.getMicrophones()
      const newMicrophones = microphones.map(mic => ({ deviceId: mic.deviceId, label: mic.label }))

      if (!isEqual(this._cachedMicrophones, newMicrophones)) {
        this._cachedMicrophones = newMicrophones
        this.emit('devices-updated')
      }
    }

    return this._cachedMicrophones ?? []
  }

  normalizeTrackLabel = (trackLabel?: string): string | undefined =>
    trackLabel !== undefined ? trackLabel.replace('Default - ', '') : undefined

  getCurrentCamera = async (): Promise<MediaDevice | undefined> => {
    if (this.tracks.localVideoTrack === undefined) return
    const camera = (await this.getCameras()).find(
      camera =>
        this.normalizeTrackLabel(camera.label) ===
        this.normalizeTrackLabel(this.tracks.localVideoTrack?.getTrackLabel())
    )

    if (camera) return camera

    // If the current camera is missing, force an update to the list of cameras
    return (await this.getCameras(true)).find(
      camera =>
        this.normalizeTrackLabel(camera.label) ===
        this.normalizeTrackLabel(this.tracks.localVideoTrack?.getTrackLabel())
    )
  }

  getDevicePermissions = (): {
    hasCameraPermission: DevicePermissionState
    hasMicrophonePermission: DevicePermissionState
  } => ({
    hasCameraPermission: this.cameraPermission,
    hasMicrophonePermission: this.microphonePermission,
  })

  getCurrentMicrophone = async (): Promise<MediaDevice | undefined> => {
    if (this.tracks.localAudioTrack === undefined) return
    const microphone = (await this.getMicrophones()).find(
      mic =>
        this.normalizeTrackLabel(mic.label) ===
        this.normalizeTrackLabel(this.tracks.localAudioTrack?.getTrackLabel())
    )

    if (microphone) return microphone

    // If the microphone camera is missing, force an update to the list of cameras
    const res = (await this.getMicrophones(true)).find(
      mic =>
        this.normalizeTrackLabel(mic.label) ===
        this.normalizeTrackLabel(this.tracks.localAudioTrack?.getTrackLabel())
    )

    return res
  }

  getRemoteUserVideoTrack = async (userId: string): Promise<IRemoteVideoTrack | undefined> => {
    const client = await this.getClient()
    return client.getRemoteUserVideoTrack(userId)
  }

  getRemoteUserAudioTrack = async (userId: string): Promise<IRemoteAudioTrack | undefined> => {
    const client = await this.getClient()
    return client.getRemoteUserAudioTrack(userId)
  }

  /**
   * A speaker is a local or remote user that has an audio track that is not muted
   * Their volume might be 0, but they are still considered a speaker as long as they are not muted
   */
  getCurrentSpeakers = async (): Promise<{ id: string; volume: number }[]> => {
    const client = await this.getClient()
    if (client.clientId === undefined) return []

    const remoteVolumes = client.getRemoteUserVolumes()
    const localAudioTrack = this.tracks.localAudioTrack

    const allVolumes =
      localAudioTrack?.muted === false
        ? remoteVolumes.concat({
            id: client.clientId,
            volume: localAudioTrack.getVolumeLevel(),
          })
        : remoteVolumes

    return allVolumes
  }

  setAutoSwitchDevices = (shouldAutoSwitch: boolean): void => {
    this.autoSwitchDevices = shouldAutoSwitch
  }

  enableEchoCancellerOnMedia = (element: HTMLMediaElement): void => {
    try {
      this.sdk.processExternalMediaAEC(element)
    } catch (error) {
      logger.warn('Failed to enable echo canceller on media', { error })
    }
  }

  muteAudio = async (): Promise<void> => {
    await this.tracks.localAudioTrack?.setMuted(true)
    this.emit('audio-state-changed')
  }

  unmuteAudio = async (): Promise<void> => {
    const cancelDelayedLog = delayedLog(
      'this.tracks.localAudioTrack.setMuted(false) was never processed',
      60000
    )
    await this.tracks.localAudioTrack?.setMuted(false)

    cancelDelayedLog()
    this.emit('audio-state-changed')
    if (this.isOnStage) {
      await this.publishTracks()
    }
  }

  enableVideo = async (): Promise<void> => {
    await this.tracks.localVideoTrack?.setEnabled(true)
    if (this.isOnStage) {
      await this.publishTracks()
    }
    this.emit('video-state-changed')
  }

  disableVideo = async (): Promise<void> => {
    await this.tracks.localVideoTrack?.setEnabled(false)
    this.emit('video-state-changed')
  }

  async joinCall(channelId: string): Promise<void> {
    if (!this.config) throw Error('Call setup before trying to join a call')

    const client = await this.getClient()
    const channelName = getLiveChannelName(this.config.tenantId, channelId)
    await client.join(channelName)
    this.callMode = { state: 'in-main-channel', mainChannelId: channelId }
    this.emit('call-mode-changed')
  }

  async goOnStage(): Promise<void> {
    // TODO: do this better
    // Perhaps this can be something like a "desired" state to be in,
    // and we start publishing immediately if the call becomes connected
    if (this.callMode.state === 'not-joined') {
      throw new Error('Cannot go on stage if not joined')
    }

    const client = await this.getClient()
    const remoteUsers = client.getRemoteUsers()

    if (remoteUsers.length >= 17) {
      logger.warn(`Trying to publish, already ${remoteUsers.length} hosts`)
    }

    await this.publishTracks()
    this.isOnStage = true
    this.emit('on-stage')
  }

  async goOffStage(): Promise<void> {
    // TODO: do this better
    if (this.callMode.state === 'not-joined') return

    logger.debug('Going off stage')

    const client = await this.getClient()
    await client.unpublish()

    this.isOnStage = false
    this.emit('off-stage')
  }

  async joinBreakoutRoom(room: string): Promise<void> {
    if (this.callMode.state === 'not-joined') throw new Error(`Can't join breakout room when not in a call`)
    if (!this.config) throw Error('Call setup before trying to join a breakout room')

    const mainChannelId = this.callMode.mainChannelId
    const breakoutRoomChannel = getLiveChannelName(this.config.tenantId, mainChannelId, room)
    const client = await this.getClient()
    logger.debug(`[joinBreakoutRoom] Joining breakout room`, { breakoutRoomChannel })
    await client.join(breakoutRoomChannel)
    logger.debug(`[joinBreakoutRoom] Joining breakout room done`, { breakoutRoomChannel })
    this.callMode = { state: 'in-breakout-room', mainChannelId, breakoutRoom: room }
    this.emit('call-mode-changed')
  }

  async leaveBreakoutRoom(): Promise<void> {
    if (this.callMode.state !== 'in-breakout-room') {
      logger.warn(`Can't leave breakout room when not in a breakout room`)
      return
    }

    return this.joinCall(this.callMode.mainChannelId)
  }

  async leaveCall(): Promise<void> {
    const client = await this.getClient()
    await client.leave()

    await this.stopScreenSharing()
    this.stopScreenRecording()

    this.callMode = { state: 'not-joined' }
    this.emit('call-mode-changed')
  }

  // TODO: there is a race condition where the screen share might show up as a regular user, before the live session state syncs
  async startScreenSharing(): Promise<void> {
    const client = await this.getClient()

    if (this.callMode.state === 'not-joined' || client.channelName === undefined)
      throw new Error(`Can't screen share when not in a call`)

    const channelName = client.channelName

    await this.screenShareMediaErrorHandler(async () => {
      const screenShareClient = await this.getScreenShareClient()
      if (screenShareClient.isPublishing) {
        await this.stopScreenSharing()
      }

      this.screenShareTracks = await this.createScreenShareTracks()
      await screenShareClient.join(channelName)

      // there is a race condition where the screen share might show up as a regular user, before the live session state syncs
      // to mittigate this we publish the `screen-share-started` before we publish the tracks, this should give a little bit extra time
      // and mirrors the behavior before moving screen share to this service
      this.emit('screen-share-started')

      await screenShareClient.publish(this.screenShareTracks)

      this.emit('screen-share-published')

      const stopScreenShareNonPromise = (): void => {
        this.stopScreenSharing().catch(logger.captureWarning)
      }

      for (const track of this.screenShareTracks) {
        track.once('track-ended', stopScreenShareNonPromise)
        track.once('closed', stopScreenShareNonPromise)
      }
    })
  }

  async stopScreenSharing(): Promise<void> {
    const client = await this.getScreenShareClient()
    await client.leave()
    this.emit('screen-share-ended')

    if (this.screenShareTracks !== undefined) {
      const tracks = this.screenShareTracks
      this.screenShareTracks = undefined

      for (const track of tracks) {
        track.close()
        track.stop()
        track.removeAllListeners()
      }
    }
  }

  async startScreenRecording({
    cropTarget,
    recordingId,
    recordingChannel,
    useUserId,
  }: {
    cropTarget?: object
    recordingId: string
    recordingChannel: string
    useUserId: number
  }): Promise<void> {
    const client = await this.getClient()
    const { channelName } = client
    if (this.callMode.state === 'not-joined' || channelName === undefined)
      throw new Error(`Screen recording: Can not record when not in a call`)

    const supportsSuppressLocalAudioPlayback = isDesktop && isChrome && Number(browserVersion) >= 109
    const videoTrackConstraints: ExtendedVideoTrackConstraints = {
      displaySurface: 'browser',
      width: { max: 1920 },
      height: { max: 1080 },
    }
    const displayMediaStreamConstraints: ExtendedDisplayMediaStreamConstraints = {
      video: videoTrackConstraints,
      audio: supportsSuppressLocalAudioPlayback
        ? { echoCancellation: false, suppressLocalAudioPlayback: false }
        : { echoCancellation: false },
      preferCurrentTab: supportsSuppressLocalAudioPlayback ? true : false,
      surfaceSwitching: 'exclude',
      selfBrowserSurface: 'include',
      systemAudio: 'include',
    }

    await this.screenShareMediaErrorHandler(async () => {
      const stream = await navigator.mediaDevices.getDisplayMedia(displayMediaStreamConstraints)
      this.screenRecordMediaStream = stream

      const [videoTrack] = stream.getVideoTracks()
      const [audioTrack] = stream.getAudioTracks()

      if (videoTrack === undefined) throw new Error(`Screen recording: Could not get video track`)

      if (cropTarget !== undefined && 'cropTo' in videoTrack) {
        const croppableMediaStreamTrack = videoTrack as BrowserCaptureMediaStreamTrack
        await croppableMediaStreamTrack.cropTo(cropTarget)
      }

      const screenRecordClient = await this.getScreenRecordClient()

      this.screenRecordVideoTrack = this.sdk.createCustomVideoTrack({
        mediaStreamTrack: videoTrack,
        optimizationMode: 'detail',
      })
      if (audioTrack !== undefined) {
        this.screenRecordAudioTrack = this.sdk.createCustomAudioTrack({ mediaStreamTrack: audioTrack })
      }

      const clientId = await screenRecordClient.join(recordingChannel, useUserId)
      const tracksToPublish: ILocalTrack[] = [this.screenRecordVideoTrack]
      if (this.screenRecordAudioTrack !== undefined) tracksToPublish.push(this.screenRecordAudioTrack)
      if (this.tracks.localAudioTrack !== undefined) tracksToPublish.push(this.tracks.localAudioTrack)
      await screenRecordClient.publish(tracksToPublish)

      this.screenRecordingId = recordingId

      logger.info(
        `Screen recording: Start on channel: ${channelName} recording-channel: ${recordingChannel} client: ${clientId} id: ${recordingId}`
      )

      this.emit('screen-record-started', { stream, recordingId, recordingChannel })

      this.screenRecordVideoTrack.once('track-ended', () => this.onStopScreenRecording())
      this.screenRecordVideoTrack.once('closed', () => this.onStopScreenRecording())
    })
  }

  stopScreenRecording(): void {
    this.screenRecordVideoTrack?.close()
    this.screenRecordAudioTrack?.close()
  }

  async onStopScreenRecording(): Promise<void> {
    const recordingId = this.screenRecordingId
    if (recordingId === undefined) throw new Error(`Can't stop recording: No recording ID`)

    this.screenRecordVideoTrack?.removeAllListeners()
    this.screenRecordVideoTrack = undefined
    this.screenRecordAudioTrack?.removeAllListeners()
    this.screenRecordAudioTrack = undefined
    this.screenRecordingId = undefined

    if (this.screenRecordMediaStream !== undefined) {
      for (const track of this.screenRecordMediaStream.getTracks()) {
        track.stop()
        this.screenRecordMediaStream.removeTrack(track)
      }
    }
    this.screenRecordMediaStream = undefined

    const screenRecordClient = await this.getScreenRecordClient()
    const { channelName, clientId } = screenRecordClient

    logger.info(`Screen recording: Stopp on channel: ${channelName} client: ${clientId} id: ${recordingId}`)

    await screenRecordClient.leave()

    this.emit('screen-record-ended', { recordingId })
  }

  useCamera = async (deviceId: string): Promise<void> => {
    await this.setupCameraTrack()

    // if there is no track we can't do anything
    if (!this.tracks.localVideoTrack) return

    try {
      await this.tracks.localVideoTrack.setDevice(deviceId)
    } catch (error) {
      this.emit('error', new UnknownError(error as AgoraRTCError))
    }
  }

  useMicrophone = async (deviceId: string): Promise<void> => {
    await this.setupMicrophoneTrack()

    // if there is no track we can't do anything
    if (!this.tracks.localAudioTrack) return

    try {
      await this.tracks.localAudioTrack.setDevice(deviceId)
      await this.tracks.localNonPublishedAudioTrack?.setDevice(deviceId)
    } catch (error) {
      this.emit('error', new UnknownError(error as AgoraRTCError))
    }
  }

  setVideoSubscribeConfig = async (config: VideoSubscribeConfig): Promise<void> => {
    const client = await this.getClient()
    client.setVideoSubscribeConfig(config)
  }

  setNoiseCancellation = async (enabled: boolean): Promise<void> => {
    if (!isDenoiserSupported) {
      logger.warn('Tried to enable noise cancellation but it is not supported')
      return
    }
    // lazy try to initialize noise cancellation processor
    if (!this.noiseCancellationProcessor) {
      await this.trySetupNoiseCancellationProcessor()
    }

    if (!this.noiseCancellationProcessor) return

    if (enabled) {
      await this.noiseCancellationProcessor.enable()
    } else {
      await this.noiseCancellationProcessor.disable()
    }
  }

  setEnableAgoraLogUpload = (enabled: boolean): void => {
    if (enabled) {
      this.sdk.setLogLevel(AGORA_CONFIG.logLevelAllLogs)
      this.sdk.enableLogUpload()
    } else {
      this.sdk.setLogLevel(AGORA_CONFIG.logLevelError)
      this.sdk.disableLogUpload()
    }
  }

  private trySetupNoiseCancellationProcessor = async (): Promise<void> => {
    if (this.noiseCancellationProcessor) return
    if (!this.tracks.localAudioTrack) return

    const denoiser = await loadAudioDenoiser()
    denoiser.onloaderror = e => {
      this.emit('error', new DenoiserError('ERROR_INITIALIZING', e))
    }

    this.noiseCancellationProcessor = denoiser.createProcessor()
    this.noiseCancellationProcessor.onoverload = async () => {
      await this.noiseCancellationProcessor?.disable()
      this.emit('error', new DenoiserError('OVERLOADED'))
    }

    this.tracks.localAudioTrack
      .pipe(this.noiseCancellationProcessor)
      .pipe(this.tracks.localAudioTrack.processorDestination)
  }

  setBackgroundBlur = async (enabled: boolean): Promise<void> => {
    if (!isVirtualBackgroundSupported) {
      logger.warn('Tried to enable virtual backgrounds but it is not supported')
      return
    }

    // lazy try to initialize background blur extension
    if (!this.backgroundBlurProcessor) {
      await this.trySetupBackgroundBlurProcessor()
    }

    if (!this.backgroundBlurProcessor) return

    if (enabled) {
      await this.backgroundBlurProcessor.enable()
    } else {
      await this.backgroundBlurProcessor.disable()
    }
  }

  private trySetupBackgroundBlurProcessor = async (): Promise<void> => {
    if (this.backgroundBlurProcessor) return
    if (!this.tracks.localVideoTrack) return

    try {
      const virtualBackground = await loadVirtualBackground()
      this.backgroundBlurProcessor = virtualBackground.createProcessor()

      await this.backgroundBlurProcessor.init('/agora-extensions/virtual-background')

      this.backgroundBlurProcessor.onoverload = async () => {
        await this.backgroundBlurProcessor?.disable()
        this.emit('error', new VirtualBackgroundError('OVERLOADED'))
      }

      this.tracks.localVideoTrack
        .pipe(this.backgroundBlurProcessor)
        .pipe(this.tracks.localVideoTrack.processorDestination)

      this.backgroundBlurProcessor.setOptions({ type: 'blur', blurDegree: 1 })
    } catch (error) {
      this.emit('error', new VirtualBackgroundError('ERROR_INITIALIZING', error))
    }
  }

  private async publishTracks(): Promise<void> {
    const cancelDelayedLog = delayedLog('video-call-service.publishTracks was never processed', 60000)
    const client = await this.getClient()

    const tracksToPublish = []
    if (
      this.tracks.localAudioTrack &&
      this.tracks.localAudioTrack.enabled &&
      !this.tracks.localAudioTrack.muted
    ) {
      tracksToPublish.push(this.tracks.localAudioTrack)
    }

    if (this.tracks.localVideoTrack && this.tracks.localVideoTrack.enabled) {
      tracksToPublish.push(this.tracks.localVideoTrack)
    }
    await client.publish(tracksToPublish)
    cancelDelayedLog()
  }

  private setupCameraTrack = async (): Promise<void> => {
    if (!this.config) throw Error('Call setup before trying to setup camera track')
    if (this.tracks.localVideoTrack) return

    const canAccessCamera = await canAccessBrowserApi('camera')
    if (!canAccessCamera || this.cameraPermission === 'denied') return

    const cameras = await this.getCameras(true)
    if (cameras.length <= 0) return

    try {
      const newTrack = await this.sdk.createCameraVideoTrack({
        encoderConfig: getCameraConfig('low'),
      })

      this.tracks.localVideoTrack = newTrack
      this.cameraResolution = 'low'

      const handleClosed = (): void => {
        if (this.tracks.localVideoTrack !== newTrack) return

        this.tracks.localVideoTrack = undefined
        this.cameraResolution = undefined

        this.emit('track-changed', { type: 'video' })
        newTrack.removeAllListeners()
      }

      this.tracks.localVideoTrack.once('closed', handleClosed)

      this.emit('track-changed', { type: 'video' })
    } catch (error) {
      const agoraError = error as AgoraRTCError

      if (agoraError.code === 'PERMISSION_DENIED') {
        this.cameraPermission = 'denied'
      }

      this.emit('error', new CameraError(agoraError))
    }
  }

  setCameraResolution = async (resolution: CameraResolution): Promise<void> => {
    if (!this.config) throw Error('Call setup before trying to set camera resolution')
    if (resolution === this.cameraResolution) return

    if (this.tracks.localVideoTrack?.enabled === true) {
      const config = getCameraConfig(resolution)

      try {
        // note: the sdk modifies the config object, so we need to pass in a new object here
        await this.tracks.localVideoTrack.setEncoderConfiguration(
          typeof config === 'string' ? config : { ...config }
        )
        this.cameraResolution = resolution
      } catch (error) {
        logger.error('Failed to set camera resolution', { error, resolution, config })
      }
    }
  }

  private setupMicrophoneTrack = async (): Promise<void> => {
    if (this.tracks.localAudioTrack && this.tracks.localNonPublishedAudioTrack) return

    const canAccessMicrophone = await canAccessBrowserApi('microphone')
    if (!canAccessMicrophone || this.microphonePermission === 'denied') return

    const microphones = await this.getMicrophones(true)
    if (microphones.length <= 0) return

    try {
      const newTrack = await this.sdk.createMicrophoneAudioTrack(getMicrophoneConfig())
      const nonPublishedTrack = await this.sdk.createMicrophoneAudioTrack(getMicrophoneConfig())
      this.tracks.localAudioTrack = newTrack
      this.tracks.localNonPublishedAudioTrack = nonPublishedTrack

      const handleClosed = (): void => {
        if (this.tracks.localAudioTrack !== newTrack) return

        this.tracks.localAudioTrack = undefined
        newTrack.removeAllListeners()
        this.emit('track-changed', { type: 'video' })
      }

      const handleNonPublishedClosed = (): void => {
        if (this.tracks.localNonPublishedAudioTrack !== nonPublishedTrack) return

        this.tracks.localNonPublishedAudioTrack = undefined
        nonPublishedTrack.removeAllListeners()
      }

      this.tracks.localAudioTrack.once('closed', handleClosed)
      this.tracks.localNonPublishedAudioTrack.once('closed', handleNonPublishedClosed)

      this.emit('track-changed', { type: 'audio' })
    } catch (error) {
      const agoraError = error as AgoraRTCError

      if (agoraError.code === 'PERMISSION_DENIED') {
        this.microphonePermission = 'denied'
      }

      this.emit('error', new MicrophoneError(agoraError))
    }
  }

  private updateDevices = async (): Promise<void> => {
    const canAccessCamera = await canAccessBrowserApi('camera')
    const canAccessMicrophone = await canAccessBrowserApi('microphone')

    if (canAccessCamera && canAccessMicrophone) {
      try {
        await this.sdk.getDevices(false)
      } catch (error) {
        // we don't care about errors here
        // we only do this so we can ask for both camera and microphone in one permission check
      }
    }

    if (canAccessMicrophone) {
      try {
        await this.getMicrophones(true)
        this.microphonePermission = 'allowed'
      } catch (error) {
        const agoraError = error as AgoraRTCError

        if (agoraError.code === 'PERMISSION_DENIED') {
          this.microphonePermission = 'denied'
        }

        this.emit('error', new MicrophoneError(agoraError))
      }
    } else {
      this.microphonePermission = 'denied'
    }

    if (canAccessCamera) {
      try {
        await this.getCameras(true)
        this.cameraPermission = 'allowed'
      } catch (error) {
        const agoraError = error as AgoraRTCError

        if (agoraError.code === 'PERMISSION_DENIED') {
          this.cameraPermission = 'denied'
        }

        this.emit('error', new CameraError(agoraError))
      }
    } else {
      this.cameraPermission = 'denied'
    }
  }

  private onMicophonesChanged = async (changedDevice: DeviceInfo): Promise<void> => {
    await this.updateDevices()
    const currentMicrophone = await this.getCurrentMicrophone()

    if (changedDevice.state === 'ACTIVE' && isValidMicrophone(changedDevice)) {
      // When plugging in a mic, switch to it
      if (this.autoSwitchDevices) {
        logger.info(`Auto-switching to new microphone: ${changedDevice.device.label}`)
        await this.useMicrophone(changedDevice.device.deviceId)
        this.emit('device-switched', { type: 'microphone', newDeviceName: changedDevice.device.label })
      }
    } else if (
      currentMicrophone === undefined ||
      (changedDevice.device.deviceId === currentMicrophone.deviceId && changedDevice.state !== 'ACTIVE')
    ) {
      const microphones = await this.getMicrophones()
      const newMic = microphones[0]
      if (newMic) await this.useMicrophone(newMic.deviceId)

      this.emit('device-switched', { type: 'microphone', newDeviceName: newMic?.label ?? 'unknown' })
    }
  }

  private onCamerasChanged = async (changedDevice: DeviceInfo): Promise<void> => {
    await this.updateDevices()
    const currentCamera = await this.getCurrentCamera()

    if (changedDevice.state === 'ACTIVE') {
      // When plugging in a camera, switch to it
      if (this.autoSwitchDevices) {
        logger.info(`Auto-switching to new camera: ${changedDevice.device.label}`)
        await this.useCamera(changedDevice.device.deviceId)
        this.emit('device-switched', { type: 'camera', newDeviceName: changedDevice.device.label })
      }
    } else if (currentCamera === undefined || changedDevice.device.label === currentCamera.deviceId) {
      const cameras = await this.getCameras()
      const newCam = cameras[0]
      if (newCam) await this.useCamera(newCam.deviceId)
      this.emit('device-switched', { type: 'camera', newDeviceName: newCam?.label ?? 'unknown' })
    }
  }

  /**
   * A helper that wrapes the function in a try catch and handles known errors around screen sharing
   * @param fn
   * @returns
   */
  private async screenShareMediaErrorHandler(fn: () => Promise<void>): Promise<void> {
    try {
      await fn()
    } catch (error) {
      const agoraError = error as AgoraRTCError
      // Every browser handles screen sharing differently, so we need to handle the errors a bit hackily.
      // This is the best way I found to find out if screensharing failed or if the user just dismissed the modal request.

      // Chrome when denied screen share by the OS
      if (
        agoraError.code === 'PERMISSION_DENIED' &&
        agoraError.message.includes('NotAllowedError: Permission denied by system')
      ) {
        this.emit('error', new ScreenShareError(agoraError))
      }

      // Chrome when the screen share modal is closed by the user
      else if (
        agoraError.code === 'PERMISSION_DENIED' &&
        agoraError.message.includes('NotAllowedError: Permission denied')
      ) {
        // This shouldn't be treated as an error, since the browser UI doesn't treat this as a "permission" issue
        // so we shouldn't either
        return
      }

      // Firefox when screen sharing is blocked by the browser
      else if (
        agoraError.code === 'PERMISSION_DENIED' &&
        agoraError.message.includes(
          'NotAllowedError: The request is not allowed by the user agent or the platform in the current context.'
        )
      ) {
        this.emit('error', new ScreenShareError(agoraError))
      }

      // Firefox when screen sharing is blocked by the system
      // Firefox also somehow seems able to circomvent the system settings but will sometimes fail when you use the
      // browser ui to stop the stream
      else if (
        agoraError.code === 'UNEXPECTED_ERROR' &&
        agoraError.message.includes('AbortError: In shutdown')
      ) {
        this.emit('error', new ScreenShareError(agoraError))
      }

      // Safari when screen sharing is blocked by the browser, once or always
      else if (
        agoraError.code === 'PERMISSION_DENIED' &&
        agoraError.message.includes(
          'NotAllowedError: The request is not allowed by the user agent or the platform in the current context, possibly because the user denied permission.'
        )
      ) {
        this.emit('error', new ScreenShareError(agoraError))
      }

      // Seems to happen on chrome sometimes it seems
      else if (
        agoraError.code === 'NOT_READABLE' &&
        agoraError.message.includes('NotReadableError: Could not start video source')
      ) {
        this.emit('error', new ScreenShareError(agoraError))
      } else {
        // We don't know what the issue is so we throw an error in order to track it from the error reporting
        throw error
      }
    }
  }

  /**
   * Since the construction of the client is async we expose a factory method to create a new instance
   */
  static createVideoCallService = async (): Promise<VideoCallService> => {
    const sdk = (await import('agora-rtc-sdk-ng')).default

    sdk.setLogLevel(AGORA_CONFIG.logLevelError)
    return new VideoCallService({ sdk })
  }
}
