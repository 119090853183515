import { atom, WritableAtom } from 'jotai'
import _ from 'lodash'
import { compressToEncodedURIComponent, decompressFromEncodedURIComponent } from 'lz-string'
import { Dispatch, SetStateAction, useEffect, useMemo, useRef, useState } from 'react'
import { useWarnUnstablePropReference } from 'sierra-client/hooks/use-warn-unstable-prop'
import { NanoId12 } from 'sierra-domain/api/nano-id'
import { identityOp } from 'sierra-domain/utils'
import { Writeable, z } from 'zod'

type SerializerResult<T> = { ok: true; value: T } | { ok: false }

interface QueryStateSerializer<T> {
  encode: (t: T) => string
  decode: (s: string) => SerializerResult<T>
}

/**
 * Serializer/deserializer to be used for `booleans` in query params
 * */
export const booleanSerializer: QueryStateSerializer<boolean> = {
  encode: t => String(t),
  decode: s => {
    try {
      return {
        ok: true,
        value: z
          .enum(['true', 'false'])
          .transform(value => value === 'true')
          .parse(s),
      }
    } catch (e) {
      return { ok: false }
    }
  },
}

/**
 * Serializer/deserializer to be used for `strings` in query params
 * Compared to `booleans` where possible state is `finite`, `strings`
 * require a zodschema for validation
 * */
export const stringWithZodSerializer = <T extends string>(
  zodSchema: z.ZodSchema<T>
): QueryStateSerializer<T> => ({
  encode: t => t,
  decode: s => {
    try {
      return { ok: true, value: zodSchema.parse(s) }
    } catch (e) {
      return { ok: false }
    }
  },
})

/**
 * Serializer/deserializer to be used for `numbers` in query params
 * */
export const numberOptionalSerializer: QueryStateSerializer<number | undefined> = {
  encode: t => (t === undefined ? 'none' : t.toString(10)),
  decode: s => {
    try {
      return { ok: true, value: s === 'none' ? undefined : parseInt(s, 10) }
    } catch (e) {
      return { ok: false }
    }
  },
}

/**
 * Serializer/deserializer to be used for `json` in query params
 * Compared to `booleans` where possible state is `finite`, `json`
 * require a zodschema for validation.
 * This also uses lz-based compression algorithm for compression. Turn it off by setting it to
 * `false` in [options]
 * */
export const jsonWithZodSerializer = <T>(
  zodSchema: z.ZodSchema<T>,
  options?: { compress: boolean }
): QueryStateSerializer<T> => {
  const compressor = options?.compress === false ? identityOp : compressToEncodedURIComponent
  const decompressor = options?.compress === false ? identityOp : decompressFromEncodedURIComponent

  return {
    encode: t => compressor(JSON.stringify(t)),
    decode: s => {
      try {
        const decompressed = decompressor(s)
        if (!decompressed) return { ok: false }
        return { ok: true, value: zodSchema.parse(JSON.parse(decompressed)) }
      } catch (e) {
        return { ok: false }
      }
    },
  }
}

export const nanoId12ListWithZodSerializer = (): QueryStateSerializer<NanoId12[]> => {
  return {
    encode: nanoId12s => nanoId12s.map(it => encodeURIComponent(it)).join(','),
    decode: nanoId12s => {
      try {
        return {
          ok: true,
          value: NanoId12.array().parse(nanoId12s.split(',').map(it => decodeURIComponent(it))),
        }
      } catch (e) {
        return { ok: false }
      }
    },
  }
}

const readQueryParam = <T>(ser: QueryStateSerializer<T>, key: string): SerializerResult<T> => {
  if (typeof window === 'undefined') return { ok: false }
  const urlParams = new URLSearchParams(window.location.search)
  const value = urlParams.get(key)
  if (value === null) return { ok: false }
  return ser.decode(value)
}

const getHrefWithParams = (pathname: string, params: URLSearchParams): string => {
  const paramsString = params.toString()

  if (paramsString === '') return pathname
  return `${pathname}?${paramsString}`
}

const writeQueryParam = <T>(ser: QueryStateSerializer<T>, key: string, value: T): void => {
  if (typeof window === 'undefined') return
  const urlParams = new URLSearchParams(window.location.search)

  const encodedValue = ser.encode(value)
  if (urlParams.get(key) === encodedValue) return

  urlParams.set(key, encodedValue)
  const href = getHrefWithParams(window.location.pathname, urlParams)
  history.replaceState({ ...history.state, as: href }, '', href)
}

const deleteQueryParam = (key: string): void => {
  if (typeof window === 'undefined') return
  const urlParams = new URLSearchParams(window.location.search)
  if (!urlParams.has(key)) return

  urlParams.delete(key)
  const href = getHrefWithParams(window.location.pathname, urlParams)
  history.replaceState({ ...history.state, as: href }, '', href)
}

export const useQueryState = <T>(
  ser: QueryStateSerializer<T>,
  defaultValue: T,
  key: string,
  initTransform: (t: T) => T = t => t
): [T, Dispatch<SetStateAction<T>>] => {
  const defaultValueRef = useRef(defaultValue)
  const [value, setValue] = useState(() => {
    const queryResult = readQueryParam(ser, key)
    return initTransform(queryResult.ok ? queryResult.value : defaultValue)
  })

  useWarnUnstablePropReference(ser, 'ser')

  useEffect(() => {
    _.isEqual(value, defaultValueRef.current) ? deleteQueryParam(key) : writeQueryParam(ser, key, value)
  }, [ser, key, value])

  return [value, setValue]
}

export const queryStateAtom = <T>(
  ser: QueryStateSerializer<T>,
  defaultValue: T,
  key: string,
  initTransform: (t: T) => T = t => t
): Writeable<WritableAtom<T, [T], void>> => {
  const init = readQueryParam(ser, key)
  const valueAtom = atom(initTransform(init.ok ? init.value : defaultValue))
  return atom(
    get => get(valueAtom),
    (__, set, value: T) => {
      _.isEqual(value, defaultValue) ? deleteQueryParam(key) : writeQueryParam(ser, key, value)
      set(valueAtom, value)
    }
  )
}

export type QueryStateAtom<T> = WritableAtom<T, [T], void>
export const useQueryStateAtom = <T>(
  serializer: QueryStateSerializer<T>,
  defaultValue: T,
  key: string,
  initTransform: (t: T) => T = _.identity
): QueryStateAtom<T> => {
  const defaultValueRef = useRef(defaultValue)
  useWarnUnstablePropReference(
    serializer,
    '`serializer` changed in useQueryStateAtom. This will negatively affect performance.'
  )
  useWarnUnstablePropReference(
    key,
    '`key` changed in useQueryStateAtom. This will negatively affect performance.'
  )
  useWarnUnstablePropReference(
    initTransform,
    '`initTransform` changed in useQueryStateAtom. This will negatively affect performance.'
  )
  return useMemo(
    () => queryStateAtom(serializer, defaultValueRef.current, key, initTransform),
    [serializer, key, initTransform]
  )
}
