import React, { useMemo } from 'react'
import { useInsightsViews } from 'sierra-client/features/insights/api-hooks/use-views'
import { toLabel } from 'sierra-client/features/insights/display-widgets/utils'
import {
  getAvailableViewData,
  useInsightsMeasures,
} from 'sierra-client/features/insights/hooks/use-insights-views'
import { DropdownTriggerWithDelete } from 'sierra-client/features/insights/widget-builder/data-selectors/dropdown-trigger-with-delete'
import { measureIconMap } from 'sierra-client/features/insights/widget-builder/data-selectors/measure-icon-map'
import { WidgetBuilderState } from 'sierra-client/features/insights/widget-builder/widget-builder-state'
import { useTranslation } from 'sierra-client/hooks/use-translation'
import { TranslationLookup } from 'sierra-client/hooks/use-translation/types'
import { labelToString } from 'sierra-client/lib/tabular/datatype/label'
import { Measure, MeasureRef, Measures, areMeasureRefsEqual } from 'sierra-domain/api/insights'
import { isDefined } from 'sierra-domain/utils'
import { MenuItem } from 'sierra-ui/components'
import { SingleSelectDropdown } from 'sierra-ui/primitives/menu-dropdown'

type MenuItemWithRef = MenuItem & {
  ref?: MeasureRef
}

const menuItem = (
  measures: Measures,
  t: TranslationLookup,
  disabledMeasures: MeasureRef[]
): MenuItemWithRef => {
  switch (measures.type) {
    case 'measures.measure':
      return {
        type: 'label' as const,
        id: measures.id,
        icon: measureIconMap[measures.id],
        label: labelToString(toLabel(measures.label), t),
        ref: measures.measure,
        disabled: disabledMeasures.some(disabledMeasure =>
          areMeasureRefsEqual(measures.measure, disabledMeasure)
        ),
        tooltip: isDefined(measures.descriptionLabel)
          ? labelToString(toLabel(measures.descriptionLabel), t)
          : undefined,
      }
    case 'measures.group':
      return {
        type: 'nested' as const,
        id: measures.id,
        icon: measureIconMap[measures.id],
        label: labelToString(toLabel(measures.label), t),
        menuItems: measures.measures.map(measure => menuItem(measure, t, disabledMeasures)),
        disabled: measures.measures.every(measure => {
          switch (measure.type) {
            case 'measures.measure':
              return disabledMeasures.some(disabledMeasure =>
                areMeasureRefsEqual(measure.measure, disabledMeasure)
              )
            default:
              return false // TODO: fix this if we add more levels
          }
        }),
      }
    case 'measures.section':
      return {
        type: 'group' as const,
        id: measures.id,
        label: labelToString(toLabel(measures.label), t),
        menuItems: measures.measures.map(measure => menuItem(measure, t, disabledMeasures)),
      }
  }
}

const flattenMenuItems = (menuItems: MenuItemWithRef[]): MenuItemWithRef[] =>
  menuItems.flatMap(menuItem =>
    menuItem.type === 'nested' || menuItem.type === 'group'
      ? flattenMenuItems(menuItem.menuItems as MenuItemWithRef[])
      : [menuItem]
  )

export type SingleMeasureChangeHandler = (newMeasure: Measure) => void

export const MeasureSelector: React.FC<{
  selectedMeasure?: MeasureRef
  onChange: SingleMeasureChangeHandler
  renderTrigger?: () => JSX.Element
  disabledMeasures: MeasureRef[]
  onDelete?: () => void
  visualisation: WidgetBuilderState['visualisation']
}> = ({ onChange, selectedMeasure, renderTrigger, disabledMeasures = [], onDelete, visualisation }) => {
  const { t } = useTranslation()

  const groupedMeasures = useInsightsMeasures()
  const { data: viewsData } = useInsightsViews()

  const disabledByProgress: MeasureRef[] = useMemo(() => {
    const disabled: MeasureRef[] = []
    if (visualisation.type === 'progress-bar') {
      for (const view of viewsData?.views ?? []) {
        for (const measure of view.measures) {
          if (measure.type.type !== 'type.progress') {
            disabled.push(measure.ref)
          }
        }
      }
    }
    return disabled
  }, [viewsData?.views, visualisation.type])

  const extraDisabledMeasures = useMemo(
    () => [...disabledMeasures, ...disabledByProgress],
    [disabledMeasures, disabledByProgress]
  )

  const { selectedItem, menuItems } = useMemo(() => {
    const menuItems: MenuItemWithRef[] =
      groupedMeasures === undefined
        ? []
        : groupedMeasures.map(groupedMeasure => menuItem(groupedMeasure, t, extraDisabledMeasures))

    const flatMenuItems = flattenMenuItems(menuItems)
    const selectedItem =
      selectedMeasure === undefined
        ? undefined
        : flatMenuItems.find(
            flatMenuItem =>
              flatMenuItem.ref !== undefined && areMeasureRefsEqual(flatMenuItem.ref, selectedMeasure)
          )
    return {
      selectedItem: selectedItem?.type === 'label' ? selectedItem : undefined,
      menuItems,
    }
  }, [extraDisabledMeasures, groupedMeasures, selectedMeasure, t])

  const onSelect = (item: MenuItemWithRef): void => {
    if (item.ref !== undefined && viewsData !== undefined) {
      const { availableMeasures } = getAvailableViewData({ viewsData })
      const measure = availableMeasures.find(
        measure => item.ref !== undefined && areMeasureRefsEqual(measure.ref, item.ref)
      )
      if (measure !== undefined) {
        onChange(measure)
      }
    }
  }

  return (
    <SingleSelectDropdown
      withSearch
      searchPlaceholder={t('dictionary.search')}
      selectedItem={selectedItem}
      menuItems={menuItems}
      onSelect={onSelect}
      renderTrigger={
        renderTrigger !== undefined
          ? renderTrigger
          : () => (
              <DropdownTriggerWithDelete
                label={selectedItem?.label}
                placeholder={'manage.insights.select-measure.placeholder'}
                icon={selectedItem?.icon}
                onDelete={onDelete}
              />
            )
      }
    />
  )
}
