import { useTranslation } from 'sierra-client/hooks/use-translation'
import { Trans } from 'sierra-client/hooks/use-translation/trans'
import { Icon } from 'sierra-ui/components'
import { Text, View } from 'sierra-ui/primitives'
import { token } from 'sierra-ui/theming'
import styled from 'styled-components'

const Container = styled.div`
  width: 450px;
  height: 300px;
  background: white;
  padding: 16px;
  align-items: center;
  justify-content: center;
  display: flex;
  gap: 16px;
  flex-direction: column;
`

const Link = styled.a`
  color: ${token('foreground/link')};
`

export const GenerateCourseOutlineDeprecation: React.FC = () => {
  const { t } = useTranslation()
  return (
    <Container>
      <Icon iconId='warning--filled' size='size-20' />
      <View direction='column' gap='4'>
        <Text size='large' align='center'>
          {t('legacy-course-gen.deprecation-title')}
        </Text>
        <Text size='large' align='center'>
          <Trans
            i18nKey='legacy-course-gen.deprecation-link'
            components={{
              link1: (
                <Link
                  target='_blank'
                  rel='noopener noreferrer'
                  href='https://help.sana.ai/en/articles/104553-generate-from-file'
                />
              ),
            }}
          />
        </Text>
      </View>
    </Container>
  )
}
