import { getInsightsQueryOptions } from 'sierra-client/features/insights/api-hooks/query-options'
import { apiQueryOptions, RouteQueryOptions } from 'sierra-client/state/api'
import { InsightsDashboardTemplateListResponse } from 'sierra-domain/api/insights'
import { XAnalyticsDashboardsTemplatesList } from 'sierra-domain/routes'

export const listDashboardTemplatesQuery = {
  ...apiQueryOptions(XAnalyticsDashboardsTemplatesList, {}),
  ...getInsightsQueryOptions<InsightsDashboardTemplateListResponse>(),
  staleTime: 10 * 1000, // 10 seconds
} as const satisfies RouteQueryOptions<typeof XAnalyticsDashboardsTemplatesList>
