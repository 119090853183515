import { TRANSITION_DURATION_MILLIS } from 'sierra-ui/components/layout-kit/constants'
import { minWidth } from 'sierra-ui/utils/media-query-styles'
import styled, { css } from 'styled-components'

export * from 'sierra-client/views/workspace/components/capitalize-first-letter-view'
export * from 'sierra-client/views/workspace/components/content-icon'
export * from 'sierra-client/views/workspace/components/filtering-container'
export * from 'sierra-client/views/workspace/components/filters-container'
export * from 'sierra-client/views/workspace/components/searchbar-container'
export * from 'sierra-client/views/workspace/components/thumbnail'
export * from 'sierra-client/views/workspace/components/vertical-separator'
export * from 'sierra-client/views/workspace/components/wrapped-favorite-icon'

export const PageMarginConstants = { ['64']: 64, ['48']: 48, ['32']: 32, ['24']: 24 }

export const InlinePageMargins = css<{ $sidebarOpen: boolean }>`
  margin-inline: ${`${PageMarginConstants['48']}px ${PageMarginConstants['24']}px`};

  ${minWidth.tablet} {
    margin-inline: ${p =>
        p.$sidebarOpen ? `${PageMarginConstants['48']}` : `${PageMarginConstants['64']}`}px
      ${`${PageMarginConstants['32']}px`};
  }

  transition: margin ${TRANSITION_DURATION_MILLIS}ms ease;
`

export const PageContainer = styled.div<{ $sidebarOpen: boolean }>`
  display: flex;
  justify-content: center;
  flex-direction: column;

  margin-block: 24px 0px;
  padding-bottom: 4rem;

  ${InlinePageMargins}
`

export const GridContainer = styled.div`
  width: 100%;
  display: grid;
  grid-template-columns: repeat(12, 1fr);
  grid-column-gap: 1.5rem;
  grid-template-rows: 1fr;
  grid-template-rows: 1fr;
  grid-row-gap: 1.5rem;

  > * {
    grid-column: 1/-1;
  }
`
