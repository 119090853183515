import React from 'react'
import { useSelector } from 'sierra-client/state/hooks'
import { selectUserId } from 'sierra-client/state/user/user-selector'
import { DisplayNone } from 'sierra-client/views/v3-author/components'
import { PollDataProvider } from 'sierra-client/views/v3-author/poll-card/poll-card-data-layer'
import { assertElementType } from 'sierra-client/views/v3-author/queries'
import { RenderingContext } from 'sierra-client/views/v3-author/rendering-context'
import { SlateWrapperProps } from 'sierra-client/views/v3-author/slate'
import { InteractiveCardWrapper } from 'sierra-client/views/v3-author/wrapper'

export const PollCardContainer = React.forwardRef<HTMLDivElement, SlateWrapperProps>((props, ref) => {
  const { children, attributes, mode, element } = props
  assertElementType('poll-card', element)
  const userId = useSelector(selectUserId)

  if (userId === undefined) return <DisplayNone>{children}</DisplayNone>
  return (
    <RenderingContext preventDrag={true} disableMenu={false}>
      <InteractiveCardWrapper {...props} {...attributes} ref={ref}>
        <RenderingContext withGrid={false}>
          <PollDataProvider element={element} mode={mode}>
            {children}
          </PollDataProvider>
        </RenderingContext>
      </InteractiveCardWrapper>
    </RenderingContext>
  )
})
