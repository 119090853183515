import { SetStateAction, WritableAtom, atom } from 'jotai'
import { useMemo } from 'react'
import { useNotif } from 'sierra-client/components/common/notifications'
import { useTranslation } from 'sierra-client/hooks/use-translation'
import { getGlobalRouter } from 'sierra-client/router'
import { History } from 'sierra-domain/version-history/types'

export type TargetIndexAtom = WritableAtom<number, [newValue: SetStateAction<number>], SetStateAction<number>>
export type HistoryWithTargetIndexAtom = { history: History; targetIndexAtom: TargetIndexAtom }
export function useHistoryWithTargetIndexAtom(
  history: History | undefined,
  { selectedPublishVersion }: { selectedPublishVersion: string | undefined }
): HistoryWithTargetIndexAtom | undefined {
  const { t } = useTranslation()
  const notif = useNotif()

  return useMemo(() => {
    if (history === undefined) {
      return undefined
    }

    const selectedPublishVersionIndex =
      selectedPublishVersion !== undefined
        ? history.updates.findIndex(
            update => update.type === 'published-version' && update.id === selectedPublishVersion
          )
        : undefined

    if (selectedPublishVersion !== undefined && selectedPublishVersionIndex === -1) {
      notif.push({
        type: 'error',
        body: t('version-history.published-version-not-found'),
      })

      void getGlobalRouter().navigate({
        to: '/history/s/$',
        search: { publishVersion: undefined },
      })
    }

    const underlyingAtom = atom(
      selectedPublishVersionIndex !== undefined && selectedPublishVersionIndex !== -1
        ? selectedPublishVersionIndex
        : history.updates.length - 1
    )
    const limitedAtom = atom(
      get => get(underlyingAtom) % history.updates.length,
      (get, set, newValue: SetStateAction<number>) => {
        set(underlyingAtom, newValue)
        return newValue
      }
    )
    return { history, targetIndexAtom: limitedAtom }
  }, [history, notif, selectedPublishVersion, t])
}
