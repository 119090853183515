import React from 'react'
import { useSelector } from 'react-redux'
import { getIdentityBrand } from 'sierra-client/components/common/identities-selector/identities-utils'
import { useLocalizedFormatters } from 'sierra-client/core/format'
import { DashboardMeta } from 'sierra-client/features/insights/atoms'
import { useTranslation } from 'sierra-client/hooks/use-translation'
import { TranslationLookup } from 'sierra-client/hooks/use-translation/types'
import { selectUserId } from 'sierra-client/state/user/user-selector'
import { InsightsDashboardCollaborator, ListedUserFilterDashboard } from 'sierra-domain/api/insights'
import { UserId } from 'sierra-domain/api/uuid'
import { AvatarStack, AvatarStackUserShape, Icon, TruncatedTextWithTooltip } from 'sierra-ui/components'
import { Text, View } from 'sierra-ui/primitives'
import styled from 'styled-components'

const Dot: React.FC = () => <Icon iconId='radio-button--dot' size='size-12' color='foreground/secondary' />

const NoWrapText = styled(Text)`
  white-space: nowrap;
`

export const collaboratorToAvatarStackItem = (
  collaborator: InsightsDashboardCollaborator
): AvatarStackUserShape => {
  const { identity } = collaborator

  const uuid = UserId.parse(identity.identity.id)
  const [firstName, lastName] = identity.name.split(' ')
  const { color, image } = getIdentityBrand({ id: identity.identity.id, ...identity })

  return {
    uuid,
    firstName: firstName ?? '',
    lastName: lastName ?? '',
    avatar: image,
    avatarColor: color ?? 'blueBright',
  }
}

const getDashboardTextSharedWithMe = (
  collaborators: ListedUserFilterDashboard['collaborators'],
  t: TranslationLookup
): string => {
  switch (collaborators.length) {
    case 0:
      throw new Error('Dashboard should have at least one collaborator')
    case 1:
      return t('manage.insights.home.shared-dashboards.owned-by-name', {
        name: collaborators[0]?.identity.name,
      })
    default:
      return t('manage.insights.home.shared-dashboards.owned-by-name-and-n-other', {
        name: collaborators[0]?.identity.name,
        count: collaborators.length - 1,
      })
  }
}

const getDashboardTextOwnedByMe = (
  dashboard: ListedUserFilterDashboard,
  userId: UserId,
  t: TranslationLookup
): string => {
  const collabotorsExludingSelf = dashboard.collaborators.filter(
    collaborator => collaborator.identity.identity.id !== userId
  )

  switch (collabotorsExludingSelf.length) {
    case 0:
      return t('manage.insights.home.my-dashboards.visible-to-you')
    case 1:
      return t('manage.insights.home.my-dashboards.shared-with-name', {
        name: collabotorsExludingSelf[0]?.identity.name,
      })
    default:
      return t('manage.insights.home.my-dashboards.shared-with-n-other', {
        count: collabotorsExludingSelf.length,
      })
  }
}

const LastVisitedText: React.FC<{ lastVisitedTimestamp: number | undefined }> = ({
  lastVisitedTimestamp,
}) => {
  const { t } = useTranslation()
  const { formatTimeAgo } = useLocalizedFormatters()

  if (lastVisitedTimestamp === undefined) return null

  const text = `${t('manage.insights.home.dashboards.last-opened')} ${formatTimeAgo(lastVisitedTimestamp)}`

  return (
    <>
      <Dot />
      <NoWrapText color='foreground/secondary'>{text}</NoWrapText>
    </>
  )
}

export const DashboardSharingInfo: React.FC<{
  dashboard: ListedUserFilterDashboard
  meta?: DashboardMeta
}> = ({ dashboard, meta }) => {
  const { t } = useTranslation()
  const userId = useSelector(selectUserId)

  if (userId === undefined) return null

  const ownedByMe = dashboard.role === 'owner'
  const collabotorsExludingSelf = dashboard.collaborators.filter(
    collaborator => collaborator.identity.identity.id !== userId
  )

  if (ownedByMe) {
    return (
      <>
        <View gap='2' grow>
          {dashboard.sharedWithAll ? (
            <TruncatedTextWithTooltip color='foreground/secondary'>
              {t('manage.insights.home.my-dashboards.visible-to-everyone')}
            </TruncatedTextWithTooltip>
          ) : (
            <>
              {collabotorsExludingSelf.length > 0 && (
                <AvatarStack
                  withRemainerIndication
                  withTooltips
                  size='minuscule'
                  users={collabotorsExludingSelf.map(collaboratorToAvatarStackItem)}
                />
              )}
              <TruncatedTextWithTooltip color='foreground/secondary'>
                {getDashboardTextOwnedByMe(dashboard, userId, t)}
              </TruncatedTextWithTooltip>
            </>
          )}
        </View>
        <LastVisitedText lastVisitedTimestamp={meta?.lastVisitedTimestampMs} />
      </>
    )
  }

  const ownerCollaborators = dashboard.collaborators.filter(collaborator => collaborator.role === 'owner')

  return (
    <View gap='2' grow>
      {ownerCollaborators.length > 0 && (
        <>
          <AvatarStack
            withRemainerIndication
            withTooltips
            size='minuscule'
            users={ownerCollaborators.map(collaboratorToAvatarStackItem)}
          />
          <TruncatedTextWithTooltip color='foreground/secondary'>
            {getDashboardTextSharedWithMe(ownerCollaborators, t)}
          </TruncatedTextWithTooltip>
          <Dot />
        </>
      )}
      {dashboard.sharedWithAll ? (
        <NoWrapText color='foreground/secondary'>
          {t('manage.insights.home.shared-dashboards.shared-with-everyone')}
        </NoWrapText>
      ) : (
        <NoWrapText color='foreground/secondary'>
          {t('manage.insights.home.shared-dashboards.shared-with-you')}
        </NoWrapText>
      )}
    </View>
  )
}
