import { UserRole } from 'sierra-domain/api/editable-content'

const levels: Record<UserRole, number> = {
  viewer: 0,
  commenter: 1,
  editor: 2,
  owner: 3,
}

export const isCollaboratorRoleAboveOrEqual = (subject: UserRole, compareTo: UserRole): boolean =>
  levels[subject] >= levels[compareTo]
