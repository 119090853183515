import React from 'react'
import { assertElementType } from 'sierra-client/views/v3-author/queries'
import { RenderingContext } from 'sierra-client/views/v3-author/rendering-context'
import { SlateWrapperProps } from 'sierra-client/views/v3-author/slate'
import { View } from 'sierra-ui/primitives'

export const ScenarioCardsContainer = React.forwardRef<HTMLDivElement, SlateWrapperProps>((props, ref) => {
  const { children, attributes, element } = props
  assertElementType('scenario-card', element)

  return (
    <RenderingContext preventDrag={true} allowBlockComments={true} disableMenu={false}>
      <View direction='column' {...props} {...attributes} ref={ref}>
        {children}
      </View>
    </RenderingContext>
  )
})
