import React from 'react'
import { useTokenValue } from 'sierra-ui/theming'

export const ProgressBarPreviewSVG: React.FC = () => {
  const getToken = useTokenValue()
  return (
    <svg width='100%' height='100%' viewBox='0 0 85 12' fill='none' xmlns='http://www.w3.org/2000/svg'>
      <rect width='85' height='12' rx='6' fill={getToken('foreground/primary')} fillOpacity='0.05' />
      <rect width='48' height='12' rx='6' fill={getToken('org/primary')} />
      <circle cx='42' cy='6' r='4' fill={getToken('surface/default')} />
      <path
        d='M40 6H44M44 6L42.4 4M44 6L42.4 8'
        stroke={getToken('foreground/primary')}
        strokeLinecap='round'
        strokeLinejoin='round'
      />
    </svg>
  )
}
