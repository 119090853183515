import { createChannel, createEvent } from 'sierra-client/realtime-data/channels/types'
import { LiveSessionTranscriptionData, SummaryStatus } from 'sierra-domain/api/live-session'
import { z } from 'zod'

export * from 'sierra-client/realtime-data/channels/live-session-data-channel'

export const SummaryUpdatedData = z.object({
  summary: z.string(),
  liveSessionId: z.string(),
  channelName: z.string(),
  updatedAt: z.string(),
  status: SummaryStatus,
})

export const TranscriptionUpdatedData = z.object({
  transcription: LiveSessionTranscriptionData,
  updatedAt: z.string(),
})

export const ActionsUpdatedData = z.object({
  actions: z.string(),
  updatedAt: z.string(),
})

export type TranscriptionUpdatedData = z.infer<typeof TranscriptionUpdatedData>

export const liveSessionSummaryChannel = createChannel('live-session-summary', [
  createEvent('summary-updated', SummaryUpdatedData),
  createEvent('transcription-updated', TranscriptionUpdatedData),
  createEvent('actions-updated', ActionsUpdatedData),
])
