import { motion } from 'framer-motion'
import { useTranslation } from 'sierra-client/hooks/use-translation'
import { resolveTokenOrColor, TokenOrColor } from 'sierra-ui/color/token-or-color'
import { AiBackground, aiColorThree, AiText } from 'sierra-ui/components'
import { LoaderAnimation, View } from 'sierra-ui/primitives'
import { token } from 'sierra-ui/theming'
import styled from 'styled-components'

const SanaLoader = styled(LoaderAnimation)`
  color: ${aiColorThree};
`
const Wrapper = styled(View)`
  position: relative;
  overflow: hidden;
`

const LoaderWrapper = styled(View).attrs({
  animated: false,
  direction: 'column',
  justifyContent: 'center',
  alignItems: 'center',
  radius: 'size-16',
  position: 'relative',
  overflow: 'hidden',
})<{ $width: string; $height: string }>`
  width: ${p => p.$width};
  height: ${p => p.$height};
  overflow: hidden;
`
export const LinesLoader: React.FC<{ $width: string; $height?: string; base?: TokenOrColor }> = ({
  $width,
  $height = '14px',
  base = 'blueLight',
}) => (
  <LoaderWrapper $width={$width} $height={$height}>
    <AiBackground variant='ghost' base={base} />
  </LoaderWrapper>
)

export const FeedbackLoader: React.FC<{ status: 'success' | 'warning' }> = ({ status }) => {
  const { t } = useTranslation()
  return (
    <Wrapper direction='column' gap='16'>
      <View grow direction='column' gap='12'>
        {Array.from({ length: 3 }).map((_, index) => (
          <LinesLoader
            key={index}
            $width={`${Math.random() * 25 + 75}%`}
            base={status === 'success' ? 'success/background' : 'warning/background'}
          />
        ))}
      </View>
      <View gap='2'>
        <SanaLoader
          base={status === 'success' ? 'success/background' : 'warning/background'}
          size={24}
          color='currentColor'
        />
        <AiText base={status === 'success' ? 'success/background' : 'warning/background'} bold>
          {t('scenario-card.generating.feedback.text')}
        </AiText>
      </View>
    </Wrapper>
  )
}

const MessageLoaderContainer = styled(View)`
  padding: 10px 22px;
  border-radius: 20px;
  align-self: flex-start;
  width: fit-content;
  background-color: ${token('surface/strong')};
  gap: 6px;
`

const Dot = styled(motion.div)`
  width: 6px;
  height: 6px;
  border-radius: 50%;
  background-color: ${p => resolveTokenOrColor('foreground/secondary', p.theme)};
  margin: 8px 0;
`

export const MessageLoaderDots: React.FC = () => {
  const dotVariants = {
    visible: {
      scale: [1, 1.3, 1.1, 1],
      opacity: [0.3, 1, 0.5, 0.3],
    },
  }

  const dotTransition = {
    duration: 1.2,
    repeat: Infinity,
    repeatDelay: 0.8,
    ease: 'easeInOut',
  }

  return (
    <MessageLoaderContainer>
      {[0, 1, 2].map(index => (
        <Dot
          key={index}
          animate='visible'
          variants={dotVariants}
          transition={{
            ...dotTransition,
            delay: index * 0.3,
          }}
        />
      ))}
    </MessageLoaderContainer>
  )
}
