import { DateTime } from 'luxon'
import React from 'react'
import { SanaPagination } from 'sierra-client/components/util/sana-pagination'
import { useDateTimeFormatter } from 'sierra-client/core/format'
import { useEnhancedPagination } from 'sierra-client/hooks/use-enhanced-pagination'
import { useTranslation } from 'sierra-client/hooks/use-translation'
import { SessionDateTimeFilter } from 'sierra-client/views/learner/course/upcoming-sessions/datetime-filter'
import { DetailsBlock, EnrollButton } from 'sierra-client/views/learner/self-enroll-live-session/components'
import {
  SessionAvailabilityFilter,
  SessionSortType,
  UseSelfEnroll,
} from 'sierra-client/views/learner/self-enroll-live-session/hooks'
import { RoundedSearchBar } from 'sierra-client/views/manage/components/rounded-search-bar'
import {
  FilteringContainer,
  FiltersContainer,
  SearchBarContainer,
  VerticalSeparator,
} from 'sierra-client/views/workspace/components'
import { SelfEnrollSession } from 'sierra-domain/content/session'
import { MenuItem } from 'sierra-ui/components'
import { Text, View } from 'sierra-ui/primitives'
import { SingleSelectDropdown } from 'sierra-ui/primitives/menu-dropdown'
import { palette, spacing, token } from 'sierra-ui/theming'
import styled, { css } from 'styled-components'

type FiltersRowProps = {
  sessions: SelfEnrollSession[]
  filter: UseSelfEnroll['filter']
  updateFilter: UseSelfEnroll['updateFilter']
}

const FiltersRow: React.FC<FiltersRowProps> = ({ sessions, filter, updateFilter }) => {
  const { t } = useTranslation()

  const sortOptions: MenuItem<SessionSortType>[] = [
    {
      type: 'label',
      id: 'date-time-asc',
      label: t('course-detail.filters.date-time-asc'),
      selected: filter.sortType === 'date-time-asc',
    },
    {
      type: 'label',
      id: 'date-time-desc',
      label: t('course-detail.filters.date-time-desc'),
      selected: filter.sortType === 'date-time-desc',
    },
  ]
  const selectedSort = sortOptions.find(item => item.id === filter.sortType)

  const availabilityItems: MenuItem<SessionAvailabilityFilter>[] = [
    {
      type: 'label',
      id: 'any',
      label: t('course-detail.filters.any-availability'),
      selected: filter.availability === 'any',
    },
    {
      type: 'label',
      id: 'not-full',
      label: t('course-detail.filters.not-full'),
      selected: filter.availability === 'not-full',
    },
  ]
  const selectedAvailability = availabilityItems.find(item => item.id === filter.availability)

  return (
    <FilteringContainer>
      <SearchBarContainer>
        <RoundedSearchBar
          maxWidth='100%'
          value={filter.query}
          onChange={query => updateFilter({ query })}
          placeholder={`${t('dictionary.search')}...`}
        />
      </SearchBarContainer>
      <FiltersContainer>
        <View>
          <SingleSelectDropdown
            selectedItem={selectedAvailability}
            onSelect={item => updateFilter({ availability: item.id })}
            menuItems={availabilityItems}
          />
        </View>
        <SessionDateTimeFilter sessions={sessions} filter={filter} updateFilter={updateFilter} />
        <VerticalSeparator />
        <View>
          <SingleSelectDropdown
            selectedItem={selectedSort}
            onSelect={item => updateFilter({ sortType: item.id })}
            menuItems={sortOptions}
          />
        </View>
      </FiltersContainer>
    </FilteringContainer>
  )
}

const SessionImageContainer = styled.div<{ $bg: string }>`
  border: 1px solid ${palette.grey[5]};
  border-radius: 20px;
  min-width: 5.5rem;
  width: 5.5rem;
  height: 5.5rem;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;

  background-image: url('${p => p.$bg}');
  background-size: cover;
`

const TitleText = styled(Text).attrs({
  size: 'small',
})``

const DateText = styled(Text).attrs({
  size: 'large',
  bold: true,
})``

const TimeText = styled(Text).attrs({
  size: 'large',
  bold: true,
})``

const SessionTable = styled.div`
  display: grid;
  grid-template-columns: repeat(3, auto);
  gap: ${spacing.small};
`

type SessionDetailsProps = {
  $faded: boolean
}
const SessionDetails = styled(View).attrs({
  direction: 'row',
  gap: 'small',
  grow: true,
})<SessionDetailsProps>`
  ${p =>
    p.$faded &&
    css`
      opacity: 0.6;
    `}
  min-width: 300px;
`

const LocationAndFacilitator = styled(View).attrs({
  direction: 'column',
  justifyContent: 'center',
  alignItems: 'flex-start',
  gap: 'none',
})`
  min-width: 100px;
`

const SessionActions = styled(View).attrs({
  direction: 'row',
  gap: 'xsmall',
  justifyContent: 'flex-end',
  alignItems: 'center',
})`
  min-width: 150px;
`

const Separator = styled.span`
  grid-column: 1 / 4;
  display: block;
  width: 100%;
  height: 0;
  border-bottom: 1px solid ${token('border/default')};
`

// TODO: Fix widths! Use grid to keep them the same between rows (we won't use <table> here)
type UpcomingSessionsProps = {
  selfEnrollRef: React.RefObject<HTMLDivElement>
  courseImageUrl: string
  selfEnrollState: UseSelfEnroll
}

export const UpcomingSessions: React.FC<UpcomingSessionsProps> = ({
  selfEnrollRef,
  courseImageUrl,
  selfEnrollState,
}) => {
  const { t } = useTranslation()
  const dateFormatter = useDateTimeFormatter({ month: 'long', day: 'numeric' })
  const timeFormatter = useDateTimeFormatter(DateTime.TIME_SIMPLE)

  const { sessionList, filteredSessionList, selfEnroll, filter, updateFilter } = selfEnrollState

  const pagination = useEnhancedPagination({ items: filteredSessionList, pageSize: 10 })

  return (
    <View gap='small' direction='column' ref={selfEnrollRef}>
      <Text size='large' bold>
        {t('course-detail.live-headline')}
      </Text>
      <FiltersRow sessions={sessionList} filter={filter} updateFilter={updateFilter} />
      <Separator />
      <SessionTable>
        {pagination.itemsInPage.map(
          (
            {
              sessionId,
              title,
              usedSpots,
              totalSpots,
              startTime,
              endTime,
              allDay,
              location,
              isEnrolled,
              loading,
            },
            i
          ) => {
            const start = DateTime.fromISO(startTime)
            const end = DateTime.fromISO(endTime)
            const freeSpots = totalSpots !== undefined ? Math.max(0, totalSpots - usedSpots) : undefined
            const sessionFull = freeSpots === 0

            const dateString = dateFormatter.formatRange(start.toJSDate(), end.toJSDate())
            const timeString = timeFormatter.formatRange(start.toJSDate(), end.toJSDate())

            const availabilityString = sessionFull
              ? t('course-detail.session-full')
              : freeSpots !== undefined
                ? t('course-detail.free-spots', { count: freeSpots })
                : undefined

            return (
              <React.Fragment key={sessionId}>
                <SessionDetails $faded={sessionFull}>
                  <SessionImageContainer $bg={courseImageUrl} />
                  <DetailsBlock>
                    <DateText>
                      {dateString}
                      {!allDay && ','}
                    </DateText>
                    {!allDay && <TimeText>{timeString}</TimeText>}
                    <TitleText>{title}</TitleText>
                  </DetailsBlock>
                </SessionDetails>
                <LocationAndFacilitator>
                  <Text size='small' color='foreground/muted'>
                    {location !== undefined && location.value.trim() !== '' ? location.value : 'Sana Live'}
                  </Text>
                </LocationAndFacilitator>
                <SessionActions>
                  {!isEnrolled && availabilityString !== undefined && (
                    <Text size='small' color='foreground/muted'>
                      {availabilityString}
                    </Text>
                  )}
                  <EnrollButton
                    enrolled={isEnrolled}
                    sessionFull={sessionFull}
                    loading={loading}
                    onClick={() => selfEnroll(sessionId, !isEnrolled)}
                  />
                </SessionActions>
                {i !== pagination.itemsInPage.length - 1 && <Separator />}
              </React.Fragment>
            )
          }
        )}
      </SessionTable>
      <SanaPagination paginationItems={pagination.paginationItems} />
    </View>
  )
}
