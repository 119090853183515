import { View } from 'sierra-ui/primitives'
import { token } from 'sierra-ui/theming'
import styled, { css } from 'styled-components'

const LayoutWrapper = styled(View).attrs({
  direction: 'column',
  justifyContent: 'space-between',
})<{ $withoutPadding?: boolean }>`
  flex: 1;
  position: relative;
  height: 100%;

  ${p =>
    p.$withoutPadding === true
      ? undefined
      : css`
          padding-inline: 24px;
        `}
`

const OuterPanelFooterWrapper = styled(View).attrs({
  direction: 'row',
  alignItems: 'center',
  gap: '8',
})`
  position: absolute;
  bottom: 0;
  width: 100%;
  border-top: 1px solid ${token('border/default')};
  padding: 24px 32px;
  background: ${token('surface/default')};
`

const InnerPanelFooterWrapper = styled(View).attrs({
  direction: 'row-reverse',
  justifyContent: 'space-between',
  alignItems: 'center',
  paddingTop: '48',
  paddingBottom: '48',
  paddingLeft: 'none',
  paddingRight: 'none',
  gap: '8',
})`
  position: sticky;
  background: linear-gradient(to top, ${token('surface/default').opacity(1)} 70%, transparent);
  bottom: 0;
  left: 0;
  width: 100%;
`

export const ProgramPanelCommon = { LayoutWrapper, OuterPanelFooterWrapper, InnerPanelFooterWrapper }
