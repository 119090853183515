import _ from 'lodash'
import { useCallback, useEffect, useState } from 'react'
import { TableInstance } from 'react-table'

type UseFEInfinitePaginationData = {
  nextPage: () => void
  resetPage: () => void
  hasMore: boolean
}

export const useFEInfinitePagination = <T extends Record<string, unknown> = Record<string, unknown>>(
  tableInstance: TableInstance<T>
): UseFEInfinitePaginationData => {
  const [pageSize, setPageSize] = useState(tableInstance.state.pageSize)

  useEffect(() => {
    setPageSize(tableInstance.state.pageSize)
  }, [tableInstance])

  // tableInstance.rows contains filtered rows
  const hasMore = tableInstance.rows.length > tableInstance.state.pageSize

  const nextPage =
    // eslint-disable-next-line react-hooks/exhaustive-deps
    useCallback(
      _.debounce(() => {
        tableInstance.setPageSize(tableInstance.state.pageSize + pageSize)
      }, 300),
      [tableInstance, pageSize]
    )

  const resetPage = useCallback(() => {
    tableInstance.setPageSize(pageSize)
  }, [tableInstance, pageSize])

  useEffect(() => {
    resetPage()
  }, [resetPage])

  return { nextPage, resetPage, hasMore }
}
