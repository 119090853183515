import _ from 'lodash'
import { DateTime } from 'luxon'
import React, { Dispatch, SetStateAction, useCallback, useEffect, useMemo, useState } from 'react'
import { CellProps, Column } from 'react-table'
import { SelectableHeader, SelectableRow } from 'sierra-client/components/table/select'
import { SortableHeader } from 'sierra-client/components/table/sortable-header'
import { joinFacilitators } from 'sierra-client/core/format'
import { useHasOrganizationPermission } from 'sierra-client/hooks/use-permissions'
import { useTranslation } from 'sierra-client/hooks/use-translation'
import { getGlobalRouter } from 'sierra-client/router'
import { ActionButton } from 'sierra-client/views/manage/components/common'
import {
  ManageTableSmall,
  useManageTableSmall,
} from 'sierra-client/views/manage/components/manage-table-small'
import {
  getAttendanceTranslationKey,
  liveSessionToCsv,
} from 'sierra-client/views/manage/live-session/live-session-utils'
import { UserModalActionsProps } from 'sierra-client/views/manage/users/components/user-modal-actions'
import { LiveSessionAssignment } from 'sierra-domain/api/manage'
import { LiveSessionId } from 'sierra-domain/api/nano-id'
import { UserId } from 'sierra-domain/api/uuid'
import { scheduledOrNull } from 'sierra-domain/content/session'
import { MenuItem } from 'sierra-ui/components'
import { Button, Text, View } from 'sierra-ui/primitives'
import { IconMenu } from 'sierra-ui/primitives/menu-dropdown'

export interface UserLiveSessionTableProps {
  liveSessions: LiveSessionAssignment[]
  currentUserId: UserId
  setUserAction: Dispatch<SetStateAction<UserModalActionsProps['action']>>
}

export const UserLiveSessionTable: React.FC<UserLiveSessionTableProps> = ({
  liveSessions,
  currentUserId,
  setUserAction,
}) => {
  const { t } = useTranslation()
  // const { isLoading, liveSessions } = useLiveSessions()
  const [focusedLiveSessionId, setFocusedLiveSessionId] = useState<LiveSessionId | undefined>(undefined)
  const [isSearchOpen, setIsSearchOpen] = useState<boolean>(false)
  const canEditAssignments = useHasOrganizationPermission('EDIT_CONTENT_ASSIGNMENTS')

  const goToDetails = useCallback((liveSessionId: string) => {
    void getGlobalRouter().navigate({ to: `/manage/events/${liveSessionId}` })
  }, [])

  const onRemove = useCallback(
    (ids: string[]) => {
      setUserAction({
        modal: 'unassign',
        messageType: 'session',
        targets: ids.map(id => ({ id, type: 'session' })),
      })
    },
    [setUserAction]
  )

  const columns: Column<LiveSessionAssignment>[] = React.useMemo(
    () => [
      {
        id: 'time',
        Header: p => {
          return (
            <>
              <SelectableHeader {...p} />
              <SortableHeader label={t('dictionary.date')} smallLabel {...p} />
            </>
          )
        },
        accessor: row => scheduledOrNull(row.data)?.startTime,
        Cell: (p: CellProps<LiveSessionAssignment>) => {
          const { startTime } = scheduledOrNull(p.row.original.data) ?? {}
          return (
            <View alignItems='center'>
              <SelectableRow {...p} />
              <Text color='LEGACY_DEFAULT_TEXT_COLOR_REPLACE_ASAP' size='small'>
                {startTime !== undefined ? DateTime.fromISO(startTime).toFormat('LLL dd yyyy, HH:mm') : '-'}
              </Text>
            </View>
          )
        },
        width: '20%',
      },
      {
        id: 'title',
        accessor: ls => ls.data.title,
        width: '30%',
        Header: p => <SortableHeader label={t('table.name')} smallLabel {...p} />,
        Cell: (p: CellProps<LiveSessionAssignment>) => {
          return (
            <Text color='LEGACY_DEFAULT_TEXT_COLOR_REPLACE_ASAP' size='small' bold>
              {p.row.original.data.title}
            </Text>
          )
        },
      },
      {
        id: 'course-title',
        accessor: ls => ls.data.title,
        width: '20%',
        Header: p => <SortableHeader label={t('manage.live-session.facilitator')} smallLabel {...p} />,
        Cell: (p: CellProps<LiveSessionAssignment>) => {
          return (
            <Text color='LEGACY_DEFAULT_TEXT_COLOR_REPLACE_ASAP' size='small'>
              {joinFacilitators(p.row.original.facilitatorsInfo)}
            </Text>
          )
        },
      },
      {
        id: 'attendance',
        accessor: 'attendance',
        width: '20%',
        Header: t('table.attendance'),
        // Header: p => <SortableHeader label={t('table.attendance')} smallLabel {...p} />, // @TODO make sortable (needs BE filter)
        Cell: p => (
          <>
            {p.row.original.attendance === undefined
              ? ''
              : t(getAttendanceTranslationKey(p.row.original.attendance))}
          </>
        ),
      },
      {
        id: 'actions',
        accessor: 'liveSessionId',
        width: '10%',
        disableSortBy: true,
        Cell: ({ row }: CellProps<LiveSessionAssignment>) => {
          const [open, setOpen] = React.useState(false)

          const { liveSessionId, facilitatorsInfo } = row.original

          useEffect(() => {
            if (open) {
              setFocusedLiveSessionId(liveSessionId)
            } else {
              setFocusedLiveSessionId(undefined)
            }
          }, [open, liveSessionId])

          const menuItems: MenuItem[] = useMemo(
            () => [
              {
                type: 'label',
                id: 'view-details',
                label: t('manage.view-details'),
                onClick: () => getGlobalRouter().navigate({ to: `/manage/events/${liveSessionId}` }),
                icon: 'calendar',
              },
              {
                type: 'label',
                id: 'unassign',
                label: t('dictionary.unassign'),
                color: 'destructive/background',
                hidden:
                  facilitatorsInfo.some(facilitator => currentUserId === facilitator.userId) ||
                  !canEditAssignments,
                onClick: () => onRemove([liveSessionId]),
                icon: 'user--remove',
              },
            ],
            [facilitatorsInfo, liveSessionId]
          )

          return (
            <View justifyContent='flex-end' grow>
              <IconMenu
                iconId='overflow-menu--horizontal'
                aria-label='Details'
                variant='transparent'
                onSelect={item => {
                  if ('onClick' in item) {
                    item.onClick?.()
                  }
                }}
                menuItems={menuItems}
                isOpen={open}
                onOpenChange={setOpen}
              />
            </View>
          )
        },
      },
    ],
    [canEditAssignments, currentUserId, onRemove, t]
  )

  const { tableInstance, selectedIds } = useManageTableSmall({
    tableOptions: { data: liveSessions, columns },
    getEntityId: ls => ls.liveSessionId,
  })

  const facilitatorIds = _.uniq(
    tableInstance.selectedFlatRows.flatMap(r => r.original.facilitatorsInfo.map(it => it.userId))
  )

  return (
    <ManageTableSmall
      tableInstance={tableInstance}
      isLoading={false}
      focusedId={focusedLiveSessionId}
      onViewDetails={goToDetails}
      getEntityId={ls => ls.liveSessionId}
      mapEntityToCsv={entity =>
        liveSessionToCsv({
          liveSessionId: entity.liveSessionId,
          data: entity.data,
          facilitatorsInfo: entity.facilitatorsInfo,
        })
      }
      isSearchOpen={isSearchOpen}
      searchTrigger={setIsSearchOpen}
      bulkActions={
        !canEditAssignments || facilitatorIds.includes(currentUserId) ? (
          <></>
        ) : (
          <ActionButton color='redBright' onClick={() => onRemove(selectedIds)}>
            {t('admin.remove')}
          </ActionButton>
        )
      }
      footerButton={
        canEditAssignments ? (
          <Button onClick={() => setUserAction({ modal: 'assign-session' })}>
            {t('manage.session.no-session.title')}
          </Button>
        ) : undefined
      }
      translations={{
        searchPlaceholder: t('search.sessions'),
        tableLoading: t('manage.sessions.table-loading'),
        tableNoResults: t('manage.sessions.no-results'),
        csvPrefix: t('content.sessions'),
      }}
    />
  )
}
