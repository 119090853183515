import { UseMutationResult } from '@tanstack/react-query'
import { invalidateDashboardCollaborators } from 'sierra-client/features/insights/api-hooks/use-dashboard-collaborators'
import { invalidateListDashboards } from 'sierra-client/features/insights/api-hooks/use-list-dashboards'
import { useTypedMutation } from 'sierra-client/state/api'
import { InsightsDashboardSetCollaboratorRolesRequest } from 'sierra-domain/api/insights'
import { RequestError } from 'sierra-domain/error'
import { XAnalyticsDashboardSetCollaboratorRoles } from 'sierra-domain/routes'

export const useSetDashboardCollaboratorRoles = (): UseMutationResult<
  null,
  RequestError,
  InsightsDashboardSetCollaboratorRolesRequest
> => {
  return useTypedMutation(XAnalyticsDashboardSetCollaboratorRoles, {
    onSuccess: async (data, variables) => {
      await invalidateDashboardCollaborators({ dashboardId: variables.dashboardId })
      await invalidateListDashboards()
    },
  })
}
