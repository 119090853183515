import { selectLoggingPage } from 'sierra-client/state/logging/selectors'
import { Context, UserAgentContext } from 'sierra-client/state/logging/types'
import { RootState } from 'sierra-client/state/types'
import woothee from 'woothee'

export const parseUserAgent = (userAgent: string): UserAgentContext => {
  const parsed = woothee.parse(userAgent)

  return {
    userAgent,
    os: {
      name: parsed.os,
      version: parsed.os_version,
    },
    device: {
      type: parsed.category,
    },
    browser: {
      name: parsed.name,
      version: parsed.version,
    },
  }
}

export const getContext = (state: RootState): Context => {
  const location = window.location

  const pageState = selectLoggingPage(state)

  return {
    ...parseUserAgent(window.navigator.userAgent),
    page: {
      path: location.pathname,
      search: location.search,
      url: location.href,
      referrer: document.referrer,
      title: document.title,
      category: pageState?.category,
      id: pageState?.id,
      props: pageState?.props,
    },
  }
}
