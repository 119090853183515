import { graphql } from 'sierra-client/api/graphql/gql'
import { graphQuery } from 'sierra-client/api/hooks/use-graphql-query'
import { CalendarEventId } from 'sierra-domain/api/nano-id'
import { UserId } from 'sierra-domain/api/uuid'

export const useDeleteCalendarEventAttendance = (): ((
  calendarEventId: CalendarEventId,
  attendeeId: UserId
) => Promise<string>) => {
  const deleteCalendarEventAttendance = async (
    calendarEventId: CalendarEventId,
    attendeeId: UserId
  ): Promise<string> => {
    const result = await graphQuery(
      graphql(`
        mutation deleteCalendarEventAttendance($calendarEventId: CalendarEventId!, $attendeeId: UserId!) {
          deleteCalendarEventAttendance(calendarEventId: $calendarEventId, attendeeId: $attendeeId) {
            calendarEventId
          }
        }
      `),
      {
        calendarEventId,
        attendeeId,
      }
    )
    return result.deleteCalendarEventAttendance.calendarEventId
  }

  return deleteCalendarEventAttendance
}
