import styled, { css } from 'styled-components'

export const DisappearingSpan = styled.span<{ $shouldShowName: boolean }>`
  text-indent: 0;
  ${p =>
    p.$shouldShowName
      ? css`
          opacity: 1;
        `
      : css`
          animation: fade-out;
          animation-fill-mode: forwards;
          animation-delay: 800ms;
          animation-duration: 200ms;

          @keyframes fade-out {
            100% {
              opacity: 0;
            }
          }
        `}
`
