import React from 'react'
import { getRoleIsAtLeastEditorAndCommenter } from 'sierra-client/features/teamspace'
import { useHasManageAccess } from 'sierra-client/views/manage/permissions/use-has-manage-access'
import { FolderTableRow } from 'sierra-client/views/workspace/teamspace/common-table-row'
import {
  CourseGroupTableRow,
  EditorContentTableRow,
} from 'sierra-client/views/workspace/teamspace/teamspace-content-table-editor-row'
import { LearnerContentTableRow } from 'sierra-client/views/workspace/teamspace/teamspace-content-table-learner-row'
import { isCollaboratorRoleAboveOrEqual } from 'sierra-client/views/workspace/utils/collaborator-role-utils'
import {
  Teamspace,
  TeamspaceContent,
  TeamspaceEditableContent,
  TeamspaceNonEditorCourse,
  isCourseGroup,
} from 'sierra-domain/api/teamspace'
import { STATIC_EMPTY_ARRAY, isDefined } from 'sierra-domain/utils'

const _TeamspaceContentRow: React.FC<{
  teamspaceContent: TeamspaceEditableContent | TeamspaceNonEditorCourse
  groupedContent: Array<TeamspaceEditableContent | TeamspaceNonEditorCourse>
  teamspace: Teamspace
  isPinned: boolean
  isOver: boolean
  showCurrentFolder: boolean
  onPin: (id: string, isPinned: boolean) => void
  onDeleted: () => void
  onDuplicate?: () => void
  onMoveToTeamspace?: () => void
}> = ({
  teamspaceContent,
  groupedContent,
  teamspace,
  isPinned,
  isOver,
  showCurrentFolder,
  onPin,
  onDeleted,
  onDuplicate,
  onMoveToTeamspace,
}) => {
  const hasManageAccess = useHasManageAccess()
  const content = teamspaceContent.content
  const { highestCollaboratorRole } = content
  const isEditableContent = teamspaceContent.type === 'editable'

  const { roleIsAtLeastEditor, roleIsAtLeastCommenter } = getRoleIsAtLeastEditorAndCommenter({
    highestCollaboratorRole,
    hasManageAccess,
    isEditableContent,
  })

  const canEditCourseGroupEditions =
    isDefined(highestCollaboratorRole) &&
    isCollaboratorRoleAboveOrEqual(highestCollaboratorRole, 'commenter') &&
    isCourseGroup(teamspaceContent) &&
    teamspaceContent.type === 'non-editor-course'

  // deal with roles. LearnerRow should ONLY be read only
  if (roleIsAtLeastEditor || roleIsAtLeastCommenter) {
    return (
      <EditorContentTableRow
        teamspaceContent={teamspaceContent}
        isPinned={isPinned}
        onPin={onPin}
        onDeleted={onDeleted}
        onDuplicate={onDuplicate}
        onMoveToTeamspace={onMoveToTeamspace}
        groupedContent={groupedContent}
        teamspace={teamspace}
        isOver={isOver}
        showCurrentFolder={showCurrentFolder}
      />
    )
  }
  // Course-groups without manage access should be read only while the editions should be editable
  else if (canEditCourseGroupEditions) {
    return (
      <CourseGroupTableRow
        teamspaceContent={teamspaceContent}
        isPinned={isPinned}
        onPin={onPin}
        onDeleted={onDeleted}
        onDuplicate={onDuplicate}
        onMoveToTeamspace={onMoveToTeamspace}
        groupedContent={groupedContent}
        teamspace={teamspace}
        isOver={isOver}
        showCurrentFolder={showCurrentFolder}
        readOnlyParent
      />
    )
  }
  // Show the read only "learner" view
  else {
    return (
      <LearnerContentTableRow
        teamspaceContent={teamspaceContent}
        isPinned={isPinned}
        onPin={onPin}
        groupedContent={groupedContent}
        teamspace={teamspace}
        isOver={isOver}
        showCurrentFolder={showCurrentFolder}
      />
    )
  }
}

export const TeamspaceContentTableRow: React.FC<{
  content: TeamspaceContent
  groupedContent?: Array<TeamspaceEditableContent | TeamspaceNonEditorCourse>
  isPinned: boolean
  onDeleted: () => void
  onPin: (id: string, isPinned: boolean) => void
  isOver: boolean
  teamspace: Teamspace
  showCurrentFolder: boolean
  onDuplicate?: () => void
  onMoveToTeamspace?: () => void
}> = ({
  content,
  groupedContent = STATIC_EMPTY_ARRAY,
  isPinned,
  onDeleted,
  onPin,
  isOver,
  teamspace,
  showCurrentFolder,
  onDuplicate,
  onMoveToTeamspace,
}) => {
  // Deal with folders first
  if (content.type === 'folder') {
    return <FolderTableRow teamspace={teamspace} folder={content} showCurrentFolder={showCurrentFolder} />
  }

  return (
    <_TeamspaceContentRow
      teamspaceContent={content}
      groupedContent={groupedContent}
      teamspace={teamspace}
      isPinned={isPinned}
      isOver={isOver}
      showCurrentFolder={showCurrentFolder}
      onDeleted={onDeleted}
      onPin={onPin}
      onDuplicate={onDuplicate}
      onMoveToTeamspace={onMoveToTeamspace}
    />
  )
}
