import _ from 'lodash'
import { FC } from 'react'
import { useTranslation } from 'sierra-client/hooks/use-translation'
import { removeNodeWithId } from 'sierra-client/views/v3-author/command'
import { useSiblings } from 'sierra-client/views/v3-author/hooks'
import { assertElementType, isElementType } from 'sierra-client/views/v3-author/queries'
import { RenderingContext } from 'sierra-client/views/v3-author/rendering-context'
import { SlateFC } from 'sierra-client/views/v3-author/slate'
import { hasOnlyEmptyTextInNodes } from 'sierra-domain/slate-util'
import { createProjectCardSection } from 'sierra-domain/v3-author/create-blocks'
import { ColorName } from 'sierra-ui/color/types'
import { RoundAvatar } from 'sierra-ui/components'
import { Button, IconButton, Spacer, View } from 'sierra-ui/primitives'
import { fonts } from 'sierra-ui/theming/fonts'
import { Path } from 'slate'
import { ReactEditor, useSlateStatic } from 'slate-react'
import styled from 'styled-components'

const SectionGrid = styled.div`
  display: grid;
  grid-gap: 1rem;
  grid-template-columns: repeat(auto-fit, 15rem);
  list-style: none;
  margin: 0;
  user-select: none;
  background: #f8f8f8;
  border-radius: 12px;
  padding: 24px;
  width: 320px;
`

const SectionHeader = styled(View).attrs({
  gap: 'none',
})`
  padding-bottom: 0.75rem;
  border-bottom: 1px solid rgba(0, 0, 0, 0.05);
  margin-bottom: 1.5rem;
`

const NoteWrapper = styled.div<{ $fontSize: string }>`
  position: relative;
  width: 15rem;
  padding: 1.25rem;
  font-size: ${p => p.$fontSize};
  transition: background-color 0.1s ease-in-out;
  background-color: #ffffff;
  border-radius: 8px;
  box-shadow: 0px 4px 12px 0px rgba(0, 0, 0, 0.03);
`

const NoteFooter = styled(View).attrs({ gap: 'none' })`
  margin-top: 16px;
`

const NoteFooterText = styled.div`
  ${fonts.body.micro};
  font-weight: 500;
  color: rgba(0, 0, 0, 0.25);
`

const Note: FC<{
  text: string
  fontSize: string
  userName: string
  userColor: ColorName
}> = ({ text, fontSize, userName, userColor }) => (
  <NoteWrapper $fontSize={fontSize} contentEditable={false}>
    {text}

    <NoteFooter>
      <RoundAvatar size='small' firstName={userName} color={userColor} />
      <Spacer size='xxsmall' />
      <NoteFooterText>{userName}</NoteFooterText>
      <View grow />
      <IconButton iconId='checkmark--outline' variant='transparent' tabIndex={-1} tooltip='Complete task' />
    </NoteFooter>
  </NoteWrapper>
)

export const ProjectCardContainer = styled(View).attrs(p => ({
  children: (
    <RenderingContext
      withGrid={false}
      preventDrag={true}
      disableMenu={true}
      placeholder={'sticky-notes.title.placeholder'}
    >
      {p.children}
    </RenderingContext>
  ),
  padding: 'xlarge medium',
}))`
  color: ${p => p.theme.home.textColor};
  flex-wrap: wrap;
  align-items: flex-start;

  /* Hack to get title layout on the first row */

  > :first-child {
    flex-basis: 100%;
  }
`

export const ProjectCardSectionContainer = styled.div`
  display: flex;
  flex-direction: column;
  min-width: 1rem;
  margin-top: 1.5rem;
`

const SectionText = styled.p`
  position: relative;
  ${fonts.body.small};
  font-weight: 500;
  color: #000;
  border-radius: 0.125rem;
  margin-left: 0.25rem;

  &::placeholder {
    color: rgba(0, 0, 0, 0.25);
  }
`

const ShiftRight = styled.div`
  margin-left: auto;
`

const Placeholder = styled.span.attrs({ contentEditable: false })`
  color: rgba(0, 0, 0, 0.25);
  pointer-events: none;
  position: absolute;
  margin-left: auto;
  margin-right: auto;
  left: 0;
  right: 0;
  text-align: inherit;
  white-space: nowrap;
`

export const ProjectCardSection: SlateFC = ({ element, children, readOnly }) => {
  assertElementType('project-card-section', element)
  const editor = useSlateStatic()
  const siblingSections = useSiblings({ nodeId: element.id }).filter(isElementType('project-card-section'))
  const isEmpty = hasOnlyEmptyTextInNodes([element])
  const isLastSection = _.last(siblingSections)?.id === element.id
  const { t } = useTranslation()

  return (
    <>
      <SectionGrid>
        <SectionHeader>
          <SectionText>
            {isEmpty && <Placeholder>Name your section...</Placeholder>}
            {children}
          </SectionText>
          {!readOnly && siblingSections.length > 1 && (
            <ShiftRight contentEditable={false}>
              <IconButton
                variant='transparent'
                iconId='trash-can'
                tabIndex={-1}
                onClick={() => removeNodeWithId(editor, element.id)}
                tooltip='Delete section'
              />
            </ShiftRight>
          )}
        </SectionHeader>
        <Note
          fontSize='21px'
          text={'Ducimus accusamus ut voluptatem dolore ratione!'}
          userName='M'
          userColor='greenBright'
        />
        <Note
          fontSize='14px'
          text={
            'Dolor delectus!! Consequatur aut architecto accusantium consequatur. Distinctio autem delectus est nemo porro autem quam enim laborum. Eaque sit et sunt quis velit?'
          }
          userName='J'
          userColor='blueBright'
        />
      </SectionGrid>
      {isLastSection && !readOnly && (
        <View contentEditable={false} marginTop='large'>
          <Button
            variant='secondary'
            icon='add'
            onClick={() => {
              const path = ReactEditor.findPath(editor, element)
              const insertAt = Path.next(path)
              editor.insertNodes(createProjectCardSection(), { at: insertAt })
            }}
          >
            {t('sticky-notes.add-board')}
          </Button>
        </View>
      )}
    </>
  )
}
