import { Delta, DeltaType, OperationType, diff } from '@sanalabs/json'
import _ from 'lodash'
import { nodeAtPath, rangeOfNode } from 'sierra-client/views/v3-author/queries'
import { assert, assertIsNonNullable, isDefined } from 'sierra-domain/utils'
import { CustomElement, CustomText, SlateDocument } from 'sierra-domain/v3-author'
import { BaseRange, Node, Path, Text } from 'slate'

type SlateOperation =
  | { type: 'insert'; at: Path; value: Node[] }
  | { type: 'update-node'; at: Path; update?: Partial<Node> }

function diffToSlateOperations(delta: Delta, path: Path = []): SlateOperation[] {
  if (delta.type === DeltaType.Object) {
    return delta.operations.flatMap(operation => {
      switch (operation.operationType) {
        case OperationType.Nested: {
          if (operation.key === 'children') {
            return diffToSlateOperations(operation.delta, path)
          } else {
            return [{ type: 'update-node', at: path }]
          }
        }
        case OperationType.Substitution: {
          const { key, value } = operation
          return [{ type: 'update-node', at: path, update: { [key]: value } }]
        }

        case OperationType.Insertion: {
          const { key, value } = operation
          return [{ type: 'update-node', at: path, update: { [key]: value } }]
        }

        case OperationType.Deletion: {
          return [{ type: 'update-node', at: path }]
        }
      }
    })
  } else {
    return delta.operations.flatMap(operation => {
      switch (operation.operationType) {
        case OperationType.Insertion:
          return [
            {
              type: 'insert',
              at: path.concat(operation.index),
              value: CustomElement.or(CustomText).array().parse(operation.values),
            },
          ]

        case OperationType.Nested: {
          const nestedOperations = diffToSlateOperations(operation.delta, path.concat(operation.index))
          return nestedOperations
        }

        case OperationType.Substitution: {
          return [
            {
              type: 'insert',
              at: path.concat(operation.index),
              value: CustomElement.or(CustomText).array().parse(operation.value),
            },
          ]
        }

        case OperationType.Deletion: {
          // We don't handle deletions at this moment
          return []
        }
      }
    })
  }
}

export type ChangedNodeSelection = BaseRange & { type: 'block' | 'text' }

function diffString(string1: string, string2: string): [offsetStart: number, offsetEnd: number][] {
  const array1 = Array.from(string1)
  const array2 = Array.from(string2)
  const delta = diff(array1, array2)
  assert(delta.type === DeltaType.Array)
  return delta.operations.flatMap(operation => {
    switch (operation.operationType) {
      case OperationType.Insertion: {
        return [[operation.index, operation.index + operation.values.length]]
      }
      case OperationType.Substitution: {
        return [[operation.index, operation.index + 1]]
      }
      // We don't support deletions yet
      case OperationType.Deletion:
        if (array2.length === 0) return [[0, 0]]
        return []
      // Nested operations are not possible since this is a flat array
      case OperationType.Nested:
        return []
    }
  })
}

export function calculateChangedNodeSelections(
  previousDocument: SlateDocument,
  currentDocument: SlateDocument
): ChangedNodeSelection[] {
  const slateOperations = diffToSlateOperations(diff(previousDocument, currentDocument))

  const allOperations = slateOperations.flatMap((operation): ChangedNodeSelection[] => {
    const firstPath = operation.at

    switch (operation.type) {
      case 'insert': {
        const firstNode = nodeAtPath(currentDocument, firstPath)
        assertIsNonNullable(firstNode)
        const lastPath = [..._.dropRight(firstPath, 1), (_.last(firstPath) ?? 0) + operation.value.length - 1]
        const lastNode = nodeAtPath(currentDocument, lastPath)
        assertIsNonNullable(lastNode)

        const [anchor] = rangeOfNode([firstNode, firstPath])
        const [, focus] = rangeOfNode([lastNode, lastPath])
        return [{ anchor, focus, type: 'block' }]
      }
      case 'update-node': {
        const oldNode = nodeAtPath(previousDocument, firstPath)
        const newNode = nodeAtPath(currentDocument, firstPath)
        const update = operation.update ?? {}
        if ('text' in update) {
          const oldText = Text.isText(oldNode) ? oldNode.text : ''
          assert(Text.isText(newNode))
          const newTextOffsets = diffString(oldText, newNode.text)
          return newTextOffsets.map(([offset1, offset2]) => ({
            anchor: { path: firstPath, offset: offset1 },
            focus: { path: firstPath, offset: offset2 },
            type: 'text',
          }))
        } else {
          assert(isDefined(newNode))
          const [anchor, focus] = rangeOfNode([newNode, firstPath])
          return [{ anchor, focus, type: 'block' }]
        }
      }
    }
  })

  return _.uniqWith(allOperations, (selection1, selection2) =>
    // We don't care if it is a block or a text selection when checking for uniqueness
    _.isEqual(_.omit(selection1, 'type'), _.omit(selection2, 'type'))
  )
}
