import _ from 'lodash'
import { Dispatch, UnknownAction } from 'redux'
import { withEditorActionsLog } from 'sierra-client/editor/action-log'
import { blockDefinitions } from 'sierra-client/editor/blocks'
import { withVoids } from 'sierra-client/views/v3-author/common/with-voids'
import { CopyPasteWithAssetsOptions } from 'sierra-client/views/v3-author/configuration/copy-paste-with-assets-options'
import { withCopy } from 'sierra-client/views/v3-author/paste/with-copy'
import { withPaste } from 'sierra-client/views/v3-author/paste/with-paste'
import { withPasteLists } from 'sierra-client/views/v3-author/paste/with-paste-lists'
import { withSanitizedSlatePaste } from 'sierra-client/views/v3-author/paste/with-sanitized-slate-paste'
import { withAddMark } from 'sierra-client/views/v3-author/plugins/with-add-mark'
import { withFilePaste } from 'sierra-client/views/v3-author/plugins/with-file-paste'
import { withIds } from 'sierra-client/views/v3-author/plugins/with-ids'
import { withShortcuts } from 'sierra-client/views/v3-author/plugins/with-shortcuts'
import { nanoid12 } from 'sierra-domain/nanoid-extensions'
import { SanaEditor } from 'sierra-domain/v3-author'
import { createParagraph } from 'sierra-domain/v3-author/create-blocks'
import { BaseEditor, Element, Editor as SlateEditor, Text, Transforms, createEditor } from 'slate'
import { withReact } from 'slate-react'

type InstallPlugins = (editor: SlateEditor) => SlateEditor

type ConfigurationOptions = {
  isQuiz: boolean
  editorId: string
  dispatch: Dispatch<UnknownAction>
  pasteFile: (card: string) => void
  excludeReact?: true
  // The slate editor will verify that all ids within a document are unique.
  // If you have additional ids that should not conflict (e.g. from another
  // document that you are copy pasting from), you should return them from this
  // function.
  readExternalIds?: () => Set<string>

  // When enabled, the editor will support copy/pasting of assets (images, videos, etc) between courses.
  copyPasteAssetOptions: CopyPasteWithAssetsOptions
}

/**
 * Install all of the plugins defined in `blockDefinitions` into an instance of the editor
 */
const installSanaBlockPlugins = (editor: SlateEditor): SlateEditor =>
  Object.values(blockDefinitions).reduce((editor, block) => block.plugin?.(editor) ?? editor, editor)

export type SanaEditorOptions = Pick<
  ConfigurationOptions,
  'readExternalIds' | 'pasteFile' | 'excludeReact' | 'copyPasteAssetOptions'
>

/**
 * Create an editor with the core functionality that all instances of the
 * editor share. This includes the ability to, e.g. render and edit all blocks,
 * and copy-paste with formatting.
 */
export const createSanaEditor = (options: SanaEditorOptions): SlateEditor => {
  const editor = (() => {
    const editor = createEditor()
    if (options.excludeReact) return editor
    else return withReact(editor)
  })()

  editor.initialImageUploads = {}
  editor.initialVideoUploads = {}
  editor.initialFileUploads = {}
  editor.initialImageUrls = {}
  editor.importingAssetsFileUrls = {}
  editor.key = nanoid12()

  const installCorePlugins: InstallPlugins = _.flow([
    withEditorActionsLog,
    withAddMark,
    withIds(options.readExternalIds ?? (() => new Set())),
    withFilePaste,
    withShortcuts,
    withPaste(options.pasteFile, options.copyPasteAssetOptions),
    withVoids,
    withCopy(options.copyPasteAssetOptions),
    withPasteLists,
    withSanitizedSlatePaste,
  ])

  return installSanaBlockPlugins(installCorePlugins(editor))
}

// When developing tests using multiple editors it can be convenient to have an
// id for each editor to help with debugging.
export interface TestEditor {
  __editorId: string
}

const withEditorId = <T extends BaseEditor>(editor: T, editorId: string): T & TestEditor => {
  const e = editor as T & TestEditor

  e.__editorId = editorId

  return e
}

type TestEditorOptions = Partial<
  SanaEditorOptions & { children: SanaEditor['children']; verbose?: boolean; editorId?: string }
>

/**
 * Create an editor which can be used for unit testing.
 */
export function createTestEditor({
  pasteFile = () => {},
  children,
  verbose,
  editorId = 'test-editor-' + _.uniqueId(),
  copyPasteAssetOptions,
  ...rest
}: TestEditorOptions = {}): SlateEditor & TestEditor {
  const editor = withEditorId(
    createSanaEditor({
      pasteFile,
      ...rest,
      copyPasteAssetOptions: copyPasteAssetOptions ?? { type: 'disabled' },
    }),
    editorId
  )
  if (children !== undefined) editor.children = children

  if (verbose === true) {
    const { apply } = editor
    editor.apply = op => {
      console.debug(`[TestEditor] Applying operation:`, op)
      apply(op)
    }
  }

  const { normalizeNode } = editor

  editor.normalizeNode = entry => {
    const [node, path] = entry
    if (path.length !== 1) return normalizeNode(entry)

    // Other cards runs this as part of withCard or similar plugin
    if (Text.isText(node) || (Element.isElement(node) && editor.isInline(node))) {
      return Transforms.wrapNodes(editor, createParagraph(), { at: path })
    }

    return normalizeNode(entry)
  }

  return editor
}
