import { useTranslation } from 'sierra-client/hooks/use-translation'
import { EndOfCourseRecommendations } from 'sierra-client/views/course-helper/content/course-helper-recommendations/end-of-course-recommendations'
import { CertificatesAndBadges } from 'sierra-client/views/course-helper/content/program-completed'
import { HelperContent } from 'sierra-client/views/course-helper/shared/shared'

export const CourseCompleted: React.FC = () => {
  const { t } = useTranslation()

  return (
    <HelperContent title={t('helper.course-completed')} icon='racing--flag'>
      <CertificatesAndBadges />
      <EndOfCourseRecommendations />
    </HelperContent>
  )
}
