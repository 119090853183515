import _ from 'lodash'
import { printTree } from 'sierra-client/editor/utils/print-tree'
import { getCurrentlySelectedNode, isElementType } from 'sierra-client/views/v3-author/queries'
import { Entity } from 'sierra-domain/entity'
import { assertNever } from 'sierra-domain/utils'
import { Table, TableDimensions } from 'sierra-domain/v3-author'
import {
  createTableCell,
  createTableHead,
  createTableHeaderCell,
  createTableRow,
} from 'sierra-domain/v3-author/create-blocks'
import { Editor, Element, Node, Path, Point, Transforms } from 'slate'

// Return number of rows, and max cells
export const getDimensions = (table: Table): TableDimensions => {
  const body = table.children.find(n => isElementType('table-body', n))
  const rows = body?.children.length ?? 0
  const columns = body?.children.reduce((max, row) => Math.max(max, row.children.length), 0) ?? 0

  return { rows, columns }
}

const getTableNode = (editor: Editor, path: Path): Entity<Table> | null => {
  const [node] = Editor.node(editor, path)

  if (!isElementType('table', node)) {
    return null
  }

  return node
}

type Operation<Args = void> = (editor: Editor, tablePath: Path, args: Args) => void

export const TableOperations: {
  harmonize: (editor: Editor, tablePath: Path) => boolean
  addRow: Operation<{ index?: number }>
  addTableHead: Operation
  addColumn: Operation<{ index?: number }>
  deleteRow: Operation<{ index: number }>
  deleteColumn: Operation<{ index: number }>
  toggleHeaders: Operation
  move: Operation<{ direction: 'right' | 'left' | 'down' | 'up' }>
  moveRow: Operation<{ from: number; to: number }>
  moveColumn: Operation<{ from: number; to: number }>
} = {
  harmonize(editor, path) {
    let didMutate = false
    Editor.withoutNormalizing(editor, () => {
      const table = getTableNode(editor, path)
      if (!table) return

      // Harmonize rows to max number of cells
      const rows = Array.from(Node.elements(table)).filter(entry => isElementType('table-row', entry[0]))
      const max = rows.reduce((max, [row]) => {
        return Math.max(max, row.children.length)
      }, 0)

      for (const [rowNode, rowPath] of rows) {
        const parent = Editor.parent(editor, [...path, ...rowPath])
        const diff = max - rowNode.children.length
        const cells = _.range(diff).map(() =>
          isElementType('table-head', parent[0])
            ? createTableHeaderCell({ children: [{ text: '' }] })
            : createTableCell()
        )

        if (cells.length > 0) {
          didMutate = true
          Transforms.insertNodes(editor, cells, {
            at: [...path, ...rowPath, rowNode.children.length],
          })
        }
      }
    })
    return didMutate
  },
  addRow(editor, path, { index }) {
    const table = getTableNode(editor, path)
    if (!table) return

    const body = Array.from(Node.elements(table)).find(e => isElementType('table-body', e[0]))
    if (body === undefined) return

    const [bodyNode, bodyPath] = body
    const dimensions = getDimensions(table)

    if (index === undefined || index > dimensions.rows) {
      const at = [...path, ...bodyPath, bodyNode.children.length]
      Transforms.insertNodes(
        editor,
        createTableRow({
          children: [createTableCell()],
        }),
        { at }
      )
      TableOperations.harmonize(editor, path)
    } else {
      Transforms.insertNodes(
        editor,
        createTableRow({
          children: [createTableCell()],
        }),
        { at: [...path, ...bodyPath, index] }
      )
    }
  },
  addTableHead(editor, path) {
    const table = getTableNode(editor, path)
    if (!table) return

    const headEntry = Array.from(Node.elements(table)).find(e => isElementType('table-head', e[0]))
    const bodyEntry = Array.from(Node.elements(table)).find(e => isElementType('table-body', e[0]))

    if (headEntry === undefined || bodyEntry === undefined) return
    const [, headPath] = headEntry
    const [, bodyPath] = bodyEntry

    //Move the current head into the first row in the body
    Transforms.moveNodes(editor, {
      at: [...path, ...headPath, 0],
      to: [...path, ...bodyPath, 0],
    })

    const dimensions = getDimensions(table)
    const createTableHeaderCells = _.range(dimensions.columns).map(() =>
      createTableHeaderCell({
        children: [
          {
            text: '',
          },
        ],
      })
    )

    //Insert a new empty table head
    Transforms.insertNodes(
      editor,
      createTableHead({
        children: [
          createTableRow({
            children: createTableHeaderCells,
          }),
        ],
      }),
      { at: [...path, ...headPath] }
    )

    printTree(editor.children)
  },
  addColumn(editor, path, { index }) {
    const table = getTableNode(editor, path)
    if (table === null) return

    const bodyEntry = Array.from(Node.elements(table)).find(e => isElementType('table-body', e[0]))
    if (bodyEntry === undefined) return

    const bodyRows = Array.from(Node.elements(bodyEntry[0]))
    const dimensions = getDimensions(table)

    // Insert a new cell into each row of the body
    Editor.withoutNormalizing(editor, () => {
      if (index === undefined || dimensions.columns < index) {
        for (const [rowNode, rowPath] of bodyRows) {
          if (isElementType('table-row', rowNode)) {
            Transforms.insertNodes(editor, createTableCell(), {
              at: [...path, ...bodyEntry[1], ...rowPath, rowNode.children.length],
            })
          }
        }
      } else {
        const rows = Array.from(Node.elements(table)).filter(entry => isElementType('table-row', entry[0]))
        for (const [rowNode, rowPath] of rows) {
          if (rowNode.children.length <= index) continue
          Transforms.insertNodes(editor, createTableCell(), { at: [...path, ...rowPath, index] })
        }
      }
      // Harmonize to make sure all rows align
      TableOperations.harmonize(editor, path)
    })
  },
  deleteRow(editor, path, { index }) {
    const table = getTableNode(editor, path)
    if (table === null) return

    const bodyEntry = Array.from(Node.descendants(table)).filter(([child]) =>
      isElementType('table-body', child)
    )[0]
    if (bodyEntry === undefined) return

    const rows = Array.from(Node.elements(bodyEntry[0])).filter(entry => isElementType('table-row', entry[0]))
    const rowPath = rows[index]?.[1]

    if (rowPath === undefined || rows.length < 2) return

    Transforms.removeNodes(editor, { at: [...path, ...bodyEntry[1], ...rowPath] })
  },
  deleteColumn(editor, path, { index }) {
    Editor.withoutNormalizing(editor, () => {
      const table = getTableNode(editor, path)
      if (table === null) return

      const dimensions = getDimensions(table)

      if (index > dimensions.columns - 1 || dimensions.columns <= 1) return

      const rows = Array.from(Node.elements(table)).filter(entry => isElementType('table-row', entry[0]))

      for (const [rowNode, rowPath] of rows) {
        if (rowNode.children.length <= index) continue
        Transforms.removeNodes(editor, { at: [...path, ...rowPath, index] })
      }
    })
  },
  toggleHeaders(editor, path) {
    const table = getTableNode(editor, path)
    if (table === null) return

    const { withHeaders } = table.options

    Transforms.setNodes(
      editor,
      {
        options: {
          withHeaders: !Boolean(withHeaders),
        },
      },
      { at: path }
    )
  },
  move(editor, path, { direction }) {
    const table = getTableNode(editor, path)
    if (table === null) return

    const cell = getCurrentlySelectedNode(editor)
    if (cell === undefined) return

    const currentRowPath = Path.parent(cell[1])
    const rows = Array.from(Node.descendants(table)).filter(e => isElementType('table-row', e[0]))
    const rowIndex = rows.findIndex(([, path]) => Path.equals(path, currentRowPath.slice(-path.length)))
    const cellIndex = cell[1][cell[1].length - 1] ?? 0

    const nextRow = rows[rowIndex + (['down', 'right'].includes(direction) ? 1 : -1)] ?? null
    let nextPoint: Point | undefined

    switch (direction) {
      case 'right':
      case 'left': {
        const horizontalDirection = direction
        const row = Editor.node(editor, Path.parent(cell[1]))

        if (!isElementType('table-row', row[0])) return

        const isLastCell = cellIndex === row[0].children.length - 1
        const isFirstCell = cellIndex === 0

        if (horizontalDirection === 'right') {
          if (isLastCell) {
            if (nextRow !== null) {
              nextPoint = { path: [...path, ...nextRow[1], 0, 0], offset: 0 }
            } else {
              nextPoint = Editor.after(editor, path)
            }
          } else {
            nextPoint = { path: [...row[1], cellIndex + 1, 0], offset: 0 }
          }
        } else {
          if (isFirstCell) {
            if (nextRow !== null) {
              nextPoint = { path: [...path, ...nextRow[1], row[0].children.length - 1, 0], offset: 0 }
            } else {
              nextPoint = Editor.before(editor, path)
            }
          } else {
            nextPoint = { path: [...row[1], cellIndex - 1, 0], offset: 0 }
          }
        }
        break
      }
      case 'up':
      case 'down': {
        const verticalDirection = direction
        if (nextRow !== null && isElementType('table-row', nextRow[0])) {
          nextPoint = { path: [...path, ...nextRow[1], cellIndex, 0], offset: 0 }
        } else {
          nextPoint = verticalDirection === 'down' ? Editor.after(editor, path) : Editor.before(editor, path)
        }
        break
      }
      default:
        assertNever(direction)
    }

    if (nextPoint !== undefined) {
      Transforms.select(editor, {
        anchor: nextPoint,
        focus: nextPoint,
      })
    }
  },
  moveRow(editor, path, params) {
    const table = getTableNode(editor, path)
    if (table === null) return

    const bodyEntry = Array.from(Node.descendants(table)).filter(([child]) =>
      isElementType('table-body', child)
    )[0]

    if (bodyEntry === undefined || !Element.isElement(bodyEntry[0])) return

    const { rows } = getDimensions(table)
    const from = params.from
    const to = Math.min(rows - 1, Math.max(0, params.to))

    if (from === to) return

    const fromPath = [...path, ...bodyEntry[1], from]
    const toPath = [...path, ...bodyEntry[1], to]

    try {
      Transforms.moveNodes(editor, {
        at: fromPath,
        to: toPath,
      })
    } catch (error) {
      return null
    }
  },
  moveColumn(editor, path, params) {
    const table = getTableNode(editor, path)
    if (table === null) return

    const rows = Array.from(Editor.nodes(editor, { at: path })).filter(entry =>
      isElementType('table-row', entry[0])
    )

    const { columns } = getDimensions(table)
    const from = params.from
    const to = Math.min(columns - 1, Math.max(0, params.to))

    if (from === to) return

    for (const [, rowPath] of rows) {
      const fromPath = [...rowPath, from]
      const toPath = [...rowPath, to]

      try {
        Transforms.moveNodes(editor, {
          at: fromPath,
          to: toPath,
        })
      } catch (error) {
        return null
      }
    }
  },
}
