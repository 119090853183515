import { useQuery } from '@tanstack/react-query'
import { typedPost } from 'sierra-client/state/api'
import { XRealtimeAdminCalendarEventsEnrollmentRequestCount } from 'sierra-domain/routes'

export const useEventGroupEnrollmentRequestsPill = (): string | undefined => {
  const count = useQuery({
    queryKey: [XRealtimeAdminCalendarEventsEnrollmentRequestCount.path],
    queryFn: () => typedPost(XRealtimeAdminCalendarEventsEnrollmentRequestCount, {}),
  })

  return count.data?.count !== undefined && count.data.count > 0 ? `${count.data.count}` : undefined
}
