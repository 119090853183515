import type { TypedActionCreator } from '@reduxjs/toolkit/dist/listenerMiddleware/types'
import _ from 'lodash'
import type { UnknownAction } from 'redux'
import { collabProviderStatusChanged, removeCollabProvider } from 'sierra-client/state/collaboration/actions'
import * as liveActions from 'sierra-client/state/live/actions'
import type { RootState } from 'sierra-client/state/types'

/**
 * The nested path for the parts of the state that are safe to send to external services.
 * The paths will be passed to `lodash.get`, any valid subpath for the state will be valid.
 * These should not conain any sensitive information such as PII, tokens, passwords, secrets, etc.
 *
 * TODO: if this could be typed it would be awesome
 */
const SAFE_STATE_PATHS: string[] = [
  'liveSession.awareness',
  'live.callState',
  'live.userSettings',
  'collaboration.collabProviderStatuses',
]

/**
 * The action types that are safe to send to external services.
 * The data in these actions should not conain any sensitive information such as PII, tokens, passwords, secrets, etc.
 */
const SAFE_ACTION_TYPES: TypedActionCreator<string>[] = [
  ..._.chain(liveActions)
    .values()
    .map(
      action =>
        ('fulfilled' in action
          ? [action.pending, action.fulfilled, action.rejected]
          : action) as TypedActionCreator<string>
    )
    .flatten()
    .value(),
  collabProviderStatusChanged,
  removeCollabProvider,
]

const SAFE_ACTION_TYPE_SET = new Set(SAFE_ACTION_TYPES.map(action => action.type))

export const actionTransformer = (action: UnknownAction): UnknownAction | null => {
  if (SAFE_ACTION_TYPE_SET.has(action.type)) {
    return action
  }

  return null
}

export const stateTransformer = (state: RootState): Partial<RootState> => {
  const safeState: Partial<RootState> = {}
  for (const safeKey of SAFE_STATE_PATHS) {
    const value = _.get(state, safeKey)
    _.set(safeState, safeKey, value)
  }

  return safeState
}
