import { v2_breakpoint } from 'sierra-ui/theming/breakpoints'
import styled, { css } from 'styled-components'

const cardGridV2Css = css`
  display: grid;
  width: 100%;
  padding: 0 1rem;
  position: relative;

  grid-template-columns: repeat(6, 1fr);
  column-gap: 1rem;

  & > * {
    grid-column: 1 / span 6;
  }

  @media screen and (min-width: ${v2_breakpoint.phone}) {
    padding: 0 1.5rem;
    grid-template-columns: repeat(12, 1fr);
    column-gap: 1.5rem;

    & > * {
      grid-column: 2 / span 10;
    }
  }
`

export const CardGridV2 = styled.div`
  ${cardGridV2Css}

  & + & {
    margin-top: 1.5rem;
  }
`
