import { UseQueryResult } from '@tanstack/react-query'
import { getInsightsQueryOptions } from 'sierra-client/features/insights/api-hooks/query-options'
import { typedInvalidateQuery, useCachedQuery } from 'sierra-client/state/api'
import {
  InsightsDashboardListCollaboratorsRequest,
  InsightsDashboardListCollaboratorsResponse,
} from 'sierra-domain/api/insights'
import { NanoId12 } from 'sierra-domain/api/nano-id'
import { XAnalyticsDashboardListCollaborators } from 'sierra-domain/routes'

export const useDashboardCollaborators = (
  dashboardId: NanoId12
): UseQueryResult<InsightsDashboardListCollaboratorsResponse> => {
  const collaboratorsData = useCachedQuery(
    XAnalyticsDashboardListCollaborators,
    { dashboardId },
    { ...getInsightsQueryOptions<InsightsDashboardListCollaboratorsResponse>() }
  )

  return collaboratorsData
}

export const invalidateDashboardCollaborators = async (
  input: InsightsDashboardListCollaboratorsRequest
): Promise<void> => {
  await typedInvalidateQuery(XAnalyticsDashboardListCollaborators, input)
}
