import React from 'react'
import { useSelector } from 'sierra-client/state/hooks'
import { selectQuestionCards } from 'sierra-client/state/self-paced/selectors'
import { AssessmentState } from 'sierra-client/views/v3-author/assessment-card/assessment-context'
import { IconButton, View } from 'sierra-ui/primitives'
import { token } from 'sierra-ui/theming'
import styled from 'styled-components'

const Dot = styled.div<{ completed: boolean; selected: boolean }>`
  background-color: ${p => p.theme.home.textColor};
  opacity: ${p => (p.completed ? 1 : 0.2)};
  width: ${p => (p.selected ? '8px' : '3px')};
  height: ${p => (p.selected ? '8px' : '3px')};
  border-radius: 99px;
`

type Disabled = { disabled: boolean }

const TransparentIconButton = styled(IconButton)<Disabled>`
  ${p => p.disabled && 'background: transparent;'}
`

const ProgressBarContainer = styled(View).attrs({ alignItems: 'center', direction: 'row' })`
  background-color: ${token('foreground/primary').opacity(0.05)};
  border-radius: 12px;
  width: fit-content;
  height: fit-content;
`

export const ProgressBar: React.FC<{
  assessmentState?: AssessmentState
  next?: () => void | Promise<void>
  previous?: () => void | Promise<void>
}> = ({ assessmentState, next, previous }) => {
  const questionCardsInRedux = useSelector(selectQuestionCards)()

  if (assessmentState === undefined) return null

  if (questionCardsInRedux === undefined) return null

  if (assessmentState.status !== 'during') return null

  const finishedQuestionIndices = assessmentState.questionExerciseIds.map(exerciseId => {
    const responses = questionCardsInRedux[exerciseId]?.responses
    if (responses === undefined) return false
    return Object.values(responses).some(it => it.sessionId === assessmentState.sessionId)
  })

  const lastIndex = assessmentState.passingCriteria - 1

  return (
    <ProgressBarContainer>
      <View style={{ height: '36px' }} gap='8'>
        <TransparentIconButton
          disabled={assessmentState.currentIndex === 0}
          variant='transparent'
          onClick={previous}
          iconId='chevron--left--small'
        />

        {finishedQuestionIndices.map((bar, index) => {
          return (
            <Dot
              selected={index === assessmentState.currentIndex}
              completed={bar || index === assessmentState.currentIndex}
              key={index}
            />
          )
        })}

        <TransparentIconButton
          disabled={
            assessmentState.currentIndex === lastIndex && finishedQuestionIndices.some(it => it !== true)
          }
          variant='transparent'
          onClick={next}
          iconId='chevron--right--small'
        />
      </View>
    </ProgressBarContainer>
  )
}
