import * as VisuallyHidden from '@radix-ui/react-visually-hidden'
import { FC, useContext } from 'react'
import {
  ThemePicker,
  useAvailableCustomThemeNames,
} from 'sierra-client/components/common/inputs/theme-picker'
import { isDefined, isNonNullable } from 'sierra-domain/utils'
import { Icon, useFormElementContext } from 'sierra-ui/components'
import { Text, View } from 'sierra-ui/primitives'
import { palette, spacing, token } from 'sierra-ui/theming'
import {
  CustomThemeName,
  PresetThemeName,
  getTheme,
  isCustomThemeName,
  isPresetThemeName,
  presetThemeNames,
} from 'sierra-ui/theming/legacy-theme'
import styled, { ThemeContext, ThemeProvider, css } from 'styled-components'

const getSafeThemeName = (theme?: string): PresetThemeName | CustomThemeName => {
  return isDefined(theme) && (isPresetThemeName(theme) || isCustomThemeName(theme)) ? theme : 'black'
}

const IconView = styled(View).attrs({
  justifyContent: 'center',
  alignItems: 'center',
})<{ $inactive?: boolean; $small: boolean }>`
  flex: 0 0 auto;
  color: ${p => p.theme.home.textColor};
  background: ${p => p.theme.home.backgroundColor};
  border-radius: ${p => (p.$small ? '6px' : '10px')};
  width: ${p => (p.$small ? '20px' : spacing['32'])};
  height: ${p => (p.$small ? '20px' : spacing['32'])};
  transition: all 50ms cubic-bezier(0.25, 0.1, 0.25, 1);
  border: 1px solid
    ${p => {
      return p.theme.home.backgroundColor.toLowerCase() === '#ffffff'
        ? token('border/default')
        : 'transparent'
    }} !important;

  ${p =>
    p.$inactive === true &&
    css`
      color: ${p => p.theme.color.grey25};
      background-color: ${palette.grey[5]};

      & > * {
        filter: grayscale(100%);
      }
    `}
`

const IconLetterView = styled(View).attrs({
  justifyContent: 'center',
  alignItems: 'center',
})<{ $small: boolean }>`
  pointer-events: none;
  user-select: none;
  text-transform: uppercase;
  font-size: ${p => (p.$small ? '9px' : '14px')};
  font-style: normal;
  font-weight: 500;
  height: 100%;
`

const getFirstLetterOrEmoji = (displayName: string): string => {
  const firstCodePoint = displayName.codePointAt(0)
  const firstLetterOrEmoji = isNonNullable(firstCodePoint) ? String.fromCodePoint(firstCodePoint) : ''

  return firstLetterOrEmoji.toUpperCase()
}

export type TeamspaceIconProps = {
  displayName: string
  themeName?: string
  inactive?: boolean
  small?: boolean
}

export const TeamspaceIcon: FC<TeamspaceIconProps> = ({ displayName, themeName, inactive, small }) => {
  const themeContext = useContext(ThemeContext)
  const safeThemeName = getSafeThemeName(themeName)
  const theme = getTheme(themeContext, safeThemeName)

  return (
    <ThemeProvider theme={theme}>
      <IconView $small={small === true} aria-hidden='true' $inactive={inactive}>
        <IconLetterView $small={small === true}>
          <Text color='currentColor' size='technical'>
            {getFirstLetterOrEmoji(displayName)}
          </Text>
        </IconLetterView>
      </IconView>
    </ThemeProvider>
  )
}

const UnsetButton = styled.button`
  all: unset;
  display: flex;
  align-items: center;
  gap: 8px;
  height: 100%;
`

const CursorView = styled(View)`
  cursor: pointer;
`

export const TeamspaceIconInput: FC<{
  displayName?: string
  selectedThemeName?: string
  onThemeChange: (theme: string) => void
}> = ({ selectedThemeName, onThemeChange, displayName }) => {
  const themeContext = useContext(ThemeContext)
  const safeThemeName = getSafeThemeName(selectedThemeName)
  const theme = getTheme(themeContext, safeThemeName)
  const availableCustomThemeNames = useAvailableCustomThemeNames()
  const formElementContext = useFormElementContext()

  return (
    <ThemeProvider theme={theme}>
      <CursorView direction='row' justifyContent='center' gap='8' grow>
        <ThemePicker
          currentTheme={safeThemeName}
          textPreview={isDefined(displayName) ? getFirstLetterOrEmoji(displayName) : undefined}
          onClick={theme => {
            onThemeChange(theme)
          }}
          trigger={
            <UnsetButton aria-hidden id={formElementContext.formId}>
              <TeamspaceIcon themeName={safeThemeName} displayName={displayName ?? ''} />
              <Icon iconId='edit' color='foreground/primary' />
            </UnsetButton>
          }
        />

        <VisuallyHidden.Root>
          <select
            name='icon-theme'
            value={safeThemeName}
            onChange={event => {
              onThemeChange(event.target.value)
            }}
          >
            {[...presetThemeNames, ...availableCustomThemeNames].map(option => (
              <option key={option} value={option}>
                {option}
              </option>
            ))}
          </select>
        </VisuallyHidden.Root>
      </CursorView>
    </ThemeProvider>
  )
}
