import { DropResult } from '@hello-pangea/dnd'
import { Editor, Path, Transforms } from 'slate'

type MoveParams = { editor: Editor; at: Path; to: Path }

const moveTopLevelElement = ({ editor, at, to }: MoveParams): void => {
  Transforms.moveNodes(editor, { at, to })
}

export const onDragEnd = (result: DropResult, editor: Editor): void => {
  editor.pushActionsLogEntry({
    type: 'on-drag-end',
    hasDestination: result.destination !== null,
  })

  if (!result.destination) return

  const sourceDroppableId = result.source.droppableId
  const destinationDroppableId = result.destination.droppableId

  // The user can't mix and match between both
  if (sourceDroppableId !== destinationDroppableId) return

  // Indices will only be one number as we're on the top level
  const pathOld = [result.source.index]
  const pathNew = [result.destination.index]

  switch (sourceDroppableId) {
    case 'top-level':
      moveTopLevelElement({ editor, at: pathOld, to: pathNew })
      break
    default:
      throw new Error('Invalid droppable type.')
  }
}
