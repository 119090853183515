import React from 'react'
import { TableInstance } from 'react-table'
import { RoundedSearchBar } from 'sierra-client/views/manage/components/rounded-search-bar'
import { IconButton, Spacer } from 'sierra-ui/primitives'
import styled from 'styled-components'

const SmallSearchBar = styled(RoundedSearchBar)`
  width: 17.5rem;
`

const SearchBarContainer = styled.div`
  display: flex;
  justify-content: flex-end;
  align-items: center;
`

const SearchIconContainer = styled.div`
  position: absolute;
  top: 50%;
  right: 0;
  transform: translateY(-50%);
`

export const TableMediumSearchTrigger: React.FC<{ onClick: () => void }> = ({ onClick }) => {
  return (
    <SearchIconContainer>
      <IconButton variant='transparent' iconId='search' onClick={onClick} />
    </SearchIconContainer>
  )
}

interface TableMediumSearchHeaderManualProps {
  searchPlaceholder: string
  value: string
  onChange: (value: string) => void
  onClose: () => void
}

export const TableMediumSearchHeaderManual = ({
  value,
  onChange,
  searchPlaceholder,
  onClose,
}: TableMediumSearchHeaderManualProps): JSX.Element => {
  return (
    <SearchBarContainer>
      <SmallSearchBar value={value} onChange={onChange} placeholder={searchPlaceholder} autoFocus />
      <Spacer />
      <IconButton variant='transparent' iconId='close' onClick={onClose} />
    </SearchBarContainer>
  )
}

interface TableMediumSearchHeaderProps<T extends Record<string, unknown> = Record<string, unknown>> {
  tableInstance: TableInstance<T>
  searchPlaceholder: string
  onClose: () => void
}

export const TableMediumSearchHeader = <T extends Record<string, unknown> = Record<string, unknown>>({
  tableInstance,
  searchPlaceholder,
  onClose,
}: TableMediumSearchHeaderProps<T>): JSX.Element => {
  return (
    <TableMediumSearchHeaderManual
      value={tableInstance.state.globalFilter ?? ''}
      onChange={value => tableInstance.setGlobalFilter(value)}
      searchPlaceholder={searchPlaceholder}
      onClose={onClose}
    />
  )
}
