import { LinkLabel } from 'sierra-client/views/workspace/learn/link-label'
import { Heading, Text, View } from 'sierra-ui/primitives'
import { palette, token } from 'sierra-ui/theming'
import styled, { css } from 'styled-components'

const CardBase = styled(View).attrs({
  direction: 'column',
  justifyContent: 'space-between',
})`
  border-radius: ${p => p.theme.borderRadius.large};
  padding: 1.75rem 2rem;
`

export const BorderCard = styled(CardBase)`
  border: 1px solid ${token('border/default')};
`

// todo(damjan): refactor
export const CompletionCard = styled(CardBase)`
  background-color: ${p => p.theme.color.blueDark};
`

// todo(damjan): refactor
const SecondaryCardBase = styled(CardBase)`
  background-color: ${p => p.theme.color.blueVivid};
`

type SecondaryCardProps = {
  title: string | JSX.Element
  metric: string | number
  description: string
}
export const SecondaryCard: React.FC<SecondaryCardProps> = ({ title, metric, description }) => (
  <SecondaryCardBase gap='medium'>
    <View direction='column'>
      {typeof title === 'string' ? (
        <Text size='small' phone='small' bold>
          {title}
        </Text>
      ) : (
        title
      )}
      <Heading size='h3' bold>
        {metric}
      </Heading>
    </View>
    <View direction='column'>
      <Text size='small' phone='small' color='foreground/muted'>
        {description}
      </Text>
    </View>
  </SecondaryCardBase>
)

export const CardLabel = styled(Text).attrs({
  size: 'micro',
  phone: 'small',
  bold: true,
  maxWidth: '18ch',
})``

export const CardHeading1 = styled(Heading).attrs({
  size: 'h4',
  bold: true,
  maxWidth: '18ch',
})``

export const CardHeading2 = styled(Heading).attrs({
  size: 'h4',
  bold: true,
  maxWidth: '18ch',
})``

export const FadedLabel = styled(CardLabel)`
  opacity: 0.5;
`

type UserStatus = 'all-done' | 'on-track' | 'at-risk' | 'needs-attention'

export const StatusBorderCard = styled(BorderCard).attrs<{ status: UserStatus }>({ gap: 'medium' })<{
  status: UserStatus
}>`
  ${p =>
    p.status === 'on-track' || p.status === 'all-done'
      ? css`
          background-color: ${p.theme.color.greenBright};

          ${CardLabel} {
            color: ${palette.primitives.white};
          }

          ${CardHeading1} {
            color: ${p.theme.color.greenDark};
          }

          ${CardHeading2} {
            color: ${palette.primitives.white};
          }

          ${LinkLabel} {
            color: ${palette.primitives.white};
          }

          ${FadedLabel} {
            color: ${palette.primitives.white};
          }
        `
      : p.status === 'needs-attention'
        ? css`
            background-color: ${p.theme.color.redVivid};

            ${CardLabel} {
              color: ${p.theme.color.redDark};
            }

            ${CardHeading1} {
              color: ${p.theme.color.redDark};
            }

            ${CardHeading2} {
              color: ${palette.primitives.white};
            }
          `
        : css`
            ${CardLabel} {
              color: ${palette.primitives.black};
            }

            ${LinkLabel} {
              color: ${palette.primitives.black};
            }

            ${CardHeading1} {
              color: ${p.theme.color.redVivid};
            }
          `}
`
