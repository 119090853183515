import { graphql } from 'sierra-client/api/graphql/gql'
import { graphQuery } from 'sierra-client/api/hooks/use-graphql-query'
import { UserId } from 'sierra-domain/api/uuid'

type IssueCertificate = (params: {
  certificateId: string
  userId: UserId
  issuedAt: string
  expiresAt: string | undefined
  supportingFileId: string | undefined
  supportingNote: string | undefined
  issuedByProxy: UserId | undefined
}) => Promise<string>

export const useIssueCertificate = (): IssueCertificate => {
  const issueCertificate: IssueCertificate = async ({
    certificateId,
    userId,
    issuedAt,
    expiresAt,
    supportingFileId,
    supportingNote,
    issuedByProxy,
  }) => {
    const result = await graphQuery(
      graphql(`
        mutation manuallyIssueCertificate(
          $certificateId: CertificateId!
          $userId: UserId!
          $issuedAt: Timestamp!
          $expiresAt: Timestamp
          $supportingFileId: String
          $supportingNote: String
          $issuedByProxy: UserId
        ) {
          manuallyIssueCertificate(
            certificateId: $certificateId
            userId: $userId
            issuedAt: $issuedAt
            expiresAt: $expiresAt
            supportingFileId: $supportingFileId
            supportingNote: $supportingNote
            issuedByProxy: $issuedByProxy
          ) {
            issuedCertificate {
              id
            }
          }
        }
      `),
      {
        certificateId,
        userId,
        issuedAt,
        expiresAt,
        supportingFileId,
        supportingNote,
        issuedByProxy,
      }
    )
    return result.manuallyIssueCertificate.issuedCertificate.id
  }

  return issueCertificate
}
