import { graphql } from 'sierra-client/api/graphql/gql'
import {
  AddCertificateToProgramMutation,
  RemoveCertificateFromProgramMutation,
} from 'sierra-client/api/graphql/gql/graphql'
import { graphQuery } from 'sierra-client/api/hooks/use-graphql-query'

export const useAddCertificateToProgram = (): ((
  programId: string,
  certificateId: string
) => Promise<AddCertificateToProgramMutation>) => {
  const onAdd = (programId: string, certificateId: string): Promise<AddCertificateToProgramMutation> =>
    graphQuery(
      graphql(`
        mutation addCertificateToProgram($programId: ProgramId!, $certificateId: CertificateId!) {
          addCertificateToProgram(programId: $programId, certificateId: $certificateId) {
            certificateIds
          }
        }
      `),
      { programId, certificateId }
    )

  return onAdd
}

export const useDeleteCertificateFromProgram = (): ((
  programId: string,
  certificateId: string
) => Promise<RemoveCertificateFromProgramMutation>) => {
  const onDelete = (
    programId: string,
    certificateId: string
  ): Promise<RemoveCertificateFromProgramMutation> =>
    graphQuery(
      graphql(`
        mutation removeCertificateFromProgram($programId: ProgramId!, $certificateId: CertificateId!) {
          removeCertificateFromProgram(programId: $programId, certificateId: $certificateId) {
            certificateIds
          }
        }
      `),
      { programId, certificateId }
    )

  return onDelete
}
