import { createFileRoute } from '@tanstack/react-router'
import { requireLoggedIn } from 'sierra-client/core/require-logged-in'
import { EventGroupPage } from 'sierra-client/views/learner/event-group/event-group-page'
import { EventGroupId, PathId } from 'sierra-domain/api/nano-id'
import { z } from 'zod'

const RouteComponent = requireLoggedIn(() => {
  const eventGroupId = Route.useParams({ select: params => params.eventGroupId })
  return <EventGroupPage eventGroupId={eventGroupId} />
})

export const Route = createFileRoute('/path/$pathId/event/$eventGroupId/')({
  component: RouteComponent as React.FC,
  params: {
    parse: z.object({ pathId: PathId, eventGroupId: EventGroupId }).parse,
    stringify: p => p,
  },
})
