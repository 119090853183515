import { DragDropContext, Draggable, Droppable } from '@hello-pangea/dnd'
import _ from 'lodash'
import { useCallback } from 'react'
import { createSelector } from 'reselect'
import { useBreakoutRoomFacilitatorActions } from 'sierra-client/components/liveV2/live-provider'
import {
  CollapsableSidebarItem,
  Divider,
  EmptyPlaceholder,
  EmptyText,
} from 'sierra-client/components/liveV2/right-sidebar/components'
import { ParticipantListItem } from 'sierra-client/components/liveV2/right-sidebar/participant-list-item'
import { useTranslation } from 'sierra-client/hooks/use-translation'
import { useDispatch, useSelector, useStore } from 'sierra-client/state/hooks'
import {
  selectAllBreakoutRooms,
  selectAllSessionParticipantsIncludingMe,
  selectFacilitatorIds,
  selectIsFacilitator,
  selectLiveSessionLocalAwarenessStateUserId,
} from 'sierra-client/state/live-session/selectors'
import { liveSessionSlice } from 'sierra-client/state/live-session/slice'
import * as breakoutRooms from 'sierra-client/state/live-session/utils'
import { selectCurrentBreakoutRoomId } from 'sierra-client/state/live/selectors'
import { NanoId12 } from 'sierra-domain/api/nano-id'
import { color } from 'sierra-ui/color'
import { Button, View } from 'sierra-ui/primitives'
import { palette, spacing } from 'sierra-ui/theming'
import { fonts } from 'sierra-ui/theming/fonts'
import { ConditionalWrapper } from 'sierra-ui/utils/conditional-wrapper'
import styled, { css } from 'styled-components'

const DroppableWrapper = styled.div<{ isDraggingOver: boolean; draggingFromThisWith: boolean }>`
  ${p =>
    p.isDraggingOver &&
    css`
      background-color: ${color(p.theme.color.blueVivid).opacity(0.1).toString()};
      border-radius: ${p.theme.borderRadius.regular};
    `}
  ${p =>
    p.draggingFromThisWith &&
    css`
      background-color: ${palette.grey[2]};
    `}
`

const DraggableWrapper = styled.div`
  div,
  p {
    cursor: unset;
  }
`

const AddRoomButton = styled.button`
  font-size: small;
  position: relative;
  background-color: ${palette.grey[5]};
  font-weight: ${fonts.weight.medium};
  border: 2px solid transparent;
  height: 2rem;
  padding: ${spacing['4']} 0;
  transition: opacity 150ms cubic-bezier(0.77, 0, 0.175, 1);
  justify-content: center;
  border-radius: ${p => p.theme.borderRadius.regular};
  cursor: pointer;
`

const selectRooms = createSelector(
  [
    selectAllSessionParticipantsIncludingMe,
    selectAllBreakoutRooms,
    selectCurrentBreakoutRoomId,
    selectLiveSessionLocalAwarenessStateUserId,
    selectIsFacilitator,
    selectFacilitatorIds,
    (_, mainRoomTitle: string) => mainRoomTitle,
  ],
  (
    participants,
    allBreakoutRooms,
    currentBreakoutRoomId,
    awarenessUserId,
    isFacilitator,
    facilitatorIds,
    mainRoomTitle
  ) => {
    const assignedParticipantIds = allBreakoutRooms.flatMap(room => room.participants)
    const nonAssignedParticipantIds = participants
      .filter(participant => !assignedParticipantIds.includes(participant.userId))
      .map(participants => participants.userId)

    const mainRoom = {
      id: 'main' as const,
      title: mainRoomTitle,
      participants: nonAssignedParticipantIds,
    }

    const allRooms = [mainRoom, ...allBreakoutRooms]
    const allRoomsWithParticipants = allRooms.map(room => {
      const participantsInRoom = participants.filter(participant => {
        if (
          facilitatorIds.includes(participant.userId) &&
          nonAssignedParticipantIds.includes(participant.userId)
        ) {
          // Note:  for facilitators that are not assigned to a room, we show them where they actually are,
          //        this is because facilitators can move between rooms without being assigned to them
          return (
            (room.id === 'main' && participant.breakoutRoomId === undefined) ||
            participant.breakoutRoomId === room.id
          )
        }
        return room.participants.includes(participant.userId)
      })
      return {
        id: room.id,
        title: room.title,
        assignedParticipants: room.participants,
        participantsInRoom,
        isCurrentUserInRoom:
          room.id === 'main' ? currentBreakoutRoomId === undefined : currentBreakoutRoomId === room.id,
      }
    })

    const sortedRooms = isFacilitator
      ? allRoomsWithParticipants
      : allRoomsWithParticipants.sort((a, b) => {
          if (awarenessUserId === undefined) return 0
          if (a.assignedParticipants.includes(awarenessUserId)) return -1
          if (b.assignedParticipants.includes(awarenessUserId)) return 1
          return 0
        })
    return sortedRooms
  }
)

const RoomComponent: React.FC<{ roomId: string }> = ({ roomId }) => {
  const isFacilitator = useSelector(selectIsFacilitator)
  const dispatch = useDispatch()
  const { t } = useTranslation()

  const room = useSelector(
    state => selectRooms(state, t('live.main-room')).find(it => it.id === roomId),
    _.isEqual
  )

  const renameRoom = useCallback(
    (roomId: NanoId12, newName: string): void => {
      void dispatch(liveSessionSlice.actions.renameBreakoutRoom({ roomId, newName }))
    },
    [dispatch]
  )
  const { joinBreakoutRoom, returnToMainRoom } = useBreakoutRoomFacilitatorActions()
  if (room === undefined) return null
  return (
    <>
      <View direction='column'>
        <ConditionalWrapper
          condition={isFacilitator}
          renderWrapper={children => (
            <Droppable droppableId={room.id} type='participants-in-sidebar'>
              {(provided, snapshot) => (
                <DroppableWrapper
                  ref={provided.innerRef}
                  isDraggingOver={snapshot.isDraggingOver}
                  draggingFromThisWith={snapshot.draggingFromThisWith !== null}
                  {...provided.droppableProps}
                >
                  {children}
                  {provided.placeholder}
                </DroppableWrapper>
              )}
            </Droppable>
          )}
        >
          <CollapsableSidebarItem
            title={room.title ?? 'Room'}
            itemCount={room.participantsInRoom.length}
            onTitleChanged={
              isFacilitator && room.id !== 'main'
                ? newTitle => room.id !== 'main' && renameRoom(room.id, newTitle)
                : undefined
            }
          >
            {room.participantsInRoom.length === 0 ? (
              <EmptyPlaceholder>
                <EmptyText>{t('live.breakout-room.no-people-in-room')}</EmptyText>
              </EmptyPlaceholder>
            ) : (
              <ul>
                {room.participantsInRoom.map((participant, index) => (
                  <ConditionalWrapper
                    key={participant.agoraUID}
                    condition={isFacilitator}
                    renderWrapper={children => (
                      <Draggable draggableId={participant.agoraUID} index={index}>
                        {(provided /* snapshot */) => (
                          <DraggableWrapper
                            ref={provided.innerRef}
                            {...provided.draggableProps}
                            {...provided.dragHandleProps}
                          >
                            {children}
                          </DraggableWrapper>
                        )}
                      </Draggable>
                    )}
                  >
                    <ParticipantListItem key={participant.agoraUID} participant={participant} />
                  </ConditionalWrapper>
                ))}
              </ul>
            )}
          </CollapsableSidebarItem>
        </ConditionalWrapper>
        {isFacilitator && (
          <View>
            <Button
              variant='secondary'
              disabled={room.isCurrentUserInRoom}
              onClick={() => {
                if (room.id === 'main') void returnToMainRoom()
                else void joinBreakoutRoom(room.id)
              }}
            >
              {room.isCurrentUserInRoom ? t('live.breakout-room.joined') : t('live.breakout-room.join')}
            </Button>
          </View>
        )}
      </View>
      <Divider />
    </>
  )
}

export const BreakoutParticipantLists = (): JSX.Element => {
  const isFacilitator = useSelector(selectIsFacilitator)
  const store = useStore()

  const dispatch = useDispatch()
  const { t } = useTranslation()

  const roomIds = useSelector(
    state => selectRooms(state, t('live.main-room')).map(room => room.id),
    _.isEqual
  )

  const requestMoveBreakoutRoom = (agoraUID: string, breakoutRoomId: string | undefined): void => {
    const state = store.getState()
    const participants = selectAllSessionParticipantsIncludingMe(state)
    const allBreakoutRooms = selectAllBreakoutRooms(state)
    const participant = participants.find(participant => participant.agoraUID === agoraUID)
    if (participant === undefined) return
    if (breakoutRoomId !== 'main' && !NanoId12.safeParse(breakoutRoomId).success) return

    void dispatch(
      liveSessionSlice.actions.updateBreakoutSession(
        breakoutRooms.moveParticipant(
          allBreakoutRooms,
          participant.userId,
          breakoutRoomId === 'main' ? undefined : (breakoutRoomId as NanoId12)
        )
      )
    )
  }

  const addBreakoutRoom = useCallback((): void => {
    void dispatch(liveSessionSlice.actions.addBreakoutRoom())
  }, [dispatch])

  return (
    <>
      <ConditionalWrapper
        condition={isFacilitator}
        renderWrapper={children => (
          <DragDropContext
            onDragEnd={result => {
              // Note: incorrect typings, result.destination can indeed be null
              // eslint-disable-next-line @typescript-eslint/no-unnecessary-condition
              if (result.destination !== undefined && result.destination !== null) {
                requestMoveBreakoutRoom(result.draggableId, result.destination.droppableId)
              }
            }}
          >
            {children}
          </DragDropContext>
        )}
      >
        <>
          {roomIds.map(roomId => (
            <RoomComponent key={roomId} roomId={roomId} />
          ))}
        </>
      </ConditionalWrapper>
      {isFacilitator && (
        <AddRoomButton onClick={addBreakoutRoom}>{t('live.breakout-rooms.add-new-room')}</AddRoomButton>
      )}
    </>
  )
}
