import { motion } from 'framer-motion'
import { forwardRef } from 'react'
import { LinkURL, URLLink } from 'sierra-client/views/workspace/utils/urls'
import { ProgressLine } from 'sierra-ui/missions/foundation'
import { Text, View } from 'sierra-ui/primitives'
import { token } from 'sierra-ui/theming'
import { v2_breakpoint } from 'sierra-ui/theming/breakpoints'
import { theme } from 'sierra-ui/theming/legacy-theme'
import styled, { css } from 'styled-components'

// Pass props for tooltip
const LinkWrapper = forwardRef<
  HTMLAnchorElement,
  {
    children: React.ReactNode[] | React.ReactNode
    href: LinkURL | null | undefined
    className?: string
  }
>(({ children, href, className, ...props }, ref) =>
  href === null || href === undefined ? (
    <span ref={ref} className={className} {...props}>
      {children}
    </span>
  ) : (
    <URLLink ref={ref} href={href} className={className} {...props}>
      {children}
    </URLLink>
  )
)

export const SectionView = styled(View).attrs({ direction: 'column' })<{
  $firstInOutline: boolean
}>`
  border-top: solid 1px ${token('border/strong')};
  border-bottom: solid 1px ${token('border/strong')};
  ${p => p.$firstInOutline && `border-top: none;`}
  padding-block: 20px;

  header {
    display: flex;
    justify-content: space-between;
    align-items: flex-start;
    gap: 24px;
  }
`

export const ProgramPageContentWrapper = styled.div`
  grid-area: content;
  margin-top: 48px;
`

export const StepContainer = styled.div<{ hasSubContent: boolean }>`
  display: grid;
  grid-template-areas: 'step-content' ${({ hasSubContent }) => hasSubContent && "'step-sub-content'"};
  gap: 0rem 0.75rem;
  margin: 1.5rem 0;

  @media screen and (max-width: ${v2_breakpoint.phone}) {
    gap: 0;
  }
`

export const HOVER_BACKGROUND_PADDING_SIZE = '0.5rem'

// removing 1px from the padding because of the border thickness.
export const StepContentContainer = styled(LinkWrapper)<{ locked: boolean }>`
  border-radius: ${theme.borderRadius['size-20']};
  transition: background-color 0.2s ease;
  transition-property: background-color, border-color;
  box-sizing: border-box;
  grid-area: step-content;
  display: flex;
  flex: 1;

  ${p => p.locked && `cursor: not-allowed;`}
`

export const StepInfoContainer = styled(View)`
  flex: 1;
  align-items: flex-start;
`

export const StepInfoHeader = styled(View)`
  width: 100%;
  justify-content: space-between;

  @media screen and (max-width: ${v2_breakpoint.phone}) {
    flex-direction: column;
    align-items: flex-start;
  }
`

export const StepProgressBarWrapper = styled(View)`
  position: absolute;
  padding: 12px;
  bottom: 0;
  z-index: 1;
  width: 100%;
`

export const ImageContainer = styled.div`
  width: 265px;
  position: relative;

  @media screen and (max-width: ${v2_breakpoint.phone}) {
    width: 144px;
    height: auto;
  }
`

export const StepSubContent = styled.div`
  grid-area: step-sub-content;
`

export const PathSubCourses = styled.div<{ $collapsed: boolean }>`
  display: flex;

  ${p =>
    p.$collapsed === true
      ? css`
          margin: 0;
        `
      : css`
          margin: 1.5rem 0 0;
        `}
`

export const PathLine = styled.div`
  width: 4px;
  background-color: ${token('border/strong')};
  margin-left: 8px;
  border-radius: 2px;
`

export const ProgressLineWithLayout = styled(ProgressLine)`
  grid-area: progress;
  max-width: 10rem;
  margin: 0.75rem 0 0 0;
  backdrop-filter: blur(34px);

  min-width: 100%;

  @media screen and (min-width: ${v2_breakpoint.tablet}) {
    width: clamp(5vw, 10vw, 200px);
    margin: 0;
  }
`

export const HeadingContainer = styled.div`
  grid-area: heading;
  display: flex;
  flex-direction: row;
`

export const WrapperOld = styled(motion.div)`
  display: flex;
  width: 100%;
  flex-direction: column;
  gap: 1.5rem;
  overflow: hidden;
  padding-left: 1.5rem;
`

export const SelfEnrollSessionListLayout = styled.div`
  padding: 1rem 0;

  @media screen and (min-width: ${v2_breakpoint.tablet}) {
    margin-left: 0;
  }
  @media screen and (min-width: ${v2_breakpoint.desktop}) {
    margin-left: calc(160px + 0.75rem);
  }
`

export const TextMaxLines = styled(Text)<{ lines: number }>`
  display: -webkit-box;
  -webkit-line-clamp: ${({ lines }) => lines};
  -webkit-box-orient: vertical;
  overflow: hidden;
  text-overflow: ellipsis;
`

export const ContentLabelWrapper = styled(View)`
  position: absolute;
  margin: 12px;
  top: 0;
  z-index: 1;
`

export const ProgramInfoButtonWrapper = styled(View).attrs({ direction: 'column' })`
  max-width: 400px;
  width: 100%;
`
