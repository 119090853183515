import _ from 'lodash'
import React, { useState } from 'react'
import { useLanguageItems } from 'sierra-client/hooks/use-language-items'
import { useTranslation } from 'sierra-client/hooks/use-translation'
import { labelToString } from 'sierra-client/lib/filter/components/common'
import {
  CategoryHeadline,
  SectionHeadline,
  SectionInfo,
} from 'sierra-client/views/manage/components/user-attributes/flows/components/layout'
import { useComposeUserInvitationConfig } from 'sierra-client/views/manage/components/user-attributes/flows/invite-users/hooks/use-compose-user-invitation-config'
import { InvitationDomainChoicesInput } from 'sierra-client/views/manage/components/user-attributes/flows/invite-users/panels/set-attributes-panel/attributes/domain-renderers/choices'
import { InvitationDomainPhoneNumberInput } from 'sierra-client/views/manage/components/user-attributes/flows/invite-users/panels/set-attributes-panel/attributes/domain-renderers/phone-number'
import { InvitationCustomAttributesSection } from 'sierra-client/views/manage/components/user-attributes/flows/invite-users/panels/set-attributes-panel/attributes/invitation-custom-attributes-section'
import { useInvitationDomains } from 'sierra-client/views/manage/components/user-attributes/hooks/use-invitation-domains'
import {
  AttributeRow,
  AttributeRowList,
} from 'sierra-client/views/manage/components/user-attributes/renderers/common'
import { Email } from 'sierra-domain/api/email'
import {
  UserAccessLevelDomainRep,
  UserAccessRoleDomainRep,
} from 'sierra-domain/user-attributes/user-invitation-domain-rep'
import { iife } from 'sierra-domain/utils'
import { MenuItem } from 'sierra-ui/components'
import { Spacer, View } from 'sierra-ui/primitives'
import { SingleSelectDropdown } from 'sierra-ui/primitives/menu-dropdown'
import { token } from 'sierra-ui/theming'
import styled from 'styled-components'

const AccessLevelRow: React.FC<{
  accessLevelDomain: UserAccessLevelDomainRep | UserAccessRoleDomainRep
}> = ({ accessLevelDomain }) => {
  const { dynamicT } = useTranslation()

  return (
    <AttributeRow label={labelToString(accessLevelDomain.label, dynamicT)}>
      <InvitationDomainChoicesInput domainRep={accessLevelDomain} />
    </AttributeRow>
  )
}

const ManagerRow: React.FC = () => {
  const { managerDomain } = useInvitationDomains()
  const { dynamicT } = useTranslation()

  if (managerDomain === undefined) {
    return <></>
  }

  return (
    <AttributeRow label={labelToString(managerDomain.label, dynamicT)}>
      <InvitationDomainChoicesInput domainRep={managerDomain} />
    </AttributeRow>
  )
}

const LanguageRow: React.FC = () => {
  const languageMenuItems = useLanguageItems()
  const { t } = useTranslation()
  const { languagePerUser, setLanguagePerUser, editingEmails } = useComposeUserInvitationConfig()
  const allLanguagesOfUsers = editingEmails.map(email => languagePerUser[Email.parse(email)])

  const selectedLanguage = allLanguagesOfUsers[0]

  const uniqueLanguages = new Set(allLanguagesOfUsers)

  /**
   * 1 user, selected language -> selected language
   * 1 user, no selected language -> undefined
   * 2 user, no selected language -> undefined
   * 2 user, selected language (same) -> selected language
   * 2 user, selected language (not same) -> mixed
   * */
  const selectedItem: MenuItem | undefined = iife(() => {
    if (selectedLanguage === undefined) {
      return undefined
    }

    if (uniqueLanguages.size === 1) {
      return languageMenuItems.find(item => item.id === selectedLanguage)
    } else if (uniqueLanguages.size > 1) {
      return {
        id: 'MIXED',
        label: _.capitalize(t('dictionary.mixed')),
        type: 'label',
      }
    }
  })

  return (
    <AttributeRow label={t('dictionary.language')}>
      <SingleSelectDropdown
        withSearch={languageMenuItems.length > 8}
        onSelect={item => {
          const newLanguages = editingEmails.reduce(
            (acc, email) => ({ ...acc, [email]: item.id }),
            languagePerUser
          )

          setLanguagePerUser(newLanguages)
        }}
        selectedItem={selectedItem}
        menuItems={languageMenuItems}
        placeholder={t('dictionary.select')}
      />
    </AttributeRow>
  )
}

const PhoneNumbersRow: React.FC = () => {
  const { phoneNumberDomain } = useInvitationDomains()
  const [validationError, setValidationError] = useState<string | undefined>(() => undefined)
  const { dynamicT } = useTranslation()

  if (phoneNumberDomain === undefined) {
    return <></>
  }

  return (
    <AttributeRow
      label={labelToString(phoneNumberDomain.label, dynamicT)}
      showWarning={validationError !== undefined}
      warningTooltip={validationError}
    >
      <InvitationDomainPhoneNumberInput
        phoneNumberDomain={phoneNumberDomain}
        onValidationError={e => setValidationError(e)}
      />
    </AttributeRow>
  )
}

const Section = styled(View).attrs({ direction: 'column', gap: '4' })`
  padding-bottom: 24px;
  border-bottom: 1px solid ${token('border/default')};
`
const AttributesContainer = styled(View).attrs({ direction: 'column', gap: '8' })``

export const AttributesSection: React.FC = () => {
  const { accessLevelDomain, accessRoleDomain } = useInvitationDomains()
  const { t } = useTranslation()

  return (
    <Section>
      <SectionHeadline>{t('manage.users.invite.set-up-attributes.title')}</SectionHeadline>
      <SectionInfo>{t('manage.users.invite.set-up-attributes.details')}</SectionInfo>
      <Spacer />
      <AttributesContainer>
        <AttributeRowList>
          <CategoryHeadline>{t('manage.default-attributes')}</CategoryHeadline>
          <AccessLevelRow accessLevelDomain={accessLevelDomain} />
          {accessRoleDomain !== undefined && <AccessLevelRow accessLevelDomain={accessRoleDomain} />}
          <ManagerRow />
          <LanguageRow />
          <PhoneNumbersRow />
        </AttributeRowList>
        <Spacer size='xsmall' />
        <InvitationCustomAttributesSection />
      </AttributesContainer>
    </Section>
  )
}
