import { forwardRef } from 'react'
import { Button, IconButton } from 'sierra-ui/primitives'

import { useSelectCurrentCardBackgroundColor } from 'sierra-client/components/liveV2/hooks/use-select-current-card'
import { useIsMobile } from 'sierra-client/state/browser/selectors'
import { ColorBuilder } from 'sierra-ui/color'
import { IconId } from 'sierra-ui/components'
import styled, { css } from 'styled-components'

export type NowButtonCSSProps = { $backgroundColor: ColorBuilder }

export const NowButtonCSS = css<NowButtonCSSProps>`
  ${p =>
    p.$backgroundColor.brightness() > 0.9
      ? css`
          background: rgba(238, 238, 238, 0.6);
        `
      : css`
          background: linear-gradient(0deg, rgba(90, 90, 90, 0.4) 0%, rgba(90, 90, 90, 0.4) 100%),
            rgba(90, 90, 90, 0.05);
        `};

  color: ${p => (p.$backgroundColor.brightness() > 0.9 ? ' rgba(0, 0, 0, 0.6)' : 'white')};

  border-radius: 12px;
  backdrop-filter: blur(34px);
  border: none;

  font-variant-numeric: tabular-nums;

  &:hover {
    ${p =>
      p.$backgroundColor.brightness() > 0.9
        ? css`
            background: rgba(238, 238, 238, 0.8);
          `
        : css`
            background: linear-gradient(0deg, rgba(90, 90, 90, 0.4) 0%, rgba(90, 90, 90, 0.4) 100%),
              rgba(90, 90, 90, 0.15);
          `};
  }
`

export const TransparentNowButton = styled(Button)`
  ${NowButtonCSS}
`

export const TransparentNowIconButton = styled(IconButton)`
  ${NowButtonCSS}
`

export const ResponsiveNowButton = forwardRef<
  HTMLButtonElement,
  { title: string; onClick?: () => void; iconId: IconId }
>(({ title, onClick, iconId, ...rest }, ref) => {
  const isMobile = useIsMobile()
  const backgroundColor = useSelectCurrentCardBackgroundColor()

  return isMobile ? (
    <TransparentNowIconButton
      $backgroundColor={backgroundColor}
      iconId={iconId}
      ref={ref}
      onClick={onClick}
      {...rest}
    />
  ) : (
    <TransparentNowButton
      $backgroundColor={backgroundColor}
      icon={iconId}
      ref={ref}
      onClick={onClick}
      {...rest}
    >
      {title}
    </TransparentNowButton>
  )
})
