import { useMemo } from 'react'
import { config } from 'sierra-client/config/global-config'
import { useIsCreateAccessible } from 'sierra-client/hooks/use-create-enabled'
import { TranslationKey } from 'sierra-client/hooks/use-translation/types'
import { useSelector } from 'sierra-client/state/hooks'
import { selectIsRestrictedUser } from 'sierra-client/state/user/user-selector'
import { useHasManageAccess } from 'sierra-client/views/manage/permissions/use-has-manage-access'

type NavLinkItem = {
  id: string
  href: string
  activePrefixes?: string[]
  translationKey: TranslationKey
}

const getNavLinks = ({
  isScorm,
  isRestrictedUser,
  hasManageAccess,
  isCreateAccessible,
}: {
  isScorm: boolean
  isRestrictedUser: boolean
  hasManageAccess: boolean
  isCreateAccessible: boolean
}): Array<NavLinkItem> => {
  if (isScorm) return []
  if (isRestrictedUser) return []

  const links: Array<NavLinkItem> = []
  // Home
  links.push({
    id: 'home',
    href: '/',
    translationKey: 'topbar.home',
  })

  // Learn
  links.push({
    id: 'learn',
    href: '/learn',
    translationKey: 'topbar.learn',
  })

  if (isCreateAccessible) {
    links.push({
      id: 'author',
      href: '/create',
      activePrefixes: ['/create/'],
      translationKey: 'topbar.author',
    })
  }

  // Admin
  if (hasManageAccess) {
    links.push({
      id: 'manage',
      href: '/manage/',
      activePrefixes: ['/manage/'],
      translationKey: 'topbar.manage',
    })
  }

  return links
}

export const useNavLinks = (): { links: Array<NavLinkItem> } => {
  const isRestrictedUser = useSelector(selectIsRestrictedUser)
  const hasManageAccess = useHasManageAccess()

  const isCreateAccessible = useIsCreateAccessible()

  const links = useMemo(
    () =>
      getNavLinks({
        isScorm: config.scorm.isScorm,
        isRestrictedUser,
        hasManageAccess,
        isCreateAccessible,
      }),
    [isRestrictedUser, hasManageAccess, isCreateAccessible]
  )

  return { links }
}
