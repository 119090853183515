import { useTranslation } from 'sierra-client/hooks/use-translation'
import { useIsMobile } from 'sierra-client/state/browser/selectors'
import { assertElementType } from 'sierra-client/views/v3-author/queries'
import { SlateFC } from 'sierra-client/views/v3-author/slate'
import { hasOnlyEmptyTextInNodes } from 'sierra-domain/slate-util'
import { Text } from 'sierra-ui/primitives'
import styled from 'styled-components'

const Placeholder = styled.span`
  user-select: none;
  pointer-events: none;
  cursor: text;
  opacity: 0.3;
`
const Container = styled.div`
  padding: 8px;
  backdrop-filter: blur(50px);
`

export const MatrixOption: SlateFC = ({ element, children }) => {
  assertElementType('matrix-option', element)
  const isEmpty = hasOnlyEmptyTextInNodes([element])
  const { t } = useTranslation()
  const isMobile = useIsMobile()

  return (
    <Container>
      <Text size={isMobile ? 'micro' : 'regular'} bold color='foreground/secondary'>
        {isEmpty && (
          <Placeholder contentEditable={false}>{t('author.sliding-scale.add-statement')}</Placeholder>
        )}
        {children}
      </Text>
    </Container>
  )
}
