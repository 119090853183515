import Ably from 'ably'
import { createContext } from 'react'

/**
 * We abstract away the connection and subscription logic to the real time client
 * since we need to track when a channel is no longer in use, so we can detach it
 * and also make sure we connect and disconnect from Ably appropriately
 *
 * this should also help making it easier to swap out Ably for another real time client
 * should we ever need to, as well as making it easier to test
 */
export type RealTimeDataClient = {
  subscribeToChannel: (args: {
    channelName: string
    eventName?: string
    callback: Ably.messageCallback<Ably.InboundMessage>
  }) => Promise<void>
  unsubscribeToChannel: (args: {
    channelName: string
    eventName?: string
    callback: Ably.messageCallback<Ably.InboundMessage>
  }) => void
  getChannelState: (channelName: string) => Ably.ChannelState
  onChannelStateChange: (channelName: string, callback: (update: Ably.ChannelStateChange) => void) => void
  offChannelStateChange: (channelName: string, callback: (update: Ably.ChannelStateChange) => void) => void

  onConnectionStateChange: (callback: (update: Ably.ConnectionStateChange) => void) => void
  offConnectionStateChange: (callback: (update: Ably.ConnectionStateChange) => void) => void
  getConnectionState: () => Ably.ConnectionState
  /**
   * This is only used for migration purposes, do not use this for new code
   * @deprecated
   */
  getAblyClient: () => Ably.Realtime
  /**
   * This is only used for migration purposes, do not use this for new code
   * @deprecated
   */
  getAblyChannel: (channelName: string) => Ably.RealtimeChannel
}

export const RealTimeDataContext = createContext<RealTimeDataClient | undefined>(undefined)
