import { graphql } from 'sierra-client/api/graphql/gql'
import { useGraphQuery } from 'sierra-client/api/hooks/use-graphql-query'
import { CourseId } from 'sierra-domain/api/nano-id'

const courseTitleQuery = graphql(`
  query SidebarShortcutCourseTitleQuery($courseId: CourseId!) {
    course(id: $courseId) {
      title
    }
  }
`)

export function useCourseTitle({ courseId }: { courseId: CourseId }): string | undefined {
  const result = useGraphQuery({ document: courseTitleQuery }, { courseId })

  return result.data?.course?.title
}
