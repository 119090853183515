import React, { useEffect, useState } from 'react'
import { Identity } from 'sierra-client/components/common/identities-selector'
import { SelectedIdentityPill } from 'sierra-client/components/common/identities-selector/atoms'
import { VirtualizedIdentities } from 'sierra-client/components/common/identities-selector/virtualized-identities'
import { useTranslation } from 'sierra-client/hooks/use-translation'
import { useSelector } from 'sierra-client/state/hooks'
import { selectUser } from 'sierra-client/state/user/user-selector'
import { getAvatarImage } from 'sierra-client/utils/avatar-img'
import { Bubble } from 'sierra-client/views/manage/programs/components/bubble'
import { PotentialGroupAdmin, PotentialProgramAdmin } from 'sierra-domain/api/manage'
import { getUserName } from 'sierra-domain/utils'
import { Autocomplete, Modal, ModalHeader, RoundAvatar, UserDisplay } from 'sierra-ui/components'
import { SelectedPillRemoveButton } from 'sierra-ui/components/autocomplete/reference-implementation/atoms'
import { Button, Heading, IconButton, ScrollView, Spacer, Text, View } from 'sierra-ui/primitives'
import { spacing, zIndex } from 'sierra-ui/theming'
import styled from 'styled-components'

// Intended to be used for both groups and programs
type PotentialAdmin = PotentialGroupAdmin | PotentialProgramAdmin

const NameContainer = styled(View)`
  overflow: hidden;
`

const NameText = styled(Text).attrs({ size: 'small', bold: true })`
  flex-shrink: 0;
`

const EmailText = styled(Text).attrs({ size: 'small', color: 'foreground/muted' })`
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
`

type AdminRowProps = {
  admin: PotentialAdmin
  canRemove: boolean
  onRemove: () => Promise<void>
}

const AdminRow: React.FC<AdminRowProps> = ({ admin, canRemove, onRemove }) => (
  <>
    <View key={admin.userId} justifyContent='space-between' grow>
      <View gap='12'>
        <RoundAvatar
          firstName={admin.firstName}
          lastName={admin.lastName}
          src={getAvatarImage(admin.userId, admin.avatar)}
          color={admin.avatarColor}
          size='medium'
        />
        <NameContainer direction='column' gap='none'>
          <NameText>{getUserName(admin)}</NameText>
          <EmailText>{admin.email}</EmailText>
        </NameContainer>
      </View>
      {canRemove && <IconButton iconId='trash-can' variant='transparent' onClick={onRemove} />}
    </View>
  </>
)

const HeaderInnerContainer = styled(View).attrs({
  direction: 'column',
  gap: '12',
})`
  width: 100%;
`

const HeaderCloseContainer = styled.div`
  position: absolute;
  top: ${spacing['16']};
  right: ${spacing['16']};
  z-index: ${zIndex.ON_MODAL};
`

const AutocompleteContainer = styled.div`
  width: 100%;
`

type AdminState = {
  loading: boolean
  fetchAll: () => void
  currentAdmins: PotentialAdmin[]
  potentialAdmins: PotentialAdmin[]
  addAdmins: (params: { adminIds: string[] }) => Promise<void>
  removeAdmins: (params: { adminIds: string[] }) => Promise<void>
}

const potentialAdminToIdentity = (potentialAdmins: PotentialAdmin[]): Identity[] => {
  const identities: Identity[] = potentialAdmins.map(pa => ({
    id: pa.userId,
    identity: {
      type: 'user',
      id: pa.userId,
    },
    name: `${pa.firstName} ${pa.lastName}`,
    email: pa.email,
    avatar: {
      type: 'color',
      initials: `${pa.firstName?.[0]} ${pa.lastName?.[0]}`,
      color: pa.avatarColor,
    },
  }))

  return identities
}

type AdminModalInnerProps = {
  canEdit: boolean
  hasFullAccess: boolean
  adminState: AdminState
}

// We'll use an inner component so that we don't fetch the list until after the modal is opened.
const AdminModalInner: React.FC<AdminModalInnerProps> = ({ canEdit, hasFullAccess, adminState }) => {
  const { loading, fetchAll, currentAdmins, potentialAdmins, addAdmins, removeAdmins } = adminState

  const { t } = useTranslation()
  const [query, setQuery] = useState('')
  const [selectedUsers, setSelectedUsers] = useState<PotentialAdmin[]>([])
  const currentUser = useSelector(selectUser)

  useEffect(() => {
    void fetchAll()
  }, [fetchAll])

  const handleAddAdmins = async (): Promise<void> => {
    if (!canEdit) {
      if (process.env.NODE_ENV === 'development') {
        throw new Error('Should be unreachable.')
      }
      return
    }
    if (selectedUsers.length === 0) return

    await addAdmins({ adminIds: selectedUsers.map(u => u.userId) })

    setQuery('')
    setSelectedUsers([])
  }

  const filteredPotentialAdmins = potentialAdminToIdentity(potentialAdmins).filter(
    item =>
      item.name.toUpperCase().includes(query.toUpperCase()) ||
      item.email?.toUpperCase().includes(query.toUpperCase())
  )

  return (
    <>
      <ModalHeader>
        <HeaderInnerContainer>
          <Heading size='h5' bold>
            {t('manage.groups.administrators')}
          </Heading>
          {canEdit && (
            <View alignItems='flex-start'>
              <AutocompleteContainer>
                <Autocomplete
                  query={query}
                  disabled={loading}
                  onQueryChange={setQuery}
                  placeholder={selectedUsers.length === 0 ? t('admin.add-users') : ''}
                  matchingItems={potentialAdminToIdentity(potentialAdmins)}
                  selectedItems={potentialAdminToIdentity(selectedUsers)}
                  onSelect={identity => {
                    const pa = potentialAdmins.find(pa => pa.userId === identity.id)

                    if (pa !== undefined) {
                      setSelectedUsers([...selectedUsers, pa])
                    }
                  }}
                  onUnselect={admin => setSelectedUsers(selectedUsers.filter(c => c.userId !== admin.id))}
                  renderSelectedItem={(identity, { onUnselect, ...props }) => {
                    return (
                      <SelectedIdentityPill key={identity.id} {...props}>
                        <Text bold>{identity.name}</Text>
                        <SelectedPillRemoveButton onClick={onUnselect} aria-label={t('dictionary.remove')} />
                      </SelectedIdentityPill>
                    )
                  }}
                  renderMatchingItemList={({ getItemProps }) => (
                    <VirtualizedIdentities<Identity>
                      getItemProps={getItemProps}
                      matchingItems={filteredPotentialAdmins}
                    />
                  )}
                />
              </AutocompleteContainer>
              <Button disabled={selectedUsers.length === 0} onClick={handleAddAdmins}>
                {t('dictionary.add')}
              </Button>
            </View>
          )}
        </HeaderInnerContainer>
      </ModalHeader>
      <Spacer size='32' />
      <ScrollView>
        <View direction='column' gap='16' paddingBottom='32'>
          {currentAdmins.map(admin => (
            <AdminRow
              key={admin.userId}
              admin={admin}
              canRemove={
                canEdit &&
                // Users with view access can see all current admins, but they can only
                // remove users that they manage from the list.
                admin.managedByCurrentUser &&
                // Users with limited access can't remove themselves from the list.
                currentUser !== undefined &&
                (hasFullAccess || admin.userId !== currentUser.uuid)
              }
              onRemove={() => removeAdmins({ adminIds: [admin.userId] })}
            />
          ))}
        </View>
      </ScrollView>
    </>
  )
}

//

type BaseModalProps = {
  open: boolean
  onClose: () => void
}

type AdminModalProps = BaseModalProps & AdminModalInnerProps

export const AdminModal: React.FC<AdminModalProps> = ({ open, onClose, ...innerProps }) => (
  <Modal open={open} onClose={onClose} padding='32 40' size={{ width: 650 }} animation='pop'>
    <HeaderCloseContainer>
      <IconButton iconId='close' variant='secondary' onClick={onClose} />
    </HeaderCloseContainer>
    <AdminModalInner {...innerProps} />
  </Modal>
)

//

type AdminSectionProps = {
  canEdit: boolean
  currentAdmins: PotentialAdmin[]
  onClickViewAll: () => void
}

export const AdminSection: React.FC<AdminSectionProps> = ({ canEdit, onClickViewAll, currentAdmins }) => {
  const { t } = useTranslation()

  const adminsToShow = 3
  const firstAdmins = currentAdmins.slice(0, adminsToShow)

  return (
    <View direction='column' gap='24' grow>
      <View>
        <Text size='small' bold>
          {t('manage.groups.administrators')}
        </Text>
        <Bubble>{currentAdmins.length}</Bubble>
      </View>
      {firstAdmins.map(admin => (
        <UserDisplay
          key={admin.userId}
          primaryText={getUserName(admin) ?? ''}
          secondaryText={admin.email}
          avatar={{
            firstName: admin.firstName,
            lastName: admin.lastName,
            src: getAvatarImage(admin.userId, admin.avatar),
            color: admin.avatarColor,
            size: 'medium',
          }}
        />
      ))}
      <View>
        <Button variant='secondary' onClick={onClickViewAll}>
          {canEdit ? t('dictionary.manage-admins') : t('dictionary.view-all')}
        </Button>
      </View>
    </View>
  )
}
