import React, { useCallback, useMemo } from 'react'
import { useTranslation } from 'sierra-client/hooks/use-translation'
import { labelToString } from 'sierra-client/lib/filter/components/common'
import { valueId } from 'sierra-client/lib/filter/components/predicate-utils'
import {
  getValueRepFromDomainChoices,
  valueRepToMenuLabelItem,
} from 'sierra-client/views/manage/components/user-attributes/flows/invite-users/utils'
import { useUserAttributesDraft } from 'sierra-client/views/manage/components/user-attributes/flows/user-attribute-settings/hooks/use-user-attributes-draft'
import { getCurrentValueRepFromDomainChoices } from 'sierra-client/views/manage/components/user-attributes/flows/user-attribute-settings/utils'
import { createInvitationAttribute } from 'sierra-client/views/manage/components/user-attributes/utils'
import { UserCustomAttributeDomainRepChoices } from 'sierra-domain/user-attributes/user-invitation-domain-rep'
import { isNotDefined } from 'sierra-domain/utils'
import { LabelMenuItem, MenuItem } from 'sierra-ui/components'
import { SingleSelectDropdown } from 'sierra-ui/primitives/menu-dropdown'

const CLEAR_ITEM_ID = 'clear-item'

export const EditDomainChoicesInput: React.FC<{
  domainRep: UserCustomAttributeDomainRepChoices
}> = ({ domainRep }) => {
  const { dynamicT, t } = useTranslation()

  const { userAttributesDraft, addOrReplaceAttributes, readOnlyAttributes, resetAttribute } =
    useUserAttributesDraft()

  const currentAttributeItem: LabelMenuItem | undefined = useMemo(
    () =>
      valueRepToMenuLabelItem(getCurrentValueRepFromDomainChoices(domainRep, userAttributesDraft), dynamicT),
    [domainRep, userAttributesDraft, dynamicT]
  )

  const setAttribute = useCallback(
    (item: MenuItem): void => {
      if (item.id === CLEAR_ITEM_ID) {
        resetAttribute(domainRep.ref)
      } else {
        const attributeValue = getValueRepFromDomainChoices(domainRep.domain, item.id)
        const attribute = createInvitationAttribute(domainRep.ref, [attributeValue.value])

        addOrReplaceAttributes([attribute])
      }
    },
    [resetAttribute, domainRep.ref, domainRep.domain, addOrReplaceAttributes]
  )

  const getMenuItems = (): MenuItem[] => {
    const domainChoiceItems: LabelMenuItem[] = domainRep.domain.choices.map(choice => ({
      type: 'label',
      id: valueId(choice.value),
      value: choice.value,
      label: labelToString(
        getValueRepFromDomainChoices(domainRep.domain, valueId(choice.value)).label,
        dynamicT
      ),
    }))

    if (isNotDefined(currentAttributeItem) || domainRep.isMandatory) {
      return domainChoiceItems
    } else {
      const clearItem: LabelMenuItem = {
        type: 'label',
        id: CLEAR_ITEM_ID,
        label: t('dictionary.none'),
        color: 'foreground/muted',
      }
      return [
        clearItem,
        {
          type: 'separator',
          id: 'separator-1',
        },
        ...domainChoiceItems,
      ]
    }
  }

  const menuItems: MenuItem[] = useMemo(getMenuItems, [
    currentAttributeItem,
    domainRep.domain,
    domainRep.isMandatory,
    dynamicT,
    t,
  ])

  return (
    <SingleSelectDropdown
      withSearch={menuItems.length > 8}
      disabled={
        readOnlyAttributes || domainRep.editableInternally === false
      } /* Can't be edited but can be shown */
      onSelect={setAttribute}
      selectedItem={currentAttributeItem}
      menuItems={menuItems}
      placeholder={t('dictionary.select')}
    />
  )
}
