import { StrategicErrorBoundary } from 'sierra-client/error/strategic-error-boundary'
import { FCC } from 'sierra-client/types'
import { View } from 'sierra-ui/primitives'
import styled from 'styled-components'

// TODO (tomas&seb): Remove this and let pages handle their own padding
// Should introduce specific layouts that each page can use
const Content = styled(View).attrs({
  paddingLeft: '48',
  paddingTop: '24',
  paddingBottom: '48',
  paddingRight: '48',
  alignItems: 'flex-start',
  grow: true,
})`
  width: 100%;
`

export const ManageLayout: FCC = ({ children }) => {
  return (
    <StrategicErrorBoundary id='manage' strategies={[]}>
      <Content>{children}</Content>
    </StrategicErrorBoundary>
  )
}
