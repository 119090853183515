import { useEffect, useMemo } from 'react'
import { useDeepEqualityMemo } from 'sierra-client/hooks/use-deep-equality-memo'
import { areArraysEqualShallow, useMemoByCompare } from 'sierra-client/hooks/use-memo-by-compare'
import { useDispatch, useSelector } from 'sierra-client/state/hooks'
import { queueUserIds } from 'sierra-client/state/users/actions'
import { usersSelectors } from 'sierra-client/state/users/selectors'
import { UserResult } from 'sierra-client/state/users/types'
import { UserId } from 'sierra-domain/api/uuid'
import { LightUser } from 'sierra-domain/user'
import { isDefined, isUUID } from 'sierra-domain/utils'
import { useOnChanged } from 'sierra-ui/utils'

export function isUserLoaded(
  userResult: UserResult
): userResult is Extract<UserResult, { status: 'loaded' }> {
  return userResult.status === 'loaded'
}

export function useUsers(_userIds: readonly UserId[]): UserResult[] {
  const dispatch = useDispatch()
  const loadedOrPendingUsers = useSelector(usersSelectors.selectEntities)
  const userIds = useDeepEqualityMemo(_userIds)

  // Log an error message for invalid ids to spot bugs sooner.
  useEffect(() => {
    const invalidUserIds = userIds.filter(id => !isUUID(id))

    if (invalidUserIds.length > 0) {
      console.error(
        `Unable to load user with ids '[${JSON.stringify(invalidUserIds)}]'. Ids must be valid UUIDs.`
      )
    }
  }, [userIds])

  // Filter out user ids that have been queued already.
  const userIdsToQueue = useMemo(
    () => userIds.filter(isUUID).filter(id => loadedOrPendingUsers[id] === undefined),
    [loadedOrPendingUsers, userIds]
  )

  // Queue newly added user ids for loading.
  useOnChanged((_prev, newIds) => {
    if (newIds.length > 0) {
      void dispatch(queueUserIds({ userIds: newIds }))
    }
  }, userIdsToQueue)

  const users = useMemo(
    () => userIds.map(userId => loadedOrPendingUsers[userId]).filter(isDefined),
    [loadedOrPendingUsers, userIds]
  )

  // Don't generate a new array if all elements are the same.
  return useMemoByCompare(users, areArraysEqualShallow)
}

export function useUser(userId?: UserId): UserResult | undefined {
  const ids = useMemo(() => (userId !== undefined ? [userId] : []), [userId])
  const users = useUsers(ids)
  return users[0]
}

/**
 * @deprecated - use useUsers instead
 */
export function useUsersLegacy(userIds: readonly UserId[]): (LightUser | undefined)[] {
  const results = useUsers(userIds)
  return useMemo(() => results.map(user => (user.status === 'loaded' ? user : undefined)), [results])
}

/**
 * @deprecated - use useUser instead
 */
export function useUserLegacy(userId?: UserId): LightUser | undefined {
  const user = useUser(userId)
  if (user?.status === 'loaded') return user
  else return undefined
}
