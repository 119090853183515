import { motion } from 'framer-motion'
import { FC } from 'react'

import { useDragLayer } from 'react-dnd'
import { createPortal } from 'react-dom'
import { DragOverlayCard } from 'sierra-client/features/program'
import { CourseTitleAndImage } from 'sierra-client/lib/tabular/components/cells'
import { AssetContext } from 'sierra-domain/asset-context'
import { ImageUnion } from 'sierra-domain/content/v2/image-union'
import { assertNever, iife } from 'sierra-domain/utils'
import { Icon } from 'sierra-ui/components'
import { View } from 'sierra-ui/primitives'
import { token } from 'sierra-ui/theming'
import styled, { css } from 'styled-components'

export type ProgramStepDragItem = {
  type: 'program-step'
  id: string
  index: number
  title: string
  thumbnail?: ImageUnion
  assetContext: AssetContext
}

export const ProgramStepDragOverlay: FC = () => {
  const { item, isDragging, position, initial, initialSource } = useDragLayer(monitor => ({
    isDragging: monitor.isDragging(),
    item: monitor.getItem(),
    position: monitor.getClientOffset(),
    initial: monitor.getInitialClientOffset() ?? { x: 0, y: 0 },
    initialSource: monitor.getInitialSourceClientOffset() ?? { x: 0, y: 0 },
    tmp: monitor.getDifferenceFromInitialOffset(),
  }))

  if (!isDragging || position === null) return null

  const x = initial.x - initialSource.x
  const y = initial.y - initialSource.y

  if (item.type !== 'program-step') return null

  return createPortal(
    <DragOverlayCard x={position.x - x} y={position.y - y}>
      <View padding={'16'} gap={'8'}>
        <Icon iconId='draggable' />
        <CourseTitleAndImage
          title={item.title}
          image={item.thumbnail}
          subline='Course'
          stepType={item.stepType}
          assetContext={item.assetContext}
          imageSize='small'
        />
      </View>
    </DragOverlayCard>,
    document.body
  )
}

export const DropIndicator = styled(motion.div).attrs({
  initial: {
    opacity: 0,
  },
  animate: {
    opacity: 1,
  },
  exit: {
    opacity: 0,
  },
  transition: {
    duration: 0.15,
  },
})<{ $position: 'above' | 'below' | 'on' }>`
  position: absolute;

  ${p =>
    iife(() => {
      switch (p.$position) {
        case 'above': {
          return css`
            width: calc(100% - 20px);
            height: 2px;
            top: -2px;
            left: 10px;
            border-radius: 2px;
            background: ${token('form/focus/border')};
          `
        }
        case 'below': {
          return css`
            width: calc(100% - 20px);
            height: 2px;
            bottom: -2px;
            left: 10px;
            border-radius: 2px;
            background: ${token('form/focus/border')};
          `
        }
        case 'on': {
          return css`
            width: 100%;
            height: 100%;
            outline: 2px solid ${token('form/focus/border')};
            background: none;
            border-radius: 16px;
          `
        }
        default:
          assertNever(p.$position)
      }
    })}
`
