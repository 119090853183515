import { Entity } from 'sierra-domain/entity'
import { CheckListItem, Heading, ListItem, Paragraph } from 'sierra-domain/v3-author'
import { createHeading, createParagraph } from 'sierra-domain/v3-author/create-blocks'

export const bullet = (indent = 0): string => {
  switch (indent % 3) {
    case 0:
      return '•'
    case 1:
      return '◦'
    case 2:
      return '▪'
    default:
      return '•'
  }
}

export type ListItemSizes = 'large' | 'regular' | 'small' | 'hSuper' | 'h1' | 'h2' | 'h3' | 'h4' | 'h5'

export function listItemLevelToHeadingLevel(level: number): number {
  if (level === 4) return 3
  if (level === 5) return 2
  if (level === 6) return 1
  if (level === 7) return 0
  if (level === 8) return 5

  return 2
}

export function headingLevelToListItemLevel(level: number | undefined): number {
  if (level === 0) return 7
  if (level === 1) return 6
  if (level === 2) return 5
  if (level === 3) return 4
  if (level === 5) return 8

  return 4
}

export function getListItemSize(level: number | undefined): ListItemSizes {
  if (level === 0) return 'large'
  if (level === 1) return 'regular'
  if (level === 2) return 'small'
  if (level === 3) return 'h5'
  if (level === 4) return 'h4'
  if (level === 5) return 'h3'
  if (level === 6) return 'h2'
  if (level === 7) return 'h1'
  if (level === 8) return 'hSuper'

  return 'regular'
}

export function getLineHeightForListItem(level: ListItemSizes | undefined): string | number {
  if (level === 'hSuper') return 0.9
  if (level === 'h1') return 1
  if (level === 'h3') return '1.1em'
  if (level === 'h4') return 1.3

  return '1.5em'
}

export function convertListItemToText(
  listItem: Entity<ListItem | CheckListItem>
): Entity<Heading> | Entity<Paragraph> {
  if (listItem.level !== undefined && listItem.level > 2) {
    return createHeading({ level: listItemLevelToHeadingLevel(listItem.level), children: listItem.children })
  }
  return createParagraph({ level: listItem.level, children: listItem.children })
}
