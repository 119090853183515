import { produce } from 'immer'
import _ from 'lodash'
import React, { Dispatch, SetStateAction, useCallback, useEffect, useMemo, useState } from 'react'
import { useTranslation } from 'sierra-client/hooks/use-translation'
import { postWithUserErrorCode } from 'sierra-client/state/api'
import { useDispatch } from 'sierra-client/state/hooks'
import { useContentList } from 'sierra-client/views/manage/courses/course-groups/hooks/use-content-list'
import { useTracking } from 'sierra-client/views/manage/courses/course-groups/hooks/use-tracking'
import {
  CourseGroupComponents,
  EligibleCourseForEdition,
  RemoveEdition,
  UpdateDefaultEdition,
  UpdateEdition,
  courseSummaryToEligibleCourse,
} from 'sierra-client/views/manage/courses/course-groups/modals/common'
import { areEditionsValid } from 'sierra-client/views/manage/courses/course-groups/modals/utils/validation'
import { DraftEditionConfig } from 'sierra-client/views/manage/courses/course-groups/types'
import {
  UseCourseGroupDetailsData,
  useManageCourseGroupDetails,
} from 'sierra-client/views/manage/courses/use-manage-course-group-details'
import { CourseKind } from 'sierra-domain/api/common'
import { CourseEditionConfig, CourseGroupConfig, CourseGroupDetailRow } from 'sierra-domain/api/manage'
import { CourseId } from 'sierra-domain/api/nano-id'
import { isLeft } from 'sierra-domain/either'
import { XRealtimeAdminCoursesUpdateCourseGroup } from 'sierra-domain/routes'
import { assertIsNonNullable } from 'sierra-domain/utils'

const {
  CourseGroupModal,
  CourseGroupHeader,
  UpdateGroupTitle,
  Content,
  Footer,
  Cancel,
  Save,
  LoadingScreen,
  CourseEditionSection,
  AddCourseEditionButton,
} = CourseGroupComponents

const emptyEdition: DraftEditionConfig = {
  editionId: undefined,
  title: '',
  description: '',
  language: '',
  isDefault: false,
}

const useIsSaveEnabled = (draft: State | undefined, initial: State | undefined): boolean => {
  return useMemo(() => {
    const allEditionsValid = areEditionsValid(draft?.editions)
    const isCourseGroupTitleEmpty = draft?.courseGroupTitle === ''

    return allEditionsValid && !isCourseGroupTitleEmpty && !_.isEqual(draft, initial)
  }, [draft, initial])
}
type State = (Omit<CourseGroupConfig, 'editions'> & { editions: DraftEditionConfig[] }) | undefined
type SetState = Dispatch<SetStateAction<State>>

const useCourseGroupDraft = (
  courseGroupId: CourseId
): {
  draft: State
  setDraft: SetState
  initial: State
  setToInitialState: () => void
  courseEditionsData: CourseGroupDetailRow[]
  courseImage: UseCourseGroupDetailsData['courseImage']
  fetchCourseGroup: () => Promise<void>
} => {
  const [draft, setDraft] = useState<State>(undefined)
  const [initial, setInitial] = useState<State>(undefined)

  const { courseGroupData, courseEditionsData, courseImage, fetchCourseGroup } =
    useManageCourseGroupDetails(courseGroupId)

  const setToInitialState = useCallback(() => {
    if (courseGroupData === undefined) {
      return
    }

    const { title, description } = courseGroupData

    const initialCourseGroupConfig: CourseGroupConfig = {
      courseGroupId,
      courseGroupTitle: title,
      courseGroupDescription: description,
      editions: courseEditionsData.map(e => ({
        editionId: e.courseId,
        title: e.title,
        description: e.description,
        language: e.language ?? '',
        isDefault: e.isDefault,
      })),
    }

    setDraft(initialCourseGroupConfig)
    setInitial(initialCourseGroupConfig)
  }, [courseEditionsData, courseGroupData, courseGroupId])

  // Set to initial state when courseGroupId changes
  useEffect(setToInitialState, [setToInitialState, courseGroupId])

  return {
    draft,
    setDraft,
    initial,
    setToInitialState,
    courseImage,
    courseEditionsData,
    fetchCourseGroup,
  }
}

/*
 * Returns the list of eligible courses for the given list of course editions,
 * e.g. the list of courses available in the course list dropdown; a course can only be used once.
 * */
const useEligibleCourses = (
  courseEditionsData: CourseGroupDetailRow[],
  initial: State | undefined
): EligibleCourseForEdition[] => {
  const kind: CourseKind | undefined = courseEditionsData[0]?.kind

  const courseList = useContentList(kind)

  const eligibleCourses = useMemo((): EligibleCourseForEdition[] => {
    const mappedCourseList: EligibleCourseForEdition[] = courseList.map(courseSummaryToEligibleCourse)
    const additionalEditions: EligibleCourseForEdition[] =
      initial?.editions.flatMap(({ editionId, title }) => {
        if (editionId === undefined) return []

        return { id: editionId, title }
      }) ?? []

    return mappedCourseList.concat(additionalEditions)
  }, [initial?.editions, courseList])

  return eligibleCourses
}

type UpdateCourseGroupModalProps = {
  courseGroupId: CourseId
  open: boolean
  onClose: () => void
  onSave: () => Promise<void>
}

export const UpdateCourseGroupModal: React.FC<UpdateCourseGroupModalProps> = ({
  open,
  onClose,
  onSave,
  courseGroupId,
}) => {
  const { t } = useTranslation()
  const dispatch = useDispatch()
  const tracking = useTracking()

  const { draft, setDraft, initial, setToInitialState, courseEditionsData, courseImage, fetchCourseGroup } =
    useCourseGroupDraft(courseGroupId)
  const eligibleCourses = useEligibleCourses(courseEditionsData, initial)
  const isSaveEnabled = useIsSaveEnabled(draft, initial)

  const alreadyInUseCourseIds = useMemo(
    () => draft?.editions.map(it => it.editionId).filter(it => it !== undefined) ?? [],
    [draft]
  )
  const alreadyInUseLanguages = useMemo(() => draft?.editions.map(it => it.language) ?? [], [draft])

  const reset = useCallback(() => {
    void (async () => {
      onClose()
      await fetchCourseGroup()
      setToInitialState()
    })()
  }, [fetchCourseGroup, onClose, setToInitialState])

  const save = useCallback(async () => {
    if (draft === undefined) {
      return
    }

    const filteredEditions = draft.editions.map(({ editionId, ...rest }): CourseEditionConfig => {
      assertIsNonNullable(editionId, 'Assertion failed: Should not attempt to save edition without courseId.')

      return { editionId, ...rest }
    })

    const result = await postWithUserErrorCode(
      XRealtimeAdminCoursesUpdateCourseGroup,
      { config: { ...draft, editions: filteredEditions } },
      dispatch,
      {
        notifications: true,
      }
    )

    await onSave()
    tracking.courseGroup.save(draft.courseGroupId)
    reset()

    if (isLeft(result)) {
      throw Error(`An unknown error occurred while updating the course group`)
    }
  }, [draft, tracking.courseGroup, dispatch, onSave, reset])

  const updateEdition: UpdateEdition = useCallback(
    (editionIndex, courseId, title, description, language, isDefault) =>
      setDraft(configDraft =>
        produce(configDraft, draft => {
          if (draft === undefined) return

          const newEdition = {
            editionId: courseId,
            title,
            description,
            language,
            isDefault,
          }

          draft.editions[editionIndex] = newEdition
        })
      ),
    [setDraft]
  )

  const removeEdition: RemoveEdition = useCallback(
    editionIndex => {
      setDraft(configDraft =>
        produce(configDraft, draft => {
          if (draft === undefined) return
          tracking.courseGroup.editionDelete(draft.editions[editionIndex]?.editionId)
          draft.editions.splice(editionIndex, 1)
        })
      )
    },
    [setDraft, tracking.courseGroup]
  )

  const addNewEdition = useCallback(() => {
    tracking.courseGroup.editionCreate()
    setDraft(configDraft =>
      produce(configDraft, draft => {
        if (draft === undefined) return
        draft.editions.push(emptyEdition)
      })
    )
  }, [setDraft, tracking.courseGroup])

  const updateDefaultEdition: UpdateDefaultEdition = useCallback(
    editionIndex => {
      setDraft(configDraft =>
        produce(configDraft, draft => {
          if (draft === undefined) return

          for (let i = 0; i < draft.editions.length; i++) {
            const current = draft.editions[i]
            if (current !== undefined) {
              current.isDefault = i === editionIndex
            }
          }
        })
      )
    },
    [setDraft]
  )

  const setTitle = useCallback(
    (title: string) =>
      setDraft(configDraft =>
        produce(configDraft, draft => {
          if (draft === undefined) return

          draft.courseGroupTitle = title
        })
      ),
    [setDraft]
  )

  return (
    <CourseGroupModal open={open} onClose={reset}>
      <CourseGroupHeader background={courseImage} onClose={reset} updateModal />
      {draft === undefined ? (
        <LoadingScreen />
      ) : (
        <>
          <Content>
            <UpdateGroupTitle title={draft.courseGroupTitle} setTitle={setTitle} />
            {draft.editions.map(({ editionId, title, description, language, isDefault }, index) => (
              <CourseEditionSection
                key={editionId}
                edition={{
                  id: editionId,
                  no: index + 1,
                  title,
                  description,
                  language,
                  isDefault,
                }}
                courseList={eligibleCourses} // The current edition shouldn't be filtered out
                editionCount={draft.editions.length}
                updateEdition={updateEdition}
                removeEdition={removeEdition}
                alreadyInUseCourseIds={alreadyInUseCourseIds.filter(id => id !== editionId)} // When updating we want the current edition to be selectable
                alreadyInUseLanguages={alreadyInUseLanguages}
                updateCallbacks={{
                  updateDefaultEdition,
                }}
              />
            ))}
            <AddCourseEditionButton onClick={addNewEdition} />
          </Content>
          <Footer>
            <Cancel onClick={reset}>{t('modal.cancel')}</Cancel>
            <Save onClick={save} disabled={!isSaveEnabled}>
              {t('modal.save')}
            </Save>
          </Footer>
        </>
      )}
    </CourseGroupModal>
  )
}
