import React, { useEffect, useState } from 'react'
import { useConfirmationModalContext } from 'sierra-client/components/common/modals/confirmation-modal'
import { useLiveContext } from 'sierra-client/components/liveV2/live-context'
import { useTranslation } from 'sierra-client/hooks/use-translation'
import { useSelector } from 'sierra-client/state/hooks'
import { selectIsSomeoneRecording } from 'sierra-client/state/live-session/selectors'
import { selectCallIsActive } from 'sierra-client/state/live/selectors'

export const ConfirmOtherRecordingModal: React.FC = () => {
  const isSomeoneRecording = useSelector(selectIsSomeoneRecording)
  const joined = useSelector(selectCallIsActive)
  const { show, close } = useConfirmationModalContext()
  const { leave } = useLiveContext()
  const [hasConfirmedToBeRecorded, setHasConfirmedToBeRecorded] = useState<boolean>(false)
  const { t } = useTranslation()

  useEffect(() => {
    if (isSomeoneRecording && !hasConfirmedToBeRecorded && joined) {
      show({
        title: t('live.recording-modal.title'),
        bodyText: t('live.recording-modal.description'),
        onConfirm: () => setHasConfirmedToBeRecorded(true),
        secondaryActionLabel: t('dictionary.leave'),
        onSecondaryAction: async () => {
          await leave()
          close()
        },
        hideCloseIcon: true,
        onlyAllowCloseFor: 'primaryAction',
      })
    }
  }, [show, close, joined, leave, isSomeoneRecording, hasConfirmedToBeRecorded, t])

  return null
}
