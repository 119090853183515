import { graphql } from 'sierra-client/api/graphql/gql'

export const getUserAttributesQuery = graphql(`
  query GetUserAttributes($userId: UserId!) {
    user(id: $userId) {
      customAttributes {
        key
        keyDisplayName
        parsedValue {
          ... on DateValue {
            __typename
            date
          }
          ... on EnumValue {
            __typename
            displayName
            enumValue
          }
          ... on IntValue {
            __typename
            int
          }
          ... on StringValue {
            __typename
            string
          }
        }
        rawValue
        type
      }
    }
  }
`)
