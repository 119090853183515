import { useIsMobile } from 'sierra-client/state/browser/selectors'
import { CreatePageDesktopFilters } from 'sierra-client/views/workspace/create-new/filters/create-page-desktop-filters'
import { CreatePageMobileFilters } from 'sierra-client/views/workspace/create-new/filters/mobile/create-page-mobile-filters'
import { CreatePageFilterRenderer } from 'sierra-client/views/workspace/create-new/filters/types'

export const CreatePageFilters: React.FC<CreatePageFilterRenderer> = props => {
  const isMobile = useIsMobile()
  const Renderer = isMobile ? CreatePageMobileFilters : CreatePageDesktopFilters

  return <Renderer {...props} />
}
