import { useState } from 'react'
import { useIdleTimer } from 'react-idle-timer'

export const useIsIdle = (): boolean => {
  const [isIdle, setIsIdle] = useState(false)

  useIdleTimer({
    timeout: 3000,
    onActive: () => setIsIdle(false),
    onIdle: () => setIsIdle(true),
    events: [
      'mousemove',
      'DOMMouseScroll',
      'mousewheel',
      'mousedown',
      'touchstart',
      'touchmove',
      'MSPointerDown',
      'MSPointerMove',
      'visibilitychange',
    ],
  })
  return isIdle
}
