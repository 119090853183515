import React, { useCallback, useMemo } from 'react'
import { useTranslation } from 'sierra-client/hooks/use-translation'
import { labelToString } from 'sierra-client/lib/filter/components/common'
import { useComposeUserInvitationConfig } from 'sierra-client/views/manage/components/user-attributes/flows/invite-users/hooks/use-compose-user-invitation-config'
import { getCommonValueForTypeOf } from 'sierra-client/views/manage/components/user-attributes/flows/invite-users/utils'
import {
  typeOfTypeToInputType,
  typeOfTypeToPlaceholder,
  typeOfTypeToValue,
} from 'sierra-client/views/manage/components/user-attributes/renderers/domains/type-of'
import { createInvitationAttribute } from 'sierra-client/views/manage/components/user-attributes/utils'
import { UserCustomAttributeDomainRepType } from 'sierra-domain/user-attributes/user-invitation-domain-rep'
import { InputPrimitive } from 'sierra-ui/primitives'

export const InvitationDomainTypeOfInput: React.FC<{
  domainRep: UserCustomAttributeDomainRepType
}> = domainRep => {
  const { dynamicT, t } = useTranslation()
  const { addOrReplaceAttributes, userAttributesConfig, editingEmails } = useComposeUserInvitationConfig()
  const domainTypeType = domainRep.domainRep.domain.typeOf.typeOf

  const inputType = useMemo(() => typeOfTypeToInputType(domainTypeType), [domainTypeType])

  const placeholder = useMemo(() => typeOfTypeToPlaceholder(domainTypeType, t), [domainTypeType, t])

  const value = useMemo(
    () => getCommonValueForTypeOf(domainRep.domainRep, userAttributesConfig, editingEmails, dynamicT),
    [domainRep.domainRep, dynamicT, editingEmails, userAttributesConfig]
  )

  const setAttribute = useCallback(
    (e: React.ChangeEvent<HTMLInputElement>): void => {
      const attribute = createInvitationAttribute(domainRep.domainRep.ref, [
        typeOfTypeToValue(domainTypeType, e.target.value),
      ])

      addOrReplaceAttributes([attribute])
    },
    [addOrReplaceAttributes, domainRep.domainRep.ref, domainTypeType]
  )

  return (
    <InputPrimitive
      type={inputType}
      max={inputType === 'date' ? '9999-12-31' : undefined}
      placeholder={placeholder}
      autoFocus={false}
      id={labelToString(domainRep.domainRep.label, dynamicT)}
      value={value?.toString() ?? ''}
      onChange={e => setAttribute(e)}
    />
  )
}
