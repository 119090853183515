import { useMemo, useState } from 'react'

type Handlers = {
  toggle: () => void
  on: () => void
  off: () => void
  reset: () => void
  set: (state: boolean) => void
}

export type ToggleHandlers = Handlers

/**
 * A more descriptive replacement for useState<boolean>
 *
 * Usage:
 * ```
 * const [state, { toggle, on, off, reset, set }] = useToggle(initialState: boolean)
 * ```
 *
 * @param initialState boolean - defaults to false
 * @returns [state, {toggle, on, off, reset}]
 */
export const useToggle = (initialState = false): [boolean, Handlers] => {
  const [state, setState] = useState(initialState)

  const controls = useMemo(
    () => ({
      toggle: () => {
        setState(currentState => !currentState)
      },
      on() {
        setState(true)
      },
      off() {
        setState(false)
      },
      reset() {
        setState(initialState)
      },
      set(s: boolean) {
        setState(s)
      },
    }),
    [initialState]
  )

  return useMemo(() => {
    return [state, controls]
  }, [controls, state])
}
