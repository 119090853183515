import React from 'react'
import { WIDGET_BUILDER_TRANSITION } from 'sierra-client/features/insights/widget-builder/data-selectors/animation-constants'
import { WidgetBuilderState } from 'sierra-client/features/insights/widget-builder/widget-builder-state'
import { DimensionRef, MeasureRef, ViewRef } from 'sierra-domain/api/insights'
import { isDefined } from 'sierra-domain/utils'
import { IconButton, View } from 'sierra-ui/primitives'
import styled from 'styled-components'
import { MultiDimensionSelector, SingleDimensionSelector } from './data-selectors'

type WidgetDimensionSelectorProps = {
  selectedView?: ViewRef
  selectedMeasures: MeasureRef[]
  selectedDimensions: DimensionRef[]
  visualisation: WidgetBuilderState['visualisation']
  onChange: (newDimensions: DimensionRef[]) => void
}

const SwitcherPosition = styled(View)`
  position: absolute;
  top: 32px;
  left: -26px;
`

export const WidgetDimensionSelector: React.FC<WidgetDimensionSelectorProps> = ({
  selectedView,
  selectedMeasures,
  selectedDimensions,
  visualisation,
  onChange,
}) => {
  const multiDimensionSupport = visualisation.type === 'table'

  return (
    <>
      {visualisation.type === 'pivot-table' ? (
        <View
          direction='column'
          grow
          padding='4 none'
          position='relative'
          animated
          initial='hide'
          animate='hide'
          whileHover='show'
        >
          <SingleDimensionSelector
            selectedDimension={selectedDimensions[0]}
            selectedMeasures={[selectedMeasures[0]].filter(isDefined)}
            onChange={newDim => onChange([newDim, ...selectedDimensions.slice(1, selectedDimensions.length)])}
            dimensionSelectorType='index'
            disabledDimensions={selectedDimensions.slice(0, 1)}
            visualisation={visualisation}
          />
          <SwitcherPosition
            animated
            variants={{ show: { opacity: 1, rotate: 90 }, hide: { opacity: 0, rotate: 90 } }}
            transition={WIDGET_BUILDER_TRANSITION}
          >
            <IconButton
              size='small'
              iconId='compare'
              variant='transparent'
              onClick={() =>
                onChange(
                  [
                    selectedDimensions[1],
                    selectedDimensions[0],
                    ...selectedDimensions.slice(2, selectedDimensions.length),
                  ].filter(isDefined)
                )
              }
            />
          </SwitcherPosition>
          <SingleDimensionSelector
            selectedDimension={selectedDimensions[1]}
            selectedMeasures={selectedMeasures}
            onChange={newDim =>
              onChange(
                [
                  selectedDimensions[0],
                  newDim,
                  ...selectedDimensions.slice(2, selectedDimensions.length),
                ].filter(isDefined)
              )
            }
            dimensionSelectorType='breakdown'
            disabledDimensions={selectedDimensions}
            visualisation={visualisation}
          />
        </View>
      ) : multiDimensionSupport ? (
        <MultiDimensionSelector
          selectedMeasures={selectedMeasures}
          selectedView={selectedView}
          onChange={onChange}
          selectedDimensions={selectedDimensions}
          visualisation={visualisation}
        />
      ) : (
        <View
          padding='4 none'
          grow
          animated
          initial={{ opacity: 0 }}
          animate={{ opacity: 1 }}
          transition={WIDGET_BUILDER_TRANSITION}
        >
          <SingleDimensionSelector
            selectedDimension={selectedDimensions[0]}
            selectedMeasures={selectedMeasures}
            selectedView={selectedView}
            onChange={newDim => onChange([newDim, ...selectedDimensions.slice(1, selectedDimensions.length)])}
            dimensionSelectorType='index'
            disabledDimensions={selectedDimensions}
            visualisation={visualisation}
          />
        </View>
      )}
    </>
  )
}
