import { useEffect, useRef, useState } from 'react'
import { FCC } from 'sierra-client/types'
import { View } from 'sierra-ui/primitives'
import { token } from 'sierra-ui/theming'
import styled, { css } from 'styled-components'

const InsightsDashboardHeaderView = styled(View)<{ $isStuck: boolean }>`
  width: 100%;
  position: sticky;
  top: -1px; /* Needs to be -1 to trigger intersection observer which defines 'isStuck' */
  padding-bottom: 16px;
  z-index: 11;
  background: ${token('surface/default')};
  transition: all 50ms ease-out;

  ${p =>
    p.$isStuck &&
    css`
      padding-top: 16px;
    `}
`

export const StickyDashboardHeader: FCC = ({ children }) => {
  const headerRef = useRef<HTMLDivElement>(null)
  const [isStuck, setIsStuck] = useState(false)

  useEffect(() => {
    const cachedRef = headerRef.current
    if (cachedRef === null) return

    const observer = new IntersectionObserver(
      ([e]) => {
        setIsStuck(e !== undefined && e.intersectionRatio < 1)
      },
      {
        threshold: [1],
      }
    )

    observer.observe(cachedRef)
    return () => observer.unobserve(cachedRef)
  }, [headerRef])

  return (
    <InsightsDashboardHeaderView
      $isStuck={isStuck}
      gap='24'
      justifyContent='space-between'
      alignItems='flex-start'
      paddingTop={'16'}
      ref={headerRef}
    >
      {children}
    </InsightsDashboardHeaderView>
  )
}
