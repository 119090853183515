import { createFileRoute } from '@tanstack/react-router'

import { PageIdentifier } from 'sierra-client/layout/sana-page'
import { ManageContent, prefetchFilterContentDomain } from 'sierra-client/views/manage/content/manage-content'
import { ManagePageWrapper } from 'sierra-client/views/manage/utils/manage-page-wrapper'

// Note: ManagePageWrapper handles the permission check.
function ManageContentPage(): JSX.Element | null {
  return (
    <ManagePageWrapper pageIdentifier={PageIdentifier.ManageContent()}>
      <ManageContent />
    </ManagePageWrapper>
  )
}

const RouteComponent = ManageContentPage

export const Route = createFileRoute('/manage/content')({
  component: RouteComponent as React.FC,
  loader: ({ context }) => {
    if (context.userId !== undefined) {
      void prefetchFilterContentDomain()
    }
  },
})
