import React from 'react'
import { ConfirmationModalProvider } from 'sierra-client/components/common/modals/confirmation-modal'
import { AnyPageInstance } from 'sierra-client/core/logging/page/types'
import { requireLoggedIn } from 'sierra-client/core/require-logged-in'
import { requireOrgPermission } from 'sierra-client/core/require-org-permission'
import { SanaPage } from 'sierra-client/layout/sana-page'
import { ManageLayout } from 'sierra-client/views/manage/components/layout/manage-layout'
import styled from 'styled-components'

const Wrapper = styled.div`
  display: flex;
  flex-wrap: wrap;
  flex: 1;
`

const _ManagePageWrapper: React.FC<{
  pageIdentifier: AnyPageInstance
  children: React.ReactNode
}> = ({ pageIdentifier, children }) => {
  return (
    <SanaPage showIntercomLauncher={true} disableMobile page={pageIdentifier}>
      <Wrapper>
        <ConfirmationModalProvider>
          <ManageLayout>{children}</ManageLayout>
        </ConfirmationModalProvider>
      </Wrapper>
    </SanaPage>
  )
}

export const ManagePageWrapper = requireLoggedIn(requireOrgPermission(_ManagePageWrapper, 'ACCESS_MANAGE'))
