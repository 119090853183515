import Collaboration from '@tiptap/extension-collaboration'
import CollaborationCursor from '@tiptap/extension-collaboration-cursor'
import { Editor, Extensions } from '@tiptap/react'
import StarterKit from '@tiptap/starter-kit'
import { useEffect, useMemo } from 'react'
import { useSelector } from 'sierra-client/state/hooks'
import { selectUser } from 'sierra-client/state/user/user-selector'
import { NarrationsYMap, getOrCreateNarrationsScriptSharedType } from 'sierra-domain/collaboration/narrations'
import { theme } from 'sierra-ui/theming/legacy-theme'
import { Awareness } from 'y-protocols/awareness'

class Provider {
  awareness: Awareness

  constructor(awareness: Awareness) {
    this.awareness = awareness
  }
}

export function useNarrationEditor({
  narrationsYMap,
  awareness,
  onScriptChanged,
}: {
  narrationsYMap: NarrationsYMap
  awareness: Awareness
  onScriptChanged: (script: string) => void
}): Editor {
  const user = useSelector(selectUser)

  const name = user?.firstName
  const color = theme.color[user?.avatarColor ?? 'pinkBright']

  const editor = useMemo(() => {
    const provider = new Provider(awareness)
    const fragment = getOrCreateNarrationsScriptSharedType(narrationsYMap)

    const extensions: Extensions = [
      StarterKit.configure({
        blockquote: false,
        bold: false,
        bulletList: false,
        code: false,
        codeBlock: false,
        dropcursor: false,
        gapcursor: false,
        hardBreak: false,
        heading: false,
        horizontalRule: false,
        italic: false,
        listItem: false,
        orderedList: false,
        strike: false,
      }),

      Collaboration.configure({ fragment }),

      CollaborationCursor.configure({
        provider,
        user: { name, color },
        render: (user: { name: string | undefined; color: string | undefined }) => {
          // Based on https://github.com/ueberdosis/tiptap/tree/main/packages/extension-collaboration-cursor
          const cursor = document.createElement('span')
          cursor.classList.add('collaboration-cursor__caret')
          cursor.setAttribute('style', `--cursor-color: ${user.color}`)

          const label = document.createElement('div')
          label.classList.add('collaboration-cursor__label')
          label.appendChild(document.createTextNode(user.name ?? ''))
          cursor.appendChild(label)

          return cursor
        },
      }),
    ]
    return new Editor({
      extensions,
      onUpdate: ({ editor }) => {
        onScriptChanged(editor.getText())
      },
    })
  }, [awareness, color, name, narrationsYMap, onScriptChanged])

  useEffect(() => {
    onScriptChanged(editor.getText())
  }, [editor, onScriptChanged])

  return editor
}
