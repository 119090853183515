import Ably from 'ably'
import { useAtomValue } from 'jotai'
import { useEffect, useMemo, useState } from 'react'
import { useRealTimeDataClient } from 'sierra-client/realtime-data/real-time-data-provider'
import { connectionStateAtom } from 'sierra-client/state/realtime-data'

/**
 *
 * This is only for migrating from the ably presence api, do not use this
 *
 * This will not connect to the channel, a channel needs to be subscribed to first in order for the channel to be connected
 * @deprecated
 */
export const useChannelPresenceIfConnected = (
  channelName: string
): { presence: Ably.RealtimePresence; connectionId: string } | undefined => {
  const client = useRealTimeDataClient()
  const [attachedMap, setAttachedMap] = useState<Record<string, boolean>>({})
  const attached = attachedMap[channelName] ?? false
  const connectionState = useAtomValue(connectionStateAtom)
  const [connectionId, setConnectionId] = useState<string | undefined>(undefined)

  useEffect(() => {
    if (connectionState === 'connected') {
      setConnectionId(client.getAblyClient().connection.id)
    }
  }, [connectionState, client])

  useEffect(() => {
    const handleUpdate = (update: Ably.ChannelStateChange): void => {
      setAttachedMap(curr => ({ ...curr, [channelName]: update.current === 'attached' }))
    }

    const onMount = async (): Promise<void> => {
      try {
        client.onChannelStateChange(channelName, handleUpdate)
        setAttachedMap(curr => ({
          ...curr,
          [channelName]: client.getChannelState(channelName) === 'attached',
        }))
      } catch (error) {
        console.error('subscribe error', error)
      }
    }

    void onMount()

    return () => {
      client.offChannelStateChange(channelName, handleUpdate)
      setAttachedMap(curr => ({
        ...curr,
        [channelName]: false,
      }))
    }
  }, [client, channelName])

  const result = useMemo(
    () =>
      attached && connectionId !== undefined
        ? { presence: client.getAblyChannel(channelName).presence, connectionId }
        : undefined,
    [attached, client, connectionId, channelName]
  )

  return result
}
