import { createAction } from '@reduxjs/toolkit'
import { CourseId, LiveSessionId, PathId } from 'sierra-domain/api/nano-id'
import { ProgramId } from 'sierra-domain/api/uuid'

export type CurrentIds = {
  currentProgramId?: ProgramId
  currentPathId?: PathId
  currentCourseId?: CourseId
  currentLiveSessionId?: LiveSessionId
}
export const locationChanged = createAction<CurrentIds>('location/changed')
