import React, { useMemo, useState } from 'react'
import {
  getFormattedValue,
  mapTwoDimensionalData,
} from 'sierra-client/features/insights/display-widgets/data-utils'
import {
  useOnDrillDownDimension,
  useOnFilterOutTableValue,
} from 'sierra-client/features/insights/display-widgets/data-viewers/utils'
import { useNormalizedTableResult } from 'sierra-client/features/insights/display-widgets/normalize-data/normalize-data'

import { toLabel } from 'sierra-client/features/insights/display-widgets/utils'
import { useInsightsViewsFromMeasures } from 'sierra-client/features/insights/hooks/use-insights-views'
import { doFilterContainType } from 'sierra-client/features/insights/utils'
import { useTranslation } from 'sierra-client/hooks/use-translation'
import { labelToString } from 'sierra-client/lib/tabular/datatype/label'
import {
  BarChartWidget,
  Dimension,
  DimensionRef,
  TableResult,
  TableValue,
  areDimensionRefsEqual,
} from 'sierra-domain/api/insights'
import { Filter } from 'sierra-domain/filter/datatype/filter'
import { BarChart, BarChartProps } from 'sierra-ui/missions/delphi/charts'
import { View } from 'sierra-ui/primitives'
import { DrillDownMenu } from './drill-down-menu'

export const BAR_CHART_MAX_LIMIT = 50

type BarChartViewerProps = {
  tableResult: TableResult
  widget: BarChartWidget
  onDrillDown?: (filter: Filter, newDimension: Dimension) => void
  onFilterOut?: (filter: Filter) => void
}

export const BarChartViewer: React.FC<BarChartViewerProps> = ({
  tableResult,
  widget,
  onDrillDown,
  onFilterOut,
}) => {
  const { t } = useTranslation()

  const normalizedTableResult = useNormalizedTableResult(tableResult, widget)
  const data = useMemo(() => {
    return mapTwoDimensionalData(normalizedTableResult, widget, t)
  }, [normalizedTableResult, widget, t])

  const twoDimensionalMetric = {
    ...data,
    rows: data.rows.map(row => ({ ...row, value: getFormattedValue(row) })),
  }

  const [drilldownMenuIsOpen, setDrilldownMenuIsOpen] = useState<
    { x: number; y: number; tableValue: TableValue } | undefined
  >(undefined)

  const { data: viewsData } = useInsightsViewsFromMeasures(widget.measures, undefined)

  const onFilterOutTableValue = useOnFilterOutTableValue({ onFilterOut, widget })

  const handleFilterOut = (): void => {
    if (onFilterOutTableValue === undefined) return

    const tableValue = drilldownMenuIsOpen?.tableValue
    if (tableValue === undefined) return

    onFilterOutTableValue(tableValue)
  }

  const onDrillDownDimension = useOnDrillDownDimension({ onDrillDown, widget })

  const handleDrillDown = (dimensionRef: DimensionRef): void => {
    if (onDrillDownDimension === undefined) return

    const tableValue = drilldownMenuIsOpen?.tableValue
    if (tableValue === undefined) return

    onDrillDownDimension(tableValue, dimensionRef)
  }

  const handleBarClick: BarChartProps['onClick'] = (data, index, e) => {
    if (onDrillDownDimension !== undefined) {
      const tableValue = twoDimensionalMetric.rows[index]?.tableValue

      const selectedFilter = viewsData?.availableFilters.find(f =>
        areDimensionRefsEqual(f.ref, widget.dimensions[0])
      ) // If there is no filter for the current dimension we cant drill down
      if ('clientX' in e && 'clientY' in e && tableValue !== undefined && selectedFilter !== undefined) {
        setDrilldownMenuIsOpen({
          x: e.clientX,
          y: e.clientY,
          tableValue,
        })
      }
    }
  }

  const { indexDimensionColumn, measureColumn, rows, yUnit } = twoDimensionalMetric

  // Hardcode limit to 50 since bar charts arent good at virtualization. If users complain we'll fix this
  const dataOverFlowing = rows.length > BAR_CHART_MAX_LIMIT
  const slicedRows = rows.slice(0, BAR_CHART_MAX_LIMIT)

  const barChartKeys = (
    widget.dimensions[1] !== undefined
      ? slicedRows.reduce<BarChartProps<string>['keys']>((keys, row) => {
          if (row.breakdownId === undefined) return keys
          if (keys.includes(row.breakdownId)) return keys
          keys.push(row.breakdownId)
          return keys
        }, [])
      : ['value']
  ) satisfies BarChartProps<string>['keys']

  const barChartData = (
    widget.dimensions[1] !== undefined
      ? slicedRows.reduce<BarChartProps<string>['data']>((rows, row) => {
          if (row.breakdownId === undefined) return rows

          const existingRow = rows.find(existingRow => existingRow.id === row.id)

          if (existingRow !== undefined) {
            existingRow.values[row.breakdownId] = row.value
          } else {
            rows.push({ values: { [row.breakdownId]: row.value }, ...row })
          }
          return rows
        }, [])
      : slicedRows.map(row => ({
          ...row,
          values: { value: row.value },
        }))
  ) satisfies BarChartProps<string>['data']

  const canFilterCurrentDimension =
    viewsData?.availableFilters.find(dimension =>
      areDimensionRefsEqual(widget.dimensions[0], dimension.ref)
    ) !== undefined

  const displayAvatars = indexDimensionColumn.type.type === 'type.user'
  const disabledByOrFilter = doFilterContainType(widget.filter, 'filter.or')

  return (
    <View data-testid='bar-chart' direction='column' alignItems='stretch' grow>
      <BarChart
        keys={barChartKeys}
        data={barChartData}
        displayAvatars={displayAvatars}
        unit={yUnit}
        yLegend={labelToString(toLabel(measureColumn.label), t)}
        onClick={handleBarClick}
        isInteractive
        trailingText={
          dataOverFlowing
            ? {
                text: t('manage.insights.widgetbuilder.max-limit-helper', { x: BAR_CHART_MAX_LIMIT }),
                tooltip: t('manage.insights.widgetbuilder.max-limit-helper-tooltip'),
              }
            : undefined
        }
      />
      {canFilterCurrentDimension && (
        <DrillDownMenu
          isOpen={drilldownMenuIsOpen !== undefined}
          position={drilldownMenuIsOpen}
          onOpenChange={() => setDrilldownMenuIsOpen(undefined)}
          selectedMeasures={widget.measures}
          selectedDimension={widget.dimensions[0]}
          onDrillDown={handleDrillDown}
          onFilterOut={handleFilterOut}
          widget={widget}
          disabledByOrFilter={disabledByOrFilter}
        />
      )}
    </View>
  )
}
