import { keepPreviousData } from '@tanstack/react-query'
import { useAtomValue } from 'jotai'
import { useCallback, useMemo } from 'react'
import { graphql } from 'sierra-client/api/graphql/gql'
import { useGraphQuery } from 'sierra-client/api/hooks/use-graphql-query'
import { useDebouncedState } from 'sierra-client/hooks/use-debounced-state'
import { useTranslation } from 'sierra-client/hooks/use-translation'
import { Domain, DomainRep, Label, Value, setPred } from 'sierra-client/lib/filter'
import { Context, labelToString } from 'sierra-client/lib/filter/components/common'
import {
  addPredValue,
  equal,
  fromPredicate,
  removePredValue,
  valueId,
} from 'sierra-client/lib/filter/components/predicate-utils'
import { TableAPI } from 'sierra-client/lib/tabular/api'
import { useDropdownLabel, useIsCurrentUserReviewer } from 'sierra-client/lib/tabular/tables/homework/utils'
import { CtxFilterComponent } from 'sierra-client/lib/tabular/tables/utils'
import { useCachedQuery } from 'sierra-client/state/api'
import { FilterBase } from 'sierra-domain/filter/datatype/filter'
import { XRealtimeAdminHomeworkSubmissionUserIdentities } from 'sierra-domain/routes'
import { ExtractFrom, STATIC_EMPTY_ARRAY, isDefined, isNonEmptyArray, noOp } from 'sierra-domain/utils'
import { MenuItem } from 'sierra-ui/components'
import { GroupMenuItem, LabelMenuItem } from 'sierra-ui/components/menu/types'
import { Text } from 'sierra-ui/primitives'
import { DefaultDropdownTrigger, MultiSelectDropdown } from 'sierra-ui/primitives/menu-dropdown'
import { MenuDropdownPrimitive } from 'sierra-ui/primitives/menu-dropdown/menu-dropdown-primitive'

type MenuItemWithValue = MenuItem & {
  value?: Value
}

const Choices: React.FC<{
  ctx: Context
  filter: FilterBase
  domain: ExtractFrom<Domain, { type: 'domain.choices' }>
  label: Label
}> = ({ ctx, filter, domain, label }) => {
  const { dynamicT, t } = useTranslation()
  const isCurrentUserReviewer = useIsCurrentUserReviewer(filter)

  const choices = useMemo(() => fromPredicate(filter.predicate), [filter.predicate])
  const { dropdownLabel } = useDropdownLabel(domain.choices)

  const items: MenuItemWithValue[] = useMemo(
    () => [
      {
        id: 'predicate',
        type: 'group' as const,
        label: labelToString(label, dynamicT),
        menuItems: domain.choices.map(valueRep => ({
          id: valueId(valueRep.value),
          type: 'checkbox' as const,
          label: labelToString(valueRep.label, dynamicT),
          value: valueRep.value,
          checked: choices.some(choice => equal(choice, valueRep.value)),
          onToggleChange: noOp,
        })),
      },
    ],
    [choices, domain.choices, dynamicT, label]
  )

  const selectedItems = useMemo(() => {
    const allMenuItems: MenuItemWithValue[] = items.flatMap(item =>
      'menuItems' in item ? item.menuItems : [item]
    )
    return choices
      .map(valueId)
      .map(it => allMenuItems.find(mi => mi.id === it))
      .filter(isDefined)
  }, [choices, items])

  const onSelect = (item: MenuItemWithValue): void => {
    if (item.value !== undefined) {
      const { value } = item
      ctx.update(f => setPred(f, addPredValue(filter.predicate, value)))
    }
  }

  const onUnselect = (item: MenuItemWithValue): void => {
    if (item.value !== undefined) {
      const { value } = item
      ctx.update(f => setPred(f, removePredValue(filter.predicate, value)))
    }
  }

  return (
    <MultiSelectDropdown
      withSearch={domain.choices.length > 10}
      searchPlaceholder={t('dictionary.search')}
      menuItems={items}
      selectedItems={selectedItems}
      onSelect={onSelect}
      onUnselect={onUnselect}
      renderTrigger={() => (
        <DefaultDropdownTrigger grow={false}>
          <Text bold>
            {isCurrentUserReviewer ? t('manage.homework.assigned-to-you') : dropdownLabel(choices, label)}
          </Text>
        </DefaultDropdownTrigger>
      )}
    />
  )
}

const toCheckboxMenuItem = (value: Value, label: string, choices: Array<Value>): MenuItemWithValue => ({
  id: valueId(value),
  type: 'checkbox',
  label: label,
  value,
  checked: choices.some(choice => equal(choice, value)),
  onToggleChange: noOp,
})

const getSelectedItems = (items: MenuItemWithValue[], selectedItems: Array<Value>): MenuItemWithValue[] => {
  const allMenuItems: MenuItemWithValue[] = items.flatMap(item =>
    'menuItems' in item ? item.menuItems : [item]
  )
  return selectedItems
    .map(valueId)
    .map(it => allMenuItems.find(mi => mi.id === it))
    .filter(isDefined)
}

const LIMIT = 30
const SubmitterAutoComplete: React.FC<{
  onSelect: (item: MenuItemWithValue) => void
  onUnselect: (item: MenuItemWithValue) => void
  choices: Array<Value>
  filter: FilterBase
  label: Label
}> = ({ onUnselect, onSelect, choices, filter, label }) => {
  const { dynamicT, t } = useTranslation()
  const isCurrentUserReviewer = useIsCurrentUserReviewer(filter)
  const [query, setQuery] = useDebouncedState<string>('', { wait: 300 })

  const identitiesRes = useCachedQuery(
    XRealtimeAdminHomeworkSubmissionUserIdentities,
    {
      limit: LIMIT,
      userQuery: query,
    },
    {
      placeholderData: x => keepPreviousData(x),
    }
  )

  const items: Array<GroupMenuItem<string>> = useMemo(
    () =>
      identitiesRes.data?.identities
        ? [
            {
              id: 'predicate',
              type: 'group' as const,
              label: labelToString(label, dynamicT),
              menuItems: [
                ...identitiesRes.data.identities.map(valueRep =>
                  toCheckboxMenuItem(
                    {
                      type: 'value.user-id',
                      value: valueRep.identity.id,
                    },
                    valueRep.label,
                    choices
                  )
                ),
                ...[
                  identitiesRes.data.identities.length === LIMIT
                    ? ({
                        id: 'load-more',
                        type: 'label' as const,
                        label: `${t('manage.homework.search-for-more.text')}...`,
                        disabled: true,
                      } satisfies LabelMenuItem)
                    : undefined,
                ].filter(isDefined),
              ],
            },
          ]
        : STATIC_EMPTY_ARRAY,
    [choices, dynamicT, identitiesRes.data?.identities, label, t]
  )

  const { dropdownLabel } = useDropdownLabel(
    identitiesRes.data?.identities.map(valueRep => {
      return {
        value: { type: 'value.user-id', value: valueRep.identity.id },
        label: { type: 'label.default', label: valueRep.label },
      } as const
    })
  )

  const selectedItems = useMemo(() => getSelectedItems(items, choices), [choices, items])

  const handleSelect = useCallback(
    (item: MenuItem): void => {
      if (selectedItems.find(selected => selected.id === item.id)) {
        onUnselect(item)
      } else {
        onSelect(item)
      }
    },
    [onSelect, onUnselect, selectedItems]
  )

  return (
    <MenuDropdownPrimitive
      onSearch={setQuery}
      searchPlaceholder={t('dictionary.search')}
      menuItems={items}
      onSelect={handleSelect}
      renderTrigger={() => (
        <DefaultDropdownTrigger grow={false}>
          <Text bold>
            {isCurrentUserReviewer ? t('manage.homework.assigned-to-you') : dropdownLabel(choices, label)}
          </Text>
        </DefaultDropdownTrigger>
      )}
    />
  )
}

const courseQuery = graphql(`
  query CourseAutoCompleteWithHomework($query: String!, $limit: Int!) {
    coursesWithHomework(query: $query, limit: $limit) {
      id
      title
    }
  }
`)

const ContentAutoComplete: React.FC<{
  onSelect: (item: MenuItemWithValue) => void
  onUnselect: (item: MenuItemWithValue) => void
  choices: Array<Value>
  label: Label
}> = ({ label, onSelect, onUnselect, choices }) => {
  const { dynamicT, t } = useTranslation()
  const [query, setQuery] = useDebouncedState<string>('', { wait: 300 })

  const courseRes = useGraphQuery(
    {
      document: courseQuery,
      queryOptions: {
        select: x => x.coursesWithHomework,
      },
    },
    { query, limit: LIMIT }
  )

  const items: Array<GroupMenuItem<string>> = useMemo(
    () =>
      isNonEmptyArray(courseRes.data)
        ? [
            {
              id: 'predicate',
              type: 'group' as const,
              label: labelToString(label, dynamicT),
              menuItems: [
                ...courseRes.data.map(valueRep =>
                  toCheckboxMenuItem({ type: 'value.course-id', value: valueRep.id }, valueRep.title, choices)
                ),
                ...[
                  courseRes.data.length === LIMIT
                    ? ({
                        id: 'load-more',
                        type: 'label' as const,
                        label: `${t('manage.homework.search-for-more.text')}...`,
                        disabled: true,
                      } satisfies LabelMenuItem)
                    : undefined,
                ].filter(isDefined),
              ],
            },
          ]
        : STATIC_EMPTY_ARRAY,
    [choices, dynamicT, courseRes.data, label, t]
  )

  const { dropdownLabel } = useDropdownLabel(
    courseRes.data?.map(valueRep => {
      return {
        value: { type: 'value.course-id', value: valueRep.id },
        label: { type: 'label.default', label: valueRep.title },
      } as const
    })
  )

  const selectedItems = useMemo(() => getSelectedItems(items, choices), [choices, items])

  const handleSelect = (item: MenuItem): void => {
    if (selectedItems.find(selected => selected.id === item.id)) {
      onUnselect(item)
    } else {
      onSelect(item)
    }
  }

  return (
    <MenuDropdownPrimitive
      onSearch={setQuery}
      searchPlaceholder={t('dictionary.search')}
      menuItems={items}
      onSelect={handleSelect}
      renderTrigger={() => (
        <DefaultDropdownTrigger grow={false}>
          <Text bold>{dropdownLabel(choices, label)}</Text>
        </DefaultDropdownTrigger>
      )}
    />
  )
}

const AutoComplete: React.FC<{
  domain: ExtractFrom<Domain, { type: 'domain.auto-complete' }>
  ctx: Context
  filter: FilterBase
  label: Label
}> = ({ domain, ctx, filter, label }) => {
  const choices = useMemo(() => fromPredicate(filter.predicate), [filter.predicate])

  const onSelect = useCallback(
    (item: MenuItemWithValue): void => {
      if (item.value !== undefined) {
        const { value } = item
        ctx.update(f => setPred(f, addPredValue(filter.predicate, value)))
      }
    },
    [ctx, filter.predicate]
  )

  const onUnselect = useCallback(
    (item: MenuItemWithValue): void => {
      if (item.value !== undefined) {
        const { value } = item
        ctx.update(f => setPred(f, removePredValue(filter.predicate, value)))
      }
    },
    [ctx, filter.predicate]
  )

  switch (domain.entity) {
    case 'entity.user': {
      return (
        <SubmitterAutoComplete
          choices={choices}
          onSelect={onSelect}
          onUnselect={onUnselect}
          filter={filter}
          label={label}
        />
      )
    }
    case 'entity.content': {
      return (
        <ContentAutoComplete choices={choices} onSelect={onSelect} onUnselect={onUnselect} label={label} />
      )
    }
    default: {
      // We do not have any views for the other entities for now
      // If we get to a state where we need them, we should try to generalize this component more similar
      // to the other AutoComplete component
      return null
    }
  }
}

const FilterView: React.FC<{ ctx: Context; filter: FilterBase; domainRep: DomainRep }> = ({
  ctx,
  filter,
  domainRep,
}) => {
  const domain = domainRep.domain
  switch (domain.type) {
    case 'domain.choices': {
      return <Choices ctx={ctx} filter={filter} domain={domain} label={domainRep.label} />
    }
    case 'domain.auto-complete': {
      return <AutoComplete domain={domain} ctx={ctx} filter={filter} label={domainRep.label} />
    }
    // TODO: we currently only care about dropdowns here. Might need more views here going forward
    case 'domain.type': {
      return null
    }
  }
}

export const Filtering: React.FC<{ api: TableAPI }> = ({ api }) => {
  const filter = useAtomValue(api.atoms.useFilter)

  if (filter === undefined) {
    return null
  }

  return <CtxFilterComponent ctx={filter.ctx} filter={filter.filter} render={FilterView} />
}
