/* eslint-disable @typescript-eslint/no-explicit-any */
// eslint-disable @typescript-eslint/no-unsafe-return
// eslint-disable @typescript-eslint/no-explicit-any

import { Middleware, UnknownAction, isAction } from '@reduxjs/toolkit'
import _ from 'lodash'
import { actionTransformer } from 'sierra-client/error/redux-safe-forwarding-transformers'
import { logger } from 'sierra-client/logger/logger'
import { trace } from 'sierra-client/logger/performance'
import { rejectedActionSeverity, reportRejectedAction } from 'sierra-client/state/notifications/actions'
import { RootState } from 'sierra-client/state/types'

function generateActionFingerprint(severity: 'warning' | 'error', action: UnknownAction): string {
  const fingerprint = `rejected__${severity}__${action.type}`
  return fingerprint
}

function shouldIgnoreAction(action: UnknownAction): boolean {
  if (_.get(action, 'error.message') === 'Failed to fetch') {
    return true
  }

  if (_.get(action, 'error.name') === 'RequestError') {
    const status = Number(_.get(action, 'error.code'))
    return status >= 400
  }

  return false
}

export const reportRejectedAsyncThunks: Middleware = storeAPI => next => action => {
  // Intercept and log all rejected `createAsyncThunk`s to the notifications slice.
  if (!isAction(action) || shouldIgnoreAction(action)) {
    return next(action)
  }

  const requestStatus: unknown = _.get(action, 'meta.requestStatus')
  if (requestStatus === 'rejected') {
    storeAPI.dispatch(reportRejectedAction(action) as any)

    const transformedAction = actionTransformer(action)
    const severity = rejectedActionSeverity(action)
    const fingerprint = generateActionFingerprint(severity, action)

    if (severity === 'warning') {
      logger.warn(`Redux Action Rejected: ${action.type}`, {
        action: transformedAction && '=> ' + JSON.stringify(transformedAction),
        error: 'error' in action ? action.error : undefined,
        fingerprint,
      })
    } else {
      logger.error(`Redux Action Rejected: ${action.type}`, {
        action: transformedAction && '=> ' + JSON.stringify(transformedAction),
        error: 'error' in action ? action.error : undefined,
        fingerprint,
      })
    }
  }

  return next(action)
}

export const logToNewRelicMiddleware: Middleware<unknown, RootState> = () => next => action => {
  if (!isAction(action)) return next(action)

  const transformedAction = actionTransformer(action)
  if (transformedAction === null) return next(action)

  // In case we need to get the state before the action, we can get that here
  // const prevState = stateTransformer(storeAPI.getState())

  let returnedValue: any
  let error: any | undefined
  try {
    returnedValue = next(action)
  } catch (err) {
    error = err
  }

  // We need to make sure the properties are non-json strings, since new relic will otherwise try to parse the objects into attributes
  // which will fail since they are too big
  logger.debug(`Redux Action: ${transformedAction.type}`, {
    actionType: transformedAction.type,
    action: '=> ' + JSON.stringify(transformedAction),
    error,
  })

  return returnedValue
}

// Do we still need this check?
const performanceMarkSupported = (): boolean => 'mark' in performance

export const userTiming: Middleware<unknown, RootState> = () => next => action => {
  if (!isAction(action) || !performanceMarkSupported()) return next(action)

  const result = trace(action.type, () => next(action))

  return result
}
