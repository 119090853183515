import { graphql } from 'sierra-client/api/graphql/gql'
import { useGraphQuery } from 'sierra-client/api/hooks/use-graphql-query'
import { CertificateSelector } from 'sierra-client/components/certificates/certificate-selector'
import { useTranslation } from 'sierra-client/hooks/use-translation'
import * as settingsState from 'sierra-client/state/author-course-settings/slice'
import { useSelector } from 'sierra-client/state/hooks'
import {
  useAddCertificateToCourse,
  useDeleteCertificateFromCourse,
} from 'sierra-client/views/course-settings/tabs/certificates-tab/use-connect-certificate-to-content'
import { HorizontalSettingsField } from 'sierra-client/views/course-settings/tabs/utils'
import { SettingsTabComponent } from 'sierra-client/views/course-settings/types'
import { View } from 'sierra-ui/primitives'

const certificatesQuery = graphql(`
  query getCertificatesConnectedToCourse($courseId: CourseId!) {
    course(id: $courseId) {
      title
      description
      contentId
      certificates {
        id
        title
        description
        previewImageUrl
      }
    }
  }
`)

export const CertificatesTab: SettingsTabComponent = () => {
  const { t } = useTranslation()
  const courseId = useSelector(settingsState.selectors.currentCourseId)
  const courseKind = useSelector(settingsState.selectors.selectCourseKind)

  const certificatesData = useGraphQuery({ document: certificatesQuery }, { courseId: courseId ?? '' })
  const certificates = certificatesData.data?.course?.certificates ?? []

  const onAdd = useAddCertificateToCourse()
  const onDelete = useDeleteCertificateFromCourse()

  if (courseId === null || courseKind === null) return null

  const handleAdd = async (certificateId: string): Promise<void> => {
    await onAdd(courseId, certificateId)
    await certificatesData.refetch()
  }

  const handleRemove = async (certificateId: string): Promise<void> => {
    await onDelete(courseId, certificateId)
    await certificatesData.refetch()
  }

  return (
    <View direction='column' gap='4'>
      <HorizontalSettingsField
        title={t('dictionary.certificate-plural')}
        description={t('manage.certificates.add-certificate-to-course-description')}
      >
        <CertificateSelector
          selectedCertificates={certificates}
          // todo(permissions): check this for all course types
          canEdit={true}
          onAdd={handleAdd}
          onRemove={handleRemove}
        />
      </HorizontalSettingsField>
    </View>
  )
}
