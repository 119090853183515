import type {
  LowStreamParameter,
  MicrophoneAudioTrackInitConfig,
  ScreenVideoTrackInitConfig,
  VideoEncoderConfiguration,
  VideoEncoderConfigurationPreset,
} from 'agora-rtc-sdk-ng'
import { isChrome, isIOS } from 'react-device-detect'
import { CameraResolution } from 'sierra-client/components/liveV2/services/video-call-service/types'

const cameraConfigRecord: Record<
  CameraResolution,
  VideoEncoderConfiguration | VideoEncoderConfigurationPreset
> = {
  high: '720p_auto', // 1280 × 720 (16:9), 15fps, 1130kbps
  mid: '480p_8', // 848 × 480 (16:9), 15fps, 610kbps
  low: '240p_4', // 424 × 240 (16:9), 15fps, 220kbps
}

export const getCameraConfig = (
  resolution: CameraResolution
): VideoEncoderConfiguration | VideoEncoderConfigurationPreset => cameraConfigRecord[resolution]

export const getLowCameraConfig = (): LowStreamParameter => {
  // 180p
  return {
    width: 318,
    height: 180,
    framerate: 15,
    bitrate: 140,
  }
}

export const getScreenVideoConfig = (): ScreenVideoTrackInitConfig => {
  return {
    encoderConfig: isChrome ? '1080p_1' : '720p_1',
    optimizationMode: 'detail',
  }
}

export const getMicrophoneConfig = (): MicrophoneAudioTrackInitConfig => {
  return { AEC: true, ANS: true, AGC: true, encoderConfig: 'speech_standard', bypassWebAudio: isIOS }
}
