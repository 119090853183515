import React from 'react'
import { ActionModal } from 'sierra-client/components/common/modals/action-modal'
import { useTranslation } from 'sierra-client/hooks/use-translation'
import { useUserSettings } from 'sierra-client/views/user-settings/use-user-settings'

export const SendTestEmailAlert: React.FC<{
  open: boolean
  onClose: () => void
  onConfirm: () => void
  isLoading: boolean
}> = ({ open, onClose, onConfirm, isLoading }) => {
  const { t } = useTranslation()
  const { currentUser } = useUserSettings()

  return (
    <ActionModal
      title={t('manage.program.program-details.steps.add-email.send-test-email-alert.headline')}
      open={open}
      onClose={onClose}
      primaryAction={onConfirm}
      primaryActionLabel={t('manage.program.program-details.steps.add-email.send-test-email-alert.send')}
      isLoading={isLoading}
    >
      {t('manage.program.program-details.steps.add-email.send-test-email-alert.description', {
        email: currentUser?.email ?? '',
      })}
    </ActionModal>
  )
}
