import { BlockDefinition } from 'sierra-client/views/block-types'
import { unwrapToTextNodes } from 'sierra-client/views/v3-author/command'
import { isElementType } from 'sierra-client/views/v3-author/queries'
import { Text } from 'slate'

export const SimpleQuote: BlockDefinition = {
  plugin: editor => {
    const { normalizeNode } = editor
    editor.normalizeNode = entry => {
      const [node, path] = entry
      if (!isElementType('simple-quote', node)) return normalizeNode(entry)

      if (!node.children.every(Text.isText)) return unwrapToTextNodes(editor, { at: path })
      return normalizeNode(entry)
    }
    return editor
  },
}
