import { Category, PageInstanceProps } from 'sierra-client/state/logging/types'
import { RootState } from 'sierra-client/state/types'

type PageInstanceBase<InputArgs extends PageInstanceProps> = {
  category: Category
  id: string
  props: InputArgs
  userIdRequired: boolean
}

export type PageInstance<
  InputArgs extends PageInstanceProps,
  OutputArgs extends PageInstanceProps,
> = PageInstanceBase<InputArgs> & {
  enrich: (state: RootState) => OutputArgs
}

type MakePageInstanceParams<
  InputArgs extends PageInstanceProps,
  OutputArgs extends PageInstanceProps,
> = PageInstanceBase<InputArgs> & {
  enrich: (props: InputArgs, state: RootState) => OutputArgs
}

export const makePageInstance = <InputArgs extends PageInstanceProps, OutputArgs extends PageInstanceProps>({
  category,
  id,
  props,
  enrich,
  userIdRequired,
}: MakePageInstanceParams<InputArgs, OutputArgs>): PageInstance<InputArgs, OutputArgs> => ({
  category,
  id,
  props,
  enrich: state => enrich(props, state),
  userIdRequired,
})

export type AnyPageInstance = PageInstance<PageInstanceProps, PageInstanceProps>
