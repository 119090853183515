import { forwardRef } from 'react'
import { AppThemeTokenProvider } from 'sierra-client/config/token-provider'
import { assertNever } from 'sierra-domain/utils'
import { color } from 'sierra-ui/color'
import { resolveTokenOrColor } from 'sierra-ui/color/token-or-color'
import { ColorName } from 'sierra-ui/color/types'
import { Icon } from 'sierra-ui/components'
import { View } from 'sierra-ui/primitives'
import { token } from 'sierra-ui/theming'
import { TransientProps } from 'sierra-ui/types'
import styled from 'styled-components'

type Size = 'modal' | 'lobby' | 'leaderboard' | 'you' | 'podium' | 'question'

const getSize = (size: Size): string => {
  switch (size) {
    case 'leaderboard':
    case 'you':
      return '32px'
    case 'question':
      return '20px'
    case 'lobby':
      return '96px'
    case 'modal':
    case 'podium':
      return '160px'
    default:
      assertNever(size)
  }
}

const getFontSize = (size: Size): string => {
  switch (size) {
    case 'leaderboard':
    case 'you':
      return '20px'
    case 'question':
      return '24px; transform: scale(0.5);'
    case 'lobby':
      return '45px'
    case 'modal':
    case 'podium':
      return '90px'
    default:
      assertNever(size)
  }
}

const Wrapper = styled.div`
  position: relative;
`

const WrapperForStack = styled.div`
  position: relative;
  margin-left: -12px;
`

const Shuffle = styled(View).attrs({
  direction: 'column',
  justifyContent: 'center',
  alignItems: 'center',
  cursor: 'pointer',
  children: <Icon iconId='shuffle' />,
})`
  position: absolute;
  top: 3px;
  right: 3px;

  width: 36px;
  height: 36px;
  border-radius: 100%;
  background: ${token('surface/default')};
  box-shadow: 0 0 0 2px rgba(0, 0, 0, 0.08);
`

const Circle = styled.div<TransientProps<LiveQuizAvatarConfig>>`
  width: ${p => getSize(p.$size)};
  height: ${p => getSize(p.$size)};
  border-radius: 100%;
  background-color: ${p =>
    p.$background === undefined ? token('surface/soft') : resolveTokenOrColor(p.$background, p.theme)};
  cursor: pointer;

  display: flex;
  align-items: center;
  justify-content: center;
`

const CircleForStack = styled.div<TransientProps<LiveQuizAvatarConfig>>`
  width: ${p => getSize(p.$size)};
  height: ${p => getSize(p.$size)};
  border-radius: 100%;
  border: 1px solid white;
  background-color: ${p =>
    p.$background === undefined
      ? color('white').under(token('surface/default').opacity(0.2)(p))
      : resolveTokenOrColor(p.$background, p.theme)};
  cursor: pointer;

  display: flex;
  align-items: center;
  justify-content: center;
`

const EmojiSpan = styled.span<{ $size: Size }>`
  display: inline-block;
  font-size: ${p => getFontSize(p.$size)};
  user-select: none;
`

export type LiveQuizAvatarConfig = { size: Size; background?: ColorName }

export type LiveQuizAvatarProps = LiveQuizAvatarConfig & {
  icon: string
  onShuffle?: () => void
  onClickAvatar?: () => void
}

export const LiveQuizAvatar = forwardRef<HTMLDivElement, LiveQuizAvatarProps>(
  ({ size, background, icon, onShuffle, onClickAvatar }, ref) => (
    <Wrapper ref={ref}>
      <AppThemeTokenProvider>
        {onShuffle !== undefined && (
          <Shuffle
            onClick={e => {
              e.stopPropagation()
              onShuffle()
            }}
          />
        )}
      </AppThemeTokenProvider>
      <Circle $size={size} $background={background} onClick={onClickAvatar}>
        <EmojiSpan $size={size}>{icon}</EmojiSpan>
      </Circle>
    </Wrapper>
  )
)

export const LiveQuizAvatarforStack = forwardRef<HTMLDivElement, LiveQuizAvatarProps>(
  ({ size, background, icon, onShuffle, onClickAvatar }, ref) => (
    <WrapperForStack ref={ref}>
      <AppThemeTokenProvider>
        {onShuffle !== undefined && <Shuffle onClick={onShuffle} />}
      </AppThemeTokenProvider>
      <CircleForStack $size={size} $background={background} onClick={onClickAvatar}>
        <EmojiSpan $size={size}>{icon}</EmojiSpan>
      </CircleForStack>
    </WrapperForStack>
  )
)
