import { Emoji as EmojiMartEmoji } from 'emoji-mart'
import { useMemo } from 'react'
import { getEmojiData } from 'sierra-client/components/common/get-emoji-data'
import styled from 'styled-components'

const StyledEmoji = styled.span`
  .emoji-mart-emoji {
    display: flex;
  }
`
const EmojiFromNativeEmoji = ({ size, emojiUnicode, className }: EmojiFromNativeProps): JSX.Element => {
  const emoji = useMemo(() => getEmojiData(emojiUnicode), [emojiUnicode])

  return (
    <StyledEmoji className={className}>
      <EmojiMartEmoji native size={size} emoji={emoji} />
    </StyledEmoji>
  )
}

const EmojiFromCode = ({ size, emojiCode, className }: EmojiFromCodeProps): JSX.Element => {
  return (
    <StyledEmoji className={className}>
      <EmojiMartEmoji native size={size} emoji={emojiCode} />
    </StyledEmoji>
  )
}

type EmojiFromCodeProps = {
  size: number
  emojiCode: string
  className?: string
}

type EmojiFromNativeProps = {
  size: number
  emojiUnicode: string
  className?: string
}

type EmojiProps = EmojiFromNativeProps | EmojiFromCodeProps

const Emoji = (props: EmojiProps): JSX.Element => {
  if ('emojiCode' in props) {
    return <EmojiFromCode {...props} />
  }

  return <EmojiFromNativeEmoji {...props} />
}

// eslint-disable-next-line import/no-default-export
export default Emoji
