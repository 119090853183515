import { useEffect, useState } from 'react'
import { useLocalTracks } from 'sierra-client/components/liveV2/services/video-call-service/hooks/useLocalTracks'
import { useRemoteSpeakerAudioVolume } from 'sierra-client/components/liveV2/speaker-provider'
import { useSelector } from 'sierra-client/state/hooks'
import { selectAudioState, selectClientId } from 'sierra-client/state/live/selectors'
import { ColorName } from 'sierra-ui/color/types'
import { AudioIndicator } from 'sierra-ui/missions/live'

type AudioIndicatorProps = {
  agoraUID: string | undefined
  iconColor?: ColorName
  bgColor?: ColorName
}

export const LocalAudioIndicator: React.FC<{
  bgColor?: ColorName
  iconColor?: ColorName
  hideMutedIcon?: boolean
}> = ({ bgColor, iconColor, hideMutedIcon }) => {
  const [volume, setVolume] = useState<number>()
  const audioState = useSelector(selectAudioState)
  const { localAudioTrack } = useLocalTracks()

  useEffect(() => {
    if (audioState === 'on') {
      const interval = setInterval(() => {
        if (localAudioTrack) {
          setVolume(localAudioTrack.getVolumeLevel())
        } else {
          setVolume(undefined)
        }
      }, 100)
      return () => clearInterval(interval)
    } else {
      setVolume(undefined)
    }
  }, [audioState, localAudioTrack])

  return (
    <AudioIndicator volume={volume} iconColor={iconColor} bgColor={bgColor} hideMutedIcon={hideMutedIcon} />
  )
}

const RemoteAudioIndicator: React.FC<AudioIndicatorProps> = ({ agoraUID, iconColor, bgColor }) => {
  const volume = useRemoteSpeakerAudioVolume(agoraUID)
  return <AudioIndicator volume={volume} iconColor={iconColor} bgColor={bgColor} />
}

export const AudioIndicatorWrapper: React.FC<AudioIndicatorProps> = ({ agoraUID, iconColor, bgColor }) => {
  const localAgoraUID = useSelector(selectClientId)
  const userIsCurrentUser = localAgoraUID === agoraUID

  if (userIsCurrentUser) return <LocalAudioIndicator iconColor={iconColor} bgColor={bgColor} />
  return <RemoteAudioIndicator agoraUID={agoraUID} iconColor={iconColor} bgColor={bgColor} />
}
