import * as React from 'react'
import { useMemo } from 'react'
import { insightsWidgetPivotTablePaginationLogger } from 'sierra-client/features/insights/logger'
import { useTranslation } from 'sierra-client/hooks/use-translation'
import * as C from 'sierra-client/lib/tabular/components/cells'
import { InfoIcon } from 'sierra-client/lib/tabular/components/cells'
import * as U from 'sierra-client/lib/tabular/components/util'
import { Cell as CellType } from 'sierra-client/lib/tabular/datatype/internal/cell'
import { Header as HeaderType } from 'sierra-client/lib/tabular/datatype/internal/header'
import * as R from 'sierra-client/lib/tabular/datatype/internal/row'
import { labelToString } from 'sierra-client/lib/tabular/datatype/label'
import { useTabularContext } from 'sierra-client/lib/tabular/provider'
import { Td, Th, Tr } from 'sierra-client/lib/tabular/provider/components/common'
import { useVirtualizedRow } from 'sierra-client/lib/tabular/provider/components/use-virtualized-row'
import { useDispatch } from 'sierra-client/state/hooks'
import { IconButton, LoadingSpinner, View } from 'sierra-ui/primitives'
import { token } from 'sierra-ui/theming'
import styled, { css } from 'styled-components'

const StyledHeader = styled.span`
  display: inline-flex;
`

const CellContent = styled(View)`
  color: ${token('foreground/primary')};
`
const Cell: React.FC<{ cell: CellType }> = ({ cell }) => {
  const { api } = useTabularContext()
  return (
    <CellContent key={cell.pos.column}>
      <C.RenderCell cell={cell} api={api} />
    </CellContent>
  )
}

const EmptyTd = styled(Td)<{ $sticky?: boolean }>`
  background-color: ${token('surface/soft')};

  &:hover {
    background-color: ${token('surface/soft')};
  }

  ${p =>
    p.$sticky === true &&
    css`
      background-color: ${token('surface/default')};
      position: sticky;
      z-index: 9;
      left: 0;
    `}
`

type StickyDirProps = { direction?: 'left' | 'right' }
const StickyCellWrapTd = styled(Td)<StickyDirProps>`
  position: sticky;
  z-index: 9;
  ${p => (p.direction === 'left' ? 'left: 0;' : p.direction === 'right' ? 'right: 0;' : '')}
`

const StyledStickyCellWrap = styled(StickyCellWrapTd)`
  border-right: 1px solid ${token('border/default')};
`

const StyledTd = styled(Td)`
  border: 1px solid ${token('border/default')};
`
const CellWrap: React.FC<{ cell: CellType }> = ({ cell }) => {
  const isFirstColumn = !cell.pos.column.startsWith('value')

  if (cell.type === 'empty') {
    return <EmptyTd key={cell.pos.column} $sticky={isFirstColumn} />
  }

  return isFirstColumn ? (
    <StyledStickyCellWrap key={cell.pos.column} direction={'left'}>
      <Cell cell={cell} />
    </StyledStickyCellWrap>
  ) : (
    <StyledTd key={cell.pos.column}>
      <Cell cell={cell} />
    </StyledTd>
  )
}

const Cells: React.FC<{ cells: Array<CellType> }> = ({ cells }) => {
  return (
    <>
      {cells.map(cell => (
        <CellWrap key={cell.pos.row + cell.pos.column} cell={cell} />
      ))}
    </>
  )
}

export const Header = ({ header }: { header: HeaderType }): JSX.Element => {
  const { t } = useTranslation()

  switch (header.type) {
    case 'label':
      return (
        <StyledHeader>
          {labelToString(header.label, t)}
          {header.tooltip !== undefined && <InfoIcon title={labelToString(header.tooltip, t)} />}
        </StyledHeader>
      )
    case 'cell':
      return <Cell cell={header.cell} />
    default:
      return <></>
  }
}

const StickyHeaderTh = styled(Th)<StickyDirProps>`
  position: sticky;
  ${p => (p.direction === 'left' ? 'left: 0;' : p.direction === 'right' ? 'right: 0;' : '')}
  z-index: 11;
`

export const PivotPagination: React.FC<{
  currentIndex: number
  totalSize: number
  onChange: (newIndex: number) => void
  pivotTableLimit: number
}> = ({ currentIndex, totalSize, onChange, pivotTableLimit }) => {
  const { t } = useTranslation()
  const dispatch = useDispatch()

  const handlePrevious = (index: number): void => {
    void dispatch(insightsWidgetPivotTablePaginationLogger({ action: 'previous' }))
    onChange(index)
  }

  const handleNext = (index: number): void => {
    void dispatch(insightsWidgetPivotTablePaginationLogger({ action: 'next' }))
    onChange(index)
  }

  return (
    <View justifyContent='space-between'>
      <View>
        {currentIndex > 1 && (
          <IconButton
            iconId='previous--outline'
            variant='transparent'
            onClick={() => handlePrevious(currentIndex - pivotTableLimit)}
            tooltip={t('dictionary.previous')}
          />
        )}
      </View>

      <View>
        {currentIndex + pivotTableLimit < totalSize && (
          <IconButton
            variant='transparent'
            iconId='next--outline'
            onClick={() => handleNext(currentIndex + pivotTableLimit)}
            tooltip={t('dictionary.next')}
          />
        )}
      </View>
    </View>
  )
}

const StyledTh = styled(Th)`
  max-width: 13rem;
  min-width: 13rem;
  white-space: unset;
`
const StyledStickyHeader = styled(StickyHeaderTh)`
  border-right: 1px solid ${token('border/default')};
  min-width: 12rem !important;
`
export const PivotTableHeader: React.FC = () => {
  const { headers } = useTabularContext()
  return (
    <thead>
      <Tr>
        {headers.map(header => {
          const isFirstColumn = !header.ref.startsWith('value')

          return isFirstColumn ? (
            <StyledStickyHeader key={header.ref} direction={'left'}>
              <Header header={header} />
            </StyledStickyHeader>
          ) : (
            <StyledTh key={header.ref}>
              <Header header={header} />
            </StyledTh>
          )
        })}
      </Tr>
    </thead>
  )
}

export const RowWrap: React.FC<{ row: R.Row }> = ({ row }) => {
  const { callbacks } = useTabularContext()
  switch (row.type) {
    case 'Row.Flat':
      return (
        <U.CellRenderContext.Provider value={{ hasChildrenRows: false }}>
          <Tr key={row.ref} onClick={() => callbacks?.onRow?.(row)}>
            <Cells cells={row.cells} />
          </Tr>
        </U.CellRenderContext.Provider>
      )
    default:
      throw new Error('Pivot table only supports flat rows')
  }
}
type RenderVirtualizedRowsAtomProps = {
  containerRef: HTMLDivElement | null
}

export const TableBody: React.FC<RenderVirtualizedRowsAtomProps> = ({ containerRef }) => {
  const { pages, dataloaderState } = useTabularContext()

  const rows = useMemo(() => {
    return pages.flatMap(it => it)
  }, [pages])

  const { padding, visibleRows } = useVirtualizedRow({
    containerRef,
    count: rows.length,
    overscan: 20,
    originalRows: rows,
    estimateSize: () => 100, // This should be the highest row height, but the virtualizer will adapt to the actual height
  })

  if (dataloaderState === 'init') {
    return (
      <tr>
        <td colSpan={999}>
          <LoadingSpinner />
        </td>
      </tr>
    )
  }
  return (
    <tbody>
      {padding.top > 0 && (
        <tr>
          <td style={{ height: padding.top }} />
        </tr>
      )}
      {visibleRows.map(row => (
        <RowWrap key={row.ref} row={row} />
      ))}
      {padding.bottom > 0 && (
        <tr>
          <td style={{ height: padding.bottom }} />
        </tr>
      )}
    </tbody>
  )
}
