import { useCallback } from 'react'
import { useIsMobileHelper } from 'sierra-client/views/course-helper/hooks/use-is-mobile-helper'
import { inlineStatePadding, InlineStatePaddingSwitch } from 'sierra-client/views/course-helper/shared/shared'
import { useSelfPacedFiles } from 'sierra-client/views/self-paced/files-provider'
import { Icon } from 'sierra-ui/components'
import { View } from 'sierra-ui/primitives'
import styled from 'styled-components'

const ForwardContainer = styled(View).attrs({
  grow: true,
  direction: 'row',
  gap: '8',
  cursor: 'pointer',
})<InlineStatePaddingSwitch>`
  ${inlineStatePadding};
`

export const Forward: React.FC = () => {
  const { nextUp, linearNextUp, goToNextUp } = useSelfPacedFiles()
  const isMobileHelper = useIsMobileHelper()

  const goToNext = useCallback(() => {
    if (nextUp.type !== 'none') {
      goToNextUp(nextUp)
    } else if (linearNextUp.type !== 'none') {
      goToNextUp(linearNextUp)
    }
  }, [nextUp, linearNextUp, goToNextUp])

  if (nextUp.type === 'none' && linearNextUp.type === 'none') {
    return null
  }

  return (
    <ForwardContainer $isMobile={isMobileHelper} onClick={goToNext} data-testid='forward-button'>
      <Icon iconId='next--filled' size='size-20' />
    </ForwardContainer>
  )
}
