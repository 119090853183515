import { z } from 'zod'

export const SkillColor = z.enum([
  'titanium',
  'platinum',
  'gold',
  'silver',
  'bronze',
  'sapphire',
  'emerald',
  'ruby',
])
export type SkillColor = z.infer<typeof SkillColor>

export const useGetSkillColors = (): Array<SkillColor> => {
  return SkillColor.options
}

export const translateSkillColor: Record<SkillColor, { left: string; right: string }> = {
  titanium: { left: '#B4B4B4', right: '#3C3C3C' },
  platinum: { left: '#D0CFD8', right: '#AAA6B5' },
  gold: { left: '#F0E8C7', right: '#BDA543' },
  silver: { left: '#EDEEF4', right: '#C7C8CF' },
  bronze: { left: '#E4B386', right: '#D89558' },
  sapphire: { left: '#8d98f9', right: '#2734b8' },
  emerald: { left: '#3bc593', right: '#065d3e' },
  ruby: { left: '#f05f5f', right: '#8a0f0f' },
}

export const skillColorToGradientBackground: Record<SkillColor, `linear-gradient(${string})`> =
  Object.fromEntries(
    Object.entries(translateSkillColor).map(([key, value]) => [
      key,
      `linear-gradient(180deg, ${value.left} 0%, ${value.right} 100%)`,
    ])
  ) as Record<SkillColor, `linear-gradient(${string})`>
