import { useCallback, useEffect, useRef, useState } from 'react'
import { usePost } from 'sierra-client/hooks/use-post'
import { FetchUserAssignments } from 'sierra-client/views/manage/components/content-users-table'
import {
  LiveSessionDetailsResponse,
  LiveSessionListEnrolledUsersResponse,
  LiveSessionListRecordingsResponse,
  LiveSessionSetAttendanceRequest,
} from 'sierra-domain/api/admin'
import { LiveSessionId } from 'sierra-domain/api/nano-id'
import { UserId } from 'sierra-domain/api/uuid'
import {
  XRealtimeAdminAssignmentsUnassignFromLiveSession,
  XRealtimeAdminLiveSessionsDeleteRecording,
  XRealtimeAdminLiveSessionsListEnrolledUsers,
  XRealtimeAdminLiveSessionsListRecordings,
  XRealtimeAdminLiveSessionsLiveSessionDetail,
  XRealtimeAdminLiveSessionsSetUsersAttendance,
} from 'sierra-domain/routes'

export type UseLiveSessionDetailsData = {
  liveSession?: LiveSessionDetailsResponse
  isLoading: boolean
  liveSessionUsers: LiveSessionListEnrolledUsersResponse & { isLoading: boolean }
  liveSessionRecordings: LiveSessionListRecordingsResponse & { isLoading: boolean }
  fetchLiveSession: (id: LiveSessionId) => Promise<void>
  unassignUsers: (id: LiveSessionId, userIds: UserId[]) => Promise<void>
  setAttendance: (req: LiveSessionSetAttendanceRequest) => Promise<void>
  fetchUsers: FetchUserAssignments<LiveSessionListEnrolledUsersResponse>
  fetchRecordings: (id: LiveSessionId) => Promise<void>
  removeRecording: (id: string) => Promise<void>
}

export const useLiveSessionDetails = (liveSessionId?: LiveSessionId): UseLiveSessionDetailsData => {
  const { postWithUserErrorException } = usePost()
  const [isLoading, setLoading] = useState(false)
  const [liveSession, setLiveSession] = useState<LiveSessionDetailsResponse | undefined>(undefined)

  const [liveSessionUsers, setLiveSessionUsers] = useState<UseLiveSessionDetailsData['liveSessionUsers']>({
    hasMore: false,
    data: [],
    isLoading: false,
  })
  const [liveSessionRecordings, setLiveSessionRecordings] = useState<
    UseLiveSessionDetailsData['liveSessionRecordings']
  >({
    hasMore: false,
    data: [],
    isLoading: false,
  })
  const unmounted = useRef(false)

  const fetchLiveSession = useCallback<UseLiveSessionDetailsData['fetchLiveSession']>(
    async id => {
      setLoading(true)
      const result = await postWithUserErrorException(XRealtimeAdminLiveSessionsLiveSessionDetail, {
        liveSessionId: id,
      })
      if (unmounted.current) return
      setLiveSession(result)
      setLoading(false)
    },
    [postWithUserErrorException]
  )

  const unassignUsers = useCallback<UseLiveSessionDetailsData['unassignUsers']>(
    async (id, userIds) => {
      await postWithUserErrorException(XRealtimeAdminAssignmentsUnassignFromLiveSession, {
        liveSessionId: id,
        userIds,
      })
    },
    [postWithUserErrorException]
  )

  const removeRecording = useCallback<UseLiveSessionDetailsData['removeRecording']>(
    async (recordingId: string) => {
      await postWithUserErrorException(XRealtimeAdminLiveSessionsDeleteRecording, {
        recordingId,
      })
    },
    [postWithUserErrorException]
  )

  const setAttendance = useCallback<UseLiveSessionDetailsData['setAttendance']>(
    async req => {
      await postWithUserErrorException(XRealtimeAdminLiveSessionsSetUsersAttendance, req)
    },
    [postWithUserErrorException]
  )

  const fetchRecordings = useCallback<UseLiveSessionDetailsData['fetchRecordings']>(
    async id => {
      setLiveSessionRecordings(current => ({ ...current, isLoading: true }))
      const response = await postWithUserErrorException(XRealtimeAdminLiveSessionsListRecordings, {
        liveSessionId: id,
        maxResults: undefined,
      })
      setLiveSessionRecordings({
        isLoading: false,
        data: response.data,
        hasMore: false,
      })
    },
    [postWithUserErrorException]
  )

  const fetchUsers = useCallback<UseLiveSessionDetailsData['fetchUsers']>(
    async (id, commonFilters, options = {}) => {
      setLiveSessionUsers(current => ({ ...current, isLoading: true }))
      const response = await postWithUserErrorException(XRealtimeAdminLiveSessionsListEnrolledUsers, {
        liveSessionId: id,
        commonFilters: {
          lastUserId: undefined,
          maxResults: options.forCsv === true ? 1000 : undefined, //If forCsv is true, we want to fetch all users
          query: undefined,
          sortBy: { direction: 'asc', type: 'name' },
          requestedUserIds: undefined,
          groupIds: undefined,
          ...commonFilters,
        },
      })

      // Don't set state for CSV export
      if (options.forCsv === true) {
        setLiveSessionUsers(current => ({ ...current, isLoading: false }))
        return response
      }

      setLiveSessionUsers(current =>
        options.reset === true
          ? { ...response, isLoading: false }
          : {
              hasMore: response.hasMore,
              data: current.data.concat(response.data),
              isLoading: false,
            }
      )
      return response
    },
    [postWithUserErrorException]
  )

  useEffect(() => {
    if (liveSessionId === undefined) return
    unmounted.current = false
    void fetchLiveSession(liveSessionId)
    return () => {
      unmounted.current = true
    }
  }, [fetchLiveSession, liveSessionId])

  // Load separately
  useEffect(() => {
    if (liveSessionId === undefined) return
    void fetchUsers(liveSessionId, undefined, { reset: true })
  }, [liveSessionId, fetchUsers])

  useEffect(() => {
    if (liveSessionId === undefined) return
    void fetchRecordings(liveSessionId)
  }, [fetchRecordings, liveSessionId])

  return {
    isLoading,
    liveSession,
    liveSessionUsers,
    liveSessionRecordings,
    fetchLiveSession,
    fetchRecordings,
    unassignUsers,
    removeRecording,
    setAttendance,
    fetchUsers,
  }
}
