import { createFileRoute } from '@tanstack/react-router'
import { requireLoggedIn } from 'sierra-client/core/require-logged-in'
import { LiveRoute } from 'sierra-client/views/liveV2/live-page'
import { LiveSessionId } from 'sierra-domain/api/nano-id'
import { z } from 'zod'

function Page(): JSX.Element | null {
  const liveSessionId = Route.useParams({ select: select => select.liveSessionId })
  return <LiveRoute liveSessionId={liveSessionId} />
}

export const Route = createFileRoute('/l/$liveSessionId/')({
  component: requireLoggedIn(Page, { allowGuestAccess: true }) as React.FC,
  params: {
    parse: z.object({ liveSessionId: LiveSessionId }).parse,
    stringify: p => p,
  },
})
