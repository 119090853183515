import React from 'react'
import { Icon } from 'sierra-ui/components'
import { View } from 'sierra-ui/primitives'
import { spacing } from 'sierra-ui/theming'
import styled from 'styled-components'

const CardContainer = styled(View).attrs({
  grow: true,
  background: 'grey80',
})`
  position: relative;
  align-self: stretch;
  justify-content: center;
`

const Background = styled.div`
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  display: grid;
  grid-template-columns: repeat(4, 1fr);
  grid-template-rows: repeat(3, 1fr);
  gap: 4rem;
  padding: ${spacing['96']};
`
const ParticipantPlaceholder = styled.div`
  background: ${p => p.theme.color.grey35};
  border-radius: 1em;
`

export const LobbyCard: React.FC<unknown> = () => {
  return (
    <CardContainer>
      <Icon iconId='video' color='white' size='size-24' />

      <Background>
        <ParticipantPlaceholder />
        <ParticipantPlaceholder />
        <ParticipantPlaceholder />
        <ParticipantPlaceholder />
        <ParticipantPlaceholder />
        <ParticipantPlaceholder />
        <ParticipantPlaceholder />
        <ParticipantPlaceholder />
        <ParticipantPlaceholder />
        <ParticipantPlaceholder />
        <ParticipantPlaceholder />
        <ParticipantPlaceholder />
      </Background>
    </CardContainer>
  )
}
