import React, { forwardRef } from 'react'
import styled from 'styled-components'

const Wrap = styled.div<{ $width?: number; $height?: number }>`
  & svg {
    display: block;
    width: ${p => p.$width ?? 32}px;
    height: ${p => p.$height ?? 32}px;
  }
`

type Props = { width?: number; height?: number }
export const MeetLogo = forwardRef<HTMLDivElement, React.HTMLAttributes<HTMLDivElement> & Props>(
  ({ width, height, ...divProps }, ref): JSX.Element => (
    <Wrap $width={width} $height={height} {...divProps} ref={ref}>
      <svg width='48' height='40' viewBox='0 0 48 40' fill='none' xmlns='http://www.w3.org/2000/svg'>
        <g clipPath='url(#clip0_8369_29747)'>
          <path
            d='M27.1523 19.7438L31.8317 25.0923L38.1238 29.1134L39.2209 19.7767L38.1238 10.6484L31.711 14.1812L27.1523 19.7438Z'
            fill='#00832D'
          />
          <path
            d='M0 28.2473V36.2016C0 38.0201 1.47291 39.493 3.29143 39.493H11.2457L12.8914 33.4806L11.2457 28.2473L5.78743 26.6016L0 28.2473Z'
            fill='#0066DA'
          />
          <path
            d='M11.2457 0L0 11.2457L5.78743 12.8914L11.2457 11.2457L12.864 6.08366L11.2457 0Z'
            fill='#E94235'
          />
          <path d='M11.2457 11.25H0V28.2557H11.2457V11.25Z' fill='#2684FC' />
          <path
            d='M45.3163 4.75913L38.13 10.6508V29.1157L45.3492 35.0348C46.4299 35.8796 48.0098 35.1088 48.0098 33.7347V6.03182C48.0098 4.64119 46.3942 3.87868 45.3163 4.75913ZM27.1586 19.7461V28.249H11.25V39.4947H34.8386C36.6571 39.4947 38.13 38.0218 38.13 36.2032V29.1157L27.1586 19.7461Z'
            fill='#00AC47'
          />
          <path
            d='M34.8386 0H11.25V11.2457H27.1586V19.7486L38.13 10.6587V3.29143C38.13 1.47291 36.6571 0 34.8386 0Z'
            fill='#FFBA00'
          />
        </g>
        <defs>
          <clipPath id='clip0_8369_29747'>
            <rect width='48' height='39.4971' fill='white' />
          </clipPath>
        </defs>
      </svg>
    </Wrap>
  )
)
