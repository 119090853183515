import { useTranslation } from 'sierra-client/hooks/use-translation'
import {
  IconPicker,
  IconPickerOption,
} from 'sierra-client/views/flexible-content/editor/toolbar/icon-picker/icon-picker'
import { File } from 'sierra-domain/flexible-content/types'
import { BulletCard } from 'sierra-domain/v3-author'

type ImageLayout = NonNullable<BulletCard['variant']>
type ImageLayoutPickerProps = {
  onLayoutChange: (layout: ImageLayout) => void
  currentLayout: ImageLayout
}

export const ImageLayoutPicker: React.FC<ImageLayoutPickerProps> = ({ onLayoutChange, currentLayout }) => {
  const { t } = useTranslation()
  const layoutOptions: IconPickerOption<ImageLayout>[] = [
    { key: 'trailing-image', iconId: 'layout--right-block' },
    { key: 'leading-image', iconId: 'layout--left-block' },
  ]

  return (
    <IconPicker
      tooltip={t('create.toolbar.layout')}
      label={t('create.toolbar.layout')}
      options={layoutOptions}
      onUpdate={option => onLayoutChange(option.key)}
      activeOption={
        layoutOptions.find(({ key }) => key === currentLayout) ?? {
          key: 'trailing-image',
          iconId: 'layout--right-block',
        }
      }
    />
  )
}

type LayoutWidth = NonNullable<File['layout']>
type LayoutWidthPickerProps = {
  onLayoutChange: (layout: LayoutWidth) => void
  currentLayout: LayoutWidth
}

export const LayoutWidthPicker: React.FC<LayoutWidthPickerProps> = ({ onLayoutChange, currentLayout }) => {
  const { t } = useTranslation()
  const layoutOptions: IconPickerOption<LayoutWidth>[] = [
    { key: 'narrow', iconId: 'layout--center' },
    { key: 'wide', iconId: 'resize--large' },
  ]

  return (
    <IconPicker
      tooltip={t('create.toolbar.layout')}
      label={t('create.toolbar.layout')}
      options={layoutOptions}
      onUpdate={option => onLayoutChange(option.key)}
      activeOption={
        layoutOptions.find(({ key }) => key === currentLayout) ?? {
          key: 'narrow',
          iconId: 'resize--medium',
        }
      }
    />
  )
}
