import { DateTime } from 'luxon'
import { apiQueryOptions, RouteQueryOptions } from 'sierra-client/state/api'
import { UserId } from 'sierra-domain/api/uuid'
import { XRealtimeUsersUserTranscript } from 'sierra-domain/routes'

export const getUserTranscriptQuery = ({
  userId,
}: {
  userId: UserId
}): RouteQueryOptions<typeof XRealtimeUsersUserTranscript> => {
  return {
    ...apiQueryOptions(XRealtimeUsersUserTranscript, { userId }),
    staleTime: 10 * 1000, // 10 seconds
    select: data => {
      const sortedExtrenalTrainings = data.externalTrainings.sort(
        (a, b) => DateTime.fromSQL(b.completionDate).valueOf() - DateTime.fromSQL(a.completionDate).valueOf()
      )
      return {
        ...data,
        externalTrainings: sortedExtrenalTrainings,
      }
    },
  }
}
