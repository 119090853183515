import { percentage } from 'sierra-client/core/format'
import { ContentProgramEnrollment, EnrolledUser } from 'sierra-domain/api/manage'

type ContentGroupCsv = {
  groupId: string
  groupName: string
  groupAdminName: string
  progress: string
  assignedAt: string
}

export const parseEnrolledGroupToCsv = (group: ContentProgramEnrollment): ContentGroupCsv => ({
  groupId: group.groupInfo.groupId,
  groupName: group.groupInfo.groupName ?? '',
  groupAdminName: group.groupInfo.adminName ?? '',
  progress: group.progress?.toFixed(2) ?? '0',
  assignedAt: group.assignedAt.toISOString(),
})

export type ContentUserCsv = {
  userId: string
  email: string
  firstName: string
  lastName: string
  progress: string
  assignedAt: string
  lastActivity: string
}

export const parseEnrolledUserToCsv = (user: EnrolledUser): ContentUserCsv => ({
  userId: user.userInfo.baseUserInfo.userId,
  email: user.userInfo.baseUserInfo.email,
  firstName: user.userInfo.baseUserInfo.firstName ?? '',
  lastName: user.userInfo.baseUserInfo.lastName ?? '',
  progress: percentage(user.progress),
  assignedAt: user.assignedAt?.toISOString() ?? '',
  lastActivity: user.lastActivity?.toISOString() ?? '',
})
