import { graphql } from 'sierra-client/api/graphql/gql'
import {
  AddCertificateToCourseMutation,
  RemoveCertificateFromCourseMutation,
} from 'sierra-client/api/graphql/gql/graphql'
import { graphQuery } from 'sierra-client/api/hooks/use-graphql-query'

export const useAddCertificateToCourse = (): ((
  courseId: string,
  certificateId: string
) => Promise<AddCertificateToCourseMutation>) => {
  const onAdd = (courseId: string, certificateId: string): Promise<AddCertificateToCourseMutation> =>
    graphQuery(
      graphql(`
        mutation addCertificateToCourse($courseId: CourseId!, $certificateId: CertificateId!) {
          addCertificateToCourse(courseId: $courseId, certificateId: $certificateId) {
            certificateIds
          }
        }
      `),
      { courseId, certificateId }
    )

  return onAdd
}

export const useDeleteCertificateFromCourse = (): ((
  courseId: string,
  certificateId: string
) => Promise<RemoveCertificateFromCourseMutation>) => {
  const onDelete = (courseId: string, certificateId: string): Promise<RemoveCertificateFromCourseMutation> =>
    graphQuery(
      graphql(`
        mutation removeCertificateFromCourse($courseId: CourseId!, $certificateId: CertificateId!) {
          removeCertificateFromCourse(courseId: $courseId, certificateId: $certificateId) {
            certificateIds
          }
        }
      `),
      { courseId, certificateId }
    )

  return onDelete
}
