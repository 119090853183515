import { Icon, IconId, Tooltip } from 'sierra-ui/components'
import { HeadingPrimitive, Text, View } from 'sierra-ui/primitives'
import styled from 'styled-components'

export const Statistics = styled(View).attrs({ direction: 'row', wrap: 'nowrap' })`
  align-items: stretch;
  flex: 1;
`

const Card = styled(View).attrs({
  direction: 'column',
  justifyContent: 'space-between',
  alignItems: 'flex-start',
  grow: true,
  radius: 'size-16',
  borderColor: 'border/default',
  padding: '16',
})`
  flex: 0 1 100%;
  min-height: 200px;
`

const Top = styled(View).attrs({ direction: 'column', alignItems: 'flex-start' })``

const Bottom = styled(View)``

const Primitive = styled(HeadingPrimitive).attrs({ size: 'h4' })``

const GreyText = styled(Text).attrs({
  size: 'small',
  bold: true,
  color: 'foreground/muted',
})``

export const Statistic: React.FC<{
  top: string
  primitive: string
  bottom: string
  iconId?: IconId
  tooltip?: string
}> = ({ top, primitive, bottom, iconId, tooltip }) => {
  return (
    <Card>
      <Top>
        <View gap='8'>
          <GreyText>{top}</GreyText>
          {tooltip !== undefined && (
            <Tooltip title={tooltip}>
              <Icon iconId='information' size='size-16' color={'grey25'} />
            </Tooltip>
          )}
        </View>
        <Primitive>{primitive}</Primitive>
      </Top>
      <Bottom>
        {iconId && <Icon iconId={iconId} color='grey25' />}
        <GreyText>{bottom}</GreyText>
      </Bottom>
    </Card>
  )
}
