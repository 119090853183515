import React from 'react'
import { useTranslation } from 'sierra-client/hooks/use-translation'
import { Toolbar, ToolbarIcon } from 'sierra-client/views/v3-author/block-toolbar'
import { removeNodeWithId, updateNodeWithId } from 'sierra-client/views/v3-author/command'
import { assertElementType } from 'sierra-client/views/v3-author/queries'
import { SlateFC } from 'sierra-client/views/v3-author/slate'
import { Entity } from 'sierra-domain/entity'
import { Separator as SeparatorBlock } from 'sierra-domain/v3-author'
import { color } from 'sierra-ui/color'
import { useFocused, useSelected, useSlateStatic } from 'slate-react'
import styled, { css } from 'styled-components'

const HR = styled.hr`
  height: 0;
  border: none;
  position: absolute;
`

const SolidLine = styled.div`
  width: 100%;
  background-color: ${p => color(p.theme.home.textColor).opacity(0.1).toString()};
  height: 2px;
`

const SeparatorToolbar: React.FC<{ element: Entity<SeparatorBlock> }> = ({ element }) => {
  const { t } = useTranslation()
  const editor = useSlateStatic()

  const { id, variant } = element
  return (
    <Toolbar elementId={element.id}>
      <ToolbarIcon
        tooltip={t('author.block-editor.separator-solid')}
        iconId='divider'
        selected={variant === 'solid'}
        onClick={() => updateNodeWithId(editor, id, { variant: 'solid' })}
      />
      <ToolbarIcon
        tooltip={t('author.block-editor.separator-dots')}
        iconId='divider'
        selected={variant === 'dotted'}
        onClick={() => updateNodeWithId(editor, id, { variant: 'dotted' })}
      />
      <ToolbarIcon
        tooltip={t('author.block-editor.remove')}
        iconId='trash-can'
        onClick={() => removeNodeWithId(editor, id)}
      />
    </Toolbar>
  )
}

const DotContainer = styled.div`
  width: 100%;
  display: flex;
  justify-content: center;
`

const Dot = styled.span`
  display: inline-block;
  width: 0.275rem;
  height: 0.275rem;
  border-radius: 50%;
  background: ${p => color(p.theme.home.textColor).opacity(0.1).toString()};
  margin: 0 0.5rem;
`

const SeparatorWrapper = styled.div<{ $selected: boolean }>`
  position: relative;
  padding: 0;

  border-radius: 2px;

  ${p =>
    p.$selected &&
    css`
      box-shadow: 0 0 0 2px #b4d5ff;
    `}
`
const Dotted: React.FC = () => (
  <>
    <HR />
    <DotContainer>
      <Dot />
      <Dot />
      <Dot />
    </DotContainer>
    {/* Empty span to satisfy slate */}
    <span />
  </>
)

const Solid: React.FC = () => (
  <>
    <HR />
    <SolidLine />
    {/* Empty span to satisfy slate */}
    <span />
  </>
)

export const SeparatorComponent: SlateFC = ({ children, element }) => {
  assertElementType('separator', element)
  const selected = useSelected()
  const focused = useFocused()
  return (
    <SeparatorWrapper $selected={selected && focused}>
      {children}
      <div contentEditable={false}>{element.variant === 'solid' ? <Solid /> : <Dotted />}</div>
      <SeparatorToolbar element={element} />
    </SeparatorWrapper>
  )
}
