import React, { useCallback, useContext, useMemo, useState } from 'react'
import { useDispatch, useSelector } from 'sierra-client/state/hooks'
import { resetQuestionCard } from 'sierra-client/state/self-paced/actions'
import { selectUserId } from 'sierra-client/state/user/user-selector'
import { FCC } from 'sierra-client/types'
import { useQuestionShuffle } from 'sierra-client/views/flexible-content/question-shuffling/shuffle-elements-provider'
import { Entity } from 'sierra-domain/entity'
import { QuestionCardBody } from 'sierra-domain/v3-author'

type QuestionCardContext = {
  retryQuestion: () => void
  isRetry: boolean
  //Todo(move question card data layer functionality here)
}

type FullQuestionCardContext = {
  questionContexts: {
    [questionBodyId: Entity<QuestionCardBody>['id']]: QuestionCardContext
    //These ids are the ids corresponding to the exercises held in the backend.
  } //They correspond to the question-body not the question-card
}

const SelfPacedQuestionContext = React.createContext<FullQuestionCardContext | undefined>(undefined)

export const SelfPacedQuestionProvider: FCC<{ questionBodyIds: Entity<QuestionCardBody>['id'][] }> = ({
  questionBodyIds,
  children,
}) => {
  const { reshuffle } = useQuestionShuffle()
  const [isRetry, setIsRetry] = useState<string>('')
  const userId = useSelector(selectUserId)
  const dispatch = useDispatch()
  if (userId === undefined) throw new Error('No userId found for in question card data layer.')

  const retry = useCallback(
    (questionBodyId: Entity<QuestionCardBody>['id']) => {
      setIsRetry(questionBodyId)
      void dispatch(resetQuestionCard({ questionExerciseId: questionBodyId }))
      reshuffle(questionBodyId)
    },
    [dispatch, reshuffle]
  )

  const value: FullQuestionCardContext = useMemo(() => {
    return {
      questionContexts: Object.assign(
        {},
        ...questionBodyIds.map(questionBodyId => ({
          [questionBodyId]: {
            retryQuestion: () => retry(questionBodyId),
            isRetry: isRetry === questionBodyId,
          },
        }))
      ),
    }
  }, [isRetry, questionBodyIds, retry])

  return <SelfPacedQuestionContext.Provider value={value}>{children}</SelfPacedQuestionContext.Provider>
}

export const useQuestionContext = (questionBodyId: Entity<QuestionCardBody>['id']): QuestionCardContext => {
  const data = useContext(SelfPacedQuestionContext)

  if (!data) {
    throw new Error('Questions data not provided')
  }

  const questionData = data.questionContexts[questionBodyId]

  if (!questionData) {
    throw new Error(
      `questionId ${questionBodyId} not found in context ${JSON.stringify(
        Object.keys(data.questionContexts)
      )} provider`
    )
  }

  return questionData
}
