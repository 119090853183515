import { useTranslation } from 'sierra-client/hooks/use-translation'
import { Icon } from 'sierra-ui/components'
import { Text } from 'sierra-ui/primitives'
import styled from 'styled-components'

const Container = styled.div`
  text-align: center;
  gap: 0.5rem;
`

export const FirstOneHereView = (): JSX.Element => {
  const { t } = useTranslation()
  return (
    <Container>
      <Icon iconId='face--add' color='foreground/muted' />
      <Text size='small' color='foreground/muted'>
        {t('live.youre-the-first-one-here')}
      </Text>
    </Container>
  )
}
