import React, { useMemo } from 'react'
import { postWithUserErrorException } from 'sierra-client/state/api'
import { useDispatch, useSelector } from 'sierra-client/state/hooks'
import { setLiveQuizState, setLiveQuizUser } from 'sierra-client/state/live-session/actions'
import { selectLiveQuizStateById } from 'sierra-client/state/live-session/selectors'
import { store } from 'sierra-client/state/store'
import { RootState } from 'sierra-client/state/types'
import { selectUserId } from 'sierra-client/state/user/user-selector'
import { useFileContext } from 'sierra-client/views/flexible-content/file-context'
import { SlateWrapperProps } from 'sierra-client/views/v3-author/slate'
import { LiveQuizGetLeaderboardResponse } from 'sierra-domain/api/strategy-v2'
import { UserId } from 'sierra-domain/api/uuid'
import { LiveQuizStatus, LiveQuizUser } from 'sierra-domain/live-session'
import {
  XRealtimeStrategyContentDataLiveQuizGetLeaderboard,
  XRealtimeStrategyContentDataLiveQuizStartQuestion,
} from 'sierra-domain/routes'

export type UserInfo = {
  nickname: string
  points: number
  questions: { [questionId: string]: 'correct' | 'incorrect' }
}

export type Info = Record<UserId, UserInfo>

export type LiveQuizContext = {
  status: LiveQuizStatus
  user: LiveQuizUser | undefined
  users: LiveQuizUser[]
  setStatus: (newState: LiveQuizStatus) => void
  setUser: (newUser: Omit<LiveQuizUser, 'userId'>) => void
  startQuestion: ({
    questionId,
    liveSessionId,
    fileId,
  }: {
    questionId: string
    liveSessionId: string
    fileId: string
  }) => Promise<number>
  info: Info
  questionCount: number
  fileId: string

  refreshLeaderboardAfterQuestion: ({
    questionId,
    liveSessionId,
    fileId,
  }: {
    questionId: string
    liveSessionId: string
    fileId: string
  }) => Promise<void>
  leaderboard: LiveQuizGetLeaderboardResponse | undefined
}

const LiveQuizContext = React.createContext<LiveQuizContext | undefined>(undefined)

export const LiveQuizContextProvider = LiveQuizContext.Provider

const startQuestion: LiveQuizContext['startQuestion'] = async ({ questionId, liveSessionId, fileId }) => {
  const { startedAt } = await postWithUserErrorException(
    XRealtimeStrategyContentDataLiveQuizStartQuestion,
    {
      liveSessionId,
      fileId,
      questionId,
    },
    store.dispatch
  )
  return startedAt
}

export const LiveQuizContextWrapper: React.FC<SlateWrapperProps> = ({ children, element }) => {
  const { file } = useFileContext()
  const dispatch = useDispatch()
  const userId = useSelector(selectUserId)
  const stateById = useSelector((state: RootState) => selectLiveQuizStateById(state, file.id))
  const [leaderboard, setLeaderboard] = React.useState<LiveQuizGetLeaderboardResponse | undefined>(undefined)

  if (file.data.type !== 'assessment-card') {
    throw new Error(
      `[AssessmentCard] Trying to access assessment card in flexible content but is of type ${file.data.type}`
    )
  }

  const contextValue = useMemo<LiveQuizContext>(
    () => ({
      status: stateById !== undefined ? stateById.status : { step: 'lobby' },
      setStatus: (newState: LiveQuizStatus) => {
        dispatch(setLiveQuizState({ fileId: file.id, status: newState }))
      },

      user: stateById !== undefined && userId !== undefined ? stateById.users[userId] : undefined,
      users: stateById !== undefined ? Object.values(stateById.users).filter(user => user !== undefined) : [],

      setUser: newUser => {
        if (userId === undefined) {
          throw new Error('User id not found')
        }

        dispatch(setLiveQuizUser({ fileId: file.id, user: { userId, ...newUser } }))
      },
      startQuestion,
      info: {},
      questionCount: element.children.length - 1,
      fileId: file.id,

      refreshLeaderboardAfterQuestion: async ({
        questionId,
        liveSessionId,
        fileId,
      }: {
        questionId: string
        liveSessionId: string
        fileId: string
      }): Promise<void> => {
        const l = await postWithUserErrorException(
          XRealtimeStrategyContentDataLiveQuizGetLeaderboard,
          {
            liveSessionId,
            fileId,
            excludeQuestionId: questionId,
          },
          store.dispatch
        )
        setLeaderboard(l)
      },
      leaderboard,
    }),

    [stateById, element.children.length, file.id, leaderboard, dispatch, userId]
  )

  return <LiveQuizContextProvider value={contextValue}>{children}</LiveQuizContextProvider>
}

export function useLiveQuizContext(): LiveQuizContext {
  const context = React.useContext(LiveQuizContext)

  if (context === undefined) throw new Error('useLiveQuizContext must be used within a QuizContextProvider')

  return context
}

export function useIsInLiveQuizContext(): boolean {
  const context = React.useContext(LiveQuizContext)
  return context !== undefined
}
