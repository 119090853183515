import React, { useCallback, useEffect, useMemo, useState } from 'react'
import { invalidateListHeatmaps } from 'sierra-client/api/hooks/use-heatmaps'
import { usePost } from 'sierra-client/hooks/use-post'
import { useTranslation } from 'sierra-client/hooks/use-translation'
import { Contains, Or, Value, createFilter } from 'sierra-client/lib/filter'
import { useCachedQuery } from 'sierra-client/state/api'
import { DownloadHeatmapButton } from 'sierra-client/views/manage/reports/components/heatmap/components/download-heatmap-button'
import { HeatmapV2 } from 'sierra-client/views/manage/reports/components/heatmap/heatmap-v2'
import { useHeatmapReportJobState } from 'sierra-client/views/manage/reports/components/heatmap/hooks/use-heatmap-report-job-state'
import { HeatmapUserFilter } from 'sierra-client/views/manage/reports/components/heatmap/types'
import { useIsReportingEnabled } from 'sierra-client/views/manage/reports/report-utils'
import {
  XRealtimeAdminHeatmapDeleteProgramHeatmap,
  XRealtimeAdminHeatmapGetHeatmapProgram,
  XRealtimeAdminHeatmapSaveProgramHeatmap,
} from 'sierra-domain/routes'
import { Modal, ModalHeader } from 'sierra-ui/components'
import { Button, Heading, IconButton, ScrollView, View } from 'sierra-ui/primitives'

const UUID = (value: string): Value => ({ type: 'value.uuid', value })

type ProgramHeatmapProps = {
  // This is very specific -- we need to know whether a saved program heatmap became
  // un-favorited so we can remove it from the list.
  onClose: (params: { isFavorited: boolean | undefined }) => void
  programId: string
  // Optional values to use before the fetch is complete
  programName?: string
  programHeatmapIsFavorited?: boolean
}

export const ProgramHeatmap: React.FC<ProgramHeatmapProps> = ({
  onClose,
  programId,
  programName,
  programHeatmapIsFavorited,
}) => {
  const { t } = useTranslation()
  const { postWithUserErrorException } = usePost()
  const isReportingEnabled = useIsReportingEnabled()

  const heatmapReportJobState = useHeatmapReportJobState()
  const userFilter = useMemo<HeatmapUserFilter>(
    () => ({
      type: 'program',
      programIds: [programId],
    }),
    [programId]
  )

  const heatmapProgramQuery = useCachedQuery(XRealtimeAdminHeatmapGetHeatmapProgram, { programId })
  // Handle favorite/saved state

  const [heatmapIsFavorited, setHeatmapIsFavoritedLocal] = useState<boolean | undefined>(
    programHeatmapIsFavorited
  )

  useEffect(() => {
    setHeatmapIsFavoritedLocal(heatmapProgramQuery.data?.isSaved)
  }, [heatmapProgramQuery.data])

  const handleClose = (): void => {
    onClose({ isFavorited: heatmapIsFavorited })
  }

  const setHeatmapIsFavorited = useCallback(
    async (programId: string, { value }: { value: boolean }): Promise<void> => {
      if (value) {
        await postWithUserErrorException(XRealtimeAdminHeatmapSaveProgramHeatmap, { programId })
      } else {
        await postWithUserErrorException(XRealtimeAdminHeatmapDeleteProgramHeatmap, { programId })
      }

      setHeatmapIsFavoritedLocal(value)
    },
    [postWithUserErrorException]
  )

  return (
    <Modal open={true} size='full-screen' animation='pop' onClose={handleClose}>
      <ModalHeader padding='medium'>
        <View direction='row' justifyContent='center' alignItems='center'>
          <Heading color='LEGACY_DEFAULT_HEADING_COLOR_REPLACE_ASAP' size='h5' bold avoidHanging={false}>
            {programName ?? heatmapProgramQuery.data?.programName}
          </Heading>
        </View>
        <IconButton iconId='close' variant='transparent' onClick={handleClose} />
      </ModalHeader>
      <ScrollView direction='column' grow marginLeft='medium'>
        {heatmapProgramQuery.data !== undefined && (
          <HeatmapV2
            userFilter={userFilter}
            initialSelectedIds={heatmapProgramQuery.data.content.map(x => x.id)}
            canAddContent={false}
            programId={programId}
          />
        )}
      </ScrollView>
      <View direction='column' background='grey2' padding='medium'>
        <View justifyContent='flex-end'>
          {heatmapIsFavorited !== undefined && (
            <Button
              variant='secondary'
              icon={heatmapIsFavorited ? 'trash-can' : 'trend--up'}
              onClick={async () => {
                await setHeatmapIsFavorited(programId, { value: !heatmapIsFavorited })
                await invalidateListHeatmaps()
              }}
            >
              {heatmapIsFavorited ? t('manage.heatmap.remove-favorite') : t('manage.heatmap.add-favorite')}
            </Button>
          )}
          {isReportingEnabled && (
            <DownloadHeatmapButton
              isLoading={heatmapReportJobState.isLoading}
              downloadWithFormat={outputFormat =>
                heatmapReportJobState.startDownload({
                  query: undefined,
                  contentIds: (heatmapProgramQuery.data?.content ?? []).map(x => x.id),
                  filter: createFilter({ type: 'user.programs' }, Contains, Or([UUID(programId)])),
                  outputFormat,
                })
              }
            />
          )}
        </View>
      </View>
    </Modal>
  )
}
