import _ from 'lodash'
import { useSafeLiveSessionContext } from 'sierra-client/components/liveV2/contexts/live-session-data'
import {
  useEditorJotaiDocument,
  useEditorJotaiIsInCurrentSelection,
  useEditorJotaiIsInLatestSelection,
  useEditorJotaiLatestValidSelection,
  useEditorJotaiPath,
} from 'sierra-client/editor/editor-jotai-context'
import {
  useSafeRouterEditorFlexibleContentId,
  useSafeRouterSelfPacedFlexibleContentId,
} from 'sierra-client/hooks/use-router-ids'
import { elementAtPath, isElementType } from 'sierra-client/views/v3-author/queries'
import { CreateContentId } from 'sierra-domain/api/nano-id'
import { CustomElement, CustomElementRegistry } from 'sierra-domain/v3-author'
import { BaseRange, Descendant, Editor, Element, Node, Path, Text } from 'slate'
import { useSlateStatic } from 'slate-react'

/**
 * Get the path for an element where `element.id === nodeId`
 */
export const usePath = ({ nodeId }: { nodeId: string }): Path => {
  return useEditorJotaiPath(nodeId)
}

/**
 * Get the editor's last selection
 */
export const useLatestSelection = (): BaseRange | null => {
  return useEditorJotaiLatestValidSelection()
}

/**
 * Determines if an element is in the editor's latest selection.
 */
export const useIsInLatestSelection = (nodeId: { nodeId: string }): boolean => {
  return useEditorJotaiIsInLatestSelection(nodeId)
}

export const useParent = ({ nodeId }: { nodeId: string }): CustomElement | Editor | undefined => {
  const document = useEditorJotaiDocument()
  const editor = useSlateStatic()
  const path = usePath({ nodeId })

  const parentPath = _.dropRight(path, 1)
  const parent = parentPath.length === 0 ? editor : elementAtPath(document, parentPath)
  return !Text.isText(parent) ? parent : undefined
}

export const useGrandParent = ({ nodeId }: { nodeId: string }): CustomElement | Editor | undefined => {
  const document = useEditorJotaiDocument()
  const editor = useSlateStatic()
  const path = usePath({ nodeId })

  const parentPath = _.dropRight(path, 2)
  const parent = parentPath.length === 0 ? editor : elementAtPath(document, parentPath)
  return !Text.isText(parent) ? parent : undefined
}

export function useAncestors({ nodeId }: { nodeId: string }): [CustomElement, Path][] {
  const document = useEditorJotaiDocument()
  const path = usePath({ nodeId })

  // Traverse up to the root from the current element, returning all elements along the way
  return path
    .map((ignore, index) => index)
    .reverse()
    .map(length => _.take(path, length))
    .flatMap((path): [CustomElement, Path][] => {
      const element = elementAtPath(document, path)
      if (Element.isElement(element)) return [[element, path]]
      else return []
    })
}

/**
 * Return the first ancestor of an element with the expected type.
 */
export const useAncestorEntryWithType = <Type extends keyof CustomElementRegistry>({
  nodeId,
  type,
}: {
  nodeId: string
  type: Type
}): [CustomElementRegistry[Type], Path] | undefined => {
  const document = useEditorJotaiDocument()
  const path = usePath({ nodeId })

  // From the current path up to the root, return the first element with the requested type
  const entries: [CustomElementRegistry[Type], Path][] = path
    .map((ignore, index) => index)
    .reverse()
    .map(length => _.take(path, length))
    .map((path): [Node | undefined, Path] => [elementAtPath(document, path), path])
    .flatMap(([node, path]): [[CustomElementRegistry[Type], Path]] | [] =>
      isElementType(type, node) ? [[node, path]] : []
    )

  return entries[0]
}

/**
 * Return the first ancestor of an element with the expected type.
 */
export const useAncestorWithType = <Type extends keyof CustomElementRegistry>({
  nodeId,
  type,
}: {
  nodeId: string
  type: Type
}): CustomElementRegistry[Type] | undefined => {
  const [node] = useAncestorEntryWithType({ nodeId, type }) ?? []
  return node
}

export const useSiblings = ({ nodeId }: { nodeId: string }): Descendant[] =>
  useParent({ nodeId })?.children ?? []

export const useChildren = <Type extends keyof CustomElementRegistry>({
  parentId,
  types,
}: {
  parentId: string
  types: Type[]
}): CustomElementRegistry[Type][] | undefined => {
  const document = useEditorJotaiDocument()
  const path = usePath({ nodeId: parentId })

  const parent = elementAtPath(document, path)

  if (parent === undefined) return undefined

  const children: CustomElementRegistry[Type][] = Array.from(Node.descendants(parent))
    .map(nodeEntry => nodeEntry[0])
    .filter(isElementType(types))

  return children
}

/**
 * Determines if an element is the only element currently selected in the editor
 */
export const useIsUniquelySelected = ({ nodeId }: { nodeId: string }): boolean => {
  return useEditorJotaiIsInCurrentSelection({ nodeId, onlyCollapsed: true })
}

export const useSafeFlexibleContentId = (): CreateContentId | undefined => {
  const selfPacedContentId = useSafeRouterSelfPacedFlexibleContentId()
  const liveSession = useSafeLiveSessionContext()
  const editorContentId = useSafeRouterEditorFlexibleContentId()

  const liveSessionContentId = liveSession?.data.flexibleContentId

  return selfPacedContentId ?? liveSessionContentId ?? editorContentId
}
