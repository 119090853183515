import { Atom, atom, useSetAtom } from 'jotai'
import _ from 'lodash/fp'
import { useCallback } from 'react'
import { useSelectAtom } from 'sierra-client/hooks/use-select-atom-value'
import {
  LivePresenceUpdate,
  LivePresenceUpdatedData,
} from 'sierra-client/realtime-data/channels/live-presence-channel/events'
import { LiveSessionPresence } from 'sierra-domain/api/live-session-presence'
import { LiveSessionId, NanoId12 } from 'sierra-domain/api/nano-id'

export const CurrentUserPresenceSessionAtom = atom<NanoId12 | undefined>(undefined)

export const PresenceStateAtom = atom<Record<LiveSessionId, LivePresenceUpdatedData[]>>({})

export type PresenceChangeEvent = {
  id: string
  logTime: string
  source: 'realtime' | 'fetch' | 'realtime-reload'
  updates: LivePresenceUpdate[]
}

export const PresenceStateEventsAtom = atom<PresenceChangeEvent[]>([])

export const useLiveSessionPresenceAtom = (liveSessionId: LiveSessionId): Atom<LiveSessionPresence[]> => {
  return useSelectAtom(
    PresenceStateAtom,
    state => state[liveSessionId]?.map(it => it.presence) ?? [],
    _.isEqual
  )
}

const MAX_EVENT_LOG_SIZE = 500

type AddEventData = Omit<PresenceChangeEvent, 'id' | 'logTime'>
export const useAddStateEvent = (): ((event: AddEventData) => void) => {
  const setEvents = useSetAtom(PresenceStateEventsAtom)

  return useCallback(
    event => {
      setEvents(events => {
        if (events.length >= MAX_EVENT_LOG_SIZE) events.splice(0, events.length - MAX_EVENT_LOG_SIZE)
        return [...events, { ...event, id: _.uniqueId('e_'), logTime: new Date().toISOString() }]
      })
    },
    [setEvents]
  )
}
