import { useMemo } from 'react'
import { createSanaEditor } from 'sierra-client/views/v3-author/configuration/editor-configurations'
import { SelfPacedFile } from 'sierra-domain/flexible-content/support'
import { assertNever } from 'sierra-domain/utils'
import { SanaEditor } from 'sierra-domain/v3-author'

type SelfPacedFileTypeWithEditor =
  | 'poll'
  | 'flip-cards'
  | 'reflections'
  | 'question-card'
  | 'assessment-card'
  | 'slate-card'
  | 'bullet'
  | 'general'
  | 'sliding-scale'
  | 'homework'

type SelfPacedFileTypeWithoutEditor = Exclude<SelfPacedFile['data']['type'], SelfPacedFileTypeWithEditor>

type SelfPacedFileWithEditor = SelfPacedFile & { data: { type: SelfPacedFileTypeWithEditor } }
type SelfPacedFileWithoutEditor = SelfPacedFile & { data: { type: SelfPacedFileTypeWithoutEditor } }

export type SelfPacedCard =
  | {
      withEditor: true
      file: SelfPacedFileWithEditor
      editor: SanaEditor
    }
  | {
      withEditor: false
      file: SelfPacedFileWithoutEditor
    }

function isSelfPacedFileWithEditor(file: SelfPacedFile): file is SelfPacedFileWithEditor {
  switch (file.data.type) {
    case 'poll':
    case 'flip-cards':
    case 'reflections':
    case 'question-card':
    case 'assessment-card':
    case 'slate-card':
    case 'bullet':
    case 'general':
    case 'sliding-scale':
    case 'homework':
      return true
    case 'stupid-questions':
    case 'scenario':
    case 'video':
    case 'image':
    case 'embed':
    case 'drop-a-word':
      return false
    default:
      assertNever(file.data)
  }
}

function isSelfPacedFileWithoutEditor(file: SelfPacedFile): file is SelfPacedFileWithoutEditor {
  return !isSelfPacedFileWithEditor(file)
}

export function useSelfPacedCard({ file }: { file: SelfPacedFile }): SelfPacedCard {
  return useMemo(() => {
    if (isSelfPacedFileWithEditor(file)) {
      const pasteFile = (): void => {}
      const copyPasteAssetOptions = { type: 'disabled' as const }
      const temp: SelfPacedCard = {
        withEditor: true,
        file,
        editor: createSanaEditor({ pasteFile, copyPasteAssetOptions }),
      }
      return temp
    } else if (isSelfPacedFileWithoutEditor(file)) {
      const temp: SelfPacedCard = {
        withEditor: false,
        file,
      }
      return temp
    } else {
      throw new Error(`Unexpected self-paced card type: ${file.data.type}`)
    }
  }, [file])
}
