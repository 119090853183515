import { useAtomValue } from 'jotai'
import { useEffect, useMemo, useState } from 'react'
import { CustomInterfaceThemeProvider } from 'sierra-client/components/common/custom-interface-theme-provider'
import { PartialRecord } from 'sierra-client/components/liveV2/types'
import { useIsMobile } from 'sierra-client/state/browser/selectors'
import { useSelector } from 'sierra-client/state/hooks'
import { selectAllCurrentIssues } from 'sierra-client/state/live/selectors'
import { pictureInPictureEnabledAtom } from 'sierra-client/state/live/settings'
import { FCC } from 'sierra-client/types'
import { AudioSettingsShortcut, AudioSettingsTab } from 'sierra-client/views/liveV2/settings/audio-tab'
import { IssueToTabMap } from 'sierra-client/views/liveV2/settings/issues'
import { LiveTabID } from 'sierra-client/views/liveV2/settings/live-tab-id'
import { LiveSettingsTabConfig, Tabs } from 'sierra-client/views/liveV2/settings/live-tabs'
import { NetworkSettingsTab } from 'sierra-client/views/liveV2/settings/network-tab'
import {
  CameraSettingShortcut,
  VideoSettingShortcut,
  VideoSettingsTab,
} from 'sierra-client/views/liveV2/settings/video-tab'
import { Modal, Panel } from 'sierra-ui/components'
import { IconButton, View } from 'sierra-ui/primitives'
import { legacyLight } from 'sierra-ui/theming/legacy-theme'
import { maxWidth } from 'sierra-ui/utils/media-query-styles'
import styled, { ThemeProvider } from 'styled-components'

const Wrapper = styled(View).attrs({ gap: 'small', alignItems: 'flex-start' })`
  height: 33rem;
  min-width: 42rem;

  ${maxWidth.phone} {
    min-width: auto;
  }
`

const preLobbyTabs: PartialRecord<LiveTabID, LiveSettingsTabConfig> = {
  video: {
    id: 'video',
    label: 'dictionary.video',
    component: VideoSettingsTab,
  },
  audio: {
    id: 'audio',
    label: 'dictionary.audio',
    component: AudioSettingsTab,
  },
}

const liveTabs: PartialRecord<LiveTabID, LiveSettingsTabConfig> = {
  ...preLobbyTabs,
  network: {
    id: 'network',
    label: 'dictionary.network',
    component: NetworkSettingsTab,
  },
}

type ResponsiveModalProps = {
  open: boolean
  onClose: () => void
}

const ResponsiveModal: FCC<ResponsiveModalProps> = ({ children, open, onClose }) => {
  const isMobile = useIsMobile()
  if (isMobile) {
    return (
      <Panel open={open} padding='medium' onClose={onClose}>
        {open && children}
      </Panel>
    )
  }
  return (
    <Modal open={open} onClose={onClose} animation='pop' size='fit-content' padding='medium'>
      {open && children}
    </Modal>
  )
}

const CloseContainer = styled(View)`
  position: absolute;
  right: 2rem;
  top: 2rem;
`

export const SettingsModal = (props: {
  open: boolean
  onClose: () => void
  renderedIn: 'pre-lobby' | 'live'
}): JSX.Element => {
  // since Jotai doesn't initialize until the atom is read, we need to read it here to to ensure the value
  // is available directly when the modal opens. Otherwise the button toggles can flash the wrong state
  useAtomValue(pictureInPictureEnabledAtom)

  const [view, setView] = useState<LiveTabID | undefined>()
  const [defaultView, setDefaultView] = useState<LiveTabID>('video')
  const issues = useSelector(selectAllCurrentIssues)
  const tabs = props.renderedIn === 'pre-lobby' ? preLobbyTabs : liveTabs
  const availableTabs = useMemo(() => Object.keys(tabs), [tabs])

  useEffect(() => {
    const issueToDisplay = issues.find(issue => availableTabs.includes(IssueToTabMap[issue.category]))
    if (issueToDisplay) {
      setDefaultView(IssueToTabMap[issueToDisplay.category])
    } else {
      setDefaultView('video')
    }
  }, [issues, availableTabs])

  const handleOnClose = (): void => {
    props.onClose()
    setView(undefined)
  }

  return (
    <>
      <ResponsiveModal open={props.open} onClose={handleOnClose}>
        <ThemeProvider theme={legacyLight}>
          <CustomInterfaceThemeProvider>
            <CloseContainer justifyContent='flex-end'>
              <IconButton iconId='close' variant='transparent' onClick={handleOnClose} />
            </CloseContainer>
            <Wrapper>
              <Tabs onClick={(tab: LiveTabID) => setView(tab)} current={view ?? defaultView} tabs={tabs} />
            </Wrapper>
          </CustomInterfaceThemeProvider>
        </ThemeProvider>
      </ResponsiveModal>

      <VideoSettingShortcut label={{ type: 'untranslated', value: 'Video Quality' }} />
      <CameraSettingShortcut label={{ type: 'untranslated', value: 'Select Camera' }} />
      <AudioSettingsShortcut label={{ type: 'untranslated', value: 'Select Microphone' }} />
    </>
  )
}
