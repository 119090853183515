import { BroadcastChannel } from 'broadcast-channel'
import { Auth } from 'sierra-client/core/auth'
import { postWithUserErrorException } from 'sierra-client/state/api'
import { RootStateThunkDispatch } from 'sierra-client/state/types'
import { statusChanged } from 'sierra-client/state/user/actions'
import { UserAuthStatus } from 'sierra-client/state/user/user-types'
import { XRealtimeAuthCheckSession, XRealtimeAuthExpireSession, XRealtimeUserMe } from 'sierra-domain/routes'

export class CookieAuth extends Auth {
  protected channel: BroadcastChannel<UserAuthStatus>

  constructor(public dispatch: RootStateThunkDispatch) {
    super()
    this.channel = new BroadcastChannel('sierra-user')
  }

  override initialize(): void {
    this.channel.onmessage = async (status: UserAuthStatus) => {
      await this.dispatch(statusChanged(status))
    }
  }

  // NOTE: `postWithUserErrorException` can call `synchronize`. Make sure to exclude any endpoints called
  // here from the condition in that function to avoid infinite recursion.
  override async synchronize(): Promise<void> {
    const result = await postWithUserErrorException(XRealtimeAuthCheckSession, {}, this.dispatch)

    if (result.status === 'ok') {
      const user = await postWithUserErrorException(XRealtimeUserMe, {}, this.dispatch)
      const status: UserAuthStatus = { type: 'user', user: user }
      await this.dispatch(statusChanged(status))
      await this.channel.postMessage(status)
    } else if (result.status === 'verify-code') {
      const status: UserAuthStatus = { type: 'code-verification' }
      await this.dispatch(statusChanged(status))
      await this.channel.postMessage(status)
    } else {
      const status: UserAuthStatus = { type: 'loading', loaded: true }
      await this.dispatch(statusChanged(status))
      await this.channel.postMessage(status)
    }
  }
}

export class NativeAuth extends CookieAuth {
  constructor(dispatch: RootStateThunkDispatch) {
    super(dispatch)
  }

  override async signOut(): Promise<void> {
    await postWithUserErrorException(XRealtimeAuthExpireSession, {}, this.dispatch)
    await this.synchronize()
  }
}
