import { JSONContent, Mark, mergeAttributes } from '@tiptap/core'

const EDITED_MESSAGE_MARK_CSS_CLASS = 'edited-message-mark'

interface EditedMessageMarkOptions {
  HTMLAttributes: Record<string, any>
}

export const EditedMessageMark = Mark.create<EditedMessageMarkOptions>({
  name: 'EditedMessageMark',

  addOptions() {
    return {
      HTMLAttributes: {
        class: EDITED_MESSAGE_MARK_CSS_CLASS,
      },
    }
  },

  parseHTML() {
    return [
      {
        tag: 'span',
        getAttrs: e => {
          const hasClass = e.classList.contains(EDITED_MESSAGE_MARK_CSS_CLASS)

          if (!hasClass) return false

          return {}
        },
      },
    ]
  },

  renderHTML({ HTMLAttributes }) {
    return ['span', mergeAttributes(this.options.HTMLAttributes, HTMLAttributes), 0]
  },
})

export const createEditedMessageMarkJsonContent = (text: string): JSONContent => {
  return {
    type: 'text',
    marks: [
      {
        type: 'EditedMessageMark',
      },
    ],
    text,
  }
}
