import { PageTitle } from 'sierra-client/components/common/page-title'
import { ManageApiClient } from 'sierra-client/views/manage/api/manage-client-api'
import { ManageXApi } from 'sierra-client/views/manage/api/manage-x-api'
import { ManageContainer } from 'sierra-client/views/manage/components/common'
import { useXApiPageEnabled } from 'sierra-client/views/manage/permissions/use-xapi-page-enabled'
import { PageHeader } from 'sierra-client/views/settings/components/page-header'
import { Spacer } from 'sierra-ui/primitives'
import styled from 'styled-components'

const APIContainer = styled(ManageContainer)`
  padding-bottom: 3rem;
  max-width: 1100px;
`

export const ManageApi = (): JSX.Element | null => {
  const showXApi = useXApiPageEnabled()

  return (
    <>
      <PageTitle title='API' />

      <APIContainer>
        <PageHeader title='manage.api.api-settings' />

        <ManageApiClient />
        {showXApi === true && (
          <>
            <Spacer size='large' />
            <ManageXApi />
          </>
        )}
      </APIContainer>
    </>
  )
}
