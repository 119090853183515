import { useCallback, useEffect, useMemo } from 'react'
import { useLiveSessionStateMutation } from 'sierra-client/api/hooks/use-live-session'
import { useConfirmationModalContext } from 'sierra-client/components/common/modals/confirmation-modal'
import { useLiveSessionContext } from 'sierra-client/components/liveV2/contexts/live-session-data'
import { Logging } from 'sierra-client/core/logging'
import { useTranslation } from 'sierra-client/hooks/use-translation'
import { useDispatch, useSelector } from 'sierra-client/state/hooks'
import { liveSessionStateChanged } from 'sierra-client/state/live-session/actions'
import { selectCallIsActive } from 'sierra-client/state/live/selectors'

export const useEndSessionModal = (): { show: () => void } => {
  const { show: showConfirmationModal, close: closeConfirmationModal } = useConfirmationModalContext()
  const liveSession = useLiveSessionContext()
  const mutation = useLiveSessionStateMutation()
  const dispatch = useDispatch()
  const { t } = useTranslation()
  const joined = useSelector(selectCallIsActive)

  useEffect(() => {
    if (!joined) {
      closeConfirmationModal()
    }
  }, [closeConfirmationModal, joined])

  const handleEndSession = useCallback(() => {
    mutation.mutate(
      {
        liveSessionId: liveSession.liveSessionId,
        state: 'ended',
      },
      {
        onSuccess: async () => {
          dispatch(liveSessionStateChanged('ended'))
        },
      }
    )

    void dispatch(Logging.liveSession.leaveButtonEndSessionConfirmed())
  }, [dispatch, liveSession.liveSessionId, mutation])

  const handleAbort = useCallback(() => {
    void dispatch(Logging.liveSession.leaveButtonCanceled())
  }, [dispatch])

  const show = useCallback(() => {
    showConfirmationModal({
      bodyText: t('live.leave-copy.end-session-explanation'),
      confirmLabel: t('live.leave-copy.end-the-session'),
      onConfirm: handleEndSession,
      deleteAction: true,
      title: t('live.leave-copy.end-the-session-for-everyone'),
      onAborted: handleAbort,
    })
  }, [showConfirmationModal, t, handleAbort, handleEndSession])

  return useMemo(() => ({ show }), [show])
}
