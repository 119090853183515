import { FC } from 'react'
import { Logging } from 'sierra-client/core/logging'
import { useDispatch, useSelector } from 'sierra-client/state/hooks'
import { selectBackgroundBlurStatus, selectNoiseCancellationStatus } from 'sierra-client/state/live/selectors'
import { useOnChanged } from 'sierra-ui/utils'

export const LogTrackingEvents: FC = () => {
  const dispatch = useDispatch()
  const backgroundBlurEnabled = useSelector(selectBackgroundBlurStatus)
  const noiseCancellationEnabled = useSelector(selectNoiseCancellationStatus)

  useOnChanged(() => {
    if (backgroundBlurEnabled) void dispatch(Logging.liveSession.backgroundBlurEnabled())
  }, backgroundBlurEnabled)

  useOnChanged(() => {
    if (noiseCancellationEnabled) void dispatch(Logging.liveSession.noiseCancellationEnabled())
  }, noiseCancellationEnabled)

  return null
}
