import { ReactNode } from 'react'
import { PreambleContent } from 'sierra-client/views/v3-author/preamble/preamble'
import { Preamble } from 'sierra-domain/v3-author'

export const PreamblePreview: React.FC<{ preamble: Preamble; children: ReactNode }> = ({
  preamble,
  children,
}) => {
  const aligned = preamble.aligned ?? 'left'
  const color = preamble.color ?? 'default'

  return (
    <PreambleContent $alignment={aligned} color='LEGACY_DEFAULT_HEADING_COLOR_REPLACE_ASAP' $color={color}>
      {children}
    </PreambleContent>
  )
}
