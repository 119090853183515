import React from 'react'
import {
  DrillDownMenuItem,
  useDrillDownMenuItems,
} from 'sierra-client/features/insights/hooks/use-drill-down-menu-items'
import { buildWidgetBuilderStateFromWidget } from 'sierra-client/features/insights/widget-builder/widget-builder-state'
import { DimensionRef, MeasureRef, Widget } from 'sierra-domain/api/insights'
import { View } from 'sierra-ui/primitives'
import { MenuDropdownPrimitive } from 'sierra-ui/primitives/menu-dropdown'
import styled from 'styled-components'

const MenuAnchor = styled(View)<{ $x?: number; $y?: number }>`
  position: fixed;
  top: ${p => `${p.$y ?? 0}px`};
  left: ${p => `${p.$x ?? 0}px`};
`

type DrillDownMenuProps = {
  isOpen: boolean
  onOpenChange: (newOpen: boolean) => void
  selectedMeasures: MeasureRef[]
  selectedDimension: DimensionRef
  position?: { x: number; y: number }
  onDrillDown: (dimensionRef: DimensionRef) => void
  onFilterOut: () => void
  widget: Widget
  disabledByOrFilter: boolean
}

export const DrillDownMenu: React.FC<DrillDownMenuProps> = ({
  isOpen,
  onOpenChange,
  position,
  onDrillDown,
  onFilterOut,
  selectedMeasures,
  selectedDimension,
  widget,
  disabledByOrFilter,
}) => {
  const visualisation = buildWidgetBuilderStateFromWidget(widget).visualisation
  const menuItems = useDrillDownMenuItems({
    selectedMeasures,
    selectedDimension,
    visualisation,
    disabledByOrFilter,
  })

  const handleSelect = (item: DrillDownMenuItem): void => {
    if (item.id === 'filter-out') {
      onFilterOut()
    } else if (item.ref !== undefined) {
      onDrillDown(item.ref)
    } else {
      throw new Error('Undefined ref for drill down item')
    }
  }

  return (
    <MenuDropdownPrimitive
      isOpen={isOpen}
      onOpenChange={onOpenChange}
      menuItems={menuItems}
      closeOnSelect={true}
      onSelect={handleSelect}
      renderTrigger={() => <MenuAnchor $x={position?.x} $y={position?.y}></MenuAnchor>}
    />
  )
}
