import { useMemo } from 'react'
import { Logging } from 'sierra-client/core/logging'
import { useDispatch } from 'sierra-client/state/hooks'

type HomeworkTracking = {
  submission: {
    submit: (args: { courseId: string; fileId: string }) => void
    submitReview: (args: { homeworkId: string; submissionId: string }) => void
  }
}
export const useTracking = (): HomeworkTracking => {
  const dispatch = useDispatch()

  return useMemo(
    () => ({
      submission: {
        submit: args => dispatch(Logging.exercise.submitSubmission(args)),
        submitReview: args => dispatch(Logging.exercise.submitSubmissionReview(args)),
      },
    }),
    [dispatch]
  )
}
