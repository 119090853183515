import React from 'react'
import { AddableDragList } from 'sierra-client/features/insights/widget-builder/data-selectors/addable-drag-list'
import { SingleDimensionSelector } from 'sierra-client/features/insights/widget-builder/data-selectors/single-dimension-selector'
import { WidgetBuilderState } from 'sierra-client/features/insights/widget-builder/widget-builder-state'
import { useTranslation } from 'sierra-client/hooks/use-translation'
import { DimensionRef, MeasureRef, ViewRef, areDimensionRefsEqual } from 'sierra-domain/api/insights'
import { Button, View } from 'sierra-ui/primitives'

export const MultiDimensionSelector: React.FC<{
  onChange: (newDimensions: DimensionRef[]) => void
  selectedDimensions: DimensionRef[]
  disabled?: boolean
  selectedMeasures: MeasureRef[]
  selectedView?: ViewRef
  visualisation: WidgetBuilderState['visualisation']
}> = ({ onChange, selectedDimensions, selectedMeasures, selectedView, visualisation }) => {
  const { t } = useTranslation()

  return (
    <>
      {selectedDimensions.length === 0 ? (
        <View grow padding='4 none'>
          <SingleDimensionSelector
            onChange={newDimension => {
              onChange([newDimension])
            }}
            selectedDimension={undefined}
            selectedMeasures={selectedMeasures}
            selectedView={selectedView}
            visualisation={visualisation}
            dimensionSelectorType='index'
            disabledDimensions={selectedDimensions}
          />
        </View>
      ) : (
        <View grow>
          <AddableDragList
            values={selectedDimensions}
            onChange={onChange}
            equalFunction={areDimensionRefsEqual}
            addComponent={
              <SingleDimensionSelector
                onChange={newDimension => {
                  onChange([...selectedDimensions, newDimension])
                }}
                selectedDimension={undefined}
                selectedMeasures={selectedMeasures}
                selectedView={selectedView}
                visualisation={visualisation}
                dimensionSelectorType={selectedDimensions.length === 0 ? 'index' : 'breakdown'}
                disabledDimensions={selectedDimensions}
                renderTrigger={() => (
                  <Button variant='secondary' icon='add' grow>
                    {t('dictionary.add')}
                  </Button>
                )}
              />
            }
            renderItem={(dimension, index) => {
              const deleteDisabled =
                visualisation.type === 'table' && visualisation.table.tableType === 'list' && index === 0

              return (
                <SingleDimensionSelector
                  onChange={newDimension => {
                    const newDimensions = [...selectedDimensions]
                    newDimensions[index] = newDimension
                    onChange(newDimensions)
                  }}
                  selectedDimension={dimension}
                  selectedMeasures={selectedMeasures}
                  selectedView={selectedView}
                  visualisation={visualisation}
                  dimensionSelectorType={index === 0 ? 'index' : 'breakdown'}
                  disabledDimensions={selectedDimensions}
                  onDelete={
                    deleteDisabled
                      ? undefined
                      : () =>
                          onChange([
                            ...selectedDimensions.slice(0, index),
                            ...selectedDimensions.slice(index + 1, selectedDimensions.length),
                          ])
                  }
                />
              )
            }}
          />
        </View>
      )}
    </>
  )
}
