import { RouterLink } from 'sierra-client/components/common/link'
import { getCreateEntityHref } from 'sierra-client/components/recommendations/utils'
import { useTranslation } from 'sierra-client/hooks/use-translation'
import { CreateEntity } from 'sierra-domain/api/entities'
import { Button } from 'sierra-ui/primitives'

export const CreateEntityActionButton: React.FC<{
  entity: CreateEntity
  onClick?: () => void
}> = ({ entity, onClick }) => {
  const { t } = useTranslation()

  const href = getCreateEntityHref(entity)

  return (
    <RouterLink href={href}>
      <Button variant='secondary' onClick={onClick}>
        {t('content.edit')}
      </Button>
    </RouterLink>
  )
}
