import { z } from 'zod'

const IssuedCertificateRep = z.object({
  id: z.string(),
  issuedToUserId: z.string(),
  issuedToUserEmail: z.string(),
  issuedToUserStatus: z.enum(['ACTIVE', 'PENDING', 'DEACTIVATED']),
  issuedToUserDisplayName: z.string(),
  issuedToUserAvatar: z.optional(z.string()),
  supportingNote: z.string().optional(),
  hasSupportingFile: z.boolean(),
  expiresAt: z.optional(z.date()),
  revokedAt: z.optional(z.date()),
})

export type IssuedCertificateRep = z.infer<typeof IssuedCertificateRep>

export function isIssuedCertificateRep(value: unknown): value is IssuedCertificateRep {
  return IssuedCertificateRep.safeParse(value).success
}
