import {
  TypedUseSelectorHook,
  // eslint-disable-next-line no-restricted-imports
  useDispatch as useDispatchPlain,
  // eslint-disable-next-line no-restricted-imports
  useSelector as useSelectorPlain,
  // eslint-disable-next-line no-restricted-imports
  useStore as useStorePlain,
} from 'react-redux'
import { AppDispatch, AppStore } from 'sierra-client/state/store'
import { RootState } from 'sierra-client/state/types'

export const useStore: () => AppStore = useStorePlain
export const useDispatch: () => AppDispatch = useDispatchPlain
export const useSelector: TypedUseSelectorHook<RootState> = useSelectorPlain
