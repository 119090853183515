import _ from 'lodash'
import { getParent, isElementType, parentType } from 'sierra-client/views/v3-author/queries'
import { Entity } from 'sierra-domain/entity'
import { nanoid12 } from 'sierra-domain/nanoid-extensions'
import { hasOnlyEmptyTextInNodes } from 'sierra-domain/slate-util'
import { BlockQuoteSubtitle } from 'sierra-domain/v3-author'
import { createBlockQuoteSubtitle, createParagraph } from 'sierra-domain/v3-author/create-blocks'
import { Editor, Element, Node, NodeEntry, Path, Point, Range, Text, Transforms } from 'slate'

const debug = (...messages: unknown[]): void => console.debug('[withBlockQuote]', ...messages)

const isSubtitle = isElementType('block-quote-subtitle')

const isValidBlockQuoteChild = isElementType([
  'paragraph',
  'bulleted-list',
  'numbered-list',
  'block-quote-subtitle',
])

export const withBlockQuote = (editor: Editor): Editor => {
  const { deleteBackward, normalizeNode, insertBreak } = editor

  editor.insertBreak = () => {
    const { selection } = editor

    if (selection && Range.isCollapsed(selection)) {
      const [subtitleMatch] = Editor.nodes(editor, {
        match: isElementType('block-quote-subtitle'),
      })

      const [quoteMatch] = Editor.nodes(editor, {
        match: isElementType('block-quote'),
      })
      if (subtitleMatch === undefined && quoteMatch !== undefined) {
        const [, quotePath] = quoteMatch
        const lastParagraphChild = _.last(Array.from(Node.children(editor, quotePath)))

        if (lastParagraphChild !== undefined) {
          const isQuoteEmpty = hasOnlyEmptyTextInNodes([lastParagraphChild[0]])
          const isSelectionAtLast = _.isEqual(_.dropRight(selection.anchor.path, 1), lastParagraphChild[1])

          if (isQuoteEmpty && isSelectionAtLast) {
            return Transforms.insertNodes(editor, createParagraph(), {
              at: Path.next(quotePath),
              select: true,
            })
          }
        }
      } else if (subtitleMatch !== undefined && quoteMatch !== undefined) {
        const quote = quoteMatch[0]
        if (quote.children[quote.children.length - 1]?.type === 'block-quote-subtitle') {
          const [, quotePath] = quoteMatch

          Transforms.insertNodes(
            editor,
            { type: 'paragraph', id: nanoid12(), children: [{ text: '' }] },
            { at: Path.next(quotePath), select: true }
          )
        } else {
          const [, subtitlePath] = subtitleMatch
          const { path: afterPath } = Editor.after(editor, selection.anchor.path) || {}
          if (afterPath !== undefined) {
            const [afterNode] = Editor.node(editor, afterPath)

            if (hasOnlyEmptyTextInNodes([afterNode])) {
              Transforms.move(editor, { distance: 1, reverse: false, unit: 'line' })
              return
            }
          }
          Transforms.insertNodes(
            editor,
            { type: 'paragraph', id: nanoid12(), children: [{ text: '' }] },
            { at: Path.next(subtitlePath), select: true }
          )
        }

        return
      }
    }

    insertBreak()
  }

  editor.normalizeNode = entry => {
    const [node, path] = entry

    if (isElementType('block-quote-subtitle', node)) {
      if (parentType(editor, path) !== 'block-quote') {
        debug(`Unwrapping block quote subtitle at ${JSON.stringify(path)}`)

        // Unwrap the block quote subtitle
        return Transforms.setNodes(editor, { type: 'paragraph' }, { at: path })
      }

      for (const [child, childPath] of Node.children(editor, path)) {
        if (!Text.isText(child)) {
          debug(`Unwrapping to text node at`, childPath)
          return Transforms.unwrapNodes(editor, { at: childPath, voids: true })
        }
      }
    }

    if (!isElementType('block-quote', node)) {
      return normalizeNode(entry)
    }

    const children = Array.from(Node.children(editor, path))
    const paragraph = children.find(([n]) => isElementType('paragraph', n))
    if (paragraph === undefined) {
      const targetPath = path.concat(0)
      debug(`Inserting missing paragraph at ${JSON.stringify(targetPath)}`)
      return editor.insertNodes(createParagraph(), { at: targetPath })
    }

    const subtitles: NodeEntry<Entity<BlockQuoteSubtitle>>[] = children.flatMap(([n, p]) =>
      isSubtitle(n) ? [[n, p]] : []
    )

    const expectedSubtitleIndex = 0

    if (subtitles.length > 1) {
      debug(`Merging duplicate subtitles at ${JSON.stringify(path)}`)
      return Transforms.mergeNodes(editor, {
        match: (n, p) => isSubtitle(n) && path.every((value, index) => p[index] === value),
      })
    } else if (subtitles.length === 0) {
      debug(`Adding missing subtitle at ${JSON.stringify(path.concat(expectedSubtitleIndex))}`)
      return editor.insertNodes(createBlockQuoteSubtitle(), {
        at: path.concat(expectedSubtitleIndex),
      })
    }

    const [subtitle] = subtitles

    if (subtitle !== undefined) {
      const [, subtitlePath] = subtitle
      const subtitleIndex = _.last(subtitlePath) ?? 0
      if (subtitleIndex !== expectedSubtitleIndex) {
        debug(`Moving subtitle from ${subtitleIndex} to ${expectedSubtitleIndex}`)
        return Transforms.moveNodes(editor, {
          at: path.concat(subtitleIndex),
          to: path.concat(expectedSubtitleIndex),
        })
      }
    }

    for (const [child, childPath] of children) {
      if (Element.isElement(child) && !isValidBlockQuoteChild(child)) {
        debug(`Setting node to paragraph at: ${JSON.stringify(childPath)}`)
        Transforms.setNodes(editor, { type: 'paragraph' }, { at: childPath })
        return
      }
    }

    normalizeNode(entry)
  }

  editor.deleteBackward = entry => {
    const { selection } = editor
    if (selection && Range.isCollapsed(selection)) {
      const [, path] = Editor.node(editor, selection.anchor.path)
      const [paragraph, paragraphPath] = Editor.node(editor, Path.parent(path))
      const { path: previousPath } = Editor.before(editor, selection.anchor.path) ?? {}

      if (previousPath !== undefined) {
        const previousNode = Editor.node(editor, previousPath)
        const [previousParentNode] = getParent(editor, previousNode)

        if (
          isElementType('paragraph', paragraph) &&
          isElementType('block-quote-subtitle', previousParentNode)
        ) {
          const start = editor.start(paragraphPath)

          if (Point.equals(selection.anchor, start)) {
            if (hasOnlyEmptyTextInNodes([paragraph])) {
              editor.removeNodes({ at: paragraphPath })
              return
            } else {
              editor.move({ distance: 1, reverse: true, unit: 'character' })
              return
            }
          }
        }
      }

      const [parent, parentPath] = Editor.node(editor, _.dropRight(path, 2))

      if (isElementType('block-quote', parent)) {
        const [match] = editor.nodes({
          match: isValidBlockQuoteChild,
        })

        if (match !== undefined) {
          // const [, path] = match
          const start = editor.start(parentPath.concat(0))

          if (Point.equals(selection.anchor, start)) {
            debug(`deleteBackward at start of node, unwrapping at ${JSON.stringify(parentPath)}`)

            editor.withoutNormalizing(() => {
              for (const [childNode, childPath] of Node.children(editor, parentPath)) {
                if (isElementType('block-quote-subtitle', childNode)) {
                  editor.wrapNodes(createParagraph(), { at: childPath })
                } else {
                  // do nothing -- should already be a paragraph
                }
              }
              editor.unwrapNodes({ at: parentPath })
            })

            return
          }
        }
      }
    }

    deleteBackward(entry)
  }

  return editor
}
