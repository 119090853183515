import React from 'react'
import { assertIsNonNullable } from 'sierra-domain/utils'
import { Element } from 'slate'

const ReactElementContext = React.createContext<Element | undefined>(undefined)

export const ElementContextProvider = ReactElementContext.Provider

export function useElement(): Element {
  const element = React.useContext(ReactElementContext)
  assertIsNonNullable(element, 'Must be wrapped in an <ElementContextProvider>')
  return element
}
