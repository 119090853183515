import { spacing, token } from 'sierra-ui/theming'
import { v2_breakpoint } from 'sierra-ui/theming/breakpoints'
import styled from 'styled-components'

const Breadcrumb = styled.span<{ disabled: boolean }>`
  flex: 0 1 23px;
  min-width: 2px;
  height: 2px;
  border-radius: 2px;

  background-color: ${token('foreground/primary')};
  ${p => p.disabled && `opacity: 0.1;`};
`

const BreadcrumbWrapper = styled.div<{ length: number }>`
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;

  position: absolute;
  inset: 50% auto auto 50%;
  transform: translate(-50%, -50%);
  @media screen and (max-width: ${v2_breakpoint.phone}) {
    transform: translate(-50%, -800%);
  }

  width: 40%;
  max-width: 40%;
  gap: ${p => (p.length > 10 ? spacing['4'] : spacing['6'])};
`

export const Breadcrumbs: React.FC<{ length: number; current: number }> = ({ length = 0, current = 0 }) => (
  <BreadcrumbWrapper length={length}>
    {Array.from(Array(length)).map((_, i) => (
      <Breadcrumb key={i} disabled={current <= i} />
    ))}
  </BreadcrumbWrapper>
)
