import { DateTime } from 'luxon'
import React, { FC } from 'react'
import { toLocalTimeFormat } from 'sierra-client/core/format'
import { useTranslation } from 'sierra-client/hooks/use-translation'
import { TranslationLookup } from 'sierra-client/hooks/use-translation/types'
import { FCC } from 'sierra-client/types'
import { StepLiveSession, StepUpcomingLiveSession } from 'sierra-client/views/learner/program/types'
import {
  EnrollmentStepType,
  isEmailEnrollmentStep,
} from 'sierra-client/views/learner/program/utils/step-graphql'
import {
  Utils,
  getExercisesFromStep,
  getHomeworkStates,
  getLiveSessionForStep,
  getStepProgress,
  getStepTimeLeft,
  isStepCompleted,
  useFormatDuration,
} from 'sierra-client/views/learner/program/utils/utils'
import { formatGqlEventSchedule } from 'sierra-client/views/manage/event-groups/event-utils'
import { getPrioritizedHomework } from 'sierra-domain/homework/utils'
import { isNonEmptyArray, isNonEmptyString } from 'sierra-domain/utils'
import { Icon } from 'sierra-ui/components'
import { Text, View } from 'sierra-ui/primitives'
import { narrowDotSeparator } from 'sierra-ui/utils'
import styled, { CSSProperties } from 'styled-components'

type ContentData = Extract<EnrollmentStepType, { __typename: 'UserProgramCourseStep' }>['content']
type EventGroupData = Extract<ContentData, { __typename: 'NativeEventGroup' }>

const InfoText = styled(Text).attrs<{ color?: string }>(({ color = 'foreground/muted' }) => ({
  size: 'small',
  bold: true,
  color,
}))<{ $whiteSpace?: CSSProperties['whiteSpace']; $color?: string }>`
  ${({ $whiteSpace }) => $whiteSpace && `white-space: ${$whiteSpace};`}
`
const CollapseButtonContainer = styled(View)``

const CollapseButtonText = styled(InfoText)`
  word-break: keep-all;
  ${CollapseButtonContainer}:hover & {
    text-decoration: underline;
  }
`

const InfoContainer = styled.div`
  grid-area: details;
  display: flex;
  flex-direction: column;
`

const DotDelimiterComponent: FCC = ({ children }) => {
  const childrenWithDelimiters = React.Children.toArray(children).map((child, index, array) => {
    const isLastChild = index === array.length - 1
    return (
      <React.Fragment key={index}>
        {child}
        {!isLastChild && <InfoText>{narrowDotSeparator}</InfoText>}
      </React.Fragment>
    )
  })

  return <>{childrenWithDelimiters}</>
}

const formatLiveSessionTime = (liveSession: StepLiveSession): string =>
  `${DateTime.fromISO(liveSession.startTime).toFormat('MMM d')}, ${toLocalTimeFormat(
    DateTime.fromISO(liveSession.startTime)
  )}–${toLocalTimeFormat(DateTime.fromISO(liveSession.endTime))}`

const getCalendarEventLabelForStep = (eventGroup: EventGroupData, t: TranslationLookup): string => {
  if (eventGroup.assignedCalendarEvents.length === 0) {
    if (eventGroup.upcomingSelfEnrollmentCalendarEvents.length > 0) {
      return t('home.assigned-content.choose-session')
    }

    return t('events-groups.no-sessions')
  }

  if (eventGroup.assignedCalendarEvents.length === 1) {
    const event = eventGroup.assignedCalendarEvents[0]
    if (event === undefined) {
      return ''
    }
    return formatGqlEventSchedule(event.schedule)
  }

  return t('events-groups.multiple-sessions')
}

const ToggleCollapseButton: FC<{ onClick: () => void; isCollapsed: boolean }> = ({
  onClick,
  isCollapsed,
}) => {
  const { t } = useTranslation()
  return (
    <CollapseButtonContainer
      direction='row'
      gap='6'
      onClick={e => {
        e.preventDefault()
        e.stopPropagation()
        onClick()
      }}
    >
      <CollapseButtonText color='foreground/muted'>
        {t(isCollapsed ? 'dictionary.expand' : 'dictionary.minimize')}
      </CollapseButtonText>
      <Icon iconId={isCollapsed ? 'chevron--down--small' : 'chevron--up--small'} color={'foreground/muted'} />
    </CollapseButtonContainer>
  )
}

export const StepMetaData: React.FC<{
  step: EnrollmentStepType
  togglePathSubContent: () => void
  upcomingSelfEnrollmentLiveSessions?: StepUpcomingLiveSession[]
  collapseButton: boolean
}> = ({ step, togglePathSubContent, upcomingSelfEnrollmentLiveSessions, collapseButton }) => {
  const { t } = useTranslation()

  const stepProgress = getStepProgress(step)
  const hasStarted = stepProgress > 0

  const showTimeLeft =
    step.__typename !== 'UserProgramEmailStep' &&
    step.content.__typename !== 'NativeLiveCourse' &&
    step.content.__typename !== 'NativeEventGroup'
  const formattedTimeLeft = useFormatDuration(getStepTimeLeft(step), hasStarted)

  const completed = isStepCompleted(step)

  const scheduledLiveSessions =
    step.__typename === 'UserProgramCourseStep'
      ? step.assignedLiveSessions
          ?.map(s => s.liveSession)
          .filter(s => s.__typename === 'ScheduledLiveSession')
      : undefined

  const liveSession =
    step.__typename === 'UserProgramCourseStep'
      ? getLiveSessionForStep(upcomingSelfEnrollmentLiveSessions ?? [], scheduledLiveSessions ?? [], true)
      : undefined

  if (isEmailEnrollmentStep(step)) {
    return null
  }

  const exercises = getExercisesFromStep(step)
  if (isNonEmptyArray(exercises)) {
    const homeworkStates = getHomeworkStates(exercises)
    const selectedHomework = getPrioritizedHomework(homeworkStates)

    if (
      selectedHomework?.grade === 'failed' ||
      selectedHomework?.grade === 'failed-with-no-retries' ||
      selectedHomework?.grade === 'not-graded'
    ) {
      return (
        <InfoContainer>
          <InfoText>
            {selectedHomework.grade === 'not-graded'
              ? t('homework.awaiting-review')
              : t('homework.not-passed')}
          </InfoText>
        </InfoContainer>
      )
    }
  }

  return (
    <InfoContainer>
      <View direction='row' gap='6' wrap='nowrap' alignItems='center'>
        <DotDelimiterComponent>
          {completed && <InfoText $whiteSpace='nowrap'>{t('dictionary.completed')}</InfoText>}
          {!completed && showTimeLeft && isNonEmptyString(formattedTimeLeft) && (
            <InfoText $whiteSpace='nowrap'>{formattedTimeLeft}</InfoText>
          )}

          {step.dueDate !== undefined && step.dueDate !== null && !completed && (
            <Utils date={step.dueDate.date} color='foreground/muted' />
          )}

          {step.content.__typename === 'NativeLiveCourse' && (
            <InfoText>
              {liveSession !== undefined
                ? formatLiveSessionTime(liveSession)
                : t('program-overview.live-course.not-scheduled')}
            </InfoText>
          )}

          {step.__typename === 'UserProgramCourseStep' && step.content.__typename === 'NativeEventGroup' && (
            <InfoText>{getCalendarEventLabelForStep(step.content, t)}</InfoText>
          )}

          {step.__typename === 'UserProgramPathStep' && step.subSteps.length > 0 && (
            <ToggleCollapseButton isCollapsed={collapseButton} onClick={togglePathSubContent} />
          )}
        </DotDelimiterComponent>
      </View>
    </InfoContainer>
  )
}

export const StepMetaDataBasic: React.FC<{
  duration: string
}> = ({ duration }) => {
  const formattedTimeLeft = useFormatDuration(duration, false)
  return (
    <InfoContainer>
      <View direction='row' gap='6' wrap='nowrap' alignItems='center'>
        <DotDelimiterComponent>
          <InfoText $whiteSpace='nowrap'>{formattedTimeLeft}</InfoText>
        </DotDelimiterComponent>
      </View>
    </InfoContainer>
  )
}
