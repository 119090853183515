import { rangeOfNode } from 'sierra-client/views/v3-author/queries'
import { EditorKeyboardEvent, SanaEditor, SlateRootElement } from 'sierra-domain/v3-author'
import { EditorAction, runEditorAction } from 'sierra-domain/v3-author/arbitraries'
import { ReactEditor } from 'slate-react'

function waitOneFrame(): Promise<void> {
  return new Promise(resolve => requestAnimationFrame(() => resolve()))
}

async function waitNFrames(n: number): Promise<void> {
  for (let i = 0; i < n; i++) {
    await waitOneFrame()
  }
}

function setSelectionIfNoneExists(editor: SanaEditor): void {
  if (editor.selection) return

  const [start] = rangeOfNode(editor, [0])
  editor.select({ anchor: start, focus: start })
}

const maxRetryCount = 0

async function waitForEditorFocus(editor: SanaEditor, retryCount = 0): Promise<void> {
  if (retryCount > maxRetryCount) {
    throw new Error(`Unable to focus editor after ${retryCount} frame(s)`)
  }

  if (retryCount > 0) {
    console.info('[waitForEditorFocus] Retry:', retryCount)
  }

  try {
    ReactEditor.focus(editor)
  } catch (e) {
    await waitOneFrame()
    return waitForEditorFocus(editor, retryCount + 1)
  }
}

async function waitForDOMPoints(editor: SanaEditor, retryCount = 0): Promise<void> {
  if (retryCount > maxRetryCount) {
    throw new Error(`Unable to resolve DOM point from Slate point after ${retryCount} frame(s)`)
  }

  if (retryCount > 0) {
    console.info('[waitForDOMPoints] Retry:', retryCount)
  }

  if (!editor.selection) return

  try {
    const { focus, anchor } = editor.selection
    ReactEditor.toDOMPoint(editor, anchor)
    ReactEditor.toDOMPoint(editor, focus)
  } catch (e) {
    await waitOneFrame()
    return waitForDOMPoints(editor, retryCount + 1)
  }
}

async function waitForDOMNodes(editor: SanaEditor, retryCount = 0): Promise<void> {
  if (retryCount > maxRetryCount) {
    throw new Error(`Unable to resolve DOM Node from Slate point after ${retryCount} frame(s)`)
  }

  if (retryCount > 0) {
    console.info('[waitForDOMNodes] Retry:', retryCount)
  }

  if (!editor.selection) return

  try {
    const nodes = editor.nodes({ at: editor.selection })
    for (const [node] of nodes) {
      ReactEditor.toDOMNode(editor, node)
    }
  } catch (e) {
    await waitOneFrame()
    return waitForDOMNodes(editor, retryCount + 1)
  }
}
export type RunEditorActionsResult = { completedActions: EditorAction[]; error: Error | undefined }

export async function runEditorActions(
  editor: SanaEditor,
  actions: EditorAction[],
  onKeyDown: (event: EditorKeyboardEvent) => void,
  abortSignal?: AbortSignal
): Promise<RunEditorActionsResult> {
  setSelectionIfNoneExists(editor)
  await waitForEditorFocus(editor)
  await waitNFrames(3)
  await waitForDOMNodes(editor)
  await waitForDOMPoints(editor)

  const completedActions: EditorAction[] = []
  for (const action of actions) {
    if (abortSignal?.aborted === true) return { completedActions, error: undefined }

    try {
      await runEditorAction(editor, action, onKeyDown)

      if (editor.selection) {
        const nodesAtSelection = [...editor.nodes({ at: editor.selection })]
        if (nodesAtSelection.length === 0) throw new Error('Invalid editor selection')
      }

      // Ensure that slate-react has time to render the full react tree
      // and that slate operations are cleared out to avoid interference between actions

      if (
        // The drag and drop error boundary sometimes absorbs errors
        // so in the experimental editor we set up a boundary which writes
        // to the editor object instead
        // eslint-disable-next-line @typescript-eslint/no-explicit-any
        (editor as any).error !== undefined
      )
        // eslint-disable-next-line @typescript-eslint/no-explicit-any
        throw (editor as any).error

      await waitNFrames(1)
      await waitForDOMNodes(editor)
      await waitForDOMPoints(editor)
      SlateRootElement.array().parse(editor.children)
    } catch (e) {
      completedActions.push(action)
      // eslint-disable-next-line no-console
      console.log(
        'Failed after running',
        completedActions.length,
        'actions with error ',
        (e as Error).message
      )
      console.error(e)
      // eslint-disable-next-line no-console
      console.log(completedActions)
      return { completedActions, error: e as Error }
    }

    completedActions.push(action)
  }
  return { completedActions, error: undefined }
}
