import { OWNER_ONLY, ShortcutMenu } from 'sierra-client/components/shortcut-menu'
import {
  useIsPermissionsInspectorToggled,
  useSetIsPermissionsInspectorToggled,
} from 'sierra-client/components/shortcut-menu/permissions-inspector/atoms'

export const DebugPermissionsShortcut: React.FC = () => {
  const isPermissionOverwritesEnabled = useIsPermissionsInspectorToggled()
  const setIsPermissionOverwritesEnabled = useSetIsPermissionsInspectorToggled()

  return (
    <ShortcutMenu.Toggle
      label={{ type: 'untranslated', value: 'Debug permissions' }}
      toggle={() => setIsPermissionOverwritesEnabled(prev => !prev)}
      isOn={() => isPermissionOverwritesEnabled}
      iconId='inspection'
      permission={OWNER_ONLY}
    />
  )
}
