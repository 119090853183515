import styled from 'styled-components'

export const FiltersContainer = styled.div`
  justify-content: flex-end;
  display: flex;
  gap: 16px;
  flex-wrap: nowrap;
  width: auto;
  align-items: center;
`
