import { useState } from 'react'
import { IdentitiesSelector } from 'sierra-client/components/common/identities-selector'
import { FetchIdentities } from 'sierra-client/components/common/identities-selector/types'
import { PermissionFormSection } from 'sierra-client/components/sharing/permission-form-elements'
import { useSetDashboardCollaboratorRoles } from 'sierra-client/features/insights/api-hooks/use-set-dashboard-collaborator-roles'
import { insightsDashboardSharedLogger } from 'sierra-client/features/insights/logger'
import { ListCollaborators } from 'sierra-client/features/insights/share-dashboard/list-collaborators'
import { editRightsItems } from 'sierra-client/features/insights/share-dashboard/menu-items'
import { VisibleToAllDropdown } from 'sierra-client/features/insights/share-dashboard/visible-to-all-dropdown'
import { useDeepEqualityMemo } from 'sierra-client/hooks/use-deep-equality-memo'
import { useTranslation } from 'sierra-client/hooks/use-translation'
import { typedPost } from 'sierra-client/state/api'
import { useDispatch } from 'sierra-client/state/hooks'
import { InsightsDashboardSetCollaboratorRolesRequest } from 'sierra-domain/api/insights'
import { IdentityWithMetadata } from 'sierra-domain/api/manage'
import { NanoId12 } from 'sierra-domain/api/nano-id'
import { XAnalyticsDashboardListPotentialCollaborators } from 'sierra-domain/routes'
import { MenuItem } from 'sierra-ui/components'
import { Button, Text, View } from 'sierra-ui/primitives'
import { SingleSelectDropdown } from 'sierra-ui/primitives/menu-dropdown'
import styled from 'styled-components'

const useFetchIdentities = (): FetchIdentities => {
  return async (query: string) => {
    const input = { query, limit: 100 }
    const data = await typedPost(XAnalyticsDashboardListPotentialCollaborators, input)
    return data.collaborators.map(it => ({ ...it, id: it.identity.id }))
  }
}

const RoleSelectorContainer = styled(View)`
  min-width: 120px;
  justify-content: flex-end;
`

export const ShareDashboardForm: React.FC<{
  dashboardId: NanoId12
  shareWithAll: boolean
  onShareWithAll: (shareWithAll: boolean) => void
}> = ({ dashboardId, shareWithAll, onShareWithAll }) => {
  const { t } = useTranslation()
  const dispatch = useDispatch()
  const setDashboardCollaboratorsMutation = useSetDashboardCollaboratorRoles()

  const editRightMenuItems: MenuItem[] = editRightsItems.map(it => ({ ...it, label: t(it.translationKey) }))
  const [selectedIdentities, setSelectedIdentities] = useState<IdentityWithMetadata[]>([])
  const [selectedEditRights, setSelectedEditRights] = useState<MenuItem | undefined>(editRightMenuItems[0])
  const fetchIdentities = useFetchIdentities()

  const handleSelect = (identity: IdentityWithMetadata): void => {
    setSelectedIdentities(current => [...current, identity])
  }

  const handleUnselect = (identity: IdentityWithMetadata): void => {
    setSelectedIdentities(current => current.filter(it => it.identity.id !== identity.identity.id))
  }

  const selectedIdentitiesWithId = useDeepEqualityMemo(
    selectedIdentities.map(identity => ({ ...identity, id: identity.identity.id }))
  )

  const handleSubmitShare = async (): Promise<void> => {
    if (selectedIdentities.length > 0 && selectedEditRights !== undefined) {
      const addedUsers = selectedIdentities
        .filter(identity => identity.identity.type === 'user')
        .reduce<InsightsDashboardSetCollaboratorRolesRequest['users']>((acc, it) => {
          acc[`${it.identity.id}`] = {
            type: 'assign',
            role: selectedEditRights.id,
          }

          return acc
        }, {})

      const addedGroups = selectedIdentities
        .filter(identity => identity.identity.type === 'userGroup')
        .reduce<InsightsDashboardSetCollaboratorRolesRequest['groups']>((acc, it) => {
          acc[`${it.identity.id}`] = {
            type: 'assign',
            role: selectedEditRights.id,
          }

          return acc
        }, {})

      setDashboardCollaboratorsMutation.mutate(
        { users: addedUsers, groups: addedGroups, dashboardId: NanoId12.parse(dashboardId) },
        {
          onSuccess: () => {
            setSelectedIdentities([])
            void dispatch(insightsDashboardSharedLogger({ dashboardId: dashboardId }))
          },
        }
      )
    }
  }

  return (
    <View direction='column' justifyContent='space-between' grow>
      <View direction='column' grow>
        <Text bold>{t('manage.insights.share-dashboard-form.heading')}</Text>
        <View direction='column' marginTop='xsmall' grow>
          <View marginBottom='xxsmall'>
            <View direction='column' grow>
              <IdentitiesSelector
                fetchIdentities={fetchIdentities}
                selectedIdentities={selectedIdentitiesWithId}
                onSelect={handleSelect}
                onUnselect={handleUnselect}
                placeholder='manage.programs.enrollment-rules.add-users-or-groups'
                trailingVisual={
                  <RoleSelectorContainer>
                    <SingleSelectDropdown
                      grow={false}
                      variant='ghost'
                      selectedItem={selectedEditRights}
                      menuItems={editRightMenuItems}
                      onSelect={setSelectedEditRights}
                    />
                  </RoleSelectorContainer>
                }
              />
            </View>

            <Button loading={setDashboardCollaboratorsMutation.isPending} onClick={handleSubmitShare}>
              {t('share.share')}
            </Button>
          </View>
          <View direction='column' grow>
            <ListCollaborators dashboardId={dashboardId} editRightMenuItems={editRightMenuItems} />
          </View>
          <PermissionFormSection
            title={t('manage.insights.share-dashboard-form.permission-heading')}
            iconId='building'
            subtitle={
              shareWithAll
                ? t('manage.insights.share-dashboard-form.permission-subtitle-share-with-all')
                : t('manage.insights.share-dashboard-form.permission-subtitle-private')
            }
          >
            <VisibleToAllDropdown shareWithAll={shareWithAll} onSelect={onShareWithAll} />
          </PermissionFormSection>
        </View>
      </View>
    </View>
  )
}
