import { SimpleTextTitle } from 'sierra-client/components/common/header-view'
import { useLiveSessionContext } from 'sierra-client/components/liveV2/contexts/live-session-data'

export const LiveSessionTitle = (): JSX.Element => {
  const liveSession = useLiveSessionContext()
  const title = liveSession.data.title

  return (
    <SimpleTextTitle size='small' bold>
      {title}
    </SimpleTextTitle>
  )
}
