import React from 'react'
import { WidgetBuilderAction } from 'sierra-client/features/insights/widget-builder/widget-builder-settings'
import { useOverrideIntercomLauncherVisibility } from 'sierra-client/intercom/intercom-visibility'
import { Dashboard } from 'sierra-domain/api/insights'
import { Modal } from 'sierra-ui/components'
import { IconButton, View } from 'sierra-ui/primitives'
import styled from 'styled-components'
import { MaybePartialDashboardWidget, PartialDashboardWidget } from './types'
import { WidgetBuilder } from './widget-builder'

const CloseButtonContainer = styled(View)`
  position: absolute;
  right: 10px;
  top: 10px;
`
const ModalBody = styled(View).attrs({ direction: 'column', alignItems: 'stretch', grow: true })`
  width: 100%;
  max-height: 100%;
`

// A component that allows a user to build a widget

export type WidgetBuilderModalState = WidgetBuilderAction | 'closed'

type WidgetBuilderModalProps = {
  state: WidgetBuilderModalState
  onClose: () => void
  onSubmit: (
    widget: MaybePartialDashboardWidget,
    onSuccess: () => void,
    dashboardId?: Dashboard['id']
  ) => void
  isSubmitting: boolean
  errorText?: string
}

export const WidgetBuilderModal: React.FC<WidgetBuilderModalProps> = ({
  state,
  onClose,
  onSubmit,
  isSubmitting,
  errorText,
}) => {
  const handleSubmit = (widget: PartialDashboardWidget, dashboardId?: Dashboard['id']): void => {
    try {
      onSubmit(widget, onClose, dashboardId)
    } catch (error) {
      console.error('Could not submit', error)
    }
  }

  useOverrideIntercomLauncherVisibility(state === 'closed' ? 'default' : 'hidden')

  return (
    <Modal open={state !== 'closed'} animation='pop' size='full-screen' onClose={onClose}>
      <ModalBody>
        <View grow direction='column' alignItems='stretch' position='relative'>
          <CloseButtonContainer>
            <IconButton iconId='close' variant='transparent' size='small' onClick={onClose} />
          </CloseButtonContainer>
          {state !== 'closed' && (
            <WidgetBuilder
              key={state.type}
              action={state}
              onSubmit={handleSubmit}
              isSubmitting={isSubmitting}
              errorText={errorText}
            />
          )}
        </View>
      </ModalBody>
    </Modal>
  )
}
