import React from 'react'
import { findPathToNodeWithId, removeNodeWithId } from 'sierra-client/views/v3-author/command'
import { assertElementType } from 'sierra-client/views/v3-author/queries'
import { useRenderingContext } from 'sierra-client/views/v3-author/rendering-context'
import { SlateFC, SlateFCWrapper, SlateWrapperProps } from 'sierra-client/views/v3-author/slate'
import { Icon } from 'sierra-ui/components'
import { IconButton, View } from 'sierra-ui/primitives'
import { LightTokenProvider, palette, token } from 'sierra-ui/theming'
import { ConditionalWrapper } from 'sierra-ui/utils'
import { Path } from 'slate'
import { useSlateStatic } from 'slate-react'
import styled, { css } from 'styled-components'

const RemoveButton = styled(IconButton).attrs({
  iconId: 'trash-can',
  variant: 'transparent',
  size: 'small',
})`
  position: absolute;
  right: -28px;
  opacity: 0;
  transition: opacity 200ms ease;
`

const AlternativeContainer = styled.div<{ $white: boolean }>`
  text-align: center;
  padding-right: 24px 64px;
  background-color: ${token('surface/soft')};
  backdrop-filter: blur(33px);
  border-radius: 16px;
  display: flex;
  flex-direction: 'row';
  justify-content: center;
  align-items: center;
  transition: background-color 200ms ease;

  gap: 36px;

  ${p =>
    p.$white === true &&
    css`
      color: ${palette.primitives.black};
      background-color: ${palette.primitives.white};
      border-color: ${palette.grey[5]};
    `}

  &:hover {
    ${RemoveButton} {
      opacity: 1;
    }
  }
`

const MiddleIconContainer = styled.div`
  position: absolute;
  left: 50%;
  top: 50%;
  transform: translate(-50%, -50%);
  gap: 8px;

  height: 36px;
  padding: 8px 10px;
  border-radius: 18px;

  display: flex;
  justify-content: center;
  align-items: center;

  background-color: ${token('surface/default')};
  z-index: 1;
`

const MiddleIcon: React.FC = () => {
  return (
    <MiddleIconContainer contentEditable={false}>
      <Icon iconId='equals' size='size-16' />
    </MiddleIconContainer>
  )
}

export const QuestionCardMatchThePairsAlternativeWrapper: SlateFCWrapper = React.forwardRef<
  HTMLDivElement,
  SlateWrapperProps
>(({ children, attributes }, ref) => {
  return (
    <div {...attributes} ref={ref}>
      {children}
    </div>
  )
})

const QuestionCardMatchThePairsAlternativeLearner: SlateFC = props => {
  return <View direction='row'>{props.children}</View>
}

const QuestionCardMatchThePairsAlternativeCreate: SlateFC = props => {
  const editor = useSlateStatic()

  const { preview } = useRenderingContext()
  const onRemove = (): void => {
    const pathToElement = findPathToNodeWithId(editor, props.element.id)
    try {
      if (pathToElement !== undefined) {
        editor.select(editor.start(Path.previous(pathToElement)))
      }
    } catch (e) {
      /* empty */
    }
    removeNodeWithId(editor, props.element.id)
  }

  return (
    <>
      <AlternativeContainer $white={preview === false}>
        <ConditionalWrapper
          condition={preview === false}
          renderWrapper={children => {
            return <LightTokenProvider>{children}</LightTokenProvider>
          }}
        >
          <>
            {props.children}
            <MiddleIcon />
          </>
        </ConditionalWrapper>
        <RemoveButton contentEditable={false} onClick={onRemove} />
      </AlternativeContainer>
    </>
  )
}

export const QuestionCardMatchThePairsAlternative: SlateFC = props => {
  const { element } = props
  // const { preview } = useRenderingContext()
  assertElementType('question-card-match-the-pairs-alternative', element)

  if (props.mode === 'create') {
    return <QuestionCardMatchThePairsAlternativeCreate {...props} />
  } else {
    return <QuestionCardMatchThePairsAlternativeLearner {...props} />
  }
}
