import { createAction, createAsyncThunk } from '@reduxjs/toolkit'
import { ChatMessageUpdated } from 'sierra-client/realtime-data/channels/live-session-chat-data-channel'
import { sendChatNotifications } from 'sierra-client/state/chat/mention-notifications'
import { selectMessage } from 'sierra-client/state/chat/selectors'
import { ChatAwarenessData } from 'sierra-client/state/chat/types'
import { getServerTimeNow } from 'sierra-client/state/collaboration/selectors'
import { RootState } from 'sierra-client/state/types'
import { selectUserId } from 'sierra-client/state/user/user-selector'
import { ChatMessageId, ChatMessageReactionId, UserId } from 'sierra-domain/api/uuid'
import {
  Chat,
  CommentTiptapMessage,
  EmojiMessage,
  MessageReaction,
  PlainTiptapMessage,
} from 'sierra-domain/chat'
import { ScopedChatId } from 'sierra-domain/collaboration/types'

type ThunkAPI = { state: RootState }

type ServerTime = { serverTime: string }

const assertChatInstantiated = (chatId: ScopedChatId, state: RootState): Chat => {
  const existingChannel = state.chat.chats[chatId]
  if (!existingChannel) {
    throw new Error(`Chat ${chatId} not instantiated`)
  }
  return existingChannel
}

type SendPlainMessageData = { id: ChatMessageId; chatId: ScopedChatId; threadId: string } & Pick<
  PlainTiptapMessage,
  'tiptapJsonData' | 'userId'
>

export const sendPlainMessage = createAsyncThunk<
  SendPlainMessageData & ServerTime,
  SendPlainMessageData,
  { state: RootState }
>('chat/sendPlainMessage', (args, { getState, dispatch }) => {
  const { chatId } = args
  const serverTime = getServerTimeNow(getState().collaboration.clock)
  const chat = assertChatInstantiated(chatId, getState())

  sendChatNotifications({ ...args, chat }, dispatch)

  return { ...args, serverTime }
})

type SendCommentMessageData = SendPlainMessageData & Pick<CommentTiptapMessage, 'contentReference'>

export const sendCommentMessage = createAsyncThunk<
  SendCommentMessageData & ServerTime,
  SendCommentMessageData,
  ThunkAPI
>('chat/sendCommentMessage', (args, { getState, dispatch }) => {
  const { chatId } = args
  const serverTime = getServerTimeNow(getState().collaboration.clock)
  const chat = assertChatInstantiated(chatId, getState())

  sendChatNotifications({ ...args, chat }, dispatch)
  return { ...args, serverTime }
})

type SendEmojiMessageData = { id: ChatMessageId; chatId: ScopedChatId; threadId: string } & Pick<
  EmojiMessage,
  'emoji' | 'userId'
>

export const sendEmojiMessage = createAsyncThunk<
  SendEmojiMessageData & ServerTime,
  SendEmojiMessageData,
  { state: RootState }
>('chat/sendEmojiMessage', (args, { getState }) => {
  const { chatId } = args
  const serverTime = getServerTimeNow(getState().collaboration.clock)
  assertChatInstantiated(chatId, getState())

  return { ...args, serverTime }
})

type ReactToMessageData = {
  id: ChatMessageReactionId
  reaction: string
  chatId: ScopedChatId
  messageId: string
  userId: UserId
}

export const chatVisibilityChanged = createAction<{
  chatId: ScopedChatId
  threadId: string
  componentKey: string
  visible: boolean
}>('chat/chatVisibilityChanged')

export const reactToMessage = createAsyncThunk<
  ReactToMessageData & ServerTime,
  ReactToMessageData,
  { state: RootState }
>('chat/reactToMessasge', (args, { getState }) => {
  const serverTime = getServerTimeNow(getState().collaboration.clock)

  return { ...args, serverTime }
})

type EditMessageData = { messageId: string; chatId: ScopedChatId } & Pick<
  PlainTiptapMessage,
  'tiptapJsonData'
>
export const editMessage = createAsyncThunk<
  EditMessageData & ServerTime,
  EditMessageData,
  { state: RootState }
>('chat/editMessage', (args, { getState }) => {
  const { chatId, messageId } = args
  const serverTime = getServerTimeNow(getState().collaboration.clock)
  const currentState = getState()
  const message = selectMessage(currentState, chatId, messageId)
  const userId = selectUserId(currentState)

  if (message.userId !== userId) {
    throw new Error('Not allowed to edit this message')
  }

  return { ...args, serverTime }
})

export const resolveComment = createAsyncThunk<
  { chatId: ScopedChatId; threadId: string; userId: UserId } & ServerTime,
  { chatId: ScopedChatId; threadId: string; userId: UserId },
  { state: RootState }
>('chat/resolve-comment', (action, { getState }) => {
  const serverTime = getServerTimeNow(getState().collaboration.clock)
  return { ...action, serverTime }
})
export const unresolveComment = createAction<{ chatId: ScopedChatId; threadId: string }>(
  'chat/unresolve-comment'
)

export const setCommentAttachmentStatus = createAction<{
  chatId: ScopedChatId
  threadId: string
  isDetached: boolean
}>('chat/set-comment-attachment-status')

// y-redux actions

export const chatChanged = createAction<{ chatId: ScopedChatId; chat: Chat }>('chat/chatChanged')

// Only used for the backend data sync backgwards compatibility
export const chatMessageUpdated = createAction<{
  chatId: ScopedChatId
  data: ChatMessageUpdated
}>('chat/backend-chat-message-updated')

export const reactionAdded = createAction<{
  chatId: ScopedChatId
  messageId: string
  reaction: MessageReaction
}>('chat/backend-reaction-added')

export const reactionRemoved = createAction<{ chatId: ScopedChatId; messageId: string; reactionId: string }>(
  'chat/backend-reaction-removed'
)

export const chatAwarenessStatesChanged = createAction<{
  chatId: ScopedChatId
  awarenessStates: ChatAwarenessData[]
}>('chat/awarenessStatesChanged')

export const chatLocalAwarenessStateMerged = createAction<{
  chatId: ScopedChatId
  partialLocalAwarenessState: Partial<ChatAwarenessData>
}>('chat/chatLocalAwarenessStateMerged')

export const chatCleared = createAction<{ chatId: ScopedChatId }>('chat/cleared')
