import React from 'react'
import { BarChartPreviewSVG } from 'sierra-client/features/insights/home/svgs/bar-chart-preview'
import { LineChartPreviewSVG } from 'sierra-client/features/insights/home/svgs/line-chart-preview'
import { MetricWidgetPreview } from 'sierra-client/features/insights/home/svgs/metric-widget-preview'
import { ProgressBarPreviewSVG } from 'sierra-client/features/insights/home/svgs/progress-preview'
import { TablePreviewSVG } from 'sierra-client/features/insights/home/svgs/table-preview'
import { Widget } from 'sierra-domain/api/insights'
import { assertNever } from 'sierra-domain/utils'
import { View } from 'sierra-ui/primitives'
import { token } from 'sierra-ui/theming'
import styled from 'styled-components'

const Dot = styled(View)`
  border-radius: 50%;
  background-color: ${token('surface/strong')};
  width: 8px;
  height: 8px;
`

const Title = styled(View)`
  border-radius: 4px;
  background-color: ${token('surface/strong')};
  width: 30px;
  height: 8px;
`

const Container = styled(View)<{ $width: number; $height: number; $x: number; $y: number }>`
  grid-column: ${props => `${props.$x + 1} / span ${props.$width}`};
  grid-row: ${props => `${props.$y + 1} / span ${props.$height}`};
  height: 100%;
  padding: 10px;
  border-radius: 10px;
  background-color: ${token('surface/soft')};
`

const getPreviewSvg = (type: Widget['type']): React.ReactNode => {
  switch (type) {
    case 'widget.metric':
      return <MetricWidgetPreview value={Math.floor(Math.random() * 864)} />
    case 'widget.line-chart':
      return <LineChartPreviewSVG />
    case 'widget.bar-chart':
      return <BarChartPreviewSVG />
    case 'widget.aggregation-table':
    case 'widget.pivot-table':
    case 'widget.list-table':
      return <TablePreviewSVG />
    case 'widget.progress-bar':
      return <ProgressBarPreviewSVG />
    default:
      assertNever(type)
  }
}

export const WidgetPreview: React.FC<{
  width: number
  height: number
  x: number
  y: number
  type: Widget['type']
}> = ({ width, height, x, y, type }) => {
  return (
    <Container
      direction='column'
      background='surface/default'
      grow
      $width={width}
      $height={height}
      $x={x}
      $y={y}
    >
      <View>
        <Dot />
        <Title />
      </View>
      <View grow direction='column' overflow='hidden'>
        {getPreviewSvg(type)}
      </View>
    </Container>
  )
}
