import {
  InputComponent,
  WithPanelOptions,
  WrappedComponent,
  WrappedComponentWhenData,
} from 'sierra-client/views/manage/utils/with-modal/types'
import { useExtensibleClose } from 'sierra-client/views/manage/utils/with-modal/use-extensible-close'
import { Panel } from 'sierra-ui/components'

export const withPanel = <P extends Record<string, unknown>>(
  { PanelComponent = Panel, ...panelProps }: WithPanelOptions = {},
  Component: InputComponent<P>
): WrappedComponent<P> => {
  // Return wrapped component
  return props => {
    const { onClose, registerOnCloseHandler } = useExtensibleClose(props.onClose)

    return (
      <PanelComponent open={props.open} onClose={onClose} {...panelProps}>
        {props.open && (
          <Component
            {...props}
            onClose={onClose}
            onCloseWithoutValidation={props.onClose}
            registerOnCloseHandler={registerOnCloseHandler}
          />
        )}
      </PanelComponent>
    )
  }
}

// Sometimes the open state is implicit based on whether some data exists. In those cases we want the modal to be open
// when there's data, without having to sync the `open` prop with all the others. This helper component has
// that behavior -- there's no `open` prop, but it will be open when there's data.

export const withPanelOpenWhenData = <P extends Record<string, unknown>>(
  { PanelComponent = Panel, ...panelProps }: WithPanelOptions = {},
  Component: InputComponent<P>
): WrappedComponentWhenData<P> => {
  return ({ data, onClose: onCloseRaw }) => {
    const { onClose, registerOnCloseHandler } = useExtensibleClose(onCloseRaw)

    return (
      <PanelComponent open={data !== undefined} onClose={onClose} {...panelProps}>
        {data !== undefined && (
          <Component
            {...data}
            onClose={onClose}
            onCloseWithoutValidation={onCloseRaw}
            registerOnCloseHandler={registerOnCloseHandler}
          />
        )}
      </PanelComponent>
    )
  }
}
