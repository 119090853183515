import { Thumbnail } from 'sierra-client/views/workspace/components'
import { v2_breakpoint } from 'sierra-ui/theming/breakpoints'
import styled from 'styled-components'

export const StyledThumbnail = styled(Thumbnail)`
  width: 56px;
  height: 40px;
  min-width: 56px;
  min-height: 40px;
  border-radius: 8px;

  @media (min-width: ${v2_breakpoint.desktop_small}) {
    width: 70px;
    height: 50px;
    min-width: 70px;
    min-height: 50px;
    border-radius: 12px;
  }
`
