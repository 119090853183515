import { AssignedLiveSessionNotification } from 'sierra-client/features/activity-center/notifications/assigned-live-session'
import { CalendarEventAssignedNotification } from 'sierra-client/features/activity-center/notifications/calendar-event-assigned'
import { CalendarEventEnrollmentRequestApprovedNotification } from 'sierra-client/features/activity-center/notifications/calendar-event-enrollment-accepted'
import { CalendarEventEnrollmentRequestRejectedNotification } from 'sierra-client/features/activity-center/notifications/calendar-event-enrollment-rejected'
import { CalendarEventUnassignedNotification } from 'sierra-client/features/activity-center/notifications/calendar-event-unassigned'
import { CalendarEventUpdatedNotification } from 'sierra-client/features/activity-center/notifications/calendar-event-updated'
import { CertificateNotification } from 'sierra-client/features/activity-center/notifications/certificate'
import { CollaboratorNotification } from 'sierra-client/features/activity-center/notifications/collaborator-access-requested'
import { CommentNotification } from 'sierra-client/features/activity-center/notifications/comment'
import { ContentDueNotification } from 'sierra-client/features/activity-center/notifications/content-due-notification'
import { DashboardSharedNotification } from 'sierra-client/features/activity-center/notifications/dashboard-shared'
import { DeletedLiveSessionNotification } from 'sierra-client/features/activity-center/notifications/deleted-live-session'
import { HomeworksReadyNotification } from 'sierra-client/features/activity-center/notifications/homeworks-available-for-review'
import { RecapReadyNotification } from 'sierra-client/features/activity-center/notifications/recap-ready'
import { SmartCardsAvailableNotification } from 'sierra-client/features/activity-center/notifications/smart-cards-available'
import { TeamspaceNotification } from 'sierra-client/features/activity-center/notifications/teamspace-access-requested'
import { UnassignedLiveSessionNotification } from 'sierra-client/features/activity-center/notifications/unassigned-live-session'
import { UpcomingLiveSessionNotification } from 'sierra-client/features/activity-center/notifications/upcoming-live-session'
import { UserAssignedNotification } from 'sierra-client/features/activity-center/notifications/user-assigned'
import { UserUnassignedNotification } from 'sierra-client/features/activity-center/notifications/user-unassigned'
import { HomeNotification } from 'sierra-domain/api/learn'
import { assertNever } from 'sierra-domain/utils'
import { Heading, View } from 'sierra-ui/primitives'

const NotImplementedNotification: React.FC<{ notification: HomeNotification }> = ({ notification }) => {
  return (
    <View debug direction='column'>
      <Heading size='h2'>{notification.type}</Heading>
      {JSON.stringify(notification, null, 2)}
    </View>
  )
}

export const NotificationCard: React.FC<{ notification: HomeNotification }> = ({ notification }) => {
  switch (notification.type) {
    case 'user-assigned':
      return <UserAssignedNotification notification={notification} />
    case 'user-thread-responded-to':
    case 'user-mentioned-in-comment':
      return <CommentNotification notification={notification} />
    case 'teamspace-access-requested':
      return <TeamspaceNotification notification={notification} />
    case 'collaborator-access-requested':
      return <CollaboratorNotification notification={notification} />
    case 'certificate-issued-to-user':
      return <CertificateNotification notification={notification} />
    case 'issued-certificate-expiring':
      return <CertificateNotification notification={notification} />
    case 'recap-ready':
      return <RecapReadyNotification notification={notification} />
    case 'homeworks-available-for-review':
      return <HomeworksReadyNotification notification={notification} />
    case 'dashboard-shared':
      return <DashboardSharedNotification notification={notification} />
    case 'smart-cards-available':
      return <SmartCardsAvailableNotification notification={notification} />
    case 'calendar-event-assigned':
      return <CalendarEventAssignedNotification notification={notification} />
    case 'calendar-event-unassigned':
      return <CalendarEventUnassignedNotification notification={notification} />
    case 'calendar-event-updated':
      return <CalendarEventUpdatedNotification notification={notification} />
    case 'user-unassigned':
      return <UserUnassignedNotification notification={notification} />
    case 'content-due':
      return <ContentDueNotification notification={notification} />
    case 'deleted-live-session':
      return <DeletedLiveSessionNotification notification={notification} />
    case 'assigned-live-session':
      return <AssignedLiveSessionNotification notification={notification} />
    case 'unassigned-live-session':
      return <UnassignedLiveSessionNotification notification={notification} />
    case 'upcoming-live-session':
      return <UpcomingLiveSessionNotification notification={notification} />
    case 'calendar-event-enrollment-request-approved':
      return <CalendarEventEnrollmentRequestApprovedNotification notification={notification} />
    case 'calendar-event-enrollment-request-rejected':
      return <CalendarEventEnrollmentRequestRejectedNotification notification={notification} />
    case 'calendar-event-self-enrolled':
      return <NotImplementedNotification notification={notification} />
    case 'calendar-event-enrollment-requests-pending-review':
      return <NotImplementedNotification notification={notification} />
    default:
      assertNever(notification)
  }
}
