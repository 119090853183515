import React, { ChangeEventHandler, FC, useCallback, useEffect, useState } from 'react'
import { useCourseGroupLanguageItems } from 'sierra-client/hooks/use-language-items'
import { usePost } from 'sierra-client/hooks/use-post'
import { useTranslation } from 'sierra-client/hooks/use-translation'
import { CoverImageOverlay } from 'sierra-client/views/manage/programs/components/cover-image-in-modal'
import { CourseSummary } from 'sierra-domain/api/admin'
import { CourseId } from 'sierra-domain/api/nano-id'
import { XRealtimeAdminCoursesCourseDetail } from 'sierra-domain/routes'
import { color } from 'sierra-ui/color'
import { ColorName } from 'sierra-ui/color/types'
import { FormElement, Icon, InputGroup, LabelMenuItem, MenuItem, Panel, Tooltip } from 'sierra-ui/components'
import {
  Button,
  Checkbox,
  Heading,
  IconButton,
  InputPrimitive,
  LoadingSpinner,
  Text,
  TextAreaPrimitive,
  View,
} from 'sierra-ui/primitives'
import { SingleSelectDropdown } from 'sierra-ui/primitives/menu-dropdown'
import { spacing } from 'sierra-ui/theming'
import { useGenerateDomId } from 'sierra-ui/utils/use-generate-dom-id'
import styled, { css } from 'styled-components'

export type EligibleCourseForEdition = {
  id: CourseId
  title: string
}

export const courseSummaryToEligibleCourse = ({
  title,
  courseId,
}: CourseSummary): EligibleCourseForEdition => ({
  id: courseId,
  title,
})

type HeaderBaseProps = {
  $background?: string
}

const Optional: React.FC = () => {
  const { t } = useTranslation()
  return (
    <Text size='small' color='grey25' bold>
      {t('input.optional')}
    </Text>
  )
}

const HeaderBase = styled(View).attrs({ background: 'grey5', alignItems: 'stretch' })<HeaderBaseProps>`
  position: relative;
  min-height: 150px;

  ${p =>
    p.$background !== undefined &&
    css`
      background: center / cover url(${p.$background});
    `}
`

const HeaderInner = styled(View).attrs({
  direction: 'column',
  justifyContent: 'flex-end',
  paddingTop: '48',
  paddingLeft: '40',
  paddingRight: '80',
})`
  position: relative;
  flex: 1;
`

const Close = styled(IconButton).attrs({ iconId: 'close', variant: 'transparent' })`
  position: absolute;
  top: ${spacing['16']};
  right: ${spacing['16']};
`

const HeaderTitle = styled(Heading)`
  max-width: 530px;
  line-height: 1;
  padding-bottom: 4px;
`

const HeaderDescription = styled(Text)`
  padding-bottom: 24px;
`

const UnselectableDiv = styled.div`
  display: inline-flex;
  user-select: none;
`

const StyledInputGroup = styled(InputGroup).attrs({ $spacing: 'none' })<{ $grow?: boolean }>`
  &&& {
    flex: 0 0 auto;

    gap: 0;

    ${p =>
      p.$grow === true &&
      css`
        flex: 1;
      `}
  }

  min-width: 0;
  justify-content: space-between;

  ${UnselectableDiv} {
    min-height: 40px;
    flex: 1;
  }
`

const TitleAlignment = styled.div`
  display: grid;
  grid-template-columns: auto 200px;
  gap: 8px;
`

const EditionTitleInput: FC<{
  label: string
  helper?: string
  value: string
  onChange?: ChangeEventHandler<HTMLInputElement>
  placeholder?: string
}> = ({ label, helper, onChange = () => {}, ...props }) => {
  return (
    <FormElement label={label} helper={helper}>
      <InputPrimitive onChange={onChange} {...props} />
    </FormElement>
  )
}

const Circle = styled(View).attrs({
  radius: 'round',
  direction: 'row',
  alignItems: 'center',
  justifyContent: 'center',
})`
  width: 32px;
  height: 32px;
`

const CircleLabel = styled(Text).attrs({ color: 'black', bold: true, size: 'micro' })`
  line-height: 0;
`

const EditionHeadingGrey = styled(Text).attrs({ color: 'grey25', bold: true })`
  padding-left: 4px;
`

type CourseSelectionInputProps = {
  courses: EligibleCourseForEdition[]
  alreadyUsedCourseIds: string[]
  currentId: string | undefined
  onChange: (item: LabelMenuItem<CourseId>) => void
  label: string
  helper?: string
}

const CourseSelectionInput: React.FC<CourseSelectionInputProps> = ({
  courses,
  alreadyUsedCourseIds,
  currentId,
  onChange,
  label,
  helper,
}) => {
  const { t } = useTranslation()
  const items = courses.map(x => ({
    id: x.id,
    type: 'label' as const,
    label: x.title,
    hidden: alreadyUsedCourseIds.includes(x.id),
  }))
  const selectedItem = items.find(item => item.id === currentId)
  const id = useGenerateDomId()

  return (
    <FormElement htmlFor={id} label={label} helper={helper}>
      <SingleSelectDropdown
        id={id}
        searchPlaceholder={t('dictionary.search')}
        withSearch
        selectedItem={selectedItem}
        onSelect={item => {
          if (item.type === 'label') {
            onChange(item)
          }
        }}
        menuItems={items}
      />
    </FormElement>
  )
}

const EditionContentWrapper = styled(View).attrs({
  paddingLeft: '32',
  marginLeft: '12',
  direction: 'column',
})`
  position: relative;

  &::after {
    position: absolute;
    top: 0;
    left: 2px;
    content: '';
    width: 4px;
    height: 100%;
    border-radius: 4px;
    background-color: ${color('grey5').toString()};
  }
`

const InfoIcon = styled(Icon).attrs({
  iconId: 'information',
  color: 'grey25',
})``

const DefaultEdition: React.FC<{ isDefaultEdition: boolean; onClick: () => void }> = ({
  isDefaultEdition,
  onClick,
}) => {
  const { t } = useTranslation()

  return (
    <View direction='row' justifyContent='flex-start' alignItems='center'>
      <Tooltip title={t('course-editions.update.set-default-edition-info')}>
        <View
          direction='row'
          justifyContent='flex-start'
          alignItems='center'
          gap='8'
          marginTop='8'
          cursor='pointer'
          onClick={onClick}
        >
          <Checkbox checked={isDefaultEdition} />
          <Text color='grey40'>{t('course-editions.update.set-default-edition')}</Text>
          <InfoIcon />
        </View>
      </Tooltip>
    </View>
  )
}

export type RemoveEdition = (editionIndex: number) => void

export type UpdateEdition = (
  editionIndex: number,
  courseId: CourseId | undefined,
  title: string,
  description: string | undefined,
  language: string,
  isDefault: boolean
) => void

export type UpdateDefaultEdition = (editionIndex: number) => void

const CourseEditionSection: React.FC<{
  edition: {
    id: CourseId | undefined
    no: number
    title: string
    description: string | undefined
    language: string
    isDefault: boolean
  }
  editionCount: number
  courseList: EligibleCourseForEdition[]
  alreadyInUseCourseIds: CourseId[]
  alreadyInUseLanguages: string[]
  removeEdition: RemoveEdition
  updateEdition: UpdateEdition
  updateCallbacks?: {
    updateDefaultEdition: UpdateDefaultEdition
  }
}> = ({
  edition,
  editionCount,
  courseList,
  alreadyInUseCourseIds,
  removeEdition,
  updateEdition,
  updateCallbacks,
}) => {
  const { postWithUserErrorException } = usePost()

  const languageItems = useCourseGroupLanguageItems()
  const { t } = useTranslation()

  const [courseId, setCourseId] = useState<CourseId | undefined>(edition.id)
  const [title, setTitle] = useState<string>(edition.title)

  const [description, setDescription] = useState<string | undefined>(edition.description)
  const [language, setLanguage] = useState<string>(edition.language)
  const [currentCourseId, setCurrentCourseId] = useState<CourseId | undefined>(courseId)

  const fetchCourse = useCallback(
    async (courseId: CourseId) => {
      const courseDataResponse = await postWithUserErrorException(XRealtimeAdminCoursesCourseDetail, {
        id: courseId,
      })
      setDescription(courseDataResponse.description)
    },
    [postWithUserErrorException]
  )

  useEffect(() => {
    // HACK: Fetch course when id changes in order to get the description.
    if (edition.id !== undefined) {
      void fetchCourse(edition.id)
    }
  }, [fetchCourse, edition.id])

  const [currentLanguageId, setCurrentLanguageId] = useState<string | undefined>(language)
  const [open, setOpen] = useState<boolean>(true)

  useEffect(() => {
    if (updateCallbacks) {
      return setOpen(true)
    }
    setOpen(editionCount <= 2 ? true : edition.no === editionCount)
  }, [updateCallbacks, editionCount, edition.no])

  const onChangeCourseSelection = useCallback((course: LabelMenuItem<CourseId>) => {
    setCourseId(course.id)
    setCurrentCourseId(course.id)
    setTitle(course.label)
  }, [])

  const onChangeLanguageSelection = useCallback((language: MenuItem<string>) => {
    setLanguage(language.id)
    setCurrentLanguageId(language.id)
  }, [])

  useEffect(() => {
    if ((courseId || title || language || description) ?? '')
      updateEdition(edition.no - 1, courseId, title, description, language, edition.isDefault)
  }, [title, description, language, courseId, edition.no, updateEdition, edition.isDefault])

  const selectedLanguageItem = languageItems.find(item => item.id === currentLanguageId)

  const languagePickerId = useGenerateDomId()

  return (
    <View direction={'column'} alignItems='stretch' paddingTop='24'>
      <View
        justifyContent={'space-between'}
        paddingBottom={'12'}
        alignItems={'center'}
        cursor='pointer'
        onClick={() => setOpen(open => !open)}
      >
        <View alignItems={'center'}>
          <Circle background={'grey5'}>
            <CircleLabel>{edition.no}</CircleLabel>
          </Circle>
          <EditionHeadingGrey>{t('dictionary.edition-singular')}</EditionHeadingGrey>
        </View>
        <View padding='4'>
          {editionCount !== 1 && (
            <IconButton
              variant='transparent'
              iconId='trash-can'
              tooltip={t('course-editions.create.delete.tooltip')}
              onClick={e => {
                e.stopPropagation()
                removeEdition(edition.no - 1)
              }}
            />
          )}
          <IconButton
            variant='transparent'
            iconId={open ? 'chevron--up--small' : 'chevron--down--small'}
            onClick={() => setOpen(open => !open)}
          />
        </View>
      </View>
      {open && (
        <EditionContentWrapper>
          {(updateCallbacks !== undefined || edition.no !== 1) && (
            <View grow={true} paddingBottom='14'>
              <StyledInputGroup $grow>
                <CourseSelectionInput
                  label={t('course-editions.create.convert-course')}
                  courses={courseList}
                  currentId={currentCourseId}
                  alreadyUsedCourseIds={alreadyInUseCourseIds}
                  onChange={onChangeCourseSelection}
                />

                {updateCallbacks !== undefined && (
                  <DefaultEdition
                    isDefaultEdition={edition.isDefault}
                    onClick={() => updateCallbacks.updateDefaultEdition(edition.no - 1)}
                  />
                )}
              </StyledInputGroup>
            </View>
          )}
          <TitleAlignment>
            {updateCallbacks === undefined && edition.no === 1 && (
              <EditionTitleInput label={t('dictionary.title')} value={edition.title} />
            )}
            {(updateCallbacks !== undefined || edition.no !== 1) && (
              <EditionTitleInput
                label={t('course-editions.create.new-edition.edition-title')}
                value={title}
                onChange={event => setTitle(event.target.value)}
              />
            )}

            <FormElement htmlFor={languagePickerId} label={t('dictionary.language')}>
              <SingleSelectDropdown
                id={languagePickerId}
                searchPlaceholder={t('dictionary.search')}
                withSearch
                selectedItem={selectedLanguageItem}
                onSelect={onChangeLanguageSelection}
                menuItems={languageItems}
                maxHeight={300}
              />
            </FormElement>
          </TitleAlignment>

          <FormElement label={t('course-editions.create.new-edition.edition-description')}>
            <TextAreaPrimitive
              value={description ?? ''}
              onChange={e => {
                setDescription(e.target.value)
              }}
              resize='none'
              autoExpand
            />
          </FormElement>
        </EditionContentWrapper>
      )}
    </View>
  )
}

const AddCourseEditionButton: React.FC<{
  onClick: () => void
}> = ({ onClick }) => {
  const { t } = useTranslation()
  return (
    <View paddingTop='24' cursor='pointer' onClick={onClick}>
      <Circle background={'grey5'}>
        <CircleLabel>
          <Icon iconId='add' />
        </CircleLabel>
      </Circle>
      <EditionHeadingGrey>{t('course-editions.create.add-new-edition')}</EditionHeadingGrey>
    </View>
  )
}

const CourseGroupHeader: React.FC<{
  background?: string
  onClose: () => void
  updateModal?: boolean
}> = ({ background, onClose, updateModal }) => {
  const { t } = useTranslation()
  const color: ColorName = background !== undefined ? 'white' : 'black'

  return (
    <HeaderBase $background={background}>
      {background !== undefined && <CoverImageOverlay />}
      <HeaderInner>
        <Close tooltip={t('dictionary.close')} onClick={onClose} />
        <HeaderTitle size='h4' color={color} bold={true}>
          {updateModal === true
            ? t('course-editions.header.title.update')
            : t('course-editions.header.title.create')}
        </HeaderTitle>
        <HeaderDescription color={color}>{t('course-editions.header.description')}</HeaderDescription>
      </HeaderInner>
    </HeaderBase>
  )
}

const UpdateGroupTitleWrapper = styled(View).attrs({
  direction: 'column',
  alignItems: 'stretch',
  marginTop: '32',
  gap: '8',
})`
  ${InputGroup} {
    flex: 1;
  }
`

const UpdateGroupTitle: React.FC<{
  title: string
  setTitle: (title: string) => void
  placeholder?: string
}> = ({ title, setTitle, placeholder }) => {
  const { t } = useTranslation()

  return (
    <UpdateGroupTitleWrapper>
      <EditionTitleInput
        label={t('course-editions.update.title')}
        value={title}
        placeholder={placeholder}
        onChange={event => setTitle(event.target.value)}
        helper={t('course-editions.update.description')}
      />
    </UpdateGroupTitleWrapper>
  )
}

const FullSize = styled(View).attrs({
  direction: 'row',
  justifyContent: 'center',
  alignItems: 'center',
  grow: true,
})``

const LoadingScreen: React.FC = () => (
  <FullSize>
    <LoadingSpinner />
  </FullSize>
)

const Vertical = styled(View).attrs({ direction: 'column' })``

const Content = styled(Vertical).attrs({
  paddingTop: 'none',
  paddingBottom: '32',
  paddingLeft: '40',
  paddingRight: '40',
})``

const Footer = styled(View).attrs({
  paddingTop: 'none',
  paddingBottom: '32',
  paddingLeft: '40',
  paddingRight: '40',
  justifyContent: 'flex-end',
  alignItems: 'center',
})`
  min-height: 90px;
`

const Cancel = styled(Button).attrs({ variant: 'secondary' })``

const Save = styled(Button).attrs({ variant: 'primary' })``

const CourseGroupModal = styled(Panel).attrs({
  size: { width: 650 },
  animation: 'slide-right',
  padding: 'none',
})``

export const CourseGroupComponents = {
  CourseGroupModal,
  CourseGroupHeader,
  UpdateGroupTitle,
  AddCourseEditionButton,
  CourseEditionSection,
  LoadingScreen,
  Optional,
  Content,
  Footer,
  Cancel,
  Save,
}
