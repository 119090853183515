import { zodResolver } from '@hookform/resolvers/zod'
import _ from 'lodash'
import { DateTime } from 'luxon'
import React, { useState } from 'react'
import { useForm } from 'react-hook-form'
import { ActionModal } from 'sierra-client/components/common/modals/action-modal'
import { useHasOrganizationPermission } from 'sierra-client/hooks/use-permissions'
import { useTranslation } from 'sierra-client/hooks/use-translation'
import {
  createSchema,
  Form,
  type FormData,
} from 'sierra-client/views/manage/certificates/issue-certificate-panel/bulk-edit/form'
import {
  MixedValue,
  useMixedValues,
} from 'sierra-client/views/manage/certificates/issue-certificate-panel/bulk-edit/utils'
import { UploadedFile } from 'sierra-client/views/manage/certificates/issue-certificate-panel/shared'
import { DraftIssued } from 'sierra-client/views/manage/certificates/issue-certificate-panel/types'
import { PanelLayout } from 'sierra-client/views/manage/components/user-attributes/flows/components/layout'
import {
  FormDatePicker,
  FormFileInput,
  FormInput,
  FormProxySelector,
} from 'sierra-client/views/manage/shared/form'
import { IdentityWithMetadata } from 'sierra-domain/api/manage'
import { UserId } from 'sierra-domain/api/uuid'
import { assert, assertNever } from 'sierra-domain/utils'
import { PanelInPanel, PanelInPanelCloseButton } from 'sierra-ui/components/layout-kit/layout-panel'
import { Button, Heading, Spacer, View } from 'sierra-ui/primitives'
import styled from 'styled-components'

const ClickableHeader = styled(View)`
  &:hover {
    background: none;
    cursor: pointer;
  }
`

const getDefaultNoteState = (mixed: MixedValue<string>): Pick<FormData, 'note'> => {
  return mixed.type === 'regular' ? { note: mixed.value } : {}
}

const getDefaultFileState = (mixed: MixedValue<UploadedFile>): Pick<FormData, 'file'> => {
  switch (mixed.type) {
    case 'regular':
      return { file: mixed.value }
    case 'mixed':
      return {}
    case 'empty':
      return {}
    default:
      assertNever(mixed)
  }
}

const getDefaultIssuerState = (mixed: MixedValue<IdentityWithMetadata>): Pick<FormData, 'issuer'> => {
  switch (mixed.type) {
    case 'regular':
      return { issuer: mixed.value }
    case 'mixed':
      return {}
    case 'empty':
      return {}
    default:
      assertNever(mixed)
  }
}

const EditAll: React.FC<{
  onCancel: () => void
  onSubmit: (_: FormData) => void
  note: MixedValue<string>
  file: MixedValue<UploadedFile>
  expiresAt: MixedValue<DateTime>
  issuedAt: MixedValue<DateTime>
  issuer: MixedValue<IdentityWithMetadata>
  userIds: UserId[]
}> = ({ onCancel, onSubmit, note, file, issuedAt, expiresAt, issuer, userIds }) => {
  const { t } = useTranslation()
  const canIssueByProxy = useHasOrganizationPermission('ISSUE_CERTIFICATES_BY_PROXY')

  const {
    handleSubmit,
    formState: { isDirty, isLoading, isValid },
    control,
  } = useForm<FormData>({
    resolver: zodResolver(createSchema(t)),
    mode: 'onChange',
    defaultValues: {
      date: {
        issue: issuedAt.type === 'regular' ? issuedAt.value : undefined,
        expiry: expiresAt.type === 'regular' ? expiresAt.value : undefined,
      },
      ...getDefaultNoteState(note),
      ...getDefaultFileState(file),
      ...getDefaultIssuerState(issuer),
    },
  })

  const [showWarning, setWarning] = useState<{ type: 'warning'; okCb: () => void; cancelCb: () => void }>()

  return (
    <Form
      onSubmit={d => {
        if (isDirty) {
          setWarning({
            type: 'warning',
            okCb: () => {
              void handleSubmit(onSubmit)(d).finally(() => setWarning(undefined))
            },
            cancelCb: () => {
              setWarning(undefined)
            },
          })
        } else {
          void handleSubmit(onSubmit)(d)
        }
      }}
    >
      <View direction='column' gap='xsmall'>
        <View alignItems='flex-start'>
          <FormDatePicker
            placeholder={
              issuedAt.type === 'mixed'
                ? _.capitalize(t('dictionary.mixed'))
                : t('input.issue-date-placeholder')
            }
            control={control}
            name='date.issue'
            label={t('input.issue-date')}
          />
          <FormDatePicker
            placeholder={
              expiresAt.type === 'mixed'
                ? _.capitalize(t('dictionary.mixed'))
                : t('input.expiry-date-placeholder')
            }
            optional
            control={control}
            name='date.expiry'
            label={t('input.expiry-date')}
          />
        </View>

        <View direction='column' grow>
          <FormInput
            placeholder={_.capitalize(
              note.type === 'mixed' ? t('dictionary.mixed') : t('manage.certificates.add-note-placeholder')
            )}
            control={control}
            name='note'
            label={t('manage.certificates.add-note-label')}
          />
          <FormFileInput mixed={file.type === 'mixed'} control={control} name='file' />
        </View>
        {canIssueByProxy && (
          <FormProxySelector
            placeholder={issuer.type === 'mixed' ? _.capitalize(t('dictionary.mixed')) : undefined}
            userIds={userIds}
            control={control}
            name='issuer'
          />
        )}
      </View>
      <View alignItems='flex-end' justifyContent='flex-end'>
        <Button type='button' onClick={onCancel} variant='secondary'>
          {t('dictionary.cancel')}
        </Button>
        <Button loading={isLoading} disabled={!isValid || !isDirty} type='submit'>
          {_.capitalize(t('manage.certificates.apply-to-all'))}
        </Button>
      </View>
      {showWarning && (
        <ActionModal
          open
          title={t('manage.certificates.issuer.edit-all.warning.title')}
          onClose={showWarning.cancelCb}
          primaryAction={showWarning.okCb}
          primaryActionLabel={_.capitalize(t('dictionary.replace'))}
          secondaryAction={showWarning.cancelCb}
          secondaryActionLabel={t('dictionary.cancel')}
        >
          {t('manage.certificates.issuer.edit-all.warning.subtext')}
          <Spacer />
        </ActionModal>
      )}
    </Form>
  )
}

export const BulkEditButtonWithPanel: React.FC<{
  issued: DraftIssued[]
  onSubmit: (_: FormData) => void
}> = ({ issued, onSubmit }) => {
  const { t } = useTranslation()
  const [open, setOpen] = useState(false)

  const mixedValues = useMixedValues(issued)

  return (
    <>
      <Button onClick={() => setOpen(true)} variant='secondary'>
        {_.capitalize(t('manage.certificates.edit-all'))}
      </Button>
      <PanelInPanel open={open} onEscapeKeyDown={() => setOpen(false)}>
        {
          // Make sure we reset the view after every open
          open && (
            <PanelLayout>
              <View padding='medium' paddingTop='medium' gap='medium' direction='column' grow>
                <ClickableHeader
                  tabIndex={0}
                  onClick={() => {
                    setOpen(false)
                  }}
                  gap='xxsmall'
                >
                  <PanelInPanelCloseButton onClose={() => setOpen(false)} label={t('dictionary.close')} />
                  <Heading size='h5' bold>
                    {_.capitalize(t('manage.certificates.edit-all'))}
                  </Heading>
                </ClickableHeader>
                <EditAll
                  onCancel={() => {
                    setOpen(false)
                  }}
                  onSubmit={data => {
                    onSubmit(data)
                    setOpen(false)
                  }}
                  expiresAt={mixedValues.expiresAt}
                  note={mixedValues.note}
                  file={mixedValues.file}
                  issuedAt={mixedValues.issuedAt}
                  issuer={mixedValues.issuer}
                  userIds={issued.map(x => {
                    assert(x.identity.identity.type === 'user')

                    return x.identity.identity.id
                  })}
                />
              </View>
            </PanelLayout>
          )
        }
      </PanelInPanel>
    </>
  )
}
