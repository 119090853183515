import React, { useMemo } from 'react'
import { temporalUnits } from 'sierra-client/features/insights/constants'
import { toLabel } from 'sierra-client/features/insights/display-widgets/utils'
import {
  DisabledByMetricLabelsMap,
  useInsightsDimensions,
  useInsightsViewsFromMeasures,
} from 'sierra-client/features/insights/hooks/use-insights-views'
import { dimensionIconMap } from 'sierra-client/features/insights/widget-builder/data-selectors/dimension-icon-map'
import {
  disabledByAlreadySelectedLabel,
  disabledByMetricLabel,
  disabledByWidgetLabel,
} from 'sierra-client/features/insights/widget-builder/data-selectors/disabled-labels'
import { DropdownTriggerWithDelete } from 'sierra-client/features/insights/widget-builder/data-selectors/dropdown-trigger-with-delete'
import { WidgetBuilderState } from 'sierra-client/features/insights/widget-builder/widget-builder-state'
import { useTranslation } from 'sierra-client/hooks/use-translation'
import { TranslationLookup } from 'sierra-client/hooks/use-translation/types'
import { labelToString } from 'sierra-client/lib/tabular/datatype/label'
import {
  DimensionRef,
  Dimensions,
  MeasureRef,
  SingleDimension,
  ViewRef,
  areDimensionRefsEqual,
} from 'sierra-domain/api/insights'
import { assertNever } from 'sierra-domain/utils'
import { MenuItem } from 'sierra-ui/components'
import { SingleSelectDropdown } from 'sierra-ui/primitives/menu-dropdown'

export type MenuItemWithRef = MenuItem & {
  ref?: DimensionRef
}

type DisabledMenuItemConfig = { disabled: false } | { disabled: true; disabledLabel: string }

type DimensionSelectorType = 'index' | 'breakdown'

const getMenuItemDisabledConfig = ({
  dimensions,
  visualisation,
  t,
  isFilter,
  dimensionSelectorType,
  disabledByMetricLabelsMap,
  disabledDimensions,
}: {
  dimensions: SingleDimension
  visualisation?: WidgetBuilderState['visualisation']
  t: TranslationLookup
  isFilter: boolean
  dimensionSelectorType: DimensionSelectorType
  disabledByMetricLabelsMap?: DisabledByMetricLabelsMap
  disabledDimensions: DimensionRef[] | undefined
}): DisabledMenuItemConfig => {
  if (
    dimensionSelectorType === 'index' &&
    !isFilter &&
    visualisation?.type === 'line-chart' &&
    !temporalUnits.includes(dimensions.dimension.unit as string)
  ) {
    return { disabled: true, disabledLabel: disabledByWidgetLabel(t, visualisation) }
  }

  if (
    disabledByMetricLabelsMap !== undefined &&
    disabledByMetricLabelsMap[JSON.stringify(dimensions.dimension)] !== undefined
  ) {
    const metricsLabels = disabledByMetricLabelsMap[JSON.stringify(dimensions.dimension)] ?? []

    return { disabled: true, disabledLabel: disabledByMetricLabel(t, metricsLabels) }
  }
  if (disabledDimensions?.find(dim => areDimensionRefsEqual(dim, dimensions.dimension))) {
    return { disabled: true, disabledLabel: disabledByAlreadySelectedLabel(t) }
  }

  return { disabled: false }
}

export const dimensionsToMenuItem = ({
  dimensions,
  visualisation,
  t,
  isFilter,
  dimensionSelectorType,
  disabledByMetricLabelsMap,
  disabledDimensions,
}: {
  dimensions: Dimensions
  visualisation?: WidgetBuilderState['visualisation']
  t: TranslationLookup
  isFilter: boolean
  dimensionSelectorType: DimensionSelectorType
  disabledByMetricLabelsMap?: DisabledByMetricLabelsMap
  disabledDimensions: DimensionRef[] | undefined
}): MenuItemWithRef[] => {
  switch (dimensions.type) {
    case 'dimensions.dimension': {
      const disabledConfig = getMenuItemDisabledConfig({
        dimensions,
        visualisation,
        t,
        isFilter,
        dimensionSelectorType,
        disabledByMetricLabelsMap,
        disabledDimensions,
      })
      const { disabled } = disabledConfig

      return [
        {
          type: 'label',
          ref: dimensions.dimension,
          id: dimensions.id,
          label: labelToString(toLabel(dimensions.label), t),
          disabled,
          tooltip: disabled ? disabledConfig.disabledLabel : undefined,
          icon: dimensionIconMap[dimensions.id],
        },
      ]
    }
    case 'dimensions.group': {
      const menuItems = dimensions.dimensions.flatMap(dimensions =>
        dimensionsToMenuItem({
          dimensions,
          t,
          visualisation,
          isFilter,
          dimensionSelectorType,
          disabledByMetricLabelsMap,
          disabledDimensions,
        })
      )

      return [
        {
          type: 'nested',
          id: dimensions.id,
          label: labelToString(toLabel(dimensions.label), t),
          menuItems: menuItems,
          icon: dimensionIconMap[dimensions.id],
          ...(menuItems.every(menuItem => menuItem.disabled)
            ? {
                disabled: true,
                tooltip:
                  menuItems[0] !== undefined && 'tooltip' in menuItems[0] ? menuItems[0].tooltip : undefined,
              }
            : {}),
        },
      ]
    }
    case 'dimensions.section': {
      return [
        ...dimensions.dimensions.flatMap(dimensions =>
          dimensionsToMenuItem({
            dimensions,
            t,
            visualisation,
            isFilter,
            dimensionSelectorType,
            disabledByMetricLabelsMap,
            disabledDimensions,
          })
        ),
        { type: 'separator', id: `separator-${Math.random()}` },
      ]
    }
    default:
      assertNever(dimensions)
  }
}

type SelectableMenuItem = Extract<MenuItemWithRef, { type: 'label' }>

export const useDimensionsMenuItems = ({
  selectedDimension,
  visualisation,
  dimensionSelectorType,
  disabledDimensionsMetricLabelsMap,
  disabledDimensions,
}: {
  selectedDimension?: DimensionRef
  visualisation?: WidgetBuilderState['visualisation']
  dimensionSelectorType: DimensionSelectorType
  disabledDimensionsMetricLabelsMap: DisabledByMetricLabelsMap | undefined
  disabledDimensions: DimensionRef[] | undefined
}): {
  selectedItem: SelectableMenuItem | undefined
  menuItems: MenuItemWithRef[]
} => {
  const { t } = useTranslation()
  const allDimensions = useInsightsDimensions()

  return useMemo(() => {
    const menuItems: MenuItemWithRef[] =
      allDimensions === undefined
        ? []
        : allDimensions.flatMap(dimensions =>
            dimensionsToMenuItem({
              dimensions,
              t,
              visualisation,
              isFilter: false,
              dimensionSelectorType,
              disabledByMetricLabelsMap: disabledDimensionsMetricLabelsMap,
              disabledDimensions,
            })
          )

    const flattenMenuItems = (menuItem: MenuItemWithRef): MenuItemWithRef[] => {
      switch (menuItem.type) {
        case 'nested':
        case 'group':
          return menuItem.menuItems.flatMap(flattenMenuItems)
        default:
          return [menuItem]
      }
    }

    const allMenuItems: MenuItemWithRef[] = menuItems.flatMap(flattenMenuItems)
    const selectedItem =
      selectedDimension === undefined
        ? selectedDimension
        : allMenuItems.find(mi => mi.ref !== undefined && areDimensionRefsEqual(mi.ref, selectedDimension))
    return {
      selectedItem: selectedItem?.type === 'label' ? selectedItem : undefined,
      menuItems,
    }
  }, [
    allDimensions,
    selectedDimension,
    t,
    visualisation,
    dimensionSelectorType,
    disabledDimensionsMetricLabelsMap,
    disabledDimensions,
  ])
}

export const SingleDimensionSelector: React.FC<{
  onChange: (newDimension: DimensionRef) => void
  selectedDimension?: DimensionRef
  disabled?: boolean
  visualisation: WidgetBuilderState['visualisation']
  dimensionSelectorType: DimensionSelectorType
  renderTrigger?: () => JSX.Element
  onDelete?: () => void
  disabledDimensions: DimensionRef[]
  selectedMeasures: MeasureRef[]
  selectedView?: ViewRef
}> = ({
  onChange,
  selectedDimension,
  disabled,
  selectedMeasures,
  visualisation,
  dimensionSelectorType,
  renderTrigger,
  onDelete,
  disabledDimensions,
  selectedView,
}) => {
  const { t } = useTranslation()
  const { data: viewData } = useInsightsViewsFromMeasures(selectedMeasures, selectedView)

  const { selectedItem, menuItems } = useDimensionsMenuItems({
    selectedDimension,
    disabledDimensionsMetricLabelsMap: viewData?.disabledDimensionsByMetricLabels,
    visualisation,
    dimensionSelectorType,
    disabledDimensions,
  })

  const onSelect = (item: MenuItemWithRef): void => {
    if (item.ref !== undefined) {
      onChange(item.ref)
    }
  }

  return (
    <SingleSelectDropdown
      withSearch
      searchPlaceholder={t('dictionary.search')}
      disabled={disabled}
      selectedItem={selectedItem}
      menuItems={menuItems}
      onSelect={onSelect}
      renderTrigger={
        renderTrigger !== undefined
          ? renderTrigger
          : () => (
              <DropdownTriggerWithDelete
                label={selectedItem?.label}
                placeholder={
                  visualisation.type === 'table' && visualisation.table.tableType === 'list'
                    ? 'manage.insights.select-attribute.placeholder'
                    : 'manage.insights.select-dimension.placeholder'
                }
                icon={selectedItem?.icon}
                onDelete={onDelete}
              />
            )
      }
    />
  )
}
