import React, { useState } from 'react'
import { useTranslation } from 'sierra-client/hooks/use-translation'
import { getGlobalRouter } from 'sierra-client/router'
import { useCachedQuery } from 'sierra-client/state/api'
import { OpenCtaContainer } from 'sierra-client/views/workspace/components/content-table-row-components'
import { Linkable, continueUrl, detailsUrl } from 'sierra-client/views/workspace/utils/urls'
import { EditableContent, NonEditorTeamspaceCourse } from 'sierra-domain/api/editable-content'
import { XRealtimeAuthorGetCourseSettings } from 'sierra-domain/routes'
import { assertNever, isDefined } from 'sierra-domain/utils'
import { MenuButton, MenuItem } from 'sierra-ui/components'
import { Button } from 'sierra-ui/primitives'
import { ControlledOrNotControlled } from 'sierra-ui/utils'
import styled from 'styled-components'

export const DetailsButton = styled(Button)`
  opacity: 0.3;

  &:hover {
    opacity: 1;
  }
`

const CTAMenuButton: React.FC<
  { primaryLink: string; secondaryLink: string | undefined } & ControlledOrNotControlled
> = ({ primaryLink, secondaryLink, isOpen, onOpenChange }) => {
  const { t } = useTranslation()
  const linkCTAMenuItems: MenuItem<'edit' | 'view'>[] = [
    {
      id: 'edit',
      type: 'label',
      label: t('dictionary.edit'),
    },
    {
      hidden: secondaryLink === undefined,
      id: 'view',
      type: 'label',
      label: t('dictionary.view'),
    },
  ]

  const controlled = isOpen !== undefined ? { isOpen, onOpenChange } : {}

  return (
    <MenuButton
      {...controlled}
      onPrimaryClick={() => getGlobalRouter().navigate({ to: primaryLink })}
      menuItems={linkCTAMenuItems}
      variant='secondary'
      menuDirection='down'
      menuLabel={t('dictionary.more-options')}
      onSelect={item => {
        switch (item.id) {
          case 'edit':
            void getGlobalRouter().navigate({ to: primaryLink })
            return
          case 'view':
            if (isDefined(secondaryLink)) {
              void getGlobalRouter().navigate({ to: secondaryLink })
            }
            return
          default:
            assertNever(item.id)
        }
      }}
    >
      {t('dictionary.edit')}
    </MenuButton>
  )
}

const LinkCTAButton: React.FC<
  { linkable: Linkable; canEdit: boolean; link: string } & ControlledOrNotControlled
> = ({ linkable, canEdit, link, isOpen, onOpenChange }) => {
  const { t } = useTranslation()
  const queryResult = useCachedQuery(XRealtimeAuthorGetCourseSettings, { courseId: linkable.id })
  const linkUrl = queryResult.data?.kind === 'link' ? queryResult.data.settings.linkSettings?.url : undefined
  const controlled = isOpen !== undefined ? { isOpen, onOpenChange } : {}

  if (!canEdit) {
    return (
      <Button
        variant='secondary'
        onClick={() => getGlobalRouter().navigate({ to: isDefined(linkUrl) ? linkUrl : link })}
      >
        {t('dictionary.view')}
      </Button>
    )
  }
  return <CTAMenuButton {...controlled} primaryLink={link} secondaryLink={linkUrl} />
}

const CTAButton: React.FC<
  {
    canEdit: boolean
    content: NonEditorTeamspaceCourse | EditableContent
    linkable: Linkable
    link: string
  } & ControlledOrNotControlled
> = ({ canEdit, content, linkable, link, isOpen, onOpenChange }) => {
  const { t } = useTranslation()

  const controlled = isOpen !== undefined ? { isOpen, onOpenChange } : {}

  switch (content.type) {
    case 'link': {
      return <LinkCTAButton linkable={linkable} link={link} canEdit={canEdit} {...controlled} />
    }

    case 'native:course-group':
    case 'scorm:course-group': {
      const CTAText = canEdit ? t('course-group.manage-editions.text') : t('dictionary.details')
      return (
        <Button variant='secondary' onClick={() => getGlobalRouter().navigate({ to: link })}>
          {CTAText}
        </Button>
      )
    }
    case 'native:live':
    case 'native:event-group': {
      const CTAText = canEdit ? t('dictionary.edit') : t('dictionary.details')
      return (
        <Button variant='secondary' onClick={() => getGlobalRouter().navigate({ to: link })}>
          {CTAText}
        </Button>
      )
    }

    case 'native:self-paced': {
      const CTAText = canEdit ? t('dictionary.edit') : t('dictionary.start')
      return (
        <Button variant='secondary' onClick={() => getGlobalRouter().navigate({ to: link })}>
          {CTAText}
        </Button>
      )
    }

    default: {
      return <CTAMenuButton {...controlled} primaryLink={link} secondaryLink={continueUrl(linkable)} />
    }
  }
}

export const CTAActions: React.FC<
  {
    canEdit: boolean
    content: NonEditorTeamspaceCourse | EditableContent
    linkable: Linkable
    link: string
    hideDetailsButton?: boolean
  } & ControlledOrNotControlled
> = ({ canEdit, content, linkable, link, hideDetailsButton }) => {
  const [isOpen, onOpenChange] = useState(false)
  const { t } = useTranslation()
  return (
    <OpenCtaContainer showContainer={isOpen}>
      <CTAButton
        canEdit={canEdit}
        content={content}
        linkable={linkable}
        link={link}
        isOpen={isOpen}
        onOpenChange={onOpenChange}
      />
      {hideDetailsButton !== true && (
        <DetailsButton
          variant='transparent'
          onClick={() => {
            void getGlobalRouter().navigate({ to: detailsUrl(linkable) as string })
          }}
        >
          {t('dictionary.details')}
        </DetailsButton>
      )}
    </OpenCtaContainer>
  )
}
