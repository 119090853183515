import React from 'react'
import { useCourseTitle } from 'sierra-client/features/global-sidebar/hooks/use-course-title'
import { SidebarCourseMode } from 'sierra-client/features/global-sidebar/types'
import { useTranslation } from 'sierra-client/hooks/use-translation'
import { getGlobalRouter } from 'sierra-client/router'
import { CourseId } from 'sierra-domain/api/nano-id'
import { Icon, TruncatedTextWithTooltip } from 'sierra-ui/components'
import { Spacer, Text, View } from 'sierra-ui/primitives'
import { token } from 'sierra-ui/theming'
import styled from 'styled-components'

const ShortCutWrapper = styled.button`
  overflow: hidden;
  position: relative;
  height: 36px;
  margin-top: 16px;
  border-radius: 10px;
  width: 100%;
  background-color: transparent;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  padding: 8px 12px;
  border: 1px solid ${token('border/strong')};
  align-items: center;
  text-align: start;
  gap: 4px;
  cursor: pointer;
`

const NoWrapText = styled(Text)`
  white-space: nowrap;
`

const AbsoluteIconContainer = styled.div`
  position: absolute;
  right: 0;
  height: 36px;
  width: 36px;
  background-color: ${token('surface/default')};
  display: flex;
  align-items: center;
  justify-content: center;
`

export const SidebarShortcut: React.FC<{
  courseId: CourseId
  href: string
  courseMode: SidebarCourseMode
}> = ({ courseId, href, courseMode }) => {
  const { t } = useTranslation()
  const courseTitle = useCourseTitle({ courseId })
  if (courseTitle === undefined) return null

  const isEditor = courseMode === 'editor'

  return (
    <ShortCutWrapper
      as='a'
      href={href}
      onClick={e => {
        e.preventDefault()
        void getGlobalRouter().navigate({ to: href })
      }}
    >
      <View gap='8' grow>
        <NoWrapText color='foreground/muted' bold>
          {t(isEditor ? 'dictionary.edit' : 'dictionary.resume')}
        </NoWrapText>
        <TruncatedTextWithTooltip bold>{courseTitle}</TruncatedTextWithTooltip>
        <Spacer size='16' />
      </View>
      <AbsoluteIconContainer>
        <Icon iconId={isEditor ? 'edit' : 'course--card--playing'} />
      </AbsoluteIconContainer>
    </ShortCutWrapper>
  )
}
