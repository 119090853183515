import { atomWithStorage } from 'sierra-client/state/storage'
import { DashboardWidget, Widget } from 'sierra-domain/api/insights'
import { NanoId12 } from 'sierra-domain/api/nano-id'

export const latestVisitedDashboardAtom = atomWithStorage<NanoId12 | undefined>(
  'insights-latest-dashboard-visited',
  undefined
)

type WidgetTypeAndSize = {
  layout: DashboardWidget['layout']
  type: Widget['type']
}

export type DashboardMeta = { lastVisitedTimestampMs: number; widgetsMeta: WidgetTypeAndSize[] }

export const dashboardsMetaAtom = atomWithStorage<Record<NanoId12, DashboardMeta>>(
  'insights-dashboards',
  {},
  undefined,
  { getOnInit: true }
)
