/* eslint-disable react/forbid-dom-props */
import DOMPurify from 'dompurify'
import { useAtomValue } from 'jotai'
import _ from 'lodash'
import React, { useEffect, useMemo, useRef, useState } from 'react'
import ReactDOM from 'react-dom'
import { HighlightSyntax } from 'sierra-client/editor/syntax-highlighting/apply-syntax-highlighting'
import { reactDebugShortcutKeyAtom } from 'sierra-client/features/react-debug-mode/atoms'
import { AbsoluteRect } from 'sierra-client/features/react-debug-mode/components/absolute-rect'
import {
  DebugState,
  computeDebugState,
  fiberDebugData,
  findDomNode,
  openFileInEditor,
  useGetLinesOfCode,
} from 'sierra-client/features/react-debug-mode/utils/utils'
import { useDeveloperToolsEnabled } from 'sierra-client/hooks/use-developer-tools-enabled'
import { SerializedDOMRect, asNonNullable, serializeDomRect } from 'sierra-domain/utils'
import { color } from 'sierra-ui/color'
import { Icon } from 'sierra-ui/components'
import { Text, View } from 'sierra-ui/primitives'
import { legacyDark, theme } from 'sierra-ui/theming/legacy-theme'
import styled, { ThemeProvider, css } from 'styled-components'

const debugContainerId = 'react-debug-container'

type Point = { x: number; y: number }
function rectFromMousePoint({ x, y }: Point): Partial<SerializedDOMRect> {
  const height = window.innerHeight
  const width = window.innerWidth
  const showAbove = y > height / 2
  const showLeft = x > width / 2
  const rect: Partial<SerializedDOMRect> = {}
  if (showAbove) rect.bottom = height - y + 10
  else rect.top = y + 15

  if (showLeft) rect.right = width - x
  else rect.left = x

  return rect
}

const CodeDiv = styled.div`
  overflow: auto;
  max-width: 400px;
  width: 100%;
  font-size: 14px;
  position: relative;
`

const Pre = styled.pre`
  padding-left: 8px;
  font-family: monospace;
  width: 100%;

  & > * {
    font-family: monospace;
    white-space: pre;
    overflow: hidden;
    text-overflow: ellipsis;
  }
`

const lineNumberWidth = 24
const LineNumber = styled.span`
  min-width: ${lineNumberWidth}px;
  display: inline-block;
  color: #999;
  margin-right: 8px;
`

const LineOfCodeText = styled.p<{ $isPrimary: boolean }>`
  width: 100%;
  color: transparent;
  ${({ $isPrimary }) =>
    $isPrimary
      ? css`
          background: ${color('white').opacity(0.1).toString()};
        `
      : css``}
`

function LinesOfCode({
  fileName,
  lineNumber,
  columnNumber,
}: {
  fileName: string
  lineNumber: number
  columnNumber: number
}): JSX.Element | null {
  const linesOfCode = useGetLinesOfCode(fileName, lineNumber, columnNumber)

  const [highlightSyntax, setHighlightSyntax] = useState<HighlightSyntax | undefined>(undefined)

  useEffect(() => {
    void import('sierra-client/editor/syntax-highlighting/apply-syntax-highlighting').then(imported => {
      setHighlightSyntax(() => imported.default.highlightSyntax)
    })
  }, [])

  if (linesOfCode === undefined) return null
  const leftTrimAmount =
    _.min(linesOfCode.map(line => line.text.length - line.text.trimStart().length).filter(it => it > 0)) ?? 0

  return (
    <CodeDiv>
      <Pre>
        {linesOfCode
          .map(line => {
            return {
              ...line,
              text: line.text.slice(leftTrimAmount),
            }
          })
          .map((line, index) => (
            <LineOfCodeText key={index} $isPrimary={line.type === 'primary'}>
              <LineNumber>{line.lineNumber}</LineNumber>
              {line.text}
            </LineOfCodeText>
          ))}

        <div
          style={{ position: 'absolute', inset: 0, left: lineNumberWidth }}
          dangerouslySetInnerHTML={{
            __html: DOMPurify.sanitize(
              highlightSyntax?.(
                linesOfCode.map(line => line.text).join('\n'),

                'tsx'
              )?.html ?? linesOfCode.map(line => line.text).join('\n')
            ),
          }}
        />
      </Pre>
    </CodeDiv>
  )
}

function currentMouseElement({ current }: MouseState['mouseRef']): Element | undefined {
  if (!current) return undefined
  const { x, y } = current
  return document.elementsFromPoint(x, y).find(it => it.id !== debugContainerId)
}

const FullWidthView = styled(View)`
  width: 100%;
`

const Debug = ({ mouseRef }: MouseState): JSX.Element => {
  const [state, setState] = useState<DebugState | undefined>(undefined)

  useEffect(() => {
    function pointerDown(event: PointerEvent): void {
      if (event.altKey) {
        const node = currentMouseElement(mouseRef)
        const reactNode = computeDebugState(node)?.pathToParent[0]
        if (reactNode === undefined) return
        const debugData = fiberDebugData(reactNode)
        if (debugData === undefined) return
        const { fileName, lineNumber, columnNumber } = debugData

        event.preventDefault()
        event.stopPropagation()
        void openFileInEditor(fileName, lineNumber ?? 0, columnNumber ?? 0)
      }
    }

    window.addEventListener('pointerdown', pointerDown)
    return () => window.removeEventListener('pointerdown', pointerDown)
  }, [mouseRef])

  useEffect(() => {
    let isCancelled = false

    function loop(): void {
      requestAnimationFrame(() => {
        if (!isCancelled) {
          const { current } = mouseRef
          if (current) {
            const { x, y } = current
            const node = document.elementsFromPoint(x, y).find(it => it.id !== debugContainerId)
            if (node) setState(computeDebugState(node))
          }
          loop()
        }
      })
    }
    loop()

    return () => {
      isCancelled = true
    }
  }, [mouseRef])

  const mousePosition = mouseRef.current
  const rectFollowingMouse = mousePosition ? rectFromMousePoint(mousePosition) : undefined
  const parentReactNode = state ? Array.from(state.pathToParent).reverse()[0] : undefined
  const reactNode = _.chain(state?.pathToParent ?? [])
    .filter(fiber => {
      const node = findDomNode(fiber)
      return node !== undefined && 'getBoundingClientRect' in node
    })
    .take(1)
    .value()[0]

  const fileDebugData = useMemo(() => (reactNode ? fiberDebugData(reactNode) : undefined), [reactNode])

  const rect = useMemo(
    () => reactNode && serializeDomRect(asNonNullable(findDomNode(reactNode)).getBoundingClientRect()),
    [reactNode]
  )

  const iconIds = state?.metadata.iconIds
  return (
    <ThemeProvider theme={legacyDark}>
      {rect && (
        <AbsoluteRect
          {...rect}
          borderRadius='0px 6px 6px 6px'
          outline={color('rgb(219, 112, 147)').opacity(0.75).shift(0.5).toString()}
        >
          <div
            style={{
              position: 'absolute',
              top: -22,
              left: -1,
              pointerEvents: 'none',
              backgroundColor: color('rgb(219, 112, 147)').opacity(0.9).toString(),
              fontSize: 16,
              whiteSpace: 'nowrap',
              padding: 8,
            }}
          >
            <Text bold size='technical' color='white'>
              {rect.width.toFixed(0)}x{rect.height.toFixed(0)}
            </Text>
          </div>
        </AbsoluteRect>
      )}

      {rectFollowingMouse && state && parentReactNode && (
        <AbsoluteRect
          {...rectFollowingMouse}
          outline='transparent'
          background={color('rgb(30, 30, 30)').opacity(0.9)}
          backdropFilter='blur(5px)'
          borderRadius={theme.borderRadius['size-10']}
        >
          {fileDebugData &&
            fileDebugData.columnNumber !== undefined &&
            fileDebugData.lineNumber !== undefined && (
              <LinesOfCode
                fileName={fileDebugData.fileName}
                columnNumber={fileDebugData.columnNumber}
                lineNumber={fileDebugData.lineNumber}
              />
            )}

          <FullWidthView direction='row' padding='4 8' gap='8'>
            {fileDebugData && (
              <View grow>
                <Text size='small' color='white' bold>
                  {_.last(fileDebugData.fileName.split('/')) ?? ''}:{fileDebugData.lineNumber ?? 0}:
                  {fileDebugData.columnNumber ?? 0}
                </Text>
              </View>
            )}

            {iconIds && (
              <View>
                <ul style={{ listStyle: 'none', padding: 0, margin: 0 }}>
                  {iconIds.map(iconId => (
                    <li key={iconId}>
                      <View alignItems='center' gap='4'>
                        <Icon iconId={iconId} color='white' size='size-12' />
                        <Text size='technical' bold color='white'>
                          {iconId}
                        </Text>
                      </View>
                    </li>
                  ))}
                </ul>
              </View>
            )}

            {fileDebugData === undefined && 'Unable to show source file'}
          </FullWidthView>
        </AbsoluteRect>
      )}
    </ThemeProvider>
  )
}

type MouseState = { mouseRef: React.RefObject<Point | undefined> }
function useMouseState(): MouseState {
  const mouseRef = useRef<Point | undefined>(undefined)

  useEffect(() => {
    function pointerMove(event: PointerEvent): void {
      const point: Point = { x: event.clientX, y: event.clientY }
      mouseRef.current = point
    }

    window.addEventListener('pointermove', pointerMove)
    return () => window.removeEventListener('pointermove', pointerMove)
  }, [])

  return { mouseRef }
}

const ReactDebugInner: React.FC = () => {
  const isReactDebugShortcutKeyDown = useAtomValue(reactDebugShortcutKeyAtom)
  const mouseState = useMouseState()

  if (!isReactDebugShortcutKeyDown) return null

  return ReactDOM.createPortal(
    <div
      id={debugContainerId}
      style={{ position: 'fixed', cursor: 'pointer', inset: 0, zIndex: 100000 }}
      onClick={e => {
        e.preventDefault()
        e.stopPropagation()
      }}
    >
      <Debug {...mouseState} />
    </div>,
    window.document.body
  )
}

export const ReactDebug: React.FC = () => {
  const developerToolsEnabled = useDeveloperToolsEnabled()
  if (process.env.NODE_ENV !== 'development') return null
  if (!developerToolsEnabled) return null
  return <ReactDebugInner />
}
