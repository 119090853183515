import { useCallback, useEffect } from 'react'
import { useLiveSessionContext } from 'sierra-client/components/liveV2/contexts/live-session-data'
import { NextCardImagePreload } from 'sierra-client/components/liveV2/file-navigation/next-card-image-preload'
import { useGoToNode } from 'sierra-client/components/liveV2/hooks/use-go-to-node'
import { useSelectCurrentCardBackgroundColor } from 'sierra-client/components/liveV2/hooks/use-select-current-card'
import { NowButtonCSS } from 'sierra-client/features/sana-now/header/buttons'
import { useGetNavigateCards } from 'sierra-client/features/sana-now/header/use-next-or-prev-card'
import { useTranslation } from 'sierra-client/hooks/use-translation'
import { useSelector } from 'sierra-client/state/hooks'
import { selectKeyboardNavigationEnabled } from 'sierra-client/state/live/selectors'
import { IconButton, View } from 'sierra-ui/primitives'
import styled from 'styled-components'

const HeaderButtonGroupWrapper = styled(View)``

export const LightHeaderGroupIconButton = styled(IconButton)`
  ${NowButtonCSS}
  :disabled {
    opacity: 0.4;
  }
`

export const RightFileNavigationButtons = (): JSX.Element | null => {
  const { flexibleContentId } = useLiveSessionContext().data
  const { t } = useTranslation()
  const { nextCardId } = useGetNavigateCards()
  const keyboardNavigationEnabled = useSelector(selectKeyboardNavigationEnabled)
  const goToNode = useGoToNode()

  const nextCard = useCallback((): void => {
    if (nextCardId !== undefined) {
      goToNode(nextCardId)
    }
  }, [goToNode, nextCardId])

  useEffect(() => {
    if (!keyboardNavigationEnabled) return

    const handleKey = (evt: KeyboardEvent): void => {
      // Don't act if an input field is the element
      const target = evt.target as HTMLElement | undefined
      if (target && 'tagName' in target) {
        const tag = target.tagName.toLowerCase()
        if (tag === 'input' || tag === 'textarea' || tag === 'select') {
          return
        }
      }

      const isTiptapInput =
        target?.classList.contains('ProseMirror') === true || target?.classList.contains('tiptap') === true

      if (isTiptapInput) {
        return
      }

      switch (evt.key) {
        case 'ArrowRight':
        case 'ArrowDown':
          nextCard()
          break
      }
    }

    window.addEventListener('keydown', handleKey)

    return () => {
      window.removeEventListener('keydown', handleKey)
    }
  }, [nextCard, keyboardNavigationEnabled])

  const backgroundColor = useSelectCurrentCardBackgroundColor()
  return (
    <>
      <HeaderButtonGroupWrapper gap='none'>
        <LightHeaderGroupIconButton
          $backgroundColor={backgroundColor}
          iconId={'next--filled'}
          onClick={nextCard}
          tooltip={t('live.next-card')}
          disabled={nextCardId === undefined}
        />
      </HeaderButtonGroupWrapper>
      {nextCardId !== undefined && (
        <NextCardImagePreload nextCardId={nextCardId} flexibleContentId={flexibleContentId} />
      )}
    </>
  )
}
