import { createContext, useCallback, useContext, useMemo, useState } from 'react'
import { TemplatePickerModal } from 'sierra-client/components/templates/template-picker-modal'
import { UseTemplates, useTemplates } from 'sierra-client/components/templates/use-templates'
import { useToggle } from 'sierra-client/hooks/use-toggle'
import { Template } from 'sierra-client/state/templates/types'
import { NanoId12 } from 'sierra-domain/api/nano-id'

type TemplatePickerContextType = {
  templatePickerOpen: boolean
  templatePickerOn: (template?: Template) => void
  templatePickerOff: () => void
  templates: UseTemplates
}

const initial = {
  templatePickerOpen: false,
  templatePickerOn: () => {},
  templatePickerOff: () => {},
  templates: {
    sanaLive: [],
    sanaSelfPaced: [],
    partnerAll: [],
    partnerLive: [],
    partnerSelfPaced: [],
    recommended: [],
    community: [],
  },
}

const TemplatePickerContext = createContext<TemplatePickerContextType>(initial)

export const TemplatePickerProvider: React.FC<
  React.PropsWithChildren<{ teamspaceId?: NanoId12; folderId?: NanoId12 }>
> = ({ teamspaceId, folderId, children }) => {
  const [templatePickerOpen, { on: templatePickerOnFn, off: templatePickerOffFn }] = useToggle()
  const [preSelectedTemplate, setPreselectedTemplate] = useState<Template>()

  const templatePickerOn = useCallback(
    (template?: Template): void => {
      setPreselectedTemplate(template)
      templatePickerOnFn()
    },
    [templatePickerOnFn]
  )

  const templatePickerOff = useCallback((): void => {
    setPreselectedTemplate(undefined)
    templatePickerOffFn()
  }, [templatePickerOffFn])

  const templates = useTemplates()

  const value = useMemo<TemplatePickerContextType>(
    () => ({
      templatePickerOpen,
      templatePickerOn,
      templatePickerOff,
      templates,
    }),
    [templatePickerOpen, templatePickerOn, templatePickerOff, templates]
  )

  return (
    <TemplatePickerContext.Provider value={value}>
      {children}
      <TemplatePickerModal
        teamspaceId={teamspaceId}
        folderId={folderId}
        open={templatePickerOpen}
        onClose={templatePickerOff}
        templates={templates}
        preSelectedTemplate={preSelectedTemplate}
      />
    </TemplatePickerContext.Provider>
  )
}

export const useTemplatePicker = (): TemplatePickerContextType => useContext(TemplatePickerContext)
