import { IconListItem } from 'sierra-client/components/common/icon-list'
import { useTranslation } from 'sierra-client/hooks/use-translation'
import { horizontalPadding } from 'sierra-client/layout/styles/content'
import { SetupContainer } from 'sierra-client/views/authentication/native/components/common'
import { Button, Text, View } from 'sierra-ui/primitives'
import { palette, spacing } from 'sierra-ui/theming'
import { breakpoints, v2_breakpoint_int } from 'sierra-ui/theming/breakpoints'
import styled from 'styled-components'

const Container = styled.div<{ $show: boolean }>`
  position: fixed;
  bottom: 0;
  left: 0;
  width: 100%;
  ${horizontalPadding}
  padding-top: 1.5rem;
  padding-bottom: 1.5rem;
  opacity: ${p => (p.$show ? 1 : 0)};
  visibility: ${p => (p.$show ? 'visible' : 'hidden')};
  transition: ${p =>
    p.$show
      ? 'opacity 0.2s ease, visibility 0s linear 0s;'
      : 'opacity 0.2s ease, visibility 0s linear 0.2s;'};
`

const Inner = styled(SetupContainer)`
  display: flex;
  flex-direction: column;
  align-items: center;

  @media screen and (min-width: ${breakpoints.phone}) {
    flex-direction: row;
    justify-content: space-between;
  }
`

const IndicatorContainer = styled(View)`
  @media screen and (max-width: ${v2_breakpoint_int.phone - 1}px) {
    margin-top: 1rem;
    margin-bottom: 1rem;
  }

  @media screen and (min-width: ${breakpoints.phone}) {
    position: fixed;
    bottom: 2.5rem;
    left: 50%;
    transform: translateX(-50%);
  }
`

const Indicator = styled.div<{ $active: boolean }>`
  height: ${spacing['4']};
  width: ${spacing['4']};
  margin-left: ${spacing['4']};
  margin-right: ${spacing['4']};
  border-radius: 50%;
  background-color: ${palette.primitives.white};
  opacity: ${p => (p.$active ? 1 : 0.2)};
`

type Props = {
  step: 1 | 2 | 3
  isDisabled: boolean
  isShowing: boolean
  nextUpText?: string
  onContinue?: () => void
}

export const AuthenticationFooter: React.FC<Props> = ({
  step,
  isDisabled,
  isShowing,
  nextUpText,
  onContinue,
}) => {
  const { t } = useTranslation()

  return (
    <Container $show={isShowing}>
      <Inner>
        <View>
          {nextUpText !== undefined && (
            <>
              <IconListItem
                iconId='next--filled'
                text={t('dictionary.next-up')}
                faded={false}
                color='white'
                bold
              />
              <Text color='foreground/primary' size='small'>
                {nextUpText}
              </Text>
            </>
          )}
        </View>
        <IndicatorContainer>
          <Indicator $active={step === 1} />
          <Indicator $active={step === 2} />
          <Indicator $active={step === 3} />
        </IndicatorContainer>
        {onContinue !== undefined && (
          <Button variant='primary' onClick={onContinue} disabled={isDisabled}>
            {step === 3 ? t('dictionary.complete') : t('create-account.continue')}
          </Button>
        )}
      </Inner>
    </Container>
  )
}
