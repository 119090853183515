import { useMemo } from 'react'
import { useLiveSessionContext } from 'sierra-client/components/liveV2/contexts/live-session-data'
import { useSelectCurrentCard } from 'sierra-client/components/liveV2/hooks/use-select-current-card'
import { selectFlexibleContentChildIds } from 'sierra-client/state/flexible-content/selectors'
import { useSelector } from 'sierra-client/state/hooks'
import { FileId } from 'sierra-domain/flexible-content/identifiers'
import { guardWith } from 'sierra-domain/utils'

export const useGetNavigateCards = (): { prevCardId: FileId | undefined; nextCardId: FileId | undefined } => {
  const { flexibleContentId } = useLiveSessionContext().data
  const currentCard = useSelectCurrentCard()

  const nodesIds = useSelector(state =>
    selectFlexibleContentChildIds(state, flexibleContentId, 'folder:root')
  )

  const fileIds = useMemo(() => nodesIds.filter(guardWith(FileId)), [nodesIds])
  const currentCardId = currentCard?.id ?? fileIds[0]

  const currentCardIdIndex = currentCardId !== undefined ? fileIds.indexOf(currentCardId) : undefined

  const prevCardId = currentCardIdIndex !== undefined ? fileIds[currentCardIdIndex - 1] : undefined
  const nextCardId = currentCardIdIndex !== undefined ? fileIds[currentCardIdIndex + 1] : undefined

  return useMemo(
    () => ({
      nextCardId,
      prevCardId,
    }),
    [nextCardId, prevCardId]
  )
}
