import VirtualBackgroundExtension, {
  VirtualBackgroundEffectOptions,
} from 'agora-extension-virtual-background'
import { VideoProcessor } from 'agora-rte-extension'

// These types are needed because the typing in agora-extension-virtual-background is not correct
export type MyVirtualBackgroundProcessor = VideoProcessor & {
  init: (assets: string) => Promise<void>
  setOptions: (options: VirtualBackgroundEffectOptions) => void
  onoverload?: (elapsedTime: number) => void
}
type MyVirtualBackgroundExtension = VirtualBackgroundExtension & {
  createProcessor: () => MyVirtualBackgroundProcessor
}

// Helper to ensure we only load one instance of the audio denoiser
let virtualBackgroundPromise: Promise<MyVirtualBackgroundExtension> | undefined
export const loadVirtualBackground = async (): Promise<MyVirtualBackgroundExtension> => {
  if (virtualBackgroundPromise) return virtualBackgroundPromise

  virtualBackgroundPromise = (async () => {
    const VirtualBackgroundExtension = (await import('agora-extension-virtual-background')).default
    const AgoraRTC = (await import('agora-rtc-sdk-ng')).default

    // TODO: The data files for the virtual background processor are copied from node_modules/TODO/external
    // to client/public/TODO and checked in there. This should be a build step.
    const extension = new VirtualBackgroundExtension() as MyVirtualBackgroundExtension
    AgoraRTC.registerExtensions([extension])

    return extension
  })()

  return virtualBackgroundPromise
}
