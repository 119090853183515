import React, { useEffect } from 'react'
import { useRemoteParticipantAudioTrack } from 'sierra-client/components/liveV2/services/video-call-service/hooks/use-remote-participant-track'

export const AudioPlayerForId: React.FC<{ agoraUid: string }> = ({ agoraUid }) => {
  const audioTrack = useRemoteParticipantAudioTrack(agoraUid)

  useEffect(() => {
    if (audioTrack) {
      audioTrack.play()
    }
    return () => {
      audioTrack?.stop()
    }
  }, [audioTrack])

  return null
}
