import React, { createContext, useCallback, useContext, useMemo, useState } from 'react'
import { ToggleHandlers, useToggle } from 'sierra-client/hooks/use-toggle'

export type State = 'chat_open' | 'auto_summary_open' | 'live_transcription_open' | 'list_open' | 'debug_open'

type RightSidebarContext = {
  isOpen: boolean
  state: State
  setState: (state: State) => void
  toggleState: (state: State) => void
  close: () => void
}

type LeftSidebarContext = {
  isOpen: boolean
  handlers: ToggleHandlers
}

const RightSidebarContext = createContext<RightSidebarContext | undefined>(undefined)
const LeftSidebarContext = createContext<LeftSidebarContext | undefined>(undefined)

export const LiveSidebarProvider = (props: { children: React.ReactNode }): JSX.Element => {
  const [state, setState] = useState<State>('chat_open')
  const [isOpen, setIsOpen] = useState(false)

  const close = useCallback(() => setIsOpen(false), [setIsOpen])
  const setNewState = useCallback((newState: State) => {
    setState(newState)
    setIsOpen(true)
  }, [])
  const toggleState = useCallback(
    (newState: State) => {
      if (state === newState && isOpen) setIsOpen(false)
      else {
        setState(newState)
        setIsOpen(true)
      }
    },
    [state, isOpen]
  )

  const [leftIsOpen, showLeftSidebarHandlers] = useToggle()

  const rightSidebarValue = useMemo(
    () => ({
      isOpen,
      close,
      setState: setNewState,
      toggleState,
      state,
    }),
    [isOpen, close, setNewState, toggleState, state]
  )

  const leftSidebarValue = useMemo(
    () => ({
      isOpen: leftIsOpen,
      handlers: showLeftSidebarHandlers,
    }),
    [leftIsOpen, showLeftSidebarHandlers]
  )

  return (
    <RightSidebarContext.Provider value={rightSidebarValue}>
      <LeftSidebarContext.Provider value={leftSidebarValue}>{props.children}</LeftSidebarContext.Provider>
    </RightSidebarContext.Provider>
  )
}

export const useRightSidebarContext = (): RightSidebarContext => {
  const context = useContext(RightSidebarContext)
  if (context === undefined) {
    throw new Error('This component must be wrapped in a LiveSidebarProvider')
  }
  return context
}
