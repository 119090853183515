import React, { useMemo, useState } from 'react'
import { EditorTextActionsContext } from 'sierra-client/features/text-actions/editor-text-actions-context'
import { EditorTextActionsModal } from 'sierra-client/features/text-actions/editor-text-actions-modal'
import {
  EditorTextActionsContextValue,
  EditorTextActionsState,
} from 'sierra-client/features/text-actions/types'
import { LightTokenProvider } from 'sierra-ui/theming'

export const EditorTextActionsProvider: React.FC<{ children: React.ReactNode }> = ({ children }) => {
  const [state, setState] = useState<EditorTextActionsState | undefined>(undefined)

  const editorTextActionsState = useMemo<EditorTextActionsContextValue>(() => {
    return {
      editorTextActionsState: state,
      setEditorTextActionsState: setState,
    }
  }, [state])

  return (
    <EditorTextActionsContext.Provider value={editorTextActionsState}>
      <LightTokenProvider>
        <EditorTextActionsModal
          data={state !== undefined ? { editorTextActionsState: state } : undefined}
          onClose={() => setState(undefined)}
        />
      </LightTokenProvider>
      {children}
    </EditorTextActionsContext.Provider>
  )
}
