import { useMemo } from 'react'
import { useInsightsViews } from 'sierra-client/features/insights/api-hooks/use-views'
import { toLabel } from 'sierra-client/features/insights/display-widgets/utils'
import { getViewIconFromViewRef } from 'sierra-client/features/insights/widget-builder/data-selectors/view-icon-map'
import { useTranslation } from 'sierra-client/hooks/use-translation'
import { labelToString } from 'sierra-client/lib/tabular/datatype/label'
import { ViewRef, areViewRefsEqual } from 'sierra-domain/api/insights'
import { MenuItem } from 'sierra-ui/components'
import { SingleSelectDropdown } from 'sierra-ui/primitives/menu-dropdown'

export type MenuItemWithRef = MenuItem & { view?: ViewRef }

export const ViewSelector: React.FC<{ selectedView?: ViewRef; onChange: (view: ViewRef) => void }> = ({
  selectedView,
  onChange,
}) => {
  const { t } = useTranslation()

  const views = useInsightsViews()

  const menuItems = useMemo(
    () =>
      (views.data?.views ?? [])
        .filter(view => view.listSpec?.type === 'list.default-dimensions')
        .map(
          view =>
            ({
              id: view.alias,
              label: labelToString(toLabel(view.label), t),
              type: 'label',
              view: view.id,
              icon: getViewIconFromViewRef(view.id),
              description:
                view.description !== undefined ? labelToString(toLabel(view.description), t) : undefined,
            }) satisfies MenuItemWithRef
        ),
    [views, t]
  )

  return (
    <SingleSelectDropdown
      placeholder={t('manage.insights.select-view.placeholder')}
      bold
      selectedItem={
        selectedView !== undefined
          ? menuItems.find(menuItem => areViewRefsEqual(menuItem.view, selectedView))
          : undefined
      }
      menuItems={menuItems}
      onSelect={(event: MenuItemWithRef) => {
        const view = ViewRef.parse(event.view)
        onChange(view)
      }}
    />
  )
}
