import { createFileRoute } from '@tanstack/react-router'

import { PageIdentifier } from 'sierra-client/layout/sana-page'
import { useInsightsEnabled } from 'sierra-client/views/manage/hooks/use-insights-enabled'
import { ManageOverview } from 'sierra-client/views/manage/overview/manage-overview'
import { ManagePageWrapper } from 'sierra-client/views/manage/utils/manage-page-wrapper'

// Note: ManagePageWrapper handles additional auth checks.
function ManageReportsPage(): JSX.Element | null {
  const insightsEnabled = useInsightsEnabled()

  if (!insightsEnabled) {
    return null
  }

  return (
    <ManagePageWrapper pageIdentifier={PageIdentifier.ManageReports()}>
      <ManageOverview />
    </ManagePageWrapper>
  )
}

const RouteComponent = ManageReportsPage

export const Route = createFileRoute('/manage/reports')({
  component: RouteComponent as React.FC,
})
