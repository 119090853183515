import React from 'react'
import { WidgetWithDashboardMetadata } from 'sierra-client/features/insights/widget-templates/hardcoded-templates'
import { useTranslation } from 'sierra-client/hooks/use-translation'
import { Icon } from 'sierra-ui/components'
import { Button, Spacer, Text, View } from 'sierra-ui/primitives'
import styled from 'styled-components'

const StartScreenText = styled(Text)`
  color: ${p => p.theme.color.grey40};
  text-align: center;
  margin-bottom: 0.5rem;
`

const AddWidgetsHelpersContainer = styled(View)`
  height: 65vh;
`

export const AddWidgetsHelpers: React.FC<{ onSelect: (widget?: WidgetWithDashboardMetadata) => void }> = ({
  onSelect,
}) => {
  const { t } = useTranslation()

  return (
    <AddWidgetsHelpersContainer
      direction='column'
      grow
      alignItems='center'
      justifyContent='center'
      padding='xlarge'
    >
      <Icon iconId='chart--column' />
      <Spacer size='xxsmall' />
      <Text size='regular' bold color='foreground/primary'>
        {t('manage.insights.startscreen.header')}
      </Text>
      <StartScreenText color='foreground/primary' lineLength={35} size='small'>
        {t('manage.insights.startscreen.text')}
      </StartScreenText>
      <Spacer size='xxsmall' />
      <Button variant='ghost' onClick={() => onSelect(undefined)}>
        {t('manage.insights.dashboard.new.widget')}
      </Button>
    </AddWidgetsHelpersContainer>
  )
}
