import { UseQueryResult } from '@tanstack/react-query'
import { graphql } from 'sierra-client/api/graphql/gql'
import {
  CertificateDetailsQuery,
  IssuedCertificateQuery,
  IssuedCertificateSupportingFileQuery,
  IssuedCertificatesByUserQuery,
} from 'sierra-client/api/graphql/gql/graphql'
import { useGraphQuery } from 'sierra-client/api/hooks/use-graphql-query'

const certificateDetailsQuery = graphql(`
  query certificateDetails($certificateId: CertificateId!) {
    certificate(id: $certificateId) {
      id
      title
      analytics {
        active
        expiring(inDays: 30)
      }
      connectedCourses {
        __typename
        courseId
        title
        courseKind
        description
        image {
          ...ImageFragment
        }
      }
      connectedPrograms {
        id
        name
        description
        image {
          ...ImageFragment
        }
      }
      disabledAt
    }
  }
`)

export const useCertificateDetails = (
  certificateId: string
): UseQueryResult<CertificateDetailsQuery, unknown> => {
  const queryResult = useGraphQuery(
    {
      document: certificateDetailsQuery,
      queryOptions: { staleTime: 60 * 1000, refetchOnWindowFocus: false, refetchOnMount: true, gcTime: 0 },
    },
    { certificateId }
  )
  return queryResult
}

// ---

const issuedCertificatesByUserQuery = graphql(`
  query issuedCertificatesByUser($certificateId: CertificateId!) {
    issuedCertificatesByUser(certificateId: $certificateId) {
      userId
      issuedToUser {
        id
        email
        status
        displayName
        avatar: avatarFilename
      }
      issuedCertificates {
        id
        expiresAt
        issuedAt
        revokedAt
        issuedByUser {
          id
          displayName
          avatar {
            ...AvatarFragment
          }
        }
        issuedByContent {
          contentId
          title
        }
        issuedByProgram {
          id
          name
        }
        hasSupportingFile
        supportingNote
      }
    }
  }
`)

export const useIssuedCertificatesByUser = (
  certificateId: string
): UseQueryResult<IssuedCertificatesByUserQuery, unknown> => {
  const queryResult = useGraphQuery(
    {
      document: issuedCertificatesByUserQuery,
      queryOptions: { staleTime: 60 * 1000, refetchOnWindowFocus: false, refetchOnMount: true, gcTime: 0 },
    },
    { certificateId }
  )
  return queryResult
}

const issuedCertificateQuery = graphql(`
  query issuedCertificate($certificateId: IssuedCertificateId!) {
    issuedCertificate(id: $certificateId) {
      id
      snapshotImageUrl
      pdfUrl
      supportingFileUrl
      certificate {
        templateData {
          orientation
        }
      }
    }
  }
`)

export const useIssuedCertificate = (
  certificateId?: string
): UseQueryResult<IssuedCertificateQuery, unknown> => {
  const queryResult = useGraphQuery(
    {
      document: issuedCertificateQuery,
      queryOptions: {
        staleTime: 60 * 1000,
        refetchOnWindowFocus: false,
        refetchOnMount: true,
        gcTime: 0,
        enabled: certificateId !== undefined,
      },
    },
    {
      certificateId:
        // This is ok as we have 'enabled' above
        certificateId as string,
    }
  )
  return queryResult
}

const issuedCertificateSupportingFileQuery = graphql(`
  query issuedCertificateSupportingFile($certificateId: IssuedCertificateId!) {
    issuedCertificate(id: $certificateId) {
      id
      supportingFileUrl
    }
  }
`)

export const useIssuedCertificateSupportingFile = (
  certificateId: string
): UseQueryResult<IssuedCertificateSupportingFileQuery, unknown> => {
  const queryResult = useGraphQuery(
    {
      document: issuedCertificateSupportingFileQuery,
      queryOptions: {
        staleTime: 60 * 1000,
        refetchOnWindowFocus: false,
        refetchOnMount: true,
        gcTime: 0,
      },
    },
    {
      certificateId: certificateId,
    }
  )
  return queryResult
}
