import { useTranslation } from 'sierra-client/hooks/use-translation'
import * as settingsState from 'sierra-client/state/author-course-settings/slice'
import { useSelector } from 'sierra-client/state/hooks'
import { DuplicateTranslatedCourse } from 'sierra-client/views/course-settings/tabs/translate-course'
import { HorizontalSettingsField } from 'sierra-client/views/course-settings/tabs/utils'
import { SettingsTabComponent } from 'sierra-client/views/course-settings/types'

export const TranslateTab: SettingsTabComponent = ({ onClose }) => {
  const { t } = useTranslation()
  const courseId = useSelector(settingsState.selectors.currentCourseId)
  const courseKind = useSelector(settingsState.selectors.selectCourseKind)

  if (courseId === null || courseKind === null) return null

  return (
    <>
      <HorizontalSettingsField
        title={t('dictionary.translate')}
        description={t('course-settings.duplicate-translated-description')}
      >
        {
          <DuplicateTranslatedCourse
            courseId={courseId}
            courseKind={courseKind}
            onTranslated={() => {
              onClose()
            }}
          />
        }
      </HorizontalSettingsField>
    </>
  )
}
