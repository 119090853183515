import { useAtom } from 'jotai'
import React, { useCallback, useEffect, useMemo, useState } from 'react'
import { InputMaybe } from 'sierra-client/api/graphql/gql/graphql'
import { ActionModal } from 'sierra-client/components/common/modals/action-modal'
import { PageTitle } from 'sierra-client/components/common/page-title'
import { useObserveElementSize } from 'sierra-client/hooks/use-observe-element-size'
import { useHasCertPermission, useOrganizationPermissions } from 'sierra-client/hooks/use-permissions'
import { useTranslation } from 'sierra-client/hooks/use-translation'
import { isIssuedCertificateRep } from 'sierra-client/lib/tabular/datatype/internal/reps/issued-certificate-rep'
import { getRowDataFromTableAPI } from 'sierra-client/lib/tabular/utils'
import { CertificatePreviewUrlModal } from 'sierra-client/views/manage/certificates/certificate-preview-url-modal'
import { EditCertificatePanel } from 'sierra-client/views/manage/certificates/edit-certificate-panel'
import { selectedCertificateAtom } from 'sierra-client/views/manage/certificates/edit-certificate-panel/store'
import {
  useEditCertificateTemplate,
  useResponsivePreview,
} from 'sierra-client/views/manage/certificates/edit-certificate-panel/use-edit-certificate'
import { IssueCertificatePanel } from 'sierra-client/views/manage/certificates/issue-certificate-panel'
import { IssuedBySelector } from 'sierra-client/views/manage/certificates/issued-by-selector'
import {
  ManageIssuedCertificatesTabular,
  ManageIssuedCertificatesTabularActions,
} from 'sierra-client/views/manage/certificates/manage-issued-certificates-tabular'
import { PreviewHtmlModal } from 'sierra-client/views/manage/certificates/preview-html-modal'
import { PreviewUrlContainer } from 'sierra-client/views/manage/certificates/preview-url-container'
import { fetchCertificate } from 'sierra-client/views/manage/certificates/use-certificate'
import {
  useCertificateDetails,
  useIssuedCertificate,
  useIssuedCertificateSupportingFile,
  useIssuedCertificatesByUser,
} from 'sierra-client/views/manage/certificates/use-issued-certificates'
import { useRevokeIssuedCertificate } from 'sierra-client/views/manage/certificates/use-revoke-issued-certificate'
import { DetailsHeader } from 'sierra-client/views/manage/components/details-header'
import { ColumnLayout } from 'sierra-client/views/manage/components/layout/column-layout'
import { ManageHeadline } from 'sierra-client/views/manage/components/layout/manage-headline'
import { useTracking } from 'sierra-client/views/workspace/utils/certificates/tracking'
import { CertificateId } from 'sierra-domain/api/nano-id'
import { isNonNullable } from 'sierra-domain/utils'
import { dynamicColor } from 'sierra-ui/color'
import { Button, IconButton, Spacer, Text, View } from 'sierra-ui/primitives'
import { token } from 'sierra-ui/theming'
import { isDefined } from 'sierra-ui/utils/is-defined'
import styled from 'styled-components'

const LazyPreviewUrlModal: React.FC<
  Omit<React.ComponentProps<typeof PreviewUrlContainer>, 'url' | 'downloadUrl' | 'onClick'> & {
    id?: string
    open: boolean
    onClose: () => void
  }
> = ({ id, ...props }) => {
  const result = useIssuedCertificate(id)

  if (result.isPending) return null
  if (result.isError) return null
  if (!isDefined(result.data.issuedCertificate)) return null
  const certificate = result.data.issuedCertificate
  return (
    <View animated={true} direction='column' gap='16' padding='8'>
      <CertificatePreviewUrlModal
        {...props}
        url={certificate.snapshotImageUrl}
        downloadUrl={certificate.pdfUrl}
        supportingFileUrl={certificate.supportingFileUrl}
        displayHeight={certificate.certificate.templateData.orientation === 'VERTICAL' ? 679 : 679}
        displayWidth={certificate.certificate.templateData.orientation === 'VERTICAL' ? 480 : 960}
      />
    </View>
  )
}

const LazyDownloadModal: React.FC<
  Omit<React.ComponentProps<typeof PreviewUrlContainer>, 'url' | 'downloadUrl' | 'onClick'> & {
    id: string
    open: boolean
    onClose: () => void
  }
> = ({ id, ...props }) => {
  const result = useIssuedCertificateSupportingFile(id)

  if (result.isPending) return null
  if (result.isError) return null
  const certificate = result.data.issuedCertificate
  if (!isDefined(certificate?.supportingFileUrl)) return null
  return (
    <View animated={true} direction='column' gap='16' padding='8'>
      <CertificatePreviewUrlModal {...props} url={certificate.supportingFileUrl} />
    </View>
  )
}

const BorderCard = styled(View)`
  flex-direction: column;
  align-items: flex-start;
  border-radius: ${p => p.theme.borderRadius.large};
  border: 1px solid ${token('border/strong')};
  flex-grow: 1;
  flex: 1;
  min-height: 100px;
  overflow: hidden;

  & p {
    min-height: 40px;
  }
`

const Container = styled(View)`
  height: 100%;
`

const NumberText = styled(Text)`
  font-size: 27px;
`

type PreviewContainerProps = {
  $backgroundColor: InputMaybe<string> | undefined
  $width: number
  $height: number
}

const PreviewContainer = styled(View).attrs({ radius: 'size-8', padding: 'none' })<PreviewContainerProps>`
  width: ${p => p.$width}px;
  height: ${p => p.$height}px;
  background-color: ${p =>
    p.$backgroundColor !== undefined && p.$backgroundColor !== null
      ? dynamicColor(p.$backgroundColor).opacity(0.5)
      : token('surface/soft')};
  transition: background-color 100ms cubic-bezier(0.25, 0.1, 0.25, 1) 200ms;
  margin-top: -1.5rem;
  position: relative;
`

const Preview = styled.iframe<{ $backgroundColor: InputMaybe<string> | undefined; $textColor: string }>`
  border-radius: 1.5rem;
  background: ${p => (p.$backgroundColor !== undefined ? p.$backgroundColor : 'grey35')};
  color: ${p => p.$textColor};
  overflow: hidden;
  position: relative;
  left: 50%;
  top: 50%;
  flex-shrink: 0;
  transition:
    background-color 100ms cubic-bezier(0.25, 0.1, 0.25, 1) 200ms,
    color 100ms cubic-bezier(0.25, 0.1, 0.25, 1) 200ms;
`

const PreviewButton = styled(IconButton)`
  position: absolute;
  right: 10px;
  bottom: 10px;
  border-radius: 50%;
`

type ManageCertificateDetailsProps = {
  certificateId: CertificateId
}

export const ManageCertificateDetails: React.FC<ManageCertificateDetailsProps> = ({ certificateId }) => {
  const { t } = useTranslation()
  const [containerElement, setContainerElement] = useState<HTMLDivElement | null>(null)
  const [previewElement, setPreviewElement] = useState<HTMLIFrameElement | null>(null)
  const [previewContainerElement, setPreviewContainerElement] = useState<HTMLDivElement | null>(null)
  const track = useTracking()

  const orgPermissions = useOrganizationPermissions()
  const hasWriteAccess = orgPermissions.has('ACCESS_ALL_CERTIFICATES')
  const canIssue = useHasCertPermission(certificateId, 'ISSUE')

  const [action, setAction] = useState<
    | { modal: undefined }
    | { modal: 'edit-certificate' }
    | { modal: 'issue-certificate' }
    | { modal: 'lazy-preview-url'; id: string }
    | { modal: 'lazy-download-url'; id: string }
    | { modal: 'preview-html' }
    | { modal: 'confirm-revoke'; issuedCertificateId: string }
  >({
    modal: undefined,
  })

  const { width: containerWidth } = useObserveElementSize(containerElement)

  // size-stop is used to inform the size of some elements of the page,
  // defined as 1/20th of the width of the container
  const stopWidth = containerWidth > 0 ? containerWidth / 20 : 50
  const sidebarWidth = Math.min(390, stopWidth * 5)

  const previewSize = useMemo(
    () => ({
      width: sidebarWidth,
      // 0.707 is the aspect ratio of A4 paper
      height: sidebarWidth * 0.707,
    }),
    [sidebarWidth]
  )

  const revokeIssuedCertificate = useRevokeIssuedCertificate()

  const [certificate, setCertificate] = useAtom(selectedCertificateAtom)

  // Load certificate data
  const { data: certificateData, refetch: refetchCertificate } = useCertificateDetails(certificateId)
  const fetchedCertificate = certificateData?.certificate

  // Load issued certificates
  const { data: issuedCertificatesByUserData, refetch: refetchIssuedCertificates } =
    useIssuedCertificatesByUser(certificateId)

  const refetchAll = useCallback(
    () => Promise.all([refetchCertificate(), refetchIssuedCertificates()]),
    [refetchCertificate, refetchIssuedCertificates]
  )

  useResponsivePreview({
    previewElement,
    previewContainerElement,
  })

  const { renderedTemplate, fetchRenderedTemplate, fullWidth, fullHeight } =
    useEditCertificateTemplate(certificate)

  useEffect(() => {
    const fetch = async (): Promise<void> => {
      const certificate = await fetchCertificate(certificateId)
      if (certificate !== undefined && certificate !== null) {
        await fetchRenderedTemplate(certificate)
        setCertificate(certificate)
      }
    }
    void fetch()
  }, [certificateId, setCertificate, fetchRenderedTemplate])

  const flipOrientation = stopWidth < 50

  const tabularActions: ManageIssuedCertificatesTabularActions =
    React.useMemo<ManageIssuedCertificatesTabularActions>(
      () => ({
        onViewIssuedCertificate: (id, api) => {
          const issuedCertificateData = getRowDataFromTableAPI(api, id)?.name.data
          if (isIssuedCertificateRep(issuedCertificateData)) {
            setAction({
              modal: 'lazy-preview-url',
              id: id,
            })
          }
        },
        onViewSupportingDoc: (id, api) => {
          const issuedCertificateData = getRowDataFromTableAPI(api, id)?.name.data
          if (isIssuedCertificateRep(issuedCertificateData)) {
            if (issuedCertificateData.hasSupportingFile) {
              setAction({
                modal: 'lazy-download-url',
                id: id,
              })
            }
          }
        },
        onRevokeCertificate: (id, api) => {
          const issuedCertificateData = getRowDataFromTableAPI(api, id)?.name.data
          if (isIssuedCertificateRep(issuedCertificateData)) {
            setAction({
              modal: 'confirm-revoke',
              issuedCertificateId: issuedCertificateData.id,
            })
          }
        },
      }),
      []
    )

  const isCertificateDeleted = isNonNullable(fetchedCertificate?.disabledAt)

  const pageTitle = [
    fetchedCertificate?.title,
    isCertificateDeleted && `(${t('manage.certificates.deleted')})`,
  ]
    .filter(Boolean)
    .join(' ')

  return (
    <>
      <PageTitle title={pageTitle} />

      <ColumnLayout gap='none'>
        <DetailsHeader backlink={{ href: '/manage/certificates', label: 'manage.backlinks--certificates' }} />
        <ManageHeadline>{pageTitle}</ManageHeadline>

        <Container alignItems='flex-start' gap='large' ref={setContainerElement}>
          <Container direction='column' grow>
            <ManageIssuedCertificatesTabular
              certificateId={certificateId}
              certificateTitle={certificate.title}
              actions={tabularActions}
              issuedCertificatesByUser={issuedCertificatesByUserData?.issuedCertificatesByUser}
            />
          </Container>

          <View direction='column' gap={'small'} style={{ width: sidebarWidth }}>
            <View justifyContent='flex-end'>
              {hasWriteAccess && (
                <Button
                  variant='secondary'
                  disabled={isCertificateDeleted}
                  onClick={() => setAction({ modal: 'edit-certificate' })}
                >
                  {t('dictionary.edit')}
                </Button>
              )}
              {canIssue && (
                <Button
                  variant='secondary'
                  disabled={isCertificateDeleted}
                  onClick={() => setAction({ modal: 'issue-certificate' })}
                >
                  {t('manage.certificates.issued-action')}
                </Button>
              )}
            </View>
            <View direction='column' gap='small'>
              <Spacer size='8' />
              <PreviewContainer
                $backgroundColor={certificate.templateData.backgroundColor}
                $width={previewSize.width}
                $height={previewSize.height}
                ref={setPreviewContainerElement}
              >
                <Preview
                  $backgroundColor={certificate.templateData.backgroundColor}
                  $textColor={certificate.templateData.textColor}
                  srcDoc={renderedTemplate}
                  width={fullWidth}
                  height={fullHeight}
                  ref={setPreviewElement}
                />
                <PreviewButton
                  variant='primary'
                  iconId='expand'
                  onClick={() => setAction({ modal: 'preview-html' })}
                />
              </PreviewContainer>
              <View direction={flipOrientation ? 'column' : 'row'}>
                <BorderCard padding='xsmall'>
                  <Text bold>{t('manage.certificates.issued-active')}</Text>
                  <NumberText size='large'>{fetchedCertificate?.analytics.active}</NumberText>
                </BorderCard>
                <BorderCard padding='xsmall'>
                  <Text bold>{t('manage.certificates.issued-expiring-30-days')}</Text>
                  <NumberText size='large'>{fetchedCertificate?.analytics.expiring}</NumberText>
                </BorderCard>
              </View>
              <Spacer size='8' />
              <View direction='column' alignItems={'flex-start'} grow paddingBottom='medium'>
                <IssuedBySelector
                  afterSubmit={refetchCertificate}
                  certificateId={certificateId}
                  disabled={isCertificateDeleted}
                />
              </View>
            </View>
          </View>
        </Container>
        <EditCertificatePanel
          open={action.modal === 'edit-certificate'}
          onClose={() => setAction({ modal: undefined })}
          editedId={certificateId}
          afterSubmit={() => {
            void refetchCertificate()
            track.edited(certificateId)
          }}
        />
        <IssueCertificatePanel
          open={action.modal === 'issue-certificate'}
          onClose={() => setAction({ modal: undefined })}
          certificateId={certificateId}
          afterSubmit={refetchAll}
        />
        <LazyPreviewUrlModal
          id={action.modal === 'lazy-preview-url' ? action.id : undefined}
          open={action.modal === 'lazy-preview-url'}
          onClose={() => setAction({ modal: undefined })}
          onShare={() => track.linkedin.share(certificateId)}
          onDownload={() => track.download.click(certificateId)}
        />
        {action.modal === 'lazy-download-url' && isDefined(action.id) && (
          <LazyDownloadModal
            id={action.id}
            open
            onClose={() => setAction({ modal: undefined })}
            onShare={() => track.linkedin.share(certificateId)}
            onDownload={() => track.download.click(certificateId)}
          />
        )}
        <PreviewHtmlModal
          open={action.modal === 'preview-html'}
          onClose={() => setAction({ modal: undefined })}
          fullWidth={fullWidth}
          fullHeight={fullHeight}
          src={renderedTemplate}
        />
        <ActionModal
          title={t('manage.certificates.revoke.modal-title')}
          open={action.modal === 'confirm-revoke'}
          onClose={() => setAction({ modal: undefined })}
          primaryActionLabel={t('manage.certificates.revoke.modal-action')}
          primaryAction={async () => {
            const id = action.modal === 'confirm-revoke' ? action.issuedCertificateId : ''
            await revokeIssuedCertificate(id)
            await refetchAll()
          }}
        >
          {t('manage.certificates.revoke.modal-text')}
        </ActionModal>
      </ColumnLayout>
    </>
  )
}
