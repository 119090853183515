import { AnimatePresence, MotionProps, motion } from 'framer-motion'
import { useSetAtom } from 'jotai'
import React, { useCallback, useEffect, useMemo, useState } from 'react'
import { isDesktop } from 'react-device-detect'
import { EmojiPickerPopupProvider, useEmojiPicker } from 'sierra-client/components/common/emoji'
import { Notification } from 'sierra-client/components/common/notifications'
import { useIsFacilitatorOrLearnerLedSession } from 'sierra-client/components/liveV2/hooks/use-is-facilitator-or-learner-led-session'
import { useLeaveModal } from 'sierra-client/components/liveV2/hooks/use-leave-modal'
import { useLiveContext } from 'sierra-client/components/liveV2/live-context'
import { liveToolbarAtom } from 'sierra-client/components/liveV2/live-layer/live-toolbar-ref'
import { ToolbarItem } from 'sierra-client/components/liveV2/live-layer/toolbar-item'
import { useLiveSessionIdContext } from 'sierra-client/components/liveV2/live-session-id-provider'
import {
  getLiveAudioShortcutHint,
  getLiveVideoShortcutHint,
} from 'sierra-client/components/liveV2/services/video-call-service/hooks/use-live-keyboard-shortcuts'
import { ShortcutMenu } from 'sierra-client/components/shortcut-menu'
import { Logging } from 'sierra-client/core/logging'
import { useTranslation } from 'sierra-client/hooks/use-translation'
import { sendEmojiMessage } from 'sierra-client/state/chat/actions'
import { useDispatch, useSelector } from 'sierra-client/state/hooks'
import {
  selectLiveSessionIsMyHandRaised,
  selectLiveSessionRaisedHands,
  selectScreenShareParticipant,
  selectUsersPositionInRaisedHandQueue,
} from 'sierra-client/state/live-session/selectors'
import { liveSessionSlice } from 'sierra-client/state/live-session/slice'
import {
  disableVideo,
  enableVideo,
  muteAudio,
  startScreenSharing,
  stopScreenSharing,
  unmuteAudio,
} from 'sierra-client/state/live/actions'
import { LiveIssueCategory } from 'sierra-client/state/live/live-issue-information'
import {
  selectAudioState,
  selectCameraPermission,
  selectIsScreenSharing,
  selectMicrophonePermission,
  selectRecoverableIssues,
  selectVideoState,
} from 'sierra-client/state/live/selectors'
import { selectUser, selectUserId } from 'sierra-client/state/user/user-selector'
import { useUsersLegacy } from 'sierra-client/state/users/hooks'
import { SettingsModal } from 'sierra-client/views/liveV2/settings/settings'
import { ChatMessageId, uuid } from 'sierra-domain/api/uuid'
import { ScopedChatId } from 'sierra-domain/collaboration/types'
import { color } from 'sierra-ui/color'
import { Icon, IconId, IconProps } from 'sierra-ui/components'
import { IconButton, Text } from 'sierra-ui/primitives'
import { palette } from 'sierra-ui/theming'
import { maxWidth } from 'sierra-ui/utils/media-query-styles'
import styled from 'styled-components'

const ToolbarContainer = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  margin: 32px;
  z-index: 1;
  pointer-events: auto;

  ${maxWidth.phone} {
    margin: 1rem;
  }
`

const HangupButton = styled(IconButton)`
  background-color: ${p => p.theme.color.redVivid};
  height: 2.5rem;
  width: 4rem;
  border-radius: 100px;
  margin-left: 1rem;

  &:hover {
    background-color: ${p => color(p.theme.color.redVivid).shift(-0.2).toString()};
  }
`

const HandRaisedQueuePosition = styled(Text)`
  color: #755f17;
`

const SettingsButtonWrapper = styled.div`
  position: relative;
`

const NotificationWrapper = styled.div`
  position: absolute;
  top: -3rem;
  left: -6rem;
  display: flex;
  width: 18rem;
  cursor: pointer;
`

const SettingsButtonDot = styled.div`
  position: absolute;
  top: -0.3rem;
  right: -0.1rem;
  width: 0.75rem;
  height: 0.75rem;
  background-color: ${p => p.theme.color.orangeLight};
  border-radius: ${p => p.theme.borderRadius.round};
`

const RaiseHandButton: React.FC = () => {
  const dispatch = useDispatch()
  const userId = useSelector(selectUserId)

  const isMyHandRaised = useSelector(selectLiveSessionIsMyHandRaised)
  const raisedHands = useSelector(selectLiveSessionRaisedHands)
  const handRaiseOrder = useSelector(state => selectUsersPositionInRaisedHandQueue(state, userId))
  const { t } = useTranslation()

  const active = isMyHandRaised

  const icon = <Icon iconId='hand' size='size-16' color='black' />
  const activeIcon =
    raisedHands.length > 1 && handRaiseOrder !== undefined ? (
      <HandRaisedQueuePosition color='LEGACY_DEFAULT_TEXT_COLOR_REPLACE_ASAP' size='micro' bold>
        {handRaiseOrder}
      </HandRaisedQueuePosition>
    ) : (
      <Icon iconId='hand' size='size-16' color={color('#755F17')} />
    )
  const hoverIcon = active ? <Icon iconId='hand--off' size='size-16' color={color('#755F17')} /> : icon

  const raiseHand = useCallback(() => {
    void dispatch(liveSessionSlice.actions.toggleRaiseHand())
  }, [dispatch])

  const isOn = useCallback(() => active, [active])

  const raiseYourHand = t('live.raise-your-hand')
  const lowerYourHand = t('live.lower-your-hand')
  return (
    <div id='tour-live-handraise'>
      <ToolbarItem
        pfcid='hand-raise-button'
        active={active}
        icon={icon}
        activeIcon={activeIcon}
        activeColor='#FFED3E'
        hoverIcon={hoverIcon}
        onClick={raiseHand}
        tooltip={active ? lowerYourHand : raiseYourHand}
      />
      <ShortcutMenu.Toggle
        label={{ type: 'untranslated', value: active ? lowerYourHand : raiseYourHand }}
        group='live'
        permission='ACCESS_LMS'
        iconId={active ? 'hand' : 'hand--off'}
        isOn={isOn}
        toggle={raiseHand}
      />
    </div>
  )
}

const EmojiInputButtonContainer = styled.div`
  position: relative;
`

const EmojiInputContainer = styled(motion.div)`
  position: absolute;
  transform: translateY(calc(-100%)) translateX(-0.375rem);
`

const EmojiInputInnerContainer = styled.div`
  border-radius: 12px;
  border: 1px solid;
  border-color: ${palette.grey[5]};
  box-shadow: 0px 4px 12px rgba(0, 0, 0, 0.04);
  background-color: rgba(255, 255, 255, 1);
  backdrop-filter: blur(40px);
  width: 3.5rem;
  display: flex;
  flex-direction: column;
`

const EmojiInputSpacing = styled.div`
  height: 1rem;
`

const EmojiButton = styled(motion.div)`
  display: flex;
  height: 3rem;
  justify-content: center;
  align-items: center;
  cursor: pointer;
  overflow: hidden;
  text-align: center;
  font-size: 1.125rem;
  transform: scale(1);
  will-change: transform;
  /* transition: transform 0.1s cubic-bezier(0.25, 0.1, 0.25, 1); */
  user-select: none;

  /* &:hover {
              transform: scale(1.4);
            } */

  &:last-child {
    border-bottom-left-radius: 0.5rem;
    border-bottom-right-radius: 0.5rem;
  }

  &:first-child {
    border-top-left-radius: 0.5rem;
    border-top-right-radius: 0.5rem;
  }
`

const EmojiInputButton: React.FC<{ chatId: ScopedChatId; container: HTMLElement | null }> = ({
  chatId,
  container,
}) => {
  const dispatch = useDispatch()
  const userId = useSelector(selectUserId)
  const { t } = useTranslation()

  const [open, setOpen] = useState(false)

  const { open: openEmojiPicker, isOpen: isEmojiPickerOpen, close: closeEmojiPicker } = useEmojiPicker()

  const send = (emoji: string) => (): void => {
    if (userId === undefined) return
    const id = ChatMessageId.parse(uuid())
    void dispatch(sendEmojiMessage({ id, chatId, threadId: 'root', userId, emoji: emoji }))
    setOpen(false)
  }

  const onEmojiSelected = (emoji: string): void => {
    if (userId === undefined) return
    const id = ChatMessageId.parse(uuid())
    void dispatch(sendEmojiMessage({ id, chatId, threadId: 'root', userId, emoji: emoji }))
  }

  const toggleEmojiPicker = (): void => {
    if (isEmojiPickerOpen) {
      closeEmojiPicker()
    } else if (container) {
      openEmojiPicker(container, ({ emoji }) => onEmojiSelected(emoji))
    }
  }

  return (
    <EmojiInputButtonContainer
      id='tour-live-emoji-reaction'
      onMouseEnter={() => setOpen(true)}
      onMouseLeave={() => setOpen(false)}
      onClick={() => setOpen(open => !open)}
    >
      <AnimatePresence>
        {open && (
          <EmojiInputContainer
            initial={{ opacity: 0 }}
            animate={{ opacity: 1 }}
            exit={{ opacity: 0 }}
            transition={{ duration: 0.15 }}
          >
            <EmojiInputInnerContainer>
              <EmojiButton
                role='button'
                onClick={send('👋')}
                whileHover={{ scale: 1.2 }}
                whileTap={{ scale: 1 }}
                transition={{
                  type: 'spring',
                  stiffness: 500,
                }}
              >
                👋
              </EmojiButton>
              <EmojiButton
                role='button'
                onClick={send('👏')}
                whileHover={{ scale: 1.2 }}
                whileTap={{ scale: 1 }}
                transition={{
                  type: 'spring',
                  stiffness: 500,
                }}
              >
                👏
              </EmojiButton>
              <EmojiButton
                role='button'
                onClick={send('👍')}
                whileHover={{ scale: 1.2 }}
                whileTap={{ scale: 1 }}
                transition={{
                  type: 'spring',
                  stiffness: 500,
                }}
              >
                👍
              </EmojiButton>
              <EmojiButton
                role='button'
                onClick={send('🎉')}
                whileHover={{ scale: 1.2 }}
                whileTap={{ scale: 1 }}
                transition={{
                  type: 'spring',
                  stiffness: 500,
                }}
              >
                🎉
              </EmojiButton>
              <EmojiButton
                role='button'
                onClick={send('😄')}
                whileHover={{ scale: 1.2 }}
                whileTap={{ scale: 1 }}
                transition={{
                  type: 'spring',
                  stiffness: 500,
                }}
              >
                😄
              </EmojiButton>
              <EmojiButton
                role='button'
                onClick={send('❤️')}
                whileHover={{ scale: 1.2 }}
                whileTap={{ scale: 1 }}
                transition={{
                  type: 'spring',
                  stiffness: 500,
                }}
              >
                ❤️
              </EmojiButton>
              <EmojiButton
                role='button'
                onClick={send('🌟')}
                whileHover={{ scale: 1.2 }}
                whileTap={{ scale: 1 }}
                transition={{
                  type: 'spring',
                  stiffness: 500,
                }}
              >
                🌟
              </EmojiButton>
              <EmojiButton
                role='button'
                whileHover={{ scale: 1.2 }}
                whileTap={{ scale: 1 }}
                transition={{
                  type: 'spring',
                  stiffness: 500,
                }}
                onClick={toggleEmojiPicker}
              >
                <Icon size='size-14' iconId='face--add' color='grey45' />
              </EmojiButton>
            </EmojiInputInnerContainer>
            <EmojiInputSpacing />
          </EmojiInputContainer>
        )}
      </AnimatePresence>

      <ToolbarItem
        active={false}
        icon={<Icon iconId='face' size='size-14' color='black' />}
        tooltip={t('dictionary.react')}
      />
    </EmojiInputButtonContainer>
  )
}

const ScreenShareButton: React.FC = () => {
  const dispatch = useDispatch()
  const isScreenSharing = useSelector(selectIsScreenSharing)

  const screenSharingParticipant = useSelector(selectScreenShareParticipant)
  const isFacilitatorOrLearnerLedSession = useIsFacilitatorOrLearnerLedSession()
  const user = useSelector(selectUser)
  const { t } = useTranslation()

  const [screenSharingUser] = useUsersLegacy(
    screenSharingParticipant?.userId !== undefined ? [screenSharingParticipant.userId] : []
  )

  const toggleScreenShare = useCallback((): void => {
    if (isScreenSharing) void dispatch(stopScreenSharing())
    else void dispatch(startScreenSharing())
  }, [isScreenSharing, dispatch])

  const stopRemoteUserScreenShare = (): void => {
    if (screenSharingParticipant === undefined) return
    void dispatch(
      liveSessionSlice.actions.publishControlEvent({
        userId: screenSharingParticipant.userId,
        userAction: 'stop_screen_share',
      })
    )
  }

  if (
    screenSharingParticipant &&
    screenSharingUser &&
    screenSharingParticipant.userId !== user?.uuid &&
    isFacilitatorOrLearnerLedSession
  ) {
    const label = `Stop ${screenSharingUser.firstName} presenting`
    return (
      <>
        <ToolbarItem
          active={true}
          activeColor='#0558F9'
          activeIcon={<Icon iconId='share-screen--stop--filled' size='size-14' color='white' />}
          icon={<Icon iconId='share-screen' size='size-14' color='black' />}
          onClick={stopRemoteUserScreenShare}
          activeTooltip={label}
        />
        <ShortcutMenu.Action
          group='live'
          permission='ACCESS_LMS'
          iconId='share-screen--stop--filled'
          label={{ type: 'untranslated', value: label }}
          run={stopRemoteUserScreenShare}
        />
      </>
    )
  }

  const disabled = !!screenSharingParticipant && screenSharingParticipant.userId !== user?.uuid

  const tooltip = disabled ? t('live.someone-is-already-presenting') : t('live.start-presenting')

  return (
    <>
      <ToolbarItem
        active={isScreenSharing}
        activeColor='#FF5A50'
        activeIcon={<Icon iconId='share-screen--stop--filled' size='size-14' color='white' />}
        icon={<Icon iconId='share-screen' size='size-14' color='black' />}
        onClick={toggleScreenShare}
        tooltip={tooltip}
        activeTooltip={t('live.stop-presenting')}
        disabled={disabled}
      />
      {!disabled && (
        <ShortcutMenu.Action
          iconId={isScreenSharing ? 'share-screen--stop--filled' : 'share-screen'}
          label={{ type: 'untranslated', value: isScreenSharing ? 'Stop presenting' : 'Start presenting' }}
          group='live'
          permission='ACCESS_LMS'
          run={toggleScreenShare}
        />
      )}
    </>
  )
}

const VideoButton: React.FC = () => {
  const videoState = useSelector(selectVideoState)
  const cameraPermission = useSelector(selectCameraPermission)
  const dispatch = useDispatch()
  const { t } = useTranslation()
  const supportsLiveKeyboardShortcuts = isDesktop

  const getTooltipText = useMemo(() => {
    if (supportsLiveKeyboardShortcuts) {
      return `${t('live.turn-off-camera')} (${getLiveVideoShortcutHint()})`
    } else {
      return t('live.turn-off-camera')
    }
  }, [supportsLiveKeyboardShortcuts, t])

  const toggleVideo = useCallback(() => {
    if (videoState === 'on') {
      void dispatch(disableVideo())
    } else {
      void dispatch(enableVideo())
    }
  }, [dispatch, videoState])

  const activeTooltip = useMemo(() => {
    if (videoState !== 'unavailable') {
      if (supportsLiveKeyboardShortcuts) {
        return `${t('live.turn-on-camera')} (${getLiveVideoShortcutHint()})`
      }
      return t('live.turn-on-camera')
    }
    if (cameraPermission === 'denied') return t('live.camera-permission-denied')
    return t('live.camera-unavailable')
  }, [videoState, cameraPermission, t, supportsLiveKeyboardShortcuts])

  return (
    <>
      <ToolbarItem
        pfcid='video-button'
        active={videoState !== 'on'}
        activeColor='#FF5A50'
        activeIcon={<Icon iconId='video--off' size='size-14' color='white' />}
        icon={<Icon iconId='video' size='size-14' color='black' />}
        onClick={toggleVideo}
        tooltip={getTooltipText}
        activeTooltip={activeTooltip}
      />
      {videoState !== 'unavailable' && (
        <ShortcutMenu.Toggle
          iconId='video'
          permission='ACCESS_LMS'
          group='live'
          isOn={() => videoState === 'on'}
          label={{
            type: 'untranslated',
            value: videoState === 'on' ? 'Turn off camera' : 'Turn on camera',
          }}
          toggle={toggleVideo}
        />
      )}
    </>
  )
}

const AudioButton: React.FC = () => {
  const audioState = useSelector(selectAudioState)
  const microphonePermission = useSelector(selectMicrophonePermission)
  const dispatch = useDispatch()
  const { t } = useTranslation()
  const supportsLiveKeyboardShortcuts = isDesktop

  const toggleAudioMuted = useCallback(async () => {
    if (audioState === 'on') await dispatch(muteAudio())
    else await dispatch(unmuteAudio())
  }, [audioState, dispatch])

  const activeTooltip = useMemo(() => {
    if (audioState !== 'unavailable') {
      if (supportsLiveKeyboardShortcuts) {
        return `${t('live.turn-on-microphone')} (${getLiveAudioShortcutHint()})`
      }
      return t('live.turn-on-microphone')
    }
    if (microphonePermission === 'denied') return t('live.microphone-permission-denied')
    return t('live.microphone-unavailable')
  }, [audioState, supportsLiveKeyboardShortcuts, microphonePermission, t])

  const getTooltipText = useMemo(() => {
    if (supportsLiveKeyboardShortcuts) {
      return `${t('live.turn-off-microphone')} (${getLiveAudioShortcutHint()})`
    } else {
      return t('live.turn-off-microphone')
    }
  }, [supportsLiveKeyboardShortcuts, t])

  return (
    <>
      <ToolbarItem
        pfcid='audio-button'
        active={audioState !== 'on'}
        activeColor='#FF5A50'
        activeIcon={<Icon iconId='microphone--off' size='size-14' color='white' />}
        icon={<Icon iconId='microphone' size='size-14' color='black' />}
        onClick={toggleAudioMuted}
        tooltip={getTooltipText}
        activeTooltip={activeTooltip}
      />
      {audioState !== 'unavailable' && (
        <ShortcutMenu.Toggle
          iconId='microphone'
          permission='ACCESS_LMS'
          group='live'
          isOn={() => audioState === 'on'}
          label={{
            type: 'untranslated',
            value: audioState === 'on' ? 'Mute microphone' : 'Unmute microphone',
          }}
          toggle={toggleAudioMuted}
        />
      )}
    </>
  )
}

const ISSUE_ICON: Record<LiveIssueCategory, IconId> = {
  audio: 'microphone--off',
  video: 'video--off',
  network: 'wifi--off',
}

export const SettingsButton: React.FC<{
  renderedIn: 'pre-lobby' | 'live'
  className?: string
  iconColor?: IconProps['color']
}> = props => {
  const issues = useSelector(selectRecoverableIssues)
  const { t } = useTranslation()
  const [showSettings, setShowSettings] = useState<boolean>(false)

  return (
    <>
      <SettingsModal
        open={showSettings}
        onClose={() => setShowSettings(false)}
        renderedIn={props.renderedIn}
      />
      <SettingsButtonWrapper>
        <ToolbarItem
          className={props.className}
          active={false}
          icon={<Icon iconId='overflow-menu--vertical' size='size-14' color={props.iconColor ?? 'black'} />}
          onClick={() => setShowSettings(true)}
          tooltip={t('dictionary.more-options')}
        />
        {issues[0] && (
          <>
            <SettingsButtonDot />
            <NotificationWrapper onClick={() => setShowSettings(true)}>
              <Notification
                type='custom'
                level='warning'
                body={t(issues[0].shortDescription)}
                icon={ISSUE_ICON[issues[0].category]}
              />
            </NotificationWrapper>
          </>
        )}
      </SettingsButtonWrapper>
    </>
  )
}

export const getToolbarAnimationProps = (isHidden: boolean): MotionProps => ({
  initial: { scale: 0.98, opacity: 1 },
  animate: { scale: isHidden ? 0.98 : 1, opacity: isHidden ? 0 : 1 },
  exit: { scale: 0.98, opacity: 0 },
  transition: { ease: [0.25, 0.1, 0.25, 1], duration: 0.35 },
  whileHover: { opacity: 1, scale: 1 },
})

export const Toolbar: React.FC<{ className?: string; shouldHide: boolean }> = ({ className, shouldHide }) => {
  const leaveModal = useLeaveModal()
  const { liveSessionId } = useLiveSessionIdContext()
  const chatId = ScopedChatId.fromId(liveSessionId)
  const dispatch = useDispatch()
  const { t } = useTranslation()
  const { leave } = useLiveContext()
  const isFacilitatorOrLearnerLedSession = useIsFacilitatorOrLearnerLedSession()

  const [container, setContainer] = useState<HTMLDivElement | null>(null)

  const onLeaveClick = useCallback(() => {
    if (isFacilitatorOrLearnerLedSession) {
      leaveModal.show()
    } else {
      void leave()
    }

    void dispatch(Logging.liveSession.leaveButtonPressed())
  }, [isFacilitatorOrLearnerLedSession, dispatch, leaveModal, leave])

  const setToolbarAtom = useSetAtom(liveToolbarAtom)
  useEffect(() => {
    if (container === null) return
    setToolbarAtom({ container, shouldHide })
    return () => setToolbarAtom(null)
  }, [container, setToolbarAtom, shouldHide])

  return (
    <>
      <EmojiPickerPopupProvider>
        <ToolbarContainer ref={setContainer} className={className}>
          <VideoButton />
          <AudioButton />

          {isDesktop && <ScreenShareButton />}
          <RaiseHandButton />
          <EmojiInputButton container={container} chatId={chatId} />
          <SettingsButton renderedIn='live' />
          <HangupButton
            iconId='phone--hang-up'
            color='white'
            tooltip={t('live.leave-session')}
            onClick={onLeaveClick}
          />
        </ToolbarContainer>
      </EmojiPickerPopupProvider>
    </>
  )
}
