import { Editor as CoreEditor } from '@tiptap/core'
import { Extension } from '@tiptap/react'
import _ from 'lodash'

export const AutoFitFont = (options?: {
  onMeasure?: (params: { calculatedFontSize: string }) => void
}): ReturnType<(typeof Extension)['create']> => {
  const onUpdateFont = _.throttle(
    ({ editor }: { editor: CoreEditor }): void => {
      const maxSize = 38
      const minSize = 1
      const delta = 0.2

      const innerEl = editor.view.dom
      const outerEl = innerEl.closest<HTMLElement>('.NoteBody')
      if (!outerEl) return

      const rawFontSize = window.getComputedStyle(innerEl).getPropertyValue('font-size')
      let fontSize = parseFloat(rawFontSize)

      innerEl.classList.add('sana-auto-fit-font__is-measuring')

      // These should be static while we change the font size.
      const outerHeight = outerEl.clientHeight
      const outerWidth = outerEl.clientWidth

      // Increase the font size until we exceed or match the container height.
      while (fontSize < maxSize && innerEl.scrollHeight < outerHeight) {
        fontSize += delta
        innerEl.style.fontSize = `${fontSize}px`
      }

      // Decrease the font size until we don't exceed the container.
      while (fontSize > minSize && (innerEl.scrollHeight > outerHeight || innerEl.scrollWidth > outerWidth)) {
        fontSize -= delta
        innerEl.style.fontSize = `${fontSize}px`
      }

      options?.onMeasure?.({ calculatedFontSize: `${fontSize}px` })

      innerEl.classList.remove('sana-auto-fit-font__is-measuring')
      innerEl.classList.add('sana-auto-fit-font__has-measured-once')
    },
    100,
    { leading: true }
  )

  return Extension.create({
    name: 'AutoFitFont',
    onCreate() {
      onUpdateFont({ editor: this.editor })
    },
    onUpdate() {
      onUpdateFont({ editor: this.editor })
    },
  })
}
