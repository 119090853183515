import React, { useState } from 'react'
import { Modal } from 'sierra-client/components/common/modals/modal'
import { usePost } from 'sierra-client/hooks/use-post'
import { useTranslation } from 'sierra-client/hooks/use-translation'
import { TagsInput } from 'sierra-client/views/course-settings/components/tags-input'
import { FormField } from 'sierra-client/views/course-settings/tabs/utils'
import { ImageUpload } from 'sierra-client/views/manage/paths/components/image-upload'
import { LinkCourseRecord } from 'sierra-domain/api/link'
import { LinkCourseSettings } from 'sierra-domain/api/manage'
import { CourseId } from 'sierra-domain/api/nano-id'
import { isRight } from 'sierra-domain/either'
import { XRealtimeAdminLinkImport, XRealtimeAdminLinkPreview } from 'sierra-domain/routes'
import { FormElement, Input } from 'sierra-ui/components'
import { Button, Spacer, TextAreaPrimitive, View } from 'sierra-ui/primitives'
import { z } from 'zod'

type DirectLinkImportDialogProps = {
  isOpen: boolean
  onClose: () => void
  onAfterImport: () => void
}

const INITIAL_SETTINGS: LinkCourseSettings & LinkCourseRecord = {
  url: '',
  title: '',
  tags: [],
  level: undefined,
  theme: undefined,
  description: undefined,
  image: undefined,
}

const INITIAL_ERRORS = { url: false, title: false }

const PREVIEW_REQUEST_TIMEOUT_MS = 5000

export const DirectLinkImportDialog: React.FC<DirectLinkImportDialogProps> = ({
  isOpen,
  onClose,
  onAfterImport,
}) => {
  const { t } = useTranslation()

  const [settings, setSettings] = useState<typeof INITIAL_SETTINGS>(INITIAL_SETTINGS)
  const [errors, setErrors] = useState(INITIAL_ERRORS)
  const [previewLoaded, setPreviewLoaded] = useState(false)
  const [isLoading, setIsLoading] = useState(false)
  const [courseId, setCourseId] = useState<CourseId | undefined>(undefined)

  const { postWithUserErrorCode } = usePost()

  const formattedUrl = (url: string): string => {
    return url.startsWith('http://') || settings.url.startsWith('https://') ? url : `https://${url}`
  }

  const validateUrl = (): boolean => {
    const invalidUrl = settings.url === ''
    setErrors({ ...errors, url: invalidUrl })
    return !invalidUrl
  }

  const validateFields = (): boolean => {
    const invalids = {
      title: settings.title === '',
      url: settings.url === '',
    }
    setErrors({ ...errors, ...invalids })
    return !Object.values(invalids).some(Boolean)
  }

  const fetchPreviewOrTimeout = (): Promise<
    z.infer<(typeof XRealtimeAdminLinkPreview)['value']> | undefined
  > => {
    return Promise.race([
      postWithUserErrorCode(XRealtimeAdminLinkPreview, { url: formattedUrl(settings.url) }),
      new Promise<undefined>(resolve => {
        setTimeout(() => {
          resolve(undefined)
        }, PREVIEW_REQUEST_TIMEOUT_MS)
      }),
    ]).then(result => (result !== undefined && isRight(result) ? result.right : undefined))
  }

  const loadPreview = async (): Promise<void> => {
    if (validateUrl()) {
      try {
        setIsLoading(true)
        const preview = await fetchPreviewOrTimeout()
        if (preview !== undefined) {
          setSettings({
            ...settings,
            title: preview.title ?? settings.title,
            description: preview.description,
            image:
              preview.image !== undefined
                ? {
                    type: 'file',
                    file: preview.image,
                    width: undefined,
                    height: undefined,
                  }
                : undefined,
            tags:
              preview.tags?.map(tagId => {
                return { id: tagId }
              }) ?? [],
          })
          // CourseId is generated at preview time, and is used for image uploads
          // We need to store it, and pass it again when we create the course from the preview data
          setCourseId(preview.courseId)
        }
      } finally {
        setPreviewLoaded(true)
        setIsLoading(false)
      }
    }
  }

  const closeAndReset = (): void => {
    onClose()
    setSettings(INITIAL_SETTINGS)
    setErrors(INITIAL_ERRORS)
    setPreviewLoaded(false)
    setIsLoading(false)
  }

  const importCourse = async (): Promise<boolean> => {
    let successful = false
    if (courseId === undefined) {
      return false
    }
    if (validateFields()) {
      try {
        setIsLoading(true)
        const { url, ...courseSettings } = settings
        const response = await postWithUserErrorCode(XRealtimeAdminLinkImport, {
          settings: courseSettings,
          record: { url: formattedUrl(url) },
          courseId,
        })
        successful = isRight(response)
      } finally {
        setIsLoading(false)
      }
    }
    return successful
  }

  const handleSettingsChange = <
    F extends keyof (LinkCourseSettings & LinkCourseRecord),
    V extends (LinkCourseSettings & LinkCourseRecord)[F],
  >(
    field: F,
    value: V
  ): void => {
    setSettings({ ...settings, ...{ [field]: value } })
    // clear errors on change
    setErrors({ ...errors, ...{ [field]: false } })
  }

  return (
    <Modal title={t('manage.course.import-courses')} open={isOpen} size='large' onClose={closeAndReset}>
      <Input
        id='URL'
        placeholder={t('author.slate.link.placeholder-url')}
        label={t('author.slate.link.link')}
        value={settings.url}
        isError={errors.url}
        helperText={errors.url ? t('content.validation.link-invalid') : undefined}
        onChange={e => handleSettingsChange('url', e.target.value)}
        spacing='medium'
      />
      {previewLoaded ? (
        <>
          <Input
            id='title'
            placeholder={t('author.course.name-placeholder')}
            label={t('dictionary.name')}
            value={settings.title}
            isError={errors.title}
            helperText={errors.title ? t('content.validation.course-title') : undefined}
            onChange={e => {
              handleSettingsChange('title', e.target.value)
            }}
            spacing='medium'
          />

          <FormElement label={t('dictionary.description')} htmlFor='description'>
            <TextAreaPrimitive
              id='description'
              placeholder={t('dictionary.description')}
              value={settings.description ?? ''}
              onChange={e => handleSettingsChange('description', e.target.value)}
              rows={4}
              autoExpand={true}
            />
            <Spacer size='medium' />
          </FormElement>

          <TagsInput
            initialSelectionTagIds={settings.tags.map(tag => tag.id)}
            onChange={selection =>
              handleSettingsChange(
                'tags',
                selection.map(id => {
                  return { id: id }
                })
              )
            }
          />
          {courseId !== undefined ? (
            <FormField title={'manage.paths.cover-image'}>
              <ImageUpload
                value={settings.image}
                onChange={image => handleSettingsChange('image', image)}
                assetContext={{ type: 'course', courseId: courseId }}
              />
            </FormField>
          ) : (
            <></>
          )}
        </>
      ) : (
        <></>
      )}
      <View gap='none' alignItems='center'>
        <Button
          loading={isLoading}
          onClick={async () => {
            if (!previewLoaded) {
              await loadPreview()
            } else {
              const successfulImport = await importCourse()
              if (successfulImport) {
                closeAndReset()
                onAfterImport()
              }
            }
          }}
        >
          {t('manage.course.import')}
        </Button>
        <Spacer size='xxsmall' />
        <Button variant='secondary' onClick={closeAndReset}>
          {t('dictionary.cancel')}
        </Button>
      </View>
    </Modal>
  )
}
