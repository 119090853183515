import { Element } from 'slate'

export function supportsTextToSpeech(element: Pick<Element, 'type'>): boolean {
  return [
    'heading',
    'paragraph',
    'bulleted-list',
    'numbered-list',
    'check-list',
    'takeaways',
    'preamble',
    'question-card-multiple-choice-alternative',
    'poll-card-alternative',
  ].includes(element.type)
}
