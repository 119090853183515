import { useTranslation } from 'sierra-client/hooks/use-translation'
import { IconButton } from 'sierra-ui/primitives'

export const HighlightPollResponseButton: React.FC<{ optionId: string }> = ({ optionId }) => {
  const { t } = useTranslation()
  const pollResponse = document.getElementById(`poll-response:${optionId}`)

  if (pollResponse === null) {
    return null
  }

  return (
    <IconButton
      iconId='view'
      tooltip={t('dictionary.view')}
      size='small'
      onMouseEnter={() => pollResponse.setAttribute('data-focus-highlight', 'true')}
      onMouseLeave={() => pollResponse.removeAttribute('data-focus-highlight')}
    />
  )
}
