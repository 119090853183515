import { BaseAwarenessState } from '@sanalabs/y-redux'
import { UserId } from 'sierra-domain/api/uuid'
import { Chat } from 'sierra-domain/chat'
import { z } from 'zod'

export const ChatAwarenessData = z.object({
  userId: UserId.optional(),
  mouseX: z.number().optional(),
  mouseY: z.number().optional(),
  isWritingTo: z.string().optional(),
})

export type ChatAwarenessData = z.TypeOf<typeof ChatAwarenessData>
type ChatAwarenessState = BaseAwarenessState & ChatAwarenessData

export type ChatAwareness = {
  states: ChatAwarenessState[]
  localState: ChatAwarenessData
}

export type ChatId = string

export type ChatState = {
  chats: Record<ChatId, Chat>
  /** A list of tokens indexed by [chatId][threadId]. Each token represents a visible chat window. A chat thread is visible if the list of tokens is non-empty.  */
  visibleChats: Record<ChatId, Record<string, string[]>>
  /** Timestamps indexed by [chatId][threadId] of the last message considered 'read' for each thread */
  lastSeenMessageTimestamp: Record<ChatId, Record<string, string>>
  awarenesses: Record<ChatId, ChatAwareness>
}
