import { token } from 'sierra-ui/theming'
import { css } from 'styled-components'

export const tiptapPlaceholder = css`
  /* Placeholder (at the top) */
  .ProseMirror p.is-editor-empty:first-child::before {
    color: ${token('foreground/muted')};
    content: attr(data-placeholder);
    float: left;
    height: 0;
    pointer-events: none;
  }
`

export const tiptapCursorStyles = css`
  /* Give a remote user a caret */
  .collaboration-cursor__caret {
    border-left: 1px solid var(--cursor-color);
    border-right: 1px solid var(--cursor-color);

    /* NOTE: Under normal circumstances we don't need a background color here since the
       element has no content and zero width. However, some zoom levels on Chrome can result
       in it having a non-zero width which leads to a distracting gap between the borders. */
    background-color: var(--cursor-color);
    margin-left: -1px;
    margin-right: -1px;
    pointer-events: none;
    position: relative;
    word-break: normal;
    z-index: 100;
  }

  /* Render the username above the caret */
  .collaboration-cursor__label {
    background-color: var(--cursor-color);
    border-radius: 3px 3px 3px 0;
    font-size: 12px;
    font-style: normal;
    font-weight: 600;
    left: -1px;
    line-height: normal;
    padding: 0.1rem 0.3rem;
    position: absolute;
    top: -1.4em;
    user-select: none;
    white-space: nowrap;
    color: white;
  }
`
