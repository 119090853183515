import { AutoTextSize } from 'auto-text-size'
import { round } from 'lodash'
import { Duration } from 'luxon'
import React from 'react'
import { mapOneDimensionalData } from 'sierra-client/features/insights/display-widgets/data-utils'
import { OneDimensionalMetric } from 'sierra-client/features/insights/display-widgets/types'
import { getPercentage } from 'sierra-client/features/insights/display-widgets/value-utils'
import { useTranslation } from 'sierra-client/hooks/use-translation'
import { MetricWidget as MetricWidgetType, TableResult } from 'sierra-domain/api/insights'
import { durationToLargestUnitString } from 'sierra-ui/missions/delphi/charts/utils'
import { Heading, Text, View } from 'sierra-ui/primitives'

const getFormattedValue = (oneDimensionalMetric: OneDimensionalMetric): string => {
  switch (oneDimensionalMetric.type) {
    case 'type.progress':
    case 'type.ratio':
      return getPercentage(oneDimensionalMetric.value)
    case 'type.duration':
      switch (oneDimensionalMetric.durationUnit) {
        case 'hours':
          return durationToLargestUnitString(Duration.fromObject({ hours: oneDimensionalMetric.value }))
        case 'minutes':
          return durationToLargestUnitString(Duration.fromObject({ minutes: oneDimensionalMetric.value }))
        case 'seconds':
          return durationToLargestUnitString(Duration.fromObject({ seconds: oneDimensionalMetric.value }))
      }
      break
    case 'type.double':
      return round(oneDimensionalMetric.value, 2).toString()
    default:
      return oneDimensionalMetric.value.toString()
  }
}

type MetricWidgetProps = {
  tableResult: TableResult
  widget: MetricWidgetType
  previewMode: boolean
}

export const MetricWidget: React.FC<MetricWidgetProps> = ({ tableResult, widget, previewMode }) => {
  const { t } = useTranslation()
  const data = mapOneDimensionalData(tableResult, t, widget)
  const formattedValue = getFormattedValue(data)

  if (previewMode) {
    return (
      <View direction='column' grow alignItems='center' justifyContent='center' data-testid='metric-widget'>
        <Text color='foreground/primary' as='span'>
          <Heading size='h1' bold>
            {formattedValue}
          </Heading>
        </Text>
      </View>
    )
  } else {
    return (
      <View direction='column' grow justifyContent='flex-end' data-testid='metric-widget'>
        <Text color='foreground/primary' as='span'>
          <AutoTextSize
            as='span'
            maxFontSizePx={40}
            minFontSizePx={20}
            mode='oneline'
            style={{ fontWeight: 500 }}
          >
            {formattedValue}
          </AutoTextSize>
        </Text>
      </View>
    )
  }
}
