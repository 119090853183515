import { SessionListButton } from 'sierra-client/components/sharing/tabs/components/session-list-button'
import { ViewInManageButton } from 'sierra-client/components/sharing/tabs/components/view-in-manage-button'
import { useContentIds } from 'sierra-client/hooks/use-content-ids'
import { View } from 'sierra-ui/primitives'
import { spacing, token } from 'sierra-ui/theming'
import styled from 'styled-components'

const Footer = styled(View)`
  border-top: 1px solid ${token('border/default')};
  padding-top: ${spacing[16]};
`
export const AssignTabFooter: React.FC = () => {
  const { liveContentId } = useContentIds()
  const isLiveContent = liveContentId !== undefined

  return (
    <Footer justifyContent='flex-end' gap='16'>
      {isLiveContent && <SessionListButton />}
      <ViewInManageButton />
    </Footer>
  )
}
