import { useEffect, useRef } from 'react'
import { getGlobalRouter } from 'sierra-client/router'
import { selectCurrentProgramId } from 'sierra-client/state/content/selectors'
import { useDispatch, useSelector } from 'sierra-client/state/hooks'
import { selectIsLoggedIn } from 'sierra-client/state/user/user-selector'
import { fetchProgramById } from 'sierra-client/state/v2/program-actions'

export const ProgramStateFetcher = (): null => {
  const dispatch = useDispatch()
  const currentProgramId = useSelector(selectCurrentProgramId)
  const isLoggedIn = useSelector(selectIsLoggedIn)
  const isFetching = useRef(false)

  useEffect(() => {
    if (!isLoggedIn) return

    if (currentProgramId === undefined) return

    // Make sure we dont do this fetch more often than we need
    // TODO: This can fail if we go from one program to another while fetching the first one.
    //  It's OK for now since this is triggered on every navigation but we should fix
    //  both issues...
    if (isFetching.current === true) return
    isFetching.current = true

    void dispatch(fetchProgramById({ programId: currentProgramId }))
      .then(x => {
        // only way to grab the error being thrown from the fetchProgramById
        if ('error' in x && x.error.name === 'ProgramError') {
          // KINDOFAHACK: If any errors happen, do replace the whole program route and rewrite the current route
          // This is necessary as it gives problems whenever learners are linked to an open course via its program path
          // without having permissions to view that program
          // use `accessDenied` pages if this behaviour is not what is desired
          const hrefWithoutProgram = window.location.href.replace('/program/[programId]/', '/')
          void getGlobalRouter().navigate({ to: hrefWithoutProgram, replace: true })
        }
        return x
      })
      .finally(() => {
        isFetching.current = false
      })
  }, [currentProgramId, dispatch, isLoggedIn])

  return null
}
