import { XYCoord, useDragLayer } from 'react-dnd'
import { FCC } from 'sierra-client/types'
import styled, { CSSProperties, createGlobalStyle } from 'styled-components'

const DragOverlayContainerWrapper = styled.div`
  position: fixed;
  pointer-events: none;
  z-index: 100;
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
`

const GlobalStyleWhileDragging = createGlobalStyle`
    * {
        user-select: none;
        cursor: grabbing !important;
    }

`

function getItemStyles(initialOffset: XYCoord | null, currentOffset: XYCoord | null): CSSProperties {
  if (!initialOffset || !currentOffset) {
    return {
      display: 'none',
    }
  }

  const { x, y } = currentOffset
  const transform = `translate(${x}px, ${y}px)`

  return {
    transform,
    WebkitTransform: transform,
  }
}

export const DragOverlayPosition: FCC = ({ children }) => {
  const { initialOffset, currentOffset } = useDragLayer<{
    initialOffset: XYCoord | null
    currentOffset: XYCoord | null
  }>(monitor => ({
    initialOffset: monitor.getInitialClientOffset(),
    currentOffset: monitor.getClientOffset(),
  }))

  return (
    <DragOverlayContainerWrapper>
      <div style={getItemStyles(initialOffset, currentOffset)}>{children}</div>
      <GlobalStyleWhileDragging />
    </DragOverlayContainerWrapper>
  )
}
