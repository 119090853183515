import _ from 'lodash'
import React, { useEffect, useMemo, useState } from 'react'
import { resolveUserAvatar } from 'sierra-client/api/content'
import { DeleteBox } from 'sierra-client/components/common/delete-box'
import { useNotif } from 'sierra-client/components/common/notifications'
import { getFlag } from 'sierra-client/config/global-config'
import { usePost } from 'sierra-client/hooks/use-post'
import { useResolveAsset } from 'sierra-client/hooks/use-resolve-asset'
import { useTranslation } from 'sierra-client/hooks/use-translation'
import { Trans } from 'sierra-client/hooks/use-translation/trans'
import { TranslationKey } from 'sierra-client/hooks/use-translation/types'
import {
  Equals,
  Filter,
  Or,
  andAll,
  createFilter,
  isEmptyFilter,
  orAll,
  stringValue,
} from 'sierra-client/lib/filter'
import { useFilter } from 'sierra-client/lib/filter/use-filter'
import { useUsersLegacy } from 'sierra-client/state/users/hooks'
import { EnrollModalComponents } from 'sierra-client/views/manage/programs/enrollment-rules/modals/common'
import { useStartDateSelector } from 'sierra-client/views/manage/programs/enrollment-rules/modals/start-date-selector'
import { UserPreviewPanel } from 'sierra-client/views/manage/programs/enrollment-rules/modals/user-preview/user-preview-panel'
import { UserPreviewConfig } from 'sierra-client/views/manage/programs/enrollment-rules/modals/user-preview/user-preview-table'
import { useTracking } from 'sierra-client/views/manage/programs/hooks/use-tracking'
import { EnrollmentStartTime, RelativeTimeAttribute } from 'sierra-domain/api/manage'
import { ProgramId } from 'sierra-domain/api/uuid'
import { AssetContext } from 'sierra-domain/asset-context'
import { ImageUnion } from 'sierra-domain/content/v2/content'
import { isLeft } from 'sierra-domain/either'
import { DomainRep } from 'sierra-domain/filter/datatype/domain'
import {
  XRealtimeAdminProgramsCreateEnrollmentRule,
  XRealtimeAdminProgramsUpdateEnrollmentRule,
  XRealtimeAdminUsersCountFilterUsers2,
  XRealtimeAdminUsersFilterUsers2,
} from 'sierra-domain/routes'
import { BaseUserRow } from 'sierra-domain/user/base-user-row'
import { isDefined, isEmptyString, isNonEmptyString } from 'sierra-domain/utils'
import { Layout, Tooltip } from 'sierra-ui/components'
import { PanelInPanelOverlay } from 'sierra-ui/components/layout-kit/layout-panel'
import { MatchingUsers } from 'sierra-ui/missions/workflows/panels/components/matching-users'
import { Button, View } from 'sierra-ui/primitives'
import { ConditionalWrapper } from 'sierra-ui/utils'

const {
  ProgramHeaderEdit,
  EnrollModal,
  Content,
  Footer,
  StartDateSection,
  TriggerSection,
  ActiveRuleSection,
  Line,
  AttributeInfo,
  AttributeHighlight,
} = EnrollModalComponents

const INITIAL_FILTER = orAll([
  andAll([
    createFilter({ type: 'user.status' }, Equals, Or([stringValue('active'), stringValue('pending')])),
    createFilter({ type: 'user.type' }, Equals, Or([stringValue('standard')])),
  ]),
])

type PreviewData = { users: BaseUserRow[]; count: number }

const useFilterPreview = ({
  filter,
  limit = 3,
}: {
  filter: Filter
  limit?: number
}): PreviewData | undefined => {
  const { postWithUserErrorException } = usePost()
  const [preview, setPreview] = useState<PreviewData>()

  useEffect(() => {
    Promise.all([
      postWithUserErrorException(XRealtimeAdminUsersFilterUsers2, {
        filter,
        limit,
      }),
      postWithUserErrorException(XRealtimeAdminUsersCountFilterUsers2, {
        filter,
      }),
    ])
      .then(([users, count]) => {
        setPreview({
          users: users.users.map(user => user.userInfo),
          count: count.count,
        })
      })
      .catch(err => {
        console.error(err)
        setPreview(undefined)
      })
  }, [postWithUserErrorException, filter, limit])

  return preview
}

const getAttributeLabel = ({ attribute }: { attribute: RelativeTimeAttribute }): string => {
  if (attribute.type === 'user.customAttribute') {
    return attribute.key
  }

  return attribute.type.replace('user.', '')
}

type BaseProps = {
  programId: ProgramId
  image?: ImageUnion
  open: boolean
  onClose: () => void
  domainReps: DomainRep[]
}

type ViewProps = BaseProps & {
  mode: 'view'
  ruleId: string
  onSave?: undefined
  initialState: InitialState
}

type InitialState = {
  title: string
  enabled: boolean
  filter: Filter
  time: EnrollmentStartTime
}

type EditProps = BaseProps & {
  mode: 'edit'
  ruleId: string
  // TODO: Pass values on save? (Or let parent refetch?)
  onSave: () => Promise<void> | void
  initialState: InitialState
  onDelete: () => Promise<void> | void
}

type CreateProps = BaseProps & {
  mode: 'create'
  onSave: () => Promise<void> | void
  initialState?: undefined
}

type EnrollmentRuleModalProps = ViewProps | EditProps | CreateProps

const InnerModal: React.FC<EnrollmentRuleModalProps> = props => {
  const { programId, image, domainReps, onSave, onClose } = props
  const tracking = useTracking()

  const { t } = useTranslation()
  const notifications = useNotif()
  const { postWithUserErrorCode } = usePost()
  const readonly = props.mode === 'view'

  // Enrollment rule info
  const [title, setTitle] = useState(props.initialState?.title ?? '')
  const [enabled, setEnabled] = useState(props.initialState?.enabled ?? true)
  const { ctx, filter } = useFilter({
    initialFilter: props.initialState?.filter ?? INITIAL_FILTER,
    domainReps,
    domain: undefined,
  })
  const generateFiltersEnabled = getFlag('manage/generate-filters')

  // Filter preview
  const matchingUsersPreview = useFilterPreview({ filter })
  const matchingUserIds = matchingUsersPreview?.users.map(user => user.userId) ?? []
  const resolvedUsers = useUsersLegacy(matchingUserIds).filter(isDefined).map(resolveUserAvatar)
  const [userPreviewOpen, setUserPreviewOpen] = useState(false)

  const startDateProps = useStartDateSelector(props.initialState?.time ?? undefined)
  const { startDate: time } = startDateProps

  const save = async (): Promise<void> => {
    if (props.mode === 'create') {
      try {
        const result = await postWithUserErrorCode(
          XRealtimeAdminProgramsCreateEnrollmentRule, // This should handle enrollment-rule/duplicate-name
          {
            name: title,
            filter,
            enabled,
            time,
            programGroupId: programId,
          }
        )

        if (isLeft(result)) {
          if (result.left === 'enrollment-rule/duplicate-name') {
            notifications.push({ type: 'enrollment-rule/duplicate-name' })
          } else {
            throw Error(`An unknown error occurred while creating an enrollment rule: ${result.left}.`)
          }
        } else {
          notifications.push({ type: 'enrollment-rule-created' })
          tracking.enrollment.rule.created(result.right.enrollmentRule.id)
          void onSave?.()
          onClose()
        }
      } catch (e) {
        notifications.push({ type: 'enrollment-rule-creation-failed' })
      }
    } else if (props.mode === 'edit') {
      // check if there is nothing to update
      if (
        _.isEqual(
          [title, enabled, filter, time],
          [
            props.initialState.title,
            props.initialState.enabled,
            props.initialState.filter,
            props.initialState.time,
          ]
        )
      ) {
        onClose()
        return
      }
      const result = await postWithUserErrorCode(XRealtimeAdminProgramsUpdateEnrollmentRule, {
        id: props.ruleId,
        name: title,
        enabled,
        time: _.isEqual(time, props.initialState.time) ? undefined : time,
        programId: props.programId,
        // Only update `filter` if it was changed.
        filter: _.isEqual(filter, props.initialState.filter) ? undefined : filter,
      })

      if (isLeft(result)) {
        if (result.left === 'enrollment-rule/duplicate-name') {
          notifications.push({ type: 'enrollment-rule/duplicate-name' })
        } else {
          throw Error(`An unknown error occured while editing an enrollment rule: ${result.left}.`)
        }
      } else {
        notifications.push({ type: 'enrollment-rule/update-success' })
        tracking.enrollment.rule.activate(result.right.enrollmentRule.id, result.right.enrollmentRule.enabled)
        void onSave?.()
        onClose()
      }
    }
  }

  const backgroundImageSrc = useResolveAsset({
    image,
    assetContext: { type: 'program', programId },
    size: 'course',
  })

  const background = image !== undefined ? backgroundImageSrc : undefined
  const saveDisabledReason = [
    isEmptyString(title) ? t('program-overview.enrollment-rule.modal.save.no-title.text') : undefined,
    isEmptyFilter(filter) ? t('program-overview.enrollment-rule.modal.save.no-filters.text') : undefined,
  ]
    .filter(isDefined)
    .at(0)

  const userPreviewConfig = useMemo<UserPreviewConfig>(
    () => ({
      type: 'rule',
      time,
      programGroupId: programId,
      filter,
    }),
    [time, programId, filter]
  )

  const assetContext: AssetContext = useMemo(
    () => ({ type: 'program', programId: ProgramId.parse(programId) }),
    [programId]
  )

  return (
    <Layout
      panels={[
        <UserPreviewPanel
          key='right'
          previewConfig={userPreviewConfig}
          count={matchingUsersPreview?.count ?? 0}
          onClose={() => setUserPreviewOpen(false)}
          open={userPreviewOpen}
        />,
      ]}
    >
      {userPreviewOpen && <PanelInPanelOverlay onClick={() => setUserPreviewOpen(false)} />}
      <ProgramHeaderEdit title={title} background={background} setTitle={setTitle} onClose={onClose} />
      <Content>
        <TriggerSection
          filter={{
            ctx,
            filter,
            domain: generateFiltersEnabled ? { type: 'user.enrollment-rule' } : undefined,
          }}
          readonly={readonly}
          assetContext={assetContext}
        />
        <Line />
        <StartDateSection {...startDateProps} disabled={readonly} />
        <Line />
        {!readonly && matchingUsersPreview && (
          <MatchingUsers
            users={resolvedUsers}
            label={t('manage.programs.enrollment-rules.matching-users', {
              count: matchingUsersPreview.count,
            })}
            previewLabel={t('manage.programs.enrollment-rules.preview')}
            onPreviewClick={() => setUserPreviewOpen(true)}
            message={
              startDateProps.startDate.type === 'relative' ? (
                <AttributeInfo>
                  <Trans
                    i18nKey={'manage.programs.enrollment-rules.missing-attribute' satisfies TranslationKey}
                    values={{
                      attribute: getAttributeLabel({ attribute: startDateProps.startDate.attribute }),
                    }}
                    components={{ wrapper: <AttributeHighlight /> }}
                  />
                </AttributeInfo>
              ) : undefined
            }
          />
        )}
        {props.mode === 'edit' && (
          <DeleteBox
            title={t('manage.programs.enrollment-rules.delete-title')}
            description={t('manage.programs.enrollment-rules.delete-description')}
            deleteLabel={t('dictionary.delete')}
            onDelete={props.onDelete}
          />
        )}
      </Content>
      <Footer>
        <ActiveRuleSection readonly={readonly} enabled={enabled} setEnabled={setEnabled} />
        {!readonly && (
          <View>
            <Button variant='ghost' onClick={onClose}>
              {t('modal.cancel')}
            </Button>
            <ConditionalWrapper
              condition={isNonEmptyString(saveDisabledReason)}
              renderWrapper={children => <Tooltip title={saveDisabledReason}>{children}</Tooltip>}
            >
              <Button variant='secondary' onClick={save} disabled={isNonEmptyString(saveDisabledReason)}>
                {t('modal.save')}
              </Button>
            </ConditionalWrapper>
          </View>
        )}
      </Footer>
    </Layout>
  )
}

export const EnrollmentRuleModal: React.FC<EnrollmentRuleModalProps> = props => {
  const { open, onClose } = props

  return (
    <EnrollModal open={open} onClose={onClose}>
      {open && <InnerModal {...props} />}
    </EnrollModal>
  )
}
