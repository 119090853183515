/**
 * Supported permission names to query for
 */
type SupportedPermissionName = PermissionName | 'microphone' | 'camera'

type QueryFailedReason = 'api-not-available' | 'unsupported-permission'

type PermissionResponse =
  | { succeeded: true; permissionState: PermissionState }
  | { succeeded: false; reason: QueryFailedReason }

/**
 * Feature check the browser Permissions API
 */
const isBrowserPermissionsApiAvailable =
  typeof window !== 'undefined' &&
  'navigator' in window &&
  'permissions' in navigator &&
  'query' in navigator.permissions

/**
 * Fallback function used for browsers that don't support the camera and microphone permissions
 * in the Permissions API.
 */
const queryCameraAndMicrophoneAccessThroughMediaDevices = async (): Promise<boolean> => {
  if (!('mediaDevices' in navigator)) {
    return false
  }

  try {
    const stream = await navigator.mediaDevices.getUserMedia({ audio: true, video: true })

    stream.getTracks().forEach(track => track.stop())
    return true
  } catch (error) {
    return false
  }
}

/**
 * Query the browser Permissions API
 */
const queryBrowserPermission = async (
  permissionName: SupportedPermissionName
): Promise<PermissionResponse> => {
  if (!isBrowserPermissionsApiAvailable) {
    return { succeeded: false, reason: 'api-not-available' }
  }

  try {
    const permissionStatus = await navigator.permissions.query({
      name: permissionName as PermissionName,
    })
    return { succeeded: true, permissionState: permissionStatus.state }
  } catch (error) {
    if (error instanceof TypeError) {
      return { succeeded: false, reason: 'unsupported-permission' }
    } else {
      throw error
    }
  }
}

/**
 * Check if we can access a specific browser API
 */
export const canAccessBrowserApi = async (permissionName: SupportedPermissionName): Promise<boolean> => {
  // Firefox and Safari has, as of writing this comment, incomplete support for the permissions api,
  // for checking camera and microphone permissions. We need to use the mediaDevices API as a fallback.
  const isFirefoxOrSafari = /Firefox|Safari/.test(navigator.userAgent)
  if (isFirefoxOrSafari && (permissionName === 'camera' || permissionName === 'microphone')) {
    return queryCameraAndMicrophoneAccessThroughMediaDevices()
  }

  const permissionResponse = await queryBrowserPermission(permissionName)

  if (!permissionResponse.succeeded) {
    return true
  }

  switch (permissionResponse.permissionState) {
    case 'granted':
    case 'prompt':
      return true
    case 'denied':
      return false
  }
}
