import { createSelector } from 'reselect'
import { useLiveSessionContext } from 'sierra-client/components/liveV2/contexts/live-session-data'
import {
  selectFlexibleContentChildIds,
  selectFlexibleContentFile,
} from 'sierra-client/state/flexible-content/selectors'
import { useSelector } from 'sierra-client/state/hooks'
import { selectCurrentCardId } from 'sierra-client/state/live-session/selectors'
import { useThemeForFile } from 'sierra-client/views/flexible-content/polaris-card-theme'
import { getDropAWordBackgroundColor } from 'sierra-client/views/v3-author/drop-a-word/renderer/utils'
import { getScenarioBackgroundColor } from 'sierra-client/views/v3-author/scenario/utils'
import { CreateContentId } from 'sierra-domain/api/nano-id'
import { FileId } from 'sierra-domain/flexible-content/identifiers'
import { createFile, File } from 'sierra-domain/flexible-content/types'
import { guardWith } from 'sierra-domain/utils'
import { color, ColorBuilder } from 'sierra-ui/color'

const emptyCourseCardFallback = createFile({ data: { type: 'live-lobby' } })

export const selectCurrentCard = createSelector(
  [state => state, (state, contentId: CreateContentId) => contentId],
  (state, contentId: CreateContentId) => {
    const flexibleContentNodeIds = selectFlexibleContentChildIds(state, contentId, 'folder:root')

    const sessionCurrentCardId = selectCurrentCardId(state)
    const sessionCurrentCard =
      sessionCurrentCardId !== undefined
        ? selectFlexibleContentFile(state, contentId, sessionCurrentCardId)
        : undefined

    const fallbackCardId = flexibleContentNodeIds.find(guardWith(FileId))
    const fallbackCurrentCard =
      fallbackCardId !== undefined ? selectFlexibleContentFile(state, contentId, fallbackCardId) : undefined

    return sessionCurrentCard ?? fallbackCurrentCard ?? emptyCourseCardFallback
  }
)

export const useSelectCurrentCard = (): File | undefined => {
  const liveSession = useLiveSessionContext()
  return useSelector(state => selectCurrentCard(state, liveSession.data.flexibleContentId))
}

export const useSelectCurrentCardBackgroundColor = (): ColorBuilder => {
  const card = useSelectCurrentCard()
  const colorName = useThemeForFile(card).home.backgroundColor

  if (card?.data.type === 'drop-a-word') {
    return getDropAWordBackgroundColor(colorName)
  }

  if (card?.data.type === 'scenario') {
    return getScenarioBackgroundColor(colorName)
  }

  return color(colorName)
}
