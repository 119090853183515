import { createFileRoute } from '@tanstack/react-router'
import React from 'react'
import { PageIdentifier, SanaPage } from 'sierra-client/layout/sana-page'
import { useSelector } from 'sierra-client/state/hooks'
import { selectIsInitializing } from 'sierra-client/state/user/user-selector'
import { GroupInvite } from 'sierra-client/views/authentication/flows/group-invite'

const GroupInvitePage: React.FC = () => {
  const token = Route.useParams({ select: params => params.token })
  const isInitializing = useSelector(selectIsInitializing)

  if (isInitializing)
    return <SanaPage mode='dark' page={PageIdentifier.Empty({ message: 'Loading the group page' })} />

  return (
    <SanaPage mode='dark' page={PageIdentifier.AuthenticationLogin()}>
      <GroupInvite token={token} />
    </SanaPage>
  )
}

export const Route = createFileRoute('/group-invite/$token')({
  component: GroupInvitePage,
})
