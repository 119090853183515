import { Logger } from 'sierra-client/core/logging/logger'

type Identifier = `certificates_${string}`

type CertificateTrackingPayload = {
  certificateId: string
}

export const myListClicked = Logger.trackLoggerWithExtra<
  CertificateTrackingPayload,
  CertificateTrackingPayload
>('certificates_my_list_clicked' satisfies Identifier, input => {
  return {
    ...input,
  }
})

export const linkedInShareClicked = Logger.trackLoggerWithExtra<
  CertificateTrackingPayload,
  CertificateTrackingPayload
>('certificates_linkedin_share_clicked' satisfies Identifier, input => {
  return {
    ...input,
  }
})

export const downloadClicked = Logger.trackLoggerWithExtra<
  CertificateTrackingPayload,
  CertificateTrackingPayload
>('certificates_download_clicked', input => {
  return {
    ...input,
  }
})

type IssueToPayload = CertificateTrackingPayload & {
  nIssuedTo: number
}
export const issueToClicked = Logger.trackLoggerWithExtra<IssueToPayload, IssueToPayload>(
  'certificates_issue_to_clicked' satisfies Identifier,
  input => {
    return {
      ...input,
    }
  }
)

export type BulkEditPayload = CertificateTrackingPayload & {
  file: boolean
  note: boolean
  issuedDate: boolean
  expiryDate: boolean
  issuer: boolean
}

export const bulkEditEdit = Logger.trackLoggerWithExtra<BulkEditPayload, BulkEditPayload>(
  'certificates_bulk_edit_edit' satisfies Identifier,
  input => {
    return {
      ...input,
    }
  }
)

export const created = Logger.trackLoggerWithExtra('certificates_created' satisfies Identifier, input => {
  return {
    ...input,
  }
})

export const deleted = Logger.trackLoggerWithExtra<CertificateTrackingPayload, CertificateTrackingPayload>(
  'certificates_deleted' satisfies Identifier,
  input => {
    return {
      ...input,
    }
  }
)

export const edited = Logger.trackLoggerWithExtra<CertificateTrackingPayload, CertificateTrackingPayload>(
  'certificates_edited' satisfies Identifier,
  input => {
    return {
      ...input,
    }
  }
)

export const addGroupToIssuing = Logger.trackLoggerWithExtra<
  CertificateTrackingPayload & { groupId: string; numberOfMembers: number },
  CertificateTrackingPayload & { groupId: string; numberOfMembers: number }
>('certificates_add-group-to-issuing' satisfies Identifier, input => {
  return {
    ...input,
  }
})
