import { ErrorBoundaryFallbackComponent } from 'sierra-client/error/strategic-error-boundary'
import { useTranslation } from 'sierra-client/hooks/use-translation'
import { Button, Text } from 'sierra-ui/primitives'
import styled from 'styled-components'

const ErrorContainer = styled.div`
  height: 100%;
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
`

const ErrorText = styled.div`
  max-width: 300px;
  margin: 0 auto;
`

const ReloadButton = styled(Button).attrs({ variant: 'ghost' })`
  margin-top: 16px;
`

export const WidgetErrorCard: React.FC<{ reload?: () => void }> = ({ reload }) => {
  const { t } = useTranslation()

  return (
    <ErrorContainer>
      <ErrorText>
        <Text color='foreground/muted'>{t('manage.insights.error-boundary')}</Text>
      </ErrorText>
      {reload !== undefined ? <ReloadButton onClick={reload}>{t('dictionary.reload')}</ReloadButton> : null}
    </ErrorContainer>
  )
}

export const widgetErrorBoundaryComponent: (
  reloadWidget?: () => void
) => ErrorBoundaryFallbackComponent = reloadWidget => {
  return () => <WidgetErrorCard reload={reloadWidget} />
}
