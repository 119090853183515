import { useMemo } from 'react'
import { resolveUserAvatar } from 'sierra-client/api/content'
import { RaisingHand } from 'sierra-client/components/liveV2/raising-hand'
import { LiveParticipant } from 'sierra-client/state/live-session/types'
import { useUsersLegacy } from 'sierra-client/state/users/hooks'
import { commaSeparatedList, getUserName, isDefined } from 'sierra-domain/utils'
import { AvatarStack, Tooltip } from 'sierra-ui/components'
import styled from 'styled-components'

const AdditionalParticipantsContainer = styled.div`
  position: relative;
  align-items: center;
  justify-content: center;
  display: flex;
  height: 100%;
  width: 100%;
`

const TextContainer = styled.div`
  display: flex;
  flex-direction: row;
  position: absolute;
  bottom: 10%;
  width: 100%;
  max-width: 100%;
  padding: 0 1rem;
  justify-content: center;
`

const Text = styled.div`
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  text-shadow:
    0px 1px 1px rgba(0, 0, 0, 0.35),
    0px 2px 6px rgba(0, 0, 0, 0.24);
  font-weight: 500;
  cursor: default;
  font-size: 0.875rem;
  color: rgba(255, 255, 255, 1);
`

const RaisedHandsContainer = styled.div`
  position: absolute;
  left: 8px;
  top: 8px;
  display: flex;
  flex-direction: row;
  gap: 4px;
  flex-wrap: wrap;
`

type AdditionalParticipantsProps = {
  participants: LiveParticipant[]
}

export const AdditionalParticipants = ({ participants }: AdditionalParticipantsProps): JSX.Element => {
  const uuids = useMemo(() => participants.map(({ userId }) => userId).filter(isDefined), [participants])
  const resolvedUsers = useUsersLegacy(uuids)
  const definedResolvedUsers = resolvedUsers.filter(isDefined).map(resolveUserAvatar)

  const names = commaSeparatedList(resolvedUsers.map(getUserName).filter(isDefined))

  return (
    <AdditionalParticipantsContainer>
      <AvatarStack size={'medium'} max={3} withTooltips users={definedResolvedUsers} />
      <TextContainer>
        <Tooltip title={names}>
          <Text color='LEGACY_DEFAULT_TEXT_COLOR_REPLACE_ASAP'>{`+${uuids.length} others`}</Text>
        </Tooltip>
      </TextContainer>
      <RaisedHandsContainer>
        {resolvedUsers.map(
          user =>
            user && (
              <Tooltip key={user.uuid} title={user.firstName}>
                <RaisingHand userId={user.uuid} />
              </Tooltip>
            )
        )}
      </RaisedHandsContainer>
    </AdditionalParticipantsContainer>
  )
}
