import _ from 'lodash'
import { useEffect, useMemo, useState } from 'react'
import { usePost } from 'sierra-client/hooks/use-post'
import { useSelector } from 'sierra-client/state/hooks'
import { selectUser } from 'sierra-client/state/user/user-selector'
import { EditionWithStatus } from 'sierra-domain/api/content-v2'
import { XRealtimeContentGetEditionStatuses } from 'sierra-domain/routes'

type UseSortedEditionsParams = {
  courseGroupId: string | undefined
}

type UseSortedEditionsReturnType = EditionWithStatus[] | undefined

export const useSortedEditions = ({
  courseGroupId,
}: UseSortedEditionsParams): UseSortedEditionsReturnType => {
  const { postWithUserErrorException } = usePost()
  const user = useSelector(selectUser)

  const [editionStatuses, setEditionStatuses] = useState<Record<string, EditionWithStatus> | undefined>(
    undefined
  )

  useEffect(() => {
    if (courseGroupId === undefined) {
      return
    }

    let cancelled = false

    // TODO(course-editions): What happens if this isn't a course group?
    void postWithUserErrorException(XRealtimeContentGetEditionStatuses, {
      courseGroupId,
    }).then(res => {
      if (!cancelled) {
        setEditionStatuses(res.editions)
      }
    })

    return () => {
      cancelled = true

      // This effect only runs when the course group id changes. We don't want to persist old data in that case. Always reset.
      setEditionStatuses(undefined)
    }
  }, [postWithUserErrorException, courseGroupId])

  const editionsWithStatus = useMemo<EditionWithStatus[] | undefined>(() => {
    if (editionStatuses === undefined || user === undefined) return undefined

    return _.orderBy(
      editionStatuses,
      [
        // Editions with progress come first, sorted from latest progress to earliest
        edition => edition.status.progressAt ?? '',
        // If any edition matches the user's UI language, that should be preferred over the default edition
        edition => (edition.data.language === user.language ? '1' : '0'),
        // The default edition comes before other editions
        edition => (edition.data.isDefault ? '1' : '0'),
        // Rest of editions are sorted alphabetically by their titles
        edition => edition.data.title,
      ],
      ['desc', 'desc', 'desc', 'asc']
    )
  }, [user, editionStatuses])

  return editionsWithStatus
}
