import { useStableFunction } from 'sierra-client/hooks/use-stable-function'
import {
  useMultiSelectCreateModuleAndMoveFiles,
  useMultiSelectDuplicateFilesCreatedByUser,
} from 'sierra-client/state/flexible-content/factory'
import { navigateToCreateContentId } from 'sierra-client/state/flexible-content/navigate'
import {
  selectClosestFileIdWhenDeleting,
  selectNodeParentFolderId,
} from 'sierra-client/state/flexible-content/selectors'
import { useSelector } from 'sierra-client/state/hooks'
import { useCreatePageContext } from 'sierra-client/views/flexible-content/create-page-context'
import { useBulkCopyCardToClipboard } from 'sierra-client/views/flexible-content/editor/content-sidebar/copy-paste-utils'
import { useMultiSelection } from 'sierra-client/views/flexible-content/editor/content-sidebar/multi-selection/hooks/use-multi-selection'
import { useReversibleEditorAction } from 'sierra-client/views/flexible-content/undo-redo/use-reversible-editor-action'
import { ScopedCreateContentId } from 'sierra-domain/collaboration/types'
import { createFolder, Folder } from 'sierra-domain/flexible-content/types'
import { isNonEmptyArray } from 'sierra-domain/utils'

type UseMultiSelectionDelete = {
  deleteMultiSelection: () => void
}

export const useMultiSelectionDelete = (): UseMultiSelectionDelete => {
  const { selection, clearSelection } = useMultiSelection()
  const applyReversibleAction = useReversibleEditorAction()
  const { createContentId, scopedCreateContentId } = useCreatePageContext()
  const parentFolderId = useSelector(state => selectNodeParentFolderId(state, createContentId))
  const closestFileWhenDeleting = useSelector(state =>
    selectClosestFileIdWhenDeleting(state, createContentId, parentFolderId, Object.keys(selection).at(-1))
  )

  const deleteMultiSelection = (): void => {
    const removeFile = (): void => {
      const files = Object.values(selection).map(({ file }) => file)

      if (isNonEmptyArray(files)) {
        applyReversibleAction({ type: 'remove-files', files })
      }
    }
    clearSelection()

    void navigateToCreateContentId({
      scopedCreateContentId,
      nodeId: closestFileWhenDeleting,
    }).then(() => {
      removeFile()
    })
  }

  return {
    deleteMultiSelection,
  }
}

type useMultiSelectionDuplicate = {
  duplicateMultiSelection: () => void
}

export const useMultiSelectionDuplicate = (): useMultiSelectionDuplicate => {
  const { selection, clearSelection } = useMultiSelection()

  const duplicateFiles = useMultiSelectDuplicateFilesCreatedByUser({
    onSuccess: () => {
      clearSelection()
    },
  })

  const duplicateMultiSelection = (): void => {
    if (duplicateFiles.isPending) {
      return
    }
    duplicateFiles.mutate({ originalFiles: Object.values(selection) })
  }

  return {
    duplicateMultiSelection,
  }
}

type useMultiSelectionCreateModule = {
  createModuleMultiSelection: () => void
}

export const useMultiSelectionCreateModule = (): useMultiSelectionCreateModule => {
  const { selection, clearSelection } = useMultiSelection()
  const { scopedCreateContentId } = useCreatePageContext()

  const isSelfPaced = ScopedCreateContentId.isSelfPacedId(scopedCreateContentId)
  const lastFileInSelection = Object.values(selection).at(-1)

  const nextToId =
    lastFileInSelection?.parentFolderId === 'folder:root'
      ? lastFileInSelection.file.id
      : lastFileInSelection?.parentFolderId

  const settings: Folder['settings'] | Record<never, unknown> =
    isSelfPaced === true ? { settings: { reviewEnabled: true } } : {}

  const newFolder = createFolder({ title: 'Module', ...settings })

  const moveFiles = useMultiSelectCreateModuleAndMoveFiles({
    onSuccess: () => {
      clearSelection()
    },
    folderDestination:
      nextToId !== undefined ? { type: 'next-to', nodeId: nextToId } : { type: 'add-at-end' },
    newFolder: newFolder,
  })

  const createModuleMultiSelection = (): void => {
    if (moveFiles.isPending) {
      return
    }
    //Assessment cards can not be in modules so we filter them out from the selection
    const nonAssessmentCardFiles = Object.values(selection).filter(
      ({ file }) => file.data.type !== 'assessment-card'
    )
    moveFiles.mutate({
      originalFiles: nonAssessmentCardFiles,
    })
  }

  return {
    createModuleMultiSelection,
  }
}

type useMultiSelectionCopy = {
  multiSelectionCopy: () => void
  multiSelectionCopyLoading: boolean
}
export const useMultiSelectionCopy = (): useMultiSelectionCopy => {
  const { selection } = useMultiSelection()
  const { operationState, createContentId } = useCreatePageContext()
  const copyCardMutation = useBulkCopyCardToClipboard({
    operationState,
    contentId: createContentId,
  })

  const multiSelectionCopy = useStableFunction((): void => {
    if (copyCardMutation.isPending) {
      return
    }

    copyCardMutation.mutate(
      Object.values(selection)
        .reverse()
        .map(({ file, parentFolderId }) => {
          return {
            fileId: file.id,
            parentFolderId,
          }
        })
    )
  })

  return {
    multiSelectionCopy,
    multiSelectionCopyLoading: copyCardMutation.isPending,
  }
}
