import { useEffect, useRef } from 'react'
import { useDispatch } from 'sierra-client/state/hooks'
import { Entity } from 'sierra-domain/entity'
import { Embed as EmbedBlock } from 'sierra-domain/v3-author'
import { decode, unwrap } from 'sierra-domain/zod-extensions'
import { z } from 'zod'

const IframeMessage = z.discriminatedUnion('event', [
  z.object({
    event: z.enum(['play', 'ended']),
    context: z.literal('player.js'),
    listener: z.string(),
  }),
  z.object({
    event: z.literal('timeupdate'),
    value: z.object({
      seconds: z.number(),
      duration: z.number(),
    }),
    context: z.literal('player.js'),
    listener: z.string(),
  }),
  z.object({
    context: z.literal('player.js'),
    event: z.literal('ready'),
    value: z.object({
      src: z.string(),
    }),
  }),
])

function assertIsString(val: unknown): string {
  if (typeof val !== 'string') throw new Error(`Expected ${JSON.stringify(val)} to be string`)
  return val
}

const events = ['getDuration', 'timeupdate', 'play', 'ended']

const getOrigin = (iframe: HTMLIFrameElement | undefined): string | undefined => {
  try {
    if (iframe?.src !== undefined) {
      return new URL(iframe.src).origin
    }
  } catch (e) {
    return undefined
  }
}

export const usePreventVideoSeeking = ({
  embedEl,
  block,
  onCompletion,
  hasFinishedBlock,
}: {
  embedEl: HTMLDivElement | null
  block: Entity<EmbedBlock>
  hasFinishedBlock: boolean
  onCompletion: () => void
}): void => {
  const currentTimeRef = useRef(0)
  const durationRef = useRef<number>(0)

  const dispatch = useDispatch()

  useEffect(() => {
    if (!embedEl || !(block.preventSeeking ?? false) || hasFinishedBlock) return

    const iframe = embedEl.getElementsByTagName('iframe')[0]

    const origin = getOrigin(iframe)

    if (iframe === undefined || (origin !== 'http://cdn.iframe.ly' && origin !== 'https://cdn.iframe.ly')) {
      // if block is marked with preventSeeking = true but doesn't have a valid iframely url
      // mark it as completed from the start
      onCompletion()
      return
    }

    const postMessageToPlayerjsIframe = (message: Record<string, string | number>): void => {
      iframe.contentWindow?.postMessage(
        JSON.stringify({
          context: 'player.js',
          listener: block.id,
          ...message,
        }),
        origin
      )
    }

    const messageHandler = (e: MessageEvent): void => {
      try {
        const data = unwrap(decode(IframeMessage, JSON.parse(assertIsString(e.data))))
        // Init listeners
        if (data.event === 'ready' && data.value.src === iframe.src) {
          events.forEach(event =>
            postMessageToPlayerjsIframe({
              method: 'addEventListener',
              value: event,
            })
          )
        } else if (data.event === 'timeupdate' && data.listener === block.id) {
          durationRef.current = data.value.duration

          // If user tries to look ahead, bring them back to latest saved time ref
          if (currentTimeRef.current + 1 < data.value.seconds) {
            postMessageToPlayerjsIframe({
              method: 'setCurrentTime',
              value: currentTimeRef.current,
            })
          } else {
            // update current time ref
            // when playing a video, we get a 'timeupdate' message each 500ms
            currentTimeRef.current = data.value.seconds
          }
        } else if (data.event === 'ended' && data.listener === block.id) {
          // eslint-disable-next-line @typescript-eslint/no-unnecessary-condition
          if (durationRef.current !== undefined && currentTimeRef.current + 1 > durationRef.current) {
            // Mark block as finished
            onCompletion()
          } else {
            // This happens when user skips ahead right before the end
            // 'end' comes right before the previous condition brings the timer
            // back to the previous position. We need to redo this here.
            postMessageToPlayerjsIframe({
              method: 'setCurrentTime',
              // eslint-disable-next-line @typescript-eslint/no-unnecessary-condition
              value: currentTimeRef.current ?? 0,
            })
            postMessageToPlayerjsIframe({
              method: 'play',
            })
          }
        } else if (data.event === 'play') {
          // Prevent multiple videos from playing at the same time
          if (data.listener !== block.id) {
            postMessageToPlayerjsIframe({
              method: 'pause',
            })
          }
        }
      } catch (err) {
        return
      }
    }
    window.addEventListener('message', messageHandler)

    return () => {
      window.removeEventListener('message', messageHandler)
      events.forEach(event =>
        postMessageToPlayerjsIframe({
          method: 'removeEventListener',
          value: event,
        })
      )
    }
  }, [block, embedEl, onCompletion, hasFinishedBlock, dispatch])
}
