import React from 'react'
import { ActionModal } from 'sierra-client/components/common/modals/action-modal'
import { useTranslation } from 'sierra-client/hooks/use-translation'

interface DeleteCourseModal {
  onDelete: () => Promise<void>
  isOpen: boolean
  onClose: () => void
}

export const DeleteCourseModal: React.FC<DeleteCourseModal> = ({ onDelete, isOpen, onClose }) => {
  const { t } = useTranslation()

  return (
    <ActionModal
      title={t('admin.organization.courses.delete-course')}
      open={isOpen}
      onClose={onClose}
      deleteAction
      primaryActionLabel={t('admin.delete')}
      primaryAction={() => {
        void onDelete()
        onClose()
      }}
    >
      {t('admin.confirm-delete-course')}
    </ActionModal>
  )
}
