import { useCallback, useRef } from 'react'
import { VideoPlayer } from 'sierra-client/components/blocks/video'
import { VideoJsPlayer } from 'sierra-client/components/blocks/video/types'
import { RouterLink } from 'sierra-client/components/common/link'
import { HomeworkFileIcon } from 'sierra-client/views/v3-author/homework/homework-atoms'
import { HomeworkFileWithUrl, MimeType } from 'sierra-domain/homework'
import { Tooltip } from 'sierra-ui/components'
import { Text, View } from 'sierra-ui/primitives'
import { palette, token } from 'sierra-ui/theming'
import styled from 'styled-components'

const StyledRouterLink = styled(RouterLink)`
  display: flex;
  width: 100%;
  height: 10rem;
  background-color: ${palette.grey[2]};
  border-radius: ${p => p.theme.borderRadius.regular};

  align-items: center;
  justify-content: center;
  transition: all 150ms cubic-bezier(0.25, 0.1, 0.25, 1);
  cursor: pointer;

  &:hover {
    background: ${palette.grey[5]};

    & > * {
      color: ${palette.primitives.black};
      transition: all 150ms cubic-bezier(0.25, 0.1, 0.25, 1);
    }
  }
`

const SubmissionWrapper = styled(View)`
  width: 100%;
  height: 100%;
  border-radius: ${p => p.theme.borderRadius['size-8']};
  overflow: hidden;
  background-color: ${palette.grey[2]};
  align-items: center;
  justify-content: center;
`

type FilePreviewProps = { fileInfo: HomeworkFileWithUrl }

const VideoWrapper = styled.div`
  width: 100%;
  aspect-ratio: 16 / 9;
  background: ${palette.primitives.black};
`

type VideoFilePreviewProps = { autoplay?: boolean }

const VideoFilePreivew: React.FC<FilePreviewProps & VideoFilePreviewProps> = ({
  fileInfo,
  autoplay = true,
}) => {
  const playerRef = useRef<VideoJsPlayer | null>(null)

  const onReady = useCallback(
    (player: VideoJsPlayer) => {
      playerRef.current = player
    },
    [playerRef]
  )

  return (
    <SubmissionWrapper>
      <VideoWrapper>
        <VideoPlayer
          onReady={onReady}
          options={{
            autoplay,
            bigPlayButton: true,
            controls: true,
            controlBar: { pictureInPictureToggle: false },
            preload: 'auto',
            fill: true,
            playbackRates: [0.5, 0.75, 1, 1.25, 1.5, 1.75, 2, 5],
            sources: [{ src: fileInfo.url, type: 'video/mp4' }],
          }}
        />
      </VideoWrapper>
    </SubmissionWrapper>
  )
}

const ImageWrapper = styled.div`
  width: 100%;
  aspect-ratio: 16 / 9;
  background: ${palette.primitives.black};
  position: relative;

  > img {
    object-fit: contain;
  }
`

type ImageFilePreivewProps = { imageSizes?: string | undefined }

const ImageFilePreview: React.FC<FilePreviewProps & ImageFilePreivewProps> = ({ fileInfo }) => {
  return (
    <SubmissionWrapper>
      <ImageWrapper>
        <img width='100%' src={fileInfo.url} alt='Exercise submission' />
      </ImageWrapper>
    </SubmissionWrapper>
  )
}

const PdfWrapper = styled.div`
  width: 100%;
  aspect-ratio: 4 / 3;

  > iframe {
    object-fit: contain;
  }
`

const PdfFilePreivew: React.FC<FilePreviewProps> = ({ fileInfo }) => {
  return (
    <SubmissionWrapper>
      <PdfWrapper>
        <iframe src={fileInfo.url} height='100%' width='100%' />
      </PdfWrapper>
    </SubmissionWrapper>
  )
}

export const IconPreviewWrapper = styled(View)`
  width: 100%;
  height: 100%;
  border-radius: ${p => p.theme.borderRadius['size-8']};
  overflow: hidden;
  background-color: ${token('surface/soft')};
  align-items: center;
  justify-content: center;
`

const IconPreview: React.FC<FilePreviewProps> = ({ fileInfo }) => {
  return (
    <IconPreviewWrapper>
      <HomeworkFileIcon mimeType={fileInfo.mimeType} />
    </IconPreviewWrapper>
  )
}

const NameIconWrapper = styled(View)`
  width: 100%;
  height: 100%;
  align-items: center;
  justify-content: center;
  flex-direction: column;
`

const FileNameWrapper = styled(Text).attrs({ color: 'foreground/muted', bold: true })``

export const FilePreview: React.FC<
  FilePreviewProps & VideoFilePreviewProps & { renderIconForMimeType?: Partial<Record<MimeType, boolean>> }
> = ({ fileInfo, autoplay, renderIconForMimeType }) => {
  if (renderIconForMimeType !== undefined && renderIconForMimeType[fileInfo.mimeType] === true) {
    return <IconPreview fileInfo={fileInfo} />
  }

  switch (fileInfo.mimeType) {
    case 'image/gif':
    case 'image/jpeg':
    case 'image/png':
      return <ImageFilePreview fileInfo={fileInfo} />
    case 'video/mp4':
      return <VideoFilePreivew fileInfo={fileInfo} autoplay={autoplay} />
    case 'application/pdf':
      return <PdfFilePreivew fileInfo={fileInfo} />
    default:
      return (
        <Tooltip title='Download'>
          <StyledRouterLink href={fileInfo.url} target='_blank' rel='noopener noreferrer'>
            <NameIconWrapper>
              <HomeworkFileIcon mimeType={fileInfo.mimeType} />
              <FileNameWrapper>{fileInfo.fileName}</FileNameWrapper>
            </NameIconWrapper>
          </StyledRouterLink>
        </Tooltip>
      )
  }
}
