import { Skeleton, Text, View } from 'sierra-ui/primitives'
import { spacing } from 'sierra-ui/theming'
import { fonts } from 'sierra-ui/theming/fonts'
import styled from 'styled-components'

const Timestamp = styled(View)``

const Username = styled(Text)``

const SegmentHeadline = styled.div`
  display: flex;
  flex-direction: row;
  gap: 8px;
  flex-grow: 1;
`

const SegmentContent = styled(View)``

const StyledSegment = styled.div`
  display: flex;
  flex-direction: row;
  gap: 2rem;
  align-items: flex-start;

  margin-bottom: 1.25rem;
  border-radius: ${p => p.theme.borderRadius.regular};
  ${fonts.body.small};

  mark {
    position: relative;
    font-weight: normal;
    color: ${p => p.theme.color.grey95};
    background-color: ${p => p.theme.color.yellowBright};
    padding: 2px 1px;
    margin: -2px -1px;
    border-radius: 4px;
  }
`
const PlaceholderUl = styled.ul`
  p,
  p::placeholder {
    ${fonts.body.small};
  }

  .ProseMirror,
  .ProseMirror [data-placeholder] {
    ${fonts.body.small};
  }

  color: ${p => p.theme.color.grey15};
  ul,
  ol {
    list-style-type: disc;
    list-style-position: outside;
    padding-left: 1rem;
  }

  li::marker {
    color: ${p => p.theme.color.grey75};
  }

  li p:first-child {
    margin-bottom: ${spacing['4']};
  }

  li p:first-child::first-letter {
    text-transform: uppercase;
  }

  li p:not(:first-child) {
    color: ${p => p.theme.color.grey50};
    font-weight: ${fonts.weight.regular};
  }

  li {
    margin-left: 1.1rem;
    margin-bottom: ${spacing['24']};
    font-weight: ${fonts.weight.medium};
    line-height: 150%;
  }

  li p {
    margin-left: 12px;
  }
`

export const SummaryListLoading: React.FC = () => {
  return (
    <>
      <PlaceholderUl>
        <li>
          <Text color='currentColor'>
            <Skeleton $width='60%' $height={12} $radius={4} />
          </Text>
          <Text color='LEGACY_DEFAULT_TEXT_COLOR_REPLACE_ASAP'>
            <Skeleton $width='50%' $height={12} $radius={4} />
          </Text>
        </li>
        <li>
          <Text color='currentColor'>
            <Skeleton $width='60%' $height={12} $radius={4} />
          </Text>
          <Text color='LEGACY_DEFAULT_TEXT_COLOR_REPLACE_ASAP'>
            <Skeleton $width='50%' $height={12} $radius={4} />
          </Text>
        </li>
        <li>
          <Text color='currentColor'>
            <Skeleton $width='60%' $height={12} $radius={4} />
          </Text>
          <Text color='LEGACY_DEFAULT_TEXT_COLOR_REPLACE_ASAP'>
            <Skeleton $width='50%' $height={12} $radius={4} />
          </Text>
        </li>
      </PlaceholderUl>
    </>
  )
}

export const SummaryTextLoading: React.FC = () => {
  return (
    <View direction='column'>
      <Text color='currentColor'>
        <Skeleton $width='85%' $height={12} $radius={4} />
      </Text>
      <Text color='currentColor'>
        <Skeleton $width='80%' $height={12} $radius={4} />
      </Text>
      <Text color='LEGACY_DEFAULT_TEXT_COLOR_REPLACE_ASAP'>
        <Skeleton $width='50%' $height={12} $radius={4} />
      </Text>
    </View>
  )
}

export const TranscriptionLoading: React.FC = () => {
  return (
    <>
      <StyledSegment>
        <Timestamp>
          <Text color='LEGACY_DEFAULT_TEXT_COLOR_REPLACE_ASAP' size='micro'>
            <Skeleton $width={'48px'} $height={'21px'} $radius={48} />
          </Text>
        </Timestamp>
        <SegmentContent direction='column' grow>
          <SegmentHeadline>
            <Username size='small' color='white' bold>
              <Skeleton $width={'120px'} $height={'12px'} $radius={4} />
            </Username>
          </SegmentHeadline>
          <Text color='currentColor'>
            <Skeleton $width='100%' $height={'12px'} $radius={4} />
          </Text>
          <Text color='currentColor'>
            <Skeleton $width='80%' $height={'12px'} $radius={4} />
          </Text>
        </SegmentContent>
      </StyledSegment>
      <StyledSegment>
        <Timestamp>
          <Text color='LEGACY_DEFAULT_TEXT_COLOR_REPLACE_ASAP' size='micro'>
            <Skeleton $width={'48px'} $height={'21px'} $radius={48} />
          </Text>
        </Timestamp>
        <SegmentContent direction='column' grow>
          <SegmentHeadline>
            <Username size='small' color='white' bold>
              <Skeleton $width={'120px'} $height={'12px'} $radius={4} />
            </Username>
          </SegmentHeadline>
          <Text color='currentColor'>
            <Skeleton $width='100%' $height={'12px'} $radius={4} />
          </Text>
          <Text color='currentColor'>
            <Skeleton $width='80%' $height={'12px'} $radius={4} />
          </Text>
        </SegmentContent>
      </StyledSegment>
      <StyledSegment>
        <Timestamp>
          <Text color='LEGACY_DEFAULT_TEXT_COLOR_REPLACE_ASAP' size='micro'>
            <Skeleton $width={'48px'} $height={'21px'} $radius={48} />
          </Text>
        </Timestamp>
        <SegmentContent direction='column' grow>
          <SegmentHeadline>
            <Username size='small' color='white' bold>
              <Skeleton $width={'120px'} $height={'12px'} $radius={4} />
            </Username>
          </SegmentHeadline>
          <Text color='currentColor'>
            <Skeleton $width='100%' $height={'12px'} $radius={4} />
          </Text>
          <Text color='currentColor'>
            <Skeleton $width='80%' $height={'12px'} $radius={4} />
          </Text>
        </SegmentContent>
      </StyledSegment>
      <StyledSegment>
        <Timestamp>
          <Text color='LEGACY_DEFAULT_TEXT_COLOR_REPLACE_ASAP' size='micro'>
            <Skeleton $width={'48px'} $height={'21px'} $radius={48} />
          </Text>
        </Timestamp>
        <SegmentContent direction='column' grow>
          <SegmentHeadline>
            <Username size='small' color='white' bold>
              <Skeleton $width={'120px'} $height={'12px'} $radius={4} />
            </Username>
          </SegmentHeadline>
          <Text color='currentColor'>
            <Skeleton $width='100%' $height={'12px'} $radius={4} />
          </Text>
          <Text color='currentColor'>
            <Skeleton $width='80%' $height={'12px'} $radius={4} />
          </Text>
        </SegmentContent>
      </StyledSegment>
    </>
  )
}
