import { FadedRadioDotIcon } from 'sierra-client/components/recommendations/components/faded-radio-dot-icon'
import { RecommendationContentIcon } from 'sierra-client/components/recommendations/components/recommendation-content-icon'
import { useTranslation } from 'sierra-client/hooks/use-translation'
import { TranslationKey } from 'sierra-client/hooks/use-translation/types'
import { CoursePublishStateLabel } from 'sierra-client/views/workspace/components/content-table-row-components'
import { CreateEntity } from 'sierra-domain/api/entities'
import { Recommendation } from 'sierra-domain/api/recommendations'
import { iife } from 'sierra-domain/utils'
import { Spacer, Text, View } from 'sierra-ui/primitives'

const entityTypeToTranslationKey: Record<CreateEntity['entityType'], TranslationKey> = {
  'native:live': 'dictionary.live',
  'native:self-paced': 'dictionary.course-singular',
  'path': 'dictionary.path-singular',
}

export const CreateEntityDetail: React.FC<{
  recommendationType: Recommendation['card']['type']
  entity: CreateEntity
}> = ({ recommendationType, entity }) => {
  const { t } = useTranslation()

  return (
    <View gap='none'>
      <RecommendationContentIcon type={entity.entityType} color='foreground/primary' faded />
      <Spacer size='4' />
      <View gap='none'>
        <Text bold size='small'>
          {t(entityTypeToTranslationKey[entity.entityType])}
        </Text>
        {iife(() => {
          if (
            entity.entityType === 'native:self-paced' &&
            ['recently-edited', 'continue-editing'].includes(recommendationType)
          ) {
            return (
              <>
                <Spacer size='xxsmall' />
                <FadedRadioDotIcon />
                <Spacer size='xxsmall' />
                <CoursePublishStateLabel
                  published={entity.creatorContext.isPublished}
                  pending={entity.creatorContext.isPending}
                />
              </>
            )
          }

          return undefined
        })}
      </View>
    </View>
  )
}
