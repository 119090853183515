import { createContext, ReactNode, useCallback, useContext, useMemo, useState } from 'react'
import {
  ActionModal,
  ActionModalProps,
  OnCloseReason,
} from 'sierra-client/components/common/modals/action-modal'
import { useTranslation } from 'sierra-client/hooks/use-translation'
import { Text } from 'sierra-ui/primitives'
import { legacyLight } from 'sierra-ui/theming/legacy-theme'
import styled, { ThemeProvider } from 'styled-components'

type ShowOptions = {
  bodyText: string
  confirmLabel?: string
  deleteAction?: boolean
  onConfirm: () => void
  onSecondaryAction?: () => void
  secondaryActionLabel?: string
  title?: string
  onAborted?: () => void
  hideCloseIcon?: boolean
  onlyAllowCloseFor?: OnCloseReason
}

export type ConfirmationModalContext = {
  close: () => void
  show: (options: ShowOptions) => void
  isOpen: boolean
}

const ConfirmationModalContext = createContext<ConfirmationModalContext | undefined>(undefined)

const BodyText = styled(Text)`
  max-width: 60ch;
`

export const ConfirmationModalProvider = ({ children }: { children: ReactNode }): JSX.Element => {
  const { t } = useTranslation()
  const [options, setOptions] = useState<ShowOptions>()
  const [open, setOpen] = useState<boolean>(false)

  const show = useCallback(
    (options: ShowOptions): void => {
      setOptions(options)
      setOpen(true)
    },
    [setOptions, setOpen]
  )

  const close = useCallback(() => {
    setOpen(false)
  }, [])

  const handleOnClose = useCallback<ActionModalProps['onClose']>(
    reason => {
      if (options?.onlyAllowCloseFor !== undefined) {
        if (options.onlyAllowCloseFor === reason) {
          close()
        }
      } else {
        close()
      }

      if (reason === 'backdropClick' || reason === 'escapeKeyDown' || reason === 'closeButtonClicked') {
        options?.onAborted?.()
      }
    },
    [close, options]
  )

  const value = useMemo(() => ({ show, close, isOpen: open }), [show, close, open])

  return (
    <ConfirmationModalContext.Provider value={value}>
      <ThemeProvider theme={legacyLight}>
        <ActionModal
          open={open}
          hideCloseIcon={options?.hideCloseIcon}
          onClose={handleOnClose}
          title={options?.title ?? undefined}
          deleteAction={options?.deleteAction}
          primaryAction={() => {
            if (options?.onConfirm) {
              options.onConfirm()
            }
          }}
          primaryActionLabel={options?.confirmLabel ?? t('dictionary.continue')}
          secondaryAction={() => {
            if (options?.onSecondaryAction) options.onSecondaryAction()
          }}
          secondaryActionLabel={options?.secondaryActionLabel}
        >
          <BodyText>{options?.bodyText !== undefined ? options.bodyText : null}</BodyText>
        </ActionModal>
      </ThemeProvider>
      {children}
    </ConfirmationModalContext.Provider>
  )
}

export const useConfirmationModalContext = (): ConfirmationModalContext => {
  const context = useContext(ConfirmationModalContext)
  if (context === undefined) {
    throw new Error('This component must be wrapped in a ConfirmationModalProvider')
  }
  return context
}
