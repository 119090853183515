import { useAtomValue } from 'jotai/index'
import { RefObject, createContext, createRef, useCallback, useContext, useLayoutEffect } from 'react'
import useMeasure from 'react-use-measure'
import { helperStateAtom } from 'sierra-client/views/course-helper/atoms'
import { HelperSize } from 'sierra-client/views/course-helper/types'

const HelperAnimationContext = createContext<{
  ref: RefObject<HTMLDivElement>
  size: HelperSize
  measure: ReturnType<typeof useMeasure>[0]
  animationEnabled: boolean
  setAnimationEnabled: (enabled: boolean) => void
}>({
  ref: createRef<HTMLDivElement>(),
  size: { width: 0, height: 0 },
  measure: () => {},
  animationEnabled: true,
  setAnimationEnabled: () => {},
})

export const HelperAnimationProvider = HelperAnimationContext.Provider

/*
 * The helper can be in two different animation states:
 *
 * - Animating between content updates (grow + shrink), depending on the size of the content
 * - Animating when we collapse / close
 *
 * For the first use case, we require the border to be a separate DOM element, otherwise we can't grow / shrink:
 * we measure the size of the content first (it's rendered with opacity: 0) and then set the size accordingly.
 *
 * As a result, in the second use case, we will need to switch off the animation (set the duration to 0), otherwise
 * the bubble size will "lag behind".
 * */
type UseHelperSize = {
  ref: RefObject<HTMLDivElement>
  size: HelperSize
  animationEnabled: boolean
  updateSize: ({ animationEnabled }: { animationEnabled: boolean }) => void
}

export function useHelperSize(): UseHelperSize {
  const { ref, size, measure, animationEnabled, setAnimationEnabled } = useContext(HelperAnimationContext)
  const state = useAtomValue(helperStateAtom)

  const updateSize: UseHelperSize['updateSize'] = useCallback(
    ({ animationEnabled }): void => {
      setAnimationEnabled(animationEnabled)
      measure(ref.current)
    },
    [ref, setAnimationEnabled, measure]
  )

  /*
   * Wait for the initial render to calculate size; if this is useEffect,
   * this will run once without RenderHelperContent being rendered and the size is initially incorrect
   * */
  useLayoutEffect(() => updateSize({ animationEnabled: true }), [updateSize, state])

  return {
    ref,
    size,
    animationEnabled,
    updateSize,
  }
}
