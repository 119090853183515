import fuzzysort from 'fuzzysort'
import { WritableDraft, produce } from 'immer'
import _ from 'lodash'
import { DateTime } from 'luxon'
import { useCallback, useMemo, useState } from 'react'
import { usePost } from 'sierra-client/hooks/use-post'
import { SelfEnrollSession } from 'sierra-domain/content/session'
import { XRealtimeContentSelfEnroll } from 'sierra-domain/routes'

export type LocalSession = SelfEnrollSession & { loading: boolean }

type State = Record<string, LocalSession>

const initializeSession = (session: SelfEnrollSession): LocalSession => ({
  ...session,
  loading: false,
})

type LoadingArgs = {
  sessionId: string
  loading: boolean
}

const setLoading = produce((draft: WritableDraft<State>, { sessionId, loading }: LoadingArgs) => {
  const session = draft[sessionId]

  if (session !== undefined) {
    session.loading = loading
  }
})

type SuccessArgs = {
  sessionId: string
  enrolled: boolean
  usedSpots: number
  totalSpots: number | undefined
}

const handleSuccess = produce(
  (draft: WritableDraft<State>, { sessionId, enrolled, usedSpots, totalSpots }: SuccessArgs) => {
    const session = draft[sessionId]

    if (session !== undefined) {
      session.isEnrolled = enrolled
      session.usedSpots = usedSpots
      session.totalSpots = totalSpots
    }
  }
)

type InvalidArgs = {
  sessionId: string
  session: SelfEnrollSession | undefined
}

const handleInvalid = produce((draft: WritableDraft<State>, { sessionId, session }: InvalidArgs) => {
  if (session === undefined) {
    delete draft[sessionId]
  } else {
    draft[sessionId] = initializeSession(session)
  }
})

export type SessionAvailabilityFilter = 'any' | 'not-full'
export type SessionSortType = 'date-time-asc' | 'date-time-desc'

const sortFunctions: Record<SessionSortType, (sessions: LocalSession[]) => LocalSession[]> = {
  'date-time-asc': sessions => _.orderBy(sessions, session => session.startTime, 'asc'),
  'date-time-desc': sessions => _.orderBy(sessions, session => session.startTime, 'desc'),
}

type SessionFilter = {
  query?: string
  date?: DateTime
  timeFrom?: string // 24h time (like 21:41 or 09:05)
  timeTo?: string // 24h time
  availability: SessionAvailabilityFilter
  sortType: SessionSortType
}

export type UseSelfEnroll = {
  sessions: State
  sessionList: LocalSession[]
  filteredSessionList: LocalSession[]
  selfEnroll: (sessionId: string, enroll: boolean) => Promise<void>
  filter: SessionFilter
  updateFilter: (values: Partial<SessionFilter>) => void
}

export const useSelfEnroll = (
  initialSessions: SelfEnrollSession[],
  setIsEnrolled?: (sessionId: string, enroll: boolean) => void
): UseSelfEnroll => {
  const { postWithUserErrorException } = usePost()

  const [sessions, setSessions] = useState<State>(() =>
    Object.fromEntries(initialSessions.map(session => [session.sessionId, initializeSession(session)]))
  )

  const [filter, setFilter] = useState<SessionFilter>(() => ({
    availability: 'any',
    sortType: 'date-time-asc',
  }))

  const updateFilter: UseSelfEnroll['updateFilter'] = useCallback(values => {
    setFilter(curr => ({ ...curr, ...values }))
  }, [])

  const sessionList: LocalSession[] = useMemo(() => _.values(sessions), [sessions])

  const filteredSessionList = useMemo(() => {
    return _(sessionList)
      .thru(sessions => {
        if (filter.query !== undefined && filter.query !== '') {
          return fuzzysort.go(filter.query, sessions, { key: ['title'] }).map(({ obj }) => obj)
        } else {
          return sessions
        }
      })
      .filter(session => {
        if (filter.availability === 'not-full') {
          // Filter out sessions that we can't enroll to
          const freeSpots =
            session.totalSpots !== undefined ? Math.max(0, session.totalSpots - session.usedSpots) : undefined

          // If we are enrolled to the session, include it even if full (still counts as "enrollable")
          if (freeSpots === 0 && !session.isEnrolled) return false
        }

        const dt = DateTime.fromISO(session.startTime)
        const time24h = dt.toLocaleString(DateTime.TIME_24_SIMPLE)

        if (filter.date !== undefined && !dt.hasSame(filter.date, 'day')) return false

        // String comparison is OK because we have leading zeros
        if (filter.timeFrom !== undefined && time24h < filter.timeFrom) return false
        if (filter.timeTo !== undefined && time24h > filter.timeTo) return false

        return true
      })
      .thru(sortFunctions[filter.sortType])
      .value()
  }, [sessionList, filter])

  const selfEnroll = useCallback<UseSelfEnroll['selfEnroll']>(
    async (sessionId, enroll) => {
      setSessions(sessions => setLoading(sessions, { sessionId, loading: true }))

      const res = await postWithUserErrorException(XRealtimeContentSelfEnroll, {
        sessionId,
        enroll,
      })

      if (res.type === 'success') {
        setIsEnrolled?.(sessionId, enroll)
        setSessions(sessions =>
          handleSuccess(sessions, {
            sessionId,
            enrolled: enroll,
            usedSpots: res.usedSpots,
            totalSpots: res.totalSpots,
          })
        )
      } else if (res.type === 'invalid') {
        setSessions(sessions => handleInvalid(sessions, { sessionId, session: res.session }))
      }
      // (damjan): handle error response (meaning self-enrollment disabled or course invalid)
      setSessions(sessions => setLoading(sessions, { sessionId, loading: false }))
    },
    [postWithUserErrorException, setIsEnrolled]
  )

  return { selfEnroll, sessions, sessionList, filteredSessionList, filter, updateFilter }
}
