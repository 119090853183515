import { fetchEventSource } from '@microsoft/fetch-event-source'
import { config } from 'sierra-client/config/global-config'
import { Auth } from 'sierra-client/core/auth'
import { AnyZodSseRoute, ZodRouteInput, ZodRouteOutput } from 'sierra-domain/api/types'
import { SseEvent } from 'sierra-domain/routes-sse'

export const postSseZod = async <Route extends AnyZodSseRoute>(
  route: Route,
  input: ZodRouteInput<Route>,
  onMessage: (event: SseEvent<ZodRouteOutput<Route>>) => void,
  onOpen: (response: Response) => Promise<void> | void | undefined,
  onError: (_: unknown) => void,
  onClose?: () => void,
  signal?: AbortSignal
): Promise<void> => {
  const host = config.auth.host
  const url = host !== undefined ? `https://${host}${route.path}` : route.path
  const token = Auth.getInstance().getToken()

  try {
    await fetchEventSource(url, {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
        'Accept': 'text/event-stream',
        ...(token !== undefined ? { Authorization: `Bearer ${token}` } : {}),
      },
      body: JSON.stringify(route.request.parse(input)),
      signal,
      async onopen(res) {
        return onOpen(res)
      },
      onmessage(event) {
        onMessage({
          ...event,
          data: route.value.parse(JSON.parse(event.data)),
        })
      },
      onclose() {
        onClose?.()
      },
      onerror(e) {
        throw e
      },
      openWhenHidden: true,
    })
  } catch (e) {
    return onError(e)
  }
}
