import { Atom } from 'jotai'
import { selectAtom } from 'jotai/utils'
import { Selection } from 'sierra-client/lib/tabular/datatype/selection'
import { Position, RowRef } from 'sierra-client/lib/tabular/types'
import { assertNever } from 'sierra-domain/utils'

export const isSelected = (ref: RowRef, sel: Selection): boolean => {
  switch (sel.type) {
    case 'all':
      return !sel.excluded.has(ref)
    case 'none':
      return false
    case 'manual':
      return sel.rows.has(ref)
    default:
      assertNever(sel)
  }
}

export const selectedAtom = (pos: Position, selectionAtom: Atom<Selection>): Atom<boolean> =>
  selectAtom(selectionAtom, sel => isSelected(pos.row, sel))
