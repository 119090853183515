import { inviteUsersUserInviteOpened } from 'sierra-client/core/logging/users/logger'
import { useTranslation } from 'sierra-client/hooks/use-translation'
import { useDispatch } from 'sierra-client/state/hooks'
import { useUserInvitationDomainLoader } from 'sierra-client/views/manage/components/user-attributes/hooks/use-invitation-domains'
import { Button } from 'sierra-ui/primitives'

export const InviteUsersButton: React.FC<{ onOpen: () => void }> = ({ onOpen }) => {
  const { t } = useTranslation()
  const { isLoading } = useUserInvitationDomainLoader()
  const dispatch = useDispatch()

  if (isLoading) {
    return <></>
  }

  return (
    <>
      <Button
        variant='secondary'
        onClick={() => {
          onOpen()
          void dispatch(inviteUsersUserInviteOpened({}))
        }}
      >
        {t('admin.organization.users.invite-users')}
      </Button>
    </>
  )
}
