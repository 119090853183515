import { AnimatePresence } from 'framer-motion'
import { PermissionFormSection } from 'sierra-client/components/sharing/permission-form-elements'
import { useTranslation } from 'sierra-client/hooks/use-translation'
import { TeamspaceRole, TeamspaceVisibility } from 'sierra-domain/api/teamspace'
import { MenuItem, Tooltip } from 'sierra-ui/components'
import { SingleSelectDropdown } from 'sierra-ui/primitives/menu-dropdown'
import styled from 'styled-components'

export const IncreasedZIndexTooltip = styled(Tooltip)`
  z-index: 35;
`

export const TeamspaceVisibilitySelector: React.FC<{
  disabled?: boolean
  visibility: TeamspaceVisibility
  setVisibility: (vis: TeamspaceVisibility) => void
}> = ({ disabled = false, visibility, setVisibility }) => {
  const { t } = useTranslation()

  const items: MenuItem<TeamspaceVisibility>[] = [
    {
      type: 'label',
      id: 'private',
      label: t('share.label.invite-only'),
    },
    {
      type: 'label',
      id: 'public',
      label: t('teamspace.visibility.public'),
    },
  ]
  const selectedItem = items.find(item => item.id === visibility)

  return (
    <IncreasedZIndexTooltip title={undefined}>
      <div>
        <PermissionFormSection
          iconId='view'
          title={t('teamspace.visibility.title')}
          subtitle={t('teamspace.visibility.sub-title')}
        >
          <AnimatePresence mode='wait' initial={false}>
            <SingleSelectDropdown<TeamspaceVisibility>
              bold={true}
              disabled={disabled}
              selectedItem={selectedItem}
              onSelect={item => {
                setVisibility(item.id)
              }}
              menuItems={items}
            />
          </AnimatePresence>
        </PermissionFormSection>
      </div>
    </IncreasedZIndexTooltip>
  )
}

export const TeamspaceDefaultRoleSelector: React.FC<{
  disabled?: boolean
  defaultRole: TeamspaceRole
  setDefaultRole: (vis: TeamspaceRole) => void
}> = ({ disabled = false, defaultRole, setDefaultRole }) => {
  const { t } = useTranslation()

  const items: MenuItem<TeamspaceRole>[] = [
    {
      type: 'label',
      label: t('author.role-editor'),
      id: 'editor',
    },
    {
      type: 'label',
      label: t('author.role-commenter'),
      id: 'commenter',
    },
    {
      type: 'label',
      label: t('author.role-viewer'),
      id: 'viewer',
    },
  ]

  const selectedItem = items.find(item => item.id === defaultRole)

  return (
    <div>
      <PermissionFormSection
        iconId={'face--add'}
        title={t('teamspace.default-role.title')}
        subtitle={t('teamspace.default-role.sub-title')}
      >
        <AnimatePresence mode='wait' initial={false}>
          <SingleSelectDropdown<TeamspaceRole>
            bold={true}
            disabled={disabled}
            selectedItem={selectedItem}
            onSelect={item => {
              setDefaultRole(item.id)
            }}
            menuItems={items}
          />
        </AnimatePresence>
      </PermissionFormSection>
    </div>
  )
}
