import { atom, useAtomValue, useSetAtom } from 'jotai'
import { useCallback, useMemo } from 'react'
import * as API from 'sierra-client/api'
import { AssetContext } from 'sierra-domain/asset-context'

const fileUploadStatesAtom = atom<{ [nodeId: string]: FileUploadStatus }>({})

type FileUploadStatus = 'idle' | 'uploading' | API.FileUploadResult

export function useEditorFileUploadStatus({ nodeId }: { nodeId: string }): FileUploadStatus {
  return useAtomValue(fileUploadStatesAtom)[nodeId] ?? 'idle'
}

export type ShowUploadFileModal = UseEditorUploadFile['showUploadModal']

type UseEditorUploadFile = {
  showUploadModal: (_: { nodeId: string; assetContext: AssetContext }) => void
  uploadFile: (_: { nodeId: string; file: File; assetContext: AssetContext }) => void
}
export function useEditorUploadFile(): UseEditorUploadFile {
  const setStatus = useSetAtom(fileUploadStatesAtom)

  const showUploadModal = useCallback<UseEditorUploadFile['showUploadModal']>(
    ({ nodeId, assetContext }) => {
      void API.showUploadModal({
        onUploadStarted: () => setStatus(previous => ({ ...previous, [nodeId]: 'uploading' })),
        assetContext,
      }).then(result => {
        setStatus(previous => ({ ...previous, [nodeId]: result }))
      })
    },
    [setStatus]
  )

  const uploadFile = useCallback<UseEditorUploadFile['uploadFile']>(
    ({ file, nodeId, assetContext }) => {
      setStatus(previous => ({ ...previous, [nodeId]: 'uploading' }))
      void API.uploadFile(file, assetContext).then(result => {
        setStatus(previous => ({ ...previous, [nodeId]: result }))
      })
    },
    [setStatus]
  )

  return useMemo(() => ({ showUploadModal, uploadFile }), [showUploadModal, uploadFile])
}
