import { useMemo } from 'react'
import { SkillId, SkillLevelSettingId, SkillRecommendationId } from 'sierra-client/api/graphql/branded-types'
import {
  Content,
  Course,
  SkillLevelSettingInput,
  SkillsSettingsInput,
} from 'sierra-client/api/graphql/gql/graphql'
import { Logging } from 'sierra-client/core/logging'
import { useDispatch } from 'sierra-client/state/hooks'
import { CourseId } from 'sierra-domain/api/nano-id'
import { SkillIconId } from 'sierra-ui/components'
import { BadgeIconId, BadgeTheme } from './components/badges/types'

type SkillTracking = {
  skill: {
    update: (_: { skillId: string; levelDescriptionLengths: number[] }) => void
    create: () => void
    setIcon: (_: { skillId?: SkillId; iconId: SkillIconId }) => void
    clickActionMenu: (_: { skillId: SkillId; action: string }) => void
    clickManageTab: (_: { skillId: SkillId }) => void
    importSanaTaxonomy: (_: { taxonomies: Array<{ title: string }> }) => void
  }
  learner: {
    subscribe: (_: { skillId: string; learnerId: string }) => void
    unsubscribe: (_: { skillId: string; learnerId: string }) => void
    setTargetLevel: (_: { skillId: string; learnerId: string; level: string }) => void
    setAchievedLevel: (_: { skillId: string; learnerId: string; level: string }) => void
    selfSubscribe: (_: { skillId: SkillId }) => void
    selfUnsubscribe: (_: { skillId: SkillId }) => void
    bulkEdit: (_: { targetLevel?: SkillLevelSettingId; achievedLevel?: SkillLevelSettingId }) => void
    clickSkillSubscriptionMenu: () => void
  }
  badge: {
    enterHover: (_: { theme: BadgeTheme; iconId: BadgeIconId }) => void
    leaveHover: (_: { theme: BadgeTheme; iconId: BadgeIconId }) => void
    click: (_: { theme: BadgeTheme; iconId: BadgeIconId }) => void
  }
  org: {
    updateSettings: (_: {
      settings: SkillsSettingsInput
      levels: Pick<SkillLevelSettingInput, 'name' | 'defaultBadgeTheme'>[]
    }) => void
  }
  content: {
    addContent: (_: {
      skillId: SkillId
      content: Array<{ contentType: Content['contentType']; courseKind?: Course['courseKind'] }>
    }) => void
    updateFastTrack: (_: { contentId: string; skillId: SkillId; enabled: boolean }) => void
    removeContent: (_: { contentId: string; skillId: SkillId }) => void
    updateLevel: (_: { contentId: string; level: SkillLevelSettingId }) => void
  }
  smartSuggestion: {
    accept: (_: { skillRecommendationId: SkillRecommendationId }) => void
    reject: (_: { skillRecommendationId: SkillRecommendationId }) => void
    changeLevel: (_: {
      skillId: SkillId
      newLevelSettingId?: SkillLevelSettingId
      prevLevelSettingId?: SkillLevelSettingId
    }) => void
    retrigger: (_: { courseId: CourseId }) => void
  }
}

export const useTracking = (): SkillTracking => {
  const dispatch = useDispatch()

  return useMemo(
    () => ({
      smartSuggestion: {
        accept: ({ skillRecommendationId }) =>
          dispatch(Logging.skill.acceptSmartSuggestion({ skillRecommendationId })),
        reject: ({ skillRecommendationId }) =>
          dispatch(Logging.skill.rejectSmartSuggestion({ skillRecommendationId })),
        changeLevel: ({ skillId, newLevelSettingId, prevLevelSettingId }) =>
          dispatch(
            Logging.skill.changeLevelSmartSuggestion({ skillId, newLevelSettingId, prevLevelSettingId })
          ),
        retrigger: ({ courseId }) => dispatch(Logging.skill.retriggerSmartSuggestion({ courseId })),
      },
      skill: {
        create: () => dispatch(Logging.skill.createNewSkill()),
        update: ({ skillId, levelDescriptionLengths }) =>
          dispatch(Logging.skill.updateSkill({ skillId, levelDescriptionLengths })),
        importSanaTaxonomy: ({ taxonomies }) => dispatch(Logging.skill.importSanaTaxonomy({ taxonomies })),
        setIcon: ({ skillId, iconId }) => dispatch(Logging.skill.pickSkillIcon({ skillId, icon: iconId })),
        clickActionMenu: ({ skillId, action }) =>
          dispatch(Logging.skill.clickSkillActionMenu({ skillId, action })),
        clickManageTab: ({ skillId }) => dispatch(Logging.skill.clickManageTab({ skillId })),
      },
      badge: {
        enterHover: ({ theme, iconId }) => dispatch(Logging.skill.enterBadgeHover({ theme, iconId })),
        leaveHover: ({ theme, iconId }) => dispatch(Logging.skill.leaveBadgeHover({ theme, iconId })),
        click: ({ theme, iconId }) => dispatch(Logging.skill.clickBadge({ theme, iconId })),
      },
      learner: {
        subscribe: ({ skillId, learnerId }) =>
          dispatch(Logging.skill.subscribeLearnerToSkill({ skillId, learnerId })),
        unsubscribe: ({ skillId, learnerId }) =>
          dispatch(Logging.skill.unsubscribeLearnerFromSkill({ skillId, learnerId })),
        setTargetLevel: ({ skillId, learnerId, level }) =>
          dispatch(Logging.skill.setTargetLevel({ skillId, learnerId, level })),
        setAchievedLevel: ({ skillId, learnerId, level }) =>
          dispatch(Logging.skill.setAchievedLevel({ skillId, learnerId, level })),
        selfSubscribe: ({ skillId }) => dispatch(Logging.skill.subscribeSelf({ skillId })),
        selfUnsubscribe: ({ skillId }) => dispatch(Logging.skill.unsubscribeSelf({ skillId })),
        bulkEdit: ({ targetLevel, achievedLevel }) =>
          dispatch(Logging.skill.bulkEditSubscribers({ targetLevel, achievedLevel })),
        clickSkillSubscriptionMenu: () => dispatch(Logging.skill.clickManageSkillSubscriptions()),
      },
      org: {
        updateSettings: ({ settings, levels }) =>
          dispatch(Logging.skill.updateSettings({ settings, levels })),
      },
      content: {
        addContent: ({ skillId, content }) => dispatch(Logging.skill.addContent({ skillId, content })),
        updateFastTrack: ({ contentId, skillId, enabled }) =>
          dispatch(Logging.skill.updateFastTrack({ contentId, skillId, enabled })),
        removeContent: ({ contentId, skillId }) =>
          dispatch(Logging.skill.removeContent({ contentId, skillId })),
        updateLevel: ({ contentId, level }) => dispatch(Logging.skill.updateLevel({ contentId, level })),
      },
    }),
    [dispatch]
  )
}
