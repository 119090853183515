import { EditorContent } from '@tiptap/react'
import { useAtomValue } from 'jotai'
import React from 'react'
import {
  useCreatePageContext,
  useCreatePageYDocContext,
} from 'sierra-client/views/flexible-content/create-page-context'
import { useSpeakerNoteContext } from 'sierra-client/views/flexible-content/editor/content-sidebar/speaker-notes/context'
import { useSpeakerNotesEditor } from 'sierra-client/views/flexible-content/editor/content-sidebar/speaker-notes/speaker-note-editor'
import {
  SpeakerNoteProps,
  SpeakerRenderer,
} from 'sierra-client/views/flexible-content/editor/content-sidebar/speaker-notes/types'
import { usePolarisContext } from 'sierra-client/views/flexible-content/polaris-editor-provider/polaris-editor-provider'
import { speakerNotesSidebarWidthAtom } from 'sierra-client/views/liveV2/speaker-notes-sidebar-width-atom'
import {
  tiptapCursorStyles,
  tiptapPlaceholder,
} from 'sierra-client/views/sticky-notes-card/common/tiptap-styles'
import { ScopedCreateContentId } from 'sierra-domain/collaboration/types'
import { assertNever, iife } from 'sierra-domain/utils'
import { TextSize, View } from 'sierra-ui/primitives'
import { token } from 'sierra-ui/theming'
import { fonts } from 'sierra-ui/theming/fonts'
import { focusRing } from 'sierra-ui/utils'
import styled, { css } from 'styled-components'

const StyledEditorContent = styled(EditorContent)<{ $editable: boolean; textSize?: TextSize }>`
  min-width: 20px;

  ${p =>
    p.$editable &&
    css`
      cursor: text;
    `}
  .ProseMirror,
  .ProseMirror [data-placeholder] {
    ${p => fonts.body[p.textSize ?? 'micro']}
    ${p =>
      !p.$editable &&
      css`
        font-weight: 500;
      `}
  }

  .ProseMirror p.is-editor-empty:first-child::before {
    color: ${token('foreground/muted')};
    content: attr(data-placeholder);
    float: left;
    height: 0;
    pointer-events: none;
  }

  ul,
  ol {
    padding: 0 1rem;
  }

  strong {
    font-weight: 500;

    ${p =>
      !p.$editable &&
      css`
        font-weight: 800;
      `}
  }

  ${p =>
    p.$editable &&
    css`
      cursor: text;
    `};

  ${tiptapPlaceholder};
  ${tiptapCursorStyles};
`

const SpeakerNoteWrapper = styled(View).attrs({
  direction: 'column',
  paddingLeft: '10',
  paddingRight: '10',
  paddingTop: '8',
  paddingBottom: '8',
  radius: 'size-10',
  height: 'auto',
  grow: true,
  gap: '2',
})<{ $active: boolean }>`
  color: ${p => p.theme.color['black']};
  background-color: ${p => p.theme.color['white']};
`

const CreateSpeakerNoteWrapper = styled(SpeakerNoteWrapper).attrs({
  onClick: (e: React.MouseEvent<HTMLElement>) => e.stopPropagation(),
  marginTop: '6',
  marginBottom: '6',
  marginLeft: '24',
  animated: true,
})`
  width: 180px;
  color: ${p => p.theme.color['black']};
  background-color: ${p => p.theme.color['white']};
  overflow: hidden;
  border: 1px solid transparent;
  transition: border-color 150ms cubic-bezier(0.25, 0.5, 0.25, 1);

  ${p => p.$active && focusRing};

  &:hover {
    border-color: ${token('form/border/1')};
  }

  ${p =>
    p.$active &&
    css`
      &:hover {
        border-color: transparent;
      }
    `};
`

const LiveSpeakerNoteWrapper = styled(SpeakerNoteWrapper).attrs({ cursor: 'pointer' })`
  color: ${token('foreground/primary')};
  background-color: ${token('surface/default')};

  ${p =>
    p.$active &&
    css`
      background-color: ${token('border/default')};
    `}
`

const SpeakerNotesImplementation: React.FC<SpeakerNoteProps> = props => {
  const editor = useSpeakerNotesEditor(props)
  const speakerNotesSidebarState = useAtomValue(speakerNotesSidebarWidthAtom)

  const { setFocusedSpeakerNote } = useSpeakerNoteContext()

  if (props.editable) {
    return (
      <CreateSpeakerNoteWrapper $active={props.active}>
        <StyledEditorContent
          $editable={props.editable}
          editor={editor}
          onFocus={() => setFocusedSpeakerNote?.(props.file.id)}
          onBlur={() => setFocusedSpeakerNote?.(undefined)}
        />
      </CreateSpeakerNoteWrapper>
    )
  } else {
    if (editor?.state.doc.textContent.trim().length === 0) {
      return null
    }

    return (
      <LiveSpeakerNoteWrapper $active={props.active}>
        <StyledEditorContent
          $editable={props.editable}
          textSize={iife((): TextSize => {
            switch (speakerNotesSidebarState) {
              case 'small':
                return 'small'
              case 'medium':
                return 'small'
              case 'large':
                return 'small'
              case 'none':
                return 'micro'
              default:
                assertNever(speakerNotesSidebarState)
            }
          })}
          editor={editor}
          onFocus={() => setFocusedSpeakerNote?.(props.file.id)}
          onBlur={() => setFocusedSpeakerNote?.(undefined)}
        />
      </LiveSpeakerNoteWrapper>
    )
  }
}

export const CreateSpeakerNotes: React.FC<SpeakerRenderer> = props => {
  const { scopedCreateContentId } = useCreatePageContext()
  const isLive = ScopedCreateContentId.contentType(scopedCreateContentId) === 'live'
  const { yDoc, awareness } = useCreatePageYDocContext()

  if (!isLive) {
    return null
  }

  return <SpeakerNotesImplementation {...props} yDoc={yDoc} awareness={awareness} />
}

export const LiveSpeakerNotes: React.FC<SpeakerRenderer> = props => {
  const { yDoc, awareness } = usePolarisContext()

  return <SpeakerNotesImplementation {...props} yDoc={yDoc} awareness={awareness} />
}
