import { removeSearchParams } from 'sierra-client/router/search-params'
import { PublicClientConfig } from 'sierra-domain/config'

export type ClientAuthConfig = {
  type: AuthType
  token: string | undefined
  host: string | undefined
}

export type ClientScormConfig = {
  isScorm: boolean
  isScormNative: boolean
  shouldUseScormPostWorkaround: boolean
}

export type GlobalClientConfig = {
  auth: ClientAuthConfig
  scorm: ClientScormConfig
} & PublicClientConfig

const AUTH_TYPES = ['scorm', 'scorm-native', 'native'] as const
type AuthType = (typeof AUTH_TYPES)[number]

function toAuthType(value: string | undefined | null): AuthType {
  return AUTH_TYPES.includes(value as AuthType) ? (value as AuthType) : 'native'
}

function processAuthSearchParams(): ClientAuthConfig {
  const url = new URL(location.href)
  const authSearchParamNames = ['x-auth', 'x-token', 'x-host']

  const [xauth, token, host] = authSearchParamNames.map(name => url.searchParams.get(name) ?? undefined)
  removeSearchParams(authSearchParamNames)

  return { type: toAuthType(xauth), token, host }
}

export function initGlobalConfig(): GlobalClientConfig {
  const injectedPublicConfig = window.__PUBLIC_CONFIG__

  if (typeof window.__PUBLIC_CONFIG__ === 'undefined') {
    throw Error('No public config was injected by the server.')
  }

  const auth = processAuthSearchParams()
  const isScormNative = auth.type === 'scorm-native'

  return {
    ...injectedPublicConfig,
    auth,
    scorm: {
      isScorm: isScormNative || auth.type === 'scorm',
      isScormNative,
      shouldUseScormPostWorkaround: auth.type === 'scorm',
    },
  }
}

export const config = initGlobalConfig()

type GlobalConfigFlags = GlobalClientConfig['organization']['flags']
type FlagName = keyof GlobalConfigFlags

/**
 * Gets a flag from the global config. Requires a user reload for flags to be updated.
 * For real-time flag updates, use {@link useFlagWithRefresh} instead.
 */
export function getFlag<TFlagName extends FlagName>(flagName: TFlagName): GlobalConfigFlags[TFlagName] {
  return config.organization.flags[flagName]
}

export type CustomHelpDeskConfig = {
  customHelpDeskUrl: string
  customHelpDeskImage: string
}

export function hasCustomHelpDeskConfig(): boolean {
  // eslint-disable-next-line @typescript-eslint/strict-boolean-expressions
  return !!config.organization.properties['custom-help-desk-url']
}
