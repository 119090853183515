import { unwrapToTextNodes } from 'sierra-client/views/v3-author/command'
import { isElementType, parentType } from 'sierra-client/views/v3-author/queries'
import { createSlidingScaleCardOption } from 'sierra-domain/v3-author/create-blocks'
import { Editor, Node, Range, Text, Transforms } from 'slate'

const debug = (...messages: unknown[]): void => console.debug('[withSlidingScaleCard]', ...messages)

const isSliderOption = isElementType('sliding-scale-card-option')

export const withSlidingScaleCard = (editor: Editor): Editor => {
  const { normalizeNode, insertBreak, deleteBackward } = editor

  editor.normalizeNode = entry => {
    const [node, path] = entry

    if (isElementType('sliding-scale-card', node)) {
      const children = Array.from(Node.children(editor, path))
      const sliderOptions = children.filter(([child]) => isSliderOption(child))

      if (parentType(editor, path) !== 'editor') {
        debug('Sliding scale card must be at the root, unwrapping at', JSON.stringify(path))
        return Transforms.unwrapNodes(editor, { at: path })
      }

      for (const [child, childPath] of children) {
        if (!isElementType('sliding-scale-card-option', child)) {
          debug(`Removing unexpected element at`, JSON.stringify(childPath))
          return Transforms.removeNodes(editor, { at: childPath, voids: true })
        }
      }

      if (sliderOptions.length === 0) {
        const at = path.concat(0)
        debug(`Adding two missing sliding-scale-card-option at`, JSON.stringify(at))
        Transforms.insertNodes(editor, [createSlidingScaleCardOption(), createSlidingScaleCardOption()], {
          at,
          voids: true,
        })
        return
      }

      if (sliderOptions.length === 1) {
        const at = path.concat(0)
        debug(`Adding one missing sliding-scale-card-option at`, JSON.stringify(at))
        Transforms.insertNodes(editor, createSlidingScaleCardOption(), { at, voids: true })
        return
      }

      if (sliderOptions.length > 2) {
        debug(`Removing additional sliding-scale-card-options at`, JSON.stringify(path))
        Transforms.removeNodes(editor, { at: path.concat(2) })
        return
      }
    }
    normalizeNode(entry)
  }

  editor.insertBreak = () => {
    const { selection } = editor
    if (!(selection && Range.isCollapsed(selection))) return insertBreak()
    const [entry] = Editor.nodes(editor, { match: isElementType('sliding-scale-card') })
    if (entry) return Transforms.move(editor, { distance: 1, unit: 'line' })
    insertBreak()
  }

  editor.deleteBackward = unit => {
    const { selection } = editor
    if (selection && Range.isCollapsed(selection)) {
      const [entry] = Editor.nodes(editor, {
        match: isElementType('sliding-scale-card'),
      })
      if (entry !== undefined) {
        const isCursorAtTheStartOfTheNode = selection.anchor.offset === 0 && selection.focus.offset === 0
        if (isCursorAtTheStartOfTheNode) return
      }

      return deleteBackward(unit)
    }
  }

  return editor
}

export const withSlidingScaleCardOption = (editor: Editor): Editor => {
  const { normalizeNode } = editor

  editor.normalizeNode = entry => {
    const [node, path] = entry

    if (isElementType('sliding-scale-card-option', node)) {
      if (parentType(editor, path) !== 'sliding-scale-card') {
        debug(
          `sliding-scale-card-option must be the direct child of a sliding-scale-card, unwrapping at`,
          JSON.stringify(path)
        )
        return Transforms.unwrapNodes(editor, { at: path, voids: true, mode: 'all' })
      }

      for (const [child, childPath] of Array.from(Node.children(editor, path))) {
        if (!Text.isText(child)) {
          debug('Unwrapping unexpected element in sliding-scale-card-option at', childPath)
          unwrapToTextNodes(editor, { at: childPath })
        }
      }
    }

    normalizeNode(entry)
  }

  return editor
}
