import React, { ChangeEvent } from 'react'
import { ApproverSetting } from 'sierra-client/api/graphql/gql/graphql'
import { IconListItem } from 'sierra-client/components/common/icon-list'
import { useAdminIdentitiesFetcher } from 'sierra-client/components/common/identities-selector/identity-fetchers'
import { useTranslation } from 'sierra-client/hooks/use-translation'
import { CalendarEventUserSelector } from 'sierra-client/views/manage/event-groups/components/calendar-event-user-selector'
import { IdentityWithMetadata } from 'sierra-domain/api/manage'
import { isNonNullable } from 'sierra-domain/utils'
import { LabelMenuItem } from 'sierra-ui/components'
import { InputPrimitive, Spacer, Switch, Text, View } from 'sierra-ui/primitives'
import { SingleSelectDropdown } from 'sierra-ui/primitives/menu-dropdown'
import { token } from 'sierra-ui/theming'
import styled from 'styled-components'

const OptionDescription = styled(Text)`
  color: ${token('foreground/muted')};
  width: 280px;
`

export const CalendarEventBottomOptions: React.FC<{
  participantLimit: number | undefined
  setParticipantLimit: (limit: number | undefined) => void
  specificReviewerIdentities: IdentityWithMetadata[]
  setSpecificReviewerIdentities: (newSpecificReviewerIdentites: IdentityWithMetadata[]) => void
  selfReportAttendance: boolean
  setSelfReportAttendance: (allow: boolean) => void
  approverSetting?: ApproverSetting
  setApproverSetting: (setting?: ApproverSetting) => void
}> = props => {
  const { t } = useTranslation()

  const fetchSpecificReviewerIdentities = useAdminIdentitiesFetcher({ withTypes: ['user', 'user-group'] })

  const approverEnabled = isNonNullable(props.approverSetting)

  const requireApprovalYes: LabelMenuItem = {
    type: 'label',
    id: 'yes',
    label: t('dictionary.yes'),
  }

  const requireApprovalNo: LabelMenuItem = {
    type: 'label',
    id: 'no',
    label: t('dictionary.no'),
  }

  const reviewerFaciliator: LabelMenuItem<ApproverSetting> = {
    type: 'label',
    id: 'FACILITATOR',
    label: t('event-groups.reviewer-facilitator'),
  }

  const reviewerDirectManager: LabelMenuItem<ApproverSetting> = {
    type: 'label',
    id: 'DIRECT_MANAGER',
    label: t('event-groups.reviewer-direct-manager'),
  }

  const reviewerSpecificReviewers: LabelMenuItem<ApproverSetting> = {
    type: 'label',
    id: 'SPECIFIC_REVIEWER',
    label: t('event-groups.reviewer-specific-reviewers'),
  }

  const selectedItem = (() => {
    switch (props.approverSetting) {
      case 'FACILITATOR':
        return reviewerFaciliator
      case 'DIRECT_MANAGER':
        return reviewerDirectManager
      case 'SPECIFIC_REVIEWER':
        return reviewerSpecificReviewers
    }
  })()

  return (
    <div>
      <IconListItem
        faded={false}
        bold
        color='foreground/muted'
        iconId='settings'
        text={t('author.homework.settings.advanced-settings')}
      />

      <Spacer size={'8'} />

      <View direction='row' justifyContent={'space-between'}>
        <View>
          <Text bold>{t('admin.author.sessions.participant-limit')}</Text>
        </View>
        <div>
          <InputPrimitive
            id='participant-limit'
            type='number'
            placeholder={t('events.no-limit')}
            value={`${props.participantLimit}`}
            min={0}
            onChange={(e: ChangeEvent<HTMLInputElement>) => {
              const newNumber = Number(e.target.value)
              props.setParticipantLimit(newNumber === 0 ? undefined : newNumber)
            }}
          />
        </div>
      </View>

      <Spacer size={'8'} />

      <View direction='row' justifyContent={'space-between'}>
        <View>
          <Text bold>{t('events.self-report-attendance')}</Text>
        </View>
        <div>
          <Switch
            checked={props.selfReportAttendance}
            onChange={(checked: boolean): void => {
              props.setSelfReportAttendance(checked)
            }}
            ariaLabel={t('events.self-report-attendance')}
          />
        </div>
      </View>

      <Spacer size={'8'} />

      <View direction='row' justifyContent={'space-between'}>
        <View direction='column' gap='2'>
          <Text bold>{t('event-groups.require-host-approval-2')}</Text>
          <OptionDescription>{t('event-groups.require-host-approval-description-3')}</OptionDescription>
        </View>
        <div>
          <SingleSelectDropdown
            onSelect={item => {
              if (item.id === 'yes') props.setApproverSetting('FACILITATOR')
              else props.setApproverSetting(undefined)
            }}
            selectedItem={approverEnabled ? requireApprovalYes : requireApprovalNo}
            menuItems={[requireApprovalYes, requireApprovalNo]}
          />
        </div>
      </View>

      {approverEnabled && (
        <>
          <Spacer size='16' />
          <View direction='row' justifyContent={'space-between'}>
            <View direction='column' gap='2'>
              <Text bold>{t('event-groups.reviewer')}</Text>
              <OptionDescription>{t('event-groups.reviewer-description')}</OptionDescription>
            </View>
            <div>
              <SingleSelectDropdown
                onSelect={item => props.setApproverSetting(item.id)}
                selectedItem={selectedItem}
                menuItems={[reviewerFaciliator, reviewerDirectManager, reviewerSpecificReviewers]}
              />
            </div>
          </View>
        </>
      )}

      {props.approverSetting === 'SPECIFIC_REVIEWER' && (
        <>
          <Spacer size='16' />
          <CalendarEventUserSelector
            selectedIdentities={props.specificReviewerIdentities}
            setSelectedIdentities={update => {
              const updatedIdentities = update(props.specificReviewerIdentities)
              props.setSpecificReviewerIdentities(updatedIdentities)
            }}
            fetchIdentities={fetchSpecificReviewerIdentities}
          />
        </>
      )}
    </div>
  )
}
