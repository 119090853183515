import React from 'react'
import {
  AdornmentRenderer,
  RecommendationPill,
} from 'sierra-client/components/recommendations/components/card-context/common'
import { DefaultCardContext } from 'sierra-client/components/recommendations/components/card-context/default-card-context'
import {
  CardInfo,
  RecommendationCard,
} from 'sierra-client/components/recommendations/components/card-context/types'
import { getNextCourse } from 'sierra-client/components/recommendations/components/card-context/utils'
import { Trans } from 'sierra-client/hooks/use-translation/trans'
import { TranslationKey } from 'sierra-client/hooks/use-translation/types'
import { LearnCourse } from 'sierra-domain/api/entities'
import { Recommendation } from 'sierra-domain/api/recommendations'
import { assertNever } from 'sierra-domain/utils'
import { IconId } from 'sierra-ui/components'
import { Text, View } from 'sierra-ui/primitives'

const getNextCoursefromProgram = (card: RecommendationCard): LearnCourse | undefined => {
  // This shouldn't be reachable but it was tricky to extract the narrowed type from InfoCard.
  // (Exclude didn't work but maybe there's another way)
  if (!('entity' in card && card.entity.entityType === 'program')) {
    return undefined
  }

  const { entity: program } = card

  return getNextCourse(program)
}

const useProgramCardInfo = (card: RecommendationCard): CardInfo => {
  const nextCourse = getNextCoursefromProgram(card)

  if (nextCourse === undefined) {
    return {
      text: '',
    }
  }

  switch (card.type) {
    case 'popular':
    case 'discover':
    case 'recently-assigned':
    case 'self-enroll-to-session':
    case 'others-are-learning':
    case 'in-progress':
    case 'featured':
    case 'assigned':
      return {
        text: '',
        adornment: {
          type: 'pill',
          component: (() => {
            const content = (
              <Trans
                i18nKey={
                  (nextCourse.entityType === 'native:live'
                    ? 'recommendation.program-step-live'
                    : 'recommendation.program-step-course') satisfies TranslationKey
                }
                values={{ title: nextCourse.title }} // TODO(seb): Add due date if available
                components={{
                  bold: <Text bold color='currentColor' as='span' />,
                }}
              />
            )

            const iconId: IconId =
              nextCourse.entityType === 'native:live' ? 'play--circle--filled' : 'bookmark--filled'

            return (
              <RecommendationPill background='white' iconId={iconId}>
                {content}
              </RecommendationPill>
            )
          })(),
        },
      }
    case 'ongoing-session':
    case 'recently-edited':
    case 'continue-editing':
    case 'create-suggestion':
    case 'recap':
    case 'added-as-collaborator':
    case 'invited-to-collaborate':
    case 'new-comments':
    case 'upcoming-session': // Should be handled by the default renderer
    case 'upcoming-due-date': // Should be handled by the default renderer
    case 'overdue': // Should be handled by the default renderer
      return { text: '' }
    default:
      assertNever(card)
  }
}

export const ProgramCardContext: React.FC<{
  // card: Extract<Recommendation['card'], { entity: { entityType: 'program' } }>
  card: Recommendation['card']
}> = ({ card }) => {
  const programCardInfo = useProgramCardInfo(card)
  const nextCourse = getNextCoursefromProgram(card)
  const shouldRenderDefault =
    nextCourse === undefined ||
    card.type === 'upcoming-session' ||
    card.type === 'upcoming-due-date' ||
    card.type === 'overdue'

  // If there is a next course
  if (shouldRenderDefault) {
    return <DefaultCardContext card={card} />
  }

  return (
    <View>
      <AdornmentRenderer cardInfo={programCardInfo} />
    </View>
  )
}
