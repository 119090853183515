import { FC } from 'react'
import { useTranslation } from 'sierra-client/hooks/use-translation'
import { ContentLabelWrapper } from 'sierra-client/views/learner/program/atoms'
import { getLearnEntityName } from 'sierra-client/views/manage/content/utils/content-utils'
import { getEntityIconId } from 'sierra-client/views/workspace/components'
import { LearnEntity } from 'sierra-domain/api/entities'
import { isDefined } from 'sierra-domain/utils'
import { IconId } from 'sierra-ui/components'
import { ContentLabel } from 'sierra-ui/missions/foundation'

type StepContentLabelProps = {
  contentType: LearnEntity['entityType']
}

export const StepContentLabel: FC<StepContentLabelProps> = ({ contentType }) => {
  const { t } = useTranslation()

  const contentLabel = isDefined(contentType) ? t(getLearnEntityName(contentType)) : undefined
  const contentIcon: IconId | undefined = isDefined(contentType)
    ? getEntityIconId(contentType, false)
    : undefined

  if (!contentIcon || contentLabel === undefined) {
    return null
  }

  return (
    <ContentLabelWrapper>
      <ContentLabel iconId={contentIcon} label={contentLabel} />
    </ContentLabelWrapper>
  )
}
