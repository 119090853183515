import { PostWithoutErrorsType } from 'sierra-client/hooks/use-post'
import { DomainRep } from 'sierra-client/lib/filter'
import { prefetchCachedQuery, useCachedQuery } from 'sierra-client/state/api'
import { BulkActionFilter } from 'sierra-domain/api/bulk-action'
import { ZodRouteInput } from 'sierra-domain/api/types'
import { UserId } from 'sierra-domain/api/uuid'
import {
  XRealtimeAdminUsersDeleteUsers,
  XRealtimeAdminUsersFilterUsersDomain,
  XRealtimeAdminUsersSetAllUsersDisabled,
} from 'sierra-domain/routes'

const input = { key: 'user.filter' } as const satisfies ZodRouteInput<
  typeof XRealtimeAdminUsersFilterUsersDomain
>

export const prefetchFilterUsersDomainReps = (): Promise<void> => {
  return prefetchCachedQuery(XRealtimeAdminUsersFilterUsersDomain, input)
}

export const useFilterUsersDomainReps = (): DomainRep[] | undefined => {
  const { data } = useCachedQuery(XRealtimeAdminUsersFilterUsersDomain, input)
  return data?.domains
}

export const setAllUsersDisabled = (
  post: PostWithoutErrorsType,
  action: {
    bulkActionFilter: BulkActionFilter
    disable?: boolean
  }
): Promise<null> =>
  post(XRealtimeAdminUsersSetAllUsersDisabled, {
    bulkActionFilter: action.bulkActionFilter,
    disable: action.disable ?? true,
  })

export const deleteUsers = (post: PostWithoutErrorsType, userIds: UserId[]): Promise<null> =>
  post(XRealtimeAdminUsersDeleteUsers, { userIds })
