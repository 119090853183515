import React, { useContext } from 'react'
import { EditorTextActionsContextValue } from 'sierra-client/features/text-actions/types'

export const EditorTextActionsContext = React.createContext<EditorTextActionsContextValue | undefined>(
  undefined
)

export const useEditorTextActions = (): EditorTextActionsContextValue => {
  const context = useContext(EditorTextActionsContext)

  if (context === undefined) {
    throw new Error('useEditorTextActions needs to be wrapped in a EditorTextActionsProvider')
  }

  return context
}
