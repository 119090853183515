import { View } from 'sierra-ui/primitives'
import styled from 'styled-components'

export const ColumnLayout = styled(View).attrs({
  direction: 'column',
  grow: true,
  gap: 'none',
})`
  height: 100%;
  width: 100%;
`
