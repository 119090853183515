import { useMemo } from 'react'
import { resolveUserAvatar } from 'sierra-client/api/content'
import { useTranslation } from 'sierra-client/hooks/use-translation'
import { selectMessagesInThread } from 'sierra-client/state/chat/selectors'
import { useSelector } from 'sierra-client/state/hooks'
import { useUsersLegacy } from 'sierra-client/state/users/hooks'
import { ScopedChatId } from 'sierra-domain/collaboration/types'
import { isDefined } from 'sierra-domain/utils'
import { AvatarStack } from 'sierra-ui/components'
import { Text } from 'sierra-ui/primitives'
import { spacing, token } from 'sierra-ui/theming'
import styled from 'styled-components'

const RepliesContainer = styled.div`
  margin-top: 0.5rem;
  display: flex;
  gap: ${spacing['4']};
  align-items: center;
  cursor: pointer;
  border-radius: 4px;
  border: 1px solid transparent;
  justify-content: flex-start;

  &::before {
    content: url("data:image/svg+xml,%3Csvg width='24' height='24' viewBox='0 0 24 24' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath fill-rule='evenodd' clip-rule='evenodd' d='M10 1V3C10 9.07513 14.9249 14 21 14H23V16H21C13.8203 16 8 10.1797 8 3V1H10Z' fill='%23E6E6E6'/%3E%3C/svg%3E%0A");
    width: 1.5rem;
    height: 1.5rem;
  }

  color: ${token('foreground/secondary')};
  transition: color 100ms cubic-bezier(0.25, 0.1, 0.25, 1);

  &:hover {
    color: ${token('foreground/primary')};
  }
`

export const MessageContentReplies = ({
  messageId,
  chatId,
  setThreadId,
}: {
  messageId: string
  chatId: ScopedChatId
  setThreadId?: (id: string) => void
}): JSX.Element | null => {
  const { t } = useTranslation()

  // We only care about the actual replies here, not the root message.
  const allMessages = useSelector(state => selectMessagesInThread(state, chatId, messageId))
  const replyMessages = useMemo(() => allMessages.slice(1), [allMessages])

  const repliersUserIds = useMemo(
    () => Array.from(new Set(replyMessages.map(message => message.userId))),
    [replyMessages]
  )

  const resolvedUsers = useUsersLegacy(repliersUserIds)
  const definedResolvedUsers = resolvedUsers.filter(isDefined).map(resolveUserAvatar)

  if (replyMessages.length < 1 || setThreadId === undefined) return null

  return (
    <>
      <RepliesContainer onClick={() => setThreadId(messageId)}>
        <AvatarStack users={definedResolvedUsers} size='tiny' max={5} />
        <Text color='currentColor' size='micro' bold>
          {t(`chat.n-replies`, { count: replyMessages.length })}
        </Text>
      </RepliesContainer>
    </>
  )
}
