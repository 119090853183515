import { useAtom } from 'jotai'
import React, { useCallback, useEffect, useMemo, useState } from 'react'
import { useCoursePermissionSettings } from 'sierra-client/api/hooks/use-course-permission'
import {
  useDuplicateEditableContentMutation,
  useEditableContentById,
} from 'sierra-client/api/hooks/use-editable-content'
import {
  SelfPacedPublishState,
  useSelfPacedPublishState,
} from 'sierra-client/api/hooks/use-self-paced-publish-state'
import { Link } from 'sierra-client/components/common/link'
import { ActionModal } from 'sierra-client/components/common/modals/action-modal'
import { useNotif } from 'sierra-client/components/common/notifications'
import {
  ButtonWrapper,
  HeaderButtonColorCSS,
  HeaderIconButton,
  HeaderItemCSS,
} from 'sierra-client/components/liveV2/header-buttons'
import { SharingButton } from 'sierra-client/components/sharing/sharing-button'
import { ShortcutMenu } from 'sierra-client/components/shortcut-menu'
import { config, getFlag } from 'sierra-client/config/global-config'
import { yDocHistoryUrl } from 'sierra-client/editor/debug-ydoc-shortcut'
import { ManageSessionsPanel } from 'sierra-client/features/sana-now'
import { IconMenuWithMoveToTeamspaces } from 'sierra-client/features/teamspace'
import { useContentIds } from 'sierra-client/hooks/use-content-ids'
import { useDeleteCourse } from 'sierra-client/hooks/use-delete-course'
import { useHasContentKindPermission } from 'sierra-client/hooks/use-permissions'
import { useTranslation } from 'sierra-client/hooks/use-translation'
import { courseSettingsChannel } from 'sierra-client/realtime-data/channels'
import { getGlobalRouter } from 'sierra-client/router'
import { postWithUserErrorException } from 'sierra-client/state/api'
import * as settingsActions from 'sierra-client/state/author-course-settings/actions'
import { fetchCourseSettings } from 'sierra-client/state/author-course-settings/actions'
import {
  selectOriginalSettingsDefaultRequiredAssignments,
  selectOriginalSettingsDescription,
  selectOriginalSettingsImage,
  selectTemplateSettings,
} from 'sierra-client/state/author-course-settings/selectors'
import * as settingsState from 'sierra-client/state/author-course-settings/slice'
import { fetchLiveSessions } from 'sierra-client/state/flexible-content/actions'
import { useDispatch, useSelector } from 'sierra-client/state/hooks'
import { selectIsOwnerUser } from 'sierra-client/state/user/user-selector'
import { TranslateCourseShortcut } from 'sierra-client/views/course-settings/tabs/translate-course'
import {
  useCreatePageContext,
  useCreatePageNodeIdContext,
  useCreatePageYDocContext,
} from 'sierra-client/views/flexible-content/create-page-context'
import { CoursePermissionSettingsContextProvider } from 'sierra-client/views/flexible-content/editor/course-permission-settings-context'
import { ConvertCourseModal } from 'sierra-client/views/flexible-content/editor/topbar/convert-course-modal'
import { CreateTemplateModal } from 'sierra-client/views/flexible-content/editor/topbar/create-template-modal'
import { LiveContentTopbar } from 'sierra-client/views/flexible-content/editor/topbar/live-content-topbar'
import { manageSessionsPanelStateAtom } from 'sierra-client/views/flexible-content/editor/topbar/manage-sessions-panel-state'
import { SelfPacedContentTopbar } from 'sierra-client/views/flexible-content/editor/topbar/self-paced-content-topbar'
import { useRealtimeCourseTitle } from 'sierra-client/views/flexible-content/use-realtime-course-title'
import { useHasManageAccess } from 'sierra-client/views/manage/permissions/use-has-manage-access'
import { EditableContent } from 'sierra-domain/api/editable-content'
import { CourseId, CreateContentId } from 'sierra-domain/api/nano-id'
import { ScopedCreateContentId } from 'sierra-domain/collaboration/types'
import { XRealtimeScormGenerateTemporaryToken } from 'sierra-domain/routes'
import { assertNever, iife } from 'sierra-domain/utils'
import { Icon, MenuItem, Tooltip } from 'sierra-ui/components'
import { Text, View } from 'sierra-ui/primitives'
import { spacing } from 'sierra-ui/theming'
import { v2_breakpoint } from 'sierra-ui/theming/breakpoints'
import styled from 'styled-components'

const rootFolderId = 'folder:root'

const TemplateButtonWrapper = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  background: ${p => p.theme.color.bluePastel};
  padding-right: 1rem;
  padding-left: 1rem;
  ${HeaderItemCSS}
`

const TemplateButtonText = styled(Text)`
  margin-left: 0.5rem;
`

const ElevetatedContainer = styled(View)`
  z-index: 10;
`

const useMenuItems = ({
  content,
  isSelfPacedContent,
  isLiveContent,
  isTemplate,
  openDeleteConfirm,
  setTypeConversionModal,
  setShowCreateTemplateModal,
  selfPacedPublishState,
  canViewManageAnalytics,
  closeMenu,
}: {
  content: EditableContent
  isSelfPacedContent: boolean
  isLiveContent: boolean
  isTemplate: boolean
  openDeleteConfirm: () => void
  setTypeConversionModal: (_: string | undefined) => void
  setShowCreateTemplateModal: (_: boolean) => void
  selfPacedPublishState: SelfPacedPublishState
  canViewManageAnalytics: boolean
  closeMenu: () => void
}): MenuItem[] => {
  const { nodeId } = useCreatePageNodeIdContext()
  const { scopedCreateContentId, createContentId } = useCreatePageContext()
  const { t } = useTranslation()
  const dispatch = useDispatch()
  const canDelete = useHasContentKindPermission(createContentId, 'DELETE')
  const isOwner = useSelector(selectIsOwnerUser)
  const courseKind = ScopedCreateContentId.contentType(scopedCreateContentId)

  const isScormViewerEnabled = getFlag('scormtrooper')
  const isPublished = selfPacedPublishState.state?.published === true
  const showLaunchInScorm = isSelfPacedContent && (isScormViewerEnabled || isOwner) && isPublished

  const getTemporaryScormToken = useCallback(
    async (contentId: string): Promise<string> => {
      const { token } = await postWithUserErrorException(
        XRealtimeScormGenerateTemporaryToken,
        { contentId },
        dispatch
      )

      return token
    },
    [dispatch]
  )

  const duplicateEditableContentMutation = useDuplicateEditableContentMutation()

  const menuItems = useMemo<MenuItem[]>(
    () => [
      {
        id: 'settings',
        type: 'label',
        icon: 'settings',
        label: t('dictionary.settings'),
        onClick: async () => {
          await dispatch(settingsActions.fetch({ courseId: CourseId.parse(createContentId) }))
          void dispatch(settingsActions.setView('course-settings'))
          closeMenu()
        },
      },
      {
        id: 'duplicate',
        type: 'label' as const,
        icon: 'duplicate',
        animateIcon: false,
        label: t('dictionary.duplicate'),
        onClick: () => {
          duplicateEditableContentMutation.mutate({
            originalFlexibleContentId: createContentId,
            title: t('content.copy-of-title', { title: content.title }),
          })
        },
      },
      {
        id: 'translate',
        type: 'label' as const,
        icon: 'translate',
        label: t('dictionary.translate'),
        onClick: async () => {
          await dispatch(settingsActions.fetch({ courseId: CourseId.parse(createContentId) }))
          void dispatch(settingsActions.setView('translate'))
        },
      },
      {
        hidden: isLiveContent || selfPacedPublishState.state?.published !== true,
        id: 'view',
        type: 'label' as const,
        icon: 'view',
        label: t('dictionary.view'),
        onClick: () => getGlobalRouter().navigate({ to: `/s/${CourseId.parse(createContentId)}` }),
      },
      {
        id: 'launch-in-scorm',
        type: 'label' as const,
        icon: 'book',
        label: 'Launch in SCORM',
        hidden: !showLaunchInScorm,
        onClick: async () => {
          const scormViewerUrl = new URL(`${window.location.origin}/scorm-viewer`)
          scormViewerUrl.searchParams.set('host', window.location.host)
          scormViewerUrl.searchParams.set('token', await getTemporaryScormToken(createContentId))

          window.open(scormViewerUrl, '_blank')
        },
      },
      {
        hidden: !canViewManageAnalytics,
        id: 'manage',
        type: 'label',
        icon: 'trend--up',
        label: t('admin.author.manage'),
        onClick: () =>
          getGlobalRouter().navigate({ to: `/manage/courses/${CourseId.parse(createContentId)}` }),
      },
      {
        id: 'glossary',
        type: 'label',
        icon: 'character--sentence-case',
        label: t('dictionary.glossary'),
        hidden: courseKind === 'live',
        onClick: async () => {
          await dispatch(settingsActions.fetch({ courseId: CourseId.parse(createContentId) }))
          void dispatch(settingsActions.setView('glossary'))
          closeMenu()
        },
      },
      {
        id: 'save',
        type: 'label',
        icon: 'template',
        label: t('author.save-as-template'),
        hidden: isTemplate,
        onClick: () => {
          setShowCreateTemplateModal(true)
        },
      },
      {
        hidden: !isLiveContent,
        id: 'convert-to-course',
        type: 'label',
        icon: 'bookmark',
        animateIcon: false,
        label: t('author.convert-to-course'),
        onClick: () => setTypeConversionModal('course'),
      },
      {
        id: 'version-history',
        type: 'label',
        icon: 'recently-viewed',
        animateIcon: false,
        label: t('author.go-to-version-history-button'),
        onClick: () => {
          void getGlobalRouter().navigate({ to: yDocHistoryUrl(scopedCreateContentId, nodeId) })
        },
      },
      {
        hidden: !isSelfPacedContent,
        id: 'convert-to-live',
        type: 'label',
        icon: 'play--circle--outline',
        animateIcon: false,
        label: t('author.convert-to-live'),
        onClick: () => setTypeConversionModal('live'),
      },
      {
        hidden: !canDelete,
        id: 'delete',
        type: 'label',
        icon: 'trash-can',
        label: t('dictionary.delete'),
        onClick: () => {
          openDeleteConfirm()
        },
      },
    ],
    [
      t,
      isLiveContent,
      selfPacedPublishState.state?.published,
      showLaunchInScorm,
      canViewManageAnalytics,
      courseKind,
      isTemplate,
      isSelfPacedContent,
      canDelete,
      dispatch,
      createContentId,
      closeMenu,
      duplicateEditableContentMutation,
      content.title,
      getTemporaryScormToken,
      setShowCreateTemplateModal,
      setTypeConversionModal,
      scopedCreateContentId,
      nodeId,
      openDeleteConfirm,
    ]
  )

  return menuItems
}

const TopbarMenu: React.FC<{
  isSelfPacedContent: boolean
  isLiveContent: boolean
  isTemplate: boolean
  canViewManageAnalytics: boolean
  openDeleteConfirm: () => void
  setShowCreateTemplateModal: (_: boolean) => void
  selfPacedPublishState: SelfPacedPublishState
  content: EditableContent
}> = ({
  isSelfPacedContent,
  isLiveContent,
  isTemplate,
  canViewManageAnalytics,
  openDeleteConfirm,
  setShowCreateTemplateModal,
  selfPacedPublishState,
  content,
}) => {
  const [typeConversionModal, setTypeConversionModal] = useState<string | undefined>(undefined)
  const translateCourseId = useSelector(settingsState.selectors.currentCourseId)
  const translateCourseKind = useSelector(settingsState.selectors.selectCourseKind)
  const [menuOpen, setMenuOpen] = useState(false)

  const defaultItems = useMenuItems({
    content,
    isSelfPacedContent,
    isLiveContent,
    isTemplate,
    openDeleteConfirm,
    setTypeConversionModal,
    setShowCreateTemplateModal,
    selfPacedPublishState,
    canViewManageAnalytics,
    closeMenu: () => setMenuOpen(false),
  })

  return (
    <>
      <ElevetatedContainer gap='xxsmall'>
        <IconMenuWithMoveToTeamspaces
          inTopBar
          defaultItems={defaultItems}
          content={content}
          isOpen={menuOpen}
          onOpenChange={setMenuOpen}
          onSelect={() => setMenuOpen(false)}
        />

        {translateCourseId !== null && translateCourseKind !== null && (
          <TranslateCourseShortcut courseId={translateCourseId} courseKind={translateCourseKind} />
        )}
      </ElevetatedContainer>
      <ConvertCourseModal
        createContentId={CreateContentId.parse(content.id)}
        type={typeConversionModal}
        onClose={() => {
          setTypeConversionModal(undefined)
        }}
      />

      <ShortcutMenu.Action
        label={
          selfPacedPublishState.state?.published !== true
            ? 'shortcut-menu.default.go-to-self-paced-disabled'
            : 'shortcut-menu.default.go-to-self-paced'
        }
        run={() => {
          const hrefWithoutCreate = window.location.href.replace('/create/', '/')
          const url = new URL(hrefWithoutCreate)

          const host = config.auth.host
          if (host !== undefined) url.searchParams.set('x-host', host)

          window.open(url, '_blank')
        }}
        group='create'
        disabled={selfPacedPublishState.state?.published !== true}
        iconId='play--circle--filled'
        permission='ACCESS_EDITOR'
      />
    </>
  )
}

const ViewLink = styled(Link)`
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 6px;
  ${HeaderItemCSS}
  ${HeaderButtonColorCSS}
  width: 2.25rem;
`

const ActionButtonsWrapper = styled(View)`
  @media screen and (max-width: ${v2_breakpoint.tablet}) {
    display: none;
  }
`

const SelfPaceCoursePublishListener: React.FC<{ selfPacedContentId: string; refetch: () => void }> = ({
  refetch,
  selfPacedContentId,
}) => {
  courseSettingsChannel.useChannelEvent({
    channelId: selfPacedContentId,
    event: 'course-publish-state-changed',
    callback: () => {
      refetch()
    },
  })

  return null
}

const RightContent: React.FC<{ toggleChat: () => void; content: EditableContent | undefined }> = ({
  toggleChat,
  content,
}) => {
  const { nodeId } = useCreatePageNodeIdContext()
  const { createContentId } = useCreatePageContext()
  const { permission } = useCreatePageYDocContext()
  const [panelState, setPanelState] = useAtom(manageSessionsPanelStateAtom)
  const { t } = useTranslation()
  const notifications = useNotif()
  const [showCreateTemplateModal, setShowCreateTemplateModal] = useState(false)
  const dispatch = useDispatch()

  const courseId = CourseId.parse(createContentId)

  useEffect(() => {
    void dispatch(fetchCourseSettings({ courseId }))
  }, [courseId, dispatch])

  const { remove, loading, showDeleteConfirm, openDeleteConfirm, closeDeleteConfirm } = useDeleteCourse({
    courseId,
    onDeleted(): void {
      void getGlobalRouter().navigate({ to: '/create' })
      notifications.push({ type: 'course-removed' })
    },
  })

  const templateSettings = useSelector(selectTemplateSettings)
  const isTemplate = templateSettings !== undefined

  const description = useSelector(selectOriginalSettingsDescription)
  const image = useSelector(selectOriginalSettingsImage)
  const defaultRequiredAssignments = useSelector(selectOriginalSettingsDefaultRequiredAssignments)

  const { liveContentId, selfPacedContentId } = useContentIds()

  const isSelfPacedContent = selfPacedContentId !== undefined
  const isLiveContent = liveContentId !== undefined
  const publishState = useSelfPacedPublishState(selfPacedContentId)

  const hasManageAccess = useHasManageAccess()

  const courseVisibilityQuery = useCoursePermissionSettings(courseId, {
    select: data => data.visibilityInOrg,
  })

  const canViewManageAnalytics = iife((): boolean => {
    // "Analytics" here just refers to the manage page, so make sure the user has access to it.
    if (!hasManageAccess) return false

    // Template courses do not have manage pages.
    if (isTemplate) return false

    // Don't show analytics if the course is private or if visibility is unknown.
    const courseVisibility = courseVisibilityQuery.data
    switch (courseVisibility) {
      case undefined:
      case 'private':
        return false
      case 'visible-admins':
      case 'visible-everyone':
        break
      default:
        assertNever(courseVisibility)
    }

    if (isLiveContent) {
      return true
    } else if (isSelfPacedContent) {
      // Only published self-paced courses have manage pages.
      const isPublishedSelfPacedCourse = publishState.state?.published === true
      return isPublishedSelfPacedCourse
    } else {
      // Should be unreachable.
      return false
    }
  })

  const updatingTitle = useRealtimeCourseTitle(createContentId)
  const title = updatingTitle.loading ? t('admin.author.no-title') : updatingTitle.title

  useEffect(() => {
    if (isLiveContent && !isTemplate) {
      void dispatch(fetchLiveSessions({ liveContentId }))
    }
  }, [dispatch, isLiveContent, isTemplate, liveContentId])

  return (
    <View>
      {selfPacedContentId !== undefined && (
        <SelfPaceCoursePublishListener
          selfPacedContentId={selfPacedContentId}
          refetch={publishState.refresh}
        />
      )}
      <CoursePermissionSettingsContextProvider>
        <View gap='xxsmall'>
          <ActionButtonsWrapper gap='xxsmall'>
            {isSelfPacedContent && publishState.state?.published === true && (
              <Tooltip title={t('dictionary.view')}>
                <ViewLink target='_blank' href={`/s/${CourseId.parse(createContentId)}/${nodeId ?? 'next'}`}>
                  <Icon iconId='view' />
                </ViewLink>
              </Tooltip>
            )}
            {canViewManageAnalytics && (
              <HeaderIconButton
                iconId='growth'
                color='currentColor'
                onClick={async () => {
                  await getGlobalRouter().navigate({
                    to: `/manage/courses/${CourseId.parse(createContentId)}`,
                  })
                }}
                tooltip={t('author.view-analytics')}
              />
            )}
          </ActionButtonsWrapper>
          <ButtonWrapper onClick={toggleChat}>
            <Text bold color='currentColor'>
              {t('dictionary.chat')}
            </Text>
          </ButtonWrapper>
          {isTemplate && (
            <TemplateButtonWrapper>
              <Tooltip title={t('author.edit-template-tooltip')}>
                <Icon iconId='warning' size='size-16' color='blueDark' />
              </Tooltip>
              <TemplateButtonText size='small' bold>
                {t('author.edit-template')}
              </TemplateButtonText>
            </TemplateButtonWrapper>
          )}
          <SharingButton
            content={{
              type: isLiveContent ? 'live-session' : 'course',
              title,
              image,
              id: liveContentId ?? selfPacedContentId!,
              isDefaultRequiredAssignmentEnabled: defaultRequiredAssignments ?? false,
            }}
            publishState={publishState}
            permission={permission}
          />
          {permission === 'edit' && (
            <>
              {isLiveContent && !isTemplate && (
                <LiveContentTopbar title={title} rootFolderId={rootFolderId} liveContentId={liveContentId} />
              )}

              {isSelfPacedContent && !isTemplate && (
                <SelfPacedContentTopbar
                  selfPacedContentId={selfPacedContentId}
                  publishState={publishState}
                  title={title}
                />
              )}
            </>
          )}
        </View>
        {permission === 'edit' && content !== undefined && (
          <TopbarMenu
            isSelfPacedContent={isSelfPacedContent}
            isLiveContent={isLiveContent}
            isTemplate={isTemplate}
            canViewManageAnalytics={canViewManageAnalytics}
            openDeleteConfirm={openDeleteConfirm}
            setShowCreateTemplateModal={setShowCreateTemplateModal}
            selfPacedPublishState={publishState}
            content={content}
          />
        )}
        <ActionModal
          open={showDeleteConfirm}
          isLoading={loading}
          onClose={closeDeleteConfirm}
          primaryAction={remove}
          title={t('content.delete-headline')}
          primaryActionLabel={t('content.delete-button')}
          deleteAction
        >
          {t('content.delete-confirm')}
        </ActionModal>
        <CreateTemplateModal
          open={showCreateTemplateModal}
          onClose={() => setShowCreateTemplateModal(false)}
          title={title}
          description={description}
          image={image}
        />
        {isLiveContent && (
          <ManageSessionsPanel
            liveContentId={liveContentId}
            open={panelState.type !== 'closed'}
            onClose={() => {
              setPanelState({ type: 'closed' })
            }}
            setState={setPanelState}
            state={panelState}
          />
        )}
      </CoursePermissionSettingsContextProvider>
    </View>
  )
}

type CreatePageTopbarProps = {
  toggleChat: () => void
  cardToolbar?: React.ReactNode
  createContentId: CreateContentId
}

const Header = styled.header`
  display: flex;
  justify-content: space-between;
  padding: ${spacing['8']};
  padding-bottom: 0;
`

const Centered = styled.div`
  margin-left: auto;
  margin-right: auto;
`

export const CreatePageTopbar: React.FC<CreatePageTopbarProps> = ({
  toggleChat,
  cardToolbar,
  createContentId,
}) => {
  const content = useEditableContentById(createContentId)

  return (
    <Header>
      <Centered>{cardToolbar}</Centered>

      <RightContent toggleChat={toggleChat} content={content.data} />
    </Header>
  )
}
