import _ from 'lodash'
import { useState } from 'react'
import { graphql } from 'sierra-client/api/graphql/gql'
import { useGraphQuery } from 'sierra-client/api/hooks/use-graphql-query'
import { useTranslation } from 'sierra-client/hooks/use-translation'
import { assertNever } from 'sierra-domain/utils'
import { Icon } from 'sierra-ui/components'
import { Button, LoadingSpinner, ScrollView, Text, View } from 'sierra-ui/primitives'
import { token } from 'sierra-ui/theming'
import styled from 'styled-components'
import { SANA_TAXONOMY, TAXONOMY } from './sana-taxonomy-data'

const TaxonomyCardContainer = styled(View)<{ state?: 'selected' | 'existing' }>`
  padding: 16px 20px;
  outline: 1px solid ${token('border/default')};
  outline-offset: -1px;
  border-radius: 14px;
  button {
    opacity: ${p => (p.state === 'selected' || p.state === 'existing' ? 1 : 0)};
  }
  &:hover {
    cursor: pointer;
    outline: 2px solid ${token('border/strong')};
    button {
      opacity: 1;
    }
  }
  ${p =>
    p.state === 'existing' &&
    `
      background-color: ${token('surface/strong')(p)};
      pointer-events: none;
  `}
`

const TaxonomyIconContainer = styled(View)`
  padding: 10px;
  border-radius: 20px;
  background-color: ${token('surface/soft')};
`

const TaxonomyCard: React.FC<{
  taxonomy: TAXONOMY
  state?: 'selected' | 'existing'
  onSelect: (id: string) => void
}> = ({ taxonomy, state, onSelect }) => {
  const { t } = useTranslation()
  const getButton = (): JSX.Element => {
    switch (state) {
      case 'selected':
        return (
          <Button variant='secondary' icon='checkmark' type='button'>
            {_.capitalize(t('dictionary.selected'))}
          </Button>
        )
      case 'existing':
        return (
          <>
            <View alignItems='center' paddingLeft='14' paddingRight='14'>
              <Icon iconId='checkmark' size='size-12' color='foreground/muted' />
              <Text bold color='foreground/muted'>
                {_.capitalize(t('dictionary.already-exists'))}
              </Text>
            </View>
          </>
        )
      case undefined:
        return (
          <Button variant='primary' type='button'>
            {_.capitalize(t('dictionary.select'))}
          </Button>
        )
      default:
        assertNever(state)
    }
  }
  return (
    <TaxonomyCardContainer onClick={() => onSelect(taxonomy.title)} state={state} direction='column' gap='12'>
      <View position='relative' justifyContent='space-between'>
        <View>
          <TaxonomyIconContainer>
            <Icon iconId={taxonomy.icon} size='size-16' />
          </TaxonomyIconContainer>
          <Text color={state === 'existing' ? 'foreground/secondary' : 'foreground/primary'} bold>
            {taxonomy.title}
          </Text>
        </View>
        {getButton()}
      </View>
      <Text color={state === 'existing' ? 'foreground/muted' : 'foreground/secondary'}>
        {taxonomy.description}
      </Text>
    </TaxonomyCardContainer>
  )
}

const Form = styled.form`
  gap: 16px;
  display: grid;
  grid-template-areas: 'title' 'taxonomy-list' 'footer';
  grid-template-rows: 20px 1fr fit-content;
  height: 100%;
  gap: 0px;
`

const GridItem = styled(View)<{ area: string }>``

const Footer = styled(GridItem).attrs({ area: 'footer' })`
  width: 100%;
  background: ${token('elevated/background')};
  border-top: 1px solid ${token('border/default')};
  padding-top: 1.5rem;
  padding-inline: 40px;
  justify-content: flex-end;
`

const TaxonomyList = styled(ScrollView)`
  grid-area: taxonomy-list;
  padding-bottom: 40px;
`

const allSkillsQuery = graphql(`
  query AllSkills {
    skills {
      data {
        id
        name
      }
    }
  }
`)

export type SubmitState = { type: 'loading'; skills: number } | { type: 'notAsked' } | { type: 'done' }
type Props = {
  onClose: () => void
  onSubmit: (selectedSkills: Array<TAXONOMY>) => void
  submitState: SubmitState
}
export const ImportSkillForm: React.FC<Props> = ({ onClose, onSubmit, submitState }) => {
  const { t } = useTranslation()
  const [selectedSkills, setSelectedSkills] = useState<Map<string, TAXONOMY>>(new Map())
  const allSkillsRes = useGraphQuery({ document: allSkillsQuery }, {})

  if (allSkillsRes.isLoading || allSkillsRes.isPending) {
    return <LoadingSpinner />
  }

  if (allSkillsRes.isError) {
    throw new Error('Failed to fetch skills')
  }

  const allSkills = allSkillsRes.data.skills.data.map(skill => skill.name)
  const loading = submitState.type === 'loading'

  return (
    <Form
      onSubmit={() => {
        onSubmit(Array.from(selectedSkills.values()))
      }}
    >
      <GridItem area='title' paddingLeft='40' paddingRight='40' paddingBottom='8'>
        <Text bold>{t('skills.form.import-sana-taxonomy.title')}</Text>
      </GridItem>
      <TaxonomyList direction='column' gap='12' paddingLeft='40' paddingRight='40'>
        {SANA_TAXONOMY.map(taxonomy => {
          return (
            <TaxonomyCard
              key={taxonomy.title}
              state={
                selectedSkills.has(taxonomy.title)
                  ? 'selected'
                  : allSkills.includes(taxonomy.title)
                    ? 'existing'
                    : undefined
              }
              onSelect={id =>
                setSelectedSkills(selected => {
                  const newSelected = new Map(selected)
                  if (selected.has(id)) {
                    newSelected.delete(id)
                  } else {
                    newSelected.set(id, taxonomy)
                  }
                  return newSelected
                })
              }
              taxonomy={taxonomy}
            />
          )
        })}
      </TaxonomyList>
      <Footer>
        <Button
          variant='secondary'
          onClick={() => {
            onClose()
          }}
          type='button'
        >
          {t('dictionary.close')}
        </Button>
        <Button
          disabled={loading || selectedSkills.size === 0}
          loading={loading}
          type='submit'
          icon={loading ? 'loading' : undefined}
        >
          {selectedSkills.size === 0
            ? t('skills.form.import-sana-taxonomy.submit-button.text-placeholder')
            : t('skills.form.import-sana-taxonomy.submit-button.text', { count: selectedSkills.size })}
        </Button>
      </Footer>
    </Form>
  )
}
