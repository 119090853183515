import React, { useCallback } from 'react'
import { useTranslation } from 'sierra-client/hooks/use-translation'
import { getGlobalRouter } from 'sierra-client/router'
import { selectCourseById } from 'sierra-client/state/content/selectors'
import { useSelector } from 'sierra-client/state/hooks'
import { BackToHome } from 'sierra-client/views/course-helper/content/actions'
import { useSelfPacedFiles } from 'sierra-client/views/self-paced/files-provider'
import { Text, View } from 'sierra-ui/primitives'

const Fallback: React.FC<{ title: string }> = ({ title }) => {
  const { t } = useTranslation()
  const goBackHome = useCallback(() => getGlobalRouter().navigate({ to: '/' }), [])

  return (
    <View direction='column'>
      <Text bold color='foreground/primary'>
        {t('helper.great-work')}
      </Text>
      <Text color='foreground/secondary'>
        {t('helper.completed-everything', {
          title: title,
        })}
      </Text>
      <BackToHome onClick={goBackHome} />
    </View>
  )
}

export const CourseFallback: React.FC = () => {
  const { flexibleContentId } = useSelfPacedFiles()
  const courseTitle = useSelector(state => selectCourseById(state, flexibleContentId))?.settings.title

  return <Fallback title={courseTitle ?? ''} />
}
