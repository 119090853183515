import React, { useState } from 'react'
import { useListHeatmaps } from 'sierra-client/api/hooks/use-heatmaps'
import { useTranslation } from 'sierra-client/hooks/use-translation'
import { ProgramHeatmap } from 'sierra-client/views/manage/reports/components/heatmap/program-heatmap'
import {
  SavedCustomHeatmapView,
  useListHeatmaps as useLegacyListHeatmaps,
} from 'sierra-client/views/manage/reports/components/saved-heatmaps-view'
import { ListHeatmapsResponse, SavedHeatmapSummary } from 'sierra-domain/api/heatmap'
import { assertNever } from 'sierra-domain/utils'
import { Icon, TruncatedTextWithTooltip } from 'sierra-ui/components'
import { Button, LoadingSpinner, Text, View } from 'sierra-ui/primitives'
import styled from 'styled-components'

const CenteredTextView = styled(View)`
  text-align: center;
`

const HeatmapModal: React.FC<{ heatmap: SavedHeatmapSummary; onClose: () => void }> = ({
  heatmap,
  onClose,
}) => {
  const { updateLocalHeatmap, removeProgramHeatmap } = useLegacyListHeatmaps()

  switch (heatmap.type) {
    case 'custom':
      return <SavedCustomHeatmapView heatmapId={heatmap.id} onClose={onClose} onSave={updateLocalHeatmap} />
    case 'program':
      return (
        <ProgramHeatmap
          programId={heatmap.programId}
          programName={heatmap.title}
          programHeatmapIsFavorited={true}
          onClose={({ isFavorited }) => {
            if (isFavorited === false) {
              removeProgramHeatmap(heatmap.programId)
            }
            onClose()
          }}
        />
      )
    default:
      assertNever(heatmap)
  }
}

const RightAlignedView = styled(View)`
  position: absolute;
  right: 0rem;
`

const HeatmapsWithData: React.FC<{ listHeatmapResponse: ListHeatmapsResponse }> = ({
  listHeatmapResponse,
}) => {
  const { t } = useTranslation()
  const [selectedHeatmap, setSelectedHeatmap] = useState<SavedHeatmapSummary>()

  if (listHeatmapResponse.data.length < 1) {
    return (
      <CenteredTextView direction='column' alignItems='center' padding='32 16'>
        <Icon iconId='chart--heat-map' color={'foreground/muted'} />
        <Text size='small' bold color={'foreground/muted'}>
          {t('manage.insights.home.heatmaps-title')}
        </Text>
        <Text size='small' color={'foreground/muted'}>
          {t('manage.insights.home.heatmaps-description')}
        </Text>
      </CenteredTextView>
    )
  }

  return (
    <>
      <View direction='column' gap='24'>
        {listHeatmapResponse.data.map((heatmap, index) => (
          <View
            key={index}
            animated
            position='relative'
            whileHover={'show-rest'}
            initial='hide'
            animate='hide'
            variants={{ 'hide': {}, 'show-rest': {} }}
          >
            <View direction='column' gap='2' grow alignItems='flex-start'>
              <TruncatedTextWithTooltip size='small' bold>
                {heatmap.title}
              </TruncatedTextWithTooltip>
              {heatmap.description !== undefined && (
                <TruncatedTextWithTooltip size='small'>{heatmap.description}</TruncatedTextWithTooltip>
              )}
            </View>
            <RightAlignedView
              variants={{ 'hide': { opacity: 0 }, 'show-rest': { opacity: 1 } }}
              animated
              background={'surface/default'}
            >
              <Button variant='secondary' onClick={() => setSelectedHeatmap(heatmap)}>
                {t('dictionary.view')}
              </Button>
            </RightAlignedView>
          </View>
        ))}
      </View>
      {selectedHeatmap !== undefined && (
        <HeatmapModal heatmap={selectedHeatmap} onClose={() => setSelectedHeatmap(undefined)} />
      )}
    </>
  )
}

const GetHeatmaps: React.FC = () => {
  const { t } = useTranslation()
  const heatmapsResponse = useListHeatmaps()

  if (heatmapsResponse.isPending) {
    return <LoadingSpinner />
  }

  if (heatmapsResponse.isError) {
    return (
      <CenteredTextView>
        <Text>{t('manage.insights.home.heatmaps-error')}</Text>
      </CenteredTextView>
    )
  }

  return <HeatmapsWithData listHeatmapResponse={heatmapsResponse.data} />
}

const BoxShadowView = styled(View).attrs({
  direction: 'column',
  padding: '24',
  radius: 'size-16',
})`
  box-shadow: 0px 2px 4px 0px #00000014;
  outline: 1px solid ${p => p.theme.tokens['border/default']};
`

export const SavedHeatmaps: React.FC = () => {
  const { t } = useTranslation()

  return (
    <View grow>
      <View direction='column' gap='12' grow>
        <Text size='small' bold color={'foreground/muted'}>
          {t('manage.insights.home.heatmaps-header')}
        </Text>
        <BoxShadowView>
          <GetHeatmaps />
        </BoxShadowView>
      </View>
    </View>
  )
}
