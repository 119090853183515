import { AgoraRTCStats } from 'agora-rtc-sdk-ng'
import _ from 'lodash'
import { useEffect, useState } from 'react'
import { Sparklines, SparklinesCurve } from 'react-sparklines-typescript'
import { formatBitrate, formatTime } from 'sierra-client/components/liveV2/live-layer/call-stats/atoms'
import { useCurrentClientContext } from 'sierra-client/components/liveV2/live-provider'
import { useTranslation } from 'sierra-client/hooks/use-translation'
import { SettingsText } from 'sierra-client/views/liveV2/settings/common'
import { IssueMessages } from 'sierra-client/views/liveV2/settings/issues'
import { LiveSettingsTabComponent } from 'sierra-client/views/liveV2/settings/live-tabs'
import { Icon, Tooltip } from 'sierra-ui/components'
import { Text, View } from 'sierra-ui/primitives'
import { palette } from 'sierra-ui/theming'
import styled from 'styled-components'

const ChartContainer = styled.div`
  margin-bottom: 1.5rem;
`

export const NetworkSettingsTab: LiveSettingsTabComponent = () => {
  const { client } = useCurrentClientContext()
  const [callStats, setCallStats] = useState<AgoraRTCStats>()
  const [uploadBitrateWindow, setUploadBitrateWindow] = useState<number[]>([])
  const [downloadBitrateWindow, setDownloadBitrateWindow] = useState<number[]>([])
  const [RTTWindow, setRTTWindow] = useState<number[]>([])
  const { t } = useTranslation()

  useEffect(() => {
    let uploadWindow: number[] = _.fill(Array(50), 0)
    let downloadWindow: number[] = _.fill(Array(50), 0)
    let RTTWindow: number[] = _.fill(Array(50), 0)

    const interval = setInterval(() => {
      const RTCStats = client?.getRTCStats()
      if (RTCStats !== undefined) {
        setCallStats(RTCStats)
        uploadWindow.push(RTCStats.SendBitrate)
        downloadWindow.push(RTCStats.RecvBitrate)
        RTTWindow.push(RTCStats.RTT)
        if (uploadWindow.length > 50) uploadWindow = _.takeRight(uploadWindow, 50)
        if (downloadWindow.length > 50) downloadWindow = _.takeRight(downloadWindow, 50)
        if (RTTWindow.length > 50) RTTWindow = _.takeRight(RTTWindow, 50)
        setUploadBitrateWindow(uploadWindow)
        setDownloadBitrateWindow(downloadWindow)
        setRTTWindow(RTTWindow)
      }
    }, 500)

    return () => {
      clearInterval(interval)
    }
  }, [client])

  return (
    <View direction='column' gap='xsmall'>
      <IssueMessages category='network' />
      {callStats !== undefined && (
        <>
          <SettingsText spacing='xxsmall'>
            {t('live.network.upload-bandwidth')}{' '}
            <Tooltip title={t('live.network.upload-bandwidth-info')}>
              <Icon iconId='information' />
            </Tooltip>
          </SettingsText>
          <Text color='grey40' size='small' spacing='xxsmall'>
            {t('live.network.using-upload-bandwidth', {
              bitrate: formatBitrate(callStats.SendBitrate),
              available: formatBitrate(callStats.OutgoingAvailableBandwidth * 1000),
            })}
          </Text>
          <ChartContainer>
            <Sparklines
              data={uploadBitrateWindow}
              width={300}
              height={30}
              min={0}
              max={callStats.OutgoingAvailableBandwidth * 1000}
              limit={40}
            >
              <SparklinesCurve style={{ strokeWidth: 1, stroke: palette.blue.bright, fill: 'none' }} />
            </Sparklines>
          </ChartContainer>
          <SettingsText spacing='xxsmall'>
            {t('live.network.download-bandwidth')}{' '}
            <Tooltip title={t('live.network.download-bandwidth-info')}>
              <Icon iconId='information' />
            </Tooltip>
          </SettingsText>
          <Text color='grey40' size='small' spacing='xxsmall'>
            {t('live.network.using-download-bandwidth', {
              bitrate: formatBitrate(callStats.RecvBitrate),
            })}
          </Text>
          <ChartContainer>
            <Sparklines data={downloadBitrateWindow} width={300} height={30} min={0} limit={40}>
              <SparklinesCurve style={{ strokeWidth: 1, stroke: palette.blue.bright, fill: 'none' }} />
            </Sparklines>
          </ChartContainer>
          <SettingsText spacing='xxsmall'>
            {t('live.network.round-trip-time')}{' '}
            <Tooltip title={t('live.network.round-trip-time-info')}>
              <Icon iconId='information' />
            </Tooltip>
          </SettingsText>
          <Text color='grey40' size='small' spacing='xxsmall'>
            {formatTime(callStats.RTT)}
          </Text>
          <ChartContainer>
            <Sparklines data={RTTWindow} width={300} height={30} min={0} limit={40}>
              <SparklinesCurve style={{ strokeWidth: 1, stroke: palette.blue.bright, fill: 'none' }} />
            </Sparklines>
          </ChartContainer>
        </>
      )}
    </View>
  )
}
