export const ButtonClickedEventId = 'button_clicked'

type ContinueLaterButtonId = 'continue-later'

export type CourseButtonId =
  | 'start-course-header'
  | 'resume-course-header'
  | 'exit-course-header'
  | 'resume-course-module-list'
  | 'review-course-module-list'

type ReviewButtonId = 'clue'
type QuizButtonId = 'quiz-start' | 'quiz-resume' | 'quiz-retake'
type PlacementTestButtonId = 'placement-test-start' | 'placement-test-resume'
type NextUpButtonId = 'next-up-start' | 'next-up-resume' | 'next-up-skip' | 'next-up-end-course'

type ButtonId =
  | CourseButtonId
  | ContinueLaterButtonId
  | ReviewButtonId
  | QuizButtonId
  | PlacementTestButtonId
  | NextUpButtonId

export type ButtonClickedProps = {
  buttonId: ButtonId
  courseId?: string
  moduleId?: string
  lessonId?: string
  questionGroupId?: string
  questionId?: string
  session?: string
  courseTitle?: string
  moduleTitle?: string
  lessonTitle?: string
}
