import { produce } from 'immer'
import { useAtom } from 'jotai'
import React from 'react'
import { useTranslation } from 'sierra-client/hooks/use-translation'
import { labelToString } from 'sierra-client/lib/filter/components/common'
import { valueId } from 'sierra-client/lib/filter/components/predicate-utils'
import { useSelector } from 'sierra-client/state/hooks'
import { selectUser } from 'sierra-client/state/user/user-selector'
import { DropdownWidth } from 'sierra-client/views/manage/users/components/user-settings-panel/general-tab/common'
import { useGeneralSettingsDraft } from 'sierra-client/views/manage/users/components/user-settings-panel/general-tab/use-general-settings-draft'
import { userDetailDataAtom } from 'sierra-client/views/manage/users/manage-user-details/atoms'
import { ValueWithoutExerciseAndFile } from 'sierra-domain/filter/datatype/value'
import { UserAccessRoleDomainRep } from 'sierra-domain/user-attributes/user-invitation-domain-rep'
import { MenuItem } from 'sierra-ui/components'
import { SingleSelectDropdown } from 'sierra-ui/primitives/menu-dropdown'

const ownerRegexPattern = /^[a-z0-9.]*@sanalabs\.com$/

type MenuItemWithChoiceDomainValue = MenuItem & {
  value?: ValueWithoutExerciseAndFile
}

export const AccessRoleDropdown: React.FC<{ userAccessRoleDomainRep: UserAccessRoleDomainRep }> = ({
  userAccessRoleDomainRep,
}) => {
  const { dynamicT } = useTranslation()
  const [userDetails] = useAtom(userDetailDataAtom)
  const me = useSelector(selectUser)
  const { generalSettingsDraft, setGeneralSettingsDraft, initialGeneralSettings, readOnlyMetadata } =
    useGeneralSettingsDraft()

  const currentUserRoleCannotBeManaged =
    // If the user's role is not in the invitation domain, it can't be managed by the admin
    initialGeneralSettings.accessRole !== undefined &&
    userAccessRoleDomainRep.domain.choices.find(c => c.value.value === initialGeneralSettings.accessRole) ===
      undefined

  const unmanagedRoleMenuItem: MenuItemWithChoiceDomainValue | undefined = currentUserRoleCannotBeManaged
    ? {
        id: userDetails!.accessRole!.role,
        type: 'label' as const,
        label: dynamicT(
          userDetails!.accessRole!.translationKey,
          undefined,
          userDetails!.accessRole!.defaultLabel
        ),
        value: { type: 'value.string', value: userDetails!.accessRole!.role },
      }
    : undefined

  const menuItems: MenuItemWithChoiceDomainValue[] = [
    ...userAccessRoleDomainRep.domain.choices
      .filter(c => {
        return c.value.value !== 'owner' || ownerRegexPattern.test(userDetails?.email ?? '')
      })
      .map(value => {
        return {
          id: valueId(value.value),
          type: 'label' as const,
          label: labelToString(value.label, dynamicT),
          value: value.value,
        }
      }),
    {
      id: 'remove',
      type: 'label' as const,
      label: 'No role',
      value: {
        type: 'value.string',
        value: '', // TODO: hack to allow clearing the role, should be removed when testing phase is over
      },
      color: 'foreground/muted',
    },
  ]

  const roleIsNotChangeable =
    // You shouldn't be able to upgrade guest users since they have fake emails
    initialGeneralSettings.accessRole === 'owner' ||
    initialGeneralSettings.accessRole === 'guest' ||
    currentUserRoleCannotBeManaged

  const disabled = readOnlyMetadata || me === undefined || roleIsNotChangeable

  const handleSelect = (item: MenuItemWithChoiceDomainValue): void =>
    setGeneralSettingsDraft(
      produce(generalSettingsDraft, edit => {
        if (initialGeneralSettings.accessRole === undefined && item.value?.value === '') {
          // TODO: related to above hack, to not treat "No role" option as a change to the user if they currently have no role
          edit.accessRole = undefined
        } else {
          edit.accessRole = item.value?.value as string
        }
      })
    )

  const presentedMenuItems = unmanagedRoleMenuItem !== undefined ? [unmanagedRoleMenuItem] : menuItems

  return (
    <DropdownWidth>
      <SingleSelectDropdown
        disabled={disabled}
        selectedItem={presentedMenuItems.find(i => i.value?.value === generalSettingsDraft.accessRole)}
        menuItems={presentedMenuItems}
        onSelect={handleSelect}
      />
    </DropdownWidth>
  )
}
