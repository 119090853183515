/**
 * The sana now selectors use the same state as the live sessions, but since they are different features they are kept separate.
 *
 */
import { createSelector } from '@reduxjs/toolkit'
import { LiveSessionState } from 'sierra-client/state/live-session/types'
import { RootState } from 'sierra-client/state/types'

const selectState = (state: RootState): LiveSessionState => state.liveSession

export const selectLocalAwarenessState = createSelector(selectState, state => state.localAwarenessState)
