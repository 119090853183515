import { Logger } from 'sierra-client/core/logging/logger'

export const courseHelperRecommendationClicked = Logger.trackLoggerWithExtra<
  { typeOfRecommendation: 'assigned' | 'recommendations' },
  { typeOfRecommendation: 'assigned' | 'recommendations' }
>(
  'course_helper_recommendation_clicked',

  input => {
    return {
      ...input,
    }
  }
)

export const courseHelperGoToBlockingExerciseClicked = Logger.trackLoggerWithExtra<
  { typeOfBlockingExercise: string },
  { typeOfBlockingExercise: string }
>(
  'course_helper_go_to_blocking_exercise_clicked',

  input => {
    return {
      ...input,
    }
  }
)
