import { useTranslation } from 'sierra-client/hooks/use-translation'
import { replaceNodeWithId, updateNodeWithId } from 'sierra-client/views/v3-author/command'
import { Entity } from 'sierra-domain/entity'
import { nanoid12 } from 'sierra-domain/nanoid-extensions'
import { QuestionCardBody } from 'sierra-domain/v3-author'
import {
  createQuestionCardMatchThePairsBody,
  createQuestionCardMultipleChoiceBody,
} from 'sierra-domain/v3-author/create-blocks'
import { MenuItem } from 'sierra-ui/components'
import { SingleSelectDropdown } from 'sierra-ui/primitives/menu-dropdown'
import { Editor } from 'slate'
import { useSlateStatic } from 'slate-react'
import styled from 'styled-components'

const canConvertQuestionBody = (
  newQuestionType: QuestionCardBody['type'],
  currentQuestionType: QuestionCardBody['type']
): boolean =>
  (currentQuestionType === 'question-card-select-all-that-apply-body' ||
    currentQuestionType === 'question-card-pick-the-best-option-body') &&
  (newQuestionType === 'question-card-select-all-that-apply-body' ||
    newQuestionType === 'question-card-pick-the-best-option-body')

const questionBodyConverter = ({
  editor,
  question,
  newQuestionType,
}: {
  editor: Editor
  question: Entity<QuestionCardBody>
  newQuestionType: QuestionCardBody['type']
}): void => {
  const { type: currentQuestionType, id: questionId } = question

  if (canConvertQuestionBody(newQuestionType, currentQuestionType)) {
    const newId = nanoid12()
    updateNodeWithId(editor, questionId, { id: newId, type: newQuestionType })
  } else if (newQuestionType === 'question-card-match-the-pairs-body') {
    replaceNodeWithId(editor, questionId, createQuestionCardMatchThePairsBody())
  } else if (newQuestionType === 'question-card-select-all-that-apply-body') {
    replaceNodeWithId(
      editor,
      questionId,
      createQuestionCardMultipleChoiceBody({ type: 'question-card-select-all-that-apply-body' })
    )
  } else if (newQuestionType === 'question-card-pick-the-best-option-body') {
    replaceNodeWithId(
      editor,
      questionId,
      createQuestionCardMultipleChoiceBody({ type: 'question-card-pick-the-best-option-body' })
    )
  }
}

const Wrapper = styled.div`
  width: 200px;
`

export const DropdownQuestionType: React.FC<{
  question: Entity<QuestionCardBody>
}> = ({ question }) => {
  const { t } = useTranslation()
  const editor = useSlateStatic()

  const items: MenuItem<QuestionCardBody['type']>[] = [
    {
      id: 'question-card-select-all-that-apply-body',
      label: t('author.slate.select-all-that-apply'),
      icon: 'checkbox--checked',
      type: 'label',
    },
    {
      id: 'question-card-pick-the-best-option-body',
      label: t('author.slate.pick-the-best-option'),
      icon: 'radio-button--checked',
      type: 'label',
    },
    {
      id: 'question-card-match-the-pairs-body',
      label: t('author.slate.match-the-pairs'),
      icon: 'puzzle',
      type: 'label',
    },
  ]

  return (
    <Wrapper>
      <SingleSelectDropdown
        disableContentTheme
        searchPlaceholder={t('menu.search.placeholder')}
        selectedItem={items.find(it => it.id === question.type)}
        onSelect={item => {
          questionBodyConverter({
            editor,
            question,
            newQuestionType: item.id as QuestionCardBody['type'],
          })
        }}
        menuItems={items}
      />
    </Wrapper>
  )
}
