import type { AgoraRTCErrorCode } from 'agora-rtc-sdk-ng'

type AgoraRTCErrorCodeString = `${AgoraRTCErrorCode}`

export interface AgoraRTCError {
  code: AgoraRTCErrorCodeString
  message: string
  name: string
}

type PeripheralErrorCode = 'PERMISSION_DENIED' | 'NOT_READABLE' | 'UNKNOWN' | 'DEVICE_NOT_FOUND'
export class CameraError extends Error {
  code: PeripheralErrorCode

  constructor(error: AgoraRTCError) {
    super(error.message)
    this.code =
      error.code === 'PERMISSION_DENIED' || error.code === 'NOT_READABLE' || error.code === 'DEVICE_NOT_FOUND'
        ? error.code
        : 'UNKNOWN'
  }
}

export class MicrophoneError extends Error {
  code: PeripheralErrorCode

  constructor(error: AgoraRTCError) {
    super(error.message)
    this.code =
      error.code === 'PERMISSION_DENIED' || error.code === 'NOT_READABLE' || error.code === 'DEVICE_NOT_FOUND'
        ? error.code
        : 'UNKNOWN'
  }
}

export class ScreenShareError extends Error {
  code: AgoraRTCErrorCodeString
  constructor(error: AgoraRTCError) {
    super(error.message)
    this.code = error.code
  }
}

class InvalidDeviceIdError extends Error {
  constructor(deviceId: string) {
    super(`Invalid device id: ${deviceId}`)
  }
}

export class DenoiserError extends Error {
  code: 'ERROR_INITIALIZING' | 'OVERLOADED'
  originalError?: unknown

  constructor(code: 'ERROR_INITIALIZING' | 'OVERLOADED', error?: unknown) {
    super(`AI Denoiser error`)
    this.originalError = error
    this.code = code
  }
}

export class VirtualBackgroundError extends Error {
  code: 'ERROR_INITIALIZING' | 'OVERLOADED'
  originalError?: unknown

  constructor(code: 'ERROR_INITIALIZING' | 'OVERLOADED', error?: unknown) {
    super(`Virtual Background error`)
    this.originalError = error
    this.code = code
  }
}

export class UnknownError extends Error {
  originalError: AgoraRTCError

  constructor(error: AgoraRTCError) {
    super(error.message)
    this.originalError = error
  }
}

export type VideoCallServiceError =
  | CameraError
  | MicrophoneError
  | InvalidDeviceIdError
  | DenoiserError
  | VirtualBackgroundError
  | ScreenShareError
  | UnknownError
