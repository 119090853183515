import { LayoutGroup } from 'framer-motion'
import { Atom, useAtomValue } from 'jotai'
import * as React from 'react'
import { useTranslation } from 'sierra-client/hooks/use-translation'
import {
  TabularToolbar,
  TabularToolbarDownloadButton,
} from 'sierra-client/lib/tabular/components/tabular-toolbar'
import { Filtering } from 'sierra-client/lib/tabular/components/util'
import { UserTableData } from 'sierra-client/lib/tabular/dataloader/users'
import { exportTableData } from 'sierra-client/lib/tabular/export'
import { TabularProviderFromTableAPI } from 'sierra-client/lib/tabular/provider'
import { BasicTabular } from 'sierra-client/lib/tabular/provider/components/basic'
import { UseTableAPI } from 'sierra-client/lib/tabular/use-table-api'
import { ColumnLayout } from 'sierra-client/views/manage/components/layout/column-layout'
import { ManageHeader, ManageHeaderSearchConfig } from 'sierra-client/views/manage/components/manage-header'
import { PageHeader } from 'sierra-client/views/settings/components/page-header'

const UsersToolbar: React.FC<{
  tableAPI: UseTableAPI<UserTableData, unknown>
  toolbarActionsAtom: Atom<React.ReactNode>
}> = ({ tableAPI, toolbarActionsAtom }) => {
  const { t } = useTranslation()
  const toolbarActions: React.ReactNode = useAtomValue(toolbarActionsAtom)

  return (
    <TabularToolbar
      countsTranslationKeys={{
        totalKey: 'manage.program-members.n-users',
        filterKey: 'manage.program-members.n-filtered',
        selectedKey: 'manage.tables.n-selected',
      }}
      api={tableAPI.api}
      clearFilters={true}
      actions={
        <>
          {toolbarActions}
          <TabularToolbarDownloadButton
            onDownloadCSV={() =>
              exportTableData({ api: tableAPI.api, dataLoader: tableAPI.dataLoader, t, fileType: 'csv' })
            }
            onDownloadXLSX={() =>
              exportTableData({ api: tableAPI.api, dataLoader: tableAPI.dataLoader, t, fileType: 'xlsx' })
            }
          />
        </>
      }
      enableAllSelection={true}
    />
  )
}

type UsersTabularProps = {
  tableAPI: UseTableAPI<UserTableData, unknown>
  headerActionElement?: React.ReactNode
  toolbarActionsAtom: Atom<React.ReactNode>
}

export const UsersTabular: React.FC<UsersTabularProps> = ({
  tableAPI,
  headerActionElement,
  toolbarActionsAtom,
}) => {
  const { api } = tableAPI

  const searchBar: ManageHeaderSearchConfig = { placeholder: 'manage.search.users' }

  return (
    <TabularProviderFromTableAPI tableAPI={tableAPI}>
      <ColumnLayout>
        <PageHeader
          title='dictionary.user-plural'
          withoutMargin
          helpLink='https://help.sana.ai/en/collections/89965-managing-users'
        />

        <ManageHeader
          api={api}
          search={searchBar}
          filtering={<Filtering api={tableAPI.api} />}
          actionElement={headerActionElement}
        />

        <LayoutGroup>
          <UsersToolbar tableAPI={tableAPI} toolbarActionsAtom={toolbarActionsAtom} />
          <BasicTabular />
        </LayoutGroup>
      </ColumnLayout>
    </TabularProviderFromTableAPI>
  )
}
