import React from 'react'
import { useTranslation } from 'sierra-client/hooks/use-translation'
import { labelToString } from 'sierra-client/lib/filter/components/common'
import { valueId } from 'sierra-client/lib/filter/components/predicate-utils'
import { useIsMobile } from 'sierra-client/state/browser/selectors'
import {
  CustomAttributeDisplay,
  PillList,
} from 'sierra-client/views/manage/components/user-attributes/flows/components/pill-section'
import { useComposeUserInvitationConfig } from 'sierra-client/views/manage/components/user-attributes/flows/invite-users/hooks/use-compose-user-invitation-config'
import {
  getValueFromConfig,
  getValueRepFromDomainChoices,
} from 'sierra-client/views/manage/components/user-attributes/flows/invite-users/utils'
import { useInvitationDomains } from 'sierra-client/views/manage/components/user-attributes/hooks/use-invitation-domains'
import { UserInvitationAttribute } from 'sierra-domain/user-attributes/user-invitation-attribute'
import { UserInvitationDomainRep } from 'sierra-domain/user-attributes/user-invitation-domain-rep'

export const UserInvitationPills: React.FC<{
  attributes: UserInvitationAttribute[]
  email: string
}> = ({ attributes, email }) => {
  const { dynamicT } = useTranslation()
  const isMobile = useIsMobile()
  const { customAttributeDomains, managerDomain } = useInvitationDomains()
  const { userAttributesConfig } = useComposeUserInvitationConfig()

  const domainReps: UserInvitationDomainRep[] = [
    ...(managerDomain === undefined ? [] : [managerDomain]),
    ...customAttributeDomains,
  ]

  const takeMax = isMobile ? 1 : 2
  const sliceIndex = Math.min(takeMax, attributes.length)
  const displays: CustomAttributeDisplay[] = attributes
    .slice(0, sliceIndex)
    .map(a => {
      const domainRep = domainReps.find(domainRep => domainRep.ref === a.ref)

      if (domainRep === undefined) throw new Error('DomainRep not found for attribute.')

      const label = labelToString(domainRep.label, dynamicT)
      const value = getValueFromConfig(domainRep, userAttributesConfig, email)

      if (value === undefined || value.type === 'value.none') {
        return undefined
      }

      if (domainRep === managerDomain) {
        const managerLabel = labelToString(
          getValueRepFromDomainChoices(managerDomain.domain, valueId(value)).label,
          dynamicT
        )

        return {
          label,
          value: managerLabel,
        }
      }

      return {
        label,
        value: value.value.toString(),
      }
    })
    .filter((v): v is CustomAttributeDisplay => Boolean(v))

  const remainingElements = attributes.length - sliceIndex

  return <PillList attributeDisplays={displays} remainingElements={remainingElements} />
}
