import { RouterLink, type RouterLinkProps } from 'sierra-client/components/common/link'
import { FlexibleContentType } from 'sierra-domain/api/author-v2'
import { ContentKind } from 'sierra-domain/api/learn'
import { assertNever } from 'sierra-domain/utils'
import { z } from 'zod'

const ExternalLinkURL = z
  .string()
  .refine(x => /^https?:\/\//.test(x), { message: 'Invalid external link' })
  .brand<'ExternalLinkURL'>()
type ExternalLinkURL = z.infer<typeof ExternalLinkURL>
export const isExternalLinkURL = (url?: LinkURL): url is ExternalLinkURL =>
  ExternalLinkURL.safeParse(url).success

export type InternalLinkURL = string & z.BRAND<'InternalLinkURL'>
export type LinkURL = InternalLinkURL | ExternalLinkURL

type LiveSessionLinkable = {
  id: string
  type: 'live-session'
  courseId: string
}

type HomeworkLinkable = {
  id: string
  type: 'homework'
  courseId: string
}

type OtherLinkable = {
  id: string
  type: Exclude<ContentKind, 'program' | 'live-session' | 'homework'>
  url?: string
  courseGroupId?: string
  activeEditionId?: string
}

type NonProgramLinkable = LiveSessionLinkable | HomeworkLinkable | OtherLinkable

type ProgramLinkable = {
  id: string
  type: 'program'
  nextStep?: NonProgramLinkable
}

export type Linkable = NonProgramLinkable | ProgramLinkable
type LinkableLike = Pick<Linkable, 'type' | 'id'>

// The "details URL" should lead to a overview of the entity. For courses
// this will be the course overview page, if it exists.
export const detailsUrl = (linkable: Linkable): InternalLinkURL => {
  const id = linkable.id

  switch (linkable.type) {
    case 'native:self-paced':
      return (
        linkable.courseGroupId === undefined ? `/s/${id}` : `/course/${linkable.courseGroupId}`
      ) as InternalLinkURL
    case 'native:course-group':
      return `/course/${id}` as InternalLinkURL
    case 'scorm:course-group':
      return `/course/${id}` as InternalLinkURL
    case 'path':
      return `/path/${id}` as InternalLinkURL
    case 'scorm':
      return (
        linkable.courseGroupId === undefined ? `/course/${id}` : `/course/${linkable.courseGroupId}`
      ) as InternalLinkURL
    case 'link':
    case 'linkedin':
    case 'native:live':
      return `/course/${id}` as InternalLinkURL
    case 'live-session':
      return `/course/${linkable.courseId}` as InternalLinkURL
    case 'homework':
      return `/s/${linkable.courseId}` as InternalLinkURL
    case 'program':
      return `/program/${id}` as InternalLinkURL
    case 'native:event-group':
      return `/event/${id}` as InternalLinkURL
    default:
      assertNever(linkable)
  }
}

const _continueUrlWithoutProgram = (linkable: NonProgramLinkable): LinkURL => {
  const id = linkable.id

  switch (linkable.type) {
    case 'native:course-group':
      if (linkable.activeEditionId !== undefined) {
        return `/s/${linkable.activeEditionId}/next` as InternalLinkURL
      } else {
        // Link to course overview.
        return detailsUrl(linkable)
      }
    case 'scorm:course-group':
      if (linkable.activeEditionId !== undefined) {
        return `/course/${linkable.activeEditionId}/scorm` as InternalLinkURL
      } else {
        // We could link to /next here, but currently the overview is the only way to access the list of courses.
        return detailsUrl(linkable)
      }
    case 'native:self-paced':
      return `/s/${id}/next` as InternalLinkURL
    case 'scorm':
      return `/course/${id}/scorm` as InternalLinkURL
    case 'live-session':
      return `/l/${id}` as InternalLinkURL
    case 'path':
    case 'homework':
    case 'native:live':
      return detailsUrl(linkable)
    case 'link':
    case 'linkedin':
      return (linkable.url as ExternalLinkURL | undefined) ?? detailsUrl(linkable)
    case 'native:event-group':
      return `/event/${id}` as InternalLinkURL // Todo event groups
    default:
      assertNever(linkable)
  }
}

// The "continue URL" should be the fastest way for the learner to jump into the content.
// For a course they should be able to click it and go directly to the course content.
export const continueUrl = (linkable: Linkable): LinkURL => {
  if (linkable.type === 'program') {
    const nextStep = linkable.nextStep
    if (nextStep === undefined) {
      return `/program/${linkable.id}` as InternalLinkURL
    } else {
      const next = _continueUrlWithoutProgram(nextStep)
      // Make sure we deal with external links first
      if (isExternalLinkURL(next)) {
        return next
      } else {
        return `/program/${linkable.id}${next}` as InternalLinkURL
      }
    }
  } else {
    return _continueUrlWithoutProgram(linkable)
  }
}

// This is only used in the library table rows.
export const titleUrl = (linkable: Linkable): string => {
  if (linkable.type === 'live-session' || linkable.type === 'native:self-paced') {
    return continueUrl(linkable)
  }
  return detailsUrl(linkable)
}

export const manageUrl = ({ id, type }: LinkableLike): InternalLinkURL => {
  switch (type) {
    case 'path':
      return `/manage/paths/${id}` as InternalLinkURL
    case 'homework':
      return `/manage/exercises/${id}` as InternalLinkURL
    case 'live-session':
      return `/manage/events/${id}` as InternalLinkURL
    case 'native:course-group':
    case 'scorm:course-group':
      return `/manage/course-group/${id}` as InternalLinkURL
    case 'native:self-paced':
    case 'native:live':
    case 'linkedin':
    case 'scorm':
    case 'link':
      return `/manage/courses/${id}` as InternalLinkURL
    case 'program':
      return `/manage/programs/${id}` as InternalLinkURL
    case 'native:event-group':
      return `/manage/in-person-events/${id}` as InternalLinkURL
    default:
      assertNever(type)
  }
}

export function getFlexibleContentSubpath(kind: FlexibleContentType): 's' | 'l' {
  switch (kind) {
    case 'native:self-paced':
      return 's'
    case 'native:live':
      return 'l'
    default:
      assertNever(kind)
  }
}

type URLLinkProps = Omit<React.AnchorHTMLAttributes<HTMLAnchorElement>, keyof RouterLinkProps | 'href'> &
  RouterLinkProps & { href: LinkURL; children: React.ReactNode } & React.RefAttributes<HTMLAnchorElement>
/**
 * Component to help us Link properly to an external URL
 * */
export const URLLink: React.FC<URLLinkProps> = props => {
  // If we see that the current URL is external, try to set target
  const inferredTarget = isExternalLinkURL(props.href) ? '_blank' : '_self'
  const target = props.target ?? inferredTarget

  // Avoid next link tag if target is supposed to go elsewhere
  if (target === '_blank') {
    return (
      <a {...props} target={target}>
        {props.children}
      </a>
    )
  }

  return (
    <RouterLink {...props} target={target}>
      {props.children}
    </RouterLink>
  )
}
