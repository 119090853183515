import { useEffect, useRef } from 'react'

/**
 * Compares two arrays. If references are different but lengths are the same, compares elements by reference.
 * Note: Doesn't do a deep equality check on elements.
 * */
export function areArraysEqualShallow<T>(a: readonly T[], b: readonly T[]): boolean {
  if (a === b) return true

  if (a.length !== b.length) return false

  return a.every((x, i) => x === b[i])
}

/** Memoizes value by comparing with passed equality function. */
export const useMemoByCompare = <A>(next: A, compare: (x: A, y: A) => boolean): A => {
  const previousRef = useRef(next)
  const previous = previousRef.current
  const isEqual = compare(previous, next)

  useEffect(() => {
    if (!isEqual) {
      previousRef.current = next
    }
  })

  return isEqual ? previous : next
}
