import { createFileRoute } from '@tanstack/react-router'
import { requireLoggedIn } from 'sierra-client/core/require-logged-in'
import { PaginatedTranscriptFetcherAndRenderer } from 'sierra-client/views/v3-author/export-pdf/export-user-transcript'
import { UserId } from 'sierra-domain/api/uuid'
import { z } from 'zod'

const Page = (): JSX.Element => {
  const userId = Route.useParams({ select: params => params.userId })

  return <PaginatedTranscriptFetcherAndRenderer userId={userId as UserId} />
}

export const Route = createFileRoute('/manage/user/$userId/export-transcript')({
  component: requireLoggedIn(Page, { allowScorm: true }) as React.FC,
  params: {
    parse: z.object({ userId: UserId }).parse,
    stringify: p => p,
  },
})
