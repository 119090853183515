import React, { useState } from 'react'
import { Column } from 'react-table'
import { SelectableHeader, SelectableRow } from 'sierra-client/components/table/select'
import { SortableHeader } from 'sierra-client/components/table/sortable-header'
import { TableMediumSearchTrigger } from 'sierra-client/components/table/table-medium'
import { date } from 'sierra-client/core/format'
import { useTranslation } from 'sierra-client/hooks/use-translation'
import {
  ManageTableSmall,
  useManageTableSmall,
} from 'sierra-client/views/manage/components/manage-table-small'
import { SmallTableWrapper } from 'sierra-client/views/manage/components/small-table-wrapper'
import { CourseRating } from 'sierra-domain/api/manage'
import { Text } from 'sierra-ui/primitives'

export type CourseFeedbacksProps = {
  feedbacks: CourseRating[]
}

const mapFeedbackToCsv = (feedback: CourseRating): Record<string, string> => ({
  comment: feedback.comment,
  createdAt: date(feedback.createdAt),
  rating: feedback.rating.toFixed(1),
})

export const CourseFeedbackTable: React.FC<CourseFeedbacksProps> = ({ feedbacks }) => {
  const { t } = useTranslation()
  const [isSearchOpen, setIsSearchOpen] = useState<boolean>(false)

  const columns: Column<CourseRating>[] = React.useMemo(
    () => [
      {
        id: 'comment',
        accessor: 'comment',
        Header: p => (
          <>
            <SelectableHeader {...p} />
            <SortableHeader label={t('dictionary.comment-singular')} smallLabel {...p} />
          </>
        ),
        Cell: p => (
          <>
            <SelectableRow {...p} />
            <Text color='LEGACY_DEFAULT_TEXT_COLOR_REPLACE_ASAP' size='small'>
              {p.row.original.comment}
            </Text>
          </>
        ),
        width: '60%',
      },
      {
        id: 'rating',
        accessor: item => `${item.rating.toFixed(1)} / 5`,
        Header: p => <SortableHeader label={t('table.rating')} {...p} />,
        width: '15%',
      },
      {
        id: 'createdAt',
        accessor: i => date(i.createdAt),
        width: '15%',
        Header: p => <SortableHeader label={t('dictionary.date')} {...p} />,
      },
      {
        width: '10%',
        id: 'actions',
        Header: <TableMediumSearchTrigger onClick={() => setIsSearchOpen(true)} />,
      },
    ],
    [t]
  )

  const { tableInstance } = useManageTableSmall({
    tableOptions: { data: feedbacks, columns: columns },
    getEntityId: () => '',
  })

  return (
    <div>
      <SmallTableWrapper title={t('dictionary.feedback')}>
        <ManageTableSmall
          tableInstance={tableInstance}
          onViewDetails={() => {}}
          getEntityId={() => ''}
          mapEntityToCsv={mapFeedbackToCsv}
          isSearchOpen={isSearchOpen}
          searchTrigger={setIsSearchOpen}
          translations={{
            searchPlaceholder: t('dictionary.search'),
            tableLoading: t('dictionary.loading'),
            tableNoResults: t('author.slate.no-results'),
            csvPrefix: t('dictionary.feedback'),
          }}
          getCellProps={() => ({
            style: { verticalAlign: 'top' },
          })}
          isLoading={false}
          focusedId={undefined}
          bulkActions={<></>}
          footerButton={<></>}
        />
      </SmallTableWrapper>
    </div>
  )
}
