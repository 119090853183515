import _ from 'lodash'

export type OptionIdsToNumber = { [key in string]: number }

const getTotalVotesFromOptionIdsToNumber = (optionIdsToVoteCount: OptionIdsToNumber): number =>
  _.sumBy(Object.values(optionIdsToVoteCount))

const getUnroundedPollResults = (optionIdsToVoteCount: OptionIdsToNumber): OptionIdsToNumber => {
  const totalVotes = getTotalVotesFromOptionIdsToNumber(optionIdsToVoteCount)
  const unroundedPercentages: OptionIdsToNumber = {}

  Object.entries(optionIdsToVoteCount).forEach(([id, votes]) => {
    unroundedPercentages[id] = totalVotes > 0 ? (votes / totalVotes) * 100 : 0
  })

  return unroundedPercentages
}

export const calculateRoundedPercentages = (optionIdsToVoteCount: OptionIdsToNumber): OptionIdsToNumber => {
  const totalVotes = getTotalVotesFromOptionIdsToNumber(optionIdsToVoteCount)
  const unroundedPollResults = getUnroundedPollResults(optionIdsToVoteCount)

  if (totalVotes === 0) return unroundedPollResults

  // Calculate rounded percentages and make sure they add up to 100
  const diff = 100 - _.sumBy(Object.values(unroundedPollResults), item => Math.round(item))

  const roundedPollResults: OptionIdsToNumber = {}

  Object.entries(unroundedPollResults)
    .sort((a, b) => (Math.round(a[1]) - a[1] > Math.round(b[1]) - b[1] ? 1 : -1))
    .forEach(([id, value], index) => {
      if (diff > index) {
        roundedPollResults[id] = Math.round(value) + 1
      } else if (index >= Object.values(unroundedPollResults).length + diff) {
        roundedPollResults[id] = Math.round(value) - 1
      } else {
        roundedPollResults[id] = Math.round(value)
      }
    })

  return roundedPollResults
}
