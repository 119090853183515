import { useCallback } from 'react'
import { useTranslation } from 'sierra-client/hooks/use-translation'
import { getGlobalRouter } from 'sierra-client/router'
import { useDispatch } from 'sierra-client/state/hooks'
import { ViewAllAssignments } from 'sierra-client/views/course-helper/content/actions'
import { RecommendationDisplay } from 'sierra-client/views/course-helper/content/course-helper-recommendations/recommendation-display'
import { courseHelperRecommendationClicked } from 'sierra-client/views/course-helper/logger'
import { HelperActions } from 'sierra-client/views/course-helper/shared/shared'
import { AssignedContentMetadata } from 'sierra-client/views/learner/home/home-v3'
import { LearnEntity } from 'sierra-domain/api/entities'
import { Text, View } from 'sierra-ui/primitives'

export const Assignments: React.FC<{ replaceHeadline?: React.ReactNode; assignments: LearnEntity[] }> = ({
  replaceHeadline,
  assignments,
}) => {
  const dispatch = useDispatch()
  const goToLearn = useCallback(() => {
    void getGlobalRouter().navigate({ to: '/learn' })
    void dispatch(
      courseHelperRecommendationClicked({
        typeOfRecommendation: 'assigned',
      })
    )
  }, [dispatch])
  const { t } = useTranslation()

  return (
    <View direction='column' gap='16'>
      {replaceHeadline === undefined ? (
        <Text bold color='foreground/secondary'>
          {t('helper.keep-learning')}
        </Text>
      ) : (
        replaceHeadline
      )}
      <View direction='column' gap='16'>
        {assignments.map(e => (
          <RecommendationDisplay
            key={e.id}
            entity={e}
            metadata={<AssignedContentMetadata entity={e} />}
            recommendationType='assigned'
          />
        ))}
      </View>
      <HelperActions>
        <ViewAllAssignments onClick={goToLearn} />
      </HelperActions>
    </View>
  )
}
