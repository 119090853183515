import { useMemo } from 'react'
import { selectCardExercises } from 'sierra-client/state/card-progress/selectors'
import { useSelector } from 'sierra-client/state/hooks'
import { SelfPacedQuestionProvider } from 'sierra-client/views/self-paced/question-context-provider'
import { SelfPacedSlateCard } from 'sierra-client/views/self-paced/slate-card'
import { useFilteredDocument } from 'sierra-client/views/self-paced/use-filtered-document'
import { isElementType } from 'sierra-client/views/v3-author/queries'
import { CreateContentId } from 'sierra-domain/api/nano-id'
import { FileId } from 'sierra-domain/flexible-content/identifiers'
import { SlateFile, SlateFileType } from 'sierra-domain/flexible-content/types'
import { idsInNodes } from 'sierra-domain/slate-util'
import { assertWith } from 'sierra-domain/utils'
import { SlateDocument } from 'sierra-domain/v3-author'
import { Editor, Node } from 'slate'

function useQuestionBodyExerciseIds({
  document,
  courseId,
  fileId,
}: {
  document: SlateDocument | undefined
  courseId: CreateContentId
  fileId: FileId
}): string[] {
  const exercises = useSelector(state => selectCardExercises(state, courseId, fileId))
  return useMemo(() => {
    if (document === undefined) return []
    const foundQuestionCards = document.flatMap(node =>
      Array.from(Node.elements(node)).filter(([node]) => isElementType('question-card', node))
    )

    return foundQuestionCards
      .flatMap(elementEntry => {
        if (elementEntry[0].type === 'question-card') {
          const correspondingExercise = idsInNodes([elementEntry[0]]).map(id => exercises?.[id])
          return correspondingExercise.map(exercise => (exercise ? exercise.id : undefined))
        } else return undefined
      })
      .filter(it => it !== undefined)
  }, [document, exercises])
}

export const SelfPacedSlate: React.FC<{
  courseId: CreateContentId
  file: SlateFile
  editor: Editor
}> = ({ courseId, file, editor }) => {
  assertWith(SlateFileType, file.data.type)

  const fileId = file.id
  const document = useFilteredDocument({ courseId, fileId })
  const questionBodyExerciseIds = useQuestionBodyExerciseIds({ document, courseId, fileId })

  if (document === undefined) return <span>Unable to find lesson with id {fileId}</span>
  return (
    <SelfPacedQuestionProvider questionBodyIds={questionBodyExerciseIds}>
      <SelfPacedSlateCard filteredDocument={document} editor={editor} courseId={courseId} file={file} />
    </SelfPacedQuestionProvider>
  )
}
