import { UseQueryResult } from '@tanstack/react-query'
import { graphql } from 'sierra-client/api/graphql/gql'
import { IssuedCertificatesForUserQuery } from 'sierra-client/api/graphql/gql/graphql'
import { useGraphQuery } from 'sierra-client/api/hooks/use-graphql-query'
import { UserId } from 'sierra-domain/api/uuid'

const query = graphql(`
  query issuedCertificatesForUser($userId: UserId!) {
    user(id: $userId) {
      issuedCertificates {
        id
        expiresAt
        snapshotImageUrl
        supportingFileUrl
        pdfUrl

        certificate {
          id
          title
          previewImage {
            file
            width
            height
          }
          templateData {
            backgroundColor
          }
        }
      }
    }
  }
`)

export const useIssuedCertificatesForUser = (
  userId: UserId
): UseQueryResult<IssuedCertificatesForUserQuery, unknown> => {
  const result = useGraphQuery({ document: query, queryOptions: { staleTime: 60 * 1000 } }, { userId })

  return result
}
