import { spacing } from 'sierra-ui/theming'
import { v2_breakpoint } from 'sierra-ui/theming/breakpoints'
import { css } from 'styled-components'

export const editorGridItem = css`
  grid-column: 2 / -2;
`

const widths = css`
  /* This can be simplified once we have a global grid */
  &:is(p),
  &:is(h5),
  &:is(li) {
    > * {
      max-width: 82ch;
    }
  }
  &:is(h1) {
    > * {
      max-width: 28ch;
    }
  }
  &:is(h3) {
    > * {
      max-width: 40ch;
    }
  }
  &:is(h4) {
    > * {
      max-width: 55ch;
    }
  }
  p,
  h5,
  li {
    max-width: 82ch;
  }
  h1 {
    max-width: 28ch;
  }
  h3 {
    max-width: 40ch;
  }
  h4 {
    max-width: 55ch;
  }
`

const wideGrid = css`
  @media screen and (min-width: ${v2_breakpoint.phone}) {
    --gutter: 10%;
  }
  @media screen and (min-width: ${v2_breakpoint.tablet}) {
    --gutter: 10%;
  }
  @media screen and (min-width: ${v2_breakpoint.desktop}) {
    --gutter: 8%;
  }
`

const narrowGrid = css`
  --gutter: 8%;
  @media screen and (min-width: ${v2_breakpoint.tablet}) {
    --middle: minmax(1rem, 4rem);
    --gutter: 1fr;
  }
`

const editorGridInternal = css`
  ${widths}

  display: grid;

  --side-padding: ${p => (p.theme.editor.isNewPageCardAlignment ? 0 : spacing[16])};
  --middle: 1fr;
  --gutter: 1fr;

  grid-template-columns:
    var(--gutter)
    repeat(12, var(--middle))
    var(--gutter);

  @media screen and (min-width: ${v2_breakpoint.phone}) {
    --side-padding: ${p => (p.theme.editor.isNewPageCardAlignment ? 0 : spacing[32])};
  }

  > * {
    padding: 0 var(--side-padding);
    ${editorGridItem}
  }

  ${p => (p.theme.editor.isWideLayout ? wideGrid : narrowGrid)}
`

export const editorGrid = css`
  ${p => p.theme.editor.withGrid && editorGridInternal}
`

export const interactiveCardGrid = css`
  row-gap: 0;
  margin-top: auto;
  margin-bottom: auto;
  padding: 4rem 0.5rem;

  & {
    --middle: minmax(1rem, 6rem);
    --gutter: 1fr;

    @media screen and (max-width: ${v2_breakpoint.phone}) {
      --gutter: 4%;
    }
  }
`
