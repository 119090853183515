import React, { useEffect, useState } from 'react'
import { AvatarId } from 'sierra-domain/api/avatar-id'
import {
  NarrationsYMap,
  getAvatarId as getRemoteAvatarId,
  setAvatarId as setRemoteAvatarId,
} from 'sierra-domain/collaboration/narrations'

export function useAvatarId(
  narrationsYMap: NarrationsYMap,
  defaultAvatarId: AvatarId
): [avatarId: AvatarId, setAvatarId: React.Dispatch<React.SetStateAction<AvatarId>>] {
  const [localAvatarId, localSetAvatarId] = useState<AvatarId>(
    getRemoteAvatarId(narrationsYMap) ?? defaultAvatarId
  )

  useEffect(() => {
    if (localAvatarId !== getRemoteAvatarId(narrationsYMap)) {
      // Update the avatar id in the user's presence too, so we can show this to other clients
      setRemoteAvatarId(narrationsYMap, localAvatarId)
    }
  }, [localAvatarId, narrationsYMap])

  useEffect(() => {
    function observe(): void {
      localSetAvatarId(getRemoteAvatarId(narrationsYMap) ?? defaultAvatarId)
    }

    narrationsYMap.observe(observe)
    return () => {
      narrationsYMap.unobserve(observe)
    }
  }, [defaultAvatarId, narrationsYMap])

  return [localAvatarId, localSetAvatarId]
}
