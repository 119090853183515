import { createSelector } from '@reduxjs/toolkit'
import _ from 'lodash'
import { DateTime } from 'luxon'
import { CardProgressState } from 'sierra-client/state/card-progress/types'
import {
  selectCurrentCourseId,
  selectCurrentPath,
  selectCurrentProgram,
} from 'sierra-client/state/content/selectors'
import { PathEntity, ProgramEntity } from 'sierra-client/state/content/types'
import { selectFileIds, selectFlexibleContent } from 'sierra-client/state/flexible-content/selectors'
import { isFile, isFolder } from 'sierra-client/state/flexible-content/types'
import { RootState } from 'sierra-client/state/types'
import { toCourseForHref } from 'sierra-client/views/learner/path/get-href-to-course'
import { NextUp } from 'sierra-domain/api/backend-self-paced/types'
import { CourseId, NanoId12, PathId } from 'sierra-domain/api/nano-id'
import { ExerciseStatus, FlexibleContentStatus, PlacementTest, Review } from 'sierra-domain/api/strategy-v2'
import { FileId, FolderId } from 'sierra-domain/flexible-content/identifiers'
import { File, Folder } from 'sierra-domain/flexible-content/types'
import { guardWith } from 'sierra-domain/utils'

const selectState = (state: RootState): CardProgressState => state.cardProgress

export const selectCourseProgressState = createSelector(
  selectState,
  (state: RootState, courseId: NanoId12) => courseId,
  (state: CardProgressState, courseId: NanoId12) => state.progress[courseId]
)

export const selectCourseCardStatus = createSelector(
  selectState,
  (state: RootState, courseId: NanoId12) => courseId,
  (state: RootState, courseId: NanoId12, fileId: FileId) => fileId,
  (state: CardProgressState, courseId: NanoId12, fileId: FileId) =>
    state.progress[courseId]?.cardStatuses[fileId]
)

export const selectBackendNextUp = createSelector(
  selectState,
  (state: RootState, courseId: NanoId12) => courseId,
  (state: CardProgressState, courseId: NanoId12): NextUp => state.nextUps[courseId] ?? { type: 'none' }
)

export const selectPublishedAt = createSelector(
  selectState,
  (state: RootState, courseId: NanoId12) => courseId,
  (state: CardProgressState, courseId: NanoId12): string | undefined => state.publishedAts[courseId]
)

const selectModuleStatuses = createSelector(
  selectCourseProgressState,
  (courseProgress): FlexibleContentStatus['moduleStatuses'] | undefined => {
    if (courseProgress === undefined) return undefined

    return courseProgress.moduleStatuses
  }
)

export const selectCourseModuleStatus = createSelector(
  selectState,
  (state: RootState, courseId: NanoId12) => courseId,
  (state: RootState, courseId: NanoId12, folderId: FolderId) => folderId,
  (state: CardProgressState, courseId: NanoId12, folderId: FolderId) =>
    state.progress[courseId]?.moduleStatuses[folderId]
)

const selectOpenReviews = createSelector(selectModuleStatuses, (moduleStatuses): FolderId[] =>
  Object.entries(moduleStatuses ?? {})
    .filter(([, status]) => status?.review?.state === 'open')
    .map(([folderId]) => FolderId.parse(folderId))
)

const selectOpenPlacementTests = createSelector(selectModuleStatuses, (moduleStatuses): FolderId[] =>
  Object.entries(moduleStatuses ?? {})
    .filter(([, status]) => status?.placementTest?.state === 'open')
    .map(([folderId]) => FolderId.parse(folderId))
)

export const selectCourseProgress = createSelector(
  [selectCourseProgressState, selectOpenReviews, selectFileIds],
  (courseProgress, openReviews, fileIds): number | undefined => {
    if (fileIds.length === 0) {
      return undefined
    }
    const total = fileIds.length + openReviews.length
    return (
      _.chain(fileIds)
        .map(id => courseProgress?.cardStatuses[id])
        .sumBy(status => (status?.completed !== undefined ? 1 : 0))
        .value() / total
    )
  }
)

export const selectCourseIsCompleted = createSelector([selectCourseProgress], progress => progress === 1)

const getNextCardInOrder = (currentFileId: FileId, fileIds: FileId[]): FileId | undefined => {
  const currentIndex = fileIds.findIndex(fileId => fileId === currentFileId)
  if (currentIndex === -1) {
    return undefined
  }

  return fileIds[currentIndex + 1]
}

const nextUpWhenCourseIsFinished = ({
  currentPath,
  currentProgram,
  currentCourseId,
}: {
  currentPath?: PathEntity
  currentProgram?: ProgramEntity
  currentCourseId: CourseId
}): NextUp => {
  // Find the next uncompleted course in the path
  const nextCourseInPath = (() => {
    if (currentPath === undefined) return
    const course = currentPath.courses.find(
      course => course.status.passedTimestamp === undefined && course.id !== currentCourseId
    )

    return course !== undefined ? { course, pathId: currentPath.id } : undefined
  })()

  const nextAvailableStepInProgram = currentProgram?.steps.find(
    step =>
      step.id !== currentCourseId &&
      step.passedAt === undefined &&
      step.availableAt !== undefined &&
      DateTime.fromISO(step.availableAt).diffNow().toMillis() <= 0
  )

  if (currentProgram !== undefined) {
    // We're in a program
    if (nextCourseInPath !== undefined) {
      // We're also in a path and the path isn't completed
      return {
        type: 'next-course-in-program',
        programId: currentProgram.id,
        pathId: nextCourseInPath.pathId,
        courseTitle: nextCourseInPath.course.title,
        course: {
          id: nextCourseInPath.course.id,
          type: nextCourseInPath.course.type,
          description: nextCourseInPath.course.description,
          duration: nextCourseInPath.course.readingTimes.total,
          image: nextCourseInPath.course.image,
          nextSessionId: undefined,
          completedSessionId: undefined,
          courseGroupId: undefined,
        },
        programTitle: currentProgram.name,
      }
    }

    if (nextAvailableStepInProgram === undefined) {
      return {
        type: 'program-completed',
        programId: currentProgram.id,
        programTitle: currentProgram.name,
      }
    }

    if (nextAvailableStepInProgram.type === 'course') {
      // return course
      return {
        type: 'next-course-in-program',
        programId: currentProgram.id,
        pathId: undefined,
        courseTitle: nextAvailableStepInProgram.title,
        course: {
          id: nextAvailableStepInProgram.id as CourseId,
          type: nextAvailableStepInProgram.courseKind,
          nextSessionId: undefined,
          completedSessionId: undefined,
          courseGroupId: undefined,
        },
        programTitle: currentProgram.name,
      }
    } else {
      // path
      const nextCourseInStep = nextAvailableStepInProgram.courses.find(
        course => course.passedAt === undefined
      )

      // This check shouldn't fail unless it's an empty path (which should be filtered out)
      if (nextCourseInStep !== undefined) {
        return {
          type: 'next-course-in-program',
          programId: currentProgram.id,
          pathId: nextAvailableStepInProgram.id as PathId, // we know it's a path
          courseTitle: nextCourseInStep.title,
          course: {
            id: nextCourseInStep.id as CourseId,
            type: nextCourseInStep.courseKind,
            nextSessionId: undefined,
            completedSessionId: undefined,
            courseGroupId: undefined,
          },
          programTitle: currentProgram.name,
        }
      }
    }
  }

  if (currentPath && nextCourseInPath) {
    return {
      type: 'next-course-in-path',
      programId: currentProgram?.id,
      pathId: nextCourseInPath.pathId,
      courseTitle: nextCourseInPath.course.title,
      course: toCourseForHref(nextCourseInPath.course),
      pathTitle: currentPath.path.title,
    }
  }

  return { type: 'course-completed' }
}

const nextUpWhenChangingFolder = ({
  openReviews,
  openPlacementTests,
  currentFolder,
  nextFolder,
}: {
  currentFolder: Folder
  nextFolder: Folder
  openReviews: FolderId[]
  openPlacementTests: FolderId[]
}): NextUp | undefined => {
  const currentFolderReview = openReviews.includes(currentFolder.id)
  const nextFolderPT = openPlacementTests.includes(nextFolder.id)

  if (currentFolderReview) {
    return { type: 'review', folderId: currentFolder.id }
  }

  if (nextFolderPT) {
    return {
      type: 'placement-test',
      folderId: nextFolder.id,
      firstFileId: nextFolder.nodeIds.find(guardWith(FileId)),
    }
  }
}

const getLinearNextUp = ({
  currentFileId,
  fileIds,
  isCourseCompleted,
  currentPath,
  currentProgram,
  folders,
  currentFolder,
  openReviews,
  openPlacementTests,
  files,
  currentCourseId,
}: {
  currentFileId: FileId
  fileIds: FileId[]
  isCourseCompleted: boolean
  currentPath?: PathEntity
  currentProgram?: ProgramEntity
  folders: Folder[]
  files: File[]
  currentFolder: Folder
  openReviews: FolderId[]
  openPlacementTests: FolderId[]
  currentCourseId: CourseId
}): NextUp => {
  const nextFileId = getNextCardInOrder(currentFileId, fileIds)

  if (nextFileId !== undefined) {
    const nextFolder = folders.find(folder => folder.nodeIds.includes(nextFileId))

    const nextFileIsInNewFolder = nextFolder !== undefined && nextFolder.id !== currentFolder.id
    if (nextFileIsInNewFolder) {
      const nextUp = nextUpWhenChangingFolder({
        openReviews,
        openPlacementTests,
        currentFolder,
        nextFolder,
      })
      if (nextUp) return nextUp
    }

    const nextFile = files.find(file => file.id === nextFileId)
    return nextFile !== undefined ? { type: 'file', fileId: nextFile.id } : { type: 'none' }
  }

  if (isCourseCompleted) return nextUpWhenCourseIsFinished({ currentPath, currentProgram, currentCourseId })

  return { type: 'none' }
}
export const selectLinearNextUp = createSelector(
  [
    selectOpenPlacementTests,
    selectOpenReviews,
    selectCourseProgressState,
    selectFlexibleContent,
    selectCourseIsCompleted,
    selectFileIds,
    selectCurrentPath,
    selectCurrentProgram,
    selectCurrentCourseId,
  ],
  (
    openPlacementTests,
    openReviews,
    courseProgress,
    flexibleContent,
    isCourseCompleted,
    fileIds,
    currentPath,
    currentProgram,
    currentCourseId
  ) => {
    const folders = Object.values(flexibleContent?.nodeMap ?? {}).filter(isFolder)
    const files = Object.values(flexibleContent?.nodeMap ?? {}).filter(isFile)

    return _.memoize((currentFileId: FileId | undefined): NextUp => {
      const currentFolder =
        currentFileId !== undefined
          ? folders.find(folder => folder.nodeIds.includes(currentFileId))
          : undefined

      if (
        currentFileId === undefined ||
        courseProgress === undefined ||
        currentFolder === undefined ||
        currentCourseId === undefined
      ) {
        return { type: 'none' }
      }

      return getLinearNextUp({
        currentFileId,
        currentFolder,
        fileIds,
        files,
        folders,
        isCourseCompleted,
        openPlacementTests,
        openReviews,
        currentPath,
        currentProgram,
        currentCourseId,
      })
    })
  }
)

const selectCardStatus = createSelector(
  [selectCourseProgressState, (state: RootState, courseId: NanoId12, fileId: FileId) => fileId],
  (courseProgress, fileId) => {
    if (courseProgress === undefined) {
      return
    }

    return courseProgress.cardStatuses[fileId]
  }
)

export const selectCardStatuses = createSelector(
  selectCourseProgressState,
  (courseProgress): FlexibleContentStatus['cardStatuses'] | undefined => {
    if (courseProgress === undefined) {
      return
    }

    return courseProgress.cardStatuses
  }
)

export const selectCardIsCompleted = createSelector(selectCardStatus, cardProgress => {
  return cardProgress?.completed !== undefined
})

export const selectCardIsCompletedOrHasSubmission = createSelector(selectCardStatus, cardProgress => {
  const cardHasHomeworkSubmission = (cardProgress?.moreStatus ?? []).length > 0
  const cardIsCompleted = cardProgress?.completed !== undefined
  return cardIsCompleted || cardHasHomeworkSubmission
})

export const selectCardExercises = createSelector(
  selectCardStatus,
  (status): Record<string, ExerciseStatus> | undefined =>
    status === undefined ? undefined : _.keyBy(status.exerciseStatuses, 'id')
)

export const selectProgressDataAvailable = createSelector(
  selectCourseProgressState,
  courseProgress => !!courseProgress
)

export const selectNextUpFileId = createSelector(selectCourseProgressState, courseProgress =>
  _.memoize((currentFileId?: FileId, allFileIds?: FileId[]): FileId | undefined => {
    if (currentFileId === undefined || allFileIds === undefined) return undefined
    const currentIndex = allFileIds.findIndex(file => file === currentFileId)
    if (currentIndex === -1) return undefined

    if (courseProgress === undefined) return undefined

    for (let i = 0; i < allFileIds.length; i++) {
      const nextIndex: number = (i + currentIndex) % allFileIds.length
      const fileId = allFileIds[nextIndex]
      if (fileId === undefined) return undefined

      if (courseProgress.cardStatuses[fileId]?.completed === undefined) return fileId
    }
  })
)

export const selectLastCompletedFileId = createSelector(selectCourseProgressState, courseProgress =>
  _.memoize((allFileIds?: FileId[]): FileId | undefined => {
    if (allFileIds === undefined) return undefined
    if (courseProgress === undefined) return undefined

    const completedFileIds = Object.entries(courseProgress.cardStatuses)
      .filter(([, status]) => status?.completed !== undefined)
      .map(([fileId]) => fileId as FileId)

    if (completedFileIds.length === 0) return allFileIds[0]

    const fileIdIndexMap = allFileIds.reduce<Record<FileId, number>>((acc, fileId, index) => {
      acc[fileId] = index
      return acc
    }, {})

    return _.maxBy(completedFileIds, fileId => fileIdIndexMap[fileId])
  })
)

export const selectReview = createSelector(selectModuleStatuses, moduleStatuses =>
  _.memoize((folderId: FolderId | undefined): Review | undefined => {
    if (moduleStatuses === undefined) return undefined
    if (folderId === undefined) return undefined

    return moduleStatuses[folderId]?.review
  })
)

export const selectIsCourseCompleted = createSelector(selectCourseProgressState, courseProgress => {
  if (courseProgress === undefined) {
    return false
  }

  return courseProgress.courseStatus.completed !== undefined
})

export const selectPlacementTestProgressState = createSelector(selectModuleStatuses, moduleStatuses =>
  _.memoize((folderId: FolderId | undefined): PlacementTest | undefined => {
    if (moduleStatuses === undefined || folderId === undefined) return undefined

    return moduleStatuses[folderId]?.placementTest
  })
)
