import { Atom, atom } from 'jotai'
import { selectAtom } from 'jotai/utils'
import { TableAPI } from 'sierra-client/lib/tabular/api'
import { Cell } from 'sierra-client/lib/tabular/datatype/internal/cell'
import { Header } from 'sierra-client/lib/tabular/datatype/internal/header'
import * as Row from 'sierra-client/lib/tabular/datatype/internal/row'
import { Selection } from 'sierra-client/lib/tabular/datatype/selection'
import { RowOfTableData, TableData } from 'sierra-client/lib/tabular/datatype/tabledata'
import { columnToCell, TableRowsData } from 'sierra-client/lib/tabular/to-table-row-data'
import { ColumnRef, Position } from 'sierra-client/lib/tabular/types'
import { isSelected } from 'sierra-client/lib/tabular/utils/atom-helpers'

export type VirtualColumn<TD extends TableData = TableData> = {
  ref: ColumnRef
  header: (api: TableAPI<TD>) => Header
  cell: (_: { pos: Position; api: TableAPI<TD>; td: TD; row: RowOfTableData<TD> }) => Cell<TD>
}

export type VirtualColumns<TD extends TableData = TableData> = {
  left: VirtualColumn<TD>[]
  right: VirtualColumn<TD>[]
}

const manifestVirtualColumnCell = <TD extends TableData = TableData>(
  pos: Position,
  api: TableAPI<TD>,
  td: TD,
  virtualColumn: VirtualColumn<TD>,
  row: RowOfTableData<TD>
): Cell<TD> => virtualColumn.cell({ pos, api, td, row })

export const toTableRowDataWithVirtualColumns = <TD extends TableData>({
  tableData,
  hiddenAtom,
  selectionAtom,
  expandedAtom,
  virtualColumns,
  api,
  isNested = false,
}: {
  tableData: TD
  hiddenAtom: Atom<Set<ColumnRef>>
  selectionAtom: Atom<Selection>
  expandedAtom: Atom<Set<string>>
  virtualColumns: VirtualColumns<TD>
  api: TableAPI<TD>
  isNested?: boolean
}): TableRowsData<TD> => {
  return tableData.rows.map(row => {
    const toLeftVirtualColumn = (v: VirtualColumn<TD>): Cell<TD> => {
      // Make sure to add a left indent if the item is nested
      if (isNested) {
        const leftIndentCell: Cell = {
          type: 'empty',
          hideDecorator: true,
          hints: ['nested-row-left'],
          pos: { row: 'virtual', column: 'virtual' },
          selected: atom(false),
          enabled: atom(true),
        }
        return leftIndentCell
      } else {
        return manifestVirtualColumnCell({ column: v.ref, row: row.id }, api, tableData, v, row)
      }
    }
    const toRightVirtualColumn = (v: VirtualColumn<TD>): Cell<TD> => {
      return manifestVirtualColumnCell({ column: v.ref, row: row.id }, api, tableData, v, row)
    }
    const { id: rowId, data } = row
    const selected = selectAtom(selectionAtom, sel => isSelected(rowId, sel))

    const nested = tableData.nested[rowId]

    const cells = tableData.columns.map(col => {
      const enabled = selectAtom(hiddenAtom, hidden => !hidden.has(col.ref))

      const colData = data[col.ref]?.data

      // If we dont have that specific ref in data with data. Create an empty cell
      if (colData === undefined) {
        return {
          type: 'empty',
          pos: { column: col.ref, row: rowId },
          hints: col.hints,
          enabled: enabled,
          selected: selected,
        } satisfies Cell
      }

      return {
        type: columnToCell[col.type],
        // This type cast should be safe and match the type property because we have this
        // strictly checked in the input of the TableData.
        data: colData as any,
        pos: { column: col.ref, row: rowId },
        hints: col.hints,
        enabled: enabled,
        selected: selected,
      } as Cell // need to do 'as' here to correctly satisfy the Cell type
    })

    if (nested) {
      const expanded = selectAtom(expandedAtom, expanded => expanded.has(rowId))
      const children = toTableRowDataWithVirtualColumns({
        tableData: nested as TD,
        hiddenAtom,
        selectionAtom,
        expandedAtom,
        virtualColumns,
        api,
        isNested: true,
      })
      const parents = cells
      return Row.createRow({
        type: 'Row.Nested',
        ref: rowId,
        expanded,
        parent: [
          // Make sure to wrap the cells in the correct virtual columns
          ...virtualColumns.left.map(toLeftVirtualColumn),
          ...parents,
          ...virtualColumns.right.map(toRightVirtualColumn),
        ],
        children: children,
      })
    } else {
      return Row.createRow({
        type: 'Row.Flat',
        ref: rowId,
        cells: [
          // Make sure to wrap the cells in the correct virtual columns
          ...virtualColumns.left.map(toLeftVirtualColumn),
          ...cells,
          ...virtualColumns.right.map(toRightVirtualColumn),
        ],
      })
    }
  })
}
