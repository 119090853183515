import { atom } from 'jotai'

type SpeakerNotesSidebarWidth = 'none' | 'small' | 'medium' | 'large'
export const speakerNotesSidebarWidthAtom = atom<SpeakerNotesSidebarWidth>('none')
export const speakerNotesExtraSidebarWidth: Record<SpeakerNotesSidebarWidth, number> = {
  none: 0,
  small: 50,
  medium: 100,
  large: 150,
}
