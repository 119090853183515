import React, { useCallback, useRef, useState } from 'react'
import {
  useDeleteFolderMutation,
  useUpdateTeamspaceFolderNameMutation,
} from 'sierra-client/api/hooks/use-teamspace'
import { ContentTableRow } from 'sierra-client/components/common/content-table-row'
import { useContentDrag } from 'sierra-client/components/common/dnd/use-content-table-drag'
import { IconMenu } from 'sierra-client/components/common/icon-menu'
import { RouterLink } from 'sierra-client/components/common/link'
import { ActionModal } from 'sierra-client/components/common/modals/action-modal'
import { useDateTimeFormatter } from 'sierra-client/core/format'
import {
  TeamspaceFolderModal,
  isTeamspaceRoleAbove,
  useTeamspaceContentDrop,
} from 'sierra-client/features/teamspace'
import { folderDeletedLogger } from 'sierra-client/features/teamspace/logger'
import { useTranslation } from 'sierra-client/hooks/use-translation'
import { getGlobalRouter } from 'sierra-client/router'
import { useIsSmallDesktop } from 'sierra-client/state/browser/selectors'
import { useDispatch } from 'sierra-client/state/hooks'
import {
  LastUpdateLabel,
  MetadataWrapper,
  OpenCtaContainer,
  RowContainer,
} from 'sierra-client/views/workspace/components/content-table-row-components'
import { EditableContent, NonEditorTeamspaceCourse } from 'sierra-domain/api/editable-content'
import { FolderRow, SelfEnrollAction, Teamspace } from 'sierra-domain/api/teamspace'
import { PinnableContentType } from 'sierra-domain/api/user'
import { assertNever, isDefined } from 'sierra-domain/utils'
import { Icon, MenuItem } from 'sierra-ui/components'
import { Button, Text, View } from 'sierra-ui/primitives'
import { palette } from 'sierra-ui/theming'
import { v2_breakpoint } from 'sierra-ui/theming/breakpoints'
import styled from 'styled-components'

export const pinnableContentType = (
  createContentKind: EditableContent['type'] | NonEditorTeamspaceCourse['type']
): PinnableContentType => {
  switch (createContentKind) {
    case 'native:self-paced':
    case 'native:live':
    case 'native:event-group':
    case 'scorm':
    case 'link':
    case 'linkedin':
    case 'native:course-group':
    case 'scorm:course-group':
      return 'course'
    case 'path':
      return 'path'
    default:
      assertNever(createContentKind)
  }
}

function useSessionDateTimeFormatter(): (
  info: Extract<SelfEnrollAction, { type: 'single-session' }>
) => string {
  const dateFormatter = useDateTimeFormatter({ month: 'short', day: 'numeric' })
  const dateTimeFormatter = useDateTimeFormatter({
    month: 'short',
    day: 'numeric',
    hour: 'numeric',
    minute: '2-digit',
  })

  return useCallback(
    ({ startTime, endTime, allDay }) => {
      if (allDay) {
        return dateFormatter.formatRange(startTime, endTime)
      } else {
        return dateTimeFormatter.formatRange(startTime, endTime)
      }
    },
    [dateFormatter, dateTimeFormatter]
  )
}

const SingleSessionSelfEnrollActionMetaData: React.FC<{
  selfEnrollAction: Extract<SelfEnrollAction, { type: 'single-session' }>
}> = ({ selfEnrollAction }) => {
  const dateTimeFormatter = useSessionDateTimeFormatter()
  const selfEnrollActionText = dateTimeFormatter(selfEnrollAction)

  return (
    <Text bold color='foreground/muted'>
      {selfEnrollActionText}
    </Text>
  )
}

export const SelfEnrollActionMetaData: React.FC<{
  selfEnrollAction: SelfEnrollAction
}> = ({ selfEnrollAction }) => {
  const { t } = useTranslation()

  if (selfEnrollAction.type === 'single-session') {
    return <SingleSessionSelfEnrollActionMetaData selfEnrollAction={selfEnrollAction} />
  }

  const selfEnrollActionText = (() => {
    switch (selfEnrollAction.type) {
      case 'multiple-sessions':
        return t('teamspace.has-self-enroll-action')
      case 'no-sessions':
        return t('dictionary.live-session.not-scheduled')
      default:
        assertNever(selfEnrollAction)
    }
  })()

  return (
    <Text bold color='foreground/muted'>
      {selfEnrollActionText}
    </Text>
  )
}

export const getCurrentFolderDisplayName = (
  teamspace: Teamspace,
  currentFolderId: string
): string | undefined => {
  const rootFolderId = teamspace.content.find(
    (it): it is FolderRow => it.type === 'folder' && it.parentFolderId === undefined
  )?.id
  if (rootFolderId === currentFolderId) return undefined

  const folder = teamspace.content.find(it => it.type === 'folder' && it.id === currentFolderId)
  const subFolderIndicator = folder?.parentFolderId !== rootFolderId ? '.. / ' : ''

  return folder?.type === 'folder' ? subFolderIndicator + folder.displayName : undefined
}

const FolderThumbnail = styled(View).attrs({ justifyContent: 'center' })`
  width: 56px;
  height: 40px;
  min-width: 56px;
  min-height: 40px;
  border-radius: 8px;
  background-color: ${palette.grey[5]};
  margin-right: 0.5rem;

  @media (min-width: ${v2_breakpoint.desktop_small}) {
    width: 70px;
    height: 50px;
    min-width: 70px;
    min-height: 50px;
    border-radius: 12px;
  }
`

export const FolderTableRow: React.FC<{
  folder: FolderRow
  teamspace: Teamspace
  showCurrentFolder: boolean
}> = ({ folder, teamspace, showCurrentFolder }) => {
  const { t } = useTranslation()
  const dispatch = useDispatch()

  const isDesktop = !useIsSmallDesktop()

  const nodeRef = useRef<HTMLDivElement | null>(null)

  const [assetContext] = useState({ type: 'unknown' as const })
  const { drag, isDragging, canDrag } = useContentDrag(folder, assetContext)
  const { isOver, canDrop, drop, confirmModal } = useTeamspaceContentDrop(teamspace, folder)

  drop(drag(nodeRef))

  const folderUrl = `/t/${teamspace.id}/${folder.id}`

  const allowEdit =
    isDefined(teamspace) && isDefined(teamspace.effectiveRole)
      ? isTeamspaceRoleAbove(teamspace.effectiveRole, 'commenter')
      : false

  const [renameModalOpen, setRenameModalOpen] = useState(false)
  const [deleteModalOpen, setDeleteModalOpen] = useState(false)

  let menuItems: MenuItem[] = [
    {
      id: 'view',
      type: 'label',
      label: t('dictionary.view'),
      icon: 'view',
      onClick: () => {
        void getGlobalRouter().navigate({ to: folderUrl })
      },
    },
  ]

  if (allowEdit) {
    menuItems = [
      {
        id: 'rename',
        type: 'label',
        label: t('admin.rename'),
        icon: 'edit',
        onClick: () => {
          setRenameModalOpen(true)
        },
      },
      ...menuItems,
      {
        id: 'delete',
        type: 'label',
        color: 'destructive/background',
        label: t('teamspace.folder.remove-folder'),
        icon: 'trash-can',
        onClick: () => {
          setDeleteModalOpen(true)
        },
      },
    ]
  }

  const renameFolderMutation = useUpdateTeamspaceFolderNameMutation()
  const deleteFolderMutation = useDeleteFolderMutation()
  const currentFolderDisplayName =
    isDefined(teamspace) && isDefined(folder.parentFolderId)
      ? getCurrentFolderDisplayName(teamspace, folder.parentFolderId)
      : undefined

  const currentFolderTitle =
    showCurrentFolder && isDefined(currentFolderDisplayName) ? `${currentFolderDisplayName} / ` : undefined

  const renameFolder = (displayName: string): void => {
    if (displayName.trim() === '') return

    renameFolderMutation.mutate(
      {
        teamspaceId: teamspace.id,
        folderId: folder.id,
        displayName: displayName.trim(),
      },
      {
        onSuccess: () => {
          setRenameModalOpen(false)
        },
      }
    )
  }

  const deleteFolder = (): void => {
    deleteFolderMutation.mutate(
      {
        teamspaceId: teamspace.id,
        folderId: folder.id,
      },
      {
        onSuccess: () => {
          setDeleteModalOpen(false)

          void dispatch(folderDeletedLogger({ folderId: folder.id }))
        },
      }
    )
  }

  return (
    <>
      <RowContainer
        ref={nodeRef}
        role='listitem'
        isDroppable={true}
        isOver={isOver && canDrop}
        isDragging={isDragging}
      >
        <ContentTableRow
          href={folderUrl}
          canDrag={canDrag}
          isOver={isOver}
          left={
            <View gap='2' grow direction='column'>
              <View wrap={isDesktop ? 'nowrap' : 'wrap'} gap='6'>
                {isDefined(currentFolderTitle) && (
                  <Text bold color='foreground/muted'>
                    {currentFolderTitle}
                  </Text>
                )}
                <Text size='small' bold>
                  {folder.displayName}
                </Text>
              </View>
              <MetadataWrapper wrap={isDesktop ? 'nowrap' : 'wrap'} gap='6' grow>
                <LastUpdateLabel lastEditorId={undefined} updatedAt={folder.updatedAt} />
              </MetadataWrapper>
            </View>
          }
          thumbnail={
            <FolderThumbnail>
              <Icon iconId='folder' />
            </FolderThumbnail>
          }
          actions={
            <>
              <OpenCtaContainer showContainer={false}>
                <RouterLink href={folderUrl}>
                  <Button variant='secondary'>{t('dictionary.open')}</Button>
                </RouterLink>
              </OpenCtaContainer>

              <IconMenu
                iconId='overflow-menu--vertical'
                closeOnPick
                items={menuItems}
                color='foreground/muted'
              />
            </>
          }
        />
      </RowContainer>
      <TeamspaceFolderModal
        modalOpen={renameModalOpen}
        onConfirm={renameFolder}
        onClose={() => {
          setRenameModalOpen(false)
        }}
        defaultFolderName={folder.displayName}
        confirmLabel={t('dictionary.save')}
      />
      <ActionModal
        open={deleteModalOpen}
        onClose={() => setDeleteModalOpen(false)}
        primaryAction={deleteFolder}
        title={t('teamspace.folder.remove-folder')}
        primaryActionLabel={t('dictionary.delete')}
        deleteAction
      >
        {t('teamspace.folder.delete-body-text')}
      </ActionModal>
      <ActionModal
        open={confirmModal.isOpen}
        title={confirmModal.title}
        onClose={confirmModal.close}
        primaryAction={confirmModal.primaryAction}
        primaryActionLabel={t('dictionary.continue')}
      >
        {confirmModal.body}
      </ActionModal>
    </>
  )
}
