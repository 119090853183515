import { createChannel, createEvent } from 'sierra-client/realtime-data/channels/types'
import { ChatMessageId, ChatMessageReactionId, UserId } from 'sierra-domain/api/uuid'
import { CommentContentReference, TiptapJsonData } from 'sierra-domain/chat'
import { DateFromISOString } from 'sierra-domain/zod-extensions'
import { z } from 'zod'

const ResolvedData = z.object({
  resolvedBy: UserId,
  resolvedAt: DateFromISOString,
})

const CommentData = z.object({
  contentRef: CommentContentReference,
  detachedFromContent: z.boolean(),
  resolved: ResolvedData.optional(),
})

const ChatMessageUpdated = z
  .object({
    messageId: ChatMessageId,
    createdAt: z.string(),
    editedAt: z.string().optional(),
    responseToMessageId: ChatMessageId.optional(),
    userId: UserId,
    messageData: TiptapJsonData,
    commentData: CommentData.optional(),
  })
  .refine(({ responseToMessageId, commentData }) => {
    if (responseToMessageId && commentData) {
      return false
    }

    return true
  })

export type ChatMessageUpdated = z.infer<typeof ChatMessageUpdated>

const ReactionAdded = z.object({
  reactionId: ChatMessageReactionId,
  messageId: ChatMessageId,
  createdAt: z.string(),
  userId: UserId,
  reaction: z.string(),
})
export type ReactionAdded = z.infer<typeof ReactionAdded>

const ReactionRemoved = z.object({
  messageId: ChatMessageId,
  userId: UserId,
  reactionId: ChatMessageReactionId,
})
export type ReactionRemoved = z.infer<typeof ReactionRemoved>

export const liveSessionChatDataChannel = createChannel('chatv2:live-session', [
  createEvent('chat-message-updated', ChatMessageUpdated),
  createEvent('reaction-added', ReactionAdded),
  createEvent('reaction-removed', ReactionRemoved),
])

export const createChatDataChannel = createChannel('chatv2:create', [
  createEvent('chat-message-updated', ChatMessageUpdated),
  createEvent('reaction-added', ReactionAdded),
  createEvent('reaction-removed', ReactionRemoved),
])
