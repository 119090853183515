import { useCallback, useEffect } from 'react'
import { useGoToNode } from 'sierra-client/components/liveV2/hooks/use-go-to-node'
import { useSelectCurrentCardBackgroundColor } from 'sierra-client/components/liveV2/hooks/use-select-current-card'
import { NowButtonCSS } from 'sierra-client/features/sana-now/header/buttons'
import { useGetNavigateCards } from 'sierra-client/features/sana-now/header/use-next-or-prev-card'
import { useTranslation } from 'sierra-client/hooks/use-translation'
import { useSelector } from 'sierra-client/state/hooks'
import { selectKeyboardNavigationEnabled } from 'sierra-client/state/live/selectors'
import { IconButton, View } from 'sierra-ui/primitives'
import styled from 'styled-components'

const HeaderButtonGroupWrapper = styled(View)``

export const LightHeaderGroupIconButton = styled(IconButton)`
  ${NowButtonCSS}
  :disabled {
    opacity: 0.4;
  }
`

export const LeftFileNavigationButtons = (): JSX.Element | null => {
  const { t } = useTranslation()
  const { prevCardId } = useGetNavigateCards()
  const keyboardNavigationEnabled = useSelector(selectKeyboardNavigationEnabled)
  const goToNode = useGoToNode()

  const prevCard = useCallback((): void => {
    if (prevCardId !== undefined) {
      goToNode(prevCardId)
    }
  }, [goToNode, prevCardId])

  useEffect(() => {
    if (!keyboardNavigationEnabled) return

    const handleKey = (evt: KeyboardEvent): void => {
      // Don't act if an input field is the element
      const target = evt.target as HTMLElement | undefined

      if (target && 'tagName' in target) {
        const tag = target.tagName.toLowerCase()
        if (tag === 'input' || tag === 'textarea' || tag === 'select') {
          return
        }
      }

      const isTiptapInput =
        target?.classList.contains('ProseMirror') === true || target?.classList.contains('tiptap') === true

      if (isTiptapInput) {
        return
      }

      switch (evt.key) {
        case 'ArrowLeft':
        case 'ArrowUp':
          prevCard()
          break
      }
    }

    window.addEventListener('keydown', handleKey)

    return () => {
      window.removeEventListener('keydown', handleKey)
    }
  }, [prevCard, keyboardNavigationEnabled])

  const backgroundColor = useSelectCurrentCardBackgroundColor()

  return (
    <HeaderButtonGroupWrapper gap='none'>
      <LightHeaderGroupIconButton
        $backgroundColor={backgroundColor}
        iconId={'previous--filled'}
        onClick={prevCard}
        tooltip={t('live.previous-card')}
        disabled={prevCardId === undefined}
      />
    </HeaderButtonGroupWrapper>
  )
}
