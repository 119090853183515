import { useMemo } from 'react'
import {
  getFormattedValue,
  mapTwoDimensionalData,
} from 'sierra-client/features/insights/display-widgets/data-utils'
import { useNormalizedTableResult } from 'sierra-client/features/insights/display-widgets/normalize-data/normalize-data'
import { useTranslation } from 'sierra-client/hooks/use-translation'
import { LineChartWidget, TableResult } from 'sierra-domain/api/insights'
import { LineChart, LineChartProps } from 'sierra-ui/missions/delphi/charts'

type LineChartViewerProps = {
  tableResult: TableResult
  widget: LineChartWidget
}

export const LineChartViewer: React.FC<LineChartViewerProps> = ({ tableResult, widget }) => {
  const { t } = useTranslation()

  const normalizedTableResult = useNormalizedTableResult(tableResult, widget)
  const data = useMemo(() => {
    return mapTwoDimensionalData(normalizedTableResult, widget, t)
  }, [normalizedTableResult, widget, t])

  const slicedRows = data.rows

  const lineChartKeys = (
    widget.dimensions[1] !== undefined
      ? slicedRows.reduce<LineChartProps<string>['keys']>((keys, row) => {
          if (row.breakdownId === undefined) return keys
          if (keys.includes(row.breakdownId)) return keys
          keys.push(row.breakdownId)
          return keys
        }, [])
      : ['value']
  ) satisfies LineChartProps<string>['keys']

  const lineChartData = (
    widget.dimensions[1] !== undefined
      ? slicedRows.reduce<LineChartProps<string>['data']>((rows, row) => {
          if (row.breakdownId === undefined) return rows

          const existingRow = rows.find(existingRow => existingRow.id === row.id)

          if (existingRow !== undefined) {
            existingRow.values[row.breakdownId] = getFormattedValue(row)
          } else {
            rows.push({ values: { [row.breakdownId]: getFormattedValue(row) }, ...row })
          }
          return rows
        }, [])
      : slicedRows.map(row => ({
          ...row,
          values: { value: getFormattedValue(row) },
        }))
  ) satisfies LineChartProps<string>['data']

  return <LineChart keys={lineChartKeys} data={lineChartData} unit={data.yUnit} />
}
