import { Text } from 'slate'

export const lineNumberCssClass = 'code-editor-line-number'
export const lineNumberPrefix = 'line-'

export function getLineNumber(leaf: Text): number | undefined {
  const [number] = Object.keys(leaf)
    .filter(it => it.startsWith(lineNumberPrefix))
    .map(it => parseInt(it.replace(lineNumberPrefix, '')))
  return number
}
