import React from 'react'
import { token } from 'sierra-ui/theming'
import styled from 'styled-components'

export const StyledResizeHandle = styled.div`
  opacity: 0;
  transition: opacity 150ms cubic-bezier(0.25, 0.1, 0.25, 1);
  &::after {
    /* Reset default resize handler styling */
    border-right: 2px solid rgba(0, 0, 0, 0) !important;
    border-bottom: 2px solid rgba(0, 0, 0, 0) !important;
  }

  path {
    fill: ${token('foreground/muted')};
  }
`

export const ResizeHandle = React.forwardRef<HTMLDivElement, { handleAxis: string }>((props, ref) => {
  const { handleAxis, ...restProps } = props

  return (
    <StyledResizeHandle
      ref={ref}
      className={`react-resizable-handle react-resizable-handle-${handleAxis}`} // These classnames break functionality if not present
      {...restProps}
    >
      <svg width='16' height='16' viewBox='0 0 16 16' fill='none' xmlns='http://www.w3.org/2000/svg'>
        <path d='M13.354 8.35378L8.35403 13.3538C8.30757 13.4002 8.25242 13.4371 8.19173 13.4622C8.13103 13.4874 8.06598 13.5003 8.00028 13.5003C7.93458 13.5003 7.86953 13.4874 7.80883 13.4622C7.74813 13.4371 7.69298 13.4002 7.64653 13.3538C7.60007 13.3073 7.56322 13.2522 7.53808 13.1915C7.51294 13.1308 7.5 13.0657 7.5 13C7.5 12.9343 7.51294 12.8693 7.53808 12.8086C7.56322 12.7479 7.60007 12.6927 7.64653 12.6463L12.6465 7.64628C12.693 7.59982 12.7481 7.56297 12.8088 7.53783C12.8695 7.51269 12.9346 7.49975 13.0003 7.49975C13.066 7.49975 13.131 7.51269 13.1917 7.53783C13.2524 7.56297 13.3076 7.59982 13.354 7.64628C13.4005 7.69273 13.4373 7.74788 13.4625 7.80858C13.4876 7.86928 13.5006 7.93433 13.5006 8.00003C13.5006 8.06572 13.4876 8.13078 13.4625 8.19148C13.4373 8.25217 13.4005 8.30732 13.354 8.35378ZM12.354 2.14628C12.3076 2.09979 12.2524 2.06291 12.1917 2.03775C12.131 2.01259 12.066 1.99963 12.0003 1.99963C11.9346 1.99963 11.8695 2.01259 11.8088 2.03775C11.7481 2.06291 11.693 2.09979 11.6465 2.14628L2.14653 11.6463C2.05271 11.7401 2 11.8673 2 12C2 12.1327 2.05271 12.26 2.14653 12.3538C2.24035 12.4476 2.3676 12.5003 2.50028 12.5003C2.63296 12.5003 2.76021 12.4476 2.85403 12.3538L12.354 2.85378C12.4005 2.80734 12.4374 2.7522 12.4626 2.6915C12.4877 2.6308 12.5007 2.56573 12.5007 2.50003C12.5007 2.43432 12.4877 2.36926 12.4626 2.30856C12.4374 2.24786 12.4005 2.19271 12.354 2.14628Z' />
      </svg>
    </StyledResizeHandle>
  )
})
