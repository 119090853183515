import { keepPreviousData } from '@tanstack/react-query'
import { useAtom } from 'jotai'
import _ from 'lodash'
import { graphql } from 'sierra-client/api/graphql/gql'
import type { AvailableSkillsQuery, MySkillsQuery } from 'sierra-client/api/graphql/gql/graphql'
import { useGraphQuery } from 'sierra-client/api/hooks/use-graphql-query'
import { useCurrentUserId } from 'sierra-client/api/hooks/use-user'
import { useNotif } from 'sierra-client/components/common/notifications'
import { SelectedSkillsAtom } from 'sierra-client/features/skills/components/select-skills/atoms'
import { SelectSkillsForm } from 'sierra-client/features/skills/components/select-skills/select-skills-form'
import { SkillPill } from 'sierra-client/features/skills/components/select-skills/skill-pill'
import {
  SelectSkillSection,
  selectSkillSectionPaddingInline,
} from 'sierra-client/features/skills/components/select-skills/styles'
import type {
  SelectSkillEntity,
  SelectSkillsFormVariant,
} from 'sierra-client/features/skills/components/select-skills/types'
import {
  useSubscribeLearnerMutation,
  useUnsubscribeLearnerMutation,
} from 'sierra-client/features/skills/shared-gql-queries'
import { useDebouncedState } from 'sierra-client/hooks/use-debounced-state'
import { useTranslation } from 'sierra-client/hooks/use-translation'
import type { TranslationKey } from 'sierra-client/hooks/use-translation/types'
import { ToolbarContainer, ToolbarSearch } from 'sierra-client/lib/tabular/components/tabular-toolbar'
import { isDefined, isNotDefined } from 'sierra-domain/utils'
import { Icon, Modal, SkillIconId } from 'sierra-ui/components'
import { Heading, ScrollView, Skeleton, Text, View } from 'sierra-ui/primitives'
import { IconMenu } from 'sierra-ui/primitives/menu-dropdown'
import { useOnChanged } from 'sierra-ui/utils'
import { minWidth } from 'sierra-ui/utils/media-query-styles'
import styled from 'styled-components'
import { useTracking } from '../../tracking'

const getAvailableSkills = graphql(`
  query AvailableSkills($query: String) {
    skills(query: $query) {
      data {
        id
        name
        defaultBadgeIcon
        description
        uniqueSubscribersCount
        skillLevels {
          levelSetting {
            id
            index
          }
        }
        subscribers {
          userInfo {
            id
          }
          subscribedBy
        }
      }
    }
  }
`)

const getMySkills = graphql(`
  query MySkills {
    me {
      skills {
        id
        name
        description
        defaultBadgeIcon
        skillLevels {
          levelSetting {
            id
            index
          }
        }
        subscribers {
          userInfo {
            id
          }
          subscribedBy
        }
      }
    }
  }
`)

const SidebarContainer = styled(ScrollView)`
  gap: 32px;
  width: 100%;
  padding-block: 12px;
  padding-inline: ${selectSkillSectionPaddingInline}px 0;

  ${minWidth.tablet} {
    height: 100%;
    max-width: 380px;
    padding-block: 40px 32px;
    padding-inline: 40px 18px;
  }
`

const List = styled.ul`
  display: flex;
  flex-direction: column;
  gap: 12px;
  list-style: none;
`

const ListItem = styled.li`
  display: flex;
  justify-content: space-between;
  align-items: center;
`

const EmptySection: React.FC<{ translationKey: TranslationKey }> = ({ translationKey }) => {
  const { t } = useTranslation()

  return (
    <View paddingTop='10' paddingLeft='16'>
      <Text color='foreground/muted'>{t(translationKey)}</Text>
    </View>
  )
}

const Sidebar: React.FC<{ variant: SelectSkillsFormVariant }> = ({ variant }) => {
  const { t } = useTranslation()
  const [selectedSkills, setSelectedSkills] = useAtom(SelectedSkillsAtom)

  const assignedContent =
    selectedSkills.status === 'ready' ? selectedSkills.skills.filter(skill => skill.isAssignedToUser) : []
  const selfAssignedContent =
    selectedSkills.status === 'ready'
      ? selectedSkills.skills.filter(skill => skill.isAssignedToUser !== true)
      : []

  return (
    <SidebarContainer>
      <View direction='column' gap='8'>
        <Heading size='h4' bold color='foreground/primary'>
          {t('skills.modal.select-skills.title')}
        </Heading>
        <Text color='foreground/secondary' size='large'>
          {t('skills.modal.select-skills.subtitle')}
        </Text>
      </View>

      <View gap='24' direction='column'>
        <View direction='column' gap='8'>
          <Text color='foreground/secondary'>{t('skills.modal.select-skills.assigned-to-you')}</Text>
          {selectedSkills.status === 'idle' ? (
            <List>
              <ListItem>
                <Skeleton $height={40} $width='100%' $radius={12} />
              </ListItem>
              <ListItem>
                <Skeleton $height={40} $width='100%' $radius={12} />
              </ListItem>
            </List>
          ) : assignedContent.length === 0 ? (
            <EmptySection translationKey='skills.modal.select-skills.no-skills-assigned' />
          ) : (
            <List>
              {assignedContent.map(skill => (
                <ListItem key={skill.id}>
                  <SkillPill
                    skillId={skill.id}
                    defaultBadgeIcon={skill.defaultBadgeIcon}
                    title={skill.name}
                    variant={variant}
                  />
                  <View paddingRight='4'>
                    <Icon iconId='locked' color='foreground/primary' />
                  </View>
                </ListItem>
              ))}
            </List>
          )}
        </View>

        <View direction='column' gap='8'>
          <Text color='foreground/secondary'>{t('skills.modal.select-skills.selected')}</Text>
          {selectedSkills.status === 'idle' ? (
            <List>
              <ListItem>
                <Skeleton $height={40} $width='100%' $radius={12} />
              </ListItem>
              <ListItem>
                <Skeleton $height={40} $width='100%' $radius={12} />
              </ListItem>
            </List>
          ) : selfAssignedContent.length === 0 ? (
            <EmptySection translationKey='skills.modal.select-skills.no-skills-selected' />
          ) : (
            <List>
              {selfAssignedContent.map(skill => (
                <ListItem key={skill.id}>
                  <SkillPill
                    skillId={skill.id}
                    defaultBadgeIcon={skill.defaultBadgeIcon}
                    title={skill.name}
                    variant={variant}
                  />

                  <IconMenu
                    iconId='overflow-menu--vertical'
                    variant='transparent'
                    size='small'
                    onSelect={() => {
                      setSelectedSkills(prev => {
                        return prev.status === 'idle'
                          ? prev
                          : {
                              status: 'ready',
                              skills: _.reject(prev.skills, { id: skill.id }),
                            }
                      })
                    }}
                    menuItems={[
                      {
                        id: 'remove',
                        type: 'label',
                        label: t('dictionary.remove'),
                      },
                    ]}
                  />
                </ListItem>
              ))}
            </List>
          )}
        </View>
      </View>
    </SidebarContainer>
  )
}

const PreventModalScroll = styled(View)`
  flex-direction: column;

  ${minWidth.tablet} {
    flex-direction: row;
    overflow: hidden;
    width: 100%;
    height: 70vh;
    height: 70dvh;
    max-height: 900px;
  }
`

const MainContainer = styled.div`
  display: flex;
  align-items: flex-start;
  flex: 1;
  flex-direction: column;
  width: 100%;
  height: 100%;
  overflow: hidden;
  gap: 18px;
  padding-block: 12px;

  > * {
    width: 100%;
  }

  ${minWidth.tablet} {
    padding-block: 40px 32px;
  }
`

type SkillQueryData = AvailableSkillsQuery['skills']['data'][number] | MySkillsQuery['me']['skills'][number]

const convertSkillQueryDataToSelectSkillEntity =
  (userId: string | undefined) =>
  (skill: SkillQueryData): SelectSkillEntity => {
    const subscribed = skill.subscribers.find(subscriber => subscriber.userInfo.id === userId)

    return {
      id: skill.id,
      name: skill.name,
      defaultBadgeIcon: SkillIconId.catch('skill--graduation--cap').parse(skill.defaultBadgeIcon),
      description: skill.description,
      uniqueSubscribersCount: 'uniqueSubscribersCount' in skill ? skill.uniqueSubscribersCount : undefined,
      isAssignedToUser: isDefined(subscribed) ? subscribed.subscribedBy !== userId : false,
      skillLevels: skill.skillLevels,
    }
  }

type SelectSkillsModalProps = {
  variant: SelectSkillsFormVariant
  open: boolean
  onCancel: () => void
  onComplete: () => void
}

export const SelectSkillsModal: React.FC<SelectSkillsModalProps> = ({
  open,
  onComplete,
  onCancel,
  variant,
}) => {
  const { t } = useTranslation()
  const notification = useNotif()
  const tracking = useTracking()

  const userIdQuery = useCurrentUserId()

  const [searchQuery, setSearchQuery] = useDebouncedState('', { wait: 100 })
  useOnChanged((_, value) => {
    if (!value) {
      setSearchQuery('')
    }
  }, open)

  const availableSkillsQuery = useGraphQuery(
    {
      document: getAvailableSkills,
      queryOptions: {
        enabled: open && isDefined(userIdQuery.data),
        placeholderData: keepPreviousData,
      },
    },
    {
      query: searchQuery,
    }
  )

  const mySkillsQuery = useGraphQuery(
    {
      document: getMySkills,
      queryOptions: {
        enabled: open && isDefined(userIdQuery.data),
      },
    },
    {}
  )

  const [selectedSkills, setSelectedSkills] = useAtom(SelectedSkillsAtom)
  useOnChanged(
    (_, skills) => {
      if (isDefined(skills) && selectedSkills.status === 'idle') {
        setSelectedSkills({
          status: 'ready',
          skills: skills.map(convertSkillQueryDataToSelectSkillEntity(userIdQuery.data)),
        })
      }
    },
    mySkillsQuery.data?.me.skills
  )

  const { mutateAsync: subscribeMutation, isPending: isSubbing } = useSubscribeLearnerMutation({
    onError: () => {
      notification.push({ type: 'error' })
    },
  })
  const { mutateAsync: unsubscribeMutation, isPending: isUnsubbing } = useUnsubscribeLearnerMutation({
    onError: () => {
      notification.push({ type: 'error' })
    },
  })

  const handleSubmit = async (updatedSelection: SelectSkillEntity[]): Promise<void> => {
    const userId = userIdQuery.data

    if (isNotDefined(userId)) return

    const initialSelection = mySkillsQuery.data?.me.skills ?? []

    const unsubscribe = _.differenceBy(initialSelection, updatedSelection, 'id').map(skill => ({
      userId,
      skillId: skill.id,
    }))

    const subscribe = _.differenceBy(updatedSelection, initialSelection, 'id')
      .map(skill =>
        skill.skillLevels.map(skillLevel => ({
          alreadyAchieved: false,
          userId,
          skillId: skill.id,
          skillLevelSettingId: skillLevel.levelSetting.id,
        }))
      )
      .flat()

    const mutations = []
    if (unsubscribe.length > 0) {
      mutations.push(
        unsubscribeMutation({ subscriptions: unsubscribe }).then(() => {
          unsubscribe.forEach(({ skillId }) => {
            tracking.learner.selfUnsubscribe({ skillId })
          })
        })
      )
    }
    if (subscribe.length > 0) {
      mutations.push(
        subscribeMutation({ subscriptions: subscribe }).then(() => {
          subscribe.forEach(({ skillId }) => {
            tracking.learner.selfSubscribe({ skillId })
          })
        })
      )
    }

    await Promise.all(mutations)
    onComplete()
  }

  return (
    <Modal
      size={{ width: 1220 }}
      open={open}
      onClose={variant === 'learner-manage' ? onCancel : undefined}
      disableScrollbarGutter
    >
      <PreventModalScroll>
        <Sidebar variant={variant} />

        <MainContainer>
          <SelectSkillSection direction='column' gap='24'>
            <ToolbarContainer>
              <ToolbarSearch onQueryChange={setSearchQuery} />
            </ToolbarContainer>
          </SelectSkillSection>

          {availableSkillsQuery.isLoading ? (
            <SelectSkillSection direction='column' gap='16'>
              <Skeleton $height={90} $width='100%' $radius={12} />
              <Skeleton $height={90} $width='100%' $radius={12} />
              <Skeleton $height={90} $width='100%' $radius={12} />
              <Skeleton $height={90} $width='100%' $radius={12} />
            </SelectSkillSection>
          ) : isNotDefined(availableSkillsQuery.data) ? (
            <SelectSkillSection direction='column'>
              <Text color='foreground/muted'>{_.capitalize(t('skills.no-skills-yet.title'))}</Text>
            </SelectSkillSection>
          ) : (
            <SelectSkillsForm
              variant={variant}
              skills={availableSkillsQuery.data.skills.data.map(
                convertSkillQueryDataToSelectSkillEntity(userIdQuery.data)
              )}
              onCancel={onCancel}
              onSubmit={handleSubmit}
              loading={isSubbing || isUnsubbing}
            />
          )}
        </MainContainer>
      </PreventModalScroll>
    </Modal>
  )
}
