import { useEffect } from 'react'
import { chatLocalAwarenessStateMerged } from 'sierra-client/state/chat/actions'
import { useDispatch, useSelector } from 'sierra-client/state/hooks'
import { selectUserId } from 'sierra-client/state/user/user-selector'
import { ScopedChatId } from 'sierra-domain/collaboration/types'

export const SyncUserId = ({ chatId }: { chatId: ScopedChatId }): null => {
  const dispatch = useDispatch()
  const userId = useSelector(selectUserId)

  useEffect(() => {
    void dispatch(chatLocalAwarenessStateMerged({ chatId, partialLocalAwarenessState: { userId } }))
  }, [dispatch, chatId, userId])

  return null
}
