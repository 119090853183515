import _ from 'lodash'
import { DateTime } from 'luxon'
import React, { useCallback, useEffect, useState } from 'react'
import { InView } from 'react-intersection-observer'
import { ChipInputWithAutocomplete } from 'sierra-client/components/common/chip-input-with-autocomplete'
import { useNotif } from 'sierra-client/components/common/notifications'
import { RequiredAssignmnentSwitch } from 'sierra-client/components/common/required-assignment-switch'
import {
  useAssignUsersAdapter,
  useExistingUserAssignments,
  useUnassignContent,
} from 'sierra-client/components/sharing/hooks'
import {
  AutocompleteContainer,
  AutocompleteDropdownContainer,
  ExistingAssignedUsersContainer,
} from 'sierra-client/components/sharing/tabs/components/containers'
import {
  MoreResultsMenuItem,
  RemainingUsersMenuItem,
  ShareModalListItemSkeleton,
  SharingModalMenuItem,
  SharingModalMenuItemWithAssignSelfPacedControls,
  SharingModalUser,
  SharingModalUserOrLoadMore,
  isSharingModalUser,
} from 'sierra-client/components/sharing/tabs/components/sharing-modal-menu-item'
import { RenderTags } from 'sierra-client/components/sharing/tabs/utils/render-tags'
import { SharingModalContent } from 'sierra-client/components/sharing/types'
import { getFlag } from 'sierra-client/config/global-config'
import { useHasOrganizationPermission } from 'sierra-client/hooks/use-permissions'
import { usePost } from 'sierra-client/hooks/use-post'
import { useTranslation } from 'sierra-client/hooks/use-translation'
import { getGlobalRouter } from 'sierra-client/router'
import { useDueDate } from 'sierra-client/views/manage/components/due-date'
import { CourseId, NanoId12 } from 'sierra-domain/api/nano-id'
import { XRealtimeAdminCoursesCourseEnrollmentsCount } from 'sierra-domain/routes'
import { DatePickerPrimitive } from 'sierra-ui/components/date-picker/date-picker-primitive'
import { Button, IconButton, View } from 'sierra-ui/primitives'
import { DefaultDropdownTrigger } from 'sierra-ui/primitives/menu-dropdown'
import { token } from 'sierra-ui/theming'
import styled from 'styled-components'

const RequiredContainer = styled(View)`
  border-top: 1px solid ${token('border/default')};
  padding-top: 20px;
  max-width: 258px;
`

export const AssignCourseParticipants: React.FC<{
  content: SharingModalContent
  canEditAssignments: boolean
}> = ({ content, canEditAssignments }) => {
  const { t } = useTranslation()
  const notifications = useNotif()
  const { assignWithDueDate, setUsersDueDate } = useDueDate()
  const unassignContent = useUnassignContent()
  const { postWithUserErrorException } = usePost()

  const [dueDate, setDueDate] = useState<string>()
  const [numRemainingGroupAssignments, setNumRemainingGroupAssignments] = useState<number>()

  const [searchQuery, setSearchQuery] = useState('')

  const [selectedUsers, setSelectedUsers] = useState<SharingModalUser[]>([])
  const [isRequired, setIsRequired] = useState(content.isDefaultRequiredAssignmentEnabled)

  const [wantMoreExistingAssignments, setWantMoreExistingAssignments] = useState(false)
  const canSetContentDueDates = useHasOrganizationPermission('SET_CONTENT_DUE_DATES')

  const {
    assignableUsers,
    isLoading: isLoadingAssignableUsers,
    hasMore: hasMoreAssignableUsers,
  } = useAssignUsersAdapter({
    content,
    searchQuery,
    shouldLoadMore: false,
  })
  const assignableItems: SharingModalUserOrLoadMore[] = [...assignableUsers, { type: 'load-more' }]

  const {
    assignments,
    hasMore: hasMoreAssignments,
    reloadAssignments,
    isLoading: isLoadingAssignments,
  } = useExistingUserAssignments({
    courseId: content.id as NanoId12,
    shouldLoadMore: wantMoreExistingAssignments,
  })

  const onAssignClick = async (): Promise<void> => {
    if (selectedUsers.length === 0) return
    await assignWithDueDate(
      selectedUsers.map(user => ({
        assignee: {
          type: 'user',
          id: user.uuid,
          dueDate: dueDate,
          isRequired: isRequired,
        },
        content: {
          id: content.id,
          type: content.type,
        },
      }))
    )

    setSearchQuery('')
    setSelectedUsers([])
    setDueDate(undefined)
    setIsRequired(false)
    notifications.push({
      type: 'custom',
      level: 'info',
      icon: 'send--filled',
      body: 'Invite sent',
    })
  }

  const fetchEnrollmentCount = useCallback(
    async () =>
      postWithUserErrorException(XRealtimeAdminCoursesCourseEnrollmentsCount, {
        courseIds: [content.id],
      }),
    [content.id, postWithUserErrorException]
  )

  useEffect(() => {
    const hasLoadedAssignmentCount = numRemainingGroupAssignments !== undefined
    if (isLoadingAssignments === false && !hasLoadedAssignmentCount) {
      void fetchEnrollmentCount().then(({ courseToEnrollments }) => {
        const totalAssignmentCount = courseToEnrollments[content.id]
        if (totalAssignmentCount !== undefined) {
          setNumRemainingGroupAssignments(totalAssignmentCount - assignments.length)
        }
      })
    }
  }, [
    assignments.length,
    content.id,
    fetchEnrollmentCount,
    isLoadingAssignments,
    numRemainingGroupAssignments,
  ])

  return (
    <div>
      <View justifyContent='space-between'>
        <AutocompleteContainer>
          <ChipInputWithAutocomplete<SharingModalUserOrLoadMore, true, true, false>
            ListboxProps={{ style: { pointerEvents: 'auto' } }}
            inputPlaceholder={selectedUsers.length === 0 ? t('admin.add-users') : ''}
            inputValue={searchQuery}
            onInputChange={(_, filter) => setSearchQuery(filter)}
            value={selectedUsers}
            onChange={(_, users) => {
              setSelectedUsers(users.filter(isSharingModalUser))
            }}
            noOptionsText={
              isLoadingAssignableUsers ? t('dictionary.loading') : t('sharing-modal.no-users-found')
            }
            autoFocus
            multiple
            disabled={!canEditAssignments}
            disableClearable
            options={assignableItems}
            getOptionDisabled={user => user.type === 'load-more'}
            getOptionSelected={(user, val) =>
              user.type === 'user' && val.type === 'user' && user.uuid === val.uuid
            }
            getOptionLabel={user =>
              user.type === 'user'
                ? _.compact([user.firstName, user.lastName, user.email]).join(' ')
                : 'load-more'
            }
            renderOption={user => {
              if (user.type === 'load-more') {
                if (searchQuery === '' && !isLoadingAssignableUsers && hasMoreAssignableUsers) {
                  return <MoreResultsMenuItem key='load-more' />
                }
              } else {
                return (
                  <SharingModalMenuItem
                    key={user.uuid}
                    user={user}
                    selected={selectedUsers.some(u => u.uuid === user.uuid)}
                  />
                )
              }
            }}
            renderTags={users => (
              <RenderTags
                selectedUsers={users.filter(isSharingModalUser)}
                setSelectedUsers={setSelectedUsers}
                uuidKey='uuid'
              />
            )}
          />
          {canSetContentDueDates && canEditAssignments ? (
            <AutocompleteDropdownContainer>
              <DatePickerPrimitive
                disablePastDates
                value={dueDate !== undefined ? DateTime.fromFormat(dueDate, 'yyyy-MM-dd') : undefined}
                onChange={newDate => setDueDate(newDate?.toFormat('yyyy-MM-dd'))}
                renderExtraContent={() =>
                  getFlag('required-assignments') && (
                    <RequiredContainer direction='column' gap='4'>
                      <RequiredAssignmnentSwitch isRequired={isRequired} setIsRequired={setIsRequired} />
                    </RequiredContainer>
                  )
                }
                renderTrigger={() => (
                  <DefaultDropdownTrigger variant='ghost' grow>
                    {dueDate ?? t('due-date.due-date')}
                    {dueDate !== undefined && (
                      <IconButton
                        variant='transparent'
                        size='small'
                        onClick={() => setDueDate(undefined)}
                        iconId='close'
                      />
                    )}
                  </DefaultDropdownTrigger>
                )}
              />
            </AutocompleteDropdownContainer>
          ) : undefined}
        </AutocompleteContainer>

        <Button
          disabled={selectedUsers.length === 0}
          onClick={async () => {
            await onAssignClick()
            void reloadAssignments()
          }}
        >
          {t('dictionary.assign')}
        </Button>
      </View>

      <ExistingAssignedUsersContainer>
        {isLoadingAssignments === true && assignments.length === 0 && (
          <View direction='column' gap='none'>
            <ShareModalListItemSkeleton />
            <ShareModalListItemSkeleton />
          </View>
        )}

        {assignments
          .map(assignment => ({
            type: 'user' as const,
            uuid: assignment.userInfo.baseUserInfo.userId,
            firstName: assignment.userInfo.baseUserInfo.firstName,
            lastName: assignment.userInfo.baseUserInfo.lastName,
            email: assignment.userInfo.baseUserInfo.email,
            avatar: assignment.userInfo.baseUserInfo.avatar,
            avatarColor: assignment.userInfo.baseUserInfo.avatarColor,
            dueDate: assignment.dueDateDirect ?? assignment.dueDateGroup,
          }))
          .map(u => (
            <SharingModalMenuItemWithAssignSelfPacedControls
              key={u.uuid}
              user={u}
              onSetDueDate={
                canSetContentDueDates
                  ? async dueDate => {
                      await setUsersDueDate(
                        [u.uuid],
                        [
                          {
                            content: { id: content.id, type: content.type },
                            dueDate: dueDate === u.dueDate ? undefined : dueDate,
                          },
                        ]
                      )
                      void reloadAssignments()
                    }
                  : undefined
              }
              onRemoveDueDate={
                canSetContentDueDates
                  ? async () => {
                      await setUsersDueDate(
                        [u.uuid],
                        [
                          {
                            content: { id: content.id, type: content.type },
                            dueDate: undefined,
                          },
                        ]
                      )
                      void reloadAssignments()
                    }
                  : undefined
              }
              onUnassign={
                canEditAssignments
                  ? async () => {
                      await unassignContent(u.uuid, content)
                      void reloadAssignments()
                    }
                  : undefined
              }
            />
          ))}

        <InView
          key={'load-more'}
          onChange={inView => {
            if (hasMoreAssignments) setWantMoreExistingAssignments(inView && isLoadingAssignments !== true)
          }}
        >
          <div />
        </InView>
        {!hasMoreAssignments &&
          numRemainingGroupAssignments !== undefined &&
          numRemainingGroupAssignments > 0 && (
            <RemainingUsersMenuItem
              remainingUsersCount={numRemainingGroupAssignments}
              onClick={() =>
                getGlobalRouter().navigate({ to: `/manage/courses/${CourseId.parse(content.id)}` })
              }
            />
          )}
        {hasMoreAssignments && <ShareModalListItemSkeleton />}
      </ExistingAssignedUsersContainer>
    </div>
  )
}
