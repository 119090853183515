import { useAtomValue } from 'jotai'
import React, { useCallback, useEffect, useState } from 'react'
import { graphql } from 'sierra-client/api/graphql/gql'
import {
  MutationSendAdHocNotificationsForProgramAllArgs,
  MutationSendAdHocNotificationsForProgramArgs,
  SendReminderAllMutation,
  SendReminderMutation,
} from 'sierra-client/api/graphql/gql/graphql'
import { graphQuery } from 'sierra-client/api/hooks/use-graphql-query'
import { useNotif } from 'sierra-client/components/common/notifications'
import { useTranslation } from 'sierra-client/hooks/use-translation'
import { ProgramUsersTableData } from 'sierra-client/lib/tabular/dataloader/program-members'
import { RowRef } from 'sierra-client/lib/tabular/types'
import { UseTableAPI } from 'sierra-client/lib/tabular/use-table-api'
import { temporaryComputeSelectedRows } from 'sierra-client/lib/tabular/utils'
import { useProgramReminderRecipients } from 'sierra-client/views/manage/programs/hooks/use-program-reminder-recipients'
import { useTracking } from 'sierra-client/views/manage/programs/hooks/use-tracking'
import { ProgramReminderPreviewSection } from 'sierra-client/views/manage/programs/send-reminder/program-reminder-preview-section'
import {
  ProgramReminderRecipientsTabular,
  ReminderRecipientsTabularAction,
  useProgramReminderRecipientsTableAPI,
} from 'sierra-client/views/manage/programs/send-reminder/program-reminder-recipients-tabular'
import { RecipientsSelector } from 'sierra-client/views/manage/programs/send-reminder/recipients-selector'
import { SendReminderAction } from 'sierra-client/views/manage/programs/send-reminder/types'
import { ProgramId } from 'sierra-domain/api/uuid'
import { Icon, Layout, Panel } from 'sierra-ui/components'
import { Button, IconButton, Spacer, Text, View } from 'sierra-ui/primitives'
import { token } from 'sierra-ui/theming'
import styled from 'styled-components'

const Modal = styled(Panel).attrs({
  size: { width: 750 },
  animation: 'slide-right',
  padding: 'none',
  disableScrollbarGutter: true,
})``

const Vertical = styled(View).attrs({ direction: 'column' })``

const LayoutWrapper = styled(View).attrs({
  direction: 'column',
  paddingLeft: '40',
  paddingRight: '40',
  justifyContent: 'space-between',
})`
  flex: 1;
  position: relative;
`

const Content = styled(Vertical).attrs({
  paddingTop: '32',
  paddingBottom: '32',
  gap: '24',
  grow: true,
})``

const Footer = styled(View).attrs({
  paddingTop: 'none',
  paddingBottom: '32',
  paddingLeft: '40',
  paddingRight: '40',
  justifyContent: 'space-between',
  alignItems: 'center',
})`
  min-height: 90px;
`

const Headline = styled(Text).attrs({ bold: true })`
  line-height: 1;
`

const TableWrapper = styled(View).attrs({ direction: 'column', grow: true })`
  width: 100%;
`

const LearnMoreWrapper = styled(View).attrs({ gap: '8', cursor: 'pointer', marginRight: '6' })`
  &:hover * {
    color: ${token('foreground/primary')};
  }
`

const LearnMoreLink: React.FC<{ programId: string }> = ({ programId }) => {
  const { t } = useTranslation()
  const tracking = useTracking()

  return (
    <LearnMoreWrapper
      onClick={() => {
        window.location.href = 'https://help.sana.ai/en/articles/136573-programs-adhoc-email-reminders'
        tracking.reminder.learnMoreClicked(programId)
      }}
    >
      <Icon iconId='information' color={'foreground/muted'} />
      <Text color={'foreground/muted'} size='small' bold>
        {t('manage.programs.send-reminder.more-info')}
      </Text>
    </LearnMoreWrapper>
  )
}

const EnrolledSelectorSection: React.FC<{ programId: string }> = ({ programId }) => {
  const { t } = useTranslation()

  return (
    <View direction='column'>
      <Headline size='small' bold>
        {t('manage.programs.send-reminder.add-recipients')}
      </Headline>
      <RecipientsSelector programId={programId} />
    </View>
  )
}

const UserRemovedIcon = styled(Icon).attrs({
  iconId: 'user--remove',
  size: 'size-16',
  color: 'foreground/secondary',
})`
  grid-column-start: 1;
  padding-top: 2px;
`

const Close = styled(IconButton).attrs({ iconId: 'close', size: 'small', variant: 'transparent' })`
  grid-column-start: 3;
  color: ${token('foreground/muted')};

  &:hover {
    background-color: transparent;
    color: ${token('foreground/primary')};
  }
`

const ViewIn2ndCol = styled(Vertical).attrs({ gap: '2' })`
  grid-column-start: 2;
`

const InfoWrapper = styled(Vertical).attrs({
  gap: '2',
  padding: '12 16',
  background: 'form/background/1',
  radius: 'size-12',
})`
  display: grid;
  grid-template-columns: 16px 1fr 24px;
  width: 100%;
  column-gap: 8px;
  align-items: start;
`

const Info: React.FC<{ completed: number; recentlyContacted: number; onClick: () => void }> = ({
  completed,
  recentlyContacted,
  onClick,
}) => {
  const { t } = useTranslation()

  const count = completed + recentlyContacted

  const translationKey =
    completed > 0 && recentlyContacted > 0
      ? 'manage.programs.send-reminder.already-completed-or-contacted'
      : completed > 0
        ? 'manage.programs.send-reminder.already-completed'
        : 'manage.programs.send-reminder.recently-contacted'

  return (
    <InfoWrapper>
      <UserRemovedIcon />
      <ViewIn2ndCol>
        <Text size='small' color='foreground/secondary' bold>
          {t('reminder.recipients-removed', { count: count })}
        </Text>
        <Text size='small' color='foreground/secondary'>
          {t(translationKey)}
        </Text>
      </ViewIn2ndCol>
      <Close onClick={onClick} />
    </InfoWrapper>
  )
}

const InfoAllExcept: React.FC<{ excludedCount: number }> = ({ excludedCount }) => {
  const { t } = useTranslation()

  return (
    <InfoWrapper>
      <UserRemovedIcon />
      <ViewIn2ndCol>
        <Text size='small' color='foreground/secondary' bold>
          {t('manage.programs.send-reminder.recipients-filtered')}
        </Text>
        {excludedCount > 0 ? (
          <Text size='small' color='foreground/secondary'>
            {t('manage.programs.send-reminder.excluded-users-explanation', { count: excludedCount })}
          </Text>
        ) : (
          <Text size='small' color='foreground/secondary'>
            {t('manage.programs.send-reminder.recipients-filtered-explanation')}
          </Text>
        )}
      </ViewIn2ndCol>
    </InfoWrapper>
  )
}

const EmptyRecipients: React.FC = () => {
  const { t } = useTranslation()

  return (
    <View direction='column' gap='2' radius='size-12' background='form/background/1' padding='32 48'>
      <Icon iconId='user' color='form/disabled/foreground' />
      <Spacer size='8' />
      <Text size='small' bold align='center' color='form/disabled/foreground'>
        {t('reminder.no-recipients.heading')}
      </Text>
      <Text size='small' align='center' color='form/disabled/foreground'>
        {t('reminder.no-recipients.explanation')}
      </Text>
    </View>
  )
}

const sendReminder = async ({
  programId,
  userIds,
}: MutationSendAdHocNotificationsForProgramArgs): Promise<SendReminderMutation> =>
  graphQuery(
    graphql(`
      mutation sendReminder($programId: ProgramId!, $userIds: [UserId!]!) {
        sendAdHocNotificationsForProgram(programId: $programId, userIds: $userIds) {
          errors {
            userId
            reason
          }
        }
      }
    `),
    { programId, userIds }
  )

const sendReminderToAll = async ({
  programId,
  excludedUsers,
}: MutationSendAdHocNotificationsForProgramAllArgs): Promise<SendReminderAllMutation> =>
  graphQuery(
    graphql(`
      mutation sendReminderAll($programId: ProgramId!, $excludedUsers: [UserId!]!) {
        sendAdHocNotificationsForProgramAll(programId: $programId, excludedUsers: $excludedUsers) {
          errors {
            userId
            reason
          }
        }
      }
    `),
    { programId, excludedUsers }
  )

export const SendReminderModal: React.FC<{
  open: boolean
  closeModal: () => void
  programId: ProgramId
  action: SendReminderAction
  programMembersTableAPI: UseTableAPI<ProgramUsersTableData, any>
}> = ({ open, closeModal, programId, action, programMembersTableAPI }) => {
  const { t } = useTranslation()

  const programUsersTableData = useAtomValue(programMembersTableAPI.api.atoms.tableData)
  const programUsersSelection = useAtomValue(programMembersTableAPI.api.atoms.selection)

  const notification = useNotif()
  const tracking = useTracking()

  const [isReminderSending, setReminderSending] = useState(false)
  const [filteredOutCompleted, setFilteredOutCompleted] = useState(0)
  const [filteredOutContacted, setFilteredOutContacted] = useState(0)

  const { selectedMembers, setSelectedMembers, fetchSelected } = useProgramReminderRecipients(programId)

  useEffect(() => {
    if (action.modal === 'send-reminder-all-except') {
      void (async () => {
        // const { alreadyCompleted, recentlyContacted } = await fetchAllExceptInfo()
        // setFilteredOutCompleted(alreadyCompleted)
        // setFilteredOutContacted(recentlyContacted)
        // TODO: we want to fetch the users who have completed the program and the users who have been recently contacted from the backend, currently we do not have an endpoint for this
      })()
    } else if (action.modal === 'send-reminder-row') {
      void (async () => {
        const { alreadyCompleted, recentlyContacted } = await fetchSelected([action.userId])
        setFilteredOutCompleted(alreadyCompleted)
        setFilteredOutContacted(recentlyContacted)
      })()
    } else {
      //Bulk case
      void (async () => {
        const rowIdSet = new Set(temporaryComputeSelectedRows(programUsersTableData, programUsersSelection))
        const selectedIds = programUsersTableData
          .flatMap(td => td.rows)
          .filter(r => rowIdSet.has(r.id) && r.data.user.data)
          .map(r => r.data.user.data!.id)

        const { alreadyCompleted, recentlyContacted } = await fetchSelected(selectedIds)
        setFilteredOutCompleted(alreadyCompleted)
        setFilteredOutContacted(recentlyContacted)
      })()
    }
  }, [action, fetchSelected, programUsersSelection, programUsersTableData])

  const tabularAction: ReminderRecipientsTabularAction = React.useMemo<ReminderRecipientsTabularAction>(
    () => ({
      onRemoveRecipient: (ref: RowRef) => {
        setSelectedMembers(selectedMembers.filter(m => m.id !== ref))
      },
    }),
    [selectedMembers, setSelectedMembers]
  )

  const tableAPI = useProgramReminderRecipientsTableAPI(tabularAction, programId)

  const onSendReminder = useCallback(async () => {
    setReminderSending(true)
    if (action.modal === 'send-reminder-all-except') {
      await sendReminderToAll({
        programId,
        excludedUsers: action.except,
      })
      closeModal()
      setReminderSending(false)
    } else {
      const response = await sendReminder({
        programId,
        userIds: selectedMembers.map(m => m.id),
      })
      if (response.sendAdHocNotificationsForProgram.errors.length === 0) {
        tracking.reminder.send(programId, selectedMembers.length)
        closeModal()
        notification.push({
          type: 'custom',
          level: 'success',
          body: t('reminder.sent', { count: selectedMembers.length }),
        })
        setReminderSending(false)
      }
    }
  }, [action, programId, selectedMembers, tracking.reminder, closeModal, notification, t])

  const onClose = useCallback(() => {
    closeModal()
    setSelectedMembers([])
  }, [closeModal, setSelectedMembers])

  const closeInfo = useCallback(() => {
    setFilteredOutCompleted(0)
    setFilteredOutContacted(0)
  }, [])

  return (
    <Modal open={open} onClose={onClose}>
      <ProgramReminderPreviewSection programId={programId} />
      <Layout>
        <LayoutWrapper>
          <Content>
            {action.modal !== 'send-reminder-all-except' && (
              <>
                <View direction='column'>
                  <EnrolledSelectorSection programId={programId} />
                </View>
                {filteredOutCompleted + filteredOutContacted > 0 && (
                  <Info
                    completed={filteredOutCompleted}
                    recentlyContacted={filteredOutContacted}
                    onClick={closeInfo}
                  />
                )}
                {selectedMembers.length > 0 && (
                  <TableWrapper>
                    <ProgramReminderRecipientsTabular tableAPI={tableAPI} />
                  </TableWrapper>
                )}
                {selectedMembers.length === 0 && <EmptyRecipients />}
              </>
            )}
            {action.modal === 'send-reminder-all-except' && (
              <>
                <InfoAllExcept excludedCount={action.except.length} />
              </>
            )}
          </Content>
        </LayoutWrapper>
        <Footer>
          <LearnMoreLink programId={programId} />
          <Button onClick={onSendReminder} disabled={isReminderSending} loading={isReminderSending}>
            {t('manage.programs.user-table.send-reminder')}
          </Button>
        </Footer>
      </Layout>
    </Modal>
  )
}
