import { createContext, FC, useContext, useMemo } from 'react'
import { ScopedLiveSessionId } from 'sierra-domain/collaboration/types'

type LiveSessionIdContext = { liveSessionId: ScopedLiveSessionId }
const LiveSessionIdContextObj = createContext<LiveSessionIdContext | undefined>(undefined)

export const LiveSessionIdProvider: FC<
  React.PropsWithChildren<{
    liveSessionId: ScopedLiveSessionId
  }>
> = ({ children, liveSessionId }) => {
  const liveSessionIdValue = useMemo(() => ({ liveSessionId }), [liveSessionId])

  return (
    <LiveSessionIdContextObj.Provider value={liveSessionIdValue}>{children}</LiveSessionIdContextObj.Provider>
  )
}

export const useLiveSessionIdContext = (): LiveSessionIdContext => {
  const context = useContext(LiveSessionIdContextObj)

  if (context === undefined) {
    throw new Error('This component must be wrapped in a LiveProvider')
  }

  return context
}

export const useLiveSessionIdContextIfAvailable = (): LiveSessionIdContext | undefined => {
  const context = useContext(LiveSessionIdContextObj)
  return context
}
