import { SyncYJson } from '@sanalabs/y-redux'
import { useCallback, useEffect } from 'react'
import { selectFlexibleContent } from 'sierra-client/state/flexible-content/selectors'
import { flexibleContentSlice } from 'sierra-client/state/flexible-content/slice'
import { RootState } from 'sierra-client/state/types'
import { CreateContentId } from 'sierra-domain/api/nano-id'
import { FlexibleContentJsonData } from 'sierra-domain/flexible-content/types'
import * as Y from 'yjs'

export const CreateContentObserver = ({
  jsonDataYMap,
  createContentId,
}: {
  jsonDataYMap: Y.Map<unknown>
  createContentId: CreateContentId
}): JSX.Element => {
  useEffect(() => {
    console.debug('[CreateContentObserver] mount for id:', createContentId)
    return () => console.debug('[CreateContentObserver] unmount for id', createContentId)
  }, [createContentId])

  const setData = useCallback(
    (data: FlexibleContentJsonData) => flexibleContentSlice.actions.setData({ createContentId, data }),
    [createContentId]
  )

  const selectData = useCallback(
    (state: RootState) => selectFlexibleContent(state, createContentId),
    [createContentId]
  )

  return <SyncYJson yJson={jsonDataYMap} setData={setData} selectData={selectData} />
}
