import { useCallback, useEffect, useMemo } from 'react'
import { useLiveSessionStateMutation } from 'sierra-client/api/hooks/use-live-session'
import { useConfirmationModalContext } from 'sierra-client/components/common/modals/confirmation-modal'
import { useLiveSessionContext } from 'sierra-client/components/liveV2/contexts/live-session-data'
import { useIsFacilitatorOrLearnerLedSession } from 'sierra-client/components/liveV2/hooks/use-is-facilitator-or-learner-led-session'
import { useLiveContext } from 'sierra-client/components/liveV2/live-context'
import { Logging } from 'sierra-client/core/logging'
import { useTranslation } from 'sierra-client/hooks/use-translation'
import { TranslationKey } from 'sierra-client/hooks/use-translation/types'
import { useDispatch, useSelector } from 'sierra-client/state/hooks'
import { liveSessionStateChanged } from 'sierra-client/state/live-session/actions'
import { selectCallIsActive } from 'sierra-client/state/live/selectors'

type LeaveCopy = {
  dialogTitle: TranslationKey
  dialogBody: TranslationKey
  dialogAction: TranslationKey
  tooltip: TranslationKey
  dialogSecondaryAction?: TranslationKey
}

const LEAVE_BUTTON_CONFIRMATION_COPY: {
  leaveSession: LeaveCopy
  endSession: LeaveCopy
} = {
  leaveSession: {
    dialogTitle: 'live.leave-copy.leave-the-session',
    dialogBody: 'live.leave-copy.are-you-sure-you-want-to-leave-the-session',
    dialogAction: 'live.leave-copy.leave-the-session',
    tooltip: 'live.leave-copy.exit-live-session',
  },
  endSession: {
    dialogTitle: 'live.leave-copy.end-the-session-for-everyone',
    dialogBody: 'live.leave-copy.end-session-explanation',
    dialogAction: 'live.leave-copy.end-the-session',
    tooltip: 'live.leave-copy.exit-live-session',
    dialogSecondaryAction: 'live.leave-copy.leave-the-session',
  },
} as const

export const useLeaveModal = (): { show: () => void; copy: LeaveCopy } => {
  const isFacilitatorOrLearnerLedSession = useIsFacilitatorOrLearnerLedSession()
  const { show: showConfirmationModal, close: closeConfirmationModal } = useConfirmationModalContext()
  const { leave: leaveLiveSession } = useLiveContext()
  const liveSession = useLiveSessionContext()
  const mutation = useLiveSessionStateMutation()
  const dispatch = useDispatch()
  const { t } = useTranslation()
  const joined = useSelector(selectCallIsActive)

  const copy = useMemo(
    () =>
      isFacilitatorOrLearnerLedSession
        ? LEAVE_BUTTON_CONFIRMATION_COPY.endSession
        : LEAVE_BUTTON_CONFIRMATION_COPY.leaveSession,
    [isFacilitatorOrLearnerLedSession]
  )

  useEffect(() => {
    if (!joined) {
      closeConfirmationModal()
    }
  }, [closeConfirmationModal, joined])

  const handleLeaveLiveSession = useCallback(async () => {
    await leaveLiveSession()

    void dispatch(Logging.liveSession.leaveButtonLeaveConfirmed())
  }, [dispatch, leaveLiveSession])

  const handleEndSession = useCallback(() => {
    mutation.mutate(
      {
        liveSessionId: liveSession.liveSessionId,
        state: 'ended',
      },
      {
        onSuccess: async () => {
          dispatch(liveSessionStateChanged('ended'))
          await leaveLiveSession()
        },
      }
    )

    void dispatch(Logging.liveSession.leaveButtonEndSessionConfirmed())
  }, [dispatch, leaveLiveSession, liveSession.liveSessionId, mutation])

  const handleAbort = useCallback(() => {
    void dispatch(Logging.liveSession.leaveButtonCanceled())
  }, [dispatch])

  const show = useCallback(() => {
    showConfirmationModal({
      bodyText: t(copy.dialogBody),
      confirmLabel: t(copy.dialogAction),
      onConfirm: isFacilitatorOrLearnerLedSession ? handleEndSession : handleLeaveLiveSession,
      deleteAction: true,
      title: t(copy.dialogTitle),
      secondaryActionLabel: copy.dialogSecondaryAction && t(copy.dialogSecondaryAction),
      onSecondaryAction: isFacilitatorOrLearnerLedSession ? handleLeaveLiveSession : undefined,
      onAborted: handleAbort,
    })
  }, [
    showConfirmationModal,
    t,
    copy,
    isFacilitatorOrLearnerLedSession,
    handleAbort,
    handleEndSession,
    handleLeaveLiveSession,
  ])

  return useMemo(() => ({ show, copy }), [show, copy])
}
