import { FC, useState } from 'react'
import { useCourseCollaboratorGroupsMutation } from 'sierra-client/api/hooks/use-course-collaborator'
import { ChipInputWithAutocomplete } from 'sierra-client/components/common/chip-input-with-autocomplete'
import { useNotif } from 'sierra-client/components/common/notifications'
import { Pill } from 'sierra-client/components/common/pill'
import { AutocompleteContainer } from 'sierra-client/components/sharing/tabs/components/containers'
import { useTranslation } from 'sierra-client/hooks/use-translation'
import { useCachedQuery } from 'sierra-client/state/api'
import { currentCourseId } from 'sierra-client/state/author-course-settings/selectors'
import { useSelector } from 'sierra-client/state/hooks'
import { SettingsTabComponent } from 'sierra-client/views/course-settings/types'
import { CourseCollaboratorGroup, CourseRole } from 'sierra-domain/api/manage'
import { NanoId12 } from 'sierra-domain/api/nano-id'
import {
  XRealtimeAdminCoursesListCourseCollaboratorGroups,
  XRealtimeAdminCoursesListCoursePotentialCollaboratorGroups,
} from 'sierra-domain/routes'
import { MenuItem, RoundAvatar } from 'sierra-ui/components'
import { Button, LoadingSpinner, Spacer, Text } from 'sierra-ui/primitives'
import { SingleSelectDropdown } from 'sierra-ui/primitives/menu-dropdown'
import { palette } from 'sierra-ui/theming'
import styled from 'styled-components'

const Container = styled.div`
  display: flex;
  flex-direction: column;
`

const InputContainer = styled.div`
  flex: 1;
  display: grid;
  grid: 1fr / 1fr 0fr;
  gap: 1.5rem;
  align-items: center;
`

const CourseRoleDropdownContainer = styled.div`
  flex: 1;
  position: absolute;
  right: 0;
  top: 1px;
`

const SuggestionItem = styled.li`
  display: flex;
  align-items: center;
`

const CollaboratorList = styled.ul`
  list-style: none;
  position: relative;

  li {
    border-bottom: 1px solid ${palette.grey[5]};
  }

  li.grid {
    display: grid;
    grid: 1fr / 0fr 1fr;
    grid-auto-columns: max-content;
    grid-auto-flow: column;
    gap: 1rem;
    justify-content: start;
    align-items: center;
    padding: 0.5rem 0;
    font-size: 1em;
  }
`

const getOptionLabel = (option: CourseCollaboratorGroup): string => option.groupName ?? ''

const InviteCollaboratorGroupsForm: FC<{
  potentialGroups: CourseCollaboratorGroup[]
  collaboratorGroups: CourseCollaboratorGroup[]
  courseId: NanoId12
}> = ({ potentialGroups, collaboratorGroups, courseId }) => {
  const { t } = useTranslation()
  const notifications = useNotif()

  const [query, setQuery] = useState('')
  const [selections, setSelections] = useState<CourseCollaboratorGroup[]>([])

  const [collaboratorRole, setCollaboratorRole] = useState<CourseRole>('editor')

  const filterOptions = (options: CourseCollaboratorGroup[]): CourseCollaboratorGroup[] => {
    const collaboratorGroupIds = new Set(collaboratorGroups.map(group => group.groupId))

    return options.filter(group => {
      if (collaboratorGroupIds.has(group.groupId)) return false

      const labelTokens = getOptionLabel(group)
        .split(' ')
        .map(t => t.toLowerCase())
      const queryTokens = query.split(' ').map(t => t.toLowerCase())

      return labelTokens.some(lt => queryTokens.some(qt => lt.startsWith(qt)))
    })
  }

  const updateCollaboratorGroupsMutation = useCourseCollaboratorGroupsMutation()

  const handleInviteSelections = (): void => {
    updateCollaboratorGroupsMutation.mutate(
      {
        courseId,
        add: selections.map(group => group.groupId),
        remove: [],
      },
      {
        onSuccess: () => {
          setSelections([])

          notifications.push({
            type: 'custom',
            level: 'success',
            body: t('notifications.group-invited-singular'),
          })
        },
      }
    )
  }

  const menuItems: MenuItem<CourseRole>[] = [
    { type: 'label', label: t('author.can-edit'), id: 'editor', selected: collaboratorRole === 'editor' },
  ]

  const selectedItem = menuItems.find(item => item.id === collaboratorRole)

  return (
    <InputContainer>
      <AutocompleteContainer>
        <ChipInputWithAutocomplete<CourseCollaboratorGroup, true, true, false>
          inputValue={query}
          onInputChange={(event, value) => setQuery(value)}
          value={selections}
          onChange={(event, values) => {
            setSelections(values)
          }}
          options={potentialGroups}
          getOptionLabel={getOptionLabel}
          filterOptions={filterOptions}
          limitTags={5}
          multiple
          disableClearable
          freeSolo={false}
          disabled={false}
          inputPlaceholder={t('author.course-settings.skill-placeholder')}
          renderTags={groups => {
            return groups.map(group => (
              <Pill key={group.groupId} variant='outlined' active={false}>
                {getOptionLabel(group)}
              </Pill>
            ))
          }}
          renderOption={group => {
            return (
              <SuggestionItem>
                <RoundAvatar firstName={group.groupName} color={group.avatarColor} />
                <Spacer />
                <Text color='LEGACY_DEFAULT_TEXT_COLOR_REPLACE_ASAP' bold size='small'>
                  {getOptionLabel(group)}
                </Text>
              </SuggestionItem>
            )
          }}
        />

        <CourseRoleDropdownContainer>
          <SingleSelectDropdown<CourseRole>
            variant='ghost'
            selectedItem={selectedItem}
            menuItems={menuItems}
            onSelect={item => setCollaboratorRole(item.id)}
          />
        </CourseRoleDropdownContainer>
      </AutocompleteContainer>
      <Button variant='secondary' onClick={handleInviteSelections} disabled={selections.length === 0}>
        {t('dictionary.invite')}
      </Button>
    </InputContainer>
  )
}

const CourseCollaboratorGroupList: FC<{
  collaboratorGroups: CourseCollaboratorGroup[]
  courseId: NanoId12
}> = ({ collaboratorGroups, courseId }) => {
  const { t } = useTranslation()

  const updateCollaboratorGroupsMutation = useCourseCollaboratorGroupsMutation()

  const handleRemoveGroup = (group: CourseCollaboratorGroup): void => {
    updateCollaboratorGroupsMutation.mutate({
      courseId,
      add: [],
      remove: [group.groupId],
    })
  }

  const editorItem = {
    type: 'label' as const,
    label: t('author.can-edit'),
    id: 'editor' as const,
    selected: true,
  }
  const items: MenuItem<'editor' | 'remove'>[] = [
    editorItem,
    { type: 'label', label: t('admin.remove'), id: 'remove' as const, color: 'destructive/background' },
  ]

  return (
    <CollaboratorList>
      {collaboratorGroups.length === 0 ? (
        <li>
          <Text size='small' color='grey35'>
            {t('author.no-collaborators')}
          </Text>
        </li>
      ) : (
        [...collaboratorGroups].map(group => (
          <li key={group.groupId} className='grid'>
            <RoundAvatar firstName={group.groupName} color={group.avatarColor} />
            <Text color='LEGACY_DEFAULT_TEXT_COLOR_REPLACE_ASAP' bold size='small'>
              {getOptionLabel(group)}
            </Text>

            <SingleSelectDropdown
              variant='ghost'
              selectedItem={editorItem}
              menuItems={items}
              onSelect={item => {
                if (item.id === 'remove') {
                  handleRemoveGroup(group)
                }
              }}
            />
          </li>
        ))
      )}
    </CollaboratorList>
  )
}

const DataLoader: FC<{ courseId: NanoId12 }> = ({ courseId }) => {
  const { t } = useTranslation()

  const potentialCollaboratorQuery = useCachedQuery(
    XRealtimeAdminCoursesListCoursePotentialCollaboratorGroups,
    { courseId },
    { retry: false }
  )

  const collaboratorGroupsQuery = useCachedQuery(
    XRealtimeAdminCoursesListCourseCollaboratorGroups,
    { courseId },
    { retry: false }
  )

  return (
    <Container>
      {potentialCollaboratorQuery.data === undefined || collaboratorGroupsQuery.data === undefined ? (
        <LoadingSpinner padding='large' />
      ) : (
        <>
          <Text bold size='small'>
            {t('author.invite-collaborators')}
          </Text>
          <Spacer size='8' />
          <InviteCollaboratorGroupsForm
            potentialGroups={potentialCollaboratorQuery.data.collaborators}
            collaboratorGroups={collaboratorGroupsQuery.data.collaborators}
            courseId={courseId}
          />
          <Spacer size='16' />
          <CourseCollaboratorGroupList
            collaboratorGroups={collaboratorGroupsQuery.data.collaborators}
            courseId={courseId}
          />
        </>
      )}
    </Container>
  )
}

export const CollaboratorGroupsTab: SettingsTabComponent = () => {
  const courseId = useSelector(currentCourseId)

  return courseId === null ? <LoadingSpinner padding='large' /> : <DataLoader courseId={courseId} />
}
