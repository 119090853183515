import { Atom, useAtomValue } from 'jotai'
import { selectAtom } from 'jotai/utils'
import { useMemo, useRef } from 'react'

export function useSelectAtom<AtomValue, SelectedValue>(
  atom: Atom<AtomValue>,
  selector: (value: AtomValue, prevSelectedValue?: SelectedValue) => SelectedValue,
  isEqual?: (a: SelectedValue, b: SelectedValue) => boolean
): Atom<SelectedValue> {
  const selectorRef = useRef(selector)
  const isEqualRef = useRef(isEqual)
  selectorRef.current = selector
  isEqualRef.current = isEqual

  return useMemo(() => selectAtom(atom, selectorRef.current, isEqualRef.current), [atom])
}

export function useSelectAtomValue<AtomValue, SelectedValue>(
  atom: Atom<AtomValue>,
  selector: (value: AtomValue, prevSelectedValue?: SelectedValue) => SelectedValue,
  isEqual?: (a: SelectedValue, b: SelectedValue) => boolean
): SelectedValue {
  const selectedAtom = useSelectAtom(atom, selector, isEqual)
  return useAtomValue(selectedAtom)
}
