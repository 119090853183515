import { isElementType } from 'sierra-client/views/v3-author/queries'
import { isDefined } from 'sierra-domain/utils'
import { Table, TableCell, TableHeaderCell } from 'sierra-domain/v3-author'
import { Text } from 'slate'

export function prettyPrintTable(table: Table): string {
  const thead = table.children[0]
  const tbody = table.children[1]

  if (!isElementType('table-head', thead) || !isElementType('table-body', tbody)) {
    return 'Failed to parse table'
  }

  function printCell(cell: TableCell | TableHeaderCell): string {
    return cell.children.map(text => (Text.isText(text) ? text.text : '')).join('')
  }

  function createSeparator(length: number): string | undefined {
    return length === 0
      ? undefined
      : `+${Array(length - 2)
          .fill('-')
          .join('')}+`
  }

  function serializeTable({ head, body }: { head: string[][]; body: string[][] }): string {
    const columnWidths: number[] = []
    let max: number | undefined = undefined

    do {
      const index = columnWidths.length

      for (const section of [head, body]) {
        for (const row of section) {
          const cell = row[index]
          max = cell !== undefined ? Math.max(max ?? 0, cell.length) : undefined
        }
      }

      if (max !== undefined) {
        columnWidths.push(max)
      }
    } while (max !== undefined)

    const headString = head
      .map(row => `| ${row.map((cell, index) => cell.padEnd(columnWidths[index] ?? 0)).join(' | ')} |`)
      .filter(isDefined)
    const bodyString = body
      .map(row =>
        row.length === 0
          ? undefined
          : `| ${row.map((cell, index) => cell.padEnd(columnWidths[index] ?? 0)).join(' | ')} |`
      )
      .filter(isDefined)

    const separator = createSeparator(bodyString.reduce((max, row) => Math.max(max, row.length), 0))

    const out = [
      separator,
      headString.length > 0 ? headString.join('\n') : undefined,
      separator,
      bodyString.join('\n'),
      separator,
    ]
      .filter(isDefined)
      .join('\n')

    return out
  }

  return serializeTable({
    head: thead.children.map(row => row.children.map(cell => printCell(cell))),
    body: tbody.children.map(row => row.children.map(cell => printCell(cell))),
  })
}

export const debug: typeof console.debug = (...args) => {
  console.debug('[Table block]', ...args)
}
