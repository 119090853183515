import React, { useState } from 'react'
import {
  useAdminIdentitiesFetcher,
  usePotentialFacilitatorsForCalendarEventsFetcher,
} from 'sierra-client/components/common/identities-selector/identity-fetchers'
import { useTranslation } from 'sierra-client/hooks/use-translation'
import {
  CalendarEventUserSelector,
  EventParticipantSelectorProps,
} from 'sierra-client/views/manage/event-groups/components/calendar-event-user-selector'
import { EventScheduleForm } from 'sierra-client/views/manage/event-groups/components/event-schedule-form'
import { EventLocationInput } from 'sierra-client/views/manage/event-groups/components/location-input'
import {
  CalendarEventSchedule,
  CreateCalendarEventData,
  EventLocation,
} from 'sierra-client/views/manage/event-groups/types'
import { IdentityWithMetadata } from 'sierra-domain/api/manage'
import { isDefined } from 'sierra-domain/utils'
import { FormElement } from 'sierra-ui/components'
import { View } from 'sierra-ui/primitives'

export const EventForm: React.FC<{
  event: CreateCalendarEventData
  updateSchedule: (newSchedule: CalendarEventSchedule) => void
  updateLocation: (newLocation: EventLocation | undefined) => void
  updateParticipantIdentities?: (newParticipantIdentites: IdentityWithMetadata[]) => void
  updateFacilitatorIdentities: (newFacilitatorIdentites: IdentityWithMetadata[]) => void
}> = ({
  event,
  updateSchedule,
  updateLocation,
  updateParticipantIdentities,
  updateFacilitatorIdentities,
}) => {
  const { t } = useTranslation()

  const [selectedParticipantIdentities, setSelectedParticipantIdentitiesState] = useState<
    IdentityWithMetadata[]
  >(event.participantIdentities)

  const setSelectedParticipantIdentities: EventParticipantSelectorProps['setSelectedIdentities'] = update => {
    const updatedIdentities = update(selectedParticipantIdentities)
    setSelectedParticipantIdentitiesState(updatedIdentities)
    updateParticipantIdentities?.(updatedIdentities)
  }

  const [selectedFacilitatorIdentities, setSelectedFacilitatorIdentitiesState] = useState<
    IdentityWithMetadata[]
  >(event.facilitatorIdentities)

  const setSelectedFacilitatorIdentities: EventParticipantSelectorProps['setSelectedIdentities'] = update => {
    const updatedIdentities = update(selectedFacilitatorIdentities)
    setSelectedFacilitatorIdentitiesState(updatedIdentities)
    updateFacilitatorIdentities(updatedIdentities)
  }

  const fetchParticipantIdentities = useAdminIdentitiesFetcher({ withTypes: ['user', 'user-group'] })
  const fetchFacilitatorIdentities = usePotentialFacilitatorsForCalendarEventsFetcher()

  return (
    <View direction='column' position='relative' marginBottom={'4'}>
      <FormElement grow={false} label={t('admin.author.sessions.location')}>
        <EventLocationInput updateLocation={updateLocation} initialLocation={event.location} />
      </FormElement>
      {isDefined(updateParticipantIdentities) && (
        <FormElement grow={false} label={t('admin.author.sessions.participants')}>
          <CalendarEventUserSelector
            selectedIdentities={selectedParticipantIdentities}
            setSelectedIdentities={setSelectedParticipantIdentities}
            fetchIdentities={fetchParticipantIdentities}
          />
        </FormElement>
      )}

      <FormElement grow={false} label={t('live.facilitator')}>
        <CalendarEventUserSelector
          selectedIdentities={selectedFacilitatorIdentities}
          setSelectedIdentities={setSelectedFacilitatorIdentities}
          fetchIdentities={fetchFacilitatorIdentities}
        />
      </FormElement>

      <EventScheduleForm schedule={event.schedule} updateSchedule={updateSchedule} />
    </View>
  )
}
