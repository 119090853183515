import React from 'react'
import { useFakeProgress } from 'sierra-client/hooks/use-fake-progress'
import { useOnMount } from 'sierra-client/hooks/use-on-mount'
import { useTranslation } from 'sierra-client/hooks/use-translation'
import { AiLoader } from 'sierra-ui/components/ai-kit/ai-loader'
import { View } from 'sierra-ui/primitives'
import styled from 'styled-components'

const LoadingBackground = styled(AiLoader)`
  width: 100%;
  height: 100%;
`

export const GenerationBackground: React.FC = () => {
  const progress = useFakeProgress({ target: 100, finishDuration: 1000 })
  useOnMount(() => {
    progress.startInfiniteProgress()
  })
  const { t } = useTranslation()
  return (
    <View grow>
      <LoadingBackground label={t('dictionary.generating')} progress={100} duration={15000} />
    </View>
  )
}
