import { Extension, Node } from '@tiptap/core'
import { Editor, useEditor } from '@tiptap/react'
import { StarterKit } from '@tiptap/starter-kit'
import { InsightsContentSuggestions } from 'sierra-client/views/insights/insights-content-search/suggestions/insights-content-suggestions'

const Multiline = Node.create({
  name: 'multiline',
  topNode: true,
  content: 'block+',
})

const BaseExtensions = [
  Multiline,
  StarterKit.configure({
    hardBreak: false,
    blockquote: false,
    bulletList: false,
    codeBlock: false,
    heading: false,
    horizontalRule: false,
    listItem: false,
    orderedList: false,
    bold: false,
    italic: false,
    strike: false,
  }),
]

/* For paragraphs */
const DisableEnter = Extension.create({
  addKeyboardShortcuts() {
    return {
      Enter: () => true,
    }
  },
})

export const useInsightsContentSuggestionEditor = (): Editor | null => {
  const editor = useEditor({
    extensions: [...BaseExtensions, DisableEnter, ...[InsightsContentSuggestions()]],
  })

  return editor
}
