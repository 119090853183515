import React, { ChangeEvent, useCallback } from 'react'
import {
  IdentitiesSelector,
  IdentitiesSelectorProps,
} from 'sierra-client/components/common/identities-selector'
import { getFlag } from 'sierra-client/config/global-config'
import { useTranslation } from 'sierra-client/hooks/use-translation'
import { AddDropdownAnchor, AddFilter } from 'sierra-client/lib/filter/components/add'
import { groupFilterInit } from 'sierra-client/lib/filter/components/filter-utils'
import { ReadOnlyFilter } from 'sierra-client/lib/filter/components/read-only-filter'
import { FilterRoot } from 'sierra-client/lib/filter/components/root'
import { UseFilter } from 'sierra-client/lib/filter/use-filter'
import { useGenerateFilter } from 'sierra-client/lib/tabular/components/util'
import { InfoIcon, TitleInput } from 'sierra-client/views/manage/components/common'
import { CoverImageOverlay } from 'sierra-client/views/manage/programs/components/cover-image-in-modal'
import { StartDateSelector } from 'sierra-client/views/manage/programs/enrollment-rules/modals/start-date-selector'
import { StartDateSelectorProps } from 'sierra-client/views/manage/programs/enrollment-rules/modals/start-date-selector/common'
import { AssetContext } from 'sierra-domain/asset-context'
import { color } from 'sierra-ui/color'
import { ColorName } from 'sierra-ui/color/types'
import { Panel } from 'sierra-ui/components'
import { Button, Heading, IconButton, Spacer, Switch, Text, View } from 'sierra-ui/primitives'
import { spacing, token } from 'sierra-ui/theming'
import { Spacing } from 'sierra-ui/types'
import styled, { css } from 'styled-components'

export const EnrollmentRuleWrapper = styled(View).attrs({
  radius: 'size-12',
  borderColor: 'border/default',
  padding: '16',
  gap: '16',
})`
  max-width: 75%;
`

const padding: Spacing = '16'

const HeaderBase = styled(View).attrs({ background: 'grey5', alignItems: 'stretch' })<{
  $background?: string
}>`
  position: relative;
  min-height: 150px;

  ${p =>
    p.$background !== undefined &&
    css`
      background: center / cover url(${p.$background});
    `}
`

const HeaderInner = styled(View).attrs({
  direction: 'column',
  justifyContent: 'space-between',
  padding,
})`
  position: relative;
  flex: 1;
`

const Close = styled(IconButton).attrs({ iconId: 'close' })`
  position: absolute;
  top: ${spacing[padding]};
  right: ${spacing[padding]};

  align-self: flex-end;
  background-color: ${color('black').opacity(0.1).toString()};

  &:hover {
    background-color: ${color('black').opacity(0.2).toString()};
  }
`

const HeaderTitle = styled(View).attrs({
  paddingLeft: padding,
  paddingRight: padding,
  paddingBottom: '6',
})``

const HeaderTitleInput = styled(TitleInput).attrs({
  background: 'transparent',
  noValueBorderColor: 'none',
  focusBorderColor: 'grey20',
})``

const StyledHeading = styled(Heading).attrs({ size: 'h4', bold: true })`
  flex: 1;
`

const ProgramHeader: React.FC<{
  children: React.ReactNode | React.ReactNode[]
  background?: string
  onClose?: () => void
}> = ({ children, background, onClose }) => {
  const color: ColorName = background !== undefined ? 'white' : 'black'
  const { t } = useTranslation()

  return (
    <HeaderBase $background={background}>
      {background !== undefined && <CoverImageOverlay />}
      <HeaderInner>
        <Spacer />
        <HeaderTitle>
          <StyledHeading color={color}>{children}</StyledHeading>
        </HeaderTitle>
        {onClose !== undefined && <Close tooltip={t('dictionary.close')} color={color} onClick={onClose} />}
      </HeaderInner>
    </HeaderBase>
  )
}

const ProgramHeaderEdit: React.FC<{
  title: string
  setTitle: (title: string) => void
  background?: string
  onClose?: () => void
}> = ({ title, setTitle, background, onClose }) => {
  const { t } = useTranslation()
  const onChange = useCallback((e: ChangeEvent<HTMLTextAreaElement>) => setTitle(e.target.value), [setTitle])
  const color: ColorName = background !== undefined ? 'white' : 'black'
  const placeholderColor: ColorName = background !== undefined ? 'white' : 'grey30'

  // TODO(seb): Add overlay

  return (
    <HeaderBase $background={background}>
      {background !== undefined && <CoverImageOverlay />}
      <HeaderInner>
        {onClose !== undefined && <Close tooltip={t('dictionary.close')} onClick={onClose} color={color} />}
        <Spacer />
        {/* To create space */}
        <HeaderTitle>
          <HeaderTitleInput
            color={color}
            placeholderColor={placeholderColor}
            value={title}
            onChange={onChange}
            placeholder={t('manage.programs.enrollment-rules.add-name')}
          />
        </HeaderTitle>
      </HeaderInner>
    </HeaderBase>
  )
}

const Vertical = styled(View).attrs({ direction: 'column' })``

const Content = styled(Vertical).attrs({
  paddingTop: '32',
  paddingBottom: '32',
  paddingLeft: '40',
  paddingRight: '40',
})``

const Footer = styled(View).attrs({
  paddingTop: 'none',
  paddingBottom: '32',
  paddingLeft: '40',
  paddingRight: '40',
  justifyContent: 'space-between',
  alignItems: 'center',
})`
  min-height: 90px;
`

const Headline = styled(Text).attrs({ bold: true })`
  line-height: 1;
`

const Explanation = styled(Text).attrs({ color: 'grey40' })``

const Trigger = styled(Vertical)``

const StartDate = styled(Vertical)``

const AddPeople = styled(Vertical)``

const FilterComponent = styled(View).attrs({ direction: 'row', wrap: 'wrap' })``

const ActiveRule = styled(View).attrs({ direction: 'row', gap: 'none' })``

const Line = styled.div`
  margin: ${spacing['24']} 0;
  border-bottom: 1px solid ${token('border/default')};
`

const EnrollModal = styled(Panel).attrs({
  size: { width: 750 },
  animation: 'slide-right',
  padding: 'none',
})``

const StartDateSection: React.FC<StartDateSelectorProps> = props => {
  const { t } = useTranslation()

  return (
    <StartDate>
      <Headline size='regular'>{t('manage.programs.enrollment-rules.start-date.title')}</Headline>
      <Explanation size='regular'>{t('manage.programs.enrollment-rules.start-date-description')}</Explanation>
      <Spacer size='6' />
      <View direction='row'>
        <StartDateSelector {...props} />
      </View>
    </StartDate>
  )
}

const TriggerSection: React.FC<{
  filter: UseFilter
  readonly?: boolean
  assetContext: AssetContext
}> = ({ filter, readonly = false, assetContext }) => {
  const { t } = useTranslation()
  const generateFiltersEnabled = getFlag('manage/generate-filters')

  const generateFilter = useGenerateFilter(filter.domain, newFilter => filter.ctx.update(() => newFilter))

  return (
    <Trigger>
      <Headline size='regular'>{t('manage.programs.enrollment-rules.trigger')}</Headline>
      <Explanation size='regular'>{t('manage.programs.enrollment-rules.trigger-explanation')}</Explanation>
      <Spacer size='6' />
      <FilterComponent>
        {readonly ? (
          <ReadOnlyFilter domainReps={filter.ctx.domainReps} filter={filter.filter} />
        ) : (
          <>
            <AddFilter
              ctx={filter.ctx}
              filter={filter.filter}
              filterInit={groupFilterInit}
              renderTrigger={() => <AddDropdownAnchor />}
              generateFilter={
                generateFiltersEnabled && filter.domain !== undefined ? generateFilter : undefined
              }
            />
            <FilterRoot
              ctx={filter.ctx}
              filter={filter.filter}
              generateFilter={
                generateFiltersEnabled && filter.domain !== undefined ? generateFilter : undefined
              }
              assetContext={assetContext}
            />
          </>
        )}
      </FilterComponent>
    </Trigger>
  )
}

const IdentitySection: React.FC<IdentitiesSelectorProps> = props => {
  const { t } = useTranslation()

  return (
    <AddPeople>
      <Headline size='regular'>{t('manage.programs.enrollment-rules.add-people')}</Headline>
      <IdentitiesSelector {...props} />
    </AddPeople>
  )
}

const ActiveRuleSection: React.FC<{
  readonly?: boolean
  enabled: boolean
  setEnabled?: (enabled: boolean) => void
}> = ({ readonly, enabled, setEnabled = () => {} }) => {
  const { t } = useTranslation()

  return (
    <ActiveRule>
      <Switch
        disabled={readonly}
        text={t('manage.programs.enrollment-rules.active')}
        onChange={() => setEnabled(!enabled)}
        checked={enabled}
      />
      <InfoIcon title={t('manage.programs.enrollment-rules.active-info')} />
    </ActiveRule>
  )
}

const DeleteBox = styled(View).attrs({
  direction: 'row',
  justifyContent: 'space-between',
  alignItems: 'flex-start',
  borderColor: 'grey5',
  radius: 'size-12',
  padding: '24',
})``

const DeleteInfo = styled(View).attrs({ direction: 'column' })`
  flex: 0.7;
`

const DeleteSubtitle = styled(Text).attrs({ size: 'small', bold: true })``

const DeleteDescription = styled(Text).attrs({ size: 'small', color: 'grey25' })``

const DeleteSection: React.FC<{ title: string; description: string; onDelete: () => void }> = ({
  title,
  description,
  onDelete,
}) => {
  const { t } = useTranslation()

  return (
    <DeleteBox>
      <DeleteInfo>
        <DeleteSubtitle color='LEGACY_DEFAULT_TEXT_COLOR_REPLACE_ASAP'>{title}</DeleteSubtitle>
        <DeleteDescription>{description}</DeleteDescription>
      </DeleteInfo>
      <Button variant='destructive' onClick={onDelete}>
        {t('manage.program.program-details.delete')}
      </Button>
    </DeleteBox>
  )
}

const AttributeInfo = styled(Text).attrs({ size: 'small', color: 'grey40' })`
  padding-left: ${spacing['8']};
`

const AttributeHighlight = styled(Text).attrs({ size: 'small', color: 'grey40' })`
  display: inline-block;
  background-color: ${color('grey10').toString()};
  padding: ${spacing['2']} ${spacing['6']};
  border-radius: ${p => p.theme.borderRadius['size-4']};
`

export const EnrollModalComponents = {
  EnrollModal,
  ProgramHeader,
  ProgramHeaderEdit,
  Content,
  Footer,
  AttributeInfo,
  AttributeHighlight,
  Line,
  TriggerSection,
  StartDateSection,
  IdentitySection,
  ActiveRuleSection,
  DeleteSection,
}
