import { useEffect } from 'react'
import { selectCurrentPathId } from 'sierra-client/state/content/selectors'
import { useDispatch, useSelector } from 'sierra-client/state/hooks'
import { selectIsLoggedIn } from 'sierra-client/state/user/user-selector'
import { fetchPathById } from 'sierra-client/state/v2/paths-actions'

// Fetch new path data whenever the current path changes
export const PathContentFetcher = (): null => {
  const dispatch = useDispatch()
  const currentPathId = useSelector(selectCurrentPathId)
  const isLoggedIn = useSelector(selectIsLoggedIn)

  useEffect(() => {
    if (!isLoggedIn) return

    if (currentPathId === undefined) return

    void dispatch(fetchPathById({ pathId: currentPathId }))
  }, [currentPathId, dispatch, isLoggedIn])

  return null
}
