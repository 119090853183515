import { spacing, SpacingProps, token } from 'sierra-ui/theming'
import { v2_breakpoint } from 'sierra-ui/theming/breakpoints'
import styled, { css } from 'styled-components'

export const Section = styled.div`
  margin: 4rem 0;
  width: 100%;
`

export const ShowcaseContainer = styled.div`
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
`

export const ShowcaseColumn = styled.div`
  flex-basis: 100%;
  max-width: 100%;

  @media screen and (min-width: ${v2_breakpoint.phone}) {
    flex-basis: calc(50% - 1rem);
    max-width: calc(50% - 1rem);
  }
`

export const Separator = styled.div<{ top?: SpacingProps; bottom?: SpacingProps }>`
  width: 100%;
  height: 1px;
  background-color: ${token('border/default')};
  ${p =>
    p.top &&
    css`
      margin-top: ${spacing[p.top]};
    `}

  ${p =>
    p.bottom &&
    css`
      margin-bottom: ${spacing[p.bottom]};
    `}

  ${p =>
    !p.bottom &&
    !p.top &&
    css`
      margin-top: 2rem;
      margin-bottom: 2rem;
    `}
`
