import { useCallback, useState } from 'react'
import { useNotif } from 'sierra-client/components/common/notifications'
import { useFakeProgress } from 'sierra-client/hooks/use-fake-progress'
import { usePost } from 'sierra-client/hooks/use-post'
import { useTranslation } from 'sierra-client/hooks/use-translation'
import {
  FileTooBigError,
  UploadMediaProgressHandler,
  UploadMediaProgressType,
  UploadMediaWithProgress,
} from 'sierra-client/views/v3-author/common/media-uploader/types'
import { PdfDataResponseCompleted } from 'sierra-domain/api/author-v2'
import { NanoId12 } from 'sierra-domain/api/nano-id'
import {
  XRealtimeAuthorPdfImportGetData,
  XRealtimeAuthorPdfImportGetGcsUrl,
  XRealtimeAuthorPdfImportStartProcessing,
} from 'sierra-domain/routes'
import { isDefined } from 'sierra-domain/utils'

export const MAX_PDF_SIZE_MB = 128
const MAX_PDF_SIZE_BYTES = MAX_PDF_SIZE_MB * 1024 * 1024

type PdfConvertStatus =
  | { status: 'idle' }
  | { status: 'uploading' }
  | { status: 'success'; images: string[] }
  | { status: 'error' }
export type UploadFileResult = { data: PdfDataResponseCompleted; pdfId: NanoId12 }
type UsePdfConvertToImages = {
  uploadPdfFile: UploadMediaWithProgress<UploadFileResult>
  status: PdfConvertStatus
}

export class PdfProcessingError extends Error {}

const useUploadWithProgress = (): ((
  url: string,
  file: Blob,
  onProgressUpdate: (progress: number) => void
) => Promise<void>) => {
  return useCallback((url, file, onProgressUpdate) => {
    return new Promise((resolve, reject) => {
      const xhr = new XMLHttpRequest()

      xhr.upload.addEventListener('progress', event => {
        if (event.lengthComputable) {
          const percentComplete = (event.loaded / event.total) * 100
          onProgressUpdate(percentComplete)
        }
      })
      xhr.addEventListener('load', () => {
        resolve()
      })
      xhr.addEventListener('error', () => {
        reject(new Error('File Upload Error'))
      })

      xhr.open('PUT', url, true)
      xhr.setRequestHeader('Content-Type', 'application/pdf')
      xhr.send(file)
    })
  }, [])
}

export const usePdfData = (): UsePdfConvertToImages => {
  const { postWithUserErrorException } = usePost()

  const [status, setStatus] = useState<PdfConvertStatus>({ status: 'idle' })
  const uploadWithProgress = useUploadWithProgress()

  const { startInfiniteProgress, finishProgress } = useFakeProgress({
    target: 100,
    finishDuration: 200,
  })

  const handleProgressUpdate = useCallback(
    (type: UploadMediaProgressType, handler?: UploadMediaProgressHandler) => (progress: number) => {
      if (isDefined(handler)) {
        handler(type, progress)
      }
    },
    []
  )

  const notif = useNotif()
  const { t } = useTranslation()

  const uploadPdfFile = useCallback<UsePdfConvertToImages['uploadPdfFile']>(
    async (blob, assetContext, onProgressUpdate) => {
      if (blob.size > MAX_PDF_SIZE_BYTES) {
        notif.push({
          type: 'error',
          body: t('manage.media-library.file-too-large', { size: MAX_PDF_SIZE_MB }),
        })
        throw new FileTooBigError(`File too large (max size ${MAX_PDF_SIZE_MB} MB)`)
      }

      setStatus({ status: 'uploading' })

      const { url, pdfId } = await postWithUserErrorException(XRealtimeAuthorPdfImportGetGcsUrl, {})

      await uploadWithProgress(url, blob, handleProgressUpdate('uploading', onProgressUpdate))

      // Use fake progress while processing the file on the backend.
      startInfiniteProgress(handleProgressUpdate('processing', onProgressUpdate))

      console.debug('[usePdfConvertImages] upload done, starting processing...')
      await postWithUserErrorException(XRealtimeAuthorPdfImportStartProcessing, {
        pdfId,
        originalFileName: blob instanceof File ? blob.name : undefined,
        courseId: assetContext.type === 'course' ? assetContext.courseId : undefined,
      })

      let data: PdfDataResponseCompleted | undefined = undefined

      // PDF processing will take at least 1 second, so wait 1 second before calling the endpoint
      await new Promise(r => setTimeout(r, 1000))

      while (data === undefined) {
        const dataResponse = await postWithUserErrorException(XRealtimeAuthorPdfImportGetData, {
          pdfId,
        })

        if (dataResponse.type === 'completed') {
          data = dataResponse
        } else if (dataResponse.type === 'error') {
          setStatus({ status: 'error' })
          throw new PdfProcessingError()
        }

        // wait for 250 milliseconds before calling the endpoint again
        await new Promise(r => setTimeout(r, 250))
      }

      await finishProgress(handleProgressUpdate('processing', onProgressUpdate))

      return { pdfId, data }
    },
    [
      finishProgress,
      handleProgressUpdate,
      postWithUserErrorException,
      startInfiniteProgress,
      uploadWithProgress,
      notif,
      t,
    ]
  )

  return { status, uploadPdfFile }
}
