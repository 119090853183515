import { useCallback } from 'react'
import { getGlobalRouter } from 'sierra-client/router'
import { selectCurrentPathId, selectCurrentProgramId } from 'sierra-client/state/content/selectors'
import { useSelector } from 'sierra-client/state/hooks'
import { FileId } from 'sierra-domain/flexible-content/identifiers'
import { isDefined } from 'sierra-ui/utils/is-defined'

type PathComponents = {
  fileId?: string
  flexibleContentId: string
  programId?: string
  courseGroupId?: string
  pathId?: string
  skipCourseOverview?: boolean
}
export const buildSelfPacedUrlPath = ({
  fileId,
  flexibleContentId,
  programId,
  courseGroupId,
  pathId,
  skipCourseOverview,
}: PathComponents): string => {
  const prefix = [
    programId !== undefined ? `/program/${programId}` : '',
    pathId !== undefined ? `/path/${pathId}` : '',
  ].join('')

  // Show course group rather than starting if courseGroupId is defined
  if (isDefined(courseGroupId)) {
    return `${prefix}/course/${courseGroupId}`
  }

  return `${prefix}/s/${flexibleContentId}/${fileId ?? (skipCourseOverview === true ? 'next' : '')}`
}

type NavigateFn = (fileId: FileId, opts?: { replace?: boolean }) => void

type NavigatorOpts = {
  flexibleContentId: string
}

export const useNavigate = ({ flexibleContentId }: NavigatorOpts): NavigateFn => {
  const currentPathId = useSelector(selectCurrentPathId)
  const currentProgramId = useSelector(selectCurrentProgramId)

  const navigate = useCallback(
    (fileId: FileId, { replace }: { replace?: boolean } = { replace: false }): void => {
      const path = buildSelfPacedUrlPath({
        fileId,
        flexibleContentId,
        programId: currentProgramId,
        pathId: currentPathId,
      })

      void getGlobalRouter().navigate({ to: path, replace })
    },

    [currentPathId, currentProgramId, flexibleContentId]
  )

  return navigate
}
