import React, { useCallback, useContext, useMemo } from 'react'
import { useDispatch } from 'sierra-client/state/hooks'
import { submitBlockInteraction } from 'sierra-client/state/self-paced/actions'
import { FCC } from 'sierra-client/types'
import { useFileContext } from 'sierra-client/views/flexible-content/file-context'
import {
  PlacementTests,
  usePlacementTestContext,
} from 'sierra-client/views/self-paced/placement-test/placement-test-context'
import { Reviews, useReviewContext } from 'sierra-client/views/self-paced/review/review-context'
import { EditorMode } from 'sierra-client/views/v3-author/slate'
import { EmbedInteraction } from 'sierra-domain/api/block-interaction'
import { Entity } from 'sierra-domain/entity'
import { Embed as EmbedBlock } from 'sierra-domain/v3-author'

// export type PollData = Record<string, Record<string, string>>

type EmbedDataLayer = {
  submitOutcome: (outcome: Omit<EmbedInteraction, 'timestamp'>) => void
}

const LiveContext = React.createContext<EmbedDataLayer | undefined>(undefined)
const SelfPacedContext = React.createContext<EmbedDataLayer | undefined>(undefined)
const CreateContext = React.createContext<EmbedDataLayer | undefined>(undefined)
const ReviewContext = React.createContext<EmbedDataLayer | undefined>(undefined)
const PlacementTestContext = React.createContext<EmbedDataLayer | undefined>(undefined)
const RecapContext = React.createContext<EmbedDataLayer | undefined>(undefined)

type EmbedBlockProvider = FCC<{ element: Entity<EmbedBlock> }>

const LiveDataProvider: EmbedBlockProvider = ({ children }) => {
  const submitOutcome: EmbedDataLayer['submitOutcome'] = useCallback(() => {}, [])
  const dataLayer: EmbedDataLayer = useMemo(() => ({ submitOutcome }), [submitOutcome])
  return <LiveContext.Provider value={dataLayer}>{children}</LiveContext.Provider>
}

const SelfPacedDataProvider: EmbedBlockProvider = ({ children }) => {
  const dispatch = useDispatch()
  const { file, flexibleContentId } = useFileContext()
  const fileId = file.id

  const dataLayer: EmbedDataLayer = useMemo(
    () => ({
      submitOutcome: outcome => {
        void dispatch(
          submitBlockInteraction({
            flexibleContentId,
            fileId,
            interactionContext: 'course',
            interaction: {
              type: 'embed-interaction',
              blockId: outcome.blockId,
              correct: outcome.correct,
              timestamp: new Date(),
            },
          })
        )
      },
    }),
    [dispatch, flexibleContentId, fileId]
  )

  return <SelfPacedContext.Provider value={dataLayer}>{children}</SelfPacedContext.Provider>
}

const CreateDataProvider: EmbedBlockProvider = ({ children }) => {
  const submitOutcome: EmbedDataLayer['submitOutcome'] = useCallback(() => {}, [])
  const dataLayer: EmbedDataLayer = useMemo(() => ({ submitOutcome }), [submitOutcome])
  return <CreateContext.Provider value={dataLayer}>{children}</CreateContext.Provider>
}

const ReviewDataProvider: EmbedBlockProvider = ({ children }) => {
  const { state, setState } = useReviewContext()

  const dataLayer: EmbedDataLayer = useMemo(
    () => ({
      submitOutcome: async outcome => {
        const updatedState = Reviews.updateResponse(state, { type: 'block', correct: outcome.correct })
        setState(await Reviews.submitEmbedResponse(updatedState))
      },
    }),
    [state, setState]
  )

  return <ReviewContext.Provider value={dataLayer}>{children}</ReviewContext.Provider>
}

const PlacementTestDataProvider: EmbedBlockProvider = ({ children }) => {
  const { state, setState } = usePlacementTestContext()

  const dataLayer: EmbedDataLayer = useMemo(
    () => ({
      submitOutcome: async outcome => {
        const updatedState = PlacementTests.updateResponse(state, { type: 'block', correct: outcome.correct })
        setState(await PlacementTests.submitEmbedResponse(updatedState))
      },
    }),
    [state, setState]
  )

  return <PlacementTestContext.Provider value={dataLayer}>{children}</PlacementTestContext.Provider>
}

const FallbackDataProvider: EmbedBlockProvider = ({ children }) => {
  return <RecapContext.Provider value={{ submitOutcome: () => {} }}>{children}</RecapContext.Provider>
}

const modeToProvider: Record<EditorMode, EmbedBlockProvider | undefined> = {
  'live': LiveDataProvider,
  'self-paced': SelfPacedDataProvider,
  'create': CreateDataProvider,
  'review': ReviewDataProvider,
  'placement-test': PlacementTestDataProvider,
  'recap': undefined,
  'template': undefined,
  'version-history': undefined,
}

export const EmbedDataProvider: FCC<{ element: Entity<EmbedBlock>; mode: EditorMode }> = ({
  mode,
  element,
  children,
}) => {
  const Provider = modeToProvider[mode] ?? FallbackDataProvider

  return <Provider element={element}>{children}</Provider>
}

export const useEmbedData = (): EmbedDataLayer | undefined => {
  const liveData = useContext(LiveContext)
  const selfPacedData = useContext(SelfPacedContext)
  const createData = useContext(CreateContext)
  const reviewData = useContext(ReviewContext)
  const placementTestData = useContext(PlacementTestContext)

  return liveData ?? selfPacedData ?? createData ?? reviewData ?? placementTestData
}
