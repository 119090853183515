import Ably from 'ably'
import { getAblyAuthParameters } from 'sierra-client/realtime-data/utils/authenticate'
import { UserId } from 'sierra-domain/api/uuid'

let currentClientId: UserId | undefined = undefined
let client: Ably.Realtime | undefined = undefined
export const getAblyClient = ({ userId }: { userId: UserId | undefined }): Ably.Realtime => {
  if (client) {
    /**
     * The backend will set the clientId in the token to be the principal id. If the two don't match we
     * will get an error. Therefore we'll make sure to reset the client if the userId changes.
     */
    if (currentClientId === undefined) {
      currentClientId = userId
      return client
    } else if (currentClientId === userId) {
      return client
    }

    client.close()
  }

  /**
   * Disable the mechanism that resends recent messages to clients that reconnect. We don't need this for
   * our Yjs binding since we have our own resume mechanism. The fewer Ably features we rely on the easier
   * our system is to reason around and the easier it gets to replace Ably if we need to.
   *
   * This is an undocumented features, got info from Ably support.
   */
  const undocumentedOptions = { resumable: false } as Partial<Ably.ClientOptions>

  currentClientId = userId
  client = new Ably.Realtime({
    echoMessages: false,
    useBinaryProtocol: false,
    autoConnect: false,

    transportParams: {
      remainPresentFor: '1000',
      heartbeatInterval: '10000',
    },

    ...undocumentedOptions,
    ...getAblyAuthParameters(),
  })

  return client
}
