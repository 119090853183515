import { produce } from 'immer'
import React, { useCallback, useMemo, useState } from 'react'
import { DeleteBox } from 'sierra-client/components/common/delete-box'
import { ActionModal } from 'sierra-client/components/common/modals/action-modal'
import { useNotif } from 'sierra-client/components/common/notifications'
import { useHasOrganizationPermission } from 'sierra-client/hooks/use-permissions'
import { useToggle } from 'sierra-client/hooks/use-toggle'
import { useTranslation } from 'sierra-client/hooks/use-translation'
import { labelToString } from 'sierra-client/lib/filter/components/common'
import { getGlobalRouter } from 'sierra-client/router'
import { PhoneNumberInput } from 'sierra-client/views/manage/components/phone-number-input'
import { FooterWrapper } from 'sierra-client/views/manage/components/user-attributes/flows/components/layout'
import {
  isInvalidEmail,
  useEmailVerification,
} from 'sierra-client/views/manage/components/user-attributes/flows/invite-users/tabs/invite-via-email/email-auto-complete/utils'
import { useInvitationDomains } from 'sierra-client/views/manage/components/user-attributes/hooks/use-invitation-domains'
import { AccessLevelDropdown } from 'sierra-client/views/manage/users/components/user-settings-panel/general-tab/access-level-dropdown'
import { AccessRoleDropdown } from 'sierra-client/views/manage/users/components/user-settings-panel/general-tab/access-role-dropdown'
import { useGeneralSettingsDraft } from 'sierra-client/views/manage/users/components/user-settings-panel/general-tab/use-general-settings-draft'
import { UserStatusDropdown } from 'sierra-client/views/manage/users/components/user-settings-panel/general-tab/user-status-dropdown'
import { UserSettingsPanelProps } from 'sierra-client/views/manage/users/components/user-settings-panel/types'
import { useUserDetails } from 'sierra-client/views/manage/users/manage-user-details/hooks/use-user-details'
import { FormElement } from 'sierra-ui/components'
import { FormDescriptor } from 'sierra-ui/missions/workflows/panels/form-elements/form-descriptor'
import { Button, InputPrimitive, Spacer, View } from 'sierra-ui/primitives'
import styled from 'styled-components'

const StretchHorizontally = styled(View).attrs({ direction: 'column', grow: true })``
const StatusSection = styled(View).attrs({ direction: 'column' })``
const StatusList = styled(View).attrs({ direction: 'column', gap: '16' })``
const NameFormSection = styled(View).attrs({ direction: 'row', gap: '16' })``

const EmailEdit: React.FC = () => {
  const { t } = useTranslation()
  const {
    generalSettingsDraft,
    initialGeneralSettings,
    getErrors,
    updateErrors,
    setGeneralSettingsDraft,
    readOnlyMetadata,
    readOnlyAttributes,
  } = useGeneralSettingsDraft()

  const canUpdate = !readOnlyMetadata && !readOnlyAttributes

  const { getEmailErrorMessage, validateEmails } = useEmailVerification()

  const latestEmailError = getErrors('incorrect-email')[0] ?? getErrors('email-rate-limit')[0]

  const captureEmailErrors = useCallback(async () => {
    const emailErrors = await validateEmails([generalSettingsDraft.email])
    const emailErrorReason = emailErrors.find(isInvalidEmail)?.reason

    if (
      emailErrorReason === undefined ||
      (emailErrorReason === 'used' && initialGeneralSettings.email === generalSettingsDraft.email)
    ) {
      return updateErrors('incorrect-email', [])
    }

    updateErrors('incorrect-email', [{ type: 'incorrect-email', reason: emailErrorReason }])
  }, [generalSettingsDraft.email, initialGeneralSettings.email, updateErrors, validateEmails])

  const helper = useMemo(() => {
    if (readOnlyAttributes) {
      return t('manage.users.user-settings.sso-synced')
    }

    if (readOnlyMetadata) {
      return t('dictionary.no-edit-access')
    }

    if (latestEmailError?.type === 'incorrect-email') {
      return getEmailErrorMessage(latestEmailError.reason)
    }

    if (latestEmailError?.type === 'email-rate-limit') {
      return t('manage.users.user-settings.email-rate-limit.info', { time: latestEmailError.rateLimit })
    }

    return undefined
  }, [readOnlyMetadata, readOnlyAttributes, getEmailErrorMessage, latestEmailError, t])

  return (
    <FormElement label={t('dictionary.email')} isError={latestEmailError !== undefined} helper={helper}>
      <InputPrimitive
        disabled={!canUpdate}
        value={generalSettingsDraft.email}
        onBlur={() => {
          void captureEmailErrors()
        }}
        onChange={async e => {
          const email = e.target.value
          setGeneralSettingsDraft(
            produce(generalSettingsDraft, edit => {
              edit.email = email
            })
          )
          updateErrors('incorrect-email', [])
          updateErrors('email-rate-limit', [])
        }}
      />
    </FormElement>
  )
}

const NameEdit: React.FC = () => {
  const { t } = useTranslation()
  const { generalSettingsDraft, setGeneralSettingsDraft, readOnlyMetadata, readOnlyAttributes } =
    useGeneralSettingsDraft()

  const canUpdateName = !readOnlyMetadata && !readOnlyAttributes

  return (
    <NameFormSection>
      <FormElement
        label={t('manage.users.user-settings.name-edit.first-name')}
        helper={
          readOnlyAttributes
            ? t('manage.users.user-settings.sso-synced')
            : readOnlyMetadata
              ? t('dictionary.no-edit-access')
              : undefined
        }
      >
        <InputPrimitive
          disabled={!canUpdateName}
          value={generalSettingsDraft.firstName}
          onChange={e => {
            const firstName = e.target.value
            setGeneralSettingsDraft(
              produce(generalSettingsDraft, edit => {
                edit.firstName = firstName
              })
            )
          }}
        />
      </FormElement>
      <FormElement
        label={t('manage.users.user-settings.name-edit.last-name')}
        helper={
          readOnlyAttributes
            ? t('manage.users.user-settings.sso-synced')
            : readOnlyMetadata
              ? t('dictionary.no-edit-access')
              : undefined
        }
      >
        <InputPrimitive
          disabled={!canUpdateName}
          value={generalSettingsDraft.lastName}
          onChange={e => {
            const lastName = e.target.value
            setGeneralSettingsDraft(
              produce(generalSettingsDraft, edit => {
                edit.lastName = lastName
              })
            )
          }}
        />
      </FormElement>
    </NameFormSection>
  )
}

const PhoneNumbersRow: React.FC = () => {
  const { phoneNumberDomain } = useInvitationDomains()
  const [validationError, setValidationError] = useState<string | undefined>(() => undefined)
  const { t, dynamicT } = useTranslation()
  const { generalSettingsDraft, setGeneralSettingsDraft, readOnlyAttributes, getErrors, updateErrors } =
    useGeneralSettingsDraft()

  const phoneNumberError = getErrors('phone-already-exists')[0]

  if (phoneNumberDomain === undefined) {
    return <></>
  }

  return (
    <FormElement
      isError={validationError !== undefined || phoneNumberError !== undefined}
      label={labelToString(phoneNumberDomain.label, dynamicT)}
      helper={phoneNumberError ? t('user-error.auth.phone-already-exists') : validationError}
    >
      <PhoneNumberInput
        value={generalSettingsDraft.phoneNumber}
        onChange={number => {
          setGeneralSettingsDraft(
            produce(generalSettingsDraft, edit => {
              edit.phoneNumber = number ?? ''
            })
          )
          if (phoneNumberError) {
            return updateErrors('phone-already-exists', [])
          }
        }}
        disabled={readOnlyAttributes || !phoneNumberDomain.editableInternally}
        onValidationError={err => setValidationError(err)}
        phoneNumberDomain={phoneNumberDomain}
      />
    </FormElement>
  )
}

const ScrollContent = styled(View).attrs({ direction: 'column' })`
  flex: 1 0 0; /* Equals max-height, makes sure we scroll here, not on the panel level */
  overflow-x: hidden;
  padding-bottom: 16px;
`

export const GeneralTab: React.FC<UserSettingsPanelProps> = ({
  userId,
  refetch,
  openDiscardAlert,
  closeDirectly,
}) => {
  const { t } = useTranslation()
  const [isSaving, setIsSaving] = useState<boolean>(false)
  const { hasChanged, hasErrors, saveSettings } = useGeneralSettingsDraft()
  const [alertDeleteOpen, { on: openAlertDelete, off: closeAlertDelete }] = useToggle(false)
  const { deleteUser } = useUserDetails()
  const notification = useNotif()
  const canDelete = useHasOrganizationPermission('DELETE_ADMINISTERED_USER')
  const { accessRoleDomain } = useInvitationDomains()
  const accessRoleEnabled = accessRoleDomain !== undefined

  const onCancel = useCallback(() => {
    if (hasChanged) {
      openDiscardAlert(true)
    } else {
      closeDirectly()
    }
  }, [hasChanged, openDiscardAlert, closeDirectly])

  const onSave = useCallback(async () => {
    setIsSaving(true)
    const result = await saveSettings(userId)
    if (result === 'OK') {
      await refetch()
      closeDirectly()
      setIsSaving(false)

      notification.push({
        type: 'custom',
        level: 'success',
        body: t('manage.users.invite.user-settings-updated'),
      })
    } else {
      setIsSaving(false)
    }
  }, [saveSettings, userId, refetch, closeDirectly, notification, t])

  return (
    <>
      <Spacer size='16' />
      <StretchHorizontally paddingRight='16'>
        <ScrollContent>
          <StatusSection>
            <StatusList>
              <FormDescriptor
                label={t('manage.users.user-settings.access-level-title')}
                description={t('manage.users.user-settings.access-level-description')}
                iconId='education'
                formElement={<AccessLevelDropdown />}
              />

              {accessRoleEnabled && (
                <FormDescriptor
                  label={t('manage.users.user-settings.access-role-title')}
                  description={t('manage.users.user-settings.access-role-description')}
                  iconId='education'
                  formElement={<AccessRoleDropdown userAccessRoleDomainRep={accessRoleDomain} />}
                />
              )}
              <FormDescriptor
                label={t('manage.users.user-settings.status-title')}
                description={t('manage.users.user-settings.status-description')}
                iconId='checkmark--outline'
                formElement={<UserStatusDropdown />}
              />
            </StatusList>
          </StatusSection>
          <Spacer size='24' />
          <View direction='column' gap='24'>
            <NameEdit />
            <EmailEdit />
            <PhoneNumbersRow />
          </View>

          <View grow />
          {canDelete && (
            <DeleteBox
              title={t('manage.users.user-settings.delete-title')}
              description={t('manage.users.user-settings.delete-description')}
              deleteLabel={t('dictionary.delete')}
              onDelete={openAlertDelete}
            />
          )}
        </ScrollContent>
        <FooterWrapper>
          <Button disabled={isSaving} variant='secondary' onClick={onCancel}>
            {t('dictionary.cancel')}
          </Button>
          <Button loading={isSaving} disabled={!hasChanged || hasErrors} onClick={onSave}>
            {t('dictionary.save')}
          </Button>
        </FooterWrapper>
      </StretchHorizontally>
      <ActionModal
        open={alertDeleteOpen}
        onClose={closeAlertDelete}
        primaryAction={async (): Promise<void> => {
          await deleteUser(userId)
          notification.push({ type: 'user-deleted' })
          closeDirectly()
          void getGlobalRouter().navigate({ to: '/manage/users' })
        }}
        primaryActionLabel={t('admin.organization.learners.delete-user')}
        deleteAction
      >
        {t('admin.organization.learners.delete-user-message')}
      </ActionModal>
    </>
  )
}
