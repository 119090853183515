import React from 'react'
import { PolarisEditorProviderValue } from 'sierra-client/views/flexible-content/polaris-editor-provider/polaris-editor-context'
import { usePolarisContext } from 'sierra-client/views/flexible-content/polaris-editor-provider/polaris-editor-provider'
import { Debug } from 'sierra-client/views/learner/components/debug'
import { SanaEditor } from 'sierra-domain/v3-author'

function usePolarisEditor(): PolarisEditorProviderValue['editor'] {
  return usePolarisContext().editor
}

export function WaitForEditor({
  children,
}: {
  children: (editor: SanaEditor) => React.ReactNode
}): JSX.Element {
  const editor = usePolarisEditor()
  if (editor.type === 'available') return <>{children(editor.value)}</>
  else return <Debug>[Debug] Editor unavailable, {editor.reason}</Debug>
}
