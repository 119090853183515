import React, { useCallback, useMemo, useState } from 'react'
import { useToggle } from 'sierra-client/hooks/use-toggle'
import { useTranslation } from 'sierra-client/hooks/use-translation'
import { useOverrideIntercomLauncherVisibility } from 'sierra-client/intercom/intercom-visibility'
import { DiscardAlert } from 'sierra-client/views/manage/components/alerts/discard-alert'
import {
  PanelHeadline,
  PanelLayout,
} from 'sierra-client/views/manage/components/user-attributes/flows/components/layout'
import { MissingMandatoryAttributesUserDetailAlert } from 'sierra-client/views/manage/components/user-attributes/flows/user-attribute-settings/alerts/missing-mandatory-attributes-user-detail-alert'
import { useUserAttributesDraft } from 'sierra-client/views/manage/components/user-attributes/flows/user-attribute-settings/hooks/use-user-attributes-draft'
import { EditAttributesTab } from 'sierra-client/views/manage/components/user-attributes/flows/user-attribute-settings/panels/edit-attributes-tab'
import { GeneralTab } from 'sierra-client/views/manage/users/components/user-settings-panel/general-tab/general-tab'
import { useGeneralSettingsDraft } from 'sierra-client/views/manage/users/components/user-settings-panel/general-tab/use-general-settings-draft'
import { UseUserDetailData } from 'sierra-client/views/manage/users/manage-user-details/hooks/use-user-detail-data'
import { UserId } from 'sierra-domain/api/uuid'
import { Panel, Tabs } from 'sierra-ui/components'
import { IconButton, Spacer, View } from 'sierra-ui/primitives'
import styled from 'styled-components'

const StyledTabs = styled(Tabs)`
  display: flex;
  flex-direction: column;

  & div[role='tabpanel'][data-state='active'] {
    display: flex;
    flex-direction: column;
    flex: 1;
  }
`

export const UserSettingsPanel: React.FC<{
  isOpen: boolean
  canEdit: boolean
  userId: UserId
  refetch: UseUserDetailData['refetch'] /* TODO: Instead of passing this, we should rewrite useUserDetailsData hook; possibly move the data to an atom? */
  closeDirectly: () => void
}> = ({ isOpen, userId, refetch, closeDirectly, canEdit }) => {
  const { t } = useTranslation()
  const [tabId, setTabId] = useState('general')
  const [nextTabId, setNextTabId] = useState<string | undefined>()
  const [alertDiscardOpen, { on: openDiscardAlertToggle, off: closeDiscardAlert }] = useToggle(false)
  const { hasChanged: hasChangedUserAttributes, resetDraft: resetUserAttributesDraft } =
    useUserAttributesDraft()
  const { hasChanged: hasChangedGeneralSettings, resetGeneralSettingsDraft } = useGeneralSettingsDraft()
  const [validationFailed, setValidationFailed] = useState(false)
  const [shouldClosePanel, setShouldClosePanel] = useState<boolean>(true)

  useOverrideIntercomLauncherVisibility(isOpen ? 'hidden' : 'default')

  const openDiscardAlert = useCallback(
    (closePanel: boolean) => {
      setShouldClosePanel(closePanel)
      openDiscardAlertToggle()
    },
    [openDiscardAlertToggle]
  )

  const haveTabsChanged = useMemo(
    () => hasChangedUserAttributes || hasChangedGeneralSettings,
    [hasChangedUserAttributes, hasChangedGeneralSettings]
  )

  const closeWithValidation = useCallback(() => {
    if (haveTabsChanged) {
      openDiscardAlert(true)
    } else {
      closeDirectly()
      resetUserAttributesDraft()
      resetGeneralSettingsDraft()
    }
  }, [haveTabsChanged, openDiscardAlert, closeDirectly, resetUserAttributesDraft, resetGeneralSettingsDraft])

  return (
    <>
      <Panel
        size={{ width: 650 }}
        animation='slide-right'
        padding='none'
        onClose={closeWithValidation}
        open={isOpen}
      >
        <PanelLayout padding='32' paddingRight='16' paddingBottom='none'>
          <View>
            <View grow>
              <PanelHeadline avoidHanging={false}>
                {canEdit ? t('manage.users.user-settings.title') : t('dictionary.details')}
              </PanelHeadline>
            </View>
            <IconButton
              iconId='close'
              tooltip={t('dictionary.close')}
              variant='transparent'
              onClick={closeWithValidation}
            />
          </View>
          <Spacer size='16' />
          <StyledTabs
            hideLine
            value={tabId}
            onChange={tabId => {
              if (haveTabsChanged) {
                openDiscardAlert(false)
                setNextTabId(tabId)
              } else {
                setTabId(tabId)
              }
            }}
            items={[
              {
                id: 'general',
                label: t('manage.users.user-settings.tabs.general'),
                content: (
                  <GeneralTab
                    userId={userId}
                    refetch={refetch}
                    closeDirectly={closeDirectly}
                    openDiscardAlert={openDiscardAlert}
                    canEdit={canEdit}
                  />
                ),
              },
              {
                id: 'attributes',
                label: t('manage.users.user-settings.tabs.attributes'),
                content: (
                  <EditAttributesTab
                    userId={userId}
                    refetch={refetch}
                    closeDirectly={closeDirectly}
                    openDiscardAlert={openDiscardAlert}
                    canEdit={canEdit}
                  />
                ),
              },
            ]}
          />
        </PanelLayout>
      </Panel>
      <MissingMandatoryAttributesUserDetailAlert
        open={validationFailed}
        onAbort={() => setValidationFailed(false)}
      />
      <DiscardAlert
        open={alertDiscardOpen}
        onClose={closeDiscardAlert}
        onConfirm={() => {
          closeDiscardAlert()
          resetUserAttributesDraft()
          resetGeneralSettingsDraft()

          // If called from the panel, we should close it. Otherwise it's called from switching tabs and we don't
          if (shouldClosePanel) {
            closeDirectly()
          }

          if (nextTabId !== undefined) setTabId(nextTabId)
        }}
      />
    </>
  )
}
