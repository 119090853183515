import { useState } from 'react'
import { forceRefreshQueryAnalyticsData } from 'sierra-client/features/insights/api-hooks/use-query-analytics-data'
import { getWidgetIcon } from 'sierra-client/features/insights/display-widgets/utils'
import { EditableWidgetTitle } from 'sierra-client/features/insights/editable-widget-title'
import {
  insightsWidgetDeletedLogger,
  insightsWidgetDuplicateLogger,
} from 'sierra-client/features/insights/logger'
import { useTranslation } from 'sierra-client/hooks/use-translation'
import { useDispatch } from 'sierra-client/state/hooks'
import { DashboardWidget } from 'sierra-domain/api/insights'
import { assertNever } from 'sierra-domain/utils'
import { Icon, MenuItem, Tooltip } from 'sierra-ui/components'
import { View } from 'sierra-ui/primitives'
import { IconMenu } from 'sierra-ui/primitives/menu-dropdown'
import { token } from 'sierra-ui/theming'
import styled from 'styled-components'
import { WidgetSubtitle } from './widget-subtitle'

const WidgetCardHeaderGroup = styled(View)`
  display: flex;
  flex-direction: row;
  align-self: flex-start;
  align-items: center;
  opacity: 0; /* Triggered by hover on Container */
  transition: opacity 0.1s ease-in-out;
`

const Container = styled.div`
  display: flex;
  justify-content: space-between;
  flex: 1;
  padding-top: 16px;
  padding-left: 24px;
  padding-right: 16px;
  padding-bottom: 12px;
  transition: background-color 0.1s ease-in-out;
  background: ${token('surface/default')};

  cursor: pointer;

  &:hover,
  &:focus-within {
    background: ${token('surface/soft')};

    ${WidgetCardHeaderGroup} {
      opacity: 1;
    }
  }

  &:active {
    cursor: grabbing;
  }
`

const StopClickPropagation = styled.div`
  display: flex;
  align-items: center;
`

type EditableHeaderProps = {
  dashboardWidget: DashboardWidget
  onSelect?: (dashboardWidget: DashboardWidget) => void
  onRemove?: (dashboardWidget: DashboardWidget) => void
  onDuplicate?: (dashboardWidget: DashboardWidget) => void
  onTitleChange?: (widgetId: string, newTitle: string, automatic: boolean) => void
  dashboardFiltersApplicableToWidget: boolean
}

type MenuActions = 'open' | 'rename' | 'refresh' | 'duplicate' | 'delete'

export const EditableHeader: React.FC<EditableHeaderProps> = ({
  dashboardWidget,
  onTitleChange,
  onSelect,
  onRemove,
  onDuplicate,
  dashboardFiltersApplicableToWidget,
}) => {
  const { t } = useTranslation()
  const dispatch = useDispatch()

  const [titleIsEditable, setTitleIsEditable] = useState(false)

  const onMenuSelect = (item: MenuItem<MenuActions>): void => {
    switch (item.id) {
      case 'open':
        onSelect?.(dashboardWidget)
        return
      case 'rename':
        setTitleIsEditable(true)
        return
      case 'refresh':
        void forceRefreshQueryAnalyticsData(dashboardWidget.widget)
        return
      case 'duplicate':
        onDuplicate?.(dashboardWidget)
        void dispatch(
          insightsWidgetDuplicateLogger({
            dashboardId: dashboardWidget.id,
            widgetType: dashboardWidget.widget.type,
          })
        )
        return
      case 'delete':
        onRemove?.(dashboardWidget)
        void dispatch(
          insightsWidgetDeletedLogger({
            dashboardId: dashboardWidget.id,
            widgetId: dashboardWidget.id,
            widgetType: dashboardWidget.widget.type,
          })
        )
        return
      default:
        assertNever(item.id)
    }
  }

  const menuItems: MenuItem<MenuActions>[] = [
    {
      type: 'label',
      id: 'open',
      label: t('dictionary.open'),
      icon: 'maximize',
      disabled: onSelect === undefined,
    },
    {
      type: 'label',
      id: 'rename',
      label: t('admin.rename'),
      icon: 'edit',
      disabled: onTitleChange === undefined,
    },
    {
      type: 'label',
      id: 'refresh',
      label: t('dictionary.refresh'),
      icon: 'restart',
    },
    {
      type: 'label',
      id: 'duplicate',
      label: t('dictionary.duplicate'),
      icon: 'duplicate',
      disabled: onDuplicate === undefined,
    },
    {
      type: 'label',
      id: 'delete',
      label: t('dictionary.delete'),
      icon: 'trash-can',
      color: 'destructive/background',
      disabled: onRemove === undefined,
    },
  ]
  return (
    <Container
      className='react-grid-layout-drag-handle' // If this is removed you can no longer drag the component inside react-grid-layout
    >
      <View direction='column' gap='8'>
        <View alignItems='flex-start'>
          <Icon
            iconId={
              dashboardFiltersApplicableToWidget
                ? getWidgetIcon(dashboardWidget.widget.type)
                : 'warning--filled'
            }
            color={dashboardFiltersApplicableToWidget ? 'foreground/primary' : 'warning/background'}
          />
          <StopClickPropagation onMouseDown={e => e.stopPropagation()} onMouseUp={e => e.stopPropagation()}>
            <EditableWidgetTitle
              isEditable={titleIsEditable}
              setIsEditable={onTitleChange !== undefined ? setTitleIsEditable : () => {}}
              initialTitle={dashboardWidget.title}
              onChange={newTitle => onTitleChange?.(dashboardWidget.id, newTitle, false)}
              color={dashboardFiltersApplicableToWidget ? 'foreground/primary' : 'warning/background'}
              editLocation='widget'
            />
          </StopClickPropagation>
        </View>
        <WidgetSubtitle widget={dashboardWidget.widget} />
      </View>

      <StopClickPropagation onMouseDown={e => e.stopPropagation()} onMouseUp={e => e.stopPropagation()}>
        <Tooltip title={t('manage.insights.widget.menu.tooltip')}>
          <WidgetCardHeaderGroup>
            {menuItems.every(it => it.disabled ?? false) ? null : (
              <IconMenu
                iconId='overflow-menu--vertical'
                menuItems={menuItems}
                variant='transparent'
                onSelect={onMenuSelect}
              />
            )}
          </WidgetCardHeaderGroup>
        </Tooltip>
      </StopClickPropagation>
    </Container>
  )
}
