import { useState } from 'react'
import { Auth } from 'sierra-client/core/auth'
import { useInterval } from 'sierra-client/hooks/use-interval'
import { usePost } from 'sierra-client/hooks/use-post'
import { useTranslation } from 'sierra-client/hooks/use-translation'
import { getUserErrorTranslationKey } from 'sierra-client/utils/translation-utils'
import { AuthenticationContent } from 'sierra-client/views/authentication/native/components/authentication-content'
import { isLeft } from 'sierra-domain/either'
import { XRealtimeUserSendVerifyEmail } from 'sierra-domain/routes'
import { Button, Spacer, Text } from 'sierra-ui/primitives'

export const VerifyEmail = (): JSX.Element => {
  const { t } = useTranslation()
  const { postWithUserErrorCode } = usePost()
  const [error, setError] = useState<undefined | string>(undefined)
  const [resent, setResent] = useState<boolean>(false)

  useInterval(() => {
    void Auth.getInstance().synchronize()
  }, 1000)

  const submit = async (): Promise<void> => {
    setError(undefined)

    const result = await postWithUserErrorCode(XRealtimeUserSendVerifyEmail, {})

    if (isLeft(result)) {
      setError(t(getUserErrorTranslationKey(result.left)))
      return
    }

    setError(undefined)
    setResent(true)
  }

  return (
    <AuthenticationContent message={t('verify-email.heading')}>
      <Text color='foreground/primary' size='small' lineLength={45} align='center'>
        {t('verify-email.after-signup')}
      </Text>
      <Spacer size='medium' />
      <Button variant='primary' id={'resend-button'} onClick={submit} disabled={resent} grow>
        {t('verify-email.button-resend')}
      </Button>
      {error !== undefined && (
        <>
          <Spacer />
          <Text size='small' color='redMedium'>
            {error}
          </Text>
        </>
      )}
    </AuthenticationContent>
  )
}
