import { DateTime, Duration } from 'luxon'
import { Text } from 'sierra-ui/primitives'
import { token } from 'sierra-ui/theming'
import styled, { css } from 'styled-components'

export const oneHourFromNowFloored = (): DateTime =>
  DateTime.now()
    .plus(Duration.fromObject({ hour: 1 }))
    .startOf('hour')

export const twoHoursFromNowFloored = (): DateTime =>
  DateTime.now()
    .plus(Duration.fromObject({ hour: 2 }))
    .startOf('hour')

export const TextButton = styled(Text).attrs({
  'role': 'button',
  'aria-pressed': false,
  'color': 'foreground/muted',
  'bold': true,
})<{ $disabled?: boolean }>`
  font-size: 0.75rem;
  align-self: flex-start;
  transition: all 0.1s cubic-bezier(0.25, 0.1, 0.25, 1);
  cursor: pointer;

  &:hover {
    color: ${token('foreground/primary')};
  }

  ${p =>
    p.$disabled === true &&
    css`
      pointer-events: none;
      color: ${token('foreground/muted')};
      cursor: not-allowed;
    `}
`
