import { useAtom } from 'jotai'
import { useCallback } from 'react'
import { FetchEmailHtmlQuery } from 'sierra-client/api/graphql/gql/graphql'
import { renderedEmailAtom } from 'sierra-client/views/manage/programs/staggered-assignments/atoms'
import { fetchEmailHtml } from 'sierra-client/views/manage/programs/staggered-assignments/queries/email-template-queries'
import { EmailTemplate } from 'sierra-client/views/manage/programs/staggered-assignments/types'
import { serializeTemplateAsRenderable } from 'sierra-client/views/manage/programs/staggered-assignments/utils'

type UseRenderProgramEmail = {
  fetchRenderedEmail: (programId: string, template: EmailTemplate) => Promise<FetchEmailHtmlQuery>
  renderedEmail: string | null
}

export const useRenderProgramEmail = (): UseRenderProgramEmail => {
  const [renderedEmail, setRenderedEmail] = useAtom(renderedEmailAtom)

  const fetchRenderedEmail = useCallback<
    (programId: string, template: EmailTemplate) => Promise<FetchEmailHtmlQuery>
  >(
    async (programId, template): Promise<FetchEmailHtmlQuery> => {
      const html = await fetchEmailHtml({
        type: 'PROGRAM',
        forProgram: serializeTemplateAsRenderable(programId, template),
      })
      setRenderedEmail(html.emailHtml.html)
      return html
    },
    [setRenderedEmail]
  )

  return {
    renderedEmail,
    fetchRenderedEmail,
  }
}
