import { z } from 'zod'

export const TrackState = z.enum(['on', 'muted', 'disabled', 'unavailable'])

export type TrackState = z.infer<typeof TrackState>
export type VideoQuality = 'auto' | 'low' | 'off'
type AgoraConnectionState = 'DISCONNECTED' | 'CONNECTING' | 'RECONNECTING' | 'CONNECTED' | 'DISCONNECTING'
type NetworkQuality = 0 | 1 | 2 | 3 | 4 | 5 | 6

export type RemoteParticipant = {
  id: string
  isPublishingAudio: boolean
  isSubscribedToAudio: boolean
  isPublishingVideo: boolean
  isSubscribedToVideo: boolean
}

export type MediaDevice = {
  deviceId: string
  label: string
}

export type ClientJoinState = 'joined' | 'changing' | 'not-joined' | 'auth-error'

export type DevicePermissionState = 'not-requested' | 'denied' | 'allowed'

export type CallMode =
  | { state: 'not-joined' }
  | { state: 'in-main-channel'; mainChannelId: string }
  | { state: 'in-breakout-room'; mainChannelId: string; breakoutRoom: string }

export type NetworkQualityStats = {
  uplink: {
    currentUplink: NetworkQuality
    windowedUplink: NetworkQuality
  }
  downlink: {
    currentDownlink: NetworkQuality
    windowedDownlink: NetworkQuality
  }
}

export type VideoSubscribeConfig = { userId: string; quality: VideoQuality }[]

export type CameraResolution = 'high' | 'mid' | 'low'

export type CallState = {
  noiseCancellationEnabled: boolean
  backgroundBlurEnabled: boolean
  forceCloudProxy: boolean
  geofenceAreas?: string[]
  audio: {
    state: TrackState
    trackLabel?: string
    trackId?: string
    deviceId?: string
  }
  video: {
    state: TrackState
    trackLabel?: string
    trackId?: string
    deviceId?: string
    resolution?: CameraResolution
  }
  screenShare: {
    enabled: boolean
    connectionState: AgoraConnectionState
    clientId?: string
  }
  screenRecord: {
    enabled: boolean
    connectionState: AgoraConnectionState
    clientId?: string
  }
  call: {
    callMode: CallMode
    clientConnected: boolean
    connectionState: AgoraConnectionState
    joinState: ClientJoinState
    remoteParticipants: RemoteParticipant[]
    isPublishing: boolean
    isPublishingAudio: boolean
    isPublishingVideo: boolean
    role?: string
    channelName?: string
    clientId?: string
    networkQualityStats?: NetworkQualityStats
    videoSubscribeConfig?: VideoSubscribeConfig
    isUsingProxy?: boolean
  }
  devices: {
    cameraPermission?: DevicePermissionState
    cameras?: MediaDevice[]
    microphonePermission?: DevicePermissionState
    microphones?: MediaDevice[]
  }
}
