import { postWithUserErrorException } from 'sierra-client/state/api'
import { createRootStateAsyncThunk } from 'sierra-client/state/create-root-state-async-thunk'
import { Tag } from 'sierra-domain/content/tag'
import { XRealtimeContentListTags } from 'sierra-domain/routes'

export const fetchTagsData = createRootStateAsyncThunk(
  'content/fetch-tags-data',
  async (_, { dispatch }): Promise<Tag[]> => {
    const { rankedTags } = await postWithUserErrorException(XRealtimeContentListTags, {}, dispatch)
    return rankedTags
  }
)
