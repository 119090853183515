import {
  isInElement,
  scrollToSelectedNode,
  selectNodeWithIdSynchronous,
} from 'sierra-client/views/v3-author/command'
import { isEnabled as isEnabledLists } from 'sierra-client/views/v3-author/list/isEnabled'
import { isEnabled as isEnabledChecklists } from 'sierra-client/views/v3-author/list/isEnabledChecklist'
import { Entity } from 'sierra-domain/entity'
import { nanoid12 } from 'sierra-domain/nanoid-extensions'
import { OrderedList, UnorderedList } from 'sierra-domain/v3-author'
import {
  createBlockQuote,
  createCheckList,
  createCheckListItem,
  createHeading,
  createOrderedList,
  createUnorderedList,
} from 'sierra-domain/v3-author/create-blocks'
import { Editor, Element, Range, Transforms } from 'slate'

const shortcuts = {
  '*': { type: 'bulleted-list', isEnabled: isEnabledLists },
  '-': { type: 'bulleted-list', isEnabled: isEnabledLists },
  '+': { type: 'bulleted-list', isEnabled: isEnabledLists },
  '1.': { type: 'numbered-list', isEnabled: isEnabledLists },
  '[]': { type: 'check-list', checked: false, isEnabled: isEnabledChecklists },
  '[x]': { type: 'check-list', checked: true, isEnabled: isEnabledChecklists },
  '>': { type: 'block-quote' },
  '#': { type: 'heading', level: 5 },
  '##': { type: 'heading', level: 0 },
  '###': { type: 'heading', level: 2 },
  '####': { type: 'heading', level: 3 },
} as const

function isShortcutKey(value: string): value is keyof typeof shortcuts {
  return value in shortcuts
}

function areShortcutsEnabled(editor: Editor): boolean {
  return !isInElement(editor, [
    'markdown',
    'block-quote',
    'takeaways',
    'preamble',
    'code',
    'table',
    'poll-card-alternative',
  ])
}

export const withShortcuts = (editor: Editor): Editor => {
  const { insertText } = editor

  editor.insertText = text => {
    const { selection } = editor

    if (
      isInElement(editor, [
        'title-card-heading',
        'bulleted-list',
        'numbered-list',
        'check-list',
        'sliding-scale-card-option',
      ])
    )
      return insertText(text)

    if (text === ' ' && selection && Range.isCollapsed(selection)) {
      const block = Editor.above(editor, { match: n => Element.isElement(n) && Editor.isBlock(editor, n) })
      const path = block ? block[1] : []
      const start = Editor.start(editor, path)
      const range = { anchor: selection.anchor, focus: start }
      const beforeText = Editor.string(editor, range)
      const shortcut = isShortcutKey(beforeText) ? shortcuts[beforeText] : undefined

      if (shortcut !== undefined && areShortcutsEnabled(editor)) {
        const isEnabled = 'isEnabled' in shortcut ? shortcut.isEnabled({ editor }) : true
        if (isEnabled === true) {
          if (shortcut.type === 'bulleted-list' || shortcut.type === 'numbered-list') {
            if (!isInElement(editor, 'list-item')) {
              const startPointId = nanoid12()
              Transforms.select(editor, range)
              Transforms.delete(editor)

              const list: Entity<UnorderedList> | Entity<OrderedList> =
                shortcut.type === 'bulleted-list'
                  ? createUnorderedList({ id: startPointId })
                  : createOrderedList({ id: startPointId })

              Transforms.wrapNodes(editor, list, {
                at: path,
              })
              selectNodeWithIdSynchronous(editor, startPointId)
              scrollToSelectedNode(editor)

              return
            }
          }
          if (shortcut.type === 'check-list') {
            if (!isInElement(editor, 'check-list-item')) {
              const startPointId = nanoid12()
              Transforms.select(editor, range)
              Transforms.delete(editor)

              Transforms.wrapNodes(
                editor,
                createCheckList({
                  children: [
                    createCheckListItem({
                      id: startPointId,
                      checked: shortcut.checked,
                      children: [{ text }],
                    }),
                  ],
                }),
                { at: path }
              )
              selectNodeWithIdSynchronous(editor, startPointId)
              scrollToSelectedNode(editor)

              return
            }
          } else {
            const block =
              shortcut.type === 'heading' ? createHeading({ level: shortcut.level }) : createBlockQuote()

            Transforms.select(editor, range)
            Transforms.delete(editor)

            Transforms.wrapNodes(editor, block, {
              at: path,
            })

            return
          }
        }
      }
    }

    insertText(text)
  }

  return editor
}
