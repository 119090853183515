import {
  DragDropContext,
  DragDropContextProps,
  Draggable,
  DraggableProvidedDragHandleProps,
  Droppable,
} from '@hello-pangea/dnd'
import React from 'react'
import {
  Cell,
  HeaderGroup,
  TableProps as RTTableProps,
  Row,
  TableCellProps,
  TableFooterProps,
  TableHeaderProps,
  TableInstance,
  TableRowProps,
} from 'react-table'
import { CheckBoxContainer } from 'sierra-client/components/table/select'
import { token } from 'sierra-ui/theming'
import { fonts } from 'sierra-ui/theming/fonts'
import styled, { css } from 'styled-components'

const TableTable = styled.table<{ $blockDrag?: boolean; className?: string }>`
  width: 100%;
  overflow-x: visible;
  overflow-y: auto;
  border-collapse: separate;

  ${p =>
    p.$blockDrag !== undefined &&
    p.$blockDrag &&
    css`
      border-spacing: 0 0.5rem;
    `}
`

const TableTh = styled.th<{ width?: string | number }>`
  position: sticky;
  top: 0;
  inset-block-start: 0;
  z-index: 2;
  ${fonts.body.small}
  height: 3.25rem;
  padding: 0 1rem 0 0;
  vertical-align: middle;
  font-weight: ${fonts.weight.bold};
  background-color: ${token('surface/default')};
  color: ${token('foreground/primary')};
  text-align: start;
  box-shadow: inset 0 -1px 0 ${token('border/default')};
`

export const TableTd = styled.td`
  ${fonts.body.small}
  padding: 1rem 1rem 1rem 0;
  vertical-align: middle;
  background-color: transparent;
  color: ${token('foreground/secondary')};
  border: none;
  transition:
    background-color 0.1s ease,
    color 0.1s ease,
    border-color 0.1s ease;
`
const hoverCss = css`
  ${TableTd} {
    background-color: ${token('surface/soft')};
    color: ${token('foreground/primary')};

    &:first-child::before,
    &:last-child::after {
      background-color: ${token('surface/soft')};
    }

    ${CheckBoxContainer} {
      background-color: ${token('surface/soft')};
    }
  }
`

export const TableTr = styled.tr<{
  isDragging?: boolean
  $blockDrag?: boolean
  isFocused?: boolean
  $isLastHighlightedItem?: boolean
}>`
  ${p =>
    p.onClick !== undefined &&
    css`
      cursor: pointer;
    `}

  ${({ isDragging }) =>
    isDragging === true &&
    css`
      display: table;
    `};

  ${TableTd},
  ${TableTh} {
    &:last-child {
      padding-right: 0;
    }

    &:first-child::before,
    &:last-child::after {
      content: '';
      position: absolute;
      top: -1px;
      height: calc(100% + 2px);
      background-color: transparent;
      transition: background-color 0.2s ease;
    }
  }

  ${TableTh} {
    &:first-child::before,
    &:last-child::after {
      height: 100%;
      background-color: ${token('surface/default')};
    }
  }

  ${TableTd} {
    &:first-child,
    &:last-child {
      position: relative;
    }

    &:first-child::before {
      border-top-left-radius: 0.5rem;
      border-bottom-left-radius: 0.5rem;
    }

    &:last-child::after {
      border-top-right-radius: 0.5rem;
      border-bottom-right-radius: 0.5rem;
    }
  }

  ${p =>
    p.$blockDrag === true
      ? css`
          td {
            background-color: ${token('surface/default')(p)};
            border-top: 1px solid ${token('border/default')(p)};
            border-bottom: 1px solid ${token('border/default')(p)};
            padding-top: 1.5rem;
            padding-bottom: 1.5rem;

            &:first-child {
              border-left: 1px solid ${token('border/default')(p)};
              border-top-left-radius: 0.5rem;
              border-bottom-left-radius: 0.5rem;
              padding-left: 1.5rem;
            }

            &:last-child {
              border-right: 1px solid ${token('border/default')(p)};
              border-top-right-radius: 0.5rem;
              border-bottom-right-radius: 0.5rem;
            }
          }
        `
      : css`
          &:not(:first-child:last-child) {
            box-shadow: inset 0 -${p.$isLastHighlightedItem === true ? 4 : 1}px 0 ${token('border/default')};
          }

          ${p.isFocused === true && hoverCss}
          &:hover {
            ${hoverCss}
          }
        `}
`

const TableContainer = styled.div<{ $small?: boolean; $blockDrag?: boolean }>`
  ${({ $small, $blockDrag }) =>
    $blockDrag !== true &&
    css`
      padding-left: 1rem;
      padding-right: 1rem;

      ${TableTr} {
        ${TableTd},
        ${TableTh} {
          &:first-child {
            padding-left: 2rem;

            &::before {
              width: 1rem;
              left: -1rem;
            }
          }

          &:last-child {
            &::after {
              width: 1rem;
              right: -1rem;
            }
          }
        }

        ${$small === true &&
        css`
          ${TableTh} {
            font-size: ${fonts.body.small};
            vertical-align: middle;
          }
        `}
      }
    `}
  * {
    /** Without this rule, Chrome tries to scroll along when new content is loaded.
      * Not sure if we really need this or if we can solve it another way.
    */
    overflow-anchor: none;
  }
`

export interface TableProps<T extends Record<string, unknown> = Record<string, unknown>> {
  tableInstance: TableInstance<T>
  blockDrag?: boolean
  small?: boolean
  headerOverride?: JSX.Element
  // DnD
  onDrop?: DragDropContextProps['onDragEnd']
  renderSubComponent?: (info: { row: Row<T>; tableInstance: TableInstance<T> }) => React.ReactNode

  // Customize behavior: https://react-table.tanstack.com/docs/examples/data-driven-classes-and-styles
  getTableProps?: (tableInstance: TableInstance<T>) => Partial<RTTableProps> & React.ComponentProps<'table'>
  getTHeadProps?: (tableInstance: TableInstance<T>) => React.ComponentProps<'thead'>
  getHeaderGroupProps?: (
    headerGroup: HeaderGroup<T>
  ) => Partial<TableHeaderProps> & React.ComponentProps<'tr'>
  getHeaderProps?: (column: HeaderGroup<T>) => Partial<TableHeaderProps> & React.ComponentProps<'th'>
  getFooterProps?: (column: HeaderGroup<T>) => Partial<TableFooterProps> & React.ComponentProps<'th'>
  getTableBodyProps?: (propGetter: TableInstance<T>) => Partial<RTTableProps> & React.ComponentProps<'tbody'>
  getRowProps?: (
    propGetter: Row<T>,
    dragHandleProps: DraggableProvidedDragHandleProps | null
  ) => Partial<TableRowProps> & React.ComponentProps<'tr'>
  getCellProps?: (
    propGetter: Cell<T>,
    dragHandleProps: DraggableProvidedDragHandleProps | null
  ) => Partial<TableCellProps> & React.ComponentProps<'td'>
}

// Temporary component to try to split table-new into separate parts
export const Table = <T extends Record<string, unknown> = Record<string, unknown>>({
  tableInstance,
  blockDrag,
  onDrop,
  small,
  headerOverride,
  renderSubComponent,
  getTableProps = () => ({}),
  getTHeadProps = () => ({}),
  getHeaderGroupProps = () => ({}),
  getHeaderProps = () => ({}),
  // getTFootProps = () => ({}),
  // getFooterGroupProps = () => ({}),
  getFooterProps = () => ({}),
  getTableBodyProps = () => ({}),
  getRowProps = () => ({}),
  getCellProps = () => ({}),
}: TableProps<T>): JSX.Element => {
  const { headerGroups, footerGroups, rows, page, prepareRow, visibleColumns } = tableInstance

  // Check pagination in this component because it changes the data source to render
  // page can be undefined
  // eslint-disable-next-line @typescript-eslint/no-unnecessary-condition
  const rowsToRender = page !== undefined ? page : rows

  const enableDnD = onDrop !== undefined

  return (
    <TableContainer $small={small} $blockDrag={blockDrag}>
      <TableTable {...tableInstance.getTableProps(getTableProps(tableInstance))} $blockDrag={blockDrag}>
        <thead {...getTHeadProps(tableInstance)}>
          {headerOverride !== undefined ? (
            <TableTr>
              <TableTh colSpan={visibleColumns.length}>{headerOverride}</TableTh>
            </TableTr>
          ) : (
            headerGroups.map(headerGroup => (
              // eslint-disable-next-line react/jsx-key
              <TableTr {...headerGroup.getHeaderGroupProps(getHeaderGroupProps(headerGroup))}>
                {headerGroup.headers.map(column => (
                  // eslint-disable-next-line react/jsx-key
                  <TableTh {...column.getHeaderProps(getHeaderProps(column))} width={column.width}>
                    {column.render('Header')}
                  </TableTh>
                ))}
              </TableTr>
            ))
          )}
        </thead>
        <DragDropContext onDragEnd={onDrop !== undefined ? onDrop : () => {}}>
          <Droppable droppableId='table-body'>
            {droppableProvided => (
              <tbody
                {...tableInstance.getTableBodyProps(getTableBodyProps(tableInstance))}
                ref={droppableProvided.innerRef}
                {...droppableProvided.droppableProps}
              >
                {rowsToRender.map(row => {
                  prepareRow(row)
                  return (
                    <Draggable
                      draggableId={row.id}
                      key={row.id}
                      index={row.index}
                      isDragDisabled={!enableDnD}
                    >
                      {(draggableProvided, draggableSnapshot) => (
                        <React.Fragment key={row.id}>
                          <TableTr
                            {...row.getRowProps(getRowProps(row, draggableProvided.dragHandleProps))}
                            key={row.id}
                            {...draggableProvided.draggableProps}
                            // If you need to make the entire tr a drag handle, add `enableRowDrag` prop
                            // {...(enableRowDrag ? draggableProvided.dragHandleProps : {})}
                            ref={draggableProvided.innerRef}
                            isDragging={draggableSnapshot.isDragging}
                            $blockDrag={blockDrag}
                          >
                            {row.cells.map(cell => {
                              const { key, ...cellProps } = cell.getCellProps(
                                getCellProps(cell, draggableProvided.dragHandleProps)
                              )

                              return (
                                <TableTd key={key} {...cellProps} width={cell.column.width}>
                                  {cell.render('Cell', {
                                    dragHandleProps: draggableProvided.dragHandleProps,
                                  })}
                                </TableTd>
                              )
                            })}
                          </TableTr>
                          {renderSubComponent !== undefined && renderSubComponent({ tableInstance, row })}
                        </React.Fragment>
                      )}
                    </Draggable>
                  )
                })}
                {droppableProvided.placeholder}
              </tbody>
            )}
          </Droppable>
        </DragDropContext>
        <tfoot>
          {footerGroups.map(group => (
            // eslint-disable-next-line react/jsx-key
            <tr {...group.getFooterGroupProps()}>
              {group.headers.map(column => (
                // eslint-disable-next-line react/jsx-key
                <td {...column.getFooterProps(getFooterProps(column))}>{column.render('Footer')}</td>
              ))}
            </tr>
          ))}
        </tfoot>
      </TableTable>
    </TableContainer>
  )
}
