import { useCallback, useEffect, useState } from 'react'
import { ActionModal } from 'sierra-client/components/common/modals/action-modal'
import { usePost } from 'sierra-client/hooks/use-post'
import { useTranslation } from 'sierra-client/hooks/use-translation'
import { Trans } from 'sierra-client/hooks/use-translation/trans'
import { TranslationKey } from 'sierra-client/hooks/use-translation/types'
import { useSelector } from 'sierra-client/state/hooks'
import { selectUserOrganizationId } from 'sierra-client/state/user/user-selector'
import { AddToSlackButton } from 'sierra-client/views/manage/settings/components/slack'
import { GetSlackConnectionResponse, SlackConnection } from 'sierra-domain/api/manage'
import {
  NotificationState,
  PatchOrgNotificationSettingsRequest,
  SlackSettings,
} from 'sierra-domain/api/org-notification-settings'
import { XRealtimeSlackConnection, XRealtimeSlackRemoveConnection } from 'sierra-domain/routes'
import { IconButton, Switch, Text, View } from 'sierra-ui/primitives'
import { token } from 'sierra-ui/theming'
import styled from 'styled-components'

export const useSlackIntegrationInfo = (): {
  connection: SlackConnection | undefined
  removeConnection: () => void
} => {
  const { postWithUserErrorException } = usePost()
  const [info, setInfo] = useState<GetSlackConnectionResponse | undefined>(undefined)

  useEffect(() => {
    void postWithUserErrorException(XRealtimeSlackConnection, {}).then(setInfo)
  }, [postWithUserErrorException])

  const removeConnection = useCallback(async () => {
    setInfo(undefined)
    await postWithUserErrorException(XRealtimeSlackRemoveConnection, {})
  }, [postWithUserErrorException])

  return {
    connection: info?.connection,
    removeConnection,
  }
}

const SlackIcon = (): JSX.Element => {
  return (
    <svg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 122.8 122.8'>
      <path
        d='M25.8 77.6c0 7.1-5.8 12.9-12.9 12.9S0 84.7 0 77.6s5.8-12.9 12.9-12.9h12.9v12.9zm6.5 0c0-7.1 5.8-12.9 12.9-12.9s12.9 5.8 12.9 12.9v32.3c0 7.1-5.8 12.9-12.9 12.9s-12.9-5.8-12.9-12.9V77.6z'
        fill='#e01e5a'
      ></path>
      <path
        d='M45.2 25.8c-7.1 0-12.9-5.8-12.9-12.9S38.1 0 45.2 0s12.9 5.8 12.9 12.9v12.9H45.2zm0 6.5c7.1 0 12.9 5.8 12.9 12.9s-5.8 12.9-12.9 12.9H12.9C5.8 58.1 0 52.3 0 45.2s5.8-12.9 12.9-12.9h32.3z'
        fill='#36c5f0'
      ></path>
      <path
        d='M97 45.2c0-7.1 5.8-12.9 12.9-12.9s12.9 5.8 12.9 12.9-5.8 12.9-12.9 12.9H97V45.2zm-6.5 0c0 7.1-5.8 12.9-12.9 12.9s-12.9-5.8-12.9-12.9V12.9C64.7 5.8 70.5 0 77.6 0s12.9 5.8 12.9 12.9v32.3z'
        fill='#2eb67d'
      ></path>
      <path
        d='M77.6 97c7.1 0 12.9 5.8 12.9 12.9s-5.8 12.9-12.9 12.9-12.9-5.8-12.9-12.9V97h12.9zm0-6.5c-7.1 0-12.9-5.8-12.9-12.9s5.8-12.9 12.9-12.9h32.3c7.1 0 12.9 5.8 12.9 12.9s-5.8 12.9-12.9 12.9H77.6z'
        fill='#ecb22e'
      ></path>
    </svg>
  )
}

const ApplicationIcon = styled.div`
  width: 48px;
  height: 48px;
  padding: 12px;
  display: flex;
  justify-content: center;
  flex-direction: row;
  align-items: center;
  border-radius: 12px;
  border: 0.8px solid ${token('border/strong')};
  flex-shrink: 0;
`

const HorizontalDivider = styled.hr`
  border: 0;
  border-top: 1px solid ${token('border/default')};
  margin: 24px 0 18px;
`

export const SlackNotificationsSettings = ({
  slackSettings,
  onChange,
}: {
  slackSettings: SlackSettings
  onChange: (_: PatchOrgNotificationSettingsRequest) => void
}): JSX.Element => {
  const organizationId = useSelector(selectUserOrganizationId)
  const slackConnection = useSlackIntegrationInfo()
  const [showDeleteConnectionConfirm, setShowDeleteConnectionConfirm] = useState<boolean>(false)
  const [showDisableNotificationsConfirm, setShowDisableNotificationsConfirm] = useState<boolean>(false)
  const { t } = useTranslation()

  if (organizationId === undefined) {
    return <></>
  }

  const enabled = slackSettings.state === NotificationState.Enabled

  const onToggleSlackNotifications = (newValue: NotificationState): void => {
    onChange({
      slackSettings: {
        ...slackSettings,
        state: newValue,
      },
    })
  }

  const iconAndTitle = (
    <View alignItems='center' gap='16'>
      <ApplicationIcon>
        <SlackIcon />
      </ApplicationIcon>
      <Text size='small' bold align='center'>
        {t('manage.settings.slack.notifications-title')}
      </Text>
    </View>
  )

  if (slackConnection.connection !== undefined) {
    return (
      <>
        <View alignItems='center' gap='none' justifyContent='space-between'>
          {iconAndTitle}
          <Switch
            checked={enabled}
            onChange={() =>
              enabled
                ? setShowDisableNotificationsConfirm(true)
                : onToggleSlackNotifications(NotificationState.Enabled)
            }
            ariaLabel='todo'
          />
        </View>
        <HorizontalDivider />
        <View grow justifyContent='space-between'>
          <Text size='small' color='foreground/secondary'>
            <Trans
              i18nKey={'manage.settings.slack.connected' satisfies TranslationKey}
              values={{ workspaceName: slackConnection.connection.teamName }}
              components={{
                bold: <b />,
              }}
            />
          </Text>
          <IconButton
            iconId='unlink'
            size='small'
            variant='transparent'
            onClick={() => {
              setShowDeleteConnectionConfirm(true)
            }}
          />
        </View>
        <ActionModal
          open={showDeleteConnectionConfirm}
          isLoading={false}
          onClose={() => {
            setShowDeleteConnectionConfirm(false)
          }}
          primaryAction={() => {
            slackConnection.removeConnection()
          }}
          title={t('manage.settings.slack.remove-modal-title')}
          primaryActionLabel={t('manage.settings.slack.remove-modal-confirm')}
          deleteAction
        >
          <Trans
            i18nKey={'manage.settings.slack.remove-modal-body' satisfies TranslationKey}
            values={{ workspaceName: slackConnection.connection.teamName }}
            components={{
              bold: <b />,
            }}
          />
        </ActionModal>
        <ActionModal
          open={showDisableNotificationsConfirm}
          isLoading={false}
          onClose={() => {
            setShowDisableNotificationsConfirm(false)
          }}
          primaryAction={() => {
            slackConnection.removeConnection()
          }}
          title={t('manage.settings.slack.disable-notifications.title')}
          primaryActionLabel={t('manage.settings.slack.disable-notifications.confirm')}
          deleteAction
        >
          <Trans
            i18nKey={'manage.settings.slack.disable-notifications.description' satisfies TranslationKey}
            values={{ workspaceName: 'slackConnection.connection.teamName' }}
            components={{
              bold: <b />,
            }}
          />
        </ActionModal>
      </>
    )
  } else {
    return (
      <View alignItems='center' gap='none' justifyContent='space-between' grow>
        {iconAndTitle}
        <AddToSlackButton organizationId={organizationId} />
      </View>
    )
  }
}
