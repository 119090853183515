import { DateTime } from 'luxon'
import { Dispatch, SetStateAction, useMemo } from 'react'
import { CellProps, Column, useTable } from 'react-table'
import { Table } from 'sierra-client/components/table/table'
import { useLocalizedFormatters } from 'sierra-client/core/format'
import { useTranslation } from 'sierra-client/hooks/use-translation'
import { getAvatarImage } from 'sierra-client/utils/avatar-img'
import { TableContainer } from 'sierra-client/views/manage/components/common'
import { FilePreview } from 'sierra-client/views/manage/homeworks/homework-file-preview'
import { LightText } from 'sierra-client/views/v3-author/homework/homework-atoms'
import { GradeStatus } from 'sierra-domain/api/homework'
import { HomeworkDetailsResponse } from 'sierra-domain/homework'
import { assertNever, getUserName } from 'sierra-domain/utils'
import { Icon, RoundAvatar, Tooltip, TruncatedText } from 'sierra-ui/components'
import { Button, Text, View } from 'sierra-ui/primitives'
import styled from 'styled-components'

type SubmissionData = HomeworkDetailsResponse['data'][number]['submissionData']
type UserData = HomeworkDetailsResponse['data'][number]['user']

type HomeworkSubmissionRow = {
  id: string
  submission?: SubmissionData
  submittedAt?: string
  submitterInfo: UserData
  dueAt?: string
  status: GradeStatus
  showReviewButton: boolean
}

const SubmissionWrapper = styled(View)`
  cursor: pointer;
  width: 96px;
  height: 60px;
  min-width: 96px;
  min-height: 60px;
  border-radius: ${p => p.theme.borderRadius['size-6']};

  overflow: hidden;
  background-color: rgba(0, 0, 0, 0.05);
  align-items: center;
  justify-content: center;

  & img {
    width: 100%;
    height: 100%;
    object-fit: cover;
  }
`

export const HomeworkSubmissionsTable: React.FC<{
  data: HomeworkDetailsResponse
  setReviewSubmissionId: Dispatch<SetStateAction<string | undefined>>
}> = ({ data, setReviewSubmissionId }) => {
  const { t } = useTranslation()
  const { formatTimestamp } = useLocalizedFormatters()

  const submussionTableColumns: Column<HomeworkSubmissionRow>[] = useMemo(
    () => [
      {
        Header: t('dictionary.user-singular'),
        accessor: 'submitterInfo',
        Cell: (props: CellProps<HomeworkSubmissionRow, HomeworkSubmissionRow['submitterInfo']>) => {
          const user = props.value

          return (
            <View>
              <RoundAvatar
                firstName={user.firstName}
                lastName={user.lastName}
                src={getAvatarImage(user.userId, user.avatar)}
                color={user.avatarColor}
                size='tiny'
              />
              <TruncatedText color='LEGACY_DEFAULT_TEXT_COLOR_REPLACE_ASAP' size='small' lines={1} bold>
                {getUserName(user) ?? ''}
              </TruncatedText>
            </View>
          )
        },
      },
      {
        Header: t('manage.homework.submission-singular'),
        accessor: 'submission',
        width: '100',
        Cell: (props: CellProps<HomeworkSubmissionRow, HomeworkSubmissionRow['submission']>) => {
          const submissionFileInfo = props.value
          const submissionId = submissionFileInfo?.submissionId

          if (submissionFileInfo === undefined) {
            return (
              <View>
                <Text color='LEGACY_DEFAULT_TEXT_COLOR_REPLACE_ASAP' size='small' as={LightText}>
                  {t('homework.no-submission')}
                </Text>
              </View>
            )
          }

          return (
            <View gap='16' onClick={() => setReviewSubmissionId(submissionId)}>
              <Tooltip title={t('dictionary.view')}>
                <SubmissionWrapper>
                  {'fileId' in submissionFileInfo ? (
                    <FilePreview
                      fileInfo={submissionFileInfo}
                      renderIconForMimeType={{ 'application/pdf': true, 'video/mp4': true }}
                    />
                  ) : null}
                </SubmissionWrapper>
              </Tooltip>
            </View>
          )
        },
      },
      {
        Header: t('dictionary.submitted'),
        accessor: 'submittedAt',
        width: '100',
        Cell: (props: CellProps<HomeworkSubmissionRow, HomeworkSubmissionRow['submittedAt']>) => {
          const createdAt = props.value

          if (createdAt === undefined) return null
          const dt = DateTime.fromISO(createdAt)

          return (
            <View>
              <Tooltip title={dt.toLocaleString(DateTime.DATETIME_MED)}>
                <Text color='LEGACY_DEFAULT_TEXT_COLOR_REPLACE_ASAP' size='small' as={LightText}>
                  {formatTimestamp(createdAt)}
                </Text>
              </Tooltip>
            </View>
          )
        },
      },
      {
        Header: t('dictionary.due'),
        accessor: 'dueAt',
        width: '100',
        Cell: (props: CellProps<HomeworkSubmissionRow, HomeworkSubmissionRow['dueAt']>) => {
          const dueAt = props.value
          if (dueAt === undefined) return null

          const dt = DateTime.fromISO(dueAt)
          return (
            <View>
              <Tooltip title={dt.toLocaleString(DateTime.DATE_MED)}>
                <Text color='LEGACY_DEFAULT_TEXT_COLOR_REPLACE_ASAP' size='small' as={LightText}>
                  {dt.toLocaleString(DateTime.DATE_MED)}
                </Text>
              </Tooltip>
            </View>
          )
        },
      },
      {
        Header: t('dictionary.status'),
        accessor: 'status',
        Cell: (props: CellProps<HomeworkSubmissionRow, HomeworkSubmissionRow['status']>) => {
          switch (props.value) {
            case 'not-submitted':
              return (
                <View>
                  <Text size='small' color='grey40'>
                    -
                  </Text>
                </View>
              )
            case 'not-graded':
              return (
                <View>
                  <Text size='small' bold color='grey40'>
                    {t('homework.ready-for-review')}
                  </Text>
                </View>
              )
            case 'failed':
              return (
                <View>
                  <Icon iconId='close--circle' color='redBright' />
                  <Text size='small' bold color='redBright'>
                    {t('homework.failed')}
                  </Text>
                </View>
              )
            case 'failed-with-no-retries':
              return (
                <View>
                  <Icon iconId='close--circle' color='redBright' />
                  <Text size='small' bold color='redBright'>
                    {t('homework.failed-with-no-retries')}
                  </Text>
                </View>
              )
            case 'passed':
              return (
                <View>
                  <Icon iconId='checkmark--outline' color='greenBright' />
                  <Text size='small' bold color='greenBright'>
                    {t('homework.passed')}
                  </Text>
                </View>
              )
            case 'dismissed':
              return (
                <View>
                  <Text size='small' bold color='grey40'>
                    {t('homework.dismissed')}
                  </Text>
                </View>
              )
            default:
              assertNever(props.value)
          }
        },
      },
      {
        width: '50',
        accessor: 'showReviewButton',
        Cell: (props: CellProps<HomeworkSubmissionRow, HomeworkSubmissionRow['showReviewButton']>) => {
          const submissionId = props.row.original.submission?.submissionId

          if (props.value) {
            return (
              <View>
                <Button variant='secondary' onClick={() => setReviewSubmissionId(submissionId)}>
                  {t('manage.homework.review')}
                </Button>
              </View>
            )
          }

          return null
        },
      },
    ],
    [t, formatTimestamp, setReviewSubmissionId]
  )

  const tableData = useMemo(
    () =>
      data.data.map(homework => ({
        id: homework.user.userId,
        dueAt: homework.dueAt,
        status: homework.statusData.gradeStatus,
        submitterInfo: homework.user,
        submission: homework.submissionData,
        submittedAt: homework.submissionData?.createdAt,
        showReviewButton: homework.statusData.gradeStatus === 'not-graded',
      })),
    [data.data]
  )

  const submissionTable = useTable<HomeworkSubmissionRow>({
    columns: submussionTableColumns,
    data: tableData,
  })

  return (
    <TableContainer>
      <Table tableInstance={submissionTable} />
      {data.data.length === 0 && (
        <View justifyContent='center' padding='large'>
          <Text size='small' color='grey40'>
            No exercises available
          </Text>
        </View>
      )}
    </TableContainer>
  )
}
