import * as Separator from '@radix-ui/react-separator'
import { useTranslation } from 'sierra-client/hooks/use-translation'
import type { TranslationKey } from 'sierra-client/hooks/use-translation/types'
import type { FCC } from 'sierra-client/types'
import { Text, View } from 'sierra-ui/primitives'
import { token } from 'sierra-ui/theming'
import styled from 'styled-components'

export const WIDGET_HEIGHT = 384

export const StatsWidgetContainer = styled(View).attrs({
  gap: '16',
  direction: 'column',
})`
  width: 100%;
`

export const StatsWidgetInnerContainer = styled.div`
  width: 100%;
  border-radius: 15px;
  border: 1px solid ${token('border/default')};
  background: ${token('surface/default')};
  box-shadow: 0px 2px 4px 0px rgba(0, 0, 0, 0.08);
  overflow: hidden;
`

export const StatsWidgetTitle: React.FC<{ translationKey: TranslationKey }> = ({ translationKey }) => {
  const { t } = useTranslation()
  return (
    <Text bold as='h3' color='foreground/secondary'>
      {t(translationKey)}
    </Text>
  )
}

export const MetricSeparator = styled(Separator.Root)`
  height: 1px;
  background-color: ${token('border/default')};
`

export const Metric: FCC<{ translationKey: TranslationKey }> = ({ children, translationKey }) => {
  const { t } = useTranslation()
  return (
    <View direction='column' gap='6'>
      <Text color='foreground/muted'>{t(translationKey)}</Text>
      {children}
    </View>
  )
}
