import { UserFirstName } from 'sierra-client/components/liveV2/atoms'
import { RaisingHand } from 'sierra-client/components/liveV2/raising-hand'
import { useTranslation } from 'sierra-client/hooks/use-translation'
import { UserId } from 'sierra-domain/api/uuid'
import { asNonNullable } from 'sierra-domain/utils'
import { color } from 'sierra-ui/color'
import { Text, View } from 'sierra-ui/primitives'
import { fonts } from 'sierra-ui/theming/fonts'
import styled from 'styled-components'

const Divider = styled.hr`
  width: 100%;
  height: 1px;
  margin: unset;
  background-color: ${p => color(p.theme.home.backgroundColor).shift(0.05).toString()};
`

const Bold = styled.span`
  font-weight: ${fonts.weight.bold};
`

export const HandRaiseText = (props: { userIds: UserId[] }): JSX.Element => {
  const { t } = useTranslation()
  if (props.userIds.length === 0) {
    return <></>
  }

  return (
    <>
      <View padding='small'>
        <RaisingHand userId={asNonNullable(props.userIds[0])} />
        <Text size='small'>
          {props.userIds.length === 1 ? (
            <>
              <Bold>
                <UserFirstName uuid={asNonNullable(props.userIds[0])} />
              </Bold>{' '}
              {t('live.is-raising-their-hand')}
            </>
          ) : props.userIds.length === 2 ? (
            <>
              <Bold>
                <UserFirstName uuid={asNonNullable(props.userIds[0])} />
              </Bold>{' '}
              {t('dictionary.and')}{' '}
              <Bold>
                <UserFirstName uuid={asNonNullable(props.userIds[1])} />
              </Bold>{' '}
              {t('live.are-raising-their-hands')}
            </>
          ) : (
            <>
              <Bold>
                <UserFirstName uuid={asNonNullable(props.userIds[0])} />
              </Bold>
              ,{' '}
              <Bold>
                <UserFirstName uuid={asNonNullable(props.userIds[1])} />
              </Bold>{' '}
              and {props.userIds.length - 2} more are raising their hands
            </>
          )}
        </Text>
      </View>
      <Divider />
    </>
  )
}
