import { useNotif } from 'sierra-client/components/common/notifications'
import { errorLogger } from 'sierra-client/error/error-logger'
import { useDispatch, useSelector } from 'sierra-client/state/hooks'
import { useMicrophone } from 'sierra-client/state/live/actions'
import { selectAvailableMicrophones, selectCurrentMicrophoneId } from 'sierra-client/state/live/selectors'
import { LabelMenuItem } from 'sierra-ui/components'

export const useMicrophonesSettings = ():
  | {
      currentId: string
      items: LabelMenuItem[]
      onItemSelected: (_: { id: string }) => void
    }
  | undefined => {
  const { push } = useNotif()
  const microphones = useSelector(selectAvailableMicrophones)
  const currentMicrophoneId = useSelector(selectCurrentMicrophoneId)
  const dispatch = useDispatch()

  const microphoneItems: LabelMenuItem[] =
    microphones?.map(device => ({
      id: device.deviceId,
      type: 'label',
      label: device.label,
      selected: currentMicrophoneId === device.deviceId,
    })) ?? []

  const onChangeMicrophone = async (itemId: string): Promise<void> => {
    try {
      // eslint-disable-next-line react-hooks/rules-of-hooks
      await dispatch(useMicrophone({ deviceId: itemId }))
    } catch (error) {
      errorLogger.captureWarning(error)
      push({ type: 'error', body: 'Failed to change microphone' })
    }
  }

  if (microphones === undefined || currentMicrophoneId === undefined) return undefined
  return {
    currentId: currentMicrophoneId,
    items: microphoneItems,
    onItemSelected: ({ id }) => onChangeMicrophone(id),
  }
}
