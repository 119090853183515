import { Logger } from 'sierra-client/core/logging/logger'

export const dropAWordWordAdded = Logger.trackLoggerWithExtra<
  { userId: string; wordLength: number },
  { userId: string; wordLength: number }
>(
  'drop_a_word_word_added',

  input => {
    return {
      ...input,
    }
  }
)

export const dropAWordWordDeleted = Logger.trackLoggerWithExtra<
  { userId: string; wordLength: number },
  { userId: string; wordLength: number }
>(
  'drop_a_word_word_deleted',

  input => {
    return {
      ...input,
    }
  }
)
