import {
  XRealtimeAdminCoursesCourseComments,
  XRealtimeAdminCoursesCourseDetail,
  XRealtimeAdminCoursesListEnrolledGroups,
  XRealtimeAdminCoursesListLiveSessions,
  XRealtimeAdminCoursesUpdateCourseVisibility,
  XRealtimeAuthorDeleteCourse,
} from 'sierra-domain/routes'

import { useCallback, useEffect, useState } from 'react'
import { usePost } from 'sierra-client/hooks/use-post'
import { getGlobalRouter } from 'sierra-client/router'
import {
  ContentProgramEnrollment,
  CourseDetailResponse,
  CourseRating,
  LiveSessionRow,
} from 'sierra-domain/api/manage'
import { CourseId } from 'sierra-domain/api/nano-id'

export type UseCourseDetailsData = {
  courseData?: CourseDetailResponse
  courseProgramAssignments: ContentProgramEnrollment[]
  courseComments: CourseRating[]
  liveSessions: LiveSessionRow[]
  isLoading: boolean
  saveVisibility: (courseId: CourseId, value: boolean) => Promise<void>
  fetchCourse: () => Promise<void>
  fetchProgramAssignments: (courseId: CourseId) => Promise<ContentProgramEnrollment[]>
  fetchLiveSessions: (courseId: CourseId) => Promise<LiveSessionRow[]>
  fetchComments: (courseId: CourseId) => Promise<CourseRating[]>
  deleteCourse: () => Promise<void>
}

export const useManageCourseDetails = (courseId: CourseId): UseCourseDetailsData => {
  const { postWithUserErrorException } = usePost()
  const [isLoading, setIsLoading] = useState<boolean>(false)
  const [courseData, setCourseData] = useState<CourseDetailResponse | undefined>(undefined)
  const [courseComments, setCourseComments] = useState<CourseRating[]>([])
  const [liveSessions, setLiveSessions] = useState<LiveSessionRow[]>([])

  const [courseProgramAssignments, setCourseProgramAssignments] = useState<ContentProgramEnrollment[]>([])

  const saveVisibility = useCallback(
    async (courseId: CourseId, value: boolean): Promise<void> => {
      setCourseData(current => (current !== undefined ? { ...current, isVisible: value } : undefined))
      await postWithUserErrorException(XRealtimeAdminCoursesUpdateCourseVisibility, {
        id: courseId,
        isVisible: value,
      })
    },
    [postWithUserErrorException]
  )

  const fetchCourse = useCallback(async () => {
    const courseDataResponse = await postWithUserErrorException(XRealtimeAdminCoursesCourseDetail, {
      id: courseId,
    })

    setCourseData(courseDataResponse)
  }, [postWithUserErrorException, courseId])

  const fetchProgramAssignments = useCallback<UseCourseDetailsData['fetchProgramAssignments']>(
    async courseId => {
      const response = await postWithUserErrorException(XRealtimeAdminCoursesListEnrolledGroups, {
        id: courseId,
      })
      setCourseProgramAssignments(response.data)
      return response.data
    },
    [postWithUserErrorException]
  )

  const fetchComments = useCallback<UseCourseDetailsData['fetchComments']>(
    async courseId => {
      const response = await postWithUserErrorException(XRealtimeAdminCoursesCourseComments, {
        id: courseId,
      })
      setCourseComments(response.data)
      return response.data
    },
    [postWithUserErrorException]
  )

  const fetchLiveSessions = useCallback<UseCourseDetailsData['fetchLiveSessions']>(
    async courseId => {
      const response = await postWithUserErrorException(XRealtimeAdminCoursesListLiveSessions, {
        flexibleContentId: courseId,
      })
      setLiveSessions(response)
      return response
    },
    [postWithUserErrorException]
  )

  useEffect(() => {
    void (async () => {
      setIsLoading(true)
      await fetchCourse()
      setIsLoading(false)
    })()
  }, [fetchCourse])

  useEffect(
    //
    () => void fetchProgramAssignments(courseId),
    [courseId, fetchProgramAssignments]
  )

  useEffect(
    //
    () => void fetchComments(courseId),
    [courseId, fetchComments]
  )

  // Fetch live sessions for native:live
  useEffect(() => {
    if (courseData?.kind !== 'native:live') return
    void fetchLiveSessions(courseId)
  }, [courseData?.kind, courseId, fetchLiveSessions])

  const deleteCourse = useCallback(async () => {
    await postWithUserErrorException(XRealtimeAuthorDeleteCourse, {
      courseId,
    })

    void getGlobalRouter().navigate({ to: '/manage/content', replace: true })
  }, [postWithUserErrorException, courseId])

  return {
    courseData,
    courseProgramAssignments,
    courseComments,
    liveSessions,
    isLoading,
    fetchProgramAssignments,
    fetchLiveSessions,
    saveVisibility,
    fetchCourse,
    deleteCourse,
    fetchComments,
  }
}
