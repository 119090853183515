import { atom, createStore, useAtomValue, useSetAtom } from 'jotai'
import { useEffect, useState } from 'react'
import { config } from 'sierra-client/config/global-config'
import { usePeriodicRefresh } from 'sierra-client/hooks/use-periodic-refresh'

const MINUTE_IN_MS = 60 * 1000
const HOUR_IN_MS = 60 * MINUTE_IN_MS

const OLD_SESSION_AGE_MS = 24 * HOUR_IN_MS

const shouldForceHardNavigationAtom = atom(false)
const _privateStore = createStore()

export function useShouldForceHardNavigation(): boolean {
  return useAtomValue(shouldForceHardNavigationAtom, { store: _privateStore })
}

// If the browser session started a long time ago, we want to do a hard
// navigation in order to update the client. Ideally we would check if
// there have been deployments since the session started, but this should
// give us most of the benefits without the complexity.
export function CheckShouldForceHardNavigation(): null {
  const [firstMountedAt] = useState(() => Date.now())
  const setShouldForceHardNavigation = useSetAtom(shouldForceHardNavigationAtom, { store: _privateStore })

  const isOldSession = usePeriodicRefresh(
    undefined,
    () => Date.now() - firstMountedAt > OLD_SESSION_AGE_MS,
    5 * MINUTE_IN_MS
  )

  const shouldForceHardNavigation = isOldSession && !config.scorm.isScorm

  useEffect(() => {
    setShouldForceHardNavigation(shouldForceHardNavigation)
  }, [shouldForceHardNavigation, setShouldForceHardNavigation])

  return null
}
