import { useTranslation } from 'sierra-client/hooks/use-translation'
import { Text } from 'sierra-ui/primitives'
import styled from 'styled-components'

const NoContentContainer = styled.div`
  min-height: 15rem;
  display: flex;
  justify-content: center;
  align-items: center;
`

export const NoContentPlaceholder: React.FC = () => {
  const { t } = useTranslation()

  return (
    <NoContentContainer>
      <Text size='regular' color='foreground/muted'>
        {t('workspace.no-content-found')}
      </Text>
    </NoContentContainer>
  )
}
