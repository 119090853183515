import _ from 'lodash'
import { useGetReportTitle } from 'sierra-client/views/manage/reports/report-utils'
import { useReportJobs, useReports } from 'sierra-client/views/manage/reports/use-reports'
import { Report, ReportJob } from 'sierra-domain/api/analytics-reporting'

const REPORT_ID = 'exercise-submission-detail'

type UseExerciseSubmissionReportData = {
  exerciseSubmissionReport: Report | undefined
  addJob: ReturnType<typeof useReportJobs>['addJob']
  latestJob: ReportJob | undefined
  reportTitle: string | undefined
}

export const useExerciseSubmissionReport = (): UseExerciseSubmissionReportData => {
  const { reports } = useReports()
  const { jobs, addJob } = useReportJobs()
  const getReportTitle = useGetReportTitle()

  const exerciseSubmissionReport = reports.find(r => r.id === REPORT_ID)

  const allJobs = jobs.filter(j => j.reportId === REPORT_ID)
  const latestJob = _.maxBy(allJobs, job => job.createdAt)

  const reportTitle =
    exerciseSubmissionReport !== undefined ? getReportTitle(exerciseSubmissionReport) : undefined

  return {
    exerciseSubmissionReport,
    addJob,
    latestJob,
    reportTitle,
  }
}
