/* eslint-disable @typescript-eslint/no-use-before-define */
import React, { useCallback } from 'react'
import { useLiveSessionContext } from 'sierra-client/components/liveV2/contexts/live-session-data'
import {
  FileScrollAnchor,
  useGoToNode,
  useScrollToActiveFileInSidebar,
} from 'sierra-client/components/liveV2/hooks/use-go-to-node'
import {
  selectCurrentCard,
  useSelectCurrentCard,
} from 'sierra-client/components/liveV2/hooks/use-select-current-card'
import { RenderFolder } from 'sierra-client/components/liveV2/shared/folder-item'
import { CardIcon } from 'sierra-client/features/collapsable-sidebar'
import { useFileTitle } from 'sierra-client/state/flexible-content/file-title'
import { selectFlexibleContent } from 'sierra-client/state/flexible-content/selectors'
import { useSelector } from 'sierra-client/state/hooks'
import { FCC } from 'sierra-client/types'
import {
  DistanceBetweenSidebarFiles,
  FileContainerBaseStylings,
} from 'sierra-client/views/flexible-content/editor/content-sidebar/file-base-stylings-css'
import { useSpeakerNoteContext } from 'sierra-client/views/flexible-content/editor/content-sidebar/speaker-notes/context'
import { LiveSpeakerNoteInput } from 'sierra-client/views/flexible-content/editor/content-sidebar/speaker-notes/ui'
import { useFlexibleContentYDoc } from 'sierra-client/views/flexible-content/polaris-editor-provider/use-flexible-content-ydoc'
import { FileTree } from 'sierra-client/views/flexible-content/render-file-tree'
import { Debug } from 'sierra-client/views/learner/components/debug'
import { FileId } from 'sierra-domain/flexible-content/identifiers'
import { File } from 'sierra-domain/flexible-content/types'
import { resolveTokenOrColor } from 'sierra-ui/color/token-or-color'
import { TruncatedTextWithTooltip } from 'sierra-ui/components'
import { Text, View } from 'sierra-ui/primitives'
import { token } from 'sierra-ui/theming'
import styled, { css } from 'styled-components'

const FileContainer = styled(View).attrs({ gap: '10' })<{ $isCurrent: boolean }>`
  ${FileContainerBaseStylings};
  margin-top: ${DistanceBetweenSidebarFiles};

  transition: background-color 50ms cubic-bezier(0.25, 0.1, 0.25, 1);

  background-color: ${p =>
    p.$isCurrent ? resolveTokenOrColor('org/primary', p.theme).opacity(0.08) : 'transparent'};

  &:hover {
    background-color: ${p => resolveTokenOrColor('org/primary', p.theme).opacity(0.08)};
  }

  color: ${token('foreground/secondary')};
`

const NoteFileContainer = styled(View)<{ $isCurrent: boolean }>`
  margin-top: 2px;
  gap: 10px;
  border-radius: 0.5rem;
  transition: background-color 50ms cubic-bezier(0.25, 0.1, 0.25, 1);
  align-items: flex-start;

  ${p =>
    !p.$isCurrent &&
    css`
      opacity: 0.25;
    `}
`

const FileExplorerContainer = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
  padding: 0.5rem 0;
  max-height: 100%;
  flex-grow: 1;
`

const RenderFile = ({ node, currentCard }: { node: File; currentCard: File | undefined }): JSX.Element => {
  const { currentTab } = useSpeakerNoteContext()

  const showSpeakerNote = currentTab === 'Notes'

  if (showSpeakerNote) return <SpeakerNoteFile node={node} />
  return <NormalFile node={node} currentCard={currentCard} />
}

const FileHighlight: FCC<{ nodeId: FileId; isCurrent: boolean }> = ({ children, nodeId, isCurrent }) => {
  const goToNode = useGoToNode()
  const onFileClick = (): void => {
    if (!isCurrent) goToNode(nodeId)
  }

  return (
    <FileContainer cursor='pointer' $isCurrent={isCurrent} position='relative' onClick={onFileClick}>
      {children}
    </FileContainer>
  )
}

const FileTitle: React.FC<{ node: File; isCurrent: boolean }> = ({ node, isCurrent }) => {
  const { yDoc } = useFlexibleContentYDoc()
  const cardTitle = useFileTitle(yDoc, node)
  return (
    <TruncatedTextWithTooltip
      lines={1}
      size='small'
      bold
      color={isCurrent ? 'foreground/primary' : 'foreground/secondary'}
    >
      {cardTitle}
    </TruncatedTextWithTooltip>
  )
}

const NormalFile = ({ node, currentCard }: { node: File; currentCard: File | undefined }): JSX.Element => {
  const isCurrent = currentCard?.id === node.id

  return (
    <FileScrollAnchor fileId={node.id}>
      <FileHighlight nodeId={node.id} isCurrent={isCurrent}>
        <CardIcon isCurrentItem={isCurrent} showPlayIcon={isCurrent} />
        <View grow overflow='hidden'>
          <FileTitle node={node} isCurrent={isCurrent} />
        </View>
      </FileHighlight>
    </FileScrollAnchor>
  )
}

const TitleWrapper = styled(View)<{ $isCurrent: boolean }>`
  background-color: ${p =>
    p.$isCurrent ? resolveTokenOrColor('org/primary', p.theme).opacity(0.08) : 'transparent'};

  width: 100%;
  ${FileContainerBaseStylings}
`

const SpeakerNoteFile = ({ node }: { node: File }): JSX.Element => {
  const currentFile = useSelectCurrentCard()
  const isCurrent = currentFile?.id === node.id
  const goToNode = useGoToNode()

  const onFileClick = useCallback((): void => {
    if (!isCurrent) goToNode(node.id)
  }, [goToNode, isCurrent, node.id])

  return (
    <FileScrollAnchor fileId={node.id}>
      <NoteFileContainer
        cursor='pointer'
        $isCurrent={isCurrent}
        position='relative'
        onClick={onFileClick}
        direction='column'
      >
        <TitleWrapper paddingTop='8' $isCurrent={isCurrent} gap='10'>
          <CardIcon isCurrentItem={isCurrent} showPlayIcon={isCurrent} />
          <View grow overflow='hidden'>
            <FileTitle node={node} isCurrent={isCurrent} />
          </View>
        </TitleWrapper>
        <LiveSpeakerNoteInput file={node} active={isCurrent} />
      </NoteFileContainer>
    </FileScrollAnchor>
  )
}

export const FileExplorer = (): JSX.Element => {
  const liveSession = useLiveSessionContext()
  const contentId = liveSession.data.flexibleContentId
  const flexibleContent = useSelector(state => selectFlexibleContent(state, contentId))
  const currentCard = useSelector(state => selectCurrentCard(state, contentId))

  useScrollToActiveFileInSidebar()

  if (!flexibleContent)
    return (
      <Debug>
        <Text>[Debug: Flexible content not defined in FileExplorer]</Text>
      </Debug>
    )

  return (
    <FileExplorerContainer>
      <FileTree
        content={flexibleContent}
        renderFile={file => <RenderFile node={file} currentCard={currentCard} />}
        renderFolder={(folder, children) => (
          <RenderFolder node={folder} currentCard={currentCard}>
            {children}
          </RenderFolder>
        )}
      />
    </FileExplorerContainer>
  )
}
