import { Text } from 'sierra-ui/primitives'
import { token } from 'sierra-ui/theming'
import styled from 'styled-components'

export const SecondaryButton = styled(Text).attrs({
  color: 'foreground/muted',
  size: 'small',
  bold: true,
})`
  display: inline-block;
  cursor: pointer;
  transition: 0.2s color ease;

  &:hover {
    color: ${token('foreground/secondary')};
  }
`
