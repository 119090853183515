import { useRef, useState } from 'react'
import { useOnUnmount } from 'sierra-client/hooks/use-on-unmount'
import { useStableFunction } from 'sierra-client/hooks/use-stable-function'

const DEFAULT_RESET_DELAY_MS = 1000

type UseResetAfterDelayParams = {
  /**
   * Delay in milliseconds before resetting value.
   *
   * Default: 1000ms
   */
  delay?: number
}

type UseResetAfterDelay = {
  /**
   * This is always false except for a period after calling `setTrue`.
   */
  isEnabled: boolean
  /**
   * Sets the boolean to `true` and starts a reset timer. Calling the function
   * multiple times will extend the timer.
   */
  setTrue: () => void
}

/**
 * (name change welcome)
 *
 * This hook can be used to temporarily show some state, like after clicking a "copy link" button.
 *
 * Note: The timer is cleaned up on unmount.
 */
export function useResetBooleanAfterDelay({
  delay = DEFAULT_RESET_DELAY_MS,
}: UseResetAfterDelayParams = {}): UseResetAfterDelay {
  const [isEnabled, setIsEnabled] = useState(false)
  const timeoutRef = useRef<NodeJS.Timeout | undefined>(undefined)

  const cancelPendingTimer = (): void => {
    clearTimeout(timeoutRef.current)
    timeoutRef.current = undefined
  }

  const setTrue = useStableFunction(() => {
    setIsEnabled(true)

    cancelPendingTimer()
    timeoutRef.current = setTimeout(() => setIsEnabled(false), delay)
  })

  /* Clean up timer on unmount. */
  useOnUnmount(cancelPendingTimer)

  return {
    isEnabled,
    setTrue,
  }
}
