import { Duration } from 'luxon'
import type { SkillLevelId } from 'sierra-client/api/graphql/branded-types'
import type {
  CourseFragmentFragment,
  ProgressContentFragmentFragment,
} from 'sierra-client/api/graphql/gql/graphql'
import { convertGQLImage } from 'sierra-client/api/graphql/util/convert-gql-image'
import type { LearnCourse, LearnEntity, ProgramCourseStep } from 'sierra-domain/api/entities'
import { NanoId12 } from 'sierra-domain/api/nano-id'
import { assertNever, isDefined, isNotDefined } from 'sierra-domain/utils'
import { z } from 'zod'

export const RestrictedEntityId = z.string().brand('RestrictedEntity')
export type RestrictedEntityId = z.infer<typeof RestrictedEntityId>

type SkillDetailRestrictedEntity = {
  entityType: 'skill:restricted-content'
  id: RestrictedEntityId
}

export const createSkillDetailRestrictedEntity = (id: string): SkillDetailRestrictedEntity => ({
  entityType: 'skill:restricted-content',
  id: RestrictedEntityId.parse(id),
})

export type SkillDetailEntity = {
  skillLevel: {
    id: SkillLevelId
    name: string
    index: number
    isLocked: boolean
    description: string | undefined
  }
  entities: Array<LearnEntity | SkillDetailRestrictedEntity>
  displayProgress: boolean
}

type ContentTypename =
  | 'LinkCourse'
  | 'LinkedInCourse'
  | 'NativeCourseGroup'
  | 'NativeEventGroup'
  | 'NativeLiveCourse'
  | 'NativeSelfPacedCourse'
  | 'Path'
  | 'Program'
  | 'ScormCourse'
  | 'ScormCourseGroup'

const convertTypenameToCourseEntityType = (
  typename: ContentTypename
): LearnCourse['entityType'] | undefined => {
  switch (typename) {
    case 'NativeSelfPacedCourse':
      return 'native:self-paced'
    case 'NativeLiveCourse':
      return 'native:live'
    case 'LinkCourse':
      return 'link'
    case 'ScormCourse':
      return 'scorm'
    case 'NativeCourseGroup':
      return 'native:course-group'
    case 'LinkedInCourse':
      return 'linkedin'
    case 'NativeEventGroup':
      return 'native:event-group'
    case 'ScormCourseGroup':
      return 'scorm:course-group'

    case 'Program':
    case 'Path':
      console.error(`${typename} are not supported in LearnCourse type`)
      return undefined

    default:
      assertNever(typename)
  }
}

const convertGQLCourseToLearnCourse = (course: CourseFragmentFragment): LearnCourse | undefined => {
  const entityType = convertTypenameToCourseEntityType(course.__typename)
  if (!isDefined(entityType)) return undefined

  const base = {
    id: course.courseId,
    title: course.title,
    image: convertGQLImage(course.image),
    tags: [],
    description: course.description ?? '',
  }
  const learnable = {
    timeEstimate: Duration.fromISO(course.duration).as('seconds'),
    learnerContext: {
      isFavorite: false,
      progress: 0,
      exercises: [],
    },
  }

  return entityType === 'native:live'
    ? {
        entityType,
        liveSessions: [],
        ...base,
        ...learnable,
      }
    : {
        entityType,
        ...base,
        ...learnable,
      }
}

export function createSkillDetailLearnEntity({
  content,
  progress,
  isLevelFastTrack,
}: {
  content: ProgressContentFragmentFragment
  progress: number
  isLevelFastTrack: boolean
}): LearnEntity | undefined {
  const convertContentToLearnEntity = (
    entityType: 'native:self-paced' | 'scorm' | 'link' | 'native:course-group' | 'linkedin'
  ): LearnEntity => {
    return {
      entityType,
      id: NanoId12.parse(content.contentId.split(':')[1]!),
      timeEstimate: Duration.fromISO(content.duration).as('seconds'),
      title: content.title,
      image: convertGQLImage(content.image),
      tags: [],
      learnerContext: {
        isFavorite: false,
        progress,
        exercises: [],
      },
      skillContext: {
        isLevelFastTrack,
      },
    }
  }

  switch (content.__typename) {
    case 'NativeCourseGroup': {
      return convertContentToLearnEntity('native:course-group')
    }
    case 'NativeSelfPacedCourse': {
      return convertContentToLearnEntity('native:self-paced')
    }
    case 'ScormCourse': {
      return convertContentToLearnEntity('scorm')
    }
    case 'LinkCourse': {
      return convertContentToLearnEntity('link')
    }
    case 'LinkedInCourse': {
      return convertContentToLearnEntity('linkedin')
    }

    case 'Program': {
      return {
        entityType: 'program',
        id: content.id,
        description: content.description ?? '',
        completedStepCount: 0,
        totalStepCount: content.stepsCount,
        learnerContext: {
          isFavorite: false,
          progress: progress,
          exercises: [],
        },
        skillContext: {
          isLevelFastTrack,
        },
        image: convertGQLImage(content.image),
        timeEstimate: Duration.fromISO(content.duration).as('seconds'),
        tags: [],
        title: content.title,
        steps: content.steps
          .map((step): ProgramCourseStep | undefined => {
            switch (step.__typename) {
              case 'CourseProgramStep': {
                if (!isDefined(step.course)) return undefined
                const course = step.course
                const learnCourse = convertGQLCourseToLearnCourse(course)

                if (isNotDefined(learnCourse)) return undefined

                return {
                  type: 'course',
                  id: course.courseId,
                  course: learnCourse,
                }
              }
              case 'EmailProgramStep':
              case 'PathProgramStep': {
                console.info(`${step.__typename} not supported yet.`)
                return undefined
              }
            }
          })
          .filter(isDefined),
      }
    }

    case 'Path': {
      return {
        entityType: 'path',
        id: content.pathId,
        title: content.title,
        description: content.description ?? '',
        timeEstimate: Duration.fromISO(content.duration).as('seconds'),
        learnerContext: {
          isFavorite: false,
          progress,
          exercises: [],
        },
        skillContext: {
          isLevelFastTrack,
        },
        image: convertGQLImage(content.image),
        tags: [],
        content: content.courses
          .map(course => {
            return convertGQLCourseToLearnCourse(course)
          })
          .filter(isDefined),
      }
    }

    case 'NativeEventGroup':
    case 'NativeLiveCourse':
    case 'ScormCourseGroup': {
      console.info(`${content.__typename} conversion to LearnEntity is not supported yet.`)
      return undefined
    }

    default:
      assertNever(content)
  }
}
