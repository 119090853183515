/* eslint-disable react/forbid-dom-props */
import React, { useCallback, useMemo } from 'react'
import { PromptWithMiniMenu } from 'sierra-client/editor/blocks/paragraph/mini-menu'
import { useShouldShowPrompt } from 'sierra-client/editor/blocks/paragraph/use-should-show-prompt'
import { useGetPathForId } from 'sierra-client/editor/editor-jotai-context'
import { editorGrid } from 'sierra-client/editor/layout'
import { TextToSpeech } from 'sierra-client/editor/text-to-speech'
import { Placeholder } from 'sierra-client/views/v3-author/components'
import { assertElementType, isElementType } from 'sierra-client/views/v3-author/queries'
import { usePlaceholder, useRenderingContext } from 'sierra-client/views/v3-author/rendering-context'
import { SlateWrapperProps } from 'sierra-client/views/v3-author/slate'
import { Entity } from 'sierra-domain/entity'
import { hasOnlyEmptyTextInNodes } from 'sierra-domain/slate-util'
import { Paragraph as ParagraphType } from 'sierra-domain/v3-author'
import { Text } from 'sierra-ui/primitives'
import { Editor, Path } from 'slate'
import { useSlateSelector } from 'slate-react'
import { default as styled } from 'styled-components'

function getParagraphSize(level: number): 'large' | 'regular' | 'small' {
  if (level === 0) return 'large'
  if (level === 1) return 'regular'
  if (level === 2) return 'small'

  // This should not be reachable, but we do not enforce it
  return 'regular'
}

const useShouldShowMiniMenu = ({
  paragraph,
}: {
  paragraph: Entity<ParagraphType>
}): { shouldShowMiniMenu: boolean } => {
  const getPathById = useGetPathForId()
  const { disableMiniMenu } = useRenderingContext()

  const selectShouldShowMiniMenu = useCallback(
    (editor: Editor): boolean => {
      if (disableMiniMenu) {
        return false
      }

      const path = getPathById(paragraph.id)

      if (path === undefined) return false

      const parentPath = Path.parent(path)
      const [parent] = editor.node(parentPath)

      const isInVerticalStack = isElementType('vertical-stack', parent)

      return !isInVerticalStack
    },
    [disableMiniMenu, getPathById, paragraph.id]
  )

  const shouldShowMiniMenu = useSlateSelector(selectShouldShowMiniMenu)

  return { shouldShowMiniMenu }
}

const ParagraphInner = styled(Text)`
  position: relative;
  color: ${p => p.theme.home.textColor};

  & span[data-slate-placeholder='true'] {
    white-space: nowrap;
  }

  ${editorGrid}
`

export const ParagraphWrapper = React.forwardRef<HTMLDivElement, SlateWrapperProps>(
  ({ element, children, attributes, ...props }, ref) => {
    assertElementType('paragraph', element)

    const isEmpty = useMemo(() => hasOnlyEmptyTextInNodes([element]), [element])
    const contextPlaceholder = usePlaceholder()
    const shouldShowPrompt = useShouldShowPrompt({ element })
    const { shouldShowMiniMenu } = useShouldShowMiniMenu({ paragraph: element })

    const { level } = element

    return (
      <ParagraphInner
        color='LEGACY_DEFAULT_TEXT_COLOR_REPLACE_ASAP'
        size={getParagraphSize(level ?? 1)}
        {...attributes}
        {...props}
        ref={ref}
      >
        {isEmpty && contextPlaceholder !== undefined && <Placeholder>{contextPlaceholder}</Placeholder>}
        {shouldShowPrompt && (
          <PromptWithMiniMenu shouldShowMiniMenu={shouldShowMiniMenu} currentId={element.id} />
        )}
        <span style={{ minWidth: 10 }}>{children}</span>

        <TextToSpeech element={element} />
      </ParagraphInner>
    )
  }
)
