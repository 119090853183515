import { graphql } from 'sierra-client/api/graphql/gql'
import { graphQuery } from 'sierra-client/api/hooks/use-graphql-query'

export const useSoftDeleteCertificate = (): ((certificateId: string) => Promise<string>) => {
  const softDeleteCertificate = async (certificateId: string): Promise<string> => {
    const result = await graphQuery(
      graphql(`
        mutation softDeleteCertificate($certificateId: CertificateId!) {
          softDeleteCertificate(certificateId: $certificateId) {
            certificateId
          }
        }
      `),
      {
        certificateId,
      }
    )
    return result.softDeleteCertificate.certificateId
  }

  return softDeleteCertificate
}
