import { parseStringToJsonContent } from 'sierra-client/views/v3-author/homework/homework-atoms'
import { FreeTextEditor } from 'sierra-ui/missions/workflows/free-text-editor'
import { ScrollView } from 'sierra-ui/primitives'
import styled from 'styled-components'

const FreeTextContainer = styled(ScrollView)`
  max-height: 450px;
`

export const ManageReviewFreetextPreview: React.FC<{ text: string }> = ({ text }) => {
  return (
    <FreeTextContainer padding='small xsmall' borderColor='border/strong' radius='size-8'>
      <FreeTextEditor content={parseStringToJsonContent(text)} editable={false} />
    </FreeTextContainer>
  )
}
