import { useCallback, useEffect, useState } from 'react'
import { usePost } from 'sierra-client/hooks/use-post'
import { XApiServerResponse } from 'sierra-domain/api/x-api'
import { XRealtimeAdminXapiGetRemoteLrsServers } from 'sierra-domain/routes'

type UseManageXApiOutput = {
  xApiServers: XApiServerResponse[]
  isLoading: boolean
  fetchServers: () => Promise<void>
}

export const mapXApiToCsv = (xApiServer: XApiServerResponse): Record<string, string> => ({
  serverId: xApiServer.serverId,
  name: xApiServer.name,
  description: xApiServer.description,
  tokenUrl: xApiServer.tokenUrl,
  statementsUrl: xApiServer.statementsUrl,
  clientId: xApiServer.clientId,
})

export const useManageXApi = (): UseManageXApiOutput => {
  const { postWithUserErrorException } = usePost()
  const [isLoading, setIsLoading] = useState<boolean>(false)
  const [xApiServers, setXApiServers] = useState<XApiServerResponse[]>([])

  const fetchServers = useCallback<UseManageXApiOutput['fetchServers']>(async () => {
    setIsLoading(true)
    const response = await postWithUserErrorException(XRealtimeAdminXapiGetRemoteLrsServers, {})
    setXApiServers(response)
    setIsLoading(false)
  }, [postWithUserErrorException])

  useEffect(() => {
    void fetchServers()
  }, [fetchServers])

  return {
    isLoading,
    xApiServers,
    fetchServers,
  }
}
