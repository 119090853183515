import { Editor } from '@tiptap/react'
import { isEqual } from 'lodash'
import { KeyboardEvent, useEffect, useMemo, useState } from 'react'
import {
  StyledEditorContent,
  getTiptapBaseExtensions,
  getTiptapContents,
} from 'sierra-client/components/chat/tiptap'
import { PreventInsertOnKeydown } from 'sierra-client/components/chat/tiptap-enter-handler'
import { useTranslation } from 'sierra-client/hooks/use-translation'
import { TiptapJsonData, TiptapMessage } from 'sierra-domain/chat'
import { Button, View } from 'sierra-ui/primitives'
import { TextAreaStyles } from 'sierra-ui/primitives/form/text-area-primitive'
import styled from 'styled-components'

const StyledInput = styled(StyledEditorContent)`
  ${TextAreaStyles};
`

const Container = styled.div`
  width: 100%;
  padding: 0 0.5rem;
`

type EditTiptapMessageProps = {
  message: TiptapMessage
  onCancel: () => void
  onSave: (updatedContent: TiptapJsonData) => void
}

export const EditTiptapMessage = ({ message, onCancel, onSave }: EditTiptapMessageProps): JSX.Element => {
  const [canSave, setCanSave] = useState(false)
  const { t } = useTranslation()

  const editor = useMemo(
    () =>
      new Editor({
        content: message.tiptapJsonData,
        extensions: [
          ...getTiptapBaseExtensions(),
          PreventInsertOnKeydown.configure({ keys: ['Enter', 'Mod-Enter'] }),
        ],
        onUpdate: ({ editor: curEditor }) => {
          const hasText = curEditor.getText().trim() !== ''
          setCanSave(hasText && !isEqual(message.tiptapJsonData, getTiptapContents(curEditor)))
        },
      }),
    [message]
  )

  useEffect(() => () => editor.destroy(), [editor])

  const onClickSave = (): void => {
    if (!canSave) return
    const data = getTiptapContents(editor)
    onSave(data)
  }

  const onClickCancel = (): void => {
    onCancel()
  }

  const onKeyDown = (event: KeyboardEvent<HTMLDivElement>): void => {
    event.stopPropagation()

    if (event.key === 'Enter' && event.shiftKey === false) {
      canSave ? onClickSave() : onClickCancel()
    }
  }

  return (
    <Container>
      <StyledInput editor={editor} onKeyDown={onKeyDown} />
      <View gap='8' paddingTop='8'>
        <Button onClick={onClickCancel} variant='secondary'>
          {t('dictionary.cancel')}
        </Button>
        <Button disabled={!canSave} onClick={onClickSave}>
          {t('dictionary.save')}
        </Button>
      </View>
    </Container>
  )
}
