import { useQueryClient, type QueryKey } from '@tanstack/react-query'
import { useCallback } from 'react'
import { SkillId } from 'sierra-client/api/graphql/branded-types'
import { graphql } from 'sierra-client/api/graphql/gql'
import type { InvalidateQuery } from 'sierra-client/state/api'

export const skillContentTableQuery = graphql(`
  query SkillContentTable(
    $id: SkillId!
    $limit: Int
    $cursor: String
    $sortBy: [SkillContentSortAttributeInput!]
  ) {
    skillAssignedContent(id: $id, limit: $limit, cursor: $cursor, sortBy: $sortBy) {
      skillId
      cursor
      totalCount
      data {
        skillLevel {
          levelSetting {
            id
            index
            name
            defaultBadgeTheme
          }
          badgeIssuedVia {
            ...BadgeIssuedVia
          }
        }
        content {
          ...NonExpensiveContentFragment
          assignmentCount
        }
        usersMissingAccess {
          ...BasicUserFragment
        }
      }
    }
    currentSkill: skill(id: $id) {
      skillLevels {
        id
        description
        levelSetting {
          id
          index
          name
        }
      }
    }
  }
`)

export const getSkillContentTableQueryKey = (skillId: SkillId): QueryKey => [
  'graphql',
  'skill-content-table',
  skillId,
]

export const useInvalidateSkillContentTableQuery = (skillId: SkillId): InvalidateQuery => {
  const queryClient = useQueryClient()
  return useCallback<InvalidateQuery>(() => {
    return queryClient.invalidateQueries({ queryKey: getSkillContentTableQueryKey(skillId) })
  }, [queryClient, skillId])
}
