import { createContext, ReactNode, useCallback, useMemo, useState } from 'react'

export type ChatControlContext = {
  showChat: boolean
  toggle: () => void
  open: () => void
  close: () => void
}

const ChatControlContextObj = createContext<ChatControlContext | undefined>(undefined)

export const ChatControlProvider = ({ children }: { children: ReactNode }): JSX.Element => {
  const [showChat, setShowChat] = useState(false)

  const toggle = useCallback(() => setShowChat(visible => !visible), [setShowChat])
  const open = useCallback(() => setShowChat(true), [setShowChat])
  const close = useCallback(() => setShowChat(false), [setShowChat])

  const contextValue = useMemo(
    () => ({
      showChat,
      toggle,
      open,
      close,
    }),
    [showChat, toggle, open, close]
  )

  return <ChatControlContextObj.Provider value={contextValue}>{children}</ChatControlContextObj.Provider>
}
