import { Text } from 'sierra-ui/primitives'

export const BeginningTruncatedText: React.FC<{ text: string }> = ({ text }) => {
  const approximatelyThreeRowsOfCharacters = 300
  const truncationIndicator = '[...] '

  let displayText = text

  if (text.length > approximatelyThreeRowsOfCharacters) {
    // We make sure we don't cut off a word in the middle
    const lastSpaceBeforeLimitFromEnd = text
      .split('')
      .reverse()
      .join('')
      .lastIndexOf(' ', approximatelyThreeRowsOfCharacters)
    displayText = truncationIndicator + text.slice(-lastSpaceBeforeLimitFromEnd)
  }

  return (
    <Text size='small' color='foreground/secondary'>
      {displayText}
    </Text>
  )
}
