import { useId } from 'react'
import { palette } from 'sierra-ui/theming'
import { fonts } from 'sierra-ui/theming/fonts'
import { styleByProp } from 'sierra-ui/utils'
import styled from 'styled-components'

type RadioSize = 'small' | 'normal'

const labelTopPadding = styleByProp('$size', {
  small: '0',
  normal: '0.125rem',
})

const radioSize = styleByProp('$size', {
  small: '1rem',
  normal: '1.25rem',
})

const ellipsePosition = styleByProp('$size', {
  small: '0.425rem',
  normal: '0.575rem',
})

const RadioLabel = styled.label<{ $size: RadioSize }>`
  ${fonts.body.small};
  position: relative;
  display: block;
  padding-left: calc(${radioSize} + 0.5rem);
  padding-top: ${labelTopPadding};
  min-height: ${radioSize};

  &::before,
  &::after {
    content: '';
    position: absolute;
    border-radius: 50%;
    background-color: ${palette.primitives.white};
    transition:
      color 0.2s ease,
      background-color 0.2s ease,
      box-shadow 0.2s ease;
  }

  &::before {
    left: 0;
    top: 0;
    height: ${radioSize};
    width: ${radioSize};
    border: 1px solid;
    border-color: ${p => p.theme.color.grey10};
  }

  &::after {
    left: ${ellipsePosition};
    top: ${ellipsePosition};
    height: 0.25rem;
    width: 0.25rem;
  }

  &:hover {
    &::before {
      border-color: ${p => p.theme.color.grey25};
    }
  }
`
const RadioInput = styled.input`
  opacity: 0;
  height: 0;
  width: 0;

  &:checked {
    & + ${RadioLabel} {
      &::before {
        border-color: ${p => p.theme.color.blueBright};
        background-color: ${p => p.theme.color.blueBright};
      }
    }
  }

  &:focus {
    & + ${RadioLabel} {
      &::before {
        box-shadow: 0px 0px 0px 4px rgba(5, 88, 249, 0.25);
      }
    }
  }

  &:disabled {
    & + ${RadioLabel} {
      pointer-events: none;

      &::before {
        border-color: ${p => p.theme.color.grey10};
        background-color: ${p => p.theme.color.grey10};
      }

      &::after {
        background-color: ${p => p.theme.color.grey10};
      }
    }
  }

  &:disabled:checked {
    & + ${RadioLabel} {
      &::after {
        background-color: ${p => p.theme.color.grey45};
      }
    }
  }
`

type RadioGroupProps = {
  name: string
  value: string
  className?: string
  size?: RadioSize
  label?: string
  checked: boolean
  disabled?: boolean
  onChange?: (e: React.ChangeEvent<HTMLInputElement>) => void
}

export const RadioButton: React.FC<RadioGroupProps> = ({
  name,
  className,
  label,
  value,
  size = 'normal',
  checked,
  disabled = false,
  onChange,
}) => {
  // The id is only used to link the label and the input
  const id = useId()

  return (
    <>
      <RadioInput
        id={id}
        onChange={onChange}
        value={value}
        name={name}
        type='radio'
        checked={checked}
        disabled={disabled}
      />
      <RadioLabel htmlFor={id} $size={size} className={className}>
        {label}
      </RadioLabel>
    </>
  )
}
