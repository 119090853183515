import { useCallback } from 'react'
import { postWithUserErrorException } from 'sierra-client/state/api'
import { useDispatch } from 'sierra-client/state/hooks'
import { RootStateThunkDispatch } from 'sierra-client/state/types'
import { XRealtimeAuthCheckPassword } from 'sierra-domain/routes'

const passwordRegex = /(?=.*[a-z])(?=.*[0-9])(?=.*[^A-Za-z0-9])|(?=.*[A-Z])(?=.*[0-9])(?=.*[^A-Za-z0-9])/

const isPasswordStrengthGood = async (
  password: string,
  dispatch: RootStateThunkDispatch
): Promise<boolean> => {
  if (password.length < 8) return false

  if (passwordRegex.test(password)) return true

  const result = await postWithUserErrorException(XRealtimeAuthCheckPassword, { password }, dispatch)

  return result.score >= 3
}

export const usePasswordStrength = (): {
  isPasswordStrengthGood: (password: string) => Promise<boolean>
} => {
  const dispatch = useDispatch()

  return {
    isPasswordStrengthGood: useCallback(
      (password: string) => isPasswordStrengthGood(password, dispatch),
      [dispatch]
    ),
  }
}
