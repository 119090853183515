import { useAtom } from 'jotai'
import { ShortcutMenu } from 'sierra-client/components/shortcut-menu'
import { useDeveloperToolsEnabled } from 'sierra-client/hooks/use-developer-tools-enabled'
import { AccessibilityTesting } from 'sierra-client/lib/accessibility-testing'
import { accessibilityTestingAtom } from 'sierra-client/lib/accessibility-testing/atoms'

export const AccessibilityTestingShortcut: React.FC = () => {
  const [isOn, setIsOn] = useAtom(accessibilityTestingAtom)
  const developerToolsEnabled = useDeveloperToolsEnabled()

  if (!developerToolsEnabled) return null
  return (
    <>
      <ShortcutMenu.Toggle
        label={{ type: 'untranslated', value: 'Accessibility Testing' }}
        toggle={() => setIsOn(prev => !prev)}
        isOn={() => isOn}
        iconId='reset'
        permission='ACCESS_LMS'
      />
      <AccessibilityTesting />
    </>
  )
}
