import { useMemo } from 'react'
import { usePeriodicRefresh } from 'sierra-client/hooks/use-periodic-refresh'
import {
  selectLastNoMessagesInChat,
  selectLastNoOfMessagesForEachUser,
} from 'sierra-client/state/chat/selectors'
import { useSelector } from 'sierra-client/state/hooks'
import { Message } from 'sierra-domain/chat'
import { ScopedChatId } from 'sierra-domain/collaboration/types'

const REFRESH_LATEST_MESSAGES_INTERVAL = 1000
const RECENT_MESSAGE_CUTOFF = 5000

// Filter out messages that are older than 5 seconds
const useLatestMessages = (allMessages: Message[] | undefined): Message[] =>
  usePeriodicRefresh(
    allMessages,
    messages => {
      if (messages === undefined) return []

      const timeNow = Date.now()

      return messages.filter(
        message => timeNow - new Date(message.timeSent).getTime() < RECENT_MESSAGE_CUTOFF
      )
    },
    REFRESH_LATEST_MESSAGES_INTERVAL
  )

const useLastNoOfMessagesByUser = ({
  chatId,
  userId,
  noOfMessages,
}: {
  chatId: ScopedChatId
  userId: string | undefined
  noOfMessages: number
}): Message[] => {
  const messages = useSelector(state => selectLastNoOfMessagesForEachUser(state, chatId, noOfMessages))

  const userMessages = useMemo(() => {
    if (userId === undefined) return []

    return messages[userId] ?? []
  }, [messages, userId])

  return useLatestMessages(userMessages)
}

export const useLastNoOfMessages = ({
  chatId,
  noOfMessages,
  exclude = [],
}: {
  chatId: ScopedChatId
  noOfMessages: number
  exclude?: string[]
}): Message[] => {
  const allMessages = useSelector(state => selectLastNoMessagesInChat(state, chatId, noOfMessages, exclude))

  return useLatestMessages(allMessages)
}

export const useLastMessageByUser = ({
  chatId,
  userId,
}: {
  chatId: ScopedChatId
  userId: string | undefined
}): Message | undefined => {
  const messages = useLastNoOfMessagesByUser({ chatId, userId, noOfMessages: 1 })

  return messages[0]
}
