import { motion } from 'framer-motion'
import { palette } from 'sierra-ui/theming'
import { fonts } from 'sierra-ui/theming/fonts'
import styled from 'styled-components'

export const GenerativeCaretContainer = styled(motion.div)`
  position: fixed;
  transition: all 100ms;
  background-color: ${palette.primitives.black};
  width: 0.15rem;
  height: 20px;
`

export const GenerativeCaretInner = styled(motion.div)`
  position: fixed;
  border-radius: 2px 2px 2px 0;
  padding: 2px 4px;
  pointer-events: none;
  user-select: none;
  transform: translateY(-100%);
  font-size: 12px;
  font-weight: ${fonts.weight.medium};
  line-height: 1rem;
  letter-spacing: normal;
  color: ${palette.primitives.white};
  background-color: ${palette.primitives.black};
  white-space: nowrap;
  transition: all 100ms;
`

export function getCursorPosition(domRange: Range): { x: number; y: number } {
  try {
    let x = 0
    let y = 0
    // Clone the range
    const range = domRange.cloneRange()
    // Collapse the range to the start, so there are not multiple chars selected
    range.collapse(true)

    // getClientRects returns all the positioning information we need
    const rect = range.getClientRects()[0]
    if (rect) {
      x = rect.left // since the caret is only 1px wide, left == right
      y = rect.top // top edge of the caret
    }

    return { x, y }
  } catch (e) {
    return { x: 0, y: 0 }
  }
}
