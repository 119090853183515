import { Duration } from 'luxon'
import {
  getIdFromTableValue,
  getLabelFromTableValue,
} from 'sierra-client/features/insights/display-widgets/table/table-value-utils'
import {
  OneDimensionalMetric,
  TwoDimensionalMetric,
  TwoDimensionalMetricRow,
} from 'sierra-client/features/insights/display-widgets/types'
import { toLabel } from 'sierra-client/features/insights/display-widgets/utils'
import { TranslationLookup } from 'sierra-client/hooks/use-translation/types'
import { labelToString } from 'sierra-client/lib/tabular/datatype/label'
import { getAvatarImage } from 'sierra-client/utils/avatar-img'
import {
  BarChartWidget,
  ColumnType,
  DimensionColumn,
  DimensionRef,
  LineChartWidget,
  MeasureColumn,
  MeasureRef,
  OneDimensionalWidget,
  TableColumn,
  TableResult,
  TableRow,
  TableValue,
  areColumnRefsEqual,
} from 'sierra-domain/api/insights'
import { ImageUnion } from 'sierra-domain/content/v2/content'
import { AvatarUrl } from 'sierra-domain/user/avatar-url'
import { AvatarData, Unit } from 'sierra-ui/missions/delphi/charts'
import { DurationUnit } from 'sierra-ui/missions/delphi/charts/unit'

const fromFileOrUrl = (userId: string, file: string): { type: 'image'; url: AvatarUrl } | undefined => {
  const url = getAvatarImage(userId, file)
  if (url === undefined) return undefined
  return { type: 'image', url }
}

export const resolveAvatarImageUnionUrl = (
  userId: string,
  image: ImageUnion
): { type: 'image'; url: AvatarUrl } | undefined => {
  switch (image.type) {
    case 'file':
      return fromFileOrUrl(userId, image.file)
    case 'url':
    case 'unsplash':
      return fromFileOrUrl(userId, image.url)
  }
}

export const getYUnit = (
  yAxisDataType: ColumnType['type'] | undefined,
  durationUnit: DurationUnit
): Unit | undefined => {
  switch (yAxisDataType) {
    case 'type.duration':
      return { type: 'duration', unit: durationUnit }
    case 'type.progress':
    case 'type.ratio':
      return { type: 'percentage' }
    default:
      return undefined
  }
}

const getLabelFromRow = (row: TableRow, t: TranslationLookup, indexColumnName: string): string => {
  const value = row.values[indexColumnName]
  return getLabelFromTableValue(value, t)
}

const getIdFromRow = (row: TableRow, indexColumnName: string): string => {
  const value = row.values[indexColumnName]
  return getIdFromTableValue(value)
}

export const getAvatarFromRow = (row: TableRow, indexColumnName: string): AvatarData | undefined => {
  const value = row.values[indexColumnName]

  switch (value?.type) {
    case 'value.user':
      if (value.user === undefined) {
        return undefined
      } else {
        switch (value.user.avatar.type) {
          case 'color':
            return {
              type: 'name',
              color: value.user.avatar.color,
              firstName: value.user.avatar.initials[0] ?? '',
              lastName: value.user.avatar.initials[1] ?? '',
              disabled: value.user.status !== 'active',
            }
          case 'image':
            return resolveAvatarImageUnionUrl(value.user.id, value.user.avatar.image)
        }
      }
      break
    default:
      return undefined
  }
}

const getValueFromDuration = (
  value: Extract<TableValue, { type: 'value.duration' }>,
  durationUnit: DurationUnit
): number => {
  if (value.value === undefined) return 0
  const duration = Duration.fromISO(value.value)
  const durationNumber = duration.shiftTo(durationUnit)[durationUnit]
  return durationNumber
}

export const getValueFromRow = (
  row: TableRow,
  durationUnit: DurationUnit,
  measureColumnName: string
): number => {
  const value = row.values[measureColumnName]

  if (value === undefined) return 0

  switch (value.type) {
    case 'value.double':
    case 'value.long':
      return value.value ?? 0
    case 'value.ratio':
    case 'value.progress':
      return value.value ?? 0
    case 'value.duration':
      return getValueFromDuration(value, durationUnit)
    case 'value.none':
      return 0
    default:
      throw Error("Can't get value from non-numeric column")
  }
}

const isMeasureColumn = (column: TableColumn | undefined): column is MeasureColumn => {
  return column?.ref.type === 'column.measure'
}

const isDimensionColumn = (column: TableColumn | undefined): column is DimensionColumn => {
  return column?.ref.type === 'column.dimension'
}

export const getMeasureColumn = (tableColumn: TableColumn[], widgetMeasureRef: MeasureRef): MeasureColumn => {
  const measureColumnRef = { type: 'column.measure' as const, measure: widgetMeasureRef }
  const maybeMeasureColumn = tableColumn.find(column => areColumnRefsEqual(column.ref, measureColumnRef))
  if (!isMeasureColumn(maybeMeasureColumn)) throw Error('Could not find measure column')

  return maybeMeasureColumn
}

export const getDimensionColumn = (
  tableColumn: TableColumn[],
  widgetIndexDimensionRef: DimensionRef
): DimensionColumn => {
  const indexDimensionColumnRef = { type: 'column.dimension' as const, dimension: widgetIndexDimensionRef }
  const indexDimensionColumn = tableColumn.find(column =>
    areColumnRefsEqual(column.ref, indexDimensionColumnRef)
  )
  if (!isDimensionColumn(indexDimensionColumn)) throw Error('Could not find index dimension column')
  return indexDimensionColumn
}

const getValueType = (row: TableRow, measureColumn: MeasureColumn): TableValue['type'] => {
  const value = row.values[measureColumn.name]
  if (value === undefined) throw Error('Could not find value')
  return value.type
}

const getDurationUnitFromDuration = (duration: Duration): DurationUnit => {
  if (duration.hours > 0) return 'hours'
  if (duration.minutes > 0) return 'minutes'
  return 'seconds'
}

const getLargestDuration = (
  rows: TableRow[],
  measureColumnName: MeasureColumn['name']
): Duration | undefined => {
  const largestDuration = rows.reduce<undefined | Duration>((largestDuration, row) => {
    const value = row.values[measureColumnName]
    if (value === undefined) return largestDuration
    if (value.type !== 'value.duration') return largestDuration
    if (value.value === undefined) return largestDuration

    const duration = Duration.fromISO(value.value)

    if (largestDuration === undefined) return duration
    if (duration.as('millisecond') > largestDuration.as('milliseconds')) {
      return duration
    }

    return largestDuration
  }, undefined)

  return largestDuration
}

const getLargestDurationUnit = (rows: TableRow[], measureColumnName: MeasureColumn['name']): DurationUnit => {
  const largestDuration = getLargestDuration(rows, measureColumnName)
  if (largestDuration === undefined) return 'hours'

  const timeUnit = getDurationUnitFromDuration(largestDuration)
  return timeUnit
}

export const getTableValue = (
  row: TableRow,
  // eslint-disable-next-line @typescript-eslint/no-duplicate-type-constituents
  columnName: MeasureColumn['name'] | DimensionColumn['name']
): TableValue => {
  const value = row.values[columnName]
  if (value === undefined) throw Error('Could not find table value')
  return value
}

export const mapTwoDimensionalData = (
  tableResult: TableResult,
  widget: BarChartWidget | LineChartWidget,
  t: TranslationLookup
): TwoDimensionalMetric => {
  const measureColumn = getMeasureColumn(tableResult.schema.columns, widget.measures[0])
  const indexDimensionColumn = getDimensionColumn(tableResult.schema.columns, widget.dimensions[0])
  const durationUnit = getLargestDurationUnit(tableResult.rows, measureColumn.name)

  const rows: TwoDimensionalMetricRow[] = tableResult.rows.map(row => {
    const rowBase = {
      id: getIdFromRow(row, indexDimensionColumn.name),
      breakdownId: undefined,
      label: getLabelFromRow(row, t, indexDimensionColumn.name),
      value: getValueFromRow(row, durationUnit, measureColumn.name),
      valueType: getValueType(row, measureColumn),
      tableValue: getTableValue(row, indexDimensionColumn.name),
    }

    switch (indexDimensionColumn.type.type) {
      case 'type.user':
        return {
          ...rowBase,
          type: indexDimensionColumn.type.type,
          avatar: getAvatarFromRow(row, indexDimensionColumn.name),
        }
      case 'type.duration':
        return { ...rowBase, type: indexDimensionColumn.type.type, durationUnit }
      default:
        return { ...rowBase, type: indexDimensionColumn.type.type }
    }
  })

  const yAxisDatatype = measureColumn.type.type
  const yUnit = getYUnit(yAxisDatatype, durationUnit)

  const metric = {
    measureColumn,
    indexDimensionColumn,
    durationUnit,
    yUnit,
    rows,
  } satisfies TwoDimensionalMetric

  return metric
}

export const mapOneDimensionalData = (
  tableResult: TableResult,
  t: TranslationLookup,
  widget: OneDimensionalWidget
): OneDimensionalMetric => {
  const measure = widget.measures[0]

  const measureColumn = getMeasureColumn(tableResult.schema.columns, measure)
  const durationUnit = getLargestDurationUnit(tableResult.rows, measureColumn.name)

  const row = tableResult.rows[0]
  if (row === undefined) throw Error('Could not find row')

  const rowBase = {
    value: getValueFromRow(row, durationUnit, measureColumn.name),
    label: labelToString(toLabel(measureColumn.label), t),
  }

  const { type } = measureColumn.type

  switch (type) {
    case 'type.duration':
      return { ...rowBase, type, durationUnit }
    default:
      return { ...rowBase, type }
  }
}

export const getFormattedValue = (twoDimensionalMetricRow: TwoDimensionalMetricRow): number => {
  switch (twoDimensionalMetricRow.valueType) {
    case 'value.ratio':
    case 'value.progress':
      return twoDimensionalMetricRow.value * 100
    default:
      return twoDimensionalMetricRow.value
  }
}
