import { useAtom, useSetAtom } from 'jotai'
import React, { useEffect } from 'react'
import {
  isPictureInPictureSupported,
  isVirtualBackgroundSupported,
} from 'sierra-client/components/liveV2/services/video-call-service'
import { VideoQuality } from 'sierra-client/components/liveV2/services/video-call-service/types'
import { ShortcutMenu, UILabel } from 'sierra-client/components/shortcut-menu'
import { useShortcutMenuDispatch } from 'sierra-client/components/shortcut-menu/context'
import { Logging } from 'sierra-client/core/logging'
import { useTranslation } from 'sierra-client/hooks/use-translation'
import { TranslationKey } from 'sierra-client/hooks/use-translation/types'
import { useDispatch, useSelector } from 'sierra-client/state/hooks'
import {
  setBackgroundBlur,
  setPreferredVideoQuality,
  updateAvailableCameras,
} from 'sierra-client/state/live/actions'
import { selectBackgroundBlurStatus, selectPreferredVideoQuality } from 'sierra-client/state/live/selectors'
import { backgroundBlurEnabledAtom, pictureInPictureEnabledAtom } from 'sierra-client/state/live/settings'
import {
  SettingsDropdownWrapper,
  SettingsText,
  StyledText,
  SwitchSetting,
} from 'sierra-client/views/liveV2/settings/common'
import { IssueMessages } from 'sierra-client/views/liveV2/settings/issues'
import { LiveSettingsTabComponent } from 'sierra-client/views/liveV2/settings/live-tabs'
import { useCameraSettings } from 'sierra-client/views/liveV2/settings/use-camera-settings'
import { LabelMenuItem } from 'sierra-ui/components'
import { Switch, Text, View } from 'sierra-ui/primitives'
import { SingleSelectDropdown } from 'sierra-ui/primitives/menu-dropdown'

const videoQualityItems: { id: string; label: TranslationKey }[] = [
  { id: 'auto', label: 'dictionary.auto' },
  { id: 'low', label: 'dictionary.low' },
  { id: 'off', label: 'live.audio-only' },
]
const useVideoQualitySettings = (): {
  items: LabelMenuItem<string>[]
  currentId: string
  onItemSelected: (item: { id: string }) => void
} => {
  const { t } = useTranslation()
  const videoQuality = useSelector(selectPreferredVideoQuality)
  const dispatch = useDispatch()

  const setVideoQuality = (quality: VideoQuality): void => {
    dispatch(setPreferredVideoQuality(quality))
  }

  const onChangeVideoQuality = (itemId: string): void => {
    const videoQuality = itemId as VideoQuality
    setVideoQuality(videoQuality)
  }

  const menuItems: LabelMenuItem[] = videoQualityItems.map(item => ({
    id: item.id,
    type: 'label' as const,
    label: t(item.label),
    selected: videoQuality === item.id,
  }))

  return {
    currentId: videoQuality,
    items: menuItems,
    onItemSelected: item => onChangeVideoQuality(item.id),
  }
}

export const VideoSettingShortcut: React.FC<{ label: UILabel }> = ({ label }) => {
  const { items, currentId, onItemSelected } = useVideoQualitySettings()
  const dispatch = useShortcutMenuDispatch()
  return (
    <ShortcutMenu.Settings
      label={label}
      permission='ACCESS_LMS'
      items={items}
      currentItemIds={[currentId]}
      onItemSelected={item => {
        onItemSelected(item)
        void dispatch({ type: 'close' })
      }}
      group='live'
    />
  )
}

export const CameraSettingShortcut: React.FC<{ label: UILabel }> = ({ label }) => {
  const settings = useCameraSettings()
  if (settings === undefined) return null
  const { items, currentId, onItemSelected } = settings
  return (
    <ShortcutMenu.Settings
      label={label}
      permission='ACCESS_LMS'
      items={items}
      currentItemIds={[currentId]}
      onItemSelected={onItemSelected}
      group='live'
    />
  )
}

export const VideoSettingsTab: LiveSettingsTabComponent = () => {
  const dispatch = useDispatch()
  const { t } = useTranslation()
  const [pictureInPictureEnabled, setPictureInPictureEnabled] = useAtom(pictureInPictureEnabledAtom)
  const backgroundBlurEnabled = useSelector(selectBackgroundBlurStatus)
  const saveBackgroundBlurSetting = useSetAtom(backgroundBlurEnabledAtom)

  useEffect(() => {
    // The list of devices is cached for performance reasons and it should be up to date,
    // however, since we aren't guarantied to get a callback when a users devices change,
    // we make sure to update the list of available cameras when the user opens the
    // video tab
    void dispatch(updateAvailableCameras())
  }, [dispatch])

  const updateBackgroundBlurSetting = async (): Promise<void> => {
    const res = await dispatch(setBackgroundBlur({ enabled: !backgroundBlurEnabled })).unwrap()
    saveBackgroundBlurSetting(res.enabled)
    void dispatch(Logging.liveSession.backgroundBlurToggled({ enabled: !backgroundBlurEnabled }))
  }

  const {
    currentId: videoQuality,
    items: videoQualityItems,
    onItemSelected: onChangeVideoQuality,
  } = useVideoQualitySettings()

  const cameraSettings = useCameraSettings()

  const selectedCameraSetting = cameraSettings?.items.find(item => item.id === cameraSettings.currentId)
  const selectedVideoQuality = videoQualityItems.find(item => item.id === videoQuality)

  return (
    <View gap='medium' direction='column'>
      <View direction='column' gap='xsmall'>
        {/** TODO: move this to the network tab when it's done */}
        <IssueMessages category='network' />
        <IssueMessages category='video' />
        <SettingsText>{t('dictionary.camera')}</SettingsText>
        {cameraSettings ? (
          <SettingsDropdownWrapper>
            <SingleSelectDropdown
              selectedItem={selectedCameraSetting}
              menuItems={cameraSettings.items}
              onSelect={cameraSettings.onItemSelected}
            />
          </SettingsDropdownWrapper>
        ) : (
          <Text color='foreground/muted' size='small'>
            {t('live.no-cameras-available')}
          </Text>
        )}
      </View>
      <View direction='column' gap='xsmall'>
        <SettingsText>{t('dictionary.video-quality')}</SettingsText>
        <SettingsDropdownWrapper>
          <SingleSelectDropdown
            selectedItem={selectedVideoQuality}
            menuItems={videoQualityItems}
            onSelect={onChangeVideoQuality}
          />
        </SettingsDropdownWrapper>
      </View>
      <View direction='column' gap='4'>
        <SwitchSetting>
          <SettingsText>{t('dictionary.background-blur')}</SettingsText>
          <Switch
            disabled={!isVirtualBackgroundSupported}
            size='small'
            checked={backgroundBlurEnabled}
            onChange={updateBackgroundBlurSetting}
            ariaLabel={t('dictionary.background-blur')}
          />
        </SwitchSetting>
        {!isVirtualBackgroundSupported ? (
          <StyledText color='foreground/muted' size='small'>
            {t('live.supported-browsers-all')}
          </StyledText>
        ) : (
          <StyledText color='foreground/muted' size='small'>
            {t('live.background-blur-info')}
          </StyledText>
        )}
      </View>
      <View direction='column' gap='4'>
        <SwitchSetting>
          <SettingsText>{t('dictionary.picture-in-picture')}</SettingsText>
          <Switch
            disabled={!isPictureInPictureSupported}
            size='small'
            checked={pictureInPictureEnabled}
            onChange={() => {
              setPictureInPictureEnabled(current => {
                void dispatch(Logging.liveSession.pictureInPictureSettingToggled({ enabled: !current }))
                return !current
              })
            }}
            ariaLabel={t('dictionary.picture-in-picture')}
          />
        </SwitchSetting>
        {!isPictureInPictureSupported ? (
          <StyledText color='foreground/muted' size='small'>
            {t('live.supported-browsers-all')}
          </StyledText>
        ) : (
          <StyledText color='foreground/muted' size='small'>
            {t('live.picture-in-picture-info')}
          </StyledText>
        )}
      </View>
    </View>
  )
}
