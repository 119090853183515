import { AnimatePresence } from 'framer-motion'
import { isEqual } from 'lodash'
import { useCallback } from 'react'
import { SquareAvatar, SquareAvatarSize } from 'sierra-client/components/common/square-avatar'
import { useSelectCurrentCard } from 'sierra-client/components/liveV2/hooks/use-select-current-card'
import { useTranslation } from 'sierra-client/hooks/use-translation'
import { useSelector } from 'sierra-client/state/hooks'
import { selectBreakoutRoom, selectRoomParticipants } from 'sierra-client/state/live-session/selectors'
import { useUserLegacy } from 'sierra-client/state/users/hooks'
import { UserId } from 'sierra-domain/api/uuid'
import { color } from 'sierra-ui/color'
import { TruncatedText } from 'sierra-ui/components'
import { Button, Text, View } from 'sierra-ui/primitives'
import styled from 'styled-components'

const ParticipantPreview = ({
  participantId,
  size,
}: {
  participantId: UserId
  size: SquareAvatarSize
}): JSX.Element => {
  const user = useUserLegacy(participantId)

  return (
    <SquareAvatar
      size={size}
      firstName={user?.firstName}
      lastName={user?.lastName}
      image={user?.avatar}
      color={user?.avatarColor}
      showName
    />
  )
}
const RoomPreview = styled(View).attrs({ direction: 'column', padding: 'small' })`
  width: 100%;
  min-height: 20rem;
  background-color: ${p => color(p.theme.home.backgroundColor).shift(0.1).toString()};
  border-radius: ${p => p.theme.borderRadius.large};
`

const InfoVIew = styled(View).attrs({
  direction: 'column',
  grow: true,
  alignItems: 'flex-start',
  gap: '2',
})`
  margin-bottom: 1.5rem;
`

const TitleRow = styled(View)`
  width: 100%;
`

const ShiftedText = styled(Text)`
  color: ${p => color(p.theme.home.backgroundColor).shift(0.4).toString()};
`

const ShiftedTruncatedText = styled(TruncatedText)`
  color: ${p => color(p.theme.home.backgroundColor).shift(0.4).toString()};
`

export const BreakoutRoomPreview = ({
  roomId,
  onClick,
}: {
  roomId: string
  onClick: (roomId: string) => void
}): JSX.Element => {
  const roomTitle = useSelector(state => selectBreakoutRoom(state, roomId)?.title)
  const currentCard = useSelectCurrentCard()
  const topic = currentCard?.data.type === 'breakout-room' ? currentCard.data.topic : undefined
  const roomParticipants = useSelector(
    state => selectRoomParticipants(state, roomId).map(p => ({ agoraUID: p.agoraUID, userId: p.userId })),
    isEqual
  )
  const { t } = useTranslation()

  const handleOnClick = useCallback(() => onClick(roomId), [onClick, roomId])

  return (
    <RoomPreview>
      <InfoVIew>
        <TitleRow>
          <View grow>
            <TruncatedText size='small' bold lines={1} color='white'>
              {roomTitle ?? ''}
            </TruncatedText>
          </View>
          <View>
            <Button onClick={handleOnClick}>{t('live.breakout-room.join')}</Button>
          </View>
        </TitleRow>
        {topic !== undefined && (
          <View>
            <ShiftedTruncatedText color='LEGACY_DEFAULT_TEXT_COLOR_REPLACE_ASAP' size='regular' lines={2}>
              {topic}
            </ShiftedTruncatedText>
          </View>
        )}
      </InfoVIew>
      <View wrap='wrap'>
        <AnimatePresence>
          {roomParticipants.map(participant => (
            <View
              key={participant.agoraUID}
              animated
              initial={{ opacity: 0 }}
              animate={{ opacity: 1 }}
              exit={{ opacity: 0 }}
              layout
            >
              <ParticipantPreview
                key={participant.agoraUID}
                participantId={participant.userId}
                size={roomParticipants.length > 6 ? 'small' : 'medium'}
              />
            </View>
          ))}
        </AnimatePresence>
      </View>
      <View>
        <ShiftedText color='LEGACY_DEFAULT_TEXT_COLOR_REPLACE_ASAP' size='micro' bold>
          {t('live.breakout-room.count-people-in-the-room', { count: roomParticipants.length })}
        </ShiftedText>
      </View>
    </RoomPreview>
  )
}
