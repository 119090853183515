import { useCallback, useEffect, useRef } from 'react'

/**
 * This hook returns a function with a stable reference that wraps the latest
 * version of the function passed to it. This is useful when you want to use a
 * function in a callback that may change over time, but you don't want to
 * create a new callback every time the function changes.
 */
export function useStableFunction<Args extends unknown[], Ret>(fn: (...args: Args) => Ret): typeof fn {
  const fnRef = useRef(fn)

  useEffect(() => {
    fnRef.current = fn
  }, [fn])

  return useCallback((...args) => fnRef.current(...args), [])
}
