import { RouterEvents } from '@tanstack/react-router'
import { useEffect, useRef } from 'react'
import { getGlobalRouter } from 'sierra-client/router'

/**
 * Triggers a callback when the location changes, providing information about where from and to.
 */
export function useOnLocationChange(
  callback: (event: RouterEvents['onBeforeLoad']) => void,
  condition = true
): void {
  // Ensure callback prop is stable.
  const callbackRef = useRef(callback)
  callbackRef.current = callback

  useEffect(() => {
    if (condition !== true) {
      return undefined
    }

    const handler = (event: RouterEvents['onBeforeLoad']): void => {
      // It calls it "pathChanged", but it's really comparing the location.href under the hood.
      if (event.pathChanged === false) {
        return
      }

      callbackRef.current(event)
    }

    const unsubscribe = getGlobalRouter().subscribe('onBeforeLoad', handler)

    return () => unsubscribe()
  }, [condition])
}
