import DOMPurify from 'dompurify'
import katex from 'katex'
import 'katex/dist/katex.min.css'
import React from 'react'
import { FCC } from 'sierra-client/types'
import { assertNever } from 'sierra-domain/utils'
import { QuestionElementWithMath } from 'sierra-domain/v3-author'
import { SimpleStyledCSS } from 'sierra-ui/utils'
import { Text as SlateText } from 'slate'
import { useSelected } from 'slate-react'
import styled, { css } from 'styled-components'

const Placeholder = styled.span.attrs({ contentEditable: false })`
  position: absolute;
  user-select: none;
  pointer-events: none;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  margin: auto;
  color: ${p => p.theme.color.grey35};
`

type RichQuestionTextPart = { type: 'plain'; value: string } | { type: 'math'; value: string }

const getQuestionTextParts = (s: string | undefined): RichQuestionTextPart[] => {
  if (s === undefined) return []

  const parts: RichQuestionTextPart[] = []
  const regex = /<math>(.*?)<\/math>/g

  let prevEnd = 0
  let m
  while ((m = regex.exec(s)) !== null) {
    const matchStart = m.index

    // Push plain text up to start of match
    parts.push({ type: 'plain', value: s.slice(prevEnd, matchStart) })

    // Push first captured group
    parts.push({ type: 'math', value: m[1] ?? '' })

    // Store the index of the end of the match
    prevEnd = regex.lastIndex
  }

  parts.push({ type: 'plain', value: s.slice(prevEnd) })

  return parts.filter(({ value }) => value !== '')
}

const TextWithMath: React.FC<{ text: string }> = ({ text }) => {
  const textWithMath = React.useMemo(() => {
    return getQuestionTextParts(text).map(({ type, value }, i) => {
      switch (type) {
        case 'plain':
          return <span key={i}>{value}</span>
        case 'math': {
          try {
            const html = katex.renderToString(value)
            return <span key={i} dangerouslySetInnerHTML={{ __html: DOMPurify.sanitize(html) }} />
          } catch (e) {
            console.error(e)
            return null
          }
        }
        default:
          assertNever(type)
      }
    })
  }, [text])

  return <>{textWithMath}</>
}

const PlainText = styled.span``

const MathText = styled.span`
  user-select: none;

  * {
    user-select: none;
  }
  .katex {
    font-size: 1em;
    line-height: 1em;
  }
`

const getShowPlainCSS = (showPlain: boolean): SimpleStyledCSS => css`
  ${PlainText} {
    ${!showPlain ? 'position: absolute;' : ''}
    ${showPlain ? 'display: block' : ''};
    ${showPlain ? 'opacity: 1' : 'opacity: 0'};
    z-index: 10;
  }

  ${MathText} {
    display: ${showPlain ? 'none' : 'block'};
  }
`

const DisplayTransparent = styled.span`
  position: absolute;
  left: 0;
  opacity: 0;
  cursor: default;
`

const MathContainer = styled.div<{ showPlain: boolean }>`
  ${p => getShowPlainCSS(p.showPlain)}
`

export const QuestionTextWithMath: FCC<{
  element: QuestionElementWithMath
  className?: string
  placeholder?: string
  readOnly?: boolean
}> = ({ element, className, placeholder, readOnly, children }) => {
  const selected = useSelected()
  const plainText = React.useMemo(
    () => element.children.map(it => (SlateText.isText(it) ? it.text : '')).join(''),
    [element.children]
  )

  const hasMath = React.useMemo(() => /<math>(.*?)<\/math>/.test(plainText), [plainText])
  const showPlain = selected || !hasMath

  return (
    <>
      {readOnly === true ? (
        <>
          {hasMath ? (
            <>
              <MathText contentEditable={false}>
                <TextWithMath text={plainText} />
              </MathText>
              <DisplayTransparent>{children}</DisplayTransparent>
            </>
          ) : (
            <PlainText>{children}</PlainText>
          )}
        </>
      ) : (
        <MathContainer className={className} showPlain={showPlain === true}>
          <PlainText>{children}</PlainText>
          {!(showPlain === true) && (
            <MathText contentEditable={false}>
              <TextWithMath text={plainText} />
            </MathText>
          )}
          {placeholder !== undefined && <Placeholder>{placeholder}</Placeholder>}
        </MathContainer>
      )}
    </>
  )
}
