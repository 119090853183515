import { AnimatePresence, motion } from 'framer-motion'
import { useRef, useState } from 'react'
import { isNonNullable } from 'sierra-domain/utils'
import { color } from 'sierra-ui/color'
import { EditableText, Icon, Label } from 'sierra-ui/components'
import { Text, View } from 'sierra-ui/primitives'
import { token } from 'sierra-ui/theming'
import { Margin } from 'sierra-ui/types'
import { resolveSizes } from 'sierra-ui/utils'
import styled, { css } from 'styled-components'

export type Response = {
  responseId: string
  response?: string
  skipped?: boolean
  correct?: boolean
  attempts?: number
  fastestCorrect?: boolean
}

export const EmptyPlaceholder = styled(View).attrs({ padding: 'xxsmall none' })``

export const EmptyText = styled(Text).attrs({
  color: 'foreground/muted',
})``

export const Divider = styled.hr<{ $marginTop?: Margin; $marginBottom?: Margin; $grow?: boolean }>`
  width: 100%;
  min-height: 1px;
  max-height: 1px;
  margin: unset;
  background-color: ${token('surface/strong')};

  ${p =>
    p.$grow === true &&
    css`
      flex-grow: 1;
    `};
  ${p => isNonNullable(p.$marginTop) && `margin-top: ${resolveSizes(p.$marginTop)};`}
  ${p => isNonNullable(p.$marginBottom) && `margin-bottom: ${resolveSizes(p.$marginBottom)};`}
`

const StyledLabel = styled(Label)`
  background-color: ${p => color(p.theme.home.backgroundColor).shift(0.05).toString()};
  border: none;
`

const StyledEditableText = styled(EditableText)`
  color: black;
  font-weight: 500;
`

const HeaderRow = styled.div`
  display: flex;
  flex-direction: row;
  gap: 12px;
`

export const CollapsableSidebarItem: React.FC<
  React.PropsWithChildren<{ title: string; itemCount?: number; onTitleChanged?: (newTitle: string) => void }>
> = ({ title, itemCount, children, onTitleChanged }): JSX.Element => {
  const [open, setOpen] = useState(true)
  const inputRef = useRef<HTMLParagraphElement>(null)
  const [contentEditable, setContentEditable] = useState(false)

  const onNewTitle = (newTitle: string): void => {
    onTitleChanged?.(newTitle)
  }

  const toggle = (): void => setOpen(isOpen => !isOpen)

  const isEditable = onTitleChanged !== undefined

  return (
    <View direction='column'>
      <HeaderRow onClick={!isEditable ? toggle : undefined}>
        <StyledEditableText
          ref={inputRef}
          contentEditable={isEditable && contentEditable}
          setContentEditable={setContentEditable}
          value={title}
          onRename={onNewTitle}
        />
        <View cursor='pointer' onClick={toggle}>
          {itemCount !== undefined && (
            <View>
              <StyledLabel $size='small' $color='black' $bgColor='grey10'>
                <span>{itemCount}</span>
              </StyledLabel>
            </View>
          )}
          <motion.div animate={{ rotate: open ? 0 : -90 }}>
            <Icon iconId='chevron--down' size='size-12' color='black' />
          </motion.div>
        </View>
      </HeaderRow>
      <AnimatePresence>
        {open && (
          <motion.div
            key='collapsable-children'
            initial={{ opacity: 0, y: -8 }}
            animate={{ opacity: 1, y: 0 }}
            exit={{ opacity: 0, y: -8 }}
            layout
            transition={{ type: 'tween', duration: 150 / 1000 }}
          >
            {children}
          </motion.div>
        )}
      </AnimatePresence>
    </View>
  )
}
