import { IMicrophoneAudioTrack } from 'agora-rtc-sdk-ng'
import { useState } from 'react'
import { useLiveContext } from 'sierra-client/components/liveV2/live-context'
import { useInterval } from 'sierra-client/hooks/use-interval'
import { useSelector } from 'sierra-client/state/hooks'
import { selectCallIsActive } from 'sierra-client/state/live/selectors'
import { useOnChanged } from 'sierra-ui/utils'

const VOLUME_THRESHOLD = 0.6
const VOLUME_POLL_INTERVAL = 1500
const MUTE_POLL_INTERVAL = 500

export const useMutedSpeakingCheck = ({
  enabled,
  localAudioTrack,
  localNonPublishedAudioTrack,
  callback,
}: {
  enabled: boolean
  localAudioTrack: IMicrophoneAudioTrack | undefined
  localNonPublishedAudioTrack: IMicrophoneAudioTrack | undefined
  callback: () => void
}): { isMuted: boolean; isSpeaking: boolean } => {
  const [isMuted, setIsMuted] = useState(false)
  const [isSpeaking, setIsSpeaking] = useState(false)

  const callIsActive = useSelector(selectCallIsActive)
  const { left } = useLiveContext()

  useInterval(() => {
    if (enabled) {
      const speaking =
        localNonPublishedAudioTrack !== undefined &&
        localNonPublishedAudioTrack.getVolumeLevel() > VOLUME_THRESHOLD
      setIsSpeaking(speaking)
    }
  }, VOLUME_POLL_INTERVAL)

  useInterval(() => {
    if (enabled) {
      const muted = localAudioTrack !== undefined && localAudioTrack.muted
      setIsMuted(muted)
    }
  }, MUTE_POLL_INTERVAL)

  const showSpeakingWhileMutedMessage = enabled && isMuted && isSpeaking && callIsActive && !left
  useOnChanged((prev, curr) => {
    if (prev !== true && curr === true) {
      callback()
    }
  }, showSpeakingWhileMutedMessage)

  return { isMuted, isSpeaking }
}
