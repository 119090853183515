import { useCallback, useMemo, useRef, useState } from 'react'
import { usePost } from 'sierra-client/hooks/use-post'
import { PotentialGroupAdmin } from 'sierra-domain/api/manage'
import { XRealtimeAdminGroupsListPotentialAdmins, XRealtimeAdminGroupsSetRoles } from 'sierra-domain/routes'

type UseGroupAdminsParams = {
  groupId: string
  currentAdmins: PotentialGroupAdmin[] | undefined
  updateGroupAdmins: (newAdmins: PotentialGroupAdmin[]) => void
}

export type UseGroupAdminsState = {
  loading: boolean
  currentAdmins: PotentialGroupAdmin[]
  potentialAdmins: PotentialGroupAdmin[]
  fetchAll: () => Promise<void>
  addAdmins: (params: { adminIds: string[] }) => Promise<void>
  removeAdmins: (params: { adminIds: string[] }) => Promise<void>
}

export const useGroupAdmins = ({
  groupId,
  currentAdmins,
  updateGroupAdmins,
}: UseGroupAdminsParams): UseGroupAdminsState => {
  const { postWithUserErrorException } = usePost()
  const [admins, setAdmins] = useState<PotentialGroupAdmin[]>([])
  const [loading, setLoading] = useState(false)
  const requestIdRef = useRef(0)

  const fetchAll = useCallback(async () => {
    requestIdRef.current++ // Cancel pending requests
    const requestId = requestIdRef.current

    setLoading(true)

    try {
      const res = await postWithUserErrorException(XRealtimeAdminGroupsListPotentialAdmins, {
        groupId,
      })

      if (requestId === requestIdRef.current) {
        setAdmins(res.users)
        updateGroupAdmins(res.users.filter(admin => admin.isAdminForGroup))
      }
    } finally {
      if (requestId === requestIdRef.current) {
        setLoading(false)
      }
    }
  }, [postWithUserErrorException, updateGroupAdmins, groupId])

  const potentialAdmins = useMemo(() => admins.filter(admin => !admin.isAdminForGroup), [admins])

  const addAdmins = useCallback<UseGroupAdminsState['addAdmins']>(
    async ({ adminIds }) => {
      await postWithUserErrorException(XRealtimeAdminGroupsSetRoles, {
        groupId,
        users: Object.fromEntries(
          adminIds.map(adminId => [adminId, { type: 'assign', role: 'admin' } as const])
        ),
      })

      setAdmins(curr => {
        const newArr = curr.map(admin =>
          adminIds.includes(admin.userId) ? { ...admin, isAdminForGroup: true } : admin
        )

        updateGroupAdmins(newArr.filter(admin => admin.isAdminForGroup))

        return newArr
      })
    },
    [postWithUserErrorException, updateGroupAdmins, groupId]
  )

  const removeAdmins = useCallback<UseGroupAdminsState['removeAdmins']>(
    async ({ adminIds }) => {
      await postWithUserErrorException(XRealtimeAdminGroupsSetRoles, {
        groupId,
        users: Object.fromEntries(adminIds.map(adminId => [adminId, { type: 'unassign' } as const])),
      })

      setAdmins(curr => {
        const newArr = curr.map(admin =>
          adminIds.includes(admin.userId) ? { ...admin, isAdminForGroup: false } : admin
        )

        updateGroupAdmins(newArr.filter(admin => admin.isAdminForGroup))

        return newArr
      })
    },
    [postWithUserErrorException, updateGroupAdmins, groupId]
  )

  return useMemo(
    () => ({
      loading,
      currentAdmins: currentAdmins ?? [],
      potentialAdmins,
      addAdmins,
      removeAdmins,
      fetchAll,
    }),
    [loading, currentAdmins, potentialAdmins, addAdmins, removeAdmins, fetchAll]
  )
}
