import { Atom, PrimitiveAtom, atom, useAtomValue, useSetAtom } from 'jotai'
import { selectAtom } from 'jotai/utils'
import _ from 'lodash'
import { default as React, useCallback, useContext, useEffect, useMemo, useRef } from 'react'
import { useDeepEqualityMemo } from 'sierra-client/hooks/use-deep-equality-memo'
import { useStableFunction } from 'sierra-client/hooks/use-stable-function'
import { useTranslation } from 'sierra-client/hooks/use-translation'
import { FCC } from 'sierra-client/types'
import { pathsByElementId } from 'sierra-client/views/v3-author/command'
import * as ShortcutCommands from 'sierra-client/views/v3-author/slash-menu/event-handlers'
import { READONLY_SLASH_MENU_STATE, SlashMenuState } from 'sierra-domain/v3-author/slash-menu'
import { BaseSelection, Descendant, Editor, Node, Path, Range } from 'slate'
import { useSlateSelector, useSlateStatic } from 'slate-react'

const _EditorJotaiContext = React.createContext<EditorJotaiContextValue | undefined>(undefined)

function useEditorJotaiContext(): EditorJotaiContextValue {
  const context = useContext(_EditorJotaiContext)
  if (context === undefined) throw new Error('Must be wrapped in <EditorJotaiContext>')
  return context
}
type Paths = { [nodeId: string]: Path }

type EditorJotaiContextValue = {
  documentAtom: Atom<Descendant[]>
  pathsAtom: Atom<Paths>
  pathsRef: React.MutableRefObject<Paths>
  currentSelectionAtom: Atom<BaseSelection>
  latestValidSelectionAtom: Atom<BaseSelection>
  latestValidSelectionRef: React.MutableRefObject<BaseSelection>
  slashMenuAtom: PrimitiveAtom<SlashMenuState>
}

export const EditorJotaiContext: FCC<{
  readOnly: boolean
  editor: Editor
  supportedSlashMenuEntryIds: SlashMenuState['idsSupportedByEditor']
}> = ({ readOnly, children, editor, supportedSlashMenuEntryIds }) => {
  const documentAtom = useMemo(() => atom(editor.children), [editor])
  const pathsAtom = useMemo(() => atom<Paths>(pathsByElementId(editor)), [editor])
  const pathsRef = useRef<Paths>({})
  const currentSelectionAtom = useMemo(() => atom(editor.selection), [editor])
  const latestValidSelectionAtom = useMemo(() => atom(editor.selection), [editor])
  const latestValidSelectionRef = useRef<BaseSelection>(null)

  const idsSupportedByEditor = useDeepEqualityMemo(supportedSlashMenuEntryIds)
  const slashMenuAtom = useMemo(
    () => atom<SlashMenuState>({ type: 'idle', idsSupportedByEditor }),
    [idsSupportedByEditor]
  )

  const setDocumentAtom = useSetAtom(documentAtom)
  const setPathsAtom = useSetAtom(pathsAtom)
  const setCurrentSelectionAtom = useSetAtom(currentSelectionAtom)
  const setLatestValidSelectionAtom = useSetAtom(latestValidSelectionAtom)

  const refreshAtoms = useCallback(
    (editor: Editor) => {
      setDocumentAtom(editor.children)

      setCurrentSelectionAtom(previousSelection => {
        const newSelection = editor.selection
        if (_.isEqual(previousSelection, newSelection)) return previousSelection
        else return newSelection
      })

      setLatestValidSelectionAtom(previousSelection => {
        const newSelection = editor.selection
        if (newSelection === null) return previousSelection

        const result = _.isEqual(previousSelection, newSelection) ? previousSelection : newSelection

        latestValidSelectionRef.current = result

        return result
      })

      setPathsAtom((previous: Paths): Paths => {
        const newPaths: Paths = {}

        for (const [{ id }, path] of Node.elements(editor)) {
          // Only update the paths that have actually changed
          const previousPath = previous[id]
          newPaths[id] = previousPath !== undefined && _.isEqual(previousPath, path) ? previousPath : path
        }

        pathsRef.current = newPaths

        return newPaths
      })
    },
    [setCurrentSelectionAtom, setDocumentAtom, setLatestValidSelectionAtom, setPathsAtom]
  )

  const setSlashMenuState = useSetAtom(slashMenuAtom)
  const { dynamicT } = useTranslation()
  const updateSlashMenu = useCallback(
    (editor: Editor) => {
      setSlashMenuState(shortcutState => {
        if (readOnly) {
          return READONLY_SLASH_MENU_STATE
        }

        const newShortcutState = ShortcutCommands.onUpdate(editor, shortcutState, dynamicT)
        if (_.isEqual(shortcutState, newShortcutState)) return shortcutState
        else return newShortcutState
      })
    },
    [dynamicT, readOnly, setSlashMenuState]
  )

  const stablePatchEditor = useStableFunction((editor: Editor): void => {
    const { onChange } = editor

    refreshAtoms(editor)
    updateSlashMenu(editor)

    editor.onChange = (...args) => {
      refreshAtoms(editor)
      updateSlashMenu(editor)

      return onChange(...args)
    }
  })

  useEffect(() => {
    // Apply patch again only if editor instance changes.
    // Note: Remounting this component will also apply the patch again.
    stablePatchEditor(editor)
  }, [stablePatchEditor, editor])

  const value: EditorJotaiContextValue = useMemo(
    () => ({
      documentAtom,
      pathsAtom,
      pathsRef,
      latestValidSelectionAtom,
      latestValidSelectionRef,
      currentSelectionAtom,
      slashMenuAtom,
    }),
    [documentAtom, pathsAtom, latestValidSelectionAtom, currentSelectionAtom, slashMenuAtom]
  )

  return <_EditorJotaiContext.Provider value={value}>{children}</_EditorJotaiContext.Provider>
}

export function useSetSlashMenuState(): (_: (oldState: SlashMenuState) => SlashMenuState) => void {
  const editor = useSlateStatic()
  const { slashMenuAtom } = useEditorJotaiContext()
  const setAtom = useSetAtom(slashMenuAtom)
  return useCallback(
    (createNewState: (_: SlashMenuState) => SlashMenuState) => {
      setAtom(previousState => {
        const newState = createNewState(previousState)

        if (_.isEqual(previousState, newState)) return previousState

        const previousType = previousState.type
        const newType = newState.type
        if (previousType !== newType) {
          switch (newType) {
            case 'active':
              editor.pushActionsLogEntry('open-slash-menu')
              break
            case 'idle':
              editor.pushActionsLogEntry('close-slash-menu')
              break
            default:
              newType satisfies never
          }
        }

        return newState
      })
    },
    [editor, setAtom]
  )
}

export function useSlashMenuState(): SlashMenuState {
  const { slashMenuAtom } = useEditorJotaiContext()
  return useAtomValue(slashMenuAtom)
}

const noPath: Path = []
export function useEditorJotaiPath(nodeId: string): Path {
  const { pathsAtom } = useEditorJotaiContext()

  const pathAtom = useMemo(
    () => selectAtom(pathsAtom, paths => paths[nodeId] ?? noPath, _.isEqual),
    [pathsAtom, nodeId]
  )
  const value = useAtomValue(pathAtom)

  return value
}

export function useEditorJotaiDocument(): Descendant[] {
  const { documentAtom } = useEditorJotaiContext()
  return useAtomValue(documentAtom)
}

export function useEditorJotaiLatestValidSelection(): BaseSelection {
  const { latestValidSelectionAtom } = useEditorJotaiContext()
  return useAtomValue(latestValidSelectionAtom)
}

/**
 * Note: This hook will _not_ trigger rerenders when the path changes.
 * It returns a stable function that returns the current value when called.
 */
export function useGetPathForId(): (nodeId: string) => Path | undefined {
  const { pathsRef } = useEditorJotaiContext()

  return useStableFunction(nodeId => pathsRef.current[nodeId])
}

/**
 * Note: This hook will _not_ trigger rerenders when the selection changes.
 * It returns a stable function that returns the current value when called.
 */
export function useReadLatestValidSelection(): () => BaseSelection {
  const { latestValidSelectionRef } = useEditorJotaiContext()

  return useCallback(() => latestValidSelectionRef.current, [latestValidSelectionRef])
}

export function isPathInSelection({
  selection,
  path,
  onlyCollapsed,
}: {
  selection: BaseSelection
  path: Path | undefined
  onlyCollapsed: boolean
}): boolean {
  if (path === undefined) return false
  if (selection === null) return false
  if (onlyCollapsed && !Range.isCollapsed(selection)) return false

  return Range.includes(selection, path)
}

export function useEditorJotaiIsInLatestSelection({
  nodeId,
  onlyCollapsed = false,
}: {
  nodeId: string
  onlyCollapsed?: boolean
}): boolean {
  const { latestValidSelectionAtom, pathsAtom } = useEditorJotaiContext()

  const isInLatestSelectionAtom = useMemo(() => {
    return atom(get => {
      const path = get(pathsAtom)[nodeId]
      const latestSelection = get(latestValidSelectionAtom)

      return isPathInSelection({
        selection: latestSelection,
        path,
        onlyCollapsed,
      })
    })
  }, [latestValidSelectionAtom, pathsAtom, nodeId, onlyCollapsed])

  return useAtomValue(isInLatestSelectionAtom)
}

export function useEditorJotaiIsInCurrentSelection({
  nodeId,
  onlyCollapsed = false,
}: {
  nodeId: string
  onlyCollapsed?: boolean
}): boolean {
  const getPathForId = useGetPathForId()

  const selectIsInCurrentSelection = useCallback(
    (editor: Editor): boolean => {
      const { selection } = editor
      const path = getPathForId(nodeId)

      return isPathInSelection({
        selection,
        path,
        onlyCollapsed,
      })
    },
    [getPathForId, nodeId, onlyCollapsed]
  )

  return useSlateSelector(selectIsInCurrentSelection)
}
