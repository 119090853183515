import { useCallback, useEffect, useState } from 'react'
import { useNotif } from 'sierra-client/components/common/notifications'
import { usePost } from 'sierra-client/hooks/use-post'
import { TagDetail } from 'sierra-domain/api/admin'
import { TagId } from 'sierra-domain/api/nano-id'
import { TagData } from 'sierra-domain/content/tag'
import { XRealtimeAdminTagsTagDetail, XRealtimeAdminTagsUpdateTag } from 'sierra-domain/routes'

type UseTagDetailsData = {
  isLoading: boolean
  tag?: TagDetail
  fetchTag: () => Promise<void>
  updateTag: (data: TagData) => Promise<void>
}

export const useTagDetails = (tagId: TagId): UseTagDetailsData => {
  const { postWithUserErrorException } = usePost()
  const [isLoading, setIsLoading] = useState(false)
  const [tag, setTag] = useState<TagDetail | undefined>(undefined)
  const notifications = useNotif()

  const fetchTag = useCallback(async () => {
    setIsLoading(true)
    setTag(undefined)

    const response = await postWithUserErrorException(XRealtimeAdminTagsTagDetail, {
      id: tagId,
    })

    setTag(response)
    setIsLoading(false)
  }, [postWithUserErrorException, tagId])

  const updateTag = useCallback(
    async (tagData: TagData) => {
      await postWithUserErrorException(XRealtimeAdminTagsUpdateTag, {
        id: tagId,
        data: tagData,
      })

      setTag(tag => tag && { ...tag, tag: { ...tag.tag, data: tagData } })
      notifications.push({ type: 'skill-saved' })
    },
    [postWithUserErrorException, tagId, notifications]
  )

  useEffect(() => {
    void fetchTag()
  }, [fetchTag])

  return { isLoading, tag, fetchTag, updateTag }
}
