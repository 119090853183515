import React, { FC } from 'react'
import { VideoPlayerProps } from 'sierra-client/components/blocks/video/types'
import { color } from 'sierra-ui/color'
import { palette } from 'sierra-ui/theming'
import { fonts } from 'sierra-ui/theming/fonts'
import { createGlobalStyle } from 'styled-components'
import 'video.js/dist/video-js.css'

const GlobalPlayerStyles = createGlobalStyle`
  .vjs-overwrite {
    cursor: pointer;
    background: none;
    
    & .vjs-control-bar {
      height: 15%;
      min-height: 3rem;
      background: transparent;
      padding: 0 0.5rem;

      &::before {
        content: '';
        position: absolute;
        left: 0;
        right: 0;
        bottom: 0;
        width: 100%;
        height: 100%;
        background: linear-gradient(180deg, rgba(0, 0, 0, 0) 0%, rgba(0, 0, 0, 0.00838519) 11.79%, rgba(0, 0, 0, 0.0324148) 21.38%, rgba(0, 0, 0, 0.0704) 29.12%, rgba(0, 0, 0, 0.120652) 35.34%, rgba(0, 0, 0, 0.181481) 40.37%, rgba(0, 0, 0, 0.2512) 44.56%, rgba(0, 0, 0, 0.328119) 48.24%, rgba(0, 0, 0, 0.410548) 51.76%, rgba(0, 0, 0, 0.4968) 55.44%, rgba(0, 0, 0, 0.585185) 59.63%, rgba(0, 0, 0, 0.674015) 64.66%, rgba(0, 0, 0, 0.7616) 70.88%, rgba(0, 0, 0, 0.846252) 78.62%, rgba(0, 0, 0, 0.926281) 88.21%, #000000 100%);
      }
    }

    & .vjs-icon-placeholder::before {
      display: flex;
      align-items: center;
      justify-content: center;
    }

    & .vjs-volume-horizontal {
      align-self: center;
    }

    & .vjs-icon-placeholder::before {
      content: '' !important;
    }

    && .vjs-big-play-button,
    &&:hover .vjs-big-play-button {
      border-radius: 999rem;
      width: 4rem;
      height: 4rem;
      background-color: ${palette.primitives.white};

      & .vjs-icon-placeholder::before {
        background: center center no-repeat url('/static/images/icons/play--filled--black.svg');
      }
    }

    & .vjs-slider {
      background-color: ${color(palette.primitives.white).opacity(0.25).toString()};
    }

    & .vjs-load-progress {
      background-color: transparent;
      & div {
        background-color: transparent;
      }
    }

    & .vjs-progress-holder {
      height: 2px;
    }
    
    & .vjs-control:focus, .vjs-slider:focus {
      text-shadow: none;
      box-shadow: none;
    }

    & .vjs-progress-control:hover .vjs-play-progress:before {
      opacity: 1;
    }

    & .vjs-play-progress:before {
      opacity: 0;
      font-size: 8px;
      right: -0.5em;
      top: -0.3333333333em;
    }

    & .vjs-remaining-time-display, .vjs-playback-rate-value {
      ${fonts.body.micro}
      font-variant-numeric: tabular-nums;
    }

    & .vjs-time-tooltip {
      ${fonts.body.micro}
      font-variant-numeric: tabular-nums;
      padding: 2px 8px;
      border-radius: 100px;
      background-color: rgba(255, 255, 255, 0.75);
    }

    & .vjs-has-started.vjs-user-inactive.vjs-playing .vjs-control-bar {
      visibility: visible;
      opacity: 0;
      pointer-events: none;
      transition: all 0.2s cubic-bezier(0.25, 0.5, 0.25, 1);
    }

    & .vjs-vol-1 .vjs-icon-placeholder::before,
    & .vjs-vol-2 .vjs-icon-placeholder::before,
    & .vjs-vol-3 .vjs-icon-placeholder::before {
      background: center center no-repeat url('/static/images/icons/volume--up.svg');
    }

    & .vjs-fullscreen-control .vjs-icon-placeholder::before {
      background: center center no-repeat url('/static/images/icons/maximize.svg');
    }

    &.vjs-fullscreen .vjs-fullscreen-control .vjs-icon-placeholder::before {
      background: center center no-repeat url('/static/images/icons/minimize.svg');
    }

    & .vjs-vol-0 .vjs-icon-placeholder::before {
      background: center center no-repeat url('/static/images/icons/volume--mute.svg');
    }

    & .vjs-playing .vjs-icon-placeholder::before {
      background: center center no-repeat url('/static/images/icons/pause--filled.svg');
    }

    & .vjs-paused .vjs-icon-placeholder::before {
      background: center center no-repeat url('/static/images/icons/play--filled.svg');
    }

    & .vjs-remaining-time {
      display: flex;
      justify-content: center;
      align-items: center;
    }

    & .vjs-progress-control:hover & .vjs-play-progress:before {
      opacity: 0;
    }

    & .vjs-progress-control:hover {
      & .vjs-play-progress:before {
        opacity: 1;
      }
    }

    & .vjs-time-tooltip,
    & .vjs-volume-tooltip {
      white-space: nowrap;
    }

    & .vjs-menu-button-popup .vjs-menu .vjs-menu-content  {
      overflow: auto;
      gap: 0;
      max-height: 300px;
      transition: all 100ms ease-in-out;
    }

    & .vjs-menu-item {
    font-size: 10px;
    padding: 5px;
    max-width: none;
    transition: all 100ms ease-in-out;
    border-radius: 5px;
    text-align: center;
    }

    & .vjs-overwrite .vjs-menu li.vjs-selected {
    background-color: ${p => p.theme.color.grey50};
    transition: all 100ms ease-in-out;
    border-radius: 5px;
    }    
    & .vjs-menu li.vjs-selected {
    color: white;
    background-color: ${p => p.theme.color.grey50};
    transition: all 100ms ease-in-out;
    border-radius: 5px;
    }

    & .vjs-menu li.vjs-menu-item:hover {
      background-color: ${p => p.theme.color.grey60};
      transition: all 100ms ease-in-out;
      border-radius: 5px;
    }

    & .vjs-menu  {
      position: relative;
      bottom: -25px;
      border-radius: 5px;
    }

    @keyframes fadein {
      from { opacity: 0; }
      to { opacity: 1; }
    }   

    & .vjs-menu-content {
      border-radius: 5px;
      padding: 3px 3px; 
      animation: 100ms ease-out fadein;
      animation-timing-function: ease-in-out;
    }

    & .vjs-playback-rate > .vjs-menu-button, .vjs-playback-rate .vjs-playback-rate-value {
      display: flex;
      justify-content: center;
      align-items: center;
      font-size: 10px;
      border-radius: 5px;
      transition: all 100ms ease-in-out;
    }
   

    & .vjs-subs-caps-button {
      & > .vjs-menu {
          bottom: 70%;

          & li {
            text-transform: unset;
          }
      }

      & .vjs-subs-caps-button .vjs-icon-placeholder::before {
        content: "\f10c" !important;
      }

    }

    & .vjs-text-track-display > div > div > div { 
      box-decoration-break: clone;
      padding-inline: 6px;
      border-radius: 6px;
      line-height: 1.3;
    }
  }
`

const LazyPlayer = React.lazy(() => import('sierra-client/components/blocks/video/video-implementation'))

export const VideoPlayer: FC<VideoPlayerProps> = props => {
  return (
    <>
      <GlobalPlayerStyles />
      <React.Suspense>
        <LazyPlayer {...props} />
      </React.Suspense>
    </>
  )
}
