import { Logger } from 'sierra-client/core/logging/logger'

type Identifier = `email_program_${string}`

export const sendTestEmail = Logger.trackLoggerWithExtra<{ templateId?: string }, { templateId?: string }>(
  'email_program_send_test_email' satisfies Identifier,
  input => {
    return {
      ...input,
    }
  }
)

export const sendReminderEmail = Logger.trackLoggerWithExtra<
  { programId: string; count: number },
  { programId: string; count: number }
>('email_program_send_reminder_email' satisfies Identifier, input => {
  return {
    ...input,
  }
})
