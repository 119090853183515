import { useAtom } from 'jotai'
import _ from 'lodash'
import React, { useEffect } from 'react'
import { useReward } from 'react-rewards'
import { Link } from 'sierra-client/components/common/link'
import { useTranslation } from 'sierra-client/hooks/use-translation'
import { Trans } from 'sierra-client/hooks/use-translation/trans'
import { TranslationKey } from 'sierra-client/hooks/use-translation/types'
import { useCachedQuery } from 'sierra-client/state/api'
import { selectCurrentPathId, selectCurrentProgramId } from 'sierra-client/state/content/selectors'
import { useGetFileTitle } from 'sierra-client/state/flexible-content/file-title'
import { useSelector } from 'sierra-client/state/hooks'
import { useQuestionShuffle } from 'sierra-client/views/flexible-content/question-shuffling/shuffle-elements-provider'
import { useSelfPacedBigContext } from 'sierra-client/views/flexible-content/self-paced-big-context'
import { reviewSuggestionsAtom } from 'sierra-client/views/self-paced/sidebar-atom'
import { buildSelfPacedUrlPath } from 'sierra-client/views/self-paced/useNavigate'
import {
  Assessments,
  evaluatePassingCriteria,
  useAssessmentContext,
} from 'sierra-client/views/v3-author/assessment-card/assessment-context'
import { assertElementType } from 'sierra-client/views/v3-author/queries'
import { SlateWrapperProps } from 'sierra-client/views/v3-author/slate'
import { XRealtimeStrategySelfPacedContentAssessmentGetAssessmentReviewRecommendations } from 'sierra-domain/routes'
import { assert } from 'sierra-domain/utils'
import { deriveTheme } from 'sierra-ui/color'
import { Icon } from 'sierra-ui/components'
import { Button, Heading, LoadingSpinner, Spacer, Text, View } from 'sierra-ui/primitives'
import { token } from 'sierra-ui/theming'
import styled from 'styled-components'

const Circle = styled(View).attrs({ justifyContent: 'center', alignItems: 'center', id: 'confetti' })`
  width: 96px;
  height: 96px;
  border-radius: 100%;
  background-color: ${p => deriveTheme(p.theme).secondaryBackgroundColor};
`

const AssessmentEndWrapper = styled(View).attrs({
  direction: 'column',
  justifyContent: 'center',
  alignItems: 'center',
  contentEditable: false,
})`
  color: ${p => deriveTheme(p.theme).textColor};
`

const CenteredText = styled(View)`
  text-align: center;
`

const confettiConfig = {
  position: 'absolute',
}

const Checkmark = styled(Icon).attrs({
  iconId: 'checkbox--checkmark--filled',
  size: 'size-16',
  color: 'border/strong',
})`
  position: absolute;
  top: 16px;
  right: 16px;
`

const RecommendationBox = styled(Link).attrs({ next: true })`
  position: relative;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  gap: 4px;
  height: 8rem;
  background-color: ${token('surface/soft')};
  transition: background-color 100ms ease-in-out;
  transition: color 100ms ease-in-out;
  &:hover {
    background-color: ${token('surface/soft').opacity(0.075)};
  }

  text-align: center;
  max-width: 272px;
  border-radius: 16px;
  padding: 32px 40px;
  cursor: pointer;
`

const HiddenRecommendationBox = styled(RecommendationBox).attrs({ href: '', children: <span></span> })`
  opacity: 0;
  pointer-events: none;
`

const ArrowText = styled(Text).attrs({ bold: true, size: 'small' })`
  display: flex;
  align-items: center;
`

const AbsoluteContainer = styled.div`
  position: absolute;
  inset: 0;
  margin: auto;
`

const AssessmentReviewRecommendations: React.FC = () => {
  const { state } = useAssessmentContext()

  const { t } = useTranslation()

  const [suggestedFiles, setSuggestedFiles] = useAtom(reviewSuggestionsAtom)

  assert(state.status !== 'start')

  const reviewRecommendations = useCachedQuery(
    XRealtimeStrategySelfPacedContentAssessmentGetAssessmentReviewRecommendations,
    {
      contentId: state.contentId,
      fileId: state.fileId,
      questionExerciseIds: state.questionExerciseIds,
      sessionId: state.sessionId,
    }
  ).data?.recommendations

  useEffect(() => {
    if (reviewRecommendations) {
      const fileIds = reviewRecommendations.map(recommendation => recommendation.fileId)
      const newRecord = _.mapValues(_.keyBy(fileIds), () => true)
      setSuggestedFiles(previous => ({ ...newRecord, ...previous }))
    }
  }, [reviewRecommendations, setSuggestedFiles])

  const {
    slateDocumentMap,
    jsonData: { nodeMap },
  } = useSelfPacedBigContext()
  const getFileTitle = useGetFileTitle(slateDocumentMap)

  const currentPathId = useSelector(selectCurrentPathId)
  const currentProgramId = useSelector(selectCurrentProgramId)

  return (
    <div>
      <View direction='column' alignItems='center' gap='16' marginTop='64'>
        {(reviewRecommendations === undefined ||
          // Don't show the title if we know that we don't have recommendations
          reviewRecommendations.length > 0) && (
          <Text size='small' color='foreground/muted' bold>
            {t('assessment.review-recommendations.title')}
          </Text>
        )}

        {reviewRecommendations === undefined ? (
          <View position={'relative'} wrap='wrap' gap='16' justifyContent='center'>
            <HiddenRecommendationBox />
            <HiddenRecommendationBox />
            <HiddenRecommendationBox />
            <AbsoluteContainer>
              <LoadingSpinner size='small' />
            </AbsoluteContainer>
          </View>
        ) : (
          <View
            animated
            initial={{ opacity: 0 }}
            animate={{ opacity: 1 }}
            transition={{ duration: 0.25 }}
            position={'relative'}
            wrap='wrap'
            gap='16'
            justifyContent='center'
          >
            {reviewRecommendations.map(({ fileId, questionIndices }) => {
              const file = nodeMap[fileId]
              if (file?.type !== 'file') return null
              return (
                <RecommendationBox
                  href={buildSelfPacedUrlPath({
                    fileId: file.id,
                    flexibleContentId: state.contentId,
                    programId: currentProgramId,
                    pathId: currentPathId,
                    skipCourseOverview: true,
                  })}
                  key={file.id}
                >
                  <ArrowText>{getFileTitle(file)}</ArrowText>
                  <Text size='micro' color='foreground/muted'>
                    {questionIndices.length > 1
                      ? t('assessment.review-recommendations.box-subtitle-multiple-questions')
                      : t('assessment.review-recommendations.box-subtitle-single', {
                          number: questionIndices.map(i => i + 1).join(', '),
                        })}
                  </Text>
                  {suggestedFiles[file.id] === false && <Checkmark />}
                </RecommendationBox>
              )
            })}
          </View>
        )}
      </View>
    </div>
  )
}

export const AssessmentEnd: React.FC<
  SlateWrapperProps & { end: 'successful' | 'failed'; allowRetry: boolean; timesUp?: boolean }
> = props => {
  const { element, end, allowRetry } = props
  const { t } = useTranslation()
  const { reward } = useReward('confetti', 'confetti', confettiConfig)

  assertElementType('assessment-introduction', element)
  const { state, setState } = useAssessmentContext()
  const { reshuffleAllQuestions } = useQuestionShuffle()
  const [, setSuggestedFiles] = useAtom(reviewSuggestionsAtom)

  useEffect(() => {
    if (end === 'successful') {
      reward()
    }
  }, [end, reward])

  return (
    <AssessmentEndWrapper>
      <Circle>
        <Icon
          iconId={end === 'successful' ? 'checkmark--outline' : !allowRetry ? 'close' : 'redo'}
          size='size-48'
          color='currentColor'
        />
      </Circle>
      <Spacer size='24' />

      <Heading bold color='LEGACY_DEFAULT_HEADING_COLOR_REPLACE_ASAP' align='center' size='h3'>
        {t(
          end === 'successful'
            ? 'assessment-card.you-passed-5apr-2024'
            : allowRetry
              ? props.timesUp === true
                ? 'assessment-card.you-failed-times-up' //Fixa copy
                : 'assessment-card.you-failed' //'assessment-card.you-failed'
              : props.timesUp === true
                ? 'assessment-card.you-failed-no-retry-times-up'
                : 'assessment-card.you-failed-no-retry'
        )}
      </Heading>
      {end === 'failed' && (
        <CenteredText justifyContent='center' direction='column' gap='none'>
          {state.status === 'start' && (
            <Text color='LEGACY_DEFAULT_TEXT_COLOR_REPLACE_ASAP' size='large'>
              {t('assessment-card.you-failed-assessment-no-retry')}
            </Text>
          )}
          {state.status !== 'start' && (
            <>
              <Text color='LEGACY_DEFAULT_TEXT_COLOR_REPLACE_ASAP' size='large'>
                <Trans
                  i18nKey={'assessment-card.failed-assessment-numbers-5apr-2024' satisfies TranslationKey}
                  values={{
                    result:
                      state.status === 'failed' || state.status === 'successful'
                        ? state.numberOfCorrectQuestions
                        : 0,
                    totalQuestions: state.questionCount,
                  }}
                  components={{
                    wrapper: <strong />,
                  }}
                />
              </Text>
              {allowRetry !== false ? (
                <Text color='LEGACY_DEFAULT_TEXT_COLOR_REPLACE_ASAP' size='large'>
                  {t('assessment-card.failed-assessment-criteria', {
                    criteria: evaluatePassingCriteria({
                      questionCount: state.questionCount,
                      passingCriteria: state.passingCriteria,
                    }),
                  })}
                </Text>
              ) : (
                <Text color='LEGACY_DEFAULT_TEXT_COLOR_REPLACE_ASAP' size='large'>
                  {t('assessment-card.failed-assessment-criteria-no-retry', {
                    criteria: evaluatePassingCriteria({
                      questionCount: state.questionCount,
                      passingCriteria: state.passingCriteria,
                    }),
                  })}
                </Text>
              )}
            </>
          )}
        </CenteredText>
      )}
      {end === 'successful' && (
        <CenteredText justifyContent='center' direction='column' gap='none'>
          {state.status === 'start' && (
            <Text color='LEGACY_DEFAULT_TEXT_COLOR_REPLACE_ASAP' size='large'>
              {t('assessment-card.you-completed-assessment-already')}
            </Text>
          )}
          {state.status === 'successful' && (
            <>
              <Text style={{ width: '36ch' }} color='LEGACY_DEFAULT_TEXT_COLOR_REPLACE_ASAP' size='large'>
                <Trans
                  i18nKey={'assessment-card.you-completed-assessment-5apr-2024' satisfies TranslationKey}
                  values={{
                    result: state.numberOfCorrectQuestions,
                    totalQuestions: state.questionCount,
                  }}
                  components={{
                    wrapper: <strong />,
                  }}
                />
              </Text>
            </>
          )}
        </CenteredText>
      )}
      {end === 'failed' && allowRetry !== false && (
        <>
          <View marginTop={'24'} direction='column' justifyContent='center' alignItems='center' gap='xsmall'>
            <Button
              onClick={() => {
                reshuffleAllQuestions()
                setState(Assessments.retry(state))
                setSuggestedFiles({})
              }}
            >
              {t('assessment-card.try-again')}
            </Button>
          </View>
          <AssessmentReviewRecommendations />
        </>
      )}
    </AssessmentEndWrapper>
  )
}
