import { useTranslation } from 'sierra-client/hooks/use-translation'
import {
  CreatePageContentFilter,
  CreatePageSetSelectedFilters,
  CreatePageSortFunctionId,
} from 'sierra-client/views/workspace/create-new/filters/types'
import { CheckboxMenuItem, LabelMenuItem } from 'sierra-ui/components/menu/types'

export const toggleContentType = (
  contentType: CreatePageContentFilter['contentType'],
  selectedContentType: CreatePageContentFilter['contentType'][],
  setSelectedContentType: CreatePageSetSelectedFilters['setSelectedContentType']
): void => {
  if (selectedContentType.includes(contentType)) {
    setSelectedContentType(selectedContentType.filter(it => it !== contentType))
  } else {
    setSelectedContentType([...selectedContentType, contentType])
  }
}

export const togglePublishState = (
  publishState: CreatePageContentFilter['publishState'],
  selectedPublishState: CreatePageContentFilter['publishState'][],
  setSelectedPublishState: CreatePageSetSelectedFilters['setSelectedPublishState']
): void => {
  if (selectedPublishState.includes(publishState)) {
    setSelectedPublishState(selectedPublishState.filter(it => it !== publishState))
  } else {
    setSelectedPublishState([...selectedPublishState, publishState])
  }
}

export const useContenTypeMenuItems = (
  selectedContentType: CreatePageContentFilter['contentType'][],
  setSelectedContentType: CreatePageSetSelectedFilters['setSelectedContentType']
): CheckboxMenuItem<CreatePageContentFilter['contentType']>[] => {
  const { t } = useTranslation()
  const contentTypeMenuItems: CheckboxMenuItem<CreatePageContentFilter['contentType']>[] = [
    {
      type: 'checkbox',
      id: 'native:live',
      label: t('dictionary.live'),
      onToggleChange: () => {
        toggleContentType('native:live', selectedContentType, setSelectedContentType)
      },
      checked: selectedContentType.includes('native:live'),
      preventClose: true,
    },
    {
      type: 'checkbox',
      id: 'native:self-paced',
      label: t('dictionary.course-singular'),
      onToggleChange: () => {
        toggleContentType('native:self-paced', selectedContentType, setSelectedContentType)
      },
      checked: selectedContentType.includes('native:self-paced'),
    },
    {
      type: 'checkbox',
      id: 'path',
      label: t('content-table.types.path'),
      onToggleChange: () => {
        toggleContentType('path', selectedContentType, setSelectedContentType)
      },
      checked: selectedContentType.includes('path'),
    },
    {
      type: 'checkbox',
      id: 'template',
      label: t('dictionary.template'),
      onToggleChange: () => {
        toggleContentType('template', selectedContentType, setSelectedContentType)
      },
      checked: selectedContentType.includes('template'),
    },
    {
      type: 'checkbox',
      id: 'folder',
      label: t('dictionary.folder'),
      onToggleChange: () => {
        toggleContentType('folder', selectedContentType, setSelectedContentType)
      },
      checked: selectedContentType.includes('folder'),
    },
    {
      type: 'checkbox',
      id: 'native:event-group',
      label: t('dictionary.in-person'),
      onToggleChange: () => {
        toggleContentType('native:event-group', selectedContentType, setSelectedContentType)
      },
      checked: selectedContentType.includes('native:event-group'),
    },
    {
      type: 'checkbox',
      id: 'scorm',
      label: t('manage.courses.import.scorm'),
      onToggleChange: () => {
        toggleContentType('scorm', selectedContentType, setSelectedContentType)
      },
      checked: selectedContentType.includes('scorm'),
    },
    {
      type: 'checkbox',
      id: 'link',
      label: t('author.slate.link.link'),
      onToggleChange: () => {
        toggleContentType('link', selectedContentType, setSelectedContentType)
      },
      checked: selectedContentType.includes('link'),
    },
    {
      type: 'checkbox',
      id: 'linkedin',
      label: 'LinkedIn',
      onToggleChange: () => {
        toggleContentType('linkedin', selectedContentType, setSelectedContentType)
      },
      checked: selectedContentType.includes('linkedin'),
    },
  ]
  return contentTypeMenuItems
}

export const usePublishStateMenuItems = (
  selectedPublishState: CreatePageContentFilter['publishState'][],
  setSelectedPublishState: CreatePageSetSelectedFilters['setSelectedPublishState']
): CheckboxMenuItem<CreatePageContentFilter['publishState']>[] => {
  const { t } = useTranslation()
  const publishStateNestedMenuItems: CheckboxMenuItem<CreatePageContentFilter['publishState']>[] = [
    {
      type: 'checkbox',
      id: 'published',
      label: t('filter.publish-state.published'),
      onToggleChange: () => {
        togglePublishState('published', selectedPublishState, setSelectedPublishState)
      },
      checked: selectedPublishState.includes('published'),
    },
    {
      type: 'checkbox',
      id: 'draft',
      label: t('filter.publish-state.draft'),
      onToggleChange: () => {
        togglePublishState('draft', selectedPublishState, setSelectedPublishState)
      },
      checked: selectedPublishState.includes('draft'),
    },
    {
      type: 'checkbox',
      id: 'pending',
      label: t('filter.publish-state.pending'),
      onToggleChange: () => {
        togglePublishState('pending', selectedPublishState, setSelectedPublishState)
      },
      checked: selectedPublishState.includes('pending'),
    },
  ]
  return publishStateNestedMenuItems
}

export const useSortByMenuItems = (
  selectedSortFunction: CreatePageSortFunctionId,
  setSelectedSortFunction?: (sortId: CreatePageSortFunctionId) => void
): LabelMenuItem<CreatePageSortFunctionId>[] => {
  const { t } = useTranslation()
  const sortOptionItems: LabelMenuItem<CreatePageSortFunctionId>[] = [
    {
      type: 'label',
      id: 'edited-desc',
      selected: selectedSortFunction === 'edited-desc',
      label: t('sort.recently-edited'),
      onClick:
        setSelectedSortFunction !== undefined ? () => setSelectedSortFunction('edited-desc') : undefined,
    },
    {
      type: 'label',
      id: 'alphabetically-asc',
      selected: selectedSortFunction === 'alphabetically-asc',
      label: t('sort.alphabetically.a-z'),
      onClick:
        setSelectedSortFunction !== undefined
          ? () => setSelectedSortFunction('alphabetically-asc')
          : undefined,
    },
    {
      type: 'label',
      id: 'alphabetically-desc',
      selected: selectedSortFunction === 'alphabetically-desc',
      label: t('sort.alphabetically.z-a'),
      onClick:
        setSelectedSortFunction !== undefined
          ? () => setSelectedSortFunction('alphabetically-desc')
          : undefined,
    },
  ]

  return sortOptionItems
}
