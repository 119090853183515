class RejectOnTimeoutError extends Error {}

export function rejectOnTimeout<T>(promise: Promise<T>, name: string, timeLimit: number): Promise<T> {
  return Promise.race([
    promise,
    new Promise<T>((_, reject) => {
      setTimeout(() => reject(new RejectOnTimeoutError(`${name} took more than ${timeLimit} ms`)), timeLimit)
    }),
  ])
}
