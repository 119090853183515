import { useMemo } from 'react'
import { getWidgetIcon } from 'sierra-client/features/insights/display-widgets/utils'
import { WidgetBuilderState } from 'sierra-client/features/insights/widget-builder/widget-builder-state'
import { useTranslation } from 'sierra-client/hooks/use-translation'
import { IconId } from 'sierra-ui/components'
import { SelectChartType } from 'sierra-ui/missions/delphi/select-chart-type'

type VisualisationTypeItem = {
  value: WidgetBuilderState['visualisation']['type']
  label: string
  icon: IconId
}

type VisualisationTypeSelectorProps = {
  selectedType?: WidgetBuilderState['visualisation']['type']
  onChange: (type: WidgetBuilderState['visualisation']) => void
}

export const VisualisationTypeSelector: React.FC<VisualisationTypeSelectorProps> = ({
  selectedType,
  onChange,
}) => {
  const { t } = useTranslation()

  const items = useMemo(
    () =>
      [
        {
          value: 'table',
          label: t('dictionary.table'),
          icon: getWidgetIcon('widget.aggregation-table'),
        },

        {
          value: 'pivot-table',
          label: t('dictionary.pivot-table'),
          icon: getWidgetIcon('widget.pivot-table'),
        },

        {
          value: 'bar-chart',
          label: t('dictionary.bar-chart'),
          icon: getWidgetIcon('widget.bar-chart'),
        },
        {
          value: 'line-chart',
          label: t('dictionary.line-chart'),
          icon: getWidgetIcon('widget.line-chart'),
        },

        {
          value: 'metric',
          label: t('dictionary.simple-metric'),
          icon: getWidgetIcon('widget.metric'),
        },
        {
          value: 'progress-bar',
          label: t('dictionary.progress-bar'),
          icon: getWidgetIcon('widget.progress-bar'),
        },
      ] as const satisfies VisualisationTypeItem[],
    [t]
  )

  const handleChange = (type: WidgetBuilderState['visualisation']['type']): void => {
    switch (type) {
      case 'table':
        return onChange({ type, table: { tableType: 'standard' } })
      default:
        return onChange({ type })
    }
  }

  return <SelectChartType onChange={handleChange} items={items} value={selectedType} />
}
