import * as Popover from '@radix-ui/react-popover'
import _ from 'lodash'
import { FC, ReactNode, useContext } from 'react'
import { config } from 'sierra-client/config/global-config'
import { useTranslation } from 'sierra-client/hooks/use-translation'
import { CustomThemes } from 'sierra-domain/api/private'
import { color, dynamicColor } from 'sierra-ui/color'
import { TokenOrColor } from 'sierra-ui/color/token-or-color'
import { ColorName } from 'sierra-ui/color/types'
import { Icon } from 'sierra-ui/components'
import { Spacer, Text } from 'sierra-ui/primitives'
import { palette, token, zIndex } from 'sierra-ui/theming'
import {
  BackendCustomThemeId,
  CustomThemeName,
  NewPresetThemeName,
  PresetThemeName,
  getCustomThemeIdFromBackendId,
  isCustomThemeName,
  legacyLight,
  newPresetThemeNames,
  newPresetThemes,
} from 'sierra-ui/theming/legacy-theme'
import styled, { ThemeContext, ThemeProvider } from 'styled-components'

const StyledPopoverContent = styled(Popover.Content)`
  /* MUIDialog has a z-index set to zIndex.MODAL. */
  z-index: ${zIndex.ON_MODAL};
`

const Picker = styled.div`
  display: grid;
  grid-template-columns: repeat(7, 2rem);
  grid-gap: 0.5rem;
`

const ThemeButton = styled.button<{ $bgColor?: ColorName; $borderColor?: TokenOrColor }>`
  position: relative;
  display: flex;
  justify-content: center;
  align-items: center;
  width: 2rem;
  height: 2rem;
  border-radius: 10px;
  background-color: ${p => (p.$bgColor !== undefined ? p.theme.color[p.$bgColor] : palette.primitives.white)};
  border: 1px solid;
  border-color: ${p => (p.$borderColor !== undefined ? p.$borderColor : 'transparent')};
  cursor: pointer;
  overflow: hidden;
`

const CustomThemeButton = styled(ThemeButton)<{ $customBgColor: string }>`
  background-color: ${p => p.$customBgColor};
`

const CustomThemeText = styled(Text)<{ $color: string }>`
  color: ${p => p.$color};
`

const SelectContainer = styled.div`
  padding: 1.5rem;
  background-color: ${token('surface/default')};
  border: 1px solid ${token('border/strong')};
  border-radius: 8px;
  box-shadow: 0 8px 16px rgba(0, 0, 0, 0.08);
`

export const getRandomPresetThemeName = (): NewPresetThemeName => {
  const randomIndex = Math.floor(Math.random() * newPresetThemeNames.length)
  return newPresetThemeNames[randomIndex] ?? 'black'
}

export const useAvailableCustomThemeNames = (): CustomThemeName[] => {
  const orgConfig = config.organization
  return Object.entries(orgConfig.settings.brand.customThemes)
    .filter(([_, theme]) => theme !== null)
    .map(([key]) => getCustomThemeIdFromBackendId(key as BackendCustomThemeId))
    .filter(isCustomThemeName)
}

export const ThemePicker: FC<{
  currentTheme: PresetThemeName | CustomThemeName
  onClick: (theme: PresetThemeName | CustomThemeName) => void
  trigger: ReactNode
  textPreview?: string
}> = ({ currentTheme, onClick, trigger, textPreview = 'A' }) => {
  const { t } = useTranslation()
  const themeContext = useContext(ThemeContext)
  const orgConfig = config.organization
  const customThemes = _.omitBy(orgConfig.settings.brand.customThemes, _.isNull)

  return (
    <Popover.Root>
      <Popover.Trigger asChild>{trigger}</Popover.Trigger>
      <Popover.Portal>
        <StyledPopoverContent sideOffset={5}>
          <SelectContainer>
            {!_.isEmpty(customThemes) && (
              <>
                <ThemeProvider theme={legacyLight}>
                  <Text size='small' bold>
                    {t('manage.settings.themes.custom.title')}
                  </Text>
                </ThemeProvider>
                <Spacer size='xsmall' />
                <Picker>
                  {_.map(customThemes, (theme, key: keyof CustomThemes) => {
                    if (theme === null) return null

                    const themeId = getCustomThemeIdFromBackendId(key)

                    return (
                      <CustomThemeButton
                        key={`theme-${key}`}
                        $customBgColor={theme.backgroundColor}
                        $borderColor={dynamicColor(theme.foregroundColor).opacity(0.2)}
                        onClick={() => onClick(themeId)}
                      >
                        {themeId === currentTheme ? (
                          <Icon
                            iconId='checkmark'
                            size='size-14'
                            color={dynamicColor(theme.foregroundColor)}
                          />
                        ) : (
                          <CustomThemeText size='small' $color={theme.foregroundColor} bold>
                            {textPreview}
                          </CustomThemeText>
                        )}
                      </CustomThemeButton>
                    )
                  })}
                </Picker>

                <Spacer size='xsmall' />
              </>
            )}

            <ThemeProvider theme={legacyLight}>
              <Text size='small' bold>
                {t('manage.settings.sana-themes')}
              </Text>
            </ThemeProvider>
            <Spacer size='xsmall' />
            <Picker>
              {_.map(newPresetThemes, (theme, key: NewPresetThemeName) =>
                key.includes('custom-') ? null : (
                  <ThemeButton
                    key={`theme-${key}`}
                    $bgColor={theme.backgroundColor}
                    onClick={() => onClick(key)}
                    $borderColor={color(theme.textColor).opacity(0.2)}
                  >
                    {key === currentTheme ? (
                      <Icon
                        iconId='checkmark'
                        size='size-14'
                        color={color(themeContext.color[theme.textColor])}
                      />
                    ) : (
                      <>
                        <Text size='small' color={theme.textColor} bold>
                          {textPreview}
                        </Text>
                      </>
                    )}
                  </ThemeButton>
                )
              )}
            </Picker>
          </SelectContainer>
        </StyledPopoverContent>
      </Popover.Portal>
    </Popover.Root>
  )
}
