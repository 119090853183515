import {
  MetadataWithIcon,
  NotificationContainer,
  Title,
} from 'sierra-client/features/activity-center/notifications/components'
import { LiveSessionImage } from 'sierra-client/features/activity-center/notifications/components/entity-image'
import { NotificationIcon } from 'sierra-client/features/activity-center/notifications/components/notification-icon'
import { NotificationTitle } from 'sierra-client/features/activity-center/notifications/components/notification-title'
import { HomeNotification } from 'sierra-domain/api/learn'
import { View } from 'sierra-ui/primitives'

export const DeletedLiveSessionNotification: React.FC<{
  notification: Extract<HomeNotification, { type: 'deleted-live-session' }>
}> = ({ notification }) => {
  const entity = notification.liveSession

  return (
    <NotificationContainer
      notification={notification}
      icon={<NotificationIcon iconId='error--stop' variant='warning' />}
      title={<NotificationTitle untranslated='Removed session' />}
      content={
        <>
          <LiveSessionImage liveSession={entity} muted />

          <View direction='column' gap='4'>
            <Title size='regular' bold color='foreground/muted'>
              {entity.title}
            </Title>
            <MetadataWithIcon type='live' />
          </View>
        </>
      }
    />
  )
}
