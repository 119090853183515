import React from 'react'
import { useTranslation } from 'sierra-client/hooks/use-translation'
import { TranslationKey } from 'sierra-client/hooks/use-translation/types'
import type { CustomAttribute } from 'sierra-domain/api/manage'
import { CustomAttributeKey } from 'sierra-domain/api/manage'
import { Text, View } from 'sierra-ui/primitives'
import { token } from 'sierra-ui/theming'
import styled from 'styled-components'

export const CustomAttributeMore = styled(View).attrs({
  justifyContent: 'center',
  alignItems: 'center',
  gap: 'none',
  background: 'grey10',
  cursor: 'pointer',
})`
  white-space: nowrap;
  padding: 3px 8px;
  border-radius: 8px;
`

export const CustomAttributeOuter = styled(View).attrs({
  justifyContent: 'center',
  alignItems: 'center',
  gap: 'none',
})`
  max-width: 100%;
  white-space: nowrap;
  border: 1px solid ${token('border/default')};
  border-radius: 8px;
`

export const CustomAttributeLeft = styled(Text).attrs({
  as: 'span',
  bold: true,
  size: 'micro',
})`
  padding: 3px 6px 3px 8px;
  overflow: hidden;
  text-overflow: ellipsis;
`

export const CustomAttributeRight = styled(Text).attrs({
  as: 'span',
  size: 'micro',
})`
  color: ${token('foreground/muted')};
  padding: 3px 8px 3px 6px;
  overflow: hidden;
  text-overflow: ellipsis;
`

const Line = styled.div`
  border-right: 1px solid ${token('border/default')};
  min-height: 12px;
  height: 80%;
`

const attributeTitle = (attributeKey: CustomAttributeKey, t: (x: TranslationKey) => string): string => {
  switch (attributeKey.type) {
    case 'organization':
      return attributeKey.displayName ?? attributeKey.id
    case 'enterprise':
      switch (attributeKey.id) {
        case 'userType':
          return t('user-attribute.user-type')
        case 'costCenter':
          return t('user-attribute.cost-center')
        case 'employeeNumber':
          return t('user-attribute.employee-number')
        case 'organization':
          return t('user-attribute.organization')
        case 'department':
          return t('user-attribute.department')
        case 'division':
          return t('user-attribute.division')
      }
  }
}

export type CustomAttributeDisplay = {
  label: string
  value: string
}

const CustomAttributeDisplay: React.FC<CustomAttributeDisplay> = ({ label, value }) => (
  <CustomAttributeOuter>
    <CustomAttributeLeft title={label}>{label}</CustomAttributeLeft>
    <Line />
    <CustomAttributeRight title={value}>{value}</CustomAttributeRight>
  </CustomAttributeOuter>
)

export const PillList: React.FC<{
  attributeDisplays: CustomAttributeDisplay[]
  remainingElements: number
  onMoreClick?: () => void
}> = ({ attributeDisplays, remainingElements, onMoreClick }) => (
  <View alignItems='stretch'>
    {attributeDisplays.map(attribute => (
      <CustomAttributeDisplay
        key={`${attribute.label}-${attribute.value}`}
        label={attribute.label}
        value={attribute.value}
      />
    ))}
    {remainingElements > 0 && (
      <CustomAttributeMore onClick={onMoreClick}>
        <Text color='grey50' size='technical' bold>
          +{remainingElements}
        </Text>
      </CustomAttributeMore>
    )}
  </View>
)

type CustomAttributesSidebarSectionProps = {
  attributes: CustomAttribute[]
  onOpenModal: () => void
}

export const PillSection: React.FC<CustomAttributesSidebarSectionProps> = ({ attributes, onOpenModal }) => {
  const { t } = useTranslation()
  const onlyTake = 2
  const firstPart: CustomAttributeDisplay[] = attributes
    .slice(0, onlyTake)
    .map(a => ({ value: a.value, label: attributeTitle(a.key, t) }))
  const remainingElements = attributes.length - onlyTake

  return (
    <PillList attributeDisplays={firstPart} remainingElements={remainingElements} onMoreClick={onOpenModal} />
  )
}
