import React, { useEffect, useRef, useState } from 'react'
import { resolveUserAvatar } from 'sierra-client/api/content'
import { Notif, useNotif } from 'sierra-client/components/common/notifications'
import { useShortcutMenuDispatch } from 'sierra-client/components/shortcut-menu/context'
import { SearchContent } from 'sierra-client/components/shortcut-menu/search-content'
import { RunAction } from 'sierra-client/components/shortcut-menu/types'
import { PostWithoutErrorsType, usePost } from 'sierra-client/hooks/use-post'
import { useTemporaryFileUpload } from 'sierra-client/hooks/use-temporary-file-upload'
import { useTranslation } from 'sierra-client/hooks/use-translation'
import { getGlobalRouter } from 'sierra-client/router'
import { useUsersLegacy } from 'sierra-client/state/users/hooks'
import { ExportFlexibleContent } from 'sierra-domain/api/admin'
import { EditableContent } from 'sierra-domain/api/editable-content'
import { NanoId12 } from 'sierra-domain/api/nano-id'
import {
  XRealtimeAuthorExportFlexibleContent,
  XRealtimeAuthorExportFlexibleContentAsZip,
  XRealtimeAuthorImportFlexibleContent,
  XRealtimeAuthorImportFlexibleContentAsZip,
} from 'sierra-domain/routes'
import { isDefined, isNonNullable } from 'sierra-domain/utils'
import { AvatarStack, Label, TruncatedText } from 'sierra-ui/components'
import { Button, Spacer, Text, View } from 'sierra-ui/primitives'
import styled from 'styled-components'

const numberOfCoursesToDisplay = 25

const ContentRowTitle: React.FC<{ title: string; type: string; isTemplate: boolean; courseId: string }> = ({
  courseId,
  title,
  type,
  isTemplate,
}) => (
  <View>
    <TruncatedText lines={2} size='small' bold>
      {`${title} [${courseId}]`}
    </TruncatedText>
    {type === 'native:live' && (
      <Label iconId='play--circle--filled' $size='small' bold $bgColor='redVivid' $color='white'>
        Live
      </Label>
    )}
    {type === 'native:self-paced' && (
      <Label iconId='bookmark--filled' $size='small' bold $bgColor='grey5' $color='grey90'>
        Course
      </Label>
    )}
    {type === 'path' && (
      <Label iconId='path' $size='small' bold $bgColor='grey5' $color='grey90'>
        Path
      </Label>
    )}
    {isTemplate && (
      <Label iconId='template' $size='small' bold $bgColor='bluePastel' $color='black'>
        Template
      </Label>
    )}
  </View>
)

const Content: React.FC<EditableContent> = ({ ...content }) => {
  const { t } = useTranslation()
  const displayTitle = content.title === '' ? t('admin.author.no-title') : content.title
  const users = useUsersLegacy(content.collaborators.map(u => u.userId))
  const definedUsers = users.filter(isDefined).map(resolveUserAvatar)

  return (
    <>
      <ContentRowTitle
        {...content}
        title={displayTitle}
        courseId={content.id}
        isTemplate={content.type !== 'path' && content.templateSettings !== undefined}
      />
      <AvatarStack users={definedUsers} size='tiny' />
    </>
  )
}

const exportCourse = async (
  content: EditableContent,
  postWithUserErrorException: PostWithoutErrorsType,
  notif: Notif
): Promise<void> => {
  const exportJson = await postWithUserErrorException(XRealtimeAuthorExportFlexibleContent, {
    courseId: NanoId12.parse(content.id),
  })
  try {
    await window.navigator.clipboard.writeText(JSON.stringify(exportJson))
  } catch (e) {
    notif.push({ type: 'error', body: 'Unable to write course to clipboard' })
    console.error(e)
  }
}

export const ExportCourse: React.FC = () => {
  const { postWithUserErrorException } = usePost()
  const notif = useNotif()

  return (
    <SearchContent
      numberOfCoursesToDisplay={numberOfCoursesToDisplay}
      ContentComponent={Content}
      onContentSelected={content => exportCourse(content, postWithUserErrorException, notif)}
      successMessage={_ => 'Copied course to clipboard'}
    />
  )
}

const exportCourseAsZip = async (
  content: EditableContent,
  postWithUserErrorException: PostWithoutErrorsType
): Promise<void> => {
  const response = await postWithUserErrorException(XRealtimeAuthorExportFlexibleContentAsZip, {
    courseId: NanoId12.parse(content.id),
  })

  window.open(response.url, '_blank', 'noopener,noreferrer')
}

export const ExportCourseAsZip: React.FC = () => {
  const { postWithUserErrorException } = usePost()

  return (
    <SearchContent
      numberOfCoursesToDisplay={numberOfCoursesToDisplay}
      ContentComponent={Content}
      onContentSelected={content => exportCourseAsZip(content, postWithUserErrorException)}
      successMessage={_ => 'Exported course as ZIP file'}
    />
  )
}

function jsonSafeParse(obj: string): unknown {
  try {
    return JSON.parse(obj)
  } catch (e) {
    console.error(e)
    return {}
  }
}

export const importCourseFromClipboard: RunAction = async ({ postWithUserErrorException, pushNotif }) => {
  const text = await navigator.clipboard.readText()
  const parseResult = ExportFlexibleContent.safeParse(jsonSafeParse(text))
  if (!parseResult.success) {
    pushNotif({
      type: 'custom',
      level: 'error',
      body: 'Error. Did you export the course first? See console for more info.',
    })
    // eslint-disable-next-line no-console
    console.log('Failed to parse course from clipboard. Found:', text, '\nErrors:', parseResult.error)
    return
  }

  const { courseId } = await postWithUserErrorException(
    XRealtimeAuthorImportFlexibleContent,
    parseResult.data
  )
  pushNotif({ type: 'custom', level: 'success', body: 'Imported course successfully' })

  const type = parseResult.data.kind
  if (type === 'native:live') {
    await getGlobalRouter().navigate({ to: `/create/l/${courseId}` })
  } else if (type === 'native:self-paced') {
    await getGlobalRouter().navigate({ to: `/create/s/${courseId}` })
  } else {
    await getGlobalRouter().navigate({ to: `/create/course/${courseId}` })
  }
}

const HiddenInput = styled.input`
  display: none;
`

const FileInput: React.FC<{ value?: File; onChange: (file?: File) => void }> = ({ value, onChange }) => {
  const { t } = useTranslation()

  const fileInput = useRef<HTMLInputElement>(null)
  const handleClickUpload = (): void => {
    if (fileInput.current === null) return
    fileInput.current.click()
  }

  useEffect(() => {
    if (value === undefined && fileInput.current) {
      fileInput.current.value = ''
    }
  }, [value])

  return (
    <View>
      <HiddenInput
        type='file'
        accept='.zip'
        ref={fileInput}
        onChange={e => {
          const file = e.target.files?.[0]
          if (isNonNullable(file)) {
            onChange(file)
          }
        }}
      />
      <Button onClick={handleClickUpload}>{t('dictionary.upload')}</Button>
    </View>
  )
}

export type UploadedFile = { id: string; file: File }
export const FileInputWithUpload: React.FC<{
  value?: UploadedFile
  onChange: (file?: UploadedFile) => void
}> = ({ value, onChange }) => {
  const { uploadTemporaryFile } = useTemporaryFileUpload(undefined)

  const [assetContext] = useState({ type: 'unknown' as const })

  return (
    <FileInput
      value={value?.file}
      onChange={async file => {
        if (file) {
          const { fileId } = await uploadTemporaryFile(file, assetContext)
          onChange({ id: fileId, file: file })
        } else {
          onChange(undefined)
        }
      }}
    />
  )
}

export const ImportContainer = styled.div`
  position: relative;
  width: 720px;
  height: 200px;
  margin: auto;
  padding-top: 4rem;
  padding-bottom: 4rem;
`

export const ImportCourseAsZip: React.FC = () => {
  const { postWithUserErrorException } = usePost()
  const notif = useNotif()
  const dispatch = useShortcutMenuDispatch()
  const { t } = useTranslation()

  const onUpload = async (file?: UploadedFile): Promise<void> => {
    if (file !== undefined) {
      await postWithUserErrorException(XRealtimeAuthorImportFlexibleContentAsZip, {
        fileId: file.id,
      })
      void dispatch({ type: 'close' })
      notif.push({ type: 'custom', level: 'success', body: 'Imported course successfully' })
    }
  }

  return (
    <ImportContainer>
      <View direction='column' alignItems='center' grow>
        <Text color='foreground/primary' size='regular' bold align='center'>
          {t('manage.course.import-courses')}
        </Text>
        <Spacer size='small' />
        <FileInputWithUpload onChange={onUpload} />
      </View>
    </ImportContainer>
  )
}
