import * as VisuallyHidden from '@radix-ui/react-visually-hidden'
import { useAtomValue } from 'jotai'
import React, { useCallback, useRef, useState } from 'react'
import { PageTitle } from 'sierra-client/components/common/page-title'
import { ShortcutMenu } from 'sierra-client/components/shortcut-menu'
import { useTranslation } from 'sierra-client/hooks/use-translation'
import * as settingsActions from 'sierra-client/state/author-course-settings/actions'
import { useDispatch } from 'sierra-client/state/hooks'
import { sidebarContainerAtom } from 'sierra-client/views/flexible-content/editor/content-sidebar/content-sidebar-atoms'
import { useRealtimeCourseTitle } from 'sierra-client/views/flexible-content/use-realtime-course-title'
import { CreateContentId } from 'sierra-domain/api/nano-id'
import { EditableText, Tooltip } from 'sierra-ui/components'
import { focusInput } from 'sierra-ui/components/editable-text/editable-text'
import { Skeleton } from 'sierra-ui/primitives'
import styled from 'styled-components'

const StyledEditableTitle = styled(EditableText)`
  transform: translateY(1px);
  max-width: 24ch;
  white-space: nowrap;
  overflow: hidden;

  background: transparent;

  transform: translateY(1px);
`

const EditableContainer = styled.div`
  flex: 1;
  min-width: 0;
`

const LoadedCreatePageTitle: React.FC<{
  createContentId: CreateContentId
  title: string
  editable: boolean
}> = ({ createContentId, title, editable }) => {
  const { t } = useTranslation()
  const dispatch = useDispatch()

  const [contentEditable, setContentEditable] = useState(false)
  const inputRef = useRef<HTMLParagraphElement>(null)

  const dispatchRenameTitle = useCallback(
    async (updatedValue: string): Promise<void> => {
      await dispatch(
        settingsActions.setCourseTitle({
          title: updatedValue,
          courseId: createContentId,
        })
      )
    },
    [dispatch, createContentId]
  )

  const sidebarContainer = useAtomValue(sidebarContainerAtom)
  const titleEditable = editable && contentEditable

  return (
    <>
      <PageTitle title={title} />
      <VisuallyHidden.Root>
        <div id='editable-title-label'>{t('author.label.edit-course-title')}</div>
      </VisuallyHidden.Root>
      <Tooltip title={titleEditable ? undefined : title} container={sidebarContainer ?? undefined}>
        <EditableContainer>
          <StyledEditableTitle
            ref={inputRef}
            aria-labelledby='editable-title-label'
            contentEditable={titleEditable}
            setContentEditable={setContentEditable}
            value={title}
            bold
            size={'small'}
            color='foreground/primary'
            className='contentTitle'
            onRename={dispatchRenameTitle}
            withSingleClick
          />
        </EditableContainer>
      </Tooltip>

      {editable && (
        <ShortcutMenu.Action
          run={() => {
            setContentEditable(true)
            focusInput(inputRef)
          }}
          iconId='edit'
          label={{ type: 'untranslated', value: 'Rename content' }}
          group='create'
          permission='ACCESS_EDITOR'
        />
      )}
    </>
  )
}

const CreatePageTitleSkeleton: React.FC = () => <Skeleton $radius={4} $height={14} $width={128} />

export const CreatePageTitle: React.FC<{ createContentId: CreateContentId; editable: boolean }> = ({
  createContentId,
  editable,
}) => {
  const title = useRealtimeCourseTitle(createContentId)

  return (
    <>
      {title.loading === false ? (
        <LoadedCreatePageTitle createContentId={createContentId} title={title.title} editable={editable} />
      ) : (
        <CreatePageTitleSkeleton />
      )}
    </>
  )
}
