import { QueryClientProvider as QCP, QueryCache, QueryClient } from '@tanstack/react-query'
import { ReactQueryDevtools } from '@tanstack/react-query-devtools'
import { FC } from 'react'
import { useIsDebugMode } from 'sierra-client/hooks/use-is-debug-mode'
import { logger } from 'sierra-client/logger/logger'
import { RequestError } from 'sierra-domain/error'

export const queryClient = new QueryClient({
  queryCache: new QueryCache({
    onError: (error, query) => {
      if (query.meta === undefined) return

      if (query.meta.feature === 'insights') {
        if (error instanceof RequestError && (error.code === '400' || error.code === '500')) {
          const { feature, ...rest } = query.meta
          logger.captureError(error, { ...rest })
        }
      }
    },
  }),
})

export const QueryClientProvider: FC<{ children: React.ReactNode }> = ({ children }) => {
  const debugMode = useIsDebugMode()

  return (
    <QCP client={queryClient}>
      {children}
      {debugMode && <ReactQueryDevtools buttonPosition='bottom-left' initialIsOpen={false} />}
    </QCP>
  )
}
