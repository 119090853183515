import { atom, PrimitiveAtom } from 'jotai'
import _ from 'lodash'
import { RecentlyVisitedEntry } from 'sierra-client/editor/utils/recently-visited/types'
import { atomWithStorage } from 'sierra-client/state/storage'
import { isDefined } from 'sierra-domain/utils'

/**
 *  This function enforces some rules which are required when:
 *    - reading entries from LocalSorage
 *    - writing entries to LocalStorage
 */
function cleanEntries(entries: RecentlyVisitedEntry[]): RecentlyVisitedEntry[] {
  return (
    _.chain(entries)
      // Since the entries are coming from local storage, it is possible that they
      // no longer match the zod definitions in the codebase, so only keep entries
      // with the expected type
      .filter(entry => RecentlyVisitedEntry.safeParse(entry).success)

      // Sort by time descending to get the newest entries at the start of the list
      // and the oldest entries at the end
      .orderBy(entry => entry.date.getTime(), 'desc')

      // Remove duplicate entries that have the same content id.
      // This is applied in the same order as the list, so newer entries get priority
      .uniqBy(entry => entry.scopedId)

      // localStorage is very limited, so we do not want to store too many entries
      .take(30)

      .value()
  )
}

// We cannot save Date objects in LocalStorage
type RecentlyVisitedStorageEntry = Omit<RecentlyVisitedEntry, 'date'> & { dateString: string }

function encode({ date, ...rest }: RecentlyVisitedEntry): RecentlyVisitedStorageEntry {
  return { ...rest, dateString: date.toISOString() }
}

function decode({ dateString, ...rest }: RecentlyVisitedStorageEntry): RecentlyVisitedEntry | undefined {
  const date = new Date(dateString)
  const parsed = RecentlyVisitedEntry.safeParse({ ...rest, date: new Date(date) })
  return parsed.success ? parsed.data : undefined
}

const recentlyVisitedLocalStorageAtom = atomWithStorage<RecentlyVisitedStorageEntry[]>(
  'recently-visited-entries',
  []
)

export const recentlyVisitedAtom: PrimitiveAtom<RecentlyVisitedEntry[]> = atom(
  get => cleanEntries(get(recentlyVisitedLocalStorageAtom).map(decode).filter(isDefined)),
  (get, set, entriesOrFunction) => {
    const previousEntries = cleanEntries(get(recentlyVisitedLocalStorageAtom).map(decode).filter(isDefined))
    const newEntries =
      typeof entriesOrFunction === 'function' ? entriesOrFunction(previousEntries) : entriesOrFunction

    set(recentlyVisitedLocalStorageAtom, newEntries.map(encode))
  }
)
