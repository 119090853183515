import { createFileRoute } from '@tanstack/react-router'
import { requireLoggedIn } from 'sierra-client/core/require-logged-in'
import { CourseDetailsPage } from 'sierra-client/views/learner/course/course-details-page'
import { PathId, SelfPacedContentId } from 'sierra-domain/api/nano-id'
import { z } from 'zod'

export const Route = createFileRoute('/path/$pathId/s/$flexibleContentId/')({
  component: requireLoggedIn(CourseDetailsPage, { allowScorm: true }) as React.FC,
  params: {
    parse: z.object({ pathId: PathId, flexibleContentId: SelfPacedContentId }).parse,
    stringify: p => p,
  },
})
