import { useSetAtom } from 'jotai'
import { useTranslation } from 'react-i18next'
import { graphql } from 'sierra-client/api/graphql/gql'
import { GetSkillBadgesQuery } from 'sierra-client/api/graphql/gql/graphql'
import { convertGQLBasicUserToAvatarStackUserShape } from 'sierra-client/api/graphql/util/convert-gql-basic-user'
import { useGraphQuery } from 'sierra-client/api/hooks/use-graphql-query'
import { SkillColor } from 'sierra-client/features/skills/colors'
import { RichBadge } from 'sierra-client/features/skills/components/badges'
import { OpenSelectSkillModalAtom } from 'sierra-client/features/skills/components/learner-board/atoms'
import { useIsTablet } from 'sierra-client/state/browser/selectors'
import { AvatarStack, SkillIconId } from 'sierra-ui/components'
import { Button, Spacer, Text, View } from 'sierra-ui/primitives'
import { token } from 'sierra-ui/theming'
import { v2_breakpoint } from 'sierra-ui/theming/breakpoints'
import styled from 'styled-components'

const skillLearnersQuery = graphql(`
  query SkillLearners($limit: Int!) {
    topSkillLearners(limit: $limit) {
      skillLearners {
        userInfo {
          ...BasicUserFragment
        }
        rank
      }
      totalUsersWithBadges
    }
  }
`)

export const getSkillBadgesQuery = graphql(`
  query GetSkillBadges($limit: Int, $sortBy: [SkillSortAttributeInput!]) {
    skills(limit: $limit, sortBy: $sortBy) {
      data {
        name
        defaultBadgeIcon
      }
    }
  }
`)

const getSkillLevelOptionsSettingsQuery = graphql(`
  query GetSkillLevelOptionsSettings {
    skillLevelSettings {
      name
      defaultBadgeTheme
    }
  }
`)

type BadgeInfo = {
  width: { tablet: number; desktop: number }
  rotation: { tablet: number; desktop: number }
}

const START_BADGES_CONFIG: { [key: number]: BadgeInfo } = {
  0: {
    width: {
      tablet: 70,
      desktop: 140,
    },
    rotation: {
      tablet: -13,
      desktop: 8,
    },
  },
  1: {
    width: {
      tablet: 120,
      desktop: 200,
    },
    rotation: {
      tablet: 9,
      desktop: -5,
    },
  },
  2: {
    width: {
      tablet: 135,
      desktop: 110,
    },
    rotation: {
      tablet: 8,
      desktop: 8,
    },
  },
}

const END_BADGES_CONFIG: { [key: number]: BadgeInfo } = {
  0: {
    width: {
      tablet: 120,
      desktop: 130,
    },
    rotation: {
      tablet: 8,
      desktop: -3,
    },
  },
  1: {
    width: {
      tablet: 80,
      desktop: 110,
    },
    rotation: {
      tablet: -5,
      desktop: 12,
    },
  },
  2: {
    width: {
      tablet: 80,
      desktop: 180,
    },
    rotation: {
      tablet: -6,
      desktop: -6,
    },
  },
}

const EmptyContainer = styled(View)`
  border-radius: 20px;
  background-color: ${token('surface/soft')};
  height: 402px;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  overflow: hidden;

  @media screen and (max-width: ${v2_breakpoint.tablet}) {
    flex-direction: column;
    justify-content: flex-start;
  }
`

const EmptyContent = styled(View)`
  display: flex;
  flex-direction: column;
  justify-content: center;
  width: 50%;
  min-width: 50%;
  z-index: 2;
  gap: 0;

  @media screen and (max-width: ${v2_breakpoint.tablet}) {
    width: 100%;
    height: 50%;
    min-height: 50%;
  }
`

const BadgeGrid = styled(View)`
  display: grid;
  width: 20%;
  grid-template-rows: 1fr 1fr 1fr;
  grid-template-columns: repeat(2, 1fr);
  height: 120%;

  @media screen and (max-width: ${v2_breakpoint.tablet}) {
    width: 160%;
    height: 20%;
    grid-template-rows: 1fr;
    grid-template-columns: repeat(2, 1fr);
    grid-template-areas: 'badge-0 badge-1';
  }
`

const FirstBadgeGrid = styled(BadgeGrid)`
  justify-content: flex-end;

  @media screen and (min-width: ${v2_breakpoint.tablet}) {
    grid-template-areas:
      'badge-0 .'
      '. badge-1'
      'badge-2 .';
  }

  @media screen and (max-width: ${v2_breakpoint.tablet}) {
    align-items: flex-end;
  }
`

const EndBadgeGrid = styled(BadgeGrid)`
  justify-content: flex-start;
  @media screen and (min-width: ${v2_breakpoint.tablet}) {
    grid-template-areas:
      'badge-0 badge-1'
      '. .'
      'badge-2 .';
  }

  @media screen and (max-width: ${v2_breakpoint.tablet}) {
    align-items: flex-start;
  }
`

const BadgeWrapper = styled(View)<{
  width: number
  rotation: number
  gridArea: string
}>`
  width: ${({ width }) => `${width}px`};
  transform: rotate(${({ rotation }) => rotation}deg);
  grid-area: ${({ gridArea }) => gridArea};
  z-index: 1;
  justify-self: center;
`

const Badge: React.FC<{
  index: number
  side: 'start' | 'end'
  name: string
  defaultBadgeIcon: string
  theme?: string
  subtitle?: string
}> = ({ index, side, name, defaultBadgeIcon, theme = 'bronze', subtitle = 'Novice' }) => {
  const isSmallScreen = useIsTablet()

  const config = side === 'start' ? START_BADGES_CONFIG[index] : END_BADGES_CONFIG[index]

  if (!config) {
    return null
  }

  const { width, rotation } = config

  const icon = SkillIconId.catch('skill--graduation--cap').parse(defaultBadgeIcon)
  const badgeTheme = SkillColor.catch('bronze').parse(theme)

  return (
    <BadgeWrapper
      gridArea={`badge-${index}`}
      width={isSmallScreen ? width.tablet : width.desktop}
      rotation={isSmallScreen ? rotation.tablet : rotation.desktop}
    >
      <RichBadge theme={badgeTheme} title={name} subtitle={subtitle} iconId={icon} />
    </BadgeWrapper>
  )
}

export const EmptyView: React.FC<{ showLeaderboard: boolean }> = ({ showLeaderboard }) => {
  const { t } = useTranslation()

  const isSmallScreen = useIsTablet()

  const { data } = useGraphQuery({ document: skillLearnersQuery }, { limit: 3 })
  const { data: skillsData } = useGraphQuery(
    { document: getSkillBadgesQuery },
    { limit: 6, sortBy: [{ key: 'SUBSCRIBERS', order: 'DESC' }] }
  )
  const { data: skillLevels } = useGraphQuery({ document: getSkillLevelOptionsSettingsQuery })

  const setSelectSkillOpen = useSetAtom(OpenSelectSkillModalAtom)

  const learners = (data?.topSkillLearners.skillLearners ?? []).map(learner =>
    convertGQLBasicUserToAvatarStackUserShape(learner.userInfo)
  )

  const produceBadgeLists = (
    items: GetSkillBadgesQuery['skills']['data']
  ): GetSkillBadgesQuery['skills']['data'] => {
    let itemsCopy = [...items]

    // If the array has less than 6 items, repeat the items until we have 6
    if (items.length < 6) {
      const diff = Math.ceil((6 - items.length) / items.length)
      for (let i = 0; i < diff; i++) {
        itemsCopy = itemsCopy.concat(items)
      }
    }

    return itemsCopy
  }

  const usersWithBadges = data?.topSkillLearners.totalUsersWithBadges
  const badges = produceBadgeLists(
    skillsData?.skills.data ?? [{ name: 'Project Management', defaultBadgeIcon: 'skill--graduation--cap' }]
  )
  const levels = skillLevels?.skillLevelSettings ?? []

  return (
    <EmptyContainer>
      <FirstBadgeGrid padding={isSmallScreen ? '4' : '16'}>
        {badges.slice(0, isSmallScreen ? 2 : 3).map((badge, i) => {
          const level = levels[i % levels.length]
          return (
            <Badge
              key={`badge_${i}`}
              index={i}
              side='start'
              theme={level?.defaultBadgeTheme}
              subtitle={level?.name}
              {...badge}
            />
          )
        })}
      </FirstBadgeGrid>
      <EmptyContent>
        <Text bold size='large' color='foreground/primary'>
          {t('skills.board.empty.title')}
        </Text>
        <Spacer size='8' />
        <View padding='none medium'>
          <Text align='center'>{t('skills.board.empty.description')}</Text>
        </View>
        {showLeaderboard && learners.length > 0 && !isSmallScreen && (
          <>
            <Spacer size='24' />
            <View>
              <AvatarStack size='tiny' users={learners} />
              <Text color='foreground/muted'>
                {learners.length < 3
                  ? t('skills.board.empty.other-learners-single', { name: learners[0]?.firstName })
                  : t('skills.board.empty.other-learners-multiple', {
                      name1: learners[0]?.firstName,
                      name2: learners[1]?.firstName,
                      otherLearnersNum: usersWithBadges,
                    })}
              </Text>
            </View>
          </>
        )}
        <Spacer size='40' />
        <Button
          variant='secondary'
          onClick={() => {
            setSelectSkillOpen(true)
          }}
        >
          {t('skills.board.empty.browse-button')}
        </Button>
      </EmptyContent>
      <EndBadgeGrid padding='16'>
        {badges.slice(3, isSmallScreen ? 5 : 6).map((badge, i) => {
          const level = levels[(3 - i) % levels.length]
          return (
            <Badge
              key={`badge_${i}`}
              index={i}
              side='end'
              theme={level?.defaultBadgeTheme}
              subtitle={level?.name}
              {...badge}
            />
          )
        })}
      </EndBadgeGrid>
    </EmptyContainer>
  )
}
