import { DateTime } from 'luxon'
import { assertNever, isNotDefined } from 'sierra-domain/utils'

export type CertificateStatus =
  | { revokedAt: DateTime; status: 'revoked' }
  | { expiredAt: DateTime; status: 'expired'; since: { months: number; days: number } }
  | { expiresAt: DateTime; status: 'expiring-soon'; in: { months: number; days: number } }
  | {
      date?: DateTime
      status: 'valid'
    }

export const getCertificateDate = (s: CertificateStatus): DateTime | undefined => {
  switch (s.status) {
    case 'revoked': {
      return s.revokedAt
    }

    case 'expired': {
      return s.expiredAt
    }

    case 'expiring-soon': {
      return s.expiresAt
    }

    case 'valid': {
      return s.date
    }
    default:
      assertNever(s)
  }
}

export const _checkCertificateStatus = ({
  revokedAt,
  expiresAt,
}: {
  revokedAt?: DateTime
  expiresAt?: DateTime
}): CertificateStatus => {
  // Check revoked first
  if (revokedAt && revokedAt.diffNow('months').months < 0) {
    return { revokedAt: revokedAt, status: 'revoked' }
  }

  // Then start checking expiresAt
  if (isNotDefined(expiresAt)) {
    // if none expires at, assume valid
    return { status: 'valid' }
  }

  const diffMonths = expiresAt.diffNow('months').months
  const diffDays = expiresAt.diffNow('days').days

  if (diffMonths < 0) {
    return {
      expiredAt: expiresAt,
      status: 'expired',
      since: { months: Math.abs(diffMonths), days: Math.abs(diffDays) },
    }
  }

  // expiring soon if its 1 month left!
  if (0 < diffMonths && diffMonths <= 1) {
    return {
      expiresAt: expiresAt,
      status: 'expiring-soon',
      in: { months: Math.abs(diffMonths), days: Math.abs(diffDays) },
    }
  }

  return { date: expiresAt, status: 'valid' }
}

export const checkCertificateStatus = (cert: {
  revokedAt?: string
  expiresAt?: string
}): CertificateStatus => {
  const expiresAt = cert.expiresAt !== undefined ? DateTime.fromISO(cert.expiresAt) : undefined
  const revokedAt = cert.revokedAt !== undefined ? DateTime.fromISO(cert.revokedAt) : undefined

  return _checkCertificateStatus({ expiresAt, revokedAt })
}
