import _ from 'lodash'
import { assert } from 'sierra-domain/utils'
import {
  CustomElement,
  CustomElementRegistry,
  CustomElementType,
  CustomText,
  SanaEditor,
} from 'sierra-domain/v3-author'
import {
  Descendant,
  Editor,
  Element,
  Location,
  Node,
  NodeEntry,
  Path,
  Point,
  Range,
  Text,
  Transforms,
} from 'slate'
import { ReactEditor } from 'slate-react'

export type NodeOrEditor = Node | SanaEditor
export type ElementKey = keyof CustomElementRegistry
type ElementOf<Type extends ElementKey> = CustomElementRegistry[Type]

type ElementChecker<Type extends ElementKey> = (
  element: NodeOrEditor | undefined
) => element is ElementOf<Type>

function isElementTypeOneOfCurried<Type extends ElementKey>(types: Type[]): ElementChecker<Type> {
  return function (element): element is ElementOf<Type> {
    return element !== undefined && Element.isElement(element) && types.includes(element.type as Type)
  }
}

export function isElementType<Type extends ElementKey>(
  type: Type | Type[],
  element: NodeOrEditor | undefined
): element is ElementOf<Type>
export function isElementType<Type extends ElementKey>(type: Type | Type[]): ElementChecker<Type>
export function isElementType<Type extends ElementKey>(
  type: Type | Type[],
  ...args: [NodeOrEditor | undefined] | []
): unknown {
  const checkElement = isElementTypeOneOfCurried(Array.isArray(type) ? type : [type])

  if (args.length === 0) {
    return checkElement
  } else {
    return checkElement(args[0])
  }
}

export function assertElementType<Type extends ElementKey>(
  type: Type | Type[],
  element: NodeOrEditor | undefined
): asserts element is ElementOf<Type> {
  assert(
    isElementType(type, element),
    () => `Expected type ${JSON.stringify(type)}, got: ${JSON.stringify(element)}`
  )
}

// ---

export const getCurrentlySelectedLeaf = (editor: SanaEditor): [CustomText, Path] | undefined => {
  const anchor = editor.selection?.anchor

  if (anchor !== undefined) {
    const node = Node.get(editor, anchor.path)

    if (Text.isText(node)) {
      return [node, anchor.path]
    }
  }
}

export const getCurrentlySelectedNode = (editor: SanaEditor): NodeEntry | undefined => {
  const currentLeaf = getCurrentlySelectedLeaf(editor)

  if (currentLeaf) {
    const [, path] = currentLeaf
    const parentNodePath = path.slice(0, -1)

    return [Node.get(editor, parentNodePath), parentNodePath]
  }
}

export const getCurrentNodeEmpty = (editor: SanaEditor): boolean => {
  const currentEntry = getCurrentlySelectedNode(editor)
  if (!currentEntry) return false

  const [currentNode] = currentEntry
  if (!Element.isElement(currentNode)) return false
  if (currentNode.children.length !== 1) return false
  const firstChild = currentNode.children[0]
  return Text.isText(firstChild) && firstChild.text === ''
}

export const getCurrentLeafIsAtRoot = (editor: SanaEditor): boolean => {
  const leaf = getCurrentlySelectedLeaf(editor)
  return leaf !== undefined ? leaf[1].length === 2 : false
}

export const getParent = (editor: SanaEditor, node: NodeEntry): NodeEntry => {
  const [, path] = node
  const parentNodePath = path.slice(0, -1)

  return [Node.get(editor, parentNodePath), parentNodePath]
}

export const getCurrentlySelectedElements = (editor: SanaEditor): NodeEntry<Element>[] => {
  const selection = editor.selection
  if (!selection || Range.isCollapsed(selection)) {
    return [] as NodeEntry<Element>[]
  }

  const { anchor, focus } = selection

  // this check is necessary due to this problem: https://github.com/ianstormtaylor/slate/issues/752
  if (
    anchor.path[0] !== undefined &&
    focus.path[0] !== undefined &&
    anchor.path[0] < focus.path[0] &&
    focus.path[1] === 0 &&
    focus.offset === 0
  ) {
    Transforms.move(editor, { distance: 1, unit: 'character', edge: 'focus', reverse: true })
  }

  const nodes = Editor.nodes<Element>(editor, {
    reverse: Range.isBackward({ anchor, focus }),
    at: [anchor.path, focus.path],
    match: n =>
      Element.isElement(n) &&
      ![
        'bullet-card',
        'title-card',
        'list-item',
        'check-list-item',
        'horizontal-stack',
        'vertical-stack',
      ].includes(n.type),
  })

  return Array.from(nodes)
}

export const findNode = (
  editor: Node,
  where: (node: CustomElement, path: Path) => boolean
): [node: CustomElement, path: Path] | [] => {
  for (const [node, path] of Node.nodes(editor)) {
    if (!Editor.isEditor(node) && Element.isElement(node) && where(node, path)) {
      return [node, path]
    }
  }
  return []
}

export const isCardElement = (element: Element | undefined): boolean => {
  return isElementType(
    [
      'title-card',
      'reflection-card',
      'poll-card',
      'sliding-scale-card',
      'question-card',
      'bullet-card',
      'sticky-notes-card',
      'project-card',
    ],
    element
  )
}

export function safeToDomNode(editor: Editor, node: Node): HTMLElement | undefined {
  try {
    return ReactEditor.toDOMNode(editor, node)
  } catch (e) {
    return undefined
  }
}

export const parentType = (editor: Editor, path: Path): CustomElementType | 'editor' | undefined => {
  const [parent] = editor.node(Path.parent(path))

  if (path.length === 0) {
    return undefined
  } else if (Editor.isEditor(parent)) {
    return 'editor'
  } else if (Element.isElement(parent)) {
    return parent.type
  } else {
    return undefined
  }
}

export const elementAtPath = (document: Node[], [i, ...rest]: Path): CustomElement | undefined => {
  if (i === undefined) {
    return undefined
  }

  const element = document[i]

  if (!Element.isElement(element)) {
    return undefined
  }

  if (rest.length === 0) {
    return element
  }

  // Recursively call this function until we've checked the whole path.
  return elementAtPath(element.children, rest)
}

export const nodeAtPath = (document: Node[], [i, ...rest]: Path): Node | undefined => {
  if (i === undefined) {
    return undefined
  }

  const node = document[i]

  if (rest.length === 0) {
    return node
  }

  // Recursively call this function until we've checked the whole path.
  return Element.isElement(node) ? nodeAtPath(node.children, rest) : undefined
}

function _rangeOfNode([node, path]: NodeEntry): [Point, Point] {
  const texts = Array.from(Node.texts(node))

  const firstTextEntry = _.first(texts)
  if (!firstTextEntry) throw new Error('Node must have a first text node')
  const [, firstTextPath] = firstTextEntry
  const firstTextPoint = { path: [...path, ...firstTextPath], offset: 0 }

  const lastTextEntry = _.last(texts)
  if (!lastTextEntry) throw new Error('Node must have a last text node')
  const [{ text }, lastTextPath] = lastTextEntry
  const lastTextPoint = { path: [...path, ...lastTextPath], offset: text.length }

  return [firstTextPoint, lastTextPoint]
}

export function rangeOfNode(entry: NodeEntry): [Point, Point]
export function rangeOfNode(editor: Editor, at: Location): [Point, Point]
export function rangeOfNode(first: Editor | NodeEntry, second: unknown = undefined): [Point, Point] {
  if (Editor.isEditor(first)) {
    assert(Location.isLocation(second))
    const entry = first.node(second)
    return _rangeOfNode(entry)
  } else {
    return _rangeOfNode(first)
  }
}

export function rangeEncapsulatesNodeEntry(range: Range, [node, path]: NodeEntry<Descendant>): boolean {
  const [nodeStart, nodeEnd] = rangeOfNode([node, path])
  const [rangeStart, rangeEnd] = Range.edges(range)

  const rangeStartsBeforeNode = Point.isBefore(rangeStart, nodeStart) || Point.equals(rangeStart, nodeStart)
  const rangeEndsAfterNode = Point.isAfter(rangeEnd, nodeEnd) || Point.equals(rangeEnd, nodeEnd)

  return rangeStartsBeforeNode && rangeEndsAfterNode
}
