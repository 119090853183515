import { DateTime } from 'luxon'
import { Text, View } from 'sierra-ui/primitives'
import { token } from 'sierra-ui/theming'
import styled from 'styled-components'

const Container = styled(View)`
  border: 1px solid ${token('border/default')};
  flex-direction: column;
  width: 3.5rem;
  height: 3.5rem;
  padding: 0.5rem;
  border-radius: 10px;
  user-select: none;
`

const Month = styled(Text)`
  text-transform: uppercase;
  letter-spacing: 2%;
`

const DayNumber = styled(Text)`
  font-variant-numeric: tabular-nums;
`

const DayNumberToday = styled(Text)`
  font-variant-numeric: tabular-nums;
  background-color: ${token('destructive/background')};
  color: ${token('destructive/foreground')};
  width: 1.5rem;
  height: 1.5rem;
  border-radius: 100%;
  line-height: 1.5rem;
  text-align: center;
`

type DateStampProps = { date?: string }

export const Datestamp: React.FC<DateStampProps> = ({ date: dateProp }) => {
  if (dateProp === undefined) {
    return (
      <Container gap='none'>
        <DayNumber size='large' color='grey50' bold>
          -
        </DayNumber>
      </Container>
    )
  }
  const date = DateTime.fromISO(dateProp)
  const today = DateTime.now().toFormat('MMM d').toString()

  const isToday = date.toFormat('MMM d') === today

  if (isToday)
    return (
      <Container gap='none'>
        <Month size='technical' color='redVivid' bold>
          {date.toFormat('MMM')}
        </Month>
        <DayNumberToday size='micro' bold>
          {date.toFormat('d')}
        </DayNumberToday>
      </Container>
    )

  return (
    <Container gap='none'>
      <Month size='technical' color='grey25' bold>
        {date.toFormat('MMM')}
      </Month>
      <DayNumber size='large' color='grey50' bold>
        {date.toFormat('d')}
      </DayNumber>
    </Container>
  )
}
