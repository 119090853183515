import { PrimitiveAtom, useSetAtom } from 'jotai'
import { FC, useEffect, useState } from 'react'
import { getCloudinaryUrlFromFileContentVideo } from 'sierra-client/api/content'
import { useUploadVideo } from 'sierra-client/hooks/use-video-upload'
import { CreatePageNarration } from 'sierra-client/views/flexible-content/ai-narrations/use-create-narration'
import { ExistingNarration } from 'sierra-client/views/flexible-content/card-narration/existing-narration'
import { useCreatePageContext } from 'sierra-client/views/flexible-content/create-page-context'
import { CreateContentId } from 'sierra-domain/api/nano-id'
import { UserId } from 'sierra-domain/api/uuid'
import { AssetContext } from 'sierra-domain/asset-context'
import { apply } from 'sierra-domain/editor/operations'
import { File } from 'sierra-domain/flexible-content/types'

type CardNarrationProps = {
  narrationStateAtom: PrimitiveAtom<boolean>
  createContentId: CreateContentId
  assetContext: AssetContext
  createPageNarration: CreatePageNarration
  card: File
}

const CardNarration: FC<CardNarrationProps> = ({
  createContentId,
  narrationStateAtom,
  assetContext,
  createPageNarration,
  card,
}) => {
  const fileId = card.id
  const { uploadVideo } = useUploadVideo()
  const { operationState } = useCreatePageContext()
  const [firstLoad, setFirstLoad] = useState(true)

  const saveFile = async ({
    blob,
    createdBy,
  }: {
    blob: Blob
    createdBy: UserId | undefined
  }): Promise<void> => {
    const response = await uploadVideo(blob, assetContext)

    if (response !== undefined) {
      const { url, durationInSeconds } = response

      // To fix issue where Safari can't play narrations uploaded using Chrome we upload the video to cloudinary
      // and replace the url here.
      const cloudinaryUrl = getCloudinaryUrlFromFileContentVideo(url)

      apply(operationState, {
        type: 'update-files',
        fileIds: [fileId],
        update: file => {
          file.narration = { url: cloudinaryUrl, durationInSeconds, createdBy }
        },
      })
    }
  }

  const deleteNarration = (): void => {
    apply(operationState, {
      type: 'update-files',
      fileIds: [fileId],
      update: file => {
        file.narration = undefined
      },
    })
  }

  const setNarrationState = useSetAtom(narrationStateAtom)
  useEffect(() => {
    if (!firstLoad) {
      setNarrationState(previous => !previous)
      setFirstLoad(false)
    }
  }, [firstLoad, setFirstLoad, setNarrationState])

  return (
    <ExistingNarration
      createPageNarration={createPageNarration}
      onDelete={deleteNarration}
      saveFile={saveFile}
      card={card}
      contentId={createContentId}
    />
  )
}

// eslint-disable-next-line import/no-default-export
export default CardNarration
