import { EditorKeyboardEvent, SanaEditor } from 'sierra-domain/v3-author'

/**
 * Pressing cmd+a or ctrl+a should select all slate text nodes within the editor.
 * Without this, **all** the text within the editor textarea would be selected,
 * (including text in buttons, menus, etc.) which is not the desired behavior.
 */
export function selectAll(event: EditorKeyboardEvent, editor: SanaEditor): void {
  const isMetaKey = event.metaKey || event.ctrlKey
  const isSelectAll = isMetaKey && event.key === 'a'
  if (!isSelectAll) {
    return
  }

  event.preventDefault()
  event.stopPropagation()

  editor.select([])
}
