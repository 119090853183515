import React, { useEffect } from 'react'
import { updateNodeWithId } from 'sierra-client/views/v3-author/command'
import { toSanaImage } from 'sierra-client/views/v3-author/images/unsplash-browser'
import { useUnsplashSuggestions } from 'sierra-client/views/v3-author/images/use-unsplash-suggestions'
import { LoadingSpinner, View } from 'sierra-ui/primitives'
import { useSlateStatic } from 'slate-react'

export const ImageWithFirstSuggestion: React.FC<{ nodeId: string }> = ({ nodeId }) => {
  const editor = useSlateStatic()
  const { suggestions } = useUnsplashSuggestions({ nodeId })
  const suggestedImage = suggestions?.images[0]

  useEffect(() => {
    if (suggestedImage) {
      void toSanaImage(suggestedImage).then(image => {
        updateNodeWithId(editor, nodeId, { image })
      })
    }
  }, [nodeId, editor, suggestedImage])

  return (
    <View grow justifyContent='center' direction='column' alignItems='center'>
      <LoadingSpinner size='medium' padding={'xxsmall'} />
    </View>
  )
}
