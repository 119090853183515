import { useCallback, useEffect, useRef, useState } from 'react'
import { usePost } from 'sierra-client/hooks/use-post'
import { LiveSessionWithAssignments } from 'sierra-domain/api/admin'
import { XRealtimeAdminLiveSessionsListLiveSessionsForAssignments } from 'sierra-domain/routes'

type UseLiveSessionData = {
  liveSessions: LiveSessionWithAssignments[]
  isLoading: boolean
  fetchLiveSessions: () => Promise<void>
}

export const useLiveSessions = (): UseLiveSessionData => {
  const { postWithUserErrorException } = usePost()
  const [isLoading, setLoading] = useState(false)
  const [liveSessions, setLiveSessions] = useState<LiveSessionWithAssignments[]>([])
  const unmounted = useRef(false)

  const fetchLiveSessions = useCallback<UseLiveSessionData['fetchLiveSessions']>(async () => {
    setLoading(true)
    const result = await postWithUserErrorException(
      XRealtimeAdminLiveSessionsListLiveSessionsForAssignments,
      {}
    )
    if (unmounted.current) return
    setLiveSessions(result.liveSessions)
    setLoading(false)
  }, [postWithUserErrorException])

  useEffect(() => {
    unmounted.current = false
    void fetchLiveSessions()
    return () => {
      unmounted.current = true
    }
  }, [fetchLiveSessions])

  return {
    isLoading,
    liveSessions,
    fetchLiveSessions,
  }
}
