import { createSelector } from '@reduxjs/toolkit'
import _ from 'lodash'
import { selectCurrentCourseId } from 'sierra-client/state/content/selectors'
import { ResultsState } from 'sierra-client/state/content/types'
import { RootState } from 'sierra-client/state/types'
import { CourseId } from 'sierra-domain/api/nano-id'
import { isDefined } from 'sierra-domain/utils'

const selectResults = (rootState: RootState): ResultsState => rootState.results

export const selectTimeLeft = createSelector(selectResults, results => results.timeLeft)

export const selectPassedTimestamps = createSelector(selectResults, results => results.passedTimestamps)
export const selectProgressTimestamps = createSelector(selectResults, results => results.progressTimestamps)

export const selectGetPassedTimestampByCourseId = createSelector(selectPassedTimestamps, timestamps =>
  _.memoize((courseId: CourseId | undefined) => (courseId !== undefined ? timestamps[courseId] : undefined))
)

const selectGetProgressTimestampByCourseId = createSelector(selectProgressTimestamps, timestamps =>
  _.memoize((courseId: CourseId | undefined) => (courseId !== undefined ? timestamps[courseId] : undefined))
)

const selectExercises = createSelector(selectResults, results =>
  _.memoize((courseId?: CourseId) => (isDefined(courseId) ? results.exercises[courseId] : undefined))
)

export const selectCurrentCoursePassedTimestamp = createSelector(
  [selectCurrentCourseId, selectGetPassedTimestampByCourseId],
  (courseId, passedTimestampGetter) => passedTimestampGetter(courseId)
)

export const selectCurrentCourseProgressTimestamp = createSelector(
  [selectCurrentCourseId, selectGetProgressTimestampByCourseId],
  (courseId, progressTimestampGetter) => progressTimestampGetter(courseId)
)

export const selectCurrentExercises = createSelector(
  [selectCurrentCourseId, selectExercises],
  (courseId, exercisesGetter) => exercisesGetter(courseId)
)
