import { DateTime } from 'luxon'
import React, { Fragment } from 'react'
import { CardInfo } from 'sierra-client/components/recommendations/components/card-context/types'
import { getDaysDiff, useGetDaysLeft } from 'sierra-client/core/format'
import { useTranslation } from 'sierra-client/hooks/use-translation'
import { assertNever, iife } from 'sierra-domain/utils'
import { ColorName } from 'sierra-ui/color/types'
import { Icon, IconId } from 'sierra-ui/components'
import { Text, View } from 'sierra-ui/primitives'
import styled from 'styled-components'

const PillBase = styled(View).attrs({
  radius: 'size-8',
  gap: '6',
})`
  pointer-events: none;
  padding: 0.5rem 0.75rem;
`
export const RecommendationPill: React.FC<{
  background: ColorName
  iconId: IconId
  children: React.ReactNode
}> = ({ background, iconId, children }) => {
  return (
    <PillBase background={background}>
      <Icon iconId={iconId} color='black' />
      <Text color='black'>{children}</Text>
    </PillBase>
  )
}

export const SessionPill = React.memo<{ startDateISO: Date | undefined }>(({ startDateISO }) => {
  const { t } = useTranslation()

  if (startDateISO === undefined) return null

  const sessionStartDateTime = DateTime.fromJSDate(startDateISO)

  const daysDiff = getDaysDiff(sessionStartDateTime)

  const sessionStartText = (() => {
    if (daysDiff === 0) {
      return `${t('dictionary.live')} ${t('dictionary.today')}`
    } else if (daysDiff === 1) {
      return `${t('dictionary.live')} ${t('dictionary.tomorrow')}`
    } else if (daysDiff > 1) {
      return `${t('dictionary.live')} ${sessionStartDateTime.monthShort} ${sessionStartDateTime.day}`
    } else {
      // Passed sessions shouldn't be shown here, but don't show anything for those.
      return undefined
    }
  })()

  if (sessionStartText === undefined) {
    return null
  }

  return (
    <RecommendationPill iconId='calendar' background='white'>
      {sessionStartText}
    </RecommendationPill>
  )
})

export const DueDatePill = React.memo<{ dueISO: string | undefined }>(({ dueISO }) => {
  const { t } = useTranslation()
  const { getFormattedDaysLeft } = useGetDaysLeft()

  if (dueISO === undefined) return null

  const dueDateTime = DateTime.fromISO(dueISO)

  const daysDiff = getDaysDiff(dueDateTime)

  const dueDateText =
    daysDiff > 2
      ? `${t('dictionary.due')} ${dueDateTime.monthShort} ${dueDateTime.day}`
      : getFormattedDaysLeft(dueISO)

  const background = ((): ColorName => {
    if (daysDiff === 0) return 'orangeLight'
    else if (daysDiff < 0) return 'redLight'
    else return 'white'
  })()

  return (
    <RecommendationPill iconId='calendar' background={background}>
      {dueDateText}
    </RecommendationPill>
  )
})

export const AdornmentRenderer: React.FC<{ cardInfo: CardInfo }> = ({ cardInfo }) => {
  const { text, adornment } = cardInfo

  return (
    <Fragment>
      {iife(() => {
        switch (adornment?.type) {
          case 'icon':
            return <Icon iconId={adornment.iconId} color='currentColor' />
          case 'custom':
            return adornment.component
          case 'pill':
            return adornment.component
          case undefined:
            return null
          default:
            assertNever(adornment)
        }
      })}
      {adornment?.type !== 'pill' && text !== '' && (
        <Text color='LEGACY_DEFAULT_TEXT_COLOR_REPLACE_ASAP' size='small' bold>
          {text}
        </Text>
      )}
    </Fragment>
  )
}
