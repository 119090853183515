import _ from 'lodash'
import { useCallback, useEffect, useState } from 'react'
import { usePost } from 'sierra-client/hooks/use-post'
import { useTranslation } from 'sierra-client/hooks/use-translation'
import { TranslationKey } from 'sierra-client/hooks/use-translation/types'
import { useSelector } from 'sierra-client/state/hooks'
import { selectUser } from 'sierra-client/state/user/user-selector'
import { GroupInsightsCard } from 'sierra-client/views/manage/components/group-insights'
import { ManageHeadline } from 'sierra-client/views/manage/components/layout/manage-headline'
import { CompletionCard, SecondaryCard } from 'sierra-client/views/manage/components/overview-cards'
import { formatPercentage } from 'sierra-client/views/measure/analytics-v2/util'
import { OverviewMetricsResponse, TopGroup } from 'sierra-domain/api/analytics-v2'
import {
  XRealtimeAnalyticsMetricsListTopGroups,
  XRealtimeAnalyticsMetricsOverview,
} from 'sierra-domain/routes'
import { MenuItem } from 'sierra-ui/components'
import { Heading, Text, View } from 'sierra-ui/primitives'
import { SingleSelectDropdown } from 'sierra-ui/primitives/menu-dropdown'
import { DarkTokenProvider } from 'sierra-ui/theming'
import { v2_breakpoint } from 'sierra-ui/theming/breakpoints'
import styled from 'styled-components'

const StatisticsGrid = styled.div`
  display: grid;
  grid-template-columns: 1fr;
  gap: 1rem;

  @media screen and (min-width: ${v2_breakpoint.phone}) {
    gap: 1.5rem;
    grid-template-columns: 1fr 1fr minmax(min-content, 0.75fr);
    grid-template-rows: 1fr 1fr;

    > :nth-child(1) {
      grid-row: 1 / -1;
    }

    > :nth-child(4) {
      grid-column: 3;
      grid-row: 1 / -1;
    }
  }

  @media screen and (min-width: ${v2_breakpoint.desktop_small}) {
    gap: 2rem;
  }
`

type UseMetricsData = {
  isLoading: boolean
  metrics?: OverviewMetricsResponse
}

type ActiveUserMetric = 'dau' | 'wau' | 'mau' | 'qau'
type TotalUsersMetric = 'all' | 'standard' | 'scorm' | 'guest' | 'deactivated'

const activeUserMetricToDescriptionKey: Record<ActiveUserMetric, TranslationKey> = {
  dau: 'manage-dashboard.daily-active-users.description',
  wau: 'manage-dashboard.weekly-active-users.description',
  mau: 'manage-dashboard.monthly-active-users.description',
  qau: 'manage-dashboard.quarterly-active-users.description',
}

const activeUserMetricToMetricsKey: Record<ActiveUserMetric, keyof OverviewMetricsResponse> = {
  dau: 'dailyActiveUsers',
  wau: 'weeklyActiveUsers',
  mau: 'monthlyActiveUsers',
  qau: 'quarterlyActiveUsers',
}

const totalUsersMetricToMetricsKey: Record<TotalUsersMetric, keyof OverviewMetricsResponse> = {
  standard: 'standardUsers',
  all: 'totalUsers',
  guest: 'guestUsers',
  scorm: 'scormUsers',
  deactivated: 'deactivatedUsers',
}

const totalUsersMetricToDescriptionKey: Record<TotalUsersMetric, TranslationKey> = {
  standard: 'manage-dashboard.standard.description',
  all: 'manage-dashboard.all.description',
  guest: 'manage-dashboard.guest.description',
  scorm: 'manage-dashboard.scorm.description',
  deactivated: 'manage-dashboard.deactivated.description',
}

const useMetrics = (): UseMetricsData => {
  const { postWithUserErrorException } = usePost()
  const [isLoading, setIsLoading] = useState<boolean>(false)
  const [metrics, setMetrics] = useState<OverviewMetricsResponse | undefined>()

  const fetchMetrics = useCallback(async (): Promise<void> => {
    setIsLoading(true)
    const response = await postWithUserErrorException(XRealtimeAnalyticsMetricsOverview, {})
    setMetrics(response)
    setIsLoading(false)
  }, [postWithUserErrorException])

  useEffect(() => {
    void fetchMetrics()
  }, [fetchMetrics])

  return {
    isLoading,
    metrics,
  }
}

type UseGroupsData = {
  isLoading: boolean
  groups?: TopGroup[]
}

const useGroups = (): UseGroupsData => {
  const { postWithUserErrorException } = usePost()
  const [isLoading, setIsLoading] = useState<boolean>(false)
  const [groups, setGroups] = useState<TopGroup[] | undefined>()

  const fetchGroups = useCallback(async (): Promise<void> => {
    setIsLoading(true)
    const response = await postWithUserErrorException(XRealtimeAnalyticsMetricsListTopGroups, {})
    setGroups(_.orderBy(response.groups, group => group.score, 'desc'))
    setIsLoading(false)
  }, [postWithUserErrorException])

  useEffect(() => {
    void fetchGroups()
  }, [fetchGroups])

  return {
    isLoading,
    groups,
  }
}

const CompletionRate = styled(View).attrs({ direction: 'column', gap: 'xsmall' })`
  min-width: 200px;
`

export const Dashboard: React.FC<unknown> = () => {
  const { t } = useTranslation()
  const { metrics } = useMetrics()
  const { groups } = useGroups()
  const [selectedActiveUserMetrics, setSelectedActiveUserMetrics] = useState<ActiveUserMetric>('mau')
  const [selectedTotalUsersMetric, setSelectedTotalUsersMetric] = useState<TotalUsersMetric>('standard')
  const user = useSelector(selectUser)

  if (user === undefined) {
    return <></>
  }

  const activeUsersDescription = t(activeUserMetricToDescriptionKey[selectedActiveUserMetrics])
  const activeUsers = metrics?.[activeUserMetricToMetricsKey[selectedActiveUserMetrics]]
  const totalUsers = metrics?.[totalUsersMetricToMetricsKey[selectedTotalUsersMetric]]
  const totalUsersDescription = t(totalUsersMetricToDescriptionKey[selectedTotalUsersMetric])

  const activeUserItems: MenuItem<ActiveUserMetric>[] = [
    {
      id: 'dau',
      selected: selectedActiveUserMetrics === 'dau',
      label: t('manage-dashboard.daily-active-users.title'),
      type: 'label',
    },
    {
      id: 'wau',
      selected: selectedActiveUserMetrics === 'wau',
      label: t('manage-dashboard.weekly-active-users.title'),
      type: 'label',
    },
    {
      id: 'mau',
      selected: selectedActiveUserMetrics === 'mau',
      label: t('manage-dashboard.monthly-active-users.title'),
      type: 'label',
    },
    {
      id: 'qau',
      selected: selectedActiveUserMetrics === 'qau',
      label: t('manage-dashboard.quarterly-active-users.title'),
      type: 'label',
    },
  ]
  const selectedActiveUserItem = activeUserItems.find(item => item.id === selectedActiveUserMetrics)

  const totalUserItems: MenuItem<TotalUsersMetric>[] = [
    {
      id: 'all',
      selected: selectedTotalUsersMetric === 'all',
      label: t('manage-dashboard.all.title'),
      type: 'label',
    },
    {
      id: 'standard',
      selected: selectedTotalUsersMetric === 'standard',
      label: t('filter.domain.user.type.standard'),
      type: 'label',
    },
    {
      id: 'guest',
      selected: selectedTotalUsersMetric === 'guest',
      label: t('filter.domain.user.type.guest'),
      type: 'label',
    },
    {
      id: 'scorm',
      selected: selectedTotalUsersMetric === 'scorm',
      label: t('filter.domain.user.type.scorm'),
      type: 'label',
    },
    {
      id: 'deactivated',
      selected: selectedTotalUsersMetric === 'deactivated',
      label: t('filter.domain.user.status.deactivated'),
      type: 'label',
    },
  ]
  const selectedTotalUsersItem = totalUserItems.find(item => item.id === selectedTotalUsersMetric)

  return (
    <div>
      <ManageHeadline>
        {user.accessLevel === 'group-admin'
          ? t('manage-dashboard.overview.title.group-admin')
          : t('manage-dashboard.overview.title.admin')}
      </ManageHeadline>
      <StatisticsGrid>
        <DarkTokenProvider>
          <CompletionCard>
            <Text size='small' bold>
              {t('manage-dashboard.assignment-completion.title')}
            </Text>
            <CompletionRate>
              <Heading size='hSuper'>{formatPercentage(metrics?.completionRate)}</Heading>
              <Text size='small' color='foreground/muted'>
                {t('manage-dashboard.assignment-completion.description')}
              </Text>
            </CompletionRate>
          </CompletionCard>
          <SecondaryCard
            title={
              <div style={{ alignSelf: 'flex-start', marginLeft: '-1rem', marginTop: '-1rem' }}>
                <SingleSelectDropdown
                  variant='ghost'
                  selectedItem={selectedActiveUserItem}
                  bold
                  menuItems={activeUserItems}
                  onSelect={item => setSelectedActiveUserMetrics(item.id)}
                />
              </div>
            }
            metric={activeUsers ?? '-'}
            description={activeUsersDescription}
          />
          <SecondaryCard
            title={
              <div style={{ alignSelf: 'flex-start', marginLeft: '-1rem', marginTop: '-1rem' }}>
                <SingleSelectDropdown
                  variant='ghost'
                  selectedItem={selectedTotalUsersItem}
                  bold
                  menuItems={totalUserItems}
                  onSelect={item => setSelectedTotalUsersMetric(item.id)}
                />
              </div>
            }
            metric={totalUsers ?? '-'}
            description={totalUsersDescription}
          />
        </DarkTokenProvider>

        <GroupInsightsCard
          groups={groups}
          // groups={[
          //   { groupId: 'a', groupName: 'group A', progress: 0.1, reasons: ['content-passed'], score: 0.05 },
          //   { groupId: 'b', groupName: 'group B', progress: 0.2, reasons: ['due-soon'], score: 0.1 },
          //   { groupId: 'c', groupName: 'group C', progress: 0.3, reasons: ['no-due-date'], score: 0.15 },
          //   { groupId: 'd', groupName: 'group D', progress: 0.4, reasons: ['on-track'], score: 0.2 },
          //   { groupId: 'e', groupName: 'group E', progress: 0.5, reasons: ['overdue'], score: 0.25 },
          // ]}
        />
      </StatisticsGrid>
    </div>
  )
}
