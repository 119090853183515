import { Atom } from 'jotai'
import { useEffect, useMemo, useState } from 'react'
import {
  AblyYDocWithAwarenessState,
  useAblyYDocWithAwareness,
  useAblyYDocWithFakeAwareness,
} from 'sierra-client/collaboration/use-ably-ydoc'
import { ContentPermission } from 'sierra-domain/api/common'
import { slateDocumentMapKey } from 'sierra-domain/collaboration/slate-document-map'
import { ScopedCreateContentId, ScopedYDocId } from 'sierra-domain/collaboration/types'
import { flexibleContentJsonDataKey } from 'sierra-domain/flexible-content/types'
import { asNonNullable } from 'sierra-domain/utils'
import { Awareness } from 'y-protocols/awareness'
import * as Y from 'yjs'

export type CreatePageYdocState = {
  yDoc: Y.Doc
  yDocId: ScopedYDocId
  latestYUpdateIdAtom: Atom<number | undefined>
  awareness: Awareness
  jsonDataYMap: Y.Map<unknown>
  permission: ContentPermission
}

const useIsLoaded = (yDoc: Y.Doc | undefined): { jsonDataYMap: Y.Map<unknown> | undefined } => {
  const [jsonDataYMap, setJsonDataYMap] = useState<Y.Map<unknown> | undefined>(undefined)

  useEffect(() => {
    if (!yDoc) return

    const yMap = yDoc.getMap<Y.Map<unknown>>()
    const checkIfYMapIsLoaded = (): void => {
      // `content === undefined` while loading

      const jsonDataContent = yMap.get(flexibleContentJsonDataKey)
      if (jsonDataContent === undefined) return

      const slateDocumentContent = yMap.get(slateDocumentMapKey)
      if (slateDocumentContent === undefined) return

      setJsonDataYMap(asNonNullable(yMap.get(flexibleContentJsonDataKey)))
    }

    checkIfYMapIsLoaded()
    yMap.observe(checkIfYMapIsLoaded)
    return () => yMap.unobserve(checkIfYMapIsLoaded)
  }, [yDoc])

  return { jsonDataYMap }
}

export function useCreatePageYdoc(
  scopedCreateContentId: ScopedCreateContentId
): (AblyYDocWithAwarenessState & CreatePageYdocState) | undefined {
  const ablyState = useAblyYDocWithAwareness(scopedCreateContentId)
  const { jsonDataYMap } = useIsLoaded(ablyState?.yDoc)

  return useMemo(
    () =>
      ablyState && jsonDataYMap ? { ...ablyState, jsonDataYMap, yDocId: scopedCreateContentId } : undefined,
    [jsonDataYMap, ablyState, scopedCreateContentId]
  )
}

export function useLivePageYdoc(
  scopedCreateContentId: ScopedCreateContentId
): (AblyYDocWithAwarenessState & CreatePageYdocState) | undefined {
  const ablyState = useAblyYDocWithFakeAwareness(scopedCreateContentId)
  const { jsonDataYMap } = useIsLoaded(ablyState?.yDoc)

  return useMemo(
    () =>
      ablyState && jsonDataYMap ? { ...ablyState, jsonDataYMap, yDocId: scopedCreateContentId } : undefined,
    [ablyState, jsonDataYMap, scopedCreateContentId]
  )
}
