import { AnimatePresence, motion } from 'framer-motion'
import React from 'react'
import { Control, UseFormWatch } from 'react-hook-form'
import { Link } from 'sierra-client/components/common/link'
import { getFlag } from 'sierra-client/config/global-config'
import { useTranslation } from 'sierra-client/hooks/use-translation'
import { SearchIcons } from 'sierra-client/views/global-assistant/components/search/search-icons'
import { GeneralSettingsFormData } from 'sierra-client/views/manage/settings/types'
import { FormSwitch } from 'sierra-client/views/manage/shared/form'
import { Icon } from 'sierra-ui/components'
import { Spacer, Text, View } from 'sierra-ui/primitives'
import { token } from 'sierra-ui/theming'

import styled from 'styled-components'

const IntegrationIcon = styled.img`
  border-radius: 12px;
  width: 48px;
  height: 48px;
`

const ToggleView = styled(View).attrs({ justifyContent: 'space-between', gap: 'none' })`
  border-top: 1px solid ${token('border/default')};
  gap: 0;
  padding: 16px 18px;
`
const Notice = styled(View).attrs({
  animated: true,
  initial: {
    height: 0,
    opacity: 0,
    marginBottom: 0,
  },
  animate: {
    height: 50, // I would prefer 'auto' here, just not as smooth with framer-motion
    opacity: 1,
    marginBottom: 8,
  },
  exit: {
    height: 0,
    opacity: 0,
    marginBottom: 0,
  },
  transition: {
    duration: 0.1,
    delay: 0.13,
    ease: 'linear',
  },
})`
  background: #ffebb0;
  color: ${token('foreground/primary')};
  border-radius: 12px;
`
const NoticeInner = styled(View).attrs({
  animated: true,
  initial: {
    padding: '0px 16px',
  },
  animate: {
    padding: '16px 16px',
  },
  exit: {
    padding: '0px 16px',
  },
  transition: {
    duration: 0.13,
    ease: 'linear',
  },
})``

const IntegrationWrapper = styled(motion.div)`
  border-radius: 16px;
  box-shadow: 0px 2px 4px 0px #00000014;
  outline: 1px solid ${token('border/default')};
`

const Card = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  gap: 24px;
  padding: 20px 24px;
  border-radius: 16px;
  box-shadow: 0px 2px 4px 0px #00000014;
  outline: 1px solid ${token('border/default')};
`

export const SearchSettings: React.FC<{
  control: Control<GeneralSettingsFormData>
  watchValues: UseFormWatch<GeneralSettingsFormData>
}> = ({ control, watchValues }) => {
  const { t } = useTranslation()

  const driveFeatureEnabled = getFlag('search-integration/google-drive')
  const microsoftFeatureEnabled = getFlag('search-integration/microsoft')
  const driveConnectChecked = watchValues('googleDrive.connect')
  const openAiConnectChecked = watchValues('openAi.connect')

  return (
    <View direction='column' gap='40'>
      <View direction='column' gap='16'>
        <Card>
          <View direction='column' gap='none'>
            <Text bold>{t('manage.settings.search.openai-chat.title')}</Text>
            <Text color='foreground/secondary'>{t('manage.settings.search.openai-chat.body')}</Text>
            <Spacer size='xsmall' />
            <Text color='foreground/muted'>{t('manage.settings.search.openai-chat.disclaimer')}</Text>
          </View>
          <FormSwitch control={control} name='openAi.connect' />
        </Card>

        <Card>
          <View direction='column' gap='none'>
            <Text bold>{t('manage.settings.search.openai-general-knowledge.title')}</Text>
            <Text color='foreground/secondary'>
              {t('manage.settings.search.openai-general-knowledge.body')}
            </Text>
          </View>
          <FormSwitch control={control} name='openAi.assistant' disabled={!openAiConnectChecked} />
        </Card>

        {(driveFeatureEnabled || microsoftFeatureEnabled) && (
          <Card>
            <View direction='column' gap='none'>
              <Text bold>{t('manage.settings.search.new-integrations.title')}</Text>
              <Text color='foreground/secondary'>{t('manage.settings.search.new-integrations.body')}</Text>
            </View>
            <FormSwitch control={control} name='enabledNewIntegrations' />
          </Card>
        )}
      </View>

      {(driveFeatureEnabled || microsoftFeatureEnabled) && (
        <View direction='column' gap='16'>
          <View direction='column' gap='none'>
            <Text bold>{t('manage.settings.search.available-integrations.title')}</Text>
            <Text color='foreground/secondary'>
              {t('manage.settings.search.available-integrations.body')}
            </Text>
          </View>

          {driveFeatureEnabled && (
            <IntegrationWrapper>
              <View alignItems='flex-start' padding='24'>
                <IntegrationIcon src={SearchIcons.drive} />
                <View direction='column' gap='none'>
                  <Text bold>{'Google Drive'}</Text>
                  <Text color='foreground/secondary'>{t('search.google-drive-info')}</Text>
                </View>
              </View>

              <ToggleView direction='column'>
                <AnimatePresence>
                  {driveConnectChecked && (
                    <Notice>
                      <NoticeInner>
                        <Text color='currentColor'>
                          Please confirm that your organization has marked the Sana app as{' '}
                          <Link
                            size='small'
                            href='https://help.sana.ai/en/articles/44252-connect-google-drive-with-sana-search'
                          >
                            trusted
                          </Link>
                          .
                        </Text>
                      </NoticeInner>
                    </Notice>
                  )}
                </AnimatePresence>

                <View justifyContent='space-between'>
                  <View>
                    <Icon color='foreground/secondary' iconId='earth--filled' />
                    <Text bold>{t('manage.settings.search.allow-connect')}</Text>
                  </View>
                  <FormSwitch control={control} name='googleDrive.connect' />
                </View>
              </ToggleView>

              <ToggleView>
                <View>
                  <Icon color='foreground/secondary' iconId='glitter' />
                  <Text bold>{t('manage.settings.search.let-assistant-read')}</Text>
                </View>
                <FormSwitch control={control} name='googleDrive.assistant' />
              </ToggleView>
            </IntegrationWrapper>
          )}

          {microsoftFeatureEnabled && (
            <IntegrationWrapper>
              <View alignItems='flex-start' padding='24'>
                <IntegrationIcon src={SearchIcons.microsoft} />
                <View direction='column' gap='none'>
                  <Text bold>{'Microsoft'}</Text>
                  <Text color='foreground/secondary'>{t('search.microsoft-info')}</Text>
                </View>
              </View>

              <ToggleView>
                <View>
                  <Icon color='foreground/secondary' iconId='earth--filled' />
                  <Text color='foreground/secondary' bold>
                    {t('manage.settings.search.allow-connect')}
                  </Text>
                </View>
                <FormSwitch control={control} name='microsoft.connect' />
              </ToggleView>

              <ToggleView>
                <View>
                  <Icon color='foreground/secondary' iconId='glitter' />
                  <Text bold>{t('manage.settings.search.let-assistant-read')}</Text>
                </View>
                <FormSwitch control={control} name='microsoft.assistant' />
              </ToggleView>
            </IntegrationWrapper>
          )}
        </View>
      )}
    </View>
  )
}
