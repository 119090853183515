import { nanoid12 } from 'sierra-domain/nanoid-extensions'
import { hasOnlyEmptyTextInNodes } from 'sierra-domain/slate-util'
import { TakeawayItem } from 'sierra-domain/v3-author'
import { createParagraph } from 'sierra-domain/v3-author/create-blocks'
import { Editor, Element, Node, Path, Range, Transforms } from 'slate'

const debug = (...messages: unknown[]): void => console.debug('[withTakeaways]', ...messages)

const isTakeawayItemEmpty = (node: TakeawayItem): boolean => {
  return hasOnlyEmptyTextInNodes([node])
}

const validChildrenTypes = ['paragraph']

export const withTakeaways = (editor: Editor): Editor => {
  const { insertBreak, normalizeNode } = editor

  editor.insertBreak = () => {
    const { selection } = editor
    if (selection && Range.isCollapsed(selection)) {
      const [takeawayItem] = Editor.nodes(editor, {
        match: node => Element.isElement(node) && node.type === 'takeaway-item',
      })
      if (takeawayItem !== undefined) {
        const [node, path] = takeawayItem

        if (isTakeawayItemEmpty(node)) {
          //This condition is not done, use convertParagraph(it) and convertList(it)
          // If the item is empty, delete the takeaway item

          const [parentNode, parentPath] = Editor.node(editor, Path.parent(path))

          if (
            Element.isElement(parentNode) &&
            parentNode.type === 'takeaways' &&
            parentNode.children.length === 1
          ) {
            // Remove the parent and its children if there is only one child
            Transforms.removeNodes(editor, { at: parentPath })
          } else {
            // Remove only the node itself
            Transforms.removeNodes(editor, { at: path })
          }
        } else {
          // Add a new one

          Transforms.insertNodes(
            editor,
            {
              type: 'takeaway-item',
              id: nanoid12(),
              children: [
                {
                  type: 'paragraph',
                  placeholder: 'author.slate.takeaway-item-placeholder',
                  id: nanoid12(),
                  children: [{ text: '' }],
                },
              ],
            },
            { at: Path.next(path) }
          )
        }

        return Transforms.move(editor, { distance: 1, unit: 'line' })
      }
    }

    insertBreak()
  }

  editor.normalizeNode = entry => {
    const [node, path] = entry

    if (Element.isElement(node) && node.type === 'takeaways') {
      const children = Array.from(Node.children(editor, path))

      for (const [child, childPath] of children) {
        if (!(Element.isElement(child) && child.type === 'takeaway-item')) {
          const childText = 'text' in child ? child.text : ''
          Transforms.removeNodes(editor, { at: childPath })
          return Transforms.insertNodes(
            editor,
            {
              type: 'takeaway-item',
              id: nanoid12(),
              children: [
                {
                  type: 'paragraph',
                  id: nanoid12(),
                  placeholder: 'author.slate.takeaway-item-placeholder',
                  children: [{ text: childText }],
                },
              ],
            },
            { at: childPath }
          )
        }
      }
    }

    if (Element.isElement(node) && node.type === 'takeaway-item') {
      const [parent] = Editor.node(editor, Path.parent(path))
      if (!(Element.isElement(parent) && parent.type === 'takeaways')) {
        debug(`Unwrapping takeaway-item at ${JSON.stringify(path)}`)
        return Transforms.setNodes(editor, { type: 'paragraph' }, { at: path })
      }

      // Should only have paragraph children
      const children = Array.from(Node.children(editor, path))
      for (const [child, childPath] of children) {
        if (!(Element.isElement(child) && validChildrenTypes.includes(child.type))) {
          debug(`Setting node to paragraph  at: ${JSON.stringify(childPath)}`)
          return Transforms.wrapNodes(editor, createParagraph(), { at: childPath })
        }
      }
    }

    if (!(Element.isElement(node) && node.type === 'takeaways')) return normalizeNode(entry)
    const children = Array.from(Node.children(editor, path))

    for (const [child, childPath] of children) {
      if (Element.isElement(child) && !(child.type === 'takeaway-item')) {
        debug(`Setting node to takeaway-item at: ${JSON.stringify(childPath)}`)
        Transforms.setNodes(editor, { type: 'takeaway-item' }, { at: childPath })
      }
    }

    normalizeNode(entry)
  }
  return editor
}
