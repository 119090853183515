import Lang from 'sierra-client/assets/locales/en/common.json'

type I18NextPlural = 'zero' | 'one' | 'two' | 'few' | 'many' | 'other'
type I18NextKeyWithoutPlural<T extends string> = T extends `${infer A}_${I18NextPlural}` ? A : T

export type TranslationKey = I18NextKeyWithoutPlural<keyof typeof Lang>
export type I18nArgs = [TranslationKey, Record<string, unknown>?]

export type TranslationLookup = (key: TranslationKey, args?: Record<string, unknown> | undefined) => string

export type DynamicT = (
  key: string,
  args?: Record<string, unknown> | undefined,
  defaultValue?: string
) => string

export type TranslationFunctions = {
  t: TranslationLookup
  dynamicT: DynamicT
}

export function isTranslationKey(key: string): boolean {
  return key in Lang
}
