import { useCallback, useEffect, useState } from 'react'
import { usePost } from 'sierra-client/hooks/use-post'
import { UserGroupRow } from 'sierra-domain/api/manage'
import { XRealtimeAdminGroupsListUserGroups } from 'sierra-domain/routes'

type UseUserGroupsData = {
  isLoading: boolean
  userGroupsData: UserGroupRow[]
  fetchUserGroups: () => Promise<void>
}

export const mapUserGroupToCsv = (group: UserGroupRow): Record<string, unknown> => {
  return {
    name: group.name,
    type: {
      'user-api': 'External',
      'user-manual': 'Static',
      'user-scim': 'External',
      'user-filter': 'Smart',
    }[group.type],
    learnersCount: group.numLearners,
    // can add more fields later on...
  }
}

export const useUserGroups: () => UseUserGroupsData = () => {
  const { postWithUserErrorException } = usePost()
  const [userGroupsData, setUserGroupsData] = useState<UserGroupRow[]>([])
  const [isLoading, setIsLoading] = useState(false)

  const fetchUserGroups = useCallback(async (): Promise<void> => {
    setIsLoading(true)
    const response = await postWithUserErrorException(XRealtimeAdminGroupsListUserGroups, {
      groupIds: undefined,
    })
    setUserGroupsData(response.data)
    setIsLoading(false)
  }, [postWithUserErrorException])

  useEffect(() => {
    void fetchUserGroups()
  }, [fetchUserGroups])

  return {
    isLoading,
    userGroupsData: userGroupsData,
    fetchUserGroups: fetchUserGroups,
  }
}
