import { useGetDaysLeft } from 'sierra-client/core/format'
import { NotificationContainer, Title } from 'sierra-client/features/activity-center/notifications/components'
import { EntityImage } from 'sierra-client/features/activity-center/notifications/components/entity-image'
import { NotificationIcon } from 'sierra-client/features/activity-center/notifications/components/notification-icon'
import { NotificationTitle } from 'sierra-client/features/activity-center/notifications/components/notification-title'
import { HomeNotification } from 'sierra-domain/api/learn'
import { Pill, Text, View } from 'sierra-ui/primitives'
import styled from 'styled-components'

const DuePill = styled(Pill)`
  background: tomato;
  color: white;
`

export const ContentDueNotification: React.FC<{
  notification: Extract<HomeNotification, { type: 'content-due' }>
}> = ({ notification }) => {
  const entity = notification.content.entity
  const { daysLeft } = useGetDaysLeft(notification.dueDate)
  return (
    <NotificationContainer
      notification={notification}
      icon={<NotificationIcon variant='warning' iconId='book' />}
      title={<NotificationTitle untranslated='Content is due' />}
      content={
        <View>
          <EntityImage entity={entity} />

          <View direction='column' gap='none'>
            <Title size='regular' bold>
              {entity.title}
            </Title>

            <View>
              <DuePill variant='error'>
                <Text size='micro' color='currentColor' bold>
                  {daysLeft}
                </Text>
              </DuePill>
            </View>
          </View>
        </View>
      }
    />
  )
}
