import { useAtomValue } from 'jotai'
import { intercomVisibilityAtom } from 'sierra-client/intercom/intercom-visibility'
import styled from 'styled-components'

const CustomHelpDeskButton = styled.button`
  /** Intercom's widget styling */
  position: fixed;
  z-index: 2147483004;
  padding: 0 !important;
  margin: 0 !important;
  border: none;
  bottom: 20px;
  right: 20px;
  max-width: 48px;
  width: 48px;
  max-height: 48px;
  height: 48px;
  border-radius: 50%;
  background: #333333;
  cursor: pointer;
  box-shadow:
    0 1px 6px 0 rgba(0, 0, 0, 0.06),
    0 2px 32px 0 rgba(0, 0, 0, 0.16);
  transition: transform 167ms cubic-bezier(0.33, 0, 0, 1);
  box-sizing: content-box;
  pointer-events: auto;

  overflow: hidden;
`

const CustomHelpDeskIcon = styled.img`
  width: 100%;
  height: 100%;
`

export type CustomHelpDeskProps = {
  url: string
  image: string
}

/**
 * Custom help desk widget used by some orgs, which overrides the Intercom widget and opens a popup/link
 * to the organization's own help desk service. Should be displayed in the same places that Intercom
 * normally is displayed.
 */
export const CustomHelpDesk = ({ url, image }: CustomHelpDeskProps): JSX.Element | null => {
  const isHelpDeskVisible = useAtomValue(intercomVisibilityAtom)

  if (isHelpDeskVisible !== 'visible') {
    return null
  }

  return (
    <CustomHelpDeskButton
      onClick={() => {
        const popupHeight = 360
        const popupWidth = 320
        const rightX = window.screenX + window.innerWidth - popupWidth
        const bottomY = window.screenY + window.innerHeight - popupHeight
        const handle = window.open(
          url,
          'helpDeskWindow',
          `popup,top=${bottomY},left=${rightX},width=${popupWidth},height=${popupHeight}`
        )

        if (handle === null) {
          // Failed to open popup, e.g. because of popup blockers.
          window.open(url, 'helpDeskTab')
        }
      }}
    >
      <CustomHelpDeskIcon src={image} />
    </CustomHelpDeskButton>
  )
}
