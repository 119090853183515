import React, { ReactNode, useRef } from 'react'
import { RouterLink } from 'sierra-client/components/common/link'
import { palette } from 'sierra-ui/theming'
import { animations, defaultEasing } from 'sierra-ui/theming/animations'
import { fonts } from 'sierra-ui/theming/fonts'
import { fadeIn } from 'sierra-ui/theming/keyframes'
import styled, { css } from 'styled-components'

export const TabBar = styled.div`
  position: relative;
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  align-items: stretch;
  width: 100%;
  border-bottom: 1px solid ${p => p.theme.color.grey10};
`

const TabLink = styled.a<{ small?: boolean; isActive: boolean }>`
  ${p => (p.small === true ? fonts.body.small : fonts.body.small)}
  position: relative;
  color: ${p => p.theme.text.default};
  padding: 1.25rem 1rem;
  cursor: pointer;
  ${defaultEasing}

  &::before {
    content: '';
    position: absolute;
    top: ${p => (p.small === true ? '0.875rem' : '1rem')};
    left: 0;
    width: 100%;
    height: ${p => (p.small === true ? 'calc(100% - 1.75rem)' : 'calc(100% - 2rem)')};
    border-radius: 3rem;
    background-color: ${palette.grey[5]};
    opacity: 0;
    z-index: -1;
    transition: opacity 0.2s ease;
  }

  &::after {
    content: '';
    position: absolute;
    left: 1rem;
    bottom: -1px;
    width: calc(100% - 2rem);
    height: 1px;
    background-color: transparent;
    transition: background-color 0.2s ease;
    z-index: 2;
  }

  &:first-child {
    padding-left: 0;

    &::after {
      left: 0;
      width: calc(100% - 1rem);
    }

    &::before {
      left: -1rem;
      width: calc(100% + 1rem);
    }
  }

  ${p =>
    p.isActive
      ? css`
          color: ${p.theme.home.tabColor};

          &::after {
            background-color: ${p.theme.home.tabColor};
          }
        `
      : css`
          &:hover {
            &::before {
              opacity: 1;
            }
          }
        `}
`

type TabItemProps = {
  children: string
  isActive: boolean
  small?: boolean
} & (
  | {
      onClick: () => void
      href?: never
    }
  | {
      onClick?: never
      href?: string
    }
)

const TabItem = React.forwardRef<HTMLAnchorElement, TabItemProps>(
  ({ children, onClick, isActive, small, href }, ref) => (
    <TabLink ref={ref} onClick={onClick} isActive={isActive} small={small} href={href}>
      {children}
    </TabLink>
  )
)

export const TabContent = styled.div`
  width: 100%;
  height: auto;
`

type TabConfig = {
  id: string | number
  title: string
  content?: ReactNode
}

export const TabContentInner = styled.div<{ hidden?: boolean }>`
  display: ${p => (p.hidden === true ? 'none' : 'block')};

  ${animations.tabs.disabled
    ? css`
        opacity: 1;
      `
    : css`
        animation: ease ${fadeIn} ${animations.tabs.in}s forwards;
      `};
`

type Props = {
  current: string | number
  small?: boolean
  className?: string
  style?: Record<string, unknown>
  RightActions?: () => JSX.Element | null
  keepMounted?: boolean
  hideHeader?: boolean
  tabs: TabConfig[]
} & (
  | {
      onClick: (tabId: any) => void
      getTabHref?: never
    }
  | {
      onClick?: never
      getTabHref: (tabId: any) => string
    }
)

/**
 * @deprecated this component is not accessible. Use import { Tabs } from 'sierra-ui/components'
 */
export const Tabs = ({
  onClick,
  getTabHref,
  current,
  small,
  RightActions,
  className,
  keepMounted,
  hideHeader,
  tabs,
  ...props
}: Props): React.ReactElement<Props> => {
  const currentItem = useRef<null | HTMLAnchorElement>(null)

  return (
    <>
      {hideHeader !== true && (
        <TabBar className={className} {...props}>
          {tabs.map(({ id, title }) => {
            const isCurrent = id === current

            if (onClick !== undefined) {
              return (
                <TabItem
                  key={id}
                  isActive={isCurrent}
                  small={small}
                  ref={isCurrent ? currentItem : null}
                  onClick={() => onClick(id)}
                >
                  {title}
                </TabItem>
              )
            } else {
              return (
                <RouterLink key={id} href={getTabHref(id)}>
                  <TabItem isActive={isCurrent} small={small} ref={isCurrent ? currentItem : null}>
                    {title}
                  </TabItem>
                </RouterLink>
              )
            }
          })}
          {RightActions && <RightActions />}
        </TabBar>
      )}
      <TabContent>
        {tabs.map(({ id, content }) => {
          // Only render the currently selected tab content
          if (keepMounted !== true) {
            return id === current ? <TabContentInner key={id}>{content}</TabContentInner> : null
          }

          // Else, render all tab contents but hide non-current tabs
          return (
            <TabContentInner key={id} hidden={id !== current}>
              {content}
            </TabContentInner>
          )
        })}
      </TabContent>
    </>
  )
}
