import React from 'react'
import { useLiveSessionContext } from 'sierra-client/components/liveV2/contexts/live-session-data'
import { useTranslation } from 'sierra-client/hooks/use-translation'
import { getOrInferVideoCallProviderFromVideoCallSetting } from 'sierra-client/lib/sana-now/get-video-call-provider-from-video-call-setting'
import { iife } from 'sierra-domain/utils'
import { Heading, View } from 'sierra-ui/primitives'

export const NoVideoCallView: React.FC<{ card: 'lobby' | 'breakout-room' }> = ({ card }) => {
  const { t } = useTranslation()
  const videoCallSetting = useLiveSessionContext().data.videoCallSetting
  const inferredProvider = getOrInferVideoCallProviderFromVideoCallSetting(videoCallSetting)

  if (videoCallSetting.type === 'url') {
    return (
      <View justifyContent='center' alignItems='center' direction='column'>
        <Heading size='h4' bold>
          {iife(() => {
            switch (card) {
              case 'lobby':
                return t('sana-now.video-cards.lobby-title')
              case 'breakout-room':
                return t('sana-now.video-cards.breakout-room-title')
            }
          })}
        </Heading>
        <Heading size='h4' color='foreground/muted' bold>
          {iife(() => {
            switch (inferredProvider) {
              case 'google-meet':
                return t('sana-now.video-cards.join-meet')
              case 'microsoft-teams':
                return t('sana-now.video-cards.join-teams')
              case 'zoom':
                return t('sana-now.video-cards.join-zoom')
              default:
                return t('sana-now.video-cards.join-other')
            }
          })}
        </Heading>
      </View>
    )
  }

  return (
    <View justifyContent='center' alignItems='center' direction='column'>
      <Heading size='h4' bold>
        {iife(() => {
          switch (card) {
            case 'lobby':
              return t('sana-now.video-cards.lobby-title')
            case 'breakout-room':
              return t('sana-now.video-cards.breakout-room-title')
          }
        })}
      </Heading>
    </View>
  )
}
