import _ from 'lodash'
import { unwrapToTextNodes } from 'sierra-client/views/v3-author/command'
import { isElementType, parentType } from 'sierra-client/views/v3-author/queries'
import { createParagraph, createStickyNotesCardSection } from 'sierra-domain/v3-author/create-blocks'
import { Editor, Node, Text, Transforms } from 'slate'

export const withStickyNotesCardSection = (editor: Editor): Editor => {
  const { normalizeNode } = editor
  const debug = (...messages: unknown[]): void =>
    console.debug(`[${withStickyNotesCardSection.name}]`, ...messages)

  editor.normalizeNode = entry => {
    const [node, path] = entry
    if (!isElementType('sticky-notes-card-section', node)) return normalizeNode(entry)

    // Must be the direct child of a 'sticky-notes-card'
    if (parentType(editor, path) !== 'sticky-notes-card') {
      debug('invalid parent, converting to paragraph at', JSON.stringify(path))
      unwrapToTextNodes(editor, { at: path })
      return Transforms.wrapNodes(editor, createParagraph(), { at: path })
    }

    // Can only contain text nodes as children
    for (const [child, childPath] of Node.children(editor, path)) {
      if (Text.isText(child)) continue

      debug('Expected text node. Unwrapping at:', JSON.stringify(childPath))
      return unwrapToTextNodes(editor, { at: childPath })
    }

    normalizeNode(entry)
  }

  return editor
}

export const withStickyNotesCard = (editor: Editor): Editor => {
  const { normalizeNode } = editor
  const debug = (...messages: unknown[]): void => console.debug(`[${withStickyNotesCard.name}]`, ...messages)

  editor.normalizeNode = entry => {
    const [node, path] = entry
    if (!isElementType('sticky-notes-card', node)) return normalizeNode(entry)

    // Must be the direct child of the editor
    if (parentType(editor, path) !== 'editor') {
      debug('invalid parent, unwrapping at', JSON.stringify(path))
      return Transforms.unwrapNodes(editor, { at: path })
    }

    const children = Array.from(Node.children(editor, path))
    // Must start with a paragraph
    if (children.length === 0) {
      const at = path.concat(0)
      debug('Inserting missing paragraph at', JSON.stringify(at))
      return Transforms.insertNodes(editor, createParagraph(), { at })
    }

    // Must contain at least one sticky-notes-card-section after the first paragraph
    if (children.length === 1) {
      const at = path.concat(1)
      debug('Inserting missing sticky-notes-card-section at', JSON.stringify(at))
      return Transforms.insertNodes(editor, createStickyNotesCardSection(), { at })
    }

    for (const [child, childPath] of children) {
      const index = _.last(childPath) ?? 0
      // Must start with a paragraph
      if (index === 0 && !isElementType('paragraph', child)) {
        debug('Converting to paragraph at', JSON.stringify(childPath))
        unwrapToTextNodes(editor, { at: childPath })
        return Transforms.wrapNodes(editor, createParagraph(), { at: childPath })
      }

      // Must only contain sticky-notes-card-sections after the first paragraph
      if (index > 0 && !isElementType('sticky-notes-card-section', child)) {
        debug('Converting to sticky-notes-card-section at', JSON.stringify(childPath))
        unwrapToTextNodes(editor, { at: childPath })
        return Transforms.wrapNodes(editor, createStickyNotesCardSection(), { at: childPath })
      }
    }

    normalizeNode(entry)
  }

  return editor
}
