import { graphql } from 'sierra-client/api/graphql/gql'
import { AttendanceStatus, SetCalendarAttendanceInput } from 'sierra-client/api/graphql/gql/graphql'
import { graphQuery } from 'sierra-client/api/hooks/use-graphql-query'

export const useSetAttendanceStatus = (): ((
  input: SetCalendarAttendanceInput
) => Promise<AttendanceStatus>) => {
  const useSetAttendanceStatus = async (input: SetCalendarAttendanceInput): Promise<AttendanceStatus> => {
    const result = await graphQuery(
      graphql(`
        mutation QuickAttendUser($input: SetCalendarAttendanceInput!) {
          setCalendarEventAttendance(input: $input) {
            attended
          }
        }
      `),
      { input }
    )
    return result.setCalendarEventAttendance.attended
  }

  return useSetAttendanceStatus
}
