import { Logger } from 'sierra-client/core/logging/logger'

const sources = [
  'google-drive',
  'sana',
  'google-search',
  'recent-searches',
  'microsoft:teams',
  'microsoft:sharepoint',
  'microsoft',
  'helpjuice',
] as const
type Source = (typeof sources)[number]

export const universalSearchModalOpened = Logger.trackLoggerWithExtra<undefined, Record<string, unknown>>(
  'universal_search_modal_opened',
  () => ({})
)

export const universalSearchQueryTriggered = Logger.trackLoggerWithExtra<
  { query: string },
  { query: string }
>('universal_search_query_triggered', ({ query }) => ({ query }))

export const universalSearchResultClicked = Logger.trackLogger<{
  query: string
  type: Source
  index: number
}>('universal_search_result_clicked', true)

export const universalSearchRepliedToAssistant = Logger.trackLogger<{
  query: string
  assistantResponse: string
  history: { source: 'assistant' | 'user'; text: string }[]
  reply?: string
}>('universal_search_replied_to_assistant', true)

export const universalSearchIntegrationAdded = Logger.trackLogger<{
  type: Source
}>('universal_search_integration_added', true)
