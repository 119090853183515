import { useTranslation } from 'sierra-client/hooks/use-translation'
import { FCC } from 'sierra-client/types'
import { Icon, IconProps, Popover } from 'sierra-ui/components'
import { Button, View } from 'sierra-ui/primitives'
import { palette } from 'sierra-ui/theming'
import styled from 'styled-components'

const Badge = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  width: 1.125rem;
  height: 1rem;
  border-radius: 50%;
  background-color: ${p => p.theme.color.blueBright};
  color: ${palette.primitives.white};
  font-size: 0.625rem;
  line-height: 0.875rem;
`

export const FilterPopover: FCC<{
  activeCount?: number
  filterIconProps?: IconProps
}> = ({ activeCount, filterIconProps, children }) => {
  const { t } = useTranslation()
  const isActive = (activeCount ?? 0) > 0

  return (
    <Popover
      align='end'
      renderTrigger={() => (
        <View gap='none'>
          {isActive ? (
            <Badge>{activeCount}</Badge>
          ) : (
            <Icon iconId='filter' size='size-16' color='grey40' {...filterIconProps} />
          )}

          <Button variant='transparent'>{t('dictionary.filter')}</Button>
        </View>
      )}
    >
      {children}
    </Popover>
  )
}
