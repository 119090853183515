import { compact } from 'lodash'
import { useState } from 'react'
import { ChipInputWithAutocomplete } from 'sierra-client/components/common/chip-input-with-autocomplete'
import { useNotif } from 'sierra-client/components/common/notifications'
import {
  AutocompleteContainer,
  AutocompleteDropdownContainer,
  BottomContainer,
  TabRootContainer,
} from 'sierra-client/components/sharing/tabs/components/containers'
import { CoursePermissionSection } from 'sierra-client/components/sharing/tabs/components/course-permission-section'
import {
  SharingModalMenuItem,
  SharingModalMenuItemWithCollaborateControls,
  SharingModalUser,
} from 'sierra-client/components/sharing/tabs/components/sharing-modal-menu-item'
import { TabTop } from 'sierra-client/components/sharing/tabs/components/tab-top'
import { RenderTags } from 'sierra-client/components/sharing/tabs/utils/render-tags'
import { SharingModalContent } from 'sierra-client/components/sharing/types'
import { config } from 'sierra-client/config/global-config'
import { useContentKindPermissions } from 'sierra-client/hooks/use-permissions'
import { useTranslation } from 'sierra-client/hooks/use-translation'
import { removeSearchParams } from 'sierra-client/router/search-params'
import * as settingsActions from 'sierra-client/state/author-course-settings/actions'
import * as settingsState from 'sierra-client/state/author-course-settings/slice'
import { Collaborator } from 'sierra-client/state/author-course-settings/types'
import { useDispatch, useSelector } from 'sierra-client/state/hooks'
import { selectUserId } from 'sierra-client/state/user/user-selector'
import { CourseRole } from 'sierra-domain/api/manage'
import { CourseId } from 'sierra-domain/api/nano-id'
import { LightUser } from 'sierra-domain/user'
import { isDefined } from 'sierra-domain/utils'
import { MenuItem } from 'sierra-ui/components'
import { Button, View } from 'sierra-ui/primitives'
import { SingleSelectDropdown } from 'sierra-ui/primitives/menu-dropdown'
import { token } from 'sierra-ui/theming'
import styled from 'styled-components'

const collaboratorSortValues: Record<CourseRole, number> = {
  owner: 3,
  editor: 2,
  commenter: 1,
}

const sortCollaboratorsFn = (a: Collaborator, b: Collaborator): number =>
  isDefined(a.role) && isDefined(b.role) ? collaboratorSortValues[b.role] - collaboratorSortValues[a.role] : 0

const ExistingCollaboratorsContainer = styled.div`
  margin-top: 8px;
  max-height: 260px;
  overflow-x: visible;
  overflow-y: hidden;

  /* Workaroud To align the sidebar with the share modal edge. */
  padding-right: 24px;
  margin-right: -24px;

  scrollbar-gutter: stable;

  &:hover {
    overflow-y: auto;
  }
`

const Container = styled(View)`
  max-width: 100%;
`

type CollaborateTabProps = {
  onClose: () => void
  content: SharingModalContent
  requestAccessUser?: LightUser
}

const PrimarySpan = styled.span`
  color: ${token('foreground/primary')};
`

type SharingModalUserWithoutType = Omit<SharingModalUser, 'type'>
export const CollaborateTab: React.FC<CollaborateTabProps> = ({ content, onClose, requestAccessUser }) => {
  const { t } = useTranslation()
  const dispatch = useDispatch()
  const notifications = useNotif()

  const [selectedUsers, setSelectedUsers] = useState<SharingModalUserWithoutType[]>(() =>
    requestAccessUser !== undefined ? [requestAccessUser] : []
  )
  const [collaboratorRole, setCollaboratorRole] = useState<CourseRole>('editor')
  const [filter, setFilter] = useState('')

  const availableCollaborators = useSelector(settingsState.selectors.selectAvailableCollaborators)
  const courseCollaborators = useSelector(settingsState.selectors.selectCollaborators)

  const userId = useSelector(selectUserId)

  const copyLink = new URL(window.location.href)
  const host = config.auth.host
  if (host !== undefined) copyLink.searchParams.set('x-host', host)

  const handleInviteCollaborators = (): void => {
    if (selectedUsers.length === 0) return

    void dispatch(
      settingsActions.updateCollaborators({
        changedCollaborators: selectedUsers.map(u => ({ ...u, role: collaboratorRole })),
      })
    )

    setFilter('')
    setSelectedUsers([])

    notifications.push({
      type: 'custom',
      level: 'info',
      icon: 'send--filled',
      body: 'Invite sent',
    })

    removeSearchParams(['request-access'])
  }

  const permissions = useContentKindPermissions(content.id)

  const courseRoleItems: MenuItem<CourseRole>[] = [
    { type: 'label', label: t('author.can-edit'), id: 'editor' },
    {
      type: 'label',
      label: t('share.label.can-comment'),
      id: 'commenter',
    },
  ]

  return (
    <TabRootContainer>
      <TabTop title={t('author.share-title', { title: content.title })} onClose={onClose} />
      <BottomContainer>
        <Container justifyContent='space-between' direction='column' gap={'32'}>
          <View direction='column'>
            {permissions.has('EDIT_COLLABORATORS') && (
              <View justifyContent='space-between'>
                <AutocompleteContainer>
                  <ChipInputWithAutocomplete<SharingModalUserWithoutType, true, true, false>
                    ListboxProps={{ style: { pointerEvents: 'auto' } }}
                    inputPlaceholder={selectedUsers.length === 0 ? t('admin.add-users') : ''}
                    inputValue={filter}
                    onInputChange={(_, filter) => setFilter(filter)}
                    value={selectedUsers}
                    onChange={(_, users) => setSelectedUsers(users)}
                    noOptionsText={
                      <PrimarySpan>
                        {availableCollaborators === 'loading'
                          ? t('dictionary.loading')
                          : t('sharing-modal.no-users-found')}
                      </PrimarySpan>
                    }
                    autoFocus
                    multiple
                    disableClearable
                    options={availableCollaborators !== 'loading' ? availableCollaborators : []}
                    getOptionLabel={user => compact([user.firstName, user.lastName, user.email]).join(' ')}
                    renderOption={user => (
                      <SharingModalMenuItem
                        key={user.uuid}
                        user={user}
                        selected={selectedUsers.some(u => u.uuid === user.uuid)}
                      />
                    )}
                    renderTags={users => (
                      <RenderTags selectedUsers={users} setSelectedUsers={setSelectedUsers} uuidKey='uuid' />
                    )}
                  />
                  <AutocompleteDropdownContainer>
                    <SingleSelectDropdown<CourseRole>
                      variant='ghost'
                      selectedItem={courseRoleItems.find(it => it.id === collaboratorRole)}
                      menuItems={courseRoleItems}
                      onSelect={item => setCollaboratorRole(item.id)}
                    />
                  </AutocompleteDropdownContainer>
                </AutocompleteContainer>

                <Button disabled={selectedUsers.length === 0} onClick={handleInviteCollaborators}>
                  {t('dictionary.invite')}
                </Button>
              </View>
            )}

            <ExistingCollaboratorsContainer>
              {[...courseCollaborators].sort(sortCollaboratorsFn).map(u => (
                <SharingModalMenuItemWithCollaborateControls
                  key={u.uuid}
                  user={u}
                  contentId={CourseId.parse(content.id)}
                  showTransferOwnershipAction={permissions.has('TRANSFER_OWNERSHIP')}
                  readOnly={!permissions.has('EDIT_COLLABORATORS')}
                  onCollaboratorRoleChange={async collaborator => {
                    await dispatch(
                      settingsActions.updateCollaborators({
                        changedCollaborators: [collaborator],
                      })
                    )

                    if (collaborator.uuid === userId) {
                      void permissions.refetch()
                    }
                  }}
                />
              ))}
            </ExistingCollaboratorsContainer>
          </View>

          <CoursePermissionSection />
        </Container>
      </BottomContainer>
    </TabRootContainer>
  )
}
