import React, { useState } from 'react'
import { usePost } from 'sierra-client/hooks/use-post'
import { useTranslation } from 'sierra-client/hooks/use-translation'
import { getUserErrorTranslationKey } from 'sierra-client/utils/translation-utils'
import { AuthenticationContent } from 'sierra-client/views/authentication/native/components/authentication-content'
import { isLeft } from 'sierra-domain/either'
import { XRealtimeUserAssignFromGroupInvite } from 'sierra-domain/routes'
import { Button, Spacer, Text } from 'sierra-ui/primitives'

type Props = {
  token: string
  onAssign: () => void
  name: string
}

export const AssignUser: React.FC<Props> = ({ token, onAssign, name }) => {
  const { t } = useTranslation()
  const { postWithUserErrorCode } = usePost()
  const [error, setError] = useState<string | undefined>()

  const submit = async (): Promise<void> => {
    const result = await postWithUserErrorCode(XRealtimeUserAssignFromGroupInvite, {
      token,
    })

    if (isLeft(result)) {
      setError(t(getUserErrorTranslationKey(result.left)))
      return
    } else {
      setError(undefined)
    }

    onAssign()
  }

  return (
    <AuthenticationContent>
      <Text color='foreground/primary' size='small'>
        {t('group-invite.proceed-to-sign-up', { name })}
      </Text>
      <Button variant='primary' id={'assign-user-button'} onClick={submit} grow>
        {t('create-account.continue')}
      </Button>
      {error !== undefined && (
        <>
          <Spacer />
          <Text size='small' color='redBright' spacing='xsmall'>
            {error}
          </Text>
        </>
      )}
    </AuthenticationContent>
  )
}
