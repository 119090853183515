import { motion } from 'framer-motion'
import { capitalize } from 'lodash'
import React, { useCallback, useMemo } from 'react'
import { tiptapContentToString } from 'sierra-client/components/chat/tiptap'
import {
  ParticipantVideo,
  useLocalParticipantVideo,
  useParticipantVideo,
} from 'sierra-client/components/liveV2/hooks/use-participant-video'
import { AudioIndicatorWrapper } from 'sierra-client/components/liveV2/live-layer/audio-indicator-wrapper'
import { RemoteTracksStats } from 'sierra-client/components/liveV2/live-layer/call-stats/remote-tracks-stats'
import { MediaPlayer } from 'sierra-client/components/liveV2/live-layer/mediaplayer'
import { useLiveSessionIdContext } from 'sierra-client/components/liveV2/live-session-id-provider'
import { useRightSidebarContext } from 'sierra-client/components/liveV2/live-sidebar-provider'
import { PrioriotyLevel } from 'sierra-client/components/liveV2/participant-provider'
import { RaisingHand } from 'sierra-client/components/liveV2/raising-hand'
import { useShowBadNetwork } from 'sierra-client/components/liveV2/services/video-call-service/hooks/use-show-bad-network'
import { VideoQuality } from 'sierra-client/components/liveV2/services/video-call-service/types'
import { useTranslation } from 'sierra-client/hooks/use-translation'
import { useLastMessageByUser } from 'sierra-client/state/chat/hooks'
import { useSelector } from 'sierra-client/state/hooks'
import {
  selectIsParticipantPinned,
  selectUserIsFacilitator,
} from 'sierra-client/state/live-session/selectors'
import {
  LiveParticipant as ParticipantType,
  LiveScreenSharingParticipant as ScreenShareParticipantType,
} from 'sierra-client/state/live-session/types'
import { selectClientId, selectVideoState } from 'sierra-client/state/live/selectors'
import { selectUser } from 'sierra-client/state/user/user-selector'
import { useUser, useUserLegacy } from 'sierra-client/state/users/hooks'
import { getAvatarImage } from 'sierra-client/utils/avatar-img'
import { UserId } from 'sierra-domain/api/uuid'
import { Message } from 'sierra-domain/chat'
import { ScopedChatId } from 'sierra-domain/collaboration/types'
import { RoundAvatar } from 'sierra-ui/components'
import { ChatOverflowDirection, ParticipantOverlay } from 'sierra-ui/missions/live'
import { StringifiedChatMessage } from 'sierra-ui/missions/live/participant-overlay'
import { palette } from 'sierra-ui/theming'
import styled from 'styled-components'

const BORDER_RADIUS = '8px'

const Container = styled(motion.div)`
  position: relative;
  width: 100%;
  height: 100%;
  will-change: opacity, transform;
`

const ParticipantContainer = styled.div<{ showBackground: boolean; isScreenShare: boolean }>`
  background-color: ${p => (p.showBackground ? palette.primitives.black : undefined)};
  border-radius: ${p => (p.isScreenShare ? undefined : BORDER_RADIUS)};
  position: relative;
  width: 100%;
  height: 100%;

  /**
          Safari hack for border radius with overflow hidden
          This will tell the browser this element needs to create a new stacking context
          https://stackoverflow.com/questions/49066011/overflow-hidden-with-border-radius-not-working-on-safari 
          */
  isolation: isolate;
`

const StyledAvatar = styled(RoundAvatar)`
  position: absolute;
  left: 50%;
  top: 50%;
  transform: translate(-50%, -50%);
`

const StyledRaisingHand = styled(RaisingHand)`
  position: absolute;
  left: 8px;
  top: 8px;
`

interface ParticipantInnerProps {
  uuid?: UserId
  isScreenShare?: boolean
  video?: ParticipantVideo
  agoraUID?: string
  chatOverflowDirection?: ChatOverflowDirection
  hideName?: boolean
}

type ParticipantProps = {
  participant: ParticipantType
  isFacilitator?: boolean
  priorityLevel?: PrioriotyLevel
  chatOverflowDirection?: ChatOverflowDirection
  hideName?: boolean
} & (
  | {
      isScreenShare: true
      participant: ScreenShareParticipantType
      quality?: never
    }
  | {
      isScreenShare?: false | undefined
      quality?: VideoQuality
    }
)

function stringifyMessage(message: Message | undefined): StringifiedChatMessage | undefined {
  switch (message?.type) {
    case undefined:
      return undefined
    case 'emoji':
      return message
    case 'tiptap-plain':
    case 'tiptap-comment': {
      const { tiptapJsonData, ...rest } = message
      return {
        ...rest,
        stringifiedMessage: tiptapContentToString(tiptapJsonData),
      }
    }
    default: {
      const exhaustive: never = message
      return exhaustive
    }
  }
}

export const ParticipantOverlayWrapper: React.FC<{
  uuid?: UserId
  isScreenShare?: boolean
  agoraUID: string | undefined
  chatOverflowDirection?: ChatOverflowDirection
  hideName?: boolean
}> = ({ uuid, isScreenShare = false, agoraUID, chatOverflowDirection, hideName }) => {
  const { t } = useTranslation()
  const user = useUserLegacy(uuid)
  const me = useSelector(selectUser)
  const userIsCurrentUser = me?.uuid === uuid
  const isPinned = useSelector(selectIsParticipantPinned)(uuid)
  const isHavingNetworkProblems = useShowBadNetwork(agoraUID)
  const isFacilitator = useSelector(selectUserIsFacilitator)(uuid)
  const { liveSessionId } = useLiveSessionIdContext()
  const chatId = ScopedChatId.fromId(liveSessionId)
  const { setState } = useRightSidebarContext()
  const openChat = useCallback(() => setState('chat_open'), [setState])

  const message = useLastMessageByUser({ chatId, userId: uuid })
  const stringifiedMessage = stringifyMessage(message)

  const audioIndicator = useMemo(
    () => <AudioIndicatorWrapper iconColor='white' agoraUID={agoraUID} />,
    [agoraUID]
  )

  // TODO: show big emojis
  return (
    <ParticipantOverlay
      name={userIsCurrentUser ? capitalize(t('dictionary.you')) : user?.firstName ?? 'loading'}
      userColor={user?.avatarColor ?? 'blueVivid'}
      isCurrentUser={userIsCurrentUser}
      isPinned={isPinned}
      isHavingNetworkProblems={isHavingNetworkProblems}
      isFacilitator={isFacilitator}
      isScreenShare={isScreenShare}
      hideName={hideName}
      audioIndicator={audioIndicator}
      message={stringifiedMessage}
      chatOverflowDirection={chatOverflowDirection}
      onClickMessage={openChat}
    />
  )
}

const ParticipantInner: React.FC<ParticipantInnerProps> = ({
  uuid,
  video,
  isScreenShare = false,
  agoraUID,
  chatOverflowDirection = 'none',
  hideName,
}) => {
  const userResult = useUser(uuid)
  const user = userResult?.status === 'loaded' ? userResult : undefined

  return (
    <Container>
      <ParticipantContainer showBackground={!isScreenShare} isScreenShare={isScreenShare}>
        <RemoteTracksStats agoraUID={agoraUID} />
        {video === undefined || video === 'in-use' ? (
          <StyledAvatar
            size='small'
            firstName={user?.firstName}
            lastName={user?.lastName}
            src={getAvatarImage(user?.uuid, user?.avatar)}
            color={user?.avatarColor}
          />
        ) : (
          <MediaPlayer video={video} />
        )}
        <ParticipantOverlayWrapper
          uuid={uuid}
          isScreenShare={isScreenShare}
          agoraUID={agoraUID}
          chatOverflowDirection={chatOverflowDirection}
          hideName={hideName}
        />
      </ParticipantContainer>
      {user !== undefined && uuid !== undefined && isScreenShare === false && (
        <StyledRaisingHand userId={uuid} />
      )}
    </Container>
  )
}

const ParticipantInnerMemo = React.memo(ParticipantInner)

export const Participant: React.FC<ParticipantProps> = ({
  participant,
  isScreenShare,
  priorityLevel = PrioriotyLevel.LOW,
  quality = 'auto',
  chatOverflowDirection = 'none',
}) => {
  const agoraUID = isScreenShare === true ? participant.agoraScreenShareUID : participant.agoraUID
  const participantVideo = useParticipantVideo(agoraUID, priorityLevel, quality)

  return (
    <ParticipantInnerMemo
      uuid={participant.userId}
      video={participantVideo}
      isScreenShare={isScreenShare}
      agoraUID={agoraUID}
      chatOverflowDirection={chatOverflowDirection}
    />
  )
}

export const LocalParticipant: React.FC<{ hideName?: boolean }> = ({ hideName }) => {
  const user = useSelector(selectUser)
  const videoState = useSelector(selectVideoState)
  const video = useLocalParticipantVideo()
  const agoraUID = useSelector(selectClientId)

  if (user === undefined) return null

  return (
    <ParticipantInnerMemo
      hideName={hideName}
      agoraUID={agoraUID}
      uuid={user.uuid}
      video={videoState !== 'on' ? undefined : video}
    />
  )
}
