import React from 'react'
import { Identity } from 'sierra-client/components/common/identities-selector'
import { IdentitiesSingleSelector } from 'sierra-client/components/common/identities-selector/identities-selector'
import { useAdminIdentitiesFetcher } from 'sierra-client/components/common/identities-selector/identity-fetchers'

export const GroupSelectorSingle: React.FC<{
  onSelect: (id: Identity) => void
  onUnselect: (id: Identity) => void
  selected?: Identity
}> = ({ selected, onSelect, onUnselect }) => {
  const fetchIdentities = useAdminIdentitiesFetcher({ withTypes: ['user-group'] })
  return (
    <IdentitiesSingleSelector
      value={selected}
      onUnselect={onUnselect}
      onSelect={onSelect}
      fetchIdentities={fetchIdentities}
    />
  )
}
