import styled from 'styled-components'

export const CenteredLoadingContainer = styled.div`
  position: absolute;
  display: flex;
  align-items: center;
  justify-content: center;
  inset: 0;

  background: rgba(255, 255, 255, 0.8);
  border-radius: 6px;

  animation: fadeIn 0.3s forwards 0.3s;

  opacity: 0;
  @keyframes fadeIn {
    0% {
      opacity: 0;
    }
    100% {
      opacity: 1;
    }
  }
`
