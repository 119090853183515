import { UseQueryResult } from '@tanstack/react-query'
import React from 'react'
import { prefetchCachedQuery, useCachedQuery } from 'sierra-client/state/api'
import { ManageContentTabular } from 'sierra-client/views/manage/content/manage-content-tabular'
import { WhoopsPage } from 'sierra-client/views/whoops-page'
import { ZodRouteInput } from 'sierra-domain/api/types'
import { FilterContentDomainResponse } from 'sierra-domain/filter/request'
import { XRealtimeContentFilterContentDomain } from 'sierra-domain/routes'
import { LoadingSpinner, View } from 'sierra-ui/primitives'

const contentFilterInput = { key: 'content.filter' } as const satisfies ZodRouteInput<
  typeof XRealtimeContentFilterContentDomain
>

export const prefetchFilterContentDomain = (): Promise<void> => {
  return prefetchCachedQuery(XRealtimeContentFilterContentDomain, contentFilterInput)
}

const useFilterContentDomain = (): UseQueryResult<FilterContentDomainResponse> => {
  const queryResult = useCachedQuery(XRealtimeContentFilterContentDomain, contentFilterInput)
  return queryResult
}

export const ManageContent: React.FC<unknown> = () => {
  const domainReps = useFilterContentDomain()

  if (domainReps.isPending) {
    return (
      <View>
        <LoadingSpinner />
      </View>
    )
  } else if (domainReps.isError) {
    // TODO: we should wrap the outer container with a StrategicErrorBoundary and throw
    return <WhoopsPage error={domainReps.error} />
  } else {
    // Render
    return <ManageContentTabular domainReps={domainReps.data.domains} />
  }
}
