import { useQuery } from '@tanstack/react-query'
import _ from 'lodash'
import { typedPost } from 'sierra-client/state/api'
import { YDocHistoryResponse } from 'sierra-domain/api/common'
import { ScopedCreateContentId } from 'sierra-domain/collaboration/types'
import { XRealtimeCollaborationGetDocumentHistory } from 'sierra-domain/routes'
import { getUpdatesMeta } from 'sierra-domain/version-history/get-updates-meta'
import { groupUpdates } from 'sierra-domain/version-history/group-updates'
import { ExpandRange, History, PublishedVersionUpdate, YjsUpdate } from 'sierra-domain/version-history/types'
import { useOnChanged } from 'sierra-ui/utils'

async function selectHistory(data: YDocHistoryResponse, expandRanges: ExpandRange[] = []): Promise<History> {
  const yjsUpdate = data.updates.map(
    (it): YjsUpdate => ({
      ...it,
      type: 'yjs',
      id: String(it.id),
    })
  )
  const publishedVersions = data.publishedVersions.map(
    (it): PublishedVersionUpdate => ({
      type: 'published-version',
      createdAt: it.publishedAt,
      id: it.id,
      userIds: it.publishedBy ? [it.publishedBy] : [],
    })
  )

  const allUpdates = _.sortBy([...yjsUpdate, ...publishedVersions], it => it.createdAt)

  const groupedUpdates = await groupUpdates(allUpdates, expandRanges)
  const yjsUpdatesMeta = await getUpdatesMeta(groupedUpdates)
  return {
    updates: groupedUpdates,
    yjsUpdatesMeta: yjsUpdatesMeta,
  }
}

export function useVersionHistory(
  scopedContentId: ScopedCreateContentId,
  expandedRanges: ExpandRange[]
): History | undefined {
  const query = useQuery({
    queryKey: [XRealtimeCollaborationGetDocumentHistory, { yDocId: scopedContentId }],
    queryFn: async () => {
      const data = await typedPost(XRealtimeCollaborationGetDocumentHistory, { yDocId: scopedContentId })
      try {
        return await selectHistory(data, expandedRanges)
      } catch (e) {
        console.debug('[useVersionHistory] Error selecting history', e)
        throw e
      }
    },
    refetchOnWindowFocus: false,
    staleTime: Infinity,
  })

  useOnChanged(oldValue => {
    if (oldValue === undefined) {
      // Do nothing on the first change
    } else {
      void query.refetch()
    }
  }, expandedRanges)

  return query.data
}
