import { spacing } from 'sierra-ui/theming'
import styled from 'styled-components'

export const HorizontalWrapper = styled.section`
  display: grid;
  grid-auto-flow: column;
  grid-auto-columns: 1fr;
  padding: 0;
  grid-column: 2 / -2;
  max-width: unset;
  gap: 1.5rem;
`

export const VerticalContent = styled.div`
  & > p,
  & > ul,
  & > ol,
  & > h1,
  & > h2,
  & > h3,
  & > h4 {
    margin-left: ${spacing[16]};
    margin-right: ${spacing[16]};

    &:first-child {
      margin-top: ${spacing[32]};
    }

    &:last-child {
      margin-bottom: ${spacing[32]};
    }
  }

  /* Default type margin */

  & > * + * {
    margin-top: ${spacing['12']};
  }

  /* Exceptions from default */

  & > p + h1,
  p + h2,
  p + h3,
  p + h4 {
    margin-top: ${spacing['4']};
  }
`
