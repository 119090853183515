import { createChannel, createEvent } from 'sierra-client/realtime-data/channels/types'
import { LiveSessionId } from 'sierra-domain/api/nano-id'
import { DateFromISOString } from 'sierra-domain/zod-extensions'
import { z } from 'zod'

export const courseSettingsChannel = createChannel('course-settings', [
  createEvent('course-title-changed', z.object({ title: z.string(), updatedAt: DateFromISOString })),
  createEvent('course-publish-state-changed', z.object({ updatedAt: DateFromISOString })),
  createEvent(
    'live-session-data-changed',
    z.object({ liveSessionId: LiveSessionId, updatedAt: DateFromISOString })
  ),
])
