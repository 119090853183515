import {
  ErrorBoundaryFallbackComponent,
  ErrorBoundaryFallbackComponentProps,
} from 'sierra-client/error/strategic-error-boundary'
import { useTranslation } from 'sierra-client/hooks/use-translation'
import { ErrorDetails } from 'sierra-client/views/error-details'
import { Icon } from 'sierra-ui/components'
import { Button, Text, View } from 'sierra-ui/primitives'
import styled from 'styled-components'

const Centered = styled(View).attrs({ direction: 'column', alignItems: 'center', gap: 'small' })`
  max-width: 30rem;
`

const Container = styled(View).attrs({
  alignItems: 'center',
  justifyContent: 'center',
  grow: true,
  background: 'grey2',
})`
  height: 100%;
`

export const CreateCardError: React.FC<
  Pick<ErrorBoundaryFallbackComponentProps, 'error' | 'sentryEventId' | 'reloadPage'>
> = ({ error, sentryEventId, reloadPage }) => {
  const { t } = useTranslation()

  return (
    <Container>
      <Centered>
        <Icon iconId='screen--off' size='size-24' color='grey40' />

        <View direction='column'>
          <Text color='LEGACY_DEFAULT_TEXT_COLOR_REPLACE_ASAP' align='center' size='regular' bold>
            {t('create.card-failed')}
          </Text>
          <Text align='center' size='small' color='grey60'>
            {t('create.card-failed-body')}
          </Text>
        </View>

        <Button onClick={reloadPage}>{t('dictionary.refresh')}</Button>

        <ErrorDetails sentryEventId={sentryEventId} error={error} />
      </Centered>
    </Container>
  )
}

export const CreateCardErrorForLearner: ErrorBoundaryFallbackComponent = ({
  error,
  sentryEventId,
  reloadPage,
}) => {
  const { t } = useTranslation()

  return (
    <Container>
      <Centered>
        <Icon iconId='screen--off' size='size-24' color='grey40' />

        <View direction='column'>
          <Text align='center' size='regular' color='black' bold>
            {t('create.card-failed--learner')}
          </Text>
          <Text align='center' size='small' color='grey60'>
            {t('create.card-failed-body--learner')}
          </Text>
        </View>

        <Button onClick={reloadPage}>{t('dictionary.refresh')}</Button>

        <ErrorDetails sentryEventId={sentryEventId} error={error} />
      </Centered>
    </Container>
  )
}
