import _, { keyBy } from 'lodash'
import { DateTime } from 'luxon'
import React, { useCallback, useEffect, useMemo, useRef, useState } from 'react'
import Emoji from 'sierra-client/components/common/emoji-component-implementation'
import { useLiveSessionIdContext } from 'sierra-client/components/liveV2/live-session-id-provider'
import { selectMessagesInThread } from 'sierra-client/state/chat/selectors'
import { useSelector } from 'sierra-client/state/hooks'
import { RootState } from 'sierra-client/state/types'
import { selectUserId } from 'sierra-client/state/user/user-selector'
import { useUser } from 'sierra-client/state/users/hooks'
import { UserId } from 'sierra-domain/api/uuid'
import { ScopedChatId } from 'sierra-domain/collaboration/types'
import { Reaction } from 'sierra-ui/components/sana-now/reaction'
import { ReactionAnimation } from 'sierra-ui/components/sana-now/reaction-animation'

const MAX_ONGOING_REACTIONS = 40
const COMPLETED_CACHE_LIMIT = 200
const SELECTED_MESSAGES = 15

const messageIsRecent = (timeSent: string): boolean => {
  try {
    const timeSinceMessage = DateTime.fromISO(timeSent).diffNow('seconds').seconds
    return timeSinceMessage > -5
  } catch {
    return false
  }
}

export const ReactionComponent: React.FC<{ emoji: string; userId: UserId }> = ({ userId, emoji }) => {
  const user = useUser(userId)

  if (user?.status !== 'loaded') return <></>

  return (
    <Reaction
      emoji={<Emoji emojiUnicode={emoji} size={80} />}
      userName={user.firstName}
      userColor={user.avatarColor}
    />
  )
}

type ReactionMessageData = {
  id: string
  reaction: string
  userId: UserId
  timeSent: string
}

export const RenderReactions = (): JSX.Element => {
  const { liveSessionId } = useLiveSessionIdContext()
  const chatId = ScopedChatId.fromId(liveSessionId)
  const completedReactionAnimationsRef = useRef<string[]>([])

  const userId = useSelector(selectUserId)

  const [reactions, setReactions] = useState<Record<string, ReactionMessageData>>({})

  const selector = useCallback(
    (state: RootState) => {
      const allMessages = selectMessagesInThread(state, chatId, 'root')

      return _.chain(allMessages)
        .flatMap(message => (message.type === 'emoji' && messageIsRecent(message.timeSent) ? [message] : []))
        .orderBy('timeSent', 'desc')
        .take(SELECTED_MESSAGES)
        .map(
          (message): ReactionMessageData => ({
            id: message.id,
            reaction: message.emoji,
            userId: message.userId,
            timeSent: message.timeSent,
          })
        )
        .value()
    },
    [chatId]
  )

  const messages = useSelector(selector, _.isEqual)

  useEffect(() => {
    setReactions(current => {
      const currentReactions = Object.values(current)

      const newMessages = messages.filter(
        message =>
          !completedReactionAnimationsRef.current.includes(message.id) &&
          // Don't show old reactions.
          // This can happen if they were ignored because the page was not visible when they were sent.
          DateTime.fromISO(message.timeSent).diffNow('seconds').seconds > -10
      )

      completedReactionAnimationsRef.current.push(...newMessages.map(message => message.id))
      completedReactionAnimationsRef.current =
        completedReactionAnimationsRef.current.slice(-COMPLETED_CACHE_LIMIT)

      if (currentReactions.length >= MAX_ONGOING_REACTIONS) {
        // Always show the users own reactions even when throttling
        const myReactions = newMessages.filter(message => message.userId === userId)
        return { ...current, ...keyBy(myReactions, 'id') }
      }

      // If the page isn't visible, don't show any new reactions
      if (document.visibilityState === 'hidden') {
        return current
      }

      return { ...current, ...keyBy(newMessages, 'id') }
    })
  }, [messages, userId])

  const renderReaction = useCallback(
    (id: string) => {
      const message = reactions[id]
      if (!message) return null

      return <ReactionComponent key={message.id} emoji={message.reaction} userId={message.userId} />
    },
    [reactions]
  )

  const removeReaction = useCallback((id: string) => {
    setReactions(current => {
      const newReactions = { ...current }
      delete newReactions[id]
      return newReactions
    })
  }, [])

  const reactionIds = useMemo(() => Object.keys(reactions), [reactions])

  return (
    <ReactionAnimation
      elements={reactionIds}
      onAnimationDone={removeReaction}
      renderReactionElement={renderReaction}
    />
  )
}
