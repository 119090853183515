import _ from 'lodash'
import { useCallback } from 'react'
import { useNonExpiringNotif } from 'sierra-client/components/common/notifications'
import { getAsString, hasOwnProperty } from 'sierra-domain/utils'

export const useShowErrorToUser = (): ((err: unknown) => void) => {
  const { push } = useNonExpiringNotif()

  const errorHandler = useCallback(
    (err: unknown) => {
      let body = 'Unkonwn error'

      // Errors are often not typed so we check for relevant properties explicitly
      if (hasOwnProperty(err, 'code')) {
        body = `code: ${_.toString(err.code)} statusCode: ${getAsString(err, 'statusCode')}`
      } else if (hasOwnProperty(err, 'message') && typeof err.message === 'string') {
        body = err.message
      }

      push({ type: 'error', title: 'Connection error ⋅ Please reload', body })

      throw err // Rethrow for traceability
    },
    [push]
  )

  return errorHandler
}
