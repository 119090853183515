import { zodResolver } from '@hookform/resolvers/zod'
import { AnimatePresence } from 'framer-motion'
import _ from 'lodash'
import React, { useRef } from 'react'
import { useFieldArray, useForm, useWatch } from 'react-hook-form'
import { SkillLevelSettingId } from 'sierra-client/api/graphql/branded-types'
import { useTranslation } from 'sierra-client/hooks/use-translation'
import { Trans } from 'sierra-client/hooks/use-translation/trans'
import { FormInput, FormSingleSelectDropdown, FormSwitch } from 'sierra-client/views/manage/shared/form'
import { Icon, aiGradient } from 'sierra-ui/components'
import { Button, IconButton, ScrollView, Text, View } from 'sierra-ui/primitives'
import { DefaultDropdownTrigger } from 'sierra-ui/primitives/menu-dropdown'
import { token } from 'sierra-ui/theming'
import styled, { css } from 'styled-components'
import { z } from 'zod'
import { SkillColor, skillColorToGradientBackground, useGetSkillColors } from '../../colors'

const duration = 0.2
const variants = {
  hidden_before: {
    opacity: 0,
    height: 0,
    marginTop: -24,
    overflow: 'hidden',
  },
  visible: {
    opacity: 1,
    height: 'auto',
    marginTop: 0,

    transition: {
      duration,
      opacity: {
        delay: duration / 2,
        duration: duration / 2,
      },
    },
  },
  hidden_after: {
    opacity: 0,
    height: 0,
    marginTop: -24,
    overflow: 'hidden',

    transition: {
      duration,
      opacity: {
        duration: duration / 2,
      },
    },
  },
}

const MAX_LEVELS = 10

export const BadgeTheme = SkillColor.or(z.literal('unknown')).catch('unknown')

const SkillLevelSetting = z.object({
  skillLevelId: SkillLevelSettingId.optional(),
  name: z.string().min(1),
  index: z.number(),
  // Allow to set value of 'unknown' but it is still not valid
  badgeTheme: BadgeTheme.refine((x): boolean => x !== 'unknown', {
    message: 'Need to select a badge color',
  }),
})

export type SkillLevelSetting = z.infer<typeof SkillLevelSetting>

const FormSchema = z.object({
  smartSuggestions: z.boolean(),
  sequentialUnlocking: z.boolean(),
  skillsVisibleToAll: z.boolean(),
  skillsShowLeaderboard: z.boolean(),
  levels: z.array(SkillLevelSetting).max(MAX_LEVELS),
})

export type FormData = z.infer<typeof FormSchema>

const Form = styled.form`
  width: 100%;
  display: flex;
  flex-direction: column;
  height: 100%;
`

const Divider = styled.hr`
  height: 1px;
  width: 100%;
  background-color: ${token('border/strong')};
`

const BetaPill = styled(Text).attrs({ size: 'technical', bold: true })`
  padding: 1px 6px;
  border-radius: 8px;
  background-color: ${token('surface/strong')};
  color: ${token('foreground/muted')};
  &::before {
    content: 'Beta';
  }
`

const _SmartIcon = styled(Icon).attrs({
  iconId: 'sana-symbol',
  color: 'destructive/foreground',
  size: 'size-16',
})`
  padding: 4px;
  border-radius: 20px;
  ${aiGradient}
`

const baseIcon = css`
  border: 1px solid;
  border-color: ${token('border/default')};
  color: ${token('foreground/primary')};
`

const _SmartIconWrapper = styled(View)`
  padding: 8px;
  border-radius: 10px;
  ${baseIcon}
`

const SmartIcon: React.FC = () => {
  return (
    <_SmartIconWrapper>
      <_SmartIcon />
    </_SmartIconWrapper>
  )
}

const SettingsIcon = css`
  padding: 12px;
  border-radius: 10px;
`

const UnlockedIcon = styled(Icon).attrs({ iconId: 'unlocked' })`
  ${SettingsIcon}
  ${baseIcon}
`

const VisibleOffIcon = styled(Icon).attrs({ iconId: 'view--off' })`
  ${SettingsIcon}
  ${baseIcon}
`

const VisibleIcon = styled(Icon).attrs({ iconId: 'view' })`
  ${SettingsIcon}
  ${baseIcon}
`

const MedalIcon = styled(Icon).attrs({ iconId: 'medal' })`
  ${SettingsIcon}
  ${baseIcon}
`

const FormBottom = styled(View)`
  flex: 0 0 auto;
  display: flex;
  flex-direction: column;
  margin-top: auto;
  margin-left: auto;
  padding: 8px 40px;
`
const ButtonWrapper = styled(View)`
  margin-top: auto;
  margin-left: auto;
`

const GridView = styled(View)`
  display: grid;
  align-items: flex-start;
  grid-template-areas:
    'title . .'
    'name color button';
  grid-template-columns: 1fr 160px 40px;
  transform-origin: top;
`
const GridTitle = styled(Text)`
  grid-area: title;
`

// TODO: The input here is set to explicit height to match the design
// This probably doesnt need to be done in the future
const GridName = styled.div`
  grid-area: name;
  input {
    height: 36px;
  }
`
const GridColor = styled.div`
  grid-area: color;
`
const GridButton = styled.div`
  grid-area: button;
`

const HelpDeskLink = styled.a.attrs({ target: '_blank' })`
  color: ${token('foreground/link')};
  cursor: pointer;
  &:hover {
    color: ${token('foreground/link').shift(0.2)};
  }
`

const LevelColor = styled.div<{ color: SkillColor }>`
  width: 16px;
  height: 16px;
  border-radius: 4px;
  background: ${p => skillColorToGradientBackground[p.color]};
`

export const SkillSettingsForm: React.FC<{
  onSubmit: (d: FormData) => void
  onCancel: () => void
  levels: Array<SkillLevelSetting>
  settings: {
    smartSuggestions: boolean
    sequentialUnlockingEnabled: boolean
    skillsVisibleToAll: boolean
    skillsShowLeaderboard: boolean
  }
  loading?: boolean
}> = ({ onSubmit, onCancel, levels, settings, loading = false }) => {
  const { t } = useTranslation()
  const levelColors = useGetSkillColors()

  const handler = useForm<FormData>({
    resolver: zodResolver(FormSchema),
    values: {
      levels: levels,
      smartSuggestions: settings.smartSuggestions,
      sequentialUnlocking: settings.sequentialUnlockingEnabled,
      skillsVisibleToAll: settings.skillsVisibleToAll,
      skillsShowLeaderboard: settings.skillsShowLeaderboard,
    },
  })

  const levelHandler = useFieldArray({ control: handler.control, name: 'levels' })
  const { formState, control } = handler
  const { isLoading, errors } = formState
  const watchSkillsVisibleToAll = useWatch({ control, name: 'skillsVisibleToAll' })

  const addLevelButtonRef = useRef<HTMLButtonElement>(null)

  return (
    <Form onSubmit={handler.handleSubmit(onSubmit)}>
      <ScrollView grow padding='none 40'>
        <View direction='column' gap='medium'>
          <View justifyContent='space-between' alignItems='center'>
            <View gap='12'>
              <SmartIcon />
              <View direction='column' gap='none'>
                <View>
                  <Text capitalize='first' bold>
                    {t('skills.engines.smart-suggestions.title')}
                  </Text>
                  <BetaPill />
                </View>
                <Text capitalize='first' color='foreground/secondary'>
                  <Trans
                    i18nKey='skills.engines.smart-suggestions.description'
                    components={{ a: <HelpDeskLink href='https://help.sana.ai/en/?q=skill' /> }}
                  />
                </Text>
              </View>
            </View>
            <FormSwitch control={handler.control} name='smartSuggestions' />
          </View>
          <View justifyContent='space-between' alignItems='center'>
            <View gap='12'>
              <UnlockedIcon />
              <View direction='column' gap='none'>
                <Text capitalize='first' bold>
                  {t('skills.engines.sequential-unlocking.title')}
                </Text>
                <Text capitalize='first' color='foreground/secondary'>
                  <Trans
                    i18nKey='skills.engines.sequential-unlocking.description'
                    components={{ a: <HelpDeskLink href='https://help.sana.ai/en/?q=skill' /> }}
                  />
                </Text>
              </View>
            </View>
            <FormSwitch control={handler.control} name='sequentialUnlocking' />
          </View>
          <View justifyContent='space-between' alignItems='center'>
            <View gap='12'>
              {watchSkillsVisibleToAll ? <VisibleIcon /> : <VisibleOffIcon />}
              <View direction='column' gap='none'>
                <Text capitalize='first' bold>
                  {t('skills.engines.visible.title')}
                </Text>
                <Text capitalize='first' color='foreground/secondary'>
                  <Trans
                    i18nKey='skills.engines.visible.description'
                    components={{ a: <HelpDeskLink href='https://help.sana.ai/en/?q=skill' /> }}
                  />
                </Text>
              </View>
            </View>
            <FormSwitch control={handler.control} name='skillsVisibleToAll' />
          </View>
          <View justifyContent='space-between' alignItems='center'>
            <View gap='12'>
              <MedalIcon />
              <View direction='column' gap='none'>
                <Text capitalize='first' bold>
                  {t('skills.engines.leaderboard.title')}
                </Text>
                <Text capitalize='first' color='foreground/secondary'>
                  {t('skills.engines.leaderboard.description')}
                </Text>
              </View>
            </View>
            <FormSwitch control={handler.control} name='skillsShowLeaderboard' />
          </View>
        </View>
        <Divider />
        <View direction='column' gap='24'>
          <AnimatePresence initial={false}>
            {levelHandler.fields.map((level, index) => (
              <GridView
                key={index}
                animated
                layout
                variants={variants}
                initial='hidden_before'
                animate='visible'
                exit='hidden_after'
              >
                <GridTitle capitalize='first' bold size='small' color='foreground/primary'>{`${t(
                  'dictionary.level'
                )} ${level.index}`}</GridTitle>
                <GridName>
                  <FormInput name={`levels.${index}.name`} control={handler.control} autoComplete={false} />
                </GridName>
                <GridColor>
                  <FormSingleSelectDropdown
                    name={`levels.${index}.badgeTheme`}
                    control={handler.control}
                    menuItems={levelColors.map(color => ({
                      id: color,
                      type: 'canvas',
                      render: () => (
                        <View key={color}>
                          <LevelColor color={color} />
                          <Text>{_.capitalize(color)}</Text>
                        </View>
                      ),
                    }))}
                    renderTrigger={() => {
                      const value = handler.getValues(`levels.${index}.badgeTheme`)
                      return (
                        <DefaultDropdownTrigger grow>
                          <Text as='span'>
                            <View>
                              {value === 'unknown' ? (
                                <Text color='foreground/secondary'>
                                  {_.capitalize(t('dictionary.select'))}
                                </Text>
                              ) : (
                                <>
                                  <LevelColor color={value} />
                                  <Text>{_.capitalize(value)}</Text>
                                </>
                              )}
                            </View>
                          </Text>
                        </DefaultDropdownTrigger>
                      )
                    }}
                  />
                </GridColor>
                <GridButton>
                  <IconButton
                    iconId='trash-can'
                    variant='transparent'
                    disabled={levelHandler.fields.length === 1}
                    tooltip={t('dictionary.delete')}
                    onClick={() => {
                      // make sure we remove and update each index accordingly
                      const newValues = levelHandler.fields
                        .filter((_, i) => i !== index)
                        .map((field, i) => ({ ...field, index: i + 1 }))

                      levelHandler.replace(newValues)
                    }}
                  />
                </GridButton>
              </GridView>
            ))}
          </AnimatePresence>
          <Button
            ref={addLevelButtonRef}
            onClick={() => {
              levelHandler.append({
                name: '',
                badgeTheme: 'unknown',
                index: levelHandler.fields.length + 1,
              })

              setTimeout(() => {
                requestAnimationFrame(() => {
                  addLevelButtonRef.current?.scrollIntoView({
                    behavior: 'smooth',
                    block: 'nearest',
                  })
                })
              }, 300) // TODO(anton): can we make this less fragile?
            }}
            variant='secondary'
            type='button'
            disabled={levelHandler.fields.length >= MAX_LEVELS}
          >
            {_.capitalize(t('skills.add-level'))}
          </Button>
        </View>
      </ScrollView>

      <FormBottom gap='xsmall'>
        {Object.values(errors).map(error => {
          return (
            <Text size='micro' color={'destructive/background'} key={error.message}>
              {error.message}
            </Text>
          )
        })}
        <ButtonWrapper>
          <Button onClick={onCancel} variant='secondary' type='button'>
            {t('dictionary.cancel')}
          </Button>
          <Button loading={loading || isLoading} type='submit'>
            {t('dictionary.save')}
          </Button>
        </ButtonWrapper>
      </FormBottom>
    </Form>
  )
}
