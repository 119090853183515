import { useState } from 'react'
import { useTranslation } from 'sierra-client/hooks/use-translation'
import { useOverrideIntercomLauncherVisibility } from 'sierra-client/intercom/intercom-visibility'
import { NanoId12 } from 'sierra-domain/api/nano-id'
import { Modal } from 'sierra-ui/components'
import { Button, IconButton, View } from 'sierra-ui/primitives'
import { token } from 'sierra-ui/theming'
import styled from 'styled-components'
import { ShareDashboardForm } from './share-dashboard-form'

const CloseContainer = styled(View)`
  position: absolute;
  top: 1rem;
  right: 0.5rem;
`

const StyledModal = styled(Modal)`
  overflow: hidden;
  position: absolute;
  top: 240px;
  right: 10px;
  min-height: 450px;
  box-shadow: 0 8px 16px 0 #00000014;
  background: ${token('surface/default')};
  border: 1px solid ${token('border/default')};

  /* Set the transform origin for the Modal pop animation. */
  transform-origin: 60% 0;
`

const ContentContainer = styled(View)`
  width: 618px;
  min-height: 450px;
  position: relative;
`

export const ShareDashboardPanel: React.FC<{
  dashboardId: NanoId12
  shareWithAll: boolean
  onShareWithAll: (shareWithAll: boolean) => void
}> = ({ dashboardId, shareWithAll, onShareWithAll }) => {
  const { t } = useTranslation()

  const [isOpen, setIsOpen] = useState(false)
  useOverrideIntercomLauncherVisibility(isOpen ? 'hidden' : 'default')

  return (
    <>
      <Button
        icon='building'
        variant='secondary'
        onClick={() => setIsOpen(true)}
        data-intercom-target='share-dashboard'
        data-testid='share-dashboard-button'
      >
        {t('share.share')}
      </Button>

      <StyledModal
        open={isOpen}
        onClose={() => setIsOpen(false)}
        overlayVariant='dark'
        animation='pop'
        size='fit-content'
      >
        <ContentContainer alignItems='stretch'>
          <CloseContainer>
            <IconButton
              variant='transparent'
              size='small'
              iconId='close'
              aria-label={t('dictionary.close')}
              onClick={() => setIsOpen(false)}
            />
          </CloseContainer>

          <View padding='small medium' grow direction='column' alignItems='stretch'>
            <ShareDashboardForm
              dashboardId={dashboardId}
              shareWithAll={shareWithAll}
              onShareWithAll={onShareWithAll}
            />
          </View>
        </ContentContainer>
      </StyledModal>
    </>
  )
}
