import _ from 'lodash'
import { useEffect, useLayoutEffect, useState } from 'react'
import { FCC } from 'sierra-client/types'
import { contentCardWidthPx } from 'sierra-client/views/learner/home/components/content-card-grid'
import { HideScrollbarUnlessHovered } from 'sierra-ui/components/layout-kit'
import { TRANSITION_DURATION_MILLIS } from 'sierra-ui/components/layout-kit/constants'
import styled, { css } from 'styled-components'

type Padding = { start: number; end: number; top?: number; bottom?: number }

const ContainerUL = styled.ul<{
  $numberOfChildren: number
  $count: number
  $padding: Padding
  $canOverflow: boolean
  $withForcedEmptyCell: boolean
}>`
  list-style: none;
  display: grid;
  max-width: 100%;

  ${p =>
    !p.$canOverflow
      ? css`
          grid-template-columns: repeat(${p.$count}, 1fr);

          /* Hide all cards after the p.$count-th card */
          & > * {
            ${p.$count > 1 &&
            css`
              :nth-child(n + ${p.$count + 1}) {
                display: none;
              }
            `}
          }
          overflow: hidden;
        `
      : css`
          overflow-x: auto;
          overflow-y: hidden;
          ${HideScrollbarUnlessHovered}

          grid-template-columns: repeat(
            ${p.$numberOfChildren},
            ${contentCardWidthPx}px
          );
        `}

  column-gap: 16px;
  grid-auto-flow: column;
  padding-inline: ${p => p.$padding.start}px ${p => p.$padding.end}px;
  ${p => p.$padding.bottom !== undefined && `padding-bottom: ${p.$padding.bottom}px;`}
  ${p => p.$padding.top !== undefined && `padding-top: ${p.$padding.top}px;`}
  transition: padding ${TRANSITION_DURATION_MILLIS}ms ease;

  ${p =>
    p.$withForcedEmptyCell &&
    css`
      padding-inline: ${p.$padding.start}px 0;
      margin-right: -${p.$padding.start}px;
    `}
`

type CardSpacings = {
  numberOfCards: number
  // gap: number
  // cardWidth: number
  canOverflow: boolean
}

export const ContentCardUL: FCC<{
  padding: Padding
  withForcedEmptyCell?: boolean
}> = ({ children, padding, withForcedEmptyCell = false }) => {
  const [containerEl, setContainerEl] = useState<HTMLUListElement | null>(null)

  const [spacings, setSpacings] = useState<CardSpacings | undefined>(undefined)

  const { start: startPadding, end: endPadding } = padding

  useEffect(() => {
    if (containerEl === null) {
      setSpacings(undefined)
      return
    }

    const measure = (): void => {
      const availableSpace = containerEl.clientWidth
      const targetCardWidth = contentCardWidthPx
      const targetGap = 16
      const widthWithoutPadding = availableSpace - (startPadding + endPadding)
      let _numberOfCards = Math.floor(widthWithoutPadding / targetCardWidth)

      const remainingWidth = widthWithoutPadding - _numberOfCards * targetCardWidth
      const totalGapsWidth = (_numberOfCards - 1) * targetGap

      if (totalGapsWidth > remainingWidth) _numberOfCards -= 1

      // If we only have room for 1 card we will be OK with horizontal
      // overflow, so allow up to 10 in this case
      const canOverflow = _numberOfCards < 2
      const numberOfCards = canOverflow ? 10 : _numberOfCards
      // const gap = Math.floor(totalGapsWidth / numberOfCards)
      // const cardWidth = Math.floor((widthWithoutPadding - totalGapsWidth) / numberOfCards)

      const newSpacings: CardSpacings = {
        numberOfCards,
        // gap,
        // cardWidth,
        canOverflow,
      }

      setSpacings(prevValue => (_.isEqual(prevValue, newSpacings) ? prevValue : newSpacings))
    }

    const observer = new ResizeObserver(measure)
    measure()

    observer.observe(containerEl)

    return () => {
      observer.disconnect()
    }
  }, [containerEl, endPadding, startPadding])

  const [numberOfChildren, setNumberOfChildren] = useState(0)

  // Exhaustive deps is intentionally disabled here because we want it to run
  // on every render in case the number of children has changed
  // eslint-disable-next-line react-hooks/exhaustive-deps
  useLayoutEffect(() => {
    if (containerEl === null) return undefined

    setNumberOfChildren(containerEl.children.length)
  })

  const count = (withForcedEmptyCell ? 1 : 0) + (spacings?.numberOfCards ?? 0)

  return (
    <ContainerUL
      ref={setContainerEl}
      $numberOfChildren={numberOfChildren}
      $count={count}
      $padding={padding}
      $canOverflow={spacings?.canOverflow ?? false}
      $withForcedEmptyCell={withForcedEmptyCell}
    >
      {spacings !== undefined && children}
    </ContainerUL>
  )
}
