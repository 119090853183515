/**
 * Checks if polyfills are needed and dynamically imports the necessary ones.
 */
export function checkPolyfills(): void {
  // eslint-disable-next-line @typescript-eslint/no-unnecessary-condition
  if (Array.prototype.at === undefined) {
    void import('sierra-client/polyfills/array-at.polyfill').then(({ polyfillArrayAt }) => {
      polyfillArrayAt()
    })
  }
}
