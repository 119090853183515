import { createFileRoute } from '@tanstack/react-router'
import { PageIdentifier } from 'sierra-client/layout/sana-page'
import { ManageApiClientDetails } from 'sierra-client/views/manage/api/manage-client-api-details'
import { useApiPageEnabled } from 'sierra-client/views/manage/permissions/use-api-page-enabled'
import { ManagePageWrapper } from 'sierra-client/views/manage/utils/manage-page-wrapper'
import { ApiClientId } from 'sierra-domain/api/nano-id'
import { z } from 'zod'

// Note: ManagePageWrapper handles the role check.
const Page: React.FC = () => {
  const apiClientId = Route.useParams({ select: p => p.apiClientId })
  const apiPageAvailable = useApiPageEnabled()

  if (apiPageAvailable !== true) {
    // todo(workflows): Redirect after initializing
    return null
  }

  return (
    <ManagePageWrapper
      pageIdentifier={
        apiClientId === 'new'
          ? PageIdentifier.ManageApiNewClient()
          : PageIdentifier.ManageApiClientDetail({ apiClientId })
      }
    >
      <ManageApiClientDetails key={apiClientId} clientId={apiClientId} />
    </ManagePageWrapper>
  )
}

export const Route = createFileRoute('/settings/api/$apiClientId')({
  component: Page,
  params: {
    parse: z.object({ apiClientId: z.union([ApiClientId, z.literal('new')]) }).parse,
    stringify: p => p,
  },
})
