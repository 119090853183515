import { useId, useState } from 'react'
import { usePathCollaboratorGroupsMutation } from 'sierra-client/api/hooks/use-path-collaborator'
import { ChipInputWithAutocomplete } from 'sierra-client/components/common/chip-input-with-autocomplete'
import { useNotif } from 'sierra-client/components/common/notifications'
import { Pill } from 'sierra-client/components/common/pill'
import { useTranslation } from 'sierra-client/hooks/use-translation'
import { useCachedQuery } from 'sierra-client/state/api'
import { SettingsTabComponent } from 'sierra-client/views/manage/paths/path-settings/types'
import type { PathCollaboratorGroup } from 'sierra-domain/api/admin'
import {
  XRealtimeAdminPathsListPathCollaboratorGroups,
  XRealtimeAdminPathsListPotentialPathCollaboratorGroups,
} from 'sierra-domain/routes'
import { FormElement, RoundAvatar } from 'sierra-ui/components'
import { Button, IconButton, LoadingSpinner, Spacer, Text } from 'sierra-ui/primitives'
import styled from 'styled-components'

const AutocompleteContainer = styled.div`
  flex: 1;
  display: grid;
  grid: 1fr / 1fr 0fr;
  gap: 1.5rem;
  align-items: center;
`

const StyledAutocomplete = styled(ChipInputWithAutocomplete<PathCollaboratorGroup, true, true, false>)`
  flex: 1;

  & {
    .MuiOutlinedInput-root {
      padding: 0.5rem;
    }
  }
`

const SuggestionItem = styled.li`
  display: flex;
  align-items: center;
`

const GroupList = styled.ul`
  list-style: none;
  position: relative;

  li.grid {
    display: grid;
    grid: 1fr / 0fr 1fr;
    grid-auto-columns: max-content;
    grid-auto-flow: column;
    gap: 1rem;
    justify-content: start;
    align-items: center;
    padding: 0.5rem 0;
    font-size: 1em;
  }
`

const getOptionLabel = (option: PathCollaboratorGroup): string => option.groupName ?? ''

export const CollaboratorGroupsTab: SettingsTabComponent = ({ pathId, onSave, canEditCollaborators }) => {
  const collaboratorGroupsQuery = useCachedQuery(XRealtimeAdminPathsListPathCollaboratorGroups, { pathId })
  const potentialCollaboratorGroupsQuery = useCachedQuery(
    XRealtimeAdminPathsListPotentialPathCollaboratorGroups,
    { pathId }
  )
  const collaborationGroupMutation = usePathCollaboratorGroupsMutation()

  const collaboratorGroups = collaboratorGroupsQuery.data?.collaborators ?? []

  const inputId = useId()

  const { t } = useTranslation()
  const notifications = useNotif()

  const [query, setQuery] = useState('')
  const [selections, setSelections] = useState<PathCollaboratorGroup[]>([])

  const handleInviteSelections = (): void => {
    collaborationGroupMutation.mutate(
      { pathId, add: selections.map(group => group.groupId), remove: [] },
      {
        onSuccess() {
          setSelections([])
          onSave()

          notifications.push({
            type: 'custom',
            level: 'success',
            body: t('notifications.group-invited-singular'),
          })
        },
      }
    )
  }

  const handleRemoveCollaboratorGroup = (group: PathCollaboratorGroup): void => {
    collaborationGroupMutation.mutate(
      { pathId, add: [], remove: [group.groupId] },
      {
        onSuccess() {
          onSave()
        },
      }
    )
  }

  const filterOptions = (options: PathCollaboratorGroup[]): PathCollaboratorGroup[] => {
    const collaboratorGroupIds = new Set(collaboratorGroups.map(group => group.groupId))

    return options.filter(group => {
      if (collaboratorGroupIds.has(group.groupId)) return false

      const labelTokens = getOptionLabel(group)
        .split(' ')
        .map(t => t.toLowerCase())
      const queryTokens = query.split(' ').map(t => t.toLowerCase())

      return labelTokens.some(lt => queryTokens.some(qt => lt.startsWith(qt)))
    })
  }

  return (
    <FormElement htmlFor={inputId} label={t('author.invite-groups')}>
      {potentialCollaboratorGroupsQuery.data === undefined ? (
        <LoadingSpinner padding='large' />
      ) : (
        <AutocompleteContainer>
          <StyledAutocomplete
            id={inputId}
            inputValue={query}
            onInputChange={(event, value) => setQuery(value)}
            value={selections}
            onChange={(event, values) => {
              setSelections(values)
            }}
            options={potentialCollaboratorGroupsQuery.data.collaborators}
            getOptionLabel={option => getOptionLabel(option)}
            filterOptions={filterOptions}
            filterSelectedOptions
            limitTags={5}
            multiple
            freeSolo={false}
            disabled={!canEditCollaborators}
            inputPlaceholder={t('author.course-settings.skill-placeholder')}
            renderTags={groups => {
              return groups.map(group => (
                <Pill key={group.groupId} variant='outlined' active={false}>
                  {getOptionLabel(group)}
                </Pill>
              ))
            }}
            renderOption={group => {
              return (
                <SuggestionItem>
                  <RoundAvatar firstName={group.groupName} color={group.avatarColor} />
                  <Spacer />
                  <Text bold>{getOptionLabel(group)}</Text>
                </SuggestionItem>
              )
            }}
          />
          <Button
            onClick={handleInviteSelections}
            disabled={!canEditCollaborators || selections.length === 0}
          >
            {t('dictionary.invite')}
          </Button>
        </AutocompleteContainer>
      )}
      <GroupList>
        {collaboratorGroups.length === 0 ? (
          <li>
            <Text color='grey35'>{t('author.no-collaborators')}</Text>
          </li>
        ) : (
          collaboratorGroups.map((group: PathCollaboratorGroup) => (
            <li key={group.groupId} className='grid'>
              <RoundAvatar firstName={group.groupName} color={group.avatarColor} />
              <Text bold>{getOptionLabel(group)}</Text>

              {canEditCollaborators && (
                <IconButton
                  onClick={() => handleRemoveCollaboratorGroup(group)}
                  iconId='close'
                  variant='transparent'
                />
              )}
            </li>
          ))
        )}
      </GroupList>
    </FormElement>
  )
}
