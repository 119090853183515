import { keepPreviousData } from '@tanstack/react-query'
import React, { useCallback } from 'react'
import { graphql } from 'sierra-client/api/graphql/gql'
import { GroupsByIdsQuery } from 'sierra-client/api/graphql/gql/graphql'
import { convertGQLAvatar } from 'sierra-client/api/graphql/util/convert-gql-avatar'
import { graphQuery, useGraphQuery } from 'sierra-client/api/hooks/use-graphql-query'
import { IdentitiesSelector, Identity } from 'sierra-client/components/common/identities-selector'
import { useTranslation } from 'sierra-client/hooks/use-translation'
import { Context } from 'sierra-client/lib/filter/components/common'
import {
  ValueAnchor,
  addPredValue,
  fromPredicate,
  removePredValue,
} from 'sierra-client/lib/filter/components/predicate-utils'
import { Avatar } from 'sierra-domain/api/manage'
import { GroupId } from 'sierra-domain/api/uuid'
import { Predicate } from 'sierra-domain/filter/datatype/pred'
import { setPred } from 'sierra-domain/filter/operations'
import { AvatarColor } from 'sierra-domain/user/avatar-color'
import { isDefined } from 'sierra-domain/utils'
import { FormElement } from 'sierra-ui/components'

const groupsQuery = graphql(`
  query GroupsAutoComplete($query: String!, $limit: Int!) {
    groups: userGroups(query: $query, limit: $limit) {
      data {
        id
        name
        avatar {
          ...AvatarFragment
        }
      }
    }
  }
`)

const groupsByIdsQuery = graphql(`
  query GroupsByIds($ids: [UserGroupId!]!) {
    groups: userGroupsFromIds(ids: $ids) {
      id
      name
      avatar {
        ...AvatarFragment
      }
    }
  }
`)

const getAvatar = (name: string, avatarColor: AvatarColor): Avatar => {
  return {
    type: 'color',
    color: avatarColor,
    initials: name[0] ?? '',
  }
}

const queryGroups = async (query: string): Promise<Identity[]> => {
  const response = await graphQuery(groupsQuery, { query, limit: 100 })

  return response.groups.data.map(group => {
    return {
      id: group.id,
      identity: {
        type: 'userGroup',
        id: group.id,
      },
      name: group.name,
      avatar: convertGQLAvatar(group.avatar),
    }
  })
}

const useGroupsFromPredicate = (predicate: Predicate): GroupsByIdsQuery['groups'] => {
  const groupIds = fromPredicate(predicate)
    .map(value => {
      switch (value.type) {
        case 'value.group-id':
          return value.value as GroupId
        case 'value.uuid':
          // TODO: It would be nice to avoid this cast.
          //  We don't actually know that these are group ids.
          return value.value as GroupId
        default:
          return undefined
      }
    })
    .filter(isDefined)

  const response = useGraphQuery(
    {
      document: groupsByIdsQuery,
      queryOptions: {
        enabled: groupIds.length !== 0,
        staleTime: 60 * 1000,
        placeholderData: keepPreviousData,
      },
    },
    { ids: groupIds }
  )

  if (groupIds.length === 0) {
    return []
  }

  return response.data?.groups ?? []
}

const useLabelsForGroupPredicate = (predicate: Predicate): string[] => {
  const groups = useGroupsFromPredicate(predicate)
  const labels = groups.map(it => it.name)
  return labels
}

const useQueryGroupsByIds = (predicate: Predicate): Identity[] => {
  const groups = useGroupsFromPredicate(predicate)

  return groups.map(group => {
    return {
      id: group.id,
      identity: {
        type: 'userGroup',
        id: group.id,
      },
      name: group.name,
      avatar: getAvatar(group.name, 'pinkBright'),
    }
  })
}

type UserAutoCompleteProps = {
  ctx: Context
  predicate: Predicate
}
export const GroupAutoComplete: React.FC<UserAutoCompleteProps> = ({ ctx, predicate }) => {
  const selectedGroups = useQueryGroupsByIds(predicate)
  const { t } = useTranslation()

  const onSelect = useCallback(
    (group: Identity) => {
      ctx.update(f => setPred(f, addPredValue(predicate, { type: 'value.group-id', value: group.id })))
    },
    [ctx, predicate]
  )

  const onUnselect = useCallback(
    (group: Identity) => {
      ctx.update(f => {
        const updatePredicate = removePredValue(predicate, { type: 'value.group-id', value: group.id })
        return setPred(f, removePredValue(updatePredicate, { type: 'value.uuid', value: group.id }))
      })
    },
    [ctx, predicate]
  )

  return (
    <FormElement label={t('dictionary.group-plural')}>
      <IdentitiesSelector
        selectedIdentities={selectedGroups}
        onSelect={onSelect}
        onUnselect={onUnselect}
        fetchIdentities={queryGroups}
        placeholder='filter.auto-complete.group.placeholder'
      />
    </FormElement>
  )
}

export const GroupAutocompleteLabel = React.forwardRef<HTMLDivElement, { predicate: Predicate }>(
  ({ predicate, ...rest }, ref) => {
    const labels = useLabelsForGroupPredicate(predicate)
    const label = labels.join(', ')

    return <ValueAnchor {...rest} ref={ref} label={label} />
  }
)
