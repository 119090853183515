import { Atom } from 'jotai'
import { useLiveSessionContext } from 'sierra-client/components/liveV2/contexts/live-session-data'
import { useLiveSessionPresenceAtom } from 'sierra-client/features/sana-now/presence/state'
import { useSelectAtomValue } from 'sierra-client/hooks/use-select-atom-value'
import { useSelector } from 'sierra-client/state/hooks'
import { selectUserId } from 'sierra-client/state/user/user-selector'
import { LiveSessionPresence } from 'sierra-domain/api/live-session-presence'
import { UserId } from 'sierra-domain/api/uuid'

/**
 * Returns the presence for the current live session
 */
export const usePresenceAtom = (): Atom<LiveSessionPresence[]> => {
  const liveSession = useLiveSessionContext()

  return useLiveSessionPresenceAtom(liveSession.liveSessionId)
}

export const usePresenceAtomParticipantIds = (): UserId[] => {
  const atom = usePresenceAtom()
  const participantIds = useSelectAtomValue(atom, it => it.map(it => it.userId))

  return participantIds
}

export const usePresenceAtomFollowMeIsEnabled = (): boolean => {
  const userId = useSelector(selectUserId)
  const atom = usePresenceAtom()

  const followMeIsEnabled = useSelectAtomValue(
    atom,
    it => it.find(it => it.data.followMeEnabled === true)?.userId === userId
  )
  return followMeIsEnabled
}

export const usePresenceAtomUserToFollow = (): UserId | undefined => {
  const atom = usePresenceAtom()

  const userToFollow = useSelectAtomValue(atom, it => it.find(it => it.data.followMeEnabled === true)?.userId)

  return userToFollow
}

export const usePresenceAtomUsersFollowingFollowMe = (): UserId[] => {
  const atom = usePresenceAtom()

  const usersFollowingFollowMe = useSelectAtomValue(atom, it =>
    it.filter(it => it.data.isFollowingFollowMe === true).map(it => it.userId)
  )

  return usersFollowingFollowMe
}
