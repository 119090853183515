import { graphql } from 'sierra-client/api/graphql/gql'
import { graphQuery } from 'sierra-client/api/hooks/use-graphql-query'

export const useRevokeIssuedCertificate = (): ((issuedCertificateId: string) => Promise<string>) => {
  const revokeIssuedCertificate = async (issuedCertificateId: string): Promise<string> => {
    const result = await graphQuery(
      graphql(`
        mutation revokeIssuedCertificate($issuedCertificateId: IssuedCertificateId!) {
          revokeIssuedCertificate(issuedCertificateId: $issuedCertificateId) {
            issuedCertificate {
              id
            }
          }
        }
      `),
      {
        issuedCertificateId,
      }
    )
    return result.revokeIssuedCertificate.issuedCertificate.id
  }

  return revokeIssuedCertificate
}
