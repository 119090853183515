import { getFlag } from 'sierra-client/config/global-config'
import { isInElement } from 'sierra-client/views/v3-author/command'
import { Editor } from 'slate'

export const isEnabled = ({ editor }: { editor: Editor }): boolean => {
  const isMatrixEnabled = getFlag('matrix-block')
  const isInValidBlock = !isInElement(editor, ['flip-card', 'question-card', 'vertical-stack'])

  return isMatrixEnabled && isInValidBlock
}
