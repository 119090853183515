import { UseQueryResult } from '@tanstack/react-query'
import { CachedQueryOptions, useCachedQuery } from 'sierra-client/state/api'
import type { GetCoursePermissionSettingsResponse } from 'sierra-domain/api/author-v2'
import { NanoId12 } from 'sierra-domain/api/nano-id'
import { XRealtimeAuthorGetCoursePermissionSettings } from 'sierra-domain/routes'

export const useCoursePermissionSettings = <TSelectData = GetCoursePermissionSettingsResponse>(
  courseId: NanoId12,
  options: CachedQueryOptions<GetCoursePermissionSettingsResponse, TSelectData> = {}
): UseQueryResult<TSelectData> => {
  return useCachedQuery(XRealtimeAuthorGetCoursePermissionSettings, { courseId }, options)
}
