import { useIsTextTruncated } from 'sierra-ui/components'
import { Text } from 'sierra-ui/primitives'
import { token } from 'sierra-ui/theming'
import styled from 'styled-components'

const TruncateAndFadeContainer = styled.div<{ $isTruncated: boolean }>`
  position: relative;
  max-height: 120px;
  overflow: hidden;

  &:after {
    content: '';
    display: ${p => (p.$isTruncated ? 'block' : 'none')};
    height: 100%;
    width: 100%;
    background: linear-gradient(transparent 15%, ${token('surface/default')} 100%);
    position: absolute;
    inset: 0;
  }
`

const PreviewContainerText = styled(Text).attrs({
  size: 'small',
  color: 'foreground/secondary',
})`
  white-space: pre-wrap;
  /* We need to limit the height in order to detect when the text is truncated. */
  height: 100%;
`

export const TruncatedAndFadedText: React.FC<{ text: string }> = ({ text }) => {
  const { isTruncatedHeight: isTruncated, setTextRef } = useIsTextTruncated()

  return (
    <TruncateAndFadeContainer $isTruncated={isTruncated}>
      <PreviewContainerText ref={setTextRef}>{text}</PreviewContainerText>
    </TruncateAndFadeContainer>
  )
}
