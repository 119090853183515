import { useQueryClient } from '@tanstack/react-query'
import { useCallback, useMemo, useState } from 'react'
import { useCachedQuery } from 'sierra-client/state/api'
import { EnrollmentRuleContext, EnrollmentRule as EnrollmentRuleType } from 'sierra-domain/api/manage'
import { XRealtimeAdminProgramsListEnrollmentRules } from 'sierra-domain/routes'

export const useEnrollmentRulesByContext = (
  context: EnrollmentRuleContext
): { rules: Array<EnrollmentRuleType>; filterOutRule: (id: string) => void } => {
  const result = useCachedQuery(XRealtimeAdminProgramsListEnrollmentRules, { context }).data?.enrollmentRules

  const [removedRuleIds, setRemovedRuleIds] = useState<string[]>([])

  const rules = useMemo(
    () => (result ?? []).filter(rule => !removedRuleIds.includes(rule.id)),
    [result, removedRuleIds]
  )

  return {
    rules,
    filterOutRule: (id: string) => {
      setRemovedRuleIds(curr => [...curr, id])
    },
  }
}

export const useInvalidateEnrollmentRuleList = (): ((context: EnrollmentRuleContext) => Promise<void>) => {
  const queryClient = useQueryClient()

  return useCallback(
    context =>
      queryClient.invalidateQueries({
        queryKey: [XRealtimeAdminProgramsListEnrollmentRules.path, { context }],
      }),
    [queryClient]
  )
}
