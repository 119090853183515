import { atomWithStorage } from 'sierra-client/state/storage'
import z from 'zod'

export const PictureInPictureState = z.union([
  z.object({
    state: z.literal('closed'),
  }),
  z.object({
    state: z.literal('chat'),
  }),
  z.object({
    state: z.literal('side'),
    size: z.union([z.literal('small'), z.literal('medium'), z.literal('large')]),
  }),
])

export type PictureInPictureState = z.infer<typeof PictureInPictureState>

export const pictureInPictureAtom = atomWithStorage<PictureInPictureState>(
  'picture-in-picture',
  {
    state: 'closed',
  },
  PictureInPictureState,
  { getOnInit: true }
)
