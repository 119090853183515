import { default as React, useCallback, useState } from 'react'
import { useIsDarkTheme } from 'sierra-client/components/common/color-theme-preference'
import { useNotif } from 'sierra-client/components/common/notifications'
import {
  ShortcutMenuContext,
  useShortcutMenuDispatch,
  useShortcutMenuState,
} from 'sierra-client/components/shortcut-menu/context'
import * as logic from 'sierra-client/components/shortcut-menu/logic'
import { resolveIndex } from 'sierra-client/components/shortcut-menu/resolve-index'
import { Result } from 'sierra-client/components/shortcut-menu/root-search'
import { useRootSearch } from 'sierra-client/components/shortcut-menu/root-search/use-root-search'
import {
  SearchBar,
  SearchBarContainer,
  SearchGroup,
  SearchResult,
  SearchResults,
} from 'sierra-client/components/shortcut-menu/search-ui'
import { RunOptions } from 'sierra-client/components/shortcut-menu/types'
import { config } from 'sierra-client/config/global-config'
import { AppThemeTokenProvider } from 'sierra-client/config/token-provider'
import { usePost } from 'sierra-client/hooks/use-post'
import { useTranslation } from 'sierra-client/hooks/use-translation'
import { getGlobalRouter } from 'sierra-client/router'
import { useDispatch, useSelector } from 'sierra-client/state/hooks'
import { store } from 'sierra-client/state/store'
import { selectIsLoggedIn } from 'sierra-client/state/user/user-selector'
import { FCC } from 'sierra-client/types'
import { assertNever } from 'sierra-domain/utils'
import { Icon, Modal } from 'sierra-ui/components'
import { View } from 'sierra-ui/primitives'
import { Checkbox } from 'sierra-ui/primitives/form/checkbox'
import { token, zIndex } from 'sierra-ui/theming'
import styled from 'styled-components'

const BorderLessView = styled(View)`
  border: none;
`

const StyledModal = styled(Modal)`
  z-index: calc(${zIndex.NOTIFICATIONS} * 2);
  border: none;
  height: fit-content;
  border-color: ${token('border/default')};
  background-color: ${token('surface/default')};

  box-shadow:
    0px 24px 38px 3px rgb(0 0 0 / 4%),
    0px 9px 46px 8px rgb(0 0 0 / 8%),
    0px 11px 15px -7px rgb(0 0 0 / 16%);
`
const Content = styled(View).attrs({ direction: 'column' })`
  height: 100%;
  border: none;
  color: ${token('foreground/primary')};

  &&&& {
    gap: 0;
  }
`

const ChevronRight = styled(Icon).attrs({ iconId: 'chevron--right--small', color: 'grey40' })``

function useRunOptions(): RunOptions {
  const { t } = useTranslation()
  const dispatch = useDispatch()
  const post = usePost()
  const notif = useNotif()

  return { store, t, dispatch, ...post, pushNotif: notif.push }
}

const SearchResultComponent: React.FC<Result> = result => {
  const { t } = useTranslation()
  const runOptions = useRunOptions()
  const text = result.label

  switch (result.type) {
    case 'link':
      return (
        <>
          <BorderLessView alignItems='center' gap='2'>
            <span>{t('notification.open')}</span>
            <ChevronRight />
            {text}
          </BorderLessView>
        </>
      )
    case 'component':
      return (
        <BorderLessView alignItems='center' gap='2'>
          {text}
          <ChevronRight />
        </BorderLessView>
      )
    case 'action':
      return <>{text}</>
    case 'toggle':
      return (
        <BorderLessView justifyContent='space-between' grow>
          {text}
          <Checkbox checked={result.isOn(runOptions)} />
        </BorderLessView>
      )
    default:
      assertNever(result)
  }
}

async function runSearchResult(
  result: Result,
  dispatch: (action: logic.Action) => void,
  runOptions: RunOptions
): Promise<void> {
  if ('disabled' in result && result.disabled === true) return

  switch (result.type) {
    case 'link':
      void dispatch({ type: 'close' })
      await getGlobalRouter().navigate({ to: result.href })
      break
    case 'action':
      void dispatch({ type: 'close' })
      await result.run(runOptions)
      break
    case 'toggle':
      void dispatch({ type: 'close' })
      await result.toggle(runOptions)
      break
    case 'component':
      void dispatch({ type: 'nest', label: result.label })
      break
    default:
      assertNever(result)
  }
}

const ShortcutMenuInner: React.FC = () => {
  const { t } = useTranslation()
  const dispatch = useShortcutMenuDispatch()
  const runOptions = useRunOptions()
  const [searchText, setSearchText] = useState('')
  const searchResults = useRootSearch(searchText)

  const [selectedIndex, setSelectedIndex] = useState(0)

  return (
    <>
      <SearchBarContainer>
        <SearchBar
          placeholder={t('shortcut-menu.default.prompt')}
          value={searchText}
          onChange={text => {
            setSearchText(text)
            setSelectedIndex(0)
          }}
          onIndexChanged={value => {
            setSelectedIndex(prev => resolveIndex(value === 'decrement' ? prev - 1 : prev + 1, searchResults))
          }}
          onResultSelected={() => {
            const selectedSearchResult = searchResults[selectedIndex % searchResults.length]
            if (selectedSearchResult === undefined) return

            void runSearchResult(selectedSearchResult.result, dispatch, runOptions)
          }}
        />
      </SearchBarContainer>

      <SearchResults>
        {searchResults.map(({ id, type, group, result, iconId, disabled }, resultIndex) => {
          return (
            <React.Fragment key={id}>
              {type === 'group-header' && (
                <SearchGroup $selected={selectedIndex % searchResults.length === resultIndex}>
                  {t(logic.groupLabels[group])}
                </SearchGroup>
              )}

              <SearchResult
                onClick={() => {
                  void runSearchResult(result, dispatch, runOptions)
                }}
                iconId={iconId}
                $disabled={disabled}
                $selected={selectedIndex % searchResults.length === resultIndex}
              >
                <SearchResultComponent {...result} />
              </SearchResult>
            </React.Fragment>
          )
        })}
      </SearchResults>
    </>
  )
}

const NestedComponent: FCC = ({ children }) => {
  return (
    <>
      <BorderLessView direction='column' grow overflow='auto' gap='none'>
        {children}
      </BorderLessView>
    </>
  )
}

const AssistantModal: React.FC = () => {
  const { nestedShortcut, isOpen, components } = useShortcutMenuState()

  const dispatch = useShortcutMenuDispatch()

  const resolveNestedComponent = useCallback(
    (label: string) => {
      const Component = components?.[label]

      if (typeof Component === 'function') return <Component />
      else return Component
    },
    [components]
  )

  const isDarkTheme = useIsDarkTheme()

  return (
    <StyledModal
      open={isOpen}
      animation='pop'
      overlayVariant={isDarkTheme ? 'dark' : 'light'}
      onClose={() => {
        void dispatch({ type: 'close' })
      }}
      size={'fit-content'}
    >
      <Content>
        {nestedShortcut === undefined ? (
          <ShortcutMenuInner />
        ) : (
          <NestedComponent>{resolveNestedComponent(nestedShortcut.label)}</NestedComponent>
        )}
      </Content>
    </StyledModal>
  )
}

export const RootShortcutMenu: FCC = ({ children }) => {
  const userIsLoggedIn = useSelector(selectIsLoggedIn)
  const enabled = userIsLoggedIn && !config.scorm.isScorm

  return (
    <AppThemeTokenProvider>
      <ShortcutMenuContext>
        {children}
        {enabled && <AssistantModal />}
      </ShortcutMenuContext>
    </AppThemeTokenProvider>
  )
}
