import { PrimitiveAtom, atom, useAtom, useAtomValue } from 'jotai'
import { useEffect, useMemo } from 'react'
import { usePathname } from 'sierra-client/hooks/router/use-pathname'
import { iife, isDefined } from 'sierra-domain/utils'
import { Icon, IconId, TruncatedTextWithTooltip } from 'sierra-ui/components'
import { View } from 'sierra-ui/primitives'
import { CSSPropTwo, Spacing } from 'sierra-ui/types'
import { useGenerateDomId } from 'sierra-ui/utils/use-generate-dom-id'
import styled from 'styled-components'
import { collapsableSidebarStateAtom } from '../atoms'
import { HeaderItemTitle, HeaderItemWrapper, ItemButton } from './shared/header-item-wrapper'
import { ItemLink, ItemLinkProps, itemLinkCSS } from './shared/item-link'
import { NestedItemList, NestedListItem } from './shared/nested-item'

type AbstractHeaderItem = {
  title: string
  iconId?: IconId
  id?: string
  type: 'label' | 'nested'
  isSelected?: boolean
  tourId?: string
  hidden?: boolean
}

type SimpleButtonHeaderItem = {
  type: 'simple-button-item'
  title: string
  onClick?: () => void
  preComponent?: React.ReactNode
  postComponent?: React.ReactNode
} & (
  | {
      preComponent?: undefined
      iconId: IconId
    }
  | {
      preComponent: React.ReactNode
      iconId?: undefined
    }
)

export type LabelHeaderItem = {
  type: 'label'
  href: string
  postComponent?: React.ReactNode
  preComponent?: React.ReactNode
  preload?: boolean
  openInNewTab?: boolean
  onClick?: () => void
} & AbstractHeaderItem

export type NestedHeaderItem = {
  type: 'nested'
  items: LabelHeaderItem[]
  expandedAtom: PrimitiveAtom<boolean>
} & AbstractHeaderItem

const StartAlignedTruncatedText = styled(TruncatedTextWithTooltip)`
  text-align: start;
`

const PlainHeaderItem: React.FC<{
  title: string
  isSelected: boolean
  iconId?: IconId
  postComponent?: React.ReactNode
  preComponent?: React.ReactNode
  bold?: boolean
  preload?: boolean
  hidden?: boolean
}> = ({ title, isSelected, iconId, postComponent, bold = true, hidden, preComponent }) => {
  const sidebarState = useAtomValue(collapsableSidebarStateAtom)

  if (hidden === true) {
    return <></>
  }

  return (
    <>
      {preComponent}
      {isDefined(iconId) && (
        <Icon iconId={iconId} color={isSelected ? 'foreground/primary' : 'foreground/secondary'} />
      )}
      <HeaderItemTitle animate={sidebarState}>
        <StartAlignedTruncatedText
          bold={bold}
          color={isSelected ? 'foreground/primary' : 'foreground/secondary'}
        >
          {title}
        </StartAlignedTruncatedText>
        {postComponent}
      </HeaderItemTitle>
    </>
  )
}

export const LabelHeaderItem: React.FC<{
  title: string
  href?: string
  iconId?: IconId
  bold?: boolean
  onClick?: () => void
  isSelected?: boolean
  tourId?: string
  preload?: boolean
  hidden?: boolean
  preComponent?: React.ReactNode
  openInNewTab?: boolean
}> = ({
  title,
  href,
  iconId,
  bold = true,
  onClick,
  isSelected,
  tourId,
  hidden,
  preload,
  preComponent,
  openInNewTab,
}) => {
  const pathname = usePathname()
  const isExactRoute = pathname === href

  if (hidden === true) {
    return <></>
  }

  return (
    <HeaderItemWrapper id={tourId} $isSelected={isSelected ?? isExactRoute} onClick={onClick}>
      <ItemLink gap='10' padding='8 10' href={href} openInNewTab={openInNewTab} preload={preload}>
        <PlainHeaderItem
          title={title}
          isSelected={isSelected ?? isExactRoute}
          iconId={iconId}
          bold={bold}
          preComponent={preComponent}
        />
      </ItemLink>
    </HeaderItemWrapper>
  )
}

const SearchHeaderItemLink = styled(View).attrs({ as: 'button' })<ItemLinkProps>`
  ${itemLinkCSS};

  width: 100%;
  background-color: transparent;
  cursor: pointer;
`

const SimpleButtonHeaderItem: React.FC<SimpleButtonHeaderItem> = ({
  onClick,
  iconId,
  title,
  preComponent,
  postComponent,
}) => (
  <HeaderItemWrapper $isSelected={false} onClick={onClick}>
    <SearchHeaderItemLink $gap='10' $padding='8 10'>
      <PlainHeaderItem
        isSelected={false}
        iconId={iconId}
        title={title}
        bold
        preComponent={preComponent}
        postComponent={postComponent}
      />
    </SearchHeaderItemLink>
  </HeaderItemWrapper>
)

type HeaderItemProps = (LabelHeaderItem | NestedHeaderItem | SimpleButtonHeaderItem) & {
  'data-testid'?: string
  'nestedItemPadding'?: CSSPropTwo<Spacing>
  'preload'?: boolean
}

const EmptyLi = styled.li`
  list-style: none;
`

export const HeaderItem: React.FC<HeaderItemProps> = props => {
  const { type, nestedItemPadding } = props
  const isNested = type === 'nested' && props.items.length > 0

  const sidebarState = useAtomValue(collapsableSidebarStateAtom)

  const noopAtom = useMemo(() => {
    return atom(false)
  }, [])

  const [areItemsExpanded, setAreItemsExpanded] = useAtom(isNested ? props.expandedAtom : noopAtom)
  const nestedItemListDomId = useGenerateDomId()

  useEffect(() => {
    if (sidebarState === 'collapsed') {
      setAreItemsExpanded(false)
    }
  }, [sidebarState, setAreItemsExpanded])

  if (type === 'label') {
    return <LabelHeaderItem {...props} />
  }

  if (type === 'simple-button-item') {
    return <SimpleButtonHeaderItem {...props} />
  }

  return (
    <EmptyLi>
      <ItemButton
        isSelected={false}
        onClick={() => setAreItemsExpanded(previous => !previous)}
        data-testid={props['data-testid']}
        aria-expanded={areItemsExpanded}
        aria-controls={nestedItemListDomId}
        {...props}
      >
        <PlainHeaderItem
          bold
          isSelected={false}
          postComponent={
            isNested ? (
              <Icon
                iconId={areItemsExpanded ? 'chevron--up--small' : 'chevron--down--small'}
                color='foreground/muted'
              />
            ) : undefined
          }
          {...props}
        />
      </ItemButton>
      <NestedItemList open={areItemsExpanded} id={nestedItemListDomId}>
        {props.items.map(({ preComponent, ...item }) => (
          <NestedListItem
            nestedItemPadding={nestedItemPadding}
            key={item.id}
            preComponent={iife(() => {
              if (isDefined(item.iconId)) {
                return { type: 'icon', iconId: item.iconId }
              }

              if (isDefined(preComponent)) {
                return { type: 'pre-component', preComponent }
              }

              return undefined
            })}
            {...item}
          />
        ))}
      </NestedItemList>
    </EmptyLi>
  )
}
