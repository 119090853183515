import { atom } from 'jotai'
import { EmailTemplate, StepHighlight } from 'sierra-client/views/manage/programs/staggered-assignments/types'
import {
  emailPlaceholders,
  emptyEmailTemplate,
} from 'sierra-client/views/manage/programs/staggered-assignments/utils'

export const editOutlinePanelOpenAtom = atom<boolean>(false)

export const highlightedStepIdAtom = atom<StepHighlight | undefined>(undefined)

export const timedHighlightedStepIdAtom = atom<StepHighlight | undefined>(undefined)

export const isSavingOutlineAtom = atom<boolean>(false)

export const addContentStepPanelOpenAtom = atom<boolean>(false)

export const emailNotificationOpenAtom = atom<boolean>(false)

export const emailTemplateIdAtom = atom<string | undefined>(undefined)

export const initialEmailTemplateAtom = atom<EmailTemplate>(emptyEmailTemplate)

export const emailTemplateAtom = atom<EmailTemplate>(emptyEmailTemplate)

export const previewEmailTemplateAtom = atom<EmailTemplate>(emailPlaceholders)

export const newEmailTemplateIds = atom<string[]>([])

export const renderedEmailAtom = atom<string>('<html/>')

export const previewElementAtom = atom<HTMLIFrameElement | null>(null)

export const emailPlaceholdersAtom =
  atom<Pick<EmailTemplate, 'headline' | 'description' | 'buttonText'>>(emailPlaceholders)
