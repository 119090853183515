import seedrandom from 'seedrandom'

export const seededShuffle = <T = unknown>(array: T[], seed: string): T[] => {
  const arrayClone = array.slice()

  const seededRandom = seedrandom.alea(seed)

  let currentIndex = arrayClone.length

  // While there remain elements to shuffle.
  while (currentIndex !== 0) {
    // Pick a remaining element.
    const randomIndex = Math.floor(seededRandom() * currentIndex)
    currentIndex--

    // And swap it with the current element.
    // eslint-disable-next-line @typescript-eslint/ban-ts-comment
    // @ts-ignore
    ;[arrayClone[currentIndex], arrayClone[randomIndex]] = [arrayClone[randomIndex], arrayClone[currentIndex]]
  }

  return arrayClone
}
