import { isEditableYjsEditor } from 'sierra-client/editor'
import { useEditorTextActions } from 'sierra-client/features/text-actions/editor-text-actions-context'
import { EditorTextActionsState } from 'sierra-client/features/text-actions/types'
import { useStableFunction } from 'sierra-client/hooks/use-stable-function'
import { storeRelativeRangeLocal } from 'sierra-client/views/commenting/utils'
import { nanoid12 } from 'sierra-domain/nanoid-extensions'
import { textInNodes } from 'sierra-domain/slate-util'
import { useSlateStatic } from 'slate-react'

type UseOpenEditorTextActionsModalForSelection = (params: {
  mode: EditorTextActionsState['mode']
  initialInstructions?: string
}) => void

export const useOpenEditorTextActionsModalForSelection = (): UseOpenEditorTextActionsModalForSelection => {
  const editor = useSlateStatic()
  const { setEditorTextActionsState } = useEditorTextActions()

  return useStableFunction(({ mode, initialInstructions } = {}) => {
    const selection = editor.selection

    if (selection === null) return
    if (!isEditableYjsEditor(editor)) return

    const key = nanoid12()
    storeRelativeRangeLocal({ editor, key, range: selection })

    const sourceText = editor
      .fragment(selection)
      .map(node => textInNodes([node]).join(''))
      .join('\n')

    setEditorTextActionsState({ sourceText, key, initialInstructions, mode })
  })
}
