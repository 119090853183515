import { useEffect } from 'react'
import chatSfx from 'sierra-client/assets/sounds/chat.mp3'
import reactionSfx from 'sierra-client/assets/sounds/reaction.mp3'
import { useSanaSound } from 'sierra-client/components/liveV2/hooks/use-sana-sound'
import { useLiveSessionIdContext } from 'sierra-client/components/liveV2/live-session-id-provider'
import { useRightSidebarContext } from 'sierra-client/components/liveV2/live-sidebar-provider'
import { usePrevious } from 'sierra-client/hooks/use-previous'
import { selectLastMessageInChat, selectUnreadMessagesCount } from 'sierra-client/state/chat/selectors'
import { useSelector } from 'sierra-client/state/hooks'
import { selectUserId } from 'sierra-client/state/user/user-selector'
import { ScopedChatId } from 'sierra-domain/collaboration/types'

export const ChatMessageSfx = ({ chatId, userId }: { chatId: ScopedChatId; userId: string }): null => {
  const { state, isOpen } = useRightSidebarContext()
  const isChatOpen = isOpen && state === 'chat_open'

  const [playChatSound] = useSanaSound(chatSfx, { volume: 0.04 })
  const [playReactionSound] = useSanaSound(reactionSfx, { volume: 0.04 })

  const unreadCount = useSelector(state => selectUnreadMessagesCount(state, chatId, 'root'))

  const message = useSelector(state => selectLastMessageInChat(state, chatId))
  const previousMessage = usePrevious(message)

  useEffect(() => {
    if (
      message !== undefined &&
      message.userId !== userId &&
      message.id !== previousMessage?.id &&
      message.responseToMessageId === undefined &&
      (!isChatOpen || document.visibilityState === 'hidden') &&
      unreadCount <= 1
    ) {
      // Play sounds when window is inactive or chat is closed and
      message.type === 'emoji' ? playReactionSound() : playChatSound()
    }
  }, [message, previousMessage, playChatSound, playReactionSound, isChatOpen, unreadCount, userId])

  return null
}

export const AudioSfx = (): JSX.Element | null => {
  const { liveSessionId } = useLiveSessionIdContext()
  const userId = useSelector(selectUserId)
  const chatId = ScopedChatId.fromId(liveSessionId)

  if (userId === undefined) return null

  return (
    <>
      <ChatMessageSfx chatId={chatId} userId={userId} />
    </>
  )
}
