import React from 'react'
import { useTranslation } from 'sierra-client/hooks/use-translation'
import { selectCourseById } from 'sierra-client/state/content/selectors'
import { useSelector } from 'sierra-client/state/hooks'
import { useSelfPacedFiles } from 'sierra-client/views/self-paced/files-provider'
import { Text, View } from 'sierra-ui/primitives'

export const ScormCourseFallback: React.FC = () => {
  const { t } = useTranslation()
  const { flexibleContentId } = useSelfPacedFiles()
  const courseTitle = useSelector(state => selectCourseById(state, flexibleContentId))?.settings.title

  return (
    <View direction='column' padding='16'>
      <Text bold color='foreground/primary'>
        {t('helper.great-work')}
      </Text>
      <Text color='foreground/secondary'>
        {t('helper.completed-everything', {
          title: courseTitle,
        })}
      </Text>
    </View>
  )
}
