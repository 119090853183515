import { useQueryClient } from '@tanstack/react-query'
import { t } from 'i18next'
import { useCallback, useState } from 'react'
import { ActionModal } from 'sierra-client/components/common/modals/action-modal'
import { AssignModal } from 'sierra-client/components/common/modals/multi-assign-modal'
import { parseModalToContentAssignment } from 'sierra-client/components/common/modals/multi-assign-modal/utils'
import { useNotif } from 'sierra-client/components/common/notifications'
import { typedPost } from 'sierra-client/state/api'
import { fetchLiveSessions } from 'sierra-client/state/flexible-content/actions'
import { NewCourseSettingsModal } from 'sierra-client/views/course-settings/course-settings-modal'
import { DueDateDialog, useDueDate } from 'sierra-client/views/manage/components/due-date'
import { DeleteCourseModal } from 'sierra-client/views/manage/courses/components/modals'
import { UseCourseDetailsData } from 'sierra-client/views/manage/courses/use-manage-course-details'
import {
  UserModalActions,
  UserModalActionsProps,
} from 'sierra-client/views/manage/users/components/user-modal-actions'
import { queryKeyTabularContentUsers } from 'sierra-client/views/manage/utils/query-keys'
import { DueDateAbsolute, DueDateGroup, DueDateSource } from 'sierra-domain/api/manage'
import { CourseId, LiveContentId } from 'sierra-domain/api/nano-id'
import { UserId } from 'sierra-domain/api/uuid'
import { XRealtimeAdminAssignmentsUnassignFromCourse } from 'sierra-domain/routes'

export type CourseModalActions =
  | ((
      | (UserModalActionsProps['action'] & { targetUserId: UserId })
      | {
          modal: 'enroll-users' | 'enroll-programs' | 'delete'
        }
      | {
          modal: 'unassign-users'
          ids: UserId[]
        }
      | {
          modal: 'assign-live-sessions'
          ids: string[]
        }
      | {
          modal: 'due-date-group'
          groupId: string
          currentDueDate?: DueDateGroup
        }
      | {
          modal: 'due-date-user'
          userId: UserId
          currentDueDate?: DueDateAbsolute
          origin?: DueDateSource
        }
    ) & { onDone?: () => void })
  | undefined

export type CourseActionModalControl = {
  open: (modalState: CourseModalActions | undefined) => void
  close: () => void
  state: CourseModalActions | undefined
}

export const useCourseActionModals = (): CourseActionModalControl => {
  const [action, setAction] = useState<CourseModalActions | undefined>()

  return {
    open: setAction,
    close: () => setAction(undefined),
    state: action,
  }
}

type Props = {
  control: CourseActionModalControl
  contentId: CourseId
  courseDetails: UseCourseDetailsData
}

export const CourseActionModals: React.FC<Props> = ({
  control: { state, close },
  contentId,
  courseDetails,
}) => {
  const { courseData, deleteCourse, fetchCourse, isLoading, fetchProgramAssignments } = courseDetails
  const { setUsersDueDate, assignWithDueDate } = useDueDate()
  const notifications = useNotif()
  const queryClient = useQueryClient()

  const unassignFromCourse = useCallback(
    async (courseId: CourseId, { userIds }: { userIds: UserId[] }) => {
      await typedPost(XRealtimeAdminAssignmentsUnassignFromCourse, {
        courseId,
        userIds,
      })

      await fetchCourse() // updates counts
    },
    [fetchCourse]
  )

  return (
    <>
      {state?.modal === 'unassign-users' && (
        <ActionModal
          open
          isLoading={isLoading}
          onClose={close}
          primaryAction={async (): Promise<void> => {
            await unassignFromCourse(contentId, { userIds: state.ids })
            close()
            notifications.push({
              type: 'custom',
              level: 'success',
              body: t('notifications.done'),
            })
          }}
          primaryActionLabel={t('dictionary.unassign')}
          title={t('manage.unassign-users.title', { count: state.ids.length })}
          deleteAction
        />
      )}

      {['enroll-users', 'enroll-groups'].includes(state?.modal ?? '') && (
        <AssignModal
          isOpen
          config={{
            subjectType: 'course',
            panes: 'user-and-user-group',
            activePane: 'user',
            showDueDates: true,
            subjectsSupportAssignmentSettings:
              courseData?.kind === 'native:live' || courseData?.kind === 'native:event-group',
            autoAssignmentAvailable: courseData?.kind === 'native:live',
            onSave: async selections => {
              const result = await assignWithDueDate(
                parseModalToContentAssignment([contentId], 'course', selections)
              )
              if (result?.error !== undefined) return

              notifications.push({ type: 'assigned' })
              close()
              void Promise.all([fetchCourse(), fetchProgramAssignments(contentId)])
              state?.onDone?.()
            },
          }}
          subjects={contentId}
          title={t('dictionary.enrollments')}
          onClose={() => {
            close()
          }}
        />
      )}
      {state?.modal === 'assign-live-sessions' && (
        <AssignModal
          isOpen
          config={{
            subjectType: 'live-session',
            panes: 'user-and-user-group',
            activePane: 'user',
            onSave: async selections => {
              const result = await assignWithDueDate(
                parseModalToContentAssignment(state.ids, 'live-session', selections)
              )
              if (result?.error !== undefined) return

              close()
              void fetchLiveSessions({ liveContentId: contentId as LiveContentId })
              state.onDone?.()
            },
          }}
          subjects={state.ids}
          title={t('dictionary.enrollments')}
          onClose={close}
        />
      )}
      <DeleteCourseModal
        isOpen={state?.modal === 'delete'}
        onClose={close}
        onDelete={async () => {
          await deleteCourse()
        }}
      />

      <NewCourseSettingsModal onSave={fetchCourse} />

      <DueDateDialog
        open={state?.modal === 'due-date-user'}
        onClose={close}
        value={state?.modal === 'due-date-user' ? state.currentDueDate : undefined}
        type='user'
        forceDisableRemove={state?.modal === 'due-date-user' ? state.origin !== 'direct' : undefined}
        onChange={async value => {
          if (state?.modal !== 'due-date-user' || value?.type === 'relative') return

          await setUsersDueDate(
            [state.userId],
            [{ dueDate: value?.date, content: { type: 'course', id: contentId } }]
          )
          void queryClient.invalidateQueries({ queryKey: queryKeyTabularContentUsers(contentId) })
          state.onDone?.()
          close()
        }}
      />
      {state && 'targetUserId' in state && (
        <UserModalActions
          targetUserId={state.targetUserId}
          action={state}
          onClose={close}
          onDone={() => {
            void fetchCourse()
            void queryClient.invalidateQueries({ queryKey: queryKeyTabularContentUsers(contentId) })
            state.onDone?.()
          }}
        />
      )}
    </>
  )
}
