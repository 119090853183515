import { AnimatePresence, motion } from 'framer-motion'
import React, { useMemo, useState } from 'react'
import { ActionModal } from 'sierra-client/components/common/modals/action-modal'
import { useOrganizationPermissions } from 'sierra-client/hooks/use-permissions'
import { useTranslation } from 'sierra-client/hooks/use-translation'
import * as settingsActions from 'sierra-client/state/author-course-settings/actions'
import * as settingsState from 'sierra-client/state/author-course-settings/slice'
import { useDispatch, useSelector } from 'sierra-client/state/hooks'
import { useExercises } from 'sierra-client/views/course-settings/components/useExercises'
import {
  CollaboratorsTab,
  CourseSettingsTab,
  ExportTab,
  GlossaryTab,
  TranslateTab,
} from 'sierra-client/views/course-settings/tabs'
import { CertificatesTab } from 'sierra-client/views/course-settings/tabs/certificates-tab'
import { CollaboratorGroupsTab } from 'sierra-client/views/course-settings/tabs/collaborator-groups-tab'
import { ExercisesTab } from 'sierra-client/views/course-settings/tabs/exercises-tab'
import { SkillsTab } from 'sierra-client/views/course-settings/tabs/skills-tab'
import { TabID } from 'sierra-client/views/course-settings/types'
import { withModal } from 'sierra-client/views/manage/utils/with-modal'
import { CourseId } from 'sierra-domain/api/nano-id'
import { VerticalTabs } from 'sierra-ui/components/vertical-tabs'
import { VerticalTabItem } from 'sierra-ui/components/vertical-tabs/vertical-tabs'
import { Button, Heading, IconButton, View } from 'sierra-ui/primitives'
import { token } from 'sierra-ui/theming'
import styled, { css } from 'styled-components'

const SAVE_CONTAINER_HEIGHT = '84px'

const Container = styled.div`
  height: 100%;
  padding-left: 32px;
`

const CloseButton = styled(IconButton).attrs({ variant: 'transparent', iconId: 'close' })`
  position: absolute;
  right: 12px;
  top: 8px;
`

const ContentContainer = styled.div<{ extraBottomPadding: boolean }>`
  height: 100%;
  display: flex;
  flex-direction: column;

  padding: 42px 48px 0;

  ${p =>
    p.extraBottomPadding &&
    css`
      padding-bottom: ${SAVE_CONTAINER_HEIGHT};
    `}

  overflow-y: auto;
  overflow-x: hidden;
`

const SaveButtonContainer = styled(motion.div)`
  background: ${token('surface/default')};
  position: absolute;
  bottom: 0;
  left: 0;
  right: 0;
  display: flex;
  gap: 8px;
  align-items: center;
  justify-content: flex-end;
  padding-right: 48px;
  height: ${SAVE_CONTAINER_HEIGHT};
  border-top: 1px solid ${token('border/default')};
`

type CourseSettingsModalInternalProps = {
  onSave: () => void
  enabledTabs: TabID[]
  activeTab: TabID
  setActiveTab: (tabId: TabID) => void
  courseId: CourseId
  hasDraft: boolean
}

const ModalWrapper = withModal<CourseSettingsModalInternalProps>(
  {
    disableScrollbarGutter: true,
    overlayVariant: 'light',
    size: { width: 950, height: 650 },
  },
  ({ onClose, onSave, enabledTabs, activeTab, setActiveTab, courseId, hasDraft }) => {
    const { t } = useTranslation()

    const tabItems = useMemo(() => {
      const allTabs: VerticalTabItem<TabID>[] = [
        {
          type: 'tab',
          id: 'course-settings',
          icon: 'settings',
          label: t('dictionary.general'),
          content: (
            <ContentContainer extraBottomPadding={hasDraft}>
              <CourseSettingsTab courseId={courseId} onSave={onSave} onClose={onClose} />
            </ContentContainer>
          ),
        },
        {
          type: 'tab',
          id: 'collaborators',
          icon: 'face--add',
          label: t('dictionary.collaborator-plural'),
          content: (
            <ContentContainer extraBottomPadding={hasDraft}>
              <CollaboratorsTab courseId={courseId} onSave={onSave} onClose={onClose} />
            </ContentContainer>
          ),
        },
        {
          type: 'tab',
          id: 'collaborator-groups',
          icon: 'user--group',
          label: t('dictionary.collaborator-group-plural'),
          content: (
            <ContentContainer extraBottomPadding={hasDraft}>
              <CollaboratorGroupsTab courseId={courseId} onSave={onSave} onClose={onClose} />
            </ContentContainer>
          ),
        },
        {
          type: 'tab',
          id: 'export',
          icon: 'export',
          label: t('dictionary.export'),
          content: (
            <ContentContainer extraBottomPadding={hasDraft}>
              <ExportTab courseId={courseId} onSave={onSave} onClose={onClose} />
            </ContentContainer>
          ),
        },
        {
          type: 'tab',
          id: 'glossary',
          icon: 'character--sentence-case',
          label: t('dictionary.glossary'),
          content: (
            <ContentContainer extraBottomPadding={hasDraft}>
              <GlossaryTab courseId={courseId} onSave={onSave} onClose={onClose} />
            </ContentContainer>
          ),
        },
        {
          type: 'tab',
          id: 'translate',
          icon: 'translate',
          label: t('dictionary.translate'),
          content: (
            <ContentContainer extraBottomPadding={hasDraft}>
              <TranslateTab courseId={courseId} onSave={onSave} onClose={onClose} />
            </ContentContainer>
          ),
        },
        {
          type: 'tab',
          id: 'certificates',
          icon: 'trophy',
          label: t('dictionary.certificate-plural'),
          content: (
            <ContentContainer extraBottomPadding={hasDraft}>
              <CertificatesTab courseId={courseId} onSave={onSave} onClose={onClose} />
            </ContentContainer>
          ),
        },
        {
          type: 'tab',
          id: 'exercises',
          icon: 'task',
          label: t('dictionary.homework-plural'),
          content: (
            <ContentContainer extraBottomPadding={hasDraft}>
              <ExercisesTab courseId={courseId} onSave={onSave} onClose={onClose} />
            </ContentContainer>
          ),
        },
        {
          type: 'tab',
          id: 'skills',
          icon: 'skill',
          label: t('dictionary.skills'),
          content: (
            <ContentContainer extraBottomPadding={hasDraft}>
              <SkillsTab courseId={courseId} onSave={onSave} onClose={onClose} />
            </ContentContainer>
          ),
        },
      ]

      return allTabs.filter(({ id }) => enabledTabs.includes(id))
    }, [courseId, enabledTabs, hasDraft, onClose, onSave, t])

    return (
      <>
        <Container>
          <CloseButton onClick={onClose} />
          <VerticalTabs
            tabBarPreComponent={
              <View marginBottom='24' marginTop='32'>
                <Heading size='h4' bold>
                  {t('dictionary.settings')}
                </Heading>
              </View>
            }
            value={activeTab}
            onChange={setActiveTab}
            items={tabItems}
          />
        </Container>
        <AnimatePresence>
          {hasDraft && (
            <SaveButtonContainer
              variants={{
                hidden: { y: 84, opacity: 0 },
                visible: { y: 0, opacity: 1, transition: { duration: 0.15, ease: [0.25, 0.1, 0.25, 1] } },
              }}
              initial='hidden'
              animate='visible'
              exit='hidden'
            >
              <Button variant='secondary' onClick={onClose}>
                {t('dictionary.cancel')}
              </Button>
              <Button variant='primary' onClick={onSave}>
                {t('dictionary.save')}
              </Button>
            </SaveButtonContainer>
          )}
        </AnimatePresence>
      </>
    )
  }
)

type NewCourseSettingsModalInternalProps = {
  onSave?: () => void
  onClose?: () => void
  courseId: CourseId
}

const NewCourseSettingsModalInternal: React.FC<NewCourseSettingsModalInternalProps> = ({
  onSave = () => {},
  onClose = () => {},
  courseId,
}) => {
  const { t } = useTranslation()
  const dispatch = useDispatch()
  const activeTab = useSelector(settingsState.selectors.currentView)
  const courseKind = useSelector(settingsState.selectors.selectCourseKind)
  const orgPermissions = useOrganizationPermissions()
  const userCanCreateCertificates = orgPermissions.has('CREATE_CERTIFICATES')
  const userCanManageSkills = orgPermissions.has('MANAGE_SKILLS')
  const skillsEnabled = userCanManageSkills
  const hasUnsavedSettingsChanges = useSelector(settingsState.selectors.hasUnsavedSettingsChanges)
  const [confirmClose, setConfirmClose] = useState(false)

  const queryResult = useExercises(courseId)
  const exercises = queryResult.data?.homeworks ?? []
  const exercisesExist = exercises.length > 0

  const enabledTabs = useMemo<TabID[]>(() => {
    let tabs: TabID[] = []

    if (courseKind === 'linkedin' || courseKind === 'link' || courseKind === 'scorm') {
      tabs = ['course-settings', 'collaborators']
    } else if (courseKind === 'native:self-paced') {
      tabs = ['course-settings', 'collaborators', 'export', 'glossary', 'translate']
      if (exercisesExist) {
        tabs = [...tabs, 'exercises']
      }
    } else if (courseKind === 'native:live') {
      tabs = ['course-settings', 'collaborators', 'export', 'translate']
    } else if (courseKind === 'native:course-group' || courseKind === 'scorm:course-group') {
      tabs = ['course-settings', 'collaborators', 'collaborator-groups', 'export']
    } else if (courseKind === 'native:event-group') {
      tabs = ['course-settings', 'collaborators']
    }

    if (userCanCreateCertificates) {
      tabs = [...tabs, 'certificates']
    }

    if (skillsEnabled) {
      tabs = [...tabs, 'skills']
    }

    return tabs
  }, [courseKind, userCanCreateCertificates, skillsEnabled, exercisesExist])

  const close = (): void => {
    if (!hasUnsavedSettingsChanges) {
      void dispatch(settingsActions.setView(null))
      onClose()
    } else {
      setConfirmClose(true)
    }
  }

  const save = async (): Promise<void> => {
    await dispatch(settingsActions.saveSettings({ notify: true }))
    onSave()
  }

  const setActiveTab = (tabId: TabID): void => {
    dispatch(settingsActions.setView(tabId))
  }

  if (!activeTab) return null

  return (
    <>
      <ModalWrapper
        open={true}
        enabledTabs={enabledTabs}
        activeTab={activeTab}
        setActiveTab={setActiveTab}
        courseId={courseId}
        onClose={close}
        onSave={save}
        hasDraft={hasUnsavedSettingsChanges}
      />

      <ActionModal
        open={confirmClose}
        title={t('author.discard-changes')}
        primaryActionLabel={t('dictionary.discard')}
        onClose={() => {
          setConfirmClose(false)
        }}
        primaryAction={() => {
          void dispatch(settingsActions.resetDraftSettings())
          void dispatch(settingsActions.setView(null))
          onClose()
        }}
        deleteAction
      >
        {t('author.about-to-discard-unsaved-changes')}
      </ActionModal>
    </>
  )
}

type NewCourseSettingModalProps = { onSave?: () => void; onClose?: () => void }

export const NewCourseSettingsModal: React.FC<NewCourseSettingModalProps> = ({
  onSave = () => {},
  onClose = () => {},
}) => {
  const courseId = useSelector(settingsState.selectors.currentCourseId)

  if (!courseId) return null

  return <NewCourseSettingsModalInternal courseId={courseId} onSave={onSave} onClose={onClose} />
}
