import React from 'react'
import { RouterLink } from 'sierra-client/components/common/link'
import { Thumbnail } from 'sierra-client/components/common/thumbnail'
import { useAssetResolver } from 'sierra-client/hooks/use-resolve-asset'
import { useTranslation } from 'sierra-client/hooks/use-translation'
import { HeatmapContent } from 'sierra-domain/api/heatmap'
import { CourseId, PathId } from 'sierra-domain/api/nano-id'
import { AssetContext } from 'sierra-domain/asset-context'
import { assertNever, iife } from 'sierra-domain/utils'
import { Tooltip, TruncatedText } from 'sierra-ui/components'
import { View } from 'sierra-ui/primitives'
import styled from 'styled-components'

export const HeaderContainer = styled(View).attrs({ gap: '4', justifyContent: 'space-between' })``

const StyledThumbnail = styled(Thumbnail)`
  --size: 2.5rem;
  width: var(--size);
  min-width: var(--size);
  height: var(--size);
  min-height: var(--size);
  border-radius: 0.75rem;
`

const FlexRow = styled.div`
  display: flex;
  justify-content: flex-start;
  align-items: flex-start;
  gap: 0.5rem;
`

const LinkWrapper = styled.a`
  cursor: pointer;
`

export type ColumnHeaderProps = {
  row: HeatmapContent
  href?: string
}

export const ColumnHeader: React.FC<ColumnHeaderProps> = ({ row, href }) => {
  const { t } = useTranslation()

  const assetResolver = useAssetResolver({ size: 'default' })

  const imageSrc = (): string => {
    if (row.type === 'certificate') {
      return row.image ?? ''
    } else {
      const assetContext: AssetContext = iife(() => {
        switch (row.type) {
          case 'course':
            return { type: 'course', courseId: CourseId.parse(row.id) }
          case 'path':
            return { type: 'path', pathId: PathId.parse(row.id) }
          default:
            assertNever(row)
        }
      })

      return assetResolver(row.image, assetContext)
    }
  }

  const titleElementText = (
    <Tooltip title={t('manage.heatmap.show-details')}>
      <TruncatedText lines={2} size='small' bold as='span' color='grey40'>
        {row.title}
      </TruncatedText>
    </Tooltip>
  )

  return (
    <View alignItems='center' gap='xxsmall'>
      <StyledThumbnail image={imageSrc()} />
      <View direction='column' gap='none' margin='none'>
        <FlexRow>
          {href === undefined ? (
            titleElementText
          ) : (
            <RouterLink href={href}>
              <LinkWrapper>{titleElementText}</LinkWrapper>
            </RouterLink>
          )}
        </FlexRow>
      </View>
    </View>
  )
}
