import React from 'react'
import { ActionModal } from 'sierra-client/components/common/modals/action-modal'
import { useTranslation } from 'sierra-client/hooks/use-translation'

export const SendTestEmailRateLimitedAlert: React.FC<{
  onClose: () => void
  tryAfterMs: number
}> = ({ onClose, tryAfterMs }) => {
  const { t } = useTranslation()

  return (
    <ActionModal
      title={t('manage.program.program-details.steps.add-email.send-test-email-rate-limited-alert.headline')}
      open={tryAfterMs > 0}
      onClose={onClose}
      isLoading={false}
    >
      {t('manage.program.program-details.steps.add-email.send-test-email-rate-limited-alert.description', {
        seconds: `${Math.trunc(tryAfterMs / 1000)}`,
      })}
    </ActionModal>
  )
}
