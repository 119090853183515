import { CancelablePromise } from 'sierra-client/utils/cancelable-promise'
import { OutputControls } from 'sierra-domain/api/author-v2'
import { NanoId12 } from 'sierra-domain/api/nano-id'
import { AssetContext } from 'sierra-domain/asset-context'
import { Video } from 'sierra-domain/v3-author'

/**
 * All currently supported mime types for new files to be uploaded.
 * See the `MimeType` for the all existing mime types.
 */
export type FileTypes =
  | 'application/pdf'
  | 'image/gif'
  | 'image/jpeg'
  | 'image/png'
  | 'image/webp'
  | 'video/mp4'
  | 'application/zip'
  | 'application/x-zip'
  | 'application/x-zip-compressed'
  | 'x-zip-compressed'

export type UploadMediaProgressType = 'uploading' | 'processing'
export type UploadMediaProgressHandler = (type: UploadMediaProgressType, value: number) => void

export type UploadMediaWithProgress<T> = (
  blob: File | Blob,
  assetContext: AssetContext,
  onProgressUpdate: UploadMediaProgressHandler
) => Promise<T>

export type UploadMedia<T> = (blob: Blob, assetContext: AssetContext) => Promise<T>

export type UploadVideo = UploadMedia<Video['video']>

/**
 * UploadMedia can throw this and it will be handled by the media uploader.
 * The error message will be displayed to the user
 */
export class FileTooBigError extends Error {
  constructor(message: string) {
    super(message)
  }
}

export type PdfUploadResult = { pdfId: NanoId12 }

export type PdfUploadStatus =
  | { type: 'idle' }
  | {
      type: 'uploading' | 'processing'
      file: File
      request: CancelablePromise<PdfUploadResult>
      progress: number
    }
  | {
      type: 'error'
      title: string
      message: string
    }
  | { type: 'success'; pdfId: NanoId12 }

export const acceptedPdfFiles = 'application/pdf'

export type PdfGenerationStartState = {
  pdfId: NanoId12
  controls: OutputControls | undefined
}
export type StartGeneratingPdf = (_: PdfGenerationStartState) => void
