import { Thumbnail as CommonThumbnail } from 'sierra-client/components/common/thumbnail'
import { v2_breakpoint } from 'sierra-ui/theming/breakpoints'
import styled, { css } from 'styled-components'

export const Thumbnail = styled(CommonThumbnail)<{ isPassedOrCompleted?: boolean }>`
  border-radius: 1.25rem;
  margin-right: 0.5rem;
  transition: all 0.1s cubic-bezier(0.25, 0.1, 0.25, 1);

  @media (min-width: ${v2_breakpoint.desktop_small}) {
    height: 5.5rem;
    width: 5.5rem;
  }

  ${p =>
    p.isPassedOrCompleted === true &&
    css`
      opacity: 0.4;
    `}
`
