import { errorLogger } from 'sierra-client/error/error-logger'
import { NodeId } from 'sierra-domain/flexible-content/identifiers'
import { File, FlexibleContentJsonData, Folder } from 'sierra-domain/flexible-content/types'
import { assertNever } from 'sierra-domain/utils'
import { View } from 'sierra-ui/primitives'
import styled from 'styled-components'

const RenderNode = ({
  nodeId,
  content,
  renderFile,
  renderFolder,
}: {
  nodeId: NodeId
  content: FlexibleContentJsonData
  renderFile: (file: File) => JSX.Element
  renderFolder: (folder: Folder, children?: JSX.Element[]) => JSX.Element
}): JSX.Element | null => {
  const node = content.nodeMap[nodeId]

  if (!node) {
    errorLogger.error('No node found with ID', { nodeId })
    return null
  }

  switch (node.type) {
    case 'file':
      return renderFile(node)
    case 'folder':
      return renderFolder(
        node,
        node.nodeIds.map(nodeId => (
          <RenderNode
            renderFile={renderFile}
            renderFolder={renderFolder}
            key={nodeId}
            nodeId={nodeId}
            content={content}
          />
        ))
      )
    case 'link':
      return <p>Node type ({node.type}) not implemented</p>
    default:
      assertNever(node)
  }
}

type FileTreeProps = {
  rootAsFolder?: boolean
  content: FlexibleContentJsonData
  renderFile: (file: File) => JSX.Element
  renderFolder: (folder: Folder, children?: JSX.Element[]) => JSX.Element
}

const MaxWidthView = styled(View)`
  max-width: 100%;
`

export const FileTree = ({
  rootAsFolder = false,
  content,
  renderFile,
  renderFolder,
}: FileTreeProps): JSX.Element => {
  const root = content.nodeMap['folder:root']

  if (root?.type !== 'folder') throw new Error('Root folder missing')

  return (
    <MaxWidthView grow gap='none' direction='column'>
      {rootAsFolder ? (
        <RenderNode
          renderFile={renderFile}
          renderFolder={renderFolder}
          nodeId='folder:root'
          content={content}
        />
      ) : (
        root.nodeIds.map(nodeId => (
          <RenderNode
            renderFile={renderFile}
            renderFolder={renderFolder}
            key={nodeId}
            nodeId={nodeId}
            content={content}
          />
        ))
      )}
    </MaxWidthView>
  )
}
