import React, { useCallback } from 'react'
import { useTranslation } from 'sierra-client/hooks/use-translation'
import { CategoryHeadline } from 'sierra-client/views/manage/components/user-attributes/flows/components/layout'
import { useComposeUserInvitationConfig } from 'sierra-client/views/manage/components/user-attributes/flows/invite-users/hooks/use-compose-user-invitation-config'
import { InvitationDomainChoicesInput } from 'sierra-client/views/manage/components/user-attributes/flows/invite-users/panels/set-attributes-panel/attributes/domain-renderers/choices'
import { InvitationDomainTypeOfInput } from 'sierra-client/views/manage/components/user-attributes/flows/invite-users/panels/set-attributes-panel/attributes/domain-renderers/type-of'
import { InvitationDomainWithParentChoicesInput } from 'sierra-client/views/manage/components/user-attributes/flows/invite-users/panels/set-attributes-panel/attributes/domain-renderers/with-parent-choices'
import { useInvitationDomains } from 'sierra-client/views/manage/components/user-attributes/hooks/use-invitation-domains'
import {
  AttributeRowList,
  CommonInputSwitch,
  CustomAttributeRow,
} from 'sierra-client/views/manage/components/user-attributes/renderers/common'
import { UserInvitationDomainRef } from 'sierra-domain/user-attributes/user-invitation-domain-ref'
import { UserCustomAttributeDomainRep } from 'sierra-domain/user-attributes/user-invitation-domain-rep'

const InvitationDomainInputSwitch: React.FC<{
  domainRep: UserCustomAttributeDomainRep
}> = ({ domainRep }) => (
  <CommonInputSwitch
    domainRep={domainRep}
    ChoicesRenderer={InvitationDomainChoicesInput}
    TypeOfRenderer={InvitationDomainTypeOfInput}
    WithParentChoicesRenderer={InvitationDomainWithParentChoicesInput}
  />
)

export const InvitationCustomAttributesSection: React.FC = () => {
  const { customAttributeDomains } = useInvitationDomains()
  const { t } = useTranslation()

  const { hasAttributeErrors, editingEmails } = useComposeUserInvitationConfig()
  const singleEmail = editingEmails[0]

  const showWarning = useCallback(
    (domainRef: UserInvitationDomainRef) =>
      singleEmail !== undefined ? hasAttributeErrors(singleEmail, domainRef) : false,
    [hasAttributeErrors, singleEmail]
  )

  /* None-editable domains should not be shown */
  const editableCustomAttributeDomains = customAttributeDomains.filter(
    domainRep => domainRep.editableInternally === true
  )

  if (editableCustomAttributeDomains.length <= 0) {
    return <></>
  }

  return (
    <AttributeRowList>
      <CategoryHeadline>{t('manage.custom-attributes')}</CategoryHeadline>
      {editableCustomAttributeDomains.map((domainRep, index) => (
        <CustomAttributeRow
          id={
            index === 0 ? 'impact-intercom-product-tour--edit-attributes-first-custom-attribute' : undefined
          }
          InputSwitch={InvitationDomainInputSwitch}
          key={domainRep.ref.key}
          domainRep={domainRep}
          showWarning={showWarning(domainRep.ref)}
        />
      ))}
    </AttributeRowList>
  )
}
