import { Text } from 'sierra-ui/primitives'
import styled from 'styled-components'

const StyledText = styled(Text)`
  display: inline-block;
  margin-bottom: 16px;
`

export const ManageHeadline: React.FC<{ children: string | undefined }> = ({ children }) => (
  <StyledText as='span' size='large' bold>
    {children === '' || children === undefined ? <>&nbsp;</> : children}
  </StyledText>
)
