import { forwardRef } from 'react'
import { And, Or, Predicate, Single, Value } from 'sierra-client/lib/filter'
import { ellipse } from 'sierra-client/lib/filter/components/common'
import * as UI from 'sierra-client/lib/filter/ui'
import { assertNever } from 'sierra-domain/utils'
import { Tooltip } from 'sierra-ui/components'

export const ValueAnchor = forwardRef<
  HTMLDivElement,
  { label: string; onClick?: () => void; readOnly?: boolean }
>(({ label, readOnly = false, ...rest }, anchorRef) => {
  return (
    <UI.Value.Wrapper readOnly={readOnly} ref={anchorRef} {...rest}>
      <Tooltip title={label}>
        <UI.Value.Text>{ellipse(24, label)}</UI.Value.Text>
      </Tooltip>
    </UI.Value.Wrapper>
  )
})

export const equal = (a: Value, b: Value): boolean => {
  if (a.type === 'value.file-id' && b.type === 'value.file-id') {
    return a.courseId === b.courseId && a.fileId === b.fileId
  }
  if (a.type === 'value.exercise-id' && b.type === 'value.exercise-id') {
    return a.courseId === b.courseId && a.fileId === b.fileId && a.exerciseId === b.exerciseId
  }
  if (
    a.type === 'value.file-id' ||
    b.type === 'value.file-id' ||
    a.type === 'value.exercise-id' ||
    b.type === 'value.exercise-id'
  ) {
    return false // Different types for some reason
  }

  return a.type === b.type && a.value === b.value
}

export const fromPredicate = (p: Predicate): Value[] => {
  switch (p.type) {
    case 'predicate.or':
    case 'predicate.and':
      return p.values
    case 'predicate.single':
      return [p.value]
    case 'predicate.none':
      return []
  }
}

export const addPredValue = (pred: Predicate, value: Value): Predicate => {
  switch (pred.type) {
    case 'predicate.none':
      return pred
    case 'predicate.single':
      return Single(value)
    case 'predicate.and':
      return pred.values.some(v => equal(v, value)) ? pred : And([...pred.values, value])
    case 'predicate.or':
      return pred.values.some(v => equal(v, value)) ? pred : Or([...pred.values, value])
    default:
      assertNever(pred)
  }
}

export const setPredValue = (pred: Predicate, value: Value): Predicate => {
  switch (pred.type) {
    case 'predicate.and':
      return And([value])
    case 'predicate.or':
      return Or([value])
    case 'predicate.single':
    case 'predicate.none':
      return pred
    default:
      assertNever(pred)
  }
}

export const resetPredValue = (pred: Predicate): Predicate => {
  switch (pred.type) {
    case 'predicate.and':
      return And([])
    case 'predicate.or':
      return Or([])
    case 'predicate.single':
    case 'predicate.none':
      return pred
    default:
      assertNever(pred)
  }
}

export const removePredValue = (pred: Predicate, value: Value): Predicate => {
  switch (pred.type) {
    case 'predicate.and':
      return And(pred.values.filter(v => !equal(v, value)))
    case 'predicate.or':
      return Or(pred.values.filter(v => !equal(v, value)))
    case 'predicate.single':
    case 'predicate.none':
      return pred
    default:
      assertNever(pred)
  }
}

export const predicateIsEmpty = (predicate: Predicate): boolean => fromPredicate(predicate).length === 0

export const valueId = (value: Value): string => {
  switch (value.type) {
    case 'value.none':
      return `${value.type}:`
    case 'value.exercise-id':
      return `course:${value.courseId}:file:${value.fileId}:exercise:${value.exerciseId}`
    case 'value.file-id':
      return `course:${value.courseId}:file:${value.fileId}`
    default:
      return `${value.type}:${value.value.toString()}`
  }
}
