import React from 'react'
import { useTokenValue } from 'sierra-ui/theming'

export const BarChartPreviewSVG: React.FC = () => {
  const getToken = useTokenValue()
  return (
    <svg width='100%' height='100%' viewBox='0 0 301 163' fill='none' xmlns='http://www.w3.org/2000/svg'>
      <path d='M6 157L5.99999 21' stroke={getToken('org/primary')} strokeWidth='8' strokeLinecap='round' />
      <path d='M34.9 157L34.9 49' stroke={getToken('org/primary')} strokeWidth='8' strokeLinecap='round' />
      <path d='M63.8 157L63.8 78' stroke={getToken('org/primary')} strokeWidth='8' strokeLinecap='round' />
      <path d='M92.7 157L92.7 134' stroke={getToken('org/primary')} strokeWidth='8' strokeLinecap='round' />
      <path d='M121.6 157L121.6 78' stroke={getToken('org/primary')} strokeWidth='8' strokeLinecap='round' />
      <path d='M150.5 157L150.5 21' stroke={getToken('org/primary')} strokeWidth='8' strokeLinecap='round' />
      <path d='M179.4 157L179.4 78' stroke={getToken('org/primary')} strokeWidth='8' strokeLinecap='round' />
      <path d='M208.3 157L208.3 58' stroke={getToken('org/primary')} strokeWidth='8' strokeLinecap='round' />
      <path d='M237.2 157L237.2 114' stroke={getToken('org/primary')} strokeWidth='8' strokeLinecap='round' />
      <path d='M266.1 157L266.1 4' stroke={getToken('org/primary')} strokeWidth='8' strokeLinecap='round' />
      <path d='M295 157L295 46' stroke={getToken('org/primary')} strokeWidth='8' strokeLinecap='round' />
    </svg>
  )
}
