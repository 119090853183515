import { useCallback } from 'react'
import { getFlag } from 'sierra-client/config/global-config'
import { useCreatePageContext } from 'sierra-client/views/flexible-content/create-page-context'
import { useTracking } from 'sierra-client/views/v3-author/scenario/tracking'
import { assertScenarioCard, ScenarioFile } from 'sierra-client/views/v3-author/scenario/utils'
import { CourseId } from 'sierra-domain/api/nano-id'
import { apply } from 'sierra-domain/editor/operations'
import {
  ScenarioDataSource,
  ScenarioDisposition,
  ScenarioGeneratedData,
  VoiceType,
} from 'sierra-domain/flexible-content/types'
import { assert } from 'sierra-domain/utils'

export const useAllowScenarioCard = (): boolean => {
  return getFlag('scenario-card')
}

export const useScenarioCardActions = ({
  file,
  courseId,
}: {
  file: ScenarioFile
  courseId: CourseId
}): {
  updateScenario: (update: (file: ScenarioFile) => void) => void
  setInputScenario: (title: string, description: string) => void
  setLearnerGoal: (goal: string) => void
  setLearnerRole: (role: string) => void
  setAssistantRole: (role: string) => void
  switchAssistantAndLearner: () => void
  setAvatar: (avatar: { characterId: string; avatar: string; voice: VoiceType }) => void
  setDataSource: (ds: Array<ScenarioDataSource>) => void
  changeGenerated: {
    learnerScenario: (scenario: string) => void
    learnerGoal: (goal: string) => void
    learnerRole: (role: string) => void
    assistantScenario: (scenario: string) => void
    assistantRole: (role: string) => void
    assissantGoal: (goal: string) => void
    scenarioTitle: (title: string) => void
    scenarioDescription: (description: string) => void
  }
  setGenerated: (generated: ScenarioGeneratedData) => void
  setAssistantDisposition: (dispositions: Array<ScenarioDisposition>) => void
} => {
  const { operationState } = useCreatePageContext()
  const tracking = useTracking({ courseId: courseId, fileId: file.id })

  const updateScenario = useCallback(
    (update: (file: ScenarioFile) => void): void => {
      apply(operationState, {
        type: 'update-files',
        fileIds: [file.id],
        update: file => {
          assertScenarioCard(file)
          update(file)
        },
      })
    },
    [file.id, operationState]
  )

  const setInputScenario = useCallback(
    (title: string, description: string): void => {
      updateScenario(file => {
        file.data.input.scenario = { type: 'custom', title, description }
      })
      tracking.inputPrompt.setDescription({ description })
    },
    [tracking.inputPrompt, updateScenario]
  )

  const setLearnerGoal = useCallback(
    (goal: string): void => {
      updateScenario(file => {
        file.data.input.learnerGoal = goal
      })
      tracking.inputPrompt.setCompletionCriteriaSuggestion({ criteria: goal })
    },
    [tracking.inputPrompt, updateScenario]
  )

  const setUserRole = useCallback(
    (role: string) => {
      updateScenario(file => {
        file.data.input.learnerRole = role
      })
      tracking.inputPrompt.setLearnerRole({ role })
    },
    [tracking.inputPrompt, updateScenario]
  )

  const setAssistantRole = useCallback(
    (role: string) => {
      updateScenario(file => {
        file.data.input.assistantRole = role
      })
      tracking.inputPrompt.setAssistantRole({ role })
    },
    [tracking.inputPrompt, updateScenario]
  )

  const switchAssistantAndUser = useCallback(() => {
    updateScenario(file => {
      assert(file.data.generated !== undefined)
      const assistantContext = file.data.generated.assistantContext
      const userContext = file.data.generated.userContext

      file.data.generated.userContext = {
        goals: assistantContext.goals,
        role: assistantContext.role,
        scenario: assistantContext.scenario,
      }
      file.data.generated.assistantContext = {
        goals: userContext.goals,
        role: userContext.role,
        scenario: userContext.scenario,
      }
    })
    tracking.editGeneratedPrompt.switchLearnerAndAssistant()
  }, [tracking.editGeneratedPrompt, updateScenario])

  const setAvatar = useCallback(
    ({ characterId, avatar, voice }: { characterId: string; avatar: string; voice: VoiceType }) => {
      updateScenario(file => {
        assert(file.data.input.character !== undefined)
        file.data.input.character = { ...file.data.input.character, name: characterId, avatar, voice }
      })
      tracking.inputPrompt.setAssistantAvatar({ avatar })
    },
    [tracking.inputPrompt, updateScenario]
  )

  const setDataSource = useCallback(
    (ds: Array<ScenarioDataSource>) => {
      updateScenario(file => {
        file.data.input.dataSources = ds
      })
      tracking.inputPrompt.setContext({ context: ds })
    },
    [tracking.inputPrompt, updateScenario]
  )

  const setGenerated = useCallback(
    (generated: ScenarioGeneratedData) => {
      updateScenario(file => {
        file.data.generated = generated
      })
    },
    [updateScenario]
  )

  const changeGeneratedUserScenario = useCallback(
    (scenario: string) => {
      updateScenario(file => {
        assert(file.data.generated !== undefined)
        file.data.generated.userContext = { ...file.data.generated.userContext, scenario }
      })
      tracking.editGeneratedPrompt.editLearnerScenario({ scenario })
    },
    [tracking.editGeneratedPrompt, updateScenario]
  )

  const changeGeneratedUserGoal = useCallback(
    (goal: string) => {
      updateScenario(file => {
        assert(file.data.generated !== undefined)
        file.data.generated.userContext = {
          ...file.data.generated.userContext,
          goals: [{ id: 'Custom-Goal', title: 'Custom Goal', description: goal }],
        }
      })
      tracking.editGeneratedPrompt.editLearnerObjective({ objective: goal })
    },
    [tracking.editGeneratedPrompt, updateScenario]
  )

  const changeGeneratedUserRole = useCallback(
    (role: string) => {
      updateScenario(file => {
        assert(file.data.generated !== undefined)
        file.data.generated.userContext = { ...file.data.generated.userContext, role }
      })
      tracking.editGeneratedPrompt.editLearnerRole({ role })
    },
    [tracking.editGeneratedPrompt, updateScenario]
  )

  const changeGeneratedAssistantScenario = useCallback(
    (scenario: string) => {
      updateScenario(file => {
        assert(file.data.generated !== undefined)
        file.data.generated.assistantContext = { ...file.data.generated.assistantContext, scenario }
      })
      tracking.editGeneratedPrompt.editAssistantScenario({ scenario })
    },
    [tracking.editGeneratedPrompt, updateScenario]
  )

  const changeGeneratedAssistantRole = useCallback(
    (role: string) => {
      updateScenario(file => {
        assert(file.data.generated !== undefined)
        file.data.generated.assistantContext = { ...file.data.generated.assistantContext, role }
      })
      tracking.editGeneratedPrompt.editAssistantRole({ role })
    },
    [tracking.editGeneratedPrompt, updateScenario]
  )

  const changeGeneratedAssistantGoal = useCallback(
    (goal: string) => {
      updateScenario(file => {
        assert(file.data.generated !== undefined)
        file.data.generated.assistantContext = {
          ...file.data.generated.assistantContext,
          goals: [{ id: 'Custom-Goal', title: 'Custom Goal', description: goal }],
        }
      })
      tracking.editGeneratedPrompt.editAssistantObjective({ objective: goal })
    },
    [tracking.editGeneratedPrompt, updateScenario]
  )

  const changeGeneratedScenarioTitle = useCallback(
    (title: string) => {
      updateScenario(file => {
        assert(file.data.generated !== undefined)
        file.data.generated.scenario = { ...file.data.generated.scenario, title: title }
      })
      tracking.editGeneratedPrompt.editScenarioTitle({ title })
    },
    [tracking.editGeneratedPrompt, updateScenario]
  )

  const changeGeneratedScenarioDescription = useCallback(
    (description: string) => {
      updateScenario(file => {
        assert(file.data.generated !== undefined)
        file.data.generated.scenario = { ...file.data.generated.scenario, description: description }
      })
      tracking.editGeneratedPrompt.editScenarioDescription({ description })
    },
    [tracking.editGeneratedPrompt, updateScenario]
  )

  const setAssistantDisposition = useCallback(
    (dispositions: Array<ScenarioDisposition>) => {
      updateScenario(file => {
        assert(file.data.input.character !== undefined)
        file.data.input.character = { ...file.data.input.character, disposition: dispositions }
      })
      tracking.inputPrompt.setAssistantBehaviour({
        behaviour: dispositions.map(d => `${d.disposition}-${d.degree.current}`).join(','),
      })
    },
    [tracking.inputPrompt, updateScenario]
  )

  return {
    setGenerated,
    updateScenario,
    setLearnerGoal,
    setInputScenario,
    setLearnerRole: setUserRole,
    setAssistantRole,
    switchAssistantAndLearner: switchAssistantAndUser,
    setAvatar,
    setDataSource,
    changeGenerated: {
      learnerScenario: changeGeneratedUserScenario,
      learnerGoal: changeGeneratedUserGoal,
      learnerRole: changeGeneratedUserRole,
      assistantScenario: changeGeneratedAssistantScenario,
      assistantRole: changeGeneratedAssistantRole,
      assissantGoal: changeGeneratedAssistantGoal,
      scenarioTitle: changeGeneratedScenarioTitle,
      scenarioDescription: changeGeneratedScenarioDescription,
    },
    setAssistantDisposition,
  }
}
