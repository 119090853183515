import { Logger } from 'sierra-client/core/logging/logger'

export const courseGenerationButtonClicked = Logger.trackLoggerWithExtra(
  'course_generation_button_clicked',
  input => {
    return {
      ...input,
    }
  }
)

export const courseGenerationClickedSaveFileButton = Logger.trackLoggerWithExtra(
  'course_generation_save_file_button_clicked',
  input => {
    return {
      ...input,
    }
  }
)

export const courseGenerationClickedSaveAllButton = Logger.trackLoggerWithExtra(
  'course_generation_saved_all_button_clicked',
  input => {
    return {
      ...input,
    }
  }
)

export const courseGenerationClickedRegenerateFileButton = Logger.trackLoggerWithExtra(
  'course_generation_regenerate_file_button_clicked',
  input => {
    return {
      ...input,
    }
  }
)

export const courseGenerationRegenerateButtonClicked = Logger.trackLoggerWithExtra(
  'course_generation_regenerate_button_clicked',
  input => {
    return {
      ...input,
    }
  }
)

export const courseGenerationPauseButtonClicked = Logger.trackLoggerWithExtra(
  'course_generation_pause_button_clicked',
  input => {
    return {
      ...input,
    }
  }
)

export const courseGenerationContinueButtonClicked = Logger.trackLoggerWithExtra(
  'course_generation_continue_button_clicked',
  input => {
    return {
      ...input,
    }
  }
)

// TODO: this should probably be somewhere else
export const createAnnotatePdfButtonClicked = Logger.trackLoggerWithExtra(
  'create_annotate_pdf_button_clicked',
  input => {
    return {
      ...input,
    }
  }
)

// TODO: this should probably be somewhere else
export const createAnnotatePdfSaveButtonClicked = Logger.trackLoggerWithExtra(
  'create_annotate_pdf_save_button_clicked',
  input => {
    return {
      ...input,
    }
  }
)
