import {
  NotificationContainer,
  UserAvatar,
  useUsername,
} from 'sierra-client/features/activity-center/notifications/components'
import { NotificationTitle } from 'sierra-client/features/activity-center/notifications/components/notification-title'
import { HomeNotification } from 'sierra-domain/api/learn'
import { Icon } from 'sierra-ui/components'
import { Text, View } from 'sierra-ui/primitives'
import { token } from 'sierra-ui/theming'
import styled from 'styled-components'

const DashboardContainer = styled(View)`
  gap: 8px;
  background: ${token('surface/soft')};
  border-radius: 12px;
  padding: 10px;
  width: 100%;
`

const StyledIcon = styled(Icon).attrs({
  iconId: 'growth',
  color: 'blueVivid',
  size: 'size-12',
})`
  width: 20px;
  height: 20px;
  border-radius: 6px;
  background: ${token('surface/default')};
  border: 1px solid ${token('border/default')};
`

export const DashboardSharedNotification: React.FC<{
  notification: Extract<HomeNotification, { type: 'dashboard-shared' }>
}> = ({ notification }) => {
  const { dashboard } = notification
  const username = useUsername(dashboard.sharedByUserId)

  return (
    <NotificationContainer
      notification={notification}
      icon={<UserAvatar userId={dashboard.sharedByUserId} />}
      cardUrl={`/manage/insights/dashboards/${dashboard.id}`}
      title={
        <NotificationTitle
          untranslated='Dashboard shared <subtitle>by {{username}}</subtitle>'
          values={{ username }}
        />
      }
      content={
        <DashboardContainer>
          <StyledIcon />
          <Text>{dashboard.title}</Text>
        </DashboardContainer>
      }
    />
  )
}
