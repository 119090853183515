import { useState } from 'react'
import { useLiveSessionContext } from 'sierra-client/components/liveV2/contexts/live-session-data'
import { LIVE_HELP_PAGE_URL } from 'sierra-client/config/links'
import { Logging } from 'sierra-client/core/logging'
import { errorLogger } from 'sierra-client/error/error-logger'
import { useTranslation } from 'sierra-client/hooks/use-translation'
import { useIsMobile } from 'sierra-client/state/browser/selectors'
import { useDispatch } from 'sierra-client/state/hooks'
import { FCC } from 'sierra-client/types'
import { MenuItem, Modal, Panel } from 'sierra-ui/components'
import { Button, Text, View } from 'sierra-ui/primitives'
import { SingleSelectDropdown } from 'sierra-ui/primitives/menu-dropdown'
import { spacing } from 'sierra-ui/theming'
import { fonts } from 'sierra-ui/theming/fonts'
import styled from 'styled-components'

const ActionLink = styled.a`
  font-weight: ${fonts.weight.bold};
  margin-left: ${spacing['4']};
`

const DeviceText = styled(View)`
  font-weight: ${fonts.weight.bold};
  padding-right: 1rem;
  padding-bottom: 0.5rem;
  padding-top: 1rem;
`

const BUG_CATEGORIES = ['audio', 'video', 'connection', 'other'] as const

type ResponsiveModalProps = {
  open: boolean
  onClose: () => void
}

const ResponsiveModal: FCC<ResponsiveModalProps> = ({ children, open, onClose }) => {
  const isMobile = useIsMobile()
  if (isMobile) {
    return (
      <Panel open={open} padding='medium'>
        {children}
      </Panel>
    )
  }
  return (
    <Modal open={open} onClose={onClose} size='fit-content' padding='medium'>
      {children}
    </Modal>
  )
}

export const BugReportModal: React.FC<{ open: boolean; onCloseModal: () => void }> = ({
  open,
  onCloseModal,
}) => {
  const { t } = useTranslation()
  const dispatch = useDispatch()
  const liveSession = useLiveSessionContext()
  const [selectedCategoryItem, setSelectedCategoryItem] = useState<MenuItem | undefined>(undefined)

  const onClose = (): void => {
    setSelectedCategoryItem(undefined)
    onCloseModal()
  }

  const report = (): void => {
    if (selectedCategoryItem === undefined) return

    errorLogger.askForUserReport('Live Session Error Report', { tags: { category: selectedCategoryItem.id } })
    void dispatch(
      Logging.liveSession.bugReported({
        bugCategory: selectedCategoryItem.id,
        sessionTitle: liveSession.data.title,
      })
    )
    onClose()
  }

  return (
    <ResponsiveModal open={open} onClose={onClose}>
      <View direction='column' gap='xsmall'>
        <View direction='row'>
          <Text size='large' bold>
            {t('live-session-page.report-issue-modal__title')}
          </Text>
        </View>
        <View direction='column' gap='2'>
          <Text size='regular'>
            {t('live-session-page.report-issue-modal__body-text')}
            <ActionLink href={LIVE_HELP_PAGE_URL} target='_blank' rel='noreferrer'>
              {t('live-session-page.report-issue-modal__body-text-link-to-help-page')}
            </ActionLink>
          </Text>
          <Text size='regular'>{t('live-session-page.report-issue-modal__body-text-after-link')}</Text>
          <DeviceText>{t('live-session-page.report-issue-modal__dropdown-label')}</DeviceText>
          <View>
            <View>
              <SingleSelectDropdown
                placeholder={t('live-session-page.report-issue-modal__dropdown-placeholder')}
                selectedItem={selectedCategoryItem}
                menuItems={BUG_CATEGORIES.map(category => ({
                  type: 'label',
                  id: category,
                  label: t(`live-session-page.report-issue-modal__dropdown-option-label--${category}`),
                }))}
                onSelect={setSelectedCategoryItem}
              />
            </View>
          </View>
        </View>
        <View>
          <View gap='xsmall'>
            <Button disabled={selectedCategoryItem === undefined} onClick={report}>
              {t('live-session-page.report-issue-modal__primary-button')}
            </Button>
            <Button variant='secondary' onClick={onClose}>
              {t('live-session-page.report-issue-modal__close-button')}
            </Button>
          </View>
        </View>
      </View>
    </ResponsiveModal>
  )
}
