import { createSelector } from '@reduxjs/toolkit'
import _ from 'lodash'
import { createCachedSelector } from 're-reselect'
import { ChatState } from 'sierra-client/state/chat/types'
import { RootState } from 'sierra-client/state/types'
import { UserId } from 'sierra-domain/api/uuid'
import { EmojiMessage, Message } from 'sierra-domain/chat'
import { ScopedChatId } from 'sierra-domain/collaboration/types'
import { asNonNullable } from 'sierra-domain/utils'

const selectState = (state: RootState): ChatState => state.chat

const selectChats = createSelector(selectState, state => state.chats)

export const selectChat = createSelector(
  [selectChats, (state: unknown, chatId: ScopedChatId) => chatId],
  (chatDocs, chatId) => chatDocs[chatId]
)

const selectMessageMap = createSelector(selectChat, chat => chat?.messages)

type UserMessageGroup = {
  id: string
  type: 'user'
  userId: UserId
  timeSent: string
  messageIds: string[]
}

export type EmojiMessageGroup = {
  id: string
  type: 'emoji'
  userIds: UserId[]
  timeSent: string
  messageIds: string[]
  emoji: string
}

export type MessageGroup = UserMessageGroup | EmojiMessageGroup

export const selectLastNoOfMessagesForEachUser = createSelector(
  [selectMessageMap, (state, chatId, noOfMessages: number) => noOfMessages],
  (messages, noOfMessages) =>
    messages === undefined
      ? {}
      : _.chain(messages)
          .values()
          .sortBy('timeSent')
          .groupBy(message => message.userId)
          .mapValues(userMessages => _.slice(userMessages, -noOfMessages))
          .value()
)

export const selectLastMessageInChat = createSelector(selectMessageMap, messages =>
  messages === undefined ? undefined : _.chain(messages).values().sortBy('timeSent').last().value()
)

export const selectLastNoMessagesInChat = createSelector(
  [
    selectMessageMap,
    (state, chatId, noOfMessages: number) => noOfMessages,
    (state, chatId, noOfMessages: number, exclude: string[]) => exclude,
  ],
  (messages, noOfMessages, exclude) =>
    messages === undefined
      ? undefined
      : _.chain(messages)
          .values()
          .filter(msg => !exclude.includes(msg.userId))
          .sortBy('timeSent')
          .slice(-noOfMessages)
          .value()
)

const selectBlockThreadRootMessages = createCachedSelector(
  [
    selectMessageMap,
    (_: unknown, chatId: ScopedChatId) => chatId,
    (_: unknown, chatId: ScopedChatId, blockId: string) => blockId,
  ],
  (messageMap, chatId, blockId) => {
    if (messageMap === undefined) return []

    return Object.values(messageMap).filter(
      message =>
        message.type === 'tiptap-comment' &&
        message.contentReference.type === 'block' &&
        message.contentReference.blockId === blockId
    )
  }
)((_, blockId) => blockId)

export const selectUnresolvedBlockThread = createSelector([selectBlockThreadRootMessages], threads => {
  const unresolvedThreads = threads.filter(
    message => message.type === 'tiptap-comment' && message.resolvedAt === undefined
  )
  return unresolvedThreads.length > 0 ? unresolvedThreads[0] : undefined
})

export const selectMessagesInThread = createCachedSelector(
  [
    selectChat,
    (state: unknown, chatId: ScopedChatId) => chatId,
    (state: unknown, chatId: ScopedChatId, threadId: string | undefined) => threadId,
  ],
  (chat, chatId, threadId) => {
    if (chat === undefined || threadId === undefined) return []

    return _.chain(chat.messages)
      .values()
      .filter(
        msg =>
          msg.id === threadId ||
          (threadId === 'root' ? msg.responseToMessageId === undefined : msg.responseToMessageId === threadId)
      )
      .sortBy(msg => msg.timeSent)
      .value()
  }
)((_chat, chatId, threadId) => `${chatId}:${threadId}`)

export const selectMessagesInThreadWithoutRoot = createSelector(
  selectMessagesInThread,
  messages => messages.filter(msg => msg.responseToMessageId !== undefined),
  {
    memoizeOptions: [
      {
        resultEqualityCheck: _.isEqual,
      },
    ],
  }
)

export const selectMessage = createCachedSelector(
  [
    selectMessageMap,
    (state: unknown, chatId: ScopedChatId) => chatId,
    (state: unknown, chatId: ScopedChatId, messageId: string) => messageId,
  ],
  (messageMap, chatId, messageId) => asNonNullable(messageMap?.[messageId])
)((state, chatId, messageId) => `${chatId}:${messageId}`)

export const selectMessageSafe = createCachedSelector(
  [
    selectMessageMap,
    (state: unknown, chatId: ScopedChatId) => chatId,
    (state: unknown, chatId: ScopedChatId, messageId: string) => messageId,
  ],
  (messageMap, chatId, messageId) => messageMap?.[messageId]
)((state, chatId, messageId) => `${chatId}:${messageId}`)

export const selectMessageReactions = createCachedSelector([selectMessage], message =>
  _.chain(message.type === 'emoji' || message.reactions === undefined ? {} : message.reactions)
    .values()
    .sortBy(reaction => reaction.timeSent)
    .groupBy(reaction => reaction.reaction)
    .value()
)((state, chatId, messageId) => `${chatId}:${messageId}`)

const RECENT_MESSAGE_INTERVAL_MS = 60 * 3 * 1000
const RECENT_MESSAGEGROUP_INTERVAL_MS = 30 * 1000

const messageIsRecent = (msg: Message, group: MessageGroup): boolean =>
  new Date(msg.timeSent).getTime() - new Date(group.timeSent).getTime() < RECENT_MESSAGE_INTERVAL_MS

const getLatestMatchingEmojiMessageGroup = (
  msg: EmojiMessage,
  messageGroups: MessageGroup[]
): EmojiMessageGroup | undefined => {
  const recentMessageCutoff = new Date(msg.timeSent).getTime() - RECENT_MESSAGEGROUP_INTERVAL_MS

  for (let i = messageGroups.length - 1; i >= 0; i--) {
    const messageGroup = asNonNullable(messageGroups[i])

    // Ignore non-emoji message groups
    if (messageGroup.type !== 'emoji') {
      continue
    }

    // Ignore groups without matching emoji
    if (messageGroup.emoji !== msg.emoji) {
      continue
    }

    // Return early if we encounter a non-recent message group, since we are iterating backwards.
    if (new Date(messageGroup.timeSent).getTime() < recentMessageCutoff) {
      return undefined
    }

    return messageGroup
  }

  return undefined
}

const createGroup = (msg: Message): MessageGroup =>
  msg.type === 'emoji'
    ? {
        id: msg.id,
        type: 'emoji',
        timeSent: msg.timeSent,
        messageIds: [msg.id],
        userIds: [msg.userId],
        emoji: msg.emoji,
      }
    : {
        id: msg.id,
        type: 'user',
        userId: msg.userId,
        timeSent: msg.timeSent,
        messageIds: [msg.id],
      }

function getMessageGroups(
  messages: Message[],
  filter: 'resolved' | 'unresolved' | null | undefined
): MessageGroup[] {
  const msgs = messages.filter(msg => {
    const isResolvedComment = msg.type === 'tiptap-comment' && msg.resolvedAt !== undefined
    switch (filter) {
      case 'resolved':
        // The resolved filter only includes resolved comments.
        return isResolvedComment
      case 'unresolved':
        // The unresolved filtering is _not_ including resolved comments.
        return !isResolvedComment
      case null:
      case undefined:
        // The default returns all messages, used in chat threads.
        return true
      default:
        filter satisfies never
    }
  })

  const messageGroups: MessageGroup[] = []

  for (let i = 0; i < msgs.length; i++) {
    const msg = asNonNullable(msgs[i])
    if (i === 0) {
      messageGroups.push(createGroup(msg))
      continue
    }

    switch (msg.type) {
      case 'emoji': {
        const latestMatchingEmojiGroup = getLatestMatchingEmojiMessageGroup(msg, messageGroups)

        if (latestMatchingEmojiGroup) {
          latestMatchingEmojiGroup.messageIds.push(msg.id)
          latestMatchingEmojiGroup.userIds = [...new Set(latestMatchingEmojiGroup.userIds).add(msg.userId)]
        } else {
          messageGroups.push(createGroup(msg))
        }
        break
      }
      case 'tiptap-plain':
      case 'tiptap-comment': {
        const currMessageGroup = asNonNullable(_.last(messageGroups))

        if (
          currMessageGroup.type === 'user' &&
          msg.userId === currMessageGroup.userId &&
          messageIsRecent(msg, currMessageGroup)
        ) {
          currMessageGroup.messageIds.push(msg.id)
        } else {
          messageGroups.push(createGroup(msg))
        }
        break
      }
      default:
        msg satisfies never
    }
  }

  return messageGroups
}

/**
 * @TODO this selector is capped to only returning 100 messages max. Real pagination should be added at some point
 */
export const selectMessageGroups = createCachedSelector(
  [
    selectMessagesInThread,
    (state: unknown, chatId: ScopedChatId) => chatId,
    (state: unknown, chatId: ScopedChatId, threadId: string) => threadId,
    (state: unknown, chatId: ScopedChatId, threadId: string, filter: null | 'resolved' | 'unresolved') =>
      filter,
  ],
  (messages: Message[], chatId, threadId, filter): MessageGroup[] => {
    return getMessageGroups(messages, filter)
  }
)((state, chatId, threadId) => `${chatId}:${threadId}`)

/** Returns a record containing the timestamps of the last read messages for each thread of the chat.
 * Only threads that have had their visibility state set at some point will be included.
 */
const selectLastSeenMessageTimestamps = createCachedSelector(
  [selectState, (state: unknown, chatId: ScopedChatId) => chatId],
  (state, chatId) => {
    const result = state.lastSeenMessageTimestamp[chatId]
    return result !== undefined ? result : {}
  }
)((state, chatId) => chatId)

/** The number of unread messages in the given chat- and thread-id combination.
 *
 * The calculation is complicated by the fact that some messages can exist in multiple threads.
 * In particular root messages of threads can be read both via the thread they started, and via the thread they are contained in
 * (the root thread most likely since nested threads are not supported at the moment).
 */
export const selectUnreadMessagesCount = createCachedSelector(
  [
    selectMessageMap,
    selectLastSeenMessageTimestamps,
    (state: unknown, chatId: ScopedChatId, threadId: string) => threadId,
  ],
  (messageMap, lastSeenMessageTimestampsISO, threadId) => {
    const lastSeenMessageTimestamps = new Map(
      Object.entries(lastSeenMessageTimestampsISO).map(([threadId, isoDate]) => [threadId, new Date(isoDate)])
    )
    const threadTimestamp = lastSeenMessageTimestamps.get(threadId)
    const rootTimestamp = lastSeenMessageTimestamps.get('root')
    let unreadMessageCount = 0
    for (const messageKey in messageMap) {
      const message = messageMap[messageKey]
      if (message === undefined) {
        continue
      }

      // Only count messages in the right thread.
      // Note that the root message in a thread has the same id as the thread itself
      if (
        (message.responseToMessageId !== undefined ? message.responseToMessageId : 'root') !== threadId &&
        message.id !== threadId
      ) {
        continue
      }

      if (message.type === 'emoji') {
        continue
      }

      const timeSent = new Date(message.timeSent)
      // Check if we have seen this message before
      let seen = threadTimestamp !== undefined && timeSent <= threadTimestamp

      // Root messages of threads could have been seen without the rest of the messages in the thread beeing seen
      if (message.responseToMessageId === undefined) {
        seen ||= rootTimestamp !== undefined && timeSent <= rootTimestamp
      }

      // This message could be the root message of a thread.
      // In that case we might have read this inner thread without having read the thread it is contained in (most likely the root thread)
      const innerThreadTimestamp = lastSeenMessageTimestamps.get(message.id)
      if (innerThreadTimestamp !== undefined) {
        seen ||= timeSent <= innerThreadTimestamp
      }

      if (!seen) {
        unreadMessageCount += 1
      }
    }
    return unreadMessageCount
  }
)((state, chatId) => chatId)

const selectUnresolvedComments = createSelector([selectChat], chat => {
  return chat?.messages === undefined
    ? []
    : _.chain(chat.messages)
        .values()
        .flatMap(message =>
          message.responseToMessageId === undefined &&
          message.type === 'tiptap-comment' &&
          message.resolvedAt === undefined
            ? [message]
            : []
        )
        .sortBy('timeSent')
        .value()
})

export const selectUnitComments = createSelector(
  [selectUnresolvedComments, (state: unknown, chatId: ScopedChatId, unitId: string) => unitId],
  (comments, unitId) => {
    return comments.flatMap(comment => (comment.contentReference.unitId === unitId ? [comment] : []))
  }
)
