import React, { FC, useRef } from 'react'
import { getFileContentImage } from 'sierra-client/api/content'
import { FileImage } from 'sierra-client/api/graphql/gql/graphql'
import { useUploadImage } from 'sierra-client/hooks/use-image-upload'
import {
  ImageUploadControl,
  ImageUploadControlProps,
} from 'sierra-client/views/manage/paths/components/image-upload-control'
import { AssetContext } from 'sierra-domain/asset-context'
import { IconButton, View } from 'sierra-ui/primitives'
import { isDefined } from 'sierra-ui/utils/is-defined'
import styled from 'styled-components'

const HiddenInput = styled.input`
  opacity: 0;
  width: 0;
  height: 0;
  overflow: hidden;
  pointer-events: none;
`

const acceptedImageMimeTypes = ['image/jpeg', 'image/png']

type UploadFileImageProps = {
  value: FileImage | undefined
  onChange: (image: FileImage | undefined) => void
  recommendedDimensions?: ImageUploadControlProps['recommendedDimensions']
  assetContext: AssetContext
}

export const UploadFileImage: FC<UploadFileImageProps> = ({
  value,
  onChange,
  recommendedDimensions,
  assetContext,
}) => {
  const imageInputRef = useRef<HTMLInputElement>(null)
  const { uploadImage } = useUploadImage()

  const handleImageUpload = async (e: React.ChangeEvent<HTMLInputElement>): Promise<void> => {
    if (e.target.files === null) return
    const file = e.target.files[0]
    if (file && acceptedImageMimeTypes.includes(file.type)) {
      const data = await uploadImage(file, assetContext)
      onChange({ file: data.file, height: data.height, width: data.width })
    }
  }

  return (
    <ImageUploadControl
      image={value?.file !== undefined ? getFileContentImage(value.file) : undefined}
      onUpload={() => imageInputRef.current?.click()}
      recommendedDimensions={recommendedDimensions}
    >
      <HiddenInput
        type='file'
        accept={acceptedImageMimeTypes.join(',')}
        ref={imageInputRef}
        onChange={handleImageUpload}
      />
      <View marginLeft={'xsmall'}>
        {isDefined(value?.file) && (
          <IconButton
            variant='transparent'
            iconId='trash-can'
            onClick={e => {
              e.stopPropagation()
              e.preventDefault()
              onChange(undefined)
            }}
          />
        )}
      </View>
    </ImageUploadControl>
  )
}
