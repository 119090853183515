import { useAtomValue } from 'jotai'
import {
  formatBitrate,
  formatFps,
  formatTime,
  RemoteTracksStatsAtom,
  SectionTitle,
  StatsText,
} from 'sierra-client/components/liveV2/live-layer/call-stats/atoms'
import { useIsDebugMode } from 'sierra-client/hooks/use-is-debug-mode'
import { View } from 'sierra-ui/primitives'
import styled from 'styled-components'

const Wrapper = styled(View).attrs({ direction: 'column', gap: '2' })`
  position: absolute;
  top: 1rem;
  left: 1rem;
  z-index: 1;
`

export const RemoteTracksStats = (props: { agoraUID: string | undefined }): JSX.Element => {
  const isDebugMode = useIsDebugMode()
  const { remoteVideoStats, remoteAudioStats } = useAtomValue(RemoteTracksStatsAtom)
  const videoStats = remoteVideoStats?.[props.agoraUID ?? '']
  const audioStats = remoteAudioStats?.[props.agoraUID ?? '']

  return (
    <>
      {isDebugMode && (
        <Wrapper>
          {videoStats && (
            <>
              <SectionTitle>Video</SectionTitle>
              <StatsText>
                Res {videoStats.receiveResolutionWidth}x{videoStats.receiveResolutionHeight}
              </StatsText>
              <StatsText>
                {`FPS recieve ${formatFps(videoStats.receiveFrameRate)} decode ${formatFps(
                  videoStats.decodeFrameRate
                )} render ${formatFps(videoStats.renderFrameRate)}`}
              </StatsText>
              <StatsText>Bitrate {formatBitrate(videoStats.receiveBitrate)}</StatsText>
              <StatsText>E2E delay {formatTime(videoStats.end2EndDelay)}</StatsText>
              <StatsText>Transport delay {formatTime(videoStats.transportDelay)}</StatsText>
              <StatsText>Receive delay {formatTime(videoStats.receiveDelay)}</StatsText>
              <StatsText>Duration {Math.floor(videoStats.publishDuration / 1000)} sec</StatsText>
              <StatsText>Codec {videoStats.codecType}</StatsText>
            </>
          )}
          {audioStats && (
            <>
              <SectionTitle>Audio</SectionTitle>
              <StatsText>Bitrate {formatBitrate(audioStats.receiveBitrate)}</StatsText>
              <StatsText>E2E delay {formatTime(audioStats.end2EndDelay)}</StatsText>
              <StatsText>Transport delay {formatTime(audioStats.transportDelay)}</StatsText>
              <StatsText>Receive delay {formatTime(audioStats.receiveDelay)}</StatsText>
              <StatsText>Duration {Math.floor(audioStats.publishDuration / 1000)} sec</StatsText>
              <StatsText>Codec {audioStats.codecType}</StatsText>
            </>
          )}
        </Wrapper>
      )}
    </>
  )
}
