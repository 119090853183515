import {
  GlobalNestedBottomComponent,
  GlobalNestedSidebarComponent,
} from 'sierra-client/features/global-sidebar'
import { useIsMobile } from 'sierra-client/state/browser/selectors'
import { useCreatePageContextSafe } from 'sierra-client/views/flexible-content/create-page-context'
import {
  CreatePageBottomContainer,
  CreatePageSidebar,
  CreatePageSidebarHeader,
} from 'sierra-client/views/flexible-content/editor/content-sidebar/create-page-sidebar'
import { CreatePageSidebarSkeleton } from 'sierra-client/views/flexible-content/editor/content-sidebar/create-page-sidebar-skeleton'
import { SpeakerNoteContext } from 'sierra-client/views/flexible-content/editor/content-sidebar/speaker-notes/context'
import { isDefined } from 'sierra-domain/utils'

export const CreatePageGlobalSidebar: GlobalNestedSidebarComponent = () => {
  const context = useCreatePageContextSafe()

  return (
    <SpeakerNoteContext>
      <CreatePageSidebarHeader />
      {isDefined(context) ? (
        <CreatePageSidebar createContentId={context.createContentId} disableScroll />
      ) : (
        <CreatePageSidebarSkeleton />
      )}
    </SpeakerNoteContext>
  )
}

export const CreatePageGlobalBottomComponent: GlobalNestedBottomComponent = () => {
  const context = useCreatePageContextSafe()
  const isMobile = useIsMobile()
  if (!isDefined(context) || isMobile) return null
  return <CreatePageBottomContainer createContentId={context.createContentId} />
}
