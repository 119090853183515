import _ from 'lodash'
import { NonExpiringNotif } from 'sierra-client/components/common/notifications'
import { logger } from 'sierra-client/logger/logger'
import { getEditorErrorMeta } from 'sierra-client/views/flexible-content/editor/editor-error-meta'
import { verifyEditability } from 'sierra-client/views/v3-author/common/verify-editability'
import { SlateWrapperProps } from 'sierra-client/views/v3-author/slate'
import { CustomElementType, SanaEditor } from 'sierra-domain/v3-author'

function fullWarning(type: CustomElementType, error: string): string {
  return `[RulesOfBlocks] '${type}' ${error}`
}

type ReportViolation = (type: CustomElementType, error: string) => void
type RulesOfBlocksSeverity = 'error' | 'warning'

function verifyRefIsSet(
  type: CustomElementType,
  wrapper: HTMLElement | null,
  reportViolation: ReportViolation
): asserts wrapper is HTMLElement {
  if (wrapper === null)
    reportViolation(
      type,
      `Wrapper must set a ref. Make sure you are creating either a styled component or use \`React.forwardRef\`.`
    )
}

function verifySlateAttributesSpread(
  type: CustomElementType,
  wrapper: HTMLElement,
  attributes: SlateWrapperProps['attributes'],
  reportViolation: ReportViolation
): void {
  for (const [attribute, expectedValue] of Object.entries(attributes)) {
    if (attribute === 'ref') continue
    const actualValue = wrapper.attributes.getNamedItem(attribute)?.value
    if (!(_.isEqual(actualValue, expectedValue) || _.isEqual(actualValue, String(expectedValue)))) {
      reportViolation(
        type,
        `Wrapper had the wrong value for attribute ${attribute}. Expected: ${String(
          expectedValue
        )}, but got: ${String(
          actualValue
        )}. Did you forget to spread the \`attributes\` props in your wrapper element?`
      )
    }
  }
}

function findElement(element: HTMLElement, { where }: { where: (_: HTMLElement) => boolean }): boolean {
  function findElementRecursive(child: ChildNode): boolean {
    const isDesiredElement = child instanceof HTMLElement && where(child)
    return isDesiredElement || Array.from(child.childNodes).some(findElementRecursive)
  }

  return where(element) || Array.from(element.childNodes).some(findElementRecursive)
}

function verifyContainsTextNode(
  type: CustomElementType,
  wrapper: HTMLElement,
  reportViolation: ReportViolation
): void {
  const containsLeafNode = findElement(wrapper, {
    where: el => el.attributes.getNamedItem('data-slate-leaf')?.value === 'true',
  })

  if (!containsLeafNode) {
    reportViolation(
      type,
      `must contain a text node. Ensure that you are using the \`children\` prop in your Wrapper and Component definitions.`
    )
  }
}

function verifySlateEditability(
  type: CustomElementType,
  wrapper: HTMLElement,
  reportViolation: ReportViolation
): void {
  try {
    verifyEditability(wrapper)
  } catch (e) {
    reportViolation(type, `failed editability rules verification, ${_.get(e, 'message')}`)
  }
}

/**
 * `verify` runs on every render, which means that we desperately need to limit how many events
 * are sent. We'll accept only sending one event per type per session, and take the warnings seriously
 * when they show up.
 */
const SEEN_TYPES_IN_SESSION = new Set<string>()

function getReportViolationFunction(
  level: RulesOfBlocksSeverity,
  editor: SanaEditor,
  notif: NonExpiringNotif
): ReportViolation {
  return (type, ...args) => {
    const message = fullWarning(type, ...args)

    if (process.env.NODE_ENV === 'development') {
      notif.push({ type: 'error', body: message })
    }

    if (level === 'error') {
      throw new Error(message)
    } else {
      if (!SEEN_TYPES_IN_SESSION.has(type)) {
        logger.captureWarning(message, getEditorErrorMeta(editor))
        SEEN_TYPES_IN_SESSION.add(type)
      }
    }
  }
}

function verify(
  editor: SanaEditor,
  type: CustomElementType,
  wrapper: HTMLElement | null,
  attributes: SlateWrapperProps['attributes'],
  level: RulesOfBlocksSeverity,
  notif: NonExpiringNotif
): void {
  const reportViolation: ReportViolation = getReportViolationFunction(level, editor, notif)

  verifyRefIsSet(type, wrapper, reportViolation)
  verifySlateAttributesSpread(type, wrapper, attributes, reportViolation)
  verifyContainsTextNode(type, wrapper, reportViolation)
  verifySlateEditability(type, wrapper, reportViolation)
}

function verifyStableElementWrapper({
  prevWrapper,
  currWrapper,
  type,
  level,
  editor,
  notif,
}: {
  prevWrapper: HTMLElement | null
  currWrapper: HTMLElement | null
  type: CustomElementType
  level: RulesOfBlocksSeverity
  editor: SanaEditor
  notif: NonExpiringNotif
}): void {
  if (prevWrapper !== null && currWrapper !== null && prevWrapper !== currWrapper) {
    const reportViolation: ReportViolation = getReportViolationFunction(level, editor, notif)

    reportViolation(type, 'Wrapper element changed')
  }
}

export const RulesOfBlocks = { verify, verifyStableElementWrapper } as const
