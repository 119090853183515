import { useAtomValue } from 'jotai'
import { useEffect, useRef, useState } from 'react'
import { useSelectCurrentCard } from 'sierra-client/components/liveV2/hooks/use-select-current-card'
import {
  CallStatsAtom,
  ComputePressureAtom,
  ComputePressureValue,
  LocalTracksStatsAtom,
  PerformanceStatsAtom,
  RenderRateVolatilityEmaAtom,
  StatsSchedulingTimeEmaAtom,
} from 'sierra-client/components/liveV2/live-layer/call-stats/atoms'
import { logger } from 'sierra-client/logger/logger'
import { useSelector } from 'sierra-client/state/hooks'
import {
  selectAllSessionParticipantsIncludingMe,
  selectScreenShareParticipant,
} from 'sierra-client/state/live-session/selectors'
import { selectCallState, selectVideoResolutionSetting } from 'sierra-client/state/live/selectors'

type Stats = {
  performance: {
    renderRateVolatility?: number
    windowedRenderRateVolatility?: number
    statsSchedulingTime?: number
    windowedStatsSchedulingTime?: number
    statsDurationTime?: number
    documentHidden?: boolean
    computePressure?: ComputePressureValue
  }
  network: {
    sendBitrate?: number
    recvBitrate?: number
    audioPacketLossRate?: number
    videoPacketLossRate?: number
    rtt?: number
    currentUplink?: number
    windowedUplink?: number
    currentDownlink?: number
    windowedDownlink?: number
  }
  callInformation: {
    currentCardType?: string
    collabParticipantCount?: number
    callParticipantCount?: number
  }
  videoConfig: {
    codec?: string
    usingSetting?: string
    capturedRes?: string
    sendRes?: string
    fpsCaptured?: number
    fpsSent?: number
  }
}

const sendStats = (stats: Stats): void => {
  logger.trace('[Call Performance Stats]', { stats })
}

const logInterval = 5000

const LogStats = (): null => {
  const callStats = useAtomValue(CallStatsAtom)
  const { renderRateVolatility, statsDurationTime, statsSchedulingTime } = useAtomValue(PerformanceStatsAtom)
  const { videoStats, audioStats } = useAtomValue(LocalTracksStatsAtom)
  const computePressure = useAtomValue(ComputePressureAtom)
  const currentCard = useSelectCurrentCard()
  const participants = useSelector(selectAllSessionParticipantsIncludingMe)
  const someoneIsScreenSharing = !!useSelector(selectScreenShareParticipant)
  const videoSetting = useSelector(selectVideoResolutionSetting)
  const callState = useSelector(selectCallState)
  const windowedRenderRateVolatility = useAtomValue(RenderRateVolatilityEmaAtom)
  const windowedStatsSchedulingTime = useAtomValue(StatsSchedulingTimeEmaAtom)

  const stats = useRef<Stats>({ performance: {}, network: {}, callInformation: {}, videoConfig: {} })

  stats.current = {
    performance: {
      renderRateVolatility,
      windowedRenderRateVolatility,
      statsDurationTime,
      statsSchedulingTime,
      windowedStatsSchedulingTime,
      documentHidden: document.hidden,
      computePressure,
    },
    network: {
      sendBitrate: callStats?.SendBitrate,
      recvBitrate: callStats?.RecvBitrate,
      audioPacketLossRate: audioStats?.currentPacketLossRate,
      videoPacketLossRate: videoStats?.currentPacketLossRate,
      rtt: callStats?.RTT,
      currentUplink: callState.call.networkQualityStats?.uplink.currentUplink,
      windowedUplink: callState.call.networkQualityStats?.uplink.windowedUplink,
      currentDownlink: callState.call.networkQualityStats?.downlink.currentDownlink,
      windowedDownlink: callState.call.networkQualityStats?.downlink.windowedDownlink,
    },
    callInformation: {
      currentCardType: someoneIsScreenSharing ? 'screen-share' : currentCard?.data.type,
      callParticipantCount: callStats?.UserCount,
      collabParticipantCount: participants.length,
    },
    videoConfig: {
      codec: videoStats && videoStats.codecType,
      usingSetting: videoSetting,
      capturedRes: videoStats && `${videoStats.captureResolutionWidth}x${videoStats.captureResolutionHeight}`,
      sendRes: videoStats && `${videoStats.sendResolutionWidth}x${videoStats.sendResolutionHeight}`,
      fpsCaptured: videoStats && videoStats.captureFrameRate,
      fpsSent: videoStats && videoStats.sendFrameRate,
    },
  }

  useEffect(() => {
    const interval = setInterval(() => {
      sendStats(stats.current)
    }, logInterval)

    return () => {
      clearInterval(interval)
    }
  }, [])

  return null
}

const STATS_SCHEDULING_TIME_WARNING_THRESHOLD = 50
const LogStatsSchedulingTime = (): null => {
  const [, setStatsSchedulingTimeInWarning] = useState<boolean>(false)
  const { statsSchedulingTime } = useAtomValue(PerformanceStatsAtom)

  useEffect(() => {
    if (statsSchedulingTime === undefined || document.hidden) return

    setStatsSchedulingTimeInWarning(prev => {
      if (prev === true && statsSchedulingTime < STATS_SCHEDULING_TIME_WARNING_THRESHOLD) {
        logger.info(`Stats scheduling time recovered (${statsSchedulingTime.toFixed()})`, {
          statsSchedulingTime,
        })
        return false
      } else if (prev === false && statsSchedulingTime > STATS_SCHEDULING_TIME_WARNING_THRESHOLD) {
        logger.info(`Stats scheduling time high (${statsSchedulingTime.toFixed()})`, { statsSchedulingTime })
        return true
      } else {
        return prev
      }
    })
  }, [statsSchedulingTime, setStatsSchedulingTimeInWarning])

  return null
}

/**
 * Under 4% is good. Under 8% reasonable.
 */
const RENDER_RATE_VOLATILITY_WARNING_THRESHOLD = 15
const LogRRVWarning = (): null => {
  const { renderRateVolatility } = useAtomValue(PerformanceStatsAtom)

  const [, setRenderRateInWarning] = useState<boolean>(false)

  useEffect(() => {
    if (renderRateVolatility === undefined || document.hidden) return

    setRenderRateInWarning(prev => {
      if (prev === true && renderRateVolatility < RENDER_RATE_VOLATILITY_WARNING_THRESHOLD) {
        logger.info(`Render rate volatility recovered (${renderRateVolatility.toFixed(1)})`, {
          renderRateVolatility,
        })
        return false
      } else if (prev === false && renderRateVolatility > RENDER_RATE_VOLATILITY_WARNING_THRESHOLD) {
        logger.info(`Render rate volatility high (${renderRateVolatility.toFixed(1)})`, {
          renderRateVolatility,
        })
        return true
      } else {
        return prev
      }
    })
  }, [renderRateVolatility, setRenderRateInWarning])

  return null
}

export const StatsMonitor = (): JSX.Element => (
  <>
    <LogStats />
    <LogRRVWarning />
    <LogStatsSchedulingTime />
  </>
)
