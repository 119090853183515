import { useAtomValue } from 'jotai'
import { useDispatch } from 'sierra-client/state/hooks'
import { restoreInitialUserSettings } from 'sierra-client/state/live/actions'
import { backgroundBlurEnabledAtom, noiseCancellationEnabledAtom } from 'sierra-client/state/live/settings'
import { useOnChanged } from 'sierra-ui/utils'

export const useRestoreInitialUserSettings = (videoCallIsReady: boolean): void => {
  const dispatch = useDispatch()

  const backgroundBlurEnabledSetting = useAtomValue(backgroundBlurEnabledAtom)
  const noiseCancellationEnabledSetting = useAtomValue(noiseCancellationEnabledAtom)

  // Sync state of background blur and noise cancellation
  useOnChanged(() => {
    if (videoCallIsReady) {
      void dispatch(
        restoreInitialUserSettings({
          backgroundBlurEnabled: backgroundBlurEnabledSetting,
          noiseCancellationEnabled: noiseCancellationEnabledSetting,
        })
      )
    }
  }, videoCallIsReady)
}
