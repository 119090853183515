import React, { useState } from 'react'
import { HeaderItem, useToggleMobileGlobalSidebar } from 'sierra-client/features/collapsable-sidebar'
import { useRouterEditorIds, useRouterSelfPacedIds } from 'sierra-client/hooks/use-router-ids'
import { useTranslation } from 'sierra-client/hooks/use-translation'
import * as settingsActions from 'sierra-client/state/author-course-settings/actions'
import { selectCourseById } from 'sierra-client/state/content/selectors'
import { useDispatch, useSelector } from 'sierra-client/state/hooks'
import { CreateContentId, SelfPacedContentId } from 'sierra-domain/api/nano-id'
import { Abbreviation } from 'sierra-domain/content/v2/content'
import { assertNever, isDefined } from 'sierra-domain/utils'
import { Modal } from 'sierra-ui/components'
import { IconButton, Text, View } from 'sierra-ui/primitives'
import styled from 'styled-components'

const Wrapper = styled(View)`
  min-width: 500px;
  height: auto;
`

const GlossaryModal: React.FC<{
  abbreviations: Abbreviation[]
  onClose: () => void
  isOpen: boolean
}> = ({ abbreviations, onClose, isOpen }) => {
  const { t } = useTranslation()

  return (
    <Modal size='fit-content' animation='grow' open={isOpen} onClose={onClose}>
      <Wrapper direction='column' grow padding='32'>
        <View justifyContent='space-between' grow>
          <Text size='small' bold>
            {t('dictionary.glossary')}
          </Text>
          <IconButton iconId='close' variant='transparent' onClick={onClose} color='foreground/muted' />
        </View>
        {abbreviations.map(abbreviation => (
          <View key={abbreviation.id} gap='4' grow>
            <Text size='small' bold>
              {abbreviation.abbreviation}
            </Text>
            <Text size='small'>{abbreviation.explanation}</Text>
          </View>
        ))}
      </Wrapper>
    </Modal>
  )
}

const _LearnerGlossary: React.FC<{ flexibleContentId: CreateContentId }> = ({ flexibleContentId }) => {
  const { t } = useTranslation()
  const { close: closeMobileSidebar } = useToggleMobileGlobalSidebar()
  const [isGlossaryModalOpen, setIsGlossaryModalOpen] = useState(false)

  const course = useSelector(state => selectCourseById(state, flexibleContentId))
  const courseAbbreviations = course?.settings.abbreviations

  if (!isDefined(courseAbbreviations) || courseAbbreviations.length === 0) return <></>

  return (
    <>
      <HeaderItem
        type='simple-button-item'
        iconId='character--sentence-case'
        title={t('dictionary.glossary')}
        onClick={() => {
          setIsGlossaryModalOpen(true)
          closeMobileSidebar()
        }}
      />
      <GlossaryModal
        abbreviations={courseAbbreviations}
        onClose={() => setIsGlossaryModalOpen(false)}
        isOpen={isGlossaryModalOpen}
      />
    </>
  )
}

const LearnerGlossary: React.FC = () => {
  const ids = useRouterSelfPacedIds()

  if (!isDefined(ids)) return <></>

  return <_LearnerGlossary flexibleContentId={ids.flexibleContentId} />
}

const _EditorGlossary: React.FC<{ createContentId: SelfPacedContentId }> = ({ createContentId }) => {
  const dispatch = useDispatch()
  const { t } = useTranslation()

  return (
    <HeaderItem
      type='simple-button-item'
      iconId='character--sentence-case'
      title={t('dictionary.glossary')}
      onClick={async () => {
        await dispatch(settingsActions.fetch({ courseId: createContentId }))
        void dispatch(settingsActions.setView('glossary'))
      }}
    />
  )
}

const EditorGlossary: React.FC = () => {
  const ids = useRouterEditorIds()
  if (!isDefined(ids) || ids.mode !== 'self-paced') return <></>

  return <_EditorGlossary createContentId={ids.contentId} />
}

export const SidebarGlossaryNavigation: React.FC<{
  mode: 'editor' | 'learner'
}> = ({ mode }) => {
  switch (mode) {
    case 'editor':
      return <EditorGlossary />
    case 'learner':
      return <LearnerGlossary />
    default:
      assertNever(mode)
  }
}
