import { atom, useSetAtom } from 'jotai'
import { debounce, sum } from 'lodash'
import { useMemo } from 'react'
import { rejectOnTimeout } from 'sierra-client/components/liveV2/services/video-call-service/helpers/throw-on-timeout'
import { config } from 'sierra-client/config/global-config'
import { useIsDebugMode } from 'sierra-client/hooks/use-is-debug-mode'
import { logger } from 'sierra-client/logger/logger'
import { getAblyClient } from 'sierra-client/realtime-data/ably-client'
import { ChannelMap } from 'sierra-client/realtime-data/real-time-data-provider/channel-map'
import { RealTimeConnectionMonitor } from 'sierra-client/realtime-data/real-time-data-provider/real-time-connection-monitor'
import { RealTimeDataDebugView } from 'sierra-client/realtime-data/real-time-data-provider/real-time-data-debug-view'
import {
  RealTimeDataClient,
  RealTimeDataContext,
} from 'sierra-client/realtime-data/real-time-data-provider/realtime-data-context'
import { AblyClientWithAuthState, authenticateChannel } from 'sierra-client/realtime-data/utils/authenticate'
import { useSelector } from 'sierra-client/state/hooks'
import { selectUserId } from 'sierra-client/state/user/user-selector'
import { retry } from 'ts-retry-promise'

const log = logger.getLogger({ source: 'RealTimeDataClientProvider' })
const debug = (...args: unknown[]): void => console.debug('[RealTimeDataClientProvider]', ...args)
export const RealTimeDataClientProvider: React.FC<React.PropsWithChildren> = ({ children }) => {
  const debugMode = useIsDebugMode()
  const userId = useSelector(selectUserId)
  const ablyClient = typeof window !== 'undefined' ? getAblyClient({ userId }) : undefined
  const allChannelsAtom = useMemo(() => atom<ChannelMap>({}), [])
  const setChannelMap = useSetAtom(allChannelsAtom)

  const tenantId = config.organization.tenantId

  const client = useMemo((): RealTimeDataClient | undefined => {
    if (tenantId === null) return undefined
    if (ablyClient === undefined) return undefined

    const namespacedChannelName = (channelName: string): string => `${tenantId}:${channelName}`

    const subscribers: Record<string, number | undefined> = {}
    const ablyClientWithAuthState: AblyClientWithAuthState = {
      ablyClient,
      authenticatedChannels: new Set(),
      currentToken: undefined,
    }

    const reattachFailedChannels = debounce(async () => {
      const existingSubscribers = Object.keys(subscribers)
      await Promise.allSettled(
        existingSubscribers.map(channelName => {
          const channel = ablyClient.channels.get(namespacedChannelName(channelName))
          if (channel.state === 'failed') {
            return channel.attach()
          }
        })
      )
    }, 5000)

    const disconnectIfNoChannels = debounce(async () => {
      const channelNames = Object.keys(subscribers)
      for (const channelName of channelNames) {
        const channel = ablyClient.channels.get(namespacedChannelName(channelName))
        if (
          subscribers[channelName] === 0 &&
          channel.state !== 'detached' &&
          channel.state !== 'detaching' &&
          channel.state !== 'failed'
        ) {
          try {
            debug(`Detaching channel ${channelName}`)
            await channel.detach()
          } catch (error) {
            // should be safe to ignore this error, if the channel has no
            // subscribers it's going to disconnect anyway once a new unsubscribe is triggered

            logger.warn('Failed to detach channel', {
              channelName,
              subscribeCount: subscribers[channelName],
              error,
            })
          }
        }
      }

      if (
        sum(Object.values(subscribers)) === 0 &&
        ablyClient.connection.state !== 'closed' &&
        ablyClient.connection.state !== 'closing'
      ) {
        ablyClient.close()
      }
    }, 2500)

    // if the connection fails all the channels will become failed
    // and Ably will not reconnect automatically for those channels
    // this will just try attaching all failed channels when good connection is re-established
    ablyClient.connection.on('connected', () => {
      void reattachFailedChannels()
    })

    return {
      subscribeToChannel: async ({ channelName, eventName, callback }) => {
        try {
          subscribers[channelName] = (subscribers[channelName] ?? 0) + 1
          const success = await authenticateChannel(ablyClientWithAuthState, tenantId, channelName)
          if (!success) throw new Error(`Failed to authenticate channel ${channelName}`)

          ablyClient.connect()

          await rejectOnTimeout(
            ablyClient.connection.whenState('connected'),
            'Waiting for Ably client to be connected after authenticating',
            10_000
          )

          const channel = ablyClient.channels.get(namespacedChannelName(channelName))

          // We need to manually track what channels are created and how many subscribers each channel has
          // since the SDK can't do that for us...
          setChannelMap(curr => ({ ...curr, [channelName]: channel }))

          // There is a race condition in ably with rapid attach/detach
          // so we retry to explicitly attach the channel if it fails to attach
          await retry(() => channel.attach(), {
            retries: 3,
            delay: 500,
            backoff: 'LINEAR',
            logger: () => {
              log.debug('Failed to attach channel, retrying', {
                channelName,
                noSubscribers: subscribers[channelName],
              })
            },
          })

          await (eventName !== undefined
            ? channel.subscribe(eventName, callback)
            : channel.subscribe(callback))
          debug(`Subscribed to channel ${channelName}`)
        } catch (error) {
          log.warn('subscribeToChannel', { error, channelName, noSubscribers: subscribers[channelName] })
          throw error
        }
      },
      unsubscribeToChannel: ({ channelName, eventName, callback }) => {
        try {
          const channel = ablyClient.channels.get(namespacedChannelName(channelName))
          if (eventName !== undefined) {
            channel.unsubscribe(eventName, callback)
          } else {
            channel.unsubscribe(callback)
          }

          const currentSubscribers = subscribers[channelName] ?? 0
          if (currentSubscribers <= 0) {
            log.error('unsubscribeToChannel with currentSubscribers <= 0', { channelName, eventName })
          } else {
            debug(`Unsubscribed from channel ${channelName}`)
            subscribers[channelName] = currentSubscribers - 1
          }

          // Detach the channel if there are no listeners
          // there is a bit of a lag so we wait a bit before detaching
          void disconnectIfNoChannels()
        } catch (error) {
          log.error('unsubscribeToChannel', { error })
          throw error
        }
      },
      getChannelState: channelName => ablyClient.channels.get(namespacedChannelName(channelName)).state,
      onChannelStateChange: (channelName, callback) => {
        const channel = ablyClient.channels.get(namespacedChannelName(channelName))
        channel.on(callback)
      },
      offChannelStateChange: (channelName, callback) => {
        const channel = ablyClient.channels.get(namespacedChannelName(channelName))
        channel.off(callback)
      },

      /**
       * This is only used for migration purposes, do not use this for new code
       */
      getAblyClient: () => ablyClient,
      getAblyChannel: (channelName: string) => ablyClient.channels.get(namespacedChannelName(channelName)),
      getConnectionState: () => ablyClient.connection.state,
      onConnectionStateChange: callback => ablyClient.connection.on(callback),
      offConnectionStateChange: callback => ablyClient.connection.off(callback),
    }
  }, [tenantId, ablyClient, setChannelMap])

  return (
    <RealTimeDataContext.Provider value={client}>
      {children}
      <RealTimeConnectionMonitor client={ablyClient} />
      {debugMode && <RealTimeDataDebugView client={ablyClient} allChannelsAtom={allChannelsAtom} />}
    </RealTimeDataContext.Provider>
  )
}
