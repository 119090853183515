import { SimpleTextTitle } from 'sierra-client/components/common/header-view'
import { selectCourseById } from 'sierra-client/state/content/selectors'
import { useSelector } from 'sierra-client/state/hooks'
import { CreateContentId } from 'sierra-domain/api/nano-id'
import { Tooltip } from 'sierra-ui/components'

export const SelfPacedTitle = ({
  flexibleContentId,
}: {
  flexibleContentId: CreateContentId
}): JSX.Element | null => {
  const course = useSelector(state => selectCourseById(state, flexibleContentId))
  const title = course?.settings.title

  return (
    <Tooltip title={title}>
      <SimpleTextTitle size='small' bold className='contentTitle'>
        {title !== undefined ? title : ''}
      </SimpleTextTitle>
    </Tooltip>
  )
}
