import {
  APP_LANGUAGES,
  AppLanguageCode,
  AppLanguageDefinition,
} from 'sierra-client/i18n/app-languages.generated'

export function isSupportedAppLanguage(code: string): code is AppLanguageCode {
  return Object.hasOwn(APP_LANGUAGES, code)
}

export function getLanguagesByCode(codes: AppLanguageCode[]): AppLanguageDefinition[] {
  return codes.map(code => APP_LANGUAGES[code])
}

export function mapLanguages<T>(
  mapper: (code: AppLanguageDefinition) => T,
  customCodes?: AppLanguageCode[]
): T[] {
  const definitions =
    customCodes !== undefined ? getLanguagesByCode(customCodes) : Object.values(APP_LANGUAGES)

  return definitions.map(mapper)
}
