import { JSONContent } from '@tiptap/core'
import { Editor } from '@tiptap/react'
import { useEffect, useState } from 'react'
import { useAssetResolver } from 'sierra-client/hooks/use-resolve-asset'
import { TranslationKey } from 'sierra-client/hooks/use-translation/types'
import { getMentionNode } from 'sierra-client/views/insights/insights-content-search/suggestions/insights-content-suggestions'
import { InsightsContentSuggestion } from 'sierra-client/views/insights/insights-content-search/types'
import { useLoadInsightContentSuggestions } from 'sierra-client/views/insights/insights-content-search/use-load-insights-content-suggestions'
import { serializeInsightContentSuggestions } from 'sierra-client/views/insights/insights-content-search/utils'

type SuggestedQuestionKey = { key: TranslationKey; taggedType: 'user' | 'group' | 'content' | undefined }

type SuggestedQuestion =
  | { type: 'tagged'; taggedItem: InsightsContentSuggestion; label: string }
  | { type: 'untagged'; label: string }

export const useSuggestedQuestion = (
  editor: Editor | null,
  t: (key: TranslationKey, args?: Record<string, unknown> | undefined) => string
): string | undefined => {
  const [suggestedQuestion, setSuggestedQuestion] = useState<SuggestedQuestion | undefined>()

  const editorValue = serializeInsightContentSuggestions(editor)

  const atTaggingResults = useLoadInsightContentSuggestions('')

  useEffect(() => {
    const getRandomTaggedItem = (
      taggedType: 'user' | 'group' | 'content' | undefined
    ): { label: string; item: InsightsContentSuggestion } | undefined => {
      const filteredResults = atTaggingResults.filter(result => result.ref.refType === taggedType)

      if (filteredResults.length === 0 || taggedType === undefined) return undefined

      const randomIndex = Math.floor(Math.random() * filteredResults.length)
      return { label: filteredResults[randomIndex]!.ref.title, item: filteredResults[randomIndex]! }
    }
    const fallbackKey = 'insights.query.suggestions.courses.available.for.each.course.type'
    if (atTaggingResults.length > 0) {
      const suggestedQuestions = [
        { key: fallbackKey, taggedType: undefined },
        {
          key: 'insights.query.suggestions.completion.rate.per.course.for.assigned.courses',
          taggedType: undefined,
        },
        { key: 'insights.query.suggestions.groups.with.highest.start.rate.for.paths', taggedType: undefined },
        {
          key: 'insights.query.suggestions.success.percentages.for.each.question.asked',
          taggedType: undefined,
        },
        { key: 'insights.query.suggestions.breakdown.of.users.by.roles', taggedType: undefined },
        { key: 'insights.query.suggestions.program.completion.rate.per.group', taggedType: undefined },
        { key: 'insights.query.suggestions.time.spent.on.learning.in.course.per.user', taggedType: 'user' },
        { key: 'insights.query.suggestions.completion.rate.per.user.for.group', taggedType: 'group' },
        { key: 'insights.query.suggestions.completion.rate.in.content', taggedType: 'content' },
        { key: 'insights.query.suggestions.completions.in.content', taggedType: 'content' },
        { key: 'insights.query.suggestions.completion.rate.in.courses.for.user', taggedType: 'user' },
        { key: 'insights.query.suggestions.question.correct.rate.per.course.for.group', taggedType: 'group' },
      ] satisfies SuggestedQuestionKey[]
      const randomQuestion = suggestedQuestions[Math.floor(Math.random() * suggestedQuestions.length)]!
      if (randomQuestion.taggedType === undefined) {
        setSuggestedQuestion({ type: 'untagged', label: t(randomQuestion.key) })
      } else {
        const randomTaggedItem = getRandomTaggedItem(randomQuestion.taggedType)
        if (randomTaggedItem === undefined) {
          setSuggestedQuestion({ type: 'untagged', label: t(fallbackKey) })
          return
        }
        setSuggestedQuestion({
          type: 'tagged',
          taggedItem: randomTaggedItem.item,
          label: t(randomQuestion.key, { taggedItem: randomTaggedItem.label }),
        })
      }
    }
  }, [atTaggingResults, editorValue, t])

  const assetResolver = useAssetResolver({ size: 'default' })

  useEffect(() => {
    const handleTab = (e: KeyboardEvent): void => {
      if (suggestedQuestion === undefined || editor === null) {
        return
      }

      if (e.key === 'Tab' && editor.isFocused) {
        e.preventDefault() // We don't want the tab to move the focus to the next element
        const suggestionNode: JSONContent = [{ type: 'text', text: suggestedQuestion.label }]
        if (suggestedQuestion.type === 'tagged') {
          const placeHolderItem = `@${suggestedQuestion.taggedItem.ref.title}`
          const mentionNode = getMentionNode(suggestedQuestion.taggedItem.ref, assetResolver)
          const textBeforeMention = suggestionNode[0].text.split(placeHolderItem)[0]
          const textAfterMention = suggestionNode[0].text.split(placeHolderItem)[1]
          suggestionNode[0].text = textBeforeMention
          suggestionNode.push(mentionNode)
          textAfterMention !== undefined && suggestionNode.push({ type: 'text', text: textAfterMention })
        }
        editor.commands.setContent(suggestionNode)
      }
    }
    window.addEventListener('keydown', handleTab)

    return () => {
      window.removeEventListener('keydown', handleTab)
    }
  }, [editor, suggestedQuestion, assetResolver])

  return suggestedQuestion?.label
}
