import React, { useCallback, useMemo, useState } from 'react'
import { useDeleteHeatmap } from 'sierra-client/api/hooks/use-heatmaps'
import { useTranslationForBaseRoute } from 'sierra-client/hooks/use-translation'
import { DomainRep, Filter, isEmptyFilter } from 'sierra-client/lib/filter'
import { ReadOnlyFilter } from 'sierra-client/lib/filter/components/read-only-filter'
import { DownloadHeatmapButton } from 'sierra-client/views/manage/reports/components/heatmap/components/download-heatmap-button'
import { SaveReportModal } from 'sierra-client/views/manage/reports/components/heatmap/components/save-report-modal'
import { HeatmapV2 } from 'sierra-client/views/manage/reports/components/heatmap/heatmap-v2'
import { useHeatmapReportJobState } from 'sierra-client/views/manage/reports/components/heatmap/hooks/use-heatmap-report-job-state'
import { HeatmapCustomFilter } from 'sierra-client/views/manage/reports/components/heatmap/types'
import { useIsReportingEnabled } from 'sierra-client/views/manage/reports/report-utils'
import { HeatmapContent, SavedCustomHeatmapSummary } from 'sierra-domain/api/heatmap'
import { Icon, Modal, ModalHeader, Tooltip } from 'sierra-ui/components'
import { Button, Heading, IconButton, ScrollView, Spacer, Text, View } from 'sierra-ui/primitives'
import styled from 'styled-components'

const CloseButton = styled(IconButton).attrs({ iconId: 'close', variant: 'transparent' })``

type UserFilterHeatmapProps = {
  heatmapSummary?: SavedCustomHeatmapSummary
  onSaveHeatmap?: (summary: SavedCustomHeatmapSummary) => void
  onClose: () => void
  query?: string
  domainReps?: DomainRep[] | undefined
  filter?: Filter | undefined
  initialContentIds?: string[]
}

export const UserFilterHeatmap: React.FC<UserFilterHeatmapProps> = ({
  heatmapSummary: initialHeatmapSummary,
  onSaveHeatmap,
  onClose,
  query,
  filter,
  domainReps,
  initialContentIds,
}) => {
  const isReportingEnabled = useIsReportingEnabled()
  const deleteHeatmapMutation = useDeleteHeatmap()

  const heatmapReportJobState = useHeatmapReportJobState()
  const [savedSummary, setSavedSummary] = useState(initialHeatmapSummary)
  const userHeatmapFilter = useMemo<HeatmapCustomFilter>(
    () => ({
      type: 'filter',
      query,
      filter: filter,
    }),
    [query, filter]
  )

  const { t } = useTranslationForBaseRoute()
  const [saveModalOpen, setSaveModalOpen] = React.useState(false)
  const [contentIds, setContentIds] = useState<string[]>([])
  const [isCertificateChosen, setCertificateChosen] = useState<boolean>(false)
  const onSave = useCallback(
    (summary: SavedCustomHeatmapSummary) => {
      onSaveHeatmap?.(summary)

      setSavedSummary(summary)
    },
    [onSaveHeatmap]
  )
  const onContentsChange = useCallback(
    (cs: Array<HeatmapContent>) => {
      setContentIds(cs.map(c => c.id))
      setCertificateChosen(cs.some(c => c.type === 'certificate'))
    },
    [setContentIds]
  )

  const isFilterEmpty = filter === undefined ? true : isEmptyFilter(filter)

  return (
    <Modal open={true} animation='pop' size='full-screen' onClose={onClose}>
      <ModalHeader padding='medium'>
        <Heading size='h5' bold avoidHanging={false}>
          {savedSummary?.title ?? t('manage.users.reporting')}
        </Heading>
        <CloseButton onClick={onClose} />
      </ModalHeader>
      <ScrollView direction='column' grow marginLeft='medium'>
        <HeatmapV2
          userFilter={userHeatmapFilter}
          initialSelectedIds={initialContentIds}
          canAddContent={true}
          onContentsChange={onContentsChange}
        />
      </ScrollView>
      {isFilterEmpty && <Spacer size='small' />}
      {!isFilterEmpty && (
        <View direction='column' background='grey2' padding='medium'>
          <View justifyContent='space-between'>
            <Text size='small' bold>
              {t('user-filter.filters')}
            </Text>
            <View>
              {isCertificateChosen && (
                <>
                  <View justifyContent='flex-start' gap='xxsmall'>
                    <Icon iconId='information' color='foreground/muted' />
                    <Tooltip title={t('manage.heatmap.certificate-warning-tooltip')}>
                      <Text size='small' bold color='foreground/muted'>
                        {t('manage.heatmap.certificate-warning')}
                      </Text>
                    </Tooltip>
                  </View>
                  <Spacer size='small' />
                </>
              )}
              {savedSummary !== undefined && (
                <Button
                  variant='secondary'
                  icon='trash-can'
                  onClick={() => {
                    deleteHeatmapMutation.mutate({ heatmapId: savedSummary.id })
                  }}
                >
                  {t('manage.heatmap.remove-favorite')}
                </Button>
              )}
              <Button variant='secondary' icon='trend--up' onClick={() => setSaveModalOpen(true)}>
                {savedSummary === undefined
                  ? t('manage.heatmap.add-favorite')
                  : t('manage.heatmap.update-favorite')}
              </Button>
              {isReportingEnabled && (
                <DownloadHeatmapButton
                  isLoading={heatmapReportJobState.isLoading}
                  downloadWithFormat={outputFormat =>
                    heatmapReportJobState.startDownload({
                      query,
                      contentIds,
                      filter,
                      outputFormat,
                    })
                  }
                />
              )}
            </View>
          </View>
          {filter !== undefined && domainReps !== undefined && (
            <View direction='row' wrap='wrap'>
              <ReadOnlyFilter filter={filter} domainReps={domainReps} />
            </View>
          )}
        </View>
      )}
      {filter !== undefined && (
        <SaveReportModal
          open={saveModalOpen}
          onClose={() => setSaveModalOpen(false)}
          savedSummary={savedSummary}
          query={query}
          filter={filter}
          contentIds={contentIds}
          onSave={onSave}
        />
      )}
    </Modal>
  )
}
