import React from 'react'
import { HeaderGroupButton } from 'sierra-client/components/liveV2/header-buttons'
import { useToggle } from 'sierra-client/hooks/use-toggle'
import { color } from 'sierra-ui/color'
import { Icon, IconId, Popover, Tooltip } from 'sierra-ui/components'
import { IconButton, Text, View } from 'sierra-ui/primitives'
import { palette, spacing } from 'sierra-ui/theming'
import styled from 'styled-components'

const StyledText = styled(Text)`
  padding-bottom: ${spacing['4']};
  border-bottom: 1px solid ${palette.grey[5]};
`

const StyledIconButton = styled(IconButton)<{ $selected: boolean }>`
  background-color: ${props => (props.$selected === true ? color('grey5').toString() : 'transparent')};
`

export type IconPickerOption<Key = string> = {
  key: Key
  iconId: IconId
}

type IconPickerProps<Key = string> = {
  tooltip?: string
  label?: string
  options: IconPickerOption<Key>[]
  onUpdate: (activeOption: IconPickerOption<Key>) => void
  activeOption: IconPickerOption<Key>
}

const MenuButton = React.forwardRef<
  HTMLButtonElement,
  {
    children: React.ReactNode
    tooltip?: string
    toggle: () => void
  }
>(({ children, tooltip, toggle }, ref) => {
  return (
    <Tooltip title={tooltip}>
      <HeaderGroupButton onClick={toggle} ref={ref}>
        {children}
      </HeaderGroupButton>
    </Tooltip>
  )
})

export function IconPicker<Key extends string = string>({
  label,
  tooltip,
  options,
  onUpdate,
  activeOption,
}: IconPickerProps<Key>): JSX.Element {
  const [open, { toggle, set: setOpen }] = useToggle()

  return (
    <Popover
      isOpen={open}
      onOpenChange={setOpen}
      renderTrigger={() => (
        <MenuButton tooltip={tooltip} toggle={toggle}>
          <View gap='2'>
            <Icon size='size-16' iconId={activeOption.iconId} />
            <Icon
              size='size-16'
              color='grey40'
              iconId={open ? 'chevron--up--small' : 'chevron--down--small'}
            />
          </View>
        </MenuButton>
      )}
    >
      <View direction='column' radius='small' padding='xxsmall' background='white'>
        {label !== undefined && (
          <StyledText color='grey40' size='micro'>
            {label}
          </StyledText>
        )}

        <View alignItems='flex-start'>
          {options.map(option => (
            <View key={option.key}>
              <StyledIconButton
                $selected={option.key === activeOption.key}
                variant='transparent'
                color='grey80'
                iconId={option.iconId}
                onClick={() => onUpdate(option)}
              />
            </View>
          ))}
        </View>
      </View>
    </Popover>
  )
}
