import { ImageFit } from 'sierra-domain/flexible-content/image-fit'
import { css } from 'styled-components'

export const prefetchImageUrl = (url: string): Promise<void> =>
  new Promise(res => {
    const image = new Image()
    image.onload = () => {
      res()
    }

    image.src = url
  })

export type CardBackgroundProps = { $background?: string; $placeholderUrl?: string; $imageFit?: ImageFit }

export const cardBackgroundStyles = css<CardBackgroundProps>`
  ${p =>
    p.$background === undefined
      ? ''
      : css<CardBackgroundProps>`
          ${p.$placeholderUrl !== undefined
            ? css<CardBackgroundProps>`
                background-image: url(${p => p.$background}), url(${p => p.$placeholderUrl});
              `
            : css<CardBackgroundProps>`
                background-image: url(${p => p.$background});
              `}

          background-position: center center;
          background-size: ${p => p.$imageFit ?? 'cover'};
          background-repeat: no-repeat;
          transition: background-image 300ms ease-out;
          will-change: background-image;
        `}
`
