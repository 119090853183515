/* eslint-disable react/jsx-no-literals */
import React from 'react'
import {
  BigPadding,
  PrintButton,
  Wrap,
  WrapButtons,
} from 'sierra-client/views/learner/certificate/components'
import { CertificateProps } from 'sierra-client/views/learner/certificate/types'
import {
  getFormattedCompletionDate,
  getImageUrl,
  handlePrint,
} from 'sierra-client/views/learner/certificate/utils'
import { Heading, Spacer, Text } from 'sierra-ui/primitives'
import styled from 'styled-components'

const StyledWrap = styled(Wrap)`
  @page {
    size: portrait;
  }
`

const Container = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  height: 100%;
  max-height: 100%;
`

const Logo = styled.img`
  width: 130px;
  align-self: flex-end;
`

const Blob = styled.img`
  max-width: 200px;
  align-self: flex-start;
`

const StyledWrapButtons = styled(WrapButtons)`
  left: 2rem;
  right: auto;
`

const Body = styled(Text)`
  max-width: 50ch;
  text-align: center;
`

export const SveaSolarCertificate: React.FC<CertificateProps> = ({ user, completionTimestamp, course }) => (
  <>
    <StyledWrap id='printwrapper'>
      <Container>
        <Logo src={getImageUrl('svea-solar-certificate-logo.png')} />
        <BigPadding />
        <Heading color='LEGACY_DEFAULT_HEADING_COLOR_REPLACE_ASAP' size='h1'>
          Course certificate
        </Heading>
        <Spacer size='xxlarge' />
        <Heading color='LEGACY_DEFAULT_HEADING_COLOR_REPLACE_ASAP' size='h4'>
          {user.firstName} {user.lastName}
        </Heading>
        <Spacer size='medium' />

        <Body color='LEGACY_DEFAULT_TEXT_COLOR_REPLACE_ASAP' size='regular'>
          has successfully completed the course and has therefore achieved the required level of competence in
        </Body>
        <Spacer size='medium' />

        <Heading color='LEGACY_DEFAULT_HEADING_COLOR_REPLACE_ASAP' size='h3'>
          {course.settings.title}
        </Heading>
        <Spacer size='large' />

        <Blob src={getImageUrl('svea-solar-certificate-blobs.png')} />

        <Text color='LEGACY_DEFAULT_TEXT_COLOR_REPLACE_ASAP' size='regular' bold>
          {getFormattedCompletionDate(completionTimestamp)}
        </Text>
        <Spacer size='small' />

        <Text color='LEGACY_DEFAULT_TEXT_COLOR_REPLACE_ASAP' size='regular'>
          The certification is issued by SVEA Renewable Solar
        </Text>
      </Container>
    </StyledWrap>
    <StyledWrapButtons>
      <PrintButton variant='secondary' onClick={handlePrint}>
        Print or download
      </PrintButton>
    </StyledWrapButtons>
  </>
)
