import { keepPreviousData } from '@tanstack/react-query'
import { sortBy } from 'lodash'
import React, { useCallback, useEffect, useMemo, useRef, useState } from 'react'
import { graphql } from 'sierra-client/api/graphql/gql'
import { useGraphQuery } from 'sierra-client/api/hooks/use-graphql-query'
import { usePost } from 'sierra-client/hooks/use-post'
import { useAreCertificatesEnabled } from 'sierra-client/views/manage/certificates/use-are-certificates-enabled'
import { useAvailableCertificates } from 'sierra-client/views/manage/reports/components/heatmap/hooks/use-available-certificates'
import { useAvailableContent } from 'sierra-client/views/manage/reports/components/heatmap/hooks/use-available-content'
import {
  AssignmentStatuses,
  CertificateStatuses,
  HeatmapUserFilter,
} from 'sierra-client/views/manage/reports/components/heatmap/types'
import {
  HeatmapContent,
  HeatmapContentCertificate,
  HeatmapContentCourse,
  HeatmapContentPath,
} from 'sierra-domain/api/heatmap'
import { CertificateId, CourseId } from 'sierra-domain/api/nano-id'
import {
  XRealtimeAdminHeatmapCellData,
  XRealtimeAdminHeatmapContentProgress2,
  XRealtimeAdminHeatmapFilterUsers,
  XRealtimeAdminHeatmapUserProgress,
} from 'sierra-domain/routes'
import { BaseUserRow } from 'sierra-domain/user/base-user-row'
import { ExtractFrom, STATIC_EMPTY_ARRAY, hasKey, isEmptyArray } from 'sierra-domain/utils'
import { useOnChanged } from 'sierra-ui/utils'

type UserProgress = Record<string, number>
type ContentProgress = Record<string, number>

type HeatmapStats = {
  assignmentStatuses: AssignmentStatuses // Data for cell content
  certificateStatuses: CertificateStatuses
  userProgress: UserProgress // Averages per user over all content
  contentProgress: ContentProgress // Averages per content over all users matching the filter
  overallProgress: number | undefined
}

type UseHeatmapParams = {
  userFilter?: HeatmapUserFilter | undefined
  initialSelectedIds?: Array<string>
  canAddContent?: boolean
  programId?: string
  contentQuery?: string
}

type AvailableHeatmapEntities = {
  coursesAndPaths: { content: Array<HeatmapContentCourse | HeatmapContentPath>; hasMore: boolean }
  certificates: { content: Array<HeatmapContentCertificate>; hasMore: boolean }
}

export type UseHeatmap = {
  users: BaseUserRow[]
  heatmapStats: HeatmapStats
  chosenContent: HeatmapContent[]
  toggleContent: (id: string) => void
  loadMoreUsers: () => void
  hasMoreUsers: boolean
}

// Only grabs the content that are available in a heatmap
// Allows you to ignore some content by passing their ids
export const useAvailableHeatmapContent = ({
  contentQuery,
  includeIds,
  userFilter,
  ignoreContentIds = STATIC_EMPTY_ARRAY,
  limit = 30,
  skip = false,
  skipCourseEditions = false,
}: {
  contentQuery?: string
  includeIds?: Array<string>
  userFilter?: HeatmapUserFilter
  ignoreContentIds?: Array<string>
  limit?: number
  skip?: boolean
  skipCourseEditions?: boolean
}): AvailableHeatmapEntities => {
  const availableContentRes = useAvailableContent({
    userFilter,
    includeIds: includeIds?.filter((id): id is CourseId => CourseId.safeParse(id).success),
    contentQuery,
    limit: limit,
    skip,
  })

  const availableCertificatesRes = useAvailableCertificates({
    title: contentQuery,
    limit,
    skip,
    includeIds: includeIds?.filter((id): id is CertificateId => CertificateId.safeParse(id).success),
  })

  const availableEntities = React.useMemo<AvailableHeatmapEntities>(
    () => ({
      coursesAndPaths: {
        content: (availableContentRes.data ?? [])
          .filter(
            (x): x is ExtractFrom<typeof x, { type: 'course' | 'path' }> =>
              x.type === 'course' || x.type === 'path'
          )
          .filter(x => {
            if (hasKey(x, 'isCourseEdition') && skipCourseEditions) {
              return !x.isCourseEdition
            }
            return true
          })
          .filter(x => ignoreContentIds.includes(x.id) === false),
        hasMore: availableContentRes.data?.length === limit,
      },
      certificates: {
        content: (availableCertificatesRes.data ?? [])
          .filter((x): x is ExtractFrom<typeof x, { type: 'certificate' }> => x.type === 'certificate')
          .filter(x => ignoreContentIds.includes(x.id) === false),
        hasMore: availableCertificatesRes.data?.length === limit,
      },
    }),
    [availableContentRes.data, limit, availableCertificatesRes.data, skipCourseEditions, ignoreContentIds]
  )

  return availableEntities
}

// Anyone is assigned
// Everyone is assigned
// Anyone has started (default one for groups)

export const useHeatmap = ({
  userFilter,
  initialSelectedIds = STATIC_EMPTY_ARRAY,
  programId,
  contentQuery,
}: UseHeatmapParams): UseHeatmap => {
  const { postWithUserErrorException } = usePost()
  const certificatesFeatureEnabled = useAreCertificatesEnabled()
  const touched = useRef(false)

  const [users, setUsers] = React.useState<BaseUserRow[]>([])

  const [_selectedContentIds, _setSelectedContentIds] = React.useState<Set<string>>(
    () => new Set(initialSelectedIds)
  )

  const selectedContentIds = useMemo(() => Array.from(_selectedContentIds), [_selectedContentIds])
  const setSelectedContentIds = useCallback((cb: (ids: Array<string>) => Array<string>) => {
    touched.current = true
    _setSelectedContentIds(ids => new Set(cb(Array.from(ids))))
  }, [])

  const initiallySelectedEntities = useAvailableHeatmapContent({
    contentQuery,
    includeIds: selectedContentIds,
    userFilter,
    // Do not grab the initial items unless you have initial selected ones
    skip: selectedContentIds.length === 0 && !touched.current,
  })

  const selectedContent = React.useMemo(() => {
    return sortBy(
      [
        ...initiallySelectedEntities.certificates.content,
        ...initiallySelectedEntities.coursesAndPaths.content,
      ],
      content => {
        return selectedContentIds.indexOf(content.id)
      }
    )
  }, [
    initiallySelectedEntities.certificates.content,
    initiallySelectedEntities.coursesAndPaths.content,
    selectedContentIds,
  ])

  const [nextUserId, setNextUserId] = React.useState<string | undefined>(undefined)
  const [initialLoadingDone, setInitialLoadingDone] = useState(false)
  const [isLoading, setIsLoading] = React.useState<boolean>(false)
  const [userProgress, setUserProgress] = React.useState<UserProgress>({})
  const [contentProgress, setContentProgress] = React.useState<Record<string, number>>({})
  const [overallProgress, setOverallProgress] = React.useState<number | undefined>(undefined)
  const [assignmentStatuses, setAssignmentStatuses] = React.useState<AssignmentStatuses>({})
  const [certificateStatuses, setCertificateStatuses] = React.useState<CertificateStatuses>({})

  const contentIds = React.useMemo(
    () => selectedContent.filter(x => x.type !== 'certificate').map(x => x.id),
    [selectedContent]
  )

  const certificateIds = React.useMemo(() => {
    const certificateContent = selectedContent.filter(it => it.type === 'certificate')
    return certificateContent.map(it => it.id)
  }, [selectedContent])

  const userIds = React.useMemo(() => users.map(x => x.userId), [users])

  // We need to fetch the user progress when:
  // 1. A new page of users is loaded
  // 2. The content list changes (content added or removed)
  const fetchUserProgress = React.useCallback(
    async ({
      userIds,
      contentIds,
      reset,
    }: {
      userIds: string[]
      contentIds: string[]
      reset?: boolean
    }): Promise<void> => {
      // This happens on the first render. Don't bother making a network call.
      if (contentIds.length === 0) {
        setUserProgress({})
        return
      }

      if (reset === true) {
        setUserProgress({})
      }

      const res = await postWithUserErrorException(XRealtimeAdminHeatmapUserProgress, {
        userIds,
        contentIds,
        programId,
      })

      setUserProgress(curr => ({ ...curr, ...res.progressByUser }))
    },
    [postWithUserErrorException, programId]
  )

  // We need to fetch content progress when:
  // 1. Content is added
  // 2. The user filter or query changes
  const fetchContentProgress = React.useCallback(
    async ({ contentIds, reset }: { contentIds: string[]; reset?: boolean }): Promise<void> => {
      if (reset === true) {
        setContentProgress({})
        setOverallProgress(undefined)
      }

      // A network call with an empty contentIds array would have no effect.
      if (contentIds.length === 0) {
        return
      }

      const res = await postWithUserErrorException(XRealtimeAdminHeatmapContentProgress2, {
        query: userFilter?.type === 'filter' ? userFilter.query : undefined,
        filter: userFilter?.type === 'filter' ? userFilter.filter : undefined,
        programIds: userFilter?.type === 'program' ? userFilter.programIds : undefined,
        contentIds,
      })

      setContentProgress(curr => ({ ...curr, ...res.progressByContent }))
      setOverallProgress(res.overallProgress)
    },
    [postWithUserErrorException, userFilter]
  )

  useOnChanged((prev, curr) => {
    // Re-fetch _all_ user progress when content list changes
    void fetchUserProgress({
      userIds: userIds,
      contentIds: curr,
      reset: true,
    })

    // Fetch content progress for newly added content only
    void fetchContentProgress({
      contentIds: curr.filter(id => prev === undefined || !prev.includes(id)),
    })
  }, contentIds)

  // If user filter changes:
  // - Re-fetch content averages
  // - Reset user averages
  // - Reset pagination and load first page
  // If content list changes:
  // - Fetch content averages for current user filter for new content (or all content)
  // - Fetch cell data for each loaded user and selected content

  const toggleContent = React.useCallback(
    (selectedId: string) => {
      // TODO: If we have both local and external content, we should not allow adding external content to the local list.

      setSelectedContentIds(curr => {
        if (curr.some(id => id === selectedId)) {
          return curr.filter(x => x !== selectedId)
        } else {
          return curr.concat(selectedId)
        }
      })
    },
    [setSelectedContentIds]
  )

  const fetchCellData = React.useCallback(
    async ({ userIds, contentIds }: { userIds: string[]; contentIds: string[] }) => {
      // Do not do anything if there is no content to fetch for
      if (isEmptyArray(userIds) || isEmptyArray(contentIds)) {
        return
      }

      const res = await postWithUserErrorException(XRealtimeAdminHeatmapCellData, {
        contentIds,
        userIds,
        programId,
      })

      setAssignmentStatuses(prev =>
        res.data.reduce(
          (acc, user) => {
            acc[user.userId] = user.contentStatus
            return acc
          },
          { ...prev }
        )
      )
    },
    [postWithUserErrorException, programId]
  )

  const issuedCertificateQueryResult = useGraphQuery(
    {
      document: graphql(`
        query heatmapIssuedCertificates($ids: [CertificateId!]!) {
          issuedCertificatesMultiple(ids: $ids) {
            certificateId
            issuedToUserId
            issuedAt
            expiresAt
            revokedAt
          }
        }
      `),
      queryOptions: {
        placeholderData: keepPreviousData,
        enabled: certificatesFeatureEnabled,
      },
    },
    { ids: certificateIds }
  )

  React.useEffect(() => {
    const certificateRes = issuedCertificateQueryResult.data

    if (certificateRes !== undefined) {
      const statuses: CertificateStatuses = {}
      for (const userId of userIds) {
        statuses[userId] = {}
        for (const issuedCertificate of certificateRes.issuedCertificatesMultiple) {
          if (issuedCertificate.issuedToUserId === userId) {
            statuses[userId]![issuedCertificate.certificateId] = {
              ...issuedCertificate,
              expiresAt: issuedCertificate.expiresAt !== null ? issuedCertificate.expiresAt : undefined,
              revokedAt: issuedCertificate.revokedAt !== null ? issuedCertificate.revokedAt : undefined,
            }
          }
        }
      }
      setCertificateStatuses(statuses)
    }
  }, [issuedCertificateQueryResult.data, userIds])

  React.useEffect(() => {
    void fetchCellData({
      userIds,
      contentIds,
    })
  }, [contentIds, fetchCellData, userIds])

  // Load another page of users.
  // After loading a page:
  // - Fetch user averages for content list
  // - Fetch cell data for each loaded user and selected content
  const loadUserPage = useCallback(
    async ({ nextUserId }: { nextUserId: string | undefined }) => {
      if (isLoading) {
        return
      }

      // We set isLoading flag to true while fetching. This is because we only want to fetch one page at a time.
      setIsLoading(true)

      try {
        // Fetch first page using this filter.
        const { users: newUsers, next: newNextUserId } = await postWithUserErrorException(
          XRealtimeAdminHeatmapFilterUsers,
          {
            query: userFilter?.type === 'filter' ? userFilter.query : undefined,
            filter: userFilter?.type === 'filter' ? userFilter.filter : undefined,
            programIds: userFilter?.type === 'program' ? userFilter.programIds : undefined,
            limit: 20,
            next: nextUserId,
          }
        )

        setUsers(curr => [...curr, ...newUsers])
        setNextUserId(newNextUserId)

        const newUserIds = newUsers.map(x => x.userId)
        void fetchCellData({
          userIds: newUserIds,
          contentIds: contentIds,
        })
        void fetchUserProgress({ userIds: newUserIds, contentIds: contentIds })
      } finally {
        requestAnimationFrame(() => {
          // Should run when table has re-rendered
          setIsLoading(false)
        })
      }
    },
    [fetchCellData, fetchUserProgress, isLoading, postWithUserErrorException, userFilter, contentIds]
  )

  // Called with no parameters, used to load the next page. Does nothing if there is no next id.
  const loadNextUserPage = useCallback(async () => {
    if (nextUserId === undefined) {
      return
    }

    await loadUserPage({ nextUserId })
  }, [loadUserPage, nextUserId])

  // Do initial loading
  useEffect(() => {
    if (!initialLoadingDone) {
      void loadUserPage({ nextUserId: undefined })
      void fetchContentProgress({ contentIds })
      setInitialLoadingDone(true)
    }
  }, [initialLoadingDone, loadUserPage, fetchContentProgress, contentIds])

  return React.useMemo(
    () => ({
      users,
      chosenContent: selectedContent,
      heatmapStats: {
        assignmentStatuses,
        certificateStatuses,
        userProgress,
        contentProgress,
        overallProgress,
      },
      toggleContent,
      loadMoreUsers: loadNextUserPage,
      hasMoreUsers: nextUserId !== undefined,
    }),
    [
      users,
      selectedContent,
      assignmentStatuses,
      certificateStatuses,
      toggleContent,
      loadNextUserPage,
      nextUserId,
      userProgress,
      contentProgress,
      overallProgress,
    ]
  )
}
