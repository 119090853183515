import { DynamicT } from 'sierra-client/hooks/use-translation/types'
import { DomainRef, DomainRep, Filter, Label, domainRefHash } from 'sierra-client/lib/filter'
import { groupBy, labelToString } from 'sierra-client/lib/filter/components/common'
import { MenuItemWithDomainRep } from 'sierra-client/lib/filter/components/types'

type DomainRepWithCategory = DomainRep & {
  category: Label
}

const isDomainInFilter = (filter: Filter, domainRef: DomainRef): boolean => {
  switch (filter.type) {
    case 'filter.filter':
      return filter.domain === domainRef
    case 'filter.and':
    case 'filter.or':
      return filter.filters.some(f => isDomainInFilter(f, domainRef))
    default:
      return false
  }
}
export const getDomainRepItems = (
  domainReps: DomainRep[],
  dynamicT: DynamicT,
  filter: Filter,
  filterUsedDomains: boolean = true
): MenuItemWithDomainRep[] => {
  const categories = [
    ...groupBy(
      domainReps.filter((item): item is DomainRepWithCategory => item.category !== undefined),
      d => {
        return labelToString(d.category, dynamicT)
      }
    ).entries(),
  ]

  const singleItems: MenuItemWithDomainRep[] = domainReps
    .filter(d => d.category === undefined)
    .map(d => ({
      id: domainRefHash(d.ref),
      type: 'label' as const,
      label: labelToString(d.label, dynamicT),
      value: d,
    }))
  return [
    ...singleItems,
    ...categories.map(([category, domainReps]) => ({
      id: category,
      type: 'nested' as const,
      label: category,
      menuItems: domainReps
        .filter(domainRep => {
          if (filterUsedDomains && isDomainInFilter(filter, domainRep.ref)) {
            return false
          }

          if (domainRep.domain.type === 'domain.choices' && domainRep.domain.choices.length === 0) {
            return false
          }

          return true
        })
        .map(d => ({
          id: domainRefHash(d.ref),
          type: 'label' as const,
          label: labelToString(d.label, dynamicT),
          value: d,
        })),
    })),
  ]
}
