import { CreateEntity } from 'sierra-domain/api/entities'
import { assertNever } from 'sierra-domain/utils'

export function getCreateEntityHref(entity: CreateEntity): string {
  switch (entity.entityType) {
    case 'native:self-paced':
      return `/create/s/${entity.id}/`
    case 'native:live':
      return `/create/l/${entity.id}/`
    case 'path':
      return `/manage/paths/${entity.id}`
    default:
      assertNever(entity)
  }
}
