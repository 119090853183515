import { useCallback, useEffect, useState } from 'react'
import { useTranslation } from 'sierra-client/hooks/use-translation'
import { TranslationFunctions } from 'sierra-client/hooks/use-translation/types'
import { embedFileNames, fileNames } from 'sierra-client/views/flexible-content/file-names'
import { SlateDocumentMap } from 'sierra-domain/collaboration/serialization'
import { DropAWordData, EmbedData, File, FileData, isSlateFile } from 'sierra-domain/flexible-content/types'
import { SlateDocument } from 'sierra-domain/v3-author'
import { getSlateDocument, getSlateDocumentSharedType } from 'sierra-domain/v3-author/slate-yjs-extension'
import { Node } from 'slate'
import * as Y from 'yjs'

const trimWords = (inputString: string): string => {
  const expString = inputString.split(/\s+/, 10)
  const outputString = expString.join(' ')
  return outputString
}

export const getDefaultFileName = (
  fileData: { type: FileData['type']; urlType?: EmbedData['urlType'] },
  t: TranslationFunctions['t']
): string => {
  if (fileData.type === 'embed' && fileData.urlType !== undefined) {
    return embedFileNames[fileData.urlType]
  }
  return t(fileNames[fileData.type])
}

/**
 * This logic is also implemented in the backend to index titles for search.
 * If changing this logic, make sure to change it also in the backend.
 * com.sanalabs.common.domain.Course.NativeSelfPacedContent.title
 *
 * Update 2024-05-18:
 * I couldn't find the implementation of this in the backend. I inlined some code
 * from the previous textInHeadingsOrParagraphs function, but the behavior should
 * be the same. /damjan
 */
export const getFileTitleFromSlateDocument = (slateDocument: SlateDocument): string | undefined => {
  for (const rootNode of slateDocument) {
    for (const [node] of Node.elements(rootNode)) {
      if (!(node.type === 'heading' || node.type === 'paragraph')) continue

      const potentialTitle = Array.from(Node.texts(node))
        .map(([{ text }]) => text)
        .join('')

      if (potentialTitle.trim().length > 0) {
        return trimWords(potentialTitle)
      }
    }
  }

  return undefined
}

const getDropAWordTitle = (fileData: DropAWordData, t: TranslationFunctions['t']): string => {
  return fileData.title === undefined || fileData.title === '' ? t('dictionary.drop-a-word') : fileData.title
}

const getFileTitle = ({
  file,
  getSlateDoc,
  t,
}: {
  file: File
  getSlateDoc: () => SlateDocument
  t: TranslationFunctions['t']
}): string => {
  if (file.title !== undefined) {
    return file.title
  }

  if (file.data.type === 'drop-a-word') {
    return getDropAWordTitle(file.data, t)
  }

  if (file.data.type === 'notepad' || file.data.type === 'external-notepad') {
    return getDefaultFileName(file.data, t)
  }

  const slateDocumentTitle = getFileTitleFromSlateDocument(getSlateDoc())

  return slateDocumentTitle ?? getDefaultFileName(file.data, t)
}

type GetFileTitle = (file: File) => string

export function useGetFileTitle(data: Y.Doc | SlateDocumentMap): GetFileTitle {
  const { t } = useTranslation()

  return useCallback(
    (file: File): string => {
      const getSlateDoc = (): SlateDocument => {
        if (data instanceof Y.Doc) {
          if (isSlateFile(file)) {
            return getSlateDocument(data, file.id)
          } else {
            return []
          }
        } else {
          return data[file.id]?.nodes ?? []
        }
      }

      return getFileTitle({ file, getSlateDoc, t })
    },
    [t, data]
  )
}

export const useFileTitle = (yDoc: Y.Doc, file: File): string => {
  const { t } = useTranslation()
  const [title, setTitle] = useState<string>(() => getFileTitle({ file, getSlateDoc: () => [], t }))

  useEffect(() => {
    if (file.title !== undefined) {
      setTitle(file.title)
      return
    }

    if (file.data.type === 'drop-a-word' && file.data.title !== undefined) {
      setTitle(file.data.title)
      return
    }

    // With external-notepads (see: https://github.com/sanalabs/sierra-platform/blob/master/docs/external-notepads.md)
    // we will no longer support syncing the notepad's document content with the file title. This is because
    // external-notepads will have their own yDoc for content which we will only connect to when opening that notepad.
    if (file.data.type === 'notepad' || file.data.type === 'external-notepad') {
      return
    }

    if (!isSlateFile(file)) {
      return
    }

    function onDocumentChanged(): void {
      const newTitle = getFileTitle({ file, getSlateDoc: () => getSlateDocument(yDoc, file.id), t })
      setTitle(newTitle)
    }

    onDocumentChanged()

    const slateSharedType = getSlateDocumentSharedType(yDoc, file.id)

    slateSharedType.observeDeep(onDocumentChanged)
    return () => slateSharedType.unobserveDeep(onDocumentChanged)
  }, [file, t, yDoc])

  return title
}
