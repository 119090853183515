import { HeaderProps } from 'react-table'
import { Icon, TruncatedTextWithTooltip } from 'sierra-ui/components'
import { Spacer, View } from 'sierra-ui/primitives'
import styled from 'styled-components'

const RotateIcon = styled(Icon)<{ $rotate: number }>`
  transform: rotate(${p => p.$rotate}deg);
`

const InlineView = styled(View).attrs({ alignItems: 'center', gap: 'none' })`
  display: inline-flex;
`

export const SortableHeader = <T extends Record<string, unknown> = Record<string, unknown>>({
  label,
  column,
}: HeaderProps<T> & { label: string }): JSX.Element => {
  return (
    <InlineView {...column.getSortByToggleProps()}>
      <TruncatedTextWithTooltip bold={column.isSorted} size='small'>
        {label}
      </TruncatedTextWithTooltip>
      <Spacer size='4' />
      {column.isSorted && (
        <RotateIcon
          iconId={'chevron--down--small'}
          $rotate={column.isSortedDesc === true ? 0 : 180}
          size='size-16'
        />
      )}
    </InlineView>
  )
}
