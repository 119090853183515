import { motion } from 'framer-motion'
import { Text, View } from 'sierra-ui/primitives'
import { spacing, token } from 'sierra-ui/theming'
import { breakpoints } from 'sierra-ui/theming/breakpoints'
import styled, { css } from 'styled-components'

export const HasLines = styled(Text).attrs({
  size: 'small',
  color: 'grey25',
})`
  width: 100%;
  display: flex;
  align-items: center;
  text-align: center;
  justify-content: center;
  margin-top: 0.6rem;
  margin-bottom: 0.2rem;

  &::before,
  &::after {
    content: '';
    flex-grow: 1;
    height: 1px;
    background: ${p => p.theme.color.grey10};
    min-width: 40px;
    display: inline-block;
    vertical-align: middle;
  }

  &::before {
    margin-right: 1rem;
  }

  &::after {
    margin-left: 1rem;
  }
`

export const AuthenticationContainer = styled.div`
  width: 100%;
  max-width: 40rem;
  margin: auto;
  padding-top: 4rem;
  padding-bottom: 4rem;
`

export const AuthenticationView = styled(View)`
  width: 100%;
`

export const SetupWrapper = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
  padding-top: ${spacing['96']};
  padding-bottom: 10rem;

  @media screen and (min-width: ${breakpoints.phone}) {
    padding-top: 10rem;
    padding-bottom: ${spacing['96']};
  }
`

export const SetupContainer = styled.div`
  max-width: 68rem;
  margin-left: auto;
  margin-right: auto;
`

export const SetupGrid = styled.div`
  display: grid;
  grid-template-columns: repeat(auto-fill, minmax(20rem, 1fr));
  gap: 1.5rem;
`

export const ScaleOnHover = styled(motion.div)`
  backface-visibility: hidden;
  -webkit-font-smoothing: antialiased;
  will-change: transform, scale;
  transform: translate3d(0, 0, 0);
`

export const SetupCard = styled.div<{ $active: boolean; $large?: boolean }>`
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  padding: 1.5rem;
  border: 1px solid ${token('border/default')};
  background-color: ${token('surface/default')};
  border-radius: ${p => p.theme.borderRadius.regular};
  min-height: ${p => (p.$large === true ? '18rem' : '12rem')};
  cursor: pointer;
  transition: background-color 0.2s ease;

  @media screen and (min-width: ${breakpoints.phone}) {
    min-height: ${p => (p.$large === true ? '22.5rem' : '12rem')};
  }

  ${p =>
    p.$active === true &&
    css`
      background-color: ${p.$large === true ? '#C8258C' : token('org/primary')};

      &:hover {
        background-color: ${p.$large === true ? '#C8258C' : token('org/primary')};
      }
    `}
`
