import React, { useEffect, useState } from 'react'
import { TimelinePanel } from 'sierra-client/features/program'
import { useTranslation } from 'sierra-client/hooks/use-translation'
import { ProgramPanelCommon } from 'sierra-client/views/manage/programs/staggered-assignments/panels/common'
import { fetchProgramOutline } from 'sierra-client/views/manage/programs/staggered-assignments/queries/fetch-program-outline'
import { deserializeOutline } from 'sierra-client/views/manage/programs/staggered-assignments/utils'
import { ProgramOutline, UserProgramVersion } from 'sierra-domain/api/manage'
import { ProgramId, UserId } from 'sierra-domain/api/uuid'
import { Panel } from 'sierra-ui/components'
import { Button } from 'sierra-ui/primitives'

const useFetchUserProgramOutline = ({
  programVersion,
  programId,
}: {
  programVersion: UserProgramVersion | undefined
  programId: string
}): ProgramOutline => {
  const [programOutline, setProgramOutline] = useState<ProgramOutline>({ steps: [], sections: [] })

  useEffect(() => {
    void (async () => {
      if (programVersion === undefined) {
        return
      }

      const graphQLOutline = await fetchProgramOutline({ programId, version: programVersion.version })
      setProgramOutline(deserializeOutline(graphQLOutline.program))
    })()
  }, [programId, programVersion])

  return programOutline
}

type ViewUserProgramVersionPanel = {
  open: boolean
  canChangeUserVersion: boolean
  programId: ProgramId
  programVersion: UserProgramVersion | undefined
  userId: UserId | null
  assignLabel: string
  assign: () => void
  onClose: () => void
}

export const ViewUserProgramModal: React.FC<ViewUserProgramVersionPanel> = ({
  open,
  canChangeUserVersion,
  programId,
  programVersion,
  userId,
  assignLabel,
  assign,
  onClose,
}) => {
  const { t } = useTranslation()
  const programOutline = useFetchUserProgramOutline({ programId, programVersion })

  return (
    <Panel size={{ width: 650 }} open={open} onClose={onClose} animation='slide-right' padding='none'>
      <ProgramPanelCommon.LayoutWrapper>
        {userId === null || programVersion === undefined ? null : (
          <TimelinePanel
            programOutline={programOutline}
            userId={userId}
            programId={programId}
            programVersionId={programVersion}
          />
        )}

        <ProgramPanelCommon.OuterPanelFooterWrapper justifyContent='space-between'>
          {canChangeUserVersion && programVersion?.isUserInLastVersion !== true ? (
            <Button variant='secondary' onClick={assign}>
              {assignLabel}
            </Button>
          ) : (
            <div />
          )}
          <Button onClick={onClose}>{t('dictionary.done')}</Button>
        </ProgramPanelCommon.OuterPanelFooterWrapper>
      </ProgramPanelCommon.LayoutWrapper>
    </Panel>
  )
}
