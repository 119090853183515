import { useCallback, useEffect, useMemo } from 'react'
import { HorizontalLineDivider } from 'sierra-client/components/common/horizontal-line-divider'
import { useLiveSessionContext } from 'sierra-client/components/liveV2/contexts/live-session-data'
import { NextCardImagePreload } from 'sierra-client/components/liveV2/file-navigation/next-card-image-preload'
import {
  HeaderButtonGroupWrapper,
  HeaderGroupIconButton,
} from 'sierra-client/components/liveV2/header-buttons'
import { useGoToNode } from 'sierra-client/components/liveV2/hooks/use-go-to-node'
import { useSelectCurrentCard } from 'sierra-client/components/liveV2/hooks/use-select-current-card'
import { useTranslation } from 'sierra-client/hooks/use-translation'
import { selectFlexibleContentChildIds } from 'sierra-client/state/flexible-content/selectors'
import { useDispatch, useSelector } from 'sierra-client/state/hooks'
import { selectKeyboardNavigationEnabled } from 'sierra-client/state/live/selectors'
import { FileId } from 'sierra-domain/flexible-content/identifiers'
import { guardWith } from 'sierra-domain/utils'

export const FileNavigationButtons = (): JSX.Element | null => {
  const dispatch = useDispatch()
  const { flexibleContentId } = useLiveSessionContext().data
  const currentCard = useSelectCurrentCard()
  const { t } = useTranslation()

  const keyboardNavigationEnabled = useSelector(selectKeyboardNavigationEnabled)

  const nodesIds = useSelector(state =>
    selectFlexibleContentChildIds(state, flexibleContentId, 'folder:root')
  )

  const fileIds = useMemo(() => nodesIds.filter(guardWith(FileId)), [nodesIds])
  const currentCardId = currentCard?.id ?? fileIds[0]

  const currentCardIdIndex = currentCardId !== undefined ? fileIds.indexOf(currentCardId) : undefined

  const prevCardId = currentCardIdIndex !== undefined ? fileIds[currentCardIdIndex - 1] : undefined
  const nextCardId = currentCardIdIndex !== undefined ? fileIds[currentCardIdIndex + 1] : undefined
  const goToNode = useGoToNode()

  const nextCard = useCallback((): void => {
    if (nextCardId !== undefined) {
      goToNode(nextCardId)
    }
  }, [goToNode, nextCardId])

  const prevCard = useCallback((): void => {
    if (prevCardId !== undefined) {
      goToNode(prevCardId)
    }
  }, [goToNode, prevCardId])

  useEffect(() => {
    if (nodesIds.length === 0 || !keyboardNavigationEnabled) return

    const handleKey = (evt: KeyboardEvent): void => {
      // Don't act if an input field is the element
      const target = evt.target as HTMLElement | undefined
      if (target && 'tagName' in target) {
        const tag = target.tagName.toLowerCase()
        if (tag === 'input' || tag === 'textarea' || tag === 'select') {
          return
        }
      }

      const isTiptapInput =
        target?.classList.contains('ProseMirror') === true || target?.classList.contains('tiptap') === true

      if (isTiptapInput) {
        return
      }

      switch (evt.key) {
        case 'ArrowRight':
        case 'ArrowDown':
          nextCard()
          break
        case 'ArrowLeft':
        case 'ArrowUp':
          prevCard()
          break
      }
    }

    window.addEventListener('keydown', handleKey)

    return () => {
      window.removeEventListener('keydown', handleKey)
    }
  }, [dispatch, nextCard, nodesIds.length, prevCard, keyboardNavigationEnabled])

  return (
    <>
      <HeaderButtonGroupWrapper gap='none'>
        <HeaderGroupIconButton
          iconId={'previous--filled'}
          onClick={prevCard}
          tooltip={t('live.previous-card')}
          disabled={prevCardId === undefined}
        />
        <HorizontalLineDivider bright />
        <HeaderGroupIconButton
          iconId={'next--filled'}
          onClick={nextCard}
          tooltip={t('live.next-card')}
          disabled={nextCardId === undefined}
        />
      </HeaderButtonGroupWrapper>
      {nextCardId !== undefined && (
        <NextCardImagePreload nextCardId={nextCardId} flexibleContentId={flexibleContentId} />
      )}
    </>
  )
}
