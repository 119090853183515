import { sha512 } from 'js-sha512'
import _ from 'lodash'
import { useMemo } from 'react'
import { tagsDataSelectors } from 'sierra-client/state/content/selectors'
import { useSelector } from 'sierra-client/state/hooks'
import { selectUser } from 'sierra-client/state/user/user-selector'
import { TagData } from 'sierra-domain/content/tag'

function hash(userId: string, tagId: string): string {
  return sha512.hex(`${userId}:${tagId}`)
}

type TagWithId = { id: string } & TagData

export function useOrderedTags(): TagWithId[] {
  const user = useSelector(selectUser)
  const tags = useSelector(tagsDataSelectors.selectEntities)
  const userId = user?.uuid

  const orderedTags = useMemo(() => {
    if (userId === undefined) return []

    const sortedTags = _.chain(tags)
      .toPairs()
      .reduce<TagWithId[]>((acc, [id, tag]) => {
        return [...acc, { id, name: tag.data.name, description: tag.data.description }]
      }, [])
      .sortBy(tag => hash(userId, tag.id))
      .value()

    return sortedTags
  }, [userId, tags])

  return orderedTags
}

export function useRankedTags(): TagWithId[] {
  const user = useSelector(selectUser)
  const tags = useSelector(tagsDataSelectors.selectEntities)
  const userId = user?.uuid

  const orderedTags = useMemo(() => {
    if (userId === undefined) return []

    const sortedTags = _.chain(tags)
      .toPairs()
      .reduce<TagWithId[]>((acc, [id, tag]) => {
        return [...acc, { id, name: tag.data.name, description: tag.data.description }]
      }, [])
      .value()

    return sortedTags
  }, [userId, tags])

  return orderedTags
}
