import { createContext, useContext } from 'react'

type SetCardProgressData = {
  setCardStarted: () => void
  setCardCompleted: () => void
  setCardViewedAndCompleted: () => void
}

export const SetCardProgressContext = createContext<SetCardProgressData | undefined>(undefined)

export const useSetCardProgress = (): SetCardProgressData => {
  const value = useContext(SetCardProgressContext)
  if (value === undefined) {
    throw new Error('useSetCardProgress needs to be wrapped in SetCardProgressProvider')
  }

  return value
}
