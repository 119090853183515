import { createAsyncThunk, createSlice } from '@reduxjs/toolkit'
import { postWithUserErrorException } from 'sierra-client/state/api'
import { OrganizationState } from 'sierra-client/state/organization/types'
import { RootState } from 'sierra-client/state/types'
import { Organization } from 'sierra-domain/organization'
import { XRealtimeUserGetOrganization } from 'sierra-domain/routes'

export const fetchOrganization = createAsyncThunk<Organization, void, { state: RootState }>(
  'organization/fetch',
  async (_, { dispatch }) => {
    const organization = await postWithUserErrorException(XRealtimeUserGetOrganization, {}, dispatch)
    return organization
  }
)

const initialState: OrganizationState = { status: { type: 'loading' } }

export const organizationSlice = createSlice({
  name: 'organization',
  initialState,
  reducers: {
    organizationCleared(state) {
      state.status = { type: 'loading' }
    },
  },
  extraReducers: builder => {
    builder.addCase(fetchOrganization.fulfilled, (state, { payload }) => {
      state.status = { type: 'loaded', organization: payload }
    })
  },
})
