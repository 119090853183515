import { Icon } from 'sierra-ui/components'
import { IconButton, Pill } from 'sierra-ui/primitives'
import { token } from 'sierra-ui/theming'
import { focusStateSmall } from 'sierra-ui/utils/focus-ring'
import styled, { css } from 'styled-components'

export const RemoveButtonInPills = styled(IconButton).attrs({
  variant: 'transparent',
  iconId: 'close',
  size: 'small',
})`
  height: fit-content;
  color: ${token('foreground/muted')};

  &:hover {
    color: ${token('foreground/primary')};
    background: none;
  }
`

export const GreyPill = styled(Pill)<{ error?: boolean }>`
  border-color: transparent;
  background: ${token('form/background/2')};
  min-height: 36px;

  ${p =>
    p.error === true &&
    css`
      color: ${token('destructive/background')};
      background: transparent;
      border: 1px solid ${token('destructive/background')};
    `}
  &[data-highlighted] {
    ${focusStateSmall}
  }
`

export const DeleteIcon = styled(Icon).attrs({ size: 'size-16', iconId: 'close' })`
  cursor: pointer;
`
