import { DateTime } from 'luxon'
import { default as React, useMemo } from 'react'
import { useTranslation } from 'sierra-client/hooks/use-translation'
import { UseSelfEnroll } from 'sierra-client/views/learner/self-enroll-live-session/hooks'
import { SelfEnrollSession } from 'sierra-domain/content/session'
import { InputDatePicker } from 'sierra-ui/components'
import { InputStyles, Text, View } from 'sierra-ui/primitives'
import { token } from 'sierra-ui/theming'
import styled, { css } from 'styled-components'

const TimePickerContainer = styled(View)`
  ${InputStyles};
  padding: 0 1rem;
  flex: 0;
`

type TimeOption = {
  value: string
  label: string
}

const TimePickerSelect = styled.select<{ $faded: boolean }>`
  display: inline-block;
  background: none;
  cursor: pointer;
  transition: color 0.2s;

  &:hover {
    color: ${p => p.theme.color.grey80};
  }

  ${p =>
    p.$faded &&
    css`
      color: ${token('foreground/primary')};
    `};
`

type TimePickerProps = {
  options: TimeOption[]
  value: string | undefined
  onChange: (value: TimeOption | undefined) => void
  defaultOption: TimeOption
}

const TimePicker: React.FC<TimePickerProps> = ({ options, value, onChange, defaultOption }) => {
  const valueMap = useMemo(() => new Map(options.map(option => [option.value, option])), [options])

  return (
    <TimePickerSelect
      value={value ?? defaultOption.value}
      onChange={e => onChange(valueMap.get(e.target.value))}
      $faded={value === undefined}
    >
      <option value={defaultOption.value} hidden>
        {defaultOption.label}
      </option>
      {options.map(option => (
        <option value={option.value} key={option.value}>
          {option.label}
        </option>
      ))}
    </TimePickerSelect>
  )
}

// 00:00, 00:30, 01:30 etc
const useTimeOptions = (): TimeOption[] =>
  useMemo(() => {
    const result = []

    for (let h = 0; h < 24; h++) {
      for (let m = 0; m < 60; m += 30) {
        const dt = DateTime.fromObject({ hour: h, minute: m })
        result.push({
          value: dt.toLocaleString(DateTime.TIME_24_SIMPLE),
          label: dt.toLocaleString(DateTime.TIME_SIMPLE),
        })
      }
    }

    const dt = DateTime.fromObject({ hour: 23, minute: 59 })
    result.push({
      value: dt.toLocaleString(DateTime.TIME_24_SIMPLE),
      label: dt.toLocaleString(DateTime.TIME_SIMPLE),
    })

    return result
  }, [])

type SessionDateTimeFilterProps = {
  sessions: SelfEnrollSession[]
  filter: UseSelfEnroll['filter']
  updateFilter: UseSelfEnroll['updateFilter']
}

export const SessionDateTimeFilter: React.FC<SessionDateTimeFilterProps> = ({ filter, updateFilter }) => {
  const { t } = useTranslation()

  const timeOptions = useTimeOptions()

  const timeFrom = useMemo(
    () => (filter.timeFrom !== undefined ? timeOptions.find(x => x.value === filter.timeFrom) : undefined),
    [timeOptions, filter.timeFrom]
  )

  const timeTo = useMemo(
    () => (filter.timeTo !== undefined ? timeOptions.find(x => x.value === filter.timeTo) : undefined),
    [timeOptions, filter.timeTo]
  )

  const defaultFrom = timeOptions[0]!
  const defaultTo = timeOptions[timeOptions.length - 1]!
  return (
    <>
      <InputDatePicker
        placeholder={t('filter.domain.type.label.date')}
        value={filter.date}
        onChange={date => {
          updateFilter({
            date,
          })
        }}
      />
      <TimePickerContainer>
        <TimePicker
          options={timeOptions}
          value={timeFrom?.value}
          onChange={option => updateFilter({ timeFrom: option?.value })}
          defaultOption={defaultFrom}
        />
        <Text color='foreground/muted' size='small'>
          –
        </Text>
        <TimePicker
          options={timeOptions}
          value={timeTo?.value}
          onChange={option => updateFilter({ timeTo: option?.value })}
          defaultOption={defaultTo}
        />
      </TimePickerContainer>
    </>
  )
}
