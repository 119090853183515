import { useMemo } from 'react'
import { config } from 'sierra-client/config/global-config'
import { useTranslation } from 'sierra-client/hooks/use-translation'
import { TranslationKey } from 'sierra-client/hooks/use-translation/types'
import { ClickOverlay } from 'sierra-client/layout/header/components/dropdown-menu/click-overlay'
import { useUserMenu } from 'sierra-client/layout/header/components/user-menu'
import { AnimatedMenuIcon } from 'sierra-client/layout/header/home/components/animated-menu-icon'
import { HeaderLogo } from 'sierra-client/layout/header/home/components/logo'
import { getGlobalRouter } from 'sierra-client/router'
import { useIsTablet } from 'sierra-client/state/browser/selectors'
import {
  selectCurrentCourse,
  selectCurrentPath,
  selectCurrentProgramId,
  selectPrependUrlWithProgramAndPath,
} from 'sierra-client/state/content/selectors'
import { useSelector } from 'sierra-client/state/hooks'
import { selectGetPassedTimestampByCourseId } from 'sierra-client/state/v2/selectors'
import { getHrefToCourse, toCourseForHref } from 'sierra-client/views/learner/path/get-href-to-course'
import { Button, IconButton, Spacer, Text, View } from 'sierra-ui/primitives'
import styled from 'styled-components'

const StyledAnimatedMenuIcon = styled(AnimatedMenuIcon)`
  margin-left: auto;
`

type ScormNextUp = {
  labelI18NArgs: [TranslationKey, Record<string, unknown>?]
  courseName?: string
  buttonI18nArgs: [TranslationKey, Record<string, unknown>?]
  href: string
}

const HeaderTextWrapper = styled.div`
  text-align: center;
  width: 100%;
  padding: 16px 30px 16px 30px;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
`

export const ScormHeader = (): JSX.Element => {
  const { isMenuOpen, closeMenu, toggleMenu, userMenuElement } = useUserMenu()
  const { t } = useTranslation()

  const prependUrlWithProgramAndPath = useSelector(selectPrependUrlWithProgramAndPath)
  const isTablet = useIsTablet() /* Same as search */
  const currentCourse = useSelector(selectCurrentCourse)
  const currentCourseId = currentCourse?.id

  const currentPath = useSelector(selectCurrentPath)
  const passedTimestamp = useSelector(selectGetPassedTimestampByCourseId)(currentCourseId)

  const currentProgramId = useSelector(selectCurrentProgramId)

  const potentialCourseGroupId = currentCourse?.type === 'scorm' ? currentCourse.courseGroupId : undefined

  const nextCourse = useMemo(() => {
    if (passedTimestamp === undefined) return undefined
    if (currentPath === undefined) return undefined
    // Finds current course ID
    const currentCourseIndexInPath = currentPath.courses.findIndex(
      c => c.id === currentCourseId || c.id === potentialCourseGroupId
    )

    // Slices paths courses and get only the ones after current course
    const coursesAfterCurrent = currentPath.courses.slice(currentCourseIndexInPath + 1)
    // Get the courses in the path before current course
    const coursesBeforeCurrent = currentPath.courses.slice(0, currentCourseIndexInPath)
    // Order is important, we first try to show the next courses, if we don't have any then the ones the user skipped
    const specialSortedArray = [...coursesAfterCurrent, ...coursesBeforeCurrent]
    // Finds the first without passedTimestamp
    const firstIncompleteNextCourse = specialSortedArray.find(c => c.status.passedTimestamp === undefined)

    // No more incomplete courses in path
    if (firstIncompleteNextCourse === undefined) {
      return {
        labelI18NArgs: ['content.path.you-completed-path'],
        courseName: undefined,
        buttonI18nArgs: ['topbar.home'],
        href: '/',
      } as ScormNextUp
    }

    // Next course
    return {
      labelI18NArgs: ['dictionary.next-up'],
      courseName: firstIncompleteNextCourse.title,
      buttonI18nArgs: ['scorm.launch'],
      href: getHrefToCourse({
        course: toCourseForHref(firstIncompleteNextCourse),
        pathId: currentPath.id,
        programId: currentProgramId,
      }),
    } as ScormNextUp
  }, [currentCourseId, currentPath, currentProgramId, passedTimestamp, potentialCourseGroupId])

  return (
    <HeaderTextWrapper>
      {isTablet ? (
        <>
          <HeaderLogo />
          {!config.scorm.isScorm && <StyledAnimatedMenuIcon isOpen={isMenuOpen} onClick={toggleMenu} />}
          {userMenuElement}
        </>
      ) : (
        <>
          <ClickOverlay isVisible={isMenuOpen} onClick={closeMenu} />
          <View grow justifyContent='space-between'>
            <View grow justifyContent='flex-end' gap='xsmall'>
              {nextCourse !== undefined && (
                <View gap='none'>
                  <Text size='small'>{t(...nextCourse.labelI18NArgs)}</Text>
                  <Spacer size='xxsmall' />
                  <Text size='small' bold>
                    {nextCourse.courseName}
                  </Text>
                  <Spacer />

                  <Button href={nextCourse.href}>{t(...nextCourse.buttonI18nArgs)}</Button>
                </View>
              )}
              <IconButton
                variant='transparent'
                iconId='logout'
                tooltip={t('session.continue-later')}
                size='small'
                onClick={() =>
                  void getGlobalRouter().navigate({
                    to: prependUrlWithProgramAndPath(
                      currentCourseId === undefined ? '/' : `/course/${currentCourseId}`
                    ),
                  })
                }
              />
            </View>
          </View>
        </>
      )}
    </HeaderTextWrapper>
  )
}
