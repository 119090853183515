import React, { useEffect, useState } from 'react'
import { BAR_CHART_MAX_LIMIT } from 'sierra-client/features/insights/display-widgets/bar-chart-viewer/bar-chart-viewer'
import { useTranslation } from 'sierra-client/hooks/use-translation'
import { ActualGhostButton } from 'sierra-client/views/workspace/learn/components'
import { MAX_LIMIT, MIN_LIMIT, Widget } from 'sierra-domain/api/insights'
import { FormElement } from 'sierra-ui/components'
import { InputPrimitive } from 'sierra-ui/primitives'
import { MenuDropdownPrimitive } from 'sierra-ui/primitives/menu-dropdown'

type LimitSelectorProps = {
  limit?: number
  onSetLimit: (newLimit?: number) => void
  widgetType: Widget['type']
}

export const LimitSelector: React.FC<LimitSelectorProps> = ({ limit, onSetLimit, widgetType }) => {
  const { t } = useTranslation()
  const [limitMenuItem, setLimitMenuItem] = useState('')

  const maxLimitForWidget = widgetType === 'widget.bar-chart' ? BAR_CHART_MAX_LIMIT : MAX_LIMIT

  useEffect(() => {
    if (limit === undefined) {
      setLimitMenuItem('')
    } else {
      setLimitMenuItem(`${limit}`)
    }
  }, [limit])

  const handleSetLimit = (): void => {
    const newLimit = Number.parseInt(limitMenuItem)
    if (newLimit >= MIN_LIMIT && newLimit <= maxLimitForWidget) {
      onSetLimit(newLimit)
    }
  }

  const isAboveLimit =
    parseInt(limitMenuItem) > maxLimitForWidget || (limit !== undefined && limit > maxLimitForWidget)
  return (
    <MenuDropdownPrimitive
      menuItems={[
        { type: 'label', label: t('manage.insights.limits.show-all'), id: 'show-all' },
        {
          type: 'label',
          label: t('manage.insights.limits.show-x', { x: 5 }),
          id: 'show-first-5',
        },
        {
          type: 'label',
          label: t('manage.insights.limits.show-x', { x: 10 }),
          id: 'show-first-10',
        },
        {
          id: 'show-x',
          type: 'canvas',
          render: () => (
            <FormElement
              label={t('manage.insights.limits.show-x', { x: '...' })}
              helper={
                isAboveLimit
                  ? t('manage.insights.widgetbuilder.max-limit-helper', { x: maxLimitForWidget })
                  : undefined
              }
            >
              <InputPrimitive
                value={limitMenuItem}
                onChange={e => setLimitMenuItem(e.target.value)}
                onKeyDown={e => {
                  if (e.key === 'Enter') handleSetLimit()
                }}
                onBlur={handleSetLimit}
              />
            </FormElement>
          ),
        },
      ]}
      closeOnSelect
      onSelect={(item, e) => {
        if (item.id === 'show-x') {
          e.preventDefault() // Do not close menu if pressing the canvas item
        }
        switch (item.id) {
          case 'show-all':
            return onSetLimit(undefined)
          case 'show-first-5':
            return onSetLimit(5)
          case 'show-first-10':
            return onSetLimit(10)
        }
      }}
      renderTrigger={() => (
        <ActualGhostButton icon='list--numbered'>
          {limit === undefined
            ? t('manage.insights.limits.show-all')
            : t('manage.insights.limits.show-x', { x: limit })}
        </ActualGhostButton>
      )}
    />
  )
}
