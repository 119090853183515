import _ from 'lodash'
import { DateTime } from 'luxon'
import { useMemo } from 'react'
import { UploadedFile } from 'sierra-client/views/manage/certificates/issue-certificate-panel/shared'
import { DraftIssued } from 'sierra-client/views/manage/certificates/issue-certificate-panel/types'
import { IdentityWithMetadata } from 'sierra-domain/api/manage'
import { isDefined, isEmptyArray, isSingleArray } from 'sierra-domain/utils'

/**
 * Type to help us deal with values that should be considered mixed, empty or regular value
 * This is to correctly model and render the views in our bulk edits
 */
export type MixedValue<T> =
  | {
      type: 'mixed'
    }
  | {
      type: 'regular'
      value: T
    }
  | {
      type: 'empty'
    }

type MixedData = {
  file: MixedValue<UploadedFile>
  expiresAt: MixedValue<DateTime>
  issuedAt: MixedValue<DateTime>
  issuer: MixedValue<IdentityWithMetadata>
  note: MixedValue<string>
}

export const getMixedValue = <T>(
  values: Array<T | undefined>,
  cmp: (t1: T, t2: T) => boolean = _.isEqual
): MixedValue<T> => {
  const realValues = values.filter(isDefined)

  const hasUndefined = realValues.length !== values.length
  const uniqueValues = _.uniqWith(realValues, cmp)

  // No unique values
  if (isEmptyArray(uniqueValues)) {
    return { type: 'empty' }
  }

  // We found unique value
  if (isSingleArray(uniqueValues)) {
    // If we find a single unique value but it is accompanied by undefiend values, its not actually unique
    if (hasUndefined) {
      return { type: 'mixed' }
    }
    return { type: 'regular', value: uniqueValues[0] }
  }

  // Also considered mixed if there are multiple unique values
  if (uniqueValues.length > 1) {
    return { type: 'mixed' }
  }

  return { type: 'empty' }
}

/**
 * Encapsulate all logic that goes from an array of issue draft to the `Mixed` values.
 * See the `MixedValue` type for more info
 */
export const useMixedValues = (issued: DraftIssued[]): MixedData => {
  const defaultNote = useMemo(
    (): MixedValue<string> =>
      getMixedValue(
        issued.map(({ supportingNote }) => supportingNote),
        _.isEqual
      ),
    [issued]
  )

  const defaultFile = useMemo(
    (): MixedValue<UploadedFile> =>
      getMixedValue(
        issued.map(({ supportingFile }) => supportingFile),
        (a, b) => a.file.name === b.file.name && a.file.size === b.file.size
      ),
    [issued]
  )

  const defaultExpiryAt = useMemo(
    (): MixedValue<DateTime> =>
      getMixedValue(
        issued.map(({ expiresAt }) => expiresAt),
        (a, b) => a.hasSame(b, 'day')
      ),
    [issued]
  )

  const defaultIssueAt = useMemo(
    (): MixedValue<DateTime> =>
      getMixedValue(
        issued.map(({ issuedAt }) => issuedAt),
        (a, b) => a.hasSame(b, 'day')
      ),
    [issued]
  )

  const defaultIssuer = useMemo(
    (): MixedValue<IdentityWithMetadata> =>
      getMixedValue(
        issued.map(({ issuedByProxy }) => issuedByProxy),
        (a, b) => a.id === b.id
      ),
    [issued]
  )

  return {
    file: defaultFile,
    expiresAt: defaultExpiryAt,
    issuedAt: defaultIssueAt,
    issuer: defaultIssuer,
    note: defaultNote,
  }
}
