import { unwrapToTextNodes } from 'sierra-client/views/v3-author/command'
import { isElementType, parentType } from 'sierra-client/views/v3-author/queries'
import { unwrapNonParagraphChildren } from 'sierra-client/views/v3-author/unwrap-non-paragraph-children'
import { nanoid12 } from 'sierra-domain/nanoid-extensions'
import { createHeading, createParagraph } from 'sierra-domain/v3-author/create-blocks'
import { Editor, Element, Node, Transforms } from 'slate'

const debug = (...messages: unknown[]): void => console.debug('[withTextCard]', ...messages)

export const withTitleCard = (editor: Editor): Editor => {
  const { normalizeNode } = editor

  editor.normalizeNode = ([node, path]) => {
    if (isElementType('title-card-heading', node)) {
      // Convert title-card-heading into headings with level 5 (size hSuper)
      const heading = createHeading({ id: node.id, level: 5 })
      debug(`Converting title-card-headings to headings at`, path)
      unwrapToTextNodes(editor, { at: path })
      return Transforms.wrapNodes(editor, heading, { at: path })
    }

    if (Element.isElement(node) && node.type === 'title-card') {
      const children = Array.from(Node.children(editor, path))

      if (parentType(editor, path) !== 'editor') {
        debug('Title-card must be at the root, unwrapping at', JSON.stringify(path))
        return Transforms.unwrapNodes(editor, { at: path })
      }

      // Ensure that there is at least a heading in title-card
      if (children.length === 0) {
        debug('Adding heading in empty title-card at', JSON.stringify(path))
        return Transforms.insertNodes(
          editor,
          { type: 'heading', level: 5, id: nanoid12(), children: [{ text: '' }] },
          { at: path.concat(0) }
        )
      }

      // All nodes after the title must be one of the supported blocks
      const supportedBlocks = [
        'heading',
        'paragraph',
        'bulleted-list',
        'numbered-list',
        'list-item',
        'check-list',
      ]
      for (const [child, childPath] of children) {
        if (!(Element.isElement(child) && supportedBlocks.includes(child.type))) {
          debug(`Converting to paragraph at`, JSON.stringify(path))
          unwrapNonParagraphChildren(editor, [child, childPath])
          return Transforms.wrapNodes(editor, createParagraph(), { at: childPath })
        }
      }
    }

    return normalizeNode([node, path])
  }

  return editor
}
