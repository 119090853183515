import React, { useEffect, useRef } from 'react'
import { useScreenRecordContext } from 'sierra-client/components/liveV2/recording/recording-provider'
import { useIsDebugMode } from 'sierra-client/hooks/use-is-debug-mode'
import { View } from 'sierra-ui/primitives'
import styled from 'styled-components'

const Wrapper = styled(View).attrs({ padding: 'xxsmall' })`
  position: absolute;
  bottom: 16rem;
  right: 2rem;
`

const StyledVideo = styled.video`
  max-width: 100%;
  margin: auto;
`

export const RecordingPreview: React.FC = () => {
  const isDebugMode = useIsDebugMode()
  const screenRecord = useScreenRecordContext()
  const videoRef = useRef<HTMLVideoElement | null>(null)

  useEffect(() => {
    if (isDebugMode && screenRecord.stream !== undefined && videoRef.current !== null) {
      videoRef.current.srcObject = screenRecord.stream
    }
  }, [isDebugMode, videoRef, screenRecord.stream])

  if (!isDebugMode || screenRecord.stream === undefined) return null

  return (
    <Wrapper>
      <StyledVideo ref={videoRef} height={200} autoPlay playsInline muted />
    </Wrapper>
  )
}
