import { createMutableDataTransfer } from 'sierra-client/views/v3-author/paste/create-mutable-data-transfer'
import { isElementType } from 'sierra-client/views/v3-author/queries'
import { Entity } from 'sierra-domain/entity'
import { CheckListItem, CustomElement, CustomText, ListItem, SanaEditor } from 'sierra-domain/v3-author'
import {
  createCheckList,
  createOrderedList,
  createUnorderedList,
} from 'sierra-domain/v3-author/create-blocks'
import { Descendant } from 'slate'

function getSlateFragment(data: DataTransfer): Descendant[] | undefined {
  const fragment = data.getData('application/x-slate-fragment')
  if (data.getData('application/x-slate-fragment')) {
    const decoded = decodeURIComponent(window.atob(fragment))
    const parsed = CustomElement.or(CustomText).array().safeParse(JSON.parse(decoded))
    if (parsed.success) {
      return parsed.data
    }
  }
  return undefined
}

function getListItemType(
  item: ListItem | CheckListItem
): 'check-list-item' | 'ordered-list-item' | 'unordered-list-item' {
  if (item.type === 'check-list-item') return 'check-list-item'
  else return item.ordinal !== undefined ? 'ordered-list-item' : 'unordered-list-item'
}

export function wrapListItems(items: Descendant[]): Descendant[] {
  const input = [...items].reverse()
  const result: Descendant[] = []

  let first = input.pop()
  while (first !== undefined) {
    const tempListItems: Entity<ListItem>[] = []
    const tempCheckListItems: Entity<CheckListItem>[] = []

    if (!isElementType(['list-item', 'check-list-item'], first)) {
      result.push(first)
      first = input.pop()
      continue
    }

    const listType = getListItemType(first)

    while (first && isElementType('list-item', first) && getListItemType(first) === listType) {
      tempListItems.push(first)
      first = input.pop()
    }

    while (first && isElementType('check-list-item', first) && getListItemType(first) === listType) {
      tempCheckListItems.push(first)
      first = input.pop()
    }

    const list =
      listType === 'check-list-item'
        ? createCheckList({ children: tempCheckListItems })
        : listType === 'ordered-list-item'
          ? createOrderedList({ children: tempListItems })
          : createUnorderedList({ children: tempListItems })

    result.push(list)
  }
  return result
}

export function withPasteLists(editor: SanaEditor): SanaEditor {
  const { insertData } = editor

  editor.insertData = data => {
    const fragment = getSlateFragment(data)
    if (fragment !== undefined) {
      const result = wrapListItems(fragment)

      const string = JSON.stringify(result)
      const encoded = window.btoa(encodeURIComponent(string))
      const dataTransfer = createMutableDataTransfer({ basedOn: data })
      dataTransfer.setData('application/x-slate-fragment', encoded)
      insertData(dataTransfer)
      return
    }

    insertData(data)
  }
  return editor
}
