import { useEffect, useMemo, useState } from 'react'
import { LegacyTokenInput } from 'sierra-client/components/common/foreground-button'
import { useNotif } from 'sierra-client/components/common/notifications'
import { usePost } from 'sierra-client/hooks/use-post'
import { useAssetResolver } from 'sierra-client/hooks/use-resolve-asset'
import { useTranslation } from 'sierra-client/hooks/use-translation'
import { addTagAction } from 'sierra-client/state/content/actions'
import { useDispatch } from 'sierra-client/state/hooks'
import { ImageUpload } from 'sierra-client/views/manage/paths/components/image-upload'
import { TagId } from 'sierra-domain/api/nano-id'
import { AssetContext } from 'sierra-domain/asset-context'
import { Tag } from 'sierra-domain/content/tag'
import { ImageUnion } from 'sierra-domain/content/v2/content'
import { nanoid12 } from 'sierra-domain/nanoid-extensions'
import { XRealtimeAdminTagsCreateTag, XRealtimeAdminTagsUpdateTag } from 'sierra-domain/routes'
import { FormElement } from 'sierra-ui/components'
import { Button, Spacer, TextAreaPrimitive, View } from 'sierra-ui/primitives'

type TagFormProps = {
  tag?: Tag
  onAfterSubmit?: () => void
  onCancel?: () => void
}

const DEFAULT_FORM_VALUES: Tag = {
  id: nanoid12() as TagId,
  data: {
    name: '',
    description: '',
  },
  enabled: true,
}

export const TagForm: React.FC<TagFormProps> = ({ tag, onCancel, onAfterSubmit }) => {
  const { t } = useTranslation()
  const [formValues, setFormValues] = useState<Tag>(DEFAULT_FORM_VALUES)
  const [nameError, setNameError] = useState<string | undefined>(undefined)
  const { postWithUserErrorException } = usePost()
  const notifications = useNotif()
  const dispatch = useDispatch()

  const [image, setImage] = useState<ImageUnion | undefined>(
    tag?.data.image !== undefined ? { type: 'url', url: tag.data.image } : undefined
  )

  const assetContext: AssetContext = useMemo(() => ({ type: 'tag' }), [])

  const assetResolver = useAssetResolver({ size: 'default' })

  useEffect(() => {
    if (image !== undefined) {
      setFormValues(values => ({
        ...values,
        data: {
          ...values.data,
          image: assetResolver(image, assetContext),
        },
      }))
    }
  }, [image, assetResolver, assetContext])

  useEffect(() => {
    setFormValues(tag ?? DEFAULT_FORM_VALUES)
  }, [tag])

  const handleBlurName = (): void => {
    if (formValues.data.name.trim() === '') {
      setNameError(t('manage.tag.errors.name'))
    } else {
      setNameError(undefined)
    }
  }

  const handleNameChange = (e: React.ChangeEvent<HTMLInputElement>): void => {
    setFormValues(values => ({
      ...values,
      data: {
        ...values.data,
        name: e.target.value,
      },
    }))
    setNameError(undefined)
  }

  const onSubmit = async (): Promise<void> => {
    if (formValues.data.name.trim() === '') {
      setNameError(t('manage.tag.errors.name'))
      return
    }
    if (tag === undefined) {
      // Create new tag
      const response = await postWithUserErrorException(XRealtimeAdminTagsCreateTag, formValues.data)
      void dispatch(
        addTagAction({
          id: response.id,
          tag: { id: response.id, data: formValues.data, enabled: true },
          courses: [],
          totalCoursesCount: 0,
          learnersCount: 0,
        })
      )
      notifications.push({ type: 'skills-added' })
    } else {
      // Update existing tag
      await postWithUserErrorException(XRealtimeAdminTagsUpdateTag, {
        id: tag.id,
        data: formValues.data,
      })

      notifications.push({ type: 'skill-saved' })
    }

    if (onAfterSubmit !== undefined) {
      onAfterSubmit()
    }
  }

  return (
    <form
      onSubmit={async e => {
        e.preventDefault()
        await onSubmit()
      }}
    >
      <LegacyTokenInput
        id='tag-name'
        label={t('manage.tags.tag-name')}
        placeholder={t('admin.tags.name-placeholder')}
        value={formValues.data.name}
        onChange={handleNameChange}
        isError={nameError !== undefined}
        onBlur={handleBlurName}
        helperText={nameError}
      />

      <FormElement label={t('admin.organization.description')} htmlFor='tag-description'>
        <TextAreaPrimitive
          id='tag-description'
          value={formValues.data.description ?? ''}
          onChange={e =>
            setFormValues(values => ({
              ...values,
              data: {
                ...values.data,
                description: e.target.value,
                image: image !== undefined ? assetResolver(image, assetContext) : undefined,
              },
            }))
          }
          placeholder={t('admin.tags.description-placeholder')}
          rows={3}
          autoExpand
        />
      </FormElement>

      <Spacer size='24' />

      <ImageUpload value={image} onChange={image => setImage(image)} assetContext={assetContext} />
      <View justifyContent='flex-end' marginTop='32'>
        <View gap='8'>
          {onCancel !== undefined && (
            <Button variant='secondary' onClick={onCancel}>
              {t('dictionary.cancel')}
            </Button>
          )}
          <Button type='submit' disabled={nameError !== undefined}>
            {t('dictionary.save')}
          </Button>
        </View>
      </View>
    </form>
  )
}
