import { useHasLiveContext } from 'sierra-client/components/liveV2/live-context'
import { useSelector } from 'sierra-client/state/hooks'
import { selectVideoCallMode } from 'sierra-client/state/live-session/selectors'

export const useBreakoutInSession = (): boolean => {
  const videoCallMode = useSelector(selectVideoCallMode)
  const hasLiveContext = useHasLiveContext()

  return videoCallMode === 'breakout' && hasLiveContext
}
