import { forwardRef } from 'react'
import { BoundingBox } from 'sierra-client/domain/commenting/types'
import { ColorBuilder } from 'sierra-ui/color'
import { TransientProps } from 'sierra-ui/types'
import { unit } from 'sierra-ui/utils'
import styled from 'styled-components'

const LayerContainer = styled.div`
  position: absolute;
  inset: 0;
  pointer-events: none;
`

type TextHighlightRectangleProps = TransientProps<BoundingBox> & {
  color: string
}

const TextHighlightRectangle = styled.div.attrs<TextHighlightRectangleProps>(p => ({
  style: {
    // Note: These values can change often, so we set them directly here instead of in the template block.
    // Otherwise we'll end up generating very many classnames.
    width: unit(p.$width),
    height: unit(p.$height),
    transform: `translate(${unit(p.$x)}, ${unit(p.$y)})`,
  },
}))<TextHighlightRectangleProps>`
  transition: background-color 90ms;
  position: absolute;
  background-color: ${p => p.color};
`

export type InlineTextIndicator = {
  id: string
  opacity: number
  rectangles: DOMRect[]
}

type RangesLayerProps = {
  color: ColorBuilder
  indicators: (InlineTextIndicator | undefined)[]
}

export const TextHighlightsLayer = forwardRef<HTMLDivElement, RangesLayerProps>(
  ({ color, indicators }, ref) => {
    const padding = 1.5

    return (
      <LayerContainer>
        {indicators.flatMap(indicator => {
          return indicator === undefined
            ? []
            : indicator.rectangles.map((rect, index) => (
                <TextHighlightRectangle
                  ref={index === 0 ? ref : null}
                  key={`${indicator.id}-${index}`}
                  $x={rect.x}
                  $y={rect.y - padding}
                  $width={rect.width}
                  $height={rect.height + 2 * padding}
                  color={color.opacity(indicator.opacity).shift(0.1).toString()}
                />
              ))
        })}
      </LayerContainer>
    )
  }
)
