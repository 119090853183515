import { getEmojiDataFromNative } from 'emoji-mart'
import 'emoji-mart/css/emoji-mart.css'
import data from 'emoji-mart/data/apple.json'
import _ from 'lodash'

export const getEmojiData = _.memoize((emojiUnicode: string) =>
  getEmojiDataFromNative(emojiUnicode, 'apple', data)
)

export const getCodeForEmoji = (emojiUnicode: string): string | undefined => {
  const emojiData = getEmojiData(emojiUnicode)

  // eslint-disable-next-line @typescript-eslint/no-unnecessary-condition
  return emojiData?.colons
}
