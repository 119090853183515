import { NotificationContainer, Title } from 'sierra-client/features/activity-center/notifications/components'
import { PlainEntityImage } from 'sierra-client/features/activity-center/notifications/components/entity-image'
import { NotificationIcon } from 'sierra-client/features/activity-center/notifications/components/notification-icon'
import { NotificationTitle } from 'sierra-client/features/activity-center/notifications/components/notification-title'
import { Trans } from 'sierra-client/hooks/use-translation/trans'
import { HomeNotification } from 'sierra-domain/api/learn'
import { Text, View } from 'sierra-ui/primitives'
import { token } from 'sierra-ui/theming'
import styled from 'styled-components'

const PreviewImage = styled.img`
  max-width: 100%;
  border-radius: 8px;
`

const CertificatePreviewContainer = styled.div<{ $background?: string }>`
  height: 112px;
  overflow: hidden;
  border-radius: 16px;
  background: ${token('surface/soft')};
  padding-top: 14px;

  img {
    display: block;
    width: 55%;
    margin: 0 auto;
  }
`

export const CertificateNotification: React.FC<{
  notification: Extract<
    HomeNotification,
    { type: 'certificate-issued-to-user' | 'issued-certificate-expiring' }
  >
}> = ({ notification }) => {
  const { certificate } = notification
  return (
    <NotificationContainer
      notification={notification}
      icon={<NotificationIcon variant='success' iconId='star--filled' />}
      title={
        <NotificationTitle
          titleKey={
            notification.type === 'issued-certificate-expiring'
              ? 'notification.certificate-expiring'
              : 'notification.certificate-earned'
          }
        />
      }
      content={
        notification.type === 'certificate-issued-to-user' ? (
          <View direction='column'>
            <CertificatePreviewContainer>
              <PreviewImage src={certificate.previewImageUrl} />
            </CertificatePreviewContainer>
            <Text bold>{certificate.title}</Text>
          </View>
        ) : (
          <View alignItems='center' grow>
            <View grow>
              <PlainEntityImage src={certificate.previewImageUrl} />
              <Title size='regular' bold>
                {certificate.title}
              </Title>
            </View>
            <Text>
              <Trans
                i18nKey='notification.certificate-expires-on'
                values={{ date: notification.expiresAt.toLocaleDateString() }}
              />
            </Text>
          </View>
        )
      }
    />
  )
}
