import { getFlag } from 'sierra-client/config/global-config'
import { useTranslation } from 'sierra-client/hooks/use-translation'
import { TranslationKey } from 'sierra-client/hooks/use-translation/types'
import { useReflectionGroupData } from 'sierra-client/views/v3-author/reflection-card/reflection-group-data-layer'
import { ReflectionCardGroupByState } from 'sierra-domain/live-session'
import { MenuItem } from 'sierra-ui/components'
import { SingleSelectDropdown } from 'sierra-ui/primitives/menu-dropdown'

const GROUP_LABEL = {
  individual: 'reflection.group-label.individual',
  smart: 'reflection.group-label.smart',
} as const satisfies Record<ReflectionCardGroupByState, TranslationKey>

type MenuItemKey = keyof typeof GROUP_LABEL

export const GroupingButton = (): JSX.Element | null => {
  const groupingEnabled = getFlag('smart-group-reflections')
  const { groupBy, setGroupBy, canSetGroupBy } = useReflectionGroupData()
  const { t } = useTranslation()

  if (!groupingEnabled) return null
  if (!canSetGroupBy) return null

  const groupByMenuItems = [
    {
      type: 'label',
      id: 'individual',
      label: t(GROUP_LABEL.individual),
      selected: groupBy === 'individual',
      icon: 'speaker--notes',
    },
    {
      type: 'label',
      id: 'smart',
      label: t(GROUP_LABEL.smart),
      selected: groupBy === 'smart',
      description: t('reflection.group-label.smart.description'),
      icon: 'sana-symbol',
    },
  ] as const satisfies MenuItem<MenuItemKey>[]

  const selecedItem = groupByMenuItems.find(item => item.id === groupBy)

  return (
    <SingleSelectDropdown<MenuItemKey>
      menuItems={groupByMenuItems}
      selectedItem={selecedItem}
      onSelect={item => setGroupBy(item.id)}
      grow={false}
      variant='ghost'
      disableContentTheme
    />
  )
}
