import {
  MetadataWithIcon,
  NotificationContainer,
  Title,
  UserAvatar,
} from 'sierra-client/features/activity-center/notifications/components'
import { LiveSessionImage } from 'sierra-client/features/activity-center/notifications/components/entity-image'
import { NotificationTitle } from 'sierra-client/features/activity-center/notifications/components/notification-title'
import { useSelector } from 'sierra-client/state/hooks'
import { selectUser } from 'sierra-client/state/user/user-selector'
import { detailsUrl } from 'sierra-client/views/workspace/utils/urls'
import { HomeNotification } from 'sierra-domain/api/learn'
import { assertIsNonNullable, getUserName } from 'sierra-domain/utils'
import { TruncatedText } from 'sierra-ui/components'
import { View } from 'sierra-ui/primitives'

export const AssignedLiveSessionNotification: React.FC<{
  notification: Extract<HomeNotification, { type: 'assigned-live-session' }>
}> = ({ notification }) => {
  const entity = notification.liveSession
  const url = detailsUrl({ id: entity.id, type: 'live-session', courseId: entity.courseId })

  const description = entity.description

  // TODO: Missing data
  const currentUser = useSelector(selectUser)
  assertIsNonNullable(currentUser)
  const assignedByUserId = currentUser.uuid

  return (
    <NotificationContainer
      notification={notification}
      icon={<UserAvatar userId={assignedByUserId} />}
      title={
        <NotificationTitle
          untranslated='{{user}} <subtitle>invited you to a session</subtitle>'
          values={{
            user: getUserName(currentUser),
          }}
        />
      }
      cardUrl={url}
      content={
        <>
          <LiveSessionImage liveSession={entity} />

          <View direction='column' gap='4'>
            <Title size='regular' bold>
              {entity.title}
            </Title>
            <MetadataWithIcon type='course' />

            {description !== '' && (
              <TruncatedText lines={2} size='small' color='foreground/secondary'>
                {description}
              </TruncatedText>
            )}
          </View>
        </>
      }
    />
  )
}
