import { useCallback, useEffect, useMemo } from 'react'
import { TableInstance } from 'react-table'
import { jsonWithZodSerializer, useQueryState } from 'sierra-client/lib/querystate/use-query-state'
import { setDifference, setIntersection, setUnion } from 'sierra-client/lib/querystate/utils'
import { z } from 'zod'

const queryStateSer = jsonWithZodSerializer(
  z.object({
    // hidden columns
    hc: z.array(z.string()),
    // shown columns
    sc: z.array(z.string()),
  })
)

const initQueryState = {
  // hidden columns
  hc: [],
  // shown columns
  sc: [],
}

/**
 * Allows for passing in default and optional columns and internally manages the toggling logic for them.
 *
 * Splits query states into show and hide state separately:
 * - `?hc=...` to hide default columns
 * - `?sc=...` to show optional columns
 *
 * @param props.defaultColumns - The columns that are shown by default
 * @param props.optionalColumns - The columns that are hidden by default
 * @param prop.stableInstance - The table instance to toggle the columns on
 * @returns The column selection state and a function to set the column selection state
 *
 * @example
 * const { columnSelection, setColumnSelection } = useManagedColumns({
 *  defaultColumns: ['id', 'name'],
 *  optionalColumns: ['email', 'phone'],
 *  tableInstance,
 * })
 *
 * // columnSelection is now Set(['id', 'name'])
 * setColumSelection(new Set(['id', 'email']))
 * // id will now be hidden and email will be shown (name is still shown)
 **/
export const useManagedColumns = <A extends object>({
  defaultColumns,
  optionalColumns,
  tableInstance,
}: {
  defaultColumns: string[]
  optionalColumns: string[]
  tableInstance: TableInstance<A>
}): {
  columnSelection: Set<string>
  setColumnSelection: (set: Set<string>) => void
  hiddenColumns: Set<string>
} => {
  const [queryState, setQueryState] = useQueryState(queryStateSer, initQueryState, 'cols')

  // Derives the column selection from the query state and the default columns
  // Selection = SC + (DC - HC)
  const columnSelection = useMemo(
    () => setUnion(new Set(queryState.sc), setDifference(new Set(defaultColumns), new Set(queryState.hc))),
    [queryState.sc, queryState.hc, defaultColumns]
  )

  // Takes care of updating the corresponding query state (different for default and optional columns)
  const setColumnSelection = useCallback(
    (s: Set<string>) =>
      setQueryState({
        hc: [...setDifference(new Set(defaultColumns), s)],
        sc: [...setIntersection(s, new Set(optionalColumns))],
      }),
    [defaultColumns, optionalColumns, setQueryState]
  )

  // Derives the hidden columns from the column selection
  const hiddenColumns = useMemo(
    () => new Set([...setDifference(new Set(optionalColumns), new Set(queryState.sc)), ...queryState.hc]),
    [queryState.sc, queryState.hc, optionalColumns]
  )

  // Updates the table instance when the hidden columns set changes
  useEffect(() => tableInstance.setHiddenColumns([...hiddenColumns]), [tableInstance, hiddenColumns])

  return {
    columnSelection,
    setColumnSelection,
    hiddenColumns,
  }
}
