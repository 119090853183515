import { VariationsTopbar } from 'sierra-client/views/v3-author/question-card/question-grid'
import { Icon, IconId } from 'sierra-ui/components'
import { View } from 'sierra-ui/primitives'
import { fadeIn } from 'sierra-ui/theming/keyframes'
import { BorderRadius } from 'sierra-ui/types'
import styled, { css } from 'styled-components'

export const assessmentContainerRadius: BorderRadius = 'size-12'

const AssessmentHeadlineWrapper = styled(View)`
  color: ${p => p.theme.home.textColor};
`

export const AssessmentIconBar = styled(View)``

export const AssessmentHeadline: React.FC<{ iconId: IconId; label: string }> = ({ iconId, label }) => {
  return (
    <AssessmentHeadlineWrapper>
      <Icon iconId={iconId} color='currentColor' size='size-16' />
      <View>{label}</View>
    </AssessmentHeadlineWrapper>
  )
}

export const AssessmentContainer = styled(View).attrs({
  grow: true,
  direction: 'column',
  alignItems: 'center',
  justifyContent: 'center',
})<{ $hide?: boolean }>`
  position: relative;
  flex: 1 0 80%;
  background-color: ${p => p.theme.home.backgroundColor};

  /* TODO: Make flex-basis work and then remove this */
  min-height: 70vh;

  ${p =>
    p.$hide === true &&
    css`
      display: none;
    `};

  & ${AssessmentIconBar} {
    opacity: 0;
    transition: opacity 150ms cubic-bezier(0.25, 0.1, 0.25, 1);
  }

  &:hover ${AssessmentIconBar} {
    opacity: 1;
  }

  & > * {
    opacity: 0;
    animation: 0.2s cubic-bezier(0.25, 0.1, 0.25, 1) ${fadeIn} forwards;
  }

  ${VariationsTopbar} {
    padding-top: 50px;
  }
`
