import { FileTypes } from 'sierra-client/views/v3-author/common/media-uploader/types'
import { NodeOrEditor, isElementType } from 'sierra-client/views/v3-author/queries'
import { hasOnlyEmptyTextInNodes } from 'sierra-domain/slate-util'
import { SanaEditor, SlateRootElement } from 'sierra-domain/v3-author'
import { createFileAttachment, createImage, createVideo } from 'sierra-domain/v3-author/create-blocks'
import { Path } from 'slate'

const debug = (...messages: unknown[]): void => console.debug('withFilePaste', ...messages)

const currentPath = ({ selection }: SanaEditor): Path | undefined =>
  selection ? selection.anchor.path : undefined

function isEmptyParagraph(node: NodeOrEditor | undefined): boolean {
  if (!isElementType('paragraph', node)) return false

  return hasOnlyEmptyTextInNodes(node.children)
}

export const acceptedImageTypes: FileTypes[] = ['image/jpeg', 'image/png', 'image/gif', 'image/webp']
export const acceptedVideoTypes: FileTypes[] = ['video/mp4']
export const maxFileSizeGb = 8
export const maxFileSizeBytes = maxFileSizeGb * 1024 * 1024 * 1024 // 8GB

export function isSupportedFile(file: File): boolean {
  return file.size < maxFileSizeBytes
}

export function withFilePaste(editor: SanaEditor): SanaEditor {
  const { insertData } = editor

  editor.insertData = transfer => {
    const path = currentPath(editor)

    const newNodes = Array.from(transfer.files).flatMap((file): SlateRootElement[] => {
      if (!isSupportedFile(file)) return []

      if (acceptedImageTypes.includes(file.type as FileTypes)) {
        const image = createImage()
        editor.pushActionsLogEntry('paste-image')
        editor.initialImageUploads[image.id] = file
        return [image]
      } else if (acceptedVideoTypes.includes(file.type as FileTypes)) {
        editor.pushActionsLogEntry('paste-video')
        const video = createVideo()
        editor.initialVideoUploads[video.id] = file
        return [video]
      } else {
        editor.pushActionsLogEntry('paste-file')
        const fileAttachment = createFileAttachment()
        editor.initialFileUploads[fileAttachment.id] = file
        return [fileAttachment]
      }
    })

    editor.withoutNormalizing(() => {
      if (!path) return
      if (newNodes.length === 0) return
      const parentPath = Path.parent(path)

      const currentNode = editor.node(parentPath)[0]
      const isDraggingToEmptyParagraph = isEmptyParagraph(currentNode)

      if (isDraggingToEmptyParagraph) {
        debug(`Deleting empty paragraph at ${JSON.stringify(parentPath)}`)
        editor.delete({ at: parentPath })
      }

      debug(`Inserting ${newNodes.length} nodes at ${JSON.stringify(parentPath)}`)
      editor.insertNodes(newNodes, { at: parentPath })
    })

    insertData(transfer)
  }

  return editor
}
