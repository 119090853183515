import { Text, View } from 'sierra-ui/primitives'
import styled from 'styled-components'

export const SettingsText = styled(Text).attrs({ bold: true, size: 'small' })`
  flex-grow: 1;
  display: flex;
  align-items: center;
  gap: 0.5rem;
`

export const SettingsDropdownWrapper = styled(View)`
  width: 275px;
`

export const StyledText = styled(Text)`
  max-width: 15rem;
`

export const SwitchSetting = styled(View).attrs({ gap: 'none', grow: true })`
  max-width: 17rem;
`
