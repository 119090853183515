import _, { drop, take } from 'lodash'
import { useMemo } from 'react'
import { useCurrentMode } from 'sierra-client/components/liveV2/hooks/use-current-mode'
import { ParticipantIdentifier } from 'sierra-client/components/liveV2/types'
import { useDeepEqualityMemo } from 'sierra-client/hooks/use-deep-equality-memo'
import { useSelector } from 'sierra-client/state/hooks'
import {
  AugmentedParticipant,
  selectAugmentedParticipantsIncludingMe,
  selectIsSomeoneScreenSharing,
} from 'sierra-client/state/live-session/selectors'
import { selectClientId, selectRemoteParticipants } from 'sierra-client/state/live/selectors'
import { selectUserId } from 'sierra-client/state/user/user-selector'
import { CreateContentId } from 'sierra-domain/api/nano-id'
import { UserId } from 'sierra-domain/api/uuid'
import { ChatOverflowDirection } from 'sierra-ui/missions/live'

// Agora clients that are i nthe call but not synced to the awareness state are represented by their agora ID.
type AgoraGridParticipant = {
  type: 'agora'
} & Omit<AugmentedParticipant, 'type' | 'userId' | 'agoraScreenShareUID'>

export type GridParticipant = AgoraGridParticipant | AugmentedParticipant

export const sortParticipantsByPriority = (participants: GridParticipant[]): GridParticipant[] =>
  _.orderBy(
    participants,
    ['isCurrentClient', 'isPinned', 'isOnStageWithAudio', 'lastActive', 'isOnStageWithVideo'],
    ['desc', 'desc', 'desc', 'desc', 'desc']
  )

/** This hook maps a list of participants to a list of just their ids, and performs deep memoization on the result */
export const useMemoizedParticipantIds = (participants: ParticipantIdentifier[]): ParticipantIdentifier[] => {
  const participantIds = useMemo(
    () => participants.map(p => ({ agoraUID: p.agoraUID, userId: p.userId })),
    [participants]
  )

  return useDeepEqualityMemo(participantIds)
}

export type UseGridParticipants = {
  participants: GridParticipant[]
  passiveParticipants: GridParticipant[]
  chatOverflowDirection?: ChatOverflowDirection
  useLowResVideo?: boolean
}

type GenericGridParticipants = {
  participantCount: number
  videosDisplayedInLobby: boolean
  allParticipants: GridParticipant[]
  clientId: string | undefined
  userId: UserId | undefined
}

export const useGenericGridParticipants = (flexibleContentId: CreateContentId): GenericGridParticipants => {
  const currentMode = useCurrentMode(flexibleContentId)
  const isSomeoneScreenSharing = useSelector(selectIsSomeoneScreenSharing)
  const videosDisplayedInLobby = currentMode === 'live-lobby' && !isSomeoneScreenSharing

  const awarenessParticipants = useSelector(selectAugmentedParticipantsIncludingMe)
  const agoraParticipants = useSelector(selectRemoteParticipants)

  const allParticipants = useMemo(() => {
    const filteredAgoraParticipants = agoraParticipants
      .filter(it => !awarenessParticipants.some(a => a.agoraUID === it.id || a.agoraScreenShareUID === it.id))
      .map(
        it =>
          ({
            type: 'agora',
            agoraUID: it.id,
            isPinned: false,
            lastActive: 0,
            handRaisedTime: undefined,
            isCurrentClient: false,
            isOnStageWithAudio: it.isPublishingAudio,
            isOnStageWithVideo: it.isPublishingVideo,
          }) satisfies AgoraGridParticipant
      )

    return [...awarenessParticipants, ...filteredAgoraParticipants]
  }, [agoraParticipants, awarenessParticipants])

  const clientId = useSelector(selectClientId)
  const userId = useSelector(selectUserId)

  return {
    participantCount: allParticipants.length,
    videosDisplayedInLobby,
    allParticipants,
    clientId,
    userId,
  }
}

// We'll define this helper function outside of the component so that we get a stable reference.
type UserWithAgoraUID = {
  agoraUID: string
}
export const hasEqualAgoraUID = (a: UserWithAgoraUID, b: UserWithAgoraUID): boolean =>
  a.agoraUID === b.agoraUID

type GetLobbyParticipantsResults = {
  activeParticipants: GridParticipant[]
  passiveParticipants: GridParticipant[]
}

type GetLobbyParticipantsArgs = {
  numberOfActiveUsers: number
  participants: GridParticipant[]
}

export function getLobbyParticipants(args: GetLobbyParticipantsArgs): GetLobbyParticipantsResults {
  const { numberOfActiveUsers, participants } = args

  const includedUsersSorted = sortParticipantsByPriority(participants)
  const pinnedUsersInCall = includedUsersSorted.filter(user => user.isPinned)

  const allUsers = pinnedUsersInCall.length > 0 ? pinnedUsersInCall : includedUsersSorted

  const activeParticipants = take(allUsers, numberOfActiveUsers)
  const passiveParticipants = drop(allUsers, numberOfActiveUsers)

  return { activeParticipants, passiveParticipants }
}
