import React from 'react'
import { useTranslation } from 'sierra-client/hooks/use-translation'
import { getDomainRep } from 'sierra-client/lib/filter'
import { AddFilter } from 'sierra-client/lib/filter/components/add'
import { Context, Intersperse } from 'sierra-client/lib/filter/components/common'
import { Domain } from 'sierra-client/lib/filter/components/domain'
import {
  removeFilterAt,
  setFilterAt,
  singleFilterInit,
} from 'sierra-client/lib/filter/components/filter-utils'
import { OperatorComponent } from 'sierra-client/lib/filter/components/operator'
import { PredicateComponent } from 'sierra-client/lib/filter/components/predicate'
import {
  ReadOnlyDomain,
  ReadOnlyOperator,
  ReadOnlyPredicate,
} from 'sierra-client/lib/filter/components/read-only-filter'
import * as UI from 'sierra-client/lib/filter/ui'
import { AssetContext } from 'sierra-domain/asset-context'
import { DomainRep } from 'sierra-domain/filter/datatype/domain'
import { Filter, FilterBase, andAll, orAll } from 'sierra-domain/filter/datatype/filter'
import { assertNever } from 'sierra-domain/utils'
import { Icon } from 'sierra-ui/components'
import { IconButton, View } from 'sierra-ui/primitives'
import styled from 'styled-components'

const WithoutBorder = styled(View)`
  border: 0;
`

const RemoveFilter: React.FC<{ ctx: Context }> = ({ ctx }) => (
  <WithoutBorder justifyContent='flex-end'>
    <UI.FilterSectionRemove onClick={ctx.remove}>
      <UI.FilterSectionRemoveHoverLayer>
        <Icon iconId='close' size='size-16' color='currentColor' />
      </UI.FilterSectionRemoveHoverLayer>
    </UI.FilterSectionRemove>
  </WithoutBorder>
)

// If the filter corresponds to a domain that's not defined (because it was removed in the config or
// because of backend changes) we'll still render it. The invalid filter will have an error state and
// the only possible interaction will be to remove the filter.
const FilterOfNoDomain: React.FC<{
  ctx: Context
  filter: FilterBase
}> = React.memo(({ ctx, filter }) => (
  <UI.FilterSection.Wrapper readOnly={false} hasError={true}>
    <ReadOnlyDomain domainRep={undefined} filterDomain={filter.domain} />
    <ReadOnlyOperator operator={filter.operator} />
    <ReadOnlyPredicate domainRep={undefined} predicate={filter.predicate} />
    <RemoveFilter ctx={ctx} />
  </UI.FilterSection.Wrapper>
))

const FilterOf: React.FC<{
  ctx: Context
  domainRep: DomainRep
  filter: FilterBase
  assetContext: AssetContext
}> = React.memo(({ ctx, domainRep, filter, assetContext }) => (
  <UI.FilterSection.Wrapper readOnly={false}>
    <Domain ctx={ctx} filter={filter} />
    <OperatorComponent ctx={ctx} domainRep={domainRep} operator={filter.operator} />
    <PredicateComponent
      ctx={ctx}
      domainRep={domainRep}
      predicate={filter.predicate}
      assetContext={assetContext}
    />
    <RemoveFilter ctx={ctx} />
  </UI.FilterSection.Wrapper>
))

const toggleDistinction = (f: Filter): Filter => {
  switch (f.type) {
    case 'filter.and':
      return orAll(f.filters)
    case 'filter.or':
      return andAll(f.filters)
    case 'filter.filter':
      return f
    default:
      assertNever(f)
  }
}

const DistinctionAnd: React.FC<{ ctx: Context }> = React.memo(({ ctx }) => {
  const { t } = useTranslation()
  return (
    <UI.Distinction.Wrapper readOnly={false} onClick={() => ctx.update(f => toggleDistinction(f))}>
      <UI.Distinction.Text>{t('user-filter.conjunction.and')}</UI.Distinction.Text>
    </UI.Distinction.Wrapper>
  )
})

const DistinctionOr: React.FC<{ ctx: Context }> = React.memo(({ ctx }) => {
  const { t } = useTranslation()
  return (
    <UI.Distinction.Wrapper readOnly={false} onClick={() => ctx.update(f => toggleDistinction(f))}>
      <UI.Distinction.Text>{t('user-filter.conjunction.or')}</UI.Distinction.Text>
    </UI.Distinction.Wrapper>
  )
})

/**
 * Function to help updating the ctx to be filter and position aware
 * If this is not used, using `update` and `remove` would change
 * incorrect places of the filter AST
 */
export const updateCtx = (ctx: Context, f: Filter, i: number): Context => {
  const update = (modify: (f: Filter) => Filter): void => {
    const newFilter = modify(f)
    switch (newFilter.type) {
      case 'filter.and':
      case 'filter.or':
        if (newFilter.filters.length === 0) {
          ctx.update(f => removeFilterAt(f, i))
        } else {
          ctx.update(f => setFilterAt(f, newFilter, i))
        }
        break
      case 'filter.filter':
        ctx.update(f => setFilterAt(f, newFilter, i))
        break
      default:
        assertNever(newFilter)
    }
  }

  const remove = (): void => {
    ctx.update(f => removeFilterAt(f, i))
  }
  return { ...ctx, update, remove }
}

export const FilterComponent: React.FC<{
  ctx: Context
  filter: Filter
  depth?: number
  generateFilter?: (query: string) => Promise<Filter>
  assetContext: AssetContext
}> = React.memo(({ ctx, filter, depth = 0, generateFilter, assetContext }) => {
  const { t } = useTranslation()
  const domainRep = 'domain' in filter ? getDomainRep(ctx.domainReps, filter.domain) : undefined

  switch (filter.type) {
    case 'filter.filter':
      return domainRep !== undefined ? (
        <FilterOf ctx={ctx} domainRep={domainRep} filter={filter} assetContext={assetContext} />
      ) : (
        <FilterOfNoDomain ctx={ctx} filter={filter} />
      )
    case 'filter.and':
    case 'filter.or':
      return (
        <>
          <Intersperse
            separator={
              filter.type === 'filter.and' ? <DistinctionAnd ctx={ctx} /> : <DistinctionOr ctx={ctx} />
            }
          >
            {filter.filters.map((filter, i) => {
              const newCtx = updateCtx(ctx, filter, i)
              return (
                <FilterComponent
                  depth={depth + 1}
                  ctx={newCtx}
                  filter={filter}
                  key={i}
                  generateFilter={generateFilter}
                  assetContext={assetContext}
                />
              )
            })}
          </Intersperse>
          {depth > 0 && (
            <AddFilter
              ctx={ctx}
              filter={filter}
              filterInit={singleFilterInit}
              renderTrigger={() => (
                <IconButton iconId='add' variant='transparent' tooltip={t('user-filter.add-filter')} />
              )}
              generateFilter={generateFilter}
            />
          )}
        </>
      )
    default:
      assertNever(filter)
  }
})
