import { VERTICAL_STACK_MARGINS_CSS } from 'sierra-client/views/v3-author/sections/stacks'
import { assertNever } from 'sierra-domain/utils'
import { Embed, Image } from 'sierra-domain/v3-author'
import { spacing } from 'sierra-ui/theming'
import { v2_breakpoint } from 'sierra-ui/theming/breakpoints'
import { SimpleStyledCSS } from 'sierra-ui/utils'
import styled, { css } from 'styled-components'

export const imageNarrowLayoutMaxWidth = css`
  grid-column: 1 / -1;
`
export const imageWideLayoutMaxWidth = css`
  grid-column: 2 / -2;
`

type SizeVariantProps = {
  customSize?: number
  variant?: Embed['variant']
  $columnVariant?: Image['columnVariant']
  maxWidth?: number
}

const NarrowSizeCss = ({ variant, customSize, maxWidth }: SizeVariantProps): SimpleStyledCSS => {
  if (customSize !== undefined) {
    return css`
      max-width: ${customSize}px;
      ${imageNarrowLayoutMaxWidth}
      margin-left: auto;
      margin-right: auto;
    `
  }
  switch (variant) {
    case 'narrow':
      return css`
        grid-column: 2 / -2;

        /* These are here to fix the case for when the image is inside the NoGridImgContainer.
        Without them the image fills all available space whereas now we have a "default size" before any manual resizing. */
        ${maxWidth !== undefined &&
        css`
          max-width: ${maxWidth}px;
          margin-left: auto;
          margin-right: auto;
        `}
      `
    case 'center':
      return css`
        grid-column: 2 / -2;

        margin: 0 20%;
      `
    case 'wide':
      return css`
        grid-column: 1 / -1;

        @media screen and (min-width: ${v2_breakpoint.desktop}) {
          margin: 0 10%;
        }
      `
    case 'full-width':
      return css`
        grid-column: 1 / -1;
      `
    default:
      return css`
        grid-column: 2 / -2;

        @media screen and (min-width: ${v2_breakpoint.phone}),
          print and (print-width: ${v2_breakpoint.phone}) {
          grid-column: 2 / span 12;
        }
      `
  }
}

const WideSizeCss = ({ variant, customSize, maxWidth }: SizeVariantProps): SimpleStyledCSS => {
  if (customSize !== undefined) {
    return css`
      ${imageWideLayoutMaxWidth}
    `
  }

  switch (variant) {
    case 'center':
      return css`
        max-width: 40ch;
        transition: all 0.3s cubic-bezier(0.25, 0.1, 0.25, 1);
      `

    case 'narrow':
      return css`
        max-width: 85ch;
        transition: all 0.3s cubic-bezier(0.25, 0.1, 0.25, 1);

        /* These are here to fix the case for when the image is inside the NoGridImgContainer.
        Without them the image fills all available space whereas now we have a "default size" before any manual resizing. */
        ${maxWidth !== undefined &&
        css`
          max-width: ${maxWidth}px;
        `}
      `

    case 'wide':
      return css`
        max-width: 100ch;
      `

    case 'full-width':
      return css`
        max-width: 100%;
        transition: all 0.3s cubic-bezier(0.25, 0.1, 0.25, 1);
      `

    default:
      return css`
        padding: 0;
        transition: all 0.3s cubic-bezier(0.25, 0.1, 0.25, 1);
      `
  }
}

const ColumnSizeCss = ({ $columnVariant, customSize, maxWidth }: SizeVariantProps): SimpleStyledCSS => {
  switch ($columnVariant) {
    case 'padded':
      if (customSize !== undefined) {
        return css`
          &:first-child {
            margin-top: ${spacing[32]};
          }
          margin-left: auto;
          margin-right: auto;
          ${imageWideLayoutMaxWidth}
        `
      }
      return css`
        max-width: 100%;
        ${VERTICAL_STACK_MARGINS_CSS}

        &:first-child {
          margin-top: ${spacing[32]};
        }

        /* These are here to fix the case for when the image is inside the NoGridImgContainer.
        Without them the image fills all available space whereas now we have a "default size" before any manual resizing. */
        ${maxWidth !== undefined &&
        css`
          max-width: ${maxWidth}px;
        `}
      `

    case undefined:
    case 'full-width':
      return css`
        margin-left: auto;
        margin-right: auto;
        max-width: 100%;
      `

    default:
      assertNever($columnVariant)
  }
}

type WrapProps = SizeVariantProps & {
  isResizing?: boolean
  $withGrid: boolean
}

export const SizeCss = css<WrapProps>`
  ${p => {
    if (!p.$withGrid && p.$columnVariant) {
      return ColumnSizeCss
    }
    if (p.theme.editor.isWideLayout) {
      return WideSizeCss
    }

    return NarrowSizeCss
  }}
`

export const Wrap = styled.div.attrs(({ customSize }: Pick<WrapProps, 'customSize'>) => ({
  style: {
    maxWidth: customSize === undefined ? undefined : `${customSize}px`,
  },
}))<WrapProps>`
  position: relative;
  transition: box-shadow 100ms ease-in-out;
  border-radius: 8px;

  && {
    ${SizeCss}
    padding: 0;
  }

  width: -webkit-fill-available;

  ${p => p.isResizing === true && 'user-select: none;'}
`
