import { useCallback, useEffect, useState } from 'react'
import { usePost } from 'sierra-client/hooks/use-post'
import { PathLocalizationRow } from 'sierra-domain/api/admin'
import { PathId } from 'sierra-domain/api/nano-id'
import {
  XRealtimeAdminPathsPathTranslations,
  XRealtimeAdminPathsSavePathTranslations,
} from 'sierra-domain/routes'

export type UsePathTranslationData = {
  isLoading: boolean
  localizationData: PathLocalizationRow[] | undefined
  fetchPathTranslations: (pathId: PathId) => Promise<PathLocalizationRow[] | undefined>
  savePathTranslations: (pathId: PathId, localizationData: PathLocalizationRow[]) => Promise<void>
}

export const usePathTranslations = (pathId: PathId): UsePathTranslationData => {
  const { postWithUserErrorException } = usePost()

  const [isLoading, setIsLoading] = useState(false)

  /* localizationData is always sorted with default coming first */
  const [localizationData, setLocalizationData] = useState<PathLocalizationRow[] | undefined>(undefined)

  const fetchPathTranslations = useCallback<UsePathTranslationData['fetchPathTranslations']>(
    async fetchPathId => {
      const response = await postWithUserErrorException(XRealtimeAdminPathsPathTranslations, {
        pathId: fetchPathId,
      })
      setLocalizationData(
        response.localizationData?.sort((a, b) => (a.isDefault === b.isDefault ? 0 : a.isDefault ? -1 : 1))
      )
      return response.localizationData
    },
    [postWithUserErrorException]
  )

  // Fetches on load
  useEffect(() => {
    void (async () => {
      setIsLoading(true)
      await fetchPathTranslations(pathId)
      setIsLoading(false)
    })()
  }, [pathId, fetchPathTranslations])

  const savePathTranslations = useCallback<UsePathTranslationData['savePathTranslations']>(
    async (updatePathId, localizationData) => {
      await postWithUserErrorException(XRealtimeAdminPathsSavePathTranslations, {
        pathId: updatePathId,
        localizationData: localizationData,
      })
      setLocalizationData(
        localizationData.sort((a, b) => (a.isDefault === b.isDefault ? 0 : a.isDefault ? -1 : 1))
      )
    },
    [postWithUserErrorException]
  )

  return {
    isLoading,
    localizationData,
    fetchPathTranslations,
    savePathTranslations,
  }
}
