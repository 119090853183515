import { useCallback } from 'react'
import { useDateTimeFormatter } from 'sierra-client/core/format'
import { useTranslation } from 'sierra-client/hooks/use-translation'
import { getGlobalRouter } from 'sierra-client/router'
import {
  ChooseSession,
  ProgramOverview,
  ViewDetails,
} from 'sierra-client/views/course-helper/content/actions'
import { NextUpCourseInformation } from 'sierra-client/views/course-helper/content/next-up-course-information'
import { useCourseHelperSessionEnrollmentMetadata } from 'sierra-client/views/course-helper/content/next-up-enrollment-metadata/use-course-helper-session-enrollment-metadata'
import { CourseForHref } from 'sierra-domain/api/backend-self-paced/types'
import { ContentInformation, EnrollmentMetadata } from 'sierra-domain/api/learn'
import { CourseId, EventGroupId } from 'sierra-domain/api/nano-id'
import { assertNever } from 'sierra-domain/utils'
import { View } from 'sierra-ui/primitives'

function useSessionDateTimeFormatter(): (
  info: Extract<EnrollmentMetadata, { type: 'single-session' | 'assigned-session' }>
) => string {
  const dateFormatter = useDateTimeFormatter({ month: 'short', day: 'numeric' })
  const dateTimeFormatter = useDateTimeFormatter({
    month: 'short',
    day: 'numeric',
    hour: 'numeric',
    minute: '2-digit',
  })

  return useCallback(
    ({ startTime, endTime, allDay }) => {
      if (allDay) {
        return dateFormatter.formatRange(startTime, endTime)
      } else {
        return dateTimeFormatter.formatRange(startTime, endTime)
      }
    },
    [dateFormatter, dateTimeFormatter]
  )
}

const useGetEnrollmentMetadataText = (
  enrollmentMetadata: EnrollmentMetadata | undefined
): string | undefined => {
  const { t } = useTranslation()
  const dateTimeFormatter = useSessionDateTimeFormatter()
  if (enrollmentMetadata === undefined) return undefined
  if (enrollmentMetadata.type === 'single-session' || enrollmentMetadata.type === 'assigned-session') {
    return dateTimeFormatter(enrollmentMetadata)
  }
  const selfEnrollActionText = (() => {
    switch (enrollmentMetadata.type) {
      case 'multiple-sessions':
        return t('teamspace.has-self-enroll-action')
      case 'no-sessions':
        return t('dictionary.live-session.not-scheduled')
      default:
        assertNever(enrollmentMetadata)
    }
  })()

  return selfEnrollActionText
}

function navigateToEventGroupOrLive(contentInfo: ContentInformation): Promise<void> {
  const router = getGlobalRouter()
  if (contentInfo.type === 'native:live') {
    return router.navigate({
      to: '/course/$courseId',
      params: { courseId: contentInfo.id as CourseId },
    })
  } else {
    return router.navigate({
      to: '/event/$eventGroupId',
      params: { eventGroupId: contentInfo.id as EventGroupId },
    })
  }
}

export const ContentWithEnrollmentInformation: React.FC<{
  course: CourseForHref
  courseTitle: string
  contentInformation: ContentInformation
  goToOverViewPage: () => void
}> = ({ course, courseTitle, contentInformation, goToOverViewPage }) => {
  const enrollmentQuery = useCourseHelperSessionEnrollmentMetadata(contentInformation)
  const metadata = useGetEnrollmentMetadataText(enrollmentQuery.data)

  const buttons = (() => {
    switch (enrollmentQuery.data?.type) {
      case undefined:
        return <></>
      case 'multiple-sessions':
        return (
          <View grow justifyContent='flex-end' marginTop='48' gap='8'>
            <ProgramOverview onClick={goToOverViewPage} />
            <ChooseSession onClick={() => navigateToEventGroupOrLive(contentInformation)} />
          </View>
        )
      case 'single-session':
        return (
          <View grow justifyContent='flex-end' marginTop='48' gap='8'>
            <ProgramOverview onClick={goToOverViewPage} />
            <ViewDetails onClick={() => navigateToEventGroupOrLive(contentInformation)} />
          </View>
        )
      case 'assigned-session':
      case 'no-sessions':
        return (
          <View grow justifyContent='flex-end' marginTop='48' gap='8'>
            <ProgramOverview onClick={goToOverViewPage} />
          </View>
        )
      default:
        return <></>
    }
  })()

  return (
    <>
      <NextUpCourseInformation course={course} courseTitle={courseTitle} extraInformation={metadata} />
      {buttons}
    </>
  )
}
