import _ from 'lodash'
import { coerceCustomTheme } from 'sierra-client/config/domain-theme'
import { useOrganizationSettings } from 'sierra-client/views/manage/settings/use-organization-settings'
import { CustomThemes } from 'sierra-domain/api/private'
import {
  CustomTheme,
  CustomThemeName,
  NewPresetThemeName,
  newPresetThemes,
  PresetTheme,
  theme as sanaTheme,
} from 'sierra-ui/theming/legacy-theme'

export type PresetThemes = Record<NewPresetThemeName, PresetTheme>
// The organization might not have defined all the custom themes, so this type needs to be a Partia
export type CustomThemeRecord = Partial<Record<CustomThemeName, CustomTheme>>

/**
 * Resolve a color in a theme to a color that can be understood by css (e.g. `'#efefef'`)
 */
export function resolveThemeColor(color: string): string {
  const sanaColorDefinitions = sanaTheme.color
  const resolvedColor: unknown = _.get(sanaColorDefinitions, color)

  return typeof resolvedColor === 'string' ? resolvedColor : color
}

/**
 * The `CustomThemes` type contains the wrong custom theme names. These names need to be migrated as defined in this function.
 */
export function migrateCustomThemes({
  customTheme1,
  customTheme2,
  customTheme3,
  customTheme4,
  customTheme5,
  customTheme6,
  customTheme7,
  customTheme8,
  customTheme9,
  customTheme10,
  customTheme11,
  customTheme12,
  customTheme13,
  customTheme14,
  customTheme15,
  customTheme16,
  customTheme17,
  customTheme18,
  customTheme19,
  customTheme20,
  customTheme21,
  customTheme22,
  customTheme23,
  customTheme24,
  customTheme25,
  customTheme26,
  customTheme27,
  customTheme28,
}: Partial<CustomThemes>): Partial<Record<CustomThemeName, CustomTheme>> {
  const record: Partial<Record<CustomThemeName, CustomTheme>> = {}
  if (customTheme1) record['custom-1'] = coerceCustomTheme(customTheme1)
  if (customTheme2) record['custom-2'] = coerceCustomTheme(customTheme2)
  if (customTheme3) record['custom-3'] = coerceCustomTheme(customTheme3)
  if (customTheme4) record['custom-4'] = coerceCustomTheme(customTheme4)
  if (customTheme5) record['custom-5'] = coerceCustomTheme(customTheme5)
  if (customTheme6) record['custom-6'] = coerceCustomTheme(customTheme6)
  if (customTheme7) record['custom-7'] = coerceCustomTheme(customTheme7)
  if (customTheme8) record['custom-8'] = coerceCustomTheme(customTheme8)
  if (customTheme9) record['custom-9'] = coerceCustomTheme(customTheme9)
  if (customTheme10) record['custom-10'] = coerceCustomTheme(customTheme10)
  if (customTheme11) record['custom-11'] = coerceCustomTheme(customTheme11)
  if (customTheme12) record['custom-12'] = coerceCustomTheme(customTheme12)
  if (customTheme13) record['custom-13'] = coerceCustomTheme(customTheme13)
  if (customTheme14) record['custom-14'] = coerceCustomTheme(customTheme14)
  if (customTheme15) record['custom-15'] = coerceCustomTheme(customTheme15)
  if (customTheme16) record['custom-16'] = coerceCustomTheme(customTheme16)
  if (customTheme17) record['custom-17'] = coerceCustomTheme(customTheme17)
  if (customTheme18) record['custom-18'] = coerceCustomTheme(customTheme18)
  if (customTheme19) record['custom-19'] = coerceCustomTheme(customTheme19)
  if (customTheme20) record['custom-20'] = coerceCustomTheme(customTheme20)
  if (customTheme21) record['custom-21'] = coerceCustomTheme(customTheme21)
  if (customTheme22) record['custom-22'] = coerceCustomTheme(customTheme22)
  if (customTheme23) record['custom-23'] = coerceCustomTheme(customTheme23)
  if (customTheme24) record['custom-24'] = coerceCustomTheme(customTheme24)
  if (customTheme25) record['custom-25'] = coerceCustomTheme(customTheme25)
  if (customTheme26) record['custom-26'] = coerceCustomTheme(customTheme26)
  if (customTheme27) record['custom-27'] = coerceCustomTheme(customTheme27)
  if (customTheme28) record['custom-28'] = coerceCustomTheme(customTheme28)
  return record
}

export function customThemeWithDefaults(
  theme: Partial<Record<CustomThemeName, CustomTheme>>
): Record<CustomThemeName, CustomTheme | null> {
  return {
    'custom-1': theme['custom-1'] ?? null,
    'custom-2': theme['custom-2'] ?? null,
    'custom-3': theme['custom-3'] ?? null,
    'custom-4': theme['custom-4'] ?? null,
    'custom-5': theme['custom-5'] ?? null,
    'custom-6': theme['custom-6'] ?? null,
    'custom-7': theme['custom-7'] ?? null,
    'custom-8': theme['custom-8'] ?? null,
    'custom-9': theme['custom-9'] ?? null,
    'custom-10': theme['custom-10'] ?? null,
    'custom-11': theme['custom-11'] ?? null,
    'custom-12': theme['custom-12'] ?? null,
    'custom-13': theme['custom-13'] ?? null,
    'custom-14': theme['custom-14'] ?? null,
    'custom-15': theme['custom-15'] ?? null,
    'custom-16': theme['custom-16'] ?? null,
    'custom-17': theme['custom-17'] ?? null,
    'custom-18': theme['custom-18'] ?? null,
    'custom-19': theme['custom-19'] ?? null,
    'custom-20': theme['custom-20'] ?? null,
    'custom-21': theme['custom-21'] ?? null,
    'custom-22': theme['custom-22'] ?? null,
    'custom-23': theme['custom-23'] ?? null,
    'custom-24': theme['custom-24'] ?? null,
    'custom-25': theme['custom-25'] ?? null,
    'custom-26': theme['custom-26'] ?? null,
    'custom-27': theme['custom-27'] ?? null,
    'custom-28': theme['custom-28'] ?? null,
  }
}

/**
 * Get all themes supported by the current organization
 */
export function useThemes(): {
  newPresetThemes: PresetThemes
  customThemes: CustomThemeRecord
} {
  const { settings } = useOrganizationSettings()

  const customThemes: Partial<Record<CustomThemeName, CustomTheme>> = migrateCustomThemes(
    settings.brand.customThemes
  )

  return { newPresetThemes, customThemes }
}
