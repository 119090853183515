import { JSONContent } from '@tiptap/core'
import { AnimatePresence, motion } from 'framer-motion'
import { FC, MutableRefObject, default as React, useEffect, useMemo, useRef, useState } from 'react'
import { ChatMessage } from 'sierra-client/components/chat/chat-message/chat-message'
import { createCommentersFromMentions } from 'sierra-client/components/chat/mention/create-commenters-from-mentions'
import { ReadOnlyTiptapContent } from 'sierra-client/components/chat/tiptap'
import { EmojiPickerPopupProvider } from 'sierra-client/components/common/emoji'
import { AppThemeTokenProvider } from 'sierra-client/config/token-provider'
import { useLocalizedFormatters } from 'sierra-client/core/format'
import { Focusable } from 'sierra-client/domain/commenting/types'
import { useTranslation } from 'sierra-client/hooks/use-translation'
import { useTypedMutation } from 'sierra-client/state/api'
import { chatVisibilityChanged, resolveComment, unresolveComment } from 'sierra-client/state/chat/actions'
import * as selectors from 'sierra-client/state/chat/selectors'
import { useDispatch, useSelector } from 'sierra-client/state/hooks'
import { selectUserId } from 'sierra-client/state/user/user-selector'
import { useUsersLegacy } from 'sierra-client/state/users/hooks'
import { FCC } from 'sierra-client/types'
import { getAvatarImage } from 'sierra-client/utils/avatar-img'
import { CommentInput, CommentInputRef } from 'sierra-client/views/commenting/comment-input'
import { useCommentingContext } from 'sierra-client/views/commenting/context'
import { ChatIdentifier } from 'sierra-domain/api/chat'
import { ChatMessageId } from 'sierra-domain/api/uuid'
import { CommentContentReference } from 'sierra-domain/chat'
import { ScopedChatId } from 'sierra-domain/collaboration/types'
import { XRealtimeChatSetMessageResolved } from 'sierra-domain/routes'
import { getUserName, isDefined } from 'sierra-domain/utils'
import { ColorName } from 'sierra-ui/color/types'
import { RoundAvatar } from 'sierra-ui/components'
import { MUIClickAwayListener } from 'sierra-ui/mui'
import { Button, IconButton, Text, View } from 'sierra-ui/primitives'
import { palette, spacing } from 'sierra-ui/theming'
import { legacyLight } from 'sierra-ui/theming/legacy-theme'
import styled, { ThemeProvider, css } from 'styled-components'

const Animated = styled(motion.div)`
  overflow: hidden;
`

const RepliesContainer: FCC = ({ children }) => {
  return (
    <Animated
      initial={{ opacity: 0, maxHeight: '0vh' }}
      animate={{
        opacity: 1,
        maxHeight: '30vh',
        transitionEnd: {
          overflowY: 'scroll',
        },
      }}
      exit={{
        opacity: 0,
        maxHeight: '0vh',
      }}
      transition={{
        duration: 0.15,
        ease: [0.25, 0.1, 0.25, 1],
      }}
    >
      {children}
    </Animated>
  )
}

const InlineCommentThreadContainer = styled.div`
  width: 350px;
  height: 100%;
  background-color: #fff;
  border-radius: 0 0.75rem 0.75rem 0.75rem;
  position: relative;
  flex-grow: 1;
  padding: 1rem;

  display: grid;
  grid: auto / 1fr;
  grid-auto-rows: auto;
  grid-auto-flow: dense;
  justify-items: stretch;
  gap: 0.5rem;
`
const ChatContainerOutline = styled.div`
  background: ${palette.primitives.white};
  color: ${palette.primitives.black};
  border-radius: 0 0.75rem 0.75rem 0.75rem;
  filter: drop-shadow(0px 8px 16px rgba(0, 0, 0, 0.08));
  transition: all 0.2s cubic-bezier(0.25, 0.1, 0.25, 1);
  will-change: filter;

  &:hover {
    cursor: pointer;
    filter: drop-shadow(0px 8px 16px rgba(0, 0, 0, 0.12));
    transition: all 0.2s cubic-bezier(0.25, 0.1, 0.25, 1);
  }
`

const AuthorContainer = styled.div`
  & > .rootMessage {
    margin-top: 0.5rem;
  }
`
const ThreadHeader = styled.header<{ column: boolean }>`
  display: flex;
  gap: 0.5rem;

  ${p =>
    p.column
      ? css`
          flex-direction: column-reverse;
          justify-content: flex-start;
          align-items: stretch;
        `
      : css`
          flex-direction: row;
          justify-content: space-between;
          align-items: center;
        `};

  .user {
    display: flex;
    gap: 0.5rem;
    align-items: center;

    .column {
      display: flex;
      flex-flow: column nowrap;
    }
  }
`
const ResolveContainer = styled.div<{ resolved: boolean }>`
  display: flex;
  gap: ${spacing['4']};
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  background: ${p => (p.resolved ? p.theme.color.orangePastel : 'none')};
  border-radius: 4px;

  ${p =>
    p.resolved &&
    css`
      button {
        padding: 0;
      }
    `}
`

const SendButton = styled(IconButton).attrs({ variant: 'transparent', size: 'small' })<{ disabled: boolean }>`
  /* color: ${p => p.theme.color.blueBright}; */
  position: absolute;
  bottom: 1.75rem;
  right: 1.75rem;

  ${p =>
    p.disabled &&
    css`
      background-color: transparent;
    `}
`

const ButtonContainer = styled.div`
  display: grid;
  grid-template-columns: repeat(2, min-content);
  gap: 0.5rem;
`

type InlineButtonProps = {
  color: ColorName
}
const InlineButton = styled(Text).attrs(props => ({
  size: 'small',
  bold: true,
  color: props.color,
}))<InlineButtonProps>`
  white-space: nowrap;
  cursor: pointer;
  transition: color 0.1s cubic-bezier(0.25, 0.1, 0.25, 1);

  &:hover {
    color: ${p => p.theme.color.grey80};
  }
`

// const getTruncatedBlockContent = (block: CustomElement): string => {
//   const { children } = block

//   // Extract text from "text blocks"
//   if (['heading', 'preamble', 'paragraph'].includes(block.type) && children.length > 0) {
//     const text = children
//       .map(child => ('text' in child ? child.text : undefined))
//       .filter(isDefined)
//       .join(' ')

//     return text.substr(0, 25)
//   }

//   // Return just the type for most blocks
//   return block.type.replace('-', ' ')
// }

type InlineCommentThreadProps = {
  chatId: ScopedChatId
  chatIdentifier: ChatIdentifier
  mode:
    | {
        type: 'creating'
        contentReference: CommentContentReference
      }
    | {
        type: 'browsing'
        threadId: string
      }
    | null
  componentKey: string
  onClose: () => void
  inputRef?: MutableRefObject<Focusable | undefined>
}

export const CommentThread: FC<InlineCommentThreadProps> = ({
  chatId,
  chatIdentifier,
  mode,
  componentKey,
  onClose,
  inputRef: externalInputRef,
}) => {
  const { t } = useTranslation()
  const { formatTimestamp } = useLocalizedFormatters()
  const dispatch = useDispatch()
  const commenting = useCommentingContext()
  const userId = useSelector(selectUserId)

  const blockThread = useSelector(state =>
    mode === null
      ? undefined
      : mode.type === 'creating' && mode.contentReference.type === 'block'
        ? selectors.selectUnresolvedBlockThread(state, chatId, mode.contentReference.blockId)
        : undefined
  )
  const threadId = mode === null ? undefined : mode.type === 'browsing' ? mode.threadId : blockThread?.id

  const messages = useSelector(state => selectors.selectMessagesInThread(state, chatId, threadId))
  const replies = useSelector(state => selectors.selectMessagesInThreadWithoutRoot(state, chatId, threadId))

  const hasAnyMessages = messages.length > 0
  const hasReplies = messages.length > 1
  const rootMessage = hasAnyMessages ? messages[0] : undefined
  const isResolved =
    hasAnyMessages && rootMessage?.type === 'tiptap-comment' && rootMessage.resolvedAt !== undefined

  const commentingUserIds = useMemo(
    () => [...messages.map(m => m.userId), userId].filter(isDefined),
    [messages, userId]
  )
  const commentingUsers = useUsersLegacy(commentingUserIds).filter(isDefined)
  const threadCreatorId = commentingUsers.find(
    commenter => commenter.uuid === (hasAnyMessages ? rootMessage?.userId : userId)
  )
  const { mutate: newChatSetMessageResolved } = useTypedMutation(XRealtimeChatSetMessageResolved)

  const [inputValue, setInputValue] = useState('')
  const [showReplies, setShowReplies] = useState(false)
  const [showInput, setShowInput] = useState(!hasAnyMessages)

  const inputRef = useRef<CommentInputRef | undefined>()

  const handleReplyClick = (): void => {
    setShowInput(true)
    setShowReplies(true)

    requestAnimationFrame(() => {
      inputRef.current?.focus()
    })
  }

  const sendComment = async (): Promise<void> => {
    if (userId === undefined) {
      throw Error('User Id not set')
    }

    if (inputRef.current === undefined) {
      throw Error('Editor not initiated')
    }

    const tiptapJsonData = inputRef.current.getJSON() as JSONContent

    // If someone was tagged/mentioned and do not have editor or commenter access, add them as commenter
    createCommentersFromMentions(tiptapJsonData, dispatch)
    void commenting?.submitComment({
      threadId,
      userId,
      body: tiptapJsonData,
    })

    setInputValue('')
    setShowInput(false)
    setShowReplies(true)
  }

  const handleResolveThread = (): void => {
    if (userId === undefined || threadId === undefined) return

    const chatMessageId = ChatMessageId.parse(threadId)
    newChatSetMessageResolved({
      chatIdentifier,
      messageId: chatMessageId,
      isResolved: true,
    })

    void dispatch(
      resolveComment({
        chatId,
        threadId,
        userId,
      })
    )
  }

  const handleUnresolveThread = (): void => {
    if (userId === undefined || threadId === undefined) return
    const chatMessageId = ChatMessageId.parse(threadId)
    newChatSetMessageResolved({
      chatIdentifier,
      messageId: chatMessageId,
      isResolved: false,
    })

    void dispatch(
      unresolveComment({
        chatId,
        threadId,
      })
    )
  }

  const handleEnter = (event: React.KeyboardEvent): void => {
    if ((event.metaKey || event.ctrlKey) && inputValue.length > 0) {
      void sendComment()
    }
  }

  /*
   * Keep track of if the chat messages are visible to the user or not
   */
  useEffect(() => {
    if (threadId !== undefined) {
      // The whole thread is visible if there are no replies. So if there are no replies we indicate that the chat is visible even if the replies foldout is not expanded.
      void dispatch(
        chatVisibilityChanged({ chatId, threadId, componentKey, visible: showReplies || !hasReplies })
      )
    }

    return () => {
      if (threadId !== undefined) {
        void dispatch(chatVisibilityChanged({ chatId, threadId, componentKey, visible: false }))
      }
    }
  }, [chatId, threadId, showReplies, hasReplies, componentKey, dispatch])

  /*
   * Keep the external input ref with the internal copy
   */
  useEffect(() => {
    if (externalInputRef !== undefined) {
      externalInputRef.current = inputRef.current
    }
  }, [inputRef, externalInputRef])

  const firstMessage = messages[0]
  if (
    firstMessage !== undefined &&
    firstMessage.type !== 'tiptap-comment' &&
    firstMessage.type !== 'tiptap-plain'
  ) {
    throw new Error(
      `Expected comment thread root to be of type tiptap-comment or tiptap-plain, got ${firstMessage.type}`
    )
  }

  return (
    <ThemeProvider theme={legacyLight}>
      <EmojiPickerPopupProvider>
        <MUIClickAwayListener onClickAway={onClose} mouseEvent='onMouseDown' touchEvent='onTouchStart'>
          <ChatContainerOutline>
            <AppThemeTokenProvider>
              <InlineCommentThreadContainer>
                <AuthorContainer>
                  <ThreadHeader column={isResolved}>
                    <div className='user'>
                      <RoundAvatar
                        key={threadCreatorId?.uuid}
                        size='tiny'
                        firstName={threadCreatorId?.firstName}
                        lastName={threadCreatorId?.lastName}
                        src={getAvatarImage(threadCreatorId?.uuid, threadCreatorId?.avatar)}
                        color={threadCreatorId?.avatarColor}
                      />
                      <View direction='row'>
                        <Text size='small' bold>
                          {getUserName(threadCreatorId)}
                        </Text>
                        {rootMessage !== undefined && (
                          <Text size='small' color='grey25'>
                            {formatTimestamp(rootMessage.timeSent)}
                          </Text>
                        )}
                      </View>
                    </div>
                    {threadId !== undefined && rootMessage?.type === 'tiptap-comment' && (
                      <ResolveContainer resolved={isResolved}>
                        {isResolved && rootMessage.resolvedAt !== undefined ? (
                          <>
                            <Button variant='transparent' onClick={handleUnresolveThread}>
                              {t('dictionary.unresolve')}
                            </Button>
                            <Text size='small'>
                              {t('commenting.resolved-at', {
                                resolvedAt: formatTimestamp(rootMessage.resolvedAt),
                              })}
                            </Text>
                          </>
                        ) : (
                          <IconButton
                            variant='transparent'
                            onClick={handleResolveThread}
                            iconId='checkmark--outline'
                            tooltip={t('dictionary.resolve')}
                          ></IconButton>
                        )}
                      </ResolveContainer>
                    )}
                  </ThreadHeader>
                  {hasAnyMessages && (
                    <div className='rootMessage'>
                      <ReadOnlyTiptapContent
                        content={firstMessage?.tiptapJsonData}
                        edited={firstMessage?.timeEdited !== undefined}
                      />
                    </div>
                  )}
                </AuthorContainer>

                <AnimatePresence>
                  {hasReplies && showReplies && (
                    <RepliesContainer>
                      {replies.map(msg => (
                        <ChatMessage
                          key={msg.id}
                          userId={msg.userId}
                          chatId={chatId}
                          chatIdentifier={chatIdentifier}
                          messageId={msg.id}
                          timeSent={msg.timeSent}
                          hideTitle={false}
                          withoutContentReferences
                        />
                      ))}
                    </RepliesContainer>
                  )}
                </AnimatePresence>

                {hasReplies &&
                  (showReplies ? (
                    <ButtonContainer>
                      <InlineButton onClick={() => setShowReplies(false)} color='grey25'>
                        {t('chat.hide-answers')}
                      </InlineButton>
                    </ButtonContainer>
                  ) : (
                    <ButtonContainer>
                      <InlineButton onClick={() => setShowReplies(true)} color='grey25'>
                        {t('chat.show-n-answers', { count: messages.length - 1 })}
                      </InlineButton>
                    </ButtonContainer>
                  ))}

                {threadId === undefined || showInput ? (
                  <>
                    <CommentInput
                      onChange={setInputValue}
                      placeholder={t('chat.add-comment-placeholder')}
                      onEnter={handleEnter}
                      ref={inputRef}
                      maxLines={1}
                      autoFocus={showInput}
                    />
                    <SendButton
                      iconId='send--filled'
                      color='black'
                      disabled={inputValue.length === 0}
                      onClick={sendComment}
                    />
                  </>
                ) : (
                  <ButtonContainer>
                    <InlineButton onClick={handleReplyClick} color='grey25'>
                      {t('dictionary.reply')}
                    </InlineButton>
                  </ButtonContainer>
                )}
              </InlineCommentThreadContainer>
            </AppThemeTokenProvider>
          </ChatContainerOutline>
        </MUIClickAwayListener>
      </EmojiPickerPopupProvider>
    </ThemeProvider>
  )
}
