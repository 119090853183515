import { color } from 'sierra-ui/color'
import { IconButton } from 'sierra-ui/primitives'
import { Theme } from 'sierra-ui/theming/legacy-theme'
import styled, { css, keyframes } from 'styled-components'

export const BlurButton = styled(IconButton).attrs({
  disabledHover: true,
  disabledBackgroundHover: true,
  color: 'white',
})<{ circular?: boolean; width?: number; height?: number }>`
  background: rgba(0, 0, 0, 0.1);
  backdrop-filter: blur(32px);
  width: ${p => (p.width !== undefined ? `${p.width}px` : '40px')};
  height: ${p => (p.height !== undefined ? `${p.height}px` : '40px')};
  border-radius: ${p => (p.circular === true ? '50%' : '12px')};
  flex-shrink: 0;

  &:hover {
    background: rgba(0, 0, 0, 0.2);
  }
`

export const Loader = styled.div<{ $loading?: boolean; fileTheme?: Theme }>`
  width: 100%;
  height: 100%;
  display: none;

  ${p =>
    p.$loading === true &&
    css`
      display: block;
    `}

  ${p => {
    const theme: Theme = p.fileTheme ?? p.theme
    return css`
      animation: ${keyframes`
                from {
                    background-color: ${color(theme.home.backgroundColor).shift(0.1).toString()};
                }
                to {
                    background-color: ${color(theme.home.backgroundColor).shift(0.2).toString()};
                }
            `} 800ms infinite alternate;
    `
  }}

    pointer-events: none;

  & * {
    pointer-events: auto;
  }
`
