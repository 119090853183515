import { createDraftSafeSelector, createSelector } from '@reduxjs/toolkit'
import { createCachedSelector } from 're-reselect'
import {
  coursesAdapter,
  linkCoursesAdapter,
  linkedinCoursesAdapter,
  pathsAdapter,
  programAdapter,
  scormCoursesAdapter,
  tagsDataAdapter,
} from 'sierra-client/state/content/adapters'
import { ContentState } from 'sierra-client/state/content/types'
import { RootState } from 'sierra-client/state/types'
import { CourseId } from 'sierra-domain/api/nano-id'

export const courseSelectors = coursesAdapter.getSelectors<RootState>(state => state.courses)
export const partnerCourseSelectors = linkedinCoursesAdapter.getSelectors<RootState>(
  state => state.partnerCourses
)
export const linkCourseSelectors = linkCoursesAdapter.getSelectors<RootState>(state => state.linkCourses)
export const scormCourseSelectors = scormCoursesAdapter.getSelectors<RootState>(state => state.scormCourses)

export const tagsDataSelectors = tagsDataAdapter.getSelectors<RootState>(state => state.tagsData)

export const selectCourseById = createCachedSelector(
  [courseSelectors.selectEntities, (_state: RootState, courseId: CourseId) => courseId],
  (entities, courseId) => entities[courseId]
)((_state, courseId) => courseId)

const selectContentState = (state: RootState): ContentState => state.content

export const selectCurrentCourseId = createDraftSafeSelector(
  selectContentState,
  content => content.currentCourseId
)

export const selectCurrentLiveSessionId = createDraftSafeSelector(
  selectContentState,
  content => content.currentLiveSessionId
)

export const isInLiveSession = createDraftSafeSelector(
  selectContentState,
  content => content.currentLiveSessionId !== undefined
)

export const isInSelfPaced = createDraftSafeSelector(
  selectContentState,
  content => content.currentCourseId !== undefined
)

export const selectCurrentProgramId = createDraftSafeSelector(
  selectContentState,
  content => content.currentProgramId
)

export const selectCurrentPathId = createDraftSafeSelector(
  selectContentState,
  content => content.currentPathId
)

const pathSelectors = pathsAdapter.getSelectors<RootState>(state => state.paths)
export const selectCurrentPath = createDraftSafeSelector(
  [selectCurrentPathId, pathSelectors.selectEntities],
  (currentPathId, paths) => (currentPathId !== undefined ? paths[currentPathId] : undefined)
)

const programSelectors = programAdapter.getSelectors<RootState>(state => state.program)
export const selectCurrentProgram = createDraftSafeSelector(
  [selectCurrentProgramId, programSelectors.selectEntities],
  (currentProgramId, programs) => (currentProgramId !== undefined ? programs[currentProgramId] : undefined)
)

export const selectCurrentCourse = createDraftSafeSelector(
  [selectCurrentCourseId, courseSelectors.selectEntities],
  (currentCourseId, courses) => (currentCourseId !== undefined ? courses[currentCourseId] : undefined)
)

export const selectHasLoadedCourseContent = createDraftSafeSelector(
  selectCurrentCourse,
  course => course !== undefined
)

export const selectCurrentCourseSettings = createSelector(selectCurrentCourse, course => course?.settings)

export const selectCurrentCourseTheme = createDraftSafeSelector(
  selectCurrentCourseSettings,
  settings => settings?.theme
)

export const selectCurrentPartnerCourse = createDraftSafeSelector(
  [selectCurrentCourseId, partnerCourseSelectors.selectEntities],
  (currentCourseId, courses) => (currentCourseId !== undefined ? courses[currentCourseId] : undefined)
)

export const selectCurrentLinkCourse = createDraftSafeSelector(
  [selectCurrentCourseId, linkCourseSelectors.selectEntities],
  (currentCourseId, courses) => (currentCourseId !== undefined ? courses[currentCourseId] : undefined)
)

export const selectCurrentScormCourse = createDraftSafeSelector(
  [selectCurrentCourseId, scormCourseSelectors.selectEntities],
  (currentCourseId, courses) => (currentCourseId !== undefined ? courses[currentCourseId] : undefined)
)

export const selectPrependUrlWithProgramAndPath = createSelector(
  selectCurrentProgramId,
  selectCurrentPathId,
  (programId?: string, pathId?: string) => (url: string) =>
    `${programId !== undefined ? `/program/${programId}` : ''}${
      pathId !== undefined ? `/path/${pathId}` : ''
    }${url}`
)
