import { FC, useState } from 'react'
import { NotificationList } from 'sierra-client/features/activity-center/notification-list'
import { useTranslation } from 'sierra-client/hooks/use-translation'
import { useCachedQuery } from 'sierra-client/state/api'
import { XRealtimeContentHomeNotifications } from 'sierra-domain/routes'
import { iife, isDefined } from 'sierra-domain/utils'
import { Popover } from 'sierra-ui/components'
import { IconButton, LoadingSpinner, ScrollView, Text, View } from 'sierra-ui/primitives'
import styled from 'styled-components'

export { NotificationCard } from 'sierra-client/features/activity-center/notification-card'
export { NotificationList } from 'sierra-client/features/activity-center/notification-list'

const Container = styled(View).attrs({ direction: 'column', gap: '12' })`
  width: 500px;
  height: 400px;
`

const RedDot = styled.div`
  width: 8px;
  height: 8px;
  background-color: red;
  border-radius: 50%;
  position: absolute;
  top: 4px;
  right: 4px;
`

const StyledIconContainer = styled.div`
  position: relative;
`

const PopoverHeader = styled.div`
  padding: 20px 24px 8px 24px;
`

const ActivityCenterContent: FC = () => {
  const notificationsQuery = useCachedQuery(XRealtimeContentHomeNotifications, {})

  if (notificationsQuery.isPending) {
    return (
      <Container justifyContent='center'>
        <LoadingSpinner />
      </Container>
    )
  }

  if (notificationsQuery.isError) {
    return (
      <Container>
        <Text bold color='destructive/background'>
          Something went wrong here, try again later.
        </Text>
      </Container>
    )
  }

  if (notificationsQuery.data.notifications.length === 0) {
    return (
      <Container>
        <Text>No new notifications</Text>
      </Container>
    )
  }

  return (
    <Container>
      <NotificationList notifications={notificationsQuery.data.notifications} />
    </Container>
  )
}

export const ActivityCenter: FC = () => {
  const { t } = useTranslation()
  const [isOpen, setIsOpen] = useState(false)

  const refetchInterval = iife(() => {
    return isOpen ? 10_000 : 60_000
  })

  const notificationsQuery = useCachedQuery(XRealtimeContentHomeNotifications, {}, { refetchInterval })
  const hasUnreads = notificationsQuery.data?.notifications.some(notif => !isDefined(notif.seenAt)) ?? false

  return (
    <Popover
      isOpen={isOpen}
      onOpenChange={setIsOpen}
      renderTrigger={() => {
        return (
          <StyledIconContainer>
            {hasUnreads && <RedDot />}
            <IconButton variant='transparent' iconId='notification' />
          </StyledIconContainer>
        )
      }}
      align='end'
      sideOffset={8}
    >
      <PopoverHeader>
        <Text bold>{t('table.activity')}</Text>
      </PopoverHeader>
      <ScrollView>
        <ActivityCenterContent />
      </ScrollView>
    </Popover>
  )
}
