import { resolveUserAvatar } from 'sierra-client/api/content'
import { useUsersLegacy } from 'sierra-client/state/users/hooks'
import { BreakoutRoom as BreakoutRoomT } from 'sierra-domain/live-session'
import { isDefined } from 'sierra-domain/utils'
import { AvatarStack } from 'sierra-ui/components'
import styled from 'styled-components'

const AvatarContainer = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: center;
  position: absolute;
  top: 1.375rem;
  padding: 0 1rem;
  width: 100%;
`

const RoomInfo = styled.div`
  display: flex;
  flex-direction: row;
  color: white;
  position: absolute;
  bottom: 1rem;
  padding: 0 1rem;
  width: 100%;
`

const RoomName = styled.div`
  text-shadow:
    0px 1px 1px rgba(0, 0, 0, 0.35),
    0px 2px 6px rgba(0, 0, 0, 0.24);
  font-weight: 500;
  max-width: 100%;
  display: -webkit-box;
  -webkit-line-clamp: 2;
  -webkit-box-orient: vertical;
  overflow: hidden;
  text-overflow: ellipsis;
`

const BorderRadiusContainer = styled.div`
  overflow: hidden;
  background-color: ${p => p.theme.color.grey60};
  border-radius: 6px;
  position: relative;
  width: 100%;
  height: 100%;

  /**
  Safari hack for border radius with overflow hidden
  This will tell the browser this element needs to create a new stacking context
  https://stackoverflow.com/questions/49066011/overflow-hidden-with-border-radius-not-working-on-safari
  */
  isolation: isolate;
`

const RoomContainer = styled.div`
  position: relative;
  width: 100%;
  height: 150px;
`

export const BreakoutRoom = ({
  room,
  onClick = () => {},
}: {
  room: BreakoutRoomT
  onClick: () => void
}): JSX.Element => {
  const users = useUsersLegacy(room.participants)
  const definedUsers = users.filter(isDefined).map(resolveUserAvatar)

  return (
    <RoomContainer onClick={onClick}>
      <BorderRadiusContainer>
        <RoomInfo>
          <RoomName>{room.title}</RoomName>
        </RoomInfo>
        <AvatarContainer>
          <AvatarStack users={definedUsers} size='medium' />
        </AvatarContainer>
      </BorderRadiusContainer>
    </RoomContainer>
  )
}
