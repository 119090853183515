import { createFileRoute } from '@tanstack/react-router'

import { requireLoggedIn } from 'sierra-client/core/require-logged-in'
import { useFlexibleContentRecord } from 'sierra-client/hooks/use-flexible-content-record'
import { ExportFlexibleContent } from 'sierra-client/views/v3-author/export-pdf/export-flexible-content'
import { LiveContentId } from 'sierra-domain/api/nano-id'
import { ScopedLiveContentId } from 'sierra-domain/collaboration/types'
import { LoadingSpinner, View } from 'sierra-ui/primitives'
import { z } from 'zod'

function Page(): JSX.Element {
  const liveContentId = Route.useParams({ select: params => params.liveContentId })
  const scopedCreateContentId = ScopedLiveContentId.fromId(liveContentId)

  const record = useFlexibleContentRecord({ scopedCreateContentId })
  return (
    <>
      {record === undefined ? (
        <View grow justifyContent='center'>
          <LoadingSpinner />
        </View>
      ) : (
        <ExportFlexibleContent record={record} scopedCreateContentId={scopedCreateContentId} />
      )}
    </>
  )
}

export const Route = createFileRoute('/create/export/l/$liveContentId')({
  component: requireLoggedIn(Page) as React.FC,
  params: {
    parse: z.object({ liveContentId: LiveContentId }).parse,
    stringify: p => p,
  },
})
