import React from 'react'
import { FCC } from 'sierra-client/types'
import { useEditorReadOnly } from 'sierra-client/views/v3-author/editor-context/editor-context'
import { useIsUniquelySelected } from 'sierra-client/views/v3-author/hooks'
import { BlockToolbar, Icon, IconId, Tooltip } from 'sierra-ui/components'
import { DarkTokenProvider, palette } from 'sierra-ui/theming'
import { useFocused } from 'slate-react'
import styled from 'styled-components'

const StyledIcon = styled(Icon).attrs({ color: 'white' })<{ selected: boolean }>`
  color: ${p => (p.selected ? palette.primitives.white : p.theme.color.grey35)};
`

export const ToolbarSeparator = styled.div`
  border-left: 1px solid ${p => p.theme.color.grey80};
  width: 1px;
  flex: 1;
`

export const ToolbarItem = styled.a`
  display: inline-flex;
  justify-content: center;
  align-items: center;

  /* padding: calc(4px + 0.5rem); */
  padding: 0.5rem;
  cursor: pointer;

  &:hover ${StyledIcon} {
    color: ${palette.primitives.white};
  }
`

export const ToolbarIcon: React.FC<{
  tooltip?: string
  selected?: boolean
  iconId: IconId
  onClick?: () => void
  className?: string
  href?: string
}> = ({ tooltip, selected = false, iconId, onClick, className, href }) => (
  <Tooltip title={tooltip}>
    <ToolbarItem
      className={className}
      href={href}
      target={'_blank'}
      onClick={e => {
        if (onClick !== undefined) {
          e.preventDefault()
          onClick()
        }
      }}
    >
      <StyledIcon iconId={iconId} size='size-16' selected={selected} />
    </ToolbarItem>
  </Tooltip>
)

const ToolbarLabelWrapper = styled.div`
  color: ${palette.primitives.white};
  height: 100%;
  display: flex;
  align-items: center;
`

export const ToolbarLabel: FCC = ({ children }) => {
  return <ToolbarLabelWrapper>{children}</ToolbarLabelWrapper>
}

const arrowSize = '0.5rem'
const Arrow = styled.span`
  bottom: 0;
  border-left: ${arrowSize} solid transparent;
  border-right: ${arrowSize} solid transparent;
  border-top: ${arrowSize} solid ${palette.primitives.black};
  margin-bottom: -${arrowSize};
  position: absolute;
  bottom: 0;
`

const ToolbarWrapper = styled.div`
  display: flex;
  justify-content: center;
  align-items: stretch;
  background-color: ${palette.primitives.black};
  border-radius: 4px;
  user-select: none;
  min-height: 2rem;
  height: fit-content;
  width: max-content;
  position: absolute;
  top: -55px;
  left: 50%;
  transform: translateX(-50%);
`

export const Toolbar: FCC<{
  elementId: string
  className?: string
  ignoreEditorFocus?: boolean
}> = ({ children, elementId, className, ignoreEditorFocus = false }) => {
  const isReadOnly = useEditorReadOnly()
  const isOnlySelectedElement = useIsUniquelySelected({ nodeId: elementId })
  const isEditorFocused = useFocused()

  if (isReadOnly) return null
  if (!isOnlySelectedElement) return null
  if (!ignoreEditorFocus && !isEditorFocused) return null
  return (
    <ToolbarWrapper className={className} contentEditable={false} onClick={e => e.stopPropagation()}>
      {children}
      <Arrow />
    </ToolbarWrapper>
  )
}

const ToolbarV2Wrapper = styled.div`
  display: flex;
  justify-content: center;
  user-select: none;
  min-height: 2rem;
  position: absolute;
  top: -55px;
  left: 50%;
  transform: translateX(-50%);
  cursor: auto;
  min-width: max-content;
`

export const ToolbarV2: FCC<{
  elementId: string
  ignoreEditorFocus?: boolean
}> = ({ children, elementId, ignoreEditorFocus = false }) => {
  const isReadOnly = useEditorReadOnly()
  const isOnlySelectedElement = useIsUniquelySelected({ nodeId: elementId })
  const isEditorFocused = useFocused()

  if (isReadOnly) return null
  if (!isOnlySelectedElement) return null
  if (!ignoreEditorFocus && !isEditorFocused) return null
  return (
    <DarkTokenProvider>
      <ToolbarV2Wrapper contentEditable={false}>
        <BlockToolbar.Root>{children}</BlockToolbar.Root>
        <Arrow />
      </ToolbarV2Wrapper>
    </DarkTokenProvider>
  )
}
