import { useEffect, useState } from 'react'
import { NanoId12 } from 'sierra-client/api/graphql/custom-scalars'
import { ExercisesQuery } from 'sierra-client/api/graphql/gql/graphql'
import { NotifyAdminSelector, ReviewerSelector } from 'sierra-client/features/homework'
import { useHomeworkPermissions } from 'sierra-client/hooks/use-permissions'
import { useTranslation } from 'sierra-client/hooks/use-translation'
import { useExercises } from 'sierra-client/views/course-settings/components/useExercises'
import { SettingsTabComponent } from 'sierra-client/views/course-settings/types'
import { SelfPacedContentId } from 'sierra-domain/api/nano-id'
import { Icon } from 'sierra-ui/components'
import { Spacer, Text, View } from 'sierra-ui/primitives'
import { token } from 'sierra-ui/theming'
import { isDefined } from 'sierra-ui/utils/is-defined'
import styled from 'styled-components'

const ExerciseItemView = styled(View).attrs({ animated: true, layout: true })`
  border-bottom: 1px solid ${token('form/border/1')};
  padding-bottom: 12px;
`

const ExerciseHeader = styled(View).attrs({ animated: true, layout: true })`
  display: flex;
  justify-content: space-between;
  align-items: center;
  cursor: pointer;
  padding: 12px 0;
`

const ExerciseContent = styled(View).attrs({ animated: true, layout: true })<{ $isOpen: boolean }>`
  height: ${props => (props.$isOpen ? 'auto' : '0')};
  overflow-y: hidden;
  padding: 0 2px;
`

const ExerciseItem: React.FC<{
  exercise: ExercisesQuery['homeworks'][number]
  isOpen: boolean
  toggleExercise: (exerciseId: string) => void
  courseId: SelfPacedContentId
}> = ({ exercise, isOpen, toggleExercise, courseId }) => {
  const { t } = useTranslation()
  const homeworkPermissions = useHomeworkPermissions(exercise.id)
  const canEditReviewersAndAdmins = homeworkPermissions.has('EDIT_REVIEWERS_AND_ADMINS')

  return (
    <ExerciseItemView direction='column' gap='none' key={exercise.id}>
      <ExerciseHeader onClick={() => toggleExercise(exercise.id)}>
        <View direction='row' gap='8' alignItems='center'>
          {/* TODO(Ludwig): Add icon for exercises based on card theme */}
          {/* <Icon size='size-10' iconId={fileTypeToIconId('homework')} /> */}
          <Text bold>{exercise.title ?? t('dictionary.homework-singular')}</Text>
        </View>
        <Icon iconId={isOpen ? 'chevron--up--small' : 'chevron--down--small'} color='foreground/muted' />
      </ExerciseHeader>

      <ExerciseContent direction='column' $isOpen={isOpen}>
        <Spacer axis='vertical' size='xxsmall' />
        <ReviewerSelector
          courseId={courseId}
          fileId={NanoId12.parse(exercise.cardId)}
          canEdit={canEditReviewersAndAdmins}
        />
        {isDefined(exercise.submissionsLimit) && (
          <>
            <Spacer axis='vertical' size='xxsmall' />
            <NotifyAdminSelector
              courseId={courseId}
              fileId={NanoId12.parse(exercise.cardId)}
              canEdit={canEditReviewersAndAdmins}
            />
          </>
        )}
        <Spacer axis='vertical' size='xsmall' />
      </ExerciseContent>
    </ExerciseItemView>
  )
}

export const ExercisesTab: SettingsTabComponent = ({ courseId }) => {
  const queryResult = useExercises(SelfPacedContentId.parse(courseId))

  const exercises = queryResult.data?.homeworks ?? []
  const sortedExercises = [...exercises].sort(
    (a, b) => (a.file.indexInCourse || 0) - (b.file.indexInCourse || 0)
  )

  // open the first exercise by default
  const firstExerciseId = sortedExercises[0]?.id
  const [openExercises, setOpenExercises] = useState<Set<string>>(new Set())

  useEffect(() => {
    setOpenExercises(new Set(firstExerciseId !== undefined ? [firstExerciseId] : []))
  }, [firstExerciseId])

  const toggleExercise = (exerciseId: string): void => {
    setOpenExercises(prev => {
      const newSet = new Set(prev)
      if (newSet.has(exerciseId)) {
        newSet.delete(exerciseId)
      } else {
        newSet.add(exerciseId)
      }
      return newSet
    })
  }

  return (
    <View direction='column' gap='12'>
      {sortedExercises.map(exercise => {
        const isOpen = openExercises.has(exercise.id)
        return (
          <ExerciseItem
            key={exercise.id}
            exercise={exercise}
            isOpen={isOpen}
            toggleExercise={toggleExercise}
            courseId={SelfPacedContentId.parse(courseId)}
          />
        )
      })}
    </View>
  )
}
