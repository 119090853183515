import { GradeStatus as GQLGradeStatus } from 'sierra-client/api/graphql/gql/graphql'
import { GradeStatus } from 'sierra-domain/api/homework'

export const gqlGradeStatusToGradeStatus: Record<GQLGradeStatus, GradeStatus> = {
  FAILED: 'failed',
  FAILED_WITH_NO_RETRIES: 'failed-with-no-retries',
  PASSED: 'passed',
  NOT_GRADED: 'not-graded',
  NOT_SUBMITTED: 'not-submitted',
  DISMISSED: 'dismissed',
}
