import React from 'react'
import { useTranslation } from 'sierra-client/hooks/use-translation'
import { useSelector } from 'sierra-client/state/hooks'
import { selectUserId } from 'sierra-client/state/user/user-selector'
import type { FCC } from 'sierra-client/types'
import { DisplayNone } from 'sierra-client/views/v3-author/components'
import { HomeworkUpload } from 'sierra-client/views/v3-author/homework/homework-upload/homework-upload'
import { assertElementType } from 'sierra-client/views/v3-author/queries'
import { RenderingContext } from 'sierra-client/views/v3-author/rendering-context'
import { SlateWrapperProps } from 'sierra-client/views/v3-author/slate'
import { CardGrid } from 'sierra-client/views/v3-author/wrapper'
import { CreateContentId } from 'sierra-domain/api/nano-id'
import { FileId } from 'sierra-domain/flexible-content/identifiers'
import { HomeworkData } from 'sierra-domain/flexible-content/types'
import { Text, View } from 'sierra-ui/primitives'
import { maxWidth } from 'sierra-ui/utils/media-query-styles'
import styled from 'styled-components'

const StyledGridWrapper = styled(CardGrid)`
  padding: 64px 8px 80px;
  margin-top: auto;
  margin-bottom: auto;
  align-content: center;
  row-gap: 0;

  ${maxWidth.desktop_small} {
    row-gap: 24px;
    grid-template-columns: 1fr;
    padding-inline: 16px;
  }
`

const InstructionSection = styled(View)<{ $fullWidth: boolean }>`
  grid-column: ${p => (p.$fullWidth ? '2/14' : '2/7')};

  ${maxWidth.desktop_small} {
    grid-column: 1/-1;
  }
`

export const HomeworkWrapper = React.forwardRef<HTMLDivElement, SlateWrapperProps>((props, ref) => {
  const { children, attributes, mode, element } = props
  assertElementType('homework-card', element)
  const userId = useSelector(selectUserId)
  const { t } = useTranslation()

  if (userId === undefined) {
    return <DisplayNone>{children}</DisplayNone>
  }

  if (mode !== 'self-paced' && mode !== 'create' && mode !== 'version-history' && mode !== 'template') {
    throw new Error(`Can't render Homework card in mode: ${mode}`)
  }

  return (
    <RenderingContext withGrid={false} preventDrag={true} disableMenu={false}>
      <StyledGridWrapper {...props} {...attributes} ref={ref}>
        <InstructionSection
          direction='column'
          $fullWidth={mode === 'create' || mode === 'version-history' || mode === 'self-paced'}
        >
          <View contentEditable={false}>
            <Text size='small' bold>
              {t('dictionary.instruction-singular')}
            </Text>
          </View>
          {children}
        </InstructionSection>
      </StyledGridWrapper>
    </RenderingContext>
  )
})

const SelfPacedHomeworkContainer = styled.div`
  display: grid;
  grid-template-columns: 1fr 1fr;
  gap: 12px;
  align-items: center;
  width: 100%;

  ${maxWidth.desktop_small} {
    grid-template-columns: 1fr;
    grid-template-rows: auto auto;
    padding-block: 52px;
  }
`

const SubmitSection = styled.div`
  padding: 20px;
`

export const SelfPacedHomeworkCard: FCC<{
  courseId: CreateContentId
  fileId: FileId
  data: HomeworkData
}> = ({ children, courseId, fileId, data }) => {
  return (
    <div>
      <SelfPacedHomeworkContainer>
        {children}
        <SubmitSection>
          <HomeworkUpload courseId={courseId} fileId={fileId} data={data} />
        </SubmitSection>
      </SelfPacedHomeworkContainer>
    </div>
  )
}
