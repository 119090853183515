import { useAtom, useSetAtom } from 'jotai'
import { range, round } from 'lodash'
import { useEffect, useState } from 'react'
import { isDenoiserSupported } from 'sierra-client/components/liveV2/services/video-call-service'
import { isPushToTalkSupported } from 'sierra-client/components/liveV2/services/video-call-service/helpers/browser-support'
import { getLivePushToTalkShortcutHint } from 'sierra-client/components/liveV2/services/video-call-service/hooks/use-live-keyboard-shortcuts'
import { useLocalTracks } from 'sierra-client/components/liveV2/services/video-call-service/hooks/useLocalTracks'
import { ShortcutMenu, UILabel } from 'sierra-client/components/shortcut-menu'
import { useShortcutMenuDispatch } from 'sierra-client/components/shortcut-menu/context'
import { Logging } from 'sierra-client/core/logging'
import { useTranslation } from 'sierra-client/hooks/use-translation'
import { useDispatch, useSelector } from 'sierra-client/state/hooks'
import { setNoiseCancellation, updateAvailableMicrophones } from 'sierra-client/state/live/actions'
import { selectNoiseCancellationStatus } from 'sierra-client/state/live/selectors'
import { noiseCancellationEnabledAtom, pushToTalkEnabledAtom } from 'sierra-client/state/live/settings'
import {
  SettingsDropdownWrapper,
  SettingsText,
  StyledText,
  SwitchSetting,
} from 'sierra-client/views/liveV2/settings/common'
import { IssueMessages } from 'sierra-client/views/liveV2/settings/issues'
import { LiveSettingsTabComponent } from 'sierra-client/views/liveV2/settings/live-tabs'
import { useMicrophonesSettings } from 'sierra-client/views/liveV2/use-microphone-settings'
import { Switch, Text, View } from 'sierra-ui/primitives'
import { SingleSelectDropdown } from 'sierra-ui/primitives/menu-dropdown'
import styled from 'styled-components'

export const AudioSettingsShortcut: React.FC<{ label: UILabel }> = ({ label }) => {
  const dispatch = useShortcutMenuDispatch()
  const microphonesSettings = useMicrophonesSettings()
  if (!microphonesSettings) return null
  return (
    <ShortcutMenu.Settings
      label={label}
      permission='ACCESS_LMS'
      items={microphonesSettings.items}
      currentItemIds={[microphonesSettings.currentId]}
      onItemSelected={item => {
        microphonesSettings.onItemSelected(item)
        void dispatch({ type: 'close' })
      }}
      group='live'
    />
  )
}

const AudioIndicatorDot = styled.div`
  width: 0.5rem;
  height: 1rem;
  border-radius: 0.25rem;
  background-color: ${props => props.theme.color.grey15};
`

const AudioIndicatorContainer = styled(View)<{ $audioLevel: number }>`
  ${AudioIndicatorDot}:nth-child(-n + ${p => p.$audioLevel}) {
    background-color: ${props => props.theme.color.grey85};
  }
`

const AUDIO_STEPS = 20
const AudioVolumeControls = (): JSX.Element => {
  const { localAudioTrack } = useLocalTracks()
  const [audioVolume, setAudioVolume] = useState(0)

  const isMuted = localAudioTrack?.muted ?? true

  useEffect(() => {
    const track = localAudioTrack
    if (!track) return

    let stop = false
    let id: undefined | number = undefined
    const onFrame = (): void => {
      id = requestAnimationFrame(() => {
        if (stop) return
        setAudioVolume(round(track.getVolumeLevel() * AUDIO_STEPS))
        setTimeout(onFrame, 50)
      })
    }

    onFrame()
    return () => {
      stop = true
      if (id !== undefined) cancelAnimationFrame(id)
    }
  }, [localAudioTrack])

  return (
    <AudioIndicatorContainer $audioLevel={isMuted ? 0 : audioVolume} direction='row' gap='4'>
      {range(AUDIO_STEPS).map(i => (
        <AudioIndicatorDot key={i} />
      ))}
    </AudioIndicatorContainer>
  )
}

export const AudioSettingsTab: LiveSettingsTabComponent = () => {
  const { t } = useTranslation()
  const dispatch = useDispatch()
  const saveDenoiserSetting = useSetAtom(noiseCancellationEnabledAtom)
  const denoiserEnabled = useSelector(selectNoiseCancellationStatus)
  const [pushToTalkEnabled, setPushToTalkEnabled] = useAtom(pushToTalkEnabledAtom)

  const microphoneSettings = useMicrophonesSettings()

  useEffect(() => {
    // The list of devices is cached for performance reasons and it should be up to date,
    // however, since we aren't guarantied to get a callback when a users devices change,
    // we make sure to update the list of available microphones when the user opens the
    // audio tab
    void dispatch(updateAvailableMicrophones())
  }, [dispatch])

  const updateNoiseCancellationSetting = async (): Promise<void> => {
    const res = await dispatch(setNoiseCancellation({ enabled: !denoiserEnabled })).unwrap()
    saveDenoiserSetting(res.enabled)
    void dispatch(Logging.liveSession.noiseCancellationToggled({ enabled: !denoiserEnabled }))
  }

  const selectedMicrophoneSetting = microphoneSettings?.items.find(
    item => item.id === microphoneSettings.currentId
  )
  return (
    <View direction='column' gap='medium'>
      <View direction='column' gap='xsmall'>
        <IssueMessages category='audio' />
        <SettingsText>{t('dictionary.microphone')}</SettingsText>
        {microphoneSettings !== undefined ? (
          <SettingsDropdownWrapper>
            <SingleSelectDropdown
              selectedItem={selectedMicrophoneSetting}
              menuItems={microphoneSettings.items}
              onSelect={microphoneSettings.onItemSelected}
            />
          </SettingsDropdownWrapper>
        ) : (
          <Text color='grey40' size='small'>
            {t('live.no-microphones-available')}
          </Text>
        )}
        <View direction='column' gap='xxsmall'>
          <SettingsText>{t('live.audio-level')}</SettingsText>
          <AudioVolumeControls />
        </View>
      </View>
      <View direction='column' gap='4'>
        <SwitchSetting>
          <SettingsText>{t('live.noise-suppression')}</SettingsText>
          <Switch
            disabled={!isDenoiserSupported}
            checked={denoiserEnabled}
            onChange={updateNoiseCancellationSetting}
            size='small'
            ariaLabel={t('live.noise-suppression')}
          />
        </SwitchSetting>
        {!isDenoiserSupported ? (
          <StyledText color='foreground/muted' size='small'>
            {t('live.supported-browsers-all')}
          </StyledText>
        ) : (
          <StyledText color='foreground/muted' size='small'>
            {t('live.noise-suppression-info')}
          </StyledText>
        )}
      </View>
      <View direction='column' gap='4'>
        <SwitchSetting>
          <SettingsText>{t('live.push-to-talk')}</SettingsText>
          <Switch
            disabled={!isPushToTalkSupported}
            size='small'
            checked={pushToTalkEnabled}
            onChange={() => {
              setPushToTalkEnabled(current => {
                void dispatch(Logging.liveSession.pushToTalkSettingToggled({ enabled: !current }))
                return !current
              })
            }}
            ariaLabel={t('live.push-to-talk')}
          />
        </SwitchSetting>
        {!isPushToTalkSupported ? (
          <StyledText color='foreground/muted' size='small'>
            {t('live.supported-browsers-all')}
          </StyledText>
        ) : (
          <StyledText color='foreground/muted' size='small'>
            {t('live.push-to-talk-hint', { hint: getLivePushToTalkShortcutHint() })}
          </StyledText>
        )}
      </View>
    </View>
  )
}
