import { AnimatePresence, motion } from 'framer-motion'
import React, { useRef, useState } from 'react'
import { useDateTimeFormatter } from 'sierra-client/core/format'
import { useIsDebugMode } from 'sierra-client/hooks/use-is-debug-mode'
import { useTranslation } from 'sierra-client/hooks/use-translation'
import { useTypedMutation } from 'sierra-client/state/api'
import {
  ComplianceCard,
  ErrorCard,
  TerminationCard,
} from 'sierra-client/views/v3-author/scenario/chat/completion'
import { DebugMenu } from 'sierra-client/views/v3-author/scenario/chat/debug'
import { ScenarioHeader } from 'sierra-client/views/v3-author/scenario/chat/header'
import { Input } from 'sierra-client/views/v3-author/scenario/chat/input'
import { ScenarioChatState } from 'sierra-client/views/v3-author/scenario/chat/state'
import { GenerationBackground } from 'sierra-client/views/v3-author/scenario/generation-background'
import { MessageLoaderDots } from 'sierra-client/views/v3-author/scenario/loading-views'
import { useTracking } from 'sierra-client/views/v3-author/scenario/tracking'
import { getScenarioBackgroundColor, ScenarioFile } from 'sierra-client/views/v3-author/scenario/utils'
import { CreateContentId } from 'sierra-domain/api/nano-id'
import { uuid } from 'sierra-domain/api/uuid'
import { ScenarioCharacter } from 'sierra-domain/flexible-content/types'
import {
  ScenarioMessage,
  XRealtimeSelfPacedScenarioComplianceCheck,
  XRealtimeSelfPacedScenarioNextMessage,
  XRealtimeSelfPacedScenarioNextMessagePreview,
  XRealtimeSelfPacedScenarioTerminationCheck,
} from 'sierra-domain/routes'
import { isDefined, isEmptyArray, isNotDefined } from 'sierra-domain/utils'
import { resolveTokenOrColor } from 'sierra-ui/color/token-or-color'
import { CloseModalButton, Icon, Modal } from 'sierra-ui/components'
import { Text, View } from 'sierra-ui/primitives'
import { v2_breakpoint } from 'sierra-ui/theming/breakpoints'
import styled from 'styled-components'

const MessageContainer = styled(View)<{
  $sender: 'user' | 'assistant'
}>`
  align-self: ${p => (p.$sender === 'user' ? 'flex-end' : 'flex-start')};
  align-items: ${p => (p.$sender === 'user' ? 'flex-end' : 'flex-start')};
  max-width: 536px;
  flex-direction: column;
  margin-bottom: 16px;
  gap: 8px;

  @media screen and (max-width: ${v2_breakpoint.phone}) {
    max-width: 75%;
  }
`

const MessageText = styled(View)<{
  $sender: 'user' | 'assistant'
}>`
  position: relative;
  padding: 10px 18px;
  border-radius: 20px;
  align-self: ${p => (p.$sender === 'user' ? 'flex-end' : 'flex-start')};
  width: fit-content;
  white-space: pre-line;
`

const AnimationView = styled(motion.div)<{
  $sender: 'user' | 'assistant'
}>`
  min-width: 74px;
  min-height: 42px;
  transform-origin: top left;
  position: absolute;
  top: 0;
  left: 0;
  border-radius: 20px;
  width: 100%;
  height: 100%;

  background-color: ${p =>
    p.$sender === 'user'
      ? resolveTokenOrColor('button/background', p.theme)
      : resolveTokenOrColor('surface/strong', p.theme)};
`

const ChatContainer = styled(motion.div)`
  margin: 48px;
  width: 100%;

  display: flex;
  flex-direction: column;
  flex-grow: 1;

  @media screen and (max-width: ${v2_breakpoint.desktop_small}) {
    padding: 12px;
    margin: 0;
  }
`

const DebugWrapper = styled(View)`
  position: absolute;
  top: 0;
  left: 0;
  padding: 8px;
`
type MessageType = ScenarioMessage & { timestamp: string }

const LoadingChatMessage: React.FC<{ character: ScenarioCharacter }> = ({ character }) => {
  return (
    <MessageContainer $sender='assistant'>
      <View padding='none 6'>
        <Text size='micro' bold color='foreground/primary'>
          {character.name}
        </Text>
      </View>
      <MessageLoaderDots />
    </MessageContainer>
  )
}
const ChatMessage: React.FC<{
  message: MessageType
  character: ScenarioCharacter
  onAnimationStart: () => void
}> = ({ message, character, onAnimationStart }) => {
  const { t } = useTranslation()
  const dateFormatter = useDateTimeFormatter({ hour: 'numeric', minute: 'numeric' })
  const MSG_ANIMATION_DELAY = 0.2

  const whenSent = dateFormatter.format(new Date(message.timestamp))

  return (
    <MessageContainer $sender={message.sender}>
      {message.sender === 'assistant' && (
        <View padding='none 6'>
          <Text size='micro' bold color='foreground/primary'>
            {character.name}
          </Text>
        </View>
      )}
      <MessageText $sender={message.sender}>
        <AnimationView
          key={`message_animation_${message.id}`}
          $sender={message.sender}
          variants={{
            hidden: message.sender === 'assistant' ? { scaleX: 0.3, scaleY: 0.5 } : { opacity: 0 },
            visible:
              message.sender === 'assistant'
                ? { scaleX: 1.0, scaleY: 1.0, minWidth: 'unset', minHeight: 'unset' }
                : { opacity: 1, minWidth: 'unset', minHeight: 'unset' },
          }}
          transition={{
            duration: MSG_ANIMATION_DELAY,
            ease: [0.22, 0.61, 0.36, 1],
          }}
          onAnimationStart={onAnimationStart}
        />
        <motion.div
          key={`message_text_animation_${message.id}`}
          initial={{ opacity: 0 }}
          animate={{ opacity: 1 }}
          transition={{
            delay: message.sender === 'assistant' ? MSG_ANIMATION_DELAY : 0,
            duration: 0.4,
          }}
          style={{ zIndex: 2 }}
        >
          <Text size='large' color={message.sender === 'user' ? 'button/foreground' : undefined}>
            {message.content}
          </Text>
        </motion.div>
      </MessageText>
      <View
        justifyContent={message.sender === 'assistant' ? 'flex-start' : 'flex-end'}
        gap='4'
        padding='none 6'
      >
        <Icon iconId='checkmark--outline' size='size-12' color='foreground/muted' />
        <Text size='micro' capitalize='first' color='foreground/muted'>{`${t(
          'dictionary.sent'
        )} ${whenSent}`}</Text>
      </View>
    </MessageContainer>
  )
}

const createMessage = (message: ScenarioMessage): MessageType => ({
  ...message,
  timestamp: new Date().toISOString(),
})

const InputWrapper = styled(View)`
  position: sticky;
  display: flex;
  bottom: 16px;
  width: 100%;
  z-index: 10;

  @media screen and (max-width: 1110px) {
    bottom: 65px;
  }
`

const ScenarioChatWrapper = styled(View)`
  background-color: ${p => getScenarioBackgroundColor(resolveTokenOrColor('surface/default', p.theme))};
  scrollbar-gutter: stable;
  overflow-y: scroll;

  > * {
    max-width: 760px;
  }

  @media screen and (max-width: 1110px) {
    padding-bottom: 50px;
  }
`

const FullWidthView = styled(View)`
  width: 100%;
`

export const ScenarioChat: React.FC<{
  character: ScenarioCharacter
  courseId: CreateContentId
  file: ScenarioFile
  previewMode?: boolean
  onEnd?: () => void
}> = ({ character, courseId, file, previewMode = false, onEnd: _onEnd }) => {
  const isDebugMode = useIsDebugMode()
  const bottomRef = useRef<HTMLDivElement | null>(null)
  const tracking = useTracking({ courseId, fileId: file.id })
  const [messages, setMessages] = useState<Array<MessageType>>([])
  const [chatState, setChatState] = useState<ScenarioChatState>({ type: 'init' })

  const nextMessageMutation = useTypedMutation(
    previewMode ? XRealtimeSelfPacedScenarioNextMessagePreview : XRealtimeSelfPacedScenarioNextMessage,
    {}
  )

  const terminationReq = useTypedMutation(XRealtimeSelfPacedScenarioTerminationCheck)
  const complianceReq = useTypedMutation(XRealtimeSelfPacedScenarioComplianceCheck)

  const onAskForNextMessage = async (allMsgs: Array<ScenarioMessage>): Promise<void> => {
    setChatState({ type: 'waiting-for-assistant' })

    await Promise.all([
      terminationReq.mutateAsync({
        fileId: file.id,
        courseId,
        messages: allMsgs,
      }),
      complianceReq.mutateAsync({
        fileId: file.id,
        courseId,
        messages: allMsgs,
      }),
      nextMessageMutation.mutateAsync({
        courseId,
        fileId: file.id,
        messages: allMsgs,
      }),
    ])
      .then(([termination, compliance, nextMessage]) => {
        if (compliance.compliant !== true) {
          setChatState({ type: 'completed', reason: 'compliance-fail' })
        } else {
          setMessages(current => [...current, createMessage(nextMessage.message)])
          setChatState({ type: 'waiting-for-user-message' })

          if (termination.terminate === true) {
            setChatState({
              type: 'completed',
              reason: isEmptyArray(termination.goalsReached)
                ? 'self-termination'
                : 'goal-reached-termination',
            })
          }
        }
      })
      .catch(_ => setChatState({ type: 'completed', reason: 'error' }))
  }

  const onAskForFirstMessage = async (): Promise<void> => {
    setChatState({ type: 'waiting-for-assistant' })
    const firstMessage = await nextMessageMutation.mutateAsync({
      courseId,
      fileId: file.id,
      messages: [],
    })

    setMessages([createMessage(firstMessage.message)])
    setChatState({ type: 'waiting-for-user-message' })
  }

  const onAddMessage = (msg: string): void => {
    const allMsgs = messages.concat(
      createMessage({
        id: uuid(),
        sender: 'user',
        content: msg,
      })
    )
    setMessages(allMsgs)
    void onAskForNextMessage(allMsgs)
  }

  const revertLastMessage = (): void => {
    const lastMessage = messages.at(-1)
    if (isNotDefined(lastMessage)) {
      throw new Error('Can not revert, no messages left')
    }
    setMessages(current => current.slice(0, current.length - 1))
    setChatState({ type: 'waiting-for-user-message' })
  }

  return (
    <ScenarioChatWrapper
      grow
      direction='column'
      position='relative'
      alignItems='center'
      paddingTop={chatState.type === 'init' ? 'none' : '80'}
      justifyContent={chatState.type === 'init' ? 'center' : 'flex-start'}
    >
      <ScenarioHeader
        file={file}
        chatState={chatState}
        onStart={() => {
          void onAskForFirstMessage()
          tracking.learner.startScenario()
        }}
      />

      {chatState.type !== 'init' && (
        <>
          <ChatContainer
            key='chat'
            initial='hidden'
            animate='visible'
            variants={{
              hidden: {
                opacity: 0,
              },
              visible: { opacity: 1 },
            }}
            transition={{ duration: 0.4, ease: 'easeInOut' }}
          >
            <View direction='column' grow>
              {messages.map(message => (
                <ChatMessage
                  key={message.id}
                  message={message}
                  character={character}
                  onAnimationStart={() => {
                    bottomRef.current?.scrollIntoView({ behavior: 'smooth' })
                  }}
                />
              ))}
              {chatState.type === 'waiting-for-assistant' && <LoadingChatMessage character={character} />}
            </View>
          </ChatContainer>

          <AnimatePresence mode='popLayout'>
            {chatState.type === 'completed' && (
              <FullWidthView
                animated
                layout
                key='completion_card'
                initial='before_visible'
                animate='visible'
                exit='after_visible'
                variants={{
                  before_visible: {
                    opacity: 0,
                    y: 50,
                  },
                  visible: {
                    opacity: 1,
                    y: 0,
                    transition: {
                      ease: 'easeOut',
                      duration: 0.2,
                      delay: 0.1,
                    },
                  },
                  after_visible: {
                    opacity: 0,
                    y: -50,
                    transition: { ease: 'easeIn', duration: 0.3, opacity: { delay: 0.1, duration: 0.2 } },
                  },
                }}
                transition={{ duration: 0.4 }}
                onAnimationStart={def => {
                  if (def === 'visible') {
                    bottomRef.current?.scrollIntoView({ behavior: 'smooth' })
                  }
                }}
                onAnimationComplete={def => {
                  if (def === 'after_visible') {
                    bottomRef.current?.scrollIntoView({ behavior: 'smooth' })
                  }
                }}
              >
                {chatState.reason === 'compliance-fail' ? (
                  <ComplianceCard
                    onUndo={() => {
                      revertLastMessage()
                      complianceReq.reset()
                      tracking.learner.undoLastMessage()
                    }}
                    onRestart={() => {
                      setMessages([])
                      setChatState({ type: 'init' })
                      complianceReq.reset()
                      void onAskForFirstMessage()
                      tracking.learner.restartScenario()
                    }}
                    onMount={() => {
                      tracking.learner.complianceFailure()
                      tracking.learner.sentMessages({ messages: messages.length })
                    }}
                  />
                ) : chatState.reason === 'error' ? (
                  <ErrorCard
                    onMount={() => {
                      tracking.learner.sentMessages({ messages: messages.length })
                    }}
                  />
                ) : (
                  <TerminationCard
                    fileId={file.id}
                    courseId={courseId}
                    selfTerminated={chatState.reason === 'self-termination'}
                    messageHistory={messages}
                    onTryAgain={() => {
                      setMessages([])
                      terminationReq.reset()
                      setChatState({ type: 'waiting-for-assistant' })
                      void onAskForFirstMessage()
                      tracking.learner.tryAgain()
                    }}
                    onFullyLoaded={() => {
                      bottomRef.current?.scrollIntoView({ behavior: 'smooth' })
                    }}
                    onMount={() => {
                      if (chatState.reason === 'goal-reached-termination') {
                        tracking.learner.completedSuccess()
                      } else {
                        tracking.learner.completedFailure()
                      }
                      tracking.learner.sentMessages({ messages: messages.length })
                    }}
                  />
                )}
              </FullWidthView>
            )}

            {chatState.type !== 'completed' && (
              <InputWrapper
                key='input'
                animated
                initial='before_visible'
                animate='visible'
                exit='after_visible'
                variants={{
                  before_visible: {
                    opacity: 0,
                    y: 20,
                  },
                  visible: {
                    opacity: 1,
                    y: 0,
                    transition: {
                      ease: 'easeOut',
                      duration: 0.2,
                      delay: 0.1,
                    },
                  },
                  after_visible: {
                    opacity: 0,
                    y: -20,
                    transition: {
                      ease: 'easeIn',
                      delay: 0.1,
                      duration: 0.2,
                    },
                  },
                }}
              >
                <Input
                  onSubmit={onAddMessage}
                  onConversationEnd={() => {
                    setChatState({
                      type: 'completed',
                      reason: messages.length < 4 ? 'self-termination' : 'goal-reached-termination',
                    })
                    tracking.learner.endConversation()
                  }}
                  assistantName={character.name}
                  chatState={chatState}
                />
              </InputWrapper>
            )}
          </AnimatePresence>

          <div ref={bottomRef} style={{ width: 5, height: 5 }} />
        </>
      )}

      {isDebugMode && (
        <DebugWrapper>
          <DebugMenu
            messageHistory={messages}
            onImport={msgs => {
              setMessages(msgs.map(createMessage))
              const lastMessage = msgs.at(-1)
              if (isDefined(lastMessage)) {
                if (lastMessage.sender === 'assistant') {
                  setChatState({ type: 'waiting-for-user-message' })
                } else {
                  void onAskForNextMessage(msgs)
                }
              }
            }}
          />
        </DebugWrapper>
      )}
    </ScenarioChatWrapper>
  )
}

const CloseTestModalButton = styled(CloseModalButton)`
  z-index: 2;
  position: absolute;
  top: 0;
  right: 0;
  padding: 16px;
`
export const ScenarioChatModal: React.FC<{
  open: boolean
  file: ScenarioFile
  courseId: CreateContentId
  onClose: () => void
  onEnd: () => void
}> = ({ open, file, courseId, onClose, onEnd }) => {
  const { character } = file.data.input
  const { userContext, assistantContext } = file.data.generated ?? {}
  return (
    <Modal onClose={onClose} size='full-screen' open={open} disableScrollbarGutter>
      <View position='relative' direction='column' grow>
        {isDefined(userContext) && isDefined(assistantContext) && isDefined(character) ? (
          <ScenarioChat character={character} courseId={courseId} file={file} onEnd={onEnd} previewMode />
        ) : (
          <GenerationBackground />
        )}
      </View>
      <CloseTestModalButton ariaLabel='close' onClick={onClose} />
    </Modal>
  )
}
