import { useCallback, useState } from 'react'
import { IdentitiesSelector, Identity } from 'sierra-client/components/common/identities-selector'
import { useTeamspacePotentialIdentitiesFetcher } from 'sierra-client/components/common/identities-selector/identity-fetchers'
import { FetchIdentities } from 'sierra-client/components/common/identities-selector/types'
import { useDeepEqualityMemo } from 'sierra-client/hooks/use-deep-equality-memo'
import { useTranslation } from 'sierra-client/hooks/use-translation'
import { IdentityWithMetadata } from 'sierra-domain/api/manage'
import { Teamspace } from 'sierra-domain/api/teamspace'
import { MenuItem } from 'sierra-ui/components'
import { Button, View } from 'sierra-ui/primitives'
import { SingleSelectDropdown } from 'sierra-ui/primitives/menu-dropdown'
import styled from 'styled-components'

export type CollaboratorRole = 'viewer' | 'commenter' | 'editor'
export type CollaboratorIdentity = IdentityWithMetadata & { role: CollaboratorRole }

const CollaboratorIdentitiesSelectorWrapper = styled(View).attrs({
  gap: '8',
  alignItems: 'center',
  grow: true,
})``

const CollaboratorIdentitiesSelector: React.FC<{
  selectedIdentities: IdentityWithMetadata[]
  setSelectedIdentities: (
    update: IdentityWithMetadata[] | ((identities: IdentityWithMetadata[]) => IdentityWithMetadata[])
  ) => void
  fetchIdentities: FetchIdentities
  trailingVisual?: React.ReactNode
}> = ({ selectedIdentities, setSelectedIdentities, fetchIdentities, trailingVisual }) => {
  const onSelect = useCallback(
    (identity: Identity) => {
      setSelectedIdentities(selectedIdentities => [...selectedIdentities, identity])
    },
    [setSelectedIdentities]
  )

  const onUnselect = useCallback(
    (identity: Identity) => {
      setSelectedIdentities(selectedIdentities =>
        selectedIdentities.filter(i => i.identity.id !== identity.id)
      )
    },
    [setSelectedIdentities]
  )

  const selectedIdentitiesWithId = useDeepEqualityMemo(
    selectedIdentities.map(identity => ({ ...identity, id: identity.identity.id }))
  )

  return (
    <IdentitiesSelector
      fetchIdentities={fetchIdentities}
      selectedIdentities={selectedIdentitiesWithId}
      onSelect={onSelect}
      onUnselect={onUnselect}
      placeholder='manage.programs.enrollment-rules.add-users-or-groups'
      trailingVisual={trailingVisual}
    />
  )
}

const mapIdentitiesToCollaborator = (
  identities: IdentityWithMetadata[],
  role: CollaboratorRole
): CollaboratorIdentity[] => {
  return identities.map(identity => ({
    ...identity,
    role,
  }))
}

export const CollaboratorRoleDropdown: React.FC<{
  selectedRole: CollaboratorRole
  onSelect: (value: CollaboratorRole) => void
  exclude?: CollaboratorRole[]
}> = ({ selectedRole, onSelect, exclude }) => {
  const { t } = useTranslation()

  const rolesToMenuItems: { [TRole in CollaboratorRole]: MenuItem<TRole> } = {
    editor: {
      id: 'editor',
      type: 'label',
      label: t('author.role-editor'),
    },
    commenter: {
      id: 'commenter',
      type: 'label',
      label: t('author.role-commenter'),
    },
    viewer: {
      id: 'viewer',
      type: 'label',
      label: t('author.role-viewer'),
    },
  }
  const menuItems = Object.values(rolesToMenuItems).filter(it => !(exclude?.includes(it.id) ?? false))
  const selectedItem = rolesToMenuItems[selectedRole]

  return (
    <SingleSelectDropdown
      variant='ghost'
      onSelect={item => {
        onSelect(item.id)
      }}
      selectedItem={selectedItem}
      menuItems={menuItems}
    />
  )
}

const CollaboratorRoleDropdownWrapper = styled.div`
  flex-shrink: 0;
`

export const AddCollaboratorsForm: React.FC<{
  onSubmit?: (collaborators: CollaboratorIdentity[]) => void | Promise<void>
  onChange?: (collaborators: CollaboratorIdentity[]) => void | Promise<void>
  teamspace?: Teamspace
  defaultIdentities: IdentityWithMetadata[]
}> = ({ onChange, onSubmit, teamspace, defaultIdentities }) => {
  const { t } = useTranslation()
  const [role, setRole] = useState<CollaboratorRole>(teamspace?.defaultRole ?? 'viewer')
  const [selectedIdentities, setSelectedIdentities] = useState<IdentityWithMetadata[]>(defaultIdentities)
  const fetchIdentities = useTeamspacePotentialIdentitiesFetcher(teamspace?.id)

  return (
    <View direction='row' alignItems='flex-start' gap='8'>
      <CollaboratorIdentitiesSelectorWrapper>
        <CollaboratorIdentitiesSelector
          fetchIdentities={fetchIdentities}
          selectedIdentities={selectedIdentities}
          setSelectedIdentities={state => {
            let nextState
            if (typeof state === 'function') {
              nextState = state(selectedIdentities)
            } else {
              nextState = state
            }

            void onChange?.(mapIdentitiesToCollaborator(nextState, role))
            setSelectedIdentities(nextState)
          }}
          trailingVisual={
            <CollaboratorRoleDropdownWrapper>
              <CollaboratorRoleDropdown
                selectedRole={role}
                onSelect={value => {
                  setRole(value)
                  void onChange?.(mapIdentitiesToCollaborator(selectedIdentities, value))
                }}
              />
            </CollaboratorRoleDropdownWrapper>
          }
        />

        {onSubmit && (
          <Button
            variant='secondary'
            disabled={selectedIdentities.length === 0}
            onClick={() => {
              void onSubmit(mapIdentitiesToCollaborator(selectedIdentities, role))
              setSelectedIdentities([])
            }}
          >
            {t('dictionary.invite')}
          </Button>
        )}
      </CollaboratorIdentitiesSelectorWrapper>
    </View>
  )
}
