import { TableValue } from 'sierra-domain/api/insights'
import { Value } from 'sierra-domain/filter/datatype/value'
import { assertNever } from 'sierra-domain/utils'

export const convertTableValueToFilterValue = (tableValue: TableValue): Value => {
  switch (tableValue.type) {
    case 'value.path':
      if (tableValue.path !== undefined) {
        return {
          type: 'value.path-id',
          value: tableValue.path.id,
        }
      }
      break
    case 'value.course':
      if (tableValue.course !== undefined) {
        return {
          type: 'value.course-id',
          value: tableValue.course.id,
        }
      }
      break
    case 'value.program':
      if (tableValue.program !== undefined) {
        return {
          type: 'value.program-id',
          value: tableValue.program.id,
        }
      }
      break
    case 'value.group':
      if (tableValue.group !== undefined) {
        return {
          type: 'value.group-id',
          value: tableValue.group.id,
        }
      }
      break
    case 'value.user':
      if (tableValue.user !== undefined) {
        return {
          type: 'value.user-id',
          value: tableValue.user.id,
        }
      }
      break
    case 'value.boolean':
      return {
        type: 'value.boolean',
        value: tableValue.value === true,
      }
    case 'value.string':
      if (tableValue.value !== undefined) {
        return {
          type: 'value.string',
          value: tableValue.value,
        }
      }
      break
    case 'value.enum':
      if (tableValue.value !== undefined) {
        return {
          type: 'value.string',
          value: tableValue.value,
        }
      } else {
        return { type: 'value.none', value: null }
      }
    case 'value.uuid':
      if (tableValue.value !== undefined) {
        return {
          type: 'value.uuid',
          value: tableValue.value,
        }
      }
      break
    case 'value.date':
      if (tableValue.value !== undefined) {
        return {
          type: 'value.date',
          value: tableValue.value,
        }
      }
      break
    case 'value.timestamp':
      if (tableValue.value !== undefined) {
        return {
          type: 'value.timestamp',
          value: tableValue.value,
        }
      }
      break
    case 'value.double':
      return {
        type: 'value.integer',
        value: Number(tableValue.value),
      }
    case 'value.long':
      return {
        type: 'value.integer',
        value: Number(tableValue.value),
      }
    case 'value.card':
      if (tableValue.card !== undefined) {
        return {
          type: 'value.file-id',
          courseId: tableValue.card.courseId,
          fileId: tableValue.card.id,
        }
      }
      break
    case 'value.exercise':
      if (tableValue.exercise !== undefined) {
        return {
          type: 'value.exercise-id',
          courseId: tableValue.exercise.courseId,
          fileId: tableValue.exercise.fileId,
          exerciseId: tableValue.exercise.id,
        }
      }
      break
    case 'value.skill':
      if (tableValue.skill !== undefined) {
        return {
          type: 'value.skill-id',
          value: tableValue.skill.id,
        }
      }
      break
    case 'value.skill-level':
      if (tableValue.skillLevel !== undefined) {
        return {
          type: 'value.uuid',
          value: tableValue.skillLevel.id,
        }
      }
      break
    case 'value.duration':
    case 'value.year':
    case 'value.quarter':
    case 'value.month':
    case 'value.week':
    case 'value.progress':
    case 'value.ratio':
    case 'value.poll-option':
    case 'value.none':
    case 'value.certificate':
    case 'value.course-tag':
    case 'value.course-version':
      throw new Error(
        `The value type is not supported to create filters from, if it should be add it!: ${JSON.stringify(
          tableValue
        )}`
      )
    default:
      assertNever(tableValue)
  }

  throw new Error(`Could not convert table value to filter value: ${JSON.stringify(tableValue)}`)
}
