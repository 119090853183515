import { BasicIssueModal } from 'sierra-client/components/liveV2/live-session-issue-modal'
import { Button, View } from 'sierra-ui/primitives'

export const RecordingIssueModal: React.FC<{ restartRecording: () => void; clearIssue: () => void }> = ({
  restartRecording,
  clearIssue,
}) => {
  return (
    <BasicIssueModal
      open
      iconId='warning'
      title='Recording stopped unexpectedly'
      longDescription='Something happened on our end, but don’t worry, your recording up until now is safe and sound! Please click the button below to start recording again.'
      cta={
        <View direction='row' gap='xxsmall'>
          <Button onClick={restartRecording}>Keep recording</Button>
          <Button variant='secondary' onClick={clearIssue}>
            Close
          </Button>
        </View>
      }
    />
  )
}
