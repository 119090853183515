export const Google = (): JSX.Element => (
  <svg xmlns='http://www.w3.org/2000/svg' width='24' height='24' fill='none' viewBox='0 0 16 18'>
    <path
      fill='#0558F9'
      d='M16 9.187c0-.555-.045-1.113-.14-1.659h-7.7v3.143h4.41a3.779 3.779 0 01-1.632 2.48v2.04h2.63C15.113 13.769 16 11.67 16 9.187z'
    ></path>
    <path
      fill='#22AC00'
      d='M8.16 17.161c2.202 0 4.058-.722 5.41-1.97l-2.63-2.04c-.731.498-1.676.78-2.777.78-2.129 0-3.934-1.436-4.582-3.367H.867v2.102A8.163 8.163 0 008.16 17.16z'
    ></path>
    <path
      fill='#FFCE0B'
      d='M3.578 10.564a4.889 4.889 0 010-3.125V5.337H.867a8.169 8.169 0 000 7.33l2.71-2.103z'
    ></path>
    <path
      fill='#D82020'
      d='M8.16 4.067a4.435 4.435 0 013.131 1.224l2.33-2.33A7.844 7.844 0 008.162.837a8.16 8.16 0 00-7.294 4.5l2.71 2.101c.646-1.934 2.454-3.37 4.583-3.37z'
    ></path>
  </svg>
)
