import * as Collapsible from '@radix-ui/react-collapsible'
import { useMemo } from 'react'
import { TeamspaceContentToMove } from 'sierra-client/features/teamspace/components/move-teamspace-content'
import { TeamspaceIcon } from 'sierra-client/features/teamspace/components/teamspace-icon'
import { useCourseTranslationItems } from 'sierra-client/hooks/use-language-items'
import { useResolveAsset } from 'sierra-client/hooks/use-resolve-asset'
import { useTranslation } from 'sierra-client/hooks/use-translation'
import { useTeamspaceNameLight } from 'sierra-client/views/flexible-content/editor/topbar/teamspace-name'
import { getContentClassificationData } from 'sierra-client/views/manage/content/utils/content-utils'
import { Thumbnail } from 'sierra-client/views/workspace/components'
import { CourseId } from 'sierra-domain/api/nano-id'
import { MovableContent } from 'sierra-domain/api/teamspace'
import { isDefined } from 'sierra-domain/utils'
import { Icon } from 'sierra-ui/components'
import { Checkbox, IconButton, Text, View } from 'sierra-ui/primitives'
import { token } from 'sierra-ui/theming'
import { v2_breakpoint } from 'sierra-ui/theming/breakpoints'
import { useGenerateDomId } from 'sierra-ui/utils/use-generate-dom-id'
import styled from 'styled-components'

const RowThumbnail = styled(Thumbnail)`
  width: 56px;
  height: 40px;
  min-width: 56px;
  min-height: 40px;
  border-radius: 8px;

  @media (min-width: ${v2_breakpoint.desktop_small}) {
    width: 70px;
    height: 50px;
    min-width: 70px;
    min-height: 50px;
    border-radius: 12px;
  }
`

const RowContainer = styled.div`
  display: flex;
  flex-grow: 1;
  justify-content: space-between;
  align-items: center;
  margin: 0px 24px 16px 40px;
`

const OneLineText = styled(Text)`
  display: inline;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  user-select: none;
  align-items: center;
  flex: 1;
`

const ContentCardWrapper = styled.div`
  width: 100%;
  padding: 8px 16px 8px 8px;
  border-radius: 12px;
  transition: all 0.1s cubic-bezier(0.25, 0.1, 0.25, 1);

  &:hover {
    background-color: ${token('surface/soft')};
  }
`

const ContentWrapper = styled(View).attrs({ justifyContent: 'space-between', grow: true })``

const OverFlowHiddenView = styled(View)`
  width: 100%;
  overflow: hidden;
`

const nonEditorContentTypes = ['link', 'linkedin', 'scorm', 'scorm:course-group', 'native:event-group']

const CourseTitle = styled.p`
  display: -webkit-box;
  -webkit-box-orient: vertical;
  -webkit-line-clamp: 2;
  overflow: hidden;
  color: ${token('foreground/primary')};
`

const CollapsibleContainer = styled.div`
  align-self: flex-end;
`

export const ContentCard: React.FC<{
  content: {
    id: MovableContent['id']
    role: MovableContent['role']
    teamspace?: MovableContent['teamspace']
    currentTeamspaceId?: MovableContent['currentTeamspaceId']
    settings: Pick<MovableContent['settings'], 'title' | 'image' | 'templateSettings'>
    kind: MovableContent['kind']
    courseEdition?: {
      language?: string
    }
  }
  isSelected: boolean | 'indeterminate'
  disabled?: boolean
  hideThumbnail?: boolean
  onCheckedChange: (content: TeamspaceContentToMove, checked: boolean) => void
  collapsable?: {
    collapsed: boolean
  }
}> = ({ content, isSelected, onCheckedChange, collapsable, disabled, hideThumbnail }) => {
  const { t } = useTranslation()
  const tLang = useCourseTranslationItems()
  const initialTeamspace = useTeamspaceNameLight(content.role, content.teamspace)
  const hideInitialTeamspaceNameForNonEditorContent =
    initialTeamspace.type === 'found' &&
    (initialTeamspace.id === 'my-content' || initialTeamspace.id === 'shared-with-me')

  const contentClassification = getContentClassificationData({
    contentType: 'course',
    courseKind: content.kind,
    isCourseEdition: isDefined(content.courseEdition),
    isTemplate: isDefined(content.settings.templateSettings),
  })

  const rowLabelDomId = useGenerateDomId()

  const assetContext = useMemo(
    () => ({ type: 'course' as const, courseId: CourseId.parse(content.id) }),
    [content.id]
  )
  const thumbnailSrc = useResolveAsset({
    assetContext,
    image: content.settings.image,
    size: 'default-sm',
  })

  return (
    <ContentWrapper>
      <View grow direction='column'>
        <OverFlowHiddenView gap='6'>
          {Boolean(hideThumbnail) ? null : <RowThumbnail image={thumbnailSrc} />}
          <OverFlowHiddenView direction='column' gap='2'>
            <View gap='2'>
              <CourseTitle id={rowLabelDomId}>{content.settings.title}</CourseTitle>

              {isDefined(collapsable) && (
                <CollapsibleContainer>
                  <Collapsible.Trigger asChild>
                    <IconButton
                      iconId={collapsable.collapsed ? 'chevron--up--small' : 'chevron--down--small'}
                      variant='transparent'
                      size='small'
                      aria-label={t('course-editions.view-actions')}
                    />
                  </Collapsible.Trigger>
                </CollapsibleContainer>
              )}
            </View>
            <OverFlowHiddenView gap='2'>
              <Icon iconId={contentClassification.iconId} size='size-12' color='foreground/muted' />

              <Text capitalize='first' color='foreground/muted' bold size='small'>
                {t(contentClassification.translationKey)}
              </Text>

              {isDefined(content.courseEdition) && isDefined(content.courseEdition.language) && (
                <>
                  <Icon iconId='radio-button--dot' color='foreground/muted' />
                  <View>
                    <OneLineText size={'small'} color='foreground/muted'>
                      {tLang.find(lang => lang.id === content.courseEdition?.language)?.label}
                    </OneLineText>
                  </View>
                </>
              )}
              {initialTeamspace.type === 'found' && (
                <>
                  {hideInitialTeamspaceNameForNonEditorContent ? null : (
                    <>
                      <Icon iconId='radio-button--dot' color='foreground/muted' />
                      <View>
                        <TeamspaceIcon
                          small
                          displayName={initialTeamspace.name}
                          themeName={initialTeamspace.iconTheme}
                        />
                        <OneLineText size={'small'} color='foreground/muted'>
                          {initialTeamspace.name}
                        </OneLineText>
                      </View>
                    </>
                  )}
                </>
              )}
            </OverFlowHiddenView>
          </OverFlowHiddenView>
        </OverFlowHiddenView>
      </View>

      <View>
        <Checkbox
          aria-labelledby={rowLabelDomId}
          checked={isSelected}
          disabled={disabled}
          onCheckedChange={checked => {
            if (checked !== 'indeterminate') {
              onCheckedChange(
                {
                  id: content.id,
                  title: content.settings.title,
                  currentTeamspaceId: content.currentTeamspaceId,
                  image: content.settings.image,
                  type: nonEditorContentTypes.includes(content.kind)
                    ? 'non-editor-content'
                    : 'editable-content',
                },
                checked
              )
            }
          }}
        />
      </View>
    </ContentWrapper>
  )
}

export const ContentRow: React.FC<{
  content: MovableContent
  isSelected: boolean
  onCheckedChange: (content: TeamspaceContentToMove, checked: boolean) => void
}> = ({ content, onCheckedChange, isSelected }) => {
  return (
    <RowContainer>
      <ContentCardWrapper>
        <ContentCard content={content} onCheckedChange={onCheckedChange} isSelected={isSelected} />
      </ContentCardWrapper>
    </RowContainer>
  )
}
