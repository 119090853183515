import { View } from 'sierra-ui/primitives'
import { token } from 'sierra-ui/theming'
import styled from 'styled-components'

export const ContentContainer = styled(View)`
  background: ${token('surface/soft')};
  width: 100%;
  border-radius: 16px;
  padding: 16px;
  display: grid;
  grid-template-columns: 1fr;
  gap: 8px;
`
