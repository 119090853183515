import { assertElementType } from 'sierra-client/views/v3-author/queries'
import { QuestionCardBodyWrapper } from 'sierra-client/views/v3-author/question-card/question-card-body-wrapper'
import { SlateFC } from 'sierra-client/views/v3-author/slate'

export const QuestionCardMultipleChoiceBody: SlateFC = props => {
  const { element } = props

  assertElementType(
    ['question-card-select-all-that-apply-body', 'question-card-pick-the-best-option-body'],
    element
  )

  return <QuestionCardBodyWrapper {...props}>{props.children}</QuestionCardBodyWrapper>
}
