import { useAtomValue } from 'jotai'
import _ from 'lodash'
import { DateTime } from 'luxon'
import { useMemo, useState } from 'react'
import { useLiveSessionContext } from 'sierra-client/components/liveV2/contexts/live-session-data'
import { useSelectCurrentCardBackgroundColor } from 'sierra-client/components/liveV2/hooks/use-select-current-card'
import { TransparentNowButton } from 'sierra-client/features/sana-now/header/buttons'
import {
  PresenceChangeEvent,
  PresenceStateAtom,
  PresenceStateEventsAtom,
} from 'sierra-client/features/sana-now/presence/state'
import { DebugUserSyncLocalPresence } from 'sierra-client/features/sana-now/presence/sync-presence'
import { isUserLoaded, useUsers } from 'sierra-client/state/users/hooks'
import { dynamic } from 'sierra-client/utils/dynamic'
import { UserIdAvatars } from 'sierra-client/views/flexible-content/editor/content-sidebar/user-id-avatars'
import { LiveSessionId } from 'sierra-domain/api/nano-id'
import { resolveTokenOrColor } from 'sierra-ui/color/token-or-color'
import { Panel, Tabs, Tooltip } from 'sierra-ui/components'
import { TabItem } from 'sierra-ui/components/tabs/tabs'
import { Button, Heading, ScrollView, Text, View } from 'sierra-ui/primitives'
import { LightTokenProvider, token } from 'sierra-ui/theming'
import styled from 'styled-components'

const Indent = styled.div<{ amount: number }>`
  margin-left: ${p => p.amount * 16}px;
`

const CurrentStateDebugPanel = ({ liveSessionId }: { liveSessionId: LiveSessionId }): JSX.Element => {
  const presenceData = useAtomValue(PresenceStateAtom)[liveSessionId] ?? []

  const _users = useUsers(presenceData.map(it => it.presence.userId)).filter(isUserLoaded)
  const users = _.keyBy(_users, it => it.uuid)

  return (
    <View padding='16' direction='column'>
      <div>
        <Text>
          <strong>Live session id</strong> {liveSessionId}
        </Text>

        {presenceData.map(it => {
          const user = users[it.presence.userId]
          return (
            <View key={it.presence.sessionId}>
              <Indent amount={1}>
                <View marginBottom='8'>
                  <Text size='micro'>
                    <Tooltip title={it.presence.userId}>
                      <span>
                        <strong>User</strong>{' '}
                        {user === undefined ? it.presence.userId : `${user.firstName} ${user.lastName}`}
                      </span>
                    </Tooltip>
                    <br />
                    <strong>Date</strong> {it.updatedAt.toISOString()}
                  </Text>
                </View>
              </Indent>
            </View>
          )
        })}
      </div>
    </View>
  )
}

const Grid = styled.div`
  display: grid;
  grid-template-columns: 1fr 2fr;
  height: 100%;
  overflow: hidden;
`

const Row = styled.div`
  display: flex;
  flex-direction: column;
  padding: 6px;
  border: 1px solid ${token('border/default')};
  border-radius: 4px;
  cursor: pointer;

  &:hover {
    background: ${token('surface/soft')};
  }
`

const EventRow = ({ event, onClick }: { event: PresenceChangeEvent; onClick: () => void }): JSX.Element => {
  const updatedUsers = event.updates.flatMap(it => (it.type === 'updated' ? [it] : []))
  const removedUsers = event.updates.flatMap(it => (it.type === 'removed' ? [it] : []))

  return (
    <Row onClick={onClick}>
      <View>
        <Text bold size='technical'>
          {event.source}
        </Text>
        <Text size='technical'>{DateTime.fromISO(event.logTime).toRelative({ style: 'short' })}</Text>
      </View>
      <View direction='column'>
        <Text>Updated {updatedUsers.length}</Text>
        <UserIdAvatars animateAvatars={false} userIds={updatedUsers.map(u => u.presence.userId)} />
      </View>
      <View direction='column'>
        <Text>Removed {removedUsers.length}</Text>
        <UserIdAvatars animateAvatars={false} userIds={removedUsers.map(u => u.userId)} />
      </View>
    </Row>
  )
}

const JsonView = dynamic(() => import('react-json-view'))

const useUsersInEvent = (event: PresenceChangeEvent): string[] => {
  const users = useUsers(
    event.updates.map(it => (it.type === 'removed' ? it.userId : it.presence.userId))
  ).filter(isUserLoaded)

  return useMemo(() => users.map(it => `${it.firstName} ${it.lastName}`), [users])
}

const EventView = ({ event }: { event: PresenceChangeEvent }): JSX.Element => {
  const users = useUsersInEvent(event)
  return (
    <View direction='column' padding='xxsmall'>
      <Text>Users: {users.join(', ')}</Text>
      <JsonView src={event} />
    </View>
  )
}

const EventsDebugTab = (): JSX.Element => {
  const events = useAtomValue(PresenceStateEventsAtom)
  const [selectedEvent, setSelectedEvent] = useState<PresenceChangeEvent | undefined>(undefined)

  const reversedEvents = useMemo(() => [...events].reverse(), [events])

  return (
    <View direction='column'>
      <Grid>
        <ScrollView gap='none'>
          {reversedEvents.map(event => (
            <EventRow key={event.id} event={event} onClick={() => setSelectedEvent(event)} />
          ))}
        </ScrollView>
        <ScrollView>{selectedEvent !== undefined && <EventView event={selectedEvent} />}</ScrollView>
      </Grid>
    </View>
  )
}

type DebugTabs = 'current-state' | 'events'

const CountWrapper = styled(View).attrs({
  border: 'none',
  justifyContent: 'center',
  alignItems: 'center',
})<{ dark: boolean }>`
  border-radius: 99px;
  min-width: 14px;
  min-height: 14px;
  padding: 0 4px;

  transition: background-color 300ms;

  background: ${p =>
    p.dark ? resolveTokenOrColor('black', p.theme) : resolveTokenOrColor('grey15', p.theme)};
`

const TabLabel: React.FC<{ count: number; label: string; isSelected: boolean }> = ({
  count,
  label,
  isSelected,
}) => (
  <View direction='row' gap='6' justifyContent='flex-start' alignItems='center'>
    <CountWrapper dark={isSelected}>
      <Text color='white' size='technical' bold>
        {count}
      </Text>
    </CountWrapper>
    <Text>{label}</Text>
  </View>
)

const DebugPanelContent = ({ liveSessionId }: { liveSessionId: LiveSessionId }): JSX.Element => {
  const presenceData = useAtomValue(PresenceStateAtom)[liveSessionId] ?? []
  const [tab, setTab] = useState<DebugTabs>('current-state')
  const [mockUsers, setMockUsers] = useState<{ id: string }[]>([])

  const tabItems: TabItem<DebugTabs>[] = [
    {
      id: 'current-state',
      label: (
        <TabLabel count={presenceData.length} label='Current State' isSelected={tab === 'current-state'} />
      ),
      content: <CurrentStateDebugPanel liveSessionId={liveSessionId} />,
    },
    {
      id: 'events',
      label: <TabLabel count={presenceData.length} label='Events' isSelected={tab === 'events'} />,
      content: <EventsDebugTab />,
    },
  ]

  return (
    <View padding='10' direction='column'>
      <View direction='row'>
        <Heading size='h2'>Presences</Heading>
        {/* eslint-disable-next-line no-console */}
        <Button onClick={() => console.log(presenceData)}>console.log(presences)</Button>
        <Button onClick={() => setMockUsers(users => [...users, { id: _.uniqueId('u_') }])}>add user</Button>
        <Button
          onClick={() =>
            setMockUsers(users => {
              users.pop()
              return [...users]
            })
          }
        >
          remove user
        </Button>
      </View>
      <Tabs value={tab} onChange={(tab: DebugTabs) => setTab(tab)} hideLine items={tabItems} />
      {mockUsers.map(user => (
        <DebugUserSyncLocalPresence key={user.id} liveSessionId={liveSessionId} data={{}} />
      ))}
    </View>
  )
}

export const DebugPresencesButton = (): JSX.Element | null => {
  const liveSession = useLiveSessionContext()
  const presenceData = useAtomValue(PresenceStateAtom)
  const presences = presenceData[liveSession.liveSessionId] ?? []
  const [open, setOpen] = useState(false)
  const userCount = new Set(presences.map(it => it.presence.userId)).size
  const backgroundColor = useSelectCurrentCardBackgroundColor()

  return (
    <>
      <TransparentNowButton
        $backgroundColor={backgroundColor}
        icon='user--add'
        decoratorPosition='left'
        variant='primary'
        onClick={() => {
          setOpen(!open)
        }}
      >
        {`${userCount}`}
      </TransparentNowButton>

      <LightTokenProvider>
        {open && (
          <Panel size='full-screen' open={open} onClose={() => setOpen(false)}>
            <DebugPanelContent liveSessionId={liveSession.liveSessionId} />
          </Panel>
        )}
      </LightTokenProvider>
    </>
  )
}
