import { keepPreviousData } from '@tanstack/react-query'
import React, { useCallback, useState } from 'react'
import { graphql } from 'sierra-client/api/graphql/gql'
import { useGraphQuery } from 'sierra-client/api/hooks/use-graphql-query'
import { useTranslation } from 'sierra-client/hooks/use-translation'
import { Context } from 'sierra-client/lib/filter/components/common'
import {
  addPredValue,
  fromPredicate,
  removePredValue,
  ValueAnchor,
} from 'sierra-client/lib/filter/components/predicate-utils'
import { TagId } from 'sierra-domain/api/nano-id'
import { Predicate } from 'sierra-domain/filter/datatype/pred'
import { setPred } from 'sierra-domain/filter/operations'
import { isDefined } from 'sierra-domain/utils'
import { Autocomplete, DefaultStyledAutocompleteOption, FormElement } from 'sierra-ui/components'
import { ListContainer, SelectedPill } from 'sierra-ui/components/autocomplete/reference-implementation/atoms'
import { IconButton, Text } from 'sierra-ui/primitives'

const tagsQuery = graphql(`
  query TagsAutoComplete($query: String!, $limit: Int!) {
    tags: tags(query: $query, limit: $limit) {
      id
      name
    }
  }
`)

const tagsByIdsQuery = graphql(`
  query TagsByIds($ids: [TagId!]!) {
    tagsFromIds(ids: $ids) {
      id
      name
    }
  }
`)

const emptyArray: AutoCompleteTag[] = []

type AutoCompleteTag = {
  id: string
  name: string
  value: { type: 'value.tag-id'; value: string }
}

const useTagsFromPredicate = (predicate: Predicate): AutoCompleteTag[] | readonly [] => {
  const tagIds = fromPredicate(predicate)
    .map(value =>
      value.type === 'value.tag-id' || value.type === 'value.nanoid12' ? value.value : undefined
    )
    .filter(isDefined)

  const response = useGraphQuery(
    {
      document: tagsByIdsQuery,
      queryOptions: {
        enabled: tagIds.length !== 0,
        staleTime: 60 * 1000,
        placeholderData: keepPreviousData,
      },
    },
    { ids: tagIds }
  )

  if (tagIds.length === 0 || response.data === undefined) {
    return emptyArray
  }

  return response.data.tagsFromIds.map(tag => ({
    id: tag.id,
    name: tag.name,
    value: { type: 'value.tag-id', value: tag.id },
  }))
}

const useLabelsForTagPredicate = (predicate: Predicate): string[] => {
  const tags = useTagsFromPredicate(predicate)
  const labels = tags.map(it => it.name)
  return labels
}

const useQueryTagsByIds = (predicate: Predicate): AutoCompleteTag[] => {
  const tags = useTagsFromPredicate(predicate)

  return tags.map(tag => {
    return {
      id: tag.id,
      name: tag.name,
      value: { type: 'value.tag-id', value: tag.id },
    }
  })
}

type TagAutoCompleteProps = {
  ctx: Context
  predicate: Predicate
}

export const TagAutoComplete: React.FC<TagAutoCompleteProps> = ({ ctx, predicate }) => {
  const { t } = useTranslation()

  const selectedTags = useQueryTagsByIds(predicate)

  const [query, setQuery] = useState('')

  const onSelect = useCallback(
    (tag: AutoCompleteTag) => {
      ctx.update(f => setPred(f, addPredValue(predicate, tag.value)))
    },
    [ctx, predicate]
  )

  const onUnselect = useCallback(
    (tag: AutoCompleteTag) => {
      ctx.update(f => setPred(f, removePredValue(predicate, tag.value)))
    },
    [ctx, predicate]
  )

  const searchTags = useGraphQuery(
    {
      document: tagsQuery,
      queryOptions: { staleTime: 60 * 1000, placeholderData: keepPreviousData },
    },
    { query, limit: 20 }
  )

  const matchingTags: AutoCompleteTag[] =
    searchTags.data?.tags.map(it => ({
      id: TagId.parse(it.id),
      name: it.name,
      value: { type: 'value.tag-id', value: it.id },
    })) ?? emptyArray

  return (
    <FormElement label={t('dictionary.tags')}>
      <Autocomplete
        placeholder={t('menu.search.placeholder')}
        autofocus
        query={query}
        onQueryChange={setQuery}
        selectedItems={selectedTags}
        matchingItems={matchingTags}
        onSelect={onSelect}
        onUnselect={onUnselect}
        renderSelectedItem={(tag, { onUnselect, ...props }) => (
          <SelectedPill key={tag.id} {...props}>
            <Text color='foreground/primary' bold>
              {tag.name}
            </Text>
            <IconButton iconId='close' onClick={onUnselect} size='small' variant='transparent' />
          </SelectedPill>
        )}
        renderMatchingItemList={({ getItemProps }) => (
          <ListContainer>
            {matchingTags.map((tag, index) => (
              <DefaultStyledAutocompleteOption key={tag.id} {...getItemProps(tag, index)}>
                <Text color='foreground/primary'>{tag.name}</Text>
              </DefaultStyledAutocompleteOption>
            ))}
          </ListContainer>
        )}
      />
    </FormElement>
  )
}

export const TagAutoCompleteLabel = React.forwardRef<HTMLDivElement, { predicate: Predicate }>(
  ({ predicate, ...rest }, ref) => {
    const labels = useLabelsForTagPredicate(predicate)
    const label = labels.join(', ')

    return <ValueAnchor {...rest} ref={ref} label={label} />
  }
)
