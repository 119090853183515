import { SlashMenuState } from 'sierra-domain/v3-author/slash-menu'
import { BasePoint, BaseRange, Editor, Range, Transforms } from 'slate'
import { ReactEditor } from 'slate-react'

export const startState = (
  editor: Editor,
  { idsSupportedByEditor }: Pick<SlashMenuState, 'idsSupportedByEditor'>
): SlashMenuState => {
  const { selection } = editor
  if (!selection) return { type: 'idle', idsSupportedByEditor }

  try {
    const end = Range.end(selection)
    const range = Editor.range(editor, end, end)
    const domRange = ReactEditor.toDOMRange(editor, range)
    const rect = domRange.getBoundingClientRect()
    return {
      type: 'active',
      top: rect.top + window.pageYOffset + rect.height,
      left: rect.left + window.pageXOffset,
      searchText: '',
      selectedIndex: 0,
      startPoint: end,
      idsSupportedByEditor: idsSupportedByEditor,
      idsMatchingSearchText: idsSupportedByEditor,
    }
  } catch (e) {
    console.debug('[slash-menu] Error starting slash menu:', e)
    return { type: 'idle', idsSupportedByEditor }
  }
}

export const clearSearchText = (editor: Editor, selection: BaseRange | null, startPoint: BasePoint): void => {
  if (selection !== null) {
    // Delete all the text between the "/" and the current cursor position
    const endPoint = Range.end(selection)
    const distance = endPoint.offset - startPoint.offset

    if (distance > 0) Transforms.delete(editor, { at: startPoint, distance })
  }
}

export const currentSearchText = (editor: Editor): string | undefined => {
  const { selection } = editor

  if (selection === null || !Range.isCollapsed(selection)) {
    return undefined
  } else {
    try {
      // Select the text between the "/" and the current cursor position
      const [start] = Range.edges(selection)
      const wordBefore = Editor.before(editor, start, { unit: 'block' })
      const beforeRange = wordBefore && Editor.range(editor, wordBefore, start)
      const beforeText = beforeRange && Editor.string(editor, beforeRange)
      const beforeMatch = beforeText?.match(/\/\w*$/)
      const searchText = beforeMatch ? beforeMatch[0] : undefined

      if (beforeMatch === undefined || beforeMatch === null) {
        return undefined
      } else {
        if (searchText === undefined) return ''
        else
          return (
            searchText
              // Search text is always lower case
              .toLocaleLowerCase()
              // Remove the leading '/'
              .slice(1, searchText.length)
          )
      }
    } catch (e) {
      console.debug('[slash-menu] Error getting current search text:', e)
      return undefined
    }
  }
}
