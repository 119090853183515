import _ from 'lodash'
import { useState } from 'react'
import { EventGroupPageDetails_EventsWithApprovalRequestsFragment } from 'sierra-client/api/graphql/gql/graphql'
import { convertGQLAvatarToAvatarProps } from 'sierra-client/api/graphql/util/convert-gql-avatar'
import { Divider } from 'sierra-client/components/liveV2/right-sidebar/components'
import { useTranslation } from 'sierra-client/hooks/use-translation'
import { HandleEventGroupEnrollmentRequestsPanel } from 'sierra-client/views/learner/event-group/handle-event-group-enrollment-panel/handle-event-group-enrollment-requests-panel'
import { DiscardAlert } from 'sierra-client/views/manage/components/alerts/discard-alert'
import { asNonNullable, isNonNullable } from 'sierra-domain/utils'
import { Icon, UserDisplay } from 'sierra-ui/components'
import { Spacer, Text, View } from 'sierra-ui/primitives'
import { token } from 'sierra-ui/theming'
import styled from 'styled-components'

const EventGroupRequestsBox = styled.div`
  display: flex;
  flex-direction: column;
  padding: 24px;
  padding-bottom: 10px;
  border: 1px solid ${token('border/default')};
  border-radius: 13px;
`

export const HandleEnrollmentRequests: React.FC<{
  eventsWithApprovalRequests: Array<EventGroupPageDetails_EventsWithApprovalRequestsFragment>
  imageUrl: string
  refetch: () => void
}> = ({ eventsWithApprovalRequests, imageUrl, refetch }) => {
  const { t } = useTranslation()
  const [isDiscardAlertOpen, setIsDiscardAlertOpen] = useState(false)
  const [showRequestsPanel, setShowRequestsPanel] = useState(false)

  const evsWithApprovalRequests = eventsWithApprovalRequests
  const allRequests = evsWithApprovalRequests
    .flatMap(event => event.enrollmentRequestsToHandle)
    .filter(isNonNullable)

  if (allRequests.length <= 0) {
    return <></>
  }

  return (
    <>
      <EventGroupRequestsBox>
        <View gap='16' direction='column'>
          {_.chain(allRequests)
            .groupBy(data => data.requester.id)
            .map(requests => {
              const requester = asNonNullable(requests[0]).requester
              const isApproved = requests.some(r => r.requestStatus === 'APPROVED')
              const isRejected = requests.some(r => r.requestStatus === 'REJECTED')

              const aggregateRequestStatus = isApproved ? 'approved' : isRejected ? 'rejected' : 'pending'

              return {
                requester,
                aggregateRequestStatus,
              }
            })
            .take(3)
            .map(({ requester, aggregateRequestStatus }) => {
              return (
                <View gap='16' key={requester.id}>
                  <UserDisplay
                    primaryText={requester.displayName}
                    secondaryText={requester.email}
                    avatar={convertGQLAvatarToAvatarProps(requester.id, requester.avatar)}
                  />
                  {aggregateRequestStatus === 'approved' && (
                    <Icon iconId='checkbox--checkmark--filled' size='size-16' color='success/background' />
                  )}
                  {aggregateRequestStatus === 'rejected' && (
                    <Icon iconId='checkbox--cross--filled' size='size-16' color='destructive/background' />
                  )}
                </View>
              )
            })
            .value()}
        </View>
        <Spacer size='24' />
        <Divider />
        <Spacer size='8' />
        <View
          grow
          justifyContent='center'
          cursor='pointer'
          padding='6 16'
          onClick={() => {
            setShowRequestsPanel(true)
          }}
        >
          <Text color='foreground/muted' bold size='small'>
            {t('event-groups.manage-requests')}
          </Text>
        </View>
      </EventGroupRequestsBox>
      <HandleEventGroupEnrollmentRequestsPanel
        imageUrl={imageUrl}
        refetch={refetch}
        calendarEvents={evsWithApprovalRequests}
        open={showRequestsPanel}
        onClose={() => setShowRequestsPanel(false)}
      />
      <DiscardAlert
        open={isDiscardAlertOpen}
        onConfirm={() => setShowRequestsPanel(false)}
        onClose={() => setIsDiscardAlertOpen(false)}
      />
    </>
  )
}
