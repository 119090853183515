import React, { useState } from 'react'
import { useNotif } from 'sierra-client/components/common/notifications'
import { useTranslation } from 'sierra-client/hooks/use-translation'
import { Alert } from 'sierra-client/views/manage/components/user-attributes/flows/components/alert'
import {
  FooterWrapper,
  ScrollGradientTop,
} from 'sierra-client/views/manage/components/user-attributes/flows/components/layout'
import { MissingMandatoryAttributesUserDetailAlert } from 'sierra-client/views/manage/components/user-attributes/flows/user-attribute-settings/alerts/missing-mandatory-attributes-user-detail-alert'
import { useInitialiseAttributesDraft } from 'sierra-client/views/manage/components/user-attributes/flows/user-attribute-settings/hooks/use-initialize-attribute-draft'
import { useUserAttributesDraft } from 'sierra-client/views/manage/components/user-attributes/flows/user-attribute-settings/hooks/use-user-attributes-draft'
import { EditCustomAttributeSection } from 'sierra-client/views/manage/components/user-attributes/flows/user-attribute-settings/panels/edit-custom-attribute-section'
import { UserSettingsPanelProps } from 'sierra-client/views/manage/users/components/user-settings-panel/types'
import { Button, ScrollView, Spacer, View } from 'sierra-ui/primitives'
import styled from 'styled-components'

/* We need this to make the flex-child (custom attribute section) scrollable and not scroll on the parent*/
const MinHeight = styled(View).attrs({ direction: 'column', grow: true })`
  height: 0;
`

export const EditAttributesTab: React.FC<UserSettingsPanelProps> = ({
  userId,
  closeDirectly,
  refetch,
  openDiscardAlert,
}) => {
  const { t } = useTranslation()
  const { hasChanged, resetDraft, readOnlyAttributes, sendInvites, validateInvites } =
    useUserAttributesDraft()
  const [isSaving, setIsSaving] = useState(false)
  const [validationFailed, setValidationFailed] = useState(false)
  const { reinitialise: reinitaliseAttributesDraft } = useInitialiseAttributesDraft(userId)
  const notification = useNotif()

  const onSave = React.useCallback(async () => {
    setIsSaving(true)
    const validationSuccess = await validateInvites()

    if (validationSuccess) {
      const success = await sendInvites(userId)

      if (success) {
        closeDirectly()
        resetDraft()
        void reinitaliseAttributesDraft()
        await refetch()
        closeDirectly()

        notification.push({
          type: 'custom',
          level: 'success',
          body: t('manage.users.invite.user-settings-updated'),
        })
      }
    } else {
      setValidationFailed(true)
    }

    setIsSaving(false)
  }, [
    validateInvites,
    sendInvites,
    userId,
    closeDirectly,
    resetDraft,
    refetch,
    reinitaliseAttributesDraft,
    notification,
    t,
  ])

  return (
    <MinHeight>
      {readOnlyAttributes && (
        // might be because user-attributes-editable flag is set to none...
        <>
          <Alert variant='info'>{t('manage.users.settings.user-settings.hr-system-info')}</Alert>
          <Spacer size='16' />
        </>
      )}
      <ScrollGradientTop top='0' height='16' />
      <ScrollView grow paddingTop='8' paddingRight='6' paddingBottom='16'>
        <EditCustomAttributeSection />
      </ScrollView>
      <FooterWrapper paddingRight='16'>
        <Button
          variant='secondary'
          onClick={() => {
            if (hasChanged) {
              openDiscardAlert(true)
            } else {
              closeDirectly()
            }
          }}
        >
          {readOnlyAttributes ? t('dictionary.close') : t('dictionary.cancel')}
        </Button>
        {!readOnlyAttributes && (
          <Button loading={isSaving} onClick={onSave} disabled={!hasChanged || isSaving}>
            {t('dictionary.save')}
          </Button>
        )}
      </FooterWrapper>
      <MissingMandatoryAttributesUserDetailAlert
        open={validationFailed}
        onAbort={() => setValidationFailed(false)}
      />
    </MinHeight>
  )
}
