import React from 'react'
import { QueryStateAtom, stringWithZodSerializer } from 'sierra-client/lib/querystate/use-query-state'
import { EditableContent, NonEditorTeamspaceCourseType } from 'sierra-domain/api/editable-content'
import { z } from 'zod'

export type CreatePageSortFunctionId = 'edited-desc' | 'alphabetically-asc' | 'alphabetically-desc'

export type CreatePageContentFilter = {
  contentType: EditableContent['type'] | NonEditorTeamspaceCourseType | 'folder' | 'template'
  publishState: 'published' | 'draft' | 'pending'
}

export type CreatePageSelectedFilters = {
  selectedContentType: CreatePageContentFilter['contentType'][]
  selectedPublishState: CreatePageContentFilter['publishState'][]
}

export type CreatePageSetSelectedFilters = {
  setSelectedContentType: React.Dispatch<React.SetStateAction<CreatePageContentFilter['contentType'][]>>
  setSelectedPublishState: React.Dispatch<React.SetStateAction<CreatePageContentFilter['publishState'][]>>
}

const CreatePageBucket = z.enum(['everything', 'created-by-me', 'shared-with-me'])
export type CreatePageBucket = z.infer<typeof CreatePageBucket>
export const CreatePageBucketSerializer = stringWithZodSerializer(CreatePageBucket)
export type CreatePageFilterRenderer = {
  selectedTabAtom: QueryStateAtom<CreatePageBucket>
  selectedFilters: CreatePageSelectedFilters
  selectedSortFunction: CreatePageSortFunctionId
  setSelectedFilters: CreatePageSetSelectedFilters
  setSelectedSortFunction: React.Dispatch<React.SetStateAction<CreatePageSortFunctionId>>
}
