import { Atom } from 'jotai'
import { Cell } from 'sierra-client/lib/tabular/datatype/internal/cell'
import { TableData } from 'sierra-client/lib/tabular/datatype/tabledata'
import { RowRef } from 'sierra-client/lib/tabular/types'

const keyRowFlat = 'Row.Flat' as const
const keyRowNested = 'Row.Nested' as const

export type Row<TD extends TableData = TableData> =
  | { type: typeof keyRowFlat; ref: RowRef; cells: Cell<TD>[] }
  | {
      type: typeof keyRowNested
      ref: RowRef
      expanded: Atom<boolean>
      parent: Cell<TD>[]
      children: Row<TD>[]
    }

export function createRow<Data extends TableData = TableData>(payload: Row<Data>): Row<Data> {
  return payload
}
