import {
  XRealtimeAdminAssignmentsUnassignFromCourseGroup,
  XRealtimeAdminCoursesCourseGroupDetail,
  XRealtimeAdminCoursesCourseGroupProgress,
  XRealtimeAdminCoursesDeleteCourseGroup,
  XRealtimeAdminCoursesDetachEdition,
  XRealtimeAdminCoursesListEnrolledGroups,
  XRealtimeAdminCoursesUpdateCourseVisibility,
} from 'sierra-domain/routes'

import { useCallback, useEffect, useMemo, useState } from 'react'
import { usePost } from 'sierra-client/hooks/use-post'
import { useResolveAsset } from 'sierra-client/hooks/use-resolve-asset'
import { getGlobalRouter } from 'sierra-client/router'
import { UseCourseDetailsData } from 'sierra-client/views/manage/courses/use-manage-course-details'
import {
  ContentProgramEnrollment,
  CourseGroupDetailRow,
  CourseGroupUserProgressRow,
} from 'sierra-domain/api/manage'
import { CourseId } from 'sierra-domain/api/nano-id'
import { UserId } from 'sierra-domain/api/uuid'
import { AssetContext } from 'sierra-domain/asset-context'

export type UseCourseGroupDetailsData = {
  courseGroupData: CourseGroupDetailRow | undefined
  courseEditionsData: CourseGroupDetailRow[]
  courseGroupProgramAssignments: ContentProgramEnrollment[]
  usersData: CourseGroupUserProgressRow[]
  isEditionsLoading: boolean
  isUsersDataLoading: boolean
  fetchCourseGroup: () => Promise<void>
  fetchProgramAssignments: (courseId: CourseId) => Promise<ContentProgramEnrollment[]>
  fetchUsersData: () => Promise<void>
  deleteCourseGroup: () => Promise<void>
  detachEdition: (courseEditionId: string) => Promise<void>
  saveVisibility: (courseId: CourseId, value: boolean) => Promise<void>
  unassignUsers: (userIds: UserId[]) => Promise<void>
  courseImage: string | undefined
}

export const useManageCourseGroupDetails = (courseGroupId: CourseId): UseCourseGroupDetailsData => {
  const { postWithUserErrorException } = usePost()
  const [isEditionsLoading, setIsEditionsLoading] = useState<boolean>(false)
  const [isUsersDataLoading, setIsUsersDataLoading] = useState<boolean>(false)
  const [courseEditionsData, setCourseEditionsData] = useState<CourseGroupDetailRow[]>([])
  const [usersData, setUsersData] = useState<CourseGroupUserProgressRow[]>([])
  const [courseGroupData, setCourseGroupData] = useState<CourseGroupDetailRow | undefined>(undefined)
  const [courseGroupProgramAssignments, setCourseGroupProgramAssignments] = useState<
    ContentProgramEnrollment[]
  >([])

  const assetContext: AssetContext = useMemo(
    () => ({ type: 'course', courseId: courseGroupId }),
    [courseGroupId]
  )

  const resolvedCourseImage = useResolveAsset({
    image: courseGroupData?.image,
    assetContext,
    size: 'admin',
  })

  const courseImage = courseGroupData?.image !== undefined ? resolvedCourseImage : undefined

  const fetchCourseGroup = useCallback(async () => {
    const courseGroupDataResponse = await postWithUserErrorException(XRealtimeAdminCoursesCourseGroupDetail, {
      id: courseGroupId,
    })
    setCourseEditionsData(courseGroupDataResponse.editionsData)
    setCourseGroupData(courseGroupDataResponse.groupData)
  }, [postWithUserErrorException, courseGroupId])

  useEffect(() => {
    void (async () => {
      setIsEditionsLoading(true)
      await fetchCourseGroup()
      setIsEditionsLoading(false)
    })()
  }, [fetchCourseGroup])

  const saveVisibility = useCallback(
    async (courseId: CourseId, value: boolean): Promise<void> => {
      setCourseGroupData(current => (current !== undefined ? { ...current, isVisible: value } : undefined))
      await postWithUserErrorException(XRealtimeAdminCoursesUpdateCourseVisibility, {
        id: courseId,
        isVisible: value,
      })
    },
    [postWithUserErrorException]
  )

  const fetchUsersData = useCallback(async () => {
    const userProgressResp = await postWithUserErrorException(XRealtimeAdminCoursesCourseGroupProgress, {
      id: courseGroupId,
    })
    setUsersData(userProgressResp.userData)
  }, [postWithUserErrorException, courseGroupId])

  const detachEdition = useCallback(
    async (courseEditionId: string) => {
      await postWithUserErrorException(XRealtimeAdminCoursesDetachEdition, {
        courseEditionId,
      })
    },
    [postWithUserErrorException]
  )

  const fetchProgramAssignments = useCallback<UseCourseDetailsData['fetchProgramAssignments']>(
    async courseGroupId => {
      const response = await postWithUserErrorException(XRealtimeAdminCoursesListEnrolledGroups, {
        id: courseGroupId,
      })
      setCourseGroupProgramAssignments(response.data)
      return response.data
    },
    [postWithUserErrorException]
  )

  const unassignUsers = useCallback<UseCourseGroupDetailsData['unassignUsers']>(
    async userIds => {
      await postWithUserErrorException(XRealtimeAdminAssignmentsUnassignFromCourseGroup, {
        courseGroupId,
        userIds,
      })

      await Promise.all([fetchCourseGroup(), fetchUsersData()])
    },
    [postWithUserErrorException, fetchCourseGroup, fetchUsersData, courseGroupId]
  )

  useEffect(() => {
    void (async () => {
      setIsUsersDataLoading(true)
      await fetchUsersData()
      setIsUsersDataLoading(false)
    })()
  }, [fetchUsersData])

  useEffect(() => void fetchProgramAssignments(courseGroupId), [courseGroupId, fetchProgramAssignments])

  const deleteCourseGroup = useCallback(async () => {
    await postWithUserErrorException(XRealtimeAdminCoursesDeleteCourseGroup, {
      courseGroupId: courseGroupId,
    })

    void getGlobalRouter().navigate({ to: '/manage/content', replace: true })
  }, [postWithUserErrorException, courseGroupId])

  return useMemo(
    () => ({
      courseGroupData,
      courseEditionsData,
      courseGroupProgramAssignments,
      usersData,
      isEditionsLoading,
      isUsersDataLoading,
      fetchCourseGroup,
      fetchUsersData,
      fetchProgramAssignments,
      deleteCourseGroup,
      saveVisibility,
      detachEdition,
      unassignUsers,
      courseImage,
    }),
    [
      courseGroupData,
      courseEditionsData,
      courseGroupProgramAssignments,
      usersData,
      isEditionsLoading,
      isUsersDataLoading,
      fetchCourseGroup,
      fetchUsersData,
      fetchProgramAssignments,
      deleteCourseGroup,
      saveVisibility,
      detachEdition,
      unassignUsers,
      courseImage,
    ]
  )
}
