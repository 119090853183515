import { Link } from '@tanstack/react-router'
import { useRef } from 'react'
import { useObserveElementSize } from 'sierra-client/hooks/use-observe-element-size'
import { useTranslation } from 'sierra-client/hooks/use-translation'
import { useUserLegacy } from 'sierra-client/state/users/hooks'
import { getAvatarImage } from 'sierra-client/utils/avatar-img'
import { parseStringToJsonContent } from 'sierra-client/views/v3-author/homework/homework-atoms'
import { UserId } from 'sierra-domain/api/uuid'
import { getUserName, isDefined } from 'sierra-domain/utils'
import { RoundAvatar } from 'sierra-ui/components'
import { FreeTextEditor } from 'sierra-ui/missions/workflows/free-text-editor'
import { Text, View } from 'sierra-ui/primitives'
import styled, { css } from 'styled-components'

const StyledRoundAvatar = styled(RoundAvatar)`
  width: 1rem;
  height: 1rem;
  min-width: 1rem;
  min-height: 1rem;
`

const MaxHeightView = styled(View)<{ maxHeight?: number }>`
  ${p =>
    p.maxHeight !== undefined &&
    css`
      max-height: ${p.maxHeight}px;
      overflow-y: hidden;
      position: relative;

      ::after {
        content: '';
        position: absolute;
        bottom: 0;
        left: 0;
        right: 0;
        height: 3rem;
        background: linear-gradient(to bottom, rgba(255, 255, 255, 0), rgba(255, 255, 255, 1));
        pointer-events: none; /* Ensures the gradient doesn't interfere with text selection */
      }
    `}
`

export const ReviewComment: React.FC<{
  reviewerId?: UserId
  feedback: string
  maxHeight?: number
  readMoreUrl?: string
}> = ({ reviewerId, feedback, maxHeight, readMoreUrl }) => {
  const { t } = useTranslation()
  const reviewer = useUserLegacy(reviewerId)
  const ref = useRef<HTMLDivElement>(null)
  const size = useObserveElementSize(ref.current)
  const isCropped = maxHeight !== undefined && size.height >= maxHeight

  return (
    <>
      <MaxHeightView
        ref={ref}
        direction='column'
        padding='10 14'
        gap='10'
        background='grey5'
        radius='large'
        maxHeight={isCropped ? maxHeight : undefined}
      >
        {isDefined(reviewer) && (
          <View>
            <StyledRoundAvatar
              size='small'
              firstName={reviewer.firstName}
              lastName={reviewer.lastName}
              src={getAvatarImage(reviewer.uuid, reviewer.avatar)}
              color={reviewer.avatarColor}
            />
            <Text bold size='small'>
              {getUserName(reviewer)}
            </Text>
          </View>
        )}
        <View background='grey5' radius='large'>
          <FreeTextEditor content={parseStringToJsonContent(feedback)} editable={false} />
        </View>
      </MaxHeightView>
      {isCropped && readMoreUrl !== undefined && (
        <View paddingTop='10'>
          <Link to={readMoreUrl}>
            <Text bold size='small' color='foreground/muted'>
              {t('dictionary.read-more')}
            </Text>
          </Link>
        </View>
      )}
    </>
  )
}
