import { AnimatePresence, motion } from 'framer-motion'
import React, { useCallback, useState } from 'react'
import { VideoPlayer } from 'sierra-client/components/blocks/video'
import { floatingPill } from 'sierra-client/components/common/header-view'
import { ActionModal } from 'sierra-client/components/common/modals/action-modal'
import { AssignModal } from 'sierra-client/components/common/modals/multi-assign-modal'
import { parseModalToContentAssignment } from 'sierra-client/components/common/modals/multi-assign-modal/utils'
import { useNotif } from 'sierra-client/components/common/notifications'
import { Logging } from 'sierra-client/core/logging'
import { useOrganizationPermissions } from 'sierra-client/hooks/use-permissions'
import { useTranslation } from 'sierra-client/hooks/use-translation'
import { useDispatch } from 'sierra-client/state/hooks'
import {
  ManageDetailContainer,
  ManageDetailContent,
  ManageDetailGrid,
  ManageDetailSidebar,
} from 'sierra-client/views/manage/components/common'
import { DetailsHeader } from 'sierra-client/views/manage/components/details-header'
import { useDueDate } from 'sierra-client/views/manage/components/due-date'
import { ColumnLayout } from 'sierra-client/views/manage/components/layout/column-layout'
import { SideBar } from 'sierra-client/views/manage/live-session/sections/side-bar'
import { LiveSessionRecordingsTable } from 'sierra-client/views/manage/live-session/tables/live-session-recordings-table'
import { LiveSessionUsersTable } from 'sierra-client/views/manage/live-session/tables/live-session-users-table'
import { useLiveSessionDetails } from 'sierra-client/views/manage/live-session/use-live-session-details'
import { LiveSessionRecording } from 'sierra-domain/api/admin'
import { LiveSessionAttendanceStatus } from 'sierra-domain/api/manage'
import { LiveSessionId } from 'sierra-domain/api/nano-id'
import { UserId } from 'sierra-domain/api/uuid'
import { Label, Tooltip } from 'sierra-ui/components'
import { IconButton, View } from 'sierra-ui/primitives'
import { spacing } from 'sierra-ui/theming'
import styled from 'styled-components'

const VideoWrapper = styled(motion.div)`
  z-index: 1;
  width: 30rem;
  max-width: 90%;
  min-width: 16rem;
  aspect-ratio: 16 / 9;
  background: black;
  padding: ${spacing['4']};
  border-radius: ${p => p.theme.borderRadius.regular};
  resize: horizontal;
  overflow: auto;
`

const RecordingButton = styled(View)`
  ${floatingPill}
  width: fit-content;
  margin-top: 16px;
`

const BottomRightContainer = styled.div`
  position: fixed;
  right: 1rem;
  bottom: 4.5rem;
`

export const ManageLiveSessionDetails: React.FC<{
  liveSessionId: LiveSessionId
  canEditAssignments: boolean
}> = ({ liveSessionId, canEditAssignments }) => {
  const { t } = useTranslation()
  const notif = useNotif()

  const { assignWithDueDate } = useDueDate()
  const {
    liveSession,
    liveSessionUsers,
    liveSessionRecordings,
    fetchUsers,
    fetchLiveSession,
    fetchRecordings,
    unassignUsers,
    removeRecording,
    setAttendance,
  } = useLiveSessionDetails(liveSessionId)

  const [action, setAction] = useState<
    | undefined
    | { modal: 'enroll-users' }
    | { modal: 'unassign-users'; ids: UserId[] }
    | { modal: 'remove-recording'; id: string }
  >()
  const [recordingUrl, setRecordingUrl] = useState<string>()
  const [showRecordingPlayer, setShowRecordingPlayer] = useState<boolean>(false)
  const [recordingId, setRecordingId] = useState<string>()
  const canMarkAttendance = useOrganizationPermissions().has('MARK_LIVE_SESSION_ATTENDANCE')

  const dispatch = useDispatch()

  const onClickRecording = (clickedRecording: LiveSessionRecording): void => {
    if (recordingId === clickedRecording.id) {
      setShowRecordingPlayer(showRecordingPlayer => !showRecordingPlayer)
    } else {
      setRecordingId(clickedRecording.id)
      setRecordingUrl(clickedRecording.hlsUrl)
      setShowRecordingPlayer(false)
      setTimeout(() => {
        setShowRecordingPlayer(true)
      }, 500)
      void dispatch(
        Logging.liveSession.watchedRecording({ context: 'manage', recordingId: clickedRecording.id })
      )
    }
  }

  const onEnrollUsers = useCallback(() => {
    setAction({ modal: 'enroll-users' })
  }, [])

  const onRemoveUsers = useCallback((userIds: UserId[]) => {
    setAction({ modal: 'unassign-users', ids: userIds })
  }, [])

  const onRemoveRecording = useCallback((recordingId: string) => {
    setAction({ modal: 'remove-recording', id: recordingId })
  }, [])

  const onSetAttendance = useCallback(
    async (userIds: UserId[], attendance: LiveSessionAttendanceStatus) => {
      await setAttendance({
        liveSessionId,
        attendance: userIds.map(userId => ({ userId, attendance })),
      })
      void fetchUsers(liveSessionId, undefined, { reset: true })
      notif.push({
        type: 'custom',
        level: 'success',
        body: t('notifications.done'),
      })
    },
    [fetchUsers, liveSessionId, notif, setAttendance, t]
  )

  if (liveSession === undefined) return null

  // TODO(seb): Add separate location

  return (
    <ColumnLayout>
      <DetailsHeader
        backlink={{ href: '/manage/content', label: 'manage.backlinks--content' }}
        titleAddon={
          <Label $size='small' $color='white' $bgColor='grey40'>
            {t('dictionary.session')}
          </Label>
        }
      />
      <ManageDetailContainer>
        <ManageDetailGrid>
          <ManageDetailSidebar>
            <SideBar liveSession={liveSession} />
          </ManageDetailSidebar>
          <ManageDetailContent>
            <View direction='column' gap='xxlarge'>
              <LiveSessionUsersTable
                liveSessionUsers={liveSessionUsers}
                maxNumberOfUsers={liveSession.liveSession.data.maxNumberOfUsers}
                contentId={liveSessionId}
                facilitatorIds={liveSession.facilitatorsInfo.map(it => it.userId)}
                canEditAssignments={canEditAssignments}
                canMarkAttendance={canMarkAttendance}
                fetchUsers={fetchUsers}
                openEnrollUsers={onEnrollUsers}
                onRemoveUsers={onRemoveUsers}
                onSetAttendance={onSetAttendance}
              />
              <LiveSessionRecordingsTable
                liveSessionDetails={liveSession}
                data={liveSessionRecordings}
                onRemoveRecording={onRemoveRecording}
                onRecordingClicked={onClickRecording}
              />
            </View>
          </ManageDetailContent>
        </ManageDetailGrid>
      </ManageDetailContainer>
      {recordingUrl !== undefined && (
        <AnimatePresence>
          {showRecordingPlayer && (
            <BottomRightContainer>
              <VideoWrapper initial={{ opacity: 0 }} animate={{ opacity: 1 }} exit={{ opacity: 0 }}>
                <VideoPlayer
                  authSearchParams={new URL(recordingUrl).search}
                  options={{
                    autoplay: true,
                    bigPlayButton: true,
                    controls: true,
                    controlBar: { pictureInPictureToggle: false }, // add this toggle
                    // playbackRates: [1, 1.25, 1.5, 2],
                    preload: 'auto',
                    fill: true,
                    sources: [{ src: recordingUrl, type: 'application/x-mpegURL' }],
                  }}
                />
              </VideoWrapper>
              <Tooltip title='Close recording'>
                <RecordingButton>
                  <IconButton
                    iconId='close'
                    variant='transparent'
                    onClick={() => setShowRecordingPlayer(isShowing => !isShowing)}
                  />
                </RecordingButton>
              </Tooltip>
            </BottomRightContainer>
          )}
        </AnimatePresence>
      )}

      {/* Modals  */}

      <ActionModal
        open={action?.modal === 'unassign-users'}
        onClose={() => setAction(undefined)}
        primaryAction={async (): Promise<void> => {
          if (action?.modal !== 'unassign-users') return
          await unassignUsers(liveSessionId, action.ids)
          void fetchUsers(liveSessionId, undefined, { reset: true })
          notif.push({
            type: 'custom',
            level: 'success',
            body: t('notifications.done'),
          })
        }}
        primaryActionLabel={t('dictionary.unassign')}
        title={t('manage.unassign-users.title', {
          count: action?.modal === 'unassign-users' ? action.ids.length : '',
        })}
        deleteAction
      >
        {t('manage.unassign-users.message', {
          count: action?.modal === 'unassign-users' ? action.ids.length : '',
        })}
      </ActionModal>
      <ActionModal
        open={action?.modal === 'remove-recording'}
        onClose={() => setAction(undefined)}
        primaryAction={async (): Promise<void> => {
          if (action?.modal !== 'remove-recording') return
          await removeRecording(action.id)
          void fetchRecordings(liveSessionId)
          notif.push({
            type: 'custom',
            level: 'success',
            body: t('notifications.done'),
          })
        }}
        primaryActionLabel={t('content.delete-button')}
        title='Delete recording'
        deleteAction
      >
        Are you sure you want to delete this recording?
      </ActionModal>
      {action?.modal === 'enroll-users' && (
        <AssignModal
          isOpen
          config={{
            subjectType: 'live-session',
            panes: 'user-and-user-group',
            activePane: 'user',
            onSave: async selections => {
              const result = await assignWithDueDate(
                parseModalToContentAssignment([liveSessionId], 'live-session', selections)
              )
              if (result?.error !== undefined) return

              setAction(undefined)
              void fetchLiveSession(liveSessionId)
              void fetchUsers(liveSessionId, undefined, { reset: true })
            },
          }}
          subjects={liveSessionId}
          title={t('dictionary.enrollments')}
          onClose={() => setAction(undefined)}
        />
      )}
    </ColumnLayout>
  )
}
