import { AnimatePresence, motion } from 'framer-motion'
import { useAtom } from 'jotai'
import React, { useEffect, useState } from 'react'
import { useRightSidebarContext } from 'sierra-client/components/liveV2/live-sidebar-provider'
import { ShortcutMenu } from 'sierra-client/components/shortcut-menu'
import {
  pictureInPictureAtom,
  PictureInPictureState,
} from 'sierra-client/features/sana-now/picture-in-picture-atom'
import { useTranslation } from 'sierra-client/hooks/use-translation'
import { Icon } from 'sierra-ui/components'
import { IconButton, Spacer, Switch, Text } from 'sierra-ui/primitives'
import { LightTokenProvider, token } from 'sierra-ui/theming'
import styled, { css } from 'styled-components'

const CornerIconsContainer = styled.div`
  display: flex;
  gap: 4px;
  position: absolute;
  top: 16px;
  right: 16px;
`

const CloseButton = styled(IconButton).attrs({ variant: 'transparent', iconId: 'close' })``
const PlusButton = styled(IconButton).attrs({ variant: 'transparent', iconId: 'plus--circle' })``
const MinusButton = styled(IconButton).attrs({ variant: 'transparent', iconId: 'minus--circle' })``

const PictureInPictureContainer = styled(motion.div)<{ $state: PictureInPictureState }>`
  position: relative;
  height: 100%;
  transition: width 0.3s;
  width: ${p => {
    if (p.$state.state !== 'side') {
      return '0px'
    }

    switch (p.$state.size) {
      case 'small':
        return '300px'
      case 'medium':
        return '375px'
      case 'large':
        return '450px'
    }
  }};
  background: rgba(242, 242, 242);
  display: grid;
  place-items: center;

  box-shadow:
    inset 0 0 0 1px rgba(0, 0, 0, 0.04),
    inset 30px 0 20px -15px rgba(0, 0, 0, 0.04);
`
const ContentContainer = styled.div`
  display: flex;
  flex-direction: column;
  overflow: hidden;
  width: 100%;
`

const TextContainer = styled.div<{ $open: boolean }>`
  opacity: ${p => (p.$open ? 1 : 0)};
  transition: opacity 0.3s;
  white-space: nowrap;
  overflow: hidden;
  user-select: none;
`

export const PictureInPictureSideArea: React.FC = () => {
  const { t } = useTranslation()
  const [pictureInPictureState, setPictureInPictureState] = useAtom(pictureInPictureAtom)
  const [textVisible, setTextVisible] = useState(false)

  useEffect(() => {
    if (pictureInPictureState.state === 'side') {
      setTimeout(() => {
        setTextVisible(true)
      }, 300)
    } else {
      setTextVisible(false)
    }
  }, [pictureInPictureState])

  return (
    <LightTokenProvider>
      <PictureInPictureContainer $state={pictureInPictureState}>
        {pictureInPictureState.state === 'side' && (
          <ContentContainer>
            <CornerIconsContainer>
              <AnimatePresence>
                {pictureInPictureState.size !== 'small' && (
                  <motion.div initial={{ opacity: 0 }} animate={{ opacity: 1 }} exit={{ opacity: 0 }}>
                    <MinusButton
                      tooltip={t('dictionary.smaller')}
                      onClick={() => {
                        switch (pictureInPictureState.size) {
                          case 'large':
                            setPictureInPictureState({ state: 'side', size: 'medium' })
                            break
                          case 'medium':
                            setPictureInPictureState({ state: 'side', size: 'small' })
                            break
                          case 'small':
                            break
                        }
                      }}
                    />
                  </motion.div>
                )}
                {pictureInPictureState.size !== 'large' && (
                  <motion.div initial={{ opacity: 0 }} animate={{ opacity: 1 }} exit={{ opacity: 0 }}>
                    <PlusButton
                      tooltip={t('dictionary.larger')}
                      onClick={() => {
                        switch (pictureInPictureState.size) {
                          case 'small':
                            setPictureInPictureState({ state: 'side', size: 'medium' })
                            break
                          case 'medium':
                            setPictureInPictureState({ state: 'side', size: 'large' })
                            break
                          case 'large':
                            break
                        }
                      }}
                    />
                  </motion.div>
                )}
              </AnimatePresence>
              <CloseButton
                tooltip={t('dictionary.close')}
                onClick={() => setPictureInPictureState({ state: 'closed' })}
              />
            </CornerIconsContainer>
            <Icon iconId='breakout-room' size='size-48' color='foreground/muted' />

            <Spacer size='16' />

            <TextContainer $open={textVisible}>
              <Text align='center' color='foreground/muted' bold>
                {t('sana-now.reserved-for-picture-in-picture-area')}
              </Text>

              <Text align='center' color='foreground/muted'>
                {t('sana-now.put-meeting-here')}
              </Text>
            </TextContainer>
          </ContentContainer>
        )}
      </PictureInPictureContainer>
      <ShortcutMenu.Action
        iconId='breakout-room'
        group='live'
        label={'sana-now.toggle-picture-in-picture-area'}
        run={(): void =>
          setPictureInPictureState(prev => {
            if (prev.state !== 'side') {
              return {
                state: 'side',
                size: 'small',
              }
            } else {
              return {
                state: 'closed',
              }
            }
          })
        }
        permission={'ACCESS_LMS'}
      />
    </LightTokenProvider>
  )
}

const ChatAreaContainer = styled.div<{ $open: boolean }>`
  position: relative;
  transition: flex-basis 0.3s;
  width: 100%;
  flex-basis: ${p => (p.$open ? '50%' : '0')};
  flex-shrink: 0;
  background-color: ${p => (p.$open ? token('elevated/fade') : token('elevated/background'))};
  display: grid;
  place-items: center;

  ${p =>
    p.$open
      ? css`
          border-top: 1px solid ${token('border/default')};
        `
      : undefined};
`

const ToggleContainer = styled.div<{ $open: boolean }>`
  padding: 4px 18px 16px;
  background-color: ${p => (p.$open ? token('elevated/fade') : token('elevated/background'))};
  display: flex;
  justify-content: space-between;
`

export const PictureInPictureChatArea: React.FC = () => {
  const { t } = useTranslation()
  const rightSidebarContext = useRightSidebarContext()
  const [pictureInPictureState, setPictureInPictureState] = useAtom(pictureInPictureAtom)
  const [textVisible, setTextVisible] = useState(false)

  const toggle = (): void =>
    setPictureInPictureState(p => {
      if (p.state !== 'chat') {
        return { state: 'chat' }
      } else {
        return { state: 'closed' }
      }
    })

  useEffect(() => {
    if (pictureInPictureState.state === 'chat') {
      setTimeout(() => {
        setTextVisible(true)
      }, 300)
    } else {
      setTextVisible(false)
    }
  }, [pictureInPictureState])
  const open = pictureInPictureState.state === 'chat' && rightSidebarContext.isOpen

  return (
    <>
      <ChatAreaContainer $open={pictureInPictureState.state === 'chat'}>
        {open && (
          <ContentContainer>
            <Icon iconId='shrink-screen' size='size-16' color='foreground/muted' />

            <Spacer size='8' />

            <TextContainer $open={textVisible}>
              <Text align='center' color='foreground/muted' bold>
                {t('sana-now.reserved-for-picture-in-picture-area')}
              </Text>

              <Text align='center' color='foreground/muted'>
                {t('sana-now.put-meeting-here')}
              </Text>
            </TextContainer>
          </ContentContainer>
        )}
      </ChatAreaContainer>
      <ToggleContainer $open={open}>
        <Text size='micro' bold color='foreground/muted'>
          {open ? t('sana-now.hide-picture-in-picture-area') : t('sana-now.show-picture-in-picture-area')}
        </Text>
        <Switch checked={open} onChange={toggle} ariaLabel={t('sana-now.toggle-picture-in-picture-area')} />
      </ToggleContainer>
    </>
  )
}
