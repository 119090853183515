import { atom, useAtomValue, useSetAtom } from 'jotai'
import { selectAtom } from 'jotai/utils'
import { useEffect, useMemo } from 'react'

const UniquenessAtom = atom<Record<string, number>>({})

/**
 * Check if the hook has been called with the same key more than once
 * Can be used to check how many times a component has been mounted
 */
export const useIsUnique = (key: string): boolean => {
  const setInstances = useSetAtom(UniquenessAtom)
  const isUniqueAtom = useMemo(
    () => selectAtom(UniquenessAtom, instances => (instances[key] ?? 0) <= 1),
    [key]
  )

  useEffect(() => {
    setInstances(current => {
      return { ...current, [key]: (current[key] ?? 0) + 1 }
    })

    return () => {
      setInstances(current => {
        const currentValue = current[key]
        return { ...current, [key]: currentValue !== undefined ? currentValue - 1 : 0 }
      })
    }
  }, [key, setInstances])

  return useAtomValue(isUniqueAtom)
}

/**
 * Will throw if the hook is called more than once with the same key in the react tree
 */
export const useAssertUniqueness = (key: string): void => {
  const isUnique = useIsUnique(key)

  useEffect(() => {
    if (!isUnique) throw new Error(`useAssertUniqueness(${key}) should used be called once in the react tree`)
  }, [isUnique, key])
}
