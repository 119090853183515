import { createFileRoute } from '@tanstack/react-router'

import { ShortcutMenu } from 'sierra-client/components/shortcut-menu'
import { useDeveloperToolsEnabled } from 'sierra-client/hooks/use-developer-tools-enabled'
import { PageIdentifier } from 'sierra-client/layout/sana-page'
import { ManageProgramDetails } from 'sierra-client/views/manage/programs/manage-program-details'
import { ManagePageWrapper } from 'sierra-client/views/manage/utils/manage-page-wrapper'
import { ProgramId } from 'sierra-domain/api/uuid'
import { z } from 'zod'

// Note: ManagePageWrapper handles the role check.
const Page: React.FC = () => {
  const programId = Route.useParams({ select: p => p.programId })
  const isDev = useDeveloperToolsEnabled()

  return (
    <>
      <ManagePageWrapper pageIdentifier={PageIdentifier.ManageProgramDetail({ programId })}>
        <ManageProgramDetails key={programId} programId={programId} />
      </ManagePageWrapper>

      {isDev && (
        <ShortcutMenu.Link
          label={{ type: 'untranslated', value: `Open program's learner page` }}
          href={`/program/${programId}`}
          iconId='arrow--right'
          permission='ACCESS_LMS'
        />
      )}
    </>
  )
}

export const Route = createFileRoute('/manage/programs/$programId')({
  component: Page,
  params: {
    parse: z.object({ programId: ProgramId }).parse,
    stringify: p => p,
  },
})
