import { createFileRoute } from '@tanstack/react-router'
import { useEffect } from 'react'
import { useCurrentSessionForRoomQuery } from 'sierra-client/api/hooks/use-live-session'
import { requireLoggedIn } from 'sierra-client/core/require-logged-in'
import { notFound404Redirect } from 'sierra-client/router/navigation'
import { LiveRoute } from 'sierra-client/views/liveV2/live-page'
import { LiveRoomId } from 'sierra-domain/api/nano-id'
import { RequestError } from 'sierra-domain/error'
import { z } from 'zod'

function Page(): JSX.Element | null {
  const liveRoomId = Route.useParams({ select: select => select.liveRoomId })

  const result = useCurrentSessionForRoomQuery(liveRoomId)

  useEffect(() => {
    if (RequestError.isNotFound(result.error)) {
      void notFound404Redirect()
    }
  }, [result.error])

  if (result.data === undefined) {
    return null
  }

  return <LiveRoute liveSessionId={result.data} />
}

export const Route = createFileRoute('/room/$liveRoomId/')({
  component: requireLoggedIn(Page, { allowGuestAccess: true }) as React.FC,
  params: {
    parse: z.object({ liveRoomId: LiveRoomId }).parse,
    stringify: p => p,
  },
})
