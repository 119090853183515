import { motion } from 'framer-motion'
import styled, { css } from 'styled-components'

export const ItemsList = styled(motion.ul)<{ $marginTop?: number }>`
  display: flex;
  flex-direction: column;
  gap: 4px;
  ${p =>
    p.$marginTop !== undefined &&
    css`
      margin-top: ${p.$marginTop}px;
    `}
`
