import React from 'react'

export const useGetManageProgramURL = (): ((programId: string) => string) => {
  return React.useCallback((programId: string) => {
    return `/manage/programs/${programId}`
  }, [])
}

export const useManageProgramURL = (programId: string): string => {
  const getManageProgramURL = useGetManageProgramURL()

  return getManageProgramURL(programId)
}
