import { OptionsObject, useSnackbar } from 'notistack'
import { useMemo } from 'react'
import { useIsDebugMode } from 'sierra-client/hooks/use-is-debug-mode'
import { useStableFunction } from 'sierra-client/hooks/use-stable-function'

export function useDevelopmentSnackbar(): {
  reportInDev: (message: string, options: OptionsObject) => void
} {
  const isDev = process.env.NODE_ENV === 'development'
  const isDebug = useIsDebugMode()
  const shouldLog = isDev || isDebug

  const { enqueueSnackbar } = useSnackbar()

  const reportInDev = useStableFunction((message: string, options: OptionsObject) => {
    if (shouldLog) {
      enqueueSnackbar(message, options)
    }
  })

  return useMemo(() => ({ reportInDev }), [reportInDev])
}
