import { createFileRoute } from '@tanstack/react-router'

import { PageIdentifier } from 'sierra-client/layout/sana-page'
import { useSettingsPageEnabled } from 'sierra-client/views/manage/permissions/use-settings-page-enabled'
import { ManageSettings } from 'sierra-client/views/manage/settings/settings-general'
import { ManagePageWrapper } from 'sierra-client/views/manage/utils/manage-page-wrapper'

// Note: ManagePageWrapper handles additional auth checks.
function ManageSettingsPage(): JSX.Element | null {
  const hasSettingsAccess = useSettingsPageEnabled()

  if (hasSettingsAccess !== true) {
    // todo(workflows): redirect once initialized
    return null
  }

  return (
    <ManagePageWrapper pageIdentifier={PageIdentifier.ManageSettings()}>
      <ManageSettings />
    </ManagePageWrapper>
  )
}

const RouteComponent = ManageSettingsPage

export const Route = createFileRoute('/settings/general')({
  component: RouteComponent as React.FC,
})
