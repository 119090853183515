import { atom } from 'jotai'

export type AblyConnectionState =
  | 'initialized'
  | 'connected'
  | 'connecting'
  | 'disconnected'
  | 'suspended'
  | 'closed'
  | 'closing'
  | 'failed'

export const connectionStateAtom = atom<AblyConnectionState>('disconnected')
