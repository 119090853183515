import React, { useCallback, useContext, useMemo, useState } from 'react'
import { useHasLiveContext } from 'sierra-client/components/liveV2/live-context'
import { useDispatch, useSelector } from 'sierra-client/state/hooks'
import { liveSessionGroupReflectionResponsesToggle } from 'sierra-client/state/live-session/actions'
import { selectIsFacilitator, selectReflectionCardStates } from 'sierra-client/state/live-session/selectors'
import { ReflectionCardGroupByState } from 'sierra-domain/live-session'

type ReflectionGroupData = {
  groupBy: ReflectionCardGroupByState
  setGroupBy: (groupBy: ReflectionCardGroupByState) => void
  canSetGroupBy: boolean
}

const LiveContext = React.createContext<ReflectionGroupData | undefined>(undefined)
const SelfPacedContext = React.createContext<ReflectionGroupData | undefined>(undefined)

const GroupDataLayerLive: React.FC<{ reflectionId: string; children: React.ReactNode }> = ({
  reflectionId,
  children,
}) => {
  const dispatch = useDispatch()

  const liveReflectionCardStates = useSelector(selectReflectionCardStates) ?? {}
  const groupBy = liveReflectionCardStates[reflectionId]?.groupBy ?? 'individual'

  const isFacilitator = useSelector(selectIsFacilitator)
  const canSetGroupBy = isFacilitator

  const setGroupBy = useCallback(
    (groupBy: ReflectionCardGroupByState) => {
      dispatch(
        liveSessionGroupReflectionResponsesToggle({
          reflectionId,
          groupBy,
        })
      )
    },
    [dispatch, reflectionId]
  )

  const value = useMemo(() => ({ groupBy, setGroupBy, canSetGroupBy }), [groupBy, setGroupBy, canSetGroupBy])

  return <LiveContext.Provider value={value}>{children}</LiveContext.Provider>
}

const GroupDataLayerSelfPaced: React.FC<{ children: React.ReactNode }> = ({ children }) => {
  const [groupBy, setGroupByState] = useState<ReflectionCardGroupByState>('individual')

  const setGroupBy = useCallback((groupBy: ReflectionCardGroupByState) => {
    setGroupByState(groupBy)
  }, [])

  const value = useMemo(() => ({ groupBy, setGroupBy, canSetGroupBy: true }), [groupBy, setGroupBy])

  return <SelfPacedContext.Provider value={value}>{children}</SelfPacedContext.Provider>
}

export const ReflectionGroupDataLayer: React.FC<{ reflectionId: string; children: React.ReactNode }> = ({
  reflectionId,
  children,
}) => {
  const hasLiveContext = useHasLiveContext()

  return hasLiveContext ? (
    <GroupDataLayerLive reflectionId={reflectionId}>{children}</GroupDataLayerLive>
  ) : (
    <GroupDataLayerSelfPaced>{children}</GroupDataLayerSelfPaced>
  )
}

export const useReflectionGroupData = (): ReflectionGroupData => {
  const hasLiveContext = useHasLiveContext()
  const liveContext = useContext(LiveContext)
  const selfPacedContext = useContext(SelfPacedContext)

  const context = hasLiveContext ? liveContext : selfPacedContext
  if (context === undefined) throw new Error('This component must be wrapped in a ReflectionGroupDataLayer')

  return context
}
