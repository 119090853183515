import { ColorName } from 'sierra-ui/color/types'
import { SpacerSize } from 'sierra-ui/primitives'
import { spacing, token } from 'sierra-ui/theming'
import styled from 'styled-components'

type MessageProps = {
  color?: ColorName
  padding?: SpacerSize
}

export const Message = styled.div<MessageProps>`
  padding: ${p => spacing[p.padding ?? '12']};
  background-color: ${p => (p.color ? p.theme.color[p.color] : token('surface/soft'))};
  border-radius: ${p => p.theme.borderRadius['size-8']};
`
