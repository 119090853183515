import { ExpensiveContentFragmentFragment } from 'sierra-client/api/graphql/gql/graphql'
import { TaggableContent } from 'sierra-client/api/hooks/use-taggable-content'
import { EditableContent, NonEditorTeamspaceCourse } from 'sierra-domain/api/editable-content'
import { LearnEntity } from 'sierra-domain/api/entities'
import { ContentKind, LearnerContent } from 'sierra-domain/api/learn'
import { CourseId, PathId } from 'sierra-domain/api/nano-id'
import { TeamspaceEditableContent, TeamspaceNonEditorCourse } from 'sierra-domain/api/teamspace'
import { ProgramId } from 'sierra-domain/api/uuid'
import { AssetContext } from 'sierra-domain/asset-context'
import { assertNever } from 'sierra-domain/utils'

export const getAssetContextFromLearnEntity = (entity: LearnEntity): AssetContext => {
  switch (entity.entityType) {
    case 'native:live':
    case 'native:event-group':
    case 'native:self-paced':
    case 'native:course-group':
    case 'scorm':
    case 'scorm:course-group':
    case 'link':
    case 'linkedin':
      return { type: 'course' as const, courseId: CourseId.parse(entity.id) }

    case 'live-session':
      return { type: 'course' as const, courseId: CourseId.parse(entity.backingCourse) }

    case 'path':
      return { type: 'path' as const, pathId: PathId.parse(entity.id) }

    case 'program':
      return { type: 'program' as const, programId: ProgramId.parse(entity.id) }

    default:
      assertNever(entity)
  }
}

export const getAssetContextFromEditableContent = (
  entity: EditableContent | NonEditorTeamspaceCourse
): AssetContext => {
  switch (entity.type) {
    case 'native:live':
    case 'native:self-paced':
    case 'native:course-group':
    case 'native:event-group':
    case 'scorm':
    case 'scorm:course-group':
    case 'link':
    case 'linkedin':
      return { type: 'course' as const, courseId: CourseId.parse(entity.id) }

    case 'path':
      return { type: 'path' as const, pathId: PathId.parse(entity.id) }

    default:
      assertNever(entity)
  }
}

export const getAssetContextFromLearnerContent = (entity: LearnerContent): AssetContext => {
  switch (entity.type) {
    case 'native:live':
    case 'native:self-paced':
    case 'native:course-group':
    case 'native:event-group':
    case 'scorm':
    case 'scorm:course-group':
    case 'link':
    case 'linkedin':
      return { type: 'course' as const, courseId: CourseId.parse(entity.id) }

    case 'live-session':
      return { type: 'course' as const, courseId: CourseId.parse(entity.courseId) }

    case 'path':
      return { type: 'path' as const, pathId: PathId.parse(entity.id) }

    case 'program':
      return { type: 'program' as const, programId: ProgramId.parse(entity.id) }

    case 'homework':
      return { type: 'homework' as const }

    default:
      assertNever(entity)
  }
}

export const getAssetContextFromTeamspaceContent = (
  entity: TeamspaceEditableContent | TeamspaceNonEditorCourse
): AssetContext => {
  switch (entity.type) {
    case 'editable':
      return { type: 'course' as const, courseId: CourseId.parse(entity.content.id) }

    case 'non-editor-course':
      switch (entity.content.type) {
        case 'scorm':
        case 'native:course-group':
        case 'scorm:course-group':
        case 'native:event-group':
        case 'link':
        case 'linkedin':
          return { type: 'course' as const, courseId: CourseId.parse(entity.content.id) }

        default:
          assertNever(entity.content)
      }
      break

    default:
      assertNever(entity)
  }
}

export const getAssetContextFromContentFragment = (
  content: ExpensiveContentFragmentFragment
): AssetContext => {
  const parts = content.contentId.split(':')

  switch (content.__typename) {
    case 'NativeLiveCourse':
    case 'NativeCourseGroup':
    case 'NativeEventGroup':
    case 'NativeSelfPacedCourse':
    case 'ScormCourse':
    case 'ScormCourseGroup':
    case 'LinkCourse':
    case 'LinkedInCourse':
      if (parts.length === 2) {
        return { type: 'course' as const, courseId: CourseId.parse(parts[1]) }
      }
      return { type: 'unknown' as const }

    case 'Path':
      if (parts.length === 2) {
        return { type: 'path' as const, pathId: PathId.parse(parts[1]) }
      }
      return { type: 'unknown' as const }

    case 'Program':
      if (parts.length === 2 && parts[1] !== undefined) {
        return { type: 'program' as const, programId: ProgramId.parse(parts[1]) }
      }
      return { type: 'unknown' as const }

    default:
      assertNever(content)
  }
}

export const getAssetContextFromContentKindAndId = (id: string, type: ContentKind): AssetContext => {
  switch (type) {
    case 'native:live':
    case 'native:self-paced':
    case 'native:course-group':
    case 'native:event-group':
    case 'scorm':
    case 'scorm:course-group':
    case 'link':
    case 'linkedin':
      return { type: 'course' as const, courseId: CourseId.parse(id) }

    case 'path':
      return { type: 'path' as const, pathId: PathId.parse(id) }

    case 'program':
      return { type: 'program' as const, programId: ProgramId.parse(id) }

    case 'homework':
      return { type: 'homework' as const }

    case 'live-session':
      return { type: 'unknown' }

    default:
      assertNever(type)
  }
}

export const getAssetContextFromTaggableContent = (
  content: Pick<TaggableContent, 'id' | 'type'>
): AssetContext => {
  return getAssetContextFromContentKindAndId(content.id, content.type)
}
