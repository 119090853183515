import { useCallback, useEffect, useRef } from 'react'
import { useStableFunction } from 'sierra-client/hooks/use-stable-function'

type UseIntervalOptions = {
  autostart?: boolean
}

export type UseIntervalCallback = (_: { clear: () => void }) => void
type UseInterval = (
  callback: UseIntervalCallback,
  delay: number,
  options?: UseIntervalOptions
) => {
  start: () => void
  clear: () => void
}

export const useInterval: UseInterval = (callback, delay, { autostart = true } = {}) => {
  const intervalId = useRef<NodeJS.Timeout | null>(null)

  // We'll make sure that the callback has a stable identity so that we can
  // safely use it in the interval without causing a new interval to be created
  // every time the callback changes.
  const tick = useStableFunction(callback)

  const clear = useCallback(() => {
    if (intervalId.current !== null) {
      clearInterval(intervalId.current)
      intervalId.current = null
    }
  }, [])

  const start = useCallback(() => {
    // Clear any existing interval before starting a new one
    clear()
    intervalId.current = setInterval(() => {
      tick({ clear })
    }, delay)
  }, [delay, tick, clear])

  useEffect(() => {
    return () => {
      // Clear existing interval on unmount
      clear()
    }
  }, [clear])

  useEffect(() => {
    if (autostart) {
      start()
    }
  }, [autostart, start])

  return {
    start,
    clear,
  }
}
