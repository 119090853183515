import React, { useCallback, useMemo } from 'react'
import { useTranslation } from 'sierra-client/hooks/use-translation'
import { labelToString } from 'sierra-client/lib/filter/components/common'
import { valueId } from 'sierra-client/lib/filter/components/predicate-utils'
import { useComposeUserInvitationConfig } from 'sierra-client/views/manage/components/user-attributes/flows/invite-users/hooks/use-compose-user-invitation-config'
import {
  getCommonValueRepForChoices,
  getValueRepFromDomainChoices,
  valueRepToMenuLabelItem,
} from 'sierra-client/views/manage/components/user-attributes/flows/invite-users/utils'
import { createInvitationAttribute } from 'sierra-client/views/manage/components/user-attributes/utils'
import { UserCustomAttributeDomainRepChoices } from 'sierra-domain/user-attributes/user-invitation-domain-rep'
import { LabelMenuItem, MenuItem } from 'sierra-ui/components'
import { SingleSelectDropdown } from 'sierra-ui/primitives/menu-dropdown'

export const InvitationDomainChoicesInput: React.FC<{
  domainRep: UserCustomAttributeDomainRepChoices
}> = ({ domainRep }) => {
  const { dynamicT, t } = useTranslation()

  const { addOrReplaceAttributes, userAttributesConfig, editingEmails } = useComposeUserInvitationConfig()

  const currentAttributeItem: LabelMenuItem | undefined = useMemo(
    () =>
      valueRepToMenuLabelItem(
        getCommonValueRepForChoices(domainRep, userAttributesConfig, editingEmails),
        dynamicT
      ),
    [domainRep, dynamicT, editingEmails, userAttributesConfig]
  )

  const setAttribute = useCallback(
    (item: MenuItem): void => {
      const attributeValue = getValueRepFromDomainChoices(domainRep.domain, item.id)
      const attribute = createInvitationAttribute(domainRep.ref, [attributeValue.value])

      addOrReplaceAttributes([attribute])
    },
    [domainRep.domain, domainRep.ref, addOrReplaceAttributes]
  )

  const menuItems: LabelMenuItem[] = useMemo(
    () =>
      domainRep.domain.choices.map(choice => ({
        type: 'label',
        id: valueId(choice.value),
        value: choice.value,
        label: labelToString(
          getValueRepFromDomainChoices(domainRep.domain, valueId(choice.value)).label,
          dynamicT
        ),
      })),
    [domainRep.domain, dynamicT]
  )

  return (
    <SingleSelectDropdown
      withSearch={menuItems.length > 8}
      onSelect={setAttribute}
      selectedItem={currentAttributeItem}
      menuItems={menuItems}
      placeholder={t('dictionary.select')}
    />
  )
}
