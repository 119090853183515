import { useRef } from 'react'
import {
  CountPill,
  IdentitiesListContainer,
  ListItem,
} from 'sierra-client/components/common/identities-selector/atoms'
import { getIdentityBrand } from 'sierra-client/components/common/identities-selector/identities-utils'
import { Identity } from 'sierra-client/components/common/identities-selector/types'
import { ListVirtualizer } from 'sierra-client/views/workspace/components/list-virtualizer'
import { UserDisplay } from 'sierra-ui/components'
import { RenderMatchingListContext } from 'sierra-ui/components/autocomplete/types'
import { View } from 'sierra-ui/primitives'

type Props<T extends Identity> = {
  getItemProps: RenderMatchingListContext<T>['getItemProps']
  matchingItems: T[]
}

export const VirtualizedIdentities = <T extends Identity>({
  getItemProps,
  matchingItems,
}: Props<T>): JSX.Element => {
  const scrollingRef = useRef<HTMLDivElement>(null)

  return (
    <IdentitiesListContainer ref={scrollingRef}>
      <ListVirtualizer
        items={matchingItems}
        scrollElement={scrollingRef.current}
        estimateSize={55}
        renderItem={(identity, index) => {
          const { color, image } = getIdentityBrand(identity)

          return (
            <ListItem key={identity.id} {...getItemProps(identity, index)}>
              <View direction='row' justifyContent='flex-start' alignItems='center'>
                <UserDisplay
                  primaryText={identity.name}
                  secondaryText={identity.identity.type === 'user' ? identity.email : undefined}
                  avatar={{ color, src: image, firstName: identity.name }}
                />
                {identity.identity.type === 'userGroup' && <CountPill>{identity.label}</CountPill>}
              </View>
            </ListItem>
          )
        }}
      />
    </IdentitiesListContainer>
  )
}
