import { UUID } from 'sierra-domain/api/uuid'
import { z } from 'zod'

export const SkillLevelSettingId = z.string().brand('SkillLevelSettingId')
export type SkillLevelSettingId = z.infer<typeof SkillLevelSettingId>

export const SkillLevelId = z.string().brand('SkillLevelId')
export type SkillLevelId = z.infer<typeof SkillLevelId>

export const SkillId = UUID.brand('SkillId')
export type SkillId = z.infer<typeof SkillId>

export const SkillRecommendationId = z.string().brand('SkillRecommendationId')
export type SkillRecommendationId = z.infer<typeof SkillRecommendationId>
