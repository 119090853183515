import { useMemo } from 'react'
import { Control, UseFormWatch } from 'react-hook-form'
import { GoogleCalendarLogo } from 'sierra-client/components/common/logos/google-calendar-logo'
import { OutlookLogo } from 'sierra-client/components/common/logos/outlook-logo'
import { SanaLogo } from 'sierra-client/components/common/logos/sana-logo'
import { GeneralSettingsFormData } from 'sierra-client/views/manage/settings/types'
import { FormSingleSelectDropdown } from 'sierra-client/views/manage/shared/form'
import { CalendarEventSettings as CalendarEventSettingsType } from 'sierra-domain/api/private'
import { CanvasMenuItem } from 'sierra-ui/components'
import { Text, View } from 'sierra-ui/primitives'
import { DefaultDropdownTrigger } from 'sierra-ui/primitives/menu-dropdown'
import { token } from 'sierra-ui/theming'
import styled from 'styled-components'

const StyledTrigger = styled(DefaultDropdownTrigger)`
  padding: 20px 24px;
  border-radius: 16px;
  box-shadow: 0px 2px 4px 0px #00000014;
  outline: 1px solid ${token('border/default')};
`

export const CalendarIntegrationSettings: React.FC<{
  control: Control<GeneralSettingsFormData>
  watchValues: UseFormWatch<GeneralSettingsFormData>
}> = ({ control, watchValues }) => {
  const calendarIntegrationSettings = watchValues('calendarIntegrationSettings')

  const menuItems: CanvasMenuItem<CalendarEventSettingsType['type']>[] = useMemo(
    () => [
      {
        type: 'canvas',
        render: () => (
          <View gap='16'>
            <SanaLogo />
            <View gap='none' direction='column'>
              <Text bold size='small'>
                Sana
              </Text>
              <Text size='small' color='foreground/secondary'>
                Sana will send emails with calendar invites for Sana events
              </Text>
            </View>
          </View>
        ),
        id: 'sana',
      },
      {
        type: 'canvas',
        render: () => (
          <View gap='16'>
            <GoogleCalendarLogo />
            <View gap='none' direction='column'>
              <Text bold size='small'>
                Google Calendar
              </Text>
              <Text size='small' color='foreground/secondary'>
                Authenticate and send invites from Google Calendar to Sana Events
              </Text>
            </View>
          </View>
        ),
        id: 'google',
      },
      {
        type: 'canvas',
        render: () => (
          <View gap='16'>
            <OutlookLogo />
            <View gap='none' direction='column'>
              <Text bold size='small'>
                Microsoft Outlook
              </Text>
              <Text size='small' color='foreground/secondary'>
                Authenticate and send invites from Microsoft Outlook to Sana Events
              </Text>
            </View>
          </View>
        ),
        id: 'microsoft',
      },
    ],
    []
  )

  return (
    <FormSingleSelectDropdown
      name='calendarIntegrationSettings.type'
      label={'Calendar invites'}
      control={control}
      renderTrigger={open => (
        <StyledTrigger grow open={open}>
          {menuItems
            .find(it => it.id === calendarIntegrationSettings.type)
            ?.render({ onItemClick: () => {} })}
        </StyledTrigger>
      )}
      menuItems={menuItems}
    />
  )
}
