import { capitalize } from 'lodash'
import { FC, useMemo, useState } from 'react'
import { SquareAvatar } from 'sierra-client/components/common/square-avatar'
import { useLiveSessionContext } from 'sierra-client/components/liveV2/contexts/live-session-data'
import { AudioIndicatorWrapper } from 'sierra-client/components/liveV2/live-layer/audio-indicator-wrapper'
import { RaisingHand } from 'sierra-client/components/liveV2/raising-hand'
import { Response } from 'sierra-client/components/liveV2/right-sidebar/components'
import { useRemoteParticipantAudioTrack } from 'sierra-client/components/liveV2/services/video-call-service/hooks/use-remote-participant-track'
import { useShowBadNetwork } from 'sierra-client/components/liveV2/services/video-call-service/hooks/use-show-bad-network'
import { Logging } from 'sierra-client/core/logging'
import { useInterval } from 'sierra-client/hooks/use-interval'
import { useTranslation } from 'sierra-client/hooks/use-translation'
import { useDispatch, useSelector } from 'sierra-client/state/hooks'
import { addFacilitator, removeFacilitator } from 'sierra-client/state/live-session/actions'
import {
  selectAllBreakoutRooms,
  selectAssignedRoomIdForUser,
  selectBreakoutSession,
  selectCanPinMore,
  selectFacilitatorIds,
  selectIsFacilitator,
  selectIsParticipantPinned,
  selectLiveSessionLocalAwarenessState,
  selectSessionParticipant,
  selectUsersPositionInRaisedHandQueue,
  selectVideoCallMode,
} from 'sierra-client/state/live-session/selectors'
import { liveSessionSlice } from 'sierra-client/state/live-session/slice'
import { LiveParticipant, Participant } from 'sierra-client/state/live-session/types'
import * as breakoutRooms from 'sierra-client/state/live-session/utils'
import { muteAudio } from 'sierra-client/state/live/actions'
import { selectAudioState } from 'sierra-client/state/live/selectors'
import { useUserLegacy } from 'sierra-client/state/users/hooks'
import { NanoId12 } from 'sierra-domain/api/nano-id'
import { UserId } from 'sierra-domain/api/uuid'
import { getUserName } from 'sierra-domain/utils'
import { Icon, LabelMenuItem, Tooltip, TruncatedText } from 'sierra-ui/components'
import { View } from 'sierra-ui/primitives'
import { IconMenu } from 'sierra-ui/primitives/menu-dropdown'
import { narrowDotSeparator } from 'sierra-ui/utils'
import styled from 'styled-components'

const ParticipantWrapper = styled(View).attrs({
  gap: '12',
  padding: '6 none',
  alignItems: 'center',
  position: 'relative',
})`
  border: none;
`

const LoadingUser = (): JSX.Element => <div>Loading…</div>

const AvatarWrapper = styled.div`
  position: relative;
`

const RaisingHandWrapper = styled.div`
  position: absolute;
  z-index: 1;
  transform: scale(0.7);
  top: -12px;
  left: -12px;
`

const EvaluationIndicatorDot = styled.div<{ correct: boolean }>`
  padding: 0.1rem;
  width: 0.3rem;
  height: 0.3rem;
  background-color: ${p => {
    if (p.correct) return p.theme.color.greenBright
    else return p.theme.color.redBright
  }};
  border-radius: 2rem;
`

const StarWrapper = styled.span`
  font-size: 12px;
`

const EvaluationIndicator: FC<{ response: Response }> = ({ response }) => {
  const { t } = useTranslation()
  if (response.correct === undefined) return null

  if (response.correct === true && response.fastestCorrect === true) {
    return (
      <Tooltip title={t('live.question-card.fastest-answer')}>
        <StarWrapper>🥇</StarWrapper>
      </Tooltip>
    )
  }

  if (response.correct === true && response.attempts === 1) {
    return (
      <Tooltip title={t('live.question-card.first-attempt-correct')}>
        <StarWrapper>⭐</StarWrapper>
      </Tooltip>
    )
  }

  return <EvaluationIndicatorDot correct={response.correct} />
}

export const ParticipantListItem = ({
  participant,
  response,
}: {
  participant: LiveParticipant
  response?: Response
}): JSX.Element => {
  const [volume, setVolume] = useState<number | undefined>(undefined)
  const dispatch = useDispatch()
  const handIsRaised = useSelector(
    state =>
      participant.userId !== undefined &&
      selectUsersPositionInRaisedHandQueue(state, participant.userId) !== undefined
  )
  const user = useUserLegacy(participant.userId)
  const facilitatorIds = useSelector(selectFacilitatorIds)
  const isParticipantFacilitator =
    participant.userId !== undefined && facilitatorIds.includes(participant.userId)
  const isUserFacilitator = useSelector(selectIsFacilitator)
  const participantAudioTrack = useRemoteParticipantAudioTrack(participant.agoraUID)
  const liveSessionId = useLiveSessionContext().liveSessionId

  const { t } = useTranslation()

  const videoCallMode = useSelector(selectVideoCallMode)
  const breakoutSession = useSelector(selectBreakoutSession)
  const awareness = useSelector(selectLiveSessionLocalAwarenessState)
  const isMe = useMemo(() => user?.uuid === awareness.userId, [user?.uuid, awareness.userId])
  const audioState = useSelector(selectAudioState)
  const audioMuted = isMe ? audioState === 'muted' : volume === undefined
  const sessionParticipant = useSelector(selectSessionParticipant)(participant.agoraUID)
  const allBreakoutRooms = useSelector(selectAllBreakoutRooms)
  const assignedRoomId = useSelector(state =>
    participant.userId !== undefined ? selectAssignedRoomIdForUser(state, participant.userId) : undefined
  )

  const isInCorrectRoom =
    isParticipantFacilitator ||
    (videoCallMode === 'breakout' && sessionParticipant?.breakoutRoomId === assignedRoomId) ||
    (videoCallMode === 'main' && sessionParticipant?.breakoutRoomId === undefined)

  const isPinned = useSelector(selectIsParticipantPinned)(participant.userId)

  useInterval(() => {
    if (participantAudioTrack) {
      setVolume(participantAudioTrack.getVolumeLevel())
    } else {
      setVolume(undefined)
    }
  }, 500)

  const requestMute = (): void => {
    if (audioMuted || participant.userId === undefined) return

    if (isMe) {
      void dispatch(muteAudio())
    } else {
      void dispatch(
        liveSessionSlice.actions.publishControlEvent({ userId: participant.userId, userAction: 'mute' })
      )
    }
  }

  const requestLowerHand = (): void => {
    if (!handIsRaised || participant.userId === undefined) return
    void dispatch(
      liveSessionSlice.actions.publishControlEvent({
        userId: participant.userId,
        userAction: 'stop_raising_hand',
      })
    )
  }

  const pinParticipant = (): void => {
    if (participant.userId === undefined) return
    void dispatch(liveSessionSlice.actions.pinParticipant({ userId: participant.userId }))
    void dispatch(Logging.liveSession.participantPinned())
  }

  const unpinParticipant = (): void => {
    if (participant.userId === undefined) return
    void dispatch(liveSessionSlice.actions.unpinParticipant({ userId: participant.userId }))
    void dispatch(Logging.liveSession.participantUnpinned())
  }

  const canPinMore = useSelector(selectCanPinMore)

  const requestToggleFacilitator = (): void => {
    if (participant.userId === undefined) return

    if (isParticipantFacilitator) {
      void dispatch(removeFacilitator({ userId: participant.userId, liveSessionId }))
      void dispatch(Logging.liveSession.facilitatorRemoved())
    } else {
      void dispatch(addFacilitator({ userId: participant.userId, liveSessionId }))
      void dispatch(Logging.liveSession.facilitatorAdded())
    }
  }

  const requestMoveBreakoutRoom = (userId: UserId, breakoutRoomId: NanoId12 | undefined): void => {
    if (breakoutSession === undefined) return

    void dispatch(
      liveSessionSlice.actions.updateBreakoutSession(
        breakoutRooms.moveParticipant(allBreakoutRooms, userId, breakoutRoomId)
      )
    )
  }

  const statusText = useMemo(() => {
    const statusLabel = !isInCorrectRoom
      ? t('live.changing-room')
      : response?.correct === true
        ? t('dictionary.correct')
        : response?.correct === false
          ? t('dictionary.incorrect')
          : response?.skipped === true
            ? t('dictionary.skipped')
            : response?.response !== undefined
              ? response.response
              : undefined

    if (!isParticipantFacilitator) return statusLabel

    return [statusLabel, t('live.facilitator')].filter(Boolean).join(` ${narrowDotSeparator} `)
  }, [response, isParticipantFacilitator, isInCorrectRoom, t])

  const showWarning = useShowBadNetwork(participant.agoraUID)

  if (user === undefined) return <LoadingUser />

  return (
    <ParticipantWrapper>
      <AvatarWrapper>
        {handIsRaised && participant.userId !== undefined && (
          <RaisingHandWrapper>
            <RaisingHand userId={participant.userId} showOnlyOrder={isUserFacilitator} />
          </RaisingHandWrapper>
        )}
        <View
          animated
          animate={{ opacity: isInCorrectRoom ? 1 : [1, 0.4, 1] }}
          transition={{ duration: 2, ease: 'easeInOut', repeat: Infinity, repeatDelay: 0.2 }}
        >
          <SquareAvatar
            size='small'
            firstName={user.firstName}
            lastName={user.lastName}
            image={user.avatar}
            color={user.avatarColor}
          />
        </View>
      </AvatarWrapper>
      <View direction='column' grow gap='none'>
        <View>
          <TruncatedText lines={1} size='small' bold>
            {isMe ? capitalize(t('dictionary.you')) : getUserName(user) ?? ''}
          </TruncatedText>
        </View>
        {statusText !== undefined && (
          <View gap='4'>
            {response && <EvaluationIndicator response={response} />}
            <TruncatedText color='foreground/muted' lines={1} size='micro'>
              {statusText}
            </TruncatedText>
          </View>
        )}
      </View>
      {isPinned && <Icon size='size-14' iconId='pin--filled' color='black' />}
      {showWarning && (
        <Tooltip title={t('live.poor-internet-connection')}>
          <Icon iconId='wifi--off' />
        </Tooltip>
      )}
      <AudioIndicatorWrapper agoraUID={participant.agoraUID} bgColor='grey25' />
      {isUserFacilitator && participant.userId !== undefined && (
        <>
          <IconMenu
            size='small'
            variant='transparent'
            iconId='overflow-menu--vertical'
            menuItems={[
              {
                id: 'mute',
                type: 'label',
                disabled: audioMuted,
                icon: 'microphone--off',
                label: t('live.mute'),
              },
              {
                id: 'lower-hand',
                type: 'label',
                disabled: !handIsRaised,
                icon: 'hand',
                label: t('live.people.lower-hand'),
              },
              {
                id: 'pin',
                type: 'label',
                disabled: !isPinned && !canPinMore,

                icon: isPinned ? 'pin--filled' : 'pin',
                label: isPinned ? t('live.unpin') : t('live.pin-for-everyone'),
              },
              {
                id: 'toggleFacilitator',
                type: 'label',
                icon: 'education',
                label: isParticipantFacilitator
                  ? t('live.remove-as-facilitator')
                  : t('live.make-facilitator'),
              },
              {
                id: 'moveToRoomGroup',
                type: 'group',
                label: t('live.breakout-rooms.move-to-breakout-room'),
                hidden: breakoutSession === undefined,
                menuItems: [
                  {
                    type: 'label',
                    id: 'main-room',
                    icon: 'user--group',
                    label: t('live.main-room'),
                  },
                  ...allBreakoutRooms.map<LabelMenuItem>(room => ({
                    type: 'label',
                    id: room.id,
                    icon: 'user--group',
                    label: t('live.breakout-rooms.move-to-named-room', { name: room.title }),
                    disabled:
                      participant.userId === undefined || room.participants.includes(participant.userId),
                  })),
                ],
              },
            ]}
            onSelect={item => {
              switch (item.id) {
                case 'mute':
                  return requestMute()
                case 'lower-hand':
                  return requestLowerHand()
                case 'pin':
                  if (isPinned) {
                    unpinParticipant()
                  } else {
                    pinParticipant()
                  }
                  return

                case 'toggleFacilitator':
                  return requestToggleFacilitator()
                case 'main-room':
                  if (participant.userId !== undefined) {
                    requestMoveBreakoutRoom(participant.userId, undefined)
                  }
                  return
                default: {
                  const roomId = NanoId12.parse(item.id)
                  if (participant.userId !== undefined) {
                    requestMoveBreakoutRoom(participant.userId, roomId)
                  }
                }
              }
            }}
          />
        </>
      )}
    </ParticipantWrapper>
  )
}

export const participantUniqueId = (participant: Participant): string => participant.agoraUID

export const ListItem: React.FC<{
  participant: Participant
  showRaisedHand?: boolean
  response?: Response
}> = props => {
  const participant = props.participant
  return <ParticipantListItem {...props} participant={participant} />
}
