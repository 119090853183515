import { useAtomValue } from 'jotai'
import { useMemo, useState } from 'react'
import { useLiveSessionIdContextIfAvailable } from 'sierra-client/components/liveV2/live-session-id-provider'
import {
  setCardProgressCompleted,
  setCardProgressStarted,
  setCardProgressViewedAndCompleted,
} from 'sierra-client/state/card-progress/actions'
import { selectPlacementTestProgressState } from 'sierra-client/state/card-progress/selectors'
import { selectNodeParentFolderId } from 'sierra-client/state/flexible-content/selectors'
import { useDispatch, useSelector } from 'sierra-client/state/hooks'
import { FCC } from 'sierra-client/types'
import { SetCardProgressContext } from 'sierra-client/views/flexible-content/progress-tracking/context'
import { DefaultProgressTracker } from 'sierra-client/views/flexible-content/progress-tracking/default-progress-tracker'
import { isPlacementTestModalOpen } from 'sierra-client/views/self-paced/placement-test/atoms'
import { CreateContentId } from 'sierra-domain/api/nano-id'
import { ScopedLiveSessionId } from 'sierra-domain/collaboration/types'
import { File } from 'sierra-domain/flexible-content/types'

export const SetCardProgressProvider: FCC<{
  file: File
  courseId: CreateContentId
}> = ({ children, file, courseId }) => {
  const dispatch = useDispatch()
  const liveContext = useLiveSessionIdContextIfAvailable()
  const liveSessionId = liveContext ? ScopedLiveSessionId.extractId(liveContext.liveSessionId) : undefined
  const parentFolder = useSelector(state => selectNodeParentFolderId(state, courseId, file.id))

  const placementTestModalOpen = useAtomValue(isPlacementTestModalOpen)

  const isPlacementTestCompleted =
    useSelector(state => selectPlacementTestProgressState(state, courseId))(parentFolder)?.state !== 'open'

  // Events are aborted while the placement test is open
  // * Additional logic in placement-test/modal
  const value = useMemo(
    () => ({
      setCardStarted: () => {
        if (!isPlacementTestCompleted || placementTestModalOpen) {
          return
        }

        void dispatch(setCardProgressStarted({ fileId: file.id, courseId, liveSessionId }))
      },
      setCardCompleted: () => {
        if (!isPlacementTestCompleted || placementTestModalOpen) {
          return
        }

        void dispatch(setCardProgressCompleted({ fileId: file.id, courseId, liveSessionId }))
      },
      setCardViewedAndCompleted: () => {
        if (!isPlacementTestCompleted || placementTestModalOpen) {
          return
        }

        void dispatch(setCardProgressViewedAndCompleted({ fileId: file.id, courseId, liveSessionId }))
      },
    }),
    [dispatch, file.id, courseId, liveSessionId, isPlacementTestCompleted, placementTestModalOpen]
  )

  return (
    <SetCardProgressContext.Provider value={value}>
      <DefaultProgressTracker file={file} />
      {children}
    </SetCardProgressContext.Provider>
  )
}

export const ProhibitCardProgress: FCC = ({ children }) => {
  const [value] = useState(() => {
    function prohibitProgressReporting(): void {
      throw new Error(`Tried to set progress inside a ProhibitCardProgressProvider, was this intentional?`)
    }

    return {
      setCardStarted: prohibitProgressReporting,
      setCardCompleted: prohibitProgressReporting,
      setCardViewedAndCompleted: prohibitProgressReporting,
    }
  })

  return <SetCardProgressContext.Provider value={value}>{children}</SetCardProgressContext.Provider>
}

export { useSetCardProgress } from 'sierra-client/views/flexible-content/progress-tracking/context'
