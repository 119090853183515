import { useTranslation } from 'sierra-client/hooks/use-translation'
import { IconButton, Text, View } from 'sierra-ui/primitives'
import { focusRing } from 'sierra-ui/utils'
import styled from 'styled-components'

const CloseIconButton = styled(IconButton)`
  &:focus-visible {
    ${focusRing}
  }
`

type TabTopProps = {
  title: string
  onClose: () => void
}

export const TabTop: React.FC<TabTopProps> = ({ title, onClose }) => {
  const { t } = useTranslation()

  return (
    <View justifyContent='space-between' marginBottom={'12'}>
      <Text as='h1' size='large' bold>
        {title}
      </Text>
      <CloseIconButton
        variant='transparent'
        color='foreground/muted'
        aria-label={t('dictionary.close')}
        onClick={onClose}
        iconId='close'
      />
    </View>
  )
}
