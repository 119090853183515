import { insightsDashboardFilterAppliedLogger } from 'sierra-client/features/insights/logger'
import { useFilterWithQueryState } from 'sierra-client/lib/filter/use-filter'
import { useDispatch } from 'sierra-client/state/hooks'
import { FilterDomain, TemplateId } from 'sierra-domain/api/insights'
import { NanoId12 } from 'sierra-domain/api/nano-id'
import { Filter, andAll } from 'sierra-domain/filter/datatype/filter'
import { useOnChanged } from 'sierra-ui/utils'

const emptyFilter = andAll([])

const getPersistedDashboardFilterLocalStorageKey = (dashboardId: string): string =>
  `insights-dashboard-filter-${dashboardId}`

const defaultFilter = { type: 'filter.and', filters: [] } as const satisfies Filter

export const useDashboardFilter = ({
  dashboardId,
  filterDomains,
  initFilter,
}: {
  dashboardId: NanoId12 | TemplateId
  filterDomains: FilterDomain[] | undefined
  initFilter?: Filter
}): [filter: Filter, (newFilter: Filter) => void] => {
  const dispatch = useDispatch()

  const { ctx: dashboardFilterContext, filter: dashboardFilter } = useFilterWithQueryState(
    defaultFilter,
    filterDomains,
    undefined,
    'filter'
  )

  const setFilter = (newFilter: Filter): void => {
    dashboardFilterContext.update(() => newFilter)
    localStorage.setItem(getPersistedDashboardFilterLocalStorageKey(dashboardId), JSON.stringify(newFilter))
    if (JSON.stringify(newFilter) !== JSON.stringify(emptyFilter)) {
      void dispatch(insightsDashboardFilterAppliedLogger({ dashboardId }))
    }
  }

  useOnChanged(
    prev => {
      const persistedForNewId = localStorage.getItem(getPersistedDashboardFilterLocalStorageKey(dashboardId))
      if (persistedForNewId !== null) {
        setFilter(JSON.parse(persistedForNewId))
      } else if (initFilter !== undefined) {
        setFilter(initFilter)
      } else if (prev !== undefined) {
        setFilter(emptyFilter)
      }
    },
    [dashboardId]
  )

  return [dashboardFilter, setFilter]
}
