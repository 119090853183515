import { TranslationKey } from 'sierra-client/hooks/use-translation/types'
import { manageUrl } from 'sierra-client/views/workspace/utils/urls'
import { CourseSummary, PathWithExtra } from 'sierra-domain/api/admin'
import { CourseKind } from 'sierra-domain/api/common'
import { LearnEntity } from 'sierra-domain/api/entities'
import { CourseId, PathId } from 'sierra-domain/api/nano-id'
import { isCourseGroupType } from 'sierra-domain/api/teamspace'
import { ProgramId } from 'sierra-domain/api/uuid'
import { AssetContext } from 'sierra-domain/asset-context'
import { ImageUnion } from 'sierra-domain/content/v2/content'
import { assertNever, isDefined } from 'sierra-domain/utils'
import { IconId } from 'sierra-ui/components'
import { z } from 'zod'

export type ContentType = 'course' | 'path' | 'homework' | 'program'
export const ContentClassification = z.enum(['path', 'course', 'live', 'external'])
export type ContentClassification = z.infer<typeof ContentClassification>

export const ContentItem = z.object({
  id: z.string(),
  image: ImageUnion.optional(),
  contentType: z.enum(['course', 'path', 'homework', 'program']),
  courseKind: CourseKind.optional(),
  title: z.string(),
  learnersCount: z.number(),
  createdAt: z.string(),
  duration: z.number().optional(),
  tags: z.array(z.string()),
  isFeatured: z.boolean(),
  isCourseEdition: z.boolean(),
  courseGroupId: z.string().optional(),
  isPublicVisibility: z.boolean(),
  readOnly: z.boolean().optional(),
})

export type ContentItem = z.infer<typeof ContentItem>

export type ContentClassificationData = {
  classification: ContentClassification
  translationKey: TranslationKey
  iconId: IconId
}

export type ContentFilter = {
  contentClassification?: ContentClassification
  /// The course need to have at least one of the given tags
  tagIds: string[]
}

export function getLearnEntityName(type: LearnEntity['entityType']): TranslationKey {
  switch (type) {
    case 'scorm':
    case 'scorm:course-group':
    case 'link':
    case 'linkedin':
      return 'dictionary.external'
    case 'live-session':
      return 'dictionary.live'
    case 'native:course-group':
      return 'dictionary.course-singular'
    case 'native:event-group':
      return 'dictionary.physical-event'
    case 'native:live':
      return 'dictionary.live'
    case 'native:self-paced':
      return 'dictionary.course-singular'
    case 'path':
      return 'dictionary.path-singular'
    case 'program':
      return 'dictionary.program-singular'
  }
}

export const getContentClassificationData = ({
  contentType,
  courseKind,
  isCourseEdition,
  isTemplate,
}: { isTemplate?: boolean } & Pick<
  ContentItem,
  'contentType' | 'courseKind' | 'isCourseEdition'
>): ContentClassificationData => {
  const external: (CourseKind | undefined)[] = ['scorm', 'link', 'linkedin', 'scorm:course-group']
  const isExternal = external.includes(courseKind)

  if (Boolean(isTemplate)) {
    return {
      classification: 'course',
      iconId: 'template',
      translationKey: 'dictionary.template',
    }
  }

  if (contentType === 'path') {
    return { classification: 'path', iconId: 'path', translationKey: 'dictionary.path-singular' }
  }

  if (isCourseEdition) {
    return {
      classification: isExternal ? 'external' : 'course',
      iconId: isExternal ? 'link' : 'bookmark--filled',
      translationKey: isExternal ? 'dictionary.external-edition-singular' : 'dictionary.course-singular',
    }
  }

  if (courseKind === 'native:event-group') {
    return {
      classification: 'course',
      iconId: 'calendar',
      translationKey: 'dictionary.in-person',
    }
  }

  if (isDefined(courseKind) && isCourseGroupType(courseKind)) {
    return {
      classification: 'course',
      iconId: isExternal ? 'link' : 'bookmark--stack--filled',
      translationKey: 'dictionary.multiple-editions',
    }
  }

  if (courseKind === 'native:live') {
    return { classification: 'live', iconId: 'play--circle--filled', translationKey: 'dictionary.live' }
  }

  if (isExternal) {
    return {
      classification: 'external',
      iconId: 'link',
      translationKey: 'dictionary.external',
    }
  }

  return {
    classification: 'course',
    iconId: 'bookmark--filled',
    translationKey: 'dictionary.course-singular',
  }
}

export const filterContent = <C extends ContentItem = ContentItem, F extends ContentFilter = ContentFilter>(
  allContent: C[],
  filter: F
): C[] => {
  if (filter.contentClassification === undefined && filter.tagIds.length === 0) return allContent

  return allContent.filter(content => {
    if (
      filter.contentClassification !== undefined &&
      filter.contentClassification !== getContentClassificationData(content).classification
    ) {
      return false
    }

    if (filter.tagIds.length > 0 && !filter.tagIds.some(s => content.tags.includes(s))) {
      return false
    }

    return true
  })
}

export const parsePathToContent = (path: PathWithExtra): ContentItem => ({
  id: path.pathId,
  contentType: 'path',
  courseKind: undefined,
  title: path.data.title,
  image: path.data.image,
  learnersCount: path.assignmentsCount,
  createdAt: path.createdAt,
  duration: path.duration,
  tags: path.tags.map(x => x.id),
  isFeatured: false,
  isCourseEdition: false,
  isPublicVisibility: path.data.settings.isPathVisible ?? false,
})

export const parseCourseToContent = (course: CourseSummary): ContentItem => ({
  id: course.courseId,
  contentType: 'course',
  courseKind: course.kind,
  title: course.title,
  image: course.image,
  learnersCount: course.enrollmentCount,
  createdAt: course.publishedAt,
  duration: course.timeTotal,
  tags: course.tags,
  isFeatured: false,
  isCourseEdition: course.isCourseEdition,
  courseGroupId: course.courseGroupId,
  isPublicVisibility: course.isPublicVisibility,
})

export const bareContentId = (contentId: string): string => contentId.split(':')[1] ?? contentId

export const getHrefForContentDetails = ({
  id,
  contentType,
  courseKind,
}: {
  id: string
  contentType: ContentType
  // TODO: This is only nullable because the type this comes from isn't a discriminated union and can
  //  represent a path.
  courseKind?: CourseKind
}): string => {
  const bareId = bareContentId(id)
  switch (contentType) {
    case 'course':
      if (courseKind === undefined) {
        return `/manage/courses/${bareId}`
      } else {
        return manageUrl({ type: courseKind, id: bareId })
      }
    case 'path':
      return manageUrl({ type: 'path', id: bareId })
    case 'homework':
      // Can't use manageUrl because it expects a courseId as well. Should fix that.
      return `/manage/exercises/${bareId}`
    case 'program':
      return `/manage/programs/${bareId}`
  }
}

export const getAssetContextForContentDetails = ({
  id,
  contentType,
}: {
  id: string
  contentType: ContentType
}): AssetContext => {
  const bareId = bareContentId(id)
  switch (contentType) {
    case 'course':
      return { type: 'course', courseId: CourseId.parse(bareId) }
    case 'path':
      return { type: 'path', pathId: PathId.parse(bareId) }
    case 'homework':
      return { type: 'homework' }
    case 'program':
      return { type: 'program', programId: ProgramId.parse(bareId) }
    default:
      assertNever(contentType)
  }
}

export const contentToCsv = (content: ContentItem): Record<string, string> => ({
  id: content.id,
  contentType: content.contentType,
  courseKind: content.courseKind ?? '',
  title: content.title,
  createdAt: content.createdAt,
  learnersCount: `${content.learnersCount}`,
  duration: content.duration?.toFixed(1) ?? '',
})
