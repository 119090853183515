import { useAtomValue } from 'jotai/index'
import React, { useCallback, useMemo } from 'react'
import { useGenerateAiFilter } from 'sierra-client/features/insights/api-hooks/use-generate-ai-filter'
import { toQueryRequest } from 'sierra-client/features/insights/api-hooks/use-query-analytics-data'
import {
  useInsightsDimensions,
  useInsightsViewsFromMeasures,
} from 'sierra-client/features/insights/hooks/use-insights-views'
import { FilterSelector } from 'sierra-client/features/insights/widget-builder/data-selectors/filter-selector'
import {
  MenuItemWithRef,
  dimensionsToMenuItem,
} from 'sierra-client/features/insights/widget-builder/data-selectors/single-dimension-selector'
import {
  WidgetBuilderState,
  buildWidgetFromWidgetBuilderState,
} from 'sierra-client/features/insights/widget-builder/widget-builder-state'
import { useTranslation } from 'sierra-client/hooks/use-translation'
import { askInsightsModelAtom } from 'sierra-client/state/settings'
import { GenerateFilterRequest, MeasureRef, ViewRef } from 'sierra-domain/api/insights'
import { Filter } from 'sierra-domain/filter/datatype/filter'
import { isCompleteWidget } from '../utils'

const emptyMenuItems: MenuItemWithRef[] = [] as const

export const WidgetFilterSelector: React.FC<{
  selectedFilter?: Filter
  onChange: (newFilter: Filter) => void
  visualisation: WidgetBuilderState['visualisation']
  selectedMeasures: MeasureRef[] | undefined
  selectedView?: ViewRef
  widgetBuilderState: WidgetBuilderState
}> = ({ onChange, selectedFilter, visualisation, selectedMeasures, selectedView, widgetBuilderState }) => {
  const { t } = useTranslation()

  const { data: viewData } = useInsightsViewsFromMeasures(selectedMeasures, selectedView)
  const filterDomains = viewData?.availableFilters
  const allDimensions = useInsightsDimensions()

  const menuItems: MenuItemWithRef[] = useMemo(() => {
    return allDimensions === undefined
      ? emptyMenuItems
      : allDimensions.flatMap(it =>
          dimensionsToMenuItem({
            dimensions: it,
            visualisation,
            t,
            isFilter: true,
            dimensionSelectorType: 'index',
            disabledByMetricLabelsMap: viewData?.disabledFiltersByMetricLabels,
            disabledDimensions: undefined,
          })
        )
  }, [allDimensions, t, viewData?.disabledFiltersByMetricLabels, visualisation])

  const generateFilterMutation = useGenerateAiFilter()

  const { data: insightsViews, isLoading } = useInsightsViewsFromMeasures(selectedMeasures, selectedView)
  const model = useAtomValue(askInsightsModelAtom)

  const generateFilter = useCallback(
    async (query: string): Promise<Filter | undefined> => {
      const builtWidget = buildWidgetFromWidgetBuilderState(widgetBuilderState)
      if (builtWidget === undefined) return Promise.reject('No widget built')
      const widgetQuery = toQueryRequest(builtWidget)
      if (isLoading) return Promise.reject('Loading')

      const filterRequest: GenerateFilterRequest = {
        filterQuery: query,
        viewIds: insightsViews.availableViews.map(view => view.id),
        widgetQuery: widgetQuery.query,
        model,
      }

      const response = await generateFilterMutation.mutateAsync(filterRequest)

      return response.filter
    },
    [widgetBuilderState, isLoading, insightsViews?.availableViews, model, generateFilterMutation]
  )

  return (
    <FilterSelector
      onChange={onChange}
      menuItems={menuItems}
      selectedFilter={selectedFilter}
      filterDomains={filterDomains}
      generateFilter={isCompleteWidget(widgetBuilderState) ? generateFilter : undefined}
    />
  )
}
