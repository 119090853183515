export const RichBadgeBackgroundSvg: React.FC = () => {
  const viewboxWidth = 224
  const viewboxHeight = 242
  const scaleX = 1 / viewboxWidth
  const scaleY = 1 / viewboxHeight

  return (
    <svg xmlns='http://www.w3.org/2000/svg' viewBox={`0 0 ${viewboxWidth} ${viewboxHeight}`}>
      <defs>
        <path
          id='rich-badge-background-path'
          d='M140 7.92824L195.923 40.2154C213.25 50.2189 223.923 68.706 223.923 88.7128V153.287C223.923 173.294 213.25 191.781 195.923 201.785L140 234.072C122.674 244.075 101.326 244.075 84 234.072L28.077 201.785C10.7505 191.781 0.0769653 173.294 0.0769653 153.287V88.7128C0.0769653 68.706 10.7505 50.2189 28.077 40.2154L84 7.92824C101.326 -2.0752 122.674 -2.0752 140 7.92824Z'
        />

        <clipPath
          id='rich-badge-background-clip'
          clipPathUnits='objectBoundingBox'
          transform={`scale(${scaleX}, ${scaleY})`}
        >
          <use href='#rich-badge-background-path' />
        </clipPath>
      </defs>
    </svg>
  )
}

export const RichBadgeForegroundSvg: React.FC = () => {
  const viewboxWidth = 224
  const viewboxHeight = 242
  const scaleX = 1 / viewboxWidth
  const scaleY = 1 / viewboxHeight

  return (
    <svg
      width='100%'
      height='100%'
      xmlns='http://www.w3.org/2000/svg'
      viewBox={`0 0 ${viewboxWidth} ${viewboxHeight}`}
      fill='transparent'
    >
      <defs>
        <path
          id='rich-badge-foreground-path'
          d='M195.923 40.2154L140 7.92824C122.674 -2.0752 101.326 -2.0752 84 7.92824L28.077 40.2154C10.7505 50.2189 0.0769653 68.706 0.0769653 88.7128V153.287C0.0769653 173.294 10.7505 191.781 28.077 201.785L84 234.072C101.326 244.075 122.674 244.075 140 234.072L195.923 201.785C213.25 191.781 223.923 173.294 223.923 153.287V88.7128C223.923 68.706 213.25 50.2189 195.923 40.2154ZM88 14.8564C102.851 6.28207 121.149 6.28207 136 14.8564L191.923 47.1436C206.774 55.718 215.923 71.5641 215.923 88.7128V153.287C215.923 155.895 215.711 158.473 215.3 161H8.69967C8.28857 158.473 8.07697 155.895 8.07697 153.287V88.7128C8.07697 71.5641 17.2257 55.718 32.077 47.1436L88 14.8564Z'
        />

        <clipPath
          id='rich-badge-foreground-clip'
          clipPathUnits='objectBoundingBox'
          transform={`scale(${scaleX}, ${scaleY})`}
        >
          <use href='#rich-badge-foreground-path' />
        </clipPath>
      </defs>

      <use href='#rich-badge-foreground-path' />
    </svg>
  )
}

export const SimpleBadgeBackgroundSvg: React.FC = () => {
  const viewboxWidth = 52
  const viewboxHeight = 56
  const scaleX = 1 / viewboxWidth
  const scaleY = 1 / viewboxHeight

  return (
    <svg xmlns='http://www.w3.org/2000/svg' viewBox={`0 0 ${viewboxWidth} ${viewboxHeight}`}>
      <defs>
        <path
          id='simple-badge-background-path'
          d='M32.0041 3.76205L43.9959 10.6831C47.7112 12.8274 50 16.7903 50 21.0789V34.921C50 39.2096 47.7112 43.1725 43.9959 45.3168L32.0041 52.2379C28.2888 54.3822 23.7112 54.3822 19.9959 52.2379L8.00413 45.3168C4.28876 43.1725 2 39.2096 2 34.921V21.0789C2 16.7903 4.28876 12.8274 8.00413 10.6831L19.9959 3.76205C23.7112 1.61773 28.2888 1.61773 32.0041 3.76205Z'
        />

        <clipPath
          id='simple-badge-background-clip'
          clipPathUnits='objectBoundingBox'
          transform={`scale(${scaleX}, ${scaleY})`}
        >
          <use href='#simple-badge-background-path' />
        </clipPath>
      </defs>
    </svg>
  )
}

export const SimpleBadgeForegroundSvg: React.FC = () => {
  const viewboxWidth = 52
  const viewboxHeight = 56

  return (
    <svg xmlns='http://www.w3.org/2000/svg' viewBox={`0 0 ${viewboxWidth} ${viewboxHeight}`}>
      <path
        id='simple-badge-foreground-path'
        d='M43.9959 11.8384L31.0049 4.34056C27.9079 2.55315 24.0921 2.55315 20.9951 4.34056L8.00408 11.8384C4.90722 13.6257 3 16.9285 3 20.5022V35.4978C3 39.0715 4.90722 42.3743 8.00408 44.1616L20.9951 51.6594C24.0921 53.4469 27.9079 53.4469 31.0049 51.6594L43.9959 44.1616C47.0928 42.3743 49 39.0715 49 35.4978V20.5022C49 16.9285 47.0928 13.6257 43.9959 11.8384ZM32.5045 1.74226L45.4955 9.24005C49.5205 11.5631 52 15.8562 52 20.5022V35.4978C52 40.1438 49.5205 44.4369 45.4955 46.7599L32.5045 54.2577C28.4795 56.5808 23.5205 56.5808 19.4955 54.2577L6.50447 46.7599C2.47949 44.4369 0 40.1438 0 35.4978V20.5022C0 15.8562 2.47949 11.5631 6.50447 9.24005L19.4955 1.74226C23.5205 -0.580754 28.4795 -0.580754 32.5045 1.74226Z'
      />
    </svg>
  )
}
