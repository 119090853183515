import React, { useState } from 'react'
import { useResolveAssetWithoutFallback } from 'sierra-client/hooks/use-resolve-asset'
import { useTranslation } from 'sierra-client/hooks/use-translation'
import { createThumbHashUrlFromImage } from 'sierra-client/utils/image-thumbhash'
import { UploadImageModal } from 'sierra-client/views/upload-image-modal/upload-image-modal'
import {
  useDefaultImageFitOption,
  useImageFitOptions,
} from 'sierra-client/views/v3-author/bullet-card/image-fit-options'
import { updateNodeWithId } from 'sierra-client/views/v3-author/command'
import {
  useEditorAssetContext,
  useEditorReadOnly,
} from 'sierra-client/views/v3-author/editor-context/editor-context'
import { ImageSelector } from 'sierra-client/views/v3-author/images/image-selector'
import { useEditorScrollContainerHeight } from 'sierra-client/views/v3-author/measure-editor-scroll-container'
import { assertElementType } from 'sierra-client/views/v3-author/queries'
import { RenderingContext } from 'sierra-client/views/v3-author/rendering-context'
import { SlateFC } from 'sierra-client/views/v3-author/slate'
import { CardGrid } from 'sierra-client/views/v3-author/wrapper'
import type { AssetContext } from 'sierra-domain/asset-context'
import { Entity } from 'sierra-domain/entity'
import { ImageFit } from 'sierra-domain/flexible-content/image-fit'
import { ImageData } from 'sierra-domain/flexible-content/types'
import { BulletCard as SlateBulletCard } from 'sierra-domain/v3-author'
import { IconButton, View } from 'sierra-ui/primitives'
import { DefaultDropdownTrigger, SingleSelectDropdown } from 'sierra-ui/primitives/menu-dropdown'
import { DarkTokenProvider, token } from 'sierra-ui/theming'
import { v2_breakpoint } from 'sierra-ui/theming/breakpoints'
import { useSlateStatic } from 'slate-react'
import styled, { css } from 'styled-components'

export const BulletCardItem: SlateFC = ({ children, element }) => {
  assertElementType('bullet-card-item', element)

  return <>{children}</>
}

const BulletCardWrapper = styled.div<{
  $variant: 'leading-image' | 'trailing-image' | 'full-width-image'
}>`
  display: flex;
  flex-direction: column;
  justify-content: center;

  background-color: transparent;
  padding-top: 5rem;
  padding-bottom: 5rem;

  ${p =>
    p.$variant === 'full-width-image' &&
    css`
      z-index: 1;
    `}

  grid-column: ${p =>
    ({
      'trailing-image': '2 / span 5',
      'leading-image': '9 / span 5',
      'full-width-image': '1 / span 14',
    })[p.$variant]};

  @media screen and (max-width: ${v2_breakpoint.desktop_small}) {
    grid-column: 2 / span 12;
    grid-row: 2;
    padding-top: 1rem;
    padding-bottom: 4rem;
    justify-content: flex-start;
  }
`
export const BulletCardItemContainer = styled.div`
  position: relative;

  & + & {
    margin-top: 1rem;
  }
`

const ImageWrapperControls = styled.div`
  display: flex;
  justify-content: space-between;
  width: 100%;
  padding: 8px;
`

const Blur = css`
  background-color: rgba(0, 0, 0, 0.1);
  backdrop-filter: blur(10px);
  transition: background-color 250ms;

  &:hover {
    && {
      background-color: rgba(0, 0, 0, 0.5);
    }
  }
`

const BlurIconButton = styled(IconButton)`
  ${Blur}
`

type ImageWrapperProps = {
  $height: number
  readOnly?: boolean
  $url?: string
  $placeholderUrl?: string
  $variant: 'leading-image' | 'trailing-image' | 'full-width-image'
  $imageFit: 'cover' | 'contain'
}
const borderRadius = '8px'
const ImageWrapper = styled.div.attrs({ contentEditable: false })<ImageWrapperProps>`
  ${p =>
    p.$imageFit === 'contain' &&
    css`
      ::before {
        inset: 0;
        content: '';
        position: absolute;
        z-index: 0;
        border-radius: ${borderRadius};
        background-image: url(${p.$url}), url(${p.$placeholderUrl});
        filter: blur(100px);
        transform: scale(3);
        overflow: hidden;
        user-select: none;
        transition: background-image 300ms ease-out;
        will-change: background-image;
      }
    `}
  ::after {
    inset: 0;
    content: '';
    z-index: 1;
    position: absolute;
    background-image: url(${p => p.$url}), url(${p => p.$placeholderUrl});
    background-size: ${p => p.$imageFit};
    background-repeat: no-repeat;
    background-position: center;
    border-radius: ${borderRadius};
    user-select: none;
    transition: background-image 300ms ease-out;
    will-change: background-image;
  }

  ${ImageWrapperControls} {
    margin-top: auto;
    bottom: 0;
    left: 0;
    right: 0;
    position: absolute;
    z-index: 2;
  }

  overflow: hidden;

  position: sticky;
  height: ${p => p.$height - 16}px;
  width: calc(100% - 4px);
  margin: 8px 4px;
  top: 8px;
  border-radius: 8px;
  user-select: none;

  ${p =>
    p.$variant === 'full-width-image' &&
    css`
      position: absolute;
      z-index: 0;
    `}

  grid-column: ${p =>
    ({
      'leading-image': '1 / span 7',
      'trailing-image': '8 / span 7',
      'full-width-image': '1 / span 14',
    })[p.$variant]};

  @media screen and (max-width: ${v2_breakpoint.desktop_small}) {
    aspect-ratio: 16 / 9;
    grid-column: 1 / -1;
    grid-row: 1;
    position: relative;
    height: unset;
    width: calc(100% - 8px);
    margin: 0.5rem;
    margin-top: unset;
    top: 0.5rem;
  }

  @media screen and (max-width: ${v2_breakpoint.tablet}) {
    width: calc(100% - 16px);
  }
`

type Variant = 'leading-image' | 'trailing-image' | 'full-width-image'

const StyledImageSelector = styled(ImageSelector)<{
  $variant: Variant
  $height: number
}>`
  display: flex;
  position: sticky;
  width: calc(100% - 16px);
  margin: 8px;
  top: 8px;
  border-radius: 8px;
  height: ${p => p.$height - 16}px;

  user-select: none;
  grid-column: ${p =>
    ({
      'leading-image': '1 / span 7',
      'trailing-image': '8 / span 7',
      'full-width-image': '1 / span 14',
    })[p.$variant]};

  & {
    @media screen and (max-width: ${v2_breakpoint.desktop_small}) {
      grid-column: 1 / -1;
      grid-row: 1;
      margin: 0.5rem;
      top: 0.5rem;
      height: 400px;
    }
  }
`

const defaultVariant = 'trailing-image' as const

const DropdownTrigger = styled(DefaultDropdownTrigger)<{ $hover: boolean }>`
  ${Blur}
  ${p => p.$hover && 'background-color: rgba(0, 0, 0, 0.5);'}
  color: ${token('foreground/primary')};
`

const Image: React.FC<
  Pick<Entity<SlateBulletCard>, 'id' | 'variant' | 'image' | 'imageFit'> & {
    assetContext: AssetContext
  }
> = ({ id, imageFit, variant, image, assetContext }) => {
  const { t, dynamicT } = useTranslation()
  const editor = useSlateStatic()

  const url = useResolveAssetWithoutFallback({ assetContext, image, size: 'default' })

  const placeholderUrl = createThumbHashUrlFromImage(image)

  const readOnly = useEditorReadOnly()
  const height = useEditorScrollContainerHeight()

  const [openModal, setOpenModal] = useState(false)

  const handleUploadDone = (newImage: ImageData['image']): void => {
    updateNodeWithId(editor, id, { image: newImage })
    setOpenModal(false)
  }

  const imageFitOptions = useImageFitOptions()
  const defaultImageFitOption = useDefaultImageFitOption()
  const imageFitOption = imageFitOptions.find(option => imageFit === option.id) ?? defaultImageFitOption

  if (url !== undefined) {
    return (
      <ImageWrapper
        $height={height}
        contentEditable={false}
        $variant={variant ?? defaultVariant}
        $url={url}
        $placeholderUrl={placeholderUrl}
        readOnly={readOnly}
        $imageFit={imageFitOption.id}
      >
        {/* We need an empty HTML element here, otherwise slate will make this block selectable */}
        <span />

        {!readOnly && (
          <DarkTokenProvider>
            <ImageWrapperControls>
              <SingleSelectDropdown
                selectedItem={imageFitOption}
                menuItems={imageFitOptions}
                onSelect={option => {
                  const imageFit = ImageFit.parse(option.id)
                  updateNodeWithId(editor, id, { imageFit })
                }}
                renderTrigger={open => (
                  <DropdownTrigger $hover={open} grow={false}>
                    {imageFitOption.label !== undefined && dynamicT(imageFitOption.label)}
                  </DropdownTrigger>
                )}
              />
              <View gap='8'>
                <>
                  <BlurIconButton
                    color={'foreground/primary'}
                    tooltip={t('author.image.change')}
                    iconId='image'
                    onClick={() => setOpenModal(true)}
                  />
                  <UploadImageModal
                    open={openModal}
                    onUploadDone={(newImage: ImageData['image']) => handleUploadDone(newImage)}
                    onClose={() => setOpenModal(false)}
                    assetContext={assetContext}
                  />
                </>

                <BlurIconButton
                  tooltip={t('author.image.remove')}
                  iconId='trash-can'
                  color={'foreground/primary'}
                  onClick={() => updateNodeWithId(editor, id, { image: undefined })}
                />
              </View>
            </ImageWrapperControls>
          </DarkTokenProvider>
        )}
      </ImageWrapper>
    )
  }

  if (!readOnly && openModal) {
    return (
      <>
        <StyledImageSelector
          $variant={variant ?? defaultVariant}
          $height={height}
          contentEditable={false}
          onOpen={() => setOpenModal(true)}
          fullscreen
          onUploadDone={(newImage: ImageData['image']) => handleUploadDone(newImage)}
          assetContext={assetContext}
        />
        <UploadImageModal
          open={openModal}
          onUploadDone={(newImage: ImageData['image']) => handleUploadDone(newImage)}
          onClose={() => setOpenModal(false)}
          assetContext={assetContext}
        />
      </>
    )
  }

  if (!readOnly) {
    return (
      <>
        <StyledImageSelector
          $variant={variant ?? defaultVariant}
          $height={height}
          contentEditable={false}
          fullscreen
          onOpen={() => setOpenModal(true)}
          onUploadDone={(newImage: ImageData['image']) => handleUploadDone(newImage)}
          assetContext={assetContext}
        />
      </>
    )
  }

  return null
}

const StyledCardGrid = styled(CardGrid)`
  @media screen and (max-width: ${v2_breakpoint.desktop_small}) {
    grid-template-rows: auto 1fr;
  }
`

export const BulletCard: SlateFC = ({ element, children }) => {
  assertElementType('bullet-card', element)
  const variant = element.variant ?? defaultVariant

  const assetContext = useEditorAssetContext()

  return (
    <RenderingContext withGrid={false} preventDrag={true} disableMenu={false}>
      <StyledCardGrid>
        {variant === 'leading-image' ? (
          <Image {...element} assetContext={assetContext} />
        ) : (
          <BulletCardWrapper $variant={variant}>{children}</BulletCardWrapper>
        )}

        {variant === 'leading-image' ? (
          <BulletCardWrapper $variant={variant}>{children}</BulletCardWrapper>
        ) : (
          <Image {...element} assetContext={assetContext} />
        )}
      </StyledCardGrid>
    </RenderingContext>
  )
}
