import React from 'react'
import { HeaderButtonGroupWrapper } from 'sierra-client/components/liveV2/header-buttons'
import { useCreatePageContext } from 'sierra-client/views/flexible-content/create-page-context'
import { LayoutWidthPicker } from 'sierra-client/views/flexible-content/editor/toolbar/layout-picker'
import { FileThemePicker } from 'sierra-client/views/flexible-content/file-theme-picker'
import { apply } from 'sierra-domain/editor/operations'
import { File } from 'sierra-domain/flexible-content/types'
import { CustomThemeName, PresetThemeName } from 'sierra-ui/theming/legacy-theme'

type GeneralCardToolbarProps = {
  file: File
  previewThemes?: (theme: undefined | CustomThemeName | PresetThemeName) => void
}

export const GeneralCardToolbar: React.FC<GeneralCardToolbarProps> = ({ file, previewThemes }) => {
  const { operationState } = useCreatePageContext()
  return (
    <HeaderButtonGroupWrapper gap='none'>
      <FileThemePicker previewThemes={previewThemes} file={file} />
      <LayoutWidthPicker
        onLayoutChange={layout =>
          apply(operationState, {
            type: 'update-files',
            fileIds: [file.id],
            update: file => {
              file.layout = layout
            },
          })
        }
        currentLayout={file.layout ?? 'narrow'}
      />
    </HeaderButtonGroupWrapper>
  )
}
