import { Input } from 'sierra-ui/components'
import { token } from 'sierra-ui/theming'
import styled from 'styled-components'

export const LegacyTokenInput = styled(Input)`
  color: ${token('foreground/primary')};
  input,
  textarea {
    border-color: ${token('border/default')};
    color: ${token('foreground/primary')};
    &:placeholder {
      color: ${token('foreground/muted')};
    }
  }
`
