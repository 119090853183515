import { useMemo } from 'react'
import { RenderFile, RenderFolder } from 'sierra-client/learn/learn-ui/file-view/helpers'
import { selectRecapFileIds } from 'sierra-client/state/flexible-content/recap-file'
import { selectFlexibleContent } from 'sierra-client/state/flexible-content/selectors'
import { useSelector } from 'sierra-client/state/hooks'
import { useFlexibleContentYDoc } from 'sierra-client/views/flexible-content/polaris-editor-provider/use-flexible-content-ydoc'
import { FileTree } from 'sierra-client/views/flexible-content/render-file-tree'
import { LiveContentId } from 'sierra-domain/api/nano-id'
import { SlateDocumentMap, serialize } from 'sierra-domain/collaboration/serialization'
import { FileId } from 'sierra-domain/flexible-content/identifiers'
import { File } from 'sierra-domain/flexible-content/types'

export const RecapFileTree: React.FC<{
  rootAsFolder?: boolean
  liveContentId: LiveContentId
  file: File
  goToFile: (fileId: FileId) => void
}> = props => {
  const { rootAsFolder = false } = props
  const flexibleContent = useSelector(state => selectFlexibleContent(state, props.liveContentId))

  const supportedFileIds = useSelector(state => selectRecapFileIds(state, props.liveContentId))

  const { yDoc } = useFlexibleContentYDoc()
  const slateDocumentMap: SlateDocumentMap = useMemo(
    () => serialize(yDoc)['']?.slateDocumentMap ?? {},
    [yDoc]
  )

  if (!flexibleContent) return null

  const root = flexibleContent.nodeMap['folder:root']
  if (root?.type !== 'folder') throw new Error('Root folder missing')

  return (
    <FileTree
      content={flexibleContent}
      rootAsFolder={rootAsFolder}
      renderFile={file => (
        <RenderFile
          flexibleContentId={props.liveContentId}
          isSelfPaced={false}
          disabled={!supportedFileIds.includes(file.id)}
          highlight={props.file.id === file.id}
          onClick={props.goToFile}
          node={file}
          slateDocumentMap={slateDocumentMap}
        />
      )}
      renderFolder={(folder, children) => (
        <RenderFolder flexibleContentId={props.liveContentId} folder={folder} currentCard={props.file}>
          {children}
        </RenderFolder>
      )}
    />
  )
}
