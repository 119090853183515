import { createAction, createAsyncThunk } from '@reduxjs/toolkit'
import _ from 'lodash'
import { errorLogger } from 'sierra-client/error/error-logger'
import { postWithUserErrorException } from 'sierra-client/state/api'
import { fetchCourseProgress } from 'sierra-client/state/card-progress/actions'
import {
  interactionToQuestionCard,
  questionCardResponseToInteraction,
} from 'sierra-client/state/self-paced/utils'
import { RootState } from 'sierra-client/state/types'
import { selectUserId } from 'sierra-client/state/user/user-selector'
import { BlockInteraction } from 'sierra-domain/api/block-interaction'
import { CreateContentId } from 'sierra-domain/api/nano-id'
import { InteractionContext } from 'sierra-domain/api/strategy-v2'
import { QuestionCard, QuestionCardResponseData } from 'sierra-domain/card/question-card'
import { Entity } from 'sierra-domain/entity'
import { FileId } from 'sierra-domain/flexible-content/identifiers'
import {
  XRealtimeStrategySelfPacedContentAssessmentGetAssessmentResponses,
  XRealtimeStrategySelfPacedContentQuestionCardGetQuestionCardResponse,
  XRealtimeStrategySelfPacedContentQuestionCardUpsertQuestionCardResponse,
  XRealtimeStrategySelfPacedContentUpsertBlockInteraction,
} from 'sierra-domain/routes'
import { isDefined } from 'sierra-domain/utils'
import { QuestionCardBody } from 'sierra-domain/v3-author'

type QuestionCardPayload<T = void> = {
  fileId: FileId
  contentId: CreateContentId
  questionExerciseId: string
  sessionId?: string
} & T

type AssessmentQuestionCardsPayload<T = void> = {
  fileId: FileId
  contentId: CreateContentId
  questionExerciseIds: string[]
  sessionId: string
} & T

export const submitQuestionCardResponse = createAsyncThunk<
  {
    questionCard: QuestionCard
    questionExerciseId: string
  },
  QuestionCardPayload<{
    element: Entity<QuestionCardBody>
    questionCardResponse: QuestionCardResponseData
  }>,
  { state: RootState }
>(
  'self-paced/question-card/upsert',
  async (
    { fileId, contentId, questionExerciseId, element, sessionId, questionCardResponse },
    { dispatch, getState }
  ) => {
    const userId = selectUserId(getState())
    const interaction = questionCardResponseToInteraction({ element, questionCardResponse, forSubmit: true })

    try {
      await postWithUserErrorException(
        XRealtimeStrategySelfPacedContentQuestionCardUpsertQuestionCardResponse,
        {
          fileId,
          contentId,
          questionExerciseId,
          interaction,
          interactionContext: sessionId !== undefined ? 'assessment' : 'course',
          sessionId,
        },
        dispatch
      ).catch(errorLogger.captureError)

      if (userId === undefined) {
        throw new Error('Could not get user id.')
      }

      const questionCard = interactionToQuestionCard({ element, userId, interaction, sessionId })

      if (questionCard === undefined) {
        throw new Error('Could not derive question card.')
      }

      await dispatch(
        fetchCourseProgress({
          courseId: contentId,
          fileId,
        })
      )

      return {
        questionExerciseId,
        questionCard,
      }
    } catch (e) {
      throw new Error('Could not post question card response as interaction.')
    }
  }
)

export const getAssessmentQuestionCardResponses = createAsyncThunk<
  {
    questionExerciseIds: string[]
    questionCards: QuestionCard[]
  },
  AssessmentQuestionCardsPayload<{
    elements: Entity<QuestionCardBody>[]
  }>,
  { state: RootState }
>(
  'self-paced/assessment-card/get-question-card-responses',
  async ({ contentId, elements, fileId, questionExerciseIds, sessionId }, { dispatch, getState }) => {
    const userId = selectUserId(getState())
    try {
      const { questionCardInteractions } = await postWithUserErrorException(
        XRealtimeStrategySelfPacedContentAssessmentGetAssessmentResponses,
        { fileId, contentId, questionExerciseIds, sessionId },
        dispatch
      )

      if (userId === undefined) {
        throw new Error('Could not get user id.')
      }

      // We consider placement test responses and assessment test responses
      const latestInteractions = _.uniqBy(
        _.orderBy(questionCardInteractions, 'timestamp', 'desc').filter(
          e => e.interactionContext === 'assessment'
        ),
        it => it.interaction.questionId
      ).map(interaction => ({
        interaction,
        element: elements.find(element => element.id === interaction.interaction.questionId),
      }))

      const questionCards = latestInteractions
        .map(({ interaction, element }) => {
          if (element === undefined) return undefined
          return interactionToQuestionCard({
            element,
            userId,
            interaction: interaction.interaction,
            sessionId,
          })
        })
        .filter(isDefined)

      return {
        questionExerciseIds,
        questionCards,
      }
    } catch (e) {
      throw new Error('Could not fetch question card response.')
    }
  }
)

export const getQuestionCardResponse = createAsyncThunk<
  {
    questionExerciseId: string
    questionCard: QuestionCard | undefined
  },
  QuestionCardPayload<{
    element: Entity<QuestionCardBody>
  }>,
  { state: RootState }
>(
  'self-paced/question-card/get',
  async ({ fileId, contentId, element, sessionId, questionExerciseId }, { dispatch, getState }) => {
    const userId = selectUserId(getState())
    try {
      const { questionCardInteractions } = await postWithUserErrorException(
        XRealtimeStrategySelfPacedContentQuestionCardGetQuestionCardResponse,
        { fileId, contentId, questionExerciseId, sessionId },
        dispatch
      )

      if (userId === undefined) {
        throw new Error('Could not get user id.')
      }

      // We consider placement test responses and assessment test responses
      const latestInteraction = _.orderBy(questionCardInteractions, 'timestamp', 'desc').find(
        e =>
          e.interactionContext === 'course' ||
          e.interactionContext === 'assessment' ||
          (e.interactionContext === 'placementTest' && e.interaction.correct === true)
      )

      const questionCard = interactionToQuestionCard({
        element,
        userId,
        interaction: latestInteraction?.interaction,
        sessionId,
      })

      return {
        questionExerciseId,
        questionCard,
      }
    } catch (e) {
      throw new Error('Could not fetch question card response.')
    }
  }
)

export const resetQuestionCard = createAction<{ questionExerciseId: string }>(
  'self-paced/question-card/reset'
)

export const submitBlockInteraction = createAsyncThunk<
  void,
  {
    flexibleContentId: CreateContentId
    fileId: FileId
    interactionContext: InteractionContext
    interaction: BlockInteraction
  },
  { state: RootState }
>(
  'self-paced/upsert-block-interaction',
  async ({ flexibleContentId, fileId, interactionContext, interaction }, { dispatch, getState }) => {
    const userId = selectUserId(getState())

    if (userId === undefined) {
      throw new Error('Could not get user id.')
    }

    await postWithUserErrorException(
      XRealtimeStrategySelfPacedContentUpsertBlockInteraction,
      { contentId: flexibleContentId, fileId, interaction, interactionContext },
      dispatch
    )

    void dispatch(
      fetchCourseProgress({
        courseId: flexibleContentId,
        fileId,
      })
    )
  }
)

export const resetSelfPacedSlice = createAction('self-paced/reset-self-paced-slice')
