import { useAtom } from 'jotai'
import _, { capitalize } from 'lodash'
import { DateTime, DateTimeFormatOptions } from 'luxon'
import React, { useCallback, useMemo, useRef, useState } from 'react'
import { useLiveSessionTranscriptionQuery } from 'sierra-client/api/hooks/use-live-session'
import { useCompletedPostSessionSummary } from 'sierra-client/api/hooks/use-post-session-summary'
import { useRecordingsInSessionQuery } from 'sierra-client/api/hooks/use-recording'
import { useAblyYDocWithAwareness } from 'sierra-client/collaboration/use-ably-ydoc'
import { VideoPlayer } from 'sierra-client/components/blocks/video'
import { VideoJsPlayer } from 'sierra-client/components/blocks/video/types'
import { transcriptionSegmentIndexAtom } from 'sierra-client/components/liveV2/atoms/recordings'
import { useLiveSessionContext } from 'sierra-client/components/liveV2/contexts/live-session-data'

import { SummaryListLoading, SummaryTextLoading } from 'sierra-client/components/liveV2/summary/loading'
import { TranscriptActions } from 'sierra-client/components/liveV2/summary/transcript-actions'
import { TranscriptSummary } from 'sierra-client/components/liveV2/summary/transcript-summary'
import { SearchableTranscript } from 'sierra-client/components/liveV2/summary/transcription'
import { RecordingInfo } from 'sierra-client/components/liveV2/summary/types'
import { Logging } from 'sierra-client/core/logging'
import { useTranslation } from 'sierra-client/hooks/use-translation'
import { useCachedQuery } from 'sierra-client/state/api'
import { useDispatch, useSelector } from 'sierra-client/state/hooks'
import { selectUser } from 'sierra-client/state/user/user-selector'
import { useUsersLegacy } from 'sierra-client/state/users/hooks'
import { getAvatarImage } from 'sierra-client/utils/avatar-img'
import {
  LiveSessionTranscription,
  LiveSessionTranscriptionWithRelativeTimes,
} from 'sierra-domain/api/live-session'
import { LiveSessionId } from 'sierra-domain/api/nano-id'
import {
  ScopedLiveSessionId,
  ScopedSessionSummaryActionsId,
  ScopedSessionSummaryId,
} from 'sierra-domain/collaboration/types'
import { getLiveChannelName } from 'sierra-domain/live/get-live-channel-name'
import { GetLiveSessionRecordingResponse } from 'sierra-domain/recordings'
import { XRealtimeStrategyLiveSessionGetAttendance } from 'sierra-domain/routes'
import { LightUser } from 'sierra-domain/user'
import { getUserName } from 'sierra-domain/utils'
import { Icon, RoundAvatar, TruncatedText } from 'sierra-ui/components'
import { SpeakerTimeline } from 'sierra-ui/missions/live'
import { Heading, Text, View } from 'sierra-ui/primitives'
import { spacing, token } from 'sierra-ui/theming'
import { v2_breakpoint } from 'sierra-ui/theming/breakpoints'
import { narrowDotSeparator, useOnChanged } from 'sierra-ui/utils'
import { minWidth } from 'sierra-ui/utils/media-query-styles'
import styled, { createGlobalStyle } from 'styled-components'

const GlobalStyles = createGlobalStyle`
    & {
        #__next {
            overflow: visible;
        }
    }
`

export const SessionSummaryContainer = styled.div`
  position: relative;
  display: grid;
  grid-template-columns: repeat(12, 1fr);
  grid-column-gap: 24px;
  grid-row-gap: 0px;

  width: 100%;
  padding: 0 ${spacing.small};

  ${minWidth.tablet} {
    padding: 0 64px;
  }
`

const SummaryContainer = styled(View)`
  margin-top: 1.5rem;
  grid-column: 1 / -1;

  @media screen and (min-width: ${v2_breakpoint.tablet}) {
    grid-column: 1 / 6;
  }
`

const TranscriptContainer = styled.div`
  margin-top: 1.5rem;
  grid-column: 1 / -1;

  @media screen and (min-width: ${v2_breakpoint.tablet}) {
    grid-column: 7 / 13;
  }
`

const SpeakerTimelineContainer = styled(View)`
  gap: 0.5rem;
`

const SummaryWrapper = styled.div`
  max-width: 80ch;
`

const NotesWrapper = styled.div`
  max-width: 70ch;
  margin-left: -1.25rem;
  margin-right: -1.25rem;
  padding: 1.5rem 1.25rem;
  border-radius: 12px;
  transition: background-color 0.1s cubic-bezier(0.25, 0.5, 0.25, 1);

  &:hover,
  &:focus-within {
    background-color: ${token('surface/soft')};
  }
`

const HeadingContainer = styled.div`
  margin-top: 2rem;
  grid-column: 1 / -1;
  align-self: end;
  order: 2;
  display: flex;
  flex-direction: column;
  gap: 8px;
  max-width: 70ch;

  @media screen and (min-width: ${v2_breakpoint.desktop}) {
    order: 1;
    margin-top: 80px;
  }
`

const VideoInner = styled.div<{ $small: boolean }>`
  grid-column: 1 / -1;
  aspect-ratio: 16 / 9;
  border-radius: 8px;
  overflow: hidden;
  transform: translateZ(0);
  order: 1;

  @media screen and (min-width: ${v2_breakpoint.desktop}) {
    grid-column: ${p => (p.$small ? '6 / 8' : '5 / 9')};
    order: 2;
  }
`

const Metadata = styled(Text).attrs({ size: 'small', color: 'currentColor' })`
  color: ${token('foreground/primary').opacity(0.4)};
`

const SectionTitle = styled(Text).attrs({
  size: 'regular',
  color: 'currentColor',
})`
  height: 40px;
`

const VideoWrapper = styled.div`
  top: 0;
  width: 100%;
  z-index: 1;
  background: ${token('surface/default')};
  padding-top: 4rem;
  padding-bottom: 1.5rem;
`

const scopedSessionSummaryId = (scopedLiveSessionId: ScopedLiveSessionId): ScopedSessionSummaryId => {
  return `session-summary:${scopedLiveSessionId}` as ScopedSessionSummaryId
}

const scopedSessionSummaryActionsId = (
  scopedLiveSessionId: ScopedLiveSessionId
): ScopedSessionSummaryActionsId => {
  return `session-summary-actions:${scopedLiveSessionId}` as ScopedSessionSummaryActionsId
}

const getRecordingInfo = (
  recording: GetLiveSessionRecordingResponse,
  meetingStartTime?: DateTime
): RecordingInfo => ({
  recordingId: recording.recordingId,
  recordingStatus: recording.status,
  recordedBy: recording.recordedBy,
  recordingStartTime: DateTime.fromISO(recording.createdAt),
  recordingRelativeStartTime:
    meetingStartTime !== undefined
      ? DateTime.fromISO(recording.createdAt).diff(meetingStartTime).as('seconds')
      : undefined,

  recordingUrl: recording.hlsUrl !== undefined ? recording.hlsUrl.split('?')[0] : undefined,
  recordingAuthParams: recording.hlsUrl !== undefined ? new URL(recording.hlsUrl).search : undefined,
  recordingPreview: recording.previewUrl,
  // TODO: The end time is not accurate for some reason, we might need to do updates on the backend to fix this
  recordingEndTime: recording.closedAt !== undefined ? DateTime.fromISO(recording.closedAt) : undefined,
  recordingRelativeEndTime:
    meetingStartTime !== undefined && recording.closedAt !== undefined
      ? DateTime.fromISO(recording.closedAt).diff(meetingStartTime).as('seconds')
      : undefined,
})

/**
 *
 * @returns the offset in seconds between the start of the recording and the start of the transcription segment
 *         or undefined if the recording does not contain the transcription segment
 */
const segmentOffsetInRecording = (
  transcriptionSegment: LiveSessionTranscription,
  recording: RecordingInfo
): number | undefined => {
  if (transcriptionSegment.startTime === undefined || recording.recordingEndTime === undefined)
    return undefined

  const segmentStart = transcriptionSegment.startTime

  const recordingStart = recording.recordingStartTime.toSeconds()
  const recordingEnd = recording.recordingEndTime.toSeconds()

  if (segmentStart >= recordingStart && segmentStart <= recordingEnd) {
    const offset = segmentStart - recordingStart
    return offset
  }

  return undefined
}

const StyledHeading = styled(Heading)`
  color: inherit;
`

const Divider = styled.div`
  grid-column: 1/-1;
  border-bottom: 1px solid ${token('border/default')};
`

const ListItemContainer = styled(View)`
  min-height: 42px;
`

const ParticipantListItem: React.FC<{
  user: LightUser
}> = ({ user }) => {
  const { t } = useTranslation()
  const me = useSelector(selectUser)
  const userIsCurrentUser = me?.uuid === user.uuid
  const guestUserEmail = user.email.startsWith('sana-live')

  return (
    <ListItemContainer grow gap='16' padding='none none'>
      <RoundAvatar
        size='medium'
        firstName={user.firstName}
        lastName={user.lastName}
        color={user.avatarColor}
        src={getAvatarImage(user.uuid, user.avatar)}
      />
      <View direction='column' gap='none'>
        <TruncatedText lines={1} size='small' bold>
          {userIsCurrentUser ? capitalize(t('dictionary.you')) : getUserName(user) ?? ''}
        </TruncatedText>

        <Text size='small' color='foreground/muted'>
          {guestUserEmail ? 'guest user' : user.email}
        </Text>
      </View>
    </ListItemContainer>
  )
}

const Blob = styled(Text).attrs({ bold: true, size: 'technical', color: 'foreground/secondary' })`
  background-color: ${token('surface/strong')};
  border-radius: 50px;
  width: fit-content;
  padding: 2.5px 8px;
`

const ParticipantsGrid = styled.div`
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  row-gap: 16px;
  column-gap: 48px;
`

const ParticipantsContainer = styled(View).attrs({ direction: 'column' })`
  margin-bottom: 36px;
`

const ShowMoreParticipantsButton = styled.button`
  display: flex;
  flex-direction: row;
  background-color: transparent;
  align-items: center;
  gap: 8px;
  width: fit-content;
  cursor: pointer;
  padding: 0;
  padding-top: 16px;
`

const DEFAULT_PARTICIPANT_LIST_LENGTH = 12

export const Summary: React.FC<{ liveSessionId: LiveSessionId; tenantId: string }> = props => {
  const recordingsQueryResult = useRecordingsInSessionQuery({ liveSessionId: props.liveSessionId })
  const channelName = getLiveChannelName(props.tenantId, props.liveSessionId)

  const channelTranscription = useLiveSessionTranscriptionQuery({
    liveSessionId: props.liveSessionId,
    channelName,
  })

  const playerRef = useRef<VideoJsPlayer | null>(null)
  const [readyVideoUrl, setReadyVideoUrl] = useState<string | undefined>(undefined)
  const dispatch = useDispatch()
  const me = useSelector(selectUser)
  const { t } = useTranslation()
  const liveSession = useLiveSessionContext()
  const [hasTrackedRecordingPlayedEvent, setHasTrackedRecordingPlayedEvent] = useState(false)

  const hasRecordings = recordingsQueryResult.isSuccess && recordingsQueryResult.data.recordings.length > 0
  const provider = useAblyYDocWithAwareness(
    scopedSessionSummaryId(ScopedLiveSessionId.fromId(props.liveSessionId))
  )
  const actionsProvider = useAblyYDocWithAwareness(
    scopedSessionSummaryActionsId(ScopedLiveSessionId.fromId(props.liveSessionId))
  )
  const summaryResult = useCompletedPostSessionSummary(props.liveSessionId)
  const [transcriptionSegmentIndex, setTranscriptionSegmentIndex] = useAtom(transcriptionSegmentIndexAtom)

  // For now we say the meeting started at the first transcription segment
  const meetingStartTime = useMemo(() => {
    const time = channelTranscription.data?.transcription?.transcriptions?.[0]?.startTime
    return time !== undefined ? DateTime.fromSeconds(time) : undefined
  }, [channelTranscription.data?.transcription?.transcriptions])

  const selectedTranscriptionSegment = useMemo(
    () =>
      transcriptionSegmentIndex !== undefined
        ? channelTranscription.data?.transcription?.transcriptions?.[transcriptionSegmentIndex]
        : undefined,
    [channelTranscription.data?.transcription?.transcriptions, transcriptionSegmentIndex]
  )

  const recordings = useMemo(() => {
    const recs = recordingsQueryResult.data?.recordings
    if (recs === undefined) {
      return undefined
    }
    return recs.map(recording => getRecordingInfo(recording, meetingStartTime))
  }, [meetingStartTime, recordingsQueryResult.data?.recordings])

  // Select the recording that matches the transcription segment
  // or the latest recording if no transcription segment is selected
  const selectedRecording = useMemo(() => {
    // No reference to transcription segment, get latest recording
    if (selectedTranscriptionSegment === undefined) {
      return _.chain(recordings)
        .filter(recording => recording.recordingUrl !== undefined)
        .maxBy(recording => recording.recordingStartTime.toSeconds())
        .value()
    }

    if (
      selectedTranscriptionSegment.startTime === undefined ||
      selectedTranscriptionSegment.endTime === undefined
    ) {
      return undefined
    }

    const recording = recordings?.find(
      recording => segmentOffsetInRecording(selectedTranscriptionSegment, recording) !== undefined
    )

    if (recording === undefined) return undefined

    return recording
  }, [recordings, selectedTranscriptionSegment])

  const isPlayerReady = selectedRecording !== undefined && selectedRecording.recordingUrl === readyVideoUrl

  useOnChanged(() => {
    if (!isPlayerReady || playerRef.current === null || selectedTranscriptionSegment === undefined) return

    const segmentOffset = segmentOffsetInRecording(selectedTranscriptionSegment, selectedRecording)
    if (segmentOffset === undefined) return

    playerRef.current.currentTime(segmentOffset)
    void playerRef.current.play()
  }, [isPlayerReady, selectedRecording?.recordingId, selectedTranscriptionSegment])

  const transcription = useMemo<LiveSessionTranscriptionWithRelativeTimes[] | undefined>(() => {
    return channelTranscription.data?.transcription?.transcriptions
      ?.map((it, index) => ({
        ...it,
        index,
        relativeStartTime:
          meetingStartTime !== undefined && it.startTime !== undefined
            ? it.startTime - meetingStartTime.toSeconds()
            : undefined,
        relativeEndTime:
          meetingStartTime !== undefined && it.endTime !== undefined
            ? it.endTime - meetingStartTime.toSeconds()
            : undefined,
        isInRecording:
          recordings?.some(recording => segmentOffsetInRecording(it, recording) !== undefined) ?? false,
      }))
      .sort((a, b) => {
        if (a.startTime === undefined || b.startTime === undefined) return 0
        return a.startTime < b.startTime ? -1 : 1
      })
  }, [channelTranscription, meetingStartTime, recordings])

  const trackRecordingPlayed = useCallback((): void => {
    if (!hasTrackedRecordingPlayedEvent && selectedRecording?.recordingId !== undefined) {
      void dispatch(
        Logging.liveSession.watchedRecording({
          context: 'summary',
          recordingId: selectedRecording.recordingId,
        })
      )
      setHasTrackedRecordingPlayedEvent(true)
    }
  }, [hasTrackedRecordingPlayedEvent, selectedRecording?.recordingId, dispatch])

  const onReady = useCallback(
    (player: VideoJsPlayer) => {
      playerRef.current = player
      setReadyVideoUrl(player.currentSrc())
    },
    [playerRef]
  )

  const onSegmentClick = useCallback(
    (segment: LiveSessionTranscriptionWithRelativeTimes) => {
      setTranscriptionSegmentIndex(segment.index)
    },
    [setTranscriptionSegmentIndex]
  )

  const sessionDate = useMemo(() => {
    if (liveSession.data.type !== 'scheduled') return undefined
    const date = DateTime.fromISO(liveSession.data.startTime)
    const formatOptions: DateTimeFormatOptions = { weekday: 'long', month: 'long', day: 'numeric' }
    if (me?.language === undefined) {
      return date.toLocaleString(formatOptions)
    }
    // If the locale is invalid, it will default to English
    return date.setLocale(me.language).toLocaleString(formatOptions)
  }, [liveSession.data, me?.language])

  const attendees =
    useCachedQuery(XRealtimeStrategyLiveSessionGetAttendance, {
      liveSessionId: props.liveSessionId,
    }).data?.attendees ?? []

  const participants = useUsersLegacy(attendees).flatMap(user => (user !== undefined ? [user] : []))

  const [participantsExpanded, setParticipantsExpanded] = useState(false)

  const shortParticipants = participants.slice(0, DEFAULT_PARTICIPANT_LIST_LENGTH)

  const userDidSpeak =
    me !== undefined && transcription?.filter(it => it.userId === me.uuid).length !== 0 ? true : false

  return (
    <>
      <GlobalStyles />
      <VideoWrapper>
        <SessionSummaryContainer>
          <HeadingContainer>
            <StyledHeading size='h4' bold avoidHanging={false}>
              {t('live.recap.summary-of')} <br />
              {liveSession.data.title}
            </StyledHeading>

            <View gap='8'>
              <Metadata>{sessionDate}</Metadata>
              <Metadata>{narrowDotSeparator}</Metadata>
              <Metadata>
                {liveSession.data.type === 'scheduled' && (
                  <>
                    {DateTime.fromISO(liveSession.data.startTime).toFormat('HH:mm')} –{' '}
                    {DateTime.fromISO(liveSession.data.endTime).toFormat('HH:mm')}
                  </>
                )}
              </Metadata>
            </View>
          </HeadingContainer>
          {hasRecordings &&
            (selectedRecording?.recordingStatus === 'completed' ||
              selectedRecording?.recordingStatus === 'processing') &&
            selectedRecording.recordingUrl !== undefined && (
              <VideoInner $small={false}>
                <VideoPlayer
                  onReady={onReady}
                  onPlay={trackRecordingPlayed}
                  authSearchParams={selectedRecording.recordingAuthParams}
                  options={{
                    autoplay: false,
                    bigPlayButton: true,
                    controls: true,
                    controlBar: {
                      liveDisplay: false,
                      pictureInPictureToggle: false,
                    },
                    preload: 'auto',
                    fill: true,
                    poster: selectedRecording.recordingPreview,
                    sources: [{ src: selectedRecording.recordingUrl, type: 'application/x-mpegURL' }],
                  }}
                />
              </VideoInner>
            )}
        </SessionSummaryContainer>
      </VideoWrapper>
      <SessionSummaryContainer>
        <Divider />
      </SessionSummaryContainer>
      <SessionSummaryContainer>
        <SummaryContainer direction='column' gap='32'>
          {liveSession.data.transcribeSession === true && (
            <View direction='column' gap='12'>
              <SectionTitle bold>{t('dictionary.summary')}</SectionTitle>
              <SummaryWrapper>
                {summaryResult.status === 'success' && summaryResult.data.format === 'note' && (
                  <Text color='currentColor' size='regular'>
                    {summaryResult.data.data.summary}
                  </Text>
                )}
                {provider === undefined || (summaryResult.status === 'loading' && <SummaryTextLoading />)}
              </SummaryWrapper>
            </View>
          )}

          <View direction='column' gap='12'>
            <SectionTitle bold>{t('dictionary.notes')}</SectionTitle>
            <NotesWrapper>
              {provider === undefined || (summaryResult.status === 'loading' && <SummaryListLoading />)}
              {provider !== undefined &&
                (summaryResult.status === 'success' || summaryResult.status === 'error') && (
                  <TranscriptSummary
                    summaryData={summaryResult}
                    yDoc={provider.yDoc}
                    awareness={provider.awareness}
                  />
                )}
            </NotesWrapper>
          </View>
          <View direction='column' gap='12'>
            <SectionTitle bold>{t('dictionary.actions')}</SectionTitle>
            <NotesWrapper>
              {actionsProvider === undefined ||
                (summaryResult.status === 'loading' && <SummaryListLoading />)}
              {actionsProvider !== undefined &&
                (summaryResult.status === 'success' || summaryResult.status === 'error') && (
                  <TranscriptActions
                    summaryData={summaryResult}
                    yDoc={actionsProvider.yDoc}
                    awareness={actionsProvider.awareness}
                  />
                )}
            </NotesWrapper>
          </View>
        </SummaryContainer>
        <TranscriptContainer>
          {me !== undefined && transcription !== undefined && userDidSpeak === true && (
            <SpeakerTimelineContainer direction='column'>
              <SectionTitle bold>{t('dictionary.speaking-balance')}</SectionTitle>
              <SpeakerTimeline
                youString={_.capitalize(t('dictionary.you'))}
                othersString={t('dictionary.others')}
                userId={me.uuid}
                transcriptions={transcription}
              />
            </SpeakerTimelineContainer>
          )}
          <ParticipantsContainer>
            <View paddingBottom={'16'} direction='row' alignItems='center'>
              <Text size='regular' bold>
                {t('admin.author.sessions.participants')}
              </Text>
              <Blob>{participants.length}</Blob>
            </View>
            <ParticipantsGrid>
              {(participantsExpanded ? participants : shortParticipants).map(user => (
                <ParticipantListItem key={user.uuid} user={user} />
              ))}
            </ParticipantsGrid>
            {participants.length > DEFAULT_PARTICIPANT_LIST_LENGTH && (
              <ShowMoreParticipantsButton onClick={() => setParticipantsExpanded(previous => !previous)}>
                <Icon
                  iconId={participantsExpanded ? 'chevron--up--small' : 'chevron--down--small'}
                  color={'grey25'}
                />
                <Text bold color={'grey25'}>
                  {' '}
                  {participantsExpanded
                    ? t('admin.author.sessions.hide-participants', {
                        count: participants.length - shortParticipants.length,
                      })
                    : t('admin.author.sessions.show-participants', {
                        count: participants.length - shortParticipants.length,
                      })}
                </Text>
              </ShowMoreParticipantsButton>
            )}
          </ParticipantsContainer>

          <SearchableTranscript
            onSegmentClick={onSegmentClick}
            recordings={recordings}
            transcription={transcription}
          />
        </TranscriptContainer>
      </SessionSummaryContainer>
    </>
  )
}
