import React from 'react'
import { graphql } from 'sierra-client/api/graphql/gql'
import { CertificateHtmlPreview, UpsertCertificateRequestInput } from 'sierra-client/api/graphql/gql/graphql'
import { graphQuery } from 'sierra-client/api/hooks/use-graphql-query'
import { useObserveElementSize } from 'sierra-client/hooks/use-observe-element-size'
import { normalizeCertificateForGraphQL } from 'sierra-client/views/manage/certificates/edit-certificate-panel/store'
import { assertNever } from 'sierra-domain/utils'

// TODO(workflows): This can be improved. It's not immediately clear why we need these hooks.

type Size = { width: number; height: number }

export type UseResponsivePreview = {
  outputSize: Size
}

// Note: Call this even if you don't use the return value, because it sets the `transform` style property on the preview element.
export const useResponsivePreview = ({
  previewElement,
  previewContainerElement,
  maxWidth = 500,
  maxHeight = 500,
  padding = 25,
  scaleToContainer = true,
}: {
  previewElement: HTMLImageElement | HTMLIFrameElement | null
  previewContainerElement: HTMLDivElement | null
  maxWidth?: number
  maxHeight?: number
  padding?: number
  scaleToContainer?: boolean
}): UseResponsivePreview => {
  const containerSize = useObserveElementSize(previewContainerElement)
  const previewSize = useObserveElementSize(previewElement)

  const { width: containerWidth, height: containerHeight } = containerSize
  const { width: previewWidth, height: previewHeight } = previewSize

  const { outputSize, previewElementTransform } = React.useMemo(() => {
    if (previewWidth === 0 || previewHeight === 0)
      return { outputSize: { width: maxWidth, height: maxHeight }, previewElementTransform: undefined }

    let targetWidth = maxWidth
    let targetHeight = maxHeight
    if (scaleToContainer) {
      targetWidth = Math.min(containerWidth - padding, maxWidth)
      targetHeight = Math.min(containerHeight - padding, maxHeight)
    }

    const heightDiff = containerHeight - padding - targetHeight
    const scale = Math.min(targetWidth / previewWidth, targetHeight / previewHeight)

    const previewElementHeightChange = (heightDiff + targetHeight + padding) / 2
    const previewElementTransform = `translate(-50%, -${previewElementHeightChange}px) scale(${scale})`

    const outputSize = {
      width: previewWidth * scale,
      height: previewHeight * scale,
    }

    return {
      outputSize,
      previewElementTransform,
    }
  }, [
    scaleToContainer,
    maxWidth,
    maxHeight,
    containerWidth,
    containerHeight,
    previewWidth,
    previewHeight,
    padding,
  ])

  React.useEffect(() => {
    previewElement?.style.setProperty('transform', previewElementTransform ?? '')
  }, [previewElement, previewElementTransform])

  return { outputSize }
}

export type UseEditCertificateTemplate = {
  renderedTemplate: string
  fetchRenderedTemplate: (certificate: UpsertCertificateRequestInput) => Promise<CertificateHtmlPreview>
  fullWidth: number
  fullHeight: number
}

export const useEditCertificateTemplate = (
  certificate: UpsertCertificateRequestInput
): UseEditCertificateTemplate => {
  const [renderedTemplate, setRenderedTemplate] = React.useState<string>('<html/>')

  const fetchRenderedTemplate = React.useCallback<UseEditCertificateTemplate['fetchRenderedTemplate']>(
    async (certificate: UpsertCertificateRequestInput) => {
      const normalizedCertificate = normalizeCertificateForGraphQL(certificate)
      const { certificateHtmlPreview } = await graphQuery(
        graphql(`
          query certificateHtmlPreview($certificate: UpsertCertificateRequestInput!) {
            certificateHtmlPreview(upsertCertificateRequest: $certificate) {
              html
            }
          }
        `),
        {
          certificate: normalizedCertificate,
        }
      )

      setRenderedTemplate(certificateHtmlPreview.html)
      return certificateHtmlPreview
    },
    []
  )

  const { fullWidth, fullHeight } = React.useMemo(() => {
    const longSide = 1920
    const shortSide = 1357

    // TODO: We probably want the physical dimensions to be configurable. A4 for now
    switch (certificate.templateData.orientation) {
      case 'HORIZONTAL':
        return { fullWidth: longSide, fullHeight: shortSide }
      case 'VERTICAL':
        return { fullWidth: shortSide, fullHeight: longSide }
      default:
        assertNever(certificate.templateData.orientation)
    }
  }, [certificate])

  return {
    renderedTemplate,
    fetchRenderedTemplate,
    fullWidth,
    fullHeight,
  }
}
