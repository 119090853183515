import { FocusScope } from '@radix-ui/react-focus-scope'
import * as RadixPopover from '@radix-ui/react-popover'
import { AnimatePresence, motion } from 'framer-motion'
import React, { KeyboardEventHandler, useCallback, useState } from 'react'
import { useTranslation } from 'sierra-client/hooks/use-translation'
import {
  findPathToNodeWithId,
  unwrapToTextNodes,
  updateNodeWithId,
} from 'sierra-client/views/v3-author/command'
import { useIsUniquelySelected } from 'sierra-client/views/v3-author/hooks'
import { assertElementType } from 'sierra-client/views/v3-author/queries'
import { SlateFC } from 'sierra-client/views/v3-author/slate'
import { Entity } from 'sierra-domain/entity'
import { Link as LinkBlock } from 'sierra-domain/v3-author'
import { color, deriveTheme } from 'sierra-ui/color'
import { Icon } from 'sierra-ui/components'
import { Button, View } from 'sierra-ui/primitives'
import { LightTokenProvider, token, zIndex } from 'sierra-ui/theming'
import { fonts } from 'sierra-ui/theming/fonts'
import { useOnChanged } from 'sierra-ui/utils'
import { Editor, Transforms } from 'slate'
import { ReactEditor, useSlateStatic } from 'slate-react'
import styled from 'styled-components'

const LinkContainer = styled.a`
  text-decoration: underline;
  color: ${p =>
    color(deriveTheme(p.theme).textColor)
      .opacity(0.8)
      .brighten(0.1)
      .toString()} !important /* Some global styles for v2 need to be overwritten here (#002fa7)*/;
`

const parseURL = (_url: string): string => {
  const url = _url.trim()

  if (/^https?:\/\//i.test(url) || /^mailto:/i.test(url)) {
    return url
  }

  return `https://${url}`
}

const LinkInputWrapper = styled(View)`
  border-radius: 10px;
  width: 300px;
  background-color: ${token('elevated/background')};
  box-shadow: 0px 8px 16px rgba(0, 0, 0, 0.08);
  overflow: hidden;
`

const LinkInput = styled.input`
  ${fonts.body.small};
  width: 100%;
  padding-top: 0;
  padding-bottom: 0;

  &::placeholder {
    color: ${p => p.theme.color.grey40};
  }

  &:focus {
    &::placeholder {
      opacity: 1;
    }
  }
`

const NoLinkComponent: React.FC<{
  link: Entity<LinkBlock>
  setIsEditLinkView: React.Dispatch<React.SetStateAction<boolean>>
}> = ({ link, setIsEditLinkView }) => {
  const editor = useSlateStatic()
  const { t } = useTranslation()
  const { id } = link
  const [value, setValue] = useState(link.url)

  const handleUrlChange = useCallback(
    (e: React.ChangeEvent<HTMLInputElement>): void => {
      const url = e.target.value
      setValue(url)
      updateNodeWithId(editor, id, { url })
    },
    [editor, id]
  )

  const handleUnlink = useCallback((): void => {
    const path = findPathToNodeWithId(editor, id)
    if (path) {
      unwrapToTextNodes(editor, { at: path })
    }
  }, [editor, id])

  const handleKeyPress: KeyboardEventHandler<HTMLInputElement> = useCallback(
    event => {
      if (event.key === 'Enter') {
        event.preventDefault()
        event.stopPropagation()

        if (value === '') {
          handleUnlink()
          return
        }
        updateNodeWithId(editor, id, { url: value })
        const path = findPathToNodeWithId(editor, id)

        try {
          if (path) {
            const range = Editor.range(editor, path)
            Transforms.select(editor, { anchor: range.focus, focus: range.focus })
          }
        } catch (e) {
          console.debug('Error selecting range after updating link', e)
        }

        setIsEditLinkView(false)
      }
      if (event.key === 'Escape' && value === '') {
        handleUnlink()
        return
      }
    },
    [editor, handleUnlink, id, setIsEditLinkView, value]
  )

  return (
    <LinkInputWrapper padding='10' gap='8' onClick={e => e.stopPropagation()} contentEditable={false}>
      <Icon iconId='link' />
      <LinkInput
        id='link'
        value={value}
        placeholder={t('author.slate.link.placeholder')}
        onChange={handleUrlChange}
        onKeyDown={handleKeyPress}
      />
    </LinkInputWrapper>
  )
}

const LinkComponent: React.FC<{
  link: Entity<LinkBlock>
  setIsEditLinkView: React.Dispatch<React.SetStateAction<boolean>>
}> = ({ link, setIsEditLinkView }) => {
  const { t } = useTranslation()
  const editor = useSlateStatic()

  const handleUnlink = useCallback((): void => {
    const unlinkAt = ReactEditor.findPath(editor, link)
    unwrapToTextNodes(editor, { at: unlinkAt })
  }, [editor, link])

  return (
    <LinkInputWrapper
      gap='8'
      onClick={e => e.stopPropagation()}
      contentEditable={false}
      padding='24'
      direction='column'
    >
      <Button
        grow
        variant='ghost'
        icon='edit'
        onClick={() => {
          setIsEditLinkView(true)
        }}
      >
        {t('author.slate.link.edit')}
      </Button>
      <Button grow variant='ghost' icon='unlink' onClick={handleUnlink}>
        {t('author.slate.link.remove')}
      </Button>
      <Button grow variant='primary' href={parseURL(link.url)} target='_blank' rel='noreferrer'>
        {t('author.slate.link.open')}
      </Button>
    </LinkInputWrapper>
  )
}

const MotionWrapper = styled(motion.div).attrs({
  initial: { y: 8, opacity: 0 },
  animate: { y: 0, opacity: 1 },
  exit: { y: -8, opacity: 0, transition: { duration: 0.1 } },
  transition: { duration: 0.2, ease: [0.25, 0.1, 0.25, 1] },
})``

const LinkToolbarInner: React.FC<{ element: Entity<LinkBlock> }> = ({ element }) => {
  const [isEditLinkView, setIsEditLinkView] = useState(element.url === '')

  return (
    <AnimatePresence mode='wait'>
      {isEditLinkView ? (
        <MotionWrapper key='edit-link'>
          <NoLinkComponent link={element} setIsEditLinkView={setIsEditLinkView} />
        </MotionWrapper>
      ) : (
        <MotionWrapper key='link-buttons'>
          <LinkComponent link={element} setIsEditLinkView={setIsEditLinkView} />
        </MotionWrapper>
      )}
    </AnimatePresence>
  )
}

const StyledPopoverContent = styled(RadixPopover.Content)`
  transform-origin: var(--radix-popover-content-transform-origin);
  max-height: calc(var(--radix-dropdown-menu-content-available-height) - 1rem);
  border-radius: 12px;
  z-index: ${zIndex.TOOLBAR};
  box-shadow: 0px 8px 16px rgba(0, 0, 0, 0.08);
  overflow: visible;
`

const CreateLink: SlateFC = ({ children, element, readOnly }) => {
  assertElementType('link', element)

  const isOnlySelectedElement = useIsUniquelySelected({ nodeId: element.id })
  const [anchorElement, setAnchorElement] = useState<HTMLAnchorElement | null>(null)

  const canShowToolbar = !readOnly && isOnlySelectedElement

  /**
   * We want to be able to close the toolbar with Escape, even if the selection is still in the link.
   **/
  const [isForceClosed, setIsForceClosed] = useState<boolean>(false)

  useOnChanged((_prev, curr) => {
    // Reset the override when the open trigger isn't active.
    if (!curr) {
      setIsForceClosed(false)
    }
  }, canShowToolbar)

  return (
    <RadixPopover.Root
      open={canShowToolbar && !isForceClosed && anchorElement !== null}
      onOpenChange={val => {
        if (!val) {
          setIsForceClosed(true)
        }
      }}
    >
      <RadixPopover.Anchor asChild>
        <LinkContainer ref={setAnchorElement} data-slate-inline='true'>
          {children}
        </LinkContainer>
      </RadixPopover.Anchor>
      <RadixPopover.Portal>
        <StyledPopoverContent
          asChild
          align='center'
          side='top'
          sideOffset={8}
          onPointerDownOutside={e => {
            if (anchorElement?.contains(e.target as Node) === true) {
              e.preventDefault()
            }
          }}
        >
          {/**
           * We don't want to steal the user's focus in case they're
           * using the arrow keys to navigate the content.
           */}
          <FocusScope onMountAutoFocus={e => e.preventDefault()}>
            <div>
              <LightTokenProvider>
                <LinkToolbarInner element={element} />
              </LightTokenProvider>
            </div>
          </FocusScope>
        </StyledPopoverContent>
      </RadixPopover.Portal>
    </RadixPopover.Root>
  )
}

export const Link: SlateFC = ({ children, ...props }) => {
  const { element, readOnly } = props
  assertElementType('link', element)

  if (!readOnly) return <CreateLink {...props}>{children}</CreateLink>
  return (
    <LinkContainer
      href={parseURL(element.url)}
      target='_blank'
      rel='noopener noreferrer'
      contentEditable={false}
    >
      {children}
    </LinkContainer>
  )
}
