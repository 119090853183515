import { Logger } from 'sierra-client/core/logging/logger'

export const editorNodeMovedLogger = Logger.trackLoggerWithExtra<
  { nodeId: string; nodeType: 'folder' | 'file' },
  { nodeId: string; nodeType: 'folder' | 'file' }
>('editor_node_moved', input => {
  return {
    ...input,
  }
})

export const moduleAddedLogger = Logger.trackLoggerWithExtra<{ moduleId: string }, { moduleId: string }>(
  'module_added',
  input => {
    return {
      ...input,
    }
  }
)
