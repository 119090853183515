import { useEffect } from 'react'
import { selectCourseIsCompleted } from 'sierra-client/state/card-progress/selectors'
import { selectCurrentProgramId } from 'sierra-client/state/content/selectors'
import { useDispatch, useSelector } from 'sierra-client/state/hooks'
import { fetchProgramById } from 'sierra-client/state/v2/program-actions'
import { CreateContentId } from 'sierra-domain/api/nano-id'

export const ProgramProgressSync: React.FC<{ createContentId: CreateContentId }> = ({
  createContentId,
}): null => {
  const dispatch = useDispatch()
  const currentProgramId = useSelector(selectCurrentProgramId)

  const courseCompleted = useSelector(state => selectCourseIsCompleted(state, createContentId))

  useEffect(() => {
    if (currentProgramId === undefined) return

    void dispatch(fetchProgramById({ programId: currentProgramId }))
  }, [currentProgramId, dispatch, courseCompleted])

  return null
}
