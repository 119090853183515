import { useAtomValue } from 'jotai'
import { useCallback } from 'react'
import { RenderFile, RenderFolder } from 'sierra-client/learn/learn-ui/file-view/helpers'
import { selectPlacementTestProgressState, selectReview } from 'sierra-client/state/card-progress/selectors'
import { useSelector } from 'sierra-client/state/hooks'
import { FileTree } from 'sierra-client/views/flexible-content/render-file-tree'
import { useSelfPacedBigContext } from 'sierra-client/views/flexible-content/self-paced-big-context'
import {
  ActivePlacementTestItem,
  NotStartedOrCompletedPlacementTestItem,
  ReviewTestItem,
} from 'sierra-client/views/self-paced/adaptive-features/adaptive-item'
import { FlexibleContentFilesContext, useSelfPacedFiles } from 'sierra-client/views/self-paced/files-provider'
import { isPlacementTestModalOpen } from 'sierra-client/views/self-paced/placement-test/atoms'
import { CreateContentId } from 'sierra-domain/api/nano-id'
import { FileId, FolderId } from 'sierra-domain/flexible-content/identifiers'
import { Folder } from 'sierra-domain/flexible-content/types'
import { guardWith, isDefined } from 'sierra-domain/utils'

type AdaptiveItemProps = {
  folder: Folder
  flexibleContentId: CreateContentId
}

const PlacementTestItem: React.FC<
  AdaptiveItemProps & {
    startPlacementTest: FlexibleContentFilesContext['startPlacementTest']
    currentPlacementTest: FlexibleContentFilesContext['currentPlacementTest']
  }
> = ({ flexibleContentId, folder, startPlacementTest, currentPlacementTest }) => {
  const placementTestModalOpen = useAtomValue(isPlacementTestModalOpen)

  const placementTest = useSelector(state => selectPlacementTestProgressState(state, flexibleContentId))(
    folder.id
  )
  const isPlaceMentTestOpen = placementTest?.state === 'open'
  const isPlacementTestCompleted = placementTest?.state === 'completed'

  if (placementTestModalOpen && folder.id === currentPlacementTest) {
    return (
      <ActivePlacementTestItem
        onClick={() => {
          startPlacementTest({
            folderId: folder.id,
            firstFileId: folder.nodeIds.find(guardWith(FileId)),
          })
        }}
      />
    )
  } else if (isPlaceMentTestOpen || isPlacementTestCompleted) {
    return (
      <NotStartedOrCompletedPlacementTestItem
        isCompleted={isPlacementTestCompleted}
        onClick={() => {
          if (!isPlacementTestCompleted) {
            startPlacementTest({
              folderId: folder.id,
              firstFileId: folder.nodeIds.find(guardWith(FileId)),
            })
          }
        }}
      />
    )
  } else {
    return null
  }
}

const ReviewItem: React.FC<
  AdaptiveItemProps & {
    setCurrentReview: (folderId: FolderId | undefined) => void
  }
> = ({ flexibleContentId, folder, setCurrentReview }) => {
  const review = useSelector(state => selectReview(state, flexibleContentId))(folder.id)
  const isReviewCompleted = review?.state === 'completed'
  const isReviewOpen = review?.state === 'open'
  const showReviewItem = isReviewOpen || isReviewCompleted

  const startReview = useCallback(() => {
    if (!isReviewCompleted) {
      setCurrentReview(folder.id)
    }
  }, [folder.id, setCurrentReview, isReviewCompleted])

  return showReviewItem ? (
    <ReviewTestItem
      numberOfLearnings={review.elements.length}
      onClick={startReview}
      isCompleted={isReviewCompleted}
    />
  ) : null
}

export const SelfPacedFileTree = (): JSX.Element | null => {
  const {
    flexibleContentId,
    currentFile,
    flexibleContent,
    setCurrentReview,
    startPlacementTest,
    goTo,
    currentReview,
    currentPlacementTest,
  } = useSelfPacedFiles()

  const handleFileClicked = useCallback((fileId: FileId) => goTo(fileId), [goTo])
  const { slateDocumentMap } = useSelfPacedBigContext()
  const placementTestModalOpen = useAtomValue(isPlacementTestModalOpen)
  const hideSelectionStateWhilePlacementTestOpen = placementTestModalOpen || isDefined(currentReview)

  if (flexibleContent === undefined) return null
  const root = flexibleContent.nodeMap['folder:root']
  if (root?.type !== 'folder') throw new Error('Root folder missing')

  return (
    <FileTree
      content={flexibleContent}
      rootAsFolder={false}
      renderFile={file => (
        <RenderFile
          flexibleContentId={flexibleContentId}
          isSelfPaced={true}
          highlight={currentFile?.id === file.id && !hideSelectionStateWhilePlacementTestOpen}
          onClick={handleFileClicked}
          node={file}
          slateDocumentMap={slateDocumentMap}
        />
      )}
      renderFolder={(folder, children) => (
        <RenderFolder flexibleContentId={flexibleContentId} folder={folder} currentCard={currentFile}>
          <PlacementTestItem
            currentPlacementTest={currentPlacementTest}
            folder={folder}
            flexibleContentId={flexibleContentId}
            startPlacementTest={startPlacementTest}
          />
          {children}
          <ReviewItem
            folder={folder}
            flexibleContentId={flexibleContentId}
            setCurrentReview={setCurrentReview}
          />
        </RenderFolder>
      )}
    />
  )
}
