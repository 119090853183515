import {
  ChatMessageUpdated,
  ReactionAdded,
  ReactionRemoved,
  createChatDataChannel,
  liveSessionChatDataChannel,
} from 'sierra-client/realtime-data/channels/live-session-chat-data-channel'
import { chatMessageUpdated, reactionAdded, reactionRemoved } from 'sierra-client/state/chat/actions'
import { useDispatch } from 'sierra-client/state/hooks'
import { ChatIdentifier } from 'sierra-domain/api/chat'
import { ScopedChatId } from 'sierra-domain/collaboration/types'
import { assertNever, iife } from 'sierra-domain/utils'

export const ChatRealtimeDataHandler = ({
  chatIdentifier,
  chatId,
}: {
  chatIdentifier: ChatIdentifier
  chatId: ScopedChatId
}): null => {
  const dispatch = useDispatch()

  const dataChannel = iife(() => {
    switch (chatIdentifier.type) {
      case 'live-session':
        return liveSessionChatDataChannel
      case 'course':
        return createChatDataChannel
    }
  })

  const channelId = iife(() => {
    switch (chatIdentifier.type) {
      case 'live-session':
        return chatIdentifier.liveSessionId
      case 'course':
        return chatIdentifier.courseId
    }
    assertNever(chatIdentifier)
  })

  dataChannel.useChannelEvent({
    channelId,
    event: 'reaction-added',
    callback: (data: ReactionAdded) => {
      dispatch(
        reactionAdded({
          chatId,
          messageId: data.messageId,
          reaction: {
            id: data.reactionId,
            userId: data.userId,
            reaction: data.reaction,
            timeSent: data.createdAt,
          },
        })
      )
    },
  })

  dataChannel.useChannelEvent({
    channelId,
    event: 'reaction-removed',
    callback: (data: ReactionRemoved) => {
      dispatch(
        reactionRemoved({
          chatId,
          messageId: data.messageId,
          reactionId: data.reactionId,
        })
      )
    },
  })

  dataChannel.useChannelEvent({
    channelId,
    event: 'chat-message-updated',
    callback: (data: ChatMessageUpdated) => {
      dispatch(
        chatMessageUpdated({
          chatId,
          data,
        })
      )
    },
  })

  return null
}
