import { ReactElement } from 'react'
import { Trans } from 'sierra-client/hooks/use-translation/trans'
import { TranslationKey } from 'sierra-client/hooks/use-translation/types'
import { Text } from 'sierra-ui/primitives'
import { FCC } from 'sierra-ui/types'

type TitleProps = { values?: Record<string, string | number | undefined> } & (
  | { titleKey: TranslationKey }
  | { untranslated: string }
)

type NotificationTranslationVariable = 'subtitle'

const translationComponents: Record<NotificationTranslationVariable, ReactElement> = {
  subtitle: <Text as='span' color='foreground/muted' />,
}

export const NotificationTitle: FCC<TitleProps> = props => {
  const isUntranslated = 'untranslated' in props

  return (
    <Text bold>
      <Trans
        i18nKey={isUntranslated ? (props.untranslated as TranslationKey) : props.titleKey}
        values={props.values}
        components={translationComponents}
      />
    </Text>
  )
}
