import { FC } from 'react'
import { Skeleton, Text } from 'sierra-ui/primitives'
import styled from 'styled-components'

const StyledSkeleton = styled(Skeleton)`
  display: flex;
  align-items: center;
  padding: 16px;
`

export const ResolvingGroupMembersSkeleton: FC = () => {
  return (
    <StyledSkeleton $width='100%' $height={30} $radius={8}>
      <Text bold color='foreground/secondary'>
        Resolving group members...
      </Text>
    </StyledSkeleton>
  )
}
