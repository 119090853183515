import _ from 'lodash'
import { isElementType } from 'sierra-client/views/v3-author/queries'
import { textInNodes } from 'sierra-domain/slate-util'
import { SlateDocument } from 'sierra-domain/v3-author'
import { createParagraph } from 'sierra-domain/v3-author/create-blocks'
import { Node } from 'slate'

function isEmpty(node: Node): boolean {
  return textInNodes([node]).join('').trim().length === 0
}

function processDocument([current, ...rest]: SlateDocument, outputDocument: SlateDocument): SlateDocument {
  if (current === undefined) {
    return outputDocument
  }
  const [next] = rest

  const currentIsH1 = isElementType('heading', current) && current.level === 1
  const nextIsNotHeading = !isElementType('heading', next)
  const nextIsNotEmpty = next !== undefined && !isEmpty(next)

  // Add a newline after h1s
  if (currentIsH1 && nextIsNotHeading && nextIsNotEmpty) {
    outputDocument.push(current)
    outputDocument.push(createParagraph())
    return processDocument(rest, outputDocument)
  }

  const nextIsHeading = isElementType('heading', next)
  const currentIsNotEmpty = !isEmpty(current)
  const currentIsNotHeading = !isElementType('heading', current)
  // Add a newline before headings
  if (nextIsHeading && currentIsNotHeading && currentIsNotEmpty) {
    outputDocument.push(current)
    outputDocument.push(createParagraph())
    return processDocument(rest, outputDocument)
  }

  const [, nextNext] = rest
  const nextIsEmpty = next !== undefined && isEmpty(next)
  const nextNextIsNotEmpty = nextNext !== undefined && !isEmpty(nextNext)
  const nextNextIsHeading = isElementType('heading', nextNext)
  if (currentIsNotEmpty && nextIsEmpty && nextNextIsNotEmpty) {
    if (!(currentIsH1 || nextNextIsHeading)) {
      outputDocument.push(current)
      return processDocument(_.drop(rest, 1), outputDocument)
    }
  }

  const currentIsEmpty = isEmpty(current)
  if (currentIsEmpty) {
    outputDocument.push(current)
    return processDocument(
      _.dropWhile(rest, node => isEmpty(node)),
      outputDocument
    )
  }

  outputDocument.push(current)
  return processDocument(rest, outputDocument)
}

/**
 * Enforce some rules about styling that we want to follow in course generation output.
 * Attempting to solve these through prompting has had mixed results.
 */
export function cleanupSlateDocument(document: SlateDocument): SlateDocument {
  return processDocument(document, [])
}
