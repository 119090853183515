import { ReactElement } from 'react'
import { FCC } from 'sierra-client/types'
import { Icon, IconId, IconProps, IconSize } from 'sierra-ui/components'
import { Text, TextProps } from 'sierra-ui/primitives'
import { SpacingProps, spacing } from 'sierra-ui/theming'
import styled, { css } from 'styled-components'

const IconContainer = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  flex-wrap: wrap;
`

const IconListItemContainer = styled.div<{ faded: boolean }>`
  display: inline-flex;
  align-items: flex-start;

  ${p =>
    p.faded &&
    css`
      opacity: 0.6;
    `};
`

const IconListItemIcon = styled(Icon)`
  position: relative;
  top: 0.05em;
  margin-right: 0.5rem;
`

const IconListItemText = styled(Text)`
  margin-right: 0.5rem;
`

const VerticalIconContainer = styled.div<{ $spacing: SpacingProps }>`
  display: flex;
  flex-direction: column;

  & > *:not(:last-child) {
    margin-bottom: ${p => spacing[p.$spacing]};
  }
`
interface IIconList {
  children: ReactElement<IconListItemProps> | Array<ReactElement<IconListItemProps>>
  className?: string
}

type IconListItemProps = TextProps & {
  iconId: IconId
  iconSize?: IconSize
  color?: IconProps['color']
  text: string
  faded?: boolean
  bold?: boolean
  onClick?: () => void
}

export const IconListItem: React.FunctionComponent<IconListItemProps> = ({
  iconId,
  iconSize = 'size-16',
  color,
  text,
  faded = true,
  bold = false,
  onClick,
  ...props
}: IconListItemProps) => (
  <IconListItemContainer faded={faded}>
    <IconListItemIcon iconId={iconId} size={iconSize} color={color} />
    <IconListItemText color={color ?? 'LEGACY_DEFAULT_TEXT_COLOR_REPLACE_ASAP'} bold={bold} {...props}>
      {text}
    </IconListItemText>
  </IconListItemContainer>
)

export const IconList: FCC<IIconList> = ({ children, className }: IIconList): JSX.Element => {
  return <IconContainer className={className}>{children}</IconContainer>
}

export const VerticalIconList: FCC<{ spacing?: SpacingProps }> = ({ spacing = 'xxsmall', children }) => {
  return <VerticalIconContainer $spacing={spacing}>{children}</VerticalIconContainer>
}
