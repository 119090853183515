import {
  getAvatarColor,
  getAvatarInitialsForColor,
  getAvatarUrl,
} from 'sierra-client/api/graphql/util/convert-gql-avatar'
import { Avatar } from 'sierra-domain/api/manage'
import { RoundAvatar } from 'sierra-ui/components'
import { AvatarProps } from 'sierra-ui/components/avatars/round-avatar'

/** Feel free to rename. This component accepts a prop of the Avatar type and maps it to the legacy props of RoundAvatar. */
export const RoundAvatarFromAvatar: React.FC<{
  userId: string | undefined
  avatar: Avatar | undefined
  size: AvatarProps['size']
}> = ({ userId, avatar, size }) => {
  const initials = getAvatarInitialsForColor(avatar)

  return (
    <RoundAvatar
      size={size}
      firstName={initials[0]}
      lastName={initials[1]}
      color={getAvatarColor(avatar)}
      src={getAvatarUrl(userId, avatar)}
    />
  )
}
