import { layoutValues } from 'sierra-client/config/layout-values'
import { v2_breakpoint } from 'sierra-ui/theming/breakpoints'
import styled, { css } from 'styled-components'

export const ClickOverlay = styled.div<{
  onClick?: () => void
  isVisible: boolean
  topOffset?: string
}>`
  cursor: pointer;
  position: absolute;
  background-color: rgba(0, 0, 0, 0.8);
  top: ${p => p.topOffset ?? layoutValues.TOP_BAR_BASE_MOBILE_HEIGHT};
  left: 0;

  width: 100%;
  height: calc(var(--VH, 1vh) * 100 - ${p => p.topOffset ?? layoutValues.TOP_BAR_BASE_MOBILE_HEIGHT});
  max-height: calc(var(--VH, 1vh) * 100 - ${p => p.topOffset ?? layoutValues.TOP_BAR_BASE_MOBILE_HEIGHT});

  @media screen and (min-width: ${v2_breakpoint.desktop_small}) {
    top: ${p => p.topOffset ?? layoutValues.TOP_BAR_BASE_HEIGHT};
    height: calc(var(--VH, 1vh) * 100 - ${p => p.topOffset ?? layoutValues.TOP_BAR_BASE_HEIGHT});
    max-height: calc(var(--VH, 1vh) * 100 - ${p => p.topOffset ?? layoutValues.TOP_BAR_BASE_HEIGHT});
  }

  ${({ isVisible }) =>
    isVisible
      ? css`
          pointer-events: all;
          opacity: 1;
          transition: opacity 0.2s cubic-bezier(0.5, 1, 0.25, 1);
        `
      : css`
          pointer-events: none;
          opacity: 0;
          transition: opacity 0s cubic-bezier(0.5, 1, 0.25, 1);
        `}
`
