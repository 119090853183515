import { motion } from 'framer-motion'
import React from 'react'
import { AudioSfx } from 'sierra-client/components/liveV2/live-layer/audio-sfx'
import { AudioPlayerForId } from 'sierra-client/components/liveV2/live-layer/audioplayer'
import { Toolbar, getToolbarAnimationProps } from 'sierra-client/components/liveV2/live-layer/toolbar'
import { useIsIdle } from 'sierra-client/hooks/use-is-idle'
import { useSelector } from 'sierra-client/state/hooks'
import { selectRecoverableIssues, selectRemoteParticipants } from 'sierra-client/state/live/selectors'
import styled from 'styled-components'

const RemoteParticipantAudio = (): JSX.Element => {
  const participants = useSelector(selectRemoteParticipants)

  return (
    <>
      {participants.map(({ id }) => (
        <AudioPlayerForId key={id} agoraUid={id} />
      ))}
    </>
  )
}

const BottomContainer = styled(motion.div)`
  position: fixed;
  left: 0;
  right: 0;
  display: flex;
  justify-content: center;
  flex-direction: column;
  align-items: center;
  width: 100%;
  bottom: 0;
  pointer-events: none;
  will-change: opacity, scale;
`

export const LiveLayer: React.FC = () => {
  const isIdle = useIsIdle()
  const hasIssues = useSelector(state => selectRecoverableIssues(state).length > 0)

  const shouldHide = isIdle && !hasIssues

  return (
    <>
      <RemoteParticipantAudio />
      <AudioSfx />
      <BottomContainer {...getToolbarAnimationProps(shouldHide)}>
        <Toolbar shouldHide={shouldHide} />
      </BottomContainer>
    </>
  )
}
