import { useNotif } from 'sierra-client/components/common/notifications'
import { useShortcutMenuDispatch } from 'sierra-client/components/shortcut-menu/context'
import { errorLogger } from 'sierra-client/error/error-logger'
import { useDispatch, useSelector } from 'sierra-client/state/hooks'
import { useCamera } from 'sierra-client/state/live/actions'
import { selectAvailableCameras, selectCurrentCameraId } from 'sierra-client/state/live/selectors'
import { LabelMenuItem } from 'sierra-ui/components'

export const useCameraSettings = ():
  | {
      items: LabelMenuItem<string>[]
      currentId: string
      onItemSelected: (item: { id: string }) => void
    }
  | undefined => {
  const dispatch = useDispatch()
  const { push } = useNotif()
  const cameras = useSelector(selectAvailableCameras)
  const currentCameraId = useSelector(selectCurrentCameraId)

  const cameraItems: LabelMenuItem<string>[] =
    cameras?.map(device => ({
      id: device.deviceId,
      type: 'label',
      label: device.label,
      selected: device.deviceId === currentCameraId,
    })) ?? []

  const onChangeCamera = async ({ id: itemId }: { id: string }): Promise<void> => {
    try {
      // eslint-disable-next-line react-hooks/rules-of-hooks
      await dispatch(useCamera({ deviceId: itemId }))
    } catch (error) {
      errorLogger.captureWarning(error)
      push({ type: 'error', body: 'Failed to change camera' })
    }
  }

  const shortcutDispatch = useShortcutMenuDispatch()

  if (cameras === undefined || currentCameraId === undefined) return undefined
  return {
    items: cameraItems,
    currentId: currentCameraId,
    onItemSelected: item => {
      void onChangeCamera(item)
      shortcutDispatch({ type: 'close' })
    },
  }
}
