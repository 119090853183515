import { ProgressBarItem } from 'sierra-client/features/collapsable-sidebar/'
import {
  GlobalNestedBottomComponent,
  GlobalNestedSidebarComponent,
} from 'sierra-client/features/global-sidebar'
import {
  useRouterSelfPacedIds,
  useSafeRouterSelfPacedFlexibleContentId,
} from 'sierra-client/hooks/use-router-ids'
import { FileView } from 'sierra-client/learn/learn-ui/file-view/file-view'
import { useSelfPacedBigContextSafe } from 'sierra-client/views/flexible-content/self-paced-big-context'
import { Debug } from 'sierra-client/views/learner/components/debug'
import { useSelfPacedFilesSafe } from 'sierra-client/views/self-paced/files-provider'
import { SelfPacedTitle } from 'sierra-client/views/self-paced/header'
import { SelfPacedFileTree } from 'sierra-client/views/self-paced/self-paced-file-tree'
import { isDefined } from 'sierra-domain/utils'
import { View } from 'sierra-ui/primitives'

export const SelfPacedGlobalSidebar: GlobalNestedSidebarComponent = () => {
  const flexibleContentId = useSafeRouterSelfPacedFlexibleContentId()
  const fileContext = useSelfPacedFilesSafe()
  const context = useSelfPacedBigContextSafe()

  if (!isDefined(fileContext) || !isDefined(context) || !isDefined(flexibleContentId))
    return (
      <Debug>
        {!isDefined(fileContext) && 'fileContext is undefined'}
        {!isDefined(context) && 'context is undefined'}
        {!isDefined(flexibleContentId) && 'flexibleContentId is undefined'}
      </Debug>
    )

  return (
    <>
      <View paddingLeft='8' marginBottom='10' gap='8'>
        <SelfPacedTitle flexibleContentId={flexibleContentId} />
      </View>

      <FileView flexibleContentId={flexibleContentId} fileTree={<SelfPacedFileTree />} disableScroll={true} />
    </>
  )
}

export const SelfPacedGlobalBottomComponent: GlobalNestedBottomComponent = () => {
  const ids = useRouterSelfPacedIds()
  if (!isDefined(ids)) {
    return null
  }
  return <ProgressBarItem flexibleContentId={ids.flexibleContentId} />
}
