import { stringifySelection } from 'sierra-client/editor/debug/debug-data'
import { formatProps } from 'sierra-client/editor/utils/print-tree'
import { redactSlateDocument } from 'sierra-domain/editor/redact-slate-document'
import { omitUndefinedFields } from 'sierra-domain/omit-undefined-fields'
import { CustomElement, SanaEditor } from 'sierra-domain/v3-author'
import { AnnotatedEditorActionLogEntry } from 'sierra-domain/v3-author/action-log-entry'
import { Descendant, Editor, Element, Node, Text } from 'slate'
import { ReactEditor } from 'slate-react'

function tryGetIsComposing(editor: ReactEditor): string {
  try {
    return `${ReactEditor.isComposing(editor)}`
  } catch (e) {
    return 'unable to read isComposing state'
  }
}

function tryGetIsFocused(editor: ReactEditor): string {
  try {
    return `${ReactEditor.isFocused(editor)}`
  } catch (e) {
    return 'unable to read isFocused state'
  }
}

type CompactNode = [CustomElement['type'], string, CompactNode[]] | string
function compactedNodes(nodes: Descendant[]): CompactNode[] {
  function withParens(value: string | undefined): string {
    if (value === undefined || value.length === 0) return ''

    return `(${value})`
  }

  function getNodeMeta(node: Descendant): string {
    if (Element.isElement(node)) {
      const nodeMetaInner = [node.id, formatProps(node)].filter(Boolean).join(',')

      return withParens(nodeMetaInner)
    } else {
      return withParens(formatProps(node))
    }
  }

  function compactNode(node: Descendant): CompactNode {
    if (Text.isText(node)) {
      return [node.text, getNodeMeta(node)].filter(Boolean).join('-')
    } else {
      return [node.type, getNodeMeta(node), node.children.map(compactNode)]
    }
  }

  return nodes.map(compactNode)
}

export function getRedactedDocumentTreeString(node: Node): string {
  const nodes = Editor.isEditor(node) ? node.children : [node]
  const docWithoutUndefined = omitUndefinedFields(nodes)
  const redactedDoc = redactSlateDocument(docWithoutUndefined as Node[])
  return JSON.stringify(compactedNodes(redactedDoc as Descendant[]))
}

function stringifyActionsLog(recentActions: AnnotatedEditorActionLogEntry[]): string {
  const compactActions = recentActions.map(({ entry, selection }) => [entry, stringifySelection(selection)])

  return JSON.stringify(compactActions)
}

export function getEditorErrorMeta(editor: SanaEditor): Record<string, string> {
  return {
    isFocused: tryGetIsFocused(editor),
    isComposing: tryGetIsComposing(editor),
    selection: `=> ${stringifySelection(editor.selection)}`,
    recentActions: `=> ${stringifyActionsLog(editor.readRecentActionsLog())}`,
    // All these fields are sent to sentry when there is a crash as the
    // crash event's `meta` object. The length of the meta object is limited,
    // and everything beyond the limit is cut off. Since the editor's
    // content is the largest field and most likely cause the meta object
    // to overflow, we put it last lexicographically by calling it `state`.
    // It used to be called `editor`, which would cause it to appear first
    // and therefore cut off the other fields.
    state: `=> ${getRedactedDocumentTreeString(editor)}`,
  }
}
