import { AnimatePresence } from 'framer-motion'
import { FC, useCallback, useState } from 'react'
import { ActionModal } from 'sierra-client/components/common/modals/action-modal'
import { OrganizationLogo } from 'sierra-client/components/organization-logo'
import {
  CourseVisibilityDropdown,
  useCourseVisibilityDropdownAllowedItems,
} from 'sierra-client/components/sharing/course-permission-dropdown'
import { PermissionFormSection } from 'sierra-client/components/sharing/permission-form-elements'
import { useLocalizedFormatters } from 'sierra-client/core/format'
import { Card, SquareLogo } from 'sierra-client/features/multi-tenancy/components'
import {
  AdditionalPublishContainer,
  ButtonsContainer,
  Fading,
} from 'sierra-client/features/multi-tenancy/editor/distribution-settings-components'
import { OrganizationSettingsCard } from 'sierra-client/features/multi-tenancy/editor/organization-cluster-visibility-settings'
import {
  constructDistributionPatch,
  translateVisibility,
  useCourseDistributionSettingsMutation,
} from 'sierra-client/features/multi-tenancy/hooks/use-course-distribution-settings'
import { useOrganizationCluster } from 'sierra-client/features/multi-tenancy/hooks/use-organization-cluster'
import { useUnpublishDistributedCourseMutation } from 'sierra-client/features/multi-tenancy/hooks/use-unpublish-distributed-course-mutation'
import { organizationComparator } from 'sierra-client/features/multi-tenancy/utils/organization-comparator'
import { useFlexibleContentRecord } from 'sierra-client/hooks/use-flexible-content-record'
import { useTranslation } from 'sierra-client/hooks/use-translation'
import { typedInvalidateQuery, useCachedQuery, useTypedMutation } from 'sierra-client/state/api'
import { useCoursePermissionSettings } from 'sierra-client/views/flexible-content/editor/course-permission-settings-context'
import { ContentDistributionSettings, CourseVisibilityInOrg } from 'sierra-domain/api/author-v2'
import { CourseId, SelfPacedContentId } from 'sierra-domain/api/nano-id'
import { ScopedSelfPacedContentId } from 'sierra-domain/collaboration/types'
import { AccessibleOrganization } from 'sierra-domain/multi-tenancy'
import {
  XRealtimeAuthorGetCoursePublishState,
  XRealtimeAuthorPublishSelfPacedCourse,
  XRealtimeAuthorUnpublishSelfPacedCourse,
  XRealtimeGetCourseDistributionPublishStates,
} from 'sierra-domain/routes'
import { isDefined, isNonEmptyArray } from 'sierra-domain/utils'
import { Icon } from 'sierra-ui/components'
import { Button, LoadingSpinner, Text, View } from 'sierra-ui/primitives'
import { dotSeparator } from 'sierra-ui/utils'
import styled from 'styled-components'

const UnpublishText = styled(Text).attrs({
  color: 'foreground/muted',
})`
  transition: all 150ms;

  &:hover {
    color: red;
    cursor: pointer;
  }

  button {
    all: unset;
    color: inherit;
  }
`

type DistributionSettingsProps = {
  courseId: CourseId
  onModalClose: () => void
}

export const DistributionSettings: FC<DistributionSettingsProps> = ({ courseId, onModalClose }) => {
  const { t } = useTranslation()
  const { formatTimeAgo } = useLocalizedFormatters()

  const scopedCreateContentId = ScopedSelfPacedContentId.fromId(SelfPacedContentId.parse(courseId))

  const organizationCluster = useOrganizationCluster()

  const distributionSettingsMutation = useCourseDistributionSettingsMutation()
  const unpublishDistributedCourseMutation = useUnpublishDistributedCourseMutation()

  const { coursePermissionSettings, updateVisibilityInOrg } = useCoursePermissionSettings()
  const visibility =
    coursePermissionSettings.status === 'done' ? coursePermissionSettings.visibilityInOrg : null

  const publishStateQuery = useCachedQuery(XRealtimeGetCourseDistributionPublishStates, { courseId })
  const publishStates = (publishStateQuery.data?.publishState ?? []).sort((first, second) =>
    organizationComparator(first.organization, second.organization)
  )
  const courseVisibilityOptions = useCourseVisibilityDropdownAllowedItems(courseId, {
    privateEnabled: publishStates.filter(state => state.organization.isClusterParent).length === 0,
  })
  const [contentDistributionSettings, setContentDistributionSettings] = useState<
    ContentDistributionSettings[]
  >([])

  const record = useFlexibleContentRecord({ scopedCreateContentId })
  const hasExerciseContent = Object.values(record?.jsonData.nodeMap ?? {}).some(
    node => isDefined(node) && node.type === 'file' && node.data.type === 'homework'
  )

  const publishedOrgNamespaceIds = publishStates.map(state => state.organization.namespaceId)

  const unPublishedOrgs = organizationCluster.cluster?.filter(
    cluster => publishedOrgNamespaceIds.includes(cluster.namespaceId) === false
  )

  const [showUnPublishedOrgs, setShowUnPublishedOrgs] = useState(false)

  const [unpublishingFromOrganization, setUnpublishingFromOrganization] =
    useState<AccessibleOrganization | null>(null)

  const publishCourseMutation = useTypedMutation(XRealtimeAuthorPublishSelfPacedCourse, {
    onSettled: async () => {
      await typedInvalidateQuery(XRealtimeAuthorGetCoursePublishState, { contentId: courseId })
      await typedInvalidateQuery(XRealtimeGetCourseDistributionPublishStates, { courseId })

      setShowUnPublishedOrgs(false)
    },
  })

  const unpublishCourseMutation = useTypedMutation(XRealtimeAuthorUnpublishSelfPacedCourse, {
    onSettled: async () => {
      await typedInvalidateQuery(XRealtimeAuthorGetCoursePublishState, { contentId: courseId })
      await typedInvalidateQuery(XRealtimeGetCourseDistributionPublishStates, { courseId })

      onModalClose()
    },
  })

  const unpublishCourse = useCallback(() => {
    const organization = unpublishingFromOrganization

    if (organization === null) {
      return
    }

    if (organization.isClusterParent) {
      unpublishCourseMutation.mutate({ contentId: courseId })
    } else {
      return unpublishDistributedCourseMutation.mutate({
        namespaceId: organization.namespaceId,
        contentId: courseId,
      })
    }
  }, [courseId, unpublishCourseMutation, unpublishDistributedCourseMutation, unpublishingFromOrganization])

  const onVisibilityChange = useCallback(
    (newVisibility: CourseVisibilityInOrg) => {
      void updateVisibilityInOrg(courseId, newVisibility)

      if (!organizationCluster.loading && organizationCluster.selfIsParent && newVisibility !== 'private') {
        const newContentDistributionSettings = contentDistributionSettings.map(setting => ({
          ...setting,
          visibility: translateVisibility(newVisibility),
        }))
        setContentDistributionSettings(newContentDistributionSettings)

        const distributionPatches = organizationCluster.cluster
          .filter(org => !org.isClusterParent)
          .map(org => constructDistributionPatch(org, { visibility: newVisibility }))
          .filter(isDefined)

        distributionSettingsMutation.mutate({
          courseId,
          settings: distributionPatches,
        })
      }
    },
    [
      updateVisibilityInOrg,
      courseId,
      organizationCluster,
      contentDistributionSettings,
      distributionSettingsMutation,
    ]
  )

  const onTogglePublishing = useCallback(
    (organization: AccessibleOrganization, checked: boolean) => {
      if (visibility === null || visibility === 'private') throw new Error('Invalid visibility')

      const patch = constructDistributionPatch(organization, { visibility })

      let settings
      if (checked) {
        settings = contentDistributionSettings.concat([patch])
      } else {
        settings = contentDistributionSettings.filter(
          setting => setting.childNamespaceId !== organization.namespaceId
        )
      }

      setContentDistributionSettings(settings)
    },
    [visibility, contentDistributionSettings]
  )

  if (organizationCluster.loading || visibility === null) {
    return (
      <View padding='64' justifyContent='center'>
        <LoadingSpinner />
      </View>
    )
  }

  return (
    <>
      <View direction='column' padding='32' gap='24'>
        <header>
          <View>
            <Icon iconId='checkmark--filled' color='success/background' />
            <Text bold size='large' as='h2'>
              {t('admin.author.published')}
            </Text>
          </View>
        </header>

        <View direction='column' gap='24'>
          <PermissionFormSection
            title={t('share.visibility-permission.title')}
            subtitle={t('share.visibility-permission.subtitle')}
          >
            <CourseVisibilityDropdown
              selectedVisibility={visibility}
              onSelect={onVisibilityChange}
              options={courseVisibilityOptions}
            />
          </PermissionFormSection>

          <View direction='column' gap='8' padding='2'>
            <AnimatePresence initial={false}>
              {publishStates.map(state => {
                const organization = state.organization
                const settingsUrl = new URL(`manage/courses/${courseId}`, `https://${organization.domain}`)

                return (
                  <Card key={organization.domain}>
                    <View justifyContent='space-between' grow>
                      <View gap='12'>
                        {isDefined(organization.squareLogoUrl) ? (
                          <SquareLogo src={organization.squareLogoUrl} />
                        ) : (
                          <OrganizationLogo orgName={organization.name} brandSettings={undefined} />
                        )}

                        <View direction='column' gap='none'>
                          <View>
                            <Text bold size='small'>
                              {organization.name}
                            </Text>
                            {organization.isClusterParent === true && (
                              <Icon iconId='building' color='foreground/muted' />
                            )}
                          </View>

                          <View gap='4'>
                            <Text color='foreground/muted'>
                              {t('manage.published-at', { date: formatTimeAgo(state.lastPublishedAt) })}
                            </Text>

                            <Text color='foreground/muted'>{dotSeparator}</Text>
                            <UnpublishText>
                              <button
                                onClick={() => {
                                  setUnpublishingFromOrganization(organization)
                                }}
                              >
                                {t('admin.author.unpublish')}
                              </button>
                            </UnpublishText>
                          </View>
                        </View>
                      </View>

                      <Button
                        variant='secondary'
                        icon={organization.isClusterParent ? 'settings' : 'launch'}
                        href={settingsUrl.toString()}
                        target='_blank'
                        rel='noreferrer'
                      >
                        {t('settings.settings')}
                      </Button>
                    </View>
                  </Card>
                )
              })}
            </AnimatePresence>
          </View>

          {isNonEmptyArray(unPublishedOrgs) && (
            <View>
              <AnimatePresence initial={false} mode='wait'>
                {showUnPublishedOrgs ? (
                  <AdditionalPublishContainer key='unpublish-list-container'>
                    <View direction='column' padding='2'>
                      <Text bold color='foreground/muted'>
                        {t('multi-tenancy.content-distribution.publish-to')}
                      </Text>
                      {unPublishedOrgs.map(unPublishedOrg => (
                        <View key={unPublishedOrg.namespaceId} grow>
                          <OrganizationSettingsCard
                            organization={unPublishedOrg}
                            onTogglePublishing={onTogglePublishing}
                            publishingInitial={false}
                            locked={false}
                            unavailable={visibility === 'private'}
                          />
                        </View>
                      ))}
                    </View>

                    <ButtonsContainer>
                      <Button
                        variant='secondary'
                        onClick={() => {
                          setShowUnPublishedOrgs(false)
                        }}
                      >
                        {t('dictionary.cancel')}
                      </Button>
                      <Button
                        variant='primary'
                        loading={publishCourseMutation.isPending}
                        onClick={() => {
                          publishCourseMutation.mutate({
                            contentId: courseId,
                            newContentDistributionSettings: contentDistributionSettings,
                          })
                        }}
                      >
                        {t('author.publish')}
                      </Button>
                    </ButtonsContainer>
                  </AdditionalPublishContainer>
                ) : (
                  <Fading key='publish-to-other-orgs' duration={0.15}>
                    <Button variant='ghost' onClick={() => setShowUnPublishedOrgs(!showUnPublishedOrgs)} grow>
                      {t('multi-tenancy.content-distribution.publish-to-other.button-label')}
                    </Button>
                  </Fading>
                )}
              </AnimatePresence>
            </View>
          )}
        </View>

        {hasExerciseContent && (
          <View background='surface/soft' padding='16' radius='size-12' direction='column' gap='none'>
            <Text bold>{t('multi-tenancy.content-distribution.exercises-warning.title')}</Text>
            <Text>{t('multi-tenancy.content-distribution.exercises-warning.body')}</Text>
          </View>
        )}
      </View>

      <ActionModal
        open={isDefined(unpublishingFromOrganization)}
        onClose={() => setUnpublishingFromOrganization(null)}
        deleteAction
        primaryAction={() => unpublishCourse()}
        primaryActionLabel='Unpublish'
        title={
          unpublishingFromOrganization?.isClusterParent === true
            ? t('multi-tenancy.content-distribution.unpublish-from-parent-warning.title')
            : t('multi-tenancy.content-distribution.unpublish-from-child-warning.title', {
                organization: unpublishingFromOrganization?.name,
              })
        }
      >
        <Text>
          {unpublishingFromOrganization?.isClusterParent === true
            ? t('multi-tenancy.content-distribution.unpublish-from-parent-warning.body')
            : t('multi-tenancy.content-distribution.unpublish-from-child-warning.body', {
                organization: unpublishingFromOrganization?.name,
              })}
        </Text>
      </ActionModal>
    </>
  )
}
