import { WidgetBuilderState } from 'sierra-client/features/insights/widget-builder/widget-builder-state'
import { DashboardWidget, SortBy } from 'sierra-domain/api/insights'

export type PartialDashboardWidget = Omit<DashboardWidget, 'layout' | 'id'>

export type MaybePartialDashboardWidget = PartialDashboardWidget | DashboardWidget

export type WidgetSortHandler = (sortBy: SortBy) => void

export const hasTimeFilter = (widget: WidgetBuilderState): boolean => {
  switch (widget.visualisation.type) {
    case 'table':
    case 'pivot-table':
    case 'line-chart':
    case 'bar-chart':
      return true
    default:
      return false
  }
}
