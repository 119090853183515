import { TemplateFilter, TemplateTabCategory } from 'sierra-client/components/templates/template-filter'
import { useTranslation } from 'sierra-client/hooks/use-translation'

export const useTemplateCategoryTexts = (): {
  getLabel: (category?: TemplateTabCategory) => string
  getHeading: (filter: TemplateFilter) => string
} => {
  const { t } = useTranslation()

  const templateCategoryLabels = {
    [TemplateTabCategory.Search]: t('dictionary.search'),
    [TemplateTabCategory.Recommended]: t('dictionary.recommended'),
    [TemplateTabCategory.Live]: t('create.home.live-templates'),
    [TemplateTabCategory.SelfPaced]: t('create.home.course-templates'),
    [TemplateTabCategory.Workshops]: t('dictionary.workshops'),
    [TemplateTabCategory.Onboarding]: t('dictionary.onboarding'),
    [TemplateTabCategory.AllTeamTemplates]: t('create.home.team-templates'),
    [TemplateTabCategory.LiveTeamTemplates]: t('template-picker.my-organization'),
    [TemplateTabCategory.SelfPacedTeamTemplates]: t('template-picker.my-organization'),
    [TemplateTabCategory.ReadyMadeCourses]: t('create.home.ready-made-courses'),
    [TemplateTabCategory.LeadershipDevelopment]: t('create.home.leadership-development'),
    [TemplateTabCategory.SalesEnablement]: t('create.home.sales-enablement'),
    [TemplateTabCategory.Compliance]: t('dictionary.compliance'),
    [TemplateTabCategory.ProductManagement]: t('create.home.product-management'),
    [TemplateTabCategory.SanaOnboarding]: t('create.home.sana-onboarding'),
    [TemplateTabCategory.All]: t('dictionary.all'),
    [TemplateTabCategory.Community]: t('create.home.community-templates'),
  }

  const templateCategoryHeadings = {
    [TemplateTabCategory.Search]: t('dictionary.search'),
    [TemplateTabCategory.Recommended]: t('create.home.recommended-templates'),
    [TemplateTabCategory.Live]: t('create.home.all-live-templates'),
    [TemplateTabCategory.SelfPaced]: t('create.home.all-course-templates'),
    [TemplateTabCategory.Workshops]: t('create.home.workshop-templates'),
    [TemplateTabCategory.Onboarding]: t('create.home.onboarding-templates'),
    [TemplateTabCategory.AllTeamTemplates]: t('create.home.team-templates'),
    [TemplateTabCategory.LiveTeamTemplates]: t('create.home.team-templates'),
    [TemplateTabCategory.SelfPacedTeamTemplates]: t('create.home.team-templates'),
    [TemplateTabCategory.ReadyMadeCourses]: t('create.home.ready-made-courses'),
    [TemplateTabCategory.LeadershipDevelopment]: t('create.home.leadership-development'),
    [TemplateTabCategory.SalesEnablement]: t('create.home.sales-enablement'),
    [TemplateTabCategory.Compliance]: t('dictionary.compliance'),
    [TemplateTabCategory.ProductManagement]: t('create.home.product-management'),
    [TemplateTabCategory.SanaOnboarding]: t('create.home.sana-onboarding'),
    [TemplateTabCategory.All]: t('dictionary.all'),
    [TemplateTabCategory.Community]: t('create.home.community-templates'),
  }

  return {
    getLabel: (category?: TemplateTabCategory) =>
      category !== undefined ? templateCategoryLabels[category] : '',
    getHeading: (filter: TemplateFilter) => {
      if (filter.primary === TemplateTabCategory.Live && filter.secondary === TemplateTabCategory.All) {
        return t('create.home.all-live-templates')
      }

      if (filter.primary === TemplateTabCategory.SelfPaced && filter.secondary === TemplateTabCategory.All) {
        return t('create.home.all-course-templates')
      }

      return templateCategoryHeadings[filter.secondary ?? filter.primary]
    },
  }
}
