import React, { useCallback, useEffect, useMemo, useRef, useState } from 'react'
import { useLearnerTheme } from 'sierra-client/hooks/use-learner-theme'
import { useResolveAsset } from 'sierra-client/hooks/use-resolve-asset'
import { PageIdentifier, SanaPage } from 'sierra-client/layout/sana-page'
import { RouteId, getGlobalRouter } from 'sierra-client/router'
import {
  selectCurrentCourse,
  selectCurrentCourseSettings,
  selectCurrentLinkCourse,
  selectCurrentPartnerCourse,
  selectCurrentScormCourse,
} from 'sierra-client/state/content/selectors'
import { CourseGroupEntity, LiveCourseEntity } from 'sierra-client/state/content/types'
import { useDispatch, useSelector } from 'sierra-client/state/hooks'
import { fetchCourseStatusById } from 'sierra-client/state/v2/courses-actions'
import { selectTimeLeft } from 'sierra-client/state/v2/selectors'
import { fetchTagsData } from 'sierra-client/state/v2/tags-actions'
import { OverviewHeader } from 'sierra-client/views/learner/components/overview/header'
import { useSortedEditions } from 'sierra-client/views/learner/course-group/hooks'
import { CourseSummary } from 'sierra-client/views/learner/course/components/summary'
import { UpcomingSessions } from 'sierra-client/views/learner/course/upcoming-sessions'
import { useSelfEnroll } from 'sierra-client/views/learner/self-enroll-live-session/hooks'
import { LinkedInCourseDetailsOutline } from 'sierra-client/views/manage/linked-in/linked-in-course-details'
import { LinkedInLearningChip } from 'sierra-client/views/manage/linked-in/linked-in-utils'
import { ScormChip } from 'sierra-client/views/manage/scorm/scorm-utils'
import { EditionWithStatus } from 'sierra-domain/api/content-v2'
import { CourseId, PathId, SelfPacedContentId } from 'sierra-domain/api/nano-id'
import { ProgramId } from 'sierra-domain/api/uuid'
import { v2_breakpoint } from 'sierra-ui/theming/breakpoints'
import { ThemeProvider, createGlobalStyle } from 'styled-components'

const GlobalStylesHome = createGlobalStyle`
  #__next {
    /* This is to allow sticky behavior on the left column on desktop */
    @media screen and (min-width: ${v2_breakpoint.tablet}) {
      overflow: visible !important;
    }
  }
`

const LinkedInCourse: React.FC = () => {
  const partnerCourse = useSelector(selectCurrentPartnerCourse)
  const courseSettings = useSelector(selectCurrentCourseSettings)
  const timeLeft = useSelector(selectTimeLeft)

  const assetContext =
    partnerCourse?.id !== undefined
      ? { type: 'course' as const, courseId: CourseId.parse(partnerCourse.id) }
      : { type: 'unknown' as const }
  const imageSrc = useResolveAsset({ image: courseSettings?.image, assetContext, size: 'default' })

  if (partnerCourse === undefined || courseSettings === undefined) return null

  const timeLeftForCourse = timeLeft[partnerCourse.id]?.total ?? partnerCourse.timeToComplete
  return (
    <>
      <GlobalStylesHome />
      <OverviewHeader
        title={courseSettings.title}
        image={imageSrc}
        titleTag={<LinkedInLearningChip variant='white' />}
      />

      <CourseSummary
        // TODO: Can be parsed at the request level after we switch everything to zod
        courseId={CourseId.parse(partnerCourse.id)}
        courseType='linkedin'
        tagIds={courseSettings.tags}
        description={courseSettings.description}
        timeLeft={timeLeftForCourse}
        timeTotal={partnerCourse.timeToComplete}
      >
        <LinkedInCourseDetailsOutline contents={partnerCourse.contents} />
      </CourseSummary>
    </>
  )
}

const ScormCourse: React.FC = () => {
  const scormCourse = useSelector(selectCurrentScormCourse)
  const courseSettings = useSelector(selectCurrentCourseSettings)

  const assetContext =
    scormCourse?.id !== undefined
      ? { type: 'course' as const, courseId: CourseId.parse(scormCourse.id) }
      : { type: 'unknown' as const }
  const imageSrc = useResolveAsset({ image: courseSettings?.image, assetContext, size: 'default' })

  if (scormCourse === undefined || courseSettings === undefined) return null

  return (
    <>
      <GlobalStylesHome />

      <OverviewHeader
        title={courseSettings.title}
        image={imageSrc}
        titleTag={<ScormChip variant='course' />}
      />

      <CourseSummary
        courseId={CourseId.parse(scormCourse.id)}
        courseType='scorm'
        tagIds={courseSettings.tags}
        description={courseSettings.description}
      />
    </>
  )
}

const LinkCourse: React.FC = () => {
  const course = useSelector(selectCurrentLinkCourse)
  const courseSettings = useSelector(selectCurrentCourseSettings)
  const timeLeft = useSelector(selectTimeLeft)

  const assetContext =
    course?.id !== undefined
      ? { type: 'course' as const, courseId: CourseId.parse(course.id) }
      : { type: 'unknown' as const }
  const timeLeftForCourse = course?.id !== undefined ? timeLeft[course.id]?.total ?? undefined : undefined

  const imageSrc = useResolveAsset({ image: courseSettings?.image, assetContext, size: 'default' })

  if (course === undefined || courseSettings === undefined) return null

  return (
    <>
      <GlobalStylesHome />

      <OverviewHeader title={courseSettings.title} image={imageSrc} />

      <CourseSummary
        courseId={CourseId.parse(course.id)}
        courseType='link'
        tagIds={courseSettings.tags}
        description={courseSettings.description}
        timeLeft={timeLeftForCourse}
      />
    </>
  )
}

const NativeLiveCourse: React.FC<{ course: LiveCourseEntity }> = ({ course }) => {
  const selfEnrollRef = useRef<HTMLDivElement>(null)
  const selfEnrollState = useSelfEnroll(course.selfEnrollSessions ?? [])
  const courseId = course.id

  const scrollToSelfEnrollSessions = useCallback(() => {
    selfEnrollRef.current?.scrollIntoView({ block: 'start', behavior: 'smooth' })
  }, [selfEnrollRef])

  const courseSettings = course.settings

  const assetContext = { type: 'course' as const, courseId: CourseId.parse(course.id) }
  const imageSrc = useResolveAsset({ image: courseSettings.image, assetContext, size: 'default' })

  return (
    <>
      <GlobalStylesHome />

      <OverviewHeader title={courseSettings.title} image={imageSrc} />

      <CourseSummary
        courseId={CourseId.parse(courseId)}
        courseType={course.type}
        tagIds={courseSettings.tags}
        description={courseSettings.description}
        scrollToSelfEnrollSessions={scrollToSelfEnrollSessions}
        availableSelfEnrollSessions={
          course.selfEnrollSessions !== undefined ? selfEnrollState.sessionList : undefined
        }
        upcomingAssignedSessions={course.upcomingAssignedSessions}
      >
        {course.selfEnrollSessions !== undefined && (
          <UpcomingSessions
            selfEnrollRef={selfEnrollRef}
            courseImageUrl={imageSrc}
            selfEnrollState={selfEnrollState}
          />
        )}
      </CourseSummary>
    </>
  )
}

const NativeCourseGroup: React.FC<{ course: CourseGroupEntity }> = ({ course }) => {
  const [selectedEdition, setSelectedEdition] = useState<EditionWithStatus | undefined>(undefined)

  const editionsWithStatus = useSortedEditions({ courseGroupId: course.id })

  // Update default selection after fetch
  useEffect(() => {
    setSelectedEdition(editionsWithStatus?.[0])
  }, [editionsWithStatus])

  const durationValues = useMemo<{ timeLeft: number; timeTotal: number } | undefined>(() => {
    if (selectedEdition === undefined) return undefined

    return {
      timeLeft: selectedEdition.data.duration * (1 - selectedEdition.status.progress),
      timeTotal: selectedEdition.data.duration,
    }
  }, [selectedEdition])

  const assetContext = { type: 'course' as const, courseId: CourseId.parse(course.id) }
  const imageSrc = useResolveAsset({ image: course.settings.image, assetContext, size: 'default' })

  return (
    <>
      <GlobalStylesHome />

      <OverviewHeader title={selectedEdition?.data.title ?? course.settings.title} image={imageSrc} />

      <CourseSummary
        courseId={CourseId.parse(course.id)}
        courseType={course.type}
        tagIds={course.settings.tags}
        description={
          selectedEdition !== undefined ? selectedEdition.data.description : course.settings.description
        }
        timeLeft={durationValues?.timeLeft}
        timeTotal={durationValues?.timeTotal}
        availableEditions={editionsWithStatus}
        selectedEdition={selectedEdition}
        setSelectedEdition={(edition: EditionWithStatus | undefined) => setSelectedEdition(edition)}
      />
    </>
  )
}

const CoursePageContent = (): JSX.Element | null => {
  const dispatch = useDispatch()
  const course = useSelector(selectCurrentCourse)

  useEffect(() => {
    if (!course) return
    void dispatch(fetchTagsData())
    void dispatch(fetchCourseStatusById({ courseId: course.id }))
  }, [dispatch, course])

  if (!course) return null

  if (course.type === 'scorm') return <ScormCourse />
  if (course.type === 'linkedin') return <LinkedInCourse />
  if (course.type === 'link') return <LinkCourse />
  if (course.type === 'native:live') return <NativeLiveCourse course={course} />
  if (course.type === 'native:course-group' || course.type === 'scorm:course-group')
    return <NativeCourseGroup course={course} />

  // We don't render the page for the type native:self-paced.
  // The plugin V4Redirect handles redirection from /course/[courseId] to /s/[courseId]
  return null
}

export type CoursePageProps = {
  courseId: CourseId
}

export const CoursePage = ({ courseId }: CoursePageProps): JSX.Element => {
  const learnerTheme = useLearnerTheme()

  return (
    <SanaPage mode='light' showIntercomLauncher={true} page={PageIdentifier.LearnerCourse({ courseId })}>
      <ThemeProvider theme={learnerTheme}>
        <CoursePageContent />
      </ThemeProvider>
    </SanaPage>
  )
}

export type NextCoursePageProps = {
  courseId: CourseId
  programId?: ProgramId
  pathId?: PathId
}

export const NextCoursePage = ({ courseId, programId, pathId }: NextCoursePageProps): null => {
  const course = useSelector(selectCurrentCourse)
  const editionsWithStatus = useSortedEditions({
    courseGroupId: typeof courseId === 'string' ? courseId : undefined,
  })

  useEffect(() => {
    if (course === undefined || courseId !== course.id) return

    if (course.type === 'scorm') {
      void getGlobalRouter().navigate({
        to: pathId !== undefined ? '/path/$pathId/course/$courseId/scorm' : '/course/$courseId/scorm',
        params: { pathId, courseId },
        replace: true,
      })
    } else if (course.type === 'native:course-group' || course.type === 'scorm:course-group') {
      const nextCourseId = editionsWithStatus?.[0]?.id

      // Nothing to do. Maybe we should redirect to the home page or something?
      if (nextCourseId === undefined) return

      const isScormGroup = course.type === 'scorm:course-group'
      const programSegment = programId !== undefined ? '/program/$programId' : ''
      const pathSegment = pathId !== undefined ? '/path/$pathId' : ''
      const courseSegment = isScormGroup ? '/course/$courseId/scorm' : '/s/$flexibleContentId/$fileId'
      const fullTargetRoute = `${programSegment}${pathSegment}${courseSegment}` as const satisfies RouteId

      void getGlobalRouter().navigate({
        to: fullTargetRoute,
        params: {
          programId,
          pathId,
          courseId: nextCourseId,
          flexibleContentId: nextCourseId as SelfPacedContentId,
          fileId: 'next',
        },
        replace: true,
      })
    }

    // V4Redirect will handle the self-paced case.
    return
  }, [course, courseId, editionsWithStatus, pathId, programId])

  return null
}
