import React, { useCallback } from 'react'
import {
  DialogIcon,
  DialogTitleWithIcons,
  DialogTitleWithIconsProps,
} from 'sierra-client/views/manage/components/dialog-utils'
import { Modal as UIModal } from 'sierra-ui/components'
import { Text, View } from 'sierra-ui/primitives'
import styled from 'styled-components'

const CloseIcon = styled(DialogIcon)`
  position: absolute;
  top: 2rem;
  right: 2rem;
`

const StyledDialogActions = styled(View)`
  position: relative;
  padding: 2rem 1rem;
  justify-content: center;

  &::before {
    content: '';
    position: absolute;
    top: 0;
    left: 2rem;
    right: 2rem;
    height: 1px;
    background-color: ${p => p.theme.color.grey10};
  }
`

const modalWidths = {
  xsmall: 444,
  small: 530,
  medium: 750,
  large: 1050,
  xlarge: 1440,
} as const

type ModalSize = keyof typeof modalWidths

export type ModalProps = {
  title?: string
  open: boolean
  size?: ModalSize
  footer?: React.ReactNode
  hideSeparator?: DialogTitleWithIconsProps['hideSeparator']
  hideCloseIcon?: boolean
  onClose?: (reason?: 'backdropClick' | 'escapeKeyDown' | 'closeButtonClicked') => void | Promise<void>
  children: React.ReactNode
}

/**
 * @deprecated
 **/
export const Modal: React.FC<ModalProps> = ({
  title,
  open,
  size = 'small',
  children,
  footer,
  hideSeparator,
  hideCloseIcon = false,
  onClose,
  ...props
}) => {
  const modalWidth = modalWidths[size]
  const closeButtonAction = useCallback(() => onClose?.('closeButtonClicked'), [onClose])

  return (
    <UIModal open={open} onClose={onClose} size={{ width: modalWidth }} {...props}>
      <View style={{ maxHeight: '100%' }} alignItems='flex-start'>
        <View
          position='relative'
          direction='column'
          grow
          alignItems='stretch'
          gap='none'
          style={{ height: '100%' }}
        >
          {title !== undefined ? (
            <DialogTitleWithIcons
              rightIcon={hideCloseIcon ? undefined : <DialogIcon onClick={closeButtonAction} />}
              hideSeparator={hideSeparator}
            >
              <Text color='foreground/primary' size='regular' bold align='center'>
                {title}
              </Text>
            </DialogTitleWithIcons>
          ) : (
            <>{!hideCloseIcon && <CloseIcon onClick={closeButtonAction} />}</>
          )}
          <View padding='medium' overflow='auto' alignItems='flex-start'>
            <View direction='column' grow>
              {children}
            </View>
          </View>
          {footer !== undefined ? <StyledDialogActions>{footer}</StyledDialogActions> : null}
        </View>
      </View>
    </UIModal>
  )
}
