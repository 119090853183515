import { createSelector } from '@reduxjs/toolkit'
import { Clock, CollaborationState } from 'sierra-client/state/collaboration/types'
import { RootState } from 'sierra-client/state/types'

const selectState = (state: RootState): CollaborationState => state.collaboration

export const getServerTimeNow = (clock?: Clock): string => {
  if (clock === undefined) return new Date().toISOString()
  const deltaMs = performance.now() - clock.relativeEpochMs
  return new Date(clock.serverEpochMs + deltaMs).toISOString()
}

export const selectClock = createSelector(selectState, state => state.clock)

export const selectCollabProviderStatuses = createSelector(selectState, state =>
  Object.values(state.collabProviderStatuses)
)
