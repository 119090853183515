import {
  ButtonClickedEventId,
  ButtonClickedProps,
  CourseButtonId,
} from 'sierra-client/core/logging/interaction/types'
import { Logger } from 'sierra-client/core/logging/logger'
import { courseSelectors } from 'sierra-client/state/content/selectors'
import { RootState } from 'sierra-client/state/types'
import { CourseId } from 'sierra-domain/api/nano-id'

type CourseMetadata = {
  courseTitle?: string
}

export const selectMetadata = (state: RootState, courseId: CourseId): CourseMetadata => {
  const course = courseSelectors.selectById(state, courseId)

  return {
    courseTitle: course?.settings.title,
  }
}
type CourseButtonClickedProps = {
  courseId: CourseId
  buttonId: CourseButtonId
}

export const buttonClicked = Logger.trackLoggerWithExtra<CourseButtonClickedProps, ButtonClickedProps>(
  ButtonClickedEventId,
  (input, state) => {
    return {
      ...input,
      ...selectMetadata(state, input.courseId),
    }
  }
)

export type ImpressionContainers = // Used in intercom, change with caution

    | 'primary'
    | 'interest'
    | 'recommended'
    | 'related'
    | 'up-next'
    | 'in-progress'
    | 'browse'
    | 'completed'
    | 'skill'
    | 'course-list'
    | 'live-session'
    | 'assigned'
    | 'role'

export type HomeImpressionProps = {
  container: ImpressionContainers
  containerPosition: number
  itemPosition: number
}

export type HomeCourseImpressionProps = HomeImpressionProps & {
  courseId: string
}

export type HomeClickProps = {
  container: ImpressionContainers
  containerPosition: number
  itemPosition: number
}
