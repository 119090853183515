import React from 'react'
import { Icon } from 'sierra-ui/components'
import { View } from 'sierra-ui/primitives'
import { palette } from 'sierra-ui/theming'
import styled from 'styled-components'

const NextIcon = styled(Icon)`
  color: ${palette.primitives.black};
  width: 24px;
  height: 24px;
`

const ButtonContainer = styled(View).attrs({
  gap: 'xxsmall',
})`
  justify-content: center;
  background-color: rgba(255, 255, 255, 0.9);
  border: 1px solid ${palette.grey[5]};
  box-shadow: 0px 4px 12px rgba(0, 0, 0, 0.04);
  backdrop-filter: blur(40px);
  z-index: 1;
  border-radius: 8px;
  padding: 0 12px;
  height: 2.5rem;

  &:empty {
    display: none;
  }
`

const ButtonWrapper = styled.div<{ $disabled?: boolean }>`
  display: flex;
  flex-direction: row;
  gap: 1rem;
  cursor: ${p => (p.$disabled === true ? 'default' : 'pointer')};
`

export type NextButtonAction = {
  message?: string
  arrow?: {
    action: () => void
  }
  nextAction?: {
    label: string
    action: undefined | (() => void)
  }
  secondaryAction?: {
    // back to program, back to path...
    label: string
    action: () => void
  }
}

export const NextUpButton = React.forwardRef<
  HTMLDivElement,
  { action: NextButtonAction; className?: string }
>(({ action, className }, ref) => {
  return (
    <ButtonWrapper
      role='button'
      aria-label='Next'
      id='self-paced-next-up'
      onClick={action.arrow?.action}
      $disabled={action.arrow?.action === undefined}
      className={className}
      ref={ref}
    >
      {action.arrow !== undefined && (
        <ButtonContainer>
          <NextIcon iconId='arrow--right' />
        </ButtonContainer>
      )}
    </ButtonWrapper>
  )
})

export const GoToPreviousButton = React.forwardRef<
  HTMLDivElement,
  { goToPrevious: () => void; className?: string }
>(({ className, goToPrevious }, ref) => {
  return (
    <ButtonWrapper className={className} role='button' ref={ref} onClick={goToPrevious}>
      <ButtonContainer>
        <NextIcon iconId='arrow--left' />
      </ButtonContainer>
    </ButtonWrapper>
  )
})
