import { atom, useAtom } from 'jotai'
import { useCallback, useEffect } from 'react'
import { useSelector } from 'react-redux'
import { useCachedQuery } from 'sierra-client/state/api'
import { selectUserId } from 'sierra-client/state/user/user-selector'
import { Teamspace } from 'sierra-domain/api/teamspace'
import { XRealtimeTeamspaceListSidebarTeamspaces } from 'sierra-domain/routes'

type TeamspaceState = { data: Teamspace[] | undefined; refetch: () => void }

const teamspaceState = atom<{
  // User the userId as a cache key so that if a user logs
  // out and another user logs in there won't be a collision
  [userId: string]: TeamspaceState
}>({})

const defaultUserState: TeamspaceState = { data: undefined, refetch: () => {} }

/**
 * This is a global cache for teamspaces. Calling `refetch` will update
 * all places where teamspaces are used.
 */
export function useSidebarTeamspaces(): TeamspaceState {
  const userId = useSelector(selectUserId)
  const [state, setState] = useAtom(teamspaceState)

  const { refetch, data } = useCachedQuery(XRealtimeTeamspaceListSidebarTeamspaces, {})

  const updateUserTeamspaceState = useCallback(
    (newState: Partial<TeamspaceState>): void => {
      if (userId === undefined) return

      setState(oldState => {
        const userState: TeamspaceState = {
          ...(oldState[userId] ?? defaultUserState),
          ...newState,
        }
        return { ...oldState, [userId]: userState }
      })
    },
    [setState, userId]
  )

  useEffect(() => updateUserTeamspaceState({ refetch }), [refetch, updateUserTeamspaceState])
  useEffect(() => updateUserTeamspaceState({ data }), [data, updateUserTeamspaceState])

  if (userId === undefined) return defaultUserState
  return state[userId] ?? defaultUserState
}
