import { DateTime } from 'luxon'
import { useCallback, useEffect, useState } from 'react'
import { useLiveSessionStateMutation } from 'sierra-client/api/hooks/use-live-session'
import {
  useLiveSessionContext,
  useLiveSessionState,
} from 'sierra-client/components/liveV2/contexts/live-session-data'
import { CountDown } from 'sierra-client/components/liveV2/count-down-hoc'
import { useIsFacilitatorOrLearnerLedSession } from 'sierra-client/components/liveV2/hooks/use-is-facilitator-or-learner-led-session'
import { useLiveContext } from 'sierra-client/components/liveV2/live-context'
import { useTranslation } from 'sierra-client/hooks/use-translation'
import { useDispatch, useSelector } from 'sierra-client/state/hooks'
import { liveSessionStateChanged } from 'sierra-client/state/live-session/actions'
import { selectCallIsActive } from 'sierra-client/state/live/selectors'
import { Modal, ModalHeader } from 'sierra-ui/components'
import { Button, Text, View } from 'sierra-ui/primitives'
import styled from 'styled-components'
const ModalWrapper = styled(View).attrs({
  direction: 'column',
  justifyContent: 'flex-start',
  paddingTop: '24',
  paddingBottom: '32',
  paddingRight: '24',
  paddingLeft: '32',
  grow: true,
})`
  min-width: 450px;
  min-height: 200px;
`

const Actions = styled(View).attrs({ gap: '8' })`
  align-self: flex-end;
`

const Content = styled(View).attrs({ direction: 'column', justifyContent: 'flex-start', grow: true })``

const CountDownText = styled(Text).attrs({ color: 'grey35', size: 'small' })`
  font-variant-numeric: tabular-nums;
`

export const LiveSessionEndedModal: React.FC = () => {
  const [open, setOpen] = useState(false)
  const [countdownEnd, setCountdownEnd] = useState<DateTime | null>(null)
  const { leave } = useLiveContext()
  const { t } = useTranslation()
  const isFacilitator = useIsFacilitatorOrLearnerLedSession()
  const joined = useSelector(selectCallIsActive)
  const mutation = useLiveSessionStateMutation()
  const liveSession = useLiveSessionContext()
  const dispatch = useDispatch()
  const isLearnerLed = useLiveSessionContext().data.learnerLedSession

  const openAndStartCountdown = useCallback(() => {
    setOpen(true)
    setCountdownEnd(DateTime.local().plus({ seconds: 10 }))
  }, [])

  const closeAndStopCountdown = useCallback(() => {
    setOpen(false)
    setCountdownEnd(null)
  }, [])

  const reopenSession = useCallback(async () => {
    mutation.mutate(
      {
        liveSessionId: liveSession.liveSessionId,
        state: 'active',
      },
      {
        onSuccess: () => {
          void dispatch(liveSessionStateChanged('active'))
          closeAndStopCountdown()
        },
      }
    )
  }, [closeAndStopCountdown, dispatch, liveSession.liveSessionId, mutation])

  const leaveCall = useCallback(async () => {
    await leave()
    closeAndStopCountdown()
  }, [closeAndStopCountdown, leave])

  const sessionState = useLiveSessionState()

  useEffect(() => {
    if (sessionState === 'ended' && joined) {
      // delay showing the leave modal to prevent the user who closed the session from seeing it
      const timeout = setTimeout(() => {
        openAndStartCountdown()
      }, 1000)

      return () => {
        clearTimeout(timeout)
      }
    } else if (sessionState === 'ended' && !joined) {
      void leaveCall()
    } else if (sessionState === 'active') {
      closeAndStopCountdown()
    }
  }, [joined, sessionState, leaveCall, openAndStartCountdown, closeAndStopCountdown])

  return (
    <Modal size='fit-content' data-testid='session-is-closed-modal' open={open}>
      <ModalWrapper>
        <ModalHeader alignItems={'flex-start'}>
          <Text size='large' bold>
            {t('live-session-page.session_closed_modal__title-text')}
          </Text>
        </ModalHeader>
        <Content>
          <Text size='regular' color='grey40'>
            {isLearnerLed
              ? t('live-session-page.session_closed_modal__body-text--learner-led')
              : t('live-session-page.session_closed_modal__body-text')}
          </Text>
        </Content>
        <Actions>
          {countdownEnd !== null && (
            <CountDown
              endTime={countdownEnd}
              onTimeoutDone={() => leaveCall()}
              render={seconds => (
                <CountDownText>
                  {t('live-session-page.session_closed_modal__automatic-leave-countdown-text', {
                    count: Math.max(seconds, 0),
                  })}
                </CountDownText>
              )}
            />
          )}
          {isFacilitator && (
            <Button
              disabled={mutation.isPending}
              variant='secondary'
              onClick={async () => {
                await reopenSession()
              }}
            >
              {t('live-session-page.session_closed_modal__reopen-button')}
            </Button>
          )}
          <Button variant='destructive' icon='phone--hang-up' onClick={() => leaveCall()}>
            {t('live-session-page.session_closed_modal__leave-button')}
          </Button>
        </Actions>
      </ModalWrapper>
    </Modal>
  )
}
