import { useCallback, useEffect, useRef, useState } from 'react'
import { ProgramOutline } from 'sierra-domain/api/manage'
import { isDefined } from 'sierra-domain/utils'

export function useDelayedState<TValue>(
  initial: TValue,
  delay: number
): [TValue, (value: TValue) => void, (value: TValue) => void] {
  const [internalState, setInternalState] = useState<TValue>(initial)
  const timeout = useRef<NodeJS.Timeout>()

  const _clearTimeout = (): void => {
    if (timeout.current !== undefined) {
      clearTimeout(timeout.current)
    }
  }

  const setDelayed = useCallback(
    (value: TValue): void => {
      _clearTimeout()

      timeout.current = setTimeout(() => {
        setInternalState(value)
      }, delay)
    },
    [delay]
  )

  const setImmediate = useCallback((value: TValue): void => {
    _clearTimeout()
    setInternalState(value)
  }, [])

  useEffect(() => {
    return () => {
      _clearTimeout()
    }
  }, [])

  return [internalState, setDelayed, setImmediate]
}

export function getSectionStepRange(
  sectionIndex: number,
  outline: ProgramOutline
): { start: number; end: number } | null {
  let start = null
  let end = null

  for (let i = 0; i < outline.steps.length; i++) {
    const step = outline.steps[i]

    if (isDefined(step) && step.sectionIndex === sectionIndex) {
      if (start === null) {
        start = i
      }

      end = i
    }
  }

  if (start === null || end === null) {
    return null
  }

  return { start, end }
}
