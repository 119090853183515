import useSound from 'use-sound'

type UseSoundFn = typeof useSound

export const useSanaSound = (
  src: string | string[],
  options?: Parameters<UseSoundFn>[1]
): ReturnType<UseSoundFn> => {
  return useSound(src, { soundEnabled: true, ...options })
}
