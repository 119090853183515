import { View } from 'sierra-ui/primitives'
import styled from 'styled-components'

export const CapitalizeFirstLetterView = styled(View)`
  p {
    text-transform: lowercase;
  }
  p:first-child {
    &::first-letter {
      text-transform: uppercase;
    }
  }
`
