import _ from 'lodash'
import { useCallback, useEffect, useMemo, useState } from 'react'
import { z } from 'zod'

type ValidatedQuery = string & z.BRAND<'ValidatedQuery'>

const DEFAULT_QUERY = 'random' as ValidatedQuery

const isValidQuery = (query: string | undefined): query is ValidatedQuery =>
  query !== undefined && query.trim() !== ''

export const getValidatedQueryOrRandom = (query: string | undefined): ValidatedQuery => {
  return isValidQuery(query) ? query : DEFAULT_QUERY
}

const getInitialPage = (query: string | undefined): number => (isValidQuery(query) ? 1 : _.random(1, 20))

const endpoint = (query: ValidatedQuery, page: number): string => {
  return `https://api.unsplash.com/search/photos?page=${page}&per_page=30&query=${encodeURI(query)}`
}

const headers = { Authorization: `Client-ID 1JqRQjQMUBMbGCJdQkzH_cH8qBqZWEE5nJ2t9sH-TJc` } as const

export type UnsplashImage = {
  id: string
  description: string
  width: number
  height: number
  urls: { raw: string; full: string; regular: string; small: string; thumb: string }
  links: { self: string; html: string; download: string; download_location: string }
  user: {
    id: string
    updated_at: string
    username: string
    name: string
    portfolio_url: string
    bio: string
    location: string
    total_likes: number
    total_photos: number
    total_collections: number
    links: { self: string; html: string; photos: string; likes: string; portfolio: string }
  }
}

type UnsplashResponse =
  | {
      results: UnsplashImage[]
      total: number
      total_pages: number
    }
  | {
      errors: string[]
    }

export const fetchImages = async (rawQuery: string, page = 1): Promise<UnsplashImage[]> => {
  const data = await fetch(endpoint(getValidatedQueryOrRandom(rawQuery), page), { headers })

  const result: UnsplashResponse = (await data.json()) as unknown as UnsplashResponse

  return 'errors' in result ? [] : result.results
}

export type UnsplashSuggestions = {
  images: UnsplashImage[]
  isLoading: boolean
  requestMore: () => void
  resolvedQuery: string
}

/**
 * Get metadata for images from Unsplash for a given query.
 * The returned `requestMore` function can be called to fetch more images, but new requests will not be sent until previous requests are completed.
 * The number of images returned at once is limited to 30, this is a restriction in the unsplash API.
 */
export const useUnsplashImages = ({ query: rawQuery }: { query?: string }): UnsplashSuggestions => {
  const resolvedQuery = getValidatedQueryOrRandom(rawQuery)
  const [state, setState] = useState<{
    query: ValidatedQuery
    images: UnsplashImage[]
    isLoading: boolean
    page: number
  }>(() => ({
    query: resolvedQuery,
    images: [],
    isLoading: false,
    page: getInitialPage(rawQuery),
  }))

  // When more content is requested, attempt to load more unless loading is already in progress
  const requestMore = useCallback(() => {
    setState(previous => {
      if (previous.isLoading) return previous
      else return { ...previous, page: previous.page + 1 }
    })
  }, [setState])

  // When the query changes, reset the page number, query, and current images
  useEffect(
    () =>
      setState(previous => ({
        isLoading: previous.isLoading,
        query: resolvedQuery,
        page: getInitialPage(rawQuery),
        images: [],
      })),
    [rawQuery, resolvedQuery]
  )

  // Refetch images when the query or the page number changes
  const { query, page, images, isLoading } = state
  useEffect(() => {
    let cancelled = false
    setState(previous => ({ ...previous, isLoading: true }))
    void fetchImages(query, page).then(fetchedImages => {
      if (cancelled) return

      setState(previous => {
        const images = _.uniqBy([...previous.images, ...fetchedImages], 'id')
        return { ...previous, images, isLoading: false }
      })
    })

    return () => {
      cancelled = true
    }
  }, [query, page])

  return useMemo(
    () => ({ images, isLoading, requestMore, resolvedQuery }),
    [images, isLoading, requestMore, resolvedQuery]
  )
}
