import React from 'react'
import PhoneInput from 'react-phone-number-input'
import { useTranslation } from 'sierra-client/hooks/use-translation'
import { validatePhoneNumberOrReturnError } from 'sierra-client/views/manage/components/user-attributes/flows/user-attribute-settings/utils'
import { useInvitationDomains } from 'sierra-client/views/manage/components/user-attributes/hooks/use-invitation-domains'
import { UserPhoneNumberDomainRep } from 'sierra-domain/user-attributes/user-invitation-domain-rep'
import { isEmptyArray, isNonEmptyString } from 'sierra-domain/utils'
import { MenuItem } from 'sierra-ui/components/menu/types'
import { InputPrimitive } from 'sierra-ui/primitives'
import { SingleSelectDropdown } from 'sierra-ui/primitives/menu-dropdown'
import { isDefined } from 'sierra-ui/utils/is-defined'
import styled from 'styled-components'

const StyledDropdown = styled(SingleSelectDropdown)`
  min-width: 100px;
`

const CountrySelect: React.FC<{
  value: string
  onChange: (value: string) => void
  options: { value?: string; label: string }[]
}> = ({ value, onChange, options }) => {
  const { t } = useTranslation()
  const { phoneNumberDomain } = useInvitationDomains()

  const menuItems = React.useMemo(() => {
    const noCustomCountries = isEmptyArray(phoneNumberDomain?.domain.countryChoices ?? [])

    return options.reduce<MenuItem[]>((list, option) => {
      if (
        (noCustomCountries || phoneNumberDomain?.domain.countryChoices.includes(option.value ?? '')) ??
        false
      ) {
        list.push({
          type: 'label',
          id: option.value ?? option.label,
          label: option.label,
        })

        if (option.label === 'International') {
          list.push({ type: 'separator', id: 'separator' })
        }
      }

      return list
    }, [])
  }, [options, phoneNumberDomain])

  const onSelect = (item: MenuItem): void => {
    onChange(item.id)
  }

  const selectedItem = menuItems.find(item => item.id === value)

  return (
    <StyledDropdown
      withSearch
      grow={false}
      searchPlaceholder={t('menu.search.placeholder')}
      onSelect={onSelect}
      selectedItem={
        isDefined(selectedItem)
          ? {
              ...selectedItem,
              type: 'label',
              label: selectedItem.id,
            }
          : {
              type: 'label',
              icon: 'wiki',
              label: '',
              id: 'International',
            }
      }
      menuItems={menuItems}
      placeholder={t('dictionary.select')}
    />
  )
}

const StyledPhoneInput = styled(PhoneInput)`
  display: flex;
  flex-grow: 1;
  gap: 8px;

  button {
    min-width: 65px;
  }
`

type PhoneNumberInputProps = {
  id?: string
  value?: string
  disabled?: boolean
  onChange: (number?: string) => void
  onValidationError: (error: string | undefined) => void
  phoneNumberDomain: UserPhoneNumberDomainRep
}

export const PhoneNumberInput: React.FC<PhoneNumberInputProps> = ({
  id,
  value,
  disabled,
  onChange,
  onValidationError,
  phoneNumberDomain,
}) => {
  const { t, dynamicT } = useTranslation()

  const validatePhoneNumber = (): void => {
    const validationError: string | undefined = isNonEmptyString(value)
      ? validatePhoneNumberOrReturnError(value, phoneNumberDomain, dynamicT)
      : undefined

    onValidationError(validationError)
  }

  return (
    <StyledPhoneInput
      id={id}
      placeholder={t('manage.users.invite.phone-number.input.placeholder')}
      international
      withCountryCallingCode
      value={value}
      disabled={disabled}
      onChange={(val?: string) => onChange(val)}
      inputComponent={InputPrimitive}
      countrySelectComponent={CountrySelect}
      onBlur={validatePhoneNumber}
    />
  )
}
