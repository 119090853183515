import React from 'react'
import { useTranslation } from 'sierra-client/hooks/use-translation'
import {
  CriteriaDefinition,
  CriteriaValue,
  Rating,
} from 'sierra-client/views/manage/homeworks/manage-homeworks-modal/types'
import {
  RatingButton,
  RatingButtonButton,
} from 'sierra-ui/missions/workflows/homework-criteria/rating-criteria'
import { Text, View } from 'sierra-ui/primitives'
import styled from 'styled-components'

const RatingButtonContainer = styled(View)`
  ${RatingButtonButton} {
    transition-duration: 0.6s;
  }

  &:hover {
    ${RatingButtonButton} {
      transition-duration: 0.3s;
    }
  }
`

const ALL_RATINGS: Rating[] = [1, 2, 3, 4, 5]

export const CriteriaRow: React.FC<{
  criteria: CriteriaDefinition
  value: CriteriaValue | undefined
  setValue: (newValue: CriteriaValue) => void
}> = ({ criteria, value, setValue }) => {
  const { t } = useTranslation()
  const [hoveredButton, setHoveredButton] = React.useState<Rating | undefined>(undefined)

  return (
    <View justifyContent='space-between'>
      <View direction='column' gap='none'>
        <Text bold>{criteria.title}</Text>
        <Text color='foreground/muted'>{t('manage.homework.criteria-rating-1-to-5-instruction')}</Text>
      </View>
      <RatingButtonContainer onMouseLeave={() => setHoveredButton(undefined)}>
        {ALL_RATINGS.map(x => (
          <RatingButton
            key={x}
            rating={x}
            activeRating={hoveredButton ?? value?.rating}
            onClick={() => setValue({ type: 'rating-1-to-5', rating: x })}
            onMouseEnter={() => setHoveredButton(x)}
          />
        ))}
      </RatingButtonContainer>
    </View>
  )
}
