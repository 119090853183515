import React from 'react'
import type { SkillId } from 'sierra-client/api/graphql/branded-types'
import { StrategicErrorBoundary } from 'sierra-client/error/strategic-error-boundary'
import {
  CourseCompletionRateWidget,
  SkillSubscriberCountWidget,
  TimeSpentOnSkillWidget,
} from 'sierra-client/features/insights'
import { widgetErrorBoundaryComponent } from 'sierra-client/features/skills/components/error/widget-error-boundary'
import {
  Metric,
  MetricSeparator,
  StatsWidgetContainer,
  StatsWidgetInnerContainer,
  StatsWidgetTitle,
  WIDGET_HEIGHT,
} from 'sierra-client/features/skills/components/stats/styles'
import { View } from 'sierra-ui/primitives'
import styled from 'styled-components'

const MetricsContainer = styled(StatsWidgetInnerContainer)`
  min-height: ${WIDGET_HEIGHT}px;
`

export const SubscribersStats: React.FC<{ skillId: SkillId }> = ({ skillId }) => {
  return (
    <StatsWidgetContainer>
      <StatsWidgetTitle translationKey='learner-dashboard.statistics' />

      <MetricsContainer>
        <View gap='24' direction='column' padding='16 24'>
          <Metric translationKey='skills.stats.learners'>
            <StrategicErrorBoundary
              id='skills-subscriber-stats-widget'
              Fallback={widgetErrorBoundaryComponent()}
              strategies={[]}
            >
              <SkillSubscriberCountWidget skillId={skillId} />
            </StrategicErrorBoundary>
          </Metric>

          <MetricSeparator />

          <Metric translationKey='skills.stats.total-time-spent'>
            <StrategicErrorBoundary
              id='skills-time-spent-stats-widget'
              Fallback={widgetErrorBoundaryComponent()}
              strategies={[]}
            >
              <TimeSpentOnSkillWidget skillId={skillId} />
            </StrategicErrorBoundary>
          </Metric>

          <MetricSeparator />

          <Metric translationKey='skills.stats.course-completion-rate'>
            <StrategicErrorBoundary
              id='skills-course-completion-rate-widget'
              Fallback={widgetErrorBoundaryComponent()}
              strategies={[]}
            >
              <CourseCompletionRateWidget skillId={skillId} />
            </StrategicErrorBoundary>
          </Metric>
        </View>
      </MetricsContainer>
    </StatsWidgetContainer>
  )
}
