import React, { useState } from 'react'
import { useTranslation } from 'sierra-client/hooks/use-translation'
import { ReportsTable } from 'sierra-client/views/manage/reports/components/reports-table'
import { JobsModal } from 'sierra-client/views/manage/reports/jobs-modal'
import { ReportModal } from 'sierra-client/views/manage/reports/report-modal'
import { useGetReportTitle, useIsReportingEnabled } from 'sierra-client/views/manage/reports/report-utils'
import { ReportFilter, useReportJobs, useReports } from 'sierra-client/views/manage/reports/use-reports'
import { Report } from 'sierra-domain/api/analytics-reporting'
import { Button, Spacer, Text, View } from 'sierra-ui/primitives'

export const ReportsView: React.FC<unknown> = () => {
  const { t } = useTranslation()
  const isReportingEnabled = useIsReportingEnabled()
  const getReportTitle = useGetReportTitle()
  const { reports } = useReports()
  const { jobs, addJob } = useReportJobs()

  // Active action/modal
  const [action, setAction] = useState<
    | undefined
    | { type: 'jobs' }
    | {
        type: 'config-report'
        report: Report
        values?: ReportFilter
      }
  >(undefined)

  const onClickReport = React.useCallback(
    (report: Report) => setAction({ type: 'config-report', report }),
    []
  )

  if (isReportingEnabled !== true) return <></>

  return (
    <>
      <View justifyContent='space-between'>
        <Text size='regular' bold>
          {t('content.reports')}
        </Text>
      </View>
      <Spacer size='small' />
      <ReportsTable onClick={onClickReport} reports={reports} jobs={jobs} />
      <Spacer size='small' />
      <Button variant='secondary' onClick={() => setAction({ type: 'jobs' })}>
        {t('manage.reports.jobs')}
      </Button>
      <Spacer size='xxlarge' />
      {action?.type === 'config-report' && (
        <ReportModal
          report={action.report}
          defaultValues={action.values}
          isOpen={true}
          onClose={() => setAction(undefined)}
          title={getReportTitle(action.report)}
          addJob={addJob}
        />
      )}
      {action?.type === 'jobs' && (
        <JobsModal jobs={jobs} reports={reports} isOpen={true} onClose={() => setAction(undefined)} />
      )}
    </>
  )
}
