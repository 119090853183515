import { UseQueryResult } from '@tanstack/react-query'
import { CachedQueryOptions, useCachedQuery } from 'sierra-client/state/api'
import { ContentInformation, EnrollmentMetadata } from 'sierra-domain/api/learn'
import { XRealtimeCourseHelperSessionEnrollmentMetadata } from 'sierra-domain/routes'

export const useCourseHelperSessionEnrollmentMetadata = <TSelectData = EnrollmentMetadata>(
  contentInformation: ContentInformation,
  options: CachedQueryOptions<TSelectData> = {}
): UseQueryResult<TSelectData> => {
  return useCachedQuery(
    XRealtimeCourseHelperSessionEnrollmentMetadata,
    { contentInformation },
    {
      retry: false,
      ...options,
    }
  )
}
