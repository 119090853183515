import { DateTime } from 'luxon'
import React, { useEffect, useMemo, useRef, useState } from 'react'
import * as ReactTable from 'react-table'
import { useSortBy } from 'react-table'
import { Table } from 'sierra-client/components/table/table'
import { usePost } from 'sierra-client/hooks/use-post'
import { useDispatch, useSelector } from 'sierra-client/state/hooks'
import { setFilename, setPdfId } from 'sierra-client/state/pdf-annotation/actions'
import { selectFilename, selectPdfId } from 'sierra-client/state/pdf-annotation/selectors'
import { selectUser } from 'sierra-client/state/user/user-selector'
import { dynamic } from 'sierra-client/utils/dynamic'
import { PdfUpload } from 'sierra-client/views/flexible-content/index/pdf-upload'
import { Section, Separator } from 'sierra-client/views/showcase/common'
import { ListUserPdfs } from 'sierra-domain/api/author-v2'
import { AssetContext } from 'sierra-domain/asset-context'
import { XRealtimeAuthorPdfImportListUserPdfs } from 'sierra-domain/routes'
import { Column, ColumnContainer, EditableText, FoldingColumn, Layout, Modal } from 'sierra-ui/components'
import { IconButton, View } from 'sierra-ui/primitives'
import styled from 'styled-components'

type ImportModalProps = {
  open: boolean
  onClose: () => void
  assetContext: AssetContext
}

const AnnotatePDF = dynamic(() => import('sierra-client/views/v3-author/annotate-pdf'))

const PdfList = styled.div`
  overflow-y: auto;
  height: 100%;
`

const CloseButton = styled.div`
  display: flex;
  flex: 1;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
`

const Divider = styled.div`
  width: 1px;
  height: 1rem;
  margin: 0 0.5rem 0;
  background-color: ${p => p.theme.color.grey20};
`

const UploadView = styled.div`
  display: flex;
  flex-direction: row;
  height: 100%;
  justify-content: center;
`

export const PDFAnnotatorModal: React.FC<ImportModalProps> = ({ open, onClose, assetContext }) => {
  const user = useSelector(selectUser)
  const pdfId = useSelector(selectPdfId)
  const dispatch = useDispatch()
  const { postWithUserErrorException } = usePost()
  const [pdfList, setPdfList] = useState<ListUserPdfs | []>([] as ListUserPdfs)
  const filename = useSelector(selectFilename)
  const [contentEditable, setContentEditable] = useState(false)
  const inputRef = useRef<HTMLParagraphElement>(null)

  type DemoRow = {
    pdfId: string
    createdAt: string
    updatedAt: string
    filename: string
  }

  const pdfColumn: ReactTable.Column<DemoRow>[] = useMemo(
    () => [
      {
        Header: 'Filename',
        accessor: row => `${row.filename}`,
      },
      {
        Header: 'Uploaded',
        accessor: row => `${row.createdAt}`,
      },
    ],
    []
  )
  const pdfTable = ReactTable.useTable<DemoRow>({
    columns: pdfColumn,
    data: pdfList.map(pdf => ({
      ...pdf,
      pdfId: pdf.pdfId,
      createdAt: DateTime.fromISO(pdf.createdAt).toFormat('dd LLL yyyy, HH:mm'),
    })),

    useSortBy,
  })

  useEffect(() => {
    void dispatch(setPdfId({ pdfId: undefined }))
  }, [dispatch])

  useEffect(() => {
    void (async () => {
      const ListUserPdfs = await postWithUserErrorException(XRealtimeAuthorPdfImportListUserPdfs, {})
      setPdfList(ListUserPdfs)
    })()
  }, [postWithUserErrorException, pdfId])

  if (!user) throw new Error('User not found')

  return (
    <Modal
      size='fit-content'
      animation='grow'
      padding={'small'}
      open={open}
      onClose={() => {
        onClose()
        void dispatch(setPdfId({ pdfId: undefined }))
      }}
    >
      <CloseButton>
        {pdfId !== undefined && (
          <View margin={'xsmall'}>
            <IconButton
              variant='transparent'
              iconId={'chevron--left'}
              onClick={async () => {
                void dispatch(setPdfId({ pdfId: undefined }))
              }}
            />
            <Divider />
            <EditableText
              ref={inputRef}
              size={'large'}
              value={filename}
              onRename={updatedValue => dispatch(setFilename({ filename: updatedValue }))}
              contentEditable={contentEditable}
              setContentEditable={setContentEditable}
            />
          </View>
        )}
        <View></View>
        <IconButton
          variant='transparent'
          iconId='close'
          onClick={() => {
            onClose()
            void dispatch(setPdfId({ pdfId: undefined }))
          }}
        />
      </CloseButton>

      {pdfId === undefined ? (
        <Layout width='100vh' height='80vh'>
          <ColumnContainer>
            <FoldingColumn open width={400}>
              <UploadView>
                <PdfUpload
                  onUploaded={async ({ pdfId }) => {
                    void dispatch(setPdfId({ pdfId: pdfId }))
                  }}
                  assetContext={assetContext}
                />
              </UploadView>
            </FoldingColumn>
            <Column>
              <PdfList>
                <Section>
                  <Table
                    tableInstance={pdfTable}
                    getRowProps={row => ({
                      onClick: () => {
                        void dispatch(setPdfId({ pdfId: row.original.pdfId }))
                      },
                    })}
                  />
                </Section>
                <Separator />
              </PdfList>
            </Column>
          </ColumnContainer>
        </Layout>
      ) : (
        <AnnotatePDF onUpload={onClose} />
      )}
    </Modal>
  )
}
