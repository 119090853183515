import { createFileRoute } from '@tanstack/react-router'

import { PageIdentifier } from 'sierra-client/layout/sana-page'
import { ManagePrograms } from 'sierra-client/views/manage/programs/manage-programs'
import { ManagePageWrapper } from 'sierra-client/views/manage/utils/manage-page-wrapper'

// Note: ManagePageWrapper handles the role check.
function ManageProgramsPage(): JSX.Element | null {
  return (
    <ManagePageWrapper pageIdentifier={PageIdentifier.ManagePrograms()}>
      <ManagePrograms />
    </ManagePageWrapper>
  )
}

const RouteComponent = ManageProgramsPage

export const Route = createFileRoute('/manage/programs/')({
  component: RouteComponent as React.FC,
})
