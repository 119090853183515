import { useIsDebugMode } from 'sierra-client/hooks/use-is-debug-mode'
import { FCC } from 'sierra-client/types'

export const Debug: FCC<{ className?: string }> = ({ children, className }) => {
  const isDebugEnabled = useIsDebugMode()
  if (!isDebugEnabled) return null

  if (className !== undefined) return <div className={className}>{children}</div>
  return <>{children}</>
}
