import type { ConnectionState, NetworkQuality } from 'agora-rtc-sdk-ng'

export const networkQualityDescriptions = {
  0: 'The network quality is unknown.',
  1: 'The network quality is excellent.',
  2: 'The network quality is good, but the bitrate may be slightly lower than optimal.',
  3: 'Users experience slightly impaired communication.',
  4: 'Users cannot communicate smoothly.',
  5: 'The network is so poor that users can barely communicate.',
  6: 'The network is down and users cannot communicate at all.',
}

const WARNING_THRESHOLD: NetworkQuality = {
  downlinkNetworkQuality: 4,
  uplinkNetworkQuality: 4,
} as const

export const isUplinkBad = (uplink: number): boolean => uplink >= WARNING_THRESHOLD.uplinkNetworkQuality
export const isDownlinkBad = (downlink: number): boolean =>
  downlink >= WARNING_THRESHOLD.downlinkNetworkQuality

export const shouldShowWarning = (uplink: number, downlink: number): boolean => {
  return isUplinkBad(uplink) || isDownlinkBad(downlink)
}

/**
 * We define the connection to be healthy when we are in a recoverable state.
 * This does not mean that we can perform certain actions such as subscribing, publishing, etc.
 */
export const connectionIsHealthy = (connectionState: ConnectionState): boolean =>
  connectionState !== 'DISCONNECTED' && connectionState !== 'DISCONNECTING'

/**
 * Check if we have a connection state that allow us to subscribe to streams.
 */
export const connectionCanSubscribe = (connectionState: ConnectionState): boolean =>
  connectionState === 'CONNECTED' || connectionState === 'RECONNECTING'
