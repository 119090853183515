import { Dispatch, ThunkDispatch, UnknownAction, createAction, createAsyncThunk } from '@reduxjs/toolkit'
import _, { range } from 'lodash'
import { errorLogger } from 'sierra-client/error/error-logger'
import { postWithUserErrorException } from 'sierra-client/state/api'
import { RootState } from 'sierra-client/state/types'
import { usersSelectors } from 'sierra-client/state/users/selectors'
import { UserId } from 'sierra-domain/api/uuid'
import { XRealtimeContentListUsers } from 'sierra-domain/routes'
import { LightUser } from 'sierra-domain/user'

const _fetchUsers = async (userIds: UserId[], dispatch: Dispatch): Promise<LightUser[]> => {
  // TODO: This is a temporary workaround since the backned has some issues with 403s being returned
  // A general retry solution can't be implemented for this since 403 SHOULD NOT be a retryable error
  // please remove this once the backend is fixed
  for (const i of range(3)) {
    try {
      const response = await postWithUserErrorException(
        XRealtimeContentListUsers,
        {
          requestedUserIds: userIds,
        },
        dispatch
      )

      return response.data.map(
        ({ userInfo: { firstName, lastName, avatar, avatarColor, userId, email } }) => ({
          firstName: firstName ?? '',
          lastName: lastName ?? '',
          avatar,
          avatarColor,
          uuid: userId,
          email: email,
        })
      )
    } catch (e) {
      errorLogger.captureWarning(e)
      console.debug(`[${i + 1}] failed to fetch users, retrying...`)
      // To avoid spamming the server, wait a bit before retrying
      await new Promise(resolve => setTimeout(resolve, 50))
    }
  }

  throw new Error(`Failed to fetch users after 3 attempts, userIds = ${JSON.stringify(userIds)}`)
}

const setMinus = <T>(arr1: T[], arr2: T[]): T[] => {
  const set2 = new Set(arr2)
  return _.uniq(arr1.filter(x => !set2.has(x)))
}

export const pendUserIds = createAction<{ userIds: UserId[] }>('users/pendUserIds')
export const removePendingUserIds = createAction<{ userIds: UserId[] }>('users/removePendingUserIds')

export const fetchUsers = createAsyncThunk<
  { users: LightUser[]; requestedUserIds: UserId[] },
  void,
  { state: RootState }
>('users/fetchUsers', async (arg, { getState, dispatch }) => {
  const queuedUserIds = getState().users.queuedUserIds
  const existingUserIds = usersSelectors.selectIds(getState())
  const userIdsToFetch = setMinus(queuedUserIds, existingUserIds)
  if (userIdsToFetch.length === 0) return { users: [], requestedUserIds: [] }

  try {
    void dispatch(pendUserIds({ userIds: userIdsToFetch }))

    const users = await _fetchUsers(userIdsToFetch, dispatch)
    return { users, requestedUserIds: userIdsToFetch }
  } catch (error) {
    void dispatch(removePendingUserIds({ userIds: userIdsToFetch }))
    throw error
  }
})

const debouncedDispatchFetchUsers = _.debounce(
  (dispatch: ThunkDispatch<RootState, unknown, UnknownAction>) => dispatch(fetchUsers()),
  50,
  { leading: false }
)

export const queueUserIds = createAsyncThunk<void, { userIds: UserId[] }, { state: RootState }>(
  'users/queueUserId',
  (arg, { dispatch }) => {
    void debouncedDispatchFetchUsers(dispatch)
  }
)
