/**
 * Make a PUT request to upload a blob to a GCS bucket.
 */
export const gcsBlobUpload = async ({
  url,
  blob,
  onProgress,
  contentType,
}: {
  url: string
  blob: Blob
  contentType: string
  onProgress: (value: number) => void
}): Promise<void> => {
  return new Promise<void>((resolve, reject) => {
    const xhr = new XMLHttpRequest()
    xhr.upload.addEventListener('progress', event => {
      if (event.lengthComputable) {
        onProgress((event.loaded / event.total) * 100)
      }
    })

    xhr.addEventListener('load', () => {
      if (xhr.status === 200) {
        resolve()
      } else {
        reject(new Error(`Upload failed with status ${xhr.status} - ${xhr.responseText}`))
      }
    })

    xhr.addEventListener('error', () => {
      reject(new Error(`Network error occurred during upload.`))
    })

    xhr.addEventListener('abort', () => {
      reject(new Error('Upload was aborted.'))
    })

    xhr.addEventListener('timeout', () => {
      reject(new Error('Upload timed out.'))
    })

    xhr.open('PUT', url)
    xhr.setRequestHeader('Content-Type', contentType)
    xhr.send(blob)
  })
}
