import { FCC } from 'sierra-client/types'
import { Text } from 'sierra-ui/primitives'
import styled from 'styled-components'

const Root = styled.div`
  width: 100%;
  padding-top: 3rem;
  padding-left: 1.5rem;
  padding-right: 1rem;
`

const StyledText = styled(Text)`
  margin-bottom: 1rem;
`

const InnerContainer = styled.div`
  display: grid;
  grid-template-columns: repeat(3, minmax(0, 1fr));

  grid-gap: 1.5rem;

  @media (max-width: 1500px) {
    grid-template-columns: repeat(2, minmax(0, 1fr));
  }

  @media (max-width: 1100px) {
    grid-template-columns: repeat(1, minmax(0, 1fr));
  }
`

type TemplatesContainerProps = {
  heading: string
  className?: string
}

export const TemplatesContainer: FCC<TemplatesContainerProps> = ({ children, heading, className }) => {
  return (
    <Root>
      <StyledText size='large' bold>
        {heading}
      </StyledText>
      <InnerContainer className={className}>{children}</InnerContainer>
    </Root>
  )
}
