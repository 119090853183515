import { useLiveSessionContext } from 'sierra-client/components/liveV2/contexts/live-session-data'
import { useSelector } from 'sierra-client/state/hooks'
import { selectIsFacilitator } from 'sierra-client/state/live-session/selectors'

export const useIsFacilitatorOrLearnerLedSession = (): boolean => {
  const isFacilitator = useSelector(selectIsFacilitator)
  const liveSession = useLiveSessionContext()

  return isFacilitator === true || liveSession.data.learnerLedSession === true
}
