import { atom, useAtomValue, useSetAtom } from 'jotai'
import { useEffect } from 'react'
import { useDebug } from 'sierra-client/lib/use-debug/use-debug'

type DebugControl = { loading: boolean; error: boolean; empty: boolean }

const initialState: DebugControl = { loading: false, error: false, empty: false }

const SkillDebugControlAtom = atom<DebugControl>(initialState)

export const useSkillDebugControl = (): DebugControl => {
  return useAtomValue(SkillDebugControlAtom)
}

export const SkillDebugControl: React.FC = () => {
  const debug = useDebug('Skills UI state', initialState)
  const setSkillDebug = useSetAtom(SkillDebugControlAtom)

  useEffect(() => {
    setSkillDebug(debug)
  }, [debug, setSkillDebug])

  return null
}
