/* eslint-disable react/forbid-dom-props */
import { XYCoord, useDragLayer } from 'react-dnd'
import { DragItem } from 'sierra-client/components/common/dnd/dnd-types'
import { Heading } from 'sierra-ui/primitives'
import { token } from 'sierra-ui/theming'
import styled, { CSSProperties, ThemeProvider, createGlobalStyle } from 'styled-components'

const DragOverlayContainer = styled.div`
  position: fixed;
  pointer-events: none;
  z-index: 100;
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
`
const GlobalStyleWhileDragging = createGlobalStyle`
	* {
		user-select: none;
    cursor: grabbing !important;
	}
`

const DraggingFlipCard = styled.div`
  width: 349px;
  height: 456px;
  background-color: ${token('foreground/primary')};
  color: white;
  border-radius: ${p => p.theme.borderRadius['size-10']};
  padding: 2rem;
`

function getItemStyles(initialOffset: XYCoord | null, currentOffset: XYCoord | null): CSSProperties {
  if (!initialOffset || !currentOffset) {
    return {
      display: 'none',
    }
  }

  const { x, y } = currentOffset

  const transform = `translate(${x}px, ${y}px)`
  return {
    transform,
    WebkitTransform: transform,
  }
}

export const CustomDragFlipCardOverLay: React.FC = () => {
  const { isDragging, item, initialOffset, currentOffset } = useDragLayer<{
    isDragging: boolean
    item: DragItem
    initialOffset: XYCoord | null
    currentOffset: XYCoord | null
  }>(monitor => ({
    item: monitor.getItem(),
    initialOffset: monitor.getInitialClientOffset(),
    currentOffset: monitor.getClientOffset(),
    isDragging: monitor.isDragging(),
  }))

  if (!isDragging) return null
  if (item.type !== 'flip-card') return null

  return (
    <ThemeProvider theme={item.theme}>
      <DragOverlayContainer contentEditable={false}>
        <div style={getItemStyles(initialOffset, currentOffset)}>
          <DraggingFlipCard>
            {item.firstTextNode !== undefined && (
              <Heading color={'surface/default'} size='h3'>
                {item.firstTextNode}
              </Heading>
            )}
          </DraggingFlipCard>
        </div>
        <GlobalStyleWhileDragging />
      </DragOverlayContainer>
    </ThemeProvider>
  )
}
