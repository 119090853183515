import _ from 'lodash'
import { createSelector } from 'reselect'
import { ISSUE_INFORMATION, LiveIssueCategory } from 'sierra-client/state/live/live-issue-information'
import { LiveIssue, LiveState } from 'sierra-client/state/live/types'
import { RootState } from 'sierra-client/state/types'

const selectState = (state: RootState): LiveState => state.live

export const selectCallState = createSelector(selectState, state => state.callState)

export const selectPreferredVideoQuality = createSelector(selectState, state => state.preferredVideoQuality)
export const selectCurrentCameraId = createSelector(selectCallState, state => state.video.deviceId)
export const selectCurrentMicrophoneId = createSelector(selectCallState, state => state.audio.deviceId)

export const selectMicrophonePermission = createSelector(
  selectCallState,
  state => state.devices.microphonePermission
)
export const selectJoinState = createSelector(selectCallState, state => state.call.joinState)
export const selectConnectionState = createSelector(selectCallState, state => state.call.connectionState)
export const selectCameraPermission = createSelector(selectCallState, state => state.devices.cameraPermission)
export const selectUsersDisplayed = createSelector(selectState, state => state.usersDisplayed)

export const selectAvailableMicrophones = createSelector(selectCallState, state => state.devices.microphones)
export const selectAvailableCameras = createSelector(selectCallState, state => state.devices.cameras)

export const selectAudioState = createSelector(selectCallState, state => state.audio.state)
export const selectVideoState = createSelector(selectCallState, state => state.video.state)
export const selectVideoResolutionSetting = createSelector(selectCallState, state => state.video.resolution)

export const selectAudioDeviceId = createSelector(selectCallState, state => state.audio.deviceId)

export const selectScreenShareClientId = createSelector(selectCallState, state => state.screenShare.clientId)
export const selectIsScreenSharing = createSelector(selectCallState, state => state.screenShare.enabled)

const selectScreenRecording = createSelector(selectCallState, state => state.screenRecord)
export const selectScreenRecordingEnabled = createSelector(
  selectScreenRecording,
  screenRecord => screenRecord.enabled
)

export const selectClientId = createSelector(selectCallState, state => state.call.clientId)

export const selectCurrentBreakoutRoomId = createSelector(selectCallState, state =>
  state.call.callMode.state === 'in-breakout-room' ? state.call.callMode.breakoutRoom : undefined
)

export const selectCallModeState = createSelector(selectCallState, state => state.call.callMode.state)

export const selectCallIsActive = createSelector(
  selectCallState,
  state => state.call.callMode.state !== 'not-joined'
)

export const selectNetworkQualityStats = createSelector(
  selectCallState,
  state => state.call.networkQualityStats
)

export const selectRemoteParticipants = createSelector(
  selectCallState,
  state => state.call.remoteParticipants
)

export const selectKeyboardNavigationEnabled = createSelector(
  selectState,
  state => state.keyboardNavigationEnabled
)

export const selectRemoteParticipant = createSelector(selectRemoteParticipants, remoteParticipants =>
  _.memoize((id?: string) =>
    id !== undefined ? remoteParticipants.find(participant => participant.id === id) : undefined
  )
)

const selectCurrentIssues = createSelector(selectState, state => state.issues)

export const selectAllCurrentIssues = createSelector(selectCurrentIssues, issues =>
  _.chain(issues)
    .filter(issue => !issue.dismissed)
    .map(issue => ISSUE_INFORMATION[issue.issueName])
    .value()
)

export const selectIssuesInCategory = createSelector(selectAllCurrentIssues, issues =>
  _.memoize((category: LiveIssueCategory) =>
    _.chain(issues)
      .filter(issue => issue.category === category)
      .value()
  )
)

export const selectIssuesWithName = createSelector(selectAllCurrentIssues, issues =>
  _.memoize((name: LiveIssue) =>
    _.chain(issues)
      .filter(issue => issue.name === name)
      .value()
  )
)

export const selectRecoverableIssues = createSelector(selectAllCurrentIssues, issues =>
  _.chain(issues)
    .filter(issue => issue.recoverable === true)
    .value()
)

export const selectUnrecoverableIssues = createSelector(selectAllCurrentIssues, issues =>
  _.chain(issues)
    .filter(issue => issue.recoverable === false)
    .value()
)

export const selectBackgroundBlurStatus = createSelector(
  selectCallState,
  state => state.backgroundBlurEnabled
)

export const selectNoiseCancellationStatus = createSelector(
  selectCallState,
  state => state.noiseCancellationEnabled
)
