import React, { useCallback, useEffect, useId, useMemo, useState } from 'react'
import { useLanguageItems } from 'sierra-client/hooks/use-language-items'
import { useTranslation } from 'sierra-client/hooks/use-translation'
import { SettingsTabComponent } from 'sierra-client/views/manage/paths/path-settings/types'
import { FormElement, Icon, MenuItem } from 'sierra-ui/components'
import { IconButton, InputPrimitive, TextAreaPrimitive, View } from 'sierra-ui/primitives'
import { SingleSelectDropdown } from 'sierra-ui/primitives/menu-dropdown'
import { Text } from 'sierra-ui/primitives/typography'
import { palette, token } from 'sierra-ui/theming'
import styled from 'styled-components'

const Circle = styled(View).attrs({
  radius: 'round',
  direction: 'row',
  alignItems: 'center',
  justifyContent: 'center',
  background: 'grey5',
})`
  width: 32px;
  height: 32px;

  transition: background 0.2s ease;
`

const CircleLabel = styled(Text).attrs({ color: 'black', bold: true, size: 'small' })``

const TranslationHeading: React.FC<{ title: string; language: string }> = ({ title, language }) => {
  return (
    <>
      {title && <Text bold>{title}</Text>}
      {
        <Text bold color='foreground/muted'>
          {title ? language : 'New edition'}
        </Text>
      }
    </>
  )
}

const TranslationContentWrapper = styled(View).attrs({
  paddingLeft: '32',
  marginLeft: '12',
  direction: 'column',
})`
  border-left: 4px solid #eeeeee;
`

const TranslationSection: React.FC<{
  translationKey: number
  translationNo: number
  translationTitle: string
  translationDescription: string | undefined
  translationLanguage: string
  alreadyInUseLanguages: string[]
  removeTranslationClick: (translationIndex: number) => void
  updateTranslation: (
    translationIndex: number,
    title: string,
    description: string | undefined,
    language: string,
    key: number | undefined
  ) => void
}> = ({
  translationKey,
  translationNo,
  translationTitle,
  translationDescription,
  translationLanguage,
  alreadyInUseLanguages,
  removeTranslationClick,
  updateTranslation,
}) => {
  const { t } = useTranslation()

  const [title, setTitle] = useState<string>(translationTitle)
  const [description, setDescription] = useState<string | undefined>(translationDescription)
  const [language, setLanguage] = useState<string>(translationLanguage)

  const titleInputId = useId()
  const languageInputId = useId()
  const descriptionInputId = useId()

  const [currentLanguageId, setCurrentLanguageId] = useState<string | undefined>(language)

  const [open, setOpen] = useState<boolean>(true)

  const onChangeLanguageSelection = useCallback((language: MenuItem<string>) => {
    setLanguage(language.id)
    setCurrentLanguageId(language.id)
  }, [])

  useEffect(() => {
    if (title || language || (description ?? ''))
      updateTranslation(translationNo - 1, title, description, language, translationKey)
  }, [title, description, language, translationNo, updateTranslation, translationKey])

  const languageItems: MenuItem[] = useLanguageItems().map(item => ({
    ...item,
    hidden: alreadyInUseLanguages.includes(item.id),
    selected: item.id === currentLanguageId,
  }))

  const selectedItem = languageItems.find(item => item.id === currentLanguageId)

  return (
    <View direction={'column'} alignItems='stretch' paddingTop='24'>
      <View justifyContent={'space-between'} paddingBottom={'12'} alignItems={'center'}>
        <View alignItems={'center'}>
          <Circle>
            <CircleLabel>{translationNo}</CircleLabel>
          </Circle>
          <TranslationHeading
            title={title}
            language={languageItems.find(l => l.id === language)?.label ?? ''}
          />
        </View>
        <View padding='none' gap='2'>
          {translationNo !== 1 && (
            <IconButton
              variant='transparent'
              iconId={'trash-can'}
              onClick={() => removeTranslationClick(translationNo - 1)}
            />
          )}
          <IconButton
            variant='transparent'
            iconId={open ? 'chevron--up--small' : 'chevron--down--small'}
            onClick={() => setOpen(open => !open)}
          />
        </View>
      </View>
      {open && (
        <TranslationContentWrapper>
          <View direction={'row'} justifyContent='space-between' alignItems={'flex-start'}>
            <FormElement label={t('dictionary.title')} htmlFor={titleInputId} grow>
              <InputPrimitive id={titleInputId} value={title} onChange={e => setTitle(e.target.value)} />
            </FormElement>

            <FormElement label={t('dictionary.language')} htmlFor={languageInputId} grow>
              <SingleSelectDropdown
                id={languageInputId}
                searchPlaceholder={t('dictionary.search')}
                withSearch
                selectedItem={selectedItem}
                onSelect={onChangeLanguageSelection}
                menuItems={languageItems}
                maxHeight={300}
              />
            </FormElement>
          </View>
          <View direction={'column'} justifyContent='space-between' paddingTop={'14'}>
            <FormElement label={t('dictionary.description')} htmlFor={descriptionInputId}>
              <TextAreaPrimitive
                id={descriptionInputId}
                value={translationDescription ?? ''}
                onChange={e => setDescription(e.target.value)}
              />
            </FormElement>
          </View>
        </TranslationContentWrapper>
      )}
    </View>
  )
}

const AddTranslationText = styled(Text).attrs({
  color: 'foreground/muted',
  bold: true,
})`
  transition: color 0.2s ease;
`

const AddTranslationButtonContainer = styled.button`
  display: flex;
  align-items: center;
  gap: 8px;
  margin: 0;
  padding: 0;
  background: none;
  padding-top: 24px;

  &:hover {
    cursor: pointer;

    ${Circle} {
      background: ${palette.grey[10]};
    }

    ${AddTranslationText} {
      color: ${token('foreground/primary')};
    }
  }
`

const AddTranslationButton: React.FC<{
  onAddNewTranslation: () => void
}> = ({ onAddNewTranslation }) => {
  const { t } = useTranslation()
  return (
    <AddTranslationButtonContainer onClick={onAddNewTranslation}>
      <Circle>
        <Icon iconId='add' />
      </Circle>
      <AddTranslationText>{t('admin.path.settings.add.translation')}</AddTranslationText>
    </AddTranslationButtonContainer>
  )
}

const Vertical = styled(View).attrs({ direction: 'column' })``

const Content = styled(Vertical).attrs({
  paddingTop: 'none',
  paddingBottom: '32',
  paddingLeft: '40',
  paddingRight: '40',
})``

export const TranslationsTab: SettingsTabComponent = ({
  translations,
  addNewTranslation,
  removeTranslationClick,
  updateTranslation,
}) => {
  const alreadyInUseLanguages = useMemo(() => translations.map(it => it.language), [translations])

  return (
    <Content>
      {translations.map((translation, index) => (
        <TranslationSection
          key={translation.key}
          translationKey={translation.key}
          translationNo={index + 1}
          translationTitle={translation.title}
          translationDescription={translation.description}
          translationLanguage={translation.language}
          updateTranslation={updateTranslation}
          removeTranslationClick={removeTranslationClick}
          alreadyInUseLanguages={alreadyInUseLanguages}
        />
      ))}
      <AddTranslationButton
        onAddNewTranslation={() => {
          addNewTranslation()
        }}
      />
    </Content>
  )
}
