import { VideoCallService } from 'sierra-client/components/liveV2/services/video-call-service/video-call-service'

export {
  isDenoiserSupported,
  isPictureInPictureSupported,
  isVirtualBackgroundSupported,
} from 'sierra-client/components/liveV2/services/video-call-service/helpers/browser-support'

let servicePromise: Promise<VideoCallService> | undefined = undefined
export const getVideoCallService = async (): Promise<VideoCallService> => {
  if (servicePromise) return servicePromise

  servicePromise = VideoCallService.createVideoCallService()

  return servicePromise
}
