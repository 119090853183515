import { createFileRoute } from '@tanstack/react-router'

import { useOrganizationPermissions } from 'sierra-client/hooks/use-permissions'
import { PageIdentifier } from 'sierra-client/layout/sana-page'
import { ManageHomework } from 'sierra-client/views/manage/homeworks/manage-homework/manage-homework'
import { ManagePageWrapper } from 'sierra-client/views/manage/utils/manage-page-wrapper'
import { HomeworkId } from 'sierra-domain/api/uuid'
import { z } from 'zod'

// Note: ManagePageWrapper handles the role check.
const Page: React.FC = () => {
  const exerciseId = Route.useParams({ select: p => p.exerciseId })
  const orgPermissions = useOrganizationPermissions()

  if (!orgPermissions.has('MANAGE_HOMEWORK')) {
    if (orgPermissions.status === 'loaded') {
      // ?
      // todo: redirect?
    }
    return null
  }

  return (
    <ManagePageWrapper pageIdentifier={PageIdentifier.ManageHomeworkDetail({ homeworkId: exerciseId })}>
      <ManageHomework key={exerciseId} homeworkId={exerciseId} />
    </ManagePageWrapper>
  )
}

export const Route = createFileRoute('/manage/exercises/$exerciseId')({
  component: Page,
  params: {
    parse: z.object({ exerciseId: HomeworkId }).parse,
    stringify: p => p,
  },
})
