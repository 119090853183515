import { ReactNode, useState } from 'react'
import { SkillId } from 'sierra-client/api/graphql/branded-types'
import { useGraphQuery, useInvalidateGraphQuery } from 'sierra-client/api/hooks/use-graphql-query'
import { HorizontalLineDivider } from 'sierra-client/components/common/horizontal-line-divider'
import { ConfirmationModalProvider } from 'sierra-client/components/common/modals/confirmation-modal'
import { PageTitle } from 'sierra-client/components/common/page-title'
import { StrategicErrorBoundary } from 'sierra-client/error/strategic-error-boundary'
import { DeleteSkillModal, EditSkillPanel } from 'sierra-client/features/skills/components/actions/skills'
import { SkillDetailManage } from 'sierra-client/features/skills/components/skill-detail/skill-detail-manage'
import {
  SkillDetailOverview,
  useInvalidateSkillDetailOverviewData,
} from 'sierra-client/features/skills/components/skill-detail/skill-detail-overview'
import { SubscribeButton } from 'sierra-client/features/skills/components/skill-detail/subscribe-button'
import { TrophyCase } from 'sierra-client/features/skills/components/trophy/trophy-case'
import { useSkillDebugControl } from 'sierra-client/features/skills/debug'
import { getSkillGeneralInfoQuery } from 'sierra-client/features/skills/shared-gql-queries'
import { useOrganizationPermissions } from 'sierra-client/hooks/use-permissions'
import { useTranslation } from 'sierra-client/hooks/use-translation'
import { stringWithZodSerializer, useQueryState } from 'sierra-client/lib/querystate/use-query-state'
import { getGlobalRouter } from 'sierra-client/router'
import { useIsMobile } from 'sierra-client/state/browser/selectors'
import { FCC } from 'sierra-client/types'
import { DetailsHeader } from 'sierra-client/views/manage/components/details-header'
import { assertNever, isNotDefined } from 'sierra-domain/utils'
import { MenuButton, Tabs } from 'sierra-ui/components'
import { Heading, LoadingSpinner, Skeleton, Spacer, Text, View } from 'sierra-ui/primitives'
import { height_100dvh } from 'sierra-ui/utils'
import styled from 'styled-components'
import z from 'zod'
import { useTracking } from '../../tracking'

const FullPage = styled(View).attrs({ direction: 'column', grow: true, gap: 'none', paddingBottom: '64' })`
  height: fit-content;
  width: 100%;
`

const ContentWrapper = styled.div<{ disabled: boolean }>`
  pointer-events: ${props => (props.disabled === true ? 'none' : 'auto')};
`

const PageWrapper: FCC<{
  type: 'learner' | 'manage'
  skillId: SkillId
  hideSubscribeButton?: boolean
  isShowingBanner?: boolean
}> = ({ children, skillId, hideSubscribeButton = false, isShowingBanner = false }) => {
  const { t } = useTranslation()
  const debugControl = useSkillDebugControl()
  const isMobile = useIsMobile()
  const query = useGraphQuery({ document: getSkillGeneralInfoQuery }, { id: skillId })
  const title = query.data?.skill?.name
  const levels = query.data?.skill?.skillLevels.length
  const content = query.data?.skill?.assignedContentsCount.count

  return (
    <FullPage>
      <PageTitle title={title} />

      <DetailsHeader backlink={{ href: '/manage/skills', label: 'manage.backlinks--skills' }} />

      <View justifyContent='space-between'>
        {debugControl.loading || isNotDefined(title) ? (
          <Skeleton $width={200} $height={34} $radius={6} />
        ) : (
          <View direction='column'>
            <Heading size='h4' bold>
              {title}
            </Heading>
            {!isMobile && (
              <View>
                <Text size='small' bold color='foreground/secondary'>
                  {t('skills.levels.text', { count: levels })}
                </Text>
                <Text color='foreground/secondary'>{`·`}</Text>
                <Text size='small' color='foreground/secondary'>
                  {t('skills.content.text', { count: content })}
                </Text>
              </View>
            )}
          </View>
        )}
        {hideSubscribeButton ? null : (
          <View gap='24'>
            <SubscribeButton skillId={skillId} />
          </View>
        )}
      </View>

      <Spacer size='24' />

      <ContentWrapper disabled={isShowingBanner}>{children}</ContentWrapper>
    </FullPage>
  )
}

type TabItem = {
  id: 'overview' | 'manage'
  label: string
  content: ReactNode
}

const TabBarOperationMenu: React.FC<{ skillId: SkillId }> = ({ skillId }) => {
  const { t } = useTranslation()
  const invalidateGeneralInfoQuery = useInvalidateGraphQuery(getSkillGeneralInfoQuery)
  const invalidateSkillDetailOverviewData = useInvalidateSkillDetailOverviewData()
  const [dialogOpen, setDialogOpen] = useState<'closed' | 'open-edit' | 'open-delete'>('closed')
  const tracking = useTracking()

  return (
    <>
      <MenuButton
        menuLabel='More options'
        variant='secondary'
        onSelect={item => {
          tracking.skill.clickActionMenu({ skillId, action: item.id })
          switch (item.id) {
            case 'delete-skill': {
              setDialogOpen('open-delete')
              break
            }
            default: {
              assertNever(item.id)
            }
          }
        }}
        menuItems={[
          {
            id: 'delete-skill',
            type: 'label',
            label: t('skills.delete-skill'),
            icon: 'trash-can',
            color: 'destructive/background',
          },
        ]}
        onPrimaryClick={() => {
          setDialogOpen('open-edit')
        }}
      >
        {t('skills.edit-skill')}
      </MenuButton>

      <EditSkillPanel
        onClose={() => {
          setDialogOpen('closed')
        }}
        onSuccess={() => {
          setDialogOpen('closed')
          void invalidateGeneralInfoQuery()
          void invalidateSkillDetailOverviewData()
        }}
        open={dialogOpen === 'open-edit'}
        skillId={dialogOpen === 'open-edit' ? skillId : undefined}
      />
      <DeleteSkillModal
        onClose={() => {
          setDialogOpen('closed')
        }}
        onSuccess={() => {
          setDialogOpen('closed')
          void getGlobalRouter().navigate({ to: '/manage/skills' })
        }}
        skillId={dialogOpen === 'open-delete' ? skillId : undefined}
      />
    </>
  )
}

const SkillActionButtons: React.FC<{ skillId: SkillId }> = ({ skillId }) => {
  const isMobile = useIsMobile()

  return (
    <>
      <SubscribeButton skillId={skillId} />
      {!isMobile && <HorizontalLineDivider marginLeft marginRight />}
      <TabBarOperationMenu skillId={skillId} />
    </>
  )
}

const zodTabType = z.enum(['overview', 'manage'])
const selectedTabSerializer = stringWithZodSerializer(zodTabType)

const ManageView: React.FC<{ skillId: SkillId }> = ({ skillId }) => {
  const { t } = useTranslation()
  const tracking = useTracking()
  const isMobile = useIsMobile()

  const [selectedTab, selectTab] = useQueryState(selectedTabSerializer, 'overview', 'tab')

  const items: TabItem[] = [
    {
      id: 'overview',
      label: t('content.overview'),
      content: (
        <View gap='none' direction='column' paddingTop='16'>
          <TrophyCase skillId={skillId} />
          <Spacer size='32' />
          <SkillDetailOverview skillId={skillId} />
        </View>
      ),
    },
    {
      id: 'manage',
      label: t('skills.manage'),
      content: (
        <View paddingTop='16'>
          <SkillDetailManage skillId={skillId} />
        </View>
      ),
    },
  ]

  return (
    <ConfirmationModalProvider>
      <StrategicErrorBoundary id='manage' strategies={[]}>
        <PageWrapper hideSubscribeButton skillId={skillId} type='manage'>
          {isMobile && (
            <View marginBottom='24'>
              <SkillActionButtons skillId={skillId} />
            </View>
          )}
          <Tabs
            value={selectedTab}
            onChange={newTab => {
              if (newTab === 'manage') {
                tracking.skill.clickManageTab({ skillId })
              }
              selectTab(newTab)
            }}
            items={items}
            hideLine={selectedTab === 'overview'}
            tabBarPostComponent={
              !isMobile ? (
                <View marginLeft='auto'>
                  <SkillActionButtons skillId={skillId} />
                </View>
              ) : undefined
            }
          />
        </PageWrapper>
      </StrategicErrorBoundary>
    </ConfirmationModalProvider>
  )
}

const CenteredContainer = styled.div`
  display: flex;
  justify-content: center;
  width: 100%;
  ${height_100dvh};
`

const LearnerView: React.FC<{ skillId: SkillId; isShowingBanner: boolean }> = ({
  skillId,
  isShowingBanner,
}) => {
  return (
    <PageWrapper type='learner' skillId={skillId} isShowingBanner={isShowingBanner}>
      <TrophyCase skillId={skillId} />
      <Spacer size='32' />
      <SkillDetailOverview skillId={skillId} />
    </PageWrapper>
  )
}

export const SkillDetailPage: React.FC<{ skillId: SkillId; isShowingBanner: boolean }> = ({
  skillId,
  isShowingBanner,
}) => {
  const permissions = useOrganizationPermissions()

  if (permissions.status === 'loading') {
    return (
      <CenteredContainer>
        <LoadingSpinner />
      </CenteredContainer>
    )
  }

  return permissions.has('MANAGE_SKILLS') ? (
    <ManageView skillId={skillId} />
  ) : (
    <LearnerView skillId={skillId} isShowingBanner={isShowingBanner} />
  )
}
