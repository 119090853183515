import _, { capitalize } from 'lodash'
import React from 'react'
import { useTranslation } from 'sierra-client/hooks/use-translation'
import { useResponsivePreview } from 'sierra-client/views/manage/certificates/edit-certificate-panel/use-edit-certificate'
import { Button, LoadingSpinner, Text, View } from 'sierra-ui/primitives'
import { MenuDropdownPrimitive } from 'sierra-ui/primitives/menu-dropdown'
import { DarkTokenProvider, palette } from 'sierra-ui/theming'
import { v2_breakpoint } from 'sierra-ui/theming/breakpoints'
import { useMediaQuery } from 'sierra-ui/utils'
import styled from 'styled-components'

const PreviewContainer = styled(View)<{
  $maxWidth: number
  $maxHeight: number
}>`
  max-width: ${p => p.$maxWidth}px;
  max-height: ${p => p.$maxHeight}px;
  border-radius: ${p => p.theme.borderRadius['size-8']};
  transition: background-color 100ms cubic-bezier(0.25, 0.1, 0.25, 1) 200ms;
  position: relative;
  overflow: hidden;
`

const PlaceholderView = styled(View)<{ $width: number; $height: number; $hide: boolean }>`
  width: ${p => p.$width}px;
  height: ${p => p.$height}px;
  display: ${p => (p.$hide ? 'none' : 'flex')};
  border-radius: ${p => p.theme.borderRadius['size-8']};
  background: ${palette.primitives.white};
  color: ${palette.primitives.black};
  justify-content: center;
`

const PreviewImage = styled.img`
  border-radius: ${p => p.theme.borderRadius['size-8']};
  position: relative;
  transform: translate(-50%, 0%);
  transform-origin: center center;
  max-height: 100%;
  max-width: 100%;
  object-fit: contain;
  left: 50%;
  top: 50%;
`

export type PreviewUrlContainerProps = {
  url?: string
  downloadUrl?: string
  supportingFileUrl?: string | null
  displayWidth?: number
  displayHeight?: number
  animated?: boolean
  linkedInUrl?: string
  canShare?: boolean
  certificateTitle?: string
  btnText?: string
  onShare?: () => void
  onDownload?: () => void
  onClick?: () => void
}

/**
 * As we are using buttons that are not for transparent backgrounds, this needs to be set for now
 * */
const TmpButton = styled(Button)`
  color: black;
  background-color: white;

  &:hover {
    background-color: ${p => p.theme.color.grey10};
    border-color: ${p => p.theme.color.grey10};
  }
`

export const PreviewUrlContainer: React.FC<
  PreviewUrlContainerProps & { animateOut?: boolean; isAchievement?: boolean }
> = ({
  onClick,
  btnText,
  url,
  downloadUrl,
  supportingFileUrl,
  displayWidth = 960,
  displayHeight = 679,
  linkedInUrl,
  canShare = false,
  certificateTitle,
  onShare,
  onDownload,
  animateOut = false,
  isAchievement = false,
}) => {
  const { t } = useTranslation()
  const isMobile = useMediaQuery(`(max-width: ${v2_breakpoint.phone})`)

  const previewContainerElement = React.useRef<HTMLDivElement | null>(null)
  const previewElement = React.useRef<HTMLImageElement | null>(null)

  useResponsivePreview({
    previewContainerElement: previewContainerElement.current,
    previewElement: previewElement.current,
    maxWidth: displayWidth,
    maxHeight: displayHeight,
    padding: 0,
    scaleToContainer: true,
  })

  const [loaded, setLoaded] = React.useState<boolean>(false)
  const [error, setError] = React.useState<string | undefined>(undefined)

  const handleDownload = (): void => {
    if (downloadUrl === undefined && url === undefined) return
    window.open(downloadUrl ?? url, '_self')
    onDownload?.()
  }

  const handleDownloadSupportingFile = (): void => {
    if (supportingFileUrl === undefined || supportingFileUrl === null) return
    window.open(supportingFileUrl, '_self')
  }

  const handleShare = (): void => {
    if (linkedInUrl === undefined && url === undefined) return
    window.open(linkedInUrl ?? url)
    onShare?.()
  }

  React.useEffect(() => {
    const image: HTMLImageElement = new Image(displayWidth, displayHeight)

    image.onload = () => {
      previewElement.current?.setAttribute('src', url ?? '')
      setLoaded(true)
    }

    // The reason we poll like this is because the image might still be generated on the server.
    let retries = 0
    const retry = _.debounce((): void => {
      if (retries >= 50) {
        setError('Failed to load image')
      } else {
        retries += 1
        image.src = url ?? ''
      }
    }, 200)
    image.onerror = retry

    image.src = url ?? ''

    return () => {
      retry.cancel()
      image.onerror = null
      image.onload = null
    }
  }, [url, displayWidth, displayHeight, previewElement])

  return (
    <DarkTokenProvider>
      <View direction='column' gap='16' padding='8'>
        <View
          justifyContent='flex-start'
          animated
          initial={{ opacity: 0, y: 5 }}
          animate={animateOut ? { y: 5, opacity: 0 } : { y: 0, opacity: 1 }}
          transition={{ duration: 0.4, type: 'spring', stiffness: 200, damping: 25 }}
        >
          {isAchievement && <Text bold>{`${t('notification.certificate-earned')}!`}</Text>}
        </View>
        {url !== undefined && (
          <PreviewContainer
            animated
            initial={{ scale: 0.5, opacity: 0 }}
            animate={animateOut ? { scale: 0.5, opacity: 0 } : { scale: 1, opacity: 1 }}
            transition={{ duration: 0.2, type: 'spring', stiffness: 200, damping: 25 }}
            $maxWidth={displayWidth}
            $maxHeight={displayHeight}
            ref={previewContainerElement}
          >
            {/* image has id to allow playwright to verify certificate upon content completion */}
            <PreviewImage
              src={url}
              ref={previewElement}
              hidden={!loaded}
              alt={
                certificateTitle !== undefined
                  ? `Image of received certificate with title ${certificateTitle}`
                  : 'Image of received certificate'
              }
            />
            <PlaceholderView $width={displayWidth} $height={displayHeight} $hide={loaded}>
              {error !== undefined ? <View>{error}</View> : <LoadingSpinner />}
            </PlaceholderView>
          </PreviewContainer>
        )}
        <View
          justifyContent='space-between'
          wrap='wrap'
          animated
          initial={{ opacity: 0, y: -5 }}
          animate={animateOut ? { y: -5, opacity: 0 } : { y: 0, opacity: 1 }}
          transition={{ duration: 0.4, type: 'spring', stiffness: 200, damping: 25 }}
        >
          {isMobile ? (
            <div />
          ) : (
            <Button variant='ghost' onClick={onClick}>
              {btnText ?? t('dictionary.dismiss')}
            </Button>
          )}
          <View wrap='wrap'>
            <MenuDropdownPrimitive
              side='bottom'
              closeOnSelect
              onSelect={item => {
                switch (item.id) {
                  case 'cert-attachment':
                    handleDownloadSupportingFile()
                    break
                  case 'cert':
                    handleDownload()
                    break
                }
              }}
              menuItems={[
                {
                  id: 'cert-attachment',
                  label: capitalize(t('manage.certificates.download-attachment')),
                  type: 'label',
                  disabled: supportingFileUrl === null,
                },
                {
                  id: 'cert',
                  label: capitalize(t('manage.certificates.download')),
                  type: 'label',
                  disabled: url === undefined,
                },
              ]}
              renderTrigger={() => (
                <TmpButton icon='download' variant='ghost'>
                  {capitalize(t('dictionary.download'))}
                </TmpButton>
              )}
            />

            {canShare && linkedInUrl !== undefined && (
              <TmpButton onClick={handleShare} icon='logo--linkedin' variant='ghost'>
                {t('manage.certificates.share-on-linkedin')}
              </TmpButton>
            )}
          </View>
        </View>
      </View>
    </DarkTokenProvider>
  )
}
