import * as U from 'sierra-client/lib/tabular/components/util'
import { useTabularContext } from 'sierra-client/lib/tabular/provider'
import { TableBody, TableHeader } from 'sierra-client/lib/tabular/provider/components/basic/components'
import { InViewportLoadMore } from 'sierra-client/lib/tabular/provider/components/common'
import styled from 'styled-components'

const Table = styled.table`
  width: 100%;
`

const TableFixedScrollContainer = styled.div<{ width: number; height: number }>`
  overflow-x: auto;
  width: ${props => props.width}px;
  height: ${props => props.height}px;
  margin: 0 0.5rem;
`
export const FixedScrollTabular = (): JSX.Element => {
  const { callbacks } = useTabularContext()
  return (
    <U.WithParentSize>
      {({ width, height, scrollRef, setScrollRef }) => (
        <TableFixedScrollContainer
          ref={setScrollRef}
          width={width}
          height={height}
          onScroll={ev => {
            callbacks?.onScroll?.({
              scrollHeight: ev.currentTarget.scrollHeight,
              scrollTop: ev.currentTarget.scrollTop,
              clientHeight: ev.currentTarget.clientHeight,
            })
          }}
        >
          <Table>
            <TableHeader />
            <TableBody containerRef={scrollRef} />
          </Table>
          <InViewportLoadMore />
        </TableFixedScrollContainer>
      )}
    </U.WithParentSize>
  )
}
