import React, { useState } from 'react'
import { graphql } from 'sierra-client/api/graphql/gql'
import { useGraphQuery } from 'sierra-client/api/hooks/use-graphql-query'
import { ActionModal } from 'sierra-client/components/common/modals/action-modal'
import { useNotif } from 'sierra-client/components/common/notifications'
import { useTranslation } from 'sierra-client/hooks/use-translation'
import { EditCertificatePanel } from 'sierra-client/views/manage/certificates/edit-certificate-panel'
import {
  ManageCertificatesTabular,
  ManageCertificatesTabularActions,
  useManageCertificateTableAPI,
} from 'sierra-client/views/manage/certificates/manage-certificates-tabular'
import { useSoftDeleteCertificate } from 'sierra-client/views/manage/certificates/use-soft-delete-certificate'
import { ColumnLayout } from 'sierra-client/views/manage/components/layout/column-layout'
import { useTracking } from 'sierra-client/views/workspace/utils/certificates/tracking'

const listCertificatesQuery = graphql(`
  query certificates {
    certificates {
      id
      title
      previewImageUrl
      analytics {
        active
        expired
      }
      description
      creator {
        displayName
      }
      disabledAt
    }
  }
`)

export const ManageCertificates: React.FC = () => {
  const { t } = useTranslation()
  const tracking = useTracking()
  const notifications = useNotif()
  //const confirmModalContext = useConfirmationModalContext()

  const [action, setAction] = useState<
    { modal: undefined; id?: never } | { modal: 'edit-certificate' | 'confirm-delete'; id?: string }
  >({
    modal: undefined,
  })

  const softDeleteCertificate = useSoftDeleteCertificate()

  const tabularActions: ManageCertificatesTabularActions = React.useMemo<ManageCertificatesTabularActions>(
    () => ({
      onDeleteCertificate: id => {
        setAction({ modal: 'confirm-delete', id })
      },
      onEditCertificate: id => {
        setAction({ modal: 'edit-certificate', id })
      },
    }),
    []
  )

  const queryResult = useGraphQuery(
    {
      document: listCertificatesQuery,
      queryOptions: {
        gcTime: 0,
        refetchOnWindowFocus: false,
      },
    },
    {}
  )
  const { data, refetch } = queryResult

  const tableAPI = useManageCertificateTableAPI(tabularActions, data?.certificates)

  return (
    <ColumnLayout>
      <ManageCertificatesTabular
        actions={tabularActions}
        tableAPI={tableAPI}
        hideCounts={data?.certificates === undefined}
      />
      <EditCertificatePanel
        open={action.modal === 'edit-certificate'}
        onClose={() => setAction({ modal: undefined })}
        afterSubmit={async () => {
          if (action.id === undefined) {
            tracking.created()
          } else {
            tracking.edited(action.id)
          }
          await refetch()
        }}
        editedId={action.id}
      />
      <ActionModal
        open={action.modal === 'confirm-delete'}
        onClose={() => setAction({ modal: undefined })}
        primaryActionLabel={t('manage.certificates.actions.delete')}
        primaryAction={async () => {
          await softDeleteCertificate(action.id!)
          await refetch()
          notifications.push({ type: 'certificate-removed' })
          tracking.deleted(action.id!)
        }}
        title={t('manage.certificates.actions.delete-warning-title')}
        deleteAction={true}
      >
        {t('manage.certificates.actions.delete-warning')}
      </ActionModal>
    </ColumnLayout>
  )
}
