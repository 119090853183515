import { useSearch } from '@tanstack/react-router'
import { memo, useEffect } from 'react'
import { SelfPacedPublishState } from 'sierra-client/api/hooks/use-self-paced-publish-state'
import { ButtonWrapper } from 'sierra-client/components/liveV2/header-buttons'
import { SharingModal } from 'sierra-client/components/sharing/sharing-modal'
import { SharingModalContent } from 'sierra-client/components/sharing/types'
import { useContentKindPermissions } from 'sierra-client/hooks/use-permissions'
import { useToggle } from 'sierra-client/hooks/use-toggle'
import { useTranslation } from 'sierra-client/hooks/use-translation'
import * as settingsState from 'sierra-client/state/author-course-settings/slice'
import { Collaborator } from 'sierra-client/state/author-course-settings/types'
import { useSelector } from 'sierra-client/state/hooks'
import { useUserLegacy } from 'sierra-client/state/users/hooks'
import {
  CoursePermissionSettings,
  useCoursePermissionSettings,
} from 'sierra-client/views/flexible-content/editor/course-permission-settings-context'
import { ContentPermission } from 'sierra-domain/api/common'
import { Icon, IconId } from 'sierra-ui/components'
import { Text } from 'sierra-ui/primitives'
import { useOnChanged } from 'sierra-ui/utils'

const deriveIconFromPermissionSettings = ((
  initialIconId: IconId
): ((coursePermissionSettings: CoursePermissionSettings, courseCollaborators: Collaborator[]) => IconId) => {
  let id = initialIconId

  return (coursePermissionSettings, courseCollaborators) => {
    if (coursePermissionSettings.status === 'done') {
      const { shareLinkAccessLevel, visibilityInOrg } = coursePermissionSettings

      // Anyone can access the document
      if (shareLinkAccessLevel !== 'invite-only') {
        id = 'building'
      }
      // A limited group of people can access the document
      else if (visibilityInOrg === 'visible-admins' || courseCollaborators.length > 1) {
        id = 'user--group'
      }
      // Only me can access the document
      else if (visibilityInOrg === 'private') {
        id = 'locked'
      }
    }

    return id
  }
})('loading')

type SharingButtonProps = {
  content: SharingModalContent
  publishState: SelfPacedPublishState
  permission: ContentPermission
}

export const SharingButton: React.FC<SharingButtonProps> = memo(({ content, publishState, permission }) => {
  const { t } = useTranslation()
  const requestAccessUserId = useSearch({ strict: false, select: s => s['request-access'] })
  const requestAccessUser = useUserLegacy(requestAccessUserId)

  const [showSharingModal, { toggle: toggleSharingModal }] = useToggle()

  useEffect(() => {
    if (requestAccessUserId !== undefined && requestAccessUser) {
      toggleSharingModal()
    }
  }, [requestAccessUserId, requestAccessUser, toggleSharingModal])

  const { fetchInitialCoursePermissionSettings, coursePermissionSettings } = useCoursePermissionSettings()
  const courseCollaborators = useSelector(settingsState.selectors.selectCollaborators)

  const iconId = deriveIconFromPermissionSettings(coursePermissionSettings, courseCollaborators)

  useEffect(() => {
    if (coursePermissionSettings.status === 'idle' || showSharingModal) {
      void fetchInitialCoursePermissionSettings(content.id)
    }
    // We only we want open as a dependency because we want to fetch only when the share modal is opening
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [showSharingModal])

  const coursePermissions = useContentKindPermissions(content.id)
  useOnChanged((prev, isOpen) => {
    if (isOpen) {
      // Refresh the permissions to get the latest state when the modal is open.
      void coursePermissions.refetch()
    }
  }, showSharingModal)

  return (
    <div id='tour-share-create' data-intercom-target='Share button'>
      <ButtonWrapper disabled={iconId === 'loading'} onClick={toggleSharingModal}>
        <Icon iconId={iconId} />
        <Text bold color='currentColor'>
          {t('share.share')}
        </Text>
      </ButtonWrapper>

      <SharingModal
        open={showSharingModal}
        onClose={toggleSharingModal}
        content={content}
        publishState={publishState}
        permission={permission}
        requestAccessUser={requestAccessUser}
      />
    </div>
  )
})
