import React, { useCallback, useContext, useMemo, useRef } from 'react'
import { logger } from 'sierra-client/logger/logger'

type StickyNotesLearnerContextValue = {
  setInitialNoteFontSize: (noteId: string, initialFontSize: string) => void
  getInitialNoteFontSize: (noteId: string) => string | undefined
}

const StickyNotesLearnerContext = React.createContext<StickyNotesLearnerContextValue | undefined>(undefined)

export const StickyNotesLearnerContextProvider: React.FC<{ children: React.ReactNode }> = ({ children }) => {
  /**
   * We need to persist the font size because the editor is recreated when drag is initiated.
   * If we don't cache it we'll get really bad flickering for the first few frames when dragging.
   * This cache maps a nodeId to a string that can be passed to the CSS `font-size` property, like '38px'.
   *
   * We'll use a ref instead of React state since we don't care about rerenders.
   * We only want to read the value once when the component renders for the first time.
   * After that the AutoFitFont extension will manage the font size directly.
   */
  const initialNoteFontSizeCacheRef = useRef(new Map<string, string>())

  const setInitialNoteFontSize = useCallback<StickyNotesLearnerContextValue['setInitialNoteFontSize']>(
    (noteId, initialFontSize) => {
      initialNoteFontSizeCacheRef.current.set(noteId, initialFontSize)
    },
    []
  )

  const getInitialNoteFontSize = useCallback<StickyNotesLearnerContextValue['getInitialNoteFontSize']>(
    noteId => {
      return initialNoteFontSizeCacheRef.current.get(noteId)
    },
    []
  )

  const contextValue = useMemo<StickyNotesLearnerContextValue>(
    () => ({
      setInitialNoteFontSize,
      getInitialNoteFontSize,
    }),
    [setInitialNoteFontSize, getInitialNoteFontSize]
  )

  return (
    <StickyNotesLearnerContext.Provider value={contextValue}>{children}</StickyNotesLearnerContext.Provider>
  )
}

const PROVIDER_NAME: string = StickyNotesLearnerContextProvider.name

const DEFAULT_STICKY_NOTES_LEARNER_CONTEXT: StickyNotesLearnerContextValue = {
  getInitialNoteFontSize: () => undefined,
  setInitialNoteFontSize: () => {},
}

export const useStickyNotesLearnerContext = (): StickyNotesLearnerContextValue => {
  const context = useContext(StickyNotesLearnerContext)

  if (context === undefined) {
    logger.captureWarning(`useStickyNotesLearnerContext was used outside of <${PROVIDER_NAME}>.`)

    return DEFAULT_STICKY_NOTES_LEARNER_CONTEXT
  }

  return context
}
