import { WritableAtom, atom, useSetAtom } from 'jotai'
import React, { useEffect, useMemo } from 'react'
import { mergeupdateAndBroadcastAwareness } from 'sierra-client/collaboration/awareness'
import { ChatBackendSync } from 'sierra-client/collaboration/chat-provider'
import { Chat, ChatProps } from 'sierra-client/components/chat/chat'
import { TypingUserData } from 'sierra-client/components/chat/typing-user-data-type'
import { useStableFunction } from 'sierra-client/hooks/use-stable-function'
import { useTranslation } from 'sierra-client/hooks/use-translation'
import { selectCurrentChatTypers } from 'sierra-client/state/flexible-content/selectors'
import { useSelector } from 'sierra-client/state/hooks'
import { usePolarisContextIfAvailable } from 'sierra-client/views/flexible-content/polaris-editor-provider/polaris-editor-provider'
import { ChatIdentifier } from 'sierra-domain/api/chat'
import { ScopedChatId } from 'sierra-domain/collaboration/types'

const SyncAwarenessToAtom: React.FC<{
  usersTypingAtom: WritableAtom<TypingUserData[], [TypingUserData[]], void>
}> = ({ usersTypingAtom }) => {
  const typingUsersRedux = useSelector(selectCurrentChatTypers)
  const setUsersTyping = useSetAtom(usersTypingAtom)

  useEffect(() => {
    setUsersTyping(typingUsersRedux)
  }, [setUsersTyping, typingUsersRedux])

  return null
}

type EditorChatProps = {
  chatId: ScopedChatId
  chatIdentifier: ChatIdentifier
  open: boolean
  onClose: () => void
  initialThreadId?: string
} & Pick<ChatProps, 'threadId' | 'changeThreadId'>

export const EditorChat: React.FC<EditorChatProps> = ({ chatId, chatIdentifier, open, ...props }) => {
  const { t } = useTranslation()

  const usersTypingAtom = useMemo(() => atom<TypingUserData[]>([]), [])

  const awareness = usePolarisContextIfAvailable()?.awareness
  const setCurrentUserIsTyping = useStableFunction((threadId: string | undefined) => {
    if (awareness === undefined) return

    mergeupdateAndBroadcastAwareness(awareness, { isWritingToChatThreadId: threadId })
  })

  return (
    <>
      <ChatBackendSync chatId={chatId} chatIdentifier={chatIdentifier} />

      <SyncAwarenessToAtom usersTypingAtom={usersTypingAtom} />

      <Chat
        chatIdentifier={chatIdentifier}
        componentKey='flexible-content'
        title={t('live.chat.all-messages')}
        visible={open}
        chatId={chatId}
        showFilter={true}
        usersTypingAtom={usersTypingAtom}
        setCurrentUserIsTyping={setCurrentUserIsTyping}
        {...props}
      />
    </>
  )
}
