import { Logger } from 'sierra-client/core/logging/logger'

type Identifier = `program_${string}`
type ProgramStepPayload = {
  id: string
  type: string
}

export const removedStep = Logger.trackLoggerWithExtra<ProgramStepPayload, ProgramStepPayload>(
  'program_removed_step' satisfies Identifier,
  input => {
    return { ...input }
  }
)

export const dragDrop = Logger.trackLoggerWithExtra<ProgramStepPayload, ProgramStepPayload>(
  'program_dragged_dropped_step' satisfies Identifier,
  input => {
    return { ...input }
  }
)

type ProgramSchedulePayload = ProgramStepPayload & {
  schedule: object
}
export const setStaggeredAssignmentTiming = Logger.trackLoggerWithExtra<
  ProgramSchedulePayload,
  ProgramSchedulePayload
>('program_set_staggered_assigment_timing_step' satisfies Identifier, input => {
  return { ...input }
})

type ProgramMetadataPayload = ProgramStepPayload & {
  metadata: object
}
export const save = Logger.trackLoggerWithExtra<ProgramMetadataPayload, ProgramMetadataPayload>(
  'program_save_new_step' satisfies Identifier,
  input => {
    return { ...input }
  }
)

export const modify = Logger.trackLoggerWithExtra<ProgramMetadataPayload, ProgramMetadataPayload>(
  'program_modify_existing_step' satisfies Identifier,
  input => {
    return { ...input }
  }
)

export const cancel = Logger.trackLoggerWithExtra('program_cancel_new_step' satisfies Identifier, input => {
  return { ...input }
})

export const cancelModifying = Logger.trackLoggerWithExtra<{ id?: string }, { id?: string }>(
  'program_cancel_modification_step' satisfies Identifier,
  input => {
    return { ...input }
  }
)

export const created = Logger.trackLoggerWithExtra('program_created' satisfies Identifier, input => {
  return { ...input }
})

export const deleted = Logger.trackLoggerWithExtra<{ id: string }, { id: string }>(
  'program_deleted' satisfies Identifier,
  input => {
    return { ...input }
  }
)

export const reminderTableActionClicked = Logger.trackLoggerWithExtra<{ id: string }, { id: string }>(
  'program_reminder_table_action_clicked' satisfies Identifier,
  input => {
    return { ...input }
  }
)

export const reminderRowActionClicked = Logger.trackLoggerWithExtra<{ id: string }, { id: string }>(
  'program_reminder_row_action_clicked' satisfies Identifier,
  input => {
    return { ...input }
  }
)

export const reminderLearnMoreClicked = Logger.trackLoggerWithExtra<{ id: string }, { id: string }>(
  'program_reminder_learn_more_clicked' satisfies Identifier,
  input => {
    return { ...input }
  }
)

export const reminderInputClicked = Logger.trackLoggerWithExtra<{ id: string }, { id: string }>(
  'program_reminder_input_clicked' satisfies Identifier,
  input => {
    return { ...input }
  }
)

type ProgramSectionPayload = { sectionIndex: number }
type CollapsableProgramSectionPayload = ProgramSectionPayload & { collapsed: boolean }

export const addSection = Logger.trackLoggerWithExtra<ProgramSectionPayload, ProgramSectionPayload>(
  'program_add_new_section' satisfies Identifier,
  input => {
    return { ...input }
  }
)

export const removeSection = Logger.trackLoggerWithExtra<ProgramSectionPayload, ProgramSectionPayload>(
  'program_remove_new_section' satisfies Identifier,
  input => {
    return { ...input }
  }
)

export const addStepToSection = Logger.trackLoggerWithExtra<
  CollapsableProgramSectionPayload,
  CollapsableProgramSectionPayload
>('program_add_step_to_section' satisfies Identifier, input => {
  return { ...input }
})

export const removeStepFromSection = Logger.trackLoggerWithExtra<
  ProgramSectionPayload,
  ProgramSectionPayload
>('program_remove_step_from_section' satisfies Identifier, input => {
  return { ...input }
})

export const unGroupSection = Logger.trackLoggerWithExtra<ProgramSectionPayload, ProgramSectionPayload>(
  'program_ungroup_section' satisfies Identifier,
  input => {
    return { ...input }
  }
)

export const moveSection = Logger.trackLoggerWithExtra<ProgramSectionPayload, ProgramSectionPayload>(
  'program_move_section' satisfies Identifier,
  input => {
    return { ...input }
  }
)

export const collapseSection = Logger.trackLoggerWithExtra<
  CollapsableProgramSectionPayload,
  CollapsableProgramSectionPayload
>('program_collapse_section' satisfies Identifier, input => {
  return { ...input }
})
