import { createSlice } from '@reduxjs/toolkit'
import { postWithUserErrorException } from 'sierra-client/state/api'
import { createRootStateAsyncThunk } from 'sierra-client/state/create-root-state-async-thunk'
import { statusChanged } from 'sierra-client/state/user/actions'
import { UserState } from 'sierra-client/state/user/user-types'
import { XRealtimeUserMe } from 'sierra-domain/routes'
import { User } from 'sierra-domain/user'

export const updateUser = createRootStateAsyncThunk<User | undefined, void>(
  'user/update-user',
  async (_, { dispatch }) => {
    try {
      const user = await postWithUserErrorException(XRealtimeUserMe, {}, dispatch)
      void dispatch(statusChanged({ type: 'user', user: user }))
      return user
    } catch {
      void dispatch(statusChanged({ type: 'loading', loaded: true }))
    }
  }
)

const initialState: UserState = {
  status: { type: 'loading', loaded: false },
}

export const userSlice = createSlice({
  name: 'user',
  initialState,
  reducers: {},
  extraReducers: builder => {
    builder.addCase(statusChanged.fulfilled, (state, { payload }) => {
      state.status = payload
    })
  },
})
