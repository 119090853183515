import { TranslationKey } from 'sierra-client/hooks/use-translation/types'
import { isElementType } from 'sierra-client/views/v3-author/queries'
import { assertNever } from 'sierra-domain/utils'
import { isValidInteractiveCardChild, SanaEditor } from 'sierra-domain/v3-author'
import { Element, Node, Path } from 'slate'
import { z } from 'zod'

const TypeWithPlaceholder = z.enum(['reflection-card', 'question-card', 'poll-card', 'sliding-scale-card'])
type TypeWithPlaceholder = z.infer<typeof TypeWithPlaceholder>
type ElementWithPlaceholder = Extract<Element, { type: TypeWithPlaceholder }>

const isElementWithPlaceholder = isElementType(TypeWithPlaceholder.options)

const countChildren = (node: ElementWithPlaceholder): number => {
  // Would be cool to find a way to avoid this repetition
  switch (node.type) {
    case 'question-card':
      return node.children.filter(child => Node.isNode(child) && isValidInteractiveCardChild(child)).length
    case 'poll-card':
      return node.children.filter(child => Node.isNode(child) && isValidInteractiveCardChild(child)).length
    case 'reflection-card':
      return node.children.filter(child => Node.isNode(child) && isValidInteractiveCardChild(child)).length
    case 'sliding-scale-card':
      return node.children.filter(child => Node.isNode(child) && isValidInteractiveCardChild(child)).length
    default:
      assertNever(node)
  }
}

const elementTypeToPlaceholderKey: Record<TypeWithPlaceholder, TranslationKey> = {
  'reflection-card': 'author.reflection.placeholder',
  'question-card': 'author.slate.write-a-question',
  'poll-card': 'author.poll.title-placeholder',
  'sliding-scale-card': 'author.sliding-scale.placeholder',
}

export function derivePlaceholderKey(
  editor: SanaEditor,
  currentPath: Path | undefined
): TranslationKey | undefined {
  if (currentPath === undefined) return undefined

  const parent = Node.parent(editor, currentPath)
  if (!Element.isElement(parent) || !isElementWithPlaceholder(parent)) return undefined

  // Only show the placeholder text if this is the only child
  if (countChildren(parent) > 1) return undefined

  return elementTypeToPlaceholderKey[parent.type]
}
