import * as ToastPrimitive from '@radix-ui/react-toast'
import { AnimatePresence, motion } from 'framer-motion'
import _ from 'lodash'
import * as React from 'react'
import { assertIsNonNullable, iife } from 'sierra-domain/utils'
import { TruncatedTextWithTooltip } from 'sierra-ui/components'
import { Button, Text } from 'sierra-ui/primitives'
import { token } from 'sierra-ui/theming'
import styled from 'styled-components'

const StyledProvider = styled(ToastPrimitive.Provider)`
  overflow: hidden;
`

const StyledViewport = styled(ToastPrimitive.Viewport)`
  position: absolute;
  bottom: 8px;
  right: 8px;
  max-width: calc(100% - 16px);
  display: flex;
  flex-direction: column;
  align-items: flex-end;
  gap: 4px;
  z-index: 1;
  pointer-events: none;
`

const StyledRoot = styled(ToastPrimitive.Root)`
  display: flex;
  align-items: center;
  gap: 4px;
  padding-left: 14px;
  border-radius: 15px;
  max-width: min(100%, 500px);
  border: 1px solid ${token('border/default')};
  background-color: ${token('elevated/background')};
  box-shadow: 0 16px 24px 0 rgba(0, 0, 0, 0.08);
  pointer-events: all;
`

const LeftContainer = styled.div`
  display: flex;
  flex-direction: column;
  flex: 1;
  min-width: 0;
`

const TitleContainer = styled(Text)`
  display: flex;
  overflow: hidden;
  white-space: nowrap;
`

const StyledToastButton = styled(Button).attrs({ icon: 'undo', variant: 'transparent' })`
  position: relative;
  margin: 4px;
  margin-left: 8px;
  color: ${token('foreground/muted')};

  :hover {
    color: ${token('foreground/primary')};
  }

  &::before {
    position: absolute;
    content: '';
    top: 10px;
    bottom: 10px;
    width: 1px;
    left: -1px;
    background-color: ${token('border/default')};
  }
`

export type SanaToast = {
  title: string | JSX.Element
  action?: {
    text: string
    altText: string
    onClick: () => void
    closeToastOnClick?: boolean
  }
}

const SingleToast: React.FC<{
  toast: SanaToast
  onClose: () => void
}> = ({ toast, onClose }) => {
  const { title, action } = toast

  const titleNode = iife(() => {
    if (typeof title !== 'string') {
      return title
    }

    return <TruncatedTextWithTooltip bold>{title}</TruncatedTextWithTooltip>
  })

  return (
    <StyledRoot
      onOpenChange={open => {
        if (!open) onClose()
      }}
      asChild
      forceMount
    >
      <motion.li
        initial={{
          y: 100,
          opacity: 0,
        }}
        animate={{
          y: 0,
          opacity: 1,
          transition: { duration: 0.3 },
        }}
        exit={{
          opacity: 0,
          transition: { duration: 0.15 },
        }}
        layout
      >
        <LeftContainer>
          <ToastPrimitive.Title asChild>
            <TitleContainer>{titleNode}</TitleContainer>
          </ToastPrimitive.Title>
        </LeftContainer>
        {action !== undefined && (
          <ToastPrimitive.Action asChild altText={action.altText}>
            <StyledToastButton
              onClick={() => {
                action.onClick()

                if (action.closeToastOnClick === true) {
                  onClose()
                }
              }}
            >
              {action.text}
            </StyledToastButton>
          </ToastPrimitive.Action>
        )}
      </motion.li>
    </StyledRoot>
  )
}

export type ToastState = Record<string, SanaToast>

type ToastContextValue = {
  addToast: (toast: SanaToast) => void
  toasts: ToastState
  setToasts: React.Dispatch<React.SetStateAction<ToastState>>
}
const ToastContext = React.createContext<ToastContextValue | undefined>(undefined)

export const useCreateToast = (): ToastContextValue => {
  const context = React.useContext(ToastContext)

  assertIsNonNullable(context, 'Must be wrapped in ToastContext')

  return context
}

export const CreateToastProvider: React.FC<{
  children: React.ReactNode
}> = ({ children }) => {
  const [toasts, setToasts] = React.useState<ToastState>({})

  const contextValue = React.useMemo(
    (): ToastContextValue => ({
      toasts,
      addToast: toast => {
        const id = _.uniqueId('toast_')
        setToasts(prev => ({ ...prev, [id]: toast }))
      },
      setToasts,
    }),
    [toasts]
  )

  return <ToastContext.Provider value={contextValue}>{children}</ToastContext.Provider>
}

export const CreateToastRenderer: React.FC = () => {
  const { toasts, setToasts } = useCreateToast()

  return (
    <StyledProvider swipeDirection='right' duration={10_000}>
      <AnimatePresence>
        {Object.entries(toasts).map(([key, toast]) => (
          <SingleToast
            key={key}
            toast={toast}
            onClose={() =>
              setToasts(prev => {
                const newState = { ...prev }
                delete newState[key]

                return newState
              })
            }
          />
        ))}
      </AnimatePresence>
      <StyledViewport />
    </StyledProvider>
  )
}
