import { motion } from 'framer-motion'
import React, { MouseEventHandler, useCallback } from 'react'
import { useCurrentUser } from 'sierra-client/api/hooks/use-user'
import { useTranslation } from 'sierra-client/hooks/use-translation'
import { useIsMobile } from 'sierra-client/state/browser/selectors'
import { useUser } from 'sierra-client/state/users/hooks'
import { FCC } from 'sierra-client/types'
import { getAvatarImage } from 'sierra-client/utils/avatar-img'
import { useMatrixData } from 'sierra-client/views/v3-author/matrix/data-layer'
import { RenderingContext } from 'sierra-client/views/v3-author/rendering-context'
import { MatrixPosition } from 'sierra-domain/api/strategy-v2'
import { UserId } from 'sierra-domain/api/uuid'
import { color } from 'sierra-ui/color'
import { RoundAvatar } from 'sierra-ui/components'
import { getElevatedBoxShadowStyles } from 'sierra-ui/components/elevation/elevated-surface-styles'
import { Spacer, Text } from 'sierra-ui/primitives'
import { token } from 'sierra-ui/theming'
import styled, { css } from 'styled-components'

const MOBILE_INSET = 8
const DESKTOP_INSET = 24

type MatrixPositionPercentage = { topPercent: number; leftPercent: number }
const matrixPositionToPercentage = (position: MatrixPosition): MatrixPositionPercentage => ({
  topPercent: Math.round((1 - (position.y + 100) / 200) * 100),
  leftPercent: Math.round(((position.x + 100) / 200) * 100),
})

const Container = styled.div`
  padding: 0;
`

const LeftRightOverFlowCss = css`
  max-height: 100%;
  width: fit-content;
  max-width: 20ch;
`

const TopBottomOverFlowCss = css`
  max-height: 50px;
  width: fit-content;
  max-width: 100%;
`

const OptionsContainer = styled.div<{ $mobile: boolean }>`
  overflow: hidden;
  position: relative;
  width: 100%;
  aspect-ratio: ${p => (p.$mobile ? '3 / 4' : '16 / 9')};
  padding: ${p => (p.$mobile ? MOBILE_INSET : DESKTOP_INSET)}px;
  background-color: ${p => color(p.theme.home.textColor).opacity(0.03)};
  border-radius: 20px;
  display: grid;
  grid-template-columns: repeat(2, 50%);
  grid-template-rows: repeat(2, 50%);
  grid-template-areas: 'a b' 'd c';

  > * {
    &:first-child {
      ${p =>
        p.$mobile
          ? css`
              grid-area: a;
              text-align: start;
              align-self: end;
              justify-self: start;
              transform: translateY(50%) translateX(-50%) rotate(-90deg) translateY(${MOBILE_INSET}px);
              white-space: nowrap;
              overflow: visible;
            `
          : css`
              grid-area: a;
              text-align: start;
              align-self: end;
              justify-self: start;
              transform: translateY(50%);
              ${LeftRightOverFlowCss}
            `}
    }

    &:nth-child(2) {
      ${p =>
        p.$mobile
          ? css`
              grid-area: b;
              text-align: center;
              align-self: start;
              justify-self: start;
              transform: translateX(-50%);
              white-space: nowrap;
              overflow: visible;
            `
          : css`
              grid-area: b;
              text-align: center;
              align-self: start;
              justify-self: start;
              transform: translateX(-50%);
              ${TopBottomOverFlowCss}
            `}
    }

    &:nth-child(3) {
      ${p =>
        p.$mobile
          ? css`
              grid-area: c;
              align-self: start;
              justify-self: end;
              text-align: right;
              transform: translateY(-50%) translateX(50%) rotate(90deg) translateY(${MOBILE_INSET}px);
              white-space: nowrap;
              overflow: visible;
            `
          : css`
              grid-area: c;
              align-self: start;
              justify-self: end;
              text-align: right;
              transform: translateY(-50%);
              ${LeftRightOverFlowCss}
            `}
    }

    &:nth-child(4) {
      ${p =>
        p.$mobile
          ? css`
              grid-area: d;
              text-align: center;
              align-self: end;
              justify-self: end;
              transform: translateX(50%);
              white-space: nowrap;
              overflow: visible;
            `
          : css`
              grid-area: d;
              text-align: center;
              align-self: end;
              justify-self: end;
              transform: translateX(50%);
              ${TopBottomOverFlowCss}
            `}
    }
  }
`

const MatrixInnerContainer = styled.div<{ $inset: number }>`
  position: absolute;
  inset: ${p => p.$inset}px;
  width: calc(100% - ${p => p.$inset * 2}px);
  height: calc(100% - ${p => p.$inset * 2}px);
  overflow: visible;
  cursor: pointer;
`

const SVGContainer = styled.div`
  position: absolute;
  width: 100%;
  height: 100%;
  z-index: -1;
`

const SVG = styled.svg`
  position: absolute;
  width: 100%;
  height: 100%;
  padding: 32px;
  z-index: -1;

  line {
    stroke: ${token('form/border/2')};
  }
`

const DashedCross: React.FC = () => {
  return (
    <SVGContainer contentEditable={false}>
      <SVG width='100%' height='100%'>
        <line
          x1='0'
          y1='50%'
          x2='100%'
          y2='50%'
          strokeDasharray='2, 8'
          strokeWidth='2'
          strokeLinecap='round'
        />
      </SVG>
      <SVG width='100%' height='100%'>
        <line
          x1='50%'
          y1='0'
          x2='50%'
          y2='100%'
          strokeDasharray='2, 8'
          strokeWidth='2'
          strokeLinecap='round'
        />
      </SVG>
    </SVGContainer>
  )
}

const MatrixPositionContainer = styled(motion.div)<{
  percentagePosition: MatrixPositionPercentage
}>`
  position: absolute;

  left: ${p => `${p.percentagePosition.leftPercent}%`};
  top: ${p => `${p.percentagePosition.topPercent}%`};
`

export const CreateMatrix: FCC = ({ children }) => {
  const { t } = useTranslation()
  // The toolbar is rendered as a child of this component and it is positioned absolutely,
  // due to options container being relative, the toolbar is out of place, we therefore extract it
  // and render it outside
  const [optionsChildren, ...toolbarChild] = React.Children.toArray(children)

  return (
    <>
      <Text size='small' bold color='foreground/secondary' contentEditable={false}>
        {t('matrix.place-yourself')}
      </Text>
      <Spacer size='8' contentEditable={false} />
      <Container>
        <OptionsContainer $mobile={false}>
          <RenderingContext withGrid={false}>{optionsChildren}</RenderingContext>
          <DashedCross />
        </OptionsContainer>
        {toolbarChild}
      </Container>
    </>
  )
}

const AvatarContainer = styled.div<{ $withShadow: boolean }>`
  border-radius: 50%;
  box-shadow: ${p =>
    p.$withShadow ? getElevatedBoxShadowStyles({ shadowSize: 'large', border: false }) : 'none'};

  transform: translate(-50%, -50%) scale(0.7);

  ${p =>
    p.$withShadow &&
    css`
      border: 4px solid ${token('surface/default')};
      outline-offset: 8px;
      border: 4px solid #ffffff;
      box-shadow: 0px 8px 16px 0px rgba(0, 0, 0, 0.08);
    `};
`

const MatrixAvatar: FCC<{ userId?: UserId; large?: boolean }> = ({ userId, large }) => {
  const user = useUser(userId)

  if (userId === undefined) {
    return <RoundAvatar size='small' firstName={'Anonymous'} />
  }

  if (user?.status === 'loaded') {
    return (
      <AvatarContainer $withShadow={large === true}>
        {large === true ? (
          <RoundAvatar
            firstName={user.firstName}
            lastName={user.lastName}
            src={getAvatarImage(user.uuid, user.avatar)}
            color={user.avatarColor}
            size='large'
          />
        ) : (
          <RoundAvatar
            size='medium'
            firstName={user.firstName}
            lastName={user.lastName}
            src={getAvatarImage(user.uuid, user.avatar)}
            color={user.avatarColor}
          />
        )}
      </AvatarContainer>
    )
  }

  return null
}

export const LearnMatrix: FCC = ({ children }) => {
  const { t } = useTranslation()
  const { matrixData, userPosition, setUserPosition } = useMatrixData()
  const currentUser = useCurrentUser()
  const isMobile = useIsMobile()

  const handleClick: MouseEventHandler<HTMLDivElement> = useCallback(
    e => {
      const rect = e.currentTarget.getBoundingClientRect()
      const x = e.clientX - rect.left
      const y = e.clientY - rect.top

      const normalizedX = Math.round((x / rect.width) * 200 - 100)
      const normalizedY = Math.round((1 - y / rect.height) * 200 - 100)

      const matrixPosition = MatrixPosition.safeParse({ x: normalizedX, y: normalizedY })

      if (matrixPosition.success) {
        setUserPosition?.(matrixPosition.data)
      } else {
        console.error('Invalid matrix position:', matrixPosition)
      }
    },
    [setUserPosition]
  )

  return (
    <>
      <Text size='small' bold color='foreground/secondary'>
        {t('matrix.place-yourself')}
      </Text>
      <Spacer size='8' contentEditable={false} />
      <Container>
        <OptionsContainer $mobile={isMobile}>
          <RenderingContext withGrid={false}>{children}</RenderingContext>
          <DashedCross />
          <MatrixInnerContainer $inset={isMobile ? MOBILE_INSET : DESKTOP_INSET} onClick={handleClick}>
            {matrixData?.responses.map(response => {
              return (
                <MatrixPositionContainer
                  layout
                  layoutId={response.matrixResponseId}
                  transition={{
                    type: 'spring',
                    stiffness: 500,
                    damping: 30,
                    duration: 0.5,
                  }}
                  key={response.matrixResponseId}
                  percentagePosition={matrixPositionToPercentage(response.position)}
                >
                  <MatrixAvatar userId={response.userId} />
                </MatrixPositionContainer>
              )
            })}
            {userPosition && currentUser.status === 'success' && (
              <MatrixPositionContainer
                layout
                layoutId='user'
                percentagePosition={matrixPositionToPercentage(userPosition)}
                transition={{
                  type: 'spring',
                  stiffness: 500,
                  damping: 30,
                  duration: 0.5,
                }}
              >
                <MatrixAvatar large userId={currentUser.data.uuid} />
              </MatrixPositionContainer>
            )}
          </MatrixInnerContainer>
        </OptionsContainer>
      </Container>
    </>
  )
}
