import React, { useEffect, useRef, useState } from 'react'
import { useGetFormattedTime } from 'sierra-client/core/format'
import { useTranslation } from 'sierra-client/hooks/use-translation'
import { Icon, IconId } from 'sierra-ui/components'
import { InternalTruncatedText, Spacer, Text } from 'sierra-ui/primitives'
import { palette } from 'sierra-ui/theming'
import { animations } from 'sierra-ui/theming/animations'
import { breakpoints, v2_breakpoint } from 'sierra-ui/theming/breakpoints'
import { narrowDotSeparator } from 'sierra-ui/utils'
import styled, { css } from 'styled-components'

const ClickableLabelContainer = styled.div<{ hasPointer: boolean }>`
  flex: 1;
  display: flex;
  align-items: top;
  overflow: hidden;

  ${p =>
    p.hasPointer &&
    css`
      cursor: pointer;
    `}
`

const CollapsableHeaderContainer = styled.div`
  display: flex;
  flex-direction: row;
  align-items: top;
  padding: 1.5rem 0;
`

const IconContainer = styled.div`
  display: flex;
  justify-content: flex-start;
  align-items: top;
  position: absolute;
  right: 0;
  top: 1.75rem;
`

interface OpenStatusIconProps {
  isShown: boolean
  isOpen: boolean
}

const SmallIcon = styled(Icon).attrs({ iconId: 'chevron--down', size: 'size-16' })``

const OpenStatusIcon = styled(SmallIcon)<OpenStatusIconProps>`
  transition: transform ${animations.default.in}s ease;
  opacity: 0;
  display: none;
  transform: rotate(0deg);

  ${p =>
    p.isShown &&
    css`
      opacity: 1;
      display: inline-block;
    `}

  ${p =>
    p.isOpen &&
    css`
      transform: rotate(180deg);
      top: 0.25rem;
    `}
`

const ModuleTypeContainer = styled.div`
  position: relative;
  width: 2.875rem;
  height: 2.875rem;
  min-width: 2.875rem;
  border-radius: 0.5rem;
  border: 1px solid #e5e7eb;
  top: 0;
  display: flex;
  justify-content: center;
  align-items: center;

  @media screen and (min-width: ${v2_breakpoint.phone}) {
    width: 3rem;
    height: 3rem;
    min-width: 3rem;
    top: 50%;
    transform: translateY(-50%);
  }
`

const LabelContainer = styled.div`
  width: 100%;
  overflow: hidden;
  margin-left: 1rem;
`

const Label = styled(InternalTruncatedText).attrs({
  size: 'small',
  bold: true,
  lines: 3,
})`
  padding-right: 1rem;
`

const SubLabel = styled(Text).attrs({
  size: 'small',
})`
  color: ${p => p.theme.color.grey35};
  white-space: nowrap;
`

const CollapsableContent = styled.div<{ isOpen?: boolean }>`
  width: 100%;
  pointer-events: none;
  overflow: hidden;
  opacity: 0;
  height: 0;
  padding-left: 1.5rem;
  transition: opacity ${animations.collapsableList.in}s ease;

  @media screen and (min-width: ${breakpoints.phone}) {
    padding-left: 1rem;
  }

  ${p =>
    (p.isOpen ?? false) &&
    css`
      pointer-events: inherit;
      overflow: visible;
      opacity: 1;
      height: auto;
      margin-top: 0.5rem;
    `}
`

const CollapsableContainer = styled.div<{ isDisabled?: boolean; isLocked: boolean }>`
  position: relative;
  display: flex;
  flex-direction: column;
  border-bottom: 1px solid ${palette.primitives.white};

  ${p =>
    (p.isDisabled ?? false) &&
    css`
      cursor: default;
      pointer-events: none;
    `}

  ${p =>
    p.isLocked &&
    css`
      opacity: 0.5;
    `}
`

const ProgressLine = styled.div<{ $progress: number }>`
  width: 2.5rem;
  height: 2px;
  margin-top: 0.675rem;
  margin-right: 2rem;

  @media screen and (min-width: ${v2_breakpoint.phone}) {
    margin-right: 2.5rem;
  }

  ${p =>
    p.$progress > 0
      ? `background: linear-gradient(90deg, ${p.theme.color.greenBright} ${p.$progress * 100}%, ${
          p.theme.color.greenBright
        } ${p.$progress * 100}%, ${palette.grey[5]} ${p.$progress * 100}%, ${palette.grey[5]} 100%);`
      : `background-color: ${palette.grey[5]}`}
`

const FlexContainer = styled.div`
  display: flex;
  align-items: center;
`

type CollapsableHeaderProps = {
  onClick: () => void
  hasContent: boolean
  isOpen: boolean
  label: React.ReactNode
  sublabel?: React.ReactNode
  progress?: number
  iconId?: IconId
  timeLeft?: number
}

const CollapsableHeader = ({
  onClick,
  hasContent,
  isOpen,
  label,
  sublabel,
  progress,
  iconId,
  timeLeft,
}: CollapsableHeaderProps): JSX.Element => {
  const formattedTime = useGetFormattedTime(timeLeft, (progress ?? 0) > 0)
  const { t } = useTranslation()

  return (
    <CollapsableHeaderContainer>
      <ClickableLabelContainer onClick={onClick} hasPointer={hasContent}>
        <ModuleTypeContainer>
          {iconId && (
            <Icon
              iconId={progress === 1 ? 'checkmark' : iconId}
              size='size-24'
              color={progress === 1 ? 'greenBright' : 'grey50'}
            />
          )}
        </ModuleTypeContainer>
        <LabelContainer>
          <Label color='LEGACY_DEFAULT_TEXT_COLOR_REPLACE_ASAP'>{label}</Label>
          <Spacer size='4' />
          <FlexContainer>
            <SubLabel color='LEGACY_DEFAULT_TEXT_COLOR_REPLACE_ASAP'>{sublabel}</SubLabel>
            {formattedTime !== undefined && (
              <>
                <Spacer size='xxsmall' />
                <Text size='regular' color='grey35'>
                  {narrowDotSeparator}
                </Text>
                <Spacer size='xxsmall' />
                <Text color='grey35' size='small'>
                  {progress === 1 ? t('course-overview.completed') : formattedTime}
                </Text>
              </>
            )}
          </FlexContainer>
        </LabelContainer>
        <IconContainer>
          <OpenStatusIcon isOpen={isOpen} isShown={hasContent} />
        </IconContainer>
        {progress !== undefined && <ProgressLine $progress={progress} />}
      </ClickableLabelContainer>
    </CollapsableHeaderContainer>
  )
}

type CollapsableItemProps = {
  iconId?: IconId
  label: React.ReactNode
  sublabel?: React.ReactNode
  onlyOneOpen: boolean
  onClick?: () => void
  isOpen?: boolean
  children?: React.ReactNode
  id?: string
  className?: string
  progress?: number
  isLocked: boolean
  timeLeft?: number
}

export const CollapsableItem = ({
  isOpen: isOpenGlobal,
  onlyOneOpen,
  onClick,
  label,
  sublabel,
  children,
  id,
  progress,
  iconId,
  isLocked,
  timeLeft,
}: CollapsableItemProps): JSX.Element => {
  const [isOpenLocal, setIsOpenLocal] = useState<boolean>(false) // only used when onlyOneOpen === false
  const rootRef = useRef<HTMLDivElement>(null)

  const hasContent = Boolean(children)
  const isOpen = onlyOneOpen ? isOpenGlobal : isOpenLocal // isOpenGlobal || (!onlyOneOpen && isOpenLocal)

  // Scroll to opened item
  useEffect(() => {
    if (isOpenLocal) {
      // TODO: Do we want this scroll effect?
      rootRef.current?.scrollIntoView({ behavior: 'smooth' })
    }
  }, [isOpenLocal])

  const handleClick = React.useCallback(() => {
    if (!hasContent) return

    if (onlyOneOpen) {
      if (onClick) onClick()
    } else {
      setIsOpenLocal(!isOpenLocal)
    }
  }, [hasContent, onlyOneOpen, isOpenLocal, onClick])

  return (
    <CollapsableContainer id={id} ref={rootRef} isLocked={isLocked}>
      <CollapsableHeader
        onClick={handleClick}
        label={label}
        sublabel={sublabel}
        isOpen={isOpen === true}
        hasContent={hasContent}
        progress={progress}
        iconId={iconId}
        timeLeft={timeLeft}
      />
      <CollapsableContent isOpen={isOpen}>{children}</CollapsableContent>
    </CollapsableContainer>
  )
}
