import { AnimatePresence } from 'framer-motion'
import { useId } from 'react'
import { SidebarButton } from 'sierra-client/components/common/sidebar-button'
import {
  PrimaryTemplateFilter,
  SecondaryTemplateFilter,
  TemplateFilter,
} from 'sierra-client/components/templates/template-filter'
import { Icon } from 'sierra-ui/components'
import { View } from 'sierra-ui/primitives'
import { spacing } from 'sierra-ui/theming'
import { fonts } from 'sierra-ui/theming/fonts'
import styled from 'styled-components'

const StyledSidebarButton = styled(SidebarButton)`
  justify-content: space-between;
`

const SubSidebarButton = styled(SidebarButton)`
  ${fonts.body.small};
  justify-content: space-between;
  width: auto;
  border-style: solid;
  padding: ${spacing['4']};
  padding-left: 0.5rem;
  height: 2.125rem;
  margin-top: 0;
  margin-left: 1.5rem;
`

type ExpandableSideBarButtonProps = {
  expanded: boolean
  primaryLabel: string
  primaryCategory: PrimaryTemplateFilter
  subButtons: { label: string; category: SecondaryTemplateFilter }[]
  templateFilter: TemplateFilter
  setTemplateFilter: (filter: TemplateFilter) => void
  toggle: () => void
  id?: string
}

export const ExpandableSideBarButton: React.FC<ExpandableSideBarButtonProps> = ({
  expanded,
  primaryLabel,
  primaryCategory,
  subButtons,
  templateFilter,
  setTemplateFilter,
  toggle,
  id,
}) => {
  const buttonId = useId()
  const regionId = useId()

  return (
    <View direction='column' id={id}>
      <StyledSidebarButton
        id={buttonId}
        onClick={toggle}
        selected={false}
        aria-expanded={expanded}
        aria-controls={regionId}
      >
        {primaryLabel} <Icon iconId={expanded ? 'chevron--up' : 'chevron--down'} />
      </StyledSidebarButton>
      <AnimatePresence>
        {expanded && (
          <View
            id={regionId}
            role='region'
            aria-labelledby={buttonId}
            direction='column'
            gap='4'
            animated
            initial={{
              y: -8,
              opacity: 0,
            }}
            animate={{
              y: 0,
              opacity: 1,
            }}
            exit={{
              y: -8,
              opacity: 0,
            }}
            transition={{
              duration: 0.1,
              ease: [0.25, 0.1, 0.25, 1],
            }}
          >
            {subButtons.map(({ label, category }) => (
              <SubSidebarButton
                key={label}
                selected={templateFilter.primary === primaryCategory && templateFilter.secondary === category}
                onClick={() => setTemplateFilter({ primary: primaryCategory, secondary: category })}
              >
                {label}
              </SubSidebarButton>
            ))}
          </View>
        )}
      </AnimatePresence>
    </View>
  )
}
