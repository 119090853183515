import { useCallback, useMemo } from 'react'
import { useNonExpiringNotif, useNotif } from 'sierra-client/components/common/notifications'
import { useTranslation } from 'sierra-client/hooks/use-translation'
import {
  GeneratedPoll,
  PollTypes,
  generatePollFlexibleContent,
  getAllNodeIds,
} from 'sierra-client/views/v3-author/slash-menu/content-generation'
import { CreateContentId } from 'sierra-domain/api/nano-id'
import { Entity } from 'sierra-domain/entity'
import { NodeId } from 'sierra-domain/flexible-content/identifiers'
import { PollCard } from 'sierra-domain/v3-author'
import {
  createParagraph,
  createPollCard,
  createPollCardAlternative,
  createPollCardAlternativeContainer,
} from 'sierra-domain/v3-author/create-blocks'
import * as Y from 'yjs'

export function useGeneratePollCard({
  yDoc,
  currentNodeId: nodeId,
  createContentId,
}: {
  yDoc: Y.Doc
  createContentId: CreateContentId
  currentNodeId: NodeId | undefined
}): { generatePollCard: (type: PollTypes) => Promise<Entity<PollCard> | undefined> } {
  const notification = useNotif()
  const persistentNotification = useNonExpiringNotif()
  const { t } = useTranslation()

  const generatePollCard = useCallback(
    async (type: PollTypes): Promise<Entity<PollCard> | undefined> => {
      const nodeIds = getAllNodeIds(yDoc)
      if (nodeIds === undefined) throw new Error('Unable to generate a question in a course without nodeIds')
      if (nodeId === undefined)
        throw new Error('Unable to generate a question. The current node is not defined')

      const previousNodes = nodeIds.includes(nodeId) ? nodeIds.slice(0, nodeIds.indexOf(nodeId) + 1) : nodeIds

      const loadingId = persistentNotification.push({
        type: 'custom',
        title: t('author.slate.generating'),
        body: '',
        level: 'info',
      })
      let poll: GeneratedPoll | undefined = undefined
      try {
        poll = await generatePollFlexibleContent(createContentId, previousNodes, type, notification)
      } finally {
        persistentNotification.remove(loadingId)
      }

      if (poll === undefined) return undefined
      else
        return createPollCard({
          children: [
            createParagraph({ children: [{ text: poll.text }] }),
            createPollCardAlternativeContainer({
              children: poll.alternatives.map(it =>
                createPollCardAlternative({
                  children: [{ text: it }],
                })
              ),
            }),
          ],
        })
    },
    [createContentId, nodeId, notification, persistentNotification, t, yDoc]
  )

  return useMemo(() => ({ generatePollCard }), [generatePollCard])
}
