import { OutlineAction, OutlineState } from 'sierra-client/features/program/admin/edit/types'
import {
  addSection,
  addSteps,
  addStepToEmptySection,
  deleteSection,
  moveSection,
  moveStep,
  patchSection,
  removeStep,
  resetOutline,
  ungroupSection,
  updateDueDate,
  updateLiveSessionAssignment,
  updateSchedule,
  updateStep,
} from 'sierra-client/features/program/admin/edit/utils'
import { ProgramTracking } from 'sierra-client/views/manage/programs/hooks/use-tracking'
import { assertNever, iife } from 'sierra-domain/utils'

export function editOutlineReducer(
  state: OutlineState,
  action: OutlineAction,
  tracking: ProgramTracking
): OutlineState {
  if (state.status === 'pending') {
    if (action.type === 'SET_INITIAL_OUTLINE') {
      return { status: 'ready', outline: action.newOutline }
    } else {
      return state
    }
  }

  const newState = iife(() => {
    switch (action.type) {
      case 'MOVE_STEP':
        return moveStep(action, state.outline, tracking)
      case 'MOVE_SECTION':
        return moveSection(action, state.outline, tracking)
      case 'ADD_STEPS':
        return addSteps(action, state.outline, tracking)
      case 'ADD_SECTION':
        return addSection(state.outline, tracking)
      case 'RESET_OUTLINE':
        return resetOutline(action)
      case 'UNGROUP_SECTION':
        return ungroupSection(action, state.outline, tracking)
      case 'DELETE_SECTION':
        return deleteSection(action, state.outline, tracking)
      case 'PATCH_SECTION':
        return patchSection(action, state.outline)
      case 'REMOVE_STEP':
        return removeStep(action, state.outline, tracking)
      case 'UPDATE_STEP':
        return updateStep(action, state.outline, tracking)
      case 'ADD_STEP_TO_EMPTY_SECTION':
        return addStepToEmptySection(action, state.outline, tracking)
      case 'UPDATE_DUE_DATE':
        return updateDueDate(action, state.outline)
      case 'UPDATE_SCHEDULE':
        return updateSchedule(action, state.outline, tracking)
      case 'UPDATE_LIVE_SESSION_ASSIGNMENT':
        return updateLiveSessionAssignment(action, state.outline)
      case 'SET_INITIAL_OUTLINE':
        return state.outline
      default:
        assertNever(action)
    }
  })

  return { outline: newState, status: 'ready' }
}
