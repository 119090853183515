import { palette, spacing } from 'sierra-ui/theming'
import { v2_breakpoint } from 'sierra-ui/theming/breakpoints'
import { fonts } from 'sierra-ui/theming/fonts'
import { narrowDotSeparator } from 'sierra-ui/utils'
import { css } from 'styled-components'

const margin = '1rem'

export const textBlockStylesV2 = css`
  p {
    margin-bottom: ${margin};
  }

  u {
    font-family: inherit;
  }

  a {
    font-family: inherit;
    color: #002fa7;
    text-decoration: underline;
  }

  ul,
  ol {
    & > li {
      margin: ${spacing['4']};
      position: relative;
      padding-left: 1.5rem;

      @media screen and (min-width: ${v2_breakpoint.phone}) {
        padding-left: 2rem;
      }
    }
  }

  & ol {
    list-style: none;
    counter-reset: my-awesome-counter;

    & > li {
      counter-increment: my-awesome-counter;

      &::before {
        content: counter(my-awesome-counter) '.';
        color: ${palette.primitives.black};
        position: absolute;
        display: inline-block;
        left: 0;
        font-family: ${fonts.sansSerif};
        font-style: normal;
        font-size: 100%;
        font-weight: 500;
      }
    }
  }

  .rtl ol li {
    padding-left: 0;
    padding-right: 1.5em;

    &::before {
      content: counter(my-awesome-counter) '${narrowDotSeparator}';
      left: auto;
      right: 0;
    }
  }

  .rtl ul li {
    padding-right: 1.5em;
    padding-left: 0;
    &:before {
      left: auto;
      right: 0;
    }
  }

  ul {
    list-style: none;

    & > li {
      &:before {
        content: '•';
        font-size: 100%;
        color: ${palette.primitives.black};
        position: absolute;
        left: 0;
      }

      ul li:before {
        content: '○';
        font-size: 100%;
        color: ${palette.primitives.black};
        position: absolute;
        left: 0;
      }
    }
  }

  li {
    margin-left: 2.5rem;

    p {
      margin-bottom: ${margin};
    }

    a {
      font-family: inherit;
      color: #002fa7;
      text-decoration: underline;
    }

    strong {
      color: #212121;
    }
  }
`
