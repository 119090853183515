import { atom } from 'jotai'
import {
  GeneralSettingError,
  GeneralSettings,
} from 'sierra-client/views/manage/users/components/user-settings-panel/general-tab/types'

export const initialGeneralSettingsAtom = atom<GeneralSettings | undefined>(undefined)

export const generalSettingsDraftAtom = atom<GeneralSettings | undefined>(undefined)

export const generalSettingsErrorsAtom = atom<GeneralSettingError[]>([])
