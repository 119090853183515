import { createFileRoute } from '@tanstack/react-router'
import { prefetchCurrentUser } from 'sierra-client/api/hooks/use-user'

import { useOrganizationPermissions } from 'sierra-client/hooks/use-permissions'
import { PageIdentifier } from 'sierra-client/layout/sana-page'
import { prefetchFilterHomeworkDomainReps } from 'sierra-client/views/manage/homeworks/manage-homeworks-tabular'
import { ManageHomeworks } from 'sierra-client/views/manage/homeworks/manage-homeworks/manage-homeworks'
import { ManagePageWrapper } from 'sierra-client/views/manage/utils/manage-page-wrapper'

// Note: ManagePageWrapper handles the role check.
function ManageExercisesPage(): JSX.Element | null {
  const orgPermissions = useOrganizationPermissions()

  if (!orgPermissions.has('MANAGE_HOMEWORK')) {
    if (orgPermissions.status === 'loaded') {
      // ?
      // todo: redirect?
    }
    return null
  }

  return (
    <ManagePageWrapper pageIdentifier={PageIdentifier.ManageHomeworks()}>
      <ManageHomeworks />
    </ManagePageWrapper>
  )
}

const RouteComponent = ManageExercisesPage

export const Route = createFileRoute('/manage/exercises/')({
  component: RouteComponent as React.FC,
  loader: () => {
    void prefetchFilterHomeworkDomainReps()
    void prefetchCurrentUser()
  },
})
