import _ from 'lodash'
import { assertElementType, isElementType } from 'sierra-client/views/v3-author/queries'
import { EmbedInteraction } from 'sierra-domain/api/block-interaction'
import { QuestionInteraction } from 'sierra-domain/api/question-interaction'
import { UserId } from 'sierra-domain/api/uuid'
import {
  BlockResponse,
  FreeTextQuestionCardResponse,
  MatchThePairsQuestionCardResponse,
  MultipleChoiceQuestionCardResponse,
  QuestionCard,
  QuestionCardResponse,
  QuestionCardResponseData,
} from 'sierra-domain/card/question-card'
import { Entity } from 'sierra-domain/entity'
import { textInNodes } from 'sierra-domain/slate-util'
import { asNonNullable, assert, assertIsNonNullable, assertNever } from 'sierra-domain/utils'
import {
  QuestionCardBody,
  QuestionCardMatchThePairsAlternativeOption,
  QuestionCard as SlateQuestionCard,
} from 'sierra-domain/v3-author'

const isQuestionBody = isElementType([
  'question-card-pick-the-best-option-body',
  'question-card-match-the-pairs-body',
  'question-card-select-all-that-apply-body',
  'question-card-free-text-body',
])

export function extractQuestionBody(questionCard: SlateQuestionCard): Entity<QuestionCardBody> {
  const questionBody: Entity<QuestionCardBody> | undefined = questionCard.children.find(isQuestionBody)

  assertIsNonNullable(questionBody, 'Could not extract question body')

  return questionBody
}

export const shufflePairs = (pairs: [string, string][]): [string, string][] => {
  return _.chain(pairs)
    .unzip()
    .thru(([left, right]) => _.zip(_.shuffle(left), _.shuffle(right)))
    .map(([l, r]): [string, string] => [asNonNullable(l), asNonNullable(r)])
    .value()
}

const evaluateMultipleChoice = ({
  element,
  response,
}: {
  element: Entity<QuestionCardBody>
  response: QuestionCardResponseData
}): boolean => {
  // Assert question card body type
  assertElementType(
    ['question-card-select-all-that-apply-body', 'question-card-pick-the-best-option-body'],
    element
  )

  // Assert response type
  assert(
    response.type === 'multiple-choice',
    `Wrong question card response ${response.type} detected for question card with type ${element.type}.`
  )

  const solution = element.children

  const result = Object.entries(response.alternatives)
    // The alternative that the user selected may have been removed by an author of the question already,
    // so we need to remove these responses. In the future, perhaps we should consider invalidating the user's
    // response entirely. It is not clear yet if this is a good idea.
    .filter(([responseId]) => element.children.find(({ id }) => id === responseId) !== undefined)
    .map(([responseId, individualResponse]) => {
      const individualSolution = solution.find(s => s.id === responseId)

      if (individualSolution === undefined) {
        throw new Error(`Could not find id ${responseId} in either response or solution.`)
      }

      return {
        response: individualResponse.status,
        solution: individualSolution.status,
      }
    })

  // In pick-the-best-option we only need at least one selection option to be correct
  return element.type === 'question-card-pick-the-best-option-body'
    ? result.some(r => r.response === 'correct' && r.solution === 'correct')
    : result.every(r => r.response === r.solution)
}

type MatchThePairsAlternative = { id: string; text: string }

export type MatchThePairsAlternativePair = {
  first: MatchThePairsAlternative
  second: MatchThePairsAlternative
}

const createMatchThePairsAlternativeFromElement = (
  element: Entity<QuestionCardMatchThePairsAlternativeOption>
): MatchThePairsAlternative => {
  const id = element.id
  const text = textInNodes(element.children).join('')

  return { id, text }
}

export const createCorrectMatchThePairMappingFromElement = (
  element: Entity<QuestionCardBody>
): MatchThePairsAlternativePair[] => {
  assertElementType('question-card-match-the-pairs-body', element)

  return element.children.map(alternative => {
    const [firstChild, secondChild] = alternative.children

    return {
      first: createMatchThePairsAlternativeFromElement(firstChild),
      second: createMatchThePairsAlternativeFromElement(secondChild),
    }
  })
}

export const evaluateMatchThePairsAlternativeAnswer = (
  correctPairs: MatchThePairsAlternativePair[],
  answer: { firstId: string; secondId: string }
): boolean => {
  const { firstId: submittedFirstId, secondId: submittedSecondId } = answer

  const pairByFirstId = correctPairs.find(p => p.first.id === submittedFirstId)

  // If we can't find the alternative, the answer can't be correct.
  if (pairByFirstId === undefined) {
    return false
  }

  const idsMatch = pairByFirstId.second.id === submittedSecondId

  // The submitted answer exactly matches the correct answer.
  if (idsMatch) {
    return true
  }

  // If the text matches exactly with another alternative, we will still count it
  // as correct, in order to allow, for example:
  // (2) <=> (1+1)
  // (2) <=> (3-1)
  // (2+2) <=> (4)
  // (5-1) <=> (4)

  // Since we now know the ids are not matching, we check the correct match for both the right
  // and left side, and see if the text contents are the same for the alternative chosen
  // by the user. See utils.test.js for some example input and expected outputs.
  const pairBySecondId = correctPairs.find(p => p.second.id === answer.secondId)

  // Same as for the other pair, we can't grade alternatives that we can't find.
  if (pairBySecondId === undefined) {
    return false
  }

  const submittedFirstText = pairByFirstId.first.text.trim()
  const submittedSecondText = pairBySecondId.second.text.trim()

  const correctFirstTextForSubmittedSecondId = pairBySecondId.first.text.trim()
  const correctSecondTextForSubmittedFirstId = pairByFirstId.second.text.trim()

  const textExactlyMatchesOtherAlternative =
    submittedFirstText === correctFirstTextForSubmittedSecondId ||
    correctSecondTextForSubmittedFirstId === submittedSecondText

  return textExactlyMatchesOtherAlternative
}

const evaluateMatchThePairs = ({
  element,
  response,
  forSubmit,
}: {
  element: Entity<QuestionCardBody>
  response: QuestionCardResponseData
  forSubmit: boolean
}): boolean => {
  assertElementType('question-card-match-the-pairs-body', element)

  // Assert response type
  if (response.type !== 'match-the-pairs') {
    throw new Error(
      `Wrong question card response ${response.type} detected for question card with type ${element.type}.`
    )
  }

  const correctPairs = createCorrectMatchThePairMappingFromElement(element)

  const isCorrect =
    response.pairs.every(({ firstId, secondId }) =>
      evaluateMatchThePairsAlternativeAnswer(correctPairs, { firstId, secondId })
    ) &&
    (forSubmit || response.pairs.every(pair => pair.status === 'correct'))

  return isCorrect
}

export const evaluateResponse = ({
  element,
  response,
  forSubmit = false,
}: {
  element: Entity<QuestionCardBody>
  response: QuestionCardResponseData
  forSubmit?: boolean
}): boolean => {
  switch (element.type) {
    case 'question-card-select-all-that-apply-body':
    case 'question-card-pick-the-best-option-body':
      return evaluateMultipleChoice({ element, response })
    case 'question-card-match-the-pairs-body':
      return evaluateMatchThePairs({ element, response, forSubmit })
    case 'question-card-free-text-body':
      throw new Error('Free text question card data has no initializer')
    default:
      assertNever(element)
  }
}

export const embedResponseToInteraction = ({
  blockId,
  blockResponse,
}: {
  blockId: string
  blockResponse: BlockResponse
}): EmbedInteraction => {
  return {
    type: 'embed-interaction',
    correct: blockResponse.correct,
    blockId,
    timestamp: new Date(),
  }
}

export const questionCardResponseToInteraction = ({
  element,
  questionCardResponse,
  forSubmit = false,
}: {
  element: Entity<QuestionCardBody>
  questionCardResponse: QuestionCardResponseData
  forSubmit?: boolean
}): QuestionInteraction => {
  switch (questionCardResponse.type) {
    case 'multiple-choice':
      return {
        questionId: element.id,
        timestamp: new Date(),
        correct: evaluateResponse({ element, response: questionCardResponse, forSubmit }),
        answer: {
          type: 'multiple-choice',
          choices: _.chain(questionCardResponse.alternatives)
            .map((value, key) => ({ id: key, status: value.status }))
            .filter(value => value.status === 'correct')
            .map(value => value.id)
            .value(),
        },
      }
    case 'match-the-pairs': {
      const correct = evaluateResponse({ element, response: questionCardResponse, forSubmit })
      return {
        questionId: element.id,
        timestamp: new Date(),
        correct,
        answer: {
          type: 'match',
          choices: questionCardResponse.pairs.map(({ firstId, secondId }) => ({
            first: firstId,
            second: secondId,
          })),
        },
      }
    }

    case 'free-text':
      throw new Error(`Unhandled question type ${questionCardResponse.type}.`)
    default:
      assertNever(questionCardResponse)
  }
}

/**
 *
 * Combine the previous attempt at a match the pairs question with the existing pairs.
 * All correct matches will be kept, and all wrong matches will be shuffled.
 */
export const combinePreviousAttemptsWithExistingPairs = ({
  previousMatches,
  allCorrectPairs,
}: {
  previousMatches: [string, string][]
  allCorrectPairs: MatchThePairsAlternativePair[]
}): MatchThePairsQuestionCardResponse['pairs'] => {
  const validFirst = new Set(allCorrectPairs.map(({ first }) => first.id))
  const validSecond = new Set(allCorrectPairs.map(({ second }) => second.id))

  const validPairsInPrevious: [string, string][] = previousMatches.filter(
    ([first, second]) => validFirst.has(first) && validSecond.has(second)
  )

  const submittedFirst = new Set(validPairsInPrevious.map(([first]) => first))
  const notIncludedPairs = allCorrectPairs
    .filter(({ first }) => !submittedFirst.has(first.id))
    .map<[string, string]>(pair => [pair.first.id, pair.second.id])

  return [
    ...validPairsInPrevious.map(([firstId, secondId]) => ({
      firstId,
      secondId,
      status: evaluateMatchThePairsAlternativeAnswer(allCorrectPairs, {
        firstId: firstId,
        secondId: secondId,
      })
        ? ('correct' as const)
        : ('incorrect' as const),
    })),
    ...shufflePairs(notIncludedPairs).map(([firstId, secondId]) => ({
      firstId,
      secondId,
      status: 'unsubmitted' as const,
    })),
  ]
}

export const interactionToQuestionCardResponse = ({
  element,
  interaction,
}: {
  element: Entity<QuestionCardBody>
  interaction: QuestionInteraction
}): QuestionCardResponseData => {
  // Check that the interaction type and body type match
  if (
    interaction.answer?.type === 'multiple-choice' &&
    (element.type === 'question-card-select-all-that-apply-body' ||
      element.type === 'question-card-pick-the-best-option-body')
  ) {
    const choices = interaction.answer.choices
    return {
      type: 'multiple-choice',
      alternatives: _.chain(element.children)
        .map(alternative => ({
          id: alternative.id,
          response: {
            status:
              choices.findIndex(choice => choice === alternative.id) > -1
                ? ('correct' as const)
                : ('incorrect' as const),
          },
        }))
        .keyBy('id')
        .mapValues('response')
        .value(),
      selectedAlternativeIds: choices,
    }
  } else if (interaction.answer?.type === 'match' && element.type === 'question-card-match-the-pairs-body') {
    const pairs = combinePreviousAttemptsWithExistingPairs({
      previousMatches: interaction.answer.choices.map(({ first, second }): [string, string] => [
        first,
        second,
      ]),
      allCorrectPairs: createCorrectMatchThePairMappingFromElement(element),
    })

    return {
      type: 'match-the-pairs',
      pairs: pairs,
    }
  } else {
    throw new Error(`Unhandled question type ${interaction.answer?.type}.`)
  }
}

export const interactionToQuestionCard = ({
  element,
  userId,
  interaction,
  sessionId,
}: {
  element: Entity<QuestionCardBody>
  userId: UserId
  interaction: QuestionInteraction | undefined
  sessionId?: string
}): QuestionCard | undefined => {
  if (interaction === undefined) {
    return undefined
  }

  const response = interactionToQuestionCardResponse({ element, interaction })

  return {
    responses: {
      [interaction.questionId]: {
        id: interaction.questionId,
        userId,
        response,
        sessionId,
        wasCorrectWhenSubmitted: interaction.correct,
      } satisfies QuestionCardResponse,
    },
  }
}

const initializeMultipleChoice = (element: Entity<QuestionCardBody>): MultipleChoiceQuestionCardResponse => {
  assertElementType(
    ['question-card-select-all-that-apply-body', 'question-card-pick-the-best-option-body'],
    element
  )

  const alternatives = _.chain(element.children)
    .keyBy('id')
    .mapValues(() => ({ status: 'incorrect' }) as const)
    .value()

  return {
    type: 'multiple-choice',
    alternatives,
    selectedAlternativeIds: [],
  }
}

const initializeMatchThePairs = (element: Entity<QuestionCardBody>): MatchThePairsQuestionCardResponse => {
  assertElementType('question-card-match-the-pairs-body', element)

  const pairs = _.chain(element.children)
    .map<[string, string]>(alternative => [alternative.children[0].id, alternative.children[1].id])
    .thru(shufflePairs)
    .map(pair => ({ firstId: pair[0], secondId: pair[1], status: 'unsubmitted' as const }))
    .value()

  return {
    type: 'match-the-pairs',
    pairs,
  }
}

export const initializeResponse = ({
  element,
}: {
  element: Entity<QuestionCardBody>
}): MultipleChoiceQuestionCardResponse | FreeTextQuestionCardResponse | MatchThePairsQuestionCardResponse => {
  switch (element.type) {
    case 'question-card-select-all-that-apply-body':
    case 'question-card-pick-the-best-option-body':
      return initializeMultipleChoice(element)
    case 'question-card-match-the-pairs-body':
      return initializeMatchThePairs(element)
    case 'question-card-free-text-body':
      throw new Error('Free text question card data has no initializer')
    default:
      assertNever(element)
  }
}
