import { useEffect, useState } from 'react'
import { usePost } from 'sierra-client/hooks/use-post'
import { useTranslation } from 'sierra-client/hooks/use-translation'
import { useDispatch } from 'sierra-client/state/hooks'
import { AuthenticationContent } from 'sierra-client/views/authentication/native/components/authentication-content'
import { isLeft } from 'sierra-domain/either'
import { UserError } from 'sierra-domain/error'
import { XRealtimeAuthUnsubscribeNotificationEmails } from 'sierra-domain/routes'
import { Text } from 'sierra-ui/primitives'

type Props = { code: string }

export const UnsubscribeEmail: React.FC<Props> = ({ code }) => {
  const { t, dynamicT } = useTranslation()
  const [success, setSuccess] = useState(false)
  const [error, setError] = useState<string | undefined>(undefined)
  const dispatch = useDispatch()
  const { postWithUserErrorCode } = usePost()

  useEffect(
    () => {
      void (async () => {
        const result = await postWithUserErrorCode(XRealtimeAuthUnsubscribeNotificationEmails, {
          code,
        })

        if (isLeft(result)) {
          setError(dynamicT(UserError.translation(result.left)))
          return
        }

        setError(undefined)
        setSuccess(true)
      })()
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [code, dispatch, dynamicT]
  )

  return (
    <AuthenticationContent message={t('unsubscribe-email.notifications-unsubscribed')}>
      {success ? (
        <>
          <Text color='foreground/primary' size='regular' lineLength={40} align='center'>
            {t('unsubscribe-email.notifications-reenable')}
          </Text>
        </>
      ) : error !== undefined ? (
        <Text size='regular' color='redBright'>
          {error}
        </Text>
      ) : null}
    </AuthenticationContent>
  )
}
