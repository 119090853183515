import { ActionModal } from 'sierra-client/components/common/modals/action-modal'
import { useTranslation } from 'sierra-client/hooks/use-translation'

type ConfirmModalProps = {
  mode?: 'user-group' | 'program'
  open: boolean
  onClose: () => void
  onConfirm: () => void
}

export const ConfirmDeleteGroupModal = ({
  open,
  mode = 'user-group',
  onClose,
  onConfirm,
}: ConfirmModalProps): JSX.Element => {
  const { t } = useTranslation()

  return (
    <ActionModal
      open={open}
      onClose={onClose}
      primaryAction={onConfirm}
      primaryActionLabel={t(
        mode === 'user-group'
          ? 'admin.organization.learners.yes-delete-group'
          : 'admin.organization.learners.delete-program'
      )}
      deleteAction
    >
      {t(
        mode === 'user-group'
          ? 'admin.organization.learners.delete-group-message'
          : 'admin.organization.learners.delete-program-message'
      )}
    </ActionModal>
  )
}
