import { useCallback } from 'react'
import { useNotif } from 'sierra-client/components/common/notifications'
import { usePost } from 'sierra-client/hooks/use-post'
import { useTranslation } from 'sierra-client/hooks/use-translation'
import { createImageThumbhashBase64 } from 'sierra-client/utils/image-thumbhash'
import { UploadMedia } from 'sierra-client/views/v3-author/common/media-uploader/types'
import { AuthorUploadFileRequest } from 'sierra-domain/api/author-v2'
import { FileImage } from 'sierra-domain/content/v2/content'
import { XRealtimeAuthorUploadFile } from 'sierra-domain/routes'
import { assertNever, iife } from 'sierra-domain/utils'

export const MAX_IMAGE_SIZE_MB = 20
const MAX_IMAGE_SIZE_BYTES = MAX_IMAGE_SIZE_MB * 1024 * 1024

const blobToImage = async (blob: Blob): Promise<HTMLImageElement> => {
  const image = new Image()
  const url = URL.createObjectURL(blob)
  image.src = url
  await image.decode()
  return image
}

export const useUploadImage = (): { uploadImage: UploadMedia<FileImage> } => {
  const { postWithUserErrorException } = usePost()
  const notif = useNotif()
  const { t } = useTranslation()

  const uploadImage = useCallback<UploadMedia<FileImage>>(
    async (blob, assetContext) => {
      if (blob.size > MAX_IMAGE_SIZE_BYTES) {
        notif.push({
          type: 'error',
          body: t('manage.media-library.file-too-large', { size: MAX_IMAGE_SIZE_MB }),
        })

        throw new Error(`File too large (max size ${MAX_IMAGE_SIZE_MB} MB)`)
      }

      const data = iife((): AuthorUploadFileRequest => {
        switch (assetContext.type) {
          case 'course':
            return { type: 'course', courseId: assetContext.courseId }
          case 'path':
            return { type: 'path', pathId: assetContext.pathId }
          case 'program':
            return { type: 'program', programId: assetContext.programId }
          case 'certificate':
            return { type: 'certificate' }
          case 'tag':
            return { type: 'tag' }
          case 'courseTemplate':
          case 'organization':
          case 'organization-settings':
          case 'homework':
          case 'unknown':
          case 'pdf-image':
            return {} // This should never happen
          default:
            assertNever(assetContext)
        }
      })

      const {
        fileId,
        url: sierraFilesUrl,
        namespacedBucketUrl,
      } = await postWithUserErrorException(XRealtimeAuthorUploadFile, data)

      const uploadUrls: string[] = [sierraFilesUrl, namespacedBucketUrl].filter(s => s !== undefined)

      // Double write to both URLs if available
      const uplodImagesPromises = uploadUrls.map(async url => {
        await fetch(url, {
          method: 'PUT',
          body: blob,
          headers: { 'Content-Type': blob.type },
        })
      })

      const [image] = await Promise.all([blobToImage(blob), ...uplodImagesPromises])

      return {
        type: 'file',
        file: fileId,
        width: image.width,
        height: image.height,
        thumbHashBase64: createImageThumbhashBase64(image),
      }
    },
    [postWithUserErrorException, notif, t]
  )

  return { uploadImage }
}
