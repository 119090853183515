import {
  LIVE_CAMERA_HELP_PAGE,
  LIVE_HELP_PAGE_URL,
  LIVE_MICROPHONE_CANNOT_CONNECT_HELP_PAGE,
  LIVE_MICROPHONE_NOT_READABLE_HELP_PAGE,
  LIVE_MICROPHONE_NO_AUDIO_HELP_PAGE,
  LIVE_MICROPHONE_PERMISSION_HELP_PAGE,
} from 'sierra-client/config/links'
import { TranslationKey } from 'sierra-client/hooks/use-translation/types'
import { LiveIssue } from 'sierra-client/state/live/types'

export type LiveIssueCategory = 'audio' | 'video' | 'network'
export type LiveIssueInformation = {
  name: LiveIssue
  category: LiveIssueCategory
  shortDescription: TranslationKey
  longDescription: TranslationKey
  title: TranslationKey
  dismissable: boolean
  helpLink?: string
  recoverable: boolean
}

const unrecoverableCallIssueInformation = {
  category: 'network',
  title: 'live-page.live-issue-information__call-connection-lost--title',
  shortDescription: 'live-page.live-issue-information__call-connection-lost--short-description',
  longDescription: 'live-page.live-issue-information__call-connection-lost--long-description',
  helpLink: LIVE_HELP_PAGE_URL,
  dismissable: false,
  recoverable: false,
} as const

const unrecoverableAblyIssueInformation = {
  category: 'network',
  title: 'live-page.live-issue-information__session-connection-lost--title',
  shortDescription: 'live-page.live-issue-information__session-connection-lost--short-description',
  longDescription: 'live-page.live-issue-information__session-connection-lost--long-description',
  helpLink: LIVE_HELP_PAGE_URL,
  dismissable: false,
  recoverable: false,
} as const

export const ISSUE_INFORMATION: Record<LiveIssue, LiveIssueInformation> = {
  'downlink-low': {
    name: 'downlink-low',
    category: 'network',
    title: 'live-page.live-issue-information__downlink-low--title',
    shortDescription: 'live-page.live-issue-information__downlink-low--short-description',
    longDescription: `live-page.live-issue-information__downlink-low--long-description`,
    dismissable: true,
    recoverable: true,
  },
  'uplink-low': {
    name: 'uplink-low',
    category: 'network',
    title: 'live-page.live-issue-information__uplink-low--title',
    shortDescription: 'live-page.live-issue-information__uplink-low--short-description',
    longDescription: `live-page.live-issue-information__uplink-low--long-description`,
    dismissable: true,
    recoverable: true,
  },
  'camera-permission-denied': {
    name: 'camera-permission-denied',
    category: 'video',
    title: 'live-page.live-issue-information__camera-permission-denied--title',
    shortDescription: 'live-page.live-issue-information__camera-permission-denied--short-description',
    longDescription: 'live-page.live-issue-information__camera-permission-denied--long-description',
    dismissable: false,
    helpLink: LIVE_CAMERA_HELP_PAGE,
    recoverable: true,
  },
  'microphone-permission-denied': {
    name: 'microphone-permission-denied',
    category: 'audio',
    title: 'live-page.live-issue-information__microphone-permission-denied--title',
    shortDescription: 'live-page.live-issue-information__microphone-permission-denied--short-description',
    longDescription: 'live-page.live-issue-information__microphone-permission-denied--long-description',
    dismissable: false,
    helpLink: LIVE_MICROPHONE_PERMISSION_HELP_PAGE,

    recoverable: true,
  },
  'microphone-not-readable': {
    name: 'microphone-not-readable',
    category: 'audio',
    title: 'live-page.live-issue-information__microphone-not-readable--title',
    shortDescription: 'live-page.live-issue-information__microphone-not-readable--short-description',
    longDescription: 'live-page.live-issue-information__microphone-not-readable--long-description',
    dismissable: false,
    helpLink: LIVE_MICROPHONE_NOT_READABLE_HELP_PAGE,
    recoverable: true,
  },
  'camera-not-readable': {
    name: 'camera-not-readable',
    category: 'video',
    title: 'live-page.live-issue-information__camera-not-readable--title',
    shortDescription: 'live-page.live-issue-information__camera-not-readable--short-description',
    longDescription: 'live-page.live-issue-information__camera-not-readable--long-description',
    dismissable: false,
    helpLink: LIVE_CAMERA_HELP_PAGE,
    recoverable: true,
  },
  'denoiser-failed-to-start': {
    name: 'denoiser-failed-to-start',
    category: 'audio',
    title: 'live-page.live-issue-information__denoiser-failed-to-start--title',
    shortDescription: 'live-page.live-issue-information__denoiser-failed-to-start--short-description',
    longDescription: 'live-page.live-issue-information__denoiser-failed-to-start--long-description',
    dismissable: true,
    helpLink: LIVE_HELP_PAGE_URL,
    recoverable: true,
  },
  'denoiser-overloaded': {
    name: 'denoiser-overloaded',
    category: 'audio',
    title: 'live-page.live-issue-information__denoiser-overloaded--title',
    shortDescription: 'live-page.live-issue-information__denoiser-overloaded--short-description',
    longDescription: 'live-page.live-issue-information__denoiser-overloaded--long-description',
    dismissable: true,
    helpLink: LIVE_HELP_PAGE_URL,
    recoverable: true,
  },
  'virtual-background-failed-to-start': {
    name: 'virtual-background-failed-to-start',
    category: 'video',
    title: 'live-page.live-issue-information__virtual-background-failed-to-start--title',
    shortDescription:
      'live-page.live-issue-information__virtual-background-failed-to-start--short-description',
    longDescription: 'live-page.live-issue-information__virtual-background-failed-to-start--long-description',
    dismissable: true,
    helpLink: LIVE_HELP_PAGE_URL,
    recoverable: true,
  },
  'virtual-background-overloaded': {
    name: 'virtual-background-overloaded',
    category: 'video',
    title: 'live-page.live-issue-information__virtual-background-overloaded--title',
    shortDescription: 'live-page.live-issue-information__virtual-background-overloaded--short-description',
    longDescription: 'live-page.live-issue-information__virtual-background-overloaded--long-description',
    dismissable: true,
    helpLink: LIVE_HELP_PAGE_URL,
    recoverable: true,
  },
  'camera-not-found': {
    name: 'camera-not-found',
    category: 'video',
    title: 'live-page.live-issue-information__camera-not-found--title',
    shortDescription: 'live-page.live-issue-information__camera-not-found--short-description',
    longDescription: 'live-page.live-issue-information__camera-not-found--long-description',
    dismissable: true,
    helpLink: LIVE_CAMERA_HELP_PAGE,
    recoverable: true,
  },
  'microphone-not-found': {
    name: 'microphone-not-found',
    category: 'audio',
    title: 'live-page.live-issue-information__microphone-not-found--title',
    shortDescription: 'live-page.live-issue-information__microphone-not-found--short-description',
    longDescription: 'live-page.live-issue-information__microphone-not-found--long-description',
    dismissable: true,
    helpLink: LIVE_MICROPHONE_CANNOT_CONNECT_HELP_PAGE,
    recoverable: true,
  },

  // When we lose the connection to Agora users won't be able to hear or see each other
  // If this happens we ask the user to reload the page since we don't have a good way to recover from this
  'call-connection-lost': {
    name: 'call-connection-lost',
    ...unrecoverableCallIssueInformation,
  },
  // When switching between rooms a poorly timed network event can cause the user to be stuck between rooms
  // If this happens we ask the user to reload the page since we don't have a good way to recover from this
  'call-switch-room-error': {
    name: 'call-switch-room-error',
    ...unrecoverableCallIssueInformation,
  },
  // When we lose the connection to Ably for the session data
  // If this happens the session state (what card you are on, poll results, etc) will not be updated
  // We treat this as an unrecoverable error since we don't have a good way to recover from this
  'session-connection-lost': {
    name: 'session-connection-lost',
    ...unrecoverableAblyIssueInformation,
  },
  // When we lose the connection to Ably for the course content
  // If this happens the content will not be updated if the course is changed (new cards added etc)
  // this can cause a lot of issues so we treat this as an unrecoverable error
  'content-connection-lost': {
    name: 'content-connection-lost',
    ...unrecoverableAblyIssueInformation,
  },
  'microphone-no-audio': {
    name: 'microphone-no-audio',
    category: 'audio',
    title: 'live-page.live-issue-information__microphone-no-audio--title',
    shortDescription: 'live-page.live-issue-information__microphone-no-audio--short-description',
    longDescription: 'live-page.live-issue-information__microphone-no-audio--long-description',
    dismissable: false,
    helpLink: LIVE_MICROPHONE_NO_AUDIO_HELP_PAGE,
    recoverable: true,
  },
  'screen-share-permission-denied': {
    name: 'screen-share-permission-denied',
    category: 'video',
    title: 'live-page.live-issue-information__screen-share-failed--title',
    shortDescription: 'live-page.live-issue-information__screen-share-failed--short-description',
    longDescription: 'live-page.live-issue-information__screen-share-failed--long-description',
    dismissable: true,
    helpLink: LIVE_HELP_PAGE_URL,
    recoverable: true,
  },
  'bad-performance': {
    name: 'bad-performance',
    category: 'video',
    title: 'live-page.live-issue-information__bad-performance--title',
    shortDescription: 'live-page.live-issue-information__bad-performance--short-description',
    longDescription: 'live-page.live-issue-information__bad-performance--long-description',
    dismissable: true,
    helpLink: LIVE_HELP_PAGE_URL,
    recoverable: true,
  },
  'camera-no-image': {
    name: 'camera-no-image',
    category: 'video',
    title: 'live-page.live-issue-information__camera-no-image--title',
    shortDescription: 'live-page.live-issue-information__camera-no-image--short-description',
    longDescription: 'live-page.live-issue-information__camera-no-image--long-description',
    dismissable: true,
    helpLink: LIVE_HELP_PAGE_URL,
    recoverable: true,
  },
}
