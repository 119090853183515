import _ from 'lodash'
import { TranslationKey, TranslationLookup } from 'sierra-client/hooks/use-translation/types'
import { assertNever } from 'sierra-domain/utils'

/**
 * OBS! Only to be used in conjunction with TabularLabel
 * Function to capitalize the first letter of a string
 */
export const capitalizedLabel = (label: BaseTabularLabel): TabularLabel => ({
  type: 'transformed',
  label,
  transform: _.capitalize,
})

export const translatedLabel = (key: TranslationKey): BaseTabularLabel => ({
  type: 'translated',
  key,
})

type BaseTabularLabel =
  | { type: 'translated'; key: TranslationKey }
  | { type: 'untranslated'; label: string }
  | { type: 'empty' }
  | { type: 'concatenated'; label1: TabularLabel; label2: TabularLabel; separator: string }
  | { type: 'dynamic'; key: TranslationKey; variables: Record<string, unknown> }

export type TabularLabel =
  | BaseTabularLabel
  | { type: 'transformed'; label: BaseTabularLabel; transform: (s: string) => string }

export const labelToString = (label: TabularLabel, t: TranslationLookup): string => {
  switch (label.type) {
    case 'translated':
      return t(label.key)
    case 'untranslated':
      return label.label
    case 'empty':
      return ''
    case 'concatenated':
      return [labelToString(label.label1, t), labelToString(label.label2, t)].join(label.separator)
    case 'transformed':
      return label.transform(labelToString(label.label, t))
    case 'dynamic':
      return t(label.key, label.variables)
    default:
      assertNever(label)
  }
}
