import React, { useMemo, useState } from 'react'
import { AppThemeTokenProvider } from 'sierra-client/config/token-provider'
import { useSelector } from 'sierra-client/state/hooks'
import { selectUser } from 'sierra-client/state/user/user-selector'
import { useLiveQuizContext } from 'sierra-client/views/v3-author/live-quiz/live-quiz-context'
import {
  LIVE_QUIZ_AVATAR_EMOJIS,
  LiveQuizAvatarPicker,
} from 'sierra-client/views/v3-author/live-quiz/live-quiz-user/live-quiz-avatar-picker'
import { sample } from 'sierra-domain/utils'
import { Modal } from 'sierra-ui/components'
import { InputStyles, Text, View } from 'sierra-ui/primitives'
import { ButtonShapeStyles } from 'sierra-ui/primitives/button/button'
import { fonts } from 'sierra-ui/theming/fonts'
import { getTheme, useCardTheme } from 'sierra-ui/theming/legacy-theme'
import styled, { ThemeProvider } from 'styled-components'

const Outer = styled(View).attrs({
  direction: 'column',
  justifyContent: 'center',
  alignItems: 'stretch',
  gap: '32',
})`
  min-width: 350px;
`

const Settings = styled(View).attrs({
  direction: 'column',
  justifyContent: 'center',
  alignItems: 'stretch',
  gap: '12',
})``

const NicknameInput = styled.input`
  ${InputStyles};
  text-align: center;
  border-radius: 16px;
  min-height: 52px;

  & {
    ${fonts.heading.h5};
    font-weight: bold;
  }
`

const JoinQuiz = styled.button.attrs({
  children: (
    <Text as='span' size='regular'>
      Join quiz
    </Text>
  ),
})`
  ${ButtonShapeStyles};
  height: auto;
  min-height: 52px;
  width: 100%;
  border-radius: 16px;
`

export const LiveQuizUserSettingsModal: React.FC = () => {
  const [settingsOpen, setSettingsOpen] = useState(true)
  const user = useSelector(selectUser)
  const [nickname, setNickname] = useState(user?.firstName ?? '')
  const [icon, setIcon] = useState(sample(LIVE_QUIZ_AVATAR_EMOJIS))
  const card = useCardTheme()
  const cardTheme = useMemo(() => getTheme(card, 'black'), [card])
  const { setUser } = useLiveQuizContext()

  const onSave = (): void => {
    setUser({ icon, nickname })
    setSettingsOpen(false)
  }

  return (
    <AppThemeTokenProvider>
      <Modal open={settingsOpen} radius='size-28' padding='40' size='fit-content'>
        <Outer>
          <View direction='column' justifyContent='center' alignItems='center' gap='32'>
            <Text color='foreground/muted' size='regular' bold>
              Choose avatar and nickname
            </Text>
            <ThemeProvider theme={cardTheme}>
              <LiveQuizAvatarPicker
                size='modal'
                background={user?.avatarColor}
                icon={icon}
                onSelectIcon={setIcon}
              />
            </ThemeProvider>
          </View>
          <Settings>
            <NicknameInput value={nickname} onChange={e => setNickname(e.target.value)} />
            <JoinQuiz onClick={onSave} />
          </Settings>
        </Outer>
      </Modal>
    </AppThemeTokenProvider>
  )
}
