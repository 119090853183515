import { useCallback, useEffect, useState } from 'react'
import { segmentService } from 'sierra-client/core/segment'
import { usePost } from 'sierra-client/hooks/use-post'
import { useTranslation } from 'sierra-client/hooks/use-translation'
import { logger } from 'sierra-client/logger/logger'
import { selectCourseById } from 'sierra-client/state/content/selectors'
import { useSelector } from 'sierra-client/state/hooks'
import { FCC } from 'sierra-client/types'
import { useShouldShowCourseRating } from 'sierra-client/views/course-helper/content/course-rating/use-should-show-course-rating'
import { useHelperSize } from 'sierra-client/views/course-helper/shared/helper-animation-context'
import { useSelfPacedFiles } from 'sierra-client/views/self-paced/files-provider'
import { XRealtimeStrategyCourseRating } from 'sierra-domain/routes'
import { CourseRatingSubmittedParams } from 'sierra-domain/segment/event'
import { Button, Spacer, Text, TextAreaPrimitive, View } from 'sierra-ui/primitives'
import { token } from 'sierra-ui/theming'
import styled, { css } from 'styled-components'

const Wrapper = styled(View).attrs({
  animated: true,
  direction: 'column',
  gap: 'none',
  background: 'surface/soft',
})`
  padding: 12px 20px;
  border-radius: 16px;
  overflow: hidden;
`

const RatingInner = styled(View).attrs({
  direction: 'column',
  gap: 'none',
  animated: true,
  initial: 'todo',
  variants: {
    todo: {
      opacity: 1,
      height: 'auto',
    },
    done: {
      opacity: 0,
      height: 0,
      pointerEvents: 'none',
      transition: {
        opacity: {
          duration: 0.15,
        },
        height: {
          delay: 0.15,
          duration: 0.1,
          ease: 'linear',
        },
      },
    },
  },
})``

const EmojiSelection = styled(View).attrs({
  direction: 'row',
  justifyContent: 'space-between',
  alignItems: 'center',
  gap: 'none',
})``

const EmojiList = styled(View).attrs({
  direction: 'row',
  justifyContent: 'flex-start',
  alignItems: 'center',
  gap: 'none',
})``

const InfoText = styled(Text).attrs({ size: 'small', bold: true, color: 'foreground/muted' })``

type EmojiSelectionState = 'selected' | 'not-selected' | 'nothing-selected'

const EmojiButton_ = styled.button<{ $selection: EmojiSelectionState }>`
  width: 36px;
  height: 36px;
  border-radius: 100%;
  cursor: pointer;

  display: inline-block;
  flex-direction: row;
  justify-content: center;
  align-items: center;

  transition: all 0.15s cubic-bezier(0.25, 0.5, 0.25, 1);

  ${p => {
    switch (p.$selection) {
      case 'selected':
        return css`
          background-color: ${token('surface/strong')};
        `
      case 'not-selected':
        return css`
          background-color: transparent;
          opacity: 0.2;
        `
      case 'nothing-selected':
        return css`
          background-color: transparent;

          &:hover {
            background-color: ${token('surface/strong')};
          }
        `
    }
  }}
`
const EmojiButton: FCC<{ selection: EmojiSelectionState; onClick: () => void; children: string }> = ({
  selection,
  onClick,
  children,
}) => {
  return (
    <EmojiButton_ $selection={selection} onClick={onClick}>
      {children}
    </EmojiButton_>
  )
}

const Comment = styled(View).attrs({
  direction: 'row',
  justifyContent: 'flex-start',
  alignItems: 'stretch',
  grow: true,
  gap: '12',
  animated: true,
  initial: 'closed',
  variants: {
    open: {
      opacity: 1,
      height: 'auto',
      transition: {
        duration: 0.15,
        when: 'beforeChildren',
      },
    },
    closed: {
      opacity: 0,
      height: 0,
      transition: {
        duration: 0.15,
        when: 'afterChildren',
      },
    },
  },
})``

const CommentInner = styled(View).attrs({
  direction: 'column',
  justifyContent: 'flex-start',
  alignItems: 'flex-end',
  paddingTop: '10', // padding here instead of gap at Wrapper to make it look nice in collapsed state
  grow: true,
  gap: '12',
  animated: true,
  variants: {
    open: {
      opacity: 1,
      transition: {
        duration: 0.15,
      },
    },
    closed: {
      opacity: 0,
      transition: {
        duration: 0.15,
      },
    },
  },
})``

const SubmitFeedback = styled(Button).attrs({ variant: 'secondary' })``

const Thanks = styled(View).attrs({
  animated: true,
  initial: 'todo',
  variants: {
    todo: {
      opacity: 0,
      height: 0,
    },
    done: {
      opacity: 1,
      height: 'auto',
      transition: {
        height: {
          ease: 'linear',
          duration: 0.1,
          delay: 0.15 /* delay opacity in RatingInner */,
        },
        opacity: {
          duration: 0.15,
          delay: 0.25 /* delay opacity in RatingInner + duration of height */,
        },
      },
    },
  },
})``

const ratings: { emoji: string; value: number }[] = [
  { emoji: '😖', value: 1 },
  { emoji: '😞', value: 2 },
  { emoji: '🙂', value: 3 },
  { emoji: '😃', value: 4 },
  { emoji: '😍', value: 5 },
]

/*
 * This component uses updateSize({ animationEnabled: boolean }) quite intensively.
 * With the collapse icon it will be the only two places used, so even though it looks complex,
 * it should be fine.
 * */
export const CourseRatingInternal: React.FC = () => {
  const { t } = useTranslation()
  const { postWithUserErrorException } = usePost()
  const { flexibleContentId: courseId, rootFolder } = useSelfPacedFiles()
  const course = useSelector(state => selectCourseById(state, courseId))
  const courseSettings = course?.settings

  const { updateSize } = useHelperSize()

  const [rating, setRating] = useState<number | undefined>()
  const [comment, setComment] = useState<string>('')
  const [done, setDone] = useState<boolean>(false)

  const onSelectOption = useCallback(
    (optionValue: number) =>
      setRating((prevRating: number | undefined) => (prevRating === optionValue ? undefined : optionValue)),
    []
  )

  /* Ensure we go back to animating the size when we unmount */
  useEffect(() => () => updateSize({ animationEnabled: true }), [updateSize])

  const onSubmit = async (): Promise<void> => {
    if (rootFolder === undefined) {
      return
    }

    const courseTitle = courseSettings !== undefined ? courseSettings.title : rootFolder.title

    if (rating === undefined) return

    try {
      await postWithUserErrorException(XRealtimeStrategyCourseRating, {
        courseId,
        rating,
        comment,
      })

      void segmentService.track(
        new CourseRatingSubmittedParams({
          rating,
          comment,
          courseId,
          courseTitle,
        })
      )
    } catch (error) {
      logger.captureError(error)
    } finally {
      setDone(true)
    }
  }

  return (
    <>
      <Spacer size='16' />
      <Wrapper>
        <RatingInner
          animate={done ? 'done' : 'todo'}
          onAnimationStart={() => updateSize({ animationEnabled: false })}
          onUpdate={() => updateSize({ animationEnabled: false })}
        >
          <EmojiSelection>
            <InfoText>{t('helper.rate-your-experience')}</InfoText>
            <EmojiList>
              {ratings.map(e => (
                <EmojiButton
                  key={e.value}
                  selection={
                    rating === undefined
                      ? 'nothing-selected'
                      : e.value === rating
                        ? 'selected'
                        : 'not-selected'
                  }
                  onClick={() => onSelectOption(e.value)}
                >
                  {e.emoji}
                </EmojiButton>
              ))}
            </EmojiList>
          </EmojiSelection>
          <Comment
            animate={rating !== undefined ? 'open' : 'closed'}
            // Don't animate size changes when the feedback form rolls out
            onAnimationStart={() => updateSize({ animationEnabled: false })}
            onUpdate={() => updateSize({ animationEnabled: false })}
            onAnimationComplete={() => updateSize({ animationEnabled: true })}
          >
            <CommentInner>
              <TextAreaPrimitive
                resize='none'
                placeholder={t('helper.course-rating-add-a-comment')}
                value={comment}
                rows={1}
                onChange={e => setComment(e.target.value)}
              />
              <SubmitFeedback onClick={onSubmit}>{t('helper.send-feedback')}</SubmitFeedback>
            </CommentInner>
          </Comment>
        </RatingInner>
        <Thanks
          animate={done ? 'done' : 'todo'}
          onUpdate={() => updateSize({ animationEnabled: false })}
          onAnimationComplete={() => updateSize({ animationEnabled: true })}
        >
          <InfoText>{t('helper.feedback-thanks')}</InfoText>
        </Thanks>
      </Wrapper>
    </>
  )
}

export const CourseRating: React.FC = () => {
  const shouldShowCourseRating = useShouldShowCourseRating()

  if (!shouldShowCourseRating) {
    return <></>
  }

  return <CourseRatingInternal />
}
