import _ from 'lodash'
import React, { useCallback, useEffect, useMemo, useState } from 'react'
import { useTranslation } from 'sierra-client/hooks/use-translation'
import { labelToString } from 'sierra-client/lib/filter/components/common'
import { valueId } from 'sierra-client/lib/filter/components/predicate-utils'
import { useComposeUserInvitationConfig } from 'sierra-client/views/manage/components/user-attributes/flows/invite-users/hooks/use-compose-user-invitation-config'
import {
  DeleteIcon,
  GreyPill,
} from 'sierra-client/views/manage/components/user-attributes/flows/invite-users/panels/set-attributes-panel/auto-complete-pills'
import { EmailWithValidation } from 'sierra-client/views/manage/components/user-attributes/flows/invite-users/tabs/invite-via-email/email-auto-complete/types'
import {
  isInvalidEmail,
  isValidEmail,
  useEmailVerification,
} from 'sierra-client/views/manage/components/user-attributes/flows/invite-users/tabs/invite-via-email/email-auto-complete/utils'
import { getValueRepFromDomainChoices } from 'sierra-client/views/manage/components/user-attributes/flows/invite-users/utils'
import { useInvitationDomains } from 'sierra-client/views/manage/components/user-attributes/hooks/use-invitation-domains'
import { createInvitationAttribute } from 'sierra-client/views/manage/components/user-attributes/utils'
import {
  UserAccessLevelDomainRep,
  UserAccessRoleDomainRep,
} from 'sierra-domain/user-attributes/user-invitation-domain-rep'
import { LabelMenuItem, MenuItem, Tooltip } from 'sierra-ui/components'
import { InputWithPills } from 'sierra-ui/components/input-with-pills/input-with-pills'
import { delimiters, splitValue } from 'sierra-ui/components/input-with-pills/internals'
import { Button, Text, View } from 'sierra-ui/primitives'
import { SingleSelectDropdown } from 'sierra-ui/primitives/menu-dropdown'
import { DefaultDropdownTriggerProps } from 'sierra-ui/primitives/menu-dropdown/default-dropdown-trigger'
import styled from 'styled-components'

const Grid = styled.div`
  display: grid;
  grid-template-columns: auto min-content;
  grid-template-rows: 46px auto;
  grid-gap: 8px;
  white-space: nowrap;
  align-items: flex-start;
  grid-row-gap: 0;
`

const AddButton = styled(Button)`
  grid-column: 2 / 3;
  grid-row: 1 / 2;
  margin-top: 3px;
`

const AutocompleteWrapper = styled(View).attrs({ grow: true })`
  grid-column: 1 / 2;
  grid-row: 1 / 3;
`

const AccessLevelDropdown: React.FC<{
  accessLevelDomain: UserAccessLevelDomainRep | UserAccessRoleDomainRep
  selectedAccessLevel: MenuItem | undefined
  setAccessLevel: (item: MenuItem | undefined) => void
  variant?: DefaultDropdownTriggerProps['variant']
  allowEmpty?: boolean
  excludeOwner?: boolean
}> = ({
  accessLevelDomain,
  selectedAccessLevel,
  setAccessLevel,
  variant = 'ghost',
  allowEmpty = false,
  excludeOwner = false,
}) => {
  const { t, dynamicT } = useTranslation()

  const menuItems: LabelMenuItem[] = useMemo(() => {
    const domainItems: LabelMenuItem[] = accessLevelDomain.domain.choices
      .filter(c => !excludeOwner || c.value.value !== 'owner')
      .map(c => ({
        type: 'label',
        id: valueId(c.value),
        label: labelToString(c.label, dynamicT),
      }))
    // allowing empty is temporary behavior while testing roles
    if (allowEmpty) {
      return [
        ...domainItems,
        {
          id: 'remove',
          type: 'label' as const,
          label: 'No role',
          color: 'foreground/muted',
        },
      ]
    } else {
      return domainItems
    }
  }, [accessLevelDomain.domain.choices, dynamicT, allowEmpty, excludeOwner])

  return (
    <SingleSelectDropdown
      placeholder={t('dictionary.select')}
      variant={variant}
      selectedItem={selectedAccessLevel}
      onSelect={(item: MenuItem) => {
        if (allowEmpty && item.id === 'remove') {
          setAccessLevel(undefined)
        } else {
          setAccessLevel(item)
        }
      }}
      menuItems={menuItems}
    />
  )
}

export const EmailAutoComplete: React.FC = () => {
  const [value, setValue] = React.useState('')
  const [selectedItems, setSelectedItems] = React.useState<EmailWithValidation[]>([])
  const { accessLevelDomain, accessRoleDomain } = useInvitationDomains()
  const { addUsers } = useComposeUserInvitationConfig()
  const { t, dynamicT } = useTranslation()
  const [accessLevel, setAccessLevel] = useState<MenuItem | undefined>(undefined)
  const [accessRole, setAccessRole] = useState<MenuItem | undefined>(undefined)
  const { getEmailErrorMessage, validateEmails } = useEmailVerification()

  /* Pick an initial access level */
  useEffect(() => {
    if (accessLevel === undefined) {
      const learnerValueRep = getValueRepFromDomainChoices(accessLevelDomain.domain, 'value.string:learner')

      const initialItem: LabelMenuItem = {
        type: 'label',
        id: valueId(learnerValueRep.value),
        label: labelToString(learnerValueRep.label, dynamicT),
      }

      setAccessLevel(initialItem)
    }
  }, [accessLevel, accessLevelDomain, dynamicT, setAccessLevel])

  const handleAddUsers = React.useCallback(() => {
    if (accessLevel === undefined) {
      throw new Error('Access level when adding users should not be undefined.')
    }

    const accessLevelValue = getValueRepFromDomainChoices(accessLevelDomain.domain, accessLevel.id)
    const accessLevelAttribute = createInvitationAttribute(accessLevelDomain.ref, [accessLevelValue.value])

    const accessRoleAttribute = (() => {
      if (accessRoleDomain === undefined || accessRole === undefined) {
        return undefined
      }
      const accessRoleValue = getValueRepFromDomainChoices(accessRoleDomain.domain, accessRole.id)
      return createInvitationAttribute(accessRoleDomain.ref, [accessRoleValue.value])
    })()

    addUsers(
      selectedItems.filter(i => i.success).map(i => i.id),
      accessLevelAttribute,
      accessRoleAttribute
    )

    setSelectedItems(selectedItems.filter(i => i.success === false))
  }, [
    accessLevel,
    accessLevelDomain.domain,
    accessLevelDomain.ref,
    accessRole,
    accessRoleDomain,
    addUsers,
    selectedItems,
  ])

  const onUnselectItem = React.useCallback(
    (item: EmailWithValidation) => setSelectedItems(previous => previous.filter(i => i.id !== item.id)),
    []
  )

  const onEnter = useCallback(
    async (value: string) => {
      const values = splitValue(value)
      const validatedEmails = await validateEmails(values)

      const validEmails = validatedEmails.filter(isValidEmail)
      const invalidEmails = validatedEmails.filter(isInvalidEmail)

      setSelectedItems(old => {
        const updatedEmails = [...old, ...validEmails, ...invalidEmails]
        return _.uniqBy(updatedEmails, 'id')
      })

      setTimeout(() => {
        setValue('')
      }, 0)
    },
    [validateEmails]
  )

  const onValueChange = useCallback(
    (value: string) => {
      const lastChar = value[value.length - 1]

      if (delimiters.test(lastChar ?? '')) {
        void onEnter(value)
      } else {
        setValue(value)
      }
    },
    [onEnter]
  )

  const onPaste = useCallback(
    (event: React.ClipboardEvent<HTMLInputElement>) => {
      void onEnter(event.clipboardData.getData('Text'))
    },
    [onEnter]
  )

  return (
    <Grid>
      <AutocompleteWrapper>
        <InputWithPills
          value={value}
          placeholder={selectedItems.length === 0 ? t('manage.users.invite.emails-placeholder') : undefined}
          onEnter={onEnter}
          onPaste={onPaste}
          onBlur={onEnter}
          onValueChange={onValueChange}
          selectedItems={selectedItems}
          onUnselect={onUnselectItem}
          trailingVisual={
            <View>
              <AccessLevelDropdown
                accessLevelDomain={accessLevelDomain}
                selectedAccessLevel={accessLevel}
                setAccessLevel={setAccessLevel}
              />
              {accessRoleDomain !== undefined && (
                <AccessLevelDropdown
                  accessLevelDomain={accessRoleDomain}
                  selectedAccessLevel={accessRole}
                  setAccessLevel={setAccessRole}
                  allowEmpty={true}
                  // ideally owner option case would be excluded by the backend,
                  // but we still (for now at least) want to support setting owner role in edit user modal
                  excludeOwner={true}
                />
              )}
            </View>
          }
          renderSelectedItem={(user, { onUnselect, ...props }) => {
            return (
              <Tooltip title={user.success === false ? getEmailErrorMessage(user.reason) : ''}>
                <GreyPill error={user.success === false} {...props}>
                  <View gap='6'>
                    <Text
                      size='small'
                      color={user.success === false ? 'destructive/background' : undefined}
                      bold
                    >
                      {user.id}
                    </Text>
                  </View>
                  <DeleteIcon
                    color={user.success === false ? 'destructive/background' : undefined}
                    onClick={onUnselect}
                    aria-label={t('dictionary.remove')}
                  />
                </GreyPill>
              </Tooltip>
            )
          }}
        />
      </AutocompleteWrapper>
      <AddButton disabled={selectedItems.length <= 0} onClick={handleAddUsers}>
        {t('dictionary.add')}
      </AddButton>
    </Grid>
  )
}
