import { produce } from 'immer'
import React, { useEffect } from 'react'
import { cardViewed } from 'sierra-client/core/logging/authoring/logger'
import { useDispatch } from 'sierra-client/state/hooks'
import {
  BreakoutRoomCard,
  BreakoutRoomCardProps,
} from 'sierra-client/views/flexible-content/breakout-room-card'
import { useEditOrResponsesStateSafe } from 'sierra-client/views/flexible-content/create-page-context'
import { fileSupportsShowingResponses } from 'sierra-client/views/flexible-content/data-history-switch/data-history-switch'
import { usePasteFile } from 'sierra-client/views/flexible-content/editor/content-sidebar/copy-paste-utils'
import { EmbedCard, EmbedCardProps } from 'sierra-client/views/flexible-content/embed-card'
import { ExternalNotepadSlateCard } from 'sierra-client/views/flexible-content/external-notepad'
import { FileContext } from 'sierra-client/views/flexible-content/file-context'
import { CreateImageCard, CreateImageCardProps } from 'sierra-client/views/flexible-content/image-card'
import { LobbyCard } from 'sierra-client/views/flexible-content/lobby-card'
import { MigrateToExternalNotepad } from 'sierra-client/views/flexible-content/migrate-to-external-notepads'
import { SlateCard } from 'sierra-client/views/flexible-content/slate-card'
import { VideoCard, VideoCardProps } from 'sierra-client/views/flexible-content/video-card'
import { StickyNotesCard } from 'sierra-client/views/sticky-notes-card/learner'
import { CreateDropAWordCard } from 'sierra-client/views/v3-author/drop-a-word/create-drop-a-word-card'
import { HomeworkCardCreateContext } from 'sierra-client/views/v3-author/homework/homework-card-create-context'
import { HomeworkSettings } from 'sierra-client/views/v3-author/homework/homework-settings'
import { QACard } from 'sierra-client/views/v3-author/qa-card/QA-card'
import {
  ReflectionCardCreateContext,
  useAllowAnonymousResponses,
} from 'sierra-client/views/v3-author/reflection-card/reflection-card-create-context'
import { CreateScenarioCard } from 'sierra-client/views/v3-author/scenario/create-scenario-card'
import { ContentPermission } from 'sierra-domain/api/common'
import { CreateContentId, LiveContentId } from 'sierra-domain/api/nano-id'
import { AssetContext } from 'sierra-domain/asset-context'
import {
  ScopedCreateContentId,
  ScopedFileId,
  ScopedLiveContentId,
  ScopedLiveSessionId,
} from 'sierra-domain/collaboration/types'
import {
  assertIsFileOfType,
  assertIsSlateFile,
  File,
  HomeworkData,
  SlateFile,
} from 'sierra-domain/flexible-content/types'
import { nanoid12 } from 'sierra-domain/nanoid-extensions'
import { assertNever } from 'sierra-domain/utils'
import { Icon } from 'sierra-ui/components'
import { Text, View } from 'sierra-ui/primitives'
import { isDefined } from 'sierra-ui/utils/is-defined'

type CreatePageActions = {
  onEmbedCardUploadDone: EmbedCardProps['onUploadDone']
  onImageCardUploadDone: CreateImageCardProps['onUploadDone']
  onVideoCardUploadDone: VideoCardProps['onUploadDone']
  setVideoCardDuration: VideoCardProps['setVideoDuration']
  onBreakoutCardFormFieldChanged: BreakoutRoomCardProps['onFormFieldChange']
  onHomeworkCardUpdate: (updates: Partial<HomeworkData>) => void
}

const ReflectionCard: React.FC<{
  file: SlateFile
  createContentId: CreateContentId
  readOnly: boolean
}> = ({ file, createContentId, readOnly }) => {
  const context = useAllowAnonymousResponses(file)

  return (
    <ReflectionCardCreateContext context={context}>
      <SlateCard
        courseId={createContentId}
        file={file}
        readOnly={readOnly}
        mode='create'
        enableCommenting={true}
      />
    </ReflectionCardCreateContext>
  )
}

const HomeworkCard: React.FC<{
  file: SlateFile
  createContentId: CreateContentId
  readOnly: boolean
  setData: CreatePageActions['onHomeworkCardUpdate']
}> = ({ file, createContentId, readOnly, setData: patchHomeworkData }) => {
  if (file.data.type !== 'homework') {
    throw new Error(`HomeworkCard can not render file type: ${file.data.type}`)
  }

  const data = file.data

  return (
    <HomeworkCardCreateContext
      context={{
        limitOfSubmissions: data.limitOfSubmissions,
        setLimitOfSubmissions: limitOfSubmissions => {
          patchHomeworkData({ limitOfSubmissions })
        },
        submissionType: data.submissionType ?? 'file',
        fileSubmissionSubtype: data.fileSubmissionSubtype,
        setSubmissionType: (submissionType, fileSubmissionSubtype) => {
          patchHomeworkData({ submissionType, fileSubmissionSubtype })
        },
        criteria: data.criteria ?? [],
        createCriteria() {
          patchHomeworkData({
            criteria: [
              ...(data.criteria ?? []),
              { id: nanoid12(), title: '', data: { type: 'rating-1-to-5' } },
            ],
          })
        },
        updateCriteria(id, newCriteria) {
          patchHomeworkData({
            criteria: data.criteria?.map(criteria => (criteria.id === id ? newCriteria : criteria)),
          })
        },
        deleteCriteria(id) {
          patchHomeworkData({
            criteria: data.criteria?.filter(criteria => criteria.id !== id) ?? [],
          })
        },
        moveCriteria({ from, to }) {
          if (from === to) return

          patchHomeworkData({
            criteria: produce(data.criteria, draft => {
              if (draft === undefined) return

              const [removed] = draft.splice(from, 1)
              if (removed === undefined) return

              draft.splice(to, 0, removed)
            }),
          })
        },
      }}
    >
      <SlateCard
        courseId={createContentId}
        file={file}
        readOnly={readOnly}
        mode='create'
        enableCommenting={true}
      />
      <HomeworkSettings courseId={createContentId} fileId={ScopedFileId.extractId(file.id)} />
    </HomeworkCardCreateContext>
  )
}

const StickyNotes: React.FC<{ createContentId: CreateContentId; file: SlateFile; readOnly: boolean }> = ({
  createContentId,
  file,
  readOnly,
}) => {
  const editOrResponseState = useEditOrResponsesStateSafe()

  if (editOrResponseState?.type === 'responses' && isDefined(editOrResponseState.liveSessionId)) {
    return (
      <StickyNotesCard
        blockedDueToSessionLimit={false}
        fileId={file.id}
        liveSessionId={ScopedLiveSessionId.fromId(editOrResponseState.liveSessionId)}
        mode='recap'
      />
    )
  }
  return (
    <SlateCard
      courseId={createContentId}
      file={file}
      readOnly={readOnly}
      mode='create'
      enableCommenting={true}
    />
  )
}

const Switch: React.FC<
  {
    file: File
    scopedCreateContentId: ScopedCreateContentId
    readOnly: boolean
    assetContext: AssetContext
    permission: ContentPermission
  } & CreatePageActions
> = ({
  file,
  scopedCreateContentId,
  readOnly: _readOnly,
  assetContext,
  permission,
  onEmbedCardUploadDone,
  onImageCardUploadDone,
  onVideoCardUploadDone,
  setVideoCardDuration,
  onBreakoutCardFormFieldChanged,
  onHomeworkCardUpdate,
}) => {
  const createContentId = ScopedCreateContentId.extractId(scopedCreateContentId)
  const pasteFile = usePasteFile({ nextTo: file.id })

  const editOrResponsesState = useEditOrResponsesStateSafe()
  const readOnlyOverride =
    editOrResponsesState?.type === 'responses' &&
    fileSupportsShowingResponses(file, ScopedCreateContentId.contentType(scopedCreateContentId))

  const readOnly = readOnlyOverride || _readOnly

  switch (file.data.type) {
    case 'slate-card':
    case 'poll':
    case 'sliding-scale':
    case 'flip-cards':
    case 'bullet':
    case 'general':
    case 'question-card':
    case 'assessment-card':
    case 'project-card': {
      assertIsSlateFile(file)

      return (
        <SlateCard
          courseId={createContentId}
          file={file}
          readOnly={readOnly}
          mode='create'
          enableCommenting={true}
        />
      )
    }

    case 'sticky-notes': {
      assertIsFileOfType(file, 'sticky-notes')

      return <StickyNotes createContentId={createContentId} file={file} readOnly={readOnly} />
    }
    case 'homework': {
      assertIsFileOfType(file, 'homework')

      return (
        <HomeworkCard
          createContentId={createContentId}
          file={file}
          readOnly={readOnly}
          setData={onHomeworkCardUpdate}
        />
      )
    }
    case 'reflections': {
      assertIsFileOfType(file, 'reflections')

      return <ReflectionCard createContentId={createContentId} file={file} readOnly={readOnly} />
    }
    case 'live-lobby':
      return <LobbyCard />
    case 'breakout-room':
      return (
        <BreakoutRoomCard
          data={file.data}
          onFormFieldChange={readOnly ? undefined : onBreakoutCardFormFieldChanged}
        />
      )
    case 'video':
      return (
        <VideoCard
          file={file}
          readOnly={readOnly}
          video={file.data.video}
          setVideoDuration={setVideoCardDuration}
          onUploadDone={onVideoCardUploadDone}
          assetContext={assetContext}
        />
      )
    case 'image':
      return (
        <CreateImageCard
          data={file.data}
          onUploadDone={onImageCardUploadDone}
          readOnly={readOnly}
          mode='create'
          assetContext={{ type: 'course', courseId: createContentId }}
        />
      )
    case 'stupid-questions':
      return <QACard fileId={file.id} data={file.data} readOnly={readOnly} />
    case 'embed':
      return <EmbedCard data={file.data} onUploadDone={onEmbedCardUploadDone} readOnly={readOnly} />
    case 'drop-a-word': {
      assertIsFileOfType(file, 'drop-a-word')

      return (
        <CreateDropAWordCard
          createContentId={createContentId}
          readOnly={readOnly}
          file={file}
          fileId={file.id}
          permission={permission}
        />
      )
    }
    case 'scenario':
      return <CreateScenarioCard courseId={createContentId} file={file} readOnly={readOnly} />
    case 'roleplay':
      return (
        <View justifyContent='center' grow>
          <Icon iconId='skill--wrench--and--screwdriver' />
          <Text size='large'>Under construction. Will return as a new card! Please delete this one.</Text>
          <Icon iconId='skill--wrench--and--screwdriver' />
        </View>
      )

    case 'external-notepad':
      return (
        <ExternalNotepadSlateCard
          mode='create'
          pasteFile={pasteFile}
          file={{ ...file, data: file.data }}
          scopedLiveContentId={ScopedLiveContentId.fromId(LiveContentId.parse(createContentId))}
          enableCommenting={true}
          blockedDueToSessionLimit={false}
          assetContext={assetContext}
        />
      )

    case 'notepad':
      return <MigrateToExternalNotepad liveContentId={LiveContentId.parse(createContentId)} />

    default:
      assertNever(file.data)
  }
}

export const CreateCardRenderer: React.FC<
  {
    file: File
    scopedCreateContentId: ScopedCreateContentId
    readOnly: boolean
    assetContext: AssetContext
    permission: ContentPermission
  } & CreatePageActions
> = props => {
  const dispatch = useDispatch()

  useEffect(() => {
    void dispatch(
      cardViewed({
        contentId: ScopedCreateContentId.extractId(props.scopedCreateContentId),
        fileId: props.file.id,
        cardType: props.file.data.type,
        mode: 'create',
      })
    )
  }, [dispatch, props.file.data.type, props.file.id, props.scopedCreateContentId])

  const liveSessionId = useEditOrResponsesStateSafe()?.liveSessionId

  return (
    <FileContext
      file={props.file}
      scopedCreateContentId={props.scopedCreateContentId}
      liveSessionId={liveSessionId}
    >
      <Switch {...props} />
    </FileContext>
  )
}
