import { FC } from 'react'
import { EnrollmentStepType } from 'sierra-client/views/learner/program/utils/step-graphql'
import { getHomeworkGradeForStep } from 'sierra-client/views/learner/program/utils/utils'
import { assertNever, iife } from 'sierra-domain/utils'
import { StepBadge } from 'sierra-ui/components'

type StepBadgeLearnerProps = {
  step: EnrollmentStepType
  locked?: boolean
}

export const LearnerStepBadge: FC<StepBadgeLearnerProps> = ({ step, locked }) => {
  const exerciseGradeStatus = getHomeworkGradeForStep(step)

  return iife<React.ReactNode>(() => {
    switch (step.__typename) {
      case 'UserProgramCourseStep':
      case 'UserProgramPathStep': {
        const showFailed =
          exerciseGradeStatus === 'failed' || exerciseGradeStatus === 'failed-with-no-retries'
        const showNotGraded = exerciseGradeStatus === 'not-graded'
        const completed = step.progress.progress === 1

        if (showFailed) {
          return <StepBadge variant='failed' size='small' locked={locked} />
        } else if (showNotGraded) {
          return <StepBadge variant='pending' size='small' locked={locked} />
        } else if (completed) {
          return <StepBadge variant='completed' size='small' locked={locked} />
        } else {
          return null
        }
      }
      case 'UserProgramEmailStep':
        return <StepBadge variant='email' size='small' />
      default:
        assertNever(step)
    }
  })
}
