import React from 'react'
import { SanaImage } from 'sierra-client/components/common/image'
import { useResolveAsset } from 'sierra-client/hooks/use-resolve-asset'
import { useToggle } from 'sierra-client/hooks/use-toggle'
import { useIsMobile } from 'sierra-client/state/browser/selectors'

import { LearnerCourseSettings } from 'sierra-domain/api/content-v2'
import { AssetContext } from 'sierra-domain/asset-context'
import { isDefined } from 'sierra-domain/utils'
import { Icon } from 'sierra-ui/components'
import { IconButton, Text, View } from 'sierra-ui/primitives'
import { token } from 'sierra-ui/theming'
import { v2_breakpoint } from 'sierra-ui/theming/breakpoints'
import styled, { css } from 'styled-components'

const StyledSanaImage = styled(SanaImage)`
  width: 1.5rem;
`

type HeaderViewContainerProps = {
  className?: string
}

export const HeaderViewContainer = styled(View).attrs((p: { className?: string }) => ({
  className: p.className,
  padding: '8',
}))<HeaderViewContainerProps>`
  top: 0;
  left: 0;
  right: 0;
  justify-content: space-between;

  color: ${token('foreground/primary')};

  p {
    white-space: nowrap;
  }

  @media screen and (max-width: ${v2_breakpoint.phone}) {
    flex-direction: column;
    align-items: start;
  }
`

export const pointerEventMixing = css`
  & > * {
    pointer-events: auto; /* The header is set to pointer-events: none; set pointer-events: auto to element which should always be clickable */
  }
`

export const floatingPill = css`
  z-index: 1;
  border-radius: 8px;
  height: 2.5rem;

  &:empty {
    display: none;
  }
`

const MobileMenuContainer = styled.div`
  display: flex;
  justify-content: flex-start;
  align-items: center;
  flex-grow: 0;
  flex-wrap: nowrap;
  flex-direction: row;
  cursor: pointer;
  pointer-events: auto;
  gap: 8px;
  height: 2.25rem;
  padding: 0 0.625rem;
  border-radius: ${p => p.theme.borderRadius['size-10']};

  background-color: ${token('surface/soft')};

  &:hover {
    background-color: ${token('surface/soft')};
  }

  @media screen and (min-width: ${v2_breakpoint.phone}) {
    display: none;
  }
`

const HeaderContentContainer = styled(View)<{ $show: boolean }>`
  @media screen and (max-width: ${v2_breakpoint.phone}) {
    ${p =>
      !p.$show &&
      css`
        display: none;
      `}
  }
`

export const LeftHeaderContent = styled(HeaderContentContainer)`
  flex-basis: 33%;
  justify-content: flex-start;
`

export const CenterHeaderContent = styled(HeaderContentContainer)`
  flex: 1;
  justify-content: center;
`

export const RightHeaderContent = styled(HeaderContentContainer)`
  flex-basis: 33%;
  justify-content: flex-end;
`

const HeaderContent = styled(View).attrs({
  padding: 'none',
  gap: 'xxsmall',
})`
  ${pointerEventMixing}
`

const ExitButton = styled(HeaderContent).attrs({
  gap: 'xxsmall',
})`
  justify-content: center;
  ${floatingPill}
  padding: 0 8px;
`

export const CenterContent = styled(HeaderContent).attrs({
  gap: 'xxsmall',
})`
  justify-content: center;
`

const LeftContent = styled(HeaderContent).attrs({
  gap: 'xxsmall',
})`
  justify-content: left;
`

const RightContent = styled(HeaderContent).attrs({
  gap: 'xxsmall',
})`
  justify-content: flex-end;
`
export const SimpleTextTitle = styled(Text)`
  transform: translateY(1px);
  max-width: 18ch;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
`
const MobileHeaderWrapper = styled(View)`
  width: 100%;

  @media screen and (min-width: ${v2_breakpoint.phone}) {
    display: none;
  }
`

const MobileTitle = styled(View)`
  padding: 0 0.5rem 0 0;
`

type HeaderViewProps = {
  title?: React.ReactNode
  disabledHover?: boolean
  onClickBack?: () => void
  backButtonTooltip?: string
  rightContent?: React.ReactNode
  centerContent?: React.ReactNode
  border?: boolean
  className?: string
  image?: LearnerCourseSettings['image']
  hideBackButton?: boolean
  assetContext: AssetContext
}

export const HeaderView: React.FC<HeaderViewProps> = ({
  title,
  onClickBack,
  rightContent,
  centerContent,
  className,
  image,
  assetContext,
  backButtonTooltip = 'Exit',
  hideBackButton = false,
}) => {
  const [showMobileMenu, showMobileMenuHandlers] = useToggle(false)
  const isMobile = useIsMobile()
  const hasContent = rightContent !== undefined || centerContent !== undefined

  const imgSrc = useResolveAsset({
    image,
    size: 'course-sm',
    assetContext,
  })

  return (
    <HeaderViewContainer className={className}>
      <MobileHeaderWrapper direction='row' grow>
        {!hideBackButton && (
          <ExitButton>
            <IconButton
              variant='transparent'
              iconId='chevron--left--small'
              onClick={onClickBack}
              tooltip={backButtonTooltip}
            />
          </ExitButton>
        )}
        <MobileTitle grow>{title}</MobileTitle>
        {hasContent && (
          <MobileMenuContainer onClick={showMobileMenuHandlers.toggle}>
            <Icon iconId={showMobileMenu ? 'close' : 'menu'} color='currentColor' />
          </MobileMenuContainer>
        )}
      </MobileHeaderWrapper>
      <LeftHeaderContent $show={showMobileMenu}>
        {!isMobile && isDefined(title) && (
          <>
            {!hideBackButton && (
              <ExitButton>
                <IconButton
                  variant='transparent'
                  iconId='chevron--left--small'
                  onClick={onClickBack}
                  tooltip={backButtonTooltip}
                />
              </ExitButton>
            )}
            <LeftContent>
              <View gap='xxsmall'>
                {image !== undefined && <StyledSanaImage src={imgSrc} ratio='1:1' rounded />}
                {title}
              </View>
            </LeftContent>
          </>
        )}
      </LeftHeaderContent>

      <CenterHeaderContent $show={showMobileMenu}>
        {centerContent !== undefined && <CenterContent>{centerContent}</CenterContent>}
      </CenterHeaderContent>
      <RightHeaderContent $show={showMobileMenu}>
        {rightContent !== undefined && <RightContent>{rightContent}</RightContent>}
      </RightHeaderContent>
    </HeaderViewContainer>
  )
}
