import { useMutation } from '@tanstack/react-query'
import { FC, useCallback, useEffect, useMemo } from 'react'
import { getNamespacedImage } from 'sierra-client/api/content'
import { graphql } from 'sierra-client/api/graphql/gql'
import {
  CheckInMutationVariables,
  EnrollInEventForCheckinMutationVariables,
  GetEventForCheckinQuery,
} from 'sierra-client/api/graphql/gql/graphql'
import { graphQuery, useGraphQuery } from 'sierra-client/api/hooks/use-graphql-query'
import { SanaLogo } from 'sierra-client/components/common/logos/sana-logo'
import { config } from 'sierra-client/config/global-config'
import { Logging } from 'sierra-client/core/logging'
import { useTranslation } from 'sierra-client/hooks/use-translation'
import { useDispatch } from 'sierra-client/state/hooks'
import { CalendarEventId, EventGroupId } from 'sierra-domain/api/nano-id'
import { isNonNullable } from 'sierra-domain/utils'
import { Icon } from 'sierra-ui/components'
import { Button, Heading, LoadingSpinner, Text, View } from 'sierra-ui/primitives'
import { token } from 'sierra-ui/theming'
import styled from 'styled-components'

const StyledSanaLogo = styled(SanaLogo)`
  & svg {
    transition: color 100ms ease;
    color: ${token('foreground/primary')};
  }
`

const checkInMutation = graphql(`
  mutation checkIn($input: SelfReportAttendanceInput!) {
    selfReportAttendance(input: $input) {
      __typename
      hasSelfReportedAttendance
    }
  }
`)

const selfEnrollMutation = graphql(`
  mutation enrollInEventForCheckin($input: EnrollInEventInput!) {
    selfEnrollToEvent(input: $input) {
      __typename
      userAssignment {
        __typename
      }
    }
  }
`)

const getEvent = graphql(`
  query getEventForCheckin($id: CalendarEventId!) {
    calendarEvent(id: $id) {
      selfReportAttendance
      title
      seatsRemaining
      userAssignment {
        attended
        hasSelfReportedAttendance
        __typename
      }
      eventGroup {
        selfEnrollmentEnabled
      }
    }
  }
`)

const PageContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  height: 100dvh;
  background: ${token('surface/default')};
`

const BrandImage = styled.img`
  width: 60px;
`

const CheckMark = styled(Icon).attrs({ iconId: 'checkmark', size: 'size-24', color: 'success/foreground' })`
  width: 48px;
  height: 48px;
  background: ${token('success/background')};
  border-radius: 50%;
`
const CenteredHeading = styled(Heading)`
  text-align: center;
`

const PageInner = ({
  event,
  calendarEventId,
  refetch,
}: {
  event: NonNullable<GetEventForCheckinQuery['calendarEvent']>
  eventGroupId: EventGroupId
  calendarEventId: CalendarEventId
  refetch: () => void
}): JSX.Element => {
  const isAssigned = isNonNullable(event.userAssignment)
  const hasCheckedIn = event.userAssignment?.hasSelfReportedAttendance === true
  const selfEnrollEnabled = event.eventGroup.selfEnrollmentEnabled
  const selfCheckInEnabled = event.selfReportAttendance === true
  const eventTitle = event.title
  const seatsRemaining = event.seatsRemaining
  const { t } = useTranslation()
  const dispatch = useDispatch()

  const {
    mutate: doCheckInMutation,
    error,
    isPending: checkinLoading,
  } = useMutation({
    mutationFn: (input: CheckInMutationVariables) => graphQuery(checkInMutation, input),
    onSuccess: () => {
      refetch()
      void dispatch(Logging.eventGroup.calendarEventUserCheckedIn({ calendarEventId: calendarEventId }))
    },
  })

  const enroll = useMutation({
    mutationFn: (input: EnrollInEventForCheckinMutationVariables) => graphQuery(selfEnrollMutation, input),
  })

  const enrollAndCheckIn = useCallback(() => {
    enroll.mutate(
      {
        input: {
          calendarEventId,
        },
      },
      {
        onSuccess: () => doCheckInMutation({ input: { calendarEventId } }),
      }
    )
  }, [calendarEventId, doCheckInMutation, enroll])

  useEffect(() => {
    if (isAssigned && !hasCheckedIn && selfCheckInEnabled) {
      doCheckInMutation({
        input: {
          calendarEventId,
        },
      })
    }
  }, [calendarEventId, doCheckInMutation, hasCheckedIn, isAssigned, selfCheckInEnabled])

  const hasError = isNonNullable(error)

  const orgConfig = config.organization

  const brandLogo =
    orgConfig.settings.brand.logo === null
      ? undefined
      : getNamespacedImage({ type: 'organization-settings' }, orgConfig.settings.brand.logo, 'image', {
          width: 80,
        })

  const canSelfEnroll = selfEnrollEnabled && seatsRemaining !== 0
  const notEnrolledText = useMemo((): string | undefined => {
    if (!selfEnrollEnabled) {
      return t('check-in-page.self-enrollment-disabled')
    }

    if (isNonNullable(seatsRemaining) && seatsRemaining > 0) {
      return t('course-detail.free-spots', { count: seatsRemaining })
    } else if (isNonNullable(seatsRemaining) && seatsRemaining === 0) {
      return t('course-detail.session-full')
    }
  }, [selfEnrollEnabled, seatsRemaining, t])

  if (hasError) {
    return <PageContainer>{<Text>{JSON.stringify(error)}</Text>}</PageContainer>
  }

  return (
    <PageContainer>
      <View gap='12' direction='column' justifyContent='center' alignItems='center' padding='none 24'>
        {hasCheckedIn && (
          <>
            <View marginBottom='24'>
              <CheckMark />
            </View>
            <CenteredHeading size='h2'>{t('check-in-page.check-in-done-title')}</CenteredHeading>
            <Text align='center'>{t('check-in-page.check-in-done-text', { eventTitle })}</Text>
          </>
        )}
        {!hasCheckedIn && (
          <>
            <View marginBottom='24'>
              {brandLogo === undefined ? <StyledSanaLogo /> : <BrandImage src={brandLogo} />}
            </View>
            <CenteredHeading size='h2'>{t('check-in-page.check-in-title')}</CenteredHeading>
            <CenteredHeading size='h2'>{`"${eventTitle}"`}</CenteredHeading>
            {!isAssigned && (
              <>
                <Text color='foreground/muted'>{t('check-in-page.not-enrolled-in-event')}</Text>
                {isNonNullable(notEnrolledText) && <Text color='foreground/muted'>{notEnrolledText}</Text>}
                <Button disabled={!canSelfEnroll} variant='secondary' onClick={enrollAndCheckIn}>
                  {t('check-in-page.enroll-and-check-in-button')}
                </Button>
              </>
            )}

            {checkinLoading && (
              <>
                <LoadingSpinner />
                <Text>{t('check-in-page.check-in-loading')}</Text>
              </>
            )}
          </>
        )}
      </View>
    </PageContainer>
  )
}

export const SelfReportAttendancePage: FC<{
  eventGroupId: EventGroupId
  calendarEventId: CalendarEventId
}> = ({ calendarEventId, eventGroupId }) => {
  const queryResult = useGraphQuery(
    {
      document: getEvent,
      queryOptions: {
        gcTime: 0,
        refetchOnWindowFocus: false,
      },
    },
    {
      id: calendarEventId,
    }
  )

  const event = queryResult.data?.calendarEvent
  if (isNonNullable(event)) {
    return (
      <PageInner
        calendarEventId={calendarEventId}
        eventGroupId={eventGroupId}
        refetch={queryResult.refetch}
        event={event}
      />
    )
  }
}
