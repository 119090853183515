import { useTranslation } from 'sierra-client/hooks/use-translation'
import { EndOfCourseRecommendations } from 'sierra-client/views/course-helper/content/course-helper-recommendations/end-of-course-recommendations'
import { HelperContent } from 'sierra-client/views/course-helper/shared/shared'
import { Text, View } from 'sierra-ui/primitives'
import styled from 'styled-components'

const MaxWidth = styled(View).attrs({
  direction: 'column',
  gap: '2',
})`
  max-width: 344px;
`

export const ProgramPending: React.FC = () => {
  const { t } = useTranslation()

  const headline = (
    <MaxWidth marginBottom='6'>
      <Text color='foreground/secondary' bold>
        {t('helper.next-step-pending')}
      </Text>
      <Text color='foreground/muted' size='small'>
        {t('helper.next-step-pending-info')}
      </Text>
    </MaxWidth>
  )

  return (
    <HelperContent title={t('helper.program-pending')} icon='racing--flag'>
      <EndOfCourseRecommendations headline={headline} />
    </HelperContent>
  )
}
