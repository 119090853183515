import { KnownViewRefs, MeasureRef, ViewRef, areMeasureRefsEqual } from 'sierra-domain/api/insights'

const measureViewRefTuples = [
  [MeasureRef.parse({ type: 'measure.native.user.count' }), { type: 'view.native.users' }],
  [
    MeasureRef.parse({ type: 'measure.native.user-course-feedback.count' }),
    { type: 'view.native.user-course-feedback' },
  ],
  [
    MeasureRef.parse({ type: 'measure.native.user-question.attempt-count' }),
    { type: 'view.native.user-question' },
  ],
  [MeasureRef.parse({ type: 'measure.native.user-poll.vote-count' }), { type: 'view.native.user-poll' }],
  [
    MeasureRef.parse({ type: 'measure.native.user-homework-submissions.count' }),
    { type: 'view.native.user-homework-submissions' },
  ],
  // [
  //   MeasureRef.parse({ type: 'measure.native.user-reflection.response-count' }),
  //   ViewRef.parse({ type: 'view.native.user-reflection-responses' }),
  // ],
] as const satisfies [MeasureRef, KnownViewRefs][]

export const getViewRefForMeasureRef = (measureRef: MeasureRef | undefined): ViewRef | undefined => {
  if (!measureRef) return undefined

  for (const [ref, viewRef] of measureViewRefTuples) {
    if (areMeasureRefsEqual(measureRef, ref)) {
      return ViewRef.parse(viewRef)
    }
  }

  return undefined
}
