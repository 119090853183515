import { useCallback, useMemo } from 'react'
import { useNonExpiringNotif, useNotif } from 'sierra-client/components/common/notifications'
import { useTranslation } from 'sierra-client/hooks/use-translation'
import {
  convertGeneratedQuestionToCard,
  generateQuestionFlexibleContent,
  getAllNodeIds,
} from 'sierra-client/views/v3-author/slash-menu/content-generation'
import { GeneratedQuestion } from 'sierra-domain/api/author-v2'
import { CreateContentId } from 'sierra-domain/api/nano-id'
import { Entity } from 'sierra-domain/entity'
import { NodeId } from 'sierra-domain/flexible-content/identifiers'
import { QuestionCard } from 'sierra-domain/v3-author'
import * as Y from 'yjs'

export function useGenerateQuestionCard({
  selection = 'single',
  yDoc,
  currentNodeId: nodeId,
  createContentId,
  afterSlateBlocks,
}: {
  selection?: 'single' | 'multiple'
  yDoc: Y.Doc
  createContentId: CreateContentId
  currentNodeId: NodeId | undefined
  afterSlateBlocks?: string[]
}): { generateQuestionCard: () => Promise<Entity<QuestionCard> | undefined> } {
  const notification = useNotif()
  const persistentNotification = useNonExpiringNotif()
  const { t } = useTranslation()

  const generateQuestionCard = useCallback(async (): Promise<Entity<QuestionCard> | undefined> => {
    const nodeIds = getAllNodeIds(yDoc)
    if (nodeIds === undefined) throw new Error('Unable to generate a question in a course without nodeIds')
    if (nodeId === undefined)
      throw new Error('Unable to generate a question. The current node is not defined')

    const previousNodes = nodeIds.includes(nodeId) ? nodeIds.slice(0, nodeIds.indexOf(nodeId) + 1) : nodeIds

    const loadingId = persistentNotification.push({
      type: 'custom',
      title: t('author.slate.generating'),
      body: '',
      level: 'info',
    })
    let question: GeneratedQuestion | undefined = undefined
    try {
      question = await generateQuestionFlexibleContent(
        createContentId,
        previousNodes,
        afterSlateBlocks ?? [],
        notification,
        selection
      )
    } finally {
      persistentNotification.remove(loadingId)
    }

    if (question === undefined) return undefined
    else return convertGeneratedQuestionToCard(question)
  }, [createContentId, nodeId, notification, persistentNotification, selection, t, yDoc, afterSlateBlocks])

  return useMemo(() => ({ generateQuestionCard }), [generateQuestionCard])
}
