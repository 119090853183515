import type { SkillId } from 'sierra-client/api/graphql/branded-types'
import { graphql } from 'sierra-client/api/graphql/gql'
import { useGraphQuery, useInvalidateGraphQuery } from 'sierra-client/api/hooks/use-graphql-query'
import {
  ContentCardContent,
  LockedCardContent,
  RestrictedCardContent,
} from 'sierra-client/components/content-card/content-card'
import { TopLearners } from 'sierra-client/features/skills/components/learner-board'
import { type SkillDetailEntity } from 'sierra-client/features/skills/components/skill-detail/types'
import { useSkillDebugControl } from 'sierra-client/features/skills/debug'
import { toLearnerSkillLevelEntities } from 'sierra-client/features/skills/helpers'
import { skillLeaderboardSettingsQuery } from 'sierra-client/features/skills/shared-gql-queries'
import { useTranslation } from 'sierra-client/hooks/use-translation'
import { isDefined, isEmptyArray, isNotDefined } from 'sierra-domain/utils'
import { Icon, Tooltip } from 'sierra-ui/components'
import { ContentCard as ContentCardUI } from 'sierra-ui/missions/foundation'
import { Skeleton, Text, View } from 'sierra-ui/primitives'
import { minWidth } from 'sierra-ui/utils/media-query-styles'
import styled from 'styled-components'
import { EmptyTableWrapper } from '../tabular/styles'

const SkillLevelHeader = styled.div`
  width: 100%;
  margin-bottom: 24px;
  max-width: 560px;
  text-wrap: balance;
`

const GridAbout = styled(View)`
  width: 100%;
  text-wrap: balance;
  max-width: 640px;
`
const GridContent = styled(View)`
  width: 100%;
`
const GridStatistics = styled(View)`
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: flex-start;
  align-self: flex-start;
  gap: 32px;
  width: 100%;
`

const Grid = styled(View)<{ $showingLeaderboard: boolean }>`
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  row-gap: 40px;
  width: 100%;
  height: 100%;

  ${minWidth.desktop} {
    display: grid;
    grid-template-columns: repeat(24, 1fr);
    ${GridAbout} {
      grid-column: 1 / span 13;
      grid-row: 1;
    }
    ${GridContent} {
      grid-column: 1 / span 17;
      grid-row: 2;
    }
    ${GridStatistics} {
      grid-column: 19 / span 6;
      grid-row: 1 / span 2;
    }

    ${props =>
      !props.$showingLeaderboard &&
      `
      ${GridAbout} {
        grid-column: 1 / span 24;
      }
      ${GridContent} {
        grid-column: 1 / span 24;
      }
    `}
  }
`

const CardGrid = styled.ul<{ $showingLeaderboard: boolean }>`
  width: 100%;
  display: grid;
  gap: 16px;

  ${minWidth.desktop_small} {
    grid-template-columns: repeat(3, 1fr);
  }

  @media (min-width: 1600px) {
    grid-template-columns: ${props => (props.$showingLeaderboard ? 'repeat(4, 1fr)' : 'repeat(5, 1fr)')};
  }
`

const SkillDetailGridItem: React.FC<{ skillDetail: SkillDetailEntity; showingLeaderboard: boolean }> = ({
  skillDetail,
  showingLeaderboard,
}) => {
  const { t } = useTranslation()

  return (
    <View key={skillDetail.skillLevel.id} direction='column' gap='16'>
      <SkillLevelHeader>
        <View gap='8'>
          <Text as='h3' bold size='regular'>
            {skillDetail.skillLevel.name}
          </Text>
          <Text as='span' color='foreground/muted' size='regular'>
            {t('skills.level-placeholder', { level: skillDetail.skillLevel.index })}
          </Text>
          {skillDetail.skillLevel.isLocked && (
            <Icon
              iconId='locked'
              color='foreground/muted'
              tooltip={t('skills.trophy.sequentially-unlocked-content.finish-previous.text')}
            />
          )}
        </View>

        {skillDetail.skillLevel.description !== undefined && (
          <Text color='foreground/secondary'>{skillDetail.skillLevel.description}</Text>
        )}
      </SkillLevelHeader>
      <CardGrid $showingLeaderboard={showingLeaderboard}>
        {skillDetail.entities.map(entity => {
          if (entity.entityType === 'skill:restricted-content') {
            return (
              <RestrictedCardContent
                key={entity.id}
                withProgressLine={skillDetail.displayProgress}
                onCardClick={() => {
                  // TODO request access to the content flow
                }}
              />
            )
          } else if (skillDetail.skillLevel.isLocked) {
            return (
              <Tooltip
                key={entity.id}
                title={t('skills.trophy.sequentially-unlocked-content.finish-previous.text')}
                side='top'
                delayDuration={100}
              >
                <LockedCardContent entity={entity} withProgressLine={skillDetail.displayProgress} />
              </Tooltip>
            )
          }
          return (
            <ContentCardContent
              key={entity.id}
              entity={entity}
              withProgressLine={skillDetail.displayProgress}
            />
          )
        })}
      </CardGrid>
    </View>
  )
}

const SkillDetailGrid: React.FC<{ entities?: SkillDetailEntity[]; showingLeaderboard: boolean }> = ({
  entities,
  showingLeaderboard,
}) => {
  const debugControl = useSkillDebugControl()
  const { t } = useTranslation()

  if (debugControl.loading || entities === undefined) {
    return (
      <>
        <View direction='column' gap='24'>
          <Skeleton $width={250} $height={20} />

          <CardGrid $showingLeaderboard={showingLeaderboard}>
            <ContentCardUI blurred disabled title='' />
            <ContentCardUI blurred disabled title='' />
            <ContentCardUI blurred disabled title='' />
            <ContentCardUI blurred disabled title='' />
            {!showingLeaderboard && <ContentCardUI blurred disabled title='' />}
          </CardGrid>
        </View>
      </>
    )
  }

  if (isEmptyArray(entities)) {
    return (
      <EmptyTableWrapper>
        <Icon iconId='course' />
        <Text bold>{t('skills.content.empty.title')}</Text>
        <Text color='foreground/muted'>{t('skills.content.empty.subtitle')}</Text>
      </EmptyTableWrapper>
    )
  }

  return entities.map(entity => (
    <SkillDetailGridItem
      key={entity.skillLevel.id}
      skillDetail={entity}
      showingLeaderboard={showingLeaderboard}
    />
  ))
}

const getSkillOverviewQuery = graphql(`
  query GetSkillOverview($id: SkillId!) {
    skill(id: $id) {
      description
      sequentialUnlockingEnabled
      skillLevels {
        id
        description
      }
      myProgress {
        progress {
          achievedAt
          progress {
            ...Progress
          }
          skillLevel {
            ...ProgressSkillLevel
          }
        }
      }
    }
  }
`)

export const useInvalidateSkillDetailOverviewData = (): ReturnType<typeof useInvalidateGraphQuery> => {
  return useInvalidateGraphQuery(getSkillOverviewQuery)
}

export const SkillDetailOverview: React.FC<{ skillId: SkillId }> = ({ skillId }) => {
  const debugControl = useSkillDebugControl()

  const skillSettings = useGraphQuery({ document: skillLeaderboardSettingsQuery }, {})
  const query = useGraphQuery(
    {
      document: getSkillOverviewQuery,
      queryOptions: {
        select(data) {
          if (isDefined(data.skill)) {
            return {
              ...data.skill,
              description: data.skill.description,
              entities: toLearnerSkillLevelEntities(
                data.skill.myProgress,
                data.skill.sequentialUnlockingEnabled,
                data.skill.skillLevels
              ),
            }
          } else return undefined
        },
      },
    },
    { id: skillId }
  )

  const showLeaderboard = skillSettings.data?.skillSettings.showLeaderboard ?? false

  return (
    <Grid $showingLeaderboard={showLeaderboard}>
      <GridAbout direction='column'>
        {debugControl.loading || isNotDefined(query.data) ? (
          <View direction='column' gap='8'>
            <Skeleton $width='100%' $height={19} />
            <Skeleton $width='80%' $height={19} />
            <Skeleton $width='90%' $height={19} />
          </View>
        ) : (
          <Text size='regular'>{query.data.description}</Text>
        )}
      </GridAbout>

      <GridContent direction='column' gap='48'>
        <SkillDetailGrid entities={query.data?.entities} showingLeaderboard={showLeaderboard} />
      </GridContent>

      {Boolean(showLeaderboard) && (
        <GridStatistics>
          <TopLearners skillId={skillId} />
        </GridStatistics>
      )}
    </Grid>
  )
}
