import React from 'react'
import { TruncatedText } from 'sierra-ui/components'

export const EmptyCell: React.FC<{ hideDecorator?: boolean }> = ({ hideDecorator = false }) => {
  return (
    <TruncatedText lines={1} size='small' italic>
      {hideDecorator ? '' : '-'}
    </TruncatedText>
  )
}
