import { AnimatePresence, motion } from 'framer-motion'
import { useAtom, useAtomValue } from 'jotai'
import React, { FC, useCallback, useEffect, useMemo, useRef, useState } from 'react'
import { ApplicationNotifications } from 'sierra-client/components/common/notifications'
import { ShortcutMenu } from 'sierra-client/components/shortcut-menu/shortcut-menu'
import { config } from 'sierra-client/config/global-config'
import { RequireCoursePermission } from 'sierra-client/core/require-course-permission'
import { TrackVisitedContent } from 'sierra-client/editor/utils/recently-visited/track-visited-content'
import { StrategicErrorBoundary } from 'sierra-client/error/strategic-error-boundary'
import { GlobalNestedBottomComponent, GlobalNestedSidebar } from 'sierra-client/features/global-sidebar'
import { useRequiredRouterSelfPacedIds } from 'sierra-client/hooks/use-router-ids'
import { useStableFunction } from 'sierra-client/hooks/use-stable-function'
import { useToggle } from 'sierra-client/hooks/use-toggle'
import { useTranslation } from 'sierra-client/hooks/use-translation'
import { IntercomLauncherVisibility } from 'sierra-client/intercom/intercom-visibility'
import { PageIdentifier, SanaPage } from 'sierra-client/layout/sana-page'
import { accessDeniedRedirect, contentNotFoundRedirect } from 'sierra-client/router/navigation'
import { fetchCourseProgress } from 'sierra-client/state/card-progress/actions'
import {
  selectLastCompletedFileId,
  selectProgressDataAvailable,
} from 'sierra-client/state/card-progress/selectors'
import { selectFileIds, selectFlexibleContent } from 'sierra-client/state/flexible-content/selectors'
import { flexibleContentSlice } from 'sierra-client/state/flexible-content/slice'
import { useDispatch, useSelector } from 'sierra-client/state/hooks'
import { useResetSelfPacedSlice } from 'sierra-client/state/self-paced/hooks'
import { newPageScrollPageAtom } from 'sierra-client/state/settings'
import { atomWithStorage } from 'sierra-client/state/storage'
import { FCC } from 'sierra-client/types'
import { CourseHelper } from 'sierra-client/views/course-helper'
import {
  SelfPacedCardScrollProvider,
  useSelfPacedCardScroll,
} from 'sierra-client/views/course-helper/shared/self-paced-card-scroll-context'
import { useNarrationVideo } from 'sierra-client/views/flexible-content/ai-narrations/use-narration-video'
import { BlurButton, Loader } from 'sierra-client/views/flexible-content/card-narration/atoms'
import { GeneratingNarrationLayer } from 'sierra-client/views/flexible-content/card-narration/generating-narration-layer'
import { Layers } from 'sierra-client/views/flexible-content/card-narration/layers'
import { CreateCardErrorForLearner } from 'sierra-client/views/flexible-content/create-card-error'
import { PolarisCardTheme } from 'sierra-client/views/flexible-content/polaris-card-theme'
import { SetCardProgressProvider } from 'sierra-client/views/flexible-content/progress-tracking/set-progress-provider'
import {
  SelfPacedContentRecord,
  SelfPacedUniversalContext,
  useSelfPacedBigContextSafe,
} from 'sierra-client/views/flexible-content/self-paced-big-context'
import { Debug } from 'sierra-client/views/learner/components/debug'
import { SelfPacedCardRenderer } from 'sierra-client/views/self-paced/card-renderer'
import { SelfPacedCardCanvas } from 'sierra-client/views/self-paced/components/self-paced-card-canvas'
import { SelfPacedCardRendererContainer } from 'sierra-client/views/self-paced/components/self-paced-card-renderer-container'
import { useSelfPacedFiles, useSelfPacedFilesSafe } from 'sierra-client/views/self-paced/files-provider'
import { KeyboardNavigation } from 'sierra-client/views/self-paced/keyboard-navigation'
import { NextCardImagePreload } from 'sierra-client/views/self-paced/next-card-image-preload'
import { PathProgressSync } from 'sierra-client/views/self-paced/path-progress-sync'
import { PlacementTest } from 'sierra-client/views/self-paced/placement-test/modal'
import { ProgramProgressSync } from 'sierra-client/views/self-paced/program-progress-sync'
import { Review } from 'sierra-client/views/self-paced/review/modal'
import {
  SelfPacedGlobalBottomComponent,
  SelfPacedGlobalSidebar,
} from 'sierra-client/views/self-paced/self-paced-global-sidebar'
import { useSelfPacedCard } from 'sierra-client/views/self-paced/use-self-paced-card'
import { useNavigate } from 'sierra-client/views/self-paced/useNavigate'
import { isElementType } from 'sierra-client/views/v3-author/queries'
import { CourseId, CreateContentId, SelfPacedContentId } from 'sierra-domain/api/nano-id'
import { AssetContext } from 'sierra-domain/asset-context'
import { ScopedCreateContentId, ScopedSelfPacedContentId } from 'sierra-domain/collaboration/types'
import { Entity } from 'sierra-domain/entity'
import { FileId } from 'sierra-domain/flexible-content/identifiers'
import { SelfPacedFile } from 'sierra-domain/flexible-content/support'
import { File } from 'sierra-domain/flexible-content/types'
import { textInNodes } from 'sierra-domain/slate-util'
import { assertNever, iife } from 'sierra-domain/utils'
import { SanaEditor } from 'sierra-domain/v3-author'
import { ColumnContainer, Layout, StyledColumn, TruncatedText } from 'sierra-ui/components'
import { LoadingSpinner, View } from 'sierra-ui/primitives'
import { token } from 'sierra-ui/theming'
import { height_100dvh, useMediaQuery } from 'sierra-ui/utils'
import { Element } from 'slate'
import { ReactEditor } from 'slate-react'
import styled, { createGlobalStyle, css, useTheme } from 'styled-components'
import { z } from 'zod'

const LoadingContainer = styled(View)`
  width: 100vw;

  ${height_100dvh}
  & > * {
    margin: auto;
  }
`

const GlobalStyles = createGlobalStyle`
    #__next {
        /* This is mainly so we don't overflow on mobile when the sidebar is open. */
        position: relative;
        height: auto;
    }
`
const PageContainer = styled.div`
  flex: 1;
  display: flex;
  flex-direction: column;
  height: 100%;
`

const Video = styled.video`
  width: 100%;
  aspect-ratio: 1;
  object-fit: cover;
  border-radius: 24px;
`

export const PolarisOuterContainer = styled.div`
  display: flex;
  flex-direction: column;
  height: 100%;
`

const LiveColumnContainer = styled(ColumnContainer)`
  flex-wrap: wrap;
  overflow: hidden;
  position: relative;
`

const CardColumn = styled(StyledColumn)`
  box-shadow: 0px 4px 12px rgba(0, 0, 0, 0.04);
  flex-basis: 1px;
  will-change: transform;

  border: 1px solid ${token('border/default')};
`

const autoPlayAtom = atomWithStorage('narration-autoplay', true, z.boolean(), { getOnInit: true })

function useRunAfterTimeout(_body: () => void, delayMs: number): void {
  const body = useStableFunction(_body)
  useEffect(() => {
    const timeout = setTimeout(() => {
      body()
    }, delayMs)

    return () => clearTimeout(timeout)
  }, [body, delayMs])
}

const CardNarration: FC<{
  currentFile: File
  contentId: CreateContentId
}> = ({ currentFile, contentId }) => {
  const fileTheme = useTheme()
  const videoRef = useRef<HTMLVideoElement>(null)
  const [autoPlaying, setAutoplaying] = useAtom(autoPlayAtom)
  const [playing, handlers] = useToggle()
  const [showVideo, setShowVideo] = useState(false)
  const supportsHover = useMediaQuery('(hover: hover)')

  const toggleVideoState = async (): Promise<void> => {
    if (videoRef.current === null) return

    if (videoRef.current.paused) {
      setAutoplaying(true)
      await videoRef.current.play()
    } else {
      setAutoplaying(false)
      void videoRef.current.pause()
    }
  }

  useEffect(() => {
    setShowVideo(false)
  }, [videoRef])

  useRunAfterTimeout(() => {
    if (videoRef.current === null) return
    if (!autoPlaying) return

    void videoRef.current.play()
  }, 1500)

  const { t } = useTranslation()
  const isHoverSupported = useMediaQuery('(hover: hover)')

  const videoState = useNarrationVideo(currentFile.narration, contentId)

  if (currentFile.narration === undefined) return null

  return (
    <React.Fragment key={currentFile.id}>
      {iife(() => {
        switch (videoState.type) {
          case 'success':
            return (
              <Layers.Container $position='absolute' larger={playing} shadow={playing}>
                <Layers.Layer>
                  <Video
                    ref={videoRef}
                    onClick={toggleVideoState}
                    disablePictureInPicture
                    onCanPlay={() => setShowVideo(true)}
                    onPlay={() => handlers.on()}
                    onPause={() => handlers.off()}
                    style={{
                      display: `${showVideo === true ? 'block' : 'none'}`,
                    }}
                    playsInline
                  >
                    <source src={videoState.videoUrl} type='video/mp4' />
                  </Video>
                </Layers.Layer>

                {!showVideo && (
                  <Layers.Layer $passive>
                    <Loader fileTheme={fileTheme} $loading />
                  </Layers.Layer>
                )}

                <Layers.Layer
                  $passive
                  withFade
                  data-hover={supportsHover}
                  padding='xxsmall'
                  alignItems='flex-end'
                  justifyContent='center'
                >
                  {!playing ? (
                    <BlurButton
                      iconId={'play--filled'}
                      onClick={toggleVideoState}
                      tooltip={t('dictionary.play')}
                      circular
                    />
                  ) : // We should only show an icon over the video while it is playing if the current
                  // device supports hovering. Otherwise the pause button will not be dissmisable on touch devices.
                  isHoverSupported ? (
                    <BlurButton
                      iconId={'pause--filled'}
                      onClick={toggleVideoState}
                      tooltip={t('dictionary.pause')}
                      circular
                    />
                  ) : null}
                </Layers.Layer>
              </Layers.Container>
            )
          case 'generating':
            return (
              <Layers.Container $position='absolute' larger={playing} shadow={playing}>
                <GeneratingNarrationLayer
                  thumbnailUrl={videoState.thumbnailUrl}
                  estimatedCompletedAt={videoState.estimatedCompletedAt}
                  createdAt={videoState.createdAt}
                  narrationId={videoState.narrationId}
                  contentId={contentId}
                  canEdit={false}
                />
              </Layers.Container>
            )
          case 'loading': {
            return (
              <Layers.Container $position='absolute' larger={playing} shadow={playing}>
                <Layers.Layer $passive>
                  <Loader fileTheme={fileTheme} $loading />
                </Layers.Layer>
              </Layers.Container>
            )
          }
          case 'no-narration':
          case 'error':
            return null
          default:
            assertNever(videoState)
        }
      })}
    </React.Fragment>
  )
}

const RemountWhenFileChanges: FCC = ({ children }) => {
  //For some reason questions break if we don't remount this when the file changes
  return <>{children}</>
}

const SelfPacedCardRendererMemo = React.memo(SelfPacedCardRenderer)

type Chunk = {
  id: string
  firstElement: Entity<Element>
  lastElement: Entity<Element>
}

const TITLE_LENGTH_LIMIT = 25
function getTextInNode(node: Entity<Element>): string {
  const allText = textInNodes(node.children).join(' ').trim()
  if (allText.length > TITLE_LENGTH_LIMIT) {
    return allText.substring(0, TITLE_LENGTH_LIMIT) + '...'
  }
  return allText
}

function isEmpty(node: Entity<Element>): boolean {
  return textInNodes(node.children).join('').trim().length === 0
}

function getChunks(editor: SanaEditor): Chunk[] {
  const chunks: Chunk[] = []
  let currentChunk: Chunk | undefined = undefined

  let state: 'start' | 'middle' = 'start'

  for (const node of editor.children) {
    if (!Element.isElement(node)) {
      continue
    }
    if (currentChunk === undefined) {
      currentChunk = {
        id: node.id,
        firstElement: node,
        lastElement: node,
      }
    }

    if (state === 'start') {
      if (!editor.isVoid(node) && (isElementType('heading', node) || isEmpty(node))) {
        currentChunk.lastElement = node
      } else {
        state = 'middle'
      }
    } else {
      if (isElementType('heading', node) && !isEmpty(node)) {
        state = 'start'
        chunks.push(currentChunk)
        currentChunk = {
          id: node.id,
          firstElement: node,
          lastElement: node,
        }
      } else {
        currentChunk.lastElement = node
      }
    }
  }

  if (currentChunk !== undefined && !chunks.includes(currentChunk)) {
    chunks.push(currentChunk)
  }

  return chunks
}

const Plutt = styled(motion.div)`
  width: 4px;
  background-color: ${token('foreground/primary').opacity(0.2)};
  height: 100%;
`

const SectionTitle = styled(TruncatedText)`
  color: ${token('foreground/muted')};
  cursor: pointer;
  transition: 200ms ease-out;
  :hover {
    transform: translateX(5px);
  }
`

const SectionLine = styled.div<{ $active: boolean; $borderRadius: 'top' | 'bottom' | undefined }>`
  display: flex;
  flex-direction: row;
  gap: 16px;
  height: 24px;
  align-items: center;
  justify-content: flex-end;
  ${p =>
    p.$active
      ? css`
          ${Plutt} {
            background-color: ${token('foreground/primary')};
            border-radius: 2px;
            height: 80%;
          }

          ${SectionTitle} {
            color: ${token('foreground/primary')};
          }
        `
      : undefined}

  &:first-child {
    ${Plutt} {
      border-top-right-radius: 2px;
      border-top-left-radius: 2px;
    }
  }

  &:last-child {
    ${Plutt} {
      border-bottom-left-radius: 2px;
      border-bottom-right-radius: 2px;
    }
  }

  ${Plutt} {
    ${p =>
      p.$borderRadius === 'top'
        ? `border-top-right-radius: 2px;
           border-top-left-radius: 2px;`
        : p.$borderRadius === 'bottom'
          ? `border-bottom-left-radius: 2px;
             border-bottom-right-radius: 2px;`
          : undefined}
  }
`

const PageCardOutlineWrapper = styled(motion.div)`
  position: absolute;
  right: 32px;
  top: 96px;
  margin: auto 0;
  display: flex;
  flex-direction: column;

  gap: 0px;
  &&&& {
    width: max-content;
    height: unset;
  }

  transition: transform 150ms ease-out;

  :hover {
    transform: scale(1.08);
  }
`

//transform: translateY(-50%) scale(1.08);

const PageCardOutline: FC<{
  editor: SanaEditor
}> = ({ editor }) => {
  const [chunkProgress, setChunkProgress] = useState<string | undefined>(undefined)
  const { scrollRef, recentScroll, scrollContainerWidth } = useSelfPacedCardScroll()

  const chunks = getChunks(editor)

  useEffect(() => {
    const test = (): void => {
      try {
        const scrollAreaBox = scrollRef.current?.getBoundingClientRect()
        if (scrollAreaBox === undefined) return

        let newProgress: string | undefined = undefined

        for (const chunk of chunks) {
          const firstElement = ReactEditor.toDOMNode(editor, chunk.firstElement)
          const lastElement = ReactEditor.toDOMNode(editor, chunk.lastElement)

          const firstElementRect = firstElement.getBoundingClientRect()
          const lastElementRect = lastElement.getBoundingClientRect()

          const progress =
            lastElementRect.bottom < scrollAreaBox.bottom
              ? 1
              : (scrollAreaBox.bottom - firstElementRect.top) /
                (lastElementRect.bottom - firstElementRect.top)

          if (firstElementRect.top > scrollAreaBox.bottom) {
            break
          } else if (progress === 1) {
            newProgress = chunk.id
          }
        }
        setChunkProgress(newProgress)
      } catch (error) {
        // ignore issues
      }
    }
    let cancelled = false
    requestAnimationFrame(function loop() {
      test()
      if (!cancelled) {
        requestAnimationFrame(loop)
      }
    })
    return () => {
      cancelled = true
    }
  }, [editor, chunks, scrollRef])

  const boxes = iife(() => {
    try {
      return chunks.map(chunk => {
        const firstElement = ReactEditor.toDOMNode(editor, chunk.firstElement)
        const lastElement = ReactEditor.toDOMNode(editor, chunk.lastElement)

        const firstElementRect = firstElement.getBoundingClientRect()
        const lastElementRect = lastElement.getBoundingClientRect()

        return {
          chunk: chunk,
          height: lastElementRect.bottom - firstElementRect.top,
        }
      })
    } catch (error) {
      return []
    }
  })

  const showSectionTitle = useMemo(
    () => scrollContainerWidth !== undefined && scrollContainerWidth > 1250,
    [scrollContainerWidth]
  )

  return (
    <PageCardOutlineWrapper animate={{ opacity: recentScroll ? 1 : 0 }} key={'page-card-outline-wrapper'}>
      {boxes.map((box, index) => {
        const nextBox = boxes[index + 1]
        const previousBox = boxes[index - 1]
        const isActive = chunkProgress === box.chunk.id

        const shouldHaveTopRadius = previousBox?.chunk.id === chunkProgress
        const shouldHaveBottomRadius = nextBox?.chunk.id === chunkProgress
        const borderRadius = shouldHaveTopRadius ? 'top' : shouldHaveBottomRadius ? 'bottom' : undefined

        return (
          <SectionLine $borderRadius={borderRadius} key={box.chunk.id} $active={isActive}>
            <AnimatePresence>
              {showSectionTitle && (
                <View
                  animated
                  initial={{ opacity: 0 }}
                  animate={{ opacity: 1 }}
                  exit={{ opacity: 0 }}
                  onClick={() => {
                    const element = ReactEditor.toDOMNode(editor, box.chunk.firstElement)
                    element.scrollIntoView({ behavior: 'smooth' })
                  }}
                >
                  <SectionTitle size='small' bold>
                    {getTextInNode(box.chunk.firstElement)}
                  </SectionTitle>
                </View>
              )}
            </AnimatePresence>
            <Plutt
              animate={isActive ? { x: 6 } : undefined}
              transition={{
                x: isActive
                  ? {
                      type: 'spring',
                      stiffness: 500,
                      damping: 20,
                    }
                  : { duration: 0.1 },
              }}
            />
          </SectionLine>
        )
      })}
    </PageCardOutlineWrapper>
  )
}

const CardColumnContentInner: React.FC<{
  currentFile: SelfPacedFile
  scopedCreateContentId: ScopedCreateContentId
  assetContext: AssetContext
}> = ({ currentFile, scopedCreateContentId, assetContext }): JSX.Element | null => {
  const { scrollRef } = useSelfPacedCardScroll()
  const card = useSelfPacedCard({ file: currentFile })
  const flexibleContentId = ScopedCreateContentId.extractId(scopedCreateContentId)

  const pageCardOutlineEnabled = useAtomValue(newPageScrollPageAtom)

  return (
    <PolarisCardTheme {...currentFile}>
      <StrategicErrorBoundary id='self-paced' Fallback={CreateCardErrorForLearner}>
        <SelfPacedCardCanvas card={currentFile} assetContext={assetContext}>
          <RemountWhenFileChanges key={currentFile.id}>
            <SetCardProgressProvider courseId={flexibleContentId} file={currentFile}>
              <SelfPacedCardRendererContainer ref={scrollRef}>
                <SelfPacedCardRendererMemo scopedCreateContentId={scopedCreateContentId} card={card} />
              </SelfPacedCardRendererContainer>
            </SetCardProgressProvider>
            <CardNarration currentFile={currentFile} contentId={flexibleContentId} />
            {card.file.data.type === 'general' && card.withEditor && pageCardOutlineEnabled && (
              <PageCardOutline editor={card.editor} />
            )}
          </RemountWhenFileChanges>
        </SelfPacedCardCanvas>
        <CourseHelper editor={card.withEditor ? card.editor : undefined} currentFile={currentFile} />
      </StrategicErrorBoundary>
    </PolarisCardTheme>
  )
}

const CardColumnContent: React.FC<{
  scopedCreateContentId: ScopedCreateContentId
}> = ({ scopedCreateContentId }): JSX.Element | null => {
  const { currentFile } = useSelfPacedFiles()
  const flexibleContentId = ScopedCreateContentId.extractId(scopedCreateContentId)
  const assetContext = useMemo(
    () => ({ type: 'course' as const, courseId: flexibleContentId }),
    [flexibleContentId]
  )

  if (currentFile === undefined) return null

  return (
    <CardColumnContentInner
      currentFile={currentFile}
      scopedCreateContentId={scopedCreateContentId}
      assetContext={assetContext}
    />
  )
}

const CenterCardColumn: React.FC<{ scopedCreateContentId: ScopedCreateContentId }> = ({
  scopedCreateContentId,
}) => {
  const [wiggle, setWiggle] = useState(false)

  const handleFailedNavigation = useCallback((): void => {
    setWiggle(true)
    setTimeout(() => setWiggle(false), 1000)
  }, [])

  return (
    <>
      <CardColumn
        layout
        animate={{ x: wiggle ? [0, -10, 10, -5, 2.5, 0] : 0 }}
        transition={{
          x: { type: 'spring', duration: 0.5 },
        }}
        $shy={false}
        $disableScrollbarGutter
        $borderRadius={'10px'}
      >
        <SelfPacedCardScrollProvider>
          <CardColumnContent scopedCreateContentId={scopedCreateContentId} />
        </SelfPacedCardScrollProvider>
      </CardColumn>
      <KeyboardNavigation onFailedNavigation={handleFailedNavigation} />
    </>
  )
}

const StyledColumnContainer = styled(LiveColumnContainer)`
  isolation: isolate;
`

const MainContent: React.FC<{ scopedCreateContentId: ScopedCreateContentId }> = ({
  scopedCreateContentId,
}) => {
  const { currentFile, rootFolder } = useSelfPacedFiles()

  if (!currentFile || !rootFolder) {
    return (
      <LoadingContainer>
        <View direction='column'>
          <LoadingSpinner />
          <Debug>[Debug] Resolving file</Debug>
        </View>
      </LoadingContainer>
    )
  }

  return (
    <PolarisOuterContainer>
      <Layout width='100%' height='100%'>
        <StyledColumnContainer gap='xxsmall' padding='xxsmall' paddingLeft='none'>
          <CenterCardColumn scopedCreateContentId={scopedCreateContentId} />
        </StyledColumnContainer>
      </Layout>
      <NextCardImagePreload />
    </PolarisOuterContainer>
  )
}

export const SelfPacedPageContent: React.FC<{
  context: SelfPacedContentRecord
  scopedCreateContentId: ScopedCreateContentId
  fileId: FileId | undefined
}> = ({ context, scopedCreateContentId, fileId }) => {
  const dispatch = useDispatch()
  const data = context.jsonData

  const fileContext = useSelfPacedFilesSafe()
  const createContentId = ScopedCreateContentId.extractId(scopedCreateContentId)
  useEffect(() => {
    void dispatch(flexibleContentSlice.actions.setData({ createContentId, data }))
  }, [dispatch, data, createContentId])

  const content = useSelector(state => selectFlexibleContent(state, createContentId))

  if (content === undefined || fileContext === undefined)
    return (
      <LoadingContainer>
        <View direction='column'>
          <LoadingSpinner />
          {fileContext === undefined && <Debug>[Debug] Loading file context</Debug>}
          {content === undefined && <Debug>[Debug] Loading redux content</Debug>}
        </View>
      </LoadingContainer>
    )

  return (
    <>
      <GlobalStyles />
      <TrackVisitedContent type='self-paced' scopedId={scopedCreateContentId} fileId={fileId} />
      <PageContainer>
        <MainContent scopedCreateContentId={scopedCreateContentId} />
        <Review />
        <PlacementTest />
      </PageContainer>
      <PathProgressSync createContentId={createContentId} />
      <ProgramProgressSync createContentId={createContentId} />
      <ApplicationNotifications />
    </>
  )
}

const Container = styled(View)`
  width: 100vw;

  ${height_100dvh}
  & > * {
    margin: auto;
  }
`

export const NextSelfPacedCard: FC<{ flexibleContentId: SelfPacedContentId }> = ({ flexibleContentId }) => {
  const navigate = useNavigate({ flexibleContentId })
  const fileIds = useSelector(state => selectFileIds(state, flexibleContentId))

  const lastCompletedFileId = useSelector(state => selectLastCompletedFileId(state, flexibleContentId))(
    fileIds
  )
  const nextFileId = lastCompletedFileId ?? fileIds[0]

  useEffect(() => {
    if (nextFileId !== undefined) {
      navigate(nextFileId, { replace: true })
    }
  }, [nextFileId, navigate])

  return null
}

const SelfPacedShortcuts: FC = () => {
  return (
    <ShortcutMenu.Action
      label='shortcut-menu.default.edit-in-create'
      group='navigation'
      run={() => {
        const { location } = window
        const url = new URL(`${location.origin}/create${location.pathname}`)

        const host = config.auth.host
        if (host !== undefined) url.searchParams.set('x-host', host)

        window.open(url, '_blank')
      }}
      iconId='play--circle--filled'
      permission='ACCESS_EDITOR'
    />
  )
}

const SelfPacedPageWithPermission: FC = () => {
  const { flexibleContentId, fileId } = useRequiredRouterSelfPacedIds()
  const scopedCreateContentId = ScopedSelfPacedContentId.fromId(flexibleContentId)
  const dispatch = useDispatch()
  const progressAvailable = useSelector(state => selectProgressDataAvailable(state, flexibleContentId))
  const context = useSelfPacedBigContextSafe()

  useResetSelfPacedSlice()

  const triggerFetchProgress = useStableFunction((courseId: CourseId) => {
    void dispatch(fetchCourseProgress({ courseId, fileId: fileId !== 'next' ? fileId : undefined }))
  })

  // Make sure to load initial progress data of a course
  useEffect(() => {
    triggerFetchProgress(flexibleContentId)
  }, [triggerFetchProgress, flexibleContentId])

  useEffect(() => {
    if (context === undefined) return

    const data = context.jsonData
    void dispatch(flexibleContentSlice.actions.setData({ createContentId: flexibleContentId, data }))
    triggerFetchProgress(flexibleContentId)
  }, [context, dispatch, flexibleContentId, triggerFetchProgress])

  if (context === undefined || !progressAvailable)
    return (
      <Container>
        <View direction='column'>
          <LoadingSpinner />
          <Debug>
            [Debug]
            {context === undefined && <p>Loading big context</p>}
            {!progressAvailable && <p>Loading Progress</p>}
          </Debug>
        </View>
      </Container>
    )

  return (
    <>
      <IntercomLauncherVisibility visibility={'hidden'} />

      {fileId === 'next' ? (
        <NextSelfPacedCard flexibleContentId={flexibleContentId} />
      ) : (
        <>
          <SelfPacedPageContent
            context={context}
            scopedCreateContentId={scopedCreateContentId}
            fileId={fileId}
          />
          <SelfPacedShortcuts />
        </>
      )}
    </>
  )
}

const StyledSanaPage = styled(SanaPage)`
  height: 100%;
`

const MemoizedSelfPacedPage = React.memo(
  ({ flexibleContentId }: { flexibleContentId: SelfPacedContentId }) => {
    return (
      <>
        <SelfPacedPageWithPermission />
        <GlobalNestedSidebar key={flexibleContentId} renderSidebar={() => <SelfPacedGlobalSidebar />} />
        <GlobalNestedBottomComponent renderBottomContent={() => <SelfPacedGlobalBottomComponent />} />
      </>
    )
  }
)

export const SelfPacedPage = (): JSX.Element => {
  const { flexibleContentId, fileId } = useRequiredRouterSelfPacedIds()
  return (
    <RequireCoursePermission
      contentId={flexibleContentId}
      minPermissionLevel='view'
      onAccessDenied={accessDeniedRedirect}
      onContentNotFound={contentNotFoundRedirect}
      showSpinner
    >
      <SelfPacedUniversalContext>
        <StyledSanaPage page={PageIdentifier.LearnerSelfPacedLesson({ courseId: flexibleContentId, fileId })}>
          <MemoizedSelfPacedPage flexibleContentId={flexibleContentId} />
        </StyledSanaPage>
      </SelfPacedUniversalContext>
    </RequireCoursePermission>
  )
}
