import React, { useCallback, useEffect, useMemo, useState } from 'react'
import { RadioButton } from 'sierra-client/components/common/inputs/radio'
import { usePost } from 'sierra-client/hooks/use-post'
import { useTranslation } from 'sierra-client/hooks/use-translation'
import { TranslationKey } from 'sierra-client/hooks/use-translation/types'
import { useCachedQuery } from 'sierra-client/state/api'
import { ContentOption, FilterInput } from 'sierra-client/views/manage/reports/components/filter-input'
import { AssignmentType, ContentType, OutputFormat } from 'sierra-domain/api/analytics-reporting'
import {
  XRealtimeAnalyticsReportsMetadataCustomUserAttributes,
  XRealtimeAnalyticsReportsMetadataListContent,
  XRealtimeAnalyticsReportsMetadataListGroups,
  XRealtimeAnalyticsReportsMetadataListPrograms,
} from 'sierra-domain/routes'
import { Checkbox, Spacer, Text, View } from 'sierra-ui/primitives'

type ContentIdsFilterProps = {
  selectedIds?: string[]
  updateSelectedIds: (contentIds: string[]) => void
  contentTypes: ContentType[]
  selectableIds?: string[]
}

export const ContentIdsFilter: React.FC<ContentIdsFilterProps> = ({
  selectedIds,
  updateSelectedIds,
  contentTypes,
  selectableIds,
}) => {
  const { t } = useTranslation()

  const contentQueryResult = useCachedQuery(XRealtimeAnalyticsReportsMetadataListContent, {
    contentTypes,
  })

  const contentData = useMemo<ContentOption[]>(() => {
    if (!contentQueryResult.isSuccess) return []

    return contentQueryResult.data.content.filter(c => {
      if (c.title === '') return false

      return selectableIds === undefined || selectableIds.includes(c.id)
    })
  }, [contentQueryResult, selectableIds])

  return (
    <>
      <Text color='LEGACY_DEFAULT_TEXT_COLOR_REPLACE_ASAP' size='small' bold>
        {t('manage.reports.filter.content.title')}
      </Text>
      <Spacer size='xxsmall' />
      <FilterInput
        contentOptions={contentData}
        currentContent={selectedIds ?? []}
        onContentChange={updateSelectedIds}
        inputPlaceholder={t('manage.reports.filter.content.input-placeholder')}
      />
      <Spacer size='medium' />
    </>
  )
}

type GroupsFilterProps = {
  selectedIds?: string[]
  updateSelectedIds: (contentIds: string[]) => void
}

export const GroupsFilter: React.FC<GroupsFilterProps> = ({ selectedIds, updateSelectedIds }) => {
  const { t } = useTranslation()
  const { postWithUserErrorException } = usePost()
  const [groupsData, setGroupsData] = useState<ContentOption[]>([])

  const fetchGroups = useCallback(async (): Promise<void> => {
    const response = await postWithUserErrorException(XRealtimeAnalyticsReportsMetadataListGroups, {})
    const groups: ContentOption[] = response.groups
      .filter(group => group.name !== '')
      .map(group => ({
        id: group.id,
        title: group.name,
        type: group.type,
      }))
      .sort((a, b) => a.title.localeCompare(b.title))
    setGroupsData(groups)
  }, [postWithUserErrorException])

  useEffect(() => {
    void fetchGroups()
  }, [fetchGroups])

  return (
    <>
      <Text color='LEGACY_DEFAULT_TEXT_COLOR_REPLACE_ASAP' size='small' bold>
        {t('manage.reports.filter.groups.title')}
      </Text>
      <Spacer size='xxsmall' />
      <FilterInput
        contentOptions={groupsData}
        currentContent={selectedIds ?? []}
        onContentChange={updateSelectedIds}
        inputPlaceholder={t('manage.reports.filter.groups.input-placeholder')}
      />
      <Spacer size='medium' />
    </>
  )
}

type ProgramsFilterProps = {
  selectedIds?: string[]
  updateSelectedIds: (programIds: string[]) => void
}

export const ProgramsFilter: React.FC<ProgramsFilterProps> = ({ selectedIds, updateSelectedIds }) => {
  const { t } = useTranslation()
  const { postWithUserErrorException } = usePost()
  const [programsData, setProgramsData] = useState<ContentOption[]>([])

  const fetchPrograms = useCallback(async (): Promise<void> => {
    const response = await postWithUserErrorException(XRealtimeAnalyticsReportsMetadataListPrograms, {})
    const programs: ContentOption[] = response.programs
      .filter(program => program.name !== '')
      .map(program => ({
        id: program.id,
        title: program.name,
        type: 'program',
      }))
      .sort((a, b) => a.title.localeCompare(b.title))
    setProgramsData(programs)
  }, [postWithUserErrorException])

  useEffect(() => {
    void fetchPrograms()
  }, [fetchPrograms])

  return (
    <>
      <Text color='LEGACY_DEFAULT_TEXT_COLOR_REPLACE_ASAP' size='small' bold>
        {t('manage.reports.filter.programs.title')}
      </Text>
      <Spacer size='xxsmall' />
      <FilterInput
        contentOptions={programsData}
        currentContent={selectedIds ?? []}
        onContentChange={updateSelectedIds}
        inputPlaceholder={t('manage.reports.filter.programs.input-placeholder')}
      />
      <Spacer size='medium' />
    </>
  )
}

type ContentTypesFilterProps = {
  allowedTypes: ContentType[]
  selectedContentTypes: ContentType[]
  updateContentTypes: (contentTypes: ContentType[]) => void
}

export const ContentTypesFilter: React.FC<ContentTypesFilterProps> = ({
  allowedTypes,
  selectedContentTypes,
  updateContentTypes,
}) => {
  const { t } = useTranslation()

  const handleContentTypeToggle = (contentType: ContentType): void => {
    const shouldRemove = selectedContentTypes.includes(contentType)

    if (shouldRemove) updateContentTypes(selectedContentTypes.filter(t => t !== contentType))
    else updateContentTypes(selectedContentTypes.concat(contentType))
  }

  const getContentTypeTranslationString = (type: ContentType): string => {
    switch (type) {
      case 'course':
        return t('dictionary.course-plural')
      case 'live':
        return t('dictionary.live')
      case 'event-group':
        return t('dictionary.in-person')
      case 'external':
        return t('dictionary.external')
      case 'path':
        return t('dictionary.path-plural')
    }
  }

  return (
    <>
      <Text color='LEGACY_DEFAULT_TEXT_COLOR_REPLACE_ASAP' size='small' bold>
        {t('manage.reports.filter.content-types.title')}
      </Text>
      <Spacer size='xxsmall' />
      <View direction='column'>
        {allowedTypes.map(type => (
          <View key={type} gap='none'>
            <Checkbox
              id={`content-type-${type}`}
              checked={selectedContentTypes.includes(type)}
              onCheckedChange={() => handleContentTypeToggle(type)}
            />
            <Spacer size='xxsmall' />
            <Text
              color='LEGACY_DEFAULT_TEXT_COLOR_REPLACE_ASAP'
              size='small'
              as='label'
              htmlFor={`content-type-${type}`}
            >
              {getContentTypeTranslationString(type)}
            </Text>
          </View>
        ))}
      </View>
      <Spacer size='medium' />
    </>
  )
}

type AssignmentTypeFilterProps = {
  selectedType?: AssignmentType
  updateAssignmentType: (assignmentType: AssignmentType) => void
}

export const AssignmentTypeFilter: React.FC<AssignmentTypeFilterProps> = ({
  selectedType,
  updateAssignmentType,
}) => {
  const { t } = useTranslation()

  return (
    <>
      <Text color='LEGACY_DEFAULT_TEXT_COLOR_REPLACE_ASAP' size='small' bold>
        {t('manage.reports.filter.assignment-type.title')}
      </Text>
      <Spacer size='xxsmall' />
      <View direction='column' gap='4'>
        <RadioButton
          size='small'
          name='assignment-type'
          label={t('admin.organization.users.tabs.all')}
          value='all'
          checked={selectedType === 'all'}
          onChange={() => updateAssignmentType('all')}
        />
        <RadioButton
          size='small'
          name='assignment-type'
          label={t('table.assigned')}
          value='assigned'
          checked={selectedType === 'assigned'}
          onChange={() => updateAssignmentType('assigned')}
        />
      </View>
      <Spacer size='medium' />
    </>
  )
}

type CustomUserAttributesFilterProps = {
  allowedAttributes: string[]
  selectedAttributes: string[]
  updateAttributes: (attributes: string[]) => void
}

export const useCustomUserAttributes: () => string[] = () => {
  const { postWithUserErrorException } = usePost()
  const [customUserAttributes, setCustomUserAttributes] = useState<string[]>([])
  const fetchAttributes = useCallback(async (): Promise<void> => {
    const response = await postWithUserErrorException(
      XRealtimeAnalyticsReportsMetadataCustomUserAttributes,
      {}
    )

    setCustomUserAttributes(response.attributes)
  }, [postWithUserErrorException])

  useEffect(() => {
    void fetchAttributes()
  }, [fetchAttributes])

  return customUserAttributes
}

export const CustomUserAttributesFilter: React.FC<CustomUserAttributesFilterProps> = ({
  allowedAttributes,
  selectedAttributes,
  updateAttributes,
}) => {
  const { t } = useTranslation()

  const handleAttributeToggle = (attribute: string): void => {
    const shouldRemove = selectedAttributes.includes(attribute)

    if (shouldRemove) updateAttributes(selectedAttributes.filter(t => t !== attribute))
    else updateAttributes(selectedAttributes.concat(attribute))
  }

  return (
    <>
      <Text color='LEGACY_DEFAULT_TEXT_COLOR_REPLACE_ASAP' size='small' bold>
        {t('manage.reports.filter.custom-user-attribute.title')}
      </Text>
      <Spacer size='xxsmall' />
      <View direction='column' gap='4'>
        {allowedAttributes.map(attribute => (
          <View key={attribute} gap='none'>
            <Checkbox
              id={`custom-user-attribute-${attribute}`}
              checked={selectedAttributes.includes(attribute)}
              onCheckedChange={() => handleAttributeToggle(attribute)}
            />
            <Spacer size='xxsmall' />
            <Text
              color='LEGACY_DEFAULT_TEXT_COLOR_REPLACE_ASAP'
              size='small'
              as='label'
              htmlFor={`custom-user-attribute-${attribute}`}
            >
              {attribute}
            </Text>
          </View>
        ))}
      </View>
      <Spacer size='medium' />
    </>
  )
}

type OutputFormatFilterProps = {
  selectedFormat?: OutputFormat
  allowedFormats: OutputFormat[]
  updateFormat: (format: OutputFormat) => void
}

const getFormatTranslationKey: (format: OutputFormat) => TranslationKey = format => {
  switch (format) {
    case 'csv':
      return 'format.csv'
    case 'xlsx':
      return 'format.xlsx'
  }
}

export const OutputFormatFilter: React.FC<OutputFormatFilterProps> = ({
  selectedFormat,
  allowedFormats,
  updateFormat,
}) => {
  const { t } = useTranslation()

  return (
    <>
      <Text color='LEGACY_DEFAULT_TEXT_COLOR_REPLACE_ASAP' size='small' bold>
        {t('manage.reports.filter.output-format.title')}
      </Text>
      <Spacer size='xxsmall' />
      <View direction='column' gap='4'>
        {allowedFormats.map(format => (
          <RadioButton
            size='small'
            key={format}
            name='output-format'
            label={t(getFormatTranslationKey(format))}
            value={format}
            checked={selectedFormat === format}
            onChange={() => updateFormat(format)}
          />
        ))}
      </View>
      <Spacer size='medium' />
    </>
  )
}
