import { Variants } from 'framer-motion'
import { useAtomValue } from 'jotai'
import React, { useState } from 'react'
import { Link } from 'sierra-client/components/common/link'
import { dashboardsMetaAtom } from 'sierra-client/features/insights/atoms'
import { DashboardSharingInfo } from 'sierra-client/features/insights/home/dashboard-sharing-info'
import { useTranslation } from 'sierra-client/hooks/use-translation'
import { InsightsDashboardTemplate, ListedUserFilterDashboard } from 'sierra-domain/api/insights'
import { Icon, IconId, TruncatedText, TruncatedTextWithTooltip } from 'sierra-ui/components'
import { Button, View } from 'sierra-ui/primitives'
import { token } from 'sierra-ui/theming'
import { minWidth } from 'sierra-ui/utils/media-query-styles'
import styled from 'styled-components'

const variants = {
  hidden: { opacity: 0, y: 20 },
  visible: {
    opacity: 1,
    y: 0,
    transition: { type: 'spring', bounce: 0, duration: 0.5, staggerChildren: 0.05 },
  },
} as const satisfies Variants

const IconWrapperView = styled(View).attrs({
  radius: 'round',
  padding: '16',
})<{ isTemplate?: boolean }>`
  background-color: ${p =>
    p.isTemplate === true ? token('org/primary').opacity(0.08) : token('surface/strong')};
`

const INITIAL_SHOW_ITEMS = 10 as const
const SHOW_ITEMS_INCREMENT = 10 as const

const DashboardList: React.FC<{
  dashboards: ListedUserFilterDashboard[]
  onCreateNewDashboard?: () => Promise<void>
}> = ({ dashboards }) => {
  const dashboardsMeta = useAtomValue(dashboardsMetaAtom)
  const { t } = useTranslation()

  const [showItems, setShowItems] = useState<number>(INITIAL_SHOW_ITEMS)

  return (
    <View
      direction='column'
      grow
      gap='24'
      animated
      initial='hidden'
      animate='visible'
      variants={variants}
      layout
    >
      {dashboards.slice(0, showItems).map(dashboard => {
        return (
          <View key={dashboard.dashboard.id} grow animated variants={variants} gap='16' layout>
            <IconWrapperView>
              <Icon iconId='grid' size='size-16' />
            </IconWrapperView>
            <Link href={`/manage/insights/dashboards/${dashboard.dashboard.id}`} next>
              <View direction='column' gap='4'>
                <View>
                  <TruncatedText lines={1} bold>
                    {dashboard.dashboard.title}
                  </TruncatedText>
                </View>
                <View gap='4'>
                  <DashboardSharingInfo dashboard={dashboard} meta={dashboardsMeta[dashboard.dashboard.id]} />
                </View>
              </View>
            </Link>
          </View>
        )
      })}
      {dashboards.length > showItems && (
        <View grow animated variants={variants}>
          <Button
            variant='ghost'
            grow
            icon='add'
            onClick={() => setShowItems(current => current + SHOW_ITEMS_INCREMENT)}
          >
            {t('dictionary.view-more')}
          </Button>
        </View>
      )}
    </View>
  )
}

const EmptyMyDashboardsList: React.FC<{ onCreateNewDashboard?: () => Promise<void> }> = ({
  onCreateNewDashboard,
}) => {
  const { t } = useTranslation()

  return (
    <View
      direction='column'
      grow
      gap='24'
      animated
      initial='hidden'
      animate='visible'
      variants={{ ...variants, visible: { ...variants.visible, opacity: 0.6 } }}
      layout
    >
      <View grow animated variants={variants} gap='16' cursor='pointer' onClick={onCreateNewDashboard} layout>
        <IconWrapperView>
          <Icon iconId='add' size='size-16' />
        </IconWrapperView>
        <View direction='column' gap='4'>
          <View>
            <TruncatedText lines={1} bold>
              {t('manage.insights.home.my-dashboards-empty-header')}
            </TruncatedText>
          </View>
          <View gap='4'>
            <TruncatedTextWithTooltip color='foreground/secondary'>
              {t('manage.insights.home.my-dashboards-empty-description')}
            </TruncatedTextWithTooltip>
          </View>
        </View>
      </View>
    </View>
  )
}

export const MyDashboardsList: React.FC<{
  dashboards: ListedUserFilterDashboard[]
  onCreateNewDashboard?: () => Promise<void>
}> = ({ dashboards, onCreateNewDashboard }) => {
  if (dashboards.length === 0) {
    return <EmptyMyDashboardsList onCreateNewDashboard={onCreateNewDashboard} />
  } else {
    return <DashboardList dashboards={dashboards} />
  }
}

const EmptySharedDashboardsList: React.FC = () => {
  const { t } = useTranslation()

  return (
    <View
      direction='column'
      grow
      gap='24'
      animated
      initial='hidden'
      animate='visible'
      variants={{ ...variants, visible: { ...variants.visible, opacity: 0.6 } }}
      layout
    >
      <View grow animated variants={variants} gap='16' layout>
        <IconWrapperView>
          <Icon iconId='user' size='size-16' />
        </IconWrapperView>
        <View direction='column' gap='4'>
          <View>
            <TruncatedText lines={1} bold>
              {t('manage.insights.home.shared-dashboards-empty-header')}
            </TruncatedText>
          </View>
          <View gap='4'>
            <TruncatedTextWithTooltip color='foreground/secondary'>
              {t('manage.insights.home.shared-dashboards-empty-description')}
            </TruncatedTextWithTooltip>
          </View>
        </View>
      </View>
    </View>
  )
}

export const SharedDashboardsList: React.FC<{ dashboards: ListedUserFilterDashboard[] }> = ({
  dashboards,
}) => {
  if (dashboards.length === 0) {
    return <EmptySharedDashboardsList />
  } else {
    return <DashboardList dashboards={dashboards} />
  }
}

const getIconForTemplate = (templateId: InsightsDashboardTemplate['id']): IconId => {
  switch (templateId) {
    case 'user-activity':
      return 'user'
    case 'courses':
      return 'book'
    case 'skills':
      return 'skill'
    case 'exercises':
      return 'task'
    case 'assessments-and-questions':
      return 'assessment'
    case 'polls-and-reflections':
      return 'poll'
    default:
      return 'grid'
  }
}

const GrowLink = styled(Link)`
  flex-grow: 1;
`

const LeftView = styled(View)`
  min-width: 8rem;
  ${minWidth.tablet} {
    min-width: 10rem;
  }
  ${minWidth.desktop_small} {
    min-width: 12rem;
  }
`

const RightView = styled(View)`
  max-width: 40rem;
  width: 100%;
  margin: auto;
`

type TranslatedTemplate = Omit<InsightsDashboardTemplate, 'name' | 'description'> & {
  name: string
  description: string
}

export const TemplateList: React.FC<{
  templates: TranslatedTemplate[]
}> = ({ templates }) => {
  const { t } = useTranslation()

  return (
    <View
      direction='column'
      grow
      gap='24'
      animated
      initial='hidden'
      animate='visible'
      variants={variants}
      layout
    >
      {templates.map(template => (
        <View key={template.id} grow animated variants={variants} layout>
          <IconWrapperView isTemplate>
            <Icon iconId={getIconForTemplate(template.id)} size='size-16' />
          </IconWrapperView>
          <GrowLink href={`/manage/insights/dashboards/templates/${template.id}`} next>
            <View>
              <LeftView direction='column' gap='4'>
                <View>
                  <TruncatedText lines={1} bold>
                    {template.name}
                  </TruncatedText>
                </View>
                <View gap='4'>
                  <TruncatedTextWithTooltip color='foreground/secondary'>
                    {t('manage.insights.home.template-dashboards.view-only')}
                  </TruncatedTextWithTooltip>
                </View>
              </LeftView>
              <RightView>
                <TruncatedTextWithTooltip lines={2} color='foreground/secondary'>
                  {template.description}
                </TruncatedTextWithTooltip>
              </RightView>
            </View>
          </GrowLink>
        </View>
      ))}
    </View>
  )
}
