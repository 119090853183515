import React, { forwardRef, useCallback } from 'react'
import { useCurrentUserId } from 'sierra-client/api/hooks/use-user'
import { SyncClock } from 'sierra-client/collaboration/sync-clock'
import { useIsFacilitatorOrLearnerLedSession } from 'sierra-client/components/liveV2/hooks/use-is-facilitator-or-learner-led-session'
import { useTranslation } from 'sierra-client/hooks/use-translation'
import { selectCardIsCompleted } from 'sierra-client/state/card-progress/selectors'
import {
  getAssessmentPassingCriteria,
  getAssessmentTimeLimit,
} from 'sierra-client/state/flexible-content/selectors'
import { useDispatch, useSelector } from 'sierra-client/state/hooks'
import { getAssessmentQuestionCardResponses } from 'sierra-client/state/self-paced/actions'
import { useIsLiveCourseOrInLive } from 'sierra-client/views/flexible-content/create-page-context'
import { useFileContext, useSafeFileContext } from 'sierra-client/views/flexible-content/file-context'
import { AddQuestion } from 'sierra-client/views/v3-author/assessment-card/add-question'
import {
  AssessmentContainer,
  AssessmentHeadline,
  assessmentContainerRadius,
} from 'sierra-client/views/v3-author/assessment-card/assessment-container'
import {
  Assessments,
  evaluatePassingCriteria,
  useAssessmentContext,
  useAssessmentContextUnsafe,
} from 'sierra-client/views/v3-author/assessment-card/assessment-context'
import { AssessmentEnd } from 'sierra-client/views/v3-author/assessment-card/assessment-end'
import { IconLabel } from 'sierra-client/views/v3-author/assessment-card/icon-label'
import { useParent, usePath } from 'sierra-client/views/v3-author/hooks'
import { useLiveQuizContext } from 'sierra-client/views/v3-author/live-quiz/live-quiz-context'
import { LiveQuizAvatar } from 'sierra-client/views/v3-author/live-quiz/live-quiz-user/live-quiz-avatar'
import { LiveQuizUserSettingsModal } from 'sierra-client/views/v3-author/live-quiz/live-quiz-user/live-quiz-user-settings-modal'
import { assertElementType, isElementType } from 'sierra-client/views/v3-author/queries'
import { QuestionCardIconBar } from 'sierra-client/views/v3-author/question-card/question-card-icon-bar'
import { RenderingContext } from 'sierra-client/views/v3-author/rendering-context'
import { SlateFC, SlateWrapperProps } from 'sierra-client/views/v3-author/slate'
import { Entity } from 'sierra-domain/entity'
import { LiveQuizUser } from 'sierra-domain/live-session'
import { assertNever, iife } from 'sierra-domain/utils'
import { AssessmentCard } from 'sierra-domain/v3-author'
import { createAssessmentQuestion } from 'sierra-domain/v3-author/create-blocks'
import { Button, Text, View } from 'sierra-ui/primitives'
import { Editor, Path, Transforms } from 'slate'
import { useSlateStatic } from 'slate-react'
import styled from 'styled-components'

const Headline = styled(View)`
  padding: 0 24px;

  > * {
    text-align: center;
  }
`

const Legend = styled(View).attrs({ contentEditable: false, gap: 'small' })`
  color: ${p => p.theme.home.textColor};
  opacity: 70%;
`

const DisplayNone = styled.div`
  display: none;
`
const AssessmentIntroductionLearnerWrapper = React.forwardRef<HTMLDivElement, SlateWrapperProps>(
  (props, ref) => {
    const { children, element, attributes } = props

    assertElementType('assessment-introduction', element)

    const { state, status } = useAssessmentContext()
    const { file, flexibleContentId } = useFileContext()

    if (file.data.type !== 'assessment-card') {
      throw new Error(
        `[AssessmentCard] Trying to access assessment card in flexible content but is of type ${file.data.type}`
      )
    }

    const isCardCompleted = useSelector(state => selectCardIsCompleted(state, flexibleContentId, file.id))

    const allowRetry = file.data.settings.allowRetry ?? true

    return (
      <AssessmentContainer {...attributes} $hide={state.status === 'during'} ref={ref} radius='none'>
        {status === undefined ? (
          <DisplayNone>{children}</DisplayNone>
        ) : (
          <>
            {isCardCompleted ? (
              <>
                <AssessmentEnd {...props} allowRetry={allowRetry} end='successful' />
                {/* Children must be rendered as a part of our rules-of-blocks */}
                <DisplayNone>{children}</DisplayNone>
              </>
            ) : status === 'failed' && !allowRetry ? (
              <>
                <AssessmentEnd
                  {...props}
                  allowRetry={false}
                  end={'failed'}
                  timesUp={
                    state.status === 'successful' || state.status === 'failed' ? state.timesUp : undefined
                  }
                />

                {/* Children must be rendered as a part of our rules-of-blocks */}
                <DisplayNone>{children}</DisplayNone>
              </>
            ) : state.status === 'start' ? (
              <>{children}</>
            ) : state.status === 'successful' || state.status === 'failed' ? (
              <>
                <AssessmentEnd
                  {...props}
                  allowRetry={allowRetry}
                  end={state.status}
                  timesUp={state.timesUp}
                />

                {/* Children must be rendered as a part of our rules-of-blocks */}
                <DisplayNone>{children}</DisplayNone>
              </>
            ) : (
              <DisplayNone>{children}</DisplayNone>
            )}
          </>
        )}
      </AssessmentContainer>
    )
  }
)

const AssessmentIntroductionCreateWrapper = React.forwardRef<HTMLDivElement, SlateWrapperProps>(
  (props, ref) => {
    const { children, element, readOnly, attributes } = props
    assertElementType('assessment-introduction', element)

    const introductionPath = usePath({ nodeId: element.id })
    const editor = useSlateStatic()

    const addQuestion = useCallback(() => {
      Transforms.insertNodes(editor, createAssessmentQuestion(), { at: Path.next(introductionPath) })
      Transforms.select(editor, Editor.start(editor, Path.next(introductionPath)))
    }, [editor, introductionPath])

    return (
      <>
        <AssessmentContainer {...attributes} ref={ref} radius={assessmentContainerRadius}>
          {children}
        </AssessmentContainer>
        <AddQuestion onClick={addQuestion} hide={readOnly} />
      </>
    )
  }
)

function useNumberOfQuestionsToGetRight(numberOfQuestions: number): number | undefined {
  const file = useSafeFileContext()?.file
  if (file?.data.type !== 'assessment-card') return undefined

  const passingCriteria = getAssessmentPassingCriteria(file)
  const numberOfQuestionsToGetRight = evaluatePassingCriteria({
    passingCriteria,
    questionCount: numberOfQuestions,
  })
  return numberOfQuestionsToGetRight
}

const NumberOfQuestionsToGetRightLabel: React.FC<{ numberOfQuestions: number }> = ({ numberOfQuestions }) => {
  const { t } = useTranslation()
  const number = useNumberOfQuestionsToGetRight(numberOfQuestions)
  if (number === undefined || number === 0) return null
  return <IconLabel iconId='trophy' label={t('assessment-card.correct-to-pass', { number })} />
}

const TimeToFinishAssessmentLabel: React.FC<{ timeLimit: number }> = ({ timeLimit }) => {
  const { t } = useTranslation()
  return <IconLabel iconId='timer' label={t('assessment-card.time-to-pass', { count: timeLimit })} />
}

const QuizAnswerFast: React.FC = () => {
  const { t } = useTranslation()
  return <IconLabel iconId='bolt' label={t('assessment-card.fast-answer')} />
}

const AssessmentStartButton: React.FC<{
  assessmentCard: Entity<AssessmentCard>
  allowRetry: boolean
  assessmentStatus: string | undefined
  timeLimit: number | undefined
}> = ({ assessmentCard, assessmentStatus, allowRetry, timeLimit }) => {
  const { t } = useTranslation()
  const dispatch = useDispatch()
  const { state, setState } = useAssessmentContext()

  return (
    <View marginTop='large' contentEditable={false}>
      {(assessmentStatus !== 'started' || allowRetry) && (
        <Button
          onClick={async () => {
            const startState = await Assessments.start(true, state, timeLimit)
            setState(startState)
          }}
        >
          {t('assessment-card.start')}
        </Button>
      )}
      {assessmentStatus === 'started' && (
        <Button
          onClick={async () => {
            const startState = await Assessments.start(false, state, timeLimit)

            const questionCardsInAssessment = assessmentCard.children.flatMap(it => {
              if (it.type === 'assessment-question') {
                return it.children.filter(child => child.type === 'question-card')
              } else return []
            })

            const questionBodies = questionCardsInAssessment.map(questionCard =>
              questionCard.children.find(
                child =>
                  child.type === 'question-card-select-all-that-apply-body' ||
                  child.type === 'question-card-free-text-body' ||
                  child.type === 'question-card-pick-the-best-option-body' ||
                  child.type === 'question-card-match-the-pairs-body'
              )
            )

            const questionBodiesTypeSafe = questionBodies.filter(
              isElementType([
                'question-card-match-the-pairs-body',
                'question-card-pick-the-best-option-body',
                'question-card-select-all-that-apply-body',
                'question-card-free-text-body',
              ])
            )

            if (startState.status === 'during') {
              void dispatch(
                getAssessmentQuestionCardResponses({
                  contentId: startState.contentId,
                  fileId: startState.fileId,
                  questionExerciseIds: startState.questionExerciseIds,
                  elements: questionBodiesTypeSafe,
                  sessionId: startState.sessionId,
                })
              )
            }
            setState(startState)
          }}
        >
          {t('assessment-card.continue')}
        </Button>
      )}
    </View>
  )
}

function useAllowRetry(): boolean {
  const file = useSafeFileContext()?.file
  if (file === undefined) return true
  if (file.data.type !== 'assessment-card') return true
  return file.data.settings.allowRetry ?? true
}

const AssessmentIntroduction: SlateFC = props => {
  const { children, element, mode } = props
  assertElementType('assessment-introduction', element)
  const assessmentCard = useParent({ nodeId: element.id })
  assertElementType('assessment-card', assessmentCard)

  const isLiveCourseOrInLive = useIsLiveCourseOrInLive()

  const { t } = useTranslation()
  const assessmentsContext = useAssessmentContextUnsafe()
  const status = assessmentsContext?.status

  const allowRetry = useAllowRetry()

  const file = useSafeFileContext()?.file
  if (file === undefined) return null
  const timeLimit = getAssessmentTimeLimit(file)

  const numberOfQuestions = assessmentCard.children.length - 1 // don't count introduction

  return (
    <RenderingContext
      withGrid={false}
      preventDrag={true}
      allowBlockComments={true}
      disableMenu={true}
      placeholder={isLiveCourseOrInLive ? 'assessment-card.quiz' : 'assessment-card.assessment'}
    >
      {(mode === 'create' || mode === 'template' || mode === 'version-history') && (
        <QuestionCardIconBar justifyContent='flex-start'>
          <AssessmentHeadline iconId='character--sentence-case' label={t('assessment-card.introduction')} />
        </QuestionCardIconBar>
      )}
      <Headline marginBottom='xsmall'>{children}</Headline>
      {timeLimit !== 'wrong-card-type' ? (
        <>
          <Legend>
            <IconLabel
              iconId='help'
              label={t('assessment-card.questions-count', { count: numberOfQuestions })}
            />
            {isLiveCourseOrInLive ? (
              <QuizAnswerFast />
            ) : (
              <>
                <NumberOfQuestionsToGetRightLabel numberOfQuestions={numberOfQuestions} />
                {timeLimit !== undefined ? <TimeToFinishAssessmentLabel timeLimit={timeLimit} /> : null}
              </>
            )}
          </Legend>
          {mode !== 'create' && mode !== 'template' && mode !== 'version-history' && (
            <>
              <AssessmentStartButton
                assessmentCard={assessmentCard}
                timeLimit={timeLimit}
                allowRetry={allowRetry}
                assessmentStatus={status}
              />
              <SyncClock />
            </>
          )}
        </>
      ) : (
        <div contentEditable={false}>Cannot render {element.type} in this context</div>
      )}
    </RenderingContext>
  )
}

const LiveQuizIntroductionComponent: SlateFC = props => {
  return props.children
}

export const AssessmentIntroductionComponent: SlateFC = props => {
  const { children, mode } = props

  const Renderer = iife(() => {
    switch (mode) {
      case 'create':
      case 'template':
      case 'version-history':
      case 'self-paced':
      case 'review':
      case 'placement-test':
      case 'recap':
        return AssessmentIntroduction
      case 'live':
        return LiveQuizIntroductionComponent
      default:
        assertNever(mode)
    }
  })

  return <Renderer {...props}>{children}</Renderer>
}

const Centred = styled(View).attrs({
  direction: 'column',
  justifyContent: 'center',
  alignItems: 'center',
  grow: true,
})`
  margin-top: 60px;
  margin-bottom: 60px;
`

const LobbyWrapperFromZak = styled(View).attrs({
  marginTop: '40',
  marginBottom: '40',
  gap: '24',
})`
  width: 100%;
  flex-wrap: wrap;
  padding: 80px;
`

const LobbyUsers: React.FC<{ users: LiveQuizUser[] }> = ({ users }) => {
  return (
    <LobbyWrapperFromZak>
      {users.map(({ userId, icon, nickname }) => (
        <View key={userId} direction='column' alignItems='center'>
          <LiveQuizAvatar size='lobby' icon={icon} />
          <Text>{nickname}</Text>
        </View>
      ))}
    </LobbyWrapperFromZak>
  )
}

const LiveQuizIntroductionWrapper = forwardRef<HTMLDivElement, SlateWrapperProps>(({ children }, ref) => {
  const { status, setStatus, user, users } = useLiveQuizContext()
  const isFacilitator = useIsFacilitatorOrLearnerLedSession()
  const currentUserId = useCurrentUserId()

  if (status.step !== 'lobby') {
    return null
  }
  if (currentUserId.data === undefined) {
    return null
  }

  const next = (): void => {
    setStatus({ step: 'countdown', currentIndex: 1, hostUserId: currentUserId.data })
  }

  return (
    <Centred ref={ref}>
      {children}
      {isFacilitator && <Button onClick={next}>Start</Button>}
      <LobbyUsers users={users} />
      {user === undefined && <LiveQuizUserSettingsModal />}
    </Centred>
  )
})

export const AssessmentIntroductionWrapper = React.forwardRef<HTMLDivElement, SlateWrapperProps>(
  (props, ref) => {
    const { children, mode } = props

    const Renderer = iife(() => {
      switch (mode) {
        case 'create':
        case 'template':
        case 'version-history':
          return AssessmentIntroductionCreateWrapper
        case 'self-paced':
        case 'review':
        case 'placement-test':
        case 'recap':
          return AssessmentIntroductionLearnerWrapper
        case 'live':
          return LiveQuizIntroductionWrapper
        default:
          assertNever(mode)
      }
    })

    return (
      <Renderer {...props} ref={ref}>
        {children}
      </Renderer>
    )
  }
)
