import { color } from 'sierra-ui/color'
import { css } from 'styled-components'

export const MiniIconColorCss = css`
  color: ${p => color(p.theme.home.textColor).opacity(0.5).toString()};

  &:hover {
    color: ${p => p.theme.home.textColor};
  }
`
