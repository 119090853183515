import { UseQueryResult } from '@tanstack/react-query'
import { getInsightsQueryOptions } from 'sierra-client/features/insights/api-hooks/query-options'
import { useCachedQuery } from 'sierra-client/state/api'
import {
  InsightsDashboardTemplateGetRequest,
  InsightsDashboardTemplateGetResponse,
} from 'sierra-domain/api/insights'
import { XAnalyticsDashboardsTemplatesGet } from 'sierra-domain/routes'

export const useGetDashboardTemplate = (
  request: InsightsDashboardTemplateGetRequest
): UseQueryResult<InsightsDashboardTemplateGetResponse> => {
  const dashboardData = useCachedQuery(XAnalyticsDashboardsTemplatesGet, request, {
    ...getInsightsQueryOptions<InsightsDashboardTemplateGetResponse>(),
    staleTime: 10 * 1000,
  })

  return dashboardData
}
