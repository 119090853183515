/* eslint-disable @typescript-eslint/explicit-function-return-type */
import { UseQueryResult, useMutation, useQuery } from '@tanstack/react-query'
import { queryClient } from 'sierra-client/api/query-client'
import { typedPost } from 'sierra-client/state/api'
import {
  GetRecordingsInSessionRequest,
  GetRecordingsInSessionResponse,
  StartLiveSessionRecordingRequest,
  StopLiveSessionRecordingRequest,
} from 'sierra-domain/recordings'
import {
  XRealtimeStrategyLiveSessionGetRecordingsInSession,
  XRealtimeStrategyLiveSessionStartRecording,
  XRealtimeStrategyLiveSessionStopRecording,
} from 'sierra-domain/routes'

const CACHE_KEY = 'recording' as const

export const getRecordingCacheKeys = (recordingId?: string): string[] => {
  return recordingId !== undefined ? [CACHE_KEY, recordingId] : [CACHE_KEY]
}

/**
 *  This will refetch every 5 seconds if any of the recordings are still processing
 *
 * */
export const useRecordingsInSessionQuery = (
  parameters: GetRecordingsInSessionRequest
): UseQueryResult<GetRecordingsInSessionResponse, unknown> => {
  const queryResult = useQuery({
    queryKey: [CACHE_KEY, 'liveSessionId', parameters.liveSessionId],
    queryFn: async () => {
      const fetchResult = await typedPost(XRealtimeStrategyLiveSessionGetRecordingsInSession, parameters)
      return fetchResult
    },
    refetchInterval: query =>
      query.state.data?.recordings.some(recording => recording.status === 'processing') === true
        ? 5000
        : false,
  })

  return queryResult
}

export const useStartRecordingMutation = () => {
  const mutation = useMutation({
    mutationFn: async (parameters: StartLiveSessionRecordingRequest) => {
      const mutationResult = await typedPost(XRealtimeStrategyLiveSessionStartRecording, parameters)
      return mutationResult
    },
    onSuccess: async data => {
      await queryClient.invalidateQueries({ queryKey: getRecordingCacheKeys(data.recordingId) })
    },
  })

  return mutation
}

export const useStopRecordingMutation = () => {
  const mutation = useMutation({
    mutationFn: async (parameters: StopLiveSessionRecordingRequest) => {
      const mutationResult = await typedPost(XRealtimeStrategyLiveSessionStopRecording, parameters)
      return mutationResult
    },
    onSuccess: async (_, variables) => {
      await queryClient.invalidateQueries({ queryKey: getRecordingCacheKeys(variables.recordingId) })
    },
  })

  return mutation
}
