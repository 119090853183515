import React from 'react'
import { FCC } from 'sierra-client/types'

const ReactPDFExportContext = React.createContext<boolean>(false)

export const PDFExportContext: FCC = ({ children }) => (
  <ReactPDFExportContext.Provider value={true}>{children}</ReactPDFExportContext.Provider>
)

export function useIsInPdfExport(): boolean {
  return React.useContext(ReactPDFExportContext)
}
