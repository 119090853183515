import { useQueryClient, UseQueryResult } from '@tanstack/react-query'
import { getCachedQueryKey, TypedMutation, useCachedQuery, useTypedMutation } from 'sierra-client/state/api'
import { OrgNotificationSettingsResponse } from 'sierra-domain/api/org-notification-settings'
import {
  XRealtimeAdminNotificationSettingsGetOrgNotificationSettings,
  XRealtimeAdminNotificationSettingsSetOrgNotificationSettings,
} from 'sierra-domain/routes'

const QUERY = XRealtimeAdminNotificationSettingsGetOrgNotificationSettings
const INPUT = {}
export const useOrgNotificationSettings = (): {
  result: UseQueryResult<OrgNotificationSettingsResponse>
  mutate: TypedMutation<typeof XRealtimeAdminNotificationSettingsSetOrgNotificationSettings>['mutate']
} => {
  const queryKey = getCachedQueryKey(QUERY, INPUT)
  const queryClient = useQueryClient()
  const res = useCachedQuery(QUERY, INPUT)

  const { mutate } = useTypedMutation(XRealtimeAdminNotificationSettingsSetOrgNotificationSettings, {
    // Work with the built in cache rather than using our own useState state
    onSuccess: data => {
      queryClient.setQueryData(queryKey, () => data)
    },
  })
  return { result: res, mutate: mutate }
}
