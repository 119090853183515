import { createSlice, isAnyOf } from '@reduxjs/toolkit'
import { deepPatchJson } from '@sanalabs/json'
import { clearState } from 'sierra-client/state/actions'
import {
  addIssue,
  clearAllIssues,
  clearIssue,
  disableVideo,
  dismissIssue,
  enableVideo,
  muteAudio,
  setBackgroundBlur,
  setCameraResolution,
  setKeyboardNavigationEnabled,
  setNoiseCancellation,
  setPreferredVideoQuality,
  setUsersDisplayed,
  unmuteAudio,
  updateAvailableCameras,
  updateAvailableMicrophones,
  updateCallState,
} from 'sierra-client/state/live/actions'
import { LiveState } from 'sierra-client/state/live/types'

const initialState: LiveState = {
  callState: {
    noiseCancellationEnabled: false,
    backgroundBlurEnabled: false,
    forceCloudProxy: false,
    audio: {
      state: 'unavailable',
    },
    video: {
      state: 'unavailable',
    },
    screenShare: {
      connectionState: 'DISCONNECTED',
      enabled: false,
    },
    screenRecord: {
      connectionState: 'DISCONNECTED',
      enabled: false,
    },
    call: {
      callMode: { state: 'not-joined' },
      joinState: 'not-joined',
      clientConnected: false,
      isPublishing: false,
      isPublishingAudio: false,
      isPublishingVideo: false,
      connectionState: 'DISCONNECTED',
      remoteParticipants: [],
    },
    devices: {},
  },
  usersDisplayed: [],
  issues: [],
  preferredVideoQuality: 'auto',
  keyboardNavigationEnabled: true,
}

export const liveSlice = createSlice({
  name: 'live',
  initialState,
  reducers: {},
  extraReducers: builder => {
    builder.addCase(clearState, () => initialState)

    builder.addCase(setCameraResolution.fulfilled, (state, { payload }) => {
      state.callState.video.resolution = payload.resolution
    })

    builder.addCase(setUsersDisplayed, (state, { payload }) => {
      state.usersDisplayed = payload
    })

    builder.addCase(setPreferredVideoQuality, (state, { payload }) => {
      state.preferredVideoQuality = payload
    })

    builder.addCase(addIssue, (state, { payload }) => {
      const currentIssues = state.issues
      if (currentIssues.find(issue => issue.issueName === payload)) {
        return
      }

      state.issues = [...currentIssues, { issueName: payload, dismissed: false }]
    })

    builder.addCase(clearIssue, (state, { payload }) => {
      state.issues = state.issues.filter(issue => issue.issueName !== payload)
    })

    builder.addCase(clearAllIssues, state => {
      state.issues = []
    })

    builder.addCase(dismissIssue, (state, { payload }) => {
      state.issues = state.issues.map(issue =>
        issue.issueName === payload ? { ...issue, dismissed: true } : issue
      )
    })

    builder.addCase(setKeyboardNavigationEnabled, (state, { payload }) => {
      state.keyboardNavigationEnabled = payload
    })

    builder.addCase(updateAvailableCameras.fulfilled, (state, { payload }) => {
      state.callState.devices.cameras = payload.cameras
    })

    builder.addCase(updateAvailableMicrophones.fulfilled, (state, { payload }) => {
      state.callState.devices.microphones = payload.microphones
    })

    builder.addCase(setNoiseCancellation.fulfilled, (state, { payload }) => {
      state.callState.noiseCancellationEnabled = payload.enabled
    })

    builder.addCase(setBackgroundBlur.fulfilled, (state, { payload }) => {
      state.callState.backgroundBlurEnabled = payload.enabled
    })

    builder.addMatcher(isAnyOf(disableVideo.fulfilled, enableVideo.fulfilled), (state, { payload }) => {
      state.callState.video.state = payload.videoState
    })

    builder.addMatcher(isAnyOf(muteAudio.fulfilled, unmuteAudio.fulfilled), (state, { payload }) => {
      state.callState.audio.state = payload.audioState
    })

    builder.addMatcher(isAnyOf(updateCallState.fulfilled), (state, { payload }) => {
      deepPatchJson(state.callState, payload.callState)
      state.transcriptionToken = payload.transcription.transcriptionToken
    })
  },
})
