import { FileIconProps } from 'sierra-client/views/flexible-content/editor/content-sidebar/icons'
import { FileType, createFile } from 'sierra-domain/flexible-content/types'

export const createFileFromType = (fileType: FileType): FileIconProps => {
  switch (fileType) {
    case 'assessment-card':
      return createFile({ data: { type: 'assessment-card', settings: { passingCriteria: 0 } } })
    case 'homework':
      return createFile({ data: { type: 'homework', submissionType: 'file' } })
    case 'embed':
      return createFile({ data: { type: 'embed' } })
    case 'video':
      return createFile({ data: { type: 'video', video: {} } })
    case 'image':
      return createFile({ data: { type: 'image', image: undefined } })
    case 'breakout-room':
      return createFile({ data: { type: 'breakout-room', settings: { algorithm: 'random', roomSize: 1 } } })
    case 'scenario':
      // TODO: what should this be HUY?
      return createFile({
        data: {
          type: 'scenario',
          input: {
            scenario: undefined,
            character: {
              voice: 'onyx',
              avatar: '',
              name: 'James',
            },
          },
        },
      })
    default:
      return createFile({ data: { type: fileType } })
  }
}
