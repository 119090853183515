import { useAtomValue } from 'jotai'
import React from 'react'
import { getFlag } from 'sierra-client/config/global-config'
import { useListDashboards } from 'sierra-client/features/insights/api-hooks/use-list-dashboards'
import { useUpsertDashboard } from 'sierra-client/features/insights/api-hooks/use-upsert-dashboard'
import { dashboardsMetaAtom } from 'sierra-client/features/insights/atoms'
import { createNewDashboardAndRedirect } from 'sierra-client/features/insights/dashboard-selector/utils'
import { insightsDashboardCreatedLogger } from 'sierra-client/features/insights/logger'
import { getRoleTranslationKey } from 'sierra-client/features/insights/utils'
import { useTranslation } from 'sierra-client/hooks/use-translation'
import { getGlobalRouter } from 'sierra-client/router'
import { useDispatch } from 'sierra-client/state/hooks'
import { Dashboard } from 'sierra-domain/api/insights'
import { MenuItem } from 'sierra-ui/components'
import { View } from 'sierra-ui/primitives'
import { SingleSelectDropdown } from 'sierra-ui/primitives/menu-dropdown'
import styled from 'styled-components'

const StyledView = styled(View)`
  width: 100%;
`

const MAX_DASHBOARDS_IN_SELECTOR = 5

export const DashboardSelector: React.FC<{ selectedId?: Dashboard['id'] }> = ({ selectedId }) => {
  const allDashboards = useListDashboards()
  const upsertDashboard = useUpsertDashboard()
  const { t } = useTranslation()
  const dispatch = useDispatch()
  const newInsightsHome = getFlag('insights/new-home')
  const dashboardsMeta = useAtomValue(dashboardsMetaAtom)

  const dashboardNavItems: MenuItem[] =
    allDashboards.data !== undefined
      ? allDashboards.data.dashboards
          .sort(
            (a, b) =>
              (dashboardsMeta[b.dashboard.id]?.lastVisitedTimestampMs ?? 0) -
              (dashboardsMeta[a.dashboard.id]?.lastVisitedTimestampMs ?? 0)
          )
          .slice(0, newInsightsHome ? MAX_DASHBOARDS_IN_SELECTOR : undefined)
          .map(dashboard => ({
            ...dashboard,
            type: 'label' as const,
            label: dashboard.dashboard.title,
            description: t(getRoleTranslationKey(dashboard.role)),
            id: dashboard.dashboard.id,
            selected: dashboard.dashboard.id === selectedId,
          }))
      : []

  const homeNavItem: MenuItem = {
    type: 'label' as const,
    id: 'view-all-dashboards',
    label: t('manage.insights.dashboard-selector.view-all-dashboards'),
    icon: 'grid' as const,
  }

  const dashboardNavItemGroup: MenuItem[] =
    dashboardNavItems.length > 0
      ? [
          {
            type: 'group' as const,
            id: 'dashboards',
            label: newInsightsHome
              ? t('manage.insights.dashboard-selector.recent-dashboards')
              : t('manage.insights.dashboard-selector.dashboards'),
            menuItems: dashboardNavItems,
          },
        ]
      : []

  const createNewDashboardNavItem: MenuItem = {
    type: 'label' as const,
    id: 'create-new-dashboard',
    label: t('manage.insights.dashboard-selector.create-new-dashboard'),
    icon: 'add' as const,
  }

  const createNewDashboardNavItemGroup: MenuItem[] = [
    {
      type: 'group' as const,
      id: 'create-new-dashboard-group',
      label: t('manage.insights.dashboard-selector.create-new'),
      menuItems: [createNewDashboardNavItem],
    },
  ]

  const menuItems: MenuItem[] = newInsightsHome
    ? [
        ...dashboardNavItemGroup,
        homeNavItem,
        { type: 'separator', id: 'separator' },
        ...createNewDashboardNavItemGroup,
      ]
    : [...dashboardNavItemGroup, ...createNewDashboardNavItemGroup]

  return (
    <StyledView data-intercom-target='dashboard-selector' data-testid='dashboard-selector'>
      <SingleSelectDropdown
        placeholder={t('manage.insights.dashboard-selector.placeholder')}
        selectedItem={dashboardNavItems.find(it => it.id === selectedId)}
        bold
        menuItems={menuItems}
        onSelect={item => {
          switch (item.id) {
            case 'create-new-dashboard':
              void createNewDashboardAndRedirect('empty', t, async dashboard => {
                const upsertedDashboard = await upsertDashboard.mutateAsync({ dashboard })
                return upsertedDashboard.dashboard
              })
              void dispatch(insightsDashboardCreatedLogger({ dashboardId: undefined }))
              break
            case 'view-all-dashboards':
              void getGlobalRouter().navigate({ to: `/manage/insights/dashboards` })
              break
            default:
              void getGlobalRouter().navigate({ to: `/manage/insights/dashboards/${item.id}` })
          }
        }}
        truncateTrigger={1}
      />
    </StyledView>
  )
}
