import { useSelectCurrentCardBackgroundColor } from 'sierra-client/components/liveV2/hooks/use-select-current-card'
import { useLiveSessionIdContext } from 'sierra-client/components/liveV2/live-session-id-provider'
import { useRightSidebarContext } from 'sierra-client/components/liveV2/live-sidebar-provider'
import { TransparentNowButton } from 'sierra-client/features/sana-now/header/buttons'
import { useTranslation } from 'sierra-client/hooks/use-translation'
import { useIsMobile } from 'sierra-client/state/browser/selectors'
import { selectUnreadMessagesCount } from 'sierra-client/state/chat/selectors'
import { useSelector } from 'sierra-client/state/hooks'
import { ScopedChatId } from 'sierra-domain/collaboration/types'
import { Text } from 'sierra-ui/primitives'
import { palette } from 'sierra-ui/theming'
import styled from 'styled-components'

const CounterWrapper = styled.div`
  padding: 1px 4px;
  height: 16px;
  min-width: 16px;
  border-radius: 8px;
  background-color: ${palette.red.vivid};
`

const CounterText = styled(Text).attrs({
  color: 'white',
  size: 'technical',
  bold: true,
})`
  font-variant-numeric: tabular-nums;
`

const UnreadMessageCount = ({ count }: { count: number }): JSX.Element => {
  return (
    <CounterWrapper>
      <CounterText>{count}</CounterText>
    </CounterWrapper>
  )
}

export const ChatButton = (): JSX.Element | null => {
  const isMobile = useIsMobile()
  const { t } = useTranslation()
  const rightSidebarContext = useRightSidebarContext()
  const { liveSessionId } = useLiveSessionIdContext()
  const chatId = ScopedChatId.fromId(liveSessionId)
  const unreadCount = useSelector(state => selectUnreadMessagesCount(state, chatId, 'root'))

  const backgroundColor = useSelectCurrentCardBackgroundColor()

  if (!isMobile && rightSidebarContext.isOpen) {
    // The chat panel on desktop renders its own close button, so we won't render it here.
    return null
  }

  return (
    <TransparentNowButton
      $backgroundColor={backgroundColor}
      decoratorPosition='left'
      customDecorator={unreadCount > 0 ? <UnreadMessageCount count={unreadCount} /> : undefined}
      onClick={() => {
        if (rightSidebarContext.isOpen) {
          rightSidebarContext.close()
        } else {
          rightSidebarContext.setState('chat_open')
        }
      }}
    >
      {t('dictionary.chat')}
    </TransparentNowButton>
  )
}
