import { createSelector } from '@reduxjs/toolkit'
import { OrganizationState } from 'sierra-client/state/organization/types'
import { RootState } from 'sierra-client/state/types'
import { isAmazon, isAssaAbloy, isHM, isPiab, isSecuritas } from 'sierra-domain/organization'

const selectState = (state: RootState): OrganizationState => state.organization

export const selectOrganization = createSelector(selectState, state => {
  const status = state.status
  if (status.type === 'loaded') {
    return status.organization
  } else {
    return undefined
  }
})

export const selectOrganizationUuid = createSelector(selectOrganization, org => org?.uuid)
export const selectOrganizationTenantId = createSelector(selectOrganization, org => org?.tenantId)

export const selectIsAutomatedTestingOrg = createSelector(
  selectOrganizationUuid,
  uuid => uuid === '1692ea56-ad62-4ed4-b032-af37dfb58430'
)

export const selectIsPiab = createSelector(selectOrganization, org => (org ? isPiab(org) : false))
export const selectIsAmazon = createSelector(selectOrganization, org => (org ? isAmazon(org) : false))
export const selectIsSecuritas = createSelector(selectOrganization, org => (org ? isSecuritas(org) : false))
export const selectIsAssaAbloy = createSelector(selectOrganization, org => (org ? isAssaAbloy(org) : false))
export const selectIsHM = createSelector(selectOrganization, org => (org ? isHM(org) : false))

export const selectOrganizationRatingModalEnabled = createSelector(selectOrganization, org => {
  const disabled = org?.disableCourseRatings ?? false

  return !disabled
})
