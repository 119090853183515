/* eslint-disable react/jsx-no-literals */
import React from 'react'
import { PrintButton, Wrap, WrapButtons } from 'sierra-client/views/learner/certificate/components'
import { CertificateProps } from 'sierra-client/views/learner/certificate/types'
import {
  getFormattedCompletionDate,
  getImageUrl,
  handlePrint,
} from 'sierra-client/views/learner/certificate/utils'
import { Heading, Spacer, Text } from 'sierra-ui/primitives'
import styled from 'styled-components'
// type WrapProps = { rtl?: boolean }

const StyledWrap = styled(Wrap)`
  @page {
    size: portrait;
  }
`

const Container = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  height: 100%;
  max-height: 100%;
`

const StyledWrapButtons = styled(WrapButtons)`
  left: 2rem;
  right: auto;
`

const SmartLearningBottomImg = styled.img`
  position: absolute;
  top: 950px;
  left: 50%;
  transform: translateX(-50%);
  width: 700px;
`
const SmartLearningTopImg = styled.img`
  position: absolute;
  top: 5px;
  left: 50%;
  transform: translateX(-50%);
  width: 700px;
`

// const SmartLearningLeftImg = styled.img`
//   position: absolute;
//   top: 300px;
//   left: 10%;
//   transform: translateX(-50%);
//   width: 350px;
// `

// const SignatureWrapSmartLearning = styled.div<WrapProps>`
//   body {
//     margin: 0;
//     padding: 0;
//     @page {
//       margin: 0;
//     }
//   }

//   direction: ${p => (p.rtl ?? false ? 'rtl' : 'ltr')};

//   padding: 2cm;
//   width: 210mm;
//   padding-bottom: 0;
//   margin: 0 auto;
//   margin-top: -7rem; /* Cover HeaderWrapper */
//   line-height: 1.5;
// `

const SignatureSmartLearning = styled.img`
  height: 120px;
`
const SmartLearningDetails = styled.div`
  padding: 0 2.5rem;
`

const SmartLearningInfo = styled.p`
  margin: 1rem 0;
  font-size: 0.75rem;
`

const LogoWrapSmartLearning = styled.div`
  display: flex;
  justify-content: flex-start;
  margin: 2rem 0 0;
`
const LogoSmartLearning = styled.img`
  width: 250px;
  align: left;
`

const BodyText: React.FC<{ courseId: string }> = ({ courseId }) => {
  if (courseId === 'C1CuXbA8xjB9') {
    // TAL MED GENNEMSLAGSKRAFT – FOR LEDERE
    return (
      <>
        På kurset har indehaveren lært at:
        <br />
        • Tale med sin passion for at få andre til at følge sig
        <br />
        • Bruge mundtlig kommunikation effektivt til at overbevise, inspirere, briefe og motivere andre
        <br />• Bruge retoriske virkemidler til at præ- sentere sig selv, og gøre sine præsentationer
        strukturerede, fokuserede og vedkommende
      </>
    )
  } else if (courseId === 'lMwfDX2X6GfM') {
    // FRA MEDARBEJDER TIL LEDER MED SUCCES
    return (
      <>
        På kurset har indehaveren lært at:
        <br />
        • Lede ved at skabe retning, koordinering og commitment
        <br />
        • Identificere sit ledelsesrum og gøre det større
        <br />
        • Bruge situationbestemt ledelse
        <br />
        • Bruge redskaber som self-efficacy, joharivinduet og assertiv adfærd til at styrke egen selvudvikling
        <br />• prioritere opgaver effektivt
      </>
    )
  } else if (courseId === 'j3Kp72HJCAkE') {
    // LED DINE MEDARBEJDERE MED PERSONTYPETEORI

    return (
      <>
        På kurset har indehaveren lært at:
        <br />
        • Kende sin egen og medarbejderes persontype gennem introduktion til enneagrammet og de 9 persontyper
        <br />
        • Bruge enneagrammet på en etisk forsvarlig måde
        <br />
        • Bruge enneagrammet til at lede sine medarbejdere, med viden om de 9 typers basale adfærdsmønstre,
        drivkraft og frygt, stress- og tryghedsben, og adfærd i sund og usund udvikling
        <br />• Lede persontyperne gennem forandringer på arbejdspladsen
      </>
    )
  } else if (courseId === 'mG0njrkyv1rN') {
    // LED DIT TEAM TIL BEDRE SAMARBEJDE
    return (
      <>
        På kurset har indehaveren lært at:
        <br />
        • Bruge ikke-voldelig, assertiv og aktivt lyttende kommunikation, til at skabe gode
        samarbejdsrelationer, bedre møder og kommunikationsflow
        <br />
        • Give sine medarbejdere bedre feedback og mere anerkendelse
        <br />
        • Løse situationer, når samarbejdet er svært: udrede misforståelser, give liv til et sløvt samarbejde
        og tage en svær samtale
        <br />• Afholde en konfliktafklarende samtale, og forebygge konflikter
      </>
    )
  } else {
    return null
  }
}

export const SmartLearningCertificate: React.FC<CertificateProps> = ({
  user,
  completionTimestamp,
  course,
}) => (
  <>
    <StyledWrap id='printwrapper'>
      <Container>
        <SmartLearningTopImg src={getImageUrl('smartlearning-cert-top.png')} />
        <Spacer size='xxlarge' />
        <Spacer size='xxlarge' />
        <Heading color='LEGACY_DEFAULT_HEADING_COLOR_REPLACE_ASAP' size='h5'>
          {user.firstName} {user.lastName} har {getFormattedCompletionDate(completionTimestamp)} gennemført
          kurset
        </Heading>
        <Spacer size='large' />
        <Heading color='LEGACY_DEFAULT_HEADING_COLOR_REPLACE_ASAP' size='h4'>
          {' '}
          {course.settings.title}
        </Heading>

        <SmartLearningInfo>
          <LogoWrapSmartLearning>
            <LogoSmartLearning src={getImageUrl('smartlearning-cert-fig.png')} />
            <SmartLearningDetails>
              <Spacer size='large' />
              <Text color='LEGACY_DEFAULT_TEXT_COLOR_REPLACE_ASAP'>
                <BodyText courseId={course.id} />
              </Text>
              <Spacer size='medium' />
              <SignatureSmartLearning src={getImageUrl('smartlearning-cert-signature.png')} />
              <Spacer size='medium' />
            </SmartLearningDetails>
          </LogoWrapSmartLearning>
        </SmartLearningInfo>
        <Spacer size='large' />
        <SmartLearningBottomImg src={getImageUrl('smartlearning-cert-bottom.png')} />
      </Container>
    </StyledWrap>
    <StyledWrapButtons>
      <PrintButton variant='secondary' onClick={handlePrint}>
        Print or download
      </PrintButton>
    </StyledWrapButtons>
  </>
)
