import { useSetAtom } from 'jotai'
import { DateTime } from 'luxon'
import { useMemo, useState } from 'react'
import { useCurrentUser } from 'sierra-client/api/hooks/use-user'
import { savedVideoCallSettingTypeAtom } from 'sierra-client/features/sana-now/create-session-panel/saved-video-call-setting-type-atom'
import { useTranslation } from 'sierra-client/hooks/use-translation'
import { TranslationLookup } from 'sierra-client/hooks/use-translation/types'
import { useRealtimeCourseTitle } from 'sierra-client/views/flexible-content/use-realtime-course-title'
import {
  oneHourFromNowFloored,
  twoHoursFromNowFloored,
} from 'sierra-client/views/manage/event-groups/components/shared'
import { eventScheduleValid } from 'sierra-client/views/manage/event-groups/event-utils'
import { CalendarEventSchedule } from 'sierra-client/views/manage/event-groups/types'
import { IdentityWithMetadata } from 'sierra-domain/api/manage'
import { LiveContentId } from 'sierra-domain/api/nano-id'
import { VideoCallSetting } from 'sierra-domain/content/session'
import { LightUser } from 'sierra-domain/user'
import { assertIsNonNullable, getUserName, guardWith } from 'sierra-domain/utils'

export type SessionFormData = {
  title: string
  facilitators: IdentityWithMetadata[]
  participants: IdentityWithMetadata[]
  schedule: CalendarEventSchedule
  learnerLedSession: boolean
  allowGuestAccess: boolean
  transcribeSession: boolean
  enableRecap: boolean
  maxNumberOfUsers: number | undefined
  location: string | undefined
  enableSelfEnrollment: boolean
  disallowEarlyJoin: boolean
  hideTranscription: boolean
  videoCallSetting: VideoCallSetting
}
type FormField = keyof SessionFormData
type ValidationResult = { isValid: true } | { isValid: false; errorMessage?: string }

export const mapUserToIdentity = (user: LightUser): IdentityWithMetadata => {
  return {
    identity: {
      type: 'user',
      id: user.uuid,
    },
    name: getUserName(user) ?? '',
    email: user.email,
    avatar: {
      type: 'color',
      initials: `${user.firstName[0] ?? ''}${user.lastName[0] ?? ''}`,
      color: user.avatarColor,
    },
  }
}

const rules: Record<FormField, (values: SessionFormData, t: TranslationLookup) => ValidationResult> = {
  title: (values, t) =>
    values.title.trim().length > 0
      ? { isValid: true }
      : {
          isValid: false,
          errorMessage: t('admin.author.create-session-panel.error-message--title-is-required'),
        },
  facilitators: values =>
    values.facilitators.length > 0 || values.learnerLedSession ? { isValid: true } : { isValid: false },
  participants: values => {
    if (values.maxNumberOfUsers === undefined) {
      return { isValid: true }
    }

    const uniqueUsers = new Set([
      ...values.participants.map(it => it.identity.id),
      ...values.facilitators.map(it => it.identity.id),
    ])

    return { isValid: uniqueUsers.size <= values.maxNumberOfUsers }
  },
  schedule: values => {
    return { isValid: eventScheduleValid(values.schedule) }
  },
  learnerLedSession: () => ({ isValid: true }),
  allowGuestAccess: () => ({ isValid: true }),
  enableRecap: () => ({ isValid: true }),
  maxNumberOfUsers: () => ({ isValid: true }),
  location: () => ({ isValid: true }),
  enableSelfEnrollment: () => ({ isValid: true }),
  disallowEarlyJoin: () => ({ isValid: true }),
  transcribeSession: () => ({ isValid: true }),
  hideTranscription: () => ({ isValid: true }),
  videoCallSetting: values => ({ isValid: guardWith(VideoCallSetting, values.videoCallSetting) }),
}

type UseSessionFormDataResult = {
  updateValue: <Field extends FormField>(field: Field, value: SessionFormData[Field]) => void
  values: SessionFormData
  validationResult: Record<FormField, ValidationResult>
  runValidate: () => Record<FormField, ValidationResult>
}

export const useSessionFormData = (
  courseId: LiveContentId,
  initialData?: Partial<SessionFormData>
): UseSessionFormDataResult => {
  const { t } = useTranslation()
  const currentUser = useCurrentUser().data
  assertIsNonNullable(currentUser)
  const courseTitleResult = useRealtimeCourseTitle(courseId)
  const defaultTitle = courseTitleResult.loading ? '' : courseTitleResult.title
  const setSavedVideoCallSettingType = useSetAtom(savedVideoCallSettingTypeAtom)

  const [values, setValues] = useState<SessionFormData>((): SessionFormData => {
    const defaultSchedule: CalendarEventSchedule = {
      type: 'with-time',
      timeZone: DateTime.local().zoneName,
      startTime: oneHourFromNowFloored(),
      endTime: twoHoursFromNowFloored(),
    }

    return {
      title: defaultTitle,
      facilitators: [mapUserToIdentity(currentUser)],
      participants: [],
      schedule: defaultSchedule,
      learnerLedSession: false,
      allowGuestAccess: false,
      maxNumberOfUsers: undefined,
      location: undefined,
      enableRecap: true,
      enableSelfEnrollment: true,
      disallowEarlyJoin: false,
      transcribeSession: true,
      hideTranscription: false,
      videoCallSetting: { type: 'none' },
      ...(initialData ?? {}),
    }
  })

  const [validationResult, setValidationResult] = useState<Record<FormField, ValidationResult>>({
    title: { isValid: true },
    facilitators: { isValid: true },
    participants: { isValid: true },
    schedule: { isValid: true },
    learnerLedSession: { isValid: true },
    allowGuestAccess: { isValid: true },
    enableRecap: { isValid: true },
    maxNumberOfUsers: { isValid: true },
    location: { isValid: true },
    enableSelfEnrollment: { isValid: true },
    disallowEarlyJoin: { isValid: true },
    transcribeSession: { isValid: true },
    hideTranscription: { isValid: true },
    videoCallSetting: { isValid: true },
  })

  return useMemo<UseSessionFormDataResult>(
    () => ({
      updateValue: (field, value) => {
        setValidationResult(v => ({ ...v, [field]: { isValid: true } }))
        if (field === 'videoCallSetting') {
          const v = value as SessionFormData['videoCallSetting']
          const transformed: VideoCallSetting = v.type === 'url' && v.url === '' ? { type: 'none' } : v
          setSavedVideoCallSettingType(transformed.type)

          setValues(v => ({ ...v, [field]: transformed }))
          return
        }

        setValues(v => ({ ...v, [field]: value }))
      },
      values,
      validationResult,
      runValidate: () => {
        const currentValidation = { ...validationResult }
        for (const field of Object.keys(values)) {
          const f = field as FormField
          currentValidation[f] = rules[f](values, t)
        }
        setValidationResult(currentValidation)
        return currentValidation
      },
    }),
    [values, validationResult, setSavedVideoCallSettingType, t]
  )
}
