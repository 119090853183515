import React, { useEffect, useMemo, useState } from 'react'
import { useInView } from 'react-intersection-observer'
import { CellProps, Column, useRowSelect, useSortBy, useTable } from 'react-table'
import { RouterLink } from 'sierra-client/components/common/link'
import { Menu } from 'sierra-client/components/common/menu'
import { useManagedColumns } from 'sierra-client/components/table/managed-columns'
import { SortableHeader } from 'sierra-client/components/table/sortable-header'
import { Table } from 'sierra-client/components/table/table'
import { SmallTableScrollContainer } from 'sierra-client/components/table/table-utils'
import { percentage } from 'sierra-client/core/format'
import { useDebouncedAndLiveState } from 'sierra-client/hooks/use-debounced-state'
import { useTranslation } from 'sierra-client/hooks/use-translation'
import { getGlobalRouter } from 'sierra-client/router'
import {
  Empty,
  InfiniteScrollMessage,
  InfoIcon,
  InheritedText,
  MenuContainer,
} from 'sierra-client/views/manage/components/common'
import { ExportCSVButton } from 'sierra-client/views/manage/components/export-csv'
import { SmallTableWrapper } from 'sierra-client/views/manage/components/small-table-wrapper'
import { Status } from 'sierra-client/views/manage/groups/components/status'
import { UseUserGroupDetailsData } from 'sierra-client/views/manage/user-groups/use-user-group-details'
import { getAccessLevelTranslationKey } from 'sierra-client/views/manage/users/user-utils'
import { ColumnSelector, Selections } from 'sierra-client/views/manage/users/utils/column-selector'
import { formatPercentage } from 'sierra-client/views/measure/analytics-v2/util'
import { UserAccessLevel } from 'sierra-domain/access-level'
import { UserGroupMember } from 'sierra-domain/api/manage'
import { UserId } from 'sierra-domain/api/uuid'
import { CustomUserAttributeMetadata } from 'sierra-domain/user/custom-user-attribute-metadata'
import { asNonNullable } from 'sierra-domain/utils'
import { UserDisplay, getAvatarPropsFromBaseUserInfo } from 'sierra-ui/components'
import { MUIMenuItem } from 'sierra-ui/mui'
import { Button, Spacer, Text, View } from 'sierra-ui/primitives'
import styled from 'styled-components'

type UsersTableProps = {
  members: UseUserGroupDetailsData['members']
  groupId: string
  fetchAssignedUsers: UseUserGroupDetailsData['fetchMembers']
  onRemove: (userIds: UserId[]) => void
  onAddUsers: () => void
  onShowHeatmap?: () => void
  canEditMembers: boolean
}

const TableButton = styled(Button).attrs({
  variant: 'secondary',
})``

type LegacyUserGroupMember = UserGroupMember & {
  // Access level is being phased out, so we don't want it in the general userInfo object.
  // We still need it here though, so that's why we overwrite the type like this.
  userInfo: UserGroupMember['userInfo'] & { accessLevel: UserAccessLevel }
}

export const UsersTableWrapper: React.FC<UsersTableProps> = ({
  members,
  groupId,
  fetchAssignedUsers,
  onRemove,
  canEditMembers,
  onAddUsers,
  onShowHeatmap,
}: UsersTableProps) => {
  const { t } = useTranslation()
  const [inViewRef, shouldLoadMore] = useInView({ threshold: 0 })
  const [focusedUserId, setFocusedUserId] = useState<string | undefined>(undefined)
  const [debouncedQuery, liveQuery, setQuery] = useDebouncedAndLiveState('')
  const isEmpty = members.totalMembersInGroup === 0

  const mapUserToCsv = (user: UserGroupMember, accessLevel: UserAccessLevel): Record<string, unknown> => {
    return {
      firstName: user.userInfo.firstName,
      lastName: user.userInfo.lastName,
      email: user.userInfo.email,
      status: user.userInfo.status,
      progress: percentage(user.progress),
      numAssignedCourses: user.numAssignedCourses,
      accessLevel: accessLevel,
    }
  }

  const fetchCsvData = async ({
    customUserAttributesMetadata,
    groupId,
  }: {
    customUserAttributesMetadata: CustomUserAttributeMetadata[]
    groupId: string
  }): Promise<Array<Record<string, unknown>>> => {
    const { data, accessLevels } = await fetchAssignedUsers(
      groupId,
      {
        limit: 50_000,
      },
      { forCsv: true }
    )

    return data.map(user => ({
      ...mapUserToCsv(user, asNonNullable(accessLevels[user.userInfo.userId])),
      ...Object.fromEntries(
        customUserAttributesMetadata.map(attr => [attr.label, user.customUserAttributes[attr.key]])
      ),
    }))
  }

  // Table
  const defaultTableColumns: (Column<LegacyUserGroupMember> & { label: string })[] = useMemo(
    () => [
      {
        id: 'name',
        label: t('table.name'),
        Header: p => {
          return (
            <>
              <SortableHeader label={t('table.name')} {...p} />
            </>
          )
        },
        width: '300px',
        Cell: (p: CellProps<LegacyUserGroupMember>) => {
          const {
            userInfo: { firstName, lastName, email, avatarColor, avatar },
          } = p.row.original

          return (
            <UserDisplay
              primaryText={`${firstName} ${lastName}`}
              secondaryText={email}
              avatar={{ ...getAvatarPropsFromBaseUserInfo({ firstName, lastName, avatarColor, avatar }) }}
            />
          )
        },
      } as Column<LegacyUserGroupMember> & { label: string },
      {
        id: 'assignedCourses',
        label: t('admin.analytics.courses'),
        Header: t('admin.analytics.courses'),
        width: '100px',
        Cell: ({ row }: CellProps<LegacyUserGroupMember>) => (
          <InheritedText>{row.original.numAssignedCourses.toString()}</InheritedText>
        ),
      },
      {
        id: 'progress',
        label: t('admin.analytics.table.progress'),
        Header: (
          <>
            {t('admin.analytics.table.progress')}
            <InfoIcon title={t('manage.users.tooltip.total-completion')} />
          </>
        ),
        Cell: ({ row }: CellProps<LegacyUserGroupMember>) => (
          <InheritedText>{formatPercentage(row.original.progress)}</InheritedText>
        ),
      },
      {
        id: 'riskScore',
        label: t('table.status'),
        Header: t('table.status'),
        width: '150px',
        Cell: ({ row }: CellProps<LegacyUserGroupMember>) => (
          <Status progress={row.original.progress} riskScore={row.original.riskScore} />
        ),
      },
      {
        id: 'access_level',
        label: t('table.role'),
        Header: t('table.role'),
        Cell: ({ row }: CellProps<LegacyUserGroupMember>) => (
          <>{t(getAccessLevelTranslationKey(row.original.userInfo.accessLevel))}</>
        ),
      },
      // {
      //   id: 'status',
      //   Header: t('admin.author.status'),
      //   width: '15%',
      //   Cell: ({ row }: SanaCellProps<LegacyUserGroupMember>) =>
      //     t(getUserStatusTranslationKey(row.original.userInfo.status)),
      // },
    ],
    [t]
  )

  const userTableColumns: Column<LegacyUserGroupMember>[] = useMemo(
    () => [
      ...defaultTableColumns,
      ...members.customUserAttributesMetadata.map((attr): Column<LegacyUserGroupMember> => {
        return {
          id: attr.key,
          accessor: row => row.customUserAttributes[attr.key],
          width: '250px',
          Header: attr.label,
          Cell: ({ row }: CellProps<LegacyUserGroupMember>) => (
            <InheritedText>{(row.original.customUserAttributes[attr.key] ?? '').toString()}</InheritedText>
          ),
        }
      }),
      {
        id: 'actions',
        Cell: ({ row }: CellProps<LegacyUserGroupMember>) => (
          <MenuContainer onClick={e => e.stopPropagation()}>
            <Menu
              onTriggerClick={() => setFocusedUserId(row.original.userInfo.userId)}
              onClose={() => setFocusedUserId(undefined)}
            >
              <RouterLink href={`/manage/users/${row.original.userInfo.userId}`}>
                <MUIMenuItem>
                  <Text size='small' bold>
                    {t('manage.view-details')}
                  </Text>
                </MUIMenuItem>
              </RouterLink>

              {canEditMembers && (
                <MUIMenuItem
                  onClick={() => {
                    onRemove([row.original.userInfo.userId])
                  }}
                >
                  <Text size='small' color='redBright'>
                    {t('admin.remove')}
                  </Text>
                </MUIMenuItem>
              )}
            </Menu>
          </MenuContainer>
        ),
        width: '10%',
      },
    ],
    [canEditMembers, defaultTableColumns, members.customUserAttributesMetadata, onRemove, t]
  )

  const tableInstance = useTable<LegacyUserGroupMember>(
    {
      columns: userTableColumns,
      data: members.data.map(({ userInfo, ...rest }) => ({
        ...rest,
        userInfo: { ...userInfo, accessLevel: asNonNullable(members.accessLevels[userInfo.userId]) },
      })),
      manualSortBy: true,
      autoResetSelectedRows: false,
      autoResetHiddenColumns: false,
    },
    useSortBy,
    useRowSelect
  )

  const { columnSelection, setColumnSelection, hiddenColumns } = useManagedColumns({
    defaultColumns: useMemo(
      () => defaultTableColumns.flatMap(({ id }) => (id === undefined ? [] : [id])),
      [defaultTableColumns]
    ),
    optionalColumns: useMemo(
      () => members.customUserAttributesMetadata.map(attr => attr.key),
      [members.customUserAttributesMetadata]
    ),
    tableInstance,
  })

  const columnSelections = useMemo(
    (): Selections =>
      [
        {
          groupName: t('manage.table.columns.menu.group.internal'),
          items: defaultTableColumns.flatMap(({ id, label }) => (id === undefined ? [] : [{ id, label }])),
        },
        {
          groupName: t('manage.table.columns.menu.group.custom'),
          items: members.customUserAttributesMetadata.map(attr => ({ id: attr.key, label: attr.label })),
        },
      ].filter(({ items }) => items.length > 0),
    [defaultTableColumns, members.customUserAttributesMetadata, t]
  )

  // const selectedUserIds = tableInstance.selectedFlatRows.map(r => r.original.userInfo.userId)

  useEffect(() => {
    if (members.next === undefined || members.isLoading || !shouldLoadMore) return
    void fetchAssignedUsers(
      groupId,
      {
        next: members.next,
        query: debouncedQuery,
      },
      {}
    )
  }, [
    members.data,
    members.next,
    members.isLoading,
    groupId,
    fetchAssignedUsers,
    shouldLoadMore,
    debouncedQuery,
  ])

  useEffect(() => {
    void fetchAssignedUsers(groupId, { query: debouncedQuery }, { reset: true })
  }, [groupId, fetchAssignedUsers, debouncedQuery])

  return (
    <SmallTableWrapper
      title={t('table.members')}
      totalCount={members.totalMembersInGroup}
      withSearch={true}
      query={liveQuery}
      setQuery={setQuery}
      rightAddon={
        <View gap='8' alignItems='center' justifyContent='center'>
          {canEditMembers && (
            <TableButton icon='face--add' onClick={onAddUsers}>
              {t('admin.add-users')}
            </TableButton>
          )}
          {onShowHeatmap !== undefined && (
            <TableButton icon='trend--up' onClick={onShowHeatmap}>
              {t('manage.heatmap.title')}
            </TableButton>
          )}
          <View marginLeft='8'>
            <ColumnSelector
              selected={columnSelection}
              onUpdate={setColumnSelection}
              selections={columnSelections}
            />
          </View>
          <View gap='none'>
            <ExportCSVButton
              fetchCsvData={() =>
                fetchCsvData({
                  groupId: groupId,
                  customUserAttributesMetadata: members.customUserAttributesMetadata.filter(
                    attr => !hiddenColumns.has(attr.key)
                  ),
                })
              }
              filename={t('admin.organization.users.users')}
            />
          </View>
        </View>
      }
    >
      {isEmpty ? (
        canEditMembers ? (
          <Empty
            iconId='user--add'
            title={t('manage.groups.user-empty-title')}
            body={t('manage.user-groups-user-empty-message')}
            actionLabel={t('manage.manage-users')}
            onClick={onAddUsers}
          />
        ) : (
          <Empty
            iconId='user'
            title={t('manage.groups.user-empty-readonly-title')}
            body={t('manage.groups.user-empty-readonly-body')}
          />
        )
      ) : (
        <>
          <SmallTableScrollContainer scrollToTopDeps={[liveQuery]}>
            <Table
              tableInstance={tableInstance}
              getRowProps={row => ({
                onClick: () => {
                  void getGlobalRouter().navigate({ to: `/manage/users/${row.original.userInfo.userId}` })
                },
                isFocused: row.original.userInfo.userId === focusedUserId,
              })}
              small
            />
            {members.next !== undefined && (
              <InfiniteScrollMessage
                padding='medium'
                ref={inViewRef}
                text={t('manage.users.table-loading')}
                showSanaLogo
              />
            )}
            {tableInstance.rows.length === 0 && (
              <InfiniteScrollMessage padding='medium' text={t('manage.users.no-results')} />
            )}
          </SmallTableScrollContainer>
          <Spacer size='small' />
        </>
      )}
    </SmallTableWrapper>
  )
}
