import _ from 'lodash'
import { DateTime } from 'luxon'
import React from 'react'
import { postWithUserErrorException } from 'sierra-client/state/api'
import { store } from 'sierra-client/state/store'
import { CreateContentId } from 'sierra-domain/api/nano-id'
import { AssessmentStatus } from 'sierra-domain/api/strategy-v2'
import { ScopedFileId } from 'sierra-domain/collaboration/types'
import { FileId } from 'sierra-domain/flexible-content/identifiers'
import {
  XRealtimeStrategySelfPacedContentAssessmentGetAssessmentResponses,
  XRealtimeStrategySelfPacedContentAssessmentStart,
} from 'sierra-domain/routes'
import { assert } from 'sierra-domain/utils'

export const evaluatePassingCriteria = ({
  passingCriteria,
  questionCount,
}: {
  passingCriteria: number
  questionCount: number
}): number => Math.ceil(passingCriteria * questionCount)

type AssessmentQuestions = {
  passingCriteria: number
  questionCount: number
  questionExerciseIds: string[]
  fileId: FileId
  contentId: CreateContentId
}

export type AssessmentState =
  | ({
      timesUp?: boolean
      status: 'successful' | 'failed'
      numberOfCorrectQuestions: number
      sessionId: string
    } & AssessmentQuestions)
  | ({
      status: 'start'
    } & AssessmentQuestions)
  | ({
      status: 'during'
      currentIndex: number
      sessionId: string
      endTime: DateTime | undefined
    } & AssessmentQuestions)

export const Assessments = {
  start: async (
    newAssessment: boolean,
    state: AssessmentState,
    timeLimit?: number
  ): Promise<AssessmentState> => {
    const { sessionId, answeredQuestions, startTime } = await postWithUserErrorException(
      XRealtimeStrategySelfPacedContentAssessmentStart,
      {
        contentId: state.contentId,
        fileId: ScopedFileId.extractId(state.fileId),
        newAssessment: newAssessment,
      },
      store.dispatch
    )

    const startTimeString = startTime?.toISOString()
    const startTimeAsIso = startTimeString !== undefined ? DateTime.fromISO(startTimeString) : undefined

    const numberOfAnsweredQuestions = _.intersection(state.questionExerciseIds, answeredQuestions).length

    const draftState: AssessmentState = {
      ...state,
      status: 'during',
      currentIndex: numberOfAnsweredQuestions - 1,
      sessionId,
      endTime:
        startTimeAsIso !== undefined && timeLimit !== undefined
          ? startTimeAsIso.plus({ minutes: timeLimit })
          : undefined,
    }

    return Assessments.next(draftState)
  },

  continue: (state: AssessmentState, sessionId: string, atIndex: number): AssessmentState => {
    return {
      ...state,
      status: 'during',
      currentIndex: atIndex,
      sessionId: sessionId,
      endTime: state.status === 'during' ? state.endTime : undefined,
    }
  },

  evaluate: async (state: AssessmentState): Promise<AssessmentState> => {
    if (state.status !== 'during') return state

    const assessmentResponses = await postWithUserErrorException(
      XRealtimeStrategySelfPacedContentAssessmentGetAssessmentResponses,
      {
        contentId: state.contentId,
        fileId: state.fileId,
        questionExerciseIds: state.questionExerciseIds, // list of nanoid12
        sessionId: state.sessionId,
      },
      store.dispatch
    )

    const numberOfCorrectQuestions = assessmentResponses.questionCardInteractions.filter(
      it => it.interaction.correct
    ).length

    const numberOfQuestionsToGetRight = evaluatePassingCriteria({
      passingCriteria: state.passingCriteria,
      questionCount: state.questionCount,
    })

    return {
      ...state,
      numberOfCorrectQuestions: numberOfCorrectQuestions,
      status: numberOfCorrectQuestions >= numberOfQuestionsToGetRight ? 'successful' : 'failed',
    }
  },

  next: (state: AssessmentState): AssessmentState => {
    if (state.status === 'during' && state.currentIndex < state.questionCount - 1) {
      return { ...state, currentIndex: state.currentIndex + 1 }
    } else {
      return state
    }
  },

  previous: (state: AssessmentState): AssessmentState => {
    if (state.status === 'during' && state.currentIndex > 0) {
      return { ...state, currentIndex: state.currentIndex - 1 }
    } else {
      return state
    }
  },

  goTo: (state: AssessmentState, index: number): AssessmentState => {
    if (state.status === 'during' && -1 < index && index < state.questionCount) {
      return { ...state, currentIndex: index }
    } else {
      return state
    }
  },

  timesUp: async (state: AssessmentState): Promise<AssessmentState> => {
    if (state.status !== 'during') return state

    const assessmentResponses = await postWithUserErrorException(
      XRealtimeStrategySelfPacedContentAssessmentGetAssessmentResponses,
      {
        contentId: state.contentId,
        fileId: state.fileId,
        questionExerciseIds: state.questionExerciseIds, // list of nanoid12
        sessionId: state.sessionId,
      },
      store.dispatch
    )

    const numberOfCorrectQuestions = assessmentResponses.questionCardInteractions.filter(
      it => it.interaction.correct
    ).length

    const numberOfQuestionsToGetRight = evaluatePassingCriteria({
      passingCriteria: state.passingCriteria,
      questionCount: state.questionCount,
    })

    return {
      ...state,
      numberOfCorrectQuestions: numberOfCorrectQuestions,
      status: numberOfCorrectQuestions >= numberOfQuestionsToGetRight ? 'successful' : 'failed',
      timesUp: true,
    }
  },

  retry: (state: AssessmentState): AssessmentState => {
    assert(state.status === 'failed', "[Assessment] Attempted retry on an assessment which hasn't failed")

    // todo: Handle this better. Not sure if we really need to reset this here.
    const newState = { ...state, numberOfCorrectQuestions: 0 }
    return { ...newState, status: 'start' }
  },
}

export type AssessmentsContext = {
  state: AssessmentState
  setState: (newState: AssessmentState) => void
  status: AssessmentStatus | undefined
}

const AssessmentContext = React.createContext<AssessmentsContext | undefined>(undefined)

export const AssessmentContextProvider = AssessmentContext.Provider

export function useAssessmentContext(): AssessmentsContext {
  const context = React.useContext(AssessmentContext)

  if (context === undefined)
    throw new Error('useAssessmentsContext must be used within a AssessmentsContextProvider')

  return context
}

/*
 * Used to check in certain cases if we're inside an assessment card.
 * Use at your own risk.
 * */
export function useAssessmentContextUnsafe(): AssessmentsContext | undefined {
  const context = React.useContext(AssessmentContext)
  return context
}
