import { DateTime, Duration } from 'luxon'
import React, { useMemo } from 'react'
import { WithPosition } from 'sierra-client/features/skills'
import { useResolveAsset } from 'sierra-client/hooks/use-resolve-asset'
import { useTranslation } from 'sierra-client/hooks/use-translation'
import { useSelector } from 'sierra-client/state/hooks'
import { selectUser } from 'sierra-client/state/user/user-selector'
import { useUser } from 'sierra-client/state/users/hooks'
import { getAvatarImage } from 'sierra-client/utils/avatar-img'
import { useGetLearnerProgram } from 'sierra-client/views/learner/program/use-get-learner-program'
import { getContinueURLForStep } from 'sierra-client/views/learner/program/utils/utils'
import { LinkButton } from 'sierra-client/views/workspace/learn/action-button'
import { ProgramLeaderboardResponse } from 'sierra-domain/api/insights'
import { ProgramId, UserId } from 'sierra-domain/api/uuid'
import { assertIsNonNullable } from 'sierra-domain/utils'
import { Icon, RoundAvatar } from 'sierra-ui/components'
import { Heading, Spacer, Text, View } from 'sierra-ui/primitives'
import { token } from 'sierra-ui/theming'
import styled, { css } from 'styled-components'

const UserContainer = styled(View)`
  padding-block: 20px;
`

const OtherUsersContainer = styled(View)`
  padding: 8px 44px;
`

const LeaderboardList = styled(View)`
  ${UserContainer}:first-of-type {
    border-top: 1px solid ${token('border/default')};
  }

  ${UserContainer} + ${UserContainer} {
    border-top: 1px solid ${token('border/default')};
  }

  ${UserContainer} + ${OtherUsersContainer} {
    border-top: 2px dotted ${token('border/strong')};
  }

  ${OtherUsersContainer} + ${UserContainer} {
    border-top: 2px dotted ${token('border/strong')};
  }
`

const LeftSide = styled(View)<{ $src: string | undefined }>`
  min-width: 350px;
  flex: 1;

  background: ${token('button/background')};
  color: ${token('button/foreground')};

  /* Add a gradient to the background image to make it easier to read the text (if there is a contrast) */
  ${p =>
    p.$src !== undefined &&
    css`
      /*background with p.$src as image overlaid with token('button/background') so that text is more visible,   */
      background: linear-gradient(
          ${token('button/background')(p).opacity(0.5)},
          ${token('button/background')(p).opacity(0.5)}
        ),
        url(${p.$src});
    `}

  padding: 32px;
  padding-top: 28px;

  background-size: cover;
`

const RightSide = styled(View)`
  flex: 2.25;
  height: 100%;

  padding: 28px 32px 26px 32px;
`

const Container = styled(View)`
  border: 1px solid ${token('border/default')};
  width: 100%;
  border-radius: 28px;
  overflow: hidden;
  align-items: stretch;

  @media screen and (max-width: 1000px) {
    ${LeftSide} {
      display: none;
    }
  }
`

const LeaderboardResetContainer = styled(View)`
  padding: 180px;
  text-align: center;
  gap: 24px;
`

const RightAlignView = styled(View)`
  text-align: right;
`

const ProgramTitle = styled(Heading).attrs({ size: 'h3', bold: true, color: 'button/foreground' })``

const ProgramDescription = styled(Text).attrs({ size: 'small', color: 'button/foreground' })``

const ProgramLengthIcon = styled(Icon).attrs({ iconId: 'path', color: 'button/foreground' })``

const ProgramLength = styled(Text).attrs({ size: 'small', color: 'button/foreground' })``

const AlignSelfCenter = styled(View)`
  align-self: center;
`

const Avatar: React.FC<{ userId: UserId }> = ({ userId }) => {
  const user = useUser(userId)

  if (user?.status !== 'loaded') {
    return null
  }

  return (
    <RoundAvatar
      size='medium'
      firstName={user.firstName}
      lastName={user.lastName}
      src={getAvatarImage(user.uuid, user.avatar)}
      color={user.avatarColor}
    />
  )
}

type ProgramLeaderboardUser = { type: 'user'; position: number } & ProgramLeaderboardResponse['users'][number]
type LeaderboardRow = ProgramLeaderboardUser | { type: 'other-users'; id: string; count: number }

export const ProgramLeaderboard: React.FC<{
  data: ProgramLeaderboardResponse
}> = ({ data }) => {
  const { t } = useTranslation()

  const enrollmentQuery = useGetLearnerProgram({ programId: data.program.id })
  const enrollment = enrollmentQuery.data?.me.enrollment
  const nextStepMaybe = enrollment?.nextStep ?? undefined
  const nextStep =
    nextStepMaybe !== undefined
      ? enrollment?.steps.find(step => {
          if (
            step.__typename === 'UserProgramCourseStep' &&
            nextStepMaybe.__typename === 'UserProgramCourseStep'
          ) {
            return step.courseId === nextStepMaybe.courseId
          } else if (
            step.__typename === 'UserProgramPathStep' &&
            nextStepMaybe.__typename === 'UserProgramPathStep'
          ) {
            return step.pathId === nextStepMaybe.pathId
          } else {
            return false
          }
        })
      : undefined

  const continueHref =
    nextStep !== undefined
      ? getContinueURLForStep({
          step: nextStep,
          upcomingSelfEnrollmentLiveSessions: undefined,
          programId: data.program.id,
          parentPathId: undefined,
        })
      : undefined

  const currentUser = useSelector(selectUser)

  const leaderboardRows: LeaderboardRow[] = useMemo(() => {
    const users: ProgramLeaderboardUser[] = data.users.map((user, index) => ({
      type: 'user',
      position: index + 1,
      ...user,
    }))

    const indexOfCurrentUser = users.findIndex(user => user.id === currentUser?.uuid)

    const currentUserRow = users[indexOfCurrentUser]

    // We allow up to 5 users in the leaderboard (from the figma designs)
    const numberOfRows = 5
    if (indexOfCurrentUser === -1) {
      return users.slice(0, numberOfRows)
    }

    assertIsNonNullable(currentUserRow)

    if (indexOfCurrentUser < numberOfRows) {
      // Current user is in the top `numberOfRows` users, so no need to show the `n other users` row
      return users.slice(0, numberOfRows)
    }
    if (indexOfCurrentUser === numberOfRows) {
      // The user is right after the `numberOfRows`-th user, so no need to show the `n other users` row
      return [...users.slice(0, numberOfRows), currentUserRow]
    }

    const numberOfOtherUsers = indexOfCurrentUser + 1 - numberOfRows
    assertIsNonNullable(currentUserRow)
    const list: LeaderboardRow[] = [
      ...users.slice(0, numberOfRows),
      { type: 'other-users', id: 'other-users-key', count: numberOfOtherUsers },
      currentUserRow,
    ]
    return list
  }, [currentUser, data])

  const resolvedImageSrc = useResolveAsset({
    image: data.program.image,
    assetContext: { type: 'program', programId: ProgramId.parse(data.program.id) },
    size: 'course',
  })
  const imageSrc = data.program.image !== undefined ? resolvedImageSrc : undefined

  return (
    <Container>
      <LeftSide grow direction='column' $src={imageSrc}>
        <View direction='column' gap='12'>
          <View gap='8'>
            <ProgramLengthIcon />
            <ProgramLength>
              {t('program-overview.details.n-step-program', { count: data.program.nrSteps })}
            </ProgramLength>
          </View>

          <Spacer size='4' />
          <ProgramTitle>{data.program.name}</ProgramTitle>
          <ProgramDescription>{data.program.description}</ProgramDescription>
        </View>

        <View grow />

        <View>
          {/* TODO(kondo): Include the API data required to show these buttons. For example,
                the play button needs to know the next step in the program based on the user's progress,
                and you need to define what the "view details" button should do */}
          {continueHref !== null && continueHref !== undefined ? (
            <LinkButton icon='play--filled' href={continueHref} text={t('dictionary.continue')} />
          ) : (
            <LinkButton
              icon='play--filled'
              href={`/program/${data.program.id}`}
              text={t('dictionary.start')}
            />
          )}

          <AlignSelfCenter>
            <LinkButton href={`/program/${data.program.id}`} text='View details' />
          </AlignSelfCenter>
        </View>
      </LeftSide>

      <RightSide grow direction='column'>
        <View justifyContent='space-between'>
          <View direction='column' gap='4'>
            <Text size='regular' bold>
              {t('program-leaderboard.title')}
            </Text>
            <Text size='regular' color='foreground/muted' bold>
              {data.program.name}
            </Text>
          </View>

          <RightAlignView direction='column' gap='4'>
            <Text size='regular' bold>
              {t('program-leaderboard.days-left', {
                count: Math.ceil(DateTime.fromISO(data.resetDate).diffNow().as('days')),
              })}
            </Text>
            <Text size='regular' color='foreground/muted' bold>
              {t('program-leaderboard.until-reset')}
            </Text>
          </RightAlignView>
        </View>

        <Spacer size='16' />

        {leaderboardRows.length === 0 ? (
          <View alignItems='center' justifyContent='center'>
            <LeaderboardResetContainer direction='column' justifyContent='center'>
              <View direction='column' gap='4'>
                <Text size='small' color='foreground/secondary' bold>
                  {t('program-leaderboard.reset-notice')}
                </Text>
                {/* TODO(kondo): When the buttton below is defined this text can be added
                    <Text size='small' color='foreground/muted'>
                      Start the program to be the first to get here
                    </Text>
                    */}
              </View>

              {/* TODO(kondo):
                    Define what this buttons should do and include that in the API response
                      <Button variant='secondary' icon='play--filled' onClick={() => {}}>
                        Start program
                      </Button>
                  */}
            </LeaderboardResetContainer>
          </View>
        ) : (
          <LeaderboardList grow direction='column' gap='none'>
            {leaderboardRows.map(row =>
              row.type === 'other-users' ? (
                <OtherUsersContainer key={row.id}>
                  <Text size='small' color='foreground/muted' bold>
                    {t('dictionary.n-others', { count: row.count })}
                  </Text>
                </OtherUsersContainer>
              ) : (
                <UserContainer grow key={row.id} justifyContent='space-between'>
                  <View gap='12'>
                    <WithPosition position={row.position}>
                      <Avatar userId={row.id} />
                    </WithPosition>
                    <View direction='column' gap='2'>
                      <Text size='small' bold>
                        {row.id === currentUser?.uuid ? t('dictionary.you') : row.displayName}
                      </Text>
                      <Text size='small' color='foreground/secondary'>
                        {row.division}
                      </Text>
                    </View>
                  </View>

                  <View gap='24'>
                    <View gap='8'>
                      <Icon iconId='time' color='foreground/muted' />
                      <Text color='foreground/muted' bold>
                        {Duration.fromObject({
                          // Round durations to the nearest second
                          seconds: Math.round(Duration.fromISO(row.timeSpent).as('seconds')),
                        })
                          .shiftTo('hours', 'minutes', 'seconds')
                          .toHuman({ unitDisplay: 'short' })}
                      </Text>
                    </View>
                    {row.correctRate !== undefined && (
                      <View gap='8'>
                        <Icon iconId='checkbox--checkmark--filled' color='foreground/muted' />
                        <Text color='foreground/secondary' bold>
                          {(row.correctRate * 100).toFixed(0)}%
                        </Text>
                      </View>
                    )}
                  </View>
                </UserContainer>
              )
            )}
          </LeaderboardList>
        )}
      </RightSide>
    </Container>
  )
}
