import { ForwardRefExoticComponent, RefAttributes } from 'react'
import { GoogleMeetOauth } from 'sierra-client/components/sana-now-integration-oauth/google-meet-oauth'
import { MicrosoftTeamsOauth } from 'sierra-client/components/sana-now-integration-oauth/microsoft-teams-oauth'
import {
  OauthComponentProps,
  ReactSimpleOauth2LoginRef,
} from 'sierra-client/components/sana-now-integration-oauth/shared'
import { ZoomOauth } from 'sierra-client/components/sana-now-integration-oauth/zoom-oauth'

export type Platform = 'meet' | 'teams' | 'zoom'

export const platformOauthComponent = (
  platform: Platform
): ForwardRefExoticComponent<OauthComponentProps & RefAttributes<ReactSimpleOauth2LoginRef>> => {
  switch (platform) {
    case 'meet':
      return GoogleMeetOauth
    case 'teams':
      return MicrosoftTeamsOauth
    case 'zoom':
      return ZoomOauth
  }
}
