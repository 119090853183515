import { useEffect } from 'react'

export const ReleaseMetadata: React.FC = () => {
  useEffect(() => {
    const hash = process.env.GITHUB_SHA

    if (hash === undefined) return

    const commitUrl = `https://github.com/sanalabs/sierra-platform/commit/${hash}`

    /* eslint-disable no-console */
    console.groupCollapsed('Release Metadata ')
    console.log('Commit:', hash)
    console.log('URL:', commitUrl)
    console.groupEnd()
    /* eslint-enable no-console */
  }, [])

  return null
}
