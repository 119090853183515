export type CancelablePromise<T> = {
  promise: Promise<T>
  cancel: () => void
}

export const makeCancelablePromise = <T,>(promise: Promise<T>): CancelablePromise<T> => {
  let isCanceled = false

  const wrappedPromise = new Promise<T>((resolve, reject) => {
    promise.then(
      value => {
        if (!isCanceled) {
          resolve(value)
        }
      },
      error => {
        if (!isCanceled) {
          reject(error)
        }
      }
    )
  })

  return {
    promise: wrappedPromise,
    cancel() {
      isCanceled = true
    },
  }
}
