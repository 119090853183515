import { useEffect, useRef } from 'react'
import { token } from 'sierra-ui/theming'
import { fonts } from 'sierra-ui/theming/fonts'
// import { useShortcutMenuDispatch, useShortcutMenuState } from 'sierra-client/components/shortcut-menu/context'
import { FCC } from 'sierra-client/types'
import { color } from 'sierra-ui/color'
import { Icon, IconId } from 'sierra-ui/components'
import { LoadingSpinner, ScrollView, Text } from 'sierra-ui/primitives'
import styled, { css, useTheme } from 'styled-components'

const SearchBarWrapper = styled.div`
  position: relative;
  width: 100%;
  color: ${p => p.theme.text.default};
`

const InputContainer = styled.div`
  position: relative;
  width: 100%;
  color: ${p => p.theme.text.default};
`

const SearchIcon = styled(Icon)`
  position: absolute;
  top: 50%;
  transform: translateY(-50%);
  left: 14px;
`

export const SearchBarContainer = styled.div`
  padding: 1rem 1rem;
`

const SearchBarInput = styled.input`
  ${fonts.body.regular};
  border: 1px solid ${token('border/strong')};
  max-width: 100%;
  width: 100%;
  height: 48px;
  padding-left: 2.375rem;
  border-radius: ${p => p.theme.borderRadius['size-10']};
  box-shadow: 0px 0px 0px 0px rgba(0, 0, 0, 0.05);
  transition: all 0.1s cubic-bezier(0.25, 0.1, 0.25, 1);
  background: ${token('surface/soft')};
  color: ${token('foreground/primary')};

  &:placeholder {
    color: ${token('foreground/muted')};
  }

  &:hover {
    border: 1px solid ${token('border/default')};
  }

  &:focus {
    border: 1px solid ${token('border/default')};
  }
`

function isMetaKey({ ctrlKey, metaKey }: { ctrlKey: boolean; metaKey: boolean }): boolean {
  const isCtrl = ctrlKey
  const isCmd = metaKey
  return isCtrl || isCmd
}

export const SearchBar: React.FC<{
  value: string
  placeholder?: string
  label?: string
  onChange: (value: string) => void
  onIndexChanged: (change: 'decrement' | 'increment') => void
  onResultSelected: () => void
}> = ({ value, placeholder, label, onChange, onIndexChanged, onResultSelected }) => {
  const ref = useRef<HTMLInputElement | null>(null)

  // Focus the input when it renders
  useEffect(() => {
    const { current } = ref
    if (!current) return

    ref.current?.focus()
  }, [ref])

  return (
    <SearchBarWrapper>
      {label !== undefined && <></>}
      <InputContainer>
        <SearchIcon iconId='search' color='foreground/primary' />
        <SearchBarInput
          type='text'
          name='search'
          ref={ref}
          placeholder={placeholder ?? ''}
          value={value}
          autoCapitalize={'none'}
          autoCorrect={'off'}
          autoComplete={'off'}
          onChange={e => onChange(e.target.value)}
          onKeyDown={e => {
            if (e.key === 'ArrowUp' || (isMetaKey(e) && e.key === 'k')) {
              e.preventDefault()
              onIndexChanged('decrement')
            }

            if (e.key === 'ArrowDown' || (isMetaKey(e) && e.key === 'j')) {
              e.preventDefault()
              onIndexChanged('increment')
            }

            if (e.key === 'Enter') {
              e.preventDefault()
              onResultSelected()
            }
          }}
        />
      </InputContainer>
    </SearchBarWrapper>
  )
}

const SearchResultWrapper = styled.div<{ $selected: boolean; $disabled?: boolean }>`
  margin-left: 1.5rem;

  transition: background-color 50ms cubic-bezier(0.25, 0.1, 0.25, 1);

  &:last-of-type {
    margin-bottom: 1.5rem;
  }

  cursor: pointer;

  ${p =>
    p.$disabled === true &&
    css`
      cursor: not-allowed !important;
      opacity: 0.6;
    `}
`

const StyledIcon = styled(Icon)<{ backgroundColor?: string }>`
  color: ${token('foreground/primary').shift(0.2)};
  height: 1rem;
  width: 1rem;
  margin-left: 0.8rem;
`

const StyledIconPlaceholder = styled.span`
  margin-left: 0.5rem;
`

const SearchResultInner = styled.div<{ $selected: boolean; $disabled?: boolean }>`
  display: flex;
  align-items: center;
  width: 100%;

  padding-block: 1rem;
  padding-inline-end: 1rem;
  color: ${token('foreground/primary')};
  border-radius: ${p => p.theme.borderRadius['size-6']};
  background-color: ${p => token('surface/default')(p).shift(p.$selected ? 0.05 : 0)};

  ${fonts.body.small};
  gap: 0.5rem;

  &:hover {
    background-color: ${token('surface/soft')};
  }

  ${p =>
    p.$disabled === true &&
    css`
      pointer-events: none;
    `}
`

export const SearchResult: FCC<{
  $selected: boolean
  $disabled?: boolean
  iconId?: IconId
  onClick?: React.MouseEventHandler<HTMLDivElement> | undefined
  className?: string
}> = ({ children, $selected, $disabled, iconId, className, onClick }) => {
  return (
    <SearchResultWrapper
      className={className}
      onClick={onClick}
      $selected={$selected}
      $disabled={$disabled}
      ref={current => {
        if ($selected && current) current.scrollIntoView({ block: 'nearest' })
      }}
    >
      <SearchResultInner $selected={$selected} $disabled={$disabled}>
        {iconId ? (
          <StyledIcon iconId={iconId} color='currentColor' backgroundColor={undefined} />
        ) : (
          <StyledIconPlaceholder />
        )}
        {children}
      </SearchResultInner>
    </SearchResultWrapper>
  )
}

const SearchGroupWrapper = styled(Text).attrs({ bold: true, size: 'small' })`
  color: ${token('foreground/primary').opacity(0.25)};
  margin: 0.5rem 1.5rem;

  ${SearchResultWrapper} + & {
    padding-top: 1rem;
  }

  &:first-child {
    margin-top: 1.5rem;
  }
`

export const SearchGroup: FCC<{ $selected: boolean }> = ({ $selected, children }) => {
  return (
    <SearchGroupWrapper
      color='LEGACY_DEFAULT_TEXT_COLOR_REPLACE_ASAP'
      ref={current => {
        if ($selected && current) current.scrollIntoView({ block: 'nearest' })
      }}
    >
      {children}
    </SearchGroupWrapper>
  )
}

export const SearchResults = styled(ScrollView)`
  gap: 0.25rem !important;
  width: 720px;
  height: 400px;
`

const LoadingSpinnerWrapper = styled.div`
  position: absolute;
  inset: 0;
  margin: auto;
  height: fit-content;
`

export const SearchContainer = styled.div`
  position: relative;
  height: 100%;
`

export const SearchLoading: React.FC = () => {
  const loadingColor: 'black' | 'white' =
    color(useTheme().home.textColor).brightness() > 0.75 ? 'white' : 'black'

  return (
    <LoadingSpinnerWrapper>
      <LoadingSpinner color={loadingColor} />
    </LoadingSpinnerWrapper>
  )
}
