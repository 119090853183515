import React from 'react'
import { useTranslation } from 'sierra-client/hooks/use-translation'
import { CertificateIssuedByRep } from 'sierra-client/lib/tabular/datatype/internal/reps/certificate-issued-by-rep'
import { useIssuedCertificateSupportingFile } from 'sierra-client/views/manage/certificates/use-issued-certificates'
import { RoundAvatarFromAvatar } from 'sierra-client/views/manage/components/round-avatar-from-avatar'
import { Icon, Popover } from 'sierra-ui/components'
import { LoadingSpinner, Text, View } from 'sierra-ui/primitives'
import { token } from 'sierra-ui/theming'
import { isDefined } from 'sierra-ui/utils/is-defined'
import styled from 'styled-components'

const FilePillAnchor = styled.a`
  display: flex;
  align-items: center;
  background-color: ${token('surface/soft')};
  padding: 4px;
  gap: 4px;
  border-radius: 6px;
  cursor: pointer;
  transition: background-color 150ms cubic-bezier(0.25, 0.1, 0.25, 1);

  &:hover {
    background: ${token('surface/soft')};
  }
`

// We can't use the `downloadFile` function because we need to handle URLs here that might not be whitelisted in the CSP.
// Maybe they should be, but for now we'll use a link that we open in a separate tab.
const FilePillLink: React.FC<{ fileUrl: string; children: string }> = ({ fileUrl, children }) => (
  <FilePillAnchor href={fileUrl} target='_blank' rel='noopener noreferrer'>
    <Icon iconId='document--blank' size='size-16' />
    <Text size='micro' bold>
      {children}
    </Text>
  </FilePillAnchor>
)

const PopoverContentsContainer = styled(View).attrs({
  background: 'surface/default',
  padding: '16',
  radius: 'size-10',
  borderColor: 'border/strong',
  alignItems: 'flex-start',
  justifyContent: 'flex-start',
})`
  box-shadow: 0px 8px 16px rgba(0, 0, 0, 0.08);
`

const SupportingFileUrl: React.FC<{ certificateId: string }> = ({ certificateId }) => {
  const { t } = useTranslation()
  const res = useIssuedCertificateSupportingFile(certificateId)

  if (res.isPending) {
    return (
      <View>
        <LoadingSpinner size='xsmall' />
        <Text>{t('dictionary.loading')}</Text>
      </View>
    )
  }

  // TODO: What should be done with error here?
  if (res.isError) {
    return null
  }

  if (!isDefined(res.data.issuedCertificate?.supportingFileUrl)) {
    return null
  }

  const supportingFileUrl = res.data.issuedCertificate.supportingFileUrl

  return (
    <FilePillLink fileUrl={supportingFileUrl}>
      {t('manage.certificates.view-supporting-document')}
    </FilePillLink>
  )
}

const CertificateIssuedByUserPopover: React.FC<{
  issuedByUser: Extract<CertificateIssuedByRep, { type: 'user' }>
}> = ({ issuedByUser }) => {
  const { t } = useTranslation()
  const { displayName, avatar, supportingNote, hasSupportingFile, id } = issuedByUser

  if (supportingNote === undefined && hasSupportingFile !== true) return null

  return (
    <div
      onClick={e => {
        // We need this so that the row doesn't intercept the Popover trigger click.
        e.stopPropagation()
      }}
    >
      <Popover
        renderTrigger={() => {
          return <Text>{t('manage.certificates.view-note')}</Text>
        }}
      >
        <PopoverContentsContainer>
          <RoundAvatarFromAvatar userId={id} size='small' avatar={avatar} />
          <View direction='column'>
            <Text bold>{displayName}</Text>
            {supportingNote !== undefined && <Text>{supportingNote}</Text>}
            {hasSupportingFile === true && <SupportingFileUrl certificateId={id} />}
          </View>
        </PopoverContentsContainer>
      </Popover>
    </div>
  )
}

export const CertificateIssuedBy: React.FC<{ issuedBy: CertificateIssuedByRep }> = ({ issuedBy }) => {
  return issuedBy.type === 'user' ? (
    <View direction='column' gap='none'>
      <Text>{issuedBy.displayName}</Text>
      <CertificateIssuedByUserPopover issuedByUser={issuedBy} />
    </View>
  ) : (
    <Text>{issuedBy.title}</Text>
  )
}
