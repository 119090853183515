import { AnimatePresence } from 'framer-motion'
import { FC, useCallback, useState } from 'react'
import { OrganizationLogo } from 'sierra-client/components/organization-logo'
import {
  CourseVisibilityDropdown,
  useCourseVisibilityDropdownAllowedItems,
} from 'sierra-client/components/sharing/course-permission-dropdown'
import { useLocalizedFormatters } from 'sierra-client/core/format'
import {
  Card,
  constructDistributionPatch,
  organizationComparator,
  SquareLogo,
  UnpublishConfirmationModal,
  useCourseDistributionSettingsMutation,
  useOrganizationCluster,
  useUnpublishDistributedCourseMutation,
} from 'sierra-client/features/multi-tenancy'
import { useTranslation } from 'sierra-client/hooks/use-translation'
import { typedInvalidateQuery, useCachedQuery, useTypedMutation } from 'sierra-client/state/api'
import {
  CoursePermissionSettingsContextProvider,
  useCoursePermissionSettings,
} from 'sierra-client/views/flexible-content/editor/course-permission-settings-context'
import { CourseVisibilityInOrg } from 'sierra-domain/api/author-v2'
import { CourseId } from 'sierra-domain/api/nano-id'
import { AccessibleOrganization } from 'sierra-domain/multi-tenancy'
import {
  XRealtimeAuthorGetCoursePublishState,
  XRealtimeAuthorUnpublishSelfPacedCourse,
  XRealtimeGetCourseDistributionPublishStates,
} from 'sierra-domain/routes'
import { isDefined } from 'sierra-domain/utils'
import { FormElement, Icon } from 'sierra-ui/components'
import { Text, View } from 'sierra-ui/primitives'
import { dotSeparator, useOnChanged } from 'sierra-ui/utils'
import styled from 'styled-components'

const UnpublishText = styled(Text).attrs({
  color: 'foreground/muted',
})`
  transition: all 150ms;
  &:hover {
    color: red;
    cursor: pointer;
  }
  button {
    all: unset;
    color: inherit;
  }
`

type Props = {
  courseId: CourseId
}

const CourseVisibilitySettingsInner: FC<Props> = ({ courseId }) => {
  const { formatTimeAgo } = useLocalizedFormatters()

  const organizationCluster = useOrganizationCluster()
  const isClusterMember = organizationCluster.loading === false && organizationCluster.cluster.length > 0
  const isClusterParent = organizationCluster.loading === false && organizationCluster.selfIsParent

  const distributionSettingsMutation = useCourseDistributionSettingsMutation()
  const unpublishDistributedCourseMutation = useUnpublishDistributedCourseMutation()
  const unpublishCourseMutation = useTypedMutation(XRealtimeAuthorUnpublishSelfPacedCourse, {
    onSettled: async () => {
      await typedInvalidateQuery(XRealtimeAuthorGetCoursePublishState, { contentId: courseId })
      await typedInvalidateQuery(XRealtimeGetCourseDistributionPublishStates, { courseId })
    },
  })

  const courseVisibilityOptions = useCourseVisibilityDropdownAllowedItems(courseId, { privateEnabled: false })

  const { coursePermissionSettings, fetchInitialCoursePermissionSettings, updateVisibilityInOrg } =
    useCoursePermissionSettings()
  const visibility =
    coursePermissionSettings.status === 'done' ? coursePermissionSettings.visibilityInOrg : null

  const publishStateQuery = useCachedQuery(XRealtimeGetCourseDistributionPublishStates, { courseId })
  const publishStates = (publishStateQuery.data?.publishState ?? []).sort((first, second) =>
    organizationComparator(first.organization, second.organization)
  )

  const [unpublishingFromOrganization, setUnpublishingFromOrganization] =
    useState<AccessibleOrganization | null>(null)

  useOnChanged((_, currentCourseId) => {
    void fetchInitialCoursePermissionSettings(currentCourseId)
  }, courseId)

  const onVisibilityChange = useCallback(
    (visibility: CourseVisibilityInOrg) => {
      void updateVisibilityInOrg(courseId, visibility)

      if (
        !organizationCluster.loading &&
        organizationCluster.selfIsParent === true &&
        visibility !== 'private'
      ) {
        const distributionPatches = organizationCluster.cluster
          .map(org => constructDistributionPatch(org, { visibility }))
          .filter(isDefined)

        distributionSettingsMutation.mutate({
          courseId,
          settings: distributionPatches,
        })
      }
    },
    [organizationCluster, distributionSettingsMutation, courseId, updateVisibilityInOrg]
  )

  const unpublishCourse = useCallback(() => {
    const organization = unpublishingFromOrganization

    if (organization === null) {
      return
    }

    if (organization.isClusterParent) {
      unpublishCourseMutation.mutate({ contentId: courseId })
    } else {
      return unpublishDistributedCourseMutation.mutate({
        namespaceId: organization.namespaceId,
        contentId: courseId,
      })
    }
  }, [courseId, unpublishCourseMutation, unpublishDistributedCourseMutation, unpublishingFromOrganization])

  const { t } = useTranslation()

  if (organizationCluster.loading || visibility === null) {
    return null
  }

  return (
    <View direction='column' gap='24'>
      <FormElement label={t('dictionary.visibility')}>
        <CourseVisibilityDropdown
          selectedVisibility={visibility}
          onSelect={onVisibilityChange}
          options={courseVisibilityOptions}
          disabled={isClusterMember && !isClusterParent}
        />
      </FormElement>

      {isClusterParent && (
        <>
          <View direction='column' gap='8' padding='2'>
            <AnimatePresence initial={false}>
              {publishStates.map(state => {
                const organization = state.organization

                return (
                  <Card key={organization.domain}>
                    <View grow gap='12'>
                      {isDefined(organization.squareLogoUrl) ? (
                        <SquareLogo src={organization.squareLogoUrl} />
                      ) : (
                        <OrganizationLogo orgName={organization.name} brandSettings={undefined} />
                      )}

                      <View direction='column' gap='none'>
                        <View>
                          <Text>{organization.name}</Text>
                          {organization.isClusterParent === true && (
                            <Icon iconId='building' color='foreground/muted' />
                          )}
                        </View>

                        <View gap='4'>
                          <Text color='foreground/muted'>
                            {t('manage.published-at', { date: formatTimeAgo(state.lastPublishedAt) })}
                          </Text>
                          <Text color='foreground/muted'>{dotSeparator}</Text>
                          <UnpublishText>
                            <button onClick={() => setUnpublishingFromOrganization(organization)}>
                              {t('admin.author.unpublish')}
                            </button>
                          </UnpublishText>
                        </View>
                      </View>
                    </View>
                  </Card>
                )
              })}
            </AnimatePresence>
          </View>

          <UnpublishConfirmationModal
            organization={unpublishingFromOrganization}
            onCancel={() => setUnpublishingFromOrganization(null)}
            onConfirm={() => unpublishCourse()}
          />
        </>
      )}
    </View>
  )
}

export const CourseVisibilitySettings: FC<Props> = props => {
  return (
    <CoursePermissionSettingsContextProvider>
      <CourseVisibilitySettingsInner {...props} />
    </CoursePermissionSettingsContextProvider>
  )
}
