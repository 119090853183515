import { t } from 'i18next'
import React, { useRef } from 'react'
import { CSVLink } from 'react-csv'
import { percentage } from 'sierra-client/core/format'
import { useTranslation } from 'sierra-client/hooks/use-translation'
import { TranslationLookup } from 'sierra-client/hooks/use-translation/types'
import { stringsColumn } from 'sierra-client/lib/tabular/column-definitions'
import { TabularToolbar } from 'sierra-client/lib/tabular/components/tabular-toolbar'
import { staticDataLoader, StaticLoaderSearchKeyBy } from 'sierra-client/lib/tabular/dataloader/static'
import { translatedLabel } from 'sierra-client/lib/tabular/datatype/label'
import {
  definition2Data,
  TableDataFromDefinition,
  TableDefinitionOf,
} from 'sierra-client/lib/tabular/datatype/tabledefinition'
import { TabularProviderFromTableAPI, useTabularContext } from 'sierra-client/lib/tabular/provider'
import { BasicTabularSimpleSize } from 'sierra-client/lib/tabular/provider/components/basic'
import { UseTableAPI, useTableAPI } from 'sierra-client/lib/tabular/use-table-api'
import { defaultMenuActionVirtualColumn, defaultSelectVirtualColumn } from 'sierra-client/lib/tabular/utils'
import { getGlobalRouter } from 'sierra-client/router'
import { AnimatedSearch } from 'sierra-client/views/manage/components/animated-search'
import { ActionButton } from 'sierra-client/views/manage/components/common'
import { getDueDateStringForGroup } from 'sierra-client/views/manage/components/due-date'
import { ExportCSVIconButton, getCsvFileName } from 'sierra-client/views/manage/components/export-csv'
import { parseEnrolledGroupToCsv } from 'sierra-client/views/manage/utils/content-enrollment-to-csv'
import { useGetManageProgramURL } from 'sierra-client/views/manage/utils/use-manage-program-url'
import { ContentProgramEnrollment } from 'sierra-domain/api/manage'
import { scrollViewStyles, Spacer, Text, View } from 'sierra-ui/primitives'
import styled from 'styled-components'

type ContentGroupsData = ContentProgramEnrollment

type ContentGroupsTableDefinition = TableDefinitionOf<
  ContentGroupsData,
  [
    { type: 'strings'; ref: 'groupName' },
    { type: 'strings'; ref: 'progress' },
    { type: 'strings'; ref: 'adminName' },
    { type: 'strings'; ref: 'dueDate' },
  ]
>

type ContentGroupsTableData = TableDataFromDefinition<ContentGroupsData, ContentGroupsTableDefinition>

const tableDefinition = (t: TranslationLookup): ContentGroupsTableDefinition => ({
  columns: [
    stringsColumn({
      ref: 'groupName',
      getData: r => r.groupInfo.groupName,
      header: translatedLabel('table.name'),
    }),
    stringsColumn({
      ref: 'progress',
      getData: r => percentage(r.progress),
      header: translatedLabel('admin.analytics.table.progress'),
    }),
    stringsColumn({
      ref: 'adminName',
      getData: r => r.groupInfo.adminName,
      header: translatedLabel('table.admin'),
    }),
    stringsColumn({
      ref: 'dueDate',
      getData: r => {
        return r.dueDate !== undefined ? getDueDateStringForGroup({ t, dueDate: r.dueDate }) : undefined
      },
      header: translatedLabel('due-date.due-date'),
    }),
  ],
  nested: {},
  rows: {
    getId: r => r.groupInfo.groupId,
  },
})

const searchKey: StaticLoaderSearchKeyBy<ContentGroupsTableData> = (tableData, row) =>
  tableData.rows[row]?.data.groupName.data ?? ''

const useContentGroupsTableAPI = (
  assignedPrograms: ContentProgramEnrollment[]
): UseTableAPI<ContentGroupsTableData, { subset: ContentGroupsTableData }> => {
  const { t } = useTranslation()
  const getManageProgramURL = useGetManageProgramURL()
  const loader = staticDataLoader(definition2Data(tableDefinition(t), assignedPrograms), searchKey)

  return useTableAPI({
    dataLoader: {
      loader,
    },
    virtualColumns: {
      left: [defaultSelectVirtualColumn()],
      right: [
        defaultMenuActionVirtualColumn({
          getProps: ({ row }) => ({
            menuItems: [
              {
                id: 'view-program',
                type: 'label',
                label: t('manage.view-details'),
                onClick: () =>
                  getGlobalRouter().navigate({ to: getManageProgramURL(row.rawData.groupInfo.groupId) }),
                icon: 'information',
              },
            ],
          }),
        }),
      ],
    },
  })
}

const TableWrapper = styled(View)`
  ${scrollViewStyles};
  min-height: 3rem;
  max-height: 27rem;
  width: 100%;
`

type ToolbarProps = {
  mapCsv: (ids: string[]) => Record<string, string>[]
}
const Toolbar: React.FC<ToolbarProps> = ({ mapCsv }) => {
  const { selection, api } = useTabularContext()
  const { t } = useTranslation()
  const csvButtonText = `${t('manage.export')} .csv`
  return (
    <TabularToolbar
      countsTranslationKeys={{
        totalKey: 'manage.content.n-contents',
        filterKey: 'manage.program-members.n-filtered',
        selectedKey: 'manage.tables.n-selected',
      }}
      api={api}
      clearFilters={false}
      actions={
        selection.type === 'manual' ? (
          <>
            <CSVLink
              data={mapCsv(Array.from(selection.rows))}
              filename={getCsvFileName(t('admin.analytics.users'))}
            >
              <ActionButton color='blueBright'>{csvButtonText}</ActionButton>
            </CSVLink>
            <ActionButton
              onClick={() => {
                api.action.setSelection({ type: 'none' })
              }}
              color='black'
            >
              {t('tabular.selection.clear-all.button')}
            </ActionButton>
          </>
        ) : undefined
      }
      enableAllSelection={true}
    />
  )
}

const Table: React.FC = () => {
  const scrollRef = useRef(null)

  return (
    <TableWrapper ref={scrollRef} alignItems='flex-start' direction='column'>
      <BasicTabularSimpleSize scrollRef={scrollRef.current} />
    </TableWrapper>
  )
}

const Footer: React.FC<{ fetchCsv: () => Promise<Record<string, string>[]> }> = ({ fetchCsv }) => {
  const { t } = useTranslation()
  return (
    <View marginBottom='32' marginTop='32' justifyContent='flex-end'>
      <ExportCSVIconButton fetchCsvData={fetchCsv} filename={t('admin.analytics.groups')} />
    </View>
  )
}

export type ContentGroupsTableProps = {
  assignedPrograms: ContentProgramEnrollment[]
}

export const ContentGroupsTable: React.FC<ContentGroupsTableProps> = ({ assignedPrograms }) => {
  const tableAPI = useContentGroupsTableAPI(assignedPrograms)
  const fetchCsv = (): Promise<Record<string, string>[]> =>
    Promise.resolve(assignedPrograms.map(parseEnrolledGroupToCsv))
  const search = (query: string): void => {
    tableAPI.api.action.setQuery({ query })
  }
  return (
    <TabularProviderFromTableAPI tableAPI={tableAPI}>
      <Text size='large' bold>
        {t('admin.organization.tab.programs')}
      </Text>
      <Spacer size='xsmall' />
      <AnimatedSearch placeholder={t('manage.search.groups')} onChange={search} />
      <Spacer size='xsmall' />
      <Toolbar
        mapCsv={ids =>
          assignedPrograms.filter(p => ids.includes(p.groupInfo.groupId)).map(parseEnrolledGroupToCsv)
        }
      />
      <Table />
      <Footer fetchCsv={fetchCsv} />
    </TabularProviderFromTableAPI>
  )
}
