import { useAtomValue, useSetAtom } from 'jotai'
import _ from 'lodash'
import React, { useCallback, useContext, useEffect, useMemo, useState } from 'react'
import { useLiveSessionIdContext } from 'sierra-client/components/liveV2/live-session-id-provider'
import { TypedEventEmitter } from 'sierra-client/lib/typed-event-emitter'
import { QuestionAnswerInserted, liveSessionDataChannel } from 'sierra-client/realtime-data/channels'
import { useCachedQuery, useTypedMutation } from 'sierra-client/state/api'
import { useDispatch, useSelector } from 'sierra-client/state/hooks'
import {
  QuestionAnswerFacilitatorSummaryData,
  currentQuestionFacilitatorSummaryDataAtom,
} from 'sierra-client/state/interactive-card-data/question-card'
import { selectIsFacilitator } from 'sierra-client/state/live-session/selectors'
import {
  getQuestionCardResponse,
  resetQuestionCard,
  submitQuestionCardResponse,
} from 'sierra-client/state/self-paced/actions'
import { selectQuestionCardResponse } from 'sierra-client/state/self-paced/selectors'
import {
  combinePreviousAttemptsWithExistingPairs,
  createCorrectMatchThePairMappingFromElement,
  evaluateResponse,
  initializeResponse,
  interactionToQuestionCardResponse,
  questionCardResponseToInteraction,
} from 'sierra-client/state/self-paced/utils'
import { RootState } from 'sierra-client/state/types'
import { selectUserId } from 'sierra-client/state/user/user-selector'
import { FCC } from 'sierra-client/types'
import { useEditOrResponsesStateSafe } from 'sierra-client/views/flexible-content/create-page-context'
import { useFileContext } from 'sierra-client/views/flexible-content/file-context'
import { useSetCardProgress } from 'sierra-client/views/flexible-content/progress-tracking/set-progress-provider'
import { useQuestionShuffle } from 'sierra-client/views/flexible-content/question-shuffling/shuffle-elements-provider'
import {
  PlacementTests,
  usePlacementTestContext,
} from 'sierra-client/views/self-paced/placement-test/placement-test-context'
import { useQuestionContext } from 'sierra-client/views/self-paced/question-context-provider'
import { Reviews, useReviewContext } from 'sierra-client/views/self-paced/review/review-context'
import { useAssessmentContextUnsafe } from 'sierra-client/views/v3-author/assessment-card/assessment-context'
import { useEditorReadOnly } from 'sierra-client/views/v3-author/editor-context/editor-context'
import { useAncestorWithType } from 'sierra-client/views/v3-author/hooks'
import { isElementType } from 'sierra-client/views/v3-author/queries'
import { EditorMode } from 'sierra-client/views/v3-author/slate'
import { LiveSessionId } from 'sierra-domain/api/nano-id'
import { LiveQuestionAnswer } from 'sierra-domain/api/strategy-v2'
import { QuestionCardResponse, QuestionCardResponseData } from 'sierra-domain/card/question-card'
import { ScopedLiveSessionId } from 'sierra-domain/collaboration/types'
import { Entity } from 'sierra-domain/entity'
import {
  XRealtimeStrategyContentDataQuestionCardGetLiveQuestionCardAnswers,
  XRealtimeStrategyContentDataQuestionCardGetMyLiveQuestionCardAnswer,
  XRealtimeStrategyContentDataQuestionCardUpsertLiveQuestionCardAnswer,
} from 'sierra-domain/routes'
import { assertNever } from 'sierra-domain/utils'
import { QuestionCardBody } from 'sierra-domain/v3-author'
import { useOnChanged } from 'sierra-ui/utils'

type LiveDataEventEmitter = TypedEventEmitter<{ data: () => void }>

type QuestionCardDataLayer = {
  updateResponse: (response: QuestionCardResponseData) => void
  submitResponse: () => Promise<void> | void
  retry: () => void
  questionId: string
  disableRetry?: boolean
  questionCardData: {
    evaluation: boolean | undefined
    response: QuestionCardResponseData
    isValidResponse: boolean
  }
  liveDataEventEmitter: LiveDataEventEmitter | undefined
}

function validateResponse(response: QuestionCardResponseData, type: QuestionCardBody['type']): boolean {
  switch (type) {
    case 'question-card-pick-the-best-option-body':
      return response.type === 'multiple-choice' && response.selectedAlternativeIds.length === 1
    case 'question-card-select-all-that-apply-body':
      return response.type === 'multiple-choice'
    case 'question-card-match-the-pairs-body':
      return response.type === 'match-the-pairs'
    case 'question-card-free-text-body':
      return response.type === 'free-text'
    default:
      assertNever(type)
  }
}

const LiveContext = React.createContext<QuestionCardDataLayer | undefined>(undefined)
const SelfPacedContext = React.createContext<QuestionCardDataLayer | undefined>(undefined)
const CreateContext = React.createContext<QuestionCardDataLayer | undefined>(undefined)
const ReviewContext = React.createContext<QuestionCardDataLayer | undefined>(undefined)
const PlacementTestContext = React.createContext<QuestionCardDataLayer | undefined>(undefined)
const RecapContext = React.createContext<QuestionCardDataLayer | undefined>(undefined)

const liveQuestionAnswerToMultipleChoiceQuestionResponse = (
  answer: LiveQuestionAnswer,
  element: Entity<QuestionCardBody>
): QuestionCardResponseData => {
  const it = answer.answer
  if (it.type === 'question-multiple-choice-answer') {
    return {
      type: 'multiple-choice',
      alternatives: element.children.reduce(
        (choices, child) => ({
          ...choices,
          [child.id]:
            it.choiceIds.includes(child.id) === true ? { status: 'correct' } : { status: 'incorrect' },
        }),
        {}
      ),
      selectedAlternativeIds: it.choiceIds,
    }
  }

  return initializeResponse({ element })
}

const liveQuestionAnswerToMatchThePairsQuestionResponse = (
  answer: LiveQuestionAnswer,
  element: Entity<QuestionCardBody>
): QuestionCardResponseData => {
  const it = answer.answer
  if (it.type === 'question-match-answer' && element.type === 'question-card-match-the-pairs-body') {
    const pairs = combinePreviousAttemptsWithExistingPairs({
      previousMatches: it.choices.map(choice => [choice.firstId, choice.secondId]),
      allCorrectPairs: createCorrectMatchThePairMappingFromElement(element),
    })

    return {
      type: 'match-the-pairs',
      pairs,
    }
  }

  return initializeResponse({ element })
}

const liveQuestionAnswerToQuestionResponse = (
  answer: LiveQuestionAnswer,
  element: Entity<QuestionCardBody>
): QuestionCardResponseData => {
  switch (element.type) {
    case 'question-card-pick-the-best-option-body':
    case 'question-card-select-all-that-apply-body':
      return liveQuestionAnswerToMultipleChoiceQuestionResponse(answer, element)
    case 'question-card-match-the-pairs-body':
      return liveQuestionAnswerToMatchThePairsQuestionResponse(answer, element)
    case 'question-card-free-text-body':
      return {
        type: 'free-text',
        answer: '',
      }
    default:
      assertNever(element)
  }
}

const questionAnswersToFacilitatorSummaries = (
  newAnswers: LiveQuestionAnswer[],
  previousSummaries: QuestionAnswerFacilitatorSummaryData[]
): QuestionAnswerFacilitatorSummaryData[] =>
  newAnswers.reduce<QuestionAnswerFacilitatorSummaryData[]>((summaries, answer) => {
    const previousEntry = summaries.find(({ userId }) => answer.userId === userId)

    if (previousEntry === undefined) {
      return [
        ...summaries,
        {
          questionId: answer.questionId,
          userId: answer.userId,
          correct: answer.correct,
          attempts: 1,
          createdAt: answer.createdAt,
        },
      ]
    }

    return summaries.map(summary =>
      summary.userId === answer.userId
        ? {
            ...summary,
            correct: summary.correct ? summary.correct : answer.correct,
            attempts: summary.attempts + 1,
          }
        : summary
    )
  }, previousSummaries)

const LiveRealTimeDataHandler = ({
  liveSessionId,
  eventId,
  onQuestionAnswerInserted,
  onReadyToReceiveData,
}: {
  liveSessionId: LiveSessionId
  eventId: string
  onQuestionAnswerInserted: (data: QuestionAnswerInserted) => void
  onReadyToReceiveData: (receiving: boolean) => void
}): null => {
  const { isReceivingData: isReceivingQuestionUpsertedData } = liveSessionDataChannel.useChannelEvent({
    channelId: liveSessionId,
    event: 'question-answer-inserted',
    eventId,
    callback: onQuestionAnswerInserted,
  })

  useEffect(() => {
    onReadyToReceiveData(isReceivingQuestionUpsertedData)
  }, [isReceivingQuestionUpsertedData, onReadyToReceiveData])

  return null
}

type QuestionCardProvider = FCC<{ element: Entity<QuestionCardBody> }>

const LiveDataProvider: QuestionCardProvider = ({ element, children }) => {
  const { liveSessionId: scopedLiveSessionId } = useLiveSessionIdContext()
  const isFacilitator = useSelector(selectIsFacilitator)
  const liveSessionId = ScopedLiveSessionId.extractId(scopedLiveSessionId)
  const { file, flexibleContentId } = useFileContext()
  const userId = useSelector(selectUserId)
  const { setCardCompleted } = useSetCardProgress()
  const [liveDataEventEmitter] = useState<LiveDataEventEmitter>(new TypedEventEmitter())

  const setFacilitatorAnswerSummaryAtom = useSetAtom(currentQuestionFacilitatorSummaryDataAtom)
  const facilitatorAnswerSummaryAtom = useAtomValue(currentQuestionFacilitatorSummaryDataAtom)

  const [realtimeReady, setRealtimeReady] = useState<boolean>(false)

  if (userId === undefined) throw new Error('No userId found')

  const questionId = element.id

  const [submitted, setSubmitted] = useState<boolean>(false)

  const [response, setResponse] = useState<QuestionCardResponseData>(initializeResponse({ element }))

  const evaluation = useMemo(
    () => (submitted === true ? evaluateResponse({ element, response }) : undefined),
    [submitted, element, response]
  )

  // Set the card to completed once the evaluation is correct
  useEffect(() => {
    if (evaluation === true) {
      setCardCompleted()
    }
  }, [evaluation, setCardCompleted])

  const questionQuery = useCachedQuery(XRealtimeStrategyContentDataQuestionCardGetMyLiveQuestionCardAnswer, {
    contentId: flexibleContentId,
    fileId: file.id,
    liveSessionId: liveSessionId,
    questionId: questionId,
  })

  useEffect(() => {
    if (questionQuery.data?.answer !== undefined) {
      const newResponse = liveQuestionAnswerToQuestionResponse(questionQuery.data.answer, element)
      setSubmitted(true)
      setResponse(newResponse)
    }
  }, [element, questionQuery.data])

  const questionsFacilitatorQuery = useCachedQuery(
    XRealtimeStrategyContentDataQuestionCardGetLiveQuestionCardAnswers,
    {
      contentId: flexibleContentId,
      fileId: file.id,
      liveSessionId: liveSessionId,
      questionId: questionId,
    },
    { enabled: realtimeReady && isFacilitator }
  )

  useEffect(() => {
    if (questionsFacilitatorQuery.data !== undefined) {
      setFacilitatorAnswerSummaryAtom(
        questionAnswersToFacilitatorSummaries(questionsFacilitatorQuery.data.answers, [])
      )
    }
  }, [questionsFacilitatorQuery.data, setFacilitatorAnswerSummaryAtom])

  const upsertQuestionResponseMutation = useTypedMutation(
    XRealtimeStrategyContentDataQuestionCardUpsertLiveQuestionCardAnswer,
    {
      onSuccess: (_res, data) => {
        setSubmitted(true)
        setResponse(interactionToQuestionCardResponse({ element, interaction: data.answer }))
      },
    }
  )

  const onQuestionInteractionUpserted = (data: QuestionAnswerInserted): void => {
    liveDataEventEmitter.emit('data')
    setFacilitatorAnswerSummaryAtom(
      questionAnswersToFacilitatorSummaries([data], facilitatorAnswerSummaryAtom)
    )
  }

  const updateResponse: QuestionCardDataLayer['updateResponse'] = useCallback(
    response => {
      if (submitted === false) {
        setResponse(response)
      }
    },
    [submitted]
  )

  const answer = useMemo(
    () => questionCardResponseToInteraction({ element, questionCardResponse: response }),
    [element, response]
  )

  const submitResponse: QuestionCardDataLayer['submitResponse'] = useCallback(() => {
    upsertQuestionResponseMutation.mutate({
      contentId: flexibleContentId,
      fileId: file.id,
      questionId: questionId,
      liveSessionId: liveSessionId,
      correct: evaluateResponse({ element, response, forSubmit: true }),
      answer,
    })
  }, [
    element,
    file.id,
    flexibleContentId,
    answer,
    liveSessionId,
    questionId,
    response,
    upsertQuestionResponseMutation,
  ])

  const retry: QuestionCardDataLayer['retry'] = useCallback((): void => {
    setSubmitted(false)
    setResponse(initializeResponse({ element }))
  }, [element])

  const value = useMemo(
    () => ({
      updateResponse,
      submitResponse,
      retry,
      disableRetry: false,
      questionCardData: { response, evaluation, isValidResponse: validateResponse(response, element.type) },
      questionId,
      liveDataEventEmitter,
    }),
    [
      element.type,
      evaluation,
      liveDataEventEmitter,
      questionId,
      response,
      retry,
      submitResponse,
      updateResponse,
    ]
  )

  return (
    <LiveContext.Provider value={value}>
      {children}

      {isFacilitator && (
        <LiveRealTimeDataHandler
          liveSessionId={liveSessionId}
          eventId={questionId}
          onQuestionAnswerInserted={onQuestionInteractionUpserted}
          onReadyToReceiveData={setRealtimeReady}
        />
      )}
    </LiveContext.Provider>
  )
}

const SelfPacedDataProvider: QuestionCardProvider = ({ children, element }) => {
  const { retryQuestion, isRetry } = useQuestionContext(element.id) //New data context, under construction
  const isAssessment = useAncestorWithType({ nodeId: element.id, type: 'assessment-question' }) !== undefined
  const assessmentContext = useAssessmentContextUnsafe()
  const assessmentProps: { sessionId: string } | undefined = useMemo(
    () =>
      isAssessment && assessmentContext !== undefined && assessmentContext.state.status !== 'start'
        ? { sessionId: assessmentContext.state.sessionId }
        : undefined,
    [isAssessment, assessmentContext]
  )

  const userId = useSelector(selectUserId)
  if (userId === undefined) throw new Error('No userId found for in question card data layer.')

  const [currentResponse, setCurrentResponse] = useState<QuestionCardResponseData>(() =>
    initializeResponse({ element })
  )
  const { file, flexibleContentId } = useFileContext()
  const questionExerciseId = element.id
  const dispatch = useDispatch()

  const selectValidQuestionCardResponse = useCallback(
    (state: RootState): QuestionCardResponse | undefined => {
      /**
       * If the response evaluates as correct with the current document, but the
       * backend response is marked as incorrect, ignore the backend response and let the user
       * submit it again.
       *
       * This is a workaround for a bug where users had submitted questions with the correct
       * answer, but the alternatives weren't set up properly.
       */
      const _questionCardResponse = selectQuestionCardResponse(state)(questionExerciseId)

      if (_questionCardResponse === undefined) return undefined

      const wasAnswerCorrectWhenSubmitted = _questionCardResponse.wasCorrectWhenSubmitted
      const isCorrectInCurrentDocument = evaluateResponse({
        element,
        response: _questionCardResponse.response,
      })

      if (!wasAnswerCorrectWhenSubmitted && isCorrectInCurrentDocument) {
        return undefined
      } else {
        return _questionCardResponse
      }
    },
    [element, questionExerciseId]
  )

  const questionCardResponse = useSelector(selectValidQuestionCardResponse)

  const submitted = useMemo(() => questionCardResponse !== undefined, [questionCardResponse])

  // Initialise question card
  useEffect(() => {
    if (!isRetry) {
      void dispatch(
        getQuestionCardResponse({
          contentId: flexibleContentId,
          fileId: file.id,
          questionExerciseId,
          element,
          ...assessmentProps,
        })
      )
    }
  }, [element, dispatch, questionExerciseId, flexibleContentId, file, assessmentProps, isRetry])

  const response: QuestionCardResponseData = useMemo(() => {
    return questionCardResponse?.response ?? currentResponse
  }, [currentResponse, questionCardResponse?.response])

  const evaluation = useMemo(
    () => (submitted ? evaluateResponse({ element, response }) : undefined),
    [element, response, submitted]
  )

  const updateResponse: QuestionCardDataLayer['updateResponse'] = useCallback(
    response => {
      if (!submitted) {
        setCurrentResponse(response)
      }
    },
    [submitted]
  )

  const submitResponse: QuestionCardDataLayer['submitResponse'] = useCallback(async () => {
    await dispatch(
      submitQuestionCardResponse({
        contentId: flexibleContentId,
        fileId: file.id,
        questionExerciseId,
        element,
        questionCardResponse: currentResponse,
        ...assessmentProps,
      })
    )
  }, [dispatch, flexibleContentId, file.id, questionExerciseId, element, currentResponse, assessmentProps])

  const retry: QuestionCardDataLayer['retry'] = useCallback(() => {
    retryQuestion()
    // dispatch(resetQuestionCard({ questionExerciseId })) This is moved to new questionContext in the card renderer
    setCurrentResponse(initializeResponse({ element }))
  }, [element, retryQuestion])

  /* When we're in an assessment and the sessionId changes, reinitialize the question-card */

  const sessionId = useMemo(() => {
    if (assessmentContext?.state.status !== 'start') return assessmentContext?.state.sessionId
    else return undefined
  }, [assessmentContext?.state])

  useOnChanged((previous, current) => {
    //Don't reset the question card if there is a response from the same session
    if (previous === current || questionCardResponse?.sessionId === sessionId) {
      return
    }
    void dispatch(resetQuestionCard({ questionExerciseId }))
    setCurrentResponse(initializeResponse({ element }))
  }, sessionId)

  const dataLayer = useMemo(
    (): QuestionCardDataLayer => ({
      updateResponse,
      submitResponse,
      retry,
      questionCardData: { evaluation, response, isValidResponse: validateResponse(response, element.type) },
      questionId: element.id,
      liveDataEventEmitter: undefined,
    }),
    [updateResponse, submitResponse, retry, evaluation, response, element.type, element.id]
  )

  return <SelfPacedContext.Provider value={dataLayer}>{children}</SelfPacedContext.Provider>
}

const ReviewDataProvider: QuestionCardProvider = ({ children, element }) => {
  const { state, setState } = useReviewContext()
  const { reshuffleAllQuestions } = useQuestionShuffle()

  const userId = useSelector(selectUserId)
  if (userId === undefined) throw new Error('No userId found for in question card data layer.')

  const question = Reviews.currentElement(state)
  if (question?.response?.type === 'block') throw new Error('Current question response is a block response')

  const dataLayer = useMemo<QuestionCardDataLayer>(() => {
    const response =
      question?.response === undefined || question.response.type === 'block'
        ? initializeResponse({ element })
        : question.response

    return {
      updateResponse: response => {
        setState(previous => Reviews.updateResponse(previous, response))
      },
      submitResponse: async () => {
        const response = await Reviews.submitQuestionResponse(state)
        setState(response)
      },
      retry: () => {
        reshuffleAllQuestions()
        setState(Reviews.resetResponse)
      },
      questionCardData: {
        evaluation: question?.evaluation,
        response,
        isValidResponse: validateResponse(response, element.type),
      },
      questionId: element.id,
      liveDataEventEmitter: undefined,
    }
  }, [element, question?.evaluation, question?.response, reshuffleAllQuestions, setState, state])

  return <ReviewContext.Provider value={dataLayer}>{children}</ReviewContext.Provider>
}

const PlacementTestDataProvider: QuestionCardProvider = ({ children, element }) => {
  const { state, setState } = usePlacementTestContext()

  useEffect(() => {
    setState(previous => {
      const question = PlacementTests.currentElement(previous)
      if (question?.response === undefined) {
        return PlacementTests.updateResponse(previous, initializeResponse({ element }))
      }
      return previous
    })
  }, [element, setState])

  const userId = useSelector(selectUserId)
  if (userId === undefined) throw new Error('No userId found for in question card data layer.')

  const question = PlacementTests.currentElement(state)
  if (question?.response?.type === 'block') throw new Error('Current question response is a block response')

  const dataLayer = useMemo<QuestionCardDataLayer>(() => {
    const response =
      question?.response === undefined || question.response.type === 'block'
        ? initializeResponse({ element })
        : question.response
    return {
      updateResponse: response => {
        setState(previous => PlacementTests.updateResponse(previous, response))
      },
      submitResponse: async () => {
        const response = await PlacementTests.submitQuestionResponse(state)
        setState(response)
      },
      retry: () => {
        setState(PlacementTests.resetResponse)
      },
      disableRetry: true,
      questionCardData: {
        evaluation: question?.evaluation,
        response,
        isValidResponse: validateResponse(response, element.type),
      },
      questionId: element.id,
      liveDataEventEmitter: undefined,
    }
  }, [element, question?.evaluation, question?.response, setState, state])

  return <PlacementTestContext.Provider value={dataLayer}>{children}</PlacementTestContext.Provider>
}

const CreateDataProvider: QuestionCardProvider = ({ children, element }) => {
  const userId = useSelector(selectUserId)
  const readOnly = useEditorReadOnly()
  const [liveDataEventEmitter] = useState<LiveDataEventEmitter>(new TypedEventEmitter())
  const editOrResponsesState = useEditOrResponsesStateSafe()
  if (userId === undefined) throw new Error('No userId found for in question card data layer.')

  const dataLayer = useMemo<QuestionCardDataLayer>(() => {
    const response = _.cloneDeep(initializeResponse({ element }))

    if (readOnly) {
      // Fake correct alternatives in the readOnly state
      switch (response.type) {
        case 'free-text':
          break
        case 'multiple-choice': {
          const correctAlternativeIds = new Set(
            element.children
              .filter(isElementType('question-card-multiple-choice-alternative'))
              .filter(child => child.status === 'correct')
              .map(child => child.id)
          )

          response.alternatives = Object.fromEntries(
            Object.entries(response.alternatives).map(([id, alt]) => [
              id,
              { ...alt, status: correctAlternativeIds.has(id) ? 'correct' : 'incorrect' },
            ])
          )
          response.selectedAlternativeIds = Object.keys(response.alternatives)
          break
        }

        case 'match-the-pairs': {
          response.pairs = createCorrectMatchThePairMappingFromElement(element).map(pair => ({
            firstId: pair.first.id,
            secondId: pair.second.id,
            status: 'correct',
          }))
        }
      }
    }

    return {
      updateResponse: () => {},
      submitResponse: () => {},
      retry: () => {},
      questionId: element.id,
      questionCardData: {
        evaluation: readOnly ? true : undefined,
        response,
        isValidResponse: validateResponse(response, element.type),
      },
      liveDataEventEmitter: liveDataEventEmitter,
    }
  }, [element, liveDataEventEmitter, readOnly])

  return (
    <PlacementTestContext.Provider value={dataLayer}>
      {children}
      {editOrResponsesState !== undefined &&
        editOrResponsesState.type === 'responses' &&
        editOrResponsesState.liveSessionId !== undefined && (
          <LiveRealTimeDataHandler
            liveSessionId={editOrResponsesState.liveSessionId}
            eventId={element.id}
            onQuestionAnswerInserted={() => {
              liveDataEventEmitter.emit('data')
            }}
            onReadyToReceiveData={() => {}}
          />
        )}
    </PlacementTestContext.Provider>
  )
}

const FallbackDataProvider: QuestionCardProvider = ({ element, children }) => {
  const userId = useSelector(selectUserId)
  if (userId === undefined) throw new Error(`Can't find User ID`)

  const response = initializeResponse({ element })

  const evaluation = evaluateResponse({ element, response })

  const contextValue = useMemo(
    () => ({
      questionCardData: { evaluation, response, isValidResponse: validateResponse(response, element.type) },
      disableRetry: true,
      questionId: element.id,
      retry: () => {},
      submitResponse: () => {},
      updateResponse: () => {},
      liveDataEventEmitter: undefined,
    }),
    [element.id, element.type, evaluation, response]
  )

  return <RecapContext.Provider value={contextValue}>{children}</RecapContext.Provider>
}

const modeToProvider: Record<EditorMode, QuestionCardProvider | undefined> = {
  'live': LiveDataProvider,
  'self-paced': SelfPacedDataProvider,
  'create': CreateDataProvider,
  'review': ReviewDataProvider,
  'placement-test': PlacementTestDataProvider,
  'recap': undefined,
  'template': undefined,
  'version-history': undefined,
}

export const QuestionCardDataProvider: FCC<{ element: Entity<QuestionCardBody>; mode: EditorMode }> = ({
  mode,
  element,
  children,
}) => {
  const Provider = modeToProvider[mode] ?? FallbackDataProvider

  return <Provider element={element}>{children}</Provider>
}

export const useQuestionCardData = (): QuestionCardDataLayer => {
  const liveData = useContext(LiveContext)
  const selfPacedData = useContext(SelfPacedContext)
  const createData = useContext(CreateContext)
  const reviewData = useContext(ReviewContext)
  const placementTestData = useContext(PlacementTestContext)
  const recapData = useContext(RecapContext)

  const data = liveData ?? selfPacedData ?? createData ?? reviewData ?? placementTestData ?? recapData

  if (!data) {
    throw new Error('Question card data not provided')
  }

  return data
}
