import { defer, maxBy } from 'lodash'
import { useEffect } from 'react'
import { useParticipantsVideoPriority } from 'sierra-client/components/liveV2/participant-provider'
import { useDeepEqualityMemo } from 'sierra-client/hooks/use-deep-equality-memo'
import { useDispatch, useSelector } from 'sierra-client/state/hooks'
import { selectScreenShareParticipants } from 'sierra-client/state/live-session/selectors'
import { setVideoSubscribeConfig } from 'sierra-client/state/live/actions'
import { selectPreferredVideoQuality } from 'sierra-client/state/live/selectors'

export const VideoSubscribeController = (): null => {
  const { videoRequests } = useParticipantsVideoPriority()
  const screenSharingUsers = useDeepEqualityMemo(useSelector(selectScreenShareParticipants))
  const preferredVideoQuality = useSelector(selectPreferredVideoQuality)
  const dispatch = useDispatch()

  useEffect(() => {
    const screenSharingUserIds = new Set()
    const screenShareUsers = screenSharingUsers.map(user => {
      screenSharingUserIds.add(user.agoraScreenShareUID)
      return {
        userId: user.agoraScreenShareUID,
        quality: 'auto' as const,
      }
    })

    const userVideos = Object.entries(videoRequests)
      .filter(
        ([uid, usageRequests]) =>
          uid !== 'local' && usageRequests.length > 0 && !screenSharingUserIds.has(uid)
      )
      .map(([uid, requests]) => ({
        userId: uid,
        quality:
          preferredVideoQuality === 'auto'
            ? maxBy(requests, 'priority')?.quality ?? ('auto' as const)
            : preferredVideoQuality,
      }))

    // When switching cards the config is updated multiple times in a short period of time,
    // we defer the update to the subscribe config and run it after the current call stack is completed
    const timeout = defer(() => {
      const config = [...userVideos, ...screenShareUsers]
      void dispatch(setVideoSubscribeConfig({ config }))
    })

    return () => clearTimeout(timeout)
  }, [dispatch, screenSharingUsers, videoRequests, preferredVideoQuality])

  return null
}
