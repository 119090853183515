import { Icon } from 'sierra-ui/components'
import styled from 'styled-components'

export const FadedRadioDotIcon = styled(Icon).attrs({
  color: 'foreground/primary',
  iconId: 'radio-button--dot',
  size: 'size-12',
})`
  opacity: 0.1;
`
