import React, { useMemo } from 'react'
import { useCurrentUserId } from 'sierra-client/api/hooks/use-user'
import { useLiveSessionContext } from 'sierra-client/components/liveV2/contexts/live-session-data'
import { postWithUserErrorException } from 'sierra-client/state/api'
import { useDispatch, useSelector } from 'sierra-client/state/hooks'
import { addLiveQuizAnswer } from 'sierra-client/state/live-session/actions'
import { selectLiveQuizStateById } from 'sierra-client/state/live-session/selectors'
import { store } from 'sierra-client/state/store'
import { RootState } from 'sierra-client/state/types'
import { FCC } from 'sierra-client/types'
import { useLiveQuizContext } from 'sierra-client/views/v3-author/live-quiz/live-quiz-context'
import { FileId } from 'sierra-domain/flexible-content/identifiers'
import { XRealtimeStrategyContentDataLiveQuizRespondToQuestion } from 'sierra-domain/routes'

type LiveQuizQuestionContext = {
  respond: (args: { alternativeId: string }) => Promise<void>
  questionId: string
  selectedAlternativeId?: string
  answers: Record<string, string[]> // alternativeId -> userId[]
}

const LiveQuizQuestionContext = React.createContext<LiveQuizQuestionContext | undefined>(undefined)

export const LiveQuizQuestionCardContextProvider: FCC<{ questionId: string }> = ({
  children,
  questionId,
}) => {
  const liveSession = useLiveSessionContext()
  const liveQuizContext = useLiveQuizContext()
  const dispatch = useDispatch()
  const [selectedAlternativeId, setSelectedAlternativeId] = React.useState<string | undefined>(undefined)
  const currentUserId = useCurrentUserId()

  const stateById = useSelector((state: RootState) =>
    selectLiveQuizStateById(state, FileId.parse(liveQuizContext.fileId))
  )

  // alternativeId -> userId
  const answers: Record<string, string[]> = useMemo(() => {
    if (stateById === undefined || stateById.questions[questionId] === undefined) {
      return {}
    }

    const x = Object.entries(stateById.questions[questionId].answers)
      .map(([userId, obj]) => {
        if (obj === undefined) {
          return undefined
        }
        return { userId, alternativeId: obj.alternativeId }
      })
      .filter(x => x !== undefined)
      .reduce(
        (acc, x) => {
          const xx = acc[x.alternativeId]
          if (xx !== undefined) {
            acc[x.alternativeId] = [x.userId, ...xx]
          } else {
            acc[x.alternativeId] = [x.userId]
          }
          return acc
        },
        {} as Record<string, string[]>
      )

    return x
  }, [stateById, questionId])

  const value: LiveQuizQuestionContext = useMemo(
    () => ({
      respond: async ({ alternativeId }): Promise<void> => {
        setSelectedAlternativeId(alternativeId)
        await postWithUserErrorException(
          XRealtimeStrategyContentDataLiveQuizRespondToQuestion,
          {
            liveSessionId: liveSession.liveSessionId,
            fileId: liveQuizContext.fileId,
            optionId: alternativeId, // TODO RENAME TO alternativeId
            questionId,
          },
          store.dispatch
        )

        if (currentUserId.data !== undefined) {
          dispatch(
            addLiveQuizAnswer({
              fileId: FileId.parse(liveQuizContext.fileId),
              alternativeId,
              questionId,
              userId: currentUserId.data,
            })
          )
        }
      },
      questionId,
      selectedAlternativeId,
      answers,
    }),
    [liveSession, liveQuizContext, questionId, selectedAlternativeId, dispatch, currentUserId.data, answers]
  )

  return <LiveQuizQuestionContext.Provider value={value}>{children}</LiveQuizQuestionContext.Provider>
}

export function useLiveQuizQuestionCardContext(): LiveQuizQuestionContext {
  const context = React.useContext(LiveQuizQuestionContext)

  if (context === undefined)
    throw new Error('useLiveQuizQuestionContext must be used within a LiveQuizQuestionContextProvider')

  return context
}
