export const languageItems = {
  'unspecified-language': 'Plain text',
  'javascript': 'JavaScript',
  'typescript': 'TypeScript',
  'python': 'Python',
  'shell': 'Bash (Shell)',
  'kotlin': 'Kotlin',
  'swift': 'Swift',
  'csharp': 'C#',
  'c': 'C',
  'cpp': 'C++',
  'php': 'PHP',
  'sql': 'SQL',
  'json': 'JSON',
  'go': 'Go',
  'rust': 'Rust',
  'haskell': 'Haskell',
  'julia': 'Julia',
  'jsx': 'JSX',
  'tsx': 'TSX',
  'html': 'HTML',
} as const
export type LanguageCode = keyof typeof languageItems
export const defaultLanguage = 'unspecified-language'
