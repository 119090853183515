import React from 'react'
import { View } from 'sierra-ui/primitives'
import styled from 'styled-components'

import { Backlink, DetailsBacklink } from 'sierra-client/views/manage/components/details-backlink'

const Container = styled(View).attrs({
  direction: 'row',
  justifyContent: 'space-between',
  alignItems: 'center',
  marginBottom: '24',
})``

type DetailsHeaderProps = {
  titleAddon?: JSX.Element
  hasTopMargin?: boolean
  backlink: Backlink
}

export const DetailsHeader: React.FC<DetailsHeaderProps> = ({
  titleAddon,
  hasTopMargin = false,
  backlink,
}) => {
  return (
    <Container marginTop={hasTopMargin === true ? '16' : undefined}>
      <DetailsBacklink backlink={backlink} />
      {titleAddon}
    </Container>
  )
}
