import { AnimatePresence, Reorder, useDragControls } from 'framer-motion'
import React, { useState } from 'react'
import { WIDGET_BUILDER_TRANSITION } from 'sierra-client/features/insights/widget-builder/data-selectors/animation-constants'
import { FCC } from 'sierra-client/types'
import { nanoid12 } from 'sierra-domain/nanoid-extensions'
import { Icon } from 'sierra-ui/components'
import { View } from 'sierra-ui/primitives'
import { useOnChanged } from 'sierra-ui/utils'
import styled from 'styled-components'

type DragItemType<ValueType> = {
  id: string
  value: ValueType
}

type AddableDragListProps<ValueType> = {
  onChange: (newValues: ValueType[]) => void
  addComponent: React.ReactNode
  values: ValueType[]
  renderItem: (value: ValueType, index: number) => JSX.Element
  equalFunction?: (a1: ValueType, a2: ValueType) => boolean // Use if values cannot be compared using ===
}

const DragHandle = styled.div`
  display: flex;
  align-items: center;
  cursor: grab;
  position: absolute;
  left: -20px;
  width: 46px;
  height: 46px;
`

export const DragItem: FCC<{
  value: any
  onDragEnd: () => void
  hasDragHandle: boolean
}> = ({ value, children, onDragEnd, hasDragHandle }) => {
  // eslint-disable-next-line react-hooks/exhaustive-deps
  const controls = useDragControls()

  return (
    <Reorder.Item
      as='div'
      dragListener={false}
      dragControls={controls}
      value={value}
      onDragEnd={onDragEnd}
      initial={{
        opacity: 0,
      }}
      animate={{ opacity: 1 }}
      exit={{ opacity: 0 }}
      transition={WIDGET_BUILDER_TRANSITION}
    >
      <View
        padding='4 none'
        grow
        position='relative'
        gap='none'
        animated
        whileHover='show'
        initial='hide'
        animate='hide'
      >
        {hasDragHandle && (
          <View
            animated
            variants={{
              show: {
                opacity: 1,
              },
              hide: {
                opacity: 0,
              },
            }}
            transition={WIDGET_BUILDER_TRANSITION}
          >
            <DragHandle onPointerDown={e => controls.start(e)}>
              <Icon iconId='draggable' />
            </DragHandle>
          </View>
        )}

        {children}
      </View>
    </Reorder.Item>
  )
}

export const AddableDragList = <ValueType,>({
  onChange,
  addComponent,
  renderItem,
  values,
  equalFunction = (a1, a2) => a1 === a2,
}: AddableDragListProps<ValueType>): JSX.Element => {
  const [itemsWithStableIds, setItemsWithStableIds] = useState<DragItemType<ValueType>[]>(
    values.map(it => ({ id: nanoid12(), value: it }))
  )

  const handleDrag = (newValues: DragItemType<ValueType>[]): void => {
    setItemsWithStableIds(newValues)
  }

  const onDragEnd = (): void => {
    onChange(itemsWithStableIds.map(it => it.value))
  }

  useOnChanged(() => {
    setItemsWithStableIds(
      values.map((it, index) => {
        const foundLocally =
          itemsWithStableIds.find(stable => equalFunction(stable.value, it)) ?? itemsWithStableIds[index]
        return {
          id: foundLocally?.id ?? nanoid12(),
          value: it,
        }
      })
    )
  }, values)

  return (
    <View direction='column' grow animated>
      <Reorder.Group axis='y' values={itemsWithStableIds} onReorder={handleDrag}>
        <AnimatePresence>
          {itemsWithStableIds.map((dragItem, index) => (
            <DragItem
              key={dragItem.id}
              value={dragItem}
              onDragEnd={onDragEnd}
              hasDragHandle={itemsWithStableIds.length > 1}
            >
              {renderItem(dragItem.value, index)}
            </DragItem>
          ))}
          <View animated layout transition={WIDGET_BUILDER_TRANSITION} marginTop='4'>
            {addComponent}
          </View>
        </AnimatePresence>
      </Reorder.Group>
    </View>
  )
}
