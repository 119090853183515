import { atom } from 'jotai'
import { UserInvitationAttributeData } from 'sierra-domain/user-attributes/user-attributes-config'
import { EmailUserValidateInvitationError } from 'sierra-domain/user-attributes/user-invitation-validation-error'

export const userAttributesDataAtom = atom<UserInvitationAttributeData>({ data: [] })

export const userAttributesValidationErrors = atom<EmailUserValidateInvitationError[]>([])

export const initialUserAttributesAtom = atom<UserInvitationAttributeData | undefined>(undefined)

/** This will reflect the value returned by the `can-edit-user-attributes` endpoint.
 * Note that if this atom is true, we interpret that as the user details being synced from
 * an external source.
 */
export const isReadonlyAttributesAtom = atom<boolean>(true)

/** This will reflect the `EDIT_USER` organization permission. */
export const isReadonlyUserMetadataAtom = atom<boolean>(true)
