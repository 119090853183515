import { atom } from 'jotai'
import { collapsableSidebarBooleanStateAtom } from 'sierra-client/features/collapsable-sidebar/atoms'
import { LiveContentId } from 'sierra-domain/api/nano-id'
import { FileId } from 'sierra-domain/flexible-content/identifiers'

export const appMainContainerAtom = atom<HTMLDivElement | null>(null)
export const HideGlobalSidebarOpenButtonAtom = atom<boolean>(false)
export const GlobalSidebarOpenAtom = collapsableSidebarBooleanStateAtom
export const GlobalSidebarIdAtom = atom<string | undefined>(undefined)
export const GlobalSidebarLiveSessionContentReferenceAtom = atom<
  { contentId: LiveContentId; fileId?: FileId } | undefined
>(undefined)
