import { UseQueryResult } from '@tanstack/react-query'
import { useMemo } from 'react'
import { graphql } from 'sierra-client/api/graphql/gql'
import { CertificatePermission } from 'sierra-client/api/graphql/gql/graphql'
import { useGraphQuery } from 'sierra-client/api/hooks/use-graphql-query'
import { useIsPermissionsInspectorToggled } from 'sierra-client/components/shortcut-menu/permissions-inspector/atoms'
import { useLocalPermissionValues } from 'sierra-client/components/shortcut-menu/permissions-inspector/hooks/use-local-permission-values'
import { PermissionKey, PermissionOptions } from 'sierra-client/hooks/use-permissions/types'
import { CachedQueryOptions, prefetchCachedQuery, useCachedQuery } from 'sierra-client/state/api'
import { useSelector } from 'sierra-client/state/hooks'
import { selectIsLoggedIn } from 'sierra-client/state/user/user-selector'
import { NanoId12, PathId } from 'sierra-domain/api/nano-id'
import {
  UserCalendarEventPermission,
  UserLiveSessionPermission,
  UserOrganizationsPermission,
  UserPermissionsRequest,
  UserPermissionsResponse,
  UserTeamspacePermission,
  type UserSkillPermission,
} from 'sierra-domain/api/user'
import { GroupId, ProgramId } from 'sierra-domain/api/uuid'
import { RequestError } from 'sierra-domain/error'
import { XRealtimeUserPermissions } from 'sierra-domain/routes'
import { assertNever, isDefined } from 'sierra-domain/utils'

const DEFAULT_QUERY_OPTIONS = {
  retry: 1,
  staleTime: 5 * 1000, // 5 seconds
} as const satisfies CachedQueryOptions

// For permissions related to a specific resource we sometimes want more granular error handling
// for example for allowing users to request access to a teamspace
type PermissionRequestStatus = 'loading' | 'loaded' | 'resource-not-found' | 'user-unauthorized' | 'error'

type PermissionChecker<T extends string> = {
  type: string | undefined
  has(permission: T): boolean
  status: PermissionRequestStatus
  debug: () => void
  refetch: () => Promise<unknown>
}

export type UserPermissionRequestParams<T extends UserPermissionsRequest['type']> = Omit<
  Extract<UserPermissionsRequest, { type: T }>,
  'type'
>
export type UserPermissionsResponseValues<T extends UserPermissionsResponse['type']> = Extract<
  UserPermissionsResponse,
  { type: T }
>['permissions'][number]

type PermissionsQueryOptions = CachedQueryOptions<UserPermissionsResponse>

const usePermissions = <T extends string>({
  type,
  permissions,
  status,
  refetch,
}: {
  type: string | undefined
  permissions: T[] | undefined
  status: PermissionRequestStatus
  refetch: () => Promise<unknown>
}): PermissionChecker<T> =>
  useMemo(() => {
    const set = new Set<T>(permissions ?? [])

    return {
      type,
      has: (permission: T): boolean => set.has(permission),
      status,
      refetch,
      debug: () => {
        // eslint-disable-next-line no-console
        console.log(`${type} permissions:`, permissions)
      },
    }
  }, [permissions, type, status, refetch])

/**
 * Owners can override permissions using the cmd + k -> "Debug permissions"
 * option to visualise frontend changes based on permission flags.
 */
const usePermissionsWithOverrides = <T extends PermissionKey>(
  type: T | undefined,
  permissions: PermissionOptions[T][] | undefined,
  status: PermissionRequestStatus,
  refetch: () => Promise<unknown>
): PermissionChecker<PermissionOptions[T]> => {
  const localPermissionOverwritesEnabled = useIsPermissionsInspectorToggled()
  const localPermissionValues = useLocalPermissionValues(type)
  const permissionsWithOverrides = useMemo(
    () => (localPermissionOverwritesEnabled ? localPermissionValues : permissions ?? []),
    [localPermissionOverwritesEnabled, localPermissionValues, permissions]
  )

  return usePermissions({
    type,
    permissions: permissionsWithOverrides,
    status,
    refetch,
  })
}

export const prefetchPermissions = <TReq extends UserPermissionsRequest['type']>(
  type: TReq,
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  params?: any
): Promise<void> => {
  return prefetchCachedQuery(XRealtimeUserPermissions, { type, ...params }, DEFAULT_QUERY_OPTIONS)
}

export function usePermissionsQuery(type: 'organization'): PermissionChecker<UserOrganizationsPermission>

export function usePermissionsQuery<
  TReq extends UserPermissionsRequest['type'],
  TRes extends UserPermissionsResponse['type'],
>(
  type: TReq,
  params: UserPermissionRequestParams<TReq>,
  options: PermissionsQueryOptions
): PermissionChecker<UserPermissionsResponseValues<TRes>>

export function usePermissionsQuery<T extends PermissionKey>(
  type: T,
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  params?: any,
  options: PermissionsQueryOptions = {}
): unknown {
  const isLoggedIn = useSelector(selectIsLoggedIn)
  const query = useCachedQuery(
    XRealtimeUserPermissions,
    { type, ...params },
    { ...DEFAULT_QUERY_OPTIONS, enabled: isLoggedIn, ...options }
  )

  let status: PermissionRequestStatus
  if (query.error instanceof RequestError) {
    switch (query.error.status) {
      case 401:
      case 403:
        status = 'user-unauthorized'
        break
      case 404:
        status = 'resource-not-found'
        break
      default:
        status = 'error'
    }
  } else if (query.error instanceof Error) {
    status = 'error'
  } else if (isDefined(query.data?.permissions)) {
    status = 'loaded'
  } else {
    status = 'loading'
  }

  return usePermissionsWithOverrides(query.data?.type, query.data?.permissions, status, query.refetch)
}

// Organization permissions
export const useOrganizationPermissions = (): PermissionChecker<UserOrganizationsPermission> => {
  return usePermissionsQuery('organization')
}

export const useHasOrganizationPermission = (permission: UserOrganizationsPermission): boolean => {
  return useOrganizationPermissions().has(permission)
}

// Teamspace permissions
export const useTeamspacePermissions = (
  id: string,
  options: PermissionsQueryOptions = {}
): PermissionChecker<UserTeamspacePermission> => {
  return usePermissionsQuery('teamspace', { id }, options)
}

export const useHasTeamspacePermission = (id: string, permission: UserTeamspacePermission): boolean => {
  return useTeamspacePermissions(id).has(permission)
}

export const prefetchTeamspacePermissions = (id: string): Promise<void> => {
  return prefetchCachedQuery(XRealtimeUserPermissions, { type: 'teamspace', id })
}

// Content Kind permissions
type ContentKindPermissions = UserPermissionsResponseValues<
  'self-paced' | 'live' | 'external-course' | 'event-group' | 'course-group'
>

export const useContentKindPermissions = (
  id: string,
  options: PermissionsQueryOptions = {}
): PermissionChecker<ContentKindPermissions> => {
  return usePermissionsQuery('content-kind', { id }, options)
}

type HomeworkPermissions = UserPermissionsResponseValues<'homework'>
export const useHomeworkPermissions = (
  id: string,
  options: PermissionsQueryOptions = {}
): PermissionChecker<HomeworkPermissions> => {
  return usePermissionsQuery('homework', { id }, options)
}

export const useHasContentKindPermission = (id: string, permission: ContentKindPermissions): boolean => {
  return useContentKindPermissions(id).has(permission)
}

// Live session permissions
export const useLiveSessionPermissions = (
  id: NanoId12,
  options: PermissionsQueryOptions = {}
): PermissionChecker<UserLiveSessionPermission> => {
  return usePermissionsQuery('live-session', { id }, options)
}

// Calendar event permissions
export const useCalendarEventPermissions = (
  id: string,
  options: PermissionsQueryOptions = {}
): PermissionChecker<UserCalendarEventPermission> => {
  return usePermissionsQuery('calendar-event', { id }, options)
}

export const useHasCalendarEventPermissions = (
  id: string,
  permission: UserCalendarEventPermission
): boolean => {
  return useCalendarEventPermissions(id).has(permission)
}

// Path permissions
export const usePathPermissions = (
  id: PathId,
  options: PermissionsQueryOptions = {}
): PermissionChecker<UserPermissionsResponseValues<'path'>> => {
  return usePermissionsQuery('path', { id }, options)
}

// Program permissions
export const useProgramPermissions = (
  id: ProgramId,
  options: PermissionsQueryOptions = {}
): PermissionChecker<UserPermissionsResponseValues<'program'>> => {
  return usePermissionsQuery('program', { id }, options)
}

// Group permissions
export const useGroupPermissions = (
  id: GroupId,
  options: PermissionsQueryOptions = {}
): PermissionChecker<UserPermissionsResponseValues<'group'>> => {
  return usePermissionsQuery('group', { id }, options)
}

// Certificate permissions
const queryStatusToPermissionStatus = (queryStatus: UseQueryResult['status']): PermissionRequestStatus => {
  switch (queryStatus) {
    case 'error':
      return 'error'
    case 'pending':
      return 'loading'
    case 'success':
      return 'loaded'
    default:
      assertNever(queryStatus)
  }
}

const certificatePermissionsQuery = graphql(`
  query CertificatePermissions($certificateId: CertificateId!) {
    certificatePermissions(certificateId: $certificateId)
  }
`)

// Specific certificate permissions
export const useCertificatePermissions = (
  certificateId: string
): PermissionChecker<CertificatePermission> => {
  const res = useGraphQuery({ document: certificatePermissionsQuery }, { certificateId })
  const status = queryStatusToPermissionStatus(res.status)

  return usePermissions({
    type: 'certificate',
    permissions: res.data?.certificatePermissions,
    status,
    refetch: res.refetch,
  })
}

export const useHasCertPermission = (certificateId: string, permission: CertificatePermission): boolean =>
  useCertificatePermissions(certificateId).has(permission)

// Skill permissions
export const useSkillPermissions = (
  id: string,
  options: PermissionsQueryOptions = {}
): PermissionChecker<UserSkillPermission> => {
  return usePermissionsQuery('skill', { id }, options)
}
