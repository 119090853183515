import { resolveNamespacedCourseImage } from 'sierra-client/api/content'
import { TemplateTabCategory } from 'sierra-client/components/templates/template-filter'
import { postWithUserErrorException } from 'sierra-client/state/api'
import { createRootStateAsyncThunk } from 'sierra-client/state/create-root-state-async-thunk'
import { Template } from 'sierra-client/state/templates/types'
import { CourseId } from 'sierra-domain/api/nano-id'
import { XRealtimeAuthorListTemplates } from 'sierra-domain/routes'

export const fetchPartnerTemplates = createRootStateAsyncThunk<Template[], undefined>(
  'templates/fetch-partner-templates',
  async (_, { dispatch }) => {
    const response = await postWithUserErrorException(XRealtimeAuthorListTemplates, {}, dispatch)
    const partnerTemplates = response.templates.map(
      (template): Template => ({
        id: template.templateId,
        label: template.title,
        description: template.description,
        imagePath:
          template.image !== undefined
            ? resolveNamespacedCourseImage(template.image, 'course-sm', {
                type: 'course',
                courseId: CourseId.parse(template.templateId), // Partner templates are courses
              })
            : undefined,
        templateType: 'partner',
        contentType: template.type,
        categories: [
          TemplateTabCategory.AllTeamTemplates,
          template.type === 'native:self-paced'
            ? TemplateTabCategory.SelfPacedTeamTemplates
            : TemplateTabCategory.LiveTeamTemplates,
        ],
      })
    )

    return partnerTemplates
  }
)
