import { useEffect, useState } from 'react'
import { SequentialTaskRunner } from 'sierra-client/lib/sequential-task-runner'
import { logger } from 'sierra-client/logger/logger'
import { useDispatch, useSelector } from 'sierra-client/state/hooks'
import { selectShouldUserBeOnStage } from 'sierra-client/state/live-session/selectors'
import { goOffStage, goOnStage } from 'sierra-client/state/live/actions'
import { selectCallIsActive } from 'sierra-client/state/live/selectors'

const MAX_VIDEO_STREAMS = 17

export const useStageController = (): void => {
  const dispatch = useDispatch()
  const joined = useSelector(selectCallIsActive)
  const [sequentialTaskRunner] = useState(() => new SequentialTaskRunner())

  const shouldPublish = useSelector(state => selectShouldUserBeOnStage(state, MAX_VIDEO_STREAMS))

  // Effect to go on stage when the user selector says so
  useEffect(() => {
    if (!joined) return

    const task = async (): Promise<void> => {
      if (shouldPublish) {
        await dispatch(goOnStage())
      }

      if (!shouldPublish) {
        await dispatch(goOffStage())
      }
    }

    if (sequentialTaskRunner.getIsRunning()) {
      logger.info('[StageController] Changing stage before last task is done', {
        shouldPublish,
      })
    }

    sequentialTaskRunner.push(task)
  }, [joined, shouldPublish, sequentialTaskRunner, dispatch])
}
