import { createChannel, createEvent } from 'sierra-client/realtime-data/channels/types'
import { NarrationMetadata, NarrationSettingsUsage } from 'sierra-domain/api/author-v2'
import { FileId } from 'sierra-domain/flexible-content/identifiers'
import { z } from 'zod'

const NarrationUpdated = z.object({
  fileId: FileId,
  narration: NarrationMetadata,
})
const SettingsUsageUpdated = z.object({
  usage: NarrationSettingsUsage,
})
export const narrationDataChannel = createChannel('narration-metadata', [
  createEvent('narration-updated', NarrationUpdated),
  createEvent('settings-usage-updated', SettingsUsageUpdated),
])
