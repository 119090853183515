import { atom } from 'jotai'

export type QuestionAnswerFacilitatorSummaryData = {
  userId: string
  questionId: string
  correct: boolean
  attempts: number
  createdAt: string
}

export const currentQuestionFacilitatorSummaryDataAtom = atom<QuestionAnswerFacilitatorSummaryData[]>([])
