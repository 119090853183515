import { useQuery } from '@tanstack/react-query'
import { DateTime, Duration, DurationLikeObject } from 'luxon'
import React from 'react'
import { queryClient } from 'sierra-client/api/query-client'
import { useConfirmationModalContext } from 'sierra-client/components/common/modals/confirmation-modal'
import { percentage, toLocalDayFormat } from 'sierra-client/core/format'
import {
  ContentScrollView,
  ContentWrapperView,
  FullWidthHorizontalLineDivider,
  FullWidthView,
  HeadingWrapperView,
  MinWidthText,
  WrapperView,
} from 'sierra-client/features/external-trainings/atoms'
import { getUserTranscriptQuery } from 'sierra-client/features/external-trainings/queries'
import { useTranslation } from 'sierra-client/hooks/use-translation'
import { useTypedMutation } from 'sierra-client/state/api'
import { openTranscriptViewer } from 'sierra-client/views/v3-author/export-pdf/export-user-transcript'
import { UserId } from 'sierra-domain/api/uuid'
import { XRealtimeUserDeleteExternalTrainings } from 'sierra-domain/routes'
import { isDefined } from 'sierra-domain/utils'
import { TruncatedText } from 'sierra-ui/components'
import { Button, Heading, IconButton, LoadingSpinner, Text, View } from 'sierra-ui/primitives'
import { dotSeparator } from 'sierra-ui/utils'
import styled, { css } from 'styled-components'

const ExternalTrainingView = styled(View)<{ pendingDeletion: boolean }>`
  ${p =>
    p.pendingDeletion &&
    css`
      @keyframes pulse {
        0% {
          opacity: 1;
        }
        100% {
          opacity: 0.2;
        }
      }
      animation: pulse 1s infinite alternate;
    `}
`

const NoTrainingsView: React.FC = () => {
  const { t } = useTranslation()

  return (
    <FullWidthView
      alignSelf='center'
      background='surface/soft'
      padding='48 none'
      radius='size-16'
      justifyContent='center'
    >
      <Text bold color='foreground/muted'>
        {t('user-transcript.no-trainings')}
      </Text>
    </FullWidthView>
  )
}

const timeSpentFromDuration = (duration: Duration): string => {
  const units = ['days', 'hours', 'minutes'] satisfies Array<keyof DurationLikeObject>

  const normalizedDurationObject = duration
    .shiftTo(...units)
    .normalize()
    .toObject()
  const nonZeroEntries = Object.entries(normalizedDurationObject).filter(([_unit, amount]) => amount > 0)

  const nonZeroDuration = Duration.fromObject(
    nonZeroEntries.length === 0 ? { ['minutes']: 0 } : Object.fromEntries(nonZeroEntries)
  )

  return nonZeroDuration.toHuman()
}

export const ViewTrainingPanelContent: React.FC<{ userId: UserId; closePanel: () => void }> = ({
  userId,
  closePanel,
}) => {
  const { t } = useTranslation()
  const confirmationModal = useConfirmationModalContext()

  const userTranscriptQuery = getUserTranscriptQuery({ userId })
  const userTranscriptQueryResult = useQuery(userTranscriptQuery)
  const deleteTrainingMutation = useTypedMutation(XRealtimeUserDeleteExternalTrainings, {
    onSettled: async () => {
      await queryClient.invalidateQueries(userTranscriptQuery)
    },
  })

  if (userTranscriptQueryResult.isPending) {
    return <LoadingSpinner />
  }

  if (userTranscriptQueryResult.isError) {
    return (
      <View justifyContent='center'>
        <Text>{t('dictionary.something-went-wrong')}</Text>
      </View>
    )
  }

  return (
    <WrapperView>
      <ContentScrollView>
        <HeadingWrapperView>
          <Heading size='h5' bold>
            {t('user-transcript.title')}
          </Heading>
          <Text size='regular' color={'foreground/secondary'}>
            {t('user-transcript.description')}
          </Text>
        </HeadingWrapperView>
        <View direction='column' gap='16'>
          <Text bold color='foreground/muted'>
            {t('user-transcript.external-trainings-title')}
          </Text>
          <ContentWrapperView>
            <>
              {userTranscriptQueryResult.data.externalTrainings.length === 0 ? (
                <NoTrainingsView />
              ) : (
                userTranscriptQueryResult.data.externalTrainings.map(training => {
                  const timeSpentDuration = Duration.fromObject({ minutes: training.durationInMinutes })

                  return (
                    <ExternalTrainingView
                      key={training.id}
                      gap='16'
                      pendingDeletion={deleteTrainingMutation.variables?.trainingId === training.id}
                    >
                      <View direction='column' gap='4' grow>
                        <TruncatedText lines={1} size='regular' bold>
                          {training.title}
                        </TruncatedText>
                        <View gap='4'>
                          <View gap='none'>
                            <TruncatedText lines={1} size='small' color='foreground/secondary' bold>
                              {training.provider}
                            </TruncatedText>
                          </View>
                          {dotSeparator}
                          <MinWidthText size='small' color='foreground/secondary'>
                            {timeSpentFromDuration(timeSpentDuration)}
                          </MinWidthText>
                          {dotSeparator}
                          <MinWidthText size='small' color='foreground/secondary'>
                            {toLocalDayFormat(DateTime.fromSQL(training.completionDate))}
                          </MinWidthText>
                        </View>
                      </View>
                      <View alignSelf='flex-start'>
                        <IconButton
                          size='small'
                          iconId='trash-can'
                          variant='transparent'
                          tooltip={t('dictionary.remove')}
                          onClick={() => {
                            confirmationModal.show({
                              title: t('user-transcript.remove-training-modal.title'),
                              bodyText: t('user-transcript.remove-training-modal.body', {
                                trainingTitle: training.title,
                              }),
                              deleteAction: true,
                              onConfirm: () => {
                                deleteTrainingMutation.mutate({ userId, trainingId: training.id })
                              },
                              confirmLabel: t('dictionary.remove'),
                            })
                          }}
                        />
                      </View>
                    </ExternalTrainingView>
                  )
                })
              )}
            </>
          </ContentWrapperView>
          <FullWidthHorizontalLineDivider />
          <Text bold color='foreground/muted'>
            {t('user-transcript.internal-trainings-title') + ' · ' + t('table.assigned')}
          </Text>
          <ContentWrapperView>
            {userTranscriptQueryResult.data.courses.length === 0 ? (
              <NoTrainingsView />
            ) : (
              userTranscriptQueryResult.data.courses.map(training => {
                const timeSpentDuration = Duration.fromObject({ minutes: training.timeSpentMinutes })
                const progressText =
                  training.progress === 0
                    ? t('manage.users.status.not-started')
                    : t('manage.users.status.n-completed', { n: percentage(training.progress) })

                return (
                  <View key={training.id} gap='16'>
                    <View direction='column' gap='4' grow>
                      <TruncatedText lines={1} size='regular' bold>
                        {training.name}
                      </TruncatedText>
                      <View gap='4'>
                        {isDefined(training.passedTimestamp) ? (
                          <MinWidthText size='small' color='foreground/secondary' bold>
                            {t('user-transcript.training-completed-on', {
                              passedDate: toLocalDayFormat(DateTime.fromISO(training.passedTimestamp)),
                            })}
                          </MinWidthText>
                        ) : (
                          <MinWidthText
                            size='small'
                            color='foreground/secondary'
                            bold={training.progress > 0}
                          >
                            {progressText}
                          </MinWidthText>
                        )}
                        {isDefined(training.startedTimestamp) && (
                          <>
                            {dotSeparator}
                            <MinWidthText size='small' color='foreground/secondary'>
                              {t('user-transcript.training-started-on', {
                                startedDate: toLocalDayFormat(DateTime.fromISO(training.startedTimestamp)),
                              })}
                            </MinWidthText>
                          </>
                        )}
                        {isDefined(training.timeSpentMinutes) && (
                          <>
                            {dotSeparator}
                            <TruncatedText lines={1} size='small' color='foreground/secondary'>
                              {timeSpentFromDuration(timeSpentDuration)}
                            </TruncatedText>
                          </>
                        )}
                      </View>
                    </View>
                  </View>
                )
              })
            )}
          </ContentWrapperView>
          <FullWidthHorizontalLineDivider />
          <Text bold color='foreground/muted'>
            {t('user-transcript.internal-trainings-title') + ' · ' + t('manage.users.self-started')}
          </Text>
          <ContentWrapperView>
            {userTranscriptQueryResult.data.selfStartedCourses.length === 0 ? (
              <NoTrainingsView />
            ) : (
              userTranscriptQueryResult.data.selfStartedCourses.map(training => {
                const timeSpentDuration = Duration.fromObject({ minutes: training.timeSpentMinutes })
                const progressText = t('manage.users.status.n-completed', {
                  n: percentage(training.progress),
                })

                return (
                  <View key={training.id} gap='16'>
                    <View direction='column' gap='4' grow>
                      <TruncatedText lines={1} size='regular' bold>
                        {training.name}
                      </TruncatedText>
                      <View gap='4'>
                        {isDefined(training.passedTimestamp) ? (
                          <MinWidthText size='small' color='foreground/secondary' bold>
                            {t('user-transcript.training-completed-on', {
                              passedDate: toLocalDayFormat(DateTime.fromISO(training.passedTimestamp)),
                            })}
                          </MinWidthText>
                        ) : (
                          <MinWidthText
                            size='small'
                            color='foreground/secondary'
                            bold={training.progress > 0}
                          >
                            {progressText}
                          </MinWidthText>
                        )}
                        {isDefined(training.startedTimestamp) && (
                          <>
                            {dotSeparator}
                            <MinWidthText size='small' color='foreground/secondary'>
                              {t('user-transcript.training-started-on', {
                                startedDate: toLocalDayFormat(DateTime.fromISO(training.startedTimestamp)),
                              })}
                            </MinWidthText>
                          </>
                        )}
                        {isDefined(training.timeSpentMinutes) && (
                          <>
                            {dotSeparator}
                            <TruncatedText lines={1} size='small' color='foreground/secondary'>
                              {timeSpentFromDuration(timeSpentDuration)}
                            </TruncatedText>
                          </>
                        )}
                      </View>
                    </View>
                  </View>
                )
              })
            )}
          </ContentWrapperView>
        </View>
      </ContentScrollView>
      <View justifyContent='space-between'>
        <Button variant='secondary' icon='download' onClick={() => openTranscriptViewer(userId)}>
          {t('manage.user.get-transcript')}
        </Button>
        <Button onClick={closePanel} variant='primary'>
          {t('dictionary.done')}
        </Button>
      </View>
    </WrapperView>
  )
}
