import _ from 'lodash'
import * as commands from 'sierra-client/views/v3-author/command'
import { insertNodeAfterNodeWithId, replaceNodeWithId } from 'sierra-client/views/v3-author/command'
import { getCurrentNodeEmpty } from 'sierra-client/views/v3-author/queries'
import { SlashMenuEntry } from 'sierra-client/views/v3-author/slash-menu/types'
import { Entity } from 'sierra-domain/entity'
import { UrlType } from 'sierra-domain/flexible-content/types'
import { nanoid12 } from 'sierra-domain/nanoid-extensions'
import { CustomElement, Image, Link, Paragraph, Video } from 'sierra-domain/v3-author'
import * as Blocks from 'sierra-domain/v3-author/create-blocks'
import { BaseRange, Editor, Location, Path, Transforms } from 'slate'

export const getPositionInDocument = (selection: BaseRange): number => {
  const [positionInDocument] = selection.anchor.path

  if (positionInDocument === undefined) {
    throw new Error('[getPositionInDocument] Position unvailable.')
  }

  return positionInDocument
}

export const replaceOrInsert = ({
  editor,
  id,
  element,
  at,
  forceInsert,
}: {
  editor: Editor
  id?: string
  element: CustomElement
  at?: Location
  forceInsert?: boolean // Always insert the new node after the current node
}): void => {
  const isCurrentNodeEmpty = getCurrentNodeEmpty(editor)

  if (isCurrentNodeEmpty && id !== undefined) {
    if (forceInsert === true) {
      insertNodeAfterNodeWithId(editor, id, element)
    } else {
      replaceNodeWithId(editor, id, element)
    }
  } else {
    Transforms.insertNodes(editor, element, { at })
  }
}

export const turnIntoParagraph: SlashMenuEntry['edit'] = ({ editor }) =>
  Transforms.setNodes(editor, { type: 'paragraph' })

export const addBlockAfterCurrentBlock = ({ path, editor }: { path: Path; editor: Editor }): void => {
  const [positionInDocument] = path
  if (positionInDocument === undefined) return

  const startPointId = nanoid12()
  const newBlock: Entity<Paragraph> = { type: 'paragraph', id: startPointId, children: [{ text: '' }] }

  replaceOrInsert({ editor, element: newBlock, at: [positionInDocument + 1], forceInsert: true })
  commands.selectNodeWithIdSynchronous(editor, startPointId)
  commands.scrollToSelectedNode(editor)
}

export const addHeading: (level: 0 | 2 | 3 | 5) => SlashMenuEntry['edit'] = level => {
  return ({ editor, currentId, lastSelection, forceInsert }) => {
    if (lastSelection === null) return

    const startPointId = nanoid12()

    replaceOrInsert({
      editor,
      id: currentId,
      element: { id: startPointId, type: 'heading', level, children: [{ text: '' }] },
      forceInsert,
    })

    commands.selectNodeWithIdSynchronous(editor, startPointId)
    commands.scrollToSelectedNode(editor)
  }
}

export const addParagraph: SlashMenuEntry['edit'] = ({ editor, currentId, lastSelection, forceInsert }) => {
  if (lastSelection === null) return

  const startPointId = nanoid12()

  replaceOrInsert({
    editor,
    id: currentId,
    element: {
      id: startPointId,
      type: 'paragraph',
      children: [{ text: '' }],
    },
    forceInsert,
  })

  commands.selectNodeWithIdSynchronous(editor, startPointId)
  commands.scrollToSelectedNode(editor)
}

export const addTakeaways: SlashMenuEntry['edit'] = ({ editor, currentId, lastSelection, forceInsert }) => {
  if (lastSelection === null) return

  const startPointId = nanoid12()

  replaceOrInsert({
    editor,
    id: currentId,
    element: Blocks.createTakeaways({
      children: [
        Blocks.createTakeawayItem({
          children: [
            Blocks.createParagraph({ id: startPointId, placeholder: 'author.slate.quote-title-placeholder' }),
          ],
        }),
      ],
    }),
    forceInsert,
  })
}

export const addLink: SlashMenuEntry['edit'] = ({ editor, lastSelection, dynamicT }) => {
  if (lastSelection === null) return
  const id = nanoid12()
  const linkText = dynamicT('author.slate.link.link')
  const link: Entity<Link> = { type: 'link', url: '', id, children: [{ text: linkText }] }
  Transforms.insertNodes(editor, link)
}

export const addBlockQuote: SlashMenuEntry['edit'] = ({ editor, currentId, lastSelection, forceInsert }) => {
  if (lastSelection === null) return

  const startPointId = nanoid12()
  replaceOrInsert({
    editor,
    id: currentId,
    element: Blocks.createBlockQuote({
      children: [
        Blocks.createParagraph({ id: startPointId, placeholder: 'author.slate.quote-title-placeholder' }),
        Blocks.createBlockQuoteSubtitle(),
      ],
    }),
    forceInsert,
  })
}

export const addVideo: SlashMenuEntry['edit'] = ({ editor, forceInsert, currentId }) => {
  const id = nanoid12()
  const video: Entity<Video> = {
    type: 'video',
    id,
    video: undefined,
    variant: 'narrow',
    credit: undefined,
    children: [{ text: '' }],
  }
  replaceOrInsert({
    id: currentId,
    editor,
    element: video,
    forceInsert,
  })
  commands.scrollToNodeWithId(editor, id)
}

export const addImage: SlashMenuEntry['edit'] = ({ editor, forceInsert, currentId }) => {
  const id = nanoid12()
  const image: Entity<Image> = {
    type: 'image',
    id,
    image: undefined,
    variant: 'narrow',
    columnVariant: 'padded',
    credit: undefined,
    altText: undefined,
    hotspots: {},
    hotspotsMandatory: false,
    children: [{ text: '' }],
  }
  replaceOrInsert({
    id: currentId,
    editor,
    element: image,
    forceInsert,
  })

  commands.selectNodeWithIdSynchronous(editor, id)
  commands.scrollToNodeWithId(editor, id)
}

export const addBulletedList =
  (): SlashMenuEntry['edit'] =>
  ({ editor, currentId, lastSelection, forceInsert }) => {
    if (lastSelection === null) return

    const startPointId = nanoid12()

    replaceOrInsert({
      editor,
      id: currentId,
      element: Blocks.createUnorderedList({ children: [Blocks.createListItem({ id: startPointId })] }),
      forceInsert,
    })

    commands.selectNodeWithIdSynchronous(editor, startPointId)
    commands.scrollToSelectedNode(editor)
  }

export const addNumberedList =
  (): SlashMenuEntry['edit'] =>
  ({ editor, currentId, lastSelection, forceInsert }) => {
    if (lastSelection === null) return

    const startPointId = nanoid12()

    replaceOrInsert({
      editor,
      id: currentId,
      element: Blocks.createOrderedList({ children: [Blocks.createListItem({ id: startPointId })] }),
      forceInsert,
    })

    commands.selectNodeWithIdSynchronous(editor, startPointId)
    commands.scrollToSelectedNode(editor)
  }

export const addChecklist: SlashMenuEntry['edit'] = ({ editor, lastSelection, currentId, forceInsert }) => {
  if (lastSelection === null) return

  const startPointId = nanoid12()

  replaceOrInsert({
    editor,
    id: currentId,
    element: Blocks.createCheckList({ children: [Blocks.createCheckListItem({ id: startPointId })] }),
    forceInsert,
  })
  commands.selectNodeWithIdSynchronous(editor, startPointId)
  commands.scrollToSelectedNode(editor)
}

export const addPreamble: SlashMenuEntry['edit'] = ({ editor, currentId, lastSelection, forceInsert }) => {
  if (lastSelection === null) return

  const startPointId = nanoid12()
  replaceOrInsert({
    editor,
    id: currentId,
    element: Blocks.createPreamble({ id: startPointId }),
    forceInsert,
  })
  commands.selectNodeWithIdSynchronous(editor, startPointId)
  commands.scrollToSelectedNode(editor)
}

export const addFileAttachment: SlashMenuEntry['edit'] = ({
  editor,
  showUploadFileModal,
  forceInsert,
  currentId,
  createPageContext,
}) => {
  const id = nanoid12()
  replaceOrInsert({
    id: currentId,
    editor,
    element: Blocks.createFileAttachment({ id }),
    forceInsert,
  })

  const assetContext = createPageContext?.createContentId
    ? { type: 'course' as const, courseId: createPageContext.createContentId }
    : { type: 'unknown' as const }

  showUploadFileModal({ nodeId: id, assetContext })
}

export const addSeparator: SlashMenuEntry['edit'] = ({ editor, currentId }) => {
  const id = nanoid12()
  replaceOrInsert({
    editor,
    id: currentId,
    element: {
      id,
      type: 'separator',
      variant: 'solid',
      children: [{ text: '' }],
    },
  })

  commands.selectNodeWithIdSynchronous(editor, id)
  commands.scrollToSelectedNode(editor)
}

export const addCode: SlashMenuEntry['edit'] = ({ editor, currentId, forceInsert }) => {
  const startPoint = nanoid12()

  replaceOrInsert({
    id: currentId,
    editor,
    element: {
      type: 'code',
      id: startPoint,
      language: 'unspecified-language',
      children: [{ text: '' }],
    },
    forceInsert,
  })

  commands.selectNodeWithIdSynchronous(editor, startPoint)
  commands.scrollToSelectedNode(editor)
}

export const addEmbed: (urlType: UrlType | undefined) => SlashMenuEntry['edit'] =
  urlType =>
  ({ editor, forceInsert, currentId }) => {
    const id = nanoid12()
    replaceOrInsert({
      id: currentId,
      editor,
      element: {
        id,
        type: 'embed',
        url: '',
        children: [{ text: '' }],
        html: '',
        variant: 'narrow',
        preventSeeking: false,
        ltiData: undefined,
        data: { type: 'legacy' },
        meta: undefined,
        isMandatory: false,
        urlType,
      },
      forceInsert,
    })

    commands.selectNodeWithIdSynchronous(editor, id)
    commands.scrollToSelectedNode(editor)
  }

export const addMarkdown: SlashMenuEntry['edit'] = ({ editor, forceInsert, currentId }) => {
  const startPointId = nanoid12()

  replaceOrInsert({
    id: currentId,
    editor,
    element: {
      id: startPointId,
      type: 'markdown',
      children: [{ text: '' }],
    },
    forceInsert,
  })

  commands.selectNodeWithIdSynchronous(editor, startPointId)
  commands.scrollToSelectedNode(editor)
}

export const addQuestionCard: SlashMenuEntry['edit'] = ({ editor, lastSelection }) => {
  if (lastSelection === null) return
  const positionInDocument = getPositionInDocument(lastSelection)

  const startPointId = nanoid12()

  replaceOrInsert({
    editor,
    element: Blocks.createQuestionCard({
      children: [Blocks.createParagraph(), ..._.drop(Blocks.createQuestionCard().children, 1)],
    }),
    at: [positionInDocument + 1],
  })

  setTimeout(() => {
    commands.selectNodeWithIdSynchronous(editor, startPointId)
    commands.scrollToSelectedNode(editor)
  })
}

export const addFlipCards: SlashMenuEntry['edit'] = ({ editor, lastSelection }) => {
  if (lastSelection === null) return
  const positionInDocument = getPositionInDocument(lastSelection)

  const startPointId = nanoid12()

  replaceOrInsert({
    editor,
    element: Blocks.createFlipCards({
      children: [
        Blocks.createFlipCardsContainer({
          children: [
            Blocks.createFlipCard({
              children: [Blocks.createFlipCardFront(), Blocks.createFlipCardBack()],
            }),
            Blocks.createFlipCard({
              children: [Blocks.createFlipCardFront(), Blocks.createFlipCardBack()],
            }),
          ],
        }),
      ],
    }),
    at: [positionInDocument + 1],
  })

  setTimeout(() => {
    commands.selectNodeWithIdSynchronous(editor, startPointId)
    commands.scrollToSelectedNode(editor)
  })
}

export const addMatrix: SlashMenuEntry['edit'] = ({ editor, lastSelection }) => {
  if (lastSelection === null) return
  const positionInDocument = getPositionInDocument(lastSelection)

  const startPointId = nanoid12()

  replaceOrInsert({
    editor,
    element: Blocks.createMatrix({ id: startPointId }),
    at: [positionInDocument + 1],
  })

  setTimeout(() => {
    commands.selectNodeWithIdSynchronous(editor, startPointId)
    commands.scrollToSelectedNode(editor)
  })
}

export const addSlidingScale: SlashMenuEntry['edit'] = ({ editor, lastSelection }) => {
  if (lastSelection === null) return
  const positionInDocument = getPositionInDocument(lastSelection)

  const startPointId = nanoid12()

  replaceOrInsert({
    editor,
    element: Blocks.createSlidingScaleCard({ id: startPointId }),
    at: [positionInDocument + 1],
  })

  setTimeout(() => {
    commands.selectNodeWithIdSynchronous(editor, startPointId)
    commands.scrollToSelectedNode(editor)
  })
}

export const addTable: SlashMenuEntry['edit'] = ({ editor, lastSelection }) => {
  if (lastSelection === null) return

  const positionInDocument = getPositionInDocument(lastSelection)
  const startPointId = nanoid12()

  replaceOrInsert({
    editor,
    element: Blocks.createTable({ id: startPointId }),
    at: [positionInDocument],
  })

  commands.selectNodeWithIdSynchronous(editor, startPointId)
  commands.scrollToSelectedNode(editor)
}

export const addSections: (number: number) => SlashMenuEntry['edit'] = number => {
  return ({ editor, lastSelection }) => {
    if (lastSelection === null) return

    const positionInDocument = getPositionInDocument(lastSelection)
    const startPointId = nanoid12()
    const children = _.range(number).map(() => Blocks.createVerticalStack())

    replaceOrInsert({
      editor,
      element: Blocks.createHorizontalStack({
        id: startPointId,
        children: children,
      }),
      at: [positionInDocument],
    })

    commands.selectNodeWithIdSynchronous(editor, startPointId)
    commands.scrollToSelectedNode(editor)
  }
}
