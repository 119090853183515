import { FC } from 'react'
import { useOrganizationPermissions } from 'sierra-client/hooks/use-permissions'
import { useTranslation } from 'sierra-client/hooks/use-translation'
import { useSelector } from 'sierra-client/state/hooks'
import { selectOrganization } from 'sierra-client/state/organization/selectors'
import { MenuButton, MenuItem } from 'sierra-ui/components'

export const ImportCourse: FC<{
  onSelect: (target: 'linked-in' | 'scorm' | 'direct-link') => void
}> = ({ onSelect }) => {
  const { t } = useTranslation()
  const organization = useSelector(selectOrganization)
  const orgPermissions = useOrganizationPermissions()

  const showLinkedInImport = organization?.linkedinEnabled === true && orgPermissions.has('IMPORT_LINKEDIN')
  const showScormImport = orgPermissions.has('IMPORT_SCORM')
  const showLinkImport = orgPermissions.has('IMPORT_LINK')

  const menuItems = [
    {
      type: 'label',
      id: 'linked-in' as const,
      label: 'LinkedIn Learning',
      hidden: !showLinkedInImport,
    },
    {
      type: 'label',
      id: 'scorm' as const,
      label: t('manage.courses.import.scorm'),
      hidden: !showScormImport,
    },
    {
      type: 'label',
      id: 'direct-link' as const,
      label: t('manage.courses.import.direct-link'),
      hidden: !showLinkImport,
    },
  ] satisfies MenuItem[]

  if (!menuItems.some(it => !it.hidden)) return null

  return (
    <MenuButton
      variant='secondary'
      menuItems={menuItems}
      onSelect={item => {
        onSelect(item.id)
      }}
    >
      {t('manage.course.import')}
    </MenuButton>
  )
}
