import { createFileRoute } from '@tanstack/react-router'
import { requireLoggedIn } from 'sierra-client/core/require-logged-in'
import { store } from 'sierra-client/state/store'
import { fetchCourseDataById, fetchCourseStatusById } from 'sierra-client/state/v2/courses-actions'
import { fetchTagsData } from 'sierra-client/state/v2/tags-actions'
import { CourseDetailsPage } from 'sierra-client/views/learner/course/course-details-page'
import { SelfPacedContentId } from 'sierra-domain/api/nano-id'
import { z } from 'zod'

export const Route = createFileRoute('/s/$flexibleContentId/')({
  component: requireLoggedIn(CourseDetailsPage, { allowScorm: true }) as React.FC,
  validateSearch: z.object({
    'smart-review': z.boolean().optional(),
  }),
  params: {
    parse: z.object({ flexibleContentId: SelfPacedContentId }).parse,
    stringify: p => p,
  },
  loader: ({ context, params }) => {
    if (context.userId !== undefined) {
      void store.dispatch(fetchTagsData())
      void store.dispatch(fetchCourseDataById({ courseId: params.flexibleContentId }))
      void store.dispatch(fetchCourseStatusById({ courseId: params.flexibleContentId }))
    }
  },
})
