import { CardCanvas } from 'sierra-client/views/shared/card-canvas'
import styled from 'styled-components'

export const SelfPacedCardCanvas = styled(CardCanvas)`
  height: 100%;
  flex-direction: column;
  overflow: hidden;

  animation: fade-in 100ms ease forwards;
  opacity: 0;
  @keyframes fade-in {
    100% {
      opacity: 1;
    }
  }
`
