import React, { useMemo } from 'react'
import { toLabel } from 'sierra-client/features/insights/display-widgets/utils'
import { useInsightsDimensions } from 'sierra-client/features/insights/hooks/use-insights-views'
import { dimensionIconMap } from 'sierra-client/features/insights/widget-builder/data-selectors/dimension-icon-map'
import { FilterSelector } from 'sierra-client/features/insights/widget-builder/data-selectors/filter-selector'
import { MenuItemWithRef } from 'sierra-client/features/insights/widget-builder/data-selectors/single-dimension-selector'
import { useTranslation } from 'sierra-client/hooks/use-translation'
import { TranslationLookup } from 'sierra-client/hooks/use-translation/types'
import { useGenerateFilter } from 'sierra-client/lib/tabular/components/util'
import { labelToString } from 'sierra-client/lib/tabular/datatype/label'
import { Dimensions, FilterDomain, Widget, areDimensionRefsEqual } from 'sierra-domain/api/insights'
import { NanoId12 } from 'sierra-domain/api/nano-id'
import { Filter } from 'sierra-domain/filter/datatype/filter'
import { assertNever } from 'sierra-domain/utils'

const emptyMenuItems: MenuItemWithRef[] = [] as const

export const dimensionsToDashboardFilterMenuItem = ({
  dimensions,
  widgetType,
  t,
  filterDomains,
}: {
  dimensions: Dimensions
  widgetType?: Widget['type']
  t: TranslationLookup
  filterDomains: FilterDomain[]
}): MenuItemWithRef[] => {
  switch (dimensions.type) {
    case 'dimensions.dimension': {
      const disabled = !filterDomains.some(domain => areDimensionRefsEqual(domain.ref, dimensions.dimension))

      return [
        {
          type: 'label',
          ref: dimensions.dimension,
          id: dimensions.id,
          label: labelToString(toLabel(dimensions.label), t),
          disabled,
          tooltip: disabled ? t('manage.insights.select-dimension.disabled-dashboard-tooltip') : undefined,
          icon: dimensionIconMap[dimensions.id],
        },
      ]
    }
    case 'dimensions.group': {
      const menuItems = dimensions.dimensions.flatMap(dimensions =>
        dimensionsToDashboardFilterMenuItem({
          dimensions,
          t,
          widgetType,
          filterDomains,
        })
      )

      return [
        {
          type: 'nested',
          id: dimensions.id,
          label: labelToString(toLabel(dimensions.label), t),
          menuItems: menuItems,
          icon: dimensionIconMap[dimensions.id],
          ...(menuItems.every(menuItem => menuItem.disabled)
            ? {
                disabled: true,
                tooltip:
                  menuItems[0] !== undefined && 'tooltip' in menuItems[0] ? menuItems[0].tooltip : undefined,
              }
            : {}),
        },
      ]
    }
    case 'dimensions.section': {
      return [
        ...dimensions.dimensions.flatMap(dimensions =>
          dimensionsToDashboardFilterMenuItem({
            dimensions,
            t,
            widgetType,
            filterDomains,
          })
        ),
        { type: 'separator', id: `separator-${Math.random()}` },
      ]
    }
    default:
      assertNever(dimensions)
  }
}

export const DashboardFilterSelector: React.FC<{
  dashboardId?: NanoId12
  selectedFilter?: Filter
  onChange: (newFilter: Filter) => void
  filterDomains?: FilterDomain[]
  widgetType?: Widget['type']
  enableResetFilter?: {
    initialFilter: Filter
  }
}> = ({ dashboardId, onChange, selectedFilter, filterDomains, widgetType, enableResetFilter }) => {
  const { t } = useTranslation()

  const allDimensions = useInsightsDimensions()

  const menuItems: MenuItemWithRef[] = useMemo(() => {
    return allDimensions === undefined || filterDomains === undefined
      ? emptyMenuItems
      : allDimensions.flatMap(it =>
          dimensionsToDashboardFilterMenuItem({
            dimensions: it,
            filterDomains,
            widgetType,
            t,
          })
        )
  }, [allDimensions, filterDomains, t, widgetType])

  const generateFilter = useGenerateFilter(
    dashboardId !== undefined ? { type: 'insights.dashboard', dashboardId } : undefined,
    onChange
  )

  return (
    <FilterSelector
      onChange={onChange}
      menuItems={menuItems}
      selectedFilter={selectedFilter}
      filterDomains={filterDomains}
      generateFilter={dashboardId !== undefined ? generateFilter : undefined}
      enableResetFilter={enableResetFilter}
    />
  )
}
