import { atom, useAtom, useAtomValue, useSetAtom } from 'jotai'
import React, { useCallback, useEffect } from 'react'
import { createPortal } from 'react-dom'
import { GlobalSidebarTabs } from 'sierra-client/features/global-sidebar/components/tabs'
import { useIsSidebarNavigationAllowed } from 'sierra-client/features/global-sidebar/hooks/use-is-sidebar-navigation-allowed'
import { useIsCreateAccessible } from 'sierra-client/hooks/use-create-enabled'
import { useTranslation } from 'sierra-client/hooks/use-translation'
import { CreateNewCourseButton } from 'sierra-client/views/manage/components/create-new-course-button'
import { View } from 'sierra-ui/primitives'
import styled from 'styled-components'

export type GlobalNestedSidebarComponent<T = unknown> = React.FC<T>
export type GlobalNestedBottomComponent = React.FC

export const nestedSidebarContext = atom<{
  Sidebar: GlobalNestedSidebarComponent | undefined
  sidebarContentRef: React.MutableRefObject<HTMLDivElement | null>
  sidebarBottomRef: React.MutableRefObject<HTMLDivElement | null>
  level: 'root' | 'nested'
}>({
  sidebarContentRef: React.createRef<HTMLDivElement | null>(),
  sidebarBottomRef: React.createRef<HTMLDivElement | null>(),
  level: 'root',
  Sidebar: undefined,
})

export const NestedSidebarViewer: React.FC = () => {
  const [context, setContext] = useAtom(nestedSidebarContext)

  const allowNavigation = useIsSidebarNavigationAllowed()

  const setRef = useCallback(
    (element: HTMLDivElement | null) => {
      setContext(prev => {
        return {
          ...prev,
          sidebarContentRef: { current: element },
        }
      })
    },
    [setContext]
  )

  return (
    <>
      <div ref={setRef} />
      {allowNavigation && context.level === 'root' && <GlobalSidebarTabs />}
    </>
  )
}

const FullWidthPortalDiv = styled.div`
  width: 100%;
`

export const BottomSidebarViewer: React.FC = () => {
  const setContext = useSetAtom(nestedSidebarContext)
  const setRef = useCallback(
    (element: HTMLDivElement | null) => {
      setContext(prev => {
        return {
          ...prev,
          sidebarBottomRef: { current: element },
        }
      })
    },
    [setContext]
  )
  return <FullWidthPortalDiv ref={setRef} />
}

export const NestedSidebarButtonViewer: React.FC = () => {
  const { t } = useTranslation()
  const isCreateAccessible = useIsCreateAccessible()
  const level = useAtomValue(nestedSidebarContext).level

  if (level === 'nested') return null
  else if (!isCreateAccessible) return null
  else
    return (
      <View margin='16' marginTop='none' justifyContent='center'>
        <CreateNewCourseButton label={t('topbar.create-new')} />
      </View>
    )
}

const NestedSidebar: React.FC<{
  renderSidebar: GlobalNestedSidebarComponent
}> = ({ renderSidebar }) => {
  const [context, setContext] = useAtom(nestedSidebarContext)

  // Move into the "nested" state when mounting this component
  useEffect(() => {
    setContext(prev => {
      if (prev.level === 'nested')
        console.error(
          'Nested global sidebars detected. This may cause errors. You should only have one nested sidebar per page.'
        )
      return { ...prev, renderSidebar: renderSidebar, level: 'nested' }
    })
    return () => setContext(prev => ({ ...prev, level: 'root', Sidebar: undefined }))
  }, [setContext, renderSidebar])

  const node = context.sidebarContentRef.current
  if (!node) return null
  return createPortal(renderSidebar({}), node)
}

const NestedBottomContainer: React.FC<{
  renderBottomContent: GlobalNestedBottomComponent
}> = ({ renderBottomContent }) => {
  const context = useAtomValue(nestedSidebarContext)

  const node = context.sidebarBottomRef.current
  if (!node) return null
  if (context.level === 'root') return null
  return createPortal(renderBottomContent({}), node)
}

export const GlobalNestedSidebar: typeof NestedSidebar = props => <NestedSidebar {...props} />
export const GlobalNestedBottomComponent: typeof NestedBottomContainer = props => (
  <NestedBottomContainer {...props} />
)
