import { DateTime } from 'luxon'
import { date } from 'sierra-client/core/format'
import { TimeFilter, TimeFilterLast } from 'sierra-domain/api/insights'
import { assertNever } from 'sierra-domain/utils'

export const getDateStringFromTimeFilterLast = (timeFilterLast: TimeFilterLast): string => {
  const now = DateTime.now()

  switch (timeFilterLast.unit) {
    case 'unit.temporal.date': {
      const start = now.minus({ days: timeFilterLast.amount })
      return start.toSQLDate()
    }
    case 'unit.temporal.year_month': {
      const start = now.minus({ months: timeFilterLast.amount })
      return start.toSQLDate()
    }
    case 'unit.temporal.year_week': {
      const start = now.minus({ weeks: timeFilterLast.amount })
      return start.toSQLDate()
    }
    case 'unit.temporal.year_quarter': {
      const start = now.minus({ months: timeFilterLast.amount * 3 })
      return start.toSQLDate()
    }
    case 'unit.temporal.year': {
      const start = now.minus({ years: timeFilterLast.amount })
      return start.toSQLDate()
    }
    default:
      assertNever(timeFilterLast.unit)
  }
}

export const getDescriptionFromTimeFilterItem = (timeFilter: TimeFilter): string => {
  switch (timeFilter.type) {
    case 'time.last':
      return `${date(getDateStringFromTimeFilterLast(timeFilter))} - ${date(DateTime.now().toSQLDate())}`
    case 'time.between':
      return `${date(timeFilter.startDate)} - ${date(timeFilter.endDate)}`
    case 'time.since':
      return `Since ${date(timeFilter.date)}`
  }
}
