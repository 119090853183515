import { RequestStatus } from 'sierra-client/api/graphql/gql/graphql'
import { assertNever } from 'sierra-domain/utils'

type BaseEnrollmentStatus =
  | 'can-request-seat'
  | 'pending-enrollment-request'
  | 'enrolled'
  | 'can-self-enroll'
  | 'enrollment-request-rejected'

export type EnrollmentStatus =
  | BaseEnrollmentStatus
  | 'disabled-self-enroll'
  | 'disabled-request-seat'
  | 'disabled-enrolled'

export type GetListItemEnrollmentStatusParams = {
  isEnrolled: boolean
  requiresApproval: boolean
  currentRequestStatus: RequestStatus | undefined
  selfEnrollAvailable: boolean
}

export const getListItemEnrollmentStatus = ({
  isEnrolled,
  requiresApproval,
  currentRequestStatus,
  selfEnrollAvailable,
}: GetListItemEnrollmentStatusParams): EnrollmentStatus => {
  // This is the default state of the button, but some of the possible states can be overridden
  // if self enrollment is not available, this can happen if the session is full.
  const baseStatus: BaseEnrollmentStatus = (() => {
    if (isEnrolled) {
      return 'enrolled'
    }

    if (requiresApproval) {
      switch (currentRequestStatus) {
        case undefined:
          return 'can-request-seat'
        case 'APPROVED':
          return 'can-self-enroll'
        case 'REJECTED':
          return 'enrollment-request-rejected'
        case 'PENDING':
          return 'pending-enrollment-request'
        default:
          assertNever(currentRequestStatus)
      }
    }

    return 'can-self-enroll'
  })()

  switch (baseStatus) {
    case 'enrolled':
      return selfEnrollAvailable ? baseStatus : 'disabled-enrolled'
    case 'can-self-enroll':
      return selfEnrollAvailable ? baseStatus : 'disabled-self-enroll'
    case 'can-request-seat':
      return selfEnrollAvailable ? baseStatus : 'disabled-request-seat'
    case 'pending-enrollment-request':
    case 'enrollment-request-rejected':
      return baseStatus
    default:
      assertNever(baseStatus)
  }
}
