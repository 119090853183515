import { Trans } from 'sierra-client/hooks/use-translation/trans'
import { TranslationKey } from 'sierra-client/hooks/use-translation/types'
import { Rating } from 'sierra-client/views/manage/homeworks/manage-homeworks-modal/types'
import { SubmissionForUser } from 'sierra-domain/homework'
import { StaticRatingNumber } from 'sierra-ui/missions/workflows/homework-criteria/rating-criteria'
import { Text, View } from 'sierra-ui/primitives'

// content.homework.criteria-rating-1-to-5-result
export const ReviewCriteriaList: React.FC<{
  criteriaValues: SubmissionForUser['reviewInfo']['criteriaValues']
}> = ({ criteriaValues }) => (
  <View direction='column' marginBottom='40' gap='16' padding='none 24'>
    {criteriaValues.map(criteriaValue => (
      <View key={criteriaValue.criteriaId} justifyContent='space-between'>
        <Text bold>{criteriaValue.title}</Text>
        <View gap='8'>
          <Trans
            i18nKey={'content.homework.criteria-rating-1-to-5-result' satisfies TranslationKey}
            values={{
              rating: criteriaValue.data.rating,
              max_rating: 5,
            }}
            components={{
              /* TODO: Don't cast rating here. Either do it with zod or handle other values
                   gracefully in the component. */
              rating: <StaticRatingNumber rating={criteriaValue.data.rating as Rating} />,
              rest: <Text bold color='foreground/muted' />,
            }}
          />
        </View>
      </View>
    ))}
  </View>
)
