import React from 'react'
import { useIsDebugMode } from 'sierra-client/hooks/use-is-debug-mode'
import { removeNodeWithId, updateNodeWithId } from 'sierra-client/views/v3-author/command'
import { ImageSelector } from 'sierra-client/views/v3-author/images/image-selector'
import { useUnsplashSuggestions } from 'sierra-client/views/v3-author/images/use-unsplash-suggestions'
import { AssetContext } from 'sierra-domain/asset-context'
import { spacing } from 'sierra-ui/theming'
import { useSlateStatic } from 'slate-react'
import styled from 'styled-components'

const DebugQuery = styled.div`
  position: absolute;
  bottom: ${spacing['8']};
  left: ${spacing['8']};
  background-color: black;
  border-radius: ${p => p.theme.borderRadius['size-6']};
  color: white;
  padding: ${spacing['4']};
`

type Props = {
  nodeId: string
  openModal: () => void
  initialFile: File | undefined
  assetContext: AssetContext
}

export const ImageSelectorWithSuggestions: React.FC<Props> = ({
  nodeId,
  openModal,
  initialFile,
  assetContext,
}) => {
  const isDebugMode = useIsDebugMode()
  const editor = useSlateStatic()
  const { query, suggestions } = useUnsplashSuggestions({ nodeId })

  return (
    <>
      <ImageSelector
        onOpen={openModal}
        onDelete={() => removeNodeWithId(editor, nodeId)}
        onUploadDone={image => updateNodeWithId(editor, nodeId, { image })}
        contentEditable={false}
        unsplashSuggestions={suggestions}
        initialFile={initialFile}
        assetContext={assetContext}
      />
      {isDebugMode && <DebugQuery>Query: {query}</DebugQuery>}
    </>
  )
}
