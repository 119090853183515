import { createFileRoute } from '@tanstack/react-router'
import { useMemo } from 'react'

import { requireLoggedIn } from 'sierra-client/core/require-logged-in'
import { VersionHistoryPage } from 'sierra-client/editor/version-history/page'
import { LiveContentId } from 'sierra-domain/api/nano-id'
import { ScopedLiveContentId } from 'sierra-domain/collaboration/types'
import { FileId } from 'sierra-domain/flexible-content/identifiers'
import { z } from 'zod'

const SplatParamSchema = z.tuple([LiveContentId]).or(z.tuple([LiveContentId, FileId]))

function Page(): JSX.Element | null {
  const [contentId, nodeId] = Route.useParams({
    select: params => SplatParamSchema.parse(params._splat?.split('/')),
  })

  const scopedContentId = ScopedLiveContentId.fromId(contentId)

  const assetContext = useMemo(() => ({ type: 'course' as const, courseId: contentId }), [contentId])

  return (
    <VersionHistoryPage
      publishVersion={undefined}
      scopedContentId={scopedContentId}
      nodeId={nodeId}
      assetContext={assetContext}
    />
  )
}

const RouteComponent = requireLoggedIn(Page)

export const Route = createFileRoute('/history/l/$')({
  component: RouteComponent as React.FC,
})
