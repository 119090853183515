import { useMemo } from 'react'
import { Logging } from 'sierra-client/core/logging'
import { useDispatch } from 'sierra-client/state/hooks'

type UserGroupTracking = {
  static: {
    create: (args: { id: string; name?: string; description?: string }) => void
    delete: (id: string) => void
  }
  smart: {
    create: (args: { id: string; name?: string; description?: string }) => void
    delete: (id: string) => void
  }
}
export const useTracking = (): UserGroupTracking => {
  const dispatch = useDispatch()

  return useMemo(
    () => ({
      static: {
        create: args => dispatch(Logging.group.groupCreate(args)),
        delete: id => dispatch(Logging.group.groupDelete({ id })),
      },
      smart: {
        create: args => dispatch(Logging.group.smartGroupCreate(args)),
        delete: id => dispatch(Logging.group.smartGroupDelete({ id })),
      },
    }),
    [dispatch]
  )
}
