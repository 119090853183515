import { useEffect, useMemo } from 'react'
import { useAssetResolver } from 'sierra-client/hooks/use-resolve-asset'
import { selectFlexibleContentFile } from 'sierra-client/state/flexible-content/selectors'
import { useSelector } from 'sierra-client/state/hooks'
import { preloadFileImage } from 'sierra-client/views/flexible-content/card-background/preload-file-image'
import { LiveContentId } from 'sierra-domain/api/nano-id'
import { AssetContext } from 'sierra-domain/asset-context'
import { FileId } from 'sierra-domain/flexible-content/identifiers'

export const NextCardImagePreload = ({
  nextCardId,
  flexibleContentId,
}: {
  nextCardId: FileId
  flexibleContentId: LiveContentId
}): null => {
  const nextFile = useSelector(state => selectFlexibleContentFile(state, flexibleContentId, nextCardId))

  const assetResolver = useAssetResolver({ size: 'default' })

  const assetContext: AssetContext = useMemo(() => {
    return { type: 'course', courseId: flexibleContentId }
  }, [flexibleContentId])

  useEffect(() => {
    if (nextFile) preloadFileImage(nextFile, assetResolver, assetContext)
  }, [assetContext, assetResolver, nextFile])

  return null
}
