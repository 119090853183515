import { FC, useCallback, useState } from 'react'
import { Card } from 'sierra-client/features/multi-tenancy/components'
import { constructDistributionPatch } from 'sierra-client/features/multi-tenancy/hooks/use-course-distribution-settings'
import { useOrganizationCluster } from 'sierra-client/features/multi-tenancy/hooks/use-organization-cluster'
import { useTranslation } from 'sierra-client/hooks/use-translation'
import { ContentDistributionSettings, CourseVisibilityInOrg } from 'sierra-domain/api/author-v2'
import { AccessibleOrganization } from 'sierra-domain/multi-tenancy'
import { isDefined } from 'sierra-domain/utils'
import { Tooltip } from 'sierra-ui/components'
import { Checkbox, Text, View } from 'sierra-ui/primitives'
import { ConditionalWrapper } from 'sierra-ui/utils'

export const OrganizationSettingsCard: FC<{
  organization: AccessibleOrganization
  onTogglePublishing: (org: AccessibleOrganization, checked: boolean) => void
  locked: boolean
  unavailable: boolean
  publishingInitial: boolean
}> = ({ organization, onTogglePublishing, locked, unavailable, publishingInitial }) => {
  const { t } = useTranslation()
  const [state, setState] = useState(publishingInitial)

  const onCheckedChange = useCallback(
    (n: boolean | 'indeterminate') => {
      const nextChecked = n === true
      setState(nextChecked)
      onTogglePublishing(organization, nextChecked)
    },
    [organization, onTogglePublishing]
  )

  return (
    <ConditionalWrapper
      condition={unavailable}
      renderWrapper={children => (
        <Tooltip title={t('multi-tenancy.content-distribution.sub-org-disabled')}>{children}</Tooltip>
      )}
    >
      <Card $disabled={unavailable}>
        <Checkbox checked={state} onCheckedChange={onCheckedChange} disabled={locked || unavailable} />
        <Text bold color='currentColor'>
          {organization.name}
        </Text>
      </Card>
    </ConditionalWrapper>
  )
}

export const OrganizationClusterVisibilitySettings: FC<{
  visibility: CourseVisibilityInOrg
  onSettingsChanged: (settings: ContentDistributionSettings[]) => void
}> = ({ visibility, onSettingsChanged }) => {
  const { t } = useTranslation()
  const cluster = useOrganizationCluster()
  const [enabledSettings, setEnabledSettings] = useState<Record<string, ContentDistributionSettings>>({})

  const hasCluster = isDefined(cluster.cluster) && cluster.cluster.length > 0

  const onTogglePublishing = useCallback(
    (organization: AccessibleOrganization, checked: boolean) => {
      if (visibility === 'private') throw new Error('Invalid visibility')

      const patch = constructDistributionPatch(organization, { visibility })

      let settings
      if (checked) {
        settings = {
          ...enabledSettings,
          [organization.namespaceId]: patch,
        }
      } else {
        const { [organization.namespaceId]: removedEntry, ...remainingSettings } = enabledSettings
        settings = remainingSettings
      }

      setEnabledSettings(settings)
      onSettingsChanged(Object.values(settings))
    },
    [enabledSettings, onSettingsChanged, visibility]
  )

  if (cluster.loading || !hasCluster || !cluster.selfIsParent) {
    return false
  }

  return (
    <View direction='column'>
      <Text bold color='foreground/muted'>
        {t('multi-tenancy.content-distribution.publish-to')}
      </Text>

      <View direction='column' gap='6'>
        {cluster.cluster.map(org => {
          const unavailable = !org.isClusterParent && visibility === 'private'

          return (
            <OrganizationSettingsCard
              key={org.domain}
              organization={org}
              onTogglePublishing={onTogglePublishing}
              locked={org.isClusterParent}
              unavailable={unavailable}
              publishingInitial={org.isClusterParent}
            />
          )
        })}
      </View>
    </View>
  )
}
