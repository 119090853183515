import React, { useMemo, useState } from 'react'
import { CellProps, Column } from 'react-table'
import { Pill, PillRow } from 'sierra-client/components/common/pill'
import { SelectableHeader, SelectableRow } from 'sierra-client/components/table/select'
import { SortableHeader } from 'sierra-client/components/table/sortable-header'
import { useTranslation } from 'sierra-client/hooks/use-translation'
import { BetweenContainer, TableFooter } from 'sierra-client/views/manage/components/common'
import { LibraryTable, useLibraryTable } from 'sierra-client/views/manage/components/library-table'
import { TagForm } from 'sierra-client/views/manage/tags/components/tag-form'
import { useTags } from 'sierra-client/views/manage/tags/use-tags'
import { TagDetail } from 'sierra-domain/api/admin'
import { Tag } from 'sierra-domain/content/tag'
import { Modal, TruncatedText } from 'sierra-ui/components'
import { Button, Heading, Spacer, Text, View } from 'sierra-ui/primitives'
import { token } from 'sierra-ui/theming'
import styled from 'styled-components'

interface CreateTagViewProps {
  tag?: Tag
  onCancel: () => void
  onAfterSave: () => void
}

const Divider = styled.div`
  width: 100%;
  background-color: ${token('border/strong')};
  height: 1px;
`

const CreateTagView: React.FC<CreateTagViewProps> = ({ tag, onCancel, onAfterSave }) => {
  const { t } = useTranslation()

  return (
    <>
      <Heading color='LEGACY_DEFAULT_HEADING_COLOR_REPLACE_ASAP' size='h4' bold>
        {t('admin.manage.tags.create-tag')}
      </Heading>
      <Spacer size='large' />
      <TagForm tag={tag} onAfterSubmit={onAfterSave} onCancel={onCancel} />
    </>
  )
}

interface AddTagViewProps {
  onCreate: () => void
  onSave: (tagIds: string[]) => void | Promise<void>
}

const TableSizer = styled(View)`
  min-width: 600px;
`

const AddTagView: React.FC<AddTagViewProps> = ({ onCreate, onSave }) => {
  const { t } = useTranslation()
  const { libraryTags, isLoading } = useTags()

  const columns: Column<TagDetail>[] = useMemo(
    () => [
      {
        id: 'name',
        width: '40%',
        Header: p => (
          <>
            <SelectableHeader {...p} />
            <SortableHeader label={t('table.name')} {...p} />
          </>
        ),
        accessor: tag => tag.tag.data.name,
        canSort: true,
        Cell: (p: CellProps<TagDetail>) => {
          const { name } = p.row.original.tag.data
          return (
            <>
              <SelectableRow {...p} />
              <PillRow>{name ? <Pill>{name}</Pill> : ''}</PillRow>
            </>
          )
        },
      },
      {
        id: 'description',
        width: '50%',
        Header: p => <SortableHeader label={t('admin.organization.description')} {...p} />,
        accessor: tag => tag.tag.data.description,
        Cell: ({ row }: CellProps<TagDetail>): JSX.Element => (
          <>
            {row.original.tag.data.description === undefined ||
            row.original.tag.data.description === '' ? null : ( // t('t.no-description')
              <TruncatedText lines={1} size='small' color='grey50'>
                {row.original.tag.data.description}
              </TruncatedText>
            )}
          </>
        ),
      },
    ],
    [t]
  )

  const { tableInstance } = useLibraryTable({ data: libraryTags, columns, getEntityId: s => s.id })

  const selectedTagIds = tableInstance.selectedFlatRows.map(r => r.original.tag.id)

  return (
    <>
      <Heading color='LEGACY_DEFAULT_HEADING_COLOR_REPLACE_ASAP' size='h4' bold>
        {t('manage.skill-library.title')}
      </Heading>
      <TableSizer>
        <LibraryTable
          tableInstance={tableInstance}
          isLoading={isLoading}
          translations={{
            tableLoading: t('manage.tags.table-loading'),
            tableEnd: t('manage.tags.table-end'),
            tableNoResults: t('manage.tags.no-results'),
            searchPlaceholder: t('manage.search.tags'),
          }}
        />
      </TableSizer>
      <TableFooter>
        <Divider />
        <Spacer size='small' />
        {selectedTagIds.length > 0 ? (
          <BetweenContainer>
            <Text size='small' bold>
              {t('table.n-selected', { count: selectedTagIds.length })}
            </Text>
            <View gap='xsmall'>
              <Button variant='secondary' onClick={() => tableInstance.toggleAllRowsSelected(false)}>
                {t('dictionary.cancel')}
              </Button>
              <Button
                onClick={async () => {
                  await onSave(selectedTagIds)
                }}
              >
                {t('dictionary.add')}
              </Button>
            </View>
          </BetweenContainer>
        ) : (
          <Button onClick={onCreate}>{t('manage.create-new')}</Button>
        )}
      </TableFooter>
    </>
  )
}

type TagView = 'add' | 'create'

interface TagLibraryModalProps {
  isOpen: boolean
  onClose: () => void
  onSave: (tagIds: string[]) => void | Promise<void>
  onAfterSave: () => void
}

export const TagLibraryModal: React.FC<TagLibraryModalProps> = ({ isOpen, onClose, onSave, onAfterSave }) => {
  const [currentView, setCurrentView] = useState<TagView>('add')

  return (
    <Modal open={isOpen} onClose={onClose} size='fit-content'>
      <View padding='medium' direction='column'>
        {currentView === 'create' ? (
          <CreateTagView onAfterSave={onAfterSave} onCancel={() => setCurrentView('add')} />
        ) : (
          <AddTagView onSave={onSave} onCreate={() => setCurrentView('create')} />
        )}
      </View>
    </Modal>
  )
}
