import _ from 'lodash'
import React, { useEffect, useMemo, useState } from 'react'
import { CSVLink } from 'react-csv'
import { useInView } from 'react-intersection-observer'
import { CellProps, Column, useGlobalFilter, useRowSelect, useSortBy, useTable } from 'react-table'
import { RouterLink } from 'sierra-client/components/common/link'
import { Menu } from 'sierra-client/components/common/menu'
import { SelectableHeader, SelectableRow, TableBulkActions } from 'sierra-client/components/table/select'
import { SortableHeader } from 'sierra-client/components/table/sortable-header'
import { Table } from 'sierra-client/components/table/table'
import {
  TableMediumSearchHeaderManual,
  TableMediumSearchTrigger,
} from 'sierra-client/components/table/table-medium'
import { SmallTableScrollContainer } from 'sierra-client/components/table/table-utils'
import { getFlag } from 'sierra-client/config/global-config'
import { useDebouncedAndLiveState } from 'sierra-client/hooks/use-debounced-state'
import { useTranslation } from 'sierra-client/hooks/use-translation'
import { getGlobalRouter } from 'sierra-client/router'
import { getAvatarImage } from 'sierra-client/utils/avatar-img'
import {
  ActionButton,
  InfiniteScrollMessage,
  MenuContainer,
} from 'sierra-client/views/manage/components/common'
import { ExportCSVIconButton, getCsvFileName } from 'sierra-client/views/manage/components/export-csv'
import { SmallTableWrapper } from 'sierra-client/views/manage/components/small-table-wrapper'
import { AttendanceMenuItems } from 'sierra-client/views/manage/live-session/components/attendance-menu'
import { getAttendanceTranslationKey } from 'sierra-client/views/manage/live-session/live-session-utils'
import { UseLiveSessionDetailsData } from 'sierra-client/views/manage/live-session/use-live-session-details'
import { userBaseToCsv } from 'sierra-client/views/manage/users/user-utils'
import { Separator } from 'sierra-client/views/showcase/common'
import { LiveSessionUserInfo } from 'sierra-domain/api/admin'
import { LiveSessionAttendanceStatus } from 'sierra-domain/api/manage'
import { UserId } from 'sierra-domain/api/uuid'
import { RoundAvatar, getAvatarPropsFromBaseUserInfo } from 'sierra-ui/components'
import { MUIMenuItem } from 'sierra-ui/mui'
import { Button, Spacer, Text, View } from 'sierra-ui/primitives'

const userToCsv = (userAssignment: LiveSessionUserInfo): Record<string, string> => ({
  ...userBaseToCsv(userAssignment.userInfo),
  attendance: userAssignment.attendance,
  assignedAt: userAssignment.assignedAt ?? '',
})

type LiveSessionUsersTableProps = {
  liveSessionUsers: UseLiveSessionDetailsData['liveSessionUsers']
  contentId: string
  facilitatorIds: UserId[]
  canEditAssignments: boolean
  canMarkAttendance: boolean
  maxNumberOfUsers?: number
  fetchUsers: UseLiveSessionDetailsData['fetchUsers']
  openEnrollUsers: () => void
  onRemoveUsers: (userIds: UserId[]) => void
  onSetAttendance: (userIds: UserId[], attendance: LiveSessionAttendanceStatus) => Promise<void>
}

export const LiveSessionUsersTable: React.FC<LiveSessionUsersTableProps> = ({
  liveSessionUsers,
  contentId,
  facilitatorIds,
  canMarkAttendance,
  maxNumberOfUsers,
  fetchUsers,
  openEnrollUsers,
  onRemoveUsers,
  onSetAttendance,
  canEditAssignments,
}) => {
  const { t } = useTranslation()
  const [inViewRef, shouldLoadMore] = useInView({ threshold: 0 })

  const [debouncedFilter, liveFilter, setFilter] = useDebouncedAndLiveState('')
  const [focusedUserId, setFocusedUserId] = useState<string | undefined>(undefined)
  const [isUserSearchOpen, setIsUserSearchOpen] = useState<boolean>(false)
  const rsvpEnabled = getFlag('rsvp')

  const columns: Column<LiveSessionUserInfo>[] = useMemo(() => {
    const rsvpColumn: Column<LiveSessionUserInfo>[] = [
      {
        id: 'rsvp',
        Header: p => <SortableHeader label={'RSVP'} smallLabel {...p} />,
        accessor: 'rsvp',
        Cell: (p: CellProps<LiveSessionUserInfo>) => {
          return <View>{p.row.original.rsvp}</View>
        },
        width: '10%',
      },
    ]

    return _.compact([
      {
        id: 'firstName',
        width: '40%',
        Header: p => {
          return (
            <>
              <SelectableHeader {...p} />
              <SortableHeader label={t('table.name')} smallLabel {...p} />
            </>
          )
        },
        Cell: (p: CellProps<LiveSessionUserInfo>) => (
          <View alignItems='center' gap='none'>
            <SelectableRow {...p} />
            <RoundAvatar
              {...getAvatarPropsFromBaseUserInfo(p.row.original.userInfo)}
              src={getAvatarImage(p.row.original.userInfo.userId, p.row.original.userInfo.avatar)}
              size='small'
            />
            <Spacer />
            <Text size='small' bold={p.row.original.isAdministered}>
              {[p.row.original.userInfo.firstName, p.row.original.userInfo.lastName].join(' ')}
            </Text>
          </View>
        ),
      },
      {
        id: 'attendance',
        accessor: 'attendance',
        width: '20%',
        Header: t('table.attendance'),
        // Header: p => <SortableHeader label={t('table.attendance')} smallLabel {...p} />, // @TODO make sortable (needs BE filter)
        Cell: p => <>{t(getAttendanceTranslationKey(p.row.original.attendance))}</>,
      },
      {
        id: 'assignedAt',
        accessor: 'assignedAt',
        width: '20%',
        Header: t('admin.groups.assigned-at'),
        Cell: p => (
          <>
            {p.row.original.assignedAt === undefined
              ? ''
              : new Date(p.row.original.assignedAt).toDateString()}
          </>
        ),
      },
      ...(rsvpEnabled === true ? rsvpColumn : []),
      {
        id: 'actions',
        width: '10%',
        Header: <TableMediumSearchTrigger onClick={() => setIsUserSearchOpen(true)} />,
        Cell: ({ row }: CellProps<LiveSessionUserInfo>) => {
          const userId = row.original.userInfo.userId
          const isAdministered = row.original.isAdministered
          const isAssigned = row.original.assignedAt !== undefined
          const unAssignable = isAssigned && isAdministered && !facilitatorIds.includes(userId)

          const hasMenuActions = isAdministered || canMarkAttendance || unAssignable

          return hasMenuActions ? (
            <MenuContainer>
              <Menu
                onTriggerClick={() => setFocusedUserId(userId)}
                onClose={() => setFocusedUserId(undefined)}
              >
                {isAdministered && (
                  <RouterLink href={`/manage/users/${userId}`}>
                    <MUIMenuItem>
                      <Text color='LEGACY_DEFAULT_TEXT_COLOR_REPLACE_ASAP' size='small' bold>
                        {t('manage.view-details')}
                      </Text>
                    </MUIMenuItem>
                  </RouterLink>
                )}

                {canMarkAttendance && (
                  <Menu
                    disabled={row.original.attendance === 'present'}
                    trigger={
                      <MUIMenuItem>
                        <Text
                          size='small'
                          bold
                          color={row.original.attendance === 'present' ? 'grey30' : 'black'}
                        >
                          {t('table.attendance')}
                        </Text>
                      </MUIMenuItem>
                    }
                    anchorOrigin={{
                      vertical: 'top',
                      horizontal: 'left',
                    }}
                    transformOrigin={{
                      vertical: 'top',
                      horizontal: 'right',
                    }}
                  >
                    <AttendanceMenuItems onClick={attendance => onSetAttendance([userId], attendance)} />
                  </Menu>
                )}

                {unAssignable ? (
                  <MUIMenuItem onClick={() => onRemoveUsers([userId])}>
                    <Text size='small' bold color='redBright'>
                      {t('dictionary.unassign')}
                    </Text>
                  </MUIMenuItem>
                ) : null}
              </Menu>
            </MenuContainer>
          ) : null
        },
      },
    ])
  }, [facilitatorIds, canMarkAttendance, onRemoveUsers, onSetAttendance, rsvpEnabled, t])

  const userTableInstance = useTable(
    { data: liveSessionUsers.data, columns, autoResetGlobalFilter: false, autoResetSortBy: false },
    useGlobalFilter,
    useSortBy,
    useRowSelect
  )

  const selectedUserIds = userTableInstance.selectedFlatRows.map(r => r.original.userInfo.userId)

  useEffect(() => {
    if (!liveSessionUsers.hasMore || liveSessionUsers.isLoading || !shouldLoadMore) return
    void fetchUsers(contentId, {
      lastUserId: liveSessionUsers.data[liveSessionUsers.data.length - 1]?.userInfo.userId,
      query: debouncedFilter,
    })
  }, [
    liveSessionUsers.data,
    liveSessionUsers.hasMore,
    liveSessionUsers.isLoading,
    contentId,
    debouncedFilter,
    fetchUsers,
    shouldLoadMore,
  ])

  useEffect(() => {
    void fetchUsers(contentId, { query: debouncedFilter }, { reset: true })
  }, [contentId, fetchUsers, debouncedFilter])

  const isFull = maxNumberOfUsers === undefined ? false : liveSessionUsers.data.length >= maxNumberOfUsers

  return (
    <div>
      <SmallTableWrapper title={t('table.assigned')}>
        <SmallTableScrollContainer scrollToTopDeps={[liveFilter]}>
          <Table
            tableInstance={userTableInstance}
            getRowProps={row => ({
              onClick: row.original.isAdministered
                ? () => {
                    void getGlobalRouter().navigate({ to: `/manage/users/${row.original.userInfo.userId}` })
                  }
                : undefined,
              isFocused: row.original.userInfo.userId === focusedUserId,
            })}
            headerOverride={
              isUserSearchOpen || userTableInstance.selectedFlatRows.length > 0 ? (
                <TableBulkActions
                  tableInstance={userTableInstance}
                  search={
                    isUserSearchOpen ? (
                      <TableMediumSearchHeaderManual
                        value={liveFilter}
                        onChange={setFilter}
                        searchPlaceholder={t('manage.search.users')}
                        onClose={() => {
                          setIsUserSearchOpen(false)
                          setFilter('')
                        }}
                      />
                    ) : (
                      <TableMediumSearchTrigger onClick={() => setIsUserSearchOpen(true)} />
                    )
                  }
                >
                  {canMarkAttendance && (
                    <Menu
                      anchorOrigin={{
                        vertical: 'bottom',
                        horizontal: 'left',
                      }}
                      transformOrigin={{
                        vertical: 'top',
                        horizontal: 'left',
                      }}
                      trigger={<ActionButton color='blueBright'>{t('table.attendance')}</ActionButton>}
                    >
                      <AttendanceMenuItems
                        onClick={attendance => onSetAttendance(selectedUserIds, attendance)}
                      />
                    </Menu>
                  )}
                  <CSVLink
                    data={userTableInstance.selectedFlatRows.map(r => userToCsv(r.original))}
                    filename={getCsvFileName(t('admin.organization.users.users'))}
                  >
                    <ActionButton color='blueBright'>
                      {t('manage.export')} {'.csv'}
                    </ActionButton>
                  </CSVLink>
                  {!selectedUserIds.some(userId => facilitatorIds.includes(userId)) && (
                    <ActionButton color='redBright' onClick={() => onRemoveUsers(selectedUserIds)}>
                      {t('admin.remove')}
                    </ActionButton>
                  )}
                </TableBulkActions>
              ) : undefined
            }
            small
          />
          {liveSessionUsers.hasMore && (
            <InfiniteScrollMessage
              padding='medium'
              ref={inViewRef}
              text={t('manage.users.table-loading')}
              showSanaLogo
            />
          )}
          {userTableInstance.rows.length === 0 && (
            <InfiniteScrollMessage padding='medium' text={t('manage.users.no-results')} />
          )}
        </SmallTableScrollContainer>
        <Separator top='none' bottom='none' />
        <Spacer size='small' />
        <View justifyContent='space-between'>
          <View>
            {canEditAssignments && (
              <Button disabled={isFull} onClick={openEnrollUsers}>
                {t('manage.manage-learners')}
              </Button>
            )}
          </View>
          <ExportCSVIconButton
            fetchCsvData={async () => {
              const response = await fetchUsers(contentId, { query: liveFilter }, { forCsv: true })
              return response.data.map(userToCsv)
            }}
            filename={t('admin.organization.users.users')}
          />
        </View>
      </SmallTableWrapper>
    </div>
  )
}
