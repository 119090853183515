import { FC, useEffect, useMemo, useState } from 'react'
import {
  useAblyYDocWithAwareness,
  useAblyYDocWithFakeAwareness,
} from 'sierra-client/collaboration/use-ably-ydoc'
import { usePolarisContext } from 'sierra-client/views/flexible-content/polaris-editor-provider/polaris-editor-provider'
import { CardGridV2 } from 'sierra-client/views/learner/lesson/grid'
import { StickyNotesLearnerContextProvider } from 'sierra-client/views/sticky-notes-card/learner-context'
import { LiveStickyNotesView } from 'sierra-client/views/sticky-notes-card/live'
import { ReadOnlyStickyNotesView } from 'sierra-client/views/sticky-notes-card/recap'
import { Data } from 'sierra-client/views/sticky-notes-card/types'
import { isElementType } from 'sierra-client/views/v3-author/queries'
import { EditorMode } from 'sierra-client/views/v3-author/slate'
import {
  StickyNotesCardYjsApi,
  createStickyNoteYjsApi,
  isStickyNotesCardYDocInitialized,
} from 'sierra-domain/card/sticky-notes-card'
import { ScopedLiveSessionId, ScopedStickyNotesCardId } from 'sierra-domain/collaboration/types'
import { FileId } from 'sierra-domain/flexible-content/identifiers'
import { textInNodes } from 'sierra-domain/slate-util'
import { assertIsNonNullable } from 'sierra-domain/utils'
import { SlateDocument } from 'sierra-domain/v3-author'
import { fromSlateSharedType, getSlateDocumentSharedType } from 'sierra-domain/v3-author/slate-yjs-extension'
import { Spacer } from 'sierra-ui/primitives'
import { spacing } from 'sierra-ui/theming'
import { fonts } from 'sierra-ui/theming/fonts'
import styled from 'styled-components'
import * as Y from 'yjs'

const CardTitle = styled.div`
  ${fonts.heading.h4};
  max-width: 100%;
  white-space: wrap;
  color: #000;
  outline: none;
  border: none;
`

const Container = styled(CardGridV2)`
  flex: 0 0 100%;
  padding-top: ${spacing['48']};
  padding-bottom: 4rem;
  grid-auto-rows: minmax(min-content, max-content);
  row-gap: 2rem;

  && {
    min-height: unset;
  }
`

function scopedStickyNotesCardId(
  scopedFiledId: FileId,
  scopedLiveSessionId: ScopedLiveSessionId
): ScopedStickyNotesCardId {
  const fileId = scopedFiledId.replace(/^file:/, '')
  return `sticky-notes-card:${fileId}:${scopedLiveSessionId}` as ScopedStickyNotesCardId
}

const transform = (doc: SlateDocument): Data => {
  const [stickyNotesCard] = doc.filter(isElementType('sticky-notes-card'))
  assertIsNonNullable(stickyNotesCard, 'The slate document was missing a sticky-notes-card')

  const titleEl = stickyNotesCard.children.filter(isElementType('paragraph'))
  const sectionEls = stickyNotesCard.children.filter(isElementType('sticky-notes-card-section'))

  return {
    title: textInNodes(titleEl).join(''),
    sections: sectionEls.map(section => ({
      id: section.id,
      title: textInNodes([section]).join(''),
      color: section.color ?? '',
    })),
  }
}

function getStickyNotesDataFromYXmlText(yType: Y.XmlText): Data {
  return transform(fromSlateSharedType(yType))
}

const useData = (fileId: FileId): Data => {
  const { yDoc } = usePolarisContext()
  const yType = useMemo(() => getSlateDocumentSharedType(yDoc, fileId), [fileId, yDoc])

  const [data, setData] = useState<Data>(() => {
    return getStickyNotesDataFromYXmlText(yType)
  })

  useEffect(() => {
    const handler = (): void => setData(getStickyNotesDataFromYXmlText(yType))

    handler()

    yType.observeDeep(handler)

    return () => yType.unobserveDeep(handler)
  }, [yType])

  return data
}

const ViewOnlyStickyNotesCard: FC<{ fileId: FileId; liveSessionId: ScopedLiveSessionId }> = ({
  fileId,
  liveSessionId,
}) => {
  const [api, setApi] = useState<StickyNotesCardYjsApi>()
  const data = useData(fileId)
  const provider = useAblyYDocWithFakeAwareness(scopedStickyNotesCardId(fileId, liveSessionId))

  useEffect(() => {
    if (!provider) return

    const handler = (): void => {
      console.debug('[ably provider] top level observer fired')
      if (isStickyNotesCardYDocInitialized(provider.yDoc)) {
        setApi(createStickyNoteYjsApi(provider.yDoc))
      }
    }

    handler()
    provider.yDoc.getMap('dataWrapper').observe(handler)

    return () => {
      provider.yDoc.getMap('dataWrapper').unobserve(handler)
      setApi(undefined)
    }
  }, [provider])

  return (
    <StickyNotesLearnerContextProvider>
      <Container>
        <CardTitle>{data.title}</CardTitle>
        {api !== undefined && <ReadOnlyStickyNotesView sections={data.sections} api={api} />}
      </Container>
    </StickyNotesLearnerContextProvider>
  )
}

export const EditableStickyNotesCard: FC<{
  fileId: FileId
  mode: Extract<EditorMode, 'live' | 'recap'>
  liveSessionId: ScopedLiveSessionId
}> = ({ fileId, liveSessionId }) => {
  const [api, setApi] = useState<StickyNotesCardYjsApi>()
  const data = useData(fileId)

  const provider = useAblyYDocWithAwareness(scopedStickyNotesCardId(fileId, liveSessionId))

  useEffect(() => {
    if (!provider) return

    const handler = (): void => {
      console.debug('[ably provider] top level observer fired')
      if (isStickyNotesCardYDocInitialized(provider.yDoc)) {
        setApi(createStickyNoteYjsApi(provider.yDoc))
      }
    }

    handler()

    provider.yDoc.getMap('dataWrapper').observe(handler)

    return () => {
      provider.yDoc.getMap('dataWrapper').unobserve(handler)
      setApi(undefined)
    }
  }, [provider])

  return (
    <StickyNotesLearnerContextProvider>
      <Spacer size='48' />
      <Container>
        <CardTitle>{data.title}</CardTitle>
        {api !== undefined && provider !== undefined && (
          <LiveStickyNotesView awareness={provider.awareness} api={api} sections={data.sections} />
        )}
      </Container>
    </StickyNotesLearnerContextProvider>
  )
}

export const StickyNotesCard: FC<{
  fileId: FileId
  mode: Extract<EditorMode, 'live' | 'recap'>
  blockedDueToSessionLimit: boolean
  liveSessionId: ScopedLiveSessionId
}> = ({ fileId, mode, blockedDueToSessionLimit, liveSessionId }) => {
  if (mode === 'recap' || blockedDueToSessionLimit === true) {
    return <ViewOnlyStickyNotesCard fileId={fileId} liveSessionId={liveSessionId} />
  }

  return (
    <pre>
      <EditableStickyNotesCard fileId={fileId} mode={mode} liveSessionId={liveSessionId} />
    </pre>
  )
}
