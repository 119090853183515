import { useTranslation } from 'sierra-client/hooks/use-translation'
import { getGlobalRouter } from 'sierra-client/router'
import { useCreatePageContext } from 'sierra-client/views/flexible-content/create-page-context'
import { CourseId } from 'sierra-domain/api/nano-id'
import { Button } from 'sierra-ui/primitives'

export const ViewInManageButton: React.FC = () => {
  const { t } = useTranslation()
  const { createContentId } = useCreatePageContext()

  return (
    <>
      <Button
        icon='building'
        decoratorPosition='left'
        onClick={() =>
          getGlobalRouter().navigate({ to: `/manage/courses/${CourseId.parse(createContentId)}` })
        }
        variant='transparent'
      >
        {t('sharing-modal.view-in-manage')}
      </Button>
    </>
  )
}
