import React, { HTMLAttributes, ReactNode } from 'react'

type HoverTargetProps = {
  onHover: (isHovering: boolean) => void
  onClick?: React.MouseEventHandler<HTMLDivElement> | undefined
  style?: HTMLAttributes<HTMLSpanElement>['style']
  className?: string
  children?: ReactNode
}

export const HoverTarget = React.forwardRef<HTMLSpanElement, HoverTargetProps>(
  ({ onHover: onChange, className, style, onClick, children }, ref) => (
    <span
      // eslint-disable-next-line react/forbid-dom-props
      style={style}
      onMouseOver={() => onChange(true)}
      onMouseOut={() => onChange(false)}
      onClick={onClick}
      className={className}
      ref={ref}
    >
      {children}
    </span>
  )
)
