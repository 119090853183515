import { Logger } from 'sierra-client/core/logging/logger'

type Identifier = `course_group_${string}`

export const save = Logger.trackLoggerWithExtra<{ id: string }, { id: string }>(
  'course_group_save' satisfies Identifier,
  input => {
    return {
      ...input,
    }
  }
)

export const editionCreate = Logger.trackLoggerWithExtra(
  'course_group_edition_create' satisfies Identifier,
  input => {
    return {
      ...input,
    }
  }
)

export const editionDelete = Logger.trackLoggerWithExtra<{ editionId?: string }, { editionId?: string }>(
  'course_group_edition_delete' satisfies Identifier,
  input => {
    return {
      ...input,
    }
  }
)
