import { motion } from 'framer-motion'
import React, { useCallback, useMemo } from 'react'
import { Link } from 'sierra-client/components/common/link'
import { SanaUpdatedModal } from 'sierra-client/components/common/modals/sana-updated-modal'
import { Portal } from 'sierra-client/components/util/portal'
import { useFakeProgress } from 'sierra-client/hooks/use-fake-progress'
import { useInterval } from 'sierra-client/hooks/use-interval'
import { useOnMount } from 'sierra-client/hooks/use-on-mount'
import { useOnUnmount } from 'sierra-client/hooks/use-on-unmount'
import { useTranslation } from 'sierra-client/hooks/use-translation'
import { useDispatch, useSelector } from 'sierra-client/state/hooks'
import {
  addNonExpiringNotification,
  addNotification,
  pruneNotifications,
  removeNotification,
  toNotificationState,
} from 'sierra-client/state/notifications/actions'
import { selectNotifications } from 'sierra-client/state/notifications/selectors'
import {
  NotificationDetails,
  NotificationLevel,
  Notification as NotificationProps,
  NotificationPushType,
  NotificationType,
  PresetNotificationDetails,
} from 'sierra-client/state/notifications/types'
import { floorToClosest } from 'sierra-domain/utils'
import { Icon } from 'sierra-ui/components'
import { LoadingSpinner, Spacer, Text } from 'sierra-ui/primitives'
import { palette, spacing, zIndex } from 'sierra-ui/theming'
import { v2_breakpoint_int } from 'sierra-ui/theming/breakpoints'
import { fadeIn } from 'sierra-ui/theming/keyframes'
import { useOnChanged } from 'sierra-ui/utils'
import styled from 'styled-components'
import { v4 } from 'uuid'

const NotificationsWrapper = styled(motion.div)`
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  height: 26rem;
  display: flex;
  flex-direction: column;
  align-items: center;
  z-index: ${zIndex.NOTIFICATIONS};
  pointer-events: none;
  padding: 0.5rem;

  @media screen and (max-width: ${v2_breakpoint_int.phone - 1}px) {
    display: none;

    flex-direction: row;
    overflow: hidden;
    overflow-y: auto;

    bottom: 0.5rem;
    right: 0.5rem;
    width: 100vw;
    max-width: 100vw;

    -ms-overflow-style: none;

    &::-webkit-scrollbar {
      display: none;
    }
  }
`

const NotificationContainer = styled(motion.div)<{ level: NotificationLevel }>`
  display: flex;
  flex-flow: row nowrap;
  /* justify-content: space-between; */
  justify-content: flex-start;
  align-items: center;
  min-height: 2rem;
  max-width: 100%;
  margin-bottom: 0.5rem;
  pointer-events: all;
  padding: 0.25rem 1rem;
  border-radius: ${spacing['4']};
  text-align: center;

  background-color: ${p => {
    switch (p.level) {
      case 'success':
        return p.theme.color.greenBright
      case 'error':
        return palette.primitives.black
      case 'warning':
        return p.theme.color.grey15
      case 'info':
      default:
        return palette.primitives.black
    }
  }};

  @media screen and (max-width: ${v2_breakpoint_int.phone - 1}px) {
    max-width: 80vw;
    margin-bottom: 0;
    margin-left: 1rem;

    &:last-of-type {
      margin-right: auto;
    }
  }
`

const NotificationText = styled(Text).attrs({ size: 'small' })<{ level: NotificationLevel }>`
  color: ${p => {
    switch (p.level) {
      case 'warning':
        return palette.primitives.black
      default:
        return palette.primitives.white
    }
  }};
`

const NotificationIcon = styled(Icon)`
  margin-right: 0.5rem;
`

const FadeAnimation = styled.div`
  opacity: 0;
  animation: ${fadeIn} 250ms ease 0s forwards;
`

const presetNotifications: Record<NotificationType, PresetNotificationDetails> = {
  'forbidden': {
    title: 'notifications.forbidden.title',
    body: 'notifications.forbidden.text',
    level: 'error',
  },
  'error': {
    title: 'notifications.error.title',
    body: 'notifications.error.text',
    level: 'error',
  },
  'success': {
    title: 'notifications.success.title',
    body: 'notifications.success.text',
    level: 'success',
  },
  'course-published': {
    title: 'notifications.course-published.title',
    body: 'notifications.course-published.text',
    level: 'success',
  },
  'course-unpublished': {
    title: 'notifications.course-unpublished.title',
    body: 'notifications.course-unpublished.text',
    level: 'success',
  },
  'user-deleted': {
    body: 'notifications.user-delete.text',
    level: 'success',
  },
  'revoke-invite': {
    title: 'notifications.revoke-invite.title',
    body: 'notifications.revoke-invite.text',
    level: 'success',
  },
  'resend-invite': {
    title: 'notifications.resend-invite.title',
    body: 'notifications.resend-invite.text',
    level: 'success',
  },
  'resend-invite-not-sent': {
    title: 'notifications.resend-invite-not-sent.title',
    body: 'notifications.resend-invite-not-sent.text',
    level: 'info',
  },
  'generate-poll/short-context': {
    title: 'notifications.poll-generation.short-context.title',
    body: 'notifications.poll-generation.short-context.text',
    level: 'info',
  },
  'generate-poll/no-poll': {
    title: 'notifications.poll-generation.no-poll.title', //TODO
    body: 'notifications.poll-generation.no-poll.text',
    level: 'info',
  },
  'generate-question/short-context': {
    title: 'notifications.question-generation.short-context.title',
    body: 'notifications.question-generation.short-context.text',
    level: 'info',
  },
  'generate-question/sensitive-context': {
    title: 'notifications.question-generation.sensitive-context.title',
    body: 'notifications.question-generation.sensitive-context.text',
    level: 'info',
  },
  'generate-question/no-question': {
    title: 'notifications.question-generation.no-question.title',
    body: 'notifications.question-generation.no-question.text',
    level: 'info',
  },
  'generate-variations/short-context': {
    // title: notifications.question-generation-variation.short-context.title
    body: 'notifications.question-generation-variation.short-context.text',
    level: 'info',
  },
  'generate-variations/sensitive-context': {
    // title: notifications.question-generation-variation.sensitive-context.title
    body: 'notifications.question-generation-variation.sensitive-context.text',
    level: 'info',
  },
  'generate-variations/no-question': {
    title: 'notifications.question-generation-variation.no-question.title',
    body: 'notifications.question-generation-variation.no-question.text',
    level: 'info',
  },
  'generate-takeaways/no-takeaway': {
    title: 'notifications.takeaway-generation.no-takeaway.title',
    body: 'notifications.takeaway-generation.no-takeaway.text',
    level: 'info',
  },
  'generate-takeaways/short-context': {
    body: 'notifications.takeaway-generation.short-context.text',
    level: 'info',
  },
  'freetext/too-long': {
    title: 'notifications.freetext.too-long.title',
    body: 'notifications.freetext.too-long.text',
    level: 'info',
  },
  'assigned': {
    body: 'notifications.assigned',
    level: 'success',
  },
  'course-removed': {
    body: 'notifications.course-removed',
    level: 'info',
  },
  'path-removed': {
    body: 'notifications.path-removed',
    level: 'success',
  },
  'path-deleted': {
    body: 'notifications.path-deleted',
    level: 'success',
  },
  'group-removed': {
    body: 'notifications.group-removed',
    level: 'success',
  },
  'user-removed': {
    body: 'notifications.user-removed',
    level: 'success',
  },
  'skill-deleted': {
    body: 'dictionary.done', // notifications.skill-deleted
    level: 'success',
  },
  'skills-added': {
    body: 'notifications.skills-added',
    level: 'success',
  },
  'skill-saved': {
    body: 'notifications.skill-saved',
    level: 'success',
  },
  'role-deleted': {
    body: 'dictionary.done', // notifications.role-deleted
    level: 'success',
  },
  'roles-added': {
    body: 'dictionary.done', // notifications.roles-added
    level: 'success',
  },
  'role-saved': {
    body: 'notifications.role-saved',
    level: 'success',
  },
  'user-progress-reset': {
    body: 'notifications.user-progress-reset',
    level: 'success',
  },
  'user-progress-complete': {
    body: 'notifications.user-progress-complete',
    level: 'success',
  },
  'user-update-email': {
    body: 'notifications.user-update-email',
    level: 'success',
  },
  'settings-saved': {
    body: 'notifications.settings-saved',
    level: 'success',
  },
  'course-duplicated': {
    body: 'notifications.course-duplicated',
    level: 'info',
  },
  'course-converted': {
    body: 'notifications.course-converted',
    level: 'info',
  },
  'course-translated': {
    body: 'notifications.course-translated',
    level: 'info',
  },
  'duplication-error': {
    body: 'notifications.duplication-error',
    level: 'error',
  },
  'translation-too-long-error': {
    body: 'notifications.translation-too-long-error',
    level: 'error',
  },
  'set-course-title-error': {
    body: 'notifications.set-course-title-error',
    level: 'error',
  },
  'program-removed': {
    body: 'notifications.program-removed',
    level: 'success',
  },
  'enrollment-rule-created': {
    body: 'manage.programs.enrollment-rules.notifications.creation-success',
    level: 'success',
  },
  'enrollment-rule-creation-failed': {
    body: 'manage.programs.enrollment-rules.notifications.creation-error',
    level: 'error',
  },
  'enrollment-rule/update-success': {
    body: 'manage.programs.enrollment-rules.notifications.updation-success',
    level: 'success',
  },
  'enrollment-rule/update-failed': {
    body: 'manage.programs.enrollment-rules.notifications.updation-error',
    level: 'error',
  },
  'enrollment-rule/duplicate-name': {
    body: 'manage.programs.enrollment-rules.notifications.duplicate-name',
    level: 'error',
  },
  'enrollment-rule/deleted': {
    body: 'manage.programs.enrollment-rules.notifications.deleted',
    level: 'success',
  },
  'course-group-creation-failed': {
    body: 'manage.course-groups.notifications.creation-error',
    level: 'error',
  },
  'course-group-created': {
    body: 'manage.course-groups.notifications.creation-success',
    level: 'success',
  },
  'visibility-updated': {
    title: 'notifications.visibility-updated.title',
    body: 'notifications.visibility-updated.text',
    level: 'success',
  },
  'certificate-removed': {
    body: 'notifications.certificate-removed',
    level: 'success',
  },
}

const ActionLink = styled(Link)<{ level: NotificationLevel }>`
  border-left: 1px solid ${p => (p.level === 'success' ? p.theme.color.grey15 : p.theme.color.grey85)};
  margin-left: 0.5rem;
  padding-left: 0.5rem;
`

const _ProgressDurationText = styled(Text).attrs({ size: 'small' })<{ level: NotificationLevel }>`
  color: ${p => {
    switch (p.level) {
      case 'warning':
        return palette.primitives.black
      default:
        return palette.primitives.white
    }
  }};
  opacity: 0.5;
  width: 2rem;
`

const FakeProgressDurationText = ({
  duration,
  level,
}: {
  duration: number
  level: NotificationLevel
}): JSX.Element => {
  const { value, startInfiniteProgress, finishProgress } = useFakeProgress({
    finishDuration: duration,
    target: 100,
  })
  useOnMount(startInfiniteProgress)
  useOnUnmount(finishProgress)

  return (
    <_ProgressDurationText level={level}>
      {floorToClosest({ value, closestNumber: 3 })}%
    </_ProgressDurationText>
  )
}

export const Notification = (notification: NotificationProps): JSX.Element => {
  const { t } = useTranslation()

  const translatePresetNotification = useCallback<
    (presetNotif: PresetNotificationDetails) => NotificationDetails
  >(
    presetNotif => ({
      ...presetNotif,
      title: presetNotif.title !== undefined ? t(presetNotif.title) : undefined,
      body: t(presetNotif.body),
    }),
    [t]
  )
  const { title, body, level, url, target } =
    notification.type === 'custom' || notification.type === 'progress'
      ? notification
      : { ...translatePresetNotification(presetNotifications[notification.type]), ...notification }

  return (
    <NotificationContainer
      role='status'
      aria-label={title ?? body}
      layout='position'
      transition={{ easing: 'ease', duration: 0.5 }}
      level={level}
    >
      {notification.icon !== undefined &&
        (notification.icon === 'loading' ? (
          <LoadingSpinner
            padding='none'
            size='small'
            color={notification.level === 'warning' ? 'black' : 'white'}
          />
        ) : (
          <NotificationIcon
            iconId={notification.icon}
            color={notification.level === 'warning' ? 'black' : 'white'}
          />
        ))}
      {title !== undefined && (
        <>
          <NotificationText bold level={level}>
            {title}
          </NotificationText>
          <Spacer axis='horizontal' size='xxsmall' />
        </>
      )}
      <NotificationText level={level}>{body}</NotificationText>
      {url !== undefined && (
        <ActionLink next={true} href={url} target={target} level={level}>
          <NotificationText bold level={level}>
            {t('notification.open')}
          </NotificationText>
        </ActionLink>
      )}
      {notification.type === 'progress' && (
        <>
          <Spacer axis='horizontal' size='6' />
          <FakeProgressDurationText duration={notification.progressDuration.duration} level={level} />
        </>
      )}
    </NotificationContainer>
  )
}

const PruneNotifications = (): null => {
  const notifications = useSelector(selectNotifications)
  const dispatch = useDispatch()

  const pruneMaybe = (): void => {
    if (notifications.length > 0) void dispatch(pruneNotifications())
  }

  // Dispatch prune action every second
  useInterval(pruneMaybe, 1000)

  // Dispatch prune action when notifications array changes
  useOnChanged(pruneMaybe, notifications)

  return null
}

const Notifications = (): JSX.Element => {
  const notifications = useSelector(selectNotifications)

  return (
    <Portal>
      <NotificationsWrapper>
        {notifications.map(notification => (
          <FadeAnimation key={notification.id}>
            <Notification {...notification} />
          </FadeAnimation>
        ))}
        <PruneNotifications />
      </NotificationsWrapper>
    </Portal>
  )
}

export const ApplicationNotifications: React.FC = () => {
  return (
    <>
      <SanaUpdatedModal />
      <Notifications />
    </>
  )
}

export type Notif = { push: (v: NotificationPushType) => void }

export const useNotif = (): Notif => {
  const dispatch = useDispatch()

  const push = useCallback(
    (push: NotificationPushType): void => {
      void dispatch(addNotification(toNotificationState(push)))
    },
    [dispatch]
  )

  return useMemo(() => ({ push }), [push])
}

export type NonExpiringNotif = {
  push: (v: NotificationPushType) => string
  remove: (id: string) => void
}

export const useNonExpiringNotif = (): NonExpiringNotif => {
  const dispatch = useDispatch()

  const push = useCallback(
    (push: NotificationPushType): string => {
      const id = v4()
      void dispatch(addNonExpiringNotification({ id, expires: undefined, ...push }))
      return id
    },
    [dispatch]
  )

  const remove = useCallback(
    (id: string): void => {
      void dispatch(removeNotification({ id }))
    },
    [dispatch]
  )

  return useMemo(() => ({ push, remove }), [push, remove])
}
