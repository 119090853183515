import React from 'react'
import { Link } from 'sierra-client/components/common/link'
import { RenderHint } from 'sierra-client/lib/tabular/hints'
import { Icon, TruncatedText } from 'sierra-ui/components'
import { AvatarProps, RoundAvatar } from 'sierra-ui/components/avatars/round-avatar'
import { InternalTruncatedText, View } from 'sierra-ui/primitives'
import styled, { css } from 'styled-components'

export const AlignSelfCenterView = styled(View)`
  align-self: center;
`

export const ColorInheritingLink = styled(Link)`
  color: inherit;
  font-weight: normal;
  min-width: 0; /* for text-overflow: ellipsis to work inside cells */

  &:hover {
    color: inherit;

    .underline-on-hover {
      text-decoration: underline;
    }
  }
`

export const DisableableRoundAvatar = styled(RoundAvatar)<{ disabled: boolean }>`
  ${p =>
    p.disabled === true &&
    css`
      background-color: ${p => p.theme.color.grey10};

      text {
        opacity: 0.8;
      }
    `}
`

const UserContainer = styled(View).attrs({ gap: '12' })`
  min-width: 200px; /* Magic number to make sure user names arent squashed on small screens */
`

const EmptyAvatar = styled.div`
  width: 2rem;
`

export const User: React.FC<
  AvatarProps & {
    hasChildrenRows?: boolean
    disabled: boolean
    hints: RenderHint[]
    toggleExpand?: (ev: React.MouseEvent<HTMLElement>) => void
    expanded?: boolean
    email: string
    isNestedChild?: boolean
    underlineUserOnHover?: boolean
  }
> = ({
  hasChildrenRows,
  disabled,
  toggleExpand,
  hints,
  expanded,
  isNestedChild = false,
  email,
  firstName,
  lastName,
  color,
  underlineUserOnHover = false,
  src,
}) => {
  const [avatarFirstName, avatarLastName] = React.useMemo(() => {
    if (firstName === undefined || firstName[0] === '[') {
      return ['-', '-']
    }

    return [firstName, lastName]
  }, [firstName, lastName])

  return (
    <UserContainer>
      {isNestedChild !== true && (
        <DisableableRoundAvatar
          firstName={avatarFirstName}
          lastName={avatarLastName}
          disabled={disabled}
          color={color}
          src={src}
        />
      )}
      {isNestedChild === true && <EmptyAvatar />}
      <View direction='column' gap='none'>
        <View>
          <InternalTruncatedText
            bold
            lines={1}
            size='small'
            className={underlineUserOnHover === true ? 'underline-on-hover' : ''}
          >
            {firstName !== undefined && lastName !== undefined && `${firstName} ${lastName}`}
          </InternalTruncatedText>
          {hints.includes('with-nested-row-toggle') === true && hasChildrenRows === true && (
            <AlignSelfCenterView onClick={toggleExpand}>
              {expanded === true ? (
                <Icon iconId={'chevron--up--small'} />
              ) : (
                <Icon iconId={'chevron--down--small'} />
              )}
            </AlignSelfCenterView>
          )}
        </View>
        {email !== firstName && (
          <TruncatedText lines={1} size='small' color='foreground/muted'>
            {email}
          </TruncatedText>
        )}
      </View>
    </UserContainer>
  )
}
