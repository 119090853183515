import { useCallback, useEffect, useMemo, useState } from 'react'
import { NoteId, NoteMetadata, SectionId, StickyNotesCardYjsApi } from 'sierra-domain/card/sticky-notes-card'

export const getSectionNoteIds = (
  api: StickyNotesCardYjsApi,
  sectionId: SectionId,
  sectionIds: SectionId[]
): NoteId[] => {
  const positions = api.getPositionArray()

  const sectionNotesIds = positions.filter(pos => pos.sectionId === sectionId).map(pos => pos.noteId)

  const isFirstSection = sectionId === sectionIds[0]

  const orphanNoteIds = isFirstSection
    ? positions.filter(pos => !sectionIds.includes(pos.sectionId)).map(pos => pos.noteId)
    : []

  return [...sectionNotesIds, ...orphanNoteIds]
}

export const useNote = (
  api: StickyNotesCardYjsApi,
  noteId: NoteId
): [NoteMetadata | undefined, (update: (old: NoteMetadata) => NoteMetadata) => void] => {
  const [note, _setNote] = useState<NoteMetadata>()

  useEffect(() => {
    const yMetadata = api.getYNoteMetadata(noteId)

    const handler = (): void => _setNote(NoteMetadata.parse(yMetadata.toJSON()))
    handler()
    yMetadata.observeDeep(handler)

    return () => yMetadata.unobserveDeep(handler)
  }, [api, noteId])

  const setNote = useCallback(
    (update: (old: NoteMetadata) => NoteMetadata) => api.setNoteMetadata(noteId, update),
    [noteId, api]
  )

  return useMemo(() => [note, setNote], [note, setNote])
}
