import { useCallback, useMemo, useState } from 'react'
import { Column } from 'react-table'
import { SortableHeader } from 'sierra-client/components/table/sortable-header'
import { TableMediumSearchTrigger } from 'sierra-client/components/table/table-medium'
import { useTranslation } from 'sierra-client/hooks/use-translation'
import { Trans } from 'sierra-client/hooks/use-translation/trans'
import { TranslationKey } from 'sierra-client/hooks/use-translation/types'
import { getGlobalRouter } from 'sierra-client/router'
import { mapApiClientToCsv, useManageApiClient } from 'sierra-client/views/manage/api/use-manage-client-api'
import {
  ManageTableSmall,
  useManageTableSmall,
} from 'sierra-client/views/manage/components/manage-table-small'
import { SmallTableWrapper } from 'sierra-client/views/manage/components/small-table-wrapper'
import { ApiClientId } from 'sierra-domain/api/nano-id'
import { ApiClientWithStatus } from 'sierra-domain/api/public-api'
import { Button } from 'sierra-ui/primitives'
import styled from 'styled-components'

const APILink = styled.a`
  text-decoration: underline;
  transition: opacity 150ms cubic-bezier(0.25, 0.1, 0.25, 1);

  &:hover {
    text-decoration: none;
    opacity: 0.8;
  }
`

export const ManageApiClient = (): JSX.Element | null => {
  const { t } = useTranslation()
  const [isSearchOpen, setIsSearchOpen] = useState(false)

  const { apiClients, isLoading } = useManageApiClient()

  const goToApiDetails = useCallback((apiClientId: string) => {
    void getGlobalRouter().navigate({
      to: '/settings/api/$apiClientId',
      params: { apiClientId: apiClientId as ApiClientId },
    })
  }, [])

  const createApiClient = async (): Promise<void> => {
    await getGlobalRouter().navigate({
      to: '/settings/api/$apiClientId',
      params: { apiClientId: 'new' },
      replace: true,
    })
  }

  const columns: Array<Column<ApiClientWithStatus>> = useMemo(
    () => [
      {
        Header: p => (
          <>
            {/* <SelectableHeader {...p} /> */}
            <SortableHeader label={t('table.name')} {...p} />
          </>
        ),
        accessor: 'name',
        Cell: p => {
          return (
            <>
              {/* <SelectableRow {...p} /> */}
              {p.row.original.name}
            </>
          )
        },
        width: '20%',
      },
      {
        Header: t('dictionary.description'),
        accessor: 'description',
        width: '20%',
      },
      {
        Header: t('manage.api.client-id'),
        accessor: 'clientId',
        width: '20%',
      },
      {
        Header: t('table.status'),
        accessor: 'status',
        Cell: ({ row }) => (
          <>{row.original.status === 'enabled' ? t('manage.api.enabled') : t('manage.api.disabled')}</>
        ),
        width: '20%',
      },
      {
        id: 'actions',
        width: '10%',
        Header: <TableMediumSearchTrigger onClick={() => setIsSearchOpen(true)} />,
        Cell: '',
      },
    ],
    [t]
  )
  const { tableInstance } = useManageTableSmall({
    tableOptions: { data: apiClients, columns: columns },
    getEntityId: c => c.clientId,
  })

  return (
    <SmallTableWrapper
      title={t('manage.api.title')}
      subtitle={
        <Trans
          i18nKey={'manage.api.subtitle-with-link' satisfies TranslationKey}
          components={{
            apilink: <APILink href='https://sana.ai/api-docs/' target='_blank' rel='noopener noreferrer' />,
          }}
        />
      }
    >
      <ManageTableSmall
        tableInstance={tableInstance}
        onViewDetails={goToApiDetails}
        getEntityId={c => c.clientId}
        mapEntityToCsv={mapApiClientToCsv}
        isSearchOpen={isSearchOpen}
        searchTrigger={setIsSearchOpen}
        translations={{
          tableLoading: t('manage.api.table-loading'),
          // tableEnd: t('manage.api.table-end'),
          searchPlaceholder: 't.todo-search-placeholder',
          tableNoResults: t('manage.api.no-results'),
          csvPrefix: t('admin.organization.tab.api'),
        }}
        isLoading={isLoading}
        focusedId={undefined}
        bulkActions={<></>}
        footerButton={<Button onClick={createApiClient}>{t('manage.api.create-new')}</Button>}
      />
    </SmallTableWrapper>
  )
}
