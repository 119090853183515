import {
  XRealtimeStrategyCourseGroupCourseSummaries,
  XRealtimeStrategyCourseGroupSelectEdition,
} from 'sierra-domain/routes'

import i18next from 'i18next'
import React from 'react'
import { useScormContext } from 'sierra-client/context/scorm-context'
import { usePost } from 'sierra-client/hooks/use-post'
import { useAssetResolver } from 'sierra-client/hooks/use-resolve-asset'
import { getGlobalRouter } from 'sierra-client/router'
import { useSelector } from 'sierra-client/state/hooks'
import { selectUser } from 'sierra-client/state/user/user-selector'
import { Edition } from 'sierra-client/views/learner/course/edition-page/types'
import { CourseId } from 'sierra-domain/api/nano-id'

export const useEditions = (scormCourseGroupId: string): Edition[] | undefined => {
  const [editions, setEditions] = React.useState<Edition[] | undefined>(undefined)
  const { postWithUserErrorException } = usePost()

  const assetResolver = useAssetResolver({ size: 'default' })

  React.useEffect(() => {
    const fetchEditions = async (): Promise<void> => {
      const { courses } = await postWithUserErrorException(XRealtimeStrategyCourseGroupCourseSummaries, {
        courseGroupId: scormCourseGroupId,
      })

      setEditions(
        courses.map(({ id, title, image, country, language, label }) => ({
          id,
          title,
          image: assetResolver(image, { type: 'course', courseId: CourseId.parse(scormCourseGroupId) }),
          country,
          language,
          label,
        }))
      )
    }

    void fetchEditions()
  }, [postWithUserErrorException, scormCourseGroupId, assetResolver])

  return editions
}

type OnContinue = (selectedEdition: Edition | undefined) => void

export const useOnContinue = (scormCourseGroupId: string): OnContinue => {
  const user = useSelector(selectUser)
  const { postWithUserErrorException } = usePost()
  const { courseKind } = useScormContext()

  const onContinue = React.useCallback<OnContinue>(
    async selectedEdition => {
      if (user === undefined || selectedEdition === undefined) return

      const { id: courseId } = selectedEdition

      const { language } = await postWithUserErrorException(XRealtimeStrategyCourseGroupSelectEdition, {
        courseGroupId: scormCourseGroupId,
        courseId,
      })

      if (courseKind === 'native:self-paced') {
        await i18next.changeLanguage(language)
        await getGlobalRouter().navigate({ to: `/s/${courseId}`, replace: true })
      } else throw Error('Invalid course kind')
    },
    [postWithUserErrorException, user, scormCourseGroupId, courseKind]
  )

  return onContinue
}
