import { useCallback, useEffect, useState } from 'react'
import { useTranslation } from 'sierra-client/hooks/use-translation'
import { TranslationKey } from 'sierra-client/hooks/use-translation/types'
import { FormElement, Popover } from 'sierra-ui/components'
import { Button, IconButton, Text, TextAreaPrimitive, View } from 'sierra-ui/primitives'
import styled from 'styled-components'

const Container = styled(View)`
  min-width: 290px;
`

type GenerationPopoverProps = {
  iconButtonTooltip: TranslationKey
  isLoading: boolean
  onGenerate: (query: string, onSuccess: () => void, onError: (errorMsg: string) => void) => void
  inputPlaceholder: TranslationKey
}

export const GenerationPopover: React.FC<GenerationPopoverProps> = ({
  iconButtonTooltip,
  isLoading,
  onGenerate,
  inputPlaceholder,
}) => {
  const { t } = useTranslation()
  const [value, setValue] = useState('')
  const [error, setError] = useState<string>()
  const [popoverOpen, setPopoverOpen] = useState(false)

  const handlePopoverOpen = (newOpen: boolean): void => {
    if (!isLoading) {
      // Never close the popover when fetching data
      setPopoverOpen(newOpen)
    }
  }

  const onSuccess = (): void => {
    setPopoverOpen(false)
    setValue('')
    setError(undefined)
  }

  const onError = (errorMsg: string): void => {
    setError(errorMsg)
  }

  const handleGenerate = useCallback(() => {
    if (!isLoading) {
      onGenerate(value, onSuccess, onError)
    }
  }, [isLoading, onGenerate, value])

  const onKeyDown = useCallback(
    (ev: KeyboardEvent): void => {
      if (ev.key === 'Enter') {
        ev.preventDefault()
        handleGenerate()
      }
    },
    [handleGenerate]
  )

  useEffect(() => {
    if (popoverOpen) {
      document.addEventListener('keydown', onKeyDown)
    }
    return () => {
      document.removeEventListener('keydown', onKeyDown)
    }
  }, [onKeyDown, popoverOpen])

  return (
    <Popover
      align='end'
      isOpen={popoverOpen}
      onOpenChange={handlePopoverOpen}
      renderTrigger={() => (
        <IconButton iconId='glitter' variant='transparent' tooltip={t(iconButtonTooltip)} />
      )}
    >
      <Container padding='xsmall' direction='column'>
        <FormElement label={t('dictionary.prompt')}>
          <TextAreaPrimitive
            disabled={isLoading}
            value={value}
            onChange={event => setValue(event.target.value)}
            placeholder={t(inputPlaceholder)}
          />
        </FormElement>
        {error !== undefined && <Text>{error}</Text>}
        <Button grow loading={isLoading} onClick={handleGenerate}>
          {t('dictionary.generate')}
        </Button>
      </Container>
    </Popover>
  )
}
