import { useEffect, useState } from 'react'
import { RouterLink } from 'sierra-client/components/common/link'
import { usePost } from 'sierra-client/hooks/use-post'
import { useTranslation } from 'sierra-client/hooks/use-translation'
import { useDispatch } from 'sierra-client/state/hooks'
import { getUserErrorTranslationKey } from 'sierra-client/utils/translation-utils'
import { AuthenticationContent } from 'sierra-client/views/authentication/native/components/authentication-content'
import { isLeft } from 'sierra-domain/either'
import { XRealtimeAuthVerifyEmail } from 'sierra-domain/routes'
import { Button, Spacer, Text } from 'sierra-ui/primitives'

type Props = { code: string }

export const VerifyEmail: React.FC<Props> = ({ code }) => {
  const { t } = useTranslation()
  const [success, setSuccess] = useState(false)
  const [error, setError] = useState<string | undefined>(undefined)
  const dispatch = useDispatch()
  const { postWithUserErrorCode } = usePost()

  useEffect(
    () => {
      void (async () => {
        const result = await postWithUserErrorCode(XRealtimeAuthVerifyEmail, { code })

        if (isLeft(result)) {
          setError(t(getUserErrorTranslationKey(result.left)))
          return
        }

        setError(undefined)
        setSuccess(true)
      })()
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [code, dispatch, t]
  )

  return (
    <AuthenticationContent message={t('verify-email.email-verification')}>
      {success ? (
        <>
          <Text color='foreground/primary' size='regular' lineLength={40} align='center'>
            {t('verify-email.success')}
          </Text>
          <Spacer size='small' />
          <RouterLink href='/'>
            <Button variant='primary' grow>
              {t('login.action')}
            </Button>
          </RouterLink>
        </>
      ) : error !== undefined ? (
        <Text size='regular' color='redBright'>
          {error}
        </Text>
      ) : null}
    </AuthenticationContent>
  )
}
