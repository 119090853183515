import { produce } from 'immer'
import { useContext, useMemo } from 'react'
import { useSelector } from 'sierra-client/state/hooks'
import { selectUserOrganizationId } from 'sierra-client/state/user/user-selector'
import { Theme } from 'sierra-ui/theming/legacy-theme'
import { ThemeContext } from 'styled-components'

export const useLearnerTheme = (): Theme => {
  const organizationId = useSelector(selectUserOrganizationId)
  const currentTheme = useContext(ThemeContext)

  const learnerTheme = useMemo(() => {
    switch (organizationId) {
      // Budbee
      case '4ff33083-7624-4151-84a0-797383a262e9':
        return produce(currentTheme, draft => {
          draft.color.greenDark = '#18706D'
          draft.color.greenBright = '#41CDA5'
          draft.color.greenPastel = '#E7F0EB'
        })
      default:
        return currentTheme
    }
  }, [organizationId, currentTheme])

  return learnerTheme
}
