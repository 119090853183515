import { useMutation, type MutationOptions, type UseMutationResult } from '@tanstack/react-query'
import { graphql } from 'sierra-client/api/graphql/gql'
import {
  type AssignImplicitSkillLevelToContentMutation,
  type AssignImplicitSkillLevelToContentMutationVariables,
  type AssignSkillLevelToContentMutation,
  type AssignSkillLevelToContentMutationVariables,
  type SubscribeLearnerToSkillMutation,
  type SubscribeLearnerToSkillMutationVariables,
  type UnsubscribeLearnerFromSkillMutation,
  type UnsubscribeLearnerFromSkillMutationVariables,
} from 'sierra-client/api/graphql/gql/graphql'
import { graphQuery, useInvalidateGraphQuery } from 'sierra-client/api/hooks/use-graphql-query'

export const getSkillsRecommendedContentQuery = graphql(`
  query GetSkillsRecommendedContentQuery {
    skills {
      data {
        recommendedContent {
          id
        }
      }
    }
  }
`)

const useInvalidateGetSkillsRecommendedContentQuery = (): ReturnType<typeof useInvalidateGraphQuery> => {
  return useInvalidateGraphQuery(getSkillsRecommendedContentQuery)
}

export const getSkillsQuery = graphql(`
  query GetSkills($limit: Int, $cursor: String, $query: String, $sortBy: [SkillSortAttributeInput!]) {
    skills(limit: $limit, cursor: $cursor, query: $query, sortBy: $sortBy) {
      data {
        id
        description
        name
        uniqueSubscribersCount
        assignedContentsCount {
          count
          autoAssociationInProgress
        }
        skillLevels {
          id
          description
          levelSetting {
            id
            index
            name
          }
        }

        recommendedContent {
          id
        }
      }
      cursor
      totalCount
    }
  }
`)

const useInvalidateGetSkillsQuery = (): ReturnType<typeof useInvalidateGraphQuery> => {
  return useInvalidateGraphQuery(getSkillsQuery)
}

export const useInvalidateGetSkillsQueries = (): (() => Promise<void>) => {
  const invalidateGetSkillsQuery = useInvalidateGetSkillsQuery()
  const invalideteGetSkillsRecommendedContentQuery = useInvalidateGetSkillsRecommendedContentQuery()

  return async () => {
    await invalidateGetSkillsQuery()
    await invalideteGetSkillsRecommendedContentQuery()
  }
}

export const getSkillsCountAndLevelsQuery = graphql(`
  query GetSkillsCountAndLevels {
    skills {
      totalCount
    }
    skillLevelSettings {
      id
    }
  }
`)

export const getSkillGeneralInfoQuery = graphql(`
  query GetSkillGeneralInfo($id: SkillId!) {
    skill(id: $id) {
      id
      name
      description
      skillLevels {
        __typename
      }
      assignedContentsCount {
        count
      }
    }
  }
`)

export const subscribeLearnersMutation = graphql(`
  mutation SubscribeLearnerToSkill($subscriptions: [SkillSubscriptionInput!]!) {
    subscribeLearners(skillSubscriptions: $subscriptions)
  }
`)

export const useSubscribeLearnerMutation = (
  options?: MutationOptions<
    SubscribeLearnerToSkillMutation,
    unknown,
    SubscribeLearnerToSkillMutationVariables,
    unknown
  >
): UseMutationResult<
  SubscribeLearnerToSkillMutation,
  unknown,
  SubscribeLearnerToSkillMutationVariables,
  unknown
> => {
  return useMutation({
    ...options,
    mutationFn: input => graphQuery(subscribeLearnersMutation, input),
  })
}

export const unsubscribeLearnerMutation = graphql(`
  mutation UnsubscribeLearnerFromSkill($subscriptions: [SkillUnsubscriptionInput!]!) {
    unsubscribeLearner(skillUnsubscriptionRequests: $subscriptions)
  }
`)

export const useUnsubscribeLearnerMutation = (
  options?: MutationOptions<
    UnsubscribeLearnerFromSkillMutation,
    unknown,
    UnsubscribeLearnerFromSkillMutationVariables,
    unknown
  >
): UseMutationResult<
  UnsubscribeLearnerFromSkillMutation,
  unknown,
  UnsubscribeLearnerFromSkillMutationVariables,
  unknown
> => {
  return useMutation({
    ...options,
    mutationFn: input => graphQuery(unsubscribeLearnerMutation, input),
  })
}

export const setLearnerLevelMutation = graphql(`
  mutation SetLearnerLevel(
    $userId: UserId!
    $skillId: SkillId!
    $skillLevelSettingId: SkillLevelSettingId!
    $alreadyAchieved: Boolean!
  ) {
    upsertLearnerLevelSubscription(
      userId: $userId
      skillId: $skillId
      skillLevelSettingId: $skillLevelSettingId
      alreadyAchieved: $alreadyAchieved
    )
  }
`)

export const assignSkillToContentMutation = graphql(`
  mutation AssignSkillLevelToContent(
    $contentIds: [InputContentId!]!
    $skillId: SkillId!
    $skillLevelSettingId: SkillLevelSettingId!
  ) {
    assignSkillLevelToContent(
      contentIds: $contentIds
      skillId: $skillId
      skillLevelSettingId: $skillLevelSettingId
    )
  }
`)

export const useAssignSkillToContentMutation = (
  options: MutationOptions<
    AssignSkillLevelToContentMutation,
    unknown,
    AssignSkillLevelToContentMutationVariables,
    unknown
  > = {}
): UseMutationResult<
  AssignSkillLevelToContentMutation,
  unknown,
  AssignSkillLevelToContentMutationVariables,
  unknown
> => {
  return useMutation({
    ...options,
    mutationFn: vars => graphQuery(assignSkillToContentMutation, vars),
  })
}

export const assignImplicitSkillLevelToContent = graphql(`
  mutation AssignImplicitSkillLevelToContent($contentIds: [InputContentId!]!, $skillId: SkillId!) {
    assignImplicitSkillLevelToContent(contentIds: $contentIds, skillId: $skillId)
  }
`)

export const useAssignImplicitSkillLevelToContentMutation = (
  options: MutationOptions<
    AssignImplicitSkillLevelToContentMutation,
    unknown,
    AssignImplicitSkillLevelToContentMutationVariables,
    unknown
  > = {}
): UseMutationResult<
  AssignImplicitSkillLevelToContentMutation,
  unknown,
  AssignImplicitSkillLevelToContentMutationVariables,
  unknown
> => {
  return useMutation({
    ...options,
    mutationFn: vars => graphQuery(assignImplicitSkillLevelToContent, vars),
  })
}

export const unassignSkillFromContentMutation = graphql(`
  mutation unassignSkillFromContent($contentIds: [InputContentId!]!, $skillId: SkillId!) {
    unassignSkillFromContent(skillId: $skillId, contentIds: $contentIds)
  }
`)

export const unassignSkillsFromContentMutation = graphql(`
  mutation unassignSkillsFromContent($contentIds: [InputContentId!]!, $skillIds: [SkillId!]!) {
    unassignSkillsFromContent(contentIds: $contentIds, skillIds: $skillIds)
  }
`)

export const skillSettingsQuery = graphql(`
  query SkillSettings {
    skillSettings {
      levelsEngineEnabled
      skillsEngineEnabled
      sequentialUnlockingEnabled
      hideSkills
      showLeaderboard
    }

    skillLevelSettings {
      id
      name
      index
      defaultBadgeTheme
    }
  }
`)

export const getMySkillProgressQuery = graphql(`
  query MySkillProgress {
    me {
      skills {
        id
        name
        sequentialUnlockingEnabled
        description
        defaultBadgeIcon
        myProgress {
          targetLevel {
            id
            index
            name
          }
          progress {
            achievedAt
            progress {
              ...SkillProgress
            }
            skillLevel {
              ...ProgressSkillLevel
            }
          }
        }
      }
    }
  }

  fragment SkillProgress on UserContentProgressWithVisibility {
    ... on ProgressRestricted {
      __typename
      contentId
    }
    ... on ProgressVisible {
      __typename
      userContentProgress {
        passedAt
        progress
        contentId
      }
    }
  }
`)

export const skillLeaderboardSettingsQuery = graphql(`
  query SkillLeaderboardSettings {
    skillSettings {
      showLeaderboard
    }
  }
`)

export const getMySkillsContent = graphql(`
  query GetMySkillsContent {
    me {
      skills {
        id
        name
        sequentialUnlockingEnabled
        skillLevels {
          id
          description
        }
        assignedContent {
          skillLevel {
            id
          }
          content {
            title
            contentId
            image {
              ...ImageFragment
            }
            contentType
            duration
          }
        }
        myProgress {
          progress {
            achievedAt
            progress {
              ...Progress
            }
            skillLevel {
              ...ProgressSkillLevel
            }
          }
        }
      }
    }
  }
`)

export const useInvalidateGetMySkillsContentQuery = (): ReturnType<typeof useInvalidateGraphQuery> => {
  return useInvalidateGraphQuery(getMySkillsContent)
}

export const getMySubcriptionsQuery = graphql(`
  query GetMySubscriptions($id: SkillId!) {
    me {
      skills {
        id
        name
      }
    }
    skill(id: $id) {
      name
      defaultBadgeIcon
      skillLevels {
        id
        levelSetting {
          id
          name
          defaultBadgeTheme
        }
      }
    }
  }
`)

export const useInvalidateGetMySubcriptionsQuery = (): ReturnType<typeof useInvalidateGraphQuery> => {
  return useInvalidateGraphQuery(getMySubcriptionsQuery)
}
