import { DateTime } from 'luxon'
import { TableValue } from 'sierra-domain/api/insights'

export const createEmptyDimensionTableValue = (tableValue: TableValue, date: Date): TableValue => {
  const luxonDate = DateTime.fromJSDate(date)
  const dateString = DateTime.fromJSDate(date).toSQLDate()
  const { weekNumber: week, month, year, quarter } = luxonDate
  switch (tableValue.type) {
    case 'value.date':
      return { type: 'value.date', value: dateString }
    case 'value.week':
      return { type: 'value.week', date: dateString, year, week }
    case 'value.month':
      return { type: 'value.month', date: dateString, year, month }
    case 'value.quarter':
      return { type: 'value.quarter', date: dateString, year, quarter }
    case 'value.year':
      return { type: 'value.year', date: dateString, year }
    default:
      return tableValue
  }
}

export const createEmptyMeasureTableValue = (tableValue: TableValue): TableValue => {
  const { type } = tableValue

  switch (type) {
    case 'value.duration':
      return { type, value: 'PT0S' }
    case 'value.long':
    case 'value.progress':
    case 'value.double':
    case 'value.ratio':
      return { type, value: 0 }
    default:
      throw new Error(`Invalid table value type: ${type}`)
  }
}
