import _ from 'lodash'
import { isElementType } from 'sierra-client/views/v3-author/queries'
import { replaceIdsInNodes } from 'sierra-domain/collaboration/slate-document-map'
import { isValidInteractiveCardChild } from 'sierra-domain/v3-author'
import { Editor, Element, Node, Transforms } from 'slate'

const debug = (...messages: unknown[]): void => console.debug('[withCard]', ...messages)

export const withCard = <T extends Editor>(editor: T, { defaultCard }: { defaultCard: Node }): T => {
  const { normalizeNode } = editor

  editor.normalizeNode = entry => {
    const [, path] = entry

    // There should be nothing at `path > [0]`
    if (_.isEqual(path, [1])) {
      debug('Card editors can only have one child at the root level. Removing at:', JSON.stringify(path))
      return Transforms.removeNodes(editor, { at: path })
    }

    if (_.isEqual(path, []) && editor.children.length === 0) {
      const at = path.concat(0)
      debug('Adding missing default card:', JSON.stringify(at))
      return Transforms.insertNodes(editor, replaceIdsInNodes([defaultCard]), { at })
    }

    return normalizeNode(entry)
  }

  return editor
}

export const withTextThenCard = <T extends Editor>(editor: T, { defaultCard }: { defaultCard: Node }): T => {
  const { normalizeNode } = editor

  editor.normalizeNode = entry => {
    const [, path] = entry

    if (path.length !== 0) return normalizeNode(entry)

    if (_.isEqual(path, []) && editor.children.length === 0) {
      const at = path.concat(0)
      debug('Adding missing default card:', JSON.stringify(at))
      return Transforms.insertNodes(editor, replaceIdsInNodes([defaultCard]), { at })
    }

    const defaultSlateElement = defaultCard

    const cardType = Element.isElement(defaultSlateElement) ? defaultSlateElement.type : undefined

    if (!cardType) return

    const children = Array.from(Node.children(editor, path))

    //There should always exist a "default card"
    const cardElement = children.filter(([child]) => isElementType(cardType, child)) //Is is possible
    if (cardElement.length === 0) {
      return Transforms.insertNodes(editor, replaceIdsInNodes([defaultCard]), { at: [children.length] })
    }

    //The default card should always be last
    const lastChild = _.last(children)

    if (lastChild !== undefined) {
      const [lastChildNode, lastChildPath] = lastChild
      if (!isElementType(cardType, lastChildNode))
        return Transforms.removeNodes(editor, { at: lastChildPath })
    }

    //Todo (isabel) remove children that arent "valid interactive card childr
    const disallowedChildren = children.filter(
      ([child]) => !isValidInteractiveCardChild(child) && !isElementType('sliding-scale-card', child)
    )
    if (disallowedChildren.length !== 0) {
      for (const [, childPath] of disallowedChildren) {
        Transforms.removeNodes(editor, { at: childPath })
      }
    }

    return normalizeNode(entry)
  }

  return editor
}
