import React, { useEffect, useState } from 'react'
import { MUIUsePaginationProps, MUIUsePaginationResult, usePaginationMUI } from 'sierra-ui/mui'

const DEFAULT_PAGE_SIZE = 10

export type UseEnhancedPaginationProps<T> = MUIUsePaginationProps & { pageSize?: number; items: T[] }
export type UseEnhancedPaginationResult<T> = {
  paginationItems: MUIUsePaginationResult['items']
  itemsInPage: T[]
}

export const useEnhancedPagination = <T,>({
  items,
  pageSize = DEFAULT_PAGE_SIZE,
  ...muiPaginationProps
}: UseEnhancedPaginationProps<T>): UseEnhancedPaginationResult<T> => {
  const pageCount = Math.ceil(items.length / pageSize)

  const [currentPage, setCurrentPage] = useState(muiPaginationProps.page ?? 1)

  useEffect(() => {
    // In case the items array change, we keep the pagination on the last page
    if (currentPage > pageCount) {
      setCurrentPage(pageCount || 1)
    }
  }, [currentPage, pageCount, setCurrentPage])

  const { items: paginationItems } = usePaginationMUI({
    hidePrevButton: currentPage === 1,
    hideNextButton: currentPage === pageCount,
    // Set hide buttons before spread to allow override
    ...muiPaginationProps,
    count: pageCount,
    page: currentPage,
    onChange: (_, page) => setCurrentPage(page),
  })

  const itemsInPage = React.useMemo(() => {
    const start = pageSize * (currentPage - 1)
    const finish = start + pageSize
    return items.slice(start, finish)
  }, [currentPage, items, pageSize])

  return {
    paginationItems,
    itemsInPage,
  }
}
