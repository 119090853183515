import { useAtom } from 'jotai'
import React, { ChangeEvent, FC } from 'react'
import { TimeUnit, ValidityPeriodInput } from 'sierra-client/api/graphql/gql/graphql'
import { useTranslation } from 'sierra-client/hooks/use-translation'
import {
  CertificateInput,
  durationUnits,
  selectedCertificateAtom,
} from 'sierra-client/views/manage/certificates/edit-certificate-panel/store'
import { FormElement, MenuItem } from 'sierra-ui/components'
import { Checkbox, InputPrimitive, Text, View } from 'sierra-ui/primitives'
import { SingleSelectDropdown } from 'sierra-ui/primitives/menu-dropdown'

interface UseValidityPeriodInput {
  refetch: (certificate: CertificateInput) => void
}

interface UseValidityPeriod {
  formValues: ValidityPeriodInput
  updateFormValue: <KeyType extends keyof ValidityPeriodInput>(
    key: KeyType,
    value: ValidityPeriodInput[KeyType]
  ) => void
}

function useValidityPeriod({ refetch }: UseValidityPeriodInput): UseValidityPeriod {
  const [certificate, setCertificate] = useAtom(selectedCertificateAtom)
  const [validityPeriod, setValidityPeriod] = React.useState<ValidityPeriodInput>(certificate.validityPeriod)
  const [updated, setUpdated] = React.useState(false)

  const formValues = React.useMemo(() => {
    let duration = validityPeriod.duration
    if (duration === undefined || duration === null) {
      duration = validityPeriod.expires ? 1 : undefined
    }
    let timeUnit = validityPeriod.timeUnit
    if (timeUnit === undefined || timeUnit === null) {
      timeUnit = validityPeriod.expires ? 'MONTHS' : undefined
    }

    return {
      expires: validityPeriod.expires,
      duration,
      timeUnit,
    }
  }, [validityPeriod])

  const updateFormValue = React.useCallback(
    <KeyType extends keyof ValidityPeriodInput>(key: KeyType, value: ValidityPeriodInput[KeyType]) => {
      setValidityPeriod(v => ({ ...v, [key]: value }))
      setUpdated(true)
    },
    [setValidityPeriod]
  )

  React.useEffect(() => {
    if (!updated) {
      return
    }

    let newValidityPeriod = { ...formValues }
    if (newValidityPeriod.expires === false) {
      newValidityPeriod = { expires: false, duration: undefined, timeUnit: undefined }
    }

    const newCertificate = { ...certificate, validityPeriod: newValidityPeriod }

    setUpdated(false)
    setCertificate(newCertificate)
    refetch(newCertificate)
  }, [updated, formValues, certificate, setCertificate, refetch])

  return { formValues, updateFormValue }
}

interface ValidityInformationInputProps {
  refetch: (certificate: CertificateInput) => void
}

export const ValidityInformationInput: FC<ValidityInformationInputProps> = ({ refetch }) => {
  const { t } = useTranslation()
  const { formValues, updateFormValue } = useValidityPeriod({ refetch })

  const consumeClick = (e: React.MouseEvent): void => {
    e.preventDefault()
  }

  const items = durationUnits.map<MenuItem<TimeUnit>>(du => ({
    id: du,
    label: t(`manage.certificates.edit-certificate-modal.duration-units.${du}`),
    type: 'label',
  }))
  const selectedItem = items.find(item => item.id === formValues.timeUnit)

  return (
    <View direction='column'>
      <FormElement label={t('manage.certificates.edit-certificate-modal.validity-period')}>
        <View>
          <Checkbox
            checked={formValues.expires}
            onCheckedChange={() => updateFormValue('expires', !formValues.expires)}
          />
          <Text>{t('manage.certificates.edit-certificate-modal.expires')}</Text>
          <>
            <View>
              <InputPrimitive
                disabled={!formValues.expires}
                value={`${formValues.duration?.valueOf() ?? ''}`}
                min={0}
                type='number'
                onChange={(e: ChangeEvent<HTMLInputElement>) =>
                  updateFormValue('duration', e.target.value === '' ? undefined : Number(e.target.value))
                }
              />
            </View>

            <View onClick={consumeClick}>
              <SingleSelectDropdown
                disabled={!formValues.expires}
                selectedItem={selectedItem}
                menuItems={items}
                onSelect={du => updateFormValue('timeUnit', du.id)}
              />
            </View>

            <Text>{t('manage.certificates.edit-certificate-modal.after-awarded-date')}</Text>
          </>
        </View>
      </FormElement>
    </View>
  )
}
