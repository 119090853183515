import React from 'react'
import { useTranslation } from 'sierra-client/hooks/use-translation'
import { OutputFormat } from 'sierra-domain/api/analytics-reporting'
import { assertNever } from 'sierra-domain/utils'
import { MenuButton } from 'sierra-ui/components'

type DownloadHeatmapButtonProps = {
  isLoading: boolean
  downloadWithFormat: (format: OutputFormat) => void
}

export const DownloadHeatmapButton: React.FC<DownloadHeatmapButtonProps> = ({
  isLoading,
  downloadWithFormat,
}) => {
  const { t } = useTranslation()

  return (
    <MenuButton
      disabled={isLoading}
      menuItems={[
        {
          id: 'download-heatmap-csv',
          type: 'label',
          label: isLoading ? t('manage.heatmap.loading') : t('manage.heatmap.download-as-csv'),
          icon: 'user--group',
          loading: isLoading,
        },
        {
          id: 'download-heatmap-xlsx',
          type: 'label',
          label: isLoading ? t('manage.heatmap.loading') : t('manage.heatmap.download-as-xlsx'),
          icon: 'user--group',
          loading: isLoading,
        },
      ]}
      onSelect={item => {
        switch (item.id) {
          case 'download-heatmap-csv':
            downloadWithFormat('csv')
            return
          case 'download-heatmap-xlsx':
            downloadWithFormat('xlsx')
            return
          default:
            assertNever(item.id)
        }
      }}
    >
      {isLoading ? t('manage.heatmap.loading') : t('manage.reports.download')}
    </MenuButton>
  )
}
