import { useEffect, useMemo, useRef } from 'react'
import { DeleteBox } from 'sierra-client/components/common/delete-box'
import { PageTitle } from 'sierra-client/components/common/page-title'
import { usePost } from 'sierra-client/hooks/use-post'
import { useTranslation } from 'sierra-client/hooks/use-translation'
import { getGlobalRouter } from 'sierra-client/router'
import { DetailsHeader } from 'sierra-client/views/manage/components/details-header'
import { ColumnLayout } from 'sierra-client/views/manage/components/layout/column-layout'
import { OverviewItem } from 'sierra-client/views/manage/components/overview-item'
import { CoursesSection } from 'sierra-client/views/manage/tags/components/sections'
import { TagForm } from 'sierra-client/views/manage/tags/components/tag-form'
import { useTagDetails } from 'sierra-client/views/manage/tags/use-tag-details'
import { TagId } from 'sierra-domain/api/nano-id'
import { XRealtimeAdminTagsDeleteTag } from 'sierra-domain/routes'
import { Spacer, Text, View } from 'sierra-ui/primitives'
import styled from 'styled-components'

const TagContainer = styled.div`
  max-width: 40rem;
  margin-left: auto;
  margin-right: auto;
  padding-top: 3rem;
  padding-bottom: 3rem;
`

type ManageTagDetailsProps = {
  tagId: TagId
}

export function ManageTagDetails({ tagId }: ManageTagDetailsProps): JSX.Element {
  const { t } = useTranslation()
  const { postWithUserErrorException } = usePost()

  const { tag, fetchTag } = useTagDetails(tagId)
  const currentTagId = useRef<TagId | undefined>(undefined)

  const courseIds = useMemo(() => tag?.courses.map(c => c.id) ?? [], [tag?.courses])

  // initialise form values
  useEffect(() => {
    if (tag === undefined || tag.id === currentTagId.current) return
    currentTagId.current = tag.id
  }, [tag])

  const deleteTag = async (): Promise<void> => {
    await postWithUserErrorException(XRealtimeAdminTagsDeleteTag, {
      id: tagId,
    })

    void getGlobalRouter().navigate({ to: `/manage/tags` })
  }

  if (tag === undefined) return <></>

  return (
    <>
      <PageTitle title={tag.tag.data.name} />

      <ColumnLayout>
        <DetailsHeader backlink={{ href: '/manage/tags', label: 'manage.backlinks--tags' }} />

        <TagContainer>
          <Text size='small' bold>
            {t('manage.tags.overview')}
          </Text>
          <Spacer size='xsmall' />
          <View alignItems='center' gap='none'>
            <OverviewItem data={tag.totalCoursesCount} label={t('admin.table.tags.courses')} />
            <Spacer size='xlarge' />
            <OverviewItem data={tag.learnersCount} label={t('admin.table.tags.learners')} />
          </View>
          <Spacer size='xlarge' />
          <Spacer size='4' />

          <>
            <TagForm tag={tag.tag} onAfterSubmit={() => fetchTag()} />
          </>
          <Spacer size='xxlarge' />

          <DeleteBox
            title={t('manage.skill.modal.delete-title')}
            description={t('manage.skill.modal.remove-message')}
            deleteLabel={t('dictionary.delete')}
            confirm
            onDelete={async () => {
              await deleteTag()
            }}
          />

          <CoursesSection courseIds={courseIds} />
        </TagContainer>
      </ColumnLayout>
    </>
  )
}
