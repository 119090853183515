import { GridWrapper } from 'sierra-client/views/v3-author/wrapper'
import styled from 'styled-components'

export const VariationsTopbar = styled(GridWrapper)`
  padding: 0;
  row-gap: 0;

  & > * {
    grid-column: 2 / span 12;
  }
`
