import { useSetAtom } from 'jotai'
import {
  MySkillProgressQuery,
  ProgressSkillLevelFragment,
  SkillProgressFragment,
} from 'sierra-client/api/graphql/gql/graphql'
import { isGQLType } from 'sierra-client/api/graphql/util/types'
import { useGraphQuery } from 'sierra-client/api/hooks/use-graphql-query'
import { SkillColor } from 'sierra-client/features/skills/colors'
import { RichBadge } from 'sierra-client/features/skills/components/badges'
import { OpenSelectSkillModalAtom } from 'sierra-client/features/skills/components/learner-board/atoms'
import { Board } from 'sierra-client/features/skills/components/learner-board/board'
import { EmptyView } from 'sierra-client/features/skills/components/learner-board/empty-view'
import { getMySkillProgressQuery } from 'sierra-client/features/skills/shared-gql-queries'
import { useTranslation } from 'sierra-client/hooks/use-translation'
import { useDebug } from 'sierra-client/lib/use-debug/use-debug'
import { useIsTablet } from 'sierra-client/state/browser/selectors'
import { ItemOf, assertNever, isDefined, isNonEmptyArray } from 'sierra-domain/utils'
import { SkillIconId } from 'sierra-ui/components'
import { ProgressCircle } from 'sierra-ui/missions/foundation'
import { Carousel } from 'sierra-ui/missions/workflows/carousel/carousel'
import { Button, Skeleton, Text, View } from 'sierra-ui/primitives'
import { token } from 'sierra-ui/theming'
import styled from 'styled-components'
import { BadgeStackItem } from '../badge-stack'

const Container = styled(View)`
  flex: 1;
  width: 100%;
  display: flex;
`

type ProgressContent =
  | {
      type: 'progress'
      progress: number
    }
  | {
      type: 'restricted'
    }

export type SkillLevelProgressStatus =
  | {
      type: 'in-progress' | 'locked'
      content: Array<ProgressContent>
    }
  | {
      type: 'achieved'
      content: Array<ProgressContent>
      at: string
    }

export type SkillProgressStatus = Array<{
  achievedAt?: string | null
  progress: SkillLevelProgressStatus
  skillLevel: ProgressSkillLevelFragment
}>

const _gqlProgressToSkillProgressStatus = (
  pss: ReadonlyArray<{
    achievedAt?: string | null
    progress: ReadonlyArray<SkillProgressFragment>
    skillLevel: ProgressSkillLevelFragment
  }>,
  { sequentialUnlockingEnabled }: { sequentialUnlockingEnabled: boolean }
): SkillProgressStatus => {
  const _ps: SkillProgressStatus = pss.map(ps => {
    const content = ps.progress.map(p => {
      if (isGQLType('ProgressVisible', p)) {
        return {
          progress: p.userContentProgress.progress,
          type: 'progress' as const,
        }
      } else {
        return {
          contentInfo: {
            contentId: p.contentId,
          },
          type: 'restricted' as const,
        }
      }
    })

    if (isDefined(ps.achievedAt)) {
      return {
        ...ps,
        progress: {
          type: 'achieved',
          at: ps.achievedAt,
          content: content,
        },
      }
    }

    return {
      ...ps,
      progress: {
        type: 'in-progress',
        content: content,
      },
    }
  })
  if (!sequentialUnlockingEnabled) {
    return _ps
  }

  return _ps.reduce(
    (acc, x) => {
      const isLocked = acc.foundInProgress
      return {
        foundInProgress: acc.foundInProgress || x.progress.type === 'in-progress',
        data: acc.data.concat(isLocked ? { ...x, progress: { ...x.progress, type: 'locked' } } : x),
      }
    },
    { foundInProgress: false, data: [] } as {
      foundInProgress: boolean
      data: SkillProgressStatus
    }
  ).data
}

const gqlProgressToSkillProgressStatus = (
  pss: Array<{
    achievedAt?: string | null
    progress: Array<SkillProgressFragment>
    skillLevel: ProgressSkillLevelFragment
  }>,
  { sequentialUnlockingEnabled }: { sequentialUnlockingEnabled: boolean }
): SkillProgressStatus => {
  return _gqlProgressToSkillProgressStatus(
    pss.map(ps => ({
      ...ps,
      progress: ps.progress,
      skillLevel: ps.skillLevel,
    })),
    { sequentialUnlockingEnabled }
  )
}

const getProgress = (p: SkillLevelProgressStatus): number => {
  switch (p.type) {
    case 'locked':
      return 0
    case 'in-progress': {
      const denominator = p.content.length > 0 ? p.content.length : 1
      return p.content.reduce((acc, p) => (p.type === 'progress' ? acc + p.progress : acc), 0) / denominator
    }
    case 'achieved':
      return 1
    default:
      assertNever(p)
  }
}

function getAllLevelBadges(
  skill: SkillStructure,
  skillProgressStatus: SkillProgressStatus
): BadgeStackItem[] {
  return skillProgressStatus.map(skp => {
    const lvl = skp.skillLevel

    return {
      color: SkillColor.catch('bronze').parse(lvl.levelSetting.defaultBadgeTheme),
      icon: SkillIconId.catch('skill--graduation--cap').parse(skill.defaultBadgeIcon),
      skill: {
        id: skill.id,
        name: skill.name,
      },
      skillLevel: {
        index: lvl.levelSetting.index,
        name: lvl.levelSetting.name,
      },
    }
  })
}

type SkillStructure = ItemOf<MySkillProgressQuery['me']['skills']>

const ErrorView: React.FC = () => {
  const { t } = useTranslation()
  return (
    <View padding='128'>
      <Text>{t('error-page.explanation')}</Text>
    </View>
  )
}

const SkeletonContainer = styled(View)`
  width: 100%;
  height: 100%;
  padding-inline-start: 24px;
`

const BadgePositioning = styled.div`
  display: grid;
  place-items: center;
  justify-content: center;
  align-content: center;

  > * {
    grid-column: 1;
    grid-row: 1;
  }
`
const BadgeSize = styled.div`
  height: 70%;
  aspect-ratio: 1 / 1.1;
`
const SeparatorLine = styled.hr`
  border: 1px solid ${token('border/default')};
  width: 100%;
  margin: 0;
`

const FullHeightView = styled(View)`
  height: 100%;
`

const StackContainer = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  position: relative;
  width: 100%;
  height: 100%;
`

const BadgeWrapper = styled.div`
  transition: all 150ms ease-in-out;
  height: 60%;
  aspect-ratio: 1 / 1.1;
  margin: 0 -20px;

  &:hover {
    transform: translateY(-10px);
  }
`

const CarouselContainer = styled(View)`
  flex-grow: 1;
`

type MyProgressSkill = MySkillProgressQuery['me']['skills'][number]

export type YourSkillsViewProps = {
  showLeaderboard: boolean
}

export const YourSkillsView: React.FC<YourSkillsViewProps> = ({ showLeaderboard }) => {
  const res = useGraphQuery({ document: getMySkillProgressQuery }, {})
  const debug = useDebug('Your Skills', { loading: false, error: false })
  const { t } = useTranslation()
  const isTablet = useIsTablet()

  const setSelectSkillOpen = useSetAtom(OpenSelectSkillModalAtom)

  const hasSkillBeenAchieved = (skill: MySkillProgressQuery['me']['skills'][number]): boolean => {
    return skill.myProgress.progress.every(progress => isDefined(progress.achievedAt))
  }

  const sortSkillsOnAchieved = (skillA: MyProgressSkill, _: MyProgressSkill): number => {
    const aAchieved = hasSkillBeenAchieved(skillA)
    return aAchieved ? 1 : 0
  }

  const skills = res.data?.me.skills

  const sortedSkills = skills?.sort(sortSkillsOnAchieved)

  if (debug.loading || res.isLoading) {
    return (
      <SkeletonContainer direction='column'>
        {Array.from({ length: 3 }).map((_, i) => (
          <View key={i} grow direction='column' gap='16'>
            <Skeleton $height={20} $width={200} />
            <Skeleton $height={54} $width='100%' />
          </View>
        ))}
      </SkeletonContainer>
    )
  }

  if (debug.error || res.isError) {
    return (
      <Board title={isTablet ? undefined : t('skills.board.your-skills')} disableManageButton>
        <Container>
          <ErrorView />
        </Container>
      </Board>
    )
  }

  return isNonEmptyArray(sortedSkills) ? (
    <Board title={isTablet ? undefined : t('skills.board.your-skills')} disableManageButton>
      <CarouselContainer>
        <Carousel.Root>
          {sortedSkills.map((s, index) => {
            const skillProgressStatus = gqlProgressToSkillProgressStatus(s.myProgress.progress, {
              sequentialUnlockingEnabled: s.sequentialUnlockingEnabled,
            })
            const level = skillProgressStatus.find(p => p.achievedAt === null)
            const icon = SkillIconId.catch('skill--graduation--cap').parse(s.defaultBadgeIcon)

            if (level === undefined) {
              const badges = getAllLevelBadges(s, skillProgressStatus)

              return (
                <Carousel.Slide key={index} index={index}>
                  <FullHeightView direction='column'>
                    <View direction='column' gap='2' alignItems='center'>
                      <a href={`/skill/${s.id}`}>
                        <Text bold>{s.name}</Text>
                      </a>
                      <Text color='foreground/secondary'>
                        {t('skills.board.your-skills.badge-stack.title')}
                      </Text>
                    </View>
                    <FullHeightView justifyContent='center'>
                      <StackContainer>
                        {badges.map((badge, j) => {
                          const icon = SkillIconId.catch('skill--graduation--cap').parse(badge.icon)
                          const theme = SkillColor.catch('bronze').parse(badge.color)

                          return (
                            <BadgeWrapper key={`badge_${badge.skill.name}_${j}`}>
                              <RichBadge
                                iconId={icon}
                                theme={theme}
                                title={s.name}
                                subtitle={badge.skillLevel.name}
                              />
                            </BadgeWrapper>
                          )
                        })}
                      </StackContainer>
                    </FullHeightView>
                  </FullHeightView>
                </Carousel.Slide>
              )
            }

            const calculatedProgress = getProgress(level.progress)
            const progressPercent = Math.round(calculatedProgress * 100)
            const badgeDisabled = progressPercent < 100
            const levelName = level.skillLevel.levelSetting.name
            const badgeTheme = SkillColor.catch('bronze').parse(
              level.skillLevel.levelSetting.defaultBadgeTheme
            )

            return (
              <Carousel.Slide key={s.id} index={index}>
                <View direction='column' justifyContent='flex-start' alignItems='center' gap='16'>
                  <View direction='column' gap='2' alignItems='center'>
                    <a href={`/skill/${s.id}`}>
                      <Text bold>{s.name}</Text>
                    </a>
                    <Text color='foreground/secondary'>
                      {progressPercent === 0 ? t('dictionary.next-up') : t('dictionary.in-progress')} -{' '}
                      {levelName}
                    </Text>
                  </View>

                  <BadgePositioning>
                    <BadgeSize>
                      <RichBadge
                        title={s.name}
                        theme={badgeDisabled ? 'disabled' : badgeTheme}
                        subtitle={levelName}
                        iconId={icon}
                      />
                    </BadgeSize>
                    <ProgressCircle
                      size={180}
                      strokeColor='blueBright'
                      strokeBackground={{ opacity: 1, color: 'border/strong' }}
                      startProgress
                      strokeWidth={16}
                      strokeShape='round'
                      percents={progressPercent}
                    />
                  </BadgePositioning>
                </View>
              </Carousel.Slide>
            )
          })}
        </Carousel.Root>
      </CarouselContainer>

      <SeparatorLine />

      <Button
        variant='transparent'
        grow
        icon='add'
        onClick={() => {
          setSelectSkillOpen(true)
        }}
      >
        {t('skills.board.empty.browse-button')}
      </Button>
    </Board>
  ) : (
    <EmptyView showLeaderboard={showLeaderboard} />
  )
}
