import { createFileRoute } from '@tanstack/react-router'
import { PageIdentifier } from 'sierra-client/layout/sana-page'
import { ManageCertificateDetails } from 'sierra-client/views/manage/certificates/manage-certificate-details'
import { useAreCertificatesEnabled } from 'sierra-client/views/manage/certificates/use-are-certificates-enabled'
import { ManagePageWrapper } from 'sierra-client/views/manage/utils/manage-page-wrapper'
import { CertificateId } from 'sierra-domain/api/nano-id'
import { z } from 'zod'

// Note: ManagePageWrapper handles the role check.
const Page: React.FC = () => {
  const certificateId = Route.useParams({ select: p => p.certificateId })
  const certificatesEnabled = useAreCertificatesEnabled()

  if (!certificatesEnabled) {
    // TODO: Check if permissions are loaded and then redirect somewhere else.
    return null
  }

  return (
    <ManagePageWrapper pageIdentifier={PageIdentifier.ManageCertificateDetail({ certificateId })}>
      <ManageCertificateDetails key={certificateId} certificateId={certificateId} />
    </ManagePageWrapper>
  )
}

const RouteComponent = Page

export const Route = createFileRoute('/manage/certificates/$certificateId')({
  component: RouteComponent,
  params: {
    parse: z.object({ certificateId: CertificateId }).parse,
    stringify: p => p,
  },
})
