import { assertNever } from 'sierra-domain/utils'
import { Icon, IconId } from 'sierra-ui/components'
import { Text, TextSize, View } from 'sierra-ui/primitives'
import { token } from 'sierra-ui/theming'
import { DesignToken } from 'sierra-ui/theming/tokens/types'
import styled from 'styled-components'

type Variant = 'warning' | 'error' | 'info' | 'success'

const getIcon = (v: Variant): IconId => {
  switch (v) {
    case 'info':
      return 'information'
    case 'warning':
      return 'warning--filled'
    case 'error':
      return 'warning'
    case 'success':
      return 'checkmark--outline'
    default:
      assertNever(v)
  }
}

const getBackground = (v: Variant): DesignToken => {
  switch (v) {
    case 'info':
      return 'surface/soft'
    case 'warning':
      return 'warning/background'
    case 'error':
      return 'destructive/background'
    case 'success':
      return 'success/background'
    default:
      assertNever(v)
  }
}

const getForeground = (v: Variant): DesignToken => {
  switch (v) {
    case 'info':
      return 'foreground/primary'
    case 'warning':
      return 'warning/foreground'
    case 'error':
      return 'destructive/foreground'
    case 'success':
      return 'success/foreground'
    default:
      assertNever(v)
  }
}
const Container = styled(View)<{ variant: Variant }>`
  color: ${p => token(getForeground(p.variant))};
  background-color: ${p => token(getBackground(p.variant))};
  padding: 12px;
  border-radius: 8px;
  gap: 8px;
`

/**
 * TODO: Not sure we should even have this component? If becomes a pattern, break this out to be used from our component lib. DO NOT JUST IMPORT THIS AS IT IS
 *
 * An alert component which is inspired of MUI:s alert component (hence the name)
 * https://mui.com/material-ui/react-alert/
 * */
export const Alert: React.FC<React.PropsWithChildren<{ variant: Variant; size?: TextSize }>> = ({
  variant,
  children,
  size,
}) => {
  const icon = getIcon(variant)

  return (
    <Container variant={variant}>
      <Icon iconId={icon} />
      <Text size={size}>{children}</Text>
    </Container>
  )
}
