import _ from 'lodash'
import { StyledMessage } from 'sierra-client/components/chat/chat-message/chat-message'
import { Emoji } from 'sierra-client/components/common/emoji'
import { EmojiMessageGroup } from 'sierra-client/state/chat/selectors'
import { useUsersLegacy } from 'sierra-client/state/users/hooks'
import { commaSeparatedList } from 'sierra-domain/utils'
import { spacing, token } from 'sierra-ui/theming'
import { fonts } from 'sierra-ui/theming/fonts'

import styled from 'styled-components'

const EmojiMessageText = styled.div`
  font-weight: ${fonts.weight.bold};
  color: ${token('foreground/muted')};
  font-size: 12px;
  padding-right: 0.5rem;
  line-height: 24px;
  align-items: center;
`

const EmojiMessage = styled.div`
  display: flex;
  padding: ${spacing['4']} 1.5rem;
  flex-direction: row;

  ${StyledMessage} + & {
    padding-top: 1.5rem;
  }

  &:last-of-type {
    padding-bottom: 2rem;
  }
`

const StyledEmoji = styled(Emoji)`
  margin-left: 6px;
  display: inline-flex;
  align-items: center;
  transform: translateY(2px);
  color: ${token('foreground/primary')};
`

const limitedCommaSeparatedList = (l: (string | undefined)[], limit: number): string => {
  const cleaned = _.compact(l)
  const [heads, others] = [cleaned.slice(0, limit), cleaned.slice(limit)]
  if (others.length <= 1) return commaSeparatedList([...heads, ...others])

  return `${_.join(heads, ', ')}, and ${others.length} others`
}

export const EmojiMessages = ({ group }: { group: EmojiMessageGroup }): JSX.Element => {
  const users = useUsersLegacy(group.userIds)

  return (
    <EmojiMessage>
      <EmojiMessageText>
        {`${limitedCommaSeparatedList(
          users.map(user => user?.firstName),
          2
        )} reacted with`}
        <StyledEmoji size={14} emojiUnicode={group.emoji} />
      </EmojiMessageText>
    </EmojiMessage>
  )
}
