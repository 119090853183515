import { useCallback, useEffect, useState } from 'react'
import { usePost } from 'sierra-client/hooks/use-post'
import { ApiClientWithStatus } from 'sierra-domain/api/public-api'
import { XRealtimeAdminApiGetClients } from 'sierra-domain/routes'

type UseManageApiData = {
  apiClients: ApiClientWithStatus[]
  isLoading: boolean
  fetchClients: () => Promise<void>
  // fetchSecret: (clientId: string) => Promise<CourseRating[]>
}

export const mapApiClientToCsv = (apiClient: ApiClientWithStatus): Record<string, string> => ({
  clientId: apiClient.clientId,
  name: apiClient.name,
  description: apiClient.description,
  status: apiClient.status,
})

export const useManageApiClient = (): UseManageApiData => {
  const { postWithUserErrorException } = usePost()
  const [isLoading, setIsLoading] = useState<boolean>(false)
  const [apiClients, setApiClients] = useState<ApiClientWithStatus[]>([])

  const fetchClients = useCallback<UseManageApiData['fetchClients']>(async () => {
    setIsLoading(true)
    const response = await postWithUserErrorException(XRealtimeAdminApiGetClients, {})
    setApiClients(response.clients)
    setIsLoading(false)
  }, [postWithUserErrorException])

  useEffect(() => {
    void fetchClients()
  }, [fetchClients])

  return {
    isLoading,
    apiClients,
    fetchClients,
  }
}
