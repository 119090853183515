import { Collaboration } from '@tiptap/extension-collaboration'
import CollaborationCursor from '@tiptap/extension-collaboration-cursor'
import Link from '@tiptap/extension-link'
import Placeholder from '@tiptap/extension-placeholder'
import { Editor, Extensions } from '@tiptap/react'
import { StarterKit } from '@tiptap/starter-kit'
import { useEffect, useMemo } from 'react'
import { useSelector } from 'sierra-client/state/hooks'
import { selectUser } from 'sierra-client/state/user/user-selector'
import { getSessionSummary, getSessionSummaryFragment } from 'sierra-domain/collaboration/session-summary-map'
import { theme } from 'sierra-ui/theming/legacy-theme'
import { Awareness } from 'y-protocols/awareness'
import * as Y from 'yjs'

class Provider {
  awareness: Awareness

  constructor(awareness: Awareness) {
    this.awareness = awareness
  }
}

type CollaborationCursorUser = {
  name?: string
  color: string
}

export type SummaryEditorProps = { placeholder: string; awareness: Awareness; yDoc: Y.Doc }

export const useSummaryEditor = ({
  placeholder,
  yDoc,
  awareness,
}: SummaryEditorProps): { editor: Editor | null; fragment: Y.XmlFragment } => {
  const user = useSelector(selectUser)
  const provider = useMemo(() => new Provider(awareness), [awareness])
  const sessionSummary = getSessionSummary(yDoc)
  const fragment = useMemo(() => getSessionSummaryFragment(sessionSummary), [sessionSummary])

  const editor = useMemo(() => {
    const extensions: Extensions = [
      StarterKit.configure({
        blockquote: false,
        dropcursor: false,
        gapcursor: false,
        hardBreak: false,
        heading: false,
        history: false,
        code: false,
        codeBlock: false,
      }),
      Link.configure(),
      Placeholder.configure({
        placeholder: placeholder,
      }),
      Collaboration.configure({
        fragment: fragment,
      }),

      CollaborationCursor.configure({
        provider,
        user: {
          name: user?.firstName,
          color: theme.color[user?.avatarColor ?? 'pinkBright'],
        } as CollaborationCursorUser,
        render: (user: CollaborationCursorUser) => {
          // Based on https://github.com/ueberdosis/tiptap/tree/main/packages/extension-collaboration-cursor
          const cursor = document.createElement('span')
          cursor.classList.add('collaboration-cursor__caret')
          cursor.setAttribute('style', `--cursor-color: ${user.color}`)

          const label = document.createElement('div')
          label.classList.add('collaboration-cursor__label')
          label.appendChild(document.createTextNode(user.name ?? ''))
          cursor.appendChild(label)

          return cursor
        },
      }),
    ]

    return new Editor({
      editable: true,
      extensions,
    })
  }, [placeholder, fragment, provider, user?.firstName, user?.avatarColor])

  useEffect(() => () => editor.destroy(), [editor])

  return { editor, fragment }
}
