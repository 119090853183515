import React from 'react'
import { useCreatePageContextSafe } from 'sierra-client/views/flexible-content/create-page-context'
import { useSelfPacedFilesSafe } from 'sierra-client/views/self-paced/files-provider'
import {
  StupidQuestionsEditorView,
  StupidQuestionsLearnerView,
} from 'sierra-client/views/v3-author/qa-card/QA-learner-view'
import { FileId } from 'sierra-domain/flexible-content/identifiers'
import { StupidQuestionsData } from 'sierra-domain/flexible-content/types'

export type QACardsProps = {
  data: StupidQuestionsData
  fileId: FileId
  readOnly: boolean
}

export const QACard: React.FC<QACardsProps> = ({ readOnly, fileId }) => {
  // const { t } = useTranslation()
  const flexibleContentId = useSelfPacedFilesSafe()?.flexibleContentId

  const createContentId = useCreatePageContextSafe()?.createContentId

  return (
    <>
      {readOnly
        ? flexibleContentId && <StupidQuestionsLearnerView courseId={flexibleContentId} fileId={fileId} />
        : createContentId && (
            <div style={{ overflowX: 'scroll' }}>
              <StupidQuestionsEditorView createContentId={createContentId} fileId={fileId} />
            </div>
          )}
    </>
  )
}
