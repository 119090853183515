import { createFileRoute } from '@tanstack/react-router'
import { requireLoggedIn } from 'sierra-client/core/require-logged-in'
import { NextCoursePage } from 'sierra-client/views/learner/course/course-page'
import { Route as IndexRoute } from './index.route'

const RouteComponent = (): JSX.Element => {
  const { courseId, programId } = Route.useParams()
  return <NextCoursePage courseId={courseId} programId={programId} />
}

export const Route = createFileRoute('/program/$programId/course/$courseId/next')({
  component: requireLoggedIn(RouteComponent, { allowScorm: true }) as React.FC,
  params: IndexRoute.options.params,
})
