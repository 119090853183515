import { FC } from 'react'
import { QuestionCardResponse } from 'sierra-client/features/sana-now/header/participants-popover/utils'
import { useTranslation } from 'sierra-client/hooks/use-translation'
import { Tooltip } from 'sierra-ui/components'
import styled from 'styled-components'

const StarWrapper = styled.span`
  font-size: 16px;
  user-select: none;
`

const EvaluationIndicatorDotContainer = styled.div`
  padding-right: 4px;
`

const EvaluationIndicatorDot = styled.div<{ correct: boolean }>`
  width: 8px;
  height: 8px;
  background-color: ${p => {
    if (p.correct) return p.theme.color.greenBright
    else return p.theme.color.redBright
  }};
  border-radius: 50%;
`

export const EvaluationIndicator: FC<{ response: QuestionCardResponse }> = ({ response }) => {
  const { t } = useTranslation()

  if (response.correct && response.fastestCorrect) {
    return (
      <Tooltip title={t('live.question-card.fastest-answer')}>
        <StarWrapper>🥇</StarWrapper>
      </Tooltip>
    )
  }

  if (response.correct && response.attempts === 1) {
    return (
      <Tooltip title={t('live.question-card.first-attempt-correct')}>
        <StarWrapper>⭐</StarWrapper>
      </Tooltip>
    )
  }

  return (
    <EvaluationIndicatorDotContainer>
      <EvaluationIndicatorDot correct={response.correct} />
    </EvaluationIndicatorDotContainer>
  )
}
