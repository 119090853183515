import { useMemo } from 'react'
import { pastedEmails } from 'sierra-client/core/logging/identities-selector/logger'
import { useDispatch } from 'sierra-client/state/hooks'

type IdentitiesSelectorTracking = {
  pastedEmails: (data: { numberOfEmails: number }) => void
}

export function useTracking(selectorId: string): IdentitiesSelectorTracking {
  const dispatch = useDispatch()

  return useMemo(
    () => ({
      pastedEmails: ({ numberOfEmails }) => dispatch(pastedEmails({ selectorId, numberOfEmails })),
    }),
    [dispatch, selectorId]
  )
}
