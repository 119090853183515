import { useQueryClient } from '@tanstack/react-query'
import { Provider, atom, useAtomValue } from 'jotai'
import * as React from 'react'
import { useCallback, useState } from 'react'
import { graphql } from 'sierra-client/api/graphql/gql'
import {
  UpdateUserToLatestProgramVersionAllMutation,
  UpdateUserToLatestProgramVersionMutation,
} from 'sierra-client/api/graphql/gql/graphql'
import { graphQuery } from 'sierra-client/api/hooks/use-graphql-query'
import { ActionModal } from 'sierra-client/components/common/modals/action-modal'
import { useTranslation } from 'sierra-client/hooks/use-translation'
import { TranslationLookup } from 'sierra-client/hooks/use-translation/types'
import { useOverrideIntercomLauncherVisibility } from 'sierra-client/intercom/intercom-visibility'
import { TableAPI } from 'sierra-client/lib/tabular/api'
import {
  TabularToolbar,
  TabularToolbarButton,
  TabularToolbarDownloadButton,
  countSelected,
} from 'sierra-client/lib/tabular/components/tabular-toolbar'
import { DataLoaderStateMachine } from 'sierra-client/lib/tabular/control/dataloader'
import {
  ProgramUsersTableData,
  programMembersDataLoader,
} from 'sierra-client/lib/tabular/dataloader/program-members'
import { Cell } from 'sierra-client/lib/tabular/datatype/internal/cell'
import { Header } from 'sierra-client/lib/tabular/datatype/internal/header'
import { exportTableData } from 'sierra-client/lib/tabular/export'
import { TableCallbacks, TabularProviderFromTableAPI } from 'sierra-client/lib/tabular/provider'
import { BasicTabular } from 'sierra-client/lib/tabular/provider/components/basic'
import { UseTableAPI, useTableAPI } from 'sierra-client/lib/tabular/use-table-api'
import {
  defaultMenuActionVirtualColumn,
  defaultSelectVirtualColumn,
  getRowDataFromTableAPI,
  temporaryComputeSelectedRows,
} from 'sierra-client/lib/tabular/utils'
import { VirtualColumn, VirtualColumns } from 'sierra-client/lib/tabular/virtual-columns'
import { getGlobalRouter } from 'sierra-client/router'
import { postWithUserErrorException } from 'sierra-client/state/api'
import { store } from 'sierra-client/state/store'
import { ManageHeader, ManageHeaderSearchConfig } from 'sierra-client/views/manage/components/manage-header'
import { useTracking } from 'sierra-client/views/manage/programs/hooks/use-tracking'
import { SendReminderModal } from 'sierra-client/views/manage/programs/send-reminder/send-reminder-modal'
import { SendReminderAction } from 'sierra-client/views/manage/programs/send-reminder/types'
import { ViewUserProgramModal } from 'sierra-client/views/manage/programs/staggered-assignments/panels/view-user-program-version-panel'
import { UserProgramVersion } from 'sierra-domain/api/manage'
import { ProgramId, UserId } from 'sierra-domain/api/uuid'
import { XRealtimeAdminAssignmentsUnassignAllExceptFromProgram } from 'sierra-domain/routes'
import { getUserName, isDefined } from 'sierra-domain/utils'
import { MenuItem, Tooltip } from 'sierra-ui/components'
import { Button, Spacer } from 'sierra-ui/primitives'
import styled from 'styled-components'

const TableSizer = styled.div`
  min-height: 500px;
`

type Callback = () => void

export type ProgramUsersActions = {
  onViewDetails: (ref: UserId) => void
  onDelete: (ref: UserId, callback?: Callback) => void
  onBulkDeleteUsers: (refs: UserId[], callback?: () => void) => void
  onViewProgramVersion: (programVersion: UserProgramVersion, userId: UserId, name: string) => void
  onAssignUsersToLatestVersion: (userIds: UserId[], names: string[]) => void
  onAssignAllUsersToLatestVersion: (excludedUsers: UserId[], selectedCount: number) => void
  onOpenReminderModalForBulk: () => void
  onOpenReminderModalForRow: (userId: UserId) => void
  onOpenReminderModalForAll: (excludedUsers: UserId[], selectedCount: number) => void
  onDeleteAllExceptUsers: (excludedUsers: UserId[], selectedCount: number) => void
}

type ViewProgressOnClick = (api: TableAPI<ProgramUsersTableData>, ref: string) => void

const WrappedButton: React.FC<{ onClick: () => void }> = ({ onClick }) => {
  const { t } = useTranslation()

  return (
    <Button
      variant='ghost'
      onClick={event => {
        event.stopPropagation()
        onClick()
      }}
    >
      {t('manage.programs.view-progress')}
    </Button>
  )
}

function createButtonColumn({
  onClick,
}: {
  onClick: ViewProgressOnClick
}): VirtualColumn<ProgramUsersTableData> {
  return {
    ref: 'view-progress',
    header: () =>
      ({
        type: 'empty',
        ref: 'view-progress',
        enabled: atom(() => true),
        sortable: atom(() => false),
        hints: [],
      }) satisfies Header,
    cell: ({ pos, api }) =>
      ({
        type: 'canvas',
        pos,
        hints: [],
        enabled: atom(() => true),
        selected: atom(() => false),
        data: {
          view: (
            <WrappedButton
              onClick={() => {
                onClick(api, pos.row)
              }}
            />
          ),
        },
      }) satisfies Cell,
  }
}

export const createProgramUsersVirtualColumns = (
  actions: ProgramUsersActions,
  { canChangeUserVersion, canEnrollUsers }: { canChangeUserVersion: boolean; canEnrollUsers: boolean },
  t: TranslationLookup
): VirtualColumns<ProgramUsersTableData> => ({
  left: [defaultSelectVirtualColumn<ProgramUsersTableData>()],
  right: [
    ...[
      createButtonColumn({
        onClick: (api: TableAPI<ProgramUsersTableData>, ref: string) => {
          const rowData = getRowDataFromTableAPI(api, ref)

          if (rowData === undefined) return

          const userData = rowData.user.data
          const versionData = rowData.version.data
          const userId = userData?.id

          if (userId === undefined) {
            return
          }

          actions.onViewProgramVersion(versionData, userId, getUserName(userData) ?? '')
        },
      }),
    ],
    defaultMenuActionVirtualColumn({
      getProps: ({ api, pos }) => {
        const menuItems: Array<MenuItem> = [
          {
            id: pos.row + '-assign-latest-version',
            type: 'label',
            label: t('manage.programs.user-table.assign-latest-version'),
            icon: 'reset',
            hidden:
              !canChangeUserVersion ||
              (getRowDataFromTableAPI(api, pos.row)?.version.data.isUserInLastVersion ?? false),
            onClick: () => {
              const data = getRowDataFromTableAPI(api, pos.row)?.user.data
              if (!data) return

              actions.onAssignUsersToLatestVersion([data.id], [getUserName(data) ?? ''])
            },
          },
          {
            id: pos.row + '-send-reminder',
            type: 'label',
            label: t('manage.programs.user-table.send-reminder'),
            icon: 'email',
            hidden: getRowDataFromTableAPI(api, pos.row)?.user.data?.status !== 'active',
            onClick: () => {
              const userData = getRowDataFromTableAPI(api, pos.row)?.user.data
              if (!userData) return

              return actions.onOpenReminderModalForRow(userData.id)
            },
          },
          {
            id: pos.row + '-unassign',
            type: 'label',
            label: t('manage.programs.user-table.unassign'),
            icon: 'user--remove',
            color: 'destructive/background',
            hidden: !canEnrollUsers,
            onClick: () => {
              const userData = getRowDataFromTableAPI(api, pos.row)?.user.data
              if (!userData) return

              return actions.onDelete(userData.id, () => api.action.refresh())
            },
          },
        ]

        return {
          menuItems,
        }
      },
    }),
  ],
})

const BulkDeleteUsers: React.FC<{
  actions: ProgramUsersActions
  api: TableAPI<ProgramUsersTableData>
}> = ({ actions, api }) => {
  const sel = useAtomValue(api.atoms.selection)
  const tableData = useAtomValue(api.atoms.tableData)
  const pag = useAtomValue(api.atoms.pagination)
  const selectedCount = countSelected(pag, sel)
  const { t } = useTranslation()

  switch (sel.type) {
    case 'none':
      return <></>
    case 'all': {
      if (api.query.query().query !== undefined) return // we don't allow to select all when there is a query
      const excludedUsers = Array.from(sel.excluded).map(it => it as UserId)
      return (
        <TabularToolbarButton
          onClick={() => actions.onDeleteAllExceptUsers(excludedUsers, selectedCount)}
          icon={'trash-can'}
        >
          {`${t('admin.remove')} (${selectedCount})`}
        </TabularToolbarButton>
      )
    }
    case 'manual': {
      const selected = temporaryComputeSelectedRows(tableData, sel)
      const selectedIds = tableData
        .flatMap(td => td.rows)
        .filter(r => selected.includes(r.id))
        .flatMap(r => {
          const userData = r.data.user.data
          return userData ? [userData.id] : []
        })

      return (
        <TabularToolbarButton
          onClick={() => {
            actions.onBulkDeleteUsers(selectedIds, async () => {
              await api.action.refresh()
              api.action.setSelection({ type: 'none' })
            })
          }}
          icon={'trash-can'}
        >
          {`${t('admin.remove')} (${selected.length})`}
        </TabularToolbarButton>
      )
    }
  }
}

const UpdateUsersToLatestVersion: React.FC<{
  actions: ProgramUsersActions
  api: TableAPI<ProgramUsersTableData>
}> = ({ actions, api }) => {
  const sel = useAtomValue(api.atoms.selection)
  const tableData = useAtomValue(api.atoms.tableData)
  const pag = useAtomValue(api.atoms.pagination)
  const selectedCount = countSelected(pag, sel)
  const { t } = useTranslation()

  switch (sel.type) {
    case 'none':
      return <></>
    case 'all': {
      if (api.query.query().query !== undefined) return // we don't allow to select all when there is a query
      const excludedUsers = Array.from(sel.excluded).map(it => it as UserId)
      return (
        <TabularToolbarButton
          onClick={() => {
            actions.onAssignAllUsersToLatestVersion(excludedUsers, selectedCount)
          }}
          icon='reset'
        >
          {`${t('manage.programs.user-table.assign-latest-version')} (${selectedCount})`}
        </TabularToolbarButton>
      )
    }
    case 'manual': {
      const selected = temporaryComputeSelectedRows(tableData, sel)
      const selectedNames = selected
        .map(s => getRowDataFromTableAPI(api, s)?.user.data)
        .map(getUserName)
        .filter(isDefined)

      const selectedIds = tableData
        .flatMap(td => td.rows)
        .filter(r => selected.includes(r.id))
        .flatMap(r => {
          const userData = r.data.user.data
          return userData ? [userData.id] : []
        })
      return (
        <TabularToolbarButton
          onClick={() => actions.onAssignUsersToLatestVersion(selectedIds, selectedNames)}
          icon='reset'
        >
          {`${t('manage.programs.user-table.assign-latest-version')} (${selected.length})`}
        </TabularToolbarButton>
      )
    }
  }
}

export const SendReminderToUsers: React.FC<{
  actions: ProgramUsersActions
  api: TableAPI<ProgramUsersTableData>
}> = ({ actions, api }) => {
  const { t } = useTranslation()
  const tableData = useAtomValue(api.atoms.tableData)
  const sel = useAtomValue(api.atoms.selection)
  const pag = useAtomValue(api.atoms.pagination)
  const selectedCount = countSelected(pag, sel)

  switch (sel.type) {
    case 'none':
      return <></>
    case 'all': {
      if (api.query.query().query !== undefined) return // we don't allow to select all when there is a query
      const excludedUsers = Array.from(sel.excluded).map(it => it as UserId)
      return (
        <TabularToolbarButton
          onClick={() => {
            actions.onOpenReminderModalForAll(excludedUsers, selectedCount)
          }}
          icon={'email'}
        >
          {`${t('manage.programs.user-table.send-reminder')} (${selectedCount})`}
        </TabularToolbarButton>
      )
    }
    default: {
      const selected = temporaryComputeSelectedRows(tableData, sel)
      return (
        <Tooltip title={t('manage.programs.user-table.tooltip')} side={'top'}>
          <TabularToolbarButton onClick={() => actions.onOpenReminderModalForBulk()} icon={'email'}>
            {`${t('manage.programs.user-table.send-reminder')} (${selected.length})`}
          </TabularToolbarButton>
        </Tooltip>
      )
    }
  }
}

export const ProgramUsersTabular: React.FC<{
  actions: ProgramUsersActions
  tableAPI: UseTableAPI<ProgramUsersTableData, { cursor: string | null | undefined }>
  onViewProgramVersion: (programVersion: UserProgramVersion, userId: UserId, name: string) => void
  canEnrollUsers: boolean
}> = ({ actions, tableAPI, canEnrollUsers }) => {
  const { t } = useTranslation()

  const memoizedCallbacks = React.useMemo<TableCallbacks<ProgramUsersTableData>>(
    () => ({ onRow: row => getGlobalRouter().navigate({ to: `/manage/users/${row.ref}` }) }),
    []
  )

  const searchBar: ManageHeaderSearchConfig = { placeholder: 'manage.search.users' }

  return (
    <TabularProviderFromTableAPI tableAPI={tableAPI} callbacks={memoizedCallbacks}>
      <ManageHeader api={tableAPI.api} search={searchBar} />
      <Spacer size={'xxsmall'} />
      <TabularToolbar
        clearFilters={false}
        api={tableAPI.api}
        enableAllSelection={true}
        countsTranslationKeys={{
          totalKey: 'manage.program-members.n-users',
          filterKey: 'manage.program-members.n-filtered',
          selectedKey: 'manage.tables.n-selected',
        }}
        actions={
          <>
            <UpdateUsersToLatestVersion actions={actions} api={tableAPI.api} />
            <SendReminderToUsers actions={actions} api={tableAPI.api} />
            {canEnrollUsers && <BulkDeleteUsers actions={actions} api={tableAPI.api} />}
            <TabularToolbarDownloadButton
              onDownloadCSV={() =>
                exportTableData({ api: tableAPI.api, dataLoader: tableAPI.dataLoader, t, fileType: 'csv' })
              }
              onDownloadXLSX={() =>
                exportTableData({ api: tableAPI.api, dataLoader: tableAPI.dataLoader, t, fileType: 'xlsx' })
              }
            />
          </>
        }
      />
      <TableSizer>
        <BasicTabular />
      </TableSizer>
    </TabularProviderFromTableAPI>
  )
}

const assignUsersToLatestProgramVersion = async (
  programId: string,
  userIds: UserId[]
): Promise<UpdateUserToLatestProgramVersionMutation> =>
  graphQuery(
    graphql(`
      mutation UpdateUserToLatestProgramVersion($programId: ProgramId!, $userIds: [UserId!]!) {
        updateProgramToLatestVersion(programId: $programId, userIds: $userIds) {
          version
        }
      }
    `),
    { programId, userIds }
  )

const assignAllUsersToLatestProgramVersion = async (
  programId: string,
  excludedUsers: UserId[]
): Promise<UpdateUserToLatestProgramVersionAllMutation> =>
  graphQuery(
    graphql(`
      mutation UpdateUserToLatestProgramVersionAll($programId: ProgramId!, $excludedUsers: [UserId!]!) {
        updateProgramToLatestVersionAll(programId: $programId, excludedUsers: $excludedUsers) {
          version
        }
      }
    `),
    { programId, excludedUsers }
  )

const removeAllExceptUsers = async (programId: string, excludedUsers: UserId[]): Promise<void> => {
  await postWithUserErrorException(
    XRealtimeAdminAssignmentsUnassignAllExceptFromProgram,
    {
      programId,
      excludedUsers,
    },
    store.dispatch
  )
}

type Action =
  | { modal: undefined }
  | {
      modal: 'show-program-version'
      programVersion: UserProgramVersion
      userId: UserId
      name: string
    }
  | SendReminderAction
/* Secondary actions allow us to open to actions at the same time (e.g. a confirmation modal) */
type SecondaryAction =
  | { modal: undefined }
  | { modal: 'assign-program-version'; userIds: UserId[]; names: string[] }
  | { modal: 'assign-program-version-all'; excludedUsers: UserId[]; selectedCount: number }
  | { modal: 'remove-all-except-users'; excludedUsers: UserId[]; selectedCount: number }

type UseProgramMembersDataLoader = (input: {
  programId: ProgramId
  onViewProgramVersion: (programVersion: UserProgramVersion, userId: UserId, name: string) => void
}) => {
  loader: DataLoaderStateMachine<ProgramUsersTableData, { cursor: string | null | undefined }>
}

export const useInvalidateProgramMembersDataloader: () => () => Promise<void> = () => {
  const queryClient = useQueryClient()

  const invalidate = useCallback(async () => {
    await queryClient.invalidateQueries({
      queryKey: ['graphql', 'program-members-tabular'],
    })
  }, [queryClient])

  return invalidate
}

const useProgramMembersDataLoader: UseProgramMembersDataLoader = ({ programId, onViewProgramVersion }) => {
  const loaderConfig = React.useMemo(
    () => ({
      loader: programMembersDataLoader({
        programId,
        onViewProgramVersion,
      }),
      options: { queryKey: ['graphql', 'program-members-tabular'] },
    }),
    [programId, onViewProgramVersion]
  )

  return loaderConfig
}

export const ProgramDetailUsersSection: React.FC<{
  programId: ProgramId
  canChangeUserVersion: boolean
  canEnrollUsers: boolean
  onRemove: (userIds: UserId[], callback?: () => void) => void
}> = ({ programId, canChangeUserVersion, canEnrollUsers, onRemove }) => {
  const { t } = useTranslation()
  const tracking = useTracking()

  const [action, setAction] = useState<Action>({ modal: undefined })
  const [secondaryAction, setSecondaryAction] = useState<SecondaryAction>({ modal: undefined })

  const openSendReminderModal =
    action.modal === 'send-reminder-bulk' ||
    action.modal === 'send-reminder-row' ||
    action.modal === 'send-reminder-all-except'
  useOverrideIntercomLauncherVisibility(openSendReminderModal ? 'hidden' : 'default')

  const onViewProgramVersion = useCallback(
    (programVersion: UserProgramVersion, userId: UserId, name: string) =>
      setAction({ modal: 'show-program-version', programVersion, userId, name }),
    []
  )

  const onAssignUsersToLatestVersion = useCallback(
    (userIds: UserId[], names: string[]) =>
      setSecondaryAction({ modal: 'assign-program-version', userIds, names }),
    []
  )

  const onAssignAllUsersToLatestVersion = useCallback((excludedUsers: UserId[], selectedCount: number) => {
    setSecondaryAction({ modal: 'assign-program-version-all', excludedUsers, selectedCount })
  }, [])

  const onOpenReminderModalForBulk = useCallback(() => {
    setAction({ modal: 'send-reminder-bulk' })
    tracking.reminder.tableActionOpen(programId)
  }, [programId, tracking.reminder])

  const onOpenReminderModalForRow = useCallback(
    (userId: UserId) => {
      setAction({ modal: 'send-reminder-row', userId })
      tracking.reminder.rowActionOpen(programId)
    },
    [programId, tracking.reminder]
  )

  const onOpenReminderModalForAll = useCallback(
    (excludedUsers: UserId[], selectedCount: number) => {
      setAction({ modal: 'send-reminder-all-except', except: excludedUsers, selectedCount })
      tracking.reminder.tableActionOpen(programId)
    },
    [programId, tracking.reminder]
  )

  const onDeleteAllExceptUsers = useCallback((excludedUsers: UserId[], selectedCount: number) => {
    setSecondaryAction({ modal: 'remove-all-except-users', excludedUsers, selectedCount })
  }, [])

  const programUsersActions = React.useMemo(
    (): ProgramUsersActions => ({
      onViewDetails: ref => getGlobalRouter().navigate({ to: `/manage/users/${ref}` }),
      onDelete: (ref, callback) => onRemove([ref], callback),
      onBulkDeleteUsers: (refs, callback) => onRemove(refs, callback),
      onViewProgramVersion: (programVersion, userId, name) =>
        onViewProgramVersion(programVersion, userId, name),
      onAssignUsersToLatestVersion: (userIds, names) => onAssignUsersToLatestVersion(userIds, names),
      onAssignAllUsersToLatestVersion: (excludedUsers, selectedCount) =>
        onAssignAllUsersToLatestVersion(excludedUsers, selectedCount),
      onOpenReminderModalForBulk: () => onOpenReminderModalForBulk(),
      onOpenReminderModalForRow: ref => onOpenReminderModalForRow(ref),
      onOpenReminderModalForAll: (excludedUsers, selectedCount) =>
        onOpenReminderModalForAll(excludedUsers, selectedCount),
      onDeleteAllExceptUsers: (excludedUsers, selectedCount) =>
        onDeleteAllExceptUsers(excludedUsers, selectedCount),
    }),
    [
      onRemove,
      onViewProgramVersion,
      onAssignUsersToLatestVersion,
      onAssignAllUsersToLatestVersion,
      onOpenReminderModalForBulk,
      onOpenReminderModalForRow,
      onOpenReminderModalForAll,
      onDeleteAllExceptUsers,
    ]
  )

  const virtualColumns = React.useMemo(
    () => createProgramUsersVirtualColumns(programUsersActions, { canChangeUserVersion, canEnrollUsers }, t),
    [programUsersActions, canChangeUserVersion, canEnrollUsers, t]
  )

  const dataLoader = useProgramMembersDataLoader({ programId, onViewProgramVersion })

  const tableAPI = useTableAPI({
    dataLoader,
    virtualColumns,
    options: { limit: 30 },
  })

  return (
    <>
      <ProgramUsersTabular
        actions={programUsersActions}
        onViewProgramVersion={onViewProgramVersion}
        tableAPI={tableAPI}
        canEnrollUsers={canEnrollUsers}
      />
      <ViewUserProgramModal
        open={action.modal === 'show-program-version'}
        canChangeUserVersion={canChangeUserVersion}
        programId={programId}
        programVersion={action.modal === 'show-program-version' ? action.programVersion : undefined}
        userId={action.modal === 'show-program-version' ? action.userId : null}
        assignLabel={
          action.modal === 'show-program-version'
            ? t('manage.programs.user-table.assign-latest-version-named', { name: action.name })
            : ''
        }
        assign={() => {
          if (action.modal !== 'show-program-version') {
            return
          }

          onAssignUsersToLatestVersion([action.userId], [action.name])
          setAction({ modal: undefined })
        }}
        onClose={() => setAction({ modal: undefined })}
      />

      <ActionModal
        open={secondaryAction.modal === 'assign-program-version'}
        onClose={() => setSecondaryAction({ modal: undefined })}
        primaryAction={() => {
          if (secondaryAction.modal !== 'assign-program-version') {
            return
          }

          void (async () => {
            await assignUsersToLatestProgramVersion(programId, secondaryAction.userIds)
            await tableAPI.api.action.refresh()
            setSecondaryAction({ modal: undefined })
          })()
        }}
        primaryActionLabel='Assign'
        title={t('manage.programs.user-table.assign-latest-version')}
      >
        {secondaryAction.modal === 'assign-program-version'
          ? // Only use the names if we have exactly one user and the name is available.
            // otherwise fall back to generic message with number only.
            // This is because selected users in Tabular are not available in the data
            // when they have been filtered out (at the time of this writing).
            secondaryAction.userIds.length === 1 && secondaryAction.names.length === 1
            ? t('manage.programs.user-table.assign-latest-version-modal', { name: secondaryAction.names[0] })
            : t('manage.programs.user-table.assign-latest-version-modal-several', {
                number: secondaryAction.userIds.length,
              })
          : ''}
      </ActionModal>

      <ActionModal
        open={secondaryAction.modal === 'assign-program-version-all'}
        onClose={() => setSecondaryAction({ modal: undefined })}
        primaryAction={() => {
          if (secondaryAction.modal !== 'assign-program-version-all') {
            return
          }
          void (async () => {
            await assignAllUsersToLatestProgramVersion(programId, secondaryAction.excludedUsers)
            await tableAPI.api.action.refresh()
            setSecondaryAction({ modal: undefined })
          })()
        }}
        primaryActionLabel='Assign'
        title={t('manage.programs.user-table.assign-latest-version')}
      >
        {secondaryAction.modal === 'assign-program-version-all'
          ? t('manage.programs.user-table.assign-latest-version-modal-several', {
              number: secondaryAction.selectedCount,
            })
          : ''}
      </ActionModal>

      <ActionModal
        open={secondaryAction.modal === 'remove-all-except-users'}
        onClose={() => setSecondaryAction({ modal: undefined })}
        primaryAction={() => {
          if (secondaryAction.modal !== 'remove-all-except-users') {
            return
          }
          void (async () => {
            await removeAllExceptUsers(programId, secondaryAction.excludedUsers)
            await tableAPI.api.action.refresh()
            setSecondaryAction({ modal: undefined })
          })()
        }}
        primaryActionLabel={t('admin.remove')}
        title={t('admin.remove')}
      >
        {secondaryAction.modal === 'remove-all-except-users'
          ? t('admin.remove-all-modal', {
              count: secondaryAction.selectedCount,
            })
          : ''}
      </ActionModal>

      {openSendReminderModal && (
        <Provider store={tableAPI.store}>
          <SendReminderModal
            open={openSendReminderModal}
            closeModal={() => {
              setAction({ modal: undefined })
            }}
            programId={programId}
            action={action}
            programMembersTableAPI={tableAPI}
          />
        </Provider>
      )}
    </>
  )
}
