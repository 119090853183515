import { FC } from 'react'
import { ProgramOutline } from 'sierra-domain/api/manage'
import { assertNever, iife, isDefined } from 'sierra-domain/utils'
import { StepBadge } from 'sierra-ui/components'

type StepNumberProps = {
  outline: ProgramOutline
  index: number
}

export const StepNumber: FC<StepNumberProps> = ({ outline, index }) => {
  const step = outline.steps[index]

  if (!isDefined(step)) {
    return <span />
  }

  const number = outline.steps.reduce((num, step, j) => {
    if (step.type !== 'email' && j <= index) {
      return num + 1
    } else {
      return num
    }
  }, 0)

  return iife<React.ReactNode>(() => {
    switch (step.type) {
      case 'path':
      case 'course':
        return <StepBadge variant='default' size='small' num={number} />
      case 'email':
        return <StepBadge variant='email' size='small' />
      default:
        assertNever(step)
    }
  })
}
