import React, { Dispatch, SetStateAction, useCallback, useEffect, useState } from 'react'
import { CellProps, Column } from 'react-table'
import { SelectableHeader, SelectableRow } from 'sierra-client/components/table/select'
import { SortableHeader } from 'sierra-client/components/table/sortable-header'
import { TableMediumSearchTrigger } from 'sierra-client/components/table/table-medium'
import { useHasOrganizationPermission } from 'sierra-client/hooks/use-permissions'
import { useTranslation } from 'sierra-client/hooks/use-translation'
import { getGlobalRouter } from 'sierra-client/router'
import { ActionButton } from 'sierra-client/views/manage/components/common'
import {
  ManageTableSmall,
  useManageTableSmall,
} from 'sierra-client/views/manage/components/manage-table-small'
import { UserModalActionsProps } from 'sierra-client/views/manage/users/components/user-modal-actions'
import { useGetManageProgramURL } from 'sierra-client/views/manage/utils/use-manage-program-url'
import { ProgramGroupMembership, UserDetailResponse } from 'sierra-domain/api/manage'
import { Button, Text, View } from 'sierra-ui/primitives'
import { IconMenu } from 'sierra-ui/primitives/menu-dropdown'

type GroupCsv = {
  groupId: string
  groupName: string
  groupAdmin: string
  assignedAt: string
}

const mapGroupToCsv = (group: ProgramGroupMembership): GroupCsv => ({
  groupId: group.groupInfo.groupId,
  groupName: group.groupInfo.groupName ?? '',
  groupAdmin: group.groupInfo.adminName ?? '',
  assignedAt: new Date(group.assignedAt).toISOString(),
})

export interface ProgramGroupsTableProps {
  programMemberships: UserDetailResponse['programMemberships']
  setUserAction: Dispatch<SetStateAction<UserModalActionsProps['action']>>
}

export const ProgramGroupsTable: React.FC<ProgramGroupsTableProps> = ({
  programMemberships: programMemberships,
  setUserAction,
}) => {
  const { t } = useTranslation()
  const [focusedProgramId, setFocusedProgramId] = useState<string | undefined>(undefined)
  const [isSearchOpen, setIsSearchOpen] = useState<boolean>(false)
  const getManageProgramURL = useGetManageProgramURL()
  const canEditAssignments = useHasOrganizationPermission('EDIT_CONTENT_ASSIGNMENTS')

  const onRemove = useCallback(
    (ids: string[]) => {
      setUserAction({
        modal: 'unassign',
        messageType: 'group',
        targets: ids.map(id => ({ id, type: 'group' })),
      })
    },
    [setUserAction]
  )

  const goToGroupDetails = useCallback(
    (groupId: string) => getGlobalRouter().navigate({ to: getManageProgramURL(groupId) }),
    [getManageProgramURL]
  )

  const groupColumns: Column<ProgramGroupMembership>[] = React.useMemo(
    () => [
      {
        id: 'groupName',
        Header: p => {
          return (
            <>
              <SelectableHeader {...p} />
              <SortableHeader label={t('table.name')} smallLabel {...p} />{' '}
            </>
          )
        },
        accessor: row => row.groupInfo.groupName ?? 'N/A',
        Cell: (p: CellProps<ProgramGroupMembership>) => {
          return (
            <View gap='none'>
              <SelectableRow {...p} />
              <Text color='LEGACY_DEFAULT_TEXT_COLOR_REPLACE_ASAP' size='small' bold>
                {p.row.original.groupInfo.groupName ?? 'N/A'}
              </Text>
            </View>
          )
        },
        width: '60%',
      },
      {
        id: 'admin',
        Header: p => <SortableHeader label={t('table.admin')} smallLabel {...p} />,
        accessor: row => row.groupInfo.adminName,
        width: '30%',
      },
      {
        id: 'actions',
        disableSortBy: true,
        Header: <TableMediumSearchTrigger onClick={() => setIsSearchOpen(true)} />,
        Cell: ({ row }: CellProps<ProgramGroupMembership>) => {
          const [open, setOpen] = useState(false)

          useEffect(() => {
            if (open) {
              setFocusedProgramId(row.id)
            } else {
              setFocusedProgramId(undefined)
            }
          }, [open, row.id])

          return (
            <View justifyContent='flex-end' grow>
              <IconMenu
                iconId='overflow-menu--horizontal'
                variant='transparent'
                aria-label={t('dictionary.details')}
                onSelect={item => {
                  if ('onClick' in item) {
                    item.onClick?.()
                  }
                }}
                menuItems={[
                  {
                    type: 'label',
                    id: 'view-details',
                    label: t('manage.view-details'),
                    onClick: () =>
                      getGlobalRouter().navigate({
                        to: getManageProgramURL(row.original.groupInfo.groupId),
                      }),
                    icon: 'user--group',
                  },
                  {
                    type: 'label',
                    id: 'remove-assignment',
                    label: t('admin.remove'),
                    hidden: !canEditAssignments,
                    onClick: () => onRemove([row.original.groupInfo.groupId]),
                    icon: 'user--remove',
                    color: 'destructive/background',
                  },
                ]}
                isOpen={open}
                onOpenChange={setOpen}
              />
            </View>
          )
        },

        width: '10%',
      },
    ],
    [canEditAssignments, onRemove, t, getManageProgramURL]
  )

  const { tableInstance } = useManageTableSmall({
    tableOptions: { data: programMemberships, columns: groupColumns },
    getEntityId: s => s.groupInfo.groupId,
  })

  const selectedGroupIds = tableInstance.selectedFlatRows.map(r => r.original.groupInfo.groupId)

  return (
    <ManageTableSmall
      tableInstance={tableInstance}
      isLoading={false}
      focusedId={focusedProgramId}
      onViewDetails={goToGroupDetails}
      getEntityId={p => p.groupInfo.groupId}
      mapEntityToCsv={mapGroupToCsv}
      isSearchOpen={isSearchOpen}
      searchTrigger={setIsSearchOpen}
      bulkActions={
        canEditAssignments ? (
          <ActionButton color='redBright' onClick={() => onRemove(selectedGroupIds)}>
            {t('admin.remove')}
          </ActionButton>
        ) : undefined
      }
      footerButton={
        canEditAssignments ? (
          <Button onClick={() => setUserAction({ modal: 'assign-programs' })}>
            {t('manage.manage-programs')}
          </Button>
        ) : undefined
      }
      translations={{
        searchPlaceholder: t('manage.search.groups'),
        tableLoading: t('manage.groups.table-loading'),
        tableNoResults: t('manage.groups.no-results'),
        csvPrefix: t('admin.analytics.groups'),
      }}
    />
  )
}
