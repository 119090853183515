import { useMatches } from '@tanstack/react-router'
import type { MakeRouteMatches } from '@tanstack/react-router/dist/esm/Matches'
import { GlobalRouter, RouteId } from 'sierra-client/router'

export type RouteMatchItem = MakeRouteMatches<GlobalRouter>

export type UseActiveRouteMatchOpts<T> = {
  select?: (match: RouteMatchItem) => T
}

/**
 * Gets the active route object (the last matched route in the stack).
 */
export function useActiveRouteMatch<T = RouteMatchItem>(opts?: UseActiveRouteMatchOpts<T>): T {
  const select = opts?.select ?? ((match: T) => match)

  return useMatches({
    select: matches => {
      const activeRoute = matches.at(-1)

      if (!activeRoute) {
        throw new Error('No active route found, make sure you are using this hook inside the RouterProvider.')
      }

      return select(activeRoute)
    },
  })
}

/**
 * Gets the active route's ID (the last matched route in the stack).
 * @example const routeId = useActiveRouteId() // -> '/course/$courseId'
 */
export function useActiveRouteId(): RouteId {
  return useActiveRouteMatch({ select: match => match.routeId })
}
