import { DateTime } from 'luxon'
import { FC } from 'react'
import { InputPrimitive } from 'sierra-ui/primitives'
import { token } from 'sierra-ui/theming'
import styled, { css } from 'styled-components'

const StyledInputPrimitive = styled(InputPrimitive)<{ error: boolean }>`
  &::-webkit-calendar-picker-indicator {
    display: none;
  }

  ${p =>
    p.error &&
    css`
      outline-color: ${token('destructive/background')};

      &:hover {
        outline-color: ${token('destructive/background')};
      }

      &:focus,
      &:active,
      &:focus-visible {
        outline-color: ${token('destructive/background')};
      }
    `}
  width: max-content;
  text-align: center;
`

type TimepickerProps = {
  id: string
  value?: DateTime
  min?: string
  onChange: (value: DateTime) => void
  onBlur?: () => void
  error?: boolean
  disabled?: boolean
}

export const Timepicker: FC<TimepickerProps> = ({
  id,
  value = DateTime.local(),
  onChange,
  onBlur,
  error,
  disabled,
}) => {
  return (
    <StyledInputPrimitive
      disabled={disabled}
      id={id}
      type='time'
      value={value.toFormat('HH:mm')}
      onChange={event => {
        const [hour, minute, second = 0] = event.target.value.split(':').map(i => parseInt(i, 10))

        if (hour === undefined || minute === undefined) {
          return
        }

        onChange(value.set({ hour, minute, second }))
      }}
      onBlur={onBlur}
      error={error ?? false}
      required
    />
  )
}
