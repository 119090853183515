import _ from 'lodash'
import { isElementType, parentType } from 'sierra-client/views/v3-author/queries'
import { unwrapNonParagraphChildren } from 'sierra-client/views/v3-author/unwrap-non-paragraph-children'
import { Entity } from 'sierra-domain/entity'
import { nanoid12 } from 'sierra-domain/nanoid-extensions'
import { BulletCardItem, UnorderedList } from 'sierra-domain/v3-author'
import { createListItem, createParagraph, createUnorderedList } from 'sierra-domain/v3-author/create-blocks'
import { Editor, Element, Node, Transforms } from 'slate'

const debug = (...messages: unknown[]): void => console.debug('[withBulletCard]', ...messages)

// All nodes after the title must be one of the supported blocks
const supportedBlocks = [
  'paragraph',
  'bulleted-list',
  'numbered-list',
  'bullet-card-item',
  'heading',
  'check-list',
]

export const withBulletCard = (editor: Editor): Editor => {
  const { normalizeNode } = editor
  editor.normalizeNode = entry => {
    const [node, path] = entry

    if (isElementType('bullet-card-item', node)) {
      const parent = parentType(editor, path)
      if (parent !== 'bullet-card') {
        debug(`Unwrapping unexpected bullet-card-item at`, JSON.stringify(path))
        return Transforms.unwrapNodes(editor, { at: path, mode: 'all', voids: true })
      }

      const { didUnwrap } = unwrapNonParagraphChildren(editor, entry)
      if (didUnwrap) return
      else return normalizeNode(entry)
    }

    if (isElementType('bullet-card', node)) {
      const children = Array.from(Node.children(editor, path))

      if (parentType(editor, path) !== 'editor') {
        debug('Bullet-card must be at the root, unwrapping at', JSON.stringify(path))
        return Transforms.unwrapNodes(editor, { at: path })
      }

      // Convert adjacent bullet-card-items into unordered lists
      const adjacentBulletCardItems: Entity<BulletCardItem>[] = []
      for (const [child, childPath] of children) {
        const index = _.last(childPath) ?? 0
        const isAtLastChild = index === children.length - 1
        const isBulletCardItem = isElementType('bullet-card-item', child)

        if (isBulletCardItem) {
          const at = [..._.dropRight(childPath, 1), index - adjacentBulletCardItems.length]
          Transforms.removeNodes(editor, { at })
          adjacentBulletCardItems.push(child)
        }
        if (isAtLastChild || !isBulletCardItem) {
          if (adjacentBulletCardItems.length > 0) {
            const list: Entity<UnorderedList> = createUnorderedList({
              children: adjacentBulletCardItems.map(bulletCardItem =>
                createListItem({ id: bulletCardItem.id, children: bulletCardItem.children })
              ),
            })
            const offset =
              adjacentBulletCardItems.length === 1
                ? 0
                : !isBulletCardItem
                  ? adjacentBulletCardItems.length
                  : adjacentBulletCardItems.length - 1
            const at = [..._.dropRight(childPath, 1), index - offset]
            debug(`Converting bullet-card-items into list at`, JSON.stringify(childPath))

            return Transforms.insertNodes(editor, list, { at })
          }
        }
      }

      // Ensure that there is a least one heading in bullet-card
      if (children.length === 0) {
        debug('Adding heading in empty bullet-card at', JSON.stringify(path))
        return Transforms.insertNodes(
          editor,
          { type: 'heading', level: 3, id: nanoid12(), children: [{ text: '' }] },
          { at: [0, 0] }
        )
      }

      for (const [child, childPath] of children) {
        if (!(Element.isElement(child) && supportedBlocks.includes(child.type))) {
          debug(`Converting unexpected node to paragraph at`, JSON.stringify(childPath))
          unwrapNonParagraphChildren(editor, [child, childPath])
          return Transforms.wrapNodes(editor, createParagraph(), { at: childPath })
        }
      }
    }

    normalizeNode(entry)
  }

  return editor
}
