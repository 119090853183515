import { useTranslation } from 'sierra-client/hooks/use-translation'
import { TranslationKey } from 'sierra-client/hooks/use-translation/types'
import { FCC } from 'sierra-client/types'
import { CourseLevel } from 'sierra-domain/api/common'
import { LinkedInDifficultyLevel } from 'sierra-domain/api/partner'
import { TruncatedTextWithTooltip } from 'sierra-ui/components'
import { Text, View } from 'sierra-ui/primitives'
import { spacing } from 'sierra-ui/theming'
import styled from 'styled-components'

const FormFieldContainer = styled.div`
  display: grid;
  grid-gap: 0.5rem;
  grid-template-columns: repeat(1, 1fr);
  grid-template-rows: repeat(2, auto);
  margin-bottom: 2.5rem;

  .titles {
    display: flex;
    flex-flow: column nowrap;
    align-items: flex-start;
    margin-bottom: 1rem;
  }

  .content {
    display: flex;
    align-items: center;
    margin: 0 -${spacing['4']};

    & > * {
      margin: 0 ${spacing['4']};
    }
  }
`
type FormFieldProps = {
  title?: TranslationKey
  description?: TranslationKey
  horizontalLayout?: boolean
}

export const FormField: FCC<FormFieldProps> = ({ children, title, description }) => {
  const { t } = useTranslation()

  return (
    <FormFieldContainer>
      <div className='titles'>
        {title !== undefined && (
          <Text bold size='small'>
            {t(title)}
          </Text>
        )}
        {description !== undefined && (
          <Text size='small' color='foreground/muted'>
            {t(description)}
          </Text>
        )}
      </div>
      <div className='content'>{children}</div>
    </FormFieldContainer>
  )
}

export const HorizontalSettingsField: FCC<{
  title: string
  description: string
  children: React.ReactNode
}> = ({ title, description, children }) => {
  return (
    <View>
      <View direction='column' grow gap='none'>
        <Text bold>{title}</Text>
        <TruncatedTextWithTooltip color='foreground/muted'>{description}</TruncatedTextWithTooltip>
      </View>
      <View grow={false}>{children}</View>
    </View>
  )
}

export const getCourseLevelTranslationKey = (
  level?: CourseLevel | LinkedInDifficultyLevel
): TranslationKey | undefined => {
  switch (level) {
    case 'ADVANCED':
      return 'manage.linked-in.difficulty.advanced'
    case 'INTERMEDIATE':
      return 'manage.linked-in.difficulty.intermediate'
    case 'BEGINNER':
      return 'manage.linked-in.difficulty.beginner'
    case 'beginner':
      return 'assessment.beginner'
    case 'intermediate':
      return 'assessment.intermediate'
    case 'expert':
      return 'assessment.expert'
    default:
      return undefined
  }
}
