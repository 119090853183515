import React from 'react'
import { ActionModal } from 'sierra-client/components/common/modals/action-modal'
import { useTranslation } from 'sierra-client/hooks/use-translation'
import { useComposeUserInvitationConfig } from 'sierra-client/views/manage/components/user-attributes/flows/invite-users/hooks/use-compose-user-invitation-config'
import { MatchingUsers } from 'sierra-ui/missions/workflows/panels/components/matching-users'
import { Spacer } from 'sierra-ui/primitives'

export const MissingMandatoryAttributes: React.FC<{
  open: boolean
  onAbort: () => void
}> = ({ open, onAbort }) => {
  const { t } = useTranslation()
  const { incompleteUsers } = useComposeUserInvitationConfig()

  return (
    <ActionModal
      title={t('manage.users.user-attributes.alerts.missing-user-details.title')}
      open={open}
      onClose={onAbort}
      secondaryAction={onAbort}
      secondaryActionLabel={t('manage.users.user-attributes.alerts.missing-user-details.add-their-details')}
    >
      {t('manage.users.user-attributes.alerts.missing-user-details.description')}
      <Spacer />
      <MatchingUsers
        users={incompleteUsers}
        label={t('manage.users.user-attributes.alerts.missing-user-details.body', {
          count: incompleteUsers.length,
        })}
        padding='16'
      />
      <Spacer />
    </ActionModal>
  )
}
