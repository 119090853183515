import { t } from 'i18next'
import { FC } from 'react'
import { ActionModal } from 'sierra-client/components/common/modals/action-modal'
import { AccessibleOrganization } from 'sierra-domain/multi-tenancy'
import { isDefined } from 'sierra-domain/utils'
import { Text } from 'sierra-ui/primitives'

type Props = {
  organization: AccessibleOrganization | null
  onCancel: () => void
  onConfirm: () => void
}

export const UnpublishConfirmationModal: FC<Props> = ({ organization, onCancel, onConfirm }) => {
  return (
    <ActionModal
      open={isDefined(organization)}
      onClose={onCancel}
      deleteAction
      primaryAction={onConfirm}
      primaryActionLabel='Unpublish'
      title={
        organization?.isClusterParent === true
          ? t('multi-tenancy.content-distribution.unpublish-from-parent-warning.title')
          : t('multi-tenancy.content-distribution.unpublish-from-child-warning.title', {
              organization: organization?.name,
            })
      }
    >
      <Text>
        {organization?.isClusterParent === true
          ? t('multi-tenancy.content-distribution.unpublish-from-parent-warning.body')
          : t('multi-tenancy.content-distribution.unpublish-from-child-warning.body', {
              organization: organization?.name,
            })}
      </Text>
    </ActionModal>
  )
}
