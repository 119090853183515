import { useState } from 'react'
import {
  useAddTeamspaceMembersMutation,
  useRemoveTeamspaceMemberMutation,
} from 'sierra-client/api/hooks/use-teamspace'
import { RouterLink } from 'sierra-client/components/common/link'
import {
  NotificationContainer,
  Title,
  UserAvatar,
  useUsername,
} from 'sierra-client/features/activity-center/notifications/components'
import { NotificationTitle } from 'sierra-client/features/activity-center/notifications/components/notification-title'
import { CollaboratorRoleDropdown, TeamspaceIcon } from 'sierra-client/features/teamspace'
import { useTranslation } from 'sierra-client/hooks/use-translation'
import { HomeNotification } from 'sierra-domain/api/learn'
import { TeamspaceRole } from 'sierra-domain/api/teamspace'
import { Button, View } from 'sierra-ui/primitives'

export const TeamspaceNotification: React.FC<{
  notification: Extract<HomeNotification, { type: 'teamspace-access-requested' }>
}> = ({ notification }) => {
  const { teamspace, requesterUserId } = notification

  const { t } = useTranslation()
  const href = `/t/${teamspace.id}`
  const [role, setRole] = useState<TeamspaceRole>(teamspace.defaultRole)
  const [requesterRole, setRequesterRole] = useState<TeamspaceRole | undefined>(notification.requesterRole)
  const addMemberMutation = useAddTeamspaceMembersMutation()
  const removeMemberMutation = useRemoveTeamspaceMemberMutation()
  const username = useUsername(requesterUserId)

  return (
    <NotificationContainer
      notification={notification}
      icon={<UserAvatar userId={requesterUserId} />}
      title={
        <NotificationTitle
          titleKey='notification.user-requested-teamspace-access'
          values={{ user: username }}
        />
      }
      content={
        <>
          <RouterLink href={href}>
            <TeamspaceIcon
              displayName={teamspace.displayName}
              themeName={teamspace.iconTheme}
              inactive={false}
            />
          </RouterLink>

          <View direction='column' gap='4' grow>
            <RouterLink href={href}>
              <Title size='regular' bold>
                {teamspace.displayName}
              </Title>
            </RouterLink>
          </View>

          <View>
            {requesterRole === undefined ? (
              <>
                <CollaboratorRoleDropdown selectedRole={role} onSelect={setRole} />
                <Button
                  disabled={addMemberMutation.isPending}
                  onClick={() => {
                    addMemberMutation.mutate(
                      {
                        identities: [
                          {
                            identity: { type: 'user', id: requesterUserId },
                            role: role,
                          },
                        ],
                        teamspaceId: teamspace.id,
                      },
                      { onSuccess: () => setRequesterRole(role) }
                    )
                  }}
                >
                  {t('notification.grant-access')}
                </Button>
              </>
            ) : (
              <Button
                disabled={removeMemberMutation.isPending}
                onClick={() =>
                  removeMemberMutation.mutate(
                    { teamspaceId: teamspace.id, identity: { type: 'user', id: requesterUserId } },
                    {
                      onSuccess: () => setRequesterRole(undefined),
                    }
                  )
                }
              >
                {t('notification.revoke-access')}
              </Button>
            )}
          </View>
        </>
      }
    />
  )
}
