import { useQuery } from '@tanstack/react-query'
import { FC, useEffect } from 'react'
import { useDebouncedAction } from 'sierra-client/hooks/use-debounced-action'
import { useTranslation } from 'sierra-client/hooks/use-translation'
import { fileSupportsShowingResponses } from 'sierra-client/views/flexible-content/data-history-switch/data-history-switch'
import { useFileContext } from 'sierra-client/views/flexible-content/file-context'
import { useQuestionCardData } from 'sierra-client/views/v3-author/question-card/question-card-data-layer'
import { ScopedCreateContentId } from 'sierra-domain/collaboration/types'
import { Icon } from 'sierra-ui/components'
import { Text, View } from 'sierra-ui/primitives'
import { fadeIn } from 'sierra-ui/theming/keyframes'
import styled from 'styled-components'

const FadeInView = styled(View)`
  animation: ${fadeIn} 0.2s ease-in forwards;
  width: 100%;
`

const _QuestionResponses: FC<{
  questionId: string
}> = ({ questionId }) => {
  const { t } = useTranslation()
  const { questionResponsesDataLoader } = useFileContext()
  const { liveDataEventEmitter } = useQuestionCardData()

  const queryRes = useQuery({
    queryKey: questionResponsesDataLoader.getQueryKey(questionId),
    queryFn: () => questionResponsesDataLoader.load(questionId),
    refetchOnWindowFocus: true,
  })

  const debouncedRefetch = useDebouncedAction<void>(() => queryRes.refetch(), { wait: 1000 })

  useEffect(() => {
    const onData = (): void => {
      void debouncedRefetch()
    }
    liveDataEventEmitter?.on('data', onData)

    return () => {
      liveDataEventEmitter?.off('data', onData)
    }
  }, [debouncedRefetch, liveDataEventEmitter])

  if (queryRes.data === undefined) {
    return null
  }

  return (
    <FadeInView justifyContent='space-between'>
      <View gap='64'>
        <View direction='column' gap='4'>
          <Text size='small' bold color='foreground/muted'>
            {t('author.question-card.responses.learners')}
          </Text>
          <View gap='6'>
            <Icon iconId='user' size='size-16' color='foreground/secondary' />
            <Text bold size='regular' color='foreground/secondary'>
              {queryRes.data.totalUsers}
            </Text>
          </View>
        </View>
        <View direction='column' gap='4'>
          <Text size='small' bold color='foreground/muted'>
            {t('author.question-card.responses.correct-first-try')}
          </Text>
          <View gap='6'>
            <Icon iconId='user' size='size-16' />
            <Text bold size='regular' color='foreground/secondary'>
              {(queryRes.data.correctOnFirstTryRate * 100).toFixed(0)}%
            </Text>
          </View>
        </View>
      </View>
    </FadeInView>
  )
}

export const QuestionResponses: typeof _QuestionResponses = props => {
  const { file, scopedCreateContentId } = useFileContext()
  const isSupportedInCurrentFile = fileSupportsShowingResponses(
    file,
    ScopedCreateContentId.contentType(scopedCreateContentId)
  )

  if (!isSupportedInCurrentFile) {
    return null
  }

  return <_QuestionResponses {...props} />
}
