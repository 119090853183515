import type { SkillId } from 'sierra-client/api/graphql/branded-types'
import { DataViewer } from 'sierra-client/features/insights/display-widgets'
import { MeasureRef, MetricWidget } from 'sierra-domain/api/insights'

const getSubscriberCountForSkill = (skillId: SkillId): MetricWidget => {
  return {
    type: 'widget.metric',
    measures: [
      MeasureRef.parse({
        type: 'measure.native.user-skill.subscriptions',
      }),
    ],
    filter: {
      type: 'filter.and',
      filters: [
        {
          type: 'filter.filter',
          domain: {
            type: 'dimension.native.skill.skill',
          },
          operator: {
            type: 'operator.eq',
          },
          predicate: {
            type: 'predicate.or',
            values: [
              {
                type: 'value.skill-id',
                value: skillId,
              },
            ],
          },
        },
      ],
    },
  }
}

export const SkillSubscriberCountWidget: React.FC<{ skillId: SkillId }> = ({ skillId }) => {
  return <DataViewer widget={getSubscriberCountForSkill(skillId)} />
}
