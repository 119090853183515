import { createSelector } from '@reduxjs/toolkit'
import { LoggingState } from 'sierra-client/state/logging/types'
import { RootState } from 'sierra-client/state/types'

const selectState = (rootState: RootState): LoggingState => rootState.logging

export const selectLoggingPage = createSelector(selectState, logging => logging.page)

export const selectLogs = createSelector(selectState, state => state.logs)

export const selectLoggingEnabled = createSelector(selectState, logging => logging.enabled)
