import { createContext, FC, ReactNode, useCallback, useContext } from 'react'
import { useDeepEqualityMemo } from 'sierra-client/hooks/use-deep-equality-memo'
import { useTranslation } from 'sierra-client/hooks/use-translation'
import { TranslationKey } from 'sierra-client/hooks/use-translation/types'
import { Theme } from 'sierra-ui/theming/legacy-theme'
import { ThemeProvider } from 'styled-components'

type RenderingContextValue = {
  /*
   * Indicate that blocks should be rendered without adhering to a grid.
   */
  withGrid: boolean

  /*
   * Disable DnD on blocks in this context
   */
  preventDrag: boolean

  /*
   * Enable or disable block comments on blocks in this context
   */
  allowBlockComments: boolean

  /*
   * Control how block actions are horizontally aligned in this context
   */
  actionsPositioning?: 'outside' | 'inside'

  /*
   * Disable the paragraph menu
   */
  disableMenu: boolean

  /*
   * Disable the mini menu
   */
  disableMiniMenu: boolean

  /*
   * Set the placeholder for an empty block in this context.
   * You should access this value through the `usePlaceholder` hook.
   */
  placeholder?: TranslationKey | { type: 'untranslated'; value: string }

  /*
   * Indicate a preview mode to nested blocks.
   */
  preview?: boolean
}

const _RenderingContext = createContext<RenderingContextValue>({
  withGrid: true,
  preventDrag: false,
  allowBlockComments: true,
  disableMenu: false,
  disableMiniMenu: false,
})

/*
 * This context provider allows for partial overrides in the tree
 */
export const RenderingContext: FC<
  {
    children: ReactNode
  } & Partial<RenderingContextValue>
> = ({ children, ...overrides }) => {
  const currentValues = useContext(_RenderingContext)
  const value = useDeepEqualityMemo({ ...currentValues, ...overrides })

  const patchTheme = useCallback(
    (theme: Theme): Theme => ({ ...theme, editor: { ...theme.editor, withGrid: value.withGrid } }),
    [value.withGrid]
  )

  return (
    <_RenderingContext.Provider value={value}>
      <ThemeProvider theme={patchTheme}>{children}</ThemeProvider>
    </_RenderingContext.Provider>
  )
}

export const useRenderingContext = (): RenderingContextValue => {
  return useContext(_RenderingContext)
}

export function usePlaceholder(): string | undefined {
  const { placeholder } = useRenderingContext()
  const { t } = useTranslation()

  if (placeholder === undefined) return undefined
  if (typeof placeholder === 'string') return t(placeholder)
  else return placeholder.value
}
