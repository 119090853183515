import React from 'react'
import { Text, View } from 'sierra-ui/primitives'
import styled from 'styled-components'

const BubbleBase = styled(View).attrs({
  alignItems: 'center',
  justifyContent: 'center',
  radius: 'size-28',
  background: 'grey5',
  paddingLeft: '6',
  paddingRight: '6',
})``

export const Bubble: React.FC<{ children: React.ReactNode }> = ({ children }) => (
  <BubbleBase>
    <Text size='micro' color='grey25' bold={true}>
      {children}
    </Text>
  </BubbleBase>
)
