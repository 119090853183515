import * as Sentry from '@sentry/react'
import { config } from 'sierra-client/config/global-config'
import { UserSingleton } from 'sierra-client/core/user'
import { errorLogger } from 'sierra-client/error/error-logger'
import {
  AblyErrorObject,
  RequestError,
  SentryException,
  SlateErrorObject,
  normalizeAblyMessage,
} from 'sierra-domain/error'

type ErrorTrackingRuntimeConfig = {
  withReplays: boolean
}

export const initErrorTracking = ({ withReplays }: ErrorTrackingRuntimeConfig): void => {
  const domain = window.location.hostname
  const environment = domain.endsWith('.sana.ai')
    ? 'production'
    : domain.endsWith('.localhost.sana.dev')
      ? 'development'
      : domain.endsWith('.preview.sana.dev')
        ? 'preview'
        : domain.endsWith('.staging.sana.dev')
          ? 'staging'
          : 'production'

  if (Boolean(config.sentryBrowserDsn)) {
    Sentry.init({
      environment,
      dsn: config.sentryBrowserDsn,
      release: process.env.GITHUB_SHA,

      /**
       * Don't sample by default, always sample on error.
       * https://docs.sentry.io/platforms/javascript/guides/react/session-replay/#sampling
       */
      replaysSessionSampleRate: 0,
      replaysOnErrorSampleRate: withReplays ? 1 : 0,

      integrations: integrations => {
        integrations.push(Sentry.extraErrorDataIntegration())

        if (withReplays) {
          integrations.push(Sentry.replayIntegration())
        }

        integrations.push(
          Sentry.rewriteFramesIntegration({
            iteratee: frame => {
              if (typeof frame.filename === 'string' && typeof frame.abs_path === 'string') {
                // Decode square brackets [] in filenames to match files correctly to source map files.
                // Only needed for devtool: 'sourcemap', not for devtool: 'eval-source-map'.
                // This means this is only needed for production builds.
                frame.filename = decodeURI(frame.filename)
                frame.abs_path = decodeURI(frame.abs_path)
              }
              return frame
            },
          })
        )

        return integrations
      },
      normalizeDepth: 20,
      ignoreErrors: [
        'Network Error',
        'ResizeObserver',
        'Failed to fetch',
        'Failed to send logs to new relic',
        'Timeout',
        /**
         * CSS preloading is blocked for some users, likely by firewalls or browser extensions.
         * It doesn't break the actual CSS blocking, so we can ignore it.
         */
        /Unable to preload CSS for .+\.css/,
      ],
      beforeSend(event, hint) {
        const { originalException } = hint

        if (originalException instanceof TypeError && originalException.message === 'Illegal invocation') {
          return null
        } else if (
          originalException instanceof RequestError &&
          originalException.path.startsWith('/x-realtime') &&
          originalException.status >= 400
        ) {
          return null
        } else if (originalException instanceof RequestError) {
          event.fingerprint = [originalException.message]
        } else {
          if (AblyErrorObject.safeParse(originalException).success) {
            const { message, code } = AblyErrorObject.parse(originalException)
            const normalizedMessage = normalizeAblyMessage(message)

            event.exception = {
              values: [new SentryException('AblyError', `${normalizedMessage} (${code})`)],
            }
            event.fingerprint = [`ablyerror_${normalizedMessage}_${code}`]
          } else if (SlateErrorObject.safeParse(originalException).success) {
            event.exception = {
              values: [new SentryException('SlateError', `Cannot resolve a Slate node from DOM node`)],
            }
            event.fingerprint = [`slateerror_cannot-resolve-dom-node`]
          }
        }

        const user = UserSingleton.getInstance().getUser()

        if (user) {
          const email = user.email.endsWith('@sanalabs.com') ? user.email : undefined

          event.user = {
            email,
            id: user.uuid,
          }

          event.tags = event.tags ?? {}
          event.tags['host'] = window.location.hostname
          event.tags['organization'] = user.organizationUuid
        }

        errorLogger.setLastSentryEvent(event)

        return event
      },
    })
  }
}
