import { ErrorBoundaryFallbackComponent } from 'sierra-client/error/strategic-error-boundary'
import { useTranslation } from 'sierra-client/hooks/use-translation'
import { Icon } from 'sierra-ui/components'
import { Text, View } from 'sierra-ui/primitives'
import styled from 'styled-components'

const ErrorText = styled(View)`
  max-width: 300px;
`

export const DashboardErrorCard: React.FC = () => {
  const { t } = useTranslation()

  return (
    <View grow direction='column' justifyContent='center' alignItems='center'>
      <ErrorText>
        <View direction='column' alignItems='flex-start' gap='xsmall'>
          <Icon iconId='rating--1' size='size-24' color='foreground/primary' />
          <Text color='foreground/primary'>{t('manage.insights.dashboard-fetch-error')}</Text>
        </View>
      </ErrorText>
    </View>
  )
}

export const DashboardErrorBoundaryComponent: ErrorBoundaryFallbackComponent = () => {
  return <DashboardErrorCard />
}
