import _ from 'lodash'
import { useRef } from 'react'

export const useDeepEqualityMemo = <T>(value: T): T => {
  const ref = useRef(value)
  if (!_.isEqual(ref.current, value)) {
    ref.current = value
  }
  return ref.current
}
