import { FCC } from 'sierra-client/types'
import { LINKED_IN_HEX_COLOR } from 'sierra-client/views/manage/linked-in/linked-in-utils'
import { CourseKind } from 'sierra-domain/api/common'
import { color } from 'sierra-ui/color'
import { Icon, IconProps } from 'sierra-ui/components'
import { View } from 'sierra-ui/primitives'
import { palette } from 'sierra-ui/theming'
import styled from 'styled-components'

const MarkWrapper = styled.div`
  position: absolute;
  top: 0;
  left: 0;
  width: 1.125rem;
  height: 1.125rem;
  border-bottom-right-radius: 3px;
  border-right: 2px solid ${palette.primitives.white};
  border-bottom: 2px solid ${palette.primitives.white};
  background-color: ${palette.primitives.white};
`

type CourseMarkProps = { kind: CourseKind | undefined; iconProps?: IconProps }

const getMarkIconProps = (kind: CourseMarkProps['kind']): IconProps | undefined => {
  if (kind === 'linkedin')
    return { iconId: 'logo--linkedin', size: 'size-16', color: color(LINKED_IN_HEX_COLOR) }
  if (kind === 'scorm') return { iconId: 'document--import', size: 'size-16', color: 'grey35' }
  return undefined
}

const RelativeView = styled(View).attrs({ alignItems: 'flex-start', gap: 'none' })`
  position: relative;
`

export const CourseMark: FCC<CourseMarkProps> = ({ children, kind, iconProps }) => {
  const markIconProps = iconProps ?? getMarkIconProps(kind)
  return (
    <RelativeView alignItems='flex-start' gap='none'>
      {children}
      {markIconProps && (
        <MarkWrapper>
          <Icon {...markIconProps} />
        </MarkWrapper>
      )}
    </RelativeView>
  )
}
