import React from 'react'
import { assertElementType } from 'sierra-client/views/v3-author/queries'
import { SlateFC, SlateFCWrapper, SlateWrapperProps } from 'sierra-client/views/v3-author/slate'
import styled from 'styled-components'

const OptionWrapper = styled.div`
  flex: 1;
  padding: 16px;
`

export const QuestionCardMatchThePairsAlternativeOptionWrapper: SlateFCWrapper = React.forwardRef<
  HTMLDivElement,
  SlateWrapperProps
>(({ children, attributes }, ref) => {
  return (
    <OptionWrapper {...attributes} ref={ref}>
      {children}
    </OptionWrapper>
  )
})

const QuestionCardMatchThePairsAlternativeOptionLearner: SlateFC = props => {
  return <>{props.children}</>
}

const QuestionCardMatchThePairsAlternativeOptionCreate: SlateFC = props => {
  return <div>{props.children}</div>
}

export const QuestionCardMatchThePairsAlternativeOption: SlateFC = props => {
  assertElementType('question-card-match-the-pairs-alternative-option', props.element)

  if (props.mode === 'create') {
    return <QuestionCardMatchThePairsAlternativeOptionCreate {...props} />
  } else {
    return <QuestionCardMatchThePairsAlternativeOptionLearner {...props} />
  }
}
