import React, { useEffect, useRef, useState } from 'react'
import { insightsWidgetRenamedLogger } from 'sierra-client/features/insights/logger'
import { useDispatch } from 'sierra-client/state/hooks'
import { EditableText } from 'sierra-ui/components/editable-text/editable-text'
import { TruncatedTextCss, TruncatedTextProps } from 'sierra-ui/primitives/typography/text'
import { DesignToken } from 'sierra-ui/theming/tokens/types'
import styled, { css } from 'styled-components'

const TruncatedTextStyled = styled(EditableText)<{ contentEditable: boolean } & TruncatedTextProps>`
  overflow: hidden;
  ${p =>
    !p.contentEditable &&
    css`
      ${TruncatedTextCss}
    `}
`

export const EditableWidgetTitle: React.FC<{
  initialTitle: string
  isEditable: boolean
  setIsEditable: (newIsEditable: boolean) => void
  onChange: (newTitle: string) => void
  color?: DesignToken
  editLocation?: 'widgetBuilder' | 'widget'
}> = ({ initialTitle, isEditable, setIsEditable, onChange, color, editLocation }) => {
  const [editedTitle, setEditedTitle] = useState(initialTitle)
  const editTitleRef = useRef<HTMLParagraphElement>(null)
  const dispatch = useDispatch()

  useEffect(() => {
    setEditedTitle(initialTitle)
  }, [initialTitle])

  const handleEditTitle = (newTitle: string): void => {
    onChange(newTitle)
    setEditedTitle(newTitle)
    void dispatch(insightsWidgetRenamedLogger({ editLocation: editLocation }))
  }

  return (
    <TruncatedTextStyled
      placeholder=''
      size='regular'
      bold
      color={color ?? 'foreground/primary'}
      ref={editTitleRef}
      value={editedTitle}
      onRename={handleEditTitle}
      contentEditable={isEditable}
      setContentEditable={setIsEditable}
      withSingleClick
      lines={2}
    />
  )
}
