import { isDefined } from 'sierra-domain/utils'
import { Icon, IconId, IconProps, TruncatedText } from 'sierra-ui/components'
import { Spacer, Text, View } from 'sierra-ui/primitives'
import { token } from 'sierra-ui/theming'
import { SanaColor } from 'sierra-ui/types'
import styled, { css } from 'styled-components'

const RootView = styled(View).attrs({ gap: 'none' })`
  padding: 12px;
  border-radius: 20px;
  background: none;
  transition: background-color 0.1s cubic-bezier(0.25, 0.1, 0.25, 1);

  &:hover {
    background-color: ${token('surface/soft')};
    cursor: pointer;
  }
`

const IconContainer = styled.div<{ backgroundColor?: SanaColor; backgroundUrl?: string }>`
  display: flex;
  justify-content: center;
  align-items: center;
  height: 56px;
  width: 56px;
  border-radius: 14px;
  padding: 20px;

  &:hover {
    cursor: pointer;
  }

  ${p =>
    isDefined(p.backgroundColor) &&
    css`
      background-color: ${p.theme.color[p.backgroundColor]};
    `}
  ${p =>
    isDefined(p.backgroundUrl) &&
    css`
      background: url('${p.backgroundUrl}');
      background-size: cover;
    `}
`

const TextContainer = styled.div`
  width: 100%;
`

type TemplateCardProps = {
  label: string
  onClick: () => void
  description?: string
  descriptionIconId?: IconId

  imagePath?: string

  iconId?: IconId
  iconColor?: IconProps['color']
  iconBackgroundColor?: SanaColor
}

export const TemplateCard: React.FC<TemplateCardProps> = ({
  onClick,
  label,
  description,
  descriptionIconId,
  imagePath,
  iconId,
  iconColor = 'white',
  iconBackgroundColor,
}) => {
  const shouldCenterLabel = iconId === undefined && imagePath === undefined

  return (
    <RootView onClick={onClick} role='button'>
      {!shouldCenterLabel && (
        <IconContainer backgroundColor={iconBackgroundColor} backgroundUrl={imagePath}>
          {isDefined(iconId) && <Icon iconId={iconId} color={iconColor} />}
        </IconContainer>
      )}
      <Spacer size='xsmall' />
      <TextContainer>
        <TruncatedText size='regular' lines={1} align={shouldCenterLabel ? 'center' : undefined} bold>
          {label}
        </TruncatedText>
        <View gap='2'>
          {isDefined(descriptionIconId) && <Icon iconId={descriptionIconId} color='grey40' />}
          {isDefined(description) && (
            <Text size='small' color='foreground/secondary'>
              {description}
            </Text>
          )}
        </View>
      </TextContainer>
    </RootView>
  )
}
