import {
  useLiveSessionContext,
  useLiveSessionState,
} from 'sierra-client/components/liveV2/contexts/live-session-data'
import { isNotDefined } from 'sierra-domain/utils'

export const useSessionActiveState = (): { type: 'can-join' | 'ended' | 'not-started' } => {
  const liveSession = useLiveSessionContext()
  const liveSessionState = useLiveSessionState()

  if (liveSessionState === 'ended') {
    return { type: 'ended' }
  }

  if (isNotDefined(liveSession.data.startedAt)) {
    return {
      type: 'not-started',
    }
  }

  return { type: 'can-join' }
}
