import { useAncestorWithType } from 'sierra-client/views/v3-author/hooks'
import { isElementType } from 'sierra-client/views/v3-author/queries'
import { MultipleChoiceQuestionCardResponse } from 'sierra-domain/card/question-card'
import { Entity } from 'sierra-domain/entity'
import { assert, isNonEmptyArray } from 'sierra-domain/utils'
import {
  QuestionCard,
  QuestionCardMultipleChoiceAlternative as QuestionCardMultipleChoiceAlternativeType,
  QuestionVariations,
} from 'sierra-domain/v3-author'

export const isQuestionBody = isElementType([
  'question-card-pick-the-best-option-body',
  'question-card-match-the-pairs-body',
  'question-card-select-all-that-apply-body',
  'question-card-free-text-body',
])

export function pickQuestionCardsFromVariation(
  element: Entity<QuestionVariations>
): [Entity<QuestionCard>, ...Entity<QuestionCard>[]] {
  const questionCards = element.children.filter(isElementType('question-card'))

  assert(isNonEmptyArray(questionCards), 'Question variations must contain at least one question')

  return questionCards
}

export function pickQuestionCardFromVariation(element: Entity<QuestionVariations>): Entity<QuestionCard> {
  return pickQuestionCardsFromVariation(element)[0]
}

export const useIsInQuestionVariation = ({ nodeId }: { nodeId: string }): boolean => {
  const questionVariation = useAncestorWithType({ nodeId, type: 'question-variations' })
  const isOnlyQuestionInVariationsGroup =
    questionVariation?.children.filter(isElementType('question-card')).length === 1

  if (questionVariation === undefined) return false
  return !isOnlyQuestionInVariationsGroup
}

export type AlternativeStatus =
  | 'create'
  | 'disabled'
  | 'unselected'
  | 'selected'
  | 'correct'
  | 'incorrect'
  | 'missed'

export const getAlternativeStatus = ({
  response,
  solution,
  id,
  submitted,
}: {
  response: MultipleChoiceQuestionCardResponse
  solution: Entity<QuestionCardMultipleChoiceAlternativeType>
  id: string
  submitted: boolean
}): AlternativeStatus => {
  const responseAlternative = response.alternatives[id]

  if (responseAlternative === undefined) {
    return 'unselected'
  }

  if (submitted) {
    if (responseAlternative.status === 'correct' && solution.status === 'correct') {
      return 'correct'
    }

    if (responseAlternative.status === 'correct' && solution.status === 'incorrect') {
      return 'incorrect'
    }

    if (responseAlternative.status === 'incorrect' && solution.status === 'correct') {
      return 'missed'
    }
  } else {
    return responseAlternative.status === 'correct' ? 'selected' : 'unselected'
  }

  return 'unselected'
}
