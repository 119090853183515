import { motion } from 'framer-motion'
import React from 'react'
import { useTranslation } from 'sierra-client/hooks/use-translation'
import { View } from 'sierra-ui/primitives'
import { TextCss } from 'sierra-ui/primitives/typography/text'
import { spacing } from 'sierra-ui/theming'
import styled from 'styled-components'

const Wrapper = styled(View)`
  width: 100%;
  position: relative;
`

const BackgroundView = styled(View)<{ $left: boolean }>`
  width: 100%;
  height: 2.5rem;
  padding: ${spacing[4]};
  border-radius: ${p => p.theme.borderRadius['size-10']};
  justify-content: ${p => (p.$left ? 'flex-start' : 'flex-end')};
`

const SelectionView = styled(View)`
  width: 50%;
  height: 100%;
  padding: ${spacing[4]} ${spacing[8]};
  border-radius: ${p => p.theme.borderRadius['size-8']};
  justify-content: center;
  outline: 1px solid ${p => p.theme.tokens['border/strong']};
  box-shadow: 0px 2px 4px 0px rgba(0, 0, 0, 0.08);
`

const TextWrapper = styled(View)`
  position: absolute;
  width: 100%;
  justify-content: space-around;
`

const TextView = styled(View)`
  width: 50%;
  justify-content: center;
`

const StyledMotionP = styled(motion.p)`
  ${TextCss}
`

const AnimatedText: React.FC<{ active: boolean; children: React.ReactNode }> = ({ active, children }) => {
  return (
    <TextView>
      <StyledMotionP
        size='small'
        bold
        color='foreground/primary'
        animate={{ opacity: active ? 1 : 0.4 }}
        transition={{ duration: 0.4, type: 'tween' }}
      >
        {children}
      </StyledMotionP>
    </TextView>
  )
}

export const WidgetTableTypeSelector: React.FC<{
  value: 'standard' | 'list'
  onChange: (value: 'standard' | 'list') => void
}> = ({ value, onChange }) => {
  const { t } = useTranslation()

  return (
    <Wrapper
      onClick={() => (value === 'standard' ? onChange('list') : onChange('standard'))}
      cursor='pointer'
    >
      <BackgroundView background={'surface/strong'} $left={value === 'standard'}>
        <SelectionView
          background={'surface/default'}
          animated
          layout
          transition={{ type: 'spring', bounce: 0.2 }}
        />
      </BackgroundView>
      <TextWrapper animated layout>
        <AnimatedText active={value === 'standard'}>
          {t('manage.insights.widgetbuilder.query.drill-across')}
        </AnimatedText>
        <AnimatedText active={value === 'list'}>
          {t('manage.insights.widgetbuilder.query.table')}
        </AnimatedText>
      </TextWrapper>
    </Wrapper>
  )
}
