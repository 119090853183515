import { createImageThumbhashBase64 } from 'sierra-client/utils/image-thumbhash'
import { FileImage } from 'sierra-domain/content/v2/file-image'
import { ImageUnion } from 'sierra-domain/content/v2/image-union'
import { MediaLibraryImage } from 'sierra-domain/content/v2/media-library-image'
import { UnsplashImage } from 'sierra-domain/content/v2/unsplash-image'

const addThumbhashToImage = async (
  image: FileImage | UnsplashImage | MediaLibraryImage,
  imgSrc: string
): Promise<ImageUnion> => {
  if (image.thumbHashBase64 !== undefined) return image

  const imageElement = new Image()
  imageElement.crossOrigin = 'anonymous'
  imageElement.src = imgSrc
  await imageElement.decode()

  return { ...image, thumbHashBase64: createImageThumbhashBase64(imageElement) }
}

export const ensureImageUnionHasThumbhash = async (
  image: ImageUnion,
  imgSrc: string
): Promise<ImageUnion> => {
  switch (image.type) {
    case 'file':
    case 'unsplash':
    case 'media-library-image':
      return addThumbhashToImage(image, imgSrc)
    case 'url':
      return image
  }
}
