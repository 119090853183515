import React from 'react'
import { Icon } from 'sierra-ui/components'
import { MUIUsePaginationResult } from 'sierra-ui/mui'
import { defaultEasing } from 'sierra-ui/theming/animations'
import styled from 'styled-components'

const PaginationContainer = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  margin: 0 auto;
  color: ${p => p.theme.color.grey25};
`

const PaginationItem = styled.a`
  & {
    display: inline-flex;
    justify-content: center;
    align-items: center;

    min-width: 45px;
    min-height: 45px;

    cursor: pointer;
    ${defaultEasing}

    &:hover,
    &.currentItem {
      color: ${p => p.theme.color.grey90};
    }

    &.currentItem {
      pointer-events: none;
    }
  }
`

interface SanaPaginationItemProps {
  item: MUIUsePaginationResult['items'][0]
}

const SanaPaginationItem: React.FC<SanaPaginationItemProps> = ({ item }) => {
  const { page, type, selected, onClick, disabled } = item

  if (type === 'start-ellipsis' || type === 'end-ellipsis') {
    const ellipsis = '…'
    return <PaginationItem>{ellipsis}</PaginationItem>
  }

  if (type === 'next' || type === 'previous') {
    return (
      <PaginationItem onClick={disabled ? undefined : onClick}>
        <Icon iconId={type === 'next' ? 'arrow--right' : 'arrow--left'} size='size-24' />
      </PaginationItem>
    )
  }

  return (
    <PaginationItem onClick={disabled ? undefined : onClick} className={selected ? 'currentItem' : undefined}>
      {page}
    </PaginationItem>
  )
}

interface SanaPaginationProps {
  paginationItems: MUIUsePaginationResult['items']
}

export const SanaPagination: React.FC<SanaPaginationProps> = ({ paginationItems }) => {
  // Hide pagination if there is only one item
  if (paginationItems.length <= 1) return null

  return (
    <PaginationContainer>
      {paginationItems.map((item, index) => (
        <SanaPaginationItem key={index} item={item} />
      ))}
    </PaginationContainer>
  )
}
