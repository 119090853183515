import { useBlocker } from '@tanstack/react-router'
import { AnimatePresence, motion } from 'framer-motion'
import { useAtom, useAtomValue, useSetAtom } from 'jotai'
import React, { useEffect } from 'react'
import { ActionModal } from 'sierra-client/components/common/modals/action-modal'
import { useLiveSessionContext } from 'sierra-client/components/liveV2/contexts/live-session-data'
import { FileExplorer as FacilitatorFileExplorer } from 'sierra-client/components/liveV2/facilitator/file-explorer'
import { scrollToActiveFileInSidebar } from 'sierra-client/components/liveV2/hooks/use-go-to-node'
import { useSelectCurrentCard } from 'sierra-client/components/liveV2/hooks/use-select-current-card'
import { LiveSessionTitle } from 'sierra-client/components/liveV2/live-session-title'
import { FileExplorer as ParticipantFileExplorer } from 'sierra-client/components/liveV2/participant/file-explorer'
import { HeaderItemTitle, collapsableSidebarStateAtom } from 'sierra-client/features/collapsable-sidebar'
import {
  GlobalNestedSidebar,
  GlobalNestedSidebarComponent,
  GlobalSidebarLiveSessionContentReferenceAtom,
  GlobalSidebarOpenAtom,
} from 'sierra-client/features/global-sidebar'
import { useTranslation } from 'sierra-client/hooks/use-translation'
import { useSelector } from 'sierra-client/state/hooks'
import { selectIsFacilitator } from 'sierra-client/state/live-session/selectors'
import {
  SpeakerNoteContext,
  useSpeakerNoteContext,
} from 'sierra-client/views/flexible-content/editor/content-sidebar/speaker-notes/context'
import { speakerNotesSidebarWidthAtom } from 'sierra-client/views/liveV2/speaker-notes-sidebar-width-atom'
import { IconButton, Text, View } from 'sierra-ui/primitives'
import styled from 'styled-components'

const SpeakerNotesIconButton = styled(IconButton).attrs({ variant: 'transparent', size: 'small' })``

const SpeakerNotesButtons: React.FC = () => {
  const { t } = useTranslation()
  const { currentTab, setTab } = useSpeakerNoteContext()
  const currentCard = useSelectCurrentCard()
  const sidebarStatus = useAtomValue(collapsableSidebarStateAtom)
  const oldGlobalSidebarOpen = useAtomValue(GlobalSidebarOpenAtom)

  const [speakerNotesSidebarWidth, setSpeakerNotesSidebarWidth] = useAtom(speakerNotesSidebarWidthAtom)

  useEffect(() => {
    if (sidebarStatus === 'collapsed' || oldGlobalSidebarOpen) {
      setTab('Outline')
    }
  }, [oldGlobalSidebarOpen, setTab, sidebarStatus])

  return (
    <>
      <HeaderItemTitle animate={sidebarStatus}>
        <Text bold size='micro' color='foreground/muted'>
          {currentTab === 'Notes' ? t('create.speaker-notes.hide') : t('sidebar.open-notes')}
        </Text>
      </HeaderItemTitle>
      <AnimatePresence>
        {currentTab === 'Notes' && (
          <>
            {speakerNotesSidebarWidth !== 'none' && (
              <motion.div initial={{ opacity: 0 }} animate={{ opacity: 1 }} exit={{ opacity: 0 }}>
                <IconButton
                  iconId='minus--circle'
                  size='small'
                  variant='transparent'
                  tooltip={t('dictionary.smaller')}
                  onClick={() => {
                    switch (speakerNotesSidebarWidth) {
                      case 'large':
                        setSpeakerNotesSidebarWidth('medium')
                        break
                      case 'medium':
                        setSpeakerNotesSidebarWidth('small')
                        break
                      case 'small':
                        setSpeakerNotesSidebarWidth('none')
                        break
                    }
                  }}
                />
              </motion.div>
            )}
            {speakerNotesSidebarWidth !== 'large' && (
              <motion.div initial={{ opacity: 0 }} animate={{ opacity: 1 }} exit={{ opacity: 0 }}>
                <IconButton
                  iconId='plus--circle'
                  size='small'
                  variant='transparent'
                  tooltip={t('dictionary.larger')}
                  onClick={() => {
                    switch (speakerNotesSidebarWidth) {
                      case 'none':
                        setSpeakerNotesSidebarWidth('small')
                        break
                      case 'small':
                        setSpeakerNotesSidebarWidth('medium')
                        break
                      case 'medium':
                        setSpeakerNotesSidebarWidth('large')
                        break
                    }
                  }}
                />
              </motion.div>
            )}
          </>
        )}
      </AnimatePresence>
      <SpeakerNotesIconButton
        tooltip={currentTab === 'Notes' ? t('sidebar.close-notes') : t('sidebar.open-notes')}
        iconId={currentTab === 'Notes' ? 'speaker--notes--off' : 'speaker--notes'}
        onClick={() => {
          setTab(currentTab === 'Notes' ? 'Outline' : 'Notes')

          if (currentCard !== undefined) {
            // We need a timeout for the layout to update before scrolling
            setTimeout(() => {
              scrollToActiveFileInSidebar(currentCard.id)
            }, 10)
          }

          setSpeakerNotesSidebarWidth('none')
        }}
      />
    </>
  )
}

const FacilitatorNestedSidebar: GlobalNestedSidebarComponent = () => (
  <SpeakerNoteContext>
    <View paddingLeft='6' gap='none'>
      <SpeakerNotesButtons />
    </View>

    <FacilitatorFileExplorer />
  </SpeakerNoteContext>
)

const ParticipantNestedSidebar: GlobalNestedSidebarComponent = () => {
  return (
    <div>
      <View paddingLeft='8' gap='8'>
        <LiveSessionTitle />
      </View>
      <ParticipantFileExplorer />
    </div>
  )
}

const AccidentalNavigationBlocker = (): JSX.Element => {
  const { t } = useTranslation()
  const { status, proceed, reset } = useBlocker()

  return (
    <ActionModal
      open={status === 'blocked'}
      primaryAction={proceed}
      onClose={reason => {
        if (reason !== 'primaryAction') {
          reset()
        }
      }}
    >
      {t('live.leave-confirmation-question')}
    </ActionModal>
  )
}

export const SetGlobalSidebarContentReference = (): JSX.Element | null => {
  const setContentReference = useSetAtom(GlobalSidebarLiveSessionContentReferenceAtom)
  const { flexibleContentId, endedAt } = useLiveSessionContext().data
  const currentCard = useSelectCurrentCard()

  useEffect(() => {
    setContentReference({
      contentId: flexibleContentId,
      fileId: endedAt === undefined ? currentCard?.id : undefined,
    })

    return () => {
      setContentReference(undefined)
    }
  }, [currentCard?.id, endedAt, flexibleContentId, setContentReference])

  return null
}

export const LiveSessionGlobalSidebar: React.FC = () => {
  const isFacilitator = useSelector(selectIsFacilitator)

  return (
    <>
      <AccidentalNavigationBlocker />
      <GlobalNestedSidebar
        renderSidebar={() => {
          const Renderer = isFacilitator ? FacilitatorNestedSidebar : ParticipantNestedSidebar
          return <Renderer />
        }}
      />
    </>
  )
}
