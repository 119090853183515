import { useTranslation } from 'sierra-client/hooks/use-translation'
import { ToolbarItem, ToolbarLabel } from 'sierra-client/views/v3-author/block-toolbar'
import { Entity } from 'sierra-domain/entity'
import { Checkbox, View } from 'sierra-ui/primitives'
import { DarkTokenProvider } from 'sierra-ui/theming'

export const ToolbarMandatoryToggle: React.FC<Entity<{ isMandatory?: boolean; onClick: () => void }>> = ({
  isMandatory,
  onClick,
}) => {
  const { t } = useTranslation()
  return (
    <DarkTokenProvider>
      <ToolbarItem>
        <ToolbarLabel>
          {t('author.slate.mandatory')}
          <View paddingLeft='4'>
            <Checkbox checked={isMandatory ?? false} onCheckedChange={() => onClick()} radio={true} />
          </View>
        </ToolbarLabel>
      </ToolbarItem>
    </DarkTokenProvider>
  )
}
