import { AnimatePresence, motion } from 'framer-motion'
import React, { FC, PropsWithChildren, ReactElement } from 'react'
import { skillColorToGradientBackground } from 'sierra-client/features/skills/colors'
import { SkillColor } from 'sierra-client/features/skills/colors/index'
import { RichBadge } from 'sierra-client/features/skills/components/badges'
import { useIsDebugMode } from 'sierra-client/hooks/use-is-debug-mode'
import { usePrevious } from 'sierra-client/hooks/use-previous'
import { useTranslation } from 'sierra-client/hooks/use-translation'
import { Icon } from 'sierra-ui/components'
import { Button, HeadingPrimitive, Spacer, Text, View } from 'sierra-ui/primitives'
import { palette, token } from 'sierra-ui/theming'
import styled from 'styled-components'
import { CreateSkillAction, ImportSkillAction } from './actions/skills'
import { SkillSettingAction } from './skill-settings/skill-settings-panel'

const Background = styled(View).attrs({
  direction: 'column',
  grow: true,
  padding: '16',
  radius: 'large',
  alignItems: 'stretch',
})`
  background-color: ${token('surface/soft')};
`

const ContentArea = styled.div`
  position: relative;
  height: 100%;
  overflow: hidden;
`

const UpperSection = styled(View).attrs({
  alignItems: 'center',
  justifyContent: 'center',
})<{ direction: 'column' | 'row' }>`
  width: 100%;
  max-width: 700px;
  flex: 2;
  position: relative;
  flex-direction: ${p => p.direction};
  margin: 0 auto;
`

const BottomSection = styled(View).attrs({
  direction: 'column',
  alignItems: 'center',
  justifyContent: 'center',
})`
  width: 100%;
  max-width: 500px;
  flex: 1;
  margin: 0 auto;
`

const StyledText = styled(Text).attrs({ size: 'regular', align: 'center' })``

const ProgressContainer = styled(View)`
  height: 32px;
  justify-content: center;
`

const Dot = styled.button<{ $selected: boolean }>`
  background-color: ${p => (p.$selected ? token('foreground/primary') : token('foreground/muted'))};
  width: ${p => (p.$selected ? '6px' : '4px')};
  aspect-ratio: 1 / 1;
  border-radius: 99px;
  padding: 0;
  cursor: pointer;
  transition: ease-in-out 0.2s;
`

type ProgressBarProps = {
  noOfSteps: number
  currentStep: number
  gotoIndex: (index: number) => void
}

const ProgressBar: React.FC<ProgressBarProps> = ({ noOfSteps, currentStep, gotoIndex }) => {
  return (
    <ProgressContainer>
      {Array(noOfSteps)
        .fill(null)
        .map((_, index) => (
          <Dot key={index} $selected={index === currentStep} onClick={() => gotoIndex(index)} />
        ))}
    </ProgressContainer>
  )
}

const BadgesContainer = styled(motion.div)`
  width: 100%;
  height: 100%;
  max-width: 500px;
  position: relative;
  overflow: hidden;
`
const BadgeAnimator = styled(motion.div)`
  width: 40%;
  bottom: 35%;
  position: absolute;

  &:nth-child(1) {
    left: 0;
  }
  &:nth-child(2) {
    margin-inline: auto;
    left: 0;
    right: 0;
    z-index: 1;
  }
  &:nth-child(3) {
    right: 0;
  }
`
const BadgeFade = styled(motion.div)`
  z-index: 1;
  position: absolute;
  bottom: 0;
  width: 100%;
  height: 100%;
  background-image: linear-gradient(to top, ${palette.grey[2]} 60%, transparent);
  transform-origin: bottom;
`

const IntroducingSkills: React.FC<{ goToNext: () => void }> = ({ goToNext }) => {
  const { t } = useTranslation()
  return (
    <AnimatePresence initial>
      <UpperSection direction='column'>
        <BadgesContainer initial={{ opacity: 0 }} animate={{ opacity: 1 }} transition={{ duration: 1 }}>
          <BadgeAnimator
            initial={{ x: 25, y: 40, scale: 0.9 }}
            animate={{ x: 0, y: 0, scale: 1 }}
            transition={{ duration: 0.6, delay: 0.0 }}
          >
            <RichBadge
              theme='silver'
              title='UI Design'
              subtitle='Intermediate'
              iconId='skill--paintpalette'
            />
          </BadgeAnimator>
          <BadgeAnimator
            initial={{ y: 40, scale: 0.9 }}
            animate={{ y: 0, scale: 1.1 }}
            transition={{ duration: 0.6, delay: 0.0 }}
          >
            <RichBadge theme='gold' title='Building Culture' subtitle='Advanced' iconId='skill--trophy' />
          </BadgeAnimator>
          <BadgeAnimator
            initial={{ x: -25, y: 40, scale: 0.9 }}
            animate={{ x: 0, y: 0, scale: 1 }}
            transition={{ duration: 0.6, delay: 0.0 }}
          >
            <RichBadge theme='titanium' title='Project Management' subtitle='Basic' iconId='skill--gauge' />
          </BadgeAnimator>

          <BadgeFade initial={{ y: '20%' }} animate={{ y: '70%' }} transition={{ duration: 0.8 }} />
        </BadgesContainer>
      </UpperSection>
      <BottomSection>
        <HeadingPrimitive size='h5' bold align='center'>
          {t('manage.skills.onboarding.introduction.title')}
        </HeadingPrimitive>
        <StyledText>{t('manage.skills.onboarding.introduction.description')}</StyledText>
        <Spacer size='xsmall' />
        <Button onClick={goToNext}>{t('dictionary.continue')}</Button>
      </BottomSection>
    </AnimatePresence>
  )
}

const LevelContainer = styled(View).attrs({ radius: 'size-12', padding: '12 24', gap: '12' })`
  border: 1px solid ${token('border/strong')};
  filter: drop-shadow(0px 2px 4px rgba(0, 0, 0, 0.08));
  background: ${token('surface/default')};
  width: 100%;
`

const LevelColor = styled.div<{ color: SkillColor }>`
  width: 16px;
  height: 16px;
  border-radius: 4px;
  background: ${p => skillColorToGradientBackground[p.color]};
`

const Level: React.FC<{ theme: SkillColor; title: string; subtitle: string }> = ({
  theme,
  title,
  subtitle,
}) => {
  return (
    <LevelContainer>
      <LevelColor color={theme} />
      <View direction='column' gap='none'>
        <Text bold>{title}</Text>
        <Text color={'foreground/muted'}>{subtitle}</Text>
      </View>
    </LevelContainer>
  )
}

const Levels: React.FC = () => {
  return (
    <UpperSection direction='column'>
      <Level theme='titanium' title='Beginner' subtitle='Level 1' />
      <Level theme='gold' title='Intermediate' subtitle='Level 2' />
      <Level theme='silver' title='Expert' subtitle='Level 3' />
    </UpperSection>
  )
}

const SetUpStructure: React.FC<{ goToNext: () => void }> = ({ goToNext }) => {
  const { t } = useTranslation()

  return (
    <>
      <Levels />
      <BottomSection>
        <HeadingPrimitive size='h5' bold align='center'>
          {t('manage.skills.onboarding.set-structure.title')}
        </HeadingPrimitive>
        <StyledText>{t('manage.skills.onboarding.set-structure.description')}</StyledText>
        <Spacer size='small' />
        <SkillSettingAction buttonText={t('manage.skills.onboarding.set-structure.action')} />
        <Button variant='transparent' onClick={goToNext}>
          {t('dictionary.continue')}
        </Button>
      </BottomSection>
    </>
  )
}

const CreateSkillIconContainer = styled(View).attrs({
  radius: 'large',
  alignItems: 'center',
  justifyContent: 'center',
})`
  width: 100%;
  height: 50%;
  min-height: 80px;
  border: 2px dashed ${token('border/strong')};
`

const CreateSkillIcon = styled(Icon).attrs({ iconId: 'add--skill', color: 'foreground/muted' })`
  & svg {
    width: 40px !important;
    height: 40px !important;
  }
`

const CreateFirstSkill: React.FC = () => {
  const { t } = useTranslation()

  return (
    <>
      <UpperSection direction='column'>
        <CreateSkillIconContainer>
          <CreateSkillIcon />
        </CreateSkillIconContainer>
      </UpperSection>
      <BottomSection gap='none'>
        <HeadingPrimitive size='h5' bold align='center'>
          {t('manage.skills.onboarding.create-skill.title')}
        </HeadingPrimitive>
        <Spacer size='xxsmall' />
        <StyledText>{t('manage.skills.onboarding.create-skill.description')}</StyledText>
        <Spacer size='medium' />
        <CreateSkillAction />
        <Spacer size='xxsmall' />
        <ImportSkillAction buttonVariant='transparent' />
      </BottomSection>
    </>
  )
}

const SlideContainer = styled(motion.div)`
  display: flex;
  flex-direction: column;
  position: absolute;
  inset: 0;
`

type Direction = 'forward' | 'backward'
const distance = 20
const slideVariants = {
  initial: (d: Direction) => ({
    opacity: 0,
    x: d === 'forward' ? `${distance}%` : `-${distance}%`,
  }),
  visible: {
    opacity: 1,
    x: 0,
  },
  exit: (d: Direction) => ({
    opacity: 0,
    x: d === 'forward' ? `-${distance}%` : `${distance}%`,
  }),
}

const Slide: FC<PropsWithChildren<{ direction: Direction }>> = ({ children, direction }) => {
  return (
    <SlideContainer
      variants={slideVariants}
      custom={direction}
      initial='initial'
      animate='visible'
      exit='exit'
      transition={{ duration: 0.2, ease: [0.25, 0, 0.25, 1] }}
    >
      {children}
    </SlideContainer>
  )
}

const PageSelector: React.FC<{
  pageIndex: number
  goToNext: () => void
}> = ({ pageIndex, goToNext }) => {
  let page: ReactElement | null = null
  const previousPage = usePrevious(pageIndex)
  const direction: Direction = (previousPage ?? -1) < pageIndex ? 'forward' : 'backward'

  switch (pageIndex) {
    case 0:
      page = (
        <Slide key='intro' direction={direction}>
          <IntroducingSkills goToNext={goToNext} />
        </Slide>
      )
      break
    case 1:
      page = (
        <Slide key='setup' direction={direction}>
          <SetUpStructure goToNext={goToNext} />
        </Slide>
      )
      break
    case 2:
      page = (
        <Slide key='create' direction={direction}>
          <CreateFirstSkill />
        </Slide>
      )
      break
    default:
      page = (
        <Slide key='intro' direction={direction}>
          <IntroducingSkills goToNext={goToNext} />
        </Slide>
      )
  }

  return (
    <AnimatePresence initial={false} custom={direction}>
      {page}
    </AnimatePresence>
  )
}

export const AdminOnboarding: React.FC = () => {
  const noOfPages = 3
  const debug = useIsDebugMode()
  const [pageIndex, setPageIndex] = React.useState(0)

  const incrementPageIndexBy = (steps: number): void => {
    const next = pageIndex + steps
    setPageIndex(next)
  }

  const incrementPageIndex = (): void => {
    incrementPageIndexBy(1)
  }

  return (
    <Background>
      <ContentArea>
        <PageSelector pageIndex={pageIndex} goToNext={incrementPageIndex} />
      </ContentArea>

      <ProgressBar
        noOfSteps={noOfPages}
        currentStep={pageIndex}
        gotoIndex={index => {
          const next = index % noOfPages
          setPageIndex(next)
        }}
      />

      {debug && (
        <View>
          {pageIndex}
          <button onClick={() => incrementPageIndexBy(-1)}>back</button>
          <button onClick={() => incrementPageIndexBy(1)}>forward</button>
        </View>
      )}
    </Background>
  )
}
