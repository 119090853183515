function createFileList(fileList?: FileList): FileList {
  const newFileList: File[] = [...(fileList ?? [])]
  return {
    [Symbol.iterator]: newFileList[Symbol.iterator].bind(newFileList),
    item: (index: number) => newFileList[index] ?? null,
    length: newFileList.length,
  }
}

export function createMutableDataTransfer({
  basedOn,
}: {
  basedOn: Partial<DataTransfer> | 'nothing'
}): DataTransfer {
  const data: Record<string, string> = {}
  const typesArray: string[] = []
  const dataTransfer = basedOn === 'nothing' ? undefined : basedOn
  const newDataTransfer: DataTransfer = {
    setData(key: string, value: string) {
      if (!typesArray.includes(key)) {
        typesArray.push(key)
      }
      data[key] = value
    },
    getData(key: string): string {
      return data[key] ?? ''
    },
    clearData(key) {
      if (key !== undefined) {
        if (typesArray.includes(key)) {
          typesArray.splice(typesArray.indexOf(key), 1)
        }
        delete data[key]
      } else {
        for (const key of typesArray) {
          delete data[key]
        }
        typesArray.splice(0, typesArray.length)
      }
    },
    dropEffect: dataTransfer?.dropEffect ?? 'copy',
    effectAllowed: dataTransfer?.effectAllowed ?? 'all',
    setDragImage: dataTransfer?.setDragImage ?? (() => {}),
    files: createFileList(dataTransfer?.files),
    items: [] as unknown as DataTransferItemList,
    types: typesArray,
  }

  if (dataTransfer !== undefined) {
    for (const type of dataTransfer.types ?? []) {
      newDataTransfer.setData(type, dataTransfer.getData?.(type) ?? '')
    }
  }

  return newDataTransfer
}
