export const AVATAR_GAP = 8
export const maxWidthHeightRatio = 16 / 9
export const minHeightWidthRatio = 10 / 16

/**
 * returns the width and height of the avatar in percentage of the container
 */
export const calculateVideoAvatarBarAvatarSize = (
  nrOfVisibleParticipants: number,
  containerWidth: number,
  containerHeight: number
): { width: number; height: number } => {
  if (containerWidth === 0 || containerHeight === 0) return { width: 0, height: 0 }

  const availableHeight = containerHeight - nrOfVisibleParticipants * AVATAR_GAP
  const height = ((availableHeight / nrOfVisibleParticipants) * 100) / containerHeight

  const availableWidth = containerWidth - nrOfVisibleParticipants * AVATAR_GAP

  const width = ((availableWidth / nrOfVisibleParticipants) * 100) / containerWidth

  const maxHeightInPercentage = (containerWidth * maxWidthHeightRatio * 100) / containerHeight

  const maxWidthInPercentage = (containerHeight * maxWidthHeightRatio * 100) / containerWidth

  return { width: Math.min(width, maxWidthInPercentage), height: Math.min(height, maxHeightInPercentage) }
}
