import { ReactNode } from 'react'
import { Pill } from 'sierra-client/components/common/pill'
import { token } from 'sierra-ui/theming'
import { fonts } from 'sierra-ui/theming/fonts'
import styled from 'styled-components'

const StyledPill = styled(Pill)`
  margin-right: 4px;
  font-weight: ${fonts.weight.medium};
  background-color: ${token('surface/default')};
  border-color: ${token('border/default')};

  *,
  & {
    color: ${token('foreground/secondary')};
  }
`

export const SharingPill: React.FC<{ children: ReactNode; onClick: () => void }> = ({
  children,
  onClick,
}) => {
  return (
    <StyledPill
      iconId='close'
      iconPos='right'
      variant='outlined'
      size='large'
      active={false}
      onClick={onClick}
    >
      {children}
    </StyledPill>
  )
}
