import { useQuery } from '@tanstack/react-query'
import { useMemo, useState } from 'react'
import { SummaryResult, tryJsonFormat } from 'sierra-client/components/liveV2/right-sidebar/use-auto-summary'
import { useOrgTenantId } from 'sierra-client/hooks/use-org-tenant-id'
import { typedPost } from 'sierra-client/state/api'
import { LiveSessionId } from 'sierra-domain/api/nano-id'
import { getLiveChannelName } from 'sierra-domain/live/get-live-channel-name'
import { XRealtimeStrategyLiveSessionGetSummary } from 'sierra-domain/routes'

const CACHE_KEY = 'post-session-summary' as const
const REFETCH_INTERVAL = 5000 as const
const REFETCH_ATTEMPTS = 30 as const

export type UseCompletedPostSessionSummaryResult =
  | { status: 'loading'; data?: undefined }
  | { status: 'success'; data: SummaryResult }
  | { status: 'error'; data?: undefined }

export const useCompletedPostSessionSummary = (
  liveSessionId: LiveSessionId
): UseCompletedPostSessionSummaryResult => {
  const [refetchEnabled, setRefetchEnabled] = useState(true)
  const tenantId = useOrgTenantId()

  const channelName = tenantId !== undefined ? getLiveChannelName(tenantId, liveSessionId) : tenantId

  const summaryResponse = useQuery({
    queryKey: [CACHE_KEY, 'liveSessionId', liveSessionId, channelName],
    queryFn: () => {
      if (channelName === undefined) return undefined
      return typedPost(XRealtimeStrategyLiveSessionGetSummary, {
        liveSessionId,
        channelName,
      })
    },
    refetchInterval: query => {
      const interval =
        query.state.data?.status === 'completed' || query.state.data?.status === 'unavailable'
          ? false
          : query.state.dataUpdateCount < REFETCH_ATTEMPTS
            ? REFETCH_INTERVAL
            : false

      if (interval === false) {
        setRefetchEnabled(false)
      }
      return interval
    },
  })

  const summaryData: SummaryResult | undefined = useMemo(() => {
    if (summaryResponse.isSuccess && summaryResponse.data?.status !== undefined) {
      if (summaryResponse.data.status === 'rolling' || summaryResponse.data.status === 'started')
        return undefined

      if (summaryResponse.data.status === 'unavailable') return { format: 'unavailable' }
      if (summaryResponse.data.summary !== undefined) {
        const asNote = tryJsonFormat(summaryResponse.data.summary)
        if (asNote)
          return {
            format: 'note',
            data: asNote,
          }

        return {
          format: 'string',
          data: summaryResponse.data.summary,
        }
      }
    }
    return undefined
  }, [summaryResponse.data?.status, summaryResponse.data?.summary, summaryResponse.isSuccess])

  return useMemo(() => {
    if (summaryData) return { status: 'success', data: summaryData }
    if (refetchEnabled) return { status: 'loading' }
    return { status: 'error' }
  }, [summaryData, refetchEnabled])
}
