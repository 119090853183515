import { useEffect, useState } from 'react'
import { Lobby } from 'sierra-client/components/liveV2/cards/lobby'
import { NoVideoCallView } from 'sierra-client/components/liveV2/cards/no-video-call-view'
import { useIsFacilitatorOrLearnerLedSession } from 'sierra-client/components/liveV2/hooks/use-is-facilitator-or-learner-led-session'
import { useSelectCurrentCard } from 'sierra-client/components/liveV2/hooks/use-select-current-card'
import { useHasLiveContext } from 'sierra-client/components/liveV2/live-context'
import { useSelector } from 'sierra-client/state/hooks'
import { selectVideoCallMode } from 'sierra-client/state/live-session/selectors'
import { BreakoutRoomsModal } from 'sierra-client/views/liveV2/breakout-rooms'
import { CreateContentId } from 'sierra-domain/api/nano-id'
import { BreakoutRoomData } from 'sierra-domain/flexible-content/types'

export const BreakoutRoom: (props: {
  data: BreakoutRoomData
  flexibleContentId: CreateContentId
}) => JSX.Element = props => {
  const isFacilitatorOrLearnerLedSession = useIsFacilitatorOrLearnerLedSession()
  const hasLiveContext = useHasLiveContext()
  const videoCallMode = useSelector(selectVideoCallMode)
  const currentCard = useSelectCurrentCard()
  const [showBreakoutRoomModal, setShowBreakoutRoomModal] = useState(videoCallMode === 'main')

  useEffect(() => {
    setShowBreakoutRoomModal(true)
  }, [currentCard?.id])

  useEffect(() => {
    if (videoCallMode === 'breakout') setShowBreakoutRoomModal(false)
  }, [videoCallMode])

  if (!hasLiveContext) return <NoVideoCallView card='breakout-room' />

  return (
    <>
      {isFacilitatorOrLearnerLedSession && showBreakoutRoomModal && (
        <BreakoutRoomsModal
          onClose={() => setShowBreakoutRoomModal(false)}
          suggestedDuration={props.data.settings.duration}
          suggestedRoomSize={props.data.settings.roomSize}
        />
      )}
      <Lobby flexibleContentId={props.flexibleContentId} />
    </>
  )
}
