import { useTranslation } from 'sierra-client/hooks/use-translation'
import { IconButton } from 'sierra-ui/primitives'

export const HighlightReflectionResponseButton: React.FC<{ reflectionId: string }> = ({ reflectionId }) => {
  const { t } = useTranslation()
  const reflectionResponse = document.getElementById(`reflection-response:${reflectionId}`)

  if (reflectionResponse === null) {
    return null
  }

  return (
    <IconButton
      iconId='view'
      variant='secondary'
      tooltip={t('dictionary.view')}
      size='small'
      onClick={() => {
        reflectionResponse.scrollIntoView({ behavior: 'smooth', block: 'center' })
        setTimeout(() => {
          reflectionResponse.setAttribute('data-focus-highlight', 'true')
        }, 800)
        setTimeout(() => {
          reflectionResponse.removeAttribute('data-focus-highlight')
        }, 3000)
      }}
    />
  )
}
