import { useCallback } from 'react'
import { ShortcutMenu } from 'sierra-client/components/shortcut-menu'
import { useShortcutMenuDispatch } from 'sierra-client/components/shortcut-menu/context'
import {
  newPollData,
  newQuestionData,
  useAddFileCreatedByUser,
} from 'sierra-client/state/flexible-content/factory'
import {
  useCreatePageContext,
  useCreatePageYDocContext,
} from 'sierra-client/views/flexible-content/create-page-context'
import { useGeneratePollCard } from 'sierra-client/views/v3-author/poll-card/use-generate-poll-card'
import { useGenerateQuestionCard } from 'sierra-client/views/v3-author/question-card/use-generate-question-card'
import { PollTypes, useGeneratePollItems } from 'sierra-client/views/v3-author/slash-menu/content-generation'
import { FileId, FolderId } from 'sierra-domain/flexible-content/identifiers'

export const GenerateCardsShortcuts: React.FC<{
  fileId: FileId
  folderId: FolderId
}> = ({ fileId, folderId }) => {
  const { createContentId } = useCreatePageContext()
  const { yDoc } = useCreatePageYDocContext()

  const addFile = useAddFileCreatedByUser({
    folderId,
    nextTo: fileId,
    cardAddedByUserLoggingType: 'generatedContent',
  })

  const { generateQuestionCard } = useGenerateQuestionCard({ yDoc, currentNodeId: fileId, createContentId })
  const generateQuestion = useCallback(async () => {
    const questionCard = await generateQuestionCard()
    if (questionCard !== undefined) {
      await addFile({ data: newQuestionData(), slateDocument: [questionCard] })
    }
  }, [addFile, generateQuestionCard])

  const { generatePollCard } = useGeneratePollCard({ yDoc, currentNodeId: fileId, createContentId })
  const generatePoll = useCallback(
    async (type: PollTypes) => {
      const pollCard = await generatePollCard(type)
      if (pollCard !== undefined) {
        await addFile({ data: newPollData(), slateDocument: [pollCard] })
      }
    },
    [addFile, generatePollCard]
  )
  const pollItems = useGeneratePollItems()
  const shortcutDispatch = useShortcutMenuDispatch()

  return (
    <>
      <ShortcutMenu.Action
        label={{ type: 'untranslated', value: 'Generate question card' }}
        iconId='checkmark--outline'
        run={() => generateQuestion()}
        permission='ACCESS_EDITOR'
        group='create'
      />
      <ShortcutMenu.Settings
        label={{ type: 'untranslated', value: 'Generate poll card' }}
        iconId='poll'
        onItemSelected={({ id }) => {
          void generatePoll(id as PollTypes)
          shortcutDispatch({ type: 'close' })
        }}
        items={pollItems}
        permission='ACCESS_EDITOR'
        group='create'
      />
    </>
  )
}
