import React from 'react'
import { useTranslation } from 'sierra-client/hooks/use-translation'
import { ClickableLabel } from 'sierra-client/views/manage/programs/enrollment-rules/modals/start-date-selector/common'

export const Now: React.FC<{ onClick: () => void }> = ({ onClick }) => {
  const { t } = useTranslation()

  return (
    <ClickableLabel onClick={onClick}>
      {t('manage.programs.enrollment-rules.start-date.directly')}
    </ClickableLabel>
  )
}
