import { SetStateAction, WritableAtom } from 'jotai'
import {
  createJSONStorage,
  // eslint-disable-next-line no-restricted-imports
  atomWithStorage as jotaiAtomWithStorage,
  unstable_withStorageValidator as withStorageValidator,
} from 'jotai/utils'
import { SyncStorage } from 'jotai/vanilla/utils/atomWithStorage'
import localStorage from 'local-storage-fallback'
import { z } from 'zod'

const createStorageForSchema = <T>(schema: z.ZodType<T> | undefined): SyncStorage<T> => {
  if (schema === undefined) return createJSONStorage<T>(() => localStorage)

  const isValidValue = (v: unknown): v is T => schema.safeParse(v).success
  const jsonStorage = createJSONStorage(() => localStorage)

  return withStorageValidator(isValidValue)(jsonStorage)
}

export const atomWithStorage = <T>(
  key: string,
  initialValue: T,
  schema?: z.ZodType<T>,
  options: {
    getOnInit: boolean
  } = {
    getOnInit: false,
  }
): WritableAtom<T, [update: SetStateAction<T>], void> => {
  return jotaiAtomWithStorage<T>(
    //
    key,
    initialValue,
    createStorageForSchema(schema),
    options
  )
}
