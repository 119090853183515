import { useMemo } from 'react'
import { graphql } from 'sierra-client/api/graphql/gql'
import { useGraphQuery } from 'sierra-client/api/hooks/use-graphql-query'
import { InsightsContentSuggestion } from 'sierra-client/views/insights/insights-content-search/types'
import {
  contentToItems,
  groupToItems,
  usersToItems,
} from 'sierra-client/views/insights/insights-content-search/utils'
import { Filter } from 'sierra-domain/filter/datatype/filter'

/* Content */
const everythingQuery = graphql(`
  query EverythingSearchAutoComplete(
    $query: String!
    $limit: Int!
    $contentTypes: [ContentType!]!
    $filter: ContentFilter!
  ) {
    content(query: $query, limit: $limit, contentTypes: $contentTypes, filter: $filter) {
      data {
        contentId
        __typename
        ... on Course {
          courseId
        }
        ... on Path {
          pathId
        }
        ... on Program {
          programId: id
        }
        title
        image {
          ...ImageFragment
        }
      }
    }
    groups: userGroups(query: $query, limit: $limit) {
      data {
        id
        name
        avatar {
          ...AvatarFragment
        }
      }
    }
    users(query: $query, limit: $limit) {
      data {
        id
        displayName
        email
        avatar {
          ...AvatarFragment
        }
      }
    }
  }
`)

// This filter is used to filter out the content with empty title. Since the initial query is an empty string,
// content without a title will be returned by default unless we apply this filter. This should provide a better
// user experience. We assume that that important content will have a title.
const searchContentFilter: Filter = {
  type: 'filter.filter',
  domain: {
    type: 'content.title',
  },
  operator: {
    type: 'operator.neq',
  },
  predicate: {
    type: 'predicate.single',
    value: {
      type: 'value.string',
      value: '',
    },
  },
}

//
// /* Skills */
// const skillsQuery = graphql(`
//   query SkillsAutoComplete($query: String!, $limit: Int!) {
//     skills: skills(query: $query, limit: $limit) {
//       data {
//         id
//         name
//       }
//     }
//   }
// `)

export const useLoadInsightContentSuggestions = (query: string): InsightsContentSuggestion[] => {
  const result = useGraphQuery(
    { document: everythingQuery },
    {
      query: query,
      limit: 3,
      contentTypes: ['COURSE', 'PATH', 'PROGRAM'],
      filter: JSON.stringify(searchContentFilter),
    }
  )
  return useMemo(() => {
    if (result.isPending || result.data === undefined) return []
    const { content, users, groups } = result.data

    const contentItems = contentToItems(content)
    const userItems = usersToItems(users)
    const groupItems = groupToItems(groups)

    return [...contentItems, ...userItems, ...groupItems]
  }, [result.data, result.isPending])
}
