import { Collaboration } from '@tiptap/extension-collaboration'
import CollaborationCursor from '@tiptap/extension-collaboration-cursor'
import Link from '@tiptap/extension-link'
import Placeholder from '@tiptap/extension-placeholder'
import { Editor, Extensions } from '@tiptap/react'
import { StarterKit } from '@tiptap/starter-kit'
import { useCallback, useEffect, useMemo } from 'react'
import { useSelector } from 'sierra-client/state/hooks'
import { selectUser } from 'sierra-client/state/user/user-selector'
import { SpeakerNoteProps } from 'sierra-client/views/flexible-content/editor/content-sidebar/speaker-notes/types'
import {
  getSpeakerNote,
  getSpeakerNoteXmlFragment,
  setSpeakerNoteTimeEdited,
} from 'sierra-domain/collaboration/speaker-notes-map'
import { theme } from 'sierra-ui/theming/legacy-theme'
import { Awareness } from 'y-protocols/awareness'

class Provider {
  awareness: Awareness

  constructor(awareness: Awareness) {
    this.awareness = awareness
  }
}

type CollaborationCursorUser = {
  name?: string
  color: string
}

export const useSpeakerNotesEditor = ({
  file,
  editable,
  yDoc,
  awareness,
}: SpeakerNoteProps): Editor | null => {
  const user = useSelector(selectUser)
  const provider = useMemo(() => new Provider(awareness), [awareness])
  const speakerNote = useMemo(() => getSpeakerNote(yDoc, file.id), [yDoc, file.id])
  const speakerNoteFragment = useMemo(() => getSpeakerNoteXmlFragment(speakerNote), [speakerNote])

  const onUpdate = useCallback(() => {
    setSpeakerNoteTimeEdited(speakerNote, new Date().toISOString())
  }, [speakerNote])

  const editor = useMemo(() => {
    const extensions: Extensions = [
      StarterKit.configure({
        blockquote: false,
        dropcursor: false,
        gapcursor: false,
        hardBreak: false,
        heading: false,
        history: false,
        code: false,
        codeBlock: false,
        bold: false,
        // document: false,
        // horizontalRule: false,
        // orderedList: false,
        // paragraph: false,
        // strike: false,
        // text: false,
      }),
      Link.configure(),
      Placeholder.configure({
        placeholder: 'Add a note...',
      }),
      Collaboration.configure({
        fragment: speakerNoteFragment,
      }),
    ]

    // Don't show collaboration cursors if the editor isn't editable, it looks very confusing
    if (editable) {
      extensions.push(
        CollaborationCursor.configure({
          provider,
          user: {
            name: user?.firstName,
            color: theme.color[user?.avatarColor ?? 'pinkBright'],
          } as CollaborationCursorUser,
          render: (user: CollaborationCursorUser) => {
            // Based on https://github.com/ueberdosis/tiptap/tree/main/packages/extension-collaboration-cursor
            const cursor = document.createElement('span')
            cursor.classList.add('collaboration-cursor__caret')
            cursor.setAttribute('style', `--cursor-color: ${user.color}`)

            const label = document.createElement('div')
            label.classList.add('collaboration-cursor__label')
            label.appendChild(document.createTextNode(user.name ?? ''))
            cursor.appendChild(label)

            return cursor
          },
        })
      )
    }

    return new Editor({
      editable,
      extensions,
      onUpdate,
    })
  }, [onUpdate, editable, provider, speakerNoteFragment, user?.avatarColor, user?.firstName])

  useEffect(() => () => editor.destroy(), [editor])

  return editor
}
