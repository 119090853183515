import { useSetAtom } from 'jotai'
import { useEffect } from 'react'
import { ShortcutMenu } from 'sierra-client/components/shortcut-menu/shortcut-menu'
import { reactDebugShortcutKeyAtom } from 'sierra-client/features/react-debug-mode/atoms'
import { useDeveloperToolsEnabled } from 'sierra-client/hooks/use-developer-tools-enabled'

const ReactDebugKeyListener: React.FC = () => {
  const setIsShortcutKeyDown = useSetAtom(reactDebugShortcutKeyAtom)

  useEffect(() => {
    function onKeyEvent({ altKey }: KeyboardEvent): void {
      setIsShortcutKeyDown(altKey)
    }

    function turnOff(): void {
      setIsShortcutKeyDown(false)
    }

    window.addEventListener('keydown', onKeyEvent)
    window.addEventListener('keyup', onKeyEvent)
    window.addEventListener('blur', turnOff)
    return () => {
      setIsShortcutKeyDown(false)
      window.removeEventListener('keydown', onKeyEvent)
      window.removeEventListener('keyup', onKeyEvent)
      window.removeEventListener('blur', turnOff)
    }
  }, [setIsShortcutKeyDown])

  return null
}

export const ReactDebugModeShortcutToggle: React.FC = () => {
  const developerToolsEnabled = useDeveloperToolsEnabled()

  if (!developerToolsEnabled) return null
  return (
    <>
      <ReactDebugKeyListener />
      <ShortcutMenu.Action
        label={{ type: 'untranslated', value: 'React Debug mode' }}
        group='developer-tools'
        run={() => {
          alert('React debug has moved! Hold down option (alt) to use it.')
        }}
        iconId='inspection'
        permission='ACCESS_LMS'
      />
    </>
  )
}
