import { stringWithZodSerializer } from 'sierra-client/lib/querystate/use-query-state'
import { UserId } from 'sierra-domain/api/uuid'
import z from 'zod'

export type ModalActions =
  | { modal: undefined }
  | { modal: 'users' }
  | { modal: 'unassign-users'; targets: UserId[]; callback?: () => void }
  | { modal: 'heatmap' }
  | { modal: 'program-admins' }
  | { modal: 'edit-program'; enrollmentMode?: 'create-enrollment-rule' | 'users' }
  | { modal: 'create-enrollment-rule' }

const zodPanelType = z.enum(['edit', 'none'])
export type PanelType = z.infer<typeof zodPanelType>
export const activePanelSerializer = stringWithZodSerializer(zodPanelType)
