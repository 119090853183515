import { Atom } from 'jotai'
import { isEqual } from 'lodash/fp'
import { useSelectAtomValue } from 'sierra-client/hooks/use-select-atom-value'
import { useTranslation } from 'sierra-client/hooks/use-translation'
import { isUserLoaded, useUsers } from 'sierra-client/state/users/hooks'
import { UserId } from 'sierra-domain/api/uuid'
import styled from 'styled-components'

const IsTypingIndicator = styled.div`
  position: absolute;
  bottom: 0;
  left: 18px;
  padding-bottom: 0.5rem;
  font-size: 12px;
  color: ${p => p.theme.color.grey25};
  user-select: none;
`

const MAX_USERS_TYPING = 5

export const UsersWriting = ({
  usersTypingAtom,
}: {
  usersTypingAtom: Atom<UserId[]>
}): JSX.Element | null => {
  const { t } = useTranslation()
  const userIds = useSelectAtomValue(usersTypingAtom, users => users.slice(0, MAX_USERS_TYPING), isEqual)
  const typingOverLimit = useSelectAtomValue(usersTypingAtom, users => users.length > MAX_USERS_TYPING)

  const users = useUsers(userIds).filter(isUserLoaded)
  const firstNames = users.map(user => user.firstName).join(', ')

  if (users.length === 0) return null
  if (typingOverLimit) return <IsTypingIndicator>Several people are typing...</IsTypingIndicator>
  return <IsTypingIndicator>{t('learner.poll-card.x-is-typing', { name: firstNames })} </IsTypingIndicator>
}
