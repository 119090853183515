import { atomWithStorage } from 'sierra-client/state/storage'
import { z } from 'zod'

const VideoCallSettingType = z.union([
  z.literal('sana'),
  z.literal('none'),
  z.literal('url'),
  z.literal('google-meet'),
  z.literal('zoom'),
  z.literal('microsoft-teams'),
])

export const savedVideoCallSettingTypeAtom = atomWithStorage(
  'saved-video-call-setting',
  undefined,
  VideoCallSettingType,
  { getOnInit: true }
)
