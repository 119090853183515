import { keepPreviousData } from '@tanstack/react-query'
import React, { useCallback, useState } from 'react'
import { SkillId } from 'sierra-client/api/graphql/branded-types'
import { graphql } from 'sierra-client/api/graphql/gql'
import { useGraphQuery } from 'sierra-client/api/hooks/use-graphql-query'
import { useTranslation } from 'sierra-client/hooks/use-translation'
import { Context } from 'sierra-client/lib/filter/components/common'
import {
  ValueAnchor,
  addPredValue,
  fromPredicate,
  removePredValue,
} from 'sierra-client/lib/filter/components/predicate-utils'
import { UUID } from 'sierra-domain/api/uuid'
import { Predicate } from 'sierra-domain/filter/datatype/pred'
import { setPred } from 'sierra-domain/filter/operations'
import { isDefined } from 'sierra-domain/utils'
import { Autocomplete, DefaultStyledAutocompleteOption, FormElement } from 'sierra-ui/components'
import { ListContainer, SelectedPill } from 'sierra-ui/components/autocomplete/reference-implementation/atoms'
import { IconButton, Text } from 'sierra-ui/primitives'

const skillsQuery = graphql(`
  query SkillsAutoComplete($query: String!, $limit: Int!) {
    skills: skills(query: $query, limit: $limit) {
      data {
        id
        name
      }
    }
  }
`)

const skillsByIdsQuery = graphql(`
  query SkillsByIds($ids: [SkillId!]!) {
    skills: skillsFromIds(ids: $ids) {
      id
      name
    }
  }
`)

const emptyArray: AutoCompleteSkill[] = []

const useSkillsFromPredicate = (predicate: Predicate): AutoCompleteSkill[] | readonly [] => {
  const skillIds = fromPredicate(predicate)
    .map(value => (value.type === 'value.skill-id' || value.type === 'value.uuid' ? value.value : undefined))
    .filter(isDefined)

  const response = useGraphQuery(
    {
      document: skillsByIdsQuery,
      queryOptions: {
        enabled: skillIds.length !== 0,
        staleTime: 60 * 1000,
        placeholderData: keepPreviousData,
      },
    },
    { ids: skillIds.map(it => SkillId.parse(it)) }
  )

  if (skillIds.length === 0) {
    return emptyArray
  }

  return (
    response.data?.skills.map(it => ({
      id: it.id,
      value: { type: 'value.skill-id', value: it.id },
      name: it.name,
    })) ?? emptyArray
  )
}

const useLabelsForSkillPredicate = (predicate: Predicate): string[] => {
  const skills = useSkillsFromPredicate(predicate)
  const labels = skills.map(it => it.name)
  return labels
}

const useQuerySkillsByIds = (predicate: Predicate): AutoCompleteSkill[] => {
  const skills = useSkillsFromPredicate(predicate)

  return skills.map(skill => {
    return {
      id: skill.id,
      name: skill.name,
      value: { type: 'value.skill-id', value: skill.id },
    }
  })
}

type AutoCompleteSkill = {
  id: UUID
  name: string
  value: { type: 'value.skill-id'; value: UUID }
}

type SkillAutoCompleteProps = {
  ctx: Context
  predicate: Predicate
}

export const SkillAutoComplete: React.FC<SkillAutoCompleteProps> = ({ ctx, predicate }) => {
  const selectedSkills = useQuerySkillsByIds(predicate)
  const [query, setQuery] = useState('')
  const { t } = useTranslation()

  const onSelect = useCallback(
    (content: AutoCompleteSkill) => {
      ctx.update(f => setPred(f, addPredValue(predicate, content.value)))
    },
    [ctx, predicate]
  )

  const onUnselect = useCallback(
    (content: AutoCompleteSkill) => {
      ctx.update(f => setPred(f, removePredValue(predicate, content.value)))
    },
    [ctx, predicate]
  )

  const searchSkills = useGraphQuery(
    { document: skillsQuery, queryOptions: { staleTime: 60 * 1000, placeholderData: keepPreviousData } },
    { query, limit: 20 }
  )

  const matchingItems: AutoCompleteSkill[] =
    searchSkills.data?.skills.data.map(it => ({
      id: it.id,
      value: { type: 'value.skill-id', value: it.id },
      name: it.name,
    })) ?? emptyArray

  return (
    <FormElement label={t('dictionary.skills')}>
      <Autocomplete
        placeholder={t('menu.search.placeholder')}
        autofocus
        query={query}
        onQueryChange={setQuery}
        selectedItems={selectedSkills}
        matchingItems={matchingItems}
        onSelect={onSelect}
        onUnselect={onUnselect}
        renderSelectedItem={(item, { onUnselect, ...props }) => {
          return (
            <SelectedPill key={item.id} {...props}>
              <Text color='foreground/primary' bold>
                {item.name}
              </Text>

              <IconButton iconId='close' onClick={onUnselect} size='small' variant='transparent' />
            </SelectedPill>
          )
        }}
        renderMatchingItemList={({ getItemProps }) => (
          <ListContainer>
            {matchingItems.map((item, index) => {
              return (
                <DefaultStyledAutocompleteOption key={item.id} {...getItemProps(item, index)}>
                  <Text color='foreground/primary'>{item.name}</Text>
                </DefaultStyledAutocompleteOption>
              )
            })}
          </ListContainer>
        )}
      />
    </FormElement>
  )
}

export const SkillAutocompleteLabel = React.forwardRef<HTMLDivElement, { predicate: Predicate }>(
  ({ predicate, ...rest }, ref) => {
    const labels = useLabelsForSkillPredicate(predicate)
    const label = labels.join(', ')

    return <ValueAnchor {...rest} ref={ref} label={label} />
  }
)
